import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    Tile, Tooltip, Button, TextArea, InlineNotification,
} from 'carbon-components-react';
import StarRatingComponent from 'react-star-rating-component';

import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy//NewJob/JDProcessOverlay';

import {
    getFeedbackGiven,
} from 'actions/candidate/references';

class Feedback extends Component {
    paths = [
        { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
        { id: 'references', name: 'References', href: '/app/campus/candidate/references' },
        { id: 'reference-feedback', name: 'Feedback', href: '' },
    ];

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { match: { params: { id = '' } } } = this.props;
        this.props.getFeedbackGiven(id);
    }

    starRating = (metricId, rating, ) => {
        let metricRating = this.state[`rate-${metricId}`];
        if (rating) {
            metricRating = rating;
        }
        return (
            <StarRatingComponent
                name={`rate-${metricId}`}
                starCount={5}
                value={metricRating ? metricRating : parseInt(0, 10)}
                starColor="#ffb400"
                emptyStarColor="#ddd"
                renderStarIcon={(index, value) => {
                    return (
                        <span>
                            <i className={index <= value ? 'fa fa-star' : 'fa fa-star'} />
                        </span>
                    );
                }}
                editing={false}
                renderStarIconHalf={() => {
                    return (
                        <span>
                            <span style={{ position: 'absolute' }}><i className="fa fa-star" /></span>
                            <span><i className="fa fa-star-half" /></span>
                        </span>
                    );
                }}
                onStarClick={(nextValue, prevValue, name) => {
                    this.setState({
                        [name]: nextValue,
                    });
                }}
            />
        );
    }

    renderSection = metricName => (
        <div className="bx--type-delta bx--type-caps mb-3">
            {metricName}
        </div>
    )

    renderFeedback = (rm) => {
        const {
            metricId, metricName,
            feedback,
        } = rm || {};
        const { narrativeText } = this.state;
        return (
            <div className="pt-2 pb-2">
                {
                    feedback && (
                        <Fragment>
                            <div className="bx--type-delta bx--type-caps mb-2">{metricName}</div>
                            <p>{feedback}</p>
                        </Fragment>)
                }
            </div>);
    }

    renderRating = (metric) => {
        const {
            metricId,
            metricName,
            metricDescription,
            rating,
        } = metric || {};
        return (
            <div className="bx--row pl-2 pr-2 mb-4" key={metricId}>
                <div style={{ width: '250px' }} className="mr-4">
                    <p className="mb-0">{metricName}</p>
                    {metricDescription && (
                        <div className="text-dark text-justify text-uppercase">
                            <small>{metricDescription}</small>
                        </div>)}
                </div>
                {this.starRating(metricId, rating)}
            </div>
        );
    }

    renderMetric = (metric, feedback, rating) => {
        const {
            metricId, metricName, metricDescription,
            ratingmetrictype: {
                metricTypeName = '',
            } = {},
        } = metric || {};
        const ratingObj = {
            metricId,
            metricName,
            metricDescription,
            rating,
        };
        const feedbackObj = {
            metricId,
            metricName,
            feedback,
        };
        let renderContent = (<div />);
        switch (metricTypeName.toLowerCase()) {
            case 'section':
                renderContent = this.renderSection(metricName);
                break;
            case 'rating':
                renderContent = this.renderRating(ratingObj);
                break;
            case 'feedback':
                renderContent = this.renderFeedback(feedbackObj);
                break;
            default:
            //
        }
        return renderContent;
    }

    render() {
        const {
            loading,
            response = []
        } = this.props;
        const { ratings = [], userInfo: { firstname = '', lastname = '', email = '' } = {} } = response && Array.isArray(response) && response.length > 0 && response[0];
        return (
            <div>
                <BackRow paths={this.paths} />
                {
                    (!loading) && (
                        <Tile className="p-4">
                            <h4 className="bx--type-gamma font-weight-bold text-capitalize">Feedback for <strong>{`${firstname} ${lastname}`}</strong></h4>
                            <p className="mb-0">
                                You have provided the following feedback for
                <b> {firstname + " " + lastname} ({email}).</b>
                                <div>We keep your rating private from the candidate.</div>
                            </p>
                            <div className="bx--modal-header__label bx--type-delta pt-1">Rating criteria</div>
                            <p className="text-dark bx--type-omega mt-2">
                                <span className="pr-2"><i className="fa fa-star text-warning"></i> - Area of concern</span>
                                <span className="pr-2"><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i> - Below average</span>
                                <span className="pr-2"><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i> - Average</span>
                                <span className="pr-2"><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i> - Good</span>
                                <span className="pr-2"><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i> - Exceptional</span>
                            </p>
                            <div>
                                <div className="bx--row justify-content-between">
                                    {
                                        ratings && ratings.length > 0 && (
                                            <Fragment>
                                                {
                                                    ratings.map((rm) => {
                                                        const {
                                                            metricId,
                                                            ratingmetrics = {},
                                                            rating,
                                                            feedback,
                                                        } = rm || {};
                                                        return (
                                                            <Fragment key={metricId}>
                                                                <div className="bx--col-md-5">
                                                                    {
                                                                        ratingmetrics && <Fragment key={ratingmetrics.metricId}>{this.renderMetric(ratingmetrics, feedback, rating)}</Fragment>
                                                                    }
                                                                </div>
                                                            </Fragment>);
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        </Tile>)
                }
                <JDProcessOverlay show={this.props.loading} message="processing..." />
            </div>);
    }
}

const mapStateToProps = state => ({
    loading: state.candidateReferences.loading,
    user: state.x0paDuser.user,
    response: state.candidateReferences.response,
});

const mapDispatchToProps = {
    getFeedbackGiven,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
