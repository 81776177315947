import { withTranslation } from 'react-i18next';
import React from 'react';
import { Select, SelectItem } from 'carbon-components-react';

const ApplicantsTabs = ({ selectedTab, tabCount, handleApplicantsTabClick }) => (
  <Select
    hideLabel
    light
    invalidText="A valid value is required"
    onChange={handleApplicantsTabClick}
    id="select-1"
  >
    {tabCount && <SelectItem selected={selectedTab === 0 ? 'selected="selected"' : ''} value="processing" text={`All Applicants (${tabCount.allApplicants || 0})`} />}
    {tabCount && <SelectItem selected={selectedTab === 1 ? 'selected="selected"' : ''} value="isLiked" text={`Favourite Candidates (${tabCount.isLiked || 0})`} />}
    {tabCount && <SelectItem selected={selectedTab === 2 ? 'selected="selected"' : ''} value="screening" text={`Pending Screening (${tabCount.screening || 0})`} />}
    {tabCount && <SelectItem selected={selectedTab === 3 ? 'selected="selected"' : ''} value="shortlisted" text={`Shortlisted (${tabCount.shortlisted || 0})`} />}
    {tabCount && <SelectItem selected={selectedTab === 4 ? 'selected="selected"' : ''} value="rejected" text={`Rejected (${tabCount.rejected || 0})`} />}
    {tabCount && <SelectItem selected={selectedTab === 5 ? 'selected="selected"' : ''} value="recommended" text={`Roboroy Shortlisted (${tabCount.recommended || 0})`} />}
    {tabCount && <SelectItem selected={selectedTab === 6 ? 'selected="selected"' : ''} value="aiSourcing" text={`AI Sourced Candidates (${tabCount.aiSourcing || 0})`} />}
  </Select>
);

export default ApplicantsTabs;
