import { withTranslation } from 'react-i18next';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'carbon-components-react';

import BackRow from 'components/common/BackRow/BackRow';

import ProfileInfo from './ProfileInfo';
import CalendarSettings from './CalendarSettings';
import ProfileSecurity from './ProfileSecurity';
import GeneralSettings from './GeneralSettings';


import './Profile.css';
import { StudentProfileView } from '../../../routes/roboroy';

class Profile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.paths = [
      { id: 'campus', name: props.t('campus'), href: '/app/campus' },
      { id: 'profile-dashboard', name: props.t('profile'), href: '' },
    ];
    this.candidatePaths = [
      { id: 'campus', name: props.t('campus'), href: '/app/campus/candidate' },
      { id: 'profile-dashboard', name: props.t('profile'), href: '' },
    ];
  }

  componentDidMount() {
  }

  render() {
    const {
      user: {
        roleId,
        profileId,
      } = {},
      t,
    } = this.props;
    return (
      <div>
        {
          roleId && roleId !== 3
            && (
              <BackRow paths={this.paths} />
            )
        }
        {
          roleId && roleId === 3
            && (
              <div className="ml-2"><BackRow paths={this.candidatePaths} /></div>
            )
        }
        <div className="bx--type-gamma font-weight-bold text-capitalize pb-1 ml-2">
          {t('profile')}
        </div>
        {(roleId && roleId === 3) ? 
          <StudentProfileView history={this.props.history}/>:
          <ProfileInfo history={this.props.history} location={this.props.location} isProfilePage />
        }
        {/* {
          (roleId !== 3) && (
            <Tabs selected={0} className="mt-4">
              <Tab label="Calendar Settings"><CalendarSettings /></Tab>
              <Tab label="Security"><ProfileSecurity /></Tab>
              <Tab label="General"><GeneralSettings /></Tab>
            </Tabs>
          )
        } */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Profile));
