import {
  REQUEST_GET_EMAIL_TEMPLATES,
  SUCCESS_GET_EMAIL_TEMPLATES,
  ERROR_GET_EMAIL_TEMPLATES,
  REQUEST_GET_EMAIL_TEMPLATE,
  SUCCESS_GET_EMAIL_TEMPLATE,
  ERROR_GET_EMAIL_TEMPLATE,
  REQUEST_UPSERT_EMAIL_TEMPLATE,
  SUCCESS_UPSERT_EMAIL_TEMPLATE,
  ERROR_UPSERT_EMAIL_TEMPLATE,
  REQUEST_SEND_EMAIL_TEMPLATE,
  SUCCESS_SEND_EMAIL_TEMPLATE,
  ERROR_SEND_EMAIL_TEMPLATE,
  REQUEST_DELETE_CUSTOM_EMAIL_TEMPLATES,
  SUCCESS_DELETE_CUSTOM_EMAIL_TEMPLATES,
  ERROR_DELETE_CUSTOM_EMAIL_TEMPLATES,
} from '../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  templates: [],
  template: {},
};

const emailTemplates = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_GET_EMAIL_TEMPLATES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_EMAIL_TEMPLATES: {
      return {
        ...state,
        templates: payload,
        loading: false,
        error: null,
      };
    }
    case ERROR_GET_EMAIL_TEMPLATES:
      return {
        ...state,
        templates: [],
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_CUSTOM_EMAIL_TEMPLATES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_CUSTOM_EMAIL_TEMPLATES: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_DELETE_CUSTOM_EMAIL_TEMPLATES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_EMAIL_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: null,
        template: {},
      };
    case SUCCESS_GET_EMAIL_TEMPLATE: {
      return {
        ...state,
        template: payload,
        loading: false,
        error: null,
      };
    }
    case ERROR_GET_EMAIL_TEMPLATE:
      return {
        ...state,
        template: {},
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_EMAIL_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_EMAIL_TEMPLATE: {
      const st = state.templates.filter(t => t.id !== payload.id);
      const upt = [...st, payload].sort((a, b) => {
        const nameA = a.displayName && a.displayName.toLowerCase();
        const nameB = b.displayName && b.displayName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        templates: upt,
        loading: false,
        error: null,
      };
    }
    case ERROR_UPSERT_EMAIL_TEMPLATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SEND_EMAIL_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SEND_EMAIL_TEMPLATE: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_SEND_EMAIL_TEMPLATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default emailTemplates;
