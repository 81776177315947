import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button, TextInput,
  Select, SelectItem,
} from 'carbon-components-react';
import {
  uploadDocs,
} from 'actions/userProfile/action';
import {
  getWorkflowDocumentTypes,
  createWorkflowDocuments,
  deleteWorkflowDocumentByID,
  updateWorkflowDocumentsById,
} from 'actions/DocumentsUpload';
import {
  getApplicationWorkflow,
} from 'actions/workflow';
import './DocumentsUpload.css';

class DocumentsUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValues: [],
      documentType: '',
      textInput: '',
      newDocuments: [],
      isValidSelectDropDown: false,
      isValidSelectDropDownText: '',
      isValidTextInput: false,
      isValidTextInputContent: '',
      index: 0,
    };
  }

  componentDidMount() {
    this.props.getWorkflowDocumentTypes();
  }

  handleChange = (e, index) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      this.setState({
        [`textInput_${index}`]: this.state.documentType,
      });
    });
  };

  handleAddInputValues = () => {
    this.setState({
      inputValues: this.state.inputValues.concat([{ name: '' }]),
    });
  }

  handleUploadFile = (element, workflowDocumentId, appStageUserMapId, name) => {
    const { appId } = this.props;
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('file', fileData[0]);
    this.props.uploadDocs(currFile).then((res) => {
      const {
        result: {
          files: {
            file,
          } = {},
        } = {},
      } = res || {};
      const {
        providerResponse: {
          location,
        } = {},
      } = (file && file[0]) || [];
      const data = {
        workflowDocumentId,
        appStageUserMapId,
        uploadLocation: location,
        workflowDocumentType: name,
      };
      this.props.updateWorkflowDocumentsById(data).then(() => {
        this.props.getApplicationWorkflow(appId);
      });
    });
  }

  handleSubmitWorkflowDocuments = () => {
    const {
      eachSubStage: {
        collaborators,
      } = {},
    } = this.props;
    const {
      appStageUserMapId,
    } = (collaborators && collaborators[0]) || [];
    const { isValidTextInput, index } = this.state;
    const { appId } = this.props;
    if (this.state.documentType === null
      || this.state.documentType === undefined
      || this.state.documentType === '') {
      this.setState({
        isValidSelectDropDown: true,
        isValidSelectDropDownText: 'Please select atleast one value',
      });
    } else if (isValidTextInput === true) {
      this.setState({
        isValidTextInput: true,
        isValidTextInputContent: 'Please enter the name of the document',
      });
    } else {
      let { newDocuments } = this.state;
      if (newDocuments && Array.isArray(newDocuments) && newDocuments.length <= 0) {
        newDocuments = [{ appStageUserMapId, workflowDocumentType: this.state.documentType, documentName: this.state[`textInput_${index}`] }];
      } else {
        newDocuments.push({
          appStageUserMapId,
          documentName: this.state[`textInput_${index}`],
          workflowDocumentType: this.state.documentType,
        });
      }
      this.setState({
        newDocuments,
      }, () => {
        if (this.state.newDocuments && Array.isArray(this.state.newDocuments) && this.state.newDocuments.length > 0) {
          this.state.newDocuments.forEach((res) => {
            const {
              documentName,
              workflowDocumentType,
              appStageUserMapId,
            } = res;
            const data = {
              documentName,
              workflowDocumentType,
              appStageUserMapId,
            };
            this.props.createWorkflowDocuments(data).then(() => {
              this.props.getApplicationWorkflow(appId);
              this.setState({
                inputValues: [],
                newDocuments: [],
              });
            });
          });
        }
      });
    }
  }

  handleDeleteInputValues = (index) => {
    const { inputValues } = this.state;
    const filteredValues = inputValues && Array.isArray(inputValues)
      && inputValues.length > 0
      && inputValues.filter((vals, idx) => idx != index);
    this.setState({
      inputValues: filteredValues,
    });
  }

  handleDeleteWorkflowDocumentById = (workflowDocumentId) => {
    const { appId } = this.props;
    this.props.deleteWorkflowDocumentByID(workflowDocumentId).then(() => {
      this.props.getApplicationWorkflow(appId);
    });
  }

  handleChangeInputValues = (e, index) => {
    this.setState({
      [`textInput_${index}`]: e.target.value,
      index: index,
    }, () => {
      if (this.state[`textInput_${index}`] === undefined
        || this.state[`textInput_${index}`] === null
        || this.state[`textInput_${index}`] === '') {
        this.setState({
          isValidTextInput: true,
        });
      } else {
        this.setState({
          isValidTextInput: false,
        });
      }
    });
  }

  render() {
    const {
      user: {
        roleId,
      } = {},
      documentTypes,
      eachSubStage,
      appId,
      jobId,
      handleOpenEmailModal,
      applicationSourceName,
      currentSubStageInProgress,
      isThisSubStageCurrentlyInProgress,
    } = this.props;
    const {
      inputValues, isValidSelectDropDown, isValidSelectDropDownText,
      isValidTextInput, isValidTextInputContent,
    } = this.state;
    return (
      <Fragment>
        {
          isThisSubStageCurrentlyInProgress(eachSubStage, currentSubStageInProgress)
            && roleId !== 3
            && (
              <h6>Please add documents that you need from candidate.</h6>
            )
        }
        {
          eachSubStage && eachSubStage.workflowdocuments
            && Array.isArray(eachSubStage.workflowdocuments)
            && eachSubStage.workflowdocuments.length > 0
            && eachSubStage.workflowdocuments.map((docs) => {
              const {
                workflowDocumentId,
                appStageUserMapId,
                documentName,
                workflowDocumentTypeId,
                uploadLocation,
                workflowdocumenttypes: {
                  name,
                } = {},
              } = docs || {};
              return (
                <div className="d-lg-flex mb-3 mt-2">
                  {
                    roleId && roleId !== 3 ? (
                      <div className="d-lg-flex">
                        <div className="mb-lg-none">
                          <Select
                            className="w-100 docs-min-width mb-2"
                            id={workflowDocumentId}
                            defaultValue="placeholder-item"
                            hideLabel
                            disabled
                          >
                            <SelectItem
                              key={workflowDocumentTypeId}
                              label={name}
                              value={name}
                            />
                          </Select>
                        </div>
                        <div className="ml-lg-2 mb-lg-none">
                          <TextInput
                            className="mb-2"
                            value={documentName}
                            placeholder="name of document"
                            hideLabel={false}
                            name={documentName}
                            disabled
                            id={workflowDocumentId}
                          />
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    roleId && roleId !== 3
                    && isThisSubStageCurrentlyInProgress(eachSubStage, currentSubStageInProgress)
                      ? (
                        <div className="ml-lg-2">
                          <Button onClick={() => this.handleDeleteWorkflowDocumentById(workflowDocumentId)} kind="danger">Delete</Button>
                        </div>
                      ) : null
                  }
                  {
                    roleId && roleId === 3 && (
                      <div style={{ minWidth: '75px' }} className="h6 mt-2 font-weight-bold">
                        {documentName}:
                      </div>
                    )
                  }
                  {
                    roleId === 3 && !uploadLocation && (
                      <div className="ml-2">
                        <input
                          type="file"
                          className="bx--file-input"
                          id={`upload-${workflowDocumentId}`}
                          name={`upload-${workflowDocumentId}`}
                          accept=".doc,.docx,.pdf"
                          onChange={(e) => this.handleUploadFile(e, workflowDocumentId, appStageUserMapId, name)}
                        />
                        <div className="bx--file h-auto flatpickr-innerContainer mx-auto text-center newjob-upload-jd">
                          <label
                            htmlFor={`upload-${workflowDocumentId}`}
                            className="bx--file-btn bx--btn bx--btn--primary uploadFile"
                            role="button"
                            id={`upload-${workflowDocumentId}`}
                          >
                            Upload File
                            <input
                              type="file"
                              className="bx--file-input"
                              id={`upload-${workflowDocumentId}`}
                              name={`upload-${workflowDocumentId}`}
                              accept=".doc,.docx,.pdf"
                              onChange={(e) => this.handleUploadFile(e, workflowDocumentId, appStageUserMapId, name)}
                              />
                          </label>
                        </div>
                      </div>
                    )
                  }
                  {
                    uploadLocation && (
                      <div className="ml-2 mt-2">
                        <a target="_blank" href={uploadLocation}>View File</a>
                      </div>
                    )
                  }
                </div>
              );
            })
        }
        {
          inputValues.map((vals, index) => {
            return (
              <div className="d-lg-flex">
                <div>
                  <Select
                    id="email-templates-list"
                    defaultValue="placeholder-item"
                    hideLabel
                    className="w-100 docs-min-width mb-2"
                    onChange={(e) => { this.handleChange(e, index); }}
                    name="documentType"
                    invalid={isValidSelectDropDown}
                    invalidText={isValidSelectDropDownText}
                  >
                    <SelectItem
                      key="id"
                      label="Select one option"
                      disabled
                      value="placeholder-item"
                    />
                    {
                      documentTypes && Array.isArray(documentTypes)
                        && documentTypes.length > 0
                        && documentTypes.map(res => (
                          <SelectItem
                            key={res.workflowDocumentTypeId}
                            label={res && res.name}
                            value={res && res.name}
                          />
                        ))
                    }
                  </Select>
                </div>
                <div className="ml-lg-2">
                  <TextInput
                    className="mb-2"
                    id={`textInput_${index}`}
                    value={this.state[`textInput_${index}`]}
                    placeholder="name of document"
                    hideLabel={false}
                    name={`textInput_${index}`}
                    onChange={(e) => { this.handleChangeInputValues(e, index); }}
                    invalid={isValidTextInput}
                    invalidText={isValidTextInputContent}
                  />
                </div>
                <div className="ml-lg-2">
                  <Button onClick={() => this.handleDeleteInputValues(index)} kind="danger">Delete</Button>
                </div>
                {
                  inputValues && Array.isArray(inputValues)
                    && inputValues.length > 0
                    && (
                      <div className="ml-lg-2">
                        <Button onClick={this.handleSubmitWorkflowDocuments} kind="primary">Submit</Button>
                      </div>
                    )
                }
              </div>
            );
          })
        }
        {
          (isThisSubStageCurrentlyInProgress(eachSubStage, currentSubStageInProgress))
            && (
              <div className="d-flex">
              {
                inputValues && Array.isArray(inputValues)
                  && inputValues.length <= 9
                  && roleId !== 3
                  && (
                    <Button
                      kind="tertiary"
                      small
                      onClick={this.handleAddInputValues}
                      disabled={inputValues.length === 1}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-plus-circle">
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="16" />
                        <line x1="8" y1="12" x2="16" y2="12" />
                      </svg>
                      <span className="ml-1">Add</span>
                    </Button>
                  )
              }
              {
                eachSubStage && eachSubStage.workflowdocuments
                && Array.isArray(eachSubStage.workflowdocuments)
                && eachSubStage.workflowdocuments.length > 0
                && roleId !== 3
                && (
                  <div className="ml-2">
                    <Button
                      onClick={() => handleOpenEmailModal({
                        name: 'recruiter-sends-document-details',
                        template: 'recruiter-sends-document-details',
                        source: applicationSourceName,
                        workflowdocuments: eachSubStage.workflowdocuments,
                        appId,
                        jobId,
                      })}
                      small
                      kind="tertiary"
                    >
                      Send Email
                    </Button>
                  </div>
                )
              }
            </div>
            )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  documentTypes: state.DocumentsUpload.documentTypes,
});

const mapDispatchToProps = {
  uploadDocs,
  getWorkflowDocumentTypes,
  createWorkflowDocuments,
  getApplicationWorkflow,
  deleteWorkflowDocumentByID,
  updateWorkflowDocumentsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsUpload);
