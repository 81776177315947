import {
  REQUEST_REFERENCE_INVITES,
  SUCCESS_REFERENCE_INVITES,
  ERROR_REFERENCE_INVITES,
  REQUEST_GET_REFERENCES,
  SUCCESS_GET_REFERENCES,
  ERROR_GET_REFERENCES,
  REQUEST_ADD_REFERENCES,
  SUCCESS_ADD_REFERENCES,
  ERROR_ADD_REFERENCES,
  REQUEST_GET_REFERENCE,
  SUCCESS_GET_REFERENCE,
  ERROR_GET_REFERENCE,
  REQUEST_USER_REFERENCES,
  SUCCESS_USER_REFERENCES,
  ERROR_USER_REFERENCES,
  SUCCESS_GET_FEEDBACKGIVEN,
  ERROR_GET_FEEDBACKGIVEN,
} from '../../actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  references: [],
  response: [],
  companies: [],
  questions: [],
  referrer: {},
  userReferences: {},
};

const candidateReferences = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_REFERENCE_INVITES:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case SUCCESS_REFERENCE_INVITES:
      return {
        ...state,
        questions: payload,
        loading: false,
        error: null,
      };
    case ERROR_REFERENCE_INVITES:
      return {
        ...state,
        questions: [],
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_REFERENCES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_REFERENCES:
      return {
        ...state,
        references: payload && payload.references?payload.references:payload,
        response: payload && payload.response,
        companies: payload && payload.companies||[],
        loading: false,
        error: null,
      };
    case ERROR_GET_REFERENCES:
      return {
        ...state,
        references: [],
        companies: [],
        loading: false,
        error: payload.error,
      };
    case SUCCESS_GET_FEEDBACKGIVEN:
      return {
        ...state,
        references:payload && payload.references?payload.references:payload,
        loading:false,
        error:null,
      }
    case ERROR_GET_FEEDBACKGIVEN:
      return {
        ...state,
        references:[],
        loading:false,
        error:payload.error
      }
    case REQUEST_ADD_REFERENCES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ADD_REFERENCES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ADD_REFERENCES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_REFERENCE:
      return {
        ...state,
        referrer: {},
        loading: true,
        error: null,
      };
    case SUCCESS_GET_REFERENCE:
      return {
        ...state,
        referrer: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_REFERENCE:
      return {
        ...state,
        referrer: {},
        loading: false,
        error: payload.error,
      };
    case REQUEST_USER_REFERENCES:
      return {
        ...state,
        userReferences: {},
        loading: true,
        error: null,
      };
    case SUCCESS_USER_REFERENCES:
      return {
        ...state,
        userReferences: payload,
        loading: false,
        error: null,
      };
    case ERROR_USER_REFERENCES:
      return {
        ...state,
        userReferences: {},
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default candidateReferences;
