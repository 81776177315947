import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Button, InlineNotification, InlineLoading,
} from 'carbon-components-react';
import TimezonesList from 'components/common/TimezonesList';

import {
  updateTimezone,
} from 'actions/userProfile/action';

import {
  requestCfUserProfiles,
  revokeCfUserProfile,
  requestCfUserCalendars,
  refreshCfUserCalendars,
} from 'actions/cronofy';

import { CRONOFY_CLIENT_ID_STAGING, CRONOFY_CLIENT_ID_LIVE } from 'containers/common/constants';

class CalendarSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifMessage: '',
      notifShow: false,
      notifType: 'success',
      loadingRefresh: false,
      isRevoking: false,
    };
  }

  componentDidMount() {
    // this.props.requestCfUserCalendars();
    this.refreshCalendars();
  }

  authorizeCalendar = () => {
    const { institutionId } = this.props;
    let connectorId = institutionId;
    if (!connectorId) {
      connectorId = 'default';
    }
    let host = window.location.host;
    let clientId = CRONOFY_CLIENT_ID_STAGING;
    if (host.includes('campus.x0pa') || host.includes('geip.x0pa')) {
      clientId = CRONOFY_CLIENT_ID_LIVE;
    }
    const redirectUri = `${window.location.origin}/cronofy/cb`;
    const authHref = `https://app.cronofy.com/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=read_write&state=${connectorId}&avoid_linking=true`;
    const wwidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const wheight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.height;
    const wleft = ((wwidth / 2) - 400);
    const wtop = ((wheight / 2) - 300);
    const winFeatures = `width=800,height=600,resizable,scrollbars,status,top=${wtop},left=${wleft}`;
    window.open(authHref, 'authCalendar', winFeatures);
  }

  revokeProfile = (calProfileId) => {
    const { institutionId } = this.props;
    this.setState({
      isRevoking: true,
    }, () => {
      this.props.revokeCfUserProfile(calProfileId, institutionId || 0)
        .then((res) => {
          if (res && !res.error) {
            this.setState({
              isRevoking: false,
              notifShow: true,
              notifMessage: 'Successfully revoked the calendar access.',
              notifType: 'success',
            });
            this.refreshCalendars();
          } else {
            this.setState({
              isRevoking: false,
              notifShow: true,
              notifMessage: 'Error occured while revoking the calendar access.',
              notifType: 'error',
            });
          }
        })
        .catch(() => {
          this.setState({
            isRevoking: false,
            notifShow: true,
            notifMessage: 'Error occured while revoking the calendar access.',
            notifType: 'error',
          });
        });
    });
  }

  onTimezoneChange = (ev) => {
    const {
      user: {
        userId, roleId,
      } = {},
    } = this.props;
    const data = {
      userId,
      roleId,
      tzid: ev.target.value,
    };
    this.props.updateTimezone(data)
      .then((res) => {
        if (res && !res.error) {
          this.setState({
            notifMessage: 'Your timezone is set successfully.',
            notifShow: true,
            notifType: 'success',
          });
        } else {
          this.setState({
            notifMessage: 'Error occured.',
            notifShow: true,
            notifType: 'error',
          });
        }
      })
      .catch(() => {
        this.setState({
          notifMessage: 'Error occured.',
          notifShow: true,
          notifType: 'error',
        });
      });
  }

  refreshCalendars = () => {
    const { institutionId } = this.props;
    this.setState({
      loadingRefresh: true,
    });
    this.props.refreshCfUserCalendars(institutionId || 0)
      .then((res) => {
        if (res && !res.error) {
          this.props.requestCfUserCalendars(institutionId || 0);
          this.setState({
            notifMessage: 'Refreshed calendars successfully.',
            // notifShow: true,
            notifType: 'success',
            loadingRefresh: false,
          });
        } else {
          this.setState({
            notifMessage: 'Error occured while refreshing calendars.',
            notifShow: true,
            notifType: 'error',
            loadingRefresh: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          notifMessage: 'Error occured while refreshing calendars.',
          notifShow: true,
          notifType: 'error',
        });
      });
  }

  render() {
    const {
      user: {
        tzid,
      } = {},
      calendars: allCalendars = [],
      institutionId,
    } = this.props;
    const {
      notifMessage, notifShow, notifType,
      loadingRefresh, isRevoking,
    } = this.state;
    let calendars = [];
    if (Array.isArray(allCalendars)) {
      if (institutionId) {
        calendars = allCalendars.filter(e => e.connectorId != 0);
      } else {
        calendars = allCalendars.filter(e => e.connectorId == 0);
      }
    }
    return (
      <div className="view-profile">
        {
          notifShow && (
            <InlineNotification lowContrast
              kind={notifType}
              title={notifMessage}
              subtitle=""
              onCloseButtonClick={() => this.setState({ notifShow: false })}
            />
          )
        }
        <Tile className="mt-2">
          <div className="mb-4">
            <TimezonesList
              onChangeHandler={this.onTimezoneChange}
              tzSelected={tzid || 'default-tzid'}
            />
          </div>
          { loadingRefresh && (<div>Refreshing... <InlineLoading /></div>)}
          {
            Array.isArray(calendars) && calendars.map((p) => {
              const {
                calendar = [],
              } = p || {};
              const calRow = Array.isArray(calendar) && calendar.map((c) => {
                const {
                  calendar_name: calendarName,
                  provider_name: providerName,
                  calendar_id: cfCalendarId,
                  profile_id: cfProfileId,
                } = c || {};
                return (
                  <Fragment key={cfCalendarId}>
                    <div className="pt-2 pb-4">
                      <span className="">
                        {calendarName} <span className="text-dark text-capitalize">({providerName})</span>
                      </span>
                      {
                        !isRevoking && (
                          <Button
                            small
                            kind="danger"
                            onClick={() => this.revokeProfile(cfProfileId)}
                            className="ml-2"
                          >
                            Revoke
                          </Button>)
                      }
                      {
                        isRevoking && (
                          <Button
                            small
                            kind="primary"
                            className="ml-2"
                          >
                            <InlineLoading className="justify-content-center x-btn-loader" description="Revoking..." />
                          </Button>
                        )
                      }
                    </div>
                  </Fragment>);
              });
              return (
                <Fragment>
                  <hr />
                  {
                    calendar && Array.isArray(calendar) && (calendar.length > 0) && (
                      <div className="bx--type-epsilon">Connected calendars</div>)
                  }
                  {
                    calendar && Array.isArray(calendar) && (calendar.length === 0) && (
                      <Fragment>
                        <p className="text-danger">There are no calendars currently associated with this account.</p>
                        <Button disabled={!tzid} onClick={this.authorizeCalendar}>
                          Connect your calendar
                        </Button>
                        {/* {
                          !tzid && (
                            <p className="mt-4 bx--type-strong">
                              You need to set timezone before connecting a calendar.
                            </p>)
                        } */}
                      </Fragment>
                    )
                  }
                  {calRow}
                  {/* {
                    loadingRefresh && Array.isArray(calendar) && (calendar.length > 0) && (
                      <Button>
                        Refreshing Calendars...
                      </Button>
                    )
                  }
                  {
                    !loadingRefresh && Array.isArray(calendar) && (calendar.length > 0) && (
                      <Button
                        disabled={loadingRefresh}
                        onClick={this.refreshCalendars}
                      >
                        Refresh Calendars
                      </Button>
                    )
                  } */}
                </Fragment>
              );
            })
          }
          {
            !loadingRefresh && calendars && Array.isArray(calendars) && (calendars.length === 0) && (
              <Fragment>
                <p className="bx--type-epsilon text-danger">There are no calendars currently associated with this account.</p>
                <Button disabled={!tzid} onClick={this.authorizeCalendar}>
                  Connect your calendar
                </Button>
                {
                  !tzid && (
                    <p className="mt-4 bx--type-strong">
                      You need to set timezone before connecting a calendar.
                    </p>)
                }
              </Fragment>
            )
          }
        </Tile>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
  cfProfiles: state.cronofy.profiles,
  calendars: state.cronofy.calendars,
});

const mapDispatchToProps = {
  requestCfUserProfiles,
  revokeCfUserProfile,
  requestCfUserCalendars,
  updateTimezone,
  refreshCfUserCalendars,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSettings);
