import {
  REQUEST_GET_STUDENT_JOB_MATCHES,
  SUCCESS_GET_STUDENT_JOB_MATCHES,
  ERROR_GET_STUDENT_JOB_MATCHES,
  REQUEST_UPDATE_STUDENT_JOB_MATCHES,
  SUCCESS_UPDATE_STUDENT_JOB_MATCHES,
  ERROR_UPDATE_STUDENT_JOB_MATCHES,
  REQUEST_ADD_TO_JOB,
  SUCCESS_ADD_TO_JOB,
  ERROR_ADD_TO_JOB,
  REQUEST_GET_STUDENT_EMAILS,
  SUCCESS_GET_STUDENT_EMAILS,
  ERROR_GET_STUDENT_EMAILS,
  REQUEST_SET_STUDENT_HIRED,
  SUCCESS_SET_STUDENT_HIRED,
  ERROR_SET_STUDENT_HIRED,
  REQUEST_GET_INTERVIEW_FEEDBACK,
  ERROR_GET_INTERVIEW_FEEDBACK,
  SUCCESS_GET_INTERVIEW_FEEDBACK,
  REQUEST_CREATE_INTERVIEW_FEEDBACK,
  ERROR_CREATE_INTERVIEW_FEEDBACK,
  SUCCESS_CREATE_INTERVIEW_FEEDBACK,
  REQUEST_UPSERT_INTERVIEW_FEEDBACK,
  ERROR_UPSERT_INTERVIEW_FEEDBACK,
  SUCCESS_UPSERT_INTERVIEW_FEEDBACK,
} from 'actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  scores: [],
  count: 0,
  emails: [],
};

const StudentProfileView = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_GET_INTERVIEW_FEEDBACK:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_INTERVIEW_FEEDBACK:
      return {
        ...state,
        interviewFeedback: payload && payload.resp,
        loading: false,
        error: null,
      };
    case ERROR_GET_INTERVIEW_FEEDBACK:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_INTERVIEW_FEEDBACK:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_INTERVIEW_FEEDBACK:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_INTERVIEW_FEEDBACK:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
      case REQUEST_UPSERT_INTERVIEW_FEEDBACK:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case SUCCESS_UPSERT_INTERVIEW_FEEDBACK:
        return {
          ...state,
          loading: false,
          error: null,
        };
      case ERROR_UPSERT_INTERVIEW_FEEDBACK:
        return {
          ...state,
          loading: false,
          error: payload.error,
        };
    case REQUEST_GET_STUDENT_JOB_MATCHES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STUDENT_JOB_MATCHES:
      return {
        ...state,
        scores: payload && payload.scores,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_GET_STUDENT_JOB_MATCHES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_STUDENT_JOB_MATCHES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_STUDENT_JOB_MATCHES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPDATE_STUDENT_JOB_MATCHES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ADD_TO_JOB:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ADD_TO_JOB:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ADD_TO_JOB:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_STUDENT_EMAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STUDENT_EMAILS:
      return {
        ...state,
        emails: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_STUDENT_EMAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SET_STUDENT_HIRED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SET_STUDENT_HIRED:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SET_STUDENT_HIRED:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default StudentProfileView;
