import axios from 'axios';

import { get, post } from 'actions/api';
import { baseUrl, flaskUrl } from 'utils/config';

const api = axios.create({
  baseURL: baseUrl,
  timeout: 1000,
  withCredentials: true,
  // headers: {'X-Custom-Header': 'foobar'}
});

// http://localhost:5000/roboroy
const uploadApi = axios.create({
  baseURL: `${flaskUrl}/roboroy`,
  timeout: 60000,
  withCredentials: true,
  headers: {
    'content-type': 'multipart/form-data',
  },
});

export const fetchAllSkills = () => get('api/skills');

export const createSkill = skill => post('api/skills', skill);

export const parseJd = data => post('roboroy/jd_parse', data);

// dropdowns endpoint http://localhost:4545/api/Jobs/metaInformation
export const fetchDropdownData = () => get('api/jobs/meta');

export const saveJob = data => post('api/Jobs', data);
export const getJobTitles = () => get('api/Jobnames');
