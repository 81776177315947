import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_USER_REGISTER,
  REQUEST_CHECK_USER,
} from '../actionTypes';


const createUser = data => (dispatch) => {
  const {
    username, password, firstname, lastname,
  } = data;
  if (!username || !password || !firstname || !lastname) {
    const err = {
      error: {
        message: 'Missing required data',
      },
    };
    return Promise.resolve(err);
  }
  const url = `${baseUrl}/userinfos`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_USER_REGISTER,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  };
  return callApi(params);
};

const validateUser = pdata => (dispatch) => {
  const {
    data,
  } = pdata || {};
  if (!data) {
    const err = {
      error: {
        message: 'Missing required data',
      },
    };
    return Promise.resolve(err);
  }
  const url = `${baseUrl}/userinviteemailmap/invite`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_CHECK_USER,
    body: JSON.stringify(pdata),
    headers: {
      'content-type': 'application/json',
    },
  };
  return callApi(params);
};

export {
  createUser,
  validateUser,
};
