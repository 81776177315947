import { withTranslation } from 'react-i18next';
import React from 'react';
import { Modal } from 'carbon-components-react';
import { TalentPoolBuckets } from './TalentPoolBuckets';

const getModalHeading = (actionType, t) => {
  let value = '';
  switch (actionType) {
    case 'shortlist':
      value = `${t('shortlist')} ${t('applicant')}`;
      break;
    case 'reject':
      value = `${t('reject')} ${t('applicant')}`;
      break;
    case 'talentpool':
      value = 'Add Applicant(s) to your talent pool';
      break;
    case 'is_liked':
      value = `${t('addToFavHeading')}`;
      break;
    default:
      value = ' ';
  }
  return value;
};


const ApplicationBatchUpdateModal = ({
  actionType, onRequestClose, open, onRequestSubmit, buckets, handleChange, bucketName,
  isBucketNameValid, invalidText, handleChangeRadioButton, useBucket, t
}) => (
  <Modal
    onRequestSubmit={onRequestSubmit}
    onRequestClose={onRequestClose}
    open={open}
    modalLabel={t('action')}
    primaryButtonText={t('submit')}
    secondaryButtonText={t('cancel')}
    modalHeading={getModalHeading(actionType, t)}
  >
    <p className="bx--modal-content__text">
      <strong className="text-dark">
        {t('areYouSureMsg')}
      </strong>
      {
          actionType
          && ((actionType == 'shortlist') || (actionType == 'reject'))
          && (
          <small className="pt-1 text-dark d-block">
              {actionType && (actionType == 'shortlist') 
                          && (<span>{t('shortlistCandMsg1')} <strong>{t('view')} {t('interview')} {t('status')}</strong> {t('shortlistCandMsg2')}</span>)}
              {actionType && (actionType == 'reject') 
                          && (<span>{t('rejectCandidateMsg1')} <strong>{t('view')} {t('interview')} {t('status')}</strong> {t('rejectCandidateMsg2')}</span>)}
          </small>
          )
      }
      {/* {
          actionType
          && ((actionType == "talentpool"))
          && (
          <div>
            <small className="pt-1 text-dark d-block">
                On submitting this action, the selected applicant(s) will be saved in your talent pool.
                Talent pool is your
                personal repository of applicants that you can save and refer for future jobs.
            </small>
            <small className="mt-2 pt-1 text-dark d-block">
              Additionally you many choose to add these selected applicants to a particular talent pool bucket. Buckets are a way 
              of organizing and managing your talent pool candidates into various groups.
            </small>
            <TalentPoolBuckets
              handleChange={handleChange}
              handleChangeRadioButton={handleChangeRadioButton}
              useBucket={useBucket}
              buckets={buckets}
              bucketName={bucketName}
              isBucketNameValid={isBucketNameValid}
              invalidText={invalidText}
            />
          </div>
          )
      } */}
      {
          actionType
          && ((actionType == 'is_liked'))
          && (
            <small className="pt-1 text-dark d-block">
              {t('addToFavMsg')}
            </small>
          )
      }
    </p>
  </Modal>
);

const ApplicationBatchUpdateModalTranslate = (withTranslation()(ApplicationBatchUpdateModal));

export { ApplicationBatchUpdateModal, ApplicationBatchUpdateModalTranslate };
