import {
  REQUEST_CANDIDATE_SHORTLIST_UPDATE,
  ERROR_CANDIDATE_SHORTLIST_UPDATE,
  SUCCESS_CANDIDATE_SHORTLIST_UPDATE,
  REQUEST_CANDIDATE_CONTENT,
  ERROR_CANDIDATE_CONTENT,
  SUCCESS_CANDIDATE_CONTENT,
  REQUEST_ALLCANDIDATE_CONTENT,
  ERROR_ALLCANDIDATE_CONTENT,
  SUCCESS_ALLCANDIDATE_CONTENT,
  REQUEST_TALENTPOOL_SEARCH,
  ERROR_TALENTPOOL_SEARCH,
  SUCCESS_TALENTPOOL_SEARCH,
  REQUEST_BULK_SHORTLIST_REJECT,
  ERROR_BULK_SHORTLIST_REJECT,
  SUCCESS_BULK_SHORTLIST_REJECT,
  REQUEST_BULK_LIKE,
  SUCCESS_BULK_LIKE,
  ERROR_BULK_LIKE,
  REQUEST_REFERSH_SCORES,
  SUCCESS_REFERSH_SCORES,
  ERROR_REFERSH_SCORES,
  REQUEST_APPLICATION_SHARE,
  SUCCESS_APPLICATION_SHARE,
  ERROR_APPLICATION_SHARE,
  REQUEST_OTHER_JOBS,
  SUCCESS_OTHER_JOBS,
  ERROR_OTHER_JOBS,
  REQUEST_CANDIDATE_PROFILE,
  SUCCESS_CANDIDATE_PROFILE,
  ERROR_CANDIDATE_PROFILE,
  REQUEST_EDIT_CANDIDATE_PROFILE,
  SUCCESS_EDIT_CANDIDATE_PROFILE,
  ERROR_EDIT_CANDIDATE_PROFILE,
  REQUEST_ASSET_ANALYTICS,
  SUCCESS_ASSET_ANALYTICS,
  ERROR_ASSET_ANALYTICS,
  REQUEST_DELETE_CANDIDATE,
  ERROR_DELETE_CANDIDATE,
  SUCCESS_DELETE_CANDIDATE,
  REQUEST_DOWNLOAD_CANDIDATE,
  ERROR_DOWNLOAD_CANDIDATE,
  SUCCESS_DOWNLOAD_CANDIDATE,
  REQUEST_DELETE_APPLICANT_INFO,
  SUCCESS_DELETE_APPLICANT_INFO,
  ERROR_DELETE_APPLICANT_INFO,
  REQUEST_UPDATE_PLACEMENT,
  ERROR_UPDATE_PLACEMENT,
  SUCCESS_UPDATE_PLACEMENT
} from '../../actions/talentSearch/actionTypes';

import {
  REQUEST_COMPANIES_DATA,
  SUCCESS_COMPANIES_DATA,
  ERROR_COMPANIES_DATA,
  REQUEST_GET_JOBS_APPLIED_COUNT,
  SUCCESS_GET_JOBS_APPLIED_COUNT,
  ERROR_GET_JOBS_APPLIED_COUNT,
} from '../../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  applications: [],
  allApplications: [],
  count: 0,
  otherJobsApplied: [],
  candidateProfile: {},
  countries: [],
  timeLine: [],
  profile: {},
  totalCount: 0,
  jobsAppliedCount: 0,
  offerAcceptedCount: 0,
};

const applicationData = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_CANDIDATE_SHORTLIST_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
        talentSearchComplete: false,
      };
    case ERROR_CANDIDATE_SHORTLIST_UPDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
        talentSearchComplete: true,
      };
    case SUCCESS_CANDIDATE_SHORTLIST_UPDATE:
      return {
        ...state,
        loading: false,
        error: null,
        talentSearchComplete: true,
      };
    case REQUEST_CANDIDATE_CONTENT:
      return {
        ...state,
        loading: true,
        error: null,
        talentSearchComplete: false,
      };
    case ERROR_CANDIDATE_CONTENT:
      return {
        ...state,
        applications: [],
        jobInfo: {},
        loading: false,
        error: payload.error,
        talentSearchComplete: false,
      };
    case SUCCESS_CANDIDATE_CONTENT:
      return {
        ...state,
        ...payload,
        loading: false,
        error: null,
        talentSearchComplete: false,
      };
    case REQUEST_ALLCANDIDATE_CONTENT:
      return {
        ...state,
        loading: true,
        error: null,
        talentSearchComplete: false,
      };
    case ERROR_ALLCANDIDATE_CONTENT:
      return {
        ...state,
        applications: [],
        jobInfo: {},
        loading: false,
        error: payload.error,
        talentSearchComplete: false,
      };
    case SUCCESS_ALLCANDIDATE_CONTENT:
      return {
        ...state,
        allApplications: payload,
        loading: false,
        error: null,
        talentSearchComplete: false,
      };
    case REQUEST_DELETE_APPLICANT_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ERROR_DELETE_APPLICANT_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case SUCCESS_DELETE_APPLICANT_INFO:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case REQUEST_TALENTPOOL_SEARCH:
      return {
        ...state,
        loading: true,
        error: null,
        talentSearchComplete: false,
      };
    case ERROR_TALENTPOOL_SEARCH:
      return {
        ...state,
        applications: [],
        loading: false,
        error: payload.error,
        talentSearchComplete: true,
      };
    case SUCCESS_TALENTPOOL_SEARCH:
      return {
        ...state,
        loading: false,
        error: null,
        talentSearchPayload: payload,
        talentSearchComplete: true,
      };
    case REQUEST_BULK_SHORTLIST_REJECT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ERROR_BULK_SHORTLIST_REJECT:
      return {
        ...state,
        applications: [],
        loading: false,
        error: payload.error,
      };
    case SUCCESS_BULK_SHORTLIST_REJECT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case REQUEST_BULK_LIKE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_BULK_LIKE:
      return {
        ...state,
        loading: false,
        error: null,
      };
      case ERROR_BULK_LIKE:
      return {
        ...state,
        applications: [],
        loading: false,
        error: payload.error,
      };
    case REQUEST_REFERSH_SCORES: 
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_REFERSH_SCORES:
      return {
        ...state,
        refreshscoreid: payload && payload.task_id,
        loading: false,
        error: null,
      };
    case ERROR_REFERSH_SCORES: 
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_APPLICATION_SHARE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_APPLICATION_SHARE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_APPLICATION_SHARE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_OTHER_JOBS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_OTHER_JOBS:
      return {
        ...state,
        otherJobsApplied: (payload && payload.otherJobApplications && payload.otherJobApplications.applicationWorkFlowArray) || [],
        totalCount: (payload && payload.otherJobApplications && payload.otherJobApplications.totalCount) || 0,
        loading: false,
        error: null,
      };
    case ERROR_OTHER_JOBS:
      return {
        ...state,
        otherJobsApplied: [],
        loading: false,
        error: payload.error,
      };
    case REQUEST_CANDIDATE_PROFILE:
      return {
        ...state,
        isProfileLoaded: false,        
        loading: true,
        error: null,
      };
    case SUCCESS_CANDIDATE_PROFILE:
      return {
        ...state,
        candidateProfile: payload,
        profile: (payload && payload.profile) || {},
        isProfileLoaded: true,
        loading: false,
        error: null,
      };
    case ERROR_CANDIDATE_PROFILE:
      return {
        ...state,
        candidateProfile: [],
        loading: false,
        error: payload.error,
      };
    case REQUEST_EDIT_CANDIDATE_PROFILE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_EDIT_CANDIDATE_PROFILE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_EDIT_CANDIDATE_PROFILE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_COMPANIES_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_COMPANIES_DATA:
      return {
        ...state,
        countries: payload,
        loading: false,
        error: null,
      };
    case ERROR_COMPANIES_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ASSET_ANALYTICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ASSET_ANALYTICS:
      return {
        ...state,
        timeLine: (payload && payload.timeLine) || [],
        loading: false,
        error: null,
      };
    case ERROR_ASSET_ANALYTICS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
      case REQUEST_DELETE_CANDIDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DOWNLOAD_CANDIDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DOWNLOAD_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DOWNLOAD_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_JOBS_APPLIED_COUNT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_JOBS_APPLIED_COUNT:
      return {
        ...state,
        jobsAppliedCount: payload && payload.count,
        offerAcceptedCount: payload && payload.offersAccepted,
        loading: false,
        error: null,
      };
    case ERROR_GET_JOBS_APPLIED_COUNT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_PLACEMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ERROR_UPDATE_PLACEMENT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case SUCCESS_UPDATE_PLACEMENT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default applicationData;
