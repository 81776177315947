import { LANGUAGE_CHANGE } from './actionTypes';

const languageChange = language => (dispatch) => {
  dispatch({
    type: LANGUAGE_CHANGE,
    payload: language,
  });
};

export default languageChange;
