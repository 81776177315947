import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Tile } from 'carbon-components-react';
import SetMixPanel from 'components/common/GetCurrentPath';
import './BackRow.css';

const finalRow = (i, paths) => i === paths.length - 1;

const BackRow = ({ showGuidedTour, handleGuidedTourClick, showBackButton, handleBackClick, paths, history: { push } }) => (
  <Fragment>
  <Breadcrumb className="xpa-c-backrow">
      {showBackButton && (
        <BreadcrumbItem className="text-uppercase" onClick={handleBackClick} href="javascript:void(0)">
          <span>&#171;&nbsp;back to previous page</span>
        </BreadcrumbItem>
      )}
      {paths && paths.map((path, i) => (
        !finalRow(i, paths) ? (
          <BreadcrumbItem key={path.id} className="backrow-name text-uppercase" href="javascript:void(0)" onClick={() => {
            SetMixPanel(`BREADCRUMBITEM_${(path.name)}_CLICKED`);
            if (!finalRow(i, paths)) push(path.href);
          }}>
            <span className="xpa-link">
              {path.name}
            </span>
          </BreadcrumbItem>
        )
          : (
            <span className="d-flex xpa-c-backrow--item" key={path.id}>
              <strong
                key={path.id}
                onClick={!finalRow(i, paths) ? () => push(path.href) : f => f}
                className="text-uppercase xpa-color--gray03">
                {path.name}
              </strong>&nbsp;
              {/* {showGuidedTour && (
                <span className="d-none d-lg-block">
                  (
                    <span>
                      <a href="javascript:void(0);" onClick={handleGuidedTourClick}>
                        <span
                          className="text-uppercase ml-1 mr-1 ">
                          Guided Tour
                        </span>
                      </a>
                    </span>
                  )
                </span>
              )} */}
            </span>
          )
      ))}
    </Breadcrumb>
    <hr className="mt-2 mb-2 ml-0 mr-0"/>
  </Fragment>  
);

export default withRouter(BackRow);
