import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  Pagination,
  div,
  InlineLoading,
  InlineNotification,
  Loading,
} from 'carbon-components-react';
import StarRatingComponent from 'react-star-rating-component';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Progress } from 'react-sweet-progress';
import { sendEmails } from 'actions/talentSearch/talentPool';
import './internCandidate.css';

import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import Preferences from 'containers/robocampus/Preferences';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import Toast from 'components/common/Toast';
import 'react-sweet-progress/lib/style.css';
import UploadAllDocuments from 'containers/common/uploadAllDocuments';

import {
  REQUEST_ALL_SKILLS,
  REQUEST_CREATE_NEW_SKILLS,
  REQUEST_ALL_LANGUAGES,
  REQUEST_ALL_LOCATIONS,
} from 'actions/talentSearch/actionTypes';

import PercentageSlider from 'components/common/PercentageSlider';

import { manageSkill } from 'actions/candidate/CandidateDetails';
import CandidateDetails from 'components/roboroy/CandidateDetails';

import {
  getCandidateProfile,
  downloadCandidateProfile,
} from 'actions/talentSearch/candidatesList';

import { updatePersonSkill } from 'actions/candidate/businessFinland';

import {
  parseJd,
  fetchDropdownData,
  associateJd,
  fetchAll,
  createNewOption,
} from 'actions/talentSearch/newJob';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  updateStudentProfile,
  getStudentProfile,
  createStudentLanguages,
  updateStudentLanguages,
  deleteStudentLanguages,
  createCityPerference,
  updateCityPerference,
  deleteCityPerference,
  createIndustryPreferences,
  updateIndustryPreferences,
  deleteIndustryPreferences,
  createFunctionPreferences,
  updateFunctionPreferences,
  deleteFunctionPreferences,
  createStudentPreference,
  updateStudentPreference,
  deleteStudentPreference,
  createSkillsLearnsForStds,
  deleteSkillsLearnsForStdsById,
  getSkillsLearnsForStds,
  getSkillsPreferredList,
} from 'actions/campus/internCandidate';

import { getAvailabilitySlots } from 'actions/workflow';
import { createCalendarEvent } from 'actions/calendarEvents';

import ProfileInfo from '../common/profile/ProfileInfo';

import {
  // getAllCountries,
  getAllIndustries,
} from '../../actions/admin/CompanyAndOffice';

const jobPrefs = {
  cityRank: 'City Preference',
  industryRank: 'Industry Preference',
  individualRank: 'Individual Preference',
  jobFunctionRank: 'Job Function Preference',
};

class InternCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stageIndex: 0,
      selectedSkills: [],
      showAddSkill: false,
      rowsData: [],
      culture: '',
      skill1: null,
      skill2: null,
      skill3: null,
      preferenceTaskOriented: 50,
      preferenceBackoffice: 50,
      preferenceTryNew: 50,
      activePage: 0,
      limit: 10,
      activeModal: false,
      uploadResumeClicked: false,
      selectedLanguages: [],
      IndustryPreferences: [],
      companyTypes: [],
      jobPreferences: [],
      jobFunctionPreferences: [],
      cities: [],
      stages: 8,
      langsData: [],
      langRating: [],
      activeLangPage: 1,
      langLimit: 10,
      showAddLang: false,
      langValue: {},
      langType: {},
      rating: 0,
      taskId: '',
      isResumeUploaded: true,
      uploadComplete: false,
      showError: false,
      showStudentLearnsErr: false,
      isOpenSkillsLearnModal: false,
      skillsLearnArr: [],
      isOpenDeleteLearnModal: false,
      stuLearnId: 0,
      isScheduling: false,
      showNtwErr: false,
      errMsg: '',
    };
    this.notifRef = React.createRef();
  }

  componentDidMount() {
    this.handleState();
  }

  handleState = ()=>{
    const { 
      // match: { params: { stageIndex = 0 } = {} } = {}, 
      propStageIndex, 
      stageIndex 
    } = this.props;
    const preferredVal = true;
    this.props.getSkillsPreferredList(preferredVal).then((res) => {
      if (res && !res.error) {
        const { preferredSkills } = this.props;
        const options =
          preferredSkills &&
          Array.isArray(preferredSkills) &&
          preferredSkills.length > 0 &&
          preferredSkills.map((vals) => {
            const { skillId, skillName } = vals || {};
            const data = {
              label: skillName,
              value: skillId,
            };
            return data;
          });
        this.setState({
          skillsLearnArr: options,
        });
      }
    });
    if (stageIndex > this.state.stages) {
      this.props.history.push(`/app/campus/candidate/interncandidate/0`);
      stageIndex = 0;
    }
    this.setState({ stageIndex: propStageIndex>=0 ? Number(propStageIndex) : Number(stageIndex) }, () => {
      const {
        getCandidateProfile,
        user: {
          userId,
          // profileId = '',
          companyId: studentInstitutionId,
          email,
          firstname: firstName,
          lastname: lastName,
        } = {},
        profileId
      } = this.props;
      getCandidateProfile(Number(profileId)).then((res) => {
        this.handleValidationStages();
        const {
          skills = null,
          studentpreferences = null,
          languages = null,
          citypreferences = null,
          industrypreferences = null,
          jobfunctions = null,
          studentprofile: { studentProfileId } = {},
        } = res || {};
        this.props.getSkillsLearnsForStds(studentProfileId);
        // this.props.createStudentInterviewSlot()
        //   .then((res) => {
        //     const { eventId } = res || {};
        //     if(!eventId) {
        //       this.props.getAvailabilitySlots({
        //         connectorId: studentInstitutionId,
        //         users: [userId],
        //         profileId,
        //         appId: 0,
        //         appStageId: 0,
        //         summary: `Scheduled interview for ${firstName} ${lastName}`,
        //         candidateName: `${firstName} ${lastName}`,
        //         candidateEmail: email,
        //         jobName: 'N/A',
        //         companyName: 'N/A',
        //         isReschedule: false,
        //       }).then ((res) => {
        //         const { slots, elem_token, participantId } = res || {};
        //         const availablePeriods = [];
        //         if(slots && Array.isArray(slots)) {
        //           slots.forEach((e) => {
        //             const { start, end } = e || {};
        //             if(start && end) {
        //               availablePeriods.push({
        //                 start,
        //                 end,
        //               });
        //             }
        //           });
        //         }
        //         this.renderSlotPicker(elem_token, availablePeriods, participantId);
        //       })
        //       .catch(console.error);
        //     } else {
        //       this.setState({
        //         scheduledSlot: res,
        //       });
        //     }
        //   })
        //   .catch(console.error);

        const selectedSkills =
          Array.isArray(skills) &&
          skills.map(
            ({
              skill: { skillName = '' } = {},
              skillId = '',
              rating = null,
              personSkillId,
            }) => ({
              label: skillName,
              value: skillId,
              rating: rating !== null ? rating : 0,
              personSkillId,
            })
          );
        if (
          Array.isArray(studentpreferences) &&
          studentpreferences.length > 0
        ) {
          const {
            preferenceBackoffice,
            preferenceTaskOriented,
            preferenceTryNew,
            cityRank = null,
            individualRank = null,
            industryRank = null,
            jobFunctionRank = null,
          } = studentpreferences[0];
          const data = {
            cityRank,
            individualRank,
            industryRank,
            jobFunctionRank,
          };
          const sortedVals = Object.keys(data).sort(
            (a, b) => Number(data[a]) - Number(data[b])
          );
          const jobPreferences = sortedVals.map((one) => jobPrefs[one]);
          this.setState({
            preferenceBackoffice,
            preferenceTaskOriented,
            preferenceTryNew,
            jobPreferences,
          });
        }
        if (
          Array.isArray(industrypreferences) &&
          industrypreferences.length > 0
        ) {
          const IndustryPreferences = industrypreferences
            .filter(
              ({ industry: { industryName = '' } = {} } = {}) =>
                industryName !== null && industryName.length > 0
            )
            .map(
              ({ industry: { industryName = '' } = {} } = {}) => industryName
            );
          this.setState({ IndustryPreferences });
        }
        if (Array.isArray(jobfunctions) && jobfunctions.length > 0) {
          const jobFunctionPreferences = jobfunctions
            .filter(
              ({ jobfunction: { functionName = '' } = {} } = {}) =>
                functionName !== null && functionName.length > 0
            )
            .map(
              ({ jobfunction: { functionName = '' } = {} } = {}) => functionName
            );
          this.setState({ jobFunctionPreferences });
        }
        if (Array.isArray(citypreferences) && citypreferences.length > 0) {
          let cities = citypreferences.filter(
            (one) => one.locationId && one.locationId !== null
          );
          cities = cities.map(
            ({ location: { locationName = '' }, studentCityId, rank = 1 }) => ({
              label: locationName,
              value: studentCityId,
              rank: Number(rank),
            })
          );
          cities.sort(({ rank: one }, { rank: two }) => {
            if (one < two) return -1;
            if (one > two) return 1;
            return 0;
          });
          this.setState({ cities });
        }
        if (Array.isArray(languages) && languages.length > 0) {
          const selectedLanguages = languages.map(
            ({
              personLangId = null,
              rating = null,
              languagetype = {},
              language = {},
            }) => ({
              language: language.languageFull,
              rating,
              type: languagetype.langType,
              id: personLangId,
            })
          );
          this.setState({ selectedLanguages }, () => this.setLangs());
        }
        this.setState({ selectedSkills }, () => this.setRows());
      });
      const { neonPoly = false } = this.props;
      const stages = neonPoly ? 8 : 9;
      this.setState({ stages });
    });
  }

  handleValidationStages = () => {
    const {
      candidateProfile,
      history: { push } = {},
      match: { params: { stageIndex = 0 } = {} } = {},
    } = this.props;
    const { skills, studentlearns, industrypreferences, jobfunctions } =
      candidateProfile || {};
    let redirectId = 1;
    let shouldRedirect = false;
    if ((skills && Array.isArray(skills) && skills.length <= 0) || !skills) {
      shouldRedirect = true;
      redirectId = 1;
    } else if (
      (studentlearns &&
        Array.isArray(studentlearns) &&
        studentlearns.length <= 0) ||
      !studentlearns
    ) {
      shouldRedirect = true;
      redirectId = 3;
    } else if (
      (industrypreferences &&
        Array.isArray(industrypreferences) &&
        industrypreferences.length <= 0) ||
      !industrypreferences ||
      (jobfunctions &&
        Array.isArray(jobfunctions) &&
        jobfunctions.length <= 0) ||
      !jobfunctions
    ) {
      shouldRedirect = true;
      redirectId = 5;
    }
    if (
      shouldRedirect === true &&
      push &&
      stageIndex &&
      redirectId < stageIndex
    ) {
      push(`/app/campus/candidate/interncandidate/${redirectId}`);
      this.setState({
        stageIndex: redirectId,
      });
    }
  };

  onChangeEvent = (e) => {
    const {
      target: { value = '', name = '' },
    } = e || {};
    this.setState({
      [name]: value,
    });
  };

  activeSkillsLearnModal = () => {
    const { preferredSkills } = this.props;
    const options =
      preferredSkills &&
      Array.isArray(preferredSkills) &&
      preferredSkills.length > 0 &&
      preferredSkills.map((vals) => {
        const { skillId, skillName } = vals || {};
        const data = {
          label: skillName,
          value: skillId,
        };
        return data;
      });
    this.setState({
      isOpenSkillsLearnModal: true,
      skillsLearnArr: options,
    });
  };

  handleStudentLearnState = () => {
    const { preferredSkills } = this.props;
    const options =
      preferredSkills &&
      Array.isArray(preferredSkills) &&
      preferredSkills.length > 0 &&
      preferredSkills.map((vals) => {
        const { skillId, skillName } = vals || {};
        const data = {
          label: skillName,
          value: skillId,
        };
        return data;
      });
    this.setState({
      skillsLearnArr: options,
    });
  };

  dismissSkillsLearnModal = () => {
    this.setState({
      isOpenSkillsLearnModal: false,
      skillLearnType: {},
    });
  };

  activeDeleteModal = (stuLearnId) => {
    this.setState({
      isOpenDeleteLearnModal: true,
      stuLearnId,
    });
  };

  dismissDeleteModal = () => {
    this.setState({
      isOpenDeleteLearnModal: false,
      stuLearnId: 0,
    });
  };

  handleCreateLearningSkills = () => {
    const { t } = this.props;
    const { skillLearnType } = this.state;
    if (!skillLearnType) {
      this.setState(
        {
          showStudentLearnsErr: true,
        },
        () => {
          this.dismissSkillsLearnModal();
        }
      );
    } else {
      const { label } = skillLearnType || {};
      const {
        candidateProfile: {
          studentlearns,
          profile: { profileId } = {},
          studentprofile: { studentProfileId } = {},
        } = {},
      } = this.props;
      const data = {
        skillName: label,
        studentProfileId,
        studentChoice: studentlearns.length + 1,
      };
      this.props.createSkillsLearnsForStds(data).then((res) => {
        if (res && !res.error) {
          this.setState(
            {
              showStudentLearnsErr: false,
              showNtwErr: false,
              errMsg: '',
            },
            () => {
              this.props.getCandidateProfile(profileId);
              this.dismissSkillsLearnModal();
            }
          );
        } else if (res && res.error && res.error.message) {
          let errMsg = '';
          if (res.error && res.error.code === '23505') {
            errMsg = `${t('uniqueSkillErrMsg')}`;
          } else {
            errMsg = `${t('errorMsg')}`;
          }
          this.setState(
            {
              showNtwErr: true,
              errMsg,
            },
            () => {
              this.handleStudentLearnState();
            }
          );
        }
      });
    }
  };

  handleDeleteLearnSkills = () => {
    const { stuLearnId } = this.state;
    const {
      candidateProfile: {
        profile: { profileId } = {},
        studentprofile: { studentProfileId } = {},
      } = {},
    } = this.props;
    this.props
      .deleteSkillsLearnsForStdsById(studentProfileId, stuLearnId)
      .then((res) => {
        if (res && !res.error) {
          this.props.getCandidateProfile(profileId);
          this.dismissDeleteModal();
        }
      });
  };

  onNextStep = () => {
    const { stageIndex, stages } = this.state;

    console.log("stageIndex 547--->")
    console.log(stageIndex)
    const {
      user: { profileId = '' },
    } = this.props;
    if (stageIndex === 1) {
      const { candidateProfile: { skills } = {} } = this.props;
      if (!skills || (Array.isArray(skills) && skills.length <= 0)) {
        this.setState(
          {
            showError: true,
          },
          () => {
            const c =
              this.notifRef &&
              this.notifRef.current &&
              this.notifRef.current.focus &&
              this.notifRef.current.focus();
          }
        );
      } else {
        this.setState(
          {
            showError: false,
          },
          () => {
            const selectedSkills = skills.map((one) => {
              const { skill, personSkillId, rating = 0 } = one || {};
              const { skillId = null, skillName = '' } = skill || {};
              return {
                label: skillName,
                value: skillId,
                personSkillId,
                rating,
              };
            });
            this.setState(
              { selectedSkills, stageIndex: stageIndex + 1 },
              () => {
                // this.props.history.replace(
                //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
                // );

                const { push, profileId } = this.props || {};
                push(
                  `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex+3}`
                );
                this.setRows();
              }
            );
          }
        );
      }
    }
    if (stageIndex === 2) {
      this.setState({ stageIndex: stageIndex + 1 }, () => {
        // this.props.history.replace(
        //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
        // );
        const { push, profileId } = this.props || {};
        push(
          `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex+3}`
        );
      });
    }
    if (stageIndex === 3) {
      const { candidateProfile: { studentlearns } = {} } = this.props;
      if (
        studentlearns &&
        Array.isArray(studentlearns) &&
        studentlearns.length < 1
      ) {
        this.setState(
          {
            showStudentLearnsErr: true,
          },
          () => {
            const c =
              this.notifRef &&
              this.notifRef.current &&
              this.notifRef.current.focus &&
              this.notifRef.current.focus();
          }
        );
      } else if (
        studentlearns &&
        Array.isArray(studentlearns) &&
        studentlearns.length >= 1
      ) {
        this.setState(
          {
            showStudentLearnsErr: false,
          },
          () => {
            this.setState({ stageIndex: stageIndex + 1 }, () => {
              // this.props.history.replace(
              //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
              // );
              const { push, profileId } = this.props || {};
              push(
                `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex+3}`
              );
            });
          }
        );
      }
    }
    if (stageIndex === 5) {
      const {
        IndustryPreferences,
        jobPreferences,
        jobFunctionPreferences,
      } = this.state;
      const {
        candidateProfile: {
          studentprofile: { studentProfileId = '' } = {},
          studentpreferences = null,
        } = {},
      } = this.props;
      const dataInd =
        Array.isArray(IndustryPreferences) &&
        IndustryPreferences.map((industryName, i) => ({
          industryName,
          rank: i + 1,
        }));
      const dataFun =
        Array.isArray(jobFunctionPreferences) &&
        jobFunctionPreferences.map((functionName, i) => ({
          functionName,
          rank: i + 1,
        }));
      const {
        preferenceTryNew,
        preferenceTaskOriented,
        preferenceBackoffice,
      } = this.state;
      const dataJobs = {
        preferenceTryNew,
        preferenceTaskOriented,
        preferenceBackoffice,
      };
      if (
        !IndustryPreferences ||
        IndustryPreferences.length <= 0 ||
        !jobFunctionPreferences ||
        jobFunctionPreferences.length <= 0
      ) {
        this.setState(
          {
            showPrefError: true,
          },
          () => {
            const c =
              this.notifRef &&
              this.notifRef.current &&
              this.notifRef.current.focus &&
              this.notifRef.current.focus();
          }
        );
      } else {
        Array.isArray(jobPreferences) &&
          jobPreferences.forEach((name, i) => {
            const type = name
              .toLowerCase()
              .replace(/ /g, '')
              .replace('preference', 'Rank')
              .replace('function', 'Function');
            dataJobs[type] = i + 1;
          });
        const { studentPreferenceId = null } =
          Array.isArray(studentpreferences) && studentpreferences.length > 0
            ? studentpreferences[0]
            : {};
        this.setState(
          { showPrefError: false, stageIndex: stageIndex + 1 },
          () => {
            // this.props.history.replace(
            //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
            // );
            const { push, profileId } = this.props || {};
            push(
              `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex+3}`
            );
            this.props.createIndustryPreferences(dataInd, studentProfileId);
            this.props.createFunctionPreferences(dataFun, studentProfileId);
            if (studentPreferenceId !== null) {
              this.props.createStudentPreference(dataJobs, studentProfileId);
            } else
              this.props.createStudentPreference(dataJobs, studentProfileId);
          }
        );
      }
    }
    if (
      stageIndex > 0 &&
      stageIndex < stages - 1 &&
      stageIndex !== 6 &&
      stageIndex !== 5 &&
      stageIndex !== 3 &&
      stageIndex !== 1
    ) {
      this.setState({ stageIndex: stageIndex + 1 }, () => {
        // this.props.history.replace(
        //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
        // );
        const { push, profileId } = this.props || {};
        push(
          `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex+3}`
        );
      });
    }
  };

  onPrevStep = () => {
    const { stageIndex } = this.state;
    if (stageIndex > 0) {
      if (stageIndex === 4) {
        const {
          // user: { profileId = '' },
          profileId
        } = this.props;
        this.props.getCandidateProfile(Number(profileId)).then((res) => {
          const { candidateProfile: { skills = [] } = {} } = this.props;
          const selectedSkills = skills.map((one) => {
            const { skill, rating = 0, personSkillId } = one || {};
            const { skillId = null, skillName = '' } = skill || {};
            return {
              label: skillName,
              value: skillId,
              rating,
              personSkillId,
            };
          });
          this.setState({ selectedSkills }, () => {
            // this.props.history.replace(
            //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
            // );
            const { push } = this.props || {};
            push(
              `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex+3}`
            );
            this.setRows();
          });
        });
        this.setState({ stageIndex: stageIndex - 1 }, () => {
          // this.props.history.replace(
          //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
          // );
          const { push } = this.props || {};
          push(
            `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex+3}`
          );
        });
      } else {
        if (stageIndex === 4) this.setState({ activePage: 0, limit: 10 });
        this.setState({ stageIndex: stageIndex - 1 }, () => {
          // this.props.history.replace(
          //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
          // );
          const { push, profileId } = this.props || {};
          push(
            `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex+3}`
          );
        });
      }
    }
  };

  onSubmitInfo = () => {
    const { stageIndex } = this.state;
    const {
      candidateProfile: {
        studentprofile: { studentProfileId = '' } = {},
        studentpreferences = null,
      } = {},
      candidateProfile,
    } = this.props;
    const data = {
      isAppSubmitted: true,
    };
    const { profile: { firstName, lastName, email, profileId } = {} } =
      candidateProfile || {};
    this.props.updateStudentProfile(data, studentProfileId).then((res) => {
      if (res && !res.error) {
        this.setState({ stageIndex: stageIndex + 1 }, () => {
          // console.log("798---> ")
          // console.log(stageIndex)
          // this.props.history.replace(
          //   `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
          // );
          const { push, profileId } = this.props || {};
          push(
            `/app/campus/candidate/register/profile/${profileId}/stage/index/${this.state.stageIndex && Number(this.state.stageIndex)+3}`
          );
        });
        // const finalData = {
        //   templateId: 1020,
        //   templateName: 'video-interview-template-449-1',
        //   emailData: [
        //     {
        //       email,
        //       profileId,
        //       payload: {
        //         studentFirstName: firstName,
        //         studentLastName: lastName,
        //       },
        //     },
        //   ],
        //   actionName: 'user-defined-email',
        // };
        // this.props
        //   .sendEmails(finalData)
        //   .then((r) => {
        //     if (r && !r.error) {
        //       this.setState({ stageIndex: stageIndex + 1 }, () => {
        //         this.props.history.replace(
        //           `/app/campus/candidate/interncandidate/${this.state.stageIndex}`
        //         );
        //       });
        //     }
        //   })
        //   .catch((e) => console.log(e));
      }
    });
  };

  onSkillsInputChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.props
        .fetchAll({
          actionType: REQUEST_ALL_SKILLS,
          apiName: 'skills',
          paramName: 'skillName',
          value: _.toLower(value),
        })
        .then((res) => {
          const options =
            Array.isArray(res) &&
            res.map((one) => ({ label: one.skillName, value: one.skillId }));
          this.setState(
            {
              skillsLearnArr: options || [],
            },
            () => {
              //this.handleStudentLearnState();
            }
          );
          if (cb && typeof cb === 'function') cb(options || []);
        });
    }
  };

  onLocationsInputChange = (value) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.props.fetchAll({
        actionType: REQUEST_ALL_LOCATIONS,
        apiName: 'locations',
        paramName: 'locationName',
        value: _.toLower(value),
      });
    }
  };

  onLanguagesInputChange = (value) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.props.fetchAll({
        actionType: REQUEST_ALL_LANGUAGES,
        apiName: 'Languages',
        paramName: 'languageFull',
        value: _.toLower(value),
      });
    }
  };

  handleCitiesDropdownChange = (name, options) => {
    const data =
      Array.isArray(options) &&
      options.map((o, i) => ({ locationName: o.label, rank: i + 1 }));
    const {
      candidateProfile: { studentprofile: { studentProfileId = '' } = {} } = {},
    } = this.props;
    if (options.length < 4) {
      this.setState({ cities: options }, () =>
        this.props.createCityPerference(data, studentProfileId)
      );
    }
  };

  handleDropdownChange = (name, option) => {
    this.setState({ [name]: option });
  };

  handleSkillDropdownChange = (name, selectedOption) => {
    let difference = [];
    const {
      user: { profileId = '' },
    } = this.props;
    if (
      !this.state[name] ||
      !Array.isArray(this.state[name]) ||
      !selectedOption ||
      !Array.isArray(selectedOption)
    )
      return;
    if (this.state[name].length > selectedOption.length) {
      difference = this.state[name].filter((x) => !selectedOption.includes(x));
      const link = `${profileId}/skill/${
        difference[0].personSkillId ? difference[0].personSkillId : ''
      }`;
      this.props
        .manageSkill('DELETE', link)
        .then(() =>
          this.setState({ [name]: selectedOption }, () => this.setRows())
        );
    } else {
      difference = selectedOption.filter((x) => !this.state[name].includes(x));
      const [{ label = '', value = '' }] = difference;
      const link = `${profileId}/skill`;
      const skill = { skillName: label, skillId: value };
      this.props.manageSkill('POST', link, skill).then((res) => {
        const { personSkillId, skillId } = res.data ? res.data[0] : {};
        const rows = selectedOption.map((row) => {
          if (row.value == skillId) {
            return {
              label: row.label,
              value: row.value,
              personSkillId: Number(personSkillId),
            };
          } else return row;
        });
        this.setState({ [name]: rows }, () => this.setRows());
      });
    }
  };

  onStarClick = (nextValue, prevValue, name) => {
    let { selectedSkills = [] } = this.state;
    const {
      candidateProfile: {
        profile: { profileId = '' },
      },
    } = this.props;
    selectedSkills = selectedSkills.map((sk) => {
      if (sk.value == name) sk.rating = nextValue;
      return sk;
    });
    const skill = selectedSkills.find(({ value }) => value == name);
    const Data = {
      skillName: skill && skill.label ? skill.label : '',
      rating: nextValue,
      profileId,
      personSkillId: skill.personSkillId,
    };
    if (profileId !== null && skill.personSkillId !== null)
      this.props.updatePersonSkill(Data);
    if (nextValue && nextValue !== null && name && name !== null) {
      this.setState({ selectedSkills }, () => this.setRows());
    }
  };

  onLangRatingClick = (nextValue, prevValue, name) => {
    this.setState({ [name]: nextValue });
  };

  setLangs = () => {
    const { selectedLanguages = [] } = this.state;
    const langsData =
      Array.isArray(selectedLanguages) &&
      selectedLanguages.map(({ language, rating, type, id }) => {
        return {
          lang: language,
          type,
          rating: (
            <StarRatingComponent
              name={language}
              starCount={5}
              value={Number(rating)}
              starColor="#5596e6"
              emptyStarColor="#ddd"
              onStarClick={this.onLangRatingClick}
              renderStarIcon={(index, val) => (
                <i
                  className={
                    index <= val
                      ? 'fa fa-thumbs-up mr-1'
                      : 'fa fa-thumbs-up  mr-1'
                  }
                />
              )}
            />
          ),
          action: (
            <Button
              name={id}
              kind="danger"
              small
              onClick={(e) => this.deleteLanguage(e)}>
              Delete
            </Button>
          ),
          id,
        };
      });
    this.setState({ langsData });
  };

  setRows = () => {
    const { selectedSkills = [] } = this.state;
    const rowsData =
      Array.isArray(selectedSkills) &&
      selectedSkills.map(({ label, value, rating }) => ({
        skill: label,
        competency: (
          <StarRatingComponent
            name={(value && value.toString()) || 'NA'}
            starCount={5}
            value={Number(rating)}
            starColor="#5596e6"
            emptyStarColor="#ddd"
            onStarClick={this.onStarClick}
            renderStarIcon={(index, val) => (
              <span>
                <i
                  className={
                    index <= val
                      ? 'fa fa-star mr-1'
                      : 'fa fa-star  mr-1'
                  }
                />
              </span>
            )}
          />
        ),
        // action: <Button kind="danger" small name={value.toString()} onClick={this.onDeleteSkill}>Delete</Button>,
        id: (value && value.toString()) || 'NA',
      }));
    this.setState({ rowsData });
  };

  // handleDropdownChange = (selectedOption, value, name) => {
  //   if (name === 'jobFunctions' && Array.isArray(this.state[name]) && this.state[name].length > 1) return;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  handleLangChange = (selectedOption, value, name) => {
    this.setState({
      [name]: value,
    });
  };

  handleSkillChange = (selectedOption, value, name) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.handleStudentLearnState();
      }
    );
  };

  onSliderChange = ({ value }, name) => {
    const {
      candidateProfile: {
        profile: { profileId = '' } = {},
        studentprofile: { studentProfileId = '' } = {},
        studentpreferences = null,
      } = {},
    } = this.props;
    let data = {};
    data[name] = value;
    const studentPreferenceId =
      Array.isArray(studentpreferences) && studentpreferences.length > 0
        ? studentpreferences[0].studentPreferenceId
        : null;
    if (studentPreferenceId && studentPreferenceId !== null) {
      this.setState(
        {
          [name]: value,
        },
        () => {
          // this.props.createStudentPreference(data, studentProfileId).then(
          //   () => this.props.getCandidateProfile(profileId),
          // )
        }
      );
    } else {
      this.setState(
        {
          [name]: value,
        }
        // () => this.props.createStudentPreference(data, studentProfileId).then(
        //   () => this.props.getCandidateProfile(profileId),
        // )
      );
    }
  };

  handlePaginationChange = ({ page, pageSize }) => {
    let { activePage = 0, limit } = this.state;
    if (page > 1) {
      activePage = (page - 1) * pageSize + 1;
      limit = activePage + pageSize - 1;
    }
    this.setState({ activePage, limit });
  };

  handleLangPaginationChange = ({ page, pageSize }) => {
    let { activeLangPage = 0, langLimit } = this.state;
    if (page > 1) {
      activeLangPage = (page - 1) * pageSize + 1;
      langLimit = activeLangPage + pageSize - 1;
    }
    this.setState({ activeLangPage, langLimit });
  };

  onNewOptionCreate = (params, value) => {
    const actionData = {
      apiName: '',
      actionType: null,
      data: {},
    };
    let { selectedSkills = {} } = this.state;
    actionData.apiName = 'Skills';
    actionData.actionType = REQUEST_CREATE_NEW_SKILLS;
    actionData.data = { skillName: value, typeId: 1 };
    this.props.createNewOption(actionData).then((res) => {
      if (res.skillId) {
        selectedSkills.push({
          label: res.skillName,
          value: Number(res.skillId),
          rating: Number(res.rating),
        });
        this.setState({ selectedSkills });
      }
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
      uploadResumeClicked: true,
    });
    // , () => {
    //   const { profileId = null } = this.props.user;
    //   if (profileId !== null) {
    //     this.props.getCandidateProfile(profileId).then(() => {
    //       // this.setState({ stageIndex: 1 });
    //       // , () => {
    //       //   this.props.history.replace('/app/campus/candidate/interncandidate/1');
    //       // });
    //     });
    //   }
    // }
  };

  activateModal = () => {
    this.setState({
      activeModal: true,
    });
  };

  onAddLanguageClick = () => {
    const { langValue = null, langType = null, rating = null } = this.state;
    let { selectedLanguages = [] } = this.state;
    selectedLanguages =
      !selectedLanguages || selectedLanguages === null ? [] : selectedLanguages;
    const templang =
      Array.isArray(selectedLanguages) && langValue.label && langType.label
        ? selectedLanguages.push({
            language: langValue.label,
            type: langType.label,
            rating,
            id: langValue.value,
          })
        : [];
    this.setState(
      {
        showAddLang: false,
        langValue: {},
        langType: {},
        rating: 0,
        selectedLanguages,
      },
      () => {
        this.setLangs();
        const {
          candidateProfile: {
            profile: { profileId = '' } = {},
            studentprofile: { studentProfileId = '' } = {},
          } = {},
        } = this.props;
        const data = {
          language: langValue.label ? langValue.label : '',
          rating,
          langType: langType.label ? langType.label : '',
        };
        this.props
          .createStudentLanguages(data, studentProfileId)
          .then(() => this.props.getCandidateProfile(profileId));
      }
    );
  };

  deleteLanguage = (e) => {
    const { target: { name = null } = {} } = e || {};
    if (name === null) return;
    let { selectedLanguages, langsData } = this.state;
    selectedLanguages =
      Array.isArray(selectedLanguages) &&
      selectedLanguages.filter(({ id } = {}) => id != name);
    langsData =
      Array.isArray(langsData) && langsData.filter(({ id } = {}) => id != name);
    this.setState({ selectedLanguages, langsData }, () => {
      const {
        candidateProfile: {
          profile: { profileId = '' } = {},
          studentprofile: { studentProfileId = '' } = {},
        } = {},
      } = this.props;
      this.props
        .deleteStudentLanguages(studentProfileId, name)
        .then(() => this.props.getCandidateProfile(profileId));
    });
  };

  addLanguageModal = () => {
    this.setState({
      showAddLang: true,
      langValue: {},
      langType: {},
      rating: 0,
    });
  };

  closeLangModal = () => {
    this.setState({
      showAddLang: false,
      langValue: {},
      langType: {},
      rating: 0,
    });
  };

  setPreferences = (data, name) => {
    this.setState({ [name]: data });
  };

  toastSuccess = () => {
    setTimeout(() => {
      // window.location.reload();
      // this.props.history.replace('/app/campus/candidate/interncandidate/1');
      this.setState({
        uploadComplete: true,
        stageIndex: 1,
        uploadResumeClicked: false,
      });
    }, 5000);
  };

  refresh = (partialRefresh) => {
    // setTimeout(() => {
    //   });
    // }, 1000);
  };

  prevStep = () => {
    // const { history: { push } = {} } = this.props;
    const { push, profileId } = this.props||{};
    // if (push) {
    //   push('/app/campus/candidate/register/profile/stage/index/3');
    // }
    if (push) {
      push(`/app/campus/candidate/register/profile/${profileId}/stage/index/3`);
    }
  };

  render() {
    const {
      // stageIndex = 0,
      rowsData,
      langsData,
      preferenceTaskOriented,
      preferenceBackoffice,
      preferenceTryNew,
      activePage,
      limit,
      activeModal,
      stages,
      documents = [],
      activeLangPage,
      langLimit,
      langValue,
      langType,
      rating,
      showAddLang,
      IndustryPreferences,
      jobFunctionPreferences,
      jobPreferences,
      uploadResumeClicked,
      isResumeUploaded,
      uploadComplete,
      showStudentLearnsErr,
      skillLearnType,
      isOpenSkillsLearnModal,
      skillsLearnArr,
      isOpenDeleteLearnModal,
      scheduledSlot,
      showNtwErr,
      errMsg,
    } = this.state;
    let { stageIndex = 0 } = this.state || {};
    const {
      skillOptions = [],
      questions = [],
      taskId,
      neonPoly = false,
      t,
      candidateProfile,
      loading,
      profileLoader,
      propStageIndex,
      hideProgress,
      disabled,
      user:{
        roleId,
      }
    } = this.props;
    if(propStageIndex>=0){
      stageIndex=propStageIndex;
    }
    const { studentlearns, studentprofile: { isAppSubmitted } = {} } =
      candidateProfile || {};
    const headers = [
      { key: 'skill', header: t('skill') },
      { key: 'competency', header: t('competency') },
    ];
    const langHeaders = [
      { key: 'lang', header: t('language') },
      { key: 'type', header: t('type') },
      { key: 'rating', header: t('rating') },
      { key: 'action', header: t('action') },
    ];
    const columnWithFilter = [
      {
        title: `${t('skill')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    const { start: eventStartTime, end: eventEndTime } = scheduledSlot || {};
    const otherAction = (
      <Button small onClick={() => this.addLanguageModal()}>
        {t('addLanguage')}
      </Button>
    );
    const uploadResumeSatge = (
      <div className="bx--form-item modal-footer xpa-banner">
        {uploadResumeClicked === true && (
          <div className="toastCandidate">
            <Toast
              taskId={taskId}
              successAction={this.toastSuccess}
              partialRefresh={this.refresh}
            />
          </div>
        )}
        <p className="bx--label-description">{t('studentUploadResume')}</p>
        <div className="justify-content-center">
          <ResumeUpload
            pageType="candidate-profile"
            dismiss={this.dismissModal}
            active={activeModal}
            multipleResume={false}
          />
          <Button disabled={uploadResumeClicked} onClick={this.activateModal}>
            {t('uploadResume')}
          </Button>
        </div>
        {uploadResumeClicked && (
          <div>
            <InlineLoading
              success={uploadComplete}
              description={t('uploadingResume')}
            />
          </div>
        )}
      </div>
    );

    // const bioInformationSatge = (<BioInformation onNextStep={this.onNextStep} />);

    const candidateProfileStage = (
      <Fragment>
        <div className="font-weight-bold h5">{t('geipMsg2')}</div>
        {/* <ProfileInfo showError={this.state.showError} intern noBio={hideProgress? false: true}  history={this.props.history}/> */}
        <CandidateDetails showError={this.state.showError} intern noBio={hideProgress? false: true} notEditable={hideProgress? true: false}  history={this.props.history}/>
      </Fragment>
    );

    const competenciesStage = (
      <div className={`bx--form-item modal-footer xpa-banner ${disabled ? 'xpa-div-disable' : ''}`}>
        <div className="font-weight-bold h5">{t('geipMsg3')}</div>
        <div className="mb-3 bx--col-12">
          <div className="compitency mb-3">
            {rowsData && Array.isArray(rowsData) && (
              <div>
                <div className="bx--type-delta text-secondary">
                  {t('skillsCompetencyTitle')}
                </div>
                <div style={{ fontWeight: "600" }}>{t('skillCompetencyExampleText')}</div>
                <div>
                  <small>{t('skillsCompMsg')}</small>
                </div>
                <GenericDataTable headers={headers} rows={rowsData} />
                {Array.isArray(rowsData) && rowsData.length === 0 && (
                  <div className="bx--type-epsilon pt-2 pb-2 align-items-center d-flex justify-content-center">
                    {t('noSkill')}
                  </div>
                )}
                {/* <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} totalItems={selectedSkills.length} /> */}
              </div>
            )}
          </div>
        </div>
        {!neonPoly ? (
          <div className="compitency bx--col mb-3">
            {
              <div>
                <GenericDataTable
                  title={t('languageProficiency')}
                  headers={langHeaders}
                  rows={langsData}
                  otherActions={[otherAction]}
                />
                {Array.isArray(langsData) && langsData.length === 0 && (
                  <div className="bx--type-epsilon pt-2 pb-2 align-items-center d-flex justify-content-center">
                    {t('noLanguages')}
                  </div>
                )}
                <Pagination
                  onChange={this.handleLangPaginationChange}
                  pageSizes={[10, 20, 30, 40, 50]}
                  totalItems={langsData.length}
                />
                <Modal
                  open={showAddLang}
                  modalHeading={t('addLanguage')}
                  primaryButtonText={t('add')}
                  secondaryButtonText={t('cancel')}
                  onRequestClose={this.closeLangModal}
                  onRequestSubmit={this.onAddLanguageClick}
                  onSecondarySubmit={this.closeLangModal}>
                  <div>
                    <DropDownBox
                      labelClass="bx--type-epsilon"
                      titleClass="col-xs-12 col-sm-12 col-md-3 mt-3"
                      title={t('language')}
                      options={
                        Array.isArray(this.props.languages)
                          ? this.props.languages.map(
                              ({ languageFull, languageId }) => ({
                                label: languageFull,
                                value: languageId,
                              })
                            )
                          : []
                      }
                      placeholder={t('language')}
                      onChange={(id, value, name) =>
                        this.handleDropdownChange(name, value)
                      }
                      onInputChange={this.onLanguagesInputChange}
                      name="langValue"
                      selectedValue={langValue}
                    />
                  </div>
                  <div>
                    <DropDownBox
                      labelClass="bx--type-epsilon"
                      titleClass="col-xs-12 col-sm-12 col-md-3 mt-3"
                      title={t('type')}
                      options={[
                        { value: '1', label: 'Written' },
                        { value: '2', label: 'Verbal' },
                      ]}
                      placeholder={t('type')}
                      onChange={this.handleLangChange}
                      name="langType"
                      selectedValue={langType}
                    />
                  </div>
                  <div className="bx--row m-0 mt-3 ml-3">
                    <div className="bx--type-epsilon mt-1 mr-3">
                      {t('rating')}
                    </div>
                    <div>
                      <StarRatingComponent
                        name={t('rating')}
                        starCount={5}
                        value={Number(rating)}
                        starColor="#5596e6"
                        emptyStarColor="#ddd"
                        onStarClick={this.onLangRatingClick}
                        renderStarIcon={(index, val) => (
                          <span>
                            <i
                              className={
                                index <= val
                                  ? 'fa fa-thumbs-up mr-1'
                                  : 'fa fa-thumbs-up  mr-1'
                              }
                            />
                          </span>
                        )}
                      />
                    </div>
                  </div>
                </Modal>
              </div>
            }
          </div>
        ) : null}
      </div>
    );

    const studentsLearningPage = (
      <Fragment>
        <div className="font-weight-bold h5 mb-2">{t('geipMsg4')}</div>
        <div className="ml-2 font-weight-bold">{t('studentLearnMsg')}</div>
        {studentlearns &&
          Array.isArray(studentlearns) &&
          studentlearns.length < 3 && (
            <div className="text-right mr-3">
              <Button disabled={disabled} kind="primary" onClick={this.activeSkillsLearnModal}>
                {t('add')} {t('skills')}
              </Button>
            </div>
          )}
        <div>
          {showStudentLearnsErr === true && (
            <InlineNotification
              lowContrast
              kind="error"
              className="bx--col"
              title=""
              subtitle={
                <span className="text-danger font-weight-bold">
                  {t('noSkillsLearnMsg')}
                </span>
              }
              onCloseButtonClick={() =>
                this.setState({ showStudentLearnsErr: false })
              }
            />
          )}
          {studentlearns &&
            Array.isArray(studentlearns) &&
            studentlearns.length > 0 && (
              <GenericDataTable
                className=""
                otherActions={[]}
                headers={columnWithFilter.map((data, index) => ({
                  key: `header_${index}`,
                  header: data.title,
                  className: data.className,
                  colpropName: data.propName,
                }))}
                rows={studentlearns.map((res) => {
                  const { stuLearnId, skill: { skillName } = {} } = res || {};
                  return {
                    isExpanded: true,
                    id: `row_${stuLearnId}`,
                    header_0: (
                      <div className="text-capitalize font-weight-bold text-secondary">
                        {skillName}
                      </div>
                    ),
                    header_1: (
                      <div className="d-flex justify-content-center">
                        <div className="ml-2">
                          <Button
                            onClick={() => this.activeDeleteModal(stuLearnId)}
                            kind="danger"
                            small
                            disabled={disabled}>
                            {t('delete')}
                          </Button>
                        </div>
                      </div>
                    ),
                  };
                })}
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
            )}
          {(!studentlearns ||
            (studentlearns &&
              Array.isArray(studentlearns) &&
              studentlearns.length <= 0)) && (
            <div className="card-body text-center">
              <div className="empty-icon">
                <i className="fa fa-flag fa-2x" />
              </div>
              <p className="empty-title h5">{t('noSkill')}</p>
            </div>
          )}
          <div className="compitency">
            <Modal
              open={isOpenSkillsLearnModal}
              modalHeading={`${t('add')} ${t('skills')}`}
              primaryButtonText={t('add')}
              secondaryButtonText={t('cancel')}
              onRequestClose={this.dismissSkillsLearnModal}
              onRequestSubmit={this.handleCreateLearningSkills}>
              <Fragment>
                <div className="mb-2">
                  {showNtwErr === true && (
                    <InlineNotification
                      lowContrast
                      kind="error"
                      className="bx--col"
                      title=""
                      subtitle={errMsg}
                      onCloseButtonClick={() =>
                        this.setState({ showNtwErr: false })
                      }
                    />
                  )}
                </div>
                <DropDownBox
                  labelClass="bx--type-epsilon"
                  titleClass="col-xs-12 col-sm-12 col-md-3 mt-3"
                  title={t('skills')}
                  onInputChange={this.onSkillsInputChange}
                  options={skillsLearnArr ? skillsLearnArr : []}
                  placeholder={t('skills')}
                  onChange={this.handleSkillChange}
                  name="skillLearnType"
                  selectedValue={skillLearnType}
                />
              </Fragment>
            </Modal>
          </div>
          <Modal
            open={isOpenDeleteLearnModal}
            modalHeading={`${t('delete')} ${t('skills')}`}
            danger
            primaryButtonText={t('delete')}
            secondaryButtonText={t('cancel')}
            onRequestClose={this.dismissDeleteModal}
            onRequestSubmit={this.handleDeleteLearnSkills}>
            <p className="font-weight-bold text-secondary">
              {t('deleteSkillLearnMsg')}
            </p>
          </Modal>
        </div>
      </Fragment>
    );

    const cityJobTypePreferencesSatge = (
      <Fragment>
        <div className="font-weight-bold h5 mb-2">{t('geipMsg5')}</div>
        <div className="bx--form-item modal-footer xpa-banner">
          <div className="mb-3 bx--col-12">
            <div className="form-group">
              <div className="Job_preferences">
                {neonPoly ? (
                  <Fragment>
                    <div className="bx--type-epsilon mb-3">
                      {t('sliderTitle')}
                    </div>
                    <div className="ml-lg-5 mt-2">
                      <PercentageSlider
                        hideSideLabels
                        minLabel={t('taskOrientedPreference')}
                        maxLabel={t('independentPreference')}
                        value={Number(preferenceTaskOriented)}
                        name="preferenceTaskOriented"
                        onChange={this.onSliderChange}
                        disabled={disabled}
                      />
                      <PercentageSlider
                        hideSideLabels
                        minLabel={t('backEndPreference')}
                        maxLabel={t('frontEndPreference')}
                        value={Number(preferenceBackoffice)}
                        name="preferenceBackoffice"
                        onChange={this.onSliderChange}
                        disabled={disabled}
                      />
                      <PercentageSlider
                        hideSideLabels
                        minLabel={t('tryNewPreference')}
                        maxLabel={t('enhanceFutherEnhanceSkills')}
                        value={Number(preferenceTryNew)}
                        name="preferenceTryNew"
                        onChange={this.onSliderChange}
                        disabled={disabled}
                      />
                    </div>
                    <hr className="mt-3 mb-3" />
                  </Fragment>
                ) : null}
                {/* <div className="bx--type-epsilon mb-3">
                  {t('preferredCitiesPreference')}
                </div>
                <div className="form-group bx--row m-0">
                  <div className="bx--col-sm-12 bx--col-md-3">
                    <div className="form-label pt-3 pb-0 mb-0 bx--type-epsilon">{t('cities')}</div>
                  </div>
                  <div className="newjob_input_container bx--col-sm-12 bx--col-md-6">
                    <DropDownBox
                      custom
                      mainClassName="bx--col-12 p-0"
                      required
                      isClearable={false}
                      className="city"
                      selectedValue={cities}
                      name="cities"
                      isMulti
                      options={allCities}
                      onChange={(id, value, name) => this.handleCitiesDropdownChange(name, value)}
                      onInputChange={this.onLocationsInputChange}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="d-flex mt-2 ">
            <div className="bx--type-zeta bx--col-lg-3">
              {t('taskOrientedPreference')}:
            </div>
            <div className="text-secondary ml-2 bx--col-lg-9">
              {t('prefMsg1')}
            </div>
          </div>
          <div className="d-flex mt-2 ">
            <div className="bx--type-zeta bx--col-lg-3">
              {t('independentPreference')}:
            </div>
            <div className="text-secondary ml-2 bx--col-lg-9 ">
              {t('prefMsg2')}
            </div>
          </div>
          <div className="d-flex mt-2 ">
            <div className="bx--type-zeta bx--col-lg-3">
              {t('backEndPreference')}:
            </div>
            <div className="text-secondary ml-2 bx--col-lg-9 ">
              {t('prefMsg3')}
            </div>
          </div>
          <div className="d-flex mt-2 ">
            <div className="bx--type-zeta bx--col-lg-3">
              {t('frontEndPreference')}:
            </div>
            <div className="text-secondary ml-2 bx--col-lg-9 ">
              {t('prefMsg4')}
            </div>
          </div>
          <div className="d-flex mt-2 ">
            <div className="bx--type-zeta bx--col-lg-3">
              {t('tryNewPreference')}:
            </div>
            <div className="text-secondary ml-2 bx--col-lg-9 ">
              {t('prefMsg5')}
            </div>
          </div>
          <div className="d-flex mt-2 ">
            <div className="bx--type-zeta bx--col-lg-3">
              {t('enhanceFutherEnhanceSkills')}:
            </div>
            <div className="text-secondary ml-2 bx--col-lg-9">
              {t('prefMsg6')}
            </div>
          </div>
        </div>
      </Fragment>
    );

    //hide={["jobpreferences"]}
    const preferencesStage = (
      <Fragment>
        <div className="font-weight-bold h5 mb-2">{t('geipMsg6')}</div>
        <input style={{ height: 0, opacity: 0 }} ref={this.notifRef} />
        {this.state.showPrefError === true && (
          <InlineNotification
            lowContrast
            kind="error"
            className="bx--col"
            title=""
            subtitle={
              <span className="text-danger font-weight-bold">
                {t('allMandatoryFields')}
              </span>
            }
            onCloseButtonClick={() => this.setState({ showPrefError: false })}
          />
        )}
        <Preferences
          ref="preferences"
          hide={['citypreferences', 'jobMatches', 'jobpreferences']}
          setData={this.setPreferences}
          industries={IndustryPreferences}
          functions={jobFunctionPreferences}
          jobs={jobPreferences}
          disabled={disabled}
        />
      </Fragment>
    );

    const additionalDocsStage = (
      <Fragment>
        <div className="bx--type-epsilon mb-3 text-center">
          {t('uploadAdditionalDocs')}
        </div>
        <div>
          <UploadAllDocuments disabled={disabled} candidateProfile={candidateProfile} />
          {/* <ResumeUpload pageType="candidate-profile" heading={t('uploadAdditional')} dismiss={this.dismissModal} active={activeModal} multipleResume={false} />
          <div className="text-center"><Button onClick={this.activateModal}>{t('uploadAdditional')}</Button></div> */}
        </div>
      </Fragment>
    );

    const finalStage = (
      <div className="">
        <div className="text-center bx--type-alpha">{t('profileSaved')}</div>
        <div className="align-content-center text-center">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
            style={{ maxWidth: 100 }}>
            <circle
              class="path circle"
              fill="none"
              stroke="#73AF55"
              stroke-width="6"
              stroke-miterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              class="path check"
              fill="none"
              stroke="#73AF55"
              stroke-width="6"
              stroke-linecap="round"
              stroke-miterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
        </div>
        {roleId && roleId == 3 && <div className="font-weight-bold h5 mt-2 text-capitalize mb-2 text-center xpa-profile-color">
          {t('geipMsg8')}
        </div>}
        {roleId && roleId == 3 && <div className="text-center bx--type-caption font-weight-bold">
          {t('geipMsg9')}
        </div>}
        {roleId && roleId == 3 &&<div className="m-3 text-center bx--row justify-content-center">
          <div className="m-3">
            <Button
              kind="primary"
              className=""
              onClick={() =>{
                this.props.history.push({
                  pathname: '/app/campus/candidate/slots',
                  state: { selectedTab: 'book-slots'}
                })
              }
              }>
              {t('bookSlots')}
            </Button>
          </div>
        </div>}
      </div>
    );

    const interviewSlotsStage = (
      <div>
        <h6 className="bx--type-delta text-center">{t('interviewSlots')}</h6>
        <div className="text-justify">
          <p className="text-secondary font-weight-bold">{t('slotsHeading')}</p>
        </div>
        <h6 className="bx--type-zeta">{t('important')}</h6>
        <div className="text-justify">
          <p style={{ fontSize: '0.9rem' }}>{t('slotsSubHeading')}</p>
        </div>
        {this.state.scheduledSlot && (
          <div>
            <p>Interview Info:</p>
            <p>
              Your interview is scheduled on
              <b>
                &nbsp;
                {moment(eventStartTime)
                  .utc()
                  .add(480, 'minutes')
                  .format('dddd, MMMM Do YYYY')}
                &nbsp;
                {moment(eventStartTime)
                  .utc()
                  .add(480, 'minutes')
                  .format('HH:mm')}{' '}
                - &nbsp;
                {moment(eventEndTime)
                  .utc()
                  .add(480, 'minutes')
                  .format('HH:mm')}{' '}
                (SGT)
              </b>
            </p>
          </div>
        )}
      </div>
    );

    const StagesData = neonPoly
      ? [
          uploadResumeSatge,
          candidateProfileStage,
          competenciesStage,
          studentsLearningPage,
          cityJobTypePreferencesSatge,
          preferencesStage,
          additionalDocsStage,
          finalStage,
        ]
      : [
          uploadResumeSatge,
          // bioInformationSatge,
          candidateProfileStage,
          competenciesStage,
          studentsLearningPage,
          cityJobTypePreferencesSatge,
          preferencesStage,
          additionalDocsStage,
          finalStage,
        ];
    return (
      <Fragment>
        <div className="InternCandidate">
          <div>
            {!hideProgress && <div>
              <div className="newjob__form__header bx--row newjob__form__header--center">
                <h3 className="bx--col-12 text-justify text-uppercase bx--type-delta">
                  {t('candidateDetailsTitle')}
                </h3>
              </div>
              <div style={{ height: 40 }}>
                <Progress
                  className="bx--col mt-2 xpa-react-symbol"
                  theme={{
                    active: {
                      symbol: (
                        <div className="bx--type-zeta">{`${t('step')} ${
                          stageIndex + 4
                        }/10`}</div>
                      ),
                      trailColor: '#f4f7fb',
                      color: '#5596e6',
                    },
                    success: {
                      symbol: (
                        <div className="bx--type-zeta">
                          {t('offersCompletedTab')}
                        </div>
                      ),
                      trailColor: 'lime',
                      color: 'green',
                    },
                  }}
                  percent={
                    (100 / stages) *
                    (stageIndex == 0 ? stageIndex : stageIndex + 1)
                  }
                />
              </div>
              <div className="mt-2 mb-2">
                <span className="text-secondary bx--type-zeta">
                  {t('studentRegMsg')}
                </span>
                <b>
                  <span className="text-secondary font-weight-bold ml-2 mt-0">
                    <a href={'mailto:askGIA@np.edu.sg'}>askGIA@np.edu.sg</a>
                  </span>
                </b>
              </div>
            </div>}
            {StagesData[stageIndex]}

            {!disabled && <div className="bx--row mt-3 justify-content-center ml-0">
              {stageIndex > 0 &&
              // stageIndex !== 1 && 
              isAppSubmitted == true &&
              stageIndex !== stages - 1 ? (
                <div className="mr-2">
                  <Button
                    kind="tertiary"
                    small
                    className=""
                    onClick={(e) => this.onPrevStep(e)}>
                    {t('previous')}
                  </Button>
                </div>
              ) : null}
              {stageIndex == 0 ? (
                <div className="mr-2">
                  <Button
                    kind="primary"
                    disabled={
                      Array.isArray(documents) && documents.length === 0
                    }
                    small
                    className=""
                    onClick={(e) => this.onNextStep(e)}>
                    {t('next')}
                  </Button>
                </div>
              ) : null}
              {stageIndex == 1 && isAppSubmitted !== true ? (
                <div className="mr-2">
                  <Button
                    kind="tertiary"
                    small
                    className=""
                    onClick={(e) => this.prevStep(e)}>
                    {t('previous')}
                  </Button>
                </div>
              ) : null}
              {stageIndex < stages - 2 && stageIndex >= 1 ? (
                <div className="mr-2">
                  <Button
                    kind="primary"
                    small
                    className=""
                    onClick={(e) => this.onNextStep(e)}>
                    {t('next')}
                  </Button>
                </div>
              ) : null}
              {stageIndex === stages - 2 ? (
                <div className="mr-2">
                  <Button
                    kind="primary"
                    small
                    onClick={(e) => this.onSubmitInfo(e)}>
                    {t('save')}
                  </Button>
                </div>
              ) : null}
            </div>}
          </div>
        </div>
        <JDProcessOverlay
          show={loading || profileLoader || this.props.loader}
          message="processing..."
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
  companyAndOffice: state.companyAndOffice,
  industries: state.companyAndOffice.industries,
  jobFunctions: state.jobCreation && state.jobCreation.jobFunctions,
  loading: state.StudentProfile.loading,
  profileLoader: state.roboroyApplications.loading,
  skillOptions: state.jobCreation && state.jobCreation.skills,
  languages: state.jobCreation && state.jobCreation.languages,
  locations: state.jobCreation && state.jobCreation.locations,
  companyTypes: state.jobCreation && state.jobCreation.companyTypes,
  candidateProfile: state.roboroyApplications.candidateProfile,
  StudentProfile: state.StudentProfile,
  taskId: state.resumeUpload.taskId,
  preferredSkills: state.StudentProfile.preferredSkills,
  neonPoly: true,
  loader: state.roboroyTalentPool.loading,
});

const mapDispatchToProps = {
  fetchAll,
  parseJd,
  fetchDropdownData,
  associateJd,
  getCandidateProfile,
  downloadCandidateProfile,
  createNewOption,
  getAllIndustries,
  manageSkill,
  updatePersonSkill,
  updateStudentProfile,
  getStudentProfile,
  createStudentLanguages,
  updateStudentLanguages,
  deleteStudentLanguages,
  createCityPerference,
  updateCityPerference,
  deleteCityPerference,
  createIndustryPreferences,
  updateIndustryPreferences,
  deleteIndustryPreferences,
  createStudentPreference,
  updateStudentPreference,
  deleteStudentPreference,
  createFunctionPreferences,
  updateFunctionPreferences,
  deleteFunctionPreferences,
  createSkillsLearnsForStds,
  deleteSkillsLearnsForStdsById,
  getSkillsLearnsForStds,
  getAvailabilitySlots,
  createCalendarEvent,
  getSkillsPreferredList,
  sendEmails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InternCandidate));
