import {
    SUCCESS_FACETS_DATA,
  } from '../actions/actionTypes';

  const initState = {
    facets:{},
  };

  const Filters = (state = initState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
      case SUCCESS_FACETS_DATA:{
          return {
              ...state,
            facets: payload,
          }
        };
      default:
        return state;
    }
  };
  
  export default Filters;