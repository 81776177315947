import { withTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { InlineNotification } from 'carbon-components-react';

class ResetPassword extends React.Component {
  constructor() {
    super();
  }
  state = {token:''};
  handleOnChange = (name, value) => {
    const { password, confirmpassword } = this.state;
    if (confirmpassword && confirmpassword.length > 0) {
      if (name == 'password' && confirmpassword != value)
        this.setState({
          validationError: {
            message: "Passwords do not match"
          }
        });
      else this.setState({ validationError : null });
    }
    this.setState({
      [name]: value
    });
  }

componentDidMount(){
  let token = this.getQueryParams('token');
    if(token && token.length>0)
      this.setState({token});
}

  changeAndValidate = (name, value) => {
    this.setState({
      [name]: value
    }, () => {
        // console.log(this.props.location);
        if (this.state.password !== this.state.confirmpassword) {
            this.setState({
                validationError : {
                    message : "Passwords do not match"
                }
            });
        } else {
            this.setState({
                validationError : null
            });            
        }
    });
  }  
  getQueryParams = (key) => {
    var qstring = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < qstring.length; i++) {
      var urlparam = qstring[i].split('=');
      if (urlparam[0] == key) {
         return urlparam[1];
      }
    }    
  }
  updatePassword = () => {
    let token = this.getQueryParams('token');
    if(token && token.length>0)
      this.setState({token});

    const eData = {
      "newPassword": this.state.password,
    };
    fetch('/roboroy/api/v1/userinfos/update-password?access_token=' + token, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(eData),
    })
    .then(function(response) {
        return response.json();
    })  
    .then((resJSON) => {
        if (resJSON && resJSON.error) {
            this.setState({                 
                validationError : {
                    message : "There was an error in processing. Please try again later."
                } 
            });
        } else {
            alert("Password reset was successful. Redirecting to login page.");
            if(token != undefined)
              window.location.href="/";
            else
              window.location.href="/auth/logout";
        }
    })
    .catch((err) => 
        this.setState({                 
            validationError : {
                message : "There was an error in processing. Please try again later."
            } 
        })
    );
  }
  render() {
    return (
      <div className="xpa-center-viewport p-2">
        <div className="row">
          <img className="mb-4 mx-auto" src={require('assets/images/republic-polytechnic.png')} />
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <form className="form-signin text-center card" onSubmit={(e) => {
              e.preventDefault();
              this.updatePassword();
              this.setState({ isLogging: true });
            }} >
              <h1 className="h3 mb-3 font-weight-normal">Reset password</h1>
              {this.state.validationError && (<InlineNotification lowContrast
                className="mt-0"
                title={this.state.validationError && this.state.validationError.message}
                subtitle=""
                iconDescription="describes the close button"
                kind="error"
                hideCloseButton
              />)}              
              <div className="">
                  <input type="password" name="password" required
                    placeholder="Enter new password"
                    autoComplete="username"
                    className="bx--text-input mb-2"
                    onChange={(e) => this.handleOnChange('password', e.target.value)}
                  />
                  <input type="password" name="confirmpassword" required
                    placeholder="Confirm password"
                    autoComplete="username"
                    className="bx--text-input mb-2"
                    onChange={(e) => this.changeAndValidate('confirmpassword', e.target.value)}
                  />                                     
                <button className="bx--btn bx--btn--primary full-width" 
                        disabled={this.state.validationError?"disabled":""} 
                        type="submit">Submit</button>                  
              </div>
              { 
                (this.state.token && this.state.token.length>0)?
                <a className="mt-2" href="/">&larr; Go back to previous page</a>:<a className="mt-2" href="/auth/logout">&larr; Go back to login page</a>
              }
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const Page = () => (
  <div className="page-forgot">
    <div className="main-body">
      <ResetPassword />
    </div>
  </div>
);

export default Page;
