import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  ToolbarItem, TextInput, Modal, Button,
  DatePickerInput, DatePicker,
} from 'carbon-components-react';
import {
  getAllFaculties,
} from 'actions/candidate/CandidateDetails';
import {
  createFacultyDetails,
  deleteFacultyDetailsById,
  upsertFacultyDetailsById,
} from 'actions/InstitutionView';
import FacultyView from 'components/roboroy/FacultyView';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import moment from 'moment';

class BrowseFaculties extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'facukties', name: 'Faculties', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      facultyActivePage: 1,
      facultyItemsPerPage: 10,
      facultySearchKey: '',
      isOpenFacultyDeleteModal: false,
      isOpenFacultyModal: false,
      facultyId: 0,
      facultyName: '',
      isValidFacultyName: false,
      isValidFacultyNameText: '',
      isInternshipStartDateValid: false,
      isInternshipEndDateValid: false,
    };
  }

  componentDidMount() {
    this.handleFacultyApiCall();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleFacultyPaginationChange = (data) => {
    const facultyActivePage = (data.page) || 1;
    const facultyItemsPerPage = data.pageSize || this.state.facultyItemsPerPage;
    this.setState({ facultyActivePage, facultyItemsPerPage }, () => {
      this.handleFacultyApiCall();
    });
  }

  activeFacultyModal = (facultyId, facultyName, internshipStartDate, internshipEndDate) => {
    console.log('date', internshipStartDate, internshipEndDate);
    if (facultyId && facultyId !== null && facultyId !== undefined) {
      this.setState({
        isOpenFacultyModal: true,
        facultyId,
        facultyName,
        internshipStartDate,
        internshipEndDate,
      });
    } else {
      this.setState({
        isOpenFacultyModal: true,
        facultyId: null,
        facultyName: '',
        internshipStartDate: null,
        internshipEndDate: null,
        
      });
    }
  }

  handleFacultyApiCall = () => {
    const {
      user: {
        company: {
          companyId,
        } = {},
      } = {},
    } = this.props;
    const {
      facultyActivePage,
      facultyItemsPerPage,
      facultySearchKey,
    } = this.state;
    const data = {
      facultyActivePage,
      facultyItemsPerPage,
      facultySearchKey,
      schoolId: companyId,
    };
    this.props.getAllFaculties(data);
  }

  handleRedirectToFacultyViewPage = (facultyId) => {
    const {
      history: {
        push,
      } = {},
      user: {
        company: {
          companyId,
        } = {},
        facultyId: userFacultyId,
        isSuperAdmin,
      } = {},
    } = this.props;
    if (push && userFacultyId == facultyId || isSuperAdmin === true) {
      push(`/app/campus/institution/info/${companyId}/faculty/${facultyId}`);
    }
  }

  dismissFacultyModal = () => {
    this.setState({
      isOpenFacultyModal: false,
      isValidFacultyName: false,
      isValidFacultyNameText: '',
      facultyName: '',
    });
  }

  activeFacultyDeleteModal = (facultyId) => {
    this.setState({
      isOpenFacultyDeleteModal: true,
      facultyId,
    });
  }

  deleteFacultyDeleteModal = () => {
    this.setState({
      isOpenFacultyDeleteModal: false,
    });
  }

  handleSearchFacultyData = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ facultySearchKey: event.target.value, facultyActivePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.handleFacultyApiCall()
      ), 1000);
    });
  }

  handleSubmitFacultyDeleteModal = () => {
    const {
      facultyId,
    } = this.state;
    this.props.deleteFacultyDetailsById(facultyId).then((res) => {
      if (res && !res.error) {
        this.handleFacultyApiCall();
        this.deleteFacultyDeleteModal();
      }
    });
  }

  handleSubmitFacultyDetails = () => {
    const {
      facultyName,
      // internshipStartDate,
      // internshipEndDate,
    } = this.state;
    const {
      t,
    } = this.props;
    let isValidFacultyName = null;
    let isValidFacultyNameText = '';
    // let isInternshipStartDateValid = false;
    // let isInternshipEndDateValid = false;
    if (!facultyName || facultyName === null || facultyName === undefined) {
      isValidFacultyName = true;
      isValidFacultyNameText = `${t('facultyNameValid')}`;
    } else {
      isValidFacultyName = false;
      isValidFacultyNameText = '';
    }
    // if (!internshipStartDate) {
    //   isInternshipStartDateValid = true;
    // } else {
    //   isInternshipStartDateValid = false;
    // }
    // if (!internshipEndDate) {
    //   isInternshipEndDateValid = true;
    // } else {
    //   isInternshipEndDateValid = false;
    // }
    this.setState({
      isValidFacultyName,
      isValidFacultyNameText,
      // isInternshipStartDateValid,
      // isInternshipEndDateValid,
    }, () => {
      this.handleCheckFaultyNameValidation();
    });
  }

  handleCheckFaultyNameValidation = () => {
    const {
      user: {
        companyId,
      } = {},
    } = this.props;
    const {
      isValidFacultyName,
      facultyName,
      facultyId,
      // isInternshipStartDateValid,
      // isInternshipEndDateValid,
      // internshipStartDate,
      // internshipEndDate,
    } = this.state;
    // && isInternshipEndDateValid !== true && isInternshipStartDateValid !== true
    if (isValidFacultyName !== true) {
      const data = {
        facultyName,
      };
      if (!facultyId) {
        data.schoolId = companyId;
        this.props.createFacultyDetails(data).then((res) => {
          if (res && !res.error) {
            this.handleFacultyApiCall();
            this.dismissFacultyModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      } else if (facultyId) {
        data.facultyId = facultyId && Number(facultyId);
        this.props.upsertFacultyDetailsById(data).then((res) => {
          if (res && !res.error) {
            this.handleFacultyApiCall();
            this.dismissFacultyModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      }
    }
  }

  handleDateChange = (date) => {
    const [
      startPeriod,
      endPeriod,
    ] = date;
    this.setState({
      internshipStartDate: startPeriod && moment(startPeriod).format(),
      internshipEndDate: endPeriod && moment(endPeriod).format(),
    });
  }

  render() {
    const {
      facultyData,
      t,
      facultyCount,
      user: {
        isAdmin: propAdmin,
        isSuperAdmin: propSuperAdmin,
        facultyId: userFacultyId,
      } = {},
      loading,
    } = this.props;
    const {
      isOpenFacultyDeleteModal,
      facultyActivePage,
      isOpenFacultyModal,
      facultyId,
      facultyName,
      isValidFacultyNameText,
      isValidFacultyName,
      isInternshipStartDateValid,
      isInternshipEndDateValid,
      internshipStartDate,
      internshipEndDate,
    } = this.state;
    const columnWithFilter = [
      {
        title: `${t('faculty')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    const otherActionButtons = [];
    if (propSuperAdmin === true) {
      otherActionButtons.push(
        <ToolbarItem>
          <Button
            className="mr-2"
            onClick={() => this.activeFacultyModal(null, null)}
            kind="primary"
          >
            {t('add')} {t('faculty')}
          </Button>
        </ToolbarItem>,
      );
    }
    return (
      <Fragment>
        <BackRow
          paths={this.paths}
        />
        <FacultyView
          facultyData={facultyData}
          columnWithFilter={columnWithFilter}
          otherActionButtons={otherActionButtons}
          handleRedirectToFacultyViewPage={this.handleRedirectToFacultyViewPage}
          activeFacultyModal={this.activeFacultyModal}
          isOpenFacultyDeleteModal={isOpenFacultyDeleteModal}
          propAdmin={propAdmin}
          propSuperAdmin={propSuperAdmin}
          activeFacultyDeleteModal={this.activeFacultyDeleteModal}
          deleteFacultyDeleteModal={this.deleteFacultyDeleteModal}
          handleSubmitFacultyDeleteModal={this.handleSubmitFacultyDeleteModal}
          handleSearchFacultyData={this.handleSearchFacultyData}
          handleFacultyPaginationChange={this.handleFacultyPaginationChange}
          facultyActivePage={facultyActivePage}
          facultyCount={facultyCount}
          userFacultyId={userFacultyId}
        />
        <Modal
          open={isOpenFacultyModal}
          modalHeading={`${facultyId ? `${t('edit')}` : `${t('add')}`} ${t('faculty')}`}
          modalLabel={`${t('faculty')}`}
          primaryButtonText={facultyId ? `${t('edit')}` : `${t('add')}`}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissFacultyModal}
          onRequestSubmit={this.handleSubmitFacultyDetails}
        >
          <TextInput
            className="mb-2"
            name="facultyName"
            id="facultyName"
            labelText={`${t('faculty')} ${t('name')}`}
            value={this.state.facultyName}
            placeholder={`${t('faculty')} ${t('name')}`}
            invalid={isValidFacultyName}
            invalidText={isValidFacultyNameText}
            onChange={e => this.handleChange(e)}
          />
          {/* <DatePicker
            dateFormat="m/d/Y"
            datePickerType="range"
            id="date-picker"
            light={false}
            locale="en"
            onChange={e => this.handleDateChange(e)}
            short={false}
          >
            <DatePickerInput
              className="some-class"
              disabled={false}
              iconDescription="Icon description"
              id="date-picker-input-id-start"
              invalid={isInternshipStartDateValid}
              invalidText={t('commonValidMsg')}
              labelText={t('internshipStrtDate')}
              pattern="d{1,2}/d{4}"
              placeholder="mm/dd/yyyy"
              type="text"
              value={internshipStartDate && moment(internshipStartDate).format('MM-DD-YYYY')}
            />
            <DatePickerInput
              className="some-class"
              disabled={false}
              iconDescription="Icon description"
              id="date-picker-input-id-end"
              invalid={isInternshipEndDateValid}
              invalidText={t('commonValidMsg')}
              labelText={t('internshipEndDate')}
              pattern="d{1,2}/d{4}"
              placeholder="mm/dd/yyyy"
              type="text"
              value={internshipEndDate && moment(internshipEndDate).format('MM-DD-YYYY')}
            />
          </DatePicker> */}
        </Modal>
        <JDProcessOverlay show={loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  facultyData: state.candidateDetails.facultyData,
  facultyCount: state.candidateDetails.facultyCount,
  loading: state.candidateDetails.loading,
});

const mapDispatchToProps = {
  getAllFaculties,
  createFacultyDetails,
  deleteFacultyDetailsById,
  upsertFacultyDetailsById,
};

export default
connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BrowseFaculties));
