import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'carbon-components-react';
import SetMixPanel from 'components/common/GetCurrentPath';
import SubHeader from './SubHeader';


import SwitchCompany from '../common/switchCompany';

const Svgs = {
  admin: (<svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>),
  users: (<svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>),
  companies: (<i className="fa fa-university" aria-hidden="true" style={{ fontSize: 20 }} />),
  templates: (<svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>),
  marketplace: (<svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>),
  myprofile: (<svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>),
  login: (<svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>),
  switchcompany: (<svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>),
  logout: (<svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><path className="logout_path" d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" fill="none" /><polyline points="16 17 21 12 16 7"/><line x1="21" y1="12" x2="9" y2="12"/></svg>),
};

class LeftNavigation extends React.Component {
  state = {
    showModal: false,
  }
  
  componentDidMount() {
  }

  showModal = (job) => {
    SetMixPanel('LEFTNAV_SWITCH_COMPANIES_CLICKED');
    this.setState({
      showModal: true,
    });
  };


  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  loadLink = (e, link) => {
    const { history: { push } = {} } = this.props;
    const clickedItem = e && e.target && e.target.textContent;
    if (link && link.length > 0 && typeof link === 'string' && push) {
      const pathArray = link.split('/');
      const trackData = `LEFTNAV_${(pathArray.length > 2 ? pathArray[2] : 'app').toUpperCase()}_${(clickedItem || 'page').replace(/ /g, '_').toUpperCase()}_CLICKED`;
      SetMixPanel(trackData);
      push(link);
    }
  }

  getList = (data) => {
    const { name, onClick, svgName } = data;
    return (
      <Fragment>
        <Tooltip
          direction="right"
          triggerText={(
            <a className="bx--col-12 d-block mb-2 mt-2 p-0  text-center text-white"  href="javascript:void(0)" onClick={onClick} >
              <div>
                <div>{Svgs[svgName.replace(/ /g, '').toLowerCase()]}</div>
              </div>
            </a>
          )}
          showIcon={false}
        >
          <span className="bx--type-caption">{name}</span>
        </Tooltip>
        <div className="bx--type-legal d-sm-block d-md-none text-white">{name}</div>
      </Fragment>
    );
  } 

  render() {
    const {
      isAdmin = false,
      roleId = 0,
      companyName = 'company',
      history,
      displayMobileSidebar,
      toggleSidebar,
      companies,
      products,
      t,
    } = this.props;

    const isEnterprise = window.location.pathname.toLowerCase().indexOf('enterprise') > -1;
    const newUI = window.location.pathname.indexOf('newui') > -1;

    return (
      <div className={`bx--col-lg-1 bx--col-md-1 p-0 d-md-block leftNav ${newUI ? 'left-navigation' : ''} ${displayMobileSidebar?'d-block':'d-none'}`} style={{ maxWidth: '5.3333%' }}>
        <nav className="bg-light sidebar p-0 bx--col-12">
          <div className="sidebar-sticky p-0" onClick={() => (displayMobileSidebar?toggleSidebar(false):true)}>
            <SubHeader products={products} push={history && history.push} companyName={companyName} isAdmin={isAdmin} displayMobileSidebar={displayMobileSidebar} />
            <div className="">
              <h6 className="align-items-center text-center d-flex justify-content-center mb-1 mt-2 sidebar-heading text-light">
              <div className="bx--type-zeta font-weight-normal small">{t('manage')}</div>
              </h6>
              <ul className="nav flex-column">
                {
                  isAdmin && !isEnterprise ? (
                    <li className={`listItem ${window.location.pathname === '/app/admin' ? 'active-selected' : ''} nav-item ml-0 text-center`} style={{ lineHeight: 1 }}>
                      {this.getList({ onClick: e => this.loadLink(e, '/app/admin'), name: t('admin'), svgName: 'admin' })}
                    </li>
                  ) : null
                }
                {
                  isAdmin && roleId !== 3 && !isEnterprise ? (
                    <li className={`listItem ${window.location.pathname === '/app/campus/users/view' ? 'active-selected' : ''} nav-item ml-0 text-center`} style={{ lineHeight: 1 }}>
                      {this.getList({ onClick: e => this.loadLink(e, '/app/campus/users/view'), name: t('users'), svgName: 'users' })}
                    </li>
                  ) : null
                }
                {
                  isAdmin && roleId !== 3 && !isEnterprise ? (
                    <li className={`listItem ${window.location.pathname === '/app/campus/companies' ? 'active-selected' : ''} nav-item ml-0 text-center`} style={{ lineHeight: 1 }}>
                      {this.getList({ onClick: e => this.loadLink(e, '/app/campus/companies'), name: t('companies'), svgName: 'companies' })}
                    </li>
                  ) : null
                }
                {
                  roleId && (roleId !== 3) && !isEnterprise ? (
                    <li className={`listItem ${window.location.pathname === '/app/campus/emails' ? 'active-selected' : ''} nav-item ml-0 text-center`} style={{ lineHeight: 1 }}>
                      {this.getList({ onClick: e => this.loadLink(e, '/app/campus/emails'), name: t('templates'), svgName: 'templates' })}
                    </li>
                  ) : null
                }
                {/* {
                    roleId && roleId !== 3 && !isEnterprise ? (
                      <li className={`listItem ${window.location.pathname === '/app/campus/marketplaceapps' ? 'active-selected' : ''} nav-item ml-0 text-center`} style={{ lineHeight: 1 }}>
                        {this.getList({ onClick: e => this.loadLink(e, '/app/campus/marketplaceapps'), name: t('marketplace'), svgName: 'marketplace' })}
                      </li>
                    ) : null
                } */}
                {
                  roleId ? (
                    <li className={`listItem ${window.location.pathname === '/app/profile' ? 'active-selected' : ''} nav-item ml-0 text-center`} style={{ lineHeight: 1 }}>
                      {this.getList({ onClick: e => this.loadLink(e, '/app/profile'), name: t('myProfile'), svgName: 'myProfile' })}
                    </li>
                  ) : null
                }
                {
                  !roleId ? (
                    <li className="nav-item listItem text-center" style={{ lineHeight: 1 }}>
                      {this.getList({ onClick: e => this.loadLink(e, '/'), name: t('login'), svgName: 'login' })}
                    </li>
                  ) : null
                }
                {
                    roleId && (roleId !== 3) && isAdmin && !isEnterprise ? (
                      <li className="nav-item listItem text-center" style={{ lineHeight: 1 }}>
                        {this.getList({ onClick: this.showModal, name: t('switchCompany'), svgName: 'switchCompany' })}
                      </li>
                    ) : null
                }
                {/* {
                    roleId && (roleId !== 3) && isAdmin ? (
                      <li className={`listItem ${window.location.pathname === listItem.path ? 'active-selected' : ''} nav-item ml-0 text-center`}>
                        <a className="bx--col-12 d-block mb-2 mt-2 p-0  text-center text-white" href="javascript:void(0)" onClick={e => this.loadLink(e,'/app/campus/company')} >
                          <div>
                          <div><svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather-folder"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg></div>
                            <div><span className="bx--type-caption">{companyName} (Leaders View)</div></div></span>
                        </a>
                      </li>) : null
                } */}
                {
                  roleId ? (
                    <li className={`listItem ${window.location.pathname === '/auth/logout' ? 'active-selected' : ''} nav-item ml-0 text-center`} style={{ lineHeight: 1 }}>
                      <Tooltip
                        direction="right"
                        triggerText={(
                          <a
                            className="bx--col-12 d-block mb-2 mt-2 p-0  text-center text-white"
                            href="/auth/logout"
                          >
                            <div>
                              <div>{Svgs.logout}</div>
                            </div>
                          </a>
                        )}
                        showIcon={false}
                      >
                        <span className="bx--type-caption">{t('logout')}</span>
                      </Tooltip>
                      <div className="bx--type-legal d-sm-block d-md-none text-white">{t('logout')}</div>
                    </li>
                  ) : null
                } 
              </ul>
            </div>
            {/* <ManageLeftNav isAdmin={isAdmin} roleId={roleId} companyName={companyName} push={history && history.push} />                              */}
          </div>
        </nav>
        {this.state.showModal && <SwitchCompany show={this.state.showModal} hide={this.hideModal} />}   
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  taskStatus: state.taskStatus,
});

export default connect(mapStateToProps, {})(withTranslation()(LeftNavigation));

{/* <div className="mr-2" data-toggle="tooltip" data-placement="right" title={listItem.label}></div> */}
