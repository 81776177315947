import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'node-uuid';
import moment from 'moment';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import OfficeDetails from './officeDetails';
import LinkBox from './linkBox';
import SalaryDetails from './salaryDetails';
import JobDescription from './jobDescription';
import {
  Tag,
  Button,
  InlineNotification,
  Toggle,
  FileUploaderItem,
  Tabs, Tab
} from 'carbon-components-react';
import { View16 } from '@carbon/icons-react';
import JobTimeline from 'components/roboroy/jobInfo/JobTimeline';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import Avatar from 'components/common/Avatar';
import CandidateProfile from 'containers/roboroy/CandidateProfile';
import AutoPilotSettings from './AutoPilot';
import WorkflowSettings from './WorkflowSettings';
import JobCollaborators from './JobCollaborators';
import JobApprovals from './JobApprovals';
import AnalyticsList from '../../../containers/roboroy/AnalyticsList/AnalyticsList';
import InterviewStatus from 'containers/roboroy/ApplicantInfo/InterviewStatus';
import PercentageSlider from 'components/common/PercentageSlider';
import {
  updateSkills,
  updateEduction,
  updateExperience,
  addEduction,
  addExperience,
} from 'components/roboroy/common/candidateProfile';
import CandidateDetails from 'components/roboroy/CandidateDetails';
import ViewJobJD from 'containers/roboroy/viewJobJD';
import './editJob';
import SetMixPanel from 'components/common/GetCurrentPath';
import JobInfoCompleteDetails from "./jobInfoCompleteDetails";
import './jobInfoContainer.css';
const track = {
  JOBINFO_EDITJOB_CLICKED: 'JOBINFO_EDITJOB_CLICKED',
  JOBINFO_CLOSEJOB_CLICKED: 'JOBINFO_CLOSEJOB_CLICKED',
  JOBINFO_REOPEN_CLICKED: 'JOBINFO_REOPEN_CLICKED',
  JOBINFO_CLONE_CLICKED: 'JOBINFO_CLONE_CLICKED',
  JOBINFO_SAVEASPDF_CLICKED: 'JOBINFO_SAVEASPDF_CLICKED',
  JOBINFO_ASSOCIATELINKEDIN_CLICKED: 'JOBINFO_ASSOCIATELINKEDIN_CLICKED',
};

const Chip = ({ title }) =>
  title ? (
    <div className="bx--tag bx--tag--blue text-uppercase  mr-2">{title}</div>
  ) : null;
const EditButton = ({ job, onEditJob, t }) => {
  return (
    <Button
      small
      onClick={() => {
        SetMixPanel(track.JOBINFO_EDITJOB_CLICKED);
        return onEditJob(job);
      }}
      onFocus={() => { }}
      className={`xpa--workflow-click mt-1 ml-2 d-none d-md-block`}
      kind="tertiary"
      icon={{
        id: 'icon--edit',
        name: 'icon--edit',
        tags: 'icon--edit',
        styles: '',
        viewBox: '0 0 16 16',
        width: '16',
        height: '16',
        svgData: {
          circles: '',
          ellipses: '',
          paths: [
            {
              d:
                'M7.926 3.38L1.002 9.72V12h2.304l6.926-6.316L7.926 3.38zm.738-.675l2.308 2.304 1.451-1.324-2.308-2.309-1.451 1.329zM.002 9.28L9.439.639a1 1 0 0 1 1.383.03l2.309 2.309a1 1 0 0 1-.034 1.446L3.694 13H.002V9.28zM0 16.013v-1h16v1z',
              'fill-rule': 'nonzero',
            },
          ],
          polygons: '',
          polylines: '',
          rects: '',
        },
      }}
      iconDescription="Add">
      {t('edit')} {t('job')}
    </Button>
  );
};
const CloseButton = ({ jobId, activateCloseJobModal, t }) => (
  <Button
    small
    onClick={() => {
      SetMixPanel(track.JOBINFO_CLOSEJOB_CLICKED);
      return activateCloseJobModal(jobId);
    }}
    onFocus={() => { }}
    className={`xpa--workflow-click mt-1 mr-2 ${jobId}`}
    kind="tertiary"
    icon={{
      id: 'icon--close--outline',
      name: 'icon--close--outline',
      tags: 'icon--close--outline',
      styles: '',
      viewBox: '0 0 16 16',
      width: '16',
      height: '16',
      svgData: {
        circles: '',
        ellipses: '',
        paths: [
          {
            d:
              'M8 14.5c3.6 0 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z',
          },
          {
            d:
              'M8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6z',
          },
        ],
        polygons: '',
        polylines: '',
        rects: '',
      },
    }}
    iconDescription="Add">
    {t('close')} {t('job')}
  </Button>
);
const ReopenJobButton = ({ jobId, activateReopenJobModal, t }) => (
  <Button
    small
    onClick={() => {
      SetMixPanel(track.JOBINFO_REOPEN_CLICKED);
      return activateReopenJobModal(jobId);
    }}
    onFocus={() => { }}
    className={`xpa--workflow-click mt-1 mr-2 ${jobId}`}
    kind="tertiary"
    icon={{
      id: 'icon--restart',
      name: 'icon--restart',
      tags: 'icon--restart',
      styles: '',
      viewBox: '0 0 13 16',
      width: '13',
      height: '16',
      svgData: {
        circles: '',
        ellipses: '',
        paths: [
          {
            d:
              'M10.948 3L8.542.727 9.23 0 13 3.564 9.228 7.108l-.685-.729L11.075 4H6.5A5.5 5.5 0 1 0 12 9.5h1A6.5 6.5 0 1 1 6.5 3h4.448z',
            'fill-rule': 'nonzero',
          },
        ],
        polygons: '',
        polylines: '',
        rects: '',
      },
    }}
    iconDescription="Add">
    {t('reOpen')} {t('job')}
  </Button>
);
const CloneButton = ({ jobId, activateCloneJobModal, t }) => (
  <Button
    small
    onClick={() => {
      SetMixPanel(track.JOBINFO_CLONE_CLICKED);
      return activateCloneJobModal(jobId);
    }}
    onFocus={() => { }}
    className={`xpa--workflow-click mt-1 mr-2 ${jobId}`}
    kind="tertiary"
    icon={{
      id: 'icon--copy',
      name: 'icon--copy',
      tags: 'icon--copy',
      styles: '',
      viewBox: '0 0 16 16',
      width: '16',
      height: '16',
      svgData: {
        circles: '',
        ellipses: '',
        paths: [
          {
            d: 'M1 10H0V2C0 .9.9 0 2 0h8v1H2c-.6 0-1 .5-1 1v8z',
          },
          {
            d:
              'M11 4.2V8h3.8L11 4.2zM15 9h-4c-.6 0-1-.4-1-1V4H4.5c-.3 0-.5.2-.5.5v10c0 .3.2.5.5.5h10c.3 0 .5-.2.5-.5V9zm-4-6c.1 0 .3.1.4.1l4.5 4.5c0 .1.1.3.1.4v6.5c0 .8-.7 1.5-1.5 1.5h-10c-.8 0-1.5-.7-1.5-1.5v-10C3 3.7 3.7 3 4.5 3H11z',
          },
        ],
        polygons: '',
        polylines: '',
        rects: '',
      },
    }}
    iconDescription="Add">
    {t('clone')} {t('job')}
  </Button>
);
const DownloadJDButton = ({ jobId, triggerJDDownload, t }) => (
  <Button
    small
    onClick={() => {
      SetMixPanel(track.JOBINFO_SAVEASPDF_CLICKED);
      return triggerJDDownload(jobId);
    }}
    onFocus={() => { }}
    className={`xpa--workflow-click mt-1 mr-2 ${jobId}`}
    kind="tertiary"
    icon={{
      id: 'icon--download',
      name: 'icon--download',
      tags: 'icon--download',
      styles: '',
      viewBox: '0 0 14 16',
      width: '14',
      height: '16',
      svgData: {
        circles: '',
        ellipses: '',
        paths: [
          {
            d:
              'M7.5 11l4.1-4.4.7.7L7 13 1.6 7.3l.7-.7L6.5 11V0h1v11zm5.5 4v-2h1v2c0 .6-.4 1-1 1H1c-.6 0-1-.4-1-1v-2h1v2h12z',
          },
        ],
        polygons: '',
        polylines: '',
        rects: '',
      },
    }}
    iconDescription="Add">
    {t('saveAsPdf')}
  </Button>
);

const AssociateLinkedin = ({ jobId, activateAssociateJobModal }) => (
  <Button
    small
    disabled
    onClick={() => {
      SetMixPanel(track.JOBINFO_ASSOCIATELINKEDIN_CLICKED);
      return activateAssociateJobModal(jobId);
    }}
    className={`xpa--workflow-click mt-1 mr-2 ${jobId}`}
    kind="tertiary"
    icon={{
      id: 'icon--link',
      name: 'icon--link',
      tags: 'icon--link',
      styles: '',
      viewBox: '0 0 16 13',
      width: '16',
      height: '13',
      svgData: {
        circles: '',
        ellipses: '',
        paths: [
          {
            d:
              'M9.3 7.1l-.7-.7c.1-.1.2-.2.6-.5.9-.8.9-2.2.1-3l-.1-.1c-.8-.8-2.3-.8-3.1.1L1.6 7.3c-.9.9-.9 2.3 0 3.1l.2.2c.8.8 2 .8 2.8.1l.7.7c-1.2 1.1-3 1-4.2-.1l-.2-.2C-.3 9.9-.3 7.8.9 6.6l4.4-4.4C6.6.9 8.6.9 9.9 2.2l.1.1c1.2 1.3 1.1 3.2-.1 4.4-.3.2-.5.4-.6.4z',
          },
          {
            d:
              'M6.8 4.7l.7.7-.7.7c-.9.9-.9 2.3 0 3.2.9.9 2.3.9 3.1 0l4.5-4.5c.9-.9.9-2.3 0-3.1l-.1-.1c-.7-.7-1.9-.7-2.7 0l-.1.1-.8-.7.1-.1c1.1-1.1 3-1.1 4.1 0l.2.1c1.2 1.2 1.2 3.3 0 4.5L10.6 10c-1.2 1.2-3.3 1.2-4.5 0-1.3-1.3-1.3-3.3 0-4.5l.7-.8z',
          },
        ],
        polygons: '',
        polylines: '',
        rects: '',
      },
    }}
    iconDescription="Associate with linkedin Job Id">
    Associate Linkedin Job
  </Button>
);

const AiSourcing = ({ job = {}, user = {} }) => {
  const {
    sourcing: {
      previousCompanies = [],
      excludeCompanies = [],
      currentCompany = {},
      industries = [],
    } = {},
    industry = {},
    jobAdditionalInfo: { secondMajor = {}, minumumGpaRequirement = '' } = {},
    major = {},
    companyType = {},
  } = job;
  const currCompany =
    currentCompany !== null && Object.keys(currentCompany).length > 0
      ? currentCompany
      : job.currentCompany;
  return (
    <div className="xp-m__job--container">
      <p className="bx--type-omega text-dark">
        In addition to the information provided in the job posting, the
        following parameters are applied when sourcing candidates for this job.
        <div>To edit this information, click on the Edit Job button.</div>
      </p>

      <div className="row pb-2">
        <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
          <h4 className="bx--modal-header__label mt-2">Current Company</h4>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9  text-left">
          <Tag type="blue">
            <span className="text-uppercase">
              {currCompany && currCompany.companyName
                ? currCompany.companyName
                : 'Any'}
            </span>
          </Tag>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
          <h4 className="bx--modal-header__label mt-2">Industries</h4>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9  text-left">
          {industries && industries !== null && (
            <div className="row pb-2">
              <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                {Array.isArray(industries) && industries.length > 0 ? (
                  industries.map((cmp, index) => (
                    <Tag type="blue" key={index}>
                      <span className="text-uppercase">
                        {cmp && cmp.sourcingindustriesName}
                      </span>
                    </Tag>
                  ))
                ) : (
                  <Tag type="blue">
                    <span className="text-uppercase">Any</span>
                  </Tag>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
          <h4 className="bx--modal-header__label mt-2">First Major</h4>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9  text-left">
          <Tag type="blue">
            <span className="text-uppercase">
              {major && major.majorName ? major.majorName : 'Any'}
            </span>
          </Tag>
        </div>
      </div>
      {user && user.roleId === 3 && (
        <div className="row pb-2">
          <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
            <h4 className="bx--modal-header__label mt-2">Company Type</h4>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9  text-left">
            <Tag type="blue">
              <span className="text-uppercase">
                {companyType && companyType.typeName
                  ? companyType.typeName
                  : 'Any'}
              </span>
            </Tag>
          </div>
        </div>
      )}
      {previousCompanies && previousCompanies !== null && (
        <div className="row pb-2">
          <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
            <h4 className="bx--modal-header__label mt-2">Previous Companies</h4>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9  text-left">
            {Array.isArray(previousCompanies) &&
              previousCompanies.length > 0 ? (
              previousCompanies.map((cmp, index) => (
                <Tag type="experimental" key={index}>
                  <span className="text-uppercase">
                    {cmp && cmp.companyName}
                  </span>
                </Tag>
              ))
            ) : (
              <Tag type="blue">
                <span className="text-uppercase">Any</span>
              </Tag>
            )}
          </div>
        </div>
      )}
      {excludeCompanies && excludeCompanies !== null && (
        <div className="row pb-2">
          <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
            <h4 className="bx--modal-header__label mt-2">Exclude Companies</h4>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9  text-left">
            {Array.isArray(excludeCompanies) && excludeCompanies.length > 0 ? (
              excludeCompanies.map(
                (cmp, index) =>
                  cmp && (
                    <Tag type="experimental" key={index}>
                      <span className="text-uppercase">
                        {cmp && cmp.companyName}
                      </span>
                    </Tag>
                  )
              )
            ) : (
              <Tag type="blue">
                <span className="text-uppercase">Any</span>
              </Tag>
            )}
          </div>
        </div>
      )}
    </div>
  );
};


const EmailJob = ({ jobId, activeEmailJobModal, t }) => (
  <Button
    small
    onClick={() => activeEmailJobModal(jobId)}
    className={`xpa--workflow-click mt-1 mr-2 ${jobId}`}
    kind="tertiary"
    icon={{
      id: 'icon--email',
      name: 'icon--email',
      tags: 'icon--email',
      styles: '',
      viewBox: '0 0 16 11',
      width: '16',
      height: '11',
      svgData: {
        circles: '',
        ellipses: '',
        paths: [
          {
            d:
              'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
          },
        ],
        polygons: '',
        polylines: '',
        rects: '',
      },
    }}
    iconDescription="Associate with linkedin Job Id">
    {t('emailThisJob')}
  </Button>
);

const JobInfoTabClick = ({
  activeSelectedTab,
  handleJobInfoTabsClick,
  dashboard,
  roleId,
  isClosed,
  canApplytoJob,
  application,
  t,
  isAdmin,
  ctypeId,
}) => {
  let accessLevel = 0;
  if (roleId == 4 || roleId == 1 || roleId == 10) {
    accessLevel = 5;
  } else if (roleId !== 3 && roleId !== 10) {
    accessLevel = 1;
  } else if (roleId == 3) {
    accessLevel = 0;
  }
  let tabContent = [
    {
      label: `${t('job')} ${t('details')}`,
      value: 'details',
      // className: 'xpa-cand-info--int-status ml-0',
      // onClick: () => handleJobInfoTabsClick('details'),
    },
  ];

  if (dashboard && accessLevel == 5) {
    tabContent.push(
      {
        label: `${t('collaborators')}`,
        value: 'collaborators',
        // className: 'xpa-cand-info--int-status ml-0',
        // onClick: () => handleJobInfoTabsClick('collaborators'),
      },
      {
        label: `${t('timeline')}`,
        value: 'timeline',
        // className: 'xpa-cand-info--int-status ml-0',
        // onClick: () => handleJobInfoTabsClick('timeline'),
      }
    );
  }

  if (dashboard && !isClosed && accessLevel == 5) {
    tabContent
      .push
      // {
      //   label: 'Auto-Pilot Settings',
      //   className: 'xpa-cand-info--int-status ml-0',
      //   value: '',
      //   onClick: () => handleJobInfoTabsClick('auto-pilot-settings'),
      // },
      ();
  }

  if (accessLevel == 5 && roleId !== 10) {
    tabContent.push({
      label: `${t('workflowSettings')}`,
      value: 'workflow-settings',
      // className: 'xpa-cand-info--int-status ml-0',
      // onClick: () => handleJobInfoTabsClick('workflow-settings'),
    });
  }

  if (
    dashboard &&
    !isClosed &&
    accessLevel == 5 &&
    ctypeId &&
    Number(ctypeId) !== 1 &&
    isAdmin === true
  ) {
    tabContent.push(
      // {
      //   label: 'Analytics',
      //   className: 'xpa-cand-info--analytics ml-0',
      //   value: '',
      //   onClick: () => handleJobInfoTabsClick('analytics'),
      // },
      // {
      //   label: 'AI Sourcing',
      //   className: 'xpa-cand-info--int-status ml-0',
      //   value: '',
      //   onClick: () => handleJobInfoTabsClick('ai-sourcing'),
      // },
      // {
      //   label: `${t('jobApproval')}`,
      //   value: 'approvals',
      //   // className: 'xpa-cand-info--int-status ml-0',
      //   // onClick: () => handleJobInfoTabsClick('approvals'),
      // }
    );
  }

  if (accessLevel === 0 && canApplytoJob === false) {
    tabContent.push({
      label: `${t('myApplications')}`,
      value: 'application',
      // className: 'xpa-cand-info--int-status ml-0',
      // onClick: () => handleJobInfoTabsClick('application'),
    });
  }

  if (
    accessLevel === 0 &&
    canApplytoJob === false &&
    application !== null &&
    application !== undefined
  ) {
    tabContent.push({
      label: `${t('interview')} ${t('status')}`,
      value: 'interview-status',
      // className: 'xpa-cand-info--int-status ml-0',
      // onClick: () => handleJobInfoTabsClick('interview-status'),
    });
  }

  return (
    <Tabs selected={0} className="xpa-cand-info--tabs xpa-c-tabs--vertical m-0">
      {
        tabContent.map((indTab) => {
          return <Tab className="xpa-cand-info--int-status ml-0" label={<span>{indTab.label}</span>} onClick={() => handleJobInfoTabsClick(indTab.value)} ></Tab>
        })
      }
    </Tabs>
  );
};

const JobInfoContainer = ({
  job = {},
  user = {},
  isAuthenticated,
  isOpenApplyJobModal = false,
  activateApplyToJobModal,
  dismissApplyTobJobModal,
  onUploadSuccess,
  finlandProfileId = null,
  onEditJob,
  approvalList,
  activateCloneJobModal,
  dismissCloneJobModal,
  activateCloseJobModal,
  activateReopenJobModal,
  triggerJDDownload,
  activateAssociateJobModal,
  onToggleClick,
  isPrivateJob,
  activeEmailJobModal,
  onAutoPilotToggleClick,
  isAutoPilotMode,
  isAutoShortlistMode,
  handleAutoShortlistToggle,
  closeJobMessage,
  showAnalyticsModule,
  displayAnalytics,
  isAISourcingEnabled,
  aiSourcingToggle,
  getJobTimeLine,
  application,
  interviewflow,
  value,
  push,
  onPreferredToggleClick,
  timeLine,
  profileId,
  offerAcceptedCount,
  activeSelectedTab,
  t,
  handleActiveSelectedTab,
  handleJobInfoTabsClick,
  workflowstages,
  handleGetJobInfo,
  recommendedJobs,
  jobsAppliedCount,
  totalJobsApplicable,
  hideJobActns,
}) => {
  const {
    office,
    vacancy,
    requiredExp,
    department,
    qualificationtype,
    jobDesc,
    jobReq,
    benefit,
    jobId,
    dashboard,
    jobname = {},
    jobfunction,
    term,
    hourtype,
    externaljobmapping = {},
    jobseniority,
    internalCode,
    company,
    region,
    country,
    closeDate,
    skills,
    status,
    isClosed,
    linkedinJobId,
    isPrivate,
    reasonForClosure,
    canApplytoJob = true,
    draft,
    preferred,
    scores,
    isJobVacancyAvailable,
    approvedVacancies,
    facultyApprovedVacancies,
    jobRejected
  } = job;
  let showErrorMsg = false

  // if (approvedVacancies != null && jobRejected == true) {
  //   showErrorMsg = true
  // }
  // else if (approvedVacancies == null && jobRejected == true) {
  //   showErrorMsg = true
  // }
  const {
    commuteTime,
    choicesRecommendation,
  } = scores || {};
  const { currentCompany, roleId, isAdmin, isSuperAdmin, facultyId } = user || {};
  const { ctypeId } = currentCompany || {};
  const {
    candidatesUploadStatus,
    createdTimestamp,
    updatedTimestamp,
    insightDetails: {
      response: { timeTaken, totalRecordsFound, totalRecordsTaken } = {},
    } = {},
  } = externaljobmapping;
  const createdTimeAi = parseInt(
    moment(new Date())
      .utc()
      .format('x'),
    10
  );
  const updatedTime =
    parseInt(
      moment(updatedTimestamp)
        .utc()
        .format('x'),
      10
    ) + 10800000;
  const finalTime = createdTimeAi > updatedTime;
  let { countryFull, countryId } = { countryFull: '', countryId: 0 };
  if (country) {
    countryFull = country.countryFull;
    countryId = country.countryId;
  }
  let appVacancies;
  let approvVacancies = [];
  if (facultyApprovedVacancies && Array.isArray(facultyApprovedVacancies) && facultyApprovedVacancies.length == 0) {
    showErrorMsg = true
  }

  if (facultyApprovedVacancies && Array.isArray(facultyApprovedVacancies) && facultyApprovedVacancies.length > 0) {
    facultyApprovedVacancies.forEach((res) => {
      let appFacultyId = res && res.facultyId
      appVacancies = res && res.facultyApprovedVacancies
      appVacancies = appVacancies && typeof appVacancies === 'string' ? parseInt(appVacancies) : appVacancies
      if (!isSuperAdmin && appFacultyId == facultyId) {
        approvVacancies.push(appVacancies)
      }
      else if (isSuperAdmin || ctypeId && Number(ctypeId) == 1) {
        if (appVacancies != 0) {
          approvVacancies.push(appVacancies)
        }
      }
    })
    if (approvVacancies && Array.isArray(approvVacancies) && approvVacancies.length > 0 && approvVacancies.includes(0) || approvVacancies.length == 0) {
      showErrorMsg = true
    }
  }
  const jobLinks = [
    {
      url: '1/applied',
      linkTitle: 0,
      title: 'X0PA Scores',
    },
    {
      url: dashboard && dashboard.psychometricTest,
      linkTitle: dashboard && dashboard.psychometricTest,
      title: 'Psychometric',
    },
    {
      url: dashboard && dashboard.videoInterview,
      linkTitle: dashboard && dashboard.videoInterview,
      title: 'Video Interviews',
    },
  ];

  const applicantLinks = [
    {
      url: `/app/campus/jobs/${jobId}/applied`,
      linkTitle: dashboard && dashboard.totalApplications,
      title: `${t('applications')}`,
      classNames: 'joblist__cnt--total',
    },
    {
      url: `/app/campus/jobs/${jobId}/applied?applicationStatus=shortlisted`,
      linkTitle: dashboard && dashboard.numOfShortlistedApplications,
      title: `${t('shortlisted')}`,
      classNames: 'joblist__cnt--short',
    },
    {
      url: `/app/campus/jobs/${jobId}/rejected`,
      linkTitle: dashboard && dashboard.numOfRejectedApplications,
      title: `${t('rejected')}`,
      classNames: 'joblist__cnt--rejected',
    },
    {
      url: `/app/campus/jobs/${jobId}/applied?hiringStatus=is-hired&`,
      linkTitle: dashboard && dashboard.hiredApplns,
      title: `${t('hired')}`,
      classNames: 'joblist__cnt--hired',
    },
  ];
  let accessLevel = 0;
  if (roleId == 4 || roleId == 1) {
    accessLevel = 5;
  } else if (roleId !== 3) {
    accessLevel = 1;
  } else if (roleId == 3) {
    accessLevel = 0;
  }
  return (
    <Fragment>
      <div>
        {isClosed && (
          <div>
            <InlineNotification
              lowContrast
              className="mt-1 mb-1"
              title="This job is now closed and not accepting any applications."
              subtitle=""
              iconDescription="describes the close button"
              kind="warning"
              hideCloseButton
            />
            <hr />
          </div>
        )}
        {hideJobActns === true && (showErrorMsg == true) && (
          <div>
            <InlineNotification
              lowContrast
              className="mt-1 mb-1"
              title="This job posting is not approved by RP."
              subtitle=""
              iconDescription="describes the close button"
              kind="error"
              hideCloseButton
            />
            <hr />
          </div>
        )}
        {/*draft === true &&
          accessLevel == 5 &&
          !isClosed &&
          hideJobActns === false && showErrorMsg == true && (
            <div>
              <InlineNotification
                lowContrast
                className="mt-1 mb-1"
                // title="This job is currently being reviewed by Republic Polytechnic."
                title="This job posting has yet to be approved by RP"
                subtitle=""
                iconDescription="describes the close button"
                kind="warning"
                hideCloseButton
              />
              <hr />
            </div>
          )*/}
        {/*
          (isAuthenticated && (accessLevel == 5)) && !isClosed && dashboard && job && !job.workflowstages && (
            <div>
              <InlineNotification lowContrast
                title={t('setUpJobLvlWorkflowMsg')}
                subtitle=""
                iconDescription="describes the close button"
                kind="error"
                hideCloseButton
              />
              <hr />
            </div>
          )*/}
        <div className="xp-m__jobinfo--hero">
          <div className="">
            <div className="row pb-2">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="">
                  <div className="text-dark ml-2">
                    <small title="Job">{`${t(
                      'requisitionId'
                    )}: ${jobId}`}</small>
                  </div>
                  <div className="card-title h5 mb-1 ml-2 d-inline-block text-center text-md-left font-weight-bold text-capitalize">
                    {jobname.nameName}
                    {
                      choicesRecommendation === true && (
                        <div className="bx--tag bx--tag--yellow ml-2">
                          {t('recommended')}
                        </div>
                      )
                    }
                  </div>
                  <div className="card-subtitle pt-1">
                    {skills &&
                      Array.isArray(skills) &&
                      skills.map((res, idx) => {
                        const { skill: { skillName } = {} } = res || {};
                        return (
                          <Fragment>
                            {idx <= 2 && <Chip title={skillName} />}
                          </Fragment>
                        );
                      })}
                  </div>
                  <div className="d-flex align-items-center ml-1">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: 16, height: 16 }}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-map-pin">
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                        <circle cx="12" cy="10" r="3" />
                      </svg>
                    </div>
                    <div className="ml-1 mt-2 text-uppercase font-weight-bold text-dark">
                      {countryFull}
                    </div>
                  </div>
                  {/* <div className="card-subtitle pt-1">
                    <Chip title={jobseniority ? jobseniority.seniorityName : ''} />
                    <Chip title={hourtype ? hourtype.typeName : ''} />
                    <Chip title={term ? term.termName : ''} />
                  </div> */}
                </div>
              </div>
              {isAuthenticated && dashboard && accessLevel > 0 && (
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 pt-2">
                  <div className="job-applicant-info">
                    <div className="row justify-content-around pb-2">
                      {applicantLinks.map(link => (
                        <LinkBox key={v4()} push={push} details={link} />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {!isAuthenticated && !isClosed && (
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 pt-2">
                  <div className="job-apply">
                    <div className="row pb-2 d-block text-center">
                      {country &&
                        country.countryFull &&
                        country.countryFull.toLowerCase().indexOf('finland') ===
                        -1 ? (
                        <Link
                          className="bx--btn bx--btn--primary"
                          to={{
                            pathname: '/',
                            search:
                              '?r=' +
                              encodeURIComponent(`roboroy/job/${jobId}`),
                          }}>
                          {t('applyToJob')}
                        </Link>
                      ) : finlandProfileId === null ? (
                        <Link
                          className="bx--btn bx--btn--primary"
                          to="/app/campus/candidate/business/finland/start">
                          {t('applyToJob')}
                        </Link>
                      ) : (
                        <Link
                          className="bx--btn bx--btn--primary"
                          to={{
                            pathname: '/',
                            search:
                              '?r=' +
                              encodeURIComponent(`roboroy/job/${jobId}`),
                          }}>
                          {t('applyToJob')}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {isAuthenticated && accessLevel == 0 && (
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 pt-2">
                  <div className="job-apply">
                    <div className="row pb-2 d-block text-center">
                      <div className="column">
                        {
                          roleId && Number(roleId) === 3 ? (
                            <div>
                              <span className="text-uppercase small font-weight-bold">Estimated commute time:</span>&nbsp;&nbsp;<span className="bx--tag bx--tag--purple">{(commuteTime && commuteTime > 0) ? `${Math.round(commuteTime)} mins` : 'Not Available'}</span>
                            </div>
                          ) : null
                        }
                        {canApplytoJob &&
                          !isClosed &&
                          isJobVacancyAvailable === true &&
                          offerAcceptedCount <= 0 &&
                          (totalJobsApplicable
                            ? (jobsAppliedCount && Number(jobsAppliedCount)) <
                            Number(totalJobsApplicable)
                            : true) && (
                            <div
                              data-toggle="modal"
                              data-target="#xpa-modal--resume-upload"
                              className="bx--btn bx--btn--primary"
                              onClick={activateApplyToJobModal}>
                              <span>{t('applyToJob')}</span>
                            </div>
                          )}
                        {!canApplytoJob && (
                          <div className="bx--btn bx--type-caps text-dark">
                            <svg width="10" height="8" viewBox="0 0 10 8">
                              <path d="M8.498 0L10 1.502 3.672 7.83 0 4.158l1.502-1.502 2.17 2.17z"></path>
                            </svg>
                            <span className="pl-1">{t('jobsAppliedTab')}</span>
                          </div>
                        )}
                      </div>
                      {/* <div className="column mt-2">
                          <div className="bx--btn bx--btn--tertiary" onClick={activeEmailJobModal} >
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;Email this Job&nbsp;&nbsp;</span>
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>
              )}
              {isAuthenticated && (
                <ResumeUpload
                  pageType="candidateApplication"
                  jobId={jobId}
                  finlandProfileId={finlandProfileId}
                  country={
                    country && country.countryFull ? country.countryFull : ''
                  }
                  dismiss={dismissApplyTobJobModal}
                  active={isOpenApplyJobModal}
                  onUploadSuccess={onUploadSuccess}
                />
              )}
            </div>
            <div className="xp-m__jobinfo--hero-actions">
              <div className="bx--row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {isAuthenticated && accessLevel == 5 && dashboard && (
                  <hr className="m-0 mb-2" />
                )}
                {/* {(isAuthenticated && (accessLevel == 5)) && dashboard && (
                  <DownloadJDButton jobId={jobId}
                    t={t}
                    triggerJDDownload={triggerJDDownload} />
                  )
                } */}
                {isAuthenticated &&
                  roleId &&
                  Number(roleId) !== 3 &&
                  (isAdmin === true || isSuperAdmin === true) &&
                  !isClosed &&
                  hideJobActns === false &&
                  dashboard && (
                    <EditButton job={job} t={t} onEditJob={onEditJob} />
                  )}
                {(isAuthenticated && (ctypeId && (Number(ctypeId) === 1 || Number(ctypeId) === 2)) && (hideJobActns === false || (hideJobActns === true)) && (roleId == 4)) && dashboard && (
                  <div className="mb-0 ml-2">
                    <CloneButton 
                      jobId={jobId}
                      t={t}
                      activateCloneJobModal={activateCloneJobModal}
                      dismissCloneJobModal={dismissCloneJobModal} />
                  </div>

                )
                }
                {/*{(isAuthenticated && (ctypeId && Number(ctypeId) === 1)) && hideJobActns === false && isClosed && dashboard && (
                  <ReopenJobButton jobId={jobId}
                      t={t}
                      activateReopenJobModal={activateReopenJobModal}
                    />
                  )
                } */}
                {isAuthenticated && (ctypeId && (Number(ctypeId) === 1 || Number(ctypeId) === 2)) && roleId && Number(roleId) !== 3 && !isClosed && dashboard && ( <Fragment>
                  <CloseButton jobId={jobId} activateCloseJobModal={activateCloseJobModal} t={t} />
                  {/*<AssociateLinkedin
                    jobId={jobId}
                    activateAssociateJobModal={activateAssociateJobModal}
                  />
                  <EmailJob
                  t={t}
                  jobId={jobId}
                  activeEmailJobModal={activeEmailJobModal}
                  />*/}
                  </Fragment>
                )
                } 
              </div>
            </div>
          </div>
        </div>
        <hr />
        {isAuthenticated &&
          accessLevel === 0 &&
          recommendedJobs &&
          Array.isArray(recommendedJobs) &&
          recommendedJobs.length > 0 && (
            <div className="mt-2">
              <div className="d-flex pt-1 mb-0">
                <div>
                  <div className="h2 font-weight-bold text-dark pt-2 m-0 text-center">
                    {Math.round(recommendedJobs[0].skillonlymatch) || 0}%
                  </div>
                  <div className="font-weight-bold text-dark">
                    Skill Relevance
                  </div>
                </div>
                <div
                  className="ml-2"
                  style={{
                    borderLeft: '1px solid #ccc',
                    height: '75px',
                  }}></div>
                <div className="ml-2 mt-2">
                  <ul class="m-0 p-0">
                    <li class="bx--type-zeta font-weight-normal pb-1">
                      Our algorithms predict that your skill relevance for this
                      job is{' '}
                      {Math.round(recommendedJobs[0].skillonlymatch) || 0}%.
                    </li>
                    <li class="bx--type-zeta font-weight-normal pb-1">
                      Your experience{' '}
                      {recommendedJobs[0] &&
                        recommendedJobs[0].skill_match_breakdown &&
                        recommendedJobs[0].skill_match_breakdown
                          .seniority_penalty >= -50
                        ? 'matches'
                        : 'does not match'}{' '}
                      the experience requirements for this job.
                    </li>
                    <li class="bx--type-zeta font-weight-normal pb-1">
                      Your location{' '}
                      {recommendedJobs[0] &&
                        recommendedJobs[0].skill_match_breakdown &&
                        recommendedJobs[0].skill_match_breakdown
                          .location_penalty >= 0
                        ? 'matches'
                        : 'does not match'}{' '}
                      the location requirements for this job.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        {/* { isAuthenticated && (accessLevel == 5) && !isClosed && dashboard ? (
        <div className="">
          <div className="bx--row align-items-center ml-2 mr-4 pr-2 justify-content-between">
            <div className="bx--col-xs-8">
              <strong>{t('visibility')}</strong>
              <div><small className="text-dark">{t('jobVisbilityMsg')}</small></div>
            </div>

            <div className="bx--col-xs-4 text-right">
              <Toggle
                id="togglePrivate"
                className="xpa-toggle"
                onToggle={onToggleClick}
                toggled={isPrivateJob}
                labelA={t('public')}
                labelB={t('private')}
              />
            </div>
          </div>
          <hr />
        </div>) : null
        } */}
        {roleId &&
          Number(roleId) !== 3 &&
          ctypeId &&
          draft === false &&
          Number(ctypeId) === 2 &&
          (isAdmin === true || isSuperAdmin === true) && 
          // approvedVacancies != null &&
          hideJobActns === false && (
            <div className="">
              <div className="bx--row align-items-center ml-2 mr-4 pr-2 justify-content-between">
                <div className="bx--col-xs-8">
                  <strong>{t('jobVisStd')}</strong>
                  {/* <div><small className="text-dark">{t('jobVisStd')}</small></div> */}
                </div>

                <div className="bx--col-xs-4 text-right">
                  <Toggle
                    id="togglePreferred"
                    className="xpa-toggle"
                    onToggle={onPreferredToggleClick}
                    toggled={preferred}
                    labelA={t('no')}
                    labelB={t('yes')}
                  />
                </div>
              </div>
              <hr />
            </div>
          )}
        {/* { (accessLevel == 5) && !isClosed && isAuthenticated ? (<div className="mt-2 tile-min-height xp-m__jobinfo--private-toggle">
          <div className="bx--row align-items-center">
            <div className="bx--col-xs-8">
              <div className="d-flex">
              <strong>Enable AI Sourcing</strong>
              <div className="ml-2">
                {
                  !isAISourcingEnabled && (
                    <span className="badge badge-secondary">Stopped</span>
                  )
                }
                {
                  isAISourcingEnabled
                  && candidatesUploadStatus
                  && !finalTime
                  && (
                    <span className="badge badge-warning">In Progress</span>
                  )
                }
                {
                  isAISourcingEnabled
                  && !candidatesUploadStatus
                  && (
                    <span className="badge badge-dark">Started</span>
                  )
                }
                {
                  isAISourcingEnabled
                    && candidatesUploadStatus
                    && finalTime
                    && (
                      externaljobmapping.status === 'SUCCESS' ? <span className="badge badge-success ml-1">Completed</span> : <span className="badge badge-danger ml-1">Failed</span>
                    )
                }
              </div>
              </div>
              <div className="bx--type-caption">Total candidates found matching this criteria <b>{totalRecordsFound}</b>.</div>
              <div><small className="text-dark">Let our AI based sourcing algorithms find the best candidates for the job. Pronto!</small></div>
            </div>
            <div className="bx--col-xs-4 text-right">
              <Toggle
                id="toggleAISourcing"
                className="xpa-toggle"
                onToggle={aiSourcingToggle}
                toggled={isAISourcingEnabled}
                labelA="No"
                labelB="Yes"/>
            </div>
          </div>
        </div>) : null
        } */}
        {/* { isAuthenticated && (accessLevel == 5) && !isClosed && dashboard ? (<div className="mt-2 tile-min-height xp-m__jobinfo--private-toggle">
          <div className="bx--row align-items-center">
            <div className="bx--col-xs-8">
              <strong>Enable Auto-Pilot Mode</strong>
              <div><small className="text-dark">Super-charge your hiring! Setup workflow and video interview once per job and let automation take over the rest.</small></div>
            </div>
            <div className="bx--col-xs-4 text-right">
              <Toggle
                id="toggleAutoPilot"
                className="xpa-toggle"
                onToggle={onAutoPilotToggleClick}
                toggled={isAutoPilotMode}
                labelA="No"
                labelB="Yes"/>
            </div>
          </div>
        </div>) : null
        } */}
        {isClosed && reasonForClosure && (
          <div className="mt-2">
            <div className="">
              <strong>Reason For closing this job:</strong>
              <p className="text-justify">{reasonForClosure}</p>
            </div>
          </div>
        )}
        <div className="row m-0 p-0 mt-2 xp-m__job--container">
          <div className="p-3 col-12 col-lg-3 col-xl-2 col-md-4 col-xl-2 xpa-bg--gray12">
            <JobInfoTabClick
              t={t}
              activeSelectedTab={activeSelectedTab || 0}
              dashboard={dashboard}
              isClosed={isClosed}
              roleId={roleId}
              handleJobInfoTabsClick={handleJobInfoTabsClick}
              canApplytoJob={canApplytoJob}
              application={application}
              ctypeId={ctypeId}
              isAdmin={user && user.isAdmin}
            />
          </div>
          {activeSelectedTab === 0 && (
            <div className="col-sm-12 col-lg-9 col-xl-10 col-md pr-0">
              <JobInfoCompleteDetails job={job} user={user} t={t} />
            </div>
          )}
          {
            activeSelectedTab === 6
            && (
              <div className="col-sm-12 col-md pr-0 xp-m__job--container"><JobCollaborators getJobTimeLine={getJobTimeLine} job={job} /></div>
            )
          }
          {activeSelectedTab === 1 && (
            <div className="col-sm-12 col-md pr-0">
              <div className="xp-m__job--container">
                <JobTimeline
                  timeLine={timeLine}
                  handleActiveSelectedTab={handleActiveSelectedTab}
                />
              </div>
            </div>
          )}
          {/* {
            activeSelectedTab === 3
              && (
                <div className="col-sm-12 col-md pr-0">
                  <AutoPilotSettings
                    job={job}
                    isAutoPilotMode={isAutoPilotMode}
                    isAutoShortlistMode={isAutoShortlistMode}
                    handleAutoShortlist={handleAutoShortlistToggle}
                    />
                </div>
              )
          } */}
          {activeSelectedTab === 2 && (
            <div className="col-sm-12 col-md pr-0">
              <div className="xp-m__job--container">
                <WorkflowSettings
                  job={job}
                  isAutoPilotMode={isAutoPilotMode}
                  isAutoShortlistMode={isAutoShortlistMode}
                  handleAutoShortlist={handleAutoShortlistToggle}
                  allWorkflowStages={workflowstages}
                  handleGetJobInfo={handleGetJobInfo}
                  user={user}
                />
              </div>
            </div>
          )}
          {/* {
            activeSelectedTab === 5
              && (
                showAnalyticsModule && (
                  <div className="col-sm-12 col-md pr-0">
                    <AnalyticsList
                    jobID={jobId}
                    applicationID={null}
                  />
                  </div>
                  ))
          } */}
          {/* {
            activeSelectedTab === 6 && (
              <div className="col-sm-12 col-md pr-0 xp-m__job--container">
                <AiSourcing job={job} user={user} />
              </div>
            )
          } */}
          {activeSelectedTab === 3 && (
            <div className="col-sm-12 col-md-8 col-lg-9 col-xl-10 pr-0 xp-m__job--container">
              <JobApprovals jobId={jobId} job={job} vacancy={vacancy} approvalList={approvalList} />
            </div>
          )}
          {activeSelectedTab === 4 && (
            <div className="col-sm-12 col-md-8 col-lg-9 col-xl-10 pr-0 ">
              <div className="xp-m__job--container ml-4">
                <CandidateProfile profileId={profileId} noNameImg />
                <div className="text-left p-2">
                  <CandidateDetails
                    notEditable
                    profileId={profileId}
                    roleId={roleId}
                    updateSkills={updateSkills}
                    updateEduction={updateEduction}
                    updateExperience={updateExperience}
                    addEduction={addEduction}
                    addExperience={addExperience}
                    finland={roleId !== 3}
                    showSkillRating
                  />
                </div>
              </div>
            </div>
          )}
          {application &&
            application !== undefined &&
            application !== null &&
            activeSelectedTab === 5 && (
              <div className="col-sm-12 col-md pr-0 ">
                <div className="xp-m__job--container">
                  <InterviewStatus
                    application={application}
                    interviewflow={interviewflow}
                    push={push}
                    // handleCandidateShortlist={this.handleCandidateShortlist}
                    value={value}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </Fragment>
  );
};

export default JobInfoContainer;
