import {
  REQUEST_RECRUITERS_LIST,
} from '../actionTypes';
import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';

const getRecruitersList = data => (dispatch) => {
  const {
    companyId,
    itemsPerPage = 10,
    activePage = 0,
    searchKey = null,
  } = data;
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  const url = `${baseUrl}/userinfos?companyId=${companyId}&include=jobs&filter[limit]=${itemsPerPage}&filter[skip]=${skip}&${
    (searchKey) ? `searchKey=${searchKey}` : ''
  }`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_RECRUITERS_LIST,
  };
  return callApi(params);
};

export default getRecruitersList;
