import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  emailLogoSection, emailBodyStart,
  emailBodyEnd, dummyEmailPlaceholders,
} from 'containers/common/constants';
import { getCompanyInfo,} from 'actions/admin/AdminSettings';

import {
  Tile, Form, TextInput, Checkbox,
  Button, InlineNotification,
} from 'carbon-components-react';
import {
  EditorState, convertToRaw, ContentState, convertFromRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import BackRow from 'components/common/BackRow/BackRow';
import {
  getEmailTemplate, sendEmailTemplate,
} from 'actions/email-templates';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

class SendEmail extends Component {
  constructor(props) {
    super(props);
    const editorState = EditorState.createEmpty();
    const footerEditorState = EditorState.createEmpty();
    this.state = {
      emailSubject: '',
      emailBody: '',
      emailFooter: '',
      invalidemailSubject: false,
      invalidemailBody: false,
      invalidemailFooter: false,
      editorState,
      footerEditorState,
      activateTemplate: true,
      showNotification: false,
      notificationTitle: 'Email template saved successfully',
      errorType: 'success',
    };
    this.notifRef = React.createRef();
  }

  componentDidMount = () => {
    const { match: { params: { templateName } = {} } = {} } = this.props;
    if (templateName && templateName === 'x') {
      return;
    }
    this.props.getEmailTemplate(templateName)
      .then((res) => {
        let editorState = EditorState.createEmpty();
        let footerEditorState = EditorState.createEmpty();
        if (res && res.emailBody) {
          const contentBlock = htmlToDraft(res.emailBody);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
          }
        }
        if (res && res.emailFooter) {
          const contentBlock = htmlToDraft(res.emailFooter);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            footerEditorState = EditorState.createWithContent(contentState);
          }
        }
        this.setState({
          emailBody: res && res.emailBody,
          emailSubject: res && res.subject,
          emailFooter: res && res.emailFooter,
          editorState,
          footerEditorState,
          activateTemplate: (res && res.status === 'active'),
        });
      });
  }

  handleInputChange = (ev) => {
    const resetInvalid = `invalid${ev.target.name}`;
    this.setState({
      [ev.target.name]: ev.target.value,
      [resetInvalid]: false,
    });
  }

  validateInput = (data) => {
    let isValid = false;
    const inValidInputs = {};
    data.map((input) => {
      const {
        name, value = '', minLength, maxLength,
      } = input || {};
      const inputValue = value || '';
      const trimmedValueLength = inputValue.trim().length;
      if ((trimmedValueLength < minLength) || (trimmedValueLength > maxLength)) {
        inValidInputs[name] = true;
      }
    });
    if (Object.keys(inValidInputs).length === 0) {
      isValid = true;
    } else {
      this.setState(inValidInputs);
    }
    return isValid;
  }

  handleOnSubmit = (ev) => {
    ev.preventDefault();
    const {
      match: { params: { templateId } = {} } = {},
      template: { templateName } = {},
    } = this.props;
    const {
      editorState, footerEditorState, emailBody,
      emailFooter, emailSubject, activateTemplate,
    } = this.state;
    const data = [
      {
        name: 'invalidemailSubject',
        value: emailSubject,
        minLength: 6,
        maxLength: 250,
      },
      {
        name: 'invalidemailBody',
        value: emailBody,
        minLength: 10,
        maxLength: 8000,
      },
      {
        name: 'invalidemailFooter',
        value: emailFooter,
        minLength: 6,
        maxLength: 1000,
      },
    ];
    const isValid = true; //this.validateInput(data);
    if (isValid) {
      console.log('SEND:');
    }
  }

  handleSendTestEmail = (ev) => {
    ev.preventDefault();
    // return;
    const {
      match: { params: { templateName, applicationId } = {} } = {},
      user: { email } = {},
    } = this.props;
    const {
      editorState, footerEditorState, emailBody,
      emailFooter, emailSubject,
    } = this.state;
    const data = [
      {
        name: 'invalidemailSubject',
        value: emailSubject,
        minLength: 6,
        maxLength: 250,
      },
      {
        name: 'invalidemailBody',
        value: emailBody,
        minLength: 10,
        maxLength: 8000,
      },
      {
        name: 'invalidemailFooter',
        value: emailFooter,
        minLength: 6,
        maxLength: 1000,
      },
    ];
    const isValid = true; //this.validateInput(data);
    if (isValid) {
      this.props.sendEmailTemplate({
        actionName: 'send-custom-email',
        email,
        templateId: -1,
        templateName,
        subject: emailSubject,
        payload: {
          appId: applicationId,
        },
        body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        footer: draftToHtml(convertToRaw(footerEditorState.getCurrentContent())),
      })
        .then((res) => {
          console.log('RES:', res);
          if (res && !res.error) {
            this.setState({
              showNotification: true,
              errorType: 'success',
              notificationTitle: 'Email sent successfully',
            }, () => {
              const c = this.notifRef && this.notifRef.current
              && this.notifRef.current.focus && this.notifRef.current.focus();
            });
          } else if (res && res.error) {
            const { error: { message = 'Unkown error' } } = res || {};
            this.setState({
              showNotification: true,
              errorType: 'error',
              notificationTitle: `Error occured: Please check the email template: ${message}`,
            }, () => {
              const c = this.notifRef && this.notifRef.current
              && this.notifRef.current.focus && this.notifRef.current.focus();
            });
          }
        });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onFooterEditorStateChange = (editorState) => {
    this.setState({
      footerEditorState: editorState,
    });
  };

  onPreview = () => {
    const { editorState = {}, footerEditorState = {} } = this.state;
    let emailSubject = this.state.emailSubject;
    this.props.getCompanyInfo().then(({ data }) => {
      let { emailBg = '#F3F4F5', logo = 'public/x0pa-logo-300.pn' } = data;
      logo = 'https://x0pa-email-assets.s3-ap-southeast-1.amazonaws.com/republic-polytechnic.jpg';
      if (emailBg === null) emailBg = '#F3F4F5';
      let emailLogoSections = emailLogoSection.replace(/{{emailBg}}/g, emailBg);
      emailLogoSections = emailLogoSections.replace('{{logo}}', logo);
      let EmailData = emailLogoSections + emailBodyStart
      + draftToHtml(convertToRaw(editorState.getCurrentContent()))
      + emailBodyEnd + draftToHtml(convertToRaw(footerEditorState.getCurrentContent()));
      EmailData = EmailData.replace(/{{{/g, '{{');
      EmailData = EmailData.replace(/}}}/g, '}}');
      EmailData = EmailData.replace(/{{#if firstName}}/g, '');
      EmailData = EmailData.replace(/{{#if lastName}}/g, '');
      EmailData = EmailData.replace(/{{#unless lastName}},/g, '');
      EmailData = EmailData.replace(/{{\/unless}}/g, '');
      EmailData = EmailData.replace(/{{\/if}}/g, '');
      EmailData = EmailData.replace(/{{else}}/g, '');
      Object.keys(dummyEmailPlaceholders).map((ele) => {
        const replaceStr = `{{${ele}}}`;
        EmailData = EmailData.replace(new RegExp(replaceStr, 'g'), dummyEmailPlaceholders[ele]);
        emailSubject = emailSubject.replace(new RegExp(replaceStr, 'g'), dummyEmailPlaceholders[ele]);
      });
      localStorage.setItem('emailPreviewSubject', emailSubject);
      localStorage.setItem('emailPreview', EmailData);
      const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
      window.open('/emailpreview/preview', 'Email Preview', params);
    });
  }

  render() {
    const paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus' },
      { id: 'email-templates', name: 'Email Templates', href: '/app/campus/emails' },
      { id: 'customize-email', name: 'Customize', href: '' },
    ];
    const { loading, template = {} } = this.props;
    const { match: { params: { applicationId = 0, jobId = 0 } = {} } = {} } = this.props;

    const { emailVars = {}, displayName } = template || {};
    const {
      editorState, footerEditorState, emailBody, emailFooter, emailSubject,
      invalidemailBody, invalidemailSubject, invalidemailFooter, activateTemplate,
      showNotification, notificationTitle, errorType,
    } = this.state;
    return (
      <div className="xpa-email-templates">
        <BackRow paths={paths} />
        <input
          style={{ height: 0, opacity: 0 }}
          ref={this.notifRef}
        />
        <div className="bx--type-gamma font-weight-bold text-capitalize pb-1">Send custom email</div>
        {
          showNotification && (
            <Tile>
              <div className="text-center">
                <span className="position-relative" style={{top:"0.5rem"}}>
                  <svg width="30" height="30" viewBox="0 0 16 16"><path d="M8 14.5c3.6 0 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"></path><path d="M11.3 4.7l1.4 1.4L6.8 12 3.3 8.6l1.4-1.4 2.1 2z"></path></svg>
                </span>
                <span className="empty-title bx--type-epsilon pl-2">                
                  Your email has been sent successfully.
                </span>
                {
                  applicationId && (applicationId > 0) && (!isNaN(applicationId)) && (
                    <div className="pt-1">
                      <Link to={`/app/campus/candidate/${applicationId}/job/${jobId}/candidateprofile`} className="bx--btn bx--btn--primary" key={`test123`}>
                        View Application
                      </Link>
                    </div>
                  )
                }
              </div>
            </Tile>
          )
        }
        {
          !showNotification && emailVars && (
            <Fragment>
              <p className="bx--type-zeta font-weight-normal pb-0 mb-0">
                The following placeholders are available to use in the email body and subject sections:
              </p>
              <Tile className="mb-2 mt-2">
                <div className="bx--row mb-2">
                  <div className="bx--col-lg-3"><strong>Placeholder</strong></div>
                  <div className="bx--col-lg-9 d-none d-lg-block"><strong>Description</strong></div>
                </div>
                <hr className="mt-0 mb-0" />
                <div>
                  {
                    emailVars && Object.keys(emailVars).map(r => (
                      <div className="bx--row border-bottom pt-2 pb-2" key={r}>
                        <div className="bx--col-lg-3 mb-1"><span>{`{{${r}}}`}</span></div>
                        <div className="bx--col-lg-9">{emailVars[r]}</div>

                      </div>
                    ))
                  }
                </div>
              </Tile>
            </Fragment>
          )
        }
        {!showNotification && (
        <Tile>
          <Form onSubmit={this.handleSendTestEmail}>
            <div className="mt-4">
              <TextInput
                id="email-subject"
                name="emailSubject"
                labelText="Email Subject"
                placeholder="Email Subject"
                hideLabel={false}
                invalid={invalidemailSubject}
                invalidText="Email subject should contain atleast 6 characters"
                onChange={this.handleInputChange}
                value={emailSubject}
                required
                minLength={6}
                maxLength={250}
              />
            </div>
            <div className="mt-4">
              <div className="bx--label">Email Body</div>
              <Editor
                editorState={editorState}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'colorPicker'],
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                }}
                onEditorStateChange={this.onEditorStateChange}
              >
                <textarea
                  name="email-body"
                  disabled
                  value={emailBody}
                />
              </Editor>
            </div>
            <div className="mt-4">
              <div className="bx--label">Email Footer</div>
              <Editor
                editorState={footerEditorState}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'colorPicker'],
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                }}
                onEditorStateChange={this.onFooterEditorStateChange}
              >
                <textarea
                  name="email-footer"
                  disabled
                  value={emailFooter}
                />
              </Editor>
            </div>
            <Button className="mt-4" type="submit">
            Send email
            </Button>
            <Button kind="tertiary" className="mt-4 ml-2" target="_blank" onClick={this.onPreview}>
            Preview email
            </Button>
          </Form>
        </Tile>)}
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.emailTemplates.loading,
  user: state.x0paDuser.user,
  template: state.emailTemplates && state.emailTemplates.template,
  companyInfo: state.adminSettings && state.adminSettings.companyInfo,
});

const mapDispatchToProps = {
  getEmailTemplate,
  sendEmailTemplate,
  getCompanyInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);
