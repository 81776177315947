import {
  REQUEST_SIGNUP,
  SUCCESS_SIGNUP,
  SUCCESS_ALL_ROLES,
  SUCCESS_COMPANY_DATA,
  SUCCESS_ALL_COUNTRIES,
  SUCCESS_ALL_STATES,
} from '../actions/actionTypes';

const initState = {
  response: '',
  allRoles: [],
  signedUp: false,
  companyData: [],
  domains: [],
  allCountries: [],
  allStates: [],
};

const signUp = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_SIGNUP:
      return {
        ...state,
        response: payload,
      };
    case SUCCESS_SIGNUP:
      return {
        ...state,
        signedUp: true,
        response: payload,
      };
    case SUCCESS_ALL_COUNTRIES:
      return {
        ...state,
        allCountries: payload,
      };
    case SUCCESS_ALL_STATES:
      return {
        ...state,
        allStates: payload,
      };
    case SUCCESS_ALL_ROLES:
      return {
        ...state,
        allRoles: payload,
      };
    case SUCCESS_COMPANY_DATA:
      return {
        ...state,
        companyData: payload || [],
      };
    default:
      return state;
  }
};

export default signUp;
