import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  Button, ToolbarItem, Modal, TextInput,
  Pagination, Tile,
} from 'carbon-components-react';
import {
  getBucketNames, createBucket, editBucketName, delBucket, reRenderContent,
} from 'actions/talentSearch/buckets';

const columnInfo = [
  {
    title: 'Name',
    className: 'text-align__left',
    name: 'id',
    propName: 'bucketName',
  },
  {
    title: 'Action',
    className: 'text-center',
    name: 'action',
    propName: 'actionName',
  },
];

const NoContentBox = ({ activeBucketModal }) => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no buckets available.</p>
      <Button onClick={activeBucketModal}>Create a Bucket</Button>
    </div>
  </Tile>
);

class Buckets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPageNumber: 10,
      activePage: 1,
      keyword: '',
      isOpenBucketModal: false,
      isOpenEditModal: false,
      bucketName: '',
      activeBucketId: 0,
      activeBucketName: '',
      invalidText: '',
      isBucketNameValid: false,
      isEditBucketNameValid: false,
      inValidEditText: '',
    };
  }

  componentDidMount() {
    this.props.reRenderContent({
      itemsPerPage: this.state.perPageNumber,
      activePage: 1,
      searchKey: this.state.keyword,
      selected:this.state.selected,
    });
    this.props.getBucketNames();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activeBucketModal = () => {
    this.setState({
      isOpenBucketModal: true,
    });
  }

  activeEditModal = (bucketId, bucketName) => {
    this.setState({
      activeBucketId: bucketId,
      activeBucketName: bucketName,
    }, () => {
      this.setState({ isOpenEditModal: true });
    });
  }

  dismissBucketModal = () => {
    const { isBucketNameValid } = this.state;
    if (isBucketNameValid) {
      this.setState({
        isBucketNameValid: false,
        invalidText: '',
        bucketName: '',
      });
    }
    this.setState({
      bucketName: '',
      isOpenBucketModal: false,
    });
  }

  dismissSecondarySubmitModal = () => {
    this.setState({
      isOpenBucketModal: false,
      bucketName: '',
    });
  }

  dismissEditModal = () => {
    const { isEditBucketNameValid } = this.state;
    if (isEditBucketNameValid) {
      this.setState({
        isEditBucketNameValid: false,
        inValidEditText: '',
      });
    }
    this.setState({
      isOpenEditModal: false,
    });
  }

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 });
    this.searchTimer = setTimeout(() => (
      this.props.reRenderContent({
        itemsPerPage: this.state.itemsPerPage,
        activePage: 1,
        searchKey: this.state.keyword,
      })
    ), 1000);
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage }, () => {
      this.props.reRenderContent({
        itemsPerPage,
        activePage: activePage,
        searchKey: this.state.keyword,
        selected:this.state.selected,
      });
    });
  };


  handleBucketNames = () => {
    const { bucketName } = this.state;
    const specialCharacters = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
    if (!bucketName || (bucketName.trim().length === 0) || specialCharacters.test(bucketName)) {
      this.setState({
        isBucketNameValid: true,
        invalidText: 'Please enter valid bucket name',
      });
    } else {
      this.props.createBucket(bucketName).then((res) => {
        if (res && res.error && res.error.message === 'unique_violation__bucket_name_key') {
          this.setState({
            isBucketNameValid: true,
            invalidText: 'The bucket name already exists, Please enter unique name.',
          });
        } else {
          this.dismissBucketModal();
          this.props.getBucketNames();
        }
      });
    }
  }

  handleEditBucketModal = () => {
    const { activeBucketId, activeBucketName } = this.state;
    const specialCharacters = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
    if (!activeBucketName || activeBucketName.trim().length === 0 || specialCharacters.test(activeBucketName)) {
      this.setState({
        isEditBucketNameValid: true,
        inValidEditText: 'Please enter valid name',
      });
    } else {
        this.props.editBucketName(activeBucketId, activeBucketName).then(() => {
        this.dismissEditModal();
        this.props.getBucketNames();
      });
    }
  }

  handleDeleteBuckets = (bucketId) => {
    this.props.delBucket(bucketId).then(() => {
      this.props.getBucketNames();
    });
  }

  render() {
    const { buckets = [], count } = this.props;
    const otherActionsButton = [];
    otherActionsButton.push(
      <ToolbarItem>
        <Button
          onClick={() => this.activeBucketModal()}
        >
          Create a New Bucket
        </Button>
      </ToolbarItem>,
    );
    return (
      <Fragment>
        <div>
          {
            <div className=" mt-2 mb-2 text-justify">
              <p className="bx--type-epsilon font-weight-normal">
                Organize your talent pool candidates into various Buckets (groups) such as Internal Employees, Sales, Engineering, etc, and
                ask Roboroy to search relevant candidates in a specific talent pool bucket for a particular job posting.
              </p>
            </div>
          }
          {<GenericDataTable
            otherActions={otherActionsButton}
            rowClass= "p-0"
            rows={
              Array.isArray(buckets) && buckets.map(bucket => ({
                isExpanded: false,
                id: `row_${bucket.bucketId}`,
                "header_0": bucket.bucketName,
                "header_1": (<div>
                  <Button  className="m-2" kind="tertiary" small onClick={() => this.activeEditModal(bucket.bucketId, bucket.bucketName)}>Edit</Button>
                  <Button kind="danger" small onClick={() => this.handleDeleteBuckets(bucket.bucketId)}>Delete</Button>
                </div>)
              }))
            }
            headers={
              columnInfo.map((data, index) => (
                {
                  key: `header_${index}`,
                  header: data.title,
                  className: data.className,
                  colpropName: data.propName,
                }
              ))
            }
            selectable={false}
            searchable
            onSearchInputChange={this.handleSearchSubmit}
          />}
          {
            buckets && buckets.length > 0
            && (
            <div>
              <Pagination
                onChange={this.handlePaginationChange}
                pageSizes={[10, 15, 20, 25, 50]}
                page={this.state.activePage}
                totalItems={count}
              />
            </div>
            )
          }
          {buckets && buckets.length <= 0
          && (
          <NoContentBox
            activeBucketModal={() => this.activeBucketModal()}
          />)}
          <JDProcessOverlay show={this.props.loading} message="processing..." />
          <Modal
            onRequestSubmit={() => this.handleBucketNames()}
            onRequestClose={this.dismissBucketModal}
            open={this.state.isOpenBucketModal}
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            modalHeading="Create a New Bucket"
          >
            <TextInput
              name="bucketName"
              id="bucketName"
              placeholder="Enter a bucket name"
              labelText="Bucket Name"
              onChange={(e) => { this.handleChange(e); }}
              invalid={this.state.isBucketNameValid}
              invalidText={this.state.invalidText}
              value={this.state.bucketName}
            />
          </Modal>
          <Modal
            onRequestSubmit={() => this.handleEditBucketModal()}
            onRequestClose={this.dismissEditModal}
            open={this.state.isOpenEditModal}
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            modalHeading="Edit Buckets"
          >
            <TextInput
              name="activeBucketName"
              id="activeBucketName"
              placeholder="abc"
              labelText="Edit a Bucket Name"
              onChange={(e) => { this.handleChange(e); }}
              invalid={this.state.isEditBucketNameValid}
              invalidText={this.state.inValidEditText}
              value={this.state.activeBucketName}
            />
          </Modal>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.roboroyTalentPool.loading,
  error: state.roboroyTalentPool.error,
  buckets: state.roboroyTalentPool.buckets,
  count: state.roboroyTalentPool.count,
});

const mapDispatchToProps = {
  getBucketNames,
  createBucket,
  editBucketName,
  delBucket,
  reRenderContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Buckets);
