import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { xGetCalendarEventById, xUpsertCalendarEventById } from 'actions/xpa';
import {
  Tile,
  InlineNotification,
  Loading,
  Button,
} from 'carbon-components-react';
import './AvailableSlots.css';
import PublicHeader from 'containers/common/publicHeader';

class AvailableSlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      notifMsg: '',
      events: {},
      showEventDate: true,
      finalStartTimeSlot: 0,
      finalEndTimeSlot: 0,
      startTime: 0,
      isFlowCompleted: false,
      showConfirmSlot: false,
      loadingEvent: false,
    };
  }

  componentDidMount() {
    this.getEventSlots();
  }

  getEventSlots = () => {
    const { match: { params: { eventId } = {} } = {} } = this.props;
    this.setState({
      loadingEvent: true,
    });
    this.props
      .xGetCalendarEventById(eventId)
      .then((res) => {
        if (!res || res.error) {
          const { message } = (res && res.error) || {};
          this.setState({
            showError: true,
            notifMsg: message || 'Error occured while getting the slots',
            loadingEvent: false,
          });
        } else {
          const { customSlots } = res || {};
          let noSelectableSlots = false;
          const selectableSlots = [];
          const mergedSlots = {};
          if (customSlots && Array.isArray(customSlots) && customSlots.length > 0) {
            customSlots.forEach((s) => {
              const { end } = s || {};
              if (moment.utc().isBefore(moment.utc(end))) {
                selectableSlots.push(s);
              }
            });
            if (selectableSlots.length < 1) {
              noSelectableSlots = true;
            }
          } else {
            noSelectableSlots = true;
          }
          if (noSelectableSlots) {
            this.setState({
              showError: true,
              notifMsg: 'Slot dates not valid now',
              events: [],
              loadingEvent: false,
            });
          } else {
            const sortedSelectableSlots = selectableSlots.sort((a, b) => moment(a.start).diff(b.start));
            sortedSelectableSlots.forEach((e) => {
              const { start, end } = e || {};
              const curDate = moment.utc(start).format('YYYYMMDD');
              const tslots = [{
                start: moment.utc(start).format('x'),
                end: moment.utc(start).add(30, 'm').format('x'),
              }];
              //this.setTimeSlots(start, end, curDate);
              if (mergedSlots[curDate]) {
                const oldSlots = mergedSlots[curDate];
                mergedSlots[curDate] = [
                  ...oldSlots,
                  ...tslots,
                ];
              } else {
                mergedSlots[curDate] = tslots;
              }
            });

            this.setState({
              showError: false,
              notifMsg: '',
              events: res,
              mergedSlots,
              loadingEvent: false,
            });
          }
        }
      })
      .catch(e => {
        console.error('error', e);
      });
  };

  setTimeSlots = (start, end) => {
    const slotsMs = [];
    const startMs = moment.utc(start).format('x');
    const endMs = moment.utc(end).format('x');
    const diffMins = (endMs - startMs) / (1000 * 60);
    let validDur = 30;
    // if ((events.duration % 15) === 0) {
    //   validDur = events.duration; 
    // }
    for (let j = 1; j <= (diffMins / validDur); j++) {
      const nextStart = moment.utc(startMs, 'x').add(j * validDur, 'm').format('x');
      const nextEnd = moment.utc(nextStart, 'x').add(validDur, 'm').format('x');
      if (j == 1) {
        if (moment.utc().isBefore(moment.utc(startMs, 'x'))) {
          slotsMs.push({
            start: moment.utc(startMs, 'x').format('x'),
            end: nextStart,
          });
        }
      }
      if (moment.utc().isBefore(moment.utc(nextStart, 'x'))) {
        slotsMs.push({
          start: nextStart,
          end: nextEnd,
        });
      }
    }
    return slotsMs;
  }

  handleTimeSlotEvents = (selDate) => {
    this.setState({
      selectedDate: selDate,
      showEventDate: false,
    });
  };

  handleChangeDateEvent = () => {
    this.setState({
      showEventDate: true,
    });
  };

  handleConfirmSlot = () => {
    const { finalStartTimeSlot, finalEndTimeSlot, events } = this.state;
    const data = {
      start: finalStartTimeSlot,
      end: finalEndTimeSlot,
      eventId: events && events.eventId,
      appId: events && events.appId,
      appStageId: events && events.appStageId,
    };
    this.setState(
      {
        schedulingSlot: true,
      },
      () => {
        this.props
          .xUpsertCalendarEventById(data)
          .then(res => {
            if (!res || res.error) {
              this.setState({
                showError: true,
                notifMsg: 'Error occured while processing',
                schedulingSlot: false,
                showConfirmSlot: false,
              });
            } else {
              this.setState({
                isFlowCompleted: true,
                startTime: res.start,
                endTime: res.end,
                schedulingSlot: false,
                showConfirmSlot: false,
              });
            }
          })
          .catch(e => {
            this.setState({
              showError: true,
              notifMsg: 'Error occured while processing',
              schedulingSlot: false,
              showConfirmSlot: false,
            });
            console.error('error', e);
          });
      }
    );
  };

  handleSelectTimeMsEvent = (start, end) => {
    const finalStart = moment.utc(start, 'x').format();
    const finalEnd = moment.utc(end, 'x').format();
    this.setState({
      finalStartTimeSlot: finalStart,
      finalEndTimeSlot: finalEnd,
      showConfirmSlot: true,
    });
  };

  render() {
    const {
      showError,
      notifMsg,
      events,
      showEventDate,
      selectedDate,
      isFlowCompleted,
      mergedSlots,
      startTime,
      schedulingSlot,
      showConfirmSlot,
      finalStartTimeSlot,
      finalEndTimeSlot,
      loadingEvent,
    } = this.state;
    const { isUserLoggedIn, user: { roleId } = {} } = this.props;
    let allDates = [];
    if (mergedSlots) {
      allDates = Object.keys(mergedSlots);
      allDates = allDates.sort();
    }
    return (
      <Fragment>
        <PublicHeader handleRedirectToLogin={() => {}} />
        {(schedulingSlot || loadingEvent) && <Loading />}
        {isUserLoggedIn && roleId !== 3 && (
          <Tile className="xpa-center-viewport xpa-login-size p-5">
            <div className="bx--row flex-column align-items-center">
              <div className="empty-icon">
                <i className="h1 icon-flag" style={{ fontSize: '60px' }} />
              </div>
              <p className="bx--type-beta pt-4 pb-4 mb-0">
                You are already signed in as an another user
              </p>
              <p>
                Please <a href="/auth/logout">logout</a> and re-visit this link
                to continue.
              </p>
            </div>
          </Tile>
        )}
        {((isUserLoggedIn === true && roleId === 3) || !isUserLoggedIn) &&
          !isFlowCompleted && (
            <Tile className="xpa-size-slots p-5 mb-2">
              {showError && (
                <InlineNotification
                  lowContrast
                  className="mt-1 mb-1"
                  title={notifMsg}
                  subtitle=""
                  iconDescription="describes the close button"
                  kind="error"
                  hideCloseButton
                />
              )}
              {!showError && events && !isFlowCompleted && (
                <Fragment>
                  <div className="text-center">
                    <div className="bx--type-delta mt-4 text-uppercase">
                      {events.summary}
                    </div>
                    <hr />
                    {showEventDate === true && (
                      <Fragment>
                        <div className="h5">Select a slot</div>
                        <div>
                          {allDates &&
                            Array.isArray(allDates) &&
                            allDates.length > 0 &&
                            allDates.map((k) => {
                              return (
                                <Fragment>
                                  <div className="xpa-border-slots ml-auto mr-auto mb-2">
                                    <div
                                      onClick={() =>
                                        this.handleTimeSlotEvents(k)
                                      }
                                      className="bx--btn font-weight-normal xpa-btn-size">
                                      {moment.utc(k, 'YYYYMMDD').format('dddd Do MMMM')}
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            })}
                        </div>
                      </Fragment>
                    )}
                    {showEventDate === false && !showConfirmSlot && (
                      <div>
                        <div className="d-flex justify-content-center">
                          <div className="mr-3 h6">{moment.utc(selectedDate, 'YYYYMMDD').format('Do MMMM YYYY')}</div>
                          <div
                            role="button"
                            className="xpa-link font-weight-bold xpa-text-underline"
                            onClick={this.handleChangeDateEvent}>
                            Change date
                          </div>
                        </div>
                        <div className="h5">Select a time slot</div>
                        <div>
                          {mergedSlots && mergedSlots[selectedDate] && Array.isArray(mergedSlots[selectedDate])
                            && mergedSlots[selectedDate].map((slotObj) => {
                              const { start, end } = slotObj || {};
                              return (
                                <div>
                                  <div className="xpa-border-slots cursor-pointer ml-auto mr-auto mb-2 p-1">
                                    <div
                                      onClick={() =>
                                        this.handleSelectTimeMsEvent(start, end)
                                      }
                                      className="bx--btn font-weight-normal xpa-btn-size">
                                      {moment.utc(start, 'x').format('HH:mm')} to{' '}
                                      {moment.utc(end, 'x').format('HH:mm')}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    {showConfirmSlot && (
                      <div className="d-flex flex-column justify-content-center">
                        <div>
                          <p className="mb-0">
                            You have selected the following interview slot:
                          </p>
                          <p className="font-weight-bold">
                            {moment.utc(finalStartTimeSlot)
                              .format('dddd Do MMMM HH:mm')}{' '}
                            to {moment.utc(finalEndTimeSlot).format('HH:mm')}
                          </p>
                          <p>Do you want to confirm this slot?</p>
                        </div>
                        <div>
                          <Button
                            style={{ backgroundColor: '#038a09' }}
                            onClick={this.handleConfirmSlot}>
                            Yes
                          </Button>
                          <Button
                            kind="danger"
                            onClick={() => {
                              this.setState({ showConfirmSlot: false });
                            }}
                            className="ml-2">
                            No
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
              <InlineNotification
                lowContrast
                className="mt-5 mb-1"
                title="Please contact the hiring manager listed on the Job Details page if you are unable to make it for any of the above interview slots."
                subtitle=""
                iconDescription="describes the close button"
                kind="info"
                hideCloseButton
              />
            </Tile>
          )}
        {((isUserLoggedIn === true && roleId === 3) || !isUserLoggedIn) &&
          isFlowCompleted && (
            <Tile className="xpa-center-viewport xpa-login-size p-5">
              <div className="text-center">
                <p>Your interview is scheduled:</p>
                <p className="font-weight-bold">
                  {moment.utc(startTime).format('dddd Do MMMM HH:mm')}
                </p>
                <p style={{ maxWidth: '600px' }}>
                  You will receive a confirmation email for the same to your
                  registered email address. Please refer to the email for
                  additional information, if any with regards to your interview.
                </p>
                <Button href={`${window.location.origin}/app/campus/candidate`} size="small">
                  Back to portal
                </Button>
              </div>
            </Tile>
          )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  loading: state.CalendarEvents.loading,
  error: state.CalendarEvents.error,
  calendarEvents: state.CalendarEvents.calendarEvents,
  eventInfo: state.CalendarEvents.eventInfo,
});

const mapDispatchToProps = {
  xGetCalendarEventById,
  xUpsertCalendarEventById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailableSlots);
