import * as api from './api';
import * as ac from './actionCreators';
import { push } from 'react-router-redux';
import moment from 'moment';

// skills
export const fetchAllSkills = () => (dispatch) => {
  dispatch(ac.fetchAllSkillsRequest());
  api
    .fetchAllSkills()
    .then((res) => {
      dispatch(ac.fetchAllSkillsSuccess(res));
    })
    .catch((error) => {
      dispatch(ac.fetchAllSkillsError(error));
    });
};

export const createSkill = skillName => (dispatch) => {
  dispatch(ac.createSkillRequest());
  api
    .createSkill({ skillName })
    .then((res) => {
      // test
      setTimeout(() => {
        dispatch(ac.createSkillSuccess(res));
      }, 1250);
    })
    .catch((error) => {
      dispatch(ac.createSkillError(error));
    });
};

export const addSkill = (name, skill) => (dispatch) => {
  dispatch(ac.addNewSkill(name, skill));
};

export const removeSkill = (name, id) => (dispatch) => {
  dispatch(ac.removeNewSkill(name, id));
};

// job parsing.
export const parseJd = file => (dispatch) => {
  dispatch(ac.jdParseStart());
  return api
    .parseJd(file)
    .then((res) => {
      dispatch(ac.jdParseSuccess(res));
    })
    .catch((error) => {
      dispatch(ac.jdParseError(error));
    });
};

// dropdown data
export const fetchDropdownData = () => (dispatch) => {
  dispatch(ac.fetchDropDownDataRequest());
  return api
    .fetchDropdownData()
    .then(res => dispatch(ac.fetchDropDownDataSuccess(res.meta)))
    .catch(error => dispatch(ac.fetchDropDownDataError(error)));
};

// need to be one action type change__dropdown
// export const changeEducation = data => dispatch => {
//   dispatch(ac.changeEducation(data));
// };

// export const changeCurrency = data => dispatch => {
//   dispatch(ac.changeCurrency(data));
// };

// export const changeJobFunction = data => dispatch => {
//   dispatch(ac.changeJobFunction(data));
// };

export const changeNewJobdropdown = (name, data) => (dispatch) => {
  dispatch(ac.changeNewJobDropdown(name, data));
};

export const changeNewJobInput = data => (dispatch) => {
  dispatch(ac.changeNewJobInput(data));
};

export const changeEndDate = data => (dispatch) => {
  dispatch(ac.changeEndDate(data));
};

export const saveJob = data => (dispatch, getState) => {
  const { jobCreation } = getState();
  return api
    .saveJob(data)
    .then(() => {
      dispatch(push('/app/campus/jobs'));
      alert('Success');
    })
    .catch(() => alert('Failed'));
};

/*

{
      companyId: 3,
      officeId: 1,
      divisionId: 0,
      nameId: jobCreation.title,
      functionId: (jobCreation.function && jobCreation.function.value) || 1,
      seniorityId: (jobCreation.seniority && jobCreation.seniority.value) || 1,
      qualTypeId: (jobCreation.education && jobCreation.education.value) || 10,
      requiredExp: jobCreation.required_exp || 0,
      role1: 'role1',
      role2: 'role2',
      role3: 'role3',
      jobDesc: jobCreation.job_desc,
      jobReq: jobCreation.job_req || '',
      benefit: jobCreation.benefit || '',
      minSalary: jobCreation.salary_from,
      maxSalary: jobCreation.salary_to,
      currencyId: (jobCreation.currency && jobCreation.currency.value) || 121,
      openDate: moment().format(),
      closeDate: '2018-02-24T16:51:28.231Z',
      vacancy: jobCreation.number_vacancies || 1,
      termId: (jobCreation.terms && jobCreation.terms.value) || 1,
      hourTypeId: (jobCreation.hour && jobCreation.hour.value) || 1,
      teamId: 0,
      status: true,
      fromResume: false,
      jobList: jobCreation.job_list,
      jobIndex: jobCreation.job_index,
      jdLocation: 'location',
      draft,
      apiMeta:{
        Hireteam:{
           date:moment().format()
        }
      }
    }

    */
