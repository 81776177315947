import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Modal, Button, Search,
} from 'carbon-components-react';
import { reRenderContent, filterJobData } from 'actions/talentSearch/jobList';
import queryString from 'query-string';
import FilterJobs from 'components/roboroy/FilterJobs';
import ApplicantSort from 'components/common/ApplicantSort';
import FilterApplications from 'components/roboroy/FilterApplications';
import {
  reRenderContent as applicationsRerenderContent,
  getStudentAppData
} from 'actions/talentSearch/candidatesList';
import FacetsBrowse from '../../components/roboroy/common/FacetsBrowse';
import {
 getEmployees
} from 'actions/BrowseEmployees';

class FilterData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenFilterJobModal: false,
      isOpenFilterApplModal: false,
      isOpenAccr: 'ap',
      isOpenAccrAppls: 'as',
      filterLength: '',
    };
  }

  componentDidMount() {
    const {
      search,
    } = this.props;
    if (search) {
      this.handleSearchQuery(search);
    } else {
      // const { defaultCheckList, filterJobs } = this.props;
      // if (filterJobs === true && defaultCheckList.hasOwnProperty("batch")) {
      //   this.handleChangeCheckboxValues(true, defaultCheckList["batch"][0], "b", "batch");
      // }
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.statusSearch !== this.props.statusSearch) && this.props.statusSearch) {
      this.handleSearchQuery(this.props.statusSearch);
    }
  }

  handleSearchQuery = (searchString) => {
    const search = decodeURIComponent(searchString);
    const searchSplit = (search && search.split('?')) || null;
    if (!searchSplit || !Array.isArray(searchSplit)) {
      return;
    }
    const resSplt = ((searchSplit.length > 0) && searchSplit[1] && searchSplit[1].split('&')) || null;
    let finalLength = 0;
    if (resSplt && Array.isArray(resSplt) && resSplt.length > 0) {
      resSplt.forEach((res) => {
        if (res) {
          const valSpl = res.split('=');
          let valueArr = [];
          if (valSpl[0] && valSpl[0].toString() && (valSpl[0] !== 'companyId' && valSpl[0] !== 'recruiterId')) {
            if (valSpl[1].includes(',')) {
              valueArr = valSpl[1].split(',');
            } else {
              valueArr = [valSpl[1]];
            }
            this.setState({
              [valSpl[0]]: valSpl[0],
              [`filteredArr-${[valSpl[0]]}`]: valueArr,
            }, () => {
              if (this.state[`filteredArr-${[valSpl[0]]}`].length > 0) {
                this.setState({
                  [`selected_${[valSpl[0]]}`]: true,
                  [`length_${[valSpl[0]]}`]: this.state[`filteredArr-${[valSpl[0]]}`].length,
                }, () => {
                  let currLength = this.state[`length_${[valSpl[0]]}`];
                  currLength = Number(currLength);
                  if (!isNaN(currLength)) {
                    finalLength += currLength;
                  }
                  this.setState({
                    filterLength: finalLength,
                  });
                });
              }
            });
          }
        }
      });
    }
  }

  activeFilterJobModal = () => {
    this.setState({
      isOpenFilterJobModal: true,
    });
  }

  dismissFilterJobModal = () => {
    this.setState({
      isOpenFilterJobModal: false,
    });
  }

  activeFilterApplModal = () => {
    this.setState({
      isOpenFilterApplModal: true,
    });
  }

  dismissFilterApplModal = () => {
    this.setState({
      isOpenFilterApplModal: false,
    });
  }

  handleOpenAcc = (val) => {
    const {
      filterApls,
      filterJobs,
    } = this.props;
    if (filterJobs) {
      this.setState({
        isOpenAccr: val,
      });
    }
    if (filterApls) {
      this.setState({
        isOpenAccrAppls: val,
      });
    }
  }

  handleChangeRadioBtn = (id, status, key) => {
    const { titles } = this.props;
    switch (id) {
      case '1':
        this.setState({
          [key]: key,
          [`filteredArr-${key}`]: [id],
          currCheck: `${status}_${id}`,
          [`length_${key}`]: 1,
        }, () => this.handleSubmitFilterValues(titles));
        break;
      case '2':
        this.setState({
          [key]: key,
          [`filteredArr-${key}`]: [id],
          currCheck: `${status}_${id}`,
          [`length_${key}`]: 1,
        }, () => this.handleSubmitFilterValues(titles));
        break;
      default:
        break;
    }
  }

  handleChangeCheckboxValues = (e, id, status, key) => {
    const { titles } = this.props;
    let filArrayVals = this.state[`filteredArr-${key}`];
    const finalKey = `${key}`;
    const currCheck = `${status}_${id}`;
    if (!(Array.isArray(filArrayVals))) {
      filArrayVals = [id];
    } else if (Array.isArray(filArrayVals) && filArrayVals.length === 0) {
      filArrayVals = [id];
    } else if (Array.isArray(filArrayVals) && filArrayVals.length > 0) {
      filArrayVals.push(id);
    }
    this.setState({
      [`${currCheck}`]: e,
      [`${finalKey}`]: key,
      [`filteredArr-${key}`]: [...new Set(filArrayVals)],
    }, () => {
      const arrSelection = `selected_${key}`;
      if (this.state[`filteredArr-${key}`].length > 0) {
        this.setState({
          [`${arrSelection}`]: true,
          [`length_${key}`]: (this.state[`filteredArr-${key}`].length),
        }, () => {
          this.handleSubmitFilterValues(titles)
        });
      }
      if (this.state[`${currCheck}`] === false) {
        const filteredList = this.state[`filteredArr-${key}`].filter((res) => {
          return res !== id;
        });
        this.setState({
          [`filteredArr-${key}`]: filteredList,
        }, () => {
          if (this.state[`filteredArr-${key}`].length <= 0) {
            this.setState({
              [`${arrSelection}`]: false,
            }, () => {
              this.handleSubmitFilterValues(titles)
            });
          }
          this.handleSubmitFilterValues(titles)
        });
      }
      this.handleSubmitFilterValues(titles)
    });
  }

  handleSelectClearAll = (res, isAllSelect) => {
    const { titles } = this.props;
    const requiredTitle = titles.filter((title) => title.titleName === res)[0];
    const ids = [];
    const { values, filterKey } = requiredTitle || {};
    let reqArrName, reqIdName;
    if (res === "Courses") {
      reqArrName = "finalCoursesArr";
      reqIdName = "courseId";
    }
    if (res === "Batch") {
      reqArrName = "finalBatchArr";
      reqIdName = "batchName";
    }
    if(res==="Status"){
      reqArrName = "applicationStatus";
      reqIdName = "statusId";
    }
    if(res==="Companies"){
      reqArrName = "finalCompsArr";
    reqIdName = "companyId";
    }
    if(res==="Interview Type"){
      reqArrName = "workflowstages";
      reqIdName = "stageTypeName";
    }
    if(res==="Workflow Stages"){
      reqArrName = "workflowSubStageStatus";
      reqIdName = "subStageId";
    }
    if(res==="Offer Status"){
      reqArrName = "offerStatus";
      reqIdName = "offerId";
    }
    if(res==="Hiring Status"){
      reqArrName = "hiringStatus";
      reqIdName = "hiringId";
    }
    if(res==="Gender"){
      reqArrName = "gender";
      reqIdName = "genderId";
    }
    if(res==="Skills"){
      reqArrName = "skills";
      reqIdName = "skillId";
    }



    if (isAllSelect === true) {
      const finalValuesArr = values[`${reqArrName}`]
      finalValuesArr && Array.isArray(finalValuesArr) && finalValuesArr.length > 0
        && finalValuesArr.map((value) => {
          ids.push(`${value[`${reqIdName}`]}`);
        })
      this.setState({ [`filteredArr-${filterKey}`]: ids }, () => {
        this.handleSubmitFilterValues(titles)
      });
    } else {
      this.setState({ [`filteredArr-${filterKey}`]: [] }, () => {
        this.handleSubmitFilterValues(titles)
      });
    }
  }

  handleSelectAllValues = (values, status, keys) => {
    const {
      filterJobs,
      filterApls,
    } = this.props;
    const objKeys = Object.keys(values);
    let finalSelectedArr = [];
    if (filterJobs) {
      finalSelectedArr = values[objKeys] && values[objKeys].map((res) => {
        return res[keys].toString();
      });
    } else if (filterApls) {
      finalSelectedArr = values[objKeys] && values[objKeys].map((res) => {
        return res[status];
      });
    }
    this.setState({
      [`filteredArr-${keys}`]: finalSelectedArr,
      [`${keys}`]: keys,
    }, () => {
      if (this.state[`filteredArr-${keys}`].length > 0) {
        this.setState({
          [`selected_${keys}`]: true,
          [`length_${keys}`]: (this.state[`filteredArr-${keys}`].length),
        });
      } else {
        this.setState({
          [`selected_${keys}`]: false,
        });
      }
    });
  }

  handleClearAllValues = (status, keys) => {
    if (this.state[`filteredArr-${keys}`] && Array.isArray(this.state[`filteredArr-${keys}`]) && this.state[`filteredArr-${keys}`].length > 0) {
      this.setState({
        [`filteredArr-${keys}`]: [],
        [`${keys}`]: '',
      }, () => {
        if (this.state[`filteredArr-${keys}`].length <= 0) {
          this.setState({
            [`selected_${keys}`]: false,
            [`length_${keys}`]: 0,
          });
        }
      });
    }
  }

  handleSubmitFilterValues = (titles) => {
    const {
      history: {
        location: {
          search,
        } = {},
      } = {},
      filterJobs,
      filterApls,
      filterEmployee
    } = this.props;
    let finalData = {};
    if (titles) {
      let finalLength = 0;
      let finalString = '';
      titles.forEach((res) => {
        let currLength = this.state[`length_${res.filterKey}`];
        currLength = Number(currLength);
        if (!isNaN(currLength)) {
          finalLength += currLength;
        }
        const value = this.state[`filteredArr-${res.filterKey}`];
        if (value && value !== undefined && value !== null && Array.isArray(value) && value.length > 0) {
          finalString += `${res.filterKey}=${value}&`;
        }
        const filterName = `filterName_${res.filterKey}`;
        const finalKeys = `${res.filterKey}`;
        finalData[`${filterName}`] = this.state[`filteredArr-${res.filterKey}`];
        finalData[`${finalKeys}`] = this.state[`${res.filterKey}`];
      });
      this.setState({
        filterLength: finalLength,
      });
      let parsedQuery = '';
      if (search) {
        parsedQuery = queryString.parse(search);
      }
      if (parsedQuery.companyId) {
        finalString += `&companyId=${parsedQuery.companyId}`;
      }
      if (parsedQuery.recruiterId) {
        finalString += `&recruiterId=${parsedQuery.recruiterId}`;
      }
      queryString.stringify(finalString);
      if (filterJobs) {
        this.handleSubmitJobFilterData(finalString, finalData);
      }

      if (filterApls) {
        this.handleSubmitApplsFilterData(finalString, finalData);
      }
      if(filterEmployee) {
        this.handleSubmitEmployeeFilterData(finalString, finalData)  
      }
    }
  }

  handleSubmitJobFilterData = (finalString, finalData) => {
    const {
      itemsPerPage, activePage, filterOptions,
      searchKey, status,
      history: {
        location: {
          pathname,
        } = {},
      } = {},
      params,
      handlePaginationChange,
    } = this.props;
    const { limit, page } = params;
    if (limit && limit > 0 && page && page > 0) {
      const paramData = {
        itemsPerPage: 10,
        activePage: 1,
        filterOptions,
        searchKey,
        status,
      };
      const filterData = { ...finalData, ...paramData };
      this.props.history.push({
        pathname: `/app/campus/jobs/${status}/page/1/limit/10`,
        search: `?${finalString}`,
      });
      const data = {
        page: 1,
        limit: 10,
      };
      this.props.reRenderContent(filterData).then(() => {
        // this.dismissFilterJobModal();
        handlePaginationChange(data);
        // setRows();
      });
    } else {
      const data = {
        itemsPerPage,
        activePage,
        filterOptions,
        searchKey,
        status,
      };
      const filterData = { ...finalData, ...data };
      this.props.history.push({
        pathname: pathname,
        search: `?${finalString}`,
      });
      this.props.reRenderContent(filterData).then(() => {
        // this.dismissFilterJobModal();
        // setRows();
      });
    }
  }

  handleSubmitApplsFilterData = (finalString, finalData) => {
    const {
      activePage,
      jobId,
      itemsPerPage,
      status,
      searchKey,
      params,
    } = this.props;
    const { limit, page } = params;
    const data = {
      activePage,
      jobId,
      itemsPerPage,
      searchKey,
    };
    const filterData = { ...data, ...finalData };
    if (limit && limit > 0 && page && page > 0) {
      this.props.history.push({
        pathname: `/app/campus/${jobId ? `jobs/${jobId}/` : 'candidates/xopa/'}${status}/page/${page}/limit/${limit}`,
        search: `?${finalString}`,
      });
      this.props.applicationsRerenderContent(filterData).then(() => {
        this.dismissFilterApplModal();
      });
    } else {
      this.props.history.push({
        pathname: `/app/campus/${jobId ? `jobs/${jobId}/` : 'candidates/xopa/'}${status}`,
        search: `?${finalString}`,
      });
      this.props.applicationsRerenderContent(filterData).then(() => {
        this.dismissFilterApplModal();
      });
    }
  }

  handleSubmitEmployeeFilterData = (finalString, finalData) => {
    const {
      itemsPerPage, activePage, filterOptions,
      searchKey, status,
      history: {
        location: {
          pathname,
        } = {},
      } = {},
      params,
      handlePaginationChange,
    } = this.props;
    const { limit, page } = params;
    if (limit && limit > 0 && page && page > 0) {
      const paramData = {
        itemsPerPage: 10,
        activePage: 1,
        filterOptions,
        searchKey,
      };
      const filterData = { ...finalData, ...paramData };
      this.props.history.push({
        pathname: `/app/campus/browse/employee/page/1/limit/10`,
        search: `?${finalString}`,
      });
      const data = {
        page: 1,
        limit: 10,
      };
      this.props.getEmployees(filterData).then(() => {
        handlePaginationChange(data);
      });
    } else {
      const data = {
        itemsPerPage,
        activePage,
        filterOptions,
        searchKey,
      };
      const filterData = { ...finalData, ...data };
      this.props.history.push({
        pathname: pathname,
        search: `?${finalString}`,
      });
      this.props.getEmployees(filterData).then(() => {
      });
    }
  }

  handlClearFilter = (titles = this.props.titles) => {
    if (titles) {
      titles.forEach((res) => {
        this.setState({
          [`filteredArr-${res.filterKey}`]: [],
          [`${res.filterKey}`]: '',
          [`selected_${res.filterKey}`]: false,
          [`length_${res.filterKey}`]: 0,
        }, () => {
          this.handleSubmitFilterValues(titles)
        });
      });
    }
  }

  handleExportStudentApps = () => {
    this.props.getStudentAppData()
  };

  render() {
    const {
      filterJobs, titles,
      filterApls, filterEmployee,
      id, t,
      handleRefreshScores,
      handleSearchCompanyName,
      onSortValueChange,
      showLoader,
      user: { isAdmin, isSuperAdmin, company: userCompany } = {},
      facets, keys,
      defaultCheckList
    } = this.props;
    const { ctypeId } = userCompany || {};
    const {
      jobSeniority, jobTerms, hourTypes, isOpenAccr,
      isOpenFilterJobModal, filterLength,
      isOpenFilterApplModal, isOpenAccrAppls,
    } = this.state;
    return (
      <Fragment>
        {
          filterJobs && filterJobs === true && (
            <div>
              {/* <Button
                kind="tertiary"
                className="ml-2"
                onClick={this.activeFilterJobModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-filter"
                >
                  <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                </svg>
                <span className="ml-2">{t('filter')}</span>
                {
                  filterLength && filterLength !== 0 ? (
                    <strong className="badge p-1 ml-2" style={{ backgroundColor: 'rgb(62, 165, 89)' }}>{filterLength}</strong>
                  ) : null
                }
              </Button>
              <Modal
                className="modal-container"
                open={isOpenFilterJobModal}
                modalHeading={`${t('filter')} ${t('jobs')}`}
                primaryButtonText={t('submit')}
                secondaryButtonText={t('cancel')}
                onRequestClose={this.dismissFilterJobModal}
                onRequestSubmit={() => this.handleSubmitFilterValues(titles)}
              >
                <div>
                  <div className="bx--row">
                    <div className="text-left ml-4 mt-2 text-capitalize bx--type-omega">{t('clearFiltersMsg')}</div>
                    <div className="ml-5 mb-2">
                      <Button small kind="tertiary" onClick={() => this.handlClearFilter(titles)}>
                        {t('clear')}
                      </Button>
                    </div>
                  </div>
                  <FilterJobs
                    jobSeniority={jobSeniority}
                    jobTerms={jobTerms}
                    hourTypes={hourTypes}
                    handleOpenAcc={this.handleOpenAcc}
                    isOpenAccr={isOpenAccr}
                    handleClearAllValues={this.handleClearAllValues}
                    titles={titles}
                    showLoader={showLoader}
                    handleSearchCompanyName={handleSearchCompanyName}
                    state={this.state}
                    handleChangeRadioBtn={this.handleChangeRadioBtn}
                    handleSelectAllValues={this.handleSelectAllValues}
                    handleChangeCheckboxValues={this.handleChangeCheckboxValues}
                  />
                </div>
              </Modal> */}
              <FacetsBrowse
                handleClearFilters={this.handlClearFilter}
                handleChangeCheckBoxVals={this.handleChangeCheckboxValues}
                handleChangeRadioBtn={this.handleChangeRadioBtn}
                handleSelectClearAll={this.handleSelectClearAll}
                // handleSubmitFilterValues={this.handleSubmitFilterValues}
                values={this.state}
                facets={facets}
                keys={keys}
                isJobsFilter
                defaultCheckList={defaultCheckList}
              // showAlteredFacetsLabels
              // activeMoreModal={this.activeMoreModal}
              // handleSubmitCheckBox={this.handleSubmitCheckBox}
              // dismissMoreModal={this.dismissMoreModal}
              // isOpenMoreModal={isOpenMoreModal}
              // mainVals={mainVals}
              />
            </div>
          )
        }
        {
          filterEmployee && filterEmployee === true && (
            <div>
              <FacetsBrowse
                handleClearFilters={this.handlClearFilter}
                handleChangeCheckBoxVals={this.handleChangeCheckboxValues}
                handleChangeRadioBtn={this.handleChangeRadioBtn}
                handleSelectClearAll={this.handleSelectClearAll}
                values={this.state}
                facets={facets}
                keys={keys}
                isEmployeeFilter
              />
            </div>
          )
        }
        {
          filterApls && filterApls === true && (
            <div className="">
              {/* <div className="d-flex"> */}
              {/* {
                id && (
                  <Button
                    onClick={handleRefreshScores}
                    className="mt-0 mr-2 bx--btn--ghost"
                    kind="tertiary"
                  >
                    {t('refreshScores')}
                  </Button>
                )
                } */}
              {/* <ApplicantSort onSortValueChange={onSortValueChange} /> */}
              {/* {ctypeId && Number(ctypeId) === 2 ? (
                  <Button onClick={() => this.handleExportStudentApps()}>
                    Export Applicant Data
                  </Button>
                ) : ''
                } */}
              {/* <Button
                  className="ml-2"
                  kind="tertiary"
                  onClick={this.activeFilterApplModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-filter"
                  >
                    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                  </svg>
                  <span className="ml-2">{`${t('filter')} ${t('applications')}`}</span>
                  {
                    filterLength && filterLength !== 0 ? (
                      <strong className="badge p-1 ml-2" style={{ backgroundColor: 'rgb(62, 165, 89)' }}>{filterLength}</strong>
                    ) : null
                  }
                </Button> */}

              {/* </div> */}
              {/* <Modal
                open={isOpenFilterApplModal}
                modalHeading="Filter Applications"
                primaryButtonText="Submit"
                secondaryButtonText="Cancel"
                onRequestClose={this.dismissFilterApplModal}
                onRequestSubmit={() => this.handleSubmitFilterValues(applTitles)}
              >
                <div>
                  <div className="bx--row">
                    <div className="text-left ml-4 mt-2 text-capitalize bx--type-omega">Click here to clear all the filters:</div>
                    <div className="ml-5 mb-2">
                      <Button small kind="tertiary" onClick={() => this.handlClearFilter(applTitles)}>
                        Clear
                      </Button>
                    </div>
                  </div>
                  <FilterApplications
                    applTitles={applTitles}
                    handleChangeCheckboxValues={this.handleChangeCheckboxValues}
                    handleOpenAcc={this.handleOpenAcc}
                    handleSelectAllValues={this.handleSelectAllValues}
                    handleClearAllValues={this.handleClearAllValues}
                    isOpenAccr={isOpenAccrAppls}
                    state={this.state}
                  />
                </div>
              </Modal> */}
              <FacetsBrowse
                handleClearFilters={this.handlClearFilter}
                handleChangeCheckBoxVals={this.handleChangeCheckboxValues}
                handleChangeRadioBtn={this.handleChangeRadioBtn}
                handleSelectClearAll={this.handleSelectClearAll}
                values={this.state}
                facets={facets}
                keys={keys}
                defaultCheckList={defaultCheckList}
                isApplsFilter
              // handleSubmitFilterValues={this.handleSubmitFilterValues}
              // defaultCheckList={this.state.defaultCheckList}
              // showAlteredFacetsLabels
              // activeMoreModal={this.activeMoreModal}
              // handleSubmitCheckBox={this.handleSubmitCheckBox}
              // dismissMoreModal={this.dismissMoreModal}
              // isOpenMoreModal={isOpenMoreModal}
              // mainVals={mainVals}
              />
            </div>
          )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.jobs.loading,
  error: state.jobs.error,
  jobs: state.jobs,
});

const mapDispatchToProps = {
  reRenderContent,
  filterJobData,
  applicationsRerenderContent,
  getStudentAppData,
  getEmployees
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FilterData));
