import { withTranslation } from 'react-i18next';
import React from 'react';
import { connect } from 'react-redux';
import { login } from 'actions';
import { push } from 'react-router-redux';
import classNames from 'classnames';

import { fetchUserLoginState } from 'actions/userstate';
import finlandImage from 'assets/images/business-finland.jpg';
import './Login.css';
// import { Mixpanel } from 'components/common/Mixpanel';
import SetMixPanel from 'components/common/GetCurrentPath';


class GoFinland extends React.Component {
  state = {
    isLogging: false,
    username: '',
    password: '',
    error: false,
  };

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value,
      error: false,
    });
  }

  openPopup = () => {
    // Mixpanel.track('BusinessFinland: linkdin login start page');
    SetMixPanel();
    const purl = `${window.location.origin}/auth/candidate/linkedin`;
    const wwidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const wheight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.height;

    const wleft = ((wwidth / 2) - 400);
    const wtop = ((wheight / 2) - 300);
    const winFeatures = `width=800,height=600,top=${wtop},left=${wleft}`;

    const rpop = window.open(purl, 'lpopup1', winFeatures);
  }

  componentDidMount() {
    window.redirectToDashboard2 = (data, origin) => {
      console.log(data, origin);
    };
    this.props.fetchUserLoginState().then(res => {
      const { user: { userId, roleId } = {} } = this.props;
      if (userId && userId !== null && roleId && roleId === 3) {
        this.props.history.push('/app/campus/candidate/business/finland/start');
      }
    })
  }

  render() {
    const formClass = classNames({
      'form-signin': true,
      'text-center': true,
      'card': true,
      'error': this.state.error,
    });
    
    return (
      <div className="finlandLanding col bx--row m-0 p-0">
        <div className="left-side col m-0 p-0 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${finlandImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <div className="text-dark m-3 text-center p-2" style={{ background: `rgba(255,255,255,0.65)` }} >
            <div className="h1 font-weight-bold"> IS FINLAND THE RIGHT FIT FOR YOU? </div>
            <div className="bx--type-gamma">Come and work with some of the best teams on the planet! Finland is among the superpowers in ICT & Digitalization, from 5G networks to financial technology and AR/VR innovations to IoT, digital education and health solutions. Sign up to X0PA to get your Fit for Finland score and apply now for a job in Finland. </div>
            <a href="https://www.businessfinland.fi/en/do-business-with-finland/kampanjasivut/indiatalentboost/ " target="_blank" className="bx--btn bx--btn--primary mt-3" >Learn more</a>
          </div>
        </div>
        <div className="right-side d-flex justify-content-center align-items-center col m-0 p-0" style={{ backgroundColor: 'white' }}>
          <div className="align-items-center d-flex justify-content-center m-0 row">
            <div className="d-flex justify-content-center ml-3">
              <img className="mb-4 mx-auto" src={require('assets/images/republic-polytechnic.png')} />
            </div>
            <div className="bx--col-lg-12 bx--col-md-12 d-flex justify-content-center xpa--login">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-4" id="candidate-login" role="tabpanel" aria-labelledby="candidate-login-tab">
                <div className="">
                  <div className="form-signin text-center">{/* card */}
                    <div className="mx-auto mt-auto mb-auto">
                      <small className="text-dark bx--type-caption">To create or edit application, Sign-in with LinkedIn to get started</small>
                      <div className="boxOr ml-3 pt-2">
                        <div className="cursor-pointer" onClick={() => this.openPopup()}>
                          <img src={require('assets/images/linkedin/Sign-In-Large---Default.png')} style={{ width: "100%" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  login, push, fetchUserLoginState,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoFinland);
