import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Tile, TextInput, ModalWrapper,
  Select, SelectItem, Loading, RadioButton, Button, InlineNotification,RadioButtonGroup, Form
} from 'carbon-components-react';
import _ from 'lodash';

import { createAnInterview, getInterviewsForRecruiter } from 'actions/videoInterview';

import {
  getApplicationWorkflow,
  updateApplicationStage,
  updateApplicationSubStage,
  updateSubStage,
} from 'actions/workflow';

import './interview.css';

class CreateAnInterviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      interviewName: '',
      interviewURL: '',
      templateId: 'placeholder-item',
      showNotification: false,
      useTemplate: null,
      showPostInterviewCreationActions: false,
      validationError: null,
    };
  }

  componentDidMount() {
    this.props.getInterviewsForRecruiter({ status: 'all' });
  }

  getInterviews = () => {
    const { interviews = [] } = this.props;
    const resfil = _.map(interviews, (eachInterview) => {
      const {
        id,
        interviewId,
        interviewName,
      } = eachInterview || {};
      return {
        id, interviewId, interviewName,
      };
    });
    return resfil;
  }

  validateForm = () => {
    let validationError;
    if (this.state.useTemplate === null) {
      validationError = {
        title: 'Please select an option to create video interview from'
      };
    }

    if (this.state.useTemplate && (!this.state.templateId || (this.state.templateId && this.state.templateId === 'placeholder-item'))) {
      validationError = {
        title: 'Please select a video interview template to clone from',
      };
    }

    return validationError;
  }

  validateAndSubmitForm = () => {
    // validation checks
    const validationError = this.validateForm();
    // console.log("validationError = ", validationError);
    if (!validationError) {
      this.createInterview();
    } else {
      this.setState({ validationError });
    }

    return false;
  }

  createInterview = () => {
    const {
      appId, jobId, appStageId, appStageUserMapId,
    } = this.props;
    const { interviewName, templateId } = this.state;
    if (!interviewName || !appId || !jobId || !appStageUserMapId) {
      console.error('No valid data');
      return false;
    }
    const data = {
      appId,
      jobId,
      appStageUserMapId,
      interviewName,
    };
    if (templateId && templateId !== 'placeholder-item') {
      data.templateId = templateId;
    }
    this.props.createAnInterview(data).then((res) => {
      this.setState({
        interviewName: '',
        templateId: 'placeholder-item',
        showNotification: true,
        showPostInterviewCreationActions: true,
        interviewId: res && res.interview,
      }, () => {
        this.props.updateApplicationStage({
          appStageId: appStageId || 0,
          videoInterviewId: (res && res.interview),
        }).then(() => {
          this.props.getApplicationWorkflow(appId || 0);
        });
      });
    });
    return true;
  }

  handleOnChange = (e) => {
    this.setState({
      interviewName: e.target.value,
    });
  }

  onSelectChange = (e) => {
    this.setState({
      templateId: e.target.value,
    });
  }

  handleTemplateOption = (e) => {
    switch (e) {
      case 'new':
        this.setState({
          useTemplate: false,
        });
        break;
      case 'template':
        this.setState({
          useTemplate: true,
        });
        break;
      default:
        console.log(e);
    }
  }

  addQuestions = (appStageUserMapId, interviewId) => {
    this.props.history.push(`/app/campus/interviews/${interviewId}`);
  }

  render() {
    const {
      hideTransactionButtons, hideForm, appStageUserMapId,
    } = this.props;
    const { validationError } = this.state;
    let showPostInterviewCreationActions = this.state.showPostInterviewCreationActions || this.props.hideForm || false;
    let interviewId = this.state.interviewId || this.props.interviewId;

    return (
      <div className="mt-2 mb-2 xpa-form--create-interview">
        {
          !showPostInterviewCreationActions && !hideForm && (
            <div>
              <Form
                onSubmit={(e) => { this.validateAndSubmitForm(); e.preventDefault(); }}
              >
                {
                  validationError && (
                  <InlineNotification lowContrast
                    onCloseButtonClick={() => {}}
                    className="mt-0"
                    title={validationError.title}
                    subtitle=""
                    iconDescription="describes the close button"
                    kind="error"
                  />)
                }
                <TextInput
                  id="test2"
                  placeholder="Eg: John Doe - Video Interview"
                  labelText="Please provide a title for your video interview"
                  onChange={this.handleOnChange}
                  value={this.state.interviewName}
                  required
                />
                <br />
                <RadioButtonGroup onChange={this.handleTemplateOption} name="radio-button-group">
                  <RadioButton
                    name="workflow-vi--new"
                    // onChange={this.handleTemplateOption}
                    value="new"
                    labelText="Create new"
                    id="workflow-vi--new"
                    className="mb-0 pl-1"
                  />
                  <RadioButton
                    name="workflow-vi--clone"
                    // onChange={this.handleTemplateOption}
                    value="template"
                    labelText="Use an existing video interview as a template"
                    id="workflow-vi--clone"
                    className="mb-0 pl-1"
                  />
                </RadioButtonGroup>
                {
                  this.state.useTemplate && (
                    <Select
                      id="interview-template"
                      labelText="Select Template"
                      defaultValue={this.state.templateId}
                      onChange={this.onSelectChange}
                    >
                      <SelectItem
                        value="placeholder-item"
                        text="-- None --"
                      />
                      {
                        this.getInterviews().map(interview => (
                          <SelectItem
                            key={interview.id}
                            value={interview.interviewId}
                            text={interview.interviewName}
                          />
                        ))
                      }
                    </Select>
                  )
                }
                {
                  !hideTransactionButtons && (
                    <Button
                      type="submit"
                      className="mt-2"
                    >
                      Create Interview
                    </Button>
                  )
                }
              </Form>
            </div>
          )
        }
        {
          showPostInterviewCreationActions && (
            <div>
              <div>
                <InlineNotification lowContrast
                  title="A Video Interview has been successfully created."
                  subtitle="Use the following actions to add/edit questions and email to candidate."
                  iconDescription="describes the close button"
                  kind="success"
                />
              </div>
              <div className="row">
                <div className="ml-3 mr-3 mt-2">
                  <Button small kind="tertiary" onClick={() => this.addQuestions(appStageUserMapId, interviewId)} className="some-class">
                    Add Questions
                  </Button>
                </div>
              </div>
            </div>
          )
        }
      </div>);
  }
}

CreateAnInterviewForm.propTypes = {
  appId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  jobId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  appStageUserMapId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = state => ({
  loading: state.videoInterviews.loading,
  user: state.x0paDuser.user,
  interviews: state.videoInterviews.interviews,
});

const mapDispatchToProps = {
  createAnInterview,
  getInterviewsForRecruiter,
  getApplicationWorkflow,
  updateApplicationStage,
  updateApplicationSubStage,
  updateSubStage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAnInterviewForm));
