import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Accordion, AccordionItem, Button,
  MultiSelect, InlineNotification, TextInput,
} from 'carbon-components-react';

import {
  updateJobWorkflowStage,
} from 'actions/job';
import RRQuestionsIframe from 'components/roboroy/RrQuestionsIframe';

class JobWorkflowStages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifMsg: 'Unknown error occured',
      notifType: 'error',
      notifShow: false,
    };
  }

  componentDidMount() {
    const {
      job: {
        jobWorkflowStages,
      } = {},
    } = this.props;
    if (jobWorkflowStages && Array.isArray(jobWorkflowStages)) {
      const savedPanelMemberObj = {};
      jobWorkflowStages.forEach((jws) => {
        const { jobStageId, scheduleEventData } = jws || {};
        if (scheduleEventData) {
          const { members = [] } = scheduleEventData;
          if (members && Array.isArray(members)) {
            const intialMemberObj = [];
            const memberObj = [];
            const memberNames = members.map((m) => {
              if (m && m.memberId && m.memberName) {
                intialMemberObj.push({
                  jobStageId,
                  memberId: m.memberId,
                  memberName: m.memberName || 'Unknown Name',
                });
                memberObj.push({
                  memberId: m.memberId,
                  memberName: m.memberName || 'Unknown Name',
                });
              }
              return (m && m.memberName) || 'Unknown';
            });
            savedPanelMemberObj[`panel-${jobStageId}`] = memberNames;
            savedPanelMemberObj[`panel-initial-${jobStageId}`] = intialMemberObj;
            savedPanelMemberObj[`panel-members-${jobStageId}`] = memberObj;
            this.setState(savedPanelMemberObj);
          }
        }
      });
    }
  }

  getUserFullName = (member) => {
    const firstname = (member && member.userinfo && member.userinfo.firstname);
    const lastname = (member && member.userinfo && member.userinfo.lastname);
    let memberName = `${firstname || 'Unknown'} ${lastname || 'Name'}`;
    if (!firstname && !lastname) {
      memberName = 'Unknown Name';
    }
    return memberName.toUpperCase();
  }

  handleMultiSelectChange = (c, jobStageId) => {
    const {
      selectedItems,
    } = c || {};
    let finalMembers = [];
    const finalMembersObj = [];
    if (selectedItems && Array.isArray(selectedItems)) {
      finalMembers = selectedItems.map((sm) => {
        const memberName = (sm && sm.memberName) || 'Unknown';
        const memberId = (sm && sm.memberId) || '0';
        const memberObj = {
          memberId,
          memberName,
        };
        finalMembersObj.push(memberObj);
        return memberName;
      });
    }
    this.setState({
      [`panel-${jobStageId}`]: finalMembers || [],
      [`panel-members-${jobStageId}`]: finalMembersObj || [],
    });
  }

  handleitemToString = (member) => {
    return (member && member.memberName) || 'Unknown';
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSavePhoneInterview = (jobStageId) => {
    // this.state[`panel-members-${jobStageId}`];
    const {
      user: {
        userId,
        firstname,
        lastname,
      } = {},
    } = this.props;
    const members = [{
      memberId: userId,
      memberName: `${firstname || 'Unknown'} ${lastname}`,
    }];
    const duration = this.state[`phone-${jobStageId}-duration`]
    // if (!members || (members && Array.isArray(members) && members.length <= 0)) {
    //   this.setState({
    //     notifMsg: 'Please add at-least one member to the panel.',
    //     notifType: 'error',
    //     notifShow: true,
    //   });
    //   return;
    // }
    const data = {
      jobStageId,
      scheduleEventData: JSON.stringify(
        {
          members: members || [],
          duration: duration || '60',
        },
      ),
    };
    this.props.updateJobWorkflowStage(data)
      .then((res) => {
        if (res && !res.error) {
          const {
            scheduleEventData,
          } = res || {};
          const scheduleEventDataObj = (scheduleEventData && JSON.parse(scheduleEventData)) || {};
          const {
            members: panelMembers = [],
            duration: eventDuration = '60',
          } = scheduleEventDataObj || {};
          const membersArr = panelMembers.map((m) => {
            return {
              jobStageId,
              memberId: m.memberId,
              memberName: m.memberName || 'Unknown Name',
            };
          });
          
          this.setState({
            [`panel-initial-${jobStageId}`]: membersArr,
            [`phone-${jobStageId}-duration`]: eventDuration,
            notifMsg: 'Interview setup saved successfully.',
            notifType: 'success',
            notifShow: true,
          }, () => {
            this.setState({
              [`isEditing-${jobStageId}`]: false,
            });
            const initialSelectedItems = this.state[`panel-initial-${jobStageId}`];
          });
        }
      });
  }

  parseScheduleObj = (scheduleEventData) => {
    let scheduleEventDataObj = {};
    if (typeof scheduleEventData === 'string') {
      try {
        scheduleEventDataObj = JSON.parse(scheduleEventData);
        return scheduleEventDataObj;
      } catch (err) {
        // console.log(err);
        return {};
      }
    } else if (typeof scheduleEventData === 'object') {
      scheduleEventDataObj = scheduleEventData;
    } else {
      return {};
    }
    return scheduleEventDataObj;
  };

  editPhoneSetup = (jobStageId, scheduleEventData) => {
    const scheduleEventDataObj = this.parseScheduleObj(scheduleEventData);
    const {
      duration = '60',
    } = scheduleEventDataObj || {};
    this.setState({
      [`isEditing-${jobStageId}`]: true,
      [`phone-${jobStageId}-duration`]: duration || '60',
    });
  };

  renderPhoneInterviewSetup = (stage) => {
    const {
      job: {
        collaboration = [],
        jobWorkflow,
      } = {},
      user: {
        firstname,
        lastname,
      } = {},
    } = this.props;
    const {
      jobStageId,
      scheduleEventData,
    } = stage || {};
    const scheduleEventDataObj = this.parseScheduleObj(scheduleEventData);
    const {
      duration = '60',
    } = scheduleEventDataObj || {};
    const panelStateArr = [`${firstname} ${lastname}`]; //this.state[`panel-${jobStageId}`] ;
    const initialSelectedItems = this.state[`panel-initial-${jobStageId}`];
    const panelMembersArr = collaboration && Array.isArray(collaboration)
    && collaboration.map((pm) => {
      return {
        jobStageId,
        memberId: pm.userId,
        memberName: this.getUserFullName(pm) || 'Unknown Name',
      };
    });

    return (
      <div>
        {
          (!initialSelectedItems || this.state[`isEditing-${jobStageId}`]
            || (Array.isArray(initialSelectedItems) && (initialSelectedItems.length <= 0)))
           && (
            <Fragment>
              <p className="mb-0 pb-0">
                <span className="bx--type-strong">Assigned to: </span>
                {
                  panelStateArr && Array.isArray(panelStateArr) && (panelStateArr.join(', '))
                }
              </p>
              {/* <div className="d-inline-block" style={{ width: 200 }}>
                <MultiSelect
                  useTitleInItem
                  label="Select members"
                  invalid={false}
                  invalidText="Invalid Selection"
                  onChange={(c) => this.handleMultiSelectChange(c, jobStageId)}
                  items={panelMembersArr}
                  itemToString={this.handleitemToString}
                  initialSelectedItems={initialSelectedItems}
                />
              </div>
              <div className="d-inline-block bx--type-strong ml-4">
                {
                  panelStateArr && Array.isArray(panelStateArr) && (
                    panelStateArr.join(', ')
                  )
                }
              </div> */}
              <p className="mt-0" style={{ maxWidth: 200 }}>
                <span className="bx--type-strong">Duration: 60mins</span>
                {/* <TextInput
                  type="number"
                  labelText="Duration (in minutes)"
                  id={`phone-${jobStageId}-duration`}
                  name={`phone-${jobStageId}-duration`}
                  onChange={this.handleInputChange}
                  defaultValue={duration || '60'}
                  value={this.state[`phone-${jobStageId}-duration`]}
                  min={15}
                  max={120}
                  step={5}
                /> */}
              </p>
              {/* <Button onClick={() => this.handleSavePhoneInterview(jobStageId, duration)}>Save setup</Button> */}
            </Fragment>
          )
        }
        {
          initialSelectedItems && !(this.state[`isEditing-${jobStageId}`]) && Array.isArray(initialSelectedItems)
          && (initialSelectedItems.length > 0) && (
            <Fragment>
              <div className="d-inline-block bx--type-strong">
                <span className="bx--type-strong">Assigned to: </span>
                {
                  panelStateArr && Array.isArray(panelStateArr) && (
                    panelStateArr.join(', ')
                  )
                }
              </div>
              <p className="mt-4" style={{ maxWidth: 200 }}>
                <span className="bx--type-strong">Duration:</span> {duration} minutes
              </p>
              <Button onClick={() => this.editPhoneSetup(jobStageId, scheduleEventData)}>Edit setup</Button>
            </Fragment>
          )
        }
        
        {/* <Select
          disabled={false}
          inline={false}
          light={false}
          className="d-inline-block mb-2"
          hideLabel
          invalidText="A valid value is required"
          onChange={() => {}}
          id={`select-${jobStageId}`}
          small
          defaultValue="dd"
        >
          {
            collaboration && collaboration.map(eachCollaborator => (
              <SelectItem
                value={eachCollaborator.userinfo && eachCollaborator.userinfo.userId}
                text={`${eachCollaborator.userinfo && eachCollaborator.userinfo.firstname} ${eachCollaborator.userinfo && eachCollaborator.userinfo.lastname}`}
              />
            ))
          }
        </Select> */}
      </div>
    );
  }

  renderOnsiteInterviewSetup = (stage) => {
    return (
      <div>
        {this.renderPhoneInterviewSetup(stage)}
      </div>
    );
  }

  renderVideoInterviewIframe = (stage) => {
    const { stageName, videoInterviewId } = stage || {};
    return (
      <div>
        {
          videoInterviewId && (
            <Fragment>
              <div className="mt-2"><strong>{`${stageName} Setup`}</strong></div>
              <RRQuestionsIframe interviewId={videoInterviewId} />
            </Fragment>
          )
        }
      </div>
    );
  }

  renderStage = (stage) => {
    const { workflowstagetype: { stageTypeName } = {} } = (stage && stage.workflowstage) || {};
    if (!stageTypeName) return (<div />);
    const stageTypeNameLower = (stageTypeName + '').toLowerCase();
    if (stageTypeNameLower.includes('video')) {
      return this.renderVideoInterviewIframe(stage);
    }
    if (stageTypeNameLower.includes('phone')) {
      return this.renderPhoneInterviewSetup(stage);
    }
    if (stageTypeNameLower.includes('online')) {
      return this.renderPhoneInterviewSetup(stage);
    }
    if (stageTypeNameLower.includes('onsite')) {
      return this.renderOnsiteInterviewSetup(stage);
    }
    return (<div />);
  };

  render() {
    const {
      stages = [],
    } = this.props;
    const {
      notifMsg = ' ',
      notifType = 'error',
      notifShow = false,
    } = this.state;
    return (
      <div>
        {
          notifShow && (
            <InlineNotification lowContrast
              iconDescription="Closes the notification"
              title={notifMsg}
              subtitle=""
              kind={notifType}
              onCloseButtonClick={() => this.setState({ notifShow: false, notifMsg: 'Error' })}
            />
          )
        }
        {
          stages && Array.isArray(stages) && (
            <Accordion>
              {
                stages.map((stage, idx) => {
                  const { workflowstage: { stageName }, jobStageId, visibility } = stage || {};
                  return (
                    visibility !== false
                    && jobStageId
                    && idx === 0
                      ? (
                        <AccordionItem
                          key={jobStageId}
                          open
                          title={(
                            <div className="text-uppercase">
                              {stageName}
                            </div>
                          )}
                          onHeadingClick={() => { }}
                        >
                          { this.renderStage(stage) }
                        </AccordionItem>
                      ) : (<div />)
                  );
                })
              }
            </Accordion>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.jobs.loading,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  updateJobWorkflowStage,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobWorkflowStages);
