import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CustomCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      className, imgSrc, title, cardSubTitle, titleOnClick, noCard
    } = this.props;

    return (
      <div className={`${className} ${noCard ? '' : 'card'} mt-2`}>
        {imgSrc && (
          <div className="card-image">
            <img src={imgSrc} className="img-responsive" />
          </div>
        )}
        <div className="card-header">
          <div className="card-title h6 mb-1">
            <Link to="#" onClick={titleOnClick}>
              {title}
            </Link>
          </div>
          <div className="card-subtitle text-gray">{cardSubTitle}</div>
        </div>
        <div className="card-body p-3">{this.props.children}</div>
      </div>
    );
  }
}

export default CustomCard;
