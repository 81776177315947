import { SET_ATTRITION_MODAL } from '../actions/actionTypes';

const showAttritionModal = (state = true, action) => {
  switch (action.type) {
    case SET_ATTRITION_MODAL:
      return action.show;
    default:
      return state;
  }
};

export default showAttritionModal;
