import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from 'carbon-components-react';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import '../dashboard.css';
import { fetchCandidateJobs } from 'actions/candidate/jobs';
import CandidateJobsList from '../components/CandidateJobsList';

class JobsInterviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      interviewsLoading: false,
      status: '',
    };
  }

  componentDidMount() {
    this.setState({
      status: 'onsiteinterview-inprogress',
      interviewsLoading: true,
    }, () => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_INTERVIEWS_CANDIDATE_JOBS').then(() => {
        this.setState({
          interviewsLoading: false,
        });
      });
    });
  }

  handleJobInterviewsTabClick = (tabType) => {
    let selectedTab = 0;
    let status = '';
    switch (tabType) {
      case 'onsiteinterview-inprogress':
        selectedTab = 0;
        status = 'onsiteinterview-inprogress';
        break;
      case 'onsiteinterview-completed':
        selectedTab = 1;
        status = 'onsiteinterview-completed';
        break;
      // case 'videointerview':
      //   selectedTab = 0;
      //   status = 'videointerview';
      //   break;
      // case 'phoneinterview':
      //   selectedTab = 1;
      //   status = 'phoneinterview';
      //   break;
      // case 'writtenassessment':
      //   selectedTab = 2;
      //   status = 'writtenassessment';
      //   break;
      default:
        break;
    }
    this.setState({
      selectedTab,
      status,
      interviewsLoading: true,
    }, () => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_INTERVIEWS_CANDIDATE_JOBS').then(() => {
        this.setState({
          interviewsLoading: false,
        });
      });
    });
  }

  handleRedirectJobInviterviews = (link) => {
    switch (link) {
      case 'onsiteinterview-inprogress':
        this.props.history.push('/app/campus/candidate/jobs/onsiteinterview-inprogress');
        break;
      case 'onsiteinterview-completed':
        this.props.history.push('/app/campus/candidate/jobs/onsiteinterview-completed');
        break;
      case 'writtenassessment':
        this.props.history.push('/app/campus/candidate/jobs/writtenassessment');
        break;
      default:
        break;
    }
  }

  handleRedirectToAllInviterviewsView = (status) => {
    this.props.history.push(`/app/campus/candidate/jobs/${status}`);
  }


  render() {
    const {
      videoInterview,
      phoneInterview,
      writtenAssessment,
      interviewJobs: { applications, count } = {},
      renderApplInvitation,
      renderOfferInvitation,
      onsiteInterviewsInprogress,
      onsiteInterviewsCompleted,
      t,
    } = this.props;
    const { selectedTab, status, interviewsLoading } = this.state;
    return (
      <Fragment>
        <div className="mt-2 p-2">
          <span className="heading-style h6 text-uppercase font-style-heading">
            {t('interviewsInProgress')}
          </span>
          {/* <div className="bx--row pb-2 mt-3">
            <div className="bx--col-lg-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobInviterviews('onsiteinterview-inprogress'); }}>{onsiteInterviewsInprogress || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('offersInProgressTab')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobInviterviews('onsiteinterview-completed'); }}>{onsiteInterviewsCompleted || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('offersCompletedTab')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-4 bx--col-lg-4 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobInviterviews('writtenassessment'); }}>{writtenAssessment || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('writtenAssessment')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div>
            <GenericTabs
              className="d-flex justify-content-end mb-2"
              selected={selectedTab}
              tabContent={[
                {
                  label: t('offersInProgressTab'),
                  value: '',
                  onClick: () => { this.handleJobInterviewsTabClick('onsiteinterview-inprogress'); },
                },
                {
                  label: t('offersCompletedTab'),
                  value: '',
                  onClick: () => { this.handleJobInterviewsTabClick('onsiteinterview-completed'); },
                },
                // {
                //   label: t('videoInterviewsTab'),
                //   value: '',
                //   onClick: () => { this.handleJobInterviewsTabClick('videointerview'); },
                // },
                // {
                //   label: t('phoneInterviewsTab'),
                //   value: '',
                //   onClick: () => { this.handleJobInterviewsTabClick('phoneinterview'); },
                // },
                // {
                //   label: t('writtenAssessmentsTab'),
                //   value: '',
                //   onClick: () => { this.handleJobInterviewsTabClick('writtenassessment'); },
                // },
              ]}
            />
          </div>
          {
            interviewsLoading === true
              && (
                <div className="bx--row justify-content-around">
                  <Loading withOverlay={false} />
                </div>
              )
          }
          {
            interviewsLoading === false
              && (
                <CandidateJobsList
                  sortedJobs={applications}
                  status={status}
                  count={count}
                  renderApplInvitation={() => {}}
                  renderOfferInvitation={() => {}}
                />
              )
          }
          {
            count && count > 5
              ? (
                <div>
                  <span className="xpa-link" onClick={() => { this.handleRedirectToAllInviterviewsView(status); }}>{t('more')}</span>
                </div>
              ) : null
          }
        </div>
        <hr />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.candidateJobs.loading,
  error: state.candidateJobs.error,
  canjobs: state.candidateJobs,
  interviewJobs: state.candidateJobs.interviewJobs,
});

const mapDispatchToProps = {
  fetchCandidateJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobsInterviews));
