import {
    REQUEST_COMPANY_JOB_POSTINGS,
    ERROR_COMPANY_JOB_POSTINGS,
    SUCCESS_COMPANY_JOB_POSTINGS,
    REQUEST_TRANSFER_JOB,
    ERROR_TRANSFER_JOB,
    SUCCESS_TRANSFER_JOB,
} from '../actions/actionTypes';

const initialState = {
    companyJobs: {}
}

const companyJobs = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case REQUEST_COMPANY_JOB_POSTINGS: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_COMPANY_JOB_POSTINGS: {
            return {
                ...state,
                loading: false,
                companyJobs: payload,
            }
        };
        case ERROR_COMPANY_JOB_POSTINGS:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case REQUEST_TRANSFER_JOB: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_TRANSFER_JOB: {
            return {
                ...state,
                loading: false,
            }
        };
        case ERROR_TRANSFER_JOB:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
}

export default companyJobs;