import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tile, TextInput,
  ToggleSmall, Button,
  InlineNotification,
  Loading,
  TextArea,
} from 'carbon-components-react';
import isEmail from 'validator/lib/isEmail';
import Select from 'react-select';
import zxcvbn from 'zxcvbn';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import {
  xSearchCompanies,
} from 'actions/xpa';

import { registerStudent, getCountries } from 'actions/campus/registrationRequest';

import './Login.css';

class registerCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentUniversityId: '',
      firstname: '',
      firstNameInvalid: false,
      lastname: '',
      countryId: 0,
      phone: '',
      phoneInvalid: false,
      email: '',
      isFulltime: false,
      company: {},
      password: '',
      repassword: '',
      isPasswordInvalid: false,
      pwdLevel: ['weak', 'weak', 'good', 'strong', 'stronger'],
      score: 0,
      isFirstCase: true,
      suggestions: [],
      isSignupDisabled: true,
      createError: false,
      createSuccess: false,
      isLoading: false,
      hashStr: '',
      personalEmail: '',
      mailingAddress: '',
      placeOfBirth: 0,
      studentUniversityIdValid: false,
      lastNameValid: false,
      countryIdValid: false,
      placeOfBirthValid: false,
      isEmailValid: false,
      isMailingAddressValid: false,
      companyName: '',
      errorMsg: '',
      showEmailerr: false,
      emailErrMsg: '',
    };
  }

  componentDidMount() {
    const { match: { params: { id = '' } } } = this.props;
    if (id && id !== null) {
      const companyId = Number(id.split('_')[2]);
      const emailHash = id.split('_')[3];
      const email = window.atob(emailHash);
      this.props.getCountries();
      this.props.xSearchCompanies(null, null, companyId).then((res) => {
        if (res && !res.error) {
          this.setState({
            email,
            companyId,
            hashStr: id,
            companyName: res && res.companyName,
          });
        }
      });
    }
  }

  onChangeEvent = (name, value) => {
    this.setState({
      [name]: value,
    });
  }

  onRegister = () => {
    const {
      t,
    } = this.props;
    const {
      firstname, lastname, email, phone,
      studentUniversityId, companyId, country,
      hashStr, personalEmail, placeOfBirth, mailingAddress
    } = this.state;
    let {
      firstNameInvalid, lastNameValid, countryIdValid,
      phoneInvalid, placeOfBirthValid, isEmailValid,
      studentUniversityIdValid, isMailingAddressValid,
      phoneLengthInvalid
    } = this.state;
    if (!studentUniversityId || studentUniversityId === null || studentUniversityId === undefined) {
      studentUniversityIdValid = true;
    } else {
      studentUniversityIdValid = false;
    }
    if (!country || (country && !country.value) || country === null || country === undefined) {
      countryIdValid = true;
    } else {
      countryIdValid = false;
    }
    if (!firstname || firstname === null || firstname === undefined) {
      firstNameInvalid = true;
    } else {
      firstNameInvalid = false;
    }
    if (!lastname || lastname === null || lastname === undefined) {
      lastNameValid = true;
    } else {
      lastNameValid = false;
    }
    if (!phone || phone === null || phone === undefined || (phone && phone.length>8)) {
      phoneInvalid = true;
      phoneLengthInvalid = (phone && phone.length>8)?true:false;
    } else {
      phoneInvalid = false;
      phoneLengthInvalid = false;
    }
    // if (!placeOfBirth || (placeOfBirth && !placeOfBirth.value) || placeOfBirth === null || placeOfBirth === undefined) {
    //   placeOfBirthValid = true;
    // } else {
    //   placeOfBirthValid = false;
    // }
    if (!personalEmail || personalEmail === null || personalEmail === undefined || !isEmail(personalEmail)) {
      isEmailValid = true;
    } else {
      isEmailValid = false;
    }
    if (!mailingAddress) {
      isMailingAddressValid = true;
    } else {
      isMailingAddressValid = false;
    }
    if (personalEmail && personalEmail.toString() && personalEmail.toLowerCase() === email && email.toString() && email.toLowerCase()) {
      this.setState({
        showEmailerr: true,
        emailErrMsg: `${t('sameEmailErrMsg')}`,
      });
    } else {
      this.setState({
        showEmailerr: false,
        emailErrMsg: '',
      }, () => {
        this.setState({
          firstNameInvalid,
          lastNameValid,
          countryIdValid,
          phoneInvalid,
          phoneLengthInvalid,
          placeOfBirthValid,
          isEmailValid,
          isMailingAddressValid,
          studentUniversityIdValid,
        }, () => {
          if (this.state.firstNameInvalid !== true && this.state.lastNameValid !== true
            && this.state.countryIdValid !== true && this.state.phoneInvalid !== true
            && this.state.placeOfBirthValid !== true && this.state.isEmailValid !== true
            && this.state.isMailingAddressValid !== true) {
            const data = {
              firstname,
              lastname,
              email,
              personalEmail,
              // placeOfBirth: placeOfBirth ? placeOfBirth.value : '',
              phone,
              studentUniversityId,
              companyId,
              countryId: country ? country.value : '',
              hashStr,
              mailingAddress
            };
            this.setState({ isLoading: true }, () => {
              this.props.registerStudent(data).then((res) => {
                if (res.error !== undefined) this.setState({ createError: true, errorMsg: res && res.error && res.error.message, isLoading: false });
                else {
                  this.setState({ createSuccess: true, isSignupDisabled: true, isLoading: false });
                }
              });
            });
          }
        });
      });
    }
  }

  onToggle = (flag) => {
    this.setState({ isFulltime: flag });
  }

  countryDropdown = (value) => {
    this.setState({
      country: value,
    });
  };

  placeOfBirthDropdow = (value) => {
    this.setState({
      placeOfBirth: value,
    });
  }

  onInputCountryDropdown = (value) => {
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      // if (value.length > 2) {
      //   this.props.getCountries();
      // }
      return value;
    }
    return null;
  };

  handleChange = (event, type = '') => {
    // const { email, firstname, lastname } = this.state;
    // const password = event.target.value;
    // if (type === 'password') {
    //   if (password.length > 7) {
    //     const hasUpperCase = /[A-Z]/.test(password);
    //     const hasLowerCase = /[a-z]/.test(password);
    //     const hasNumbers = /\d/.test(password);
    //     if (hasUpperCase && hasLowerCase && hasNumbers) this.setState({ isFirstCase: false });
    //     else this.setState({ isFirstCase: true });
    //     const Result = zxcvbn(password, [email, firstname, lastname]);
    //     this.setState({ suggestions: Result.feedback.suggestions, score: Result.score });
    //     if (Result.score < 2 || (!hasUpperCase || !hasLowerCase || !hasNumbers)) this.setState({ isPasswordInvalid: true });
    //     else this.setState({ isPasswordInvalid: false, isSignupDisabled: false });
    //   } else this.setState({ isPasswordInvalid: true, isFirstCase: true, isSignupDisabled: true });
    // }
    const { target: { name, value } = {} } = event;
    this.setState({
      [name]: value,
    });
  }

  getSingapoorOnTop = (propsCountries) => {
    // let propsCountries = [];
    const fromIndex =  propsCountries.findIndex(object => {
      return object && object.countryFull == 'Singapore';
    });
    const toIndex = 0;
    const reqCountryElement = propsCountries.splice(fromIndex, 1)[0];
    propsCountries.splice(toIndex, 0, reqCountryElement);
    return propsCountries;
  }

  render() {
    const {
      studentUniversityId, firstname, firstNameInvalid, lastname,
      phone, email, phoneInvalid, phoneLengthInvalid, isFulltime, country,
      repassword, password, isPasswordInvalid, isPasswordMatch,
      pwdLevel, score, isFirstCase, isSignupDisabled,
      createError, createSuccess, isLoading, placeOfBirth,
      personalEmail, studentUniversityIdValid, lastNameValid,
      countryIdValid, placeOfBirthValid, isEmailValid,
      companyName, errorMsg, showEmailerr, emailErrMsg, mailingAddress, isMailingAddressValid
    } = this.state;
    const { companyAndOffice: { countries: propsCountries = [] } = {}, t, user } = this.props;
    const countries = this.getSingapoorOnTop(propsCountries);
    const {
      userId,
    } = user || {};
    let showLogo = null;
    if (userId) {
      showLogo = false;
    } else {
      showLogo = true;
    }
    const invalidText = `${t('fieldErrMsg')}`;
    return (
      <Fragment>
        <div className="col-12 registerCandidate align-items-center d-flex justify-content-around">
          { !createSuccess && (
            <div className="container bg-white col-10 align-items-center d-flex justify-content-center">
              <Tile className="bx--row text-center" style={{ maxWidth: 800 }}>
                {
                  showLogo === true && (
                    <div className="col-12 mb-4 row">
                      <img className="mx-auto area_icon_width" src={require('assets/images/republic-polytechnic.png')} alt="" />
                      
                    </div> 
                  )
                }
                
                {
                   createError && <InlineNotification lowContrast kind="error" title="" subtitle={errorMsg} onCloseButtonClick={() => { this.setState({ createError: true }); }} />
                }
                {
                  !createError && showEmailerr === true
                    && (
                      <InlineNotification lowContrast kind="error" title="" subtitle={emailErrMsg} onCloseButtonClick={() => { this.setState({ createError: true }); }} />
                    )
                }
                
                <div className="col-12 font-weight-bold mb-4 reg_head_form bx--type-beta">{t('registrationForm')}</div>

                {/* edit section  santosh*/}
          <form className="forrm_box_pos studentInfo ">
              <div className="forrm_box_pos form-row my-2">
                <div className="col-6">
                <TextInput id="companyName" name="companyName" labelText={<span>{t('education')} {t('institution')}</span>} value={companyName && companyName.toUpperCase()} disabled />
                </div>
                <div className="col-6">
                <TextInput id="studentUniversityId" labelText={t('studentId')} invalid={studentUniversityIdValid} invalidText={invalidText} name="studentUniversityId" placeholder={(email && email.includes('connect.np.edu.sg')) ? "eg: 10123456A":"eg: S10123456A"} value={studentUniversityId} onChange={({ target: { value = '', name = '' } = {} }) => { this.onChangeEvent(name, value); }} />
                </div>
              </div>
              <div className="forrm_box_pos form-row ">
              <div className="col-6">
              
              </div>
                <div className="col-6">
                
                </div>
              </div>
              <div className="forrm_box_pos form-row my-2">
                <div className="col-6">
                    <DropDownBox
                        title={t('country')}
                        mainClassName="bx--form-item"
                        titleClass="bx--select"
                        labelClass="bx--label"
                        custom
                        className={countryIdValid === true ? 'bx--col m-0 p-0 xpa-rs-wrap invalid mb-0' : 'bx--col m-0 p-0'}
                        options={countries.map((one) => ({
                          label: one && one.countryFull,
                          value: one && one.countryId,
                        }))}
                        name="countryValue"
                        onInputChange={e => this.onInputCountryDropdown(e)}
                        placeholder={t('country')}
                        onChange={(id, value, name) => this.countryDropdown(value)}
                        selectedValue={country}
                      />
                      {
                        countryIdValid === true && (
                          <div className="invalid-login-color text-left small">{invalidText}</div>
                        )
                      }
                </div>
                <div className="col-6">
                      
                </div>
              </div>
              <div className="forrm_box_pos form-row my-2">
                <div className="col-6">
                <TextInput id="firstName" name="firstname" labelText={t('firstName')} invalid={firstNameInvalid} invalidText={invalidText} placeholder={t('firstName')} value={firstname} onChange={({ target: { value = '', name = '' } = {} }) => { this.onChangeEvent(name, value); }} />
                </div>
                <div className="col-6">
                <TextInput id="lastName" name="lastname" labelText={t('lastName')} invalid={lastNameValid} invalidText={invalidText} placeholder={t('lastName')} value={lastname} onChange={({ target: { value = '', name = '' } = {} }) => { this.onChangeEvent(name, value); }} />
                </div>
              </div>
              <div className="forrm_box_pos form-row my-2">
                  <div className="col-6">
                  <TextInput 
                    id="phone" 
                    name="phone" 
                    labelText={t('phone')} 
                    invalid={phoneInvalid} 
                    invalidText={!phoneLengthInvalid ? invalidText: 'Mobile No. can contain only 8 digits'} 
                    placeholder={t('phone')} 
                    value={phone} 
                    onChange={({ target: { value = '', name = '' } = {} }) => { 
                      this.onChangeEvent(name, value); 
                    }} 
                  />
                  </div>
                  <div className="col-6">
                  
                  </div>
              </div>
              <div className="forrm_box_pos form-row my-3">
                  <div className="col-6">
                  <TextInput id="email" name="email" disabled labelText={t('emailAddressSchool')} placeholder="email address" value={email} />
                  </div>
                  <div className="col-6">
                  <TextInput id="personalEmail" name="personalEmail" labelText={t('personalEmail')} invalid={isEmailValid} invalidText={invalidText} placeholder="myemail@gmail.com" value={personalEmail} onChange={({ target: { value = '', name = '' } = {} }) => { this.onChangeEvent(name, value); }} />
                  </div>
              </div>
              <div className="forrm_box_pos form-row my-3">
                  <div className="col-6">
                  <TextArea id="mailingAddress" name="mailingAddress" invalid={isMailingAddressValid} invalidText={invalidText} labelText={t('mailingAddress')} placeholder="Enter mailing address" value={mailingAddress} onChange={({ target: { value = '', name = '' } = {} }) => { this.onChangeEvent(name, value); }} />
                  </div>
              </div>
              <div className="forrm_box_pos form-row area_btn_pos my-3">
                  <div className="col-6 my-3">
                  <Button small className="" onClick={e => this.onRegister(e)}>{t('register')}</Button>
                  </div>
                  <div className="col-6">
                    
                  </div>
              </div>
              
          </form>

                {/* edit section  santosh*/}

              </Tile>
            </div>
          )}
          {createSuccess && (
            <div className="align-items-center card d-flex justify-content-center card m-3 p-3" style={{ width: '100%' }}>
              <div className="row mb-4">
                <img className="mx-auto" src={require('assets/images/republic-polytechnic.png')} alt="" />
              </div>
              <div className="align-content-center text-center w-25">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{ maxWidth: 100 }} >
                  <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                  <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                </svg>
              </div>
              <div className="text-center bx--type-beta">{t('studentProfileCreated')}</div>
              {
                showLogo === true && (
                  <div className="m-3 text-center bx--row justify-content-center">
                    <div className="m-3">
                      <Button kind="tertiary" className="" onClick={() => this.props.history.push('/public/student/login')}>{t('campusLogin')}</Button>
                    </div>
                  </div>
                )
              }
              {
                showLogo === false && (
                  <div className="m-3 text-center bx--row justify-content-center">
                    <div className="m-3">
                      <Button kind="tertiary" className="" onClick={() => this.props.history.push('/app/campus/browse/students')}>{t('browseStudents')}</Button>
                    </div>
                  </div>
                )
              }
            </div>
          )}
          {isLoading && <Loading withOverlay />}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  companyAndOffice: state.companyAndOffice,
  registration: state.RegistrationRequest,
});

const mapDispatchToProps = {
  getCountries,
  xSearchCompanies,
  registerStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(registerCandidate));
