import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_GET_EMAIL_TEMPLATES, REQUEST_GET_EMAIL_TEMPLATE,
  REQUEST_UPSERT_EMAIL_TEMPLATE, REQUEST_SEND_EMAIL_TEMPLATE,
  REQUEST_DELETE_CUSTOM_EMAIL_TEMPLATES,
} from './actionTypes';

const getEmailTemplates = () => (dispatch) => {
  const url = `${baseUrl}/emailtemplates?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_EMAIL_TEMPLATES,
    url,
    method: 'GET',
    responseType: 'application/json',
  };
  return callApi(params);
};

const deleteCustomEmailTemplates = templateId => (dispatch) => {
  if (!templateId) {
    return {
      error: {
        code: 404,
        message: 'No valid email template id',
      },
    };
  }
  const url = `${baseUrl}/emailtemplates/${templateId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_DELETE_CUSTOM_EMAIL_TEMPLATES,
    url,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

const getCustomEmailTemplates = () => (dispatch) => {
  const url = `${baseUrl}/emailtemplates?_=${new Date().getTime()}&type=usertemplates`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_EMAIL_TEMPLATES,
    url,
    method: 'GET',
    responseType: 'application/json',
  };
  return callApi(params);
};

const getEmailTemplate = templateId => (dispatch) => {
  if (!templateId) {
    return {
      error: {
        code: 404,
        message: 'No valid email template id',
      },
    };
  }
  const url = `${baseUrl}/emailtemplates/${templateId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_EMAIL_TEMPLATE,
    url,
    method: 'GET',
    responseType: 'application/json',
  };
  return callApi(params);
};

const addEmailTemplate = data => (dispatch) => {
  const { templateId, displayName } = data || {};
  if (!templateId && !displayName) {
    return {
      error: {
        code: 404,
        message: 'No valid email template id',
      },
    };
  }
  const url = templateId ? `${baseUrl}/emailtemplates/${templateId}?_=${new Date().getTime()}` : `${baseUrl}/emailtemplates?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_EMAIL_TEMPLATE,
    url,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

const upsertEmailTemplate = data => (dispatch) => {
  const { templateId } = data || {};
  if (!templateId) {
    return {
      error: {
        code: 404,
        message: 'No valid email template id',
      },
    };
  }

  const url = `${baseUrl}/emailtemplates/${templateId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_EMAIL_TEMPLATE,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

const sendEmailTemplate = data => (dispatch) => {
  const {
    templateId, email, templateName, payload,
  } = data || {};
  if ((!templateId && !templateName) || (!email && !payload)) {
    console.error('No valid email template id or email', data);
    return Promise.resolve({
      error: {
        code: 404,
        message: 'No valid email template id or email',
      },
    });
  }

  const url = `${baseUrl}/emailtemplates/send-email/${templateId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_SEND_EMAIL_TEMPLATE,
    url,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

export {
  getEmailTemplates,
  getEmailTemplate,
  addEmailTemplate,
  upsertEmailTemplate,
  sendEmailTemplate,
  getCustomEmailTemplates,
  deleteCustomEmailTemplates,
};
