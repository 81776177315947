import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getJobAnalyticsDashboardCount,
} from 'actions/talentSearch/candidatesList';
import {
  Loading, Tile
} from 'carbon-components-react';
import ColoredCircle from 'components/common/ColoredCircle';

import './JobAnalytics.css';

class JobAnalytics extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getJobAnalyticsDashboardCount({
      status: 'recommended',
      jobId: this.props.jobId
    })

  }
  render() {
    return (
      <div className="xpa-job-analytics">
        {this.props.jobanalytics && this.props.jobanalytics.loading && (
          <div className="text-center"><Loading className="d-inline-block" withOverlay={false} /></div>
        )}
        {this.props.jobanalytics && !this.props.jobanalytics.loading && (
          <div className="pt-3">
            <div className="row">
              {this.props.jobanalytics && this.props.jobanalytics.jobanalytics && this.props.jobanalytics.jobanalytics.dashboard && (
                <div className="col-xs-12 col-sm-12 col-md-4 mb-2">
                  <Tile className="xpa-job-analytics--card xpa-job-analytics--card--grey ">
                    <div className="numiconcard__number--title text-uppercase xpa-numiconcard__open-jobs">Total Applications</div>
                    <div className="numiconcard__number--number text-center">
                      {this.props.jobanalytics.jobanalytics.dashboard.allApplicants}
                    </div>
                  </Tile>
                </div>
              )
              }
              {this.props.jobanalytics && this.props.jobanalytics.jobanalytics && this.props.jobanalytics.jobanalytics.dashboard && (
                <div className="col-xs-12 col-sm-12 col-md-4 mb-2">
                  <Tile className="xpa-job-analytics--card xpa-job-analytics--card--orange ">
                    <div className="numiconcard__number--title text-uppercase xpa-numiconcard__open-jobs">Roboroy Recommended</div>
                    <div className="numiconcard__number--number text-center">
                      {this.props.jobanalytics.jobanalytics.dashboard.recommended}
                    </div>
                  </Tile>
                </div>
              )
              }
              {this.props.jobanalytics && this.props.jobanalytics.jobanalytics && this.props.jobanalytics.jobanalytics.dashboard && (
                <div className="col-xs-12 col-sm-12 col-md-4 mb-2">
                  <Tile className="xpa-job-analytics--card xpa-job-analytics--card--green ">
                    <div className="numiconcard__number--title text-uppercase xpa-numiconcard__open-jobs">Shortlisted</div>
                    <div className="numiconcard__number--number text-center">
                      {this.props.jobanalytics.jobanalytics.dashboard.shortlisted}
                    </div>
                  </Tile>
                </div>
              )
              }
            </div>
            <hr />
            {this.props.jobanalytics && this.props.jobanalytics.jobanalytics
              && this.props.jobanalytics.jobanalytics.recommendedApplications
              && this.props.jobanalytics.jobanalytics.recommendedApplications.length > 0
              && (
                <div>
                  <div className="pt-3 border-0 text-dark text-uppercase pb-2">Applicants shortlisted by RoboRoy:</div>
                  {this.props.jobanalytics && this.props.jobanalytics.jobanalytics && this.props.jobanalytics.jobanalytics.recommendedApplications && (
                    <div className="row pl-3 pr-3">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="bx--modal-header__label p-2">Name</th>
                            <th scope="col" className=" bx--modal-header__label text-center p-2">CV Relevance(%)</th>
                            <th scope="col" className=" bx--modal-header__label text-center  p-2">Loyalty(%)</th>
                            <th scope="col" className=" bx--modal-header__label text-center  p-2">Performance(%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.jobanalytics.jobanalytics.recommendedApplications && this.props.jobanalytics.jobanalytics.recommendedApplications.map((eachAppl, indx) => (
                            <tr>
                              <td className={`h6 font-weight-bold pt-3 text-dark1 ${indx == 0 ? 'border-0' : ''}`}>{eachAppl && eachAppl.profile && eachAppl.profile.firstName} {eachAppl && eachAppl.profile && eachAppl.profile.lastName}</td>
                              <td>
                                <ColoredCircle className="xp-c__colored-circle--big" getColor="getAbsoluteMatchingColor" value={eachAppl && eachAppl.skillMatch ? Math.round(eachAppl.skillMatch) : (eachAppl && eachAppl.skillMatch === null ? 'N/A' : 0)} />
                              </td>
                              <td>
                                <ColoredCircle className="xp-c__colored-circle--big" getColor="getAbsoluteMatchingColor" value={eachAppl && eachAppl.loyalty ? Math.round(eachAppl.loyalty) : (eachAppl && eachAppl.loyalty === null ? 'N/A' : 0)} />
                              </td>
                              <td>
                                <ColoredCircle className="xp-c__colored-circle--big" getColor="getAbsoluteMatchingColor" value={eachAppl && eachAppl.performance ? Math.round(eachAppl.performance) : (eachAppl && eachAppl.performance === null ? 'N/A' : 0)} />
                              </td>

                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}


          </div>
        )}
      </div>);
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
  jobanalytics: (state.jobAnalytics) || {}
});

const mapDispatchToProps = {
  getJobAnalyticsDashboardCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobAnalytics);
