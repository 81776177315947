import { useTranslation } from 'react-i18next';

const NavItems = [
  {
    name: 'campus',
    items: [
      {
        label: 'dashboard',
        icon: 'icon-home',
        path: '/app/campus',
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>`,
        roles: [1,4,5,6,9],
        ctypeId: [1, 2],
      },
      {
        label: 'Cities',
        icon: 'fa fa-map-marker',
        path: '/app/campus/browse/cities',
        roles: [1,4,5,6,9],
        ctypeId: [0],
      },
      {
        label: 'Languages',
        icon: 'fa fa-language',
        path: '/app/campus/browse/languages',
        roles: [1,4,5,6,9],
        ctypeId: [0],
      },
      {
        label: 'browseCompanies',
        icon: 'fa fa-id-badge',
        path: '/app/campus/browse/companies',
        roles: [1,4,5,6,9],
        clName: 'ml-2',
        ctypeId: [2],
      },
      {
        label: 'browseInstitutions',
        icon: 'fa fa-building',
        path: '/app/campus/browse/institutions',
        roles: [1,4,5,6,9],
        clName: 'ml-2',
        ctypeId: [1],
      },
      {
        label: 'students',
        icon: 'fa fa-graduation-cap',
        path: '/app/campus/browse/students',
        roles: [1,4,5,6,9],
        clName: 'ml-2',
        ctypeId: [2],
      },
      {
        label: 'jobs',
        icon: 'icon-documents-text',
        path: '/app/campus/jobs/open/',
        svg: `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>`,
        roles: [1,4,5,6,9],
        ctypeId: [1, 2],
      },
      {
        label: 'jobsShared', // with me
        icon: 'fa fa-share-alt',
        path: '/app/campus/jobs/shared/page/1/limit/10/',
        roles: [1,4,5,6,9],
        ctypeId: [1, 2],
      },
      {
        label: 'applicants',
        icon: 'icon-group',
        path : '/app/campus/candidates/xopa/processing',
        svg:`
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>`,
        roles: [1,4,5,6,9],
        ctypeId: [1, 2],
      },
      {
        label: 'videoInterviewsTab',
        path: '/app/campus/interviews/status/draft',
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-video"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg>`,
        roles: [1,4,5,6,9],
        ctypeId: [1, 2],
        accepts: [
          '/app/campus/interviews/status/draft',
          '/app/campus/interviews/status/awaiting',
          '/app/campus/interviews/status/awaitingfeedback',
          '/app/campus/interviews/status/completed',
          '/app/campus/interviews/status/skipped',
        ],
      },
      {
        label: 'offers',
        icon: 'fa fa-handshake-o',
        path: '/app/campus/offers',
        roles: [1,4,5,6,9],
        ctypeId: [1, 2],
        accepts: ['/app/campus/offers'],
      },
      {
        label: 'calendar',
        icon: 'icon--calendar',
        svg:`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>`,
        path: '/app/campus/interviews-calendar',
        roles: [1,4,5,6,9],
        ctypeId: [1, 2],
      },
      {
        label: 'reports',
        icon: 'fa fa-file-text',
        path: '/app/campus/reports',
        roles: [1,4,5,6,9],
        ctypeId: [1, 2],
      },
      // {
      //     label: 'Global Talent Pool',
      //     icon: 'icon-globe2',
      //     path: '/app/campus/gtp',
      //     svg:`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>`,
      //     roles: [1,4,5,6,9],
      // },
      // {
      //   label: 'myAnalytics',
      //   icon: 'icon-chart-graph2',
      //   path: '/app/campus/analytics',
      //   svg:`
      //   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-bar-chart-2"><line x1="18" y1="20" x2="18" y2="10"></line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6" y1="20" x2="6" y2="14"></line></svg>`,
      //   roles: [1,4,5,6,9],
      //   ctypeId: [1, 2],
      // },
      {
        label: 'dashboard',
        icon: 'icon-home',
        path: '/app/campus/candidate',
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>`,
        roles: [3],
        ctypeId: [1, 2],
      },
      {
        label: 'browseJobs',
        icon: 'icon-zoom2',
        path: '/app/campus/candidate/browse?filters=%5B%7B%22JobType%22%3A%5B%22Open%20Jobs%22%5D%7D%5D',
        svg: `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>`,
        roles: [3],
        ctypeId: [1, 2],
      },
      {
        label: 'jobMatches',
        icon: 'fa fa-sun-o',
        path: '/app/campus/candidate/job/matches',
        roles: [3],
        ctypeId: [1, 2],
      },
      {
        label: 'recommendations',
        icon: 'icon-write',
        path: '/app/campus/candidate/recommendations',
        svg: `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-sun"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>`,
        roles: [5],
        ctypeId: [1, 2],
      },
      // {
      //   label: 'references',
      //   icon: 'icon-user-comment',
      //   path: '/app/campus/candidate/references',
      //   svg:`
      //   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>`,
      //   roles: [3],
      //   ctypeId: [1, 2],
      // },
    ],
  },
  {
    name: 'admin',
    items: [
      {
        label: 'home',
        icon: 'icon-home',
        path: '/app/campus',
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>`,
        roles: [4],
        ctypeId: [1, 2],
      },
    ],
  },
  {
    name: 'app/profile',
    items: [
      {
        label: 'home',
        icon: 'icon-home',
        path: '/app/campus',
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>`,
        roles: [4],
        ctypeId: [1, 2],
      },
      {
        label: 'home',
        icon: 'icon-home',
        path: '/app/campus/candidate',
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>`,
        roles: [3],
        ctypeId: [1, 2],
      },
    ],
  },
];

export default NavItems;
