import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import { 
  REQUEST_FACETS_DATA,
  REQUEST_FACETS_RESULT,} from './actionTypes';
import { deleteApiCache } from 'utils/helpers';

const getFacets = data => (dispatch) => {
        const value = data && data.value ? data.value : '';
        let url = `${baseUrl}${value}`;
        const params = {
            dispatch,
            method: 'GET',
            url,
            actionType: REQUEST_FACETS_DATA,
        };
        deleteApiCache('xpa-api-jobs');
        return callApi(params);
    };
  
    
    export { getFacets };