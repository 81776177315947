import React, { memo } from 'react';
import { SimpleBarChart } from '@carbon/charts-react';
import './CarbonGraphsStyles.css';
import '@carbon/charts/styles.css';

const BarGraph = (props) => {
  const {
    data, title, isHorizontal, options,
  } = props;
  const optns = {
    title,
    axes: {
      left: {
        primary: true,
      },
      bottom: {
        secondary: true,
      },
    },
    bars: {
      maxWidth: 30,
    },
    height: (options && options.height) || '400px',
  };
  if (isHorizontal === true) {
    optns.axes.left.scaleType = 'labels';
  } else {
    optns.axes.bottom.scaleType = 'labels';
  }
  return (
    <SimpleBarChart data={data} options={optns} />
  );
};

export default memo(BarGraph);
