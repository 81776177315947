import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Button, Loading, RadioButton, InlineNotification,
} from 'carbon-components-react';

import { RecruiterView, CandidateErrorView } from 'components/roboroy/invite/invite';

import {fetchUserLoginState} from 'actions/userstate';
import {
  handleCandidateJobPrivacyConsent,
} from 'actions/candidate/candidateConsent';
import {
  getCandidateProfile,
} from 'actions/talentSearch/candidatesList';
import { getUserInvitation, updateUserApplicationAndMap } from 'actions/invite';
import defaultLogo from 'assets/images/user.svg';

import './invite.css';

class Invite extends React.Component {
  state = {
    company: {},
    job: {},
    profile: {},
    profileId: 0,
    error: false,
    loading: true,
    errMessage: '',
    agreeTalentPool: null,
    agreeRecommendations: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { inviteHash },
      },
    } = this.props;
    // this.props.fetchUserLoginState().then(() => {
      const djob = this.props.getUserInvitation({ appId: inviteHash });
      djob
      .then((res = {}) => {
        // console.log("Res",res);
        if (res && res.error) {
          this.setState({
            error: true,
            loading: false,
            errMessage: (res.error.message) || '',
          });
          return;
        }
        const {jobInfo={},company={},profile:{profileId, teamId}={}} = res;
        this.setState({
          company: res.company,
          job: res.jobInfo,
          profile: res.profile,
          profileId: profileId,
          jobId: jobInfo.jobId,
          teamId: teamId,
          loading: false,
        });
        // Check whetherthis is the correct place for global
        // window.mapUserAndProfile = (data, origin) => {
        //   if(origin !== window.location.href) {
        //     console.log('Popup Origin did not match');
        //     return;
        //   }
        //   const userProfileMapURL = `${window.location.origin}/api/candidate/profileusermap?_=${new Date().getTime()}&profileId=${this.state.profileId}&invite=${inviteHash}&jobId=${this.state.jobId}&teamId=${this.state.teamId}&inviteaccept=true`;
        //   window.location.href = userProfileMapURL;
        // }
      });
      const {
        isUserLoggedIn,
      } = this.props;
      if (!isUserLoggedIn) {
        window.sessionStorage.setItem('xpa-redirect-uri', `/invite/${inviteHash}/accept`);
      }
    // });
  }

  onConfirmInterest = () => {
    const {
      match: {
        params: { inviteHash },
      },
    } = this.props;
    const {
      profileId,
      jobId,
    } = this.state;
    const {
      isUserLoggedIn,
      user: { roleId = 0 } = {},
    } = this.props;
    const currappId = inviteHash.split('x');
    if (isUserLoggedIn && (roleId === 3)) {
      const { agreeRecommendations, agreeTalentPool } = this.state;
      const gdprConsentDate = new Date().toUTCString();
      const agreeStoreApplication = true;
      const data = {
        agreeRecommendations,
        agreeStoreApplication,
        agreeTalentPool,
        gdprConsentDate,
        profileId,
      };
      this.props.updateUserApplicationAndMap({
        invite: inviteHash,
        profileId,
        jobId,
        inviteaccept: true,
      }).then((res = {}) => {
        if (res && res.error) {
          this.setState({
            error: true,
            loading: false,
            errMessage: (res.error.message) || '',
          });
          if (res.error.message === 'You have already acted on the invitation.') {
            window.sessionStorage.removeItem('xpa-redirect-uri');
          }
        } else {
          this.props.handleCandidateJobPrivacyConsent(data);
          window.sessionStorage.removeItem('xpa-redirect-uri');
          this.props.history.push({
            pathname: `/app/campus/job/${jobId}`,
            search: `?appId=${Number(currappId[1])}`
          });
        }
      });
    }
  }

  openPopup = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/app/public/student/login');
    }
  }

  handleChangeRadioButton =(e) => {
    switch (e) {
      case 'recomm-true':
        this.setState({
          agreeRecommendations: true,
          modalError: false,
        });
        break;
      case 'recomm-false':
        this.setState({
          agreeRecommendations: false,
          modalError: false,
        });
        break;
      case 'tal-true':
        this.setState({
          agreeTalentPool: true,
          modalError: false,
        });
        break;
      case 'tal-false':
        this.setState({
          agreeTalentPool: false,
          modalError: false,
        });
        break;
      default:
    }
  }

  handleSubmitJobConsent = () => {
    
  }

  handleCloseNotifButton = () => {
    this.setState({ modalError: false });
  }

  render() {
    const {
      company: {
        companyName = "Default Company",
      } = {},
      loading,
      error,
    } = this.state;
    const {
      job: {
        jobId,
        jobname: {
          nameName: jobName = "Job Name"
        } = {},
        jobfunction: {
          functionName = "Function Name"
        } = {},
        jobseniority: {
          seniorityName = "Experience"
        } = {},
        hourtype: {
          typeName = "Job Type"
        } = {},
        term: {
          termName = "Job Term"
        } = {},
      } = {},
      errMessage,
    } = this.state;

    const { isUserLoggedIn, user: { roleId } = {} } = this.props;

    const candidateView = () => {
      if (error) {
        return (<CandidateErrorView message={errMessage} />);
      } else {
        return (
          <Fragment>
            <div className="ml-loo-wrap d-flex justify-content-center">
              <img src={require('assets/images/republic-polytechnic.png')} alt="" height="auto" width="auto" />
            </div>
            {/* <div className="m-logo-wrap">
              <img src="https://s3.ap-southeast-1.amazonaws.com/x0pa-email-assets/x0pa-logo-300.png" alt="X0PA Ai Logo" className="m-logo" height="60" width="101" />
            </div> */}
            <div className="title-register">Congratulations! You are shortlisted.</div>
            <div className="text-center">
            {
              !isUserLoggedIn && (<div>
                <div className="text-center">
                  Please sign in below to move forward with your candidature.
                </div>
                <div className="box-subscribe mt-2 mb-4">
                  <div className="linkedin-wrap">
                    <Button
                      kind="tertiary"
                      href="/public/student/login"
                    >
                      Sign In
                    </Button>
                    {/* <img src={require('assets/images/linkedin/Sign-In-Large---Default.png')}
                    title="Sign in with LinkedIn" width="210" height="39" /> */}
                  </div>
                </div>
              </div>)
            }

            {
              (isUserLoggedIn && (roleId === 3))
              && (<div>
                {/* <div className="text-left">
                <PrivacyJobConsent 
                    jobName={jobName}
                    companyName={companyName}
                    handleChangeRadioButton={this.handleChangeRadioButton}
                    modalError={this.state.modalError}
                    handleCloseNotifButton={this.handleCloseNotifButton}
                  />
                </div> */}
                <div className="box-subscribe mt-2 mb-4">
                  <Button onClick={this.onConfirmInterest}>
                    Confirm your interest
                  </Button>
                  {/* <Link className="bx--btn bx--btn--primary" to="/app/campus/candidate">
                    
                  </Link> */}
                </div>
                <div className="text-center mb-4">
                  On confirming your interest, you will be redirected to job detail page.
                </div>
              </div>)
            }
            <div className="container">
              <div className="row jc-card">
                <div className="col-sm-12 col-md-6">
                  <div className="">
                    <div className="card-title h5 text-capitalize"><a href={`/app/campus/job/${jobId}`} target="_blank">{jobName}</a></div>
                    {/* <div className="card-subtitle mb-1 text-gray">
                      {functionName}
                    </div>
                    <div className="card-subtitle pt-2">
                      <div className="bx--tag bx--tag--private mr-2">{seniorityName}</div>
                      <div className="bx--tag bx--tag--private mr-2">{typeName}</div>
                      <div className="bx--tag bx--tag--private mr-2">{termName}</div>
                    </div> */}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 invite-company">
                  <div className="avatar-wrap">
                    <img src={defaultLogo} width="60" height="60" style={{borderRadius:"50%"}}
                      className="company-avatar"
                    />
                    <div className="card-title h5 pt-2 mb-1 text-capitalize">{companyName}</div>
                    <div className="card-subtitle text-gray">
                      Recruiting Team
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Fragment>
        )
      }
    }

    return (
      <div className="row" style={{ marginLeft: 0, marginRight: 0, height: '100%' }}>
        <div className="col-md-12 invite-content">
          <div className="body-register">
            <div className="card-invite">
              <div className="card card-content">
                { loading && (
                  <div className="bx--row justify-content-center">
                    <Loading withOverlay={false} />
                  </div>)
                }
                { !loading && isUserLoggedIn && (roleId !== 3) ? <RecruiterView /> : candidateView()  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PrivacyJobConsent = ({
  jobName,
  companyName,
  handleChangeRadioButton,
  modalError,
  handleCloseNotifButton,
}) => (
  <Fragment>
    {modalError === true && (
      <InlineNotification lowContrast
        kind="error"
        className="bx--col"
        title=""
        subtitle="Please make a selection"
        onCloseButtonClick={handleCloseNotifButton}
      />)}
    <div className="text-justify text-dark font-weight-bold">
      As part of our commitment to transparency in how we deal
      with your personal information, we let you decide:
    </div>
    <div className="ml-2 mt-2">
      <RadioButton
        name="recommendations"
        value="recomm-true"
        id="radio-1"
        disabled={false}
        onChange={(e) => { handleChangeRadioButton(e); }}
        labelText={<div>Yes I would like you to consider me for the role of <b className="text-capitalize">{jobName}</b> and for new roles in the future at <b className="text-capitalize">{companyName}</b>.</div>}
      />
      <RadioButton
        name="recommendations"
        value="recomm-false"
        id="radio-2"
        onChange={(e) => { handleChangeRadioButton(e); }}
        disabled={false}
        labelText="No thanks."
      />
    </div>
    <div className="text-justify text-dark font-weight-bold">
      As part of our Talent Network,  we consider your profile on
      ongoing basis to assess what exiciting roles you might be
      suited to. We'll always treat your personal details with
      utmost care in accordance with our privacy policy.
    </div>
    <div className="ml-2 mt-2">
      <RadioButton
        name="talentpool"
        value="tal-true"
        id="radio-3"
        disabled={false}
        onChange={(e) => { handleChangeRadioButton(e); }}
        labelText={<div>Yes, I consent to use my profile as a prospect for <b className="text-capitalize">{companyName}</b> to help assess and match me to vacancies.</div>}
      />
      <RadioButton
        name="talentpool"
        value="tal-false"
        id="radio-4"
        onChange={(e) => { handleChangeRadioButton(e); }}
        disabled={false}
        labelText="No thanks."
      />
    </div>
  </Fragment>
);

const mapStateToProps = state => ({
  loading: state.jobs.loading,
  error: state.jobs.error,
  application: state.invitation,
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
  profile: state.roboroyApplications.profile,
});

const mapDispatchToProps = {
  getUserInvitation,
  updateUserApplicationAndMap,
  fetchUserLoginState,
  getCandidateProfile,
  handleCandidateJobPrivacyConsent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);