import {
  REQUEST_GET_OFFER_DETAILS,
  SUCCESS_GET_OFFER_DETAILS,
  ERROR_GET_OFFER_DETAILS,
  REQUEST_EDIT_OFFER_DETAILS,
  SUCCESS_EDIT_OFFER_DETAILS,
  ERROR_EDIT_OFFER_DETAILS,
  REQUEST_CREATE_OFFER_DETAILS,
  SUCCESS_CREATE_OFFER_DETAILS,
  ERROR_CREATE_OFFER_DETAILS,
  REQUEST_GET_CURRENCY_VALUE,
  SUCCESS_GET_CURRENCY_VALUE,
  ERROR_GET_CURRENCY_VALUE,
  REQUEST_GET_REJECT_REASONS,
  SUCCESS_GET_REJECT_REASONS,
  ERROR_GET_REJECT_REASONS,
  REQUEST_GET_CANDIDATES_IN_OFFER_LIST,
  SUCCESS_GET_CANDIDATES_IN_OFFER_LIST,
  ERROR_GET_CANDIDATES_IN_OFFER_LIST,
  REQUEST_CREATE_OFFER_DOCUMENTS,
  SUCCESS_CREATE_OFFER_DOCUMENTS,
  ERROR_CREATE_OFFER_DOCUMENTS,
  REQUEST_GET_OFFER_DOCUMENTS,
  SUCCESS_GET_OFFER_DOCUMENTS,
  ERROR_GET_OFFER_DOCUMENTS,
  REQUEST_DELETE_OFFER_DOCUMENTS,
  SUCCESS_DELETE_OFFER_DOCUMENTS,
  ERROR_DELETE_OFFER_DOCUMENTS,
  REQUEST_JOB_OFFER_DETAILS,
  SUCCESS_JOB_OFFER_DETAILS,
  ERROR_JOB_OFFER_DETAILS
} from 'actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  offerDetails: [],
  timeInterval: [],
  application: {},
  currencies: [],
  rejectReason: [],
  summary: {},
  canSendOffer: true
};

const OfferInformation = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_GET_CANDIDATES_IN_OFFER_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATES_IN_OFFER_LIST:
      return {
        ...state,
        offers: payload && payload.offers,
        count: payload && payload.count,
        summary: payload && payload.summary,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATES_IN_OFFER_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_OFFER_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_OFFER_DETAILS:
      return {
        ...state,
        offerDetails: payload && payload.offers,
        timeInterval: payload && payload.timeInterval,
        application: payload && payload.application,
        loading: false,
        error: null,
      };
    case ERROR_GET_OFFER_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_EDIT_OFFER_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_EDIT_OFFER_DETAILS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_EDIT_OFFER_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_OFFER_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_OFFER_DETAILS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_OFFER_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CURRENCY_VALUE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CURRENCY_VALUE:
      return {
        ...state,
        currencies: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_CURRENCY_VALUE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_REJECT_REASONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_REJECT_REASONS:
      return {
        ...state,
        rejectReason: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_REJECT_REASONS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_OFFER_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_JOB_OFFER_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_JOB_OFFER_DETAILS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_JOB_OFFER_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };  
    default:
      return state;
  }
};

export default OfferInformation;
