import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_GET_COMPANY_INFO,
  REQUEST_UPDATE_COMPANY_INFO,
} from '../actionTypes';

const getCompanyInfo = () => (dispatch) => {
  const url = `${baseUrl}/company-info/get?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_COMPANY_INFO,
  };
  return callApi(params);
};

const updateCompanyInfo = data => (dispatch) => {
  const url = `${baseUrl}/company-info/update?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_COMPANY_INFO,
  };
  return callApi(params);
};

export {
  getCompanyInfo,
  updateCompanyInfo,
};
