import {
  REQUEST_GET_STUDENT_INTERVIEW_ASSESS,
  SUCCESS_GET_STUDENT_INTERVIEW_ASSESS,
  ERROR_GET_STUDENT_INTERVIEW_ASSESS,
  REQUEST_ADD_CAND_TO_ASSESS,
  SUCCESS_ADD_CAND_TO_ASSESS,
  ERROR_ADD_CAND_TO_ASSESS,
  REQUEST_SEND_ASSESS_INV_EMAIL,
  SUCCESS_SEND_ASSESS_INV_EMAIL,
  ERROR_SEND_ASSESS_INV_EMAIL,
} from 'actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  assessments: [],
};

const StudentAssessments = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_STUDENT_INTERVIEW_ASSESS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STUDENT_INTERVIEW_ASSESS:
      return {
        assessments: payload && payload.assessments,
        loading: false,
        error: null,
      };
    case ERROR_GET_STUDENT_INTERVIEW_ASSESS:
      return {
        loading: false,
        error: payload.error,
      };
    case REQUEST_ADD_CAND_TO_ASSESS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ADD_CAND_TO_ASSESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ADD_CAND_TO_ASSESS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SEND_ASSESS_INV_EMAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SEND_ASSESS_INV_EMAIL:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SEND_ASSESS_INV_EMAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default StudentAssessments;
