import { withTranslation } from 'react-i18next';
import React from 'react';
import {
  Checkbox,
  Accordion, AccordionItem,
} from 'carbon-components-react';
import FilterTitle from './filterTitle';

const FilterApplications = ({
  applTitles,
  handleChangeCheckboxValues,
  state,
  isOpenAccr,
  handleOpenAcc,
  handleSelectAllValues,
  handleClearAllValues,
  t,
}) => {
  return (
    <Accordion>
      {
        applTitles && Array.isArray(applTitles)
        && applTitles.length > 0
        && applTitles.map((res) => {
          const { values } = res || {};
          const {
            applicationStatus,
            applicantType,
            source,
            workflowstages,
            workflowSubStageStatus,
            offerStatus,
            hiringStatus,
          } = values || {};
          return (
            <div>
              {
                res && (
                  <AccordionItem
                    title={res && (
                      <FilterTitle
                        titleName={res && res.titleName}
                        titleId={res && res.titleId}
                        currStatus={res && res.filterKey}
                        state={state}
                      />
                    )}
                    open={(isOpenAccr === (res && res.status))}
                    onClick={() => handleOpenAcc(res.status)}
                  >
                    <div className="d-flex">
                      <div onClick={() => handleSelectAllValues(values, res && res.objKey, res && res.filterKey)}><a href='#'>{t('selectAll')} |</a></div>
                      <div><a href='#' onClick={() => handleClearAllValues(res && res.status, res && res.filterKey)}>&nbsp;{t('clear')}</a></div>
                    </div>
                    <div>
                      {
                        res.titleId === 1 && (
                          <div className="bx--row">
                            {
                              applicationStatus && Array.isArray(applicationStatus)
                                && applicationStatus.length > 0
                                && applicationStatus.map(applStatus => (
                                  <div className="bx--col-lg-6">
                                    <Checkbox
                                      checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(applStatus.statusId.toString() || '')) : false}
                                      labelText={applStatus && applStatus.statusName}
                                      id={`statusName_${applStatus.statusId}`}
                                      name={`statusName_${applStatus.statusId}`}
                                      onChange={(e) => { handleChangeCheckboxValues(e, applStatus.statusId.toString(), res.status, res.filterKey); }}
                                    />
                                  </div>
                                ))
                            }
                          </div>
                        )
                      }
                      {
                        res.titleId === 2 && (
                          <div className="bx--row">
                            {
                              applicantType && Array.isArray(applicantType)
                                && applicantType.length > 0
                                && applicantType.map(type => (
                                  <div className="bx--col-lg-6">
                                    <Checkbox
                                      checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(type.applicantTypeId.toString() || '')) : false}
                                      labelText={type && type.applicantTypeName}
                                      id={`applicantTypeName_${type.applicantTypeId}`}
                                      name={`applicantTypeName_${type.applicantTypeId}`}
                                      onChange={(e) => { handleChangeCheckboxValues(e, type.applicantTypeId.toString(), res.status, res.filterKey); }}
                                    />
                                  </div>
                                ))
                            }
                          </div>
                        )
                      }
                      {
                        res.titleId === 3 && (
                          <div className="bx--row">
                            {
                              source && Array.isArray(source)
                                && source.length > 0
                                && source.map(data => (
                                  <div className="bx--col-lg-6">
                                    <Checkbox
                                      checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(data.sourceId.toString() || '')) : false}
                                      labelText={data && data.sourceName}
                                      id={`applicantTypeName_${data.sourceId}`}
                                      name={`applicantTypeName_${data.sourceId}`}
                                      onChange={(e) => { handleChangeCheckboxValues(e, data.sourceId.toString(), res.status, res.filterKey); }}
                                    />
                                  </div>
                                ))
                            }
                          </div>
                        )
                      }
                      {
                        res.titleId === 4 && (
                          <div className="bx--row">
                            {
                              workflowstages && Array.isArray(workflowstages)
                                && workflowstages.length > 0
                                && workflowstages.map(stage => (
                                  <div className="bx--col-lg-6">
                                    <Checkbox
                                      checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(stage.stageTypeName.toString() || '')) : false}
                                      labelText={stage && stage.stageTypeName}
                                      id={`stageTypeName_${stage.stageTypeId}`}
                                      name={`stageTypeName_${stage.stageTypeId}`}
                                      onChange={(e) => { handleChangeCheckboxValues(e, stage.stageTypeName.toString(), res.status, res.filterKey); }}
                                    />
                                  </div>
                                ))
                            }
                          </div>
                        )
                      }
                      {
                        res.titleId === 5 && (
                          <div className="bx--row">
                            {
                              workflowSubStageStatus && Array.isArray(workflowSubStageStatus)
                                && workflowSubStageStatus.length > 0
                                && workflowSubStageStatus.map(subStage => (
                                  <div className="bx--col-lg-6">
                                    <Checkbox
                                      checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(subStage.subStageId.toString() || '')) : false}
                                      labelText={subStage && subStage.subStageName}
                                      id={`subStageName_${subStage.subStageId}`}
                                      name={`subStageName_${subStage.subStageId}`}
                                      onChange={(e) => { handleChangeCheckboxValues(e, subStage.subStageId.toString(), res.status, res.filterKey); }}
                                    />
                                  </div>
                                ))
                            }
                          </div>
                        )
                      }
                      {
                        res.titleId === 6 && (
                          <div className="bx--row">
                            {
                              offerStatus && Array.isArray(offerStatus)
                                && offerStatus.length > 0
                                && offerStatus.map(offer => (
                                  <div className="bx--col-lg-6">
                                    <Checkbox
                                      checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(offer.offerId.toString() || '')) : false}
                                      labelText={offer && offer.offerName}
                                      id={`offerName_${offer.offerId}`}
                                      name={`offerName_${offer.offerId}`}
                                      onChange={(e) => { handleChangeCheckboxValues(e, offer.offerId.toString(), res.status, res.filterKey); }}
                                    />
                                  </div>
                                ))
                            }
                          </div>
                        )
                      }
                      {
                        res.titleId === 7 && (
                          <div className="bx--row">
                            {
                              hiringStatus && Array.isArray(hiringStatus)
                                && hiringStatus.length > 0
                                && hiringStatus.map(hire => (
                                  <div className="bx--col-lg-6">
                                    <Checkbox
                                      checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(hire.hiringId.toString() || '')) : false}
                                      labelText={hire && hire.hiringName}
                                      id={`hiringName_${hire.hiringId}`}
                                      name={`hiringName_${hire.hiringId}`}
                                      onChange={(e) => { handleChangeCheckboxValues(e, hire.hiringId.toString(), res.status, res.filterKey); }}
                                    />
                                  </div>
                                ))
                            }
                          </div>
                        )
                      }
                    </div>
                  </AccordionItem>
                )
              }
            </div>
          );
        })
      }
    </Accordion>
  );
};

export default (withTranslation()(FilterApplications));
