import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Avatar from 'components/common/Avatar';
import {
  Switcher,
  SwitcherItem,
} from 'carbon-components-react/lib/components/UIShell';
import { Loading, Search } from 'carbon-components-react';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import { fetchAllCompaniesList } from 'actions/BrowseCompanies';
import { fetchAllInstitutionsList } from 'actions/BrowseInstitutions';
import {
  switchCompany,
} from '../../actions/admin/CompanyAndOffice';
import './switchCompany.css';

const CompInstComponent = (props) => {
  const { data, selectCompany } = props || {};
  return (
    data &&
    Array.isArray(data) &&
    data.map((res) => {
      const {
        _source: { companyName, companyId, companyinfo: { logo } = {} } = {}
      } = res || {};
      return (
        <SwitcherItem className="mt-2 mb-2">
          <div className="d-flex align-items-center">
            <div className="xpa-avatar-width">
              <Avatar
                user={{
                  firstname: companyName,
                  lastname: '',
                  picture: logo,
                }}
                classNameInfo="rounded-circle"
                size="30"
                isCompanyLogo
              />
            </div>
            <div onClick={() => selectCompany(companyId)} className="text-capitalize ml-2">{companyName}</div>
          </div>
        </SwitcherItem>
      );
    })
  );
};

class SwitchCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instKeyword: '',
      InstActivePage: 1,
      InstItemsPerPage: 1000,
      compKeyword: '',
      compActivePage: 1,
      compItemsPerPage: 1000,
      selectedTab: 0,
    };
  }

  componentDidMount() {
    this.setState(
      {
        instKeyword: '',
        InstActivePage: 1,
        InstItemsPerPage: 1000,
        compKeyword: '',
        compActivePage: 1,
        compItemsPerPage: 1000,
      },
      () => {
        this.handleInstApiCall();
        this.handleCompApiCall();
      },
    );
  }

  handleInstApiCall = () => {
    const { instKeyword, InstActivePage, InstItemsPerPage } = this.state;
    const data = {
      keyword: instKeyword,
      activePage: InstActivePage,
      itemsPerPage: InstItemsPerPage,
      actionName: 'REQUEST_BROWSE_INSTITUTIONS_LIST',
    };
    this.props.fetchAllInstitutionsList(data);
  };

  handleCompApiCall = () => {
    const { compKeyword, compActivePage, compItemsPerPage } = this.state;
    const data = {
      keyword: compKeyword,
      activePage: compActivePage,
      itemsPerPage: compItemsPerPage,
      actionName: 'REQUEST_BROWSE_COMPANIES_LIST',
    };
    this.props.fetchAllCompaniesList(data);
  };

  handleChangeTabClick = (val) => {
    let selectedTab = 0;
    switch (val) {
      case 'company':
        selectedTab = 0;
        break;
      case 'institution':
        selectedTab = 1;
        break;
      default:
        break;
    }
    this.setState({
      selectedTab,
    });
  };

  selectCompany = (companyId) => {
    this.props.switchCompany(companyId).then(()=>window.location.reload());
  }

  searchTimer = null;

  handleInstSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState(
      { instKeyword: event.target.value, InstActivePage: 1 },
      () => {
        this.searchTimer = setTimeout(
          () =>
            this.props.fetchAllInstitutionsList({
              itemsPerPage: 1000,
              activePage: 1,
              keyword: this.state.instKeyword,
              actionName: 'REQUEST_BROWSE_INSTITUTIONS_LIST'
            }),
          1000,
        );
      }
    );
  };

  handleCompSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState(
      { compKeyword: event.target.value, compActivePage: 1 },
      () => {
        this.searchTimer = setTimeout(
          () =>
            this.props.fetchAllCompaniesList({
              itemsPerPage: 1000,
              activePage: 1,
              keyword: this.state.compKeyword,
              actionName: 'REQUEST_BROWSE_COMPANIES_LIST'
            }),
          1000,
        );
      },
    );
  };

  render() {
    const {
      companies,
      t,
      institutions,
      instLoading,
      compLoading,
      user: {
        isAdmin,
        isSuperAdmin,
        companyId: userCompId,
        company: { companyName: userCompName } = {}
      } = {},
    } = this.props;
    const {
      selectedTab,
      compKeyword,
      instKeyword,
    } = this.state;
    let instData = [];
    if (isAdmin === true && isSuperAdmin !== true) {
      instData = [
        {
          _source: {
            companyName: userCompName,
            companyId: userCompId,
          },
        },
      ];
    } else {
      instData = institutions;
    }
    return (
      <Fragment>
        <GenericTabs
          selected={selectedTab}
          className="tab-color"
          tabContent={[
            {
              label: `${t('companies')} ${t('list')}`,
              value: '',
              onClick: () => {
                this.handleChangeTabClick('company');
              },
            },
            {
              label: `${t('institution')} ${t('list')}`,
              value: '',
              onClick: () => {
                this.handleChangeTabClick('institution');
              },
            },
          ]}
        />
        {selectedTab === 0 && instLoading !== true && compLoading !== true && (
          <div>
            <div className="mt-2 mb-2">
              <Search
                light
                type="text"
                labelText={`${t('search')} ${t('company')}`}
                closeButtonLabelText=""
                placeHolderText={`${t('search')} ${t('company')}`}
                id="search company"
                value={compKeyword}
                onChange={this.handleCompSearchSubmit}
              />
            </div>
            {
              companies && Array.isArray(companies)
                && companies.length > 0
                && (
                  <Switcher>
                    <CompInstComponent selectCompany={this.selectCompany} data={companies} />
                  </Switcher>
                )
            }
          </div>
        )}
        {selectedTab === 1 && instLoading !== true && compLoading !== true && (
          <div>
            <div>
              <Search
                light
                type="text"
                labelText={`${t('search')} ${t('institution')}`}
                closeButtonLabelText=""
                placeHolderText={`${t('search')} ${t('institution')}`}
                id="search institution"
                value={instKeyword}
                onChange={this.handleInstSearchSubmit}
              />
            </div>
            {instData &&
              Array.isArray(instData) &&
              instData.length > 0 &&
              (
                <Switcher className="expanded-item">
                  <CompInstComponent selectCompany={this.selectCompany} data={instData} />
                </Switcher>
              )
            }
          </div>
        )}
        {
          (instLoading === true || compLoading === true) && (
            <div className="bx--row justify-content-around align-items-center loader-height">
              <Loading withOverlay={false} />
            </div>
          )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  institutions: state.BrowseInstitutions.switchInstitutionsList,
  instCount: state.BrowseInstitutions.switchInstCount,
  companies: state.BrowseCompanies.switchCompaniesList,
  compCount: state.BrowseCompanies.switchCompaniesCount,
  instLoading: state.BrowseInstitutions.loading,
  compLoading: state.BrowseCompanies.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchAllInstitutionsList,
    fetchAllCompaniesList,
    switchCompany,
  })(withTranslation()(SwitchCompany)),
);
