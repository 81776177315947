import {
  REQUEST_CREATE_COMPANY_USER,
  SUCCESS_CREATE_COMPANY_USER,
  ERROR_CREATE_COMPANY_USER,
  REQUEST_CREATE_INSTITUTION_USER,
  SUCCESS_CREATE_INSTITUTION_USER,
  ERROR_CREATE_INSTITUTION_USER,
  REQUEST_COMPANY_SIZES,
  SUCCESS_COMPANY_SIZES,
  ERROR_COMPANY_SIZES,
  REQUEST_COMPANY_UEN_VALIDATION,
  SUCCESS_COMPANY_UEN_VALIDATION,
  ERROR_COMPANY_UEN_VALIDATION,
  REQUEST_COMPANY_TYPES,
  ERROR_COMPANY_TYPES,
  SUCCESS_COMPANY_TYPES,
  REQUEST_GET_SALUATION_LIST,
  SUCCESS_GET_SALUATION_LIST,
  ERROR_GET_SALUATION_LIST,
  REQUEST_GET_COMPANY_INDUSTRIES,
  SUCCESS_GET_COMPANY_INDUSTRIES,
  ERROR_GET_COMPANY_INDUSTRIES,
} from 'actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  companySize: [],
  companyTypes: [],
  saluationList: [],
  industriesList: [],
};

const CompanyRegistration = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_CREATE_COMPANY_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_COMPANY_USER:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_COMPANY_USER:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_INSTITUTION_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_INSTITUTION_USER:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_INSTITUTION_USER:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_COMPANY_SIZES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_COMPANY_SIZES:
      return {
        ...state,
        companySize: payload,
        loading: false,
        error: null,
      };
    case ERROR_COMPANY_SIZES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_COMPANY_UEN_VALIDATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_COMPANY_UEN_VALIDATION:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_COMPANY_UEN_VALIDATION:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_COMPANY_TYPES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_COMPANY_TYPES:
      return {
        ...state,
        companyTypes: payload,
        loading: false,
        error: null,
      };
    case ERROR_COMPANY_TYPES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_SALUATION_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_SALUATION_LIST:
      return {
        ...state,
        saluationList: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_SALUATION_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_COMPANY_INDUSTRIES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUCCESS_GET_COMPANY_INDUSTRIES:
      return {
        ...state,
        industriesList: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_COMPANY_INDUSTRIES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default CompanyRegistration;
