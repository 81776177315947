import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Modal,
  InlineNotification,
  FormGroup,
  RadioButtonGroup,
  RadioButton,
  Select,
  SelectItem,
  SelectItemGroup,
} from 'carbon-components-react';
import moment from 'moment';

import emailTemplatesList from 'utils/email-templates';

const getModalHeading = actionType => {
  let value = '';
  switch (actionType) {
    case 'recruiter-sends-consent':
      value = 'Send Consent to applicant';
      break;
    case 'recruiter-reject-consent':
      value = 'Reject Consent';
      break;
    case 'shortlist-email':
      value = 'Send Email to Applicant';
      break;
    case 'reject-email':
      value = 'Send Rejection Email to Applicant';
      break;
    case 'arya-invite-not-sent':
      value = 'Send Introduction Email to Applicant';
      break;
    case 'arya-invite-sent':
      value = 'Send Follow-up Email to Applicant';
      break;
    case 'shortlisted-invite-not-sent':
      value = 'Send Shortlisted Email to Applicant';
      break;
    case 'shortlisted-invite-sent':
      value = 'Resend Email to Applicant';
      break;
    case 'reference-request':
      value = 'Request References From Applicant';
      break;
    case 'recruiter-sends-offer-details':
      value = 'Send Offer Details';
      break;
    default:
      value = 'Send Email to Applicant';
  }
  return value;
};

const EmailModal = ({
  actionType,
  onRequestClose,
  open,
  onViewEmail,
  isLoading,
  application,
  onRequestSubmit,
  onEditEmailChange,
  editEmailBeforeSend,
  notifObj,
  onEmailModalTemplateChange,
  emailTemplate,
}) => {
  const { applicationemail } = application || {};
  let lastSentEmail = false;
  if (applicationemail && Array.isArray(applicationemail)) {
    const paEmailsArr = applicationemail.filter(
      e =>
        e.templateName === 'passive-followup-email' ||
        e.templateName === 'passive-introduction-email'
    );
    lastSentEmail =
      paEmailsArr &&
      Array.isArray(paEmailsArr) &&
      paEmailsArr.reverse()[0] &&
      paEmailsArr.reverse()[0].createdAt;
  }
  return (
    <Modal
      onRequestSubmit={onRequestSubmit}
      onRequestClose={onRequestClose}
      open={open}
      modalLabel="Email Templates"
      primaryButtonText="Submit"
      secondaryButtonText="Cancel"
      modalHeading={getModalHeading(actionType)}>
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '45px' }}>
          <div
            data-loading
            className="bx--loading bx--loading--small  d-inline-block">
            <svg className="bx--loading__svg" viewBox="-75 -75 150 150">
              <circle cx="0" cy="0" r="37.5" />
            </svg>
          </div>
          <div className="text-dark mt-1">Sending Email...</div>
        </div>
      )}
      {!isLoading && (
        <Fragment>
          {notifObj && notifObj.notifShow && (
            <InlineNotification
              lowContrast
              kind={notifObj.notifType || 'info'}
              title={notifObj.notifMsg || 'Default notification message'}
              subtitle=""
            />
          )}
          <div className="bx--modal-content__text">
            <Select
              labelText="Select Email Template"
              className="mb-4"
              id="email-templates-list"
              onChange={onEmailModalTemplateChange}
              value={emailTemplate || 'custom-email'}
              defaultValue={emailTemplate || 'custom-email'}>
              {emailTemplatesList &&
                Object.keys(emailTemplatesList).map(etg => {
                  const groupItem = emailTemplatesList[etg] || {};
                  const { name, group, templates } = groupItem;
                  return (
                    <SelectItemGroup key={group} label={name}>
                      {templates &&
                        Object.keys(templates).map(et => {
                          const emailItem = templates[et] || {};
                          const { name: emailItemName } = emailItem;
                          return (
                            <SelectItem
                              value={et}
                              text={emailItemName}
                              key={et}
                            />
                          );
                        })}
                    </SelectItemGroup>
                  );
                })}
            </Select>
            {actionType && emailTemplate && emailTemplate !== 'custom-email' && (
              <Fragment>
                {actionType === 'arya-invite-sent' && (
                  <span className="pt-0 pb-1 text-dark d-block">
                    You have already sent an email on{' '}
                    {moment(lastSentEmail).format('Do MMM YYYY')}. Click on{' '}
                    <strong>Submit</strong> to send a follow-up email. (
                    <span className="bx--link pointer" onClick={onViewEmail}>
                      preview email
                    </span>
                    )
                  </span>
                )}
                {actionType !== 'arya-invite-sent' && (
                  <span className="pt-0 pb-1 text-dark d-block">
                    Clicking on <strong>submit</strong> will send an email to
                    the applicant.&nbsp;
                    <span className="bx--link pointer" onClick={onViewEmail}>
                      Click here
                    </span>{' '}
                    to view content of the email.
                  </span>
                )}
                <FormGroup
                  className="mb-0 d-none"
                  legendText="Do you want to edit email content before sending?">
                  <RadioButtonGroup
                    legend="Edit email before send"
                    name="edit-email-group"
                    valueSelected={editEmailBeforeSend}
                    onChange={onEditEmailChange}>
                    <RadioButton
                      value="edit-email-yes"
                      id="edit-email-yes"
                      labelText="Yes"
                    />
                    <RadioButton
                      value="edit-email-no"
                      id="edit-email-no"
                      labelText="No"
                    />
                  </RadioButtonGroup>
                </FormGroup>
                <span className="text-dark bx--type-legal d-none">
                  (Selecting
                  <strong> YES</strong>, will close this popup and redirect to a
                  new page where you can edit content and send email)
                </span>
              </Fragment>
            )}
            {actionType && emailTemplate && emailTemplate === 'custom-email' && (
              <span>
                Clicking on <b>submit</b> will close this popup and redirect to
                a new page where you can add content and send email.
              </span>
            )}
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

export default EmailModal;
