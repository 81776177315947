import {
  REQUEST_USER_INVITATION,
  SUCCESS_USER_INVITATION,
  ERROR_USER_INVITATION,
  REQUEST_OFFER_CONSENT_STATUS,
  SUCCESS_OFFER_CONSENT_STATUS,
  ERROR_OFFER_CONSENT_STATUS,
  REQUEST_GET_OFFER_CONSENT,
  SUCCESS_GET_OFFER_CONSENT,
  ERROR_GET_OFFER_CONSENT,
} from 'actions/invite/actionTypes';

const initState = {
  loading: true,
  error: null,
  invitation: {},
};

const invitation = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_USER_INVITATION:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case SUCCESS_USER_INVITATION:
      return {
        ...state,
        error: null,
        loading: false,
        invitation: payload,
      };
    case ERROR_USER_INVITATION:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case REQUEST_GET_OFFER_CONSENT:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_GET_OFFER_CONSENT:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case ERROR_GET_OFFER_CONSENT:
      return {
        ...state,
        error: payload && payload.error,
        loading: false,
      };
    case REQUEST_OFFER_CONSENT_STATUS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_OFFER_CONSENT_STATUS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case ERROR_OFFER_CONSENT_STATUS:
      return {
        ...state,
        error: payload && payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default invitation;
