import {
  REQUEST_GET_COMPANIES_LIST,
  SUCCESS_GET_COMPANIES_LIST,
  ERROR_GET_COMPANIES_LIST,
  REQUEST_GET_COMPANIES_FACETS,
  ERROR_GET_COMPANIES_FACETS,
  SUCCESS_GET_COMPANIES_FACETS,
  REQUEST_BROWSE_COMPANIES_LIST,
  ERROR_BROWSE_COMPANIES_LIST,
  SUCCESS_BROWSE_COMPANIES_LIST,
} from 'actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  companies: [],
  switchCompaniesList: [],
  switchCompaniesCount: 0,
  locations: [],
  count: 0,
  facets: {},
};

const BrowseCompanies = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_COMPANIES_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_COMPANIES_LIST:
      return {
        ...state,
        companies: payload && payload.companies,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_GET_COMPANIES_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_BROWSE_COMPANIES_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_BROWSE_COMPANIES_LIST:
      return {
        ...state,
        switchCompaniesList: payload && payload.companies,
        switchCompaniesCount: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_BROWSE_COMPANIES_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_COMPANIES_FACETS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_COMPANIES_FACETS:
      return {
        ...state,
        facets: payload,
        locations: payload && payload.Locations,
        loading: false,
        error: null,
      };
    case ERROR_GET_COMPANIES_FACETS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default BrowseCompanies;
