import { RECEIVE_CANDIDATE_DATA } from '../actions/actionTypes';

const candidateData = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_CANDIDATE_DATA:
      return action.data;
    default:
      return state;
  }
};

export default candidateData;
