import {
  REQUEST_CANDASHBOARD_DATA,
  SUCCESS_CANDASHBOARD_DATA,
  ERROR_CANDASHBOARD_DATA,
  REQUEST_CANDIDATE_RECOMMENDED_JOBS,
  SUCCESS_CANDIDATE_RECOMMENDED_JOBS,
  ERROR_CANDIDATE_RECOMMENDED_JOBS,
} from '../../actions/actionTypes';

const initState = {
  recJobsLoading: true,
  loading: true,
  error: null,
  dashboard: {},
  recommendedJobs: [],
};

const candidateDashboard = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_CANDASHBOARD_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CANDASHBOARD_DATA:
      return {
        ...state,
        dashboard: payload,
        loading: false,
        error: null,
      };
    case ERROR_CANDASHBOARD_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CANDIDATE_RECOMMENDED_JOBS:
      return {
        ...state,
        recJobsLoading: true,
        error: null,
      };
    case SUCCESS_CANDIDATE_RECOMMENDED_JOBS:
      return {
        ...state,
        recommendedJobs: payload,
        recJobsLoading: false,
        error: null,
      };
    case ERROR_CANDIDATE_RECOMMENDED_JOBS:
      return {
        ...state,
        recJobsLoading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default candidateDashboard;
