import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_REFERENCE_INVITES,
  REQUEST_ADD_REFERENCES,
  REQUEST_GET_REFERENCES,
  REQUEST_ADD_REFERENCE_FEEDBACK,
  REQUEST_GET_REFERENCE,
  REQUEST_USER_REFERENCES,
  REQUEST_GET_FEEDBACKGIVEN,
} from '../actionTypes';

const getReferenceInvites = (data = {}) => (dispatch) => {
  const {
    itemsPerPage = 10,
    filterOptions = '',
    activePage = 1,
    status="given",
  } = data;
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = "";
  if(status === 'given')
    url = `${baseUrl}/references?_=${new Date().getTime()}&status=reference-given`;//&id=0&filter[limit]=${itemsPerPage}&filter[skip]=${skip}&`.concat(filterOptions);
  else
    url = `${baseUrl}/references?_=${new Date().getTime()}&status=reference-received&id=0&filter[limit]=${itemsPerPage}&filter[skip]=${skip}&`.concat(filterOptions);

  const params = {
    dispatch,
    actionType: REQUEST_GET_REFERENCES,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getReferenceInviteConfigs = (referenceId = 0) => (dispatch) => {
  if (!referenceId) {
    return Promise.reject({
      error: true,
      message: 'Invalid reference Id',
    });
  }
  const url = `${baseUrl}/references/${referenceId}?_=${new Date().getTime()}&referenceId=${referenceId}`;
  const params = {
    dispatch,
    actionType: REQUEST_REFERENCE_INVITES,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const addReferenceInvites = (data = []) => (dispatch) => {
  if (data && (data.length < 1)) {
    return Promise.reject({
      error: true,
      message: 'Invalid references data',
    });
  }
  const url = `${baseUrl}/references/invites?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    actionType: REQUEST_ADD_REFERENCES,
    url,
    body: JSON.stringify(data),
    method: 'POST',
  };

  return callApi(params);
};

const addReferenceFeedback = (data = []) => (dispatch) => {
  if (data && (data.length < 1)) {
    return Promise.reject({
      error: true,
      message: 'Invalid reference feedback data',
    });
  }
  const url = `${baseUrl}/references?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    actionType: REQUEST_ADD_REFERENCE_FEEDBACK,
    url,
    body: JSON.stringify(data),
    method: 'POST',
  };

  return callApi(params);
};

const validateReference = refHash => (dispatch) => {
  if (!refHash) {
    return Promise.reject({
      error: true,
      message: 'Invalid reference hash',
    });
  }
  const url = `${baseUrl}/references/invites/${refHash}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_REFERENCE,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getFeedbackGiven = id => (dispatch) => {
  if(id && id !== null){
    const url = `${baseUrl}/references?_=${new Date().getTime()}&givenRefId=${id}`;
    const params = {
      dispatch,
      actionType: REQUEST_GET_FEEDBACKGIVEN,
      url,
      method: 'GET',
    };
    return callApi(params);
  }
  return;
}

const getApplicantReferences = teamId => (dispatch) => {
  const url = `${baseUrl}/references?_=${new Date().getTime()}&teamId=${teamId}`;
  const params = {
    actionType: REQUEST_USER_REFERENCES,
    dispatch,
    url,
    method: 'GET',
  };
  return callApi(params);
};

export {
  getReferenceInvites,
  getReferenceInviteConfigs,
  addReferenceInvites,
  addReferenceFeedback,
  validateReference,
  getApplicantReferences,
  getFeedbackGiven,
};
