import {
  REQUEST_TALENTPOOL,
  ERROR_TALENTPOOL,
  SUCCESS_TALENTPOOL,
  REQUEST_BUCKETS,
  SUCCESS_BUCKETS,
  ERROR_BUCKETS,
  REQUEST_GET_BUCKETS,
  SUCCESS_GET_BUCKETS,
  ERROR_GET_BUCKETS,
  REQUEST_EDIT_BUCKETS,
  SUCCESS_EDIT_BUCKETS,
  ERROR_EDIT_BUCKETS,
  REQUEST_DEL_BUCKETS,
  SUCCESS_DEL_BUCKETS,
  ERROR_DEL_BUCKETS,
  REQUEST_ADD_CANDIDATES_BUCKETS,
  SUCCESS_ADD_CANDIDATES_BUCKETS,
  ERROR_ADD_CANDIDATES_BUCKETS,
  REQUEST_SEND_CUSTOMEMAIL,
  SUCCESS_SEND_CUSTOMEMAIL,
  REQUEST_SEND_EMAIL_TO_COMPANIES,
  SUCCESS_SEND_EMAIL_TO_COMPANIES
} from '../../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  profiles: {
    count: 0,
    jobs: [],
  },
  buckets: [],
  count: 0,
};

const roboroyTalentPool = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_TALENTPOOL:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_TALENTPOOL:
      return {
        ...state,
        profiles: [],
        loading: false,
        error: payload.error,
      };
    case SUCCESS_TALENTPOOL:
      return {
        ...state,
        profiles: payload,
        loading: false,
        error: null,
      };
    case REQUEST_BUCKETS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_BUCKETS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case ERROR_BUCKETS:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case REQUEST_GET_BUCKETS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_GET_BUCKETS:
      return {
        ...state,
        buckets: payload && payload.buckets,
        count: payload && payload.count,
        error: null,
        loading: false,
      };
    case ERROR_GET_BUCKETS:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case REQUEST_EDIT_BUCKETS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case SUCCESS_EDIT_BUCKETS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_EDIT_BUCKETS:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case REQUEST_DEL_BUCKETS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case SUCCESS_DEL_BUCKETS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case ERROR_DEL_BUCKETS:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case REQUEST_ADD_CANDIDATES_BUCKETS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_ADD_CANDIDATES_BUCKETS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case ERROR_ADD_CANDIDATES_BUCKETS:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case REQUEST_SEND_CUSTOMEMAIL:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SUCCESS_SEND_CUSTOMEMAIL:
      return {
        ...state,
        error: false,
        loading: false,
      };
      case REQUEST_SEND_EMAIL_TO_COMPANIES:
        return {
          ...state,
          error: false,
          loading: true,
        };
      case SUCCESS_SEND_EMAIL_TO_COMPANIES:
        return {
          ...state,
          error: false,
          loading: false,
        };
    
    default:
      return state;
  }
};

export default roboroyTalentPool;
