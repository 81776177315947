import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Search, Checkbox, Button,
  Pagination,
} from 'carbon-components-react';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  fetchAllInstitutionsList,
  fetchAllInstitutionsFacets,
} from 'actions/BrowseInstitutions';
import FacetsBrowse from 'components/roboroy/common/FacetsBrowse';
import queryString from 'query-string';
import BrowseCompInst from 'components/roboroy/common/BrowseCompInst';

const NoInstitutions = ({
  t,
}) => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">{t('noInstitutionsMsg')}</p>
    </div>
  </Tile>
);

class BrowseInstitutions extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'institution', name: 'Browse Institutions', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      activePage: 1,
      itemsPerPage: 10,
      Locations: [],
      isOpenMoreModal: false,
      mainVals: null,
    };
  }

  componentDidMount() {
    const {
      location: {
        search,
      } = {},
    } = this.props || {};
    if (search) {
      const finalData = {};
      let filterArr = [];
      const parsedQuery = queryString.parse(search);
      if (parsedQuery && parsedQuery.locations) {
        this.setState({
          [`${parsedQuery.locations}`]: parsedQuery.locations,
        }, () => {
          if (filterArr.length <= 0) {
            filterArr = [this.state[`${parsedQuery.locations}`]]
          } else {
            filterArr.push(this.state[`${parsedQuery.locations}`])
          }
          finalData.location = filterArr;
          this.props.fetchAllInstitutionsList(finalData);
          this.props.fetchAllInstitutionsFacets(finalData);
        });
      }
    } else {
      const {
        itemsPerPage,
        activePage,
        keyword,
      } = this.state;
      const data = {
        itemsPerPage,
        activePage,
        keyword,
      };
      this.props.fetchAllInstitutionsList(data);
      this.props.fetchAllInstitutionsFacets();
    }
  }

  handleClearFilters = () => {
    const {
      activePage,
      itemsPerPage,
      keyword,
    } = this.state;
    const {
      Locations,
    } = this.state;
    if (Locations && Array.isArray(Locations) && Locations.length > 0) {
      this.setState({
        Locations: [],
      }, () => {
        this.props.history.push('/app/campus/browse/institutions');
        const data = {
          activePage,
          itemsPerPage,
          keyword,
          Locations: this.state.Locations,
        };
        this.props.fetchAllInstitutionsList(data);
        this.props.fetchAllInstitutionsFacets(data);
      });
    }
  }

  handleChangeCheckBoxVals = (key, e, res) => {
    const {
      mainVals,
    } = this.state;
    let filterVals = this.state[`${res}`];
    if (e === true) {
      if (!Array.isArray(filterVals)) {
        filterVals = [key];
      } else if (filterVals && Array.isArray(filterVals) && filterVals.length <= 0) {
        filterVals = [key];
      } else {
        filterVals.push(key);
      }
    }
    this.setState({
      [`${res}`]: filterVals,
    }, () => {
      if (e === false) {
        const filteredList = this.state[`${res}`].filter(v => v != key);
        this.setState({
          [`${res}`]: filteredList,
        }, () => {
          if (mainVals === null) {
            this.handleSubmitFilterVals(res);
          }
        });
      } else if (mainVals === null) {
        this.handleSubmitFilterVals(res);
      }
    });
  }

  handleSubmitFilterVals = (res) => {
    const finaldata = this.state;
    this.props.fetchAllInstitutionsList(finaldata);
    this.props.fetchAllInstitutionsFacets(finaldata);
  }
  
  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.props.fetchAllInstitutionsList({
          itemsPerPage: this.state.itemsPerPage,
          activePage: 1,
          keyword: this.state.keyword,
        })
      ), 1000);
    });
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      this.props.fetchAllInstitutionsList(this.state);
    });
  };

  handleRedirectInstInfoPage = (id) => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push(`/app/campus/institution/info/${id}`);
    }
  }

  handleRedirectRegisterInst = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/app/campus/registration/institution');
    }
  }

  activeMoreModal = (keys) => {
    this.setState({
      isOpenMoreModal: true,
      mainVals: keys,
    });
  }

  dismissMoreModal = () => {
    this.setState({
      isOpenMoreModal: false,
      mainVals: null,
    });
  }

  handleSubmitCheckBox = () => {
    this.handleSubmitFilterVals();
    this.dismissMoreModal();
  }


  render() {
    const {
      institutions, loading, count, t,
      user: {
        isSuperAdmin,
      } = {},
      facets,
    } = this.props;
    const {
      activePage, keyword, isOpenMoreModal, mainVals,
    } = this.state;
    return (
      <Fragment>
        <BackRow
          paths={this.paths}
        />
        <div className="bx--type-delta mt-2 mb-2">{t('browseInstitutions')}</div>
        <div className="bx--row">
          <div className="bx--col-lg-4 bx--col-md-12 bx--col-sm-12">
            <FacetsBrowse
              handleClearFilters={this.handleClearFilters}
              handleChangeCheckBoxVals={this.handleChangeCheckBoxVals}
              values={this.state}
              facets={facets}
              activeMoreModal={this.activeMoreModal}
              handleSubmitCheckBox={this.handleSubmitCheckBox}
              dismissMoreModal={this.dismissMoreModal}
              isOpenMoreModal={isOpenMoreModal}
              mainVals={mainVals}
            />
          </div>
          <div className="bx--col-lg-8 bx--col-md-12 bx--col-sm-12">
            <div className="bx--row">
              <div className="mt-1 bx--col-lg-11 justify-content-between bx--col-md-6 bx--col-sm-12 d-flex">
                <div>
                  <Search
                    light
                    type="text"
                    labelText={`${t('search')} ${t('institution')}`}
                    closeButtonLabelText=""
                    placeHolderText={`${t('search')} ${t('institution')}`}
                    id="search industry"
                    value={keyword}
                    onChange={this.handleSearchSubmit}
                  />
                </div>
              </div>
              <div className="bx--col-lg-11 bx--col-md-12 bx--col-sm-12">
                <BrowseCompInst data={institutions} handleRedirectToCompView={this.handleRedirectInstInfoPage} />
                {
                  institutions && Array.isArray(institutions)
                    && institutions.length > 0
                    && (
                      <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
                    )
                }
                {
                  ((!institutions) || (institutions && Array.isArray(institutions)
                    && institutions.length <= 0))
                    && (
                      <NoInstitutions t={t} />
                    )
                }
              </div>
            </div>
          </div>
        </div>
        <JDProcessOverlay show={loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  institutions: state.BrowseInstitutions.institutions,
  count: state.BrowseInstitutions.count,
  locations: state.BrowseInstitutions.locations,
  facets: state.BrowseInstitutions.facets,
  loading: state.BrowseInstitutions.loading,
});

const mapDispatchToProps = {
  fetchAllInstitutionsList,
  fetchAllInstitutionsFacets,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BrowseInstitutions));
