import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { logout, login, logoutUser } from 'actions';
import checkTaskStatus from 'actions/talentSearch/taskStatus';
import LanguageChange from 'containers/common/languageChange';
import SwitchCompany from 'components/common/switchCompany';
// import { Link } from 'react-router-dom';
import {
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderMenuItem,
  HeaderPanel,
} from 'carbon-components-react/lib/components/UIShell';
import axios from 'axios';

import {
  Logout16,
} from '@carbon/icons-react';
import './NavRightList.css';
import AutoLogout from '../../AutoLogout'

class NavRightList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLanguageModal: false,
      showCompaniesModal: false,
    };
  }

  handleLangugaeModal = () => {
    const {
      showLanguageModal,
    } = this.state;
    this.setState({
      showLanguageModal: !showLanguageModal,
      showCompaniesModal: false,
    });
  }

  handleSwitchCompany = () => {
    const {
      showCompaniesModal,
    } = this.state;
    this.setState({
      showCompaniesModal: !showCompaniesModal,
      showLanguageModal: false,
    });
  }

  handleDisableSimulator=()=>{
    sessionStorage.removeItem("userToken");
    const userIdToRemove=sessionStorage.getItem("simulationUserId");
    sessionStorage.removeItem("simulationUserId");
    axios.post(`${window.location.protocol}//${window.location.host}/auth/logout?simulation=true&userId=${userIdToRemove}`).then((res) => {
      if(res && res.error){
        console.error(res.error)
      }
    }).catch((err) => {
      console.log("axios err", err);
    })
    this.props.history.push(`/app/campus`)
    window.location.reload();
  }

  render() {
    const {
      showLanguageModal, showCompaniesModal,
    } = this.state;
    const {
      user: {
        roleId,
        company: {
          ctypeId,
        } = {},
        currentCompany,
        isSuperAdmin
      } = {},
      handleShowSimulatorSubMenu,
      history
    } = this.props;
    let link = '';
    if (roleId && Number(roleId) === 3) {
      link = 'https://geiphelp.x0pa.ai/geip-user-guide/docs/studentdoc1';
    } else if (roleId && Number(roleId) !== 3) {
      if (ctypeId && Number(ctypeId) === 1) {
        link = 'https://rpguide.x0pa.ai/docs/cdoc0';
      } else if (ctypeId && Number(ctypeId) === 2) {
        link = 'https://geiphelp.x0pa.ai/geip-user-guide/docs/studentdoc1';
      }
    }
    const {
      companyName,
    } = currentCompany || {};
    const currLanguage = localStorage.getItem('i18nextLng');
    return (
      <Fragment>
        <HeaderGlobalBar>
          {
            <AutoLogout logoutUser={this.props.logoutUser} history={history}/>
          }
          {/* <HeaderGlobalAction className="company-name-width" isActive={showCompaniesModal} aria-label="Switch Company" onClick={this.handleSwitchCompany}>
            {roleId && Number(roleId) !== 3 && (
              <Fragment>
                <div className="current-lang text-uppercase xpa-name-overflow">
                  {companyName}
                </div>

              </Fragment>
            )}
          </HeaderGlobalAction> */}
          {/* <HeaderGlobalAction isActive={showLanguageModal} aria-label="Switch Language" onClick={this.handleLangugaeModal}>
            {
                currLanguage && (
                  <div className="current-lang">
                    {currLanguage}
                  </div>
                )
              }
          </HeaderGlobalAction> */}
          {/* {roleId === 1 && ctypeId === 2 && isSuperAdmin === true && */}
          {/* <HeaderMenuItem element={Link}>
              <b>Simulator</b>
            </HeaderMenuItem> */}
          {/* } */}
          {
            sessionStorage.getItem("userToken")!=undefined && sessionStorage.getItem("userToken")!=null && 
            <HeaderGlobalAction className="right-action-width" aria-label="simulate" onClick={() => {
              this.handleDisableSimulator();
            }}>
              <a target="_blank">
                <span className="right-action-color">
                Disable Simulation
              </span>
              </a>
            </HeaderGlobalAction> 
          }

          {parseInt(roleId) !== 3 && parseInt(ctypeId) !== 1 && 
            <HeaderGlobalAction className="right-action-width" aria-label="simulate" onClick={() => {
              handleShowSimulatorSubMenu();
            }}>
              <a target="_blank">
                <span className="right-action-color">
                  Simulate
              </span>
              </a>
            </HeaderGlobalAction>
          }
          <HeaderGlobalAction className="right-action-width" aria-label="User Guide" onClick={()=>{ window.open(link,'_blank')}} >
            <a target="_blank" href={link}>
              <span className="right-action-color">
                User Guide
              </span>
            </a>
          </HeaderGlobalAction>
          <HeaderGlobalAction id="xpa" aria-label="Logout" href="/auth/logout" onClick={() => { }}>
            <a href="/auth/logout"><Logout16 style={{ fill: 'white' }} /></a>
          </HeaderGlobalAction>
        </HeaderGlobalBar>
        {/* <HeaderPanel expanded={showCompaniesModal}>
          {showCompaniesModal && (
            <SwitchCompany
              showCompaniesModal={showCompaniesModal}
            />
          )}
        </HeaderPanel> */}
        {/* <HeaderPanel expanded={showLanguageModal}>
          {
            showLanguageModal === true && (
              <LanguageChange showLanguageModal={showLanguageModal} />
            )
          }
        </HeaderPanel> */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  taskStatus: state.taskStatus,
});

export default connect(mapStateToProps, {
  login,
  logout,
  logoutUser,
  push,
  checkTaskStatus,
})(NavRightList);
