import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NavItemsList from 'components/Header/navItemsList';
import YuvoLogo from 'assets/images/yuvohub.png';
import geiImage from 'assets/images/gei-full-colour-rgb-horizontal.jpg';
import './LeftNavigationList.css';
import SetMixPanel from 'components/common/GetCurrentPath';
import { Button, Tooltip } from 'carbon-components-react';
import SwitchCompany from '../common/switchCompany';
import { withTranslation } from 'react-i18next';

const LogoCon = ({ isYuvoEmail, showToggledView }) => (
  <div className="sidebar-brand-icon ml-5">
    {!isYuvoEmail && (<img className={showToggledView === true ? 'xpa-top-header--logo xpa-logo-width-toggled' : 'xpa-top-header--logo xpa-logo-width'} src={require('assets/images/republic-polytechnic.png')} alt="logo" />)}
    {isYuvoEmail && (<img className="xpa-top-header--logo" src={YuvoLogo} alt="logo" />)}
  </div>
);

const MainIcons = ({
  isYuvoEmail,
  displayMobileSidebar,
  rootPath,
  roleId,
  showToggledView,
}) => {
  let navPath = '/app/campus';
  if (roleId && (Number(roleId) === 3)) {
    navPath = '/app/campus/candidate';
  } else {
    navPath = '/app/campus';
  }

  return (
    <Fragment>
      {
        !displayMobileSidebar && (
          <Link
            className="sidebar-brand d-flex align-items-center justify-content-center xpa-logo-backgorund-color"
            to={navPath}
          >
            <LogoCon showToggledView={showToggledView} isYuvoEmail={isYuvoEmail} />
          </Link>
        )
      }
      {
        displayMobileSidebar && (
          <div
            className="sidebar-brand d-flex align-items-center justify-content-center xpa-logo-backgorund-color"
          >
            <LogoCon showToggledView={showToggledView} isYuvoEmail={isYuvoEmail} />
          </div>
        )
      }
    </Fragment>
  );
};

const MenuListItem = ({
  items,
  roleId,
  handleRedirectUrl,
  showEnterpriseList,
  state,
  showSubItemsList,
  showModal,
  handleOnClickNavItems,
  showToggledView,
  currUrl,
  propCtypeId,
  isAdmin,
  isSuperAdmin,
  t,
}) => (
  <Fragment>
    {
    items && Array.isArray(items)
      && items.length > 0
      && items.map((item) => {
        const {
          label,
          icon,
          path,
          roles,
          subItems,
          accepts,
          ctypeId,
        } = item || {};
        let labelClassName = 'nav-link';
        if (showSubItemsList === label) {
          labelClassName = 'nav-link';
        } else {
          labelClassName = 'nav-link collapsed';
        }
        const selectedListItem = accepts && Array.isArray(accepts)
          && accepts.length > 0 && accepts.includes(currUrl);
        return (
          <Fragment>
            <li className="nav-item">
              {
                (subItems && Array.isArray(subItems)
                  && subItems.length <= 0)
                  && roles.includes(roleId)
                  && (((isAdmin === true && !ctypeId.includes(0)) || (isSuperAdmin === true)) ? true : (roleId === 3 ? true : ctypeId && ctypeId.includes(Number(propCtypeId))))
                  ? (
                    <a
                      onClick={label === 'switchCompany' ? showModal : ((label !== 'logout') && (() => handleRedirectUrl(path, label)))}
                      className={`nav-link ${selectedListItem === true ? 'xpa-nav-item-list-highlighted' : ''}`}
                      href={label === 'logout' ? `${path}` : 'javascript:void(0)'}
                    >
                      {
                        showToggledView === true ? (
                          <Tooltip direction="right" showIcon={false} triggerText={<i className={`xpa-left-nav-color-size ${icon}`} />} tabIndex={0}>
                            {t(label)}
                          </Tooltip>
                        ) : (
                          <i className={`xpa-left-nav-color-size ${icon}`} />
                        )
                      }
                      {
                        showToggledView === false && (
                          <span className={`xpa-left-nav-color-size ml-2 xpa-left-nav-min-width ${showToggledView === true ? 'xpa-toggled-view-font-size' : 'xpa-left-nav-list-name-size'}`}>{t(label)}</span>
                        )
                      }
                    </a>
                  ) : (
                    <Fragment>
                      {
                        roles.includes(roleId)
                          && (((isAdmin === true && !ctypeId.includes(0)) || (isSuperAdmin === true)) ? true : (roleId === 3 ? true : ctypeId && ctypeId.includes(Number(propCtypeId))))
                          && (
                            <a
                              onClick={() => handleOnClickNavItems(label)}
                              className={`${labelClassName} ${selectedListItem === true ? 'xpa-nav-item-list-highlighted' : ''}`}
                              href="javascript:void(0)"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded={state[`${label}`]}
                              aria-controls="collapseTwo"
                            >
                              {
                                showToggledView === true ? (
                                  <Tooltip direction="right" showIcon={false} triggerText={<i className={`xpa-left-nav-color-size ${icon}`} />} tabIndex={0}>
                                    {t(label)}
                                  </Tooltip>
                                ) : (
                                  <i className={`xpa-left-nav-color-size ${icon}`} />
                                )
                              }
                              {
                                showToggledView === false && (
                                  <span className={`xpa-left-nav-color-size ml-2 xpa-label-cursor xpa-left-nav-min-width ${showToggledView === true ? 'xpa-toggled-view-font-size' : 'xpa-left-nav-list-name-size'}`}>{t(label)}</span>
                                )
                              }
                            </a>
                          )
                      }
                    </Fragment>
                  )
              }
              <SubListItems
                subItems={subItems}
                showEnterpriseList={showEnterpriseList}
                label={label}
                t={t}
                showSubItemsList={showSubItemsList}
                handleRedirectUrl={handleRedirectUrl}
                propCtypeId={propCtypeId}
                isAdmin={isAdmin}
                isSuperAdmin={isSuperAdmin}
              />
            </li>
          </Fragment>
        );
      })
  }
  </Fragment>
);

const SubListItems = ({
  subItems,
  showEnterpriseList,
  label,
  t,
  showSubItemsList,
  handleRedirectUrl,
  propCtypeId,
  isAdmin,
  isSuperAdmin,
}) => (
  <Fragment>
    {
    subItems && subItems.length > 0
      && showEnterpriseList === false
      && (
        <div id="collapseTwo" className={(showSubItemsList === label) ? 'collapse show' : 'collapse'} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            {
              subItems && Array.isArray(subItems)
                && subItems.length > 0
                && subItems.map((subItem) => {
                  const {
                    subItemsLabel,
                    subItemsPath,
                    subItemsIcon,
                    ctypeId,
                  } = subItem || {};
                  return (
                    <Fragment>
                      {
                        (((isAdmin === true && ctypeId.includes(propCtypeId && Number(propCtypeId))) || (isSuperAdmin === true)) ? true : ctypeId.includes(Number(propCtypeId)))
                          && (
                            <div className="d-flex ml-2 align-items-center collapse-item p-0">
                              <i className={subItemsIcon} />
                              <a className="w-100 xpa-left-nav-list xpa-collapse-item" onClick={() => handleRedirectUrl(subItemsPath, subItemsLabel)}  href="javascript:void(0)">{t(subItemsLabel)}</a>
                            </div>
                          )
                      }
                    </Fragment>
                  );
                })
            }
          </div>
        </div>
      )
  }
  </Fragment>
);

class LeftNavigationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubItemsList: '',
      showModal: false,
      showToggledView: false,
    };
  }

  handleOnClickNavItems = (name) => {
    const { showSubItemsList } = this.state;
    if (showSubItemsList === name) {
      this.setState({
        showSubItemsList: null,
      });
    } else {
      this.setState({
        showSubItemsList: name,
      });
    }
  }

  showModal = () => {
    SetMixPanel('LEFTNAV_SWITCH_COMPANIES_CLICKED');
    this.setState({
      showModal: true,
    });
  };


  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleRedirectUrl = (path, label) => {
    const {
      history: {
        push,
      } = {},
      user: {
        company: {
          ctypeId,
          companyId,
        } = {},
        isAdmin,
        isSuperAdmin,
      } = {},
    } = this.props;
    if (push) {
      if (isSuperAdmin !== true && isAdmin === true && ctypeId == 2 && label === 'institutions') {
        push(`/app/campus/company/info/${companyId}`);
        this.handleOnClickNavItems();
      } else if (isSuperAdmin !== true && isAdmin === true && ctypeId == 1 && label === 'companies') {
        push(`/app/campus/company/info/${companyId}`);
        this.handleOnClickNavItems();
      } else {
        push(path);
        this.handleOnClickNavItems();
      }
    }
  }

  handleToggleButtonClick = () => {
    const { showToggledView } = this.state;
    this.setState({
      showToggledView: !showToggledView,
    });
  }

  render() {
    const {
      user, isYuvoEmail, displayMobileSidebar, rootPath,
      showSideBar, t,
    } = this.props;
    const { showSubItemsList, showModal, showToggledView } = this.state;
    const {
      roleId,
      currentCompany,
      isAdmin,
      isSuperAdmin,
    } = user || {};
    const {
      ctypeId: propCtypeId,
    } = currentCompany || {};
    let showEnterpriseList = false;
    const currUrl = window.location.pathname;
    if (currUrl.includes('/app/campus') || currUrl.includes('/app/admin')) {
      showEnterpriseList = false;
    }
    return (
      <Fragment>
        {
          NavItemsList && Array.isArray(NavItemsList)
            && NavItemsList.map((res) => {
              const {
                mainPath,
                items,
              } = res || {};
              return (
                <Fragment>
                  <ul className={`navbar-nav sidebar sidebar-dark accordion p-0 ${isYuvoEmail ? 'xpa-yuvo-color' : ''} ${showSideBar === true ? 'toggled' : ''} ${showToggledView === true ? 'toggled' : ''}`} id="accordionSidebar">
                    <MainIcons
                      isYuvoEmail={isYuvoEmail}
                      handleRedirectUrl={this.handleRedirectUrl}
                      mainPath={mainPath}
                      roleId={roleId}
                      displayMobileSidebar={displayMobileSidebar}
                      rootPath={rootPath}
                      showToggledView={showToggledView}
                    />
                    <hr className="sidebar-divider my-0" />
                    <MenuListItem
                      mainPath={mainPath}
                      displayMobileSidebar={displayMobileSidebar}
                      rootPath={rootPath}
                      isYuvoEmail={isYuvoEmail}
                      showToggledView={showToggledView}
                      items={items}
                      t={t}
                      showModal={this.showModal}
                      roleId={roleId}
                      handleRedirectUrl={this.handleRedirectUrl}
                      showEnterpriseList={showEnterpriseList}
                      state={this.state}
                      showSubItemsList={showSubItemsList}
                      handleOnClickNavItems={this.handleOnClickNavItems}
                      currUrl={currUrl}
                      propCtypeId={propCtypeId}
                      isAdmin={isAdmin}
                      isSuperAdmin={isSuperAdmin}
                    />
                    <div className="text-center d-none d-md-inline">
                      <Button onClick={this.handleToggleButtonClick} className="rounded-circle border-0" id="sidebarToggle" />
                    </div>
                  </ul>
                </Fragment>
              );
            })
        }
        {showModal === true && <SwitchCompany show={showModal} hide={this.hideModal} />}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  taskStatus: state.taskStatus,
});

export default connect(mapStateToProps, {})(withTranslation()(LeftNavigationList));
