import { LANGUAGE_CHANGE } from 'actions/actionTypes';

const initState = {
  language: { value: '1', label: 'EN' },
  languages: [
    { value: '1', label: 'EN' },
    { value: '2', label: 'ZH' },
  ],
};

const ProductLanguge = (state = initState, action) => {
  const { type, payload = {} } = action;
  if (type === LANGUAGE_CHANGE) {
    return ({ ...state, language: payload === {} ? state.language : payload });
  }
  return ({ ...state });
};

export default ProductLanguge;
