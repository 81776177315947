import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { REQUEST_USERINFO } from 'actions/userProfile/action.constant';
import { fetchAll, updateGtpOptin } from 'actions/userProfile/action';

class GtpOptinCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pbDisabled: false,
      isOpenModal: false,
    }
  }

  handleOpenToRecruiters = () => {
    const { user: { userId, roleId, publicVisibility } = {} } = this.props;
    this.setState({
      pbDisabled: true,
    });
    this.props.updateGtpOptin({userId, publicVisibility: !publicVisibility, roleId})
    .then((res) => {
      this.setState({
        pbDisabled: false,
      });
    })
    .catch((err) => {
      console.error('User GTP update error:', err);
      this.setState({
        pbDisabled: false,
      });
    });
  }
  dismissModal = () => {
    this.setState({
      isOpenModal: false,
    });
  }
  render() {
    const { pbDisabled } = this.state;
    const { user: {publicVisibility}, hideIfSet = false } = this.props;
    return (
      <div className="pt-2 mb-2">
      {
        <div className="bx--row m-0">
          <div className="bx--col-xs-12 bx--col-sm-9">
            <p className="font-weight-bold m-0">Let my profile be visible to recruiters</p>
            <small className="text-dark">By enabling this option, recruiters can send you requests to open job requisitions that fit your profile.</small>
          </div>
          <div className="bx--col-xs-12 bx--col-sm-3 text-md-right">
            <span className="xpa-gtp-option">
              {publicVisibility ? 'ON':'OFF'}
            </span>
            <button type="button"
              className={classNames('btn', 'btn-toggle', {'active': publicVisibility}, {'disabled': pbDisabled })} data-toggle="button" aria-pressed="false" autoComplete="off" disabled={pbDisabled} onClick={this.handleOpenToRecruiters}>
              <div className="handle"></div>
            </button>
          </div>
        </div>
      }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = { updateGtpOptin, fetchAll };

export default connect(mapStateToProps, mapDispatchToProps)(GtpOptinCard);
