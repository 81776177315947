import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Tile, TextInput, ModalWrapper, Button, DataTableSkeleton,
  Select, SelectItem, InlineNotification, Pagination,
  DataTable,Search,
} from 'carbon-components-react';
import SetMixPanel from 'components/common/GetCurrentPath';
import _ from 'lodash';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import Avatar from 'components/common/Avatar';
import moment from 'moment';

import {
  getInterviewsForRecruiter,
} from 'actions/videoInterview';

import './interview.css';

const track = {
  VIDEOINTERVIEW_TITLE: 'VIDEOINTERVIEW_TITLE',
  VIDEOINTERVIEW_APPLICANT: 'VIDEOINTERVIEW_APPLICANT',
  VIDEOINTERVIEW_JOB: 'VIDEOINTERVIEW_JOB',
};

const {
  TableContainer, Table, TableRow,
  TableBody, TableCell, } = DataTable;

class VideoInterviewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interviewName: '',
      interviewURL: '',
      activePage: 1,
      ascDesc: '',
      sortParam: '',
      activeFilter: null,
      itemsPerPage: 10,
      activeColumn: '',
      currentSortProp : null,
      keyword: '',
      selectedTab: 0,
      isLoading: true,
      status: 'draft',
      rowsData:[],
      headersInfo: [{ key: "interview", header: "Interview" }]
    }
  }

  paths = [
    { id: 'roboroy', name: 'Recruiter', href: '/app/campus' },
    { id: 'interviews', name: 'Video Interviews', href: '' }
  ];

  componentDidMount() {
    const {
      match: {
        params,
      } = {},
      history: {
        push,
      } = {},
    } = this.props;
    if (params && params.status) {
      this.setState({
        status: params.status,
      }, () => {
        if (this.state.status === 'draft') {
          this.setState({
            selectedTab: 0,
          });
        } else if (this.state.status === 'awaiting') {
          this.setState({
            selectedTab: 1,
          });
        } else if (this.state.status === 'awaitingfeedback') {
          this.setState({
            selectedTab: 2,
          });
        } else if (this.state.status === 'completed') {
          this.setState({
            selectedTab: 3,
          });
        } else {
          this.setState({
            selectedTab: 4,
          });
        }
        this.props.getInterviewsForRecruiter({
          status: this.state.status,
          keyword: null,
        }).then((res) => {
          // this.setRows();
          this.setState({
            isLoading: false,
          });
        });
      });
    } else {
      this.props.getInterviewsForRecruiter({
        status: this.state.status,
        keyword: null,
      }).then((res) => {
        const d = push && push(`/app/campus/interviews/status/${this.state.status}`);
        // this.setRows();
        this.setState({
          isLoading: false,
        });
      });
    }
  }

  searchTimer = null;
  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value });
    this.searchTimer = setTimeout(() => (
      this.handlePaginationChange({ page: 1, searchKey: this.state.keyword })
    ), 1000);
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    const {
      match: {
        params: { id, status: paramStatus },
      },
    } = this.props;
    this.setState({ activePage, itemsPerPage,isLoading:true}, () => {
      // const params
      this.props.getInterviewsForRecruiter({
        searchKey: data.searchKey || null,
        status: this.state.status,
        activePage,
        itemsPerPage,
      }).then((res) => {
        this.setState({
          isLoading: false,
        });
        // this.setRows();
      });
    });
  };

  // CardRowBox = rowData => (
  //   <div className="bx--tile p-0">
  //     <div className="">
  //       <div className="bx--row p-2">
  //         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
  //           <div className="text-sm-center text-md-center text-center text-lg-left">
  //            <div className="text-dark">
  //               <small title="Job">{`Requisition ID: ${rowData.jobId}`}</small>
  //             </div>
  //             <a href={rowData.interviewLink} title="Interview Name" onClick={() =>SetMixPanel(track.VIDEOINTERVIEW_TITLE)} className="card-title h5 mb-1 d-inline-block text-center text-lg-left font-weight-bold text-capitalize xpa-link">
  //               {rowData.interviewName}
  //             </a>
  //             {rowData.applicant && rowData.applicant.trim().length > 0 && <div title="Applicant" className="text-dark card-subtitle mb-1 bx--type-zeta pt-lg-1">
  //                Candidate Name: <a onClick={() =>SetMixPanel(track.VIDEOINTERVIEW_APPLICANT)} href={rowData.applicantLink}>{` ${rowData.applicant}`}</a>
  //             </div>}
  //             {/* <small title="Applicant ID" className="text-dark">{` (${rowData.applicantId})`}</small> */}
  //             {rowData.jobName && rowData.jobName.trim().length > 0 && <div title="Designation" className="text-dark card-subtitle mb-1 bx--type-zeta pt-lg-1">
  //                Job Title:<a onClick={() =>SetMixPanel(track.VIDEOINTERVIEW_JOB)} href={rowData.jobLink}>{` ${rowData.jobName}`}</a>
  //             </div>}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  // setRows = () => {
  //   const rowsData = this.getInterviews().map((interview) => {
  //     const oneRow = {
  //       applicant: `${interview.firstName || ''} ${interview.lastName || ''}`,
  //       applicantLink: `candidate/${interview.atsCandidateId || 0}/job/${interview.jobId || 0}`,
  //       applicantId:interview.atsCandidateId,
  //       interviewName: interview.interviewName || "-",
  //       interviewLink: "/app/campus/interviews/" + interview.interviewId || 0,
  //       jobName: interview.jobName || '',
  //       jobLink: `job/${interview.jobId || 0}`,
  //       jobId:interview.jobId,
  //     };
  //     return {id:`${interview.interviewId}`,interview:this.CardRowBox(oneRow),};
  //   });
  //   this.setState({ rowsData,isLoading:false, });
  // }

  handleInterviewsTabClick = (tabType) => {
    let selectedTab = 0;
    let status = tabType;
    const {
      history: {
        push,
      } = {},
    } = this.props;
    switch (tabType) {
      case 'draft':
        selectedTab = 0;
        status = 'draft';
        break;
      case 'awaiting-response':
        selectedTab = 1;
        status = 'awaiting';
        break;
      case 'awaiting-feedback':
        selectedTab = 2;
        status = 'awaitingfeedback';
        break;
      case 'completed':
        selectedTab = 3;
        status = 'completed';
        break;
      case 'skipped':
        selectedTab = 4;
        status = 'skipped';
        break;        
      default:
        selectedTab = 0;
        status = null;
        break;
    }
    SetMixPanel(`Video Interviews status ${status}`);
    this.setState({ selectedTab, status, keyword: null,isLoading:true }, () => {
      push && push(`/app/campus/interviews/status/${status}`)
      this.handlePaginationChange({
        page: 1,
      });
    });
  }

  getInterviews = () => {
    
    const { 
      interviews = [] ,
    } = this.props;
    const interviewsList = interviews.map((interviewObj) => {
      const { 
        interviewName,
        interviewId,
        atsCandidateId,
        videointerviews = {},
        application: {
          job: {
            jobId,
            jobname: { nameName: jobName, } = {},
          } = {},
          profile: { firstName, lastName, preferName, } = {},
        } = {},
      } = interviewObj
      return {jobName, firstName, lastName, preferName, interviewName, atsCandidateId, jobId, interviewId, ...videointerviews};
    });
    const resfil = []
    return interviewsList;
  }

  qs = (ascDesc, propName) => {
    const name = propName.split('.')[propName.split('.').length - 1];
    let type = '';
    switch (name) {
      case 'interviewName': type = 'interview'; break;
      case 'nameName': type = 'job'; break;
      case 'firstName': type = 'profile'; break;
      default: return;
    }
    return encodeURIComponent(`filterObj={"${type}":"${name} ${ascDesc}"}`);
  };

  onSortValueChange = (value) => {
    const sortVal = value.indexOf('Asc') > 0 ? 'ASC' : 'DESC';
    const propName = value.indexOf('Asc') > 0 ? value.replace('Asc', '') : value.replace('Dsc', '');
    this.setState({ ascDesc: sortVal, sortParam: this.qs(sortVal, propName), activePage: 1, isLoading: true, currentSortProp: propName }, () => {
      this.props.getInterviewsForRecruiter({
        itemsPerPage: this.state.itemsPerPage,
        activePage: this.state.activePage,
        filterOptions: this.state.sortParam,
        searchKey: this.state.keyword,
        status: this.state.status,
      }).then((res) => {
        // this.setRows();
      });
    });
  }

  handleRedirectToInterviews = (interviewId) => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push && interviewId) {
      push(`/app/campus/interviews/${interviewId}`);
    }
  }

  render() {
    const { selectedTab, isLoading, status,rowsData,headersInfo } = this.state;
    const {
      count : {
        draft = 0,
        awaiting = 0,
        completed = 0,
        skipped = 0,
        awaitingfeedback = 0,
      } = {},
      interviews,
      t,
    } = this.props;
    const totalItemsCount = (this.props.count && this.props.count[status]) || 0;
    return (
      <div className="mb-3">
        <BackRow paths={this.paths} />
        <h4 className="bx--type-gamma font-weight-bold text-capitalize">{t('videoInterviewsTab')}</h4>                
        <GenericTabs
          selected={selectedTab}
          tabContent={[
            {
              label: `${t('draft')} (${(draft) || 0})`,
              value: '',
              onClick: (e) => this.handleInterviewsTabClick("draft")
            },
            {
              label: `${t('awaiting')} ${t('response')} (${(awaiting) || 0})`,
              value: '',
              onClick: (e) => this.handleInterviewsTabClick("awaiting-response")
            },
            {
              label: `${t('awaiting')} ${t('feedback')} (${(awaitingfeedback) || 0})`,
              value: '',
              onClick: (e) => this.handleInterviewsTabClick("awaiting-feedback")
            },
            {
              label: `${t('completed')} (${(completed) || 0})`,
              value: '',
              onClick: (e) => this.handleInterviewsTabClick("completed")
            },
            {
              label: `${t('skipped')} (${(skipped) || 0})`,
              value: '',
              onClick: (e) => this.handleInterviewsTabClick("skipped")
            },            
          ]} />
        <div className="bx--col-lg p-0">
          <div className="bx--row ml-0 mt-2 mr-0">
            <div className="bx--col-lg-9 p-0 bx--col-xs-12">
              <Search light type="text" style={{ maxWidth: 500 }} className="p-0 col-lg-6" placeHolderText={t('searchVideoInt')}
               onChange={this.handleSearchSubmit} id="videoInterviews" value={this.state.keyword && this.state.keyword!== null?this.state.keyword:''} />
            </div>
            <div className="sort bx--col-xs-12 mt-2 mt-lg-0 bx--col-lg-3 pl-0 text-right pr-0">
              {<Select
                light
                className="bx--col-xs-12 p-0"
                hideLabel
                invalidText="A valid value is required"
                onChange={(e) => this.onSortValueChange(e.target.value)}
                id="select-1"
                defaultValue="placeholder-item">
                <SelectItem disabled hidden value="placeholder-item" text={t('sortBy')} />
                {/* <SelectItem value="application.job.jobIdAsc" text="Job ID Asc" />
                <SelectItem value="application.job.jobIdDsc" text="Job ID Dsc" />
                <SelectItem value="application.appIdAsc" text="Applicant ID Asc" />
                <SelectItem value="application.appIdDsc" text="Applicant ID Dsc" /> */}
                <SelectItem value="videointerviews.interviewNameAsc" text={`${t('interview')} ${t('name')} ${t('ascending')}`} />
                <SelectItem value="videointerviews.interviewNameDsc" text={`${t('interview')} ${t('name')} ${t('descending')}`} />
                <SelectItem value="application.profile.firstNameAsc" text={`${t('applicant')} ${t('name')} ${t('ascending')}`} />
                <SelectItem value="application.profile.firstNameDsc" text={`${t('applicant')} ${t('name')} ${t('descending')}`} />
                <SelectItem value="application.job.jobname.nameNameAsc" text={`${t('job')} ${t('title')} ${t('ascending')}`} />
                <SelectItem value="application.job.jobname.nameNameDsc" text={`${t('job')} ${t('title')} ${t('descending')}`} />
              </Select>}
            </div>
          </div>
          {/* <div className="interviewList">
          <DataTable rows={rowsData} headers={headersInfo} render={({ rows, headers, getHeaderProps }) => {
            return <TableContainer title="" className="bg-transparent mt-2" style={{ overflow: "hidden" }}>
              <Table className="bg-transparent" style={{ borderSpacing: "0px 6px", borderCollapse: "separate" }}>
                <TableBody className="bg-transparent">
                  {rows.map(row => (
                    <TableRow key={row.id} className="mb-2">
                      {row.cells.map(cell => (
                        <TableCell
                          key={cell.id}
                          className="pr-0 pl-0"
                        >
                          {cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>;
          }} />
        </div> */}
        {
          interviews && Array.isArray(interviews)
            && interviews.length > 0
            && (
              <div className="bx--tile mt-1 pb-1">
                {
                  interviews.map((res, idx) => {
                    const {
                      application: {
                        appId,
                        job: {
                          jobId,
                          jobname: {
                            nameName,
                          } = {},
                          company: {
                            companyName,
                          } = {},
                        } = {},
                        profile: {
                          firstName,
                          lastName,
                        } = {},
                      } = {},
                      updatedAt,
                      interviewName,
                      interviewId,
                    } = res || {};
                    return (
                      <Fragment>
                        <div className={`bx--row ${(((interviews.length - 1) == idx)) && 'pb-2'}`}>
                        <div className=" bx--type-epsilon mt-1 bx--col-lg-3 bx--col-md-4 bx--col-sm-12">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg>
                            <span className="xpa-link ml-1" onClick={() => this.handleRedirectToInterviews(interviewId)}>{interviewName}</span>
                          </div>
                          <div className="mt-1 bx--col-lg-3">
                            <a className="font-weight-bold text-capitalize" href={`/app/campus/job/${jobId}`} onClick={() => SetMixPanel(track.ROBOROY_DASHBOARD_VIDEOINTERVIEWJOB_CLICKED)}>{nameName}</a>
                            {
                              jobId && (
                                <span className="text-dark text-normal ml-1">({jobId})</span>
                              )
                            }
                          </div>
                          <div className="mt-1 text-dark bx--col-lg-2 bx--col-md-4 bx--col-sm-12">
                            {companyName}
                          </div>
                          <div className="ml-2 xpa-avatar-width">
                            <Avatar
                              user={{
                                firstname: firstName,
                                lastname: lastName,
                                picture: null,
                              }}
                              classNameInfo="rounded-circle"
                              size="30"
                            />
                          </div>
                          <div className="font-weight-bold text-capitalize bx--col-lg-2 bx--col-md-4 bx--col-sm-12 bx--col-xs-10 mt-1">
                            <a href={`/app/campus/candidate/${appId}/job/${jobId}/interviewstatus`} onClick={() => SetMixPanel(track.ROBOROY_DASHBOARD_VIDEOINTERVIEW_CLICKED)}>{firstName} {lastName}</a>
                          </div>
                          <div className="mt-1 ml-2 text-dark">
                            {moment(updatedAt).fromNow()}
                          </div>
                        </div>
                        {
                          !(((interviews.length - 1) == idx)) && <hr className="mb-2 mt-1" />
                        }
                      </Fragment>
                    );
                  })
                }
              </div>
            )
        }
        {totalItemsCount > 0 && <Pagination
          onChange={this.handlePaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={this.state.activePage}
          totalItems={totalItemsCount} />}
          {
            interviews && Array.isArray(interviews) && interviews.length <= 0 && <NoContentBox t={t} />
          }
      </div>
      <JDProcessOverlay show={ this.state.isLoading} message="processing..." />
      </div>);
  }
}

const NoContentBox = ({
  t,
}) => (
  <Tile className="mt-2">
    <div className="mt-2">
      <div className="text-center">
        <div className="empty-icon">
          <i className="fa fa-flag fa-2x" />
        </div>
        <p className="empty-title h5">{t('noVideoIntMsg')}</p>
      </div>
    </div>
  </Tile>
);

const mapStateToProps = state => ({
  loading: state.videoInterviews.loading,
  user: state.x0paDuser.user,
  interviews: state.videoInterviews.interviews,
  count: state.videoInterviews.count,
});
const mapDispatchToProps = {
  getInterviewsForRecruiter,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VideoInterviewsList));
