import callApi from 'utils/callApi';
import { baseUrl } from 'utils/config';
import {
  REQUEST_PROFILE_EMAILS,
  REQUEST_DASHBOARD_INFO
} from './actionTypes';

const getProfileEmails = profileId => (dispatch) => {
  const err = {
    data: '',
    error: {
      message: 'No valid data passed',
    },
  };
  if (!profileId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/profiles/${profileId}/emails`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_PROFILE_EMAILS,
    dispatch,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const exportPlacementData = (courseId, schoolId, batch) =>(dispatch)=> {
  let url = `${baseUrl}/profiles/exports?_=${new Date().getTime()}&`
  if(courseId){
    url += `&courseId=${courseId}`
  }
  if(schoolId){
    url += `&schoolId=${schoolId}`
  }
  if(batch){
    url += `&batch=${batch}`
  }
  window.location.href = url;
}

const getDashboardsInfo = (filterArr) => (dispatch) => {
  const {
    courseIds = '',
    filterName_courseIds = [],
    batch,
    filterName_batch = [],
  } = filterArr
  let url = `${baseUrl}/jobs/dashboardInfo?`;
  if (courseIds) {
    url += `&filterArr[courseIds]=${JSON.stringify(filterName_courseIds)}`
  }

  if (batch) {
    url += `&filterArr[batch]=${JSON.stringify(filterName_batch)}`
  }

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DASHBOARD_INFO,
    dispatch,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const dashboardJobs = (status) => (dispatch) => {
  let url = `${baseUrl}/jobs/dashboardjobs`;
  if(status){
    url += `?status=${status}`
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DASHBOARD_INFO,
    dispatch,
    url,
    method: 'GET',
  };
  return callApi(params);
};

export {
  getProfileEmails,
  exportPlacementData,
  getDashboardsInfo,
  dashboardJobs
};
