import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_GET_STUDENT_JOB_MATCHES,
  REQUEST_UPDATE_STUDENT_JOB_MATCHES,
  REQUEST_ADD_TO_JOB,
  REQUEST_GET_STUDENT_EMAILS,
  REQUEST_SET_STUDENT_HIRED,
  REQUEST_GET_INTERVIEW_FEEDBACK,
  REQUEST_CREATE_INTERVIEW_FEEDBACK,
  REQUEST_UPSERT_INTERVIEW_FEEDBACK,
} from './actionTypes';

const getInterviewFeedback = profileId => (dispatch) => {
  const error = {
    message: 'Invalid profile Id',
  };
  if (!profileId) {
    return Promise.reject(error);
  }
  const url = `${baseUrl}/profilefeedbacks/${profileId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_INTERVIEW_FEEDBACK,
  };

  return callApi(params);
};

const createInterviewFeedback = data => (dispatch) => {
  const {
    profileId,
  } = data || {};
  const err = {
    message: 'No profileId passed',
  };
  if (!profileId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/profilefeedbacks/${profileId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_INTERVIEW_FEEDBACK,
  };

  return callApi(params);
};

const upsertInterviewFeedback = data => (dispatch) => {
  const {
    profileFeedbackId,
  } = data || {};
  const err = {
    message: 'No profile feedback Id passed',
  };
  if (!profileFeedbackId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/profilefeedbacks/${profileFeedbackId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPSERT_INTERVIEW_FEEDBACK,
  };

  return callApi(params);
};

const getStudentProfileJobMatches = (data, criteria) => (dispatch) => {
  const {
    studentProfileId,
    itemsPerPage = 10, activePage = 1,
    filterOptions, searchKey,
    cityArr,
  } = data;
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  const error = {
    message: 'Invalid student profile Id',
  };
  if (!studentProfileId) {
    Promise.reject(error);
  }
  let url = `${baseUrl}/studentprofile/${studentProfileId}/matches?filter[skip]=${skip}&filter[limit]=${itemsPerPage}&_=${new Date().getTime()}&`.concat(filterOptions);
  if (criteria) {
    url += `&filter[criteria]=${criteria}`;
  }
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }
  if (cityArr && Array.isArray(cityArr) && cityArr.length > 0) {
    url += `&filter[cities]=${encodeURIComponent(JSON.stringify(cityArr))}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_STUDENT_JOB_MATCHES,
  };

  return callApi(params);
};

const updateStudentProfileJobMatches = data => (dispatch) => {
  const {
    scoringStudentId,
    facultyPreferenceRank,
    studentPreferenceRank,
    roleId,
  } = data || {};
  const finalData = {};
  if (roleId !== 3) {
    finalData.facultyPreferenceRank = facultyPreferenceRank;
  }
  if (studentPreferenceRank) {
    finalData.studentPreferenceRank = studentPreferenceRank;
  }
  const url = `${baseUrl}/scores/jobmatches/${scoringStudentId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(finalData),
    actionType: REQUEST_UPDATE_STUDENT_JOB_MATCHES,
  };

  return callApi(params);
};

const addStudentProfileToJob = data => (dispatch) => {
  const {
    jobId,
    profileId,
  } = data || {};
  const err = {
    message: 'No jobId or profileId passed',
  };
  if (!jobId || !profileId) {
    Promise.reject(err);
  }
  const url = `${baseUrl}/jobs/addtojob?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_ADD_TO_JOB,
  };

  return callApi(params);
};

const getStudentProfileEmails = profileId => (dispatch) => {
  const error = {
    message: 'Invalid profile Id',
  };
  if (!profileId) {
    Promise.reject(error);
  }
  const url = `${baseUrl}/applicationemails/${profileId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_STUDENT_EMAILS,
  };

  return callApi(params);
};


const addStudentToSetAsHired = data => (dispatch) => {
  const {
    jobId,
    profileId,
  } = data || {};
  const err = {
    message: 'No jobId or profileId passed',
  };
  if (!jobId || !profileId) {
    Promise.reject(err);
  }
  const url = `${baseUrl}/jobs/setAsHired?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_SET_STUDENT_HIRED,
  };

  return callApi(params);
};

export {
  getStudentProfileJobMatches,
  updateStudentProfileJobMatches,
  addStudentProfileToJob,
  getStudentProfileEmails,
  addStudentToSetAsHired,
  getInterviewFeedback,
  createInterviewFeedback,
  upsertInterviewFeedback,
};
