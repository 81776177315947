import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Loading, Modal, InlineNotification,
  Button, Select, SelectItem,
} from 'carbon-components-react';
import { sendReminder } from 'actions/videoInterview';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { isMobilePhone } from 'validator';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import {
  isCurrentUserAdminLevelCollaborator,
} from 'components/roboroy/WorkflowModule/workflowUtils';
import isEmail from 'validator/lib/isEmail';
import {
  createOfferDetails,
  getJobOfferDetails
} from 'actions/offerInformation';
import {
  getWorkflowStages,
  createWorkflow,
  cloneWorkflow,
  updateSubStage,
  getApplicationWorkflow,
  updateApplicationSubStage,
  assignSubStageToUser,
  deleteCurrUser,
  updateStageConfigs,
  skipStage,
  scheduleStage,
  getAvailabilitySlots,
  manuallyScheduleStage,
} from 'actions/workflow';
import { upsertProfileContact, getAssetAnalytics } from 'actions/talentSearch/candidatesList';
import { getCustomEmailTemplates, sendEmailTemplate } from 'actions/email-templates';
import { sendEmails } from 'actions/talentSearch/talentPool';
import ApplicantWorkflowStages from './workflowStages';

class WorkflowStatusUpdateModal extends Component {
  constructor(props) {
    super(props);
    const availableSlots = {
      // one: {
      //   key: 'one',
      //   start: moment(moment().add(2, 'd').format('MM/DD/YYYY 09:00')).toDate(),
      //   end: moment(moment().add(2, 'd').format('MM/DD/YYYY 18:00')).toDate(),
      // },
    };
    this.state = {
      open: true,
      showNotif: false,
      notifMessage: '',
      notifType: 'error',
      isScheduleBtnLoading: false,
      applicantMobileNumber: '',
      participants: [],
      isEditingSlots: true,
      showNotification: false,
      noSlots: false,
      availableSlots,
      offerjobModal: false,
      isAdditionalInfoInvalid: false,
      isAdditionalInfoInvalidText: ""
      // cantOfferjobModal: false
    };
  }

  componentDidMount() {
    if (this && this.props && this.props.appId) {
      this.rerenderContent(this.props.appId);
      this.props.getOfferDetails(this.props.appId);
    }
    if (this && this.props && this.props.workflows && this.props.workflows.workflow) {
      this.setState({ workflow: this.props.workflows.workflow }, () => {
        this.props.getCustomEmailTemplates();
      });
    }
    this.handleJobOfferInfo()
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.appId !== prevProps.appId) {
      this.rerenderContent();
    }
  }

  rerenderContent(appId) {
    this.getThisWorkflow(appId || this.props.appId).then(() => {
      if (this && this.props && this.props.workflows && this.props.workflows.workflow) {
        this.setState({ workflow: this.props.workflows.workflow });
      }
    });
  }

  getThisWorkflow(appId) {
    return this.props.getApplicationWorkflow(appId);
  }

  handleSubmitCompleteThisStage = () => {
    const {
      workflowId,
      appStageId,
      appStageUserMapId,
      feedback,
      outcomeId,
      rating,
    } = this.state;
    this.props.updateSubStage({
      feedback,
      outcomeId,
      userMapId: appStageUserMapId,
      rating,
      workflowId,
      appStageId,
    }).then((resp) => {
      if (resp && !resp.error) {
        window.location.reload();
      }
    }).catch(e => console.log('error', e));
  }

  handleJobOfferInfo = () => {
    const { jobId, appId} = this.props
    this.props.getJobOfferDetails(jobId, appId).then((res) => {
      if (res && !res.error) {
        this.setState({
          canSendOffer: (res && res.canSendOffer)
        })
      }
    })
  }

  handleUserSubStageCompletion = ({ workflowId, appStageId, appStageUserMapId, feedback, outcomeId, rating, openModal }) => {
    if (openModal === true) {
      this.setState({
        openModal: true,
        workflowId,
        appStageId,
        appStageUserMapId,
        feedback,
        outcomeId,
        rating,
      });
    } else {
      this.props.updateSubStage({
        feedback,
        outcomeId,
        userMapId: appStageUserMapId,
        rating,
        workflowId,
        appStageId,
      }).then((resp) => {
        if (this.props.showInModal === false) {
          //this.handleOfferRoundNotif();
          this.rerenderContent();
          if (outcomeId === 6000) {
            this.handleSendOfferDetails();
          } else if (outcomeId === 6001) {
            window.location.reload();
            this.handleSendRejectEmail();
          }
        } else {
          this.rerenderContent();
        }
      });
    }
  }

  handleSendRejectEmail = () => {
    const { application } = this.props;
    const { appId, profileId, profile } = application || {};
    const { email } = profile || {};
    const data = {
      templateId: 6,
      templateName: 'company-ihl-rejects',
      emailData: [{
        email: email || 'dummy',
        profileId: profileId || 0,
        appId: appId || 0,
      }],
      actionName: 'send-email',
    };

    this.setState({
      notifShow: false,
      notifMsg: '',
    }, () => {
      this.props.sendEmails(data)
        .then((res) => {
          const { error, MessageId } = res || {};
          if (error) {
            throw new Error('Error occured.');
          }
          if (MessageId) {
            this.setState({
              notifShow: true,
              notifKind: 'success',
              notifMsg: 'Email sent successfully',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            notifShow: true,
            notifKind: 'error',
            notifMsg: 'Error occured while sending email',
          });
        });
    });
  }

  handleSendOfferDetails = () => {
    const {
      appId,
      application,
      jobInfo,
    } = this.props;
    const {
      profile: {
        firstName,
        lastName,
        email,
      } = {},
    } = application || {};
    const {
      maxSalary,
      jobname: {
        nameName,
      } = {},
    } = jobInfo || {};
    const data = {
      appId,
      offerName: `Offer #1`,
      position: nameName,
      salary: maxSalary,
      status: 'draft',
    };
    this.props.getAssetAnalytics(appId);
    this.props.createOfferDetails(data).then((res) => {
      if (res && !res.error) {
        const sendEmailData = {
          payload: {
            appId: appId || 0,
            offerId: (res && res.offerId) || 0,
          },
          templateId: -1,
          actionName: 'send-email',
          templateName: 'recruiter-sends-offer-details',
          email,
          emailActionType: 'recruiter-offer-request',
        };
        this.props.sendEmailTemplate(sendEmailData).then((result) => {
          if (result && !result.error) {
            window.location.reload();
          }
        });
      }
    }).catch(e => console.log('Error:::::', e));
  }

  handleReassignSubStage = ({
    workflowId, appStageId, subStageId, userId,
    roleId, deleteCurrId, collabs = null,
  }) => {
    let collaborators = [];
    if (Array.isArray(collabs) && collabs.length > 0) {
      collaborators = collabs.map(one => one);
    } else collaborators = [{ userId, roleId }];
    // if (deleteCurrId && deleteCurrId !== null) {
    this.props.deleteCurrUser({
      workflowId,
      appStageId: appStageId ? parseInt(appStageId, 10) : null,
      subStageId,
      userId: deleteCurrId,
    }).then(() => {
      // this.rerenderContent();
      this.props.assignSubStageToUser({
        workflowId,
        appStageId: appStageId ? parseInt(appStageId, 10) : null,
        subStageId,
        collaborators,
      }).then(() => {
        this.rerenderContent();
      });
    });
    // } else {
    //   this.props.assignSubStageToUser({
    //     workflowId,
    //     appStageId: appStageId ? parseInt(appStageId, 10) : null,
    //     subStageId,
    //     collaborators,
    //   }).then(() => {
    //     this.rerenderContent();
    //   });
    // }
  }

  handleDeleteStage = (workflowId, stageConfig, stageId) => {
    const { appId, jobId } = this.props;
    let stageConfigList = stageConfig.split(',');
    let newStageConfigList = stageConfigList.filter(eachConfig => eachConfig != stageId);
    const data = {
      workflowId,
      stageConfig: newStageConfigList.join(','),
    };
    const finalData = { ...data };
    if (!appId && jobId !== null) {
      finalData.jobId = jobId;
    } else if (appId !== null) {
      finalData.appId = appId;
    }
    this.props.updateStageConfigs(finalData).then(() => {
      this.rerenderContent();
    });
  }

  handleOfferRoundNotif = () => {
    const {
      workflows, application,
      offerDetails,
    } = this.props;
    const {
      offerConsentSent,
      offerConsentAccept,
      isHired,
    } = application || {};
    const workflow = workflows && workflows.workflow;
    const acceptedStates = [6000, 6002, 6005];
    const rejectedStates = [6001, 6003, 6004];
    if (workflow && workflow.outcomeId && acceptedStates.includes(Number(workflow.outcomeId))) {
      if (offerConsentSent === null && offerConsentAccept === null) {
        this.setState({
          showNotification: true,
          notifType: 'success',
          notifMessage: 'Congratulations! The applicant has cleared all interview rounds. To start sending offer, please get consent from applicant.',
        }, () => {
          this.rerenderContent();
        });
      } else if (offerConsentSent === true && offerConsentAccept !== true && isHired === null) {
        this.setState({
          showNotification: true,
          notifType: 'error',
          notifMessage: 'The candidate has not provided consent to send offers.',
        });
      } else if (offerConsentSent === true && offerConsentAccept === true && isHired === null
        && offerDetails && Array.isArray(offerDetails) && offerDetails.length <= 0) {
        this.setState({
          showNotification: true,
          notifType: 'success',
          notifMessage: 'The candidate has progressed to offer round.',
        });
      } else if (offerConsentSent === true && offerConsentAccept === true && isHired === null
        && offerDetails && Array.isArray(offerDetails) && offerDetails[0]
        && offerDetails[0].status && offerDetails[0].status.toString()
        && offerDetails[0].status.toLowerCase() !== 'accepted') {
        this.setState({
          showNotification: true,
          notifType: 'warning',
          notifMessage: 'Candidate has successfully completed interview rounds and offer round is currently inprogress',
        });
      } else if (offerConsentSent === true && offerConsentAccept === true && isHired === null
        && offerDetails && Array.isArray(offerDetails) && offerDetails[0]
        && offerDetails[0].status && offerDetails[0].status.toString()
        && offerDetails[0].status.toLowerCase() === 'accepted') {
        this.setState({
          showNotification: true,
          notifType: 'success',
          notifMessage: 'Congratulations! The candidate has accepted your offer. Please close the offer round to finish hiring.',
        });
      } else if (offerConsentSent === true && offerConsentAccept === true && isHired === true) {
        this.setState({
          showNotification: true,
          notifType: 'success',
          notifMessage: 'Congrulations! This candidate has been hired!',
        });
      } else if (offerConsentSent === true && offerConsentAccept === true && isHired === false) {
        this.setState({
          showNotification: true,
          notifType: 'error',
          notifMessage: 'This candidate has marked as no hire!',
        });
      }
    } else if (workflow && workflow.outcomeId && rejectedStates.includes(Number(workflow.outcomeId))) {
      this.setState({
        showNotification: true,
        notifType: 'error',
        notifMessage: 'This candidate has not cleared the interview rounds.',
      });
    }

  }

  // handleAddStage = (workflow, appStageId) => {

  //   let indexToInsertAt = 0;
  //   let isAlreadyAddInProgress = false;
  //   workflow && workflow.stages.forEach(function(eachStage, index) {
  //     if (eachStage.stageMeta && eachStage.stageMeta.appStageId == appStageId  && !isAlreadyAddInProgress) {
  //       indexToInsertAt = index + 1;
  //     }
  //     if (eachStage && eachStage.mode && eachStage.mode == 'add') {
  //       isAlreadyAddInProgress = true;
  //       indexToInsertAt = index;
  //     }
  //   });

  //   if (!isAlreadyAddInProgress) {
  //     workflow.stages.splice(indexToInsertAt, 0, {mode:"add"});
  //     this.setState({workflow});
  //   } else {
  //     this.showEditWorkflowError(indexToInsertAt, 'Please finish adding stage.');
  //   }

  // }
  // handleAddStageCancel = (workflow, indexToRemoveAt) => {
  //   workflow.stages.splice(indexToRemoveAt, 1);
  //   this.setState({workflow, [`selectedStageVal${indexToRemoveAt}`] : undefined});
  // }

  // handleStageMovement = (workflow, indexToMove, direction) => {
  //   const { appId, jobId } = this.props;
  //   let configuration = this.state.workflow;
  //   let stageConfigs = [];
  //   configuration && configuration.stages.forEach(function(eachStage, index) {
  //     if (eachStage.stageMeta && eachStage.stageMeta.stageId) {
  //       stageConfigs.push(eachStage.stageMeta.stageId);
  //     }
  //   });
  //   let temp = stageConfigs[indexToMove];
  //   stageConfigs[indexToMove] = stageConfigs[indexToMove+direction];
  //   stageConfigs[indexToMove+direction] = temp;
  //   const data = {
  //     workflowId: configuration.workflowId,
  //     stageConfig: stageConfigs.join(','),
  //   };
  //   const finalData = { ...data };
  //   if (!appId && jobId !== null) {
  //     finalData.jobId = jobId;
  //   } else if (appId !== null) {
  //     finalData.appId = appId;
  //   }
  //   this.props.updateStageConfigs(finalData).then(() => {
  //     this.rerenderContent();
  //   });
  // }
  // handleMoveStageDown = (workflow, indexToMove) => {
  //   this.handleStageMovement(workflow,indexToMove,1);
  // }

  // handleMoveStageUp = (workflow, indexToMove) => {
  //   this.handleStageMovement(workflow,indexToMove,-1);
  // }


  // handleAddStageSave = (workflowStages, currentStages, indexToSaveAt) => {
  //   const { appId, jobId } = this.props;
  //   if (this.state[`selectedStageVal${indexToSaveAt}`]) {
  //     let stageTypeId = this.state[`selectedStageVal${indexToSaveAt}`];

  //     //getting all stageids for this stage type
  //     let remainingStagesInSelectedStageType = [];

  //     //find remaining stages in each stage type
  //     workflowStages.forEach(function(eachStage, index) {
  //       if (eachStage && eachStage.stageTypeId == stageTypeId) {
  //         remainingStagesInSelectedStageType = eachStage && eachStage.stages && eachStage.stages.filter(eachStageItem => {
  //           let returnVal = true;
  //           currentStages.forEach((currentStageItem) => {
  //             if (currentStageItem.stageMeta && currentStageItem.stageMeta.stageId == eachStageItem.stageId) {
  //               returnVal = false;
  //             }
  //           })

  //           return returnVal;
  //         })
  //       }
  //     });

  //     if (remainingStagesInSelectedStageType && remainingStagesInSelectedStageType.length > 0) {
  //       let configuration = this.state.workflow;
  //       configuration.stages[indexToSaveAt] = {
  //         mode : 'add',
  //         stageMeta : {
  //           stageId : remainingStagesInSelectedStageType[0].stageId
  //         }
  //       }

  //       let stageConfigs = [];

  //       configuration && configuration.stages.forEach(function(eachStage, index) {
  //         if (eachStage.stageMeta && eachStage.stageMeta.stageId) {
  //           stageConfigs.push(eachStage.stageMeta.stageId);
  //         }
  //       });
  //       const data = {
  //         workflowId: configuration.workflowId,
  //         stageConfig: stageConfigs.join(','),
  //       };
  //       const finalData = { ...data };
  //       if (!appId && jobId !== null) {
  //         finalData.jobId = jobId;
  //       } else if (appId !== null) {
  //         finalData.appId = appId;
  //       }
  //       this.props.updateStageConfigs(finalData).then(() => {
  //         this.rerenderContent();
  //       });

  //     } else {
  //       this.showEditWorkflowError(indexToSaveAt, 'Invalid configuration choosen. Please retry again.');
  //     }
  //   } else {
  //     this.showEditWorkflowError(indexToSaveAt, 'Please select a valid stage.');
  //   }

  // }
  showEditWorkflowError = (indexToShowAt, errorMsg) => {
    let configuration = this.state.workflow;
    configuration.stages[indexToShowAt] = {
      mode: 'add',
      errorMsg
    }
    this.setState({ workflow: configuration });
  }
  // handleAddStageSelectChange = (selectedStage, index) => {
  //   this.setState({
  //     [`selectedStageVal${index}`] : selectedStage
  //   });
  // }
  handleSkipStage = (appStageId, workflowId) => {
    this.props.skipStage({
      appStageId,
      workflowId,
    }).then(() => {
      //will be removed once updateSubStageDetails is fixed with complete await. Few pieces are in async.
      this.rerenderContent();
      this.handleSendOfferDetails();
    });
  }

  closeThisModal = () => {
    if (this.props.updateParentPage) {
      this.props.updateParentPage(true);
    }
    this.props.dismiss();
  }

  submitShare = (recruiter = {}, applicant = {}, companyName, nameName, cstage) => {
    const {
      workflows: {
        workflow: {
          stages = [],
        } = {},
      } = {},
    } = this.props;
    const videoId = cstage.stageMeta && cstage.stageMeta.videoInterviewId;
    const {
      email = '',
      firstName = '',
      lastName = '',
    } = applicant;
    const {
      firstname = '',
      lastname = '',
    } = recruiter;
    const isemailValid = isEmail(email + '');
    if (isemailValid) {
      this.props.sendReminder({
        templateName: 'interview-reminder',
        firstName,
        lastName,
        email,
        companyName,
        jobName: nameName,
        recruiterName: `${firstname} ${lastname}`,
        interviewId: videoId,
      });
      return true;
    }
    return false;
  }

  handleGetSlots = (eachSubStage, isReschedule) => {
    const {
      appId = 0,
      application: {
        profileId = 0,
        profile: {
          firstName = '',
          lastName = '',
          email,
        } = '',
        job: {
          jobname: {
            nameName = '',
          } = {},
        } = {},
      } = {},
      jobInfo,
    } = this.props;
    const {
      company: {
        companyName = '',
      },
    } = jobInfo || {};
    const { appStageId = 0 } = eachSubStage || {};
    const userId = eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].userId;
    this.setState({
      isEditingSlots: true,
    }, () => {
      this.props.getAvailabilitySlots({
        users: [userId],
        profileId,
        appId,
        appStageId,
        summary: `Scheduled interview for ${firstName} ${lastName}`,
        candidateName: `${firstName} ${lastName}`,
        candidateEmail: email,
        jobName: nameName,
        companyName,
        isReschedule,
      }).then((res) => {
        const { error } = res || {};
        if (error) {
          const errObj = {
            showNotif: true,
            notifType: 'error',
            notifMessage: error.message || 'Unkown error occured.',
            isScheduleBtnLoading: false,
          };
          if (error.message.indexOf('No member in the panel is free') > -1) {
            errObj.noSlots = true;
          }
          this.setState(errObj);
        } else {
          const slotsState = {};
          if (res && res.slots && Array.isArray(res.slots)) {
            (res.slots).forEach((r) => {
              slotsState[r.key] = {
                date: moment(r.start).format('YYYY-MM-DD'),
                start: moment(r.start).format('HH:mm'),
                end: moment(r.end).format('HH:mm'),
              };
            });
          }
          const participantArr = (res && res.slots && Array.isArray(res.slots) && res.slots[0].participants);
          this.setState({
            isScheduleBtnLoading: false,
            availableSlots: res && res.slots,
            slotsState,
            participants: participantArr,
          }, () => {
            console.log('HANDLE GET SLOTS:::', this.state.slotsState);
          });
          this.rerenderContent(appId);
        }
      });
    });
  }

  handleSubstageSchedule = ({ eachSubStage, isReschedule }) => {
    const {
      appId = 0,
      application: {
        profileId = 0,
        profile: {
          firstName = '',
          lastName = '',
          email,
        } = '',
        job: {
          jobname: {
            nameName = '',
          } = {},
        } = {},
        workflow: {
          stages: appWorkflowStages = [],
        } = {},
      } = {},
      jobInfo,
      user: {
        company: {
          displayCompanyName
        } = {}
      } = {}
    } = this.props;
    const { company } = jobInfo || {};
    const { companyName = 'RP' } = company || {};
    const { appStageId = 0 } = eachSubStage || {};
    const userId = eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].userId;

    let currentStage = {};
    if (appWorkflowStages && Array.isArray(appWorkflowStages)) {
      const currentStageArr = appWorkflowStages.filter((st) => st && st.stageMeta && st.stageMeta.appStageId == appStageId);
      currentStage = (currentStageArr && Array.isArray(currentStageArr) && currentStageArr[0]
        && currentStageArr[0].stageMeta) || {};
    }
    const { eventId } = currentStage || {};
    const rescheduleEventId = eventId || false;
    if (!email) {
      this.setState({
        showNotif: true,
        notifType: 'error',
        notifMessage: 'No email record available for the applicant.',
      });
      return;
    }
    this.setState({
      isScheduleBtnLoading: true,
      showNotif: false,
    });
    const { availableSlots = {}, eventAdditionalInfo } = this.state;
    const asl = Object.keys(availableSlots).map((k) => {
      const { start } = availableSlots[k] || {};
      const startTime = start;
      const endTime = moment(start).add(30, 'm').format();
      if (!start) {
        return false;
      }
      return {
        start: moment(startTime).format("YYYY-MM-DDTHH:mm:00"),
        end: moment(endTime).format("YYYY-MM-DDTHH:mm:00"),
      };
    }).filter(e => e);
    const scheduleStageObj = {
      users: [userId],
      profileId,
      appId,
      appStageId,
      summary: `Scheduled interview for ${firstName} ${lastName}`,
      candidateName: `${firstName} ${lastName}`,
      candidateEmail: email,
      jobName: nameName,
      companyName: displayCompanyName || "",
      isReschedule,
      rescheduleEventId,
      eventAdditionalInfo,
    };

    let validationFailed = false;
    const data = {}
    if (asl && Array.isArray(asl) && asl.length > 0) {
      scheduleStageObj.availableSlots = asl;
    } else {
      validationFailed = true;
      data.showNotif = true;
      data.notifType = 'error';
      data.notifMessage = 'Please add at-least one slot';
      data.isScheduleBtnLoading = false;
    }

    if (scheduleStageObj.eventAdditionalInfo === null || scheduleStageObj.eventAdditionalInfo === undefined || scheduleStageObj.eventAdditionalInfo === "") {
      validationFailed = true;
      data.isAdditionalInfoInvalid = true;
      data.isAdditionalInfoInvalidText = "Please enter the additional info. It cannot be empty.";
    }

    console.log('scheduleStageObj', scheduleStageObj);
    // return;
    if (validationFailed === true) {
      this.setState({ ...data })
      return;
    }

    this.props.manuallyScheduleStage(scheduleStageObj).then((res) => {
      const { error } = res || {};
      if (error) {
        this.setState({
          showNotif: true,
          notifType: 'error',
          notifMessage: error.message || 'Unkown error occured.',
          isScheduleBtnLoading: false,
        });
      } else {
        this.setState({
          isScheduleBtnLoading: false,
          isEditingSlots: false,
          showNotif: false,
        });
        this.rerenderContent(appId);
      }
    });
  }

  onNotifClose = () => {
    this.setState({
      showNotif: false,
      notifType: 'info',
      notifMessage: '',
    });
  }

  handleAddPhone = (e) => {
    e.preventDefault();
    const { applicantMobileNumber = 0 } = this.state;
    const isValidPhone = isMobilePhone(applicantMobileNumber);
    if (!isValidPhone) {
      this.setState({
        showNotif: true,
        notifType: 'error',
        notifMessage: 'Invalid phone number.',
      });
      return;
    }
    const {
      application: {
        profileId = 0,
        mobile: {
          personMobId,
        } = {},
      } = {},
    } = this.props;
    const data = {
      profileId,
      mobile: applicantMobileNumber,
    };
    if (personMobId) {
      data.personMobId = personMobId;
    }
    this.props.upsertProfileContact(data)
      .then((res) => {
        if (res && !res.error) {
          this.setState({
            showNotif: true,
            notifType: 'success',
            notifMessage: 'Applicant phone number added successfully.',
          });
          window.location.reload();
        }
        if ((res && res.error) || !res) {
          const {
            error: {
              message = 'Error occured while saving the phone number.',
            } = {},
          } = res || {};
          this.setState({
            showNotif: true,
            notifType: 'error',
            notifMessage: message,
          });
        }
      });
  }

  handlePhoneChange = (e) => {
    this.setState({
      applicantMobileNumber: e.target.value,
    });
  }

  handleNotificationClose = () => {
    this.setState({ showNotification: false });
  }

  handleTimeChange = (e, key, type) => {
    const { slotsState } = this.state;
    const currSlot = { ...slotsState[key] };
    currSlot[type] = e;
    const changedSlot = {
      [key]: currSlot,
    };
    const newSlots = { ...slotsState, ...changedSlot };
    this.setState({
      availableSlots: newSlots,
    });
  }

  handleDeleteSlot = (key) => {
    const { availableSlots } = this.state;
    const availableSlotsKeys = (availableSlots && Object.keys(availableSlots)) || [];
    const newSlots = {};
    availableSlotsKeys.forEach((s) => {
      const slot = availableSlots[s];
      if (slot && slot.key && (slot.key !== key)) {
        newSlots[slot.key] = slot;
      }
    });
    this.setState({
      availableSlots: newSlots,
    });
  }

  handleAddSlot = () => {
    const { availableSlots } = this.state;
    const newSlots = cloneDeep(availableSlots);
    const date = new Date().toISOString();
    const now = Date.now();
    const key = btoa(`${date}${now}`);
    newSlots[key] = {
      key,
      start: null,
      end: null,
    };
    this.setState({
      availableSlots: newSlots,
    });
  }

  handleOnChangeDateTime = (dateTime, key, s) => {
    const { availableSlots } = this.state;
    const currentSlot = availableSlots[key];
    const newSlot = {};
    newSlot[key] = cloneDeep(currentSlot);
    if (newSlot && newSlot[key]) {
      newSlot[key][s] = dateTime;
      console.log('dateTime', dateTime, dateTime.toISOString(), moment.utc(dateTime).format());
      const minDiff = moment(moment()).diff(dateTime, 'm');
      const curHHmm = moment(dateTime).format('HHmm');
      if ((curHHmm === '0000')) {
        if (dateTime && minDiff > 0) {
          const min15Diff = Math.ceil(minDiff / 15) * 15;
          newSlot[key][s] = moment(dateTime).add(min15Diff, 'm').toDate();
          console.log('00000', dateTime, min15Diff);
        } else {
          const min15Diff = Math.ceil(-1 * (minDiff / 15)) * 15;
          newSlot[key][s] = moment(dateTime).add(8, 'h').toDate();
          console.log('00001', dateTime, min15Diff);
        }
      }

      if (s === 'end') {
        const startMilliSeconds = moment(newSlot[key]['start']).format('HHmm');
        const endMilliSeconds = moment(dateTime).format('HHmm');
        const mdiff = moment(endMilliSeconds, 'HHmm').diff(moment(startMilliSeconds, 'HHmm'), 'm');
        if ((endMilliSeconds - startMilliSeconds <= 0)) {
          newSlot[key].invalid = true;
          newSlot[key].invalidMsg = 'End time should be after start time';
        } else {
          newSlot[key].invalid = false;
          newSlot[key].invalidMsg = '';
        }
        if (mdiff && !isNaN(mdiff) && mdiff < 30) {
          newSlot[key].invalid = true;
          newSlot[key].invalidMsg = 'Slot duration should be at-least 30mins';
          if (mdiff < 0) {
            newSlot[key].invalidMsg = 'End time should be after start time';
          }
        }
      } else {
        const startMilliSeconds = moment(dateTime).format('HHmm');
        const endMilliSeconds = moment(newSlot[key]['end']).format('HHmm');
        const mdiff = moment(endMilliSeconds, 'HHmm').diff(moment(startMilliSeconds, 'HHmm'), 'm');
        if ((endMilliSeconds - startMilliSeconds <= 0)) {
          newSlot[key].invalid = true;
          newSlot[key].invalidMsg = 'End time should be after start time';
        } else {
          newSlot[key].invalid = false;
          newSlot[key].invalidMsg = '';
        }
        if (mdiff && !isNaN(mdiff) && mdiff < 60) {
          newSlot[key].invalid = true;
          newSlot[key].invalidMsg = 'Slot duration should be at-least 30mins';
          if (mdiff < 0) {
            newSlot[key].invalidMsg = 'End time should be after start time';
          }
        }
      }
    }
    const newSlots = { ...availableSlots, ...newSlot };
    this.setState({
      availableSlots: newSlots,
      showNotif: false,
    });
  }

  handleEventAdditionalInfo = (e) => {
    this.setState({
      eventAdditionalInfo: e.target.value,
      isAdditionalInfoInvalid: false,
      isAdditionalInfoInvalidText: ""
    });
  }

  activeOfferJobModal = () => {
    // const { canSendOffer } = this.state;
    // if (canSendOffer === true) {
    this.setState({
      offerjobModal: true,
    });
    // } else {
    //   this.setState({
    //     cantOfferjobModal: true,
    //   });
    // }
  }

  dismissOfferJobModal = () => {
    // const { canSendOffer } = this.state;
    // if (canSendOffer === true) {
    this.setState({
      offerjobModal: false,
    });
    // } else {
    //   this.setState({
    //     cantOfferjobModal: false,
    //   });
    // }
  }

  render() {
    const {
      appId, interviews = [], dismiss, show, jobId, workflows, showInModal,
      application, isCandidateFlow = false, updateParentPage, user,
      handleOpenEmailModal, handleApplicantTabClick,
      offerDetails, emailTemplates, offerAcceptedCount,
    } = this.props;
    const workflow = workflows && workflows.workflow;
    const {
      showNotification, notifType, notifMessage,
      isScheduleBtnLoading, applicantMobileNumber,
      availableSlots, isEditingSlots, showNotif,
      noSlots, eventAdditionalInfo, canSendOffer, offerjobModal,
      isAdditionalInfoInvalid, isAdditionalInfoInvalidText
    } = this.state;
    const notificationObj = {
      showNotif,
      notifType,
      notifMessage,
    };
    const {
      applicationsource: {
        sourceName,
      } = {},
    } = application || {};
    const applicationSourceName = (sourceName && (('' + sourceName).toLowerCase())) || '';
    const customEmailTemplates = emailTemplates !== null && Array.isArray(emailTemplates) ? emailTemplates.filter(({ isUserTemplate = null }) => isUserTemplate == true) : [];
    // console.log('wrok-flow-update',application, this.props.user, application);
    // console.log("---- workflowStatusUpdate = ", this.props.loading);
    return (
      <div>
        {!showInModal
          && (
            <OfferNotification
              showNotification={showNotification}
              workflow={workflow}
              notifType={notifType}
              notifMessage={notifMessage}
              application={application}
              offerDetails={offerDetails}
              handleOpenEmailModal={handleOpenEmailModal}
              user={user}
              handleApplicantTabClick={handleApplicantTabClick}
              handleNotificationClose={this.handleNotificationClose}
            />
          )}
        {showInModal && (
          <Modal
            onRequestClose={this.closeThisModal}
            open={show}
            passiveModal
            modalHeading={`${application && application.profile && application.profile.firstName} ${application && application.profile && application.profile.lastName}`}
            onRequestSubmit={() => { }}
            modalLabel="Interview Status"
          >
            <div className={`bx--modal-content__text  pl-0 ${this.props.loading ? 'text-center' : ''}`}>
              {
                !this.props.loading && workflow && (
                  <ApplicantWorkflowStages
                    appId={appId}
                    isCandidateFlow={isCandidateFlow}
                    jobId={jobId}
                    workflow={workflow}
                    offerAcceptedCount={offerAcceptedCount}
                    handleUserSubStageCompletion={this.handleUserSubStageCompletion}
                    handleReassignSubStage={this.handleReassignSubStage}
                    handleDeleteStage={this.handleDeleteStage}
                    handleSkipStage={this.handleSkipStage}
                    handleSubstageSchedule={this.handleSubstageSchedule}
                    application={application}
                    user={this.props.user}
                    notification={notificationObj}
                    onNotifClose={this.onNotifClose}
                    isScheduleBtnLoading={isScheduleBtnLoading}
                    handleAddPhone={this.handleAddPhone}
                    handlePhoneChange={this.handlePhoneChange}
                    applicantMobileNumber={applicantMobileNumber}
                    submitShare={(cstage) => this.submitShare(user, application && application.profile, application && application.job && application.job.company && application.job.company.companyName, application && application.job && application.job.jobname && application.job.jobname.nameName, cstage)}
                    availableSlots={availableSlots}
                    handleGetSlots={this.handleGetSlots}
                    isEditingSlots={isEditingSlots}
                    value={this.props.value}
                    handleOpenEmailModal={handleOpenEmailModal}
                    applicationSourceName={applicationSourceName}
                    handleDeleteSlot={this.handleDeleteSlot}
                    handleAddSlot={this.handleAddSlot}
                    noSlots={noSlots}
                    handleOnChangeDateTime={this.handleOnChangeDateTime}
                    changeEventAdditionalInfo={this.handleEventAdditionalInfo}
                    eventAdditionalInfo={eventAdditionalInfo}
                    canSendOffer={canSendOffer}
                    activeOfferJobModal={this.activeOfferJobModal}
                    dismissOfferJobModal={this.dismissOfferJobModal}
                    offerjobModal={offerjobModal}
                    isAdditionalInfoInvalid={isAdditionalInfoInvalid}
                    isAdditionalInfoInvalidText={isAdditionalInfoInvalidText}
                  // cantOfferjobModal={cantOfferjobModal}
                  />)
              }
              {
                this.props.loading && (
                  <Loading className="d-inline-block" withOverlay={false} />
                )
              }
            </div>
          </Modal>)
        }
        {
          !showInModal && (
            <div className="bx--modal-content__text  pl-0">
              <JDProcessOverlay show={this.props.loading} message="processing..." />
              <GenericTabs
                className="xpa-cand-info--tabs"
                selected={0}
                tabContent={[
                  {
                    className: 'xpa-cand-info--int-status',
                    label: 'Status',
                    value:
                      (
                        <div>
                          {
                            workflow && application.inviteaccept &&
                            (
                              <ApplicantWorkflowStages
                                appId={appId}
                                isCandidateFlow={isCandidateFlow}
                                jobId={jobId}
                                user={this.props.user}
                                offerAcceptedCount={offerAcceptedCount}
                                workflow={workflow}
                                handleUserSubStageCompletion={this.handleUserSubStageCompletion}
                                handleReassignSubStage={this.handleReassignSubStage}
                                handleDeleteStage={this.handleDeleteStage}
                                handleSkipStage={this.handleSkipStage}
                                handleSubstageSchedule={this.handleSubstageSchedule}
                                application={application}
                                updateParentPage={updateParentPage}
                                notification={notificationObj}
                                onNotifClose={this.onNotifClose}
                                isScheduleBtnLoading={isScheduleBtnLoading}
                                handleAddPhone={this.handleAddPhone}
                                handlePhoneChange={this.handlePhoneChange}
                                applicantMobileNumber={applicantMobileNumber}
                                submitShare={(cstage) => this.submitShare(user, application && application.profile, application && application.job && application.job.company && application.job.company.companyName, application && application.job && application.job.jobname && application.job.jobname.nameName, cstage)}
                                availableSlots={availableSlots}
                                onChangeTime={(e, key, type) => this.handleTimeChange(e, key, type)}
                                handleGetSlots={this.handleGetSlots}
                                isEditingSlots={isEditingSlots}
                                value={this.props.value}
                                handleOpenEmailModal={handleOpenEmailModal}
                                applicationSourceName={applicationSourceName}
                                handleDeleteSlot={this.handleDeleteSlot}
                                handleAddSlot={this.handleAddSlot}
                                noSlots={noSlots}
                                handleOnChangeDateTime={this.handleOnChangeDateTime}
                                changeEventAdditionalInfo={this.handleEventAdditionalInfo}
                                eventAdditionalInfo={eventAdditionalInfo}
                                canSendOffer={canSendOffer}
                                activeOfferJobModal={this.activeOfferJobModal}
                                dismissOfferJobModal={this.dismissOfferJobModal}
                                offerjobModal={offerjobModal}
                                isAdditionalInfoInvalid={isAdditionalInfoInvalid}
                                isAdditionalInfoInvalidText={isAdditionalInfoInvalidText}
                              // cantOfferjobModal={cantOfferjobModal}
                              />
                            )
                          }
                          {
                            workflow && !application.inviteaccept && (
                              <div className="text-dark col-12 pt-2">This candidate is not eligible for interview setup. Interviews can be created for shortlisted candidates after candidate accepts the consent to proceed with application.</div>
                            )
                          }
                        </div>
                      ),
                    onClick: () => { }
                  },
                  // {
                  //   className: 'xpa-cand-info--int-status',
                  //   label: (<div>{
                  //     this.props.user && this.props.user.roleId
                  //       && this.props.user.roleId !== 3
                  //       && 'Edit Workflow'
                  //   }</div>),
                  //   value: (
                  //             <ApplicantWorkflowStagesEditor
                  //               workflow={workflow}
                  //               workflowStages={this.props.workflows && this.props.workflows.workflowstages}
                  //               user={this.props.user}
                  //               appId={appId}
                  //               application={application}
                  //             />
                  //         ),
                  //   onClick: () => {}
                  // },
                ]} />



            </div>)
        }
        <Modal
          onRequestClose={() => { this.setState({ openModal: false }) }}
          open={this.state.openModal}
          secondaryButtonText="Cancel"
          primaryButtonText="Submit"
          modalHeading="Skip this stage"
          onRequestSubmit={this.handleSubmitCompleteThisStage}
          modalLabel="Action"
        >
          <div>
            Are you sure you want to mark this stage as complete?
          </div>
          <div className="text-dark mt-2">
            By doing this, you will not be able to schedule interview with this candidate and the interview process will move to the decision stage
          </div>
        </Modal>
      </div>
    );
  }
}

const OfferNotification = ({
  showNotification, workflow, notifType,
  notifMessage, application, offerDetails,
  handleOpenEmailModal, user, handleApplicantTabClick,
  handleNotificationClose,
}) => {
  const {
    appId,
    jobId,
    applicationemail,
    isHired,
    offerConsentSent,
    offerConsentAccept,
    collaboration,
    applicationsource: {
      sourceName,
    } = {},
  } = application || {};
  const applicationSourceName = (sourceName && (('' + sourceName).toLowerCase())) || '';
  const sendOfferConsentArr = applicationemail && Array.isArray(applicationemail)
    && applicationemail.filter((e) => e.templateName === 'recruiter-sends-consent');
  const rejectOfferConsentArr = applicationemail && Array.isArray(applicationemail)
    && applicationemail.filter((e) => e.templateName === 'recruiter-rejects-interview');
  const acceptedStates = [6000, 6002, 6005];
  const rejectedStates = [6001, 6003, 6004];
  return (
    <div>
      {
        showNotification
        && workflow && workflow.outcomeId
        && (
          <InlineNotification lowContrast
            kind={notifType}
            subtitle=""
            title={notifMessage}
            iconDescription="describes the close button"
            onCloseButtonClick={handleNotificationClose}
          />
        )
      }
      {/* {
        workflow && workflow.outcomeId
        && (rejectedStates.includes(Number(workflow && workflow.outcomeId)))
        && rejectOfferConsentArr && Array.isArray(rejectOfferConsentArr)
        && rejectOfferConsentArr.length <= 0
        && offerConsentSent === null
        && offerConsentAccept === null
        && (
          <Button
            kind="danger"
            className="mt-2"
            small
            onClick={() => handleOpenEmailModal({
              name: 'recruiter-reject-interview',
              template: 'recruiter-rejects-interview',
              source: applicationSourceName,
              appId,
              jobId,
            })}
          >
            Reject Email
          </Button>
        )
      } */}
      {/* {
        workflow && workflow.outcomeId
        && (acceptedStates.includes(Number(workflow && workflow.outcomeId)))
          && (
            <div>
              <div>
                {
                  sendOfferConsentArr && Array.isArray(sendOfferConsentArr)
                    && sendOfferConsentArr.length <= 0
                    && offerConsentSent === null
                      && (
                        <Button
                          className="mt-2"
                          small
                          disabled={!(isCurrentUserAdminLevelCollaborator(user, collaboration))}
                          kind="tertiary"
                          onClick={() => handleOpenEmailModal({
                            name: 'recruiter-sends-consent',
                            template: 'recruiter-sends-consent',
                            source: applicationSourceName,
                            appId,
                            jobId,
                          })}
                        >
                          Send Offer Consent
                        </Button>
                      )
                }
                {
                  workflow && workflow.outcomeId
                  && (acceptedStates.includes(Number(workflow && workflow.outcomeId)))
                  && sendOfferConsentArr && Array.isArray(sendOfferConsentArr)
                  && sendOfferConsentArr.length > 0
                  && offerConsentSent === true
                  && (offerConsentAccept === null || offerConsentAccept === false)
                  && (
                    <Button
                      className="mt-2"
                      small
                      disabled={!(isCurrentUserAdminLevelCollaborator(user, collaboration))}
                      kind="tertiary"
                      onClick={() => handleOpenEmailModal({
                        name: 'recruiter-sends-consent',
                        template: 'recruiter-sends-consent',
                        source: applicationSourceName,
                        appId,
                        jobId,
                      })}
                    >
                      Resend Offer Consent
                    </Button>
                  )
                }
                {
                offerConsentSent === true
                  && offerConsentAccept === true
                  && isHired === nullofferAcceptedCount
                  && (
                    <Button
                      className="mt-2"
                      kind="tertiary"
                      small
                      onClick={() => handleApplicantTabClick('applicant_offer')}
                    >
                      Manage Offers
                    </Button>
                  )
              }
              </div>
            </div>
          )
      } */}
    </div>
  );
};

WorkflowStatusUpdateModal.propTypes = {
  appId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  jobId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = state => ({
  loading: state.workflows.loading,
  user: state.x0paDuser.user,
  workflows: state.workflows,
  offerDetails: state.OfferInformation.offerDetails,
  emailTemplates: state.emailTemplates.templates,
});

const mapDispatchToProps = {
  getWorkflowStages,
  createWorkflow,
  getApplicationWorkflow,
  cloneWorkflow,
  updateSubStage,
  updateApplicationSubStage,
  assignSubStageToUser,
  deleteCurrUser,
  sendReminder,
  updateStageConfigs,
  skipStage,
  scheduleStage,
  getAvailabilitySlots,
  upsertProfileContact,
  getCustomEmailTemplates,
  sendEmails,
  manuallyScheduleStage,
  createOfferDetails,
  getAssetAnalytics,
  sendEmailTemplate,
  getJobOfferDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowStatusUpdateModal);
