import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Tile, ModalWrapper, Loading, InlineNotification,
  SkeletonText, Accordion, AccordionItem, AccordionSkeleton, InlineLoading, Button, TextArea,
} from 'carbon-components-react';
import StarRatingComponent from 'react-star-rating-component';
// import { Icon } from 'carbon-icons';
import { throttle } from 'lodash';
import moment from 'moment';

import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import BackRow from 'components/common/BackRow/BackRow';
import { withTranslation } from 'react-i18next';
// import VideoIndexer from 'components/roboroy/videoIndexer';

// import CreateAnInterviewModal from './createInterview';

import {
  getAnInterview,
  getInterviewURL,
  inviteCandidate,
  candidateAnswers,
  getInterviewStatus,
  fetchVideoInterviewDetails,
  videoInterviewRating,
  getVideoInterviewEmotions,
} from 'actions/videoInterview';

import './interview.css';

const RRQuestionsIframe = ({ url }) => (
  <div className="mt-3">
    <div id="rr-loader">
      <Loading withOverlay={false} />
    </div>
    <div id="rr-iframe-wrap" className="invisible">
      <iframe title="RecRight Iframe" id="rr-iframe" height="300" className="video-iframe"
        allow="camera;microphone" src={url}>
      </iframe>
    </div>
  </div>
);

const RRAnswersIframe = ({
  answers = {}, inviteSent, candidateResponse=[],
  handleChange, handleStarClick, handleRatingSubmit, state,
  summarizedInsights,
}) => {
  const { videos = [], loading, error, } = answers;
  return (
    <div className="bx--row mt-3">
      {loading ? <AccordionSkeleton/>
        : (
          <Accordion className="xpa-video--answers pl-2 pr-2">
            {
              videos.map((video, vid) => {
                const {
                  question: {
                    title = 'No title set for video question',
                    textQuestion,
                    videoUrl,
                  } = {},
                  answers: candidateAnswers = [],
                } = video;
                const isVideoQuestion = videoUrl ? true : false;
                const interviewQuestion = isVideoQuestion ? title : textQuestion ;
                return (
                  <AccordionItem className="full-width" title={
                    (
                      <div className="bx--row">
                            {isVideoQuestion &&  (
                              <div className="bx--col-xs-2 text-center  pt-2" style={{background: "#F3F4F5"}} >                       
                                <video controls key={video.id} width="150px">
                                  <source src={video.question.videoUrl} type="video/mp4"></source>
                                  <p>Your browser doesn't support HTML5 video. Here is a 
                                    <a href={video.question.videoUrl}>link to the video</a> 
                                      instead.
                                  </p>
                                </video>
                              </div>
                            )}         
                            {!isVideoQuestion &&  (
                              <div className="bx--col-xs-2 text-center  pt-2" style={{background: "#F3F4F5"}} >                       
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="img40_40" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video-off" ><path d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                              </div>
                            )}                              
                            <div className="bx--col-xs-10 text-left  pt-2">
                              <div className="bx--type-zeta">{interviewQuestion}</div>
                              {(candidateAnswers.length > 0 && candidateResponse[vid]) && (
                                <div>
                                  <StarRatingComponent
                                      name={`video-rating-dummy-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`}
                                      starCount={5}
                                      editing={false}    
                                      value={state[`video-rating-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`] || (candidateAnswers && candidateResponse[vid] && candidateResponse[vid].rating)}
                                      starColor="#ffb400"
                                      emptyStarColor="#ddd"
                                      renderStarIcon={(index, value) => {
                                        return (
                                          <span>
                                            <i className={index <= value ? 'fa fa-star' : 'fa fa-star'} />
                                          </span>
                                        );
                                      }}
                                      renderStarIconHalf={() => {
                                        return (
                                          <span>
                                            <span style={{ position: 'absolute' }}><i className="fa fa-star" /></span>
                                            <span><i className="fa fa-star-half" /></span>
                                          </span>
                                        );
                                      }}
                                      editing
                                    /> 
                                  <div>
                                    <small className="text-dark">
                                      {state.hasOwnProperty(`video-comments-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`) ? state[`video-comments-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`] : (candidateAnswers && candidateResponse[vid] && candidateResponse[vid].comments || '')}
                                    </small>
                                  </div>
                                </div>
                                )}                        
                            </div>                                     

                      </div>
                    )
                    }>
                    <div className="">
                      {
                        (candidateAnswers.length > 0) && (
                          <div className="">
                            <div className="d-lg-flex">
                              <video controls key={video.id}>
                                <source src={video.answers[0].videoUrl} type="video/mp4"></source>
                                <p>Your browser doesn't support HTML5 video. Here is
                              a <a href={video.answers[0].videoUrl}>link to the video</a> instead.</p>
                              </video>
                              {(candidateAnswers && candidateResponse[vid])
                                && (
                                <div className="ml-lg-5">
                                  <strong className="">Rating</strong>
                                  <div>
                                    <StarRatingComponent
                                      name={`video-rating-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`}
                                      starCount={5}
                                      value={state[`video-rating-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`] || (candidateAnswers && candidateResponse[vid] && candidateResponse[vid].rating)}
                                      starColor="#ffb400"
                                      emptyStarColor="#ddd"
                                      onStarClick={(rating) => handleStarClick(rating, `video-rating-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`)}
                                      renderStarIcon={(index, value) => {
                                        return (
                                          <span>
                                            <i className={index <= value ? 'fa fa-star' : 'fa fa-star'} />
                                          </span>
                                        );
                                      }}
                                      renderStarIconHalf={() => {
                                        return (
                                          <span>
                                            <span style={{ position: 'absolute' }}><i className="fa fa-star" /></span>
                                            <span><i className="fa fa-star-half" /></span>
                                          </span>
                                        );
                                      }}
                                      editing
                                    />
                                  </div>
                                  <hr className="mt-0" />
                                  <strong className="">Comments</strong>
                                  <TextArea
                                    className="some-class"
                                    hideLabel
                                    onChange={handleChange}
                                    placeholder="Please provide your feedback on this video response."
                                    id="comments"
                                    name={`video-comments-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`}
                                    value={state.hasOwnProperty(`video-comments-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`) ? state[`video-comments-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`] : (candidateAnswers && candidateResponse[vid] && candidateResponse[vid].comments || '')}
                                  />
                                  <Button
                                    className="mt-2"
                                    small
                                    onClick={() => handleRatingSubmit(candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId)}
                                  >
                                    Submit
                                  </Button>
                                  {(state[`video-rating-response-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`] == 'success') && (<span className="text-success bx--type-zeta pl-2">Successfully saved.</span>)}
                                  {(state[`video-rating-response-${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId || 0}`] == 'error') && (<span className="text-danger bx--type-zeta pl-2">There was an error in processing your request, please try again in few mins.</span>)}
                                </div>
                                )}
                            </div>
                            <p className="m-0 mt-2 bx--type-caption text-dark">
                              Answered on:&nbsp;
                              {moment(video.answers[0].createdAt).format('DD/MM/YYYY HH:mm')}</p>
                            {/* <VideoIndexer
                              summarizedInsights={summarizedInsights}
                              data={state[`${candidateAnswers && candidateResponse[vid] && candidateResponse[vid].questionId}_videoIndexer`]}
                            /> */}
                          </div>
                        )
                      }
                    </div>
                  </AccordionItem>
                );
              })
            }
          </Accordion>
        )
        }
      {
        (videos.length === 0) && inviteSent &&
        (<p className="text-center mt-2 text-dark ml-3">The candidate has not posted any answers yet. Once candidate responds, answer will show up in this pane.</p>)
      }
    </div>
  );
};

class InterviewQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      interview: {},
      interviewURL: '',
      showNotification: false,
      emailSent: false,
      videos: [],
      rrLoading: true,
      canSendInvite: false,
      noAnswerableQuestions: false,
      sendingInvite: false,
    };
    this.paths = [
      { id: 'roboroy', name: 'Recruiter', href: '/app/campus' },
      { id: 'interviews', name: 'Video Interviews', href: '/app/campus/interviews' },
      { id: 'interview', name: 'Video Interview', href: '' },
    ];
  }

  componentDidMount() {
    const {
      match: { params: { id } = {} } = {},
      interview: {
        rrCandidateId,
        inviteSent,
      } = {},
    } = this.props;
    this.props.getAnInterview({ interviewId: id }).then((res = {}) => {
      const { data = [] } = res;
      if (data[0]) {
        this.setState({
          interview: data[0],
        });
      }
    });
    this.props.getInterviewURL({
      interviewId: id,
    }).then((res) => {
      if (res && res.data) {
        this.setState({
          interviewURL: res.data,
        });
      }
    })
      .catch((err) => {
        console.log(err);
      });
    this.props.fetchVideoInterviewDetails(id).then((res) => {
      if (res && Array.isArray(res) && res.length > 0) {
        res.map((vals) => {
          const {
            questionId,
          } = vals || {};
          if (questionId) {
            this.props.getVideoInterviewEmotions(questionId).then((vidRes) => {
              this.setState({
                [`${questionId}_videoIndexer`]: vidRes && vidRes.data,
              });
            })
              .catch(e => console.log('Error:::::', e));
          }
        });
      }
    });
    if (id && inviteSent && rrCandidateId) {
      this.props.candidateAnswers({
        candidate: rrCandidateId,
        interview: id,
      }).then((res = {}) => {
        const { data: { videos } = {} } = res;
        if (videos) {
          this.setState({
            videos,
          });
        }
      });
    }
    window.addEventListener('message', throttle(this.adjustVideoIframeHeight, 2000), false);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleStarClick = (rating, name) => {
    this.setState({
      [name]: rating,
    });
  };

  handleRatingSubmit = (questionId) => {
    const {
      match: {
        params: {
          id,
        } = {},
      } = {},
  } = this.props;
    let key = `video-rating-response-${questionId}`;
    this.props.videoInterviewRating({
      interviewId: id,
      questionId,
      rating: this.state[`video-rating-${questionId}`],
      comments: this.state[`video-comments-${questionId}`]
    }).then((res) => {
      this.setState({[key] : 'success'}, () => {
        setTimeout(() => {
          this.setState({[key] : ''});
        },3000);
      });      
    })
    .catch((err) => {
      console.log(err);
      this.setState({[key] : 'error'}, () => {
        setTimeout(() => {
          this.setState({[key] : ''});
        },3000);
      });
    })
  }

  adjustVideoIframeHeight = (event) => {
    const { origin, data = [] } = event;
    const allowedOrigins = [
      'https://demo.recright.com',
      'https://recright.com',
      'https://www.recright.com',
    ];
    if (!allowedOrigins.includes(origin)) {
      console.error('Post Message from malicious domain');
      return false;
    }
    const rrFrameWrap = document.querySelector('#rr-iframe-wrap');
    const rrFrame = document.querySelector('#rr-iframe');
    const rrLoader = document.querySelector('#rr-loader');
    if (rrFrameWrap) {
      rrFrameWrap.classList.remove('invisible');
    }
    if (rrLoader) {
      rrLoader.hidden = true;
    }
    if (rrFrame && data[2] && data[2] !== rrFrame.clientHeight) {
      rrFrame.height = event.data[2];
    }
  }

  handleSendInvite = () => {
    const {
      interview: {
        interviewId,
        interviewName,
        email,
        firstName,
        lastName,
        preferName,
        atsJobId,
        atsCandidateId,
        publicUuid: interviewURL,
      } = {},
      match: { params: { id } = {} } = {},
    } = this.props;
    this.setState({
      sendingInvite: true,
      noAnswerableQuestions: false,
    });
    this.props.getInterviewStatus({ interview: id })
      .then(({ data } = {}) => {
        const { answerableQuestions = 0 } = data || {};
        if (answerableQuestions > 0) {
          // this.setState({
          //   canSendInvite: true,
          // });
          // const { canSendInvite } = this.state;
          // if (!canSendInvite) {
          //   this.setState({
          //     showNotification: true,
          //   });
          //   return;
          // }
          this.props.inviteCandidate({
            interviewId,
            interviewName,
            email,
            interviewURL,
            firstName,
            lastName,
            templateName: 'interview',
            preferName,
            atsJobId,
            atsCandidateId,
          })
            .then(() => {
              this.setState({
                emailSent: true,
                showNotification: true,
                sendingInvite: false,
              });
            });
        } else {
          this.setState({
            noAnswerableQuestions: true,
            sendingInvite: false,
          });
        }
      });
    return true;
  }

  handleApplicantsTabClick = (tabType) => {
    let selectedTab = 0;
    let status = tabType;
    switch (tabType) {
      case 'questions':
        selectedTab = 0;
        break;
      case 'answers':
        selectedTab = 1;
        break;
      default:
        selectedTab = 0;
        status = null;
        break;
    }
    this.setState({ selectedTab, status, keyword: null });
  }

  render() {
    const {
      match: { params: { id } = {} } = {},
      interview: {
        atsCandidateId,
        jobName,
        jobId,
        interviewName,
        firstName,
        lastName,
        preferName,
        inviteSent,
        rrCandidateId,
        status,
        answers = {},
      } = {},
      candidateResponse = [],
      summarizedInsights = {},
      t,
    } = this.props;
    const {
      emailSent, interviewURL, showNotification, noAnswerableQuestions, sendingInvite,
    } = this.state;
    let tabContent = [
      {
        label: `${t('interviewQues')}`,
        value: <Tile><RRQuestionsIframe url={interviewURL} /></Tile>,
        onClick: () => this.handleApplicantsTabClick('questions'),
      },
    ];
    if (inviteSent) {
      tabContent.push({
        label: `${t('candidateAns')}`,
        value: (
          <Tile>
            <RRAnswersIframe
              answers={answers}
              isLoading={false}
              inviteSent={inviteSent}
              candidateResponse={candidateResponse}
              handleChange={this.handleChange}
              handleStarClick={this.handleStarClick}
              handleRatingSubmit={this.handleRatingSubmit}
              state={this.state}
              summarizedInsights={summarizedInsights}
            />
          </Tile>),
        onClick: () => this.handleApplicantsTabClick('answers'),
      });
    }
    if (inviteSent && rrCandidateId) {
      tabContent = [{
        label: `${t('candidateAns')}`,
        value: <Tile><RRAnswersIframe 
          handleSentimentsTabClick={this.handleSentimentsTabClick}
          answers={answers}
          isLoading={false}
          inviteSent={inviteSent}
          candidateResponse={candidateResponse}
          handleChange={this.handleChange}
          handleStarClick={this.handleStarClick}
          handleRatingSubmit={this.handleRatingSubmit}
          summarizedInsights={summarizedInsights}
          state={this.state} /></Tile>,
        onClick: () => this.handleApplicantsTabClick('answers'),
      }];
    }

    return (
      <Fragment>
        {
          showNotification && (
          <InlineNotification lowContrast
            title={t('vidIntMsg1')}
            subtitle=""
            kind="success"
          />)
        }
        {
          noAnswerableQuestions && (
          <InlineNotification lowContrast
            title={t('vidIntMsg2')}
            subtitle=""
            kind="warning"
          />)
        }
        <BackRow paths={this.paths} />
        <h4 className="bx--type-gamma font-weight-bold text-capitalize">{t('videoInterviewsTab')}</h4>

        <Tile className="mt-3 mb-3">
          <div className="bx--row align-items-center">
            <div className="bx--col-xs-12 bx--col-md-12">
              {
                this.props.loading ? (
                  <SkeletonText  lineCount={4} paragraph />)
                  : (
                    <div className="">
                      <div className="bx--row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                          <div className="text-sm-center text-md-center text-center text-lg-left">
                            <div className="text-dark">
                              <small title="Job">{`Requisition ID: ${jobId}`}</small>
                            </div>
                            <div title="Interview Name" className="card-title h5 mb-2 d-inline-block text-center font-weight-bold text-capitalize">
                              {`${interviewName}`}
                            </div>
                            {
                              <div title="Applicant" className="text-dark card-subtitle mb-2 bx--type-zeta pt-lg-1">
                                  {t('candidate')} {t('name')}:
                                <span className="pl-1">
                                  <Link className="bx--link" to={`/app/campus/candidate/${atsCandidateId}/job/${jobId}/interviewstatus`}>{`${firstName} ${lastName}`}</Link>
                                </span>
                              </div>
                            }
                            {
                              <div title="Designation" className="text-dark card-subtitle mb-2 bx--type-zeta pt-lg-1">
                                  {t('job')} {t('title')}:
                                <span className="pl-1">
                                  <Link className="bx--link" to={`/app/campus/job/${jobId}`}>{jobName}</Link>
                                </span>
                              </div>
                            }
                            {
                              <div title="Status" className="text-dark card-subtitle mb-1 bx--type-zeta pt-lg-1">
                                <span>
                                  {t('status')}:
                                  <span className="pl-1">{(inviteSent || emailSent) ? `${t('invitationSent')}` : `${t('invitationNotSent')}`}</span>
                                </span>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              }
            </div>
            <div className="bx--col-xs-12 bx--col-md-12 ml-1">
              {
                !emailSent && !inviteSent && !this.props.loading && !sendingInvite && (!status || (status && status !== 'Skipped')) && (
                <ModalWrapper
                  buttonTriggerClassName="mt-sm-2"
                  buttonTriggerText="Send Interview Invite"
                  modalHeading={t('vidIntMsg3')}
                  primaryButtonText={t('send')}
                  secondaryButtonText={t('cancel')}
                  handleSubmit={this.handleSendInvite}
                  shouldCloseAfterSubmit
                  disabled={sendingInvite}
                >
                  <div className="bx--modal-content__text text-left">
                    {t('vidIntMsg4')}
                    <br />
                    <ul>
                      <li>{t('candidate')}: <strong>{firstName} {lastName}</strong></li>
                      <li>{t('interview')}: <strong>{interviewName}</strong></li>
                    </ul>
                    {t('vidIntMsg5')}
                    <strong> {t('vidIntMsg6')}</strong>
                  </div>
                </ModalWrapper>)
              }
              {
                sendingInvite && (
                  <Button
                    className="mt-sm-2"
                    small
                  >
                    <InlineLoading className="justify-content-center x-btn-loader" description={t('sendingInvite')} />
                  </Button>
                )
              }
            </div>
          </div>
        </Tile>
        <div>
          <GenericTabs
            selected={this.state.selectedTab}
            tabContent={tabContent}
          />
        </div>
      </Fragment>);
  }
}

const mapStateToProps = state => ({
  loading: state.videoInterviews.loading,
  user: state.x0paDuser.user,
  interview: state.videoInterviews.interview,
  candidateResponse: state.videoInterviews.candidateResponse,
  summarizedInsights: state.videoInterviews.summarizedInsights,
});

const mapDispatchToProps = {
  getInterviewURL,
  getAnInterview,
  inviteCandidate,
  candidateAnswers,
  getInterviewStatus,
  fetchVideoInterviewDetails,
  videoInterviewRating,
  getVideoInterviewEmotions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InterviewQuestions));
