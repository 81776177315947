import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SetMixPanel from 'components/common/GetCurrentPath';
import ApplicantCard from 'components/roboroy/common/ApplicantCard';
import XopaModal from 'components/roboroy/XopaModal';
import { AISourcedWithNoEmail, AISourcedHelpInfo, AISourcedEmailMissingWarning } from 'components/common/atoms';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import { Pagination, Button, TooltipIcon, SkeletonText, Select, SelectItem, SelectItemGroup, ToolbarItem, Tooltip } from 'carbon-components-react';
import {
  getCurrentStageInProgress,
  getCurrentSubStageInProgress
} from 'components/roboroy/WorkflowModule/workflowUtils';
import ApplicantsTabs from 'components/common/ApplicantTabs';
import FilterApplications from 'components/roboroy/FilterApplications';
import WorkflowAction from 'components/roboroy/WorkflowModule/workflowAction';
import ApplicantSort from 'components/common/ApplicantSort';
import FilterData from 'containers/roboroy/FilterData';
import {
  Layout,
  PagingInfo,
  Result,
  SearchBox,
  Facets,
  MultiCheckboxFacet,
  SingleSelectFacet,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import {
  getStudentAppData
} from 'actions/talentSearch/candidatesList';
import { connect } from 'react-redux';
import './XopaTable.css'
import { getDocumentByIds } from 'actions/document';

const NoApplicantBox = ({ t }) => (
  <div className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">{t('noApplicationsMsg')}</p>
    </div>
  </div>
);

const columnInfo = [
  {
    title: 'Name',
    className: 'text-align__left p-2',
    name: 'name',
    propName: 'profile.firstName',
    colSpan: 1,
  },
];

const track = {
  APPLICATION_TITLE_CLICKED: 'APPLICATION_TITLE_CLICKED',
  APPLICATION_CANDIDATE_INTERVIEWSTATUS: 'APPLICATION_CANDIDATE_INTERVIEWSTATUS',
}

class XopaTable extends Component {

  state = {
    activeModal: false,
    xopaScores: this.props.xopaScores || [],
    handleCandidateShortlist: this.props.handleCandidateShortlist,
    activeColumn: '',
    bulkselected: [],
  };

  sortBy = (prop) => {
    const xopaScores = this.state.xopaScores.sort((a, b) => (a[prop] || 0) - (b[prop] || 0));

    if (this.sort[prop]) {
      xopaScores.reverse();
      this.sort[prop] = false;
    } else {
      this.sort[prop] = true;
    }

    this.setState({
      xopaScores,
    });
  };

  sort = {
    hireability: true,
    acceptability: true,
    loyality: true,
    performance: true,
    cv_relevance: true,
  };

  activateModal = (c, e) => {
    if (
      e.target.className.includes('check') ||
      e.target.className.includes('times') ||
      e.target.className.includes('fa')
    ) {
      return;
    }
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  thOnClick = (data, index, element) => {
    const { target: { title } } = element;
    this.setState({
      activeColumn: title
    })
    this.props.sortClicked('qsValuesForSort', data, index, element);
  };

  applicantInfo = (c, history, skeletonObj) => {
    return (<div>
      {!skeletonObj ? (<div>
        <Link
          className="card-title  mb-1 d-inline-block text-center font-weight-b1old bx--type-epsilon xpa-link text-capitalize"
          to={`/app/campus/candidate/${c.appId}/job/${c && c.job && c.job.jobId}`}
          onClick={({ target = {} }) => {
            SetMixPanel(track.APPLICATION_TITLE_CLICKED);
            return history.push(`/app/campus/candidate/${c.appId}/job/${c && c.job && c.job.jobId}/candidateprofile`);
          }
          }>
          {c && c.profile && c.profile.firstName}&nbsp;
      {c && c.profile && c.profile.lastName}
          {(!c) || (!c.profile) || ((!c.profile.firstName) && (!c.profile.lastName)) && 'Name Unidentified'}
        </Link>
        &nbsp;{c && c.profile && c.isInTalentPool && (
          <span className="d-inline-block xpa-pos--top-5px">
            <TooltipIcon tooltipText="Talent pool candidate" >
              <svg width="20" height="20" fill-rule="evenodd"><path d="M6 15.745A6.968 6.968 0 0 0 10 17a6.968 6.968 0 0 0 4-1.255V15.5a2.5 2.5 0 0 0-2.5-2.5h-3A2.5 2.5 0 0 0 6 15.5v.245zm-.956-.802A3.5 3.5 0 0 1 8.5 12h3a3.5 3.5 0 0 1 3.456 2.943 7 7 0 1 0-9.912 0zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16z" fill-rule="nonzero"></path><path d="M10 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill-rule="nonzero"></path></svg>
            </TooltipIcon>
          </span>
        )}
        {c && c.isLiked && (
          <span className="d-inline-block xpa-pos--top-5px">
            <TooltipIcon tooltipText="Favourite candidate">
              <svg width="20" height="20" fill-rule="evenodd">
                <path d="M8.109 11.75l-4.5 3.2c-.2.1-.5 0-.4-.3l1.5-5.1-4.6-3.2c-.2-.2-.1-.5.1-.5l5.5-.5 2-5.2c.1-.2.4-.2.5 0l2 5.2 5.5.5c.2 0 .3.3.1.4l-4.5 3.3 1.5 5.1c.1.2-.2.4-.4.3l-4.3-3.2z">
                </path>
              </svg>
            </TooltipIcon>
          </span>
        )}
        {c && c.isHired && (
          <span title="This candidate is hired" className="d-inline-block mt-2 badge text-success">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smile"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
          </span>
        )}
        {c && c.isHired === false && (
          <span title="This candidate is not hired" className="d-inline-block mt-2 badge text-danger ">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-frown"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
          </span>
        )}
        {c && (c.applicationsource)
          && (c.applicationsource.sourceName)
          && (c.applicationsource.sourceName.toString() && c.applicationsource.sourceName.toLowerCase() === 'ai sourcing')
          && (
            <span className="badge badge-info d-inline-block">
              AI Sourced
            </span>
          )
        }
        {c && (c.applicationsource) && (c.applicationsource.sourceName) && (c.applicationsource.sourceName.toString() && c.applicationsource.sourceName.toLowerCase() === 'ai sourcing') ? (
          <span className="position-relative ml-1" style={{ top: "0.25rem" }}>
            <AISourcedHelpInfo />
          </span>
        ) : null}

      </div>) : (<div className="mb-0">
        <SkeletonText />
      </div>)}
      {!skeletonObj ? (<div>{c.job && (
        <small className="border-0 text-small  ml-0 pl-0  d-block text-left">
          <span className="xpa-fixed-width--S d-inline-block">Applied for</span>&nbsp;:&nbsp;&nbsp;&nbsp;
          <Link to={`/app/campus/job/${c.job.jobId}`}>
            {c.job.jobname.nameName}
          </Link>
        </small>)}</div>) : (<div>
          <div className="">
            <small className="xpa-fixed-width--S d-inline-block">Applied for</small>&nbsp;:&nbsp;&nbsp;&nbsp;
            <SkeletonText width="80px" className="m-0 d-inline-block" />
          </div>
        </div>)}
      {/* {!skeletonObj && c.stage && (<small className="border-0 text-small  ml-0 pl-0  d-block text-left"><span className="xpa-fixed-width--S d-inline-block">Stage</span>&nbsp;:&nbsp;&nbsp;&nbsp;{c.stage}</small>)} */}
      {!skeletonObj ? (
        <div>
          {c && c.profile && c.profile.totalExp && (<small className="border-0 text-small  ml-0 pl-0  d-block text-left"><span className="xpa-fixed-width--S d-inline-block">Experience</span>&nbsp;:&nbsp;&nbsp;&nbsp;{Math.round(c.profile.totalExp)} {Math.round(c.profile.totalExp) > 1 ? 'years' : 'year'}</small>)}
        </div>
      ) : (<div>
        <div className="border-0 text-small  ml-0 pl-0  d-block text-left">
          <small className="xpa-fixed-width--S d-inline-block">Experience</small>&nbsp;:&nbsp;&nbsp;&nbsp;
        <SkeletonText width="80px" className="m-0 d-inline-block" />
        </div>
      </div>)}

      {c && c.profile && c.profile.salary && !skeletonObj && (<small className="border-0 text-small  ml-0 pl-0  d-block text-left"><span className="xpa-fixed-width--S d-inline-block">Salary</span>&nbsp;:&nbsp;&nbsp;&nbsp;{c.profile.salary}</small>)}
      {!skeletonObj ? (
        <div>
          {c && c.location && (<small className="border-0 text-small  ml-0 pl-0  d-block text-left"><span className="xpa-fixed-width--S d-inline-block">Location</span>&nbsp;:&nbsp;&nbsp;&nbsp;{c.location}</small>)}
        </div>
      ) : (<div>
        <div className="border-0 text-small  ml-0 pl-0  d-block text-left">
          <small className="xpa-fixed-width--S d-inline-block">Location</small>&nbsp;:&nbsp;&nbsp;&nbsp;
          <SkeletonText width="80px" className="m-0 d-inline-block" />
        </div>
      </div>)}
      <hr className="mt-1 mb-1 p-0" />
      {!skeletonObj ? (<div>
        {c.hasOwnProperty('roboroyShortlist') && (c.roboroyShortlist === true) && (
          <div>
            <div className="">
              <span aria-hidden="true" className="fa fa-thumbs-up text-success" />
              <small className="text-dark ml-1">Roboroy upvotes this candidate</small>
            </div>
          </div>
        )}
        {c.hasOwnProperty('roboroyShortlist') && (c.roboroyShortlist === false) && (
          <div>
            <div className="">
              <span aria-hidden="true" className="fa fa-thumbs-down text-danger" />
              <small className="text-dark ml-1">Roboroy downvotes this candidate</small>
            </div>
          </div>
        )}
      </div>) : (<div>
        <small className="text-dark">
          <SkeletonText />
        </small>
      </div>)}
      {!skeletonObj ? (<div>
        {c.hasOwnProperty('roboroyShortlist') && (c.applicationsource)
          && (c.applicationsource.sourceName)
          && (c.applicationsource.sourceName.toString() && c.applicationsource.sourceName.toLowerCase() === 'recruiter')
          && (
            <div>
              <div className="">
                <span aria-hidden="true" className="fa fa-upload text-dark" />
                <small className="text-dark ml-1">Resume uploaded by recruiter.</small>
              </div>
            </div>
          )}
        {c.hasOwnProperty('roboroyShortlist') && (c.applicationsource)
          && (c.applicationsource.sourceName)
          && (c.applicationsource.sourceName.toString() && c.applicationsource.sourceName.toLowerCase() === 'candidate')
          && (
            <div>
              <div className="">
                <span aria-hidden="true" className="fa fa-id-badge text-dark" />
                <small className="text-dark ml-1">Resume uploaded by candidate.</small>
              </div>
            </div>
          )}
        {c.hasOwnProperty('roboroyShortlist') && (c.applicationsource)
          && (c.applicationsource.sourceName)
          && (c.applicationsource.sourceName.toString() && c.applicationsource.sourceName.toLowerCase() === 'ai sourcing')
          && (
            <div>
              <div className="">
                <span aria-hidden="true" className="fa fa-upload text-dark" />
                <small className="text-dark ml-1">Sourced through X0PA AI sourcing</small>
              </div>
            </div>
          )}
      </div>) : (<div>
        <div className="">
          <span aria-hidden="true" className="fa fa-upload text-dark" />
          <small className="text-dark ml-1">Resume uploaded by recruiter.</small>
        </div>
      </div>)}
      {!skeletonObj ? (<div>
        <div className={`${c.inviteaccept}`}>
          {c.hasOwnProperty('shortlisted') && c.shortlisted && (
            <div>
              <span className="badge badge-outline-success mr-2">
                Shortlisted
            </span>
              {c.hasOwnProperty('inviteaccept') && (c.inviteaccept == true) && (
                <span className="badge badge-success mr-2">
                  Invite Accepted
                </span>
              )}
              {c.hasOwnProperty('offerConsentSent')
                && c.hasOwnProperty('offerConsentAccept')
                && (c.offerConsentSent === true)
                && (c.offerConsentAccept === null)
                && (
                  <span className="badge badge-outline-warning">
                    Awaiting offer consent
                  </span>
                )
              }
              {/* {c.hasOwnProperty('offerConsentSent')
                && c.hasOwnProperty('offerConsentAccept')
                && (c.offerConsentSent === true)
                && (c.offerConsentAccept === true)
                && (
                  <div className="badge badge-success">
                    Offer consent accepted
                  </div>
                )
              } */}
              {c.hasOwnProperty('offerConsentSent')
                && c.hasOwnProperty('offerConsentAccept')
                && (c.offerConsentSent === true)
                && (c.offerConsentAccept === false)
                && (
                  <span className="badge badge-danger">
                    Offer consent rejected
                  </span>
                )
              }
              {c.hasOwnProperty('offerConsentSent')
                && c.hasOwnProperty('offerConsentAccept')
                && (c.offerConsentSent === false)
                && (c.offerConsentAccept === null)
                && (
                  <span className="badge badge-danger">
                    Interview round reject
                  </span>
                )
              }
              {c.hasOwnProperty('inviteaccept') && (c.invitesent === true)
                && (c.inviteaccept === null) && (!c.fromApplicant) && (
                  <span className="badge badge-warning">
                    Awaiting email acceptance
                  </span>
                )}
              {c.hasOwnProperty('inviteaccept') && (c.inviteaccept == false) && (
                <span className="badge badge-danger">
                  Invite Rejected
                </span>
              )}
            </div>
          )}
          {c.hasOwnProperty('shortlisted') && (c.shortlisted === false) && (
            <div>
              <div className="badge badge-outline-danger ">
                Rejected
            </div>
            </div>
          )}
          {!c.hasOwnProperty('shortlisted') || (c.shortlisted === null) && (
            <div>
              <div className="badge badge-outline-secondary">
                Screening
            </div>
            </div>
          )}
        </div>
      </div>) : (<div className="badge badge-outline-secondary">
        Screening
      </div>)}
      {c && c.profile && !c.profile.email ? (
        <AISourcedEmailMissingWarning />
      ) : (<span></span>)}

    </div>)
  }

  handleExportStudentApps = () => {
    this.props.getStudentAppData()
  };

  onDownloadClick = () => {
    const {
      applicationDocs,
    } = this.props;
    let personDocIds = []
    if (applicationDocs && Array.isArray(applicationDocs) && applicationDocs.length > 0) {
      applicationDocs.forEach((res) => {
        let personDocId = res && res.personDocId
        personDocIds.push(personDocId)
      })
    }
    this.props.getDocumentByIds(personDocIds).then((res) => {
      if (res && !res.error) {
        let resptype = res && res.resp && res.resp.type
        let objectResp = res && res.resp
        if (resptype === 'application/pdf') {
          const c = URL.createObjectURL(objectResp);
          this.setState({
            loading: false,
            cv: c,
          });
        } else {
          const a = document.createElement('a');
          const url = URL.createObjectURL(objectResp);
          a.href = url;
          let fileName = `Document-${Date.now()}`;
          if (resptype == 'application/msword') {
            fileName = `Document-${Date.now()}.doc`;
          }
          if (resptype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            fileName = `Document-${Date.now()}.docx`;
          }
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            this.setState({
              loading: false,
              downloaded: true,
              documentName: fileName,
              cv: null,
            });
          }, 100);
        }
      } else {
        this.setState({
          loading: false,
          error: true,
        });
      }
    })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  // Optimize rerender ==> XopaRow
  render() {
    const { activeColumn } = this.state;
    const { xopaScores, history, minMax, taskStatus,
      handleSearchSubmit, columnSortJobList, handleWorkflowSetup,
      handleWorkflowStatusUpdate, batchActionClickHandler, handleRefreshScores,
      selectedTab, handleOpenEmailModal, onViewEmail, activeSendOfferModal,
      onSortValueChange, jobDetails,
      match: {
        params: { id, status:paramsStatus },
      },
      location: {
        pathname
      },
      user: {
        isSuperAdmin,
        isAdmin,
        company: {
          ctypeId,
        } = {},
      } = {},
      applTitles,
      activePage,
      jobId,
      itemsPerPage,
      status,
      searchKey,
      params,
      search,
      statusSearch,
      count,
      t,
      sortedApplicant,
      handlePaginationChange,
      uploadedFilesLength
    } = this.props;


    let batchActions = [
      {
        label: `${t('favorite')}`,
        key: "is_liked",
        icon: {
          id: "icon--favorite",
          name: "icon--favorite",
          tags: "icon--favorite",
          styles: "",
          viewBox: "0 0 16 15",
          width: "16",
          height: "15",
          svgData: {
            circles: "",
            ellipses: "",
            paths: [
              {
                d: "M8.109 11.75l-4.5 3.2c-.2.1-.5 0-.4-.3l1.5-5.1-4.6-3.2c-.2-.2-.1-.5.1-.5l5.5-.5 2-5.2c.1-.2.4-.2.5 0l2 5.2 5.5.5c.2 0 .3.3.1.4l-4.5 3.3 1.5 5.1c.1.2-.2.4-.4.3l-4.3-3.2z"
              }
            ],
            polygons: "",
            polylines: "",
            rects: ""
          }
        }
      },
      {
        label: `${t('shortlist')}`,
        key: "shortlist",
        icon: {
          id: "icon--checkmark--outline",
          name: "icon--checkmark--outline",
          tags: "icon--checkmark--outline",
          styles: "",
          viewBox: "0 0 16 16",
          width: "16",
          height: "16",
          svgData: {
            circles: "",
            ellipses: "",
            paths: [
              {
                d: "M8 14.5c3.6 0 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
              },
              {
                d: "M11.3 4.7l1.4 1.4L6.8 12 3.3 8.6l1.4-1.4 2.1 2z"
              }
            ],
            polygons: "",
            polylines: "",
            rects: ""
          }
        }
      },
      // {
      //   label: "Add to my Talent Pool",
      //   key: "talentpool",
      //   icon: {
      //     id: "icon--user",
      //     name: "icon--user",
      //     tags: "icon--user",
      //     styles: "",
      //     viewBox: "0 0 16 16",
      //     width: "16",
      //     height: "16",
      //     svgData: {
      //       circles: "",
      //       ellipses: "",
      //       paths: [
      //         {
      //           d: "M4 13.7c1.1.8 2.5 1.3 4 1.3s2.9-.5 4-1.3v-1.2c0-.8-.7-1.5-1.5-1.5h-5c-.8 0-1.5.7-1.5 1.5v1.2zm-1-.8v-.4C3 11.1 4.1 10 5.5 10h5c1.4 0 2.5 1.1 2.5 2.5v.4c1.2-1.3 2-3 2-4.9 0-3.9-3.1-7-7-7S1 4.1 1 8c0 1.9.8 3.6 2 4.9zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
      //         },
      //         {
      //           d: "M8 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 1C6.3 9 5 7.7 5 6s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
      //         }
      //       ],
      //       polygons: "",
      //       polylines: "",
      //       rects: ""
      //     }
      //   }
      // },
      {
        label: `${t('send')} ${t('email')}`,
        key: 'email',
        icon: {
          id: "icon--email",
          name: "icon--email",
          tags: "icon--email",
          styles: "",
          viewBox: "0 0 16 11",
          width: "16",
          height: "11",
          svgData: {
            circles: '',
            ellipses: '',
            paths: [
              {
                d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                'fill-rule': 'nonzero',
              },
            ],
            polygons: "",
            polylines: "",
            rects: ""
          }
        }
      },
    ]

    if(id!==null && id!==undefined && paramsStatus==="applied" && isSuperAdmin===false && ctypeId==2){
      batchActions.push({
        label: `${t('Transfer')}`,
        key: "transfer",
        icon: {
          id: "icon--transfer",
          name: "icon--transfer",
          tags: "icon--transfer",
          styles: "",
          viewBox: "0 0 16 15",
          width: "16",
          height: "15",
          svgData: {
            circles: "",
            ellipses: "",
            paths: [
              {
                d: "M8.109 11.75l-4.5 3.2c-.2.1-.5 0-.4-.3l1.5-5.1-4.6-3.2c-.2-.2-.1-.5.1-.5l5.5-.5 2-5.2c.1-.2.4-.2.5 0l2 5.2 5.5.5c.2 0 .3.3.1.4l-4.5 3.3 1.5 5.1c.1.2-.2.4-.4.3l-4.3-3.2z"
              }
            ],
            polygons: "",
            polylines: "",
            rects: ""
          }
        }
      })
    }
    

    if (ctypeId == 2) {
      batchActions.push({
        label: `${t('reject')}`,
        key: "reject",
        icon: {
          id: "icon--close--outline",
          name: "icon--close--outline",
          tags: "icon--close--outline",
          styles: "",
          viewBox: "0 0 16 16",
          width: "16",
          height: "16",
          svgData: {
            circles: "",
            ellipses: "",
            paths: [
              {
                d: "M8 14.5c3.6 0 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
              },
              {
                d: "M8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6z"
              }
            ],
            polygons: "",
            polylines: "",
            rects: ""
          }
        }
      })
    }
    const getCols = (c, skeletonObj = false) => {
      let currentStage = c.workflow && getCurrentStageInProgress(c.workflow);
      let currentSubStage;
      if (currentStage) {
        currentSubStage = getCurrentSubStageInProgress(currentStage.stage);
        // console.log("---- currentSubStage = ", currentSubStage, currentStage);
      }
      const {job}=c||{}
      const {company,jobId}=job||{};
      const {companyId}=company||{};
      return {
        isExpanded: false,
        id: `row_${c.appId}_${c && c.profile && c.profile.profileId}_${(!(c && c.profile && c.profile.email) || (c && (c.isActive === false))) ? true : false}_${companyId}_${jobId}`,
        // "header_0": (skeletonObj ? <SkeletonText /> : c.appId),
        header_0: (
          <div>
            {/* {this.applicantInfo(c, history, skeletonObj)} */}
            <ApplicantCard
              application={c}
              history={history}
              isApplicantInfo={false}
              skeleton={skeletonObj ? true : false}
            />
            {c && c.profile && c.profile.email && !(c.isActive == false) && !(!c.hasOwnProperty('shortlisted') || (c.shortlisted === null)) ? (
              <div>
                <hr className="mb-2 ml-0 mr-0 mt-2" />
                {
                  c && c.shortlisted !== null && (
                    <Button
                      small
                      onClick={() => SetMixPanel(track.APPLICATION_CANDIDATE_INTERVIEWSTATUS)}//handleWorkflowStatusUpdate(application, appId, workflow)}
                      href={`/app/campus/candidate/${c.appId}/job/${c.jobId}/interviewstatus`}
                      onFocus={() => { }}
                      className="xpa--workflow-click ml-2"
                      kind="tertiary"
                      icon={
                        {
                          id: "icon--visibility-on",
                          name: "icon--visibility-on",
                          tags: "icon--visibility-on",
                          styles: "",
                          viewBox: "0 0 16 11",
                          width: "16",
                          height: "11",
                          svgData: {
                            circles: "",
                            ellipses: "",
                            paths: [
                              {
                                d: "M8 7.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 1c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                              },
                              {
                                d: "M8 10c2.8 0 5.1-1.5 6.9-4.6C13.1 2.5 10.8 1 8 1 5.2 1 3 2.4 1.2 5.4 2.9 8.6 5.2 10 8 10zM8 0c3.3 0 6 1.8 8.1 5.4C14 9.2 11.3 11 8 11S2 9.2 0 5.5C2 1.9 4.6 0 8 0z"
                              },
                            ],
                            polygons: "",
                            polylines: "",
                            rects: ""
                          }
                        }
                      }
                      iconDescription="Add"
                    >
                      {t('view')} {t('status')}
                    </Button>
                  )
                }
                {/* <WorkflowAction
                  application={c}
                  handleWorkflowStatusUpdate={handleWorkflowStatusUpdate}
                  handleWorkflowSetup={handleWorkflowSetup}
                  handleOpenEmailModal={(e) => handleOpenEmailModal(e)}
                  activeSendOfferModal={(e) => activeSendOfferModal(e)}
                  onViewEmail={onViewEmail}
                  user={user}
                  jobDetails={jobDetails}
                /> */}
              </div>
            ) : (<div />)}

          </div>
        ),
      };
    };

    const otherActionButtons = [];
    // if (id) {
    //   otherActionButtons.push(
    //     <ToolbarItem>
    //       <Button
    //         onClick={handleRefreshScores}
    //         className="mt-0 mr-2 bx--btn--ghost"
    //         kind="tertiary"
    //       >
    //         Refresh Scores
    //       </Button>
    //     </ToolbarItem>
    //   )
    // }


    // otherActionButtons.push(<ApplicantSort onSortValueChange={onSortValueChange} />);
    otherActionButtons.push(ctypeId && Number(ctypeId) === 2 ? (
      <Button onClick={() => this.handleExportStudentApps()}>
        Export Applicant Data
      </Button>
    ) : null
    );


    const tabsList = ['processing', 'is_liked', 'screening', 'shortlisted', 'rejected', 'recommended'];
    // otherActionButtons.push()
    // otherActionButtons.push(
    //   <ApplicantsTabs 
    //     handleApplicantsTabClick={(e) => this.props.handleApplicantsTabClick(e.target.value)}
    //     tabCount={this.props.tabCount}
    //     selectedTab={selectedTab}
    //   />
    // )
    let skeletonApplRecords = [];
    let applRecordRows = [];
    const { onUploadSuccess } = this.props;
    if (taskStatus && taskStatus.task_result === null && (taskStatus.task_state === "STARTED" || taskStatus.task_state === 'PENDING')) {
      skeletonApplRecords = onUploadSuccess && onUploadSuccess.map((r, index) => {
        let c = {
          appId: index,
          profile: {
            profileId: index,
          },
        };
        return getCols(c, true);
      });
    } else if ((taskStatus && taskStatus.task_state === "SUCCESSFUL CREATION")
      || (taskStatus && taskStatus.returnvalue)) {
      applRecordRows = xopaScores.map(c => {
        return getCols(c)
      });
    }

    applRecordRows = xopaScores.map(c => {
      return getCols(c)
    });

    const finalApplRecords = [];
    if (skeletonApplRecords && Array.isArray(skeletonApplRecords)) {
      finalApplRecords.push(...skeletonApplRecords);
    }
    if (applRecordRows && Array.isArray(applRecordRows)) {
      finalApplRecords.push(...applRecordRows);
    }

    const facets = {
      [`${t('status')}`]: [
        { key: `${t('screening')}`, id: 'screening', isSelected: false, filterKey: "applicationStatus", status: "as" },
        { key: `${t('shortlisted')}`, id: 'shortlisted', isSelected: false, filterKey: "applicationStatus", status: "as" },
        { key: `${t('rejected')}`, id: 'rejected', isSelected: false, filterKey: "applicationStatus", status: "as" },
        { key: 'Favourites', id: 'favourites', isSelected: false, filterKey: "applicationStatus", status: "as" },
        { key: 'Interviews in Progress', id: 'pendingInterviews', isSelected: false, filterKey: "applicationStatus", status: "as" },
        { key: 'Interviews Completed', id: 'completedInterviews', isSelected: false, filterKey: "applicationStatus", status: "as" },
      ],
      [`${t('interviewType')}`]: [
        { key: 'Onsite Interview', id: 'Onsite Interview', isSelected: false, filterKey: 'workflowStages', status: 'it' },
      ],
      [`${t('workflowStages')}`]: [
        { key: `${t('scheduling')}`, id: 'Scheduling', isSelected: false, filterKey: 'workflowSubStageStatus', status: 'wss' },
        { key: `${t('decision')}`, id: 'Decision', isSelected: false, filterKey: 'workflowSubStageStatus', status: 'wss' },
      ],
      [`${t('offerStatus')}`]: [
        { key: `${t('offer')} sent`, id: 'offer-detail-sent', isSelected: false, filterKey: 'offerStatus', status: 'os' },
        { key: `${t('offer')} ${t('accepted')}`, id: 'offer-accepted', isSelected: false, filterKey: 'offerStatus', status: 'os' },
        { key: `${t('offer')} ${t('rejected')}`, id: 'offer-rejected', isSelected: false, filterKey: 'offerStatus', status: 'os' },
      ],
      [`${t('hiringStatus')}`]: [
        { key: `${t('hired')}`, id: 'is-hired', isSelected: false, filterKey: 'hiringStatus', status: 'hs' },
        { key: `${t('no')}-${t('hire')}`, id: 'is-not-hired', isSelected: false, filterKey: 'hiringStatus', status: 'hs' },
      ],
      [`${t('Gender')}`]: [
        { key: `${t('Male')}`, id: 'male', isSelected: false, filterKey: 'gender', status: 'g' },
        { key: `${t('Female')}`, id: 'female', isSelected: false, filterKey: 'gender', status: 'g' },
        { key: `${t('Undisclosed')}`, id: 'undisclosed', isSelected: false, filterKey: 'gender', status: 'g' },
      ],
      [`${t('Skills')}`]: [
        { key: `${t('Microsoft Powerpoint')}`, id: 'ms-powerpoint', isSelected: false, filterKey: 'skills', status: 's' },
        { key: `${t('Microsoft Word')}`, id: 'ms-word', isSelected: false, filterKey: 'skills', status: 's' },
        { key: `${t('Microsoft Office')}`, id: 'ms-office', isSelected: false, filterKey: 'skills', status: 's' },
        { key: `${t('Microsoft Excel')}`, id: 'ms-excel', isSelected: false, filterKey: 'skills', status: 's' },
        { key: `${t('Customer Service Oriented')}`, id: 'cso', isSelected: false, filterKey: 'skills', status: 's' },
        { key: `${t('Operations')}`, id: 'operations', isSelected: false, filterKey: 'skills', status: 's' },
        { key: `${t('Cashiering')}`, id: 'cashiering', isSelected: false, filterKey: 'skills', status: 's' },
        { key: `${t('Customer Service')}`, id: 'customer-service', isSelected: false, filterKey: 'skills', status: 's' },
        { key: `${t('Retail Sales')}`, id: 'retail-sales', isSelected: false, filterKey: 'skills', status: 's' },
      ],
    }
    const keys = facets && Object.keys(facets);
    const { defaultCheckList } = this.props;
    return (
      <div className="applicants-layout">
       {
        pathname == '/app/campus/candidates/xopa/processing' && (
          <div className="bx--col-xs-4 text-right">
            <div className="clearfix">
              <Button className="" onClick={this.onDownloadClick}>Click here to download CVs of all applicants</Button>
            </div>
          </div>
        )
       }
       
        <Layout
          sideContent={
            <FilterData
              id={id}
              handleRefreshScores={handleRefreshScores}
              // onSortValueChange={onSortValueChange}
              defaultCheckList={defaultCheckList}
              facets={facets}
              keys={keys}
              filterApls={true}
              titles={applTitles}
              activePage={activePage}
              jobId={jobId}
              itemsPerPage={itemsPerPage}
              searchKey={searchKey}
              status={status}
              params={params}
              history={history}
              search={search}
              statusSearch={statusSearch}
            />
          }


          bodyContent={
            <div>
              <div className="d-lg-flex d-md-flex justify-content-between">
                <div>
                  <PagingInfo
                    end={Math.min(
                      Number(count),
                      Number(itemsPerPage) *
                      Number(activePage)
                    )}
                    start={
                      1 +
                      Number(activePage - 1) *
                      Number(itemsPerPage)
                    }
                    totalResults={Number(count)}
                  />
                </div>
                <div>
                  <ApplicantSort onSortValueChange={onSortValueChange} />
                </div>

              </div>
              <GenericDataTable
                className="d-block"
                otherActions={otherActionButtons}
                batchActionClick={batchActionClickHandler}
                batchActions={
                  batchActions
                }
                customSorting={columnSortJobList}
                // expandedRows={
                //   sortedJobs.map(job => (
                //     <JobDetailsModal
                //       job={job}
                //       jobDetails={job}
                //     />
                //   ))
                // }
                headers={
                  columnInfo.map((data, index) => (
                    {
                      key: `header_${index}`,
                      header: data.title,
                      className: data.className,
                      colpropName: data.propName
                    }
                  ))
                }
                rows={finalApplRecords}
                selectable
                searchable={true}
                onSearchInputChange={handleSearchSubmit}
                expandable={false}
              />
              {sortedApplicant && sortedApplicant.length > 0 && (
                <div className="">
                  <Pagination
                    onChange={handlePaginationChange}
                    pageSizes={[10, 20, 30, 40, 50]}
                    pageSize={itemsPerPage}
                    page={activePage}
                    totalItems={count}
                  />
                </div>)}
              {xopaScores && xopaScores.length + uploadedFilesLength <= 0 && (
                <NoApplicantBox t={t} />
              )}
              <XopaModal active={this.state.activeModal} dismiss={this.dismissModal} />
            </div>
          }
        />

        {/* <div className="xpa-appl-info--list"> */}
          {/* <FilterData
            id={id}
            handleRefreshScores={handleRefreshScores}
            onSortValueChange={onSortValueChange}
            filterApls={true}
            titles={applTitles}
            activePage={activePage}
            jobId={jobId}
            itemsPerPage={itemsPerPage}
            searchKey={searchKey}
            status={status}
            params={params}
            history={history}
            search={search}
            statusSearch={statusSearch}
          /> */}
          {/* <GenericDataTable
            className="d-block"
            otherActions={otherActionButtons}
            batchActionClick={batchActionClickHandler}
            batchActions={
              batchActions
            }
            customSorting={columnSortJobList}
            // expandedRows={
            //   sortedJobs.map(job => (
            //     <JobDetailsModal
            //       job={job}
            //       jobDetails={job}
            //     />
            //   ))
            // }
            headers={
              columnInfo.map((data, index) => (
                {
                  key: `header_${index}`,
                  header: data.title,
                  className: data.className,
                  colpropName: data.propName
                }
              ))
            }
            rows={finalApplRecords}
            selectable
            searchable={true}
            onSearchInputChange={handleSearchSubmit}
            expandable={false}
          />
          <XopaModal active={this.state.activeModal} dismiss={this.dismissModal} />
        </div> */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getStudentAppData,
  getDocumentByIds
};

export default connect(null, mapDispatchToProps)(withRouter(withTranslation()(XopaTable)));
