import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Button,
} from 'carbon-components-react';

const DocumentsData = (props) => {
  const {
    documents, t,
    center,
    user: {
      company: {
        ctypeId,
      } = {},
    } = {},
    handleDeleteDoc,
  } = props;
  const hostName = window.location.hostname;
  let link = '';
  if (hostName.includes('internship-placement.rp.edu.sg')) {
    link = 'https://x0pa-rp-storage.s3-ap-southeast-1.amazonaws.com';
  } else {
    link = 'https://x0pa-rps-storage.s3-ap-southeast-1.amazonaws.com';
  }
  let finalDocs = [];
  const typeIds = [11, 12];
  if (documents && Array.isArray(documents)
  && documents.length > 0) {
    finalDocs = documents.filter(res => !(typeIds.includes(res && res.typeId)));
  }
  return (
    <div>
      {
        finalDocs && Array.isArray(finalDocs)
        && finalDocs.length > 0
        && finalDocs.map((res) => {
          const {
            typeId,
            fileName,
            documenttype: {
              typeName,
            } = {},
            personDocId,
            cvLink,
          } = res || {};
          return (
            <div className={`bx--row mb-2 align-items-center ${center ? 'justify-content-center' : ''}`}>
              {
                !fileName && typeId === 1 && (
                  <Fragment>
                    <div className="bx--type-zeta bx--col-lg-2 bx--col-md-3 bx--col-sm-6">
                      {typeName}
                    </div>
                    <div className="ml-2">
                      <a target="_blank" href={`/view/${cvLink}/cv?_=${Date.now()}`}>
                        <Button size="small" kind="tertiary">
                          {t('view')} {t('file')}
                        </Button>
                      </a>
                    </div>
                    {
                      ctypeId && Number(ctypeId) !== 1 && (
                        <div className="ml-2">
                          <Button size="small" onClick={() => handleDeleteDoc(personDocId)} kind="danger">{t('delete')}</Button>
                        </div>
                      )
                    }
                  </Fragment>
                )
              }
              {
                fileName && (
                  <Fragment>
                    <div className="bx--type-zeta bx--col-lg-2 bx--col-md-3 bx--col-sm-6">
                      {fileName}
                    </div>
                    <div className="ml-2">
                      <a target="_blank" href={`/view/${cvLink}/cv?_=${Date.now()}`}>
                        <Button size="small" kind="tertiary">
                          {t('view')} {t('file')}
                        </Button>
                      </a>
                    </div>
                    {
                      ctypeId && Number(ctypeId) !== 1 && (
                        <div className="ml-2">
                          <Button size="small" onClick={() => handleDeleteDoc(personDocId)} kind="danger">{t('delete')}</Button>
                        </div>
                      )
                    }
                  </Fragment>
                )
              }
              {/* {
                ctypeId && Number(ctypeId) !== 1 && !fileName && typeId === 1 && (
                  <div className="ml-2">
                    <Button size="small" onClick={() => handleDeleteDoc(personDocId)} kind="danger">{t('delete')}</Button>
                  </div>
                )
              } */}
            </div>
          )
        })
    }
    </div>
  );
};

export default (withTranslation()(DocumentsData));
