import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';

import ColorLegend from 'components/common/ColorLegend';

import RoboSidebar from 'components/roboroy/Sidebar';
import XopaTable from 'containers/roboroy/XopaTable';
import BackRow from 'components/common/BackRow/BackRow';

import { xopaScores, jobs } from './data';
import logo from './logo-white.png';

class CandidatesListXopa extends Component {
  state = {
    searchTerm: '',
    job: {},
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      job: jobs[0],
    });
    const { id } = this.props.match.params;
    this.paths = [
      { id: 'dashboard', name: 'Dashboard', href: '/app/campus' },
      { id: 'jobs', name: 'Jobs', href: '/app/campus/jobs' },
      { id: 'applied', name: 'Applied Candidates', href: `/app/campus/jobs/${id}/applied` },
      { id: 'score', name: 'xOpa Score', href: '' },
    ];
  }

  render() {
    return (
      <div className="candidates_list">
        {/* <RoboSidebar /> */}
        <div className="candidates_container">
          {/* <BackRow paths={this.paths} /> */}
          <div className="candidates_container__header">
            <div className="candidates_container__header--top">
              <div>
                <h3>Applied Candidates</h3>
                <input
                  placeholder="Search"
                  type="text"
                  name="searchTerm"
                  value={this.state.searchTerm}
                  onChange={this.handleChange}
                />
              </div>
              <button className="xopa__button nopointer" onClick={this.firXopa}>
                <img src={logo} alt="xopa logo" />
                <span>Scores</span>
              </button>
              <div className="talentpool">
                <h4>Talent Pool</h4>
                <div className="talent__button first">
                  <span>upload database</span>
                </div>
                <div className="talent__button">
                  <span>upload resumes</span>
                </div>
              </div>
            </div>
            <div className="candidates_container__header--subtitle">
              <h4 className="table__job__title">
                Job Title: <span> {this.state.job.name}</span>
              </h4>
              <ColorLegend />
            </div>
          </div>
          <XopaTable xopaScores={xopaScores} />
        </div>
      </div>
    );
  }
}

// todo- change style of container border depdinging on value

export default CandidatesListXopa;
