import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Tile, Button, Modal, TextInput,
  DatePicker, DatePickerInput, Toggle,
  InlineNotification, Loading,
  TimePicker, TextArea,
} from 'carbon-components-react';
import { withTranslation } from 'react-i18next';

import {
  createInstitutionSlot,
  listInstitutionSlots,
  updateInstitutionSlot,
  deleteInstitutionSlot,
} from 'actions/calendarEvents';

class InterviewSlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddSlotModalOpen: false,
      interviewName: '',
      isFixedSlot: false,
      // interviewDate: Date.now(),
      interviewStartTime: '09:00',
      interviewEndTime: '18:00',
      interviewsPerSlot: 1,
      duration: 30,
      interviewDescription: '',
      notifMessage: '',
      notifShow: false,
      notifType: 'success',
      addingSlot: false,
      loadingSlots: true,
      editingSlot: false,
      slots: [],
    };
  }

  componentDidMount() {
    this.listSlots();
  }

  openAddSlotModal = () => {
    this.setState({
      isAddSlotModalOpen: true,
      interviewName: '',
      interviewDescription: '',
      interviewsPerSlot: 1,
    });
  }

  dismissModal = () => {
    this.setState({
      isAddSlotModalOpen: false,
      isEditSlotModalOpen: false,
    });
  }

  onToggle = (e) => {
    this.setState({
      isFixedSlot: e,
    });
  }

  onAddSlot = () => {
    const { institutionId } = this.props;
    const {
      interviewDate, isFixedSlot, interviewsPerSlot,
      interviewStartTime, interviewEndTime, duration,
      interviewName, interviewDescription,
      interviewStartDate, interviewEndDate,
    } = this.state;
    let interviewDuration = duration;
    let isInterviewNameInvalid = false;
    let isInterviewDateInvalid = false;
    let isInterviewTimeInvalid = false;
    let isInterviewStartTimeInvalid = false;
    let isInterviewEndTimeInvalid = false;
    let isInvalid = false;
    if (!interviewDate && isFixedSlot) {
      isInterviewDateInvalid = true;
      isInvalid = true;
    }
    if ((!interviewStartDate || !interviewEndDate) && !isFixedSlot) {
      isInterviewDateInvalid = true;
      isInvalid = true;
    }
    if (!interviewName) {
      isInterviewNameInvalid = true;
      isInvalid = true;
    }
    if (!moment(interviewStartTime, 'HH:mm').isValid()) {
      isInterviewStartTimeInvalid = true;
      isInvalid = true;
    }
    if (!moment(interviewEndTime, 'HH:mm').isValid()) {
      isInterviewEndTimeInvalid = true;
      isInvalid = true;
    }
    const d = moment(interviewEndTime, 'HH:mm').diff(moment(interviewStartTime, 'HH:mm'), 'm');
    if (d < 0) {
      isInterviewTimeInvalid = true;
      isInvalid = true;
    }
    if (isFixedSlot && d > 0) {
      interviewDuration = d;
    }
    if (isInvalid) {
      this.setState({
        isInterviewNameInvalid,
        isInterviewDateInvalid,
        isInterviewTimeInvalid,
        isInterviewStartTimeInvalid,
        isInterviewEndTimeInvalid,
      });
      return;
    }
    // return;
    const data = {
      institutionId,
      interviewDate,
      startTime: moment(`${interviewDate} ${interviewStartTime}:00Z`, 'MM/DD/YYYY HH:mm:ssZ').utc().subtract('480', 'minutes').format(),
      endTime: moment(`${interviewDate} ${interviewEndTime}:00Z`, 'MM/DD/YYYY HH:mm:ssZ').utc().subtract('480', 'minutes').format(),
      slotType: isFixedSlot ? 'fixed' : 'flexible',
      duration: interviewDuration || 30,
      interviewName: interviewName || 'Interview #',
      interviewDescription: interviewDescription || null,
    };
    if (!isFixedSlot) {
      data.interviewsPerSlot = interviewsPerSlot || 1;
      data.interviewDates = [interviewStartDate, interviewEndDate];
      data.startTime = moment(`${interviewStartDate} ${interviewStartTime}:00Z`, 'MM/DD/YYYY HH:mm:ssZ').utc().subtract('480', 'minutes').format();
      data.endTime = moment(`${interviewEndDate} ${interviewEndTime}:00Z`, 'MM/DD/YYYY HH:mm:ssZ').utc().subtract('480', 'minutes').format();
    }
    this.setState({
      addingSlot: true,
      notifMessage: 'Adding an interview slot...',
      notifShow: true,
      notifType: 'info',
    });
    this.props.createInstitutionSlot(data)
      .then((res) => {
        const { error } = res || {};
        if (!res || error) {
          throw error;
        } else {
          this.setState({
            notifMessage: 'Interview slot added successfully.',
            notifShow: true,
            notifType: 'success',
            isAddSlotModalOpen: false,
            addingSlot: false,
            interviewDate: '',
            interviewName: '',
            interviewDescription: '',
            interviewsPerSlot: 1,
          });
          this.listSlots();
        }
      })
      .catch(() => {
        this.setState({
          notifMessage: 'Error while adding an interview slot.',
          notifShow: true,
          notifType: 'error',
          isAddSlotModalOpen: false,
          addingSlot: false,
        });
      });
  }

  handleOnChange = (name, v, d) => {
    if (name === 'interviewDates') {
      this.setState({
        interviewStartDate: moment(d[0]).format('MM/DD/YYYY'),
        interviewEndDate: moment(d[1]).format('MM/DD/YYYY'),
        isInterviewNameInvalid: false,
        isInterviewDateInvalid: false,
        isInterviewTimeInvalid: false,
        isInterviewStartTimeInvalid: false,
        isInterviewEndTimeInvalid: false,
      });
    } else {
      this.setState({
        [`${name}`]: v,
        isInterviewNameInvalid: false,
        isInterviewDateInvalid: false,
        isInterviewTimeInvalid: false,
        isInterviewStartTimeInvalid: false,
        isInterviewEndTimeInvalid: false,
      });
    }
  }

  closeNotification = () => {
    this.setState({
      notifMessage: '',
      notifShow: false,
      notifType: 'success',
    });
  }

  listSlots = () => {
    const { institutionId } = this.props;
    this.props.listInstitutionSlots(institutionId)
      .then((res) => {
        const { error, slots } = res || {};
        if (!res || error) {
          throw error;
        }
        this.setState({
          slots: slots || [],
          loadingSlots: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingSlots: false,
        });
      });
  }

  deleteInstitutionSlot = (slotId) => {
    this.setState({
      addingSlot: true,
      notifMessage: 'Deleting an interview slot...',
      notifShow: true,
      notifType: 'info',
    });
    this.props.deleteInstitutionSlot(slotId)
      .then((res) => {
        const { error } = res || {};
        if (!res || error) {
          throw error;
        }
        this.setState({
          notifMessage: 'Deleted the interview slot successfully.',
          notifShow: true,
          notifType: 'success',
          addingSlot: false,
        });
        this.listSlots();
      })
      .catch((error) => {
        this.setState({
          notifMessage: 'Error while deleting an interview slot.',
          notifShow: true,
          notifType: 'error',
          addingSlot: false,
        });
      });
  }

  openEditInstitutionSlot = (slot) => {
    const {
      slotType,
      duration, interviewName,
      interviewDescription,
      interviewsPerSlot,
    } = slot || {};
    this.setState({
      isEditSlotModalOpen: true,
      editingSlot: true,
      activeSlot: slot || 0,
      editingSlotType: slotType,
      duration: duration || 30,
      interviewName,
      interviewDescription,
      interviewsPerSlot,
    });
  }

  updateInstitutionSlot = () => {
    const { institutionId } = this.props;
    const { activeSlot } = this.state || {};
    const { slotId } = activeSlot || {};
    this.setState({
      isEditSlotModalOpen: false,
      editingSlot: true,
      notifMessage: 'Updating an interview slot...',
      notifShow: true,
      notifType: 'info',
      [`ed-${slotId}`]: true,
    });
    const {
      duration,
      interviewName,
      interviewDescription,
      interviewsPerSlot,
    } = this.state || {};
    const udata = {
      institutionId,
      slotId,
      duration,
      interviewName,
      interviewDescription,
      interviewsPerSlot,
    };
    this.props.updateInstitutionSlot(udata)
      .then((res) => {
        const { error } = res || {};
        if (!res || error) {
          throw error;
        }
        this.setState({
          notifMessage: 'Updated the interview slot successfully.',
          notifShow: true,
          notifType: 'success',
          editingSlot: false,
          [`ed-${slotId}`]: false,
        });
        this.listSlots();
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          notifMessage: 'Error while updating the interview slot.',
          notifShow: true,
          notifType: 'error',
          editingSlot: false,
          [`ed-${slotId}`]: false,
        });
      });
  }

  resetLoadingStates = () => {
    this.setState({
      editingSlot: false,
      addingSlot: false,
    });
  }

  renderSlotsList = () => {
    const { slots, editingSlot } = this.state;
    if (slots && Array.isArray(slots)) {
      const slotsList = slots.map((s) => {
        const {
          interviewName,
          slotType,
          duration,
          startTime,
          endTime,
          slotId,
          interviewsPerSlot,
          interviewDescription,
        } = s || {};
        return (
          <div key={slotId} className="p-2 mt-2 border">
            <div className="bx--row">
              <div className="bx--col-md-2 text-dark bx--type-strong">Interview Name</div>
              <div>:&nbsp;
                <span className="bx--type-caps bx--type-strong">
                  { interviewName }
                </span>
              </div>
            </div>
            <div className="bx--row">
              <div className="bx--col-md-2 text-dark bx--type-strong">Slot Type</div>
              <div>: <span className="bx--type-caps">{slotType}</span></div>
            </div>
            {
              (slotType !== 'fixed') && (
                <div className="bx--row">
                  <div className="bx--col-md-2 text-dark bx--type-strong">Interview Dates</div>
                  <div>: {moment(startTime).format('Do MMM YYYY')} - {moment(endTime).format('Do MMM YYYY')}</div>
                </div>
              )
            }
            {
              (slotType === 'fixed') && (
                <div className="bx--row">
                  <div className="bx--col-md-2 text-dark bx--type-strong">Interview Date</div>
                  <div>: {moment(startTime).format('Do MMM YYYY')}</div>
                </div>
              )
            }
            <div className="bx--row">
              <div className="bx--col-md-2 text-dark bx--type-strong">Start Time</div>
              <div>: {moment(startTime).utc().add(480, 'minutes').format('HH:mm')} (SGT)</div>
            </div>
            <div className="bx--row">
              <div className="bx--col-md-2 text-dark bx--type-strong">End Time</div>
              <div>: {moment(endTime).utc().add(480, 'minutes').format('HH:mm')} (SGT)</div>
            </div>
            <div className="bx--row">
              <div className="bx--col-md-2 text-dark bx--type-strong">Duration</div>
              <div>: {duration} mins</div>
            </div>
            <div className="bx--row">
              <div className="bx--col-md-2 text-dark bx--type-strong">Interviews Per Slot</div>
              <div>: {(slotType !== 'fixed') ? interviewsPerSlot : 'Unlimited'}</div>
            </div>
            <div className="text-dark bx--type-strong">Description:</div>
            <div>{interviewDescription || 'N/A'}</div>
            <div className="mt-2">
              <Button
                className="mr-2"
                kind="tertiary"
                small
                onClick={() => { this.openEditInstitutionSlot(s); }}
                disabled={editingSlot}
              >
                { editingSlot && (this.state[`ed-${slotId}`]) ? 'Editing...' : 'Edit' }
              </Button>
              <Button
                kind="danger"
                small
                onClick={() => { this.deleteInstitutionSlot(slotId); }}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      });
      return slotsList;
    }
    return <div />;
  }

  render() {
    const { t } = this.props;
    const {
      isAddSlotModalOpen, isFixedSlot,
      interviewStartTime, interviewEndTime,
      interviewDescription, duration,
      notifType, notifMessage, notifShow,
      addingSlot, loadingSlots, interviewName,
      interviewsPerSlot, isInterviewNameInvalid,
      isInterviewDateInvalid, isInterviewTimeInvalid,
      isInterviewStartTimeInvalid, isInterviewEndTimeInvalid,
      isEditSlotModalOpen, editingSlot, editingSlotType,
    } = this.state;
    const id = moment(interviewEndTime, 'HH:mm').diff(moment(interviewStartTime, 'HH:mm'), 'm');
    const interviewDuration = isNaN(id) ? 0 : Number(id);
    return (
      <Tile>
        {
          loadingSlots && (<Loading withOverlay={false} />)
        }
        {
          notifShow && (
            <InlineNotification lowContrast
              kind={notifType}
              title={notifMessage}
              subtitle=""
              onCloseButtonClick={this.closeNotification}
            />
          )
        }
        <div className="d-flex flex-column">
          {
            this.renderSlotsList()
          }
        </div>
        <Button
          small
          onClick={this.openAddSlotModal}
          className="mt-4"
        >
          Add slot
        </Button>
        <Modal
          open={isAddSlotModalOpen && !addingSlot}
          modalHeading={`${t('add')} ${t('slot')}`}
          primaryButtonText={t('add')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissModal}
          onRequestSubmit={this.onAddSlot}
        >
          <Fragment>
            <div className="mb-2">
              <TextInput
                labelText="Interview Name"
                name="interviewName"
                id="interviewName"
                maxLength={200}
                value={interviewName}
                onChange={(e) => this.handleOnChange('interviewName', e.target.value)}
                placeholder="Interview Name"
                invalid={isInterviewNameInvalid}
                invalidText="Please enter valid interview name"
              />
            </div>
            <div className="bx--label">Is this a fixed slot?</div>
            <Toggle
              name="isFixedSlot"
              defaultToggled={isFixedSlot}
              onToggle={this.onToggle}
              labelA="No"
              labelB="Yes"
              id="isFixedSlot"
            />
            <div className="d-flex">
              <div className="flex-grow-0">
                {
                  !isFixedSlot && (
                    <DatePicker
                      id="date-picker-flexible"
                      datePickerType="range"
                      onChange={(d, v) => { this.handleOnChange('interviewDates', v, d); }}
                      onClose={() => {}}
                      minDate={moment().format('MM/DD/YYYY')}
                      short={false}
                    >
                      <DatePickerInput
                        id="date-picker-input-id-start"
                        labelText="Start Date"
                        pattern="d{1,2}/d{4}"
                        placeholder="mm/dd/yyyy"
                        iconDescription=""
                        onChange={() => {}}
                        onClose={() => {}}
                        invalid={isInterviewDateInvalid}
                        invalidText="Please select interview start date"
                      />
                      <DatePickerInput
                        disabled={false}
                        id="date-picker-input-id-end"
                        invalid={isInterviewDateInvalid}
                        invalidText="Please select interview end date"
                        labelText="End Date"
                        onChange={() => {}}
                        onClose={() => {}}
                        pattern="d{1,2}/d{4}"
                        placeholder="mm/dd/yyyy"
                        type="text"
                      />
                    </DatePicker>
                  )
                }
                {
                  isFixedSlot && (
                    <DatePicker
                      id="date-picker-fixed"
                      datePickerType="single"
                      onChange={(d, v) => { this.handleOnChange('interviewDate', v, d); }}
                      onClose={() => {}}
                      minDate={moment().format('MM/DD/YYYY')}
                    >
                      <DatePickerInput
                        id="date-picker-input-id-fixed"
                        labelText="Interview Date"
                        pattern="d{1,2}/d{4}"
                        placeholder="mm/dd/yyyy"
                        iconDescription=""
                        onChange={() => {}}
                        onClose={() => {}}
                        invalid={isInterviewDateInvalid}
                        invalidText="Please select an interview date"
                      />
                    </DatePicker>
                  )
                }
              </div>
              <div className="ml-2 flex-grow-0">
                <TimePicker
                  id="start-time-picker"
                  hideLabel={false}
                  labelText="Start time"
                  invalidText="Please enter valid time"
                  onChange={(d) => { this.handleOnChange('interviewStartTime', d.target.value); }}
                  onClose={() => {}}
                  value={interviewStartTime}
                  invalid={isInterviewTimeInvalid || isInterviewStartTimeInvalid}
                />
              </div>
              <div className="ml-2 flex-grow-0">
                <TimePicker
                  id="end-time-picker"
                  hideLabel={false}
                  labelText="End time"
                  invalidText="Please enter valid time"
                  onChange={(d) => { this.handleOnChange('interviewEndTime', d.target.value); }}
                  onClose={() => {}}
                  value={interviewEndTime}
                  invalid={isInterviewTimeInvalid || isInterviewEndTimeInvalid}
                />
              </div>
            </div>
            <div className="mt-2 d-flex">
              <div style={{ width: 100 }}>
                {
                  !isFixedSlot && (
                    <TextInput
                      labelText="Duration(mins)"
                      name="duration"
                      id="duration"
                      type="number"
                      min={5}
                      step={5}
                      max={600}
                      value={duration}
                      onChange={(e) => this.handleOnChange('duration', e.target.value)}
                    />
                  )
                }
                {
                  isFixedSlot && (
                    <Fragment>
                      <div className="bx--label">Duration(mins)</div>
                      {
                        interviewDuration
                      }
                    </Fragment>
                  )
                }
              </div>
              {
                !isFixedSlot && (
                  <div className="ml-2" style={{ width: 200 }}>
                    <TextInput
                      labelText="Interviews Per Slot"
                      name="interviewsPerSlot"
                      id="interviewsPerSlot"
                      type="number"
                      min={1}
                      step={1}
                      max={600}
                      value={interviewsPerSlot}
                      onChange={(e) => this.handleOnChange('interviewsPerSlot', e.target.value)}
                    />
                  </div>
                )
              }
            </div>
            <div className="mt-2 pr-4">
              <TextArea
                labelText="Interview Description"
                id="interviewDescription"
                name="interviewDescription"
                onChange={(e) => this.handleOnChange('interviewDescription', e.target.value)}
                value={interviewDescription}
              />
            </div>
          </Fragment>
        </Modal>

        <Modal
          open={isEditSlotModalOpen && editingSlot && !addingSlot}
          modalHeading={`${t('edit')} ${t('slot')}`}
          primaryButtonText={t('edit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissModal}
          onRequestSubmit={this.updateInstitutionSlot}
          onSecondarySubmit={this.resetLoadingStates}
        >
          <Fragment>
            <div className="d-block bx--label">Slot Type: {editingSlotType}</div>
            <div className="mb-2">
              <TextInput
                labelText="Interview Name"
                name="interviewName"
                id="interviewName"
                maxLength={200}
                value={interviewName}
                onChange={(e) => this.handleOnChange('interviewName', e.target.value)}
                placeholder="Interview Name"
                invalid={isInterviewNameInvalid}
                invalidText="Please enter valid interview name"
              />
            </div>
            <div className="mt-2 d-flex">
              <div style={{ width: 100 }}>
                <TextInput
                  labelText="Duration(mins)"
                  name="duration"
                  id="duration"
                  type="number"
                  min={5}
                  step={5}
                  max={600}
                  value={duration}
                  onChange={(e) => this.handleOnChange('duration', e.target.value)}
                />
                {/* {
                  (editingSlotType === 'fixed') && (
                    <Fragment>
                      <div className="bx--label">Duration(mins)</div>
                      {
                        interviewDuration
                      }
                    </Fragment>
                  )
                } */}
              </div>
              {
                (editingSlotType !== 'fixed') && (
                  <div className="ml-2" style={{ width: 200 }}>
                    <TextInput
                      labelText="Interviews Per Slot"
                      name="interviewsPerSlot"
                      id="interviewsPerSlot"
                      type="number"
                      min={1}
                      step={1}
                      max={600}
                      value={interviewsPerSlot}
                      onChange={(e) => this.handleOnChange('interviewsPerSlot', e.target.value)}
                    />
                  </div>
                )
              }
            </div>
            <div className="mt-2 pr-4">
              <TextArea
                labelText="Interview Description"
                id="interviewDescription"
                name="interviewDescription"
                onChange={(e) => this.handleOnChange('interviewDescription', e.target.value)}
                value={interviewDescription}
              />
            </div>
          </Fragment>
        </Modal>
      </Tile>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  createInstitutionSlot,
  listInstitutionSlots,
  updateInstitutionSlot,
  deleteInstitutionSlot,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InterviewSlots));
