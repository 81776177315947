const normalize = (val, min, max) => ((val - min) / (max - min) || 0) + 0.01;
const getNormalized = (c, key, minMax) => {
    let normalized = Math.round(normalize(
      c && c[key] ? Math.round(c[key]) : 0,
      minMax[key][0],
      minMax[key][1],
    )*100);

    if (normalized > 100) {
      return 100
    } 
    if ((normalized == Infinity) || (normalized == -Infinity)) {
      return 100
    }
    return normalized;
  }
export { normalize, getNormalized };