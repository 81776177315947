import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { REQUEST_ALL_PUBLIC_JOBS, REQUEST_ADD_JOBTO_PROFILE } from '../actionTypes';
import { deleteApiCache } from 'utils/helpers';

const fetchAllPublicJobs = data => (dispatch) => {
  let url = `${baseUrl}/jobs/list?_=${new Date().getTime()}`;
  if(data.value.length > 0)
    url += `&searchKey=${encodeURIComponent(data.value)}`;
  if (data.JobType.length > 0) {
    url += `&jobType=${encodeURIComponent((JSON.stringify(data.JobType)))}`;
  }
  if (data && Array.isArray(data.commuteTime) && data.commuteTime.length > 0) {
    url += `&commuteTime=${encodeURIComponent((JSON.stringify(data.commuteTime)))}`;
  }
  if (data && Array.isArray(data.salaryRange) && data.salaryRange.length > 0) {
    url += `&salaryRange=${encodeURIComponent((JSON.stringify(data.salaryRange)))}`;
  }
  if(data.company.length > 0)
    url += `&company=${encodeURIComponent((JSON.stringify(data.company)))}`;
    if(data.location.length > 0)
    url +=`&location=${encodeURIComponent(JSON.stringify(data.location))}`;
    if(data.skills.length > 0)
    url +=`&skills=${encodeURIComponent(JSON.stringify(data.skills))}`;
    if(data.jobname.length > 0)
    url +=`&jobname=${encodeURIComponent(JSON.stringify(data.jobname))}`;
    if(data.experienceList.length > 0)
    url +=`&experienceList=${encodeURIComponent(JSON.stringify(data.experienceList))}`;
    if(data.skip >= 0)
    url +=`&filter[skip]=${data.skip}`;
    if(data.limit != 0)
    url +=`&filter[limit]=${data.limit}`;
  const params = {
    dispatch,
    actionType: REQUEST_ALL_PUBLIC_JOBS,
    url,
    method: 'GET',
  };
  deleteApiCache('xpa-api-jobs');
  return callApi(params);
};

const addJobToProfile = data => (dispatch) => {
  const { jobId, profileId } = data;
  const url = `${baseUrl}/jobs/talentpool/matches?job_id=${jobId}&profile_id=${profileId}&add_downvoted=true&_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_ADD_JOBTO_PROFILE,
    url,
    method: 'GET',
  };
  deleteApiCache('xpa-api-jobs');
  return callApi(params);
};

export { fetchAllPublicJobs, addJobToProfile };
