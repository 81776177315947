import { withTranslation } from 'react-i18next';
import React from 'react';

const JobDescription = ({ jobDesc, jobReq, benefit, t }) => {
    // if (!jobDesc) return <div>Not specified.</div>;

    const renderHtml = (htmlObject)=> {return <div dangerouslySetInnerHTML={htmlObject} />};
    return (
      <div>
        {jobDesc ? <div className="xpa-format--pre-content jdesc mb-3">{renderHtml({__html : jobDesc})}</div> : <div className="mb-2">Not Specified</div>}
        {jobReq ? (<div ><span className="font-weight-bold h6 text-dark">{t('jobRequirements')}</span>:<hr/><div className="xpa-format--pre-content jdesc mb-3">{renderHtml({__html : jobReq})}</div></div>) : ''}
        {benefit ? (<div><h5>BENEFITS:</h5><hr/><div className="xpa-format--pre-content jdesc mb-3">{benefit}</div></div>) : ''}
      </div>
    );
};

export default (withTranslation()(JobDescription));
