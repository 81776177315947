
import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Pagination, Button, Search, Modal,
  InlineNotification, Tag, Tile,
  FormGroup, RadioButtonGroup, RadioButton,
  Select, SelectItem, SelectItemGroup,
} from 'carbon-components-react';
import moment from 'moment';
import ProductTour from 'components/common/ProductTour/ProductTour';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import Toast from 'components/common/Toast';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import { TalentPoolBuckets } from 'components/roboroy/common/TalentPoolBuckets';
import { Mixpanel } from 'components/common/Mixpanel';

import LeftNavFilter from 'containers/common/leftNavFilter/leftNavFilter';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { getBucketNames, addCandidatesToBuckets, createBucket } from 'actions/talentSearch/buckets';
import { reRenderContent, sendEmails } from 'actions/talentSearch/talentPool';
import Buckets from './Buckets';
import { getFacets } from 'actions/leftNavFilter';
import { filterJobData, addProfileToJob } from 'actions/talentSearch/jobList';
import { getCustomEmailTemplates } from 'actions/email-templates';

import { talentPoolCard } from '../RoboroyCards';

import './TalentPool.css';

const NoContentBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no candiadates for your current selection.</p>
      <p className="empty-title">
        Add resumes to talent pool for reconsidering candidates in future.
      </p>
    </div>
  </Tile>
);

class TalentPool extends Component {
  constructor(props) {
    super(props);
    const filter = JSON.stringify([]);

    this.state = {
      perPageNumber: 10,
      activePage: 1,
      productTourIsOpen: false,
      keyword: '',
      selected:{},
      checked:[],
      Filter:[],
      headersInfo:[
        { key: 'name', header: 'Name' },
      ],
      rowsData:[],
      selectedTab: 0,
      status: true,
      isOpenAddToBucketModal: false,
      selectedBucketId: 0,
      useBucket: false,
      bucketName: '',
      isBucketNameValid: false,
      invalidText: '',
      selectedId: 0,
      showAddToJobModel:false,
      selectedJobId:0,
      jobSearchKey:'',
      jobsRows:[],
      jobsHeaders:[
        { key: 'job', header: 'Job' },
        { key: 'company', header: 'Company' },
        { key: 'action', header: 'Action' },
      ],
      jobsLimit:5,
      jobsskip:0,
      totalJobs:0,
      addedToJobs:[],
      url: this.getFilterURL(filter),
      selectTemplateModal: false,
      selectedEmailTemplate: 'placeholder-item',
      selectedEmails: [],
      noTemplateError: false,
    };  
    this.closeTour = this.closeTour.bind(this);
  }

  closeTour() {
    this.setState({productTourIsOpen : false});
  } 
  componentDidMount() {
    this.props.reRenderContent({
      itemsPerPage: this.state.perPageNumber,
      activePage: 1,
      searchKey: this.state.keyword,
      selected:this.state.selected,
    }).then(() => this.setRows());
    this.props.getCustomEmailTemplates();
    this.props.getBucketNames();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activeAddToBucketModal = ({ target: { name } }, id) => {
    const profileId = name || id;
    this.setState({
      isOpenAddToBucketModal: true,
      selectedId: profileId,
    });
  }

  dismissAddToBucketModal = () => {
    const { isBucketNameValid } = this.state;
    if (isBucketNameValid) {
      this.setState({
        isBucketNameValid: false,
        invalidText: '',
        bucketName: '',
        selectedId: 0,
      });
    }
    this.setState({
      isOpenAddToBucketModal: false,
      bucketName: '',
      selectedId: 0,
    });
  }

  refresh = (partialRefresh) => {
    setTimeout(() => {
      this.props.reRenderContent({
        itemsPerPage: this.state.perPageNumber,
        activePage: this.state.activePage || 1,
        searchKey: this.state.keyword,
        selected:this.state.selected,
      }).then(()=>{
        this.setRows();
        this.props.getFacets({value: this.state.url});
      });  
    }, 1000);
  }
  itemsPerPageChanged = (e) => {
    this.props.reRenderContent({ itemsPerPage: e.target.value,
       activePage: 1,
       searchKey: this.state.keyword,
       selected:this.state.selected, }).then(()=>this.setRows());
    this.handleChange(e);
  };

  properRowData = ({ _source = {} }) => {
    const row = _source;
    const { we = [], currency: { currencyShort = '' } = {}, salary, } = row;
    const com = we.map((e) => {
      const {
        startDate = '-',
        endDate = '-',
        job: {
          company: {
            companyName = '-',
          } = {},
          jobfunction: {
            functionName = '-',
          } = {},
          jobseniority: {
            seniorityName = '-',
          } = {},
          qualificationtype: {
            typeName = '-',
          } = {},
        } = {},
      } = e;
      return {
        startDate,
        endDate,
        company: companyName,
        function: functionName,
        seniority: seniorityName,
        qualification: typeName,
      };
    });
    const {
      firstName = '', lastName = '',
      totalExp = 0, address = [],
      personDocuments = null, buckets = [],
      overallScore,
    } = row;
    const currCompany = com[0]
    return {
      firstName, lastName, totalExp,
      address, salary, currencyShort,
      currCompany, overallScore, personDocuments,
      buckets, id:row.profileId
    };
  };


  setRows = () => {
    const Jobs = this.props.profileData && this.props.profileData.profiles && this.props.profileData.profiles.jobs||[];
    const rowsData = Jobs && Array.isArray(Jobs) && Jobs.map((row) => {
      const rowData = this.properRowData(row);
      return ({
        id: rowData.id.toString(),
        name: talentPoolCard(rowData, { addToJob: this.addToJob, activeAddToBucketModal: this.activeAddToBucketModal }),
      });
    }) || [];
    this.setState({ rowsData });
  }

  activateModal = (job) => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  getFilterURL = (filter) => {
    const url = `/profile-base/filterFacetsRTP?filters=${encodeURIComponent(filter ? `${filter}` : '[]')}`;
    return url;
  }

  setSelected = (selected,Filter,checked)=>{
    const filter = JSON.stringify(Filter);
    const url = this.getFilterURL(filter);
    this.setState({selected,Filter,checked, url},()=>{
      this.props.reRenderContent({
        itemsPerPage: this.state.perPageNumber,
        activePage: 1,
        searchKey: this.state.keyword,
        selected: this.state.selected,
      }).then(()=>this.setRows());
    })
  }

  toastSuccess = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };
  

  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'talent-pool', name: 'Talent Pool', href: '/app/campus/candidates/xopa' },
  ];

  addToJob = ({ target: { name } }, id)=>{
    const profileId = name || id;
    this.setState({selectedId : profileId, showAddToJobModel : true, jobSearchKey:"", jobsRows:[], jobsskip:0},)
  }

  closeAddToJob = ()=>{
    this.setState({selectedId : 0, showAddToJobModel : false, jobSearchKey:"", jobsRows:[], jobsskip:0})
  }

 handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage }, () => {
      this.props.reRenderContent({
        itemsPerPage,
        activePage: activePage,
        searchKey: this.state.keyword,
        selected:this.state.selected,
      }).then(()=>this.setRows());
    });
  };

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 });
    this.searchTimer = setTimeout(() => (
      this.props.reRenderContent({
        itemsPerPage: this.state.itemsPerPage,
        activePage: 1,
        searchKey: this.state.keyword,
        selected:this.state.selected,
      }).then(()=>this.setRows())
    ), 1000);
  }

  handleBucketNames = () => {
    const {
      bucketName, useBucket, selectedBucketId, selectedId,
    } = this.state;
    const specialCharacters = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
    if (useBucket) {
      if (!bucketName || bucketName.trim().length === 0 || specialCharacters.test(bucketName)) {
        this.setState({
          isBucketNameValid: true,
          invalidText: 'Please enter valid bucket name',
        });
      } else {
        this.props.createBucket(bucketName).then((res) => {
          if (res && res.error && res.error.message === 'unique_violation__bucket_name_key') {
            this.setState({
              isBucketNameValid: true,
              invalidText: 'The bucket name already exists, Please enter unique name.',
            });
          } else {
            this.dismissAddToBucketModal();
            this.props.getBucketNames();
            this.props.addCandidatesToBuckets(selectedId, res && res.data && res.data.bucketId).then(()=>this.refresh());
            // window.location.reload();
          }
        });
      }
    } else {
      if (!selectedBucketId || selectedBucketId === 0) {
        this.setState({
          isBucketNameValid: true,
          invalidText: 'Please select atleast one bucket name',
        });
      } else {
        this.dismissAddToBucketModal();
        this.props.addCandidatesToBuckets(selectedId, selectedBucketId).then(()=>this.refresh());
        // window.location.reload();
      }      
    }
  }

  handleChangeRadioButton =(e) => {
    switch (e) {
      case 'select':
        this.setState({
          useBucket: false,
        });
        break;
      case 'create-new':
        this.setState({
          useBucket: true,
        });
        break;
      default: return;
    }
  }


  handleTalentPoolTabsClick = (tabType) => {
    let selectedTab = (tabType == "browse_talent_pool")?0:1;
    Mixpanel.track(`TalentPool -> ${selectedTab===0?"Browse":"Buckets"}`);
    this.setState({selectedTab, status: (tabType == "browse_talent_pool")?true:false, keyword: null}, () => {
      this.props.reRenderContent({
        itemsPerPage: this.state.itemsPerPage,
        activePage: 1,
        status: this.state.status,
      });      
    });
  }

  handleJobsPaginationChange = (data) => {
    const jobsskip = (data.page)?(data.page-1)*data.pageSize:1;
    const jobsLimit = data.pageSize || this.state.jobsLimit;
    this.setState({ jobsskip,jobsLimit }, () => this.onSearchJobChange({target:{value:""}}) );
  };

  showJobsToAdd = ()=>{
    const {selectedId,jobsskip=0,jobsLimit=5,jobSearchKey="",addedToJobs=[]} = this.state;
    const qs = `&filter[skip]=${jobsskip}&filter[limit]=${jobsLimit}&searchKey=${jobSearchKey}&status=open`
    this.props.filterJobData(qs).then((res)=>{
    const {count=0,jobs=[]} = res;
    const jobsRows = jobs.map(row=>{
      return {
        id:row.jobId.toString(),
        job: (<div className="text-sm-center text-md-center text-center text-lg-left">
          <div className="text-dark"><small title="Job">{`Requisition ID: ${row.jobId}`}</small></div>
          <div title="Designation" className="card-title h6 mb-1 d-inline-block text-center text-uppercase">{row && row.jobname && row.jobname.nameName}</div>
       </div>),
        company: row && row.company && row.company.companyName,
        action: <Button kind="tertiary" small onClick={() => this.onAddJob(row.jobId, Number(selectedId))}>Add
        <svg className="bx--btn__icon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 7H4v2h3v3h2V9h3V7H9V4H7v3zm1 9A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" fillRule="evenodd" />
          </svg>
        </Button>,
      }
    });
    this.setState({ jobsRows, totalJobs: count });
  });
}

  searchJobTimer = null;

  onSearchJobChange = ({target:{value=""}}={})=>{
    if (this.searchJobTimer) {
      clearTimeout(this.searchJobTimer);
    }
    this.setState({jobSearchKey:value},()=>{
      if(value.length>2) this.searchJobTimer = setTimeout(this.showJobsToAdd,1000);
    });
  }

  onAddJob = (jobId = 0, profileId = 0) => {
    let { addedToJobs } = this.state;
    addedToJobs.push(jobId);
    this.setState({
      selectedId : 0, showAddToJobModel : false,
      addedToJobs, jobSearchKey: "",
      jobsRows: [], jobsskip: 0
    }, () => this.props.addProfileToJob(jobId, profileId));//.then(this.showJobsToAdd)
  }

  resetStates = () => {
    this.setState({
      selected:{},
      checked:[],
      Filter:[],
    },this.handleTalentPoolTabsClick('add_to_bucket'));
  }

  getSelectedRowsMails = (rows) => {
    const selected = Array.isArray(rows) && rows.map(r => r.id);
    const { profileData: { profiles: { jobs = [] } = {} } = {} } = this.props;
    const mailsArray = jobs !== null ? jobs.filter(({ _source: { email = '' }, _id = null }) => selected.indexOf(_id) > -1) : [];
    const emailData = [];
    if (mailsArray && Array.isArray(mailsArray)) {
      mailsArray.forEach((e) => {
        const { _source: { email = null, profileId = null } } = e || {};
        if (email !== null && profileId !== null && email.length > 0) {
          emailData.push({
            email,
            profileId,
          });
        }
      });
    }
    this.setState({ selectTemplateModal: true, selectedEmails: emailData });
  }

  templateModalSubmit = () => {
    const { selectedEmails, selectedEmailTemplate } = this.state;
    if (selectedEmailTemplate === null || selectedEmailTemplate.indexOf('placeholder-item') > -1) {
      this.setState({ noTemplateError: true });
      return;
    }
    if (Array.isArray(selectedEmails) && selectedEmails.length === 0) {
      this.setState({
        selectedEmailTemplate: 'placeholder-item',
        selectTemplateModal: false,
        selectedEmails: [],
        noTemplateError: false,
      });
      return;
    }
    const templateId = selectedEmailTemplate.split('_')[0];
    const templateName = selectedEmailTemplate.split('_')[1];
    const emailData = selectedEmails;
    const data = {
      templateId,
      templateName,
      emailData,
      actionName: 'user-defined-email',
    };

    this.setState({ selectedEmailTemplate: 'placeholder-item', selectTemplateModal: false, selectedEmails: [], noTemplateError: false }, () => this.props.sendEmails(data));
  }

  templateModalClose = () => {
    this.setState({ selectedEmailTemplate: 'placeholder-item', selectTemplateModal: false, selectedEmails: [], noTemplateError: false });
  }

  onEmailTemplateChange = (e) => {
    const selectedEmailTemplate = e.target.value;
    this.setState({ selectedEmailTemplate, noTemplateError: false});
  }

  jobBucketModals = () => (
    <div>
      <Modal
        onRequestSubmit={() => this.handleBucketNames()}
        onRequestClose={this.dismissAddToBucketModal}
        open={this.state.isOpenAddToBucketModal}
        modalLabel="Action"
        primaryButtonText="Submit"
        secondaryButtonText="Cancel"
        modalHeading="Add to Bucket"
      >
        <p className="bx--modal-content__text">
        <strong className="text-dark">
          Are you sure you want to proceed with this action?
        </strong>
        </p>
        <small className="pt-1 text-dark d-block">
          On submitting this action, this profle will be added to the bucket.
        </small>
        <TalentPoolBuckets 
          handleChange={(e) => this.handleChange(e)}
          handleChangeRadioButton={this.handleChangeRadioButton}
          useBucket={this.state.useBucket}
          buckets={this.props.buckets}
          bucketName={this.state.bucketName}
          isBucketNameValid={this.state.isBucketNameValid}
          invalidText={this.state.invalidText}
        />
      </Modal>
      <Modal
        onRequestClose={this.closeAddToJob}
        passiveModal
        open={this.state.showAddToJobModel}
        modalLabel="Search Job"
        modalHeading="Add to Job"
      >
        <Search className="mb-2" value={this.state.jobSearchKey} labelText="Search Jobs" placeHolderText="Enter job title" onChange={this.onSearchJobChange} />
        {this.state.jobsRows.length > 0 && (
          <div>
            <GenericDataTable
              rows={this.state.jobsRows}
              title="Jobs"
              headers={this.state.jobsHeaders}
            />
            <Pagination onChange={this.handleJobsPaginationChange} pageSizes={[5, 10, 15, 20, 25]} page={this.state.activePage} totalItems={this.props.jobs.count} />
          </div>
        )}
        {this.props.jobs.count === 0 && this.state.jobSearchKey.length > 0 && (
          <Tile className="">
            <div className="card-body text-center ">
              <div className="empty-icon">
                <i className="icon icon-3x icon-people" />
              </div>
              <p className="empty-title h6">There are no jobs to show for the current Search</p>
            </div>
          </Tile>
        )}
      </Modal>
    </div>
  );

  render() {
    const batchActions = [
      {
        label: 'Send Email',
        key: 'email',
        icon: {
          "id": "icon--email",
          "name": "icon--email",
          "tags": "icon--email",
          "styles": "",
          "viewBox": "0 0 16 11",
          "width": "16",
          "height": "11",
          "svgData": {
            "circles": "",
            "ellipses": "",
            "paths": [
              {
                "d": "M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z",
                "fill-rule": "nonzero"
               }
            ],
            "polygons": "",
            "polylines": "",
            "rects": ""
          }
        }
      }];
    const {
      profiles: { jobs = [], count } = {},
    } = this.props.profileData;
    const { profilePage = false, profileId = null } = this.props;
    const { perPageNumber, Filter,rowsData,headersInfo, selectedTab, checked,
      jobsRows, jobsHeaders, useBucket, jobSearchKey, totalJobs, selectTemplateModal,
      selectedEmailTemplate, noTemplateError,
    } = this.state;
    const { buckets = [],loading,jobsLoading, tasks, jobTaskId, user: { userId }, emailTemplates = null } = this.props;
    const customEmailTemplates = emailTemplates !== null && Array.isArray(emailTemplates) ? emailTemplates.filter(({ isUserTemplate = null }) => isUserTemplate == true) : [];
    const countValue = Math.ceil(count / perPageNumber);
    let taskId = '';
    if (tasks && tasks[userId] && Array.isArray(tasks[userId])) taskId = tasks[userId][0].taskId;

    const guideSteps = [
      {
        selector: '.unknown-class-meow-meow',
        content: 'This is the talent pool page, your personal repository of candidates.',
      },
      {
        selector: '.xpa-modal--resume-upload',
        content: 'Upload CVs of candiadates you would like to add to your talent pool.',
      },
      {
        selector: '.xpa-talent-pool--wrapper',
        content: 'This section shows the list of candidates in your talent pool.',
      },
      {
        selector: '.xpa-talent-pool--wrapper .bx--table-toolbar .bx--toolbar-search-container',
        content: 'Filter candidates list by name search',
      },
      {
        selector: '.xpa-talent-pool--wrapper .bx--data-table-v2 thead',
        content: 'Sort talent pool candidates by any of the available columns by clicking on them.',
      },
      {
        selector: '.xpa-talent-pool--wrapper .bx--data-table-v2 tbody tr:nth-child(1) td:last-child',
        content: 'View candidate CV here.',
      },
    ];

    const filter = JSON.stringify(Filter);
    const url = this.getFilterURL(filter);

    if (profilePage && profileId) {
      if (jobs.length > 0) {
        let row = Array.isArray(jobs) && jobs.filter(({ _id = null }) => profileId === Number(_id));
        row = this.properRowData(row[0]);
        return (
          <div className="col-sm d-block d-md-flex justify-content-center justify-content-lg-start p-0 text-center">
            {talentPoolCard(row, {
              addToJob: this.addToJob, activeAddToBucketModal: this.activeAddToBucketModal,
              profilePage: true, profileId,
            })}
            { this.jobBucketModals() }
          </div>
        );
      }
      return <div />;
    }

    return (
      <div className="xpav-rpt">
        <BackRow paths={this.paths} showGuidedTour={true} handleGuidedTourClick={() => this.setState({ productTourIsOpen: true })} />
        <ProductTour
          onRequestClose={this.closeTour}
          isOpen={this.state.productTourIsOpen}
          steps={guideSteps}
        />
        <Toast taskId={jobTaskId} successAction={this.toastSuccess} partialRefresh={()=>{}} />
        <h4 className="bx--type-gamma font-weight-bold text-capitalize">Talent Pool</h4>  
        <Toast taskId={taskId} successAction={this.toastSuccess} partialRefresh={this.refresh} />
        <div className="">
          <div className="mt-2 mb-2 xpa-bg__1">
            <div className="card-footer clearfix">
              <div className="float-left pt-2">
                Tap into your past candidates to find ones suitable for current open roles.
              </div>
              <div className="text-right xpa-modal--resume-upload" data-toggle="modal" data-target="#xpa-modal--resume-upload">
                <Button onClick={() => this.activateModal()}>
                  ADD NEW RESUME
                </Button>
              </div>
            </div>
          </div>
        </div>
        {this.props.jobTaskId && <InlineNotification lowContrast kind="success" title="This profile" subtitle="will be added to the selected job momentarily." hideCloseButton />}
        {
          (
            <div>
              <GenericTabs
                selected={selectedTab}
                tabContent={[
                  {
                    label: 'Browse Talent Pool ',
                    value: '',                    
                    onClick: () => this.handleTalentPoolTabsClick('browse_talent_pool'),
                  },
                  {
                    label: 'Buckets',
                    value: '',
                    selected: true,
                    onClick: () => this.resetStates(),
                  },
                ]}
              />
            </div>
          )
        }
        {(selectedTab === 0) && (
          <div className="bx--row mt-2 p-lg-2">
            <LeftNavFilter url={url} className="" setSelected={this.setSelected} />
            <div className="bx--col-lg bx--col-xs-12 p-0 mt-md-1">
            {/* <div className='row ml-0 mb-2 mt-2 mr-0' style={{ maxWidth: 500 }}>
              <Search
                light
                type="text"
                className="p-0 bx--col-xs-12 bx--col-sm-12"
                labelText="Search job"
                closeButtonLabelText=""
                placeHolderText="Search Talent Pool"
                onChange={this.handleSearchSubmit}
                id="search pool"
                value={this.state.keyword}
              />
            </div> */}
              {checked && Array.isArray(checked) && checked.length>0 && (
                <div className="mt-2"><label className="bx--label">Selected Filters:&nbsp;</label>
                  {checked.map((ele, key) => ( <Tag type="private" key={key}>{ele}</Tag>))}
                </div>
              )}
              <div>
                <div className=" xpa-bg__none">
                  <ResumeUpload
                    pageType="talentpool"
                    jobId={this.props.match.params.id}
                    dismiss={this.dismissModal}
                    active={this.state.activeModal}
                  />
                  <div className="table-responsive">
                    <GenericDataTable
                      rows={rowsData}
                      selectable
                      tableStyle={{ borderSpacing: '0px 6px', borderCollapse: 'separate' }}
                      trClass="m-0 p-0 rtp_row"
                      tdClass="m-0 p-0"
                      searchable
                      onSearchInputChange={this.handleSearchSubmit}
                      batchActions={batchActions}
                      batchActionClick={this.getSelectedRowsMails}
                      tbodyClass="bg-transparent"
                      headers={headersInfo}
                      rowClass="text-center p-0 bx--col-12"
                      extras={{ result: { className: 'p-0' } }}
                    />
                  </div>
                </div>
              </div>
              {jobs && jobs.length > 0 && (
                <div className="">
                  <Pagination
                    onChange={this.handlePaginationChange}
                    pageSizes={[10, 20, 30, 40, 50]}
                    page={this.state.activePage}
                    totalItems={count} />
                </div>
              )}
              <JDProcessOverlay show={loading || jobsLoading} message="processing..." />
              {this.state.rowsData && this.state.rowsData.length <= 0 && (<NoContentBox />)}
              {this.jobBucketModals()}
              <Modal
                onRequestSubmit={this.templateModalSubmit}
                onRequestClose={this.templateModalClose}
                open={selectTemplateModal}
                modalLabel="Email Templates"
                primaryButtonText="Submit"
                secondaryButtonText="Cancel"
                modalHeading="Send Mail"
              >
                <div className="bx--modal-content__text">
                  {noTemplateError && <InlineNotification lowContrast kind="error" title="No template selected" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ noTemplateError: false })} />}
                  <Select
                    labelText="Select Email Template"
                    className="mb-4"
                    id="email-templates-list"
                    onChange={this.onEmailTemplateChange}
                    value={selectedEmailTemplate}
                    defaultValue="placeholder-item"
                  >
                    <SelectItem disabled value="placeholder-item" text="Choose a template" />
                    {
                      customEmailTemplates && Array.isArray(customEmailTemplates)
                        && customEmailTemplates.map(({ displayName = '', id = null, templateName = '' }) => (<SelectItem key={id} name={templateName} label={displayName} value={`${id}_${templateName}`} />))
                    }
                  </Select>
                  {
                    <Fragment>
                      <span className="pt-0 pb-1 text-dark d-block">
                        Clicking on <strong>submit</strong> will send an email to the applicant.
                      </span>
                    </Fragment>
                  }
                </div>
              </Modal>
            </div>
          </div>
        )}
        {(selectedTab === 1) && (
          <div>
            <Buckets />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.roboroyTalentPool.loading || state.roboroyApplications.loading,
  error: state.roboroyTalentPool.error,
  profileData: state.roboroyTalentPool,
  tasks: state.resumeUpload,
  buckets: state.roboroyTalentPool.buckets,
  jobs: state.jobs,
  jobsLoading:state.jobs.loading,
  jobTaskId:state.jobs.task_id,
  emailTemplates: state.emailTemplates.templates,
});

const mapDispatchToProps = {
  reRenderContent,
  getBucketNames,
  createBucket,
  getFacets,
  addCandidatesToBuckets,
  filterJobData,
  addProfileToJob,
  getCustomEmailTemplates,
  sendEmails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentPool);
