import React, { Fragment } from 'react';
import {
  Button, InlineLoading,
  TextArea
} from 'carbon-components-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import Avatar from 'components/common/Avatar';
import "./workflowHelpers.css"

const ScheduleSubStageAction = ({
  eachSubStage, handleSubStageSchedule, isScheduleBtnLoading, isReschedule, isResend,
}) => {
  let scheduleButtonText = 'Schedule an interview';
  if (isReschedule) {
    scheduleButtonText = 'Re-Schedule an interview';
    return null;
  }
  if (isResend) {
    scheduleButtonText = 'Resend calendar invite';
  }
  return (
    <Fragment>
      { !isScheduleBtnLoading && (
        <Button
          small
          icon={
            {
              id: 'icon--calendar',
              name: 'icon--calendar',
              tags: 'icon--calendar',
              styles: '',
              viewBox: '0 0 14 16',
              width: '14',
              height: '16',
              svgData: {
                circles: '',
                ellipses: '',
                paths: [
                  {
                    'fill-rule': 'nonzero',
                    d: 'M0 5h14v1H0V5zm3-5h1v4H3V0zm7 0h1v4h-1V0zM0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 0 14.5v-12zm1 0v12a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5z',
                  },
                ],
                polygons: '',
                polylines: '',
                rects: '',
              },
            }
          }
          iconDescription="Yes"
          onClick={() => {
            handleSubStageSchedule({
              eachSubStage,
              appStageUserMapId: 'x0pa-dummy-id',
              isReschedule,
            });
          }}
          onFocus={() => { }}
          kind="primary"
          className="ml-0"
        >
          {
            scheduleButtonText
          }
        </Button>)
      }
      {
        isScheduleBtnLoading && (
          <Button
            className="ml-0"
            small
          >
            <InlineLoading className="justify-content-center x-btn-loader" description="Scheduling interview..." />
          </Button>)
      }
    </Fragment>
  );
};

const AssignedUserAvatar = ({ eachSubStage }) => {
  return (
    <div className="text-uppercase bx--type-zeta text-dark">
      <Avatar
        user={{
          firstname: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].userinfo && eachSubStage.collaborators[0].userinfo.firstname,
          lastname: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].userinfo && eachSubStage.collaborators[0].userinfo.lastname,
          picture: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].userinfo && eachSubStage.collaborators[0].userinfo.picture,
        }}
        size="30"
        classNameInfo="rounded-circle mr-1"
      />
      {`${eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].userinfo && eachSubStage.collaborators[0].userinfo.firstname} ${eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].userinfo && eachSubStage.collaborators[0].userinfo.lastname}`}
    </div>);
};

const SlotsPicker = (props) => {
  const {
    availableSlots,
    onChangeDateTime,
    onDeleteSlot,
    onAddSlot,
    changeEventAdditionalInfo,
    eventAdditionalInfo,
    isAdditionalInfoInvalid,
    isAdditionalInfoInvalidText
  } = props;
  const slotsKeys = (availableSlots && Object.keys(availableSlots)) || [];
  return (
    <Fragment>
      <div className="mb-4" style={{ maxWidth: '650px' }}>
        {/*<p className="mb-2">Please add interview time slots for the candidate. We will present 30mins interview slots.</p>*/}
        <p className="mb-2">Each interview slot is 30 minutes.
         If you would like a longer time slot, please select more than one(1) slot accordingly.</p>
         <p>Our students typically have their lessons from 9 am to 5 pm on weekdays. Please refrain from scheduling interviews during this timing. Do contact the student(s) directly and make alternative interview arrangement, if required.</p>
        {/* <p>Adding a time-slot from 2pm to 330pm will mean the student will be able to pick a STARTING time slot of 2pm, 230pm and 3pm.</p> */}
        {
          slotsKeys.map((o, i) => {
            const r = availableSlots[o];
            if (!r) return <div />;
            const { start, end, key, invalid, invalidMsg } = r || {};
            const marr = moment().toArray();
            let minTime = moment([marr[0], marr[1], marr[2], '08']).toDate();
            if (moment(start).diff(moment(), 'd') < 1) {
              minTime = moment([marr[0], marr[1], marr[2], marr[3]]).toDate();
            }
            // const maxTime = moment([marr[0], marr[1], marr[2], '20']).toDate();
            return (<div key={key} className="d-flex mb-4">
              <div className="mr-2">
                <DatePicker
                  placeholderText="Click to select a date and start time"
                  selected={start}
                  onChange={(dt) => onChangeDateTime(dt, key, 'start')}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="do MMM, yyyy HH:mm"
                  className="bx--text-input"
                  minDate={new Date()}
                // minTime={minTime}
                // maxTime={maxTime}
                />
              </div>
              {/* <div className="ml-2">
                  <DatePicker
                    placeholderText="Click to select end time"
                    selected={end}
                    onChange={(dt) => onChangeDateTime(dt, key, 'end')}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="HH:mm"
                    className={`bx--text-input ${invalid ? 'invalid' : ''}`}
                    disabled={!start}
                    showTimeSelectOnly
                    // minTime={minTime}
                    // maxTime={maxTime}
                  />
                  {
                    invalid && (<div className="bx--form-requirement">
                      {
                        invalidMsg || 'Invalid time slot'
                      }
                    </div>)
                  }
                </div> */}
              {
                slotsKeys && (slotsKeys.length > 1) && (
                  <Button kind="danger" small onClick={() => onDeleteSlot(key, r)} className="ml-2">Delete</Button>
                )
              }
            </div>
            );
          })
        }
        {
          slotsKeys && (slotsKeys.length < 7) && (
            <Button onClick={() => { onAddSlot(); }} className="mt-2 mb-2" small>Add slot</Button>
          )
        }
        <TextArea
          id="event-addl-info"
          name="event-addl-info"
          labelText="Addtional info(optional)*"
          className="mt-2"
          onChange={changeEventAdditionalInfo}
          helperText={<div>
            <div className="mb-1">
              <div>Please provide interview details e.g.</div>
              <div className="ml-3">
                <div className="">&#8226; Face-to-face Interview: Location, Who to look for, Things to bring</div>
                <div className="">&#8226; E-interview: To send meeting join link via email directly to students, if not arranged yet.</div>
              </div>
            </div>
            <div>Information provided in the field will be sent immediately to the student via a system-generated email. Please contact the student directly in the event there is a need to re-arrange the scheduled interview.</div>
          </div>}
          placeholder={"Please provide interview details e.g.\n-> Face-to-face Interview: Location, Who to look for, Things to bring\n-> E-interview: To send meeting join link via email directly to students, if not arranged yet."}
          required
          value={eventAdditionalInfo}
          invalid={isAdditionalInfoInvalid}
          invalidText={isAdditionalInfoInvalidText}
        />
      </div>
    </Fragment >
  );
};


export {
  ScheduleSubStageAction,
  AssignedUserAvatar,
  SlotsPicker,
};
