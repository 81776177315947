import { withTranslation } from 'react-i18next';
import React from 'react';
import { Select, SelectItem } from 'carbon-components-react';

const ApplicantSort = ({ onSortValueChange, t }) => (
    <Select
      light
      className="bx--col-xs-12 p-0"
      hideLabel
      invalidText="A valid value is required"
      onChange={(e) => onSortValueChange(e.target.value)}
      id="select-1"
      defaultValue="placeholder-item">
      <SelectItem disabled hidden value="placeholder-item" text={t('sortBy')} />
      <SelectItem value="appIdAsc" text={`${t('applDate')} ${t('ascending')}`} />
      <SelectItem value="appIdDsc" text={`${t('applDate')} ${t('descending')}`} />
      <SelectItem value="profile.firstNameAsc" text={`${t('applicant')} ${t('name')} ${t('ascending')}`} />
      <SelectItem value="profile.firstNameDsc" text={`${t('applicant')} ${t('name')} ${t('descending')}`} />
      <SelectItem value="skillScoreAsc" text={`${t('loyalty')} ${t('ascending')}`} />
      <SelectItem value="skillScoreDsc" text={`${t('loyalty')} ${t('descending')}`} />
      <SelectItem value="compatibilityScoreAsc" text={`${t('cvRelevance')} ${t('ascending')}`} />
      <SelectItem value="compatibilityScoreDsc" text={`${t('cvRelevance')} ${t('descending')}`} />
      <SelectItem value="finalScoreAsc" text={`${t('performance')} ${t('ascending')}`} />
      <SelectItem value="finalScoreDsc" text={`${t('performance')} ${t('descending')}`} />
    </Select>
);

export default (withTranslation()(ApplicantSort));
