import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { TextInput } from 'carbon-components-react';

const noPattern = ['department', 'internalCode'];

const InputFieldBox = ({
  type = 'text',
  title,
  name,
  handleChange,
  state,
  min,
  max,
  mainClass,
  titleClass,
  placeholder,
  className,
  inputClass,
  required,
  labelClass,
  disabled,
}) => (
  <div className={`${mainClass || 'form-group bx--row m-0'}`}>
    <div className={`${titleClass || 'col-sm-12 col-md-3'}`}>
      <label className={`${labelClass || 'form-label pt-3 pb-0 mb-0'}`}>
        {required ? <div className="bx--row m-0 pl-1"><div>{title}</div><div className="text-danger">*</div></div> : title }
      </label>
    </div>
    <div className={`${className || 'newjob_input_container col-xs-12 col-sm-12 col-md-9'}`}>
      {type === 'number' ? (
        <TextInput
          required={required}
          type={type}
          placeholder={placeholder || "Sample text"}
          className={inputClass || ''}
          name={name}
          hideLabel
          min={min}
          max={max}
          labelText=""
          value={state[name] && state[name] !== null ? state[name] : ''}
          onChange={handleChange}
          disabled={disabled}
        />
      ) : (
        <TextInput
          required={required}
          type={type}
          pattern={noPattern.includes(name) ? '/^[ A-Za-z0-9()+-*%,]*$/' : '^/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/'}
          placeholder={placeholder || "Sample text"}
          className={inputClass || ''}
          name={name}
          labelText=""
          hideLabel
          minLength={0}
          maxLength={max}
          value={state[name] && state[name] !== null ? state[name] : ''}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </div>
  </div>
);

export default InputFieldBox;
