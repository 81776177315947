import { withTranslation } from 'react-i18next';
import React, { Component } from "react";
import { Tile, DatePicker, DatePickerInput, Breadcrumb, BreadcrumbItem, } from "carbon-components-react";
import moment from "moment";
import "./style.css"

class Duration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(7,'days').format("MM/DD/YYYY"),
      endDate: moment().format("MM/DD/YYYY"),
      isHourly: true,
      isDaily: true,
      isWeekly: false,
      isMonthly: false,
      isYearly: false,
      period: '1w',
      category: 'Hourly',
    };
  }

  setPeriodInterval = (period) => {
    const selectedPeriod = period.target.innerText;
    let { isHourly, isDaily, isWeekly, isMonthly, isYearly } = this.calculateDefaultIntervals(selectedPeriod);
    this.setState({ isHourly, isDaily, isWeekly, isMonthly, isYearly });
  }

  calculateDefaultIntervals = (period) => {
    let intervalDefaults = {
      isHourly: false,
      isDaily: false,
      isWeekly: false,
      isMonthly: false,
      isYearly: false,
    };

    switch (period) {
      case '1w':  { intervalDefaults.isHourly = true; intervalDefaults.isDaily = true; }; break;
      case '4w':  { intervalDefaults.isDaily = true;  intervalDefaults.isWeekly = true; }; break;
      case '1y':  { intervalDefaults.isWeekly = true; intervalDefaults.isMonthly = true; }; break;
      case 'Mtd': { intervalDefaults.isDaily = true; }; break;
      case 'Qtd': { intervalDefaults.isDaily = true; }; break;
      case 'Ytd': { intervalDefaults.isMonthly = true; }; break;
      case 'All': { intervalDefaults.isMonthly = true; intervalDefaults.isYearly = true; }; break;
      default: { intervalDefaults.isDaily = true; }; break;
    }    

    return intervalDefaults;
  }

  onDurationChange = ({startPeriod, endPeriod})=>{
      let startDate = startPeriod || (this.state.startDate && moment(this.state.startDate).startOf('day')) || moment().startOf('day');
      let endDate = endPeriod || (this.state.endDate && moment(this.state.endDate).endOf('day')) || moment().endOf('day');
      let category = this.state && this.state.category;
      this.props.durationChange({startDate: moment(startDate).unix()*1000,endDate: moment(endDate).unix()*1000,interval:category.toLowerCase()});
  }

  onDateChange = (Dates) => {
    if (Dates && Dates.length > 0)
      (Dates.length > 1) ?
        this.setState({ startDate: moment(Dates[0]).format('MM/DD/YYYY'), 
                        endDate: moment(Dates[1]).format('MM/DD/YYYY'),
                        period: '', 
                        category: 'Daily',
                        ...(this.calculateDefaultIntervals('')),
                      },()=>this.onDurationChange({})) :
        this.setState({ startDate: moment(Dates[0]).format('MM/DD/YYYY'),
                        period: '', 
                        category: 'Daily', 
                        ...(this.calculateDefaultIntervals('')),                        
                      },()=>this.onDurationChange({}));
    return;
  }

  onPeroidSelect = (clik) => {
    const period = clik.target.innerText;
    let category = this.calculateDefaultCategory(period);
    let timePeriods = this.calculateTimePeriods(period);
    let {startPeriod, endPeriod} = timePeriods;

    this.setState({ 
      period, 
      category,
      startDate: moment(startPeriod).format("MM/DD/YYYY"), 
      endDate:   moment(endPeriod).format("MM/DD/YYYY") 
     },()=>this.onDurationChange({
      startPeriod: timePeriods && timePeriods.startPeriod, 
      endPeriod:   timePeriods && timePeriods.endPeriod
    }));
  }

  calculateDefaultCategory = (period) => {
    let category = "";

    switch (period) {
      case '1w': { category = "Hourly";}; break;
      case '4w': { category = "Daily";}; break;
      case '1y': { category = "Weekly";}; break;
      case 'Mtd': { category = "Daily";}; break;
      case 'Qtd': { category = "Daily";}; break;
      case 'Ytd': { category = "Monthly";}; break;
      case 'All': { category = "Monthly";}; break;
      default: { category = "Hourly" }; break;
    }    

    return category;
  }

  calculateTimePeriods = (period) => {
    let startPeriod = moment().startOf('day').unix()*1000;
    let endPeriod = moment().endOf('day');

    switch (period) {
      case '1w':  { startPeriod = moment().subtract(1, 'weeks').startOf('day'); }; break;
      case '4w':  { startPeriod = moment().subtract(4, 'weeks').startOf('day'); }; break;
      case '1y':  { startPeriod = moment().subtract(1, 'years').startOf('day'); }; break;
      case 'Mtd': { startPeriod = moment().startOf('month').startOf('day'); }; break;
      case 'Qtd': { startPeriod = moment().startOf('quarter').startOf('day'); }; break;
      case 'Ytd': { startPeriod = moment().startOf('year').startOf('day'); }; break;
      case 'All': { startPeriod = moment().startOf('year').year(1970).startOf('day'); }; break;
      default: { }; break;
    }

    return {startPeriod, endPeriod};
  }

  onCategorySelect = (clik) => {
    const category = clik.target.innerText;
    let period = this.state.period;
    let timePeriods = this.calculateTimePeriods(period);
    let {startPeriod, endPeriod} = timePeriods;

    this.setState({ 
      category, 
      startDate: moment(startPeriod).format("MM/DD/YYYY"), 
      endDate: moment(endPeriod).format("MM/DD/YYYY") 
    },()=>this.onDurationChange({
      startPeriod,
      endPeriod
    }));
  }
  
  componentDidMount (){
    this.onDurationChange({});
  }
  render() {
    const { period, category,startDate,endDate } = this.state;
    const { hide } = this.props;
    return (
      <div className="duration mb-lg-0 rounded p-0 pt-2 pt-lg-3 p-lg-3">
      
        {hide && <div className="filter">
          <div className="bx--row row m-0 p-0 justify-content-center">
            <div className="period-list rounded bx--col-xs-12 align-items-center bx--col-lg-4 m-lg-0 m-2 justify-content-center card shadow-sm mr-lg-3">
              <div className="periods row bx--row justify-content-center align-items-center" onClick={this.setPeriodInterval}>
                <a style={{"cursor":"pointer"}} className={`m-0 ${period === "1w" ? "period selected" : "period"}`}>
                  <div onClick={this.onPeroidSelect}>1w</div>
                </a>
                <a style={{"cursor":"pointer"}} className={`m-0 ${period === "4w" ? "period selected" : "period"}`}>
                  <div onClick={this.onPeroidSelect}>4w</div>
                </a>
                <a style={{"cursor":"pointer"}} className={`m-0 ${period === "1y" ? "period selected" : "period"}`}>
                  <div onClick={this.onPeroidSelect}>1y</div>
                </a>
                <a style={{"cursor":"pointer"}} className={`m-0 ${period === "Mtd" ? "period selected" : "period"}`}>
                  <div onClick={this.onPeroidSelect}>Mtd</div>
                </a>
                {/* <a className={`${period === "Qtd" ? "period selected" : "period"}`}>
                  <div onClick={this.onPeroidSelect}>Qtd</div>
                </a> */}
                <a style={{"cursor":"pointer"}} className={`m-0 ${period === "Ytd" ? "period selected" : "period"}`}>
                  <div onClick={this.onPeroidSelect}>Ytd</div>
                </a>
                <a style={{"cursor":"pointer"}} className={`m-0 ${period === "All" ? "period selected" : "period"}`}>
                  <div onClick={this.onPeroidSelect}>All</div>
                </a>
              </div>
            </div>
            <div className="category-list bx--col-xs-12 m-lg-0 m-2 align-items-center justify-content-center bx--col-lg-3 rounded shadow-sm card mr-lg-3">
              <div className="categories row bx--row align-items-center justify-content-center">
                {this.state.isHourly && <a style={{"cursor":"pointer"}} className={`m-0 ${category === "Hourly" ? "category selected" : "category"}`} >
                  <div onClick={this.onCategorySelect}>Hourly</div>
                </a>}
                {this.state.isDaily && <a style={{"cursor":"pointer"}} className={`m-0 ${category === "Daily" ? "category selected" : "category"}`} >
                  <div onClick={this.onCategorySelect}>Daily</div>
                </a>}
                {this.state.isWeekly && <a style={{"cursor":"pointer"}} className={`m-0 ${category === "Weekly" ? "category selected" : "category"}`} >
                  <div onClick={this.onCategorySelect}>Weekly</div>
                </a>}
                {this.state.isMonthly && <a style={{"cursor":"pointer"}} className={`m-0 ${category === "Monthly" ? "category selected" : "category"}`} >
                  <div onClick={this.onCategorySelect}>Monthly</div>
                </a>}
                {this.state.isYearly && <a style={{"cursor":"pointer"}} className={`m-0 ${category === "Yearly" ? "category selected" : "category"}`} >
                  <div onClick={this.onCategorySelect}>Yearly</div>
                </a>}
              </div>
            </div>
            <div className="range align-items-center justify-content-center m-lg-0 m-2 bx--col-lg-4 rounded shadow-sm card mr-lg-3">
              <DatePicker className="justify-content-center pl-4" minDate="1/10/1970" maxDate={moment().format("MM/DD/YYYY")} datePickerType="range" onChange={d => this.onDateChange(d)} light >
                <DatePickerInput className="mb-0 ml-2" id="start" placeholder="Start Date" value={moment(startDate).format("MM/DD/YYYY")} />
                <DatePickerInput className="mb-0 mr-2" id="end" placeholder="End Date" value={moment(endDate).format("MM/DD/YYYY")} />
              </DatePicker>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default Duration;
