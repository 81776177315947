import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loading } from 'carbon-components-react';

import { getEmailTemplate } from 'actions/email-templates';

import {
  emailLogoSection, emailBodyStart,
  emailBodyEnd, dummyEmailPlaceholders,
} from 'containers/common/constants';
import { getCompanyInfo } from 'actions/admin/AdminSettings';

class PreviewData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      emailData: 'No data',
      emailSubject: '(No subject)',
    };
  }

  componentDidMount() {
    const { match: { params: { template } } } = this.props;
    if (template === 'preview') {
      this.setState({
        isLoading: false,
        emailData: localStorage.getItem('emailPreview'),
        emailSubject: localStorage.getItem('emailPreviewSubject'),
      });
      return;
    }
    this.props.getCompanyInfo().then(({ data }) => {
      this.props.getEmailTemplate(template).then((res) => {
        let { emailBg = '#F3F4F5', logo = '/republic-polytechnic.jpg' } = data;
        logo = 'https://x0pa-email-assets.s3-ap-southeast-1.amazonaws.com/republic-polytechnic.jpg';
        if (emailBg === null) emailBg = '#F3F4F5';
        let emailLogoSections = emailLogoSection.replace(/{{emailBg}}/g, emailBg);
        emailLogoSections = emailLogoSections.replace('{{logo}}', logo);
        let emailSubject = res.subject || '(No subject)';
        let EmailData = emailLogoSections + emailBodyStart + res.emailBody + emailBodyEnd;
        EmailData = EmailData.replace(/{{{/g, "{{");
        EmailData = EmailData.replace(/}}}/g, "}}");
        EmailData = EmailData.replace(/{{#if firstName}}/g, "");
        EmailData = EmailData.replace(/{{#if lastName}}/g,"");
        EmailData = EmailData.replace(/{{#unless lastName}},/g, "");
        EmailData = EmailData.replace(/{{\/unless}}/g, "");
        EmailData = EmailData.replace(/{{\/if}}/g, "");
        EmailData = EmailData.replace(/{{else}}/g, "");
        Object.keys(dummyEmailPlaceholders).map(ele => {
          const replaceStr = "{{" + ele + "}}";
          EmailData = EmailData.replace(new RegExp(replaceStr, 'g'), dummyEmailPlaceholders[ele]);
          emailSubject = emailSubject.replace(new RegExp(replaceStr, 'g'), dummyEmailPlaceholders[ele]);
        });
        
        this.setState({
          isLoading: false,
          emailData: EmailData,
          emailSubject,
        });
      });
    });
  }

  render() {
    const { isLoading, emailData, emailSubject } = this.state;
    const htmlBody = { __html: emailData };
    return (
      <div>
        {
          !isLoading && (
          <div>
            <div className="bx--type-epsilon font-weight-normal p-4">
              <span className="bx--type-epsilon">Subject:</span> {emailSubject}
            </div>
            <div className="emailPreview" dangerouslySetInnerHTML={htmlBody} />
          </div>)
        }
        {
          isLoading && (<Loading />)
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  companyInfo: state.adminSettings && state.adminSettings.companyInfo,
});

const mapDispatchToProps = {
  getCompanyInfo,
  getEmailTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewData);
