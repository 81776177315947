import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import Avatar from 'components/common/Avatar';
import { AISourcedEmailMaskHelpInfo, AISourcedEmailMissingWarning } from 'components/common/atoms';
import LinkedInLogo from 'assets/images/linkedin21px.png';
import {
  SkeletonText, Link, Button, Modal
} from 'carbon-components-react';
import ColoredCircle from 'components/common/ColoredCircle';
import SetMixPanel from 'components/common/GetCurrentPath';

const track = {
  APPLICATION_NAME_CLICKED: 'APPLICATION_NAME_CLICKED',
  APPLICATION_EMAIL_CLICKED: 'APPLICATION_EMAIL_CLICKED',
  APPLICATION_JOB_CLICKED: 'APPLICATION_JOB_CLICKED',
};

const ApplicantScores = ({ application, minMax, getNormalized, t }) => (
  <div className="applicant-score-card col-xs-12 col-sm-12 col-md-12 col-lg-6 p-0 pt-3">
    <div className="container xp-reset">
      <div className="row">
        <div className={`space-top col text-center text-uppercase ${(application && application.skillScore === null) ? ' mb-0 ' : ''}`}>
          <h3 className={`xp-c-text-align__center ${(application && application.skillScore === null) ? ' mb-0 ' : ''}`}>
            {application && application.skillScore === null ? (
              <SkeletonText className="xpa-circle--skeleton mb-0" />
            ) : (
                <ColoredCircle
                  className="xp-c__colored-circle--big"
                  getColor="getAbsoluteMatchingColor"
                  value={application && application.skillScore ? Math.round(application.skillScore) : (application && application.skillScore === null ? 'N/A' : 0)} />
              )}
          </h3>
          <small className="text-uppercase">{t('skillScore')}</small>
        </div>
        <div className={`space-top col text-center text-uppercase ${(application && application.compatibilityScore === null) ? ' mb-0 ' : ''}`}>
          <h3 className={`xp-c-text-align__center ${(application && application.compatibilityScore === null) ? ' mb-0 ' : ''}`}>
            {application && application.compatibilityScore === null ? (
              <SkeletonText className="xpa-circle--skeleton mb-0" />
            ) : (
                <ColoredCircle
                  className="xp-c__colored-circle--big"
                  getColor="getAbsoluteMatchingColor"
                  value={(application && application.compatibilityScore) ? ((application.compatibilityScore == -1) ? 'N/A' : Math.round(application.compatibilityScore)) : (application && application.compatibilityScore === null ? 'N/A' : 0)} />
              )}
          </h3>
          <small className="text-uppercase">{t('compatibilityScore')}</small>
        </div>
        <div className={`space-top col text-center text-uppercase ${(application && application.finalScore === null) ? ' mb-0 ' : ''}`}>
          <h3 className={`xp-c-text-align__center ${(application && application.finalScore === null) ? ' mb-0 ' : ''}`}>
            {application && application.finalScore === null ? (
              <SkeletonText className="xpa-circle--skeleton mb-0" />
            ) : (
                <ColoredCircle
                  className="xp-c__colored-circle--big"
                  getColor="getAbsoluteMatchingColor"
                  value={(application && application.finalScore) ? ((application.finalScore == -1) ? 'N/A' : Math.round(application.finalScore)) : (application && application.finalScore === null ? 'N/A' : 0)} />
              )}
          </h3>
          <small className="text-uppercase">{t('finalScore')}</small>
        </div>
      </div>
    </div>
  </div>
);

const ApplicantCard = ({
  application, history, showEmail, skeleton,
  t, offerAcceptedCount, updatePlacementInfo,
  activePlacementModal, dismissPlacementModal, placementModal, isApplicantInfo,
  user
}) => {
  // console.log(application)
  const {
    isSuperAdmin,
    isAdmin,
    company: {
      ctypeId
    } = {}
  } = user || {};
  const { profile, scoresInfo } = application || {};
  const { firstName, lastName, preferName } = profile || {};
  let formattedName = `${firstName || ''} ${lastName || ''}`;
  if (!preferName) {
    formattedName = `${firstName || ''} ${lastName || ''}`;
    if (!firstName && !lastName) {
      formattedName = 'Name Unidentified';
    }
  }
  const jobId = application.job && application.job.jobId ? application.job.jobId : application.jobId;
  return (
    skeleton ? (
      <div className="bx--grid m-0 pl-0 ml-3">
        <div className="bx--row xpa-center--Y">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 pt-lg-2 pl-lg-3 pt-md-2 pl-md-3 p-0">
            <SkeletonText />
            <SkeletonText />
            <SkeletonText />
            <SkeletonText />
            <SkeletonText />
          </div>
          {/* <ApplicantScores
                application={{
                  compatibilityScore: null,
                  finalScore: null,
                  skillScore: null
                }}
                t={t}
                minMax={[]}
                getNormalized={()  => ({})} /> */}
        </div>
      </div>
    ) : (
        <div className="bx--grid m-0  pl-0 ml-3">
          <div className="bx--row xpa-center--Y align-items-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="xpa-left--1">
                <small className="text-dark">
                  {t('applicationId')}: {application.appId}
                </small>
                <div className="xpa-cand-info--card-info h5 mb-0 mt-1">
                  {(<Avatar
                    user={{
                      firstname: application.profile.firstName,
                      lastname: application.profile.lastName,
                      picture: application.shortlisted && application.inviteaccept ? application && application.candidateInfo && application.candidateInfo.userinfo && application.candidateInfo.userinfo.picture : null,
                    }}
                    size="30"
                    classNameInfo="rounded-circle mr-1"
                  />)}
                  <div onClick={() => { history && history.push && history.push(`/app/campus/candidate/${application.appId}/job/${jobId}/candidateprofile`) }} className="card-title mb-1 d-inline-block xpa-link text-center font-weight-bold bx--type-delta  text-capitalize">
                    {firstName} {lastName}
                  </div>
                  {
                    application && application.scoresInfo && application.scoresInfo.choicesRecommendation === true ? (
                      <div className="bx--tag bx--tag--yellow ml-2">
                        Recommended
                      </div>
                    ) : null
                  }
                  {/* <Link

                to={`/app/campus/candidate/${application.appId}/job/${application.job && application.job.jobId ? application.job.jobId : application.jobId}/candidateprofile`}
                onClick={({ target = {} }) => {
                  SetMixPanel(track.APPLICATION_NAME_CLICKED); // (AppId-${application.appId},JobId-${application.job ? application.job.jobId : 0})
                  const jobId = application.job && application.job.jobId ? application.job.jobId : application.jobId;
                  return ;
                }}
              >

              </Link> */}
                  {application.profile && application.profile.linkedin
                    && (<a href={application.profile.linkedin.indexOf('http') >= 0 ? application.profile.linkedin : `http://${application.profile.linkedin}`} target="_blank" className="ml-1"><img src={LinkedInLogo} /></a>)}
                </div>
                {showEmail && application.profile.email ? (
                  <div>
                    <span className="">{t('email')}: </span>
                    <span className="text-dark bx--modal-header__label">
                      <a href={`mailto:${application.profile.email}`} onClick={() => SetMixPanel(track.APPLICATION_EMAIL_CLICKED)}>{application.profile.email}</a>
                      {/* ${application.profile.email} */}
                      {application.profile && application.profile.email
                        && !(application.shortlisted === true)
                        && application.applicationSourceId && application.applicationSourceId === 5 ? (
                          <span className="position-relative ml-1" style={{ top: '0.25rem' }}>
                            <AISourcedEmailMaskHelpInfo />
                          </span>
                        ) : null}
                    </span>
                  </div>
                ) : ('')}
                {application.job && application.job.jobname && application.job.jobname.nameName && application.job.jobname.nameName !== null && (
                  <div className="bx--type-caption">
                    <div className="bx--row ml-0">
                      <div className="">{t('appliedFor')}</div>
                      <div className="ml-1">
                        <a className="text-capitalize" href={`/app/campus/job/${application.job.jobId}`}>
                          {application.job.jobname.nameName}
                        </a>
                      </div>
                      {
                        application.job.company && application.job.company.companyId && application.job.company.companyName && (
                          <Fragment>
                            <div className="ml-1">with</div>
                            <div className="ml-1">
                              <a className="text-capitalize" href={`/app/campus/company/info/${application.job.company.companyId}`}>
                                {application.job.company && application.job.company.displayCompanyName}
                              </a>
                            </div>
                          </Fragment>
                        )
                      }
                    </div>
                  </div>
                )}
                {application && application.scoresInfo && application.scoresInfo.studentprofile && application.scoresInfo.studentprofile.faculty &&
                  application.scoresInfo.studentprofile.faculty.facultyName ? (
                    <div className="bx--type-caption">
                      <div className="bx--row ml-0">
                        <div className="">{t('facultyName')}</div>
                        <div className="ml-1">
                          <a className="text-capitalize">
                            {application.scoresInfo.studentprofile.faculty.facultyName}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : ('')
                }
                {application && application.scoresInfo && application.scoresInfo.studentprofile && application.scoresInfo.studentprofile.courses &&
                  application.scoresInfo.studentprofile.courses.courseName ? (
                    <div className="bx--type-caption">
                      <div className="bx--row ml-0">
                        <div className="">{t('courseName')}</div>
                        <div className="ml-1">
                          <a className="text-capitalize">
                            {application.scoresInfo.studentprofile.courses.courseName}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : ('')
                }
                <div className="row">
                  <div className="pl-0 text-black-50">
                    {application && application.profile && application.profile.address && application.profile.address.location ? (
                      <small className="col-8 text-capitalize" title="Location">
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 12, height: 16 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin">
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                          <circle cx="12" cy="10" r="3" />
                        </svg>
                        {`  ${application.profile.address.location.locationName}`}</small>)
                      : ''
                    }
                    {application && application.profile && application.profile.totalExp ? (
                      <small className="col-8 text-capitalize" title="Experience">
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 12, height: 12 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-briefcase mb-1">
                          <rect x="2" y="7" width="20" height="14" rx="2" ry="2" />
                          <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
                        </svg>
                        {Math.round(application.profile.totalExp) == 0 ? ' No Experience' : `  ${Math.round(application.profile.totalExp)} ${t('yearsExp')}`}
                      </small>) : ''
                    }
                  </div>
                </div>
                {/* {application.hasOwnProperty('roboroyShortlist') && (application.roboroyShortlist === true) && (
              <div>
                <div className="bx--type-epsilon">
                  <span aria-hidden="true" className="fa fa-thumbs-up text-success" />
                  <small className="text-dark ml-1">Roboroy upvotes this candidate</small>
                </div>
              </div>
            )}
            {application.hasOwnProperty('roboroyShortlist') && (application.roboroyShortlist === false) && (
              <div>
                <div className="bx--type-epsilon">
                  <span aria-hidden="true" className="fa fa-thumbs-down text-danger" />
                  <small className="text-dark ml-1">Roboroy downvotes this candidate</small>
                </div>
              </div>
            )} */}
                <div>
                  {/* {application && application.isInTalentPool && (
                <span className="badge badge-outline-secondary mr-2">Talent Pool Candidate</span>
              )} */}
                  {application.hasOwnProperty('isLiked')
                    && application.isLiked === true && (
                      <span className="bx--tag bx--tag--blue mr-2">
                        <i className="fa fa-heart-o" aria-hidden="true" />&nbsp;&nbsp;{t('favouriteCandidate')}
                      </span>
                    )
                  }
                  {/* {application.applicationSourceId && application.applicationSourceId === 5 ? (
                <span className="badge badge-outline-dark d-inline-block ml-0">
                  AI Sourced
                  </span>
              ) : null} */}
                  {/* {application.applicationSourceId && application.applicationSourceId === 5 ? (
                <span className="position-relative ml-1" style={{ top: '0.25rem' }}>
                  <AISourcedHelpInfo />
                </span>
              ) : null} */}
                </div>
                <div>
                  {(<div>
                    <div className={`${application.inviteaccept}`}>
                      {!application.hasOwnProperty('isActive') || (application.isActive === false) && (
                        <div>
                          <div className="bx--tag bx--tag--red  mr-2">
                            {t('application')} {t('delete')}
                          </div>
                        </div>
                      )}
                      {application.hasOwnProperty('shortlisted') && application.shortlisted && !(application.isActive === false) && (
                        <div>
                          <span className="bx--tag bx--tag--green mr-2 ml-0">
                            {t('shortlisted')}
                          </span>
                          {application.hasOwnProperty('inviteaccept') && (application.invitesent === true)
                            && (application.inviteaccept === null) && (!application.fromApplicant) && (
                              <span className="bx--tag bx--tag--cool-gray mr-2">
                                {t('awaitingEmailAcceptance')}
                              </span>
                            )}
                          {application.hasOwnProperty('inviteaccept') && (application.inviteaccept == true) && (
                            <span className="bx--tag bx--tag--green mr-2">
                              {t('invite')} {t('accepted')}
                            </span>
                          )}
                          {application.hasOwnProperty('inviteaccept') && (application.inviteaccept == false) && (
                            <span className="badge badge-danger mr-2">
                              {t('invite')} {t('rejected')}
                            </span>
                          )}
                          {application.hasOwnProperty('inviteaccept') && (application.inviteaccept == true)
                            && application.hasOwnProperty('offerConsentSent') && (application.offerConsentSent === null)
                            && application.hasOwnProperty('isHired') && (application.isHired === null) && (
                              <span className="bx--tag bx--tag--cool-gray mr-2">
                                {t('interviews')} {t('offersInProgressTab')}
                              </span>
                            )}
                          {(application.hasOwnProperty('offerConsentSent')
                            && application.hasOwnProperty('offerConsentAccept')
                            && (application.offerConsentSent === true) ||
                            (application.workflow && application.workflow.outcomeId))
                            && (
                              <span className="bx--tag bx--tag--green mr-2">
                                {t('interviews')} {t('completed')}
                              </span>
                            )
                          }
                          {application.hasOwnProperty('offerConsentSent')
                            && application.hasOwnProperty('offerConsentAccept')
                            && (application.offerConsentSent === true)
                            && (application.offerConsentAccept === null)
                            && (
                              <span className="bx--tag bx--tag--cool-gray mr-2">
                                {t('awaiting')} {t('offer')} {t('consent')}
                              </span>
                            )
                          }
                          {application.hasOwnProperty('offerConsentSent')
                            && application.hasOwnProperty('offerConsentAccept')
                            && (application.offerConsentSent === true)
                            && (application.offerConsentAccept === true)
                            && (
                              <span className="bx--tag bx--tag--green mr-2">
                                {t('offer')} {t('consent')} {t('accepted')}
                              </span>
                            )
                          }
                          {application.hasOwnProperty('offerConsentSent')
                            && application.hasOwnProperty('offerConsentAccept')
                            && (application.offerConsentSent === true)
                            && (application.offerConsentAccept === false)
                            && (
                              <span className="badge badge-danger mr-2">
                                {t('offer')} {t('consent')} {t('rejected')}
                              </span>
                            )
                          }
                          {application.hasOwnProperty('offerConsentSent')
                            && application.hasOwnProperty('offerConsentAccept')
                            && (application.offerConsentSent === false)
                            && (application.offerConsentAccept === null)
                            && (
                              <span className="badge badge-danger mr-2">
                                {t('inteviews')} {t('round')} {t('rejected')}
                              </span>
                            )
                          }
                       
                          {application.hasOwnProperty('inviteaccept') && (application.inviteaccept == true)
                            && application.hasOwnProperty('offerConsentSent') && (application.offerConsentSent === true)
                            && application.hasOwnProperty('offerConsentAccept') && (application.offerConsentAccept === true)
                            && application.hasOwnProperty('isHired') && (application.isHired === null)
                            && (
                              (offerAcceptedCount != null && offerAcceptedCount != undefined && offerAcceptedCount > 0) ?
                                (<span title="This candidate is not hired" className="badge text-danger  mr-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-frown"><circle cx="12" cy="12" r="10" /><path d="M16 16s-1.5-2-4-2-4 2-4 2" /><line x1="9" y1="9" x2="9.01" y2="9" /><line x1="15" y1="9" x2="15.01" y2="9" /></svg>
                    &nbsp;{t('not')} {t('hired')}
                                </span>) : (<span className="bx--tag bx--tag--cool-gray mr-2">
                                  {t('offer')} {t('offersInProgressTab')}
                                </span>)
                            )}
                          {
                            application.hasOwnProperty('isHired')
                            && application.isHired === true
                            && (
                              <span title="This candidate is hired" className="badge text-success  mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-smile"><circle cx="12" cy="12" r="10" /><path d="M8 14s1.5 2 4 2 4-2 4-2" /><line x1="9" y1="9" x2="9.01" y2="9" /><line x1="15" y1="9" x2="15.01" y2="9" /></svg>
                            &nbsp;{t('hired')}
                              </span>
                            )
                          }
                          {
                            application.hasOwnProperty('isAppAutoRejected') && (application.isAppAutoRejected === true) ? (
                              <span title="This person's candidature is auto hired" className="badge text-danger  mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-frown"><circle cx="12" cy="12" r="10" /><path d="M16 16s-1.5-2-4-2-4 2-4 2" /><line x1="9" y1="9" x2="9.01" y2="9" /><line x1="15" y1="9" x2="15.01" y2="9" /></svg>
                                &nbsp;{t('autoRejected')}
                              </span>
                            ) : (

                              application.hasOwnProperty('isHired') && (application.isHired === false) && (
                                <span title="This candidate is not hired" className="badge text-danger  mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-frown"><circle cx="12" cy="12" r="10" /><path d="M16 16s-1.5-2-4-2-4 2-4 2" /><line x1="9" y1="9" x2="9.01" y2="9" /><line x1="15" y1="9" x2="15.01" y2="9" /></svg>
                                  &nbsp;{t('not')} {t('hired')}
                                </span>
                              )
                            )
                          }
                        </div>
                      )}
                      {
                        application.hasOwnProperty('shortlisted') && (application.shortlisted === false) 
                          && application.hasOwnProperty('isAppAutoRejected') && (application.isAppAutoRejected === true) ? (
                          <span title="This person's candidature is auto hired" className="badge text-danger  mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-frown"><circle cx="12" cy="12" r="10" /><path d="M16 16s-1.5-2-4-2-4 2-4 2" /><line x1="9" y1="9" x2="9.01" y2="9" /><line x1="15" y1="9" x2="15.01" y2="9" /></svg>
                            &nbsp;{t('autoRejected')}
                          </span>
                        ) : (

                          application.hasOwnProperty('shortlisted') && (application.shortlisted === false) && (
                            <div>
                              <div className="badge badge-danger  mr-2">
                                {t('rejected')}
                              </div>
                            </div>
                          )
                        )
                      }
                      {!application.hasOwnProperty('shortlisted') || (application.shortlisted === null) && (
                        <div>
                          <div className="badge badge-secondary  mr-2">
                            {t('screening')}
                          </div>
                        </div>
                      )}
                      {application && application.profile && !application.profile.email ? (
                        <AISourcedEmailMissingWarning t={t} />
                      ) : (<span></span>)}
                    </div>
                  </div>)}
                </div>
              </div>
            </div>
            {/* <ApplicantScores
          application={application}
          t={t}
          minMax={application && application.minMax}
          getNormalized={getNormalized} /> */}
          {
            isApplicantInfo === true &&  ctypeId == 2 && (
              <div className="bx--col text-right">
                <div className="clearfix">
                  {
                    application.hasOwnProperty('isHired')
                      && application.isHired === true && (
                      <div>
                        <Button size="small" kind="primary" className="mr-0" onClick={() => activePlacementModal()}> Reverse Placement </Button>
                        <Modal
                          open={placementModal}
                          shouldSubmitOnEnter
                          danger
                          modalHeading=<div className="text-left">{t('reversePlacement')}</div>
                          primaryButtonText={t('yes')}
                          secondaryButtonText={t('no')}
                          onRequestClose={dismissPlacementModal}
                          onRequestSubmit={()=> {
                            updatePlacementInfo({
                              appId: application.appId,
                              isReversePlacement: true
                            }); 
                          }}
                          >
                          <div className="text-left text-dark">
                            {t('rpMessage')} <br /><br />
                            1) Email sent to the employer indicating the student is no longer avaliable. <br />
                            2) Email sent to the student indicating the offer has been rejected.
                            <br /> <br />
                            {t('endMessage')}
                          </div>
                        </Modal>
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
       
          </div>
         
        </div>
        
    )
  );
};

export default (withTranslation()(ApplicantCard));
