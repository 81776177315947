import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { flaskUrl } from '../../utils/config';
import { REQUEST_JOB_DATA } from '../actionTypes';
import {
  REQUEST_CLOSE_JOB, REQUEST_CLONE_JOB, REQUEST_ASSOCIATE_LINKEDIN_JOB,
  REQUEST_STATUS_JOB, REQUEST_ADD_PROFILETOJOB, REQUEST_GET_JOB_TIMELINE,
  REQUEST_JOB_BATCH_ACTIONS, REQUEST_JOB_REJECT, REQUEST_APPROVE_VACANCIES,
  REQUEST_JOB_SHORTLIST_ACTIONS
  } from 'actions/actionTypes';
/*
  filter[limit] - defines the number of items to show per page
  filter[skip] - defines the number of items to skip.
  This will be equal to (page number - 1) * limit

  Eg:
  Limit = 10
  For 1st page : page number = 1, skip = (1-1)*10 = 0
  For 2nd page : page number = 2, skip = (2-1)*10 = 10
*/

const reRenderContent = data => (dispatch) => {
  const {
    jobId = 0,
    itemsPerPage = 10,
    activePage = 0,
    filterOptions = '',
    searchKey = null,
    status = null,
    seniorityId = '',
    termId = '',
    typeId = '',
    filterName_seniorityId = [],
    filterName_termId = [],
    filterName_typeId = [],
    isPrivate = '',
    filterName_isPrivate = [],
    isAutoPilot = '',
    aiSourced = '',
    filterName_aiSourced = [],
    filterName_isAutoPilot = [],
    companyId = '',
    recruiterId = '',
    filterName_companyId = 0,
    filterName_recruiterId = 0,
    filterName_approvalStatus = [],
    approvalStatus = '',
    filterName_companyIds = [],
    companyIds = '',
    courseIds = '',
    batch,
    filterName_batch,
    filterName_courseIds = []
  } = data;
  const usp = new URLSearchParams(window.document.location.search)
  const avaliableVacancies = usp.get("avaliableVacancies")
  const yetToShortlisted = usp.get("yetToShortlisted")
  const withNoHires = usp.get("withNoHires")
  const withNoApplicants = usp.get("withNoApplicants")
  const positionsFilled = usp.get("positionsFilled")
  const positionsWithVacancies = usp.get("positionsWithVacancies")
  const approvedVacancies = usp.get("approvedVacancies")
  const availableApprovedVacancies = usp.get("availableApprovedVacancies")

  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = '';
  url = `${baseUrl}/jobs?_=${new Date().getTime()}&jobId=${jobId}&filter[limit]=${itemsPerPage}&filter[skip]=${skip}&${
    (searchKey) ? `searchKey=${searchKey}` : ''
  }&${
    (status !== null && status !== 'alljobs') ? `status=${status}` : ''
  }&`.concat(filterOptions);
  if (seniorityId && filterName_seniorityId
    && filterName_seniorityId !== undefined
    && filterName_seniorityId !== null
    && Array.isArray(filterName_seniorityId)
    && filterName_seniorityId.length > 0) {
    filterName_seniorityId.map((res) => {
      url += `&filterArr[${seniorityId}]=${res}`;
    });
  }
  if (companyId) {
    filterName_companyId.map((res) => {
      url += `&companyId=${res}`;
    });
  }
  if (recruiterId) {
    filterName_recruiterId.map((res) => {
      url += `&recruiterId=${res}`;
    });
  }
  if (termId) {
    filterName_termId.map((res) => {
      url += `&filterArr[${termId}]=${res}`;
    });
  }
  if (typeId) {
    filterName_typeId.map((res) => {
      url += `&filterArr[${typeId}]=${res}`;
    });
  }
  if (isPrivate) {
    filterName_isPrivate.map((res) => {
      if (res == 1) {
        url += `&filterArr[${isPrivate}]=false`;
      }
      if (res == 2) {
        url += `&filterArr[${isPrivate}]=true`;
      }
    });
  }
  if (approvalStatus) {
    filterName_approvalStatus.map((res) => {
      if (res == 1) {
        url += `&filterArr[${approvalStatus}]=false`;
      }
      if (res == 2) {
        url += `&filterArr[${approvalStatus}]=true`;
      }
    });
  }
  if (companyIds) {
    url += `&filterArr[companyIds]=${JSON.stringify(filterName_companyIds)}`
  }
  if (courseIds) {
    url += `&filterArr[courseIds]=${JSON.stringify(filterName_courseIds)}`
  }

  if (batch) {
    url += `&filterArr[batch]=${JSON.stringify(filterName_batch)}`
  }

  if (isAutoPilot) {
    filterName_isAutoPilot.map((res) => {
      if (res == 1) {
        url += `&filterArr[${isAutoPilot}]=true`;
      }
      if (res == 2) {
        url += `&filterArr[${isAutoPilot}]=false`;
      }
    });
  }
  if (aiSourced) {
    filterName_aiSourced.map((res) => {
      if (res == 1) {
        url += `&filterArr[${aiSourced}]=true`;
      }
      if (res == 2) {
        url += `&filterArr[${aiSourced}]=false`;
      }
    });
  }
  if(avaliableVacancies){
    url += `&filterArr[avaliableVacancies]=true`
  }
  if(yetToShortlisted){
    url += `&filterArr[yetToShortlisted]=true`
  }
  if(withNoHires){
    url += `&filterArr[withNoHires]=true`
  }
  if(withNoApplicants){
    url += `&filterArr[withNoApplicants]=true`
  }
  if(positionsFilled){
    url += `&filterArr[positionsFilled]=true`
  }
  if(positionsWithVacancies){
    url += `&filterArr[positionsWithVacancies]=true`
  }
  if(approvedVacancies){
    url += `&filterArr[approvedVacancies]=true`
  }
  if(availableApprovedVacancies){
    url += `&filterArr[availableApprovedVacancies]=true`
  }
  const params = {
    actionType: REQUEST_JOB_DATA,
    dispatch,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getJobInfo = ({
  jobId = 0,
  filterOptions = '',
}) => (dispatch) => {
  const url = `${baseUrl}/jobs/${jobId}?_=${new Date().getTime()}&jobId=${jobId}`.concat(filterOptions);

  const params = {
    actionType: REQUEST_JOB_DATA,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
};

// example: filter[order][0]=appId%20DESC
const filterJobData = filterOptions => (dispatch) => {
  const url = `${baseUrl}/jobs?_=${new Date().getTime()}`.concat(filterOptions);

  const params = {
    actionType: REQUEST_JOB_DATA,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getJobTimeLine = jobId => (dispatch) => {
  const url = `${baseUrl}/analytics/timeline?jobId=${jobId}`;
  const params = {
    actionType: REQUEST_GET_JOB_TIMELINE,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
};


const addProfileToJob = (jobId=0,profileId=0)=>(dispatch)=> {
  const url = `${flaskUrl}/jobs/talentpool/matches?job_id=${jobId}&profile_id=${profileId}&add_downvoted=true&_=${new Date().getTime()}`
  const params = {
    actionType: REQUEST_ADD_PROFILETOJOB,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
}

const closeJob = (jobId, reasonForClosure, userId, roleId) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No job id passed',
  };
  if(!jobId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/jobs/${jobId}?_=${new Date().getTime()}`;
  const data = {
    jobId,
    reasonForClosure,
  };
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_CLOSE_JOB,
    dispatch,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const reopenJob = (jobId, userId, roleId) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No job id passed',
  };
  if (!jobId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/jobs/${jobId}?_=${new Date().getTime()}`;
  const data = {
    jobId,
    closeDate: null,
    xactionName: 'reopen-job',
  };
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_CLOSE_JOB,
    dispatch,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const cloneJob = (data) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No job id passed',
  };
  const { jobIds } = data || {};
  if(!jobIds) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/jobs/clones`;
  // const url = `${baseUrl}/jobs-base/cloneJob?jobId=${jobId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_CLONE_JOB,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};


const associateLinkedinJob = (jobId, linkedinJobId) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No job id or linkedin job id passed',
  };

  if (!(jobId && linkedinJobId)) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/jobs?_=${new Date().getTime()}`;
  const data = {
    jobId,
    linkedinJobId,
  };
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_ASSOCIATE_LINKEDIN_JOB,
    dispatch,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const statusOfJob = data => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No job id is available',
  };
  const { jobId } = data;
  if (!jobId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/jobs/${jobId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_STATUS_JOB,
    dispatch,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const downloadJD = jobId => (dispatch) => {
  const url = `${baseUrl}/jobs/exports?jobId=${jobId}`;
  window.location.href = url;
  return;
};

const upsertJobBatchActions = data => (dispatch) => {
  const url = `${baseUrl}/jobs?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_JOB_BATCH_ACTIONS,
    dispatch,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  return callApi(params);
};


const updateJobApprovedVacancies = (data) => (dispatch) => {
  const {
    jobId,
    jobvacancies
  } = data
  const url = `${baseUrl}/jobs/${jobId}/jobvacancies?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType:REQUEST_APPROVE_VACANCIES,
  };

  return callApi(params);
};

const getJobExport = (data) =>(dispatch)=> {
  const {
    filterName_approvalStatus = [],
    approvalStatus = '',
    filterName_companyIds = [],
    companyIds = '',
    courseIds = '',
    batch,
    filterName_batch,
    filterName_courseIds = [],
    status
  } = data;

  let url = `${baseUrl}/jobs/exportjobs?_=${new Date().getTime()}&${
    (status) ? `status=${status}` : ''
  }`

  if (approvalStatus) {
    filterName_approvalStatus.map((res) => {
      if (res == 1) {
        url += `&filterArr[${approvalStatus}]=false`;
      }
      if (res == 2) {
        url += `&filterArr[${approvalStatus}]=true`;
      }
    });
  }
  if (companyIds) {
    url += `&filterArr[companyIds]=${JSON.stringify(filterName_companyIds)}`
  }
  if (courseIds) {
    url += `&filterArr[courseIds]=${JSON.stringify(filterName_courseIds)}`
  }

  if (batch) {
    url += `&filterArr[batch]=${JSON.stringify(filterName_batch)}`
  }
  window.location.href = url;
}

const updateJobReject = data => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No job id is available',
  };
  const { jobId, jobRejected } = data;
  if (!jobId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/jobs/${jobId}/reject?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_JOB_REJECT,
    dispatch,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const upsertJobShortlistActions = data => (dispatch) => {
  const url = `${baseUrl}/jobs/sendshortlistemail?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_JOB_SHORTLIST_ACTIONS,
    dispatch,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  return callApi(params);
}; 

export {
  reRenderContent,
  getJobInfo,
  filterJobData,
  closeJob,
  cloneJob,
  reopenJob,
  downloadJD,
  associateLinkedinJob,
  statusOfJob,
  addProfileToJob,
  getJobTimeLine,
  upsertJobBatchActions,
  getJobExport,
  updateJobReject,
  updateJobApprovedVacancies,
  upsertJobShortlistActions
};
