import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SetMixPanel from 'components/common/GetCurrentPath';
import ProductTour from 'components/common/ProductTour/ProductTour';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { fetchDashboard, getWorkflowSubstages } from 'actions/talentSearch/dashboard';
import { reRenderContent as jobsRenderData } from 'actions/talentSearch/jobList';
import { getCandidatesInOffer } from 'actions/offerInformation';
import { Loading, Button, Tooltip, TooltipIcon, TextInput } from 'carbon-components-react';
import NivoGraphs from 'components/common/NivoGraphs';

import {
  fetchAllCompaniesFacets,
  fetchAllCompaniesList
} from 'actions/BrowseCompanies';
import {
  getJobApprovalListByStatus,
} from 'actions/talentSearch/JobApprovals';
import {
  fetchAllInstitutionsList,
} from 'actions/BrowseInstitutions';
import {
  getStudentsFacets,
  getStudentsList,
  getAssessmentsCounts
} from 'actions/BrowseStudents';
import {
  getInterviewsForRecruiter,
} from 'actions/videoInterview';
import {
  reRenderContent,
} from 'actions/talentSearch/candidatesList';
import {
  getCalendarEvents,
} from 'actions/calendarEvents';
import OffersView from './components/OffersView';
import VideoInterviewsView from './components/VideoInterviewsView';
import ApplicationView from './components/ApplicationView';
import JobsView from './components/JobsView';
import InterviewsView from './components/InterviewsView';
import WorkflowSubstagesView from './components/WorkflowSubstagesView';
import JobApproversView from './components/JobApproversView';
import './Dashboard.css';
import { exportPlacementData, getDashboardsInfo } from 'actions/profile';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import {
  getAllFaculties,
  getAllCourses
} from 'actions/candidate/CandidateDetails';
import { size } from 'lodash';
import { getBatchDetails } from 'actions/BatchManagementView';

const track = {
  ROBOROY_DASHBOARD_TOTALJOBS: 'ROBOROY_DASHBOARD_TOTALJOBS',
  ROBOROY_DASHBOARD_ANALYTICS: 'ROBOROY_DASHBOARD_ANALYTICS',
  ROBOROY_DASHBOARD_SHORTLISTED_CANDIDATES: 'ROBOROY_DASHBOARD_SHORTLISTED_CANDIDATES',
  ROBOROY_DASHBOARD_REJECTED_CANDIDATES: 'ROBOROY_DASHBOARD_REJECTED_CANDIDATES',
  ROBOROY_DASHBOARD_TOTAL_APPLICANTS: 'ROBOROY_DASHBOARD_TOTAL_APPLICANTS',
  ROBOROY_DASHBOARD_CANDIDATES_PENDINGSCREENING: 'ROBOROY_DASHBOARD_CANDIDATES_PENDINGSCREENING',
  ROBOROY_DASHBOARD_INTERVIEWS_CALENDAR: 'ROBOROY_DASHBOARD_INTERVIEWS_CALENDAR',
  ROBOROY_DASHBOARD_VIDEOINTERVIEWS: 'ROBOROY_DASHBOARD_VIDEOINTERVIEWS',
  ROBOROY_DASHBOARD_VEDIOINTERVIEW_DRAFT: 'ROBOROY_DASHBOARD_VEDIOINTERVIEW_DRAFT',
  ROBOROY_DASHBOARD_VEDIOINTERVIEW_AWAITING: 'ROBOROY_DASHBOARD_VEDIOINTERVIEW_AWAITING',
  ROBOROY_DASHBOARD_VIDEOINTERVIEW_COMPLETED: 'ROBOROY_DASHBOARD_VIDEOINTERVIEW_COMPLETED',
  ROBOROY_DASHBOARD_WRITTENASSESSMENTS: 'ROBOROY_DASHBOARD_WRITTENASSESSMENTS',
  ROBOROY_DASHBOARD_WRITTENASSESSMENTS_DRAFT: 'ROBOROY_DASHBOARD_WRITTENASSESSMENTS_DRAFT',
  ROBOROY_DASHBOARD_WRITTENASSESSMENTS_AWAITING: 'ROBOROY_DASHBOARD_WRITTENASSESSMENTS_AWAITING',
  ROBOROY_DASHBOARD_WRITTENASSESSMENTS_COMPLETED: 'ROBOROY_DASHBOARD_WRITTENASSESSMENTS_COMPLETED',
  ROBOROY_DASHBOARD_OFFERS: 'ROBOROY_DASHBOARD_OFFERS',
  ROBOROY_DASHBOARD_OFFERS_INPROGRESS: 'ROBOROY_DASHBOARD_OFFERS_INPROGRESS',
  ROBOROY_DASHBOARD_OFFERS_COMPLETED: 'ROBOROY_DASHBOARD_OFFERS_COMPLETED',
  ROBOROY_DASHBOARD_OPENJOBS: 'ROBOROY_DASHBOARD_OPENJOBS',
  ROBOROY_DASHBOARD_CLOSEDJOBS: 'ROBOROY_DASHBOARD_CLOSEDJOBS',
  ROBOROY_DASHBOARD_SHAREDJOBS: 'ROBOROY_DASHBOARD_SHAREDJOBS',
  ROBOROY_DASHBOARD_CVSOURCES_AISOURCING: 'ROBOROY_DASHBOARD_CVSOURCES_AISOURCING',
  ROBOROY_DASHBOARD_CVSOURCES_TALENTPOOL: 'ROBOROY_DASHBOARD_CVSOURCES_TALENTPOOL',
  ROBOROY_DASHBOARD_CVSOURCES_RECRUITERUPLOAD: 'ROBOROY_DASHBOARD_CVSOURCES_RECRUITERUPLOAD',
  ROBOROY_DASHBOARD_CVSOURCES_CANDIDATESAPPLY: 'ROBOROY_DASHBOARD_CVSOURCES_CANDIDATESAPPLY',
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productTourIsOpen: false,
      applLoading: false,
      jobLoading: false,
      selectedTab: 0,
      videoIntStatus: '',
      videoIntvLoading: false,
      offerStatus: '',
      offerTab: 0,
      offersLoading: false,
      companiesLoading: false,
      institutionsLoading: false,
      studentsLoading: false,
      jobApprLoading: false,
      pendingRegistration: 0,
      approvedCompanies: 0,
      companyCountLoader: false,
      studentCountLoader: false,
      completedProfile: 0,
      appliedForJobs: 0,
      completedInterviews: 0,
      offeredPositions: 0,
      confirmedPlacements: 0,
      cohortSize: 0,
      UploadedOfferLetter: 0,
      offersInProgress: 0,
      screeningCount: 0,
      hiredCount: 0,
      requestedVacancies: 0,
      approvedVacancies: 0,
      availableApprovedVacancies: 0,
      facultyValue: { label: "", value: "" },
      courseValue: { label: "", value: "" },
      batch: { label: "", value: "" },
      filterLoader: false,
      batchDefaultValue: '',
      approvedCompaniesCount: 0
    };
    this.closeTour = this.closeTour.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    const { currentCompany, company, userId } = user || {};
    const {
      ctypeId,
    } = company || {};
    // const userId = user || {}
    const { companyId: currentCompanyId } = currentCompany || {};
    if(ctypeId == 1){
      this.props.fetchDashboard(2343);
    }

    // this.props.getWorkflowSubstages({
    //   status: 'inprogress',
    //   itemsPerPage: 5,
    // });
    this.setState({
      applLoading: true,
      jobLoading: true,
      // videoIntStatus: 'awaitingfeedback',
      selectedTab: 0,
      // videoIntvLoading: true,
      offerStatus: 'inprogress',
      offersLoading: true,
      companiesLoading: true,
      institutionsLoading: true,
      studentsLoading: true,
      // jobApprLoading: true,
      companyCountLoader: ctypeId && Number(ctypeId) === 2 ? true : false,
      studentCountLoader: ctypeId && Number(ctypeId) === 2 ? true : false,
    }, () => {
      if (ctypeId && Number(ctypeId) === 2) {
        const managedCompData = {
          activePage: 1,
          itemsPerPage: 1000,
          Companymapstatus: ['Accepted'],
        };
        this.props.fetchAllCompaniesList(managedCompData).then((res) => {
          if (res && !res.error) {
            this.setState({
              approvedCompaniesCount: res && res.count
            })
          }
        })
        this.props.fetchAllCompaniesFacets().then((res) => {
          if (res && !res.error) {
            const {
              companyFacets: {
                Companymapstatus,
              } = {},
            } = this.props;
            let pendingRegistration = 0;
            let approvedCompanies = 0;
            if (Companymapstatus && Array.isArray(Companymapstatus) && Companymapstatus.length > 0) {
              Companymapstatus.forEach((facs) => {
                const {
                  key,
                  doc_count,
                } = facs || {};
                if (key === 'Accepted') {
                  approvedCompanies = doc_count;
                }
                if (key === 'Pending') {
                  pendingRegistration = doc_count;
                }
              });
            }
            this.setState({
              approvedCompanies,
              pendingRegistration,
              companyCountLoader: false,
            });
          }
        });

        this.props.getBatchDetails().then((res) => {
          const { list: batchList } = res;
          const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
          if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
            this.setState({
              ...this.state,
              batch: { label: currentBatch.batchName, value: currentBatch.batchId },
              batchDefaultValue: currentBatch.batchName,
              batchArr: batchList
            }, () => {
              const { batchDefaultValue, batch } = this.state;
              let data = {
                Batch: [batch.label],
                Suspended: ["no"]
              }
              this.handleGetAssessmentsCounts();
              this.props.getStudentsList(data).then((res) => {
                this.props.getStudentsFacets(data)
              }).then(() => {
                setTimeout(() => {
                  this.handleGetStudentCounts()
                }, 3000)
                this.handleDropdownChange(batchDefaultValue, batch, "batch");
                // this.onFilterChange(this.state.batch, this.state.courseValue!=null?this.state.courseValue.value:null, this.state.facultyValue!=null?this.state.facultyValue.value:null);
              })
            })
          } else {
            this.setState({
              ...this.state,
              batch: { label: "Choose Batch", value: 0 },
              batchArr: []
            })
          }
        });
      }

      this.props.getBatchDetails().then((res) => {
        const { list: batchList } = res;
        const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];

        let tempBatch;
        if (parseInt(ctypeId) !== 1) {
          if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
            tempBatch = currentBatch.batchName;
          }
        } 
        this.props.getCandidatesInOffer({
          itemsPerPage: 5,
          activePage: 0,
          status: this.state.offerStatus,
          currentCompanyId,
          batch: tempBatch
        }).then(() => {
          this.setState({
            offersLoading: false,
          });
        });
        if(parseInt(ctypeId) == 1){
          this.props.jobsRenderData({
            itemsPerPage: 5,
            activePage: 0,
          }).then(() => {
            this.setState({
              jobLoading: false,
            });
          });
        }
        else{
          this.props.jobsRenderData({
            itemsPerPage: 5,
            activePage: 0,
            batch: "batch",
            filterName_batch: [this.state.batch.label],
          }).then(() => {
            this.setState({
              jobLoading: false,
            });
          });
        }
     
        this.props.reRenderContent({
          itemsPerPage: 5,
          activePage: 0,
          status: 'processing',
          dashboard: true,
          batch: [this.state.batch.label]
        }).then(() => {
          this.setState({
            applLoading: false,
          });
        });
      });
      // this.props.getInterviewsForRecruiter({
      //   itemsPerPage: 5,
      //   activePage: 0,
      //   status: this.state.videoIntStatus,
      //   currentCompanyId,
      // }).then(() => {
      //   this.setState({
      //     videoIntvLoading: false,
      //   });
      // });
      // this.props.getJobApprovalListByStatus().then((res) => {
      //   if (res && !res.error) {
      //     this.setState({
      //       jobApprLoading: false,
      //     });
      //   }
      // })

      this.props.getBatchDetails().then((res) => {
        const { list: batchList } = res;
        const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];

        let tempBatch;
        if (parseInt(ctypeId) !== 1) {
          if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
            tempBatch = currentBatch.batchName;
          }
        }
        let filterArr = {
          courseIds: '',
          filterName_courseIds: [],
          batch: "batch",
          filterName_batch: [this.state.batch.label]
        }

        if (filterArr.courseIds.length > 0) {
          this.props.getDashboardsInfo(filterArr).then((res) => {
            if (res && !res.error) {
              this.setState({
                screeningCount: res.screeningCount,
                hiredCount: res.notHiredCount,
                requestedVacancies: res.requestedVacanciesCount,
                approvedVacancies: res.approvedVacanciesCount,
                availableApprovedVacancies: res.availableApprovedVacancies,
                positionsWithVacancies: res.positionsWithVacancies,
                noApplicants: res.noApplicants,
                positionsFilled: res.positionsFilled
              })
            }
          })
        }
        else if (filterArr.courseIds.length == 0) {
          this.props.getDashboardsInfo(filterArr).then((res) => {
            if (res && !res.error) {
              this.setState({
                screeningCount: res.screeningCount,
                hiredCount: res.notHiredCount,
                requestedVacancies: res.requestedVacanciesCount,
                approvedVacancies: res.approvedVacanciesCount,
                availableApprovedVacancies: res.availableApprovedVacancies,
                positionsWithVacancies: res.positionsWithVacancies,
                noApplicants: res.noApplicants,
                positionsFilled: res.positionsFilled
              })
            }
          })
        }
      });

      // this.props.getCalendarEvents('today');
      // this.props.reRenderContent({
      //   itemsPerPage: 5,
      //   activePage: 0,
      //   status: 'processing',
      //   dashboard: true
      // }).then(() => {
      //   this.setState({
      //     applLoading: false,
      //   });
      // });
    });
    this.handleFetchCoursesList();
  }

  handleGetAssessmentsCounts = () => {
    const { batch } = this.state || {};
    const { label } = batch || {};
    const assessmentData = { batch:label };
    this.props.getAssessmentsCounts(assessmentData).then((res)=>{
      if(res && !res.error){
        const { bookPanelInterview, profilingAssessment, selectedForGeip, videoAssessment } = res || {};
        const { booked: bookedCount } = bookPanelInterview || {};
        const { completed: profilingAssessmentCompletedCount } = profilingAssessment || {};
        const { selected: selectedForGeipCount } = selectedForGeip || {};
        const { completed:videoAssessmentCompletedCount } = videoAssessment || {};

        this.setState({ 
          bookedCount,
          profilingAssessmentCompletedCount,
          selectedForGeipCount,
          videoAssessmentCompletedCount
        })
      }
    })
  }

  onInputFacultyDropdow = (value) => {
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      if (value.length > 2) {
        const {
          user: {
            currentCompany: {
              companyId
            } = {}
          } = {}
        } = this.props
        const data = {
          schoolId: companyId,
          facultyActivePage: 1,
          facultyItemsPerPage: 1000,
        };
        this.props.getAllFaculties(data);
      }
      return value;
    }
    return null;
  };

  facultyDropdown = (value) => {
    this.setState({
      facultyValue: value,
    });
  }

  courseDropdown = (value) => {
    this.setState({
      courseValue: value,
    });
  }

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue
    })
  }

  onFilterChange = (batch, courseId, facultyId) => {
    const { user } = this.props;
    const { currentCompany, company } = user || {};
    const {
      ctypeId,
    } = company || {};
    const userId = user || {}
    const { companyId: currentCompanyId } = currentCompany || {};
    const { batchDefaultValue } = this.state;

    // if (courseId) {
    let filterArr = {
      courseIds: [courseId],
      filterName_courseIds: [courseId]
    }
    this.setState({
      filterLoader: true
    }, () => {
      const { batch } = this.state;
      let jobsCallData = {
        itemsPerPage: 5,
        activePage: 0,
      }
      let appCallData = {
        itemsPerPage: 5,
        activePage: 0,
        status: 'processing',
      }
      if (courseId !== null && courseId !== undefined && courseId !== "") {
        jobsCallData["courseIds"] = [courseId];
        jobsCallData["filterName_courseIds"] = [courseId];
      }
      if (batch !== null && batch !== undefined && batch.label !== "") {
        jobsCallData["batch"] = "batch";
        jobsCallData["filterName_batch"] = [batch.label];
        appCallData["batch"] = [batch.label];
      }
      // console.log(data);
      // if (courseId === null || courseId === undefined || courseId === "") {
      this.props.jobsRenderData(jobsCallData).then(() => {
        this.setState({
          jobLoading: false,
          filterLoader: false
        });
      });

      this.props.reRenderContent(appCallData).then(() => {
        this.setState({
          applLoading: false,
          filterLoader: false
        });
      });
      // } else {
      //   this.props.jobsRenderData({
      //     itemsPerPage: 5,
      //     activePage: 0,
      //     courseIds: [courseId],
      //     filterName_courseIds: [courseId]
      //   }).then(() => {
      //     this.setState({
      //       jobLoading: false,
      //       filterLoader: false
      //     });
      //   });
      // }


      let studentsCallData = {
        "Suspended": ["no"]
      }
      if (batch != null && batch.value != "") {
        studentsCallData.Batch = [batch.label]
      }
      if (courseId && this.state.courseValue && this.state.courseValue.label) {
        studentsCallData.Courses = [this.state.courseValue.label]
      }
      if (facultyId && this.state.facultyValue && this.state.facultyValue.label) {
        studentsCallData.Faculties = [this.state.facultyValue.label]
      }
      // console.log(studentsCallData)
      this.props.getStudentsList(studentsCallData).then((res) => {
        // console.log(res)
        // console.log(studentsCallData)
        this.props.getStudentsFacets(studentsCallData).then((res) => {
          // console.log(res)
          this.handleGetStudentCounts()
          this.setState({
            filterLoader: false
          })
        })
          .catch((err) => {
            console.log("err", err)
          })
      })
        .catch((err) => {
          console.log("err", err)
        })

      let dashboardCallData = {
        courseIds: [courseId],
        filterName_courseIds: [courseId],
      }
      // if (batch != null && batch.value != "") {
      //   dashboardCallData["batch"]=batch;
      //   dashboardCallData["filterName_batch"]=[batch.label];
      // }
      // console.log(dashboardCallData)
      this.props.getDashboardsInfo(dashboardCallData).then((res) => {
        if (res && !res.error) {
          this.setState({
            screeningCount: res.screeningCount,
            hiredCount: res.notHiredCount,
            requestedVacancies: res.requestedVacanciesCount,
            approvedVacancies: res.approvedVacanciesCount,
            availableApprovedVacancies: res.availableApprovedVacancies,
            positionsWithVacancies: res.positionsWithVacancies,
            noApplicants: res.noApplicants,
          })
        }
      })
      // this.props.reRenderContent({
      //   itemsPerPage: 5,
      //   activePage: 0,
      //   status: 'processing',
      //   courseId
      // }).then(() => {
      //   this.setState({
      //     applLoading: false,
      //     filterLoader: false
      //   });
      // });
      // if(parseInt(ctypeId)!==1){
      //   batch="AY21 Sem 1";
      // }
      this.props.getCandidatesInOffer({
        itemsPerPage: 5,
        activePage: 0,
        status: this.state.offerStatus,
        courseId,
        batch: batchDefaultValue
      }).then(() => {
        this.setState({
          offersLoading: false,
          filterLoader: false
        });
      });
    })
  };

  handleFetchCoursesList = () => {
    const { user: { companyId, company: { ctypeId } = {} } = {} } = this.props;
    const coursesData = {
      courseActivePage: 1,
      courseItemsPerPage: 1000,
      schoolId: companyId,
    };
    this.props.getAllCourses(coursesData).then(res => {
      if (res && !res.error) {
        const { courses } = res;
        let finalCoursesArr = [];
        if (courses && Array.isArray(courses) && courses.length > 0) {
          finalCoursesArr = courses.map(course => {
            const { courseId, courseName } = ({} = course || {});
            return { courseId, courseName };
          });
          this.setState({
            finalCoursesArr,
          });
        }
      }
    });
  };

  onInputCourseDropdow = (value) => {
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      if (value.length > 2) {
        const { user: { companyId, company: { ctypeId } = {} } = {} } = this.props;
        const data = {
          courseActivePage: 1,
          courseItemsPerPage: 1000,
          schoolId: companyId,
        };
        this.props.getAllCourses(data);
      }
      return value;
    }
    return null;
  };


  handleDashboardStudentCounts = () => {
    const {
      studentFacets,
      studentsCount,
    } = this.props;
    const cohortSize = studentsCount;
    let {
      unCompletedProfile,
    } = this.state;
    const keys = studentFacets && Object.keys(studentFacets);
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((res) => {
        const finalKeys = studentFacets[res] && Array.isArray(studentFacets[res]) && studentFacets[res].length > 0 && studentFacets[res].find(val => val.key === 'no');
        const {
          doc_count,
        } = finalKeys || {};
        if (res === 'Application Submitted') {
          unCompletedProfile = doc_count;
        }
      });
    }
    this.setState({
      unCompletedProfile,
    });
  }

  handleGetStudentCounts = () => {

    const {
      studentFacets,
      studentsCount,
    } = this.props;
    const cohortSize = studentsCount;
    let {
      completedProfile,
      appliedForJobs,
      completedInterviews,
      offeredPositions,
      confirmedPlacements,
      UploadedOfferLetter,
      offersInProgress,
      shortlistedByCompanies
    } = this.state;
    const keys = studentFacets && Object.keys(studentFacets);
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((res) => {
        const finalKeys = studentFacets[res] && Array.isArray(studentFacets[res]) && studentFacets[res].length > 0 && studentFacets[res].find(val => val.key === 'yes');
        const {
          doc_count,
        } = finalKeys || {};
        if (res === 'Application Submitted') {
          completedProfile = doc_count;
        }
        if (res === 'Applied to Jobs') {
          appliedForJobs = doc_count;
        }
        if (res === 'Accepted Offer') {
          offeredPositions = doc_count;
        }
        if (res === 'Completed Workflow') {
          completedInterviews = doc_count;
        }
        if (res === 'Hired') {
          confirmedPlacements = doc_count;
        }
        if (res === 'Uploaded Signed Offer Letter') {
          UploadedOfferLetter = doc_count;
        }
        if (res === 'Offer In Progress') {
          offersInProgress = doc_count;
        }
        if (res === 'Shortlisted By Companies') {
          shortlistedByCompanies = doc_count;
        }
      });
    }
    this.handleDashboardStudentCounts()
    this.setState({
      completedProfile,
      appliedForJobs,
      completedInterviews,
      offeredPositions,
      confirmedPlacements,
      cohortSize,
      UploadedOfferLetter,
      offersInProgress,
      shortlistedByCompanies,
      studentCountLoader: false,
    });
  }

  handleOffersTabClick = (tabType) => {
    const { user } = this.props;
    const { currentCompany, company } = user || {};
    const { ctypeId } = company;
    const { companyId: currentCompanyId } = currentCompany || {};
    let offerStatus = 'inprogress';
    let offerTab = 0;
    if (tabType === 'awaiting') {
      offerStatus = 'inprogress';
      offerTab = 0;
    } else if (tabType === 'completed') {
      offerStatus = 'completed';
      offerTab = 1;
    }
    const { batchDefaultValue } = this.state;
    let batch;
    if (parseInt(ctypeId) !== 1) {
      batch = batchDefaultValue
    }
    this.setState({
      offerStatus,
      offerTab,
      offersLoading: true,
    }, () => {
      this.props.getCandidatesInOffer({
        itemsPerPage: 5,
        activePage: 0,
        status: this.state.offerStatus,
        currentCompanyId,
        batch: batch
      }).then(() => {
        this.setState({
          offersLoading: false,
        });
      });
    });
  }

  handleVideoInterviewsClick = (type) => {
    switch (type) {
      case 'interviews':
        SetMixPanel(track.ROBOROY_DASHBOARD_VIDEOINTERVIEWS);
        this.props.history.push('/app/campus/interviews');
        break;
      case 'draft':
        SetMixPanel(track.ROBOROY_DASHBOARD_VEDIOINTERVIEW_DRAFT);
        this.props.history.push('/app/campus/interviews/status/draft');
        break;
      case 'awaiting':
        SetMixPanel(track.ROBOROY_DASHBOARD_VEDIOINTERVIEW_AWAITING);
        this.props.history.push('/app/campus/interviews/status/awaiting');
        break;
      case 'completed':
        SetMixPanel(track.ROBOROY_DASHBOARD_VIDEOINTERVIEW_COMPLETED);
        this.props.history.push('/app/campus/interviews/status/completed');
        break;
      default:
        break;
    }
  };

  handleInterviewWrokflowTabClick = (tabType) => {
    const { user } = this.props;
    const { currentCompany } = user || {};
    const { companyId: currentCompanyId } = currentCompany || {};
    let videoIntStatus = 'awaitingfeedback';
    let selectedTab = 0;
    if (tabType === 'awaiting') {
      videoIntStatus = 'awaitingfeedback';
      selectedTab = 0;
    } else if (tabType === 'completed') {
      videoIntStatus = 'completed';
      selectedTab = 1;
    }
    this.setState({
      videoIntStatus,
      selectedTab,
      videoIntvLoading: true,
    }, () => {
      this.props.getInterviewsForRecruiter({
        itemsPerPage: 5,
        activePage: 0,
        status: this.state.videoIntStatus,
        currentCompanyId,
      }).then(() => {
        this.setState({
          videoIntvLoading: false,
        });
      });
    });
  }

  handleJobsClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_TOTALJOBS);
    this.props.history.push('/app/campus/jobs');
  };

  handleShortListClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_SHORTLISTED_CANDIDATES);
    this.props.history.push('/app/campus/candidates/xopa/shortlisted');
  };

  handleRejectClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_REJECTED_CANDIDATES);
    this.props.history.push('/app/campus/candidates/xopa/rejected');
  };

  handleCandidatesClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_TOTAL_APPLICANTS);
    this.props.history.push('/app/campus/candidates/xopa/processing');
  };

  handleCandidatesInProcessClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_CANDIDATES_PENDINGSCREENING);
    this.props.history.push('/app/campus/candidates/xopa/screening');
  };

  handleInterviewsClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_INTERVIEWS_CALENDAR);
    this.props.history.push('/app/campus/interviews-calendar');
  };

  handleOpenJobsClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_OPENJOBS);
    this.props.history.push('/app/campus/jobs/open/page/1/limit/10/');
  }

  handleClosedJobsClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_CLOSEDJOBS);
    this.props.history.push('/app/campus/jobs/closed/page/1/limit/10/');
  }

  handleSharedJobsClick = () => {
    SetMixPanel(track.ROBOROY_DASHBOARD_SHAREDJOBS);
    this.props.history.push('/app/campus/jobs/shared/page/1/limit/10/');
  }

  handleOffersClick = (type) => {
    switch (type) {
      case 'offers':
        SetMixPanel(track.ROBOROY_DASHBOARD_OFFERS);
        this.props.history.push('/app/campus/offers');
        break;
      case 'inprogress':
        SetMixPanel(track.ROBOROY_DASHBOARD_OFFERS_INPROGRESS);
        this.props.history.push('/app/campus/offers/inprogress');
        break;
      case 'completed':
        SetMixPanel(track.ROBOROY_DASHBOARD_OFFERS_COMPLETED);
        this.props.history.push('/app/campus/offers/completed');
        break;
      default:
        break;
    }
  };

  handleViewAllCompanies = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/app/campus/browse/companies');
    }
  }

  handleViewAllInstitutions = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/app/campus/browse/institutions');
    }
  }

  handleViewAllStudents = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/app/campus/browse/students');
    }
  }

  closeTour() {
    this.setState({ productTourIsOpen: false });
  }

  onExportClick = () => {
    let courseId = this.state.courseValue.value;
    let schoolId = this.state.facultyValue.value;
    let batch = this.state.batch.label;
    this.props.exportPlacementData(courseId, schoolId, batch)
  };

  render() {
    const {
      dashboard, calendarEvents,
      applications, interviews,
      jobs, subStages, interviewCount,
      offers, offersCount, companiesCount,
      companies, institutions, institutionsCount,
      students, studentsCount,
      history: {
        push,
      } = {},
      user: {
        firstname,
        lastname,
        isAdmin,
        isSuperAdmin,
        roleId,
        courseAdmin,
        facultyId,
        faculty: {
          facultyName
        } = {},
        company: {
          ctypeId,
        } = {},
      } = {},
      t,h1,
      approvalList,
      jobApprLoading,
      coursesData,
      facultyData,
    } = this.props;
    const {
      approved,
      pending,
      open,
      closed,
    } = (jobs && jobs.dashboard) || {};
    const paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus' },
      { id: 'Dashboard', name: 'Dashboard', href: '' }
    ];
    const {
      totalApplications,
      favouriteApplns,
      numOfShortlistedApplications,
      pendingWorkflowCount,
      completedWorkflowCount,
      hiredApplns,
      rejectedApplns,
      numOfScreeningApplications,
      sharedJobCount,
      numOfCandidateOffers
    } = dashboard || {};
    const {
      applLoading, jobLoading,
      videoIntStatus, videoIntvLoading,
      selectedTab, offerTab, offerStatus,
      offersLoading, companiesLoading,
      institutionsLoading, studentsLoading,
      approvedCompanies,
      pendingRegistration,
      companyCountLoader,
      completedProfile,
      appliedForJobs,
      completedInterviews,
      offeredPositions,
      confirmedPlacements,
      cohortSize,
      studentCountLoader,
      UploadedOfferLetter,
      offersInProgress,
      screeningCount,
      hiredCount,
      requestedVacancies,
      approvedVacancies,
      availableApprovedVacancies,
      unCompletedProfile,
      positionsWithVacancies,
      noApplicants,
      positionsFilled,
      facultyValue,
      courseValue,
      batch,
      filterLoader,
      finalCoursesArr,
      shortlistedByCompanies,
      batchArr,
      approvedCompaniesCount,
      videoAssessmentCompletedCount,
      selectedForGeipCount,
      profilingAssessmentCompletedCount,
      bookedCount
    } = this.state;
    const guideSteps = [
      {
        selector: '.unknown-class-meow-meow',
        content: 'This is the main dashboard of Roboroy application where you can get a bird-eye view of jobs and applications.',
      },
      {
        selector: '.xpa-pg-roboroy--dashboard .bx--tile .xpa-numiconcard__open-jobs',
        content: 'Click this section to see list of jobs that you have posted.',
      },
      {
        selector: '.xpa-pg-roboroy--dashboard .bx--tile .xpa-numiconcard__total-applns',
        content: 'Click this section to see consolidated list of all applicants from all the jobs that you have posted',
      },
      {
        selector: '.xpa-pg-roboroy--dashboard .bx--tile .xpa-numiconcard__video-int',
        content: 'Click this section to see consolidated list of all video interviews.',
      },
      {
        selector: '.xpa-pg-roboroy--dashboard .bx--tile .xpa-numiconcard__shortlisted',
        content: 'Click this section to see consolidated list of all applicants you have shorlisted across all jobs.',
      },
      {
        selector: '.xpa-pg-roboroy--dashboard .bx--tile .xpa-numiconcard__in-process',
        content: 'Click this section to see consolidated list of all applicants you are currently screening across all jobs.',
      },
      {
        selector: '.xpa-pg-roboroy--dashboard .bx--tile .xpa-numiconcard__rejected',
        content: 'Click this section to see consolidated list of all applicants you have rejected across all jobs.',
      },
    ];
    let batchString = ''
    if (this.state.batch && this.state.batch.label && this.state.batch.label !== "") {
      batchString += `batch=${this.state.batch.label}`
    }
    let courseString = ''
    if (this.state.courseValue && this.state.courseValue.value) {
      courseString += `courseIds=${this.state.courseValue.value}`
    }
    let courseStudentString = ''
    if (this.state.courseValue && this.state.courseValue.label) {
      courseStudentString += `Courses=${this.state.courseValue.label}`
    }

    let facultyString = ''
    if (this.state.facultyValue && this.state.facultyValue.label) {
      facultyString += `Faculties=${this.state.facultyValue.label}`
    }

    const commonProps = {
      fromDashboard: true,
      batch: this.state.batch != null ? this.state.batch.label : null,
      course: this.state.courseValue != null ? this.state.courseValue.label : null,
      faculty: this.state.facultyValue != null ? this.state.facultyValue.label : null,
    }
    return (
      <div className="">
        {/* <RoboSidebar /> */}
        <div className="">
          <BackRow paths={paths} />
          {/* <ProductTour
            onRequestClose={this.closeTour}
            isOpen={this.state.productTourIsOpen}
            steps={guideSteps}
          /> */}
        </div>
        <div className="ml-1">
          <div>
            {t('welcome')}!
          </div>
          {/* < StdInfo /> */}
          
          
          <h5 className="text-uppercase pb-2 font-weight-bold">
            {firstname} {lastname}
          </h5>
        </div>
        {
          ctypeId && Number(ctypeId) == 2 ? (
            <div className="d-flex align-items-center">
              {
                isSuperAdmin === true ? (
                  <div className="d-flex align-items-center">
                    <DropDownBox
                      title={t('faculty')}
                      mainClassName="bx--form-item ml-5 bx--col-lg-5"
                      titleClass="bx--select"
                      labelClass="bx--label"
                      custom
                      options={facultyData && Array.isArray(facultyData)
                        && facultyData.length > 0
                        ? (facultyData.map(one => ({
                          label: one.facultyName,
                          value: one.facultyId,
                        }))) : []}
                      name={t('faculty')}
                      onInputChange={e => this.onInputFacultyDropdow(e)}
                      placeholder={t('faculty')}
                      onChange={(id, value, name) => this.facultyDropdown(value)}
                      selectedValue={facultyValue}
                    />
                    <DropDownBox
                      title={t('course')}
                      mainClassName="bx--form-item ml-5 bx--col-lg-5"
                      titleClass="bx--select"
                      labelClass="bx--label"
                      custom
                      options={finalCoursesArr && Array.isArray(finalCoursesArr)
                        && finalCoursesArr.length > 0
                        ? (finalCoursesArr.map(one => ({
                          label: one.courseName,
                          value: one.courseId,
                        }))) : []}
                      name={t('course')}
                      onInputChange={e => this.onInputCourseDropdow(e)}
                      placeholder={t('course')}
                      onChange={(id, value, name) => this.courseDropdown(value)}
                      selectedValue={courseValue}
                    />
                  </div>
                ) : ''
              }
              <div className="d-flex align-items-center">
                {
                  !isSuperAdmin && isAdmin === true && !courseAdmin && (
                    <DropDownBox
                      title={t('course')}
                      mainClassName="bx--form-item ml-5 bx--col-lg-5"
                      titleClass="bx--select"
                      labelClass="bx--label"
                      custom
                      options={finalCoursesArr && Array.isArray(finalCoursesArr)
                        && finalCoursesArr.length > 0
                        ? (finalCoursesArr.map(one => ({
                          label: one.courseName,
                          value: one.courseId,
                        }))) : []}
                      name={t('course')}
                      onInputChange={e => this.onInputCourseDropdow(e)}
                      placeholder={t('course')}
                      onChange={(id, value, name) => this.courseDropdown(value)}
                      selectedValue={courseValue}
                    />
                  )
                }

                <DropDownBox
                  title={t('batch')}
                  mainClassName="bx--form-item ml-5 bx--col-lg-5"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  options={batchArr && Array.isArray(batchArr)
                    && batchArr.length > 0
                    ? (batchArr.map(one => ({
                      label: one.batchName,
                      value: one.batchId,
                    }))) : []}
                  placeholder={t('batch')}
                  onChange={(value, e, name) => {
                    this.handleDropdownChange(value, e, "batch")
                  }}
                  name={t('batch')}
                  selectedValue={batch}
                />
              </div>
              <div className="d-flex">
                <div className="m-4">
                  <Button
                    className="bx--btn bx--btn--primary full-width"
                    onClick={() => this.onFilterChange(this.state.batch, this.state.courseValue != null ? this.state.courseValue.value : null, this.state.facultyValue != null ? this.state.facultyValue.value : null)}>
                    {' '}
              Filter{' '}
                  </Button>
                </div>

                {roleId && Number(roleId) == 1 && ctypeId && Number(ctypeId) == 2 && isSuperAdmin || (isAdmin && !courseAdmin && facultyId != null) ? (
                  <div className="m-4 pt-3">
                    <a href="#" className="xpa-link" onClick={() => this.onExportClick()} > Export Placement Data</a>
                  </div>
                ) : ' '}
                <JDProcessOverlay
                  show={this.state && this.state.filterLoader}
                  message="processing..."
                />
              </div>
            </div>
          ) : ''
        }
        <hr />
        {
          (courseAdmin === true || isAdmin === true) && ctypeId && Number(ctypeId) === 2 && !isSuperAdmin && (
            <Fragment>
              <div>
                <h6 className="text-uppercase">
                  Companies
                </h6>
                {
                  companyCountLoader === true
                  && (
                    <div className="bx--row justify-content-around">
                      <Loading withOverlay={false} />
                    </div>
                  )
                }
                {
                  companyCountLoader !== true && (
                    <div>
                      <div>
                        <div className="row">
                          <div onClick={() => { push && push('/app/campus/browse/companies?Companymapstatus=Accepted&') }} className="col-sm m-2 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Companies
                            </div>
                            <h1 className="xpa-link">
                              {approvedCompaniesCount || 0}
                            </h1>
                          </div>
                          <div onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }} className="col-sm m-2 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Positions Pending For Approval
                            </div>
                            <h1 className="xpa-link">
                              {pending || 0}
                            </h1>
                          </div>
                        </div>
                        <div className="row">
                          <div onClick={() => { push && push(`/app/campus/jobs/open?${courseString}&${batchString}`) }} className="col-sm m-2 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Requested Vacancies
                            </div>
                            <h1 className="xpa-link">
                              {requestedVacancies || 0}
                            </h1>
                          </div>
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?approvedVacancies=1&${courseString}&${batchString}`) }} className="col-sm m-2 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Approved Vacancies
                            </div>
                            <h1 className="xpa-link">
                              {approvedVacancies || 0}
                            </h1>
                          </div>
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?availableApprovedVacancies=1&?${courseString}&${batchString}`) }} className="col-sm m-2 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Available Approved Vacancies
                            </div>
                            <h1 className="xpa-link">
                              {availableApprovedVacancies || 0}
                            </h1>
                          </div>
                        </div>
                        <div className="row">
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?yetToShortlisted=1&${courseString}&${batchString}`) }} className="col-sm m-2 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              POSITIONS WITH APPLICANTS BUT YET TO SHORTLISTED
                            </div>
                            <h1 className="xpa-link">
                              {screeningCount || 0}
                            </h1>
                          </div>
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?withNoHires=1&${courseString}&${batchString}`) }} className="col-sm m-2 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              POSITIONS WITH SHORTLISTED APPLICANTS BUT NO HIRES
                            </div>
                            <h1 className="xpa-link">
                              {hiredCount || 0}
                            </h1>
                          </div>
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?withNoApplicants=1&${courseString}&${batchString}`) }} className="col-sm m-2 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Positions With No Applicants
                            </div>
                            <h1 className="xpa-link">
                              {noApplicants || 0}
                            </h1>
                          </div>
                        </div>
                      </div>


                      {/* <div className="bx--row"> */}
                        {/* <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push('/app/campus/browse/companies?Companymapstatus=Pending&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {pendingRegistration || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                           Pending Registration
                          </div>
                        </div> */}
                        {/* <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push('/app/campus/browse/companies?Companymapstatus=Accepted&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {approvedCompaniesCount || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Companies
                          </div>
                        </div>
                        <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {pending || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Positions Pending For Approval
                          </div>
                        </div> */}
                        {/*<div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push('/app/campus/jobs/open') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {requestedVacancies || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Requested Vacancies
                          </div>
                        </div>*/}
                      {/* </div> */}
                      {/* <div className="bx--row mt-3"> */}
                        {/*<div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push('/app/campus/jobs/open') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {requestedVacancies || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Requested Vacancies
                          </div>
                        </div>*/}
                        {/* <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push(`/app/campus/jobs/open?${courseString}&${batchString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {requestedVacancies || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Requested Vacancies
                          </div>
                        </div>
                        <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?approvedVacancies=1&${courseString}&${batchString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {approvedVacancies || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Approved Vacancies
                          </div>
                        </div>
                        <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?availableApprovedVacancies=1&?${courseString}&${batchString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {availableApprovedVacancies || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Available Approved Vacancies
                          </div>
                        </div>
                        <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?yetToShortlisted=1&${courseString}&${batchString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {screeningCount || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Positions With Applicants But Yet To Shortlisted
                          </div>
                        </div>
                        <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?withNoHires=1&${courseString}&${batchString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {hiredCount || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Positions With Shortlisted Applicants But No Hires
                          </div>
                        </div>
                        <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                          <div onClick={() => { push && push(`/app/campus/jobs/open/?withNoApplicants=1&${courseString}&${batchString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                            {noApplicants || 0}
                          </div>
                          <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                            Positions With No Applicants
                          </div>
                        </div> */}
                      {/* </div> */}
                    </div>
                  )
                }
              </div>
              <hr />
              <h6 className="text-uppercase">
                Students
              </h6>
              {
                studentCountLoader === true
                && (
                  <div className="bx--row justify-content-around">
                    <Loading withOverlay={false} />
                  </div>
                )
              }
              {
                studentCountLoader === false && (
                  <div>
                    <div className="container">
                      <div className="row mb-3">
                        <div className="col-lg-6" 
                          onClick={() => {
                            push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                              }
                            })
                          }}
                          // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                        >
                          <div className=" p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Cohort Size
                            </div>
                            <h1 className="xpa-link">
                              {cohortSize || 0}
                            </h1>
                          </div>
                        </div>
                        <div className="col-lg-6" 
                          onClick={() => {
                            push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Application Submitted", value: "yes" }
                              }
                            })
                          }}
                          // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                        >
                          <div className=" p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Profiles Completed
                            </div>
                            <h1 className="xpa-link">
                              {completedProfile || 0}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-4" 
                          onClick={() => {
                            push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Room Offline Video Interview", value: "yes" }
                              }
                            })
                          }}
                          // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                        >
                          <div className=" p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Room Offline Video Interview
                            </div>
                            <h1 className="xpa-link">
                              {videoAssessmentCompletedCount || 0}
                            </h1>
                          </div>
                        </div>
                        <div className="col-lg-4" 
                          onClick={() => {
                            push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Profiling Assessment", value: "yes" }
                              }
                            })
                          }}
                          // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                        >
                          <div className=" p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Profiling Assessment
                            </div>
                            <h1 className="xpa-link">
                              {profilingAssessmentCompletedCount || 0}
                            </h1>
                          </div>
                        </div>
                        <div className="col-lg-4" 
                          onClick={() => {
                            push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Booked Panel Interview", value: "yes" }
                              }
                            })
                          }}
                          // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                        >
                          <div className=" p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Book Panel Interview
                            </div>
                            <h1 className="xpa-link">
                              {bookedCount || 0}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-3">
                          <NivoGraphs
                            finalNivoData={[
                              {
                                "id": "cohort",
                                "value": selectedForGeipCount || 0,
                                "label": "Cohort Size"
                              },
                              // {
                              //   "id": "profilesCompleted",
                              //   "value": completedProfile || 0,
                              //   "label": "Profiles Completed"
                              // },
                              {
                                "id": "appliedForJobs",
                                "value": appliedForJobs || 0,
                                "label": "Applied For Positions"
                              },
                              {
                                "id": "shortlistedByCompanies",
                                "value": shortlistedByCompanies || 0,
                                "label": "Shortlisted By Companies"
                              },
                              {
                                "id": "confirmedPlacements",
                                "value": confirmedPlacements || 0,
                                "label": "Confirmed Placements"
                              },
                              {
                                "id": "uploadedOfferLetter",
                                "value": UploadedOfferLetter || 0,
                                "label": "Uploaded Offer Letter"
                              }

                            ]}
                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                            type="funnel"
                            // title="Average Duration(in days) Taken for a Student/Company to find a Suitable Match"
                            colors={["#e8c1a0", "#f47560", "#f1e15b", "#e8a838", "#61cdbb", "#97e3d5"]}
                            // colors={{ scheme: 'nivo' }}
                            beforeSeparatorLength={0}
                            afterSeparatorLength={0}
                            afterSeparatorOffset={0}
                            currentPartSizeExtension={20}
                            currentBorderWidth={5}
                            height={300}
                          />
                          <div className="row">
                            <div className="d-flex mr-3 xpa-legend-box">
                              <div style={{ backgroundColor: "#e8c1a0" }} className="xpa-color-box"></div>
                              <div>Selected For GEIP</div>
                            </div>
                            {/* <div className="d-flex mr-3 xpa-legend-box">
                              <div style={{ backgroundColor: "#f47560" }} className="xpa-color-box"></div>
                              <div>Profiles Completed</div>
                            </div> */}
                            <div className="d-flex mr-3 xpa-legend-box">
                              <div style={{ backgroundColor: "#f1e15b" }} className="xpa-color-box"></div>
                              <div>Applied For Positions</div>
                            </div>
                            <div className="d-flex mr-3 xpa-legend-box">
                              <div style={{ backgroundColor: "#e8a838" }} className="xpa-color-box"></div>
                              <div>Shortlisted By Companies</div>
                            </div>
                            <div className="d-flex mr-3 xpa-legend-box">
                              <div style={{ backgroundColor: "#61cdbb" }} className="xpa-color-box"></div>
                              <div>Confirmed Placements</div>
                            </div>
                            <div className="d-flex mr-3 xpa-legend-box">
                              <div style={{ backgroundColor: "#97e3d5" }} className="xpa-color-box"></div>
                              <div>Uploaded Offer Letter</div>
                            </div>
                          </div>

                        </div>

                        <div className="col-sm-9">
                          <div className="container">
                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Selected For GEIP", value: "yes" }
                                  }
                                })
                            }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                              <div className="col-sm-8 text-dark text-uppercase">
                                Selected for GEIP
                              </div>
                              <div className="col-sm-4 d-flex justify-content-center">
                                <span className="xpa-link">
                                  {selectedForGeipCount || 0}
                                </span>
                              </div>
                            </div>

                            {/* <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Application Submitted", value: "yes" }
                                  }
                                })
                            }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                              <div className="col-sm-8 text-dark text-uppercase">
                                Profiles Completed
                              </div>
                              <div className="col-sm-4 d-flex justify-content-center">
                                <span className="xpa-link">
                                  {completedProfile || 0}
                                </span>
                              </div>
                            </div> */}

                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Applied to Jobs", value: "yes" }
                                  }
                                })
                            }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                              <div className="col-sm-8 text-dark text-uppercase">
                                Applied For Positions
                              </div>
                              <div className="col-sm-4 d-flex justify-content-center">
                                <span className="xpa-link">
                                  {appliedForJobs || 0}
                                </span>
                              </div>
                            </div>

                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Shortlisted By Companies", value: "yes" }
                                  }
                                })
                            }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                              <div className="col-sm-8 text-dark text-uppercase">
                                Shortlisted By Companies
                              </div>
                              <div className="col-sm-4 d-flex justify-content-center">
                                <span className="xpa-link">
                                  {shortlistedByCompanies || 0}
                                </span>
                              </div>
                            </div>

                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Hired", value: "yes" }
                                  }
                                })
                            }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                              <div className="col-sm-8 text-dark text-uppercase">
                                Confirmed Placements
                              </div>
                              <div className="col-sm-4 d-flex justify-content-center">
                                <span className="xpa-link">
                                  {confirmedPlacements || 0}
                                </span>
                              </div>
                            </div>


                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Uploaded Signed Offer Letter", value: "yes" }
                                  }
                                })
                            }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                              <div className="col-sm-8 text-dark text-uppercase">
                                Uploaded Offer Letter
                              </div>
                              <div className="col-sm-4 d-flex justify-content-center">
                                <span className="xpa-link">
                                  {UploadedOfferLetter || 0}
                                </span>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <div className="container"> */}
                      <div className="row">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Offer In Progress", value: "yes" }
                              }
                            })
                        }} className="col-sm m-2 p-2 card xpa-link">
                          <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                            Offer in Progress
                            </div>
                          <h1 className="xpa-link">
                            {offersInProgress || 0}
                          </h1>
                        </div>

                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Hired", value: "no" }
                              }
                            })
                        }} className="col-sm m-2 p-2 card xpa-link">
                          <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                            No Placements
                            </div>
                          <h1 className="xpa-link">
                            {(cohortSize - confirmedPlacements) || 0}
                          </h1>
                        </div>

                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Application Submitted", value: "no" }
                              }
                            })
                        }} className="col-sm m-2 p-2 card xpa-link">
                          <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                            Uncompleted Profile
                            </div>
                          <h1 className="xpa-link">
                            {unCompletedProfile || 0}
                          </h1>
                        </div>
                      </div>
                      {/* </div>

                      </div> */}
                    </div>


                    {/* <div className="bx--row">
                      <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}`) }} 
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {cohortSize || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Cohort Size
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Application Submitted", value: "no" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Application%20Submitted=no&`) }} 
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {unCompletedProfile || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Uncompleted Profile
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Application Submitted", value: "yes" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Application%20Submitted=yes&`) }} 
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {completedProfile || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Completed Profile
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Applied to Jobs", value: "yes" }
                              }
                            })
                        }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {appliedForJobs || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Applied For Positions
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Shortlisted By Companies", value: "yes" }
                              }
                            })
                        }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {shortlistedByCompanies || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Shortlisted by Companies
                        </div>
                      </div> */}

                    {/*<div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => { push && push('/app/campus/browse/students?Completed%20Workflow=yes&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {completedInterviews || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Completed Interviews
                        </div>
                      </div>*/}
                    {/*<div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => { push && push('/app/campus/browse/students?Accepted%20Offer=yes&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {offeredPositions || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Offered Positions
                        </div>
                      </div>*/}
                    {/* <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Hired", value: "no" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Hired=no&`) }} 
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {(cohortSize - confirmedPlacements) || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          No Placements
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Offer In Progress", value: "yes" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Offer%20InProgress=yes&`) }}
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {offersInProgress || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Offer In Progress
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Hired", value: "yes" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Hired=yes&`) }}
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {confirmedPlacements || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Confirmed Placements
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Uploaded Signed Offer Letter", value: "yes" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Uploaded%20Signed%20Offer%20Letter=yes&`) }} 
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {UploadedOfferLetter || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Uploaded Offer Letter
                        </div>
                      </div>
                    </div> */}
                  </div>
                )
              }
              <hr />
            </Fragment>
          )
        }
        {
          ctypeId && Number(ctypeId) === 2 && isSuperAdmin && (
            <Fragment>
              <div>
                <h6 className="text-uppercase">
                  Companies
                </h6>
                {
                  companyCountLoader === true
                  && (
                    <div className="bx--row justify-content-around">
                      <Loading withOverlay={false} />
                    </div>
                  )
                }
                {
                  companyCountLoader !== true && (
                    <div>
                      <div className="container">
                        <div className="row">
                          {/* <div className="col-sm p-2 m-3 card xpa-link" onClick={() => { push && push('/app/campus/browse/companies?Companymapstatus=Pending&') }}>
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              New Registrations
                          </div>
                            <h1 className="xpa-link">
                              {pendingRegistration || 0}
                            </h1>
                          </div> */}
                          <div onClick={() => { push && push('/app/campus/browse/companies?Companymapstatus=Accepted&') }} className="col-sm m-3 p-2 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Registered Companies
                            </div>
                            <h1 className="xpa-link">
                              {approvedCompaniesCount || 0}
                            </h1>
                          </div>
                          <div onClick={() => { push && push(`/app/campus/jobs/approved/?${courseString}&${batchString}`) }} className="col-sm p-2 m-3 card xpa-link">
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Approved Job Postings
                          </div>
                            <h1 className="xpa-link">
                              {approved || 0}
                            </h1>

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm p-2 m-3 card xpa-link" onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}>
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              New Job Posting Requests
                            </div>
                            <h1 className="xpa-link">
                              {pending || 0}
                            </h1>
                          </div>
                          <div className="col-sm p-2 m-3 card xpa-link" onClick={() => { push && push(`/app/campus/jobs/approved/?positionsFilled=1&${batchString}`) }}>
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Positions Filled
                            </div>
                            <h1 className="xpa-link">
                              {positionsFilled || 0}
                            </h1>
                          </div>
                          <div className="col-sm m-3 p-2 card xpa-link" onClick={() => { push && push(`/app/campus/jobs/open/?positionsWithVacancies=1&${batchString}`) }}>
                            <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                              Positions With Vacancies
                            </div>
                            <h1 className="xpa-link">
                              {positionsWithVacancies || 0}
                            </h1>
                          </div>
                        </div>
                      </div>

                      {/*
                      <div>
                        <div className="bx--row">
                          <div className="bx--col-xl-4 bx--col-lg-6 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                            <div onClick={() => { push && push('/app/campus/browse/companies?Companymapstatus=Pending&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                              {pendingRegistration || 0}
                            </div>
                            <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                              New Registrations
                          </div>
                          </div>
                          <div className="bx--col-xl-4 bx--col-lg-6 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                            <div onClick={() => { push && push('/app/campus/browse/companies?Companymapstatus=Accepted&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                              {approvedCompanies || 0}
                            </div>
                            <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                              Approved Companies
                          </div>
                          </div>
                          <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                            <div onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                              {pending || 0}
                            </div>
                            <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                              New Job Posting Requests
                          </div>
                          </div>
                          <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                            <div onClick={() => { push && push(`/app/campus/jobs/approved/?${courseString}`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                              {approved || 0}
                            </div>
                            <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                              Approved Job Postings
                          </div>
                          </div>
                          <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                            <div onClick={() => { push && push('/app/campus/jobs/approved/') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                              {positionsFilled || 0}
                            </div>
                            <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                              Positions Filled
                          </div>
                          </div>
                          <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                            <div onClick={() => { push && push('/app/campus/jobs/approved/') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                              {positionsWithVacancies || 0}
                            </div>
                            <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                              Positions With Vacancies
                          </div>
                          </div>
                        </div>
                      </div>
                      */}
                    </div>

                  )
                }
              </div>
              <hr />
              <h6 className="text-uppercase">
                Students
              </h6>
              <JDProcessOverlay
                show={this.state && this.state.studentCountLoader}
                message="processing..."
              />
              {
                studentCountLoader === true
                && (
                  <div className="bx--row justify-content-around">
                    <Loading withOverlay={false} />
                  </div>
                )
              }


              {studentCountLoader === false && (
                <div>
                  <div className="container">
                    <div className="row mb-3">
                      <div className="col-lg-6" onClick={() => {
                        push && push(
                          {
                            pathname: '/app/campus/browse/students',
                            state: {
                              ...commonProps,
                            }
                          })
                        }}
                        // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                      >
                        <div className=" p-2 card xpa-link">
                          <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                            Cohort Size
                          </div>
                          <h1 className="xpa-link">
                            {cohortSize || 0}
                          </h1>
                        </div>
                      </div>
                      <div className="col-lg-6" 
                        onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Application Submitted", value: "yes" }
                              }
                            })
                        }}
                        // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                      >
                        <div className=" p-2 card xpa-link">
                          <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                            Profiles Completed
                          </div>
                          <h1 className="xpa-link">
                            {completedProfile || 0}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4" 
                        onClick={() => {
                          push && push(
                          {
                            pathname: '/app/campus/browse/students',
                            state: {
                              ...commonProps,
                              addOn: { label: "Room Offline Video Interview", value: "yes" }
                            }
                          })
                        }}
                        // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                      >
                        <div className=" p-2 card xpa-link">
                          <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                            Room Offline Video Interview
                          </div>
                          <h1 className="xpa-link">
                            {videoAssessmentCompletedCount || 0}
                          </h1>
                        </div>
                      </div>
                      <div className="col-lg-4"
                        onClick={() => {
                          push && push(
                          {
                            pathname: '/app/campus/browse/students',
                            state: {
                              ...commonProps,
                              addOn: { label: "Profiling Assessment", value: "yes" }
                            }
                          })
                        }} 
                        // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                      >
                        <div className=" p-2 card xpa-link">
                          <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                            Profiling Assessment
                          </div>
                          <h1 className="xpa-link">
                            {profilingAssessmentCompletedCount || 0}
                          </h1>
                        </div>
                      </div>
                      <div className="col-lg-4" 
                        onClick={() => {
                          push && push(
                          {
                            pathname: '/app/campus/browse/students',
                            state: {
                              ...commonProps,
                              addOn: { label: "Booked Panel Interview", value: "yes" }
                            }
                          })
                        }} 
                        // onClick={() => { push && push(`/app/campus/jobs/pending/?${courseString}&${batchString}`) }}
                      >
                        <div className=" p-2 card xpa-link">
                          <div className="text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                            Book Panel Interview
                          </div>
                          <h1 className="xpa-link">
                            {bookedCount || 0}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <NivoGraphs
                          finalNivoData={[
                            {
                              "id": "selectedForGeip",
                              "value": selectedForGeipCount || 0,
                              "label": "Selected For GEIP"
                            },
                            // {
                            //   "id": "profilesCompleted",
                            //   "value": completedProfile || 0,
                            //   "label": "Profiles Completed"
                            // },
                            {
                              "id": "appliedForJobs",
                              "value": appliedForJobs || 0,
                              "label": "Applied For Jobs"
                            },
                            {
                              "id": "shortlistedByCompanies",
                              "value": shortlistedByCompanies || 0,
                              "label": "Shortlisted By Companies"
                            },
                            {
                              "id": "confirmedPlacements",
                              "value": confirmedPlacements || 0,
                              "label": "Confirmed Placements"
                            }

                          ]}
                          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                          type="funnel"
                          // title="Average Duration(in days) Taken for a Student/Company to find a Suitable Match"
                          colors={["#e8c1a0", "#f47560", "#f1e15b", "#e8a838", "#61cdbb"]}
                          beforeSeparatorLength={0}
                          afterSeparatorLength={0}
                          afterSeparatorOffset={0}
                          currentPartSizeExtension={20}
                          currentBorderWidth={5}
                          height={300}
                        />
                        <div className="row">
                          <div className="d-flex mr-3 xpa-legend-box">
                            <div style={{ backgroundColor: "#e8c1a0" }} className="xpa-color-box"></div>
                            <div>Selected For GEIP</div>
                          </div>
                          {/* <div className="d-flex mr-3 xpa-legend-box">
                            <div style={{ backgroundColor: "#f47560" }} className="xpa-color-box"></div>
                            <div>Profiles Completed</div>
                          </div> */}
                          <div className="d-flex mr-3 xpa-legend-box">
                            <div style={{ backgroundColor: "#f1e15b" }} className="xpa-color-box"></div>
                            <div>Applied For Jobs</div>
                          </div>
                          <div className="d-flex mr-3 xpa-legend-box">
                            <div style={{ backgroundColor: "#e8a838" }} className="xpa-color-box"></div>
                            <div>Shortlisted By Companies</div>
                          </div>
                          <div className="d-flex mr-3 xpa-legend-box">
                            <div style={{ backgroundColor: "#61cdbb" }} className="xpa-color-box"></div>
                            <div>Confirmed Placements</div>
                          </div>
                        </div>

                      </div>

                      <div className="col-sm-9">
                        <div className="container">
                          <div onClick={() => {
                            push && push(
                              {
                                pathname: '/app/campus/browse/students',
                                state: {
                                  ...commonProps,
                                  addOn: { label: "Selected For GEIP", value: "yes" }
                                }
                              })
                          }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                            <div className="col-sm-8 text-dark text-uppercase">
                              Selected for GEIP
                              </div>
                            <div className="col-sm-4 d-flex justify-content-center">
                              <span className="xpa-link">
                                {selectedForGeipCount || 0}
                              </span>
                            </div>
                          </div>

                          {/* <div onClick={() => {
                            push && push(
                              {
                                pathname: '/app/campus/browse/students',
                                state: {
                                  ...commonProps,
                                  addOn: { label: "Application Submitted", value: "yes" }
                                }
                              })
                          }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                            <div className="col-sm-8 text-dark text-uppercase">
                              Profiles Completed
                              </div>
                            <div className="col-sm-4 d-flex justify-content-center">
                              <span className="xpa-link">
                                {completedProfile || 0}
                              </span>
                            </div>
                          </div> */}

                          <div onClick={() => {
                            push && push(
                              {
                                pathname: '/app/campus/browse/students',
                                state: {
                                  ...commonProps,
                                  addOn: { label: "Applied to Jobs", value: "yes" }
                                }
                              })
                          }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                            <div className="col-sm-8 text-dark text-uppercase">
                              Applied For Jobs
                              </div>
                            <div className="col-sm-4 d-flex justify-content-center">
                              <span className="xpa-link">
                                {appliedForJobs || 0}
                              </span>
                            </div>
                          </div>

                          <div onClick={() => {
                            push && push(
                              {
                                pathname: '/app/campus/browse/students',
                                state: {
                                  ...commonProps,
                                  addOn: { label: "Shortlisted By Companies", value: "yes" }
                                }
                              })
                          }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                            <div className="col-sm-8 text-dark text-uppercase">
                              Shortlisted By Companies
                              </div>
                            <div className="col-sm-4 d-flex justify-content-center">
                              <span className="xpa-link">
                                {shortlistedByCompanies || 0}
                              </span>
                            </div>
                          </div>

                          <div onClick={() => {
                            push && push(
                              {
                                pathname: '/app/campus/browse/students',
                                state: {
                                  ...commonProps,
                                  addOn: { label: "Hired", value: "yes" }
                                }
                              })
                          }} className="row p-2 m-3 xpa-link font-weight-bold xpa-border-bottom--solid-1 xpa-fs-l" >
                            <div className="col-sm-8 text-dark text-uppercase">
                              Confirmed Placements
                              </div>
                            <div className="col-sm-4 d-flex justify-content-center">
                              <span className="xpa-link">
                                {confirmedPlacements || 0}
                              </span>
                            </div>
                          </div>

                          {/*
  
                          <div className="row">
                            <div className="col-sm card xpa-link m-3 p-2" onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Hired", value: "yes" }
                                  }
                                })
                            }}>
                              <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                                Profiles Completed
                              </div>
                              <h1
                                // `/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Application%20Submitted=yes&`) }}
                                className="xpa-link">
                                {completedProfile || 0}
                              </h1>
                            </div>
                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Applied to Jobs", value: "yes" }
                                  }
                                })
                            }} className="col-sm card xpa-link m-3 p-2">
                              <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                                Applied For Jobs
                              </div>
                              <h1
                                // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Applied%20to%20Jobs=yes&`)  
                                className="xpa-link">
                                {appliedForJobs || 0}
                              </h1>
                            </div>
                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Shortlisted By Companies", value: "yes" }
                                  }
                                })
                            }} className="col-sm card xpa-link m-3 p-2">
                              <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                                Shortlisted By Companies
                              </div>
                              <h1
                                // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Shortlisted%20By%20Companies=yes&`) 
                                className="xpa-link">
                                {shortlistedByCompanies || 0}
                              </h1>
                            </div>
                            <div className="col-sm card xpa-link m-3 p-2">
                              <div onClick={() => {
                                push && push(
                                  {
                                    pathname: '/app/campus/browse/students',
                                    state: {
                                      ...commonProps,
                                      addOn: { label: "Hired", value: "yes" }
                                    }
                                  })
                              }} className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                                Confirmed Placements
                              </div>
                              <h1
                                // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Hired=yes&`) 
                                className="xpa-link">
                                {confirmedPlacements || 0}
                              </h1>
                            </div>
                          </div>
                            */}
                        </div>
                      </div>

                      {/* <div className="col-sm-9">
                        <div className="container">
                          <div className="row">
                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                  }
                                })
                            }} className="col-sm card xpa-link p-2 m-3">
                              <div className="col-sm text-dark text-uppercase bx--type-caption mb-1 xpa-f-sz">
                                Cohort Size
                              </div>
                              <div className="col-sm">
                                <h1
                                  // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}`) }} 
                                  className="xpa-link">
                                  {cohortSize || 0}
                                </h1>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm card xpa-link m-3 p-2" onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Application Submitted", value: "yes" }
                                  }
                                })
                            }}>
                              <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                                Profiles Completed
                              </div>
                              <h1
                                // `/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Application%20Submitted=yes&`) }}
                                className="xpa-link">
                                {completedProfile || 0}
                              </h1>
                            </div>
                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Applied to Jobs", value: "yes" }
                                  }
                                })
                            }} className="col-sm card xpa-link m-3 p-2">
                              <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                                Applied For Jobs
                              </div>
                              <h1
                                // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Applied%20to%20Jobs=yes&`)  
                                className="xpa-link">
                                {appliedForJobs || 0}
                              </h1>
                            </div>
                            <div onClick={() => {
                              push && push(
                                {
                                  pathname: '/app/campus/browse/students',
                                  state: {
                                    ...commonProps,
                                    addOn: { label: "Shortlisted By Companies", value: "yes" }
                                  }
                                })
                            }} className="col-sm card xpa-link m-3 p-2">
                              <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                                Shortlisted By Companies
                              </div>
                              <h1
                                // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Shortlisted%20By%20Companies=yes&`) 
                                className="xpa-link">
                                {shortlistedByCompanies || 0}
                              </h1>
                            </div>
                            <div className="col-sm card xpa-link m-3 p-2">
                              <div onClick={() => {
                                push && push(
                                  {
                                    pathname: '/app/campus/browse/students',
                                    state: {
                                      ...commonProps,
                                      addOn: { label: "Hired", value: "yes" }
                                    }
                                  })
                              }} className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                                Confirmed Placements
                        </div>
                              <h1
                                // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Hired=yes&`) 
                                className="xpa-link">
                                {confirmedPlacements || 0}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>

                  </div>
                </div>
              )}

              {/* {
                studentCountLoader === false && (
                  <div>
                    <div className="bx--row"> */}
              {/* <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}`) }} 
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {cohortSize || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Cohort Size
                        </div>
                      </div>
                    </div>
                    <div className="bx--row mt-3">
                      <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Application Submitted", value: "yes" }
                              }
                            })
                        }}
                          // `/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Application%20Submitted=yes&`) }}
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {completedProfile || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Profiles Completed
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Applied to Jobs", value: "yes" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Applied%20to%20Jobs=yes&`)  
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {appliedForJobs || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Applied For Jobs
                        </div>
                      </div> */}
              {/*<div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => { push && push('/app/campus/browse/students?Completed%20Workflow=yes&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {completedInterviews || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Completed Interviews
                        </div>
                      </div>*/}
              {/*<div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => { push && push('/app/campus/browse/students?Accepted%20Offer=yes&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {offeredPositions || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Offered Positions
                        </div>
                      </div>*/}
              {/* <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Shortlisted By Companies", value: "yes" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Shortlisted%20By%20Companies=yes&`) 
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {shortlistedByCompanies || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Shortlisted By Companies
                        </div>
                      </div>
                      <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                        <div onClick={() => {
                          push && push(
                            {
                              pathname: '/app/campus/browse/students',
                              state: {
                                ...commonProps,
                                addOn: { label: "Hired", value: "yes" }
                              }
                            })
                        }}
                          // push && push(`/app/campus/browse/students?${batchString}&${courseStudentString}&${facultyString}&Hired=yes&`) 
                          style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                          {confirmedPlacements || 0}
                        </div>
                        <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                          Confirmed Placements
                        </div>
                      </div> */}
              {/* </div>
                  </div>
                )
              } */}
              <hr />
            </Fragment>
          )
        }

        {
          ctypeId && Number(ctypeId) === 1 && (
            <Fragment>
              <h6 className="text-uppercase">
                Job postings
              </h6>
              <div className="text-right mb-4">
                <Button kind="primary" onClick={() => { push && push('/app/campus/new/job/registration') }}>
                  Create a New Job
                </Button>
              </div>
              {
                jobLoading === true
                && (
                  <div className="bx--row justify-content-around">
                    <Loading withOverlay={false} />
                  </div>
                )
              }
              {
                jobLoading !== true && (
                  <div className="bx--row">
                    <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                      <div onClick={() => { push && push('/app/campus/jobs/alljobs/') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                        {(dashboard && dashboard.jobs) || 0}
                      </div>
                      <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                        Total Job Postings <Tooltip>
                          {"Active and Non-active"}
                        </Tooltip>
                      </div>
                    </div>
                    <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                      <div onClick={() => { push && push('/app/campus/jobs/open/') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                        {open || 0}
                      </div>
                      <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                        Active Job Postings
                      </div>
                    </div>
                    <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                      <div onClick={() => { push && push('/app/campus/jobs/shared/') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                        {sharedJobCount || 0}
                      </div>
                      <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                        Active Shared Job Postings
                      </div>
                    </div>
                    <div className="bx--col-xl-4 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                      <div onClick={() => { push && push('/app/campus/jobs/closed/') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                        {closed || 0}
                      </div>
                      <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                        Position Filled
                      </div>
                    </div>
                  </div>
                )
              }
              <hr />
              <h6>
                Candidates
              </h6>
              <div className="bx--row">
                <div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push(`/app/campus/candidates/xopa/processing?`) }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {totalApplications || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Total Applications
                  </div>
                </div>
                <div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?applicationStatus=screening') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {numOfScreeningApplications || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    New Applications
                  </div>
                </div>
                <div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?applicationStatus=favourites') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {favouriteApplns || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Favorites
                  </div>
                </div>
                <div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?applicationStatus=shortlisted') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {numOfShortlistedApplications || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Shortlisted
                  </div>
                </div>
                {/*<div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?applicationStatus=pendingInterviews') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {pendingWorkflowCount || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Pending Interviews
                  </div>
                </div>*/}
              </div>
              <div className="bx--row mt-3">
                <div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?applicationStatus=completedInterviews') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {completedWorkflowCount || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Completed Interviews
                  </div>
                </div>
                {/*<div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?offerStatus=offer-detail-sent&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {(offersCount && offersCount['applications-completed']) || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Offered
                  </div>
                </div>*/}
                <div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?offerStatus=offer-detail-sent&') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {numOfCandidateOffers}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Offered
                  </div>
                </div>
                <div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?hiringStatus=is-hired') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {hiredApplns || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Hired
                  </div>
                </div>
                <div className="bx--col-xl-3 bx--col-lg-3 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                  <div onClick={() => { push && push('/app/campus/candidates/xopa/processing?applicationStatus=rejected') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                    {rejectedApplns || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">
                    Rejected
                  </div>
                </div>
              </div>
              <hr />
            </Fragment>
          )
        }

        <JobsView
          dashboard={dashboard}
          jobs={jobs && jobs.jobs}
          jobsCount={jobs && jobs.dashboard}
          handleJobsClick={this.handleJobsClick}
          handleOpenJobsClick={this.handleOpenJobsClick}
          showViewAll
          handleClosedJobsClick={this.handleClosedJobsClick}
          handleSharedJobsClick={this.handleSharedJobsClick}
          jobLoading={jobLoading}
          totalJobs={jobs && jobs.count}
          push={push}
        />
        <ApplicationView
          dashboard={dashboard}
          handleCandidatesClick={this.handleCandidatesClick}
          handleCandidatesInProcessClick={this.handleCandidatesInProcessClick}
          handleShortListClick={this.handleShortListClick}
          handleRejectClick={this.handleRejectClick}
          applications={applications && applications.applications}
          applCount={applications && applications.count}
          applLoading={applLoading}
          push={push}
        />
        {/* {
          ctypeId && Number(ctypeId) === 2 && (
            <JobApproversView
              approvalList={approvalList}
              jobApprLoading={jobApprLoading}
              push={push}
            />
          )
        } */}
        {/* <InterviewsView
          calendarEvents={calendarEvents}
          handleInterviewsClick={this.handleInterviewsClick}
          push={push}
        /> */}
        {/* {
          ctypeId && Number(ctypeId) === 1 && (
            <WorkflowSubstagesView
              subStages={subStages}
              push={push}
            />
          )
        } */}
        {/* <VideoInterviewsView
          handleVideoInterviewsClick={this.handleVideoInterviewsClick}
          handleInterviewWrokflowTabClick={this.handleInterviewWrokflowTabClick}
          selectedTab={selectedTab}
          interviewCount={interviewCount}
          dashboard={dashboard}
          videoIntvLoading={videoIntvLoading}
          interviews={interviews}
          videoIntStatus={videoIntStatus}
        /> */}
        <OffersView
          handleOffersClick={this.handleOffersClick}
          offerTab={offerTab}
          offersCount={offersCount}
          handleOffersTabClick={this.handleOffersTabClick}
          offersLoading={offersLoading}
          offers={offers}
          dashboard={dashboard}
          offerStatus={offerStatus}
          push={push}
        />
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  loading: state.roboroyDashboard.loading,
  dashboard: state.roboroyDashboard,
  calendarEvents: state.CalendarEvents.calendarEvents,
  applications: state.roboroyApplications,
  jobs: state.jobs,
  subStages: state.roboroyDashboard.subStages,
  interviews: state.videoInterviews.interviews,
  interviewCount: state.videoInterviews.count,
  offers: state.OfferInformation.offers,
  offersCount: state.OfferInformation.summary,
  user: state.x0paDuser.user,
  approvalList: state.JobApprovals.approvalList,
  companyFacets: state.BrowseCompanies.facets,
  studentFacets: state.BrowseStudents.facets,
  studentsCount: state.BrowseStudents.count,
  facultyData: state.candidateDetails.facultyData,
  coursesData: state.candidateDetails.coursesData,
});
const mapDispatchToProps = {
  fetchDashboard,
  getCalendarEvents,
  reRenderContent,
  jobsRenderData,
  getWorkflowSubstages,
  getInterviewsForRecruiter,
  getCandidatesInOffer,
  fetchAllCompaniesFacets,
  fetchAllInstitutionsList,
  getStudentsFacets,
  getJobApprovalListByStatus,
  getStudentsList,
  exportPlacementData,
  getDashboardsInfo,
  getAllCourses,
  getAllFaculties,
  getBatchDetails,
  fetchAllCompaniesList,
  getAssessmentsCounts
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard));