import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_GET_CITIES_LIST,
  REQUEST_CREATE_CITIES,
  REQUEST_EDIT_CITY,
  REQUEST_DELETE_CITY,
} from './actionTypes';

const getCitiesList = data => (dispatch) => {
  const {
    activePage,
    itemsPerPage,
    keyword,
  } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/cities?_=${new Date().getTime()}&`;
  if (itemsPerPage !== -1) {
    url += `filter[skip]=${skip}&`;
    url += `filter[limit]=${itemsPerPage}&`;
  }
  if (keyword) {
    url += `searchKey=${keyword}`;
  }
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CITIES_LIST,
  };
  return callApi(params);
};

const createCityDetails = data => (dispatch) => {
  const url = `${baseUrl}/cities?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_CITIES,
  };

  return callApi(params);
};

const upsertCityDetails = data => (dispatch) => {
  const {
    cityId,
  } = data || {};
  const url = `${baseUrl}/cities/${cityId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_CITY,
  };

  return callApi(params);
};

const deleteCityDetailsById = cityId => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No city id passed',
  };
  if (!cityId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/cities/${cityId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_CITY,
  };
  return callApi(params);
};

export {
  getCitiesList,
  createCityDetails,
  upsertCityDetails,
  deleteCityDetailsById,
};
