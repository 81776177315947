import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Avatar from 'components/common/Avatar';
import moment from 'moment';
import {
  createApplicantNotes,
  getApplicantNotes,
  editApplicantNotes,
  deleteApplicationNotes,
} from 'actions/ApplicantNotes';
import {
  TextArea, Button, Modal,
} from 'carbon-components-react';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

class ApplicantNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applNotes: '',
      isApplNotesValid: false,
      isApplNotesValidText: '',
      isOpenEditApplNotesModal: false,
      isOpenDeleteApplModal: false,
      editApplNotes: '',
      isApplEditNotesValid: false,
      isApplEditNotesValidText: '',
      editNoteId: 0,
      deleteNoteId: 0,
    };
  }

  componentDidMount() {
    const { appId, user } = this.props;
    const { userId } = user || {};
    this.props.getApplicantNotes(appId, userId);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activeOpenEditApplNotesModal = (noteId, notes) => {
    this.setState({
      isOpenEditApplNotesModal: true,
      editNoteId: noteId,
      editApplNotes: notes,
    });
  }

  dismissEditApplNotesModal = () => {
    const { isApplEditNotesValid, isApplEditNotesValidText } = this.state;
    if (isApplEditNotesValid === true) {
      this.setState({
        isApplEditNotesValid: false,
        isApplEditNotesValidText: '',
      });
    }
    this.setState({
      isOpenEditApplNotesModal: false,
    });
  }

  activeOpenDeleteApplNotesModal = (noteId) => {
    this.setState({
      isOpenDeleteApplModal: true,
      deleteNoteId: noteId,
    });
  }

  dismissDeleteApplNotesModal = () => {
    this.setState({
      isOpenDeleteApplModal: false,
    });
  }

  handleSubmitApplicationNotes = () => {
    const { appId, t, user } = this.props;
    const { userId } = user || {};
    const { applNotes } = this.state;
    const data = {
      notes: applNotes,
      appId,
    };
    if (applNotes === null || applNotes === undefined || applNotes === '') {
      this.setState({
        isApplNotesValid: true,
        isApplNotesValidText: `${t('validationUploading')}`,
      });
    } else {
      this.props.createApplicantNotes(data).then(() => {
        this.props.getApplicantNotes(appId, userId);
        this.setState({
          applNotes: '',
          isApplNotesValid: false,
          isApplNotesValidText: '',
        });
      })
        .catch(e => console.error(e));
    }
  }

  handleSubmitEditApplicationNotes = () => {
    const { appId, t, user } = this.props;
    const { userId } = user || {};
    const { editApplNotes, editNoteId } = this.state;
    if (editApplNotes === null || editApplNotes === undefined || editApplNotes === '') {
      this.setState({
        isApplEditNotesValid: true,
        isApplEditNotesValidText: `${t('validationUploading')}`,
      });
    } else {
      const data = {
        appId,
        notes: editApplNotes,
      };
      this.props.editApplicantNotes(data, editNoteId).then(() => {
        this.props.getApplicantNotes(appId, userId);
        this.dismissEditApplNotesModal();
      })
        .catch(e => console.error(e));
    }
  }

  handleSubmitDeleteApplicationNotes = () => {
    const { appId, user } = this.props;
    const { userId } = user || {};
    const { deleteNoteId } = this.state;
    this.props.deleteApplicationNotes(deleteNoteId).then(() => {
      this.props.getApplicantNotes(appId, userId);
      this.dismissDeleteApplNotesModal();
    })
      .catch(e => console.error(e));
  }

  render() {
    const {
      user,
      notes, loading,
      t,
    } = this.props;
    const {
      firstname,
      lastname,
      picture,
    } = user || {};
    const {
      applNotes, isApplNotesValid, isApplNotesValidText, isOpenEditApplNotesModal,
      isOpenDeleteApplModal, editApplNotes, isApplEditNotesValid, isApplEditNotesValidText,
    } = this.state;
    return (
      <Fragment>
        <div className="bx--type-delta">{t('notes')}</div>
        <hr />
        <p className="text-dark mb-0">{t('notesMsg')}</p>
        <div className="d-flex mt-2">
          <div className="ml-2">
            <Avatar
              user={{
                firstname,
                lastname,
                picture,
              }}
              classNameInfo="rounded-circle"
              size="30"
            />
          </div>
          <div className="ml-2">
            <TextArea
              labelText=""
              hideLabel
              invalid={isApplNotesValid}
              invalidText={isApplNotesValidText}
              name="applNotes"
              placeholder={t('enterNotes')}
              id="test2"
              value={applNotes}
              onChange={(e) => { this.handleChange(e); }}
            />
          </div>
        </div>
        <div className="mt-2 ml-5">
          <Button onClick={this.handleSubmitApplicationNotes} small kind="primary">{t('add')} {t('note')}</Button>
        </div>
        <hr />
        {
          notes && Array.isArray(notes)
            && notes.length > 0
            && notes.map((res) => {
              const {
                notes: notesValue,
                createdDate,
                noteId,
                userinfo: {
                  firstname: resFirstName,
                  lastname: resLastName,
                  picture: resPicture,
                } = {},
              } = res || {};
              return (
                <div>
                  <div>
                    <span>
                      <Avatar
                        user={{
                          firstname: resFirstName,
                          lastname: resLastName,
                          picture: resPicture,
                        }}
                        classNameInfo="rounded-circle"
                        size="30"
                      />
                    </span>
                    <span className="ml-2 bx--type-zeta text-uppercase">{resFirstName} {resLastName}</span>
                    <span className="ml-1 text-dark"><small>{moment(createdDate).format('MMM Do YYYY')}</small></span>
                  </div>
                  <div className="mt-1 text-justify ml-1">{notesValue}</div>
                  <div>
                    <Button onClick={() => this.activeOpenEditApplNotesModal(noteId, notesValue)} kind="ghost" small>{t('edit')}</Button>
                    <Button onClick={() => this.activeOpenDeleteApplNotesModal(noteId)} className="ml-2" kind="ghost" small>{t('delete')}</Button>
                  </div>
                  <hr />
                </div>
              );
            })
        }
        <Modal
          open={isOpenEditApplNotesModal}
          modalHeading={`${t('edit')} ${t('note')}`}
          primaryButtonText={t('edit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissEditApplNotesModal}
          onRequestSubmit={this.handleSubmitEditApplicationNotes}
        >
          <TextArea
            labelText=""
            hideLabel
            invalid={isApplEditNotesValid}
            invalidText={isApplEditNotesValidText}
            name="editApplNotes"
            placeholder={t('enterNotesPlaceholder')}
            id="test1"
            value={editApplNotes}
            onChange={(e) => { this.handleChange(e); }}
          />
        </Modal>
        <Modal
          open={isOpenDeleteApplModal}
          modalHeading={`${t('delete')} ${t('note')}`}
          danger
          primaryButtonText={t('delete')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissDeleteApplNotesModal}
          onRequestSubmit={this.handleSubmitDeleteApplicationNotes}
        >
          <p>{t('deleteNoteMsg')}</p>
        </Modal>
        <JDProcessOverlay show={loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.ApplicantNotes.loading,
  notes: state.ApplicantNotes.notes,
});

const mapDispatchToProps = {
  createApplicantNotes,
  getApplicantNotes,
  editApplicantNotes,
  deleteApplicationNotes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ApplicantNotes));
