import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
//import Company from '../../components/Admin/Company';
import {
  getAllIndustries,
  getAllCompanies,
  createNewOption,
  getCompanyData,
  updateResource,
  deleteResource,
  createNewResource,
  associateCompany,
  getAllStates,
  getAllCountries,
  getAuthorizedCompanies,
  dissociateCompany,
  authorizeCompany
} from '../../actions/admin/CompanyAndOffice';

// import AccordionTable from './accordionTable';

import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';

import {
  TextInput, Button, Modal, Loading, InlineNotification, Pagination, Tile, TextArea,
} from 'carbon-components-react';

const NoCompaniesBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no companies currently associated.</p>
    </div>
  </Tile>
);
class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: 0,
      addNewCompanyModal: false,
      isCompanyChange: false,
      businessName: '',
      countryId: 0,
      stateId: 0,
      stateFull: '',
      city: '',
      office: '',
      zip: '',
      companyId: 0,
      industryId: 0,
      website: '',
      addressLine1: '',
      addressLine2: '',
      rowsData: [],
      companyValue: {},
      isCompanyPresent: true,
      isSelectedCompany: false,
      selectedCompany: { description: '', website: '', },
      headersInfo: [
        { key: 'companyName', header: 'Company Name' },
        { key: 'description', header: 'Description' },
        { key: 'website', header: 'Website' },
        { key: 'status', header: 'Status' },
        { key: 'action', header: 'Action' },
      ],
      rowData: [],
      error: false,
      activePage:1,
      itemsPerPage:10,
      headerData: [
        { key: 'details', header: 'Details' },
      ]
    };
  }

  onRemoveCompany = (companyObj) => {
    this.setState({ addNewCompanyModal: false });
    this.props.dissociateCompany([
      {
        companyId: companyObj.childCompanyId,
      }
    ]).then(() => {
      this.reRenderContent();
    });
  }

  componentDidMount() {
    // this.props.getAllIndustries();
    // this.props.getAllCountries();
    // this.props.getCompanyData({ type: 'company' });
    this.reRenderContent();
  }

  reRenderContent = ({itemsPerPage=null,activePage}={}) => {
    itemsPerPage = itemsPerPage===null?this.state.itemsPerPage:itemsPerPage;
    activePage = activePage===null?this.state.activePage:activePage;
    this.props.getAuthorizedCompanies({itemsPerPage,activePage}).then(() => {
      const {managedCompanies={}}=this.props.companyAndOffice.authorizedCompanies;
      let companies = managedCompanies.companies||[] ;
      let rowsData = companies.map((company) => ({
          id: company.childCompanyId.toString(),
          companyName: (
            <div className="text-capitalize">
              {company.company.companyName || "-"}
            </div>
          ),
          description:company.description || "-",
          website:company.website || "-",
          status: company.isParentCompany ||company.status === null ? (<div className="mt-2 ml-1 bx--tag bx--tag--beta">Parent Company</div>) : (
            <Fragment>
              {company && company.status === "Pending" && (<div className="mt-2 ml-1 bx--tag bx--tag--cool-gray">Pending</div>)}
              {company && company.status === "Accepted" && (<div className="mt-2 ml-1 bx--tag bx--tag--green">Accepted</div>)}
              {company && company.status === "Rejected" || (company && company.status === "Revoked")  && (<div className="mt-2 ml-1 bx--tag bx--tag--red">Rejected</div>)}
            </Fragment>            
          ),
          action: (<Button kind="danger" disabled={company.isParentCompany} title={company.isParentCompany ? "This Company can't be removed" : ""}
              small onClick={(e) => this.onRemoveCompany(company)}>Remove</Button>),
        }));
      rowsData = rowsData && Array.isArray(rowsData) ? rowsData : [];
      this.setState({ rowsData });
    });
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () =>
      this.reRenderContent({
        itemsPerPage:itemsPerPage,
        activePage,
      }));
  };

  handleCompanySelection = (e = {}) => {
    const { label = '', value = '' } = e === null ? {} : e;
    const companyValue = { label, value };
    this.setState({ companyId: value, companyValue }, () => {
      this.state.companyId && this.props.getCompanyData({ type: 'company', id: this.state.companyId }).then((res) => {
        this.setState({
          selectedCompany: {
            companyId: res && res.companyId || 0,
            companyName: res && res.companyName || '',
          }
        })
      });
    });
  }

  onAddCompany = () => {
    this.setState({ addNewCompanyModal: true, companyId: 0, isCompanyPresent: true, isSelectedCompany: false });
  };

  onNewOptionCreate = (value, params) => {
    let actionData = {};
    if (params == 'company') {
      this.setState({ isNewCompany: true, });
      actionData = {
        apiName: 'companies',
        data: {
          companyName: value,
        },
      };
    }
    else if (params == 'state') {
      actionData = {
        apiName: 'States',
        data: {
          stateFull: value,
          stateShort: '',
          countryId: this.state.countryValue.value || 1,
        },
      };
    }
    this.props.createNewOption(actionData).then((newCompany) => {
      this.setState({ companies: [{ company_id: newCompany.companyId, company_name: newCompany.companyName }], companyId: newCompany.companyId });
    });
  };

  onInputCompanyDropdown = (value, cb) => {
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      if (value.length >= 2) {
        this.props.getAllCompanies({
          apiName: 'companies-base/companies',
          paramName: 'companyName',
          value,
        }).then((res) => {
          if (res && Array.isArray(res) && cb && typeof cb === 'function') {
            const options = res.map(one => ({ label: one.company_name, value: one.company_id }));
            cb(options);
          }
        });
      }
      return value;
    }
  };

  onInputStateDropdown = (value) => {
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      if (value.length > 3) {
        this.props.getAllStates({
          apiName: 'States/autoComplete',
          paramName: 'stateFull',
          value,
        });
      }
      return value;
    }
    return;
  };


  upsertCompany = () => {
    if (this.state.companyValue && this.state.companyValue.value && this.state.companyValue.value === null) {
      this.setState({ isCompanyPresent: false });
      return;
    }
    const Found = new Promise((res, rej) => {
      if (this.state.rowsData && this.state.rowsData.length > 0) {
        this.state.rowsData.find((ele) => {
          if ((ele.companyId == this.state.companyValue.value) && ((ele.status === 'Accepted') || (ele.status === '-'))) {
            rej(ele);
          }
        });
        res();
      }
    });
    Found.then((found) => {
      this.setState({ addNewCompanyModal: false, isSelectedCompany: false, isCompanyPresent: true, });
      const Data = {
        type: 'company',
        others: this.state.selectedCompany
      }
      // this.props.createNewResource(Data).then(() => {
      this.props.associateCompany([
        {
          companyId: this.state.selectedCompany.companyId,
          description: this.state.selectedCompany.description,
          website: this.state.selectedCompany.website
        },
      ]).then((res) => {
        let error = false;
        if (res.error) error = true;
        this.setState({ companyId: 0, companyValue: {}, error, selectedCompany: { description: '', website: '', }, companies: [{}] });
        this.reRenderContent();
      });
    });
    Found.catch(() => (this.setState({ isSelectedCompany: true })))
    // });
  };

  setCompany = () => (this.setState({ isCompanyPresent: true }));
  setSelectCompany = () => (this.setState({ isSelectedCompany: false }));

  render() {
    const { rowsData, headersInfo, error, headersInfoAgencies, rowsDataAgencies } = this.state;
    const extras = {
      companyName:{className:"bx--col-12 justify-content-center bx--type-zeta",},
      description:{className:"bx--col-12 justify-content-center",},
      website:{className:"bx--col-12 justify-content-center text-info",},
      status:{className:"bx--col-12 justify-content-center",},
      action:{className:"bx--col-12 justify-content-center",},
    };
    const { user: { company = {}, currentCompany = {} } } = this.props;    

    let isParentCompany = false;
    if ((company && company.companyId) == (currentCompany && currentCompany.companyId)) {
      isParentCompany = true;
    }
    return (
      <div>
        <Loading active={this.props.loading} className='companiesLoading' />
        {error === true && <InlineNotification lowContrast
          title="Unable to add this company to managed services, Please contact X0PA support."
          subtitle=""
          onCloseButtonClick={() => this.setState({ error: false })}
          iconDescription="close button"
          kind="error"
        />}
        <Modal
          open={this.state.addNewCompanyModal}
          className='w-100'
          modalHeading="Choose a company"
          modalLabel="Company"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          iconDescription="Close the modal"
          onRequestClose={() => { this.setState({ addNewCompanyModal: false, selectedCompany: { description: '', website: '', } }) }}
          onRequestSubmit={() => this.upsertCompany()}
          onSecondarySubmit={() => { this.setState({ addNewCompanyModal: false, selectedCompany: { description: '', website: '', } }) }}
        >
          {/*<div className="form-group row">
            
             
            </div> 
          </div>*/}
          {/* <div className="newjob_input_container col-xs-12 col-sm-12 col-md-9">
          <Company
            companyAndOffice={this.props.companyAndOffice}
            getCompanyData={this.props.getCompanyData}
            updateResource={this.props.updateResource}
            deleteResource={this.props.deleteResource}
            createNewResource={this.props.createNewResource}
          /></div> */}
          {
            !this.state.isCompanyPresent && <InlineNotification lowContrast
              title="Please enter the company name."
              subtitle=""
              onCloseButtonClick={this.setCompany}
              iconDescription="close button"
              kind="error"
            />
          }
          {
            this.state.isSelectedCompany && <InlineNotification lowContrast
              title="This company is already added."
              subtitle=""
              onCloseButtonClick={this.setSelectCompany}
              iconDescription="close button"
              kind="error"
            />
          }
          <DropDownBoxWithCreate
            required
            titleClass="p-0"
            className="p-0"
            mainClassName="p-0 m-0"
            isLoading={this.props.companyAndOffice.companiesAutocompleteLoading}
            isAsync
            options={this.props.companies && Array.isArray(this.props.companies)
              && this.props.companies.map(({ company_id, company_name }) => ({ label: company_name, value: company_id }))}
            newOptionCreator={(name, value) => this.onNewOptionCreate(value, 'company')}
            onInputChange={this.onInputCompanyDropdown}
            title={'Company'}
            placeholder="Please enter company name"
            onChange={this.handleCompanySelection}
            labelClass="bx--type-epsilon"
            name="jobValue"
            selectedValue={this.state.companyValue}
          />
          {/* <label className="bx--label">Industry</label>
          <Select
            id='industry_name'
            options={this.props.companyAndOffice.industries.map(({ industryId, industryName }) => ({
              label: industryName,
              value: industryId,
            }))}
            required
            className="m-1"
            value={this.state.industryId}
            onInputChange={() => { }}
            placeholder="Indusrty"
            onChange={(e) => { this.setState({ industryId: e.value }) }}
          />
          {
            (this.state.isNewCompany)?
            (<div><TextInput name="address1" labelText="Address Line 1" className="m-1 w-auto"
           placeholder="Flat no, Buildin..." value={this.state.addressLine1}  maxLength={80}
            onChange={value=>this.setState({ addressLine1: value.target.value })}
            show = {this.state.isNewCompany} />
          <TextInput name="address2" labelText="Address Line 2" className="m-1 w-auto"
           value={this.state.addressLine2} maxLength={80}
            onChange={value =>this.setState({ addressLine2: value.target.value })}
             placeholder="Street, Location..." show={this.state.isNewCompany} /></div>)
             :(<div><label className="bx--label">Office Address</label>
             <Select.Creatable
               id='offcie_address'
               options={this.props.companyAndOffice && this.props.companyAndOffice.companies && Array.isArray(this.props.companyAndOffice.companies) && this.props.companyAndOffice.companies.map(({ companyId, companyName }) => {
                 return {
                   label: companyName,
                   value: companyId,
                 }
               }
               )}
               required
               show = {!this.state.isNewCompany}
               onNewOptionClick={value => this.onNewOptionCreate(value,'office')}
               onInputChange={e => this.onInputOfficeDropdown(e)}
               placeholder="Office Address"
               className="m-1"
               onChange={(e) => { this.setState({ companyId: e.value }) }}
               value={this.state.companyId}
             /></div>)}
          <label className="bx--label">Country</label>
          <Select
            id='country_name'
            options={this.props.companyAndOffice && this.props.companyAndOffice.countries && this.props.companyAndOffice.countries.map(({ countryId, countryFull }) => ({
              label: countryFull,
              value: countryId,
            }))}
            required
            className="m-1"
            value={this.state.countryId}
            onInputChange={() => { }}
            placeholder="Country"
            onChange={(e) => { this.setState({ countryId: e.value }) }}
          />
          <label className="bx--label">State</label>
          <Select.Creatable
            id='state_name'
            options={this.props.companyAndOffice && this.props.companyAndOffice.states && Array.isArray(this.props.companyAndOffice.states) && this.props.companyAndOffice.states.map(({ stateId, stateFull }) => {
              return {
                label: stateFull,
                value: stateId,
              }
            }
            )}
            required
            onNewOptionClick={value => this.onNewOptionCreate(value,'state')}
            onInputChange={e => this.onInputStateDropdown(e)}
            placeholder="State name"
            className="m-1"
            onChange={(e) => { this.setState({ stateId: e.value }) }}
            value={this.state.stateId}
          />
          <TextInput name="city" labelText="City" className="m-1 w-auto" placeholder="zip code"  maxLength={10} value={this.state.city || ''} onChange={(value) => { this.setState({ city: value.target.value }) }} /> */}
          <div>
            <div className="bx--col-8 p-0 newjob_input_container">
              <TextArea
                className="mb-3"
                labelText="Description"
                placeholder="description..."
                col={40}
                id="description"
                value={this.state.selectedCompany && this.state.selectedCompany.description}
                onChange={(e) => { this.setState({ selectedCompany: { ...this.state.selectedCompany, description: e.target.value } }) }}
                state={this.state}
              />
            </div>
          </div>
          <div className="mb-3 p-0 newjob_input_container">
            <div className="bx--col-8 p-0">
              <TextInput
                id="website"
                labelText="Website"
                type="email"
                placeholder="website"
                value={this.state.selectedCompany && this.state.selectedCompany.website}
                onChange={(e) => {
                  console.log(e.target.value);
                  this.setState({ selectedCompany: { ...this.state.selectedCompany, website: e.target.value } });
                }}
              />
              {/* <InputFieldBox
                className=""
                title="Website"
                name="website"
                labelClass="bx--type-epsilon"
                value={this.state.selectedCompany && this.state.selectedCompany.website}
                onChange={(e) => {
                  console.log(e.target.value);
                  this.setState({ selectedCompany: { ...this.state.selectedCompany, website: e.target.value } });
                }}
                state={this.state}
              /> */}
            </div>
          </div>
        </Modal>
        {isParentCompany ? (    
          <div className="companies">
          {rowsData && Array.isArray(rowsData) && rowsData.length > 0 &&
            <GenericDataTable rows={rowsData} headers={headersInfo} extras={extras}
              rowClass={"text-center p-0 bx--col-12"} otherActions={[]} />}
          {this.props.count > 0 && <Pagination
            onChange={this.handlePaginationChange}
            pageSizes={[10, 20, 30, 40, 50]}
            page={this.state.activePage}
            totalItems={this.props.count} />}
          {this.state.rowsData && this.state.rowsData.length <= 0 && (<NoCompaniesBox />)}
        </div>
        ) : (
          <Tile className="bx--col-md-3 mt-2 mb-2">
            <div className="text-center text-dark pt-2">
              Switch to your parent company view to see companies you manage.
            </div>
          </Tile>
          )}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  companyAndOffice: state.companyAndOffice,
  count: (state && state.companyAndOffice && state.companyAndOffice.authorizedCompanies  && state.companyAndOffice.authorizedCompanies.managedCompanies && state.companyAndOffice.authorizedCompanies.managedCompanies.count) || 0,
  companyId: state.companyAndOffice.companyId,
  companies: state.companyAndOffice.companies,
  stateId: state.companyAndOffice.stateId,
  loading: state.companyAndOffice.loading,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getAllIndustries,
  getAllCompanies,
  createNewOption,
  updateResource,
  deleteResource,
  getCompanyData,
  createNewResource,
  getAllStates,
  getAllCountries,
  associateCompany,
  getAuthorizedCompanies,
  dissociateCompany,
  authorizeCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);