import callApi from 'utils/callApi';
import { baseUrl, regularUrl } from 'utils/config';
import {
  REQUEST_USER_EMAIL_INFO,
  REQUEST_GET_USER_EMAIL_INFO
} from './actionTypes';

const employeeEmailConfirmation = (data) => dispatch => {
  const {  
    userId,
    email
   } = data || {};

  let url = `${baseUrl}/companies/employeeEmailConfirmation`;

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_USER_EMAIL_INFO,
  };
  return callApi(params);
};

const getEmailConfirmationInfo = data => dispatch => {
  const { 
    userId
   } = data || {};
  let url = `${baseUrl}/companies/getEmailConfirmationInfo/${userId}?`;
  
  const params = {
    dispatch,
    actionType: REQUEST_GET_USER_EMAIL_INFO,
    url,
    method: 'GET',
  };
  return callApi(params);
};


export {
 employeeEmailConfirmation,
 getEmailConfirmationInfo
};