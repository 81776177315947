import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_GLOBAL_SEARCH,
} from './actionTypes';

const searchGlobally = data => (dispatch) => {
  const {
    limit = 10, key = '', skip = 0, type,
  } = data;
  let url = `${baseUrl}/search?_=${new Date().getTime()}&searchKey=${key}&filter[limit]=${limit}&filter[skip]=${skip}`;
  url = type ? `${url}&type=${type}` : url;
  const params = {
    dispatch,
    actionType: REQUEST_GLOBAL_SEARCH,
    url,
    method: 'GET',
    responseType: 'application/json',
  };
  return callApi(params);
};


export {
  searchGlobally,
};
