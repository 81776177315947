import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  DataTableSkeleton, Tile, Modal, DataTable,
  Tabs, Tab, Button,
} from 'carbon-components-react';
import classNames from 'classnames';

import BackRow from 'components/common/BackRow/BackRow';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';

import {
  getEmailTemplates, upsertEmailTemplate, getCustomEmailTemplates,
  deleteCustomEmailTemplates,
} from 'actions/email-templates';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

const NoTemplateBox = ({
  t,
}) => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">{t('noTemplatesMsg')}</p>
    </div>
  </Tile>
);

const {
  TableContainer, Table, TableRow,
  TableBody, TableCell,
} = DataTable;

class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenCustomizeModal: false,
      activeTemplateId: 0,
      activeTemplateName: '',
      rowData: [],
      selected: 'default',
      headersInfo: [{ key: 'template', header: 'Emailtemplates' }],
    };
  }

  componentDidMount = () => {
    this.props.getEmailTemplates();
    this.setRows('default');
  }

  handleActiveTemplate = (templateId = 0, type, templateName, ownerId, displayName) => {
    if (templateId) {
      let status = 'draft';
      if (type === 'draft') {
        status = 'active';
      } else {
        status = 'draft';
      }
      if (!ownerId) {
        this.setState({
          isOpenCustomizeModal: true,
          activeTemplateId: templateId,
          activeTemplateName: displayName,
        });
      } else {
        this.props.upsertEmailTemplate({
          templateId, status, templateName,
        });
      }
    }
  }

  CardRowBox = (rowData, val) => (
    <div className="bx--tile p-0">
      <div className="">
        <div className="bx--row p-2">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="h5">
              {`${rowData.templateName.displayName}`}
            </div>
            {
              rowData && rowData.templateDesc
                && rowData.templateDesc.desc
                && (
                  <div className="text-justify text-dark">
                    {`${rowData.templateDesc.desc}`}
                  </div>
                )
            }
            {
              val.toString() === 'default'
                && (
                  <div className="mt-1">
                    {rowData.status}
                  </div>
                )
            }
            <div className="mt-2">
              {rowData.customize}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  setRows = (val) => {
    const { templates = [] } = this.props;
    const rowData = templates && Array.isArray(templates) && templates.map((template) => {
      const {
        id, templateName, desc, ownerId, displayName, status,
      } = template || {};
      let templateType = false;
      if ((status === 'active') && (ownerId !== null)) {
        templateType = true;
      }
      const oneRow = {
        templateName: { displayName },
        templateDesc: { desc },
        status: (
          <div>
            <span className="bx--type-omega">
              DEFAULT
            </span>
            <button
              type="button"
              className={classNames('btn', 'btn-toggle', { active: templateType })}
              data-toggle="button"
              aria-pressed="false"
              autoComplete="off"
              onClick={() => this.handleActiveTemplate(id, status, templateName, ownerId, displayName)}
            >
              <div className="handle" />
            </button>
            <span className="ml-2 bx--type-omega">
              CUSTOM
            </span>
          </div>
        ),
        customize: (
          <div>
            <Link
              to={`/app/campus/email/${id}`}
              className="bx--btn bx--btn--sm bx--btn--tertiary"
            >
            Customize
            </Link>
            {
              this.state.selected === 'custom'
                && (
                  <Button
                    kind="danger"
                    small
                    className="ml-2"
                    onClick={() => { this.handleDeleteCustomEmailTemplates(id); }}
                  >
                    Delete
                  </Button>
                )
            }
          </div>
        ),
      };
      return { id: `${id}`, template: this.CardRowBox(oneRow, val) };
    });
    this.setState({
      rowData: rowData || [],
    });
  }

  goToCustomizeEmail = () => {
    const { activeTemplateId } = this.state;
    this.props.history.push(`/app/campus/email/${activeTemplateId}`);
  }

  dismissCustomizeModal = () => {
    this.setState({
      isOpenCustomizeModal: false,
    }, () => this.props.getEmailTemplates());
  }

  handleTabClick = (value) => {
    if (value === 'default') {
      this.setState({
        selected: 'default',
      }, () => {
        this.props.getEmailTemplates().then(() => this.setRows('default'));
      });
    } else {
      this.setState({
        selected: 'custom',
      }, () => {
        this.props.getCustomEmailTemplates().then(() => this.setRows('custom'));
      });
    }
  }

  handleDeleteCustomEmailTemplates = (templateId) => {
    this.props.deleteCustomEmailTemplates(templateId).then(() => {
      this.props.getCustomEmailTemplates().then(() => this.setRows('custom'));
    })
  }

  render() {
    const paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus' },
      { id: 'email-templates', name: 'Email Templates', href: '' },
    ];
    const { loading, templates = [], t, user } = this.props;
    const {
      user: {
        isSuperAdmin,
        isAdmin,
      } = {},
    } = this.props;
    const { headersInfo, rowData, selected } = this.state;
    const emailTemplates = templates.map(o => o);
    let headers = [
      {
        key: 'templateName',
        header: `${t('templates')} ${t('name')}`,
        colpropName: 'templateName',
        className: 'minw-250',
      },
      {
        key: 'templateDesc',
        header: `${t('desc')}`,
        colpropName: 'desc',
      },
      // {
      //   key: 'status',
      //   header: `${t('active')} ${t('templates')}`,
      //   colpropName: 'active',
      //   className: 'text-center minw-250',
      // },
      {
        key: 'customize',
        header: `${t('action')}`,
        colpropName: 'customize',
      },
    ];
    let rows = [];
    if (emailTemplates && Array.isArray(emailTemplates)) {
      rows = emailTemplates.map((r) => {
        const {
          id, templateName, desc, ownerId, displayName, status,
          isSharedWithCompany,
        } = r || {};
        let templateType = false;
        if ((status === 'active') && (ownerId !== null)) {
          templateType = true;
        }
        return {
          id,
          templateName: (
            <div className="text-dark text-uppercase font-weight-bold">
            { displayName || '-' } { isSharedWithCompany ? (<span className="bx--tag bx--tag--custom">Shared</span>) : null }
            </div>
          ),
          templateDesc: <div className="text-dark">{desc}</div>,
          customize: (
            <div>
              <Link
                to={`/app/campus/email/${id}`}
                className="bx--btn bx--btn--sm bx--btn--tertiary"
              >
                {t('customize')}
              </Link>
              {
                selected === 'custom'
                  && (
                    <Button
                      kind="danger"
                      small
                      className="ml-2"
                      onClick={() => { this.handleDeleteCustomEmailTemplates(id); }}
                    >
                      {t('delete')}
                    </Button>
                  )
              }
            </div>
          ),
          status: (
            <div>
              <span className="bx--type-omega">
                {t('default')}
              </span>
              <button
                type="button"
                className={classNames('btn', 'btn-toggle', { active: templateType })}
                data-toggle="button"
                aria-pressed="false"
                autoComplete="off"
                onClick={() => this.handleActiveTemplate(id, status, templateName, ownerId, displayName)}
              >
                <div className="handle" />
              </button>
              <span className="ml-2 bx--type-omega">
                {t('custom')}
              </span>
            </div>
          ),
        };
      });
    }
    if (selected === 'custom') {
      headers = headers.filter(one => (one.key !== 'status'));
    }
    const { isOpenCustomizeModal, activeTemplateName } = this.state;
    return (
      <div className="xpa-email-templates">
        <BackRow paths={paths} />
        <div className="h6 font-weight-bold text-capitalize pb-1">{t('email')} {t('templates')}</div>
        {/* <p className="bx--type-epsilon font-weight-normal">
          In this section, you can change the content of the emails that are sent from RoboRoy. The following is the list
          of emails that get sent from RoboRoy in different scenarios. Email content is common across all the companies you manage. You may choose to use the default template provided 
          by RoboRoy or you could choose to customize a template.
        </p> */}
        {
          (isSuperAdmin === true || isAdmin === true) && (
            <Tabs selected={0}>
            <Tab label={<span>{t('default')} {t('templates')}</span>} onClick={() => this.handleTabClick('default')} ></Tab>
            <Tab label={<span>{t('custom')} {t('templates')}</span>} onClick={() => this.handleTabClick('custom')}>
              <div className="justify-content-end d-flex">
                <Link className="bx--btn bx--btn--sm bx--btn--primary" to="/app/campus/email/add">{t('addCustomTemplate')}</Link>
              </div>
            </Tab>
          </Tabs>
          )
        }
        { loading && (isSuperAdmin === true || isAdmin === true) && <DataTableSkeleton zebra /> }
        { !loading && (isSuperAdmin === true || isAdmin === true) && (
          <GenericDataTable
            className="d-none d-lg-block"
            headers={headers}
            rows={rows}
            selectable={false}
            searchable={false}
            expandable={false}
            otherActions={false}
            customSorting={() => {}}
            batchActions={false}
          />)
        }
        <div className="d-lg-none">
          <DataTable
            rows={rowData}
            headers={headersInfo}
            render={({ rows, headers, getHeaderProps }) => {
              return (
                <TableContainer title="" className="bg-transparent mt-2" style={{ overflow: "hidden" }}>
                  <Table className="bg-transparent" style={{ borderSpacing: "0px 6px", borderCollapse: "separate" }}>
                    <TableBody className="bg-transparent">
                      {rows.map(row => (
                        <TableRow key={row.id} className="mb-2">
                          {row.cells.map(cell => (
                            <TableCell
                              key={cell.id}
                              className="pr-0 pl-0"
                            >
                              {cell.value}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>);
            }}
          />
        </div>
        {
          (isSuperAdmin !== true && isAdmin !== true) && (
            <div className="h6 text-center">
              {t('noAccessToWebPage')}
            </div>
          )
        }
        {(rows.length === 0 && (isSuperAdmin === true || isAdmin === true) && <NoTemplateBox t={t} />)}
        <Modal
          open={isOpenCustomizeModal}
          onRequestSubmit={() => { this.goToCustomizeEmail(); }}
          onRequestClose={this.dismissCustomizeModal}
          modalLabel="Send Email"
          primaryButtonText="Customize"
          secondaryButtonText="Cancel"
          modalHeading="Customize Email template"
        >
          <p>
            You have choosen to customize the default email template for <b>{activeTemplateName}</b>.
            Clicking on <b>Customize</b> will redirect to a new page where the template can be edited.
          </p>
          <p>{t('areYouSureMsg')}</p>
        </Modal>
      </div>);
  }
}

const mapStateToProps = state => ({
  loading: state.emailTemplates.loading,
  user: state.x0paDuser.user,
  templates: state.emailTemplates && state.emailTemplates.templates,
});

const mapDispatchToProps = {
  getEmailTemplates,
  upsertEmailTemplate,
  getCustomEmailTemplates,
  deleteCustomEmailTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailTemplates));
