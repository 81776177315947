import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import { getCandidateUsageReport } from 'actions/talentSearch/userusagereport';
import moment from "moment";

class CandidateUsageReport extends  Component {
	componentDidMount() {
		this.props.getCandidateUsageReport({skip: 0, limit: 50});
	}
	    render () {
            const {userdata : {jobsCount = 0, count = 0, trend = [], details = []} = {}} = this.props.userdata;
            const columnWithFilter = [
				{
					title: 'Date',
					className: 'text-left',
					name: 'userId',
					propName: 'trend[0].create_date',
				},
				{
					title: 'Count',
					className: 'text-left',
					name: 'userName',
					propName: 'trend[0].count',
				},			
				{
					title: 'Sample Profiles',
					className: 'text-left',
					name: 'userName',
					propName: 'trend[0].count',
				},					
			];            
      return (
				<Fragment>
	                <h4 className="bx--type-gamma font-weight-bold text-capitalize">Candidates Report</h4>
                    <hr/>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 mb-2">
                            <div className="bx--tile">
                                <div className="numiconcard__number--title text-uppercase xpa-numiconcard__open-jobs">
                                    Total number of unique candidates
                                </div>
                                <div className="numiconcard__number--number text-center">
                                    {count}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 mb-2">
                            <div className="bx--tile">
                                <div className="numiconcard__number--title text-uppercase xpa-numiconcard__open-jobs">
                                    Total number of jobs created
                                </div>
                                <div className="numiconcard__number--number text-center">
                                    {jobsCount}
                                </div>
                            </div>
                        </div>						
                    </div>
                    <GenericDataTable 
						    className=""
							title={``}
							otherActions={[]}
							customSorting={() => { }}
							rowClass="p-0"
							headers={
								columnWithFilter.map((data, index) => (
									{
										key: `header_${index}`,
										header: data.title,
										className: data.className,
										colpropName: data.propName
									}
								))
							}

							rows = {
								trend.map((eachTrend, index) => (
								{
									"isExpanded": false,
									"id": `row_${index}`,
									"header_0": eachTrend.create_date ? moment(eachTrend.create_date).format("MMMM Do, YYYY") : '',
									"header_1": eachTrend.count,
									"header_2" : eachTrend.create_date ? PrintLinks(details[new Date(eachTrend.create_date).getTime()]) : '',
								}
								))
							}

							selectable={false}
    						searchable={false}
    						onSearchInputChange={() => { }}
    						expandable={false}
    						batchActions={false}
						/>                    
        	        <JDProcessOverlay show={this.props.loading} message="processing..." />
          </Fragment>
				);
			}
    }


const PrintLinks = (details) => (
	<div>
        {
          details.map((actObj)=> {
            return (
				<div>
					<a href={actObj.linkedin} >
						{actObj.linkedin}
					</a>
				</div>
            )
          })
        }
	</div>
) 

const mapStateToProps = state => ({
		loading: state.usageReport.loading,
		userdata: state.usageReport,
});

const mapDispatchToProps = {
    getCandidateUsageReport,
};

export default connect(mapStateToProps,mapDispatchToProps)(CandidateUsageReport);
    