//gets the current stage that is in progress
const getCurrentStageInProgress = (workflow) => {
    let stage, index;

    if (workflow && workflow.stages) {
        index = 0;
        for (let eachStage of workflow.stages) {
        if (eachStage && eachStage.stageMeta && !eachStage.stageMeta.outcomeId) {
            stage = eachStage;
            break;
        }
        index++;
        }
    }

    return { stage, index };
}

//gets current substage in progress, given a stage object
const getCurrentSubStageInProgress = (stage) => {
    let subStage, index = -1;
    if (stage && stage.subStages) {
        index = 0;
        for (let eachSubStage of stage.subStages) {
        if (eachSubStage && !eachSubStage.isSubStageCompleted) {
            subStage = eachSubStage;
            break;
        }
        index++;
        }
    }
    return { subStage, index };
}

const isThereARejectedStage = (workflow) => {
    let hasRejectedStage = false;

    if (workflow && workflow.stages) {
        for (let eachStage of workflow.stages) {
        if (eachStage && eachStage.stageMeta && eachStage.stageMeta.outcomeId && (eachStage.stageMeta.outcomeId == 6001)) {
            hasRejectedStage = true;
            break;
        }
        }
    }

    return hasRejectedStage;
}

// checks if this is the current stage.
const isThisSubStageCurrentlyInProgress = (givenSubStage, currentSubStageInProgress) => {
  // console.log("--- is this in progress = ", currentSubStageInProgress.subStage, givenSubStage, (currentSubStageInProgress.subStage && currentSubStageInProgress.subStage.appStageUserMapId) == (givenSubStage && givenSubStage.appStageUserMapId));
  // return ((currentSubStageInProgress.subStage
  //   && currentSubStageInProgress.subStage.appStageUserMapId) == (givenSubStage && givenSubStage.appStageUserMapId));
  return ((currentSubStageInProgress.subStage && currentSubStageInProgress.subStage.collaborators && currentSubStageInProgress.subStage.collaborators[0] &&  currentSubStageInProgress.subStage.collaborators[0].appStageUserMapId) == (givenSubStage && givenSubStage.collaborators && givenSubStage.collaborators[0] &&  givenSubStage.collaborators[0].appStageUserMapId));

};

const isThisSubStageComplete = (givenSubStage) => {
  return givenSubStage && givenSubStage.collaborators && givenSubStage.collaborators[0] &&  givenSubStage.collaborators[0].appStageUserMapId;
//  return (givenSubStage.appStageUserFeedbackId);
}

const isThisStageComplete = (givenStage) => {
  return (givenStage && givenStage.stageMeta && givenStage.stageMeta.outcomeId);
};

const isThisStageCurrent = (givenStage, currentStageInProgress) => {
  return (givenStage && givenStage.stageMeta
    && givenStage.stageMeta.appStageId) == ((currentStageInProgress && currentStageInProgress.stage && currentStageInProgress.stage.stageMeta && currentStageInProgress.stage.stageMeta.appStageId))
};

// checks if this is a video interview stage
const isThisVideoInterviewStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.workflowstage
  && givenStage.stageMeta.workflowstage.workflowstagetype
  && (givenStage.stageMeta.workflowstage.workflowstagetype.stageTypeName == 'Video Interview');
};

const isThisPhoneInterviewStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.workflowstage && givenStage.stageMeta.workflowstage.workflowstagetype && (givenStage.stageMeta.workflowstage.workflowstagetype.stageTypeName === 'Phone Interview');
};

const isThisOnsiteInterviewStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.workflowstage && givenStage.stageMeta.workflowstage.workflowstagetype && (givenStage.stageMeta.workflowstage.workflowstagetype.stageTypeName === 'Onsite Interview');
};

const isThisOnlineInterviewStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.workflowstage && givenStage.stageMeta.workflowstage.workflowstagetype && (givenStage.stageMeta.workflowstage.workflowstagetype.stageTypeName === 'Online Interview');
};

// checks if this is a scheduling substage
const isThisASchedulingSubStage = (givenSubStage) => {
  return givenSubStage && givenSubStage.subStageMeta && (givenSubStage.subStageMeta.substage.toLowerCase() == "scheduling");
};

const isThisAInterviewSubStage = (givenSubStage) => {
  return givenSubStage && givenSubStage.subStageMeta && (givenSubStage.subStageMeta.substage.toLowerCase() == "interview");
};

const isThisVideoInterviewSchedulingSubStage = (givenStage, givenSubStage) => {
  return (isThisVideoInterviewStage(givenStage) && isThisASchedulingSubStage(givenSubStage));
};

const isThisPhoneInterviewSchedulingSubStage = (givenStage, givenSubStage) => {
  return (isThisPhoneInterviewStage(givenStage) && isThisASchedulingSubStage(givenSubStage));
};

const isThisOnsiteInterviewSchedulingSubStage = (givenStage, givenSubStage) => {
  return (isThisOnsiteInterviewStage(givenStage) && isThisASchedulingSubStage(givenSubStage));
};

const isThisOnlineInterviewSchedulingSubStage = (givenStage, givenSubStage) => {
  return (isThisOnlineInterviewStage(givenStage) && isThisASchedulingSubStage(givenSubStage));
};

const isThisFeedbackSubstage = (givenSubStage) => {
  return (givenSubStage && givenSubStage.subStageMeta && givenSubStage.subStageMeta.substage.toLowerCase() == "feedback");
}

const isThisDecisionSubstage = (givenSubStage) => {
  return (givenSubStage && givenSubStage.subStageMeta && givenSubStage.subStageMeta.substage.toLowerCase() == "decision");
};

const isCurrentUserAdminLevelCollaborator = (user, collaborationList) => {
  if (user && collaborationList && Array.isArray(collaborationList)) {
    const userId = user && user.userId;
    const foundUserList = collaborationList
      .filter(eachCollaborator => eachCollaborator && eachCollaborator.userId == userId && ((eachCollaborator.roleId == 1) || (eachCollaborator.roleId == 4)));

    return (foundUserList && Array.isArray(foundUserList) && foundUserList.length > 0);
  }
  return false;
};

//checks if we are currently waiting on user to provide feedback/complete an action on this substage.
const shouldUserActOnThisSubStage = (user, application, givenSubStage, currentSubStageInProgress) => {
  //if the sub-stage is assigned to the current user and if this substage is the current substage and if feedback is not given, then true
  console.log("shouldUserActOnThisSubStage = ", givenSubStage, currentSubStageInProgress, user, isThisSubStageCurrentlyInProgress(givenSubStage, currentSubStageInProgress));
  //if the current user is a collabortor for this job and is an administrator.
  return (givenSubStage && ( (givenSubStage.isAssignedToCurrentUser)
    // && !givenSubStage.appStageUserFeedbackId
    && !(givenSubStage && givenSubStage.collaborators && givenSubStage.collaborators[0] &&  givenSubStage.collaborators[0].appStageUserFeedbackId)
    && isThisSubStageCurrentlyInProgress(givenSubStage, currentSubStageInProgress)))
    // && ((currentSubStageInProgress && currentSubStageInProgress.subStage && currentSubStageInProgress.subStage.appStageUserMapId) == givenSubStage.appStageUserMapId)));
}

const isEventCreatedForStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.eventId;
};

const isEventConfirmedForStage = (givenStage) => {
  return (isEventCreatedForStage(givenStage) && givenStage.stageMeta.calendarevents && (givenStage.stageMeta.calendarevents.status === 'active'));
};

const canShowScheduleButton = (eachStage, eachSubStage) => {
  // console.log("--- can show ",eachStage, eachSubStage, isThisASchedulingSubStage(eachSubStage)
  // , isThisPhoneInterviewSchedulingSubStage(eachStage, eachSubStage)
  // , !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage)
  // , !isEventCreatedForStage(eachStage));
  return ((isThisASchedulingSubStage(eachSubStage) || isThisAInterviewSubStage(eachSubStage))
  && (
    isThisPhoneInterviewSchedulingSubStage(eachStage, eachSubStage)
    || isThisOnsiteInterviewSchedulingSubStage(eachStage, eachSubStage)
    || isThisOnlineInterviewSchedulingSubStage(eachStage, eachSubStage)
  )
  && !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage)
  && !(isEventCreatedForStage(eachStage))
  // && (!isThisDecisionSubstage(eachSubStage) && !isThisFeedbackSubstage(eachSubStage))
  );
};

const isVideoInterviewStageInStages = (stagesList = [], workflowStagesArr = []) => {
  const videoStagesArr = stagesList.map((stage) => {
    const stageInfo = workflowStagesArr.find(e => e.stages.find(p => p.stageId === stage));
    if (stageInfo && stageInfo.stageTypeName && stageInfo.stageTypeName.toLowerCase
      && stageInfo.stageTypeName.toLowerCase().includes('video')) {
      // console.log('STAGE', stage);
      // console.log(JSON.stringify(stageInfo));
      return stage;
    }
    return false;
  })
    .filter(e => e);
  if (Array.isArray(videoStagesArr) && videoStagesArr.length > 0) {
    // console.log('VIDEO STAGES:', videoStagesArr);
    return videoStagesArr;
  }
  return false;
};

const isSchedulingConfirmed = (eachStage, eachSubStage) => {
  return ((isThisASchedulingSubStage(eachSubStage) || isThisAInterviewSubStage(eachSubStage))
    && (
      isThisPhoneInterviewSchedulingSubStage(eachStage, eachSubStage)
      || isThisOnsiteInterviewSchedulingSubStage(eachStage, eachSubStage)
      || isThisOnlineInterviewSchedulingSubStage(eachStage, eachSubStage)
    )
    && !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage)
    && isEventConfirmedForStage(eachStage)
  );
};

const isThisOnSiteOrPhoneInterviewSubstage = (givenStage, givenSubStage) => {
  return (isThisAInterviewSubStage(givenSubStage) && (isThisOnsiteInterviewStage(givenStage) || isThisPhoneInterviewStage(givenStage)));
};

const isThisOnlineInterviewSubstage = (givenStage, givenSubStage) => {
  return (isThisAInterviewSubStage(givenSubStage) && isThisOnsiteInterviewStage(givenStage));
};

module.exports = {
  getCurrentStageInProgress,
  isThereARejectedStage,
  getCurrentSubStageInProgress,
  isThisSubStageCurrentlyInProgress,
  isThisPhoneInterviewSchedulingSubStage,
  isThisPhoneInterviewStage,
  isThisASchedulingSubStage,
  isThisSubStageComplete,
  isThisStageComplete,
  isThisStageCurrent,
  isThisVideoInterviewSchedulingSubStage,
  isThisFeedbackSubstage,
  isThisDecisionSubstage,
  isCurrentUserAdminLevelCollaborator,
  shouldUserActOnThisSubStage,
  canShowScheduleButton,
  isEventCreatedForStage,
  isVideoInterviewStageInStages,
  isSchedulingConfirmed,
  isThisOnsiteInterviewSchedulingSubStage,
  isThisAInterviewSubStage,
  isThisOnsiteInterviewStage,
  isThisOnlineInterviewSubstage,
  isThisOnlineInterviewStage,
  isThisOnSiteOrPhoneInterviewSubstage,
  isThisOnlineInterviewSchedulingSubStage,
};
