import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import { 
  REQUEST_GET_USER_ACTIVITY_LOGS,
} from './actionTypes';

const getUserActivityLogs = data => (dispatch) => {
  const {
    userId,
    activePage,
    itemsPerPage,
  } = data || {};
  if (!userId) {
    const error = {
      msg: 'Invalid user id',
    };
    Promise.reject(error);
  }
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  const url = `${baseUrl}/userlogs?_=${new Date().getTime()}&userId=${userId}&filter[skip]=${skip}&filter[limit]=${itemsPerPage}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_USER_ACTIVITY_LOGS,
  };
  return callApi(params);
};

export {
  getUserActivityLogs,
};
