import { withTranslation } from 'react-i18next';
import React from 'react';
import {
  TextArea,
} from 'carbon-components-react';

const FacultyQuestionsConfig = (props) => {
  const {
    t, questionConfig,
    handleDeleteQuesConfig,
    showAnsInput, state,
    handleChange, disabled,
    answerConfig,
  } = props;
  return (
    <div className="mb-4">
      {
        questionConfig && Array.isArray(questionConfig)
          && questionConfig.length > 0
          && questionConfig.map((res, idx) => {
            const {
              question,
              uuid,
            } = res || {};
            return (
              <div>
                <div className="d-flex">
                  <div className="mb-2 bx--type-zeta">
                    {question}
                  </div>
                  {
                    showAnsInput === false && (
                      <div className="ml-lg-2">
                        <a style={{ color: 'red' }} onClick={() => handleDeleteQuesConfig(uuid)} href="javascript:void(0)">{t('delete')}</a>
                      </div>
                    )
                  }
                </div>
                {
                    showAnsInput && answerConfig && Array.isArray(answerConfig)
                    && answerConfig.length > 0
                    && (
                      <hr />
                    )
                  }
                {
                    showAnsInput && ((!answerConfig || (answerConfig && Array.isArray(answerConfig) && answerConfig.length <= 0)) || disabled !== true) && (
                      <TextArea
                        className="mb-2"
                        id={`answer-${idx}`}
                        value={state[`answer-${idx}`]}
                        hideLabel={false}
                        disabled={disabled}
                        name={`answer-${idx}`}
                        onChange={(e) => { handleChange(e, uuid, idx); }}
                        invalid={state.isValidAns}
                        invalidText={t('offerMsg11')}
                      />
                    )
                  }
                {
                  showAnsInput && answerConfig && Array.isArray(answerConfig)
                    && answerConfig.length > 0
                    && disabled === true
                    && answerConfig.map((ans) => {
                      const {
                        answer,
                        uuid: ansUUID,
                      } = ans || {};
                      return (
                        <div>
                          {
                            uuid === ansUUID ? <span>{answer}</span> : null
                          }
                          
                        </div>
                      );
                    })
                }
              </div>
            );
          })
      }
    </div>
  );
};

export default (withTranslation()(FacultyQuestionsConfig));
