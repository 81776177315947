import { batch } from 'react-redux';
import callApi from '../utils/callApi';
import { rppyUrl,baseUrl } from '../utils/config';
import {
    REQUEST_ANALYTICS_INFO,
    REQUEST_UPDATE_INFO
} from './actionTypes';

const getAnalyticsInfo = data => (dispatch) => {
    const { schoolId, courseId, batchId } = data;
    const err = {
        data: '',
        error: true,
        message: 'Missing required fields.',
    };
    if (schoolId===null || schoolId===undefined || courseId===null || courseId===undefined || batchId===null || batchId===undefined) {
        return Promise.reject(err);
    }
    let url = `${baseUrl}/rppy/read-json/all?school_id=${schoolId}&course_id=${courseId}&batch_id=${batchId}`;
    
    const params = {
        dispatch,
        method: 'GET',
        url,
        actionType: REQUEST_ANALYTICS_INFO,
        headers:{
            "x-api-key":"tEmHQDTz1BR0ZAILRPUJCDjpkAj6QztzqYJbsOe1HXLcTpYwgbQdKIdmAoWnaCi0"
        }
    };
    return callApi(params);
}

const updateInfo = () => (dispatch) => {
    let url = `${baseUrl}/rppy/update-data`;
    const params = {
        dispatch,
        method: 'GET',
        url,
        actionType: REQUEST_UPDATE_INFO,
        headers:{
            "x-api-key":"tEmHQDTz1BR0ZAILRPUJCDjpkAj6QztzqYJbsOe1HXLcTpYwgbQdKIdmAoWnaCi0"
        }
    };
    return callApi(params);
}

export {getAnalyticsInfo,updateInfo};