import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { Tabs, Tab } from 'carbon-components-react';
import './GenericTabs.css';
import SetMixPanel from 'components/common/GetCurrentPath';


function GenericTabs(props) {
  const {tabContent, selected, className} = props;
  return (
    <Tabs className={`${className}`} triggerHref="#anotherAnchor" selected={selected || 0}>
      {tabContent.map((tab, tabIdx) => (
        <Tab key={tabIdx}
          className={tab.className}
          onClick={() => {
            SetMixPanel(`TAB_${tab.label}_CLICKED`);
            if (tab.onClick) tab.onClick();
          }}
          onKeyDown={tab.onKeyDown?tab.onKeyDown:()=>{}}
          label={tab.label}>
          <div className="some-content">
              {tab.value}
          </div>
        </Tab>
      ))}
    </Tabs>
  );
}

export default GenericTabs;
