import { withTranslation } from 'react-i18next';
import React from 'react';
import { connect } from 'react-redux';
import { login } from 'actions';
import { push } from 'react-router-redux';
import classNames from 'classnames';
import { Icon } from 'carbon-components-react';
import { iconArrowLeft } from 'carbon-icons';

import { fetchUserLoginState } from 'actions/userstate';
import './Login.css';
import { Link } from 'react-router-dom';
import SetMixPanel from 'components/common/GetCurrentPath';
import { InlineNotification } from 'carbon-components-react';
import PublicHeader from 'containers/common/publicHeader';


class Recruiterlogin extends React.Component {
  state = {
    isLogging: false,
    username: '',
    password: '',
    error: false,
  };

  componentDidMount() {
    SetMixPanel('RECRUITER_LOGIN');
    window.redirectToDashboard2 = (data, origin) => {
      console.log(data, origin);
    };
    this.props.fetchUserLoginState().then((res) => {
      const { user: { userId, roleId } = {} } = this.props;
      if (userId && userId !== null && roleId && roleId === 3) {
        this.props.history.push('/app/campus/candidate/business/finland/start');
      }
    });
  }

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value,
      error: false,
    });
  }

  handleOnSubmit = (e) => {
    const {
      user: {
        userId,
      } = {},
    } = this.props;
    e.preventDefault();
    this.props.setLogging(true);
    this.setState({ isLogging: true });
    this.props.login(this.state.username, this.state.password).then(() => {
        this.props.setLogging(false);
        this.props.fetchUserLoginState(userId);
      }).catch(() => {
        this.props.setLogging(false);
        this.setState({ isLogging: false, error: true });
      });
  }

  onCloseButtonClick = () => {
    this.setState({
      error: false,
    });
  }

  render() {
    const formClass = classNames({
      'form-signin': true,
      'text-center': true,
      card: true,
      error: this.state.error,
    });
    
    return (
      <div className="recruiterlogin login-page col-12 bx--row m-0 p-0">
        <PublicHeader />
        <div className="left-side col-12 col-md-6 col-lg-6 col-xl-6 m-0 p-0 d-flex justify-content-center align-items-center flex-column">
          {/* <Link className="bx--btn bx--btn--ghost mt-3 ml-2" to="/" style={{ color: 'white', background: `rgba(0,0,0,0.4)` }}>
            <Icon className="mr-2" icon={iconArrowLeft} fill="white" description="leftArrow" />
            Go back to home page
          </Link> */}
          <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
            <div className="m-3 text-center p-2" style={{ background: 'rgba(0,0,0,0.4)' }}>
              <div className="h1 font-weight-bold"> Artificial intelligence enabled platform for hiring </div>
              <div className="login-list" style={{ fontSize: 18 }}>
                <ol>
                  <li className="m-0 p-0">External hiring</li>
                  <li className="m-0 p-0">Internal hiring</li>
                  <li className="m-0 p-0">Resource allocation and management</li>
                  <li className="m-0 p-0">Restructuring and reorganizations</li>
                  <li className="m-0 p-0">Managed services</li>
                  <li className="m-0 p-0">AI Sourcing</li>
                </ol>
              </div>
              <a href="https://www.x0pa.com/" target="_blank" className="bx--btn bx--btn--tertiary mt-3" style={{ color: 'white', borderColor: 'white' }}>Learn more</a>
            </div>
          </div>
        </div>
        <div className="right-side d-flex justify-content-center align-items-center pt-3 col m-0 p-0" style={{ backgroundColor: 'white', height: 768 }}>
          <div className="d-block text-center">
            <div className="ml-3">
              <img className="mb-4 mx-auto" src={require('assets/images/republic-polytechnic.png')} />
            </div>
            <div className="xpa--login">
              <div className="row" id="xpaLoginTabContent">
                <div className="col-sm-12 col-md-6 col-lg-6 mb-3" id="login" role="tabpanel" aria-labelledby="login-tab">
                  <form className={formClass} onSubmit={this.handleOnSubmit}>
                    <h1 className="h3 mb-3 font-weight-normal">Client Login</h1>
                    {
                      this.state.error && (
                        <InlineNotification lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="error"
                          subtitle="Invalid login details"
                          onCloseButtonClick={this.onCloseButtonClick}
                        />
                      )
                    }
                    <div className="bx--form-item">
                      <input
                        type="text"
                        name="username"
                        required
                        placeholder="Email/Username"
                        autoComplete="username"
                        className="bx--text-input mb-2"
                        onChange={e => this.handleOnChange('username', e.target.value)}
                      />
                    </div>
                    <div className="bx--form-item">
                      <input
                        type="password"
                        name="password"
                        required
                        placeholder="Password"
                        autoComplete="current-password"
                        className="bx--text-input"
                        onChange={e => this.handleOnChange('password', e.target.value)}
                      />
                    </div>
                    <button className="bx--btn bx--btn--primary btn-block" type="submit">Sign in</button>
                    <Link className="mt-2" to="/forgot-password">Forgot password?</Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  login, push, fetchUserLoginState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recruiterlogin);
