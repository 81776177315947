import { withTranslation } from 'react-i18next';
import React from 'react';

const FacultyDescription = ({ facultyDesc, t }) => {

    const renderHtml = (htmlObject)=> {return <div dangerouslySetInnerHTML={htmlObject} />};
    return (
      <div>
        {facultyDesc ? <div className="xpa-format--pre-content jdesc mb-3">{renderHtml({__html : facultyDesc})}</div> : null}
      </div>
    );
};

export default (withTranslation()(FacultyDescription));
