import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toggle } from 'carbon-components-react';
import {enableTalentPoolSharing, fetchUserLoginState} from 'actions/userstate';

class GeneralSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({enableTalentpoolSharing: this.props && this.props.user && this.props.user.enableTalentpoolSharing})
  }

  toggleTalentPoolSharing = () => {
    this.setState({enableTalentpoolSharing: !this.state.enableTalentpoolSharing});
    const data = {
      enableTalentpoolSharing: !this.props.user.enableTalentpoolSharing,
      userId: this.props.user && this.props.userId,
    };
    this.props.enableTalentPoolSharing(data);
  }
  render() {
    const {enableTalentpoolSharing = false } = this.state;
    const {user : { sharinghash = ''}} = this.props;
    return (
      <div className="xpa--profile-security">
        <div className="bx--tile mt-2">
          <div className="bx--type-epsilon pb-1">Talent pool settings</div>
            <div>
                <div className="bx--row">
                    <div className="bx--col-xs-8">
                        <p className="text-dark bx--type-zeta mb-0  pt-4">Enable talent pool link sharing</p>
                        {<div className="text-dark bx--type-caption mb-0">
                            <div>You can share this link with your propsective candidates to be part of your talent pool.</div>                         
                        </div>}
                        {<p className="text-dark bx--type-caption mb-0">
                            Your personal talent pool link is <a href={`${window.location.protocol}//${window.location.host}/public/join/talentpool/${sharinghash}`}>{window.location.protocol}//{window.location.host}/public/join/talentpool/{sharinghash}</a>
                        </p>}
                    </div>
                    <div className="bx--col-xs-4 text-right">
                        {/* <Toggle
                            id="togglePrivate" 
                            className="xpa-toggle"
                            onToggle={this.toggleTalentPoolSharing}
                            toggled={enableTalentpoolSharing}
                            labelA="No"
                            labelB="Yes"
                        /> */}
                    </div> 
                </div>
            </div>          
          <hr/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  enableTalentPoolSharing,
  fetchUserLoginState,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
