import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import { Pagination } from 'carbon-components-react';
import { getUsersUsageReport } from 'actions/talentSearch/userusagereport';
import moment from "moment";

class UserUsageReport extends  Component {
	componentDidMount() {
		this.props.getUsersUsageReport({skip: 0, limit: 50});
	}

    handlePaginationChange = (data) => {
		const page = (data.page) || 1;  
		const skip = ((page - 1) * 50);
		
		this.props.getUsersUsageReport({skip, limit: 50});
	}
	    render () {
			const {userdata = []} = this.props.userdata;		
				const columnWithFilter = [
				{
					title: 'User ID',
					className: 'text-left',
					name: 'userId',
					propName: 'userdata[0].userId',
				},
				{
					title: 'Name',
					className: 'text-left',
					name: 'userName',
					propName: 'userdata[0].firstname userdata[0].lastname',
				},
				{
					title: 'Email',
					className: 'text-center',
					name: 'emailId',
					propName: 'userdata[0].email'
				},				
				{
					title: 'Jobs created',
					className: 'text-center ',
					name: 'jobsCount',
					propName: 'userdata[0].jobsCount',
				},
				{
					title: 'Applications count',
					className: 'text-center ',
					name: 'applicationsCount',
					propName: 'userdata[0].applicationsCount',
				},
				{
					title: 'Subscription',
					className: 'text-center ',
					name: 'subscriptions',
					propName: 'userdata[0].subscriptions',
				}				
			];

      return (
				<Fragment>
	                <h4 className="bx--type-gamma font-weight-bold text-capitalize">Usage Report</h4>                
					<GenericDataTable 
						    className=""
							title={``}
							otherActions={[]}
							customSorting={() => { }}
							rowClass="p-0"
							headers={
								columnWithFilter.map((data, index) => (
									{
										key: `header_${index}`,
										header: data.title,
										className: data.className,
										colpropName: data.propName
									}
								))
							}
							rows = {
								userdata && userdata.map ? userdata.map((user) => (
								{
									"isExpanded": false,
									"id": `row_${user.userId}`,
									"header_0": user.userId,
									"header_1": (
										`${user.firstname} ${user.lastname}` || '-'
									),
									"header_2":user.email,
									"header_3":user.jobsCount,
									"header_4":user.applicationsCount,
									"header_5": (<div>
											{user.subscriptions && user.subscriptions.length > 0 ? (
													(user.subscriptions.map((eachSubscription) => (
														<div><small>
														{`${eachSubscription.productName} - ${moment(eachSubscription.startDate).format("MMMM Do YYYY")} - ${moment(eachSubscription.endDate).format("MMMM Do YYYY")}`}
														</small></div>
													)))													
											) : '-'}
										</div>)
									
								}
								)) : []
							}

							selectable={false}
    						searchable={false}
    						onSearchInputChange={() => { }}
    						expandable={false}
    						batchActions={false}
						/>
						<div className="">
							<Pagination
								page={1}
								onChange={this.handlePaginationChange}
								pageSizes={[50]}
								totalItems={1000}
								/>
						</div>

        	<JDProcessOverlay show={this.props.loading} message="processing..." />
          </Fragment>
				);
			}
    }



const mapStateToProps = state => ({
		loading: state.usageReport.loading,
		userdata: state.usageReport,
});

const mapDispatchToProps = {
    getUsersUsageReport,
};

export default connect(mapStateToProps,mapDispatchToProps)(UserUsageReport);
    
