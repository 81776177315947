import { randomJob, randomCandidate, randomUser, xopaScore } from './helpers';

export const users = [];

export const jobs = [];
export const candidates = [];
export const xopaScores = [];

for (let i = 0; i < 15; i += 1) {
  jobs.push(randomJob());
  candidates.push(randomCandidate());
  users.push(randomUser());
  xopaScores.push(xopaScore());
}
export default null;
