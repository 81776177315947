import {
  REQUEST_X0PA_DUSER, SUCCESS_X0PA_DUSER, ERROR_X0PA_DUSER,
  SUCCESS_GTP_OPTIN_UPDATE, ERROR_GTP_OPTIN_UPDATE,
  REQUEST_CHANGE_USER_TZ, SUCCESS_CHANGE_USER_TZ, ERROR_CHANGE_USER_TZ,
  REQUEST_USER_LOGIN_INFO, SUCCESS_USER_LOGIN_INFO, ERROR_USER_LOGIN_INFO,
  REQUEST_GET_USER_LOGIN_INFO, SUCCESS_GET_USER_LOGIN_INFO, ERROR_GET_USER_LOGIN_INFO,
  REQUEST_GET_USER_LOGIN, SUCCESS_GET_USER_LOGIN, ERROR_GET_USER_LOGIN
} from '../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  user: {},
  isUserLoggedIn: null,
};

const x0paDuser = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_X0PA_DUSER:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case SUCCESS_X0PA_DUSER:
      let userLoggedIn = false;
      if(payload && !payload.error) {
        userLoggedIn = true;
      } else {
        userLoggedIn = false;
      }
      const { meta = {} } = payload;
      return {
        ...state,
        isUserLoggedIn: userLoggedIn,
        user: meta,
        loading: false,
        error: null,
      };
    case ERROR_X0PA_DUSER:
      return {
        ...state,
        loading: false,
        isUserLoggedIn: false,
        error: payload.error,
      };
    case SUCCESS_GTP_OPTIN_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          publicVisibility: action.payload.publicVisibility,
        },
      }
    case ERROR_GTP_OPTIN_UPDATE:
      return {
        ...state,
        publicVisibility: false
      }
    case REQUEST_CHANGE_USER_TZ:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case SUCCESS_CHANGE_USER_TZ:
      return {
        loading: false,
        error: null,
        ...state,
        user: {
          ...state.user,
          tzid: action.payload.tzid,
        },
      };
    case ERROR_CHANGE_USER_TZ:
      return {
        loading: false,
        error: true,
        ...state,
      };
    case REQUEST_USER_LOGIN_INFO:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case SUCCESS_USER_LOGIN_INFO:
      return {
        loading: false,
        error: null,
        ...state,
        user: {
          ...state.user,
          tzid: action.payload.tzid,
        },
      };
    case ERROR_USER_LOGIN_INFO:
      return {
        loading: false,
        error: true,
        ...state,
      };
    case REQUEST_GET_USER_LOGIN_INFO:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case SUCCESS_GET_USER_LOGIN_INFO:
      return {
        loading: false,
        error: null,
        ...state,
        user: {
          ...state.user,
          tzid: action.payload.tzid,
        },
      };
    case ERROR_GET_USER_LOGIN_INFO:
      return {
        loading: false,
        error: true,
        ...state,
      };  
    case REQUEST_GET_USER_LOGIN:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case SUCCESS_GET_USER_LOGIN:
      return {
        loading: false,
        error: null,
        ...state,
        user: {
          ...state.user,
          tzid: action.payload.tzid,
        },
      };
    case ERROR_GET_USER_LOGIN:
      return {
        loading: false,
        error: true,
        ...state,
      };  
    default:
      return state;
  }
};

export default x0paDuser;
