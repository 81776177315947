import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'carbon-components-react';

class ProfileSecurity extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    const {
      user: {
        roleId,
      } = {},
    } = this.props;
    return (
      <div className="xpa--profile-security">
        <div className="bx--tile mt-2">
          {
            (roleId !== 3) && (
              <Button type="submit" kind="primary" href="/reset-password">
                Change password
              </Button>)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSecurity);
