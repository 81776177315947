import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import moment from 'moment';
import {
  Pagination,
} from 'carbon-components-react';

const ApplicantOtherJobs = ({
  otherJobsApplied = [], push, totalCount, handleOtherJobsPaginationChange,
  activePage, t,
}) => {
  console.log('otherJobsApplied', otherJobsApplied);
  const columnWithFilter = [
    {
      title: `${t('jobId')}`,
      className: 'text-left',
      name: 'jobId',
      propName: 'jobId',
    },
    {
      title: `${t('jobTitle')}`,
      className: 'text-left',
      name: 'jobName',
      propName: 'jobname.nameName',
    },
    {
      title: `${t('jobPosted')}`,
      className: 'text-center ',
      name: 'postDate',
      propName: 'openDate',
    },
    {
      title: `${t('appliedOn')}`,
      className: 'text-center ',
      name: 'company name',
      propName: 'companyName',
    },
    {
      title: `${t('status')}`,
      className: 'text-center ',
      name: 'status',
      propName: 'status',
    },

  ];
  return (
    <Fragment>
      {
        otherJobsApplied && Array.isArray(otherJobsApplied)
          && otherJobsApplied.length > 0
          && (
            <GenericDataTable
              className="d-none d-md-block"
              otherActions={[]}
              customSorting={() => { }}
              headers={
                columnWithFilter.map((data, index) => (
                  {
                    key: `header_${index}`,
                    header: data.title,
                    className: data.className,
                    colpropName: data.propName,
                  }
                ))
              }
              rows={
                (otherJobsApplied).map((job) => {
                  const {
                    currentStageName, currentSubStageName, status, appDate,
                  } = job || {};
                  let currSubStatus = `${currentSubStageName}`;
                  if (!currentSubStageName) {
                    currSubStatus = `${t('notStarted')}`;
                  } else {
                    currSubStatus = `(${currentSubStageName})`;
                  }
                  return ({
                    isExpanded: false,
                    id: `row_${job.job.jobId}`,
                    header_0: job.job.jobId,
                    header_1: (
                      <a href="javascript:void(0)" onClick={() => push(`/app/campus/job/${job.job.jobId}`)}>
                        {(job && job.job && job.job.jobname && job.job.jobname.nameName) || '-'}
                      </a>
                    ),
                    header_2: (moment(job && job.job && job.job.openDate).format('MMM Do YYYY')),
                    header_3: ((job && moment(appDate).format('MMM Do YYYY')) || '-'),
                    header_4: ((job && status && (<div className="bx--modal-header__label bx--type-delta d-inline-block mr-2">{status}</div>)) || (job && (
                      <div className="bx--modal-header__label bx--type-delta d-inline-block mr-2">
                        {currentStageName}
                        <span className="text-dark">{currSubStatus}</span>
                      </div>
                    ))),
                  }
                  );
                })
              }
              selectable={false}
              searchable={false}
              onSearchInputChange={() => { }}
              expandable={false}
              batchActions={false}
            />
          )
      }
      {
        otherJobsApplied && Array.isArray(otherJobsApplied)
          && otherJobsApplied.length > 0
          && (
            <div className="mt-2">
              <Pagination
                onChange={handleOtherJobsPaginationChange}
                pageSizes={[10, 20, 30, 40, 50]}
                page={activePage}
                totalItems={totalCount}
              />
            </div>
          )
      }
      {
        (!otherJobsApplied || (otherJobsApplied && Array.isArray(otherJobsApplied) && otherJobsApplied.length <= 0))
          && (
            <div className="mt-2">
              <div className="text-center">
                <div className="empty-icon">
                  <i className="fa fa-flag fa-2x" />
                </div>
                <p className="empty-title h5">{t('noJobsMsg')}</p>
              </div>
            </div>
          )
      }
    </Fragment>
  );
};

export default (withTranslation()(ApplicantOtherJobs));
