import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import {
  getAnalyticsActivityReports,
} from 'actions/analytics';
import {
  Modal,
  Button,
} from 'carbon-components-react';
import {
  getAllFaculties,
} from 'actions/candidate/CandidateDetails';
import FacultyCoursesSelection from 'components/roboroy/FacultyCoursesSelection';
import {
  handleSelectAllFacultyCourses,
} from 'containers/common/FacultyCheckboxSelectionClearfns';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { TagCloud } from 'react-tagcloud';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import BarGraph from 'components/roboroy/BarGraph';
import './AnalyticsDetails.css';

class AnalyticsDetails extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'analytics', name: 'Analytics', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      skillsData: [],
      // citiesAggsDatasets: {},
      // countriesAggsDatasets: {},
      // internshipAggsDatasets: {},
      functionAggsDatasets: {},
      // industryAggsDatasets: {},
      skillsPrefsData: [],
      // jobFunctionPreferenceAggsDatasets: {},
      // cityPreferenceAggsDatasets: {},
      // industryPreferenceAggsDatasets: {},
      salaryRangeDatasets: {},
      companyAggsDatasets: {},
      applicationAggsDatasets: {},
      statusDatasets: {},
      isOpenFacultyCoursesModal: false,
      filterLength: 0,
    };
  }

  componentDidMount() {
    this.props.getAnalyticsActivityReports().then((res) => {
      if (res && !res.error) {
        const {
          user: {
            company: {
              companyId,
              ctypeId,
            } = {},
          } = {},
        } = this.props;
        if (ctypeId && Number(ctypeId) === 2) {
          const facultyData = {
            facultyActivePage: 1,
            facultyItemsPerPage: 1000,
            facultySearchKey: null,
            schoolId: companyId && Number(companyId),
          };
          this.props.getAllFaculties(facultyData).then((facs) => {
            if (facs && !facs.error) {
              this.handleAnalyticsData();
            }
          });
        } else {
          this.handleAnalyticsData();
        }
      }
    });
  }

  handleAnalyticsData = () => {
    const {
      activityReports,
    } = this.props;
    const {
      skillAggs,
      // citiesAggs,
      // countriesAggs,
      // internshipAggs,
      functionAggs,
      // industryAggs,
      skillPreferenceAggs,
      // jobFunctionPreferenceAggs,
      // cityPreferenceAggs,
      // industryPreferenceAggs,
      companyAggs,
      applicationAggs,
      salaryRange,
      status,
    } = activityReports || {};
    let skillsData = [];
    let companyAggsDatasets = [];
    let functionAggsDatasets = [];
    let skillsPrefsData = [];
    let applicationAggsDatasets = [];
    let salaryRangeDatasets = [];
    let statusDatasets = [];
    if (skillAggs && Array.isArray(skillAggs) && skillAggs.length > 0) {
      skillsData = skillAggs.map((res) => {
        const {
          count,
          skill_name,
        } = res;
        const data = {
          value: skill_name,
          count: count && Number(count),
        };
        return data;
      });
    }
    if (skillPreferenceAggs && Array.isArray(skillPreferenceAggs) && skillPreferenceAggs.length > 0) {
      skillsPrefsData = skillPreferenceAggs.map((res) => {
        const {
          count,
          skill_name,
        } = res;
        const data = {
          value: skill_name,
          count: count && Number(count),
        };
        return data;
      });
    }
    // if (citiesAggs && Array.isArray(citiesAggs) && citiesAggs.length > 0) {
    //   citiesAggsDatasets = this.handleCityAggsDonutGraph(citiesAggs);
    // }
    // if (cityPreferenceAggs && Array.isArray(cityPreferenceAggs) && cityPreferenceAggs.length > 0) {
    //   cityPreferenceAggsDatasets = this.handleCityAggsDonutGraph(cityPreferenceAggs);
    // }
    // if (countriesAggs && Array.isArray(countriesAggs) && countriesAggs.length > 0) {
    //   countriesAggsDatasets = this.handleCountyAggsDonutGraph(countriesAggs);
    // }
    // const internshipAggsLabels = internshipAggs && Object.keys(internshipAggs);
    // let internshipAggsCountData = [];
    // if (internshipAggsLabels && Array.isArray(internshipAggsLabels) && internshipAggsLabels.length > 0) {
    //   internshipAggsCountData = internshipAggsLabels.map(res => res && internshipAggs[res]);
    // }
    // internshipAggsDatasets = {
    //   labels: internshipAggsLabels,
    //   datasets: [{
    //     label: 'Internship data',
    //     data: internshipAggsCountData,
    //   }],
    // };
    const salaryRangeLabels = salaryRange && Object.keys(salaryRange);
    let salaryRangeCountData = [];
    if (salaryRangeLabels && Array.isArray(salaryRangeLabels) && salaryRangeLabels.length > 0) {
      salaryRangeCountData = salaryRangeLabels.map(res => res && (salaryRange[res] || 0));
    }
    salaryRangeDatasets = {
      labels: salaryRangeLabels,
      datasets: [{
        label: 'Salary Range',
        data: salaryRangeCountData,
      }],
    };
    const statusLabels = status && Object.keys(status);
    let statusCountData = [];
    if (statusLabels && Array.isArray(statusLabels) && statusLabels.length > 0) {
      statusCountData = statusLabels.map(res => res && status[res] && Array.isArray(status[res]) && status[res].length > 0 && status[res][0] && status[res][0].count);
    }
    statusDatasets = {
      labels: statusLabels,
      datasets: [{
        label: 'Status',
        data: statusCountData,
      }],
    };
    if (functionAggs && Array.isArray(functionAggs) && functionAggs.length > 0) {
      functionAggsDatasets = this.handleFuncAggsBarGraph(functionAggs);
    }

    if (companyAggs && Array.isArray(companyAggs) && companyAggs.length > 0) {
      companyAggsDatasets = this.handleCompanyAggsBarGraph(companyAggs);
    }

    if (applicationAggs && Array.isArray(applicationAggs) && applicationAggs.length > 0) {
      applicationAggsDatasets = this.handleApplAggsBarGraph(applicationAggs);
    }

    // if (jobFunctionPreferenceAggs && Array.isArray(jobFunctionPreferenceAggs) && jobFunctionPreferenceAggs.length > 0) {
    //   jobFunctionPreferenceAggsDatasets = this.handleFuncAggsBarGraph(jobFunctionPreferenceAggs);
    // }
    // if (industryAggs && Array.isArray(industryAggs) && industryAggs.length > 0) {
    //   industryAggsDatasets = this.handleIndustryAggsBarGraph(industryAggs);
    // }
    // if (industryPreferenceAggs && Array.isArray(industryPreferenceAggs) && industryPreferenceAggs.length > 0) {
    //   industryPreferenceAggsDatasets = this.handleIndustryAggsBarGraph(industryPreferenceAggs);
    // }
    this.setState({
      skillsData,
      // citiesAggsDatasets,
      // countriesAggsDatasets,
      // internshipAggsDatasets,
      functionAggsDatasets,
      // industryAggsDatasets,
      skillsPrefsData,
      companyAggsDatasets,
      applicationAggsDatasets,
      salaryRangeDatasets,
      statusDatasets,
      // jobFunctionPreferenceAggsDatasets,
      // cityPreferenceAggsDatasets,
      // industryPreferenceAggsDatasets,
    });
  }

  handleIndustryAggsBarGraph = (industryAggs) => {
    let industryAggsLabels = [];
    let industryAggsCountData = [];
    industryAggs.forEach((res, idx) => {
      const {
        industry_name,
        count,
      } = res || {};
      if (idx <= 4) {
        if (industryAggsLabels && Array.isArray(industryAggsLabels) && industryAggsLabels.length <= 0) {
          industryAggsLabels = [industry_name];
        } else {
          industryAggsLabels.push(industry_name);
        }
  
        if (industryAggsCountData && Array.isArray(industryAggsCountData) && industryAggsCountData.length <= 0) {
          industryAggsCountData = [count && Number(count)];
        } else {
          industryAggsCountData.push(count && Number(count));
        }
      }
    });
    const finalData = {
      labels: industryAggsLabels.reverse(),
      datasets: [{
        label: 'Functions Data',
        data: industryAggsCountData.reverse(),
      }],
    };
    return finalData;
  }

  handleCompanyAggsBarGraph = (companyAggs) => {
    let companyAggsLabels = [];
    let companyAggsCountData = [];
    companyAggs.forEach((res, idx) => {
      const {
        company_name,
        count,
      } = res || {};
      if (idx <= 9) {
        if (companyAggsLabels && Array.isArray(companyAggsLabels) && companyAggsLabels.length <= 0) {
          companyAggsLabels = [company_name];
        } else {
          companyAggsLabels.push(company_name);
        }
  
        if (companyAggsCountData && Array.isArray(companyAggsCountData) && companyAggsCountData.length <= 0) {
          companyAggsCountData = [count && Number(count)];
        } else {
          companyAggsCountData.push(count && Number(count));
        }
      }
    });
    const finalData = {
      labels: companyAggsLabels.reverse(),
      datasets: [{
        label: 'Company Data',
        data: companyAggsCountData.reverse(),
      }],
    };
    return finalData;
  }

  handleApplAggsBarGraph = (applicationAggs) => {
    let applAggsLabels = [];
    let applAggsCountData = [];
    applicationAggs.forEach((res, idx) => {
      const {
        name_name,
        count,
      } = res || {};
      if (idx <= 9) {
        if (applAggsLabels && Array.isArray(applAggsLabels) && applAggsLabels.length <= 0) {
          applAggsLabels = [name_name];
        } else {
          applAggsLabels.push(name_name);
        }
  
        if (applAggsCountData && Array.isArray(applAggsCountData) && applAggsCountData.length <= 0) {
          applAggsCountData = [count && Number(count)];
        } else {
          applAggsCountData.push(count && Number(count));
        }
      }
    });
    const finalData = {
      labels: applAggsLabels.reverse(),
      datasets: [{
        label: 'Job Popular Data',
        data: applAggsCountData.reverse(),
      }],
    };
    return finalData;
  }

  handleFuncAggsBarGraph = (functionAggs) => {
    let functionAggsLabels = [];
    let functionAggsCountData = [];
    functionAggs.forEach((res, idx) => {
      const {
        function_name,
        count,
      } = res || {};
      if (idx <= 9) {
        if (functionAggsLabels && Array.isArray(functionAggsLabels) && functionAggsLabels.length <= 0) {
          functionAggsLabels = [function_name];
        } else {
          functionAggsLabels.push(function_name);
        }
  
        if (functionAggsCountData && Array.isArray(functionAggsCountData) && functionAggsCountData.length <= 0) {
          functionAggsCountData = [count && Number(count)];
        } else {
          functionAggsCountData.push(count && Number(count));
        }
      }
    });
    const finalData = {
      labels: functionAggsLabels.reverse(),
      datasets: [{
        label: 'Functions Data',
        data: functionAggsCountData.reverse(),
      }],
    };
    return finalData;
  }

  handleCountyAggsDonutGraph = (countriesAggs) => {
    let countryAggslabels = [];
    let countryAggsCountdata = [];
    countriesAggs.forEach((res) => {
      const {
        country_full,
        count,
      } = res || {};
      if (countryAggslabels && Array.isArray(countryAggslabels) && countryAggslabels.length <= 0) {
        countryAggslabels = [country_full];
      } else {
        countryAggslabels.push(country_full);
      }

      if (countryAggsCountdata && Array.isArray(countryAggsCountdata) && countryAggsCountdata.length <= 0) {
        countryAggsCountdata = [count && Number(count)];
      } else {
        countryAggsCountdata.push(count && Number(count));
      }
    });
    const finalData = {
      labels: countryAggslabels,
      datasets: [{
        label: 'Countries Data',
        data: countryAggsCountdata,
      }],
    };
    return finalData;
  }

  handleCityAggsDonutGraph = (citiesAggs) => {
    let cityAggsLabels = [];
    let cityAggsCountData = [];
    citiesAggs.forEach((res) => {
      const {
        city_name,
        count,
      } = res || {};
      if (cityAggsLabels && Array.isArray(cityAggsLabels) && cityAggsLabels.length <= 0) {
        cityAggsLabels = [city_name];
      } else {
        cityAggsLabels.push(city_name);
      }

      if (cityAggsCountData && Array.isArray(cityAggsCountData) && cityAggsCountData.length <= 0) {
        cityAggsCountData = [count && Number(count)];
      } else {
        cityAggsCountData.push(count && Number(count));
      }
    });
    const finalData = {
      labels: cityAggsLabels,
      datasets: [{
        label: 'Cities Data',
        data: cityAggsCountData,
      }],
    };
    return finalData;
  }

  activeFacultyCoursesModal = () => {
    this.setState({
      isOpenFacultyCoursesModal: true,
    });
  }

  dismissFacultyCoursesModal = () => {
    this.setState({
      isOpenFacultyCoursesModal: false,
    });
  }

  handleChangeCheckboxValues = (e, courseId, facultyId) => {
    let {
      coursemap,
    } = this.state;
    let selectedCourses = [];
    if (e === true) {
      if (!coursemap || (coursemap && Array.isArray(coursemap) && coursemap.length <= 0)) {
        coursemap = [{ facultyId, courses: [courseId] }];
        selectedCourses = [courseId];
      } else if (coursemap && Array.isArray(coursemap) && coursemap.length > 0) {
        let filteredData = coursemap.find(res => res.facultyId === facultyId);
        if (filteredData && filteredData.courses && Array.isArray(filteredData.courses) && filteredData.courses.length > 0) {
          filteredData.courses.push(courseId);
          selectedCourses = filteredData.courses;
        } else {
          filteredData = { facultyId, courses: [courseId] };
          selectedCourses = [courseId];
          coursemap.push(filteredData);
        }
      }
    }
    this.setState({
      coursemap,
      [`courses-${facultyId}`]: selectedCourses,
    }, () => {
      if (e === false) {
        const {
          coursemap: stateCourses,
        } = this.state;
        let remainingCourses = [];
        if (stateCourses && Array.isArray(stateCourses) && stateCourses.length > 0) {
          const filteredList = stateCourses.filter(res => res.facultyId !== facultyId);
          let filteredArr = [];
          if (filteredList && Array.isArray(filteredList) && filteredList.length <= 0) {
            filteredArr = stateCourses.filter(res => res.facultyId === facultyId);
            if (filteredArr && Array.isArray(filteredArr) && filteredArr[0] && filteredArr[0].courses && Array.isArray(filteredArr[0].courses) && filteredArr[0].courses.length > 1) {
              const filtCour = filteredArr[0].courses.filter(res => res !== courseId);
              filteredArr[0].courses = filtCour;
              remainingCourses = filtCour;
            } else {
              filteredArr = stateCourses.filter(res => res.facultyId !== facultyId);
              remainingCourses = [];
            }
            this.setState({
              coursemap: filteredArr,
              [`courses-${facultyId}`]: remainingCourses,
            });
          } else {
            filteredArr = stateCourses.find(res => res.facultyId === facultyId);
            if (filteredArr && filteredArr.courses && Array.isArray(filteredArr.courses) && filteredArr.courses.length > 1) {
              const filtCour = filteredArr.courses.filter(res => res !== courseId);
              filteredArr.courses = filtCour;
              remainingCourses = filtCour;
              filteredList.push(filteredArr);
            }
            this.setState({
              coursemap: filteredList,
              [`courses-${facultyId}`]: remainingCourses,
            });
          }
        }
      }
    });
  }

  handleSelectAllCheckboxes = (facultyId, courses, e) => {
    const {
      user: {
        company: {
          ctypeId,
        } = {},
      } = {},
    } = this.props;
    if (e === true) {
      const {
        coursemap,
      } = this.state;
      const data = handleSelectAllFacultyCourses({ courses, facultyId, ctypeId, coursemap });
      const {
        filteredList,
        selectedCourses,
      } = data;
      this.setState({
        coursemap: filteredList,
        [`courses-${facultyId}`]: selectedCourses,
        [`faculty-${facultyId}`]: facultyId,
      });
    } else {
      this.handleClearAllCheckboxes(facultyId);
    }
  }

  handleClearAllCheckboxes = (facultyId) => {
    const {
      coursemap,
    } = this.state;
    let filteredData = [];
    if (coursemap && Array.isArray(coursemap) && coursemap.length > 1) {
      filteredData = coursemap.filter(res => res.facultyId !== facultyId);
    } else if (coursemap && Array.isArray(coursemap) && coursemap.length === 1) {
      filteredData = [];
    }
    this.setState({
      [`courses-${facultyId}`]: [],
      coursemap: filteredData,
      [`faculty-${facultyId}`]: null,
    });
  }

  handleFacultyCoursesAnalytics = () => {
    const {
      coursemap,
    } = this.state;
    let facultyIds = [];
    let courseIds = [];
    if (coursemap && Array.isArray(coursemap) && coursemap.length > 0) {
      coursemap.forEach((res) => {
        const {
          facultyId,
          courses,
        } = res || {};
        if (facultyIds && Array.isArray(facultyIds) && facultyIds.length > 0) {
          facultyIds.push(facultyId);
        } else {
          facultyIds = [facultyId];
        }
        if (courseIds && Array.isArray(courseIds) && courseIds.length > 0) {
          courseIds.push(...courses);
        } else {
          courseIds = courses;
        }
      });
    }
    const data = {
      facultyIds,
      courseIds,
    };
    const facultyLength = facultyIds && Array.isArray(facultyIds) && facultyIds.length;
    const courseLength = courseIds && Array.isArray(courseIds) && courseIds.length;
    this.props.getAnalyticsActivityReports(data).then((res) => {
      if (res && !res.error) {
        this.handleAnalyticsData();
        this.dismissFacultyCoursesModal();
        this.setState({
          filterLength: facultyLength + courseLength,
        });
      }
    });
  }

  render() {
    const {
      t,
      activityReports,
      loading,
      user: {
        isAdmin,
        isSuperAdmin,
        facultyId: userFacultyId,
        company: {
          ctypeId,
        } = {},
      } = {},
      facultyData,
    } = this.props;
    const {
      skillsData,
      // citiesAggsDatasets,
      // countriesAggsDatasets,
      // internshipAggsDatasets,
      functionAggsDatasets,
      companyAggsDatasets,
      // industryAggsDatasets,
      skillsPrefsData,
      applicationAggsDatasets,
      salaryRangeDatasets,
      statusDatasets,
      // jobFunctionPreferenceAggsDatasets,
      // cityPreferenceAggsDatasets,
      // industryPreferenceAggsDatasets,
      isOpenFacultyCoursesModal,
      filterLength,
    } = this.state;
    const {
      totalJobs,
      totalApplns,
    } = activityReports || {};
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <Modal
          open={isOpenFacultyCoursesModal}
          shouldSubmitOnEnter
          modalHeading="Filter by Faculty & Courses"
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissFacultyCoursesModal}
          onRequestSubmit={this.handleFacultyCoursesAnalytics}
        >
          <div>
            <FacultyCoursesSelection
              facultyData={facultyData}
              isSuperAdmin={isSuperAdmin}
              userFacultyId={userFacultyId}
              ctypeId={ctypeId}
              isAdmin={isAdmin}
              state={this.state}
              hideCoursesDetails
              handleChangeCheckboxValues={this.handleChangeCheckboxValues}
              handleSelectAllCheckboxes={this.handleSelectAllCheckboxes}
              handleClearAllCheckboxes={this.handleClearAllCheckboxes}
            />
          </div>
        </Modal>
        {
          ctypeId && Number(ctypeId) === 2 && (
            <div className="mb-2 text-right">
              <Button
                kind="primary"
                size="small"
                onClick={this.activeFacultyCoursesModal}
              >
                <span>{t('filter')}</span>
                {
                  filterLength && filterLength !== 0 ? (
                    <strong className="badge p-1 ml-2" style={{ color: 'black', backgroundColor: 'white' }}>{filterLength}</strong>
                  ) : null
                }
              </Button>
            </div>
          )
        }
        <div className="h5">
          {t('jobs')} {t('analytics')}
        </div>
        <div className="bx--row justify-content-center">
          <div className="text-center box-border created-jobs-color bx--col-lg-1 bx--col-md-2">
            <div className="h4 text-center">
              {totalJobs || 0}
            </div>
            <div className="h6 text-center text-uppercase font-weight-light mb-2 text-dark">
              {t('totalJobs')}
            </div>
          </div>
        </div>
        <div className="h6 text-capitalize mb-2">
          {t('topSkills')}
        </div>
        <div>
          {
            skillsData && Array.isArray(skillsData)
              && skillsData.length > 0
              ? (
                <TagCloud
                  minSize={15}
                  maxSize={40}
                  tags={skillsData}
                  onClick={() => {}}
                />
              ) : (
                <div className="ml-2 text-dark font-weight-bold">{t('noSkills')}</div>
              )
          }
        </div>
        <hr />
        <div className="bx--row">
          <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-4 bx--col-xs-4">
            {
              applicationAggsDatasets && applicationAggsDatasets.labels
                ? (
                  <BarGraph data={applicationAggsDatasets} isHorizontal title="Jobs by Applications received" />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      Jobs by Applications received
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
          <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-4 bx--col-xs-4">
            {
              salaryRangeDatasets && salaryRangeDatasets.labels
                ? (
                  <BarGraph data={salaryRangeDatasets} isHorizontal title="Salary Range" />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      Salary Range
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
        </div>
        <hr />
        <div className="bx--row">
          <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-4 bx--col-xs-4">
            {
              companyAggsDatasets && companyAggsDatasets.labels
                ? (
                  <BarGraph data={companyAggsDatasets} isHorizontal title={t('distributionByCompany')} />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      {t('distributionByCompany')}
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
          <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-4 bx--col-xs-4">
            {
              functionAggsDatasets && functionAggsDatasets.labels
                ? (
                  <BarGraph data={functionAggsDatasets} isHorizontal title={t('distributionByFunction')} />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      {t('distributionByFunction')}
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
        </div>
        <hr />
        {/* <div className="bx--row">
          <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
            {
              citiesAggsDatasets && citiesAggsDatasets.labels
                ? (
                  <DonutGraph data={citiesAggsDatasets} title={t('distributionByCity')} donutLabel={t('cities')} />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      {t('distributionByCity')}
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
          <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
            {
            countriesAggsDatasets && countriesAggsDatasets.labels
              ? (
                <DonutGraph data={countriesAggsDatasets} donutLabel={t('countries')} title={t('distributionByCountry')} />
              ) : (
                <div>
                  <div className="h6 text-capitalize mb-2">
                    {t('distributionByCountry')}
                  </div>
                  <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                </div>
              )
          }
          </div>
        </div>
        <hr /> */}
        {/* <div className="bx--row">
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-xs-12 bx--col-sm-12">
            {
              internshipAggsDatasets && internshipAggsDatasets.labels
                ? (
                  <BarGraph data={internshipAggsDatasets} isHorizontal title={t('distributionByInternship')} />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      {t('distributionByInternship')}
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-xs-12 bx--col-sm-12">

          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-xs-12 bx--col-sm-12">
            {
              industryAggsDatasets && industryAggsDatasets.labels
                ? (
                  <BarGraph data={industryAggsDatasets} isHorizontal title={t('distributionByIndustry')} />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      {t('distributionByIndustry')}
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
        </div>
        <hr /> */}
        <div className="h5">
          {t('applications')} {t('analytics')}
        </div>
        <div className="bx--row justify-content-center">
          <div className="text-center box-border created-jobs-color bx--col-lg-2 bx--col-md-3">
            <div className="h4 text-center">
              {totalApplns || 0}
            </div>
            <div className="h6 text-center font-weight-light text-uppercase mb-2 text-dark">
              {t('totalApplications')}
            </div>
          </div>
        </div>
        <div className="h6 text-capitalize mb-2 mt-2">
          {t('topSkills')}
        </div>
        <div>
          {
            skillsPrefsData && Array.isArray(skillsPrefsData)
              && skillsPrefsData.length > 0
              ? (
                <TagCloud
                  minSize={15}
                  maxSize={40}
                  tags={skillsPrefsData}
                  onClick={() => {}}
                />
              ) : (
                <div className="ml-2 text-dark font-weight-bold">{t('noSkills')}</div>
              )
          }
        </div>
        <hr />
        <div>
          {
              statusDatasets && statusDatasets.labels
                ? (
                  <BarGraph data={statusDatasets} isHorizontal={false} title="Applications by status" />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      Applications by status
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
        </div>
        {/* <div className="bx--row mb-5">
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-xs-12 bx--col-sm-12">
            {
              jobFunctionPreferenceAggsDatasets && jobFunctionPreferenceAggsDatasets.labels
                ? (
                  <BarGraph data={jobFunctionPreferenceAggsDatasets} isHorizontal title={t('preferredJobFunc')} />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      {t('preferredJobFunc')}
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-xs-12 bx--col-sm-12">
            {
              cityPreferenceAggsDatasets && cityPreferenceAggsDatasets.labels
                ? (
                  <DonutGraph donutLabel={t('cities')} data={cityPreferenceAggsDatasets} title={t('preferredCitiesAnalytics')} />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      {t('preferredCitiesAnalytics')}
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-xs-12 bx--col-sm-12">
            {
              industryPreferenceAggsDatasets && industryPreferenceAggsDatasets.labels
                ? (
                  <BarGraph data={industryPreferenceAggsDatasets} isHorizontal title={t('prefIndustries')} />
                ) : (
                  <div>
                    <div className="h6 text-capitalize mb-2">
                      {t('prefIndustries')}
                    </div>
                    <div className="text-dark font-weight-bold">{t('noAnalyticsMsg')}</div>
                  </div>
                )
            }
          </div>
        </div> */}
        <JDProcessOverlay show={loading} message="processing..." />
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  activityReports: state.Analytics.activityReports,
  loading: state.Analytics.loading,
  facultyData: state.candidateDetails.facultyData,
});

const mapDispatchToProps = {
  getAnalyticsActivityReports,
  getAllFaculties,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AnalyticsDetails));
