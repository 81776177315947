import {
  REQUEST_JOB_DATA, SUCCESS_JOB_DATA, ERROR_JOB_DATA,
  REQUEST_CLOSE_JOB, SUCCESS_CLOSE_JOB, ERROR_CLOSE_JOB,
  REQUEST_CLONE_JOB, SUCCESS_CLONE_JOB, ERROR_CLONE_JOB,
  REQUEST_ASSOCIATE_LINKEDIN_JOB, SUCCESS_ASSOCIATE_LINKEDIN_JOB,
  ERROR_ASSOCIATE_LINKEDIN_JOB, REQUEST_STATUS_JOB, SUCCESS_STATUS_JOB,
  ERROR_STATUS_JOB, REQUEST_JOB_COLLABORATORS, SUCCESS_JOB_COLLABORATORS,
  ERROR_JOB_COLLABORATORS,
  REQUEST_AUTHORIZED_USERS, SUCCESS_AUTHORIZED_USERS, ERROR_AUTHORIZED_USERS,
  SUCCESS_ADD_PROFILETOJOB,REQUEST_ADD_PROFILETOJOB,
  REQUEST_UPDATE_JOB_WORKFLOW_SATGE, SUCCESS_UPDATE_JOB_WORKFLOW_SATGE, ERROR_UPDATE_JOB_WORKFLOW_SATGE,
  REQUEST_JOB_BATCH_ACTIONS, ERROR_JOB_BATCH_ACTIONS, SUCCESS_JOB_BATCH_ACTIONS, REQUEST_COMPANY_AUTHORIZED_USERS,
  SUCCESS_COMPANY_AUTHORIZED_USERS, ERROR_COMPANY_AUTHORIZED_USERS, REQUEST_JOB_REJECT,
  SUCCESS_JOB_REJECT, ERROR_JOB_REJECT, REQUEST_APPROVE_VACANCIES, SUCCESS_APPROVE_VACANCIES, ERROR_APPROVE_VACANCIES,
  REQUEST_JOB_SHORTLIST_ACTIONS, ERROR_JOB_SHORTLIST_ACTIONS, SUCCESS_JOB_SHORTLIST_ACTIONS
} from '../../actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  jobs: [],
  count: 0,
  recruiters: {},
  company: {},
  activejob: null,
  jobCollaborators: {
    users: [],
    roles: [],
  },
  authorizedUsers: {
    users: [],
    roles: [],
  },
  companyAuthorizedUsers: {
    users: [],
    roles: [],
  }
};

const jobs = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_JOB_DATA:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_JOB_DATA:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_JOB_DATA:
      return {
        ...state,
        recruiters: payload && payload.recruiter,
        company: payload && payload.company,
        loading: false,
        error: null,
        ...payload,
      };
    case REQUEST_CLONE_JOB:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_CLONE_JOB:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_CLONE_JOB:
      return {
        loading: false,
        error: null,
      };
    case REQUEST_CLOSE_JOB:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_CLOSE_JOB:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_CLOSE_JOB:
      return {
        loading: false,
        error: null,
      };
    case REQUEST_ASSOCIATE_LINKEDIN_JOB:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ASSOCIATE_LINKEDIN_JOB:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ASSOCIATE_LINKEDIN_JOB:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_STATUS_JOB:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_STATUS_JOB:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_STATUS_JOB:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_JOB_COLLABORATORS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_JOB_COLLABORATORS:
      return {
        ...state,
        jobCollaborators: payload,
        loading: false,
        error: null,
      };
    case ERROR_JOB_COLLABORATORS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_AUTHORIZED_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_AUTHORIZED_USERS:
      return {
        ...state,
        authorizedUsers: payload,
        loading: false,
        error: null,
      };
    case ERROR_AUTHORIZED_USERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case  REQUEST_ADD_PROFILETOJOB:
      return {
        ...state,
        loading:true,
        error:null,
      }
    case  SUCCESS_ADD_PROFILETOJOB:
      return {
        ...state,
        loading:false,
        ...payload,
        error:null,
      }
    case REQUEST_UPDATE_JOB_WORKFLOW_SATGE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_JOB_WORKFLOW_SATGE: {
      const jobsArr = state.jobs;
      const jobWorkflowStages = (jobsArr && Array.isArray(jobsArr)
        && (jobsArr.length === 1) && jobsArr[0] && jobsArr[0].jobWorkflowStages);
      let newJobs = jobsArr.slice();
      let newJobWorkflowStages = jobWorkflowStages;
      if (jobWorkflowStages && Array.isArray(jobWorkflowStages)) {
        newJobWorkflowStages = jobWorkflowStages.map((jws) => {
          const jwStage = { ...jws };
          if (jwStage.jobStageId == payload.jobStageId) {
            if (payload && payload.scheduleEventData) {
              jwStage.scheduleEventData = payload.scheduleEventData;
            }
            if (payload && payload.writtenAssessmentId) {
              jwStage.writtenAssessmentId = payload.writtenAssessmentId;
            }
          }
          return jwStage;
        });
        newJobs[0].jobWorkflowStages = newJobWorkflowStages;
      }
      return {
        ...state,
        jobs: newJobs,
        loading: false,
        error: null,
      };
    }
    case ERROR_UPDATE_JOB_WORKFLOW_SATGE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_JOB_BATCH_ACTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_JOB_BATCH_ACTIONS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_JOB_BATCH_ACTIONS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_COMPANY_AUTHORIZED_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_COMPANY_AUTHORIZED_USERS:
      return {
        ...state,
        companyAuthorizedUsers: payload,
        loading: false,
        error: null,
      };
    case ERROR_COMPANY_AUTHORIZED_USERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_JOB_REJECT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_JOB_REJECT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_JOB_REJECT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_APPROVE_VACANCIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_APPROVE_VACANCIES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_APPROVE_VACANCIES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      }; 
    case REQUEST_JOB_SHORTLIST_ACTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_JOB_SHORTLIST_ACTIONS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_JOB_SHORTLIST_ACTIONS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };   
    default:
      return state;
  }
};

export default jobs;
