import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import {
  TextInput, Checkbox, Modal,
  Select, SelectItem, DatePickerInput,
  DatePicker, Accordion, AccordionItem,
  Button, InlineNotification, TextArea,
  RadioButtonGroup,
  RadioButton,
  FileUploaderDropContainer,
} from 'carbon-components-react';
import htmlToDraft from 'html-to-draftjs';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import FacultyCoursesSelection from 'components/roboroy/FacultyCoursesSelection';
import {
  getJobInfo,
} from 'actions/talentSearch/jobList';
import OfficeDetails from 'components/roboroy/common/OfficeDetails';
import {
  getAllFaculties,
} from 'actions/candidate/CandidateDetails';
import {
  handleSelectAllFacultyCourses,
} from 'containers/common/FacultyCheckboxSelectionClearfns';
import {
  getCompanyUsersList,
} from 'actions/InstitutionView';
import {
  officeDetailsValidation,
  getLocationsDetails,
} from 'containers/common/campusLogin/validationFunctions';
import { Editor } from 'react-draft-wysiwyg';
import { count } from '@wordpress/wordcount';
import JobDocuments from 'containers/roboroy/jobdocuments';
import PercentageSlider from 'components/common/PercentageSlider';
import {
  xSearchCompanies,
} from 'actions/xpa';
import {
  getLocations,
} from 'actions/campus/registrationRequest';
import {
  fetchAllCompaniesList,
} from 'actions/BrowseCompanies';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import {
  EditorState, convertToRaw, ContentState, Modifier, convertFromHTML,
} from 'draft-js';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
// import {
//   getLocationsDetails,
// } from 'containers/common/campusLogin/validationFunctions';
import { getAuthorizedCompanies } from 'actions/admin/CompanyAndOffice';
import {
  getCitiesList,
} from 'actions/CitiesDetails';
import {
  saveJob,
  editJob,
  fetchDropdownData,
  addOffice,
  fetchAllOffices,
  getWorkdaysList,
  parseJdJobReg
} from 'actions/talentSearch/newJob';
import './NewJob.css';
import SampleCompany from 'assets/files/Sample_Company_Training_Plan.docx';

import { uploadDocuments } from 'actions/document';
import { cloneDeep } from 'lodash';

import {
  getBatchDetails,
} from 'actions/BatchManagementView';
import { getCountries } from 'actions/campus/registrationRequest';


const BatchDetailsInfo = ({ batch }) => {
  const { batchName, batchDetails } = batch;
  return (
    <div style={{fontSize:"large", fontWeight:"600"}}>
      {batchName}
      {/* <h6>{batchName}</h6>
      {batchDetails.map((b) => {
        const { batchStartDate, batchEndDate } = b;
        return (<div className="">
          <div className="text-capitalize">{b.facultyName}</div>
          <div className="mb-2"><span className="xpa-text-size-sm">Start Date:{moment(batchStartDate).format('DD/MM/YYYY')}</span> - <span className="xpa-text-size-sm">End Date:{moment(batchEndDate).format('DD/MM/YYYY')}</span></div>
        </div>)
      }
      )} */}
    </div>);
}

class NewJobReg extends Component {
  paths = [
    { id: 'campus', name: 'Hiring', href: '/app/campus' },
    { id: 'open', name: 'Jobs', href: '/app/campus/jobs' },
    { id: 'create', name: 'Create a job', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      jobDesc: '',
      countriesArr: [],
      termsArr: [],
      functionsArr: [],
      industriesArr: [],
      skillsmusthave: null,
      cityVal: null,
      countryVal: null,
      currencyVal: { label: 'SGD', value: 125 },
      institutionVal: { label: 'NGEE ANN POLYTECHNIC', value: 1 },
      industryVal: { label: 'Education', value: 5 },
      citiesArr: [],
      salaryType: '',
      selectedType: [],
      internalCode: '',
      isPrivate: false,
      currenciesArr: [],
      closeDate: moment().add(1, 'years'),
      hideForm: false,
      showLoader: false,
      coursemap: [],
      termVal: { label: 'Internship', value: 4 },
      preferenceBackoffice: 50,
      preferenceTaskOriented: 50,
      officesArr: [],
      isOpenAddOfcModal: false,
      officesval: {},
      workdaysArr: [],
      jobDocuments: [],
      jobJDArr: [],
      uploadingDocs: false,
      managedCompsListArr: [],
      userListArr: [],
      showCustomShiftErr: false,
      showAddCommentErr: false,
      showFacultiesToSelect: false
    };
    this.detailsNotifRef = React.createRef();
    // this.detailsNotifRef = React.createRef();
  }

  componentDidMount() {
    this.props.fetchDropdownData().then((res) => {
      if (res && !res.error) {
        this.props.getWorkdaysList();
        this.props.getCountries();
        this.props.xSearchCompanies('', 2, 0).then((comps) => {
          if (comps && !comps.error) {
            const institutionArr = comps.map(one => ({ label: one.companyName && one.companyName.toString() && one.companyName.toUpperCase(), value: one.companyId }));
            this.setState({
              institutionArr,
            }, () => {
              const dataVals = {
                activePage: 1,
                itemsPerPage: 1000,
                keyword: '',
              };
              const {
                institutionVal: {
                  value: instId,
                } = {},
              } = this.state;
              if (instId) {
                const facultyData = {
                  facultyActivePage: 1,
                  facultyItemsPerPage: 1000,
                  facultySearchKey: null,
                  schoolId: instId,
                };
                this.props.getBatchDetails().then((res) => {
                  const { list: batchList } = res;
                  const activeBatches = batchList.filter((batch) => batch.isActiveBatch === true);
                  if (activeBatches !== null && activeBatches !== undefined && Array.isArray(activeBatches) && activeBatches.length > 0) {
                    this.setState({
                      ...this.state,
                      activeBatches: activeBatches,
                    })
                  } else {
                    this.setState({
                      ...this.state,
                      activeBatches: []
                    })
                  }

                });
                this.props.getAllFaculties(facultyData).then((facs) => {
                  if (facs && !facs.error) {
                    this.props.getCitiesList(dataVals).then((cities) => {
                      if (cities && !cities.error) {
                        const managedCompData = {
                          activePage: 1,
                          itemsPerPage: 1000,
                          Companymapstatus: ['Accepted'],
                        };
                        this.props.fetchAllCompaniesList(managedCompData).then((mangdComps) => {
                          if (mangdComps && !mangdComps.error) {
                            this.handleFetchDropdownData();
                          }
                        });
                      }
                    }).catch(e => console.log('Error:::::', e));
                  }
                });
              }
            });
          }
        }).catch(e => console.log('Error:::::', e));
      }
    }).catch(e => console.log('Error:::::', e));
  }

  handleFetchDropdownData = () => {
    const {
      dropdowns,
      cities,
      workDaysList,
      companies,
      facultyData,
    } = this.props;
    const {
      currencies,
      terms,
      jobFunctions,
      industries,
    } = dropdowns || {};
    let countriesArr = [];
    let currenciesArr = [];
    let termsArr = [];
    let functionsArr = [];
    let industriesArr = [];
    let citiesArr = [];
    let workdaysArr = [];
    let jobDocumentsArr = [];
    let managedCompsListArr = [];
    if (cities && Array.isArray(cities) && cities.length > 0) {
      countriesArr = cities.map((countr) => {
        const {
          country: {
            countryFull,
            countryId,
          } = {},
        } = countr;
        const data = {
          label: countryFull,
          value: countryId,
        };
        return data;
      });
    }

    if (companies && Array.isArray(companies) && companies.length > 0) {
      managedCompsListArr = companies.map((res) => {
        const {
          _source: {
            companyId,
            companyName,
            displayCompanyName,
            office: {
              officeId,
              address: {
                addressLine1,
                addressLine2,
                zipcode,
              } = {},
            } = {},
          } = {},
        } = res || {};
        const data = {
          label: displayCompanyName, /*companyName*/
          value: companyId,
          officeId,
          officeName: `${addressLine1} ${addressLine2} ${zipcode}`,
        };
        return data;
      });
    }

    if (workDaysList && Array.isArray(workDaysList) && workDaysList.length > 0) {
      workdaysArr = workDaysList.map((workdays) => {
        const {
          workdayId,
          workdayType,
        } = workdays;
        const data = {
          label: workdayType,
          value: workdayId,
        };
        return data;
      });
    }

    if (cities && Array.isArray(cities) && cities.length > 0) {
      citiesArr = cities.map((countr) => {
        const {
          cityId,
          cityName,
          countryId,
        } = countr;
        const data = {
          label: cityName,
          value: cityId,
          countryId,
        };
        return data;
      });
    }

    if (currencies && Array.isArray(currencies) && currencies.length > 0) {
      currenciesArr = currencies.map((curr) => {
        const {
          currencyId,
          currencyFull,
          countryId,
        } = curr;
        const data = {
          label: currencyFull,
          value: currencyId,
          countryId,
        };
        return data;
      });
    }

    if (industries && Array.isArray(industries) && industries.length > 0) {
      industriesArr = industries.map((curr) => {
        const {
          industryId,
          industryName,
        } = curr;
        const data = {
          label: industryName,
          value: industryId,
        };
        return data;
      });
    }

    if (terms && Array.isArray(terms) && terms.length > 0) {
      termsArr = terms.map((curr) => {
        const {
          termId,
          termName,
        } = curr;
        const data = {
          label: termName,
          value: termId,
        };
        return data;
      });
    }

    if (jobFunctions && Array.isArray(jobFunctions) && jobFunctions.length > 0) {
      functionsArr = jobFunctions.map((curr) => {
        const {
          functionId,
          functionName,
        } = curr;
        const data = {
          label: functionName,
          value: functionId,
        };
        return data;
      });
    }
    this.setState({
      countriesArr,
      currenciesArr,
      termsArr,
      functionsArr,
      citiesArr,
      industriesArr,
      workdaysArr,
      managedCompsListArr,
    }, () => {
      const {
        match: {
          params: {
            jobId,
          } = {},
        } = {},
        user: {
          companyId: userCompId,
          company: {
            ctypeId,
          } = {},
        } = {},
        jobs,
      } = this.props;
      if (jobId) {
        this.props.getJobInfo({ jobId }).then((res) => {
          if (res && !res.error) {
            if (ctypeId && Number(ctypeId) === 2) {
              const {
                jobs: propJobs,
              } = this.props;
              const {
                companyId,
              } = (propJobs && propJobs[0]) || {};
              this.props.fetchAllOffices(companyId).then((ofc) => {
                if (ofc && !ofc.error) {
                  this.handleofficedropDowns();
                }
              }).catch(e => console.log('Error:::::', e));
            } else if (ctypeId && Number(ctypeId) === 1) {
              this.props.fetchAllOffices(userCompId).then((ofc) => {
                if (ofc && !ofc.error) {
                  this.handleofficedropDowns();
                }
              }).catch(e => console.log('Error:::::', e));
            }
            this.handleSaveJobsData();
          }
        });
      } else if (ctypeId && Number(ctypeId) === 1) {
        this.props.fetchAllOffices(userCompId).then((res) => {
          if (res && !res.error) {
            this.handleofficedropDowns();
            const facultyId = facultyData && Array.isArray(facultyData) && facultyData.length > 0 && facultyData[0] && facultyData[0].facultyId;
            // this.handleSelectAllCheckboxes(facultyId, [], true);
          }
          this.setState({ defaultSelectedValue: "" })
        }).catch(e => console.log('Error:::::', e));
      } else if (ctypeId && Number(ctypeId) === 2) {
        const facultyId = facultyData && Array.isArray(facultyData) && facultyData.length > 0 && facultyData[0] && facultyData[0].facultyId;
        // this.handleSelectAllCheckboxes(facultyId, [], true);
        this.setState({ defaultSelectedValue: "" })
      }
    });
  }

  handleSaveJobsData = () => {
    const {
      jobs,
    } = this.props;
    const {
      jobDesc,
      jobReq,
      closeDate,
      internalCode,
      minSalary,
      maxSalary,
      isPrivate,
      companyId,
      locationId,
      recruiterId,
      user: {
        firstname,
        lastname,
      } = {},
      currencyId,
      countryId,
      customShift,
      customIndustry,
      functionId,
      termId,
      teamId,
      industryId,
      institutionId,
      jobcoursemap,
      additionalComments,
      company: {
        // companyName: managedCompanyName,
        displayCompanyName: managedCompanyName,
      } = {},
      // office: {
      //   officeId,
      //   address: {
      //     addressLine1,
      //     addressLine2,
      //     location: {
      //       locationName,
      //     } = {},
      //   } = {},
      // } = {},
      workdays: {
        workdayId,
        workdayType,
      } = {},
      cities: {
        cityName,
        cityId,
      } = {},
      industry: {
        industryName,
      } = {},
      currency: {
        currencyFull,
      } = {},
      jobname: {
        nameName,
      } = {},
      country: {
        countryFull,
      } = {},
      skills,
      vacancy,
      jobfunction: {
        functionName,
      } = {},
      institution: {
        companyName,
      } = {},
      term: {
        termName,
      } = {},
      // jobPreferences: {
      //   preferenceBackoffice,
      //   preferenceTaskOriented,
      // } = {},
      jobdocuments,
      jobjd,
      jobfacultymap,
      jobBatch,
      batchId
    } = (jobs && jobs[0]) || {};
    const { office } = (jobs && jobs[0]) || {};
    const {
      officeId,
      address: {
        addressLine1,
        addressLine2,
        location: {
          locationName,
        } = {},
      } = {}
    } = office || {};
    const data = {
      title: nameName,
      internalCode,
      jobDesc,
      isPrivate,
      jobReq,
      noOfVacancies: vacancy,
      closeDate,
      managedCompanyVal: { label: managedCompanyName, value: companyId },
      userListVal: { label: `${firstname} ${lastname}`, value: recruiterId },
      minSalary,
      maxSalary,
      workdaysVal: { label: workdayType, value: workdayId },
      institutionVal: { label: (companyName && companyName.toString() && companyName.toUpperCase()), value: institutionId },
      currencyVal: { label: currencyFull, value: currencyId },
      countryVal: { label: countryFull, value: countryId },
      functionVal: { label: functionName, value: functionId },
      termVal: { label: termName, value: termId },
      cityVal: { label: cityName, value: cityId },
      officesval: { label: `${addressLine1} ${(addressLine2 && addressLine2 !== null && addressLine2 != undefined) ? addressLine2 : ""} ${locationName}`, value: officeId },
      industryVal: { label: industryName, value: industryId },
      jobDocuments: jobdocuments,
    };
    if (jobjd && jobjd.jobDocId) {
      data.jobJDArr = [jobjd];
    }

    if (institutionId) {
      const facultyData = {
        facultyActivePage: 1,
        facultyItemsPerPage: 1000,
        facultySearchKey: null,
        schoolId: institutionId,
      };
      this.props.getAllFaculties(facultyData)
    }

    if (batchId !== null && batchId !== undefined) {
      // this.handleRadioButtonChange(batchId);
      this.handleBatchSelection(batchId);
    }
    data.defaultSelectedValue = parseInt(batchId);
    // this.setState({...this.state, defaultSelectedValue: batchId })
    const jobCourseData = {};
    const jobCourseMapData = {};
    const jobFaculties = {};

    if (jobcoursemap && Array.isArray(jobcoursemap) && jobcoursemap.length > 0) {
      jobcoursemap.forEach((res) => {
        const {
          facultyId,
          courseId,
        } = res || {};
        if (jobCourseData[`courses-${facultyId}`]) {
          jobCourseData[`courses-${facultyId}`].push(courseId);
        } else {
          jobCourseData[`courses-${facultyId}`] = [courseId];
        }
        if (jobCourseMapData[facultyId]) {
          jobCourseMapData[facultyId].courses.push(courseId);
        } else {
          jobCourseMapData[facultyId] = {
            courses: [courseId],
          };
        }
        jobFaculties[`faculty-${facultyId}`] = facultyId;
        jobFaculties[`is-faculty-${facultyId}-present`] = true;
      });
    }

    if (jobfacultymap && Array.isArray(jobfacultymap) && jobfacultymap.length > 0
      && jobcoursemap && Array.isArray(jobcoursemap) && jobcoursemap.length <= 0) {
      jobfacultymap.forEach((facs) => {
        const {
          facultyId,
        } = facs || {};
        jobFaculties[`faculty-${facultyId}`] = facultyId;
      });
    }
    const courseMapKeys = jobCourseMapData && Object.keys(jobCourseMapData);
    let finalCourseMapData = [];
    if (courseMapKeys && Array.isArray(courseMapKeys) && courseMapKeys.length > 0) {
      finalCourseMapData = courseMapKeys.map((res) => {
        const filteredCourses = jobCourseMapData && jobCourseMapData[res] && jobCourseMapData[res].courses.filter((course) => course != null)
        const coursData = {
          facultyId: res,
          courses: filteredCourses,
        };
        return coursData;
      });
    } else if (jobfacultymap && Array.isArray(jobfacultymap) && jobfacultymap.length > 0) {
      finalCourseMapData = jobfacultymap.map((facs) => {
        const {
          facultyId,
        } = facs || {};
        const facData = {
          facultyId,
          courses: [],
        };
        return facData;
      });
    }
    let jobDescEditorState = EditorState.createEmpty();
    let jobReqEditorState = EditorState.createEmpty();

    if (jobDesc) {
      const contentBlock = htmlToDraft(jobDesc);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        jobDescEditorState = EditorState.createWithContent(contentState);
      }
    }
    if (jobReq) {
      const contentBlockjobReq = htmlToDraft(jobReq);
      if (contentBlockjobReq) {
        const contentStatejobReq = ContentState.createFromBlockArray(contentBlockjobReq.contentBlocks);
        jobReqEditorState = EditorState.createWithContent(contentStatejobReq);
      }
    }
    const isJobDescEmpty = jobDescEditorState.getCurrentContent().getPlainText().trimLeft().length > 0 ? false : true;
    const isJobReqEmpty = jobReqEditorState.getCurrentContent().getPlainText().trimLeft().length > 0 ? false : true;
    if (skills && Array.isArray(skills) && skills.length > 0) {
      data.skillsmusthave = skills.map((res) => {
        const {
          skill: {
            skillId,
            skillName,
          } = {},
        } = res || {};
        const finalVal = {
          label: skillName,
          value: skillId,
        };
        return finalVal;
      });
    }
    this.setState({
      ...data,
      isJobDescEmpty,
      isJobReqEmpty,
      jobDescEditorState,
      jobReqEditorState,
      teamId,
      ...jobFaculties,
      customShift,
      customIndustry,
      additionalComments,
      coursemap: finalCourseMapData,
      ...jobCourseData,
      // preferenceBackoffice: preferenceBackoffice && Number(preferenceBackoffice),
      // preferenceTaskOriented: preferenceTaskOriented && Number(preferenceTaskOriented),
    });
  }

  handleToggleChange = () => {
    const {
      isPrivate,
    } = this.state;
    this.setState({
      isPrivate: !isPrivate,
    });
  }

  handleChange = (e, name) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      if (name === 'title') {
        this.setInternalCode();
      }
    });
  }

  handleRadioButtonChange = (batchId) => {
    const { facultyData } = this.props;
    const { activeBatches } = this.state;
    const requiredBatch = activeBatches.filter((activeBatch) => parseInt(activeBatch.batchId) === parseInt(batchId));
    const { batchName } = requiredBatch[0] || {};
    const requiredBatchDetails = requiredBatch[0].batchDetails;
    let requiredFacultyData = [];
    for (let ei in requiredBatchDetails) {
      const rfd = facultyData.filter((faculty) => faculty.facultyName === requiredBatchDetails[ei].facultyName)[0];
      requiredFacultyData.push(rfd);
    }

    this.setState({
      showFacultiesToSelect: true,
      facultyData: requiredFacultyData,
      "batchId": parseInt(batchId),
      "jobBatch": batchName,
      showBatchMapValidation: false
    });
  }

  handleBatchSelection = (batchId) => {
    const { facultyData } = this.props;
    const { activeBatches } = this.state;
    const requiredBatch = activeBatches.filter((activeBatch) => parseInt(activeBatch.batchId) === parseInt(batchId));
    const { batchName } = requiredBatch[0] || {};
    const requiredBatchDetails = requiredBatch[0].batchDetails;
    let requiredFacultyData = [];
    for (let ei in requiredBatchDetails) {
      const rfd = facultyData.filter((faculty) => faculty.facultyName === requiredBatchDetails[ei].facultyName)[0];
      requiredFacultyData.push(rfd);
    }
    const coursemap = [];
    requiredFacultyData.map((faculty)=>{
      const { facultyId, courses } = faculty || {};
      const coursesArr= courses.map((course) => course.courseId);
      coursemap.push({
        facultyId,
        courses:coursesArr
      })
    })

    this.setState({
      // showFacultiesToSelect: true,
      facultyData: requiredFacultyData,
      "batchId": parseInt(batchId),
      "jobBatch": batchName,
      coursemap
      // showBatchMapValidation: false
    });
  }

  handlePasted = (pastedData, type) => {
    const params = {
      jobDesc: {
        limit: 4893,
        currState: 'jobDescEditorState',
        oldState: 'jobDescOldEditorState',
        empty: 'isJobDescEmpty',
      },
      jobReq: {
        limit: 2893,
        currState: 'jobReqEditorState',
        oldState: 'jobReqOldEditorState',
        empty: 'isJobReqEmpty',
      },
    };

    const Len = draftToHtml(convertToRaw(this.state[params[type].currState].getCurrentContent())).length;
    if (Len < params[type].limit && (Len + pastedData.length) > params[type].limit) {
      const sliceLen = params[type].limit - Len;
      pastedData = pastedData.slice(0, sliceLen);
    }
    if (Len < params[type].limit && (Len + pastedData.length) <= params[type].limit) {
      const blockMap = ContentState.createFromText(pastedData).blockMap;
      const newContentState = Modifier.replaceWithFragment(this.state[params[type].currState].getCurrentContent(), this.state[params[type].currState].getSelection(), blockMap);
      const newEditorState = EditorState.push(this.state[params[type].currState], newContentState, 'insert-fragment');
      this.setState({
        [params[type].currState]: newEditorState,
        [params[type].oldState]: newEditorState,
        [params[type].empty]: (pastedData && typeof pastedData == 'string' && pastedData.trimLeft().length > 0) ? false : true,
        [type]: draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
      });
    }
    return true;
  }

  onEditorStateChange = (jobEditorState, type) => {
    const params = {
      jobDesc: {
        limit: 4996,
        currState: 'jobDescEditorState',
        oldState: 'jobDescOldEditorState',
        empty: 'isJobDescEmpty',
      },
      jobReq: {
        limit: 2996,
        currState: 'jobReqEditorState',
        oldState: 'jobReqOldEditorState',
        empty: 'isJobReqEmpty',
      },
    };
    let HtmlContent = '';
    HtmlContent = draftToHtml(convertToRaw(jobEditorState.getCurrentContent()));
    if (HtmlContent.length <= params[type].limit) {
      const textLength = jobEditorState.getCurrentContent().getPlainText().trimLeft().length;
      this.setState({
        [params[type].currState]: jobEditorState,
        [params[type].oldState]: jobEditorState,
        [params[type].empty]: textLength > 0 ? false : true,
        [type]: draftToHtml(convertToRaw(jobEditorState.getCurrentContent())),
      });
    } else {
      const OldStateHtml = draftToHtml(convertToRaw(this.state[params[type].oldState].getCurrentContent()));
      const textLength = this.state[params[type].oldState].getCurrentContent().getPlainText().trimLeft().length;
      const blocks = convertFromHTML(OldStateHtml);
      const state = ContentState.createFromBlockArray(blocks);
      const newEditorState = EditorState.createWithContent(state);
      this.setState({
        [params[type].currState]: newEditorState,
        [params[type].oldState]: newEditorState,
        [params[type].empty]: textLength > 0 ? false : true,
        [type]: draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
      });
    }
  };

  onInputDropdownChange = (value) => {
    if (value) {
      this.props.getLocations(value).then((res) => {
        if (res && !res.error) {
          const { locations } = this.props;
          const values = {
            locations,
          };
          const locationsArr = getLocationsDetails(values);
          this.setState({
            locationsArr,
          });
        }
      });
    }
  };


  handleDetailsValidation = () => {
    const {
      title, internalCode, cityVal, countryVal,
      skillsmusthave, institutionVal, officesval,
      managedCompanyVal, userListVal, maxSalary,

    } = this.state;
    let isMaxSalaryValid = false;
    let isJobTitleValid = false;
    let isInternalCodeValid = false;
    let isCityValid = false;
    let isCountryValid = false;
    let isSkillsValid = false;
    let isInstitutionValid = false;
    let isOfficevalid = false;
    let isCompanyValid = false;
    let isUserValid = false;
    const {
      user: {
        company: {
          ctypeId,
        } = {},
      } = {},
    } = this.props;
    const {
      value: locationId,
    } = cityVal || {};
    const {
      value: officeId,
    } = officesval || {};
    const {
      value: companyId,
    } = managedCompanyVal || {};
    const {
      value: userId,
    } = userListVal || {};
    const {
      value: institutionId,
    } = institutionVal;
    const {
      value: countryId,
    } = countryVal || {};
    if (!institutionId) {
      isInstitutionValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isInstitutionValid = false;
    }

    if (ctypeId && Number(ctypeId) === 2 && !companyId) {
      isCompanyValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isCompanyValid = false;
    }

    if (ctypeId && Number(ctypeId) === 2 && !userId) {
      isUserValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isUserValid = false;
    }

    if (!officeId) {
      isOfficevalid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isOfficevalid = false;
    }

    if (!title) {
      isJobTitleValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isJobTitleValid = false;
    }

    if (!internalCode) {
      isInternalCodeValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isInternalCodeValid = false;
    }

    if (!locationId) {
      isCityValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isCityValid = false;
    }

    if (!countryId) {
      isCountryValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isCountryValid = false;
    }

    if (!skillsmusthave || (skillsmusthave && Array.isArray(skillsmusthave) && skillsmusthave.length <= 0)) {
      isSkillsValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isSkillsValid = false;
    }

    if (!maxSalary) {
      isMaxSalaryValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    }
    else {
      isMaxSalaryValid = false;
    }

    this.setState({
      isJobTitleValid,
      isInternalCodeValid,
      isCityValid,
      isCountryValid,
      isSkillsValid,
      isInstitutionValid,
      isOfficevalid,
      isCompanyValid,
      isUserValid,
      isMaxSalaryValid
    }, () => {
      if (this.state.isJobTitleValid !== true && this.state.isInternalCodeValid !== true
        && this.state.isCityValid !== true && this.state.isCountryValid !== true && this.state.isSkillsValid !== true
        && this.state.isInstitutionValid !== true && this.state.isOfficevalid !== true
        && this.state.isCompanyValid !== true && isUserValid !== true && isMaxSalaryValid !== true) {
        this.handleAddInfoValidation();
      }
    });
  }

  handleFacultyValidation = () => {
    const {
      coursemap, batchId, jobBatch
    } = this.state;
    const {
      user: {
        company: {
          ctypeId,
        } = {},
      } = {},
      match: {
        params: {
          jobId,
        } = {},
      } = {},
    } = this.props;
    let showCourseMapValidation = false;
    let showBatchMapValidation = false;
    let showSHLCourseErrorMsg = false;
    if (batchId === null || batchId === undefined || jobBatch === null || jobBatch === undefined) {
      showBatchMapValidation = true;
      let c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.offsetTop
      c=c+1000;
      window.scrollTo({ top: c, behavior: 'smooth' });
      this.setState({
        showBatchMapValidation
      })
      return;
    } else {
      showBatchMapValidation = false;
    }
    // if (!coursemap || (coursemap && Array.isArray(coursemap) && coursemap.length <= 0)) {
    //   showCourseMapValidation = true;
    //   const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
    //     && this.detailsNotifRef.current.focus();
    // } else {
    //   if (!jobId && ctypeId && Number(ctypeId) === 1) {
    //     let isSHLPresent = false;
    //     let isSHLCoursePresent = false;
    //     for (let i in coursemap) {
    //       if (coursemap[i].facultyId && Number(coursemap[i].facultyId) === 60) {
    //         isSHLPresent = true;
    //         if (coursemap[i].courses && Array.isArray(coursemap[i].courses) && coursemap[i].courses.length > 0) {
    //           isSHLCoursePresent = true;
    //         }
    //       }
    //     }
    //     if (isSHLPresent === true && isSHLCoursePresent === false ) {
    //       showSHLCourseErrorMsg = true;
    //     }
    //   }
    //   showCourseMapValidation = false;
    // }
    this.setState({
      showCourseMapValidation,
      showBatchMapValidation,
      showSHLCourseErrorMsg
    }, () => {
      if (this.state.showCourseMapValidation !== true && this.state.showBatchMapValidation !== true && this.state.showSHLCourseErrorMsg !== true) {
        this.handleDescValidation();
      }
    });
  }

  handleAddInfoValidation = () => {
    const {
      termVal: {
        value: termId,
      } = {},
      functionVal: {
        value: functionId,
      } = {},
      currencyVal: {
        value: currencyId,
      } = {},
      industryVal: {
        value: industryId,
      } = {},
      workdaysVal: {
        value: workdayId,
      } = {},
      customShift,
      customIndustry,
      noOfVacancies,
    } = this.state;
    const {
      user: {
        isSuperAdmin,
        company: {
          ctypeId,
        } = {},
      } = {},
    } = this.props;
    let isTermValid = false;
    let isFunctionValid = false;
    let isIndustryValid = false;
    let isVacancyValid = false;
    let isCurrencyValid = false;
    let isWorkDayValid = false;
    let isShiftValid = false;
    let isCustomIndustryValid = false;
    if (!termId) {
      isTermValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isTermValid = false;
    }

    if (!functionId) {
      isFunctionValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isFunctionValid = false;
    }

    if (!workdayId) {
      isWorkDayValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isWorkDayValid = false;
    }

    if (workdayId && Number(workdayId) === 4 && !customShift) {
      isShiftValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isShiftValid = false;
    }

    if (industryId && Number(industryId) === 30 && !customIndustry) {
      isCustomIndustryValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isCustomIndustryValid = false;
    }

    if (!industryId) {
      isIndustryValid = true;
    } else {
      isIndustryValid = false;
    }

    if (!currencyId) {
      isCurrencyValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isCurrencyValid = false;
    }

    if (!noOfVacancies || noOfVacancies <= 0) {
      isVacancyValid = true;
      const c = this.detailsNotifRef && this.detailsNotifRef.current && this.detailsNotifRef.current.focus
        && this.detailsNotifRef.current.focus();
    } else {
      isVacancyValid = false;
    }

    this.setState({
      isTermValid,
      isFunctionValid,
      isVacancyValid,
      isCurrencyValid,
      isIndustryValid,
      isWorkDayValid,
      isShiftValid,
      isCustomIndustryValid,
    }, () => {
      if (this.state.isTermValid !== true && this.state.isFunctionValid !== true
        && this.state.isVacancyValid !== true && this.state.isCurrencyValid !== true
        && this.state.isIndustryValid !== true && this.state.isWorkDayValid !== true
        && this.state.isShiftValid !== true && this.state.isCustomIndustryValid !== true) {
        this.handleFacultyValidation();
      }
    });
  }

  handleDescValidation = () => {
    const {
      jobDescEditorState,
      jobReqEditorState,
    } = this.state;
    let showDescErr = false;
    if (!jobDescEditorState && !jobReqEditorState) {
      showDescErr = true;
    } else {
      showDescErr = false;
    }

    this.setState({
      showDescErr,
    }, () => {
      if (this.state.showDescErr !== true) {
        this.handleSubmitJobData();
      }
    });
  }

  handleSubmitJobData = () => {
    const {
      internalCode, title, cityVal,
      countryVal, skillsmusthave, isPrivate,
      termVal, functionVal, minSalary, maxSalary,
      currencyVal, noOfVacancies, closeDate,
      jobReq, jobDesc, institutionVal,
      coursemap, industryVal, workdaysVal,
      // preferenceTaskOriented,
      // preferenceBackoffice,
      officesval, customShift, customIndustry,
      additionalComments, jobDocuments, jobJDArr,
      managedCompanyVal: { value: companyId } = {},
      userListVal: { value: userId } = {},
      showCustomShiftErr, jobBatch, batchId
    } = this.state;
    const {
      user: {
        userId: loggedInUserId,
        company: {
          companyId: userCompId,
          ctypeId,
        } = {},
      } = {},
      match: {
        params: {
          jobId,
        } = {},
      } = {},
      jobs,
    } = this.props;

    const {
      companyId: jobCompanyId,
      recuiterCompanyId,
      recruiterId,
      jobfacultymap,
      jobname: {
        nameName,
      } = {},
      maxSalary: propMaxSalary,
    } = (jobs && jobs[0]) || [];
    const params = {
      status: true,
      fromResume: false,
      draft: false,
    };

    if (ctypeId && Number(ctypeId) === 1) {
      params.companyId = userCompId;
    } else {
      params.companyId = companyId;
    }
    if (ctypeId && Number(ctypeId) === 1) {
      params.recruiterId = loggedInUserId;
    } else {
      params.recruiterId = userId;
    }
    params.title = title;
    params.internalCode = internalCode;
    params.isPrivate = isPrivate;
    params.institutionId = institutionVal && institutionVal.value;
    params.cityId = cityVal && cityVal.value;
    params.countryId = countryVal && countryVal.value;
    params.industryId = industryVal && industryVal.value;
    params.officeId = officesval && officesval.value;
    params.workdayId = workdaysVal && workdaysVal.value;
    params.customShift = customShift;
    params.customIndustry = customIndustry;
    params.additionalComments = additionalComments;
    if (skillsmusthave && Array.isArray(skillsmusthave)
      && skillsmusthave.length > 0) {
      params.skillsmusthave = skillsmusthave.map((res) => {
        const {
          label,
        } = res || {};
        const data = {
          skill: label,
          type: 1,
        };
        return data;
      });
    }
    params.termId = termVal && termVal.value;
    params.functionId = functionVal && functionVal.value;
    params.minSalary = minSalary;
    params.maxSalary = maxSalary;
    params.currencyId = currencyVal && currencyVal.value;
    params.vacancy = noOfVacancies;
    params.closeDate = closeDate;
    params.jobDesc = jobDesc;
    params.jobReq = jobReq;
    params.jobBatch = jobBatch;
    params.batchId = batchId;
    // params.preferences = {
    //   preferenceTaskOriented,
    //   preferenceBackoffice,
    // };
    if (coursemap && Array.isArray(coursemap) && coursemap.length > 0) {
      params.coursemap = coursemap;
    }
    if (jobDocuments && Array.isArray(jobDocuments)) {
      params.jobDocuments = jobDocuments;
    }
    if (jobJDArr && Array.isArray(jobJDArr)) {
      params.jobJDArr = jobJDArr;
    }

    if (!jobId) {
      this.setState({
        showLoader: true,
      }, () => {
        if (customShift && customShift.length > 200) {
          this.setState({
            showCustomShiftErr: true,
            showLoader: false
          })
        }
        if (additionalComments && additionalComments.length > 2000) {
          this.setState({
            showAddCommentErr: true,
            showLoader: false
          })
        }
        this.props.saveJob(params).then((res) => {
          if (res && !res.error) {
            this.setState({
              hideForm: true,
              currJobId: res && res.data && res.data.jobId,
              showLoader: false,
            });
          }
        }).catch(e => console.log('Error:::::', e));
      });
    } else if (jobId) {
      this.setState({
        showLoader: true,
      }, () => {
        params.recruiterId = recruiterId;
        // params.companyId = recuiterCompanyId;
        params.companyId = jobCompanyId;
        params.jobId = jobId && Number(jobId);
        params.teamId = this.state.teamId;
        let facultyOverwrite = true;
        const propFacultyLength = (jobfacultymap && Array.isArray(jobfacultymap) && jobfacultymap.length) || 0;
        const stateFaculty = (coursemap.length) || 0;
        if (propFacultyLength !== stateFaculty || nameName !== title || maxSalary !== propMaxSalary) {
          facultyOverwrite = true;
        } else {
          facultyOverwrite = false;
        }
        params.facultyOverwrite = facultyOverwrite;
        if (customShift && customShift.length > 200) {
          this.setState({
            showCustomShiftErr: true,
            showLoader: false
          })
        }
        if (additionalComments && additionalComments.length > 2000) {
          this.setState({
            showAddCommentErr: true,
            showLoader: false
          })
        }
        this.props.editJob(params).then((res) => {
          if (res && !res.error) {
            this.setState({
              hideForm: true,
              currJobId: jobId,
              showLoader: false,
            });
          }
        }).catch(e => console.log('Error:::::', e));
      });
    }
  }

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    }, () => {
      const {
        countryVal,
        institutionVal,
        managedCompanyVal,
      } = this.state;
      if (name === 'countryVal' && countryVal && countryVal.value) {
        const {
          countryVal: {
            value: countryId,
          } = {},
          citiesArr,
          currenciesArr,
        } = this.state;
        let finalCityArr = [];
        let currencyVal = {};
        if (citiesArr && Array.isArray(citiesArr) && citiesArr.length > 0) {
          finalCityArr = citiesArr.filter(cities => cities.countryId == countryId);
        }
        if (currenciesArr && Array.isArray(currenciesArr) && currenciesArr.length > 0) {
          currencyVal = currenciesArr.find(curr => curr.countryId == countryId);
        }
        this.setState({
          citiesArr: finalCityArr,
          currencyVal,
        });
      }
      if (name === 'institutionVal' && institutionVal && institutionVal.value) {
        const data = {
          facultyActivePage: 1,
          facultyItemsPerPage: 1000,
          facultySearchKey: null,
          schoolId: institutionVal.value,
        };
        this.props.getAllFaculties(data).then((res) => {
          if (res && !res.error) {
            this.setState({
              coursemap: [],
            });
          }
        });
      }
      if (name === 'managedCompanyVal' && managedCompanyVal && managedCompanyVal.value) {
        const {
          officeId,
          officeName,
          value: companyId,
        } = managedCompanyVal || {};
        // const officesval = { label: officeName, value: officeId };
        const officesval = {};
        this.setState({
          officesval,
        }, () => {
          this.props.fetchAllOffices(companyId).then((res) => {
            if (res && !res.error) {
              this.handleofficedropDowns();
              const data = {
                usersActivePage: 1,
                usersItemsPerPage: 1000,
                usersSearchKey: null,
                companyId,
              };
              this.props.getCompanyUsersList(data).then((users) => {
                if (users && !users.error) {
                  let userListArr = [];
                  const {
                    usersList,
                  } = this.props;
                  if (usersList && Array.isArray(usersList) && usersList.length > 0) {
                    userListArr = usersList.map((us) => {
                      const {
                        firstname,
                        lastname,
                        userId,
                      } = us || {};
                      const userData = {
                        label: `${firstname} ${lastname}`,
                        value: userId,
                      };
                      return userData;
                    });
                    this.setState({
                      userListArr,
                      userListVal: undefined
                    });
                  }
                }
              });
            }
          });
        });
      }
    });
  };

  handleChangeCheckboxValues = (e, courseId, facultyId) => {
    let {
      coursemap,
    } = this.state;
    let selectedCourses = [];
    let finalCourseMap = cloneDeep(coursemap)
    if (e === true) {
      if (!finalCourseMap || (finalCourseMap && Array.isArray(finalCourseMap) && finalCourseMap.length <= 0)) {
        finalCourseMap = [{ facultyId, courses: [courseId] }];
        selectedCourses = [courseId];
      } else if (finalCourseMap && Array.isArray(finalCourseMap) && finalCourseMap.length > 0) {
        let filteredData = finalCourseMap.filter(res => res.facultyId === facultyId);
        let filterCourse = filteredData && Array.isArray(filteredData) && filteredData.length > 0 &&
          filteredData.filter((res) => {
            return res && res.courses && Array.isArray(res.courses) && res.courses.length >= 0
          })
        filteredData = filterCourse && filterCourse[0] || {}
        if (filteredData && filteredData.courses && Array.isArray(filteredData.courses) && filteredData.courses.length >= 0) {
          filteredData.courses.push(courseId);
          selectedCourses = filteredData.courses;
        } else {
          filteredData = { facultyId, courses: [courseId] };
          selectedCourses = [courseId];
          finalCourseMap.push(filteredData);
        }
      }
    }
    this.setState({
      coursemap: finalCourseMap,
      [`courses-${facultyId}`]: selectedCourses,
    }, () => {
      if (e === false) {
        const {
          coursemap: stateCourses,
        } = this.state;
        let remainingCourses = [];
        if (stateCourses && Array.isArray(stateCourses) && stateCourses.length > 0) {
          const filteredList = stateCourses.filter(res => res.facultyId !== facultyId);
          let filteredArr = [];
          if (filteredList && Array.isArray(filteredList) && filteredList.length <= 0) {
            filteredArr = stateCourses.filter(res => res.facultyId === facultyId);
            if (filteredArr && Array.isArray(filteredArr) && filteredArr[0] && filteredArr[0].courses && Array.isArray(filteredArr[0].courses) && filteredArr[0].courses.length > 1) {
              const filtCour = filteredArr[0].courses.filter(res => res !== courseId);
              filteredArr[0].courses = filtCour;
              remainingCourses = filtCour;
            } else {
              filteredArr = stateCourses.filter(res => res.facultyId !== facultyId);
              remainingCourses = [];
            }
            if (this.state.hasOwnProperty(`faculty-${facultyId}`) && this.state[`faculty-${facultyId}`] != null && this.state[`faculty-${facultyId}`] != undefined && filteredArr.length <= 0) {
              filteredArr.push({
                "facultyId": facultyId,
                "courses": []
              })
            }
            this.setState({
              coursemap: filteredArr,
              [`courses-${facultyId}`]: remainingCourses,
            });
          } else {
            filteredArr = stateCourses.find(res => res.facultyId === facultyId);

            if (filteredArr && filteredArr.courses && Array.isArray(filteredArr.courses) && filteredArr.courses.length > 0) {
              const filtCour = filteredArr.courses.filter(res => res !== courseId);
              filteredArr.courses = filtCour;
              remainingCourses = filtCour;
              if (filteredArr.courses.length > 0) {
                filteredList.push(filteredArr);
              } else {
                if (this.state.hasOwnProperty(`faculty-${facultyId}`) && this.state[`faculty-${facultyId}`] != null && this.state[`faculty-${facultyId}`] != undefined) {
                  filteredList.push(filteredArr);
                }
              }
            }
            this.setState({
              coursemap: filteredList,
              [`courses-${facultyId}`]: remainingCourses,
            });
          }
        }
      }
    });
  }

  handleSkillDropdownChange = (selectedOption, name, e) => {
    this.setState({
      [name]: selectedOption,
    });
  };

  setInternalCode = () => {
    const {
      title: label,
    } = this.state;
    if (label !== null && label.length > 0) {
      let internalCode = label;
      internalCode = internalCode.match(/\b(\w)/g);
      internalCode = internalCode.join('');
      this.setState({ internalCode });
      return internalCode;
    }
  }

  onCreateOptionClick = (name, value) => {
    if (value && typeof value === 'string' && value.trimLeft().length === 0) return;
    if (value && typeof value === 'string') value = value.trimLeft();
    const {
      skillsmusthave,
    } = this.state;
    const newSkill = {
      label: value,
      value: skillsmusthave && skillsmusthave.length + 1,
    };
    let finalSkillsList = skillsmusthave;
    if (!finalSkillsList || (finalSkillsList && finalSkillsList.length <= 0)) {
      finalSkillsList = [newSkill];
    } else if (finalSkillsList && finalSkillsList.length > 0) {
      finalSkillsList.push(newSkill);
    }
    this.setState({
      [name]: finalSkillsList,
      skillAdded: true,
      displayMessage: true,
      success: true,
      message: 'New skill created successfully!',
    });
  };

  onSkillsInputChange = (value, cb) => {
    if (cb && typeof cb === 'function') cb([]);
  };

  onDatePickerChange = (date) => {
    const [startPeriod = ''] = date;
    const closeDate = moment(startPeriod).format('YYYY-MM-DD');
    this.setState({ closeDate });
  }

  handleRecdirectJobsPage = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/app/campus/jobs/open');
    }
  }

  handleSelectAllCheckboxes = (facultyId, courses, e) => {
    const {
      user: {
        isSuperAdmin,
        isAdmin,
        company: {
          ctypeId,
        } = {},
      } = {},

    } = this.props;
    if (e === true) {
      const {
        coursemap,
      } = this.state;
      let data = {};
      if (isSuperAdmin === true || (ctypeId && Number(ctypeId) === 1 && isAdmin === true)) {
        data = handleSelectAllFacultyCourses({ courses, facultyId, ctypeId, coursemap });
      } else {
        data["filteredList"] = coursemap;
        data["filteredList"].push({
          "facultyId": facultyId,
          "courses": []
        })
        data["selectedCourses"] = [];
      }
      const {
        filteredList,
        selectedCourses,
      } = data;
      this.setState({
        coursemap: filteredList,
        [`courses-${facultyId}`]: selectedCourses,
        [`faculty-${facultyId}`]: facultyId,
      });
    } else {
      this.setState({
        [`faculty-${facultyId}`]: null,
      });
      this.handleClearAllCheckboxes(facultyId);
    }
  }

  handleClearAllCheckboxes = (facultyId) => {
    const {
      coursemap,
    } = this.state;
    let filteredData = [];
    if (coursemap && Array.isArray(coursemap) && coursemap.length > 1) {
      filteredData = coursemap.filter(res => res.facultyId !== facultyId);
    } else if (coursemap && Array.isArray(coursemap) && coursemap.length === 1) {
      filteredData = [];
    }
    this.setState({
      [`courses-${facultyId}`]: [],
      coursemap: filteredData,
      [`faculty-${facultyId}`]: null,
    });
  }

  onSliderChange = ({ value }, name) => {
    if (name === null || value === null) return;
    this.setState({ [name]: value });
  }

  activeAddOfficeModal = () => {
    this.setState({
      isOpenAddOfcModal: true,
    });
  };

  dismissAddOdcModal = () => {
    this.setState({
      isOpenAddOfcModal: false,
    });
  };

  handleofficedropDowns = () => {
    const { offices } = this.props;
    let officesArr = [];
    if (offices && Array.isArray(offices) && offices.length > 0) {
      officesArr = offices.map((res) => {
        const {
          officeId,
          address: { addressLine1, location: { locationName } = {} } = {},
        } = res || {};
        const data = {
          label: `${addressLine1}, ${locationName}`,
          value: officeId,
        };
        return data;
      });
      this.setState({
        officesArr,
      });
    }
  };

  handleSubmitOfcdetails = () => {
    let {
      addressLine1,
      addressLine2,
      zipcode,
      country,
      location,
    } = this.state;
    const {
      t,
    } = this.props;
    const officeInfo = {
      addressLine1,
      addressLine2,
      zipcode,
      country,
      location,
      t,
    };
    const officeValidInfo = officeDetailsValidation(officeInfo);
    if (addressLine2 === null || addressLine2 === undefined) {
      addressLine2 = "";
    }
    this.setState({
      ...officeValidInfo,
    }, () => {
      const {
        isValidAddrLine1,
        isValidCountry,
        isValidZipcode,
        isValidCity,
      } = this.state;
      if (
        isValidAddrLine1 !== true &&
        isValidCountry !== true &&
        isValidZipcode !== true &&
        isValidCity !== true
      ) {
        const {
          user: {
            company: {
              companyName: userCompName,
              ctypeId,
              companyId: userCompId,
            } = {},
          } = {},
          jobs,
          countries,
        } = this.props;
        let countryObj = {};
        if (countries && Array.isArray(countries) && countries.length > 0) {
          countryObj = countries.find(res => res.countryId == country);
        }
        const {
          companyId: jobCompId,
          company: {
            companyName: jobCompName,
          } = {},
        } = (jobs && jobs[0]) || {};
        const data = {
          type: 'office',
          addressId: 0,
          addressLine1,
          addressLine2,
          zipcode,
          location: location.label,
          locationName: location.label,
          country: countryObj && countryObj.countryFull,
          countryId: country,
        };
        if (ctypeId && Number(ctypeId) === 1) {
          data.companyId = userCompId;
          data.companyName = userCompName;
        } else if (ctypeId && Number(ctypeId) === 2) {
          data.companyId = jobCompId;
          data.companyName = jobCompName;
        }
        this.props.addOffice(data).then((res) => {
          if (res && !res.error) {
            this.props.fetchAllOffices(data.companyId).then((ofs) => {
              if (ofs && !ofs.error) {
                this.handleofficedropDowns();
                this.dismissAddOdcModal();
              }
            }).catch(e => console.log('Error:::::', e));
          }
        });
      }
    });
  };

  handleChangeDocuments = (docs, type) => {
    if (type === 'jd') {
      this.setState({
        jobJDArr: docs,
      });
    } else {
      this.setState({
        jobDocuments: docs,
      });
    }
  }

  handleUploadDocuments = (data, type) => {
    this.setState({
      uploadingDocs: true,
    }, () => {
      this.props.uploadDocuments(data)
        .then((res) => {
          const { files } = res || {};
          if (files && Array.isArray(files) && files.length > 0) {
            this.setState((prevState) => {
              if (type === 'jd') {
                const finalJD = [...files];
                return {
                  jobJDArr: finalJD,
                  uploadingDocs: false,
                };
              } else {
                const { jobDocuments } = prevState || {};
                const finalDocs = [...jobDocuments, ...files];
                return {
                  jobDocuments: finalDocs,
                  uploadingDocs: false,
                };
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            uploadingDocs: false,
          });
        });
    });
  }

  uploadFile = data => {
    window.data = data;
    this.setState(
      {
        showLoader: true,
      },
      () => {
        this.props
          .parseJdJobReg(data)
          .then(res => {
            if (res && !res.error) {
              console.log(res);
              const { jobDesc } = res;
              const values = this.props.jobCreation;
              this.handleParseJd(res);
              this.setState({
                // jobDescFile: '',
                jobDescFile: jobDesc,
                showLoader: false,
              });
            }
          })
          .catch(e => {
            console.log('Error::::', e);
            this.setState({
              showLoader: false,
            });
          });
      }
    );
  };

  handleParseJd = values => {
    const { skills, jobDesc = '', jobReq, jobTitle, country, currency, vacancy, qualification, seniority } = values || {};
    const { countryName, countryId } = country || {};
    const { currencyName, currencyId } = currency || {};
    const { qualificationName, qualificationId } = qualification || {};
    const { seniorityName, seniorityId } = seniority || {};
    const data = {
      title: jobTitle,
    };
    if (skills && Array.isArray(skills) && skills.length > 0) {
      data.skillsmusthave = skills.map((skill, idx) => {
        const data = {
          label: skill,
          value: idx + 1,
        };
        return data;
      });
    }
    let jobdes = jobDesc;
    let jobreq = jobReq;

    if (jobdes && jobdes !== null && jobdes.length > 10000) {
      jobdes = jobdes.substr(0, 9800);
    }
    if (jobreq && jobreq !== null && jobreq.length > 10000) {
      jobreq = jobreq.substr(0, 9800);
    }
    let isJobDescEmpty = true;
    let isJobReqEmpty = true;
    if (jobdes && jobdes !== null && typeof jobdes === 'string' && jobdes.trimLeft().length > 0) {
      isJobDescEmpty = false;
    }
    if (jobreq && jobreq !== null && typeof jobreq === 'string' && jobreq.trimLeft().length > 0) {
      isJobReqEmpty = false;
    }
    let jobDescEditorState = EditorState.createEmpty();
    let jobReqEditorState = EditorState.createEmpty();

    if (jobdes) {
      const contentBlock = htmlToDraft(jobdes);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        jobDescEditorState = EditorState.createWithContent(contentState);
      }
    }
    if (jobreq) {
      const contentBlockjobReq = htmlToDraft(jobreq);
      if (contentBlockjobReq) {
        const contentStatejobReq = ContentState.createFromBlockArray(contentBlockjobReq.contentBlocks);
        jobReqEditorState = EditorState.createWithContent(contentStatejobReq);
      }
    }
    const internalCode = this.setInternalCode(jobTitle);
    this.setState({
      ...data,
      internalCode,
      jobDesc: jobdes,
      jobReq: jobreq,
      jobDescEditorState,
      jobReqEditorState,
      countryVal: {
        label: countryName,
        value: countryId,
      },
      currencyVal: {
        label: currencyName,
        value: currencyId,
      },
      noOfVacancies: vacancy || 1,
      qualificationTypesVal: {
        label: qualificationName,
        value: qualificationId,
      },
      jobSenioritiesVal: {
        label: seniorityName,
        value: seniorityId,
      },
    });
  };

  handleFileChange = e => {
    const { t: translation } = this.props;
    const ext = e.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['pdf', 'doc', 'docx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    if (isFileAllowed) {
      const {
        target: { files, value },
      } = e;

      const formData = new FormData();
      formData.append('file', files[0]);
      this.setState(
        {
          jobDescFile: value,
          loading: true,
        },
        () => {
          this.uploadFile(formData);
        }
      );
    } else {
      alert(translation('Please upload valid file type, allowed files are PDF, Word'));
    }
  };


  render() {
    const {
      t,
      history: {
        push,
      } = {},
      match: {
        params: {
          jobId,
        } = {},
      } = {},
      loading,
      countries,
      // facultyData,
      user: {
        facultyId: userFacultyId,
        courseId:userCourseId,
        isSuperAdmin,
        isAdmin,
        courseAdmin,
        company: {
          ctypeId,
        } = {},
      } = {},
    } = this.props;
    // const { facultyData } = this.state;
    const {
      title, countryVal, countriesArr, citiesArr, internalCode, institutionVal,
      cityVal, skillsmusthave, termsArr, termVal, closeDate, institutionArr,
      jobDescEditorState, jobReqEditorState, isPrivate, isInstitutionValid,
      functionsArr, functionVal, currencyVal, currenciesArr, noOfVacancies,
      minSalary, maxSalary, isJobTitleValid, isInternalCodeValid, isCityValid,
      isCountryValid, isSkillsValid, isVacancyValid, isTermValid, isFunctionValid,
      isCurrencyValid, showDescErr, hideForm, currJobId, showLoader, industriesArr, industryVal,
      isIndustryValid, preferenceTaskOriented, preferenceBackoffice, officesArr,
      isOfficevalid, officesval, isOpenAddOfcModal,
      addressLine1,
      addressLine2,
      zipcode,
      country,
      city, customShift, isShiftValid, additionalComments,
      officeName,
      isValidAddrLine1,
      isValidAddrLine1Text,
      isValidOfficeName,
      isValidOfficeNameText,
      isValidCity,
      isValidCityText,
      isValidCountry,
      isValidCountryText,
      isValidZipcode,
      location,
      locationsArr,
      isValidZipcodeText, workdaysVal,
      isWorkDayValid, workdaysArr,
      showCourseMapValidation, showBatchMapValidation, showSHLCourseErrorMsg, customIndustry,
      isCustomIndustryValid, managedCompsListArr,
      isCompanyValid, managedCompanyVal,
      userListArr, userListVal, isUserValid,
      isMaxSalaryValid,
      showCustomShiftErr,
      showAddCommentErr,
      activeBatches,
      batchId,
      showFacultiesToSelect,
      facultyData,
      defaultSelectedValue = null
    } = this.state;
    console.log('facultyData',facultyData)

    let finalArr = [];
    for (let i = 0; i < 52; i++) {
      if (finalArr.length <= 0) {
        finalArr = [i + 1];
      } else {
        finalArr.push(i + 1);
      }
    }
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        {
          !hideForm && (
            <div class="ml-3">
              {
                jobId ? (
                  <div className="h5 mt-2">{t('editJobPostingHeading')}</div>
                ) : (
                  <div className="h5 mt-2">{t('jobPostingHeading')}</div>
                )
              }
              <div>
                <div className="text-dark">{t('jobPostingUpdatedLabel1')}</div>
                <div className="text-dark">{t('jobPostingUpdatedLabel2')}</div>
                <div className="text-dark">{t('jobPostingUpdatedLabel3')}</div>
              </div>
            </div>
          )
        }
        <input
          style={{ height: 0, opacity: 0 }}
          type="text"
          ref={this.detailsNotifRef}
        />
        {
          !hideForm && (
            <div>
              <div className="ml-3 mb-3">
                <div className="h6">Upload JD</div>
                {/* <JobDocuments
                  labelText="Click to upload Job Description file"
                  accept={[
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ]}
                  updateDocuments={(f) => this.handleChangeDocuments(f, 'jd')}
                  uploadDocument={(d) => this.handleUploadDocuments(d, 'jd')}
                  jobDocuments={this.state.jobJDArr}
                  maxFiles={1}
                  multiple={false}
                /> */}
                <FileUploaderDropContainer
                  {...this.props}
                  labelText={t('Click to upload Job Description file')}
                  onAddFiles={this.handleFileChange}
                  className='xpa-file-cont-h'
                />
              </div>
              <Accordion>
                <AccordionItem
                  title={<div className="bx--type-epsilon text-uppercase xpa-link">{t('basic')} {t('information')}</div>}
                  open
                  className="p-0 ml-0 accordion-content"
                >
                  {/* <div className="sub-heading-size mt-2 text-dark mb-2 font-weight-bold text-dark mb-2">{t('institution')} {t('information')}</div> */}
                  <div className="bx--row mt-2 align-items-center">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{(t('institution'))} {t('name')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBox
                        title=""
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        required
                        custom
                        disabled={(jobId !== null && jobId !== undefined)}
                        className={`w-100 ${isInstitutionValid ? 'invalid' : ''}`}
                        options={institutionArr}
                        placeholder={t('institution')}
                        onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                        name="institutionVal"
                        selectedValue={institutionVal}
                      />
                      {
                        isInstitutionValid === true && (
                          <div className="bx--form-requirement mb-2">
                            {t('commonValidMsg')}
                          </div>
                        )
                      }
                    </div>
                  </div>
                  {
                    ctypeId && Number(ctypeId) === 2 && (
                      <div className="bx--row align-items-center mt-2">
                        <div className="bx--col-lg-3">
                          <span className="bx--type-zeta">{t('companies')}</span>{' '}
                          <span className="xpa-job-title">*</span>
                        </div>
                        <div className="bx--col-lg-6 bx--col-xl-7">
                          <DropDownBox
                            title=""
                            mainClassName="bx--form-item mb-0"
                            titleClass=""
                            labelClass=""
                            required
                            custom
                            disabled={jobId}
                            className={`w-100 ${isCompanyValid ? 'invalid' : ''}`}
                            options={managedCompsListArr}
                            placeholder={t('companies')}
                            onChange={(value, e, name) =>
                              this.handleDropdownChange(value, e, name)
                            }
                            name="managedCompanyVal"
                            selectedValue={managedCompanyVal}
                          />
                          {isCompanyValid === true && (
                            <div className="error-msg mb-2">
                              {t('commonValidMsg')}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  }
                  {
                    ctypeId && Number(ctypeId) === 2 && (
                      <div className="bx--row align-items-center mt-2">
                        <div className="bx--col-lg-3">
                          <span className="bx--type-zeta">Assigned to:</span>{' '}
                          <span className="xpa-job-title">*</span>
                        </div>
                        <div className="bx--col-lg-6 bx--col-xl-7">
                          <DropDownBox
                            title=""
                            mainClassName="bx--form-item mb-0"
                            titleClass=""
                            labelClass=""
                            required
                            custom
                            disabled={jobId}
                            className={`w-100 ${isUserValid ? 'invalid' : ''}`}
                            options={userListArr}
                            placeholder={t('users')}
                            onChange={(value, e, name) =>
                              this.handleDropdownChange(value, e, name)
                            }
                            name="userListVal"
                            selectedValue={userListVal}
                          />
                          {isUserValid === true && (
                            <div className="error-msg mb-2">
                              {t('commonValidMsg')}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  }
                  <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('stdReportingAddrs')}</span>{' '}
                      <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBox
                        title=""
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        required
                        custom
                        className={`w-100 ${isOfficevalid ? 'invalid' : ''}`}
                        options={officesArr}
                        placeholder="Office Name"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        name="officesval"
                        selectedValue={officesval}
                      />
                      {isOfficevalid === true && (
                        <div className="error-msg mb-2">
                          {t('ofcNameValidMsg')}
                        </div>
                      )}
                    </div>
                    <div className={`ml-3 ml-xl-0 mt-xl-0 mt-2 ${isOfficevalid ? 'mb-4 pb-2' : null}`}>
                      <Button kind="primary" onClick={this.activeAddOfficeModal}>
                        {t('addNewAddr')}
                      </Button>
                    </div>
                  </div>
                  {/* <div className="sub-heading-size mt-2 text-dark mb-2 font-weight-bold text-dark mb-2">{t('title')} {t('information')}</div> */}
                  <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('job')} {t('title')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <TextInput
                        name="title"
                        id="title"
                        invalid={isJobTitleValid}
                        invalidText={t('commonValidMsg')}
                        hideLabel
                        placeholder={t('title')}
                        labelText={<div>{t('name')}</div>}
                        value={title}
                        onChange={(e) => { this.handleChange(e, 'title'); }}
                      />
                    </div>
                  </div>
                  {/* <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('internalCode')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <TextInput
                        name="internalCode"
                        id="internalCode"
                        hideLabel
                        invalid={isInternalCodeValid}
                        invalidText={t('commonValidMsg')}
                        placeholder={t('internalCode')}
                        labelText={<div>{t('name')}</div>}
                        value={internalCode}
                        onChange={(e) => { this.handleChange(e, 'code'); }}
                      />
                    </div>
                  </div> */}
                  {/* <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('job')} {t('visibility')}</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <Toggle
                        id="toggle-1"
                        onToggle={this.handleToggleChange}
                        toggled={isPrivate}
                        labelA={t('public')}
                        labelB={t('private')}
                      />
                    </div>
                  </div> */}
                  {/* <div className="sub-heading-size mt-2 text-dark mb-2 font-weight-bold">{t('location')}</div> */}
                  <div className="bx--row mt-3 align-items-center">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('country')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBox
                        title=""
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        required
                        custom
                        className={`w-100 ${isCountryValid ? 'invalid' : ''}`}
                        options={countriesArr}
                        placeholder={t('country')}
                        onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                        name="countryVal"
                        selectedValue={countryVal}
                      />
                      {
                        isCountryValid === true && (
                          <div className="bx--form-requirement mb-2">
                            {t('commonValidMsg')}
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="bx--row mt-2 align-items-center">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('city')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBox
                        title=""
                        labelClass="mb-0"
                        titleClass=""
                        mainClassName="row m-0"
                        className={`w-100 ${isCityValid ? 'invalid' : ''}`}
                        options={citiesArr}
                        custom
                        name="cityVal"
                        placeholder={t('cities')}
                        onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                        selectedValue={cityVal}
                      />
                      {
                        isCityValid === true && (
                          <div className="bx--form-requirement mb-2">
                            {t('commonValidMsg')}
                          </div>
                        )
                      }
                    </div>
                  </div>
                  {/* <div className="sub-heading-size mt-2 text-dark mb-2 font-weight-bold">{t('skills')}</div> */}
                  <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('mustHaveSkills')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBoxWithCreate
                        // isAsync
                        title=""
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        multi
                        custom
                        className={`w-100 ${isSkillsValid ? 'invalid' : ''}`}
                        required
                        isClearable={false}
                        name="skillsmusthave"
                        props={this.props}
                        options={[]}
                        newOptionCreator={this.onCreateOptionClick}
                        onInputChange={this.onSkillsInputChange}
                        selectedValue={this.state.skillsmusthave}
                        onChange={(value, name, e) => this.handleSkillDropdownChange(value, name, e)}
                      />
                      {
                        isSkillsValid === true && (
                          <div className="bx--form-requirement mb-2">
                            {t('commonValidMsg')}
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="bx--row mt-3 align-items-center">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('jobFunction')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBox
                        title=""
                        mainClassName="bx--form-item"
                        titleClass=""
                        labelClass=""
                        required
                        custom
                        className={`w-100 ${isFunctionValid ? 'invalid' : ''}`}
                        options={functionsArr}
                        placeholder={t('jobFunction')}
                        onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                        name="functionVal"
                        selectedValue={functionVal}
                      />
                      {
                        isFunctionValid === true && (
                          <div className="bx--form-requirement mb-2">
                            {t('commonValidMsg')}
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('stipend')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <div className="d-flex">
                        <div className="w-50">
                          <TextInput
                            name="maxSalary"
                            id="maxSalary"
                            hideLabel
                            type="number"
                            placeholder="1000"
                            invalid={isMaxSalaryValid}
                            invalidText={`${t('commonValidMsg')} ${t('errMessage')}`}
                            labelText={<div>{t('name')}</div>}
                            value={maxSalary}
                            onChange={(e) => { this.handleChange(e, 'maxSalary'); }}
                          />
                          {/* <div className="mt-1" style={{ color: 'green' }}>
                            Recommended amount is $500 - $1000.<br />
                            Refer to <a href=" https://www.rp.edu.sg/docs/default-source/student-life-doc/internship/internship---frequently-asked-questions.pdf" target="_blank"> here </a> 
                            for more information.
                          </div> */}
                        </div>
                        <div className="ml-2 w-50">
                          <DropDownBox
                            title=""
                            mainClassName="bx--form-item mb-0"
                            titleClass=""
                            labelClass=""
                            required
                            custom
                            className={`w-100 ${isCurrencyValid ? 'invalid' : ''}`}
                            options={currenciesArr}
                            placeholder={t('currencyHolder')}
                            onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                            name="currencyVal"
                            selectedValue={currencyVal}
                          />
                          {
                            isCurrencyValid === true && (
                              <div className="bx--form-requirement mb-2">
                                {t('commonValidMsg')}
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bx--row mt-2 align-items-center">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('noOfWorkDays')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBox
                        title=""
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        required
                        custom
                        className={`w-100 ${isWorkDayValid ? 'invalid' : ''}`}
                        options={workdaysArr}
                        placeholder={t('noOfWorkDays')}
                        onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                        name="workdaysVal"
                        selectedValue={workdaysVal}
                      />
                      {
                        isWorkDayValid === true && (
                          <div className="bx--form-requirement mb-2">
                            {t('commonValidMsg')}
                          </div>
                        )
                      }
                    </div>
                  </div>
                  {
                    workdaysVal && workdaysVal.value && Number(workdaysVal.value) === 4
                    && (
                      <div className="bx--row align-items-center mt-2">
                        <div className="bx--col-lg-3">
                          <span className="bx--type-zeta">{t('workShift')}</span> <span className="xpa-job-title">*</span>
                        </div>
                        <div className="bx--col-lg-6 bx--col-xl-7">
                          {
                            showCustomShiftErr === true && (
                              <InlineNotification lowContrast className="w-100 p-0 m-0" kind="error" title="Please enter less than 200 characters" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showCustomShiftErr: false })} />
                            )
                          }
                          <TextInput
                            name="customShift"
                            id="customShift"
                            hideLabel
                            invalid={isShiftValid}
                            invalidText={t('commonValidMsg')}
                            placeholder={t('workShift')}
                            labelText={<div>{t('customShift')}</div>}
                            value={customShift}
                            onChange={(e) => { this.handleChange(e, 'customShift'); }}
                          />
                        </div>
                      </div>
                    )
                  }
                  <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('addlComments')}</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      {
                        showAddCommentErr === true && (
                          <InlineNotification lowContrast className="w-100 p-0 m-0" kind="error" title="Please enter less than 2000 characters" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showCustomShiftErr: false })} />
                        )
                      }
                      <TextArea
                        name="additionalComments"
                        id="additionalComments"
                        hideLabel
                        placeholder={t('addlComments')}
                        labelText={<div>{t('addlComments')}</div>}
                        value={additionalComments}
                        onChange={(e) => { this.handleChange(e, 'additionalComments'); }}
                      />
                    </div>
                  </div>
                  <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('noOfVacancies')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <TextInput
                        name="noOfVacancies"
                        id="noOfVacancies"
                        hideLabel
                        invalid={isVacancyValid}
                        invalidText={t('commonValidMsg')}
                        placeholder="0"
                        type="number"
                        labelText={<div>{t('name')}</div>}
                        value={noOfVacancies}
                        onChange={(e) => { this.handleChange(e, 'noOfVacancies'); }}
                      />
                    </div>
                  </div>
                </AccordionItem>
                {
                  institutionVal && institutionVal.value && (

                    <div>
                      <AccordionItem
                        ref={this.detailsNotifRef}
                        className="p-0 ml-0 accr-wdth accordion-content"
                        title={
                          (
                            <div>
                              <span className="bx--type-epsilon text-uppercase xpa-link">{t('facultyCoursesMsg')}</span>
                            </div>
                          )
                        }
                        open
                      // open={showBatchMapValidation || showCourseMapValidation}
                      >
                        <div>
                          {
                            showBatchMapValidation === true && (
                              <InlineNotification lowContrast className="w-100 p-0 m-0" kind="error" title="Please select preferred batch period" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showDescErr: false })} />
                            )
                          }
                          <div>
                            <span className="bx--type-delta text-capitalize">{t('selectInternshipPeriodMsg')}</span>
                          </div>
                          {defaultSelectedValue !== null && <RadioButtonGroup
                            className="xpa-customize-radio"
                            legendText="Select Batch"
                            name="radio-button-group"
                            defaultSelected={parseInt(defaultSelectedValue)}
                            // onChange={(e) => this.handleRadioButtonChange(e)}>
                            onChange={(e) => this.handleBatchSelection(e)}
                            >
                            {
                              activeBatches && Array.isArray(activeBatches) && activeBatches.length > 0 &&
                              activeBatches.map((activeBatch) => {
                                const { batchName, batchId } = activeBatch;
                                return <RadioButton 
                                  disabled={(jobId !== null && jobId !== undefined)} 
                                  className="add-border" 
                                  labelText= {<BatchDetailsInfo batch={activeBatch}/>}
                                  value={batchId} 
                                  id={batchId} 
                                />
                              })
                            }
                          </RadioButtonGroup>}
                        </div>
                        {
                          showFacultiesToSelect && facultyData && Array.isArray(facultyData) && facultyData.length > 0 &&
                          <div>
                            <hr />
                            {
                              showCourseMapValidation === true && (
                                <InlineNotification lowContrast className="w-100 p-0 m-0" kind="error" title="Please select atleast one option to proceed" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showDescErr: false })} />
                              )
                            }
                            {
                              showSHLCourseErrorMsg === true && (
                                <InlineNotification lowContrast className="w-100 p-0 m-0" kind="error" title="Please select atleast one course from School of Sports, Health and Leisure." subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showDescErr: false })} />
                              )
                            }
                            <FacultyCoursesSelection
                              jobId={jobId}
                              facultyData={facultyData}
                              isSuperAdmin={isSuperAdmin}
                              userFacultyId={userFacultyId}
                              ctypeId={ctypeId}
                              isAdmin={isAdmin}
                              userCourseId={userCourseId}
                              courseAdmin={courseAdmin}
                              state={this.state}
                              handleChangeCheckboxValues={this.handleChangeCheckboxValues}
                              handleSelectAllCheckboxes={this.handleSelectAllCheckboxes}
                              handleClearAllCheckboxes={this.handleClearAllCheckboxes}
                            />
                          </div>
                        }
                      </AccordionItem>
                    </div>
                  )
                }
                {/* <AccordionItem
                  className="p-0 ml-0 accordion-content"
                  title={<div className="bx--type-epsilon text-uppercase xpa-link">{t('additionInfo')}</div>}
                >
                  <input
                    style={{ height: 0, opacity: 0 }}
                    type="text"
                    ref={this.detailsNotifRef}
                  />
                  <div className="sub-heading-size mt-2 text-dark mb-2 font-weight-bold">{t('employmentDetails')}</div>
                  <div className="bx--row mt-3 align-items-center">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('job')} {t('type')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBox
                        title=""
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        required
                        custom
                        className={`w-100 ${isTermValid ? 'invalid' : ''}`}
                        options={termsArr}
                        placeholder={`${t('select')} ${t('job')} ${t('type')}`}
                        onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                        name="termVal"
                        selectedValue={termVal}
                      />
                      {
                        isTermValid === true && (
                          <div className="bx--form-requirement mb-2">
                            {t('commonValidMsg')}
                          </div>
                        )
                      }
                    </div>
                  </div>

                  <div className="bx--row mt-3 align-items-center">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('industries')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <DropDownBox
                        title=""
                        mainClassName="bx--form-item"
                        titleClass=""
                        labelClass=""
                        required
                        custom
                        className={`w-100 ${isIndustryValid ? 'invalid' : ''}`}
                        options={industriesArr}
                        placeholder={t('industries')}
                        onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                        name="industryVal"
                        selectedValue={industryVal}
                      />
                      {
                        isIndustryValid === true && (
                          <div className="bx--form-requirement mb-2">
                            {t('commonValidMsg')}
                          </div>
                        )
                      }
                    </div>
                  </div>
                  {
                    industryVal && industryVal.label && industryVal.label === 'Others'
                      && (
                        <div className="bx--row align-items-center mt-2">
                          <div className="bx--col-lg-3">
                            <span className="bx--type-zeta">{t('customIndustry')}</span> <span className="xpa-job-title">*</span>
                          </div>
                          <div className="bx--col-lg-6 bx--col-xl-7">
                            <TextInput
                              name="customIndustry"
                              id="customIndustry"
                              hideLabel
                              invalid={isCustomIndustryValid}
                              invalidText={t('commonValidMsg')}
                              placeholder={t('customIndustry')}
                              labelText={<div>{t('customIndustry')}</div>}
                              value={customIndustry}
                              onChange={(e) => { this.handleChange(e, 'customIndustry'); }}
                            />
                          </div>
                        </div>
                      )
                  }
                  <div className="sub-heading-size mt-2 text-dark mb-2 font-weight-bold">{t('annualSalary')}</div>
                  <div className="bx--row align-items-center mt-2">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">Min.</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7">
                      <TextInput
                        name="minSalary"
                        id="minSalary"
                        hideLabel
                        placeholder="0"
                        labelText={<div>{t('name')}</div>}
                        value={minSalary}
                        onChange={(e) => { this.handleChange(e, 'minSalary'); }}
                      />
                    </div>
                  </div>


                  <div className="bx--row mt-3 align-items-center">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('currency')}</span> <span className="xpa-job-title">*</span>
                    </div>

                  </div>

                  <div className="sub-heading-size mt-4 text-dark mb-2 font-weight-bold">
                    {t('percentageSliderMsg')}
                  </div>
                  <PercentageSlider hideSideLabels minLabel={t("taskOrientedPreference")} maxLabel={t("independentPreference")} value={preferenceTaskOriented} name="preferenceTaskOriented" onChange={this.onSliderChange} />
          <PercentageSlider hideSideLabels minLabel={t("backEndPreference")} maxLabel={t("frontEndPreference")} value={preferenceBackoffice} name="preferenceBackoffice" onChange={this.onSliderChange} />
                  <div className="bx--row align-items-center mt-3">
                    <div className="bx--col-lg-3">
                      <span className="bx--type-zeta">{t('closeDate')}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <div className="bx--col-lg-6 bx--col-xl-7 d-flex align-items-center">
                      <DatePicker datePickerType="single" onChange={this.onDatePickerChange}>
                        <DatePickerInput
                          name="closeDate"
                          className="end_date"
                          id="start"
                          placeholder="End Date"
                          value={moment(closeDate).format('MM/DD/YYYY')}
                        />
                      </DatePicker>
                    </div>
                  </div>
                </AccordionItem> */}
                <AccordionItem
                  className="p-0 ml-0 accordion-content"
                  title={<div className="bx--type-epsilon text-uppercase xpa-link">{t('desc')}</div>}
                  open
                  // open={showDescErr}
                >
                  {
                    showDescErr === true && (
                      <InlineNotification lowContrast className="w-100 p-0 m-0" kind="error" title="Please fill all fields marked with *" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showDescErr: false })} />
                    )
                  }
                  <div>
                    <div className="bx--row ml-0 mt-2">
                      <div className="newjob__form__header bx--col-lg-3">
                        <span className="bx--type-zeta">{t('jobDescription')}</span> <span className="xpa-job-title">*</span>
                      </div>
                      <div>
                        <Editor
                          required
                          toolbar={
                            {
                              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                            }
                          }
                          editorState={jobDescEditorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Type the job description here, including duties, tasks, responsibilities and what the candidate will do on a typical day."
                          handlePastedText={(pastedData) => { this.handlePasted(pastedData, 'jobDesc'); }}
                          onEditorStateChange={(editorState) => { this.onEditorStateChange(editorState, 'jobDesc'); }}
                        />
                        <label htmlFor="jd_upload">
                          <div className="newjob_upload  d-none">
                            <span>Or</span>
                            <span>Upload a File (.doc, .pdf)</span>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="bx--row ml-0 mt-2">
                      <div className="newjob__form__header bx--col-lg-3">
                        <span className="bx--type-zeta">{t('jobRequirements')}</span> <span className="xpa-job-title">*</span>
                      </div>
                      <div>
                        <Editor
                          required
                          toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                          }}
                          editorState={jobReqEditorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Type the job requirements here, including the candidates’ required qualification, skills and abilities."
                          handlePastedText={(pastedData) => { this.handlePasted(pastedData, 'jobReq'); }}
                          onEditorStateChange={(editorState) => { this.onEditorStateChange(editorState, 'jobReq'); }}
                        />
                        <label htmlFor="jd_upload">
                          <div className="newjob_upload  d-none">
                            <span>Or</span>
                            <span>Upload a File (.doc, .pdf)</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </AccordionItem>
                <AccordionItem
                  className="p-0 ml-0 accordion-content"
                  title={<div className="bx--type-epsilon text-uppercase xpa-link">{t('jobdocuments')}</div>}
                  open
                  // open={showDescErr}
                >
                  <InlineNotification lowContrast className="w-100 p-0 m-0 mb-2 mt-2" kind="info" title={t('jobDocsMsg')} subtitle="" hideCloseButton onCloseButtonClick={() => { }} />
                  <div className="mt-2 mb-2 bx--type-zeta">
                    {t('jobDocsMsg2')}
                  </div>
                  <div className="d-flex align-items-center">
                    Click here to download training plan
                    <Button
                      kind="ghost"
                      size="small"
                    >
                      <a target="_blank" href={SampleCompany}>
                        Sample
                      </a>
                    </Button>
                  </div>
                  <JobDocuments
                    labelText="Drag and drop files here or click to upload"
                    multiple
                    accept={[
                      'application/pdf',
                      'application/msword',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    ]}
                    updateDocuments={this.handleChangeDocuments}
                    uploadDocument={this.handleUploadDocuments}
                    jobDocuments={this.state.jobDocuments}
                    maxFiles={5}
                  />
                </AccordionItem>
              </Accordion>
              <div className="d-flex justify-content-center">
                <Button
                  kind="tertiary"
                  onClick={this.handleRecdirectJobsPage}
                >
                  {t('cancel')}
                </Button>
                <Button
                  kind="primary"
                  className="ml-2"
                  onClick={this.handleDetailsValidation}
                  disabled={this.state.uploadingDocs}
                >
                  {t('submit')}
                </Button>
              </div>
            </div>
          )
        }
        {
          hideForm === true && (
            <div className="">
              {
                !jobId && (
                  <div className="text-center h3">{t('jobCreatedMsg')}</div>
                )
              }
              {
                jobId && (
                  <div className="text-center h3">{t('editJobSuccess')}</div>
                )
              }
              <div className="align-content-center text-center">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{ maxWidth: 100 }} >
                  <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                  <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>
              </div>
              <div className="m-3 text-center bx--row justify-content-center">
                <div className="m-3">
                  <Button kind="primary" className="" onClick={() => { push && push(`/app/campus/job/${currJobId}`) }}>{t('view')} {t('job')}</Button>
                </div>
              </div>
            </div>
          )
        }
        <Modal
          open={isOpenAddOfcModal}
          modalHeading="Add Office Details"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          onRequestClose={this.dismissAddOdcModal}
          onRequestSubmit={() => this.handleSubmitOfcdetails()}
        >
          <OfficeDetails
            countries={countries}
            handleChange={this.handleChange}
            city={city}
            zipcode={zipcode}
            officeName={officeName}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            country={country}
            isValidAddrLine1={isValidAddrLine1}
            isValidAddrLine1Text={isValidAddrLine1Text}
            isValidCity={isValidCity}
            isValidCityText={isValidCityText}
            isValidCountry={isValidCountry}
            isValidCountryText={isValidCountryText}
            isValidOfficeName={isValidOfficeName}
            isValidOfficeNameText={isValidOfficeNameText}
            isValidZipcode={isValidZipcode}
            isValidZipcodeText={isValidZipcodeText}
            disabled={false}
            location={location}
            handleDropdownChange={this.handleDropdownChange}
            onInputDropdownChange={this.onInputDropdownChange}
            locationsArr={locationsArr}
          />
        </Modal>
        <JDProcessOverlay show={showLoader || loading || this.props.newJobLoader} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.jobs.loading,
  newJobLoader: state.jobCreation.loading,
  countries: state.RegistrationRequest.countries,
  locations: state.RegistrationRequest.locations,
  dropdowns: state.jobCreation && state.jobCreation.dropdowns,
  jobs: state.jobs.jobs,
  cities: state.StudentProfile.cities,
  facultyData: state.candidateDetails.facultyData,
  offices: state.jobCreation.offices,
  workDaysList: state.jobCreation.workDaysList,
  companies: state.BrowseCompanies.companies,
  usersList: state.InstitutionView.usersList,
});

const mapActionsToProps = {
  getBatchDetails,
  getLocations,
  saveJob,
  editJob,
  fetchDropdownData,
  getJobInfo,
  getAuthorizedCompanies,
  xSearchCompanies,
  getCitiesList,
  getAllFaculties,
  addOffice,
  fetchAllOffices,
  getWorkdaysList,
  uploadDocuments,
  fetchAllCompaniesList,
  getCompanyUsersList,
  getCountries,
  parseJdJobReg
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(NewJobReg));
