import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_GET_COMPANY_DETAILS_BY_ID,
  REQUEST_EDIT_COMPANY_DETAILS_BY_ID,
  REQUEST_GET_USERS_LIST,
  REQUEST_GET_STUDENTS_LIST_ADMIN,
  REQUEST_CREATE_FACULTY,
  REQUEST_DELETE_FACULTY,
  REQUEST_EDIT_FACULTY,
  REQUEST_CREATE_COURSE,
  REQUEST_EDIT_COURSE,
  REQUEST_DELETE_COURSE,
  REQUEST_GET_JOB_PROFILE_LIST_MATCHING,
  REQUEST_CREATE_USER_FOR_COMPANY,
  REQUEST_GET_STATUS_MATCHING,
  REQUEST_UPSERT_COMP_ADDL_INFO,
  REQUEST_GET_EMPLOYERS_JOB_INFO,
  REQUEST_PERFORM_JOB_TRANSFER
} from './actionTypes';

const getEmployersJobInfo = data => (dispatch) => {
  const { userId } = data;
  const url = `${baseUrl}/userinfos/${userId}/employersJobInfo`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    // body: JSON.stringify(data),
    actionType: REQUEST_GET_EMPLOYERS_JOB_INFO,
  };
  return callApi(params);
};

const performJobTransfer = data => (dispatch) => {
  const { userId, transferUserId } = data;
  const url = `${baseUrl}/userinfos/${userId}/transferJobs`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify({transferUserId}),
    actionType: REQUEST_PERFORM_JOB_TRANSFER,
  };
  return callApi(params);
}

const getCompanyDetailsById = id => (dispatch) => {
  const url = `${baseUrl}/companies/${id}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_COMPANY_DETAILS_BY_ID,
  };

  return callApi(params);
};

const upsertCompanyDetailsById = (data, id) => (dispatch) => {
  const url = `${baseUrl}/companies/${id}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_COMPANY_DETAILS_BY_ID,
  };

  return callApi(params);
};

const createCourseDetails = data => (dispatch) => {
  const url = `${baseUrl}/courses?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_COURSE,
  };

  return callApi(params);
};

const upsertCourseDetailsById = data => (dispatch) => {
  const {
    courseId,
  } = data || {};
  const url = `${baseUrl}/courses/${courseId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_COURSE,
  };

  return callApi(params);
};

const deleteCourseDetailsById = courseId => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No course id passed',
  };
  if (!courseId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/courses/${courseId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_COURSE,
  };
  return callApi(params);
};

const createFacultyDetails = data => (dispatch) => {
  const url = `${baseUrl}/faculties?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_FACULTY,
  };

  return callApi(params);
};

const upsertFacultyDetailsById = data => (dispatch) => {
  const {
    facultyId,
  } = data || {};
  const url = `${baseUrl}/faculties/${facultyId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_FACULTY,
  };

  return callApi(params);
};

const deleteFacultyDetailsById = facultyId => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No faculty id passed',
  };
  if (!facultyId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/faculties/${facultyId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_FACULTY,
  };
  return callApi(params);
};

const getCompanyUsersList = data => (dispatch) => {
  const {
    usersActivePage,
    usersItemsPerPage,
    usersSearchKey,
    companyId,
    facultyId,
  } = data || {};
  const skip = usersActivePage === 0 ? 0 : (usersActivePage - 1) * usersItemsPerPage;
  let url = `${baseUrl}/companies/usersList?companyId=${companyId}&_=${new Date().getTime()}&`;
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${usersItemsPerPage}&`;
  if (facultyId) {
    url += `facultyId=${facultyId}&`;
  }
  if (usersSearchKey) {
    url += `searchKey=${usersSearchKey}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_USERS_LIST,
  };

  return callApi(params);
};

const getStudentsListById = data => (dispatch) => {
  const {
    id,
    studentsItemsPerPage,
    studentsActivePage,
    studentsSearchKey,
    facultyId,
  } = data || {};
  const skip = studentsActivePage === 0 ? 0 : (studentsActivePage - 1) * studentsItemsPerPage;
  let url = `${baseUrl}/companies/studentslist?instituteId=${id}&_=${new Date().getTime()}&`;
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${studentsItemsPerPage}&`;
  if (facultyId) {
    url += `facultyId=${facultyId}&`;
  }
  if (studentsSearchKey) {
    url += `searchKey=${studentsSearchKey}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_STUDENTS_LIST_ADMIN,
  };

  return callApi(params);
};

const triggerJobProfileList = (institutionId, action, allocNumber) => (dispatch) => {
  const err = {
    message: 'No companyId passed',
  };
  if (!institutionId) {
    Promise.reject(err);
  }
  const url = `${baseUrl}/jobs/matches/${institutionId}?action=${action}&allocNumber=${allocNumber}&_=${new Date().getTime()}&`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_JOB_PROFILE_LIST_MATCHING,
  };

  return callApi(params);
};

const triggerStatusOfMatching = taskId => (dispatch) => {
  const url = `${baseUrl}/geip/tasks/${taskId}?_=${new Date().getTime()}&`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_STATUS_MATCHING,
  };

  return callApi(params);
};

const createUserForACompany = data => (dispatch) => {
  const url = `${baseUrl}/companies/users?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_USER_FOR_COMPANY,
  };

  return callApi(params);
};

const upsertCompanyAddlInfo = data => (dispatch) => {
  const {
    companyId,
  } = data || {};
  if (!companyId) {
    const error = {
      msg: 'Invalid company id',
    };
    Promise.reject(error);
  }
  const url = `${baseUrl}/companyaddinfo/updateaddinfo/${companyId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPSERT_COMP_ADDL_INFO,
  };

  return callApi(params);
};

export {
  getEmployersJobInfo,
  performJobTransfer,
  getCompanyDetailsById,
  upsertCompanyDetailsById,
  getCompanyUsersList,
  getStudentsListById,
  createFacultyDetails,
  deleteFacultyDetailsById,
  upsertFacultyDetailsById,
  createCourseDetails,
  upsertCourseDetailsById,
  deleteCourseDetailsById,
  triggerJobProfileList,
  createUserForACompany,
  triggerStatusOfMatching,
  upsertCompanyAddlInfo,
};
