import { useTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Loading } from 'carbon-components-react';
import FillCircle from 'components/common/FillCircle/FillCircle';
import Avatar from 'components/common/Avatar';
import moment from 'moment';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import '../Dashboard.css';
import SetMixPanel from 'components/common/GetCurrentPath';

const track = {
  ROBOROY_DASHBOARD_OFFER_CLICKED: 'ROBOROY_DASHBOARD_OFFER_CLICKED',
  ROBOROY_DASHBOARD_OFFERJOB_CLICKED: 'ROBOROY_DASHBOARD_OFFERJOB_CLICKED',
};

const OffersView = ({
  handleOffersClick,
  offerTab,
  offersCount,
  handleOffersTabClick,
  offersLoading,
  offers,
  offerStatus,
  dashboard,
  push,
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="mt-2 pb-5">
        <span className="heading-style h6 text-uppercase font-style-heading">
          {t('offers')}
        </span>
        {/* <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={() => handleOffersClick('inprogress')}>
              <div className="d-flex justify-content-around align-items-center p-2">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large'}}>
                    {(offersCount && offersCount['applications-inprogress']) || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz ">{t('offersInProgressTab')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={() => handleOffersClick('completed')}>
              <div className="d-flex justify-content-around align-items-center p-2">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large'}}>
                    {(offersCount && offersCount['applications-completed']) || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz ">{t('completed')}</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div style={{ flex: 1 }}>
          <GenericTabs
            className="d-flex justify-content-end"
            selected={offerTab}
            tabContent={[
              {
                label: `${t('offersInProgressTab')} (${(offersCount && offersCount['applications-inprogress']) || 0})`,
                value: '',
                onClick: (e) => handleOffersTabClick("awaiting")
              },
              {
                label: `${t('completed')}  (${(offersCount && offersCount['applications-completed']) || 0})`,
                value: '',
                onClick: (e) => handleOffersTabClick("completed")
              },
            ]}
          />
        </div>
        {
          offersLoading === true
          && (
            <div className="bx--row m-0 justify-content-center">
              <Loading withOverlay={false} />
            </div>
          )
        }
        {
          offers && Array.isArray(offers)
            && offers.length > 0
            && offersLoading === false
            && offers.map((res, idx) => {
              const {
                application: {
                  appId,
                  job: {
                    jobId,
                    jobname: {
                      nameName,
                    } = {},
                    company: {
                      companyName,
                      displayCompanyName
                    } = {},
                  } = {},
                } = {},
                name,
                startDate,
              } = res || {};
              return (
                <Fragment>
                  <div className="bx--row m-0">
                    <div className="bx--row bx--col-lg-2 bx--col-md-4 bx--col-sm-4 bx--col-xs-6">
                      <div className="ml-2 xpa-avatar-width">
                        <Avatar
                          user={{
                            firstname: name,
                            lastname: null,
                            picture: null,
                          }}
                          classNameInfo="rounded-circle"
                          size="30"
                        />
                      </div>
                      <div className="xpa-name-overflow ml-2 pl-0 bx--col-xl-9 bx--col-lg-7 bx--col-md-7 bx--col-sm-3 bx--col-xs-3 mt-1">
                      <span className="xpa-link font-weight-bold text-capitalize" onClick={() => { SetMixPanel(track.ROBOROY_DASHBOARD_WORKFLOW_CLICKED); push && push(`/app/campus/candidate/${appId}/job/${jobId}/interviewstatus`)}}>{name}</span>
                      </div>
                    </div>
                    <div className="mt-1 ml-lg-2 ml-md-2 bx--col-lg-4 pl-sm-4 pl-md-3">
                    <span className="font-weight-bold text-capitalize xpa-link" onClick={() => { SetMixPanel(track.ROBOROY_DASHBOARD_WORKFLOWJOB_CLICKED); push && push(`/app/campus/job/${jobId}`)}}>{nameName}</span>
                      <span className="text-dark text-normal ml-1">({`${jobId}`})</span>
                    </div>
                    <div className="mt-1 ml-2 text-dark bx--col-lg-4 text-uppercase xpa-name-overflow ml-md-2">
                      {displayCompanyName}
                    </div>
                    <div className="mt-1 pl-lg-4 text-dark pl-sm-3 bx--col-lg-2">
                      <span className="ml-xl-3 ml-md-2 ml-lg-0 ml-sm-2">{moment(startDate).format('DD/MM/YY, hh:mma')}</span>
                    </div>
                  </div>
                  {
                    !(((offers.length - 1) == idx)) && <hr className="mb-2 mt-1" />
                  }
                </Fragment>
              );
            })
        }
        {
          offers && Array.isArray(offers)
            && offers.length <= 0
            && offersLoading === false
            && (
              <div className="card-body text-center">
                <p className="font-style-heading h6">{t('noOfferRounds')}</p>
              </div>
            )
        }
        {
          offerTab === 0 && offerStatus === 'inprogress'
            && offersCount
            && offersCount['applications-inprogress']
            && offersCount['applications-inprogress'] > 5
            ? <div onClick={() => { push && push(`/app/campus/offers/${offerStatus}`) }} className="mt-0"><span className="xpa-link pt-2">{t('more')}</span></div> : null
        }
        {
          offerTab === 1 && offerStatus === 'completed'
            && offersCount
            && offersCount['applications-completed']
            && offersCount['applications-completed'] > 5
            ? <div onClick={() => { push && push(`/app/campus/offers/${offerStatus}`) }} className="mt-0"><span >{t('more')}</span></div> : null
        }
      </div>
      <hr />
    </Fragment>
  );
};

export default OffersView;
