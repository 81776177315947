import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button, Modal, Select, SelectItem, Checkbox,
} from 'carbon-components-react';

import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import {
  InlineNotification
} from 'carbon-components-react';
import CustomCard from 'components/common/CustomCard';
import Avatar from 'components/common/Avatar';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

import {
  getAuthorizedUsers,
  getJobCollaborators,
  addJobCollaborator,
  removeJobCollaborator,
  getAuthorizedUsersForCollaborators
} from 'actions/job';

class JobCollaborators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddCollabModelOpen: false,
    };
  }

  componentDidMount() {
    // this.props.getJobCollaborators(this.props.job && this.props.job.teamId, this.props.job.jobId);
  }

  onAddCollaborator = () => {
    this.setState({
      isAddCollabModelOpen: true,
    });
  }

  onSubmitAddCollaborator = () => {
    this.props.addJobCollaborator({
      jobId: this.props.job && this.props.job.jobId,
      userId: this.state.selectedUserId,
      roleId: 1 /*this.state.selectedRoleId*/
    }).then(() => {
      this.setState({ selectedUserId: 'placeholder-item', /*selectedRoleId: 'placeholder-item'*/ });
      this.props.getJobCollaborators(this.props.job && this.props.job.teamId, this.props.job.jobId);
      this.props.getAuthorizedUsersForCollaborators(this.props.job && this.props.job.teamId, this.props.job.companyId);
      this.onDismissAddCollaborator();
      this.props.getJobTimeLine(this.props.job && this.props.job.jobId);
    });
  }

  validateForm = () => {
    let validationError;

    if ((this.state.selectedUserId === null) || (!this.state.selectedUserId) || (this.state.selectedUserId && this.state.selectedUserId == 'placeholder-item')) {
      validationError = {
        title: 'Please select a user to add as collaborator'
      }
    }
    // if ((this.state.selectedRoleId === null) || (!this.state.selectedRoleId) || (this.state.selectedRoleId && this.state.selectedRoleId == 'placeholder-item')) {
    //   validationError = {
    //     title: 'Please select a role for your collaborator'
    //   }
    // }
    return validationError;
  }

  validateAndSubmitForm = (appId, jobId) => {
    //validation checks
    let validationError = this.validateForm();
    // alert("--- selectedWorkflow = ", this.state.selectedWorkflow);
    if (!validationError) {
      this.setState({ validationError: null }, () => {
        this.onSubmitAddCollaborator();
      });
      return true;
    } else {
      this.setState({ validationError });
    }

    return false;
  }

  onDismissAddCollaborator = () => {
    this.setState({
      isAddCollabModelOpen: false,
    });
  }

  getCompanyUsers = () => {
    const { authorizedUsers = [], companyAuthorizedUsers = [], user = {}, t } = this.props;
    const selectList = [];
    selectList.push(
      <SelectItem
        selected="selected"
        value="placeholder-item"
        text={t('chooseUser')}
      />
    );
    companyAuthorizedUsers && companyAuthorizedUsers.map((eachAuthorizedUser) => {
      const { userInfo } = eachAuthorizedUser || {};
      if (userInfo && userInfo.userId && user && user.userId && (user.userId !== userInfo.userId) && (userInfo.active == true)) {
        selectList.push(
          (userInfo && userInfo.userId && <SelectItem
            value={`${userInfo && userInfo.userId}`}
            text={`${userInfo && userInfo.firstname} ${userInfo && userInfo.lastname} (${userInfo && userInfo.email})`}
          />));
      }
    });

    return selectList;
  }

  getRoles = () => {
    const { roles = [], t } = this.props;
    const rolesList = [];
    rolesList.push(
      <SelectItem
        selected="selected"
        value="placeholder-item"
        text={t('chooseRole')}
      />
    );
    roles && roles.map(r => {
      rolesList.push(
        <SelectItem
          value={`${r.roleId}`}
          text={`${r.roleName}`}
        />)
    });

    return rolesList;
  }

  onRevokeAccess = (teamId, userId, roleId) => {
    this.props.removeJobCollaborator([{
      jobId: this.props.job && this.props.job.jobId,
      userId,
      roleId
    }]).then(() => {
      this.props.getJobCollaborators(this.props.job && this.props.job.teamId, this.props.job && this.props.job.jobId);
      this.props.getAuthorizedUsers(this.props.job && this.props.job.teamId);
      this.props.getJobTimeLine(this.props.job && this.props.job.jobId);
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getMobileRows = () => {
    let collaborators = [];
    if (this.props.collaborators && this.props.collaborators.length > 0) {

      collaborators = this.props.collaborators.map((eachCollaborator) => (
        <div>
          <p>{eachCollaborator.userinfo && eachCollaborator.userinfo.email || '-'}</p>
          <p>{eachCollaborator.userinfo && eachCollaborator.userinfo.roleName || '-'}</p>
          <Button kind="danger" small onClick={() => this.onRevokeAccess(eachCollaborator.teamId, eachCollaborator.userId, eachCollaborator.roleId)}>
              Remove
          </Button>
        </div>
      ));

    }
    return collaborators.join('');

  }
  getRows = () => {
    let collaborators = [];
    if (this.props.collaborators && this.props.collaborators.length > 0) {

      collaborators = this.props.collaborators.map((eachCollaborator) => (
        {
          id: `row_${eachCollaborator.userId}`,
          header_0: (<div><Avatar user={{
            firstname : eachCollaborator.userinfo && eachCollaborator.userinfo.firstname,
            lastname :  eachCollaborator.userinfo && eachCollaborator.userinfo.lastname,
            picture: eachCollaborator.userinfo && eachCollaborator.userinfo.picture
          }} size="30" classNameInfo="rounded-circle mr-1" />
          <span className="pr-1 text-uppercase">{eachCollaborator.userinfo && eachCollaborator.userinfo.firstname ? eachCollaborator.userinfo.firstname : ''}</span>
          <span className="pr-1 text-uppercase">{eachCollaborator.userinfo && eachCollaborator.userinfo.lastname ? eachCollaborator.userinfo.lastname : ''}</span>
          </div>),
          header_1: `${eachCollaborator.userinfo && eachCollaborator.userinfo.email || '-'}`,
          header_2: `${eachCollaborator.userrole && eachCollaborator.userrole.roleName || '-'}`,
          header_3: (
            <Button kind="danger" small onClick={() => this.onRevokeAccess(eachCollaborator.teamId, eachCollaborator.userId, eachCollaborator.roleId)}>
              Remove
            </Button>
          ),
        }
      ));

    }

    return collaborators;
  }

  render() {
    const {
      t,
    } = this.props;
    const otherActionButtons = [(<Button small onClick={this.onAddCollaborator}>{t('add')} {t('collaborator')}</Button>)];
    const columnWithFilter = [
      {
        tid: 'jc-name',
        title: `${t('name')}`,
        className: 'text-left',
        name: 'appId',
        propName: 'appId',
      },
      {
        tid: 'jc-email',
        title: `${t('emailUser')}`,
        className: 'text-center',
        name: 'email',
        propName: 'jobId',
      },
      {
        tid: 'jc-role',
        title: `${t('role')}`,
        className: 'text-center',
        name: 'Role',
        propName: 'jobId',
      },
      {
        tid: 'jc-revoke',
        title: `${t('action')}`,
        className: 'text-center',
        name: 'Revoke',
        propName: 'jobId',
      },
    ];
    return (
      <Fragment>
        <div className="tile-min-height xp-m__jobinfo--private-toggle">
          <div className="bx--row align-items-center">
            <div className="bx--col-lg-12">
              <strong>{t('addCollaborators')}</strong>
              <div>
                <div>
                  <small className="text-dark">{t('collaboratorsMsg1')}</small>
                </div>
                <div>
                  <small className="text-dark">{t('collaboratorsMsg2')}</small>
                </div>
                <div>
                  <small className="text-dark">{t('collaboratorsMsg3')}</small>
                </div>
              </div>
            </div>
          </div>

          <GenericDataTable
            className="d-none d-sm-none d-md-block d-lg-block d-xl-block"
            title=""
            otherActions={otherActionButtons}
            headers={
              columnWithFilter.map((data, index) => (
                {
                  key: `header_${index}`,
                  header: data.title,
                  className: data.className,
                  colpropName: data.propName,
                }
              ))
            }
            rows={this.getRows()}
            customSorting={() => console.log('dd')}
            selectable={false}
            searchable={false}
            expandable={false}
            batchActions={false}
          />
            <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none text-right">
              {otherActionButtons}
            </div>
            {(this.props.collaborators && this.props.collaborators.length > 0) ?
              this.props.collaborators.map((eachCollaborator) => (
                <CustomCard
                  noCard={true}
                  className="card-layout p-0 d-block d-sm-block d-md-none d-lg-none d-xl-none border"
                  title={`${eachCollaborator.userinfo && eachCollaborator.userinfo.firstname ? eachCollaborator.userinfo.firstname : ''} ${eachCollaborator.userinfo && eachCollaborator.userinfo.lastname ? eachCollaborator.userinfo.lastname : ''}`}>
                  <div>
                    {eachCollaborator.userinfo && eachCollaborator.userinfo.email ? <p className="pb-0 mb-1">{eachCollaborator.userinfo.email}</p>: ''}
                    {eachCollaborator.userrole && eachCollaborator.userrole.roleName ? <p className="pb-0 mb-1 text-dark">{eachCollaborator.userrole.roleName}</p>: ''}
                    <Button kind="danger" small onClick={() => this.onRevokeAccess(eachCollaborator.teamId, eachCollaborator.userId, eachCollaborator.roleId)}>
                        Remove
                    </Button>
                  </div>
                </CustomCard>

            )) : ''
          }
          {
            this.props.collaborators && this.props.collaborators.length <= 0 && (
              <div className="">
                <div className="text-center">
                  <div className="empty-icon">
                    <i className="fa fa-flag fa-2x"></i>
                  </div>
                  <p className="empty-title h5">{t('noCollaboratorsMsg')}</p>
                </div>
              </div>
            )
          }
        </div>
        <Modal
          className="jc-add-collab--modal"
          open={this.state.isAddCollabModelOpen}
          danger={false}
          shouldSubmitOnEnter={false}
          modalHeading={t('addCollaboratorToJob')}
          modalLabel="Action"
          modalAriaLabel=""
          primaryButtonText={t('add')}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.onDismissAddCollaborator}
          onRequestSubmit={this.validateAndSubmitForm}
        >
        <div>
          <div className="mb-0 ml-2 text-left text-dark">
            <p style={{fontSize:'14px'}}> Do you see your colleague in the dropdown list below? If no, your colleague might not have registered for an account.
              Please ask him/her to register with the same company UEN on our
                <a href=" https://internship-placement.rp.edu.sg/public/register/company/info"> https://internship-placement.rp.edu.sg/public/register/company/info</a>
            </p>
          </div>
          <div>
          </div>
        </div>
          <div className="bx--grid">
          {this.state.validationError &&
            <InlineNotification lowContrast
                onCloseButtonClick={() => {}}
                className="mt-0"
                title={this.state.validationError.title}
                subtitle=""
                iconDescription="describes the close button"
                kind="error"

              />
            }
            {this.state.isAddCollabModelOpen &&
              (
                <div className="bx--row">
                  <div className="bx--col-xs-6">
                    <Select
                      labelText="Select user"
                      className="mb-0 ml-2"
                      hideLabel={true}
                      invalidText={t('validationErrorMsg')}
                      onChange={this.handleChange}
                      name="selectedUserId"
                      id="jc-user-select"
                      defaultValue={this.state.selectedUserId}
                    >
                      {this.getCompanyUsers()}
                    </Select>
                  </div>
                  {/*<div className="bx--col-xs-6">
                    <Select
                      className="mb-0 ml-2"
                      labelText="Select role"
                      hideLabel={true}
                      name="selectedRoleId"
                      invalidText={t('validationErrorMsg')}
                      onChange={this.handleChange}
                      id="jc-role-select"
                      defaultValue={this.state.selectedRoleId}
                    >
                      {this.getRoles()}
                    </Select>
                  </div>*/}
                </div>
              )}
          </div>


        </Modal>
        <JDProcessOverlay show={((this.props && this.props.loading) || (this.props && this.props.loadingApplication))} message="processing..." />
      </Fragment>);
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.jobs.loading,
  error: state.jobs.error,
  collaborators: state.jobs.jobCollaborators && state.jobs.jobCollaborators.users,
  authorizedUsers: state.jobs.authorizedUsers && state.jobs.authorizedUsers.users,
  companyAuthorizedUsers: state.jobs.companyAuthorizedUsers && state.jobs.companyAuthorizedUsers.users,
  roles: state.jobs.authorizedUsers && state.jobs.authorizedUsers.roles,
});

const mapDispatchToProps = {
  getAuthorizedUsers,
  getJobCollaborators,
  addJobCollaborator,
  removeJobCollaborator,
  getAuthorizedUsersForCollaborators
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobCollaborators));
