import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import { Tile, Button } from 'carbon-components-react';
import {
  getCandidateProfile,
} from 'actions/talentSearch/candidatesList';
import {deleteDocumentById} from 'actions/document';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

class StudentDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
		loading: false
    };
  }

  componentDidMount() {
    const {
      profileId,
    } = this.props;
    if (profileId) {
      this.props.getCandidateProfile(profileId).then(() => {
        this.setRows();
      });
    }
  }

  deleteDocument = (personDocId) => {
     if (personDocId) {
	  this.setState({loading:true},() => {
		  this.setState({loading:false});
		  this.props.deleteDocumentById(personDocId).then(() => {
			window.location.reload();
		  });
	  });
    }
  }
  setRows = (res) => {
    const { candidateProfile: { documents = null } } = this.props;
    const filteredDocs = documents && Array.isArray(documents)
      && documents.map((val) => {
        const {
          documenttype: {
            typeId,
          } = {},
          fileName,
          subLocation,
          personDocId,
        } = val || {};
        const data = {};
        if ((!fileName && typeId === 1) || fileName) {
          data.personDocId = personDocId;
          data.fileName = fileName;
          if (fileName) {
            data.subLocation = subLocation;
          } else {
            const hostName = window.location.hostname;
            let link = '';
            if (hostName.includes('internship-placement.rp.edu.sg')) {
              link = 'https://x0pa-rp-storage.s3-ap-southeast-1.amazonaws.com';
            } else {
              link = 'https://x0pa-rps-storage.s3-ap-southeast-1.amazonaws.com';
            }
            data.subLocation = `${link}/${subLocation}`;
          }
          data.typeId = typeId;
        }
        return data;
      });
    const finalFilterDocs = filteredDocs && Array.isArray(filteredDocs)
      && filteredDocs.length > 0
      && filteredDocs.filter(res => res && (res.personDocId != null || res.personDocId !== undefined));
    if (Array.isArray(finalFilterDocs) && finalFilterDocs.length > 0) {
      const rows = finalFilterDocs.map((one, i) => ({
        id: one.personDocId ? one.personDocId : i,
        name: one.fileName ? one.fileName: '-',
        document: one.typeId == 1 ? 'Resume' : 'Portfolio',
        action: (
			<div>
				<a className="bx--btn bx--btn--sm bx--btn--tertiary mr-2"  target="_blank" href={`${one.subLocation}`}>{this.props.t('view')} {this.props.t('documents')}</a>
				{finalFilterDocs.length > 1 ? (<Button onClick={() => this.deleteDocument(one.personDocId || 0)} kind="danger" small>{this.props.t('delete')}</Button>) : (<span></span>)}
			</div>
			),
      }));
      this.setState({ rows });
    }
  }

  render() {
    const {
      t,
    } = this.props;
    const {
      rows,
    } = this.state;
    const headers = [
      { key: 'name', header: t('name') },
      { key: 'document', header: t('document') },
      { key: 'action', header: t('action') },
    ];
    return (
      <Fragment>
        <div>
          {
            rows && Array.isArray(rows)
              && rows.length > 0
              && (
                <GenericDataTable title={t('documents')} rows={rows} headers={headers} rowClass="text-center p-0 bx--col-12" />
              )
          }
          {(!rows || (Array.isArray(rows) && rows.length === 0)) && (
            <div className="bx--tile bx--type-epsilon pt-2 pb-2 align-items-center d-flex justify-content-center">{t('noDocuments')}</div>
          )}
          <JDProcessOverlay show={this.state.loading} message="processing..." />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  candidateProfile: state.roboroyApplications.candidateProfile,
  loading: state.roboroyApplications.loading,
  neonPoly: false,
});

const mapDispatchToProps = {
  getCandidateProfile,
  deleteDocumentById,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StudentDocuments));