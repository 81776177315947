import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput, Form, Button, InlineNotification, Modal, Loading, Tile,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import {
  employeeEmailConfirmation,
  getEmailConfirmationInfo
} from 'actions/UserEmailConfirmation';

import zxcvbn from 'zxcvbn';

import { SignedInErrorView, SignedInSuccessView, InviteErrorView } from 'components/common/ErrorViews';

class UserEmailConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uLoading: true,
    };
  }

  componentDidMount() {
  }

  render() {
    const { isUserLoggedIn } = this.props;
    return (
      <div>
        {
          !isUserLoggedIn && (
            <Tile className="xp-m__session--container mt-1">
              <Tile className="mb-2 mt-5" style={{'padding': '80px'}}>
                <div className="empty text-center">
                  <p className="empty-title h5">Please Login.</p>
                  <div className="empty-icon">
                    <i className="fa fa-flag fa-2x" />
                  </div>
                  <div>
                    Please login to acknowledge. 
                     <Link
                        className="bx--btn--primary"
                        to="/app/campus/emailconfirmation">
                        Login Here
                      </Link>
                  </div>
                </div>
              </Tile>
            </Tile>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  employeeEmailConfirmation,
  getEmailConfirmationInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEmailConfirmation);
