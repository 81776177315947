import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Cell, ResponsiveContainer, Bar, BarChart, XAxis, YAxis,
  Tooltip, Legend,
} from 'recharts';
import ModifiedRegionsTooltip from './ModifiedRegionsTooltip';

const CustomizedLegend = (props) => {
  const {
    data,
  } = props;
  const cityColors = ['#ffa600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c'];
  return (
    <div className="">
      {
        data && Array.isArray(data)
          && data.length > 0
          && data.map((res, index) => {
            const {
              key,
              count,
            } = res || {};
            return (
              <div className="mr-2">
                <svg className="recharts-surface pr-1 pt-1" width="16" height="16" viewBox="0 0 32 32" version="1.1"><path stroke="none" fill={cityColors[index]} d="M0,4h32v24h-32z" className="recharts-legend-icon"></path></svg>
                <span className="pt-2">{key}</span>
              </div>
            );
          })
      }
    </div>
  );
};

const BarChartAnalytics = (props) => {
  const {
    data, t, value,
    toolTip,
  } = props;
  const cityColors = ['#ffa600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c'];
  console.log('data', data);
  return (
    <Fragment>
      <div className="bx--col-lg-4">
        <div className="bx--type-zeta text-center text-capitalize mb-4">
          {t(`${value}`)}
        </div>
        {
          data && Array.isArray(data)
            && data.length > 0
            ? (
              <Fragment>
                <div className="mb-2">
                  <ResponsiveContainer width={400} height={200} barCategoryGap="10%">
                    <BarChart layout="horizontal" data={data || []}>
                      <XAxis type="category" dataKey="key" />
                      <YAxis type="number" hide />
                      <Tooltip content={<ModifiedRegionsTooltip regionsArr={data} toolTip={toolTip} />} />
                      <Bar barSize={20} dataKey="count" fill="color">
                        {
                          data
                          && Array.isArray(data)
                          && data.length > 0
                          && data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={cityColors[index]} />
                          ))
                        }
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div>
                  <CustomizedLegend data={data} />
                </div>
              </Fragment>
            ) : (
              <div className="text-dark font-weight-bold text-center">{t('noAnalyticsMsg')}</div>
            )
        }
      </div>
    </Fragment>
  );
};

export default (withTranslation()(BarChartAnalytics));
