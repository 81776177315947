import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import classnames from 'classnames';

import 'components/roboroy/CommonModal.css';

class JobDetailsModal extends Component {

  render() {
    const { jobDetails } = this.props;
    const renderHtml = (htmlObject)=> {return <div dangerouslySetInnerHTML={htmlObject} />};
    if (!Object.values(jobDetails).length) return null;
    return (
        <div>
          <div className="modal-body  pl-0">
            <div className="">
              <h5 className="xp-c-text__upper">Overview</h5>
              {jobDetails.jobDesc && (<p className="xpa-format--pre-content">{renderHtml({__html : jobDetails.jobDesc})}</p>)}
              {jobDetails.jobReq && (<div><h5>Job Requirements:</h5><p className="xpa-format--pre-content">{renderHtml({__html : jobDetails.jobReq})}</p></div>)}
              {jobDetails.benefit && (<div><h5>Benefits:</h5><p className="xpa-format--pre-content">{renderHtml({__html : jobDetails.benefit})}</p></div>)}
              {!(jobDetails.jobDesc || jobDetails.jobReq || jobDetails.benefit) && (
                <p>No information provided.</p>
              )}
            </div>
            <div className="">
              <h5 className="xp-c-text__upper">Qualification</h5>
              {jobDetails.qualificationtype &&
                jobDetails.qualificationtype.typeName && (
                  <p className="xp-c-text__justify">
                    {jobDetails.qualificationtype && jobDetails.qualificationtype.typeName}
                  </p>
                )}
              {!(jobDetails.qualificationtype && jobDetails.qualificationtype.typeName) && (
                <p className="xp-c-text__justify">No information provided.</p>
              )}
            </div>
          </div>
        </div>
    );
  }
}

export default JobDetailsModal;
