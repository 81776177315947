import {
  REQUEST_GET_COMPANY_INFO,
  SUCCESS_GET_COMPANY_INFO,
  ERROR_GET_COMPANY_INFO,
  REQUEST_UPDATE_COMPANY_INFO,
  SUCCESS_UPDATE_COMPANY_INFO,
  ERROR_UPDATE_COMPANY_INFO,
} from 'actions/actionTypes';

const initialState = {
  companyInfo: {},
  loading: false,
};


const adminSettings = (state = initialState, action) => {
  const { type, payload } = action;
  let { data } = payload || {};
  if (!data) {
    data = {};
  }
  switch (type) {
    case REQUEST_GET_COMPANY_INFO:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: { ...state.companyInfo, ...data },
        loading: false,
      };
    case ERROR_GET_COMPANY_INFO:
      return {
        ...state,
        loading: false,
      };
    case REQUEST_UPDATE_COMPANY_INFO:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_UPDATE_COMPANY_INFO:
      return {
        ...state,
        companyInfo: { ...state.companyInfo, ...data },
        loading: false,
      };
    case ERROR_UPDATE_COMPANY_INFO:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default adminSettings;
