import {
  SUCCESS_ALL_INDUSTRIES,
  SUCCESS_COMPANY_DATA,
  SUCCESS_ALL_COMPANIES,
  REQUEST_ALL_COMPANIES,
  ERROR_ALL_COMPANIES,
  SUCCESS_CREATE_NEW_COMPANY,
  SUCCESS_CREATE_NEW_STATE,
  SUCCESS_ALL_COUNTRIES,
  SUCCESS_ALL_STATES,
  SUCCESS_COMPANY_OFFICE_DATA,
  SUCCESS_DOMAIN_DATA,
  SUCCESS_AUTHORIZED_COMPANIES,
  REQUEST_AUTHORIZED_COMPANIES,
  REQUEST_ADD_AUTHORIZED_COMPANY,
  SUCCESS_ADD_AUTHORIZED_COMPANY,
  REQUEST_AUTHORIZE_NEWCOMPANIES,
  SUCCESS_AUTHORIZE_NEWCOMPANIES,
  REQUEST_SWITCH_COMPANY,
  SUCCESS_SWITCH_COMPANY,
} from '../../actions/actionTypes';

const initialState = {
  companiesAutocompleteLoading: false,
  companyData: [],
  officeData: [],
  industries: [],
  companies:[],
  states:[],
  countries:[],
  companyId: 1,
  stateId:1,
  companyName: '',
  industryId: '',
  description: '',
  website: '',
  loading:false,
};

const returnCompanyData = (state, payload) => {
  if (payload.length > 1) {
    return {
      ...state,
      companyData: payload,
    };
  }
  const companyData = payload[0];
  return {
    ...state,
    ...companyData,
  };
};

const companyAndOffice = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_ALL_COMPANIES:
      return {
        error: null,
        ...state,
        companiesAutocompleteLoading: true,
      };
    case ERROR_ALL_COMPANIES:
      return {
        ...state,
        companiesAutocompleteLoading: false,
        error: payload.error,
      };    
    case SUCCESS_COMPANY_DATA:
      return returnCompanyData(state, payload);
    case SUCCESS_COMPANY_OFFICE_DATA:
      return {
        ...state,
        officeData: payload,
      };
    case SUCCESS_ALL_INDUSTRIES:
      return {
        ...state,
        industries: payload,
      };
    case SUCCESS_DOMAIN_DATA:
      return {
        ...state,
        domains: payload,
      };
    case SUCCESS_CREATE_NEW_COMPANY:
      return {
        ...state,
        ...payload,
        companyId:payload.companyId
      };
      case SUCCESS_CREATE_NEW_STATE:
      return {
        ...state,
        ...payload,
        stateId:payload.stateId,
      };
    case SUCCESS_ALL_COMPANIES: {
      return {
        ...state,
        companies: payload && Array.isArray(payload) ? payload : [payload],
        companiesAutocompleteLoading: false
      };
    }
    case SUCCESS_ALL_COUNTRIES: {
      return {
        ...state,
        countries: payload,
      };
    }
    case SUCCESS_ALL_STATES: {
      return {
        ...state,
        states: payload,
      };
    }
    case SUCCESS_ADD_AUTHORIZED_COMPANY:{
      return {
        ...state,
        loading:false,
      };
    }
    case SUCCESS_AUTHORIZED_COMPANIES: {
      return {
        ...state,
        authorizedCompanies: payload,
        loading:false,
      };
    }
    case REQUEST_ADD_AUTHORIZED_COMPANY:{
      return {
        ...state,
        loading:true,
      };
    }
    case REQUEST_AUTHORIZED_COMPANIES:{
      return {
        ...state,
        loading:true,
      };
    }
    case REQUEST_AUTHORIZE_NEWCOMPANIES:{
      return {
        ...state,
        loading:true,
      }
    }
    case SUCCESS_AUTHORIZE_NEWCOMPANIES:{
      return {
        ...state,
        loading:false,
        authorizedCompanies: payload,
      }
    }
    case REQUEST_SWITCH_COMPANY:{
      return {
        ...state,
        loading:true,
      }
    }
    case SUCCESS_SWITCH_COMPANY:{
      return {
        ...state,
        loading:false,
        ...payload,
      }
    }
    default:
      return state;
  }
};

export default companyAndOffice;
