import { v4 } from 'node-uuid';
import { locations, stages, jobs, users } from './randomData';

export function randomScore(min = 20, max = 99) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function randomNumber(min = 20, max = 99) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function randomJobId(min = 10000, max = 99999) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function randomExp(min = 7, max = 12) {
  const num = Math.floor(Math.random() * (max - min) + min);
  return `${num}`;
}

export function randomAge(min = 25, max = 40) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function randomSalary(min = 1000, max = 10000) {
  return Math.floor(Math.random() * (max - min) + min);
}

export const randomLocation = () => locations[Math.floor(Math.random() * locations.length)];

export const randomStage = () => stages[Math.floor(Math.random() * stages.length)];

export const randomDay = () => randomNumber(1, 31);
export const randomMonth = () => randomNumber(1, 12);
export const randomYear = () => randomNumber(2015, 2018);
export const randomDate = () => `${randomDay()}/${randomMonth()}/${randomYear()}`;

export const calcBorderColor = (value) => {
  const v = parseInt(value, 10);
  let y;

  if (v < 65) y = 'poor';
  if (v > 65) y = 'avg';
  if (v > 75) y = 'good';
  if (v > 85) y = 'excellent';

  const lut = {
    poor: '#f48184',
    avg: 'orange',
    good: '#75d47e',
    excellent: '#4c9c07',
  };

  return lut[y];
};

export const randomItemFrom = items => items[Math.floor(Math.random() * items.length)];

export const randomJob = () => ({
  id: randomJobId(),
  name: randomItemFrom(jobs),
  keywords: ['Keyword 1', 'Keyword 2'],
  postDate: randomDate(),
  hiringManager: randomItemFrom(users),
});

export const randomCandidate = () => ({
  id: v4(),
  job_id: randomJobId(),
  name: randomItemFrom(users),
  dateApplied: randomDate(),
  internal: Math.random() > 0.5 ? 'Yes' : 'No',
  stage: randomItemFrom(stages),
});

export const xopaScore = () => ({
  id: v4(),
  name: randomItemFrom(users),
  interviewed: Math.random() > 0.5,
  stage: randomItemFrom(stages),
  exp: randomExp(),
  age: randomAge(),
  salary: `SGD ${randomSalary()}`,
  location: randomLocation(),
  cv_relevance: randomNumber(0, 99),
  acceptability: randomScore(),
  hireability: randomScore(),
  loyality: randomScore(),
  performance: randomScore(),
  job_id: randomJobId(),
  dateApplied: randomDate(),
  internal: Math.random() > 0.5 ? 'Yes' : 'No',
});

export const randomUser = () => ({
  id: v4(),
  name: randomItemFrom(users),
  score: randomScore(),
});

export default null;
