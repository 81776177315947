import {
    REQUEST_BATCH_DETAILS,
    SUCCESS_BATCH_DETAILS,
    ERROR_BATCH_DETAILS,
    REQUEST_SET_CURRENT_BATCH,
    SUCCESS_SET_CURRENT_BATCH,
    ERROR_SET_CURRENT_BATCH,
    REQUEST_SET_ACTIVE_BATCH,
    SUCCESS_SET_ACTIVE_BATCH,
    ERROR_SET_ACTIVE_BATCH,
    REQUEST_ADD_NEW_BATCH,
    SUCCESS_ADD_NEW_BATCH,
    ERROR_ADD_NEW_BATCH,
    REQUEST_EDIT_BATCH,
    SUCCESS_EDIT_BATCH,
    ERROR_EDIT_BATCH,
    REQUEST_CLOSE_BATCH_JOBS,
    SUCCESS_CLOSE_BATCH_JOBS,
    ERROR_CLOSE_BATCH_JOBS,
    REQUEST_BATCH_JOBS_LIST,
    SUCCESS_BATCH_JOBS_LIST,
    ERROR_BATCH_JOBS_LIST
} from '../actions/actionTypes';

const initialState = {
    batchDetails: {}
}

const batchDetails = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case REQUEST_BATCH_DETAILS: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_BATCH_DETAILS: {
            return {
                ...state,
                loading: false,
                batchDetails: payload,
            }
        };
        case ERROR_BATCH_DETAILS:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case REQUEST_SET_CURRENT_BATCH: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_SET_CURRENT_BATCH: {
            return {
                ...state,
                loading: false,
            }
        };
        case ERROR_SET_CURRENT_BATCH:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case REQUEST_SET_ACTIVE_BATCH: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_SET_ACTIVE_BATCH: {
            return {
                ...state,
                loading: false,
            }
        };
        case ERROR_SET_ACTIVE_BATCH:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case REQUEST_ADD_NEW_BATCH: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_ADD_NEW_BATCH: {
            return {
                ...state,
                loading: false,
            }
        };
        case ERROR_ADD_NEW_BATCH:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case REQUEST_EDIT_BATCH: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_EDIT_BATCH: {
            return {
                ...state,
                loading: false,
            }
        };
        case ERROR_EDIT_BATCH:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
         case REQUEST_CLOSE_BATCH_JOBS: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_CLOSE_BATCH_JOBS: {
            return {
                ...state,
                loading: false,
            }
        };
        case ERROR_CLOSE_BATCH_JOBS:
            return {
                ...state,
                loading: false,
                error: payload.error,
            }; 
         case REQUEST_BATCH_JOBS_LIST: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_BATCH_JOBS_LIST: {
            return {
                ...state,
                loading: false,
            }
        };
        case ERROR_BATCH_JOBS_LIST:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };           
        default:
            return state;

    }
}

export default batchDetails;