import {
    REQUEST_USERS_USAGE_REPORT,
    SUCCESS_USERS_USAGE_REPORT,
    ERROR_USERS_USAGE_REPORT,
  } from '../../actions/actionTypes';

  const initialState = {
    loading: true,
		error: null,
		userdata: [ ],
  };

  const usageReport = (state = initialState, action) => {
      const { type, payload } = action;
      switch(type) {
        case REQUEST_USERS_USAGE_REPORT:
          return {
             ...state,
              loading: true,
              error: null,
          };
        case SUCCESS_USERS_USAGE_REPORT:
          return {
						...state,
						userdata: payload,
            loading: false,
            error: null,
          };
        case ERROR_USERS_USAGE_REPORT: 
          return {
          	...state,
            loading: false,
            error: payload.error,
          };
        default:
          return state;
        }
	};
	
	export default usageReport;