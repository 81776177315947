import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  Button, TextInput, Modal, Tile,
  Tabs, Tab, Toggle, InlineNotification,
  Select, SelectItem, DatePicker, DatePickerInput, Loading,
} from 'carbon-components-react';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { debounce } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import {
  getUserReport, editUser, createUserSubscription,
  deleteUserSubscription, editUserSubscription,
  sendUserActivationEmail,
} from 'actions/report';
import moment from 'moment';

import {
  xUserRegRequest,
  xSearchCompanies,
  xCreateCompany,
} from 'actions/xpa';

class X0PAUserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      userReport: {},
      selectedCompanyObj: {},
      isDropdownLoading: false,
      notifType: 'success',
      notifShow: false,
      notifMessage: 'default message',
      isSubmitLoading: false,
      isModalLoading: false,
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails = () => {
    const { params: { userId } = {} } = this.props.match;
    if (userId) {
      this.props.getUserReport(userId || 0)
        .then((res) => {
          const {
            firstname, lastname, email,
            active: isUserActive, isAdmin: isUserAdmin,
            company,
          } = res || {};
          const { companyId, companyName } = company || {};
          this.setState({
            firstName: firstname,
            lastName: lastname,
            email,
            isUserActive,
            isUserAdmin,
            userReport: res,
            selectedCompanyObj: {
              label: companyName,
              value: companyId,
            },
          });
        })
        .catch(err => console.error(err));
    }
  }

  activateModal = (name, extraStateData = {}) => {
    let modalHeading = 'Default Heading';
    let modalPrimaryButtonText = 'Submit';
    let isDangerModal = false;
    switch (name) {
      case 'edit-user':
        modalHeading = 'Edit user';
        modalPrimaryButtonText = 'Edit user';
        isDangerModal = false;
        break;
      case 'delete-user':
        modalHeading = 'Edit user';
        modalPrimaryButtonText = 'Edit user';
        isDangerModal = true;
        break;
      case 'add-subscription':
        modalHeading = 'Add subscription';
        modalPrimaryButtonText = 'Add subscription';
        isDangerModal = false;
        break;
      case 'edit-subscription':
        modalHeading = 'Edit subscription';
        modalPrimaryButtonText = 'Edit subscription';
        isDangerModal = false;
        break;
      case 'delete-subscription':
        modalHeading = 'Delete subscription';
        modalPrimaryButtonText = 'Delete subscription';
        isDangerModal = true;
        break;
      default:
        break;
    }
    const extraState = extraStateData || {};
    this.setState({
      modalHeading,
      modalPrimaryButtonText,
      isDangerModal,
      isModalOpen: true,
      activeModalName: name,
      ...extraState,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModalName: 'none',
      isModalOpen: false,
      subStartDate: '',
      subEndDate: '',
      selectedProduct: 'no-product',
      subStartDateInvalid: false,
      subEndDateInvalid: false,
      activeUserSubscriptionId: 0,
      isModalLoading: false,
    });
  };

  onInputChange = (evt) => {
    if (evt && evt.target) {
      const { name, value } = evt.target;
      this.setState({
        [`${name}Invalid`]: false,
        [name]: value,
      });
    }
  }

  isInputValid = (input) => {
    if (!input || (input && !input.trim())) {
      return false;
    }
    return true;
  }

  onFormSubmit = () => {
    const {
      firstName,
      lastName,
      selectedCompanyObj,
      email,
    } = this.state;
    const { label: companyName, value: companyId } = selectedCompanyObj || {};
    const invalidObj = {};
    let isInvalid = false;
    const validFirstName = this.isInputValid(firstName);
    if (!validFirstName) {
      invalidObj.firstNameInvalid = true;
      isInvalid = true;
    }
    const validLastName = this.isInputValid(lastName);
    if (!validLastName) {
      invalidObj.lastNameInvalid = true;
      isInvalid = true;
    }
    const validCompanyName = this.isInputValid(companyName);
    if (!validCompanyName) {
      invalidObj.companyNameInvalid = true;
      isInvalid = true;
    }
    const validEmail = this.isInputValid(email);
    if (!validEmail || !isEmail(email)) {
      invalidObj.emailInvalid = true;
      isInvalid = true;
    }
    const loadState = {
      notifShow: false,
      isSubmitLoading: true,
    };
    return { ...invalidObj, ...loadState, isInvalid };
  }

  onInputCompanyDropdown = (value) => {
    if (/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value)) {
      if (value.length >= 2) {
        return this.props.xSearchCompanies(value).then((res) => {
          if (res && Array.isArray(res)) {
            const options = res.map(one => ({ label: one.company_name, value: one.company_id }));
            return options;
          }
          return [{ label: 'X', value: -1 }];
        });
      }
    }
  };

  onCreateCompany = (com) => {
    this.setState({
      isDropdownLoading: true,
    });
    if (!com) {
      return;
    }
    this.props.xCreateCompany(com.toLowerCase()).then((newCompany) => {
      const { companyName, companyId, error } = newCompany || {};
      if (error) {
        console.error(error);
        return;
      }
      this.setState({
        selectedCompanyObj: { label: companyName, value: companyId },
        companyNameInvalid: false,
      });
    }).finally(() => {
      this.setState({
        isDropdownLoading: false,
      });
    });
  }

  handleChangeCompany = (e) => {
    this.setState({
      selectedCompanyObj: e,
      companyNameInvalid: false,
    });
  }

  handleCloseNotification = () => {
    this.setState({
      notifShow: false,
      notifType: 'success',
      notifMessage: 'default message',
    });
  }

  onToggle = (value, name) => {
    this.setState({
      [`${name}Invalid`]: false,
      [name]: value,
    });
  }

  renderEditUserContent = () => {
    const {
      firstNameInvalid, lastNameInvalid,
      companyNameInvalid, isDropdownLoading, emailInvalid,
      userReport, firstName, lastName,
      email, isUserActive, isUserAdmin, selectedCompanyObj,
    } = this.state;
    const {
      userrole,
    } = userReport || {};
    const { roleId } = userrole || {};
    const selectedCompany = selectedCompanyObj;
    return (
      <div>
        <TextInput
          required
          id="firstName"
          className="mb-2"
          name="firstName"
          labelText="First Name"
          placeholder="Jane"
          invalid={firstNameInvalid}
          invalidText="Please enter first name"
          onChange={this.onInputChange}
          value={firstName}
        />
        <TextInput
          required
          id="lastName"
          className="mb-2"
          name="lastName"
          labelText="Last Name"
          placeholder="Doe"
          invalid={lastNameInvalid}
          invalidText="Please enter last name"
          onChange={this.onInputChange}
          value={lastName}
        />
        <div className="xpa-dp-wrap">
          <label htmlFor="companyName" className="bx--label">Company</label>
          <AsyncCreatableSelect
            id="companyName"
            name="companyName"
            className={companyNameInvalid ? 'xpa-rs-wrap invalid mb-2' : 'xpa-rs-wrap mb-2'}
            classNamePrefix="xrs"
            placeholder="Select company"
            isDisabled={isDropdownLoading}
            isClearable
            noOptionsMessage={({ inputValue }) => !inputValue && 'Start typing to search companies'}
            loadOptions={debounce(this.onInputCompanyDropdown, 150)}
            onCreateOption={this.onCreateCompany}
            onChange={this.handleChangeCompany}
            value={selectedCompany}
          />
          <div className="bx--form-requirement" id="companyName-error-msg">Please select company</div>
        </div>
        <TextInput
          required
          id="email"
          className="mb-2"
          type="email"
          name="email"
          labelText="Email"
          placeholder="janedoe@acme.com"
          invalid={emailInvalid}
          invalidText="Please enter valid email"
          onChange={this.onInputChange}
          value={email}
        />
        {
          (roleId !== 3) && (
            <Fragment>
              <div className="bx--label">Is admin?</div>
              <Toggle
                name="isUserAdmin"
                defaultToggled={isUserAdmin}
                onToggle={this.onToggle}
                labelA="No"
                labelB="Yes"
                id="isUserAdmin"
              />
              <div className="bx--label">Is user active?</div>
              <Toggle
                name="isUserActive"
                defaultToggled={isUserActive}
                onToggle={this.onToggle}
                labelA="No"
                labelB="Yes"
                id="isUserActive"
              />
            </Fragment>
          )
        }
      </div>
    );
  }

  onEditUser = () => {
    const { params: { userId } = {} } = this.props.match;
    const {
      email: newEmail,
      firstName,
      lastName,
      isUserActive: newActiveStatus,
      isUserAdmin: newIsAdminStatus,
      userReport, selectedCompanyObj,
    } = this.state;
    const {
      active, isAdmin, email, firstname, lastname, company,
    } = userReport || {};
    const { companyId } = company || {};
    const { value: newCompanyId, label: companyName } = selectedCompanyObj || {};
    const data = {
      userId: Number(userId),
    };
    if (newEmail && (email !== newEmail)) {
      data.email = newEmail;
    }
    if (firstName && (firstname !== firstName)) {
      data.firstname = firstName;
    }
    if (lastName && (lastname !== lastName)) {
      data.lastname = lastName;
    }
    if ([true, false].includes(newActiveStatus) && (active !== newActiveStatus)) {
      data.active = newActiveStatus;
    }
    if ([true, false].includes(newIsAdminStatus) && (isAdmin !== newIsAdminStatus)) {
      data.isAdmin = newIsAdminStatus;
    }
    if (companyId != newCompanyId) {
      data.companyId = newCompanyId;
    }
    const formValidObj = this.onFormSubmit();
    const { isInvalid = true, ...restFormState } = formValidObj || {};
    if (isInvalid) {
      this.setState(restFormState);
    } else if (Object.keys(data).length > 1) {
      this.props.editUser(data)
        .then((res) => {
          const {
            firstname: firstNameRes, lastname: lastNameRes, email: emailRes,
            active: isUserActive, isAdmin: isUserAdmin, companyId: companyIdRes,
          } = res || {};
          const newState = {
            firstName: firstNameRes,
            lastName: lastNameRes,
            email: emailRes,
            isUserActive,
            isUserAdmin,
            activeModalName: 'none',
            isModalOpen: false,
            notifShow: true,
            notifType: 'success',
            notifMessage: 'Editing is completed successfully.',
            isModalLoading: false,
          };
          if (companyId != newCompanyId) {
            newState.selectedCompanyObj = {
              value: companyIdRes,
              label: companyName,
            };
          }
          this.setState(newState);
        })
        .catch((err) => {
          this.setState({
            activeModalName: 'none',
            isModalOpen: false,
            notifShow: true,
            notifType: 'error',
            notifMessage: 'Error occured.',
            isModalLoading: false,
          });
          console.error(err);
        });
    } else {
      this.setState({
        activeModalName: 'none',
        isModalOpen: false,
        notifShow: true,
        notifType: 'info',
        notifMessage: 'Nothing changed.',
        isModalLoading: false,
      });
    }
  }

  onDatePickerChange = (dateArr) => {
    const [startDate, endDate] = dateArr || [];
    let subStartDate;
    let subEndDate;
    if (startDate) {
      subStartDate = moment(startDate);
      if (subStartDate.isValid()) {
        subStartDate = subStartDate.format('DD-MM-YYYY');
      }
    }

    if (endDate) {
      subEndDate = moment(endDate);
      if (subEndDate.isValid()) {
        subEndDate = subEndDate.format('DD-MM-YYYY');
      }
    }
    this.setState({
      subStartDate,
      subEndDate,
      subStartDateInvalid: false,
      subEndDateInvalid: false,
    });
  }

  renderUpsertSubscriptionForm = (isEditing = false) => {
    let selectedProduct = 'no-product';
    let selectedStartDate = moment().format('DD/MM/YYYY');
    let selectedEndDate = moment().add('7', 'day').format('DD/MM/YYYY');
    if (isEditing) {
      const { activeSubscription } = this.state;
      const { usersubscriptionId, productsubscription } = activeSubscription || {};
      const { product, subscription } = productsubscription || {};
      const { startDate, endDate } = subscription || {};
      const { productName } = product || {};
      selectedProduct = productName;
      selectedStartDate = moment(startDate).format('DD/MM/YYYY');
      selectedEndDate = moment(endDate).format('DD/MM/YYYY');
    }
    const { subStartDateInvalid, subEndDateInvalid, productNameInvalid } = this.state;

    return (
      <div>
        {
          !isEditing && (
            <Select
              invalidText="A valid value is required"
              onChange={(e) => {
                this.setState({ selectedProduct: (e && e.target && e.target.value), productNameInvalid: false });
              }}
              id="add-sub-select"
              defaultValue="no-product"
              labelText="Choose a product"
              invalid={productNameInvalid}
            >
              <SelectItem
                disabled
                hidden
                value="no-product"
                text="--None--"
              />
              <SelectItem
                value="ROBOROY"
                text="X0PA FULL"
              />
              <SelectItem
                value="X0PA EXT"
                text="X0PA EXTENSION"
              />
              <SelectItem
                value="ENTERPRISE"
                text="ENTERPRISE"
              />
            </Select>
          )
        }
        {
          isEditing && (
            <div className="bx--label">Product: {selectedProduct}</div>
          )
        }
        <DatePicker
          id="sub-rangedate-picker"
          className="mt-4"
          onChange={this.onDatePickerChange}
          datePickerType="range"
          iconDescription=""
          minDate={moment().toISOString()}
          maxDate={moment().add(1, 'year').toISOString()}
          dateFormat="d/m/Y"
        >
          <DatePickerInput
            id="date-picker-input-id-start"
            labelText="Start date"
            pattern="d{1,2}/d{4}"
            placeholder="dd/mm/yyyy"
            invalidText="Please select start date"
            iconDescription=""
            onClick={() => {}}
            onChange={() => {}}
            invalid={subStartDateInvalid}
          />
          <DatePickerInput
            id="date-picker-input-id-end"
            labelText="End date"
            pattern="d{1,2}/d{4}"
            placeholder="dd/mm/yyyy"
            invalidText="Please select start date"
            iconDescription=""
            onClick={() => {}}
            onChange={() => {}}
            invalid={subEndDateInvalid}
          />
        </DatePicker>
      </div>
    );
  }

  renderAddSubscription = () => {
    return this.renderUpsertSubscriptionForm(false);
  }

  renderEditSubscription = () => {
    return this.renderUpsertSubscriptionForm(true);
  }

  onAddSubscription = () => {
    const { params: { userId } = {} } = this.props.match;
    const { selectedProduct, subStartDate, subEndDate } = this.state;
    const data = {
      userId,
      startDate: subStartDate,
      endDate: subEndDate,
      productName: selectedProduct,
    };
    if (selectedProduct && selectedProduct !== 'no-product' && subStartDate && subEndDate) {
      this.setState({
        isModalLoading: true,
      }, () => {
        this.props.createUserSubscription(data)
          .then((res) => {
            const { error } = res || {};
            if (error) {
              throw error;
            }
            this.setState({
              activeModalName: 'none',
              isModalOpen: false,
              notifShow: true,
              notifType: 'success',
              notifMessage: 'Subscription is added successfully.',
              selectedProduct: 'no-product',
              subStartDate: '',
              subEndDate: '',
              isModalLoading: false,
            }, () => {
              this.getUserDetails();
            });
          })
          .catch((err) => {
            console.error(err);
            const { code, message } = err || {};
            let errorMessage = 'Error occured while adding subscription.';
            if (message) {
              errorMessage = message;
            }
            if (code === 'x0pa-29') {
              errorMessage = 'Subscription already exists within the given period.';
            }
            this.setState({
              activeModalName: 'none',
              isModalOpen: false,
              notifShow: true,
              notifType: 'error',
              notifMessage: errorMessage,
              selectedProduct: 'no-product',
              subStartDate: '',
              subEndDate: '',
              isModalLoading: false,
            });
          });
      });
    } else {
      const invalidState = {};
      if (!selectedProduct || selectedProduct === 'no-product') {
        invalidState.productNameInvalid = true;
      }
      if (!subStartDate) {
        invalidState.subStartDateInvalid = true;
      }
      if (!subEndDate) {
        invalidState.subEndDateInvalid = true;
      }
      this.setState(invalidState);
    }
  }

  renderDeleteSubscription = () => {
    return (
      <div>
        Are you sure you want to delete the user subscription?
      </div>
    );
  }

  submitModalRequest = () => {
    const { activeModalName } = this.state;
    switch (activeModalName) {
      case 'edit-user':
        this.onEditUser();
        break;
      case 'add-subscription':
        this.onAddSubscription();
        break;
      case 'edit-subscription':
        this.handleEditUserSubscription();
        break;
      case 'delete-subscription':
        this.handleDeleteUserSubscription();
        break;
      default:
        break;
    }
  }

  handleDeleteUserSubscription = () => {
    const { params: { userId } = {} } = this.props.match;
    const { activeUserSubscriptionId, subStartDate, subEndDate } = this.state;
    const data = {
      userId,
      startDate: subStartDate,
      endDate: subEndDate,
      userSubscriptionId: activeUserSubscriptionId || 0,
    };
    this.setState({
      isModalLoading: true,
    }, () => {
      this.props.deleteUserSubscription(data)
        .then((res) => {
          const { error } = res || {};
          if (error) {
            throw error;
          }
          this.setState({
            activeModalName: 'none',
            isModalOpen: false,
            notifShow: true,
            notifType: 'success',
            notifMessage: 'Subscription is deleted successfully.',
            activeUserSubscriptionId: 0,
            subStartDate: '',
            subEndDate: '',
            isModalLoading: false,
          }, () => {
            this.getUserDetails();
          });
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            activeUserSubscriptionId: 0,
            activeModalName: 'none',
            isModalOpen: false,
            notifShow: true,
            notifType: 'error',
            notifMessage: 'Error occured while deleting the subscription.',
            subStartDate: '',
            subEndDate: '',
            isModalLoading: false,
          });
        });
    });
  }

  handleEditUserSubscription = () => {
    const { params: { userId } = {} } = this.props.match;
    const {
      activeUserSubscriptionId, subStartDate, subEndDate,
    } = this.state;
    const data = {
      userId,
      userSubscriptionId: activeUserSubscriptionId || 0,
      startDate: subStartDate,
      endDate: subEndDate,
    };
    if (activeUserSubscriptionId && subStartDate && subEndDate) {
      this.setState({
        isModalLoading: true,
      }, () => {
        this.props.editUserSubscription(data)
          .then((res) => {
            console.log(res);
            const { error } = res || {};
            if (error) {
              throw error;
            }
            this.setState({
              activeUserSubscriptionId: 0,
              activeModalName: 'none',
              isModalOpen: false,
              notifShow: true,
              notifType: 'success',
              notifMessage: 'Subscription is edited successfully.',
              subStartDate: '',
              subEndDate: '',
              isModalLoading: false,
            }, () => {
              this.getUserDetails();
            });
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              activeUserSubscriptionId: 0,
              activeModalName: 'none',
              isModalOpen: false,
              notifShow: true,
              notifType: 'error',
              notifMessage: 'Error occured while editing the subscription.',
              subStartDate: '',
              subEndDate: '',
              isModalLoading: false,
            });
          });
      });
    } else {
      const invalidState = {};
      if (!subStartDate) {
        invalidState.subStartDateInvalid = true;
      }
      if (!subEndDate) {
        invalidState.subEndDateInvalid = true;
      }
      this.setState(invalidState);
    }
  }

  sendActivationEmail = () => {
    const { params: { userId } = {} } = this.props.match;
    const {
      isUserActive, email,
    } = this.state;
    this.props.sendUserActivationEmail({
      isUserActive,
      email,
      userId: Number(userId),
    })
      .then((res) => {
        console.log(res);
        const { error } = res || {};
        if (error) {
          throw error;
        }
        this.setState({
          notifShow: true,
          notifType: 'success',
          notifMessage: 'User activation email sent successfully.',
        });
      })
      .catch((err) => {
        console.error(err);
        const { message } = err || {};
        this.setState({
          notifShow: true,
          notifType: 'error',
          notifMessage: message || 'Error occured while sending email.',
        });
      });
  }

  isValidProduct = (productName) => {
    const products = ['ROBOROY', 'X0PA EXT', 'ENTERPRISE', 'X0PA FULL'];
    if (!productName) {
      return false;
    }
    if (products.includes(productName)) {
      return true;
    }
    return false;
  }

  render() {
    const {
      notifShow, notifType, notifMessage,
      userReport, isModalOpen, activeModalName,
      modalHeading, modalPrimaryButtonText, isDangerModal,
      isUserActive, isUserAdmin, email, isModalLoading,
    } = this.state;
    const {
      firstname, lastname, company, userrole, subscriptions, sharinghash,
    } = userReport || {};
    const { companyName } = company || {};
    const { roleId, roleName } = userrole || {};
    let [requestedProduct, tempPass] = (sharinghash && sharinghash.split('%PA0X%')) || ['NONE', 'X0PAINVALIDTEMPAPSS'];
    if (!this.isValidProduct(requestedProduct)) {
      requestedProduct = 'NONE';
    }
    if (!tempPass) {
      tempPass = 'X0PAINVALIDTEMPAPSS';
    }

    return (
      <Fragment>
        <div className="mt-2 mb-2">
          {
            notifShow && (
              <InlineNotification lowContrast
                className="mb-0 mt-0"
                title={notifMessage}
                subtitle=""
                kind={notifType}
                iconDescription="Closes the notification"
                onCloseButtonClick={this.handleCloseNotification}
              />
            )
          }
        </div>
        <h4 className="bx--type-gamma font-weight-bold text-capitalize">User Details</h4>
        <Tile>
          <p className="bx--type-delta bx--type-caps">{firstname} {lastname}</p>
          <p>Email: {email}</p>
          <p>Company: {companyName}</p>
          <p>Role: {roleName}</p>
          <p>Active: {isUserActive ? 'Active' : 'Inactive'}</p>
          <p>Admin: {isUserAdmin ? 'Yes' : 'No'}</p>
          {
            (requestedProduct !== 'NONE') && (
              <div>
                <p><b>Requested Product: {requestedProduct}</b></p>
                <p>Temporary Pass: {`X${tempPass}`}</p>
              </div>
            )
          }
          {
            (tempPass === 'X0PAINVALIDTEMPAPSS') && sharinghash && (requestedProduct === 'NONE') && !isUserActive && (
              <p className="text-danger"><b>There might be an error while registering this user.</b></p>
            )
          }
          <Button
            onClick={() => this.activateModal('edit-user')}
            className="mr-2"
            kind="tertiary"
            small
          >
          Edit user
          </Button>
          {
            isUserActive && (
              <Button
                onClick={this.sendActivationEmail}
                className="mr-2"
                kind="tertiary"
                small
              >
              Send Activation Email
              </Button>
            )
          }
        </Tile>
        <Tabs>
          {
            (roleId !== 3) && (
              <Tab label="Subscriptions">
                <Tile>
                  <Button
                    small
                    onClick={() => this.activateModal('add-subscription')}
                  >
                  Add subscription
                  </Button>
                  {
                    subscriptions && Array.isArray(subscriptions) && (subscriptions.length > 0) && (
                      <div className="d-flex flex-wrap">
                        {
                          subscriptions.map((usub) => {
                            const { usersubscriptionId, productsubscription } = usub || {};
                            const { product, subscription } = productsubscription || {};
                            const { startDate, endDate } = subscription || {};
                            const { productName } = product || {};
                            return (
                              <div key={usersubscriptionId} className="p-2 mt-2 mr-2 border minw-250">
                                <div>
                                  <div>Product: {productName}</div>
                                  <div>StartDate: {moment(startDate).format('Do MMM YYYY')}</div>
                                  <div>EndDate: {moment(endDate).format('Do MMM YYYY')}</div>
                                </div>
                                <div className="mt-2">
                                  <Button
                                    className="mr-2"
                                    kind="tertiary"
                                    small
                                    onClick={() => {
                                      const extraState = {
                                        activeUserSubscriptionId: usersubscriptionId || 0,
                                        activeSubscription: usub,
                                      };
                                      this.activateModal('edit-subscription', extraState);
                                    }}
                                  >
                                  Edit
                                  </Button>
                                  <Button
                                    kind="danger"
                                    small
                                    onClick={() => {
                                      const extraState = {
                                        activeUserSubscriptionId: usersubscriptionId || 0,
                                      };
                                      this.activateModal('delete-subscription', extraState);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    )
                  }
                </Tile>
              </Tab>
            )
          }
        </Tabs>
        <Modal
          open={isModalOpen}
          modalHeading={modalHeading}
          primaryButtonText={modalPrimaryButtonText}
          secondaryButtonText="Cancel"
          onRequestClose={this.dismissModal}
          onRequestSubmit={this.submitModalRequest}
          onSecondarySubmit={this.dismissModal}
          danger={isDangerModal}
          disabled
        >
          <div />
          { isModalLoading && <Loading withOverlay={false} /> }
          { !isModalLoading && (activeModalName === 'edit-user') && this.renderEditUserContent() }
          { !isModalLoading && (activeModalName === 'add-subscription') && this.renderAddSubscription() }
          { !isModalLoading && (activeModalName === 'edit-subscription') && this.renderEditSubscription() }
          { !isModalLoading && (activeModalName === 'delete-subscription') && this.renderDeleteSubscription() }
        </Modal>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.usageReport.loading,
  userdata: state.usageReport,
});

const mapDispatchToProps = {
  getUserReport,
  xUserRegRequest,
  xSearchCompanies,
  xCreateCompany,
  editUser,
  createUserSubscription,
  deleteUserSubscription,
  editUserSubscription,
  sendUserActivationEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(X0PAUserView);
