import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TextInput, Select, SelectItem, Loading, RadioButton,
  RadioButtonGroup, InlineNotification, Button, Modal, Form,
} from 'carbon-components-react';
import './workflowStages.css';

import {
  getAllUserWorkflow,
  getWorkflowStages,
  createWorkflow,
  cloneWorkflow,
  assignWorkflowToJob,
  updateStageConfigs,
} from 'actions/workflow';

import { isVideoInterviewStageInStages } from './workflowUtils';

class CreateWorkflowModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      useTemplate: null,
      selectedWorkflow: null,
      workflowName: '',
      currentSelectedStages: [],
      selectedStage: null,
      validationError: null,
    };
  }

  // componentDidMount() {
  //         this.props.getAllUserWorkflow();
  //         this.props.getWorkflowStages();
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.appId !== prevProps.appId) {
      this.setState({
        open: true,
        useTemplate: null,
        selectedWorkflow: null,
        workflowName: '',
        currentSelectedStages: [],
        selectedStage: null,
        validationError: null,
      });
    }
  }

  getAllUserWorkflows = () => {
    const { workflows: { workflows = [] } = {} } = this.props;
    return workflows;
  }

  getStages = () => {
    const { workflows: { workflowstages = [] } = {}, isAutoPilotMode, appId, jobId } = this.props;
    let stageTypeId = -1;
    if (this.state.currentSelectedStages && this.state.currentSelectedStages.stageTypeId) {
        stageTypeId = this.state.currentSelectedStages.stageTypeId;
    }
    // console.log("workflowstages = ", workflowstages);

    // if (isAutoPilotMode) {
    //     if (this.state.currentSelectedStages && (this.state.currentSelectedStages.length <= 0)) {
    //         //nothing in currentselected stage, so blindly add video interview stage
    //         let videoInterviewStageFromList = workflowstages.filter(eachWorkflowStage => { 
    //             let returnVal = false; 
    //             if (eachWorkflowStage.stageTypeId == 3000) {
    //                 returnVal = true;
    //             }                    
    //             return returnVal;
    //         });
    //         if (videoInterviewStageFromList && videoInterviewStageFromList.length > 0) {
    //             let stageSelectedByDefault = videoInterviewStageFromList[0];
    //             stageSelectedByDefault.stagesCount = 1;
    //             this.setState({currentSelectedStages : [stageSelectedByDefault]});
    //         }
    //     }
    // }

    let remainingStages = workflowstages.filter((eachWorkflowStage) => { 
      let returnVal = true;
      for (let i = 0; i < this.state.currentSelectedStages.length; i++) {
        if (this.state.currentSelectedStages[i].stageTypeId == eachWorkflowStage.stageTypeId) {
          returnVal = false;
          break;
        }
      }
      return returnVal;
    });
    if (!appId && jobId) {
      remainingStages = remainingStages.filter(res => res && res.stageTypeId && Number(res.stageTypeId) !== 3007);
    }
    // console.log("remainingStages = ", remainingStages);
    return remainingStages;
  }

  getCurrentSelection = () => {
    return this.state.currentSelectedStages;
  }

  handleOnTitleChange = (e) => {
    this.setState({
      workflowName: e.target.value,
    });
  }

  onExistingWorkflowSelectChange = (e) => {
    this.setState({
      selectedWorkflow: this.getExistingWorkflow(e.target.value),
    });
  }

  onStageSelectChange = (e) => {
    this.setState({
      selectedStage: this.getSelectedStageInfo(e.target.value),
    });
  }

  onStageTypeCountSelectChange = (e) => {
    let typeIdandCount = e.target.value;
    let typeId = typeIdandCount.split('_')[0];
    let count = typeIdandCount.split('_')[1];     

    let currentSelectedStages = this.state.currentSelectedStages;
    currentSelectedStages = currentSelectedStages.map((eachWorkflowStage) => {
      if ((eachWorkflowStage && eachWorkflowStage.stageTypeId) == typeId) {
        eachWorkflowStage.stagesCount = parseInt(count, 10);
      }
      return eachWorkflowStage;
    });
    // console.log("--- currentSelectedStage = ", currentSelectedStages, typeIdandCount);
    this.setState({ currentSelectedStages });
  }

  getSelectedStageInfo = (stageTypeId) => {
    const { workflows: { workflowstages = [] } = {} } = this.props;
    const result = workflowstages.filter(eachWorkflowStage => eachWorkflowStage && eachWorkflowStage.stageTypeId == stageTypeId);
    if (result && result[0]) {
      result[0].stagesCount = 1;
    }
    // console.log("--- workflowstagesworkflowstagesworkflowstages", workflowstages, stageTypeId, result);
    return result && result[0];
  }

  addCurrentSelectedStagetoList = () => {
    if (this.state.selectedStage) {
      let currentSelectedStages = this.state.currentSelectedStages;
      currentSelectedStages.push(this.state.selectedStage);
      this.setState({ currentSelectedStages, selectedStage: null, validationError: null });    
    } else {
      this.setState({
        validationError: {
          title: 'Please select a valid interview substage.',
        },
      });
    }
  }

  removeFromCurrentSelectedStages = (stageTypeId) => {
    let currentSelectedStages = this.state.currentSelectedStages;
    currentSelectedStages = currentSelectedStages.filter(eachWorkflowStage => eachWorkflowStage && eachWorkflowStage.stageTypeId !== stageTypeId);
    this.setState({ currentSelectedStages });
  }

  getExistingWorkflow = (workflowId) => {
    const { workflows: { workflows = [] } = {} } = this.props;
    const result = workflows.filter(eachWorkflow => eachWorkflow && eachWorkflow.workflowId == workflowId);
    return result && result[0];
  }

  handleWorkflowOptionChange =(e) => {
    switch (e) {
      case 'new':
        this.setState({
          useTemplate: false,
        });
        break;
      case 'template':
        this.setState({
          useTemplate: true,
        });
        break;
      default:
    }
  }

  validateForm = () => {
    let validationError;
    // console.log("this.state.useTemplate = ", this.state.useTemplate);
    if (this.state.useTemplate === null) {
      validationError = {
        title: 'Please select an option to create workflow from.',
      };
    }
    if ((this.state.useTemplate) && (!this.state.selectedWorkflow)) {
      validationError = {
        title: 'Please select a workflow to clone',
      };
    }
    if (!(this.state.useTemplate) && (this.state.currentSelectedStages && (this.state.currentSelectedStages.length <= 0))) {
      validationError = {
        title: 'Please add atleast one interview stage',
      };
    }
    if (!this.state.workflowName || (this.state.workflowName && (!this.state.workflowName.trim()) && this.state.workflowName.trim().length <= 0)) {
      validationError = {
        title: 'Please enter a valid workflow name',
      };
    }
    return validationError;
  }

  validateAndSubmitForm = (appId, jobId) => {
    // validation checks
    const validationError = this.validateForm();
    // console.log("validationError = ", appId, jobId);
    // alert("--- selectedWorkflow = ", this.state.selectedWorkflow);
    if (!validationError) {
      this.generateWorkflow(appId, jobId);
    } else {
      this.setState({ validationError });
    }
    return false;
  }

  createJobInterview = (jobId, workflowId, stageId = 0) => {
    const data = {
      isJobWorkflow: true,
      appId: '0', // Be careful pass string '0' and not 0
      jobId,
      appStageUserMapId: `job#${jobId}-wf#${workflowId}-stage#${stageId}`,
      interviewName: `#${jobId}-#workflow-#${workflowId}-#stage-#${stageId}-InterviewTemplate`,
      stageId,
      workflowId,
    };
  }

  generateWorkflow = (appId, jobId) => {
    const { useTemplate, selectedWorkflow } = this.state;
    const { updateParentPage, workflowRes } = this.props;
    if (useTemplate) {
      // if use template, clone workflow
      // console.log(" selectedWorkflow = ", selectedWorkflow.workflowId);

      this.props.cloneWorkflow({
        workflowId: selectedWorkflow.workflowId,
        name: this.state.workflowName,
        appId: appId,
      }).then((res) => {
        // console.log("created!!!" , appId, res);
        if (res && res.meta && res.meta.workflowId) {
          if ((!appId || (appId == 0)) && jobId) {
            // workflow creation was succesful
            // Job workflow mode
            // this.createJobInterview(jobId, res && res.workflowId);
            // this.props.assignWorkflowToJob({
            //   jobId,
            //   workflowId: res && res.meta && res.meta.workflowId,
            // }).then((res1) => {
            //   // Job workflow mode
            //   // console.log("finished assigning workflow to applicant", res1);
            // });
          } else {
            // workflow creation was succesful
            // this.props.assignWorkflowToApplication({
            //   appId,
            //   workflowId : res && res.meta && res.meta.workflowId,
            // }).then((res1) => {
              // console.log("finished assigning workflow to applicant", res1);
              updateParentPage(false);
              this.props.dismiss();
            // });
          }
        } else {
          // workflow creation failed..show error
          this.setState({
            validationError: {
              title: 'There was an error in processing. Pls. try again later.',
            },
          });
        }
      });
    } else {
      // create a new workflow.

      let selectedStagesList = [];
      // get selected stages list
      this.state.currentSelectedStages.map((eachSelectedStage) => {
        if (eachSelectedStage && eachSelectedStage.stagesCount
          && eachSelectedStage.stages
            && Array.isArray(eachSelectedStage.stages)
            && eachSelectedStage.stages.length > 0) {
          const sublist = eachSelectedStage.stages.slice(0, eachSelectedStage.stagesCount);
          const sublistStageIds = sublist.map(stage => stage.stageId);
          selectedStagesList = selectedStagesList.concat(sublistStageIds);
        }
      });

      // let selectedStagesList = this.state.currentSelectedStages.map(stage => stage.stageId);    
      let workflowInsert = {
        stageConfig: selectedStagesList.join(','),
        isPublic: true,
        createdBy: this.props.user.userId,
        // workflowId: 1,
        name: this.state.workflowName,
      };
      const finalWorkflowInsert = {...workflowInsert};
      if (jobId !== null && !appId) {
        finalWorkflowInsert.jobId = jobId;
      } else if (appId !== null) {
        finalWorkflowInsert.appId = appId;
      }
      // console.log("selectedStagesList = ", selectedStagesList);
      // return;
      // if ((!appId || (appId == 0)) && jobId) {
      //   workflowInsert.isPublic = false;
      // }
      if (workflowRes && workflowRes.workflowId) {
        const data = {
          workflowId: workflowRes.workflowId,
          stageConfig: selectedStagesList.join(','),
        };
        const finalData = {...data};
        if (jobId !== null && !appId) {
          finalData.jobId = jobId;
        } else if (appId !== null) {
          finalData.appId = appId;
        }
        this.props.updateStageConfigs(data).then(() => {
          window.location.reload();
        });
      } else {
        this.props.createWorkflow(finalWorkflowInsert).then((res) => {
          // console.log("created!!!" , appId, res);
          if (res && res.workflowId) {
          // workflow creation was succesful
          // Autopilot mode
            if ((!appId || (appId == 0)) && jobId) {
              window.location.reload();
              const { workflows: { workflowstages: workflowstagesArr = [] } = {} } = this.props;
              const videoInterviewStagesArr = isVideoInterviewStageInStages(selectedStagesList, workflowstagesArr);
              if (videoInterviewStagesArr) {
                videoInterviewStagesArr.map((vs) => {
                  this.createJobInterview(jobId, res && res.workflowId, vs);
                });
              } else {
                this.props.updateParentPage(false);
                this.props.dismiss();
              }
              // workflow creation was succesful
              // this.props.assignWorkflowToJob({
              //   jobId,
              //   workflowId: res && res.workflowId,
              // }).then((res1) => {
              //   // Autopilot mode
              //   // console.log("finished assigning workflow to applicant", res1);
                
              // });
            } else {
              // this.props.assignWorkflowToApplication({
              //   appId,
              //   workflowId: res && res.workflowId,
              // })
              //   .then((res1) => {
                  updateParentPage(false);
                  this.props.dismiss();
                  // console.log("finished assigning workflow to applicant", res1);
                // });
            }
          } else {
            // workflow creation failed..show error
          }
        });
      }
    }
  }

  render() {
    const {
      appId, jobId, interviews = [], dismiss, show, application,
      showInModal = false, updateParentPage, isAutoPilotMode = false,
      isJobWorkflow, collaboration, user: { isAdmin, isSuperAdmin, userId, roleId, company: { companyId, ctypeId } = {} } = {},
      value, t,
    } = this.props;
    const {
      job: {
        institutionId,
        recruiterId,
      } = {},
    } = application || {};
    const collaboratorRes = collaboration && collaboration.filter(res => (res.userId == userId && (res.roleId == 1 || res.roleId == 4)));
    const { validationError } = this.state;
    const handleWorkflowSetUpbody = () => {
      return (
        <div className={`bx--modal-content__text  pl-0  ${this.props.loading ? 'text-center' : ''}`}>
          {this.props.loading && (
            <Loading className="d-inline-block" withOverlay={false} />
          )}
          {!this.props.loading && (
            <Form onSubmit={(e) => { this.validateAndSubmitForm(appId, jobId); e.preventDefault(); }} className="some-class">
              {validationError && (
                <InlineNotification lowContrast
                  onCloseButtonClick={() => {}}
                  className="mt-0"
                  title={validationError.title}
                  subtitle=""
                  iconDescription="describes the close button"
                  kind="error"
                />)}
              {/* <div className="bx--modal-input-content">             */}
              <TextInput
                id="test2"
                placeholder={t('workflowPlaceholder')}
                labelText={t('workflowHeading')}
                onChange={this.handleOnTitleChange}
                value={this.state.workflowName}
                required
              />
              {/* </div> */}
              <br />
              {(isAutoPilotMode || isJobWorkflow) ? (
                <RadioButtonGroup onChange={this.handleWorkflowOptionChange} name="radio-button-group" defaultSelected="default-selected">                        
                  <RadioButton
                    name="workflow-selection--new"
                    // onChange={this.handleWorkflowOptionChange}
                    value="new"
                    labelText={t('createNew')}
                    id="workflow-selection--new2"
                    className="mt-0 pl-1"
                  />
                </RadioButtonGroup>) : (
                  <RadioButtonGroup onChange={this.handleWorkflowOptionChange} name="radio-button-group" defaultSelected="default-selected">
                    <RadioButton
                      name="workflow-selection--clone"
                      // onChange={this.handleWorkflowOptionChange}
                      value="template"
                      labelText={t('cloneWorkflowMsg')}
                      id="workflow-selection--clone1"
                      className="mb-0 pl-1"
                    />
                    <RadioButton
                      name="workflow-selection--new"
                      // onChange={this.handleWorkflowOptionChange}
                      value="new"
                      labelText={t('createNew')}
                      id="workflow-selection--new2"
                      className="mt-0 pl-1"
                    />
                  </RadioButtonGroup>)}
              {
                  this.state.useTemplate && (
                    <div className="ml-5">
                      <Select
                        id="xpa-clone-workflow"
                        labelText={t('select')}
                        defaultValue={this.state.selectedWorkflow}
                        onChange={this.onExistingWorkflowSelectChange}
                        hideLabel
                      >
                        <SelectItem
                          value="placeholder-item"
                          text={t('none')}
                        />
                        {
                          this.getAllUserWorkflows().map(workflow => (
                            <SelectItem
                              key={workflow.workflowId}
                              value={workflow.workflowId}
                              text={workflow.name}
                            />))
                        }
                      </Select>
                        {
                          this.state.selectedWorkflow && (
                          <div>
                            <div>
                              <strong><span className="xpa-f-sz--M">{t('stages')}:</span></strong>
                            </div>
                            {
                              this.state.selectedWorkflow 
                              && this.state.selectedWorkflow.stages.map(stage => (
                                <div className="row">
                                  <div className="col-6 mt-auto mb-auto">
                                    <SelectItem
                                      disabled
                                      key={stage.stageId}
                                      value={stage && stage.stageName}
                                      text={stage && stage.stageName}
                                    />
                                  </div>
                                </div>))
                            }
                          </div>)
                        }
                    </div>
                  )
              }
              {
                (this.state.useTemplate === false) && (
                  <div className="ml-0">
                    <strong><span className="xpa-f-sz--M">{t('selectInterviewStages')}</span></strong>
                    {this.getCurrentSelection().length <= 0 && (
                      <div><small className="text-dark">{t('noInterviewStagesMsg')}</small></div>
                    )}
                    {
                      this.getCurrentSelection().map((stages, index) => (
                        <div className="">
                          <div className="row">
                            <SelectItem
                              className="col-5 pt-3"
                              disabled
                              key={stages.stageTypeId}
                              value={stages.stageTypeName}
                              text={stages.stageTypeName}
                            />
                            <Select
                              id="interview-template--sel"
                              labelText=""
                              defaultValue={null}
                              onChange={this.onStageTypeCountSelectChange}
                            >
                              {
                                stages && stages.stages && stages.stages.map((eachStage, eachStageIndex) => (
                                  <SelectItem
                                    key={eachStage.stageId}
                                    value={`${stages.stageTypeId}_${eachStageIndex + 1}`}
                                    text={eachStageIndex + 1}
                                  />
                                ))
                              }
                            </Select>
                            <div className="col-3 pt-3 pl-0 ml-0">
                              <Button
                                small
                                onClick={() => this.removeFromCurrentSelectedStages(stages.stageTypeId)}
                                onFocus={() => { }}
                                kind="danger"
                              >
                                {t('delete')}
                              </Button>
                            </div>
                          </div>
                        </div>))
                    }
                    <hr />
                    <strong><span className="xpa-f-sz--M">{t('chooseWorkflowStages')}</span></strong>
                    {
                      this.getStages() && this.getStages().length > 0 && (
                        <div className="row">
                          <div className="col-5 mt-auto mb-auto">
                            <Select
                              id="interview-template"
                              labelText=""
                              defaultValue={null}
                              onChange={this.onStageSelectChange}
                            >
                              <SelectItem
                                value="placeholder-item"
                                text={t('none')}
                              />
                              {
                                this.getStages().map(stages => (
                                  <SelectItem
                                    key={stages.stageTypeId}
                                    value={stages.stageTypeId}
                                    text={stages.stageTypeName}
                                  />))
                              }
                            </Select>
                          </div>
                          <div className="col-5 mt-auto mb-auto pt-2">
                            <Button
                              small
                              kind="tertiary"
                              onClick={() => this.addCurrentSelectedStagetoList()}
                              onFocus={() => { }}
                            >
                              {t('add')}
                            </Button>
                          </div>
                        </div>)
                    }
                  </div>
                )
              }
              <div className="pt-4 text-right pb-1 pr-1">
                <Button
                  kind="tertiary"
                  onClick={dismiss}
                  // onFocus={onFocus()}
                >
                  {t('cancel')}
                </Button>
                <Button type="submit">
                  {t('save')}
                </Button>
              </div>
            </Form>)}
        </div>
      );
    };
    return (
      <Fragment>
        { !showInModal && (
          <Modal
            onRequestClose={dismiss}
            className="some-class "
            open={show}
            modalLabel={`${application && application.profile && application.profile.firstName} ${application && application.profile && application.profile.lastName}`}
            passiveModal
            // onRequestSubmit={() => this.validateAndSubmitForm(appId)}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            modalHeading="Candidate Interview setup"
          >
            {handleWorkflowSetUpbody()}
          </Modal>)
        }
        {
          (showInModal && roleId !== 3
            && ((isSuperAdmin === true)
            || (recruiterId === userId)
            || (isAdmin === true && ctypeId && Number(ctypeId) === 2 && Number(companyId) === Number(institutionId))
            || (isAdmin === true && ctypeId && Number(ctypeId) === 1)
            || (collaboratorRes && Array.isArray(collaboratorRes) && collaboratorRes.length > 0))
          ) ? (
            <Fragment>
              <div className="d-none d-lg-block d-md-block bx--modal-input-content">
                {handleWorkflowSetUpbody()}
              </div>
              <div className="d-block d-lg-none d-md-none">
                {handleWorkflowSetUpbody()}
              </div>
            </Fragment>
            ) : (
              <div>
                {
                  value && value.tabName && value.tabName === 'interviewstatus'
                    && (
                      <span>{t('noAccessToWorkflow')}</span>
                    )
                }
                {
                  value && value.id && value.id !== null
                    && value.id !== undefined
                    && roleId === 3
                    && (
                      <span>{t('workflowMsg')}</span>
                    )
                }
              </div>
            )
        }
      </Fragment>
    );
  }
}

CreateWorkflowModal.propTypes = {
  appId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  jobId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = state => ({
  loading: state.workflows.loading,
  user: state.x0paDuser.user,
  workflows: state.workflows,
});

const mapDispatchToProps = {
  getAllUserWorkflow,
  getWorkflowStages,
  createWorkflow,
  cloneWorkflow,
  assignWorkflowToJob,
  updateStageConfigs,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateWorkflowModal));
