import { useTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';

class ForgotPassword extends React.Component {
  constructor() {
    super();
  }
  state = {
  };

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  forgotPassword = () => {
    const eData = {
      "email": this.state.email.toLowerCase(),
    };
    fetch('/roboroy/api/v1/userinfos/reset-password', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(eData),
    })
    .then(function(response) {
      return response.json();
    })  
    .then((resJSON) => {
      if (resJSON && resJSON.error) {
        this.setState({ error: true });
      } else {
        this.setState({ error: false });
      }
    })
    .catch((err) => this.setState({ error: true }));
  }
  render() {
    const { t } = this.props;
    return (
      <div className="xpa-center-viewport p-2">
        <div className="row">
          <img className="mb-4 mx-auto" src={require('assets/images/republic-polytechnic.png')} />
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <form className="form-signin text-center card" onSubmit={(e) => {
              e.preventDefault();
              this.forgotPassword();
              
            }} >
              <h1 className="h3 mb-3 font-weight-normal">{t('forgotPassword2')}</h1>
              {this.state.error && (<div className="m-1 text-danger xpa-f-sz--M ">
                {t('processingError')}
                </div>
                )}
              {(this.state.error === false ) && (
                <div className="m-1 text-success xpa-f-sz--M ">
                  {t('emailToReset')}
                </div>
              )}              
              <div className="">
                  <input type="email" required name="username" required
                    placeholder={t('enterRegisteredEmail')}
                    autoComplete="username"
                    className="bx--text-input mb-2"
                    onChange={(e) => this.handleOnChange('email', e.target.value)}
                  />
                <button class="bx--btn bx--btn--primary full-width"  type="submit">{t('resetPassword')}</button>                  
              </div>
              <Link className="mt-2" to="/">&larr; {t('goToLogin')}</Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const Page = () => (
  <div className="page-forgot">
    <div className="main-body">
      <ForgotPassword t={useTranslation().t} />
    </div>
  </div>
);

export default Page;
