export const FETCH_ALL_SKILLS_REQUEST = 'FETCH_ALL_SKILLS_REQUEST';
export const FETCH_ALL_SKILLS_SUCCESS = 'FETCH_ALL_SKILLS_SUCCESS';
export const FETCH_ALL_SKILLS_ERROR = 'FETCH_ALL_SKILLS_ERROR';

export const CREATE_SKILL_REQUEST = 'CREATE_SKILL_REQUEST';
export const CREATE_SKILL_SUCCESS = 'CREATE_SKILL_SUCCESS';
export const CREATE_SKILL_ERROR = 'CREATE_SKILL_ERROR';

export const ADD_SKILL = 'ADD_SKILL';
export const REMOVE_SKILL = 'REMOVE_SKILL';

// job parse
export const JD_PARSE_START = 'JD_PARSE_START';
export const JD_PARSE_SUCCESS = 'JD_PARSE_SUCCESS';
export const JD_PARSE_ERROR = 'JD_PARSE_ERROR';

// dropdowns
export const FETCH_DROPDOWN_DATA_REQUEST = 'FETCH_DROPDOWN_DATA_REQUEST';
export const FETCH_DROPDOWN_DATA_SUCCESS = 'FETCH_DROPDOWN_DATA_SUCCESS';
export const FETCH_DROPDOWN_DATA_ERROR = 'FETCH_DROPDOWN_DATA_ERROR';

export const CHANAGE_DROPDOWN = 'CHANAGE_DROPDOWN';

export const CHANGE_END_DATE = 'CHANGE_END_DATE';

export const CHANGE_JOB_CREATION_INPUT = 'CHANGE_JOB_CREATION_INPUT';
