import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Tile, ToggleSmall, Modal, TextInput, InlineNotification,
} from 'carbon-components-react';

import { getMarketApp, updateMarketplaceApp } from 'actions/marketplace';
import BackRow from 'components/common/BackRow/BackRow';

import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import Avatar from 'components/common/Avatar';
import ApplicationSettings from './ApplicationSettings';

class ApplicationDetails extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'marketplace', name: 'Marketplace', href: '/app/campus/marketplaceapps' },
    { id: 'marketplace-app', name: 'Application details' },

  ];

  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
      selectedTab: 0,
      showAuthModal: false,
      apiKey: '',
      userName: '',
      showInlineError: false,
    };
  }

  componentDidMount() {
    const { match: { params: { id = null } } } = this.props;
    if (id && id !== null) {
      this.props.getMarketApp({ id }).then((res) => this.setState({ isEnabled: res && res.enabled }));
    }
  }

    onToggle = (marketplaceId, flag) => {
      if (flag === true && this.state.isEnabled === false) {
        this.setState({ isEnabled: flag, showAuthModal: false }); // true
      }
      else {
        this.setState({ isEnabled: flag, showAuthModal: false });
      }
      this.props.updateMarketplaceApp({marketplaceId, enabled: flag});
    }

    handleChange = (ev) => {
      const { target: { value = '', name = '' } } = ev;
      if (name && typeof name === 'string' && name.length > 0) {
        this.setState({ [name]: value });
      }
    }

    showAuthModalfn = () => {
      this.setState({ showAuthModal: true, showInlineError: false });
    }

    validatenSaveAuth = () => {
      const { apiKey, userName } = this.state;
      if (apiKey.trim().length === 0 || userName.trim().length === 0) {
        this.setState({ showInlineError: true });
        return;
      }
      /* Save credentials */
      this.setState({ showAuthModal: false, showInlineError: false });
    }

    render() {
      const {
        isEnabled, showAuthModal, selectedTab, userName,
        apiKey, showInlineError,
      } = this.state;
      const {
        ApplicationInfo: { marketplaceapps = {} } = {},
      } = this.props;
      const {
        appName = '-', company: { companyName = '-', description = 'No company information has been provided at the moment' } = {},
        appDescription = 'No application information has been provided at the moment.', companyinfo: {logo} = {},
        marketplaceId,
      } = marketplaceapps;
      const data = { firstname: appName, picture: logo || null };


      const tabContent = false ? [
        {
          label: 'App details',
          value: (
            <div className="ml-lg-3">
              <div className="bx--type-Delta mb-3 font-weight-bold">Description:</div>
              <div className="bx--type-Zeta">{appDescription !== null ? appDescription : 'No company information has been provided at the moment.'}</div>
            </div>),
          onClick: () => { this.setState({ selectedTab: 0 })},
        },
        {
          label: 'Settings',
          value: <ApplicationSettings apiUser={userName} apiKey={apiKey} showAuthModalfn={() => this.showAuthModalfn()} />,
          onClick: () => { this.setState({ selectedTab: 1 }); },
        },
      ] : [
        {
          label: 'App details',
          value: (
            <div className="ml-lg-0">
              <div className="bx--type-Zeta">{appDescription !== null ? appDescription : 'No application information has been provided at the moment.' }</div>
            </div>),
          onClick: () => { this.setState({ selectedTab: 0 }); },
        },
      ];

      return (
        <div>
          <BackRow paths={this.paths} />
          <Modal
            className=""
            open={showAuthModal}
            modalHeading="Authentication Details"
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={() => { this.setState({ showAuthModal: false, showInlineError: false }); }}
            onRequestSubmit={() => this.validatenSaveAuth()}
            onSecondarySubmit={() => { this.setState({ showAuthModal: false, showInlineError: false }); }}
          >
            <div>
              { showInlineError === true && <InlineNotification lowContrast title="Please provide valid Username and API Token" subtitle="" kind="error" hideCloseButton /> }
              <div className="ml-lg-0">
                <div className="mr-2 bx--type-zeta mt-2 mb-1">Username:</div>
                <div className="bx--col-lg-4  ml-0 pl-0">
                  <TextInput required id="userName00" name="userName" onChange={ev => this.handleChange(ev)} labelText="" placeholder="User Name" value={userName} maxLength={50} />
                </div>
              </div>
              <div className="mt-3 ml-lg-0">
                <div className="mr-2 mt-2 mb-1 bx--type-zeta">API Token:</div>
                <div className="bx--col-lg-4 ml-0 pl-0">
                  <TextInput required id="apiKey0" name="apiKey" labelText="" onChange={ev => this.handleChange(ev)} placeholder="API Token" value={apiKey} maxLength={50} />
                </div>
              </div>
            </div>
          </Modal>
          <Tile className="mb-3">
            <div className="bx--row m-0">
              <div className="bx--col-lg-2 col-lg-2 bx--col-4 col-4 mt-auto mb-auto">
                <Avatar isCompanyLogo={true} user={data} size="100" classNameInfo="rounded mt-4 mt-lg-0 mx-auto img-fluid d-block" />
              </div>
              <div className="bx--col-lg-10 col-lg-10 bx--col-8 col-8">
                <div className="bx--row m-0">
                  <div className="bx--type-beta font-weight-bold">{`${appName}  `}</div>
                </div>
                <div className="bx--type-Epsilon text-dark mb-1">{companyName}</div>
                {/* <div className="bx--type-Delta font-weight-bold">Description:</div>
                <div className="bx--type-Zeta mb-3">{appDescription !== null ? appDescription : "No description"}</div> */}
                <div className="bx--type-Delta font-weight-bold mt-0">{`About ${companyName} :`}</div>
                <div className=" text-dark">{description !== null ? description : 'No company information has been provided at the moment.'}</div>
                <div className="text-left bx--row m-0">
                  <div className="mt-3 mr-1">Disabled</div>
                  <div><ToggleSmall toggled={isEnabled} ariaLabel="" onToggle={e => this.onToggle(marketplaceId, e)} id="enableApplication" /></div>
                  <div className="mt-3 ml-1">Enabled</div>
                </div>
              </div>
            </div>
          </Tile>
          <div className="mt-3">
            <Tile className="">
              <GenericTabs className="mb-3" selected={selectedTab} tabContent={tabContent} />
            </Tile>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  ApplicationInfo: state.marketplace.applicationDetails,
  loading: state.marketplace.loading,
});

const mapDispatchToProps = {
  getMarketApp,
  updateMarketplaceApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails);
