import { REQUEST_UPLOAD_IMAGE } from 'actions/userProfile/action.constant';
import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import { deleteApiCache } from 'utils/helpers';
import {
  REQUEST_GET_CANDIDATES_IN_OFFER_LIST,
  REQUEST_GET_OFFER_DETAILS,
  REQUEST_EDIT_OFFER_DETAILS,
  REQUEST_CREATE_OFFER_DETAILS,
  REQUEST_GET_CURRENCY_VALUE,
  REQUEST_GET_REJECT_REASONS,
  REQUEST_CREATE_OFFER_DOCUMENTS,
  REQUEST_GET_OFFER_DOCUMENTS,
  REQUEST_DELETE_OFFER_DOCUMENTS,
  REQUEST_JOB_OFFER_DETAILS,
  SUCCESS_JOB_OFFER_DETAILS,
  ERROR_JOB_OFFER_DETAILS
} from './actionTypes';

const getCandidatesInOffer = ({
  itemsPerPage = 10,
  activePage = 0,
  status = 'inprogress',
  courseId,
  batch
}) => dispatch => {
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = ''
  url = `${baseUrl}/offers?_=${new Date().getTime()}&status=${status}&filter[limit]=${itemsPerPage}&filter[skip]=${skip}`
  if(courseId){
    url += `&courseId=${courseId}`
  }
  if(batch!==null && batch!==undefined){
    url+= `&filter[batch]=${batch}`
  }

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CANDIDATES_IN_OFFER_LIST,
  };

  return callApi(params);
};

const createOfferDetails = data => dispatch => {
  const url = `${baseUrl}/offers`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_OFFER_DETAILS,
  };
  deleteApiCache('xpa-api-offers');
  return callApi(params);
};

const editOfferDetails = data => dispatch => {
  const url = `${baseUrl}/offers`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_OFFER_DETAILS,
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api-offers');
  return callApi(params);
};

const getOfferDetails = appId => dispatch => {
  const url = `${baseUrl}/offers?applicationId=${appId}&_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_OFFER_DETAILS,
  };

  return callApi(params);
};

const getRejectReasons = () => dispatch => {
  const url = `${baseUrl}/rejectreason`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_REJECT_REASONS,
  };

  return callApi(params);
};

const getCurrencyValues = () => dispatch => {
  const url = `${baseUrl}/Currencies`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CURRENCY_VALUE,
  };

  return callApi(params);
};

const createOfferDocuments = data => dispatch => {
  const url = `${baseUrl}/offerdocuments?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_OFFER_DOCUMENTS,
  };
  deleteApiCache('xpa-api-offers');
  return callApi(params);
};

const uploadOfferDocs = data => dispatch => {
  console.log('baseUrl', baseUrl);
  let url = `${baseUrl}/Images/`;
  const hostname = window.location.hostname;
  const endingUrl = `upload?_=${new Date().getTime()}`;
  if (hostname.includes('localhost')) {
    url += `x0pa-dev-offers/${endingUrl}`;
  }
  if (hostname.includes('cstage.x0pa')) {
    url += `x0pa-cstage-offers/${endingUrl}`;
  }
  if (hostname.includes('campus.x0pa')) {
    url += `x0pa-campus-offers/${endingUrl}`;
  }
  if (hostname.includes('geip.x0pa')) {
    url += `x0pa-geip-offers/${endingUrl}`;
  }
  if (hostname.includes('rps.x0pa')) {
    url += `x0pa-rps-offers/${endingUrl}`;
  }
  if (hostname.includes('rp.x0pa')) {
    url += `x0pa-rp-offers/${endingUrl}`;
  }
  const params = {
    dispatch,
    method: 'POST',
    url,
    body: data,
    actionType: REQUEST_UPLOAD_IMAGE,
  };
  return callApi(params);
};

const getOfferDocumentsById = offerDocumentId => dispatch => {
  const url = `${baseUrl}/offerdocuments/${offerDocumentId}/doc?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_OFFER_DOCUMENTS,
    responseType: 'application/pdf',
  };

  return callApi(params);
};

const deleteOfferDocumentsById = offerDocumentId => dispatch => {
  const url = `${baseUrl}/offerdocuments/${offerDocumentId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_OFFER_DOCUMENTS,
  };
  deleteApiCache('xpa-api-offers');
  return callApi(params);
};

const getOfferExport = () =>(dispatch)=> {
  const url = `${baseUrl}/offers/exports?_=${new Date().getTime()}`
  window.location.href = url;
}

const getJobOfferDetails = (jobId, appId) => dispatch => {
  const url = `${baseUrl}/offers/${jobId}/offerjob/${appId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_JOB_OFFER_DETAILS,
  };

  return callApi(params);
};

export {
  createOfferDetails,
  editOfferDetails,
  getOfferDetails,
  getCurrencyValues,
  getRejectReasons,
  getCandidatesInOffer,
  createOfferDocuments,
  uploadOfferDocs,
  getOfferDocumentsById,
  deleteOfferDocumentsById,
  getOfferExport,
  getJobOfferDetails
};
