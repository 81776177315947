import { transform, camelCase } from 'lodash';
import {
  SUCCESS_PARSE_JD,
  SUCCESS_ALL_SKILLS,
  SUCCESS_CREATE_NEW_JOB,
  SUCCESS_CREATE_NEW_SKILLS,
  SUCCESS_CREATE_NEW_JOB_TITLE,
  SUCCESS_DROPDOWN_DATA,
  SUCCESS_JOB_NAMES,
  REQUEST_JOB_NAMES,
  ERROR_JOB_NAMES,
  SUCCESS_CREATE_NEW_COMPANY,
  SUCCESS_ALL_COUNTRIES,
  REQUEST_ALL_COMPANIES,
  REQUEST_ALL_OFFICES,
  REQUEST_ALL_JOBFUNCTIONS,
  SUCCESS_ALL_COMPANIES,
  SUCCESS_ALL_OFFICES,
  SUCCESS_TOPFIVE_RESULTS,
  SUCCESS_ALL_JOBFUNCTIONS,
  SUCCESS_ALL_COMPANYTYPES,
  REQUEST_ALL_LANGUAGES,
  SUCCESS_ALL_LANGUAGES,
  REQUEST_ALL_LOCATIONS,
  SUCCESS_ALL_LOCATIONS,
  REQUEST_JOB_APPROVAL_LIST,
  SUCCESS_JOB_APPROVAL_LIST,
  SUCCESS_MANAGE_QUESTION,
  ERROR_JOB_APPROVAL_LIST,
  REQUEST_GET_WORKDAYS_LIST,
  SUCCESS_GET_WORKDAYS_LIST,
  ERROR_GET_WORKDAYS_LIST,
} from '../../actions/talentSearch/actionTypes';

const initState = {
  workDaysList: [],
  title: '',
  department: '',
  internal_code: '',
  countries: [],
  country: '',
  region: '',
  job_desc: '',
  job_req: '',
  job_list: '',
  salary_from: '',
  salary_to: '',
  recruiter: '',
  required_exp: '',
  reporting_manager: '',
  designation: '',
  designation_name: '',
  number_vacancies: '',
  close_date: '',
  benefit: '',
  terms: '',
  function: '',
  seniority: '',
  hour: '',
  education: '',
  company_desc: '',
  skill: [],
  skills: [],
  skillsmusthave: [],
  skillsgoodtohave: [],
  skillsdesired: [],
  jobName: [],
  companies: [],
  offices: [],
  dropdowns: {
    company: {
      companyName: '',
      companyId: 0,
    },
    loading: false,
    error: '',
    jobFunctions: [],
    jobSeniorities: [],
    qualificationTypes: [],
    industries: [],
    currencies: [],
    countries: [],
    terms: [],
    hourTypes: [],
    companyTypes: [],
    firstFive: [],
  },
};

const formatValue = (value) => {
  if (value === null) {
    return '';
  }
  return value;
};

const jobCreation = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case SUCCESS_DROPDOWN_DATA:
      return {
        ...state,
        dropdowns: action.payload.meta,
      };
    case SUCCESS_ALL_SKILLS:
      return {
        ...state,
        skills: action.payload||[],
      };
    case SUCCESS_CREATE_NEW_SKILLS:
      return {
        ...state,
        skills: [...state.skills, action.payload],
      };
    case SUCCESS_CREATE_NEW_JOB_TITLE:
      return {
        ...state,
          jobName: [action.payload],
      };
    case SUCCESS_PARSE_JD:
      let jdData = transform(
        action.payload,
        (result, value, key) => {
          result[camelCase(key)] = formatValue(value);
        },
        {},
      );
      let jobName = [];
      if (action.payload.jobName === undefined) {
        const { name } = action.payload;
        const nameId = 0;
        if (Array.isArray(name)) jobName = name;
        else jobName.push({ nameId, nameName: name });
      }
      //console.log(jdData);
      return {
        ...state,
        ...jdData,
        jobName,
      };
    case SUCCESS_CREATE_NEW_JOB:
      return {
        ...state,
        ...action.payload.data,
      };
    case REQUEST_JOB_NAMES:
      return {
        ...state,
        jobNameAutoCompleteLoading: true,
        loading: false,
      };   
    case ERROR_JOB_NAMES:
      return {
        ...state,
        jobName: [],
        jobNameAutoCompleteLoading: true,
      };         
    case SUCCESS_JOB_NAMES:
      return {
        ...state,
        jobName: action.payload,
        jobNameAutoCompleteLoading: false,
        loading: false,
      };
    case SUCCESS_ALL_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SUCCESS_CREATE_NEW_COMPANY:
      return {
        ...state,
        companies: [action.payload]
      };
    case SUCCESS_ALL_COMPANIES:
      return {
        ...state,
        companies: action && action.payload && Array.isArray(action.payload) ? action.payload : (action && action.payload ? [action && action.payload] : []),
        loading: false,       
      };
    case SUCCESS_ALL_OFFICES:
      return {
        ...state,
        offices: action.payload,
        loading: false,
      };
    case SUCCESS_TOPFIVE_RESULTS:
      return {
        ...state,
        firstFive: action.payload,
        loading: false,
      };
    case SUCCESS_ALL_COMPANYTYPES:
      return {
        ...state,
        companyTypes: action.payload,
        loading: false,
      };
    case SUCCESS_ALL_JOBFUNCTIONS:
      return {
        ...state,
        jobFunctions: action.payload,
        loading: false,
      };
    case REQUEST_ALL_JOBFUNCTIONS:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_ALL_COMPANIES:
      return {
        ...state,
      };
    case REQUEST_ALL_OFFICES:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_ALL_LANGUAGES: {
      return {
        ...state,
        loading: true,
      };
    }
    case SUCCESS_ALL_LANGUAGES: {
      return {
        ...state,
        loading: false,
        languages: action.payload && action.payload.languages,
      };
    }
    case REQUEST_ALL_LOCATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SUCCESS_ALL_LOCATIONS: {
      return {
        ...state,
        loading: false,
        locations: action.payload,
      };
    }
    case SUCCESS_MANAGE_QUESTION: {
      return {
        ...state,
        loading: false,
        questions: action.payload,
      };
    }
    case REQUEST_GET_WORKDAYS_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SUCCESS_GET_WORKDAYS_LIST: {
      return {
        ...state,
        workDaysList: action.payload,
        loading: false,
      };
    }
    case ERROR_GET_WORKDAYS_LIST: {
      return {
        ...state,
        loading: false,
        error: action.payload && action.payload.error,
      };
    }
    default:
      return state;
  }
};

export default jobCreation;

// case INVALIDATE_JOBS:
//   return initState;
// case at.CHANGE_MODAL_JOB:
//   return {
//     ...state,
//     activejob: action.payload.job,
//   };
