import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Tile } from 'carbon-components-react';
import Avatar from 'components/common/Avatar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AssetAnalytics from 'containers/roboroy/AssetAnalytics';
import './editJob';

const ActionTypesContent = ({
  actionType,
  assetType,
  firstname,
  lastname,
  stageName,
  roleName,
  t,
}) => {
  let finalValue = '';
  if (assetType === 'job') {
    switch (actionType) {
      case 'created':
        finalValue = <span>{t('jobTimelineMsg1')}</span>;
        break;
      case 'closed':
        finalValue = <span>{t('jobTimelineMsg2')}</span>;
        break;
      case 'reopen':
        finalValue = <span>{t('jobTimelineMsg3')}</span>;
        break;
      case 'edit':
        finalValue = <span>{t('jobTimelineMsg4')}</span>;
        break;
      case 'preferred-enabled':
        finalValue = <span>has set the visibility status of this job to <b>Preferred</b>. </span>;
        break;
      case 'preferred-disabled':
        finalValue = <span>has set the visibility status of this job to <b>Not Preferred</b>. </span>;
        break;
      default:
        break;
    }
    if (actionType.includes('-')) {
      const visibilityValue = actionType.split('-')[1];
      const toggleValue = actionType.split('-')[0];
      if (visibilityValue === 'add') {
        finalValue = <span>{t('has')} {t('added')} <b>{firstname} {lastname}</b> {t('as')} {roleName && roleName.toLowerCase() === 'administrator' ? `${t('an')}` : `${t('a')}`} <b>{roleName}</b> {t('to')} {t('this')} {t('job')}.</span>;
      } else if (visibilityValue === 'delete') {
        finalValue = <span>{t('has')} {t('removed')} <b>{firstname} {lastname}</b> {t('as')} {roleName && roleName.toLowerCase() === 'administrator' ? `${t('an')}` : `${t('a')}`} <b>{roleName}</b> {t('to')} {t('this')} {t('job')}.</span>;
      } else if (toggleValue === 'autopilot') {
        finalValue = <span>{t('has')} <b>{visibilityValue}</b> {t('autoPilot')} {t('mode')}.</span>;
      } else if (toggleValue === 'jobvisibility') {
        finalValue = <span>{t('jobTimelineMsg5')} <b>{visibilityValue === 'enabled' ? `${t('public')}.` : `${t('private')}.`}</b></span>;
      } else if (toggleValue === 'aisourcing') {
        finalValue = <span>has <b>{visibilityValue}</b> AI sourcing.</span>;
      } else if (toggleValue === 'autoshortlist') {
        finalValue = <span>{t('has')} <b>{visibilityValue}</b> {t('jobTimelineMsg6')} {t('autoPilot')} {t('mode')}.</span>;
      }
    }
  } else if (assetType === 'workflow') {
    finalValue = <span>{t('jobTimelineMsg7')}</span>;
  } else if (assetType === 'stage' && stageName !== 'final decision' && actionType === 'created') {
    finalValue = <span>{t('has')} {t('added')} </span>;
  } else if (assetType === 'stage' && stageName !== 'final decision' && actionType === 'deleted') {
    finalValue = <span>{t('has')} {t('deleted')}</span>;
  }
  return finalValue;
};

const JobTimeline = ({
  timeLine,
  handleActiveSelectedTab,
  t,
}) => (
  <Fragment>
    {
      timeLine && Array.isArray(timeLine)
        && timeLine.length > 0
        && timeLine.map((res) => {
          const recruiterName = `${res && res.performedBy && res.performedBy.firstname} ${res && res.performedBy && res.performedBy.lastname}`;
          return (
            <Fragment>
              {
                res && res.actionType
                  && (
                    <Fragment>
                      {
                        res && res.workflowstage
                        && res.workflowstage.stageName
                        && res.workflowstage.stageName.toString()
                        && res.workflowstage.stageName.toLowerCase() === 'final decision'
                          ? null : (
                            <div>
                              <div className="d-flex">
                                <div>
                                  <span>
                                    <Avatar
                                      user={{
                                        firstname: res && res.performedBy && res.performedBy.firstname,
                                        lastname: res && res.performedBy && res.performedBy.lastname,
                                        picture: res && res.performedBy && res.performedBy.picture ? res.performedBy.picture : null,
                                      }}
                                      classNameInfo="rounded-circle"
                                      size="40"
                                    />
                                  </span>
                                </div>
                                <div className="mt-1 ml-2">
                                  <div>
                                    {
                                      res && res.actionType
                                        && (
                                          <span className="text-capitalize">
                                            <b>{recruiterName}</b>&nbsp;
                                            <ActionTypesContent
                                              actionType={res
                                                && res.actionType
                                                && res.actionType.toString()
                                                && res.actionType.toLowerCase()}
                                              assetType={res
                                                && res.assetType
                                                && res.assetType.toString()
                                                && res.assetType.toLowerCase()}
                                              firstname={res
                                                && res.assignedTo
                                                && res.assignedTo.firstname}
                                              lastname={res
                                                && res.assignedTo
                                                && res.assignedTo.lastname}
                                              stageName={res
                                                && res.workflowstage
                                                && res.workflowstage.stageName
                                                && res.workflowstage.stageName.toString()
                                                && res.workflowstage.stageName.toLowerCase()}
                                              roleName={res
                                                && res.additionalInfo
                                                && res.additionalInfo.roleName
                                                && res.additionalInfo.roleName.toString()
                                              }
                                              t={t}
                                            />
                                          </span>
                                        )
                                    }&nbsp;
                                    {
                                      res && res.workflowstage
                                        && res.workflowstage.stageName
                                        && res.workflowstage.stageName.toString()
                                        && res.workflowstage.stageName.toLowerCase() !== 'final decision'
                                        && (
                                          <span>
                                            <Link to="#" onClick={() => handleActiveSelectedTab(4)} ><b>{res.workflowstage.stageName}</b></Link> {t('jobTimelineMsg8')}
                                          </span>
                                        )
                                    }
                                  </div>
                                  <div>
                                    {
                                      res && res.actionDate
                                        && (
                                          moment(res.actionDate).fromNow()
                                        )
                                    }
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          )
                      }
                    </Fragment>
                  )
              }
            </Fragment>
          );
        })
    }
  </Fragment>
);

export default (withTranslation()(JobTimeline));
