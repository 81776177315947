import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import PercentageSlider from 'components/common/PercentageSlider';
import ProductTour from 'components/common/ProductTour/ProductTour';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import _ from 'lodash';
import { Editor } from 'react-draft-wysiwyg';
import {
  getCitiesList,
} from 'actions/CitiesDetails';
import {
  xSearchCompanies,
} from 'actions/xpa';
import {
  EditorState, convertToRaw, ContentState, Modifier, convertFromHTML,
} from 'draft-js';
import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import createCounterPlugin from 'draft-js-counter-plugin';
import {
  ModalWrapper, Toggle, Checkbox, TextInput,
  Modal, InlineNotification, Button,
  Select, SelectItem, Tile, FormGroup,
  Pagination, TextArea, DatePicker,
  DatePickerInput,
} from 'carbon-components-react';
import {
  fetchAllInstitutionsList,
} from 'actions/BrowseInstitutions';

import {
  getAllCompanies,
  getAuthorizedCompanies,
} from 'actions/admin/CompanyAndOffice';

import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import 'react-day-picker/lib/style.css';
import 'draft-js-counter-plugin/lib/plugin.css';
import {
  parseJd,
  saveJob,
  editJob,
  fetchDropdownData,
  associateJd,
  fetchAll,
  fetchCompanyId,
  createNewOption,
  fetchAllOffices,
  getFirstFiveMatchInTableCol,
  addOffice,
} from 'actions/talentSearch/newJob';
import {
  getAuthorizedUsers,
} from 'actions/job';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';

import {
  getJobInfo,
} from 'actions/talentSearch/jobList';
import Avatar from 'components/common/Avatar';

import {
  REQUEST_JOB_NAMES,
  REQUEST_ALL_COMPANIES,
  REQUEST_CREATE_NEW_JOB_TITLE,
  REQUEST_CREATE_NEW_SKILLS,
  REQUEST_CREATE_NEW_COMPANY,
  REQUEST_ALL_COUNTRIES,
  REQUEST_ALL_SKILLS,
  REQUEST_ALL_LANGUAGES,
} from 'actions/talentSearch/actionTypes';
import { changeNewJobInput, changeNewJobdropdown, changeEndDate } from './redux/actions';

import * as userData from 'actions/userProfile/action';
import { REQUEST_USERINFO } from 'actions/userProfile/action.constant';

import './NewJob.css';
import data from '../data';

import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import JobQuestions from 'containers/robocampus/jobQuestions';
import Preview from 'components/roboroy/newJob/formPreview.component';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
import InputFieldBox from 'components/common/inputFields/InputFields.component';
import RenderMessage from 'components/common/message/renderMessage.component';
import Preferences from 'containers/robocampus/Preferences';


import 'react-quill/dist/quill.snow.css';

const counterPlugin = createCounterPlugin();
const { CharCounter} = counterPlugin;

const propertyMapper = {
  name: 'jobName',
  term: 'terms',
  requiredExp: 'jobSeniorities',
  function: 'jobFunctions',
};

const roles = [
  'Individual',
  'Revenue Generating',
  'Cost Centre',
  'Client Facing',
  'Internal Facing',
  'Manager',
];

const NoContentBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no users to show for your current selection.</p>
    </div>
  </Tile>
);

const langLevels = [{ label: 'Not Required', value: 1 }, { label: 'Basic', value: 2 }, { label: 'Required', value: 3 }];

/*
  TODO: Container should only pass state to child component.
  Create new component and import that component.
*/
class NewJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productTourIsOpen: false,
      loading: false,
      company: {},
      offices: {},
      message: '',
      isEditJobCase: false,
      editFlag: true,
      skillAdded: false,
      displayMessage: false,
      companyId: 0,
      isCompanyChange: false,
      officeId: 1,
      activePreview: false,
      divisionId: 0,
      countries: '',
      country: '',
      region: '',
      nameId: 0,
      nameName: '',
      internalCode: '',
      functionId: 0,
      seniorityId: 0,
      qualTypeId: 0,
      requiredExp: 0,
      numberVacancies: 0,
      jobDesc: '',
      isJobDescEmpty: true,
      isJobReqEmpty: true,
      jobDescEditorState: EditorState.createEmpty(),
      jobReqEditorState: EditorState.createEmpty(),
      jobDescOldEditorState: EditorState.createEmpty(),
      jobReqOldEditorState: EditorState.createEmpty(),
      jobReq: '',
      benefit: '',
      minSalary: 0,
      maxSalary: null,
      currencyId: 0,
      openDate: moment().format(),
      closeDate: moment().add(7, 'days'),
      internshipStartDate: moment().format(),
      internshipEndDate: moment().add(7, 'days'),
      vacancy: null,
      termId: 0,
      hourTypeId: 0,
      jobList: props.jobCreation.job_list,
      jobIndex: props.jobCreation.job_index,
      jdLocation: 'location',
      teamId: 0,
      status: true,
      fromResume: false,
      draft: false,
      skill: [], // provided by jd parse response.
      skills: [],
      skillsmusthave: [],
      skillsgoodtohave: [],
      skillsdesired: [],
      isPrivate: false,
      displayCompanyName: false,
      recuiterCompanyId: null,
      recuiterCompany: {},
      agencyName: '',
      agencyId: null,
      isAgencyChange: false,
      locationAgnostic: false,
      aiSourcing: false,
      similarTitles: [],
      similarTitlesStr: '',
      address: '',
      jobScope: 0,
      office: {},
      officeAdd1: '',
      officeAdd2: '',
      officeRegion: '',
      officeZip: '',
      officeCountry: {},
      jobLang: 'en',
      isTravelling: false,
      inShifts: false,
      gpaScores: '',
      missingFields: false,
      modalError: false,
      jobLink: '',
      stageIndex: 0,
      stages: 7,
      disableNext: true,
      jobId: null,
      values: [],
      langsData: [],
      showAddLang: false,
      selectedLanguages: [],
      langValue: {},
      langWritten: {},
      langVerbal: {},
      industrypreferences: [],
      jobfunctionpreferences: [],
      jobPreferences: [],
      jobQuestions: [],
      list: [],
      dropdowns: {
        jobName: [],
        jobFunctions: [],
        jobSeniorities: [],
        qualificationTypes: [],
        currencies: [],
        terms: [],
        hourTypes: [],
      },
      apiMeta: {
        Jobname: {
          nameName: '',
        },
        Hireteam: {
          date: moment().format(),
        },
      },
    };
    this.closeTour = this.closeTour.bind(this);
  }

  getSourcingIndustriesMeta(selectedIndustries) {
    let sourcedIndustries = [];
    let selectedIndustriesArr = selectedIndustries.split(',');
    let filteredIndustries = [];
    if (this && this.props && this.props.jobCreation 
             && this.props.jobCreation.dropdowns 
             && this.props.jobCreation.dropdowns.sourcingIndustries 
             && Array.isArray(this.props.jobCreation.dropdowns.sourcingIndustries)
      ) {
        filteredIndustries = this.props.jobCreation.dropdowns.sourcingIndustries.filter(item => (
            item 
              && item.sourcingindustriesId 
              && selectedIndustriesArr.indexOf(item.sourcingindustriesId + '') >=0
        ));
      }
    
    let modifiedList = filteredIndustries.map(item => (
      {
        label: item.sourcingindustriesName,
        value: item.sourcingindustriesId 
      }
    ));

    return modifiedList;
  }

  componentDidMount() {
    const {
      match: {
        params: {
          stageIndex = 0,
          jobId = null,
        } = {},
      } = {},
      user: {
        isAdmin,
        isSuperAdmin,
        company: {
          companyId: userCompId,
          companyName: userCompName,
          ctypeId,
        } = {},
      } = {},
    } = this.props;
    const dataVals = {
      activePage: 1,
      itemsPerPage: -1,
      keyword: '',
    };
    this.props.getCitiesList(dataVals);
    this.setState({ stageIndex: Number(stageIndex), disableNext: false, jobId }, () => {
      this.props.xSearchCompanies('', 2, 0).then((res) => {
        if (res && !res.error) {
          const options = res.map(one => ({ label: one.companyName, value: one.companyId }));
          this.setState({
            list: options,
          });
        }
      });
      if (isAdmin === true && isSuperAdmin !== true && ctypeId && Number(ctypeId) === 2) {
        const companyValue = { label: userCompName, value: userCompId && Number(userCompId)}
        this.setState({
          companyValue,
        }, () => {
          this.props.fetchAllOffices(this.state.companyValue && this.state.companyValue.value);
        });
      }
      // const instData = {
      //   itemsPerPage: -1,
      //   activePage: 1,
      //   keyword: '',
      // };
      // this.props.fetchAllInstitutionsList(instData);
      if( jobId !== null ) this.props.getJobInfo({ jobId }).then( res => {
        let selectedLanguages = [];
        const { jobs: [job = null] = [] } = res || {};
        if ( job !== null ) {
          let langData = {};
          if (job.joblanguages && Array.isArray(job.joblanguages) && job.joblanguages.length > 0) {
            job.joblanguages.forEach((res) => {
              const {
                jobLangId,
                rating,
                language: {
                  languageId,
                  languageFull,
                } = {},
                languagetype: {
                  langTypeId,
                } = {},
              } = res || {};
              const data = {
                langTypeId,
                languageFull,
                jobLangId,
                rating,
                languageId,
              };
              if (langData[languageId]) {
                langData[languageId].languagesType.push(rating);
              } else {
                langData[languageId] = {
                  ...data,
                  languagesType: [rating],
                };
              }
            });
          }
          const keys = langData && Object.keys(langData);
          let selectedLanguages = [];
          if (keys && Array.isArray(keys) && keys.length > 0) {
            selectedLanguages = keys.map((k) => {
              const {
                langTypeId,
                languageFull,
                jobLangId,
                languagesType,
                languageId,
              } = langData[k] || {};
              const data = {
                language: languageFull, written: languagesType && languagesType[0], verbal: languagesType && languagesType[1], id: languageId,
              }
              return data;
            })
          }
          this.setState({
            ...job,
            isEditJobCase: true,
            title: job && job.jobname && job.jobname.nameName ? job.jobname.name : null,
            skillsmusthave: job.skills.filter(skill => (skill && (skill.requirementId == 1)) ).map(skill => ({label:skill && skill.skill && skill.skill.skillName, value:skill && skill.skill && skill.skill.skillId,})),
            skillsgoodtohave: job.skills.filter(skill => (skill && (skill.requirementId == 2)) ).map(skill => ({label:skill && skill.skill && skill.skill.skillName, value:skill && skill.skill && skill.skill.skillId,})),
            skillsdesired: job.skills.filter(skill => (skill && (skill.requirementId == 3)) ).map(skill => ({label:skill && skill.skill && skill.skill.skillName, value:skill && skill.skill && skill.skill.skillId,})),                            
            term: job && typeof job.term === 'object' ? job.term : '',
            seniority: job && job.jobseniority && job.jobseniority.seniorityName,
            hourType: job && job.hourtype && job.hourtype.typeName,
            currency: job && job.currency && job.currency.currencyFull,
            country: { value: job.countryId, label: job.country.countryFull },
            selectedLanguages,
            'function': job && job.jobfunction && job.jobfunction.functionName,
          }, () => {
            this.handleCityData(job);
            this.setLangs();
          });
        }
        this.setBaseInformation();
        this.setDropDownValues();
      });
      this.props.getAuthorizedCompanies({limit : -1});
      this.onLanguagesInputChange();
      this.props.fetchDropdownData().then(() => {
        this.setBaseInformation();
        // if (this.state.jobId && this.state.jobId !== null) this.props.getJobInfo({ jobId: this.state.jobId });
      });
    });
    this.props.getAuthorizedUsers();
  }

  handleCityData = (job) => {
    const {
      cities,
    } = this.props;
    const citiesData = cities && Array.isArray(cities)
    && cities.length > 0
    && cities.filter(e => (e.cityId) == (job && job.cityId));
  const cityObj = citiesData && Array.isArray(citiesData)
    && citiesData.length > 0 && citiesData[0];
   const region = { value: cityObj && cityObj.cityId && Number(cityObj.cityId), label: cityObj && cityObj.cityName};
   this.setState({
     region,
   });
  }

  setJobQuestions = (jobQuestionBank, action) => {
    let { jobQuestions = [] } = this.state;
    const { jobCreation: { questions: { jobQuestions: questions } = {} } = {} } = this.props;
    jobQuestions = Array.isArray(jobQuestions) && jobQuestions.map(one => one.hasOwnProperty('jobquestionbank')
      ? {
        question: one.jobquestionbank.question,
        jobQuestionBankId: one.jobQuestionBankId,
        jobQuestionId: one.jobQuestionId,
        jobQuestionType: 'written',
        isRequired: true,
      } : one
    );
    jobQuestions = Array.isArray(jobQuestions) && jobQuestions.map(({ question, jobQuestionBankId, jobQuestionId }) => (
      {
        question,
        jobQuestionBankId,
        jobQuestionBank: question,
        jobQuestionId,
        jobQuestionType: 'written',
        isRequired: true,
      }
    ));
    if (action === 'add') {
      const questionExist = questions.filter(({ question }) => question === jobQuestionBank);
      const {
        jobQuestionBankId = null,
        jobQuestionId = null,
      } = Array.isArray(questionExist) && questionExist.length > 0 ? questionExist[0] : {};
      jobQuestions.push({
        jobQuestionBank,
        question: jobQuestionBank,
        jobQuestionType: 'written',
        isRequired: true,
        jobQuestionId,
        jobQuestionBankId,
      });
    } else {
      jobQuestions = jobQuestions.filter(({ question }) => question !== jobQuestionBank);
    }
    this.setState({ jobQuestions }, () => {
      const { questions: { wrappedInstance: { setJobQuestions = null } = {} } = {} } = this.refs
      if (setJobQuestions !== null) {
        setJobQuestions(this.state.jobQuestions);
      }
    });
  }

  onLanguagesInputChange = (value = '') => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.props.fetchAll({
        actionType: REQUEST_ALL_LANGUAGES,
        apiName: 'Languages',
        paramName: 'languageFull',
        value: '',
      });
    }
  }

  setBaseInformation() {
    userData.fetchAll({
      actionType: REQUEST_USERINFO,
      apiName: 'userinfo-base/profileInformation',
    });
    if(this.state.closeDate == null) {
      this.setState({ closeDate: moment().add(7, 'days') });
    }
    if (this.state.jobId !== null) {

      const locationState = {
        ...this.state,
        ...this.props.location.state,
        currencyId: this.state.currencyId,
        closeDate: this.state.closeDate ? moment(this.state.closeDate).utc() : moment().utc(),
      };

      let jobDescEditorState = EditorState.createEmpty();
      let jobReqEditorState = EditorState.createEmpty();

      if (locationState.jobDesc) {
        const contentBlock = htmlToDraft(locationState.jobDesc);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          jobDescEditorState = EditorState.createWithContent(contentState);
        }
      }
      if (locationState.jobReq) {
        const contentBlockjobReq = htmlToDraft(locationState.jobReq);
        if (contentBlockjobReq) {
          const contentStatejobReq = ContentState.createFromBlockArray(contentBlockjobReq.contentBlocks);
          jobReqEditorState = EditorState.createWithContent(contentStatejobReq);
        }
      }
      const userId = this.props.currUser.user.userId;
      const title = locationState && locationState.jobname && locationState.jobname.nameName ? locationState.jobname.nameName : null;
      const isJobDescEmpty = jobDescEditorState.getCurrentContent().getPlainText().trimLeft().length > 0 ? false : true;
      const isJobReqEmpty = jobReqEditorState.getCurrentContent().getPlainText().trimLeft().length > 0 ? false : true;
      const foundPermissions = locationState.collaboration && locationState.collaboration.find(ele => (ele.userId == userId));
      const editFlag = foundPermissions && foundPermissions.roleId && foundPermissions.roleId == 4 ? true : false;
      const agencyName = locationState.recuitercompany && locationState.recuitercompany.companyName || '';
      const recuiterCompanyId = locationState.recuitercompany && locationState.recuitercompany.companyId || null;
      const recruiterCompany = locationState.recuitercompany && locationState.recuitercompany !== null ?
        { label: locationState.recuitercompany.companyName, value: locationState.recuitercompany.companyId } : { label: '', value: '' };
      const { state: { office = {}, officeId } } = this;
      const termValue = locationState.term && locationState.term !== null ? { label: locationState.term.termName, value: locationState.term.termId } : { label: '', value: '' };
      let { previousCompanies = [], excludeCompanies = [], currentCompany = {} } = locationState.sourcing || {};
      let { major = {}, secondMajor = {}, minumumGpaRequirement = '' } = locationState.jobAdditionalInfo || {};
      if (!major || major !== null) major = locationState.major || {};
      currentCompany = currentCompany !== null ? { label: currentCompany.companyName, value: currentCompany.companyId } : { label: '', value: '' };
      previousCompanies = previousCompanies !== null && Array.isArray(previousCompanies)
        && previousCompanies.map(({ companyId = '', companyName = '' } = {}) => ({ label: companyName, value: companyId }));
      excludeCompanies = excludeCompanies !== null && Array.isArray(excludeCompanies) && excludeCompanies.map(({ companyId = '', companyName = '' } = {}) => ({ label: companyName, value: companyId }));
      const industryValue = locationState.industry && locationState.industry !== null
        ? { label: locationState.industry.industryName, value: locationState.industry.industryId } : { label: '', value: '' };
      const sourcingIndustryValue = locationState.sourcingIndustries && locationState.sourcingIndustries !== null
      ? this.getSourcingIndustriesMeta(locationState.sourcingIndustries) : [];
      const firstMajor = major !== null ? { label: major.majorName, value: major.majorId } : { label: '', value: '' };
      secondMajor = secondMajor !== null ? { label: secondMajor.majorName, value: secondMajor.majorId } : { label: '', value: '' };
      const companyValue = locationState && locationState.institutionId ? { label: locationState && locationState.institution && locationState && locationState.institution.companyName, value: locationState && locationState.institutionId } : null;
      let {
        address: {
          addressLine1 = '',
          addressLine2 = '',
          zipcode = '',
          location: {
            locationName = '',
            country: { countryFull = '' } = {},
            state: { stateFull = '' } = {},
          } = {},
        } = {},
        addressId = '',
      } = office || {};
      addressLine1 = addressLine1 === null || addressLine1.length === 0 ? '' : `${addressLine1}, `;
      addressLine2 = addressLine2 === null || addressLine2.length === 0 ? '' : `${addressLine2}, `;
      locationName = locationName === null || locationName.length === 0 ? '' : `${locationName}, `;
      stateFull = stateFull === null || stateFull.length === 0 ? '' : `${stateFull}, `;
      countryFull = countryFull === null || countryFull.length === 0 ? '' : `${countryFull}, `;
      zipcode = zipcode === null || zipcode.length === 0 ? '' : `${zipcode}`;
      let label = `${addressLine1}${addressLine2}${locationName}${stateFull}${countryFull}${zipcode}`;
      label = label.replace(/Unkn, /g, '');
      label = label.trim(', ');
      const officeValue = { label, value: officeId };
      this.setState({
        ...this.state,
        ...locationState,
        editFlag,
        termValue,
        currentCompany,
        previousCompanies,
        excludeCompanies,
        firstMajor,
        secondMajor,
        companyValue,
        industryValue,
        recuiterCompanyId,
        recruiterCompany,title,
        agencyValue: recruiterCompany,
        agencyName,
        officeValue,
        sourcingIndustryValue,
        jobDescEditorState,
        jobReqEditorState,
        isJobDescEmpty,
        isJobReqEmpty,
        gpaScores: minumumGpaRequirement,
        jobLang: locationState.lang || 'en',
      }, () => {
        const industrypreferences = locationState.jobIndustryPreferences;
        if (Array.isArray(industrypreferences) && industrypreferences.length > 0) {
          const IndustryPreferences = industrypreferences
            .filter(({ industry: { industryName = '' } = {} } = {}) => industryName !== null && industryName.length > 0)
            .map(({ industry: { industryName = '' } = {} } = {}) => industryName);
          this.setState({ industrypreferences: IndustryPreferences });
        }
        const jobfunctions = locationState.jobFunctionPreferences;
        if (Array.isArray(jobfunctions) && jobfunctions.length > 0) {
          const jobFunctionPreferences = jobfunctions
            .filter(({ jobfunction: { functionName = '' } = {} } = {}) => functionName !== null && functionName.length > 0)
            .map(({ jobfunction: { functionName = '' } = {} } = {}) => functionName);
          this.setState({ jobfunctionpreferences: jobFunctionPreferences });
        }
        this.handleCityData(locationState);
        this.props.fetchCompanyId(this.state.companyId);
        this.props.fetchAllOffices(this.state.companyValue && this.state.companyValue.value);
        this.props.fetchAll({
          actionType: REQUEST_JOB_NAMES,
          apiName: 'jobnames',
          paramName: 'nameName',
          value: this.state.jobname && this.state.jobname.nameName,
        });
      });
    }
  }

  componentWillReceiveProps(props) {
    const { jobCreation = {}, dropdowns = {}, history: { location: { state = {} } = {} } = {} } = props;
    let { companies: [ company = {} ] } = jobCreation;
    const { companyName, companyId } = company;
    company = { label: companyName, value: companyId };
    if (this.state.companyId === 0 || companyId === 0) {
      this.setState(
        {
          ...this.state,
          dropdowns,
          companyName,
          companyId,
          company,
          officeId: (dropdowns && dropdowns.officeId) || 1,
        },
      );
    } else if (this.state.companyValue === undefined) {
      this.setState({...state}, () => {
        this.setDropDownValues();
      });
    }
  }

  setDropDownValues = () => {
    const { state } = this;
    const { jobCreation = {}, dropdowns = {} } = this.props;
    let { companies: [ company = {} ] } = jobCreation;
    const { companyName, companyId } = company;
    company = { label: companyName, value: companyId };
    const companyValue = company || { label: state.companyName, value: state.companyId };
    // const agencyValue = state.agencyValue || { label: state.agencyName, value: state.agencyId };
    const title =  state.title ? state.title : null;
    // const similarTitlesValue = state.similarTitles;
    const countryValue = state.country;
    const officeValue = state.officeValue || { label: '', value: '' };
    // const qualificationValue = { label: state.qualificationtype && state.qualificationtype.typeName, value: state.qualificationtype && state.qualificationtype.typeId };
    // const termValue = state.termValue || { lable: state.term, value: state.termId };
    // const jobSeneiorityValue = { label: state.seniority, value: state.seniorityId };
    // const hourTypeValue = { label: state.hourType, value: state.hourTypeId };
    const jobFunctionValue = { label: state.function, value: state.functionId };
    const industryValue = state.industryValue || { label: state.industryName, value: state.industryId };
    const companyTypeValue = { label: state.companyTypeName, value: state.companyTypeId };
    const currencyValue = { label: state.currency, value: state.currencyId };
    // const firstMajorValue = { label: state.major ? state.major.majorName : '', value: state.major ? state.major.majorId : '' };
    // const secondMajorValue = state.secondMajor || { label: state.secondMajorName, value: state.secondMajorId };
    // const internalCode = this.setInternalCode(jobValue.label);
    const internshipStartDate = moment(state.internshipStartDate && state.internshipStartDate !== null ? state.internshipStartDate : new Date());
    const internshipEndDate = moment(state.internshipEndDate && state.internshipEndDate !== null ? state.internshipEndDate : new Date());
    const {
      daysPerWeek,
      hoursPerDay,
      jobQuestions: questions,
      joblanguages: languages,
      companysize,
      website,
      jobPreferences = null,
    } = state;
    const {
      preferenceBackoffice = 0,
      preferenceTaskOriented = 0,
      preferenceTryNew = 0,
    } = jobPreferences !== null ? jobPreferences : {};
    const questionData = Array.isArray(questions) && questions.map(({ jobQuestionBankId, jobQuestionId, jobquestionbank: { question } }) => ({
      jobQuestionBankId,
      jobQuestionId,
      question,
    }));
    this.setJobsAdded(questionData);
    this.setState({
      // companyValue,
      // agencyValue,
      // jobValue,
      // similarTitlesValue,
      countryValue,
      officeValue,
      // qualificationValue,
      // termValue,
      // jobSeneiorityValue,
      // hourTypeValue,
      jobFunctionValue,
      industryValue,
      companyTypeValue,
      currencyValue,
      // firstMajorValue,
      // secondMajorValue,
      // internalCode,
      internshipStartDate,
      internshipEndDate,
      companysize,
      website,
      title,
      preferenceBackoffice,
      preferenceTaskOriented,
      preferenceTryNew,
      daysPerWeek: { value: daysPerWeek, label: `${daysPerWeek} ${daysPerWeek === 1 ? 'day' : 'days'}` },
      hoursPerDay: { value: hoursPerDay, label: `${hoursPerDay} ${hoursPerDay === 1 ? 'hour' : 'hours'}` },
    });
  }

  setJobsAdded = (questions) => {
    const jobQuestions = Array.isArray(questions)
      ? questions.map(({ question, jobQuestionBankId, jobQuestionId }) => (
        {
          question,
          jobQuestionBankId,
          jobQuestionId,
          jobQuestionType: 'written',
          isRequired: true,
        }
      )
    ) : [];
    this.setState({jobQuestions}, () => {
      const { questions: { wrappedInstance: { setJobQuestions = null } = {} } = {} } = this.refs;
      if (setJobQuestions !== null) {
        setJobQuestions(this.state.jobQuestions);
      }
    });
  }

 handlePasted = (pastedData, type) => {
    const params = {
      jobDesc: {
        limit: 4893,
        currState: 'jobDescEditorState',
        oldState: 'jobDescOldEditorState',
        empty: 'isJobDescEmpty',
      },
      jobReq: {
        limit: 2893,
        currState: 'jobReqEditorState',
        oldState: 'jobReqOldEditorState',
        empty: 'isJobReqEmpty',
      },
    };
  
    const Len = draftToHtml(convertToRaw(this.state[params[type].currState].getCurrentContent())).length;
    if (Len < params[type].limit && (Len + pastedData.length) > params[type].limit) {
      const sliceLen = params[type].limit - Len;
      pastedData = pastedData.slice(0, sliceLen);
    }
    if (Len < params[type].limit && (Len + pastedData.length) <= params[type].limit) {
      const blockMap = ContentState.createFromText(pastedData).blockMap;
      const newContentState = Modifier.replaceWithFragment(this.state[params[type].currState].getCurrentContent(), this.state[params[type].currState].getSelection(), blockMap);
      const newEditorState = EditorState.push(this.state[params[type].currState], newContentState, 'insert-fragment');
      this.setState({ 
        [params[type].currState]: newEditorState,
        [params[type].oldState]: newEditorState,
        [params[type].empty]: (pastedData && typeof pastedData == 'string' && pastedData.trimLeft().length>0)?false:true,
        [type]:draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
      });
    }
    return true;
  }

  closeTour() {
    this.setState({ productTourIsOpen: false });
  }

  onEditorStateChange = (jobEditorState, type) => {
    const params = {
      jobDesc: {
        limit: 4996,
        currState: 'jobDescEditorState',
        oldState: 'jobDescOldEditorState',
        empty: 'isJobDescEmpty',
      },
      jobReq: {
        limit: 2996,
        currState: 'jobReqEditorState',
        oldState: 'jobReqOldEditorState',
        empty: 'isJobReqEmpty',
      }
    };
    let HtmlContent = '';
    HtmlContent = draftToHtml(convertToRaw(jobEditorState.getCurrentContent()));
    if (HtmlContent.length <= params[type].limit) {
      const textLength = jobEditorState.getCurrentContent().getPlainText().trimLeft().length;
      this.setState({ 
        [params[type].currState]: jobEditorState,
        [params[type].oldState]: jobEditorState,
        [params[type].empty]: textLength>0?false:true,
        [type]: draftToHtml(convertToRaw(jobEditorState.getCurrentContent())),
      });
    } else {
      const OldStateHtml = draftToHtml(convertToRaw(this.state[params[type].oldState].getCurrentContent()));
      const textLength = this.state[params[type].oldState].getCurrentContent().getPlainText().trimLeft().length;
      const blocks = convertFromHTML(OldStateHtml);
      const state = ContentState.createFromBlockArray(blocks);
      const newEditorState = EditorState.createWithContent(state);
      this.setState({
        [params[type].currState]: newEditorState,
        [params[type].oldState]: newEditorState,
        [params[type].empty]: textLength > 0?false:true,
        [type]: draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
      });
    }
  };

  onCreateOptionClick = (name, value) => {
    if (value && typeof value === 'string' && value.trimLeft().length === 0) return;
    if (value && typeof value === 'string') value = value.trimLeft();
    const existingSkillData = this.state[name];
    const newSkill = {
      label: value,
      value: 1,
    };
    this.setState({
      [name]: existingSkillData.concat(newSkill),
      skillAdded: true,
      displayMessage: true,
      success: true,
      message: 'New skill created successfully!',
    });
    window.setTimeout(() => {
      this.setState({
        message: '',
        displayMessage: false,
      });
    }, 2000);
    // const actionData = {
    //   apiName: 'Skills',
    //   actionType: REQUEST_CREATE_NEW_SKILLS,
    //   data: {
    //     skillName: value,
    //     typeId: 1,
    //   },
    // };
    // this.props.createNewOption(actionData).then((res) => {
    //   const resp = res.data || {};
    //     if (resp.skillId) {

    //     }
    //   })
    //   .catch(this.displayError);
  };

  handleEdit = () => {
    this.setState({
      editFlag: !this.state.editFlag,
    }, () => {
      this.props.fetchCompanyId(this.state.companyId);
      this.props.fetchAllOffices(this.state.companyId);
    });

  };

  displayError = () => {
    this.setState({
      displayMessage: true,
      error: true,
      message: 'Sorry an error occured, please try again!',
    });
    window.setTimeout(() => {
      this.setState({
        displayMessage: false,
      });
    }, 2000);
  };

  hideLoadingAndRedirect = (res) => {
    const jobId = res.data ? res.data.jobId : res.jobId;
    this.setState({
      jobId,
      loading: false,
      stageIndex: this.state.stages - 1,
    });
  };

  isValid = (info, type) => {
    let data = info || {};
    if (type === 'number') return info && info !== null && info.toString().length > 0 ? true : false;
    if (type === 'string') return info && info !== null && info.length > 0 ? true : false;
    if (type === 'array') data = info && Array.isArray(info) ? info[0] || {} : {};
    const { value = null, label = null } = data;
    if (value !== null && label !== null && value !== '' && label !== '') return true;
    else return false;
  }

  saveJobData = (e) => {
    // e.preventDefault();
    this.setState({ loading: true });
    // remove other params from skillsmusthave skillsgoodtohave skillsdesired
    const skillsmusthave = this.state.skillsmusthave;
    const { nameId } = this.state;
    const {
      user: {
        isSuperAdmin,
        currentCompany: {
          companyId: currComId,
        } = {},
      } = {},
    } = this.props;
    const {
      dropdowns,
      skillAdded,
      loading,
      message,
      displayMessage,
      skill,
      skills,
      agencyId,
      displayCompanyName,
      recuiterCompanyId,
      companyValue,
      agencyValue,
      jobValue,
      similarTitlesValue,
      countryValue,
      officeValue,
      qualificationValue,
      termValue,
      jobSeneiorityValue,
      hourTypeValue,
      jobFunctionValue,
      industryValue,
      sourcingIndustryValue,
      companyTypeValue,
      currencyValue,
      firstMajorValue,
      secondMajorValue,
      currentCompany,
      previousCompanies,
      excludeCompanies,
      gpaScores,
      recuiterCompany,
      jobLang,
      preferenceTaskOriented = 0,
      preferenceTryNew = 0,
      preferenceBackoffice = 0,
      langsData,
      jobQuestions,
      internshipStartDate,
      internshipEndDate,
      hoursPerDay,
      daysPerWeek,
      region,
      ...otherParams
    } = this.state;

    // if(this.isValid(companyValue, 'object') === false || this.isValid(officeValue, 'object') === false || this.isValid(jobValue, 'object') === false
    //   || this.isValid(countryValue, 'object') === false || this.isValid(this.state.skillsmusthave, 'array') === false || this.isValid(termValue, 'object') === false
    //   || this.isValid(jobSeneiorityValue, 'object') === false || this.isValid(hourTypeValue, 'object') === false ||this.isValid(jobFunctionValue, 'object') === false
    //   || this.isValid(currencyValue, 'object') === false || this.isValid(qualificationValue, 'object') === false || this.state.closeDate === '' || this.state.closeDate === null) return this.setState({ missingFields: true, loading: false });

    let params = {};
    if (typeof nameId === 'object') {
      const newJobTitleParams = {
        nameId: 0,
        apiMeta: {
          Jobname: {
            nameName: nameId.label,
          },
          Hireteam: {
            date: moment().format(),
          },
        },
      };

      params = Object.assign({}, { ...otherParams }, newJobTitleParams, {
        skillsmusthave,
      });
      // this.props
      //   .saveJob(params)
      //   .then(this.hideLoadingAndRedirect)
      //   .catch(this.displayError);

    } else {
      const apiMeta = {
        apiMeta: {
          Hireteam: {
            date: moment().format(),
          },
        },
      };

      params = Object.assign(
        {},
        { ...otherParams },
        { ...apiMeta },
        {
          skillsmusthave,
        },
      );

      // this.props
      //   .saveJob(params)
      //   .then(this.hideLoadingAndRedirect)
      //   .catch(this.displayError);
    }

    const sourcing = {
      previousCompanyNames: previousCompanies && Array.isArray(previousCompanies) ? previousCompanies.map(cmp => cmp.label) : [],
      excludeCompanyNames: excludeCompanies && Array.isArray(excludeCompanies) ? excludeCompanies.map(cmp => cmp.label) : [],
      currentCompanyName: currentCompany && currentCompany.label ? currentCompany.label : '',
    };
    const jobAdditionalInfo = {
      firstMajor: firstMajorValue && firstMajorValue.label ? firstMajorValue.label : '',
      secondMajor: secondMajorValue && secondMajorValue.label ? secondMajorValue.label : '',
      minumumGpaRequirement: gpaScores,
      companyTypeName: industryValue && industryValue.label ? industryValue.label : '',
    };
    if (isSuperAdmin === true) {
      params.companyId = currComId;
    }
    params.jobAdditionalInfo = jobAdditionalInfo;
    params.sourcing = sourcing;
    params.sourcingIndustries = sourcingIndustryValue && Array.isArray(sourcingIndustryValue) ? (
      sourcingIndustryValue.map((eachSource) => (eachSource && eachSource.value)).join(',')
    ) : null ;
    params.institutionId = companyValue && companyValue.value ? companyValue.value : '';
    params.agencyId = agencyValue && agencyValue.value ? agencyValue.value : '';
    // params.nameId = jobValue && jobValue.value ? jobValue.value : '';
    params.title = this.state.title ? this.state.title : '';
    params.countryId = countryValue && countryValue.value ? countryValue.value : '';
    params.cityId = region && region.value ? region.value : '';
    params.officeId = officeValue && officeValue.value ? officeValue.value : '';
    params.qualTypeId = qualificationValue && qualificationValue.value ? qualificationValue.value : '';
    params.termId = termValue && termValue.value ? termValue.value : '';
    params.seniorityId = jobSeneiorityValue && jobSeneiorityValue.value ? jobSeneiorityValue.value : '';
    params.hourTypeId = hourTypeValue && hourTypeValue.value ? hourTypeValue.value : '';
    params.functionId = jobFunctionValue && jobFunctionValue.value ? jobFunctionValue.value : '';
    params.industryName = industryValue && industryValue.label ? industryValue.label : '';
    params.companyTypeName = companyTypeValue && companyTypeValue.label ? companyTypeValue.label : '';
    params.currencyId = currencyValue && currencyValue.value ? currencyValue.value : '';
    params.firstMajor = firstMajorValue && firstMajorValue.label ? firstMajorValue.label : '';
    params.secondMajor = secondMajorValue && secondMajorValue.label ? secondMajorValue.label : '';
    params.fbnumber = preferenceBackoffice;
    params.currentCompanyName = currentCompany && currentCompany.label ? currentCompany.label : '';
    params.previousCompanies = previousCompanies && Array.isArray(previousCompanies) ? previousCompanies.map(cmp => cmp.value) : [];
    params.excludeCompanies = excludeCompanies && Array.isArray(excludeCompanies) ? excludeCompanies.map(cmp => cmp.value) : [];
    params.minumumGpaRequirement = this.state.gpaScores;
    params.recuiterCompanyId = agencyValue && agencyValue.value ? agencyValue.value : '';
    params.recruiterCompany = agencyValue;
    params.lang = jobLang || 'en';
    params.preferences = {
      preferenceTryNew,
      preferenceTaskOriented,
      preferenceBackoffice,
    };
    params.recruiterId = this.props.user.userId;
    // if (params.title === undefined || params.title.length === 0) {
    //   params.title =  this.state.nameName ||this.state.name;
    // }
    // if (params.title === undefined || params.title.length == 0 && params.jobname && params.jobname.nameName) {
    //   params.title =  params.jobname.nameName;
    // }

    params.skillsmusthave = params && params.skillsmusthave.filter(skill => skill && skill.label).map(skill => (
      {
        skill: skill && skill.label,
        type: 1,
      }
    ));
    params.skillsdesired = params && params.skillsdesired.filter(skill => skill && skill.label).map(skill => (
      {
        skill: skill && skill.label,
        type: 1,
      }
    ));
    params.skillsgoodtohave = params && params.skillsgoodtohave.filter(skill => skill && skill.label).map(skill => (
      {
        skill: skill && skill.label,
        type: 1,
      }
    ));
    // params.recuiterCompanyId = this.state.agencyValue ? this.state.agencyValue.value : agencyId ? agencyId: recuiterCompanyId;
    // params.countryId = this.state.countryValue ? this.state.countryValue.value : params.country;
    params.displayCompanyName = displayCompanyName;
    delete params.country;
    delete params.jobDescEditorState;
    delete params.jobReqEditorState;
    delete params.jobDescOldEditorState;
    delete params.jobReqOldEditorState;
    const data = [];
    const langs = this.state.selectedLanguages.map(({ language, written, verbal }) => {
      data.push({ language, langType: 'Written', rating: written });
      data.push({ language, langType: 'Verbal', rating: verbal });
    });
    // params.joblanguages = data.map(o => o);
    params.languages = data.map(o => o);
    params.jobQuestions = jobQuestions;
    params.internshipStartDate = internshipStartDate;
    params.internshipEndDate = internshipEndDate;
    // params.hoursPerDay = hoursPerDay && hoursPerDay.value ? hoursPerDay.value : 0;
    // params.daysPerWeek = daysPerWeek && daysPerWeek.value ? daysPerWeek.value : 0;


    if (this.state.isEditJobCase) {
      this.props.editJob(params)
      // .then((resp) => {
      //   if(resp.jobId)
      //     this.props.associateJd(resp.jobId);
      //   else
      //   this.props.associateJd(params.jobId);
      // })
      .then(this.hideLoadingAndRedirect)
      .catch(this.displayError);
    } else {
      delete params.jobId
      this.props.saveJob(params)
      // .then((resp) => {
      //   if(resp && resp.data && resp.data.jobId)
      //     this.props.associateJd(resp.data.jobId);
      //   else
      //   this.props.associateJd(params.jobId);
      // })
      .then(this.hideLoadingAndRedirect)
      .catch(this.displayError);
    }


  };

  
  handleDateChange = (date, name) => {
    this.setState({
      [name]: date,
    });
  };

  clickOutside = () => {
    this.refs.picker.cancelFocusInput();
    this.refs.picker.setOpen(false);
  }


  handleFileChange = (e) => {
    const ext = e.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['pdf', 'doc', 'docx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    if (isFileAllowed) {
      const {
        target: { files, value },
      } = e;

      const formData = new FormData();
      formData.append('file', files[0]);
      this.setState(
        {
          jobDescFile: value,
          loading: true,
        },
        () => {
          this.uploadFile(formData);
        },
      );
    } else {
      alert('Please upload valid file type, allowed files are PDF, Word');
    }
  };

  onDatePickerChange = (date) => {
    const [startPeriod = '', endPeriod = ''] = date;
    const internshipStartDate = moment(startPeriod).format('YYYY-MM-DD');
    const internshipEndDate = moment(endPeriod).format('YYYY-MM-DD');
    this.setState({ internshipEndDate, internshipStartDate  });
  }

  onMajorDropdownChange = (value) => {
    this.props.getFirstFiveMatchInTableCol('Majors', 'majorName', value);
  }

  uploadFile = (data) => {
    window.data = data;
    this.props.parseJd(data).then(() => {
      this.setState({ loading: false });
      const { state } = this;
      const values = this.props.jobCreation;
      const {
        dropdowns: {
          countries = [],
          currencies = [],
          hourTypes = [],
          jobFunctions = [],
          industries = [],
          terms = [],
          jobSeniorities = [],
          qualificationTypes = [],
          companyTypes = [],
        },
      } = this.props;
      const { 
        skill = [],
        companyDesc = '',
        country = '',
        closeDate = '',
        currency = '',
        department = '',
        education = '',
        // function = '',
        hour = '',
        jobDesc = '',
        jobName = [],
        jobReq = '',
        location = '',
        maxSalary = 0,
        minSalary = 0,
        name = '',
        requiredExp = 0,
        seniority = '',
        numberVacancies = '',
        term = '',
       } = values;

      const jobValue = { 
        value: Array.isArray(jobName) && typeof jobName[0] === 'object' ? jobName[0].nameId : '' ,
        label: Array.isArray(jobName) && typeof jobName[0] === 'object' ? jobName[0].nameName : ''
      };

      const title = Array.isArray(jobName) && typeof jobName[0] === 'object' ? jobName[0].nameName : '';

      let clsDate = closeDate !== null || closeDate === '' ? moment().add(7, 'days') : moment(values.closeDate); 
      
      let jobdes = jobDesc;
      let jobreq = jobReq;
      
      if (jobdes && jobdes.length > 4900) jobdes = jobdes.substr(0, 4899);
      if (jobreq && jobreq.length > 2900) jobreq = jobreq.substr(0, 2899);
      
      let isJobDescEmpty = true;
      let isJobReqEmpty = true;
      
      if (jobdes && typeof jobdes === 'string' && jobdes.trimLeft().length > 0) isJobDescEmpty = false;
      if (jobreq && typeof jobreq === 'string' && jobreq.trimLeft().length > 0) isJobReqEmpty = false;
      
      let jobDescEditorState = EditorState.createEmpty();
      let jobReqEditorState = EditorState.createEmpty();     
      
      if (jobdes) {
        const contentBlock = htmlToDraft(jobdes);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          jobDescEditorState = EditorState.createWithContent(contentState);
        }
      }
      if (jobreq) {
        const contentBlockjobReq = htmlToDraft(jobreq);
        if (contentBlockjobReq) {
          const contentStatejobReq = ContentState.createFromBlockArray(contentBlockjobReq.contentBlocks);
          jobReqEditorState = EditorState.createWithContent(contentStatejobReq);
        }
      }

      let countryValue = country !== null && country !== ''
        ? countries.find(c => (country === c.countryShort || country === c.countryFull)) : {};
      countryValue = { value: countryValue.countryId, label: countryValue.countryFull };

      let currencyValue = currency !== null && currency !== ''
        ? currencies.find(c => (currency === c.currencyShort || currency === c.currencyFull)) : {};
      currencyValue = { value: currencyValue.currencyId, label: currencyValue.currencyFull };

      let jobFunctionValue = values.function !== null && values.function !== ''
        ? jobFunctions.find(c => values.function === c.functionName) : {};
      jobFunctionValue = { value: jobFunctionValue && jobFunctionValue.functionId, label: jobFunctionValue && jobFunctionValue.functionName };

      let hourTypeValue = hour !== null && hour !== ''
        ? hourTypes.find(h => hour === h.typeName) : {};
      hourTypeValue = { label: hourTypeValue.typeName, value: hourTypeValue.typeId };

      let jobSeneiorityValue = requiredExp !== null && requiredExp !== ''
        ? jobSeniorities.find(js => js.seniorityName.indexOf(requiredExp) > -1 ) : {};
      jobSeneiorityValue = { label: jobSeneiorityValue.seniorityName, value: jobSeneiorityValue.seniorityId };

      const skillsmusthave = skill !== null && Array.isArray(skill)
        ? skill.map((s, i) => ({ label: s, value: i })) : [];


      let termValue = term !== null && term !== ''
        ? terms.find(t => term === t.termName) : {};
      termValue = { label: termValue.termName, value: termValue.termId };

      const internalCode = this.setInternalCode(name);

      const Dataval = {
        jobValue,
        countryValue,
        currencyValue,
        jobFunctionValue,
        hourTypeValue,
        jobSeneiorityValue,
        termValue,
        skillsmusthave,
        internalCode,
        region: location,
        maxSalary,
        minSalary,
        nameName: name,
        name,
        jdLocation: location,
        termId: term,
        jobDesc: jobdes,
        jobReq: jobreq,
        jobDescEditorState,
        title,
        jobReqEditorState,
        currencyId: currency,
        vacancy: numberVacancies,
        closeDate: clsDate,
        isJobDescEmpty,
        isJobReqEmpty,
      };
      // department: values.function,
      // skillsmusthave: skillsmusthave,

      const newState = Object.assign({}, { ...state }, { loading: false, ...values }, { ...Dataval }, { ...jobValue });
      this.setState({ ...newState }, this.returnDropdownId);
    });

    this.setState({
      jobDescFile: '',
      stageIndex: 1,
    }, () => {
      const { jobId = null, stageIndex = 1 } = this.state;
      this.props.history.replace(`/app/campus/jobs/new/${stageIndex}${jobId !== null ? `/${jobId}` : '' }`);
    });
  };

  returnDropdownId = () => {
    const { state } = this;

    Object.keys(propertyMapper).map((key) => {
      const valueFromState = state[key];
      const name = `${key}Id`;

      if (valueFromState) {
        if (typeof valueFromState === 'number') {
          this.setState({
            [name]: valueFromState,
          });
        } else {
          const dropdownValue = state.dropdowns[propertyMapper[key]];
          const obj = dropdownValue.filter(val => val[`${key}Name`] === valueFromState)[0];
          const indexValue = dropdownValue.indexOf(obj);

          // value exists
          if (indexValue !== -1) {
            this.setState({
              [name]: dropdownValue[indexValue][name],
            });
          } else if (name === 'nameId') {
            this.setState({
              nameId: 0,
            });
          }
        }
      }
    });
  };

  // incase.
  progress = (progressEvent) => {
    const percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total);
  };

  handleSkillAdd = (state, skill) => {
    if (this.state[state].includes(skill)) {
      return;
    }
    this.setState({
      [state]: [...this.state[state], skill],
    });
  };

  handleChange = (e, param) => {
    // const jobDetails = ['jobDesc', 'jobReq'];
    const numberField = ['minSalary', 'maxSalary', 'vacancy', 'gpaScores'];
    if (e.target && e.target.name !== undefined && e.target && e.target.value !== undefined) {
      const {
        target: { name, value },
      } = e;
      // !jobDetails.includes(name)
      if (value && typeof value === 'string' && value.trimLeft().length === 0) return;
      if (numberField.includes(name)) {
        this.setState({
          [name]: value.charAt(0) === '0' ? '' : value,
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [param]: e,
      });
    }
  };

  handleTitleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  setInternalCode=(label = null) => {
    if (label !== null && label.length > 0) {
      let internalCode = label;
      internalCode = internalCode.match(/\b(\w)/g);
      internalCode = internalCode.join('');
      this.setState({ internalCode });
      return internalCode;
    }
  }

  jobTitleDropdown = (metaValue, name) => {
    if (metaValue && (metaValue.label === metaValue.value)) {
      this.setState({
        nameId: metaValue,
        [name]: metaValue,
      });
      // this.props.createNewJobTitle()
    } else {
      this.setState({
        [name]: metaValue,
        nameId: metaValue !== null ? metaValue.value : '',
      }, () => this.setInternalCode( metaValue !== null ? metaValue.label : ''));
    }
    this.setState({
      nameName: metaValue && metaValue.label || '',
    });
  };


  similarTitlesDrpDwn = (similarTitles, name) => {
    let similarTitlesStr = '';
    similarTitlesStr = similarTitles.map(one => one.label);
    similarTitlesStr = similarTitlesStr.toString();
    similarTitlesStr = similarTitlesStr.replace(/,/g, ', ');
    this.setState({ similarTitles, [name]: similarTitles, similarTitlesStr });
  }

  companyDropdown = (value, metaValue, name, flag) => {
    this.setState({
      isCompanyChange: flag,
      companyId: value,
      [name]: metaValue,
      officeValue: {},
      office: {},
    }, () => {
      this.props.fetchAllOffices(value);
    });
    if (this.state.agencyValue && this.state.agencyValue.value && this.state.agencyValue.value === value) {
      this.setState({
        recuiterCompanyId: null, agencyName: '', officeValue: {},
        companyName: metaValue && metaValue.label, agencyValue: {},
        recruiterCompany:{},
     });
    }
  };

  agencyDropdown = (value, metaValue, name, flag) => {
    this.setState({
      isAgencyChange: flag,
      recuiterCompanyId: value,
      [name]: metaValue,
    });
    this.setState({
      agencyName: metaValue && metaValue.label,
      agencyId: metaValue && metaValue.value,
      recuiterCompanyId: metaValue && metaValue.value,
    });
  };

  onInputCompanyDropdown = (value, cb) => {
    if (/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value)) {
      if (value.length >= 2) {
        this.props.getAllCompanies({
          apiName: 'companies-base/companies',
          paramName: 'companyName',
          value,
        }).then( res => {
          if (res && Array.isArray(res) && cb && typeof cb === 'function') {
            const options = res.map(one=>({ label: one.company_name, value: one.company_id }));
            cb(options);
          }
        });
      }
      return value;
    }
  };

  officeCountryDropdown = (value, metaValue) => {
    this.setState({ officeCountry: metaValue });
  };

  countryDropdown = (value, metaValue = {}, name) => {
    const {
      dropdowns: { currencies = [] } = {},
    } = this.props;
    const currencyfind = currencies !== null && metaValue !== null ? (currencies.find(curr => metaValue.value == curr.countryId))|| {} : {};
    const currencyValue = {
      value: currencyfind.currencyId,
      label: `${currencyfind.currencyFull} (${currencyfind.currencyShort})`,
    };
    this.setState({
      [name]: metaValue,
      country: value,
      countryName: metaValue !== null ? metaValue.label : '',
      currencyValue,
      region: null,
    });
  };

  searchTimerSchVal = null;

  onInputJobTitleDropdown = (value, cb) => {
    if (/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value)) {
      if (this.searchTimerSchVal) {
        clearTimeout(this.searchTimerSchVal);
      }
      if (value.length > 2) {
        this.searchTimerSchVal = setTimeout(() => (
        this.props.fetchAll({
          actionType: REQUEST_JOB_NAMES,
          apiName: 'jobnames',
          paramName: 'nameName',
          value,
        }).then(res => {
          const options = res && Array.isArray(res) && res.map(one =>({ label: one.nameName, value: one.nameId }));
          cb(options);
        })), 1000);
      }
      return [{label: value, value: 1}];
    }
  };

  onNewOptionCreate = (name, value, params) => {
    let actionData = {};
    if (params === 'title') {
      actionData = {
        apiName: 'Jobnames',
        actionType: REQUEST_CREATE_NEW_JOB_TITLE,
        data: {
          nameName: value,
          companyId: this.props.dropdowns.company.companyId,
        },
      };
    } else if ( params && params === 'company' || params.includes('company')) {
      actionData = {
        apiName: 'Companies',
        actionType: REQUEST_CREATE_NEW_COMPANY,
        data: {
          companyName: value,
        },
      };
    }
    this.props.createNewOption(actionData).then((data) => {
      let res = {};
      if (data.data) res = data.data;
      else res = data;
      const option = {
        label: '',
        value: '',
      };
      if (params === 'title') {
        option.label = res.nameName; option.value = res.nameId;
        this.setInternalCode(res.nameName);
      }
      else {
        option.label = res.companyName; option.value = res.companyId;
      }

      this.setState({
        [name]: option,
      });
    });
  };

  onInputCountryDropdown = (value) => {
    const {
      cities,
    } = this.props;
    if (/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value)) {
      if (value.length > 2) {
        this.props.fetchAll({
          actionType: REQUEST_ALL_COUNTRIES,
          apiName: 'countries',
          paramName: 'countryFull',
          value,
        });
      }
      return value;
    }
  };
  
  

  onSkillsInputChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (value === null) {
      this.props.fetchAll({
        actionType: REQUEST_ALL_SKILLS,
        apiName: 'skills',
        paramName: 'skillName',
        value: _.toLower(null),
      }).then((res) => {
        const options = res.map(one => ({ label: one.skillName, value: one.skillId }));
        cb(options);
      });
      return _.toLower(value);
    } else {
      cb([]);
    }
  };

  handleSkillDropdownChange = (selectedOption, name) => {
    //let difference = this.state[name].filter(x => !value.includes(x));
    this.setState({
      [name]: selectedOption,
    });
  };

  handleDropdownChange = (value, metaValue, name) => {
    let { langsData = [] } = this.state;
    if (name === 'region' && value && value !== null && [4, 5, 6].includes(value)) {
      const { label: language = '' } = metaValue;
      if (Array.isArray(langsData) && langsData.filter(one => one.language === language).length === 0) {
        const lang = {
          language: 'Chinese',
          written: 2,
          verbal: 2,
          action: <Button name={langsData.length} kind="danger" small disabled>Delete</Button>,
          id: 50,
        };
        langsData = [lang];
      }
    }
    this.setState({
      [name]: metaValue,
    }, () => {
      if (name === 'region' && value && value !== null && [4, 5, 6].includes(value)) {
        this.setState({ langsData, selectedLanguages: langsData }, () => {
          this.setLangs();
        });
      }
    });
  };

  onSliderChange = ({ value }, name) => {
    if (name === null || value === null) return;
    this.setState({ [name]: value });
  }

  // dropdownValues = props => [
    // {
    //   id: 'dd1',
    //   title: 'Job Type',
    //   name: 'termValue',
    //   options: props.dropdowns.terms.map(item => ({
    //     value: item.termId,
    //     label: item.termName,
    //   })),
    //   value: this.state.termValue,
    // },
    // {
    //   id: 'dd2',
    //   title: 'Experience Level',
    //   name: 'jobSeneiorityValue',
    //   options: props.dropdowns.jobSeniorities.map(item => ({
    //     value: item.seniorityId,
    //     label: item.seniorityName,
    //   })),
    //   value: this.state.jobSeneiorityValue,
    // },
    // {
    //   id: 'dd3',
    //   title: 'Employment Type',
    //   name: 'hourTypeValue',
    //   options: props.dropdowns.hourTypes.map(item => ({
    //     value: item.typeId,
    //     label: item.typeName,
    //   })),
    //   value: this.state.hourTypeValue,
    // },
  //   {
  //     id: 'dd4',
  //     title: 'Job Function',
  //     name: 'jobFunctionValue',
  //     options: props.dropdowns.jobFunctions.map(item => ({
  //       value: item.functionId,
  //       label: item.functionName,
  //     })),
  //     value: this.state.jobFunctionValue,
  //   },
  // ];

  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'open', name: 'Jobs', href: '/app/campus/jobs' },
    { id: 'create', name: 'Create a job', href: '/app/campus/jobs/new/0' },
  ];

  onPreview = () => {
    let Data = {};
    Data.nameName = this.state.nameName;
    Data.nameId = this.state.nameId;
    if (Data.nameName === undefined && this.state.jobname && this.state.jobname.nameName)
      Data.nameName = this.state.jobname.nameName;
    //country
    if (this.state.country && this.state.country.label)
      Data.countryName = this.state.country.label;
    else if ((this.state.country || this.state.countryId) && this.state.dropdowns.countries) {
      const find = this.state.country || this.state.countryId;
      const cur = this.state.dropdowns.countries.find((ep) => {
        return (ep && ( ep.countryId == find || ep.countryFull == find || ep.countryShort == find));
      });
      if (cur) Data.countryName = cur.countryFull;
    }
    //Education
    if (this.state.qualificationtype && this.state.qualificationtype.typeName)
      Data.qualType = this.state.qualificationtype.typeName;
    else if (this.state.qualTypeId) {
      const qulification = this.state.dropdowns.qualificationTypes.find((quali) => {
        return (quali && quali.typeId == this.state.qualTypeId);
      });
      if (qulification) Data.qualType = qulification.typeName;
    }
    //title
    if (this.state.nameId) {
      const Name = this.props.jobCreation.jobName.find((jname) => {
        return (jname && jname.nameId==this.state.nameId);
      });
      Data = Object.assign({}, { ...Data }, { ...Name });
    }
    //seniority
    if ((this.state.seniorityId || this.state.requiredExp) && this.state.dropdowns.jobSeniorities) {
      const find = this.state.seniorityId || this.state.requiredExp;
      const Exp = this.state.dropdowns.jobSeniorities.find((ep) => {
        return (ep && (ep.seniorityId == find || ep.seniorityName == find));
      });
      if(Exp)
        Data.seniority = Exp.seniorityName;
    }
    //hourType
    if ((this.state.hourType || this.state.hourTypeId) && this.state.dropdowns.hourTypes) {
      const find = this.state.hourType || this.state.hourTypeId;
      const hour = this.state.dropdowns.hourTypes.find((ep) => {
        return (ep && ( ep.typeId == find || ep.typeName == find ));
      });
      if(hour)
        Data.hourType = hour.typeName;
    }
    //function
    if((this.state.function || this.state.functionId) && this.state.dropdowns.jobFunctions) {
      const find = this.state.function || this.state.functionId;
      const fun = this.state.dropdowns.jobFunctions.find((ep) => {
        return (ep && ( ep.functionId == find || ep.functionName == find ));
      });
      if (fun) Data.function = fun.functionName;
    }
    //term
    if ((this.state.term || this.state.termId) && this.state.dropdowns.terms) {
      const find = this.state.term || this.state.termId;
      const tem = this.state.dropdowns.terms.find((ep) => {
        return (ep &&( ep.termId == find || ep.termName == find ));
      });
      if(tem)
        Data.term = tem.termName;
    }
    //currency
    if((this.state.currency || this.state.currencyId) && this.state.dropdowns.currencies) {
      const find = this.state.currency || this.state.currencyId;
      const cur = this.state.dropdowns.currencies.find(ep=>{
        return (ep &&( ep.currencyId == find || ep.currencyFull == find ));
      });
      if(cur)
        Data.currency = cur.currencyFull;
    }
   
    Data.companyId = this.state.companyId;
    Data.companyName = this.state.companyName;
    if(Data.companyId==undefined && this.state.company && this.state.company.companyId)
      Data.companyId = this.state.company.companyId;
    if (Data.companyName ==undefined && this.state.company && this.state.company.companyName)
      Data.companyName = this.state.company.companyName;

    Data.recuiterCompanyId = this.state.recuiterCompanyId;
    Data.agencyName = this.state.agencyName;
      
    const newState = Object.assign({}, {...this.state}, {...Data}, {activePreview: !this.state.activePreview,});
    this.setState({...newState});
    Data.isPrivate = this.state.isPrivate;
  }

  companyNameBlured = () => {
    this.setState({
      editFlag: true,
    });
  }

  onToggle = () => {
    this.setState({ isPrivate: !this.state.isPrivate });
  }

  onLocationBasedToggle = () => {
    this.setState({ locationAgnostic: !this.state.locationAgnostic });
  }

  officeDropdown = (e) => {
    const officeValue = e;
    this.setState({ officeValue });
  }

  hideCompany = () => {
    this.setState({
      displayCompanyName: !this.state.displayCompanyName,
      recuiterCompanyId: null,
      recuiterCompany: {},
      agencyName: '',
    });
  }

  changeCheckBox = (flag, name) => {
    this.setState({ [name]: flag });
  }

  aiSourcingToggle = () => {
    this.setState({ aiSourcing: !this.state.aiSourcing });
  }

  onCloseOfficeModal = () => {
    this.setState({ modalError: false, officeAdd1: '', officeAdd2: '', officeRegion: '', officeZip: '', officeCountry: {} });
  }
  
  onAddoffice = (e) => {
    const {
      officeAdd1 = '', officeAdd2 = '', officeRegion = '', officeZip = '', officeCountry = {},
      companyValue = {},
    } = this.state;
    if (!officeAdd1 || !officeRegion || this.isValid(officeCountry, 'object') === false) return this.setState({ modalError: true })
    // const data = {
    //   officeName: companyValue.label,
    //   companyId: companyValue.value,
    //   type: 'office',
    //   addressId: 0,
    //   apiMeta: {
    //     Address: {
    //       addressLine1: officeAdd1,
    //       addressLine2: officeAdd2,
    //       zipcode: officeZip,
    //       apiMeta: {
    //         Location: {
    //           locationName: officeRegion,
    //           countryId: officeCountry.value ? officeCountry.value : '',
    //         },
    //       },
    //     },
    //   },
    // };
    const data = {
      officeName: companyValue.label,
      companyId: companyValue.value,
      type: 'office',
      addressId: 0,
      addressLine1: officeAdd1,
      addressLine2: officeAdd2,
      zipcode: officeZip,
      location: officeRegion,
      locationName: officeRegion,
      country: officeCountry.label ? officeCountry.label : '',
    };
    this.setState({ modalError: false, officeAdd1: '', officeAdd2: '', officeRegion: '', officeZip: '', officeCountry: {} }, () => {
      this.props.addOffice(data).then(
        () => this.props.fetchAllOffices(companyValue.value),
      );
    })
    return true;
  }

  handleCompanyDropdownChange = (value, name) => {
    this.setState({ [name]: value});
  }

  onPrevStep = () => {
    let { stageIndex } = this.state;
    // && !(this.stageVerify())
    if (stageIndex > 0) {
      stageIndex -= 1;
      this.setState({ stageIndex }, () => {
        const { jobId = null } = this.state;
      this.props.history.replace(`/app/campus/jobs/new/${stageIndex}${jobId !== null ? `/${jobId}` : '' }`);
      });
    }
  }

  onNextStep = () => {
    let { stageIndex, stages, jobQuestions } = this.state;
    if (stageIndex < stages - 1 && !(this.stageVerify())) {
      stageIndex += 1;
      this.setState({ stageIndex }, async () => {
        const { jobId = null } = this.state;
        await this.props.history.replace(`/app/campus/jobs/new/${stageIndex}${jobId !== null ? `/${jobId}` : '' }`);
        await this.setJobsAdded(jobQuestions);
      });
    }
  }


  stageVerify = () => {
    const {
      companyValue,
      officeValue,
      jobValue,
      countryValue,
      isJobDescEmpty,
      isJobReqEmpty,
      skillsmusthave,
      qualificationValue,
      termValue,
      jobSeneiorityValue,
      hourTypeValue,
      jobFunctionValue,
      currencyValue,
      vacancy = null,
      closeDate,
      stageIndex,
      displayCompanyName,
      agencyValue,
      hoursPerDay,
      daysPerWeek,
      internshipStartDate = '',
      internshipEndDate = '',
      companysize = '',
      region,
      preferenceTaskOriented = null,
      preferenceBackoffice = null,
      preferenceTryNew = null,
      industrypreferences,
      jobfunctionpreferences,
      langsData,
    } =  this.state;
    switch(stageIndex) {
      case 1: {
        let disableNext = (this.isValid(companyValue, 'object') && this.isValid(officeValue, 'object') && this.isValid(this.state.title, 'string')
        && this.isValid(countryValue, 'object') && this.isValid(region, 'object')) === false;
        if (displayCompanyName === true) disableNext = true;
        this.setState({ disableNext, missingFields: disableNext });
        return disableNext;
      } break;
      case 2:{
        const disableNext =  isJobDescEmpty && isJobReqEmpty;
        this.setState({ disableNext, missingFields: disableNext });
        return disableNext;
      } break;
      case 3:{
        const disableNext = (this.isValid(skillsmusthave, 'array') && this.isValid(Number(vacancy), 'number') &&
        this.isValid(internshipStartDate && moment(internshipStartDate).format(), 'string') && this.isValid(internshipEndDate && moment(internshipEndDate).format(), 'string') &&
        this.isValid(closeDate && moment(closeDate).format(), 'string')) === false;
        this.setState({ disableNext, missingFields: disableNext });
        return disableNext;
      } break;
      case 4:{
        const disableNext = (Array.isArray(industrypreferences) && industrypreferences.length > 0
          && Array.isArray(jobfunctionpreferences) && jobfunctionpreferences.length > 0) === false;
        this.setState({ disableNext, missingFields: disableNext });
        return disableNext;
      } break;
      default: return false;
    }
  }

  onMandarinRadioChange = (value, name) => {
    this.setState({[name]: value});
  }

  handleLangPaginationChange = ({ page, pageSize }) => {
    let { activeLangPage = 0, langLimit } = this.state;
    if (page > 1) {
      activeLangPage = (page - 1) * pageSize + 1;
      langLimit = activeLangPage + pageSize - 1;
    }
    this.setState({ activeLangPage, langLimit });
  };

  onAddLanguageClick = () => {
    const {
      langValue = null,
      langWritten = null,
      langVerbal = null,
    } = this.state;
    let { selectedLanguages = [] } = this.state;
    // selectedLanguages = !selectedLanguages || selectedLanguages === null ? [] : selectedLanguages;
    const data = {
      language: langValue.label,
      written: langWritten.value || 3,
      verbal: langVerbal.value || 3,
      id: langValue.value,
    };
    if (selectedLanguages && Array.isArray(selectedLanguages) && selectedLanguages.length <= 0) {
      selectedLanguages = [data];
      this.setState({
        showAddLang: false,
        langValue: {},
        langWritten: {},
        langVerbal: {},
        showLangErr: false,
        selectedLanguages,
      }, () => this.setLangs());
    } else {
      let finalArr = [];
      if (selectedLanguages && Array.isArray(selectedLanguages) && selectedLanguages.length > 0) {
        finalArr = selectedLanguages.find((res) => {
          const {
            language,
            written,
            verbal,
            id,
          } = res;
          if (id == langValue.value) {
            return false;
          }
          return true;
        });
        if (!finalArr) {
          this.setState({
            showLangErr: true,
          });
        } else {
          selectedLanguages.push(data);
          this.setState({
            showAddLang: false,
            langValue: {},
            langWritten: {},
            langVerbal: {},
            showLangErr: false,
            selectedLanguages,
          }, () => this.setLangs());
        }
      }
    }
    // const templang = Array.isArray(selectedLanguages) && langValue.label ? selectedLanguages.push({
    //   language: langValue.label,
    //   written: langWritten.value || 3,
    //   verbal: langVerbal.value || 3,
    //   id: langValue.value,
    // }) : [];

  }

  deleteLanguage = (e) => {
    const { target: { name = null } = {} } = e || {};
    if (name === null) return;
    let { selectedLanguages, langsData } = this.state;
    selectedLanguages = Array.isArray(selectedLanguages) && selectedLanguages.filter(({ id } = {}) => id != name);
    langsData = Array.isArray(langsData) && langsData.filter(({ id } = {}) => id != name);
    this.setState({ selectedLanguages, langsData }, () => {
      console.log('Waiting to delete');
    });
  }

  addLanguageModal = () => {
    this.setState({ showAddLang: true, langValue: {}, langWritten: {}, langVerbal: {} });
  }

  closeLangModal = () => {
    this.setState({ showAddLang: false, langValue: {}, langWritten: {}, langVerbal: {} });
  }

  setLangs= () => {
    const { selectedLanguages = [], region } = this.state;
    console.log('selectedLanguages', selectedLanguages);
    const langsData = Array.isArray(selectedLanguages) && selectedLanguages.map(({ language, written, verbal, id }) => {
      let finalVal = '';
      if (written && Number(written) === 1) {
        finalVal = 'Not required';
      } else if (written && Number(written) === 2) {
        finalVal = 'Basic';
      } else if (written && Number(written) === 3) {
        finalVal = 'required';
      }
      let speakVal = '';
      if (verbal && Number(verbal) === 1) {
        speakVal = 'Not required';
      } else if (verbal && Number(verbal) === 2) {
        speakVal = 'Basic';
      } else if (verbal && Number(verbal) === 3) {
        speakVal = 'required';
      }
      return ({
        language,
        written: finalVal,
        verbal: speakVal,
        action: <Button name={id} disabled={id && id == 50} kind="danger" small onClick={(e) => this.deleteLanguage(e)}>Delete</Button>,
        id,
      });
    });
    this.setState({ langsData });
  }

  setPreferences = (data, name) => {
    console.log(name.toLowerCase(), data);
    this.setState({ [name.toLowerCase()]: data });
  }


  render() {
    const { props } = this;
    const {
      history: { push },
      authorizedCompanies: {
        agencies = {},
      } = {},
      userInfo,
      dropdowns: {
        countries,
        company: {
          companyName,
        } = {},
      } = {},
      t,
      institutions,
      cities,
    } = props;

    const {
      company,
      companyValue,
      agencyValue,
      jobValue,
      similarTitlesValue,
      countryValue,
      officeValue,
      qualificationValue,
      firstMajorValue,
      secondMajorValue,
      termValue,
      jobSeneiorityValue,
      hourTypeValue,
      jobFunctionValue,
      currencyValue,
      companyTypeValue,
      industryValue,
      sourcingIndustryValue,
      currentCompany,
      previousCompanies,
      excludeCompanies,
      missingFields,
      officeCountry,
      stageIndex,
      stages,
      disableNext,
      jobId = null,
      lang,
      langsData,
      showAddLang,
      langValue,
      langVerbal,
      langWritten,
      industrypreferences,
      jobfunctionpreferences,
      jobPreferences,
      jobQuestions,
      hoursPerDay,
      daysPerWeek,
      internshipStartDate,
      internshipEndDate,
      website = null,
      companysize,
      region,
      closeDate,
      preferenceBackoffice = 0,
      preferenceTaskOriented = 0,
      preferenceTryNew = 0,
      showLangErr,
      companyDesc = null,
    } =  this.state;
    const finalInsArr = institutions && Array.isArray(institutions)
      && institutions.length > 0
      && institutions.map((res) => {
        const {
          _source: {
            companyName,
            companyId,
          } = {},
        } = res || {};
        const data = {
          label: companyName,
          value: companyId,
        };
        return data;
      })
    const langHeaders = [
      { key: 'language', header: `${t('language')}` },
      { key: 'written', header: `${t('writing')}` },
      { key: 'verbal', header: `${t('speaking')}` },
      { key: 'action', header: `${t('action')}` },
    ];

    let cityArr = cities && Array.isArray(cities)
    && cities.length > 0
    && cities.map((res) => {
      const {
        cityName,
        cityId,
      } = res || {};
      const data = {
        label: cityName,
        value: cityId,
      };
      return data;
    });

    const countryIds = cities && Array.isArray(cities)
    && cities.length > 0
    && cities.map((res) => {
      const {
        country: {
          countryId,
        } = {},
      } = res || {};
      return countryId;
    });

    let finalCountryId = [];

    if (countryIds && Array.isArray(countryIds) && countryIds.length > 0) {
      finalCountryId = [...new Set(countryIds)];
    }
    const filteredCountries = finalCountryId && Array.isArray(finalCountryId)
      && finalCountryId.length > 0
      && finalCountryId.map((res) => {
        const cityCountries = countries && Array.isArray(countries)
          && countries.length > 0 && countries.find(v => v && v.countryId == res);
        return cityCountries;
      });

    const countryArr = filteredCountries && Array.isArray(filteredCountries)
    && filteredCountries.length > 0
    && filteredCountries.map((res) => {
      const {
        countryFull,
        countryId,
      } = res || {};
      const data = {
        label: countryFull,
        value: countryId,
      };
      return data;
    });

    if (countryValue && countryValue.value) {
      const finalArr = cities.filter(res => res && res.country && res.country.countryId == countryValue.value);
      cityArr = finalArr && Array.isArray(finalArr)
      && finalArr.length > 0
      && finalArr.map((res) => {
        const {
          cityName,
          cityId,
        } = res || {};
        const data = {
          label: cityName,
          value: cityId,
        };
        return data;
      });
    } else {
      cityArr = cities && Array.isArray(cities)
      && cities.length > 0
      && cities.map((res) => {
        const {
          cityName,
          cityId,
        } = res || {};
        const data = {
          label: cityName,
          value: cityId,
        };
        return data;
      });
    }

    const previewFields = [
      {
        header: `${t('company')}`,
        field: [
          {
            label: `${t('name')}`,
            value: companyValue && companyValue.label ? companyValue.label : '',
          },
          // { label: 'Hiring by staffing agency', value: this.state.displayCompanyName?'Yes' : 'No' },
          // {
          //   label: 'Staffing Agency',
          //   value: this.state.agencyName || '-',
          // },
          {
            label: `${t('office')}`,
            value: officeValue && officeValue.label ? officeValue.label : '',
          },
          { label: `${t('website')}`, value: website },
          { label: `${t('companySize')}`, value: companysize },
          { label: `${t('companyDescription')}`, value: this.state.companyDesc ? this.state.companyDesc : '' },
        ],
      },
      {
        header: `${t('jobTitle')}`,
        field: [
          { label: `${t('title')}`, value: jobValue && jobValue.label ? jobValue.label : '' },
          // { label: 'Department', value: this.state.department },
          // { label: 'Internal Code', value: this.state.internalCode },
          // { label: 'Similar Titles', value: this.state.similarTitlesStr },
          // { label: 'Job Visibility', value: this.state.isPrivate ? 'Private' : 'Public' },
          // { label: 'Accept Applicants From Other Countries', value: this.state.locationAgnostic ? 'Yes' : 'No' },
        ],
      },
      {
        header: `${t('location')}`,
        field: [
          { label: `${t('country')}`, value: countryValue && countryValue.label ? countryValue.label : '' },
          { label: `${t('city')}`, value: this.state.region && this.state.region.label ? this.state.region.label : '' },
          // { label: 'Address', value: this.state.address },
        ],
      },
      {
        header: `${t('jobDescription')}`,
        field: [{ label: '', value: draftToHtml(convertToRaw(this.state.jobDescEditorState.getCurrentContent())) }],
      },
      {
        header: `${t('jobRequirements')}`,
        field: [{ label: '', value: draftToHtml(convertToRaw(this.state.jobReqEditorState.getCurrentContent()))}],
      },
      {
        header: `${t('skills')}`,
        field: [
          {
            label: `${t('mustHaveSkills')}`,
            value: this.state.skillsmusthave.filter(skill => skill && skill.label).map(skill => skill.label).toString().replace(/,/g,', '),
          },
          // {
          //   label: 'Good to Have Skills',
          //   value: this.state.skillsgoodtohave.filter(skill => skill && skill.label).map(skill => skill.label).toString().replace(/,/g,', '),
          // },
          // {
          //   label: 'Desirable Skills',
          //   value: this.state.skillsdesired.filter(skill => skill && skill.label).map(skill => skill.label).toString().replace(/,/g,', '),
          // },
        ],
      },
      // {
      //   header: 'Education',
      //   field: [
      //     { label: 'Minimum required qualification', value: qualificationValue && qualificationValue.label ? qualificationValue.label : '' },
      //     { label: 'First Major/Minor/Degree', value: firstMajorValue && firstMajorValue.label ? firstMajorValue.label : '' },
      //   ],
      // },
      {
        header: `${t('employmentDetails')}`,
        field: [
          // { label: 'Job Type', value: termValue && termValue.label ? termValue.label : '' },
          // { label: 'Experience Level', value: jobSeneiorityValue && jobSeneiorityValue.label ? jobSeneiorityValue.label : '' },
          // { label: 'Hour type', value: hourTypeValue && hourTypeValue.label ? hourTypeValue.label : '' },
          // { label: 'Job Function', value: jobFunctionValue && jobFunctionValue.label ? jobFunctionValue.label : '' },
          // { label: 'Work Hours Per Day', value: hoursPerDay && hoursPerDay.label ? hoursPerDay.label : '' },
          // { label: 'Days Per Week', value: daysPerWeek && daysPerWeek.label ? daysPerWeek.label : '' },
          { label: `${t('stipend')}`, value: this.state.minSalary },
          { label: `${t('currency')}`, value: currencyValue && currencyValue.label ? currencyValue.label : '' },
          { label: `${t('vacancies')}`, value: this.state.vacancy },
          {
            label: `${t('internshipPeriod')}`,
            value: `${moment(internshipStartDate).format('MM/DD/YYYY')} - ${moment(internshipEndDate).format('MM/DD/YYYY')}`,
            type: 'date',
          },
          {
            label: `${t('closingDate')}`,
            value: moment(this.state.closeDate).format('MM/DD/YYYY') || moment().format('MM/DD/YYYY'),
            type : 'date',
          },
        ],
      },
      // {
      //   header: 'Annual Salary',
      //   field: [
      //     { label: 'Stipend', value: this.state.minSalary },
      //     { label: 'Currency', value: currencyValue && currencyValue.label ? currencyValue.label : '' },
      //     { label: 'No. of Vacancies', value: this.state.vacancy },
      //     {
      //       label: 'Closing date',
      //       value: moment(this.state.closeDate).format('MM/DD/YYYY') || moment().format('MM/DD/YYYY'),
      //       type : 'date',
      //     },
      //   ],
      // },
      {
        header: `${t('preferredApplicants')}`,
        field: [
          { label: `${t('taskOriented')}`, value: preferenceTaskOriented },
          { label: `${t('backend')}`, value: preferenceBackoffice },
          { label: `${t('learnSomethingNew')}`, value: preferenceTryNew },
        ],
      },
      {
        header: `${t('languageProficiency')}`,
        field: langsData.map(({ language, written, verbal }) => {
          return({
            label: language,
            value: <div>{verbal}<small className="ml-1">(verbal)</small>{' - '}{written}<small className="ml-1">(written)</small></div>,
          });
        }),
      },
      // {
      //   header: 'Job Questions',
      //   field: jobQuestions.map(({ question }, i) => {
      //     return({
      //       label: `Question ${i + 1}`,
      //       value: `${question}`,
      //     });
      //   }),
      // },
    ];

    if( this.props.user && this.props.user.roleId === 3) {
      previewFields[6].field.push({ label: 'Second Major/Minor/Degree', value: secondMajorValue && secondMajorValue.label ? secondMajorValue.label : '' });
      previewFields[6].field.push({ label: 'Minimum required GPA', value: this.state.gpaScores });
      previewFields[8].field.push({ label: 'Company Type', value: companyTypeValue && companyTypeValue.label ? companyTypeValue.label : '' });
      previewFields[9].field.push({ label: 'Task Oriented/Structured', value: this.state.jobScope });
      previewFields[9].field.push({ label: 'Willingness to travel', value: this.state.isTravelling ? 'Yes' : 'No' });
      previewFields[9].field.push({ label: 'Shift Hours', value: this.state.inShifts ? 'Yes' : 'No' });
    }

    if (this.state.company && this.state.company.companyName && this.state.company.companyId && companyValue && companyValue.label === undefined) {
      companyValue.value = this.state.company.companyId;
      companyValue.label = this.state.company.companyName;
    }

    const relatedCompanyNames=[];
    let relatedCompanies = (props && props.dropdowns && props.dropdowns.relatedCompanies)
      ? props.dropdowns.relatedCompanies.map((ele) => {
        relatedCompanyNames.push(ele.company.companyName);
        return ele.company;
      }) : [];
    let { companies } = [];
    if (this.state.collaboration && this.state.collaboration.length > 0) {
      companies = (props.jobCreation && props.jobCreation.companies && Array.isArray(props.jobCreation.companies))
        ? props.jobCreation.companies.filter(ele => relatedCompanyNames.indexOf(ele.companyName) < 0) : [];
      companies = companies.concat(relatedCompanies);
    } else companies = relatedCompanies;
    const offices = props.jobCreation && props.jobCreation.offices && Array.isArray(props.jobCreation.offices)
      ? props.jobCreation.offices : [];
    const guideSteps = [
      {
        selector: '.unknown-class-meow-meow',
        content: 'Create a new job posting or edit existing jobs.',
      },
      {
        selector: '.newjob__form .newjob-upload-jd',
        content: 'Create a new job by uploading a job description. X0PA will automatically fill the form with information from job description file.',
      },
      {
        selector: '.newjob__form .newjob-company-section',
        content: 'Choose the company for which you would like to create the job. To add new company, navigate to Admin section or ask your administrator.',
      },
      {
        selector: '.newjob__form .newjob-skills-section',
        content: 'Choose the skills that are required for this job. Skills play an imporant role in the shortlisting process.',
      },
      {
        selector: '.newjob__form .newjob-private-section',
        content: 'Jobs can be private or public. Public jobs are visible to everyone and candidates can apply directly. Private jobs are invisible.',
      },
      {
        selector: '.newjob__form .previewButton',
        content: 'Preview the job listing before submitting. ',
      },
    ];

    const CustomOverlay = ({ classNames, ...props })=> {
      return (
        <div
          className={classNames.overlayWrapper}
          style={{ top:-350, "backgroundColor": "aliceblue",}}
          { ...props }
        />
      );
    };

    const otherAction = (<Button small onClick={() => this.addLanguageModal()}>{t('addLanguage')}</Button>);

    const allCities = [
      { value: '1', label: 'Bangkok' },
      { value: '2', label: 'Ho Chi Minh' },
      { value: '3', label: 'Jakarta' },
      { value: '4', label: 'Beijing' },
      { value: '5', label: 'Hong Kong' },
      { value: '6', label: 'Shanghai' },
      { value: '7', label: 'Helsinki' },
      { value: '8', label: 'London' },
      { value: '9', label: 'Silicon Valley' },
      { value: '10', label: 'Sydney' },
    ];

    const uploadJD = (
      <div className="bx--form-item modal-footer xpa-banner">
        <p className="bx--label-description">{t('uploadJDLabel')}</p>
        <div className="bx--file h-auto flatpickr-innerContainer d-inline-block mx-auto text-center newjob-upload-jd" data-file>
          <label
            htmlFor="jd_upload"
            className="bx--file-btn bx--btn bx--btn--primary uploadFile"
            role="button"
            tabIndex="0">{t('uploadFile')}</label>
          <input
            type="file"
            className="bx--file-input"
            id="jd_upload"
            name="jobDescFile"
            accept=".doc,.docx,.pdf"
            value={this.state.jobDescFile&&this.state.jobDescFile!=null?this.state.jobDescFile:''}
            onChange={this.handleFileChange}
            data-file-uploader
            data-target="[data-file-container]"
          />
          <div data-file-container className="bx--file-container"></div>
        </div>
        <div className="m-3">
          <Button kind="tertiary" small className="" onClick={this.onNextStep}>{t('skip')}</Button>
        </div>
      </div>
    );

    const companyDetails = (
      <div>
        <div className="newjob__form__header">
          <h5 className="bx--type-epsilon xpa-color--gray02">{t('institution')}</h5>
        </div>
        <div className="">
          <div className="bx--row pl-3">
            <DropDownBox
              mainClassName="bx--row bx--col-lg-9"
              disabled={!this.state.editFlag}
              titleClass="col-md-4 col-sm-12"
              title={t("name")}
              required
              custom
              className="bx--col-lg-8 pl-3"
              name="companyValue"
              options={this.state.list ? this.state.list : []}
              placeholder={t('institution')}
              onChange={(value, e, name) => this.companyDropdown(value, e, name, false)}
              selectedValue={companyValue}
            />
          </div>
        </div>
        <div className="newjob-name-section">
          <div className="bx--row pl-3">
            <DropDownBox
              required
              title={t("office")}
              disabled={this.state.companyId === 0}
              custom
              titleClass="col-md-4 col-sm-12"
              mainClassName="bx--row bx--col-lg-9"
              className="bx--col-lg-8 pl-3"
              options={offices.map((one) => {
                let { address: {
                  addressLine1 = '',
                  addressLine2 = '',
                  zipcode = '',
                  location: {
                    locationName = '',
                    country: { countryFull = '' } = {},
                    state: { stateFull = '' } = {},
                  } = {},
                } = {}} = one;
                addressLine1 = addressLine1 === null || addressLine1.length === 0 ? '' : `${addressLine1}, `;
                addressLine2 = addressLine2 === null || addressLine2.length === 0 ? '' : `${addressLine2}, `;
                zipcode = zipcode === null || zipcode.length === 0 ? '' : `${zipcode}`;
                locationName = locationName === null || locationName.length === 0 ? '' : `${locationName}, `;
                countryFull = countryFull === null || countryFull.length === 0 ? '' : `${countryFull}, `;
                stateFull = stateFull === null || stateFull.length === 0 ? '' : `${stateFull}, `;
                let label = `${addressLine1}${addressLine2}${locationName}${countryFull}${zipcode}`;
                label = label.replace(/Unkn, /g, '');
                return ({
                  label,
                  value: one.officeId,
                })}
              )}
              placeholder={t("address")}
              onChange={(value, e, name) => this.officeDropdown(e)}
              name="officeValue"
              selectedValue={officeValue}
            />
            {/* <ModalWrapper
              id="newOffice"
              buttonTriggerClassName=" bx--btn--sm ml-2 bx--btn bx--btn--ghost"
              triggerButtonKind="secondary"
              handleSubmit={() => this.onAddoffice()}
              disabled={this.isValid(companyValue, 'object') === false}
              buttonTriggerText="Add new office"
              modalHeading="Add Office"
              primaryButtonText="Add"
              shouldCloseAfterSubmit={true}
            >
              <div className="bx--col m-0 p-0">
                {this.state.modalError === true && (
                  <InlineNotification lowContrast
                    kind="error"
                    className="bx--col"
                    title=""
                    subtitle={<div>Please fill all the mandatory fields marked with &nbsp;<span className="text-danger font-weight-bold">*</span></div>}
                    onCloseButtonClick={() => this.setState({ modalError: false })}
                />)}
                <div className="bx--col m-0 p-0">
                    <InputFieldBox
                      mainClass="bx--form-item"
                      titleClass="bx--select"
                      labelClass="bx--label"
                      className="bx--col m-0 p-0"
                      title={<div>Address Line 1<span className="text-danger">*</span></div>}
                      name="officeAdd1"
                      placeholder="address line 1"
                      handleChange={this.handleChange}
                      state={this.state} max="100"
                    />
                </div>
                <div className="bx--col m-0 mt-3 p-0">
                    <InputFieldBox
                      mainClass="bx--form-item"
                      titleClass="bx--select"
                      labelClass="bx--label"
                      className="bx--col m-0 p-0"
                      title="Address Line 2"
                      name="officeAdd2"
                      placeholder="Street or Area"
                      handleChange={this.handleChange}
                      state={this.state}
                      max="100"
                    />
                </div>
                <div className="m-0 bx--col mt-3 p-0">
                    <InputFieldBox
                      mainClass="bx--form-item"
                      titleClass="bx--select"
                      labelClass="bx--label"
                      className="bx--col m-0 p-0"
                      title={<div>City<span className="text-danger">*</span></div>}
                      name="officeRegion"
                      placeholder="region"
                      handleChange={this.handleChange}
                      state={this.state} max="100"
                    />
                </div>
                <div className="bx--col m-0 mt-3 p-0">
                    <InputFieldBox
                      mainClass="bx--form-item"
                      titleClass="bx--select"
                      labelClass="bx--label"
                      className="bx--col m-0 p-0"
                      title="Zipcode"
                      name="officeZip"
                      placeholder="Zipcode"
                      handleChange={this.handleChange}
                      state={this.state} max="100"
                    />
                </div>
                <div className="m-0 p-0 mt-3 bx--col">
                  <DropDownBox
                    title="Country"
                    mainClassName="bx--form-item"
                    titleClass="bx--select"
                    labelClass="bx--label"
                    required
                    custom
                    className="bx--col m-0 p-0"
                    options={props.dropdowns && props.dropdowns.countries && Array.isArray(props.dropdowns.countries) && props.dropdowns.countries.map(country => ({
                      label: country.countryFull,
                      value: country.countryId,
                    }))}
                    onInputChange={e => this.onInputCountryDropdown(e)}
                    placeholder={'Country'}
                    onChange={(value, e, name) => this.officeCountryDropdown(value, e, name)}
                    name="officeCountry"
                    selectedValue={officeCountry}
                  />
                </div>
              </div>
            </ModalWrapper> */}
          </div>
        </div>
        {/* <InputFieldBox
          title={t("website")}
          name="website"
          titleClass="ml-1 col-sm-12 col-md-3"
          max="150"
          disabled
          handleChange={this.handleChange}
          placeholder="www.sample.com"
          state={this.state}
        /> */}
        {/* <InputFieldBox
          type="number"
          name="companysize"
          required
          title={t("companySize")}
          min="1"
          max="1000000"
          placeholder="500"
          handleChange={this.handleChange}
          state={this.state}
        /> */}
        {/* <div className="form-group bx--row m-0">
          <div className="col-sm-12 col-md-3" >
            <label className="form-label pt-3 pb-0 mb-0">
              {t('companyDescription')}
            </label>
          </div>
          <div className="newjob_input_container col-xs-12 col-sm-12 col-md-9">
            <TextArea disabled placeholder={t("companyDescriptionHolder")} name="companyDesc" value={companyDesc} onChange={this.handleChange}/>
          </div>
        </div> */}
        <div className="newjob__form__header">
          <h5 className="bx--type-epsilon xpa-color--gray02">{t("jobTitle")}</h5>
        </div>
        <div className="d-flex">
          <div className="ml-1 bx--col-lg-3">
            {t('title')} <span className="xpa-job-title">*</span>
          </div>
          <div className="ml-2 w-50">
            <TextInput
            name="title"
            id="title"
            hideLabel
            placeholder={t('jobTitle')}
            labelText={<div>{t('name')}</div>}
            value={this.state.title}
            onChange={(e) => this.handleChange(e)}
          />
          </div>
        </div>
            {/* <DropDownBoxWithCreate
              required
              isLoading={props.jobCreation.jobNameAutoCompleteLoading}
              isAsync
              pattern = '^[a-zA-Z0-9 ]*$'
              options={props.jobCreation && props.jobCreation.jobName && Array.isArray(props.jobCreation.jobName)&&
                 props.jobCreation.jobName.filter((ele) =>(ele != null)).map(({ nameName, nameId })=> ({ label: nameName,value: nameId }))}
              inputProps={{
                maxLength: "50"
              }}
              newOptionCreator={(name, value) => this.onNewOptionCreate(name, value, "title")}
              onInputChange={this.onInputJobTitleDropdown}
              title={t('title')}
              placeholder={t("jobTitle")}
              onChange={(e, name) => this.jobTitleDropdown(e, name)}
              name="jobValue"
              selectedValue={jobValue}
            /> */}
        <div className="newjob__form__header">
          <h5 className="bx--type-epsilon xpa-color--gray02">{t('location')}</h5>
        </div>
        <div className="">
            <DropDownBox
              required
              title={t("country")}
              options={(countryArr && Array.isArray(countryArr) && countryArr.length > 0) ? countryArr : []}
              // onInputChange={e => this.onInputCountryDropdown(e)}
              placeholder={t("country")}
              onChange={(value, e, name) => this.countryDropdown(value, e, name)}
              name="countryValue"
              selectedValue={countryValue}
            />
        </div>
        <DropDownBox
          title={t("city")}
          required
          labelClass="mb-0"
          titleClass="col-sm-12 col-md-3 mt-2"
          mainClassName="row m-0 pl-1"
          options={(cityArr && Array.isArray(cityArr) && cityArr.length > 0) ? cityArr : []}
          name="region"
          placeholder={t("city")}
          onChange={this.handleDropdownChange}
          selectedValue={region}
        />
        {<label><small className="text-danger font-weight-bold">{t('allMandatoryFields')}</small></label>}
      </div>
    );

    const jobDescReq = (
      <div>
        <div className="newjob__form__header">
          <h5 className="bx--type-epsilon xpa-color--gray02">{t('jobDescription')}</h5>
        </div>
        <div className="flexRow newjob_desc_container descriptionField col-xs-11 col-sm-11 pl-0" >
        <Editor
        required
          toolbar={
            {
              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
            }                
          }
          editorState={this.state.jobDescEditorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          placeholder={t('jobDescHolder')}
          handlePastedText = {(pastedData)=>this.handlePasted(pastedData,'jobDesc')}
          onEditorStateChange={(editorState)=>this.onEditorStateChange(editorState,'jobDesc')}
        />
        {/* <CharCounter editorState={ this.state.jobDescEditorState } limit={4000} /> */}
          <label htmlFor="jd_upload">
            <div className="newjob_upload  d-none">
              <span>Or</span>
              <span>Upload a File (.doc, .pdf)</span>
            </div>
          </label>
        </div>
        <div className="newjob__form__header">
          <h5 className="bx--type-epsilon xpa-color--gray02">{t('jobRequirements')}</h5>
        </div>
        <div className="flexRow newjob_desc_container requirmentField col-xs-11 col-sm-11 pl-0">
        <Editor
        required
          toolbar={
            {
              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
            }                
          }
          editorState={this.state.jobReqEditorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          placeholder={t('jobReqHolder')}
          handlePastedText = {(pastedData)=>this.handlePasted(pastedData,'jobReq')}
          onEditorStateChange={(editorState)=>this.onEditorStateChange(editorState,'jobReq')}
        />
        {/* <CharCounter editorState={ this.state.jobReqEditorState } limit={2500} />*/}
          <label htmlFor="jd_upload">
            <div className="newjob_upload  d-none">
              <span>Or</span>
              <span>Upload a File (.doc, .pdf)</span>
            </div>
          </label>
        </div>
        {<label><small className="text-danger font-weight-bold">{t('jobDescReqLabel')}</small></label>}
      </div>
    );

    const skills = (
      <div>
        <div className="newjob__form__header">
          <h5 className="bx--type-epsilon xpa-color--gray02">{t('skills')}</h5>
        </div>
        {this.state.skillAdded &&
          this.state.message && (
            <RenderMessage message={this.state.message} success={this.state.success} />
          )}
        <div className="newjob-skills-section">
          <DropDownBoxWithCreate
            isAsync
            title={t("mustHaveSkills")}
            multi
            required
            isClearable={false}
            name="skillsmusthave"
            className='musthaveskillsField newjob-musthave-skills'
            props={this.props}
            options={[]}
            newOptionCreator={this.onCreateOptionClick}
            onInputChange={this.onSkillsInputChange}
            selectedValue={this.state.skillsmusthave}
            onChange={this.handleSkillDropdownChange}
          />
        </div>
      </div>
    );

    const empDetails = (
      <Fragment>
        <div>
          <div className="newjob__form__header">
            <h5 className="bx--type-epsilon xpa-color--gray02">{t('employmentDetails')}</h5>
          </div>
          <InputFieldBox
            type="number"
            title={t("stipend")}
            min="0"
            max="1000000000"
            name="minSalary"
            handleChange={this.handleChange}
            state={this.state}
            placeholder={1000}
          />
          <DropDownBox
            title={t("currency")}
            onChange={this.handleDropdownChange}
            selectedValue={currencyValue}
            placeholder={t("currencyHolder")}
            name="currencyValue"
            clearable={false}
            clearValueText={false}
            options={this.props.jobCreation.dropdowns.currencies.map(item => ({
              value: item.currencyId,
              label: `${item.currencyFull} (${item.currencyShort})`,
            }))}
          />
          <InputFieldBox
            type="number"
            name="vacancy"
            required
            title={t("vacancies")}
            min="1"
            max="1000"
            handleChange={this.handleChange}
            state={this.state}
            placeholder={1}
          />
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-3">
              <label className="form-label pt-3 pb-0 mb-0">
                <div className="bx--row m-0 ml-1 pl-3"><div>{t("internshipPeriod")}</div><div className="text-danger">*</div></div>
              </label>
            </div>
            <div className="flexRow newjob_input_container pl-4 col-sm-12 col-md-9">
            <DatePicker className="d-flex" maxDate="1/10/2021" minDate={moment().format('MM/DD/YYYY')} datePickerType="range" onChange={this.onDatePickerChange}>
                  <DatePickerInput
                    name="internshipStartDate"
                    className="end_date"
                    id="start"
                    placeholder="Start Date"
                    value={moment(this.state.internshipStartDate).format('MM/DD/YYYY')}
                    dateFormat="DD-MMM-YYYY"
                  />
                  <div>
                    <DatePickerInput
                      className="mb-0 mr-2"
                      // disabled={selectedEducation.isCurrentEducation && selectedEducation.isCurrentEducation === true}
                      id="end"
                      name="internshipEndDate"
                      placeholder="End Date"
                      dateFormat="DD-MMM-YYYY"
                      value={moment(this.state.internshipEndDate || this.state.internshipEndDate).format('MM/DD/YYYY')}
                    />
                  </div>
                </DatePicker>
            {/* <span>
              <DayPickerInput
                required
                formatDate={formatDate}
                overlayComponent = {CustomOverlay}
                parseDate={parseDate}
                align="top center"
                value={moment(this.state.internshipStartDate).format('MM/DD/YYYY')}
                onDayChange={date => this.handleDateChange(date, 'internshipStartDate')}
                dayPickerProps={{ disabledDays: { before: new Date() } }}
                name="internshipStartDate"
                className="end_date"
              />
            </span>
            <b className="mr-2 ml-3 mt-1">&#x2013;</b>
            <span className="ml-2">
              <DayPickerInput
                required
                formatDate={formatDate}
                overlayComponent = {CustomOverlay}
                parseDate={parseDate}
                align="top center"
                value={moment(this.state.internshipEndDate || this.state.internshipStartDate).format('MM/DD/YYYY')}
                onDayChange={date => this.handleDateChange(date, 'internshipEndDate')}
                dayPickerProps={{ disabledDays: { before: this.state.internshipStartDate ? new Date(this.state.internshipStartDate) : new Date() } }}
                name="internshipEndDate"
                className="end_date"
              />
            </span> */}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-3">
              <label className="form-label pt-3 pb-0 mb-0">
                <div className="bx--row m-0 ml-1 pl-3"><div>{t('closingDate')}</div><div className="text-danger">*</div></div>
              </label>
            </div>
            <div className="flexRow newjob_input_container pl-4 col-sm-12 col-md-9">
            <DayPickerInput
              required
              formatDate={formatDate}
              overlayComponent = {CustomOverlay}
              parseDate={parseDate}
              align="top center"
              value={moment(this.state.closeDate).format('MM/DD/YYYY')}
              onDayChange={date => this.handleDateChange(date, 'closeDate')}
              dayPickerProps={{ disabledDays: { before: new Date() } }}
              name="closeDate"
              className="end_date"
            />
            </div>
          </div>
          <hr className="mt-3 mb-3" />
          <div className="mb-2 text-dark text-justify">
            {t('percentageSliderMsg')}
          </div>
          <PercentageSlider hideSideLabels minLabel={t("taskOrientedPreference")} maxLabel={t("independentPreference")} value={preferenceTaskOriented} name="preferenceTaskOriented" onChange={this.onSliderChange} />
          <PercentageSlider hideSideLabels minLabel={t("backEndPreference")} maxLabel={t("frontEndPreference")} value={preferenceBackoffice} name="preferenceBackoffice" onChange={this.onSliderChange} />
          {/* <PercentageSlider hideSideLabels minLabel={t("tryNewPreference")} maxLabel={t("enhanceFutherEnhanceSkills")} value={preferenceTryNew} name="preferenceTryNew" onChange={this.onSliderChange} /> */}
          {<label><small className="text-danger font-weight-bold">{t("allMandatoryFields")}</small></label>}
        </div>
      </Fragment>
    );

    const languageProficiency = (
      <div>
        <div className="h5 font-weight-bold">
          {t('languageProficiency')}
        </div>
        <div className="mt-1 text-dark">
          {t('langProfSubHeader')}
        </div>
        <GenericDataTable
          className=""
          // title={t('languageProficiency')}
          headers={langHeaders}
          rows={langsData}
          otherActions = {[otherAction]}
        />
        {Array.isArray(langsData) && langsData.length === 0 && (
          <Tile className="bx--type-epsilon pt-2 pb-2 align-items-center d-flex justify-content-center">{t('noLanguages')}</Tile>
        )}
        {/* <Pagination
          onChange={this.handleLangPaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          totalItems={langsData.length}
        /> */}
        <Modal
          open={showAddLang}
          modalHeading={t('addLanguage')}
          primaryButtonText={t('add')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.closeLangModal}
          onRequestSubmit={this.onAddLanguageClick}
          onSecondarySubmit={this.closeLangModal}
        >
          <div>
            {
              showLangErr === true && (
                <InlineNotification lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={t('langErr')}
                  onCloseButtonClick={() => this.setState({ showLangErr: false })}
              />
              )
            }
            <DropDownBox
              labelClass="bx--type-epsilon mb-0"
              title={t('language')}
              mainClassName="lang"
              titleClass="col-sm-12"
              custom
              className="col-sm-12"
              options={ Array.isArray(this.props.languages)
                ? this.props.languages.map(
                  ({ languageFull, languageId }) => (
                    { label: languageFull, value: languageId }
                  ),
                ) : []
              }
              placeholder={t('language')}
              onChange={this.handleDropdownChange}
              name="langValue"
              selectedValue={langValue}
            />
          </div>
          <div>
            <DropDownBox
              labelClass="bx--type-epsilon mb-0"
              titleClass="col-sm-12 col-xs-12"
              title={t('verbalProficiency')}
              custom
              className="col-sm-12"
              mainClassName="lang"
              options={ langLevels }
              placeholder={t('verbalProficiency')}
              onChange={this.handleDropdownChange}
              name="langVerbal"
              selectedValue={langVerbal}
            />
          </div>
          <div>
            <DropDownBox
              labelClass="bx--type-epsilon mb-0"
              titleClass="col-sm-12 col-xs-12"
              custom
              className="col-sm-12"
              title={t('writingProficiency')}
              mainClassName="lang"
              options={ langLevels }
              placeholder={t('writingProficiency')}
              onChange={this.handleDropdownChange}
              name="langWritten"
              selectedValue={langWritten}
            />
          </div>
        </Modal>
      </div>
    );

    const preferences = (<Preferences ref="preferences" hide={["jobpreferences", "citypreferences", "jobMatches"]} setData={this.setPreferences} industries={industrypreferences} functions={jobfunctionpreferences} />);

    const jobQuestionsStage = (
      <div className="bx--col mt-4">
        <JobQuestions ref="questions" setJobQuestions={this.setJobQuestions} jobQuestions={jobQuestions} />
      </div>
    );

    const lastStageBtns = (
      <div className="mr-3 m-md-auto bx--grid row justify-content-center">
        <div className="m-3">
          <Button kind="tertiary" small className="" onClick={this.onPrevStep}>{t('previous')}</Button>
        </div>
        <Button
          kind="primary"
          small
          onClick={
            async (e) => {
              const inValid = await this.stageVerify(); 
              if(!inValid){
                 this.saveJobData(e);
              }
              return;
            }
          }
          className="saveButton mt-3"
        >
          {this.state && this.state.isEditJobCase? t('saveJob') : t('createAJob')}
        </Button>
        <input
          data-toggle="modal"
          data-target="#xpa-preview--modal"
          type="button"
          className="bx--btn bx--btn--sm bx--btn--tertiary m-3 previewButton"
          onClick={this.onPreview}
          value={t("preview")}
        />
        <button className="bx--btn bx--btn--sm bx--btn--tertiary m-3 cancleButton" type='button' onClick={() => push('/app/campus/jobs/open/')}>
          {t('cancel')}
        </button>
      </div>
    );

    const finalStage = (
      <div className="">
        <div className="text-center bx--type-alpha">{t('jobCreatedMsg')}</div>
        <div className="align-content-center text-center">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{ maxWidth: 100 }} >
          <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
          <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
        </svg>
        </div>
        <div className="m-3 text-center bx--row justify-content-center">
          <div className="m-3">
              <Button kind="tertiary" className="" onClick={() => this.props.history.push('/app/campus/jobs')}>{t('backToJobList')}</Button>
          </div>
          <div className="m-3">
              <Button kind="primary" className="" disabled={jobId === null} onClick={() => this.props.history.push(`/app/campus/job/${jobId}`)}>{`${t('viewJob')}`}</Button>
          </div>
        </div>
      </div>
    );

    const jobStages = [
      uploadJD,
      companyDetails,
      jobDescReq,
      (<Fragment>{skills}{empDetails}</Fragment>),
      preferences,
      (<Fragment>{languageProficiency}{lastStageBtns}</Fragment>),
      finalStage,
    ];

    return (
      <form>
        <div className="xp-c-container xp-c-background--light">
          {!this.state.skillAdded
            && this.state.message
            && ( <RenderMessage message={this.state.message} success={this.state.success} /> )
          }
          <Preview active={ this.state.activePreview }  dismiss={this.onPreview} fields={previewFields} />
          <BackRow paths={this.paths} showGuidedTour={true} handleGuidedTourClick={() => this.setState({ productTourIsOpen: true })}/>
          <ProductTour
            onRequestClose={this.closeTour}
            isOpen={this.state.productTourIsOpen}
            steps={guideSteps}
          />          
          <div className="newjob__form p-lg-5 form-horizontal card">
            <div className="newjob__form__header newjob__form__header--center">
              {jobId === null ? <h3 className="text-uppercase">{t('jobPostingHeading')}</h3>
                : <h3 className="text-uppercase">{t('editJobPostingHeading')}</h3>
              }
              <p>{t('jobPostingLabel')}</p>
            </div>
            <div className="bx--col" style={{ height: 40 }}>
                <Progress
                  className="bx--col mt-2"
                  theme={
                    {
                      active: {
                        symbol: <div className="bx--type-zeta">{`${Math.round((100 / stages) * (stageIndex + 1))}%`}</div>,
                        trailColor: '#f4f7fb',
                        color: '#5596e6',
                      },
                      success: {
                        symbol: <div className="bx--type-zeta">{`${Math.round((100 / stages) * (stageIndex + 1))}%`}</div>,
                        trailColor: '#f4f7fb',
                        color: '#5596e6',
                      },
                    }
                  }
                  percent={(100 / stages) * (stageIndex + 1)}
                />
              </div>
            <div className="">
              {stageIndex < jobStages.length ? jobStages[stageIndex] : null}
            </div>
            <div className="bx--row mt-3 justify-content-center ml-0">
              {
                stageIndex > 0 && stageIndex < (stages - 2)
                  ? (
                    <div className="bx--row justify-content-center">
                      <div className="m-3">
                        <Button kind="tertiary" disabled={jobId !== null && stageIndex === 1} small className="" onClick={this.onPrevStep}>{t('previous')}</Button>
                      </div>
                      <div className="m-3">
                        <Button kind="tertiary" small className="" onClick={() => push('/app/campus')}>{t('cancel')}</Button>
                      </div>
                      <div className="m-3">
                        <Button kind="primary" small className="" onClick={this.onNextStep}>{t('next')}</Button>
                      </div>
                    </div>
                  ) : null
              }
            </div>
          </div>
          <Modal open={missingFields} passiveModal danger modalHeading="Validation Error" onRequestClose={() => this.setState({ missingFields: false })}>
            <p className="bx--modal-content__text">
              <div className="bx--row m-0 p-0">
                <div>
                  Please fill all the mandatory fields marked with <span className="text-danger">*</span> to create the job posting.
                </div>
              </div>
            </p>
          </Modal>
          <JDProcessOverlay show={this.state.loading || this.props.loading} message="processing..." />
        </div>
      </form>
    );
  }
}

//  eslint-disable-next-line
const PlaceHolder = () => <div style={{ opacity: '0' }}>placeholder</div>;

const mapStateToProps = state => ({
  skills: state.jobCreation && state.jobCreation.skills,
  jdData: state.jobCreation && state.jobCreation.jdData,
  dropdowns: state.jobCreation && state.jobCreation.dropdowns,
  jobCreation: state.jobCreation,
  userInfo: state.profile.userInfo,
  currUser: state.x0paDuser,
  companies: state.companyAndOffice.companies,
  authorizedCompanies: state.companyAndOffice.authorizedCompanies,
  job: state.jobs.jobs && Array.isArray(state.jobs.jobs) ? state.jobs.jobs[0] : {},
  loading: state.jobCreation.loading || state.companyAndOffice.loading,
  authorizedUsers: state.jobs.authorizedUsers,
  user: state.x0paDuser.user,
  languages: state.jobCreation && state.jobCreation.languages,
  institutions: state.BrowseInstitutions.institutions,
  cities: state.StudentProfile.cities,
  xSearchCompanies,
  neonPoly: false,
});

const mapActionsToProps = {
  parseJd,
  fetchDropdownData,
  changeNewJobInput,
  changeNewJobdropdown,
  changeEndDate,
  saveJob,
  editJob,
  associateJd,
  fetchAll,
  fetchCompanyId,
  createNewOption,
  fetchAllOffices,
  getFirstFiveMatchInTableCol,
  addOffice,
  getAllCompanies,
  getAuthorizedCompanies,
  getJobInfo,
  getAuthorizedUsers,
  fetchAllInstitutionsList,
  getCitiesList,
  xSearchCompanies,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(NewJob));
