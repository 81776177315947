import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput, Form, Button, InlineNotification, Modal, Loading, Tile,
} from 'carbon-components-react';

import zxcvbn from 'zxcvbn';

import { SignedInErrorView, SignedInSuccessView, InviteErrorView } from 'components/common/ErrorViews';
import { createUser, validateUser } from '../../actions/admin/Registration';

import './Registration.css';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstname: '',
      lastname: '',
      password: '',
      repassword: '',
      hashData: '',
      isPasswordMatch: true,
      isError: false,
      isPasswordInvalid: false,
      isrePassInvalid: false,
      isFirstCase: true,
      suggestions: [],
      pwdLevel: ['weak', 'weak', 'good', 'strong', 'stronger'],
      score: 0,
      isSignupDisabled: true,
      errorMessage: 'Unknown error',
      registrationComplete: false,
      uLoading: true,
      userExists: false,
      isInviteError: false,
    };
  }

  componentDidMount() {
    const { match: { params: { data } = {} } = {} } = this.props;
    this.setState({ hashData: data });
    this.props.validateUser({data}).then((res) => {
      if (res && res.error) {
        this.setState({
          uLoading: false,
          isInviteError: true,
        });
        return;
      }
      if (res && !res.data) {
        this.setState({
          uLoading: false,
          userExists: false,
        });
      } else {
        this.setState({
          uLoading: false,
          userExists: true,
        });
      }
    });
  }

  handleChange = (event,type='') => {
    const password =  event.target.value;
    if (type == "password") {
      if (password.length > 7) {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        if (hasUpperCase && hasLowerCase && hasNumbers)
          this.setState({ isFirstCase: false, });
        else
          this.setState({ isFirstCase: true, });
        const Result = zxcvbn(password, [this.state.username, this.state.firstname, this.state.lastname]);
        this.setState({ suggestions: Result.feedback.suggestions, score: Result.score });
        if (Result.score < 2 || (!hasUpperCase || !hasLowerCase || !hasNumbers)) this.setState({ isPasswordInvalid: true })
        else this.setState({ isPasswordInvalid: false, isSignupDisabled: false })
      }
      else
        this.setState({ isPasswordInvalid: true,isFirstCase: true,isSignupDisabled: true })
    }
    const { target: { name, value } = {} } = event;
    this.setState({
      [name]: value,
    });
  }

  registerUser = (ev) => {
    ev.preventDefault();
    const {
      username, password, repassword, hashData, firstname, lastname,isPasswordInvalid,
    } = this.state;
    if (password === repassword && !isPasswordInvalid) {
      const data = {
        username,
        firstname,
        lastname,
        password,
        data: hashData,
        roleId: 4,
      };
      this.setState({ isError: false, isPasswordMatch: true });
      this.props.createUser(data)
        .then((res) => {
          if (res && res.error) {
            this.setState({ isError: true, errorMessage: res.error.message });
          } else {
            this.setState({ registrationComplete: true }, () => {
              setTimeout(() => { this.props.history.push('/login?registration=1'); }, 3000);
            });
          }
        });
    } else {
      this.setState({ isPasswordMatch: false, isrePassInvalid: true });
    }
  }

  render() {
    const {
      username, firstname, lastname, isPasswordMatch,
      repassword, password, isError, errorMessage, isFirstCase,
      registrationComplete, isPasswordInvalid, pwdLevel, score,
      isSignupDisabled, isrePassInvalid, uLoading, userExists, isInviteError,
    } = this.state;
    const { isUserLoggedIn } = this.props;
    return (
      <div className="registraton-form">
        { uLoading && <Loading withOverlay /> }
        { isInviteError && <InviteErrorView /> }
        {
        !isInviteError && !userExists && !isUserLoggedIn && (isUserLoggedIn !== null) && (
          <div className="bx--tile rf-wrap">
            <div className="row justify-content-center">
              <img
                className="mb-4"
                src={require('assets/images/republic-polytechnic.png')}
                alt="X0PA Ai Logo"
                width="120"
                height="71"
              />
            </div>
            <div className="text-center">
              <h4>
                Welcome to X0PA!
              </h4>
              <p>
                Please fill this form to complete registration.
              </p>
            </div>
            {
              isPasswordMatch ? null : (
                <InlineNotification lowContrast
                  kind="error"
                  role="alert"
                  className="mt-1 mb-1"
                  title="Password mismatch"
                  subtitle="Please retry the password."
                  iconDescription="describes the close button"
                  onCloseButtonClick={() => this.setState({ isPasswordMatch: true })}
                />)
            }
            {
            isError ? (
              <InlineNotification lowContrast
                kind="error"
                role="alert"
                className="mt-1 mb-1"
                title="Error occured"
                subtitle={errorMessage}
                iconDescription="describes the close button"
                onCloseButtonClick={() => this.setState({ isError: false })}
              />) : null
            }
            <div className="xpa--login">
              <div className="tab-content" id="xpaLoginTabContent">
                <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                  <Form onSubmit={ev => this.registerUser(ev)}>
                    <div className="row">
                      <TextInput
                        required
                        id="firstname"
                        name="firstname"
                        labelText="First Name"
                        placeholder="First Name"
                        onChange={(ev) => { this.handleChange(ev); }}
                        value={firstname}
                        maxLength={15}
                      />
                      <TextInput
                        required
                        id="lastname"
                        name="lastname"
                        labelText="Last Name"
                        placeholder="Last Name"
                        onChange={(ev) => { this.handleChange(ev); }}
                        value={lastname}
                        maxLength={15}
                      />
                    </div>
                    <div className="row">
                      <TextInput
                        required
                        id="username"
                        name="username"
                        labelText="Username"
                        placeholder="Username"
                        onChange={(ev) => { this.handleChange(ev); }}
                        value={username}
                        maxLength={20}
                      />
                      <TextInput
                        type="password"
                        required
                        id="password"
                        name="password"
                        labelText={`Password (${pwdLevel[score]})`}
                        placeholder="Password"
                        invalid={isPasswordInvalid}
                        invalidText={isFirstCase ? (<span>Password must be at least 8 characters and contain<br/>at least one uppercase, one lowercase, and one number.</span>):<b>Password Strength: {pwdLevel[score]}</b>}
                        onChange={(ev) => { this.handleChange(ev, 'password'); }}
                        value={password}
                        maxLength={15}
                      />
                    </div>
                    <div className="row align-items-center">
                      <TextInput
                        type="password"
                        required
                        id="repassword"
                        name="repassword"
                        labelText="Re-type Password"
                        placeholder="Password"
                        invalid={isrePassInvalid}
                        invalidText="password must be same as above"
                        onChange={(ev) => { this.handleChange(ev); }}
                        value={repassword}
                        maxLength={15}
                      />
                      <Button type="submit" className="rf-btn" kind="primary" disabled={isSignupDisabled}>
                      Sign up
                      </Button>
                    </div>
                    <p className="text-dark small pt-4">
                      By signing up, you agree to Terms & Conditions and &nbsp;
                      <a
                        href="https://www.x0pa.com/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      &nbsp;of X0PA.
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </div>)
        }
        {
          !isInviteError && isUserLoggedIn && <SignedInErrorView />
        }
        {
          !isInviteError && userExists && !isUserLoggedIn && <SignedInSuccessView />
        }
        <Modal
          modalHeading="Registration successful"
          passiveModal
          open={registrationComplete}
          onRequestClose={() => { this.props.history.push('/login?registration=1'); }}
        >
          Your registration is complete, please sign in to continue
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  registration: state.registration,
  register: state.registration.register,
});

const mapDispatchToProps = {
  createUser,
  validateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
