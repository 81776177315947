import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Onboarding from 'containers/common/onboarding/Onboarding';
import './style.css';

import { Loading } from 'carbon-components-react';

import CandidateBrowseJobs from 'containers/roboroy/candidate/browse';
import PublicHeader from 'containers/common/publicHeader';


class PublicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: '',
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {
          component = '',
        } = {},
      } = {},
    } = this.props;
    if (this.getComponent(component) === null) {
      this.setState({ component: 'jobs' }, () => {
        this.props.history.push('/public/jobs');
      });
    } else this.setState({ component });
  }


  getComponent = (component) => {
    const { isUserLoggedIn } = this.props;
    switch (component) {
      case 'jobs': return (<CandidateBrowseJobs publicPage />);
      case 'onboard': {
        if (isUserLoggedIn === true) {
          return (
            <Redirect to="/app/campus/" />
          );
        }
        return (
          (isUserLoggedIn === false) ? (<Onboarding publicPage />) : (<Loading />)
        );
      }
      default: return null;
    }
  }

  render() {
    const { component = null } = this.state;
    return (
      <Fragment>
        <PublicHeader />
        <div className="publicComponentDiv">
          {this.getComponent(component)}
        </div>
      </Fragment>
    );
  }
}

export default PublicPage;
