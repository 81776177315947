import {
  REQUEST_JOB_APPROVAL_LIST,
  SUCCESS_JOB_APPROVAL_LIST,
  ERROR_JOB_APPROVAL_LIST,
  REQUEST_CREATE_APPROVAL_LIST,
  SUCCESS_CREATE_APPROVAL_LIST,
  ERROR_CREATE_APPROVAL_LIST,
  REQUEST_DELETE_APPROVAL_LIST,
  SUCCESS_DELETE_APPROVAL_LIST,
  ERROR_DELETE_APPROVAL_LIST,
  REQUEST_EDIT_APPROVAL_LIST,
  SUCCESS_EDIT_APPROVAL_LIST,
  ERROR_EDIT_APPROVAL_LIST,
} from '../../actions/talentSearch/actionTypes';
import {
  REQUEST_JOB_APPROVAL_VACANCIES,
  SUCCESS_JOB_APPROVAL_VACANCIES,
  ERROR_JOB_APPROVAL_VACANCIES,
} from 'actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  approvalList: [],
  count: 0,
  approvedVacancies: [],
};

const JobApprovals = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_JOB_APPROVAL_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_JOB_APPROVAL_LIST:
      return {
        ...state,
        approvalList: payload && payload.approvals,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_JOB_APPROVAL_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_APPROVAL_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_APPROVAL_LIST:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_APPROVAL_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_APPROVAL_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_APPROVAL_LIST:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_APPROVAL_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_EDIT_APPROVAL_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_EDIT_APPROVAL_LIST:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_EDIT_APPROVAL_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_JOB_APPROVAL_VACANCIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_JOB_APPROVAL_VACANCIES:
      return {
        ...state,
        approvedVacancies: payload && payload.approvedFacultyList,
        loading: false,
        error: null,
      };
    case ERROR_JOB_APPROVAL_VACANCIES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default JobApprovals;
