import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { flaskUrl } from '../../utils/config';

import {
    REQUEST_USERS_USAGE_REPORT,
  } from '../../actions/actionTypes';

  const getUsersUsageReport = (data) => (dispatch) => {
    let filter = encodeURIComponent(JSON.stringify(data));
    const url = `${baseUrl}/userinfos/usage?filter=${filter}`;
    const params = {
      headers: {
        'content-type': 'application/json',
      },    
      dispatch,
      method: 'GET',
      url,
      actionType: REQUEST_USERS_USAGE_REPORT,
    };  
    return callApi(params);
  }

  const getCandidateUsageReport = (data) => (dispatch) => {
    let filter = encodeURIComponent(JSON.stringify(data));
    const url = `${baseUrl}/userinfos/candidates/usage?filter=${filter}`;
    const params = {
      headers: {
        'content-type': 'application/json',
      },    
      dispatch,
      method: 'GET',
      url,
      actionType: REQUEST_USERS_USAGE_REPORT,
    };  
    return callApi(params);
  }  

  export { getUsersUsageReport, getCandidateUsageReport };