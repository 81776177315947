import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SetMixPanel from 'components/common/GetCurrentPath';
import BackRow from 'components/common/BackRow/BackRow';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import Avatar from 'components/common/Avatar';
import {
  Tile, Select, SelectItem, Button,
  InlineNotification, Accordion, AccordionItem,
} from 'carbon-components-react';
import './Reports.css';
import { baseUrl } from 'utils/config';
import {
  getReportsDetails,
  downloadReportsDetails,
  getStudentReportDetails,
} from 'actions/Reports';
import {
  fetchAllCompaniesList,
} from 'actions/BrowseCompanies';
import {
  fetchAllInstitutionsList,
} from 'actions/BrowseInstitutions';
import JobsView from 'containers/roboroy/dashboard/components/JobsView';

const track = {
  ROBOROY_DASHBOARD_OPENJOBS: 'ROBOROY_DASHBOARD_OPENJOBS',
  ROBOROY_DASHBOARD_CLOSEDJOBS: 'ROBOROY_DASHBOARD_CLOSEDJOBS',
  ROBOROY_DASHBOARD_SHAREDJOBS: 'ROBOROY_DASHBOARD_SHAREDJOBS',
};

class Reports extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'open', name: 'Reports', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedValue: 0,
      companyId: 0,
      jobLoading: false,
      isValidCompanyId: false,
      isValidCompanyIdVal: '',
      url: '',
      isValidInstIdVal: '',
      isValidInstId: false,
      institutionId: 0,
      showStudentsdata: false,
      showErr: false,
      errMsg: '',
    };
  }

  componentDidMount() {
    this.props.fetchAllCompaniesList({
      itemsPerPage: -1,
      activePage: 1,
      searchKey: null,
      showJobsData: false,
    });
    this.props.fetchAllInstitutionsList({
      itemsPerPage: -1,
      activePage: 1,
      searchKey: null,
      showStudentsdata: false,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmitCompReports = (value) => {
    const {
      t,
    } = this.props;
    const {
      companyId,
      selectedValue,
      institutionId,
    } = this.state;
    if (selectedValue === 'companies') {
      let isValidCompanyId = false;
      let isValidCompanyIdVal = '';
      if (!companyId || companyId === null || companyId === undefined) {
        isValidCompanyId = true;
        isValidCompanyIdVal = `${t('companyNameValid')}`;
      } else {
        isValidCompanyId = false;
        isValidCompanyIdVal = '';
      }
      this.setState({
        isValidCompanyId,
        isValidCompanyIdVal,
      }, () => {
        this.handleValidationChecks(value);
      });
    } else if (selectedValue === 'students') {
      let isValidInstId = false;
      let isValidInstIdVal = '';
      if (!institutionId || institutionId === null || institutionId === undefined) {
        isValidInstId = true;
        isValidInstIdVal = `${t('offerMsg4')}`;
      } else {
        isValidInstId = false;
        isValidInstIdVal = '';
      }
      this.setState({
        isValidInstId,
        isValidInstIdVal,
      }, () => {
        this.handleInsValidationChecks(value);
      });
    }
  }

  handleInsValidationChecks = (value) => {
    const {
      isValidInstId,
      institutionId,
    } = this.state;
    if (isValidInstId !== true) {
      if (value === 'submit') {
        const data = {
          institutionId,
        };
        this.props.getStudentReportDetails(data).then((res) => {
          if (res && !res.error) {
            this.setState({
              showStudentsdata: true,
              showErr: false,
              errMsg: '',
            });
          } else if (res && res.error) {
            this.setState({
              showErr: true,
              errMsg: res && res.error && res.error.message,
            });
          }
        }).catch(e => console.log('Error:::::', e));
      } else if (value === 'download') {
        let url = `${baseUrl}/reports/downloadReport?_=${new Date().getTime()}&`;
        if (institutionId) {
          url += `institutionId=${institutionId}&`;
        }
        this.setState({
          url,
        });
      }
    }
  }

  handleValidationChecks = (value) => {
    const {
      isValidCompanyId,
      companyId,
    } = this.state;
    const data = {
      companyId,
    };
    if (isValidCompanyId !== true) {
      if (value === 'submit') {
        this.setState({
          jobLoading: true,
        }, () => {
          this.props.getReportsDetails(data).then((res) => {
            if (res && !res.error) {
              this.setState({
                jobLoading: false,
                showJobsData: true,
                showErr: false,
                errMsg: '',
              });
            } else if (res && res.error) {
              this.setState({
                showErr: true,
                errMsg: res && res.error && res.error.message,
              });
            }
          })
            .catch((e) => {
              console.log('Error:::::', e);
              this.setState({
                jobLoading: false,
              });
            });
        });
      } else if (value === 'download') {
        let url = `${baseUrl}/reports/download?_=${new Date().getTime()}&`;
        if (companyId) {
          url += `companyId=${companyId}&`;
        }
        this.setState({
          url,
        });
      }
    }
  }

  handleOpenJobsClick = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    SetMixPanel(track.ROBOROY_DASHBOARD_OPENJOBS);
    if (push) {
      push('/app/campus/jobs/open/page/1/limit/10/');
    }
  }

  handleClosedJobsClick = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    SetMixPanel(track.ROBOROY_DASHBOARD_CLOSEDJOBS);
    if (push) {
      push('/app/campus/jobs/closed/page/1/limit/10/');
    }
  }

  handleSharedJobsClick = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    SetMixPanel(track.ROBOROY_DASHBOARD_SHAREDJOBS);
    if (push) {
      push('/app/campus/jobs/shared/page/1/limit/10/');
    }
  }

  handleRedirectJobPage = (jobId) => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push(`/app/campus/job/${jobId}`);
    }
  }

  render() {
    const {
      t,
      companies,
      jobsCount,
      jobs,
      students,
      history: {
        push,
      } = {},
      institutions,
    } = this.props;
    const {
      open,
      closed,
      shared,
    } = jobsCount || {};
    const totalJobs = Number(open) + Number(closed) + Number(shared);
    const dashboard = {
      jobs: totalJobs,
    };
    const {
      selectedValue,
      jobLoading,
      showJobsData,
      isValidCompanyId,
      isValidCompanyIdVal, showErr, errMsg,
      url, isValidInstId, isValidInstIdVal,
      showStudentsdata, institutionId,
    } = this.state;
    const columnWithFilter = [
      {
        title: `${t('job')} ${t('title')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('location')}`,
        className: 'text-center',
        name: 'location',
        propName: 'location',
      },
      {
        title: `${t('skills')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <div className="bx--type-delta mt-1">{t('reports')}</div>
        <Tile className="mt-2">
          {
            showErr === true && (
              <InlineNotification lowContrast
                className="mt-1 mb-1"
                title=""
                iconDescription="describes the close button"
                kind="error"
                subtitle={errMsg}
                onCloseButtonClick={() => { this.setState({ showErr: false }); }}
              />
            )
          }
          <div className="bx--row">
            <div className="bx--col-lg-2 mt-2 h6 text-uppercase">{t('measure')}</div>
            <div>
              <Select
                className="xpa-reports-select-width"
                hideLabel
                onChange={this.handleChange}
                name="selectedValue"
                id="reports-select"
              >
                <SelectItem
                  text={t('dropDownPlaceholder')}
                  value="placeholder-item-0"
                  id="0"
                />
                <SelectItem
                  value="students"
                  text={t('students')}
                  id="1"
                />
                <SelectItem
                  value="companies"
                  text={t('companies')}
                  id="2"
                />
              </Select>
            </div>
          </div>
          {
            selectedValue === 'companies' && companies
              && Array.isArray(companies)
              && companies.length > 0
              && (
                <div className="bx--row mt-4">
                  <div className="bx--col-lg-2 mt-2 h6 text-uppercase">{t('list')}</div>
                  <div>
                    <Select
                      className="xpa-reports-select-width"
                      hideLabel
                      invalid={isValidCompanyId}
                      invalidText={isValidCompanyIdVal}
                      onChange={this.handleChange}
                      name="companyId"
                      id="reports-select-1"
                    >
                      <SelectItem
                        text={t('dropDownPlaceholder')}
                        value="placeholder-item-0"
                        id="0"
                      />
                      {
                        companies.map(res => (
                          <SelectItem
                            value={res && res['_source'] && res['_source'] && res['_source'].companyId}
                            text={res && res['_source'] && res['_source'] && res['_source'].companyName && res['_source'].companyName.toString() && res['_source'].companyName.toUpperCase()}
                            id="1"
                          />
                        ))
                      }
                    </Select>
                  </div>
                </div>
              )
          }
          {
            selectedValue === 'students' && institutions
              && Array.isArray(institutions)
              && institutions.length > 0
              && (
                <div className="bx--row mt-4">
                  <div className="bx--col-lg-2 mt-2 h6 text-uppercase">{t('list')}</div>
                  <div>
                    <Select
                      className="xpa-reports-select-width"
                      hideLabel
                      invalid={isValidInstId}
                      invalidText={isValidInstIdVal}
                      onChange={this.handleChange}
                      name="institutionId"
                      id="reports-select-3"
                    >
                      <SelectItem
                        text={t('dropDownPlaceholder')}
                        value="placeholder-item-0"
                        id="0"
                      />
                      {
                        institutions.map(res => (
                          <SelectItem
                            value={res && res['_source'] && res['_source'] && res['_source'].companyId}
                            text={res && res['_source'] && res['_source'] && res['_source'].companyName && res['_source'].companyName.toString() && res['_source'].companyName.toUpperCase()}
                            id="1"
                          />
                        ))
                      }
                    </Select>
                  </div>
                </div>
              )
          }
          {
            (selectedValue === 'companies' || selectedValue === 'students')
              && (
                <div className="d-flex mt-3">
                  <div className="mr-2">
                    <Button
                      kind="primary"
                      small
                      onClick={() => this.handleSubmitCompReports('submit')}
                    >
                      {t('submit')}
                    </Button>
                  </div>
                  <div>
                    <Button onClick={() => this.handleSubmitCompReports('download')} small kind="primary">
                      <a className="xpa-link-icon button-link" target={url ? '_blank' : ''} href={url ? url : 'javascript:void(0)'}><span>{t('download')}</span> <i className="fa fa-download" aria-hidden="true" /></a>
                    </Button>
                  </div>
                </div>
              )
          }
        </Tile>
        <div className="mt-2">
          {
            showJobsData === true && (
              <JobsView
                dashboard={dashboard}
                jobs={jobs}
                showViewAll={false}
                jobsCount={jobsCount}
                handleJobsClick={this.handleJobsClick}
                handleOpenJobsClick={this.handleOpenJobsClick}
                handleClosedJobsClick={this.handleClosedJobsClick}
                handleSharedJobsClick={this.handleSharedJobsClick}
                jobLoading={jobLoading}
                totalJobs={totalJobs}
                push={push}
              />
            )
          }
        </div>
        <div className="mt-2">
          {
            showStudentsdata === true
              && students && Array.isArray(students)
              && students.length > 0
              ? (
                <Tile>
                  <div className="mb-2 mt-1 text-dark h5">
                    {t('students')} {t('reports')}
                  </div>
                  <Accordion>
                    {
                      students.map((res) => {
                        const {
                          studentName,
                          applicationDetails,
                        } = res || {};
                        return (
                          <AccordionItem
                            title={
                              <div className="d-flex align-items-center">
                                <div className="ml-2 xpa-avatar-width">
                                  <Avatar
                                    user={{
                                      firstname: studentName,
                                      lastname: null,
                                      picture: null,
                                    }}
                                    classNameInfo="rounded-circle"
                                    size="30"
                                  />
                                </div>
                                <div className="text-uppercase font-weight-bold ml-2">{studentName}</div>
                              </div>
                            }
                          >
                            <div>
                              <div className="mb-2 h5">
                                {t('totalApplications')}: <span className="bx--tag bx--tag--ibm">{applicationDetails && Array.isArray(applicationDetails) && applicationDetails.length}</span>
                              </div>
                              {
                                applicationDetails && Array.isArray(applicationDetails)
                                  && applicationDetails.length > 0
                                  ? (
                                    <GenericDataTable
                                      className=""
                                      otherActions={[]}
                                      headers={
                                        columnWithFilter.map((data, index) => (
                                          {
                                            key: `header_${index}`,
                                            header: data.title,
                                            className: data.className,
                                            colpropName: data.propName,
                                          }
                                        ))}
                                      rows={
                                        (applicationDetails && Array.isArray(applicationDetails)
                                        && applicationDetails.length > 0)
                                          ? applicationDetails.map((appl) => {
                                            const {
                                              job: {
                                                jobId,
                                                cities: {
                                                  cityName,
                                                } = {},
                                                country: {
                                                  countryFull,
                                                } = {},
                                                jobname: {
                                                  nameName,
                                                } = {},
                                                skills,
                                              } = {},
                                            } = appl || {};
                                            return ({
                                              isExpanded: true,
                                              id: `row_${jobId}`,
                                              header_0: (
                                                <div onClick={() => this.handleRedirectJobPage(jobId)} className="xpa-link text-capitalize font-weight-bold">
                                                  {nameName}
                                                </div>
                                              ),
                                              header_1: (
                                                <div className="d-flex justify-content-center text-capitalize">
                                                  <div>{countryFull}{cityName ? ',' : null}</div>
                                                  <div className="ml-2">{cityName}</div>
                                                </div>
                                              ),
                                              header_2: (
                                                <div className="bx--row justify-content-center">
                                                  {
                                                    skills && Array.isArray(skills)
                                                      && skills.length > 0
                                                      && skills.map((skill) => {
                                                        const {
                                                          skill: {
                                                            skillName,
                                                          } = {},
                                                        } = skill || {};
                                                        return (
                                                          <div className="bx--tag bx--tag--custom ml-2 text-capitalize">
                                                            {skillName}
                                                          </div>
                                                        );
                                                      })
                                                  }
                                                </div>
                                              ),
                                            });
                                          }) : []}
                                      selectable={false}
                                      searchable={false}
                                      onSearchInputChange={() => {}}
                                      expandable={false}
                                      batchActions={false}
                                    />
                                  ) : (
                                    <div className="text-dark h6 font-weight-bold">
                                      {t('reportsMsg1')}
                                    </div>
                                  )
                              }
                            </div>
                          </AccordionItem>
                        );
                      })
                  }
                  </Accordion>
                </Tile>
              ) : (
                <div>
                  {
                    selectedValue === 'students'
                      && showStudentsdata === true
                      && isValidInstId !== true
                      && institutionId
                      ? (
                        <Tile>
                          <div className="text-center">
                            <div className="empty-icon">
                              <i className="fa fa-flag fa-2x" />
                            </div>
                            <p className="empty-title h5">{t('noStudentsMsg')}</p>
                          </div>
                        </Tile>
                      ) : null
                  }
                </div>
              )
              }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  companies: state.BrowseCompanies.companies,
  jobs: state.Reports.jobs,
  jobsCount: state.Reports.jobsCount,
  institutions: state.BrowseInstitutions.institutions,
  students: state.Reports.students,
});
const mapDispatchToProps = {
  getReportsDetails,
  downloadReportsDetails,
  fetchAllCompaniesList,
  fetchAllInstitutionsList,
  getStudentReportDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Reports));
