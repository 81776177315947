import callApi from '../../utils/callApi';
import { baseUrl, flaskUrl } from '../../utils/config';
import {
  REQUEST_JOB_APPROVAL_LIST,
  REQUEST_CREATE_APPROVAL_LIST,
  REQUEST_DELETE_APPROVAL_LIST,
  REQUEST_EDIT_APPROVAL_LIST,
  REQUEST_UPDATE_APPROVAL_LIST
} from './actionTypes';
import {
  REQUEST_JOB_APPROVAL_VACANCIES,
} from 'actions/actionTypes';

const getJobApprovalList = jobId => (dispatch) => {
  const url = `${baseUrl}/jobapprovers?_=${new Date().getTime()}&jobId=${jobId}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_JOB_APPROVAL_LIST,
  };

  return callApi(params);
};

const getJobApprovalListByStatus = () => (dispatch) => {
  const url = `${baseUrl}/jobapprovers?_=${new Date().getTime()}&status=pending&filter[limit]=5&filter[skip]=0`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_JOB_APPROVAL_LIST,
  };

  return callApi(params);
};

const createJobApprovalList = data => (dispatch) => {
  const url = `${baseUrl}/jobapprovers?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify([data]),
    actionType: REQUEST_CREATE_APPROVAL_LIST,
  };

  return callApi(params);
};

const editJobApprovalList = (jobId, value, vacancies, facultyId, jobApproveId) => (dispatch) => {
  const data = {
    isApproved: value,
    jobId,
    // vacancies,
    facultyId,
    jobApproveId
  };
  const url = `${baseUrl}/jobapprovers?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_APPROVAL_LIST,
  };

  return callApi(params);
};


const deleteJobApprovalList = (jobId, jobApprovedId) => (dispatch) => {
  const url = `${baseUrl}/jobapprovers/${jobApprovedId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_APPROVAL_LIST,
  };
  return callApi(params);
};

const getApprovedVacancies = () => (dispatch) => {
  const url = `${baseUrl}/jobapprovers/approvedVacancies?_=${new Date().getTime()}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_JOB_APPROVAL_VACANCIES,
  };
  return callApi(params);
};

const updateJobApprovalList = (jobId, facultyId, jobApproveId, isPrimaryCourseAdmin) => (dispatch) => {
  const data = {
    jobId,
    facultyId,
    jobApproveId,
    isPrimaryCourseAdmin
  };
  const url = `${baseUrl}/jobapprovers/update?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType:REQUEST_UPDATE_APPROVAL_LIST,
  };

  return callApi(params);
};

export {
  getJobApprovalList,
  createJobApprovalList,
  deleteJobApprovalList,
  editJobApprovalList,
  getJobApprovalListByStatus,
  getApprovedVacancies,
  updateJobApprovalList
};
