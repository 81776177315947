import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import getRecruitersList from 'actions/admin/RecruitersList';
import {
  DataTable, Search, Pagination,
  Tile, InlineNotification
} from 'carbon-components-react';
import { RecruitersView } from 'containers/roboroy/RoboroyCards';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

const {
  TableContainer, Table, TableRow,
  TableBody, TableCell,
} = DataTable;

const NoContentBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no users to show for your current selection.</p>
      <p className="empty-title text-dark">Please switch to your parent company to see the list of users in your organization.</p>      
    </div>
  </Tile>
);

class RecruitersList extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'users', name: 'Users', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      rowsData: [],
      headersInfo: [{ key: 'users', header: 'Users-View' }],
      activePage: 1,
      itemsPerPage: 10,
      keyword: '',
    };
  }

  componentDidMount() {
    const {
      user: {
        currentCompany: {
          companyId,
        } = {},
      } = {},
    } = this.props;
    this.setState({
      activePage: 1,
      itemsPerPage: 10,
    }, () => {
      const data = {
        activePage: this.state.activePage,
        itemsPerPage: this.state.itemsPerPage,
        searchKey: this.state.keyword,
        status: 'accepted',
        companyId,
      };
      this.props.getRecruitersList(data).then(() => {
        this.setRows();
      });
    });
  }

  setRows = () => {
    const {
      usersList,
    } = this.props;
    const finalData = (usersList && Array.isArray(usersList)
      && usersList.length > 0 && usersList.map((res) => {
      const RowData = {
        id: (res && res.userId) || 0,
        firstname: (res && res.firstname) || '',
        lastName: (res && res.lastname) || '',
        email: (res && res.email) || '',
        picture: (res && res.picture) || '',
        open: (res && res.jobs && res.jobs.openjobs) || 0,
        closed: (res && res.jobs && res.jobs.closejobs) || 0,
        shared: (res && res.jobs && res.jobs.sharejobs) || 0,
        companyId: (res && res.companyId) || 0,
      };
      const oneRow = { id: RowData.id.toString(), users: RecruitersView(RowData) };
      return oneRow;
    })) || [];
    this.setState({ rowsData: finalData });
  };

  handlePaginationChange = (data) => {
    const {
      user: {
        currentCompany: {
          companyId,
        } = {},
      } = {},
    } = this.props;
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage }, () => {
      const currData = {
        itemsPerPage,
        activePage: this.state.activePage,
        searchKey: this.state.keyword,
        companyId,
      };
      this.props.getRecruitersList(currData).then(() => {
        this.setRows();
      });
    });
  };

  searchTimer = null;

  handleSearchSubmit = (event) => {
    const {
      user: {
        currentCompany: {
          companyId,
        } = {},
      } = {},
    } = this.props;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      const data = {
        itemsPerPage: this.state.itemsPerPage,
        activePage: this.state.activePage,
        searchKey: this.state.keyword,
        companyId,
      };
      this.searchTimer = setTimeout(() => this.props.getRecruitersList(data).then(() => this.setRows()), 1000);
    });
  };

  render() {
    const { usersList, count } = this.props;
    const {
      headersInfo, rowsData, keyword, activePage,
    } = this.state;
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <h4 className="font-weight-bold text-capitalixe mb-0 bx--type-gamma">Users</h4>
        <InlineNotification lowContrast
            title={`In this section, you can see the list of users in your parent organization and their activity.`}
            subtitle=""
            iconDescription="describes the close button"
            kind="info"
            hideCloseButton
        />                  
        <div className="bx--row">
          <div className="mt-2 ml-3 bx--col-lg-7 p-0 bx--col-xs-12">
            <Search
              light
              type="text"
              style={{ maxWidth: 500 }}
              className="p-0 col-lg-6"
              labelText="Search job"
              closeButtonLabelText=""
              placeHolderText="Search Users"
              onChange={(e) => this.handleSearchSubmit(e)}
              id="search company"
              value={keyword}
            />
          </div>
        </div>
        <DataTable
          rows={rowsData}
          headers={headersInfo}
          render={({ rows, headers, getHeaderProps }) => (
            <TableContainer title="" className="bg-transparent mt-2" style={{ overflow: 'hidden' }}>
              <Table className="bg-transparent" style={{ borderSpacing: '0px 6px', borderCollapse: 'separate' }}>
                <TableBody className="bg-transparent">
                  {rows.map(row => (
                    <TableRow key={row.id} className="mb-2">
                      {row.cells.map(cell => (
                        <TableCell
                          key={cell.id}
                          className="pr-0 pl-0"
                        >
                          {cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        />
        {
          rowsData && rowsData.length <= 0 && <NoContentBox />
        }
        {
          rowsData && rowsData.length > 0 && (
            <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
          )
        }
        <JDProcessOverlay show={(this.props && this.props.loading)} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.RecruitersList.loading,
  user: state.x0paDuser.user,
  usersList: state.RecruitersList.usersList,
  count: state.RecruitersList.count,
});

const mapDispatchToProps = {
  getRecruitersList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecruitersList);
