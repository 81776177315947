import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Tile } from 'carbon-components-react';
import HumanTime from 'utils/humanTime';


class ModifiedTooltip extends Component {
    render() {
        const { active, } = this.props;
        if (active) {
            const { payload, label, pivotAggregation } = this.props;
            const data = payload && payload[0] && payload[0].payload;
            return <div className="recharts-default-tooltip columns card rounded shadow-sm" style={{ margin: "5px", padding: "10px", whiteSpace: "nowrap", textAlign: "center", }}>
                {data && data!==null && (data instanceof Object)  && Object.keys(data).map((key, pos) => {
                    return (                         
                        <div key={pos} className="recharts-tooltip-label pb-0 mb-0 row bx--row bx--col-12" style={{ width: "300px" }}>
                            <div className="text-gray pb-0 mb-0  bx--col-xs-12 bx--col-md-6  bx--col-lg-8 bx--type-omega text-left font-weight-normal">
                                <span className="d-inline-block mr-1" style={{ background: colors[pos % colors.length], width:"10px", height: "10px" }}></span>
                                <span className="d-inline-block text-uppercase" 
                                    style={{ color: pivotAggregation.color }}>
                                    {key}                              
                                </span>                                
                                <small className="text-dark pl-1">({pivotAggregation.label})</small>
                            </div>
                            <div className="metriccard-number pt-0 pb-0 mb-0 bx--col-xs-12 bx--col-md-6 bx--col-lg-4 bx--type-omega text-left" style={{ color: colors[pos % colors.length] }}>
                                {
                                        pivotAggregation.type == "date" && (
                                            HumanTime.getHumanTime(data[key])
                                    )
                                    }
                                    {
                                        pivotAggregation.type == "number" && (
                                            data[key]
                                    )
                                    }                                
                            </div>
                        </div>
                    );
                })}
            </div>;
        }
        return null;
    }
}

// function getExactTime(milliSec) {
//     let hours = Math.round(((milliSec / 1000) / 60) / 60);
//     const days = Math.floor(hours / 24);
//     const totalTimeInHours = hours; 
//     hours = days > 0 ? (hours - days * 24) : hours;
//     const totalDays = days>0?(days>1?days.toString()+" days":days.toString()+" day"):"";
//     const totalHours= hours>0?(hours>1?hours.toString()+" hours":hours.toString()+" hour"):"";
//     const totalTime = totalDays.length>0?totalDays+" "+totalHours:totalHours; 
//     return {
//         days:days > 0 ? days:0,
//         hours:hours > 0 ? hours: 0,
//         totalHours:totalTimeInHours,
//         timeTaken: totalTime,
//     };
// }

const colors = ['#ffa600','#ff7c43','#f95d6a','#d45087','#a05195','#665191','#2f4b7c','#003f5c']

function AnalyticsStackedBarGraph(props) {
    const {pivotAggregation, aggregations, summary, tag} = props;
    // console.log("----------------->>>> ", props);
    const Data = [{}];
    const keys = tag && props.data && props.data.map(ele=>{
        Data[0][ele[tag]] = ele[pivotAggregation.name] > 0 ? ele[pivotAggregation.name] : 0;
        return ele[tag];
    })||[];

    // console.log("----- props", Data);

    // const listOfAvgValues = props.value && Array.isArray(props.value)?props.value.map(n=> {if (n.avg > 0) { return n.avg&&n.avg} else {return 0}}):[];
    // const listOfMinValues = props.value && Array.isArray(props.value)?props.value.map(n=> {if (n.min > 0) { return n.min&&n.min} else {return 0}}):[];
    // const listOfMaxValues = props.value && Array.isArray(props.value)?props.value.map(n=> {if (n.max > 0) { return n.max&&n.max} else {return 0}}):[];

    // console.log("-----$$$", listOfAvgValues);
    // let avgTime = Array.isArray(listOfAvgValues) && listOfAvgValues.reduce((a, b) => a + b, 0);
    // let minTime = Array.isArray(listOfMinValues) && listOfMinValues.reduce((a, b) => a + b, 0);
    // let maxTime = Array.isArray(listOfMaxValues) && listOfMaxValues.reduce((a, b) => a + b, 0);

    // avgTime = avgTime / (listOfAvgValues.length);
    // minTime = minTime / (listOfMinValues.length);
    // maxTime = maxTime / (listOfMaxValues.length);

    //console.log("-----$$$ ---- ", summary);
    const {isApplicant=false} = props;
    return (
    summary && summary[pivotAggregation.name] > 0 ? (
    <div className="analyticsBar p-0">
        <div className="bx--row row m-0 p-0 card-link justify-content-center align-items-center text-lg-left">
            <div className="bx--col-xs-12 bx--col-lg-12  mt-lg-3">
                <div className="text-dark card-subtitle mb-3 bx--type-epsilon mt-3">{props.heading || "-"}</div>
                <div class="bx--grid">
                    <div class="bx--row">
                        {aggregations && aggregations.map((eachAggregation) => {
                            return (
                                <div class={`bx--col-xs-12 bx--col-md-${12/aggregations.length} text-center mb-2`}>
                                    <div className="bx--type-gamma card-subtitle text-info">
                                        {
                                            eachAggregation.type == "date" && (
                                                HumanTime.getHumanTime(summary[eachAggregation.name])
                                            )
                                        }
                                        {
                                            eachAggregation.type == "number" && (
                                                summary[eachAggregation.name]
                                            )
                                        }                                    
                                        <div class="bx--type-caption text-dark">{eachAggregation.label}</div>
                                    </div>
                                </div>    
                            )
                        })
                        }
                    </div>
                </div>                
                {/* <div className="bx--type-omega card-subtitle text-info h6">{`${props.progress} ${prop.progress>0?"higher than usual.":"less than usual."}`}</div> */}
            </div>
            <div className="bx--col-xs-12 bx--col-lg-12  ml-lg-0 mt-lg-0 mt-1 ml-4">
                <ResponsiveContainer className="barGraph justify-content-center align-items-center" width="90%" height="90%" barCategoryGap="5%">
                    <BarChart data={Data || []}
                        className="ml-lg-5" layout="vertical">
                        <Tooltip content={<ModifiedTooltip pivotAggregation={pivotAggregation}/>}/>
                        <XAxis type="number" hide={true} domain={['dataMin', 'dataMax']}/>
                        <YAxis type="category" dataKey={tag} hide={true}  />
                        <Legend/>
                        { keys.map((ele,pos)=>{
                            const color = colors[pos%colors.length];
                            return (<Bar dataKey={ele.toString()} key={pos} width="100%" stackId="same" fill={color} barSize={20}  />);
                            })
                        }
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>
    ) : (
        <div className="analyticsBar p-0">
        <div className="bx--row row m-0 p-0 card-link justify-content-center align-items-center text-lg-left">
            <div className="bx--col-xs-12 bx--col-lg-12  mt-lg-3">
                <div className="text-dark card-subtitle mb-3 bx--type-epsilon mt-3">{props.heading || "-"}</div>
                <small className="text-dark d-block">There is no sufficient data to generate analytics.</small>
            </div>
        </div>
    </div>        
    )
    )
                    }
export default AnalyticsStackedBarGraph;