import { withTranslation } from 'react-i18next';
import React, { Component, Fragment, } from 'react';
import { connect } from 'react-redux';
import {
  emailLogoSection, emailBodyStart,
  emailBodyEnd, dummyEmailPlaceholders
} from 'containers/common/constants';
import { getCompanyInfo, } from 'actions/admin/AdminSettings';

import {
  Form, TextInput, Checkbox,
  Button, InlineNotification,
} from 'carbon-components-react';
import {
  EditorState, convertToRaw, ContentState, convertFromRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import BackRow from 'components/common/BackRow/BackRow';
import {
  getEmailTemplate, addEmailTemplate, sendEmailTemplate,
} from 'actions/email-templates';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

class CustomizeEmail extends Component {
  constructor(props) {
    super(props);
    const editorState = EditorState.createEmpty();
    const footerEditorState = EditorState.createEmpty();
    this.state = {
      emailSubject: '',
      emailBody: '',
      emailFooter: '',
      invalidemailSubject: false,
      invalidemailBody: false,
      invalidemailFooter: false,
      editorState,
      footerEditorState,
      activateTemplate: true,
      showNotification: false,
      notificationTitle: 'Email template saved successfully',
      errorType: 'success',
      isUserTemplate: false,
      description: '',
      isSharedWithCompany: false,
    };
    this.notifRef = React.createRef();
  }

  componentDidMount = () => {
    const { match: { params: { templateId } = {} } = {} } = this.props;
    console.log('CDM', templateId);
    if (templateId !== 'add') {
      this.props.getEmailTemplate(templateId).then((res) => {
        let editorState = EditorState.createEmpty();
        let footerEditorState = EditorState.createEmpty();
        if (res && res.emailBody) {
          const contentBlock = htmlToDraft(res.emailBody);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
          }
        }
        if (res && res.emailFooter) {
          const contentBlock = htmlToDraft(res.emailFooter);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            footerEditorState = EditorState.createWithContent(contentState);
          }
        }
        this.setState({
          emailBody: res && res.emailBody,
          emailSubject: res && res.subject,
          emailFooter: res && res.emailFooter,
          templateName: res && res.displayName,
          description: res && res.desc,
          editorState,
          footerEditorState,
          isUserTemplate: res && res.isUserTemplate,
          activateTemplate: (res && res.status === 'active'),
          isSharedWithCompany: (res && res.isSharedWithCompany),
          templateKey: res && res.templateName,
        });
      });
    } else {
      const emailFooter = '<p style="text-align:center;">Made by X0PA Ai</p><p style="text-align:center;">Block 81, Launchpad <span style="color: rgb(102,102,102);font-size: 14px;font-family: Helvetica, arial, sans-serif;">•</span> 81 Ayer Rajah Crescent, # Units 66/ 67 <span style="color: rgb(102,102,102);font-size: 14px;font-family: Helvetica, arial, sans-serif;">•</span> Singapore 139967</p>';
      const contentBlock = htmlToDraft(emailFooter);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const footerEditorState = EditorState.createWithContent(contentState);
        this.setState({ emailFooter, footerEditorState })
      }
    }
  }

  handleInputChange = (ev) => {
    const resetInvalid = `invalid${ev.target.name}`;
    this.setState({
      [ev.target.name]: ev.target.value,
      [resetInvalid]: false,
    });
  }

  validateInput = (data) => {
    let isValid = false;
    const inValidInputs = {};
    data.map((input) => {
      const {
        name, value = '', minLength, maxLength,
      } = input || {};
      const inputValue = value || '';
      const trimmedValueLength = inputValue.trim().length;
      if ((trimmedValueLength < minLength) || (trimmedValueLength > maxLength)) {
        inValidInputs[name] = true;
      }
    });
    if (Object.keys(inValidInputs).length === 0) {
      isValid = true;
    } else {
      this.setState(inValidInputs);
    }
    return isValid;
  }

  handleOnSubmit = (ev) => {
    ev.preventDefault();
    const {
      match: { params: { templateId } = {} } = {},
      template: { templateName } = {},
    } = this.props;
    const {
      editorState, footerEditorState, emailBody,
      emailFooter, emailSubject, activateTemplate, description,
      isSharedWithCompany,
    } = this.state;
    const data = [
      {
        name: 'invalidemailSubject',
        value: emailSubject,
        minLength: 6,
        maxLength: 250,
      },
      {
        name: 'invalidemailBody',
        value: emailBody,
        minLength: 10,
        maxLength: 8000,
      },
      {
        name: 'invalidemailFooter',
        value: emailFooter,
        minLength: 6,
        maxLength: 1000,
      },
    ];
    if (templateId === 'add') {
      data.push({
        name: 'invalidtemplateName',
        value: this.state.templateName,
        minLength: 6,
        maxLength: 250,
      });
    }
    const isValid = true; //this.validateInput(data);
    if (isValid) {
      const data = templateId === 'add' ? ({
        desc: this.state.description,
        displayName: this.state.templateName,
        templateName: this.state.templateName && this.state.templateName !== null ? this.state.templateName.toLowerCase() : '',
        subject: emailSubject,
        status: 'active',
        emailBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        emailFooter: draftToHtml(convertToRaw(footerEditorState.getCurrentContent())),
        html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        text: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        isUserTemplate: true,
        isSharedWithCompany: isSharedWithCompany,
      }) : ({
        templateId,
        templateName,
        subject: emailSubject,
        status: true,
        isSharedWithCompany: isSharedWithCompany,
        desc: description,
        emailBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        emailFooter: draftToHtml(convertToRaw(footerEditorState.getCurrentContent())),
        html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        text: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        footer: draftToHtml(convertToRaw(footerEditorState.getCurrentContent())),
      });
      this.props.addEmailTemplate(data).then((res) => {
        const { id } = res || {};
        if (id && !(Number(id) === Number(templateId))) {
          this.setState({
            errorType: 'success',
            showNotification: true,
            notificationTitle: 'Email template saved successfully, please send test email and check.',
          }, () => {
            const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
              && this.notifRef.current.focus();
            this.setState({
              templateKey: res && res.templateName,
              templateName: res && res.displayName,
              displayName: res && res.displayName,
            }, () => {
              this.props.history.push(`/app/campus/email/${id}`);
            });
          });
        } else {
          this.setState({
            errorType: 'success',
            showNotification: true,
            notificationTitle: 'Email template saved successfully, please send test email and check.',
          }, () => {
            const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
              && this.notifRef.current.focus();
          });
        }
      });
    }
  }

  handleSendTestEmail = () => {
    const {
      match: { params: { templateId } = {} } = {},
      template: { templateName } = {},
      user: { email } = {},
    } = this.props;
    const {
      editorState, footerEditorState, emailBody,
      emailFooter, emailSubject, templateKey,
    } = this.state;
    const data = [
      {
        name: 'invalidemailSubject',
        value: emailSubject,
        minLength: 6,
        maxLength: 250,
      },
      {
        name: 'invalidemailBody',
        value: emailBody,
        minLength: 10,
        maxLength: 8000,
      },
      {
        name: 'invalidemailFooter',
        value: emailFooter,
        minLength: 6,
        maxLength: 1000,
      },
    ];
    const isValid = true; //this.validateInput(data);
    if (isValid) {
      this.props.sendEmailTemplate({
        email,
        templateId,
        templateName: templateKey || templateName,
        subject: emailSubject,
        body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        footer: draftToHtml(convertToRaw(footerEditorState.getCurrentContent())),
      })
        .then((res) => {
          if (res && !res.error) {
            this.setState({
              showNotification: true,
              errorType: 'success',
              notificationTitle: 'Test email sent successfully',
            }, () => {
              const c = this.notifRef && this.notifRef.current
                && this.notifRef.current.focus && this.notifRef.current.focus();
            });
          } else if (res && res.error) {
            const { error: { message = 'Unkown error' } } = res || {};
            this.setState({
              showNotification: true,
              errorType: 'error',
              notificationTitle: `Error occured: Please check the email template: ${message}`,
            }, () => {
              const c = this.notifRef && this.notifRef.current
                && this.notifRef.current.focus && this.notifRef.current.focus();
            });
          }
        });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onFooterEditorStateChange = (editorState) => {
    this.setState({
      footerEditorState: editorState,
    });
  };

  onPreview = () => {
    const { editorState = {}, footerEditorState = {} } = this.state;
    let emailSubject = this.state.emailSubject;
    this.props.getCompanyInfo().then(({ data }) => {
      let { emailBg = '#F3F4F5', logo = 'public/x0pa-logo-300.png' } = data;
      logo = 'https://x0pa-email-assets.s3-ap-southeast-1.amazonaws.com/republic-polytechnic.jpg';
      if (emailBg === null) emailBg = '#F3F4F5';
      let emailLogoSections = emailLogoSection.replace(/{{emailBg}}/g, emailBg);
      emailLogoSections = emailLogoSections.replace('{{logo}}', logo);
      let EmailData = emailLogoSections + emailBodyStart
        + draftToHtml(convertToRaw(editorState.getCurrentContent()))
        + emailBodyEnd + draftToHtml(convertToRaw(footerEditorState.getCurrentContent()));
      EmailData = EmailData.replace(/{{{/g, '{{');
      EmailData = EmailData.replace(/}}}/g, '}}');
      EmailData = EmailData.replace(/{{#if firstName}}/g, '');
      EmailData = EmailData.replace(/{{#if lastName}}/g, '');
      EmailData = EmailData.replace(/{{#unless lastName}},/g, '');
      EmailData = EmailData.replace(/{{\/unless}}/g, '');
      EmailData = EmailData.replace(/{{\/if}}/g, '');
      EmailData = EmailData.replace(/{{else}}/g, '');
      Object.keys(dummyEmailPlaceholders).map(ele => {
        const replaceStr = `{{${ele}}}`;
        EmailData = EmailData.replace(new RegExp(replaceStr, 'g'), dummyEmailPlaceholders[ele]);
        emailSubject = emailSubject.replace(new RegExp(replaceStr, 'g'), dummyEmailPlaceholders[ele]);
      });
      localStorage.setItem('emailPreviewSubject', emailSubject);
      localStorage.setItem('emailPreview', EmailData);
      const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
      window.open('/emailpreview/preview', 'Email Preview', params);
    });
  }

  render() {
    const paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus' },
      { id: 'email-templates', name: 'Email Templates', href: '/app/campus/emails' },
      { id: 'customize-email', name: 'Customize', href: '' },
    ];
    const { loading, template = {} } = this.props;
    const { match: { params: { templateId } = {} } = {} } = this.props;
    const { emailVars = {}, displayName } = template || {};
    const {
      editorState, footerEditorState, emailBody, emailFooter, emailSubject,
      invalidemailBody, invalidemailSubject, invalidemailFooter, activateTemplate,
      showNotification, notificationTitle, errorType, templateName, invalidtemplateName,
      isUserTemplate, description, isSharedWithCompany,
    } = this.state;


    return (
      <div className="xpa-email-templates">
        <BackRow paths={paths} />
        <input
          style={{ height: 0, opacity: 0 }}
          ref={this.notifRef}
        />
        <div className="bx--type-gamma font-weight-bold text-capitalize pb-1">{`${templateId !== 'add' ? `Customize -  ${this.state.displayName || displayName}` : 'Custom Template'}`}</div>
        {
          showNotification && (
            <InlineNotification lowContrast
              kind={errorType}
              title={notificationTitle}
              subtitle=""
              iconDescription="Email template notifications"
              onCloseButtonClick={() => this.setState({
                showNotification: false,
                notificationTitle: '',
              })}
            />
          )
        }
        <div className="mb-4">
          {(templateId !== 'add' && !isUserTemplate) ? (
            <p className="bx--type-zeta font-weight-normal text-justify">
              In this section, you can modify the subject, body and footer of the current email template.
                Email templates contain <b>Placeholders</b>. Placeholders are dynamic content units that change based on the context.
                For example: For an email template that is sent to candidates, the applicant name changes for each applicant.
                So we define the applicant name as a placeholder in the template.
                It is very essential that that placeholders are properly added to the template.
                Please make sure that you check the content of the email by using <b>"Send test email"</b>
                feature to verify your changes before saving them.
            </p>) : (
            <p className="bx--type-zeta font-weight-normal text-justify">
              In this section, you can add new template with custom template name, subject, body and footer of email.
                  Please make sure that you check the content of the email by using <b>"Send test email"</b>
                  feature to verify your changes before saving them.
            </p>
          )}
        </div>
        {(templateId !== 'adds') && (
          <Fragment>
            <p className="bx--type-zeta font-weight-normal pb-0 mb-0">
              The following placeholders are available to use in the email body and subject sections:
            </p>
            <div className="bx--row">
              <div className="bx--col-lg-6 bx--col-md-6 bx--colsm-12 bx--col-xs-12">
                <Form onSubmit={this.handleOnSubmit}>
                  {(templateId === 'add' || isUserTemplate) && (
                    <div>
                      <div className="mt-4">
                        <TextInput
                          id="template-name"
                          name="templateName"
                          labelText="Template Name"
                          placeholder="Template Name"
                          hideLabel={false}
                          invalid={invalidtemplateName}
                          invalidText="Template name should contain atleast 6 characters"
                          onChange={this.handleInputChange}
                          value={templateName}
                          required
                          minLength={6}
                          maxLength={250}
                        />
                      </div>
                      <div className="mt-4">
                        <TextInput
                          id="template-description"
                          name="description"
                          labelText="Description"
                          placeholder="Description"
                          hideLabel={false}
                          invalid={invalidtemplateName}
                          invalidText="Please provide description"
                          onChange={this.handleInputChange}
                          value={description}
                          required
                          minLength={6}
                          maxLength={250}
                        />
                      </div>
                    </div>)}
                  <div className="mt-4">
                    <TextInput
                      id="email-subject"
                      name="emailSubject"
                      labelText="Email Subject"
                      placeholder="Email Subject"
                      hideLabel={false}
                      invalid={invalidemailSubject}
                      invalidText="Email subject should contain atleast 6 characters"
                      onChange={this.handleInputChange}
                      value={emailSubject}
                      required
                      minLength={6}
                      maxLength={250}
                    />
                  </div>
                  <div className="mt-4">
                    <div className="bx--label">Email Body</div>
                    <Editor
                      editorState={editorState}
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'colorPicker'],
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                      }}
                      onEditorStateChange={this.onEditorStateChange}
                    >
                      <textarea
                        name="email-body"
                        disabled
                        value={emailBody}
                      />
                    </Editor>
                  </div>
                  <div className="mt-4">
                    <div className="bx--label">Email Footer</div>
                    <Editor
                      editorState={footerEditorState}
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'colorPicker'],
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                      }}
                      onEditorStateChange={this.onFooterEditorStateChange}
                    >
                      <textarea
                        name="email-footer"
                        disabled
                        value={emailFooter}
                      />
                    </Editor>
                  </div>
                  {/* {(templateId !== 'add' && !isUserTemplate) && (
              <div className="mt-4">
                <Checkbox
                  checked={activateTemplate}
                  labelText="Set as active template"
                  name="activateTemplate"
                  onChange={() => {
                    this.setState((state) => {
                      return {
                        activateTemplate: !state.activateTemplate,
                      };
                    });
                  }}
                  id="is-active-template"
                />
              </div>
            )} */}
                  {/* {((templateId === 'add') || isUserTemplate) && (
              <div className="mt-4">
                <Checkbox
                  checked={isSharedWithCompany}
                  labelText="Share with the company"
                  name="isSharedWithCompany"
                  onChange={() => {
                    this.setState((ps) => {
                      return {
                        isSharedWithCompany: !ps.isSharedWithCompany,
                      };
                    });
                  }}
                  id="isSharedWithCompany"
                />
              </div>
            )} */}
                  <Button className="mt-4" type="submit">
                    Save Template
            </Button>
                  {
                    (templateId !== 'add') && (
                      <Button kind="tertiary" className="mt-4 ml-2" onClick={this.handleSendTestEmail}>
                        Send Test Email
                      </Button>
                    )
                  }
                  <Button kind="tertiary" className="mt-4 ml-2" target="_blank" onClick={this.onPreview}>
                    Preview Email
            </Button>
                </Form>
              </div>
              <div className="mb-2 mt-2 bx--col-lg-6 bx--col-md-6 bx--colsm-12 bx--col-xs-12">
                {
                  ((templateId !== 'add') && !isUserTemplate) && (<Fragment>
                    <div className="bx--row mb-2">
                      <div className="bx--col-lg-3"><strong>Placeholder</strong></div>
                      <div className="bx--col-lg-9 d-none d-lg-block"><strong>Description</strong></div>
                    </div>
                    <hr className="mt-0 mb-0" />
                    <div>
                      {
                        emailVars && Object.keys(emailVars).map(r => (
                          <div className="bx--row border-bottom pt-2 pb-2" key={r}>
                            <div className="bx--col-lg-3 mb-1"><span>{`{{${r}}}`}</span></div>
                            <div className="bx--col-lg-9">{emailVars[r]}</div>

                          </div>
                        ))
                      }
                    </div>
                  </Fragment>)
                }
                {
                  (templateId === 'add' || isUserTemplate === true) && (
                    <Fragment>
                      <div>
                        <div className="mb-4">
                          <div><h5 className="xpa-color-charcoal">Browse Companies Placeholders</h5></div>
                          <div className="bx--row mb-2">
                            <div className="bx--col-lg-3"><strong>Placeholder</strong></div>
                            <div className="bx--col-lg-9 d-none d-lg-block"><strong>Description</strong></div>
                          </div>
                          <hr className="mt-0 mb-0" />
                          <div>
                            <div className="bx--row border-bottom pt-2 pb-2">
                              <div className="bx--col-lg-3 mb-1"><span>{`{{employerName}}`}</span></div>
                              <div className="bx--col-lg-9">Name of the employer</div>
                            </div>
                            <div className="bx--row border-bottom pt-2 pb-2">
                              <div className="bx--col-lg-3 mb-1"><span>{`{{companyName}}`}</span></div>
                              <div className="bx--col-lg-9">Name of the company</div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div><h5 className="xpa-color-charcoal">Browse Students Placeholders</h5></div>
                          <div className="bx--row mb-2">
                            <div className="bx--col-lg-3"><strong>Placeholder</strong></div>
                            <div className="bx--col-lg-9 d-none d-lg-block"><strong>Description</strong></div>
                          </div>
                          <hr className="mt-0 mb-0" />
                          <div>
                            <div className="bx--row border-bottom pt-2 pb-2">
                              <div className="bx--col-lg-3 mb-1"><span>{`{{firstName}}`}</span></div>
                              <div className="bx--col-lg-9">Name of the student</div>
                            </div>
                            <div className="bx--row border-bottom pt-2 pb-2">
                              <div className="bx--col-lg-3 mb-1"><span>{`{{studentID}}`}</span></div>
                              <div className="bx--col-lg-9">Republic Polytechnic ID</div>
                            </div>
                            <div className="bx--row border-bottom pt-2 pb-2">
                              <div className="bx--col-lg-3 mb-1"><span>{`{{studentSchool}}`}</span></div>
                              <div className="bx--col-lg-9">Student's School</div>
                            </div>
                            <div className="bx--row border-bottom pt-2 pb-2">
                              <div className="bx--col-lg-3 mb-1"><span>{`{{studentCourse}}`}</span></div>
                              <div className="bx--col-lg-9">Student's Course</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>)
                }

              </div>

            </div>
          </Fragment>)}

        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.emailTemplates.loading,
  user: state.x0paDuser.user,
  template: state.emailTemplates && state.emailTemplates.template,
  companyInfo: state.adminSettings && state.adminSettings.companyInfo,
});

const mapDispatchToProps = {
  getEmailTemplate,
  addEmailTemplate,
  sendEmailTemplate,
  getCompanyInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeEmail);
