import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Loading } from 'carbon-components-react';

import {fetchUserLoginState} from 'actions/userstate';
import { getUserInvitation, updateUserApplicationAndMap } from 'actions/invite';
import {
  validateReference,
} from 'actions/candidate/references';

class ReferenceInvite extends React.Component {
  state = {
    isError: false,
  };

  componentDidMount() {
    const {
      isUserLoggedIn,
      match: { params: { id } = {} } = {},
    } = this.props;
    this.getReferenceFeedback();
    if (!isUserLoggedIn) {
      window.sessionStorage.setItem('xpa-redirect-uri', `/public/rf/${id}`);
    }
  }

  openPopup = () => {
    const purl = `${window.location.origin}/auth/candidate/linkedin`;
    const wwidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const wheight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
    const wleft = ((wwidth / 2) - 400);
    const wtop = ((wheight / 2) - 300);
    const winFeatures = `width=800,height=600,top=${wtop},left=${wleft}`;
    window.open(purl, 'lpopup', winFeatures);
  }

  getReferenceFeedback = () => {
    const { match: { params: { id = '' } } } = this.props;
    const rid = id && id.split('x');
    if (rid.length === 4) {
      this.props.validateReference(id)
        .then((res) => {
          if (res && res.error) {
            this.setState({
              isError: true,
            });
          } else {
            console.log(res);
          }
        });
    }
  }

  candidateView = () => {
    const {
      isUserLoggedIn,
      user: { roleId } = {},
      match: { params: { id } = {} } = {},
      referrer: {
        feedbackProvided,
        referrerName,
        referrerEmail,
      } = {},
    } = this.props;
    const { isError } = this.state;
    console.log(id);
    return (
      <Fragment>
        <div className="m-logo-wrap">
          <img
            src="https://s3.ap-southeast-1.amazonaws.com/x0pa-email-assets/x0pa-logo-300.png"
            alt="X0PA Ai Logo"
            className="m-logo"
            height="60"
            width="101"
          />
        </div>
        {
          isError && (
          <div className="text-center bx--type-gamma mt-4 mb-2">
            No reference invite found, probably you have followed a wrong link.
          </div>)
        }
        {
          feedbackProvided && !isError && (<div className="text-center bx--type-gamma mt-4 mb-2">You have already provided feedback to {referrerName}</div>)
        }
        {
          !feedbackProvided && !isError && (
            <Fragment>
              <div className="text-center bx--type-gamma mt-4 mb-2">
                You have been asked to give a recommendation { referrerName ? `to ${referrerName}`: ''}.
              </div>
              <div className="text-center">
                {
                  !isUserLoggedIn && (
                  <div>
                    <div className="text-center mb-2">
                      Please sign in below to provide your valuable feedback.
                    </div>
                    <div className="box-subscribe mt-2 mb-4">
                      <div className="linkedin-wrap" onClick={() => this.openPopup()}>
                        <img src={require('assets/images/linkedin/Sign-In-Large---Default.png')}
                        title="Sign in with LinkedIn" width="210" height="39" />
                      </div>
                    </div>
                  </div>)
                }
                {
                  (isUserLoggedIn && (roleId === 3)) && (
                  <div>
                    <div className="text-center">
                      Please click below to provide your valuable feedback.
                    </div>
                    <div className="box-subscribe mt-2 mb-4">
                      <a href={`/app/campus/candidate/rf/${id}`} className="bx--btn bx--btn--primary">Submit Feedback</a>
                    </div>
                  </div>)
                }
                {
                  (isUserLoggedIn && (roleId !== 3)) && (
                  <div>
                    <div className="text-center">
                      You are not logged in as a candidate, please <a href="/auth/logout">logout </a>
                      and open this link again.
                    </div>
                  </div>)
                }
              </div>
            </Fragment>
          )
        }
        
      </Fragment>);
  }

  render() {
    const { loading } = this.props;
    return (
      <div className="row" style={{ marginLeft: 0, marginRight: 0, height: '100%' }}>
        <div className="col-md-12 invite-content">
          <div className="body-register">
            <div className="card-invite">
              <div className="card card-content">
                { loading && (
                  <div className="bx--row justify-content-center">
                    <Loading withOverlay={false} />
                  </div>)
                }
                {
                  this.candidateView()
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.jobs.loading,
  error: state.jobs.error,
  application: state.invitation,
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
  referrer: state.candidateReferences.referrer,
});

const mapDispatchToProps = {
  getUserInvitation,
  updateUserApplicationAndMap,
  fetchUserLoginState,
  validateReference,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceInvite);
