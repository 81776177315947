import { RECEIVE_EMPLOYEE_DATA } from '../actions/actionTypes';

const employeeData = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_EMPLOYEE_DATA:
      return action.data;
    default:
      return state;
  }
};

export default employeeData;
