import {
  REQUEST_XOPA_APPLICATIONS,
  ERROR_XOPA_APPLICATIONS,
  SUCCESS_XOPA_APPLICATIONS,
} from '../../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  applications: [],
};

const applicationData = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_XOPA_APPLICATIONS:
      return {
        ...state,
        applications: [],
        loading: false,
        error: null,
      };
    case ERROR_XOPA_APPLICATIONS:
      return {
        ...state,
        applications: [],
        error: payload.error,
        loading: true,
      };
    case SUCCESS_XOPA_APPLICATIONS:
      return {
        applications: payload.applications,
        loading: true,
        error: null,
      };
    default:
      return state;
  }
};

export default applicationData;
