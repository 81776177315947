import humanizeDuration from 'humanize-duration';
const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms',
      }
    },
    round: true,
    largest: 2,
    spacer: '',
    serialComma: false
});

const getHumanTime = (timeInMs, options) => {
    return shortEnglishHumanizer(timeInMs || 0, options);
}

const HumanTime = {
    getHumanTime
}
export default HumanTime;