import {withRouter} from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import './editJob.css';

class EditJob extends Component {
     
    render() {
        const {job} = this.props;
        return (
            <div className="card">
                <button className ='edit-btn' onClick = { ()=>this.props.history.push({pathname:'/app/campus/jobs/new/0',state:{...job}}) }>Edit</button>
            </div>
        );
    }
}

export default withRouter(EditJob);