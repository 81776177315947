import { withTranslation } from 'react-i18next';
import React from 'react';
import { Mixpanel } from './Mixpanel';

const GetCurrentPath = () => window.location.href.replace(window.location.origin, '');

const SetMixPanel = (comment = null) => {
  let tarckStatement = comment;
  if (tarckStatement === null) Mixpanel.track(`OPENED_PAGE -> ${GetCurrentPath()}`);
  else {
    tarckStatement = tarckStatement.toUpperCase().replace(/ /g, '_');
    Mixpanel.track(tarckStatement);
  }
  // comment !== null ? console.log(tarckStatement) : console.log(`OPENED_PAGE -> ${GetCurrentPath()}`);
};

export default SetMixPanel;

export { GetCurrentPath };
