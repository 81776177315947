import callApi from '../../utils/callApi';
import { flaskUrl } from '../../utils/config';
import { REQUEST_TASK_STATUS } from '../actionTypes';

const checkTaskStatus = taskId => (dispatch) => {
  // const url = `${flaskUrl}/jobs/uploads/status/${taskId}?_=${new Date().getTime()}`;
  const url = `${flaskUrl}/resumes/task/${taskId}?_=${new Date().getTime()}`;

  const params = {
    actionType: REQUEST_TASK_STATUS,
    url,
    dispatch,
    method: 'GET',
  };

  return callApi(params);
};

export default checkTaskStatus;
