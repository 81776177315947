import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Tile, Tag } from 'carbon-components-react';
import { Link } from 'react-router-dom';

import Avatar from 'components/common/Avatar';

class ApplicationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      firstname = '',
      lastname = '',
      picture = null,
      isEnabled = false,
      applicationName = '-',
      company: {
        companyName = '-',
        displayComapanyName='-' 
      },
      appId = 1 
    } = this.props;
    const data = {
      firstname,
      lastname,
      picture: picture || null,
    };
    return (
      <Fragment>
        <Tile className="p-2 shadow rounded">
          <Avatar isCompanyLogo user={data} size="100" classNameInfo="rounded mx-auto img-fluid d-block" />
          <div className="mt-3"><Link to={`marketplaceapps/${appId}`} className="bx--type-epsilon" title="Application">{applicationName}</Link></div>
          <div className="mt-1 bx--type-omega text-dark" title="Company">{displayComapanyName}</div>
          {/* <div className="rating mt-2"></div> */}
          <div className=" text-right"><Tag type={isEnabled ? 'community' : 'beta'} > {isEnabled ? 'Enabled' : 'Disabled'}</Tag></div>
        </Tile>
      </Fragment>
    );
  }
}

export default ApplicationCard;
