import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button, Modal, Pagination, Tile,
  InlineLoading, InlineNotification,
} from 'carbon-components-react';
import StarRatingComponent from 'react-star-rating-component';
import _ from 'lodash';

import './internCandidate.css';
import BioInformation from './bioInformation';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import Toast from 'components/common/Toast';
// import { Mixpanel } from 'components/common/Mixpanel';
import SetMixPanel from 'components/common/GetCurrentPath';


import {
  REQUEST_ALL_SKILLS,
  REQUEST_CREATE_NEW_SKILLS,
} from 'actions/talentSearch/actionTypes';

import {
  manageSkill,
} from 'actions/candidate/CandidateDetails';

import {
  getCandidateProfile,
  downloadCandidateProfile,
} from 'actions/talentSearch/candidatesList';

import {
  parseJd,
  fetchDropdownData,
  associateJd,
  fetchAll,
  createNewOption,
} from 'actions/talentSearch/newJob';

import {
  getFinlandProfile,
  createFinlandProfileSpecificInfo,
  updateFinlandProfileSpecificInfo,
  deleteFinlandProfileSpecificInfo,
  getFinlandProfileSpecificInfo,
  submitProfile,
  updatePersonSkill,
  addToTalentPool,
} from 'actions/candidate/businessFinland';

import {
  REQUEST_GET_PREFERENCE_RANKS,
  REQUEST_GET_WORK_PREFERENCE,
  REQUEST_UPDATE_PREFERENCE_RANKS,
} from 'actions/actionTypes';

import { REQUEST_USERINFO } from 'actions/userProfile/action.constant';

import ProfileInfo from '../common/profile/ProfileInfo';

let loading = false;

class BusinessFinland extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stageIndex: 0,
      selectedSkills: [],
      newSkill: [],
      compitencies: {},
      showAddSkill: false,
      rowsData: [],
      activePage: 0,
      limit: 10,
      activeModal: false,
      uploadResumeClicked: false,
      Preferences: [],
      stages: 6,
      taskId: '',
      isResumeUploaded: true,
      uploadComplete: false,
    };
  }

  componentDidMount() {
    let {
      match: {
        params: {
          stageIndex = 0,
        } = {},
      } = {},
    } = this.props;
    if (stageIndex > this.state.stages) {
      this.history.replace(`/app/campus/candidate/business/finland/0`);
      stageIndex = 0;
    }
    this.setState({ stageIndex: Number(stageIndex) }, () => {
      this.props.fetchAll({
        actionType: REQUEST_USERINFO,
        apiName: 'userinfo-base/profileInformation',
        value: '',
      }).then(() => {
        const { getCandidateProfile, user: { profileId = '' } } = this.props;
        getCandidateProfile(Number(profileId)).then(() => {
          const { candidateProfile: { workpreferenceranking = [], documents = [] } = {} } = this.props;
          if (workpreferenceranking !== null && Array.isArray(workpreferenceranking) && workpreferenceranking.length > 0) {
            const byRank = workpreferenceranking.slice(0);
            byRank.sort((a, b) => (Number(a.rank - b.rank)));
            this.setState({
              Preferences: Array.isArray(byRank) && byRank.map(({ finlandworkpreference = {}, workPreferenceRankId }) => (
                { label: finlandworkpreference.workPreference, value: finlandworkpreference.workPreferenceId, workPreferenceRankId }
              )),
            }, () => {
              if (stageIndex == 0 && Array.isArray(documents) && documents.length > 0) {
                this.setState({ stageIndex: 1 }, () => this.props.history.replace('/app/campus/candidate/business/finland/1'));
              } else this.setState({ isResumeUploaded: false });
            });
          } else {
            this.props.getFinlandProfileSpecificInfo({
              infoAction: REQUEST_GET_WORK_PREFERENCE,
              infoType: 'finlandworkpreferences',
            }).then(res => this.setState({ Preferences: res && res !== null && Array.isArray(res) && res.map(n => ({ label: n.workPreference, value: n.workPreferenceId })) }, () => {
              if (stageIndex == 0 && Array.isArray(documents) && documents.length > 0) {
                this.setState({ stageIndex: 1 }, () => this.props.history.replace('/app/campus/candidate/business/finland/1'));
              } else this.setState({ isResumeUploaded: false });
            }));
          }
          const { candidateProfile: { skills = [] } = {} } = this.props;
          const selectedSkills = skills.map((one) => {
            const { skill: { skillId = null, skillName = '' }, personSkillId, rating = 0 } = one;
            return ({
              label: skillName,
              value: skillId,
              rating,
              personSkillId,
            });
          });
          SetMixPanel();
          // Mixpanel.track('Business Finland Application Start');
          this.setState({ selectedSkills }, () => this.setRows());
        });
      });
    });
  }

  onChangeEvent = (e) => {
    const { target: { value = '', name = '' } } = e || {};
    this.setState({
      [name]: value,
    });
  }

  onSubmit = () => {
    const { user: { profileId = '' } } = this.props;
    const { stageIndex } = this.state;
    if (stageIndex > -1 && stageIndex < 6) {
      const { Preferences = null } = this.state;
      if (Preferences !== null && Array.isArray(Preferences)) {
        Preferences.map(({ label, value, workPreferenceRankId }, rank) => {
          loading = true;
          const data = {
            infoData: { workPreference: label, rank: rank + 1 },
            infoAction: REQUEST_UPDATE_PREFERENCE_RANKS,
            infoType: 'workpreferencerankings',
            infoTypeId: workPreferenceRankId,
          };
          if (workPreferenceRankId && workPreferenceRankId !== null) {
            this.props.updateFinlandProfileSpecificInfo(data).then(() => {
              if (Preferences.length === rank + 1) {
                this.props.addToTalentPool(profileId);
                this.props.submitProfile([Number(profileId)]).then( (res) => {
                  loading = false;
                  SetMixPanel(`workinfinland_Submitting_profile ${this.state.stageIndex + 1}`);
                  // Mixpanel.track(`BusinessFinland: Submitting the pofile on satge ${this.state.stageIndex + 1}`);
                  this.setState({ stageIndex: this.state.stageIndex + 1 }, () => this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`));
                });
              }
            });
          } else {
            this.props.createFinlandProfileSpecificInfo(data).then(() => {
              if (Preferences.length === rank + 1) {
                this.props.addToTalentPool(profileId);
                this.props.submitProfile([Number(profileId)]).then( (res) => {
                  loading = false;
                  SetMixPanel(`workinfinland_Submitting_profile ${this.state.stageIndex + 1}`);
                  // Mixpanel.track(`BusinessFinland: Submitting the pofile on satge ${this.state.stageIndex + 1}`);
                  this.setState({ stageIndex: this.state.stageIndex + 1 }, () => this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`));
                });
              }
            });
          }
        });
      }
    }
  }


  onNextStep = () => {
    const { stageIndex } = this.state;
    if (stageIndex === 2) {
      const { getCandidateProfile, user: { profileId = '' } } = this.props;
      getCandidateProfile(Number(profileId)).then((res) => {
        const { candidateProfile: { skills = [] } = {} } = this.props;
        const selectedSkills = skills.map((one) => {
          const { skill: { skillId = null, skillName = '' }, personSkillId, rating = 0 } = one;
          return ({
            label: skillName,
            value: skillId,
            personSkillId,
            rating,
          });
        });
        this.setState({ selectedSkills }, () => {
          SetMixPanel(`workinfinland_Moving_to_next_step ${this.state.stageIndex}`);
          // Mixpanel.track(`BusinessFinland: Navigating to next page ${this.state.stageIndex}`);
          this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`);
          this.setRows();
        });
      });
    } else if (stageIndex === 4) {
      const { Preferences = null } = this.state;
      if (Preferences !== null && Array.isArray(Preferences)) {
        Preferences.map(({ label, value, workPreferenceRankId }, rank) => {
          const data = {
            infoData: { workPreference: label, rank: rank + 1 },
            infoAction: REQUEST_UPDATE_PREFERENCE_RANKS,
            infoType: 'workpreferencerankings',
            infoTypeId: workPreferenceRankId,
          };
          if (workPreferenceRankId && workPreferenceRankId !== null) this.props.updateFinlandProfileSpecificInfo(data).then(
            () => {
              this.setState({ stageIndex: stageIndex + 1 }, () => {
                SetMixPanel(`workinfinland_Moving_to_next_step ${this.state.stageIndex}`);
                // Mixpanel.track(`BusinessFinland: Navigating to next page ${this.state.stageIndex}`);
                this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`);
              });
            },
          );
          else this.props.createFinlandProfileSpecificInfo(data).then(
            () => {
              this.setState({ stageIndex: stageIndex + 1 }, () => {
                SetMixPanel(`workinfinland_Moving_to_next_step ${this.state.stageIndex}`);
                // Mixpanel.track(`BusinessFinland: Navigating to next page ${this.state.stageIndex}`);
                this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`);
              });
            },
          );
        });
      }
    }
    
    if (stageIndex !== 4 && stageIndex > -1 && stageIndex < 6) {
      if (stageIndex === 2) this.setState({ activePage: 0, limit: 10 });
      this.setState({ stageIndex: stageIndex + 1 }, () => {
        SetMixPanel(`workinfinland_Moving_to_next_step ${this.state.stageIndex}`);
        // Mixpanel.track(`BusinessFinland: Navigating to next page ${this.state.stageIndex}`);
        this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`);
      });
    }
  }

  onPrevStep = () => {
    const { stageIndex } = this.state;
    if (stageIndex > 0) {
      if (stageIndex === 3) {
        const { getCandidateProfile, user: { profileId = '' } } = this.props;
        getCandidateProfile(Number(profileId)).then((res) => {
          const { candidateProfile: { skills = [] } = {} } = this.props;
          const selectedSkills = skills.map((one) => {
            const { skill: { skillId = null, skillName = '' }, rating = 0 } = one;
            return ({
              label: skillName,
              value: skillId,
              rating,
            });
          });
          this.setState({ selectedSkills }, () => {
            SetMixPanel(`workinfinland_Moving_to_previous_step ${this.state.stageIndex}`);
            // Mixpanel.track(`BusinessFinland: Navigating to prev page ${this.state.stageIndex}`);
            this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`);
            this.setRows();
          });
        });
        this.setState({ stageIndex: stageIndex - 1 }, () => {
          SetMixPanel(`workinfinland_Moving_to_previous_step ${this.state.stageIndex}`);
          // Mixpanel.track(`BusinessFinland: Navigating to prev page ${this.state.stageIndex}`);
          this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`);
        });
      } else {
        if (stageIndex === 4) this.setState({ activePage: 0, limit: 10 });
        this.setState({ stageIndex: stageIndex - 1 }, () => {
          SetMixPanel(`workinfinland_Moving_to_previous_step ${this.state.stageIndex}`);
          // Mixpanel.track(`BusinessFinland: Navigating to prev page ${this.state.stageIndex}`);
          this.props.history.replace(`/app/campus/candidate/business/finland/${this.state.stageIndex}`);
        });
      }
    }
  }

  onSubmitInfo = () => {
  }

  onSkillsInputChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.props.fetchAll({
        actionType: REQUEST_ALL_SKILLS,
        apiName: 'skills',
        paramName: 'skillName',
        value: _.toLower(value),
      }).then((res) => {
        const options = res.map(one => ({ label: one.skillName, value: one.skillId }));
        if (cb && typeof cb === 'function') cb(options);
      });
    }
  }

  handleSkillDropdownChange = (name, selectedOption) => {
    let difference = [];
    const { candidateProfile: { profile: { profileId = '' } } } = this.props;
    if (!this.state[name] || !Array.isArray(this.state[name]) || !selectedOption || !Array.isArray(selectedOption)) return;
    if (this.state[name].length > selectedOption.length) {
      difference = this.state[name].filter(x => !selectedOption.includes(x));
      const link = `${profileId}/skill/${difference[0].personSkillId ? difference[0].personSkillId : ''}`;
      this.props.manageSkill('DELETE', link).then(() => this.setState({ [name]: selectedOption }, () => this.setRows()));
    } else {
      difference = selectedOption.filter(x => !this.state[name].includes(x));
      const [{ label = '', value = '' }] = difference;
      const link = `${profileId}/skill`;
      const skill = { skillName: label, skillId: value };
      this.props.manageSkill('POST', link, skill).then((res) => {
        const { personSkillId, skillId } = res.data ? res.data[0] : {};
        const rows = selectedOption.map((row) => {
          if (row.value == skillId) {
            return {
              label: row.label,
              value: row.value,
              personSkillId: Number(personSkillId),
            };
          } else return row;
        });
        this.setState({ [name]: rows }, () => this.setRows());
      });
    }
  }

  onSliderChange = ({ value }, name) => {
    const { compitencies } = this.state;
    if (name === null || value === null) return;
    compitencies[name] = value;
    this.setState({ compitencies });
  }

  onAddSkill = () => {
    this.setState({ showAddSkill: true });
  }

  onStarClick = (nextValue, prevValue, name) => {
    let { selectedSkills = [] } = this.state;
    const { candidateProfile: { profile: { profileId = '' } } } = this.props;
    selectedSkills = selectedSkills.map((sk) => { if (sk.value == name) sk.rating = nextValue; return sk; });
    const skill = selectedSkills.find(({ value }) => value == name);
    const Data = {
      skillName: skill && skill.label ? skill.label : '',
      rating: nextValue,
      profileId,
      personSkillId: skill.personSkillId,
    };
    if (profileId !== null && skill.personSkillId !== null) this.props.updatePersonSkill(Data);
    if (nextValue && nextValue !== null && name && name !== null) {
      this.setState({ selectedSkills }, () => this.setRows());
    }
  }

  setRows = () => {
    const { selectedSkills } = this.state;

    const rowsData = selectedSkills.map(({ label, value, rating }) => ({
      skill: label,
      competency: (<StarRatingComponent
        name={value.toString()}
        starCount={5}
        value={rating || 0}
        starColor="#5596e6"
        emptyStarColor="#ddd"
        onStarClick={this.onStarClick}
        renderStarIcon={(index, val) => (
          <span>
            <i className={index <= val ? 'fa fa-thumbs-up mr-1' : 'fa fa-thumbs-up  mr-1'} />
          </span>
        )
      }
      />),
      // action: <Button kind="danger" small name={value.toString()} onClick={this.onDeleteSkill}>Delete</Button>,
      id: value.toString(),
    }));
    this.setState({ rowsData });
  }

  handleDropdownChange = (selectedOption, value, name) => {
    this.setState({
      [name]: value,
    });
  };

  onSliderChange = ({ value }, name) => {
    this.setState({ [name]: value });
  }

  handlePaginationChange = ({ page, pageSize }) => {
    let { activePage = 0, limit } = this.state;
    if (page > 1) {
      activePage = (page - 1) * pageSize;
      limit = activePage + pageSize;
    } else {
      activePage = 0;
      limit = 10;
    }
    this.setState({ activePage, limit });
  };

  onNewOptionCreate = (params, value) => {
    const actionData = {
      apiName: '',
      actionType: null,
      data: {},
    };
    const { candidateProfile: { profile: { profileId = '' } } } = this.props;
    let { selectedSkills = [] } = this.state;
    actionData.apiName = 'Skills';
    actionData.actionType = REQUEST_CREATE_NEW_SKILLS;
    actionData.data = { skillName: value, typeId: 1 };
    this.props.createNewOption(actionData).then((data) => {
      let res = {};
      if (data.data) res = data.data;
      else res = data;
      if (res.skillId) {
        const newSkill = ({
          label: res.skillName,
          value: Number(res.skillId),
        });
        const link = `${profileId}/skill`;
        const skill = { skillName: newSkill.label, skillId: newSkill.value };
        this.props.manageSkill('POST', link, skill).then((res) => {
          if (res.error) selectedSkills.push(newSkill);
          else {
            const { personSkillId } = res.data ? res.data[0] : {};
            selectedSkills.push({ label: newSkill.label, value: newSkill.value, personSkillId: Number(personSkillId) });
          }
          this.setState({
            selectedSkills,
            newSkill: {},
            showAddSkill: false,
          }, () => this.setRows());
        });
      }
    });
  }

  activateModal = () => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = (flag) => {
    SetMixPanel('workinfinland_Resume_Uploaded_sucessfully');
    this.setState({
      activeModal: false,
      uploadResumeClicked: flag,
    });
  };

  toastSuccess = () => {
    setTimeout(() => {
      // window.location.reload();
      this.props.history.replace('/app/campus/candidate/business/finland/1');
      this.setState({ uploadComplete: true, stageIndex: 1, uploadResumeClicked: false });
    }, 5000);
  };

  refresh = (partialRefresh) => {
    // setTimeout(() => {
    //   });
    // }, 1000);
  };

  onDragEnd = (Preferences) => {
    this.setState({ Preferences });
  }

  render() {
    const {
      stageIndex = 0,
      rowsData,
      selectedSkills,
      activePage,
      limit,
      activeModal,
      Preferences,
      uploadResumeClicked,
      stages,
      isResumeUploaded,
      uploadComplete,
    } = this.state;

    // const otherActionButtons = [(<Button small onClick={this.onAddSkill}>Add Skill</Button>)];
    const { skillOptions = [], taskId } = this.props;
    // const invalidText = 'This field is required';
    const headers = [
      { key: 'skill', header: 'Skill' },
      { key: 'competency', header: 'Competency' },
      // { key: 'action', header: 'Action' },
    ];

    // task_state: SUCCESSFUL CREATION
    
    return (
      <Fragment>
        <div className="InternCandidate">
          <div className={`form-horizontal${stageIndex !== 1 ? ' card shadow' : ''}`}>
            <div className="newjob__form__header bx--row newjob__form__header--center">
              <h3 className="bx--col-12 text-justify text-uppercase bx--type-delta ml-3">Candidate information</h3>
              <div className={`bx--col m-2 m-md-4 m-lg-4 shadow-none${stageIndex === 1 ? ' card' : ''}`} style={{ height: 40 }}>
                <Progress
                  className="bx--col mt-2"
                  theme={
                    {
                      active: {
                        symbol: <div className="bx--type-zeta">{`${Math.round((100 / (stages)) * (stageIndex == 0 ? stageIndex : stageIndex + 1))}%`}</div>,
                        trailColor: '#f4f7fb',
                        color: '#5596e6',
                      },
                      success: {
                        symbol: <div className="bx--type-zeta">{`${Math.round((100 / (stages)) * (stageIndex == 0 ? stageIndex : stageIndex + 1))}%`}</div>,
                        trailColor: 'lime',
                        color: 'green',
                      },
                    }
                  }
                  percent={(100 / (stages)) * (stageIndex == 0 ? stageIndex : stageIndex + 1)}
                />
              </div>
            </div>
            {
              stageIndex === 0
                ? (
                  <div className="bx--form-item modal-footer xpa-banner">
                    {uploadResumeClicked === true && (
                      <div className="toastCandidate">
                        <Toast taskId={taskId} successAction={this.toastSuccess} partialRefresh={this.refresh} />
                      </div>
                    )}
                    <p className="bx--label-description">Upload your resume (.doc/.pdf) and let X0PA auto-fill the application for you!</p>
                    <div className="justify-content-center">
                      <ResumeUpload pageType="candidate-profile" dismiss={this.dismissModal} active={activeModal} multipleResume={false} />
                      <Button disabled={uploadResumeClicked} onClick={this.activateModal}>Upload Resume</Button>
                    </div>
                    {uploadResumeClicked && <div><InlineLoading success={uploadComplete} description="your resume is being processed  please wait..." /></div>}
                  </div>)
                : null
            }
            {
              stageIndex === 1
                ? (
                  <BioInformation onPrevStep={e => this.onPrevStep(e)} onNextStep={e => this.onNextStep(e)} finland />
                ) : null
            }
            {
              stageIndex === 2
                ? (
                  <ProfileInfo intern finland/>
                ) : null
            }
            {
              stageIndex === 3
                ? (
                  <div className="bx--form-item modal-footer xpa-banner">
                    <div className="mb-3 bx--col-12">
                      <div className="form-group row">
                        <div className="col-xs-12 col-sm-12 col-md-3 bx--col-xs-12 bx--col-sm-12 bx--col-md-3">
                          <div className="form-label pt-3 pb-0 mb-0 bx--type-epsilon">Skills from resume :</div>
                        </div>
                        <div className="ml-4 mr-4 mt-2 bx--col">
                          <DropDownBoxWithCreate
                            isAsync
                            placeholder="Skills"
                            custom
                            multi
                            mainClassName="bx--col-12 p-0"
                            required
                            isClearable={false}
                            name="selectedSkills"
                            newOptionCreator={this.onNewOptionCreate}
                            onInputChange={this.onSkillsInputChange}
                            selectedValue={selectedSkills || []}
                            onChange={(value, name) => this.handleSkillDropdownChange(name, value)}
                          />
                        </div>
                      </div>
                      <div className="compitency mb-3">
                        {
                          <div>
                            <GenericDataTable
                              className=""
                              title="Skills & Competency"
                              headers={headers}
                              rows={rowsData.slice(activePage, limit)}
                            />
                            {rowsData.length > 0 ? (
                              <Pagination
                                onChange={this.handlePaginationChange}
                                pageSizes={[10, 20, 30, 40, 50]}
                                totalItems={selectedSkills.length}
                              />) : (
                                <Tile>
                                  <div className="card-body text-center">
                                    <div className="empty-icon">
                                      <i className="fa fa-flag fa-2x" />
                                    </div>
                                    <p className="empty-title h5">There are no skills associated.</p>
                                  </div>
                                </Tile>
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                ) : null
            }
            {
              stageIndex === 4
                ? (
                  <div className="bx--row xpa-banner p-3">
                    <div className="bx--type-zeta mb-3 ml-4">
                      Align your interests based on priorities.
                      Please rank the following preferences in descending order with highest priority on top.
                    </div>
                    <div className="bx--row bx--col justify-content-center mb-3 m-0 p-0">
                    </div>
                  </div>
                ) : null
            }
            {
              stageIndex === 5 && (
                <div className="" style={{ height: '-webkit-fill-available' }}>
                  <div className="text-center bx--type-alpha">Your profile has been successfully created.</div>
                  <div className="align-content-center text-center">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{ maxWidth: 100 }} >
                      <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                      <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg>
                  </div>
                  <div className="m-3 text-center bx--row justify-content-center">
                    {/* <div className="m-3">
                      <Button kind="tertiary" className="" onClick={() => this.props.history.push('/app/campus/jobs')}>Back to jobs list</Button>
                    </div> */}
                    <div className="m-3">
                      <Button kind="primary" disabled={this.props.loading} className="" onClick={() => { SetMixPanel('workinfinland_Clicked_view_Profile'); this.props.history.push('/app/profile'); }}>View Profile</Button>
                    </div>
                  </div>
                </div>
              )
            }
            <div className="bx--row mt-3 justify-content-center ml-0 mb-2">
              {
                stageIndex !== 1 && stageIndex > 0 && stageIndex < stages - 1
                  ? (
                    <div className="mr-2">
                      <Button kind="tertiary" small className="" onClick={e => this.onPrevStep(e)}>Previous</Button>
                    </div>
                  ) : null
              }
              {
                stageIndex !== 1 && stageIndex < stages - 2
                  ? (
                    <div className="mr-2">
                      <Button kind="primary" disabled={!isResumeUploaded && stageIndex == 0} small className="" onClick={e => this.onNextStep(e)}>Next</Button>
                    </div>
                  ) : null
              }
              {
                stageIndex === stages - 2
                  ? (
                    <div className="mr-2">
                      <Button kind="primary" small className="" onClick={e => this.onSubmit(e)}>Submit</Button>
                    </div>
                  ) : null
              }
            </div>
          </div>
        </div>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </Fragment>);
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  companyAndOffice: state.companyAndOffice,
  loading: state.roboroyApplications.loading || loading,
  skillOptions: state.jobCreation && state.jobCreation.skills,
  candidateProfile: state.roboroyApplications.candidateProfile,
  taskId: state.resumeUpload.taskId,
});

//state.companyAndOffice.loading || state.roboroyApplications.loading || state.finlandProfile.loading

const mapDispatchToProps = {
  fetchAll,
  parseJd,
  fetchDropdownData,
  associateJd,
  getCandidateProfile,
  downloadCandidateProfile,
  createNewOption,
  getFinlandProfile,
  createFinlandProfileSpecificInfo,
  updateFinlandProfileSpecificInfo,
  deleteFinlandProfileSpecificInfo,
  getFinlandProfileSpecificInfo,
  manageSkill,
  submitProfile,
  updatePersonSkill,
  addToTalentPool,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessFinland);
