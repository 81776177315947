import { withTranslation } from 'react-i18next';
import React from 'react';

const TimezonesList = ({ onChangeHandler, tzSelected = 'default-tzid', t }) => (
  <div className="bx--form-item">
    <div className="bx--select">
      <label htmlFor="x-tz-picker" className="bx--label">{t('setYourTimezone')}</label>
      <select onChange={ev => onChangeHandler(ev)} className="bx--select-input" name="x-tz-picker" value={tzSelected} id="x-tz-picker">
        <option className="bx--select-option" name="default-tzid" value="default-tzid" disabled>{t('selectTimezone')}</option>
        <option className="bx--select-input" value="Africa/Abidjan">
          UTC/GMT +00:00 - Africa/Abidjan
        </option>
        <option className="bx--select-input" value="Africa/Accra">
          UTC/GMT +00:00 - Africa/Accra
        </option>
        <option className="bx--select-input" value="Africa/Addis_Ababa">
          UTC/GMT +03:00 - Africa/Addis_Abab	
        </option>
        <option className="bx--select-input" value="Africa/Algiers">
          UTC/GMT +01:00 - Africa/Algiers			
        </option>
        <option className="bx--select-input" value="Africa/Asmara">
          UTC/GMT +03:00 - Africa/Asmara			
        </option>
        <option className="bx--select-input" value="Africa/Bamako">
          UTC/GMT +00:00 - Africa/Bamako			
        </option>
        <option className="bx--select-input" value="Africa/Bangui">
          UTC/GMT +01:00 - Africa/Bangui			
        </option>
        <option className="bx--select-input" value="Africa/Banjul">
          UTC/GMT +00:00 - Africa/Banjul			
        </option>
        <option className="bx--select-input" value="Africa/Bissau">
          UTC/GMT +00:00 - Africa/Bissau			
        </option>
        <option className="bx--select-input" value="Africa/Blantyre">
          UTC/GMT +02:00 - Africa/Blantyre			
        </option>
        <option className="bx--select-input" value="Africa/Brazzaville">
          UTC/GMT +01:00 - Africa/Brazzaville			
        </option>
        <option className="bx--select-input" value="Africa/Bujumbura">
          UTC/GMT +02:00 - Africa/Bujumbura			
        </option>
        <option className="bx--select-input" value="Africa/Cairo">
          UTC/GMT +02:00 - Africa/Cairo			
        </option>
        <option className="bx--select-input" value="Africa/Casablanca">
          UTC/GMT +01:00 - Africa/Casablanca			
        </option>
        <option className="bx--select-input" value="Africa/Ceuta">
          UTC/GMT +01:00 - Africa/Ceuta			
        </option>
        <option className="bx--select-input" value="Africa/Conakry">
          UTC/GMT +00:00 - Africa/Conakry			
        </option>
        <option className="bx--select-input" value="Africa/Dakar">
          UTC/GMT +00:00 - Africa/Dakar			
        </option>
        <option className="bx--select-input" value="Africa/Dar_es_Salaam">
          UTC/GMT +03:00 - Africa/Dar_es_Salaam			
        </option>
        <option className="bx--select-input" value="Africa/Djibouti">
          UTC/GMT +03:00 - Africa/Djibouti			
        </option>
        <option className="bx--select-input" value="Africa/Douala">
          UTC/GMT +01:00 - Africa/Douala			
        </option>
        <option className="bx--select-input" value="Africa/El_Aaiun">
          UTC/GMT +01:00 - Africa/El_Aaiun			
        </option>
        <option className="bx--select-input" value="Africa/Freetown">
          UTC/GMT +00:00 - Africa/Freetown			
        </option>
        <option className="bx--select-input" value="Africa/Gaborone">
          UTC/GMT +02:00 - Africa/Gaborone			
        </option>
        <option className="bx--select-input" value="Africa/Harare">
          UTC/GMT +02:00 - Africa/Harare			
        </option>
        <option className="bx--select-input" value="Africa/Johannesburg">
          UTC/GMT +02:00 - Africa/Johannesburg			
        </option>
        <option className="bx--select-input" value="Africa/Juba">
          UTC/GMT +03:00 - Africa/Juba			
        </option>
        <option className="bx--select-input" value="Africa/Kampala">
          UTC/GMT +03:00 - Africa/Kampala			
        </option>
        <option className="bx--select-input" value="Africa/Khartoum">
          UTC/GMT +02:00 - Africa/Khartoum			
        </option>
        <option className="bx--select-input" value="Africa/Kigali">
          UTC/GMT +02:00 - Africa/Kigali			
        </option>
        <option className="bx--select-input" value="Africa/Kinshasa">
          UTC/GMT +01:00 - Africa/Kinshasa			
        </option>
        <option className="bx--select-input" value="Africa/Lagos">
          UTC/GMT +01:00 - Africa/Lagos			
        </option>
        <option className="bx--select-input" value="Africa/Libreville">
          UTC/GMT +01:00 - Africa/Libreville			
        </option>
        <option className="bx--select-input" value="Africa/Lome">
          UTC/GMT +00:00 - Africa/Lome			
        </option>
        <option className="bx--select-input" value="Africa/Luanda">
          UTC/GMT +01:00 - Africa/Luanda			
        </option>
        <option className="bx--select-input" value="Africa/Lubumbashi">
          UTC/GMT +02:00 - Africa/Lubumbashi			
        </option>
        <option className="bx--select-input" value="Africa/Lusaka">
          UTC/GMT +02:00 - Africa/Lusaka			
        </option>
        <option className="bx--select-input" value="Africa/Malabo">
          UTC/GMT +01:00 - Africa/Malabo			
        </option>
        <option className="bx--select-input" value="Africa/Maputo">
          UTC/GMT +02:00 - Africa/Maputo			
        </option>
        <option className="bx--select-input" value="Africa/Maseru">
          UTC/GMT +02:00 - Africa/Maseru			
        </option>
        <option className="bx--select-input" value="Africa/Mbabane">
          UTC/GMT +02:00 - Africa/Mbabane			
        </option>
        <option className="bx--select-input" value="Africa/Mogadishu">
          UTC/GMT +03:00 - Africa/Mogadishu			
        </option>
        <option className="bx--select-input" value="Africa/Monrovia">
          UTC/GMT +00:00 - Africa/Monrovia			
        </option>
        <option className="bx--select-input" value="Africa/Nairobi">
          UTC/GMT +03:00 - Africa/Nairobi			
        </option>
        <option className="bx--select-input" value="Africa/Ndjamena">
          UTC/GMT +01:00 - Africa/Ndjamena			
        </option>
        <option className="bx--select-input" value="Africa/Niamey">
          UTC/GMT +01:00 - Africa/Niamey			
        </option>
        <option className="bx--select-input" value="Africa/Nouakchott">
          UTC/GMT +00:00 - Africa/Nouakchott			
        </option>
        <option className="bx--select-input" value="Africa/Ouagadougou">
          UTC/GMT +00:00 - Africa/Ouagadougou			
        </option>
        <option className="bx--select-input" value="Africa/Porto-Novo">
          UTC/GMT +01:00 - Africa/Porto-Novo			
        </option>
        <option className="bx--select-input" value="Africa/Sao_Tome">
          UTC/GMT +01:00 - Africa/Sao_Tome			
        </option>
        <option className="bx--select-input" value="Africa/Tripoli">
          UTC/GMT +02:00 - Africa/Tripoli			
        </option>
        <option className="bx--select-input" value="Africa/Tunis">
          UTC/GMT +01:00 - Africa/Tunis			
        </option>
        <option className="bx--select-input" value="Africa/Windhoek">
          UTC/GMT +02:00 - Africa/Windhoek			
        </option>
        <option className="bx--select-input" value="America/Adak">
          UTC/GMT -10:00 - America/Adak			
        </option>
        <option className="bx--select-input" value="America/Anchorage">
          UTC/GMT -09:00 - America/Anchorage			
        </option>
        <option className="bx--select-input" value="America/Anguilla">
          UTC/GMT -04:00 - America/Anguilla			
        </option>
        <option className="bx--select-input" value="America/Antigua">
          UTC/GMT -04:00 - America/Antigua			
        </option>
        <option className="bx--select-input" value="America/Araguaina">
          UTC/GMT -03:00 - America/Araguaina			
        </option>
        <option className="bx--select-input" value="America/Argentina/Buenos_Aires">
          UTC/GMT -03:00 - America/Argentina/Buenos_Aires			
        </option>
        <option className="bx--select-input" value="America/Argentina/Catamarca">
          UTC/GMT -03:00 - America/Argentina/Catamarca			
        </option>
        <option className="bx--select-input" value="America/Argentina/Cordoba">
          UTC/GMT -03:00 - America/Argentina/Cordoba			
        </option>
        <option className="bx--select-input" value="America/Argentina/Jujuy">
          UTC/GMT -03:00 - America/Argentina/Jujuy			
        </option>
        <option className="bx--select-input" value="America/Argentina/La_Rioja">
          UTC/GMT -03:00 - America/Argentina/La_Rioja			
        </option>
        <option className="bx--select-input" value="America/Argentina/Mendoza">
          UTC/GMT -03:00 - America/Argentina/Mendoza			
        </option>
        <option className="bx--select-input" value="America/Argentina/Rio_Gallegos">
          UTC/GMT -03:00 - America/Argentina/Rio_Gallegos			
        </option>
        <option className="bx--select-input" value="America/Argentina/Salta">
          UTC/GMT -03:00 - America/Argentina/Salta			
        </option>
        <option className="bx--select-input" value="America/Argentina/San_Juan">
          UTC/GMT -03:00 - America/Argentina/San_Juan			
        </option>
        <option className="bx--select-input" value="America/Argentina/San_Luis">
          UTC/GMT -03:00 - America/Argentina/San_Luis			
        </option>
        <option className="bx--select-input" value="America/Argentina/Tucuman">
          UTC/GMT -03:00 - America/Argentina/Tucuman			
        </option>
        <option className="bx--select-input" value="America/Argentina/Ushuaia">
          UTC/GMT -03:00 - America/Argentina/Ushuaia			
        </option>
        <option className="bx--select-input" value="America/Aruba">
          UTC/GMT -04:00 - America/Aruba			
        </option>
        <option className="bx--select-input" value="America/Asuncion">
          UTC/GMT -03:00 - America/Asuncion			
        </option>
        <option className="bx--select-input" value="America/Atikokan">
          UTC/GMT -05:00 - America/Atikokan			
        </option>
        <option className="bx--select-input" value="America/Bahia">
          UTC/GMT -03:00 - America/Bahia			
        </option>
        <option className="bx--select-input" value="America/Bahia_Banderas">
          UTC/GMT -06:00 - America/Bahia_Banderas			
        </option>
        <option className="bx--select-input" value="America/Barbados">
          UTC/GMT -04:00 - America/Barbados			
        </option>
        <option className="bx--select-input" value="America/Belem">
          UTC/GMT -03:00 - America/Belem			
        </option>
        <option className="bx--select-input" value="America/Belize">
          UTC/GMT -06:00 - America/Belize			
        </option>
        <option className="bx--select-input" value="America/Blanc-Sablon">
          UTC/GMT -04:00 - America/Blanc-Sablon			
        </option>
        <option className="bx--select-input" value="America/Boa_Vista">
          UTC/GMT -04:00 - America/Boa_Vista			
        </option>
        <option className="bx--select-input" value="America/Bogota">
          UTC/GMT -05:00 - America/Bogota			
        </option>
        <option className="bx--select-input" value="America/Boise">
          UTC/GMT -07:00 - America/Boise			
        </option>
        <option className="bx--select-input" value="America/Cambridge_Bay">
          UTC/GMT -07:00 - America/Cambridge_Bay			
        </option>
        <option className="bx--select-input" value="America/Campo_Grande">
          UTC/GMT -03:00 - America/Campo_Grande			
        </option>
        <option className="bx--select-input" value="America/Cancun">
          UTC/GMT -05:00 - America/Cancun			
        </option>
        <option className="bx--select-input" value="America/Caracas">
          UTC/GMT -04:00 - America/Caracas			
        </option>
        <option className="bx--select-input" value="America/Cayenne">
          UTC/GMT -03:00 - America/Cayenne			
        </option>
        <option className="bx--select-input" value="America/Cayman">
          UTC/GMT -05:00 - America/Cayman			
        </option>
        <option className="bx--select-input" value="America/Chicago">
          UTC/GMT -06:00 - America/Chicago			
        </option>
        <option className="bx--select-input" value="America/Chihuahua">
          UTC/GMT -07:00 - America/Chihuahua			
        </option>
        <option className="bx--select-input" value="America/Costa_Rica">
          UTC/GMT -06:00 - America/Costa_Rica			
        </option>
        <option className="bx--select-input" value="America/Creston">
          UTC/GMT -07:00 - America/Creston			
        </option>
        <option className="bx--select-input" value="America/Cuiaba">
          UTC/GMT -03:00 - America/Cuiaba			
        </option>
        <option className="bx--select-input" value="America/Curacao">
          UTC/GMT -04:00 - America/Curacao			
        </option>
        <option className="bx--select-input" value="America/Danmarkshavn">
          UTC/GMT +00:00 - America/Danmarkshavn			
        </option>
        <option className="bx--select-input" value="America/Dawson">
          UTC/GMT -08:00 - America/Dawson			
        </option>
        <option className="bx--select-input" value="America/Dawson_Creek">
          UTC/GMT -07:00 - America/Dawson_Creek			
        </option>
        <option className="bx--select-input" value="America/Denver">
          UTC/GMT -07:00 - America/Denver			
        </option>
        <option className="bx--select-input" value="America/Detroit">
          UTC/GMT -05:00 - America/Detroit			
        </option>
        <option className="bx--select-input" value="America/Dominica">
          UTC/GMT -04:00 - America/Dominica			
        </option>
        <option className="bx--select-input" value="America/Edmonton">
          UTC/GMT -07:00 - America/Edmonton			
        </option>
        <option className="bx--select-input" value="America/Eirunepe">
          UTC/GMT -05:00 - America/Eirunepe			
        </option>
        <option className="bx--select-input" value="America/El_Salvador">
          UTC/GMT -06:00 - America/El_Salvador			
        </option>
        <option className="bx--select-input" value="America/Fort_Nelson">
          UTC/GMT -07:00 - America/Fort_Nelson			
        </option>
        <option className="bx--select-input" value="America/Fortaleza">
          UTC/GMT -03:00 - America/Fortaleza			
        </option>
        <option className="bx--select-input" value="America/Glace_Bay">
          UTC/GMT -04:00 - America/Glace_Bay			
        </option>
        <option className="bx--select-input" value="America/Godthab">
          UTC/GMT -03:00 - America/Godthab			
        </option>
        <option className="bx--select-input" value="America/Goose_Bay">
          UTC/GMT -04:00 - America/Goose_Bay			
        </option>
        <option className="bx--select-input" value="America/Grand_Turk">
          UTC/GMT -05:00 - America/Grand_Turk			
        </option>
        <option className="bx--select-input" value="America/Grenada">
          UTC/GMT -04:00 - America/Grenada			
        </option>
        <option className="bx--select-input" value="America/Guadeloupe">
          UTC/GMT -04:00 - America/Guadeloupe			
        </option>
        <option className="bx--select-input" value="America/Guatemala">
          UTC/GMT -06:00 - America/Guatemala			
        </option>
        <option className="bx--select-input" value="America/Guayaquil">
          UTC/GMT -05:00 - America/Guayaquil			
        </option>
        <option className="bx--select-input" value="America/Guyana">
          UTC/GMT -04:00 - America/Guyana			
        </option>
        <option className="bx--select-input" value="America/Halifax">
          UTC/GMT -04:00 - America/Halifax			
        </option>
        <option className="bx--select-input" value="America/Havana">
          UTC/GMT -05:00 - America/Havana			
        </option>
        <option className="bx--select-input" value="America/Hermosillo">
          UTC/GMT -07:00 - America/Hermosillo			
        </option>
        <option className="bx--select-input" value="America/Indiana/Indianapolis">
          UTC/GMT -05:00 - America/Indiana/Indianapolis			
        </option>
        <option className="bx--select-input" value="America/Indiana/Knox">
          UTC/GMT -06:00 - America/Indiana/Knox			
        </option>
        <option className="bx--select-input" value="America/Indiana/Marengo">
          UTC/GMT -05:00 - America/Indiana/Marengo			
        </option>
        <option className="bx--select-input" value="America/Indiana/Petersburg">
          UTC/GMT -05:00 - America/Indiana/Petersburg			
        </option>
        <option className="bx--select-input" value="America/Indiana/Tell_City">
          UTC/GMT -06:00 - America/Indiana/Tell_City			
        </option>
        <option className="bx--select-input" value="America/Indiana/Vevay">
          UTC/GMT -05:00 - America/Indiana/Vevay			
        </option>
        <option className="bx--select-input" value="America/Indiana/Vincennes">
          UTC/GMT -05:00 - America/Indiana/Vincennes			
        </option>
        <option className="bx--select-input" value="America/Indiana/Winamac">
          UTC/GMT -05:00 - America/Indiana/Winamac			
        </option>
        <option className="bx--select-input" value="America/Inuvik">
          UTC/GMT -07:00 - America/Inuvik			
        </option>
        <option className="bx--select-input" value="America/Iqaluit">
          UTC/GMT -05:00 - America/Iqaluit			
        </option>
        <option className="bx--select-input" value="America/Jamaica">
          UTC/GMT -05:00 - America/Jamaica			
        </option>
        <option className="bx--select-input" value="America/Juneau">
          UTC/GMT -09:00 - America/Juneau			
        </option>
        <option className="bx--select-input" value="America/Kentucky/Louisville">
          UTC/GMT -05:00 - America/Kentucky/Louisville			
        </option>
        <option className="bx--select-input" value="America/Kentucky/Monticello">
          UTC/GMT -05:00 - America/Kentucky/Monticello			
        </option>
        <option className="bx--select-input" value="America/Kralendijk">
          UTC/GMT -04:00 - America/Kralendijk			
        </option>
        <option className="bx--select-input" value="America/La_Paz">
          UTC/GMT -04:00 - America/La_Paz			
        </option>
        <option className="bx--select-input" value="America/Lima">
          UTC/GMT -05:00 - America/Lima			
        </option>
        <option className="bx--select-input" value="America/Los_Angeles">
          UTC/GMT -08:00 - America/Los_Angeles			
        </option>
        <option className="bx--select-input" value="America/Lower_Princes">
          UTC/GMT -04:00 - America/Lower_Princes			
        </option>
        <option className="bx--select-input" value="America/Maceio">
          UTC/GMT -03:00 - America/Maceio			
        </option>
        <option className="bx--select-input" value="America/Managua">
          UTC/GMT -06:00 - America/Managua			
        </option>
        <option className="bx--select-input" value="America/Manaus">
          UTC/GMT -04:00 - America/Manaus			
        </option>
        <option className="bx--select-input" value="America/Marigot">
          UTC/GMT -04:00 - America/Marigot			
        </option>
        <option className="bx--select-input" value="America/Martinique">
          UTC/GMT -04:00 - America/Martinique			
        </option>
        <option className="bx--select-input" value="America/Matamoros">
          UTC/GMT -06:00 - America/Matamoros			
        </option>
        <option className="bx--select-input" value="America/Mazatlan">
          UTC/GMT -07:00 - America/Mazatlan			
        </option>
        <option className="bx--select-input" value="America/Menominee">
          UTC/GMT -06:00 - America/Menominee			
        </option>
        <option className="bx--select-input" value="America/Merida">
          UTC/GMT -06:00 - America/Merida			
        </option>
        <option className="bx--select-input" value="America/Metlakatla">
          UTC/GMT -09:00 - America/Metlakatla			
        </option>
        <option className="bx--select-input" value="America/Mexico_City">
          UTC/GMT -06:00 - America/Mexico_City			
        </option>
        <option className="bx--select-input" value="America/Miquelon">
          UTC/GMT -03:00 - America/Miquelon			
        </option>
        <option className="bx--select-input" value="America/Moncton">
          UTC/GMT -04:00 - America/Moncton			
        </option>
        <option className="bx--select-input" value="America/Monterrey">
          UTC/GMT -06:00 - America/Monterrey			
        </option>
        <option className="bx--select-input" value="America/Montevideo">
          UTC/GMT -03:00 - America/Montevideo			
        </option>
        <option className="bx--select-input" value="America/Montserrat">
          UTC/GMT -04:00 - America/Montserrat			
        </option>
        <option className="bx--select-input" value="America/Nassau">
          UTC/GMT -05:00 - America/Nassau			
        </option>
        <option className="bx--select-input" value="America/New_York">
          UTC/GMT -05:00 - America/New_York			
        </option>
        <option className="bx--select-input" value="America/Nipigon">
          UTC/GMT -05:00 - America/Nipigon			
        </option>
        <option className="bx--select-input" value="America/Nome">
          UTC/GMT -09:00 - America/Nome			
        </option>
        <option className="bx--select-input" value="America/Noronha">
          UTC/GMT -02:00 - America/Noronha			
        </option>
        <option className="bx--select-input" value="America/North_Dakota/Beulah">
          UTC/GMT -06:00 - America/North_Dakota/Beulah			
        </option>
        <option className="bx--select-input" value="America/North_Dakota/Center">
          UTC/GMT -06:00 - America/North_Dakota/Center			
        </option>
        <option className="bx--select-input" value="America/North_Dakota/New_Salem">
          UTC/GMT -06:00 - America/North_Dakota/New_Salem			
        </option>
        <option className="bx--select-input" value="America/Ojinaga">
          UTC/GMT -07:00 - America/Ojinaga			
        </option>
        <option className="bx--select-input" value="America/Panama">
          UTC/GMT -05:00 - America/Panama			
        </option>
        <option className="bx--select-input" value="America/Pangnirtung">
          UTC/GMT -05:00 - America/Pangnirtung			
        </option>
        <option className="bx--select-input" value="America/Paramaribo">
          UTC/GMT -03:00 - America/Paramaribo			
        </option>
        <option className="bx--select-input" value="America/Phoenix">
          UTC/GMT -07:00 - America/Phoenix			
        </option>
        <option className="bx--select-input" value="America/Port-au-Prince">
          UTC/GMT -05:00 - America/Port-au-Prince			
        </option>
        <option className="bx--select-input" value="America/Port_of_Spain">
          UTC/GMT -04:00 - America/Port_of_Spain			
        </option>
        <option className="bx--select-input" value="America/Porto_Velho">
          UTC/GMT -04:00 - America/Porto_Velho			
        </option>
        <option className="bx--select-input" value="America/Puerto_Rico">
          UTC/GMT -04:00 - America/Puerto_Rico			
        </option>
        <option className="bx--select-input" value="America/Punta_Arenas">
          UTC/GMT -03:00 - America/Punta_Arenas			
        </option>
        <option className="bx--select-input" value="America/Rainy_River">
          UTC/GMT -06:00 - America/Rainy_River			
        </option>
        <option className="bx--select-input" value="America/Rankin_Inlet">
          UTC/GMT -06:00 - America/Rankin_Inlet			
        </option>
        <option className="bx--select-input" value="America/Recife">
          UTC/GMT -03:00 - America/Recife			
        </option>
        <option className="bx--select-input" value="America/Regina">
          UTC/GMT -06:00 - America/Regina			
        </option>
        <option className="bx--select-input" value="America/Resolute">
          UTC/GMT -06:00 - America/Resolute			
        </option>
        <option className="bx--select-input" value="America/Rio_Branco">
          UTC/GMT -05:00 - America/Rio_Branco			
        </option>
        <option className="bx--select-input" value="America/Santarem">
          UTC/GMT -03:00 - America/Santarem			
        </option>
        <option className="bx--select-input" value="America/Santiago">
          UTC/GMT -03:00 - America/Santiago			
        </option>
        <option className="bx--select-input" value="America/Santo_Domingo">
          UTC/GMT -04:00 - America/Santo_Domingo			
        </option>
        <option className="bx--select-input" value="America/Sao_Paulo">
          UTC/GMT -02:00 - America/Sao_Paulo			
        </option>
        <option className="bx--select-input" value="America/Scoresbysund">
          UTC/GMT -01:00 - America/Scoresbysund			
        </option>
        <option className="bx--select-input" value="America/Sitka">
          UTC/GMT -09:00 - America/Sitka			
        </option>
        <option className="bx--select-input" value="America/St_Barthelemy">
          UTC/GMT -04:00 - America/St_Barthelemy			
        </option>
        <option className="bx--select-input" value="America/St_Johns">
          UTC/GMT -03:30 - America/St_Johns			
        </option>
        <option className="bx--select-input" value="America/St_Kitts">
          UTC/GMT -04:00 - America/St_Kitts			
        </option>
        <option className="bx--select-input" value="America/St_Lucia">
          UTC/GMT -04:00 - America/St_Lucia			
        </option>
        <option className="bx--select-input" value="America/St_Thomas">
          UTC/GMT -04:00 - America/St_Thomas			
        </option>
        <option className="bx--select-input" value="America/St_Vincent">
          UTC/GMT -04:00 - America/St_Vincent			
        </option>
        <option className="bx--select-input" value="America/Swift_Current">
          UTC/GMT -06:00 - America/Swift_Current			
        </option>
        <option className="bx--select-input" value="America/Tegucigalpa">
          UTC/GMT -06:00 - America/Tegucigalpa			
        </option>
        <option className="bx--select-input" value="America/Thule">
          UTC/GMT -04:00 - America/Thule			
        </option>
        <option className="bx--select-input" value="America/Thunder_Bay">
          UTC/GMT -05:00 - America/Thunder_Bay			
        </option>
        <option className="bx--select-input" value="America/Tijuana">
          UTC/GMT -08:00 - America/Tijuana			
        </option>
        <option className="bx--select-input" value="America/Toronto">
          UTC/GMT -05:00 - America/Toronto			
        </option>
        <option className="bx--select-input" value="America/Tortola">
          UTC/GMT -04:00 - America/Tortola			
        </option>
        <option className="bx--select-input" value="America/Vancouver">
          UTC/GMT -08:00 - America/Vancouver			
        </option>
        <option className="bx--select-input" value="America/Whitehorse">
          UTC/GMT -08:00 - America/Whitehorse			
        </option>
        <option className="bx--select-input" value="America/Winnipeg">
          UTC/GMT -06:00 - America/Winnipeg			
        </option>
        <option className="bx--select-input" value="America/Yakutat">
          UTC/GMT -09:00 - America/Yakutat			
        </option>
        <option className="bx--select-input" value="America/Yellowknife">
          UTC/GMT -07:00 - America/Yellowknife			
        </option>
        <option className="bx--select-input" value="Antarctica/Casey">
          UTC/GMT +08:00 - Antarctica/Casey			
        </option>
        <option className="bx--select-input" value="Antarctica/Davis">
          UTC/GMT +07:00 - Antarctica/Davis			
        </option>
        <option className="bx--select-input" value="Antarctica/DumontDUrville">
          UTC/GMT +10:00 - Antarctica/DumontDUrville			
        </option>
        <option className="bx--select-input" value="Antarctica/Macquarie">
          UTC/GMT +11:00 - Antarctica/Macquarie			
        </option>
        <option className="bx--select-input" value="Antarctica/Mawson">
          UTC/GMT +05:00 - Antarctica/Mawson			
        </option>
        <option className="bx--select-input" value="Antarctica/McMurdo">
          UTC/GMT +13:00 - Antarctica/McMurdo			
        </option>
        <option className="bx--select-input" value="Antarctica/Palmer">
          UTC/GMT -03:00 - Antarctica/Palmer			
        </option>
        <option className="bx--select-input" value="Antarctica/Rothera">
          UTC/GMT -03:00 - Antarctica/Rothera			
        </option>
        <option className="bx--select-input" value="Antarctica/Syowa">
          UTC/GMT +03:00 - Antarctica/Syowa			
        </option>
        <option className="bx--select-input" value="Antarctica/Troll">
          UTC/GMT +00:00 - Antarctica/Troll			
        </option>
        <option className="bx--select-input" value="Antarctica/Vostok">
          UTC/GMT +06:00 - Antarctica/Vostok			
        </option>
        <option className="bx--select-input" value="Arctic/Longyearbyen">
          UTC/GMT +01:00 - Arctic/Longyearbyen			
        </option>
        <option className="bx--select-input" value="Asia/Aden">
          UTC/GMT +03:00 - Asia/Aden			
        </option>
        <option className="bx--select-input" value="Asia/Almaty">
          UTC/GMT +06:00 - Asia/Almaty			
        </option>
        <option className="bx--select-input" value="Asia/Amman">
          UTC/GMT +02:00 - Asia/Amman			
        </option>
        <option className="bx--select-input" value="Asia/Anadyr">
          UTC/GMT +12:00 - Asia/Anadyr			
        </option>
        <option className="bx--select-input" value="Asia/Aqtau">
          UTC/GMT +05:00 - Asia/Aqtau			
        </option>
        <option className="bx--select-input" value="Asia/Aqtobe">
          UTC/GMT +05:00 - Asia/Aqtobe			
        </option>
        <option className="bx--select-input" value="Asia/Ashgabat">
          UTC/GMT +05:00 - Asia/Ashgabat			
        </option>
        <option className="bx--select-input" value="Asia/Atyrau">
          UTC/GMT +05:00 - Asia/Atyrau			
        </option>
        <option className="bx--select-input" value="Asia/Baghdad">
          UTC/GMT +03:00 - Asia/Baghdad			
        </option>
        <option className="bx--select-input" value="Asia/Bahrain">
          UTC/GMT +03:00 - Asia/Bahrain			
        </option>
        <option className="bx--select-input" value="Asia/Baku">
          UTC/GMT +04:00 - Asia/Baku			
        </option>
        <option className="bx--select-input" value="Asia/Bangkok">
          UTC/GMT +07:00 - Asia/Bangkok			
        </option>
        <option className="bx--select-input" value="Asia/Barnaul">
          UTC/GMT +07:00 - Asia/Barnaul			
        </option>
        <option className="bx--select-input" value="Asia/Beirut">
          UTC/GMT +02:00 - Asia/Beirut			
        </option>
        <option className="bx--select-input" value="Asia/Bishkek">
          UTC/GMT +06:00 - Asia/Bishkek			
        </option>
        <option className="bx--select-input" value="Asia/Brunei">
          UTC/GMT +08:00 - Asia/Brunei			
        </option>
        <option className="bx--select-input" value="Asia/Chita">
          UTC/GMT +09:00 - Asia/Chita			
        </option>
        <option className="bx--select-input" value="Asia/Choibalsan">
          UTC/GMT +08:00 - Asia/Choibalsan			
        </option>
        <option className="bx--select-input" value="Asia/Colombo">
          UTC/GMT +05:30 - Asia/Colombo			
        </option>
        <option className="bx--select-input" value="Asia/Damascus">
          UTC/GMT +02:00 - Asia/Damascus			
        </option>
        <option className="bx--select-input" value="Asia/Dhaka">
          UTC/GMT +06:00 - Asia/Dhaka			
        </option>
        <option className="bx--select-input" value="Asia/Dili">
          UTC/GMT +09:00 - Asia/Dili			
        </option>
        <option className="bx--select-input" value="Asia/Dubai">
          UTC/GMT +04:00 - Asia/Dubai			
        </option>
        <option className="bx--select-input" value="Asia/Dushanbe">
          UTC/GMT +05:00 - Asia/Dushanbe			
        </option>
        <option className="bx--select-input" value="Asia/Famagusta">
          UTC/GMT +02:00 - Asia/Famagusta			
        </option>
        <option className="bx--select-input" value="Asia/Gaza">
          UTC/GMT +02:00 - Asia/Gaza			
        </option>
        <option className="bx--select-input" value="Asia/Hebron">
          UTC/GMT +02:00 - Asia/Hebron			
        </option>
        <option className="bx--select-input" value="Asia/Ho_Chi_Minh">
          UTC/GMT +07:00 - Asia/Ho_Chi_Minh			
        </option>
        <option className="bx--select-input" value="Asia/Hong_Kong">
          UTC/GMT +08:00 - Asia/Hong_Kong			
        </option>
        <option className="bx--select-input" value="Asia/Hovd">
          UTC/GMT +07:00 - Asia/Hovd			
        </option>
        <option className="bx--select-input" value="Asia/Irkutsk">
          UTC/GMT +08:00 - Asia/Irkutsk			
        </option>
        <option className="bx--select-input" value="Asia/Jakarta">
          UTC/GMT +07:00 - Asia/Jakarta			
        </option>
        <option className="bx--select-input" value="Asia/Jayapura">
          UTC/GMT +09:00 - Asia/Jayapura			
        </option>
        <option className="bx--select-input" value="Asia/Jerusalem">
          UTC/GMT +02:00 - Asia/Jerusalem			
        </option>
        <option className="bx--select-input" value="Asia/Kabul">
          UTC/GMT +04:30 - Asia/Kabul			
        </option>
        <option className="bx--select-input" value="Asia/Kamchatka">
          UTC/GMT +12:00 - Asia/Kamchatka			
        </option>
        <option className="bx--select-input" value="Asia/Karachi">
          UTC/GMT +05:00 - Asia/Karachi			
        </option>
        <option className="bx--select-input" value="Asia/Kathmandu">
          UTC/GMT +05:45 - Asia/Kathmandu			
        </option>
        <option className="bx--select-input" value="Asia/Khandyga">
          UTC/GMT +09:00 - Asia/Khandyga			
        </option>
        <option className="bx--select-input" value="Asia/Kolkata">
          UTC/GMT +05:30 - Asia/Kolkata			
        </option>
        <option className="bx--select-input" value="Asia/Krasnoyarsk">
          UTC/GMT +07:00 - Asia/Krasnoyarsk			
        </option>
        <option className="bx--select-input" value="Asia/Kuala_Lumpur">
          UTC/GMT +08:00 - Asia/Kuala_Lumpur			
        </option>
        <option className="bx--select-input" value="Asia/Kuching">
          UTC/GMT +08:00 - Asia/Kuching			
        </option>
        <option className="bx--select-input" value="Asia/Kuwait">
          UTC/GMT +03:00 - Asia/Kuwait			
        </option>
        <option className="bx--select-input" value="Asia/Macau">
          UTC/GMT +08:00 - Asia/Macau			
        </option>
        <option className="bx--select-input" value="Asia/Magadan">
          UTC/GMT +11:00 - Asia/Magadan			
        </option>
        <option className="bx--select-input" value="Asia/Makassar">
          UTC/GMT +08:00 - Asia/Makassar			
        </option>
        <option className="bx--select-input" value="Asia/Manila">
          UTC/GMT +08:00 - Asia/Manila			
        </option>
        <option className="bx--select-input" value="Asia/Muscat">
          UTC/GMT +04:00 - Asia/Muscat			
        </option>
        <option className="bx--select-input" value="Asia/Nicosia">
          UTC/GMT +02:00 - Asia/Nicosia			
        </option>
        <option className="bx--select-input" value="Asia/Novokuznetsk">
          UTC/GMT +07:00 - Asia/Novokuznetsk			
        </option>
        <option className="bx--select-input" value="Asia/Novosibirsk">
          UTC/GMT +07:00 - Asia/Novosibirsk			
        </option>
        <option className="bx--select-input" value="Asia/Omsk">
          UTC/GMT +06:00 - Asia/Omsk			
        </option>
        <option className="bx--select-input" value="Asia/Oral">
          UTC/GMT +05:00 - Asia/Oral			
        </option>
        <option className="bx--select-input" value="Asia/Phnom_Penh">
          UTC/GMT +07:00 - Asia/Phnom_Penh			
        </option>
        <option className="bx--select-input" value="Asia/Pontianak">
          UTC/GMT +07:00 - Asia/Pontianak			
        </option>
        <option className="bx--select-input" value="Asia/Pyongyang">
          UTC/GMT +09:00 - Asia/Pyongyang			
        </option>
        <option className="bx--select-input" value="Asia/Qatar">
          UTC/GMT +03:00 - Asia/Qatar			
        </option>
        <option className="bx--select-input" value="Asia/Qyzylorda">
          UTC/GMT +06:00 - Asia/Qyzylorda			
        </option>
        <option className="bx--select-input" value="Asia/Riyadh">
          UTC/GMT +03:00 - Asia/Riyadh			
        </option>
        <option className="bx--select-input" value="Asia/Sakhalin">
          UTC/GMT +11:00 - Asia/Sakhalin			
        </option>
        <option className="bx--select-input" value="Asia/Samarkand">
          UTC/GMT +05:00 - Asia/Samarkand			
        </option>
        <option className="bx--select-input" value="Asia/Seoul">
          UTC/GMT +09:00 - Asia/Seoul			
        </option>
        <option className="bx--select-input" value="Asia/Shanghai">
          UTC/GMT +08:00 - Asia/Shanghai			
        </option>
        <option className="bx--select-input" value="Asia/Singapore">
          UTC/GMT +08:00 - Asia/Singapore			
        </option>
        <option className="bx--select-input" value="Asia/Srednekolymsk">
          UTC/GMT +11:00 - Asia/Srednekolymsk			
        </option>
        <option className="bx--select-input" value="Asia/Taipei">
          UTC/GMT +08:00 - Asia/Taipei			
        </option>
        <option className="bx--select-input" value="Asia/Tashkent">
          UTC/GMT +05:00 - Asia/Tashkent			
        </option>
        <option className="bx--select-input" value="Asia/Tbilisi">
          UTC/GMT +04:00 - Asia/Tbilisi			
        </option>
        <option className="bx--select-input" value="Asia/Tehran">
          UTC/GMT +03:30 - Asia/Tehran			
        </option>
        <option className="bx--select-input" value="Asia/Thimphu">
          UTC/GMT +06:00 - Asia/Thimphu			
        </option>
        <option className="bx--select-input" value="Asia/Tokyo">
          UTC/GMT +09:00 - Asia/Tokyo			
        </option>
        <option className="bx--select-input" value="Asia/Tomsk">
          UTC/GMT +07:00 - Asia/Tomsk			
        </option>
        <option className="bx--select-input" value="Asia/Ulaanbaatar">
          UTC/GMT +08:00 - Asia/Ulaanbaatar			
        </option>
        <option className="bx--select-input" value="Asia/Urumqi">
          UTC/GMT +06:00 - Asia/Urumqi			
        </option>
        <option className="bx--select-input" value="Asia/Ust-Nera">
          UTC/GMT +10:00 - Asia/Ust-Nera			
        </option>
        <option className="bx--select-input" value="Asia/Vientiane">
          UTC/GMT +07:00 - Asia/Vientiane			
        </option>
        <option className="bx--select-input" value="Asia/Vladivostok">
          UTC/GMT +10:00 - Asia/Vladivostok			
        </option>
        <option className="bx--select-input" value="Asia/Yakutsk">
          UTC/GMT +09:00 - Asia/Yakutsk			
        </option>
        <option className="bx--select-input" value="Asia/Yangon">
          UTC/GMT +06:30 - Asia/Yangon			
        </option>
        <option className="bx--select-input" value="Asia/Yekaterinburg">
          UTC/GMT +05:00 - Asia/Yekaterinburg			
        </option>
        <option className="bx--select-input" value="Asia/Yerevan">
          UTC/GMT +04:00 - Asia/Yerevan			
        </option>
        <option className="bx--select-input" value="Atlantic/Azores">
          UTC/GMT -01:00 - Atlantic/Azores			
        </option>
        <option className="bx--select-input" value="Atlantic/Bermuda">
          UTC/GMT -04:00 - Atlantic/Bermuda			
        </option>
        <option className="bx--select-input" value="Atlantic/Canary">
          UTC/GMT +00:00 - Atlantic/Canary			
        </option>
        <option className="bx--select-input" value="Atlantic/Cape_Verde">
          UTC/GMT -01:00 - Atlantic/Cape_Verde			
        </option>
        <option className="bx--select-input" value="Atlantic/Faroe">
          UTC/GMT +00:00 - Atlantic/Faroe			
        </option>
        <option className="bx--select-input" value="Atlantic/Madeira">
          UTC/GMT +00:00 - Atlantic/Madeira			
        </option>
        <option className="bx--select-input" value="Atlantic/Reykjavik">
          UTC/GMT +00:00 - Atlantic/Reykjavik			
        </option>
        <option className="bx--select-input" value="Atlantic/South_Georgia">
          UTC/GMT -02:00 - Atlantic/South_Georgia			
        </option>
        <option className="bx--select-input" value="Atlantic/St_Helena">
          UTC/GMT +00:00 - Atlantic/St_Helena			
        </option>
        <option className="bx--select-input" value="Atlantic/Stanley">
          UTC/GMT -03:00 - Atlantic/Stanley			
        </option>
        <option className="bx--select-input" value="Australia/Adelaide">
          UTC/GMT +10:30 - Australia/Adelaide			
        </option>
        <option className="bx--select-input" value="Australia/Brisbane">
          UTC/GMT +10:00 - Australia/Brisbane			
        </option>
        <option className="bx--select-input" value="Australia/Broken_Hill">
          UTC/GMT +10:30 - Australia/Broken_Hill			
        </option>
        <option className="bx--select-input" value="Australia/Currie">
          UTC/GMT +11:00 - Australia/Currie			
        </option>
        <option className="bx--select-input" value="Australia/Darwin">
          UTC/GMT +09:30 - Australia/Darwin			
        </option>
        <option className="bx--select-input" value="Australia/Eucla">
          UTC/GMT +08:45 - Australia/Eucla			
        </option>
        <option className="bx--select-input" value="Australia/Hobart">
          UTC/GMT +11:00 - Australia/Hobart			
        </option>
        <option className="bx--select-input" value="Australia/Lindeman">
          UTC/GMT +10:00 - Australia/Lindeman			
        </option>
        <option className="bx--select-input" value="Australia/Lord_Howe">
          UTC/GMT +11:00 - Australia/Lord_Howe			
        </option>
        <option className="bx--select-input" value="Australia/Melbourne">
          UTC/GMT +11:00 - Australia/Melbourne			
        </option>
        <option className="bx--select-input" value="Australia/Perth">
          UTC/GMT +08:00 - Australia/Perth			
        </option>
        <option className="bx--select-input" value="Australia/Sydney">
          UTC/GMT +11:00 - Australia/Sydney			
        </option>
        <option className="bx--select-input" value="Europe/Amsterdam">
          UTC/GMT +01:00 - Europe/Amsterdam			
        </option>
        <option className="bx--select-input" value="Europe/Andorra">
          UTC/GMT +01:00 - Europe/Andorra			
        </option>
        <option className="bx--select-input" value="Europe/Astrakhan">
          UTC/GMT +04:00 - Europe/Astrakhan			
        </option>
        <option className="bx--select-input" value="Europe/Athens">
          UTC/GMT +02:00 - Europe/Athens			
        </option>
        <option className="bx--select-input" value="Europe/Belgrade">
          UTC/GMT +01:00 - Europe/Belgrade			
        </option>
        <option className="bx--select-input" value="Europe/Berlin">
          UTC/GMT +01:00 - Europe/Berlin			
        </option>
        <option className="bx--select-input" value="Europe/Bratislava">
          UTC/GMT +01:00 - Europe/Bratislava			
        </option>
        <option className="bx--select-input" value="Europe/Brussels">
          UTC/GMT +01:00 - Europe/Brussels			
        </option>
        <option className="bx--select-input" value="Europe/Bucharest">
          UTC/GMT +02:00 - Europe/Bucharest			
        </option>
        <option className="bx--select-input" value="Europe/Budapest">
          UTC/GMT +01:00 - Europe/Budapest			
        </option>
        <option className="bx--select-input" value="Europe/Busingen">
          UTC/GMT +01:00 - Europe/Busingen			
        </option>
        <option className="bx--select-input" value="Europe/Chisinau">
          UTC/GMT +02:00 - Europe/Chisinau			
        </option>
        <option className="bx--select-input" value="Europe/Copenhagen">
          UTC/GMT +01:00 - Europe/Copenhagen			
        </option>
        <option className="bx--select-input" value="Europe/Dublin">
          UTC/GMT +00:00 - Europe/Dublin			
        </option>
        <option className="bx--select-input" value="Europe/Gibraltar">
          UTC/GMT +01:00 - Europe/Gibraltar			
        </option>
        <option className="bx--select-input" value="Europe/Guernsey">
          UTC/GMT +00:00 - Europe/Guernsey			
        </option>
        <option className="bx--select-input" value="Europe/Helsinki">
          UTC/GMT +02:00 - Europe/Helsinki			
        </option>
        <option className="bx--select-input" value="Europe/Isle_of_Man">
          UTC/GMT +00:00 - Europe/Isle_of_Man			
        </option>
        <option className="bx--select-input" value="Europe/Istanbul">
          UTC/GMT +03:00 - Europe/Istanbul			
        </option>
        <option className="bx--select-input" value="Europe/Jersey">
          UTC/GMT +00:00 - Europe/Jersey			
        </option>
        <option className="bx--select-input" value="Europe/Kaliningrad">
          UTC/GMT +02:00 - Europe/Kaliningrad			
        </option>
        <option className="bx--select-input" value="Europe/Kiev">
          UTC/GMT +02:00 - Europe/Kiev			
        </option>
        <option className="bx--select-input" value="Europe/Kirov">
          UTC/GMT +03:00 - Europe/Kirov			
        </option>
        <option className="bx--select-input" value="Europe/Lisbon">
          UTC/GMT +00:00 - Europe/Lisbon			
        </option>
        <option className="bx--select-input" value="Europe/Ljubljana">
          UTC/GMT +01:00 - Europe/Ljubljana			
        </option>
        <option className="bx--select-input" value="Europe/London">
          UTC/GMT +00:00 - Europe/London			
        </option>
        <option className="bx--select-input" value="Europe/Luxembourg">
          UTC/GMT +01:00 - Europe/Luxembourg			
        </option>
        <option className="bx--select-input" value="Europe/Madrid">
          UTC/GMT +01:00 - Europe/Madrid			
        </option>
        <option className="bx--select-input" value="Europe/Malta">
          UTC/GMT +01:00 - Europe/Malta			
        </option>
        <option className="bx--select-input" value="Europe/Mariehamn">
          UTC/GMT +02:00 - Europe/Mariehamn			
        </option>
        <option className="bx--select-input" value="Europe/Minsk">
          UTC/GMT +03:00 - Europe/Minsk			
        </option>
        <option className="bx--select-input" value="Europe/Monaco">
          UTC/GMT +01:00 - Europe/Monaco			
        </option>
        <option className="bx--select-input" value="Europe/Moscow">
          UTC/GMT +03:00 - Europe/Moscow			
        </option>
        <option className="bx--select-input" value="Europe/Oslo">
          UTC/GMT +01:00 - Europe/Oslo			
        </option>
        <option className="bx--select-input" value="Europe/Paris">
          UTC/GMT +01:00 - Europe/Paris			
        </option>
        <option className="bx--select-input" value="Europe/Podgorica">
          UTC/GMT +01:00 - Europe/Podgorica			
        </option>
        <option className="bx--select-input" value="Europe/Prague">
          UTC/GMT +01:00 - Europe/Prague			
        </option>
        <option className="bx--select-input" value="Europe/Riga">
          UTC/GMT +02:00 - Europe/Riga			
        </option>
        <option className="bx--select-input" value="Europe/Rome">
          UTC/GMT +01:00 - Europe/Rome			
        </option>
        <option className="bx--select-input" value="Europe/Samara">
          UTC/GMT +04:00 - Europe/Samara			
        </option>
        <option className="bx--select-input" value="Europe/San_Marino">
          UTC/GMT +01:00 - Europe/San_Marino			
        </option>
        <option className="bx--select-input" value="Europe/Sarajevo">
          UTC/GMT +01:00 - Europe/Sarajevo			
        </option>
        <option className="bx--select-input" value="Europe/Saratov">
          UTC/GMT +04:00 - Europe/Saratov			
        </option>
        <option className="bx--select-input" value="Europe/Simferopol">
          UTC/GMT +03:00 - Europe/Simferopol			
        </option>
        <option className="bx--select-input" value="Europe/Skopje">
          UTC/GMT +01:00 - Europe/Skopje			
        </option>
        <option className="bx--select-input" value="Europe/Sofia">
          UTC/GMT +02:00 - Europe/Sofia			
        </option>
        <option className="bx--select-input" value="Europe/Stockholm">
          UTC/GMT +01:00 - Europe/Stockholm			
        </option>
        <option className="bx--select-input" value="Europe/Tallinn">
          UTC/GMT +02:00 - Europe/Tallinn			
        </option>
        <option className="bx--select-input" value="Europe/Tirane">
          UTC/GMT +01:00 - Europe/Tirane			
        </option>
        <option className="bx--select-input" value="Europe/Ulyanovsk">
          UTC/GMT +04:00 - Europe/Ulyanovsk			
        </option>
        <option className="bx--select-input" value="Europe/Uzhgorod">
          UTC/GMT +02:00 - Europe/Uzhgorod			
        </option>
        <option className="bx--select-input" value="Europe/Vaduz">
          UTC/GMT +01:00 - Europe/Vaduz			
        </option>
        <option className="bx--select-input" value="Europe/Vatican">
          UTC/GMT +01:00 - Europe/Vatican			
        </option>
        <option className="bx--select-input" value="Europe/Vienna">
          UTC/GMT +01:00 - Europe/Vienna			
        </option>
        <option className="bx--select-input" value="Europe/Vilnius">
          UTC/GMT +02:00 - Europe/Vilnius			
        </option>
        <option className="bx--select-input" value="Europe/Volgograd">
          UTC/GMT +04:00 - Europe/Volgograd			
        </option>
        <option className="bx--select-input" value="Europe/Warsaw">
          UTC/GMT +01:00 - Europe/Warsaw			
        </option>
        <option className="bx--select-input" value="Europe/Zagreb">
          UTC/GMT +01:00 - Europe/Zagreb			
        </option>
        <option className="bx--select-input" value="Europe/Zaporozhye">
          UTC/GMT +02:00 - Europe/Zaporozhye			
        </option>
        <option className="bx--select-input" value="Europe/Zurich">
          UTC/GMT +01:00 - Europe/Zurich			
        </option>
        <option className="bx--select-input" value="Indian/Antananarivo">
          UTC/GMT +03:00 - Indian/Antananarivo			
        </option>
        <option className="bx--select-input" value="Indian/Chagos">
          UTC/GMT +06:00 - Indian/Chagos			
        </option>
        <option className="bx--select-input" value="Indian/Christmas">
          UTC/GMT +07:00 - Indian/Christmas			
        </option>
        <option className="bx--select-input" value="Indian/Cocos">
          UTC/GMT +06:30 - Indian/Cocos			
        </option>
        <option className="bx--select-input" value="Indian/Comoro">
          UTC/GMT +03:00 - Indian/Comoro			
        </option>
        <option className="bx--select-input" value="Indian/Kerguelen">
          UTC/GMT +05:00 - Indian/Kerguelen			
        </option>
        <option className="bx--select-input" value="Indian/Mahe">
          UTC/GMT +04:00 - Indian/Mahe			
        </option>
        <option className="bx--select-input" value="Indian/Maldives">
          UTC/GMT +05:00 - Indian/Maldives			
        </option>
        <option className="bx--select-input" value="Indian/Mauritius">
          UTC/GMT +04:00 - Indian/Mauritius			
        </option>
        <option className="bx--select-input" value="Indian/Mayotte">
          UTC/GMT +03:00 - Indian/Mayotte			
        </option>
        <option className="bx--select-input" value="Indian/Reunion">
          UTC/GMT +04:00 - Indian/Reunion			
        </option>
        <option className="bx--select-input" value="Pacific/Apia">
          UTC/GMT +14:00 - Pacific/Apia			
        </option>
        <option className="bx--select-input" value="Pacific/Auckland">
          UTC/GMT +13:00 - Pacific/Auckland			
        </option>
        <option className="bx--select-input" value="Pacific/Bougainville">
          UTC/GMT +11:00 - Pacific/Bougainville			
        </option>
        <option className="bx--select-input" value="Pacific/Chatham">
          UTC/GMT +13:45 - Pacific/Chatham			
        </option>
        <option className="bx--select-input" value="Pacific/Chuuk">
          UTC/GMT +10:00 - Pacific/Chuuk			
        </option>
        <option className="bx--select-input" value="Pacific/Easter">
          UTC/GMT -05:00 - Pacific/Easter			
        </option>
        <option className="bx--select-input" value="Pacific/Efate">
          UTC/GMT +11:00 - Pacific/Efate			
        </option>
        <option className="bx--select-input" value="Pacific/Enderbury">
          UTC/GMT +13:00 - Pacific/Enderbury			
        </option>
        <option className="bx--select-input" value="Pacific/Fakaofo">
          UTC/GMT +13:00 - Pacific/Fakaofo			
        </option>
        <option className="bx--select-input" value="Pacific/Fiji">
          UTC/GMT +13:00 - Pacific/Fiji			
        </option>
        <option className="bx--select-input" value="Pacific/Funafuti">
          UTC/GMT +12:00 - Pacific/Funafuti			
        </option>
        <option className="bx--select-input" value="Pacific/Galapagos">
          UTC/GMT -06:00 - Pacific/Galapagos			
        </option>
        <option className="bx--select-input" value="Pacific/Gambier">
          UTC/GMT -09:00 - Pacific/Gambier			
        </option>
        <option className="bx--select-input" value="Pacific/Guadalcanal">
          UTC/GMT +11:00 - Pacific/Guadalcanal			
        </option>
        <option className="bx--select-input" value="Pacific/Guam">
          UTC/GMT +10:00 - Pacific/Guam			
        </option>
        <option className="bx--select-input" value="Pacific/Honolulu">
          UTC/GMT -10:00 - Pacific/Honolulu			
        </option>
        <option className="bx--select-input" value="Pacific/Kiritimati">
          UTC/GMT +14:00 - Pacific/Kiritimati			
        </option>
        <option className="bx--select-input" value="Pacific/Kosrae">
          UTC/GMT +11:00 - Pacific/Kosrae			
        </option>
        <option className="bx--select-input" value="Pacific/Kwajalein">
          UTC/GMT +12:00 - Pacific/Kwajalein			
        </option>
        <option className="bx--select-input" value="Pacific/Majuro">
          UTC/GMT +12:00 - Pacific/Majuro			
        </option>
        <option className="bx--select-input" value="Pacific/Marquesas">
          UTC/GMT -09:30 - Pacific/Marquesas			
        </option>
        <option className="bx--select-input" value="Pacific/Midway">
          UTC/GMT -11:00 - Pacific/Midway			
        </option>
        <option className="bx--select-input" value="Pacific/Nauru">
          UTC/GMT +12:00 - Pacific/Nauru			
        </option>
        <option className="bx--select-input" value="Pacific/Niue">
          UTC/GMT -11:00 - Pacific/Niue			
        </option>
        <option className="bx--select-input" value="Pacific/Norfolk">
          UTC/GMT +11:00 - Pacific/Norfolk			
        </option>
        <option className="bx--select-input" value="Pacific/Noumea">
          UTC/GMT +11:00 - Pacific/Noumea			
        </option>
        <option className="bx--select-input" value="Pacific/Pago_Pago">
          UTC/GMT -11:00 - Pacific/Pago_Pago			
        </option>
        <option className="bx--select-input" value="Pacific/Palau">
          UTC/GMT +09:00 - Pacific/Palau			
        </option>
        <option className="bx--select-input" value="Pacific/Pitcairn">
          UTC/GMT -08:00 - Pacific/Pitcairn			
        </option>
        <option className="bx--select-input" value="Pacific/Pohnpei">
          UTC/GMT +11:00 - Pacific/Pohnpei			
        </option>
        <option className="bx--select-input" value="Pacific/Port_Moresby">
          UTC/GMT +10:00 - Pacific/Port_Moresby			
        </option>
        <option className="bx--select-input" value="Pacific/Rarotonga">
          UTC/GMT -10:00 - Pacific/Rarotonga			
        </option>
        <option className="bx--select-input" value="Pacific/Saipan">
          UTC/GMT +10:00 - Pacific/Saipan			
        </option>
        <option className="bx--select-input" value="Pacific/Tahiti">
          UTC/GMT -10:00 - Pacific/Tahiti			
        </option>
        <option className="bx--select-input" value="Pacific/Tarawa">
          UTC/GMT +12:00 - Pacific/Tarawa			
        </option>
        <option className="bx--select-input" value="Pacific/Tongatapu">
          UTC/GMT +13:00 - Pacific/Tongatapu			
        </option>
        <option className="bx--select-input" value="Pacific/Wake">
          UTC/GMT +12:00 - Pacific/Wake			
        </option>
        <option className="bx--select-input" value="Pacific/Wallis">
          UTC/GMT +12:00 - Pacific/Wallis			
        </option>
        <option className="bx--select-input" value="UTC">
          UTC/GMT +00:00 - UTC			
        </option>
      </select>
      <svg className="bx--select__arrow" width="10" height="5" viewBox="0 0 10 5">
        <path d="M0 0l5 4.998L10 0z" fillRule="evenodd" />
      </svg>
    </div>
  </div>
);

export default withTranslation()(TimezonesList);
