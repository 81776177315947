import React, { useState, useCallback, useEffect } from 'react';
import {
  FileUploaderItem,
  FileUploaderDropContainer,
  FormItem,
} from 'carbon-components-react';
import { View16 } from '@carbon/icons-react';

let lastId = 0;

function uid(prefix = 'id') {
  lastId++;
  return `${prefix}${lastId}`;
}

function JobDocuments(props) {
  const [files, setFiles] = useState(props.jobDocuments);

  const onAddFiles = useCallback(
    (evt, { addedFiles }) => {
      evt.stopPropagation();
      const data = new FormData();
      const newFiles = [];
      const invalidFiles = [];
      const existingFiles = files.map(e => e.fileName);
      addedFiles.forEach(file => {
        if(!existingFiles.includes(file.name)) {
          if(file.size < 512*10000) {
            data.append('file', file);
            newFiles.push({
              jobDocId: file.name,
              fileName: file.name,
              size: file.size,
              status: 'uploading',
              iconDescription: 'Uploading',
            });
          } else {
            invalidFiles.push({
              jobDocId: file.name,
              fileName: file.name,
              size: file.size,
              status: 'edit',
              iconDescription: 'Delete',
              invalid: true,
              errorSubject: 'File size exceeds limit',
              errorBody: '5MB max file size. Select a new file and try again.',
            });
          }
        }
      });
      if(props.multiple) {
        const allFiles = [...files, ...newFiles, ...invalidFiles];
        setFiles(allFiles);
      } else {
        const allFiles = [...newFiles, ...invalidFiles];
        setFiles(allFiles);
      }
      if(newFiles.length > 0) {
        props.uploadDocument(data);
      }
    },
    [files, props.multiple]
  );

  const handleFileUploaderItemClick = useCallback(
    (evt, p) => {
      const { uuid: clickedUuid } = p || {};
      const nfiles = files.filter(({ jobDocId, fileName }) => {
        if(!jobDocId) {
          return clickedUuid != fileName;
        } 
        return clickedUuid != jobDocId
      });
      props.updateDocuments(nfiles);
    },
    [files]
  );

  useEffect(() => {
    setFiles(props.jobDocuments);
    // return () => {
    // };
  }, [props.jobDocuments]);
  let s3BucketDomain = 'https://x0pa-rps-storage.s3-ap-southeast-1.amazonaws.com';
  if(window.location.host.includes('internship-placement.rp.edu.sg')) {
    s3BucketDomain = 'https://x0pa-rp-storage.s3-ap-southeast-1.amazonaws.com';
  }

  return (
    <FormItem>
      <p className="bx--label-description">Only .pdf, .doc and .docx files</p>
      {props.jobDocuments && props.jobDocuments.length < (props.maxFiles || 1) && (
        <FileUploaderDropContainer {...props} onAddFiles={onAddFiles} />
      )}
      <div className="uploaded-files d-flex" style={{ width: '100%' }}>
        { files && Array.isArray(files) && files.length > 0 && files.map(
          ({
            jobDocId,
            fileName,
            location,
            size,
            status,
            iconDescription,
            invalid,
            ...rest
          }) => (
            <div className="d-flex mr-2" key={jobDocId}>
              <FileUploaderItem
                key={uid()}
                uuid={jobDocId || fileName}
                name={fileName}
                size={size}
                status={status || 'edit'}
                iconDescription={iconDescription}
                invalid={invalid}
                onDelete={handleFileUploaderItemClick}
                {...rest}
              />
              {location && (
                <a
                  style={{ backgroundColor: '#f4f4f4' }}
                  className="d-flex mb-2 pr-2 align-items-center"
                  target="_blank"
                  href={`${s3BucketDomain}/${location}`}>
                  <View16 />
                </a>
              )}
            </div>
          )
        )}
      </div>
    </FormItem>
  );
}

export default JobDocuments;
