import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import { deleteApiCache } from 'utils/helpers';
import {
  REQUEST_WORKFLOW_DOC_TYPES,
  REQUEST_CREATE_DOC_TYPES,
  REQUEST_UPDATE_DOC_TYPES,
  REQUEST_DELETE_DOC_TYPES,
} from './actionTypes';

const getWorkflowDocumentTypes = () => (dispatch) => {
  const url = `${baseUrl}/workflowdocumenttypes?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_WORKFLOW_DOC_TYPES,
  };
  
  return callApi(params);
};

const createWorkflowDocuments = data => (dispatch) => {
  const url = `${baseUrl}/workflowdocuments?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_DOC_TYPES,
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const updateWorkflowDocumentsById = data => (dispatch) => {
  const {
    workflowDocumentId,
    appStageUserMapId,
    uploadLocation,
    workflowDocumentType,
  } = data || {};
  const finalData = {
    appStageUserMapId,
    uploadLocation,
    workflowDocumentType,
  };
  const url = `${baseUrl}/workflowdocuments/${workflowDocumentId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(finalData),
    actionType: REQUEST_UPDATE_DOC_TYPES,
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const deleteWorkflowDocumentByID = workflowDocumentId => (dispatch) => {
  const url = `${baseUrl}/workflowdocuments/${workflowDocumentId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_DOC_TYPES,
    dispatch,
    url,
    method: 'DELETE',
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

export {
  getWorkflowDocumentTypes,
  createWorkflowDocuments,
  updateWorkflowDocumentsById,
  deleteWorkflowDocumentByID,
};
