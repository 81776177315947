import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import {
  getCandidateProfile, editCandidateProfile, getCountries, editProfileContact,
  findOtherJobsApplied,
 } from 'actions/talentSearch/candidatesList';
import { connect } from 'react-redux';
import moment from 'moment';
import linkedinImg from 'assets/images/linkedin21px.png';
import FillCircle from 'components/common/FillCircle/FillCircle';
import {
  Button, Modal, TextInput,
  Select, SelectItem, Loading, Tile,
  Tabs, Tab,
} from 'carbon-components-react';
import isEmail from 'validator/lib/isEmail';
import CandidateDetails from 'components/roboroy/CandidateDetails';
import findAllUserJobApplicants from 'actions/talentSearch/applicantInfo';
import { getProfileEmails } from 'actions/profile';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import TalentPool from './TalentPool/TalentPool';
import { EditIcon } from 'components/common/Icons';
import {
  updateSkills,
  updateEduction,
  updateExperience,
  addEduction,
  addExperience,
} from 'components/roboroy/common/candidateProfile';
import { FitForFinlandHelp } from 'components/common/atoms';
import Avatar from 'components/common/Avatar';
import ApplicantOtherJobs from 'components/roboroy/ApplicantOtherJobs';
import phone from 'phone';
import './timeline.css';
import youtube from 'assets/images/youtube.png';

class CandidateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenEditProfileModal: false,
      firstName: '',
      lastName: '',
      email: '',
      location: '',
      address: '',
      address2: '',
      zipcode: '',
      stateFull: '',
      isInvalidFirstName: false,
      isInvalidFirstNameText: '',
      isInvalidLastName: false,
      isInvalidLastNameText: '',
      isInvalidEmail: false,
      isInvalidEmailText: '',
      countryId: 0,
      isValidCountryId: false,
      isValidCountryIdValue: '',
      isValidStateFull: false,
      isValidStateFullText: '',
      isValidLocationName: false,
      isValidLocationNameValue: '',
      personMobileNumber: '',
      linkedin: '',
      youtubeLink: '',
      youtubeId: '',
      isYoutubeIdValid: false,
      isYoutubeIdValidText: '',
      profileEmails: [],
      itemsPerPage: 10,
      activePage: 1,
      showLoader: false,
    };
  }

  componentDidMount() {
    const { application, user = {}, profileId } = this.props;
    const { itemsPerPage, activePage } = this.state;
    const { roleId = 0 } = user || {};
    let currProfileId = 0;
    if (roleId && (roleId === 4 || roleId === 1)) {
      currProfileId = application ? application.profileId : profileId;
    } else {
      currProfileId = profileId;
    }
    if (currProfileId) {
      this.setState({
        showLoader: true,
      }, () => {
        this.props.getCandidateProfile(currProfileId).then((res) => {
          this.setState({
            currProfileId,
            showLoader: false,
            countryId: res && res.profile && res.profile.address && res.profile.address.location && res.profile.address.location.countryId,
            personMobileNumber: res && res.profile && res.profile.contacts && res.profile.contacts[0] && res.profile.contacts[0].mobile,
          });
        });
        // this.props.findOtherJobsApplied(0, currProfileId, itemsPerPage, activePage);
        this.props.getProfileEmails(currProfileId)
          .then((res) => {
            this.setState({
              profileEmails: res,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      });
    }
    this.props.getCountries();
  }

  activeEditProfileModal = () => {
    const { countries } = this.props;
    const candidateProfile = this.props && this.props.candidateProfile && this.props.candidateProfile.profile;
    const candidateCountryId = (candidateProfile && candidateProfile.address && candidateProfile.address.location && candidateProfile.address.location.countryId) || '';
    let selectedCountry;
    if (candidateCountryId) {
      selectedCountry = countries && Array.isArray(countries)
        && countries.find(res => (res.country == candidateCountryId))
    }
    const {
      contacts,
    } = candidateProfile || {};
    const {
      country: {
        countryId,
      } = {},
    } = (contacts && contacts[0]) || [];
    this.setState({
      firstName: (candidateProfile && candidateProfile.firstName) ? candidateProfile.firstName : '',
      lastName: (candidateProfile && candidateProfile.lastName) ? candidateProfile.lastName : '',
      email: (candidateProfile && candidateProfile.email) ? candidateProfile.email : '',
      // stateFull: (candidateProfile && candidateProfile.address && candidateProfile.address.location
      //   && candidateProfile.address.location.state && candidateProfile.address.location.state.stateFull
      //   ? `${candidateProfile.address.location.state.stateFull}` : ''),
      // address: (candidateProfile && candidateProfile.address && candidateProfile.address.addressLine1) ? candidateProfile.address.addressLine1 : '',
      // address2: (candidateProfile && candidateProfile.address && candidateProfile.address.addressLine2) ? candidateProfile.address.addressLine2 : '',
      // location: (candidateProfile && candidateProfile.address && candidateProfile.address.location && candidateProfile.address.location.locationName) ? candidateProfile.address.location.locationName : '',
      // zipcode: (candidateProfile && candidateProfile.address && candidateProfile.address.zipcode) ? candidateProfile.address.zipcode : '',
      isOpenEditProfileModal: true,
      countryId,
      personMobileNumber: (candidateProfile && candidateProfile.contacts && candidateProfile.contacts[0] && candidateProfile.contacts[0].mobile) || '',
      linkedin: (candidateProfile && candidateProfile.linkedin) ? candidateProfile.linkedin : '',
      // youtubeLink: (candidateProfile && candidateProfile.youtubeLink) ? candidateProfile.youtubeLink : '',
    });
  }

  dismissEditProfileModal = () => {
    const {
      isInvalidFirstName, isInvalidLastName, isInvalidEmail, isValidCountryId, isValidStateFull, isValidLocationName,
      isValidPhoneNumber,
    } = this.state;
    if (isInvalidFirstName || isInvalidLastName || isInvalidEmail || isValidCountryId || isValidStateFull || isValidLocationName || isValidPhoneNumber) {
      this.setState({
        isInvalidFirstName: false,
        isInvalidFirstNameText: '',
        isInvalidLastName: false,
        isInvalidLastNameText: '',
        isInvalidEmail: false,
        isInvalidEmailText: '',
        isValidCountryId: false,
        isValidStateFull: false,
        isValidStateFullText: '',
        isValidLocationName: false,
        isValidLocationNameValue: '',
        isValidPhoneNumber: false,
        isValidPhoneNumberValue: '',
      });
    }
    this.setState({
      isOpenEditProfileModal: false,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleEditProfileModal = () => {
    const { application, countries, t } = this.props;
    let candidateProfile = this.props && this.props.candidateProfile && this.props.candidateProfile.profile;
    const mobileObj = candidateProfile && candidateProfile.contacts && candidateProfile.contacts[0] || {};
    const { personMobId } = mobileObj || {};
    const {
      firstName, lastName, email, location, address, zipcode, address2, stateFull, countryId,
      personMobileNumber, linkedin, youtubeLink,
    } = this.state;
    const selectedCountryObj = countries.find(c => (c.countryId == countryId));
    let finalPersonNumber = [];
    const {
      countryShort,
      countryCode,
      countryFull,
    } = selectedCountryObj || {};
    let countryValue = '';
    const personal = {
      firstName,
      lastName,
      email,
      countryShort,
      linkedin,
      countryFull,
      // zipcode,
      // youtubeLink,
      // address: {
      //   addressLine1: address,
      //   addressLine2: address2,
      //   location: {
      //     locationName: location,
      //     country: countryFull,
      //     state: stateFull,
      //   },
      // },
    };
    const getVideoId = require('get-video-id');
    const res = getVideoId(youtubeLink || '');
    let isValidCountryId = null;
    let isValidCountryIdValue = '';
    let isInvalidFirstName = null;
    let isInvalidFirstNameText = '';
    let isInvalidLastName = null;
    let isInvalidLastNameText = '';
    let isValidPhoneNumber = null;
    let isValidPhoneNumberValue = '';
    let isInvalidEmail = null;
    let isInvalidEmailText = '';
    if (!firstName || firstName === null || firstName === undefined) {
      isInvalidFirstName = true;
      isInvalidFirstNameText = `${t('nameValidation')}`;
    } else {
      isInvalidFirstName = false;
      isInvalidFirstNameText = '';
    }
    if (!lastName || lastName === null || lastName === undefined) {
      isInvalidLastName = true;
      isInvalidLastNameText = `${t('nameValidation')}`;
    } else {
      isInvalidLastName = false;
      isInvalidLastNameText = '';
    }
    if (!countryCode) {
      isValidCountryId = true;
      isValidCountryIdValue = `${t('countryValidation')}`;
    } else {
      countryValue = countryCode.slice(1, 3);
      isValidCountryId = false;
      isValidCountryIdValue = '';
    }
    if (!personMobileNumber) {
      isValidPhoneNumber = true;
      isValidPhoneNumberValue = `${t('phoneNoValidation')}`;
    } else {
      isValidPhoneNumber = false;
      isValidPhoneNumberValue = '';
    }
    if (personMobileNumber) {
      const phoneValue = phone(personMobileNumber, countryShort);
      if (!phoneValue || phoneValue.length <= 0) {
        isValidPhoneNumber = true;
        isValidPhoneNumberValue = `${t('phoneNoValidation')}`;
      } else if (phoneValue && Array.isArray(phoneValue) && phoneValue[0].includes(countryCode)) {
        finalPersonNumber = phoneValue[0].split(countryCode);
        isValidPhoneNumber = false;
        isValidPhoneNumberValue = '';
      } else if (phoneValue && Array.isArray(phoneValue) && phoneValue[0].includes(countryValue)) {
        isValidPhoneNumber = false;
        isValidPhoneNumberValue = '';
        finalPersonNumber = phoneValue[0].split(countryValue);
      }
    }
    const mobileData = {
      mobile: finalPersonNumber && finalPersonNumber[1],
      profileId: candidateProfile && candidateProfile.profileId,
    };
    if (personMobId) {
      mobileData.personMobId = personMobId;
    }
    if (countryCode) {
      mobileData.codeId = countryId;
      mobileData.countryFull = countryFull;
    }
    this.setState({
      personMobileNumber: mobileData.mobile,
    });
    if (!email || email === null || email === undefined || !isEmail(email)) {
      isInvalidEmail = true;
      isInvalidEmailText = `${t('emailValidation')}`;
    } else {
      isInvalidEmail = false;
      isInvalidEmailText = '';
    }
    // else if ((address || address2) && (!stateFull || stateFull === null || stateFull === undefined)) {
    //   this.setState({
    //     isValidStateFull: true,
    //     isValidStateFullText: 'Please enter a valid state name',
    //   });
    // }
    if (!countryId || countryId === 0) {
      isValidCountryId = true;
      isValidCountryIdValue = `${t('countryValidation')}`;
    } else {
      isValidCountryId = false;
      isValidCountryIdValue = '';
    }
    this.setState({
      isInvalidFirstName,
      isInvalidFirstNameText,
      isInvalidLastName,
      isInvalidLastNameText,
      isValidCountryId,
      isValidCountryIdValue,
      isValidPhoneNumber,
      isValidPhoneNumberValue,
      isInvalidEmail,
      isInvalidEmailText,
    }, () => {
      if (this.state.isInvalidFirstName !== true
        && this.state.isInvalidLastName !== true
        && this.state.isValidCountryId !== true
        && this.state.isValidPhoneNumber !== true
        && this.state.isInvalidEmail !== true) {
        this.dismissEditProfileModal();
        const cpData = {
          profileId: candidateProfile && candidateProfile.profileId ? Number(candidateProfile.profileId) : Number(this.props.user.profileId),
          ...personal,
        };
        if (cpData.profileId) this.props.editCandidateProfile(cpData).then(() => {
          if (mobileData && mobileData.mobile) {
            this.props.editProfileContact(mobileData).then(() => {
              this.rerender(candidateProfile && candidateProfile.profileId, application && application.appId);
            });
          } else {
            this.rerender(candidateProfile && candidateProfile.profileId, application && application.appId);
          }
        });
      }
    });
    // else if (countryId && (!stateFull || stateFull === null || stateFull === undefined)) {
    //   this.setState({
    //     isValidStateFull: true,
    //     isValidStateFullText: 'Please enter a valid state name',
    //   });
    // } else if (location && (!stateFull || stateFull === null || stateFull === undefined)) {
    //   this.setState({
    //     isValidStateFull: true,
    //     isValidStateFullText: 'Please enter a valid state name',
    //   });
    // } else if (stateFull && (!location || location === null || location === undefined)) {
    //   this.setState({
    //     isValidLocationName: true,
    //     isValidLocationNameValue: 'Please enter valid location name',
    //   });
    // } else if (youtubeLink && !res.id) {
    //   this.setState({
    //     isYoutubeIdValid: true,
    //     isYoutubeIdValidText: 'Please enter valid url',
    //   });
    // }
  }

  handleOtherJobsPaginationChange = (data) => {
    const candidateProfile = this.props && this.props.candidateProfile && this.props.candidateProfile.profile;
    const {
      profileId,
    } = candidateProfile || {};
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ itemsPerPage,activePage }, () => {
      // this.props.findOtherJobsApplied(0, profileId, this.state.itemsPerPage, this.state.activePage);
    });
  };

  rerender = (profileId, appId) => {
    if (profileId) {
      this.props.getCandidateProfile(profileId);
    }
    if (appId) {
      this.props.findAllUserJobApplicants(appId);
    }
  }

  handleYoutubeLink = () => {
    const {
      profileId,
    } = this.props;
    const { youtubeLink } = this.state;
    if (youtubeLink) {
      const getVideoId = require('get-video-id');
      const res = getVideoId(youtubeLink || '');
      if (res && res.service && res.service.toString() && res.service.toLowerCase() === 'youtube' && res.id) {
        this.setState({
          youtubeId: res && res.id,
        });
        const data = {
          profileId: Number(profileId),
          youtubeLink,
        };
        this.props.editCandidateProfile(data).then(() => {
          this.props.getCandidateProfile(profileId);
        });
      } else {
        this.setState({
          isYoutubeIdValid: true,
          isYoutubeIdValidText: 'Please enter valid url',
        });
      }
    }
  }

  render() {
    const {
      candidateProfile, application, countries, loading, user,
      notEditable = false, noBenchMark = false, finland = false,
      showOtherJobsTab = false, noNameImg = false, t,
    } = this.props;
    const { roleId = 0, company: { ctypeId } = {} } = user || {};
    const {
      isOpenEditProfileModal, firstName, lastName, email, location, address, zipcode,
      isInvalidFirstName, isInvalidFirstNameText, isInvalidLastName, isInvalidLastNameText,
      isInvalidEmail, isInvalidEmailText, address2, stateFull, countryId,
      isValidCountryId, isValidCountryIdValue, isValidStateFull, isValidStateFullText,
      isValidLocationName, isValidLocationNameValue, personMobileNumber, isValidPhoneNumber,
      isValidPhoneNumberValue, linkedin, youtubeLink, currProfileId, youtubeId,
      isYoutubeIdValid, isYoutubeIdValidText, showLoader,
    } = this.state;
    return (
      <Fragment>
        <div>
          <div className="d-flex justify-content-center">
            {
              (showLoader === true) && (
                <div>
                  <Loading withOverlay={false} />
                </div>
              )
            }
          </div>
          {
            showLoader === true && (
              <div className="text-center bx--type-delta">{t('loadingMsg')}</div>
            )
          }
        </div>
        {
          (currProfileId && showLoader === false) ? (
            <Fragment>
              <div>
                <ApplicantInfoContainer
                  noBenchMark={noBenchMark}
                  notEditable={notEditable}
                  application={application || {}}
                  profile={candidateProfile && candidateProfile.profile ? candidateProfile.profile : candidateProfile}
                  activeEditProfileModal={this.activeEditProfileModal}
                  loading={loading}
                  roleId={roleId}
                  handleChange={this.handleChange}
                  link={youtubeId}
                  youtubeLink={youtubeLink}
                  handleYoutubeLink={this.handleYoutubeLink}
                  isYoutubeIdValid={isYoutubeIdValid}
                  isYoutubeIdValidText={isYoutubeIdValidText}
                  finland={finland}
                  proId={this.props.profileId}
                  profileEmails={this.state.profileEmails}
                  showOtherJobsTab={showOtherJobsTab}
                  showEmailsTab={false}
                  activePage={this.state.activePage}
                  handleOtherJobsPaginationChange={this.handleOtherJobsPaginationChange}
                  otherJobsApplied={this.props.otherJobsApplied}
                  totalCount={this.props.totalCount}
                  noNameImg={noNameImg}
                  candidateProfile={candidateProfile}
                  ctypeId={ctypeId}
                  t={t}
                />
              </div>
              <Modal
                open={isOpenEditProfileModal}
                shouldSubmitOnEnter
                modalHeading="Edit Candidate Profile"
                primaryButtonText="Submit"
                secondaryButtonText="Cancel"
                onRequestClose={this.dismissEditProfileModal}
                onRequestSubmit={this.handleEditProfileModal}
              >
                <div className="d-lg-flex">
                  <TextInput
                    className="mb-2"
                    name="firstName"
                    id="firstName"
                    labelText="FirstName"
                    placeholder="First Name"
                    onChange={e => this.handleChange(e)}
                    invalid={isInvalidFirstName}
                    invalidText={isInvalidFirstNameText}
                    value={firstName}
                  />
                  <TextInput
                    className="ml-lg-2"
                    name="lastName"
                    id="lastName"
                    labelText="LastName"
                    placeholder="Last Name"
                    onChange={e => this.handleChange(e)}
                    invalid={isInvalidLastName}
                    invalidText={isInvalidLastNameText}
                    value={lastName}
                  />
                </div>
                {/* <div className="mt-2">
                  <TextInput
                    name="address"
                    id="address"
                    labelText="Address 1"
                    placeholder="Address"
                    onChange={e => this.handleChange(e)}
                    value={address}
                  />
                </div>
                <div className="mt-2">
                  <TextInput
                    name="address2"
                    id="address2"
                    labelText="Address 2"
                    placeholder="Address"
                    onChange={e => this.handleChange(e)}
                    value={address2}
                  />
                </div>
                <div className="mt-2">
                  <TextInput
                    name="location"
                    id="location"
                    labelText="Location"
                    placeholder="Location"
                    onChange={e => this.handleChange(e)}
                    value={location}
                    invalid={isValidLocationName}
                    invalidText={isValidLocationNameValue}
                  />
                </div> */}
                <div className="mt-2 mr-2">
                  <Select
                    name="countryId"
                    labelText="Country"
                    id="countryId"
                    onChange={e => this.handleChange(e)}
                    value={countryId}
                    className="w-100"
                    invalid={isValidCountryId}
                    invalidText={isValidCountryIdValue}
                  >
                    <SelectItem text="Select" />
                    {
                      countries && Array.isArray(countries) && countries.map(b => (
                        <SelectItem key={b.countryId} text={`${b.countryFull}`} value={`${b.countryId}`} />
                      ))
                    }
                  </Select>
                </div>
                {/* <div className="d-lg-flex">
                  <div className="mt-2">
                    <TextInput
                      name="stateFull"
                      id="stateFull"
                      labelText="State Name"
                      placeholder="State"
                      onChange={e => this.handleChange(e)}
                      value={stateFull}
                      invalid={isValidStateFull}
                      invalidText={isValidStateFullText}
                    />
                  </div>
                </div> */}
                <div className="mt-2">
                  <TextInput
                    name="personMobileNumber"
                    id="phoneNumber"
                    labelText="Phone Number"
                    helperText="Enter phone number without country code"
                    placeholder="9876543210"
                    onChange={e => this.handleChange(e)}
                    value={personMobileNumber}
                    invalid={isValidPhoneNumber}
                    invalidText={isValidPhoneNumberValue}
                    type="number"
                  />
                </div>
                {/* <div className="mt-2">
                  <TextInput
                    name="zipcode"
                    id="zipcode"
                    labelText="Zipcode"
                    placeholder="zipcode"
                    onChange={e => this.handleChange(e)}
                    value={zipcode}
                  />
                </div> */}
                <div className="mt-2 mb-2">
                  <TextInput
                    name="email"
                    id="email"
                    labelText="Email"
                    placeholder="Email"
                    disabled
                    onChange={e => this.handleChange(e)}
                    invalid={isInvalidEmail}
                    invalidText={isInvalidEmailText}
                    value={email}
                  />
                </div>
                <div>
                  <TextInput
                    name="linkedin"
                    id="linkedin"
                    labelText="Linkedin Profile"
                    placeholder="link"
                    onChange={e => this.handleChange(e)}
                    value={linkedin}
                  />
                </div>
                {/* <div className="mt-2 mb-2">
                  <TextInput
                    name="youtubeLink"
                    id="youtubeLink"
                    labelText="Youtube Link"
                    placeholder="link"
                    onChange={e => this.handleChange(e)}
                    value={youtubeLink}
                    invalid={isYoutubeIdValid}
                    invalidText={isYoutubeIdValidText}
                  />
                </div> */}
              </Modal>
            </Fragment>
          ) : null
        }
      </Fragment>
    );
  }
}

const ApplicantInfoContainer = (props) => {
  const {
    profile, application, activeEditProfileModal, loading, roleId,
    notEditable = false, noBenchMark = false, handleChange, link,
    handleYoutubeLink, youtubeLink, isYoutubeIdValid, isYoutubeIdValidText,
    finland, profileEmails, showEmailsTab = false, showOtherJobsTab,
    otherJobsApplied, totalCount, activePage, handleOtherJobsPaginationChange,
    noNameImg, candidateProfile, t, proId, ctypeId,
  } = props;
  const {
    studentprofile: {
      studentUniversityId,
      courses: {
        courseName,
      } = {},
      faculty: {
        facultyName,
      } = {},
    } = {},
    userProfile: {
      userinfo: {
        firstname,
        lastname,
        picture,
      } = {},
    } = {},
  } = candidateProfile || {};
  const { experience = [], profileId, linkedin } = profile;
  const experienceDetails = experience.map(values => ({
    company: values.company,
    expDesc: values.expDesc,
    startDate: values.startDate,
    endDate: values.endDate,
  }));
  const getVideoId = require('get-video-id');
  const res = getVideoId((profile && profile.youtubeLink) || '');
  // const updateSkills = (Skills) => {
  //   if (profile.skill) profile.skill = Skills;
  // };

  const finalLink = youtubeLink && youtubeLink.toString() && youtubeLink.split('=')[1];
  // const updateSkills = (Skills) => {
  //   if (profile.skill) profile.skill = Skills;
  // };

  // const updateExperience = (index, Company) => {
  //   if (profile.experience && Array.isArray(profile.experience) && profile.experience.length >= index) {
  //     profile.experience[index] = Company;
  //   }
  // };

  // const addEduction = (School) => {
  //   if (profile.education && Array.isArray(profile.education)) profile.education.push(School);
  // };

  // const addExperience = (Company) => {
  //   if (profile.experience && Array.isArray(profile.experience)) profile.experience.push(Company);
  // };
  return (
    <Fragment>
      <div className={`${noBenchMark ? 'bx--tile p-4 mb-2 ' : ''}bx--row text-md-left text-xs-center `}>
        <div className={`${noBenchMark ? 'm-0 p-0' : ''} mt-md-2 mt-xs-2 mb-md-2 mb-xs-2 bx--col-lg-6`}>
          <div className={`${noBenchMark ? 'd-flex justify-content-around justify-content-md-start ml-md-2' : ''} ml-md-2`}>
            {profile ? (
              <div className=" mt-0 mb-3 text-uppercase row m-0">
                {!profile.firstName && !profile.lastName ? (
                  <span className="text-capitalize">
                    Name Unidentified
                  </span>
                ) : (
                  <div className="text-capitalize">
                    <div className="xpa-cand-info--card-info mb-0 bx--row align-items-center">
                      {!noNameImg && (<div className="mr-2">{(<Avatar user={{ firstname, profile, picture, }} size="100" classNameInfo="rounded-circle mr-1" />)}</div>)}
                      <div>
                        <div className="h6 mb-1">
                          {`${profile.firstName ? profile.firstName : ''} ${profile.lastName ? profile.lastName : ''}`}
                        </div>
                        {profile ? (
                          <div className={`${noBenchMark ? 'd-flex justify-content-center justify-content-md-start' : ''}`}>
                            <div className={`${noBenchMark ? 'd-block text-center' : ''}`}>
                              <div>
                                {profile.address && profile.address.addressLine1
                                  ? `${profile.address.addressLine1} `
                                  : ''}
                              </div>
                              <div>
                                {profile.address && profile.address.addressLine2
                                  ? `${profile.address.addressLine2} `
                                  : ''}
                              </div>
                              <div>
                                {profile.address &&
                                  profile.address.location &&
                                  profile.address.location.locationName
                                  ? `${profile.address.location.locationName},`
                                  : ''}
                                {profile.address &&
                                    profile.address.location.state &&
                                    profile.address.location.state.stateFull
                                  ? `${profile.address.location.state.stateFull}, `
                                  : ''}
                                {profile.address &&
                                profile.address.location &&
                                profile.address.location.country &&
                                profile.address.location.country.countryFull
                                  ? `${profile.address.location.country.countryFull} `
                                  : ''}
                                {profile.address && profile.address.zipcode
                                  ? `${profile.address.zipcode} `
                                  : ''}
                              </div>
                              <div>
                                { profile.contacts
                                  && profile.contacts[0]
                                  && profile.contacts[0].country
                                  && profile.contacts[0].country.countryCode
                                  ? `(${profile.contacts[0].country.countryCode})-`
                                  : ''
                                }
                                { profile.contacts
                                  && profile.contacts[0]
                                  && profile.contacts[0].mobile
                                  ? `${profile.contacts[0].mobile}`
                                  : ''
                                }
                              </div>
                            </div>
                          </div>
                        ) : ('')}
                                  
                      </div>
                    </div>
                  </div>
                )
                }
                {/* {
                  notEditable === false && (
                    <Button onClick={activeEditProfileModal} className="ml-0 ml-3 mt-3 pb-2" kind="ghost" small>
                      <EditIcon />
                    </Button>
                  )
                } */}
              </div>
            ) : ('')}
          </div>
          {/* {
            profile && profile.overallScore && !finland && (
              <div className="align-items-center bx--type-caps bx--type-epsilon d-flex mt-2 pt-2 pb-2 pl-2 pr-3 mb-3 border">
                <div className="pl-2 pb-2 pt-2 pr-0">
                  Your Fit for Finland Score:
                  <span className="ml-2 mr-2" style={{position:'relative',top:'2px'}}><FitForFinlandHelp /></span>
                </div>

                <FillCircle fill={Math.round(profile.overallScore)} fillColor="#4b2040" textColor="#53777A" />
              </div>
            )
          } */}
        </div>
        <div className={`${noBenchMark ? 'm-0 p-0' : ''} mt-2 bx--col-lg-6 xpa-name-overflow`}>
        {
            studentUniversityId ? (
              <div>
                <span>{t('studentUniversityId')}:</span> {studentUniversityId}
              </div>
            ) : null
          }
          {
            facultyName ? (
              <div>
                <span className="text-capitalize">{facultyName}</span>
              </div>
            ) : null
          }
          {
            courseName ? (
              <div>
                <span  className="text-capitalize">{courseName}</span>
              </div>
            ) : null
          }
          {profile && profile.personalEmail
            ? (
              <div className={`${noBenchMark ? 'd-flex justify-content-center justify-content-md-start' : ''}`}>
                <svg width="16" height="9" viewBox="0 0 16 11">
                  <path d="M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
                </svg>
                <span className="text-dark font-weight-bold ml-2 mt-0">
                  <a className="text-lowercase" href={`mailto:${profile.personalEmail}`}>{profile.personalEmail}</a>
                </span>
                <span className="text-dark ml-1">({t('personal')})</span>
              </div>
            ) : null}
          {profile && profile.email
            ? (
              <div className={`${noBenchMark ? 'd-flex justify-content-center justify-content-md-start' : 'd-flex'}`}>
                <div>
                  <svg width="16" height="9" viewBox="0 0 16 11">
                    <path d="M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
                  </svg>
                </div>
                <div>
                  <span className="text-dark font-weight-bold ml-2 mt-0">
                    <a className="text-lowercase" href={`mailto:${profile.email}`}>{profile.email}</a>
                  </span>
                </div>
              </div>
            ) : null}
          {/* {
            candidateProfile && candidateProfile.profile && candidateProfile.profile.tier && roleId && roleId !== 3
            && ctypeId && Number(ctypeId) === 2 ? (
              <div className="d-block text-center border p-2 mt-2">
                <div>{t('profilingTier')}</div>
                <div>
                  <h3 className="m-0"><strong>{candidateProfile.profile.tier}</strong></h3>
                </div>
              </div>
            ) : null
          } */}
          {/* {
            profile && profile.youtubeLink
              ? (
                  <div>
                    <iframe
                      allowFullScreen="allowFullScreen"
                      src={`https://www.youtube.com/embed/${res && res.id}?ecver=1&amp;iv_load_policy=3&amp;rel=0&amp;showinfo=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=300&amp;width=300`}
                      width="300"
                      height="150"
                      allowtransparency="true"
                      frameborder="0">
                      <div>
                          <a rel='nofollow' id='CAnlKAXg' href='https://www.comparetyres.com/help/winter-tyres>winter'></a>
                      </div>
                      <div>
                        <a rel='nofollow' id='CAnlKAXg' href='https://wildernesswood.co.uk/wilderness-tyres-atv-getting-stuck-in-the-mud'>
                          4x4 tyres
                        </a>
                      </div>
                      <small>Powered by
                        <a href='https://youtubevideoembed.com'>Embed YouTube Video</a>
                      </small>
                    </iframe>
                    <div class="text-dark bx--type-caption">To remove video, click on edit profile icon next to name</div>
                  </div>
              ) : (
                <div>
                  {
                    roleId && roleId === 3
                      && (
                        <div>
                          <div className="font-weight-bold text-dark">Add your video portfolio</div>
                          <img src={youtube} alt="Youtube" style={{ maxHeight: '30px', maxWidth: 'none' }} />
                          <div className="mt-1 bx--type-caption text-dark">Step 1.  Upload your video in Youtube.</div>
                          <div className="bx--type-caption text-dark">Step 2. Save the YouTube link to your profile below.</div>
                          <div className="d-flex mt-1">
                            <TextInput
                              labelText=""
                              hideLabel
                              name="youtubeLink"
                              id="youtubeLink"
                              placeholder="youtube.com/watch?v=PLM39sZlRu0"
                              onChange={e => handleChange(e)}
                              value={youtubeLink}
                              invalid={isYoutubeIdValid}
                              invalidText={isYoutubeIdValidText}
                            />
                            <div className="ml-2 mt-auto text-center">
                              <Button disabled={!youtubeLink} kind="tertiary" onClick={() => handleYoutubeLink()}>Save</Button>
                            </div>
                          </div>
                        </div>
                      )
                  }
                </div>
              )
          }
          {
            profile && !profile.youtubeLink && noBenchMark ? (<TalentPool profilePage profileId={profile.profileId} />) : null
          } */}
        </div>
      </div>
      {
        roleId && (roleId !== 3) && showOtherJobsTab && (
        <Tabs
          // className={`${noBenchMark ? 'p-4' : ''}`}
          selected={0}
          onClick={() => {}}
          onKeyDown={() => {}}
          onSelectionChange={() => {}}
        >
          <Tab label={showOtherJobsTab && <span>Profile Details</span>}>
            {/* {
              roleId && (roleId === 4) && (noBenchMark === false) && profile && (profile.overallScore === null)
            && profile && profile.totalExp ? (
              <div className="align-items-center bx--row justify-content-center">
                <Tile className="col-4 mt-2 mb-2 d-none">
                  <div className="">
                    <div className="text-center clearfix">
                      <strong className="mr-0 text-left float-left text-dark h2 m-0">
                        {`${Math.round(profile.totalExp)}`}
                      </strong>
                      <span className="text-dark h5 pt-0 mb-0">WORK EXPERIENCE</span>
                    </div>
                    <span className="bx--modal-header__label bx--type-delta xpa-fixed-width--S text-dark">Years</span>
                  </div>
                </Tile>
                <Tile className="bx--tile mr-4 ml-4 mt-2 mb-2 pb-0 pt-4 d-flex justify-content-center col-12 p-0 col-lg-5 col-md-10 col-sm-5 col-xl-4">
                  <div className="align-items-baseline d-flex row">
                    <div className="text-dark bx--modal-header__label mr-0 mb-0 pb-0 d-inline-block">
                      <div>
                        <FillCircle fill={application.twoWayMatch && Math.round(application.twoWayMatch) ? ((Math.round(application.twoWayMatch) * 1.4) > 100 ? '100' : Math.round(application.twoWayMatch) * 1.4).toFixed(0) : '0'} fillColor="#4b2040" textColor="#53777A" />
                      </div>
                    </div>
                    <div className="bx--modal-header__label bx--type-delta xpa-fixed-width--S ml-4 d-inline-block ml-2" style={{ position: 'relative', top: '-25px' }}>
                      <TooltipDefinition tooltipText={`Our big data analysis shows that this applicant's profile has a ${application.twoWayMatch && Math.round(application.twoWayMatch) ? ((Math.round(application.twoWayMatch) * 1.4) > 100 ? '100' : Math.round(application.twoWayMatch) * 1.4).toFixed(0) : '0'}% match when compared against people
                      who are working in a similar role.`}
                      >
                        <span className="bx--modal-header__label h5">BENCHMARK</span>
                      </TooltipDefinition>
                    </div>
                  </div>
                </Tile>
              </div>
            ) : null} */}
            {
              roleId && roleId !== 3 && (
              <div className={`${noBenchMark ? 'bx--tile p-0 pt-2 ' : ''}text-left`}>
                <CandidateDetails
                  notEditable={notEditable}
                  profileId={profileId || proId}
                  application={application}
                  updateSkills={updateSkills}
                  updateEduction={updateEduction}
                  updateExperience={updateExperience}
                  addEduction={addEduction}
                  addExperience={addExperience}
                  finland={finland}
                />
              </div>
              )
            }
          </Tab>
          {
            showEmailsTab && profileEmails && Array.isArray(profileEmails) && (profileEmails.length > 0) && (
              <Tab label="Emails">
                <div style={{ paddingLeft: 16 }} className={`${noBenchMark ? 'bx--tile pl-4 pt-4 ' : ''}`}>
                  {
                    profileEmails.map((e) => {
                      const {
                        id, templateName = 'UNKNOWN', createdAt, appId,
                      } = e || {};
                      let sentDateTime = moment(createdAt);
                      if (sentDateTime.isValid()) {
                        sentDateTime = sentDateTime.format('Do MMM YYYY HH:mm');
                      } else {
                        sentDateTime = 'UNKNOWN';
                      }
                      return (
                        <div key={id} className="mb-2">
                          <p><span className="bx--type-caps bx--type-omega">{templateName}</span> email was sent on {sentDateTime}
                            {(appId && appId != 0) ? ` in the context of application #${appId}` : null }.
                          </p>
                        </div>
                      );
                    })
                  }
                </div>
              </Tab>
            )
          }
          {
            showOtherJobsTab && otherJobsApplied
              && Array.isArray(otherJobsApplied)
              && otherJobsApplied.length > 0
              && (
                <Tab label="Other Jobs Applied">
                  <Tile>
                    <div className="font-weight-bold bx--type-delta">Other Jobs applied by this profile</div>
                      <hr />
                      <p className="text-dark mb-0">This section shows all the jobs that this particular applicant has applied for this company.</p>
                      <div className="d-block d-sm-none">
                        {
                          otherJobsApplied.map((job, index) => (
                            <CardLayout details={{
                                  status: job && job.status,
                                  appDate: job && job.appDate,
                              ...(job && job.job)
                            }} key={index} />
                          ))}
                      </div>
                    <ApplicantOtherJobs
                      otherJobsApplied={otherJobsApplied}
                      totalCount={totalCount}
                      handleOtherJobsPaginationChange={handleOtherJobsPaginationChange}
                      activePage={activePage}
                    />
                  </Tile>
                </Tab>
              )
          }
          {
            showOtherJobsTab && otherJobsApplied
            && Array.isArray(otherJobsApplied)
            && otherJobsApplied.length <= 0
            && (
              <div>
                <div className="text-dark col-12 pt-2">
                  This candidate has not applied for any other jobs.
                </div>
              </div>
            )
          }
        </Tabs>
        )}
        {
          roleId && showOtherJobsTab !== true && roleId !== 3 && (
          <div className={`${noBenchMark ? 'bx--tile p-0 pt-2 ' : ''}text-left`}>
            <CandidateDetails
              notEditable={notEditable}
              profileId={profileId || proId}
              application={application}
              updateSkills={updateSkills}
              updateEduction={updateEduction}
              updateExperience={updateExperience}
              addEduction={addEduction}
              addExperience={addExperience}
              finland={finland}
            />
          </div>
          )
        }
      {/* <JDProcessOverlay show={loading} message="processing..." /> */}
    </Fragment>
  );
};
const CardLayout = ({ details }) => {
  const {
    jobId,
    jobname: { nameName } = {},
    appDate,
    status,
    openDate,
  } = details;

  const newDate = new Date(openDate);



  return (
    <div className="mt-2">
      <div className="font-weight-bold"><a href={`/app/campus/job/${jobId}`}>{nameName}</a></div>
      <div><small className="text-dark">Job posted on:</small><small className="ml-1">{moment(newDate).format('MMM Do YYYY')}</small></div>
      <div><small className="text-dark">Applied on:</small><small className="ml-1">{moment(appDate).format('MMM Do YYYY')}</small></div>
      <div className="text-dark font-weight-bold">{status ? status : null}</div>
      <hr />
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.roboroyApplications.loading,
  applications: state.roboroyApplications,
  candidateProfile: state.roboroyApplications.candidateProfile,
  countries: state.roboroyApplications.countries,
  user: state.x0paDuser.user,
  otherJobsApplied: state.roboroyApplications.otherJobsApplied,
  totalCount: state.roboroyApplications.totalCount,
});

const mapDispatchToProps = {
  findAllUserJobApplicants,
  getCandidateProfile,
  editCandidateProfile,
  getCountries,
  editProfileContact,
  getProfileEmails,
  findOtherJobsApplied,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CandidateProfile));
