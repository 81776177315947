import { withTranslation } from 'react-i18next';
import React from 'react';
import FlexModalWrapper from 'react-modal-wrapper';
import 'react-modal-wrapper/dist/main.css'; // to load default styles
import Dropzone from 'react-dropzone';
import { upload } from 'actions/api';

class Upload extends React.Component {
  constructor() {
    super();
    this.state = {
      files: [],
      closeModal: '',
    };
  }

  render() {
    const { values } = this.state;
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" id="upload-modal">
        {/* <FlexModalWrapper
          className="x0pa-modal"
          closeOnEsc
          closeOnOutsideClick
          openByClickOn={this._button()}
        > */}
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header"><h5 className="modal-title">Upload</h5>
            <a href="javascript:void(0)" className="h4 modal-close"  data-dismiss="modal" >X</a>
            </div>
            {this._modal()}
          </div>
        </div>
        {/* </FlexModalWrapper> */}
      </div>
    );
  }

  onDrop(files) {
    this.setState({
      files: this.state.files.concat(files),
    });
  }

  onUpload() {
    const req = upload();
    this.state.files.forEach(file => {
      req.attach('theseNamesMustMatch', file);
    });
    req.end((err, res) => {
      if (err) console.log(err);
      alert('Your files have been uploaded successfully! You should be able to see the updated data in dashboard once processing is complete.');
      this.setState({files : []});
    });
  }

  _modal() {
    return (
      <div className="modal-body pl-0">
        <Dropzone onDrop={this.onDrop.bind(this)} className="dropzone" multiple>
          <img src={require('../../assets/images/dragyourinfo.png')} />
        </Dropzone>
        <div
          style={{
            // fontFamily: '"Open Sans",Helvetica,Arial,sans-serif',
            color: 'rgb(75, 73, 73)',
            marginTop: '-30px',
            marginBottom: '30px',
            marginLeft: '50px',
          }}
        >
          {this.state.files.map(f => <div>{f.name}</div>)}
        </div>
        <div
          className="bx--btn bx--btn--primary mt-2"
          style={{
            float: 'right',
            color: 'white',
          }}
        >
          <a onClick={this.onUpload.bind(this, this.state.files)}>Upload File</a>
        </div>
      </div>
    );
  }
  _button() {
    return (
      <div className="" style={{ left: '10px' }}>
        <a className="csv-btn h2 pt-2 pr-2 mt-1"  data-tooltip="Upload New Data">
          <span className="fa fa-upload" />
        </a>
      </div>
    );
  }
}


const styles = {
  contentBadge: {
    color: '#463043',
    fontFamily: 'futura-font',
    fontWeight: 'bold',
    fontSize: '13px',
    marginTop: 20,
    marginBottom: 10,
    marginLeft: '10px',
    width: '130px',
  },
  titleStyle: {
    fontFamily: 'futura-font',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  subtitleStyle: {
    fontFamily: 'futura-font',
    fontSize: '12px',
  },
  slider: {
    width: 680,
    color: '#472447',
    height: '10px',
  },
  sliderStyle: {
    selectionColor: '#472447',
    handleFillColor: '#472447',
  },
  customWidth: {
    width: '100%',
    height: 48,
    top: '-10px',
    fontFamily: 'futura-font',
  },
  underlineStyle: {
    borderColor: '#632763',
  },
};

const iconStyles = {
  marginRight: 44,
  position: 'relative',
  marginTop: '-9px',
};

export default Upload;
