import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { LineChart, Line, Tooltip, CartesianGrid, ResponsiveContainer, YAxis } from 'recharts';
import moment from "moment";
import HumanTime from 'utils/humanTime';

class ModifiedTooltip extends Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label, tag } = this.props;
      const data = (payload!=null&&payload.length>0)?payload[0].payload:{};
      // console.log("====MRRRRR ", tag, data);

      return <div className="recharts-default-tooltip columns card rounded shadow-sm" style={{ margin: "0px", padding: "10px", whiteSpace: "nowrap", textAlign: "center" }}>
          <div className="recharts-tooltip-label pb-0 mb-0">
            <p className="text-gray pb-0 mb-0">
              <small>
                {`${moment(data.startTime).format("MMMM Do YYYY, h:mm:ss a")} - ${moment(data.endTime).format("MMMM Do YYYY, h:mm:ss a")}`}
              </small>
            </p>
            <p className="metriccard-number pt-0 pb-0 mb-0">
            {tag.map((tg,key) => (
              <div key={key} className="bx--row">
                <div className="text-gray pb-0 mb-0  bx--col-xs-6 bx--col-md-6  bx--col-lg-7 bx--type-omega text-left font-weight-normal">
                    <span className="d-inline-block mr-1" style={{ background: tg.color, width:"10px", height: "10px" }}></span>
                    <span className="d-inline-block mr-1 text-dark" >{`${tg.label} `}</span>
                    
                </div>
                <div className="text-gray pb-0 mb-0  bx--col-xs-6 bx--col-md-6  bx--col-lg-5 bx--type-omega text-left font-weight-normal">
                    <span className="d-inline-block" 
                        style={{ color: tg.color }}>
                        {
                          tg.type == "date" && (
                            HumanTime.getHumanTime(data[tg.name])
                          )
                        }
                        {
                          tg.type == "number" && (
                            data[tg.name]
                          )
                        }
                        {
                          tg.type == "percent" && (
                            <div>{`${data[tg.name]}%`}</div>
                          )
                        }                              
                    </span>
                </div>
              </div>

            ))}    
            </p>
          </div>
        </div>;
    }
    return null;
  }
}

const cleanup = (data, tag) => {
  let cleanData = [];
  cleanData = data && data.map(x => {
    tag.map(tg => (
      x[tg.name] = x[tg.name] ? x[tg.name] : 0
    ));
    return x;
  });  
  return cleanData;
}

function AnalyticsLineGraph(props) { 
  // console.log("******", props.data, props.tag);
  // const data = cleanup(props.data, props.tag);
  // console.log("******>>", data, props.tag);

  // return (<div>test</div>)
  const data = cleanup(props.data, props.tag);
  //  console.log("******", data, props.tag);
  const {showGraph=true} = props;
  return (<div className={props.className || ''}>
    <div className={`bx--row row m-0 p-0 card-link justify-content-center text-sm-center text-md-center text-center ${showGraph?"text-lg-left":""}`} style={{display:"flex"}}>
      <div className={`bx--col-xs-12 mt-lg-3 ${showGraph?"bx--col-lg-4 col-lg-4 ":""}`}>
        <div className="text-dark card-subtitle mb-3 h6">{props.heading || "-"}</div>
        <div className="bx--type-omega card-subtitle text-info h3">
          {props.subheading}
        </div>
      </div>
      {showGraph && <div className="bx--col-lg-8 bx--col-xs-12 col-lg-8 ml-lg-0 mt-lg-0 mt-1 ml-4">
        <ResponsiveContainer width="90%" height={80}>
          <LineChart data={data || []} className="ml-lg-5">
            <Tooltip content={<ModifiedTooltip tag={props.tag} />} />
            <YAxis type="number" hide={true} />
            <CartesianGrid vertical horizontal={false} />
            {
              props.tag.map(tg => (
                <Line type='linear' dataKey={tg.name} stroke={tg.color} strokeWidth={1.5} />
              ))
            }

          </LineChart>
        </ResponsiveContainer>
      </div>}
    </div>
  </div>);
}

export default AnalyticsLineGraph;