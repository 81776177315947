import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, ToolbarItem, Toggle, FileUploaderButton,
  Modal, TextInput, TextArea, InlineNotification,
} from 'carbon-components-react';
import { baseUrl } from 'utils/config';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import { Progress } from 'react-sweet-progress';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import ProfileUpdateDetails from 'containers/common/profile/ProfileUpdateDetails';
import FacultySettings from 'components/roboroy/FacultySettings';
import { isURL } from 'validator';
import moment from 'moment';
import {
  getCompanyDetailsById,
  getCompanyUsersList,
  getStudentsListById,
  createFacultyDetails,
  deleteFacultyDetailsById,
  upsertFacultyDetailsById,
  triggerJobProfileList,
  createUserForACompany,
  triggerStatusOfMatching,
  upsertCompanyDetailsById,
  upsertCompanyAddlInfo,
  getEmployersJobInfo,
  performJobTransfer
} from 'actions/InstitutionView';
import {
  upsertUserInfoDetails,
} from 'actions/candidate/candidateConsent';
import {
  getAllFaculties,
  getAllCourses
} from 'actions/candidate/CandidateDetails';
import {
  getAllCountries,
  authorizeCompany,
} from 'actions/admin/CompanyAndOffice';
import {
  getCompanyInfo,
  updateCompanyInfo,
} from 'actions/admin/AdminSettings';
import {
  getSaluationList,
} from 'actions/companyRegistration';
import {
  updateStudentProfile,
} from 'actions/campus/internCandidate';
import FacultyView from 'components/roboroy/FacultyView';
import UsersView from 'components/roboroy/UsersView';
import StudentsView from 'components/roboroy/StudentsView';
import BatchManagementView from 'components/roboroy/BatchManagementView';
import CalendarSettings from 'containers/common/profile/CalendarSettings';
import InterviewSlots from 'containers/robocampus/Slots';
import {
  uploadStudentsAttendance,
  getStudentsList,
  uploadStudentsLoInfo
} from 'actions/BrowseStudents';
import {
  getDocumentTypes,
} from 'actions/uploadAllDocuments';
import StudentAttendance from 'assets/files/student-att.xls';

import {
  Layout,
  PagingInfo,
  Result,
  SearchBox,
  Facets,
  MultiCheckboxFacet,
  SingleSelectFacet,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';

import "./InstitutionView.css";

class InstitutionView extends Component {
  instPaths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'inst-view', name: 'Institution Info', href: '' },
  ];

  facultyPaths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'Faculty', name: 'Browse Schools', href: '/app/campus/browse/faculties' },
    { id: 'school-view', name: 'School Info', href: '' },
  ];

  compPaths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'company', name: 'Browse Companies', href: '/app/campus/browse/companies?Companymapstatus=Accepted&Companymapstatus=Accepted%2CPending&' },
    { id: 'comp-view', name: 'Company Info', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      selectedSection: "basicInformation",
      isOpenFacultyModal: false,
      facultyName: '',
      isValidFacultyName: false,
      isValidFacultyNameText: '',
      isOpenUserModal: false,
      firstname: '',
      lastname: '',
      email: '',
      isFirstNameValid: false,
      isFirstNameValidText: '',
      isLastNameValid: false,
      isLastNameValidText: '',
      isEmailValid: false,
      isEmailValidText: '',
      phoneNumber: null,
      isValidCountryCode: false,
      isValidCountryCodeValue: '',
      isPhoneNumberValid: false,
      isPhoneNumberValidText: '',
      countryCode: null,
      studentsItemsPerPage: 10,
      studentsActivePage: 1,
      studentsSearchKey: '',
      usersItemsPerPage: 10,
      usersActivePage: 1,
      usersSearchKey: '',
      facultyActivePage: 1,
      facultyItemsPerPage: 10,
      facultySearchKey: '',
      isOpenFacultyDeleteModal: false,
      facultyId: 0,
      isAdmin: false,
      isSuperAdmin: false,
      active: false,
      courseAdmin: false,
      userId: 0,
      showProgressBar: false,
      studentsCount: null,
      jobsCount: null,
      showNotifErr: false,
      errMsg: '',
      url: '',
      instAllocNumber: null,
      isValidAllocNumb: false,
      isValidAllocNumbText: '',
      compAllocNumber: null,
      isValidCompAllocNumb: false,
      isValidCompAllocNumbText: '',
      showInstSaveMsg: false,
      showStdSaveMsg: false,
      status: false,
      showUserErrNotif: false,
      isEligible: false,
      isOpenStudentAttModal: false,
      isOpenStudentLoInfoModal: false,
      additionalDocLink: '',
      cvTemplate: '',
      studentUploadDocs: [],
      inputValues: [],
      questInputValues: [],
      questionConfig: [],
      userFacultyId: 0,
      isValidUserFaculty: false,
      otpLength: null,
      otpExpiry: null,
      saluationListArr: [],
      showLoader: false,
      showAccptRejBtns: false,
      showFacultySetgsMsg: null,
      courseId: 0,
    };
  }

  componentDidMount() {
    this.handleGetInstData();
    this.handleSingleSelectFacets();
  }

  handleSingleSelectFacets = () => {
    const {
      match: {
        params: {
          id: institutionId,
          status: paramStatus,
          facultyId: paramFacultyId,
        } = {},
      } = {},
      location: { pathname },
      t,
    } = this.props;
    // let {leftNavData=[]}=this.state;
    let leftNavData = [];
    if (pathname.includes("/company/info")) {
      leftNavData = [
        {
          titleName: 'Basic Information',
          titleProp: 'basicInformation',
        },
      ]
    } else {
      leftNavData = [
        {
          titleName: 'Basic Information',
          titleProp: 'basicInformation',
        },
        {
          titleName: `${t('users')}`,
          titleProp: 0,
        },
        {
          titleName: `${t('students')}`,
          titleProp: 1,
        },
        {
          titleName: `${t('logAttendance')}`,
          titleProp: 2,
        },

      ];
      if (!paramStatus || (paramStatus !== 'faculty')) {
        leftNavData.push({
          titleName: `${t('faculty')}`,
          titleProp: 3
        }, {
          titleName: `${t('settings')}`,
          titleProp: 4
        });
        leftNavData.push({
          titleName: `${t('Batch Management')}`,
          titleProp: 5
        })
      }
      if (paramStatus === 'faculty') {
        leftNavData.push({
          titleName: `${t('assignLiaisonOfficer')}`,
          titleProp: 4
        });
        leftNavData.push({
          titleName: `${t('settings')}`,
          titleProp: 3
        });
      }
      leftNavData.push({
        titleName: `${t('studentJobMatching1')}`,
        titleProp: 6
      })
    }


    const multiValueProps = {
      label: 'Sections',
      options: [],
      onRemove: () => { },
      onSelect: (val) => {
        const foundIndex = leftNavData.findIndex(
          (r) => r && r.titleName === val
        );
        const propValue = leftNavData[foundIndex].titleProp;
        this.handleOpenSections(propValue);
      },
    };

    const filterSingleLinkArr = [];
    leftNavData.forEach((res) => {
      const { titleName, titleProp } = res || {};
      const finalObj = {
        selected: false,
        value: titleName,
        count: '',
        propVal: titleProp,
      };
      filterSingleLinkArr.push(finalObj);
    });
    multiValueProps.options.push(...filterSingleLinkArr);
    this.setState({
      multiValueProps: [multiValueProps],
    });
  }

  handleOpenSections = (propsValue) => {
    this.setState({
      selectedSection: propsValue,
    }, () => {
      if (this.state.selectedSection === 3) {
        const {
          facultyData,
          match: {
            params: {
              facultyId,
            } = {},
          } = {},
        } = this.props;
        let finalFacultyData = {};
        if (facultyData && Array.isArray(facultyData) && facultyData.length > 0) {
          finalFacultyData = facultyData.find(res => res.facultyId == facultyId);
        }
        const {
          totalJobsApplicable,
          cvTemplate,
          termsConditions,
          studentUploadDocs,
          additionalDocLink,
          questionConfig,
          internshipStartDate,
          internshipEndDate,
        } = finalFacultyData || {};
        let finalStdUplDocs = studentUploadDocs;
        if (typeof studentUploadDocs === 'string') {
          finalStdUplDocs = studentUploadDocs && JSON.parse(studentUploadDocs);
        } else if (studentUploadDocs && Array.isArray(studentUploadDocs)) {
          finalStdUplDocs = studentUploadDocs;
        }
        let finalQuesConfig = questionConfig;
        if (typeof questionConfig === 'string') {
          finalQuesConfig = questionConfig && JSON.parse(questionConfig);
        } else if (questionConfig && Array.isArray(questionConfig)) {
          finalQuesConfig = questionConfig;
        }
        this.setState({
          totalJobsApplicable,
          cvTemplate,
          termsConditions,
          studentUploadDocs: finalStdUplDocs,
          additionalDocLink,
          questionConfig: finalQuesConfig,
          internshipStartDate,
          internshipEndDate,
        });
      }
    });
  };


  componentDidUpdate(prevProps) {
    const {
      match: {
        params: {
          id,
          status: paramStatus
        } = {},
      } = {},
    } = prevProps || {};
    const {
      match: {
        params: {
          id: propId,
          status: propParamStatus
        } = {},
      } = {},
    } = this.props || {};

    if (id != propId || paramStatus != propParamStatus) {
      this.handleGetInstData();
      this.handleSingleSelectFacets();
    }
  }

  handleGetInstData = () => {
    const {
      match: {
        params: {
          id,
        } = {},
      } = {},
    } = this.props;
    this.setState({
      studentsItemsPerPage: 10,
      studentsActivePage: 1,
      studentsSearchKey: '',
    }, () => {
      this.handleStudentsApiCall();
      this.handleFacultyApiCall()
      this.handleCourseApiCall();
      this.handleUsersApiCall();
      this.props.getCompanyDetailsById(id).then((val) => {
        if (val && !val.error) {
          const {
            companyDetails,
          } = this.props;
          const {
            status,
            companyadditionalinfo: {
              termsConditions,
            } = {},
          } = companyDetails || {};
          this.setState({
            status,
            termsConditions,
          });
        }
      });
      this.props.getDocumentTypes();
      this.props.getAllCountries();
      const facultyData = {
        facultyActivePage: 1,
        facultyItemsPerPage: 1000,
        facultySearchKey: null,
      };
      // this.props.getAllFaculties(facultyData)
      this.props.getCompanyInfo().then((res) => {
        if (res && !res.error) {
          const {
            // meta,
            otpLength,
            otpExpiry,
          } = (res && res.data) || {};
          this.setState({
            otpLength,
            otpExpiry,
          }, () => {
            this.props.getSaluationList().then((sals) => {
              if (sals && !sals.error) {
                const {
                  saluationList,
                } = this.props;
                let saluationListArr = [];
                if (saluationList && Array.isArray(saluationList) && saluationList.length > 0) {
                  saluationListArr = saluationList.map((s) => {
                    const {
                      saluationId,
                      saluationName,
                    } = s || {};
                    const data = {
                      label: saluationName,
                      value: saluationId,
                    };
                    return data;
                  });
                }
                this.setState({
                  saluationListArr,
                });
              }
            });
          });
          // const {
          //   instAllocNumber,
          //   compAllocNumber,

          // } = meta || {};
          // if (instAllocNumber) {
          //   this.setState({
          //     instAllocNumber,
          //   });
          // }
          // if (compAllocNumber) {
          //   this.setState({
          //     compAllocNumber,
          //   });
          // }
        }
      });
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleStudentsPaginationChange = (data) => {
    const studentsActivePage = (data.page) || 1;
    const studentsItemsPerPage = data.pageSize || this.state.studentsItemsPerPage;
    this.setState({ studentsActivePage, studentsItemsPerPage }, () => {
      this.handleStudentsApiCall();
    });
  }

  handleUsersPaginationChange = (data) => {
    const usersActivePage = (data.page) || 1;
    const usersItemsPerPage = data.pageSize || this.state.usersItemsPerPage;
    this.setState({ usersActivePage, usersItemsPerPage }, () => {
      this.handleUsersApiCall();
    });
  }

  handleFacultyPaginationChange = (data) => {
    const facultyActivePage = (data.page) || 1;
    const facultyItemsPerPage = data.pageSize || this.state.facultyItemsPerPage;
    this.setState({ facultyActivePage, facultyItemsPerPage }, () => {
      this.handleFacultyApiCall();
    });
  }

  searchTimer = null;

  handleSearchStudentsData = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ studentsSearchKey: event.target.value, studentsActivePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.handleStudentsApiCall()
      ), 1000);
    });
  }

  handleSearchUsersData = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ usersSearchKey: event.target.value, usersActivePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.handleUsersApiCall()
      ), 1000);
    });
  }

  handleSearchFacultyData = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ facultySearchKey: event.target.value, facultyActivePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.handleFacultyApiCall()
      ), 1000);
    });
  }

  handleStudentsApiCall = () => {
    const {
      match: {
        params: {
          id, facultyId,
        } = {},
      } = {},
      user: {
        company: {
          companyName,
        } = {},
      } = {},
      facultyData,
    } = this.props;
    const {
      studentsItemsPerPage,
      studentsActivePage,
      studentsSearchKey,
    } = this.state;
    const data = {
      itemsPerPage: studentsItemsPerPage,
      activePage: studentsActivePage,
      searchKey: studentsSearchKey,
      // Institutions: [companyName],
    };
    let currFaculty = {};
    if (facultyData && Array.isArray(facultyData) && facultyData.length > 0) {
      currFaculty = facultyData.find(res => res.facultyId == facultyId);
    }
    if (facultyId) {
      data.Faculties = [currFaculty && currFaculty.facultyName && currFaculty.facultyName.toLowerCase()];
    }
    this.props.getStudentsList(data);
  }

  handleFacultyApiCall = () => {
    const {
      match: {
        params: {
          id, facultyId,
        } = {},
      } = {},
    } = this.props;
    const {
      facultyActivePage,
      facultyItemsPerPage,
      facultySearchKey,
    } = this.state;
    const data = {
      facultyActivePage,
      facultySearchKey,
      schoolId: id,
    };
    if (facultyId) {
      data.facultyItemsPerPage = 1000;
    } else {
      data.facultyItemsPerPage = facultyItemsPerPage;
    }
    this.props.getAllFaculties(data);
  }

  handleCourseApiCall = () => {
    const {
      match: {
        params: {
          id,
          facultyId,
        } = {},
      } = {},
    } = this.props;
    const finalData = {
      schoolId: id,
      facultyId,
      courseItemsPerPage: 10,
      courseSearchKey: '',
      courseActivePage: 1,
    };
    this.props.getAllCourses(finalData);
  }

  handleUsersApiCall = () => {
    const {
      match: {
        params: {
          id, facultyId,
        } = {},
      } = {},
    } = this.props;
    const {
      usersActivePage,
      usersItemsPerPage,
      usersSearchKey,
    } = this.state;
    const data = {
      usersActivePage,
      usersItemsPerPage,
      usersSearchKey,
      companyId: id,
    };
    if (facultyId) {
      data.facultyId = facultyId && Number(facultyId);
    }
    this.props.getCompanyUsersList(data);
  }

  activeFacultyModal = (facultyId, facultyName) => {
    if (facultyId && facultyId !== null && facultyId !== undefined) {
      this.setState({
        isOpenFacultyModal: true,
        facultyId,
        facultyName,
      });
    } else {
      this.setState({
        isOpenFacultyModal: true,
        facultyId: null,
        facultyName: '',
      });
    }
  }

  dismissFacultyModal = () => {
    this.setState({
      isOpenFacultyModal: false,
      isValidFacultyName: false,
      isValidFacultyNameText: '',
      facultyName: '',
    });
  }

  activeFacultyDeleteModal = (facultyId) => {
    this.setState({
      isOpenFacultyDeleteModal: true,
      facultyId,
    });
  }

  deleteFacultyDeleteModal = () => {
    this.setState({
      isOpenFacultyDeleteModal: false,
    });
  }

  activeUsersModal = (data) => {
    if (data) {
      this.setState({
        isOpenUserModal: true,
        ...data,
      });
    } else {
      this.setState({
        isOpenUserModal: true,
        userId: 0,
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        countryCode: 0,
        isSuperAdmin: false,
        isAdmin: false,
        active: false,
      });
    }
  }

  handleCompanyUserActiveToggle=(e, propUserId)=>{
    if(e==false){
      this.props.getEmployersJobInfo({userId: propUserId}).then((res)=>{

        if(res && !res.error){
          const { doesEmployerCreatedJobs } = res;
          if(doesEmployerCreatedJobs){
            this.setState({
              selectedUserToTransferJob: 'select',
              selectedUserValue: propUserId,
              isOpenUserJobTransferModal:true,
            })
          }else{
            this.setState({
              selectedUserValue: propUserId,
              isOpenInactiveModal:true,
              inactiveModalMsg: 'This user is not attached to any jobs and can be set to inactive. Click on set as inactive button to make this user inactive.'
            })
          }
        }
      });
    }else{
      // make patch call with active as true
      this.setState({
        selectedUserValue: propUserId,
      },()=>{
        this.setUserActiveFlag(true);
      })
    }
  }

  setUserActiveFlag = (activeFlag) =>{
    const {selectedUserValue: userId} = this.state || {};
    const data = {userId , active: activeFlag}
    this.props.upsertUserInfoDetails(data)
    .then((res) => {
      if (res && !res.error) {
        this.handleUsersApiCall();
      }
    })
    .catch(e => console.log('Error:::::', e));
  }

  handleSubmitInactiveModal = () => {
    this.setUserActiveFlag(false);
    this.dismissInactiveModal();
    // this.props.upsertCompanyAddlInfo({userId:selectedUserValue, active:false})
  }

  dismissInactiveModal = () => {
    this.setState({isOpenInactiveModal:false})
  }



  dismissUserJobTransferModal=()=>{
    this.setState({
      isOpenUserJobTransferModal:false,
      isTransferJobUserInvalid:false,
      isTransferJobUserInvalidText:null,
      isJobTransferUnsuccessfull:null
    })
  }

  userChange=(e)=>{
    this.setState({
      selectedUserToTransferJob:e.target.value,
      isTransferJobUserInvalid:false,
      isTransferJobUserInvalidText:null
    })
  }

  handleSubmitUserJobTransfer=()=>{
    const {selectedUserValue, selectedUserToTransferJob} = this.state || { };
    if(!selectedUserToTransferJob || selectedUserToTransferJob=='select'){
      this.setState({isTransferJobUserInvalid:true,isTransferJobUserInvalidText: 'Select user to transfer job'})
      return;
    }
    const data = {
      userId:selectedUserValue,
      transferUserId:selectedUserToTransferJob
    }
    this.props.performJobTransfer(data).then((res)=>{
      if(res && !res.error){
        const {totalJobs, transferJobCount} = res || { };
        if(totalJobs == transferJobCount){
          this.setState({
            isOpenUserJobTransferModal:false,
            isOpenInactiveModal:true,
            inactiveModalMsg: 'Job transfer has been succesfull. Click on set as inactive button to make this user inactive.'
          })
        }else{
          this.setState({
            isJobTransferUnsuccessfull: {
              totalJobs, transferJobCount
            }
          })
        }
      }
    })
  }

  dismissUserModal = () => {
    this.setState({
      isOpenUserModal: false,
      firstname: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      countryCode: 0,
      isSuperAdmin: false,
      isAdmin: false,
      active: false,
      userId: null,
    });
  }

  handleSubmitCompanyAllocNumb = () => {
    const {
      instAllocNumber,
    } = this.state;
    const {
      t,
    } = this.props;
    let isValidAllocNumb = false;
    let isValidAllocNumbText = '';
    if (!instAllocNumber || instAllocNumber === null || instAllocNumber === undefined) {
      isValidAllocNumb = true;
      isValidAllocNumbText = `${t('allocValdMsg')}`;
    } else {
      isValidAllocNumb = false;
      isValidAllocNumbText = '';
    }
    this.setState({
      isValidAllocNumbText,
      isValidAllocNumb,
    }, () => {
      this.handleCompInfoUpdate('institution');
    });
  }

  handleSubmitCompAllocNumb = () => {
    const {
      compAllocNumber,
    } = this.state;
    const {
      t,
    } = this.props;
    let isValidCompAllocNumb = false;
    let isValidCompAllocNumbText = '';
    if (!compAllocNumber || compAllocNumber === null || compAllocNumber === undefined) {
      isValidCompAllocNumb = true;
      isValidCompAllocNumbText = `${t('allocValdMsg')}`;
    } else {
      isValidCompAllocNumb = false;
      isValidCompAllocNumbText = '';
    }
    this.setState({
      isValidCompAllocNumbText,
      isValidCompAllocNumb,
    }, () => {
      this.handleCompInfoUpdate('student');
    });
  }

  handleCompInfoUpdate = (value) => {
    const {
      instAllocNumber,
      isValidAllocNumb,
      isValidCompAllocNumb,
      compAllocNumber,
    } = this.state;
    const {
      match: {
        params: {
          id,
        } = {},
      } = {},
    } = this.props;
    if (isValidAllocNumb !== true && isValidCompAllocNumb !== true) {
      const data = {
        companyId: Number(id),
        meta: { instAllocNumber, compAllocNumber },
      };
      this.props.updateCompanyInfo(data).then((res) => {
        if (res && !res.error) {
          this.props.getCompanyInfo();
          if (value === 'student') {
            this.setState({
              showStdSaveMsg: true,
            });
          } else {
            this.setState({
              showInstSaveMsg: true,
            });
          }
        }
      });
    }
  }

  handleSubmitFacultyDeleteModal = () => {
    const {
      facultyId,
    } = this.state;
    this.props.deleteFacultyDetailsById(facultyId).then((res) => {
      if (res && !res.error) {
        this.handleFacultyApiCall();
        this.deleteFacultyDeleteModal();
      }
    });
  }

  handleChangeTabClick = (tab) => {
    let selectedTab = 0;
    switch (tab) {
      case 'users':
        selectedTab = 0;
        break;
      case 'students':
        selectedTab = 1;
        break;
      // case 'matches':
      //   selectedTab = 2;
      //   break;
      // case 'calendar':
      //   selectedTab = 4;
      //   break;
      // case 'interview-slots':
      //   selectedTab = 5;
      //   break;
      case 'import':
        selectedTab = 2;
        break;
      case 'faculty':
        selectedTab = 3;
        break;
      case 'settings':
        selectedTab = 3;
        break;
      case 'comp-settings':
        selectedTab = 4;
        break;
      case 'batch-management':
        selectedTab = 5;
        break;
      default:
        break;
    }
    this.setState({
      selectedTab,
    }, () => {
      if (this.state.selectedTab === 3) {
        const {
          facultyData,
          match: {
            params: {
              facultyId,
            } = {},
          } = {},
        } = this.props;
        let finalFacultyData = {};
        if (facultyData && Array.isArray(facultyData) && facultyData.length > 0) {
          finalFacultyData = facultyData.find(res => res.facultyId == facultyId);
        }
        const {
          totalJobsApplicable,
          cvTemplate,
          termsConditions,
          studentUploadDocs,
          additionalDocLink,
          questionConfig,
          internshipStartDate,
          internshipEndDate,
        } = finalFacultyData || {};
        let finalStdUplDocs = studentUploadDocs;
        if (typeof studentUploadDocs === 'string') {
          finalStdUplDocs = studentUploadDocs && JSON.parse(studentUploadDocs);
        } else if (studentUploadDocs && Array.isArray(studentUploadDocs)) {
          finalStdUplDocs = studentUploadDocs;
        }
        let finalQuesConfig = questionConfig;
        if (typeof questionConfig === 'string') {
          finalQuesConfig = questionConfig && JSON.parse(questionConfig);
        } else if (questionConfig && Array.isArray(questionConfig)) {
          finalQuesConfig = questionConfig;
        }
        this.setState({
          totalJobsApplicable,
          cvTemplate,
          termsConditions,
          studentUploadDocs: finalStdUplDocs,
          additionalDocLink,
          questionConfig: finalQuesConfig,
          internshipStartDate,
          internshipEndDate,
        });
      }
    });
  }

  handleSubmitFacultyDetails = () => {
    const {
      facultyName,
    } = this.state;
    const {
      t,
    } = this.props;
    let isValidFacultyName = null;
    let isValidFacultyNameText = '';
    if (!facultyName || facultyName === null || facultyName === undefined) {
      isValidFacultyName = true;
      isValidFacultyNameText = `${t('facultyNameValid')}`;
    } else {
      isValidFacultyName = false;
      isValidFacultyNameText = '';
    }
    this.setState({
      isValidFacultyName,
      isValidFacultyNameText,
    }, () => {
      this.handleCheckFaultyNameValidation();
    });
  }

  handleDeteleFacultyDetails = (facultyId) => {
    this.props.deleteFacultyDetailsById(facultyId).then((res) => {
      if (res && !res.error) {
        this.handleFacultyApiCall();
      }
    })
      .catch(e => console.log('Error', e));
  }

  handleCheckFaultyNameValidation = () => {
    const {
      match: {
        params: {
          id,
        } = {},
      } = {},
    } = this.props;
    const {
      isValidFacultyName,
      facultyName,
      facultyId,
    } = this.state;
    if (isValidFacultyName !== true) {
      const data = {
        facultyName,
      };
      if (!facultyId) {
        data.schoolId = id && Number(id);
        this.props.createFacultyDetails(data).then((res) => {
          if (res && !res.error) {
            this.handleFacultyApiCall();
            this.dismissFacultyModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      } else if (facultyId) {
        data.facultyId = facultyId && Number(facultyId);
        this.props.upsertFacultyDetailsById(data).then((res) => {
          if (res && !res.error) {
            this.handleFacultyApiCall();
            this.dismissFacultyModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      }
    }
  }

  handleSubmitUserDetails = () => {
    const {
      t,
      companyDetails: {
        ctypeId,
        domain,
      } = {},
      match: {
        params: {
          facultyId,
        } = {},
      } = {},
    } = this.props;
    const {
      firstname,
      lastname,
      designation,
      countryCode,
      phoneNumber,
      email,
      userFacultyId,
      courseId,
      saluationVal: {
        value: saluationId,
      } = {},
    } = this.state;
    const splitEmail = email && email.toString() && email.split('@');
    const finalEmail = splitEmail && Array.isArray(splitEmail)
      && splitEmail.length > 1
      && splitEmail[1] && splitEmail[1].toString()
      && splitEmail[1].toLowerCase();
    const finalDomain = domain && domain.toString() && domain.toLowerCase();
    let isFirstNameValid = null;
    let isFirstNameValidText = '';
    let isLastNameValid = null;
    let isLastNameValidText = '';
    let isEmailValid = null;
    let isEmailValidText = '';
    let isValidCountryCode = null;
    let isValidCountryCodeValue = '';
    let isPhoneNumberValid = null;
    let isPhoneNumberValidText = '';
    let isValidDesignation = null;
    let isValidDesignationVal = '';
    let isValidUserFaculty = false;
    let isSaluationValid = false;
    if (!saluationId) {
      isSaluationValid = true;
    } else {
      isSaluationValid = false;
    }
    if (!firstname || firstname === null || firstname === undefined) {
      isFirstNameValid = true;
      isFirstNameValidText = `${t('nameValidation')}`;
    } else {
      isFirstNameValid = false;
      isFirstNameValidText = '';
    }
    if (!lastname || lastname === null || lastname === undefined) {
      isLastNameValid = true;
      isLastNameValidText = `${t('nameValidation')}`;
    } else {
      isLastNameValid = false;
      isLastNameValidText = '';
    }
    if (!email || email === null || email === undefined) {
      isEmailValid = true;
      isEmailValidText = `${t('noValidEmail')}`;
    } else {
      isEmailValid = false;
      isEmailValidText = '';
    }
    // if (finalEmail !== finalDomain) {
    //   isEmailValid = true;
    //   isEmailValidText = `${t('emailDomainVld')}`;
    // } else {
    //   isEmailValid = false;
    //   isEmailValidText = '';
    // }

    // if (!countryCode || countryCode === null || countryCode === undefined) {
    //   isValidCountryCode = true;
    //   isValidCountryCodeValue = `${t('countryCodeValidation')}`;
    // } else {
    //   isValidCountryCode = false;
    //   isValidCountryCodeValue = '';
    // }
    // if (!phoneNumber || phoneNumber === null || phoneNumber === undefined) {
    //   isPhoneNumberValid = true;
    //   isPhoneNumberValidText = `${t('phoneNoValidation')}`;
    // } else {
    //   isPhoneNumberValid = false;
    //   isPhoneNumberValidText = '';
    // }
    if (ctypeId === 2 && (!designation || designation === null || designation === undefined)) {
      isValidDesignation = true;
      isValidDesignationVal = `${t('designationValidation')}`;
    } else {
      isValidDesignation = false;
      isValidDesignationVal = '';
    }
    if (ctypeId && Number(ctypeId) === 2 && !facultyId && !userFacultyId) {
      isValidUserFaculty = true;
    } else {
      isValidUserFaculty = false;
    }
    this.setState({
      isFirstNameValid,
      isFirstNameValidText,
      isLastNameValid,
      isLastNameValidText,
      isEmailValid,
      isEmailValidText,
      isValidCountryCode,
      isValidCountryCodeValue,
      isPhoneNumberValid,
      isPhoneNumberValidText,
      isValidDesignation,
      isValidDesignationVal,
      isValidUserFaculty,
      isSaluationValid,
    }, () => {
      this.handleUserDetailsValidation();
    });
  }

  handleUserDetailsValidation = () => {
    const {
      companyDetails: {
        ctypeId,
        office: {
          officeId,
        } = {},
      } = {},
    } = this.props;
    const {
      isFirstNameValid,
      isLastNameValid,
      isValidCountryCode,
      isPhoneNumberValid,
      isEmailValid,
      userId,
      isValidDesignation,
      isValidUserFaculty,
      userFacultyId,
      isSaluationValid,
    } = this.state;
    const {
      match: {
        params: {
          id, facultyId,
        } = {},
      } = {},
    } = this.props;
    const {
      firstname,
      lastname,
      designation,
      countryCode,
      phoneNumber,
      email,
      isAdmin,
      isSuperAdmin,
      active,
      courseId,
      courseAdmin,
      saluationVal: {
        value: saluationId,
      } = {},
      secondaryphone,
      inactivityStatus
    } = this.state;
    const data = {
      firstname,
      lastname,
      designation,
      codeId: countryCode,
      phone: phoneNumber,
      email,
      companyId: Number(id),
      officeId,
      username: `${firstname} ${lastname}`,
      isSuperAdmin,
      active,
      courseAdmin,
      saluationId,
      secondaryphone,
    };
    if(inactivityStatus!== undefined && inactivityStatus!==null){
      data.inactivityStatus=inactivityStatus;
    }
    if (ctypeId && Number(ctypeId) === 2) {
      if (!facultyId) {
        data.facultyId = userFacultyId;
      } else {
        data.facultyId = facultyId;
      }
    }
    if (ctypeId && Number(ctypeId) === 2) {
      data.isAdmin = isAdmin;
    } else {
      data.isAdmin = true;
    }
    if (isSuperAdmin === true) {
      data.roleId = 1;
    } else if (isSuperAdmin !== true) {
      data.roleId = 4;
    }

    if (ctypeId && Number(ctypeId) === 2 && courseAdmin === true) {
      data.roleId = 4
      data.courseId = courseId
    }
    if (!userId) {
      if (isFirstNameValid !== true && isLastNameValid !== true
        && isEmailValid !== true
        && isValidCountryCode !== true
        && isValidDesignation !== true
        && isValidUserFaculty !== true
        && isPhoneNumberValid !== true
        && isSaluationValid !== true) {
        this.props.createUserForACompany(data).then((res) => {
          if (res && !res.error) {
            this.handleUsersApiCall();
            this.dismissUserModal();
          } else if (res && res.error) {
            this.setState({
              showUserErrNotif: true,
            });
          }
        })
          .catch(e => console.log('Error:::::', e));
      }
    } else {
      data.userId = Number(userId);
      if (isFirstNameValid !== true && isLastNameValid !== true
        && isSaluationValid !== true) {
        this.props.upsertUserInfoDetails(data).then((res) => {
          if (res && !res.error) {
            this.handleUsersApiCall();
            this.dismissUserModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      }
    }
  }

  handleRedirectToFacultyViewPage = (facultyId) => {
    const {
      history: {
        push,
      } = {},
      match: {
        params: {
          id,
        } = {},
      } = {},
      user: {
        facultyId: userFacultyId,
        isSuperAdmin,
      } = {},
    } = this.props;
    this.setState({ selectedSection: "basicInformation" })
    if (push && (facultyId == userFacultyId || isSuperAdmin === true)) {
      push(`/app/campus/institution/info/${id}/faculty/${facultyId}`);
    }
  }

  handleRedirectProfilePage = (profileId, studentProfileId) => {
    const {
      history: { push } = {},
    } = this.props;
    if (push) {
      push(`/app/campus/profile/${profileId}/student/${studentProfileId}`);
    }
  }

  handleRedirectStudentEditPage = (profileId) => {
    const {
      history: { push } = {},
    } = this.props;
    if (push) {
      push(`/app/campus/update/profile/${profileId}`);
    }
  }

  handleRedirectStudentRegPg = () => {
    const {
      history: { push } = {},
    } = this.props;
    if (push) {
      push('/app/campus/import/students');
    }
  }

  handleSubmitTriggerMatches = () => {
    const {
      match: {
        params: {
          id,
        } = {},
      } = {},
    } = this.props;
    const {
      instAllocNumber,
    } = this.state;
    let allocNumber = 5;
    if (instAllocNumber && instAllocNumber !== null && instAllocNumber !== undefined) {
      allocNumber = instAllocNumber;
    } else {
      allocNumber = 5;
    }
    const institutionId = id;
    const action = 'campus-score-calculation';
    this.handleTriggerMatching(institutionId, action, allocNumber);
  }

  handleTriggerMatching = (institutionId, action, allocNumber) => {
    this.setState({
      showProgressBar: true,
    }, () => {
      this.props.triggerJobProfileList(institutionId, action, allocNumber).then((res) => {
        if (res && !res.error) {
          this.setState({
            studentsCount: res && res.studentsCount,
            jobsCount: res && res.jobsCount,
          }, () => {
            const {
              taskId,
            } = this.props;
            const setInt = setInterval(() => {
              this.props.triggerStatusOfMatching(taskId).then((vals) => {
                if (vals && !vals.error && vals.task_state && vals.task_state === 'SUCCESS') {
                  clearInterval(setInt);
                  window.location.reload();
                  this.setState({
                    showProgressBar: false,
                  });
                }
              }).catch((e) => {
                console.log('Error', e);
                this.setState({
                  showProgressBar: false,
                });
              });
            }, 5000);
          });
        } else if (res && res.error) {
          this.setState({
            showNotifErr: true,
            errMsg: res && res.error && res.error.message,
          });
        }
      })
        .catch((e) => {
          console.log('Error', e);
          this.setState({
            showProgressBar: false,
          });
        });
    });
  }

  handleSubmitTriggerStdMtch = () => {
    const {
      match: {
        params: {
          id,
        } = {},
      } = {},
    } = this.props;
    const institutionId = id;
    const action = 'campus-choice-allocation';
    const {
      compAllocNumber,
    } = this.state;
    let allocNumber = 5;
    if (compAllocNumber && compAllocNumber !== null && compAllocNumber !== undefined) {
      allocNumber = compAllocNumber;
    } else {
      allocNumber = 5;
    }
    this.handleTriggerMatching(institutionId, action, allocNumber);
  }

  handleIsAdminToggleClick = () => {
    const {
      isAdmin,
    } = this.state;
    this.setState({
      isAdmin: !isAdmin,
    });
  }

  handleIsSuperAdminToggleClick = () => {
    const {
      isSuperAdmin,
    } = this.state;
    this.setState({
      isSuperAdmin: !isSuperAdmin,
    });
  }

  handleActiveToggleClick = () => {
    const {
      active,
    } = this.state;
    this.setState({
      active: !active,
    });
  }

  handleInactivityToggle = (e) =>{
    this.setState({inactivityStatus: !e});
  }

  handleCourseAdminToggleClick = () => {
    const {
      courseAdmin
    } = this.state;
    this.setState({
      courseAdmin: !courseAdmin,
    })
  }

  handleDownloadstudentsData = () => {
    const url = `${baseUrl}/studentprofile/csvexport?_=${new Date().getTime()}&`;
    this.setState({
      url,
    });
  }

  isDisabledToggleClick = () => {
    const {
      status,
    } = this.state;
    const {
      match: {
        params: {
          id,
        } = {},
      } = {},
    } = this.props;
    this.setState({
      status: !status,
    }, () => {
      const data = {
        status: this.state.status,
      };
      this.props.upsertCompanyDetailsById(data, id).then((res) => {
        if (res && !res.error) {
          this.props.getCompanyDetailsById(id);
        }
      });
    });
  }

  handleSubmitFacultySettings = () => {
    const {
      match: {
        params: {
          facultyId, id,
        } = {},
      } = {},
    } = this.props;
    const {
      totalJobsApplicable,
      cvTemplate,
      termsConditions,
      additionalDocLink,
      studentUploadDocs,
      questionConfig,
      // internshipStartDate,
      // internshipEndDate,
    } = this.state;
    let isValidCvTemplate = false;
    let isValidAddlDocs = false;
    if (!(isURL(cvTemplate))) {
      isValidCvTemplate = true;
    } else {
      isValidCvTemplate = false;
    }
    if (!(isURL(additionalDocLink))) {
      isValidAddlDocs = true;
    } else {
      isValidAddlDocs = false;
    }
    this.setState({
      isValidAddlDocs,
      isValidCvTemplate,
    }, () => {
      if (this.state.isValidAddlDocs !== true && this.state.isValidCvTemplate !== true) {
        const data = {
          totalJobsApplicable,
          cvTemplate,
          termsConditions,
          facultyId: facultyId && Number(facultyId),
          additionalDocLink,
          studentUploadDocs,
          questionConfig,
          schoolId: id && Number(id),
          // internshipStartDate,
          // internshipEndDate,
        };
        this.props.upsertFacultyDetailsById(data).then((res) => {
          if (res && !res.error) {
            this.setState({
              showFacultySetgsMsg: true,
            },()=>this.handleFacultyApiCall());
          }
        }).catch((e) => {
          console.log('Error:::::', e);
          this.setState({
            showFacultySetgsMsg: false,
          });
        });
      }
    });
  }

  handleAddInputValues = () => {
    this.setState({
      inputValues: this.state.inputValues.concat([{ name: '' }]),
    });
  }

  handleAddQuesInputValues = () => {
    this.setState({
      questInputValues: this.state.questInputValues.concat([{ name: '' }]),
    });
  }

  handleChangeInputValues = (e, index, type) => {
    if (type === 'upload') {
      this.setState({
        [`textInput_${index}`]: e.target.value,
        index,
      }, () => {
        if (this.state[`textInput_${index}`] === undefined
          || this.state[`textInput_${index}`] === null
          || this.state[`textInput_${index}`] === '') {
          this.setState({
            isValidTextInput: true,
          });
        } else {
          this.setState({
            isValidTextInput: false,
          });
        }
      });
    } else {
      this.setState({
        [`question-${index}`]: e.target.value,
        questionIndex: index,
      }, () => {
        if (this.state[`question-${index}`] === undefined
          || this.state[`question-${index}`] === null
          || this.state[`question-${index}`] === '') {
          this.setState({
            isValidQuestion: true,
          });
        } else {
          this.setState({
            isValidQuestion: false,
          });
        }
      });
    }
  }

  handleSelectItemChange = (e, index) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      this.setState({
        [`textInput_${index}`]: this.state[`docType-${index}`],
      });
    });
  };

  handleDeleteStdDocs = (typeId) => {
    let {
      studentUploadDocs,
    } = this.state;
    if (studentUploadDocs && Array.isArray(studentUploadDocs) && studentUploadDocs.length <= 0) {
      studentUploadDocs = [];
    } else if (studentUploadDocs && Array.isArray(studentUploadDocs) && studentUploadDocs.length > 0) {
      studentUploadDocs = studentUploadDocs.filter(res => res.typeId !== typeId);
    }
    this.setState({
      studentUploadDocs,
    });
  }

  handleDeleteQuesConfig = (uuid) => {
    let {
      questionConfig,
    } = this.state;
    if (questionConfig && Array.isArray(questionConfig) && questionConfig.length <= 0) {
      questionConfig = [];
    } else if (questionConfig && Array.isArray(questionConfig) && questionConfig.length > 0) {
      questionConfig = questionConfig.filter(res => res.uuid !== uuid);
    }
    this.setState({
      questionConfig,
    });
  }

  handleAddQuestVals = (idx) => {
    let {
      questionConfig,
    } = this.state;
    if (!this.state[`question-${idx}`]) {
      this.setState({
        isValidQuestion: true,
      });
    } else {
      this.setState({
        isValidQuestion: false,
      }, () => {
        if (!questionConfig || (questionConfig && Array.isArray(questionConfig) && questionConfig.length <= 0)) {
          questionConfig = [{ question: this.state[`question-${idx}`], questiontype: 'Subjective' }];
        } else {
          questionConfig.push({ question: this.state[`question-${idx}`], questiontype: 'Subjective' });
        }
        this.setState({
          questionConfig,
          questInputValues: [],
          [`question-${idx}`]: null,
        }, () => {
          const {
            questionConfig: updatedQues,
          } = this.state;
          if (updatedQues && Array.isArray(updatedQues) && updatedQues.length < 20) {
            this.handleAddQuesInputValues();
          }
        });
      });
    }
  }

  handleAddStudentDocs = (index) => {
    let {
      studentUploadDocs,
    } = this.state;
    const {
      typeId,
    } = this.state;
    const {
      documentTypes,
    } = this.props;
    if (!this.state[`docType-${index}`]) {
      this.setState({
        isValidSelectDropDown: true,
        isValidTextInput: false,
      });
    } else if (!this.state[`textInput_${index}`]) {
      this.setState({
        isValidTextInput: true,
        isValidSelectDropDown: false,
      });
    } else {
      this.setState({
        isValidSelectDropDown: false,
        isValidTextInput: false,
      }, () => {
        let workflowDocObj = {};
        if (documentTypes && Array.isArray(documentTypes) && documentTypes.length > 0) {
          workflowDocObj = documentTypes.find(res => res.typeName == this.state[`docType-${index}`]);
        }
        if ((studentUploadDocs && Array.isArray(studentUploadDocs) && studentUploadDocs.length <= 0) || !studentUploadDocs) {
          studentUploadDocs = [{ name: this.state[`textInput_${index}`], typeId: workflowDocObj && workflowDocObj.typeId, typeName: this.state[`docType-${index}`] }];
        } else {
          studentUploadDocs.push({ name: this.state[`textInput_${index}`], typeId: workflowDocObj && workflowDocObj.typeId, typeName: this.state[`docType-${index}`] });
        }
        this.setState({
          studentUploadDocs,
          [`docType-${index}`]: null,
          [`textInput_${index}`]: null,
          inputValues: [],
        }, () => {
          const {
            studentUploadDocs: updatedDocs,
          } = this.state;
          if (updatedDocs && Array.isArray(updatedDocs) && updatedDocs.length < 10) {
            this.handleAddInputValues();
          }
        });
      });
    }
  }

  handleDeleteInputValues = (index, type) => {
    if (type === 'upload') {
      const { inputValues } = this.state;
      const filteredValues = inputValues && Array.isArray(inputValues)
        && inputValues.length > 0
        && inputValues.filter((vals, idx) => idx != index);
      this.setState({
        inputValues: filteredValues,
      });
    } else {
      const { questInputValues } = this.state;
      const quesFilVals = questInputValues && Array.isArray(questInputValues)
        && questInputValues.length > 0
        && questInputValues.filter((vals, idx) => idx != index);
      this.setState({
        questInputValues: quesFilVals,
      });
    }
  }

  handleOpenMarketToggle = (isOpenMarket, studentProfileId) => {
    const data = {};
    if (isOpenMarket === null || isOpenMarket === false) {
      data.isOpenMarket = true;
    } else {
      data.isOpenMarket = false;
    }
    this.handleUpdateStudentProfileApiCall(data, studentProfileId);
  }

  handleUpdateStudentProfileApiCall = (data, studentProfileId) => {
    const {
      match: {
        params: {
          id, facultyId,
        } = {},
      } = {},
    } = this.props;
    this.props.updateStudentProfile(data, studentProfileId).then((res) => {
      if (res && !res.error) {
        this.setState({
          showLoader: true,
        }, () => {
          setTimeout(() => {
            this.handleStudentsApiCall();
            this.setState({
              showLoader: false,
            });
          }, 5000);
        });
      }
    }).catch(e => console.log('Error:::::', e));
  }

  handleToggleEligibility = (isEligible, studentProfileId) => {
    const data = {};
    if (isEligible === null || isEligible === false) {
      data.isEligible = true;
    } else {
      data.isEligible = false;
    }
    this.handleUpdateStudentProfileApiCall(data, studentProfileId);
  }

  activeStdsAttdModal = () => {
    this.setState({
      isOpenStudentAttModal: true,
    });
  }

  dismissStdsAttModal = () => {
    this.setState({
      isOpenStudentAttModal: false,
    });
  }

  activeStdsLoInfoModal = () => {
    this.setState({
      isOpenStudentLoInfoModal: true,
    });
  }

  dismissStdsLoInfoModal = () => {
    this.setState({
      isOpenStudentLoInfoModal: false,
    });
  }

  handleSubmitUploadModal = (element) => {
    const { files } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    let { index } = this.state;
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('excel', fileData[0]);
    this.props.uploadStudentsAttendance(currFile).then((res) => {
      if (res && !res.error) {
        this.dismissStdsAttModal();
        this.handleStudentsApiCall();
        this.setState({
          showSuccessMsg: true,
        });
      }
    }).catch(e => console.log('Error:::::', e));
  }

  handleSubmitUploadInfoModal = (element) => {
    const { files } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    let { index } = this.state;
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('excel', fileData[0]);
    this.props.uploadStudentsLoInfo(currFile).then((res) => {
      if (res && !res.error) {
        this.dismissStdsLoInfoModal();
        this.handleStudentsApiCall();
        this.setState({
          showSuccessMsg: true,
        });
      }
    }).catch(e => console.log('Error:::::', e));
  }

  handleUpsertCompanyAddlInfo = () => {
    const {
      termsConditions,
      otpLength,
      otpExpiry,
    } = this.state;
    let isOtpLengthValid = false;
    if (otpLength === undefined || otpLength === null || otpLength === '' || otpLength < 4 || otpLength > 10) {
      isOtpLengthValid = true;
    } else {
      isOtpLengthValid = false;
    }
    let isOtpExpiryValid = false;
    if (otpExpiry === undefined || otpExpiry === null || otpExpiry === '' || otpExpiry < 3) {
      isOtpExpiryValid = true;
    } else {
      isOtpExpiryValid = false;
    }


    this.setState({
      isOtpLengthValid,
      isOtpExpiryValid
    }, () => {
      if (this.state.isOtpLengthValid !== true && this.state.isOtpExpiryValid !== true) {
        const {
          match: {
            params: {
              id,
            } = {},
          } = {},
        } = this.props;
        const data = {
          companyId: id && Number(id),
          termsConditions,
        };
        this.setState({
          showLoader: true,
        }, () => {
          this.props.upsertCompanyAddlInfo(data);
          if (otpLength || otpExpiry) {
            const compInfoData = {
              companyId: id,
              otpLength,
              otpExpiry,
            };
            this.props.updateCompanyInfo(compInfoData).then((res) => {
              if (res && !res.error) {
                this.props.getCompanyInfo();
                this.setState({
                  showLoader: false,
                });
              }
            });
          }
        });
      }
    });
  }

  handleDateChange = (date) => {
    const [
      startPeriod,
      endPeriod,
    ] = date;
    this.setState({
      internshipStartDate: startPeriod && moment(startPeriod).format(),
      internshipEndDate: endPeriod && moment(endPeriod).format(),
    });
  }

  handleRedirectFacultyCourse = () => {
    const {
      history: {
        push,
      } = {},
      match: {
        params: {
          id, facultyId,
        } = {},
      } = {},
    } = this.props;
    if (push) {
      push(`/app/campus/institution/${id}/faculty/${facultyId}`);
    }
  }

  activeAcceptCompModal = (status) => {
    this.setState({
      isOpenAcceptCompanyModal: true,
      compStatus: status,
    });
  }

  dismissAcceptCompModal = () => {
    this.setState({
      isOpenAcceptCompanyModal: false,
      compStatus: '',
      showAccptRejBtns: false,
    });
  }

  activeRejectCompModal = (status) => {
    this.setState({
      isOpenRejectCompanyModal: true,
      compStatus: status,
    });
  }

  dismissRejectCompModal = () => {
    this.setState({
      isOpenRejectCompanyModal: false,
      compStatus: '',
      showAccptRejBtns: false,
    });
  }

  handleAcceptRejectManagedCompanies = () => {
    const {
      user: {
        companyId: currCompId,
      } = {},
      match: {
        params: {
          id,
        } = {},
      } = {},
    } = this.props;
    const {
      compStatus,
    } = this.state;
    const data = {
      parentCompanyId: id,
      childCompanyId: currCompId,
      status: compStatus,
    };
    this.props.authorizeCompany(data).then((res) => {
      if (res && !res.error) {
        this.dismissAcceptCompModal();
        this.dismissRejectCompModal();
        this.props.getCompanyDetailsById(id).then((dets) => {
          if (dets && !dets.error) {
            this.handleUsersApiCall();
          }
        });
      }
    });
  }

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    });
  };

  handleShowAccRejBtns = () => {
    const {
      showAccptRejBtns,
    } = this.state;
    this.setState({
      showAccptRejBtns: !showAccptRejBtns,
    });
  }

  handleViewNotification = (data) => {
    const { userId: id } = data;
    this.props.history.push(`/app/campus/notifications/${id}`);
  }

  render() {

    const {
      match: {
        params: {
          id: institutionId,
          status: paramStatus,
          facultyId: paramFacultyId,
        } = {},
      } = {},
      location: { pathname },
      companyDetails,
      history,
      t,
      loading,
      companyDetails: {
        companyName,
        displayCompanyName,
        ctypeId,
        companymap: {
          status: compAccRejStatus,
        } = {},
      } = {},
      facultyData,
      coursesData,
      usersList,
      usersCount,
      countries,
      studentsCount,
      students,
      facultyCount,
      candidateDetailsLoading,
      user: {
        isAdmin: propAdmin,
        isSuperAdmin: propSuperAdmin,
        courseAdmin: propCourseAdmin,
        company: {
          ctypeId: userCtypeId,
        } = {},
        userId: currUserId,
        facultyId: loggedInUsrFacId,
        courseId: userCourseId
      } = {},
      documentTypes,
      compLoading,
    } = this.props;
    let editSettings = true;
    if (loggedInUsrFacId && propAdmin === true && !paramFacultyId && propSuperAdmin !== true) {
      editSettings = false;
    }
    let isCurrFaculty = false;

    if (loggedInUsrFacId && propAdmin === true && !paramFacultyId && propSuperAdmin !== true && userCourseId == null) {
      isCurrFaculty = true;
    }
    if ((loggedInUsrFacId == paramFacultyId) || propSuperAdmin === true) {
      isCurrFaculty = true;
    }
    const {
      selectedTab, selectedSection, multiValueProps, isOpenFacultyModal,
      isValidFacultyName, isValidFacultyNameText,
      isOpenUserModal,
      isEmailValid, isEmailValidText, isFirstNameValid,
      isFirstNameValidText, isLastNameValid, isLastNameValidText,
      isPhoneNumberValid, isPhoneNumberValidText,
      isValidCountryCode, isValidCountryCodeValue, usersActivePage,
      countryCode, phoneNumber, isValidDesignationVal, studentsActivePage, studentsItemsPerPage,
      isValidDesignation, designation, email: currStateEmail, firstname: currStateFirstName, lastname: currStateLastName,
      isOpenFacultyDeleteModal, facultyId, facultyActivePage,
      isAdmin, showUserErrNotif, saluationListArr,
      isSuperAdmin, userId, additionalDocLink, showAccptRejBtns,
      active, showProgressBar, showNotifErr, errMsg,
      studentsCount: stateStudentsCount, jobsCount, url,
      isValidAllocNumb, isValidAllocNumbText, instAllocNumber,
      compAllocNumber, isValidCompAllocNumb, isValidCompAllocNumbText,
      showInstSaveMsg, showStdSaveMsg, status, termsConditions, cvTemplate,
      totalJobsApplicable, isEligible, isOpenStudentAttModal, showSuccessMsg,
      studentUploadDocs, inputValues, questionConfig, userFacultyId, isValidUserFaculty,
      otpExpiry, otpLength, isOpenAcceptCompanyModal, isOpenRejectCompanyModal,
      isOtpLengthValid, isOtpExpiryValid, showLoader, isValidAddlDocs, isValidCvTemplate,
      showFacultySetgsMsg, courseAdmin, courseId,
      usersItemsPerPage,
      isOpenUserJobTransferModal, selectedUserValue, selectedUserToTransferJob, isTransferJobUserInvalid,
      isTransferJobUserInvalidText, isOpenInactiveModal, inactiveModalMsg, isJobTransferUnsuccessfull,
      inactivityStatus, isOpenStudentLoInfoModal
    } = this.state;

    let finalFacultyData = {};
    if (facultyData && Array.isArray(facultyData) && facultyData.length > 0) {
      finalFacultyData = facultyData.find(res => res.facultyId == paramFacultyId);
    }

    const tabContent = [
      {
        label: `${t('users')}`,
        value: '',
        onClick: () => { this.handleChangeTabClick('users'); },
      },
      {
        label: `${t('students')}`,
        value: '',
        onClick: () => { this.handleChangeTabClick('students'); },
      },
      // {
      //   label: `${t('studentJobMatching')}`,
      //   value: '',
      //   onClick: () => { this.handleChangeTabClick('matches'); },
      // },
      // {
      //   label: `${t('calendar')} ${t('settings')}`,
      //   value: '',
      //   onClick: () => { this.handleChangeTabClick('calendar'); },
      // },
      // {
      //   label: `${t('interviewSlots')}`,
      //   value: '',
      //   onClick: () => { this.handleChangeTabClick('interview-slots'); },
      // },
      {
        label: `${t('logAttendance')}`,
        value: '',
        onClick: () => { this.handleChangeTabClick('import'); },
      },
    ];
    if (!paramStatus || (paramStatus !== 'faculty')) {
      tabContent.push({
        label: `${t('faculty')}`,
        value: '',
        onClick: () => { this.handleChangeTabClick('faculty'); },
      }, {
        label: `${t('settings')}`,
        value: '',
        onClick: () => { this.handleChangeTabClick('comp-settings'); },
      });
      tabContent.push({
        label: `${t('Batch Management')}`,
        value: '',
        onClick: () => { this.handleChangeTabClick('batch-management'); },
      })
    }
    if (paramStatus === 'faculty') {
      tabContent.push({
        label: `${t('settings')}`,
        value: '',
        onClick: () => { this.handleChangeTabClick('settings'); },
      });
      tabContent.push({
        label: `${t('assignLiaisonOfficer')}`,
        value: '',
        onClick: () => { this.handleChangeTabClick('assignLiaisonOfficer'); }
      })
    }


    const columnWithFilter = [
      {
        title: `${t('faculty')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    const otherActionButtons = [];
    if (propSuperAdmin === true) {
      otherActionButtons.push(
        <ToolbarItem>
          <Button
            className="mr-2"
            onClick={() => this.activeFacultyModal(null, null)}
            kind="primary"
          >
            {t('add')} {t('faculty')}
          </Button>
        </ToolbarItem>,
      );
    }


    return (
      <Fragment>
        {
          ctypeId === 1 && (
            <Fragment>
              <BackRow paths={this.compPaths} />
            </Fragment>
          )
        }
        {
          ctypeId === 2 && !paramFacultyId && (
            <Fragment>
              <BackRow paths={this.instPaths} />
            </Fragment>
          )
        }
        {
          ctypeId === 2 && paramFacultyId && (
            <Fragment>
              <BackRow paths={this.facultyPaths} />
            </Fragment>
          )
        }
        { ((propSuperAdmin === true && propAdmin === true) || (parseInt(userCtypeId) !== 1 && propAdmin === true)) &&
          <div className="institutions-layout">
            <Layout
              sideContent={
                <Facets>
                  {multiValueProps &&
                    multiValueProps.map((Multiprop) => (
                      <SingleLinksFacet {...{ ...Multiprop }} />
                    ))}
                </Facets>
              }
              bodyContent={
                <div>
                  {selectedSection === 'basicInformation' && (
                    paramStatus && paramStatus === 'faculty' ? (
                      <Fragment>
                        <div className="ml-3">
                          <div className="h6 mb-4 text-uppercase">
                            {finalFacultyData && finalFacultyData.facultyName}
                          </div>
                          {
                            (loggedInUsrFacId == paramFacultyId || propSuperAdmin === true) && (
                              <div className="bx--row align-items-center mb-4">
                                <div className="bx--col-lg-2 bx--type-zeta text-capitalize">
                                  {t('courses')}
                                </div>
                                <div>
                                  <Button
                                    kind="tertiary"
                                    onClick={this.handleRedirectFacultyCourse}
                                    size="small"
                                  >
                                    {t('view')} {t('courses')}
                                  </Button>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="h6 mb-2 text-uppercase">
                          {displayCompanyName}
                        </div>
                        <div className="mb-2">
                          <ProfileUpdateDetails history={history} companyDetails={companyDetails} editSettings={editSettings} />
                          <hr className="mt-2 mb-2" />
                          {
                            ctypeId && Number(ctypeId) === 1
                            && editSettings
                            && (
                              <div>
                                <div className="d-flex align-items-center ml-1">
                                  <div className="bx--col-lg-2 bx--col-md-3 bx--col-sm-3">
                                    <span className="">{t('disableCompany')}</span>
                                  </div>
                                  <div className="ml-4 pl-lg-3">
                                    <Toggle
                                      id="toggle-11111"
                                      onToggle={this.isDisabledToggleClick}
                                      toggled={!status}
                                      labelA="No"
                                      labelB="Yes"
                                    />
                                  </div>
                                </div>
                                <hr className="mb-0" />
                                {
                                  editSettings
                                  && (
                                    <div className="d-flex align-items-center ml-1 mt-2">
                                      <div className="bx--col-lg-2 bx--col-md-3 bx--col-sm-3">
                                        {t('compRegApprStatus')}
                                      </div>
                                      <div>
                                        <div className="d-lg-flex d-md-flex ml-4 pl-lg-3 mb-2">
                                          <div>
                                            {
                                              compAccRejStatus !== 'Pending' ? (
                                                <div>
                                                  {
                                                    compAccRejStatus === 'Accepted'
                                                    && (
                                                      <span className="bx--tag bx--tag--green">{compAccRejStatus}</span>
                                                    )
                                                  }
                                                  {
                                                    compAccRejStatus === 'Rejected'
                                                    && (
                                                      <span className="bx--tag bx--tag--red">{compAccRejStatus}</span>
                                                    )
                                                  }
                                                </div>
                                              ) : (
                                                <span className="bx--tag bx--tag--cool-gray">{t('invitesPendingTab')}</span>
                                              )
                                            }
                                          </div>
                                          <div>
                                            <Button
                                              kind="ghost"
                                              size="small"
                                              onClick={this.handleShowAccRejBtns}
                                            >
                                              {t('changeStatus')}
                                            </Button>
                                          </div>
                                        </div>
                                        {
                                          showAccptRejBtns && (
                                            <div className="d-flex ml-4 pl-3">
                                              <div>
                                                <Button
                                                  kind="primary"
                                                  small
                                                  onClick={() => {
                                                    this.activeAcceptCompModal(
                                                      'Accepted'
                                                    );
                                                  }}>
                                                  {t('accept')}
                                                </Button>
                                              </div>
                                              <div className="ml-2">
                                                <Button
                                                  kind="danger"
                                                  small
                                                  onClick={() => {
                                                    this.activeRejectCompModal(
                                                      'Rejected'
                                                    );
                                                  }}>
                                                  {t('reject')}
                                                </Button>
                                              </div>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </div>
                                  )
                                }
                                <hr />
                              </div>
                            )
                          }
                        </div>
                        {pathname.includes("/company/info") &&
                          <UsersView
                            isCompanyInfo
                            isOpenUserJobTransferModal={isOpenUserJobTransferModal}
                            handleCompanyUserActiveToggle={this.handleCompanyUserActiveToggle}
                            dismissUserJobTransferModal={this.dismissUserJobTransferModal}
                            handleSubmitUserJobTransfer={this.handleSubmitUserJobTransfer}
                            userChange={this.userChange}
                            selectedUserValue={selectedUserValue}
                            selectedUserToTransferJob={selectedUserToTransferJob}
                            isTransferJobUserInvalid={isTransferJobUserInvalid}
                            isTransferJobUserInvalidText={isTransferJobUserInvalidText}
                            handleSubmitInactiveModal={this.handleSubmitInactiveModal}
                            dismissInactiveModal={this.dismissInactiveModal}
                            isJobTransferUnsuccessfull={isJobTransferUnsuccessfull}
                            isOpenInactiveModal={isOpenInactiveModal}
                            inactiveModalMsg={inactiveModalMsg}
                            inactivityStatus={inactivityStatus}
                            handleInactivityToggle={this.handleInactivityToggle}
                            usersItemsPerPage={usersItemsPerPage}
                            userCount={usersCount}
                            usersList={usersList}
                            usersCount={usersCount}
                            activeUsersModal={this.activeUsersModal}
                            isOpenUserModal={isOpenUserModal}
                            dismissUserModal={this.dismissUserModal}
                            propCompanyName={companyName}
                            handleChange={this.handleChange}
                            handleViewNotification={this.handleViewNotification}
                            firstname={currStateFirstName}
                            lastname={currStateLastName}
                            disabled
                            secondaryphone={this.state.secondaryphone}
                            push={this.props.history && this.props.history.push}
                            editSettings={editSettings}
                            isCurrFaculty={isCurrFaculty}
                            handleDropdownChange={this.handleDropdownChange}
                            saluationVal={this.state.saluationVal}
                            hideUserEditOpt={(compAccRejStatus === 'Pending' && ctypeId && Number(ctypeId) === 1) ? true : false}
                            paramFacultyId={paramFacultyId}
                            isFirstNameValid={isFirstNameValid}
                            isFirstNameValidText={isFirstNameValidText}
                            propAdmin={propAdmin}
                            propSuperAdmin={propSuperAdmin}
                            propCourseAdmin={propCourseAdmin}
                            isLastNameValid={isLastNameValid}
                            isLastNameValidText={isLastNameValidText}
                            isEmailValid={isEmailValid}
                            email={currStateEmail}
                            isEmailValidText={isEmailValidText}
                            countryCode={countryCode}
                            isValidCountryCode={isValidCountryCode}
                            countries={countries}
                            saluationListArr={saluationListArr}
                            isValidCountryCodeValue={isValidCountryCodeValue}
                            phoneNumber={phoneNumber}
                            isPhoneNumberValid={isPhoneNumberValid}
                            isPhoneNumberValidText={isPhoneNumberValidText}
                            designation={designation}
                            isValidDesignation={isValidDesignation}
                            isValidDesignationVal={isValidDesignationVal}
                            usersActivePage={usersActivePage}
                            handleUsersPaginationChange={this.handleUsersPaginationChange}
                            handleSearchUsersData={this.handleSearchUsersData}
                            isAdmin={isAdmin}
                            userFacultyId={userFacultyId}
                            isValidUserFaculty={isValidUserFaculty}
                            showUserErrNotif={showUserErrNotif}
                            isSuperAdmin={isSuperAdmin}
                            active={active}
                            courseAdmin={courseAdmin}
                            handleIsAdminToggleClick={this.handleIsAdminToggleClick}
                            handleIsSuperAdminToggleClick={this.handleIsSuperAdminToggleClick}
                            handleActiveToggleClick={this.handleActiveToggleClick}
                            handleCourseAdminToggleClick={this.handleCourseAdminToggleClick}
                            handleSubmitUserDetails={this.handleSubmitUserDetails}
                            userId={userId}
                            isSaluationValid={this.state.isSaluationValid}
                            facultyData={facultyData}
                            coursesData={coursesData}
                            ctypeId={ctypeId}
                            userCtypeId={userCtypeId}
                            currUserId={currUserId}
                            schoolId={institutionId}
                            courseId={courseId}
                          />
                        }
                      </Fragment>
                    )
                  )}
                  {
                    selectedSection === 0 && (
                      <UsersView
                        inactivityStatus={inactivityStatus}
                        handleInactivityToggle={this.handleInactivityToggle}
                        usersItemsPerPage={usersItemsPerPage}
                        userCount={usersCount}
                        usersList={usersList}
                        usersCount={usersCount}
                        activeUsersModal={this.activeUsersModal}
                        isOpenUserModal={isOpenUserModal}
                        dismissUserModal={this.dismissUserModal}
                        propCompanyName={companyName}
                        handleChange={this.handleChange}
                        firstname={currStateFirstName}
                        lastname={currStateLastName}
                        disabled
                        secondaryphone={this.state.secondaryphone}
                        push={this.props.history && this.props.history.push}
                        // editSettings={editSettings}
                        isCurrFaculty={isCurrFaculty}
                        handleDropdownChange={this.handleDropdownChange}
                        saluationVal={this.state.saluationVal}
                        hideUserEditOpt={(compAccRejStatus === 'Pending' && ctypeId && Number(ctypeId) === 1) ? true : false}
                        paramFacultyId={paramFacultyId}
                        isFirstNameValid={isFirstNameValid}
                        isFirstNameValidText={isFirstNameValidText}
                        propAdmin={propAdmin}
                        propSuperAdmin={propSuperAdmin}
                        isLastNameValid={isLastNameValid}
                        isLastNameValidText={isLastNameValidText}
                        isEmailValid={isEmailValid}
                        email={currStateEmail}
                        isEmailValidText={isEmailValidText}
                        countryCode={countryCode}
                        isValidCountryCode={isValidCountryCode}
                        countries={countries}
                        saluationListArr={saluationListArr}
                        isValidCountryCodeValue={isValidCountryCodeValue}
                        phoneNumber={phoneNumber}
                        isPhoneNumberValid={isPhoneNumberValid}
                        isPhoneNumberValidText={isPhoneNumberValidText}
                        designation={designation}
                        isValidDesignation={isValidDesignation}
                        isValidDesignationVal={isValidDesignationVal}
                        usersActivePage={usersActivePage}
                        handleUsersPaginationChange={this.handleUsersPaginationChange}
                        handleSearchUsersData={this.handleSearchUsersData}
                        isAdmin={isAdmin}
                        userFacultyId={userFacultyId}
                        isValidUserFaculty={isValidUserFaculty}
                        showUserErrNotif={showUserErrNotif}
                        isSuperAdmin={isSuperAdmin}
                        active={active}
                        courseAdmin={courseAdmin}
                        handleIsAdminToggleClick={this.handleIsAdminToggleClick}
                        handleIsSuperAdminToggleClick={this.handleIsSuperAdminToggleClick}
                        handleActiveToggleClick={this.handleActiveToggleClick}
                        handleCourseAdminToggleClick={this.handleCourseAdminToggleClick}
                        handleSubmitUserDetails={this.handleSubmitUserDetails}
                        userId={userId}
                        isSaluationValid={this.state.isSaluationValid}
                        facultyData={facultyData}
                        coursesData={coursesData}
                        ctypeId={ctypeId}
                        userCtypeId={userCtypeId}
                        currUserId={currUserId}
                        schoolId={institutionId}
                        courseId={courseId}
                      />
                    )
                  }
                  {
                    selectedSection === 3 && paramStatus !== 'faculty' && (
                      <FacultyView
                        facultyData={facultyData}
                        columnWithFilter={columnWithFilter}
                        otherActionButtons={otherActionButtons}
                        handleRedirectToFacultyViewPage={this.handleRedirectToFacultyViewPage}
                        activeFacultyModal={this.activeFacultyModal}
                        isOpenFacultyDeleteModal={isOpenFacultyDeleteModal}
                        propAdmin={propAdmin}
                        propSuperAdmin={propSuperAdmin}
                        activeFacultyDeleteModal={this.activeFacultyDeleteModal}
                        deleteFacultyDeleteModal={this.deleteFacultyDeleteModal}
                        handleSubmitFacultyDeleteModal={this.handleSubmitFacultyDeleteModal}
                        handleSearchFacultyData={this.handleSearchFacultyData}
                        handleFacultyPaginationChange={this.handleFacultyPaginationChange}
                        facultyActivePage={facultyActivePage}
                        facultyCount={facultyCount}
                        userFacultyId={loggedInUsrFacId}
                      />
                    )
                  }
                  {
                    selectedSection === 1 && (
                      <StudentsView
                        students={students}
                        studentsItemsPerPage={studentsItemsPerPage}
                        studentsCount={studentsCount}
                        propAdmin={propAdmin}
                        propSuperAdmin={propSuperAdmin}
                        isCurrFaculty={isCurrFaculty}
                        handleRedirectProfilePage={this.handleRedirectProfilePage}
                        handleRedirectStudentEditPage={this.handleRedirectStudentEditPage}
                        handleStudentsPaginationChange={this.handleStudentsPaginationChange}
                        studentsActivePage={studentsActivePage}
                        handleSearchStudentsData={this.handleSearchStudentsData}
                        handleRedirectStudentRegPg={this.handleRedirectStudentRegPg}
                        handleToggleEligibility={this.handleToggleEligibility}
                        handleOpenMarketToggle={this.handleOpenMarketToggle}
                        isEligible={isEligible}
                        handleViewNotification={this.handleViewNotification}
                      />
                    )
                  }

                  {
                    selectedSection === 5 && (
                      <BatchManagementView />
                    )
                  }
                  {selectedSection === 6 &&
                    (
                      <div className="ml-2">
                        {
                          showNotifErr === true && (
                            <InlineNotification lowContrast
                              className="mt-1 mb-1"
                              title=""
                              iconDescription="describes the close button"
                              kind="error"
                              subtitle={errMsg}
                              onCloseButtonClick={() => { this.setState({ showNotifErr: false })}}
                            />
                          )
                        }
                        <div className="text-capitalize h6 mb-2">{companyName}</div>
                        <hr className="mt-2 mb-2" />
                        {
                          stateStudentsCount && stateStudentsCount !== null
                            && (
                              <div>
                                <span className="">{t('students')} {t('count')}: </span>{stateStudentsCount}
                              </div>
                            )
                        }
                        {
                          jobsCount && jobsCount !== null
                            && (
                              <div>
                                <span className="">{t('jobs')} {t('count')}:</span> {jobsCount}
                              </div>
                            )
                        }
                        <div className="font-weight-bold text-uppercase mb-2">
                          {t('institution')} {t('matching')}
                        </div>
                        <div className="text-dark">
                          {t('triggerMatchingMsg')} <b>{t('jobMatches')}.</b>
                        </div>
                        <div className="mt-1 font-weight-bold">
                          {t('triggerMatchingMsg1')}
                        </div>
                        <div className="ml-3">
                          {t('jobMatchesMsg2')}
                        </div>
                        <div className="ml-3">
                          {t('jobMatchesMsg3')}
                        </div>
                        <div className="mt-2">
                          <a target="_blank" href="https://drive.google.com/file/d/1Lc6RDGDiABti6O33QrGIJbMuecZ12Gq8/view?usp=sharing">
                            <b className="xpa-link text-capitalize">
                              {t('clickHere')}
                            </b>&nbsp;
                          </a>
                          {t('jobMatchesMsg4')}
                        </div>
                        <div className="d-flex mb-2 mt-1 align-items-center">
                          <div>
                            <TextInput
                              name="instAllocNumber"
                              id="instAllocNumber"
                              labelText={`${t('allocNumber')}`}
                              value={instAllocNumber}
                              placeholder="5"
                              invalid={isValidAllocNumb}
                              invalidText={isValidAllocNumbText}
                              onChange={e => this.handleChange(e)}
                            />
                          </div>
                          <div className="ml-2 mt-4">
                            <Button
                              kind="primary"
                              onClick={this.handleSubmitCompanyAllocNumb}
                            >
                              {t('save')}
                            </Button>
                          </div>
                        </div>
                        {
                          showInstSaveMsg === true && (
                            <div style={{ color: 'green' }} className="mt-2 mb-2">
                              <i className="fa fa-check-circle-o" aria-hidden="true" />&nbsp;&nbsp;
                              {t('saved')}
                            </div>
                          )
                        }
                        <div className="mb-2 mt-2">
                          <Button
                            kind="tertiary"
                            small
                            disabled={showProgressBar}
                            onClick={this.handleSubmitTriggerMatches}
                          >
                            {t('triggerInstMatching')}
                          </Button>
                        </div>
                        <hr className="mt-2 mb-2" />
                        <div className="font-weight-bold text-uppercase mb-2">
                          {t('student')} {t('matching')}
                        </div>
                        <div className="text-dark">
                          {t('triggerMatchingMsg2')} <b>{t('studentPreferred')}.</b>
                        </div>
                        <div className="mt-2 font-weight-bold">
                          {t('triggerMatchingMsg3')}
                        </div>
                        <div className="mt-2">
                          <a target="_blank" href="https://drive.google.com/file/d/1Lc6RDGDiABti6O33QrGIJbMuecZ12Gq8/view?usp=sharing">
                            <b className="xpa-link text-capitalize">
                              {t('clickHere')}
                            </b>&nbsp;
                          </a>
                          {t('jobMatchesMsg4')}
                        </div>
                        <div className="d-flex mb-2 mt-1 align-items-center">
                          <div>
                            <TextInput
                              name="compAllocNumber"
                              id="compAllocNumber"
                              labelText={`${t('allocNumber')}`}
                              value={compAllocNumber}
                              placeholder="5"
                              invalid={isValidCompAllocNumb}
                              invalidText={isValidCompAllocNumbText}
                              onChange={e => this.handleChange(e)}
                            />
                          </div>
                          <div className="ml-2 mt-4">
                            <Button
                              kind="primary"
                              onClick={this.handleSubmitCompAllocNumb}
                            >
                              {t('save')}
                            </Button>
                          </div>
                        </div>
                        {
                          showStdSaveMsg === true && (
                            <div style={{ color: 'green' }} className="mt-2 mb-2">
                              <i className="fa fa-check-circle-o" aria-hidden="true" />&nbsp;&nbsp;
                              {t('saved')}
                            </div>
                          )
                        }
                        <div className="mt-2 mb-2">
                          <Button
                            kind="tertiary"
                            small
                            disabled={showProgressBar}
                            onClick={this.handleSubmitTriggerStdMtch}
                          >
                            {t('triggerStdMatching')}
                          </Button>
                        </div>
                        <div className="mt-2">
                          {
                            showProgressBar === true && (
                              <Progress
                                percent={50}
                              />
                            )
                          }
                        </div>
                      </div>
                    )
                  }

                  {/* {
                  selectedTab === 2 && (
                    <div>
                      {
                        showNotifErr === true && (
                          <InlineNotification lowContrast
                            className="mt-1 mb-1"
                            title=""
                            iconDescription="describes the close button"
                            kind="error"
                            subtitle={errMsg}
                            onCloseButtonClick={() => { this.setState({ showNotifErr: false })}}
                          />
                        )
                      }
                      <div><span className="bx--modal-header__label">{t('institution')} {t('name')}:</span> <b className="text-capitalize text-dark pl-2">{companyName}</b></div>
                      <hr className="mt-1 mb-2" />
                      {
                        stateStudentsCount && stateStudentsCount !== null
                          && (
                            <div>
                              <span className="bx--modal-header__label">{t('students')} {t('count')}: </span>{stateStudentsCount}
                            </div>
                          )
                      }
                      {
                        jobsCount && jobsCount !== null
                          && (
                            <div>
                              <span className="bx--modal-header__label">{t('jobs')} {t('count')}:</span> {jobsCount}
                            </div>
                          )
                      }
                      <div className="h5 font-weight-bold mb-2">
                        {t('institution')} {t('matching')}
                      </div>
                      <div className="text-dark">
                        {t('triggerMatchingMsg')} <b>{t('jobMatches')}.</b>
                      </div>
                      <div className="mt-1 font-weight-bold">
                        {t('triggerMatchingMsg1')}
                      </div>
                      <div className="ml-3">
                        {t('jobMatchesMsg2')}
                      </div>
                      <div className="ml-3">
                        {t('jobMatchesMsg3')}
                      </div>
                      <div className="mt-2">
                        <a target="_blank" href="https://drive.google.com/file/d/1Lc6RDGDiABti6O33QrGIJbMuecZ12Gq8/view?usp=sharing">
                          <b className="xpa-link text-capitalize">
                            {t('clickHere')}
                          </b>&nbsp;
                        </a>
                        {t('jobMatchesMsg4')}
                      </div>
                      <div className="d-flex mb-2 mt-1 align-items-center">
                        <div>
                          <TextInput
                            name="instAllocNumber"
                            id="instAllocNumber"
                            labelText={`${t('allocNumber')}`}
                            value={instAllocNumber}
                            placeholder="5"
                            invalid={isValidAllocNumb}
                            invalidText={isValidAllocNumbText}
                            onChange={e => this.handleChange(e)}
                          />
                        </div>
                        <div className="ml-2 mt-4">
                          <Button
                            kind="primary"
                            onClick={this.handleSubmitCompanyAllocNumb}
                          >
                            {t('save')}
                          </Button>
                        </div>
                      </div>
                      {
                        showInstSaveMsg === true && (
                          <div style={{ color: 'green' }} className="mt-2 mb-2">
                            <i className="fa fa-check-circle-o" aria-hidden="true" />&nbsp;&nbsp;
                            {t('saved')}
                          </div>
                        )
                      }
                      <div className="mb-2 mt-2">
                        <Button
                          kind="tertiary"
                          small
                          disabled={showProgressBar}
                          onClick={this.handleSubmitTriggerMatches}
                        >
                          {t('triggerInstMatching')}
                        </Button>
                      </div>
                      <hr className="mt-2 mb-2" />
                      <div className="h5 font-weight-bold mb-2">
                        {t('student')} {t('matching')}
                      </div>
                      <div className="text-dark">
                        {t('triggerMatchingMsg2')} <b>{t('studentPreferred')}.</b>
                      </div>
                      <div className="mt-2 font-weight-bold">
                        {t('triggerMatchingMsg3')}
                      </div>
                      <div className="mt-2">
                        <a target="_blank" href="https://drive.google.com/file/d/1Lc6RDGDiABti6O33QrGIJbMuecZ12Gq8/view?usp=sharing">
                          <b className="xpa-link text-capitalize">
                            {t('clickHere')}
                          </b>&nbsp;
                        </a>
                        {t('jobMatchesMsg4')}
                      </div>
                      <div className="d-flex mb-2 mt-1 align-items-center">
                        <div>
                          <TextInput
                            name="compAllocNumber"
                            id="compAllocNumber"
                            labelText={`${t('allocNumber')}`}
                            value={compAllocNumber}
                            placeholder="5"
                            invalid={isValidCompAllocNumb}
                            invalidText={isValidCompAllocNumbText}
                            onChange={e => this.handleChange(e)}
                          />
                        </div>
                        <div className="ml-2 mt-4">
                          <Button
                            kind="primary"
                            onClick={this.handleSubmitCompAllocNumb}
                          >
                            {t('save')}
                          </Button>
                        </div>
                      </div>
                      {
                        showStdSaveMsg === true && (
                          <div style={{ color: 'green' }} className="mt-2 mb-2">
                            <i className="fa fa-check-circle-o" aria-hidden="true" />&nbsp;&nbsp;
                            {t('saved')}
                          </div>
                        )
                      }
                      <div className="mt-2 mb-2">
                        <Button
                          kind="tertiary"
                          small
                          disabled={showProgressBar}
                          onClick={this.handleSubmitTriggerStdMtch}
                        >
                          {t('triggerStdMatching')}
                        </Button>
                      </div>
                      <div className="mt-2">
                        {
                          showProgressBar === true && (
                            <Progress
                              percent={50}
                            />
                          )
                        }
                      </div>
                    </div>
                  )
                } */}
                  {/* {
                selectedTab === 3 && (
                  <CalendarSettings institutionId={institutionId} />
                )
              }
              {
                selectedTab === 5 && (
                  <InterviewSlots institutionId={institutionId} />
                )
              } */}
                  {/* {
                selectedTab === 3 && (
                  <div>
                    <div className="bx--type-zeta mb-2 text-capitalize">{t('exportMsg')}</div>
                    <Button
                      onClick={this.handleDownloadstudentsData}
                      small
                      kind="primary"
                    >
                      <a className="xpa-link-icon button-link" target="_blank" href={url}><span>{t('download')}</span> <i className="fa fa-download" aria-hidden="true" /></a>
                    </Button>
                  </div>
                )
              } */}
                  {
                    selectedSection === 2 && (
                      <div className="mb-5">
                        <div className="h6 mb-2">
                          {t('logStdAttendance')}
                        </div>
                        {
                          isCurrFaculty ? (
                            <div>
                              <div className="mb-2">
                                {t('logAttMsg')}
                              </div>
                              {
                                showSuccessMsg === true && (
                                  <InlineNotification
                                    lowContrast
                                    kind="success"
                                    className=""
                                    title=""
                                    subtitle={t('uploadedSuccessfulMsg')}
                                    onCloseButtonClick={() => { }}
                                  />
                                )
                              }
                              <div className="d-flex">
                                <div>
                                  <Button
                                    kind="primary"
                                    size="small"
                                    onClick={this.activeStdsAttdModal}
                                  >
                                    {t('uploadFile')}
                                  </Button>
                                </div>
                                <div className="ml-2">
                                  <Button
                                    kind="ghost"
                                    size="small"
                                    onClick={() => { }}
                                  >
                                    <a style={{ textDecoration: 'none' }} target="_blank" href={`/roboroy/api/v1/companies/downloadAttendance?instituteId=${institutionId}&${paramFacultyId ? `facultyId=${paramFacultyId}&` : ''}`}>
                                      {t('download')} {t('template')}
                                    </a>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-center h6">
                              {t('noAccess')}
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  {
                    selectedSection === 3 && paramStatus === 'faculty' && (
                      <div>
                        <div className="h6 mt-2 mb-2">
                          {t('facultySettings')}
                        </div>
                        {
                          showFacultySetgsMsg === true && (
                            <InlineNotification
                              lowContrast
                              kind="success"
                              className=""
                              title=""
                              subtitle={t('stgsSuccessMsg')}
                              onCloseButtonClick={() => { this.setState({ showFacultySetgsMsg: null }); }}
                            />
                          )
                        }
                        {
                          showFacultySetgsMsg === false && (
                            <InlineNotification
                              lowContrast
                              kind="error"
                              className=""
                              title=""
                              subtitle={t('processingError')}
                              onCloseButtonClick={() => { this.setState({ showFacultySetgsMsg: null }); }}
                            />
                          )
                        }
                        {
                          isCurrFaculty ? (
                            <div>
                              <FacultySettings
                                handleChange={this.handleChange}
                                totalJobsApplicable={totalJobsApplicable}
                                cvTemplate={cvTemplate}
                                termsConditions={termsConditions}
                                additionalDocLink={additionalDocLink}
                                documentTypes={documentTypes}
                                studentUploadDocs={studentUploadDocs}
                                handleAddInputValues={this.handleAddInputValues}
                                handleChangeInputValues={this.handleChangeInputValues}
                                handleDeleteInputValues={this.handleDeleteInputValues}
                                handleAddStudentDocs={this.handleAddStudentDocs}
                                handleAddQuestVals={this.handleAddQuestVals}
                                handleSelectItemChange={this.handleSelectItemChange}
                                handleDeleteStdDocs={this.handleDeleteStdDocs}
                                state={this.state}
                                isValidCvTemplate={isValidCvTemplate}
                                isValidAddlDocs={isValidAddlDocs}
                                inputValues={inputValues}
                                handleAddQuesInputValues={this.handleAddQuesInputValues}
                                questInputValues={this.state.questInputValues}
                                handleAddQuestVals={this.handleAddQuestVals}
                                handleDeleteQuesConfig={this.handleDeleteQuesConfig}
                                handleDateChange={this.handleDateChange}
                                questionConfig={questionConfig}
                              />
                              <div className="text-center mt-3">
                                <Button
                                  kind="primary"
                                  onClick={this.handleSubmitFacultySettings}
                                >
                                  {t('submit')}
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="text-center h6">
                              {t('noAccess')}
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  {
                    selectedSection === 4 && paramStatus === 'faculty' && (
                      <div className="mb-5">
                        <div className="h6 mb-2">
                          {t('assignLiaisonOfficer')}
                        </div>
                        {
                          isCurrFaculty ? (
                            <div>
                              <div className="mb-2">
                                {t('loMsg')}
                              </div>
                              {
                                showSuccessMsg === true && (
                                  <InlineNotification
                                    lowContrast
                                    kind="success"
                                    className=""
                                    title=""
                                    subtitle={t('uploadedSuccessfulMsg')}
                                    onCloseButtonClick={() => { }}
                                  />
                                )
                              }
                              <div className="d-flex">
                                <div>
                                  <Button
                                    kind="primary"
                                    size="small"
                                    onClick={this.activeStdsLoInfoModal}
                                  >
                                    {t('uploadFile')}
                                  </Button>
                                </div>
                                <div className="ml-2">
                                  <Button
                                    kind="ghost"
                                    size="small"
                                    onClick={() => { }}
                                  >
                                    <a style={{ textDecoration: 'none' }} target="_blank" href={`/roboroy/api/v1/companies/downloadStudentLOInfo?instituteId=${institutionId}&_=${new Date().getTime()}&${paramFacultyId ? `facultyId=${paramFacultyId}&` : ''}`}>
                                      {t('download')} {t('template')}
                                    </a>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-center h6">
                              {t('noAccess')}
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  {
                    selectedSection === 4 && !paramStatus && (
                      <div>
                        <div className="h6 mb-2">
                          {t('settings')}
                        </div>
                        <hr />
                        {
                          propSuperAdmin === true && (
                            <div>
                              <div className="mb-2 h6">
                                {t('company')} {t('registration')}
                              </div>
                              <div className="bx--row align-items-center mt-2">
                                <div className="bx--type-zeta bx--col-lg-3">
                                  {t('termsOfUse')}
                                </div>
                                <div className="bx--col-lg-5">
                                  <TextArea
                                    className="mb-2"
                                    name="termsConditions"
                                    id="termsConditions"
                                    hideLabel
                                    labelText={t('termsConditions')}
                                    value={termsConditions}
                                    placeholder={t('termsOfUse')}
                                    onChange={e => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                              <div className="mb-2 h6">
                                OTP {t('settings')}
                              </div>
                              <div className="bx--row align-items-center mt-2">
                                <div className="bx--type-zeta bx--col-lg-3">
                                  {t('expiryTime')}
                                </div>
                                <div className="bx--col-lg-5">
                                  <TextInput
                                    className="mb-2"
                                    name="otpExpiry"
                                    id="otpExpiry"
                                    hideLabel
                                    invalid={isOtpExpiryValid}
                                    invalidText={t('otpExpiryValidMsg')}
                                    labelText={t('expiryTime')}
                                    value={otpExpiry}
                                    placeholder="3"
                                    onChange={e => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                              <div className="bx--row align-items-center mt-2">
                                <div className="bx--type-zeta bx--col-lg-3">
                                  {t('otpLength')}
                                </div>
                                <div className="bx--col-lg-5">
                                  <TextInput
                                    className="mb-2"
                                    name="otpLength"
                                    id="otpLength"
                                    hideLabel
                                    invalid={isOtpLengthValid}
                                    invalidText={t('otpLengthValidMsg')}
                                    labelText={t('otpLength')}
                                    value={otpLength}
                                    placeholder="4"
                                    onChange={e => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                              <div>
                                <Button
                                  kind="primary"
                                  onClick={this.handleUpsertCompanyAddlInfo}
                                >
                                  {t('submit')}
                                </Button>
                              </div>
                            </div>
                          )
                        }
                        {
                          propSuperAdmin !== true && (
                            <div className="h6 mt-2">
                              {t('noAccessToWebPage')}
                            </div>
                          )
                        }
                      </div>
                    )
                  }

                </div>
              }
            />
          </div>
        }




        {/* {
          paramStatus && paramStatus === 'faculty'
            ? (
              <Fragment>
                <div className="ml-3">
                  <div className="h6 mb-4 text-uppercase">
                    {finalFacultyData && finalFacultyData.facultyName}
                  </div>
                  {
                    (loggedInUsrFacId == paramFacultyId || propSuperAdmin === true) && (
                      <div className="bx--row align-items-center mb-4">
                        <div className="bx--col-lg-2 bx--type-zeta text-capitalize">
                          {t('courses')}
                        </div>
                        <div>
                          <Button
                            kind="tertiary"
                            onClick={this.handleRedirectFacultyCourse}
                            size="small"
                          >
                            {t('view')} {t('courses')}
                          </Button>
                        </div>
                      </div>
                    )
                  }
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="h6 mb-2 text-uppercase">
                  {companyName}
                </div>
                <div className="mb-2">
                  <ProfileUpdateDetails history={history} companyDetails={companyDetails} editSettings={editSettings} />
                  <hr className="mt-2 mb-2" />
                  {
                    ctypeId && Number(ctypeId) === 1
                    && editSettings
                    && (
                      <div>
                        <div className="d-flex align-items-center ml-1">
                          <div className="bx--col-lg-2 bx--col-md-3 bx--col-sm-3">
                            <span className="">{t('disableCompany')}</span>
                          </div>
                          <div className="ml-4 pl-lg-3">
                            <Toggle
                              id="toggle-11111"
                              onToggle={this.isDisabledToggleClick}
                              toggled={!status}
                              labelA="No"
                              labelB="Yes"
                            />
                          </div>
                        </div>
                        <hr className="mb-0" />
                        {
                          editSettings
                          && (
                            <div className="d-flex align-items-center ml-1 mt-2">
                              <div className="bx--col-lg-2 bx--col-md-3 bx--col-sm-3">
                                {t('compRegApprStatus')}
                              </div>
                              <div>
                                <div className="d-lg-flex d-md-flex ml-4 pl-lg-3 mb-2">
                                  <div>
                                    {
                                      compAccRejStatus !== 'Pending' ? (
                                        <div>
                                          {
                                            compAccRejStatus === 'Accepted'
                                            && (
                                              <span className="bx--tag bx--tag--green">{compAccRejStatus}</span>
                                            )
                                          }
                                          {
                                            compAccRejStatus === 'Rejected'
                                            && (
                                              <span className="bx--tag bx--tag--red">{compAccRejStatus}</span>
                                            )
                                          }
                                        </div>
                                      ) : (
                                          <span className="bx--tag bx--tag--cool-gray">{t('invitesPendingTab')}</span>
                                        )
                                    }
                                  </div>
                                  <div>
                                    <Button
                                      kind="ghost"
                                      size="small"
                                      onClick={this.handleShowAccRejBtns}
                                    >
                                      {t('changeStatus')}
                                    </Button>
                                  </div>
                                </div>
                                {
                                  showAccptRejBtns && (
                                    <div className="d-flex ml-4 pl-3">
                                      <div>
                                        <Button
                                          kind="primary"
                                          small
                                          onClick={() => {
                                            this.activeAcceptCompModal(
                                              'Accepted'
                                            );
                                          }}>
                                          {t('accept')}
                                        </Button>
                                      </div>
                                      <div className="ml-2">
                                        <Button
                                          kind="danger"
                                          small
                                          onClick={() => {
                                            this.activeRejectCompModal(
                                              'Rejected'
                                            );
                                          }}>
                                          {t('reject')}
                                        </Button>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          )
                        }
                        <hr />
                      </div>
                    )
                  }
                </div>
              </Fragment>
            )
        }
        {
          ctypeId && Number(ctypeId) === 2
          && (
            <Fragment>
              <div className="mb-2">
                <GenericTabs
                  selected={selectedTab}
                  tabContent={tabContent}
                />
              </div>
            </Fragment>
          )
        }
        {
          selectedTab === 0 && (
            <UsersView
              usersList={usersList}
              usersCount={usersCount}
              activeUsersModal={this.activeUsersModal}
              isOpenUserModal={isOpenUserModal}
              dismissUserModal={this.dismissUserModal}
              propCompanyName={companyName}
              handleChange={this.handleChange}
              firstname={currStateFirstName}
              lastname={currStateLastName}
              disabled
              secondaryphone={this.state.secondaryphone}
              push={this.props.history && this.props.history.push}
              editSettings={editSettings}
              isCurrFaculty={isCurrFaculty}
              handleDropdownChange={this.handleDropdownChange}
              saluationVal={this.state.saluationVal}
              hideUserEditOpt={(compAccRejStatus === 'Pending' && ctypeId && Number(ctypeId) === 1) ? true : false}
              paramFacultyId={paramFacultyId}
              isFirstNameValid={isFirstNameValid}
              isFirstNameValidText={isFirstNameValidText}
              propAdmin={propAdmin}
              propSuperAdmin={propSuperAdmin}
              isLastNameValid={isLastNameValid}
              isLastNameValidText={isLastNameValidText}
              isEmailValid={isEmailValid}
              email={currStateEmail}
              isEmailValidText={isEmailValidText}
              countryCode={countryCode}
              isValidCountryCode={isValidCountryCode}
              countries={countries}
              saluationListArr={saluationListArr}
              isValidCountryCodeValue={isValidCountryCodeValue}
              phoneNumber={phoneNumber}
              isPhoneNumberValid={isPhoneNumberValid}
              isPhoneNumberValidText={isPhoneNumberValidText}
              designation={designation}
              isValidDesignation={isValidDesignation}
              isValidDesignationVal={isValidDesignationVal}
              usersActivePage={usersActivePage}
              handleUsersPaginationChange={this.handleUsersPaginationChange}
              handleSearchUsersData={this.handleSearchUsersData}
              isAdmin={isAdmin}
              userFacultyId={userFacultyId}
              isValidUserFaculty={isValidUserFaculty}
              showUserErrNotif={showUserErrNotif}
              isSuperAdmin={isSuperAdmin}
              active={active}
              courseAdmin={courseAdmin}
              handleIsAdminToggleClick={this.handleIsAdminToggleClick}
              handleIsSuperAdminToggleClick={this.handleIsSuperAdminToggleClick}
              handleActiveToggleClick={this.handleActiveToggleClick}
              handleCourseAdminToggleClick={this.handleCourseAdminToggleClick}
              handleSubmitUserDetails={this.handleSubmitUserDetails}
              userId={userId}
              isSaluationValid={this.state.isSaluationValid}
              facultyData={facultyData}
              coursesData={coursesData}
              ctypeId={ctypeId}
              userCtypeId={userCtypeId}
              currUserId={currUserId}
              schoolId={institutionId}
              courseId={courseId}
            />
          )
        }
        {
          selectedTab === 3 && paramStatus !== 'faculty' && (
            <FacultyView
              facultyData={facultyData}
              columnWithFilter={columnWithFilter}
              otherActionButtons={otherActionButtons}
              handleRedirectToFacultyViewPage={this.handleRedirectToFacultyViewPage}
              activeFacultyModal={this.activeFacultyModal}
              isOpenFacultyDeleteModal={isOpenFacultyDeleteModal}
              propAdmin={propAdmin}
              propSuperAdmin={propSuperAdmin}
              activeFacultyDeleteModal={this.activeFacultyDeleteModal}
              deleteFacultyDeleteModal={this.deleteFacultyDeleteModal}
              handleSubmitFacultyDeleteModal={this.handleSubmitFacultyDeleteModal}
              handleSearchFacultyData={this.handleSearchFacultyData}
              handleFacultyPaginationChange={this.handleFacultyPaginationChange}
              facultyActivePage={facultyActivePage}
              facultyCount={facultyCount}
              userFacultyId={loggedInUsrFacId}
            />
          )
        }
        {
          selectedTab === 1 && (
            <StudentsView
              students={students}
              studentsCount={studentsCount}
              propAdmin={propAdmin}
              propSuperAdmin={propSuperAdmin}
              isCurrFaculty={isCurrFaculty}
              handleRedirectProfilePage={this.handleRedirectProfilePage}
              handleRedirectStudentEditPage={this.handleRedirectStudentEditPage}
              handleStudentsPaginationChange={this.handleStudentsPaginationChange}
              studentsActivePage={studentsActivePage}
              handleSearchStudentsData={this.handleSearchStudentsData}
              handleRedirectStudentRegPg={this.handleRedirectStudentRegPg}
              handleToggleEligibility={this.handleToggleEligibility}
              handleOpenMarketToggle={this.handleOpenMarketToggle}
              isEligible={isEligible}
            />
          )
        }

        {
          selectedTab === 5 && (
            <BatchManagementView
              batchDetails={batchDetails}
              currentBatchValue={currentBatchValue}

            />
          )
        } */}
        {/* {
          selectedTab === 2 && (
            <div>
              {
                showNotifErr === true && (
                  <InlineNotification lowContrast
                    className="mt-1 mb-1"
                    title=""
                    iconDescription="describes the close button"
                    kind="error"
                    subtitle={errMsg}
                    onCloseButtonClick={() => { this.setState({ showNotifErr: false })}}
                  />
                )
              }
              <div><span className="bx--modal-header__label">{t('institution')} {t('name')}:</span> <b className="text-capitalize text-dark pl-2">{companyName}</b></div>
              <hr className="mt-1 mb-2" />
              {
                stateStudentsCount && stateStudentsCount !== null
                  && (
                    <div>
                      <span className="bx--modal-header__label">{t('students')} {t('count')}: </span>{stateStudentsCount}
                    </div>
                  )
              }
              {
                jobsCount && jobsCount !== null
                  && (
                    <div>
                      <span className="bx--modal-header__label">{t('jobs')} {t('count')}:</span> {jobsCount}
                    </div>
                  )
              }
              <div className="h5 font-weight-bold mb-2">
                {t('institution')} {t('matching')}
              </div>
              <div className="text-dark">
                {t('triggerMatchingMsg')} <b>{t('jobMatches')}.</b>
              </div>
              <div className="mt-1 font-weight-bold">
                {t('triggerMatchingMsg1')}
              </div>
              <div className="ml-3">
                {t('jobMatchesMsg2')}
              </div>
              <div className="ml-3">
                {t('jobMatchesMsg3')}
              </div>
              <div className="mt-2">
                <a target="_blank" href="https://drive.google.com/file/d/1Lc6RDGDiABti6O33QrGIJbMuecZ12Gq8/view?usp=sharing">
                  <b className="xpa-link text-capitalize">
                    {t('clickHere')}
                  </b>&nbsp;
                </a>
                {t('jobMatchesMsg4')}
              </div>
              <div className="d-flex mb-2 mt-1 align-items-center">
                <div>
                  <TextInput
                    name="instAllocNumber"
                    id="instAllocNumber"
                    labelText={`${t('allocNumber')}`}
                    value={instAllocNumber}
                    placeholder="5"
                    invalid={isValidAllocNumb}
                    invalidText={isValidAllocNumbText}
                    onChange={e => this.handleChange(e)}
                  />
                </div>
                <div className="ml-2 mt-4">
                  <Button
                    kind="primary"
                    onClick={this.handleSubmitCompanyAllocNumb}
                  >
                    {t('save')}
                  </Button>
                </div>
              </div>
              {
                showInstSaveMsg === true && (
                  <div style={{ color: 'green' }} className="mt-2 mb-2">
                    <i className="fa fa-check-circle-o" aria-hidden="true" />&nbsp;&nbsp;
                    {t('saved')}
                  </div>
                )
              }
              <div className="mb-2 mt-2">
                <Button
                  kind="tertiary"
                  small
                  disabled={showProgressBar}
                  onClick={this.handleSubmitTriggerMatches}
                >
                  {t('triggerInstMatching')}
                </Button>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="h5 font-weight-bold mb-2">
                {t('student')} {t('matching')}
              </div>
              <div className="text-dark">
                {t('triggerMatchingMsg2')} <b>{t('studentPreferred')}.</b>
              </div>
              <div className="mt-2 font-weight-bold">
                {t('triggerMatchingMsg3')}
              </div>
              <div className="mt-2">
                <a target="_blank" href="https://drive.google.com/file/d/1Lc6RDGDiABti6O33QrGIJbMuecZ12Gq8/view?usp=sharing">
                  <b className="xpa-link text-capitalize">
                    {t('clickHere')}
                  </b>&nbsp;
                </a>
                {t('jobMatchesMsg4')}
              </div>
              <div className="d-flex mb-2 mt-1 align-items-center">
                <div>
                  <TextInput
                    name="compAllocNumber"
                    id="compAllocNumber"
                    labelText={`${t('allocNumber')}`}
                    value={compAllocNumber}
                    placeholder="5"
                    invalid={isValidCompAllocNumb}
                    invalidText={isValidCompAllocNumbText}
                    onChange={e => this.handleChange(e)}
                  />
                </div>
                <div className="ml-2 mt-4">
                  <Button
                    kind="primary"
                    onClick={this.handleSubmitCompAllocNumb}
                  >
                    {t('save')}
                  </Button>
                </div>
              </div>
              {
                showStdSaveMsg === true && (
                  <div style={{ color: 'green' }} className="mt-2 mb-2">
                    <i className="fa fa-check-circle-o" aria-hidden="true" />&nbsp;&nbsp;
                    {t('saved')}
                  </div>
                )
              }
              <div className="mt-2 mb-2">
                <Button
                  kind="tertiary"
                  small
                  disabled={showProgressBar}
                  onClick={this.handleSubmitTriggerStdMtch}
                >
                  {t('triggerStdMatching')}
                </Button>
              </div>
              <div className="mt-2">
                {
                  showProgressBar === true && (
                    <Progress
                      percent={50}
                    />
                  )
                }
              </div>
            </div>
          )
        } */}
        {/* {
          selectedTab === 3 && (
            <CalendarSettings institutionId={institutionId} />
          )
        }
        {
          selectedTab === 5 && (
            <InterviewSlots institutionId={institutionId} />
          )
        } */}
        {/* {
          selectedTab === 3 && (
            <div>
              <div className="bx--type-zeta mb-2 text-capitalize">{t('exportMsg')}</div>
              <Button
                onClick={this.handleDownloadstudentsData}
                small
                kind="primary"
              >
                <a className="xpa-link-icon button-link" target="_blank" href={url}><span>{t('download')}</span> <i className="fa fa-download" aria-hidden="true" /></a>
              </Button>
            </div>
          )
        } */}
        {/* {
          selectedTab === 2 && (
            <div className="mb-5">
              <div className="h6 mb-2">
                {t('logStdAttendance')}
              </div>
              {
                isCurrFaculty ? (
                  <div>
                    <div className="mb-2">
                      {t('logAttMsg')}
                    </div>
                    {
                      showSuccessMsg === true && (
                        <InlineNotification
                          lowContrast
                          kind="success"
                          className=""
                          title=""
                          subtitle={t('uploadedSuccessfulMsg')}
                          onCloseButtonClick={() => { }}
                        />
                      )
                    }
                    <div className="d-flex">
                      <div>
                        <Button
                          kind="primary"
                          size="small"
                          onClick={this.activeStdsAttdModal}
                        >
                          {t('uploadFile')}
                        </Button>
                      </div>
                      <div className="ml-2">
                        <Button
                          kind="ghost"
                          size="small"
                          onClick={() => { }}
                        >
                          <a style={{ textDecoration: 'none' }} target="_blank" href={`/roboroy/api/v1/companies/downloadAttendance?instituteId=${institutionId}&${paramFacultyId ? `facultyId=${paramFacultyId}&` : ''}`}>
                            {t('download')} {t('template')}
                          </a>
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className="text-center h6">
                      {t('noAccess')}
                    </div>
                  )
              }
            </div>
          )
        }
        {
          selectedTab === 3 && paramStatus === 'faculty' && (
            <div>
              <div className="h6 mt-2 mb-2">
                {t('facultySettings')}
              </div>
              {
                showFacultySetgsMsg === true && (
                  <InlineNotification
                    lowContrast
                    kind="success"
                    className=""
                    title=""
                    subtitle={t('stgsSuccessMsg')}
                    onCloseButtonClick={() => { this.setState({ showFacultySetgsMsg: null }); }}
                  />
                )
              }
              {
                showFacultySetgsMsg === false && (
                  <InlineNotification
                    lowContrast
                    kind="error"
                    className=""
                    title=""
                    subtitle={t('processingError')}
                    onCloseButtonClick={() => { this.setState({ showFacultySetgsMsg: null }); }}
                  />
                )
              }
              {
                isCurrFaculty ? (
                  <div>
                    <FacultySettings
                      handleChange={this.handleChange}
                      totalJobsApplicable={totalJobsApplicable}
                      cvTemplate={cvTemplate}
                      termsConditions={termsConditions}
                      additionalDocLink={additionalDocLink}
                      documentTypes={documentTypes}
                      studentUploadDocs={studentUploadDocs}
                      handleAddInputValues={this.handleAddInputValues}
                      handleChangeInputValues={this.handleChangeInputValues}
                      handleDeleteInputValues={this.handleDeleteInputValues}
                      handleAddStudentDocs={this.handleAddStudentDocs}
                      handleAddQuestVals={this.handleAddQuestVals}
                      handleSelectItemChange={this.handleSelectItemChange}
                      handleDeleteStdDocs={this.handleDeleteStdDocs}
                      state={this.state}
                      isValidCvTemplate={isValidCvTemplate}
                      isValidAddlDocs={isValidAddlDocs}
                      inputValues={inputValues}
                      handleAddQuesInputValues={this.handleAddQuesInputValues}
                      questInputValues={this.state.questInputValues}
                      handleAddQuestVals={this.handleAddQuestVals}
                      handleDeleteQuesConfig={this.handleDeleteQuesConfig}
                      handleDateChange={this.handleDateChange}
                      questionConfig={questionConfig}
                    />
                    <div className="text-center mt-3">
                      <Button
                        kind="primary"
                        onClick={this.handleSubmitFacultySettings}
                      >
                        {t('submit')}
                      </Button>
                    </div>
                  </div>
                ) : (
                    <div className="text-center h6">
                      {t('noAccess')}
                    </div>
                  )
              }
            </div>
          )
        }
        {
          selectedTab === 4 && !paramStatus && (
            <div>
              <div className="h6 mb-2">
                {t('settings')}
              </div>
              <hr />
              {
                propSuperAdmin === true && (
                  <div>
                    <div className="mb-2 h6">
                      {t('company')} {t('registration')}
                    </div>
                    <div className="bx--row align-items-center mt-2">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('termsOfUse')}
                      </div>
                      <div className="bx--col-lg-5">
                        <TextArea
                          className="mb-2"
                          name="termsConditions"
                          id="termsConditions"
                          hideLabel
                          labelText={t('termsConditions')}
                          value={termsConditions}
                          placeholder={t('termsOfUse')}
                          onChange={e => this.handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="mb-2 h6">
                      OTP {t('settings')}
                    </div>
                    <div className="bx--row align-items-center mt-2">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('expiryTime')}
                      </div>
                      <div className="bx--col-lg-5">
                        <TextInput
                          className="mb-2"
                          name="otpExpiry"
                          id="otpExpiry"
                          hideLabel
                          labelText={t('expiryTime')}
                          value={otpExpiry}
                          placeholder="4"
                          onChange={e => this.handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="bx--row align-items-center mt-2">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('otpLength')}
                      </div>
                      <div className="bx--col-lg-5">
                        <TextInput
                          className="mb-2"
                          name="otpLength"
                          id="otpLength"
                          hideLabel
                          invalid={isOtpLengthValid}
                          invalidText={t('otpLengthValidMsg')}
                          labelText={t('otpLength')}
                          value={otpLength}
                          placeholder="4"
                          onChange={e => this.handleChange(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <Button
                        kind="primary"
                        onClick={this.handleUpsertCompanyAddlInfo}
                      >
                        {t('submit')}
                      </Button>
                    </div>
                  </div>
                )
              }
              {
                propSuperAdmin !== true && (
                  <div className="h6 mt-2">
                    {t('noAccessToWebPage')}
                  </div>
                )
              }
            </div>
          )
        } */}
        <Modal
          open={isOpenFacultyModal}
          modalHeading={`${facultyId ? `${t('edit')}` : `${t('add')}`} ${t('faculty')}`}
          modalLabel={`${t('faculty')}`}
          primaryButtonText={facultyId ? `${t('edit')}` : `${t('add')}`}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissFacultyModal}
          onRequestSubmit={this.handleSubmitFacultyDetails}
        >
          <TextInput
            className="mb-2"
            name="facultyName"
            id="facultyName"
            labelText={`${t('faculty')} ${t('name')}`}
            value={this.state.facultyName}
            placeholder={`${t('faculty')} ${t('name')}`}
            invalid={isValidFacultyName}
            invalidText={isValidFacultyNameText}
            onChange={e => this.handleChange(e)}
          />
        </Modal>
        <Modal
          onRequestSubmit={this.handleSubmitUploadModal}
          onRequestClose={this.dismissStdsAttModal}
          open={isOpenStudentAttModal}
          passiveModal
          modalLabel=""
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading={<div className="text-capitalize">{t('upload')}</div>}
        >
          <div>
            <div className="mb-2 mt-2">
              {t('importStudentsMsg')}
            </div>
            <FileUploaderButton
              accept={['.xls', '.xlsx', '.XLS', '.XLSX']}
              buttonKind="primary"
              className="bob"
              labelText={`${t('upload')}`}
              name="index"
              onChange={this.handleSubmitUploadModal}
              role="button"
              tabIndex={0}
            />
          </div>
        </Modal>
        <Modal
          onRequestSubmit={this.handleSubmitUploadInfoModal}
          onRequestClose={this.dismissStdsLoInfoModal}
          open={isOpenStudentLoInfoModal}
          passiveModal
          modalLabel=""
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading={<div className="text-capitalize">{t('upload')}</div>}
        >
          <div>
            <div className="mb-2 mt-2">
              {t('importStudentsMsg')}
            </div>
            <FileUploaderButton
              accept={['.xls', '.xlsx', '.XLS', '.XLSX']}
              buttonKind="primary"
              className="bob"
              labelText={`${t('upload')}`}
              name="index"
              onChange={this.handleSubmitUploadInfoModal}
              role="button"
              tabIndex={0}
            />
          </div>
        </Modal>
        <Modal
          open={isOpenAcceptCompanyModal}
          shouldSubmitOnEnter
          modalHeading={t('accept')}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissAcceptCompModal}
          onRequestSubmit={this.handleAcceptRejectManagedCompanies}
        >
          <div className="text-dark text-left">
            {t('acceptManagedCompMsg')}
          </div>
        </Modal>
        <Modal
          open={isOpenRejectCompanyModal}
          shouldSubmitOnEnter
          modalHeading={t('reject')}
          danger
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissRejectCompModal}
          onRequestSubmit={this.handleAcceptRejectManagedCompanies}
        >
          <div className="text-dark text-left">
            {t('rejectManagedCompMsg')}
          </div>
        </Modal>
        <JDProcessOverlay show={loading || candidateDetailsLoading || showLoader || compLoading || this.props.studentLoader || this.props.browseStdsLoader} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.InstitutionView.loading,
  companyDetails: state.InstitutionView.companyDetails,
  facultyData: state.candidateDetails.facultyData,
  facultyCount: state.candidateDetails.facultyCount,
  usersList: state.InstitutionView.usersList,
  usersCount: state.InstitutionView.usersCount,
  countries: state.companyAndOffice.countries,
  students: state.BrowseStudents.students,
  studentsCount: state.BrowseStudents.count,
  candidateDetailsLoading: state.candidateDetails.loading,
  taskId: state.InstitutionView.taskId,
  taskState: state.InstitutionView.taskState,
  documentTypes: state.UploadDocuments.docs,
  saluationList: state.CompanyRegistration.saluationList,
  compLoading: state.companyAndOffice.loading,
  studentLoader: state.StudentProfile.loading,
  browseStdsLoader: state.BrowseStudents.loading,
  coursesData: state.candidateDetails.coursesData
});

const mapDispatchToProps = {
  getEmployersJobInfo,
  performJobTransfer,
  getCompanyDetailsById,
  getAllFaculties,
  getAllCountries,
  getCompanyUsersList,
  getStudentsListById,
  createFacultyDetails,
  deleteFacultyDetailsById,
  upsertFacultyDetailsById,
  triggerJobProfileList,
  upsertUserInfoDetails,
  createUserForACompany,
  triggerStatusOfMatching,
  getCompanyInfo,
  updateCompanyInfo,
  upsertCompanyDetailsById,
  updateStudentProfile,
  uploadStudentsAttendance,
  getDocumentTypes,
  upsertCompanyAddlInfo,
  authorizeCompany,
  getSaluationList,
  getStudentsList,
  getAllCourses,
  uploadStudentsLoInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InstitutionView));
