import {
  REQUEST_ALL_PUBLIC_JOBS,
  SUCCESS_ALL_PUBLIC_JOBS,
  ERROR_ALL_PUBLIC_JOBS,
  REQUEST_ADD_JOBTO_PROFILE,
  SUCCESS_ADD_JOBTO_PROFILE,
} from '../../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  count:0,
  Jobs:[],
};

const BrowseJobs = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case REQUEST_ALL_PUBLIC_JOBS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ALL_PUBLIC_JOBS:{
      return {
        ...state,
        loading: false,
        error: null,
        Jobs:payload.jobs,
        count:payload.count,
      };
    }
    case ERROR_ALL_PUBLIC_JOBS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ADD_JOBTO_PROFILE: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    };
    case SUCCESS_ADD_JOBTO_PROFILE: {
      return {
        ...state,
        loading: false,
        error: false,
        profileAdded: payload,
      };
    };
    default:
      return state;
  }
};

export default BrowseJobs;