import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import {
  getJobInfo, closeJob, cloneJob, reopenJob,
  downloadJD, associateLinkedinJob, statusOfJob,
  getJobTimeLine,
} from 'actions/talentSearch/jobList';
import LeftNavigationList from 'components/Header/LeftNavigationList';
import { getRecommendedJobs } from 'actions/candidate/dashboard';
import CarbonIconsHeader from 'components/roboroy/CarbonIconsHeader';
import {
  associateJd,
} from 'actions/talentSearch/newJob';
import JobInfoSkeleton from 'components/roboroy/JobInfoSkeleton';
import NavRightList from 'components/Header/NavRightList';
import SubHeader from 'components/Header/SubHeader';
import ProductTour from 'components/common/ProductTour/ProductTour';
import BackRow from 'components/common/BackRow/BackRow';
import HeaderTabs from 'components/common/HeaderTabs';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import JobInfoContainer from 'components/roboroy/jobInfo/jobInfoContainer';
import JobNotFound from 'components/roboroy/jobInfo/jobNotFound';
import EditJob from 'components/roboroy/jobInfo/editJob';
import { Modal, TextInput, TextArea, Select, SelectItem } from 'carbon-components-react';
import { selectedApplicationIndex } from 'utils/common';
import { shareCV } from 'actions/talentSearch/candidatesList';
import LeftNavigation from 'components/Header/LeftNavigation';
import Toast from 'components/common/Toast';
import SetMixPanel from 'components/common/GetCurrentPath';
import { Tile, Button, InlineNotification } from 'carbon-components-react';
import findAllUserJobApplicants from 'actions/talentSearch/applicantInfo';
import {
  getOfferDetails,
} from 'actions/offerInformation';

import {
  getWorkflowStages,
  getAllUserWorkflow,
} from 'actions/workflow';

import { fetchUserLoginState } from 'actions/userstate';
import {
  getCandidateProfile,
  getJobAppliedCount,
} from 'actions/talentSearch/candidatesList';
import {
  getAuthorizedUsers,
  getJobCollaborators,
  addJobCollaborator,
  removeJobCollaborator,
  getAuthorizedUsersForCollaborators
} from 'actions/job';
import HeaderWithActions from 'components/Header/HeaderWithActions';
import SideNavList from 'components/Header/SideNav';
import {
  Header,
  HeaderMenuButton,
  SkipToContent,
  HeaderName,
} from 'carbon-components-react/lib/components/UIShell';
import {
  getJobApprovalList,
  editJobApprovalList,
} from 'actions/talentSearch/JobApprovals';
import geipImage from 'assets/images/republic-polytechnic-white.png';
// redux
import './JobInfo.css';
import Search from 'carbon-components-react/lib/components/Search';
import { getBatchDetails } from 'actions/BatchManagementView';

class JobInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAnalyticsModule: false,
      loading: false,
      searchTerm: '',
      activeModal: false,
      activeJob: null,
      isOpenApplyJobModal: false,
      isOpenAppliedJobModal: false,
      isOpenCloneJobModal: false,
      isOpenReopenJobModal: false,
      isOpenCloseJobModal: false,
      isOpenAssociateJobModal: false,
      isOpenEmailJobModal: false,
      isOpenToggleModal: false,
      linkedinJobid: '',
      isLinkedinIdValid: false,
      isPrivate: true,
      isAutoPilot: false,
      isAutoShortlist: false,
      isAutoPilotModal: false,
      invalidText: '',
      inValidMessage: '',
      productTourIsOpen: false,
      emailShare: '',
      message: '',
      isEmailIdValid: false,
      isEmailTextValid: false,
      closeJobMessage: '',
      displayMobileSidebar: false,
      isAISourcingEnabled: false,
      activeSelectedTab: 0,
      finlandProfileId: null,
      showSideBar: false,
      showSubItems: false,
      label: '',
      isSideNavExpanded: false,
    };
    this.closeTour = this.closeTour.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeTour() {
    this.setState({ productTourIsOpen: false });
  }

  activateModal = (job) => {
    this.props.changeModalJob(job);
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  activateApplyToJobModal = () => {
    // console.log('Open Apply Job Modal');
    this.setState({
      isOpenApplyJobModal: true,
    });
  }

  activateCloneJobModal = () => {
    // console.log('Open Apply Job Modal');
    this.setState({
      isOpenCloneJobModal: true,
    });
  }
  activateCloseJobModal = () => {
    // console.log('Open Apply Job Modal');
    this.setState({
      isOpenCloseJobModal: true,
    });
  }
  activateReopenJobModal = () => {
    // console.log('Open Apply Job Modal');
    this.setState({
      isOpenReopenJobModal: true,
    });
  }

  activateAssociateJobModal = () => {
    this.setState({
      isOpenAssociateJobModal: true,
    });
  }

  activeEmailJobModal = () => {
    this.setState({
      isOpenEmailJobModal: true,
    });
  }

  activeToggleModal = () => {
    this.setState({
      isOpenToggleModal: true,
    })
  }

  dismissToggleModal = () => {
    this.setState({
      isOpenToggleModal: false,
    });
  }

  dismissEmailJobModal = () => {
    const { isEmailIdValid } = this.state;
    if (isEmailIdValid) {
      this.setState({
        emailShare: '',
        isEmailIdValid: false,
        invalidText: '',
      });
    }
    this.setState({
      isOpenEmailJobModal: false,
    });
  }

  dismissAssociateJobModal = () => {
    const {
      jobs = [],
    } = this.props;
    const [job = {}] = jobs;
    const {
      linkedinJobId,
    } = job;
    const { isLinkedinIdValid } = this.state;
    if (isLinkedinIdValid) {
      this.setState({
        linkedinJobid: linkedinJobId,
        isLinkedinIdValid: false,
        invalidText: '',
      });
    }
    this.setState({
      isOpenAssociateJobModal: false,
    });
  }

  dismissApplyTobJobModal = () => {
    // console.log('Close Apply Job Modal');
    this.setState({
      isOpenApplyJobModal: false,
    });
  }
  dismissCloneJobModal = () => {
    // console.log('Close Apply Job Modal');
    this.setState({
      isOpenCloneJobModal: false,
      selectedBatchVal: null,
      selectedBatchValInvalid: false
    });
  }
  dismissCloseJobModal = () => {
    // console.log('Close Apply Job Modal');
    this.setState({
      isOpenCloseJobModal: false,
    });
  }
  dismissAppliedTobJobModal = () => {
    // console.log('Close Applied Job Modal');
    this.setState({
      isOpenAppliedJobModal: false,
    });
  }
  dismissReopenJobModal = () => {
    // console.log('Close Apply Job Modal');
    this.setState({
      isOpenReopenJobModal: false,
    });
  }
  onUploadSuccess = () => {
    this.setState({
      isOpenAppliedJobModal: true,
    });
  }

  onCloseJob = (jobId) => {
    this.dismissCloseJobModal();
    const { closeJobMessage } = this.state;
    this.props.closeJob(jobId && Number(jobId), closeJobMessage).then((res) => {
      this.handleGetJobInfo(jobId);
      this.props.getJobTimeLine(jobId);
    });
  }

  onPreferredToggleClick = (jobId, pv) => {
    this.setState({
      preferred: pv,
    }, () => {
      const patchObj = {
        jobId,
        preferred: this.state.preferred,
      };
      this.props.statusOfJob(patchObj).then(() => {
        this.handleGetJobInfo(jobId);
        this.props.getJobTimeLine(jobId);
      });
    });
  }

  onToggleClick = (jobId) => {
    this.setState({
      isPrivate: !this.state.isPrivate,
    }, () => {
      const patchObj = {
        jobId,
        isPrivate: this.state.isPrivate,
      };
      this.props.statusOfJob(patchObj).then(() => {
        this.props.getJobTimeLine(jobId);
      });
    });
  }
  toggleSidebar = (evn) => {
    this.setState({
      displayMobileSidebar: !this.state.displayMobileSidebar,
    });
  }

  onAutoPilotToggleClick = (jobId) => {
    const { isAutoPilot } = this.state;
    if (isAutoPilot === false) {
      this.setState({
        isOpenToggleModal: true,
      });
    } else {
      this.setState({
        isAutoPilot: !this.state.isAutoPilot,
      }, () => {
        const patchObj = {
          jobId,
          isAutoPilot: this.state.isAutoPilot,
        };
        this.props.statusOfJob(patchObj).then(() => {
          this.props.getJobTimeLine(jobId);
        });
      });
    }
    return isAutoPilot;
  }

  handleAutoShortlistToggle = (jobId) => {
    this.setState({
      isAutoShortlist: !this.state.isAutoShortlist,
    }, () => {
      const patchObj = {
        jobId,
        isAutoShortlist: this.state.isAutoShortlist,
      };
      this.props.statusOfJob(patchObj).then(() => {
        this.props.getJobTimeLine(jobId);
      });
    });
  }

  validateLinkedinId = (jobId, linkedinJobId) => {
    if (isNaN(Number(linkedinJobId))) {
      this.setState({
        isLinkedinIdValid: true,
        invalidText: "Please enter valid Linkedin job ID",
      });
      return;
    }
    this.props.associateLinkedinJob(jobId, linkedinJobId).then((res) => {
      if (res && res.error) {
        if (res.error.message === "unique_violation__job_linkedin_job_id_key") {
          this.setState({
            isLinkedinIdValid: true,
            invalidText: "The entered text is already associated with another Job, please enter unique Linkedin job ID",
          });
        } else {
          this.setState({
            isLinkedinIdValid: true,
            invalidText: "There was an error in processing, please try later",
          });
        }
      } else if (res && res.jobId) {
        this.dismissAssociateJobModal();
        this.setState({
          linkedinJobid: linkedinJobId,
          isLinkedinIdValid: false,
          invalidText: '',
        });
        this.handleGetJobInfo(res && res.jobId);
      }
    });
  }

  handleAutoPilotModal = (jobId) => {
    this.setState({
      isAutoPilot: true,
    }, () => {
      const patchObj = {
        jobId,
        isAutoPilot: this.state.isAutoPilot,
      };
      this.props.statusOfJob(patchObj).then(() => {
        this.props.getJobTimeLine(jobId);
      });
    });
    this.dismissToggleModal();
  }

  handleLinkedinJobSplit = (url) => {
    const { linkedinJobid } = this.state;
    let parsedLinkedInJobId = '';
    let idFromUrl = linkedinJobid.split(url);
    idFromUrl.reverse();
    parsedLinkedInJobId = idFromUrl[0];
    if (parsedLinkedInJobId.includes('/')) {
      parsedLinkedInJobId = parsedLinkedInJobId.split('/');
    } else if (parsedLinkedInJobId.includes('?')) {
      parsedLinkedInJobId = parsedLinkedInJobId.split('?');
    } else if (parsedLinkedInJobId.includes('/?')) {
      parsedLinkedInJobId = parsedLinkedInJobId.split('/?');
    }
    if (Array.isArray(parsedLinkedInJobId)) {
      parsedLinkedInJobId = parsedLinkedInJobId[0];
    }
    return parsedLinkedInJobId;
  }

  onAssociateLinkedinjob = (jobId) => {
    const { linkedinJobid } = this.state;
    let parsedLinkedInJobId = '';
    if (linkedinJobid && linkedinJobid.includes("linkedin.com/jobs/view/")) {
      parsedLinkedInJobId = this.handleLinkedinJobSplit("linkedin.com/jobs/view/");
    } else if (linkedinJobid && linkedinJobid.includes("linkedin.com/jobs/cap/view/")) {
      parsedLinkedInJobId = this.handleLinkedinJobSplit("linkedin.com/jobs/cap/view/");
    } else {
      parsedLinkedInJobId = linkedinJobid;
    }
    if (!parsedLinkedInJobId || parsedLinkedInJobId === '') {
      this.setState({
        isLinkedinIdValid: true,
        invalidText: "Please enter valid Linkedin job ID",
      });
      return;
    }
    this.validateLinkedinId(jobId, parsedLinkedInJobId);
  }

  onReopenJob = (jobId) => {
    this.dismissReopenJobModal();
    this.props.reopenJob(jobId).then((res) => {
      this.handleGetJobInfo(jobId);
      this.props.getJobTimeLine(jobId);
    });
  }

  onCloneJob = (jobId) => {
    const { selectedBatchVal } = this.state || {};
    const { batchName, batchId } = selectedBatchVal || {};
    if(!selectedBatchVal || !batchName || !batchId){
      this.setState({ selectedBatchValInvalid: true });
      return;
    }
    this.props.cloneJob({jobIds:[jobId], batchName, batchId}).then((res) => {
      if (res && Array.isArray(res) && res.length>0 ) {
        this.props && this.props.history && this.props.history.push(`/app/campus/job/${res[0].jobId}`);
        this.handleGetJobInfo(res && res.data && res.data.jobId);
        this.props.associateJd(res.data.jobId);
        this.props.getJobTimeLine(res.data.jobId);
      }else{
        this.handleSetCloneJobNotif('Sorry, something went wrong', 'error');
      }
      this.dismissCloneJobModal();
    });
  }

  handleSetCloneJobNotif = (cloneJobNotifText, cloneJobNotifType) => {
    this.setState({ showCloneJobNotif: true, cloneJobNotifText, cloneJobNotifType }, () => {
      setTimeout(() => {
        this.setState({showCloneJobNotif: false, cloneJobNotifText: '', cloneJobNotifType: null})
      }, 5000);
    })
  }
  
  triggerJDDownload = (jobId) => {
    this.props.downloadJD(jobId);
  }

  handleGetJobInfo = (jobId) => {
    return this.props.getJobInfo({ jobId })
      .then((res = {}) => {
        const {
          jobs = []
        } = res;
        const [job = {}] = jobs;
        const {
          aiSourcing,
          isAutoPilot,
          isPrivate,
          linkedinJobId,
          isAutoShortlist,
          companyId
        } = job;
        //console.log('LINKEDIN ID',this.state.linkedinJobid);
        this.setState({
          linkedinJobid: linkedinJobId,
          isPrivate,
          isAutoPilot,
          isAutoShortlist,
          isAISourcingEnabled: aiSourcing
        }, () => {
          // console.log('LINKEDIN ID',this.state.linkedinJobid);
          let teamId = this.props.jobs && this.props.jobs[0] && this.props.jobs[0].teamId;
          this.props.getAuthorizedUsers(teamId);
          this.props.getAuthorizedUsersForCollaborators(teamId, companyId)
          if (teamId) {
            this.props.getJobCollaborators(teamId, jobId);
          }
        });
      })
  }

  validateShareJobInput = () => {
    const { emailShare, message = '' } = this.state;
    const validationObj = isEmail(emailShare + '');
    if (!validationObj) {
      this.setState({
        isEmailIdValid: true,
        invalidText: 'Please enter valid mail id',
      });
    }
    return validationObj;
  }

  submitShare = (jobId) => {
    const validationObj = this.validateShareJobInput();
    const { emailShare, message } = this.state;
    const { user: { firstname, lastname } = {} } = this.props;
    if (validationObj) {
      this.dismissEmailJobModal();
      this.props.shareCV({
        cvLink: jobId,
        templateName: 'email-job',
        email: emailShare,
        message,
        firstName: firstname,
        lastName: lastname,
      })
        .then(() => {
          this.setState({
            isOpenEmailJobModal: false,
            isEmailIdValid: false,
            isEmailTextValid: false,
            emailShare: '',
            message: '',
          });
        })
      return true;
    }
    return false;
  }

  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'open', name: 'Jobs', href: '/app/campus/jobs/open' },
    { id: 'job', name: 'Job details', href: '' },
  ];
  candidatepaths = [
    { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
    { id: 'job', name: 'Job details', href: '' },
  ];

  aiSourcingToggle = (jobId) => {
    this.setState({ isAISourcingEnabled: !this.state.isAISourcingEnabled },
      () => {
        const patchObj = {
          jobId,
          aiSourcing: this.state.isAISourcingEnabled,
        };
        this.props.statusOfJob(patchObj).then(() => {
          this.props.getJobTimeLine(jobId);
        });
      });
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      jobs = [],
      location: {
        search,
        state: {
          activeSelectedTab,
        } = {},
      } = {},
    } = this.props;
    const [job = {}] = jobs;
    const jobId = id;
    const {
      canApplytoJob,
    } = job || {};
    
    this.props.getBatchDetails().then((res) => {
      if(res && !res.error){
        const { list: batchList } = res;
        const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
        const batchDropDownList = batchList.map(one => ({ label: one.batchName, value: one.batchId }));
        const stateData = {
          batchDropDownList,
        };
        if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
          stateData.currentBatchName = currentBatch.batchName;
        }
        this.setState({
          ...this.state,
          ...stateData,
        })
      }
    })

    if (search) {
      const parsedQuery = queryString.parse(search);
      if (parsedQuery && parsedQuery.appId) {
        this.props.findAllUserJobApplicants(parsedQuery.appId);
      }
    }
    const showPopup = window.location.search.includes('popup=1');
    this.props.fetchUserLoginState().then(() => {
      const { user: { profileId = null } = {}, location } = this.props;
      this.props.getCandidateProfile(profileId).then((res) => {
        const { finlandprofile: [finlandProfile = {}] = [] } = res;
        const { finlandProfileId = null } = finlandProfile || {};
        this.handleGetJobInfo(id);
        this.props.getJobAppliedCount(profileId);
        this.props.getAllUserWorkflow();
        this.props.getWorkflowStages();
        this.props.getJobApprovalList(jobId);
        this.props.getJobTimeLine(jobId);
        this.props.getRecommendedJobs(0, Number(jobId));
        this.setState({
          isOpenApplyJobModal: (showPopup && canApplytoJob),
          finlandProfileId,
          activeSelectedTab: activeSelectedTab || this.state.activeSelectedTab,
        });
        SetMixPanel();
      });
    });
  }

  displayAnalytics = () => {
    this.setState({ showAnalyticsModule: true });
  }

  toastSuccess = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  handleActiveSelectedTab = (value) => {
    this.setState({
      activeSelectedTab: value,
    });
  }

  handleJobInfoTabsClick = (tabType) => {
    let activeSelectedTab = 0;
    switch (tabType) {
      case 'details':
        activeSelectedTab = 0;
        break;
      case 'collaborators':
        activeSelectedTab = 6;
        break;
      case 'timeline':
        activeSelectedTab = 1;
        break;
      // case 'auto-pilot-settings':
      //   activeSelectedTab = 3;
      //   break;
      case 'workflow-settings':
        activeSelectedTab = 2;
        break;
      // case 'analytics':
      //   this.setState({showAnalyticsModule : true});
      //   activeSelectedTab = 5;
      //   break;
      // case 'ai-sourcing':
      //   this.setState({showAnalyticsModule : true});
      //   activeSelectedTab = 6;
      //   break;
      case 'approvals':
        activeSelectedTab = 3;
        break;
      case 'application':
        activeSelectedTab = 4;
        break;
      case 'interview-status':
        activeSelectedTab = 5;
        break;
      default:
        activeSelectedTab = 0;
        break;
    }
    this.setState({
      activeSelectedTab,
    });
  }

  JobcontainerInfo = () => {
    const {
      user: { roleId } = {}, location, timeLine,
      candidateProfile: {
        studentprofile: {
          faculty: {
            totalJobsApplicable,
          } = {},
        } = {},
      } = {},
      approvalList,
      match,
      jobs,
      isUserLoggedIn,
    } = this.props;
    const { jobId } = (jobs && jobs[0]) || {};
    const { params } = match || {};
    let country = { countryFull: ' ', countryID: 0 };
    const activeSelectedTab = this.state.activeSelectedTab;
    let collaboratorRoleId = 3;
    const interviewflow = {
      // handleUserSubStageCompletion: this.handleUserSubStageCompletion,
      showInModal: false,
      application: this.props.applications.applications && this.props.applications.applications[0],
      appId: this.props.applications.applications && this.props.applications.applications[0] && this.props.applications.applications[0].appId,
      jobId: this.props && this.props.match && this.props.match.params && this.props.match.params.id,
      updateParentPage: this.handleReload,
      // handleOpenEmailModal: this.handleOpenEmailModal,
      // handleApplicantTabClick: this.handleApplicantTabClick,
      getOfferDetails: this.props.getOfferDetails,
    };
    if (this.props.jobs && this.props.jobs[0] && this.props.jobs[0].collaboration && this.props.jobs[0].collaboration.length > 0) {
      let filterCollaboratorsByUserId = this.props.jobs[0].collaboration.filter(eachCollaborator => eachCollaborator.userId == this.props.user.userId);
      if (filterCollaboratorsByUserId && filterCollaboratorsByUserId.length > 0) {
        collaboratorRoleId = filterCollaboratorsByUserId[0] && filterCollaboratorsByUserId[0].roleId;
      } else {
        collaboratorRoleId = 3;
      }
    }

    if (this.props.user && this.props.user.isAdmin && this.props.user.company && (this.props.user.company.companyId == (this.props.jobs && this.props.jobs[0] && this.props.jobs[0].companyId))) {
      collaboratorRoleId = 4;
    }

    if (this.props.jobs && this.props.jobs.length > 0 && this.props.jobs[0].country) country = this.props.jobs[0].country;
    let approvedJobs = [];
    let hideJobActns = false;
    if (approvalList && Array.isArray(approvalList) && approvalList.length > 0) {
      approvedJobs = approvalList.filter(res => res && res.isApproved === false);
      const apprvdJobsLngth = approvedJobs && Array.isArray(approvedJobs) && approvedJobs.length;
      const approvalsListLngth = approvalList.length;
      if (apprvdJobsLngth === approvalsListLngth) {
        hideJobActns = true;
      } else {
        hideJobActns = false;
      }
    }

    // if (jobId != (params && params.id)) {
    //   return (
    //     <JobInfoSkeleton isAuthenticated={isUserLoggedIn} roleId={roleId} />
    //   );
    // }

    return (
      <JobInfoContainer
        user={this.props.user}
        jobsAppliedCount={this.props.jobsAppliedCount}
        job={this.props.jobs[0]}
        offerAcceptedCount={this.props.offerAcceptedCount}
        isAuthenticated={this.props.isUserLoggedIn}
        roleId={collaboratorRoleId}
        triggerJDDownload={this.triggerJDDownload}
        activateReopenJobModal={this.activateReopenJobModal}
        activateCloneJobModal={this.activateCloneJobModal}
        activateCloseJobModal={this.activateCloseJobModal}
        activateAssociateJobModal={this.activateAssociateJobModal}
        activeEmailJobModal={this.activeEmailJobModal}
        dismissCloneJobModal={this.dismissCloneJobModal}
        activateApplyToJobModal={this.activateApplyToJobModal}
        activeSelectedTab={activeSelectedTab}
        handleActiveSelectedTab={this.handleActiveSelectedTab}
        workflowstages={this.props.workflowstages}
        dismissApplyTobJobModal={this.dismissApplyTobJobModal}
        profileId={this.props.user && this.props.user.profileId}
        isOpenApplyJobModal={this.state.isOpenApplyJobModal}
        closeJobMessage={this.state.closeJobMessage}
        displayAnalytics={this.displayAnalytics}
        hideJobActns={hideJobActns}
        interviewflow={interviewflow}
        totalJobsApplicable={totalJobsApplicable}
        value={this.props && this.props.match && this.props.match.params}
        push={this.props && this.props.history && this.props.history.push}
        application={this.props.applications && this.props.applications.applications && this.props.applications.applications[0]}
        handleGetJobInfo={this.handleGetJobInfo}
        handleJobInfoTabsClick={this.handleJobInfoTabsClick}
        showAnalyticsModule={this.state.showAnalyticsModule}
        onToggleClick={() => this.onToggleClick(this.props.jobs && this.props.jobs[0] && this.props.jobs[0].jobId)}
        onPreferredToggleClick={(e) => {this.onPreferredToggleClick(this.props.jobs && this.props.jobs[0] && this.props.jobs[0].jobId,e)}}
        onAutoPilotToggleClick={() => this.onAutoPilotToggleClick(this.props.jobs && this.props.jobs[0] && this.props.jobs[0].jobId)}
        isPrivateJob={this.state.isPrivate}
        recommendedJobs={this.props.recommendedJobs}
        getJobTimeLine={this.props.getJobTimeLine}
        timeLine={timeLine}
        approvalList={approvalList}
        t={this.props.t}
        isAutoPilotMode={this.state.isAutoPilot}
        isAISourcingEnabled={this.state.isAISourcingEnabled}
        isAutoShortlistMode={this.state.isAutoShortlist}
        aiSourcingToggle={() => this.aiSourcingToggle(this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs[0] && this.props.jobs[0].jobId)}
        handleAutoShortlistToggle={() => this.handleAutoShortlistToggle(this.props.jobs && this.props.jobs[0] && this.props.jobs[0].jobId)}
        onUploadSuccess={this.onUploadSuccess}
        finlandProfileId={this.state.finlandProfileId}
        onEditJob={
          job => this.props.history.push({
            pathname: `/app/campus/new/job/registration/${job.jobId}`,
            state: {
              ...job,
              isEditJobCase: true,
              skillsmusthave: job.skills.filter(skill => (skill && (skill.requirementId == 1))).map(skill => ({ label: skill && skill.skill && skill.skill.skillName, value: skill && skill.skill && skill.skill.skillId, })),
              skillsgoodtohave: job.skills.filter(skill => (skill && (skill.requirementId == 2))).map(skill => ({ label: skill && skill.skill && skill.skill.skillName, value: skill && skill.skill && skill.skill.skillId, })),
              skillsdesired: job.skills.filter(skill => (skill && (skill.requirementId == 3))).map(skill => ({ label: skill && skill.skill && skill.skill.skillName, value: skill && skill.skill && skill.skill.skillId, })),
              term: job && typeof job.term === 'object' ? job.term : '',
              seniority: job && job.jobseniority && job.jobseniority.seniorityName,
              hourType: job && job.hourtype && job.hourtype.typeName,
              currency: job && job.currency && job.currency.currencyFull,
              country: { value: job.countryId, label: country.countryFull },
              'function': job && job.jobfunction && job.jobfunction.functionName,
            },
          })
        }
      />);
  }

  // handleApproveCurrentUser = (jobApprovedId) => {
  //   const {
  //     match: {
  //       params: {
  //         id: jobId,
  //       } = {},
  //     } = {},
  //   } = this.props;
  //   const {
  //     vacancies,
  //   } = this.state;
  //   let isVacancyValid = false;
  //   if (!vacancies) {
  //     isVacancyValid = true;
  //   } else {
  //     isVacancyValid = false;
  //   }
  //   this.setState({
  //     isVacancyValid,
  //   }, () => {
  //     if (this.state.isVacancyValid !== true) {
  //       this.props.editJobApprovalList(jobId && Number(jobId), true, vacancies && Number(vacancies)).then((res) => {
  //         if (res && !res.error) {
  //           this.props.getJobApprovalList(jobId && Number(jobId));
  //           this.props.getJobInfo({ jobId: jobId && Number(jobId) });
  //         }
  //       });
  //     }
  //   });
  // }

  // handleDisApproveCurrentUser = (jobApprovedId) => {
  //   const {
  //     match: {
  //       params: {
  //         id: jobId,
  //       } = {},
  //     } = {},
  //   } = this.props;
  //   this.props.editJobApprovalList(jobId && Number(jobId), false, 0).then((res) => {
  //     if (res && !res.error) {
  //       this.props.getJobApprovalList(jobId && Number(jobId));
  //       this.props.getJobInfo({ jobId: jobId && Number(jobId) });
  //     }
  //   });
  // }

  handleReload = () => {
    window.location.reload();
  }

  handleShowSideBar = () => {
    const { showSideBar } = this.state;
    this.setState({
      showSideBar: !showSideBar,
    });
  }

  handleShowSubMenu = (subItems, label, value, desc) => {
    const {
      showSubItems,
    } = this.state;
    if (value === true) {
      if (this.state.subItems && Array.isArray(this.state.subItems) && this.state.subItems.length > 0 && label !== this.state.label) {
        this.setState({
          showSubItems: true,
          subItems,
          label,
          desc,
        });
      } else {
        this.setState({
          showSubItems: !showSubItems,
          subItems,
          label,
          desc,
        });
      }
    } else {
      this.setState({
        showSubItems: false,
        subItems: [],
        label: '',
      });
    }
  }

  handleRedirectMainPage = () => {
    const {
      history: {
        push,
      } = {},
      user: {
        roleId,
      } = {},
    } = this.props;
    if (push) {
      if (roleId && Number(roleId) === 3) {
        push('/app/campus/candidate');
      } else {
        push('/app/campus');
      }
    }
  }

  onClickSideNavExpand = () => {
    const {
      isSideNavExpanded,
    } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  }

  handleBatchDropdownChange = (e) => {
    const name = e.target.name; 
    const value = JSON.parse(e.target.value);
    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      history, match, isUserLoggedIn,
      user: { roleId, facultyId, products = [], isAdmin = false, email, userId,
        isSuperAdmin,
        company: {
          ctypeId: propCtypeId,
          companyName = '',
          companyId,
        } = {},
      } = {}, taskId,
      approvalList,
      location,
      t,
      offerAcceptedCount,
      jobsAppliedCount,
      candidateProfile: {
        studentprofile: {
          faculty: {
            totalJobsApplicable,
          } = {},
        } = {},
      } = {},
      jobs=[]
    } = this.props;
    const {approvedVacancies, 
      dashboard:{
        hiredApplns
      }={}
    }=jobs[0]||{};
    // let filteredList = [];
    // if (userId) {
    //   filteredList = approvalList && Array.isArray(approvalList)
    //   && approvalList.length > 0
    //   && approvalList.filter(res => res.isApproved == null && res && res.facultyId == facultyId);
    // }
    let country = { countryFull: '', countryID: 0 };
    if (this.props.jobs && this.props.jobs.length > 0 && this.props.jobs[0].country)
      country = this.props.jobs[0].country;
    const { 
      displayMobileSidebar, 
      showSideBar, 
      currentBatchName,
      selectedBatchVal,
      batchDropDownList,
      selectedBatchValInvalid,
    } = this.state;
    const guideSteps = [
      {
        selector: '.unknown-class-meow-meow',
        content: 'This is the main job listing page. It provides details about the job that you have created.',
      },
      {
        selector: '.xp-m__jobinfo .xp-m__jobinfo--hero',
        content: 'This section provides basic information about the job such as title, domain and job type. In addition it provides various actions that can be perfomed.',
      },
      {
        selector: '.xp-m__jobinfo .job-applicant-info',
        content: 'This section provides information about applicants for this job such as total applications, shortlisted and rejected applicants count.',
      },
      {
        selector: '.xp-m__jobinfo .xp-m__jobinfo--hero-actions',
        content: 'This section provides various actions that can be perfomed such as Clone, Edit, Close, Re-open etc.,',
      },
      {
        selector: '.xp-m__jobinfo .xp-m__jobinfo--private-toggle ',
        content: 'This section provides information about job visibility status. Jobs can be either set as public or private.',
      },
      {
        selector: '.xp-m__jobinfo .xp-m__jobinfo--full-info',
        content: 'This section provides complete information about the job such as company information, skills, requirements etc.,',
      },
    ];
    let rootPath = window.location.origin;
    if (products && Array.isArray(products)) {
      if (products.includes('ROBOCAMPUS')) {
        rootPath = `${window.location.origin}/app/campus`;
      }
    }
    let isYuvoEmail = email && email.indexOf && email.indexOf('yuvohub.com') > -1;
    let collaboratorRoleId = 3;
    let isACollaborator = false;
    let isTheCandidateWhoApplied = false;
    if (this.props.jobs && this.props.jobs[0] && this.props.jobs[0].collaboration && this.props.jobs[0].collaboration.length > 0) {
      let filterCollaboratorsByUserId = this.props.jobs[0].collaboration.filter(eachCollaborator => eachCollaborator.userId == this.props.user.userId);
      if (filterCollaboratorsByUserId && filterCollaboratorsByUserId.length > 0) {
        collaboratorRoleId = filterCollaboratorsByUserId[0] && filterCollaboratorsByUserId[0].roleId;
        isACollaborator = true;
      } else {
        collaboratorRoleId = 3;
        isACollaborator = false;
      }
    }

    if (this.props.user && this.props.user.isAdmin && this.props.user.company && (this.props.user.company.companyId == (this.props.jobs && this.props.jobs[0] && this.props.jobs[0].companyId))) {
      collaboratorRoleId = this.props.user.roleId;
      isACollaborator = true;
    }

    if (this.props.jobs && this.props.jobs[0] && this.props.jobs[0].canApplytoJob == false) {
      isTheCandidateWhoApplied = true;
    }
  const isInStaging=window.location.origin.includes('rps.x0pa.ai');
    return (
      <div>
        <Header aria-label="RP">
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={() => { this.onClickSideNavExpand() }}
            isActive={false}
          />
          <HeaderName
            className="pr-0"
            onClick={this.handleRedirectMainPage}
            href="javascript:void(0)"
            prefix="">
            <img style={isInStaging?{ height: '100%' }:{}} className="xpa-top-header--logo" src={isInStaging?'https://rpopubassets.blob.core.windows.net/login/logo/rps.x0pa.ai.png':geipImage} alt="logo" />
          </HeaderName>
          <SkipToContent />
          <HeaderWithActions
            propLabel={this.state.label}
            showSubItems={this.state.showSubItems}
            handleShowSubMenu={this.handleShowSubMenu}
            history={history}
          />
          {isUserLoggedIn && !displayMobileSidebar ? (
            <NavRightList history={history} />
          ) : null}
          <div className="hide-side-nav d-md-block d-sm-block d-xs-block d-xl-none">
            <SideNavList history={history} isSideNavExpanded={this.state.isSideNavExpanded} />
          </div>
        </Header>
        {this.state.showSubItems === true && (
          <div className="bx--tile subitems-card bx--row p-0 m-0 justify-content-around align-items-center mt-lg-4 mt-md-5 mt-sm-5 mt-xs-5">
            <div className="bx--col-lg-3 d-flex d-flex--wrap mt-3 mb-0">
              <div>
                <div className="h4 font-weight-bold">{t(this.state.label)}</div>
                <div className="">
                  {t(this.state.desc)}
                </div>
              </div>
            </div>
            <div className="bx--col-lg-8 d-flex d-flex--wrap mb-3 mt-5 xpa-bdr--left">
              {this.state.subItems &&
                Array.isArray(this.state.subItems) &&
                this.state.subItems.length > 0 &&
                this.state.subItems.map((res) => {
                  const {
                    subItemsLabel, subItemsIcon, subItemsPath, ctypeId,
                    subDesc,
                  } = res || {};
                  return (
                    <Fragment>
                      {
                        (roleId === 3 ? true : (((isSuperAdmin === true || isAdmin === true || (propCtypeId && Number(propCtypeId) === 1)) && ctypeId.includes(0)) ? true : (ctypeId && ctypeId.includes(Number(propCtypeId))))) && (
                          <Fragment>
                            <div className="d-flex bx--col-lg-3 ml-2 w-100">
                              <div>
                                <CarbonIconsHeader label={subItemsLabel} />
                              </div>
                              <div>
                                <div className="ml-3 h6 text-dark">
                                  <Link
                                    className="font-weight-bold"
                                    onClick={() => this.handleShowSubMenu([], null, false)}
                                    to={(subItemsLabel === 'institutions' && propCtypeId && Number(propCtypeId) === 2) ? `/app/campus/institution/info/${companyId}` : subItemsPath}>
                                    {t(subItemsLabel)}
                                  </Link>
                                  <div className="small text-justify w-75">
                                    {t(subDesc)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )
                      }
                    </Fragment>
                  );
                })}
            </div>
          </div>
        )}
        <div className="w-100 pt-3">
          <div> 
                  
            <section className="section-align-center bx--tile pl-lg-5 pl-4 pr-4 pt-5 mt-3 pr-lg-5 main-application-view w-100">
              <div className="xp-m__jobinfo xp-c-container xp-c-background--light p-3">
                {
                  isUserLoggedIn && (roleId != 3) && <BackRow paths={this.paths} showGuidedTour={false} handleGuidedTourClick={() => this.setState({ productTourIsOpen: true })} />
                }
                {
                  isUserLoggedIn && (roleId == 3) && <BackRow paths={this.candidatepaths} />
                }
                {/* {
                        filteredList && Array.isArray(filteredList)
                          && filteredList.length === 1
                          && roleId && Number(roleId) !== 3
                          && (
                            <div className="mb-2">
                              <div className="font-weight-bold h6">Job Posting Approval</div>
                              <div className="mt-2">
                                <div className=" pl-0 mt-2 text-dark bx--type-strong">Do you approve posting of this job requisition?</div>
                                <div className="mt-2 mb-2 w-25">
                                  <TextInput
                                    name="vacancies"
                                    id="vacancies"
                                    placeholder="6"
                                    labelText={t('vacancies')}
                                    onChange={this.handleChange}
                                    value={this.state.vacancies}
                                    required
                                    invalid={this.state.isVacancyValid}
                                    invalidText={t('vacancyValidationMsg')}
                                  />
                                </div>
                                <div className="mt-2">
                                  <Button
                                    onClick={() => { this.handleApproveCurrentUser(filteredList && filteredList[0] && filteredList[0].jobApproveId)}}
                                    className="mr-2"
                                    kind="tertiary" small>Yes</Button>
                                  <Button
                                    onClick={() => { this.handleDisApproveCurrentUser(filteredList && filteredList[0] && filteredList[0].jobApproveId); }}
                                    kind="danger"
                                    small>No</Button>
                                </div>
                              </div>
                              <hr />
                          </div>
                          )
                      } */}
                     
                <ProductTour
                  onRequestClose={this.closeTour}
                  isOpen={this.state.productTourIsOpen}
                  steps={guideSteps}
                />
                {(roleId !== 3) && <div className="jobInfoUpload">
                  <Toast taskId={taskId} successAction={this.toastSuccess} partialRefresh={() => { }} />
                </div>}
                {(this.props.loading || this.props.apprLoading) && (
                  <JobInfoSkeleton
                    isAuthenticated={isUserLoggedIn}
                    roleId={roleId}
                  />
                )}
                {this.state.showCloneJobNotif && (
                  <InlineNotification
                    lowContrast
                    className="mt-0"
                    title={this.state.cloneJobNotifText}
                    subtitle=""
                    iconDescription=""
                    kind={this.state.cloneJobNotifType}
                  />
                )}
                {
                  this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0
                  && this.props.jobs[0].draft === false
                  && this.props.jobs[0].isJobVacancyAvailable !== true && roleId && Number(roleId) === 3 && (
                    <InlineNotification
                      lowContrast
                      className="mt-0"
                      title="This internship position is no longer available as it has been accepted by another student. Please apply for other positions / accept other offer(s) instead. Thank you."
                      subtitle=""
                      iconDescription=""
                      kind="error"
                    />
                  )
                }
                {
                  offerAcceptedCount <= 0 && jobsAppliedCount < totalJobsApplicable
                  && this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0 && this.props.jobs[0].canApplytoJob !== false
                  && this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0 && this.props.jobs[0].isClosed !== true
                  && this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0 && this.props.jobs[0].isJobVacancyAvailable === true
                  && (
                    <InlineNotification
                      lowContrast
                      kind="warning"
                      title={`You can apply for ${totalJobsApplicable - jobsAppliedCount} ${totalJobsApplicable - jobsAppliedCount > 1 ? 'more jobs' : 'job'}`}
                      subtitle=""
                      hideCloseButton
                    />
                  )
                }
                {
                  offerAcceptedCount <= 0 && jobsAppliedCount >= totalJobsApplicable
                  && this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0 && this.props.jobs[0].canApplytoJob !== false
                  && this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0 && this.props.jobs[0].isClosed !== true
                  && this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0 && this.props.jobs[0].isJobVacancyAvailable === true
                  && (
                    <InlineNotification
                      lowContrast
                      kind="error"
                      title="You cannot apply to this job as you have reached the limit."
                      subtitle=""
                      hideCloseButton
                    />
                  )
                }
                {
                  offerAcceptedCount > 0 && this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0
                  && this.props.jobs && Array.isArray(this.props.jobs) && this.props.jobs.length > 0 && this.props.jobs[0].isJobVacancyAvailable === true
                  && this.props.jobs[0].canApplytoJob !== false && (
                    <InlineNotification
                      lowContrast
                      kind="error"
                      title={t('offerAcceptedErrMsg')}
                      subtitle=""
                      hideCloseButton
                    />
                  )
                }

                {
                  (approvedVacancies && hiredApplns && (hiredApplns>=approvedVacancies))? (
                    <InlineNotification
                      lowContrast
                      kind="warning"
                      title={<span>This job position is currently fully placed. The current number of approved vacancies is {approvedVacancies}. Please contact the <a href="https://www.rp.edu.sg/docs/default-source/student-life-doc/internship/internship---period-master.pdf" target="_blank">RP staff</a> from the respective school/diplomas to increase the number of vacancies for this position.</span>}
                      subtitle=""
                      hideCloseButton
                    />
                  ):null
                }

                {/* {this.state.isPrivate && (<JobNotFound />)} */}
                {this.props.jobs
                  && this.props.jobs[0]
                  && !isACollaborator
                  && this.props.jobs[0].draft === true
                  && !this.state.isPrivate
                  && roleId === 3
                  && this.props.loading !== true
                  && (<JobNotFound isUserLoggedIn={isUserLoggedIn} />)}
                {!this.state.isPrivate
                  && (this.state.isPrivate !== null)
                  && !isUserLoggedIn
                  && this.props.jobs
                  && this.props.jobs.length > 0
                  && this.props.jobs[0]
                  && (this.props.jobs[0].draft === false && roleId == 3 ? false : true)
                  && this.props.loading !== true
                  && (this.JobcontainerInfo())}
                {!this.state.isPrivate && roleId !== 3 && this.props.loading !== true && ((this.props.jobs && this.props.jobs.length <= 0) || (!this.props.jobs)) && (<JobNotFound isUserLoggedIn={isUserLoggedIn} />)}
                {isUserLoggedIn && (collaboratorRoleId == 3) && roleId !== 3 && this.state.isPrivate && this.props.loading !== true && !isTheCandidateWhoApplied && (<JobNotFound isUserLoggedIn={isUserLoggedIn} />)}
                {/* {isUserLoggedIn && (collaboratorRoleId == 3) && this.state.isPrivate  && isTheCandidateWhoApplied && this.props.jobs && this.props.jobs.length > 0 && (this.JobcontainerInfo())} */}
                {/* {isUserLoggedIn && !this.state.isPrivate && this.props.jobs && this.props.jobs.length > 0
                        && this.props.jobs[0]
                        && isACollaborator
                        && this.props.jobs[0].draft === true
                        && (this.JobcontainerInfo())} */}
                {isUserLoggedIn && (collaboratorRoleId == 3) && !this.state.isPrivate && this.props.jobs && this.props.jobs.length > 0
                  && this.props.jobs[0]
                  && this.props.loading !== true
                  && (this.props.jobs[0].draft === false && roleId == 3 ? true : true)
                  // && this.props.jobs[0].draft === false
                  && (this.JobcontainerInfo())}
                {isUserLoggedIn && this.props.loading !== true && (collaboratorRoleId == 3) && !this.state.isPrivate && this.props.loading !== true && ((this.props.jobs && this.props.jobs.length <= 0) || (!this.props.jobs)) && (<JobNotFound isUserLoggedIn={isUserLoggedIn} />)}
                {isUserLoggedIn && (isACollaborator) && (collaboratorRoleId != 3) && this.props.jobs && this.props.jobs.length > 0
                  && (this.props.jobs[0].draft === false && roleId == 3 ? false : true)
                  && this.JobcontainerInfo()}
                {isUserLoggedIn && roleId === 3 && this.props.jobs && this.props.jobs.length > 0 && this.props.loading !== true && this.state.isPrivate && (<JobNotFound isUserLoggedIn={isUserLoggedIn} />)}
                {isUserLoggedIn && (isACollaborator) && (collaboratorRoleId != 3) && this.props.loading !== true && ((this.props.jobs && this.props.jobs.length <= 0) || (!this.props.jobs)) && (<JobNotFound isUserLoggedIn={isUserLoggedIn} />)}

                {/* {this.props.jobs && this.props.jobs.length && isUserLoggedIn && (roleId !== 3) ?
                        this.JobcontainerInfo()  : null}
                      {isUserLoggedIn && (roleId == 3) && !this.state.isPrivate ? this.JobcontainerInfo() : null } */}

                <Modal
                  onRequestSubmit={() => { this.onReopenJob(this.props.jobs[0] && this.props.jobs[0].jobId) }}
                  onRequestClose={this.dismissReopenJobModal}
                  className="some-class"
                  open={this.state.isOpenReopenJobModal}
                  modalLabel="Action"
                  primaryButtonText="Reopen"
                  secondaryButtonText="Cancel"
                  modalHeading="Reopen Job"
                >
                  <p className="bx--modal-content__text">
                    <strong className="text-dark">
                      Do you want to re-open the existing job posting?
                            </strong>
                    <small className="pt-1 text-dark d-block">
                      Re-opening a job posting will not set a close date for the job. You will need to edit job
                      posting to set a close date.
                            </small>
                  </p>
                </Modal>

                <Modal
                  onRequestSubmit={() => { this.onCloneJob(this.props.jobs[0] && this.props.jobs[0].jobId) }}
                  onRequestClose={this.dismissCloneJobModal}
                  className="some-class"
                  open={this.state.isOpenCloneJobModal}
                  modalLabel="Action"
                  primaryButtonText="Clone"
                  secondaryButtonText="Cancel"
                  modalHeading="Clone Job"
                >
                  <p className="bx--modal-content__text">
                    {/* <strong className="text-dark"> */}
                    {/* This job was previously created for AY2020 Semester 2 (Sep 2020 – Feb 2021). Proceed to clone this for AY2021 Semester 1 (Mar – Aug 2021)? */}
                      {/* This job was created previously.
                      Proceed to clone this for {currentBatchName}? */}
                              {/*Do you want to create a copy of the existing job posting?*/}
                    {/* </strong> */}
                    <div>
                      <Select
                        name="selectedBatchVal"
                        hideLabel
                        id="select-batch"
                        invalid={selectedBatchValInvalid}
                        invalidText='Please select batch'
                        onChange={e => this.handleBatchDropdownChange(e)}
                        value={selectedBatchVal ? JSON.stringify(selectedBatchVal):'select'}>
                        <SelectItem text="Select" value='select' />
                        {batchDropDownList &&
                          batchDropDownList.map(b => (
                            <SelectItem
                              key={b.value}
                              text={`${b.label}`}
                              value={JSON.stringify({batchName: b.label, batchId: b.value})}
                            />
                          ))}
                      </Select>
                      {/* {
                        selectedBatchValInvalid === true && (
                          <div className="bx--form-requirement mb-2">
                            Please select batch
                          </div>
                        )
                      } */}
                    </div>
                    <div>
                      <small className="pt-1 text-dark d-block">
                        Job will be cloned to selected batch. You will be redirected to the new job posting, once cloning is completed.
                      </small>
                    </div>
                  </p>
                </Modal>

                <Modal
                  onRequestSubmit={() => { this.onCloseJob(this.props.jobs[0] && this.props.jobs[0].jobId) }}
                  onRequestClose={this.dismissCloseJobModal}
                  className="some-class"
                  open={this.state.isOpenCloseJobModal}
                  modalLabel="Action"
                  primaryButtonText="Yes"
                  secondaryButtonText="Cancel"
                  modalHeading="Close Job"
                >
                  <p className="bx--modal-content__text">
                    <strong className="text-dark">
                      Would you like to close a job?
                          </strong>
                    <small className="pt-1 text-dark d-block">
                      Closing it would imply that you will no longer receive applications and 
                        all applications tied to this job will be closed. Click on Yes to close the job.
                          </small>
                  </p>
                  <TextArea
                    name="closeJobMessage"
                    labelText="Please specify reason for closing this job."
                    placeholder="Reason for closing this job"
                    onChange={(ev) => { this.handleChange(ev); }}
                  />
                </Modal>

                <Modal
                  onRequestSubmit={() => { this.onAssociateLinkedinjob(this.props.jobs && this.props.jobs[0] && this.props.jobs[0].jobId) }}
                  onRequestClose={this.dismissAssociateJobModal}
                  open={this.state.isOpenAssociateJobModal}
                  modalLabel="Action"
                  primaryButtonText="Associate"
                  secondaryButtonText="Cancel"
                  modalHeading="Associate a Linkedin Job"
                >
                  <TextInput
                    name="linkedinJobid"
                    id="linkedinJobid"
                    placeholder="eg:12345 or https://www.linkedin.com/jobs/view/12345/"
                    labelText="Linkedin Job Id"
                    onChange={this.handleChange}
                    value={this.state.linkedinJobid ? this.state.linkedinJobid : ''}
                    required
                    invalid={this.state.isLinkedinIdValid}
                    invalidText={this.state.invalidText}
                  />
                </Modal>

                <Modal
                  onRequestSubmit={() => { this.submitShare(this.props.jobs && this.props.jobs[0] && this.props.jobs[0].jobId) }}
                  onRequestClose={this.dismissEmailJobModal}
                  open={this.state.isOpenEmailJobModal}
                  modalLabel="Sharing"
                  primaryButtonText="Send"
                  secondaryButtonText="Cancel"
                  modalHeading="Email This Job to Someone"
                >
                  <TextInput
                    id="emailshare"
                    className="mb-2"
                    labelText="To:"
                    name="emailShare"
                    invalid={this.state.isEmailIdValid}
                    invalidText={this.state.invalidText}
                    placeholder="Enter recepient email address"
                    onChange={(ev) => { this.handleChange(ev); }}
                    value={this.state.emailShare}
                  />
                  <TextArea
                    labelText="Message:"
                    className="some-class mb-2"
                    placeholder="Enter your message here."
                    name="message"
                    invalid={this.state.isEmailTextValid}
                    invalidText={this.state.inValidMessage}
                    onChange={(ev) => { this.handleChange(ev); }}
                    value={this.state.message}
                  />
                </Modal>
                <Modal
                  onRequestSubmit={() => this.handleAutoPilotModal(this.props.jobs && this.props.jobs[0] && this.props.jobs[0].jobId)}
                  onRequestClose={this.dismissToggleModal}
                  open={this.state.isOpenToggleModal}
                  modalLabel="Action"
                  primaryButtonText="Submit"
                  secondaryButtonText="Cancel"
                  modalHeading="Enable Auto-Pilot Mode"
                >
                  <div className="bx--modal-content__text justify-text">
                    <p>
                      Auto-Pilot feature helps speed up the recruitment process. By enabling Auto-Pilot mode, RoboRoy will automatically schedule the interview and send out emails to candidates.
                          <br />
                          Recruiters can choose to automatically schedule interviews for RoboRoy shortlisted candidates or the candidates they manually shortlist.
                          <br />
                          In order for this mode to work, Recruiters will need to setup the workflow and video interview questions after creating the job.
                          </p>
                  </div>
                </Modal>
                <Modal
                  modalHeading="Job Application"
                  passiveModal
                  open={this.state.isOpenAppliedJobModal}
                  onRequestClose={this.dismissAppliedTobJobModal}>
                  <div className="p-4">
                    <h5 className="modal-title">Thanks for applying</h5>
                    <p className="m-0 pt-3">We will proccess the resume and get back to you.</p>
                  </div>
                </Modal>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}


// const { finlandProfileId } = this.state;
// const { user: { profileId } = {} } = this.props;

const mapStateToProps = state => ({
  loading: state.jobs.loading,
  error: state.jobs.error,
  jobs: state.jobs.jobs,
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
  candidateProfile: state.roboroyApplications.candidateProfile,
  loadingApplication: state.roboroyApplications.loading,
  taskId: state.resumeUpload.task_id,
  timeLine: state.candidateJobs.timeLine,
  workflowstages: state.workflows.workflowstages,
  recommendedJobs: state.candidateDashboard.recommendedJobs,
  approvalList: state.JobApprovals.approvalList,
  apprLoading: state.JobApprovals.loading,
  applications: state.roboroyApplications,
  jobsAppliedCount: state.roboroyApplications.jobsAppliedCount,
  offerAcceptedCount: state.roboroyApplications.offerAcceptedCount,
});

const mapDispatchToProps = {
  getJobInfo,
  closeJob,
  cloneJob,
  reopenJob,
  downloadJD,
  associateJd,
  associateLinkedinJob,
  statusOfJob,
  shareCV,
  getWorkflowStages,
  getAllUserWorkflow,
  getAuthorizedUsers,
  getJobCollaborators,
  addJobCollaborator,
  removeJobCollaborator,
  getJobTimeLine,
  getRecommendedJobs,
  getCandidateProfile,
  fetchUserLoginState,
  getJobApprovalList,
  editJobApprovalList,
  findAllUserJobApplicants,
  getOfferDetails,
  getJobAppliedCount,
  getAuthorizedUsersForCollaborators,
  getBatchDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobInfo));
