import {
  REQUEST_GET_REPORTS_DATA,
  SUCCESS_GET_REPORTS_DATA,
  ERROR_GET_REPORTS_DATA,
  REQUEST_DOWNLOAD_REPORTS_DATA,
  SUCCESS_DOWNLOAD_REPORTS_DATA,
  ERROR_DOWNLOAD_REPORTS_DATA,
  REQUEST_GET_STUDENT_REPORTS,
  SUCCESS_GET_STUDENT_REPORTS,
  ERROR_GET_STUDENT_REPORTS,
} from 'actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  jobs: [],
  jobsCount: {},
  students: [],
};

const Reports = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_GET_REPORTS_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_REPORTS_DATA:
      return {
        ...state,
        jobs: payload && payload.jobs,
        jobsCount: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_GET_REPORTS_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DOWNLOAD_REPORTS_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DOWNLOAD_REPORTS_DATA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DOWNLOAD_REPORTS_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_STUDENT_REPORTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STUDENT_REPORTS:
      return {
        ...state,
        students: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_STUDENT_REPORTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default Reports;
