import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  TextInput, DataTable, Tabs, Tab, Button,
   Modal, Tile, InlineNotification, Toggle,
   Loading, Pagination,
} from 'carbon-components-react';
import isEmail from 'validator/lib/isEmail';

import CompanyDetails from './CompanyDetails';
import AgencyDetails from './AgenciesDetails';
import AdminSettings from './AdminSettings';
import Avatar from 'components/common/Avatar';
import BackRow from 'components/common/BackRow/BackRow';

import {
  getInvites,
  sendInvite,
  deleteInvite,
} from '../../actions/admin/AdminDashboard';

import { getAuthorizedCompanies,} from '../../actions/admin/CompanyAndOffice';
import { getCompanyInfo } from 'actions/admin/AdminSettings';

const {
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell,
  TableHeader, TableToolbar, TableToolbarContent, TableToolbarSearch,
} = DataTable;

const NoUsersBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no users currently associated.</p>
    </div>
  </Tile>
);
class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: '',
      rowsData: [],
      addNewUserModal: false,
      headersInfo: [
        { key: 'emailId', header: 'Email' },
        { key: 'companyName', header: 'Company' },        
        { key: 'status', header: 'Status' },
        { key: 'action', header: 'Action' },
      ],
      rowData: [],
      headersData: [
        { key: 'userInvite', header: 'UserInvite' },
      ],
      isInvalidEmail: true,
      isResendInvite: false,
      isEmailExist: false,
      isAdmin: false,
      activePage:1,
      limit:10,
    };
  }

  // componentDidMount() {
  //   const { user } = this.props;
  //   this.prepareRowData(user);
  // }

  CardRowBox = (rowData) => {
    return (
      <div className="ml-2 mt-2">
        <span>{rowData.emailId}</span>
        <span>{rowData.status}</span>
        <span>{rowData.companyName}</span>        
        <div className="mt-2 ml-1 mb-2">{rowData.action}</div>
      </div>
    );
  }

  prepareRowData = ({limit=null,skip=null}={}) => {
    limit = limit===null?this.state.limit:limit;
    skip = skip===null?((this.state.activePage-1)*limit):skip;
    const { user } = this.props;
    this.props.getInvites(limit,skip).then(() => {
      const { invites } = this.props;
      let count = 1;
      const mobileInvitesData = [];
      const invitesData = invites && Array.isArray(invites) && invites.map((val) => {
        const { status = '', emailId, isParentCompanyUser = false } = val;
        const iStatus = status && typeof status ==='string'?status.toLowerCase():'';
        count += 1;
        val.status = (
          <div>
            {(iStatus === 'pending' ? (
              <div className="mt-2 ml-1 badge badge-outline-warning">{val.status}</div>
            ) : (<div className="mt-2 ml-1 badge badge-outline-success">{val.status}</div>))}
          </div>
        );
        const Remaining = {
          id: count.toString(),
          action: (
            <div>
              { (iStatus === 'pending') && <Button className="mr-2" kind="tertiary" small onClick={() => this.onResendInvite(emailId)}>Re-Invite</Button> }
              { !isParentCompanyUser && <Button kind="danger" small onClick={() => this.onRemoveUser(emailId)}>Remove</Button>}
            </div>
          ),
        };
        val.emailId = (
          <div>
            {val.userInfo ? 
              (<div><Avatar user={{
                            firstname : val.userInfo && val.userInfo.firstname,
                            lastname :  val.userInfo && val.userInfo.lastname,
                            picture: val.userInfo && val.userInfo.picture
                }} size="30" classNameInfo="rounded-circle mr-lg-1" />
                  <span className="pr-lg-1 ml-1 text-uppercase">{val.userInfo && val.userInfo.firstname ? val.userInfo && val.userInfo.firstname : ''}</span>
                  <span className="pr-lg-1 text-uppercase">{val.userInfo && val.userInfo.lastname ? val.userInfo && val.userInfo.lastname : ''}</span>
                  <span><small className="text-dark">({val.emailId})</small></span>
              </div>) : 
              <div className="ml-1">{val.emailId}</div>
            }

          </div>
        );
        val.companyName= (
          <div>
            <span className="pr-lg-1 ml-1 text-uppercase">{val.userInfo && val.userInfo.company && val.userInfo.company.companyName ? val.userInfo.company.companyName  : ''}</span>
          </div>
        )
        val = Object.assign({}, { ...val }, { ...Remaining });
        mobileInvitesData.push({ id: val.id, userInvite: this.CardRowBox(val) });
        return val;
      });
      if (invitesData) {
        this.setState({
          rowsData: invitesData,
          rowData: mobileInvitesData,
          addNewUserModal: false,
          emailId: '',
          isInvalidEmail: true,
        });
      }
    });
  }

  onAddUser = () => {
    this.setState({ addNewUserModal: true, isInvalidEmail:true });
  }

  isValidEmail = (emailId) => {
    const emailRegEx = new RegExp(/\S+@\S+\.\S+/);
    const isEmailValid = emailRegEx.test(emailId);
    if (!emailId || !isEmailValid) {
      this.setState({ isInvalidEmail: false });
      return false;
    }
    return true;
  }

  onResendInvite = (emailId) => {
    if (!isEmail(emailId+'')){this.setState({isInvalidEmail:false}); return};
    const data = [{ emailId }];
    this.props.sendInvite(data)
      .then(({ error } = {}) => {
        this.setState({
          isResendInvite: true,
        });
        if (!error) {
          // this.prepareRowData();
        }
      });
  }

  onclickInvite = () => {
    const { emailId } = this.state;
    if(emailId.length == 0){
      this.setState({isInvalidEmail:false});
      return;
    }
    if (!isEmail(emailId+'')) {this.setState({isInvalidEmail:false}); return};
    this.setState({addNewUserModal: false, isInvalidEmail:true});
    const data = [{ emailId:emailId, isAdmin:this.state.isAdmin, }];
    this.props.sendInvite(data)
      .then((res) => {
        if (res[0].name == 'error') {
          this.setState({isEmailExist:true,addNewUserModal: false,},() => {setTimeout(this.onCloseAlert,10000)});
        }
        else{
          this.setState({isEmailExist:false,isAdmin:false,});
          this.prepareRowData();
        }
      });
  }

  onRemoveUser = (emailId) => {
    if (!isEmail(emailId+'')) {this.setState({isInvalidEmail:false}); return};
    const data = [{ emailId }];
    this.props.deleteInvite(data)
      .then(({ error } = {}) => {
        if (!error) {
          this.prepareRowData();
        }
      });
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const limit = data.pageSize || this.state.limit;
    this.setState({ activePage, limit }, () =>
      this.prepareRowData({
        limit: limit,
        skip: (activePage - 1) * limit,
      }));
  };

  onNewEmailChange = (value) => {
    this.setState({ emailId: value });
  }

  onToggle = () => {
    this.setState({ isAdmin: !this.state.isAdmin });
  }

  onCloseAlert = () => {
    this.setState({ isEmailExist: false });
  }

  render() {
    const { isUserLoggedIn = false, user: { roleId = 0, company: { ctypeId } = {}, currentCompany: { companyName = 'company' } = {} }, match } = this.props;
    const {
      emailId, isInvalidEmail, addNewUserModal, rowsData, headersInfo, isResendInvite, isEmailExist,
      headersData, rowData,
    } = this.state;
    const paths = [
      { id: 'roboroy', name: 'ROBOROY', href: '/app/campus' },
      { id: 'admin', name: 'Settings', href: '/app/admin' }
    ];

    return (
      <div className="mt-2">
        <Loading  active={this.props.loading} className="processing" />
        <div className="container grid-lg">
          <BackRow paths={paths} />        
          {/* <InlineNotification lowContrast
            title={`This is the Admin section for ${companyName}. Here’s where you can grant additional users access to this application.
            Also manage companies for which job posting can be created.`}
            subtitle=""
            iconDescription="describes the close button"
            kind="info"
            hideCloseButton
          />       
          {
            isResendInvite && (
            <InlineNotification lowContrast
              title="Email invitation resent successfully"
              subtitle=""
              iconDescription="close notification"
              kind="success"
            />)
          }
          {
            isEmailExist && (
            <InlineNotification lowContrast
              title="Email already exists"
              subtitle=""
              iconDescription="close notification"
              onCloseButtonClick={this.onCloseAlert}
              kind="error"
            />)
          } */}
          
          <div className="form-group mb-lg-5">
            <Tabs selected={0}>
              {/* <Tab label="Users" onClick={this.prepareRowData}>
                <Modal
                  open={addNewUserModal}
                  modalHeading="Invite User"
                  modalLabel="User Mail"
                  primaryButtonText="Invite"
                  secondaryButtonText="Cancel"
                  iconDescription="Close the modal"
                  onRequestClose={() => { this.setState({ addNewUserModal: false, isAdmin: false, emailId: '' }); }}
                  onRequestSubmit={() => this.onclickInvite()}
                  onSecondarySubmit={() => { this.setState({ addNewUserModal: false, isAdmin: false, emailId: '' }); }}
                >
                  <div className="">
                    <TextInput
                      type="email"
                      id="userEmail"
                      labelText="Email address:"
                      placeholder="Email address"
                      onChange={(ele) => { this.onNewEmailChange(ele.target.value); }}
                      maxLength={80}
                      value={emailId}
                      invalid={!isInvalidEmail}
                      invalidText="A valid email address is required"
                      required
                    />
                    <div className="mt-3">
                      <label className="bx--label">Set as administrator:</label>
                      <Toggle toggled={this.state.isAdmin} id="toggle" onToggle={this.onToggle} labelA="No" labelB="Yes"/>
                    </div>
                  </div>
                </Modal>
                <div className="d-none d-lg-block">
                  <DataTable
                    rows={rowsData}
                    headers={headersInfo}
                    render={({ rows, headers, getHeaderProps }) => {
                      return (
                        <TableContainer title="" className="mt-4">
                          <TableToolbar>
                            <TableToolbarContent>
                              <Button onClick={this.onAddUser} small kind="primary">
                                Add user
                              </Button>
                            </TableToolbarContent>
                          </TableToolbar>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {headers.map(header => (
                                  <TableHeader {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map(row => (
                                <TableRow key={row.id}>
                                  {row.cells.map(cell => (
                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>);
                    }}
                  />
                </div>
                {
                  <div className="d-lg-none">
                    <DataTable
                      rows={rowData}
                      headers={headersData}
                      render={({ rows, headers, getHeaderProps }) => (
                      <TableContainer title="" className="bg-transparent mt-2" style={{ overflow: "hidden" }}>
                        <TableToolbar>
                          <TableToolbarContent>
                            <Button onClick={this.onAddUser} small kind="primary">
                              Add user
                            </Button>
                          </TableToolbarContent>
                        </TableToolbar>
                        <Table className="bg-transparent" style={{ borderSpacing: '0px 6px', borderCollapse: 'separate' }}>
                          <TableBody className="bg-transparent">
                            {rows.map(row => (
                              <TableRow key={row.id} className="mb-2">
                                {row.cells.map(cell => (
                                  <TableCell
                                    key={cell.id}
                                    className="pr-0 pl-0"
                                  >
                                    {cell.value}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>)}
                  />
                  </div>
                }
                {this.props.count > 0 && (
                  <Pagination
                    onChange={this.handlePaginationChange}
                    pageSizes={[10, 20, 30, 40, 50]}
                    page={this.state.activePage}
                    totalItems={this.props.count} 
                  />)}
                {this.state.rowsData && this.state.rowsData.length <= 0 && (<NoUsersBox />)}
              </Tab> */}
              {
                ctypeId && Number(ctypeId) === 1
                  && (
                    <Tab label="Agencies" onClick={() => this.props.getAuthorizedCompanies({})}><CompanyDetails /></Tab>
                  )
              }
              {
                ctypeId && Number(ctypeId) === 2
                  && (
                    <Tab label="Managed Companies" onClick={() => this.props.getAuthorizedCompanies({})}><AgencyDetails history={this.props.history} /></Tab>
                  )
              }
              {/* <Tab label="Emails" onClick={this.props.getCompanyInfo}><AdminSettings /></Tab> */}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
  profile: state.profile.userInfo,
  invites: state.AdminDashboard.invites,
  count: state.AdminDashboard.count,
  loading: state.AdminDashboard.loading,
});

const mapDispatchToProps = {
  sendInvite,
  getInvites,
  deleteInvite,
  getAuthorizedCompanies,
  getCompanyInfo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard));
