import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Accordion, AccordionItem } from 'carbon-components-react';
import BackRow from 'components/common/BackRow/BackRow';
import { Tile } from 'carbon-components-react';

class HelpSection extends Component {
	render () {
		const paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus' },
      { id: 'help', name: 'Help Section', href: '' },
    ];
		return (
			<Fragment>
				<BackRow paths={paths}/>
				<Tile>
					<Accordion>
						<AccordionItem title = "How to create a job in Roboroy?">
							<iframe 
								width="560" height="315" 
								src="https://www.youtube.com/embed/SaqZNPTiVW4" 
								frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
							</iframe>
						</AccordionItem>
						<AccordionItem title = "How to upload a resume in Roboroy?">
							<iframe 
								width="560" height="315" 
								src="https://www.youtube.com/embed/tUJkJHgPZVc" 
								frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
							</iframe>
						</AccordionItem>
						<AccordionItem title = "How to shortlist or reject an applicant?">
							<iframe 
								width="560" height="315" 
								src="https://www.youtube.com/embed/xOh14yUink8" 
								frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
							</iframe>
						</AccordionItem>
						<AccordionItem title = "Setting up an interview workflow for an applicant">
							<iframe 
								width="560" height="315" 
								src="https://www.youtube.com/embed/2BoDiEC6hGU" 
								frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
							</iframe>
						</AccordionItem>
						<AccordionItem title = "Managing your recruitment process in Roboroy">
							<iframe 
								width="560" height="315" 
								src="https://www.youtube.com/embed/Ujdq-m5Ks7Y" 
								frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
							</iframe>
						</AccordionItem>
						<AccordionItem title = "Setting up Admin section">
							<iframe 
								width="560" height="315" 
								src="https://www.youtube.com/embed/CxgLHlJFi1w" 
								frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
							</iframe>
						</AccordionItem>
					</Accordion>
				</Tile>
			</Fragment>
		);
	}
}

export default HelpSection;