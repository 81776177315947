import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { REQUEST_DASHBOARD_DATA, REQUEST_DASHBOARD_WORKFLOW_SUBSTAGES_DATA } from '../actionTypes';

// const url = `${baseUrl}/Profiles/findByUser?fromTalentPool=true&_=${new Date().getTime()}`;
const fetchDashboard = () => (dispatch) => {
  const url = `${baseUrl}/jobs/activities?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_DASHBOARD_DATA,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getWorkflowSubstages = ({
  status,
  itemsPerPage = 0,
}) => (dispatch) => {
  const url = `${baseUrl}/workflowsubstage?status=${status}&filter[limit]=${itemsPerPage}&_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_DASHBOARD_WORKFLOW_SUBSTAGES_DATA,
    url,
    method: 'GET',
  };

  return callApi(params);
};

export {
  fetchDashboard,
  getWorkflowSubstages,
};
