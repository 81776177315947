import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavItemsList from 'components/Header/navItemsList';
import {
  SideNav,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SideNavMenuItem,
} from 'carbon-components-react/lib/components/UIShell';
import {
  Home16, Search16, Events16, UserAvatar16,
  Identification16, Tools16, ChartBar16,
  Migrate16, UserProfile16, Logout16, Compare16,
  Development16, Building16, Education16,
  Document16, EventsAlt16, Video16, EventSchedule16,
  Partnership16, Email16,
} from '@carbon/icons-react';
import './SideNav.css';

class SideNavList extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleSubItemsSvgs = (name) => {
    let svg = '';
    switch (name) {
      case 'jobs':
        svg = <Document16 />;
        break;
      case 'applicants':
        svg = <EventsAlt16 />;
        break;
      case 'recruiterTalentpool':
        svg = <Video16 />;
        break;
      case 'calendar':
        svg = <EventSchedule16 />;
        break;
      case 'offers':
        svg = <Partnership16 />;
        break;
      case 'emailTemplates':
        svg = <Email16 />;
        break;
      case 'settings':
        svg = <Migrate16 />;
        break;
      case 'myProfile':
        svg = <UserProfile16 />;
        break;
      default:
        svg = <Home16 />;
        break;
    }
    return svg;
  };

  handleCurrentSvgs = (name) => {
    let svg = '';
    switch (name) {
      case 'dashboard':
        svg = <Home16 />;
        break;
      case 'companies':
        svg = <Development16 />;
        break;
      case 'institutions':
        svg = <Building16 />;
        break;
      case 'students':
        svg = <Education16 />;
        break;
      case 'source':
        svg = <Events16 />;
        break;
      case 'browse':
        svg = <Search16 />;
        break;
      case 'hire':
        svg = <Identification16 />;
        break;
      case 'admin':
        svg = <Tools16 />;
        break;
      case 'myAnalytics':
        svg = <ChartBar16 />;
        break;
      case 'profile':
        svg = <UserProfile16 />;
        break;
      case 'browseJobs':
        svg = <Search16 />;
        break;
      default:
        svg = <Home16 />;
        break;
    }
    return svg;
  };

  render() {
    const {
      user: {
        roleId,
        currentCompany,
      } = {},
      t,
      isSideNavExpanded,
    } = this.props || {};
    const {
      ctypeId,
    } = currentCompany || {};
    return (
      <Fragment>
        <SideNav aria-label="Side navigation" expanded={isSideNavExpanded}>
          <SideNavItems>
            {
              NavItemsList && Array.isArray(NavItemsList)
                && NavItemsList.length > 0
                && NavItemsList.map((navItems, idx) => {
                  const {
                    label,
                    path,
                    roles,
                    accepts,
                    ctypeId: propCtypeId,
                    subItems,
                  } = navItems || {};
                  return (
                    <Fragment>
                      {
                        subItems && Array.isArray(subItems)
                        && subItems.length <= 0
                          ? (
                            <Fragment>
                              {
                                roles && Array.isArray(roles)
                                  && roles.includes(roleId && Number(roleId))
                                  && (roleId && Number(roleId) !== 3 ? propCtypeId && Array.isArray(propCtypeId)
                                  && propCtypeId.includes(ctypeId && Number(ctypeId)) : true)
                                  && (
                                    <SideNavMenuItem className="labels-color" element={Link} to={path} href="javascript:void(0)">
                                      {this.handleCurrentSvgs(label)}&nbsp;&nbsp;{t(label)}
                                    </SideNavMenuItem>
                                  )
                              }
                            </Fragment>
                          ) : (
                            <Fragment>
                              {
                                roles && Array.isArray(roles)
                                && roles.includes(roleId && Number(roleId))
                                && (roleId && Number(roleId) !== 3 ? propCtypeId && Array.isArray(propCtypeId)
                                && propCtypeId.includes(ctypeId && Number(ctypeId)) : true)
                                && (
                                  <SideNavMenu aria-label={t(label)} key={`${label}-${idx + 1}`} title={<div className="labels-color">{this.handleCurrentSvgs(label)}&nbsp;&nbsp;<b>{t(label)}</b></div>}>
                                    {
                                      subItems.map((subItms, index) => {
                                        const {
                                          subItemsLabel,
                                          subItemsIcon,
                                          subItemsPath,
                                          accepts,
                                          ctypeId: subCtypeId,
                                        } = subItms || {};
                                        if (subCtypeId && Array.isArray(subCtypeId)
                                        && subCtypeId.includes(ctypeId && Number(ctypeId))) {
                                          return (
                                            <SideNavMenuItem key={`${subItemsLabel}-${index + 1}`} element={Link} to={subItemsPath}>
                                              <span className="labels-color">{this.handleSubItemsSvgs(subItemsLabel)}&nbsp;&nbsp;<b>{t(subItemsLabel)}</b></span>
                                            </SideNavMenuItem>
                                          );
                                        }
                                        return <div />;
                                      })
                                    }
                                  </SideNavMenu>
                                )
                              }
                            </Fragment>
                          )
                      }
                    </Fragment>
                  )
                })
            }
          </SideNavItems>
        </SideNav>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  taskStatus: state.taskStatus,
});

export default connect(mapStateToProps, {})(withTranslation()(SideNavList));