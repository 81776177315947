export const REQUEST_XOPA_APPLICATIONS = 'REQUEST_XOPA_APPLICATIONS';
export const ERROR_XOPA_APPLICATIONS = 'ERROR_XOPA_APPLICATIONS';
export const SUCCESS_XOPA_APPLICATIONS = 'SUCCESS_XOPA_APPLICATIONS';

export const REQUEST_USER_TALENTPOOL_SHARE = 'REQUEST_USER_TALENTPOOL_SHARE';
export const SUCCESS_USER_TALENTPOOL_SHARE = 'SUCCESS_USER_TALENTPOOL_SHARE';
export const ERROR_USER_TALENTPOOL_SHARE = 'ERROR_USER_TALENTPOOL_SHARE';

export const REQUEST_USER_TALENTPOOL_SHARE_INFO = 'REQUEST_USER_TALENTPOOL_SHARE_INFO';
export const SUCCESS_USER_TALENTPOOL_SHARE_INFO = 'SUCCESS_USER_TALENTPOOL_SHARE_INFO';
export const ERROR_USER_TALENTPOOL_SHARE_INFO = 'ERROR_USER_TALENTPOOL_SHARE_INFO';

export const REQUEST_GET_INTERVIEW_FEEDBACK = 'REQUEST_GET_INTERVIEW_FEEDBACK';
export const ERROR_GET_INTERVIEW_FEEDBACK = 'ERROR_GET_INTERVIEW_FEEDBACK';
export const SUCCESS_GET_INTERVIEW_FEEDBACK = 'SUCCESS_GET_INTERVIEW_FEEDBACK';

export const REQUEST_CREATE_INTERVIEW_FEEDBACK = 'REQUEST_CREATE_INTERVIEW_FEEDBACK';
export const ERROR_CREATE_INTERVIEW_FEEDBACK = 'ERROR_CREATE_INTERVIEW_FEEDBACK';
export const SUCCESS_CREATE_INTERVIEW_FEEDBACK = 'SUCCESS_CREATE_INTERVIEW_FEEDBACK';

export const REQUEST_UPSERT_INTERVIEW_FEEDBACK = 'REQUEST_UPSERT_INTERVIEW_FEEDBACK';
export const ERROR_UPSERT_INTERVIEW_FEEDBACK = 'ERROR_UPSERT_INTERVIEW_FEEDBACK';
export const SUCCESS_UPSERT_INTERVIEW_FEEDBACK = 'SUCCESS_UPSERT_INTERVIEW_FEEDBACK';

export const REQUEST_TALENTPOOL = 'REQUEST_TALENTPOOL';
export const ERROR_TALENTPOOL = 'ERROR_TALENTPOOL';
export const SUCCESS_TALENTPOOL = 'SUCCESS_TALENTPOOL';

export const REQUEST_SEND_CUSTOMEMAIL = 'REQUEST_SEND_CUSTOMEMAIL';
export const SUCCESS_SEND_CUSTOMEMAIL = 'SUCCESS_SEND_CUSTOMEMAIL';

export const REQUEST_SEND_EMAIL_TO_COMPANIES = 'REQUEST_SEND_EMAIL_TO_COMPANIES';
export const SUCCESS_SEND_EMAIL_TO_COMPANIES = 'SUCCESS_SEND_EMAIL_TO_COMPANIES';

export const REQUEST_DELETE_DOCUMENT = 'REQUEST_DELETE_DOCUMENT';
export const ERROR_DELETE_DOCUMENT = 'ERROR_DELETE_DOCUMENT';
export const SUCCESS_DELETE_DOCUMENT = 'SUCCESS_DELETE_DOCUMENT';

export const REQUEST_USER_LOGIN_INFO = 'REQUEST_USER_LOGIN_INFO';
export const SUCCESS_USER_LOGIN_INFO = 'SUCCESS_USER_LOGIN_INFO';
export const ERROR_USER_LOGIN_INFO = 'ERROR_USER_LOGIN_INFO';

export const REQUEST_GET_USER_LOGIN_INFO = 'REQUEST_GET_USER_LOGIN_INFO';
export const SUCCESS_GET_USER_LOGIN_INFO = 'SUCCESS_GET_USER_LOGIN_INFO';
export const ERROR_GET_USER_LOGIN_INFO = 'ERROR_GET_USER_LOGIN_INFO';

export const REQUEST_GET_USER_LOGIN = 'REQUEST_GET_USER_LOGIN';
export const SUCCESS_GET_USER_LOGIN = 'SUCCESS_GET_USER_LOGIN';
export const ERROR_GET_USER_LOGIN = 'ERROR_GET_USER_LOGIN';

export const REQUEST_USER_EMAIL_INFO = 'REQUEST_USER_EMAIL_INFO';
export const SUCCESS_USER_EMAIL_INFO = 'SUCCESS_USER_EMAIL_INFO';
export const ERROR_USER_EMAIL_INFO = 'ERROR_USER_EMAIL_INFO';

export const REQUEST_GET_USER_EMAIL_INFO = 'REQUEST_GET_USER_EMAIL_INFO';
export const SUCCESS_GET_USER_EMAIL_INFO = 'SUCCESS_GET_USER_EMAIL_INFO';
export const ERROR_GET_USER_EMAIL_INFO = 'ERROR_GET_USER_EMAIL_INFO';

// user email notifications
export const REQUEST_USER_EMAIL_NOTIFICATIONS = 'REQUEST_USER_EMAIL_NOTIFICATIONS';
export const ERROR_USER_EMAIL_NOTIFICATIONS = 'ERROR_USER_EMAIL_NOTIFICATIONS';
export const SUCCESS_USER_EMAIL_NOTIFICATIONS = 'SUCCESS_USER_EMAIL_NOTIFICATIONS';

// ------------------------------------------------------------------------------------------

// transferjob
export const REQUEST_COMPANY_JOB_POSTINGS = 'REQUEST_COMPANY_JOB_POSTINGS';
export const ERROR_COMPANY_JOB_POSTINGS = 'ERROR_COMPANY_JOB_POSTINGS';
export const SUCCESS_COMPANY_JOB_POSTINGS = 'SUCCESS_COMPANY_JOB_POSTINGS';

export const REQUEST_TRANSFER_JOB = 'REQUEST_TRANSFER_JOB';
export const ERROR_TRANSFER_JOB = 'ERROR_TRANSFER_JOB';
export const SUCCESS_TRANSFER_JOB = 'SUCCESS_TRANSFER_JOB';



// ------------------------------------------------------------------------------------------
export const REQUEST_BUCKETS = 'REQUEST_BUCKETS';
export const ERROR_BUCKETS = 'ERROR_BUCKETS';
export const SUCCESS_BUCKETS = 'SUCCESS_BUCKETS';

export const REQUEST_GET_BUCKETS = 'REQUEST_GET_BUCKETS';
export const SUCCESS_GET_BUCKETS = 'SUCCESS_GET_BUCKETS';
export const ERROR_GET_BUCKETS = 'ERROR_GET_BUCKETS';

export const REQUEST_EDIT_BUCKETS = 'REQUEST_EDIT_BUCKETS';
export const SUCCESS_EDIT_BUCKETS = 'SUCCESS_EDIT_BUCKETS';
export const ERROR_EDIT_BUCKETS = 'ERROR_EDIT_BUCKETS';

export const REQUEST_DEL_BUCKETS = 'REQUEST_DEL_BUCKETS';
export const SUCCESS_DEL_BUCKETS = 'SUCCESS_DEL_BUCKETS';
export const ERROR_DEL_BUCKETS = 'ERROR_DEL_BUCKETS';

export const REQUEST_ADD_CANDIDATES_BUCKETS = 'REQUEST_ADD_CANDIDATES_BUCKETS';
export const SUCCESS_ADD_CANDIDATES_BUCKETS = 'SUCCESS_ADD_CANDIDATES_BUCKETS';
export const ERROR_ADD_CANDIDATES_BUCKETS = 'ERROR_ADD_CANDIDATES_BUCKETS';

export const REQUEST_COMPANIES_DATA = 'REQUEST_COMPANIES_DATA';
export const SUCCESS_COMPANIES_DATA = 'SUCCESS_COMPANIES_DATA';
export const ERROR_COMPANIES_DATA = 'ERROR_COMPANIES_DATA';

export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA';
export const ERROR_DASHBOARD_DATA = 'ERROR_DASHBOARD_DATA';
export const SUCCESS_DASHBOARD_DATA = 'SUCCESS_DASHBOARD_DATA';

export const REQUEST_CANDIDATE_LIST_DATA = 'REQUEST_CANDIDATE_LIST_DATA';
export const ERROR_CANDIDATE_LIST_DATA = 'ERROR_CANDIDATE_LIST_DATA';
export const SUCCESS_CANDIDATE_LIST_DATA = 'SUCCESS_CANDIDATE_LIST_DATA';

export const REQUEST_RESUME_UPLOAD = 'REQUEST_RESUME_UPLOAD';
export const ERROR_RESUME_UPLOAD = 'ERROR_RESUME_UPLOAD';
export const SUCCESS_RESUME_UPLOAD = 'SUCCESS_RESUME_UPLOAD';

export const REQUEST_JOB_DATA = 'REQUEST_JOB_DATA';
export const ERROR_JOB_DATA = 'ERROR_JOB_DATA';
export const SUCCESS_JOB_DATA = 'SUCCESS_JOB_DATA';

// batch management view
export const REQUEST_BATCH_DETAILS = 'REQUEST_BATCH_DETAILS';
export const SUCCESS_BATCH_DETAILS = 'SUCCESS_BATCH_DETAILS';
export const ERROR_BATCH_DETAILS = 'ERROR_BATCH_DETAILS';

export const REQUEST_SET_CURRENT_BATCH = 'REQUEST_SET_CURRENT_BATCH';
export const SUCCESS_SET_CURRENT_BATCH = 'SUCCESS_SET_CURRENT_BATCH';
export const ERROR_SET_CURRENT_BATCH = 'ERROR_SET_CURRENT_BATCH';

export const REQUEST_SET_ACTIVE_BATCH = 'REQUEST_SET_ACTIVE_BATCH';
export const SUCCESS_SET_ACTIVE_BATCH = 'SUCCESS_SET_ACTIVE_BATCH';
export const ERROR_SET_ACTIVE_BATCH = 'ERROR_SET_ACTIVE_BATCH';

export const REQUEST_ADD_NEW_BATCH = 'REQUEST_ADD_NEW_BATCH';
export const SUCCESS_ADD_NEW_BATCH = 'SUCCESS_ADD_NEW_BATCH';
export const ERROR_ADD_NEW_BATCH = 'ERROR_ADD_NEW_BATCH';

export const REQUEST_EDIT_BATCH = 'REQUEST_EDIT_BATCH';
export const SUCCESS_EDIT_BATCH = 'SUCCESS_EDIT_BATCH';
export const ERROR_EDIT_BATCH = 'ERROR_ADD_NEW_BATCH';

export const REQUEST_CLOSE_BATCH_JOBS = 'REQUEST_CLOSE_BATCH_JOBS';
export const SUCCESS_CLOSE_BATCH_JOBS = 'SUCCESS_CLOSE_BATCH_JOBS';
export const ERROR_CLOSE_BATCH_JOBS = 'ERROR_CLOSE_BATCH_JOBS';

export const REQUEST_BATCH_JOBS_LIST = 'REQUEST_BATCH_JOBS_LIST';
export const SUCCESS_BATCH_JOBS_LIST = 'SUCCESS_BATCH_JOBS_LIST';
export const ERROR_BATCH_JOBS_LIST = 'ERROR_BATCH_JOBS_LIST';


// ---------------------------------------------------------------------------

export const REQUEST_TALENTPOOL_SEARCH = 'REQUEST_TALENTPOOL_SEARCH';
export const ERROR_TALENTPOOL_SEARCH = 'ERROR_TALENTPOOL_SEARCH';
export const SUCCESS_TALENTPOOL_SEARCH = 'SUCCESS_TALENTPOOL_SEARCH';

export const REQUEST_TASK_STATUS = 'REQUEST_TASK_STATUS';
export const ERROR_TASK_STATUS = 'ERROR_TASK_STATUS';
export const SUCCESS_TASK_STATUS = 'SUCCESS_TASK_STATUS';

export const REQUEST_FACETS_DATA = 'REQUEST_FACETS_DATA';
export const ERROR_FACETS_DATA = 'ERROR_FACETS_DATA';
export const SUCCESS_FACETS_DATA = 'SUCCESS_FACETS_DATA';

export const REQUEST_FACETS_RESULT = 'REQUEST_FACETS_RESULT';
export const ERROR_FACETS_RESULT = 'ERROR_FACETS_RESULT';
export const SUCCESS_FACETS_RESULT = 'SUCCESS_FACETS_RESULT';

export const REQUEST_SIGNUP = 'REQUEST_SIGNUP';
export const SUCCESS_SIGNUP = 'SUCCESS_SIGNUP';
export const REQUEST_ALL_COUNTRIES = 'REQUEST_ALL_COUNTRIES';
export const SUCCESS_ALL_COUNTRIES = 'SUCCESS_ALL_COUNTRIES';
export const REQUEST_ALL_STATES = 'REQUEST_ALL_STATES';
export const SUCCESS_ALL_STATES = 'SUCCESS_ALL_STATES';
export const REQUEST_ALL_ROLES = 'REQUEST_ALL_ROLES';
export const SUCCESS_ALL_ROLES = 'SUCCESS_ALL_ROLES';

// RoboCampus
export const REQUEST_REGISTRATION_LINK = 'REQUEST_REGISTRATION_LINK';
export const SUCCESS_REGISTRATION_LINK = 'SUCCESS_REGISTRATION_LINK';
export const ERROR_REGISTRATION_LINK = 'ERROR_REGISTRATION_LINK';

export const REQUEST_REGISTER_STUDENT = 'REQUEST_REGISTER_STUDENT';
export const SUCCESS_REGISTER_STUDENT = 'SUCCESS_REGISTER_STUDENT';
export const ERROR_REGISTER_STUDENT = 'ERROR_REGISTER_STUDENT';

export const REQUEST_ALL_QUALTYPE = 'REQUEST_ALL_QUALTYPE';
export const ERROR_ALL_QUALTYPE = 'ERROR_ALL_QUALTYPE';
export const SUCCESS_ALL_QUALTYPE = 'SUCCESS_ALL_QUALTYPE';

export const REQUEST_ALL_QUALIFICATION = 'REQUEST_ALL_QUALIFICATION';
export const ERROR_ALL_QUALIFICATION = 'ERROR_ALL_QUALIFICATION';
export const SUCCESS_ALL_QUALIFICATION = 'SUCCESS_ALL_QUALIFICATION';

export const REQUEST_ALL_HOURTYPE = 'REQUEST_ALL_HOURTYPE';
export const ERROR_ALL_HOURTYPE = 'ERROR_ALL_HOURTYPE';
export const SUCCESS_ALL_HOURTYPE = 'SUCCESS_ALL_HOURTYPE';

export const REQUEST_ALL_MAJOR = 'REQUEST_ALL_MAJOR';
export const ERROR_ALL_MAJOR = 'ERROR_ALL_MAJOR';
export const SUCCESS_ALL_MAJOR = 'SUCCESS_ALL_MAJOR';

export const REQUEST_ALL_COUNTRY = 'REQUEST_ALL_COUNTRY';
export const ERROR_ALL_COUNTRY = 'ERROR_ALL_COUNTRY';
export const SUCCESS_ALL_COUNTRY = 'SUCCESS_ALL_COUNTRY';

export const REQUEST_FEW_SCHOOLS = 'REQUEST_FEW_SCHOOLS';
export const ERROR_FEW_SCHOOLS = 'ERROR_FEW_SCHOOLS';
export const SUCCESS_FEW_SCHOOLS = 'SUCCESS_FEW_SCHOOLS';

export const REQUEST_CREATE_NEW_SCHOOL = 'REQUEST_CREATE_NEW_SCHOOL';
export const SUCCESS_CREATE_NEW_SCHOOL = 'SUCCESS_CREATE_NEW_SCHOOL';

export const REQUEST_CREATE_NEW_CERTIFICATE = 'REQUEST_CREATE_NEW_CERTIFICATE';
export const SUCCESS_CREATE_NEW_CERTIFICATE = 'SUCCESS_CREATE_NEW_CERTIFICATE';

export const REQUEST_CREATE_NEW_ORGANIZATION = 'REQUEST_CREATE_NEW_ORGANIZATION';
export const SUCCESS_CREATE_NEW_ORGANIZATION = 'SUCCESS_CREATE_NEW_ORGANIZATION';

export const REQUEST_CREATE_NEW_ACTIVITYTYPE = 'REQUEST_CREATE_NEW_ACTIVITYTYPE';
export const SUCCESS_CREATE_NEW_ACTIVITYTYPE = 'SUCCESS_CREATE_NEW_ACTIVITYTYPE';

export const REQUEST_CREATE_NEW_HOBBY = 'REQUEST_CREATE_NEW_HOBBY';
export const SUCCESS_CREATE_NEW_HOBBY = 'SUCCESS_CREATE_NEW_HOBBY';

export const REQUEST_FEW_CERTIFICATES = 'REQUEST_FEW_CERTIFICATES';
export const SUCCESS_FEW_CERTIFICATES = 'SUCCESS_FEW_CERTIFICATES';

export const REQUEST_FEW_ORGANIZATION = 'REQUEST_FEW_ORGANIZATION';
export const SUCCESS_FEW_ORGANIZATION = 'SUCCESS_FEW_ORGANIZATION';

export const REQUEST_FEW_ACTIVITIES = 'REQUEST_FEW_ACTIVITIES';
export const SUCCESS_FEW_ACTIVITIES = 'SUCCESS_FEW_ACTIVITIES';

export const REQUEST_FEW_ACTIVITIESTYPES = 'REQUEST_FEW_ACTIVITIESTYPES';
export const SUCCESS_FEW_ACTIVITIESTYPES = 'SUCCESS_FEW_ACTIVITIESTYPES';

export const REQUEST_FEW_HOBBIES = 'REQUEST_FEW_HOBBIES';
export const SUCCESS_FEW_HOBBIES = 'SUCCESS_FEW_HOBBIES';

export const REQUEST_FEW_HOBBYTYPES = 'REQUEST_FEW_HOBBYTYPES';
export const SUCCESS_FEW_HOBBYTYPES = 'SUCCESS_FEW_HOBBYTYPES';

export const REQUEST_FEW_ETHNICITIES = 'REQUEST_FEW_ETHNICITIES';
export const SUCCESS_FEW_ETHNICITIES = 'SUCCESS_FEW_ETHNICITIES';

export const REQUEST_FEW_COURSES = 'REQUEST_FEW_COURSES';
export const SUCCESS_FEW_COURSES = 'SUCCESS_FEW_COURSES';

export const REQUEST_FEW_FACULTY = 'REQUEST_FEW_FACULTY';
export const SUCCESS_FEW_FACULTY = 'SUCCESS_FEW_FACULTY';

export const REQUEST_FEW_CERTTYPE = 'REQUEST_FEW_CERTTYPE';
export const SUCCESS_FEW_CERTTYPE = 'SUCCESS_FEW_CERTTYPE';

export const REQUEST_GET_ID_DATA = 'REQUEST_GET_ID_DATA';
export const SUCCESS_GET_ID_DATA = 'SUCCESS_GET_ID_DATA';

export const REQUEST_CREATE_PROFILEINFO = 'REQUEST_CREATE_PROFILEINFO';
export const REQUEST_UPDATE_PROFILEINFO = 'REQUEST_UPDATE_PROFILEINFO';
export const REQUEST_DELETE_PROFILEINFO = 'REQUEST_DELETE_PROFILEINFO';
export const SUCCESS_CREATE_PROFILEINFO = 'SUCCESS_CREATE_PROFILEINFO';
export const SUCCESS_UPDATE_PROFILEINFO = 'SUCCESS_UPDATE_PROFILEINFO';
export const SUCCESS_DELETE_PROFILEINFO = 'SUCCESS_DELETE_PROFILEINFO';

export const REQUEST_REMOVE_PROFILESKILL = 'REQUEST_REMOVE_PROFILESKILL';
export const REQUEST_ADD_PROFILESKILL = 'REQUEST_ADD_PROFILESKILL';
export const SUCCESS_REMOVE_PROFILESKILL = 'SUCCESS_REMOVE_PROFILESKILL';
export const SUCCESS_ADD_PROFILESKILL = 'SUCCESS_ADD_PROFILESKILL';

export const REQUEST_CREATE_NEW_QUALIFICATION = 'REQUEST_CREATE_NEW_QUALIFICATION';
export const SUCCESS_CREATE_NEW_QUALIFICATION = 'SUCCESS_CREATE_NEW_QUALIFICATION';

export const REQUEST_UPDATE_PROFILE = 'REQUEST_UPDATE_PROFILE';
export const SUCCESS_UPDATE_PROFILE = 'SUCCESS_UPDATE_PROFILE';

export const REQUEST_ALL_PROFILE_COURSES = 'REQUEST_ALL_PROFILE_COURSES';
export const SUCCESS_ALL_PROFILE_COURSES = 'SUCCESS_ALL_PROFILE_COURSES';
export const ERROR_ALL_PROFILE_COURSES = 'ERROR_ALL_PROFILE_COURSES';

export const REQUEST_ALL_SCHOOL_FACULTIES = 'REQUEST_ALL_SCHOOL_FACULTIES';
export const SUCCESS_ALL_SCHOOL_FACULTIES = 'SUCCESS_ALL_SCHOOL_FACULTIES';
export const ERROR_ALL_SCHOOL_FACULTIES = 'ERROR_ALL_SCHOOL_FACULTIES';

export const REQUEST_CREATE_PROFILE_COURSES = 'REQUEST_CREATE_PROFILE_COURSES';
export const SUCCESS_CREATE_PROFILE_COURSES = 'SUCCESS_CREATE_PROFILE_COURSES';
export const ERROR_CREATE_PROFILE_COURSES = 'ERROR_CREATE_PROFILE_COURSES';

// admin actions
export const REQUEST_ALL_INDUSTRIES = 'REQUEST_ALL_INDUSTRIES';
export const SUCCESS_ALL_INDUSTRIES = 'SUCCESS_ALL_INDUSTRIES';

export const REQUEST_CREATE_NEW_STATE = 'REQUEST_CREATE_NEW_STATE';
export const SUCCESS_CREATE_NEW_STATE = 'SUCCESS_CREATE_NEW_STATE';

export const REQUEST_ALL_COMPANIES = 'REQUEST_ALL_COMPANIES';
export const SUCCESS_ALL_COMPANIES = 'SUCCESS_ALL_COMPANIES';
export const ERROR_ALL_COMPANIES = 'ERROR_ALL_COMPANIES';

export const REQUEST_RECRUITERS_LIST = 'REQUEST_RECRUITERS_LIST';
export const SUCCESS_RECRUITERS_LIST = 'SUCCESS_RECRUITERS_LIST';
export const ERROR_RECRUITERS_LIST = 'ERROR_RECRUITERS_LIST';

export const REQUEST_ADD_AUTHORIZED_COMPANY = 'REQUEST_ADD_AUTHORIZED_COMPANY';
export const SUCCESS_ADD_AUTHORIZED_COMPANY = 'SUCCESS_ADD_AUTHORIZED_COMPANY';
export const ERROR_ADD_AUTHORIZED_COMPANY = 'ERROR_ADD_AUTHORIZED_COMPANY';

export const REQUEST_AUTHORIZE_NEWCOMPANIES = 'REQUEST_AUTHORIZE_NEWCOMPANIES';
export const SUCCESS_AUTHORIZE_NEWCOMPANIES = 'SUCCESS_AUTHORIZE_NEWCOMPANIES';

export const REQUEST_SWITCH_COMPANY = 'REQUEST_SWITCH_COMPANY';
export const SUCCESS_SWITCH_COMPANY = 'SUCCESS_SWITCH_COMPANY';

export const REQUEST_COMPANY_DETAILS = 'REQUEST_COMPANY_DETAILS';
export const SUCCESS_COMPANY_DETAILS = 'SUCCESS_COMPANY_DETAILS';

export const REQUEST_COMPANY_DATA = 'REQUEST_COMPANY_DATA';
export const SUCCESS_COMPANY_DATA = 'SUCCESS_COMPANY_DATA';

export const REQUEST_USER_REGISTER = 'REQUEST_USER_REGISTER';
export const SUCCESS_USER_REGISTER = 'SUCCESS_USER_REGISTER';
export const ERROR_USER_REGISTER = 'ERROR_USER_REGISTER';

export const REQUEST_USER_INVITE = 'REQUEST_USER_INVITE';
export const SUCCESS_USER_INVITE = 'SUCCESS_USER_INVITE';
export const ERROR_USER_INVITE = 'ERROR_USER_INVITE';

export const REQUEST_USER_INVITE_UPDATE = 'REQUEST_USER_INVITE_UPDATE';
export const SUCCESS_USER_INVITE_UPDATE = 'SUCCESS_USER_INVITE_UPDATE';
export const ERROR_USER_INVITE_UPDATE = 'ERROR_USER_INVITE_UPDATE';

export const REQUEST_USER_DELETE = 'REQUEST_USER_DELETE';
export const SUCCESS_USER_DELETE = 'SUCCESS_USER_DELETE';

export const REQUEST_AUTHORIZED_COMPANIES = 'REQUEST_AUTHORIZED_COMPANIES';
export const SUCCESS_AUTHORIZED_COMPANIES = 'SUCCESS_AUTHORIZED_COMPANIES';
export const ERROR_AUTHORIZED_COMPANIES = 'ERROR_AUTHORIZED_COMPANIES';

export const REQUEST_AUTHORIZED_USERS = 'REQUEST_AUTHORIZED_USERS';
export const SUCCESS_AUTHORIZED_USERS = 'SUCCESS_AUTHORIZED_USERS';
export const ERROR_AUTHORIZED_USERS = 'ERROR_AUTHORIZED_USERS';

export const REQUEST_COMPANY_OFFICE_DATA = 'REQUEST_COMPANY_OFFICE_DATA';
export const SUCCESS_COMPANY_OFFICE_DATA = 'SUCCESS_COMPANY_OFFICE_DATA';

export const REQUEST_CREATE_NEW_COMPANY = 'REQUEST_CREATE_NEW_COMPANY';
export const SUCCESS_CREATE_NEW_COMPANY = 'SUCCESS_CREATE_NEW_COMPANY';
export const REQUEST_UPDATE_COMPANY = 'REQUEST_UPDATE_COMPANY';
export const SUCCESS_UPDATE_COMPANY = 'SUCCESS_UPDATE_COMPANY';
export const REQUEST_DELETE_COMPANY = 'REQUEST_DELETE_COMPANY';
export const SUCCESS_DELETE_COMPANY = 'SUCCESS_DELETE_COMPANY';

export const REQUEST_CREATE_NEW_OFFICE = 'REQUEST_CREATE_NEW_OFFICE';
export const SUCCESS_CREATE_NEW_OFFICE = 'SUCCESS_CREATE_NEW_OFFICE';
export const REQUEST_UPDATE_OFFICE = 'REQUEST_UPDATE_OFFICE';
export const SUCCESS_UPDATE_OFFICE = 'SUCCESS_UPDATE_OFFICE';
export const REQUEST_DELETE_OFFICE = 'REQUEST_DELETE_OFFICE';
export const SUCCESS_DELETE_OFFICE = 'SUCCESS_DELETE_OFFICE';

export const REQUEST_UPDATE_OFFICE_DATA = 'REQUEST_UPDATE_OFFICE_DATA';
export const SUCCESS_UPDATE_OFFICE_DATA = 'SUCCESS_UPDATE_OFFICE_DATA';

export const REQUEST_DOMAIN_DATA = 'REQUEST_DOMAIN_DATA';
export const SUCCESS_DOMAIN_DATA = 'SUCCESS_DOMAIN_DATA';

export const SUCCESS_ADD_WRITTENTEST_QUESTIONS = "SUCCESS_ADD_WRITTENTEST_QUESTIONS";
export const REQUEST_ADD_WRITTENTEST_QUESTIONS = "REQUEST_ADD_WRITTENTEST_QUESTIONS";

export const REQUEST_UPDATE_WRITTENTEST_QUESTIONS = "REQUEST_UPDATE_WRITTENTEST_QUESTIONS";
export const SUCCESS_UPDATE_WRITTENTEST_QUESTIONS = "SUCCESS_UPDATE_WRITTENTEST_QUESTIONS";

export const REQUEST_DELETE_WRITTENTEST_QUESTIONS = "REQUEST_DELETE_WRITTENTEST_QUESTIONS";
export const SUCCESS_DELETE_WRITTENTEST_QUESTIONS = "SUCCESS_DELETE_WRITTENTEST_QUESTIONS";

export const REQUEST_WRITTENTEST_QUESTIONS = "REQUEST_WRITTENTEST_QUESTIONS";
export const SUCCESS_WRITTENTEST_QUESTIONS = "SUCCESS_WRITTENTEST_QUESTIONS";

/* Dummy Auth User call */
export const REQUEST_X0PA_DUSER = 'REQUEST_X0PA_DUSER';
export const ERROR_X0PA_DUSER = 'ERROR_X0PA_DUSER';
export const SUCCESS_X0PA_DUSER = 'SUCCESS_X0PA_DUSER';

/* Candidate Specific */
export const REQUEST_CANDASHBOARD_DATA = 'REQUEST_CANDASHBOARD_DATA';
export const SUCCESS_CANDASHBOARD_DATA = 'SUCCESS_CANDASHBOARD_DATA';
export const ERROR_CANDASHBOARD_DATA = 'ERROR_CANDASHBOARD_DATA';

export const REQUEST_ADD_PROFILETOJOB = 'REQUEST_ADD_PROFILETOJOB';
export const SUCCESS_ADD_PROFILETOJOB = 'SUCCESS_ADD_PROFILETOJOB';
export const ERROR_ADD_PROFILETOJOB = 'ERROR_ADD_PROFILETOJOB';

export const REQUEST_CANDIDATE_JOBS = 'REQUEST_CANDIDATE_JOBS';
export const SUCCESS_CANDIDATE_JOBS = 'SUCCESS_CANDIDATE_JOBS';
export const ERROR_CANDIDATE_JOBS = 'ERROR_CANDIDATE_JOBS';

export const REQUEST_INVITE_CANDIDATE_JOBS = 'REQUEST_INVITE_CANDIDATE_JOBS';
export const SUCCESS_INVITE_CANDIDATE_JOBS = 'SUCCESS_INVITE_CANDIDATE_JOBS';
export const ERROR_INVITE_CANDIDATE_JOBS = 'ERROR_INVITE_CANDIDATE_JOBS';

export const REQUEST_APPLIED_CANDIDATE_JOBS = 'REQUEST_APPLIED_CANDIDATE_JOBS';
export const SUCCESS_APPLIED_CANDIDATE_JOBS = 'SUCCESS_APPLIED_CANDIDATE_JOBS';
export const ERROR_APPLIED_CANDIDATE_JOBS = 'ERROR_APPLIED_CANDIDATE_JOBS';

export const REQUEST_INTERVIEWS_CANDIDATE_JOBS = 'REQUEST_INTERVIEWS_CANDIDATE_JOBS';
export const SUCCESS_INTERVIEWS_CANDIDATE_JOBS = 'SUCCESS_INTERVIEWS_CANDIDATE_JOBS';
export const ERROR_INTERVIEWS_CANDIDATE_JOBS = 'ERROR_INTERVIEWS_CANDIDATE_JOBS';

export const REQUEST_OFFERS_CANDIDATE_JOBS = 'REQUEST_OFFERS_CANDIDATE_JOBS';
export const SUCCESS_OFFERS_CANDIDATE_JOBS = 'SUCCESS_OFFERS_CANDIDATE_JOBS';
export const ERROR_OFFERS_CANDIDATE_JOBS = 'ERROR_OFFERS_CANDIDATE_JOBS';

export const REQUEST_CANDIDATE_RECOMMENDED_JOBS = 'REQUEST_CANDIDATE_RECOMMENDED_JOBS';
export const SUCCESS_CANDIDATE_RECOMMENDED_JOBS = 'SUCCESS_CANDIDATE_RECOMMENDED_JOBS';
export const ERROR_CANDIDATE_RECOMMENDED_JOBS = 'ERROR_CANDIDATE_RECOMMENDED_JOBS';

export const REQUEST_REFERENCE_INVITES = 'REQUEST_REFERENCE_INVITES';
export const SUCCESS_REFERENCE_INVITES = 'SUCCESS_REFERENCE_INVITES';
export const ERROR_REFERENCE_INVITES = 'ERROR_REFERENCE_INVITES';

export const REQUEST_ALL_PUBLIC_JOBS = 'REQUEST_ALL_PUBLIC_JOBS';
export const SUCCESS_ALL_PUBLIC_JOBS = 'SUCCESS_ALL_PUBLIC_JOBS';
export const ERROR_ALL_PUBLIC_JOBS = 'ERROR_ALL_PUBLIC_JOBS';

export const REQUEST_ADD_JOBTO_PROFILE = 'REQUEST_ADD_JOBTO_PROFILE';
export const SUCCESS_ADD_JOBTO_PROFILE = 'SUCCESS_ADD_JOBTO_PROFILE';

export const REQUEST_CANDIDATE_INVITE_UPDATE = 'REQUEST_CANDIDATE_INVITE_UPDATE';
export const SUCCESS_CANDIDATE_INVITE_UPDATE = 'SUCCESS_CANDIDATE_INVITE_UPDATE';
export const ERROR_CANDIDATE_INVITE_UPDATE = 'ERROR_CANDIDATE_INVITE_UPDATE';

export const REQUEST_CANDIDATE_RECOMMENDED_SKILLS = 'REQUEST_CANDIDATE_RECOMMENDED_SKILLS';
export const SUCCESS_CANDIDATE_RECOMMENDED_SKILLS = 'SUCCESS_CANDIDATE_RECOMMENDED_SKILLS';
export const ERROR_CANDIDATE_RECOMMENDED_SKILLS = 'ERROR_CANDIDATE_RECOMMENDED_SKILLS';

export const REQUEST_SKILLS_RECOMMENDATIONS = 'REQUEST_SKILLS_RECOMMENDATIONS';
export const SUCCESS_SKILLS_RECOMMENDATIONS = 'SUCCESS_SKILLS_RECOMMENDATIONS';
export const ERROR_SKILLS_RECOMMENDATIONS = 'ERROR_SKILLS_RECOMMENDATIONS';

export const REQUEST_JOBS_RECOMMENDATIONS = 'REQUEST_JOBS_RECOMMENDATIONS';
export const SUCCESS_JOBS_RECOMMENDATIONS = 'SUCCESS_JOBS_RECOMMENDATIONS';
export const ERROR_JOBS_RECOMMENDATIONS = 'ERROR_JOBS_RECOMMENDATIONS';

export const REQUEST_CANDIDATE_PRIVACY_CONSENT = 'REQUEST_CANDIDATE_PRIVACY_CONSENT';
export const SUCCESS_CANDIDATE_PRIVACY_CONSENT = 'SUCCESS_CANDIDATE_PRIVACY_CONSENT';
export const ERROR_CANDIDATE_PRIVACY_CONSENT = 'ERROR_CANDIDATE_PRIVACY_CONSENT';

export const REQUEST_CANDIDATE_PRIVACY_JOB_CONSENT = 'REQUEST_CANDIDATE_PRIVACY_JOB_CONSENT';
export const SUCCESS_CANDIDATE_PRIVACY_JOB_CONSENT = 'SUCCESS_CANDIDATE_PRIVACY_JOB_CONSENT';
export const ERROR_CANDIDATE_PRIVACY_JOB_CONSENT = 'ERROR_CANDIDATE_PRIVACY_JOB_CONSENT';

export const REQUEST_GET_PROFILE_VIEWS = 'REQUEST_GET_PROFILE_VIEWS';
export const SUCCESS_GET_PROFILE_VIEWS = 'SUCCESS_GET_PROFILE_VIEWS';
export const ERROR_GET_PROFILE_VIEWS = 'ERROR_GET_PROFILE_VIEWS';

export const RECEIVE_EMPLOYEE_DATA = 'RECEIVE_EMPLOYEE_DATA';
export const SET_USER = 'SET_USER';
export const TOGGLE_MAP_VIEW = 'TOGGLE_MAP_VIEW';
export const RECEIVE_CANDIDATE_DATA = 'RECEIVE_CANDIDATE_DATA';
export const SET_TAB = 'SET_TAB';
export const SET_ATTRITION_MODAL = 'SET_ATTRITION_MODAL';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_YEAR = 'SET_YEAR';

/* Global Search */

export const REQUEST_GLOBAL_SEARCH = 'REQUEST_GLOBAL_SEARCH';
export const ERROR_GLOBAL_SEARCH = 'ERROR_GLOBAL_SEARCH';
export const SUCCESS_GLOBAL_SEARCH = 'SUCCESS_GLOBAL_SEARCH';

/* Market Place */
export const REQUEST_MARKETPLACES = 'REQUEST_MARKETPLACES';
export const ERROR_MARKETPLACES = 'ERROR_MARKETPLACES';
export const SUCCESS_MARKETPLACES = 'SUCCESS_MARKETPLACES';

export const REQUEST_MARKETAPP = 'REQUEST_MARKETAPP';
export const ERROR_MARKETAPPS = 'ERROR_MARKETAPP';
export const SUCCESS_MARKETAPP = 'SUCCESS_MARKETAPP';

export const REQUEST_COMPANY_MARKETPLACE_MAP = 'REQUEST_COMPANY_MARKETPLACE_MAP';
export const ERROR_COMPANY_MARKETPLACE_MAP = 'ERROR_COMPANY_MARKETPLACE_MAP';
export const SUCCESS_COMPANY_MARKETPLACE_MAP = 'SUCCESS_COMPANY_MARKETPLACE_MAP';

/* Global Talent Pool */
export const REQUEST_GPT = 'REQUEST_GPT';
export const SUCCESS_GPT = 'SUCCESS_GPT';
export const ERROR_GPT = 'ERROR_GPT';

export const REQUEST_GTP_OPTIN_UPDATE = 'REQUEST_GTP_OPTIN_UPDATE';
export const ERROR_GTP_OPTIN_UPDATE = 'ERROR_GTP_OPTIN_UPDATE';
export const SUCCESS_GTP_OPTIN_UPDATE = 'SUCCESS_GTP_OPTIN_UPDATE';

/* Video Interviews */
export const REQUEST_GET_INTERVIEWS = 'REQUEST_GET_INTERVIEWS';
export const ERROR_GET_INTERVIEWS = 'ERROR_GET_INTERVIEWS';
export const SUCCESS_GET_INTERVIEWS = 'SUCCESS_GET_INTERVIEWS';

export const REQUEST_GET_INTERVIEW = 'REQUEST_GET_INTERVIEW';
export const ERROR_GET_INTERVIEW = 'ERROR_GET_INTERVIEW';
export const SUCCESS_GET_INTERVIEW = 'SUCCESS_GET_INTERVIEW';

export const REQUEST_CREATE_INTERVIEW = 'REQUEST_CREATE_INTERVIEW';
export const ERROR_CREATE_INTERVIEW = 'ERROR_CREATE_INTERVIEW';
export const SUCCESS_CREATE_INTERVIEW = 'SUCCESS_CREATE_INTERVIEW';

export const REQUEST_GET_INTERVIEW_URL = 'REQUEST_GET_INTERVIEW_URL';
export const ERROR_GET_INTERVIEW_URL = 'ERROR_GET_INTERVIEW_URL';
export const SUCCESS_GET_INTERVIEW_URL = 'SUCCESS_GET_INTERVIEW_URL';

export const REQUEST_INTERVIEW_INVITE = 'REQUEST_INTERVIEW_INVITE';
export const ERROR_INTERVIEW_INVITE = 'ERROR_INTERVIEW_INVITE';
export const SUCCESS_INTERVIEW_INVITE = 'SUCCESS_INTERVIEW_INVITE';

export const REQUEST_CANDIDATE_ANSWERS = 'REQUEST_CANDIDATE_ANSWERS';
export const ERROR_CANDIDATE_ANSWERS = 'ERROR_CANDIDATE_ANSWERS';
export const SUCCESS_CANDIDATE_ANSWERS = 'SUCCESS_CANDIDATE_ANSWERS';

export const REQUEST_INTERVIEW_STATUS = 'REQUEST_INTERVIEW_STATUS';
export const ERROR_INTERVIEW_STATUS = 'ERROR_INTERVIEW_STATUS';
export const SUCCESS_INTERVIEW_STATUS = 'SUCCESS_INTERVIEW_STATUS';

export const REQUEST_VIDEO_INTERVIEW_RESPONSE_DETAILS = 'REQUEST_VIDEO_INTERVIEW_RESPONSE_DETAILS';
export const SUCCESS_VIDEO_INTERVIEW_RESPONSE_DETAILS = 'SUCCESS_VIDEO_INTERVIEW_RESPONSE_DETAILS';
export const ERROR_VIDEO_INTERVIEW_RESPONSE_DETAILS = 'ERROR_VIDEO_INTERVIEW_RESPONSE_DETAILS';

export const REQUEST_CANDIDATE_VIDEO_INTERVIEW_RATING = 'REQUEST_CANDIDATE_VIDEO_INTERVIEW_RATING';
export const SUCCESS_CANDIDATE_VIDEO_INTERVIEW_RATING = 'SUCCESS_CANDIDATE_VIDEO_INTERVIEW_RATING';
export const ERROR_CANDIDATE_VIDEO_INTERVIEW_RATING = 'ERROR_CANDIDATE_VIDEO_INTERVIEW_RATING';

export const REQUEST_GET_VIDEO_INDEXER = 'REQUEST_GET_VIDEO_INDEXER';
export const SUCCESS_GET_VIDEO_INDEXER = 'SUCCESS_GET_VIDEO_INDEXER';
export const ERROR_GET_VIDEO_INDEXER = 'ERROR_GET_VIDEO_INDEXER';

/** workflow  */
export const REQUEST_GET_USER_WORKFLOWS = "REQUEST_GET_USER_WORKFLOWS";
export const SUCCESS_GET_USER_WORKFLOWS = "SUCCESS_GET_USER_WORKFLOWS";
export const ERROR_GET_USER_WORKFLOWS = "ERROR_GET_USER_WORKFLOWS";

export const REQUEST_GET_WORKFLOW_STAGES = "REQUEST_GET_WORKFLOW_STAGES";
export const SUCCESS_GET_WORKFLOW_STAGES = "SUCCESS_GET_WORKFLOW_STAGES";
export const ERROR_GET_WORKFLOW_STAGES = "ERROR_GET_WORKFLOW_STAGES";

export const REQUEST_CREATE_WORKFLOW = "REQUEST_CREATE_WORKFLOW";
export const SUCCESS_CREATE_WORKFLOW = "SUCCESS_CREATE_WORKFLOW";
export const ERROR_CREATE_WORKFLOW = "ERROR_CREATE_WORKFLOW";

export const REQUEST_DELETE_WORKFLOW = 'REQUEST_DELETE_WORKFLOW';
export const SUCCESS_DELETE_WORKFLOW = 'SUCCESS_DELETE_WORKFLOW';
export const ERROR_DELETE_WORKFLOW = 'ERROR_DELETE_WORKFLOW';

export const REQUEST_ASSIGN_WORKFLOW_TO_APPLICANT = "REQUEST_ASSIGN_WORKFLOW_TO_APPLICANT";
export const SUCCESS_ASSIGN_WORKFLOW_TO_APPLICANT = "SUCCESS_ASSIGN_WORKFLOW_TO_APPLICANT";
export const ERROR_ASSIGN_WORKFLOW_TO_APPLICANT = "ERROR_ASSIGN_WORKFLOW_TO_APPLICANT";

export const REQUEST_UPDATE_WORKFLOW_CONFIG = "REQUEST_UPDATE_WORKFLOW_CONFIG";
export const SUCCESS_UPDATE_WORKFLOW_CONFIG = "SUCCESS_UPDATE_WORKFLOW_CONFIG";
export const ERROR_UPDATE_WORKFLOW_CONFIG = "ERROR_UPDATE_WORKFLOW_CONFIG";

export const REQUEST_SKIP_WORKFLOW_STAGE = "REQUEST_SKIP_WORKFLOW_STAGE";
export const SUCCESS_SKIP_WORKFLOW_STAGE = "SUCCESS_SKIP_WORKFLOW_STAGE";
export const ERROR_SKIP_WORKFLOW_STAGE = "ERROR_SKIP_WORKFLOW_STAGE";

export const REQUEST_UPDATE_WORKFLOW_APPL_STAGE = "REQUEST_UPDATE_WORKFLOW_APPL_STAGE";
export const SUCCESS_UPDATE_WORKFLOW_APPL_STAGE = "SUCCESS_UPDATE_WORKFLOW_APPL_STAGE";
export const ERROR_UPDATE_WORKFLOW_APPL_STAGE = "ERROR_UPDATE_WORKFLOW_APPL_STAGE";

export const REQUEST_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER = "REQUEST_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER";
export const SUCCESS_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER = "SUCCESS_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER";
export const ERROR_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER = "ERROR_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER";

export const REQUEST_DELETE_WORKFLOW_APPL_SUBSTAGE_USER = "REQUEST_DELETE_WORKFLOW_APPL_SUBSTAGE_USER";
export const SUCCESS_DELETE_WORKFLOW_APPL_SUBSTAGE_USER = "SUCCESS_DELETE_WORKFLOW_APPL_SUBSTAGE_USER";
export const ERROR_DELETE_WORKFLOW_APPL_SUBSTAGE_USER = "ERROR_DELETE_WORKFLOW_APPL_SUBSTAGE_USER";

export const REQUEST_APPL_WORKFLOW = "REQUEST_APPL_WORKFLOW";
export const SUCCESS_APPL_WORKFLOW = "SUCCESS_APPL_WORKFLOW";
export const ERROR_APPL_WORKFLOW = "ERROR_APPL_WORKFLOW";

/* Job */

export const REQUEST_ANALYTICS_DATA = "REQUEST_ANALYTICS_DATA";
export const SUCCESS_ANALYTICS_DATA = "SUCCESS_ANALYTICS_DATA";

export const REQUEST_ANALYTICS_INFO = "REQUEST_ANALYTICS_INFO";
export const SUCCESS_ANALYTICS_INFO = "SUCCESS_ANALYTICS_INFO";
export const ERROR_ANALYTICS_INFO = "ERROR_ANALYTICS_INFO";

export const REQUEST_UPDATE_INFO = "REQUEST_UPDATE_INFO";
export const SUCCESS_UPDATE_INFO = "SUCCESS_UPDATE_INFO";
export const ERROR_UPDATE_INFO = "ERROR_UPDATE_INFO";


export const REQUEST_CLOSE_JOB = "REQUEST_CLOSE_JOB";
export const SUCCESS_CLOSE_JOB = "SUCCESS_CLOSE_JOB";
export const ERROR_CLOSE_JOB = "ERROR_CLOSE_JOB";

export const REQUEST_CLONE_JOB = "REQUEST_CLONE_JOB";
export const SUCCESS_CLONE_JOB = "SUCCESS_CLONE_JOB";
export const ERROR_CLONE_JOB = "ERROR_CLONE_JOB";

export const REQUEST_ASSOCIATE_LINKEDIN_JOB = "REQUEST_ASSOCIATE_LINKEDIN_JOB";
export const SUCCESS_ASSOCIATE_LINKEDIN_JOB = "SUCCESS_ASSOCIATE_LINKEDIN_JOB";
export const ERROR_ASSOCIATE_LINKEDIN_JOB = "ERROR_ASSOCIATE_LINKEDIN_JOB";

export const REQUEST_STATUS_JOB = 'REQUEST_STATUS_JOB';
export const SUCCESS_STATUS_JOB = 'SUCCESS_STATUS_JOB';
export const ERROR_STATUS_JOB = 'ERROR_STATUS_JOB';

export const REQUEST_DOCUMENT = 'REQUEST_DOCUMENT';
export const SUCCESS_DOCUMENT = 'SUCCESS_DOCUMENT';
export const ERROR_DOCUMENT = 'ERROR_DOCUMENT';

export const REQUEST_JOB_DOCUMENT = 'REQUEST_JOB_DOCUMENT';
export const SUCCESS_JOB_DOCUMENT = 'SUCCESS_JOB_DOCUMENT';
export const ERROR_JOB_DOCUMENT = 'ERROR_JOB_DOCUMENT';

export const REQUEST_USERS_USAGE_REPORT = 'REQUEST_USERS_USAGE_REPORT';
export const SUCCESS_USERS_USAGE_REPORT = 'SUCCESS_USERS_USAGE_REPORT';
export const ERROR_USERS_USAGE_REPORT = 'ERROR_USERS_USAGE_REPORT';

export const REQUEST_ADD_REFERENCES = 'REQUEST_ADD_REFERENCES';
export const SUCCESS_ADD_REFERENCES = 'SUCCESS_ADD_REFERENCES';
export const ERROR_ADD_REFERENCES = 'ERROR_ADD_REFERENCES';

export const REQUEST_GET_FEEDBACKGIVEN = 'REQUEST_GET_FEEDBACKGIVEN';
export const SUCCESS_GET_FEEDBACKGIVEN = 'SUCCESS_GET_FEEDBACKGIVEN';
export const ERROR_GET_FEEDBACKGIVEN = 'ERROR_GET_FEEDBACKGIVEN';

export const REQUEST_GET_REFERENCES = 'REQUEST_GET_REFERENCES';
export const SUCCESS_GET_REFERENCES = 'SUCCESS_GET_REFERENCES';
export const ERROR_GET_REFERENCES = 'ERROR_GET_REFERENCES';

export const REQUEST_ADD_REFERENCE_FEEDBACK = 'REQUEST_ADD_REFERENCE_FEEDBACK';
export const SUCCESS_ADD_REFERENCE_FEEDBACK = 'SUCCESS_ADD_REFERENCE_FEEDBACK';
export const ERROR_ADD_REFERENCE_FEEDBACK = 'ERROR_ADD_REFERENCE_FEEDBACK';

export const REQUEST_GET_REFERENCE = 'REQUEST_GET_REFERENCE';
export const SUCCESS_GET_REFERENCE = 'SUCCESS_GET_REFERENCE';
export const ERROR_GET_REFERENCE = 'ERROR_GET_REFERENCE';

export const REQUEST_SEND_REMINDER = 'REQUEST_SEND_REMINDER';
export const SUCCESS_SEND_REMINDER = 'SUCCESS_SEND_REMINDER';
export const ERROR_SEND_REMINDER = 'ERROR_SEND_REMINDER';

export const REQUEST_USER_REFERENCES = 'REQUEST_USER_REFERENCES';
export const SUCCESS_USER_REFERENCES = 'SUCCESS_USER_REFERENCES';
export const ERROR_USER_REFERENCES = 'ERROR_USER_REFERENCES';

export const REQUEST_JOB_COLLABORATORS = 'REQUEST_JOB_COLLABORATORS';
export const SUCCESS_JOB_COLLABORATORS = 'SUCCESS_JOB_COLLABORATORS';
export const ERROR_JOB_COLLABORATORS = 'ERROR_JOB_COLLABORATORS';

export const REQUEST_ADD_JOB_COLLABORATOR = 'REQUEST_ADD_JOB_COLLABORATOR';
export const SUCCESS_ADD_JOB_COLLABORATOR = 'SUCCESS_ADD_JOB_COLLABORATOR';
export const ERROR_ADD_JOB_COLLABORATOR = 'ERROR_ADD_JOB_COLLABORATOR';

export const REQUEST_JOB_COLLABORATORS_DELETE = 'REQUEST_JOB_COLLABORATORS_DELETE';
export const SUCCESS_JOB_COLLABORATORS_DELETE = 'SUCCESS_JOB_COLLABORATORS_DELETE';
export const ERROR_JOB_COLLABORATORS_DELETE = 'ERROR_JOB_COLLABORATORS_DELETE';

export const REQUEST_GET_EMAIL_TEMPLATES = 'REQUEST_GET_EMAIL_TEMPLATES';
export const SUCCESS_GET_EMAIL_TEMPLATES = 'SUCCESS_GET_EMAIL_TEMPLATES';
export const ERROR_GET_EMAIL_TEMPLATES = 'ERROR_GET_EMAIL_TEMPLATES';

export const REQUEST_GET_EMAIL_TEMPLATE = 'REQUEST_GET_EMAIL_TEMPLATE';
export const SUCCESS_GET_EMAIL_TEMPLATE = 'SUCCESS_GET_EMAIL_TEMPLATE';
export const ERROR_GET_EMAIL_TEMPLATE = 'ERROR_GET_EMAIL_TEMPLATE';

export const REQUEST_DELETE_CUSTOM_EMAIL_TEMPLATES = 'REQUEST_DELETE_CUSTOM_EMAIL_TEMPLATES';
export const SUCCESS_DELETE_CUSTOM_EMAIL_TEMPLATES = 'SUCCESS_DELETE_CUSTOM_EMAIL_TEMPLATES';
export const ERROR_DELETE_CUSTOM_EMAIL_TEMPLATES = 'ERROR_DELETE_CUSTOM_EMAIL_TEMPLATES';

export const REQUEST_UPSERT_EMAIL_TEMPLATE = 'REQUEST_UPSERT_EMAIL_TEMPLATE';
export const SUCCESS_UPSERT_EMAIL_TEMPLATE = 'SUCCESS_UPSERT_EMAIL_TEMPLATE';
export const ERROR_UPSERT_EMAIL_TEMPLATE = 'ERROR_UPSERT_EMAIL_TEMPLATE';

export const REQUEST_SEND_EMAIL_TEMPLATE = 'REQUEST_SEND_EMAIL_TEMPLATE';
export const SUCCESS_SEND_EMAIL_TEMPLATE = 'SUCCESS_SEND_EMAIL_TEMPLATE';
export const ERROR_SEND_EMAIL_TEMPLATE = 'ERROR_SEND_EMAIL_TEMPLATE';

export const REQUEST_GET_COMPANY_INFO = 'REQUEST_GET_COMPANY_INFO';
export const SUCCESS_GET_COMPANY_INFO = 'SUCCESS_GET_COMPANY_INFO';
export const ERROR_GET_COMPANY_INFO = 'ERROR_GET_COMPANY_INFO';

export const REQUEST_UPDATE_COMPANY_INFO = 'REQUEST_UPDATE_COMPANY_INFO';
export const SUCCESS_UPDATE_COMPANY_INFO = 'SUCCESS_UPDATE_COMPANY_INFO';
export const ERROR_UPDATE_COMPANY_INFO = 'ERROR_UPDATE_COMPANY_INFO';

export const REQUEST_CHECK_USER = 'REQUEST_CHECK_USER';
export const SUCCESS_CHECK_USER = 'SUCCESS_CHECK_USER';
export const ERROR_CHECK_USER = 'ERROR_CHECK_USER';

export const REQUEST_CF_TOKEN = 'REQUEST_CF_TOKEN';
export const SUCCESS_CF_TOKEN = 'SUCCESS_CF_TOKEN';
export const ERROR_CF_TOKEN = 'ERROR_CF_TOKEN';

export const REQUEST_CF_USER_PROFILES = 'REQUEST_CF_USER_PROFILES';
export const SUCCESS_CF_USER_PROFILES = 'SUCCESS_CF_USER_PROFILES';
export const ERROR_CF_USER_PROFILES = 'ERROR_CF_USER_PROFILES';

export const REQUEST_CF_REVOKE_PROFILE = 'REQUEST_CF_REVOKE_PROFILE';
export const SUCCESS_CF_REVOKE_PROFILE = 'SUCCESS_CF_REVOKE_PROFILE';
export const ERROR_CF_REVOKE_PROFILE = 'ERROR_CF_REVOKE_PROFILE';

export const REQUEST_CF_USER_CALENDARS = 'REQUEST_CF_USER_CALENDARS';
export const SUCCESS_CF_USER_CALENDARS = 'SUCCESS_CF_USER_CALENDARS';
export const ERROR_CF_USER_CALENDARS = 'ERROR_CF_USER_CALENDARS';

export const REQUEST_CF_REFRESH_CALENDARS = 'REQUEST_CF_REFRESH_CALENDARS';
export const SUCCESS_CF_REFRESH_CALENDARS = 'SUCCESS_CF_REFRESH_CALENDARS';
export const ERROR_CF_REFRESH_CALENDARS = 'ERROR_CF_REFRESH_CALENDARS';

export const REQUEST_CF_EVENT_DATA = 'REQUEST_CF_EVENT_DATA';
export const SUCCESS_CF_EVENT_DATA = 'SUCCESS_CF_EVENT_DATA';
export const ERROR_CF_EVENT_DATA = 'ERROR_CF_EVENT_DATA';

export const REQUEST_CF_RETRY_SLOT = 'REQUEST_CF_RETRY_SLOT';
export const SUCCESS_CF_RETRY_SLOT = 'SUCCESS_CF_RETRY_SLOT';
export const ERROR_CF_RETRY_SLOT = 'ERROR_CF_RETRY_SLOT';

export const REQUEST_CHANGE_USER_TZ = 'REQUEST_CHANGE_USER_TZ';
export const SUCCESS_CHANGE_USER_TZ = 'SUCCESS_CHANGE_USER_TZ';
export const ERROR_CHANGE_USER_TZ = 'ERROR_CHANGE_USER_TZ';

export const REQUEST_SCHEDULE_STAGE = 'REQUEST_SCHEDULE_STAGE';
export const SUCCESS_SCHEDULE_STAGE = 'SUCCESS_SCHEDULE_STAGE';
export const ERROR_SCHEDULE_STAGE = 'ERROR_SCHEDULE_STAGE';

export const REQUEST_UPDATE_JOB_WORKFLOW_SATGE = 'REQUEST_UPDATE_JOB_WORKFLOW_SATGE';
export const SUCCESS_UPDATE_JOB_WORKFLOW_SATGE = 'SUCCESS_UPDATE_JOB_WORKFLOW_SATGE';
export const ERROR_UPDATE_JOB_WORKFLOW_SATGE = 'ERROR_UPDATE_JOB_WORKFLOW_SATGE';

export const REQUEST_GET_JOB_TIMELINE = 'REQUEST_GET_JOB_TIMELINE';
export const SUCCESS_GET_JOB_TIMELINE = 'SUCCESS_GET_JOB_TIMELINE';
export const ERROR_GET_JOB_TIMELINE = 'ERROR_GET_JOB_TIMELINE';

export const REQUEST_AVAILABLE_SLOTS = 'REQUEST_AVAILABLE_SLOTS';
export const SUCCESS_AVAILABLE_SLOTS = 'SUCCESS_AVAILABLE_SLOTS';
export const ERROR_AVAILABLE_SLOTS = 'ERROR_AVAILABLE_SLOTS';

export const REQUEST_APPROVE_VACANCIES = 'REQUEST_APPROVE_VACANCIES';
export const SUCCESS_APPROVE_VACANCIES = 'SUCCESS_APPROVE_VACANCIES';
export const ERROR_APPROVE_VACANCIES = 'ERROR_APPROVE_VACANCIES';

/* Offer */
export const REQUEST_GET_OFFER_DETAILS = 'REQUEST_GET_OFFER_DETAILS';
export const SUCCESS_GET_OFFER_DETAILS = 'SUCCESS_GET_OFFER_DETAILS';
export const ERROR_GET_OFFER_DETAILS = 'ERROR_GET_OFFER_DETAILS';

export const REQUEST_EDIT_OFFER_DETAILS = 'REQUEST_EDIT_OFFER_DETAILS';
export const SUCCESS_EDIT_OFFER_DETAILS = 'SUCCESS_EDIT_OFFER_DETAILS';
export const ERROR_EDIT_OFFER_DETAILS = 'ERROR_EDIT_OFFER_DETAILS';

export const REQUEST_CREATE_OFFER_DETAILS = 'REQUEST_CREATE_OFFER_DETAILS';
export const SUCCESS_CREATE_OFFER_DETAILS = 'SUCCESS_CREATE_OFFER_DETAILS';
export const ERROR_CREATE_OFFER_DETAILS = 'ERROR_CREATE_OFFER_DETAILS';

export const REQUEST_JOB_OFFER_DETAILS = 'REQUEST_JOB_OFFER_DETAILS';
export const SUCCESS_JOB_OFFER_DETAILS = 'SUCCESS_JOB_OFFER_DETAILS';
export const ERROR_JOB_OFFER_DETAILS = 'ERROR_JOB_OFFER_DETAILS';

export const REQUEST_GET_REJECT_REASONS = 'REQUEST_GET_REJECT_REASONS';
export const SUCCESS_GET_REJECT_REASONS = 'SUCCESS_GET_REJECT_REASONS';
export const ERROR_GET_REJECT_REASONS = 'ERROR_GET_REJECT_REASONS';

export const REQUEST_CREATE_OFFER_DOCUMENTS = 'REQUEST_CREATE_OFFER_DOCUMENTS';
export const SUCCESS_CREATE_OFFER_DOCUMENTS = 'SUCCESS_CREATE_OFFER_DOCUMENTS';
export const ERROR_CREATE_OFFER_DOCUMENTS = 'ERROR_CREATE_OFFER_DOCUMENTS';

export const REQUEST_GET_OFFER_DOCUMENTS = 'REQUEST_GET_OFFER_DOCUMENTS';
export const SUCCESS_GET_OFFER_DOCUMENTS = 'SUCCESS_GET_OFFER_DOCUMENTS';
export const ERROR_GET_OFFER_DOCUMENTS = 'ERROR_GET_OFFER_DOCUMENTS';

export const REQUEST_DELETE_OFFER_DOCUMENTS = 'REQUEST_DELETE_OFFER_DOCUMENTS';
export const SUCCESS_DELETE_OFFER_DOCUMENTS = 'SUCCESS_DELETE_OFFER_DOCUMENTS';
export const ERROR_DELETE_OFFER_DOCUMENTS = 'ERROR_DELETE_OFFER_DOCUMENTS';

/* Currencies */
export const REQUEST_GET_CURRENCY_VALUE = 'REQUEST_GET_CURRENCY_VALUE';
export const SUCCESS_GET_CURRENCY_VALUE = 'SUCCESS_GET_CURRENCY_VALUE';
export const ERROR_GET_CURRENCY_VALUE = 'ERROR_GET_CURRENCY_VALUE';

export const REQUEST_GET_CANDIDATES_IN_OFFER_LIST = 'REQUEST_GET_CANDIDATES_IN_OFFER_LIST';
export const SUCCESS_GET_CANDIDATES_IN_OFFER_LIST = 'SUCCESS_GET_CANDIDATES_IN_OFFER_LIST';
export const ERROR_GET_CANDIDATES_IN_OFFER_LIST = 'ERROR_GET_CANDIDATES_IN_OFFER_LIST';

/* Calendar Events */
export const REQUEST_GET_CALENDAR_EVENTS = 'REQUEST_GET_CALENDAR_EVENTS';
export const SUCCESS_GET_CALENDAR_EVENTS = 'SUCCESS_GET_CALENDAR_EVENTS';
export const ERROR_GET_CALENDAR_EVENTS = 'ERROR_GET_CALENDAR_EVENTS';

export const REQUEST_GET_CALENDAR_EVENT_ID = 'REQUEST_GET_CALENDAR_EVENT_ID';
export const SUCCESS_GET_CALENDAR_EVENT_ID = 'SUCCESS_GET_CALENDAR_EVENT_ID';
export const ERROR_GET_CALENDAR_EVENT_ID = 'ERROR_GET_CALENDAR_EVENT_ID';

export const REQUEST_UPSERT_PROFILE_CONTACT = 'REQUEST_UPSERT_PROFILE_CONTACT';
export const SUCCESS_UPSERT_PROFILE_CONTACT = 'SUCCESS_UPSERT_PROFILE_CONTACT';
export const ERROR_UPSERT_PROFILE_CONTACT = 'ERROR_UPSERT_PROFILE_CONTACT';

/* Business Finland */
export const REQUEST_GET_FINLAND_PROFILE = 'REQUEST_GET_FINLAND_PROFILE';
export const ERROR_GET_FINLAND_PROFILE = 'ERROR_GET_FINLAND_PROFILE';
export const SUCCESS_GET_FINLAND_PROFILE = 'SUCCESS_GET_FINLAND_PROFILE';

export const REQUEST_UPDATE_FINLAND_PROFILE = 'REQUEST_UPDATE_FINLAND_PROFILE';
export const ERROR_UPDATE_FINLAND_PROFILE = 'ERROR_UPDATE_FINLAND_PROFILE';
export const SUCCESS_UPDATE_FINLAND_PROFILE = 'SUCCESS_UPDATE_FINLAND_PROFILE';

export const REQUEST_CREATE_FINLAND_PROFILE = 'REQUEST_CREATE_FINLAND_PROFILE';
export const ERROR_CREATE_FINLAND_PROFILE = 'ERROR_CREATE_FINLAND_PROFILE';
export const SUCCESS_CREATE_FINLAND_PROFILE = 'SUCCESS_CREATE_FINLAND_PROFILE';

export const REQUEST_DELETE_FINLAND_PROFILE = 'REQUEST_DELETE_FINLAND_PROFILE';
export const ERROR_DELETE_FINLAND_PROFILE = 'ERROR_DELETE_FINLAND_PROFILE';
export const SUCCESS_DELETE_FINLAND_PROFILE = 'SUCCESS_DELETE_FINLAND_PROFILE';

export const REQUEST_POST_PREFERENCE_RANKS = 'REQUEST_POST_PREFERENCE_RANKS';
export const ERROR_POST_PREFERENCE_RANKS = 'ERROR_POST_PREFERENCE_RANKS';
export const SUCCESS_POST_PREFERENCE_RANKS = 'SUCCESS_POST_PREFERENCE_RANKS';

export const REQUEST_UPDATE_PREFERENCE_RANKS = 'REQUEST_UPDATE_PREFERENCE_RANKS';
export const ERROR_UPDATE_PREFERENCE_RANKS = 'ERROR_UPDATE_PREFERENCE_RANKS';
export const SUCCESS_UPDATE_PREFERENCE_RANKS = 'SUCCESS_UPDATE_PREFERENCE_RANKS';

export const REQUEST_GET_PREFERENCE_RANKS = 'REQUEST_GET_PREFERENCE_RANKS';
export const ERROR_GET_PREFERENCE_RANKS = 'ERROR_GET_PREFERENCE_RANKS';
export const SUCCESS_GET_PREFERENCE_RANKS = 'SUCCESS_GET_PREFERENCE_RANKS';

export const REQUEST_GET_WORK_PREFERENCE = 'REQUEST_GET_WORK_PREFERENCE';
export const ERROR_GET_WORK_PREFERENCE = 'ERROR_GET_WORK_PREFERENCE';
export const SUCCESS_GET_WORK_PREFERENCE = 'SUCCESS_GET_WORK_PREFERENCE';

export const REQUEST_POST_WORK_PREFERENCE = 'REQUEST_POST_WORK_PREFERENCE';
export const ERROR_POST_WORK_PREFERENCE = 'ERROR_POST_WORK_PREFERENCE';
export const SUCCESS_POST_WORK_PREFERENCE = 'SUCCESS_POST_WORK_PREFERENCE';

export const REQUEST_UPDATE_WORK_PREFERENCE = 'REQUEST_UPDATE_WORK_PREFERENCE';
export const ERROR_UPDATE_WORK_PREFERENCE = 'ERROR_UPDATE_WORK_PREFERENCE';
export const SUCCESS_UPDATE_WORK_PREFERENCE = 'SUCCESS_UPDATE_WORK_PREFERENCE';

export const REQUEST_POST_PERSONHOBBIES = 'REQUEST_POST_PERSONHOBBIES';
export const ERROR_POST_PERSONHOBBIES = 'ERROR_POST_PERSONHOBBIES';
export const SUCCESS_POST_PERSONHOBBIES = 'SUCCESS_POST_PERSONHOBBIES';

export const REQUEST_UPDATE_PERSONHOBBIES = 'REQUEST_UPDATE_PERSONHOBBIES';
export const ERROR_UPDATE_PERSONHOBBIES = 'ERROR_UPDATE_PERSONHOBBIES';
export const SUCCESS_UPDATE_PERSONHOBBIES = 'SUCCESS_UPDATE_PERSONHOBBIES';

export const REQUEST_POST_PERSONACTIVITIES = 'REQUEST_POST_PERSONACTIVITIES';
export const ERROR_POST_PERSONACTIVITIES = 'ERROR_POST_PERSONACTIVITIES';
export const SUCCESS_POST_PERSONACTIVITIES = 'SUCCESS_POST_PERSONACTIVITIES';

export const REQUEST_UPDATE_PERSONACTIVITIES = 'REQUEST_UPDATE_PERSONACTIVITIES';
export const ERROR_UPDATE_PERSONACTIVITIES = 'ERROR_UPDATE_PERSONACTIVITIES';
export const SUCCESS_UPDATE_PERSONACTIVITIES = 'SUCCESS_UPDATE_PERSONACTIVITIES';

export const REQUEST_DELETE_PREFERENCE_RANKS = 'REQUEST_DELETE_PREFERENCE_RANKS';
export const ERROR_DELETE_PREFERENCE_RANKS = 'ERROR_DELETE_PREFERENCE_RANKS';
export const SUCCESS_DELETE_PREFERENCE_RANKS = 'SUCCESS_DELETE_PREFERENCE_RANKS';

export const REQUEST_DELETE_WORK_PREFERENCE = 'REQUEST_DELETE_WORK_PREFERENCE';
export const ERROR_DELETE_WORK_PREFERENCE = 'ERROR_DELETE_WORK_PREFERENCE';
export const SUCCESS_DELETE_WORK_PREFERENCE = 'SUCCESS_DELETE_WORK_PREFERENCE';

export const REQUEST_DELETE_PERSONHOBBIES = 'REQUEST_DELETE_PERSONHOBBIES';
export const ERROR_DELETE_PERSONHOBBIES = 'ERROR_DELETE_PERSONHOBBIES';
export const SUCCESS_DELETE_PERSONHOBBIES = 'SUCCESS_DELETE_PERSONHOBBIES';

export const REQUEST_DELETE_PERSONACTIVITIES = 'REQUEST_DELETE_PERSONACTIVITIES';
export const ERROR_DELETE_PERSONACTIVITIES = 'ERROR_DELETE_PERSONACTIVITIES';
export const SUCCESS_DELETE_PERSONACTIVITIES = 'SUCCESS_DELETE_PERSONACTIVITIES';

export const REQUEST_FINLAND_PROFILE_SUBMIT = 'REQUEST_FINLAND_PROFILE_SUBMIT';
export const SUCCESS_FINLAND_PROFILE_SUBMIT = 'SUCCESS_FINLAND_PROFILE_SUBMIT';

export const REQUEST_UPDATE_PERSONSKILL = 'REQUEST_UPDATE_PERSONSKILL';
export const SUCCESS_UPDATE_PERSONSKILL = 'SUCCESS_UPDATE_PERSONSKILL';

export const REQUEST_ADD_TO_TALENTPOOL = 'REQUEST_ADD_TO_TALENTPOOL';
export const SUCCESS_ADD_TO_TALENTPOOL = 'SUCCESS_ADD_TO_TALENTPOOL';

export const REQUEST_PROFILE_EMAILS = 'REQUEST_PROFILE_EMAILS';
export const ERROR_PROFILE_EMAILS = 'ERROR_PROFILE_EMAILS';
export const SUCCESS_PROFILE_EMAILS = 'SUCCESS_PROFILE_EMAILS';

export const REQUEST_DASHBOARD_WORKFLOW_SUBSTAGES_DATA = 'REQUEST_DASHBOARD_WORKFLOW_SUBSTAGES_DATA';
export const ERROR_DASHBOARD_WORKFLOW_SUBSTAGES_DATA = 'ERROR_DASHBOARD_WORKFLOW_SUBSTAGES_DATA';
export const SUCCESS_DASHBOARD_WORKFLOW_SUBSTAGES_DATA = 'SUCCESS_DASHBOARD_WORKFLOW_SUBSTAGES_DATA';

/* Document Collection */
export const REQUEST_WORKFLOW_DOC_TYPES = 'REQUEST_WORKFLOW_DOC_TYPES';
export const ERROR_WORKFLOW_DOC_TYPES = 'ERROR_WORKFLOW_DOC_TYPES';
export const SUCCESS_WORKFLOW_DOC_TYPES = 'SUCCESS_WORKFLOW_DOC_TYPES';

export const REQUEST_CREATE_DOC_TYPES = 'REQUEST_CREATE_DOC_TYPES';
export const ERROR_CREATE_DOC_TYPES = 'ERROR_CREATE_DOC_TYPES';
export const SUCCESS_CREATE_DOC_TYPES = 'SUCCESS_CREATE_DOC_TYPES';

export const REQUEST_UPDATE_DOC_TYPES = 'REQUEST_UPDATE_DOC_TYPES';
export const ERROR_UPDATE_DOC_TYPES = 'ERROR_UPDATE_DOC_TYPES';
export const SUCCESS_UPDATE_DOC_TYPES = 'SUCCESS_UPDATE_DOC_TYPES';

export const REQUEST_DELETE_DOC_TYPES = 'REQUEST_DELETE_DOC_TYPES';
export const ERROR_DELETE_DOC_TYPES = 'ERROR_DELETE_DOC_TYPES';
export const SUCCESS_DELETE_DOC_TYPES = 'SUCCESS_DELETE_DOC_TYPES';

/* Applicant Notes */

export const REQUEST_CREATE_APPL_NOTES = 'REQUEST_CREATE_APPL_NOTES';
export const ERROR_CREATE_APPL_NOTES = 'ERROR_CREATE_APPL_NOTES';
export const SUCCESS_CREATE_APPL_NOTES = 'SUCCESS_CREATE_APPL_NOTES';

export const REQUEST_GET_APPL_NOTES = 'REQUEST_GET_APPL_NOTES';
export const ERROR_GET_APPL_NOTES = 'ERROR_GET_APPL_NOTES';
export const SUCCESS_GET_APPL_NOTES = 'SUCCESS_GET_APPL_NOTES';

export const REQUEST_EDIT_APPL_NOTES = 'REQUEST_EDIT_APPL_NOTES';
export const ERROR_EDIT_APPL_NOTES = 'ERROR_EDIT_APPL_NOTES';
export const SUCCESS_EDIT_APPL_NOTES = 'SUCCESS_EDIT_APPL_NOTES';

export const REQUEST_DELETE_APPL_NOTES = 'REQUEST_DELETE_APPL_NOTES';
export const ERROR_DELETE_APPL_NOTES = 'ERROR_DELETE_APPL_NOTES';
export const SUCCESS_DELETE_APPL_NOTES = 'SUCCESS_DELETE_APPL_NOTES';

/* Student Profile */

export const REQUEST_CREATE_STUDENT_PROFILE = 'REQUEST_CREATE_STUDENT_PROFILE';
export const ERROR_CREATE_STUDENT_PROFILE = 'ERROR_CREATE_STUDENT_PROFILE';
export const SUCCESS_CREATE_STUDENT_PROFILE = 'SUCCESS_CREATE_STUDENT_PROFILE';

export const REQUEST_UPDATE_STUDENT_PROFILE = 'REQUEST_UPDATE_STUDENT_PROFILE';
export const ERROR_UPDATE_STUDENT_PROFILE = 'ERROR_UPDATE_STUDENT_PROFILE';
export const SUCCESS_UPDATE_STUDENT_PROFILE = 'SUCCESS_UPDATE_STUDENT_PROFILE';

export const REQUEST_DELETE_STUDENT_PROFILE = 'REQUEST_DELETE_STUDENT_PROFILE';
export const ERROR_DELETE_STUDENT_PROFILE = 'ERROR_DELETE_STUDENT_PROFILE';
export const SUCCESS_DELETE_STUDENT_PROFILE = 'SUCCESS_DELETE_STUDENT_PROFILE';

export const REQUEST_GET_STUDENT_PROFILE = 'REQUEST_GET_STUDENT_PROFILE';
export const ERROR_GET_STUDENT_PROFILE = 'ERROR_GET_STUDENT_PROFILE';
export const SUCCESS_GET_STUDENT_PROFILE = 'SUCCESS_GET_STUDENT_PROFILE';

export const REQUEST_CREATE_STUDENT_LANGUAGES = 'REQUEST_CREATE_STUDENT_LANGUAGES';
export const ERROR_CREATE_STUDENT_LANGUAGES = 'ERROR_CREATE_STUDENT_LANGUAGES';
export const SUCCESS_CREATE_STUDENT_LANGUAGES = 'SUCCESS_CREATE_STUDENT_LANGUAGES';

export const REQUEST_UPDATE_STUDENT_LANGUAGES = 'REQUEST_UPDATE_STUDENT_LANGUAGES';
export const ERROR_UPDATE_STUDENT_LANGUAGES = 'ERROR_UPDATE_STUDENT_LANGUAGES';
export const SUCCESS_UPDATE_STUDENT_LANGUAGES = 'SUCCESS_UPDATE_STUDENT_LANGUAGES';

export const REQUEST_DELETE_STUDENT_LANGUAGES = 'REQUEST_DELETE_STUDENT_LANGUAGES';
export const ERROR_DELETE_STUDENT_LANGUAGES = 'ERROR_DELETE_STUDENT_LANGUAGES';
export const SUCCESS_DELETE_STUDENT_LANGUAGES = 'SUCCESS_DELETE_STUDENT_LANGUAGES';

export const REQUEST_CREATE_CITY_PREFERENCES = 'REQUEST_CREATE_CITY_PREFERENCES';
export const ERROR_CREATE_CITY_PREFERENCES = 'ERROR_CREATE_CITY_PREFERENCES';
export const SUCCESS_CREATE_CITY_PREFERENCES = 'SUCCESS_CREATE_CITY_PREFERENCES';

export const REQUEST_UPDATE_CITY_PREFERENCES = 'REQUEST_UPDATE_CITY_PREFERENCES';
export const ERROR_UPDATE_CITY_PREFERENCES = 'ERROR_UPDATE_CITY_PREFERENCES';
export const SUCCESS_UPDATE_CITY_PREFERENCES = 'SUCCESS_UPDATE_CITY_PREFERENCES';

export const REQUEST_DELETE_CITY_PREFERENCES = 'REQUEST_DELETE_CITY_PREFERENCES';
export const ERROR_DELETE_CITY_PREFERENCES = 'ERROR_DELETE_CITY_PREFERENCES';
export const SUCCESS_DELETE_CITY_PREFERENCES = 'SUCCESS_DELETE_CITY_PREFERENCES';

export const REQUEST_CREATE_INDUSTRY_PREFERENCES = 'REQUEST_CREATE_INDUSTRY_PREFERENCES';
export const ERROR_CREATE_INDUSTRY_PREFERENCES = 'ERROR_CREATE_INDUSTRY_PREFERENCES';
export const SUCCESS_CREATE_INDUSTRY_PREFERENCES = 'SUCCESS_CREATE_INDUSTRY_PREFERENCES';

export const REQUEST_UPDATE_INDUSTRY_PREFERENCES = 'REQUEST_UPDATE_INDUSTRY_PREFERENCES';
export const ERROR_UPDATE_INDUSTRY_PREFERENCES = 'ERROR_UPDATE_INDUSTRY_PREFERENCES';
export const SUCCESS_UPDATE_INDUSTRY_PREFERENCES = 'SUCCESS_UPDATE_INDUSTRY_PREFERENCES';

export const REQUEST_DELETE_INDUSTRY_PREFERENCES = 'REQUEST_DELETE_INDUSTRY_PREFERENCES';
export const ERROR_DELETE_INDUSTRY_PREFERENCES = 'ERROR_DELETE_INDUSTRY_PREFERENCES';
export const SUCCESS_DELETE_INDUSTRY_PREFERENCES = 'SUCCESS_DELETE_INDUSTRY_PREFERENCES';

export const REQUEST_CREATE_FUNCTION_PREFERENCES = 'REQUEST_CREATE_FUNCTION_PREFERENCES';
export const ERROR_CREATE_FUNCTION_PREFERENCES = 'ERROR_CREATE_FUNCTION_PREFERENCES';
export const SUCCESS_CREATE_FUNCTION_PREFERENCES = 'SUCCESS_CREATE_FUNCTION_PREFERENCES';

export const REQUEST_UPDATE_FUNCTION_PREFERENCES = 'REQUEST_UPDATE_FUNCTION_PREFERENCES';
export const ERROR_UPDATE_FUNCTION_PREFERENCES = 'ERROR_UPDATE_FUNCTION_PREFERENCES';
export const SUCCESS_UPDATE_FUNCTION_PREFERENCES = 'SUCCESS_UPDATE_FUNCTION_PREFERENCES';

export const REQUEST_DELETE_FUNCTION_PREFERENCES = 'REQUEST_DELETE_FUNCTION_PREFERENCES';
export const ERROR_DELETE_FUNCTION_PREFERENCES = 'ERROR_DELETE_FUNCTION_PREFERENCES';
export const SUCCESS_DELETE_FUNCTION_PREFERENCES = 'SUCCESS_DELETE_FUNCTION_PREFERENCES';

export const REQUEST_CREATE_STUDENT_PREFERENCES = 'REQUEST_CREATE_STUDENT_PREFERENCES';
export const ERROR_CREATE_STUDENT_PREFERENCES = 'ERROR_CREATE_STUDENT_PREFERENCES';
export const SUCCESS_CREATE_STUDENT_PREFERENCES = 'SUCCESS_CREATE_STUDENT_PREFERENCES';

export const REQUEST_UPDATE_STUDENT_PREFERENCES = 'REQUEST_UPDATE_STUDENT_PREFERENCES';
export const ERROR_UPDATE_STUDENT_PREFERENCES = 'ERROR_UPDATE_STUDENT_PREFERENCES';
export const SUCCESS_UPDATE_STUDENT_PREFERENCES = 'SUCCESS_UPDATE_STUDENT_PREFERENCES';

export const REQUEST_DELETE_STUDENT_PREFERENCES = 'REQUEST_DELETE_STUDENT_PREFERENCES';
export const ERROR_DELETE_STUDENT_PREFERENCES = 'ERROR_DELETE_STUDENT_PREFERENCES';
export const SUCCESS_DELETE_STUDENT_PREFERENCES = 'SUCCESS_DELETE_STUDENT_PREFERENCES';

/* Browse Companies */
export const REQUEST_GET_COMPANIES_LIST = 'REQUEST_GET_COMPANIES_LIST';
export const ERROR_GET_COMPANIES_LIST = 'ERROR_GET_COMPANIES_LIST';
export const SUCCESS_GET_COMPANIES_LIST = 'SUCCESS_GET_COMPANIES_LIST';

export const REQUEST_GET_COMPANIES_FACETS = 'REQUEST_GET_COMPANIES_FACETS';
export const ERROR_GET_COMPANIES_FACETS = 'ERROR_GET_COMPANIES_FACETS';
export const SUCCESS_GET_COMPANIES_FACETS = 'SUCCESS_GET_COMPANIES_FACETS';

/* Browse Industries */
export const REQUEST_GET_INSTITUTIONS_LIST = 'REQUEST_GET_INSTITUTIONS_LIST';
export const ERROR_GET_INSTITUTIONS_LIST = 'ERROR_GET_INSTITUTIONS_LIST';
export const SUCCESS_GET_INSTITUTIONS_LIST = 'SUCCESS_GET_INSTITUTIONS_LIST';

export const REQUEST_GET_INSTITUTIONS_FACETS = 'REQUEST_GET_INSTITUTIONS_FACETS';
export const ERROR_GET_INSTITUTIONS_FACETS = 'ERROR_GET_INSTITUTIONS_FACETS';
export const SUCCESS_GET_INSTITUTIONS_FACETS = 'SUCCESS_GET_INSTITUTIONS_FACETS';

/* Browse Students */
export const REQUEST_GET_STUDENTS_LIST = 'REQUEST_GET_STUDENTS_LIST';
export const ERROR_GET_STUDENTS_LIST = 'ERROR_GET_STUDENTS_LIST';
export const SUCCESS_GET_STUDENTS_LIST = 'SUCCESS_GET_STUDENTS_LIST';

/* For all */
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';

export const REQUEST_GETALL_DOC_TYPES = 'REQUEST_GETALL_DOC_TYPES';
export const SUCCESS_GETALL_DOC_TYPES = 'SUCCESS_GETALL_DOC_TYPES';

export const REQUEST_GET_PROFILE_DOCS = 'REQUEST_GET_PROFILE_DOCS';
export const SUCCESS_GET_PROFILE_DOCS = 'SUCCESS_GET_PROFILE_DOCS';

export const REQUEST_ADD_PROFILE_DOC = 'REQUEST_ADD_PROFILE_DOC';
export const SUCCESS_ADD_PROFILE_DOC = 'SUCCESS_ADD_PROFILE_DOC';

export const REQUEST_ADD_PROFILE_SIGNED_DOC = 'REQUEST_ADD_PROFILE_SIGNED_DOC';
export const SUCCESS_ADD_PROFILE_SIGNED_DOC = 'SUCCESS_ADD_PROFILE_SIGNED_DOC';

export const REQUEST_DELETE_PROFILE_DOC = 'REQUEST_DELETE_PROFILE_DOC';
export const SUCCESS_DELETE_PROFILE_DOC = 'SUCCESS_DELETE_PROFILE_DOC';

/* Company/Institution Registration */
export const REQUEST_CREATE_COMPANY_USER = 'REQUEST_CREATE_COMPANY_USER';
export const ERROR_CREATE_COMPANY_USER = 'ERROR_CREATE_COMPANY_USER';
export const SUCCESS_CREATE_COMPANY_USER = 'SUCCESS_CREATE_COMPANY_USER';

export const REQUEST_CREATE_INSTITUTION_USER = 'REQUEST_CREATE_INSTITUTION_USER';
export const ERROR_CREATE_INSTITUTION_USER = 'ERROR_CREATE_INSTITUTION_USER';
export const SUCCESS_CREATE_INSTITUTION_USER = 'SUCCESS_CREATE_INSTITUTION_USER';

/* Cites */
export const REQUEST_GET_CITIES_LIST = 'REQUEST_GET_CITIES_LIST';
export const ERROR_GET_CITIES_LIST = 'ERROR_GET_CITIES_LIST';
export const SUCCESS_GET_CITIES_LIST = 'SUCCESS_GET_CITIES_LIST';

/* Job Matches */
export const REQUEST_GET_STUDENT_JOB_MATCHES = 'REQUEST_GET_STUDENT_JOB_MATCHES';
export const ERROR_GET_STUDENT_JOB_MATCHES = 'ERROR_GET_STUDENT_JOB_MATCHES';
export const SUCCESS_GET_STUDENT_JOB_MATCHES = 'SUCCESS_GET_STUDENT_JOB_MATCHES';

export const REQUEST_UPDATE_STUDENT_JOB_MATCHES = 'REQUEST_UPDATE_STUDENT_JOB_MATCHES';
export const ERROR_UPDATE_STUDENT_JOB_MATCHES = 'ERROR_UPDATE_STUDENT_JOB_MATCHES';
export const SUCCESS_UPDATE_STUDENT_JOB_MATCHES = 'SUCCESS_UPDATE_STUDENT_JOB_MATCHES';

/* Individual Pages View */
export const REQUEST_GET_COMPANY_DETAILS_BY_ID = 'REQUEST_GET_COMPANY_DETAILS_BY_ID';
export const ERROR_GET_COMPANY_DETAILS_BY_ID = 'ERROR_GET_COMPANY_DETAILS_BY_ID';
export const SUCCESS_GET_COMPANY_DETAILS_BY_ID = 'SUCCESS_GET_COMPANY_DETAILS_BY_ID';

export const REQUEST_EDIT_COMPANY_DETAILS_BY_ID = 'REQUEST_EDIT_COMPANY_DETAILS_BY_ID';
export const ERROR_EDIT_COMPANY_DETAILS_BY_ID = 'ERROR_EDIT_COMPANY_DETAILS_BY_ID';
export const SUCCESS_EDIT_COMPANY_DETAILS_BY_ID = 'SUCCESS_EDIT_COMPANY_DETAILS_BY_ID';

/* Users List for ADMIN */
export const REQUEST_GET_USERS_LIST = 'REQUEST_GET_USERS_LIST';
export const ERROR_GET_USERS_LIST = 'ERROR_GET_USERS_LIST';
export const SUCCESS_GET_USERS_LIST = 'SUCCESS_GET_USERS_LIST';

/* Students List for ADMIn */
export const REQUEST_GET_STUDENTS_LIST_ADMIN = 'REQUEST_GET_STUDENTS_LIST_ADMIN';
export const ERROR_GET_STUDENTS_LIST_ADMIN = 'ERROR_GET_STUDENTS_LIST_ADMIN';
export const SUCCESS_GET_STUDENTS_LIST_ADMIN = 'SUCCESS_GET_STUDENTS_LIST_ADMIN';

/* Create, update and faculty and courses */
export const REQUEST_CREATE_FACULTY = 'REQUEST_CREATE_FACULTY';
export const ERROR_CREATE_FACULTY = 'ERROR_CREATE_FACULTY';
export const SUCCESS_CREATE_FACULTY = 'SUCCESS_CREATE_FACULTY';

export const REQUEST_DELETE_FACULTY = 'REQUEST_DELETE_FACULTY';
export const ERROR_DELETE_FACULTY = 'ERROR_DELETE_FACULTY';
export const SUCCESS_DELETE_FACULTY = 'SUCCESS_DELETE_FACULTY';

export const REQUEST_EDIT_FACULTY = 'REQUEST_EDIT_FACULTY';
export const ERROR_EDIT_FACULTY = 'ERROR_EDIT_FACULTY';
export const SUCCESS_EDIT_FACULTY = 'SUCCESS_EDIT_FACULTY';

export const REQUEST_CREATE_COURSE = 'REQUEST_CREATE_COURSE';
export const ERROR_CREATE_COURSE = 'ERROR_CREATE_COURSE';
export const SUCCESS_CREATE_COURSE = 'SUCCESS_CREATE_COURSE';

export const REQUEST_EDIT_COURSE = 'REQUEST_EDIT_COURSE';
export const ERROR_EDIT_COURSE = 'ERROR_EDIT_COURSE';
export const SUCCESS_EDIT_COURSE = 'SUCCESS_EDIT_COURSE';

export const REQUEST_DELETE_COURSE = 'REQUEST_DELETE_COURSE';
export const ERROR_DELETE_COURSE = 'ERROR_DELETE_COURSE';
export const SUCCESS_DELETE_COURSE = 'SUCCESS_DELETE_COURSE';

/* Cities curd operations */
export const REQUEST_CREATE_CITIES = 'REQUEST_CREATE_CITIES';
export const ERROR_CREATE_CITIES = 'ERROR_CREATE_CITIES';
export const SUCCESS_CREATE_CITIES = 'SUCCESS_CREATE_CITIES';

export const REQUEST_EDIT_CITY = 'REQUEST_EDIT_CITY';
export const ERROR_EDIT_CITY = 'ERROR_EDIT_CITY';
export const SUCCESS_EDIT_CITY = 'SUCCESS_EDIT_CITY';

export const REQUEST_DELETE_CITY = 'REQUEST_DELETE_CITY';
export const ERROR_DELETE_CITY = 'ERROR_DELETE_CITY';
export const SUCCESS_DELETE_CITY = 'SUCCESS_DELETE_CITY';

/* languages curd operations */
export const REQUEST_GET_LANGUAGES = 'REQUEST_GET_LANGUAGES';
export const ERROR_GET_LANGUAGES = 'ERROR_GET_LANGUAGES';
export const SUCCESS_GET_LANGUAGES = 'SUCCESS_GET_LANGUAGES';

export const REQUEST_CREATE_LANGUAGES = 'REQUEST_CREATE_LANGUAGES';
export const ERROR_CREATE_LANGUAGES = 'ERROR_CREATE_LANGUAGES';
export const SUCCESS_CREATE_LANGUAGES = 'SUCCESS_CREATE_LANGUAGES';

export const REQUEST_EDIT_LANGUAGES = 'REQUEST_EDIT_LANGUAGES';
export const ERROR_EDIT_LANGUAGES = 'ERROR_EDIT_LANGUAGES';
export const SUCCESS_EDIT_LANGUAGES = 'SUCCESS_EDIT_LANGUAGES';

export const REQUEST_DELETE_LANGUAGES = 'REQUEST_DELETE_LANGUAGES';
export const ERROR_DELETE_LANGUAGES = 'ERROR_DELETE_LANGUAGES';
export const SUCCESS_DELETE_LANGUAGES = 'SUCCESS_DELETE_LANGUAGES';

/* Preferred skills */
export const REQUEST_GET_PREFERRED_SKILLS = 'REQUEST_GET_PREFERRED_SKILLS';
export const ERROR_GET_PREFERRED_SKILLS = 'ERROR_GET_PREFERRED_SKILLS';
export const SUCCESS_GET_PREFERRED_SKILLS = 'SUCCESS_GET_PREFERRED_SKILLS';

/* Employers job info */
export const REQUEST_GET_EMPLOYERS_JOB_INFO = 'REQUEST_GET_EMPLOYERS_JOB_INFO';
export const ERROR_GET_EMPLOYERS_JOB_INFO = 'ERROR_GET_EMPLOYERS_JOB_INFO';
export const SUCCESS_GET_EMPLOYERS_JOB_INFO = 'SUCCESS_GET_EMPLOYERS_JOB_INFO';

/* Employers job tansfer */
export const REQUEST_PERFORM_JOB_TRANSFER = 'REQUEST_PERFORM_JOB_TRANSFER';
export const ERROR_PERFORM_JOB_TRANSFER = 'ERROR_PERFORM_JOB_TRANSFER';
export const SUCCESS_PERFORM_JOB_TRANSFER = 'SUCCESS_PERFORM_JOB_TRANSFER';

/* Job matches */
export const REQUEST_GET_JOB_PROFILE_LIST_MATCHING = 'REQUEST_GET_JOB_PROFILE_LIST_MATCHING';
export const ERROR_GET_JOB_PROFILE_LIST_MATCHING = 'ERROR_GET_JOB_PROFILE_LIST_MATCHING';
export const SUCCESS_GET_JOB_PROFILE_LIST_MATCHING = 'SUCCESS_GET_JOB_PROFILE_LIST_MATCHING';

export const REQUEST_CREATE_USER_FOR_COMPANY = 'REQUEST_CREATE_USER_FOR_COMPANY';
export const ERROR_CREATE_USER_FOR_COMPANY = 'ERROR_CREATE_USER_FOR_COMPANY';
export const SUCCESS_CREATE_USER_FOR_COMPANY = 'SUCCESS_CREATE_USER_FOR_COMPANY';

/* Report */
export const REQUEST_GET_REPORTS_DATA = 'REQUEST_GET_REPORTS_DATA';
export const ERROR_GET_REPORTS_DATA = 'ERROR_GET_REPORTS_DATA';
export const SUCCESS_GET_REPORTS_DATA = 'SUCCESS_GET_REPORTS_DATA';

export const REQUEST_DOWNLOAD_REPORTS_DATA = 'REQUEST_DOWNLOAD_REPORTS_DATA';
export const ERROR_DOWNLOAD_REPORTS_DATA = 'ERROR_DOWNLOAD_REPORTS_DATA';
export const SUCCESS_DOWNLOAD_REPORTS_DATA = 'SUCCESS_DOWNLOAD_REPORTS_DATA';

/* Skills learn */
export const REQUEST_GET_SKILL_LEARNS = 'REQUEST_GET_SKILL_LEARNS';
export const ERROR_GET_SKILL_LEARNS = 'ERROR_GET_SKILL_LEARNS';
export const SUCCESS_GET_SKILL_LEARNS = 'SUCCESS_GET_SKILL_LEARNS';

export const REQUEST_CREATE_SKILL_LEARN = 'REQUEST_CREATE_SKILL_LEARN';
export const ERROR_CREATE_SKILL_LEARN = 'ERROR_CREATE_SKILL_LEARN';
export const SUCCESS_CREATE_SKILL_LEARN = 'SUCCESS_CREATE_SKILL_LEARN';

export const REQUEST_DELETE_SKILL_LEARN = 'REQUEST_DELETE_SKILL_LEARN';
export const ERROR_DELETE_SKILL_LEARN = 'ERROR_DELETE_SKILL_LEARN';
export const SUCCESS_DELETE_SKILL_LEARN = 'SUCCESS_DELETE_SKILL_LEARN';

/* Add to job */
export const REQUEST_ADD_TO_JOB = 'REQUEST_ADD_TO_JOB';
export const ERROR_ADD_TO_JOB = 'ERROR_ADD_TO_JOB';
export const SUCCESS_ADD_TO_JOB = 'SUCCESS_ADD_TO_JOB';

export const REQUEST_CREATE_CALENDAR_EVENT = 'REQUEST_CREATE_CALENDAR_EVENT';
export const ERROR_CREATE_CALENDAR_EVENT = 'ERROR_CREATE_CALENDAR_EVENT';
export const SUCCESS_CREATE_CALENDAR_EVENT = 'SUCCESS_CREATE_CALENDAR_EVENT';

export const REQUEST_GET_STUDENT_INTERVIEW_EVENT = 'REQUEST_GET_STUDENT_INTERVIEW_EVENT';
export const ERROR_GET_STUDENT_INTERVIEW_EVENT = 'ERROR_GET_STUDENT_INTERVIEW_EVENT';
export const SUCCESS_GET_STUDENT_INTERVIEW_EVENT = 'SUCCESS_GET_STUDENT_INTERVIEW_EVENT';

export const REQUEST_GET_STUDENT_INTERVIEW_ASSESS = 'REQUEST_GET_STUDENT_INTERVIEW_ASSESS';
export const ERROR_GET_STUDENT_INTERVIEW_ASSESS = 'ERROR_GET_STUDENT_INTERVIEW_ASSESS';
export const SUCCESS_GET_STUDENT_INTERVIEW_ASSESS = 'SUCCESS_GET_STUDENT_INTERVIEW_ASSESS';

export const REQUEST_ADD_CAND_TO_ASSESS = 'REQUEST_ADD_CAND_TO_ASSESS';
export const ERROR_ADD_CAND_TO_ASSESS = 'ERROR_ADD_CAND_TO_ASSESS';
export const SUCCESS_ADD_CAND_TO_ASSESS = 'SUCCESS_ADD_CAND_TO_ASSESS';

export const REQUEST_SEND_ASSESS_INV_EMAIL = 'REQUEST_SEND_ASSESS_INV_EMAIL';
export const ERROR_SEND_ASSESS_INV_EMAIL = 'ERROR_SEND_ASSESS_INV_EMAIL';
export const SUCCESS_SEND_ASSESS_INV_EMAIL = 'SUCCESS_SEND_ASSESS_INV_EMAIL';

/* Browse Students */
export const REQUEST_GET_STUDENTS = 'REQUEST_GET_STUDENTS';
export const ERROR_GET_STUDENTS = 'ERROR_GET_STUDENTS';
export const SUCCESS_GET_STUDENTS = 'SUCCESS_GET_STUDENTS';

/*Companies Users */
export const REQUEST_GET_COMPANIES_USERS = 'REQUEST_GET_COMPANIES_USERS';
export const ERROR_GET_COMPANIES_USERS = 'ERROR_GET_COMPANIES_USERS';
export const SUCCESS_GET_COMPANIES_USERS = 'SUCCESS_GET_COMPANIES_USERS';

/* User Token simulate.js*/
export const REQUEST_USER_TOKEN = 'REQUEST_USER_TOKEN';
export const ERROR_USER_TOKEN = 'ERROR_USER_TOKEN';
export const SUCCESS_USER_TOKEN = 'SUCCESS_USER_TOKEN';

export const REQUEST_GET_STUDENT_FACETS = 'REQUEST_GET_STUDENT_FACETS';
export const ERROR_GET_STUDENT_FACETS = 'ERROR_GET_STUDENT_FACETS';
export const SUCCESS_GET_STUDENT_FACETS = 'SUCCESS_GET_STUDENT_FACETS';

export const REQUEST_CREATE_INSTITUTION_SLOT = 'REQUEST_CREATE_INSTITUTION_SLOT';
export const ERROR_CREATE_INSTITUTION_SLOT = 'ERROR_CREATE_INSTITUTION_SLOT';
export const SUCCESS_CREATE_INSTITUTION_SLOT = 'SUCCESS_CREATE_INSTITUTION_SLOT';

export const REQUEST_GET_INSTITUTION_SLOTS = 'REQUEST_GET_INSTITUTION_SLOTS';
export const ERROR_GET_INSTITUTION_SLOTS = 'ERROR_GET_INSTITUTION_SLOTS';
export const SUCCESS_GET_INSTITUTION_SLOTS = 'SUCCESS_GET_INSTITUTION_SLOTS';

export const REQUEST_EDIT_INSTITUTION_SLOT = 'REQUEST_EDIT_INSTITUTION_SLOT';
export const ERROR_EDIT_INSTITUTION_SLOTS = 'ERROR_EDIT_INSTITUTION_SLOT';
export const SUCCESS_EDIT_INSTITUTION_SLOTS = 'SUCCESS_EDIT_INSTITUTION_SLOT';

export const REQUEST_DELETE_INSTITUTION_SLOT = 'REQUEST_DELETE_INSTITUTION_SLOT';
export const ERROR_DELETE_INSTITUTION_SLOTS = 'ERROR_DELETE_INSTITUTION_SLOT';
export const SUCCESS_DELETE_INSTITUTION_SLOTS = 'SUCCESS_DELETE_INSTITUTION_SLOT';

export const REQUEST_GET_STATUS_MATCHING = 'REQUEST_GET_STATUS_MATCHING';
export const ERROR_GET_STATUS_MATCHING = 'ERROR_GET_STATUS_MATCHING';
export const SUCCESS_STATUS_MATCHING = 'SUCCESS_STATUS_MATCHING';

export const REQUEST_BROWSE_INSTITUTIONS_LIST = 'REQUEST_BROWSE_INSTITUTIONS_LIST';
export const ERROR_BROWSE_INSTITUTIONS_LIST = 'ERROR_BROWSE_INSTITUTIONS_LIST';
export const SUCCESS_BROWSE_INSTITUTIONS_LIST = 'SUCCESS_BROWSE_INSTITUTIONS_LIST';

export const REQUEST_BROWSE_COMPANIES_LIST = 'REQUEST_BROWSE_COMPANIES_LIST';
export const ERROR_BROWSE_COMPANIES_LIST = 'ERROR_BROWSE_COMPANIES_LIST';
export const SUCCESS_BROWSE_COMPANIES_LIST = 'SUCCESS_BROWSE_COMPANIES_LIST';

export const REQUEST_IMPORT_STUDENTS = 'REQUEST_IMPORT_STUDENTS';
export const ERROR_IMPORT_STUDENTS = 'ERROR_IMPORT_STUDENTS';
export const SUCCESS_IMPORT_STUDENTS = 'SUCCESS_IMPORT_STUDENTS';

export const REQUEST_IMPORT_STUDENTS_ATTENDANCE = 'REQUEST_IMPORT_STUDENTS_ATTENDANCE';
export const ERROR_IMPORT_STUDENTS_ATTENDANCE = 'ERROR_IMPORT_STUDENTS_ATTENDANCE';
export const SUCCESS_IMPORT_STUDENTS_ATTENDANCE = 'SUCCESS_IMPORT_STUDENTS_ATTENDANCE';

export const REQUEST_IMPORT_STUDENTS_LO_INFO = 'REQUEST_IMPORT_STUDENTS_LO_INFO';
export const ERROR_IMPORT_STUDENTS_LO_INFO = 'ERROR_IMPORT_STUDENTS_LO_INFO';
export const SUCCESS_IMPORT_STUDENTS_LO_INFO = 'SUCCESS_IMPORT_STUDENTS_LO_INFO';

export const REQUEST_CONFIRM_STUDENTS_EMAIL_INFO = 'REQUEST_CONFIRM_STUDENTS_EMAIL_INFO';
export const ERROR_CONFIRM_STUDENTS_EMAIL_INFO = 'ERROR_CONFIRM_STUDENTS_EMAIL_INFO';
export const SUCCESS_CONFIRM_STUDENTS_EMAIL_INFO = 'SUCCESS_CONFIRM_STUDENTS_EMAIL_INFO';

export const REQUEST_BROWSE_EMPLOYEE_INFO = 'REQUEST_BROWSE_EMPLOYEE_INFO';
export const ERROR_BROWSE_EMPLOYEE_INFO = 'ERROR_BROWSE_EMPLOYEE_INFO';
export const SUCCESS_BROWSE_EMPLOYEE_INFO = 'SUCCESS_BROWSE_EMPLOYEE_INFO';

export const REQUEST_BROWSE_EMPLOYEE_ID_INFO = 'REQUEST_BROWSE_EMPLOYEE_ID_INFO';
export const ERROR_BROWSE_EMPLOYEE_ID_INFO = 'ERROR_BROWSE_EMPLOYEE_ID_INFO';
export const SUCCESS_BROWSE_EMPLOYEE_ID_INFO = 'SUCCESS_BROWSE_EMPLOYEE_ID_INFO';

export const REQUEST_BROWSE_EMPLOYEE_JOBS_INFO = 'REQUEST_BROWSE_EMPLOYEE_JOBS_INFO';
export const ERROR_BROWSE_EMPLOYEE_JOBS_INFO = 'ERROR_BROWSE_EMPLOYEE_JOBS_INFO';
export const SUCCESS_BROWSE_EMPLOYEE_JOBS_INFO = 'SUCCESS_BROWSE_EMPLOYEE_JOBS_INFO';

export const REQUEST_CONFIRM_COMPANY_EMAIL_INFO = 'REQUEST_CONFIRM_COMPANY_EMAIL_INFO';
export const ERROR_CONFIRM_COMPANY_EMAIL_INFO = 'ERROR_CONFIRM_COMPANY_EMAIL_INFO';
export const SUCCESS_CONFIRM_COMPANY_EMAIL_INFO = 'SUCCESS_CONFIRM_COMPANY_EMAIL_INFO';

/* Activity Repots */
export const REQUEST_ANALYTICS_ACTIVITY_REPORTS = 'REQUEST_ANALYTICS_ACTIVITY_REPORTS';
export const ERROR_ANALYTICS_ACTIVITY_REPORTS = 'ERROR_ANALYTICS_ACTIVITY_REPORTS';
export const SUCCESS_ANALYTICS_ACTIVITY_REPORTS = 'SUCCESS_ANALYTICS_ACTIVITY_REPORTS';

export const REQUEST_DELETE_CALENDAR_EVENT = 'REQUEST_DELETE_CALENDAR_EVENT';
export const ERROR_DELETE_CALENDAR_EVENT = 'ERROR_DELETE_CALENDAR_EVENT';
export const SUCCESS_DELETE_CALENDAR_EVENT = 'SUCCESS_DELETE_CALENDAR_EVENT';

export const REQUEST_ANALYTICS_LOGS = 'REQUEST_ANALYTICS_LOGS';
export const ERROR_ANALYTICS_LOGS = 'ERROR_ANALYTICS_LOGS';
export const SUCCESS_ANALYTICS_LOGS = 'SUCCESS_ANALYTICS_LOGS';

/* Student Reports */
export const REQUEST_GET_STUDENT_REPORTS = 'REQUEST_GET_STUDENT_REPORTS';
export const ERROR_GET_STUDENT_REPORTS = 'ERROR_GET_STUDENT_REPORTS';
export const SUCCESS_GET_STUDENT_REPORTS = 'SUCCESS_GET_STUDENT_REPORTS';

/* Student Profile Emails */
export const REQUEST_GET_STUDENT_EMAILS = 'REQUEST_GET_STUDENT_EMAILS';
export const ERROR_GET_STUDENT_EMAILS = 'ERROR_GET_STUDENT_EMAILS';
export const SUCCESS_GET_STUDENT_EMAILS = 'SUCCESS_GET_STUDENT_EMAILS';

/* Student Company Type Preferences */
export const REQUEST_CREATE_STUDENT_COMPANY_PREFERENCES = 'REQUEST_CREATE_STUDENT_COMPANY_PREFERENCES';
export const ERROR_CREATE_STUDENT_COMPANY_PREFERENCES = 'ERROR_CREATE_STUDENT_COMPANY_PREFERENCES';
export const SUCCESS_CREATE_STUDENT_COMPANY_PREFERENCES = 'SUCCESS_CREATE_STUDENT_COMPANY_PREFERENCES';

export const REQUEST_GET_COMPANY_TYPES = 'REQUEST_GET_COMPANY_TYPES';
export const ERROR_GET_COMPANY_TYPES = 'ERROR_GET_COMPANY_TYPES';
export const SUCCESS_GET_COMPANY_TYPES = 'SUCCESS_GET_COMPANY_TYPES';

export const REQUEST_GET_JOBS_APPLIED_COUNT = 'REQUEST_GET_JOBS_APPLIED_COUNT';
export const ERROR_GET_JOBS_APPLIED_COUNT = 'ERROR_GET_JOBS_APPLIED_COUNT';
export const SUCCESS_GET_JOBS_APPLIED_COUNT = 'SUCCESS_GET_JOBS_APPLIED_COUNT';

/* Locations */
export const REQUEST_GET_ALL_LOCATIONS = 'REQUEST_GET_ALL_LOCATIONS';
export const ERROR_GET_ALL_LOCATIONS = 'ERROR_GET_ALL_LOCATIONS';
export const SUCCESS_GET_ALL_LOCATIONS = 'SUCCESS_GET_ALL_LOCATIONS';

/* Company Size */
export const REQUEST_COMPANY_SIZES = 'REQUEST_COMPANY_SIZES';
export const ERROR_COMPANY_SIZES = 'ERROR_COMPANY_SIZES';
export const SUCCESS_COMPANY_SIZES = 'SUCCESS_COMPANY_SIZES';

/* Company Validation */
export const REQUEST_COMPANY_UEN_VALIDATION = 'REQUEST_COMPANY_UEN_VALIDATION';
export const ERROR_COMPANY_UEN_VALIDATION = 'ERROR_COMPANY_UEN_VALIDATION';
export const SUCCESS_COMPANY_UEN_VALIDATION = 'SUCCESS_COMPANY_UEN_VALIDATION';

/* Company Additional Info Update */
export const REQUEST_UPSERT_COMP_ADDL_INFO = 'REQUEST_UPSERT_COMP_ADDL_INFO';
export const ERROR_UPSERT_COMP_ADDL_INFO = 'ERROR_UPSERT_COMP_ADDL_INFO';
export const SUCCESS_UPSERT_COMP_ADDL_INFO = 'SUCCESS_UPSERT_COMP_ADDL_INFO';

/* Company Types */
export const REQUEST_COMPANY_TYPES = 'REQUEST_COMPANY_TYPES';
export const ERROR_COMPANY_TYPES = 'ERROR_COMPANY_TYPES';
export const SUCCESS_COMPANY_TYPES = 'SUCCESS_COMPANY_TYPES';

/* Saluation List */
export const REQUEST_GET_SALUATION_LIST = 'REQUEST_GET_SALUATION_LIST';
export const ERROR_GET_SALUATION_LIST = 'ERROR_GET_SALUATION_LIST';
export const SUCCESS_GET_SALUATION_LIST = 'SUCCESS_GET_SALUATION_LIST';

/* Batch Actions */
export const REQUEST_JOB_BATCH_ACTIONS = 'REQUEST_JOB_BATCH_ACTIONS';
export const ERROR_JOB_BATCH_ACTIONS = 'ERROR_JOB_BATCH_ACTIONS';
export const SUCCESS_JOB_BATCH_ACTIONS = 'SUCCESS_JOB_BATCH_ACTIONS';

export const REQUEST_JOB_SHORTLIST_ACTIONS = 'REQUEST_JOB_SHORTLIST_ACTIONS';
export const ERROR_JOB_SHORTLIST_ACTIONS = 'ERROR_JOB_SHORTLIST_ACTIONS';
export const SUCCESS_JOB_SHORTLIST_ACTIONS = 'SUCCESS_JOB_SHORTLIST_ACTIONS';

/* Batch Actions */
export const REQUEST_GET_COMPANY_INDUSTRIES = 'REQUEST_GET_COMPANY_INDUSTRIES';
export const ERROR_GET_COMPANY_INDUSTRIES = 'ERROR_GET_COMPANY_INDUSTRIES';
export const SUCCESS_GET_COMPANY_INDUSTRIES = 'SUCCESS_GET_COMPANY_INDUSTRIES';

/* Activity Logs */
export const REQUEST_GET_USER_ACTIVITY_LOGS = 'REQUEST_GET_USER_ACTIVITY_LOGS';
export const ERROR_GET_USER_ACTIVITY_LOGS = 'ERROR_GET_USER_ACTIVITY_LOGS';
export const SUCCESS_GET_USER_ACTIVITY_LOGS = 'SUCCESS_GET_USER_ACTIVITY_LOGS';

/* Set As Hired */
export const REQUEST_SET_STUDENT_HIRED = 'REQUEST_SET_STUDENT_HIRED';
export const ERROR_SET_STUDENT_HIRED = 'ERROR_SET_STUDENT_HIRED';
export const SUCCESS_SET_STUDENT_HIRED = 'SUCCESS_SET_STUDENT_HIRED';

export const REQUEST_JOB_APPROVAL_VACANCIES = 'REQUEST_JOB_APPROVAL_VACANCIES';
export const ERROR_JOB_APPROVAL_VACANCIES = 'ERROR_JOB_APPROVAL_VACANCIES';
export const SUCCESS_JOB_APPROVAL_VACANCIES = 'SUCCESS_JOB_APPROVAL_VACANCIES';

export const REQUEST_DASHBOARD_INFO = 'REQUEST_DASHBOARD_INFO';
export const ERROR_DASHBOARD_INFO = 'ERROR_DASHBOARD_INFO';
export const SUCCESS_DASHBOARD_INFO = 'SUCCESS_DASHBOARD_INFO';

export const REQUEST_DOWNLOAD_DOCUMENT = 'REQUEST_DOWNLOAD_DOCUMENT';
export const SUCCESS_DOWNLOAD_DOCUMENT = 'SUCCESS_DOWNLOAD_DOCUMENT';
export const ERROR_DOWNLOAD_DOCUMENT = 'ERROR_DOWNLOAD_DOCUMENT';

export const REQUEST_COMPANY_AUTHORIZED_USERS = 'REQUEST_COMPANY_AUTHORIZED_USERS';
export const SUCCESS_COMPANY_AUTHORIZED_USERS = 'SUCCESS_COMPANY_AUTHORIZED_USERS';
export const ERROR_COMPANY_AUTHORIZED_USERS = 'ERROR_COMPANY_AUTHORIZED_USERS';

export const REQUEST_JOB_REJECT = 'REQUEST_JOB_REJECT';
export const SUCCESS_JOB_REJECT = 'SUCCESS_JOB_REJECT';
export const ERROR_JOB_REJECT = 'ERROR_JOB_REJECT';

/* GET CAND VI RESPONSES */
export const REQUEST_GET_CAND_VI_RESPONSES_BY_ID = 'REQUEST_GET_CAND_VI_RESPONSES_BY_ID';
export const SUCCESS_GET_CAND_VI_RESPONSES_BY_ID = 'SUCCESS_GET_CAND_VI_RESPONSES_BY_ID';
export const ERROR_GET_CAND_VI_RESPONSES_BY_ID = 'ERROR_GET_CAND_VI_RESPONSES_BY_ID';
