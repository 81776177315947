import { withTranslation } from 'react-i18next';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PublicHeader from 'containers/common/publicHeader';

import './Login.css';

class Login extends React.Component {
  state = {};

  render() {
    return (
      <div className="login-page col-12 bx--row m-0 p-0">
        <PublicHeader />
        
        <div className="left-side col-12 col-md-6 col-lg-6 col-xl-6 m-0 p-0 d-flex justify-content-center align-items-center flex-column">
          <div className="p-4">
            <div className="bx--type-alpha font-weight-bold d-flex justify-content-center">I am an Employer</div>
            <div className="d-flex justify-content-center mt-3" style={{ fontSize: 18 }}>
              Manage your applications and candidates in one place.
            </div>
            
            <div className="bx--row m-0 justify-content-center">
              <Link className="bx--btn bx--btn--tertiary mt-3 mr-4" to="/recruiter/login" style={{ color: 'white', borderColor: 'white' }}>Login</Link>
              <Link className="bx--btn bx--btn--tertiary mt-3" to="/public/onboard" style={{ color: 'white', borderColor: 'white' }}>Signup</Link>
            </div>
          </div>
        </div>
        <div className="right-side d-flex justify-content-center align-items-center col m-0 p-0 flex-column">
          <div className="p-4">
            <div className="bx--type-alpha font-weight-bold d-flex justify-content-center">I am a Job Seeker</div>
            <div className="d-flex justify-content-center mt-3" style={{ fontSize: 18 }}>
              Forget searching for jobs, let jobs find you!
            </div>
            <div className="bx--row m-0 p-0 justify-content-center">
              <Link className="bx--btn bx--btn--tertiary mt-3 mr-3 login-candiate-button" to="/professional/login">Login / Signup</Link>
              <Link className="bx--btn bx--btn--tertiary mt-3 login-candiate-button" to="/public/jobs">Browse Jobs</Link>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
