import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_JOB_COLLABORATORS,
  REQUEST_AUTHORIZED_USERS,
  REQUEST_ADD_JOB_COLLABORATOR,
  REQUEST_JOB_COLLABORATORS_DELETE,
  REQUEST_UPDATE_JOB_WORKFLOW_SATGE,
  REQUEST_COMPANY_AUTHORIZED_USERS
} from './actionTypes';

const getAuthorizedUsers = (teamId = 0) => (dispatch) => {
  const url = `${baseUrl}/userinviteemailmap?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_AUTHORIZED_USERS,
    url,
    method: 'GET',
    responseType: 'application/json',
  };
  return callApi(params);
};

const getAuthorizedUsersForCollaborators = (teamId = 0, jobcompanyId) => (dispatch) => {
  const url = `${baseUrl}/userinviteemailmap/${jobcompanyId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_COMPANY_AUTHORIZED_USERS,
    url,
    method: 'GET',
    responseType: 'application/json',
  };
  return callApi(params);
};

const getJobCollaborators = (teamId, jobId = 0) => (dispatch) => {
  const url = `${baseUrl}/jobs/${jobId}/collaborators?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_JOB_COLLABORATORS,
    url,
    method: 'GET',
    responseType: 'application/json',
  };
  return callApi(params);
};


const removeJobCollaborator = data => (dispatch) => {
  const { jobId } = data && data[0];
  const url = `${baseUrl}/jobs/${jobId}/collaborators?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
    actionType: REQUEST_JOB_COLLABORATORS_DELETE,
  };
  return callApi(params);
};


const addJobCollaborator = data => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid job collaborator',
  };
  if (!data) {
    return Promise.reject(err);
  }
  const {
    jobId = 0,
  } = data || {};
  const url = `${baseUrl}/jobs/${jobId}/collaborators?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_ADD_JOB_COLLABORATOR,
    url,
    method: 'POST',
    body: JSON.stringify(data),
    responseType: 'application/json',
  };
  return callApi(params);
};

const updateJobWorkflowStage = data => (dispatch) => {
  const url = `${baseUrl}/jobworkflowstage?_=${Date.now()}`;
  const params = {
    dispatch,
    method: 'PATCH',
    url,
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_JOB_WORKFLOW_SATGE,
  };
  return callApi(params);
};

export {
  getAuthorizedUsers,
  getJobCollaborators,
  addJobCollaborator,
  removeJobCollaborator,
  updateJobWorkflowStage,
  getAuthorizedUsersForCollaborators
};
