import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, ToolbarItem,
  Modal, TextInput, Pagination,
} from 'carbon-components-react';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import BackRow from 'components/common/BackRow/BackRow';
import {
  getAllFaculties,
  getAllCourses,
} from 'actions/candidate/CandidateDetails';
import {
  createCourseDetails,
  upsertCourseDetailsById,
  deleteCourseDetailsById,
} from 'actions/InstitutionView';

class CourseView extends Component {
  instPaths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'institution', name: 'Browse Faculty', href: '/app/campus/browse/faculties' },
    { id: 'inst-view', name: 'Course Info', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      isOpenCourseModal: false,
      isValidCourseName: false,
      isValidCourseNameText: '',
      courseName: '',
      facultyActivePage: 1,
      facultyItemsPerPage: 10,
      facultySearchKey: '',
      courseId: 0,
      isOpenDeleteCourseModal: false,
      courseItemsPerPage: 10,
      courseActivePage: 1,
      courseSearchKey: '',
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {
          institutionId,
        } = {},
      } = {},
    } = this.props;
    const {
      facultyActivePage,
      facultyItemsPerPage,
      facultySearchKey,
    } = this.state;
    const data = {
      schoolId: institutionId,
      facultyActivePage,
      facultyItemsPerPage,
      facultySearchKey,
    };
    this.handleCourseApiCall();
    this.props.getAllFaculties(data);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  activeCourseModal = (courseId, courseName) => {
    if (courseId) {
      this.setState({
        isOpenCourseModal: true,
        courseId,
        courseName,
      });
    } else {
      this.setState({
        isOpenCourseModal: true,
        courseId: 0,
        courseName: '',
      });
    }
  }

  dismissCourseModal = () => {
    this.setState({
      isOpenCourseModal: false,
      isValidCourseName: false,
      isValidCourseNameText: '',
      courseName: '',
      courseId: 0,
    });
  }

  activeDeleteCourseModal = (courseId) => {
    this.setState({
      isOpenDeleteCourseModal: true,
      courseId,
    });
  }

  dismissDeleteCourseModal = () => {
    this.setState({
      isOpenDeleteCourseModal: false,
    });
  }

  handleRedirectToInstitutionPage = (id) => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push(`/app/campus/institution/info/${id}`);
    }
  }

  handleSubmitCourseDetails = () => {
    const {
      courseName,
    } = this.state;
    const {
      t,
    } = this.props;
    let isValidCourseName = null;
    let isValidCourseNameText = '';
    if (!courseName || courseName === null || courseName === undefined) {
      isValidCourseName = true;
      isValidCourseNameText = `${t('courseNameValidation')}`;
    } else {
      isValidCourseName = false;
      isValidCourseNameText = '';
    }
    this.setState({
      isValidCourseName,
      isValidCourseNameText,
    }, () => {
      this.handleCheckCourValidation();
    });
  }

  handleCourseApiCall = () => {
    const {
      match: {
        params: {
          institutionId,
          facultyId,
        } = {},
      } = {},
    } = this.props;
    const {
      courseItemsPerPage,
      courseSearchKey,
      courseActivePage,
    } = this.state;
    const finalData = {
      schoolId: institutionId,
      facultyId,
      courseItemsPerPage,
      courseSearchKey,
      courseActivePage,
    };
    this.props.getAllCourses(finalData);
  }

  handleCheckCourValidation = () => {
    const {
      isValidCourseName,
      courseName,
      courseId,
    } = this.state;
    const {
      match: {
        params: {
          institutionId,
          facultyId,
        } = {},
      } = {},
    } = this.props;
    if (isValidCourseName !== true) {
      const data = {
        schoolId: institutionId,
        facultyId,
        courseName,
      };
      if (courseId) {
        data.courseId = courseId;
        this.props.upsertCourseDetailsById(data).then((res) => {
          if (res && !res.error) {
            this.handleCourseApiCall();
            this.dismissCourseModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      } else {
        this.props.createCourseDetails(data).then((res) => {
          if (res && !res.error) {
            this.handleCourseApiCall();
            this.dismissCourseModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      }
    }
  }

  handleDeleteCourseDetails = () => {
    const {
      courseId,
    } = this.state;
    this.props.deleteCourseDetailsById(courseId).then((res) => {
      if (res && !res.error) {
        this.handleCourseApiCall();
        this.dismissDeleteCourseModal();
      }
    })
      .catch(e => console.log('Error:::::', e));
  }

  handleCoursePaginationChange = (data) => {
    const courseActivePage = (data.page) || 1;
    const courseItemsPerPage = data.pageSize || this.state.courseItemsPerPage;
    this.setState({ courseActivePage, courseItemsPerPage }, () => {
      this.handleCourseApiCall();
    });
  }

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ courseSearchKey: event.target.value, courseActivePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.handleCourseApiCall()
      ), 1000);
    });
  }

  render() {
    const {
      isOpenCourseModal,
      isValidCourseName, isValidCourseNameText,
      courseId, isOpenDeleteCourseModal,
      courseActivePage,
    } = this.state;
    const {
      match: {
        params: {
          facultyId,
        } = {},
      } = {},
      coursesData,
      facultyData,
      t,
      coursesCount,
      institutionLoading,
      loading,
      user: {
        isAdmin,
        isSuperAdmin,
        facultyId: userFacultyId,
      } = {},
    } = this.props;
    const finalFacultyData = facultyData && Array.isArray(facultyData)
      && facultyData.length > 0 && facultyData.filter(e => e.facultyId == facultyId);
    const {
      facultyName,
      schoolId,
      company: {
        companyName,
      } = {},
    } = (finalFacultyData && Array.isArray(finalFacultyData)
      && finalFacultyData.length > 0 && finalFacultyData[0]) || [];
    const columnWithFilter = [
      {
        title: `${t('course')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    const otherActionButtons = [];
    if (userFacultyId == facultyId || isSuperAdmin === true) {
      otherActionButtons.push(
        <ToolbarItem>
          <Button
            className="mr-2"
            onClick={() => this.activeCourseModal(null, null)}
            kind="primary"
          >
            {t('add')} {t('course')}
          </Button>
        </ToolbarItem>,
      );
    }
    return (
      <Fragment>
        <BackRow paths={this.instPaths} />
        <div className="text-capitalize">
          <h6>{facultyName}</h6>
        </div>
        <GenericTabs
          selected={0}
          tabContent={[
            {
              label: t('course'),
              value: '',
              onClick: () => {},
            },
          ]}
        />
        {
          coursesData && Array.isArray(coursesData)
            && coursesData.length > 0
            && (
              <div>
                <div className="bx--type-zeta text-uppercase mb-2">
                  {t('course')} {t('details')}
                </div>
                <GenericDataTable
                  className=""
                  otherActions={otherActionButtons}
                  headers={
                    columnWithFilter.map((data, index) => (
                      {
                        key: `header_${index}`,
                        header: data.title,
                        className: data.className,
                        colpropName: data.propName,
                      }
                    ))}
                  rows={coursesData.map((res) => {
                    const {
                      courseName: propCourseName,
                      courseId: propCourseId,
                    } = res || {};
                    return ({
                      isExpanded: true,
                      id: `row_${propCourseId}`,
                      header_0: (
                        <div className="text-capitalize text-center text-lg-left text-md-left font-weight-bold text-dark">
                          {propCourseName}
                        </div>
                      ),
                      header_1: (
                        <div className="d-flex justify-content-center">
                          {
                            (isAdmin === true || isSuperAdmin === true)
                              ? (
                                <Fragment>
                                  <div>
                                    <Button onClick={() => this.activeCourseModal(propCourseId, propCourseName)} kind="tertiary" small>{t('edit')}</Button>
                                  </div>
                                  {/*<div className="ml-2">
                                    <Button onClick={() => this.activeDeleteCourseModal(propCourseId)} kind="danger" small>{t('delete')}</Button>
                                  </div>*/}
                                </Fragment>
                              ) : '-'
                          }
                        </div>
                      ),
                    });
                  })}
                  selectable={false}
                  searchable
                  onSearchInputChange={this.handleSearchSubmit}
                  expandable={false}
                  batchActions={false}
                />
                {
                  coursesCount && coursesCount > 0 && (
                    <Pagination onChange={this.handleCoursePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={courseActivePage} totalItems={coursesCount} />
                  )
                }
              </div>
            )
        }
        {
          (!coursesData || (
            coursesData && Array.isArray(coursesData)
              && coursesData.length <= 0
          )) && (
            <div className="mt-2 bx--tile">
              <div className="text-center">
                <div className="empty-icon">
                  <i className="fa fa-flag fa-2x" />
                </div>
                <p className="empty-title h5">{t('noCoursesMsg')}</p>
                {
                  (isAdmin === true || isSuperAdmin === true)
                    && (
                      <Button
                        className="mr-2"
                        onClick={() => this.activeCourseModal(null, null)}
                        kind="primary"
                      >
                        {t('add')} {t('course')}
                      </Button>
                    )
                }
              </div>
            </div>
          )
        }
        <Modal
          open={isOpenCourseModal}
          modalHeading={`${!courseId ? t('add') : t('edit')} ${t('course')}`}
          modalLabel={`${t('course')}`}
          primaryButtonText={!courseId ? t('add') : t('edit')}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissCourseModal}
          onRequestSubmit={this.handleSubmitCourseDetails}
        >
          <TextInput
            className="mb-2"
            name="courseName"
            id="courseName"
            labelText={`${t('course')} ${t('name')}`}
            value={this.state.courseName}
            placeholder={`${t('course')} ${t('name')}`}
            invalid={isValidCourseName}
            invalidText={isValidCourseNameText}
            onChange={e => this.handleChange(e)}
          />
        </Modal>
        <Modal
          open={isOpenDeleteCourseModal}
          modalHeading={`${t('delete')} ${t('course')}`}
          modalLabel={`${t('course')}`}
          primaryButtonText={t('delete')}
          danger
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissDeleteCourseModal}
          onRequestSubmit={this.handleDeleteCourseDetails}
        >
          <div className="text-dark font-weight-bold">
            {t('deleteCourseMsg')}
          </div>
        </Modal>
        <JDProcessOverlay show={(loading || institutionLoading)} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  facultyData: state.candidateDetails.facultyData,
  coursesData: state.candidateDetails.coursesData,
  coursesCount: state.candidateDetails.coursesCount,
  loading: state.candidateDetails.loading,
  institutionLoading: state.InstitutionView.loading,
});

const mapDispatchToProps = {
  getAllFaculties,
  getAllCourses,
  createCourseDetails,
  upsertCourseDetailsById,
  deleteCourseDetailsById,
};

export default
connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourseView));
