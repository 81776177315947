import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';

// import './internCandidate.css';
import {
  REQUEST_CREATE_STUDENT_PROFILE,
  REQUEST_UPDATE_STUDENT_PROFILE,
  REQUEST_DELETE_STUDENT_PROFILE,
  REQUEST_GET_STUDENT_PROFILE,
  REQUEST_CREATE_STUDENT_LANGUAGES,
  REQUEST_UPDATE_STUDENT_LANGUAGES,
  REQUEST_DELETE_STUDENT_LANGUAGES,
  REQUEST_CREATE_STUDENT_PREFERENCES,
  REQUEST_UPDATE_STUDENT_PREFERENCES,
  REQUEST_DELETE_STUDENT_PREFERENCES,
  REQUEST_CREATE_CITY_PREFERENCES,
  REQUEST_UPDATE_CITY_PREFERENCES,
  REQUEST_DELETE_CITY_PREFERENCES,
  REQUEST_CREATE_INDUSTRY_PREFERENCES,
  REQUEST_UPDATE_INDUSTRY_PREFERENCES,
  REQUEST_DELETE_INDUSTRY_PREFERENCES,
  REQUEST_CREATE_FUNCTION_PREFERENCES,
  REQUEST_UPDATE_FUNCTION_PREFERENCES,
  REQUEST_DELETE_FUNCTION_PREFERENCES,
  REQUEST_GET_PREFERRED_SKILLS,
  REQUEST_GET_SKILL_LEARNS,
  REQUEST_CREATE_SKILL_LEARN,
  REQUEST_DELETE_SKILL_LEARN,
  REQUEST_CREATE_STUDENT_COMPANY_PREFERENCES,
  REQUEST_GET_COMPANY_TYPES,
} from '../actionTypes';

const createStudentProfile = data => (dispatch) => {
  const url = `${baseUrl}/studentprofile`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_STUDENT_PROFILE,
  };
  return callApi(params);
};

const updateStudentProfile = (data, id) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_STUDENT_PROFILE,
  };
  return callApi(params);
};

const deleteStudentProfile = id => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_STUDENT_PROFILE,
  };
  return callApi(params);
};

const getStudentProfile = id => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_STUDENT_PROFILE,
  };
  return callApi(params);
};

const createStudentLanguages = (data, id) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/language`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_STUDENT_LANGUAGES,
  };
  return callApi(params);
};

const updateStudentLanguages = (data, id, langId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/language/${langId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_STUDENT_LANGUAGES,
  };
  return callApi(params);
};

const deleteStudentLanguages = (id, langId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/language/${langId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_STUDENT_LANGUAGES,
  };
  return callApi(params);
};

const createStudentPreference = (data, id) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/studentpreference`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_STUDENT_PREFERENCES,
  };
  return callApi(params);
};

const updateStudentPreference = (data, id, prefId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/studentpreference/${prefId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_STUDENT_PREFERENCES,
  };
  return callApi(params);
};

const deleteStudentPreference = (id, prefId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/studentpreference/${prefId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_STUDENT_PREFERENCES,
  };
  return callApi(params);
};

const createCityPerference = (data, id) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/cityperference`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_CITY_PREFERENCES,
  };
  return callApi(params);
};

const updateCityPerference = (data, id, cityId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/cityperference/${cityId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_CITY_PREFERENCES,
  };
  return callApi(params);
};

const deleteCityPerference = (id, cityId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/cityperference/${cityId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_CITY_PREFERENCES,
  };
  return callApi(params);
};

const createIndustryPreferences = (data, id) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/industryperference`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_INDUSTRY_PREFERENCES,
  };
  return callApi(params);
};

const updateIndustryPreferences = (data, id, industryId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/industryperference/${industryId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_INDUSTRY_PREFERENCES,
  };
  return callApi(params);
};

const deleteIndustryPreferences = (id, industryId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/industryperference/${industryId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_INDUSTRY_PREFERENCES,
  };
  return callApi(params);
};

const createFunctionPreferences = (data, id) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/studentjobfunction`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_FUNCTION_PREFERENCES,
  };
  return callApi(params);
};

const updateFunctionPreferences = (data, id, functionId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/studentjobfunction/${functionId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_FUNCTION_PREFERENCES,
  };
  return callApi(params);
};

const deleteFunctionPreferences = (id, functionId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${id}/studentjobfunction/${functionId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_FUNCTION_PREFERENCES,
  };
  return callApi(params);
};

const getSkillsPreferredList = preferredVal => (dispatch) => {
  const url = `${baseUrl}/skills?preferred=${preferredVal}&_=1568700853903`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PREFERRED_SKILLS,
  };
  return callApi(params);
};

const createSkillsLearnsForStds = data => (dispatch) => {
  const {
    studentProfileId,
  } = data || {};
  const url = `${baseUrl}/studentprofile/${studentProfileId}/studentlearn`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_SKILL_LEARN,
  };
  return callApi(params);
};

const deleteSkillsLearnsForStdsById = (studentProfileId, stuLearnId) => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${studentProfileId}/studentlearn/${stuLearnId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_SKILL_LEARN,
  };
  return callApi(params);
};

const getSkillsLearnsForStds = studentProfileId => (dispatch) => {
  const url = `${baseUrl}/studentprofile/${studentProfileId}/studentlearn`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_SKILL_LEARNS,
  };
  return callApi(params);
};

const createStudentCompanyPref = data => (dispatch) => {
  const {
    studentProfileId,
    ctypeData,
  } = data || {};
  if (!studentProfileId) {
    const error = {
      msg: 'Invalid student profile id passed',
    };
    Promise.reject(error);
  }
  const url = `${baseUrl}/studentprofile/${studentProfileId}/ctypepreference`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(ctypeData),
    actionType: REQUEST_CREATE_STUDENT_COMPANY_PREFERENCES,
  };
  return callApi(params);
};

const getCompanyTypes = () => (dispatch) => {
  const url = `${baseUrl}/companytypes`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_COMPANY_TYPES,
  };
  return callApi(params);
};


export {
  createStudentProfile,
  updateStudentProfile,
  deleteStudentProfile,
  getStudentProfile,
  createStudentLanguages,
  updateStudentLanguages,
  deleteStudentLanguages,
  createCityPerference,
  updateCityPerference,
  deleteCityPerference,
  createIndustryPreferences,
  updateIndustryPreferences,
  deleteIndustryPreferences,
  createFunctionPreferences,
  updateFunctionPreferences,
  deleteFunctionPreferences,
  createStudentPreference,
  updateStudentPreference,
  deleteStudentPreference,
  getSkillsPreferredList,
  createSkillsLearnsForStds,
  deleteSkillsLearnsForStdsById,
  getSkillsLearnsForStds,
  createStudentCompanyPref,
  getCompanyTypes,
};
