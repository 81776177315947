import { withTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import LanguageChange from 'containers/common/languageChange';
import republicPolytechnic from 'assets/images/republic-polytechnic-white.png';


import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
} from 'carbon-components-react/lib/components/UIShell';
import './publicHeader.css';

const PublicHeader = ({
  showLanguageModal = false,
  handleLangugaeModal,
  handleRedirectToLogin,
  showAdminLogin,
  handleRedirectToAdminPage,
  t,
  userGuideLink,
}) => {
  const currLanguage = localStorage.getItem('i18nextLng');
  let host = window.location.host;
  if(host) {
    host = host.toLowerCase();
  }
  const polyLogoURL = `https://acadpubassets.blob.core.windows.net/xpub/${host}/logo-top.png`;
  let polyHeaderName = "Polytechnic";
  let showLanguageSwitcher = false;
  if(
    host.includes("cstage")
    || host.includes("geip.np.edu.sg")
    || host.includes("localhost:4545")
  ) {
    polyHeaderName = "GEIP @SG";
    showLanguageSwitcher = true;
  }
  if(host.includes("rp.edu.sg")
    || host.includes("rps.x0pa.ai")
    || host.includes("localhost:4565")) {
    polyHeaderName = "Internship Placement System";
  }

  return (
    <div>
      <Header aria-label="X0PA">
        <HeaderName onClick={() => handleRedirectToLogin()} className="pr-0 pl-0" href="javascript:void(0)" prefix="">
          <img style={{ height: '100%' }} className="xpa-top-header--logo" src={polyLogoURL} alt="logo" />
        </HeaderName>
        <b className="pl-3 app-name-color">{polyHeaderName}</b>
        <HeaderGlobalBar>
          {
            showAdminLogin === true && (
              <HeaderGlobalAction className="pt-2 right-action-width" aria-label="Admin Login" onClick={() => handleRedirectToAdminPage()}>
                <div className="right-action-color">
                  {t('adminLogin')}
                </div>
              </HeaderGlobalAction>
            )
          }
          {/* {
            showAdminLogin !== true && (
              <HeaderGlobalAction className="pt-2 right-action-width" aria-label="User Guide" onClick={() => {}}>
                <a target="_blank" href={userGuideLink}>
                  <span className="right-action-color">
                    {t('userguide')}
                  </span>
                </a>
              </HeaderGlobalAction>
            )
          } */}
          {
            showLanguageSwitcher && (
              <HeaderGlobalAction aria-label="Language Change" onClick={() => handleLangugaeModal()}>
              {
                currLanguage && (
                  <div className="current-lang">
                    {currLanguage}
                  </div>
                )
              }
            </HeaderGlobalAction>
            )
          }
        </HeaderGlobalBar>
        <HeaderPanel expanded={showLanguageModal}>
          {
            showLanguageModal === true && (
              <LanguageChange showLanguageModal={showLanguageModal} />
            )
          }
        </HeaderPanel>
      </Header>
    </div>
  );
};

export default (withTranslation()(PublicHeader));
