import { useTranslation } from 'react-i18next';
import React from 'react';
import { Tile } from 'carbon-components-react';

const JobNotFound = (props) => {
  const {
    isUserLoggedIn,
  } = props;
  return (
    <Tile className="p-4 mb-2">
      <div className="empty text-center">
        <div className="empty-icon">
          <i className="fa fa-flag fa-2x" />
        </div>
        <p className="empty-title h5">{useTranslation().t('noJobInfo')}</p>
        {
          isUserLoggedIn !== true && (
            <div>
              You may have to login in to the portal if you are an employer. <a href="/">Login Here</a>
            </div>
          )
        }
      </div>
    </Tile>
  )
};

export default JobNotFound;
