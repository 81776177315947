import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Button,
} from 'carbon-components-react';
import Steps, { Step } from 'rc-steps';
import './WizardSteps.css';

const WizardSteps = (props) => {
  const {
    t,
    header,
    handleRedirectToHome,
    currStep,
    hideCompanyDetTerms,
    onChange,
    handleFinalStep,
    handleSubmitUserDetails,
    state,
    stepsArr,
    handleOnBackSteps,
    hideBtnValues,
  } = props || {};
  const containerStyle = {
    border: '1px solid #dedcdc',
  };
  const finalStep = stepsArr && Array.isArray(stepsArr) && stepsArr.length;
  return (
    <div>
      <div className="d-lg-flex d-md-flex justify-content-between">
        <div>
          <h3>
            {header}
          </h3>
        </div>
        {
          hideBtnValues !== true && (
            <div>
              <Button kind="secondary" onClick={handleRedirectToHome}>
                {t('cancel')}
              </Button>
              {
                currStep !== 0 && (
                  <Button
                    kind="tertiary"
                    className="ml-2"
                    onClick={handleOnBackSteps}>
                    Back
                  </Button>
                )
              }
              {currStep !== finalStep - 1 && hideCompanyDetTerms === false && (
                <Button
                  kind="primary"
                  className="ml-2"
                  onClick={() => onChange(null)}>
                  {t('next')}
                </Button>
              )}
              {currStep === finalStep - 1 && hideCompanyDetTerms === false && (
                <Button
                  kind="primary"
                  className="ml-lg-2 ml-md-2 mt-2 mt-lg-0 mt-md-0"
                  onClick={handleFinalStep}>
                  {t('submit')}
                </Button>
              )}
              {currStep === 1 && hideCompanyDetTerms === true && (
                <Button
                  kind="primary"
                  className="ml-2"
                  onClick={handleSubmitUserDetails}>
                  {t('submit')}
                </Button>
              )}
            </div>
          )
        }
      </div>
      <div className="d-lg-none d-md-none">
        {
          stepsArr && Array.isArray(stepsArr)
            && stepsArr.map((res, idx) => (
              <div className="mt-3">
                {
                  currStep === idx && (
                    <div className="d-flex align-items-center">
                      <div className="circle">{idx + 1}</div>
                      <div className="ml-1 bx--type-zeta">{res}</div>
                    </div>
                  )
                }
              </div>
            ))
        }
      </div>
      <div className="mt-2 d-none d-lg-block d-md-block mb-2">
        <Steps
          style={containerStyle}
          className="p-2"
          type="navigation"
          current={currStep}
          onChange={onChange}>
          {stepsArr.map((res, idx) => {
            let disabledStatus = false;
            if (state[`${idx}-status`] === 'error') {
              disabledStatus = true;
            } else {
              disabledStatus = false;
            }
            return (
              <Step
                className={disabledStatus === true ? 'pt-1' : ''}
                title={res}
                disabled={disabledStatus}
                status={
                  state[`${idx}-status`] === 'error'
                    ? 'wait'
                    : state[`${idx}-status`]
                }
              />
            );
          })}
        </Steps>
      </div>
    </div>
  );
};

export default (withTranslation()(WizardSteps));
