import React from 'react';
import {
  Development32, Building32,
  Education32, Events32, Document32,
  EventsAlt32, Email32, Map32, Language32,
  Settings32, Enterprise32
} from '@carbon/icons-react';

const CarbonIconsHeader = (props) => {
  const {
    label,
  } = props;
  let currIcon = '';
  switch (label) {
    case 'companies':
      currIcon = <Development32 />
      break;
    case 'institutions':
      currIcon = <Building32 />;
      break;
    case 'students':
      currIcon = <Education32 />;
      break;
    case 'faculty':
      currIcon = <Events32 />;
      break;
    case 'jobs':
      currIcon = <Document32 />;
      break;
    case 'applicants':
      currIcon = <EventsAlt32 />;
      break;
    case 'emailTemplates':
      currIcon = <Email32 />;
      break;
    case 'cities':
      currIcon = <Map32 />;
      break;
    case 'languages':
      currIcon = <Language32 />;
      break;
    case 'settings':
      currIcon = <Settings32 />;
      break;
    case 'employees':
      currIcon = <Events32 />;
      break;  
    case 'Register new company':
      currIcon = <Enterprise32 />;
    default:
      break;
  }
  return currIcon;
};

export default CarbonIconsHeader;
