import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import BackRow from 'components/common/BackRow/BackRow';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import AnalyticsList from 'containers/roboroy/AnalyticsList/AnalyticsList';

class RecruiterAnalytics extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'user-1', name: 'Users', href: '/app/campus/users/view' },
    { id: 'users', name: 'Analytics', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const {
      location: {
        search,
      } = {},
    } = this.props;
    const searchSplit = search.split('?');
    const resSplt = searchSplit[1].split('&');
    const finalVal = resSplt && Array.isArray(resSplt) && resSplt.length > 0
      && resSplt.map((res) => {
        if (res) {
          const valSpl = res.split('=');
          this.setState({
            [valSpl[0]]: valSpl[1],
          });
        }
      });
  }

  render() {
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <GenericTabs
          selected={0}
          tabContent={[
            {
              label: 'Analytics',
              value: (<AnalyticsList
                value={null}
                jobID={null}
                applicationID={null}
                companyID={this.state['companyId']}
                recruiterId={this.state['recruiterId']}
              />),
              onClick: () => {},
            },
          ]}
        />
      </Fragment>
    );
  }
}

export default RecruiterAnalytics;
