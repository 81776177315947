export const users = [
  'Alise Maris',
  'Lessie Ho',
  'Abdul Coldren',
  'Bud Gaynor',
  'Ivette Russum',
  'Merideth Otero',
];

export const locations = [
  'Lanercoast',
  'Penzance',
  'Beachmarsh',
  'Ballinamallard',
  'Beachcastle',
  'Kelna',
  'Ormskirk',
  'Norfolk',
  'Tunstead',
  'Pathstow',
];

export const jobs = [
"Account Executive",
"Administrative Assistant",
"Administrative Manager",
"Branch Manager",
"Business Analyst",
"Business Manager",
"Chief Executive Officer",
"Office Manager",
]

export const stages = ['Interview', 'Application', 'Screening'];
export default {};
