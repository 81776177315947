import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Loading, Button,
} from 'carbon-components-react';
import { throttle } from 'lodash';

import { getInterviewURL } from 'actions/videoInterview';

class RRQuestionsIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      interview: {},
      interviewURL: '',
      showNotification: false,
      emailSent: false,
      videos: [],
      rrLoading: true,
      canSendInvite: false,
      noAnswerableQuestions: false,
      sendingInvite: false,
      isError: false,
    };
  }

  componentDidMount() {
    const {
      interviewId,
    } = this.props;
    this.props.getInterviewURL({
      interviewId,
    })
      .then((res) => {
        if (res && res.data) {
          this.setState({
            interviewURL: res.data,
          });
        } else {
          this.setState({
            isError: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    window.addEventListener('message', throttle(this.adjustVideoIframeHeight, 2000), false);
  }

  adjustVideoIframeHeight = (event) => {
    const { interviewId } = this.props;
    const { origin, data = [] } = event;
    const allowedOrigins = [
      'https://demo.recright.com',
      'https://recright.com',
      'https://www.recright.com',
    ];
    if (!allowedOrigins.includes(origin)) {
      console.error('Post Message from malicious domain');
      return false;
    }
    const rrFrameWrap = document.querySelector(`#rr-iframe-wrap-${interviewId}`);
    const rrFrame = document.querySelector(`#rr-iframe-${interviewId}`);
    const rrLoader = document.querySelector(`#rr-loader-${interviewId}`);
    if (rrFrameWrap) {
      rrFrameWrap.classList.remove('invisible');
    }
    if (rrLoader) {
      rrLoader.hidden = true;
    }
    if (rrFrame && data[2] && data[2] !== rrFrame.clientHeight) {
      rrFrame.height = event.data[2];
    }
  }

  render() {
    const { interviewId } = this.props;
    const { interviewURL, isError } = this.state;
    return (
      <div className="mt-3">
        <div id={`rr-loader-${interviewId}`}>
          { !isError && <Loading withOverlay={false} /> }
        </div>
        <div id={`rr-iframe-wrap-${interviewId}`} className="invisible">
          <iframe
            title={`RecRight Iframe - Video Interview${interviewId}`}
            id={`rr-iframe-${interviewId}`}
            height="300"
            className="video-iframe"
            allow="camera;microphone"
            src={interviewURL}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.workflows.loading,
  user: state.x0paDuser.user,
  workflows: state.workflows,
});

const mapDispatchToProps = {
  getInterviewURL,
};

export default connect(mapStateToProps, mapDispatchToProps)(RRQuestionsIframe);
