import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import { REQUEST_X0PA_DUSER, SUCCESS_X0PA_DUSER, ERROR_X0PA_DUSER, REQUEST_USER_TALENTPOOL_SHARE, 
  REQUEST_USER_TALENTPOOL_SHARE_INFO, REQUEST_USER_LOGIN_INFO, REQUEST_GET_USER_LOGIN_INFO, REQUEST_GET_USER_LOGIN } from './actionTypes';

const fetchUserLoginState = () => (dispatch) => {
  const url = `${baseUrl}/userinfo-base/profileInformation?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_X0PA_DUSER,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const enableTalentPoolSharing = data => (dispatch) => {
  const {
    enableTalentpoolSharing,
    userId,
  } = data;
  const url = `${baseUrl}/userinfos/${userId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify({ enableTalentpoolSharing }),
    actionType: REQUEST_USER_TALENTPOOL_SHARE,
  };

  return callApi(params);
};

const userLoginInfo = data => (dispatch) => {  
  const url = `${baseUrl}/userinfos/logininfo`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_USER_LOGIN_INFO,
  };

  return callApi(params);
};

const getIsEligibleToLogin = data => (dispatch) => { 
  const { loginInfoId } = data 
  const url = `${baseUrl}/userinfos/logininfo/${loginInfoId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_GET_USER_LOGIN,
  };

  return callApi(params);
};

const getUserLoginInfo = (email) => (dispatch) => {
  const url = `${baseUrl}/userinfos/${encodeURIComponent(JSON.stringify(email.email))}/getlogininfo`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_USER_LOGIN_INFO,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getTalentPoolInviteInfo = (userId, hash) => (dispatch) => {
  const url = `${baseUrl}/userinfos/${userId}/invites/${hash}`;
  const params = {
    dispatch,
    actionType: REQUEST_USER_TALENTPOOL_SHARE_INFO,
    url,
    method: 'GET',
  };
  return callApi(params);
};

export  {fetchUserLoginState, enableTalentPoolSharing, getTalentPoolInviteInfo, userLoginInfo, getUserLoginInfo, getIsEligibleToLogin};
