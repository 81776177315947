import axios from 'axios';
import superagent from 'superagent';
import { store } from '../App';
import { regularUrl } from 'utils/config';
axios.defaults.baseURL = `${regularUrl}/`;
// http://localhost:4545/api/
// https://ai.x0pa.com/api/
// axios.defaults.baseURL = 'https://a.x0pa.com:3333/';

export const [get, post] = ['get', 'post'].map(method => (path, data) =>
  axios({
    method,
    url: path,
    data,
  }).then(response => response.data));

export const getEmployeeData = username => post('upload/getJson', { username });
export const getEmployees = username => post('upload/getJson/2', { username });
export const getEmployeePredictions = username => post('upload/getJson/3', { username });
export const getOTP = email => post('emailVerify', { email });
export const login = ({username, password, captcha}) => post('login', { username, password, captcha});
export const logout = () => get('logout');
export const upload = () =>
  superagent
    .post(`${axios.defaults.baseURL}upload/csv`)
    .field('username', store.getState().x0paDuser.user&&store.getState().x0paDuser.user.userId?store.getState().x0paDuser.user.userId:'');
export const signUp = () => superagent.post(`${axios.defaults.baseURL}upload/file`);
export const getCandidateData = () => get('upload/candidate');
