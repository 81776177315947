import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Pagination,
  Button,
  DataTable,
  Search,
  Select,
  SelectItem,
  Modal,
  RadioButton,
  Tag,
  Tile,
  RadioButtonGroup,
  InlineNotification
} from 'carbon-components-react';
import { fetchAllCompaniesList } from 'actions/BrowseCompanies';
import SetMixPanel from 'components/common/GetCurrentPath';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import JobDetailsModal from './JobDetailsModal';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import ProductTour from 'components/common/ProductTour/ProductTour';
import CustomCard from 'components/common/CustomCard';
import BackRow from 'components/common/BackRow/BackRow';
import Avatar from 'components/common/Avatar';
import JobsListView from 'components/roboroy/JobsListView';
import { fetchDropdownData } from 'actions/talentSearch/newJob';
import { getAllCourses } from 'actions/candidate/CandidateDetails';
// import LeftNavFilter from 'containers/common/leftNavFilter/leftNavFilter';

import { jobCard } from '../RoboroyCards';

import {
  reRenderContent,
  filterJobData,
  upsertJobBatchActions,
  getJobExport,
  upsertJobShortlistActions,
  cloneJob,
} from 'actions/talentSearch/jobList';
import { getApprovedVacancies } from 'actions/talentSearch/JobApprovals';
import FilterData from '../FilterData';
import {
  Layout,
  PagingInfo,
  Result,
  SearchBox,
  Facets,
  MultiCheckboxFacet,
  SingleSelectFacet,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';

import './JobList.css';
import FacetsBrowse from '../../../components/roboroy/common/FacetsBrowse';
import { getBatchDetails } from 'actions/BatchManagementView';

const track = {
  ROBOROY_DASHBOARD_JOBS_CLICKED: 'ROBOROY_DASHBOARD_JOBS_CLICKED',
};

const Chip = ({ title }) =>
  title ? (
    <div className="bx--tag bx--tag--local  ml-0 mr-2">{title}</div>
  ) : null;

const { TableContainer, Table, TableRow, TableBody, TableCell } = DataTable;

const CreateJobBtn = ({ t }) => (
  <Link
    to="/app/campus/new/job/registration"
    className="bx--btn bx--btn--primary"
    key={`test123`}>
    {t('createJob')}
  </Link>
);

const NoJobMessageBox = ({ search, t, isAdmin, isSuperAdmin }) => (
  <Tile className="mt-2">
    <div className="card-body text-center ">
      <div className="empty-icon">
        <i className="icon icon-3x icon-people" />
      </div>
      <p className="empty-title h5">{t('noJobsPosted')}</p>
      <p className="empty-subtitle">{t('clickToCreateJob')}</p>
      <div className="empty-action">
        <div className="col-xs-12">
          {search && (
            <Link
              to="/app/campus/jobs/open"
              onClick={() => {
                window.location.reload();
              }}
              className="bx--btn bx--btn--tertiary mr-2"
              key={`test123`}>
              Clear Filters
            </Link>
          )}
          {(isAdmin === true || isSuperAdmin === true) && (
            <CreateJobBtn t={t} />
          )}
        </div>
      </div>
    </div>
  </Tile>
);

class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      searchTerm: '',
      sortByAscending: true,
      sortedJobs: [],
      activePage: 1,
      ascDesc: '',
      activeJob: {},
      sortParam: '',
      activeFilter: null,
      itemsPerPage: 10,
      activeColumn: '',
      currentSortProp: null,
      keyword: '',
      selectedTab: 0,
      status: 'open',
      productTourIsOpen: false,
      headersInfo: [{ key: 'job', header: 'Job' }],
      rowsData: [],
      jobSeniority: [],
      jobTerms: [],
      hourTypes: [],
      activeSelectedTab: '',
      selectedSort: 'placeholder-item',
      showSharedJobsTab: false,
      finalCompsArr: [],
      companyName: '',
      finalCoursesArr: [],
      finalBatchArr: []
    };
    this.closeTour = this.closeTour.bind(this);
  }

  componentDidMount() {
    // this.handleDefaultChecklist();
    this.handleFetchBatchList();
    this.renderOnMount();
    this.handleFetchCompanisList();
    const { user: { company: { ctypeId } = {} } = {} } = this.props;
    if (ctypeId && Number(ctypeId) === 2) {
      this.props.getApprovedVacancies();
    }
    // const { finalCompsArr, finalCoursesArr } = this.state;
    this.handleFetchCoursesList();
  }

  componentDidUpdate(prevProps) {
    const { match: { params: prevParams } = {} } = prevProps;
    const { match: { params = null } = {} } = this.props;
    if (prevParams.status === params.status) return;
    else this.setState({
      status: params.status
    }, () => {
      // this.renderOnMount(); 
      // this.handleDefaultChecklist();
    });
  }

  handleDefaultChecklist = () => {
    const { user: { company: { ctypeId } = {} } = {} } = this.props;
    this.props.getBatchDetails().then((res) => {
      const { list } = res;
      this.setState({ finalBatchArr: list }, () => {
        this.props.getBatchDetails().then((res) => {
          const { list: batchList } = res;
          const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
          if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
            let batch = currentBatch.batchName;
            if (parseInt(ctypeId) !== 1) {
              const defaultCheckList = {};
              defaultCheckList["batch"] = [batch];
              this.setState({ defaultCheckList })
            }
          }
        });
      })
    })
  }

  handleFetchBatchList = () => {
    const { user: { company: { ctypeId } = {} } = {} } = this.props;
    this.props.getBatchDetails().then((res) => {
      const { list } = res;
      const batchDropDownList = list.map(one => ({ label: one && one.batchName, value: one && one.batchId }));
      this.setState({ 
        finalBatchArr: list, 
        batchDropDownList 
      },() => {
        // this.props.getBatchDetails().then((res) => {
          const { finalBatchArr: batchList } = this.state || {};
          const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
          if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
            if (parseInt(ctypeId) !== 1) {
              this.setState({ "currentBatch": currentBatch.batchName })
            }
          }
        // });
      })
    })
  }


  handleFetchCompanisList = () => {
    const managedCompData = {
      activePage: 1,
      itemsPerPage: 1000,
      Companymapstatus: ['Accepted'],
    };
    console.log("calling fetch companies list!!!!")
    this.props.fetchAllCompaniesList(managedCompData).then(res => {
      if (res && !res.error) {
        // const { companies } = this.props;
        const { companies } = res || {};
        let finalCompsArr = [];
        if (companies && Array.isArray(companies) && companies.length > 0) {
          finalCompsArr = companies.map(comps => {
            const { _source: { companyId, companyName, displayCompanyName } = {} } = comps || {};
            return { companyId, companyName:(displayCompanyName || companyName)  };
          });
          finalCompsArr.sort((a, b) => {
            if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
              return -1;
            }
            if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
              return 1;
            }
            if (a.companyName.toLowerCase() === b.companyName.toLowerCase()) {
              return 0;
            }
          });
          this.setState({
            finalCompsArr,
          });
        }
      }
    });
  };

  handleFetchCoursesList = () => {
    const { user: { companyId, company: { ctypeId } = {} } = {} } = this.props;
    const coursesData = {
      courseActivePage: 1,
      courseItemsPerPage: 1000,
      schoolId: companyId,
    };
    this.props.getAllCourses(coursesData).then(res => {
      if (res && !res.error) {
        const { courses } = res;
        let finalCoursesArr = [];
        if (courses && Array.isArray(courses) && courses.length > 0) {
          finalCoursesArr = courses.map(course => {
            const { courseId, courseName } = ({} = course || {});
            return { courseId, courseName };
          });
          finalCoursesArr.sort((a, b) => {
            if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) {
              return -1;
            }
            if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) {
              return 1;
            }
            if (a.courseName.toLowerCase() === b.courseName.toLowerCase()) {
              return 0;
            }
          });
          this.setState({
            finalCoursesArr,
          });
        }
      }
    });
  };

  renderOnMount = () => {
    const {
      match: { params: { limit, page, status: paramStatus } = {} } = {},
      location: { search } = {},
      user: {
        companyId,
        company: {
          ctypeId
        } = {}
      } = {}
    } = this.props;
    SetMixPanel();
    // this.props.fetchDropdownData().then(res => {
    //   this.setState({
    //     jobSeniority: res && res.meta && res.meta.jobSeniorities,
    //     jobTerms: res && res.meta && res.meta.terms,
    //     hourTypes: res && res.meta && res.meta.hourTypes,
    //   });
    // });
    if (limit && page && paramStatus) {
      this.setState(
        {
          itemsPerPage: limit,
          activePage: page,
          status: paramStatus,
          sortByAscending: false,
        },
        () => {
          if (this.state.status === 'open') {
            this.setState({
              selectedTab: 0,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'closed') {
            this.setState({
              selectedTab: 1,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'pending') {
            this.setState({
              selectedTab: 2,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'approved') {
            this.setState({
              selectedTab: 3,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'rejected') {
            this.setState({
              selectedTab: 4,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'alljobs') {
            this.setState({
              selectedTab: 2,
              showSharedJobsTab: false,
            });
          }
          else if (this.state.status === 'shared') {
            this.setState({
              selectedTab: 3,
              showSharedJobsTab: false,
            });
          }
          const data = {
            itemsPerPage: this.state.itemsPerPage,
            activePage: this.state.activePage,
            filterOptions: this.state.sortParam,
            searchKey: this.state.keyword,
            status: this.state.status,
          };
          if (search) {
            const renderData = this.handleSearchQuery(search);
            // this.setState({ defaultCheckList: renderData.defaultCheckList })
            const finalData = { ...data, ...renderData };
            this.props.reRenderContent(finalData).then(() => {
              // this.setRows();
              this.handlePathParams();
            });
          } else {
            this.setState({ defaultCheckList: {} })
            this.props.reRenderContent(data).then(() => {
              // this.setRows();
              this.handlePathParams();
            });
          }
        }
      );
    } else {
      this.setState(
        {
          itemsPerPage: 10,
          activePage: 1,
          sortByAscending: false,
          status: paramStatus,
        },
        () => {
          if (this.state.status === 'open') {
            this.setState({
              selectedTab: 0,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'closed') {
            this.setState({
              selectedTab: 1,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'pending') {
            this.setState({
              selectedTab: 2,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'approved') {
            this.setState({
              selectedTab: 3,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'rejected') {
            this.setState({
              selectedTab: 4,
              showSharedJobsTab: false,
            });
          } else if (this.state.status === 'alljobs') {
            this.setState({
              selectedTab: 2,
              showSharedJobsTab: false,
            });
          }
          else if (this.state.status === 'shared') {
            this.setState({
              selectedTab: 3,
              showSharedJobsTab: false,
            });
          }
          const data = {
            itemsPerPage: this.state.itemsPerPage,
            activePage: this.state.activePage,
            filterOptions: this.state.sortParam,
            searchKey: this.state.keyword,
            status: this.state.status,
          };
          if (search) {
            const renderData = this.handleSearchQuery(search);
            // this.setState({ defaultCheckList: renderData.defaultCheckList })
            const { history: { push } = {} } = this.props;
            if (this.state.status !== null) {
              push &&
                push(
                  `/app/campus/jobs/${this.state.status}${search ? search : '/'
                  }`
                );
              // this.handleJobsTabClick(this.state.status);
            }
            const finalData = { ...data, ...renderData };
            this.props.reRenderContent(finalData).then(() => {
              // this.setRows();
            });
          } else {
            if (parseInt(ctypeId) === 2) {
              this.props.getBatchDetails().then((res) => {
                const { list: batchList } = res;
                const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
                if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
                  let batch = currentBatch.batchName;
                  this.setState({ "currentBatch": batch }, () => {
                    const renderData = this.handleSearchQuery(`/?batch=${batch}`);
                    const finalData = { ...data, ...renderData };
                    this.props.reRenderContent(finalData).then(() => {
                      const { history: { push } = {} } = this.props;
                      if (this.state.status !== null) {
                        push &&
                          push(
                            `/app/campus/jobs/${this.state.status}${search ? search : `/?batch=${batch}`
                            }`
                          );
                        // this.handleJobsTabClick(this.state.status);
                      }
                      // this.setRows();
                    });
                  })
                }
              });
            } else {
              this.setState({ defaultCheckList: {} })
              this.props.reRenderContent(data).then(() => {
                const { history: { push } = {} } = this.props;
                if (this.state.status !== null) {
                  push &&
                    push(
                      `/app/campus/jobs/${this.state.status}${search ? search : '/'
                      }`
                    );
                  // this.handleJobsTabClick(this.state.status);
                }
                // this.setRows();
              });
            }
          }
        }
      );
    }
  };

  // componentWillReceiveProps(props) {
  //   this.setRows();
  // }

  handlePaginationChange = data => {
    const { history: { push } = {}, location: { search } = {} } = this.props;
    const { status } = this.state;
    const activePage = data && data.page || 1;
    const itemsPerPage = data && data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      const currData = {
        itemsPerPage: this.state.itemsPerPage,
        activePage: this.state.activePage,
        filterOptions: this.state.sortParam,
        searchKey: this.state.keyword,
        status: this.state.status,
      };
      if (search) {
        const renderData = this.handleSearchQuery(search);
        const finalData = { ...currData, ...renderData };
        this.props.reRenderContent(finalData).then(() => {
          // this.setRows();
        });
      } else {
        this.props.reRenderContent(currData).then(() => {
          // this.setRows();
        });
      }
    });
    if (activePage > 0 && itemsPerPage > 0) {
      push &&
        push(
          `/app/campus/jobs/${status}/page/${activePage}/limit/${itemsPerPage}${search ? search : '/'
          }`
        );
    }
  };

  searchTimer = null;

  // setRows = () => {
  //   const { jobs: { jobs } } = this.props;
  //   const rowsData = (jobs && jobs.map((ele) => {
  //     const {dashboard} = ele;
  //     const RowData = {
  //       id: ele.jobId, openDate: ele.openDate, title: ele && ele.jobname && ele.jobname.nameName,
  //       draft: ele && ele.draft,
  //       function: ele && ele.jobfunction && ele.jobfunction.functionName, seniority: ele && ele.jobseniority && ele.jobseniority.seniorityName,
  //       country: ele.country ? ele.country.countryFull : '',
  //       hourtype: ele && ele.hourtype && ele.hourtype.typeName, term: ele && ele.term && ele.term.termName,
  //       total: (dashboard && dashboard.totalApplications) || 0,
  //       shortlisted: (dashboard && dashboard.numOfShortlistedApplications) || 0,
  //       rejected: (dashboard && dashboard.numOfRejectedApplications) || 0,
  //     };
  //     const oneRow = { id: RowData.id.toString(), job: jobCard(RowData) };
  //     return oneRow;
  //   })) || [];
  //   this.setState({ rowsData });
  // };

  handlePathParams = () => {
    const {
      location: { search } = {},
      match: { params: { limit, page, status: paramStatus } = {} } = {},
      history: { push } = {},
    } = this.props;
    if (limit && page && paramStatus) {
      push &&
        push(
          `/app/campus/jobs/${paramStatus}/page/${page}/limit/${limit}${search ? search : '/'
          }`
        );
    } else {
      push &&
        push(
          `/app/campus/jobs/${this.state.status}/page/${this.state.activePage
          }/limit/${this.state.itemsPerPage}${search ? search : '/'}`
        );
    }
  };

  activateModal = job => {
    this.setState({ activeModal: true });
  };

  searchTimerVal = null;

  handleSearchCompanyName = event => {
    const { finalCompsArr } = this.state;
    if (this.searchTimerVal) {
      clearTimeout(this.searchTimerVal);
    }
    const companyName =
      event.target.value &&
      event.target.value.toString() &&
      event.target.value.toLowerCase() &&
      event.target.value.trim();
    let finalFilteredArr = [];
    if (companyName && companyName !== '' && companyName.length > 0) {
      finalFilteredArr = finalCompsArr.filter(res => {
        const { companyName: propCompName } = res;
        if (
          propCompName &&
          propCompName.toLowerCase() &&
          !propCompName.includes(companyName)
        ) {
          return false;
        }
        return true;
      });
      this.setState(
        {
          finalCompsArr: finalFilteredArr,
          showLoader: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showLoader: false,
            });
          }, 1000);
        }
      );
    } else {
      this.setState(
        {
          showLoader: true,
        },
        () => {
          this.handleFetchCompanisList();
          setTimeout(() => {
            this.setState({
              showLoader: false,
            });
          }, 1000);
        }
      );
    }
  };

  handleSearchSubmit = event => {
    const { location: { search, state } = {} } = this.props;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      const data = {
        itemsPerPage: this.state.itemsPerPage,
        activePage: 1,
        filterOptions: this.state.sortParam,
        searchKey: this.state.keyword,
        status: this.state.status,
      };
      if (search) {
        const renderData = this.handleSearchQuery(search);
        const finalData = { ...data, ...renderData };
        this.searchTimer = setTimeout(
          () => this.props.reRenderContent(finalData),
          1000
        );
      } else {
        this.searchTimer = setTimeout(
          () => this.props.reRenderContent(data),
          1000
        );
      }
    });
  };

  closeTour() {
    this.setState({ productTourIsOpen: false });
  }

  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'open', name: 'Jobs', href: '' },
  ];

  onSortValueChange = value => {
    this.setState(
      {
        selectedSort: value,
      },
      () => {
        const sortVal = value.indexOf('Asc') > 0 ? 'ASC' : 'DESC';
        const propName =
          value.indexOf('Asc') > 0
            ? value.replace('Asc', '')
            : value.replace('Dsc', '');
        const qs = `&filter[limit]=${this.state.itemsPerPage}&filter[skip]=0&searchKey=${this.state.keyword}&status=${this.state.status}&filter[order][0]=${propName}%20${sortVal}`;
        this.props.filterJobData(qs).then(res => {
          this.setState({ activePage: 1 });
        });
      }
    );
  };

  handleJobsTabClick = tabType => {
    const {
      history: { push } = {},
      location: { search, state } = {},
      user: { company: { ctypeId } = {} } = {},
      match: { params: { limit, page, status: paramStatus } = {} } = {},
    } = this.props;
    const { showSharedJobsTab } = this.state;
    let selectedTab = 0;
    let status = 'open';
    if (tabType == 'open' && showSharedJobsTab === false) {
      status = 'open';
      selectedTab = 0;
    } else if (tabType == 'closed' && showSharedJobsTab === false) {
      status = 'closed';
      selectedTab = 1;
    } else if (ctypeId && Number(ctypeId) === 2) {
      if (tabType === 'pending') {
        status = 'pending';
        selectedTab = 2;
      } else if (tabType === 'approved') {
        status = 'approved';
        selectedTab = 3;
      } else if (tabType === 'rejected') {
        status = 'rejected';
        selectedTab = 4;
      }
    } else if (ctypeId && Number(ctypeId) === 1 && tabType === 'alljobs') {
      status = 'alljobs';
      selectedTab = 2;
    }
    else if (ctypeId && Number(ctypeId) === 1 && tabType == 'shared') {
      status = 'shared';
      selectedTab = 3;
    }
    let finalData = {};
    if (limit && page && paramStatus) {
      this.setState(
        {
          itemsPerPage: 10,
          activePage: 1,
          status: paramStatus,
        },
        () => {
          if (search) {
            this.setState({
              itemsPerPage: this.state.itemsPerPage,
              activePage: this.state.activePage,
              status: status,
            });
            const data = {
              itemsPerPage: this.state.itemsPerPage,
              activePage: this.state.activePage,
              status: status,
            };
            const renderData = this.handleSearchQuery(search);
            finalData = { ...data, ...renderData };
            this.props.reRenderContent(finalData).then(() => {
              // this.setRows();
            });
          }
          push &&
            push(
              `/app/campus/jobs/${status}/page/${this.state.activePage}/limit/${this.state.itemsPerPage
              }${search ? search : '/'}`
            );
        }
      );
    } else {
      if (search) {
        const data = {
          itemsPerPage: this.state.itemsPerPage,
          activePage: this.state.activePage,
          status: status,
        };
        this.setState({
          itemsPerPage: this.state.itemsPerPage,
          activePage: this.state.activePage,
          status: status,
        });
        const renderData = this.handleSearchQuery(search);
        finalData = { ...data, ...renderData };
        this.props.reRenderContent(finalData).then(() => {
          // this.setRows();
        });
      }
      push && push(`/app/campus/jobs/${status}${search ? search : '/'}`);
    }
    SetMixPanel(`Jobs List status ${status}`);
    if (!search) {
      this.setState(
        { selectedTab, status, keyword: '', selectedSort: 'placeholder-item' },
        () => {
          const currData = {
            itemsPerPage: this.state.itemsPerPage,
            activePage: 1,
            status: this.state.status,
          };
          this.props.reRenderContent(currData);
        }
      );
    }
  };

  handleSearchQuery = searchString => {
    const search = decodeURIComponent(searchString);
    const searchSplit = search.split('?');
    const resSplt = searchSplit[1].split('&');
    const finalVal = {};
    let defaultCheckList = {};
    let resSpltLength = 0;
    resSplt &&
      Array.isArray(resSplt) &&
      resSplt.length > 0 &&
      resSplt.forEach(res => {
        if (res) {
          const valSpl = res.split('=');
          let valueArr = [];
          if (valSpl[1].includes(',')) {
            valueArr = valSpl[1].split(',');
          } else {
            valueArr = [valSpl[1]];
          }
          defaultCheckList[valSpl[0]] = valueArr;
          finalVal[valSpl[0]] = valSpl[0];
          finalVal[`filterName_${valSpl[0]}`] = valueArr;
          finalVal.defaultCheckList = defaultCheckList;
        }
        resSpltLength++;
        if (resSpltLength === resSplt.length) {
          this.setState({ defaultCheckList: defaultCheckList })
        }
      });
    return finalVal;
  };

  batchActionClickHandler = (actionItems, actionState) => {
    this.setState({
      showBatchActionModal: true,
      selectTemplateModal: true,
    });
    if (actionState === 'list') {
      const actionItemsIds = actionItems.map(({ id = '' }) => Number(id));
      this.getSelectedJobRows(actionItemsIds);
    }
    if(actionState === 'reminder'){
      const actionItemsIds = actionItems.map(({ id = '' }) => Number(id.split('_')[1]));
      const emailData = this.handleCurrentSelectedEmail(actionItemsIds);
    }
  };

  handleSubmitShortListReminder = () => {
    const { jobIds } = this.state;
    const batchAcData = {
      jobIds: jobIds,
    };

    this.dismissBathcActionModal();
    this.props.upsertJobShortlistActions(batchAcData).then(res => {
      if (res && !res.error) {
        this.setState({
          isOpenSendReminderEmailModal: false,
          isOpenPassiveCustRegModal: false,
        })
        this.handlePathParams();
        window.location.reload();
      }
    });
  };

  handleSelectCustomRegModal = (actionItems, actionState) => {
    const actionItemsIds = actionItems.map(({ id = '' }) => Number(id));
    if (actionState == 'reminder') {
      this.setState({
        isOpenPassiveCustRegModal: true,
      });
      this.getSelectedJobRows(actionItemsIds);

    } else if (actionState === 'list') {
      this.setState({
        showBatchActionModal: true,
      });
      this.getSelectedJobRows(actionItemsIds);
    } else if(actionState == 'cloneJobs') {
      this.setState({
        isOpenCloneJobModal: true,
        actionItemsIds
      });
    }
  }

  handleSetCloneJobNotif = (cloneJobNotifText, cloneJobNotifType) => {
    this.setState({ showCloneJobNotif: true, cloneJobNotifText, cloneJobNotifType }, () => {
      setTimeout(() => {
        this.setState({showCloneJobNotif: false, cloneJobNotifText: '', cloneJobNotifType: null})
      }, 8000);
    })
  }

  onCloneJob = () => {
    const { actionItemsIds, selectedBatchVal } = this.state || {};
    const { batchName, batchId } = selectedBatchVal || {};
    if(!selectedBatchVal || !batchName || !batchId){
      this.setState({ selectedBatchValInvalid: true });
      return;
    }
    // [{jobId, batchName, batchId}]
    this.props.cloneJob({jobIds: actionItemsIds, batchName, batchId}).then((res) => {
      if (res && Array.isArray(res) && res.length>0 ) {
        this.handlePaginationChange();
        this.handleSetCloneJobNotif('Job(s) cloned Successfully!', 'success');
      }else{
        this.handleSetCloneJobNotif('Sorry, something went wrong', 'error');
      }
      this.dismissCloneJobModal();
    });
  }

  dismissCloneJobModal = () => {
    // console.log('Close Apply Job Modal');
    this.setState({
      isOpenCloneJobModal: false,
      selectedBatchVal: null,
      actionItemsIds: null,
      selectedBatchValInvalid: false,
    });
  }

  getSelectedJobRows = selected => {
    this.setState({
      jobIds: selected,
    });
  };

  dismissBathcActionModal = () => {
    this.setState({
      showBatchActionModal: false,
      preferred: null,
      open: null,
    });
  };

  handleSubmitBatchActions = () => {
    const { preferred, jobIds, isAutoPilot, open } = this.state;
    const {
      location: { search } = {},
      user: { userId } = {},
      xpaCurrentCompanyId,
    } = this.props;
    const batchAcData = {
      jobIds,
    };
    if (preferred !== null) {
      batchAcData.preferred = preferred;
    }
    if (open !== null) {
      if (open === true) {
        batchAcData.closeDate = null;
        batchAcData.xactionName = 'reopen-job';
      } else {
        batchAcData.reasonForClosure = '';
      }
    }
    this.dismissBathcActionModal();
    this.props.upsertJobBatchActions(batchAcData).then(res => {
      if (res && !res.error) {
        const data = {
          itemsPerPage: this.state.itemsPerPage,
          activePage: this.state.activePage,
          filterOptions: this.state.sortParam,
          searchKey: this.state.keyword,
          status: this.state.status,
        };
        if (search) {
          const renderData = this.handleSearchQuery(search);
          const finalData = { ...data, ...renderData };
          this.props.reRenderContent(finalData).then(() => {
            this.handlePathParams();
          });
        } else {
          this.props.reRenderContent(data).then(() => {
            this.handlePathParams();
          });
        }
      }
    });
  };

  handleChangeRadioButton = e => {
    switch (e) {
      case 'preferred-false':
        this.setState({
          preferred: false,
        });
        break;
      case 'preferred-true':
        this.setState({
          preferred: true,
        });
        break;
      case 'open-true':
        this.setState({
          open: true,
        });
        break;
      case 'open-false':
        this.setState({
          open: false,
        });
        break;
      case 'shortlist-reminder':
        this.setState({
          selectedValue: e,
          isOpenSendReminderEmailModal: true
        });
        break;
      default:
        break;
    }
  };

  handleExportJobs = () => {
    const {
      match: { params: { limit, page, status: paramStatus } = {} } = {},
      location: { search } = {},
      user: {
        companyId,
        company: {
          ctypeId
        } = {}
      } = {}
    } = this.props;
    let finalResp = this.handleSearchQuery(search) 
    finalResp.status = paramStatus
    this.props.getJobExport(finalResp);
  };

  onViewReminderEmail = () => {
    const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
    window.open(`/emailpreview/shortlist-reminder-email`, 'Email Preview', params);
  }

  handleBatchDropdownChange = (e) => {
    const name = e.target.name; 
    const value = JSON.parse(e.target.value);
    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      rowsData,
      headersInfo,
      selectedTab,
      selectedSort,
      jobSeniority,
      hourTypes,
      jobTerms,
      showSharedJobsTab,
      showBatchActionModal,
      finalCompsArr,
      finalCoursesArr,
      finalBatchArr,
      // facets
      defaultCheckList,
      isOpenPassiveCustRegModal,
      isOpenSendReminderEmailModal,
      selectedBatchValInvalid,
      batchDropDownList,
      selectedBatchVal,
    } = this.state;
    // console.log(this.props)
    // console.log(defaultCheckList)
    // console.log(finalCompsArr)
    // console.log(finalCoursesArr)
    // console.log(finalBatchArr)
    const {
      jobs: { count, dashboard },
      recruiters,
      company,
      jobs,
      t,
      user: { isAdmin, isSuperAdmin, company: userCompany } = {},
      history: { push } = {},
      approvedVacancies,
    } = this.props;
    const { ctypeId } = userCompany || {};
    const titles = [
      {
        titleId: 7,
        titleName: 'Approvals',
        status: 'ap',
        values: {
          approvalStatus: [
            { visbName: 'Completed', approvalStatus: 1 },
            { visbName: 'Pending', approvalStatus: 2 },
          ],
        },
        filterKey: 'approvalStatus',
      },
      // {
      //   titleId: 1,
      //   titleName: `${t('expRange')}`,
      //   status: 'js',
      //   values: {
      //     jobSeniority,
      //   },
      //   filterKey: 'seniorityId',
      // },
      // {
      //   titleId: 2,
      //   titleName: `${t('hourType')}`,
      //   status: 'ht',
      //   values: {
      //     hourTypes,
      //   },
      //   filterKey: 'typeId',
      // },
      // {
      //   titleId: 3,
      //   titleName: `${t('term')}`,
      //   status: 'jt',
      //   values: {
      //     jobTerms,
      //   },
      //   filterKey: 'termId',
      // },
      // {
      //   titleId: 4,
      //   titleName: `${t('visibility')}`,
      //   status: 'vs',
      //   values: {
      //     jobVisbility: [
      //       { visbName: `${t('publicJobs')}`, isPrivate: 1 },
      //       { visbName: `${t('privateJobs')}`, isPrivate: 2 },
      //     ],
      //   },
      //   filterKey: 'isPrivate',
      // },
      // {
      //   titleId: 5,
      //   titleName: `${t('autoPilot')}`,
      //   status: 'ap',
      //   values: {
      //     jobAutoPilot: [
      //       { autoPilName: `${t('on')}`, isAutoPilot: 1 },
      //       { autoPilName: `${t('off')}`, isAutoPilot: 2 },
      //     ],
      //   },
      //   filterKey: 'isAutoPilot',
      // },
      // {
      //   titleId: 6,
      //   titleName: `${t('aiSourcing')}`,
      //   status: 'ais',
      //   values: {
      //     jobSourced: [
      //       { sourcedName: `${t('on')}`, aiSourced: 1 },
      //       { sourcedName: `${t('off')}`, aiSourced: 2 },
      //     ],
      //   },
      //   filterKey: 'aiSourced',
      // },
      {
        titleId: 9,
        titleName: 'Courses',
        status: 'cop',
        values: {
          finalCoursesArr,
        },
        filterKey: 'courseIds',
      },
    ];

    if (ctypeId && Number(ctypeId) === 2) {
      titles.push({
        titleId: 10,
        titleName: 'Batch',
        status: 'b',
        values: {
          finalBatchArr,
        },
        filterKey: 'batch',
      });
    }

    if (ctypeId && Number(ctypeId) === 2) {
      titles.push({
        titleId: 8,
        titleName: 'Companies',
        status: 'cp',
        values: {
          finalCompsArr,
        },
        filterKey: 'companyIds',
      });
    }
    // const com=()=>{
    //   const promises = finalCompsArr.map(company => {
    //     return {
    //       key:company.companyName,
    //       isSelected:false
    //     }
    //   })

    //   return Promise.all(promises)
    // }

    const facets = {
      "Approvals": [
        { key: 'completed', id: '1', isSelected: false, filterKey: "approvalStatus", status: "ap" },
        { key: 'pending', id: '2', isSelected: false, filterKey: "approvalStatus", status: "ap" },
      ],
      "Courses": finalCoursesArr.map(course => {
        return {
          key: course.courseName,
          id: course.courseId.toString(),
          isSelected: false,
          status: "cop",
          filterKey: "courseIds",
        }
      }),
    }
    if (ctypeId && Number(ctypeId) === 2) {
      facets["Batch"] = finalBatchArr.map(batch => {
        return {
          key: batch.batchName,
          isSelected: false,
          id: batch.batchName,
          status: "b",
          filterKey: "batch"
        }
      })
    }
    if (ctypeId && Number(ctypeId) === 2) {
      facets["Companies"] = finalCompsArr.map(company => {
        return {
          key: company.companyName,
          isSelected: false,
          id: company.companyId.toString(),
          status: "cp",
          filterKey: "companyIds"
        }
      })
    }
    const keys = facets && Object.keys(facets);
    const tabContent = [];
    tabContent.push(
      {
        label: `${t('open')} ${t('jobs')} (${(dashboard && dashboard.open) ||
          0})`,
        value: '',
        onClick: () => {
          this.handleJobsTabClick('open');
        },
      },
      {
        label: `${t('closed')} ${t('jobs')} (${(dashboard &&
          dashboard.closed) ||
          0})`,
        value: '',
        selected: true,
        onClick: () => {
          this.handleJobsTabClick('closed');
        },
      }
    );
    if (ctypeId && Number(ctypeId) === 2) {
      tabContent.push(
        {
          label: `${t('invitesPendingTab')} ${t('jobs')} (${(dashboard &&
            dashboard.pending) ||
            0})`,
          value: '',
          onClick: () => {
            this.handleJobsTabClick('pending');
          },
        },
        {
          label: `Approved ${t('jobs')} (${(dashboard && dashboard.approved) ||
            0})`,
          value: '',
          selected: true,
          onClick: () => {
            this.handleJobsTabClick('approved');
          },

        },
        {
          label: `${t('rejected')} ${t('jobs')} (${(dashboard &&
            dashboard.rejected) ||
            0})`,
          value: '',
          selected: true,
          onClick: () => {
            this.handleJobsTabClick('rejected');
          },
        }
      );
    }
    if (ctypeId && Number(ctypeId) === 1) {
      tabContent.push({
        label: `All ${t('jobs')} (${(dashboard &&
          dashboard.open &&
          dashboard.closed &&
          Number(dashboard.open) + Number(dashboard.closed)) ||
          0})`,
        value: '',
        onClick: () => {
          this.handleJobsTabClick('alljobs');
        },
      });
      tabContent.push({
        label: `${'shared'} ${t('jobs')} (${(dashboard && dashboard.shared) ||
          0})`,
        value: '',
        onClick: () => {
          this.handleJobsTabClick('shared');
        },
      });
    }
    // if (showSharedJobsTab === true) {
    //   tabContent.push({
    //     label: `${'shared'} ${t('jobs')} (${(dashboard && dashboard.shared) ||
    //       0})`,
    //     value: '',
    //     selected: showSharedJobsTab,
    //     onClick: () => {
    //       this.handleJobsTabClick('shared');
    //     },
    //   });
    // }
    const columnWithFilter = [
      {
        title: `${t('job')} ${t('name')}`,
        className: 'text-left',
        name: 'job',
        propName: 'job',
      },
    ];

    // if (ctypeId && Number(ctypeId) === 2) {
    //   columnWithFilter.push({
    //     title: `Approval ${t('status')}`,
    //     className: 'text-left',
    //     name: 'job',
    //     propName: 'job',
    //   });
    // } else {
    //   columnWithFilter.push({
    //     title: `Created`,
    //     className: 'text-left',
    //     name: 'job',
    //     propName: 'job',
    //   });
    // }

    // columnWithFilter.push({
    //   title: `${t('summary')}`,
    //   className: 'text-center',
    //   name: 'summary',
    //   propName: 'summary',
    // });

    const guideSteps = [
      {
        selector: '.unknown-class-meow-meow',
        content:
          'This is the page to browse through jobs you have already posted.',
      },
      {
        selector: '.joblist__content .bx--tabs',
        content:
          'Click this section to see list of jobs that are open and closed.',
      },
      {
        selector: '.joblist__content .bx--data-table-v2 ',
        content: 'This section provides the list of jobs.',
      },
      {
        selector: '.joblist__content .bx--data-table-v2 thead',
        content:
          'Sort jobs by any of the available columns by clicking on them.',
      },
      {
        selector:
          '.joblist__content .bx--table-toolbar .bx--toolbar-search-container',
        content: 'Filter job list by name search',
      },
      {
        selector:
          '.joblist__content .bx--table-toolbar .bx--toolbar-content .bx--btn--primary',
        content: 'Create a new job by clicking on this button',
      },
    ];
    const otherActionButtons = [];
    if (
      (ctypeId && Number(ctypeId) === 2 && isAdmin === true) ||
      isSuperAdmin === true
    ) {
      otherActionButtons.push(
        <Button
          className="mr-2"
          kind="tertiary"
          disabled={count < 1}
          onClick={() => this.handleExportJobs()}>
          Export Jobs
        </Button>
      );
    }
    otherActionButtons.push(
      <Button
        kind="primary"
        onClick={() => {
          push && push('/app/campus/new/job/registration');
        }}>
        {t('createJob')}
      </Button>
    )
    let approvesList;

    const batchActionButtons = [
      {
        label: 'Clone Job(s)',
        key: 'cloneJobs',
      }
    ]
    if(ctypeId && ctypeId == 2){
      batchActionButtons.push({
        label: 'Send Reminder',
        key: 'reminder'
      },
      {
        label: 'Batch Actions',
        key: 'list',
      });
    }

    return (
      <div className="jobList">
        <div className="joblist__container xp-c-container xp-c-background--light ">
          <BackRow
            paths={this.paths}
            showGuidedTour={false}
            handleGuidedTourClick={() =>
              this.setState({ productTourIsOpen: true })
            }
          />
          <ProductTour
            onRequestClose={this.closeTour}
            isOpen={this.state.productTourIsOpen}
            steps={guideSteps}
          />
          {ctypeId && Number(ctypeId) === 2 && (
            <div className="mt-2 mb-2">
              <div className="h6 mb-2">Vacancies approved per faculty.</div>
              {approvedVacancies &&
                Array.isArray(approvedVacancies) &&
                approvedVacancies.length > 0 &&
                approvedVacancies.map(res => {
                  const { count: facultyCount, faculty_name } = res || {};
                  return faculty_name ? (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-lg-2 text-capitalize">
                        {faculty_name}
                      </div>
                      <div className="bx--tag bx--tag--blue">
                        {facultyCount}
                      </div>
                    </div>
                  ) : (
                    []
                  );
                })}
              <hr />
            </div>
          )}
          <h4 className="bx--type-gamma font-weight-bold text-capitalize mb-0">
            {t('jobs')}
          </h4>
          {recruiters &&
            recruiters !== null &&
            recruiters !== undefined &&
            recruiters !== {} && (
              <Tile className="mt-2">
                <div className="bx--row p-1">
                  <div className="bx--col-lg-1">
                    <Avatar
                      user={{
                        firstname: recruiters.firstname,
                        lastname: recruiters.lastname,
                        picture: recruiters.picture ? recruiters.picture : null,
                      }}
                      classNameInfo="rounded-circle"
                      size="60"
                    />
                  </div>
                  <div>
                    <div className="text-dark font-weight-bold bx--type-gamma text-capitalize">
                      <Link
                        to={{
                          pathname: `/app/campus/users/${recruiters.userId}`,
                          search: `companyId=${recruiters.company &&
                            recruiters.company.companyId}&recruiterId=${recruiters.userId
                            }`,
                        }}>
                        {recruiters.firstname} {recruiters.lastname}
                      </Link>
                    </div>
                    <div className="">
                      <span className="text-capitalize">
                        {recruiters.company && recruiters.company.companyName}
                      </span>
                      <span>
                        {recruiters.company && recruiters.company.website
                          ? `(${recruiters.company.website})`
                          : null}
                      </span>
                    </div>
                    <div className="">
                      <svg width="16" height="9" viewBox="0 0 16 11">
                        <path d="M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
                      </svg>
                      <span className="text-dark bx--modal-header__label ml-2 mt-0">
                        <a href={`mailto:${recruiters.email}`}>
                          {recruiters.email}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </Tile>
            )}
          {company &&
            company !== null &&
            company !== {} &&
            !recruiters &&
            company.companyName && (
              <Tile className="mt-2 p-1 pl-3">
                <div className="p-1">
                  <div className="bx--row p-1">
                    <div>
                      <Avatar
                        user={{
                          firstname: company.companyName,
                          lastname: '',
                          picture:
                            company &&
                              company.companyinfo &&
                              company.companyinfo.logo
                              ? company.companyinfo.logo
                              : null,
                        }}
                        isCompanyLogo
                        classNameInfo="rounded-circle"
                        size="40"
                      />
                    </div>
                    <div className="card-title h5 mt-2 ml-2 d-inline-block text-center text-md-left font-weight-bold text-capitalize mb-0">
                      {company.companyName}
                    </div>
                  </div>
                  <div className="mt-1 mb-1 text-dark text-justify">
                    {company.description ? company.description : null}
                  </div>
                  <div>
                    {company.website ? (
                      <a target="_blank" href={`https://${company.website}`}>
                        {company.website}
                      </a>
                    ) : null}
                  </div>
                </div>
              </Tile>
            )}
          <div className="joblist-layout">
            <Layout
              sideContent={
                // <p>sideContent</p>
                facets !== undefined && facets !== null &&
                (
                  (Number(ctypeId) === 1)
                  ||
                  (facets["Companies"] !== undefined && facets["Courses"] !== undefined && facets["Companies"].length > 0 && facets["Courses"].length > 0)
                ) &&
                defaultCheckList != null && defaultCheckList != undefined &&
                <FilterData
                  defaultCheckList={defaultCheckList}
                  itemsPerPage={this.state.itemsPerPage}
                  activePage={this.state.activePage}
                  filterOptions={this.state.sortParam}
                  searchKey={this.state.keyword}
                  titles={titles}
                  handleSearchCompanyName={this.handleSearchCompanyName}
                  status={this.state.status}
                  showLoader={this.state.showLoader}
                  // setRows={this.setRows}
                  history={this.props.history}
                  params={this.props.match && this.props.match.params}
                  search={
                    this.props.location && this.props.location.search
                  }
                  handlePaginationChange={this.handlePaginationChange}
                  filterJobs={true}
                  facets={facets}
                  keys={keys}
                />
                // <FacetsBrowse
                //   handleClearFilters={this.handleClearFilters}
                //   handleChangeCheckBoxVals={this.handleChangeCheckBoxVals}
                //   handleSubmitFilterVals={this.handleSubmitFilterVals}
                //   values={this.state}
                //   facets={facets}
                //   keys={keys}
                //   defaultCheckList={this.state.defaultCheckList}
                //   showAlteredFacetsLabels
                //   activeMoreModal={this.activeMoreModal}
                //   handleSubmitCheckBox={this.handleSubmitCheckBox}
                // // dismissMoreModal={this.dismissMoreModal}
                // // isOpenMoreModal={isOpenMoreModal}
                //   // mainVals={mainVals}
                // />
              }

              bodyContent={
                <div className="joblist__content pt-0 mt-0">
                  <GenericTabs selected={selectedTab} className="tabs-width-fix" tabContent={tabContent} />
                  {this.state.showCloneJobNotif && (
                    <InlineNotification
                      lowContrast
                      className="mt-0"
                      title={this.state.cloneJobNotifText}
                      subtitle=""
                      iconDescription=""
                      kind={this.state.cloneJobNotifType}
                    />
                  )}
                  <div>
                    <PagingInfo
                      end={Math.min(
                        Number(count),
                        Number(this.state.itemsPerPage) *
                        Number(this.state.activePage)
                      )}
                      start={
                        1 +
                        Number(this.state.activePage - 1) *
                        Number(this.state.itemsPerPage)
                      }
                      totalResults={Number(count)}
                    />
                  </div>
                  <div>
                    {/* <LeftNavFilter url={url} className="" setSelected={this.setSelected} /> */}
                    <div className="bx--col-lg p-0">
                      <div className="d-lg-flex d-md-flex justify-content-between">
                        <div>
                          {/* <Search
                            light
                            type="text"
                            style={{ maxWidth: 500 }}
                            className="p-0 col-lg-6"
                            labelText="Search job"
                            closeButtonLabelText=""
                            placeHolderText={`${t('search')} ${t('jobs')}`}
                            onChange={this.handleSearchSubmit}
                            id="search pool"
                            value={this.state.keyword}
                          /> */}
                        </div>
                        <div className="d-lg-flex d-md-flex">
                          <div className="mr-2 mt-2 mt-lg-0 mt-md-0">
                            {/* <FilterData
                            itemsPerPage={this.state.itemsPerPage}
                            activePage={this.state.activePage}
                            filterOptions={this.state.sortParam}
                            searchKey={this.state.keyword}
                            titles={titles}
                            handleSearchCompanyName={this.handleSearchCompanyName}
                            status={this.state.status}
                            showLoader={this.state.showLoader}
                            // setRows={this.setRows}
                            history={this.props.history}
                            params={this.props.match && this.props.match.params}
                            search={
                              this.props.location && this.props.location.search
                            }
                            handlePaginationChange={this.handlePaginationChange}
                            filterJobs={true}
                          /> */}
                          </div>
                          <div>
                            <Select
                              light
                              className="bx--col-xs-12 p-0"
                              hideLabel
                              invalidText="A valid value is required"
                              onChange={e => this.onSortValueChange(e.target.value)}
                              id="select-1"
                              defaultValue="placeholder-item"
                              value={selectedSort}>
                              <SelectItem
                                value="placeholder-item"
                                disabled
                                hidden
                                text={t('sortBy')}
                              />
                              <SelectItem
                                value="jobname.nameNameAsc"
                                text={`${t('title')}-${t('ascending')} `}
                              />
                              <SelectItem
                                value="jobname.nameNameDsc"
                                text={`${t('title')}-${t('descending')} `}
                              />
                              <SelectItem
                                value="dashboard.totalApplicationsAsc"
                                text={`${t('applications')}-${t('ascending')} `}
                              />
                              <SelectItem
                                value="dashboard.totalApplicationsDsc"
                                text={`${t('applications')}-${t('descending')} `}
                              />
                              <SelectItem
                                value="dashboard.numOfShortlistedApplicationsAsc"
                                text={`${t('shortlisted')}-${t('ascending')} `}
                              />
                              <SelectItem
                                value="dashboard.numOfShortlistedApplicationsDsc"
                                text={`${t('shortlisted')}-${t('descending')} `}
                              />
                              <SelectItem
                                value="dashboard.numOfRejectedApplicationsAsc"
                                text={`${t('rejected')}-${t('ascending')} `}
                              />
                              <SelectItem
                                value="dashboard.numOfRejectedApplicationsDsc"
                                text={`${t('rejected')}-${t('descending')} `}
                              />
                              <SelectItem
                                value="openDateAsc"
                                text={`${t('postDate')}-${t('ascending')} `}
                              />
                              <SelectItem
                                value="openDateDsc"
                                text={`${t('postDate')}-${t('descending')} `}
                              />
                            </Select>
                          </div>
                          {/* <div>
                            {(isAdmin === true || isSuperAdmin === true) && (
                              <div className="newJobBtn ml-lg-2 ml-md-2 mb-2 mb-lg-0 mb-md-0 bx--col-xs-12 ml-0 mt-2 bx--col-lg p-0 mt-lg-0 text-left">
                                <Button
                                  kind="primary"
                                  onClick={() => {
                                    push && push('/app/campus/new/job/registration');
                                  }}>
                                  {t('createJob')}
                                </Button>
                              </div>
                            )}
                          </div> */}
                        </div>
                      </div>
                      {/* {jobs &&
                      jobs &&
                      jobs.jobs &&
                      Array.isArray(jobs && jobs.jobs) &&
                      jobs &&
                      jobs.jobs.length > 0 && ( */}
                      <div>
                        <GenericDataTable
                          className="content-alignment"
                          otherActions={[otherActionButtons]}
                          headers={columnWithFilter.map((data, index) => ({
                            key: `header_${index}`,
                            header: data.title,
                            className: data.className,
                            colpropName: data.propName,
                          }))}
                          rows={
                            jobs &&
                              jobs.jobs &&
                              Array.isArray(jobs && jobs.jobs) &&
                              jobs &&
                              jobs.jobs.length > 0
                              ? jobs &&
                              jobs.jobs.map(res => {
                                let {
                                  jobId,
                                  openDate,
                                  vacancy,
                                  jobname: { nameName } = {},
                                  company: { companyName, displayCompanyName } = {},
                                  user: { firstname, lastname },
                                  institution,
                                  approvedList,
                                  approvedListFormatted=[],
                                  country: { countryFull } = {},
                                  jobfunction: { functionName } = {},
                                  dashboard: {
                                    totalApplications,
                                    numOfShortlistedApplications,
                                    numOfRejectedApplications,
                                    hiredApplns,
                                  } = {},
                                  preferred,
                                  draft,
                                  approvedVacancies
                                } = res;
                                // let isPrimaryCourseArr=[];
                                // let isCourseApprovedArr = [];
                                // let isCourseRejectedArr = [];
                                // let isCoursePendingArr = [];
                                // if(approvedListFormatted && approvedListFormatted.length>0){
                                //   for(let i=0;i<approvedListFormatted.length;i++){
                                //     let {jobCourseMappings=[]}=approvedListFormatted[i];
                                //     for(let j=0;j<jobCourseMappings.length;j++){
                                //       let res=jobCourseMappings[j];
                                //       if(res && res.isApproved===true){
                                //         isCourseApprovedArr.push(Number(res.courseId))
                                //       }
                                //       if(res && res.isApproved===false){
                                //         isCourseRejectedArr.push(Number(res.courseId))
                                //       }
                                //       if(res && res.isApproved===null){
                                //         isCoursePendingArr.push(Number(res.courseId))
                                //       }
                                //       if(res && res.isPrimaryCourseAdmin===true){
                                //         isPrimaryCourseArr.push(Number(res.courseId))
                                //       }
                                //     }
                                //   }
                                // }

                                // if (approvedList && approvedList.length > 0) {
                                //   approvedList.forEach((res) => {
                                //     if (res && res.isApproved === true) {
                                //       isCourseApprovedArr.push(Number(res.courseId))
                                //     }
                                //     if (res && res.isApproved === false) {
                                //       isCourseRejectedArr.push(Number(res.courseId))
                                //     }
                                //     if (res && res.isApproved === null) {
                                //       isCoursePendingArr.push(Number(res.courseId))
                                //     }
                                //     if (res && res.isPrimaryCourseAdmin === true) {
                                //       isPrimaryCourseArr.push(Number(res.courseId))
                                //     }
                                //   })
                                // }
                                let doesContain = [];
                                let al;
                                if (approvedList && approvedList.length > 0) {
                                  al = approvedList.filter((all) => {
                                    if (doesContain.includes(all.facultyId)) {
                                      return false;
                                    } else {
                                      doesContain.push(all.facultyId)
                                      return true;
                                    }
                                  })
                                }
                                approvedList = al;

                                return {
                                  isExpanded: true,
                                  id: `${jobId}`,
                                  header_0: (
                                    <div className="mt-3 mb-3">
                                      <div className="d-flex small ml-2">
                                        <div className="text-dark">
                                          {t('requisitionId')}: {jobId}
                                        </div>
                                        <div className="ml-3">
                                          {moment(openDate).fromNow()}
                                        </div>
                                      </div>
                                      <a
                                        href={`/app/campus/job/${jobId}`}
                                        // onClick={() => {
                                        //   push &&
                                        //     push(`/app/campus/job/${jobId}`);
                                        // }}
                                        className="xpa-name-overflow text-capitalize xpa-link bx--type-epsilon ml-2" style={{ width: '150px' }}>
                                        {nameName}
                                      </a>
                                      <div className="d-flex">
                                        <div className="bx--tag bx--tag--purple">
                                          {functionName}
                                        </div>
                                        <div className="d-flex align-items-center bx--tag bx--tag--blue">
                                          <div>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              style={{ width: 16, height: 16 }}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="feather feather-map-pin">
                                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                                              <circle cx="12" cy="10" r="3" />
                                            </svg>
                                          </div>
                                          <div className="ml-1 mt-1">
                                            {countryFull}
                                          </div>
                                        </div>
                                      </div>
                                      {ctypeId && Number(ctypeId) === 2 ? (
                                        <div className="bx--tag ml-2 bx--tag--cool-gray">
                                          Requested Vacancies: {vacancy}
                                        </div>
                                      ) : (
                                        <span />
                                      )}
                                      {ctypeId && Number(ctypeId) === 2 && (
                                        <div className="d-flex ml-2 mt-1 mb-1">
                                          {preferred === true ? (
                                            <div className="bx--tag bx--tag--green ml-0">
                                              <span
                                                title="Preferred"
                                                className="approved-color">
                                                <i className="fas fa-check-circle" />
                                              </span>
                                            &nbsp;Visibility
                                            </div>
                                          ) : (
                                            <div className="bx--tag bx--tag--red ml-0">
                                              <span
                                                title="Not-Preferred"
                                                className="rejected-color">
                                                <i className="fa fa-times-circle" />
                                              </span>
                                            &nbsp;Visibility
                                            </div>
                                          )}
                                        </div>
                                      )}{' '}
                                      <div className="ml-2">
                                        <span className="mt-1  text-dark text-uppercase xpa-name-overflow xpa-name-width-fix">
                                          {!ctypeId == 2 ||
                                            (ctypeId && ctypeId == 2)
                                            ? displayCompanyName
                                            : institution &&
                                            institution.companyName}
                                        </span>
                                      </div>
                                      <div className="ml-2">
                                        <span className="" style={{ fontWeight: 600 }}>{`Recruiter - ${firstname} ${lastname}`}</span>
                                      </div>
                                    </div>
                                  ),
                                  header_1: (
                                    <div>
                                      <div>
                                        {ctypeId && Number(ctypeId) === 2 && approvedList &&
                                          Array.isArray(approvedList) &&
                                          approvedList.length > 0 ? (
                                          <Fragment>
                                            <div className="ml-2 mb-2 ml-lg-0 ml-md-0 text-left">
                                              <div>
                                                {/*<div className="bx--tag bx--tag--cyan">
                                                  Approved Vacancies:{' '}
                                                  {approvedVacancies ? approvedVacancies : 0}
                                                </div>*/}
                                                {
                                                  approvedListFormatted.map((faculty,indx) => {
                                                    return <div className="mb-2">
                                                    <div className="d-flex mb-1">
                                                      <div className="text-capitalize mt-0 pl-2">
                                                        { faculty && faculty.facultyName }
                                                        <div className="bx--tag bx--tag--cyan">
                                                          Approved Vacancies:{' '}
                                                          {faculty.facultyApprovedVacancies ? faculty.facultyApprovedVacancies : 0}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {ctypeId && Number(ctypeId) === 2 && (
                                                      <div>
                                                        <hr className="mt-1 mb-2" />
                                                        {faculty.jobCourseMappings &&
                                                          Array.isArray(
                                                            faculty.jobCourseMappings
                                                          ) &&
                                                          faculty.jobCourseMappings.length > 0 &&
                                                          faculty.jobCourseMappings.map(crs => {
                                                            const {
                                                              course:{
                                                                courses:{
                                                                  courseName
                                                                }={},
                                                              }={},
                                                              isApproved,
                                                              isPrimaryCourseAdmin
                                                            } = crs || {};
                                                            return (
                                                              <div className="small text-capitalize text-dark mt-1">
                                                                { courseName != null && isApproved===true ? (
                                                                  <span
                                                                    title="Approved"
                                                                    className="approved-color">
                                                                    <i className="fas fa-check-circle" />
                                                                  </span>
                                                                ) : ''
                                                                }
                                                                { courseName != null && isApproved===null ? (
                                                                  <span
                                                                    title="Pending Approval"
                                                                    className="pending-color">
                                                                    <i
                                                                      className="fa fa-clock-o"
                                                                      aria-hidden="true"
                                                                    />
                                                                  </span>
                                                                ) : ''
                                                                }
                                                                { courseName != null && isApproved===false ? (
                                                                  <span
                                                                    title="Rejected"
                                                                    className="rejected-color">
                                                                    <i
                                                                      className="fa fa-times-circle"
                                                                      aria-hidden="true"
                                                                    />
                                                                  </span>
                                                                ) : ''
                                                                }
                                                                {' '}
                                                                {courseName}
                                                                {' '}
                                                                {courseName != null && isPrimaryCourseAdmin===true ? (
                                                                  <span
                                                                    title="Approved"
                                                                    className="approved-color">
                                                                    <i className="fas fa-check-circle" /> Primary
                                                                  </span>
                                                                ) : ''}
                                                              </div>
                                                            );
                                                          })}
                                                      </div>
                                                    )}
                                                  </div>
                                                  })
                                                }

                                                {/* {approvedList.map((approvedLis,indx) => {
                                                  return <div className="mb-2">
                                                    <div className="d-flex mb-1"> */}
                                                      {/*<div>
                                                      { approvedList[0].isApproved === true && (
                                                        <span
                                                          title="Approved"
                                                          className="approved-color">
                                                          <i className="fas fa-check-circle" />
                                                        </span>
                                                      )}
                                                      { approvedList[0].isApproved === false && (
                                                        <span
                                                          title="Rejected"
                                                          className="rejected-color">
                                                          <i
                                                            className="fa fa-times-circle"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      )}
                                                      { approvedList[0].isApproved === null && (
                                                        <span
                                                          title="Pending Approval"
                                                          className="pending-color">
                                                          <i
                                                            className="fa fa-clock-o"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      )}
                                                    </div>*/}
                                                      {/* {
                                                        approvedLis && approvedLis.length > 0 && approvedLis && approvedLis.courses[0] ? (
                                                          <div className="text-capitalize mt-0 pl-2">
                                                            { approvedLis && approvedLis.courses[0]
                                                              && approvedLis.courses[0].faculty.facultyName}
                                                          </div>
                                                        ) : (
                                                          <div className="text-capitalize mt-0 pl-2">
                                                            { approvedLis && approvedLis.faculty && approvedLis.faculty.facultyName }
                                                          </div>
                                                        )
                                                      }
                                                    </div>
                                                    {ctypeId && Number(ctypeId) === 2 && (
                                                      <div>
                                                        <hr className="mt-1 mb-2" />
                                                        {approvedLis.courses &&
                                                          Array.isArray(
                                                            approvedLis.courses
                                                          ) &&
                                                          approvedLis.courses.length > 0 &&
                                                          approvedLis.courses.map(crs => {
                                                            const {
                                                              faculty: {
                                                                facultyName
                                                              },
                                                              courses: {
                                                                courseName,
                                                                courseId
                                                              } = {},
                                                            } = crs || {};
                                                            return (
                                                              <div className="small text-capitalize text-dark mt-1">
                                                                { courseName != null && isCourseApprovedArr.includes(Number(courseId)) ? (
                                                                  <span
                                                                    title="Approved"
                                                                    className="approved-color">
                                                                    <i className="fas fa-check-circle" />
                                                                  </span>
                                                                ) : ''
                                                                }
                                                                { courseName != null && isCoursePendingArr.includes(Number(courseId)) ? (
                                                                  <span
                                                                    title="Pending Approval"
                                                                    className="pending-color">
                                                                    <i
                                                                      className="fa fa-clock-o"
                                                                      aria-hidden="true"
                                                                    />
                                                                  </span>
                                                                ) : ''
                                                                }
                                                                { courseName != null && isCourseRejectedArr.includes(Number(courseId)) ? (
                                                                  <span
                                                                    title="Rejected"
                                                                    className="rejected-color">
                                                                    <i
                                                                      className="fa fa-times-circle"
                                                                      aria-hidden="true"
                                                                    />
                                                                  </span>
                                                                ) : ''
                                                                }
                                                                {' '}
                                                                {courseName}
                                                                {' '}
                                                                {courseName != null && isPrimaryCourseArr.includes(Number(courseId)) ? (
                                                                  <span
                                                                    title="Approved"
                                                                    className="approved-color">
                                                                    <i className="fas fa-check-circle" /> Primary
                                                                  </span>
                                                                ) : ''}
                                                              </div>
                                                            );
                                                          })}
                                                      </div>
                                                    )}
                                                  </div>
                                                })
                                                } */}
                                              </div>
                                            </div>
                                          </Fragment>
                                        ) : (
                                          // <div>{moment(openDate).fromNow()}</div>
                                          null
                                        )}
                                      </div>
                                      <div className="d-flex justify-content-around">
                                        <div className="text-center joblist__cnt--total">
                                          <div
                                            className="mr-1 bx--type-beta font-weight-light xpa-link"
                                            onClick={() => {
                                              push &&
                                                push(
                                                  `/app/campus/jobs/${jobId}/applied`
                                                );
                                            }}>
                                            {totalApplications}
                                          </div>
                                          <div className="text-dark">
                                            Applications
                                          </div>
                                        </div>
                                        <div className="text-center  joblist__cnt--short">
                                          <div
                                            className="mr-1 bx--type-beta font-weight-light xpa-link"
                                            onClick={() => {
                                              push &&
                                                push(
                                                  `/app/campus/jobs/${jobId}/shortlisted?applicationStatus=shortlisted`
                                                );
                                            }}>
                                            {numOfShortlistedApplications}
                                          </div>
                                          <div className="text-dark">
                                            Shortlisted
                                      </div>
                                        </div>
                                        <div className="text-center  joblist__cnt--rejected">
                                          <div
                                            className="mr-1 bx--type-beta font-weight-light xpa-link"
                                            onClick={() => {
                                              push &&
                                                push(
                                                  `/app/campus/jobs/${jobId}/rejected`
                                                );
                                            }}>
                                            {numOfRejectedApplications}
                                          </div>
                                          <div className="text-dark">Rejected</div>
                                        </div>
                                        <div className="text-center  joblist__cnt--hired">
                                          <div
                                            className="mr-1 bx--type-beta font-weight-light xpa-link"
                                            onClick={() => {
                                              push &&
                                                push(
                                                  `/app/campus/jobs/${jobId}/applied?hiringStatus=is-hired&`
                                                );
                                            }}>
                                            {hiredApplns}
                                          </div>
                                          <div className="text-dark">Hired</div>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                };
                              })
                              : []
                          }
                          selectable
                          // ={ctypeId && Number(ctypeId) === 2}
                          searchable
                          onSearchInputChange={this.handleSearchSubmit}
                          expandable={false}
                          batchActionClick={this.handleSelectCustomRegModal}
                          batchActions={batchActionButtons}
                        />
                        {jobs &&
                          jobs.jobs &&
                          Array.isArray(jobs.jobs) &&
                          jobs.jobs.length > 0 && (
                            <div className="">
                              <Pagination
                                onChange={this.handlePaginationChange}
                                pageSizes={[10, 20, 30, 40, 50]}
                                page={this.state.activePage}
                                totalItems={count}
                              />
                            </div>
                          )}
                        {jobs &&
                          jobs.jobs &&
                          Array.isArray(jobs.jobs) &&
                          jobs.jobs.length <= 0 && (
                            <NoJobMessageBox
                              t={t}
                              search={this.props.location && this.props.location.search}
                              isAdmin={isAdmin}
                              isSuperAdmin={isSuperAdmin}
                            />
                          )}

                      </div>
                      {/* )} */}
                      {/* {
                  jobs && jobs.jobs.length > 0
                    && (
                      <div className="mt-2 pb-2">
                      <JobsListView push={this.props.history && this.props.history.push} jobs={jobs && jobs.jobs} jobLoading={false} />
                      </div>
                    )
                } */}
                    </div>
                  </div>
                </div>

              }
            />
          </div>




          {/* <div className="joblist__content pt-0 mt-0">
            <GenericTabs selected={selectedTab} className="tabs-width-fix" tabContent={tabContent} />
            <div> */}
          {/*dont-uncomment <LeftNavFilter url={url} className="" setSelected={this.setSelected} /> */}
          {/* <div className="bx--col-lg p-0">
                <div className="d-lg-flex d-md-flex justify-content-between">
                  <div> */}
          {/*dont-uncomment <Search
                      light
                      type="text"
                      style={{ maxWidth: 500 }}
                      className="p-0 col-lg-6"
                      labelText="Search job"
                      closeButtonLabelText=""
                      placeHolderText={`${t('search')} ${t('jobs')}`}
                      onChange={this.handleSearchSubmit}
                      id="search pool"
                      value={this.state.keyword}
                    /> */}
          {/* </div>
                  <div className="d-lg-flex d-md-flex">
                    <div className="mr-2 mt-2 mt-lg-0 mt-md-0">
                      <FilterData
                        itemsPerPage={this.state.itemsPerPage}
                        activePage={this.state.activePage}
                        filterOptions={this.state.sortParam}
                        searchKey={this.state.keyword}
                        titles={titles}
                        handleSearchCompanyName={this.handleSearchCompanyName}
                        status={this.state.status}
                        showLoader={this.state.showLoader}
                        // setRows={this.setRows}
                        history={this.props.history}
                        params={this.props.match && this.props.match.params}
                        search={
                          this.props.location && this.props.location.search
                        }
                        handlePaginationChange={this.handlePaginationChange}
                        filterJobs={true}
                      />
                    </div>
                    <div>
                      <Select
                        light
                        className="bx--col-xs-12 p-0"
                        hideLabel
                        invalidText="A valid value is required"
                        onChange={e => this.onSortValueChange(e.target.value)}
                        id="select-1"
                        defaultValue="placeholder-item"
                        value={selectedSort}>
                        <SelectItem
                          value="placeholder-item"
                          disabled
                          hidden
                          text={t('sortBy')}
                        />
                        <SelectItem
                          value="jobname.nameNameAsc"
                          text={`${t('title')}-${t('ascending')} `}
                        />
                        <SelectItem
                          value="jobname.nameNameDsc"
                          text={`${t('title')}-${t('descending')} `}
                        />
                        <SelectItem
                          value="dashboard.totalApplicationsAsc"
                          text={`${t('applications')}-${t('ascending')} `}
                        />
                        <SelectItem
                          value="dashboard.totalApplicationsDsc"
                          text={`${t('applications')}-${t('descending')} `}
                        />
                        <SelectItem
                          value="dashboard.numOfShortlistedApplicationsAsc"
                          text={`${t('shortlisted')}-${t('ascending')} `}
                        />
                        <SelectItem
                          value="dashboard.numOfShortlistedApplicationsDsc"
                          text={`${t('shortlisted')}-${t('descending')} `}
                        />
                        <SelectItem
                          value="dashboard.numOfRejectedApplicationsAsc"
                          text={`${t('rejected')}-${t('ascending')} `}
                        />
                        <SelectItem
                          value="dashboard.numOfRejectedApplicationsDsc"
                          text={`${t('rejected')}-${t('descending')} `}
                        />
                        <SelectItem
                          value="openDateAsc"
                          text={`${t('postDate')}-${t('ascending')} `}
                        />
                        <SelectItem
                          value="openDateDsc"
                          text={`${t('postDate')}-${t('descending')} `}
                        />
                      </Select>
                    </div> */}
          {/* dont-uncomment <div>
                      {(isAdmin === true || isSuperAdmin === true) && (
                        <div className="newJobBtn ml-lg-2 ml-md-2 mb-2 mb-lg-0 mb-md-0 bx--col-xs-12 ml-0 mt-2 bx--col-lg p-0 mt-lg-0 text-left">
                          <Button
                            kind="primary"
                            onClick={() => {
                              push && push('/app/campus/new/job/registration');
                            }}>
                            {t('createJob')}
                          </Button>
                        </div>
                      )}
                    </div> */}
          {/* </div>
                </div>
                {jobs &&
                  jobs &&
                  jobs.jobs &&
                  Array.isArray(jobs && jobs.jobs) &&
                  jobs &&
                  jobs.jobs.length > 0 && (
                    <GenericDataTable
                      className="content-alignment"
                      otherActions={[otherActionButtons]}
                      headers={columnWithFilter.map((data, index) => ({
                        key: `header_${index}`,
                        header: data.title,
                        className: data.className,
                        colpropName: data.propName,
                      }))}
                      rows={
                        jobs &&
                          jobs.jobs &&
                          Array.isArray(jobs && jobs.jobs) &&
                          jobs &&
                          jobs.jobs.length > 0
                          ? jobs &&
                          jobs.jobs.map(res => {
                            const {
                              jobId,
                              openDate,
                              vacancy,
                              jobname: { nameName } = {},
                              company: { companyName } = {},
                              user: { firstname, lastname },
                              institution,
                              approvedList,
                              country: { countryFull } = {},
                              jobfunction: { functionName } = {},
                              dashboard: {
                                totalApplications,
                                numOfShortlistedApplications,
                                numOfRejectedApplications,
                                hiredApplns,
                              } = {},
                              preferred,
                              draft,
                              approvedVacancies
                            } = res;
                            let isPrimaryCourseId;
                            let isCourseApprovedArr = [];
                            let isCourseRejectedArr = [];
                            let isCoursePendingArr = [];
                            if (approvedList && approvedList.length > 0) {
                              approvedList.forEach((res) => {
                                if (res && res.isApproved === true) {
                                  isCourseApprovedArr.push(Number(res.courseId))
                                }
                                if (res && res.isApproved === false) {
                                  isCourseRejectedArr.push(Number(res.courseId))
                                }
                                if (res && res.isApproved === null) {
                                  isCoursePendingArr.push(Number(res.courseId))
                                }
                                if (res && res.isPrimaryCourseAdmin === true) {
                                  isPrimaryCourseId = res.courseId
                                }
                              })
                            }
                            return {
                              isExpanded: true,
                              id: `${jobId}`,
                              header_0: (
                                <div>
                                  <div className="d-flex small ml-2">
                                    <div className="text-dark">
                                      {t('requisitionId')}: {jobId}
                                    </div>
                                    <div className="ml-3">
                                      {moment(openDate).fromNow()}
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => {
                                      push &&
                                        push(`/app/campus/job/${jobId}`);
                                    }}
                                    className="xpa-name-overflow text-capitalize xpa-link bx--type-epsilon ml-2" style={{ width: '150px' }}>
                                    {nameName}
                                  </div>
                                  <div className="d-flex">
                                    <div className="bx--tag bx--tag--purple">
                                      {functionName}
                                    </div>
                                    <div className="d-flex align-items-center bx--tag bx--tag--blue">
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{ width: 16, height: 16 }}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-map-pin">
                                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                                          <circle cx="12" cy="10" r="3" />
                                        </svg>
                                      </div>
                                      <div className="ml-1 mt-1">
                                        {countryFull}
                                      </div>
                                    </div>
                                  </div>
                                  {ctypeId && Number(ctypeId) === 2 ? (
                                    <div className="bx--tag ml-2 bx--tag--cool-gray">
                                      Requested Vacancies: {vacancy}
                                    </div>
                                  ) : (
                                      <span />
                                    )}
                                  {ctypeId && Number(ctypeId) === 2 && (
                                    <div className="d-flex ml-2 mt-1 mb-1">
                                      {preferred === true ? (
                                        <div className="bx--tag bx--tag--green ml-0">
                                          <span
                                            title="Preferred"
                                            className="approved-color">
                                            <i className="fas fa-check-circle" />
                                          </span>
                                            &nbsp;Visibility
                                        </div>
                                      ) : (
                                          <div className="bx--tag bx--tag--red ml-0">
                                            <span
                                              title="Not-Preferred"
                                              className="rejected-color">
                                              <i className="fa fa-times-circle" />
                                            </span>
                                            &nbsp;Visibility
                                          </div>
                                        )}
                                    </div>
                                  )}{' '}
                                  <div className="ml-2">
                                    <span className="mt-1  text-dark text-uppercase xpa-name-overflow xpa-name-width-fix">
                                      {!ctypeId == 2 ||
                                        (ctypeId && ctypeId == 2)
                                        ? companyName
                                        : institution &&
                                        institution.companyName}
                                    </span>
                                  </div>
                                  <div className="ml-2">
                                    <span className="" style={{ fontWeight: 600 }}>{`Recruiter - ${firstname} ${lastname}`}</span>
                                  </div>
                                </div>
                              ),
                              header_1: (
                                <div>
                                  {ctypeId && Number(ctypeId) === 2 && approvedList &&
                                    Array.isArray(approvedList) &&
                                    approvedList.length > 0 ? (
                                      <Fragment>
                                        <div className="ml-2 ml-lg-0 ml-md-0">
                                          <div>
                                            <div className="d-flex align-items-center mb-1"> */}
          {/* dont-uncomment <div>
                                                { approvedList[0].isApproved === true && (
                                                  <span
                                                    title="Approved"
                                                    className="approved-color">
                                                    <i className="fas fa-check-circle" />
                                                  </span>
                                                )}
                                                { approvedList[0].isApproved === false && (
                                                  <span
                                                    title="Rejected"
                                                    className="rejected-color">
                                                    <i
                                                      className="fa fa-times-circle"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                )}
                                                { approvedList[0].isApproved === null && (
                                                  <span
                                                    title="Pending Approval"
                                                    className="pending-color">
                                                    <i
                                                      className="fa fa-clock-o"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                )}
                                              </div>*/}
          {/* {
                                                approvedList[0] && approvedList[0].length > 0 && approvedList[0] && approvedList[0].courses[0] ? (
                                                  <div className="text-capitalize mt-0 pl-2">
                                                    { approvedList[0] && approvedList[0].courses[0]
                                                      && approvedList[0].courses[0].faculty.facultyName}
                                                  </div>
                                                ) : (
                                                    <div className="text-capitalize mt-0 pl-2">
                                                      { approvedList[0].faculty.facultyName}
                                                    </div>
                                                  )
                                              }
                                            </div>
                                            <div className="bx--tag bx--tag--cyan">
                                              Approved Vacancies:{' '}
                                              {approvedVacancies ? approvedVacancies : 0}
                                            </div>
                                            {ctypeId &&
                                              Number(ctypeId) === 2 && (
                                                <div>
                                                  <hr className="mt-1 mb-2" />
                                                  {approvedList[0].courses &&
                                                    Array.isArray(
                                                      approvedList[0].courses
                                                    ) &&
                                                    approvedList[0].courses.length > 0 &&
                                                    approvedList[0].courses.map(crs => {
                                                      const {
                                                        faculty: {
                                                          facultyName
                                                        },
                                                        courses: {
                                                          courseName,
                                                          courseId
                                                        } = {},
                                                      } = crs || {};
                                                      return (
                                                        <div className="small text-capitalize text-dark mt-1">
                                                          { courseName != null && isCourseApprovedArr.includes(Number(courseId)) ? (
                                                            <span
                                                              title="Approved"
                                                              className="approved-color">
                                                              <i className="fas fa-check-circle" />
                                                            </span>
                                                          ) : ''
                                                          }
                                                          { courseName != null && isCoursePendingArr.includes(Number(courseId)) ? (
                                                            <span
                                                              title="Pending Approval"
                                                              className="pending-color">
                                                              <i
                                                                className="fa fa-clock-o"
                                                                aria-hidden="true"
                                                              />
                                                            </span>
                                                          ) : ''
                                                          }
                                                          { courseName != null && isCourseRejectedArr.includes(Number(courseId)) ? (
                                                            <span
                                                              title="Rejected"
                                                              className="rejected-color">
                                                              <i
                                                                className="fa fa-times-circle"
                                                                aria-hidden="true"
                                                              />
                                                            </span>
                                                          ) : ''
                                                          }
                                                          {' '}
                                                          {courseName}
                                                          {' '}
                                                          {courseName != null && isPrimaryCourseId == courseId ? (
                                                            <span
                                                              title="Approved"
                                                              className="approved-color">
                                                              <i className="fas fa-check-circle" /> Primary
                                                            </span>
                                                          ) : ''}
                                                        </div>
                                                      );
                                                    })}
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <div>{moment(openDate).fromNow()}</div>
                                    )}
                                </div>
                              ),
                              header_2: (
                                <div className="d-flex justify-content-around">
                                  <div className="text-center joblist__cnt--total">
                                    <div
                                      className="mr-1 bx--type-beta font-weight-light xpa-link"
                                      onClick={() => {
                                        push &&
                                          push(
                                            `/app/campus/jobs/${jobId}/applied`
                                          );
                                      }}>
                                      {totalApplications}
                                    </div>
                                    <div className="text-dark">
                                      Applications
                                      </div>
                                  </div>
                                  <div className="text-center  joblist__cnt--short">
                                    <div
                                      className="mr-1 bx--type-beta font-weight-light xpa-link"
                                      onClick={() => {
                                        push &&
                                          push(
                                            `/app/campus/jobs/${jobId}/shortlisted?applicationStatus=shortlisted`
                                          );
                                      }}>
                                      {numOfShortlistedApplications}
                                    </div>
                                    <div className="text-dark">
                                      Shortlisted
                                      </div>
                                  </div>
                                  <div className="text-center  joblist__cnt--rejected">
                                    <div
                                      className="mr-1 bx--type-beta font-weight-light xpa-link"
                                      onClick={() => {
                                        push &&
                                          push(
                                            `/app/campus/jobs/${jobId}/rejected`
                                          );
                                      }}>
                                      {numOfRejectedApplications}
                                    </div>
                                    <div className="text-dark">Rejected</div>
                                  </div>
                                  <div className="text-center  joblist__cnt--hired">
                                    <div
                                      className="mr-1 bx--type-beta font-weight-light xpa-link"
                                      onClick={() => {
                                        push &&
                                          push(
                                            `/app/campus/jobs/${jobId}/applied?hiringStatus=is-hired&`
                                          );
                                      }}>
                                      {hiredApplns}
                                    </div>
                                    <div className="text-dark">Hired</div>
                                  </div>
                                </div>
                              ),
                            };
                          })
                          : []
                      }
                      selectable={ctypeId && Number(ctypeId) === 2}
                      searchable
                      onSearchInputChange={this.handleSearchSubmit}
                      expandable={false}
                      batchActionClick={this.batchActionClickHandler}
                      batchActions={[
                        {
                          label: 'Batch Actions',
                          key: 'list',
                        },
                      ]}
                    />
                  )} */}
          {/* dont-uncomment {
                  jobs && jobs.jobs.length > 0
                    && (
                      <div className="mt-2 pb-2">
                      <JobsListView push={this.props.history && this.props.history.push} jobs={jobs && jobs.jobs} jobLoading={false} />
                      </div>
                    )
                } */}
          {/* {jobs &&
                  jobs.jobs &&
                  Array.isArray(jobs.jobs) &&
                  jobs.jobs.length <= 0 && (
                    <NoJobMessageBox
                      t={t}
                      search={this.props.location && this.props.location.search}
                      isAdmin={isAdmin}
                      isSuperAdmin={isSuperAdmin}
                    />
                  )}
                {jobs &&
                  jobs.jobs &&
                  Array.isArray(jobs.jobs) &&
                  jobs.jobs.length > 0 && (
                    <div className="">
                      <Pagination
                        onChange={this.handlePaginationChange}
                        pageSizes={[10, 20, 30, 40, 50]}
                        page={this.state.activePage}
                        totalItems={count}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div> */}
        </div>

        <Modal
          onRequestClose={() => { this.setState({ isOpenPassiveCustRegModal: false }); }}
          open={isOpenPassiveCustRegModal}
          passiveModal
          modalLabel="Select Templates"
          modalHeading={t('select')}
        >
          <div className="row ml-1">
            <RadioButtonGroup onChange={e => {
              this.handleChangeRadioButton(e);
            }}
              defaultSelected={this.state.selectedValue}
              valueSelected={this.state.selectedValue}
            >
              <RadioButton
                name="templates"
                value="shortlist-reminder"
                labelText="Reminder to Shortlist Candidates"
              />
            </RadioButtonGroup>
          </div>
        </Modal>
        <Modal
          onRequestSubmit={this.handleSubmitShortListReminder}
          onRequestClose={() => {
            this.setState({
              isOpenSendReminderEmailModal: false,
            })
          }}
          open={isOpenSendReminderEmailModal}
          modalLabel="Send Shortlist Reminder Email"
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading="Send Mail"
        >

          <div>
            Are you sure you want to send shortlist reminder email to selected job employers?
          </div>
          <span className="pt-0 pb-1 text-dark d-block">
            Clicking on <strong>submit</strong> will send an email to
            the employers.&nbsp;
            <span className="xpa-link" onClick={this.onViewReminderEmail}>
              Click here
            </span>{' '}
            to view content of the email.
          </span>
     
        </Modal>

        <Modal
          onRequestSubmit={this.handleSubmitBatchActions}
          onRequestClose={this.dismissBathcActionModal}
          open={showBatchActionModal}
          modalLabel="Action"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          modalHeading="Batch Actions">
          <div>
            <div className="mb-2 text-dark">
              For the jobs that you've selected, please choose the properties to
              be set.
            </div>
            <div className="mb-2 bx--type-zeta">Job visibility to students</div>
            <div className="d-flex">
              <div className="col-md-3">
                <RadioButton
                  name="preferred"
                  value="preferred-true"
                  id="radio-2"
                  checked={this.state.preferred === true}
                  onChange={e => {
                    this.handleChangeRadioButton('preferred-true');
                  }}
                  disabled={false}
                  labelText="Yes"
                />
              </div>
              <div className="col-md-3">
                <RadioButton
                  name="preferred"
                  value="preferred-false"
                  id="radio-1"
                  checked={this.state.preferred === false}
                  disabled={false}
                  onChange={e => {
                    this.handleChangeRadioButton('preferred-false');
                  }}
                  labelText="No"
                />
              </div>
            </div>
            {/* <div className="mb-2 bx--type-zeta">Job Status</div>
            <div className="d-flex">
              <div className="col-md-3">
                <RadioButton
                  name="open"
                  value="open-true"
                  id="radio-5"
                  disabled={false}
                  checked={this.state.open == true}
                  onChange={e => {
                    this.handleChangeRadioButton('open-true');
                  }}
                  labelText="Open"
                />
              </div>
              <div className="col-md-3">
                <RadioButton
                  name="open"
                  value="open-false"
                  id="radio-6"
                  checked={this.state.open == false}
                  onChange={e => {
                    this.handleChangeRadioButton('open-false');
                  }}
                  disabled={false}
                  labelText="Closed"
                />
              </div>
            </div> */}
          </div>
        </Modal>
        <Modal
          onRequestSubmit={this.onCloneJob}
          onRequestClose={this.dismissCloneJobModal}
          className="some-class"
          open={this.state.isOpenCloneJobModal}
          modalLabel="Action"
          primaryButtonText="Clone"
          secondaryButtonText="Cancel"
          modalHeading="Clone Job"
        >
          <p className="bx--modal-content__text">
            {/* <strong className="text-dark"> */}
            {/* This job was previously created for AY2020 Semester 2 (Sep 2020 – Feb 2021). Proceed to clone this for AY2021 Semester 1 (Mar – Aug 2021)? */}
              {/* This job was created previously.
              Proceed to clone this for {currentBatchName}? */}
                      {/*Do you want to create a copy of the existing job posting?*/}
            {/* </strong> */}
            <div>
              <Select
                name="selectedBatchVal"
                hideLabel
                id="select-batch"
                invalid={selectedBatchValInvalid}
                invalidText='Please select batch'
                value={selectedBatchVal ? JSON.stringify(selectedBatchVal) : 'select'}
                onChange={e => this.handleBatchDropdownChange(e)}>
                <SelectItem text="Select" value='select'/>
                {batchDropDownList &&
                  batchDropDownList.map(b => (
                    <SelectItem
                      key={b.value}
                      text={`${b.label}`}
                      value={JSON.stringify({batchName: b.label, batchId: b.value})}
                    />
                  ))}
              </Select>
              {/* {
                selectedBatchValInvalid === true && (
                  <div className="bx--form-requirement mb-2">
                    Please select batch
                  </div>
                )
              } */}
            </div>
            <div>
              <small className="pt-1 text-dark d-block">
                Job(s) will be cloned to selected batch.
              </small>
            </div>
          </p>
        </Modal>

       
        <JDProcessOverlay
          show={this.props && this.props.loading}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.jobs.loading,
  error: state.jobs.error,
  jobs: state.jobs,
  recruiters: state.jobs.recruiters,
  company: state.jobs.company,
  companies: state.BrowseCompanies.companies,
  approvedVacancies: state.JobApprovals.approvedVacancies,
});

const mapDispatchToProps = {
  reRenderContent,
  filterJobData,
  fetchDropdownData,
  upsertJobBatchActions,
  fetchAllCompaniesList,
  getApprovedVacancies,
  getAllCourses,
  getJobExport,
  getBatchDetails,
  upsertJobShortlistActions,
  cloneJob,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(JobList));
