import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_GET_FINLAND_PROFILE,
  REQUEST_UPDATE_FINLAND_PROFILE,
  REQUEST_CREATE_FINLAND_PROFILE,
  REQUEST_DELETE_FINLAND_PROFILE,
  REQUEST_FINLAND_PROFILE_SUBMIT,
  REQUEST_UPDATE_PERSONSKILL,
  REQUEST_ADD_TO_TALENTPOOL,
} from '../actionTypes';
import { deleteApiCache } from 'utils/helpers';

const getFinlandProfile = profileId => (dispatch) => {
  const url = `${baseUrl}/finlandprofiles/${profileId}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_FINLAND_PROFILE,
  };
  return callApi(params);
};

const createFinlandProfile = data => (dispatch) => {
  const url = `${baseUrl}/finlandprofiles`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_FINLAND_PROFILE,
  };
  return callApi(params);
}
const updateFinlandProfile = info => (dispatch) => {
  const {
    finlandProfileId, data,
  } = info;
  const url = `${baseUrl}/finlandprofiles/${finlandProfileId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_FINLAND_PROFILE,
  };
  return callApi(params);
};

const deleteFinlandProfile = profileId => (dispatch) => {
  const url = `${baseUrl}/finlandprofiles/${profileId}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_FINLAND_PROFILE,
  };
  return callApi(params);
};

// const managePreferenceRanking = info => (dispatch) => {
//   const { type, rankId = '', data } = info;
//   const url = `${baseUrl}/workpreferencerankings/${rankId}`;
//   let callData =  {};
//   switch (type) {
//     case 'create': return callApi({
//       headers: {
//         'content-type': 'application/json',
//       },
//       dispatch,
//       method: 'POST',
//       url,
//       body: JSON.stringify(data),
//       actionType: REQUEST_POST_PREFERENCE_RANKS,
//     });
//     case 'update': callData = makeCall({
//       url, type, data, REQUEST_UPDATE_PREFERENCE_RANKS,
//     }); break;
//     case 'delete': callData = makeCall({
//       url, type, data, REQUEST_DELETE_PREFERENCE_RANKS,
//     }); break;
//     default: break;
//   }
//   return callApi(callData);
// };

// const manageWorkPreference = (info) => {
//   const { type, preferenceId = '', data } = info;
//   const url = `${baseUrl}/finlandworkpreferences/${preferenceId}`;
//   let callData =  {};
//   switch (type) {
//     case 'create': return callApi({
//       headers: {
//         'content-type': 'application/json',
//       },
//       dispatch,
//       method: 'POST',
//       url,
//       body: JSON.stringify(data),
//       actionType: REQUEST_POST_WORK_PREFERENCE,
//     });makeCall({
//       url, type, data, ,
//     }); break;
//     case 'update': callData =  makeCall({
//       url, type, data, REQUEST_UPDATE_WORK_PREFERENCE,
//     }); break;
//     case 'delete': callData =  makeCall({
//       url, type, data, REQUEST_DELETE_WORK_PREFERENCE,
//     }); break;
//     default: break;
//   }
//   return callApi(callData);
// };

// const managePersonHobbies = (info) => {
//   const { type, hobbyId = '', data } = info;
//   const url = `${baseUrl}/personhobbies/${hobbyId}`;
//   let callData =  {};
//   switch (type) {
//     case 'create': callData =  makeCall({
//       url, type, data, REQUEST_POST_PERSONHOBBIES,
//     }); break;
//     case 'update': callData =  makeCall({
//       url, type, data, REQUEST_UPDATE_PERSONHOBBIES,
//     }); break;
//     case 'delete': callData =  makeCall({
//       url, type, data, REQUEST_DELETE_PERSONHOBBIES,
//     }); break;
//     default: break;
//   }
//   return callApi(callData);
// };

// const managePersonActivities = (info) => {
//   const { type, activityId = '', data } = info;
//   const url = `${baseUrl}/personactivites/${activityId}`;
//   let callData =  {};
//   console.log(info, url);
//   switch (type) {
//     case 'create': callData =  makeCall({
//       url, type, data, REQUEST_POST_PERSONACTIVITIES,
//     }); break;
//     case 'update': callData =  makeCall({
//       url, type, data, REQUEST_UPDATE_PERSONACTIVITIES,
//     }); break;
//     case 'delete': callData =  makeCall({
//       url, type, data, REQUEST_DELETE_PERSONACTIVITIES,
//     }); break;
//     default: break;
//   }
//   return callApi(callData);
// };

const getFinlandProfileSpecificInfo = data => (dispatch) => {
  const {
    profileId, infoType, infoAction, isProfileSpecific = false,
  } = data;
  let url = `${baseUrl}/${infoType}`;
  if (isProfileSpecific !== null && isProfileSpecific) url = `${url}&${profileId}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: infoAction,
  };
  return callApi(params);
};

const createFinlandProfileSpecificInfo = data => (dispatch) => {
  const {
    profileId, infoType, infoData, infoAction, isProfileSpecific = false,
  } = data;
  let url = `${baseUrl}/${infoType}`;
  if (isProfileSpecific !== null && isProfileSpecific) url = `${url}&${profileId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(infoData),
    actionType: infoAction,
  };
  return callApi(params);
};

const updateFinlandProfileSpecificInfo = data => (dispatch) => {
  const {
    infoAction, infoType, infoTypeId, infoData,
  } = data;
  const url = `${baseUrl}/${infoType}/${infoTypeId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(infoData),
    actionType: infoAction,
  };
  return callApi(params);
};

const deleteFinlandProfileSpecificInfo = data => (dispatch) => {
  const {
    profileId, infoType, infoTypeId, infoAction, isProfileSpecific = false,
  } = data;
  let url = `${baseUrl}/${infoType}/${infoTypeId}`;
  if (isProfileSpecific !== null && isProfileSpecific) url = `${url}&${profileId}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: infoAction,
  };
  return callApi(params);
};

const submitProfile = profileIdArr => (dispatch) => {
  const url = `${baseUrl}/profiles/fi/scores`;
  const data = {
    profile_id: profileIdArr,
    mode_details: {
      loyalty: {
        version: 'v1',
        tag: 'businessfinland',
      },
      performance: {
        version: 'v1',
        tag: 'businessfinland',
      },
    },
  };
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_FINLAND_PROFILE_SUBMIT,
  };
  return callApi(params);
};

const updatePersonSkill = data => (dispatch) => {
  const { profileId, skillName, rating, personSkillId } = data;
  const url = `${baseUrl}/profiles/${profileId}/skill/${personSkillId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify({ skillName, rating }),
    actionType: REQUEST_UPDATE_PERSONSKILL,
  };
  deleteApiCache('xpa-api-profiles');
  return callApi(params);

};

const addToTalentPool = profileId => (dispatch) => {
  let url = `${baseUrl}/profile-base/bulkAddToTalentPool`;
  const data = [{
    profileId,
    sharinghash: 'workinfinland',
  }];
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_ADD_TO_TALENTPOOL,
  };
  return callApi(params);
};

export {
  getFinlandProfile,
  updateFinlandProfile,
  deleteFinlandProfile,
  createFinlandProfile,
  createFinlandProfileSpecificInfo,
  updateFinlandProfileSpecificInfo,
  deleteFinlandProfileSpecificInfo,
  getFinlandProfileSpecificInfo,
  submitProfile,
  updatePersonSkill,
  addToTalentPool,
};
