import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveFunnel } from '@nivo/funnel';

const NivoGraphs = (props) => {
    const {
        title,
        subtitle,
        finalNivoData = [],
        finalNivoKeys = [],
        groupMode = 'stacked',
        enableLabel = false,
        options,
        className,
        margin,
        height = 400,
        gridXValues = null,
        min,
        max,
        colors = "purple",
        type = "bar",
        legendBottom = "",
        axisTop = null,
        axisRight = null,
        axisBottom = null,
        axisLeft = null,
        enableGridX = false,
        enableGridY = false,
        markers = [],
        indexBy = "key",
        translateX = 0,
        translateY = 0,
        id = "",
        itemWidth = 120,
        labelTextColor = "#ffffff",
        layout,
        beforeSeparatorLength=0,
        afterSeparatorLength=0,
        afterSeparatorOffset=0,
        currentPartSizeExtension=0,
        currentBorderWidth=0,
        motionConfig="wobbly",
        labelFormat=d => Math.abs(d.value),
        tooltip=d => (<div>{`${d.id} - ${d.indexValue}: `}<strong>{Math.abs(d.value)}</strong></div>),
        labelSkipWidth=null
    } = props || {};

    return (
        <div>
            <div>
                <div
                    style={{
                        height: height
                    }}>
                    <div
                        className={
                            className ? className : 'bx--type-epsilon mt-2'
                        }>
                        {title}
                    </div>
                    <div
                        className='mb-2 mt-1' style={{ lineHeight: 1.25 }}>
                        {subtitle}
                    </div>
                    {type === "bar" &&
                        <ResponsiveBar
                            data={finalNivoData}
                            keys={finalNivoKeys}
                            indexBy={indexBy}
                            margin={margin}
                            padding={0.3}
                            layout={layout}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={colors}
                            groupMode={groupMode}
                            markers={markers}
                            tooltipFormat={d => Math.abs(d)}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={axisTop}
                            axisRight={axisRight}
                            axisBottom={axisBottom}
                            axisLeft={axisLeft}
                            minValue={min}
                            maxValue={max}
                            enableGridY={enableGridY}
                            enableGridX={enableGridX}
                            labelSkipWidth={12}
                            labelTextColor={labelTextColor}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            tooltip={tooltip}
                            label={labelFormat}
                            labelSkipWidth={labelSkipWidth}
                            // labelFormat= {d => {console.log(d)}}
                            legends={[
                                {
                                    anchor: 'bottom-left',
                                    direction: 'row',
                                    symbolSize: 10,
                                    translateY: translateY,
                                    translateX: translateX,
                                    itemCount: 4,
                                    itemWidth: itemWidth,
                                    itemHeight: 12,
                                    itemsSpacing: 14,
                                    itemDirection: 'left-to-right',
                                },
                            ]}
                        />
                    }

                    {
                        type === "line" &&
                        <ResponsiveLine
                            data={[
                                {
                                    id: id,
                                    data: finalNivoData
                                }
                            ]}
                            xScale={{
                                type: 'time',
                                format: '%d/%m/%Y',
                                useUTC: false,
                                // precision: 'day',
                            }}
                            xFormat="time:%d/%m/%Y"
                            yScale={{
                                type: 'linear',
                                stacked: false,
                            }}
                            enableGridX={enableGridX}
                            enableGridY={enableGridY}
                            axisRight={axisRight}
                            axisTop={axisTop}
                            axisLeft={axisLeft}
                            axisBottom={axisBottom}
                            curve="natural"
                            enablePoints={false}
                            enablePointLabel={false}
                            pointSize={16}
                            pointBorderWidth={1}
                            pointBorderColor={{
                                from: 'color',
                                modifiers: [['darker', 0.3]],
                            }}
                            useMesh={true}
                            enableSlices={false}
                            margin={margin}
                            legends={[
                                {
                                    anchor: 'bottom-left',
                                    direction: 'row',
                                    symbolSize: 10,
                                    translateY: translateY,
                                    translateX: translateX,
                                    itemCount: 4,
                                    itemWidth: 120,
                                    itemHeight: 12,
                                    itemsSpacing: 14,
                                    itemDirection: 'left-to-right',
                                },
                            ]}
                        />
                    }

                    {type === "funnel" &&
                        <ResponsiveFunnel
                            data={finalNivoData}
                            margin={margin}
                            colors={colors}
                            borderWidth={20}
                            labelColor={labelTextColor}
                            beforeSeparatorLength={beforeSeparatorLength}
                            afterSeparatorLength={afterSeparatorLength}
                            afterSeparatorOffset={afterSeparatorOffset}
                            currentPartSizeExtension={currentPartSizeExtension}
                            currentBorderWidth={currentBorderWidth}
                            motionConfig={motionConfig}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default NivoGraphs;