import {
  REQUEST_CANDIDATE_PRIVACY_CONSENT,
  REQUEST_CANDIDATE_PRIVACY_JOB_CONSENT,
} from 'actions/actionTypes';
import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';

const upsertUserInfoDetails = data => (dispatch) => {
  const { userId } = data;
  const url = `${baseUrl}/userinfos/${userId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CANDIDATE_PRIVACY_CONSENT,
  };
  return callApi(params);
};

const handleCandidateJobPrivacyConsent = data => (dispatch) => {
  const { profileId } = data;
  const url = `${baseUrl}/profiles/${profileId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CANDIDATE_PRIVACY_JOB_CONSENT,
  };
  return callApi(params);
};

export {
  upsertUserInfoDetails,
  handleCandidateJobPrivacyConsent,
};
