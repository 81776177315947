import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PublicHeader from 'containers/common/publicHeader';
import { withTranslation } from 'react-i18next';
import PublicFooter from '../PublicFooter';

import './Login.css';
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLanguageModal: false,
    };
  }

  handleRedirectToLogin = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/');
    }
  }

  handleLangugaeModal = () => {
    const { showLanguageModal } = this.state;
    this.setState({
      showLanguageModal: !showLanguageModal,
    });
  }

  handleRedirectToAdminPage = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/public/RP/login');
    }
  }

  render() {
    const { t } = this.props;
    const { showLanguageModal } = this.state;
    const studentImage = `https://acadpubassets.blob.core.windows.net/xpub/${window.location.host}/student-img.jpg`;

    const compImage = `https://acadpubassets.blob.core.windows.net/xpub/${window.location.host}/company-img.jpg`;

    

    return (
      <div>
        <div className="all-login login-page col-12 bx--row m-0 p-0">
          <PublicHeader
          
            handleRedirectToLogin={this.handleRedirectToLogin}
            showLanguageModal={showLanguageModal}
            handleLangugaeModal={this.handleLangugaeModal}
            showAdminLogin
            handleRedirectToAdminPage={this.handleRedirectToAdminPage}
          />
          <div style={{ backgroundImage: `url(${studentImage})`, backgroundPosition: 'top' }} className="background-imgs col-lg-6 col-sm-12 col col-md-6 m-0 p-0">
            <div className="student-mask background-masking d-flex justify-content-between align-items-center flex-column">
              <div className="text-uppercase h1 font-weight-bold mt-2 pt-5 main_header_color">
                student
            </div>
              <div className=" lgbtn_rgbtn_pos">
                <div className="">
                  <div className="st_btn_main lgbtn_rgbtn">
                    <Link className="bx--btn bx--btn--primary button-color bx--btn--sm mt-3 mr-4 w-100" to="/public/student/login">{t('login')}</Link>
                  </div >
                  {/* register buttons */}
                  <div className="st_btn_main">
                    <Link className="bx--btn bx--btn--primary button-color bx--btn--sm mt-2 mr-3 w-100" to="/public/campus/register">{t('register')}</Link>
                  </div>
                    
                  <div className="text-center mt-2">
                    <a target="_blank" className="bx--btn bx--btn--primary bx--btn--sm button-color" href="https://geiphelp.x0pa.ai/geip-user-guide/docs/studentdoc1">{t('userguide')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ backgroundImage: `url(${compImage})` }} className="background-imgs col-lg-6 col-sm-12 col-md-6 m-0 p-0">
            <div className="institution-mask d-flex justify-content-between align-items-center flex-column background-masking">
              <div className="text-uppercase h1 font-weight-bold mt-2 pt-5 main_header_color">
                company
            </div>
              <div className="p-4">
                <div className="text-center btn_pos">
                  <div className="btn_disp">
                    <Link className="bx--btn bx--btn--primary button-color bx--btn--sm mt-3 mr-3 w-100" to="/public/company/login">{t('login')}</Link>
                  </div>
                  <div className="btn_disp bt_disp_pos">
                    <Link className="bx--btn bx--btn--primary button-color bx--btn--sm mt-2 mr-3 w-100" to="/public/register/company/info">{t('register')}</Link>
                  </div>
                  <div className="text-center mt-2">
                    <a target="_blank" className="bx--btn bx--btn--primary bx--btn--sm button-color" href="https://geiphelp.x0pa.ai/geip-user-guide/docs/studentdoc1">{t('userguide')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PublicFooter 
            handleRedirectMainPage={() => {}}
            currProd="roboroy"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
