import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { Modal, StructuredListWrapper,StructuredListBody,
  StructuredListRow,StructuredListCell } from 'carbon-components-react';
import moment from 'moment';

import 'components/roboroy/CommonModal.css';
import './formPreview.css';

class Preview extends Component {
  renderHtml = (htmlObject) => { return <div dangerouslySetInnerHTML={htmlObject} /> };
  render() {
    return (
      <Modal
        onRequestClose={this.props.dismiss}
        className="xpa-m-preview"
        open={this.props.active}
        passiveModal
        modalHeading="Preview">
        <div className="lists">
        {this.props.fields && Array.isArray(this.props.fields) && this.props.fields.map((row,index)=>{
          return (<div key={`${index}`}>
            <h5 className="m-0 mb-1">{row.header}</h5>
            <div style={{borderBottom: "2px solid #3d70b2"}}></div> 
          <StructuredListWrapper>
            <StructuredListBody>
              { row.field && Array.isArray(row.field) && row.field.map(({label,value},key)=>{
                return (<StructuredListRow key={`${key}`}>
                {label&& (label.length > 0) &&
                <StructuredListCell noWrap>
                {label}
                </StructuredListCell>}
                <StructuredListCell>
                {label&&label.length>0?value:this.renderHtml({__html: value })}
                </StructuredListCell>
                </StructuredListRow>);
              }) }
            </StructuredListBody>
            </StructuredListWrapper>
            </div>);
        }) }
        </div>
        {/* <div className="modal-body pl-0">
          {this.props.fields.map((field,index) => {
            return (<div key={index}>
              <div className="newjob__form__header text-uppercase">
                <h5 className="text-medium">{field.header}</h5>
              </div>
              {
                field.field.map(({ label, value, type },pos) => {
                  if(['Skills'].includes(field.header) && Array.isArray(value) && value.length > 0) {
                    value = value.join(', ');
                  }
                  return (<div className="form-group" key={pos}>
                    {label && (<div className="preview-label">
                      <label className="form-label pt-3 pb-0 mb-0">{label}</label>
                    </div>)}
                    <div className="preview-value">
                      {['Job Description', 'Job Requirements'].includes(field.header) ? 
                          this.renderHtml({__html: value }):
                        (<div>{type && type == "date" ? moment(value).format("DD/MM/YYYY") : value}</div>)
                    }
                    {['Private Status'].includes(field.header) ? this.renderHtml({__html: value}) : ''}
                    </div>
                  </div>)
                })
              }
            </div>)
          })}
        </div> */}
      </Modal>
    );
  }
}

export default Preview;
