import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
    REQUEST_COMPANY_JOB_POSTINGS,
    REQUEST_TRANSFER_JOB
} from './actionTypes';

const getCompanyJobPostings = data => (dispatch) => {
    const { companyId } = data;
    const err = {
        data: '',
        error: true,
        message: 'Missing required fields.',
    };
    if (companyId === null || companyId === undefined) {
        return Promise.reject(err);
    }
    let url = `${baseUrl}/applications/getJobsWithCompany?companyId=${companyId}`;

    const params = {
        headers: {
            'content-type': 'application/json',
        },
        dispatch,
        method: 'GET',
        url,
        actionType: REQUEST_COMPANY_JOB_POSTINGS,
    };
    return callApi(params);
}

const transferApplications = data => (dispatch) => {
    let url = `${baseUrl}/applications/transferApplication`;

    const params = {
        headers: {
            'content-type': 'application/json',
        },
        dispatch,
        method: 'PATCH',
        url,
        body: JSON.stringify(data),
        actionType: REQUEST_TRANSFER_JOB,
    };
    return callApi(params);
}

export { getCompanyJobPostings, transferApplications };