import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, DataTable, Pagination, Search, Select, SelectItem } from 'carbon-components-react';
import _ from 'lodash';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import SetMixPanel from 'components/common/GetCurrentPath';
import Avatar from 'components/common/Avatar';
import moment from 'moment';

import { getCandidatesInOffer, getOfferExport } from 'actions/offerInformation';
import { getBatchDetails } from 'actions/BatchManagementView';

const {
  TableContainer, Table, TableRow,
  TableBody, TableCell, } = DataTable;

const track = {
  OFFERS_TITLE_CLICKED: 'OFFER_TITLE_CLICKED',
  OFFER_JOB_CLICKED: 'OFFER_JOB_CLICKED',
};

class CandidatesInOfferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interviewName: '',
      interviewURL: '',
      activePage: 1,
      ascDesc: '',
      sortParam: '',
      activeFilter: null,
      itemsPerPage: 10,
      activeColumn: '',
      currentSortProp: null,
      keyword: '',
      selectedTab: 0,
      isLoading: true,
      status: 'inprogress',
      rowsData: [],
      count: 0,
      draft: 0,
      awaiting: 0,
      completed: 0,
      headersInfo: [{ key: "offers", header: "Candidates in Offer Round" }]
    }
  }

  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'offers', name: 'Candidates in Offer Round', href: '' }
  ];

  componentDidMount() {
    const {
      match: {
        params: {
          status: paramStatus,
        } = {},
      } = {},
    } = this.props;

    if (paramStatus) {
      this.setState({
        status: paramStatus,
      }, () => {
        if (this.state.status === 'inprogress') {
          this.setState({
            selectedTab: 0,
          }, () => {
            this.setRows();
          });
        } else {
          this.setState({
            selectedTab: 1,
          }, () => {
            this.setRows();
          });
        }
      });
    } else {
      this.setRows();
    }
  }

  handlePaginationChange = (data) => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    // const status = data.status || 'inprogress';
    const status = data.status || this.state.status;
    const d = push && push(`/app/campus/offers/${status}`);
    this.setState({
      status,
      activePage,
      itemsPerPage,
    }, () => {
      this.setRows();
    });
  };

  // CardRowBox = rowData => (<div className="bx--tile p-0">
  //   <div className="">
  //     <div className="bx--row p-2">
  //       <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
  //         <div className="text-sm-center text-md-center text-center text-lg-left">
  //           <div className="text-dark">
  //             <small title="Job">{`Requisition ID: ${rowData.jobId}`}</small>
  //           </div>
  //           <a href={rowData.applicantLink} title="Interview Name" onClick={() => SetMixPanel(track.OFFERS_TITLE_CLICKED)} className="card-title h5 mb-1 d-inline-block text-lg-left text-center font-weight-bold text-capitalize xpa-link">
  //             {rowData.offerName}
  //           </a>
  //           {/* {rowData.applicant && rowData.applicant.trim().length > 0 && <div title="Applicant" className="text-dark card-subtitle mb-1 bx--type-zeta pt-lg-1">
  //             Candidate Name: <a href={rowData.applicantLink}>{` ${rowData.applicant}`}</a>
  //           </div>} */}
  //           {/* <small title="Applicant ID" className="text-dark">{` (${rowData.applicantId})`}</small> */}
  //           {rowData.jobName && rowData.jobName.trim().length > 0 && <div title="Designation" className="text-dark card-subtitle mb-1 bx--type-zeta pt-lg-1">
  //             Job Title:<a onClick={() => SetMixPanel(track.OFFER_JOB_CLICKED)} href={rowData.jobLink}>{` ${rowData.jobName}`}</a>
  //           </div>}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>);

  setRows = () => {
    const {
      status, itemsPerPage, activePage, keyword,
    } = this.state;
    const {
      user: {
        company: {
          ctypeId
        } = {},
      } = {},
    } = this.props
    this.props.getBatchDetails().then((res) => {
      const { list: batchList } = res;
      const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
      let batch;
      if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
        if(parseInt(ctypeId)!==1){
          batch=currentBatch.batchName;
        }
      }
      this.props.getCandidatesInOffer({ status, itemsPerPage, activePage, batch });
    });

    // .then((res) => {
    //   const offers = this.props.offers && Array.isArray(this.props.offers)?this.props.offers:res.offers;
    //   const {count:{inprogress=0,completed=0}} = res;
    //   const rowsData = Array.isArray(offers) ? offers.map((offer={}) => {
    //     const oneRow = {
    //       applicantLink: `candidate/${ offer.application && offer.application.appId || 0}/job/${offer.application && offer.application.job && offer.application.job.jobId || 0}`,
    //       applicantId: offer.application && offer.application.appId,
    //       offerName: offer.name || "-",
    //       jobName: offer.application && offer.application.job && offer.application.job.jobname && offer.application.job.jobname.nameName || '',
    //       jobLink: `job/${offer.application && offer.application.job && offer.application.job.jobId || 0}`,
    //       jobId: offer.application && offer.application.job && offer.application.job.jobId,
    //     };
    //     return { id: `${offer.offerId}`, offers: this.CardRowBox(oneRow), };
    //   }):[];
    //   this.setState({ rowsData, isLoading: false});
    // });
  }

  handleOffersTabClick = (status) => {
    this.handlePaginationChange({ page: 1, status, pageSize: 10 });
  }

  getInterviews = () => {

    const {
      interviews = [],
    } = this.props;
    const interviewsList = interviews.map((interviewObj) => {
      const {
        videointerviews = {},
        application: {
          job: {
            jobname: { nameName: jobName, } = {},
          } = {},
          profile: { firstName, lastName, preferName, } = {},
        } = {},
      } = interviewObj
      return { jobName, firstName, lastName, preferName, ...videointerviews };
    });
    const resfil = []
    return interviewsList;
  }

  handleExportOffers = () => {
    this.props.getOfferExport();
  };

  render() {
    const { selectedTab, status, rowsData = [], headersInfo } = this.state;
    const {
      count: {
        inprogress = 0,
        completed = 0,
      } = {},
      offers,
      t,
      summary,
      user: {
        currentCompany: {
          ctypeId
        } = {},
      } = {},
    } = this.props;
    const inProgressCount =
      (summary && summary['applications-inprogress']) || 0;
    const completedCount = (summary && summary['applications-completed']) || 0;
    const totalCount =
      (summary && summary[`applications-${status || 'inprogress'}`]) || 0;
    return (
      <div className="mb-3">
        <BackRow paths={this.paths} />
        {
          ctypeId && Number(ctypeId) === 2 && (
            <div className="text-right mb-lg-0 mb-xs-2">
              <Button className="mb-2 mt-2 mt-md-0"
                onClick={() => this.handleExportOffers()}> Export Offers Data</Button>
            </div>
          )
        }
        <GenericTabs
          selected={selectedTab}
          tabContent={[
            {
              label: `${t('offersInProgressTab')}(${inProgressCount})`,
              value: '',
              onClick: (e) => this.handleOffersTabClick("inprogress")
            },
            {
              label: `${t('completed')}(${completedCount})`,
              value: '',
              onClick: (e) => this.handleOffersTabClick("completed")
            },
          ]} />
        {/* <div className="interviewList">
            <DataTable rows={rowsData} headers={headersInfo} render={({ rows, headers, getHeaderProps }) => {
              return <TableContainer title="" className="bg-transparent mt-2" style={{ overflow: "hidden" }}>
                <Table className="bg-transparent" style={{ borderSpacing: "0px 6px", borderCollapse: "separate" }}>
                  <TableBody className="bg-transparent">
                    {rows.map(row => (
                      <TableRow key={row.id} className="mb-2">
                        {row.cells.map(cell => (
                          <TableCell
                            key={cell.id}
                            className="pr-0 pl-0"
                          >
                            {cell.value}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>;
            }} />
          </div> */}
        {
          offers && Array.isArray(offers)
          && offers.length > 0
          && (
            <div className="mt-2 pb-2">
              {
                offers.map((res, idx) => {
                  const {
                    application: {
                      appId,
                      job: {
                        jobId,
                        jobname: {
                          nameName,
                        } = {},
                        company: {
                          companyName,
                          displayCompanyName
                        } = {},
                      } = {},
                    } = {},
                    name,
                    startDate,
                  } = res || {};
                  return (
                    <Fragment>
                      <div className={`bx--row ${((offers.length - 1) == idx) && 'pb-2'}`}>
                        <div className="ml-2 xpa-avatar-width">
                          <Avatar
                            user={{
                              firstname: name,
                              lastname: null,
                              picture: null,
                            }}
                            classNameInfo="rounded-circle"
                            size="30"
                          />
                        </div>
                        <div className="xpa-name-overflow font-weight-bold text-capitalize bx--col-lg-2 bx--col-md-4 bx--col-sm-3 pl-0 ml-2 bx--col-xs-3 mt-1">
                          <a href={`/app/campus/candidate/${appId}/job/${jobId}/interviewstatus`} onClick={() => SetMixPanel(track.ROBOROY_DASHBOARD_OFFER_CLICKED)}>{name}</a>
                        </div>
                        <div className="mt-1 bx--col-lg-4">
                          <a className="font-weight-bold text-capitalize" href={`/app/campus/job/${jobId}`} onClick={() => SetMixPanel(track.ROBOROY_DASHBOARD_OFFERJOB_CLICKED)}>{nameName}</a>
                          <span className="text-dark text-normal ml-1">({`${jobId}`})</span>
                        </div>
                        <div className="mt-1 font-weight-bold text-dark bx--col-lg-4 text-capitalize">
                          {displayCompanyName}
                        </div>
                        <div className="mt-1 ml-3 ml-xl-0 text-dark text-right">
                          {startDate ? moment(startDate).format('DD/MM/YY, hh:mma') : '-'}
                        </div>
                      </div>
                      {
                        offers && !((offers.length - 1) == idx) && <hr className="mb-2 mt-1" />
                      }
                    </Fragment>
                  );
                })
              }
            </div>
          )
        }
        {offers && Array.isArray(offers) && offers.length > 0 && <Pagination
          onChange={this.handlePaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={this.state.activePage}
          pageSize={this.state.itemsPerPage}
          totalItems={totalCount} />}
        {
          offers && Array.isArray(offers) && offers.length <= 0 &&
          <NoInterviewsMessageBox t={t} />
        }
        {selectedTab === 1 && <div />}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>);
  }
}

const NoInterviewsMessageBox = ({
  t,
}) => (
  <div className="">
    <div className="card-body text-center ">
      <div className="empty-icon">
        <i className="icon icon-3x icon-people" />
      </div>
      <p className="empty-title h5">{t('noOfferRounds')}</p>
    </div>
  </div>
);

const mapStateToProps = state => ({
  loading: state.OfferInformation.loading,
  user: state.x0paDuser.user,
  offers: state.OfferInformation.offers,
  count: state.OfferInformation.count,
  summary: state.OfferInformation.summary,
});
const mapDispatchToProps = {
  getCandidatesInOffer,
  getOfferExport,
  getBatchDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CandidatesInOfferList));
