import {
  REQUEST_GET_STUDENTS_LIST,
  ERROR_GET_STUDENTS_LIST,
  SUCCESS_GET_STUDENTS_LIST,
  REQUEST_GET_STUDENTS,
  SUCCESS_GET_STUDENTS,
  ERROR_GET_STUDENTS,
  REQUEST_GET_STUDENT_FACETS,
  SUCCESS_GET_STUDENT_FACETS,
  ERROR_GET_STUDENT_FACETS,
  REQUEST_IMPORT_STUDENTS,
  SUCCESS_IMPORT_STUDENTS,
  ERROR_IMPORT_STUDENTS,
  REQUEST_IMPORT_STUDENTS_ATTENDANCE,
  SUCCESS_IMPORT_STUDENTS_ATTENDANCE,
  ERROR_IMPORT_STUDENTS_ATTENDANCE,
  REQUEST_IMPORT_STUDENTS_LO_INFO,
  ERROR_IMPORT_STUDENTS_LO_INFO,
  SUCCESS_IMPORT_STUDENTS_LO_INFO,
  REQUEST_CONFIRM_STUDENTS_EMAIL_INFO,
  ERROR_CONFIRM_STUDENTS_EMAIL_INFO,
  SUCCESS_CONFIRM_STUDENTS_EMAIL_INFO
} from 'actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  students: [],
  courses: [],
  faculties: [],
  institutions: [],
  studentList: [],
  count: 0,
};

const BrowseStudents = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_STUDENTS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STUDENTS_LIST:
      return {
        ...state,
        studentList: payload && payload.students,
        // count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_GET_STUDENTS_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_STUDENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STUDENTS:
      return {
        ...state,
        students: payload && payload.students,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_GET_STUDENTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_STUDENT_FACETS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STUDENT_FACETS:
      return {
        ...state,
        facets: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_STUDENT_FACETS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_IMPORT_STUDENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_IMPORT_STUDENTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_IMPORT_STUDENTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_IMPORT_STUDENTS_ATTENDANCE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_IMPORT_STUDENTS_ATTENDANCE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_IMPORT_STUDENTS_ATTENDANCE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_IMPORT_STUDENTS_LO_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_IMPORT_STUDENTS_LO_INFO:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_IMPORT_STUDENTS_LO_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
      case REQUEST_CONFIRM_STUDENTS_EMAIL_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CONFIRM_STUDENTS_EMAIL_INFO:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CONFIRM_STUDENTS_EMAIL_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };     
    default:
      return state;
  }
};

export default BrowseStudents;
