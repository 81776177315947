import {
    REQUEST_ANALYTICS_INFO,
    SUCCESS_ANALYTICS_INFO,
    ERROR_ANALYTICS_INFO,
    REQUEST_UPDATE_INFO,
    SUCCESS_UPDATE_INFO,
    ERROR_UPDATE_INFO
} from '../actions/actionTypes';

const initialState = {
    analyticsInfo: {}
}

const analyticsInfo = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case REQUEST_ANALYTICS_INFO: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_ANALYTICS_INFO: {
            return {
                ...state,
                loading: false,
                analyticsInfo: payload,
            }
        };
        case ERROR_ANALYTICS_INFO:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };

        case REQUEST_UPDATE_INFO: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_UPDATE_INFO: {
            return {
                ...state,
                loading: false,
                analyticsInfo: payload,
            }
        };
        case ERROR_UPDATE_INFO:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;

    }
}

export default analyticsInfo;