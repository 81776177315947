import React, { Component, Fragment } from 'react';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import {
  getAllCourses,
} from 'actions/candidate/CandidateDetails';
import { withTranslation } from 'react-i18next';
import {
  SelectItem, Select,
} from 'carbon-components-react';
import { connect } from 'react-redux';

class Course extends Component {
  constructor(props) {
    console.log("propsDDDD",props)
    super(props)
    this.state= {
      courseInfo:''
    }
  }

  componentDidMount(){
   this.handleCourseApiCall()
  }


  handleCourseApiCall = () => {
    const {
      facultyId,
      facultyData,
      schoolId,
    } = this.props;
    if(facultyData && Array.isArray(facultyData) && facultyData.length>0){
      facultyData.map((resp) => {
        let facultyCourse = resp && resp.courses
        if(facultyId == resp.facultyId){
          if(facultyCourse && Array.isArray(facultyCourse) && facultyCourse.length >0){
            return (
              this.setState({
                courseInfo: facultyCourse,
              })
            )
          }
        }
      })
    }
  }

  render() {
    const { t, handleChange, courseAdmin, courseId } = this.props
    const { courseInfo } = this.state
    return (
      <div className="w-100">
        {
          courseAdmin === true ? (
            <Select
              name="courseId"
              labelText={t('course')}
              id="courseId"
              onChange={e => handleChange(e)}
              value={courseId}
              className="w-100"
            >
              <SelectItem text="Select" />
              {
                courseInfo && Array.isArray(courseInfo)
                && courseInfo.length>0 && (
                  courseInfo.map((res) => {
                    const {
                      courseId,
                      courseName,
                    } = res || {};
                    return (
                      <SelectItem key={courseId} text={courseName} value={courseId} />
                    );
                  })
                )
              }
            </Select>
          ) : ''
        }
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  coursesData: state.candidateDetails.coursesData
});

const mapDispatchToProps = {
  getAllCourses
};

export default
connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Course));
