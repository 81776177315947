import { useTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Loading } from 'carbon-components-react';
import FillCircle from 'components/common/FillCircle/FillCircle';
import Avatar from 'components/common/Avatar';
import moment from 'moment';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import '../Dashboard.css';
import SetMixPanel from 'components/common/GetCurrentPath';

const track = {
  ROBOROY_DASHBOARD_APPLICANT_CLICKED: 'ROBOROY_DASHBOARD_APPLICANT_CLICKED',
  ROBOROY_DASHBOARD_APPLICANTJOB_CLICKED: 'ROBOROY_DASHBOARD_APPLICANTJOB_CLICKED',
};

const ApplicationView = ({
  dashboard,
  handleCandidatesClick,
  handleCandidatesInProcessClick,
  handleShortListClick,
  handleRejectClick,
  applications,
  applCount,
  applLoading,
  push,
}) => {
  const { t } = useTranslation();
  const screeningPercent = ((dashboard.numOfCandidatesInProcess * 100) / dashboard.totalApplications);
  const shortlistedPercent = ((dashboard.numOfShortlistedApplications * 100) / dashboard.totalApplications);
  const rejectedPercent = ((dashboard.numOfRejectedApplications * 100) / dashboard.totalApplications);
  return (
    <Fragment>
      <div className="mb-2">
        <span className="heading-style h6 text-uppercase font-style-heading">
          {t('applications')}
        </span>
        {/* <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={handleCandidatesClick}>
              <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large'}}>
                    {dashboard.totalApplications}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('totalApplications')}</div>
                </div>
                <div style={{ fontSize: 'xx-large'}}><i className="fa fa-address-card xpa-text-grey--light ml-3" /></div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={handleCandidatesInProcessClick}>
              <div className="d-flex justify-content-around align-items-center p-2">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large'}}>
                    {dashboard.numOfCandidatesInProcess || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('jobsScreeningTab')}</div>
                </div>
                <div className="ml-3">
                  <FillCircle
                    fill={Number.isNaN(Number(`${screeningPercent}`))
                      ? 0
                      : Math.round(Number(screeningPercent))}
                    fillColor="#FFA500"
                    textColor="#000000"
                    isPercent={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={handleShortListClick}>
              <div className="d-flex justify-content-around align-items-center p-2">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large' }}>
                    {dashboard.numOfShortlistedApplications}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz ">{t('jobsShortlistedTab')}</div>
                </div>
                <div className="ml-3">
                  <FillCircle
                    fill={Number.isNaN(Number(`${shortlistedPercent}`))
                      ? 0
                      : Math.round(Number(shortlistedPercent))}
                    fillColor="#008000"
                    textColor="#000000"
                    isPercent={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={handleRejectClick}>
              <div className="d-flex justify-content-around align-items-center p-2">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large' }}>
                    {dashboard.numOfRejectedApplications}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz ">{t('jobsRejectedTab')}</div>
                </div>
                <div className="ml-3">
                  <FillCircle
                    fill={Number.isNaN(Number(`${rejectedPercent}`))
                      ? 0
                      : Math.round(Number(rejectedPercent))}
                    fillColor="#FF0000"
                    textColor="#000000"
                    isPercent={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div style={{ flex: 1 }}>
          <GenericTabs
            className="d-flex justify-content-end d-flex"
            selected={0}
            tabContent={[
              {
                label: t('recentlyApplied'),
                value: '',
                onClick: () => {},
              },
            ]}
          />
        </div>
        {
            applLoading === true
            && (
              <div className="bx--row justify-content-around">
                <Loading withOverlay={false} />
              </div>
            )
          }
        {
          applications && Array.isArray(applications)
            && applications.length > 0
            && applLoading === false
            && applications.map((appl, idx) => {
              const {
                appId,
                job: {
                  jobId,
                  jobname: {
                    nameName,
                  } = {},
                  company: {
                    companyName,
                    displayCompanyName
                  } = {},
                } = {},
                profile: {
                  firstName,
                  lastName,
                } = {},
                appDate,
              } = appl || {};
              return (
                <Fragment>
                  <div className="bx--row mt-2">
                    <div className="bx--row bx--col-lg-2 bx--col-md-4 bx--col-sm-4 bx--col-xs-5 ml-md-0">
                      <div className="ml-2 xpa-avatar-width">
                        <Avatar
                          user={{
                            firstname: firstName,
                            lastname: lastName,
                            picture: null,
                          }}
                          classNameInfo="rounded-circle"
                          size="30"
                        />
                      </div>
                      <div className="font-weight-bold text-capitalize bx--col-sm-3 mt-1 pl-2">
                        <span className="xpa-link" onClick={() => { SetMixPanel(track.ROBOROY_DASHBOARD_APPLICANT_CLICKED); push && push(`/app/campus/candidate/${appId}/job/${jobId}/interviewstatus`)}}>{firstName} {lastName}</span>
                      </div>
                    </div>
                    <div className="mt-1 bx--col-lg-4 ml-lg-2 ml-md-2">
                      <span className="font-weight-bold text-capitalize xpa-link" onClick={() => { SetMixPanel(track.ROBOROY_DASHBOARD_APPLICANTJOB_CLICKED); push && push(`/app/campus/job/${jobId}`)}}>{nameName}</span>
                      <span className="text-dark text-normal pl-1">({`${jobId}`})</span>
                    </div>
                    <div className="mt-1 ml-2 font-weight-bold text-dark bx--col-lg-4 text-uppercase">
                      {displayCompanyName}
                    </div>
                    <div className="mt-1 ml-2 pl-1 text-dark text-right ml-md-3">
                      {moment(appDate).format('DD/MM/YY, hh:mma')}
                    </div>
                  </div>
                  {
                    !(((applications.length - 1) == idx)) && <hr className="mb-2 mt-1" />
                  }
                </Fragment>
              );
            })
        }
        {
          applications && Array.isArray(applications)
            && applications.length <= 0
            && applLoading !== true
            && (
              <div className="card-body text-center">
                <p className="font-style-heading h6">{t('noRecentApplications')}</p>
              </div>
            )
        }
        {/*
          applCount
            && applCount > 5
            ? (
              <div onClick={handleCandidatesClick} className="xpa-link">{t('more')}</div>
            ) : null
        */}
      </div>
      <hr />
    </Fragment>
  );
};

export default ApplicationView;
