import { withTranslation } from 'react-i18next';
import React from 'react';
// import './Contact.css';
import demo from 'assets/images/contact/demo.svg';
import inquiry from 'assets/images/contact/inquiry.svg';
import support from 'assets/images/contact/support.svg';

const Contact = () => (
  <div id="Page" className="Page">
    <div className="Page-container">
      <div className="">
        <section className="Hero Page-header">
          <div className="Hero-container Hero--textCenter Container">
            <div className="Hero-copy Typography">
              <h1>Contact Us</h1>
              <p>
                X0PA is here to help your organisation excel in its employee
                satisfaction journey. Our data analytics platform will help you
                every step of the way and we're always ready to provide you with
                more information or answer any questions you may have.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className="Contact Content">
        <section className="contact-section">
          <div className="Container panelsec3">
            <div className="Contact-main">
              <div className="Content-header">
                <h2 className="Heading2">We&apos;re At Your Service</h2>
                <p>Please select the purpose of your enquiry.</p>
              </div>
              <div className="Contact-panel">
                <div className="Contact-panel-image">
                  <img src={demo} alt="Demo" />
                </div>
                <div className="Contact-panel-content">
                  <h3 className="Heading3">Request A Demo</h3>
                  <p>
                    Request a demonstration of X0PA's ground-breaking data
                    analytics platform.
                  </p>
                  <a
                    href="mailto:info@x0pa.com?subject=Demo%20Request"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn--outline-red-500 btn--arrowRight Lightbox-trigger Lightbox-trigger-desktop"
                  >
                    Request A Demo{' '}
                  </a>
                </div>
                <a
                  href="mailto:info@x0pa.com?subject=Demo%20Request"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="Lightbox-trigger Lightbox-trigger-mobile"
                />
              </div>
              <div className="Contact-panel">
                <div className="Contact-panel-image">
                  <img src={inquiry} alt="Inquiry" />
                </div>
                <div className="Contact-panel-content">
                  <h3 className="Heading3">Sales Inquiry</h3>
                  <p>
                    Reach out to our team directly for immediate assistance with
                    all sales-related enquiries.
                  </p>
                  <a
                    href="mailto:info@x0pa.com?subject=Sales%20Inquiry"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn--outline-red-500 btn--arrowRight Lightbox-trigger Lightbox-trigger-desktop"
                  >
                    Sales Inquiry{' '}
                  </a>
                </div>
                <a
                  href="mailto:info@x0pa.com?subject=Sales%20Inquiry"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="Lightbox-trigger Lightbox-trigger-mobile"
                />
              </div>
              <div className="Contact-panel">
                <div className="Contact-panel-image">
                  <img src={support} alt="Support" />
                </div>
                <div className="Contact-panel-content">
                  <h3 className="Heading3">Customer Support</h3>
                  <p>
                    Get in touch with customer support for assistance with your
                    X0PA implementation.
                  </p>
                  <a
                    href="mailto:info@x0pa.com?subject=Customer%20Support"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn--outline-red-500 btn--arrowRight Lightbox-trigger Lightbox-trigger-desktop"
                  >
                    Customer Support{' '}
                  </a>
                </div>
                <a
                  href="mailto:info@x0pa.com?subject=Customer%20Support"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="Lightbox-trigger Lightbox-trigger-mobile"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="Container Contact-footer-container">
            <div className="Contact-footer--left Accordion">
              <div className="Contact-footer--left-contentWrapper">
                <div>
                  <h3 className="Accordion-toggle Heading Heading3">Contact</h3>
                  <p className="Accordion-content">
                    <div>Address: 6 Battery Road #24-02 Singapore 049909</div>
                    <div>Email: info@x0pa.com</div>
                    <div>Phone: +65 65360436</div>
                    <div>
                      Website:{' '}
                      <a
                        href="https://www.x0pa.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.x0pa.com
                      </a>
                    </div>

                    <span className="Contact-maplink">
                      <a
                        className="btn btn--red-500"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://goo.gl/maps/4W6JqeK7LPP2"
                      >
                        Get Directions
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="Contact-footer--right">
              <iframe
                title="google-maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.406922053541!2d103.85062475813857!3d1.285701099765573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190969fd9013%3A0xd1ef1f3fe54c488!2s6+Battery+Rd%2C+Singapore+049909!5e0!3m2!1sen!2ssg!4v1514833168050"
                width={1000}
                height={400}
                style={{ border: 0 }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
);

export default Contact;
