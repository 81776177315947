import React from 'react';
import "./Batch.css";
import { Education32, Information16 } from '@carbon/icons-react';
import { Tooltip } from "carbon-components-react";


const Batch = ({ mainHeading, count, subHeading, icon, isDollar, isCard, isInfo }) => (
    <div className={isCard ? "card p-3" : ""}>
        <div className="d-flex no-gutters align-items-center">
            {icon != null && icon != undefined && <div className="mr-3">
                <div style={{ borderRadius: '50%', width: '50px', height: '50px', display: 'inline-block' }} class="bg-warning">
                    <div style={{ marginLeft: '9px', marginTop: '9px' }}>
                        {icon}
                    </div>
                </div>
            </div>}
            <div class="">
                {mainHeading}
                {isInfo ? <span className="mt-1"><Tooltip direction="right" tabIndex={0} triggerText="">
                    {subHeading}
                </Tooltip></span> : null}
                <div class=""><h1>{(count != null && count != undefined) ? (isDollar ? `$${count}` : count) : (isDollar ? `$0` : 0)}</h1></div>
            </div>
        </div>
    </div>
)

export default Batch;