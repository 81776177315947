import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import BackRow from 'components/common/BackRow/BackRow';
import JobTimeline from 'components/roboroy/jobInfo/JobTimeline';
import AssetAnalytics from 'containers/roboroy/AssetAnalytics';
import { Tile } from 'carbon-components-react';
import {
  getAnalyticsLogs,
} from 'actions/analytics';
import {
  Pagination, DatePicker, DatePickerInput,
  Button,
} from 'carbon-components-react';
import moment from 'moment';
import { connect } from 'react-redux';

class AdminAnalyticsLogs extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'logs', name: 'Analytics Logs', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      startDate: moment().subtract(7,'days').format("MM/DD/YYYY"),
      endDate: moment().format("MM/DD/YYYY"),
    };
  }

  componentDidMount() {
    const data = {
      activePage: 1,
      itemsPerPage: 10,
    };
    this.props.getAnalyticsLogs(data);
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      this.props.getAnalyticsLogs({
        itemsPerPage: this.state.itemsPerPage,
        activePage: this.state.activePage,
      });
    });
  }

  onDateChange = (Dates) => {
    if (Dates && Dates.length > 0) {
      if (Dates.length > 1) {
        this.setState({
          startDate: moment(Dates[0]).format('MM/DD/YYYY'),
          endDate: moment(Dates[1]).format('MM/DD/YYYY'),
        });
      } else {
        this.setState({
          startDate: moment(Dates[0]).format('MM/DD/YYYY'),
        });
      }
    }
  }

  handleSubmitAnalyticsLogs = () => {
    const {
      startDate,
      endDate,
      activePage,
      itemsPerPage,
    } = this.state;
    const data = {
      activePage,
      itemsPerPage,
      startDate: moment(startDate).format('x'),
      endDate: moment(endDate).format('x'),
    };
    this.props.getAnalyticsLogs(data);
  }

  handleClearFilters = () => {
    this.setState({
      startDate: null,
      endDate: null,
    });
  }

  render() {
    const {
      logs,
      user,
      count, t,
    } = this.props;
    const {
      activePage,
      startDate,
      endDate,
    } = this.state;
    const jobTimeline = logs && Array.isArray(logs)
      && logs.length > 0
      && logs.filter(res => res && res.assetType === 'job');
    const applTimeLine = logs && Array.isArray(logs)
    && logs.length > 0
    && logs.filter(res => res && res.assetType !== 'job');
    console.log('jobTimeline', jobTimeline);
    console.log('applTimeLine', applTimeLine);
    return (
      <Fragment>
        <BackRow
          paths={this.paths}
        />
        <div className="bx--tile">
          <div className="bx--type-delta mb-2">
            {t('filters')}
          </div>
          <hr className="mt-1 mb-1" />
          <div className="d-flex">
            <div className="mb-2 bx--col-lg-4">
              <DatePicker className="justify-content-center pl-4" minDate="1/10/1970" maxDate={moment().format("MM/DD/YYYY")} datePickerType="range" onChange={d => this.onDateChange(d)} light >
                <DatePickerInput labelText={t('startDate')} className="mb-0 ml-2" id="start" placeholder="Start Date" value={moment(startDate).format("MM/DD/YYYY")} />
                <DatePickerInput labelText={t('endDate')} className="mb-0 mr-2" id="end" placeholder="End Date" value={moment(endDate).format("MM/DD/YYYY")} />
              </DatePicker>
            </div>
            <div className="bx--col-lg-1 mt-4">
              <Button
                kind="primary"
                onClick={this.handleSubmitAnalyticsLogs}
              >
                {t('submit')}
              </Button>
            </div>
            <div className="bx--col-lg-1 mt-4">
              <Button
                kind="tertiary"
                onClick={this.handleClearFilters}
              >
                {t('clearFilters')}
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-2 mb-2 bx--type-delta">
          {t('timeline')}
        </div>
        <Tile>
          <JobTimeline timeLine={jobTimeline} user={user} applTimeLine={applTimeLine} handleActiveSelectedTab={() => {}} />
          {
            applTimeLine && Array.isArray(applTimeLine)
              && applTimeLine.length > 0
              && (
                <AssetAnalytics
                  application={{}}
                  handleApplicantTabClick={() => {}}
                  timeLine={applTimeLine}
                  user={user}
                />
              )
          }
        </Tile>
        {
          logs && Array.isArray(logs)
            && logs.length > 0
            && (
              <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
            )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  logs: state.Analytics.logs,
  count: state.Analytics.count,
});

const mapDispatchToProps = {
  getAnalyticsLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminAnalyticsLogs));
