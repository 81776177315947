import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { DatePickerInput, DatePicker } from 'carbon-components-react';
import { withTranslation } from 'react-i18next';

import './timeline.css';
import './CandidateDetails.css';
import 'rc-slider/assets/index.css';

import {
  Button, TextInput, Toggle,
  Checkbox,
} from 'carbon-components-react';


import {
  getCandidateProfile,
} from 'actions/talentSearch/candidatesList';

import {
  getAllDropDownData,
  updateProfile,
} from 'actions/candidate/CandidateDetails';

import {
  createStudentProfile,
  updateStudentProfile,
} from 'actions/campus/internCandidate';

import {
  REQUEST_ALL_COUNTRY,
  REQUEST_FEW_ETHNICITIES,
  REQUEST_GET_CURRENCY_VALUE,
} from 'actions/actionTypes';

const Range = createSliderWithTooltip(Slider.Range);

const chineseGrades = [
  'A1',
  'A2',
  'B3',
  'B4',
  'C5',
  'C6',
  'D7',
  'E8',
  'F9',
  'NA',
];

const yearsOfStudy = [
  '1st Year',
  '2nd Year',
  '3rd year',
  '4th Year',
  '5th Year',
];

class CandidateBio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryValue: { label: '', value: '' },
      maritalValue: { label: '', value: '' },
      genderValue: { label: '', value: '' },
      dob: '',
      ethnicityValue: { label: '', value: '' },
      militaryRank: '',
      militaryTitle: '',
      placeOfBirth: '',
      studentUniversityId: '',
      schoolEmail: '',
      schoolValue: { label: '', value: '' },
      internIntake: '',
      internLenght: '',
      chineseOGrade: '',
      chineseHigherOGrade: '',
      chineseOGradeScore: '',
      chineseHigherOGradeScore: '',
      currentGpa: '',
      facultyValue: {},
      courseValue: {},
      courseId: {},
      facultyId: {},
      yearOfStudy: {},
    };
  }

  componentDidMount() {
    const { candidateProfile: { profile = {} } } = this.props;
    if (profile === null || !profile.profileId || profile.profileId === null) {
      const { user: { profileId = null } = {} } = this.props;
      if (profileId) {
        this.props.getCandidateProfile(profileId).then(() => {
          this.setStageBio();
        });
      }
    } else {
      this.setStageBio();
    }
  }

  isValid = (info, type) => {
    let data = info || {};
    if (type === 'number') return info && info !== null && info.toString().length > 0 ? true : false;
    if (type === 'string') return info && info !== null && info.length > 0 ? true : false;
    if (type === 'array') data = info && Array.isArray(info) ? info[0] || {} : {};
    const { value = null, label = null } = data;
    if (value !== null && label !== null && value !== '' && label !== '') return true;
    else return false;
  }

  isValidString = (str = null) => {
    if (str !== null && typeof str === 'string' && str.trim().length > 0) return true;
    return false;
  }


  isValidBio = () => {
    const {
      countryValue,
      genderValue,
      dob = null,
      ethnicityValue,
      placeOfBirth = null,
      studentUniversityId = null,
      schoolEmail = null,
      schoolValue,
      internIntake = null,
      internLenght = null,
      chineseOGrade = null,
      currentGpa = null,
      chineseHigherOGrade = null,
      yearOfStudy = null,
    } = this.state;
    
    if (this.isValid(countryValue, 'object') === false || this.isValid(genderValue, 'object') === false || this.isValid(ethnicityValue, 'object') === false || this.isValidString(moment(dob).format('MM/DD/YYYY')) === false
      || this.isValidString(studentUniversityId) === false || this.isValidString(schoolEmail) === false || this.isValid(schoolValue, 'object') === false || this.isValidString(placeOfBirth) === false
      || this.isValidString(currentGpa) === false || this.isValid(yearOfStudy, 'object') === false) {
      if (this.props.onNextStep && this.props.onNextStep(false)) return false;
    }
    if (this.props.onNextStep && this.props.onNextStep(true)) return true;
    return false;
  }

  setStageBio = () => {
    const { candidateProfile: { profile = {}, studentprofile = {} } = {} } = this.props;
    const {
      gender = {},
      nationality = {},
      militaryRank = '',
      militaryTitle = '',
      ethnicity = {},
      birthDate = '',
      email: schoolEmail = '',
      placeOfBirth = {},
      school = {},
      internIntake = '',
      internLenght = '',
    } = profile || {};

    const {
      company = null,
      studentUniversityId = null,
      chinesegrade: { grade: chineseOGrade = {}, score: chineseOGradeScore = null } = {},
      hchinesegrade: { grade: chineseHigherOGrade = {}, score: chineseHigherOGradeScore = null } = {},
      faculty = {},
      course = {},
      currentGpa = '',
      // facultyId = '',
      // courseId = '',
    } = studentprofile || {};

    let { yearOfStudy = null } = studentprofile || {};

    const { facultyValue = {}, courseValue = {} } = this.state;

    if (company !== null && company.companyId && company.companyId !== null) {
      school.value = company.companyId;
      school.label = company.companyName;
    }
    if (faculty !== null && faculty.facultyId && faculty.facultyId !== null) {
      facultyValue.value = faculty.facultyId;
      facultyValue.label = faculty.facultyName;
    }
    if (course !== null && course.courseId && course.courseId !== null) {
      courseValue.value = course.companyId;
      courseValue.label = course.companyName;
    }
    if (profile !== null && profile.genderId && profile.genderId !== null) {
      gender.label = gender.genderName;
      gender.value = gender.genderId;
    }
    if (profile !== null && profile.nationalityId && profile.nationalityId !== null) {
      nationality.label = nationality.countryFull;
      nationality.value = nationality.countryId;
    }
    if (profile !== null && profile.ethnicityId && profile.ethnicityId !== null) {
      ethnicity.label = ethnicity.ethnicityName;
      ethnicity.value = ethnicity.ethnicityId;
    }

    if (yearOfStudy !== null && typeof yearOfStudy === 'string') {
      yearOfStudy = { label: yearOfStudy, value: yearsOfStudy.indexOf(yearOfStudy) + 1 };
    }

    this.setState({
      genderValue: gender,
      countryValue: nationality,
      ethnicityValue: ethnicity,
      militaryRank,
      militaryTitle,
      placeOfBirth,
      studentUniversityId,
      schoolEmail,
      schoolValue: school,
      internIntake,
      internLenght,
      chineseOGrade: { label: chineseOGrade.gradeName ? chineseOGrade.gradeName : '', value: chineseOGrade.gradeId ? chineseOGrade.gradeId : '' },
      chineseHigherOGrade: { label: chineseHigherOGrade.gradeName ? chineseHigherOGrade.gradeName : '', value: chineseHigherOGrade.gradeId ? chineseHigherOGrade.gradeId : '' },
      currentGpa,
      facultyValue,
      courseValue,
      yearOfStudy,
      chineseOGradeScore,
      chineseHigherOGradeScore,
      // courseId,
      // facultyId,
      dob: birthDate === null ? moment() : moment(birthDate).toDate(),
    });
  }

  // To handle all text and text areas
  handleText = (e) => {
    const { target: { name = '', value = '' } } = e;
    const finalVal = value && value.toString() && value.trimLeft();
    this.setState({ [name]: finalVal });
  }
  // =======================================================

  onInputDropdown = (value, params) => {
    if ((/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) && value.length >= 2) || value === '') {
      const actionData = {
        actionType: '',
        apiName: '',
        value,
      };
      const {
        countries = [], candidateDetails: { ethnicities = [] },
      } = this.props;

      switch (params) {
        case 'country': {
          if (countries !== null && countries.length > 0) return;
          actionData.actionType = REQUEST_ALL_COUNTRY;
          actionData.apiName = '/Countries';
          break;
        }
        case 'ethnicity': {
          if (ethnicities !== null && ethnicities.length === 13) return;
          actionData.actionType = REQUEST_FEW_ETHNICITIES;
          actionData.apiName = '/ethnicities';
          break;
        }
        default: return;
      }
      actionData.value = value;
      this.props.getAllDropDownData(actionData);
    }
  }

  onDropdownChange = (e, params) => {
    if (e === undefined) return;
    const stageBio = e === null ? {} : e;
    this.setState({ [params]: stageBio });
  };

  saveBio = () => {
    const {
      countryValue,
      genderValue,
      dob,
      ethnicityValue,
      militaryRank = '',
      militaryTitle = '',
      placeOfBirth,
      studentUniversityId = '',
      schoolEmail = '',
      schoolValue,
      internIntake = '',
      internLenght = '',
      chineseOGrade = '',
      chineseHigherOGrade = '',
      currentGpa = '',
      chineseOGradeScore = '',
      chineseHigherOGradeScore = '',
      yearOfStudy = '',
    } = this.state;
    const { candidateProfile: { profile: { profileId = '' } = {}, studentprofile: { studentProfileId = '' } = {} } = {} } = this.props;
    const infoData = {
      gender: (genderValue && genderValue.label) || '',
      nationality: (countryValue && countryValue.label) || '',
      ethnicity: (ethnicityValue && ethnicityValue.label) || '',
      ethnicityId: (ethnicityValue && ethnicityValue.value) || '',
      militaryRank,
      militaryTitle,
      birthDate: dob,
      placeOfBirth,
      studentUniversityId,
      schoolEmail,
      schoolValue: (schoolValue.label ? schoolValue.label : ''),
      internIntake,
      internLenght,
      chineseOGrade: (chineseOGrade.label ? chineseOGrade.label : 'NA'),
      chineseHigherOGrade: (chineseHigherOGrade.label ? chineseHigherOGrade.label : 'NA'),
      currentGpa,
      chineseOGradeScore: Number(chineseOGradeScore),
      chineseHigherOGradeScore: Number(chineseHigherOGradeScore),
      yearOfStudy: yearOfStudy !== null ? yearOfStudy.label : null,
    };
    const data = { profileId, infoData };
    if (this.isValidBio() === true) {
      this.props.updateProfile(data).then(() => {
        this.props.updateStudentProfile(infoData, studentProfileId).then(() => {
          if (profileId) {
            this.props.getCandidateProfile(profileId);
          }
        });
      });
    }
  }

  onBioDOBChange = (date) => {
    this.setState({ dob: date[0] });
  }

  render() {
    const {
      countries = [],
      candidateDetails: {
        ethnicities = [],
      },
      disabled,
      t,
    } = this.props;

    const {
      countryValue,
      genderValue,
      dob,
      ethnicityValue,
      studentUniversityId,
      placeOfBirth,
      schoolEmail,
      schoolValue,
      // internIntake,
      // internLenght,
      chineseOGrade,
      chineseHigherOGrade,
      currentGpa,
      yearOfStudy,
      chineseOGradeScore,
      chineseHigherOGradeScore,
      // courseValue,
      // facultyValue,
    } = this.state;

    // const CustomOverlay = ({ classNames, ...props }) => (
    //   <div
    //     className={classNames.overlayWrapper}
    //     style={{ top: -350, backgroundColor: 'aliceblue' }}
    //     {...props}
    //   />
    // );

    return (
      <div className="bx--row m-0 p-0 candidateBio">
        <div className="bx--col ml-md-1">
          <DropDownBox
            title={t('school')}
            required
            labelClass="bx--type-zeta mb-0"
            titleClass="col-sm-12 col-md-3 mt-2"
            className="pl-3"
            options={countries.map(one => ({
              label: one.schoolName,
              value: one.schoolId,
            }))}
            disabled
            name="schoolValue"
            onInputChange={e => this.onInputDropdown(e, 'Faculty')}
            placeholder={t('school')}
            onChange={(id, value, name) => this.onDropdownChange(value, name)}
            selectedValue={schoolValue}
          />
        </div>
        <div className="bx--col bx--row m-0 pl-3">
          <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('studentId')}<span className="text-danger">*</span></div>
          <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
            <TextInput placeholder={t('studentId')} disabled labelText="" hideLabel name="studentUniversityId" max="20" value={studentUniversityId || ''} onChange={this.handleText} id="studentUniversityId" />
          </div>
        </div>
        <div className="bx--col bx--row m-0 pl-3">
          <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('schoolEmail')}<span className="text-danger">*</span></div>
          <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
            <TextInput placeholder="school email" disabled labelText="" hideLabel name="schoolEmail" max="50" value={schoolEmail || ''} onChange={this.handleText} id="schoolEmail" />
          </div>
        </div>
        {/* <div className="bx--col ml-md-1">
          <DropDownBox
            title="Faculty"
            required
            labelClass="bx--type-zeta mb-0"
            titleClass="col-sm-12 col-md-3 mt-2"
            options={[].map(one => ({
              label: one.facultyName,
              value: one.facultyId,
            }))}
            disabled={disabled}
            name="facultyValue"
            onInputChange={e => this.onInputDropdown(e, 'schofacultyol')}
            placeholder="faculty"
            onChange={(id, value, name) => this.onDropdownChange(value, name)}
            selectedValue={facultyValue}
          />
        </div>
        <div className="bx--col ml-md-1">
          <DropDownBox
            title="School"
            required
            labelClass="bx--type-zeta mb-0"
            titleClass="col-sm-12 col-md-3 mt-2"
            options={[].map(one => ({
              label: one.courseName,
              value: one.courseId,
            }))}
            disabled={disabled}
            name="Course Name"
            onInputChange={e => this.onInputDropdown(e, 'course')}
            placeholder="courseValue"
            onChange={(id, value, name) => this.onDropdownChange(value, name)}
            selectedValue={courseValue}
          />
        </div> */}
        <div className="bx--col bx--row m-0 pl-3">
          <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('studentGpa')}<span className="text-danger">*</span></div>
          <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
            <TextInput placeholder={t('studentGpa')} id="currentGpa" disabled={disabled} labelText="" hideLabel name="currentGpa" max="10" value={currentGpa || ''} onChange={this.handleText} />
          </div>
        </div>
        <div className="bx--col ml-md-1">
          <DropDownBox
            title={t('nationality')}
            required
            labelClass="bx--type-zeta mb-0"
            titleClass="col-sm-12 col-md-3 mt-2"
            mainClassName="row m-0 pl-1"
            options={countries.map(one => ({
              label: one.countryFull,
              value: one.countryId,
            }))}
            disabled={disabled}
            name="countryValue"
            onInputChange={e => this.onInputDropdown(e, 'country')}
            placeholder={t('country')}
            onChange={(id, value, name) => this.onDropdownChange(value, name)}
            selectedValue={countryValue}
          />
        </div>
        <div className="bx--col ml-md-1">
          <DropDownBox
            title={t('gender')}
            required
            disabled={disabled}
            labelClass="bx--type-zeta mb-0"
            titleClass="col-sm-12 col-md-3 mt-2"
            mainClassName="row m-0 pl-1"
            options={[{ label: 'Male', value: 1 }, { label: 'Female', value: 2 }, { label: 'Undisclosed', value: 3 }]}
            name="genderValue"
            placeholder={t('gender')}
            onChange={(id, value, name) => this.onDropdownChange(value, name)}
            selectedValue={genderValue}
          />
        </div>
        {(
          <Fragment>
            <div className="bx--col bx--row m-0 pl-3">
              <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('birhtPlace')}<span className="text-danger">*</span></div>
              <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                <TextInput placeholder={t('birhtPlace')} id="placeOfBirth" disabled={disabled} labelText="" hideLabel name="placeOfBirth" max="100" value={placeOfBirth || ''} onChange={this.handleText} />
              </div>
            </div>
            {/* <div className="bx--col ml-md-1">
              <DropDownBox
                title="Country of birth"
                required
                labelClass="bx--type-zeta mb-0"
                titleClass="col-sm-12 col-md-3 mt-2"
                options={countries.map(one => ({
                  label: one.countryFull,
                  value: one.countryId,
                }))}
                disabled={disabled}
                name="placeOfBirth"
                onInputChange={e => this.onInputDropdown(e, 'country')}
                placeholder="Country"
                onChange={(id, value, name) => this.onDropdownChange(value, name)}
                selectedValue={placeOfBirth}
              />
            </div> */}
            <div className="bx--col bx--row m-0 pl-3">
              <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('birthDate')}<span className="text-danger">*</span></div>
              <div className="newjob_input_container dob col-sm-12 col-md-9">
                {!disabled ? (
                  <DatePicker id="date-picker" className="its_bio" disabled={disabled} onChange={this.onBioDOBChange} datePickerType="single">
                    <DatePickerInput className="mt-1" id="date-picker-input-id" pattern="d{1,2}/d{4}" placeholder="mm/dd/yyyy" value={moment(dob).format('MM/DD/YYYY')} />
                  </DatePicker>
                ) : (
                    <DatePickerInput id="date-picker-input-id" value={moment(dob).format('MM/DD/YYYY')} />
                )}
              </div>
            </div>
            <div className="bx--col">
              <DropDownBox
                title={t('ethnicity')}
                disabled={disabled}
                required
                labelClass="bx--type-zeta mb-0"
                titleClass="col-sm-12 col-md-3 mt-2 ml-1"
                options={ethnicities.map(one => ({
                  label: one.ethnicityName,
                  value: one.ethnicityId,
                }))}
                onInputChange={e => this.onInputDropdown(e, 'ethnicity')}
                name="ethnicityValue"
                placeholder={t('ethnicity')}
                onChange={(id, value, name) => this.onDropdownChange(value, name)}
                selectedValue={ethnicityValue}
              />
            </div>
          </Fragment>
        )}
        {/* <Fragment>
          <div className="bx--col bx--row m-0 pl-3">
            <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">Internship Intake<span className="text-danger">*</span></div>
            <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
              <TextInput type="number" placeholder="Intake" labelText="" disabled={disabled} hideLabel name="internIntake" max="100" value={internIntake || ''} onChange={this.handleText} id="internIntake" />
            </div>
          </div>
          <div className="bx--col bx--row m-0 pl-3">
            <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">Internship Length <small className="font-weight-bold">(weeks)</small><span className="text-danger">*</span></div>
            <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
              <TextInput type="number" placeholder="Time Period" id="internLenght" disabled={disabled} labelText="" hideLabel name="internLenght" max="100" value={internLenght || ''} onChange={this.handleText} />
            </div>
          </div>
        </Fragment> */}
        <div className="bx--col ml-md-1">
          <DropDownBox
            title={t('currentYearOfStudy')}
            required
            disabled={disabled}
            labelClass="bx--type-zeta mb-0"
            titleClass="col-sm-12 col-md-3 mt-2"
            mainClassName="row m-0 pl-1"
            options={yearsOfStudy.map((year, i) => ({ label: year, value: i + 1 }))}
            name="yearOfStudy"
            placeholder={t('year')}
            onChange={(id, value, name) => this.onDropdownChange(value, name)}
            selectedValue={yearOfStudy}
          />
        </div>
        <div className="bx--col ml-md-1 bx--row m-0 mt-1">
          <div className="bx--col-md-5 p-0">
            <DropDownBox
              title={t('chineseGrade')}
              disabled={disabled}
              custom
              className="col-sm-12 col-md-5 pl-4"
              labelClass="bx--type-zeta mb-0"
              titleClass="col-sm-12 col-md-7 mt-2"
              options={chineseGrades.map((one, i) => ({ value: i + 1, label: one }))}
              name="chineseOGrade"
              placeholder="Grade"
              onChange={(id, value, name) => this.onDropdownChange(value, name)}
              selectedValue={chineseOGrade}
            />
          </div>
          <div className="bx--row m-0 p-0 mb-3">
            <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('score')}</div>
            <div className="col-md col-sm-12 pl-2 pl-md-0 pr-2 pr-md-3">
              <TextInput type="number" placeholder={t('score')} id="chineseOGradeScore" disabled={disabled} labelText="" hideLabel name="chineseOGradeScore" max="100" value={chineseOGradeScore || ''} onChange={this.handleText} />
            </div>
          </div>
        </div>
        <div className="bx--col ml-md-1 bx--row m-0 align-items-center">
          <div className="bx--col-md-5 p-0">
            <DropDownBox
              title={t('higherChineseGrade')}
              disabled={disabled}
              custom
              className="col-sm-12 col-md-5 pl-4"
              labelClass="bx--type-zeta mb-0"
              titleClass="col-sm-12 col-md-7 mt-2"
              options={chineseGrades.map((one, i) => ({ value: i + 1, label: one }))}
              name="chineseHigherOGrade"
              placeholder="Grade"
              onChange={(id, value, name) => this.onDropdownChange(value, name)}
              selectedValue={chineseHigherOGrade}
            />
          </div>
          <div className="bx--row m-0 p-0 mb-3">
            <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('score')}</div>
            <div className="col-md col-sm-12 pl-2 pl-md-0 pr-2 pr-md-3">
              <TextInput type="number" placeholder={t('score')} id="chineseHigherOGradeScore" disabled={disabled} labelText="" hideLabel name="chineseHigherOGradeScore" max="100" value={chineseHigherOGradeScore || ''} onChange={this.handleText} />
            </div>
          </div>
        </div>
        {!disabled && (
          <label className="ml-3">
            <small className="text-danger font-weight-bold">{t('allMandatoryFields')}</small>
          </label>
        )}
        {!disabled ? (
          <div className="bx--row bx--col mt-3 justify-content-center ml-0">
            {/* <div className="mr-2">
              <Button kind="tertiary" small className="" onClick={e => this.prosp.onPrevStep(e)}>Previous</Button>
            </div> */}
            <div className="mr-2">
              <Button kind="primary" small className="" onClick={this.saveBio}>{t('next')}</Button>
            </div>
          </div>
        ) : (
          !disabled && (
          <div className="bx--col text-center mb-3">
            <Button small onClick={this.saveBio}>{t('saveButtonLable')}</Button>
          </div>
          )
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  candidateProfile: state.roboroyApplications.candidateProfile,
  countries: state.roboroyApplications.countries,
  currencies: state.OfferInformation.currencies,
  loading: state.roboroyApplications.loading,
  candidateDetails: state.candidateDetails,
});

const mapDispatchToProps = {
  getAllDropDownData,
  getCandidateProfile,
  updateProfile,
  updateStudentProfile,
  createStudentProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CandidateBio));
