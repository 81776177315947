import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import { REQUEST_DOCUMENT, 
  REQUEST_DELETE_DOCUMENT,
  REQUEST_DOWNLOAD_DOCUMENT,
  REQUEST_JOB_DOCUMENT,
  SUCCESS_JOB_DOCUMENT,
  ERROR_JOB_DOCUMENT
} from './actionTypes';

const getDocumentById = id => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid document id passed',
  };
  if (!id) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/Persondocuments/cv?_=${new Date().getTime()}&id=${id}`;
  const params = {
    dispatch,
    actionType: REQUEST_DOCUMENT,
    url,
    method: 'GET',
    responseType: 'application/pdf',
  };
  return callApi(params);
};

const deleteDocumentById = id => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No person document id passed',
  };
  if (!id) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/persondocs/${id}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_DOCUMENT,
  };
  return callApi(params);
}

const uploadDocuments = (filesData) => (dispatch) => {
  const url = `${baseUrl}/jobdocuments`;

  const params = {
    actionType: 'REQUEST_DOCUMENTS_UPLOAD',
    url,
    dispatch,
    method: 'POST',
    body: filesData,
  };
  return callApi(params);
};

const getJobDocumentById = id => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid document id passed',
  };
  if (!id) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/jobdocuments/jd?_=${new Date().getTime()}&id=${id}`;
  const params = {
    dispatch,
    actionType: REQUEST_JOB_DOCUMENT,
    url,
    method: 'GET',
    responseType: 'application/pdf',
  };
  return callApi(params);
};

const getDocumentByIds = ids => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid document id passed',
  };
  if (!ids) {
    return Promise.reject(err);
  }
  let url = `${baseUrl}/Persondocuments/cvDownload?_=${new Date().getTime()}`;
  if (ids && Array.isArray(ids) && ids.length > 0) {
    url += `&personDocIds=${encodeURIComponent(JSON.stringify(ids))}&`;
  }
  const params = {
    dispatch,
    actionType: REQUEST_DOWNLOAD_DOCUMENT,
    url,
    method: 'GET',
    responseType: 'application/pdf',
  };
  return callApi(params);
};

export {
  getDocumentById,
  deleteDocumentById,
  uploadDocuments,
  getDocumentByIds,
  getJobDocumentById
};
