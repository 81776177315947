import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { RecruiterView, CandidateErrorView } from 'components/roboroy/invite/invite';
import { rejectInvitation, getUserInvitation } from 'actions/invite';
import {
  RadioButton, InlineNotification,
} from 'carbon-components-react';
import {
  handleCandidateJobPrivacyConsent,
} from 'actions/candidate/candidateConsent';

import './invite.css';

class InviteReject extends React.Component {
  state = {
    stayTuned: null,
    error: false,
    agreeTalentPool: null,
    modalError: false,
    errMessage: '',
    profileId: 0,
  }

  componentDidMount() {
    const {
      match: {
        params: { inviteHash },
      },
    } = this.props;
    /* TODO: Check the application record before dispatch */
    const djob = this.props.getUserInvitation({ appId: inviteHash });
      djob
      .then((res = {}) => {
        // console.log("Res",res);
        if (res && res.error) {
          this.setState({
            error: true,
            errMessage: (res.error.message) || '',
          });
          return;
        }
        const { profile: { profileId } = {} } = res;
        this.setState({
          profileId,
        });
      });
    this.props.rejectInvitation({ invite: inviteHash }).then((res) => {
      if ((res && res.error)) {
        this.setState({
          error: true,
        });
      }
    });
  }

  handleChangeRadioButton =(e) => {
    switch (e) {
      case 'tal-true':
        this.setState({
          agreeTalentPool: true,
          modalError: false,
        });
        break;
      case 'tal-false':
        this.setState({
          agreeTalentPool: false,
          modalError: false,
        });
        break;
      default:
    }
  }

  handleUserResponse = (r) => {
    const { profileId } = this.state;
    const { agreeTalentPool } = this.state;
    this.setState({
      stayTuned: r,
    });
  }

  handleCloseNotifButton = () => {
    this.setState({ modalError: false });
  }

  render() {
    const {
      error, stayTuned, modalError, errMessage,
    } = this.state;
    const { isUserLoggedIn, user: { roleId, company: { companyName } = {} } = {} } = this.props;
    return (
      <div className="row" style={{ marginLeft: 0, marginRight: 0, height: '100%' }}>
        <div className="col-md-12 invite-content">
          <div className="body-register">
            <div className="card-invite">
              <div className="card card-content">
                {
                  (!isUserLoggedIn || (roleId && roleId === 3)) && !error && (
                    <Fragment>
                      <div className="ml-loo-wrap d-flex justify-content-center">
                        <img src={require('assets/images/republic-polytechnic.png')} alt="" height="auto" width="auto" />
                      </div>
                      <div className="title-register">We are sorry to see you go.</div>
                      {/* <div>
                        {
                          stayTuned === null && (
                            <CandidateJobConsent
                              handleChangeRadioButton={this.handleChangeRadioButton}
                              modalError={modalError}
                              handleCloseNotifButton={this.handleCloseNotifButton}
                              companyName={companyName}
                            />
                          )
                        }
                      </div> */}
                      <div className="text-center">
                        <div className="text-center">
                          Would you like to stay in our database and be informed of future opportunities?
                        </div>
                        {
                          (stayTuned === null) && (
                            <div className="box-subscribe m-4 btn-group">
                              <button type="button" className="btn btn-success" onClick={() => this.handleUserResponse('yes')}>Yes</button>
                              <button type="button" className="btn btn-danger" onClick={() => this.handleUserResponse('no')}>No</button>
                            </div>)
                        }

                        <div className="box-subscribe m-4">
                          {
                            (stayTuned) && (<div className="bx--type-gamma">Thanks for your response</div>)
                          }
                        </div>
                      </div>
                    </Fragment>
                  )
                }
                {
                  (isUserLoggedIn && (roleId !== 3)) && <RecruiterView />
                }
                {
                  ((!isUserLoggedIn || (roleId && roleId === 3)) && error) && (
                    <CandidateErrorView
                      message={errMessage}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CandidateJobConsent = ({
  handleChangeRadioButton,
  modalError,
  handleCloseNotifButton,
  companyName,
}) => (
  <div>
    {modalError === true && (
      <InlineNotification lowContrast
        kind="error"
        className="bx--col"
        title=""
        subtitle="Please make a selection"
        onCloseButtonClick={handleCloseNotifButton}
      />)}
    <div className="text-justify text-dark font-weight-bold">
      As part of our Talent Network,  we consider your profile on
      ongoing basis to assess what exiciting roles you might be
      suited to. We'll always treat your personal details with
      utmost care in accordance with our privacy policy.
    </div>
    <div className="ml-2 mt-2">
      <RadioButton
        name="talentpool"
        value="tal-true"
        id="radio-3"
        disabled={false}
        onChange={(e) => { handleChangeRadioButton(e); }}
        labelText={<div>Yes, I consent to use my profile as a prospect for <b className="text-capitalize">{companyName}</b> to help assess and match me to vacancies.</div>}
      />
      <RadioButton
        name="talentpool"
        value="tal-false"
        id="radio-4"
        onChange={(e) => { handleChangeRadioButton(e); }}
        disabled={false}
        labelText="No thanks."
      />
    </div>
  </div>
);

const mapStateToProps = state => ({
  loading: state.jobs.loading,
  error: state.jobs.error,
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  rejectInvitation,
  getUserInvitation,
  handleCandidateJobPrivacyConsent,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteReject);
