import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import {
  TextInput,
  Select, SelectItem,
} from 'carbon-components-react';

const ContactDetails = (props) => {
  const {
    firstname,
    lastname,
    disabled,
    isFirstNameValid,
    isFirstNameValidText,
    handleChange,
    t,
    isLastNameValid,
    isLastNameValidText,
    isEmailValid,
    email,
    isEmailValidText,
    countryCode,
    isValidCountryCode,
    countries,
    isValidCountryCodeValue,
    phoneNumber,
    isPhoneNumberValid,
    isPhoneNumberValidText,
    currRoute,
    designation,
    isValidDesignation,
    isValidDesignationVal,
    disableEmail,
    ctypeId,
    saluationListArr,
    handleDropdownChange,
    isSaluationValid,
    saluationVal,
    secondaryphone,
    showSecondaryPhone,
  } = props;
  return (
    <Fragment>
      <div className="mt-2">
        {/* <h6 className="text-uppercase mt-2 mb-2 text-dark font-weight-bold">{t('contact')} {t('info')}</h6>
        <hr /> */}
      </div>
      <div className="d-lg-flex w-100">
        <div className="mb-2 w-50">
          <div className="bx--label">{t('saluation')}</div>
          <DropDownBox
            title=""
            mainClassName="bx--form-item mb-0"
            titleClass=""
            labelClass=""
            required
            disabled={disabled}
            custom
            className={`w-100 ${isSaluationValid ? 'invalid' : ''}`}
            options={saluationListArr}
            placeholder={t('saluation')}
            onChange={(value, e, name) => handleDropdownChange(value, e, name)}
            name="saluationVal"
            selectedValue={saluationVal}
          />
          {
            isSaluationValid === true && (
              <div className="invalid-msg">
                {t('offerMsg4')}
              </div>
            )
          }
        </div>
        <div className="ml-2 mt-1" style={{ minWidth: '215px' }}>
          <TextInput
            className="mb-2"
            name="firstname"
            id="firstname"
            labelText={t('firstName')}
            value={firstname}
            disabled={disabled}
            placeholder="Jane"
            invalid={isFirstNameValid}
            invalidText={isFirstNameValidText}
            onChange={e => handleChange(e)}
          />
        </div>
        <div style={{ minWidth: '215px' }} className="ml-lg-3 mt-1">
          <TextInput
            className="mb-2"
            name="lastname"
            id="lastname"
            disabled={disabled}
            invalid={isLastNameValid}
            invalidText={isLastNameValidText}
            value={lastname}
            labelText={t('lastName')}
            placeholder="Doe"
            onChange={e => handleChange(e)}
          />
        </div>
      </div>
      <div>
        <TextInput
          className="mb-2"
          name="email"
          id="email"
          labelText={t('email')}
          invalid={isEmailValid}
          invalidText={isEmailValidText}
          value={email}
          disabled={disabled || disableEmail}
          placeholder={t('emailPlaceholder')}
          onChange={e => handleChange(e)}
        />
      </div>
      <div className="d-flex">
        <div className="mt-2 mr-2 w-25">
          <Select
            name="countryCode"
            labelText={<span>{t('country')} {t('code')}</span>}
            id="countryCode"
            disabled={disabled}
            onChange={e => handleChange(e)}
            value={countryCode || {}}
            invalid={isValidCountryCode}
            invalidText={isValidCountryCodeValue}
          >
            <SelectItem text={t('select')} />
            {
              countries && Array.isArray(countries) && countries.map((b, i) => (
                <SelectItem key={b.countryCode} text={`${b.countryFull}(+${b.countryCode})`} value={`${b.countryCode}`} />
              ))
            }
          </Select>
        </div>
        <div className="mt-2 w-75">
          <TextInput
            className="mb-2"
            name="phoneNumber"
            id="phoneNumber"
            value={phoneNumber === null ? '' : phoneNumber}
            labelText="Contact Number"
            disabled={disabled}
            invalid={isPhoneNumberValid}
            type="number"
            invalidText={isPhoneNumberValidText}
            placeholder="9876543211"
            onChange={e => handleChange(e)}
          />
        </div>
      </div>
      {
        showSecondaryPhone && (
          <div>
            <TextInput
              className="mb-2"
              name="secondaryphone"
              id="secondaryphone"
              value={secondaryphone}
              labelText="Secondary Phone"
              disabled={disabled}
              type="number"
              placeholder="########"
              onChange={e => handleChange(e)}
            />
          </div>
        )
      }
      <div>
        <TextInput
          className="mb-2"
          name="designation"
          id="designation"
          value={designation}
          labelText={t('designation')}
          invalid={isValidDesignation}
          disabled={disabled}
          invalidText={isValidDesignationVal}
          placeholder={t('designation')}
          onChange={e => handleChange(e)}
        />
      </div>
    </Fragment>
  );
};

export default (withTranslation()(ContactDetails));
