import { withTranslation } from 'react-i18next';
import React from 'react';
import { SkeletonText } from 'carbon-components-react';

const ColorLegend = ({
  showTitle, className, t,
}) => (
  <div>
  <ul
    className={className?className:''}
    style={{
      display: 'flex',
      listStyleType: 'none',
      marginTop: '0px',
      marginLeft: '0px',
      paddingLeft: '0px'
    }}
  >
    {showTitle && (<li>
      Gender Balance: 
    </li>)}
    <li>
      <span className="bg-pink small-cir" />{t('belowAvg')}
    </li>
    <li>
      <span className="bg-yellow small-cir" />{t('average')}
    </li>
    <li>
      <span className="bg-green small-cir" />{t('good')}
    </li>
    <li>
      <span className="bg-darkgreen small-cir" />{t('excellent')}
    </li>   
  </ul>
  <ul
    className={className?className:''}
    style={{
      display: 'flex',
      listStyleType: 'none',
      marginTop: '0px',
      marginLeft: '0px',
      paddingLeft: '0px'
    }}
  >
    <li>
      <SkeletonText className="xpa-circle--skeleton small-cir"/>{t('skeletonMsg')}
    </li>    
  </ul>  
  </div>
);
export default ColorLegend;
