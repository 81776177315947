import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { xSearchCompanies, xCreateCompany } from 'actions/xpa';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { EditIcon, AddIcon, TrashIcon } from 'components/common/Icons';
import { count } from '@wordpress/wordcount';
import './timeline.css';
import './CandidateDetails.css';
import CandidateBio from './CandidateBio';
import StarRatingComponent from 'react-star-rating-component';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import GeipDetails from 'containers/common/campusLogin/CandidateRegister/geipDetails';
import { updateSkills } from 'components/roboroy/common/candidateProfile';
import { Mixpanel } from 'components/common/Mixpanel';
import PercentageSlider from 'components/common/PercentageSlider';
import JobPreferences from 'containers/robocampus/Preferences';
import SkillsAndLanguage from 'containers/common/campusLogin/CandidateRegister/skillsAndLanguage';
import StudentDocuments from 'containers/common/profile/StudentDocuments';
import DocumentsData from 'components/roboroy/DocumentsData';

import {
  Tag,
  Button,
  Modal,
  DatePicker,
  DatePickerInput,
  TextInput,
  TextArea,
  Checkbox,
  Accordion,
  AccordionItem,
  InlineNotification,
  Pagination,
  SelectItem,
  Select,
} from 'carbon-components-react';

import {
  REQUEST_ALL_SKILLS,
  REQUEST_CREATE_NEW_COMPANY,
  REQUEST_ALL_COMPANIES,
  REQUEST_CREATE_NEW_JOB_TITLE,
  REQUEST_CREATE_NEW_SKILLS,
} from 'actions/talentSearch/actionTypes';

import {
  fetchAll,
  fetchCompanyId,
  createNewOption,
} from 'actions/talentSearch/newJob';

import { getCandidateProfile } from 'actions/talentSearch/candidatesList';

import {
  getAllDropDownData,
  getFewDropDownData,
  getRowDataById,
  deleteProfileSpecificInfo,
  updateProfileSpecificInfo,
  createProfileSpecificInfo,
  manageSkill,
  updateProfile,
} from 'actions/candidate/CandidateDetails';

import {
  getFinlandProfile,
  updateFinlandProfile,
  createFinlandProfileSpecificInfo,
  updateFinlandProfileSpecificInfo,
  deleteFinlandProfileSpecificInfo,
} from 'actions/candidate/businessFinland';

import { getAllCompanies } from '../../actions/admin/CompanyAndOffice';
import { getSkillsPreferredList } from 'actions/campus/internCandidate';
import {
  REQUEST_ALL_HOURTYPE,
  REQUEST_ALL_QUALTYPE,
  REQUEST_ALL_MAJOR,
  REQUEST_ALL_COUNTRY,
  REQUEST_FEW_SCHOOLS,
  REQUEST_ALL_QUALIFICATION,
  REQUEST_CANDIDATE_JOBS,
  REQUEST_CREATE_NEW_SCHOOL,
  REQUEST_CREATE_NEW_QUALIFICATION,
  REQUEST_FEW_CERTIFICATES,
  REQUEST_FEW_CERTTYPE,
  REQUEST_FEW_ORGANIZATION,
  REQUEST_CREATE_NEW_CERTIFICATE,
  REQUEST_CREATE_NEW_ORGANIZATION,
  REQUEST_FEW_ETHNICITIES,
  REQUEST_FEW_ACTIVITIES,
  REQUEST_FEW_ACTIVITIESTYPES,
  REQUEST_CREATE_NEW_ACTIVITYTYPE,
  REQUEST_CREATE_NEW_HOBBY,
  REQUEST_FEW_HOBBIES,
  REQUEST_FEW_HOBBYTYPES,
  REQUEST_POST_PREFERENCE_RANKS,
  REQUEST_UPDATE_PREFERENCE_RANKS,
  REQUEST_POST_WORK_PREFERENCE,
  REQUEST_UPDATE_WORK_PREFERENCE,
  REQUEST_POST_PERSONHOBBIES,
  REQUEST_UPDATE_PERSONHOBBIES,
  REQUEST_POST_PERSONACTIVITIES,
  REQUEST_UPDATE_PERSONACTIVITIES,
  REQUEST_DELETE_PREFERENCE_RANKS,
  REQUEST_DELETE_WORK_PREFERENCE,
  REQUEST_DELETE_PERSONHOBBIES,
  REQUEST_DELETE_PERSONACTIVITIES,
} from '../../actions/actionTypes';
import {
  getAllCountries,
} from 'actions/admin/CompanyAndOffice';

const jobPrefs = {
  cityRank: 'City Preference',
  industryRank: 'Industry Preference',
  individualRank: 'Individual Preference',
  jobFunctionRank: 'Job Function Preference',
};

class CandidateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manageSkills: false,
      editSummary: false,
      eduModal: '',
      deleteModal: '',
      expModal: '',
      certModal: '',
      projModal: '',
      actModal: '',
      hobModal: '',
      deleteExp: false,
      profileSkills: [],
      selectedSkills: [],
      selectedEducation: {},
      selectedExperience: {},
      selectedCertification: {},
      selectedProject: {},
      selectedActivity: {},
      selectedHobby: {},
      summary: '',
      showBio: false,
      showSkills: true,
      showEdu: true,
      showExp: true,
      showCert: true,
      showProj: true,
      showAct: true,
      showHob: true,
      activePage: 0,
      limit: 10,
      alreadyExist: '',
      languages: [],
      languagesSpeak: [],
      languagesWrite: [],
      preferenceBackoffice: 0,
      preferenceTaskOriented: 0,
      preferenceTryNew: 0,
      cities: [],
      IndustryPreferences: [],
      JobPreferences: [],
      CityPreferences: [],
      jobFunctionPreferences: [],
      arrSkills: [],
      showSameDateError: false,
      cityPreferences: [],
    };
  }

  handlePaginationChange = ({ page, pageSize }) => {
    let { activePage = 0, limit } = this.state;
    if (page > 1) {
      activePage = (page - 1) * pageSize;
      limit = activePage + pageSize;
    } else {
      activePage = 0;
      limit = 10;
    }
    this.setState({ activePage, limit });
  };

  componentDidMount() {
    let {
      profileId = '',
      getCandidateProfile,
      candidateProfile: { profile = {}, skills = [] } = {},
      match:{
        params
      } = {},
      user:{
        roleId,
        profileId: userProfileId
      } = {} 
    } = this.props || {};
    const { profileId: paramsProfileId } = params || {};
    if(!profileId){
      profileId = paramsProfileId;
    }
    if(roleId === 3){
      profileId = userProfileId;
    }
    // if (!this.props.candidateProfile || this.props.candidateProfile === null || Object.keys(this.props.candidateProfile).length === 0
    //   || (profileId !== null && profileId !== '') && (!profile.profileId && profile.profileId === null)) {
    //   getCandidateProfile(profileId).then(() => {
    //     this.setSummary();
    //   });
    // } else this.setSummary();
    const preferredVal = true;
    let selectedSkills = [];
    this.props.getSkillsPreferredList(preferredVal).then(res => {
      if (res && !res.error) {
        const { preferredSkills } = this.props;
        const options =
          preferredSkills &&
          Array.isArray(preferredSkills) &&
          preferredSkills.length > 0 &&
          preferredSkills.map(vals => {
            const { skillId, skillName } = vals || {};
            const data = {
              label: skillName,
              value: skillId,
            };
            return data;
          });
        this.setState({
          arrSkills: options,
        }, () => {
          this.props.getAllCountries();
        });
      }
    });
    if (profileId) {
      getCandidateProfile(profileId).then(() => {
        this.setSummary();
      });
    }
  }

  setSummary = () => {
    const {
      candidateProfile: {
        profile = {},
        skills = [],
        languages: langs = [],
        studentpreferences,
        citypreferences = null,
        industrypreferences = null,
        jobfunctions = null,
      } = {},
    } = this.props || {};
    if (Array.isArray(skills)) this.setRows();
    const selected =
      langs &&
      Array.isArray(langs) &&
      langs.length > 0 &&
      langs.filter(i => i.languageId && i.languageId !== null);
    const languages =
      Array.isArray(selected) &&
      selected.map(({ rating = null, languagetype = {}, language = {} }) => ({
        language: language.languageFull,
        rating,
        type: languagetype.langType,
      }));
    const languagesSpeak =
      languages &&
      Array.isArray(languages) &&
      languages.length > 0 &&
      languages.filter(
        o => typeof o.type === 'string' && o.type.toLowerCase() === 'verbal'
      );
    const languagesWrite =
      languages &&
      Array.isArray(languages) &&
      languages.length > 0 &&
      languages.filter(
        o => typeof o.type === 'string' && o.type.toLowerCase() === 'written'
      );
    const {
      preferenceBackoffice,
      preferenceTaskOriented,
      preferenceTryNew,
      cityRank = null,
      individualRank = null,
      industryRank = null,
      jobFunctionRank = null,
    } = (studentpreferences && studentpreferences[0]) || [];
    const data = {
      cityRank,
      individualRank,
      industryRank,
      jobFunctionRank,
    };
    const sortedVals = Object.keys(data).sort(
      (a, b) => Number(data[a]) - Number(data[b])
    );
    const jobPreferences = sortedVals.map(one => jobPrefs[one]);
    this.setState({
      languages,
      languagesSpeak,
      languagesWrite,
      preferenceBackoffice: Number(preferenceBackoffice),
      preferenceTaskOriented: Number(preferenceTaskOriented),
      preferenceTryNew: Number(preferenceTryNew),
      jobPreferences,
    });
    if (Array.isArray(industrypreferences) && industrypreferences.length > 0) {
      const IndustryPreferences = industrypreferences
        .filter(
          ({ industry: { industryName = '' } = {} } = {}) =>
            industryName !== null && industryName.length > 0
        )
        .map(({ industry: { industryName = '' } = {} } = {}) => industryName);
      this.setState({ IndustryPreferences });
    }
    if (Array.isArray(jobfunctions) && jobfunctions.length > 0) {
      const jobFunctionPreferences = jobfunctions.map(res => {
        const { jobfunction: { functionName } = {} } = res || {};
        return functionName;
      });
      // .filter(({ jobfunction: { functionName = '' } = {} } = {}) => functionName !== null && functionName.length > 0)
      // .map(({ jobfunction: { functionName = '' } = {} } = {}) => functionName);
      this.setState({ jobFunctionPreferences });
    }
    if (Array.isArray(citypreferences) && citypreferences.length > 0) {
      const cityPreferences = citypreferences.map(cit => {
        const { cities: { cityName } = {} } = cit || {};
        return cityName;
      });
      this.setState({ cityPreferences });
    }
    this.setState({ summary: profile !== null ? profile.summary : '' });
  };

  isValid = (info, type) => {
    let data = info || {};
    if (type === 'number')
      return info && info !== null && info.toString().length > 0 ? true : false;
    if (type === 'string')
      return info && info !== null && info.length > 0 ? true : false;
    if (type === 'array')
      data = info && Array.isArray(info) ? info[0] || {} : {};
    const { value = null, label = null } = data;
    if (value !== null && label !== null && value !== '' && label !== '')
      return true;
    else return false;
  };

  searchTimerSkill = null;
  // Manage skills actions
  onSkillsInputChange = (value, cb) => {
    const { preferredSkills } = this.props;
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (this.searchTimerSkill) {
      clearTimeout(this.searchTimerSkill);
    }
    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.searchTimerSkill = setTimeout(
        () =>
          this.props
            .fetchAll({
              actionType: REQUEST_ALL_SKILLS,
              apiName: 'skills',
              paramName: 'skillName',
              value: _.toLower(value),
            })
            .then(res => {
              const options =
                Array.isArray(res) &&
                res.map(one => ({ label: one.skillName, value: one.skillId }));
              this.setState({
                arrSkills: options,
              });
              if (cb && typeof cb === 'function') cb(options);
            }),
        1000
      );
    }
  };

  handleSkillDropdownChange = (name, selectedOption) => {
    let difference = [];
    const {
      candidateProfile: { profile: { profileId = '' } = {} },
    } = this.props;
    if (
      !this.state[name] ||
      !Array.isArray(this.state[name]) ||
      !selectedOption ||
      !Array.isArray(selectedOption)
    )
      return;
    if (this.state[name].length > selectedOption.length) {
      difference = this.state[name].filter(x => !selectedOption.includes(x));
      // console.log('removed skill', difference);
      const link = `${profileId}/skill/${
        difference[0].value ? difference[0].value : ''
      }`;
      this.props.manageSkill('DELETE', link);
    } else {
      difference = selectedOption.filter(x => !this.state[name].includes(x));
      const { label = '', value = '' } = difference[0]||{};
      // console.log('added skill', difference);
      const link = `${profileId}/skill`;
      const skill = { skillName: label, skillId: value };
      this.props.manageSkill('POST', link, skill);
    }
    const profileSkills = selectedOption.map(one => ({
      skill: {
        skillId: one.value,
        skillName: one.label,
      },
      skillId: one.value.toString(),
    }));
    this.setState({ [name]: selectedOption, profileSkills });
  };

  onManageSkills = skills => {
    const selectedSkills =
      Array.isArray(skills) &&
      skills.map(one => {
        const temp = one.skill ? one.skill : one;
        return {
          label: temp.skillName,
          value: one.personSkillId,
        };
      });
    this.setState({
      profileSkills: skills,
      selectedSkills,
      manageSkills: true,
    });
  };

  onSaveSkills = skills => {
    const { candidateProfile } = this.props;
    const { profile } = candidateProfile || {};
    const { profileId } = profile || {};
    const profileSkills = skills.map(one => ({
      skill: {
        skillId: one.value,
        skillName: one.label,
      },
      skillId: one.value.toString(),
    }));
    this.setState({ manageSkills: false }, () => {
      updateSkills(candidateProfile, profileSkills);
      this.props.getCandidateProfile(profileId);
    });
  };
  // =======================================================

  onInputCompanyDropdown = value => {
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      if (value.length >= 4) {
        this.props.fetchAll({
          actionType: REQUEST_ALL_COMPANIES,
          apiName: 'Companies/autoComplete',
          paramName: 'companyName',
          value,
        });
      }
    }
    return value;
  };
  // ========================================================

  // EDUCATION Add Edit delete
  onAddEdu = event => {
    const {
      target: { name = '' },
    } = event;
    const selectedEducation = {
      schoolValue: {},
      eduTypeValue: {},
      qualificationTypeValue: {},
      schoolName: '',
      hourType: '',
      qualificationType: '',
      qualificationName: '',
      qualificationValue: {},
      majorValue: {},
      region: '',
      countryValue: {},
      majorName: '',
      startDate: '',
      endDate: '',
      gpa: '',
      modalError: false,
    };
    this.setState({ eduModal: 'add', selectedEducation }, () => {
      this.onInputDropdown('', 'qualtype');
      this.onInputDropdown('', 'hourType');
    });
  };

  getSelectedEdu = arrayIndex => {
    const {
      candidateProfile: { education = [] },
    } = this.props;
    if (typeof arrayIndex === 'number' && education.length > 0) {
      const slectedEdu = education.filter(
        (obj, index) => index === arrayIndex
      )[0];
      const {
        school: {
          schoolName = '',
          schoolId = null,
          location: {
            country: { countryFull = '', countryId = '' } = {},
            locationName = '',
          } = {},
        } = {},
        qualificationtype,
        hourtype,
        qualification: { qualificationName = '', qualId = null } = {},
        major: { majorName = '', majorId = '' } = {},
        startDate,
        endDate,
        gpa = '',
        personQualId = '',
      } = slectedEdu;
      return {
        schoolValue: { label: schoolName, value: schoolId },
        schoolName,
        hourtype: hourtype && hourtype !== null ? hourtype.typeName : '',
        eduTypeValue: {
          label:
            hourtype && hourtype.typeName !== null ? hourtype.typeName : '',
          value: hourtype && hourtype.typeId ? hourtype.typeId : '',
        },
        qualificationType:
          qualificationtype && qualificationtype.typeName !== null
            ? qualificationtype.typeName
            : '',
        qualificationTypeValue: {
          label:
            qualificationtype && qualificationtype.typeName !== null
              ? qualificationtype.typeName
              : '',
          value:
            qualificationtype && qualificationtype.typeId !== null
              ? qualificationtype.typeId
              : '',
        },
        qualificationName,
        qualificationValue: { label: qualificationName, value: qualId },
        majorName,
        majorValue: { label: majorName, value: majorId },
        region: locationName,
        countryValue: { label: countryFull, value: countryId },
        startDate,
        endDate,
        gpa,
        personQualId,
      };
    }
  };

  isValidEdu = (data) => {
    const {
      qualificationTypeValue = {},
      schoolValue = {},
      eduTypeValue = {},
      countryValue = {},
      startDate = '',
      isCurrentSchool = false,
      endDate = '',
    } = data;
    const {
      schoolName,
      region,
      eduCountryFull,
      qualTypeName,
      hourType,
    } = this.state;
    if (
      // qualificationTypeValue === null ||
      // schoolValue === null ||
      // eduTypeValue === null ||
      // countryValue === null ||
      // qualificationTypeValue.label === undefined ||
      // schoolValue.label === undefined ||
      // eduTypeValue.label === undefined ||
      // countryValue.label === undefined ||
      // qualificationTypeValue.label === '' ||
      // schoolValue.label === '' ||
      // eduTypeValue.label === '' ||
      // countryValue.label === '' ||
      startDate === null ||
      startDate === '' ||
      region === '' ||
      region === null ||
      region === undefined || !schoolName || !eduCountryFull || !qualTypeName || !hourType
    )
      return false;
    return true;
  };

  onEditEdu = (e, edu) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedEducation = this.getSelectedEdu(arrayIndex);
    // console.log(selectedEducation);
    this.setState(
      {
        selectedEducation,
        eduModal: 'edit',
      },
      () => {
        this.onInputDropdown('', 'qualtype');
        this.onInputDropdown('', 'hourType');
      }
    );
  };

  onDeleteEdu = (e, edu) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedEducation = this.getSelectedEdu(arrayIndex);
    this.setState({ deleteModal: 'edu', selectedEducation });
  };
  // =======================================================

  // Certifications Add Edit delete
  onAddCert = event => {
    const {
      target: { name = '' },
    } = event;
    const selectedCertification = {
      certificatetypeValue: { label: '', value: '' },
      certificationType: '',
      certFullValue: { label: '', value: '' },
      certFull: '',
      certShort: '',
      organizationValue: { label: '', value: '' },
      organization: '',
      startDate: '',
      endDate: '',
      region: '',
    };
    this.setState({ certModal: 'add', selectedCertification });
  };

  getSelectedCert = arrayIndex => {
    const {
      candidateProfile: { certifications = [] },
    } = this.props;
    if (typeof arrayIndex === 'number' && certifications.length > 0) {
      const selectedCert = certifications.filter(
        (obj, index) => index === arrayIndex
      )[0];
      const {
        location = {},
        certificate = {},
        personCertId = '',
      } = selectedCert;
      const {
        certificatetype: { typeId = null, typeName = '' } = {},
        organization: { organizationName = '', organizationId = '' } = {},
        certFull = '',
        certShort = '',
        certId = null,
      } = certificate;
      const region = location.locationName;
      return {
        certificatetypeValue: { label: typeName, value: typeId },
        certificationType: typeName,
        certFullValue: { label: certFull, value: certId },
        certFull,
        certShort,
        organizationValue: { label: organizationName, value: organizationId },
        organization: organizationName,
        region,
        personCertId,
      };
    }
  };

  onEditCert = e => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedCertification = this.getSelectedCert(arrayIndex);
    // console.log(selectedCertification);
    this.setState({
      selectedCertification,
      certModal: 'edit',
    });
  };

  isValidCert = data => {
    let { certFullValue = {}, certShort = '', region = '', startDate,
    endDate, } = data;
    if (certShort === '' && certFullValue.certShort)
      certShort = certFullValue.certShort;
    if (
      certFullValue === null ||
      // certShort === null ||
      // certShort === '' ||
      certFullValue.label === '' ||
      certFullValue.label === undefined ||
      startDate === '' ||
      startDate === '' ||
      startDate === undefined
    )
      return false;
    return true;
  };

  onDeleteCert = (e, edu) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedCertification = this.getSelectedCert(arrayIndex);
    // console.log(arrayIndex, edu);
    this.setState({ deleteModal: 'cert', selectedCertification });
  };
  // =======================================================

  // Project Add Edit delete
  onAddProj = event => {
    const {
      target: { name = '' },
    } = event;
    // console.log(name);
    const selectedProject = {
      projectTitle: '',
      projectDesc: '',
      startDate: '',
      endDate: '',
    };
    this.setState({ projModal: 'add', selectedProject });
  };

  getSelectedProj = arrayIndex => {
    const {
      candidateProfile: { projects = [] },
    } = this.props;
    if (typeof arrayIndex === 'number' && projects.length > 0) {
      const selectedProj = projects.filter(
        (obj, index) => index === arrayIndex
      )[0];
      const {
        projectTitle = '',
        projectDesc = '',
        profileId = '',
        startDate = '',
        endDate = '',
        projectId = '',
      } = selectedProj;
      return {
        projectTitle,
        projectDesc,
        profileId,
        startDate,
        endDate,
        projectId,
      };
    }
  };

  isValidProj = data => {
    const { projectTitle = '' } = data;
    if (projectTitle === null || projectTitle === '') return false;
    return true;
  };

  onEditProj = e => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedProject = this.getSelectedProj(arrayIndex);
    this.setState({
      selectedProject,
      projModal: 'edit',
    });
  };

  onDeleteProj = (e, edu) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedProject = this.getSelectedProj(arrayIndex);
    // console.log(arrayIndex, selectedProject);
    this.setState({ deleteModal: 'proj', selectedProject });
  };
  // =======================================================

  // Experience Add Edit delete
  onAddExp = event => {
    const {
      target: { name = '' },
    } = event;
    // console.log(name);
    const selectedExperience = {
      jobTitle: '',
      jobValue: { label: '', value: '' },
      companyName: '',
      companyValue: { label: '', value: '' },
      countryFull: '',
      countryValue: { label: '', value: '' },
      region: '',
      experienceDescription: '',
      startDate: '',
      endDate: '',
    };
    this.setState({ expModal: 'add', selectedExperience });
  };

  getSelectedExp = arrayIndex => {
    const {
      candidateProfile: { workexperience = [] },
    } = this.props;
    if (typeof arrayIndex === 'number' && workexperience.length > 0) {
      const selectedExp = workexperience.filter(
        (obj, index) => index === arrayIndex
      )[0];
      const {
        job: {
          jobname: { nameName = '', nameId = '' } = {},
          country: { countryFull = '', countryId = null } = {},
          region = '',
          company: { companyName = '', companyId = null } = {},
        } = {},
        startDate,
        endDate,
        expDesc = '',
        experienceId = '',
      } = selectedExp;
      return {
        jobTitle: nameName,
        jobValue: { label: nameName, value: nameId },
        companyName,
        companyValue: { label: companyName, value: companyId },
        countryFull,
        countryValue: { label: countryFull, value: countryId },
        region,
        expDesc,
        startDate,
        endDate,
        experienceId,
      };
    }
    return {};
  };

  isValidExp = data => {
    const { companyValue = {}, region = {}, countryValue = {} } = data;
    const { jobTitle,           expCountryFull,
      companyName, } = this.state;
    if (
      jobTitle === null ||
      !jobTitle ||
      jobTitle === undefined ||
      // companyValue === null ||
      region === null ||
      !companyName || !expCountryFull ||
      // companyValue.label === undefined ||
      // countryValue === '' ||
      // countryValue.label === '' ||
      // countryValue.label === undefined ||
      // companyValue.label === '' ||
      region === ''
    )
      return false;
    return true;
  };

  onEditExp = (e, exp) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedExperience = this.getSelectedExp(arrayIndex);
    this.setState({
      expModal: 'edit',
      selectedExperience,
    });
  };

  onDeleteExp = (e, Exp) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedExperience = this.getSelectedExp(arrayIndex);
    // console.log(arrayIndex, Exp);
    this.setState({ deleteModal: 'exp', selectedExperience });
  };
  // =======================================================

  // =======================================================

  // Activity Add Edit delete
  onAddAct = event => {
    const {
      target: { name = '' },
    } = event;
    // console.log(name);
    const selectedActivity = {
      activityType: '',
      activityValue: { label: '', value: '' },
      startDate: '',
      endDate: '',
      description: '',
    };
    this.setState({ actModal: 'add', selectedActivity }, () =>
      this.props.getAllDropDownData({
        actionType: REQUEST_FEW_ACTIVITIESTYPES,
        apiName: 'activitytypes',
        value: '',
      })
    );
  };

  getSelectedAct = arrayIndex => {
    const {
      candidateProfile: { activities = [] },
    } = this.props;
    if (typeof arrayIndex === 'number' && activities.length > 0) {
      const selectedAct = activities.filter(
        (obj, index) => index === arrayIndex
      )[0];
      const {
        activity: { activityName = '', activityId = '' } = {},
        activitytype: { typeId = '', typeName = '' } = {},
        startDate,
        endDate,
        description,
        personActId,
      } = selectedAct;
      return {
        activityTypeValue: { label: typeName, value: typeId },
        activityValue: { label: activityName, value: activityId, typeId },
        activityName,
        description,
        startDate,
        endDate,
        activityId,
        personActId,
      };
    }
    return {};
  };

  isValidAct = data => {
    const {
      activityValue = {},
      description = '',
      startDate = null,
      activityTypeValue = {},
    } = data;
    const {
      activityName,
      activityType,
    } = this.state;
    if (
      !activityName
      // this.isValid(activityTypeValue, 'object') === false ||
      // description === null ||
      // description === '' ||
      // startDate === '' ||
      // startDate === null
    )
      return false;
    return true;
  };

  onEditAct = (e, act) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedActivity = this.getSelectedAct(arrayIndex);
    this.setState(
      {
        actModal: 'edit',
        selectedActivity,
      },
      () =>
        this.props.getAllDropDownData({
          actionType: REQUEST_FEW_ACTIVITIESTYPES,
          apiName: 'activitytypes',
          value: '',
        })
    );
  };

  onDeleteAct = (e, Act) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedActivity = this.getSelectedAct(arrayIndex);
    // console.log(arrayIndex, Exp);
    this.setState({ deleteModal: 'act', selectedActivity });
  };
  // =======================================================

  // =======================================================

  // Hobbies Add Edit delete
  onAddHob = event => {
    const {
      target: { name = '' },
    } = event;
    // console.log(name);
    const selectedHobby = {
      hobbyValue: { label: '', value: '' },
      hobbyTypeValue: { label: '', value: '' },
      playValue: { label: '', value: '' },
    };
    this.setState({ hobModal: 'add', selectedHobby }, () =>
      this.props.getAllDropDownData({
        actionType: REQUEST_FEW_HOBBYTYPES,
        apiName: 'Hobbytypes',
        value: '',
      })
    );
  };

  getSelectedHob = arrayIndex => {
    const {
      candidateProfile: { hobbies = [] },
    } = this.props;
    if (typeof arrayIndex === 'number' && hobbies.length > 0) {
      const selectedHob = hobbies.filter(
        (obj, index) => index === arrayIndex
      )[0];
      const {
        hobby: {
          hobbyId = '',
          hobby = '',
          playground: { typeId = '', typeName = '' } = {},
        },
        personHobbyId = '',
        hobbytype: { hobbyTypeId = '', hobbyType = '' } = {},
      } = selectedHob;
      return {
        hobbyId,
        hobbyValue: { value: hobbyId, label: hobby, hobbyTypeId },
        hobbyTypeValue: { value: hobbyTypeId, label: hobbyType },
        personHobbyId,
        playValue: { value: typeId, label: typeName },
      };
    }
    return {};
  };

  isValidHob = data => {
    const { hobbyTypeValue = {}, hobbyValue = {}, playValue = {} } = data;
    if (
      // this.isValid(hobbyTypeValue) === false ||
      !this.state.hobbyName
      // this.isValid(playValue) === false
    )
      return false;
    return true;
  };

  onEditHob = (e, act) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedHobby = this.getSelectedHob(arrayIndex);
    this.setState({ hobModal: 'edit', selectedHobby }, () =>
      this.props.getAllDropDownData({
        actionType: REQUEST_FEW_HOBBYTYPES,
        apiName: 'Hobbytypes',
        value: '',
      })
    );
  };

  onDeleteHob = (e, Act) => {
    const arrayIndex = Number(e.currentTarget.getAttribute('name'));
    const selectedHobby = this.getSelectedHob(arrayIndex);
    // console.log(arrayIndex, Exp);
    this.setState({ deleteModal: 'hob', selectedHobby });
  };
  // =======================================================

  // close action for all modals
  closeModal = modalName => {
    // switch (modalName) {
    //   case 'eduModal': {
    //     this.setState({ [modalName]: '', modalError: false });
    //     break;
    //   }
    //   case 'deleteModal': {
    //     this.setState({ [modalName]: '' });
    //     break;
    //   }
    //   case 'expModal': {
    //     this.setState({ [modalName]: '' });
    //     break;
    //   }
    //   case 'certModal': {
    //     this.setState({ [modalName]: '' });
    //     break;
    //   }
    //   case 'projModal': {
    //     this.setState({ [modalName]: '' });
    //     break;
    //   }
    //   default: break;
    // }
    this.setState({ [modalName]: '', modalError: false, alreadyExist: '' });
  };
  // =======================================================

  // Secondary button action for all modals
  secSubmitModal = modalName => {
    switch (modalName) {
      case 'eduModal': {
        this.setState({
          [modalName]: '',
          selectedEducation: {},
          modalError: false,
        });
        break;
      }
      case 'deleteModal': {
        this.setState({ [modalName]: '' });
        break;
      }
      case 'expModal': {
        this.setState({
          [modalName]: '',
          selectedExperience: {},
          modalError: false,
        });
        break;
      }
      case 'certModal': {
        this.setState({
          [modalName]: '',
          selectedCertification: {},
          modalError: false,
        });
        break;
      }
      case 'projModal': {
        this.setState({
          [modalName]: '',
          selectedProject: {},
          modalError: false,
        });
        break;
      }
      case 'actModal': {
        this.setState({
          [modalName]: '',
          selectedActivity: {},
          modalError: false,
        });
        break;
      }
      case 'hobModal': {
        this.setState({
          [modalName]: '',
          selectedHobby: {},
          modalError: false,
        });
        break;
      }
      default:
        break;
    }
    this.setState({ alreadyExist: '', modalError: false });
  };
  // =======================================================

  // Primary button action for all modals
  primeSubmitModal = modalName => {
    const {
      user: { roleId, profileId: userProfId },
    } = this.props;
    const {
      candidateProfile: { profile: { profileId: candProfId } = {} } = {},
    } = this.props;
    let profileId = null;
    if (roleId && Number(roleId) === 3) {
      profileId = userProfId;
    } else {
      profileId = candProfId;
    }
    const {
      selectedEducation,
      selectedCertification,
      selectedExperience,
      selectedProject,
      selectedActivity,
      selectedHobby,
    } = this.state;
    const {
      createProfileSpecificInfo,
      updateProfileSpecificInfo,
      deleteProfileSpecificInfo,
      getCandidateProfile,
      createFinlandProfileSpecificInfo,
      updateFinlandProfileSpecificInfo,
      deleteFinlandProfileSpecificInfo,
    } = this.props;
    const manageType = this.state[modalName];
    const data = {
      profileId,
      infoType: '',
      infoTypeId: '',
      infoData: {},
      infoAction: '',
    };
    // console.log(manageType, modalName, this.state);
    switch (modalName) {
      case 'eduModal': {
        const {
          schoolName,
          region,
          eduCountryFull,
          qualTypeName,
          hourType,
        } = this.state;
        if (this.isValidEdu(selectedEducation) === false)
          return this.setState({ modalError: true });
        this.setState({ [modalName]: '', modalError: false }, () => {
          data.infoType = 'education';
          const {
            schoolValue = {},
            qualificationTypeValue = {},
            eduTypeValue = {},
            qualificationValue = {},
            majorValue = {},
            startDate = '',
            endDate = '',
            // region = '',
            gpa = '',
            countryValue = {},
            personQualId = '',
          } = selectedEducation;
          data.infoData = {
            schoolName,
            qualificationType: qualTypeName || '',
            hourType,
            qualification: qualificationValue.label || '',
            major: majorValue.label || '',
            startDate,
            endDate,
            gpa,
            country: eduCountryFull || '',
            region,
          };
          if (manageType === 'add') {
            createProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          } else if (manageType === 'edit') {
            data.infoTypeId = personQualId;
            updateProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          }
        });
        break;
      }
      case 'deleteModal': {
        this.setState({ [modalName]: '' });
        switch (manageType) {
          case 'edu':
            data.infoType = 'education';
            data.infoTypeId = selectedEducation.personQualId;
            break;
          case 'exp':
            data.infoType = 'experience';
            data.infoTypeId = selectedExperience.experienceId;
            break;
          case 'cert':
            data.infoType = 'certification';
            data.infoTypeId = selectedCertification.personCertId;
            break;
          case 'proj':
            data.infoType = 'projects';
            data.infoTypeId = selectedProject.projectId;
            break;
          case 'act':
            data.infoType = 'personactivities';
            data.infoTypeId = selectedActivity.personActId;
            return deleteFinlandProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          case 'hob':
            data.infoType = 'personhobbies';
            data.infoTypeId = selectedHobby.personHobbyId;
            return deleteFinlandProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          default:
            break;
        }
        deleteProfileSpecificInfo(data).then(() =>
          getCandidateProfile(profileId)
        );
        break;
      }
      case 'expModal': {
        const {
          expCountryFull,
          companyName,
        } = this.state;
        if (this.isValidExp(selectedExperience) === false)
          return this.setState({ modalError: true });
        this.setState({ [modalName]: '', modalError: false }, () => {
          // console.log(selectedExperience, manageType);
          data.infoType = 'experience';
          const {
            region = '',
            companyValue = {},
            startDate = '',
            endDate = '',
            countryValue = {},
            expDesc = '',
            experienceId = '',
          } = selectedExperience;
          const { jobTitle } = this.state;
          data.infoData = {
            companyName,
            jobTitle: jobTitle || '',
            startDate,
            endDate,
            experienceDescription: expDesc,
            country: expCountryFull || '',
            region,
          };
          if (manageType === 'add') {
            createProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          } else if (manageType === 'edit') {
            data.infoTypeId = experienceId;
            updateProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          }
        });
        break;
      }
      case 'certModal': {
        if (this.isValidCert(selectedCertification) === false)
          return this.setState({ modalError: true });
        this.setState({ [modalName]: '', modalError: false }, () => {
          data.infoType = 'certification';
          // console.log(selectedCertification, manageType);
          const {
            region = '',
            certificatetypeValue = {},
            personCertId = '',
            certFullValue = {},
            certShort = '',
            organizationValue = {},
            startDate,
            endDate,
          } = selectedCertification;

          data.infoData = {
            // region,
            // certificationType: certificatetypeValue.label || '',
            certFull: certFullValue.label || '',
            // certShort: certShort,
            organization: (organizationValue && organizationValue.label) || '',
            startDate,
            endDate,
          };
          if (manageType === 'add') {
            createProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          } else if (manageType === 'edit') {
            data.infoTypeId = personCertId;
            updateProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          }
        });
        break;
      }
      case 'projModal': {
        if (this.isValidProj(selectedProject) === false)
          return this.setState({ modalError: true });
        this.setState({ [modalName]: '', modalError: false }, () => {
          data.infoType = 'projects';
          // console.log(selectedProject, manageType);
          const {
            projectTitle = '',
            projectDesc = '',
            projectId = '',
            startDate = '',
            endDate = '',
          } = selectedProject;
          data.infoData = {
            projectTitle,
            projectDesc,
            profileId: Number(profileId),
            startDate,
            endDate,
          };
          if (manageType === 'add') {
            createProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          } else if (manageType === 'edit') {
            data.infoTypeId = projectId;
            updateProfileSpecificInfo(data).then(() =>
              getCandidateProfile(profileId)
            );
          }
        });
        break;
      }
      case 'actModal': {
        const {
          activityName,
          activityType,
        } = this.state;
        if (this.isValidAct(selectedActivity) === false)
          return this.setState({ modalError: true });
        data.infoType = 'personactivities';
        const {
          activityValue = '',
          description = '',
          startDate = null,
          endDate = null,
          personActId = '',
          activityTypeValue = '',
        } = selectedActivity;
        data.infoData = {
          activityName,
          activityTypeName: activityType,
          description,
          profileId,
          startDate: startDate == ''?null : startDate,
          endDate: endDate == ''?null : endDate,
        };
        if (manageType === 'add') {
          data.infoAction = REQUEST_POST_PERSONACTIVITIES;
          createFinlandProfileSpecificInfo(data).then(res => {
            if (res.error) {
              this.setState({ alreadyExist: 'act' });
            } else
              getCandidateProfile(profileId).then(() =>
                this.setState({ [modalName]: '', modalError: false })
              );
          });
        } else if (manageType === 'edit') {
          data.infoAction = REQUEST_UPDATE_PERSONACTIVITIES;
          data.infoTypeId = personActId;
          updateFinlandProfileSpecificInfo(data).then(res => {
            if (res.error) {
              this.setState({ alreadyExist: 'act' });
            } else
              getCandidateProfile(profileId).then(() =>
                this.setState({ [modalName]: '', modalError: false })
              );
          });
        }
        break;
      }
      case 'hobModal': {
        const {
          hobbyName
        } = this.state;
        if (this.isValidHob(selectedHobby) === false)
          return this.setState({ modalError: true });
        data.infoType = 'personhobbies';
        const {
          hobbyValue,
          hobbyTypeValue,
          playValue,
          hobbyId = '',
          personHobbyId,
        } = selectedHobby;
        data.infoData = {
          hobby: hobbyName || '',
          hobbyType: hobbyTypeValue.label || '',
          playground: playValue.label || '',
          profileId: profileId,
        };
        if (manageType === 'add') {
          data.infoAction = REQUEST_POST_PERSONHOBBIES;
          createFinlandProfileSpecificInfo(data).then(res => {
            if (res.error) {
              this.setState({ alreadyExist: 'hob' });
            } else
              getCandidateProfile(profileId).then(() =>
                this.setState({ [modalName]: '', modalError: false })
              );
          });
        } else if (manageType === 'edit') {
          data.infoAction = REQUEST_UPDATE_PERSONHOBBIES;
          data.infoTypeId = personHobbyId;
          updateFinlandProfileSpecificInfo(data).then(res => {
            if (res.error) {
              this.setState({ alreadyExist: 'hob' });
            } else
              getCandidateProfile(profileId).then(() =>
                this.setState({ [modalName]: '', modalError: false })
              );
          });
        }
        break;
      }
      default:
        break;
    }
  };
  // =======================================================

  // To handle all text and text areas
  handleText = e => {
    const {
      target: { name = '', value = '' },
    } = e;
    const finalVal = value && value.toString() && value.trimLeft();
    if (name.indexOf('_') > 0) {
      const trget = name.split('_');
      const stateVal = this.state;
      const chgVal = stateVal[trget[0]];
      chgVal[trget[1]] = finalVal;
      this.setState({ [trget[0]]: chgVal });
    } else {
      this.setState({ [name]: finalVal });
    }
  };
  // =======================================================

  /* For Dropdown list, create and new options start */

  onNewOptionCreate = (params, value) => {
    const {
      candidateProfile: { profile: { profileId } = {} } = {},
    } = this.props;
    const actionData = {
      apiName: '',
      actionType: null,
      data: {},
    };
    const {
      selectedExperience = {},
      selectedEducation = {},
      selectedSkills = [],
      selectedCertification = {},
      selectedProject = {},
      selectedActivity = {},
      selectedHobby = {},
    } = this.state;
    const { createNewOption } = this.props;
    switch (params) {
      case 'companyValue': {
        actionData.apiName = 'Company-base';
        actionData.actionType = REQUEST_CREATE_NEW_COMPANY;
        actionData.data = { companyName: value };
        this.props.xCreateCompany({ companyName: value }).then(data => {
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res.companyId) {
            selectedExperience.companyValue = {
              label: res.companyName,
              value: Number(res.companyId),
            };
            this.setState({ selectedExperience });
          }
        });
        break;
      }
      case 'jobValue': {
        actionData.apiName = 'Jobnames';
        actionData.actionType = REQUEST_CREATE_NEW_JOB_TITLE;
        actionData.data = { nameName: value };
        createNewOption(actionData).then(data => {
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res.nameId) {
            selectedExperience.jobValue = {
              label: res.nameName,
              value: Number(res.nameId),
            };
            this.setState({ selectedExperience });
          }
        });
        break;
      }
      case 'schoolValue': {
        actionData.apiName = 'School-base';
        actionData.actionType = REQUEST_CREATE_NEW_SCHOOL;
        actionData.data = { schoolName: value };
        createNewOption(actionData).then(data => {
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res.schoolId) {
            selectedEducation.schoolValue = {
              label: res.schoolName,
              value: Number(res.schoolId),
            };
            this.setState({ selectedEducation });
          }
        });
        break;
      }
      case 'qualificationValue': {
        actionData.apiName = 'Qualification-base';
        actionData.actionType = REQUEST_CREATE_NEW_QUALIFICATION;
        actionData.data = { qualificationName: value };
        createNewOption(actionData).then(data => {
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res.qualId) {
            selectedEducation.qualificationValue = {
              label: res.qualificationName,
              value: Number(res.qualId),
            };
            this.setState({ selectedEducation });
          }
        });
        break;
      }
      case 'selectedSkills': {
        actionData.apiName = 'Skills';
        actionData.actionType = REQUEST_CREATE_NEW_SKILLS;
        actionData.data = { skillName: value, typeId: 1 };
        createNewOption(actionData).then(data => {
          if (data && data.error) return;
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res.skillId) {
            const link = `${profileId}/skill`;
            const skill = {
              skillName: res.skillName,
              skillId: Number(res.skillId),
            };
            this.props.manageSkill('POST', link, skill).then(r => {
              if (r && !r.error) {
                selectedSkills.push({
                  label: res.skillName,
                  value: Number(res.skillId),
                });
                this.setState({ selectedSkills });
              }
            });
          }
        });
        break;
      }
      case 'certFullValue': {
        actionData.apiName = 'Certificate-base';
        actionData.actionType = REQUEST_CREATE_NEW_CERTIFICATE;
        actionData.data = { certFull: value };
        createNewOption(actionData).then(data => {
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res.certId) {
            selectedCertification.certFullValue = {
              label: res.certFull,
              value: Number(res.certId),
            };
            this.setState({ selectedCertification });
          }
        });
        break;
      }
      case 'organizationValue': {
        actionData.apiName = 'Organization-base';
        actionData.actionType = REQUEST_CREATE_NEW_ORGANIZATION;
        actionData.data = { organizationName: value };
        createNewOption(actionData).then(data => {
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res !== null && res.organizationId) {
            selectedCertification.organizationValue = {
              label: res.organizationName,
              value: Number(res.organizationId),
            };
            this.setState({ selectedCertification });
          }
        });
        break;
      }
      case 'activityValue': {
        actionData.apiName = 'Activities';
        actionData.actionType = REQUEST_CREATE_NEW_ACTIVITYTYPE;
        actionData.data = { activityName: value };
        createNewOption(actionData).then(data => {
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res !== null && res.activityId) {
            selectedActivity.activityValue = {
              label: res.activityName,
              value: Number(res.activityId),
              typeId: null,
            };
            this.setState({ selectedActivity });
          }
        });
        break;
      }
      case 'hobbyValue': {
        actionData.apiName = 'hobbies';
        actionData.actionType = REQUEST_CREATE_NEW_HOBBY;
        actionData.data = { hobby: value };
        createNewOption(actionData).then(data => {
          let res = {};
          if (data.data) res = data.data;
          else res = data;
          if (res !== null && res.hobbyId) {
            selectedHobby.hobbyValue = {
              label: res.hobby,
              value: Number(res.hobbyId),
              hobbyTypeId: null,
            };
            this.setState({ selectedHobby });
          }
        });
        break;
      }
      default:
        break;
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  searchTimerSchVal = null;

  searchTimerCertFull = null;

  searchTimerCompVal = null;

  searchTimerJobVal = null;

  searchTimerOrgVal = null;

  searchTimerQualVal = null;

  searchTimerAct = null;

  searchTimerHob = null;

  searchTimerHobType = null;

  onInputDropdownFew = (value, cb, params) => {
    const actionData = {
      actionType: '',
      apiName: '',
      link: '',
    };
    const { getFewDropDownData, getAllCompanies } = this.props;
    switch (params) {
      case 'schoolValue': {
        actionData.actionType = REQUEST_FEW_SCHOOLS;
        actionData.apiName = 'schools';
        actionData.link = `?search=${encodeURIComponent(value)}`;
        if (this.searchTimerSchVal) {
          clearTimeout(this.searchTimerSchVal);
        }
        if (
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
          value.length > 2
        ) {
          this.searchTimerSchVal = setTimeout(
            () =>
              getFewDropDownData(actionData).then(res => {
                if (res && res.error) return;
                const options = res.map(one => ({
                  label: one.schoolName,
                  value: one.schoolId,
                  countryValue: {
                    label:
                      one &&
                      one.locationId &&
                      one.location &&
                      one.location.country &&
                      one.location.country.countryFull,
                    value: one && one.location && one.location.countryId,
                  },
                }));
                cb(options);
              }),
            1000
          );
        }
        break;
      }
      case 'qualificationValue': {
        actionData.actionType = REQUEST_ALL_QUALIFICATION;
        actionData.apiName = 'qualifications';
        actionData.link = `?search=${encodeURIComponent(value)}`;
        if (this.searchTimerQualVal) {
          clearTimeout(this.searchTimerQualVal);
        }
        if (
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
          value.length > 2
        ) {
          this.searchTimerQualVal = setTimeout(
            () =>
              getFewDropDownData(actionData).then(res => {
                if (res && res.error) return;
                const options = res.map(one => ({
                  label: one.qualificationName,
                  value: one.qualId,
                }));
                cb(options);
              }),
            1000
          );
        }
        break;
      }
      case 'companyValue': {
        actionData.actionType = REQUEST_ALL_COMPANIES;
        actionData.apiName = 'companies-base/companies';
        actionData.paramName = 'companyName';
        actionData.value = value;
        if (this.searchTimerCompVal) {
          clearTimeout(this.searchTimerCompVal);
        }
        if (
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
          value.length > 2
        ) {
          this.searchTimerCompVal = setTimeout(
            () =>
              this.props.xSearchCompanies(value, null, null).then(res => {
                if (res && res.error) return;
                const options = res.map(one => ({
                  label: one.companyName,
                  value: one.companyId,
                }));
                cb(options);
              }),
            1000
          );
        }
        break;
      }
      case 'jobValue': {
        if (this.searchTimerJobVal) {
          clearTimeout(this.searchTimerJobVal);
        }
        this.searchTimerJobVal = setTimeout(
          () =>
            this.props
              .fetchAll({
                actionType: REQUEST_CANDIDATE_JOBS,
                apiName: 'jobnames',
                paramName: 'nameName',
                value,
              })
              .then(res => {
                if (res && res.error) return;
                const options =
                  res &&
                  Array.isArray(res) &&
                  res.map(one => ({ label: one.nameName, value: one.nameId }));
                cb(options);
              }),
          1000
        );
        break;
      }
      case 'certFullValue': {
        actionData.actionType = REQUEST_FEW_CERTIFICATES;
        actionData.apiName = 'certificates';
        actionData.link = `?search=${encodeURIComponent(value)}`;
        if (this.searchTimerCertFull) {
          clearTimeout(this.searchTimerCertFull);
        }
        if (
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
          value.length > 2
        ) {
          this.searchTimerCertFull = setTimeout(
            () =>
              getFewDropDownData(actionData).then(res => {
                if (res && res.error) return;
                const options = res.map(one => ({
                  label: one.certFull,
                  value: one.certId,
                  certShort: one.certShort,
                  organizationId: one.organizationId,
                  typeId: one.typeId,
                  certificatetypeValue: {
                    label: one.certificatetype && one.certificatetype.typeName,
                    value: one.certificatetype && one.certificatetype.typeId,
                  },
                }));
                cb(options);
              }),
            1000
          );
        }
        break;
      }
      case 'organizationValue': {
        actionData.actionType = REQUEST_FEW_ORGANIZATION;
        actionData.apiName = 'organizations';
        actionData.link = `?search=${encodeURIComponent(value)}`;
        if (this.searchTimerOrgVal) {
          clearTimeout(this.searchTimerOrgVal);
        }
        if (
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
          value.length > 2
        ) {
          this.searchTimerOrgVal = setTimeout(
            () =>
              getFewDropDownData(actionData).then(res => {
                if (res && res.error) return;
                const options = res.map(one => ({
                  label: one.organizationName,
                  value: one.organizationId,
                }));
                cb(options);
              }),
            1000
          );
        }
        break;
      }
      case 'activityValue': {
        actionData.actionType = REQUEST_FEW_ACTIVITIES;
        actionData.apiName = 'activities';
        actionData.link = `?searchKey=${encodeURIComponent(value)}`;
        if (this.searchTimerAct) {
          clearTimeout(this.searchTimerAct);
        }
        if (
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
          value.length > 2
        ) {
          this.searchTimerAct = setTimeout(
            () =>
              getFewDropDownData(actionData).then(res => {
                if (res && res.error) return;
                const options = res.map(one => ({
                  label: one.activityName,
                  value: one.activityId,
                  typeId: one.typeId,
                }));
                cb(options);
              }),
            1000
          );
        }
        break;
      }
      case 'hobbyValue': {
        actionData.actionType = REQUEST_FEW_HOBBIES;
        actionData.apiName = 'hobbies';
        actionData.link = `?searchKey=${encodeURIComponent(value)}`;
        if (this.searchTimerHob) {
          clearTimeout(this.searchTimerHob);
        }
        if (
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
          value.length > 2
        ) {
          this.searchTimerHob = setTimeout(
            () =>
              getFewDropDownData(actionData).then(res => {
                if (res && res.error) return;
                const options = res.map(one => ({
                  label: one.hobby,
                  value: one.hobbyId,
                  hobbyTypeId: one.hobbyTypeId,
                }));
                cb(options);
              }),
            1000
          );
        }
        break;
      }
      case 'hobbyTypeValue': {
        actionData.actionType = REQUEST_FEW_HOBBYTYPES;
        actionData.apiName = 'Hobbytypes';
        actionData.link = `?searchKey=${encodeURIComponent(value)}`;
        if (this.searchTimerHobType) {
          clearTimeout(this.searchTimerHobType);
        }
        if (
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
          value.length > 2
        ) {
          this.searchTimerHobType = setTimeout(
            () =>
              getFewDropDownData(actionData).then(res => {
                if (res && res.error) return;
                const options = res.map(one => ({
                  label: one.hobbyType,
                  value: one.hobbyTypeId,
                }));
                cb(options);
              }),
            1000
          );
        }
        break;
      }
      default:
        break;
    }
  };

  onInputDropdown = (value, params) => {
    if (
      (/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) &&
        value.length >= 2) ||
      value === ''
    ) {
      const actionData = {
        actionType: '',
        apiName: '',
        value,
      };
      const {
        countries = [],
        qualtypes = [],
        certificateTypes = [],
        hourtypes = [],
        ethnicities = [],
        hobbytypes = [],
      } = this.props.candidateDetails;

      switch (params) {
        case 'qualtype': {
          actionData.actionType = REQUEST_ALL_QUALTYPE;
          actionData.apiName = '/Qualificationtypes';
          break;
        }
        case 'major': {
          actionData.actionType = REQUEST_ALL_MAJOR;
          actionData.apiName = '/Majors';
          break;
        }
        case 'country': {
          actionData.actionType = REQUEST_ALL_COUNTRY;
          actionData.apiName = '/Countries';
          break;
        }
        case 'hourType': {
          actionData.actionType = REQUEST_ALL_HOURTYPE;
          actionData.apiName = '/Hourtypes';
          break;
        }
        case 'certificateType': {
          actionData.actionType = REQUEST_FEW_CERTTYPE;
          actionData.apiName = '/Certificatetypes';
          break;
        }
        case 'ethnicity': {
          actionData.actionType = REQUEST_FEW_ETHNICITIES;
          actionData.apiName = '/ethnicities';
          break;
        }
        case 'hobbytype': {
          actionData.actionType = REQUEST_FEW_HOBBYTYPES;
          actionData.apiName = '/Hobbytypes';
          break;
        }
        case 'activitytType': {
          actionData.actionType = REQUEST_FEW_ACTIVITIESTYPES;
          actionData.apiName = '/activitytypes';
          break;
        }
        default:
          return;
      }
      actionData.value = value;
      this.props.getAllDropDownData(actionData);
    }
  };

  onDropdownChange = (e, params, type) => {
    if (e === undefined) return;
    switch (type) {
      case 'edu': {
        const { selectedEducation = {} } = this.state;
        selectedEducation[params] = e === null ? {} : e;
        this.setState({ selectedEducation }, () => {
          if (
            params === 'schoolValue' &&
            e !== null &&
            e.countryValue !== null
          ) {
            selectedEducation.countryValue = e.countryValue;
            this.setState({
              selectedEducation,
            });
          }
        });
        break;
      }
      case 'exp': {
        const { selectedExperience = {} } = this.state;
        selectedExperience[params] = e === null ? {} : e;
        this.setState({ selectedExperience });
        break;
      }
      case 'cert': {
        const { selectedCertification = {} } = this.state;
        selectedCertification[params] = e === null ? {} : e;
        this.setState({ selectedCertification }, () => {
          if (params === 'certFullValue' && e !== null) {
            selectedCertification.certificatetypeValue = e.certificatetypeValue;
            this.setState({ selectedCertification });
            if (
              e.organizationId &&
              e.organizationId !== null &&
              e.organizationId !== ''
            ) {
              this.props
                .getRowDataById(
                  'Organization-base',
                  'organizationId',
                  e.organizationId
                )
                .then(res => {
                  if (
                    res &&
                    Array.isArray(res) &&
                    res[0] !== null &&
                    res[0].organizationId
                  ) {
                    const { selectedCertification } = this.state;
                    selectedCertification.organizationValue = {
                      label: res[0].organizationName,
                      value: res[0].organizationId,
                    };
                    this.setState({ selectedCertification });
                  }
                });
            } else if (
              e.organizationId === null ||
              e.organizationId === undefined
            ) {
              selectedCertification.organizationValue = null;
              this.setState({
                selectedCertification,
              });
            }
          }
        });
        break;
      }
      case 'proj': {
        const { selectedProject = {} } = this.state;
        selectedProject[params] = e === null ? {} : e;
        this.setState({ selectedProject });
        break;
      }
      case 'act': {
        const { selectedActivity = {} } = this.state;
        selectedActivity[params] = e === null ? {} : e;
        this.setState({ selectedActivity });
        break;
      }
      case 'hob': {
        const { selectedHobby = {} } = this.state;
        selectedHobby[params] = e === null ? {} : e;
        this.setState({ selectedHobby });
        break;
      }
      default:
        this.setState({ [params]: e });
        break;
    }
  };
  // =======================================================

  /* To check is current check Start */

  onCurrExpCheckBox = isCurrentCompany => {
    const { selectedExperience } = this.state;
    if (isCurrentCompany === true) selectedExperience.endDate = '';
    selectedExperience.isCurrentCompany = isCurrentCompany;
    this.setState({ selectedExperience });
  };

  onCurrEduCheckBox = isCurrentSchool => {
    const { selectedEducation } = this.state;
    if (isCurrentSchool === true) selectedEducation.endDate = '';
    selectedEducation.isCurrentSchool = isCurrentSchool;
    this.setState({ selectedEducation });
  };

  // =======================================================

  /* On Accordin Click Start */

  onEduClick = e => {
    const type = e.target.getAttribute('class');
    let { showEdu } = this.state;
    showEdu = !showEdu;
    if (type === 'bx--accordion__heading') this.setState({ showEdu });
  };

  onExpClick = e => {
    const type = e.target.getAttribute('class');
    let { showExp } = this.state;
    showExp = !showExp;
    if (type === 'bx--accordion__heading') this.setState({ showExp });
  };

  onCertClick = e => {
    const type = e.target.getAttribute('class');
    let { showCert } = this.state;
    showCert = !showCert;
    if (type === 'bx--accordion__heading') this.setState({ showCert });
  };

  onProjClick = e => {
    const type = e.target.getAttribute('class');
    let { showProj } = this.state;
    showProj = !showProj;
    if (type === 'bx--accordion__heading') this.setState({ showProj });
  };

  onActClick = e => {
    const type = e.target.getAttribute('class');
    let { showAct } = this.state;
    showAct = !showAct;
    if (type === 'bx--accordion__heading') this.setState({ showAct });
  };

  onHobClick = e => {
    const type = e.target.getAttribute('class');
    let { showHob } = this.state;
    showHob = !showHob;
    if (type === 'bx--accordion__heading') this.setState({ showHob });
  };

  // =======================================================

  /* On Date Range Change Start */

  onEduDateChange = date => {
    const [startPeriod = '', endPeriod = ''] = date;
    const { selectedEducation } = this.state;
    if (
      moment(startPeriod).format('YYYY-MM-DD') ===
      moment(endPeriod).format('YYYY-MM-DD')
    ) {
      this.setState({
        showSameDateError: true,
      });
    } else {
      selectedEducation.startDate = moment(startPeriod).format('YYYY-MM-DD');
      selectedEducation.endDate = moment(endPeriod).format('YYYY-MM-DD');
      this.setState({ selectedEducation, showSameDateError: false });
    }
  };

  onCertificationdateChange = (date) => {
    const [startPeriod = '', endPeriod = ''] = date;
    const {
      selectedCertification,
    } = this.state;
    if (
      moment(startPeriod).format('YYYY-MM-DD') ===
      moment(endPeriod).format('YYYY-MM-DD')
    ) {
      this.setState({
        showSameDateError: true,
      });
    } else {
      selectedCertification.startDate = moment(startPeriod).format('YYYY-MM-DD');
      selectedCertification.endDate = moment(endPeriod).format('YYYY-MM-DD');
      this.setState({ selectedCertification, showSameDateError: false });
    }
  }

  onExpDateChange = date => {
    const [startPeriod = '', endPeriod = ''] = date;
    const { selectedExperience } = this.state;
    if (
      moment(startPeriod).format('YYYY-MM-DD') ===
      moment(endPeriod).format('YYYY-MM-DD')
    ) {
      this.setState({
        showSameDateError: true,
      });
    } else {
      selectedExperience.startDate = moment(startPeriod).format('YYYY-MM-DD');
      selectedExperience.endDate = moment(endPeriod).format('YYYY-MM-DD');
      this.setState({ selectedExperience, showSameDateError: false });
    }
  };

  onProjDateChange = date => {
    const [startPeriod = '', endPeriod = ''] = date;
    const { selectedProject } = this.state;
    if (
      moment(startPeriod).format('YYYY-MM-DD') ===
      moment(endPeriod).format('YYYY-MM-DD')
    ) {
      this.setState({
        showSameDateError: true,
      });
    } else {
      selectedProject.startDate = moment(startPeriod).format('YYYY-MM-DD');
      selectedProject.endDate = moment(endPeriod).format('YYYY-MM-DD');
      this.setState({ selectedProject, showSameDateError: false });
    }
  };

  onActDateChange = date => {
    const [startPeriod = '', endPeriod = ''] = date;
    const { selectedActivity } = this.state;
    selectedActivity.startDate = moment(startPeriod).format('YYYY-MM-DD');
    selectedActivity.endDate = moment(endPeriod).format('YYYY-MM-DD');
    this.setState({ selectedActivity });
  };

  // =======================================================

  saveBio = () => {
    const { summary = '' } = this.state;
    const {
      candidateProfile: {
        profile: { profileId = '' },
      },
    } = this.props;
    const infoData = {
      summary,
    };
    if (count(summary, 'words', {}) > 50) {
      this.setState({
        showSummaryErr: true,
      });
    } else {
      const data = { profileId, infoData };
      this.setState(
        { editSummary: false, modalError: false, showSummaryErr: false },
        () => {
          this.props.updateProfile(data).then(() => {
            this.props.getCandidateProfile(profileId);
          });
        }
      );
    }
  };

  onCloseSummary = () => {
    const {
      profile: { summary = '' },
    } = this.props.candidateProfile || {};
    this.setState({ editSummary: false, modalError: false, summary });
  };

  handleClearDate = endDateType => {
    const {
      selectedEducation,
      selectedExperience,
      selectedProject,
      selectedActivity,
      selectedCertification,
    } = this.state;
    switch (endDateType) {
      case 'selectedEducation':
        selectedEducation.endDate = '';
        this.setState({
          selectedEducation,
        });
        break;
      case 'selectedExperience':
        selectedExperience.endDate = '';
        this.setState({
          selectedExperience,
        });
        break;
      case 'selectedProject':
        selectedProject.endDate = '';
        this.setState({
          selectedProject,
        });
        break;
      case 'selectedActivity':
        selectedActivity.endDate = '';
        this.setState({
          selectedActivity,
        });
      break;
      case 'selectedCertification':
        selectedCertification.endDate = '';
        this.setState({
          selectedCertification,
        });
        break;
      default:
        break;
    }
  };

  setRows = () => {
    const { candidateProfile = {} } = this.props;
    const { skills = [] } = candidateProfile || {};
    const selectedSkills = Array.isArray(skills)
      ? skills.map(({ skill: s = {}, rating }) => ({
          label: s.skillName,
          value: s.skillId,
          rating,
        }))
      : [];
    const rowsData = selectedSkills.map(({ label, value, rating }) => ({
      skill: label,
      competency: (
        <StarRatingComponent
          name={value.toString()}
          starCount={5}
          value={rating || 0}
          starColor="#5596e6"
          emptyStarColor="#ddd"
          onStarClick={this.onStarClick}
          renderStarIcon={(index, val) => (
            <span>
              <i
                className={
                  index <= val
                    ? 'fa fa-thumbs-up mr-1'
                    : 'fa fa-thumbs-up  mr-1'
                }
              />
            </span>
          )}
        />
      ),
      // action: <Button kind="danger" small name={value.toString()} onClick={this.onDeleteSkill}>Delete</Button>,
      id: value.toString(),
    }));
    this.setState({ rowsData });
  };

  render() {
    const {
      skillsOptions = [],
      candidateProfile = {},
      candidateDetails = {},
      user: { roleId, company: { ctypeId } = {} } = {},
      noBio = false,
      notEditable = false,
      showSkillRating = false,
      t,
      preferredSkills,
      user,
    } = this.props;
    const {
      workexperience = [],
      education = [],
      skills = [],
      certifications = [],
      projects = [],
      profile = {},
      documents = [],
      activities = [],
      hobbies = [],
      finlandprofile: [finlandProfile] = [],
      workpreferenceranking = [],
    } = candidateProfile || {};
    let finalDocs = [];
    if (documents && Array.isArray(documents) && documents.length > 0) {
      finalDocs = documents.filter(res => res && res.typeId == 1);
    }
    const { profileId } = profile || this.props;
    const { companies = [], neonPoly } = this.props;
    const { finlandProfileId = null } = finlandProfile || {};
    const {
      qualtypes = [],
      majors = [],
      hourtypes = [],
      schools = [],
      qualifications = [],
      jobs = [],
      certificates = [],
      organizations = [],
      certificateTypes = [],
      ethnicities = [],
      activityTypes = [],
      hobbytypes = [],
    } = candidateDetails || {};
    const {
      countries = [],
    } = this.props;
    let Preferences = [];
    if (
      workpreferenceranking !== null &&
      Array.isArray(workpreferenceranking) &&
      workpreferenceranking.length > 0
    ) {
      const byRank = workpreferenceranking.slice(0);
      byRank.sort((a, b) => Number(a.rank - b.rank));
      Preferences =
        Array.isArray(byRank) &&
        byRank.map(({ finlandworkpreference = {} }) => ({
          label: finlandworkpreference.workPreference,
          value: finlandworkpreference.workPreferenceId,
        }));
    }

    const experienceDetails =
      workexperience &&
      Array.isArray(workexperience) &&
      workexperience.map(values => ({
        company: values.job && values.job.company,
        expDesc: values.expDesc,
        startDate: values.startDate,
        endDate: values.endDate,
        experienceId: values.experienceId,
        jobId: values.jobId,
        job: values.job || {},
      }));

    const {
      manageSkills,
      eduModal,
      expModal,
      selectedSkills,
      selectedEducation,
      selectedExperience,
      showEdu,
      showExp,
      showCert,
      showProj,
      selectedCertification,
      selectedProject,
      projModal,
      certModal,
      editSummary,
      modalError,
      summary,
      showAct,
      showHob,
      selectedActivity,
      actModal,
      hobModal,
      selectedHobby,
      rowsData,
      activePage,
      limit,
      alreadyExist,
      preferenceBackoffice,
      preferenceTaskOriented,
      preferenceTryNew,
      cities,
      languages,
      languagesSpeak,
      languagesWrite,
      IndustryPreferences,
      jobFunctionPreferences,
      jobPreferences,
      arrSkills,
    } = this.state;
    const finalArr =
      preferredSkills &&
      Array.isArray(preferredSkills) &&
      preferredSkills.length > 0 &&
      preferredSkills.map(res => {
        const { skillId, skillName } = res || {};
        const data = {
          label: skillName,
          value: skillId,
        };
        return data;
      });
    let { deleteModal } = this.state;
    switch (deleteModal) {
      case 'edu':
        deleteModal = t('deleteEducation');
        break;
      case 'exp':
        deleteModal = t('deleteEducation');
        break;
      case 'cert':
        deleteModal = t('deleteExperience');
        break;
      case 'proj':
        deleteModal = t('deleteProject');
        break;
      case 'act':
        deleteModal = t('deleteActivity');
        break;
      case 'hob':
        deleteModal = t('deleteHobby');
        break;
      default:
        deleteModal = '';
    }
    const headers = [
      { key: 'skill', header: 'Skill' },
      { key: 'competency', header: 'Competency' },
      // { key: 'action', header: 'Action' },
    ];
    return (
      <div className="CandidateDetails">
        {
         (eduModal === 'add' || eduModal === 'edit') && (
          <Modal
            open={eduModal === 'add' || eduModal === 'edit'}
            modalHeading={
              eduModal === 'add'
                ? `${t('addEducation')}`
                : `${t('editEducation')}`
            }
            primaryButtonText={
              eduModal === 'add' ? `${t('add')}` : `${t('save')}`
            }
            secondaryButtonText={t('cancel')}
            onRequestClose={() => this.closeModal('eduModal')}
            onRequestSubmit={() => this.primeSubmitModal('eduModal')}
            onSecondarySubmit={() => this.secSubmitModal('eduModal')}>
            <div className="m-0 p-0 bx--col">
              {modalError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={
                    <span className="text-danger font-weight-bold">
                      {t('allMandatoryFields')}
                    </span>
                  }
                  onCloseButtonClick={() =>
                    this.setState({ modalError: false })
                  }
                />
              )}
              {this.state.showSameDateError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={
                    <span className="text-danger font-weight-bold">
                      {t('sameDateErrMsg')}
                    </span>
                  }
                  onCloseButtonClick={() =>
                    this.setState({ showSameDateError: false })
                  }
                />
              )}
              {/* <div className="bx--col m-0 p-0">
                <DropDownBoxWithCreate
                  isAsync
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  title={t('schoolName')}
                  required
                  // options={schools.map(one => ({ label: one.schoolName, value: one.schoolId }))}
                  newOptionCreator={this.onNewOptionCreate}
                  onInputChange={(value, cb) =>
                    this.onInputDropdownFew(value, cb, 'schoolValue')
                  }
                  placeholder={t('schoolName')}
                  onChange={(value, name) =>
                    this.onDropdownChange(value, name, 'edu')
                  }
                  name="schoolValue"
                  selectedValue={selectedEducation.schoolValue}
                />
              </div> */}
              <div className="bx--col m-0 p-0">
                <TextInput
                  id="schoolName"
                  labelText={
                    <div>
                      School Name
                      <span className="text-danger">*</span>
                    </div>
                  }
                  className="mb-3"
                  name="schoolName"
                  placeholder="Please enter school name"
                  value={this.state.schoolName}
                  onChange={e => this.handleChange(e)}
                  required
                />
              </div>
              <div className="mt-2 mb-2 w-100">
                <Select
                  name="eduCountryFull"
                  labelText={<div>Country <span className="text-danger">*</span></div>}
                  id="eduCountryFull"
                  onChange={e => this.handleChange(e)}
                  value={this.state.eduCountryFull}
                  className="w-100 select-input-width"
                >
                  <SelectItem text="Select" />
                  {
                    countries && Array.isArray(countries) && countries.map(b => (
                      <SelectItem key={b.countryId} text={`${b.countryFull}`} value={`${b.countryFull}`} />
                    ))
                  }
                </Select>
              </div>
              {/* <div className="bx--col m-0 p-0">
                <DropDownBox
                  title={t('country')}
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  required
                  options={countries.map(one => ({
                    label: one.countryFull,
                    value: one.countryId,
                  }))}
                  name="countryValue"
                  onInputChange={e => this.onInputDropdown(e, 'country')}
                  placeholder={t('country')}
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'edu')
                  }
                  selectedValue={
                    selectedEducation && selectedEducation.countryValue
                  }
                />
              </div> */}
              <div className="bx--col m-0 p-0">
                <TextInput
                  id="region"
                  labelText={
                    <div>
                      {t('city')}
                      <span className="text-danger">*</span>
                    </div>
                  }
                  className="mb-3"
                  name="region"
                  placeholder="city, state or zip"
                  value={this.state.region}
                  onChange={e => this.handleChange(e)}
                  required
                />
              </div>
              <div className="mt-2 mb-2 w-100">
                <Select
                  name="qualTypeName"
                  labelText={<div>Qualification Level <span className="text-danger">*</span></div>}
                  id="qualTypeName"
                  onChange={e => this.handleChange(e)}
                  value={this.state.qualTypeName}
                  className="w-100 select-input-width"
                >
                  <SelectItem text="Select" />
                  {
                    qualtypes && Array.isArray(qualtypes) && qualtypes.map(b => (
                      <SelectItem key={b.typeId} text={`${b.typeName}`} value={`${b.typeName}`} />
                    ))
                  }
                </Select>
              </div>
              {/* <div className="bx--col m-0 p-0">
                <DropDownBox
                  title={t('qualificationLevel')}
                  required
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'edu')
                  }
                  selectedValue={selectedEducation.qualificationTypeValue}
                  name="qualificationTypeValue"
                  options={qualtypes.map(one => ({
                    label: one.typeName,
                    value: one.typeId,
                  }))}
                />
              </div> */}
              <div className="bx--col m-0 p-0">
                <DropDownBoxWithCreate
                  isAsync
                  options={qualifications.map(one => ({
                    label: one.qualificationName,
                    value: one.qualId,
                  }))}
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  title={t('qualification')}
                  newOptionCreator={this.onNewOptionCreate}
                  onInputChange={(value, cb) =>
                    this.onInputDropdownFew(value, cb, 'qualificationValue')
                  }
                  placeholder={t('qualification')}
                  onChange={(value, name) =>
                    this.onDropdownChange(value, name, 'edu')
                  }
                  name="qualificationValue"
                  selectedValue={selectedEducation.qualificationValue}
                />
              </div>
              {/* <div className="bx--col m-0 p-0">
                <DropDownBox
                  selectedValue={selectedEducation.majorValue}
                  name="majorValue"
                  title={t('major')}
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  onInputChange={e => this.onInputDropdown(e, 'major')}
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'edu')
                  }
                  options={majors.map(one => ({
                    label: one.majorName,
                    value: one.majorId,
                  }))}
                />
              </div> */}
              <div className="mt-2 mb-2 w-100">
                <Select
                  name="hourType"
                  labelText={<div>Education Type <span className="text-danger">*</span></div>}
                  id="hourType"
                  onChange={e => this.handleChange(e)}
                  value={this.state.hourType}
                  className="w-100 select-input-width"
                >
                  <SelectItem text="Select" />
                  {
                    hourtypes && Array.isArray(hourtypes) && hourtypes.map(b => (
                      <SelectItem key={b.typeId} text={`${b.typeName}`} value={`${b.typeName}`} />
                    ))
                  }
                </Select>
              </div>
              {/* <div className="bx--col m-0 p-0">
                <DropDownBox
                  title={t('educationType')}
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  required
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'edu')
                  }
                  selectedValue={selectedEducation.eduTypeValue}
                  name="eduTypeValue"
                  options={hourtypes.map(one => ({
                    label: one.typeName,
                    value: one.typeId,
                  }))}
                />
              </div> */}
              {/* <div className="bx--col m-0 p-0">
                <TextInput
                  id="gpa"
                  labelText={t('gpaScore')}
                  className="mb-3"
                  type="number"
                  name="selectedEducation_gpa"
                  placeholder={t('gpaScore')}
                  value={selectedEducation.gpa || ''}
                  onChange={this.handleText}
                  max="10"
                />
              </div> */}
              <div className="bx--col m-0 p-0">
                <div className="bx--type-zeta bx--col-3 mb-1 p-0">
                  {t('educationPeriod')}
                  <span className="text-danger">*</span>
                </div>
                <DatePicker
                  className="d-flex"
                  minDate="1/10/1960"
                  maxDate={moment().format('MM/DD/YYYY')}
                  datePickerType="range"
                  onChange={this.onEduDateChange}>
                  <DatePickerInput
                    className="mb-0 ml-2"
                    id="start"
                    placeholder="Start Date"
                    value={
                      selectedEducation.startDate &&
                      selectedEducation.startDate !== null &&
                      selectedEducation.startDate !== ''
                        ? moment(selectedEducation.startDate).format(
                            'MM/DD/YYYY'
                          )
                        : ''
                    }
                  />
                  <div>
                    <DatePickerInput
                      className="mb-0 mr-2"
                      // disabled={selectedEducation.isCurrentEducation && selectedEducation.isCurrentEducation === true}
                      id="end"
                      placeholder="End Date"
                      value={
                        selectedEducation.endDate &&
                        selectedEducation.endDate !== null &&
                        selectedEducation.endDate !== ''
                          ? moment(selectedEducation.endDate).format(
                              'MM/DD/YYYY'
                            )
                          : ''
                      }
                    />
                    <div className="mt-1">
                      <a
                        href="#"
                        onClick={() =>
                          this.handleClearDate('selectedEducation')
                        }>
                        {t('clear')}
                      </a>
                    </div>
                  </div>
                </DatePicker>
              </div>
              <div className="bx--col m-0 p-0 bx--col-md-6">
                <Checkbox
                  checked={
                    selectedEducation.isCurrentSchool &&
                    (selectedEducation.isCurrentSchool === true ||
                      selectedEducation.endDate === null)
                  }
                  labelText={t('currentSchoolLabel')}
                  wrapperClassName="ml-2"
                  onChange={this.onCurrEduCheckBox}
                  id="currentSchool"
                />
              </div>
              {/* {this.state.isJobDescEmpty && this.state.isJobReqEmpty && <label><small className="text-danger font-weight-bold">*Job Description or Job Requirment Field must not be empty</small></label>} */}
            </div>
          </Modal>
    )
        }
        {
          (expModal === 'add' || expModal === 'edit') && (
          <Modal
            open={expModal === 'add' || expModal === 'edit'}
            modalHeading={
              expModal === 'add' ? t('addExperience') : t('editExperience')
            }
            primaryButtonText={expModal === 'add' ? t('add') : t('save')}
            secondaryButtonText={t('cancel')}
            onRequestClose={() => this.closeModal('expModal')}
            onRequestSubmit={() => this.primeSubmitModal('expModal')}
            onSecondarySubmit={() => this.secSubmitModal('expModal')}>
            <div className="m-0 p-0 bx--col">
              {modalError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={
                    <span className="text-danger font-weight-bold">
                      {t('allMandatoryFields')}
                    </span>
                  }
                  onCloseButtonClick={() =>
                    this.setState({ modalError: false })
                  }
                />
              )}
              {this.state.showSameDateError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={
                    <span className="text-danger font-weight-bold">
                      {t('sameDateErrMsg')}
                    </span>
                  }
                  onCloseButtonClick={() =>
                    this.setState({ showSameDateError: false })
                  }
                />
              )}
              <div className="bx--col mb-2 p-0">
                {/* <DropDownBoxWithCreate
                  isAsync
                  title={t("jobTitle")}
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  required
                  // options={jobs && jobs.map(one => ({ label: one.nameName, value: one.nameId }))}
                  newOptionCreator={this.onNewOptionCreate}
                  onInputChange={(value, cb) => this.onInputDropdownFew(value, cb, 'jobValue')}
                  placeholder={t("jobTitle")}
                  onChange={(value, name) => this.onDropdownChange(value, name, 'exp')}
                  name="jobValue"
                  selectedValue={selectedExperience.jobValue}
                /> */}
                <TextInput
                  value={this.state.jobTitle}
                  name="jobTitle"
                  id="jobTitle"
                  onChange={e => this.handleChange(e)}
                  labelText={
                    <div>
                      {t('jobTitle')}
                      <span className="text-danger">*</span>
                    </div>
                  }
                  placeholder={t('jobTitle')}
                />
              </div>
              <div>
              <TextInput
                  value={this.state.companyName}
                  name="companyName"
                  id="companyName"
                  onChange={e => this.handleChange(e)}
                  labelText={
                    <div>
                      Company
                      <span className="text-danger">*</span>
                    </div>
                  }
                  placeholder={t('company')}
                />
              </div>
              {/* <div className="bx--col m-0 p-0">
                <DropDownBoxWithCreate
                  isAsync
                  title={t('company')}
                  required
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  // options={companies.map(one => ({ label: one.company_name, value: one.company_id }))}
                  newOptionCreator={this.onNewOptionCreate}
                  onInputChange={(value, cb) =>
                    this.onInputDropdownFew(value, cb, 'companyValue')
                  }
                  placeholder={t('company')}
                  onChange={(value, name) =>
                    this.onDropdownChange(value, name, 'exp')
                  }
                  name="companyValue"
                  selectedValue={selectedExperience.companyValue}
                />
              </div> */}
              <div className="mt-2 mb-2 w-100">
                <Select
                  name="expCountryFull"
                  labelText={<div>Country <span className="text-danger">*</span></div>}
                  id="expCountryFull"
                  onChange={e => this.handleChange(e)}
                  value={this.state.expCountryFull}
                  className="w-100 select-input-width"
                >
                  <SelectItem text="Select" />
                  {
                    countries && Array.isArray(countries) && countries.map(b => (
                      <SelectItem key={b.countryId} text={`${b.countryFull}`} value={`${b.countryFull}`} />
                    ))
                  }
                </Select>
              </div>
              {/* <div className="bx--col m-0 p-0">
                <DropDownBox
                  title={
                    <div>
                      {t('country')}
                      <span className="text-danger">*</span>
                    </div>
                  }
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  options={countries.map(one => ({
                    label: one.countryFull,
                    value: one.countryId,
                  }))}
                  name="countryValue"
                  onInputChange={e => this.onInputDropdown(e, 'country')}
                  placeholder={t('country')}
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'exp')
                  }
                  selectedValue={
                    selectedExperience && selectedExperience.countryValue
                  }
                />
              </div> */}
              <div className="bx--col m-0 p-0">
                <TextInput
                  id="region"
                  labelText={
                    <div>
                      {t('city')}
                      <span className="text-danger">*</span>
                    </div>
                  }
                  className="mb-3"
                  name="selectedExperience_region"
                  placeholder="City, city, state or zip"
                  value={
                    (selectedExperience &&
                      selectedExperience.region &&
                      selectedExperience.region.toString() &&
                      selectedExperience.region.trimLeft()) ||
                    ''
                  }
                  required
                  onChange={this.handleText}
                  max="100"
                />
              </div>
              <div className="bx--col m-0 p-0">
                <TextArea
                  labelText={t('description')}
                  name="selectedExperience_expDesc"
                  invalidText="A valid value is required"
                  placeholder="Description about this role."
                  value={
                    (selectedExperience &&
                      selectedExperience.expDesc &&
                      selectedExperience.expDesc.toString() &&
                      selectedExperience.expDesc.trimLeft()) ||
                    ''
                  }
                  id="test2"
                  cols={50}
                  rows={3}
                  onChange={this.handleText}
                />
              </div>
              <div className="bx--col mt-3 m-0 p-0">
                <div className="bx--type-zeta bx--col-3 mb-1 p-0">
                  {t('experiencePeriod')}
                </div>
                <DatePicker
                  className="indexing"
                  minDate="1/10/1970"
                  maxDate={moment().format('MM/DD/YYYY')}
                  datePickerType="range"
                  onChange={this.onExpDateChange}>
                  <DatePickerInput
                    className="mb-0 ml-2"
                    id="start"
                    placeholder="Start Date"
                    value={
                      selectedExperience.startDate &&
                      selectedExperience.startDate !== null &&
                      selectedExperience.startDate !== ''
                        ? moment(selectedExperience.startDate).format(
                            'MM/DD/YYYY'
                          )
                        : ''
                    }
                  />
                  <div>
                    <DatePickerInput
                      className="mb-0 mr-2"
                      // disabled={selectedExperience.isCurrentCompany && selectedExperience.isCurrentCompany === true}
                      id="end"
                      placeholder="End Date"
                      value={
                        selectedExperience.endDate &&
                        selectedExperience.endDate !== null &&
                        selectedExperience.endDate !== ''
                          ? moment(selectedExperience.endDate).format(
                              'MM/DD/YYYY'
                            )
                          : ''
                      }
                    />
                    <div className="mt-1">
                      <a
                        href="#"
                        onClick={() =>
                          this.handleClearDate('selectedExperience')
                        }>
                        clear
                      </a>
                    </div>
                  </div>
                </DatePicker>
              </div>
              <div className="bx--col m-0 p-0 bx--col-md-6">
                <Checkbox
                  checked={
                    selectedExperience.isCurrentCompany &&
                    selectedExperience.isCurrentCompany === true
                  }
                  labelText={t('currentCompanyLabel')}
                  wrapperClassName="ml-2"
                  onChange={this.onCurrExpCheckBox}
                  id="currentCompnay"
                />
              </div>
              {/* {this.state.isJobDescEmpty && this.state.isJobReqEmpty
              && <label><small className="text-danger font-weight-bold">
              *Job Description or Job Requirment Field must not be empty</small></label>} */}
            </div>
          </Modal>
          )
        }
        {
          (certModal === 'add' || certModal === 'edit') && (
          <Modal
            open={certModal === 'add' || certModal === 'edit'}
            modalHeading={
              certModal === 'add'
                ? t('addCertification')
                : t('editCertification')
            }
            primaryButtonText={certModal === 'add' ? t('add') : t('save')}
            secondaryButtonText={t('cancel')}
            onRequestClose={() => this.closeModal('certModal')}
            onRequestSubmit={() => this.primeSubmitModal('certModal')}
            onSecondarySubmit={() => this.secSubmitModal('certModal')}>
            <div className="m-0 p-0 bx--col">
              {modalError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={
                    <span className="text-danger font-weight-bold">
                      {t('allMandatoryFields')}
                    </span>
                  }
                  onCloseButtonClick={() =>
                    this.setState({ modalError: false })
                  }
                />
              )}
              <div className="bx--col m-0 p-0">
                <DropDownBoxWithCreate
                  isAsync
                  title={t('certification')}
                  required
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  // options={certificates.map(one => ({ label: one.certFull, value: one.certId }))}
                  newOptionCreator={this.onNewOptionCreate}
                  onInputChange={(value, cb) =>
                    this.onInputDropdownFew(value, cb, 'certFullValue')
                  }
                  placeholder="Certification Name"
                  onChange={(value, name) =>
                    this.onDropdownChange(value, name, 'cert')
                  }
                  name="certFullValue"
                  selectedValue={selectedCertification.certFullValue}
                />
              </div>
              <div className="bx--col m-0 p-0">
                <div className="bx--type-zeta bx--col-3 mb-1 p-0">
                  Certification Period
                  <span className="text-danger">*</span>
                </div>
                <DatePicker
                  className="d-flex"
                  minDate="1/10/1960"
                  maxDate={moment().format('MM/DD/YYYY')}
                  datePickerType="range"
                  onChange={this.onCertificationdateChange}>
                  <div>
                  <DatePickerInput
                    className="mb-0 ml-2"
                    id="start"
                    placeholder="Date of Issue"
                    value={
                      selectedCertification.startDate &&
                      selectedCertification.startDate !== null &&
                      selectedCertification.startDate !== ''
                        ? moment(selectedCertification.startDate).format(
                            'MM/DD/YYYY'
                          )
                        : ''
                    }
                  />
                  </div>
                  <div>
                    <DatePickerInput
                      className="mb-0 mr-2"
                      // disabled={selectedEducation.isCurrentEducation && selectedEducation.isCurrentEducation === true}
                      id="end"
                      placeholder="Date of Expiry"
                      value={
                        selectedCertification.endDate &&
                        selectedCertification.endDate !== null &&
                        selectedCertification.endDate !== ''
                          ? moment(selectedCertification.endDate).format(
                              'MM/DD/YYYY'
                            )
                          : ''
                      }
                    />
                    <div className="mt-1">
                      <a
                        href="#"
                        onClick={() =>
                          this.handleClearDate('selectedCertification')
                        }>
                        {t('clear')}
                      </a>
                    </div>
                  </div>
                </DatePicker>
              </div>
              {/* <div className="bx--col m-0 p-0">
                <TextInput
                  id="abbr"
                  // disabled={certModal === 'edit'}
                  required
                  labelText={
                    <div>
                      {t('abbreviation')}
                      <span className="text-danger">*</span>
                    </div>
                  }
                  className="mb-3"
                  name="selectedCertification_certShort"
                  placeholder="short form"
                  onChange={this.handleText}
                  value={
                    selectedCertification &&
                    selectedCertification.certFullValue &&
                    selectedCertification.certFullValue.certShort
                      ? selectedCertification.certFullValue.certShort
                      : (selectedCertification.certShort &&
                          selectedCertification.certShort.toString() &&
                          selectedCertification.certShort.trimLeft()) ||
                        ''
                  }
                  max="10"
                />
              </div>
              <div className="bx--col m-0 p-0">
                <DropDownBox
                  title={t('certificationType')}
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  // disabled={certModal === 'edit'}
                  options={certificateTypes.map(one => ({
                    label: one.typeName,
                    value: one.typeId,
                  }))}
                  name="certificatetypeValue"
                  onInputChange={e =>
                    this.onInputDropdown(e, 'certificateType')
                  }
                  placeholder={t('certificationType')}
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'cert')
                  }
                  selectedValue={selectedCertification.certificatetypeValue}
                />
              </div> */}
              <div className="bx--col m-0 p-0">
                <DropDownBoxWithCreate
                  isAsync
                  title={t('organization')}
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  // disabled={certModal === 'edit'}
                  // options={organizations.map(one => ({ label: one.organizationName, value: one.organizationId }))}
                  newOptionCreator={this.onNewOptionCreate}
                  onInputChange={(value, cb) =>
                    this.onInputDropdownFew(value, cb, 'organizationValue')
                  }
                  placeholder="Organization Name"
                  onChange={(value, name) =>
                    this.onDropdownChange(value, name, 'cert')
                  }
                  name="organizationValue"
                  selectedValue={
                    selectedCertification &&
                    selectedCertification.organizationValue
                  }
                />
              </div>

              {/* <div className="bx--col m-0 p-0">
                <TextInput
                  id="rgn"
                  labelText={
                    <div>
                      {t('city')}
                      <span className="text-danger">*</span>
                    </div>
                  }
                  className="mb-3"
                  required
                  name="selectedCertification_region"
                  placeholder="region"
                  onChange={this.handleText}
                  value={
                    (selectedCertification &&
                      selectedCertification.region &&
                      selectedCertification.region.toString() &&
                      selectedCertification.region.trimLeft()) ||
                    ''
                  }
                  max="100"
                />
              </div> */}
              {/* {this.state.isJobDescEmpty && this.state.isJobReqEmpty &&
              <label><small className="text-danger font-weight-bold">
              *Job Description or Job Requirment Field must not be empty
              </small></label>} */}
            </div>
          </Modal>
          )
        }
        {
          (projModal === 'add' || projModal === 'edit') && (
            <Modal
              open={projModal === 'add' || projModal === 'edit'}
              modalHeading={
                projModal === 'add' ? t('addProject') : t('editProject')
              }
              primaryButtonText={projModal === 'add' ? t('add') : t('save')}
              secondaryButtonText={t('cancel')}
              onRequestClose={() => this.closeModal('projModal')}
              onRequestSubmit={() => this.primeSubmitModal('projModal')}
              onSecondarySubmit={() => this.secSubmitModal('projModal')}>
              <div className="m-0 p-0">
                {modalError === true && (
                  <InlineNotification
                    lowContrast
                    kind="error"
                    className="bx--col"
                    title=""
                    subtitle={
                      <span className="text-danger font-weight-bold">
                        {t('allMandatoryFields')}
                      </span>
                    }
                    onCloseButtonClick={() =>
                      this.setState({ modalError: false })
                    }
                  />
                )}
                {this.state.showSameDateError === true && (
                  <InlineNotification
                    lowContrast
                    kind="error"
                    className="bx--col"
                    title=""
                    subtitle={
                      <span className="text-danger font-weight-bold">
                        {t('sameDateErrMsg')}
                      </span>
                    }
                    onCloseButtonClick={() =>
                      this.setState({ showSameDateError: false })
                    }
                  />
                )}
                <div className="bx--col m-0 p-0">
                  <TextInput
                    id="cert"
                    name="selectedProject_projectTitle"
                    required
                    labelText={
                      <div>
                        {t('project')}
                        <span className="text-danger">*</span>
                      </div>
                    }
                    placeholder="project name"
                    value={
                      (selectedProject &&
                        selectedProject.projectTitle &&
                        selectedProject.projectTitle.toString() &&
                        selectedProject.projectTitle.trimLeft()) ||
                      ''
                    }
                    onChange={this.handleText}
                  />
                </div>
                <div className="bx--col m-0 p-0 mt-3">
                  <TextArea
                    labelText={t('projectDetails')}
                    name="selectedProject_projectDesc"
                    placeholder="Description about this project."
                    value={
                      (selectedProject &&
                        selectedProject.projectDesc &&
                        selectedProject.projectDesc.toString() &&
                        selectedProject.projectDesc.trimLeft()) ||
                      ''
                    }
                    id="test2"
                    cols={40}
                    rows={3}
                    onChange={this.handleText}
                  />
                </div>
                <div className="bx--type-zeta bx--col-3 mt-3 mb-1 p-0">
                    {t('projectPeriod')}
                  </div>
                <div className="bx--row m-0 p-0">
                  <DatePicker
                    className=""
                    minDate="1/10/1970"
                    maxDate={moment().format('MM/DD/YYYY')}
                    datePickerType="range"
                    onChange={this.onProjDateChange}>
                    <div>
                      <DatePickerInput
                        className="mb-0 ml-2"
                        id="start"
                        placeholder="Start Date"
                        value={
                          selectedProject &&
                          selectedProject.startDate &&
                          selectedProject.startDate !== null &&
                          selectedProject.startDate !== ''
                            ? moment(selectedProject.startDate).format('MM/DD/YYYY')
                            : ''
                        }
                      />
                    </div>
                    <div className="ml-2">
                      <DatePickerInput
                        className="mb-0 mr-2"
                        // disabled={selectedExperience.isCurrentCompany && selectedExperience.isCurrentCompany === true}
                        id="end"
                        placeholder="End Date"
                        value={
                          selectedProject &&
                          selectedProject.endDate &&
                          selectedProject.endDate !== null &&
                          selectedProject.endDate !== ''
                            ? moment(selectedProject.endDate).format('MM/DD/YYYY')
                            : ''
                        }
                      />
                      <div className="mt-1">
                        <a
                          href="#"
                          onClick={() => this.handleClearDate('selectedProject')}>
                          {t('clear')}
                        </a>
                      </div>
                    </div>
                  </DatePicker>
                </div>
                <div className="bx--col m-0 p-0">
                  <Checkbox
                    checked={
                      selectedExperience.isCurrentCompany &&
                      selectedExperience.isCurrentCompany === true
                    }
                    labelText="This is my current project"
                    wrapperClassName="ml-2"
                    onChange={this.onCurrExpCheckBox}
                    id="currentCompnay"
                  />
                </div>
                {/* {this.state.isJobDescEmpty && this.state.isJobReqEmpty
                && <label><small className="text-danger font-weight-bold">
                *Job Description or Job Requirment Field must not be empty</small></label>} */}
              </div>
            </Modal>
          )
        }
        {
          <Modal
            open={actModal === 'add' || actModal === 'edit'}
            modalHeading={
              actModal === 'add' ? t('addActivity') : t('editActivity')
            }
            primaryButtonText={actModal === 'add' ? t('add') : t('save')}
            secondaryButtonText={t('cancel')}
            onRequestClose={() => this.closeModal('actModal')}
            onRequestSubmit={() => this.primeSubmitModal('actModal')}
            onSecondarySubmit={() => this.secSubmitModal('actModal')}>
            {alreadyExist === 'act' && (
              <InlineNotification
                lowContrast
                kind="error"
                className="bx--col"
                title="Activity already exist"
                subtitle="can't add the activity"
                onCloseButtonClick={() => this.setState({ alreadyExist: '' })}
              />
            )}
            <div className="m-0 p-0">
              {modalError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={
                    <span className="text-danger font-weight-bold">
                      {t('allMandatoryFields')}
                    </span>
                  }
                  onCloseButtonClick={() =>
                    this.setState({ modalError: false })
                  }
                />
              )}
              <div>
              <TextInput
                  value={this.state.activityName}
                  name="activityName"
                  id="activityName"
                  onChange={e => this.handleChange(e)}
                  labelText={
                    <div>
                      Activity Name
                      <span className="text-danger">*</span>
                    </div>
                  }
                  placeholder={t('acitivities')}
                />
              </div>
              {/* <div className="bx--col m-0 p-0">
                <DropDownBoxWithCreate
                  isAsync
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  required
                  className="bx--col m-0 p-0"
                  title={t('activityName')}
                  required
                  newOptionCreator={this.onNewOptionCreate}
                  onInputChange={(value, cb) =>
                    this.onInputDropdownFew(value, cb, 'activityValue')
                  }
                  placeholder={t('activityName')}
                  onChange={(value, name) =>
                    this.onDropdownChange(value, name, 'act')
                  }
                  name="activityValue"
                  selectedValue={selectedActivity.activityValue}
                />
              </div> */}
              <div className="mt-2 mb-2 w-100">
                <Select
                  name="activityType"
                  labelText={<div>Activity Type <span className="text-danger">*</span></div>}
                  id="activityType"
                  onChange={e => this.handleChange(e)}
                  value={this.state.activityType}
                  className="w-100 select-input-width"
                >
                  <SelectItem text="Select" />
                  {
                    activityTypes && Array.isArray(activityTypes) && activityTypes.map(b => (
                      <SelectItem key={b.typeId} text={`${b.typeName}`} value={`${b.typeName}`} />
                    ))
                  }
                </Select>
              </div>
              {/* <div className="bx--col m-0 p-0 mt-3">
                <DropDownBox
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  title={t('activityType')}
                  options={
                    activityTypes !== null &&
                    activityTypes.map(one => {
                      return { value: one.typeId, label: one.typeName };
                    })
                  }
                  name="activityTypeValue"
                  placeholder={t('activityType')}
                  onInputChange={e =>
                    this.onInputDropdown(e, 'activityTypeValue')
                  }
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'act')
                  }
                  selectedValue={selectedActivity.activityTypeValue}
                />
              </div> */}
              <div className="bx--col m-0 p-0 mt-3">
                <TextArea
                  labelText={
                    <div className="bx--row m-0 p-0">
                      <div>Achievements</div>
                    </div>
                  }
                  name="selectedActivity_description"
                  placeholder="Achievements about this project"
                  value={
                    (selectedActivity &&
                      selectedActivity.description &&
                      selectedActivity.description.toString() &&
                      selectedActivity.description.trimLeft()) ||
                    ''
                  }
                  id="test2"
                  required
                  cols={40}
                  rows={3}
                  onChange={this.handleText}
                />
              </div>
              <div className="bx--row m-0 p-0">
                <div className="bx--type-zeta bx--col-3 mt-3 mb-1 p-0">
                  <div className="bx--row m-0 p-0">
                    <div>{t('activityPeriod')}</div>
                  </div>
                </div>
              </div>
              <DatePicker
                  className=""
                  minDate="1/10/1970"
                  maxDate={moment().format('MM/DD/YYYY')}
                  datePickerType="range"
                  onChange={this.onActDateChange}>
                  <DatePickerInput
                    className="mb-0 ml-2"
                    id="start"
                    placeholder="Start Date"
                    value={
                      selectedActivity &&
                      selectedActivity.startDate &&
                      selectedActivity.startDate !== null &&
                      selectedActivity.startDate !== ''
                        ? moment(selectedActivity.startDate).format(
                            'MM/DD/YYYY'
                          )
                        : ''
                    }
                  />
                  <div>
                    <DatePickerInput
                      className="mb-0 mr-2"
                      // disabled={selectedExperience.isCurrentCompany && selectedExperience.isCurrentCompany === true}
                      id="end"
                      placeholder="End Date"
                      value={
                        selectedActivity &&
                        selectedActivity.endDate &&
                        selectedActivity.endDate !== null &&
                        selectedActivity.endDate !== ''
                          ? moment(selectedActivity.endDate).format(
                              'MM/DD/YYYY'
                            )
                          : ''
                      }
                    />
                    <div className="mt-1">
                      <a
                        href="#"
                        onClick={() =>
                          this.handleClearDate('selectedActivity')
                        }>
                        {t('clear')}
                      </a>
                    </div>
                  </div>
                </DatePicker>
              {/* {this.state.isJobDescEmpty && this.state.isJobReqEmpty
              && <label><small className="text-danger font-weight-bold">
              *Job Description or Job Requirment Field must not be empty</small></label>} */}
            </div>
          </Modal>
        }
        {
          <Modal
            open={hobModal === 'add' || hobModal === 'edit'}
            modalHeading={hobModal === 'add' ? t('addHobby') : t('editHobby')}
            primaryButtonText={hobModal === 'add' ? t('add') : t('save')}
            secondaryButtonText={t('cancel')}
            onRequestClose={() => this.closeModal('hobModal')}
            onRequestSubmit={() => this.primeSubmitModal('hobModal')}
            onSecondarySubmit={() => this.secSubmitModal('hobModal')}>
            {alreadyExist === 'hob' && (
              <InlineNotification
                lowContrast
                kind="error"
                className="bx--col"
                title="Hobby already exist"
                subtitle="can't add the hobby"
                onCloseButtonClick={() => this.setState({ alreadyExist: '' })}
              />
            )}
            <div className="m-0 p-0">
              {modalError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={
                    <span className="text-danger font-weight-bold">
                      {t('allMandatoryFields')}
                    </span>
                  }
                  onCloseButtonClick={() =>
                    this.setState({ modalError: false })
                  }
                />
              )}
                            <div>
              <TextInput
                  value={this.state.hobbyName}
                  name="hobbyName"
                  id="hobbyName"
                  onChange={e => this.handleChange(e)}
                  labelText={
                    <div>
                      Hobby Name
                      <span className="text-danger">*</span>
                    </div>
                  }
                  placeholder={t('hobbyName')}
                />
              </div>
              {/* <div className="bx--col m-0 p-0">
                <DropDownBoxWithCreate
                  isAsync
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  required
                  className="bx--col m-0 p-0"
                  title={t('hobbyName')}
                  // options={schools.map(one => ({ label: one.schoolName, value: one.schoolId }))}
                  newOptionCreator={this.onNewOptionCreate}
                  onInputChange={(value, cb) =>
                    this.onInputDropdownFew(value, cb, 'hobbyValue')
                  }
                  placeholder={t('hobby')}
                  onChange={(value, name) =>
                    this.onDropdownChange(value, name, 'hob')
                  }
                  name="hobbyValue"
                  selectedValue={selectedHobby.hobbyValue}
                />
              </div> */}
              {/* <div className="bx--col m-0 p-0 mt-3">
                <DropDownBox
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  title={t('hobbyType')}
                  options={
                    hobbytypes !== null &&
                    hobbytypes.map(one => {
                      return { value: one.hobbyTypeId, label: one.hobbyType };
                    })
                  }
                  name="hobbyTypeValue"
                  placeholder={t('hobbyType')}
                  onInputChange={e => this.onInputDropdown(e, 'hobbytype')}
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'hob')
                  }
                  selectedValue={selectedHobby.hobbyTypeValue}
                />
              </div>
              <div className="bx--row m-0 p-0">
                <DropDownBox
                  title={t('playground')}
                  mainClassName="bx--form-item"
                  titleClass="bx--select"
                  labelClass="bx--label"
                  custom
                  className="bx--col m-0 p-0"
                  options={[
                    { label: 'Indoor', value: 1 },
                    { label: 'Outdoor', value: 2 },
                  ]}
                  name="playValue"
                  placeholder={t('inoutDoor')}
                  onChange={(id, value, name) =>
                    this.onDropdownChange(value, name, 'hob')
                  }
                  selectedValue={selectedHobby && selectedHobby.playValue}
                />
              </div> */}
              {/* {this.state.isJobDescEmpty && this.state.isJobReqEmpty
              && <label><small className="text-danger font-weight-bold">
              *Job Description or Job Requirment Field must not be empty</small></label>} */}
            </div>
          </Modal>
        }
        {
          <Modal
            open={deleteModal !== ''}
            modalHeading={deleteModal}
            primaryButtonText={t('delete')}
            danger
            secondaryButtonText={t('cancel')}
            onRequestClose={() => this.closeModal('deleteModal')}
            onRequestSubmit={() => this.primeSubmitModal('deleteModal')}
            onSecondarySubmit={() => this.secSubmitModal('deleteModal')}>
            <p className="bx--modal-content__text">
              <strong className="text-dark">{t('deleteEntry')}</strong>
              {/* <b className="text-lowercase">{` ${deleteModal.replace('Delete ', '')}`}</b> */}
            </p>
          </Modal>
        }
        {
          <Modal
            open={editSummary === true}
            modalHeading="Edit Summary"
            primaryButtonText="Save"
            secondaryButtonText={t('cancel')}
            onRequestClose={this.onCloseSummary}
            onRequestSubmit={this.saveBio}
            onSecondarySubmit={this.onCloseSummary}>
            <div className="bx--row m-0 p-0">
              {modalError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  title="Summary"
                  className="bx--col"
                  subtitle="cannot be empty"
                  onCloseButtonClick={() =>
                    this.setState({ modalError: false })
                  }
                />
              )}
              {this.state.showSummaryErr === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  title="Summary"
                  className="bx--col"
                  subtitle={t('countErr')}
                  onCloseButtonClick={() =>
                    this.setState({ modalError: false })
                  }
                />
              )}
              <div className="mt-3 bx--col m-0 p-0">
                <TextArea
                  labelText="Summary"
                  required
                  name="summary"
                  placeholder="A short intro about yourself."
                  value={
                    (summary && summary.toString() && summary.trimLeft()) || ''
                  }
                  id="test5"
                  cols={60}
                  rows={5}
                  onChange={this.handleText}
                />
              </div>
            </div>
          </Modal>
        }
        {profile ? (
          <div>
            <div className="bx--row row">
              <div>
                {/* {t('parserNote')} */}
                <div className="d-flex align-items-center">
                  <div className="mt-1 h6 font-weight-bold text-uppercase text-dark">
                    {t('summary')}
                  </div>

                  {(!summary || !notEditable) ? (
                    <div>
                      <a
                        onClick={() => this.setState({ editSummary: true })}
                        title="add summary"
                        className="ml-2"
                        href="javascript:void(0)">
                        {t('add')}
                      </a>
                    </div>
                  ) : (
                    <div>
                      {
                        notEditable === false && (
                          <a
                            onClick={() => this.setState({ editSummary: true })}
                            title="edit summary"
                            className="ml-2"
                            href="javascript:void(0)">
                            {t('edit')}
                          </a>
                        )
                      }
                    </div>
                  )}
                </div>
                {!summary ? (
                  <div>
                    <div className="  text-dark pb-3">
                      {t('noSummary')}.
                    </div>
                  </div>
                ) : (
                  <div className="bx--type-gamma text-center">
                    <blockquote style={{ fontSize: '20px' }}>
                      {summary}
                    </blockquote>
                  </div>
                )}
              </div>
            </div>
            <hr />
            {/* {
              roleId && Number(roleId) !== 3 && !notEditable
                && (
                  <div className="bx--row row align-items-center">
                    <div className="w-100">
                      <div className="mt-1 h6 font-weight-bold text-uppercase text-dark">
                        {t('documents')}
                      </div>
                      <div className="ml-4">
                        <DocumentsData center={false} user={user} documents={finalDocs} />
                      </div>
                    </div>
                  </div>
                )
            } */}
            <div className="bx--row row align-items-center">
              <div>
              <div className="mt-1 h6 font-weight-bold text-uppercase text-dark">
                {t('skills')}
              </div>
              {notEditable === false && (
                <div>
                  {manageSkills === false ? (
                    <a
                      onClick={() => this.onManageSkills(skills)}
                      title="manage skills"
                      className="ml-2"
                      href="javascript:void(0)">
                      {(Array.isArray(skills) && skills.length>0)?t('edit'): t('add')}
                    </a>
                  ) : (
                    <Button
                      onClick={() => this.onSaveSkills(selectedSkills)}
                      title="manage skills"
                      className="ml-2"
                      kind="ghost"
                      small>
                      <div
                        className="fa fa-window-close"
                        style={{ fontSize: 20 }}
                      />
                    </Button>
                  )}
                </div>
              )}
              <div>
              {this.props.showError === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  className="bx--col"
                  title=""
                  subtitle={
                    <span className="text-danger font-weight-bold">
                      {t('noSkillsMsg')}
                    </span>
                  }
                  onCloseButtonClick={() =>
                    this.setState({ modalError: false })
                  }
                />
              )}
            </div>
            {manageSkills === false &&
            Array.isArray(skills) && skills.length > 0 ? (
              <div className="profile-skills">
                <div className="row pb-2">
                  <div className="col-xs-12 col-sm-12 text-left">
                    {skills.map(
                      (
                        { skill: { skillName = '' } = {}, rating = null } = {},
                        key
                      ) => (
                        <Tag key={key} type="blue">
                          <div className="text-uppercase">
                            <div className="text-center bx--row m-0 pt-1 pb-1">
                              <div>{skillName}</div>
                              <div
                                className={
                                  rating
                                    ? 'pl-1 ml-1 bx--row m-0 start_label_div'
                                    : 'pl-1 ml-1 bx--row m-0'
                                }>
                                {Array.from(Array(Number(rating))).map(() => (
                                  <i
                                    className="fa fa-star"
                                    style={{
                                      color: '#ffb400',
                                      marginTop: 2,
                                      marginLeft: 2,
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </Tag>
                      )
                    )}
                  </div>
                </div>
              </div>
            ) : (
              manageSkills === false && (
                <div className="  text-dark pb-3">
                  {t('noSkills')}.
                </div>
              )
            )}
              </div>
            </div>
            
            {manageSkills === true && (
              <div className="ml-4">
                <div className="pb-2">
                  <div className="col-xs-12 col-sm-12 text-left">
                    <DropDownBoxWithCreate
                      // isAsync
                      multi
                      mainClassName="bx--col-12 p-0"
                      custom
                      options={
                        arrSkills && arrSkills.length > 0 ? arrSkills : finalArr
                      }
                      isClearable={false}
                      newOptionCreator={this.onNewOptionCreate}
                      onInputChange={this.onSkillsInputChange}
                      placeholder={t('skills')}
                      onChange={(value, name) =>
                        this.handleSkillDropdownChange(name, value)
                      }
                      name="selectedSkills"
                      selectedValue={selectedSkills}
                      isDisableDropdownClass
                    />
                  </div>
                  <div className="mt-2 mb-3 justify-content-center bx--row">
                    <Button
                      onClick={() => this.onSaveSkills(selectedSkills)}
                      title="manage skills"
                      kind="primary"
                      small>
                      {t('save')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <Accordion>
              {/* {!noBio && roleId !== 3 && (
                <AccordionItem
                  title={(
                    <div className="bx--row row">
                      <div className="mb-1 pt-2 ml-0 h6 font-weight-bold text-uppercase text-dark">
                        {t('geipApplication')}
                      </div>
                    </div>
                  )}
                  className="p-0"
                >
                  <GeipDetails allCountries={countries} profileId={profileId} indexId={2} disabled />
                </AccordionItem>
              )} */}
              {/* {
                !noBio && roleId !== 3 && (
                  <div>
                    <div className="bx--row row">
                      <div className="mb-1 pt-2 ml-0 h6 font-weight-bold text-uppercase text-dark">
                        {t('documents')}
                      </div>
                    </div>
                    <StudentDocuments profileId={profileId} />
                  </div>
                )
              }
              {!noBio && (
                <AccordionItem
                  title={(
                    <div className="bx--row row">
                      <div className="mb-1 pt-2 ml-0 h6 font-weight-bold text-uppercase text-dark">
                        {t('bio&Edu')}
                      </div>
                    </div>
                  )}
                  className="pb-2"
                >
                  <GeipDetails allCountries={countries} profileId={profileId} indexId={3} disabled />
                </AccordionItem>
              )}
              <AccordionItem
                title={(
                  <div className="bx--row row">
                    <div className="mb-1 pt-2 ml-1 h6 font-weight-bold text-uppercase text-dark">
                      {t('languageProficiency')}
                    </div>
                  </div>
                  )
                }
                onClick={this.onEduClick}
                open={showEdu && finlandProfileId === null}
                className="p-0"
              >
                <SkillsAndLanguage candidateProfile={candidateProfile} notEditable={notEditable} />
              </AccordionItem> */}
              <hr />
              <div>
                <div className="bx--row row align-items-center">
                  <div>
                    <div className="d-flex align-items-center">
                    <div className="mb-1 pt-2 h6 font-weight-bold text-uppercase text-dark">
                      {t('education')}
                    </div>
                    <Tag
                      type="cyan"
                      className="ml-1 mt-2 mr-1">{`${education.length}`}</Tag>
                    {notEditable === false && (
                      <a
                        onClick={this.onAddEdu}
                        title="add education"
                        className="ml-1 mt-2"
                        href="javascript:void(0)">
                        {t('add')}
                      </a>
                    )}
                    </div>
                    {education.length > 0 ? (
                  <div className="pt-2 ml-2">
                    <ul className="timeline">
                      {education.map((edu, key) => {
                        const k = key;
                        return (
                          <div key={k}>
                            {(edu && (edu.school || edu.qualification || edu.major)) ? (
                              <div className="pb-3">
                                <div className="row pb-0">
                                  <div className="col-xs-12 col-sm-12 col-md-10 bx--row row  text-left">
                                    <div className="text-uppercase bx--row align-items-baseline text-dark ml-4 pl-3 mt-2">
                                      <strong>
                                        <li className="pl-2">
                                          {edu &&
                                            edu.school &&
                                            edu.school.schoolName}
                                        </li>
                                      </strong>
                                      {notEditable === false && (
                                        <div>
                                          <a
                                            onClick={e =>
                                              this.onEditEdu(e, 'edit')
                                            }
                                            name={k}
                                            title="edit"
                                            className="ml-2 mt-0 small"
                                            href="javascript:void(0)">
                                            {t('edit')}
                                          </a>
                                          <a
                                            onClick={e =>
                                              this.onDeleteEdu(e, 'edu')
                                            }
                                            name={k}
                                            className="mt-0"
                                            className="delete-color-text small pl-2"
                                            title="delete school"
                                            href="javascript:void(0)">
                                            {t('delete')}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="ml-3">
                                  <div className="ml-4">
                                    {edu &&
                                    edu.school &&
                                    edu.school.location ? (
                                      <span className="text-capitalize">
                                        {edu &&
                                          edu.school &&
                                          edu.school.location &&
                                          edu.school.location.locationName}
                                        {edu.school.location.country &&
                                        edu.school.location.country.countryFull
                                          ? ','
                                          : null}
                                      </span>
                                    ) : null}
                                    {edu &&
                                    edu.school &&
                                    edu.school.location &&
                                    edu.school.location.country &&
                                    edu.school.location.country.countryFull &&
                                    edu.school.location.country.countryFull.toString() &&
                                    edu.school.location.country.countryFull.toLowerCase() !==
                                      '<unknown>' ? (
                                      <span className="text-capitalize">
                                        &nbsp;
                                        {
                                          edu.school.location.country
                                            .countryFull
                                        }
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="ml-3">
                                  <div className="ml-4">
                                    {edu &&
                                    edu.qualificationtype &&
                                    edu.qualificationtype.typeName ? (
                                      <span className="text-capitalize">
                                        {edu.qualificationtype.typeName}
                                        {edu.qualification &&
                                        edu.qualification.qualificationName
                                          ? ','
                                          : null}
                                      </span>
                                    ) : null}
                                    {edu &&
                                    edu.qualification &&
                                    edu.qualification.qualificationName ? (
                                      <span className="text-capitalize">
                                        &nbsp;
                                        {edu.qualification.qualificationName}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="ml-4">
                                  <div className="ml-3">
                                    {edu && edu.major && edu.major.majorName ? (
                                      <span className="text-capitalize">
                                        {edu.major.majorName}
                                        {edu &&
                                        edu.hourtype &&
                                        edu.hourtype.typeName
                                          ? ','
                                          : null}
                                      </span>
                                    ) : null}
                                    {edu &&
                                    edu.hourtype &&
                                    edu.hourtype.typeName ? (
                                      <span className="p-1 text-capitalize">
                                        &nbsp;{edu.hourtype.typeName}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                {edu && edu.gpa ? (
                                  <div className="ml-3">
                                    <div className="">
                                      <span className="ml-4">
                                        Overall GPA: {edu.gpa}
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {edu && edu.startDate ? (
                                  <div className="text-muted ml-3">
                                    <small className="">
                                      <span className="ml-4">
                                        <i className="fa fa-calendar" />{' '}
                                        {moment(edu.startDate).format(
                                          'MMM Do YYYY'
                                        )}
                                        &nbsp;-&nbsp;
                                        {moment(edu.endDate).isValid()
                                          ? moment(edu.endDate).format(
                                              'MMM Do YYYY'
                                            )
                                          : 'Present'}
                                      </span>
                                    </small>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <div className="  text-dark pb-3">
                      {t('noEducation')}
                    </div>
                  </div>
                )}
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="bx--row row align-items-center">
                  <div>
                  <div className="d-flex align-items-center">
                  <div className="mb-1 pt-2 h6 font-weight-bold text-uppercase text-dark">
                    {t('workExperience')}
                  </div>
                  <Tag
                    type="cyan"
                    className="ml-1 mt-2 mr-1">{`${workexperience.length}`}</Tag>
                  {notEditable === false && (
                    <a
                    onClick={this.onAddExp}
                    title="add experience"
                    className="ml-1 mt-2"
                    href="javascript:void(0)">
                    {t('add')}
                  </a>
                  )}
                </div>
                {workexperience.length > 0 && experienceDetails ? (
                  <div className="pt-2 ml-2">
                    <ul className="timeline">
                      {experienceDetails.map((exp, k) => {
                        const {
                          company = {},
                          experienceId = null,
                          jobId = null,
                          job: {
                            jobname: { nameName = '' } = {},
                            company: { companyName = '' } = {},
                          },
                        } = exp || {};
                        const jobName = `${
                          experienceId !== null ? experienceId : ''
                        }_${jobId !== null ? jobId : ''}`;
                        return (
                          nameName && (
                            <div key={k} className="pb-3">
                              {
                                <div className="row pb-0">
                                  <div className="col-xs-12 col-sm-12 col-md-10 bx--row row text-left">
                                    <div className="text-uppercase bx--row align-items-baseline text-dark ml-4 pl-3 mt-2">
                                      <strong>
                                        <li className="pl-2">{nameName}</li>
                                      </strong>
                                      {notEditable === false && (
                                        <div>
                                          <a
                                            onClick={e =>
                                              this.onEditExp(e, jobName)
                                            }
                                            name={k}
                                            title="edit"
                                            className="ml-2 mt-0 small"
                                            href="javascript:void(0)">
                                            {t('edit')}
                                          </a>
                                          <a
                                            onClick={e =>
                                              this.onDeleteExp(e, jobName)
                                            }
                                            name={k}
                                            className="mt-0 delete-color-text small pl-2"
                                            title="delete company"
                                            href="javascript:void(0)">
                                            {t('delete')}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              }
                              <div className="ml-3">
                                {companyName ? (
                                  <div className="ml-4 text-uppercase">
                                    {companyName}
                                  </div>
                                ) : null}
                              </div>
                              <div className="ml-3">
                                <div className="ml-4">
                                  {exp && exp.job && exp.job.region ? (
                                    <span className="text-capitalize">
                                      {exp.job.region}
                                      {exp.job.country &&
                                      exp.job.country.countryFull.toString() &&
                                      exp.job.country.countryFull.toLowerCase() !==
                                        '<unknown>' &&
                                      exp.job.country.countryFull
                                        ? ','
                                        : null}
                                    </span>
                                  ) : null}
                                  {exp &&
                                  exp.job &&
                                  exp.job.country &&
                                  exp.job.country.countryFull &&
                                  exp.job.country.countryFull.toString() &&
                                  exp.job.country.countryFull.toLowerCase() !==
                                    '<unknown>' ? (
                                    <span className="text-capitalize">
                                      &nbsp;{exp.job.country.countryFull}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="ml-3">
                                <small className="">
                                  <span className="text-muted ml-4">
                                    <i className="fa fa-calendar" />{' '}
                                    {exp.startDate
                                      ? moment(exp && exp.startDate).format(
                                          'MMM Do YYYY'
                                        )
                                      : null}
                                    &nbsp;to&nbsp;
                                    {moment(exp.endDate).isValid()
                                      ? moment(exp && exp.endDate).format(
                                          'MMM Do YYYY'
                                        )
                                      : 'Present'}
                                  </span>
                                </small>
                              </div>
                              {exp && exp.expDesc ? (
                                <div className="ml-3">
                                  <div className="text-justify ml-4">
                                    {exp.expDesc}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          )
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <div className="  text-dark pb-3">
                      {t('noExperience')}
                    </div>
                  </div>
                )}
                  </div>
                  </div>
              </div>
              <hr />
              <div>
                <div className="bx--row row align-items-center">
                  <div>
                    <div className="d-flex align-items-center">
                    <div className="pt-2">
                    <span className="h6 font-weight-bold text-uppercase">{t('certifications')}</span> <span className="text-dark">(Optional)</span>&nbsp;&nbsp;
                  </div>
                  <Tag
                    type="cyan"
                    className="ml-1 mt-2 mr-1">{`${certifications.length}`}</Tag>
                  {notEditable === false && (
                    <a
                    onClick={this.onAddCert}
                    title="add Certifications"
                    className="ml-1 mt-2"
                    href="javascript:void(0)">
                    {t('add')}
                  </a>
                  )}
                    </div>
                    {certifications.length > 0 ? (
                  <div className="pt-2 ml-2">
                    <ul className="timeline">
                      {certifications.map((cert, key) => {
                        const {
                          certificate = {},
                          location: { locationName = '' } = {},
                          startDate,
                          endDate,
                        } = cert;
                        const {
                          certShort = '',
                          certFull = '',
                          organization: { organizationName = '' } = {},
                          certificatetype: { typeName = '' } = {},
                        } = certificate || {};
                        const k = key;
                        return (
                          <div key={k}>
                            {cert !== null && certFull ? (
                              <div className="pb-3">
                                <div className="row pb-0">
                                  <div className="col-xs-12 col-sm-12 col-md-10 bx--row row  text-left">
                                    <div className="text-uppercase bx--row align-items-baseline text-dark ml-4 mt-2 pl-3 bx--row">
                                      <strong>
                                        <li className="pl-2">{certFull}</li>
                                      </strong>
                                      &nbsp;&nbsp;
                                      {certShort && certShort !== null ? (
                                        <span>{`(${certShort})`}</span>
                                      ) : null}
                                      {/* {typeName !== '' && typeName !== null &&<Tag className="small" type="cyan">{typeName}</Tag>} */}
                                      {notEditable === false && (
                                        <div>
                                          <a
                                            onClick={e => this.onEditCert(e)}
                                            name={key}
                                            title="edit"
                                            className="ml-2 mt-0 small"
                                            href="javascript:void(0)">
                                            {t('edit')}
                                          </a>
                                          <a
                                            onClick={e => this.onDeleteCert(e)}
                                            name={key}
                                            className="mt-0 delete-color-text small pl-2"
                                            title="delete school"
                                            href="javascript:void(0)">
                                            {t('delete')}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {typeName ? (
                                  <div className="row pb-0">
                                    <div className="col-xs-12 col-sm-12 col-md-10 text-left ml-3">
                                      <span className="ml-4 text-capitalize">
                                        {typeName}
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="ml-3">
                                  <span className="ml-4 text-capitalize">
                                    {organizationName}
                                    {organizationName ? ',' : null}{' '}
                                    {locationName}
                                  </span>
                                </div>
                                {startDate !== null ? (
                                  <div className="row pb-2">
                                    <small className="col-xs-12 col-sm-12 col-md-10 text-left ml-3">
                                      <span className="text-muted ml-4">
                                        <i className="fa fa-calendar" />{' '}
                                        {moment(startDate).format(
                                          'MMM Do YYYY'
                                        )}
                                        &nbsp;-&nbsp;
                                        {moment(endDate).isValid()
                                          ? moment(endDate).format(
                                              'MMM Do YYYY'
                                            )
                                          : 'Present'}
                                      </span>
                                    </small>
                                  </div>
                                ) : null}
                                {/* { (certFull !== null && certFull !== '')
                                      ? (
                                        <div className="row pb-0 pl-2">
                                          <small className="col-xs-12 col-sm-12 col-md-10 text-left ml-4">
                                            <div className="text-justify">{certShort}</div>
                                          </small>
                                        </div>) : null
                                    } */}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <div className="  text-dark pb-3">
                      {t('noCertification')}
                    </div>
                  </div>
                )}
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="bx--row row align-items-center">
                  <div>
                  <div className="d-flex align-items-center">
                    <div className="mb-1 pt-2 text-dark">
                      <span className="h6 font-weight-bold text-uppercase">{t('projects')}</span> <span className="text-dark">(Optional)</span>&nbsp;&nbsp;
                    </div>
                    <Tag
                      type="cyan"
                      className="ml-1 mt-2 mr-1">{`${projects.length}`}</Tag>
                    {notEditable === false && (
                      <a
                      onClick={this.onAddProj}
                      title="add projects"
                      className="ml-1 mt-2"
                      href="javascript:void(0)">
                      {t('add')}
                    </a>
                    )}
                  </div>
                  {projects.length > 0 ? (
                  <div className="pt-2 ml-2">
                    <ul className="timeline">
                      {projects.map((proj, key) => {
                        const {
                          projectTitle = '',
                          projectDesc = '',
                          startDate = '',
                          endDate = '',
                          // organization = '',
                        } = proj;
                        return (
                          <div key={key}>
                            {proj !== null && projectTitle ? (
                              <div className="pb-3">
                                <div className="row pb-0">
                                  <div className="col-xs-12 col-sm-12 col-md-10 bx--row row  text-left">
                                    <div className="text-uppercase bx--row align-items-baseline text-dark ml-4 mt-2 pl-3 bx--row">
                                      <strong>
                                        <li className="pl-2">{projectTitle}</li>
                                      </strong>
                                      {notEditable === false && (
                                        <div>
                                          <a
                                            onClick={e => this.onEditProj(e)}
                                            name={key}
                                            title="edit"
                                            className="ml-2 mt-0 small"
                                            href="javascript:void(0)">
                                            {t('edit')}
                                          </a>
                                          <a
                                            onClick={e => this.onDeleteProj(e)}
                                            name={key}
                                            className="mt-0 delete-color-text small pl-2"
                                            title="delete project"
                                            href="javascript:void(0)">
                                            {t('delete')}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {/* {
                                      <div className="row pb-0">
                                        <div className="col-xs-12 col-sm-12 col-md-10 text-left ml-2">
                                          <span className="ml-4">
                                            {organization}
                                          </span>
                                        </div>
                                      </div>
                                    } */}
                                {projectDesc !== null && projectDesc !== '' ? (
                                  <div className="row pb-0 pl-2">
                                    <div className="col-xs-12 col-sm-12 col-md-10 text-left ml-4">
                                      <div className="text-justify ml-2">
                                        {projectDesc}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {startDate !== null ? (
                                  <div className="row pb-2">
                                    <small className="col-xs-12 col-sm-12 col-md-10 text-left ml-3">
                                      <span className="text-muted ml-4">
                                        <i className="fa fa-calendar" />{' '}
                                        {moment(startDate).format(
                                          'MMM Do YYYY'
                                        )}
                                        &nbsp;-&nbsp;
                                        {moment(endDate).isValid()
                                          ? moment(endDate).format(
                                              'MMM Do YYYY'
                                            )
                                          : 'Present'}
                                      </span>
                                    </small>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <div className="  text-dark pb-3">
                      {t('noProject')}
                    </div>
                  </div>
                )}
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="bx--row row">
                  <div>
                    <div className="d-flex align-items-center">
                    <div className="mb-1 pt-2 text-dark">
                    <span className="h6 font-weight-bold text-uppercase">CO/Extra-curricular Activities</span> <span className="text-dark">(Optional)</span>&nbsp;&nbsp;
                  </div>
                  <Tag
                    type="cyan"
                    className="ml-1 mt-2 mr-1">{`${activities.length}`}</Tag>
                  {notEditable === false && (
                    <a
                    onClick={this.onAddAct}
                    title="add activities"
                    className="ml-1 mt-2"
                    href="javascript:void(0)">
                    {t('add')}
                  </a>
                  )}
                    </div>
                    {activities !== null && activities.length > 0 ? (
                  <div className="pt-2 ml-2">
                    <ul className="timeline">
                      {activities.map((act, key) => {
                        const {
                          activitytype = {},
                          description = '',
                          startDate = '',
                          endDate = '',
                          activity: { activityName = '' },
                        } = act;
                        return (
                          <div key={key}>
                            {act !== null && activityName ? (
                              <div className="pb-3">
                                <div className="row pb-0">
                                  <div className="col-xs-12 col-sm-12 col-md-10 bx--row row  text-left">
                                    <div className="text-uppercase bx--row align-items-baseline text-dark ml-4 mt-2 pl-3 bx--row">
                                      <strong>
                                        <li className="pl-2">{activityName}</li>
                                      </strong>
                                      {notEditable === false && (
                                        <div>
                                          <a
                                            onClick={e => this.onEditAct(e)}
                                            name={key}
                                            title="edit"
                                            className="ml-2 mt-0 small"
                                            href="javascript:void(0)">
                                            {t('edit')}
                                          </a>
                                          <a
                                            onClick={e => this.onDeleteAct(e)}
                                            name={key}
                                            className="mt-0 delete-color-text small pl-2"
                                            title="delete activity"
                                            href="javascript:void(0)">
                                            {t('delete')}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {
                                  <div className="row pb-0">
                                    <div className="col-xs-12 col-sm-12 col-md-10 text-left ml-3">
                                      <span className="ml-4">
                                        {activitytype.typeName}
                                      </span>
                                    </div>
                                  </div>
                                }
                                {description !== null && description !== '' ? (
                                  <div className="row pb-0 pl-2">
                                    <div className="col-xs-12 col-sm-12 col-md-10 text-left ml-4">
                                      <div className="text-justify ml-2">
                                        {description}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {startDate !== null ? (
                                  <div className="row pb-2">
                                    <small className="col-xs-12 col-sm-12 col-md-10 text-left ml-3">
                                      <span className="text-muted ml-4">
                                        <i className="fa fa-calendar" />{' '}
                                        {moment(startDate).format(
                                          'MMM Do YYYY'
                                        )}
                                        &nbsp;-&nbsp;
                                        {moment(endDate).isValid()
                                          ? moment(endDate).format(
                                              'MMM Do YYYY'
                                            )
                                          : 'Present'}
                                      </span>
                                    </small>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <div className="  text-dark pb-3">
                      {t('noActivity')}
                    </div>
                  </div>
                )}
                  </div>
                </div>

              </div>
              <hr />
              <div>
                <div className="bx--row row">
                  <div>
                    <div className="d-flex align-items-center">
                    <div className="mb-1 pt-2 text-dark">
                    <span className="h6 font-weight-bold text-uppercase">{t('hobbies')}</span> <span className="text-dark">(Optional)</span>&nbsp;&nbsp;
                  </div>
                  <Tag
                    type="cyan"
                    className="ml-1 mt-2 mr-1">{`${hobbies.length}`}</Tag>
                  {notEditable === false && (
                    <a
                    onClick={this.onAddHob}
                    title="add hobbies"
                    className="ml-1 mt-2"
                    href="javascript:void(0)">
                    {t('add')}
                  </a>
                  )}
                    </div>
                    {hobbies.length > 0 ? (
                  <div className="pt-2 ml-2">
                    <ul className="timeline">
                      {hobbies.map((hob, key) => {
                        const {
                          hobby: {
                            hobby = '',
                            playground: { typeName = '' } = {},
                          } = {},
                          // organization = '',
                          hobbytype: { hobbyType = '' } = {},
                        } = hob;
                        return (
                          <div key={key}>
                            {hob !== null && hobby ? (
                              <div className="pb-3">
                                <div className="row pb-0">
                                  <div className="col-xs-12 col-sm-12 col-md-10 bx--row row  text-left">
                                    <div className="text-uppercase bx--row align-items-baseline text-dark ml-4 mt-2 pl-3 bx--row">
                                      <strong>
                                        <li className="pl-2">{hobby}</li>
                                      </strong>
                                      {notEditable === false && (
                                        <div>
                                          <a
                                            onClick={e => this.onEditHob(e)}
                                            name={key}
                                            title="edit"
                                            className="ml-2 mt-0 small"
                                            href="javascript:void(0)">
                                            {t('edit')}
                                          </a>
                                          <a
                                            onClick={e => this.onDeleteHob(e)}
                                            name={key}
                                            className="mt-0 delete-color-text small pl-2"
                                            title="delete hobby"
                                            href="javascript:void(0)">
                                            {t('delete')}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {
                                  <div className="row pb-0">
                                    <div className="col-xs-12 col-sm-12 col-md-10 text-left ml-3">
                                      <span className="ml-4">{hobbyType}</span>
                                    </div>
                                  </div>
                                }
                                {typeName !== null && typeName !== '' ? (
                                  <div className="row pb-0 pl-2">
                                    <div className="col-xs-12 col-sm-12 col-md-10 text-left ml-4">
                                      <div className="text-justify ml-2">
                                        {typeName}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div className="  text-dark pb-3">
                    {t('noHobby')}
                  </div>
                )}
                  </div>
                </div>

              </div>
              <hr />
              {/* {!noBio && (
                <AccordionItem
                  title={(
                    <div className="bx--row row">
                      <div className="mb-1 pt-2 ml-0 h6 font-weight-bold text-uppercase text-dark">
                        {t('bioInformation')}
                      </div>
                    </div>
                  )}
                  className="p-0"
                >
                  <CandidateBio disabled />
                </AccordionItem>
              )} */}
              {/* {!noBio && roleId && Number(roleId) === 3 && (
                <AccordionItem
                  title={(
                    <div className="bx--row row">
                      <div className="mb-1 pt-2 ml-0 h6 font-weight-bold text-uppercase text-dark">
                        {t('jobPreferences')}
                      </div>
                    </div>
                  )}
                  className="p-0"
                >
                  <div>
                    <div class="bx--type-zeta text-dark bx--col-lg-4 mb-2 mt-2">{t('preferredCitiesPreference')}</div>
                    <div className="col-xs-12 col-sm-12 text-left">
                      { cities.map(({ label = '' } = {}, key) => (
                        <Tag key={key} type="blue">
                          <div className="text-uppercase">
                            <div className="text-center bx--row m-0 pt-1 pb-1">
                              <div>{label}</div>
                            </div>
                          </div>
                        </Tag>
                      ))}
                    </div>
                    {(this.props.neonPoly) && (
                      <Fragment>
                        <hr className="mt-2 mb-2" />
                        {
                          preferenceTaskOriented !== 0 && (
                            <PercentageSlider disabled hideSideLabels minLabel={t('taskOrientedPreference')} maxLabel={t('independentPreference')} value={Number(this.state.preferenceTaskOriented)} name="preferenceTaskOriented" onChange={() => {}} />
                          )
                        }
                        {
                          preferenceBackoffice !== 0
                          && (
                            <PercentageSlider disabled hideSideLabels minLabel={t('backEndPreference')} maxLabel={t('frontEndPreference')} value={Number(this.state.preferenceBackoffice)} name="preferenceBackoffice" onChange={() => {}} />
                          )
                        }
                        {
                          preferenceTryNew !== 0
                            && (
                              <PercentageSlider disabled hideSideLabels minLabel={t('tryNewPreference')} maxLabel={t('enhanceFutherEnhanceSkills')} value={Number(this.state.preferenceTryNew)} name="preferenceTryNew" onChange={() => {}} />
                            )
                        }
                        <hr className="mt-2 mb-2" />
                        <JobPreferences hide={['citypreferences', 'jobMatches']} disabled industries={IndustryPreferences} cityPreferences={this.state.cityPreferences} functions={jobFunctionPreferences} jobs={jobPreferences} />
                      </Fragment>
                    )}
                  </div>
                </AccordionItem>
              )} */}
            </Accordion>
            <JDProcessOverlay
              show={this.props.loading || !this.props.isProfileLoaded}
              message="processing..."
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  skillsOptions: state.jobCreation && state.jobCreation.skills,
  candidateProfile: state.roboroyApplications.candidateProfile,
  countries: state.companyAndOffice.countries || state.roboroyApplications.countries,
  loading: state.roboroyApplications.loading,
  candidateDetails: state.candidateDetails,
  companies: state.companyAndOffice.companies,
  finlandProfile: state.finlandProfile,
  isProfileLoaded: state.roboroyApplications.isProfileLoaded,
  preferredSkills: state.StudentProfile.preferredSkills,
  neonPoly: true,
});

const mapDispatchToProps = {
  fetchAll,
  fetchCompanyId,
  getAllDropDownData,
  deleteProfileSpecificInfo,
  updateProfileSpecificInfo,
  createProfileSpecificInfo,
  getFewDropDownData,
  createNewOption,
  getRowDataById,
  getCandidateProfile,
  manageSkill,
  updateProfile,
  getAllCompanies,
  getFinlandProfile,
  updateFinlandProfile,
  // managePersonHobbies,
  // managePersonActivities,
  createFinlandProfileSpecificInfo,
  updateFinlandProfileSpecificInfo,
  deleteFinlandProfileSpecificInfo,
  getSkillsPreferredList,
  xSearchCompanies,
  xCreateCompany,
  getAllCountries,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(CandidateDetails))
);
