import { withTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import SetMixPanel from 'components/common/GetCurrentPath';
import './editJob.css'

const LinkBox = ({ details }) => {
    const { url, linkTitle, title, push, classNames } = details;
    return (
      <div className={`text-center ${classNames}`}>
        <Link
          className="mr-1 bx--type-beta font-weight-light xpa-link"
          to={url}
        >
          {linkTitle}
        </Link>
        {/* <div
          className="mr-1 bx--type-beta font-weight-light xpa-link"
          onClick={() => {
            push &&
              push(url);
          }}>
          
        </div> */}
        <div className="text-dark">{title}</div>
      </div>
    );
  };

export default LinkBox;
