import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import StarRatingComponent from 'react-star-rating-component';

const SkillsCompetency = (props) => {
  const {
    skills,
    onStarClick,
  } = props;
  return (
    <Fragment>
      {
        skills
        && Array.isArray(skills)
        && skills.length > 0
        && skills.map((res) => {
          const {
            personSkillId,
            rating,
            skill: {
              skillName,
            } = {},
          } = res || {};
          return (
            <div className="d-flex justify-content-between">
              <div className="text-capitalize text-dark">
                {skillName}
              </div>
              <div>
                <StarRatingComponent
                  name={personSkillId}
                  id={personSkillId}
                  starCount={5}
                  value={Number(rating)}
                  starColor="#5596e6"
                  emptyStarColor="#ddd"
                  onStarClick={(nextValue) => { onStarClick(nextValue, personSkillId, skillName); }}
                  renderStarIcon={(index, val) => (
                    <span>
                      <i className={index <= val ? 'fa fa-thumbs-up mr-1' : 'fa fa-thumbs-up  mr-1'} />
                    </span>
                  )
                }
                />
              </div>
            </div>
          );
        })
      }
    </Fragment>
  );
};

export default (withTranslation()(SkillsCompetency));
