import {
    REQUEST_GET_COMPANIES_USERS,
    ERROR_GET_COMPANIES_USERS,
    SUCCESS_GET_COMPANIES_USERS
} from 'actions/actionTypes';

const initalState = {
    loading: false,
    error: null,
    companiesUsers: [],
    count: 0,
};

const CompaniesUsers = (state = initalState, action) => {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_GET_COMPANIES_USERS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SUCCESS_GET_COMPANIES_USERS:
            return {
                ...state,
                companiesUsers: payload && payload.users,
                count: payload && payload.count,
                loading: false,
                error: null,
            };
        case ERROR_GET_COMPANIES_USERS:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
};

export default CompaniesUsers;
