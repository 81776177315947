import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import {
  getAuthorizedCompanies,
} from 'actions/admin/CompanyAndOffice';
import {
  DataTable, Search, Pagination,
  Tile, InlineNotification
} from 'carbon-components-react';
import { companiesCard } from 'containers/roboroy/RoboroyCards';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

const {
  TableContainer, Table, TableRow,
  TableBody, TableCell,
} = DataTable;

const NoContentBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no companies to show for your current selection.</p>
    </div>
  </Tile>
);

class CompaniesList extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'companies', name: 'Managed Companies', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      rowsData: [],
      headersInfo: [{ key: 'company', header: 'Company' }],
      activePage: 1,
      itemsPerPage: 10,
      keyword: '',
    };
  }

  componentDidMount() {
    this.setState({
      activePage: 1,
      itemsPerPage: 10,
    }, () => {
      const data = {
        activePage: this.state.activePage,
        itemsPerPage: this.state.itemsPerPage,
        searchKey: this.state.keyword,
        status: 'accepted',
      };
      this.props.getAuthorizedCompanies(data).then(() => {
        this.setRows();
      });
    });
  }

  setRows = () => {
    const {
      authorizedCompanies: {
        managedCompanies,
      } = {},
    } = this.props;
    const { companies } = managedCompanies || {};
    const finalData = (companies && Array.isArray(companies)
      && companies.length > 0 && companies.map((res) => {
      const RowData = {
        id: (res && res.company && res.company.companyId) || 0,
        name: (res && res.company && res.company.companyName) || '',
        open: (res && res.jobs && res.jobs.openjobs) || 0,
        closed: (res && res.jobs && res.jobs.closejobs) || 0,
        shared: (res && res.jobs && res.jobs.sharejobs) || 0,
        isParentCompany : (res.isParentCompany || false),
        logo: (res && res.company && res.company.companyinfo && res.company.companyinfo.logo),
      };
      const oneRow = { id: RowData.id.toString(), company: companiesCard(RowData) };
      return oneRow;
    })) || [];
    this.setState({ rowsData: finalData });
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage }, () => {
      const currData = {
        itemsPerPage,
        activePage: this.state.activePage,
        searchKey: this.state.keyword,
        status: 'accepted',
      };
      this.props.getAuthorizedCompanies(currData).then(() => {
        this.setRows();
      });
    });
  };

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      const data = {
        itemsPerPage: this.state.itemsPerPage,
        activePage: this.state.activePage,
        searchKey: this.state.keyword,
        status: 'accepted',
      };
      this.searchTimer = setTimeout(() => this.props.getAuthorizedCompanies(data).then(() => this.setRows()), 1000);
    });
  };

  render() {
    const {
      authorizedCompanies: {
        managedCompanies,
      } = {},
    } = this.props;
    const { count } = managedCompanies || {};
    const {
      rowsData, headersInfo, keyword, activePage,
    } = this.state;
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <h4 className="font-weight-bold text-capitalixe mb-0 bx--type-gamma">Managed Companies</h4>
        <InlineNotification lowContrast
            title={`In this section, you can see the list of companies that your organization is currently managing.`}
            subtitle=""
            iconDescription="describes the close button"
            kind="info"
            hideCloseButton
        />                  
        <div className="bx--row">
          <div className="mt-2 ml-3 bx--col-lg-7 p-0 bx--col-xs-12">
            <Search
              light
              type="text"
              style={{ maxWidth: 500 }}
              className="p-0 col-lg-6"
              labelText="Search job"
              closeButtonLabelText=""
              placeHolderText="Search Companies"
              onChange={(e) => this.handleSearchSubmit(e)}
              id="search company"
              value={keyword}
            />
          </div>
        </div>
        <DataTable
          rows={rowsData}
          headers={headersInfo}
          render={({ rows, headers, getHeaderProps }) => (
            <TableContainer title="" className="bg-transparent mt-2" style={{ overflow: 'hidden' }}>
              <Table className="bg-transparent" style={{ borderSpacing: '0px 6px', borderCollapse: 'separate' }}>
                <TableBody className="bg-transparent">
                  {rows.map(row => (
                    <TableRow key={row.id} className="mb-2">
                      {row.cells.map(cell => (
                        <TableCell
                          key={cell.id}
                          className="pr-0 pl-0"
                        >
                          {cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        />
        {
          rowsData && rowsData.length <= 0 && <NoContentBox />
        }
        {
          rowsData && rowsData.length > 0 && (
            <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
          )
        }
        <JDProcessOverlay show={(this.props && this.props.loading)} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  authorizedCompanies: state.companyAndOffice.authorizedCompanies,
  loading: state.companyAndOffice.loading,
});

const mapDispatchToProps = {
  getAuthorizedCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList);
