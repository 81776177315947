import { useTranslation, withTranslation  } from 'react-i18next';
import React, { Fragment } from 'react';
import moment from 'moment';
import SetMixPanel from 'components/common/GetCurrentPath';

const track = {
  ROBOROY_DASHBOARD_APPLICANT_CLICKED: 'ROBOROY_DASHBOARD_APPLICANT_CLICKED',
  ROBOROY_DASHBOARD_APPLICANTJOB_CLICKED: 'ROBOROY_DASHBOARD_APPLICANTJOB_CLICKED',
};

const NoJobMessageBox = ({ croute, t = useTranslation().t }) => (
  <div className="card-body text-center">
    <div className="empty">
      <p className="empty-title h5">
        {croute === 'invites' ? t('noJobInvites') : null}
        {croute === 'invites-accepted' ? t('noAcceptedJobs') : null}
        {croute === 'invites-rejected' ? t('noRejectedJobs') : null}
        {croute === 'invites-pending' ? t('noPendingJobs') : null}
        {croute === 'applied' ? t('noAppliedJobs') : null}
        {croute === 'accepted' ? t('notAcceptedJobs') : null}
        {croute === 'rejected' ? t('noInvitesRejected') : null}
        {croute === 'screening' ? t('noInvitesPending') : null}
        {croute === 'videointerview' ? t('noInProgressVideoInterviews') : null}
        {croute === 'onsiteinterview-inprogress' ? t('noInProgressOnsiteInterviews') : null}
        {croute === 'onsiteinterview-completed' ? t('noCompletedOnsiteInterviews') : null}
        {croute === 'phoneinterview' ? t('noInProgressPhoneInterviews') : null}
        {croute === 'writtenassessment' ? t('noInProgressAssessments') : null}
        {croute === 'offer-invites' ? t('noOffers') : null}
        {croute === 'offer-inprogress' ? t('noInProgressOffers') : null}
        {croute === 'offer-completed' ? t('noCompletedOffers') : null}
      </p>
    </div>
  </div>
);

const CandidateJobsList = ({
  sortedJobs,
  status,
  count,
  renderApplInvitation,
  renderOfferInvitation,
}) => (
  <Fragment>
    {
      sortedJobs && Array.isArray(sortedJobs)
      && sortedJobs.length > 0
      && sortedJobs.map((job, idx) => {
        const {
          appId,
          jobId,
          job: {
            openDate,
            jobname: {
              nameName,
            } = {},
            company: {
              companyName,
              displayCompanyName
            } = {},
            country: {
              countryFull,
            } = {},
          } = {},
        } = job || {};
        const { t } = useTranslation();
        return (
          <Fragment>
            <div className={`bx--row align-items-center m-0 ${((sortedJobs.length - 1) === idx) && 'pb-2'}`}>
              <div className="ml-3 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
              </div>
              {
                status && status !== 'offer-inprogress' && status !== 'offer-completed'
                  && (
                    <div className="text-capitalize ml-1 bx--col-lg-2 bx--col-md-10 pl-0 bx--col-sm-3 xpa-name-overflow bx--col-xs-3 mt-2">
                      <a href={`/app/campus/job/${jobId}?appId=${appId}`} className="font-weight-bold" onClick={() => SetMixPanel(track.ROBOROY_DASHBOARD_JOBS_CLICKED)}>{nameName}</a>
                      <span className="ml-1">({`${jobId}`})</span>
                    </div>
                  )
              }
              {
                status && (status === 'offer-inprogress' || status === 'offer-completed')
                  && (
                    <div className="text-capitalize ml-1 bx--col-lg-2 bx--col-md-10 pl-0 bx--col-sm-3 xpa-name-overflow bx--col-xs-3 mt-2">
                      <a href={`/app/campus/candidate/applications/${appId}/offers`} className="font-weight-bold" onClick={() => SetMixPanel(track.ROBOROY_DASHBOARD_JOBS_CLICKED)}>{nameName}</a>
                      <span className="ml-1">({`${jobId}`})</span>
                    </div>
                  )
              }
              <div className="bx--col-lg-2 bx--col-md-12 bx--col-xs-12 bx--col-sm-12 mt-2">
                <span className="bx--tag bx--tag--blue">{t('internship')}</span>
              </div>
              <div className="ml-2 mt-2 font-weight-bold text-dark bx--col-lg-2 bx--col-md-12 bx--col-xs-12 bx--col-sm-12 text-capitalize">
                {displayCompanyName}
              </div>
              <div className="ml-2 mt-1 text-dark bx--col-lg-1 bx--col-md-12 bx--col-xs-12 bx--col-sm-12 text-capitalize">
                <div className="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 16, height: 16 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin">
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                      <circle cx="12" cy="10" r="3" />
                    </svg>
                  </div>
                  <div className="ml-1 mt-1">{countryFull}</div>
                </div>
              </div>
              <div className="bx--col-lg-2 ml-2 bx--col-sm-12 mt-2">
                <div className="emailInvite">
                  <div className="column pt-0">
                    <div className="clearfix">
                      {
                        (status
                          && ((status === 'offer-invites') || (status === 'offer-inprogress') || (status === 'offer-completed')))
                          && (
                            <div>
                              {
                                job
                                && job.offerConsentSent === null
                                && job.offerConsentAccept === null ? <span>-</span> : ''
                              }
                              {/* {
                                job
                                  && job.offerConsentSent === true
                                  && job.offerConsentAccept === null
                                  && renderOfferInvitation(job.appId)
                              } */}
                              {/* {job.offerConsentAccept === false ? (
                                <div>
                                  <div className="btn btn-sm btn-outline-danger">{t('invitesRejectedTab')}</div>
                                </div>
                              ) : null}
                              {job.offerConsentAccept === true ? (
                                <div>
                                  <div className="btn btn-sm btn-outline-success">{t('invitesAcceptedTab')}</div>
                                </div>
                              ) : null} */}
                            </div>
                          )
                          // : (
                          //   <div>
                          //     {job && job.inviteaccept === null && job.invitesent === null ? <span>-</span> : ''}
                          //     {job && job.inviteaccept === null
                          //       && job.invitesent === true
                          //       && renderApplInvitation(job.appId)}
                          //     {job && job.inviteaccept === false ? (
                          //       <div>
                          //         <div className="btn btn-sm btn-outline-danger">{t('invitesRejectedTab')}</div>
                          //       </div>
                          //     ) : null}
                          //     {job && job.inviteaccept === true ? (
                          //       <div>
                          //         <div className="btn btn-sm btn-outline-success">{t('invitesAcceptedTab')}</div>
                          //       </div>
                          //     ) : null}
                          //   </div>
                          // )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 ml-3 text-dark text-right">
                {moment(openDate).fromNow()}
              </div>
            </div>
            {
              sortedJobs && !((sortedJobs.length - 1) === idx) && <hr className="mb-2 mt-1" />
            }
          </Fragment>
        );
      })
    }
    {
      count <= 0 && <NoJobMessageBox croute={status} />
    }
  </Fragment>
);

export default CandidateJobsList;
