import { withTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'carbon-components-react';

import {
  getCurrentStageInProgress,
  getCurrentSubStageInProgress,
  isCurrentUserAdminLevelCollaborator,
} from 'components/roboroy/WorkflowModule/workflowUtils';

const WorkflowAction = ({
  application, handleWorkflowStatusUpdate, handleWorkflowSetup,
  isCandidateFlow = false, handleOpenEmailModal, activeSendOfferModal,
  user, jobDetails,
}) => {
  const currentStage = application && application.workflow && getCurrentStageInProgress(application.workflow);
  let currentSubStage;
  if (currentStage) {
    currentSubStage = getCurrentSubStageInProgress(currentStage.stage);
  }
  const acceptedStates = [6000, 6002, 6005];
  const rejectedStates = [6001, 6003, 6004];

  const {
    appId,
    jobId,
    profile: { email } = {},
    fromApplicant,
    shortlisted,
    invitesent,
    inviteaccept,
    workflow,
    collaboration,
    applicationsource,
    applicationemail,
    offerConsentSent,
    offerConsentAccept,
  } = application || {};
  const {
    isAutoPilot = false,
  } = jobDetails && jobDetails[0] || [];
  const { sourceName = '' } = applicationsource || {};
  const applicationSourceName = (sourceName && ((''+sourceName).toLowerCase())) || '';
  const introEmailArr = applicationemail && Array.isArray(applicationemail)
  && applicationemail.filter((e) => e.templateName === 'passive-introduction-email');
  const introFollowUpEmailArr = applicationemail && Array.isArray(applicationemail)
  && applicationemail.filter((e) => e.templateName === 'passive-followup-email');  
  const rejectEmailArr = applicationemail && Array.isArray(applicationemail)
  && applicationemail.filter((e) => e.templateName === 'reject');
  const sendOfferConsentArr = applicationemail && Array.isArray(applicationemail)
  && applicationemail.filter((e) => e.templateName === 'recruiter-sends-consent');
  const rejectOfferConsentArr = applicationemail && Array.isArray(applicationemail)
  && applicationemail.filter((e) => e.templateName === 'recruiter-rejects-interview');
  const canSendRejectEmail = rejectEmailArr && Array.isArray(rejectEmailArr) && (rejectEmailArr.length === 0);
  return (
    <div>
      {
        user && user.roleId !== 3
          && (
            <div>
              {
                !isCandidateFlow && !fromApplicant && (invitesent === null)
                // && (shortlisted !== true)
                && email
                && (applicationSourceName === 'ai sourcing') && (
                <span>
                  {
                    introEmailArr && Array.isArray(introEmailArr) && (introEmailArr.length > 0)
                    && (
                    <Button
                      small
                      kind="tertiary"
                      className="ml-md-2"
                      disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
                      onClick={() => handleOpenEmailModal({
                        name: 'arya-invite-sent',
                        template: 'passive-followup-email',
                        source: applicationSourceName,
                        appId,
                        jobId,
                      })}
                    >
                      Introduction - Follow up #{introFollowUpEmailArr.length+1}
                    </Button>)
                  }
                  {
            !introEmailArr || (introEmailArr && Array.isArray(introEmailArr) && (introEmailArr.length === 0)) && email
            && (shortlisted == true) && (
              <Button
                small
                className="ml-md-2 mr-md-0 mr-2"
                kind="tertiary"
                disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
                onClick={() => handleOpenEmailModal({
                  name: 'arya-invite-not-sent',
                  template: 'passive-introduction-email',
                  source: applicationSourceName,
                  appId,
                  jobId,
                })}
              >
              Send Introduction
              </Button>)
          }
        </span>
        )
      }
      {
        canSendRejectEmail && (shortlisted === false) && email && (
        <span>
          {
            ((applicationSourceName !== 'ai sourcing') || (workflow && workflow.outcomeId
              && (rejectedStates.includes(Number(workflow && workflow.outcomeId))) === true)) && (
              <Button
                small
                className="ml-md-2"
                disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
                kind="danger"
                onClick={() => handleOpenEmailModal({
                  name: 'rejected',
                  template: 'reject',
                  source: applicationSourceName,
                  appId,
                  jobId,
                })}
              >
                Send reject email
              </Button>
            )
          }
          
        </span>)
      }
      {
        !isCandidateFlow && email && (invitesent === null) && !isAutoPilot
        && (shortlisted && (shortlisted === true)) && (inviteaccept === null) && (
          <Button
            small
            className="ml-md-2"
            disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
            kind="tertiary"
            onClick={() => handleOpenEmailModal({
              name: 'shortlisted-invite-not-sent',
              template: 'shortlist',
              source: applicationSourceName,
              appId,
              jobId,
            })}
          >
            Send Shortlist Email
          </Button>
        )
      }
      {
        !isCandidateFlow && shortlisted && email && !isAutoPilot
        && (shortlisted === true) && (invitesent === true)
        && (inviteaccept === null) && (
          <Button
            small
            className="ml-md-2"
            disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
            onClick={() => handleOpenEmailModal({
              name: 'shortlisted-invite-sent',
              template: 'shortlist',
              source: applicationSourceName,
              appId,
              jobId,
            })}
            kind="tertiary"
          >
            Resend Shortlist Email
          </Button>
        )
      }
      {
        shortlisted && ((inviteaccept && (inviteaccept === true))) 
        && (!workflow) && (
          <span>
            {
              isCandidateFlow && (
                <div className="badge badge-outline-dark">
                Initiated
                </div>)
            }
            {
              !isCandidateFlow && (
              <Button
                small
                disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
                onClick={() => handleWorkflowSetup(application, appId)}
                onFocus={() => { }}
                className="xpa--workflow-click ml-md-2"
                kind="tertiary"
                icon={
                  {
                    id: 'icon--settings',
                    name: 'icon--settings',
                    tags: 'icon--settings',
                    styles: '',
                    viewBox: '0 0 15 16',
                    width: '15',
                    height: '16',
                    svgData: {
                      circles: '',
                      ellipses: '',
                      paths: [
                        {
                          d: 'M7.5 10.5C8.9 10.5 10 9.4 10 8S8.9 5.5 7.5 5.5 5 6.6 5 8s1.2 2.5 2.5 2.5zm0 1C5.6 11.5 4 9.9 4 8s1.6-3.5 3.5-3.5S11 6.1 11 8s-1.5 3.5-3.5 3.5z',
                        },
                        {
                          d: 'M6.3 2.6l-.3.1c-.7.2-1.3.5-1.9 1l-.2.2-1.4-.8-1.3 1.8L2.4 6l-.1.2C2.1 6.8 2 7.4 2 8c0 .3 0 .7.1 1l.1.3-1.2 1L2.2 12l1.1-.6.3.3c.6.8 1.4 1.3 2.4 1.6l.3.1.2 1.6h2.1l.2-1.6.3-.1c.8-.2 1.6-.7 2.2-1.3l.3-.2 1.1.6 1.2-1.7-1.1-1 .1-.3c.1-.5.1-1 .1-1.4 0-.5-.1-1.1-.2-1.6l-.1-.3 1.2-1-1.2-1.7-1.4.6-.3-.2c-.6-.5-1.3-.9-2-1.1l-.2-.1L8.6 1H6.5l-.2 1.6zM5.5.9c0-.5.5-.9 1-.9h2.1c.5 0 .9.4 1 .9l.1 1c.6.2 1.2.5 1.8 1l.7-.4c.4-.2 1-.1 1.3.3l1.2 1.7c.3.4.2 1-.1 1.3l-.7.6c.1.5.1 1.1.1 1.6 0 .4 0 .8-.1 1.3l.6.6c.4.3.4.9.1 1.3l-1.2 1.7c-.3.4-.8.5-1.3.3l-.4-.2c-.6.5-1.3.9-2 1.2l-.1 1c-.1.5-.5.9-1 .9H6.5c-.5 0-.9-.4-1-.9l-.1-1c-.8-.3-1.6-.8-2.3-1.4l-.4.2c-.4.2-1 .1-1.3-.3L.2 11c-.3-.4-.2-1 .1-1.3l.8-.7c0-.4-.1-.7-.1-1 0-.6.1-1.2.2-1.8l-.6-.6C.2 5.3.1 4.7.4 4.3l1.2-1.7c.3-.5.9-.6 1.3-.4l.9.5c.5-.3 1-.6 1.6-.8l.1-1z',
                        },
                      ],
                      polygons: '',
                      polylines: '',
                      rects: '',
                    },
                  }
                }
                iconDescription="Add"
              >
                Setup Workflow
              </Button>)
            }
          </span>
        )
      }
      {
        shortlisted && (inviteaccept === true)
        && (workflow) && (
          <span>
            {/* {
              workflow.outcomeId
              && (acceptedStates.includes(Number(workflow.outcomeId))) && (
                <div className="badge badge-outline-success">
                  Accepted
                </div>
              )
            }
            {
              workflow.outcomeId
              && (rejectedStates.includes(Number(workflow.outcomeId))) && (
                <div className="badge badge-outline-danger">
                  Rejected
                </div>
              )
            } */}
            {
              isCandidateFlow && !workflow.outcomeId && (
                <div className="badge badge-outline-warning">
                  In progress
                </div>
              )
            }
            {
              !isCandidateFlow && workflow && !workflow.outcomeId && currentSubStage
              && currentSubStage.subStage && currentSubStage.subStage.isAssignedToCurrentUser && (
                <Button
                  small
                  onClick={() => handleWorkflowStatusUpdate(application, appId, workflow)}
                  onFocus={() => { }}
                  className="xpa--workflow-click ml-md-2"
                  kind="danger"
                  icon={
                    {
                      "id": "icon--warning--outline",
                      "name": "icon--warning--outline",
                      "tags": "icon--warning--outline",
                      "styles": "",
                      "viewBox": "0 0 16 16",
                      "width": "16",
                      "height": "16",
                      "svgData": {
                        "circles": "",
                        "ellipses": "",
                        "paths": [
                          {
                            "d": "M7.25 6h1.5v5h-1.5z"
                          },
                          {
                            "d": "M.75 16a.75.75 0 0 1-.67-1.085L7.33.415a.75.75 0 0 1 1.34 0l7.25 14.5A.75.75 0 0 1 15.25 16H.75zm1.214-1.5h12.072L8 2.427 1.964 14.5z",
                            "fill-rule": "nonzero"
                          },
                          {
                            "d": "M7 12.75a1 1 0 1 1 2 0 1 1 0 1 1-2 0"
                          }
                        ],
                        "polygons": "",
                        "polylines": "",
                        "rects": ""
                      }
                    }
                  }
                  iconDescription="Add"
                >
                  Action Required
                </Button>
              )
            }
            {/* {
              ((currentSubStage && currentSubStage.subStage
                && !currentSubStage.subStage.isAssignedToCurrentUser) || (workflow && workflow.outcomeId))
                && (
                  <Button
                    small
                    onClick={() => handleWorkflowStatusUpdate(application, appId, workflow)}
                    onFocus={() => { }}
                    className="xpa--workflow-click ml-md-2"
                    kind="tertiary"
                    icon={
                      {
                        "id": "icon--visibility-on",
                        "name": "icon--visibility-on",
                        "tags": "icon--visibility-on",
                        "styles": "",
                        "viewBox": "0 0 16 11",
                        "width": "16",
                        "height": "11",
                        "svgData": {
                          "circles": "",
                          "ellipses": "",
                          "paths": [
                            {
                                "d": "M8 7.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 1c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                            },
                            {
                                "d": "M8 10c2.8 0 5.1-1.5 6.9-4.6C13.1 2.5 10.8 1 8 1 5.2 1 3 2.4 1.2 5.4 2.9 8.6 5.2 10 8 10zM8 0c3.3 0 6 1.8 8.1 5.4C14 9.2 11.3 11 8 11S2 9.2 0 5.5C2 1.9 4.6 0 8 0z"
                            }
                          ],
                          "polygons": "",
                          "polylines": "",
                          "rects": ""
                        }
                      }
                    }
                    iconDescription="Add"
                  >
                    View Interview Status
                  </Button>
                )
            } */}
            <span>
              {
                workflow && workflow.outcomeId
                && (acceptedStates.includes(Number(workflow && workflow.outcomeId)))
                && offerConsentSent === null
                  && (
                    <Button
                      className="ml-md-2"
                      small
                      disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
                      kind="tertiary"
                      onClick={() => handleOpenEmailModal({
                        name: 'recruiter-sends-consent',
                        template: 'recruiter-sends-consent',
                        source: applicationSourceName,
                        appId,
                        jobId,
                      })}
                      onFocus={() => { }}
                    >
                      Send Offer Consent
                    </Button>
                  )
              }
            </span>
            <span>
              {
                workflow && workflow.outcomeId
                && (acceptedStates.includes(Number(workflow && workflow.outcomeId)))
                && offerConsentSent === true
                && offerConsentAccept === null
                  && (
                    <Button
                      className="ml-md-2"
                      small
                      disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
                      kind="tertiary"
                      onClick={() => handleOpenEmailModal({
                        name: 'recruiter-sends-consent',
                        template: 'recruiter-sends-consent',
                        source: applicationSourceName,
                        appId,
                        jobId,
                      })}
                      onFocus={() => { }}
                    >
                      Resend Offer Consent
                    </Button>
                  )
              }
            </span>
            <span>
              {
                workflow.outcomeId
                && (rejectedStates.includes(Number(workflow.outcomeId)))
                && rejectOfferConsentArr && Array.isArray(rejectOfferConsentArr)
                && rejectOfferConsentArr.length <= 0
                && offerConsentSent === null
                && offerConsentAccept === null
                  && (
                    <Button
                      small
                      disabled={user && user.roleId && Number(user.roleId) !== 3 ? false : true}
                      className="ml-md-2"
                      kind="danger"
                      onClick={() => handleOpenEmailModal({
                        name: 'recruiter-reject-interview',
                        template: 'recruiter-rejects-interview',
                        source: applicationSourceName,
                        appId,
                        jobId,
                      })}
                      onFocus={() => { }}
                    >
                      Send Reject Email
                    </Button>
                  )
              }
            </span>
          </span>
        )
      }
      {
        (shortlisted === null) && (applicationSourceName !== 'ai sourcing') && (
        <div>
        &nbsp;
        </div>)
      }
    </div>
          )
      }
    </div>
    );
};

export default WorkflowAction;
