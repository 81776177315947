import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import GeipDetails from './geipDetails';

import { registerStudent } from 'actions/campus/registrationRequest';
import InternCandidate from '../../../robocampus/internCandidate';

import geiImage from 'assets/images/gei-full-colour-rgb-horizontal.jpg';

class RegisterPublicCandidate extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
    { id: 'register', name: 'Registration', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      t,
      // user: {
      //   profileId,
      // } = {},
      taskId,
      history: {
        push,
      } = {},
      match: {
        params: {
          profileId,
          indexId,
        } = {},
      } = {},
      countries,
    } = this.props;
    let percent = 0;
    if (indexId && Number(indexId) === 0) {
      percent = 2;
    } else if (indexId && Number(indexId) === 1) {
      percent = 10;
    } else if (indexId && Number(indexId) === 2) {
      percent = 19;
    } else if (indexId && Number(indexId) === 3) {
      percent = 25;
    }
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        {indexId>=0 && indexId<=3 &&
          <div>
            <div>
              <h3 className="bx--col-12 text-justify text-uppercase bx--type-delta">{t('candidateDetailsTitle')}</h3>
              <div className="mb-2 p-1">
                <Progress
                  className="bx--col mt-2 xpa-react-symbol pl-0"
                  theme={
                    {
                      active: {
                        symbol: <div className="bx--type-zeta">{`${t('step')} ${Number(indexId) + 1}/10`}</div>,
                        trailColor: '#f4f7fb',
                        color: '#5596e6',
                      },
                      success: {
                        symbol: <div className="bx--type-zeta">{t('offersCompletedTab')}</div>,
                        trailColor: 'lime',
                        color: 'green',
                      },
                    }
                  }
                  percent={percent}
                />
              </div>
            </div>
            <hr />
            <div className="mt-2 mb-2">
              <span className="text-dark bx--type-zeta">{t('studentRegMsg')}</span>
              <b>
                <span className="text-dark font-weight-bold ml-2 mt-0">
                  <a href={'mailto:askGIA@np.edu.sg'}>askGIA@np.edu.sg</a>
                </span>
              </b>
            </div>
            <hr />
            <div className="d-flex h-100 justify-content-center align-items-center">
              <div className="w-100">
                <div className="font-weight-bold text-center mb-4 bx--type-delta">
                  <h5>{t('registerGeipHeading')}</h5>
                </div>
                <GeipDetails allCountries={countries} disabled={false} profileId={profileId} push={push} taskId={taskId} indexId={indexId} ref="geipDetails" />
              </div>
            </div>
          </div>
        }
        {indexId>=4 && 
          <div>
            <InternCandidate history={this.props.history} stageIndex={indexId-3} push={push} profileId={profileId}/>
          </div>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  countries: state.RegistrationRequest.countries,
  taskId: state.resumeUpload.taskId,
});

const mapDispatchToProps = {
  registerStudent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(withTranslation()(RegisterPublicCandidate));
