import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import RoboSidebar from 'components/roboroy/Sidebar';
import FinalOfferCard from './FinalOfferCard';
import './FinalOfferDetails.css';

const cardData = [
  {
    name: 'jon doe',
    cv: 'https://gogole.com',
    tel: '+65 1333 2323',
    email: 'jon@example.com',
  },
  {
    name: 'jon doe',
    cv: 'https://gogole.com',
    tel: '+65 1333 2323',
    email: 'jon@example.com',
  },
  {
    name: 'jon doe',
    cv: 'https://gogole.com',
    tel: '+65 1333 2323',
    email: 'jon@example.com',
  },
  {
    name: 'jon doe',
    cv: 'https://gogole.com',
    tel: '+65 1333 2323',
    email: 'jon@example.com',
  },
];

class FinalOfferDetails extends Component {
  state = {
    data: cardData,
  };

  render() {
    return (
      <div className="finalOfferDetails">
        {/* <RoboSidebar /> */}
        <div className="finalOfferDetails__innter">
          <div>
            <h2>Final Offer Details</h2>
            {/* <input type="text" placeholder="search" /> */}
          </div>

          <div className="finalOfferDetails__cardContainer">
            {this.state.data.map(item => <FinalOfferCard key={item.id} {...item} />)}
          </div>
        </div>
      </div>
    );
  }
}

export default FinalOfferDetails;
