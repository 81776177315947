import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_ANALYTICS_DATA, REQUEST_ANALYTICS_ACTIVITY_REPORTS,
  REQUEST_ANALYTICS_LOGS,
} from './actionTypes';

const getAnalytics = data => (dispatch) => {
  const {
    startDate, endDate, interval, jobID = null, applicationID = null, companyID = null,
    value, recruiterId, currId,
  } = data;
  let url = `${baseUrl}/analytics/trends?startDate=${startDate}&endDate=${endDate}&interval=${interval}`;
  if (applicationID === null && jobID !== null) {
    url += `&jobId=${jobID}`;
  } else if (applicationID !== null) {
    url += `&applicationId=${applicationID}`;
  } else if (value.toLowerCase() === 'company' && companyID !== null) {
    url += `&isCompanyView=true&companyId=${companyID}`;
  } else if (value.toLowerCase() === 'analytics' && !jobID && !applicationID && companyID !== null) {
    url += `&companyId=${companyID}`;
  }
  if (currId && recruiterId) {
    url += `&companyId=${currId}`;
  }
  if (recruiterId) {
    url += `&recruiterId=${recruiterId}`;
  }
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ANALYTICS_DATA,
  };
  return callApi(params);
};

const getAnalyticsActivityReports = data => (dispatch) => {
  const {
    facultyIds,
    courseIds,
  } = data || {};
  let url = `${baseUrl}/analytics/activityReports?_=${new Date().getTime()}`;
  if (facultyIds && Array.isArray(facultyIds) && facultyIds.length > 0) {
    url += `&facultyIds=${encodeURIComponent(JSON.stringify(facultyIds))}`;
  }
  if (courseIds && Array.isArray(courseIds) && courseIds.length > 0) {
    url += `&courseIds=${encodeURIComponent(JSON.stringify(courseIds))}`;
  }
  const params = {
    dispatch,
    actionType: REQUEST_ANALYTICS_ACTIVITY_REPORTS,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getAnalyticsLogs = data => (dispatch) => {
  const {
    activePage,
    itemsPerPage,
    startDate,
    endDate,
  } = data;
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/analytics/logs?_=${new Date().getTime()}&`;
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  if (startDate) {
    url += `startDate=${startDate}&`;
  }
  if (endDate) {
    url += `endDate=${endDate}&`;
  }
  const params = {
    dispatch,
    actionType: REQUEST_ANALYTICS_LOGS,
    url,
    method: 'GET',
  };
  return callApi(params);
};

export { getAnalytics, getAnalyticsActivityReports, getAnalyticsLogs };
