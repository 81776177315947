import { withTranslation } from 'react-i18next';
import React from 'react';
import { Slider } from 'carbon-components-react';

const PercentageSlider = ({
  value: data, onChange, name, minLabel, maxLabel, showPercentage,
  minClass = '', maxClass = '', hideSideLabels, disabled,
}) => (
  <div className="Slider bx--row mt-4">
    <div className={`bx--type-zeta ${minClass} bx--col-lg-4 mb-4 ${showPercentage ? 'mt-4' : 'mt-2'}`}>{minLabel}</div>
    <div className="bx--col-lg-5 mb-4">
      <Slider
        hideTextInput
        value={data ? Number(data) : 0}
        min={0}
        max={100}
        disabled={disabled}
        step={10}
        labelText={showPercentage ? `${data}%` : ''}
        onChange={val => onChange(val, name)}
        minLabel={minLabel}
        maxLabel={maxLabel}
        formatLabel={val => (hideSideLabels ? '' : `${val}%`)}
      />
    </div>
    <div className={`bx--type-zeta ${maxClass} bx--col-lg-3 mt-md-4 ${showPercentage ? 'mt-4' : 'mt-lg-0'}`}>{maxLabel}</div>
  </div>
);

// {`${value}%`} for label text shows percentage
//if label text is enabled then
export default PercentageSlider;
