import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getMarketPlaces } from 'actions/marketplace';

import BackRow from 'components/common/BackRow/BackRow';
import ApplicationCard from 'components/common/ApplicationCard/ApplicationCard';

class MarketPlace extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'marketplace', name: 'Marketplace', href: '/app/campus/marketplaceapps' },
  ];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getMarketPlaces();
  }

  render() {
    const {
      paths, props,
    } = this;
    const { applications = [] } = props;
    return (
      <div className="marketPlace">
        <BackRow paths={ paths } />
        {/* <h4 className="bx--type-gamma font-weight-bold text-capitalize">Marketplace</h4>   */}
        <div className="bx--row m-0 mt-lg-3 mb-lg-4">
          { applications && Array.isArray(applications)
            && applications.map((app, i) => (
              <div key={app.marketplaceId || i} className="bx--col-lg-2 col-lg-2 p-lg-0 mr-lg-3 col-6 bx--col-6 mb-3">
                <ApplicationCard
                  firstname={app.appName || '-'}
                  picture={app.companyinfo ? app.companyinfo.logo : null}
                  applicationName={app.appName || '-'}
                  company={app.company ? app.company : {}}
                  isEnabled={app.companyDetails ? app.companyDetails.enabled : false}
                  appId={app.marketplaceId} />
              </div>
            ))
         }
        </div>
      </div>);
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  applications: state.marketplace.applications,
  loading: state.marketplace.loading,
});


const mapDispatchToProps = {
  getMarketPlaces,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlace);
