import axios from 'axios';
import { baseUrl } from 'utils/config';

const api = axios.create({
  baseURL: baseUrl,
  timeout: 1000,
  withCredentials: true
  // headers: {'X-Custom-Header': 'foobar'}
});

// endpoint
// http://localhost:4545/api/Applications/findAllUserJobApplicants

export const fetchApplicationsByCompany = companyId => {
  return api.get(`/api/Applications/Applications/findAllUserJobApplicants`);
};
