import { withTranslation } from 'react-i18next';
import React from 'react';

import JobWorkflowStages from 'containers/roboroy/JobInfo/JobWorkflowStages';
import CreateWorkflowModal from 'components/roboroy/WorkflowModule/createWorkflow';
import ApplicantWorkflowStagesEditor from 'containers/roboroy/WorkflowEditStages';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';

const WorkflowSettings = (props) => {
  const {
    user: {
      isAdmin,
      isSuperAdmin,
      currentCompany: {
        ctypeId,
        companyId,
      } = {},
      userId = 0,
    } = {},
    user = {},
    job = {},
    isAutoPilotMode,
    handleAutoShortlist,
    isAutoShortlistMode,
    allWorkflowStages,
    handleGetJobInfo,
    t,
  } = props || {};
  const {
    jobId,
    workflowstages,
    isAutoPilot,
    jobWorkflow,
    jobWorkflowStages = [],
    collaboration = [],
    recruiterId,
    institutionId,
  } = job;
  const {
    workflow: {
      createdBy,
    } = {},
  } = jobWorkflow || {};
  const workflowRes = jobWorkflow && jobWorkflow.workflow;
  const finalData = { ...workflowRes };
  finalData.stages = jobWorkflowStages && Array.isArray(jobWorkflowStages)
    && jobWorkflowStages.length > 0 && jobWorkflowStages.map((r) => {
    return {
      stageMeta: {
        ...r,
        appStageId: r.jobStageId,
      },
    };
  });
  const application = {
    job,
  };
  return (
    <div>
      {(
        <div>
          <div className="mt-2"><strong>{t('workflowSetUp')}</strong></div>
          {workflowstages && (
            <div>
              <small>{t('workflowSetupMsg')}</small>
              {
                (createdBy != userId) && (
                <div>
                  {workflowstages.map((eachObj, index) => (
                    <p className="bx--modal-header__label mt-2 text-dark">{eachObj && eachObj.stageName} (<span class="text-dark">{eachObj && eachObj.workflowstagetype && eachObj.workflowstagetype.stageTypeName}</span>) </p>
                  ))}
                </div>)
              }
              {
                jobWorkflowStages && Array.isArray(jobWorkflowStages)
                  && jobWorkflowStages.length > 0
                  && (createdBy == userId || recruiterId == createdBy || (isSuperAdmin === true) || (recruiterId === userId)
                  || (isAdmin === true && ctypeId && Number(ctypeId) === 2 && Number(companyId) === Number(institutionId))
                  || (isAdmin === true && ctypeId && Number(ctypeId) === 1)
                  || (collaboration && Array.isArray(collaboration) && collaboration.length > 0)) && (
                  <GenericTabs
                    className="xpa-cand-info--tabs"
                    selected={0}
                    tabContent={[
                      {
                        className: 'xpa-cand-info--int-status',
                        label: `${t('stages')}`,
                        value:
                        (
                          <JobWorkflowStages job={job} stages={jobWorkflowStages} />
                        ),
                        onClick: () => {},
                      },
                      // {
                      //   className: 'xpa-cand-info--int-status',
                      //   label: `${t('edit')} ${t('workflow')}`,
                      //   value:
                      //   (
                      //     <ApplicantWorkflowStagesEditor
                      //       workflow={finalData}
                      //       workflowStages={allWorkflowStages}
                      //       user={user}
                      //       jobId={jobId}
                      //       application={job}
                      //       handleGetJobInfo={handleGetJobInfo}
                      //     />
                      //   ),
                      //   onClick: () => {},
                      // },
                    ]}
                  />
                )
              }
              {/* {
                (createdBy == userId) && (<JobWorkflowStages job={job} stages={jobWorkflowStages} />)
              } */}
              
            </div>
          )}
          {((!workflowstages) || (jobWorkflowStages
            && Array.isArray(jobWorkflowStages)
            && jobWorkflowStages.length > 1))
            && (
            <div>
              <small className="text-dark">
                {t('setUpWorkflowMsg')}
              </small>
              <div className="pb-2" />
              <CreateWorkflowModal
                jobId={jobId}
                appId={0}
                showInModal
                application={application}
                show
                isAutoPilotMode={isAutoPilotMode}
                updateParentPage={() => {
                  window.location.reload();
                }}
                dismiss={() => {}}
                isJobWorkflow
                collaboration={collaboration}
                workflowRes={workflowRes}
              />
            </div>
          )}
        </div>
        )
      }
    </div>
  );
};

export default (withTranslation()(WorkflowSettings));
