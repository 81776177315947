import {
    SUCCESS_USER_REGISTER,
  } from '../../actions/actionTypes';
  
  const initialState = {
    register:[],
  };

const registration = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SUCCESS_USER_REGISTER: {
            return {
                ...state,
                register: payload,
            };
        }
        default:
            return state;
    }
}

export default registration;