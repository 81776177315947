import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Checkbox,
} from 'carbon-components-react';
import moment from 'moment';
import FacultyDescription from '../../containers/roboroy/NewJob/FacultyDescription';

const FacultyCoursesSelection = (props) => {
  const {
    t, facultyData, isSuperAdmin,
    userFacultyId, ctypeId,
    isAdmin, courseAdmin, state,
    handleChangeCheckboxValues,
    handleSelectAllCheckboxes,
    handleClearAllCheckboxes,
    hideCoursesDetails,
    isModal, jobId, userCourseId
  } = props || {};
  return (
    <div>
      {
        facultyData && Array.isArray(facultyData)
        && facultyData.length > 0
        && facultyData.map((faculty) => {
          const {
            facultyId,
            facultyName,
            courses,
            internshipStartDate,
            internshipEndDate,
            facultyDesc,
          } = faculty || {};
          let isCurrentFaculty = false;
          if (isSuperAdmin === true || (userFacultyId == facultyId) || (ctypeId && Number(ctypeId) === 1 && isAdmin === true)) {
            isCurrentFaculty = true;
          } else {
            isCurrentFaculty = false;
          }
          const cantTickFaculty = (isCurrentFaculty === false && state.hasOwnProperty(`is-faculty-${facultyId}-present`));
          return (
            <div className="mb-2">
              <Checkbox
                className="mb-0"
                key={facultyId}
                disabled={(cantTickFaculty === true ? true : false) || (jobId && ctypeId && Number(ctypeId)===1)}
                labelText={
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="bx--type-delta text-capitalize">{facultyName}</div>
                      {internshipStartDate && internshipEndDate && <div>
                        <span className="text-dark">{parseInt(facultyId) === 48 ? t('internshipPeriod') : t('internshipPeriod2')}</span>
                        &nbsp;&nbsp;
                        {/* <span className="">:{moment(internshipStartDate).format('DD MMMM YYYY')}</span> to <span className="">{moment(internshipEndDate).format('DD MMMM YYYY')}</span> */}
                      </div>}</div>&nbsp;&nbsp;&nbsp;</div>}
                checked={state[`faculty-${facultyId}`] == facultyId}
                id={`faculty-${facultyId}`}
                name={`faculty-${facultyId}`}
                value={state[`faculty-${facultyId}`]}
                onChange={(e) => handleSelectAllCheckboxes(facultyId, courses, e)}
              />
              {
                ctypeId && Number(ctypeId) === 2
                && isCurrentFaculty
                && (
                  <div onClick={() => handleClearAllCheckboxes(facultyId, courses)} className="xpa-link small">{t('clearFilters')}</div>
                )
              }
              {
                hideCoursesDetails !== true && (
                  <div className="mb-2 mt-2">
                    <FacultyDescription facultyDesc={facultyDesc} />
                  </div>
                )
              }
              {
                ((ctypeId && Number(ctypeId) === 2 && !isModal) || (ctypeId && Number(ctypeId)===1 && facultyId && Number(facultyId)===60))
                && (
                  <div className="bx--row mt-1">
                    {
                      courses && Array.isArray(courses)
                      && courses.length > 0
                      && courses.map((crs) => {
                        const {
                          courseId,
                          courseName,
                        } = crs || {};
                        return (
                          <div className="bx--col-lg-4">
                            <Checkbox
                              key={courseId}
                              disabled={isCurrentFaculty === false || (jobId && ctypeId && Number(ctypeId) === 1) || (jobId && ctypeId && Number(ctypeId)===2 && isAdmin===true && courseAdmin===true && courseId===userCourseId)}
                              labelText={<div className="text-capitalize">{courseName}</div>}
                              checked={state[`courses-${facultyId}`] && Array.isArray(state[`courses-${facultyId}`]) && state[`courses-${facultyId}`].length > 0 && state[`courses-${facultyId}`].includes(courseId)}
                              id={`course-${courseId}`}
                              name={`course-${courseId}`}
                              value={state[`course-${courseId}`]}
                              onChange={(e) => { handleChangeCheckboxValues(e, courseId, facultyId); }}
                            />
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }
              <hr />
            </div>
          )
        })
      }
    </div>
  )
}

export default (withTranslation()(FacultyCoursesSelection));
