import {
  REQUEST_GET_INTERVIEWS,
  SUCCESS_GET_INTERVIEWS,
  ERROR_GET_INTERVIEWS,
  REQUEST_GET_INTERVIEW,
  SUCCESS_GET_INTERVIEW,
  ERROR_GET_INTERVIEW,
  REQUEST_CANDIDATE_ANSWERS,
  SUCCESS_CANDIDATE_ANSWERS,
  ERROR_CANDIDATE_ANSWERS,
  REQUEST_VIDEO_INTERVIEW_RESPONSE_DETAILS,
  SUCCESS_VIDEO_INTERVIEW_RESPONSE_DETAILS,
  ERROR_VIDEO_INTERVIEW_RESPONSE_DETAILS,
  REQUEST_GET_CAND_VI_RESPONSES_BY_ID,
  SUCCESS_GET_CAND_VI_RESPONSES_BY_ID,
  ERROR_GET_CAND_VI_RESPONSES_BY_ID,
} from '../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  interviews: [],
  count: {
    draft: 0,
    awaiting: 0,
    completed: 0,
  },
  interview: {
    answers: {
      videos: [],
      loading: true,
      error: null,
    },
    isLoading: true,
    error: null,
  },
  candidateResponse: [],
  videoInterviewRes: {},
};

const videoInterviews = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_GET_INTERVIEWS:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case SUCCESS_GET_INTERVIEWS:
      const { interviews = [], count = {} } = payload;
      return {
        ...state,
        interviews: [...interviews],
        count: { ...count },
        loading: false,
        error: null,
      };
    case ERROR_GET_INTERVIEWS:
      return {
        ...state,
        loading: false,
        interviews: initState.interviews,
        count: initState.count,
        error: payload.error,
      };
    case REQUEST_GET_INTERVIEW:
      return {
        interview: {
          loading: true,
          error: null,
          answers: { ...state.interview.answers }
        },
        ...state,
      };
    case SUCCESS_GET_INTERVIEW:
      let isLoading = true;
      if ((payload.data && (Object.keys(payload.data).length > 0))) {
        isLoading = false;
      } else {
        payload.data = {};
      }
      return {
        ...state,
        interview: {
          loading: isLoading,
          error: null,
          ...payload.data,
        },
      };
    case ERROR_GET_INTERVIEW:
      return {
        ...state,
        loading: false,
        interview: {
          loading: false,
          error: payload.error,
          answers: { ...state.interview.answers }
        },
      };
    case REQUEST_CANDIDATE_ANSWERS:
      return {
        loading: true,
        error: null,
        interview: {},
        ...state,
      };
    case SUCCESS_CANDIDATE_ANSWERS:
      const newInterviewState = {
        ...state.interview,
        answers: {
          loading: false,
          error: false,
          videos: payload.data.videos,
        },
      };
      return {
        ...state,
        interview: { ...newInterviewState },
        loading: isLoading,
        error: null,
      };
    case ERROR_CANDIDATE_ANSWERS:
      return {
        ...state,
        loading: false,
        interview: {
          ...state.interview,
          answers: {
            loading: false,
            error: payload.error,
            videos: [],
          },
        },
        error: payload.error,
      };
    case REQUEST_VIDEO_INTERVIEW_RESPONSE_DETAILS:
      return {
        loading: true,
        error: null,
        candidateResponse: [],
        ...state,
      };
    case SUCCESS_VIDEO_INTERVIEW_RESPONSE_DETAILS:
      return {
        ...state,
        candidateResponse: payload,
        loading: false,
        error: null,
      };
    case ERROR_VIDEO_INTERVIEW_RESPONSE_DETAILS:
      return {
        ...state,
        loading: false,
        candidateResponse: [],
        error: payload.error,
      };
    case REQUEST_GET_CAND_VI_RESPONSES_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        videoInterviewRes: {},
      };
    case SUCCESS_GET_CAND_VI_RESPONSES_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        videoInterviewRes: payload && payload.response || {},
      };
    case ERROR_GET_CAND_VI_RESPONSES_BY_ID:
      return {
        ...state,
        loading: false,
        videoInterviewRes: {},
        error: payload.error,
      };
    default:
      return state;
  }
};

export default videoInterviews;
