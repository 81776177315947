import * as at from './actionTypes';

export const fetchApplicationByCompanyRequest = () => ({
  type: at.FETCH_COMPAPPLICATIONS_REQUEST,
});

export const fetchApplicationByCompanyError = error => ({
  type: at.FETCH_COMPAPPLICATIONS_ERROR,
  payload: {
    error,
  },
});

export const fetchApplicationByCompanySuccess = data => ({
  type: at.FETCH_COMPAPPLICATIONS_SUCCESS,
  payload: {
    data,
  },
});
