import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DragablePreferences.css';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source || []);
  const destClone = Array.from(destination || []);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const getItemStyle = (isDragging, draggableStyle, image) => {
  return ({
    backgroundImage: `url(${image})`,
    ...draggableStyle,
  });
};

class DragablePreferences extends Component {

    id2List = {
      droppable: 'items',
      droppable2: 'selected',
    };


    constructor(props) {
      super(props);
      const { Data = [] } = props;
      this.state = {
        items: Data.map(one => ({ id: one.toLowerCase().replace(/\//g, '_').replace(/-/g, '_').replace(/ /g, '_'), content: one.replace(/\//g, '/ ') })),
        selected: [],
      };
    }

    componentDidMount() {
      const { selected: choosed = [] } = this.props;
      let { items } = this.state;
      const choosedLower = choosed && choosed.toString().toLowerCase().split(',');
      items = Array.isArray(choosed) ? items.filter(({ id }) => !choosedLower.includes(id)) : items.map(i => i);
      const selected = choosed && Array.isArray(choosed) && choosed.length > 0 && choosed.map(one => ({ id: one && one.toString() && one.toLowerCase().replace(/\//g, '_').replace(/-/g, '_').replace(/ /g, '_').replace(/__/g, '_'), content: one && one.replace(/\//g, '/ ') }));
      this.setState({ selected, items });
    }

    onDragEnd = (change) => {
      const { limit = 2 } = this.props;
      const { source, destination } = change;
      if (!destination) {
        return;
      }
      if (source.droppableId === destination.droppableId) {
        const items = reorder(
          this.state[source.droppableId],
          source.index,
          destination.index,
        );
        let state = { items };
        if (source.droppableId === 'selected') {
          state = { selected: items };
        }
        this.setState(state);
      } else {
        const result = move(
          this.state[source.droppableId],
          this.state[destination.droppableId],
          source,
          destination,
        );
        if (result.selected.length > limit) return;
        this.setState({
          items: result.items,
          selected: result.selected,
        }, () => this.props.sendResults(result.selected));
      }
    };

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
      const { items = [], selected = [] } = this.state;
      const { disabled = false, t, xtype } = this.props;
      return (
        <div className="dragable-preference bx--row">
          <div className="bx--row p-0 mb-2">
            <div className="bx--col-9 col-9 p-0 pl-1 text-dark bx--type-zeta">{t('dragOptions')}</div>
            <div className="bx--col-3 col-3 p-0 pl-2 text-dark bx--type-zeta">{t('selectedOptions')}</div>
          </div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="items">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className="dragPref_list justify-content-around bx--row bx--col-9 col-9"
                >
                  {Array.isArray(items) && items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, `/images/${xtype || 'industries'}/${item.id}.jpg`)}
                          className="listPref bx--tile text-white d-flex align-items-end bx--type-epsilon"
                        >
                          <div className="h-100 w-100 d-flex align-items-end text-capitalize" style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
                            {t((item.content.charAt(0).toLowerCase() + item.content.substring(1)))}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="selected">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className="dragPref_selected bx--col-3 col-3 xpa-selected-color p-0 pl-1 pr-1"
                >
                  {Array.isArray(selected) && selected.filter(({ id }) => id && id !== null && id.length > 0).map((item, index) => {
                    let val = 'st';
                    if (index + 1 === 1) {
                      val = 'st';
                    } else if (index + 1 === 2) {
                      val = 'nd';
                    } else if (index + 1 === 3) {
                      val = 'rd';
                    } else if (index + 1 >= 4) {
                      val = 'th';
                    }
                    return (
                      <Fragment key={item.id}>
                        <span className="font-weight-bold">{index + 1}{val} Choice</span>
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={disabled}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, `/images/${xtype || 'industries'}/${item.id}.jpg`)}
                              className="selectedPref ml-1 mr-1 mt-2 mb-2 bx--tile d-flex text-white bx--type-epsilon"
                            >
                              <div className="w-100 d-flex align-items-end text-capitalize" style={{ backgroundColor: 'rgba(0,0,0,0.65)', flex: 1 }}>
                                {t((item.content.charAt(0).toLowerCase() + item.content.substring(1)).replace(/ /g, '').replace(/\//, ' ').replace(/\//, ' ').replace(/-/g, ' '))}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </Fragment>
                  )})}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      );
    }
}

export default (withTranslation() (DragablePreferences));
