import moment from 'moment';

export const CRONOFY_CLIENT_ID_STAGING = 'vkfxiLGBiPyYyqtrLlR4z6V3ULZwk1sx';
export const CRONOFY_CLIENT_ID_LIVE = 'wCY0Cf8sjwQ-ZAXARo7fbxpAjcwvbv91';

export const emailLogoSection = `
<div style="padding:0;background-color:{{emailBg}};padding-bottom: 80px;">
<center style="min-width: 580px; width: 100%">
    <table class="container" style="border-collapse: collapse; border-spacing: 0; margin: 0 auto; padding: 0; text-align: inherit; vertical-align: top; width: 580px">
        <tr style="padding: 0; text-align: center; vertical-align: top" align="center">
            <td class="wrapper last" style="-moz-hyphens: auto; -webkit-hyphens: auto; border-collapse: collapse !important; color: #333333; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: normal; hyphens: auto; line-height: 20px; margin: 0; padding: 0 0px 0 0; position: relative; text-align: center; vertical-align: top; word-break: break-word"
                align="center" valign="top">
                <table class="twelve columns" style="border-collapse: collapse; border-spacing: 0; margin: 0 auto; padding: 0; text-align: center; vertical-align: top; width: 540px">
                  <tr style="padding: 0; text-align: center; vertical-align: top" align="center">
                    <td style="-moz-hyphens: auto; -webkit-hyphens: auto; border-collapse: collapse !important; color: #333333; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: normal; hyphens: auto; line-height: 20px; margin: 0; padding: 0px 0px 10px; text-align: center; vertical-align: top; word-break: break-word"
                      align="center" valign="top">
                      <div class="" style="text-align: center" align="center">
                        <a href="#" style="color: #4183C4; text-decoration: none">
                          <img height="150" width="120" class="center logo-wordmark" src="{{logo}}"  style="-ms-interpolation-mode: bicubic; border: none; float: none; margin: 0 auto; max-width: 100%; outline: none; padding: 25px 0 17px; text-align: center; text-decoration: none; width: auto" align="none"/>
                        </a>
                      </div>
                    </td>
                    <td class="expander" style="-moz-hyphens: auto; -webkit-hyphens: auto; border-collapse: collapse !important; color: #333333; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: normal; hyphens: auto; line-height: 20px; margin: 0; padding: 0; text-align: center; vertical-align: top; visibility: hidden; width: 0px; word-break: break-word"
                      align="center" valign="top"></td>
                  </tr>
                </table>
            </td>
        </tr>
    </table>
</center>`;

export const emailBodyStart = `
    <table cellpadding="0" cellspacing="0" border="0" align="center" style="margin: auto;">
        <tbody>
            <tr>
                <td style="background:#fff;border:1px solid rgba(0, 0, 0, .125);border-collapse:separate;border-radius:4px">
                <table>
                <tbody>
                    <tr height="75px">
                    </tr>
                    <tr>
                        <td width="60px;"></td>
                        <td width="580" style="font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;line-height:20px;font-weight:300;color:#333333;text-decoration:none;">
                `;

export const emailBodyEnd = `</td>
<td width="60px;"></td>
</tr>
<tr height="75px"></tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</div>`;

export const dummyEmailPlaceholders = {
  companyName: 'ACME Inc.',
  firstName: 'Jane',
  lastName: 'Doe',
  jobName: 'Dummy Job Name',
  candidateName: 'Jane Doe',
  recruiterName: 'Jill Pane',
  recruiterFirstName: 'Jill',
  recruiterLastName: 'Pane',
  candidateFirstName: 'Jane',
  candidateLastName: 'Doe',
  recruiterEmail: 'recruiter@x0pa.com',
  email: 'dummy@example.com',
  reason: 'Laptop was crashed',
  referrerName: 'Juliet',
  interviewName: 'Dummy Interview Name',
  interviewURL: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">Submit your video responses</a>`,
  acceptLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; margin-bottom: 20px;">Yes, I'm interested</a>`,
  rejectLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #525C6D; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; ">No, I'm not interested</a>`,
  acceptOfferConsent: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; margin-bottom: 20px;">Yes, I'm interested</a>`,
  rejectOfferConsent: `<a href="#" style="-webkit-text-size-adjust: none; background: #525C6D; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; ">No, I'm not interested</a>`,
  registerLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">Accept Invitation</a>`,
  sharedCvLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; margin-bottom: 20px;">Open CV</a>`,
  sharedJobLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; margin-bottom: 20px;">View Job posting</a>`,
  scheduleInterviewLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">Schedule an Interview</a>`,  
  jobLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; margin-bottom: 20px;">View Job posting</a>`,
  message: 'This is a dummy message which will be replaced by the actual content',
  requestReferenceLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: 250px !important; margin-bottom: 20px;">Add References</a>`,
  applicationLink: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">View Application</a>`,
  joinMeetingBtn: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">Join Meeting</a>`,
  startMeetingBtn: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">Start Meeting</a>`,
  viewApplication: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">View Application</a>`,
  viewOfferDetails: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">View Offer</a>`,
  acceptRequestBtn: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">Link to written assessment</a>`,
  viewRequestBtn: `<a href="#" style="-webkit-text-size-adjust: none; background: #3d70b2; border-width: 2px; border-color: transparent; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: bold; letter-spacing: normal; margin: 0 auto; padding: 12px 44px; text-align: center; text-decoration: none; width: auto !important">Link to written assessment</a>`,
  workflowName: 'Dummy workflow name',
  workflowstages: '<ul><li><b>Video Interview</b></li><li><b>Phone Interview</b></li></ul>',
  stageName: 'Video interview',
  subStageName: 'Feedback',
  roleName: 'DummyRole',
  collaboratorName: 'Jill Doe',
  appId: '11111',
  assigneeName: 'Jill Pane',
  interviewDate: moment().format('Do MMM YYYY'),
  startTime: '11.59 PM',
  endTime: '3.00 AM',
  tzid: 'Asia/Singapore',
  applicantPhoneNumber: '+65-87654321',
  domainURL: 'https://internship-placement.rp.edu.sg',
};
