import callApi from 'utils/callApi';
import { baseUrl } from 'utils/config';
import {
  REQUEST_GET_INSTITUTIONS_LIST,
  REQUEST_GET_INSTITUTIONS_FACETS,
} from './actionTypes';

const fetchAllInstitutionsList = data => (dispatch) => {
  const {
    itemsPerPage, activePage, keyword = '',
    actionName,
  } = data || {};
  console.log('data', data);
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/companies/institutionslist?`;
  if (data && data.Locations && Array.isArray(data.Locations) && data.Locations.length > 0) {
    url += `location=${encodeURIComponent(JSON.stringify(data.Locations))}&`;
  }
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  if (keyword) {
    url += `searchKey=${keyword}&`;
  }
  const params = {
    dispatch,
    url,
    method: 'GET',
  };
  if (!actionName) {
    params.actionType = REQUEST_GET_INSTITUTIONS_LIST;
  } else {
    params.actionType = actionName;
  }
  return callApi(params);
};

const fetchAllInstitutionsFacets = data => (dispatch) => {
  const finalData = {};
  if (data && data.Locations && Array.isArray(data.Locations) && data.Locations.length > 0) {
    finalData.Locations = data.Locations;
  }
  const url = `${baseUrl}/companies/institutionfacets?filters=${encodeURIComponent(JSON.stringify(finalData))}`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_INSTITUTIONS_FACETS,
    url,
    method: 'GET',
  };
  return callApi(params);
};

export {
  fetchAllInstitutionsList,
  fetchAllInstitutionsFacets,
};
