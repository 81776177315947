import { withTranslation } from 'react-i18next';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

/* Common */
import NotFound from 'components/common/NotFound';

/* Candidate */
import {
  CandidateDashboard,
  CandidateJobList,
  CandidateBrowseJobs,
  CandidateReferences,
  ReferenceFeedback,
  Feedback,
  CandidateOffers,
  SkillsRecommended,
  StartFinland,
  RegisterPublicCandidate,
  StudentProfileView,
  BrowseCompanies,
  StudentProfileRegister,
  Notifications
} from './roboroy';

import {
  InternCandidate,
  BusinessFinland,
  StudentSlots,
} from './robocampus';


/* Misc */
import PrivateRoute from './privateRoute';

const CandidateRoutes = ({ match, isUserLoggedIn, roleId }) => (
  <Switch>
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}`}
      component={CandidateDashboard}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/business/finland/start`}
      component={StartFinland}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/register/profile`}
      component={StudentProfileRegister}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/recommended/jobs`}
      component={StudentProfileView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/register/profile`}
      component={RegisterPublicCandidate}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/register/profile/:profileId/stage/index/:indexId`}
      component={RegisterPublicCandidate}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/browse`}
      component={CandidateBrowseJobs}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/notifications`}
      component={Notifications}
    />
    {/* <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/job/matches`}
      component={StudentProfileView}
    /> */}
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/browse/Countries/:Countries/JobType/:JobType`}
      component={CandidateBrowseJobs}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/jobs`}
      component={CandidateJobList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/jobs/:status`}
      component={CandidateJobList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/browse/companies`}
      component={BrowseCompanies}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/recommendations`}
      component={SkillsRecommended}
    />
    {/* <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/interncandidate/:stageIndex`}
      component={InternCandidate}
    /> */}
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/slots`}
      component={StudentSlots}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/business/finland/:stageIndex`}
      component={BusinessFinland}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/applications/:id/offers`}
      component={CandidateOffers}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/references`}
      component={CandidateReferences}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/rf/:id`}
      component={ReferenceFeedback}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/feedbackgiven/:id`}
      component={Feedback}
    />
    <Route
      path={`${match.url}/*`}
      render={
        props => (<NotFound {...props} roleId={roleId} />)
      }
    />
  </Switch>
);

export default CandidateRoutes;
