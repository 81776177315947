import * as ac from "./actionCreators"
import * as api from "./api"

export const fetchApplicationByCompany = companyId => dispatch => {
  dispatch(ac.fetchApplicationByCompanyRequest())
  api.fetchApplicationsByCompany(companyId).then(res => {
    dispatch(ac.fetchApplicationByCompanySuccess(res.data))
  }).catch(error => {
    dispatch(ac.fetchApplicationByCompanyError(error))
  })
}