const deleteJobApiCache = () => {
  window.caches.delete('xpa-api-jobs')
    .then((dres) => {
      console.log('Deleting cache:::xpa-api-jobs:::', dres);
    })
    .catch((err) => {
      console.error('deleteJobApiCache:::xpa-api-jobs:::', err);
    });
  window.caches.delete('xpa-api-jobs-cf')
    .then((dres) => {
      console.log('Deleting cache:::xpa-api-jobs:::', dres);
    })
    .catch((err) => {
      console.error('deleteJobApiCache:::xpa-api-jobs:::', err);
    });
};

const deleteApiCache = (cacheName) => {
  try {
    window.caches.delete(cacheName)
      .then((dres) => {
        console.log(`Deleting cache:::${cacheName}:::`, dres);
      })
      .catch((err) => {
        console.error(`deleteApiCache:::${cacheName}:::`, err);
      });
  } catch (error) {
    console.error(`deleteApiCache:::${cacheName}:::`, error);
  }
};

export {
  deleteJobApiCache,
  deleteApiCache,
};
