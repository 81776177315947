import { push } from 'react-router-redux';
import {
  SET_EMPLOYEES,
  SET_ATTRITION_MODAL,
  RECEIVE_EMPLOYEE_DATA,
  RECEIVE_CANDIDATE_DATA,
  TOGGLE_MAP_VIEW,
  SET_USER,
  SET_TAB,
} from './actionTypes';
import * as api from './api';
import axios from 'axios';

export const getEmployeeData = username => (dispatch) => {
  const prediction = api.getEmployeePredictions(username);
  api
    .getEmployees(username)
    .then((data) => {
      const parsedData = JSON.parse(data);
      dispatch({
        type: SET_EMPLOYEES,
        data: parsedData,
      });

      prediction.then((predictionData) => {
        Object.entries(JSON.parse(predictionData)).forEach(([id, values]) => {
          (parsedData[id] || {}).loyalty = {
            salaryIncrease: {
              5: values.loyalty_5percent,
              10: values.loyalty_10percent,
              15: values.loyalty_15percent,
            },
            promotion: {
              yes: values.loyalty_imm_promotion,
              no: values.loyalty_no_promotion,
            },
          };
        });
        dispatch({
          type: SET_EMPLOYEES,
          data: parsedData,
        });
      });
    })
    .catch(e => console.log('no employee data2', e));
  return api
    .getEmployeeData(username)
    .then(data =>
      dispatch({
        type: RECEIVE_EMPLOYEE_DATA,
        data: JSON.parse(data).map(row => JSON.parse(row)),
      }))
    .catch((e) => {
      console.log('no employee data', e);
      return dispatch({
        type: RECEIVE_EMPLOYEE_DATA,
        data: [],
      });
    });
};

export const setUser = user => ({
  type: SET_USER,
  user,
});

export const logout = () => setUser({});

export const logoutUser = () => dispatch => {
  setUser({});
  api
  .logout()
  .then(() => {
    // console.log('success logout');
    window.location.href='/auth/logout';
  })
  .catch((e) => {
    // console.log('failed logout');
    window.location.href='/auth/logout';
    throw e;
  });
}

const handleDisableSimulator=()=>{
  sessionStorage.removeItem("userToken");
  const userIdToRemove=sessionStorage.getItem("simulationUserId");
  sessionStorage.removeItem("simulationUserId");
  axios.post(`${window.location.protocol}//${window.location.host}/auth/logout?simulation=true&userId=${userIdToRemove}`).then((res) => {
    if(res && res.error){
      console.error(res.error)
    }
  }).catch((err) => {
    console.log("axios err", err);
  })
}

export const sessionLogoutUser = () => {
  // console.log('success logout');
  axios.post(`${window.location.protocol}//${window.location.host}/auth/logout`).then((res) => {
    // console.log("axios res",res);
    if(sessionStorage.getItem("userToken")!==null){
      handleDisableSimulator();
    }
    if(!window.location.href.includes('/public/session/out')){
      window.location.href = '/public/session/out';
    }
    else{
      window.location.href = '/';
    }
  }).catch((err) => {
    console.log("axios err", err);
  })
}

export const login = (username, password) => dispatch =>
  api.login(username, password).then(() => {
    return dispatch(setUser({ username, password }));
  }).catch((e) => {
    // console.log('failed login', username, password);
    // dispatch(push('/campus/login'));
    throw e;
  }
);

export const getOTP = (email) => dispatch =>
  api.getOTP(email).catch((e) => {
    // console.log('failed login', username, password);
    // dispatch(push('/campus/login'));
    throw e;
  }
);

export const toggleMapView = () => ({
  type: TOGGLE_MAP_VIEW,
});

export const getCandidateData = () => (dispatch) => {
  api.getCandidateData().then((data) => {
    dispatch({
      type: RECEIVE_CANDIDATE_DATA,
      data: JSON.parse(data),
    });
  });
};

export const setTab = currentTab => ({
  type: SET_TAB,
  currentTab,
});

export const setAttritionModal = show => ({
  type: SET_ATTRITION_MODAL,
  show,
});
