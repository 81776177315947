import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Tile, Button, Pagination,
  ToolbarItem,
  Toggle,
} from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import Avatar from 'components/common/Avatar';
import {
  PagingInfo,
} from '@elastic/react-search-ui-views';

const StudentsView = (props) => {
  const {
    t,
    students,
    studentsCount,
    handleRedirectProfilePage,
    handleStudentsPaginationChange,
    studentsActivePage,
    handleSearchStudentsData,
    handleRedirectStudentRegPg,
    propAdmin,
    propSuperAdmin,
    handleToggleEligibility,
    isCurrFaculty,
    handleRedirectStudentEditPage,
    handleOpenMarketToggle,
    studentsItemsPerPage,
    handleViewNotification
  } = props;
  const columnWithFilter = [
    {
      title: `${t('students')} ${t('name')}`,
      className: 'text-left',
      name: 'user',
      propName: 'user',
    },
    {
      title: `${t('email')}`,
      className: 'text-left',
      name: 'email',
      propName: 'email',
    },
    {
      title: `${t('studentUniversityId')}`,
      className: 'text-left',
      name: 'studentUniversityId',
      propName: 'studentUniversityId',
    },
    {
      title: `${t('eligibility')}`,
      className: 'text-center',
      name: 'eligibility',
      propName: 'eligibility',
    },
    {
      title: 'Open Market',
      className: 'text-center',
      name: 'market',
      propName: 'market',
    },
    {
      title: 'Hired',
      className: 'text-center',
      name: 'is-hired',
      propName: 'is-hired',
    },
  ];
  if (isCurrFaculty || propSuperAdmin === true) {
    columnWithFilter.push({
      title: `${t('action')}`,
      className: 'text-center',
      name: 'action',
      propName: 'action',
    });
  }
  const otherActionButtons = [];
  if (propSuperAdmin === true || isCurrFaculty) {
    otherActionButtons.push(
      <ToolbarItem>
        <Button
          className="mr-2"
          onClick={handleRedirectStudentRegPg}
          kind="primary"
        >
          {t('add')} {t('student')}
        </Button>
      </ToolbarItem>,
    );
  }
  return (
    <Fragment>
      <div className="h6 mb-2">
        {t('student')} {t('details')}
      </div>
      <div className="mb-2">
        {t('StdAccMsg')}
      </div>
      <div className="meta d-flex justify-content-between w-100 align-items-center">
        <div>
          <PagingInfo
            end={Math.min(
              Number(studentsCount),
              Number(studentsItemsPerPage) *
              Number(studentsActivePage)
            )}
            start={
              1 +
              Number(studentsActivePage - 1) *
              Number(studentsItemsPerPage)
            }
            totalResults={Number(studentsCount)}
          />
        </div>
      </div>
      <GenericDataTable
        className=""
        otherActions={otherActionButtons}
        headers={
          columnWithFilter.map((data, index) => (
            {
              key: `header_${index}`,
              header: data.title,
              className: data.className,
              colpropName: data.propName,
            }
          ))}
        rows={
          (students && Array.isArray(students)
            && students.length > 0)
            ? students.map((res) => {
              const {
                _source: {
                  studentProfileId,
                  profileId,
                  profile: {
                    firstName,
                    lastName,
                    email,
                  } = {},
                  isOpenMarket,
                  studentUniversityId,
                  isEligible,
                  isHired,
                  userId
                } = {},
              } = res || {};
              return ({
                isExpanded: true,
                id: `row_${studentProfileId}`,
                header_0: (
                  <div className="d-flex align-items-center">
                    <div>
                      <Avatar
                        user={{
                          firstname: firstName,
                          lastname: lastName,
                          picture: null,
                        }}
                        classNameInfo="rounded-circle"
                        size="30"
                      />
                    </div>
                    {
                      (firstName || lastName) ? (
                        <div onClick={() => handleRedirectProfilePage(profileId, studentProfileId)} className="ml-2 xpa-link text-capitalize">
                          {firstName} {lastName}
                        </div>
                      ) : (
                        <div onClick={() => handleRedirectProfilePage(profileId, studentProfileId)} className="ml-2 xpa-link text-capitalize">
                          Name Unidentified
                        </div>
                      )
                    }

                  </div>
                ),
                header_1: (
                  <div>
                    {
                      email ? (
                        <a className="text-lowercase" href={`mailto:${email}`}>{email}</a>
                      ) : '-'
                    }
                  </div>
                ),
                header_2: (
                  <div>
                    <span className="d-lg-none d-md-none bx--type-zeta pr-2">Student ID</span>
                    {studentUniversityId}
                  </div>
                ),
                header_3: (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-lg-none d-md-none bx--type-zeta mr-2">
                      Student Attended
                    </div>
                    {
                      isCurrFaculty ? (
                        <Toggle
                          className="align-items-lg-center align-items-md-center mt-2 mt-lg-0 mt-md-0"
                          id={`toggle-${studentProfileId}`}
                          onToggle={() => handleToggleEligibility(isEligible, studentProfileId)}
                          toggled={isEligible}
                          labelA="No"
                          // disabled={isHired === true}
                          labelB="Yes"
                        />
                      ) : (
                        <div>
                          {isEligible ? <span className="bx--tag bx--tag--green">{t('yes')}</span> : <span className="bx--tag bx--tag--red">{t('no')}</span>}
                        </div>
                      )
                    }
                  </div>
                ),
                header_4: (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-lg-none d-md-none bx--type-zeta mr-2">
                      Open Market
                    </div>
                    {
                      isCurrFaculty ? (
                        <Toggle
                          className="align-items-lg-center align-items-md-center mt-2 mt-lg-0 mt-md-0"
                          id={`toggle-open-${studentProfileId}`}
                          onToggle={() => handleOpenMarketToggle(isOpenMarket, studentProfileId)}
                          toggled={isOpenMarket}
                          // disabled={isHired === true}
                          labelA="No"
                          labelB="Yes"
                        />
                      ) : (
                        <div>
                          {isEligible ? <span className="bx--tag bx--tag--green">{t('yes')}</span> : <span className="bx--tag bx--tag--red">{t('no')}</span>}
                        </div>
                      )
                    }
                  </div>
                ),
                header_5: (
                  <div className="d-lg-flex d-md-flex justify-content-center">
                    <span className="d-lg-none d-md-none pr-2">
                      Hired
                    </span>
                    {
                      isHired === true ? (
                        <div className="bx--tag bx--tag--green">
                          Hired
                        </div>
                      ) : (
                        <div className="bx--tag bx--tag--red">
                          Not Hired
                        </div>
                      )
                    }
                  </div>
                ),
                header_6: (
                  <div>
                    <div className="d-lg-flex d-md-flex justify-content-center w-100 mb-2">
                      <Button onClick={() => handleRedirectStudentEditPage(profileId)} style={{ width: "150px" }} kind="tertiary" small>{t('edit')}</Button>
                    </div>
                    <div className="d-lg-flex d-md-flex justify-content-center">
                      <Button className="w-100" onClick={() => handleViewNotification({ userId })} style={{ paddingRight: "15px" }} kind="primary" small>{t('View Notifications')}</Button>
                    </div>
                  </div>
                )
              });
            }) : []
        }
        selectable={false}
        searchable
        onSearchInputChange={handleSearchStudentsData}
        expandable={false}
        batchActions={false}
      />
      {
        (studentsCount && studentsCount > 0) ? (
          <Pagination onChange={handleStudentsPaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={studentsActivePage} totalItems={studentsCount} />
        ) : null
      }
      {
        (!students || (
          students && Array.isArray(students)
          && students.length <= 0
        )) && (
          <div className="bx--tile">
            <div className="text-center">
              <div className="empty-icon">
                <i className="fa fa-flag fa-2x" />
              </div>
              <p className="empty-title h5">{t('noStudentsMsg')}</p>
              {/* {
                (propAdmin === true || propSuperAdmin === true)
                  && (
                    <Button
                      className="mr-2"
                      kind="primary"
                      onClick={handleRedirectStudentRegPg}
                    >
                      {t('add')} {t('student')}
                    </Button>
                  )
              } */}
            </div>
          </div>
        )
      }
    </Fragment>
  );
};

export default (withTranslation()(StudentsView));
