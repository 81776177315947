import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PublicHeader from 'containers/common/publicHeader';
import studentImage from 'assets/loginImages/student-img.jpg';
import '.././Login.css';

class CommonPublicReg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLanguageModal: false,
    };
  }

  handleRedirectToLogin = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/');
    }
  }

  handleLangugaeModal = () => {
    const { showLanguageModal } = this.state;
    this.setState({
      showLanguageModal: !showLanguageModal,
    });
  }

  render() {
    const {
      showLanguageModal,
    } = this.state;
    const {
      match: {
        params: {
          status,
        } = {},
      } = {},
      t,
    } = this.props;
    return (
      <div>
        <PublicHeader
          handleRedirectToLogin={this.handleRedirectToLogin}
          showLanguageModal={showLanguageModal}
          handleLangugaeModal={this.handleLangugaeModal}
        />
        <div className="studentlogin login-page col-12 bx--row m-0 p-0">
          <div style={{ backgroundImage: `url(${studentImage})`, backgroundPosition: 'top' }} className="left-side student-login-background bx--col-xl-6 bx--col-lg-6 bx--col-md-6 bx--col-sm-4 m-0 p-0 d-flex justify-content-center align-items-center flex-column">
            <div className="student-mask main-page-student-mask col m-0 p-0 d-flex justify-content-center align-items-center" />
          </div>
          <div className="bx--col-lg-6 d-flex align-items-center justify-content-center flex-column">
            <div className="w-50">
              <div className="">
                <div className="text-center">
                  <div className="bx--type-beta font-weight-bold mb-4">{t('registration')}</div>
                  <div className="text-left text-justify text-dark h6">
                    {
                      status === 'student'
                        && (
                          <div>
                            <span className="text-dark">{t('studentRegMsg')}</span>
                          </div>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(withTranslation()(CommonPublicReg));
