import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import NivoGraphs from "components/common/NivoGraphs";
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import { Button } from 'carbon-components-react';
import { getAnalyticsInfo, updateInfo } from "actions/analyticsInfo";
import { connect } from 'react-redux';
import {
    getAllFaculties,
    getAllCourses,

} from 'actions/candidate/CandidateDetails';
import Batch from "../../components/roboroy/common/Batch"
import { Education32, Money32 } from '@carbon/icons-react';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import BackRow from 'components/common/BackRow/BackRow';
import "./Analytics.css";
import { getBatchDetails } from 'actions/BatchManagementView';



// const batchArr = [
//     { label: "AY20 Sem 2", value: "AY20 Sem 2" },
//     { label: "AY21 Sem 1", value: "AY21 Sem 1" }
// ]

class Analytics extends Component {
    paths = [
        { id: 'campus', name: 'Campus', href: '/app/campus' },
        { id: 'analytics', name: 'Analytics', href: '' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            facultyValue: { label: "", value: "" },
            courseValue: { label: "", value: "" },
            batchValue: { label: "", value: "" },
            analyticsInfo: {}
        }
    }



    componentDidMount() {
        this.props.getBatchDetails().then((res) => {
            const { list: batchList } = res;
            const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];

            if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
                this.setState({
                    ...this.state,
                    batchValue: { label: currentBatch.batchName, value: currentBatch.batchName },
                    batchArr: batchList
                }, () => {
                    this.handleDropdownChange(currentBatch.batchName, this.state.batchValue, "batchValue");
                    // this.onFilterChange(this.state.batchValue.value, this.state.courseValue.value, this.state.facultyValue.value);
                    const analyticsParams = {
                        schoolId: -1, courseId: -1, batchId: currentBatch.batchName
                    }
                    let analyticsInfo, facultyArr, coursesArr;
                    const { user: { companyId, company: { ctypeId } = {} } = {} } = this.props;
                    this.props.getAnalyticsInfo(analyticsParams).then((res) => {
                        analyticsInfo = res;
                        const data = {
                            schoolId: companyId,
                            facultyActivePage: 1,
                            facultyItemsPerPage: 1000,
                        };
                        this.props.getAllFaculties(data).then((r) => {
                            const res = r.faculities;
                            facultyArr = res && Array.isArray(res)
                                && res.length > 0
                                ? (res.map(one => ({
                                    label: one.facultyName,
                                    value: one.facultyId,
                                }))) : []
                            const param = {
                                courseActivePage: 1,
                                courseItemsPerPage: 1000,
                                schoolId: companyId,
                            };

                            this.props.getAllCourses(param).then((r) => {
                                const res = r.courses;
                                coursesArr = res && Array.isArray(res)
                                    && res.length > 0
                                    ? (res.map(one => ({
                                        label: one.courseName,
                                        value: one.courseId,
                                    }))) : []
                                this.setState({
                                    coursesArr: coursesArr,
                                    facultyArr: facultyArr,
                                    analyticsInfo: analyticsInfo
                                })
                            });
                        });
                    })
                })
            } else {
                this.setState({
                    ...this.state,
                    batch: { label: "Choose Batch", value: 0 },
                    batchArr: []
                })
            }
        });

    }

    getUniqueListBy = (arr, key, value, keyName) => {
        if (arr && Array.isArray(arr) && arr.length > 0) {
            return arr.map((item) => {
                const returnItem =
                {
                    key: item[key],
                    [keyName]: item[value]
                }
                if (item["% of Total"] !== undefined && item["% of Total"] !== null) {
                    returnItem["percentOfTotal"] = item["% of Total"];
                }
                return returnItem;
            })
        }
        return [];
    };


    getGroupedData = (arr, key, actualValue1, actualValue2, value1, value2) => {
        if (arr && Array.isArray(arr) && arr.length > 0) {
            return arr.map((item) => {
                const returnItem = {
                    key: item[key],
                    // key: item[key].toString().toUpperCase(),
                    [value1]: item[actualValue1],
                    [value2]: item[actualValue2]
                }
                if (item.hasOwnProperty("link")) {
                    returnItem.key = `${item[key]}_${item["link"]}`
                }
                return returnItem;
            })
        }
        return [];
    }

    getLineData = (arr, xValue, yValue) => {
        if (arr && Array.isArray(arr) && arr.length > 0) {
            return arr.map((item) => {
                return {
                    x: item[xValue],
                    y: item[yValue]
                }
            })
        }
        return [];
    }

    getDivergedData = (arr1, arr2, k1, kn1, kn2, valueOf) => {
        if (arr1 && Array.isArray(arr1) && arr1.length > 0 && arr2 && Array.isArray(arr2) && arr2.length > 0) {
            return arr1.map((item1, index) => {
                return {
                    key: item1[k1],
                    [kn1]: -item1[valueOf],
                    [kn2]: (arr2[index] != undefined && arr2[index] != null) ? arr2[index][valueOf] : 0,
                }
            });
        }
        return [];
    }

    handleDropdownChange = (v, metaValue, name) => {
        this.setState({
            [name]: metaValue === null ? { label: "", value: "" } : metaValue
        });
    }

    onFilterChange = (batchId, courseId, schoolId) => {
        if (batchId === null || batchId === undefined || batchId === "") {
            batchId = -1;
        }
        if (courseId === null || courseId === undefined || courseId === "") {
            courseId = -1;
        }
        if (schoolId === null || schoolId === undefined || schoolId === "") {
            schoolId = -1;
        }
        const params = { batchId: batchId, courseId: courseId, schoolId: schoolId }
        this.props.getAnalyticsInfo(params).then((res) => {
            this.setState({
                analyticsInfo: res
            })
        });
    }

    // createTickValues = (nivoDataByShortListToHired) => {
    //     if (nivoDataByShortListToHired != null && nivoDataByShortListToHired != undefined && Array.isArray(nivoDataByShortListToHired)) {
    //         let max = nivoDataByShortListToHired[0]['Count of companies'];
    //         for (let i = 0; i < nivoDataByShortListToHired.length; i++) {
    //             max = max < nivoDataByShortListToHired[i]['Count of companies'] ? nivoDataByShortListToHired[i]['Count of companies'] : max;
    //         }
    //         let tv = [];
    //         for (let i = 0; i <= max; i++) {
    //             tv.push(i)
    //         }
    //         return tv;
    //     }
    // }

    render() {
        const {
            t,
            loading,
            user: {
                isAdmin,
                isSuperAdmin,
                courseAdmin,
                facultyId,
                company: {
                    ctypeId,
                } = {},
            } = {},
        } = this.props;
        const { analyticsInfo, facultyArr, coursesArr, batchArr } = this.state;

        const {
            json_graph_breakdown_number_from_shortlisted_to_hired,
            json_requested_vs_approved_vacancies,
            json_student_company_type_breakdown,
            json_student_industry_type_breakdown,
            json_student_job_function_breakdown,
            json_student_preferences_backend,
            json_student_preferences_taskoriented,
            json_top_popular_companies,
            json_top_popular_jobs,
            json_top_10_popular_skills_companies,
            json_top_10_popular_skills_students,
            json_common_skills_between_company_students,
            json_trend_of_successful_placement_rate,
            json_workday_types,
            single_number_of_students,
            single_number_of_requested_vacancies,
            single_number_of_companies,
            single_number_of_application,
            single_student_vs_available_iip_placement_ratio,
            single_company_vs_available_iip_placement_ratio,
            single_maximum_monthly_allowance,
            single_minimum_monthly_allowance,
            single_average_monthly_allowance,
            single_median_monthly_allowance
        } = analyticsInfo || {};
        const nivoKeys = ["No of students"];
        const nivoDataByJobFunction = (json_student_job_function_breakdown != null &&
            json_student_job_function_breakdown != undefined &&
            Array.isArray(json_student_job_function_breakdown) &&
            json_student_job_function_breakdown.length > 0
        ) ? this.getUniqueListBy(json_student_job_function_breakdown, "function_name", "Count", nivoKeys[0]).reverse() : null;
        const nivoDataByCompanyType = (json_student_company_type_breakdown != null &&
            json_student_company_type_breakdown != undefined &&
            Array.isArray(json_student_company_type_breakdown) &&
            json_student_company_type_breakdown.length > 0
        ) ? this.getUniqueListBy(json_student_company_type_breakdown, "company type", "Count", nivoKeys[0]).reverse() : null;
        const nivoDataByIndustryType = (json_student_industry_type_breakdown != null &&
            json_student_industry_type_breakdown != undefined &&
            Array.isArray(json_student_industry_type_breakdown) &&
            json_student_industry_type_breakdown.length > 0
        ) ? this.getUniqueListBy(json_student_industry_type_breakdown, "Industry Name", "Count", nivoKeys[0]).reverse() : null;
        const nivoKeysByRequestedApproved = ["Approved Vacancies", "Requested Vacancies"];
        const nivoDataByRequestedVsApproved = (json_requested_vs_approved_vacancies != null &&
            json_requested_vs_approved_vacancies != undefined &&
            Array.isArray(json_requested_vs_approved_vacancies) &&
            json_requested_vs_approved_vacancies.length > 0
        ) ? this.getGroupedData(json_requested_vs_approved_vacancies, "Company Name", "Approved Vacancies", "Requested Vacancies", nivoKeysByRequestedApproved[0], nivoKeysByRequestedApproved[1]).reverse() : null;

        const nivoDataBySuccessRate = (json_trend_of_successful_placement_rate != null &&
            json_trend_of_successful_placement_rate != undefined &&
            Array.isArray(json_trend_of_successful_placement_rate) &&
            json_trend_of_successful_placement_rate.length > 0
        ) ? this.getLineData(json_trend_of_successful_placement_rate, "placement_date_processed", "Cumulative Frequency") : null;

        const nivoKeysByApplicantsVsApproved = ["No of Applicants", "Approved Vacancies"];
        const nivoDataByCompaniesApplicantsVsApproved = (json_top_popular_companies != null &&
            json_top_popular_companies != undefined &&
            Array.isArray(json_top_popular_companies) &&
            json_top_popular_companies.length > 0
        ) ? this.getGroupedData(json_top_popular_companies, "Company Name", "No of Applicants", "Approved Vacancies", nivoKeysByApplicantsVsApproved[0], nivoKeysByApplicantsVsApproved[1]).reverse() : null;

        const nivoDataByJobsApplicantsVsApproved = (json_top_popular_jobs != null &&
            json_top_popular_jobs != undefined &&
            Array.isArray(json_top_popular_jobs) &&
            json_top_popular_jobs.length > 0
        ) ? this.getGroupedData(json_top_popular_jobs, "Job name from Company", "No of Applicants", "Approved Vacancies", nivoKeysByApplicantsVsApproved[0], nivoKeysByApplicantsVsApproved[1]).reverse() : null;

        const nivoKeysByPopularSkillsByStudents = ["No of Students"];
        const nivoDataByPopularSkillsByStudents = (json_top_10_popular_skills_students != null &&
            json_top_10_popular_skills_students != undefined &&
            Array.isArray(json_top_10_popular_skills_students) &&
            json_top_10_popular_skills_students.length > 0
        ) ? this.getUniqueListBy(json_top_10_popular_skills_students, "Skill Name", "Count", nivoKeysByPopularSkillsByStudents[0]).reverse() : null;

        const nivoKeysByPopularSkillsByCompanies = ["No of Companies"];
        const nivoDataByPopularSkillsByCompanies = (json_top_10_popular_skills_companies != null &&
            json_top_10_popular_skills_companies != undefined &&
            Array.isArray(json_top_10_popular_skills_companies) &&
            json_top_10_popular_skills_companies.length > 0
        ) ? this.getUniqueListBy(json_top_10_popular_skills_companies, "Skill Name", "Number Indicated", nivoKeysByPopularSkillsByCompanies[0]).reverse() : null;

        const nivoKeysByCommonSkills = ["No of Students", "No of Companies"];
        const nivoDataByCommonSkills = (json_common_skills_between_company_students != null &&
            json_common_skills_between_company_students != undefined &&
            Array.isArray(json_common_skills_between_company_students) &&
            json_common_skills_between_company_students.length > 0
        ) ? this.getGroupedData(json_common_skills_between_company_students, "Skill Name", "No of Students", "No of Companies", nivoKeysByCommonSkills[0], nivoKeysByCommonSkills[1]).reverse() : null;

        const nivoKeysByShortListToHired = ["Count of companies"];
        const nivoDataByShortListToHired = (json_graph_breakdown_number_from_shortlisted_to_hired != null &&
            json_graph_breakdown_number_from_shortlisted_to_hired != undefined &&
            Array.isArray(json_graph_breakdown_number_from_shortlisted_to_hired) &&
            json_graph_breakdown_number_from_shortlisted_to_hired.length > 0
        ) ? this.getUniqueListBy(json_graph_breakdown_number_from_shortlisted_to_hired, "Number of Days from Shortlist to Hired", "Count", nivoKeysByShortListToHired[0]) : null;

        const nivoKeysByPreference = ["Preference Task Oriented", "Preference Backoffice"];
        const nivoDataByPreference = (
            json_student_preferences_taskoriented != null &&
            json_student_preferences_taskoriented != undefined &&
            Array.isArray(json_student_preferences_taskoriented) &&
            json_student_preferences_taskoriented.length > 0 &&
            json_student_preferences_backend != null &&
            json_student_preferences_backend != undefined &&
            Array.isArray(json_student_preferences_backend) &&
            json_student_preferences_backend.length > 0
        ) ? this.getDivergedData(json_student_preferences_taskoriented, json_student_preferences_backend, "preference_task_oriented", nivoKeysByPreference[0], nivoKeysByPreference[1], "count") : null;
        const nivoKeysByWorkdayTypes = ["Count"];
        const nivoDataByWorkdayTypes = (json_workday_types != null &&
            json_workday_types != undefined &&
            Array.isArray(json_workday_types) &&
            json_workday_types.length > 0
        ) ? this.getUniqueListBy(json_workday_types, "Workday Type", "Count", nivoKeysByWorkdayTypes[0]).reverse() : null;

        const commonProps = {
            type: "bar",
            enableGridX: true,
            enableGridY: true,
            axisTop: null,
            axisRight: null,
            axisBottom: {
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 100,
            },
            axisLeft: {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -40,
            },
        }

        return (
            <div>
                <BackRow paths={this.paths} />
                <div className="h3">
                    {t('analytics')}
                </div>
                <hr />
                <div
                    className='bx--type-epsilon mb-2 mt-2 text-black text-capitalize'>
                    {t('Filters')}
                </div>
                <div className="bx--row" >

                    {
                        isSuperAdmin === true ? (
                            <div className="d-flex">
                                <div>
                                    <DropDownBox
                                        title={t('faculty')}
                                        mainClassName="bx--form-item bx--col-lg-5"
                                        titleClass="bx--select"
                                        labelClass="bx--label"
                                        custom
                                        options={facultyArr}
                                        name={t('faculty')}
                                        placeholder={t('faculty')}
                                        onChange={(value, e, name) => this.handleDropdownChange(value, e, "facultyValue")}
                                        selectedValue={this.state.facultyValue}
                                    />
                                </div>
                                <div>
                                    <DropDownBox
                                        title={t('course')}
                                        mainClassName="bx--form-item bx--col-lg-5"
                                        titleClass="bx--select"
                                        labelClass="bx--label"
                                        custom
                                        options={coursesArr}
                                        name={t('course')}
                                        placeholder={t('course')}
                                        onChange={(value, e, name) => this.handleDropdownChange(value, e, "courseValue")}
                                        selectedValue={this.state.courseValue}
                                    />
                                </div>
                            </div>
                        ) : ''
                    }

                    {
                        !isSuperAdmin && isAdmin === true && !courseAdmin && parseInt(ctypeId) !== 1 && (
                            <div>
                                <DropDownBox
                                    title={t('course')}
                                    mainClassName="bx--form-item bx--col-lg-5"
                                    titleClass="bx--select"
                                    labelClass="bx--label"
                                    custom
                                    options={coursesArr}
                                    name={t('course')}
                                    placeholder={t('course')}
                                    onChange={(value, e, name) => this.handleDropdownChange(value, e, "courseValue")}
                                    selectedValue={this.state.courseValue}
                                />
                            </div>
                        )
                    }

                    <div>
                        <DropDownBox
                            title={t('batch')}
                            mainClassName="bx--form-item bx--col-lg-5"
                            titleClass="bx--select"
                            labelClass="bx--label"
                            custom
                            options={batchArr && Array.isArray(batchArr)
                                && batchArr.length > 0
                                ? (batchArr.map(one => ({
                                    label: one.batchName,
                                    value: one.batchName,
                                }))) : []}
                            name={t('batch')}
                            placeholder={t('batch')}
                            onChange={(value, e, name) => this.handleDropdownChange(value, e, "batchValue")}
                            selectedValue={this.state.batchValue}
                        />
                    </div>

                    <div className="mr-5">
                        <Button
                            className="bx--btn bx--btn--primary full-width mt-3"
                            onClick={() => this.onFilterChange(this.state.batchValue.value, this.state.courseValue.value, this.state.facultyValue.value)}>
                            {' '}
                            Filter{' '}
                        </Button>
                    </div>

                    <div>
                        <Button
                            className="bx--btn bx--btn--primary full-width mt-3"
                            kind="secondary"
                            onClick={() => this.props.updateInfo()}>
                            {' '}
                            Update{' '}
                        </Button>
                    </div>
                </div>
                <hr />
                <div>
                    <div className="bx--row mb-2">
                        <div className="bx--col card m-3 p-2">
                            <Batch icon={<Education32 />} isInfo mainHeading="Number of Students" count={single_number_of_students} subHeading="Excluding Suspended Students" />
                        </div>
                        <div className="bx--col card m-3 p-2">
                            <Batch icon={<Money32 />} isDollar mainHeading="Maximum Monthly Allowance" count={single_maximum_monthly_allowance} />
                        </div>
                    </div>
                    <div className="bx--row mb-2">
                        <div className="bx--col card m-3 p-2">
                            <Batch icon={<Education32 />} isInfo mainHeading="Student vs Available IIP Placement Ratio" count={single_student_vs_available_iip_placement_ratio} subHeading="Ratio of Students:Total Number of Requested Vacancies" />
                        </div>
                        <div className="bx--col card m-3 p-2">
                            <Batch icon={<Money32 />} isDollar mainHeading="Minimum Monthly Allowance" count={single_minimum_monthly_allowance} />
                        </div>
                    </div>
                    <div className="bx--row mb-2">
                        <div className="bx--col card m-3 p-2">
                            <Batch icon={<Education32 />} isInfo mainHeading="Company vs Available IIP Placement Ratio" count={single_company_vs_available_iip_placement_ratio} subHeading="Ratio of Company:Total Number of Requested Vacancies" />
                        </div>
                        <div className="bx--col card m-3 p-2">
                            <Batch icon={<Money32 />} isDollar mainHeading="Average Monthly Allowance" count={single_average_monthly_allowance} />
                        </div>
                    </div>
                    <div className="bx--row mb-3">
                        <div className="bx--col card m-3 p-2">
                            <Batch icon={<Education32 />} isInfo mainHeading="Number of Applications" count={single_number_of_application} subHeading="From Students" />
                        </div>
                        <div className="bx--col card m-3 p-2">
                            <Batch icon={<Money32 />} isDollar mainHeading="Median Monthly Allowance" count={single_median_monthly_allowance} />
                        </div>
                    </div>
                </div>
                <hr />
                {nivoDataByJobFunction != null && nivoDataByCompanyType &&
                    <div>
                        <div className="bx--row" >
                            {nivoDataByJobFunction != null && <div className="bx--col-lg-6 mb-5">
                                <NivoGraphs
                                    {...commonProps}
                                    layout="horizontal"
                                    colors="purple"
                                    finalNivoData={nivoDataByJobFunction}
                                    finalNivoKeys={nivoKeys}
                                    title="Students by Job Function"
                                    subtitle="The breakdown of students that were hired by job function."
                                    margin={{ top: 0, right: 10, bottom: 75, left: 168 }}
                                    translateX={-160}
                                    translateY={50}
                                    labelSkipWidth={60}
                                    labelFormat={d => `${Math.abs(d.value)} (${d.data.percentOfTotal}%)`}
                                    tooltip={d => (<div>{`${d.id} - ${d.indexValue}: `}<strong>{`${Math.abs(d.value)} (${d.data.percentOfTotal}%)`}</strong></div>)}
                                />
                            </div>}
                            {nivoDataByCompanyType != null && <div className="bx--col-lg-6 mb-5">
                                <NivoGraphs
                                    {...commonProps}
                                    layout="horizontal"
                                    colors="purple"
                                    finalNivoData={nivoDataByCompanyType}
                                    finalNivoKeys={nivoKeys}
                                    gridXValues={null}
                                    title="Students by Company Type"
                                    subtitle="The breakdown of students that were hired by company type."
                                    margin={{ top: 0, right: 10, bottom: 75, left: 140 }}
                                    translateX={-130}
                                    translateY={50}
                                    labelSkipWidth={60}
                                    labelFormat={d => `${Math.abs(d.value)} (${d.data.percentOfTotal}%)`}
                                    tooltip={d => (<div>{`${d.id} - ${d.indexValue}: `}<strong>{`${Math.abs(d.value)} (${d.data.percentOfTotal}%)`}</strong></div>)}
                                />
                            </div>}
                        </div>
                        <hr />
                    </div>
                }

                {nivoDataByIndustryType != null &&
                    <div>
                        <div className="bx--row mb-5">
                            <div className="bx--col-lg-12">
                                <NivoGraphs
                                    {...commonProps}
                                    layout="horizontal"
                                    colors="purple"
                                    finalNivoData={nivoDataByIndustryType}
                                    finalNivoKeys={nivoKeys}
                                    gridXValues={null}
                                    title="Students by Industry Type"
                                    subtitle="The breakdown of students that were hired by the different industry type in the market."
                                    layout="horizontal"
                                    margin={{ top: 0, right: 10, bottom: 70, left: 250 }}
                                    height={600}
                                    translateX={-240}
                                    translateY={50}
                                    labelSkipWidth={60}
                                    labelFormat={d => `${Math.abs(d.value)} (${d.data.percentOfTotal}%)`}
                                    tooltip={d => (<div>{`${d.id} - ${d.indexValue}: `}<strong>{`${Math.abs(d.value)} (${d.data.percentOfTotal}%)`}</strong></div>)}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>
                }
                {nivoDataByRequestedVsApproved != null &&
                    <div>
                        <div className="bx--row mb-5">
                            <div className="bx--col-lg-12">
                                <NivoGraphs
                                    type="bar"
                                    enableGridX={true}
                                    enableGridY={true}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 10,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: 100,
                                    }}
                                    axisLeft={{
                                        format: (v) => {
                                            const values = v.split("_");
                                            return <a href={`${values[1]}`} target="_blank">{values[0]}</a>;
                                        },
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }}
                                    tooltip={d => {
                                        const values = d.indexValue.split("_");
                                        return <div>{`${d.id} - ${values[0]}: `}<strong>{Math.abs(d.value)}</strong></div>
                                    }}

                                    layout="horizontal"
                                    colors={['#61cdbb', '#97e3d5']}
                                    // labelTextColor="grey"
                                    finalNivoData={nivoDataByRequestedVsApproved}
                                    finalNivoKeys={nivoKeysByRequestedApproved}
                                    groupMode="grouped"
                                    title="Top 20 Companies with the Most Number of Requested and Approved Vacancies"
                                    subtitle="Top 20 companies with the most number of requested vacancies."
                                    margin={{ top: 0, right: 10, bottom: 75, left: 250 }}
                                    height={600}
                                    translateX={-240}
                                    translateY={50}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>}
                {nivoDataBySuccessRate != null &&
                    <div>
                        <div className="bx--row mb-5">
                            <div className="bx--col-lg-12">
                                <NivoGraphs
                                    type="line"
                                    title="Trend of Successful Placement Rate"
                                    subtitle="The number of students that are hired against the number of days since the launch of the portal."
                                    id="Students Placed"
                                    finalNivoData={nivoDataBySuccessRate}
                                    margin={{ top: 10, right: 10, bottom: 70, left: 40 }}
                                    enableGridY
                                    enableGridX
                                    axisTop={null}
                                    axisRight={null}
                                    axisLeft={{
                                        legend: '',
                                        legendOffset: 12,
                                    }}
                                    axisBottom={{
                                        format: '%b %Y',
                                        // tickValues: 12,
                                        legend: "placement_date",
                                        legendOffset: -12,
                                    }}
                                    translateX={-35}
                                    translateY={50}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>}

                {nivoDataByCompaniesApplicantsVsApproved != null &&
                    <div>
                        <div className="bx--row mb-5">
                            <div className="bx--col-lg-12">
                                <NivoGraphs
                                    type="bar"
                                    enableGridX={true}
                                    enableGridY={true}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 10,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: 100,
                                    }}
                                    axisLeft={{
                                        format: (v) => {
                                            const values = v.split("_");
                                            return <a href={`${values[1]}`} target="_blank">{values[0]}</a>;
                                        },
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }}
                                    tooltip={d => {
                                        const values = d.indexValue.split("_");
                                        return <div>{`${d.id} - ${values[0]}: `}<strong>{Math.abs(d.value)}</strong></div>
                                    }}
                                    layout="horizontal"
                                    colors={['#61cdbb', '#97e3d5']}
                                    finalNivoData={nivoDataByCompaniesApplicantsVsApproved}
                                    finalNivoKeys={nivoKeysByApplicantsVsApproved}
                                    groupMode="grouped"
                                    title="Top 10 Popular Companies"
                                    subtitle="The top 10 companies with the highest number of applicants vis-a-vis the number of approved vacancies."
                                    margin={{ top: 0, right: 10, bottom: 75, left: 250 }}
                                    height={600}
                                    translateX={-240}
                                    translateY={50}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>}

                {nivoDataByJobsApplicantsVsApproved != null &&
                    <div>
                        <div className="bx--row mb-5">
                            <div className="bx--col-lg-12">
                                <NivoGraphs
                                    type="bar"
                                    enableGridX={true}
                                    enableGridY={true}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 10,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: 100,
                                    }}
                                    axisLeft={{
                                        format: (v) => {
                                            const values = v.split("_");
                                            return <a href={`${values[1]}`} target="_blank">{values[0]}</a>;
                                        },
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }}
                                    tooltip={d => {
                                        const values = d.indexValue.split("_");
                                        return <div>{`${d.id} - ${values[0]}: `}<strong>{Math.abs(d.value)}</strong></div>
                                    }}
                                    layout="horizontal"
                                    colors={['#61cdbb', '#97e3d5']}
                                    finalNivoData={nivoDataByJobsApplicantsVsApproved}
                                    finalNivoKeys={nivoKeysByApplicantsVsApproved}
                                    groupMode="grouped"
                                    title="Top 10 Popular Jobs"
                                    subtitle="The top 10 jobs with the highest number of applicants vis-a-vis the number of approved vacancies."
                                    margin={{ top: 0, right: 10, bottom: 75, left: 450 }}
                                    height={600}
                                    translateX={-440}
                                    translateY={50}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>
                }

                <div >
                    <div className="bx--row">
                        {nivoDataByPreference != null && <div className="bx--col-lg-12 mb-5">
                            <NivoGraphs
                                type="bar"
                                title="Student Preferences"
                                subtitle="The breakdown of students individual preferences."
                                layout="horizontal"
                                finalNivoData={nivoDataByPreference}
                                finalNivoKeys={nivoKeysByPreference}
                                markers={
                                    [
                                        {
                                            axis: 'x',
                                            value: 0,
                                            lineStyle: { strokeOpacity: 0 },
                                            textStyle: { fill: '#2ebca6' },
                                        },
                                        {
                                            axis: 'x',
                                            value: 0,
                                            lineStyle: { stroke: '#f47560', strokeWidth: 1 },
                                            textStyle: { fill: '#e25c3b' },
                                        },
                                    ]
                                }
                                axisTop={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 12,
                                    format: v => `${Math.abs(v)}`,
                                }}
                                axisLeft={{
                                    tickSize: 0,
                                    tickPadding: 12,
                                }}
                                axisRight={null}
                                enableGridY
                                enableGridX
                                margin={{ top: 0, right: 50, bottom: 65, left: 50 }}
                                colors={['#97e3d5', '#f47560']}
                                translateY={55}
                                translateX={-40}
                                itemWidth={150}
                            />
                        </div>}
                    </div>
                    <hr />
                </div>

                <div >
                    <div className="bx--row">
                        {nivoDataByShortListToHired != null && <div className="bx--col-lg-12" style={{ marginBottom: "2rem" }}>
                            <NivoGraphs
                                type="bar"
                                enableGridX={true}
                                enableGridY={true}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: 'middle',
                                    legendOffset: 38,
                                    legend: "Number of Days from Shortlist to Hired",
                                    // tickValues: this.createTickValues(nivoDataByShortListToHired),
                                }
                                }
                                axisLeft={
                                    {
                                        legend: "count",
                                        // tickValues: this.createTickValues(nivoDataByShortListToHired),
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }
                                }
                                finalNivoData={nivoDataByShortListToHired}
                                finalNivoKeys={nivoKeysByShortListToHired}
                                title="Number of Days from Shortlist to Hired"
                                tooltip={d => <div>{`${d.id} - ${d.indexValue} ${d.indexValue == 1 ? "Day" : "Days"} : `}<strong>{`${Math.abs(d.value)} ${Math.abs(d.value) == 1 ? "Company" : "Companies"}`}</strong></div>}
                                subtitle="The number of companies and the number of days taken from the shortlisting of a student to the hiring stage."
                                gridXValues={null}
                                margin={{ top: 20, right: 10, bottom: 90, left: 50 }}
                                translateX={-45}
                                translateY={70}
                            />
                        </div>}
                    </div>
                    <hr />
                </div>

                {(nivoDataByPopularSkillsByStudents != null || nivoDataByPopularSkillsByCompanies != null) && <div>
                    <div className="bx--row mb-3">
                        {nivoDataByPopularSkillsByStudents != null && <div className="bx--col-lg-6 mb-5">
                            <NivoGraphs
                                {...commonProps}
                                layout="horizontal"
                                colors="purple"
                                finalNivoData={nivoDataByPopularSkillsByStudents}
                                finalNivoKeys={nivoKeysByPopularSkillsByStudents}
                                title="Top 10 Skills Indicated by Students"
                                subtitle="Top 10 skills that students have indicated."
                                margin={{ top: 0, right: 10, bottom: 70, left: 160 }}
                                translateX={-150}
                                translateY={60}
                            />
                        </div>}
                        {nivoDataByPopularSkillsByCompanies != null && <div className="bx--col-lg-6 mb-5">
                            <NivoGraphs
                                {...commonProps}
                                layout="horizontal"
                                colors="purple"
                                finalNivoData={nivoDataByPopularSkillsByCompanies}
                                finalNivoKeys={nivoKeysByPopularSkillsByCompanies}
                                title="Top 10 Skills Indicated by Companies"
                                subtitle="Top 10 skills that companies have indicated."
                                margin={{ top: 0, right: 10, bottom: 70, left: 170 }}
                                translateX={-150}
                                translateY={60}
                            />
                        </div>}
                    </div>
                    <hr />
                </div>}

                {nivoDataByCommonSkills != null &&
                    <div>
                        <div className="bx--row mb-5">
                            <div className="bx--col-lg-12">
                                <NivoGraphs
                                    {...commonProps}
                                    layout="horizontal"
                                    colors={['#61cdbb', '#97e3d5']}
                                    finalNivoData={nivoDataByCommonSkills}
                                    finalNivoKeys={nivoKeysByCommonSkills}
                                    groupMode="grouped"
                                    title="Highest Common Matched Skills between Students and Companies"
                                    subtitle="The most common matched skills between students and companies."
                                    margin={{ top: 0, right: 10, bottom: 75, left: 250 }}
                                    height={500}
                                    translateX={-240}
                                    translateY={50}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>
                }

                <div>
                    <div className="bx--row ">
                        <div className="bx--col-lg-6 mb-5">
                            <NivoGraphs
                                finalNivoData={[
                                    {
                                        "id": "shortlist",
                                        "value": 13,
                                        "label": "Number of days from student applied to Company Shortlist Student"
                                    },
                                    {
                                        "id": "offerLetterSent",
                                        "value": 5,
                                        "label": "Number of days from Company Shortlist Student to Offer Letter Sent(Company has Accepted Students)"
                                    },
                                    {
                                        "id": "placementConfirmation",
                                        "value": 1,
                                        "label": "Number of days from Offer Letter Sent to Placement Confirmation (Student has Confirmed Placement)"
                                    }
                                ]}
                                margin={{ top: 20, right: 20, bottom: 70, left: 20 }}
                                type="funnel"
                                title="Average Duration(in days) Taken for a Student/Company to find a Suitable Match"
                                colors={["#9e0142", "#d53e4f", "#f46d43"]}
                                beforeSeparatorLength={100}
                                afterSeparatorLength={100}
                                afterSeparatorOffset={0}
                                currentPartSizeExtension={30}
                                currentBorderWidth={0}
                            />
                            <div className="d-flex xpa-legend-box">
                                <div style={{ backgroundColor: "#9e0142" }} className="xpa-color-box"></div>
                                <div>Number of days from student applied to Company Shortlist Student</div>
                            </div>
                            <div className="d-flex xpa-legend-box">
                                <div style={{ backgroundColor: "#d53e4f" }} className="xpa-color-box"></div>
                                <div>Number of days from Company Shortlist Student to Offer Letter Sent(Company has Accepted Students)</div>
                            </div>
                            <div className="d-flex xpa-legend-box">
                                <div style={{ backgroundColor: "#f46d43" }} className="xpa-color-box"></div>
                                <div>Number of days from Offer Letter Sent to Placement Confirmation (Student has Confirmed Placement)</div>
                            </div>
                        </div>

                        {nivoDataByWorkdayTypes != null && <div className="bx--col-lg-6 mb-5">
                            <NivoGraphs
                                {...commonProps}
                                layout="horizontal"
                                labelSkipWidth={60}
                                labelFormat={d => `${Math.abs(d.value)} (${d.data.percentOfTotal}%)`}
                                tooltip={d => (<div>{`${d.id} - ${d.indexValue}: `}<strong>{`${Math.abs(d.value)} (${d.data.percentOfTotal}%)`}</strong></div>)}
                                color="purple"
                                finalNivoData={nivoDataByWorkdayTypes}
                                finalNivoKeys={nivoKeysByWorkdayTypes}
                                title="Workday Types"
                                subtitle="The breakdown of the workday types for IIP"
                                margin={{ top: 0, right: 10, bottom: 65, left: 210 }}
                                translateX={-200}
                                translateY={55}
                            />
                        </div>}
                    </div>
                </div>
                <JDProcessOverlay show={loading} message="processing..." />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    p: state,
    analyticsInfo: state.analyticsInfo.analyticsInfo,
    loading: state.analyticsInfo.loading,
    facultyData: state.candidateDetails.facultyData,
    user: state.x0paDuser.user,
    coursesData: state.candidateDetails.coursesData,
})

const mapDispatchToProps = {
    getAnalyticsInfo,
    getAllFaculties,
    getAllCourses,
    updateInfo,
    getBatchDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Analytics));
