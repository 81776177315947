import {
  REQUEST_GET_STUDENT_INTERVIEW_ASSESS,
  REQUEST_ADD_CAND_TO_ASSESS,
  REQUEST_SEND_ASSESS_INV_EMAIL,
} from 'actions/actionTypes';
import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';

const getStudentInterviewAssessments = profileId => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'Missing required fields.',
  };
  if (!profileId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/events/s/assessments?_=${Date.now()}&profileId=${profileId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_STUDENT_INTERVIEW_ASSESS,
  };

  return callApi(params);
}

const addCandToAssessment = ({ data, sid }) => (dispatch) => {
  let url = `${baseUrl}/events/s/assessments/add?_=${Date.now()}`;
  if (sid) {
    url += `&profileId=${sid}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_ADD_CAND_TO_ASSESS,
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const sendAssessInvitationEmail = ({ data, sid }) => (dispatch) => {
  let url = `${baseUrl}/events/s/assessments/invite?_=${Date.now()}`;
  if (sid) {
    url += `&profileId=${sid}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_SEND_ASSESS_INV_EMAIL,
    body: JSON.stringify(data),
  };

  return callApi(params);
};

export {
  getStudentInterviewAssessments,
  addCandToAssessment,
  sendAssessInvitationEmail
};
