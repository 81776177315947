import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_GET_REPORTS_DATA,
  REQUEST_DOWNLOAD_REPORTS_DATA,
  REQUEST_GET_STUDENT_REPORTS,
} from './actionTypes';

const getReportsDetails = data => (dispatch) => {
  const {
    companyId,
  } = data || {};
  let url = `${baseUrl}/reports?_=${new Date().getTime()}&`;
  if (companyId) {
    url += `companyId=${companyId}&`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_REPORTS_DATA,
  };

  return callApi(params);
};

const downloadReportsDetails = data => (dispatch) => {
  const {
    companyId,
  } = data || {};
  let url = `${baseUrl}/reports/download?_=${new Date().getTime()}&`;
  if (companyId) {
    url += `companyId=${companyId}&`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_DOWNLOAD_REPORTS_DATA,
  };

  return callApi(params);
};

const getStudentReportDetails = data => (dispatch) => {
  const {
    institutionId,
  } = data || {};
  const error = {
    message: 'Invalid institutionId',
  };
  if (!institutionId) {
    Promise.reject(error);
  }
  let url = `${baseUrl}/reports/students?_=${new Date().getTime()}&`;
  if (institutionId) {
    url += `institutionId=${institutionId}&`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_STUDENT_REPORTS,
  };

  return callApi(params);
};

export {
  getReportsDetails,
  downloadReportsDetails,
  getStudentReportDetails,
};
