import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, AccordionItem, } from 'carbon-components-react';
import { getUserEmailNotifications } from "actions/userEmailNotifications.js"
import BackRow from 'components/common/BackRow/BackRow';
import moment from 'moment';
import "./Notifications.css";
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
// import { parse } from 'query-string';
// import { stubObject } from 'lodash';
import parse from 'html-react-parser';


const EmailNotificationTile = ({ subject, status, createdAt }) => {
    return (
        <div className="bx--row">
            <div className="bx--col">
                {subject}
            </div>
            <div className="bx--col">
                {status}
            </div>
            <div className="bx--col">
                {moment(createdAt).format('DD/MM/YY, hh:mma')}
            </div>
        </div>
    )
}

class Notifications extends Component {
    compPaths = [
        { id: 'campus', name: 'Campus', href: '/app/campus' },
        { id: 'comp-view', name: 'notifications', href: '' },
    ];
    constructor(props) {
        super(props);
        this.state = {
            userEmailNotifications: [],
            userInfo: {},
            loading: false
        }
    }

    componentDidMount() {
        this.handleEmailNotificationsData();
    }




    componentDidUpdate(prevProps) {
        const {
            match: {
                params: { id: prevPropId }
            }
        } = prevProps;

        const {
            match: {
                params: { id: propId }
            }
        } = this.props;
        if (propId != prevPropId) {
            this.handleEmailNotificationsData();
        }
    }


    handleEmailNotificationsData = () => {
        const {
            match: {
                params: { id: propId }
            }
        } = this.props;
        if (propId !== null && propId !== undefined) {
            const id = propId;
            this.props.getUserEmailNotifications({ id }).then((res) => {
                this.setState({ userEmailNotifications: res.list, userInfo: res.userinfo })
            })
        } else {
            const { userId, roleId, company: { ctypeId } } = this.props.user;
            // if (parseInt(roleId) === 4 && parseInt(ctypeId) === 1) {
            const id = userId;
            this.props.getUserEmailNotifications({ id }).then((res) => {
                this.setState({ userEmailNotifications: res.list, userInfo: res.userinfo })
            })
            // }
        }
    }

    render() {
        // const { userId, roleId, company: { ctypeId } } = this.props.user;
        const { userEmailNotifications, userInfo } = this.state;
        const { userId, ctype_id: ctypeId, firstname, lastname, company_name } = userInfo || {};
        const {
            match: {
                params: { id: propId }
            }
        } = this.props;
        return (
            <Fragment>
                {
                    // parseInt(ctypeId) === 1 && (
                    <Fragment>
                        <BackRow paths={this.compPaths} />
                    </Fragment>
                    // )
                }
                <div>
                    <div>
                        <h4>{(propId !== null && propId !== undefined) ? ctypeId == 1 ? "Employer" : "Student" : ""} Email Notifications</h4>
                        {propId !== null && propId !== undefined && ctypeId == 1 &&
                            <div className="mb-2">
                                <div>
                                    <span>Employer Name: </span><span style={{ fontWeight: 600 }}>{`${firstname} ${lastname}`}</span>
                                </div>
                                <div>
                                    <span>Company Name: </span><span style={{ fontWeight: 600 }}>{`${company_name}`}</span>
                                </div>
                            </div>
                        }

                        {propId !== null && propId !== undefined && ctypeId != 1 &&
                            <div className="mb-2">
                                <span>Student Name: </span><span style={{ fontWeight: 600 }}>{`${firstname} ${lastname}`}</span>
                            </div>
                        }
                        {
                            (propId === null || propId === undefined) &&
                            <div className="mb-2">
                                <span>Name: </span><span style={{ fontWeight: 600 }}>{`${firstname} ${lastname}`}</span>
                            </div>
                        }
                    </div>

                    {userEmailNotifications &&
                        <Accordion className="">
                            <AccordionItem
                                className="cutomize-accordion-item"
                                title={
                                    <div className="bx--row">
                                        <div className="bx--col">
                                            <h6>Subject</h6>
                                        </div>
                                        <div className="bx--col">
                                            <h6>Status</h6>
                                        </div>
                                        <div className="bx--col">
                                            <h6>Sent at</h6>
                                        </div>
                                    </div>
                                }
                            />
                            {
                                userEmailNotifications.map((emailNotification) => {
                                    const { subject, status, createdAt, body } = emailNotification;
                                    // var parse = require('html-react-parser');
                                    const doc = parse(body);
                                    return <AccordionItem
                                        title={
                                            <EmailNotificationTile
                                                subject={subject}
                                                status={status}
                                                createdAt={createdAt}
                                                body={body}
                                            />
                                        }
                                    >
                                        <div style={{backgroundColor:"whitesmoke", padding:"15px"}}>
                                            {doc}
                                        </div>
                                        {/* {body} */}
                                    </AccordionItem>
                                })
                            }
                        </Accordion>
                    }
                </div>
                <JDProcessOverlay show={this.props.loading} message="processing..." />
            </Fragment>
        )
    }

}

const mapStateToprops = state => ({
    user: state.x0paDuser.user,
    loading: state.userEmailNotifications.loading
})

const mapDispatchToprops = {
    getUserEmailNotifications
}

export default connect(mapStateToprops, mapDispatchToprops)(withTranslation()(Notifications));