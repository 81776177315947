const darkGreen = {
  bg: 'bg-darkgreen-g',
  cl: 'cl-darkgreen',
  withWhiteText: '#4c9c07',
};
const lightGreen = {
  bg: 'bg-green-g',
  cl: 'cl-green',
  withWhiteText: '#7bf39c',
};
const yellow = { bg: 'bg-yellow-g', cl: 'cl-yellow', withWhiteText: '#efb132' };
const red = { bg: 'bg-pink-g', cl: 'cl-pink', withWhiteText: '#eb757b' };
const black = { bg: 'bg-white-g', cl: 'cl-black', withWhiteText: 'black' };
const lavender = {bg : 'bg-lavender-g', cl : 'cl-lavender', withWhiteText : '#CDC1C5'}
function getColor(value, v1, v2, v3) {
  switch (true) {
    case value >= v1:
      return darkGreen;
    case value >= v2:
      return lightGreen;
    case value >= v3:
      return yellow;
    case typeof value === 'number':
      return red;
    default:
      return lavender;
  }
}

const getNetValueColor = value => getColor(value, 411470 / 1000, 215319 / 1000, 108928 / 1000);

function getLoyaltyColor(value) {
  value = value && value.toString();
  if (!value || value.length == 0 || value.startsWith('N/A')) {
    return black;
  }
  value = parseFloat(value.split('%')[0]);
  if (value >= 70) {
    return darkGreen;
  }
  if (value >= 40 && value < 70) {
    return yellow;
  }
  if (value < 40) {
    return red;
  }
  return red;
}

function getAbsoluteMatchingColor(value) {
  value = value && value.toString();
  if ((value === null || (value && value.length) == 0 || (value && value.startsWith && value.startsWith('N/A')) ) && (value !== 0)) {
    return black;
  }
  value = value + '';
  value = parseFloat(value.split('%')[0]);
  if (value >= 70) {
    return darkGreen;
  }
  if (value >= 50 && value < 70) {
    return lightGreen;
  }
  if (value > 25 && value <= 49) {
    return yellow;
  }
  if (value <= 25) {
    return red;
  }
}

function getPerformanceColor(value) {
  value = value && value.toString();
  if (!value || value.length == 0 || value.startsWith('N/A')) {
    return black;
  }
  value = parseFloat(value.split('%')[0]);
  if (value >= 75) {
    return darkGreen;
  }
  if (value >= 50) {
    return lightGreen;
  }
  if (value >= 25) {
    return yellow;
  }
  return red;
}

function getRiskColor(value) {
  value = value && value.toString();
  if (!value || value.length == 0 || value.startsWith('N/A')) {
    return darkGreen;
  }
  value = parseFloat(value.split('%')[0]);
  if (value >= 75) {
    return red;
  }
  if (value >= 50) {
    return yellow;
  }
  if (value >= 25) {
    return lightGreen;
  }
  return darkGreen;
}

function getStabilityColor(yearValue) {
  // console.log("Stability1", yearValue, typeof yearValue);
  yearValue = yearValue && yearValue.toString();
  // console.log("Stability", JSON.stringify(yearValue), typeof yearValue);
  if (!yearValue || yearValue.length == 0 || yearValue.startsWith('N/A')) {
    return lavender;
  }
  yearValue = parseFloat(yearValue.trim().split(' ')[0]);
  // console.log("Stability1", yearValue, typeof yearValue);
  if (yearValue > 5) {
    return darkGreen;
  }
  if (yearValue >= 3) {
    return lightGreen;
  }
  if (yearValue >= 1.5) {
    return yellow;
  }
  return red;
}

function getDiversityColor(ratioValue) {
  ratioValue = ratioValue && ratioValue.toString();
  if (!ratioValue || ratioValue.length == 0 || ratioValue.startsWith('N/A')) {
    return lavender;
  }
  const values = ratioValue.split('/');
  const diff = Math.abs(parseFloat(values[0]) - parseFloat(values[1]));
  if (diff <= 10) {
    return darkGreen;
  }
  if (diff <= 20) {
    return lightGreen;
  }
  if (diff <= 40) {
    return yellow;
  }
  return red;
}

function getAttritionColor(value) {
  value = value && value.toString();
  if (!value || value.length == 0 || value.startsWith('N/A')) {
    return lavender;
  }
  value = parseFloat(value.split('%')[0]);
  if (value <= 10) {
    return darkGreen;
  }
  if (value <= 15) {
    return lightGreen;
  }
  if (value <= 20) {
    return yellow;
  }
  return red;
}

const ColorCode = {
  getLoyaltyColor,
  getPerformanceColor,
  getRiskColor,
  getStabilityColor,
  getDiversityColor,
  getAttritionColor,
  getNetValueColor,
  getAbsoluteMatchingColor,
};

export default ColorCode;
