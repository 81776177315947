import {
  SUCCESS_USER_INVITE,
  SUCCESS_USER_INVITE_UPDATE,
  SUCCESS_USER_DELETE,
  REQUEST_USER_INVITE,
  REQUEST_USER_INVITE_UPDATE,
} from 'actions/actionTypes';

const initialState = {
  invites: [],
  newInvite: [],
  deleteInvite: [],
  loading: false,
  count:0,
};

const AdminDashboard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUCCESS_USER_INVITE:
      return {
        ...state,
        newInvite: payload,
        loading: false,
      };
    case REQUEST_USER_INVITE:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_USER_INVITE_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_USER_INVITE_UPDATE:
      return {
        ...state,
        invites: (payload && payload.users) || [],
        count:(payload && payload.count) || 0,
        loading: false,
      };
    case SUCCESS_USER_DELETE:
      return {
        ...state,
        deleteInvite: payload,
      };
    default:
      return state;
  }
};

export default AdminDashboard;
