import {
  REQUEST_GET_CALENDAR_EVENTS,
  SUCCESS_GET_CALENDAR_EVENTS,
  ERROR_GET_CALENDAR_EVENTS,
  REQUEST_GET_CALENDAR_EVENT_ID,
  SUCCESS_GET_CALENDAR_EVENT_ID,
  ERROR_GET_CALENDAR_EVENT_ID,
} from 'actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  calendarEvents: [],
  eventInfo: {},
};

const CalendarEvents = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_CALENDAR_EVENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CALENDAR_EVENTS:
      return {
        calendarEvents: payload && payload.calendarEvents,
        loading: false,
        error: null,
      };
    case ERROR_GET_CALENDAR_EVENTS:
      return {
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CALENDAR_EVENT_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CALENDAR_EVENT_ID:
      return {
        eventInfo: (payload && payload.eventInfo) || null,
        loading: false,
        error: null,
      };
    case ERROR_GET_CALENDAR_EVENT_ID:
      return {
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default CalendarEvents;
