import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { login, getOTP } from 'actions';
import { push } from 'react-router-redux';
import classNames from 'classnames';
import PublicHeader from 'containers/common/publicHeader';
// import instImage from 'assets/loginImages/institution_img.jpg';
import instImage from 'assets/loginImages/institution_img.jpg';
import { InlineNotification, Loading } from 'carbon-components-react';
import { withTranslation } from 'react-i18next';
import { fetchUserLoginState,  userLoginInfo, getUserLoginInfo, getIsEligibleToLogin } from 'actions/userstate';
import Recaptcha from 'react-invisible-recaptcha';
import './Login.css';


class CollegeLogin extends React.Component {
  state = {
    isLogging: false,
    username: '',
    otpSent: false,
    error: false,
    otp: '',
    otpErrorMsg: '',
    showLanguageModal: false,
    loginAttempts: 3,
    isReSendOtp: false,
    enableCaptcha: true,
    captcha: null,
    loginAttemptsArray: [],
    ableToLogin: true,
    finalCount: 0
  };

  handleOnChange = (name, value) => {
    if (name == "captcha") {
      this.setState({ captchaError: false })
    }
    if (name == "username") {
      const regx = new RegExp(String.fromCharCode(0x2024), 'g');
      value = value.replace(regx, '.');
      this.setState({
        [name]: value,
        error: false,
      })
    }
    else {
      this.setState({
        [name]: value,
        error: false,
      });
    }
  }

  onCloseButtonClick = () => {
    this.setState({
      error: false,
    });
  }

  handleOnSubmit = (e) => {
    const {
      user: {
        userId,
      } = {},
      t,
    } = this.props;
    e.preventDefault();
    const { username, otp, otpSent, captcha, loginAttemptsArray } = this.state;
    //getTime
    let currentTime = new Date();
    let lastLoginAttemptTime;
    let loginInfoId;

    this.props.getUserLoginInfo({email: this.state.username}).then((resp) => {
      if(resp && !resp.error){
        loginInfoId = resp && resp.login_info_id;
        lastLoginAttemptTime = resp && resp.locked_time_stamp;
        let isAccountLocked = resp && resp.is_account_locked;
        let previousLoginAttempts = resp && resp.login_attempts_count
        previousLoginAttempts = (typeof previousLoginAttempts == "string" ? parseInt(previousLoginAttempts) : previousLoginAttempts)
        if(previousLoginAttempts == 10){
          loginInfoId = typeof loginInfoId == "string" ? parseInt(loginInfoId) : loginInfoId
          let data = {
            currentTime: currentTime,
            loginInfoId: loginInfoId
          }
          this.props.getIsEligibleToLogin(data).then((resps) => {
            if(resps && !resps.error){
              let userLoginRecently = resps && resps.userLoginRecently
              if(userLoginRecently == true){
                this.setState({
                  ableToLogin: false,
                  isLogging: false,
                  finalCount: previousLoginAttempts
                })
              }
            }
          }).catch((err) => {
            console.log("err$$$",err)
          })
        }
      }
    })
    this.setState({ isLogging: true }, () => {
      if (!otpSent) {
        this.recaptcha.reset();
        this.recaptcha.execute();
      } else {
        const data = {
          "username": (username && username.toString() && username.toLowerCase()),
          "password": (otp && otp.trim()),
          "captcha": (captcha && captcha.toString())
        }
        this.props.login(data).then(() => {
          this.props.fetchUserLoginState(userId).then((res) => {
            if (res && res.error) {
              return this.setState({ isLogging: false, error: true });
            }
          });
          this.setState({ otpSent: true, isLogging: false });
        }).catch((err) => {
          // alert('Invalid username/password');
          console.log('X0PA Error:', err);
          this.setState({
            isLogging: false,
            error: true,
            loginAttempts: this.state.loginAttempts - 1,
            isReSendOtp: false
          });

          loginAttemptsArray.push(this.state.loginAttempts)
       
          let sumOfloginAttempts;
          let timeDifference;
          
          if (loginAttemptsArray && Array.isArray(loginAttemptsArray) && loginAttemptsArray.length > 0) {
            sumOfloginAttempts = loginAttemptsArray.length || 0
          }
          let info = {
            email: this.state.username,
            lockedTimeStamp: new Date().toISOString(),
            loginAttemptsCount: sumOfloginAttempts
          }
          
          this.props.userLoginInfo(info).then((res) => {
            if(res && !res.error){
              this.props.getUserLoginInfo({email: (res && res.email)}).then((resp) => {
                lastLoginAttemptTime = resp && resp.locked_time_stamp;
                let previousLoginAttempts = resp && resp.login_attempts_count
                previousLoginAttempts = (typeof previousLoginAttempts == "string" ? parseInt(previousLoginAttempts) : previousLoginAttempts)
                if(previousLoginAttempts == 10){
                  this.setState({
                    // ableToLogin: false,
                    isLogging: false,
                    finalCount: previousLoginAttempts,
                    loginAttempts: 0
                  })
                }
              })
            }
          }).catch((err) => {
            console.log("err$$$$",err)
          })
        });
      }
    });
  }

  handleResendOtp = () => {
    this.setState({
      isLogging: true,
    }, () => {
      const {
        username,
      } = this.state;
      this.props.getOTP(username && username.toString() && username.toLowerCase()).then(() => {
        this.setState({
          isLogging: false,
          error: true,
          isReSendOtp: true
        });
        if (this.state.isReSendOtp === true) {
          this.setState({
            loginAttempts: 3
          })
        }
      });
    });
  }

  handleRedirectToLogin = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/');
    }
  }

  handleLangugaeModal = () => {
    const { showLanguageModal } = this.state;
    this.setState({
      showLanguageModal: !showLanguageModal,
    });
  }

  onResolved = () => {
    const {
      user: {
        userId,
      } = {},
      t,
    } = this.props;
    const { username, otp, ableToLogin } = this.state;
    const captcha = this.recaptcha.getResponse();
    if(ableToLogin == true){
      this.setState({ captcha }, () => {
        this.props.getOTP(username && username.toString() && username.toLowerCase(),).then((res) => {
          if (!res) {
            this.setState({
              otpSent: false, isLogging: false, error: true, errMsg: 'error1'
            });
          }
          if (res && !res.error) {
            this.setState({ otpSent: true, isLogging: false });
          }
          else {
            if (res.error == "not a registered domain") {
              this.setState({
                otpSent: false,
                isLogging: false,
                error: true,
                errMsg: 'error2'
              })
            }
            if (res.error == "user is not approved yet") {
              this.setState({
                otpSent: false, isLogging: false, error: true, errMsg: 'error3'
              })
            }
          }
        }).catch((err) => {
          // alert('Failed to get OTP');
          console.log('X0PA Error:', err);
          const otpErrorMsg = `${t('failedOtp')}`;
          this.setState({ isLogging: false, error: true, otpErrorMsg, errMsg: 'error4' });
        });
      })
    }
    else{
    }
  }

  render() {
    const { error, otpSent, showLanguageModal, loginAttempts, isReSendOtp, captchaError, errMsg, loginAttemptsArray, ableToLogin, finalCount } = this.state;
    const formClass = classNames({
      'form-signin': true,
      'text-center': true,
      card: true,
      error,
    });

    const { t } = this.props;
    let isVerifyDisable = false
    let isResendDisable = false

    if (loginAttempts == 0) {
      isVerifyDisable = true
    }

    if (isReSendOtp === true) {
      isVerifyDisable = false
    }

    if(loginAttemptsArray && Array.isArray(loginAttemptsArray) && loginAttemptsArray.length == 10){
      isVerifyDisable = true
      isResendDisable = true
    }

    if(finalCount == 10){
      isVerifyDisable = true
      isResendDisable = true
    }

    return (
      <Fragment>
        <PublicHeader
          handleRedirectToLogin={this.handleRedirectToLogin}
          showLanguageModal={showLanguageModal}
          handleLangugaeModal={this.handleLangugaeModal}
          userGuideLink="https://rp-adminhelp.x0pa.ai/"
        />
        <div className="collegelogin login-page col-12 bx--row m-0 p-0">
          <div style={{ backgroundImage: `url(${instImage})` }} className="left-side col-12 col-md-6 col-lg-6 col-xl-6 m-0 p-0 d-flex justify-content-center align-items-center flex-column">
          
            <div className="institution-mask col m-0 p-0 d-flex justify-content-center align-items-center">
            
              <div className="m-3 text-center p-2" style={{ background: 'rgba(0,0,0,0.4)' }}>
                <div className="bx--type-alpha main_header_color font-weight-bold">{t('collegeLoginMsg')}</div>
              </div>
            </div>
          </div>
          <div className="right-side d-flex justify-content-center align-items-center pt-3 col m-0 p-0" style={{ backgroundColor: 'white' }}>
            <div className="d-block text-center">
              <div className="ml-3">
                <img className="mb-4 mx-auto" src={require('assets/images/republic-polytechnic.png')} />
              </div>
              <div className="xpa--login">
                <div className="row" id="xpaLoginTabContent">
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3" id="login" role="tabpanel" aria-labelledby="login-tab">
                    <form className={formClass} onSubmit={this.handleOnSubmit}>
                      <h1 className="h3 mb-3 font-weight-normal text-dark">{t('collgeLogin')}</h1>
                      {
                        ableToLogin == false /*&& !finalCount*/ ? (
                          <InlineNotification lowContrast
                            className="mt-1 mb-1"
                            title=""
                            iconDescription="describes the close button"
                            kind="error"
                            subtitle={`${t('loginAttemptsExceeded')}`}
                            onCloseButtonClick={this.onCloseButtonClick}
                          />
                        ) : null
                      }
                      {error && !otpSent ? (
                        <InlineNotification lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="error"
                          subtitle={
                            <div>
                              {errMsg == 'error2' && (
                                <span>Your email address is currently not registered in the system. Please contact <a href="help-internship@rp.edu.sg">help-internship@rp.edu.sg for more information.</a></span>
                              )}
                              {
                                errMsg == 'error1' && (
                                  <span>We were not able to receive your request. Please check your internet/intranet connection or try again later. If you are still facing issues,Please contact <a href="help-internship@rp.edu.sg">help-internship@rp.edu.sg for more information.</a></span>
                                )
                              }
                              {
                                errMsg == 'error3' && (
                                  <span>Your company email address is still pending for approval. Please contact <a href="help-internship@rp.edu.sg">help-internship@rp.edu.sg for more information.</a></span>
                                )
                              }
                              {
                                errMsg == 'Error Occured' && (
                                  <span>We were not able to receive your request. Please check your internet/intranet connection or try again later. If you are still facing issues,Please contact <a href="help-internship@rp.edu.sg">help-internship@rp.edu.sg for more information.</a></span>
                                )
                              }
                              {
                                errMsg == 'error4' && (
                                  <span>{t('failedOtp')}</span>
                                )
                              }
                            </div>}
                          onCloseButtonClick={this.onCloseButtonClick}
                        />
                      ) : null}
                      {
                        !error && otpSent ? (
                          <InlineNotification lowContrast
                            className="mt-1 mb-1"
                            title=""
                            iconDescription="describes the close button"
                            kind="info"
                            subtitle={t('enterOtp')}
                            onCloseButtonClick={this.onCloseButtonClick}
                          />
                        ) : null
                      }
                      {error && otpSent && !isReSendOtp && loginAttempts > 0 && loginAttemptsArray && Array.isArray(loginAttemptsArray) && loginAttemptsArray.length < 10 ? (
                        <InlineNotification lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="error"
                          subtitle={`${t('loginAttemps')} ${loginAttempts} left. ${t('endMsg')}`}
                          onCloseButtonClick={this.onCloseButtonClick}
                        />
                      ) : null}

                      {error && otpSent && loginAttempts == 0 && finalCount == 10 ?  (
                        <InlineNotification lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="error"
                          subtitle={`${t('loginAttemptsExceeded')}`}
                          onCloseButtonClick={this.onCloseButtonClick}
                        />
                      ) : null}

                      {error && isReSendOtp ? (
                        <InlineNotification lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="info"
                          subtitle={`${t('incorrectOTP')}`}
                          onCloseButtonClick={this.onCloseButtonClick}
                        />
                      ) : null}

                      {error && otpSent && loginAttempts === 0 && finalCount < 10 ? (
                        <InlineNotification lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="error"
                          subtitle={`${t('exceedAttempts')}`}
                          onCloseButtonClick={this.onCloseButtonClick}
                        />
                      ) : null}
                      <div className="bx--form-item">
                        <input
                          type="email"
                          name="username"
                          required
                          disabled={otpSent}
                          placeholder={t('emailUser')}
                          autoComplete="username"
                          className="bx--text-input mb-2"
                          onChange={e => this.handleOnChange('username', e.target.value)}
                          value={this.state.username}
                        />
                      </div>
                      <div className="">
                        <Recaptcha
                          ref={ref => this.recaptcha = ref}
                          sitekey={
                            (window.location.host.includes('internship-placement.rp.edu')
                            || window.location.host.includes('cstage.x0pa.ai'))
                              ? '6LejJJMaAAAAAF6sEbPrPAmvB2WlQRPXA8o8o5BD'
                              : '6Ld6Ko0aAAAAAGusGhB8a6rHibwKJYSDx6n3RlpP'
                          }
                          onResolved={this.onResolved} />
                      </div>
                      {!otpSent ? (
                        <div className="bx--row justify-content-center ml-0 mr-0">
                          <button className="bx--btn bx--btn--primary" type="submit" disabled={ableToLogin == false}>{t('getOtp')}</button>
                        </div>
                      ) : (
                        <Fragment>
                          <div className="bx--form-item">
                            <input
                              type="text"
                              name="otp"
                              required
                              placeholder={t('enterOTP')}
                              autoComplete="otp"
                              className="bx--text-input mb-2"
                              onChange={e => this.handleOnChange('otp', e.target.value)}
                            />
                          </div>
                          {/* <div className="">
                            {this.state.enableCaptcha ? (
                              <ReCAPTCHA
                                ref={this._reCaptchaRef}
                                sitekey={
                                  // '6LdiOXUaAAAAACi_XWRNzJmHW-Nawmx-4Ca1kLDI'
                                  window.location.host.includes('internship-placement.rp.edu')
                                    ? '6Lfqr3oaAAAAAJBXqgkXLhsCQ7bUIVBosqnItldy'
                                    : '6Ld-r3oaAAAAAOLrLewU1UehMZ8h_stP1WehpKBS'
                                }
                                onChange={(value) =>
                                  this.handleOnChange('captcha', value)
                                }
                              />
                            ) : (
                              <span />
                            )}
                          </div>
                          {
                            captchaError && <div className="xpa--captcha-err text-left">*Please tick captcha</div>
                          } */}
                          <div className="bx--row justify-content-center mt-2 ml-0 mr-0">
                            <div>
                              <button className="bx--btn bx--btn--primary" type="submit" disabled={isVerifyDisable}>{t('login')}</button>
                            </div>
                            <div>
                              <button className="bx--btn bx--btn--tertiary ml-2" disabled={isResendDisable} onClick={this.handleResendOtp} type="button">Resend OTP</button>
                            </div>
                          </div>
                        </Fragment>

                      )}
                    </form>
                    {this.state.isLogging && <Loading className="d-inline-block" withOverlay />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  login,
  push,
  fetchUserLoginState,
  getOTP,
  userLoginInfo,
  getUserLoginInfo,
  getIsEligibleToLogin
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CollegeLogin));
