import { withTranslation } from 'react-i18next';
import React from 'react';
// import _ from 'lodash';
// import 'jquery-slimscroll/jquery.slimscroll.min';
import 'react-modal-wrapper/dist/main.css'; // to load default styles
import 'react-input-range/lib/css/index.css';
// import 'styles/jquery.nice-select'; 
import logo from './roboroy-filter.png';

class SidebarContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // const locationArr = this.getDataSortedByTotal('location');
    // const gradesArr = this.getDataSortedByTotal('grades');
    // const designationArr = this.getDataSortedByTotal('designation');
    // const departmentArr = this.getDataSortedByTotal('department');
    //
    // this.state = {
    //   location: props.setSelectedFilter ? props.setSelectedFilter.location : [],
    //   department: props.setSelectedFilter ? props.setSelectedFilter.department : [],
    //   designation: props.setSelectedFilter ? props.setSelectedFilter.designation : [],
    //   grades: props.setSelectedFilter ? props.setSelectedFilter.grades : [],
    //   regions: props.setSelectedFilter ? props.setSelectedFilter.regions : [],
    //   regionsArr: [...new Set(locationArr.map(loc => countries[loc]))],
    //   setSelectedFilter: props.setSelectedFilter,
    //   firstSlider: 0,
    //   secondSlider: 1,
    //   threeSlider: 1,
    //   datajson: props.datajson,
    //   primaryAttributeValue: 0,
    //   value2: {
    //     min: 10,
    //     max: 50,
    //   },
    //
    //   values: [3, 8],
    //   value: 5,
    //   value1: {
    //     min: 10,
    //     max: 50,
    //   },
    //
    //   value3: {
    //     min: 1000,
    //     max: 30000,
    //   },
    //   locationFilterSize: 3,
    //   gradeFilterSize: 3,
    //   departmentFilterSize: 3,
    //   designationFilterSize: 3,
    //   addLocationVisible: true,
    //   searchLocationVisible: false,
    //   addDepartmentVisible: true,
    //   searchDepartmentVisible: false,
    //   addDesignationVisible: true,
    //   searchDesignationVisible: false,
    //   addGradeVisible: true,
    //   searchGradeVisible: false,
    //   locationArr,
    //   gradesArr,
    //   designationArr,
    //   departmentArr,
    // };
    //
    // this.clearallcheckbox = this.clearallcheckbox.bind(this);
    //
    // this.checkLocationCheck = this.checkLocationCheck.bind(this);
    // this.checkGradeCheck = this.checkGradeCheck.bind(this);
    // this.checkDesignationCheck = this.checkDesignationCheck.bind(this);
    // this.checkDepartmentCheck = this.checkDepartmentCheck.bind(this);
  }

  onChange = value =>
    this.setState({
      value,
    });

  getDataSortedByTotal(variable) {
    const vars = this.props.datajson[this.props.fields[variable].index];
    const sortArr = vars[this.props.fields[variable].totalEmployeesName];
    return vars[this.props.fields[variable].typeName]
      .map((loc, i) => [loc, sortArr[i]])
      .sort((a, b) => a[1] < b[1])
      .map(arr => arr[0]);
  }

  _getSelectedFilter(state = this.state) {
    const selectedFilter = {
      location: state.location,
      department: state.department,
      designation: state.designation,
      grades: state.grades,
      regions: state.regions,
    };

    this.props.onFilterSelected(selectedFilter);
  }

  regionChange(event) {
    const regionArr = this.state.regions.slice();
    const value = event.target.value;
    const index = regionArr.indexOf(value);
    if (event.target.checked) {
      if (!(index > -1)) {
        regionArr.push(value);
      }
    } else if (index > -1) {
      regionArr.splice(index, 1);
    }

    this.setState({ regions: regionArr });

    this._getSelectedFilter({ ...this.state, regions: regionArr });
  }

  locationChange(event) {
    const locationArray = this.state.location.slice();
    const value = event.target.value;
    const index = locationArray.indexOf(value);
    if (event.target.checked) {
      if (!(index > -1)) {
        locationArray.push(value);
      }
    } else if (index > -1) {
      locationArray.splice(index, 1);
    }

    this.setState({ location: locationArray });

    this._getSelectedFilter({ ...this.state, location: locationArray });
  }

  departmentChange(event) {
    const departmentArray = this.state.department.slice();
    const value = event.target.value;
    const index = departmentArray.indexOf(value);
    if (event.target.checked) {
      if (!(index > -1)) {
        departmentArray.push(value);
      }
    } else if (index > -1) {
      departmentArray.splice(index, 1);
    }

    this.setState({ department: departmentArray });

    this._getSelectedFilter({ ...this.state, department: departmentArray });
  }

  designationChange(event) {
    const designationArray = this.state.designation.slice();
    const value = event.target.value;
    const index = designationArray.indexOf(value);
    if (event.target.checked) {
      if (!(index > -1)) {
        designationArray.push(value);
      }
    } else if (index > -1) {
      designationArray.splice(index, 1);
    }

    this.setState({ designation: designationArray });

    this._getSelectedFilter({ ...this.state, designation: designationArray });
  }

  gradeChange(event) {
    const gradeArray = this.state.grades.slice();
    const value = event.target.value;
    const index = gradeArray.indexOf(value);
    if (event.target.checked) {
      if (!(index > -1)) {
        gradeArray.push(value);
      }
    } else if (index > -1) {
      gradeArray.splice(index, 1);
    }

    this.setState({ grades: gradeArray });

    this._getSelectedFilter({ ...this.state, grades: gradeArray });
  }

  clearallcheckbox() {
    console.log('check');
    const elem = document.querySelector('input[type="checkbox"]:checked');
    elem.checked = false;
    this.setState(
      {
        location: [],
        department: [],
        designation: [],
        grades: [],
      },
      () => this._getSelectedFilter(),
    );
  }
  componentDidMount() {
    document.querySelector('select').niceSelect();
    const nav = this.nav;
    const $nav = document.querySelector(nav);

    // scroll
    // $nav.slimscroll({
    //   height: '100%',
    // });
    // document.querySelector('.flip').click(() => {
    //   $('.panel').slideToggle('slow');
    // });
    // $('#accordion')
    //   .find('.accordion-toggle')
    //   .click(function () {
    //     // Expand or collapse this panel
    //     $(this)
    //       .next()
    //       .slideToggle('slow');

    //     // changes arrow
    //     if (
    //       $(this)
    //         .find('.arrow')
    //         .hasClass('arrowUp')
    //     ) {
    //       $(this)
    //         .find('.arrow')
    //         .removeClass('arrowUp')
    //         .addClass('arrowDown');
    //     } else {
    //       $('#accordion')
    //         .find('.arrow')
    //         .removeClass('arrowUp')
    //         .addClass('arrowDown');
    //       $(this)
    //         .find('.arrow')
    //         .removeClass('arrowDown')
    //         .addClass('arrowUp');
    //     }
    //     // Hide the other panels
    //     $('.accordion-content')
    //       .not($(this).next())
    //       .slideUp('slow');
    //   });

    if (this.state.setSelectedFilter) {
      this._getSelectedFilter();
    }
  }

  checkLocationCheck(loc) {
    let locations = [],
      foundflag = false;

    if (this.state.setSelectedFilter) {
      if (this.state.setSelectedFilter.location.length > 0) {
        console.log('if');
        locations = this.props.setSelectedFilter.location;
        locations.forEach((location) => {
          if (location == loc) {
            foundflag = true;
            // this._getSelectedFilter();
          }
        });

        return foundflag;
      }
      return false;
    }
    return false;
  }

  checkDepartmentCheck(dpt) {
    let departments = [],
      foundflag = false;

    if (this.state.setSelectedFilter) {
      if (this.state.setSelectedFilter.department.length > 0) {
        departments = this.state.setSelectedFilter.department;
        departments.forEach((department) => {
          if (department == dpt) {
            foundflag = true;
            // this._getSelectedFilter();
          }
          console.log(foundflag);
          return foundflag;
        });
      } else {
        console.log('first ese ');
        return false;
      }
    } else {
      return false;
    }
  }

  checkDesignationCheck(dsg) {
    let designations = [],
      foundflag = false;

    if (this.state.setSelectedFilter) {
      if (this.state.setSelectedFilter.designation.length > 0) {
        designations = this.state.setSelectedFilter.designation;
        designations.forEach((designation) => {
          if (designation == dsg) {
            foundflag = true;
            // this._getSelectedFilter();
          }

          return foundflag;
        });
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkGradeCheck(grd) {
    let grades = [],
      foundflag = false;

    if (this.state.setSelectedFilter) {
      if (this.state.setSelectedFilter.grades.length > 0) {
        grades = this.state.setSelectedFilter.grades;
        grades.forEach((grade) => {
          if (grades == grd) {
            foundflag = true;
            // this._getSelectedFilter();
          }

          return foundflag;
        });
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  render() {
    const listItems = ['Item 1', 'Item 2'].map(loc => (
      <li key={loc}>
        <input
          type="checkbox"
          className="option-check"
          defaultChecked={!!this.checkLocationCheck(loc)}
          onChange={this.locationChange.bind(this)}
          value={loc}
        />
        <h6 className="option-head">{loc}</h6>
      </li>
    ));

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100vh - 146px)',
        }}
      >
        <div>
          <div id="sidebar">
            <button onClick={this.clearallcheckbox} className="clear-btn">
              clear all
            </button>
            <h1 className="flip">FILTER BY</h1>
            <div className="filter-panel">
              <div id="accordion">
                <div className="section-wrapper">
                  <h2 className="accordion-toggle cata-head">
                    X0PA Filter<span className="arrow arrowDown" />
                  </h2>
                  <div className="accordion-content">
                    <ul className="dropdown">{listItems}</ul>
                  </div>
                </div>
                <div className="section-wrapper">
                  <h2 className="accordion-toggle cata-head">
                    Fit-to-role<span className="arrow arrowDown" />
                  </h2>
                  <div className="accordion-content">
                    <ul className="dropdown">{listItems}</ul>
                  </div>
                </div>
                <div className="section-wrapper">
                  <h2 className="accordion-toggle cata-head">
                    Location<span className="arrow arrowDown" />
                  </h2>
                  <div className="accordion-content">
                    <ul className="dropdown">{listItems}</ul>
                  </div>
                </div>
                <div className="section-wrapper">
                  <h2 className="accordion-toggle cata-head">
                    Salary Expectation<span className="arrow arrowDown" />
                  </h2>
                  <div className="accordion-content">
                    <ul className="dropdown">{listItems}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <div>
          <img src={logo} alt="Roboroy" style={{ marginBottom: '20px' }} />
        </div>
      </div>
    );
  }
}

export default SidebarContent;
