import {
  SUCCESS_GETALL_DOC_TYPES,
  SUCCESS_GET_PROFILE_DOCS,
  SUCCESS_ADD_PROFILE_DOC,
  SUCCESS_DELETE_PROFILE_DOC,
} from '../actions/actionTypes';

const initState = {
  docs: [],
  profileDocs: [],
};

const UploadDocuments = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SUCCESS_GETALL_DOC_TYPES: {
      return {
        ...state,
        docs: payload,
      };
    }
    case SUCCESS_GET_PROFILE_DOCS: {
      return {
        ...state,
        profileDocs: payload,
      };
    }
    case SUCCESS_ADD_PROFILE_DOC: {
      return {
        ...state,
      };
    }
    case SUCCESS_DELETE_PROFILE_DOC: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default UploadDocuments;
