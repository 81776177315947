import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput, Form, Button, InlineNotification, Modal, Loading, Tile,
} from 'carbon-components-react';
import {
  employeeEmailConfirmation,
  getEmailConfirmationInfo
} from 'actions/UserEmailConfirmation';

import zxcvbn from 'zxcvbn';

import { SignedInErrorView, SignedInSuccessView, InviteErrorView } from 'components/common/ErrorViews';

class UserEmailConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uLoading: true,
    };
  }

  componentDidMount() {
    const {
      user: { userId, email, roleId, isSuperAdmin, isAdmin, company: { ctypeId } = {} } = {},
    } = this.props;

    let data = {
      userId: userId
    }

    this.props.getEmailConfirmationInfo(data).then((res) => {
      if(res && !res.error){
        if(res && Array.isArray(res) && res.length > 0){
          this.setState({
            responseAlreadyTaken: true,
            isButtonDisable: true 
          })
        }
      }
    })
  }

  handleEmailConfirmation = () => {
    const {
      user: { userId, email, roleId, isSuperAdmin, isAdmin, company: { ctypeId } = {} } = {},
    } = this.props;

    const data = {
      email: email,
      userId: userId,
      acknowledged: true
    };

    this.props.employeeEmailConfirmation(data).then(res => {
      if (res && !res.error) {
        this.setState({
          responseTaken: true,
          isButtonDisable: true 
        })
      }
    });
  };

  render() {
    const { isUserLoggedIn } = this.props;
    return (
      <div>
        {
          isUserLoggedIn && (
            <Tile className="xp-m__session--container mt-1">
              <Tile className="mb-2 mt-5" style={{'padding': '80px'}}>
                <div className="empty text-center">
                  <p className="empty-title h5">Please Click Acknowledge</p>
                  <div className="empty-icon">
                    <i className="fa fa-flag fa-2x" />
                  </div>
                  <div>
                    Your email will be acknowledged by the platform.
                      <div className="bx--col text-center mb-3">
                        <Button type="submit" className="rf-btn" kind="primary" disabled={this.state.isButtonDisable}
                          onClick={this.handleEmailConfirmation}>
                            <span className="ml-5"> Acknowledge </span>
                        </Button>
                      </div> 
                      <div className="bx--col text-center mb-3">  
                        {
                          this.state.responseTaken === true ? (
                            <h4>Thank You! We Received Your Acknowledgement.</h4>
                          ) : ''
                        }
                      </div>  
                      <div className="bx--col text-center mb-3">  
                        {
                          this.state.responseAlreadyTaken === true ? (
                            <h4>Thank You! We Have Already Received Your Acknowledgement.</h4>
                          ) : ''
                        }
                      </div>  
                  </div>
                </div>
              </Tile>
            </Tile>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  employeeEmailConfirmation,
  getEmailConfirmationInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEmailConfirmation);
