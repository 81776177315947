import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import { Button, Pagination, Modal } from 'carbon-components-react';
import { getUsersUsageReport } from 'actions/talentSearch/userusagereport';

class UserRegistrationRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModal: false,
    };
  }

  componentDidMount() {
    this.props.getUsersUsageReport({ skip: 0, limit: 50 });
  }

  handlePaginationChange = (data) => {
    const page = (data.page) || 1;
    const skip = ((page - 1) * 50);
    this.props.getUsersUsageReport({ skip, limit: 50 });
  }

  activateModal = () => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  render() {
    const { userdata = [] } = this.props.userdata;
    const { activeModal } = this.state;
    const columnWithFilter = [
      {
        title: 'User ID',
        className: 'text-left',
        name: 'userId',
        propName: 'userdata[0].userId',
      },
      {
        title: 'Name',
        className: 'text-left',
        name: 'userName',
        propName: 'userdata[0].firstname userdata[0].lastname',
      },
      {
        title: 'Email',
        className: 'text-center',
        name: 'emailId',
        propName: 'userdata[0].email',
      },
      {
        title: 'Company Name',
        className: 'text-center ',
        name: 'jobsCount',
        propName: 'userdata[0].jobsCount',
      },
      {
        title: 'Status',
        className: 'text-center ',
        name: 'applicationsCount',
        propName: 'userdata[0].applicationsCount',
      },
      {
        title: 'Actions',
        className: 'text-center ',
        name: 'actions',
        propName: 'userdata[0].subscriptions',
      },
    ];

    return (
      <Fragment>
        <h4 className="bx--type-gamma font-weight-bold text-capitalize">User Registration</h4>
        <GenericDataTable
          title=""
          customSorting={() => { }}
          rowClass="p-0"
          headers={
            columnWithFilter.map((data, index) => (
              {
                key: `header_${index}`,
                header: data.title,
                className: data.className,
                colpropName: data.propName,
              }
            ))
          }
          rows={
            userdata && userdata.map ? userdata.map(user => ({
              isExpanded: false,
              id: `row_${user.userId}`,
              header_0: user.userId,
              header_1: (
                `${user.firstname} ${user.lastname}` || '-'
              ),
              header_2: user.email,
              header_3: (user.company && user.company.companyName) || '-',
              header_4: (user.active ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>),
              header_5: (
                <div>
                  <Button href={`${user.userId}`} kind="primary" className="mr-2" small>
                  View
                  </Button>
                </div>
              ),
            })) : []
          }
          selectable={false}
          searchable={false}
          onSearchInputChange={() => { }}
          expandable={false}
        />
        {/* batchActions={[{ key: 'Edit', label: 'Delete' }]} */}
        <div className="">
          <Pagination
            page={1}
            onChange={this.handlePaginationChange}
            pageSizes={[50]}
            totalItems={1000}
          />
        </div>
        <Modal
          open={activeModal}
          modalHeading="Add user"
          primaryButtonText="Add user"
          secondaryButtonText="Cancel"
          onRequestClose={this.dismissModal}
          onRequestSubmit={() => {}}
          onSecondarySubmit={() => {}}
        >
          sfdf
        </Modal>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.usageReport.loading,
  userdata: state.usageReport,
});

const mapDispatchToProps = {
  getUsersUsageReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistrationRequests);
