import React, { Fragment } from 'react';
import {
  SkeletonText, ButtonSkeleton, TagSkeleton,
  ToggleSkeleton,
} from 'carbon-components-react';

const JobInfoSkeleton = ({ isAuthenticated, roleId }) => {
  let accessLevel = 0;
  if ((roleId == 4) || (roleId == 1)) {
    accessLevel = 5;
  } else if ((roleId !== 3)) {
    accessLevel = 1;
  } else if (roleId == 3) {
    accessLevel = 0;
  }
  return (
    <Fragment>
      <div className="xp-m__jobinfo--hero">
        <div className="">
          <div className="row pb-2">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="">
                <SkeletonText style={{ maxWidth: 120 }} />
                <SkeletonText style={{ maxWidth: 320 }} />
                <div className="card-subtitle pb-1">
                  <SkeletonText style={{ maxWidth: 150 }} />
                </div>
                <div className="card-subtitle pt-1 d-flex">
                  <TagSkeleton className="mr-2" style={{ width: 80 }} />
                  <TagSkeleton className="mr-2" style={{ width: 80 }} />
                  <TagSkeleton className="mr-2" style={{ width: 80 }} />
                </div>
              </div>
            </div>
            {
              isAuthenticated && (accessLevel > 0) && (
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="job-applicant-info">
                    <div className="row pb-2">
                      <div className="text-center col">
                        <h3 className="xp-c-text-align__center ">
                          <SkeletonText className="xpa-circle--skeleton" />
                        </h3>
                        <span className="bx--modal-header__label mt-2">Applications</span>
                      </div>
                      <div className="text-center col">
                        <h3 className="xp-c-text-align__center ">
                          <SkeletonText className="xpa-circle--skeleton" />
                        </h3>
                        <span className="bx--modal-header__label mt-2">Shortlisted</span>
                      </div>
                      <div className="text-center col">
                        <h3 className="xp-c-text-align__center ">
                          <SkeletonText className="xpa-circle--skeleton" />
                        </h3>
                        <span className="bx--modal-header__label mt-2">Rejected</span>
                      </div>
                      <div className="text-center col">
                        <h3 className="xp-c-text-align__center ">
                          <SkeletonText className="xpa-circle--skeleton" />
                        </h3>
                        <span className="bx--modal-header__label mt-2">Hired</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          {
            isAuthenticated && (accessLevel > 0) && (
              <div className="xp-m__jobinfo--hero-actions">
                <div className="bx--row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="mr-2"><ButtonSkeleton small /></div>
                </div>
              </div>
            )
          }
        </div>
      </div>
      {
        isAuthenticated && (accessLevel > 0) && (
          <Fragment>
            <div className="mt-2 tile-min-height xp-m__jobinfo--private-toggle">
              <div className="bx--row align-items-center">
                <div className="bx--col-xs-8">
                  <SkeletonText style={{ maxWidth: 200 }} />
                  <div>
                    <small className="text-secondary">
                      <SkeletonText />
                    </small>
                  </div>
                </div>
                {/* <div className="bx--col-xs-4 text-right">
                  <div className="xpa-toggle">
                    <ToggleSkeleton />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="mt-2 tile-min-height xp-m__jobinfo--private-toggle">
              <SkeletonText />
              <SkeletonText />
              <SkeletonText />
            </div>
            <div className="mt-2 tile-min-height xp-m__jobinfo--private-toggle">
              <div className="bx--row align-items-center">
                <div className="bx--col-xs-8">
                  <SkeletonText style={{ maxWidth: 200 }} />
                  <div>
                    <small className="text-secondary">
                      <SkeletonText />
                    </small>
                  </div>
                </div>
                <div className="bx--col-xs-4 text-right">
                  <div className="xpa-toggle">
                    <ButtonSkeleton small />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )
      }
    </Fragment>
  );
};

export default JobInfoSkeleton;
