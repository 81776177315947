import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Button, InlineNotification} from 'carbon-components-react';
import moment from 'moment';
import WorkflowStatusUpdateModal from 'components/roboroy/WorkflowModule/workflowStatusUpdate';
import OfferInformation from 'containers/roboroy/OfferManagement/OfferInformation';
import WorkflowAction from 'components/roboroy/WorkflowModule/workflowAction';
import { getAssetAnalytics, getJobAppliedCount } from 'actions/talentSearch/candidatesList';
import { ActionContenrForAnalyticsTranlation } from 'containers/roboroy/AssetAnalytics';
import { sendEmails } from 'actions/talentSearch/talentPool';
import {getOfferDetails} from 'actions/offerInformation';

import './InterviewStatus.css';

class InterviewStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContnet: [],
      showofferConsent: false,
    };
  }

  componentDidMount() {
    const { application } = this.props;
    const { appId, profileId } = application || {};
    this.props.getAssetAnalytics(appId);
    this.props.getJobAppliedCount(profileId);
    this.props.getOfferDetails(appId)
  }

  getImageType = (title) => {
    const {
      application: {
        shortlisted,
        invitesent,
        inviteaccept,
        offerConsentSent,
        offerConsentAccept,
        isHired,
        workflow: { outcomeId = null } = {},
      } = {},
      t,
    } = this.props;
    const retVal = {
      title: '',
      img: undefined,
    };
    const acceptedStates = [6000, 6002, 6004, 6005];
    //const rejectedStates = [6001, 6003, ];
    switch (title) {
      case 'Screening':
        retVal.title = `${t('screening')}`;
        retVal.img = shortlisted !== null ? 'successImg' : 'waitingImg';
        break;
      case 'Shortlisted':
        if (shortlisted !== null) retVal.img = shortlisted === true ? 'successImg' : 'rejectImg';
        if (retVal.img === 'successImg') retVal.title = `${t('shortlisted')}`;
        if (retVal.img === 'rejectImg') retVal.title = `${t('rejected')}`;
        break;
      case 'Get Candidate Consent':
        if (shortlisted === true && invitesent === null) retVal.img = 'waitingImg';
        if (shortlisted === true && invitesent === true) retVal.img = 'successImg';
        retVal.title = `${t('get')} ${t('candidate')} ${t('consent')}`;
        break;
      case 'Awaiting email acceptance':
        if (shortlisted === true && invitesent === true && inviteaccept === null) retVal.img = 'waitingImg';
        else if (shortlisted === true && invitesent === true) retVal.img = inviteaccept === true ? 'successImg' : 'rejectImg';
        retVal.title = `${t('awaitingEmailAcceptance')}`;
        break;

      case 'Interview workflow':
        if (shortlisted === true && invitesent === true
          && inviteaccept === true && outcomeId === null && isHired === null) retVal.img = 'waitingImg';
        if (shortlisted === true
          && invitesent === true && inviteaccept === true && outcomeId !== null) retVal.img = 'successImg';
        retVal.title = `${t('interview')} ${t('workflow')}`;
        break;

      case 'Interview Decision':
        if (shortlisted === true && invitesent === true && inviteaccept === true
          && outcomeId !== null && acceptedStates.indexOf(Number(outcomeId)) > -1) retVal.img = 'successImg';
        if (shortlisted === true && invitesent === true && inviteaccept === true
          && outcomeId !== null && acceptedStates.indexOf(Number(outcomeId)) === -1) retVal.img = 'rejectImg';
        retVal.title = `${t('interview')} ${t('decision')}`;
        break;

      case 'Offer Consent':
        if (shortlisted === true && invitesent === true && inviteaccept === true
          && acceptedStates.indexOf(Number(outcomeId)) > -1 && offerConsentSent === null) retVal.img = 'waitingImg';
        if (shortlisted === true && invitesent === true && inviteaccept === true
          && acceptedStates.indexOf(Number(outcomeId)) > -1 && offerConsentSent === true) retVal.img = 'successImg';
        retVal.title = `${t('offer')} ${t('consent')}`;
        break;

      case 'Awaiting Offer Consent':
        if (shortlisted === true && invitesent === true && inviteaccept === true
          && acceptedStates.indexOf(Number(outcomeId)) > -1 && offerConsentSent === true && offerConsentAccept === null) retVal.img = 'waitingImg';
        if (retVal.img === 'waitingImg') retVal.title = `${t('awaiting')} ${t('consent')}`;
        else if (shortlisted === true && invitesent === true && inviteaccept === true
          && acceptedStates.indexOf(Number(outcomeId)) > -1 && offerConsentSent === true) retVal.img = offerConsentAccept === true ? 'successImg' : 'rejectImg';
        if (retVal.img === 'successImg') retVal.title = `${t('offer')} ${t('consent')}`;
        if (retVal.img === 'rejectImg') retVal.title = `${t('offer')} ${t('consent')} ${t('rejected')}`;
        break;
      case 'Offer flow':
        if (shortlisted === true && invitesent === true && inviteaccept === true && acceptedStates.indexOf(Number(outcomeId)) > -1
          && offerConsentSent === true && offerConsentAccept === true && ((isHired!==null && isHired===false) || isHired === null) ) {
            let isWaitingImage=false;
            const {offerDetails}=this.props;
            for(let offer of offerDetails){
              if(offer.status==="inprogress"){
                isWaitingImage=true;
                break;
              }
            }
            if(isWaitingImage===true)
              retVal.img = 'waitingImg';
            else  
              retVal.img="rejectImg";
            
          }
        if (shortlisted === true && invitesent === true && inviteaccept === true && acceptedStates.indexOf(Number(outcomeId)) > -1
          && offerConsentSent === true && offerConsentAccept === true && isHired !== null && isHired===true){
            retVal.img = 'successImg';
          }
        retVal.title = `${t('offer')} ${t('flow')}`;
        break;
      case 'Hiring Decision':
        if (shortlisted === true && invitesent === true && inviteaccept === true && acceptedStates.indexOf(Number(outcomeId)) > -1
          && offerConsentSent === true && offerConsentAccept === true && isHired !== null) retVal.img = isHired === true ? 'successImg' : 'rejectImg';
        retVal.title = `${t('hiringDecision')}`;
        break;
      default: break;
    }
    return retVal;
  }

  getTitle = (title) => {
    const result = this.getImageType(title);
    return (
      <div className={`bx--row align-items-center${result.img === undefined ? ' text-dark' : ''}${(title === 'Interview workflow' || title === 'Offer flow') && result.img !== undefined ? ' bx--col-md' : ''}`}>
        {this.getImage(result.img)}
        <div className="ml-1 bx--type-epsilon">
          {result.title !== '' ? result.title : title}
        </div>
      </div>
    );
  }

  getImage = (image) => {
    switch (image) {
      // springgreen
      case 'successImg': return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle">
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
          <polyline points="22 4 12 14.01 9 11.01" />
        </svg>
      );
      // #ED8A19
      case 'waitingImg': return (
        <svg className="feather feather-clock sc-iwsKbI cnlcoQ" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" data-reactid="321">
          <circle cx="12" cy="12" r="10" />
          <polyline points="12 6 12 12 16 14" />
        </svg>
      );
      // #F05228
      case 'rejectImg': return (
        <svg className="feather feather-x-circle sc-iwsKbI cnlcoQ" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" data-reactid="1371">
          <circle cx="12" cy="12" r="10" />
          <line x1="15" y1="9" x2="9" y2="15" />
          <line x1="9" y1="9" x2="15" y2="15" />
        </svg>
      );
      // gray
      default: return (
        <svg className="feather feather-minus-circle sc-iwsKbI cnlcoQ" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" data-reactid="811">
          <circle cx="12" cy="12" r="10" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
      );
    }
  }

  getArrow = (type) => {
    switch (type) {
      case 0: return (
        <svg className="feather feather-chevron-down sc-dnqmqq jxshSx" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true">
          <polyline points="6 9 12 15 18 9" />
        </svg>
      );
      case 1: return (
        <svg className="feather feather-chevron-up sc-dnqmqq jxshSx" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true">
          <polyline points="18 15 12 9 6 15" />
        </svg>
      );
      default: break;
    }
  }

  accordionAction = (name) => {
    let { showContnet } = this.state;
    const pos = showContnet.includes(name);
    if (pos) showContnet = showContnet.slice(pos, 1);
    else showContnet.push(name);
    this.setState({ showContnet });
  }

  offerConsent = () => {
    this.setState({ showofferConsent: !this.state.showofferConsent });
  }

  getStatus = (action) => {
    let actionType = action;
    const {
      timeLine = [],
      application: {
        shortlisted,
      } = {},
    } = this.props;

    let data = timeLine !== null && Array.isArray(timeLine)
      ? timeLine.filter(one => one.actionType === actionType)[0] || {} : {};

    if (Object.keys(data).length === 0 && (actionType === 'candidate-accepts-consent'
      || actionType === 'is-hired' || actionType === 'shortlist-email-sent'
      || actionType === 'offer-accepted' || actionType === 'recruiter-rejects-interview')) {
      if (actionType === 'candidate-accepts-consent') actionType = 'candidate-rejects-consent';
      else if (actionType === 'is-hired') actionType = 'is-not-hired';
      else if (actionType === 'offer-accepted') actionType = 'offer-rejected';
      else if (actionType === 'shortlist-email-sent') actionType = 'shortlisted';
      else if (actionType === 'recruiter-rejects-interview') actionType = 'completed';
      data = timeLine !== null && Array.isArray(timeLine) ? timeLine.filter(one => one.actionType === actionType)[0] || {} : {};
    }
    if (actionType === 'shortlisted' && shortlisted === false) {
      actionType = 'rejected';
      data = timeLine !== null && Array.isArray(timeLine) ? timeLine.filter(one => one.actionType === actionType)[0] || {} : {};
    }

    const {
      applicationsource: { sourceName = '' } = {},
      actionDate = '',
      workflowId,
      assetType,
    } = data || {};

    let stage = '';
    let sstage = '';

    if (workflowId && workflowId !== null) {
      const {
        workflowstage: { stageName = '' } = {},
        subStageMeta: { substage = '' } = {},
      } = data || {};
      stage = stageName;
      sstage = substage;
    }

    const recruiterName = `${data && data.performedBy && data.performedBy.firstname} ${data && data.performedBy && data.performedBy.lastname}`;
    const assignedName = `${data && data.assignedTo && data.assignedTo.firstname} ${data && data.assignedTo && data.assignedTo.lastname}`;

    const params = {
      actionType,
      sourceName,
      assetType,
      stageName: stage,
      substage: sstage,
      assignedName,
    };

    if (Object.keys(data).length !== 0) {
      return (
        <div className="bx--col-12 mt-1">
          <div>
            {actionType !== 'screening' && <span className="text-capitalize"><b>{recruiterName}&nbsp;</b></span>}
            <ActionContenrForAnalyticsTranlation
              actionType={params.actionType}
              sourceName={params.sourceName}
              assetType={params.assetType}
              stageName={params.stageName}
              substage={params.substage}
              assignedName={params.assignedName}
            />
          </div>
          <div> { actionDate ? moment(actionDate).fromNow() : null } </div>
        </div>
      );
    }
  }

  showInterviewWorkflow = (interviewflow, jobInfo, offerAcceptedCount) => (
    <div className="mt-4">
      <WorkflowStatusUpdateModal
        handleUserSubStageCompletion={interviewflow.handleUserSubStageCompletion}
        showInModal={interviewflow.showInModal}
        application={interviewflow.application}
        appId={interviewflow.appId}
        jobId={interviewflow.jobId}
        updateParentPage={interviewflow.updateParentPage}
        handleOpenEmailModal={interviewflow.handleOpenEmailModal}
        handleApplicantTabClick={interviewflow.handleApplicantTabClick}
        getOfferDetails={interviewflow.getOfferDetails}
        value={this.props.value}
        offerAcceptedCount={offerAcceptedCount}
        jobInfo={jobInfo}
      />
    </div>
  );

  handleSendRejectEmail = () => {
    const { application } = this.props;
    const { appId, profileId, profile } = application || {};
    const { email } = profile || {};
    const data = {
      templateId: 6,
      templateName: 'company-ihl-rejects',
      emailData: [{
        email: email || 'dummy',
        profileId: profileId || 0,
        appId: appId || 0,
      }],
      actionName: 'send-email',
    };
    if (this.state.sendingRejectEmail) { return; }
    // user-defined-email
    this.setState({
      sendingRejectEmail: true,
      notifShow: false,
      notifMsg: '',
    }, () => {
      this.props.sendEmails(data)
        .then((res) => {
          const { error, MessageId } = res || {};
          if (error) {
            throw new Error('Error occured.');
          }
          if (MessageId) {
            this.setState({
              notifShow: true,
              notifKind: 'success',
              notifMsg: 'Email sent successfully',
              sendingRejectEmail: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            notifShow: true,
            notifKind: 'error',
            notifMsg: 'Error occured while sending email',
            sendingRejectEmail: false,
          });
        });
    });
  }

  renderRejectEmail = (stage) => {
    const i = this.getImageType(stage);
    const { img } = i || {};
    if (img !== 'rejectImg') {
      return null;
    }
    return (
      <div className="mt-2">
        {this.state.notifShow && (
          <InlineNotification
            lowContrast
            kind={this.state.notifKind}
            title={this.state.notifMsg}
            subtitle=""
            onCloseButtonClick={() =>
              this.setState({ notifShow: false })
            }
          />
        )}
        {/*<Button disabled={this.state.sendingRejectEmail} size="small" kind="danger" onClick={this.handleSendRejectEmail} className="mt-2">
          { this.state.sendingRejectEmail ? "Sending Email..." : "Send Reject Email" }
        </Button>*/}
      </div>
    );
  }

  render() {
    const {
      interviewflow, application, push,
      handleCandidateShortlist,
      jobInfo,
    } = this.props;
    const {
      application: {
        inviteaccept,
        offerConsentAccept,
      } = {},
      offerAcceptedCount,
      user = {},
      t,
    } = this.props;
    const { showContnet = [], showofferConsent = false } = this.state;
    return (
      <div className="p-md-3 InterviewStatus">
        <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Screening').img}`}>
          <div className="bx--row m-0 align-items-center mt-1 justify-content-between pl-3">
            <div className="mr-2">
              {this.getTitle('Screening')}
              {this.getImageType('Screening').img && this.getStatus('screening')}
            </div>
            {this.getImageType('Screening').img === 'waitingImg' && (
              <Fragment>
                {/* <p className="bx--col-md-8 m-0 ml-md-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex font-weight-light" style={{ flex: 1 }}>Select if this candidate is shortlisted or rejected to proceed with further process.</p> */}
                {
                    user && user.roleId
                      && user.roleId !== 3
                      && (
                        <div className="bx--row">
                          <div className="text-center"><Button kind="primary" small className="ml-2" onClick={() => handleCandidateShortlist(interviewflow.appId, true)}>Shortlist</Button></div>
                          <div className="text-center"><Button kind="danger--primary" small className="ml-2" onClick={() => handleCandidateShortlist(interviewflow.appId, false)}>Reject</Button></div>
                        </div>
                      )
                  }
              </Fragment>
            )}
          </div>
        </div>
        <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Shortlisted').img}`}>
          <div className="bx--row m-0 align-items-center mt-1 justify-content-between pl-3">
            <div className="mr-2">
              <div className="bx--row m-0">
                {this.getTitle('Shortlisted')}
                {this.getImageType('Shortlisted').title === 'Shortlisted' && <p className="bx--col-md-8 m-0 ml-md-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex font-weight-light" style={{ flex: 1 }}>This Candidate has been shortlisted.</p>}
                {this.getImageType('Shortlisted').title === 'Rejected' && <p className="bx--col-md-8 m-0 ml-md-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex font-weight-light" style={{ flex: 1 }}>This Candidate has been Rejected.</p>}
              </div>
              {this.getImageType('Shortlisted').img && this.getImageType('Shortlisted').img !== 'waitingImg' && this.getStatus('shortlisted')}
            </div>
            {this.getImageType('Shortlisted').title === 'Rejected' && <WorkflowAction application={application} user={user} handleOpenEmailModal={interviewflow && interviewflow.handleOpenEmailModal} />}
          </div>
        </div>
        {/*<div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Get Candidate Consent').img}`}>
          <div className="bx--row m-0 align-items-center mt-1 justify-content-between pl-3">
            <div className="mr-2 mb-md-0 mb-2">
              {this.getTitle('Get Candidate Consent')}
              {this.getImageType('Get Candidate Consent').img && this.getImageType('Get Candidate Consent').img !== 'waitingImg' && this.getStatus('shortlist-email-sent')}
            </div>
            {this.getImageType('Get Candidate Consent').img === 'waitingImg' && (
              <Fragment>
                <p className="bx--col-md-8 m-0 ml-md-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex font-weight-light" style={{ flex: 1 }}>Send a mail to the shortlisted candidate for interview.</p>
                <WorkflowAction application={application} user={user} handleOpenEmailModal={interviewflow && interviewflow.handleOpenEmailModal} />
              </Fragment>
            )}
          </div>
        </div>
        <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Awaiting email acceptance').img}`}>
          <div className="bx--row m-0 align-items-center justify-content-between pl-3">
            <div className="mr-2 mb-md-0 mb-2">
              {this.getTitle('Awaiting email acceptance')}
              {this.getImageType('Awaiting email acceptance').img && this.getImageType('Awaiting email acceptance').img !== 'waitingImg' && this.getStatus('emailacceptance')}
            </div>
            {this.getImageType('Awaiting email acceptance').img === 'waitingImg' && (
              <Fragment>
                <p className="bx--col-md-8 m-0 ml-md-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex font-weight-light" style={{ flex: 1 }}>Resend mail to the shortlisted candidate for interview.</p>
                <WorkflowAction application={application} user={user} handleOpenEmailModal={interviewflow.handleOpenEmailModal} />
              </Fragment>
            )}
          </div>
        </div>*/}
        <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Interview workflow').img}${inviteaccept === true ? ' pl-3' : ''}`}>
          {inviteaccept === true && (
            <div className="m-0 pl-0 ml-3 mt-1">
              <div className="our-accordion pl-3">
                <a role="accordion" className="bx--row" onClick={() => this.accordionAction('interviewworkflow')}>
                  {this.getTitle('Interview workflow')}
                  <div className="bx--row d-flex">{this.getArrow(showContnet.includes('interviewworkflow') ? 1 : 0)}</div>
                </a>
                { this.getImageType('Interview workflow').img === 'successImg' && showContnet.includes('interviewworkflow') ? this.showInterviewWorkflow(interviewflow, jobInfo, offerAcceptedCount) : ''}
                { this.getImageType('Interview workflow').img === 'waitingImg' && !showContnet.includes('interviewworkflow') ? this.showInterviewWorkflow(interviewflow, jobInfo, offerAcceptedCount) : ''}
              </div>
            </div>
          )}
          {inviteaccept === null && (
            <div className="bx--row m-0 align-items-center mt-1 justify-content-between pl-3">
              <div className="mr-2">{this.getTitle('Interview workflow')}</div>
            </div>
          )}
        </div>
        <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Interview Decision').img}`}>
          <div className="bx--row m-0 align-items-center justify-content-between pl-3">
            <div className="mr-2 mb-md-0 mb-2">
              <div className="bx--row m-0">
                {this.getTitle('Interview Decision')}
                <div className="">
                  {
                    this.getImageType('Interview Decision').img === 'successImg'
                      && <p className="bx--col-md-8 bx--type-epsilon mt-3 text-success" style={{ flex: 1 }}><b className="mr-1 text-black">:</b>ACCEPTED</p>
                  }
                  {
                    this.getImageType('Interview Decision').img === 'rejectImg'
                    && <p className="bx--col-md-8 bx--type-epsilon mt-3 text-danger" style={{ flex: 1 }}><b className="mr-1 text-black">:</b>REJECTED</p>
                  }
                </div>
              </div>
              {this.getImageType('Interview Decision').img && this.getImageType('Interview Decision').img !== 'waitingImg'
                && this.getImageType('Interview Decision').img !== 'successImg'&& (
                <div className="align-items-center d-flex justify-content-md-end mb-2 mb-md-0" style={{ flex: 1 }}>
                  <WorkflowAction application={application} user={user} handleOpenEmailModal={interviewflow.handleOpenEmailModal} />
                </div>
              )}
              {this.getImageType('Interview Decision').img && this.getImageType('Interview Decision').img !== 'waitingImg' && this.getStatus('recruiter-rejects-interview')} {/* 'completed')} */}
            </div>
          </div>
          <div>
            {this.renderRejectEmail('Interview Decision')}
          </div>
        </div>
        {/* <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Offer Consent').img}`}>
          <div className="bx--row m-0 align-items-center mt-1 justify-content-between pl-3">
            <div className="mr-2 mb-md-0 mb-2">
              {this.getTitle('Offer Consent')}
              {this.getImageType('Offer Consent').img && this.getImageType('Offer Consent').img !== 'waitingImg' && this.getStatus('consent-email-sent')}
            </div>
            {this.getImageType('Offer Consent').img === 'waitingImg' && (
              <Fragment>
                <p className="bx--col-md-8 m-0 ml-md-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex font-weight-light" style={{ flex: 1 }}>Send offer consent to the candidate</p>
                <WorkflowAction application={application} user={user} handleOpenEmailModal={interviewflow.handleOpenEmailModal} />
              </Fragment>
            )}
          </div>
        </div>
        <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Awaiting Offer Consent').img}`}>
          <div className="bx--row m-0 align-items-center mt-1 justify-content-between pl-3">
            <div className="mr-2 mb-md-0 mb-2">
              {this.getTitle('Awaiting Offer Consent')}
              {this.getImageType('Awaiting Offer Consent').img && this.getImageType('Awaiting Offer Consent').img !== 'waitingImg' && this.getStatus('candidate-accepts-consent')}
            </div>
            {this.getImageType('Awaiting Offer Consent').img === 'waitingImg' && (
              <Fragment>
                <p className="bx--col-md-8 m-0 ml-md-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex font-weight-light" style={{ flex: 1 }}>Resend offer consent to the candidate.</p>
                <WorkflowStatusUpdateModal
                  dismiss={this.offerConsent}
                  show={showofferConsent}
                  showInModal
                  jobId={interviewflow.jobId}
                  application={interviewflow.application}
                  updateParentPage={interviewflow.updateParentPage}
                  appId={interviewflow.appId}
                  handleOpenEmailModal={interviewflow.handleOpenEmailModal}
                  getOfferDetails={interviewflow.getOfferDetails}
                  value={this.props.value}
                  jobInfo={jobInfo}
                />
                <WorkflowAction application={application} user={user} handleOpenEmailModal={interviewflow.handleOpenEmailModal} handleWorkflowStatusUpdate={this.offerConsent} />
              </Fragment>
            )}
          </div>
        </div> */}
        <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Offer flow').img}${offerConsentAccept === true ? ' pl-0' : ''}`}>
          {offerConsentAccept === true && (
            <div className="m-0 pl-0 ml-3 mt-1">
              <div className="our-accordion pl-3">
                <a role="accordion" className="bx--row" onClick={() => this.accordionAction('offerflow')} >
                  {this.getTitle('Offer flow')}
                  <div className="bx--row d-flex">{this.getArrow(showContnet.includes('offerflow') ? 1 : 0)}</div>
                </a>
                {this.getImageType('Offer flow').img && this.getImageType('Offer flow').img !== 'waitingImg' && this.getImageType('Offer flow').img !== 'rejectImg'  && !showContnet.includes('offerflow') && this.getStatus('offer-accepted')}
                { this.getImageType('Offer flow').img === 'successImg' && showContnet.includes('offerflow')
                  ? <div className="mt-4"><OfferInformation offerAcceptedCount={offerAcceptedCount} applications={{ applications: [application] }} jobInfo={jobInfo} push={push} /></div> : ''}
                { (this.getImageType('Offer flow').img === 'waitingImg' || this.getImageType('Offer flow').img === 'rejectImg' )&& !showContnet.includes('offerflow')
                  ? <div className="mt-4"><OfferInformation offerAcceptedCount={offerAcceptedCount} applications={{ applications: [application] }} jobInfo={jobInfo} push={push} /></div> : ''}
              </div>
            </div>
          )}
          {offerConsentAccept === null && (
            <div className="bx--row m-0 align-items-center mt-1 justify-content-between pl-3">
              <div className="mr-2">{this.getTitle('Offer flow')}</div>
            </div>
          )}
        </div>
        <div className={`m-md-2 bx--col-md bx--tile shadow-sm rounded mb-2 ${this.getImageType('Hiring Decision').img}`}>
          <div className="bx--row m-0 align-items-center mt-1 justify-content-between pl-3">
            <div className="ml-0">
              <div className="d-flex">
              <div className="mr-2 mb-md-0 mb-2">
                {this.getTitle('Hiring Decision')}
              </div>
              <div className="ml-2">
                {
                  this.getImageType('Hiring Decision').img === 'successImg'
                    && <p className="bx--col-md-8 bx--type-epsilon m-0 pl-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex text-success" style={{ flex: 1 }}><b className="mr-1 text-black text-uppercase">:</b>{t('hire')}</p>
                }
                {
                  this.getImageType('Hiring Decision').img === 'rejectImg'
                    && <p className="bx--col-md-8 bx--type-epsilon m-0 pl-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex text-danger" style={{ flex: 1 }}><b className="mr-1 text-black text-uppercase">:</b>{t('no')} {t('hire')}</p>
                }
              </div>
              </div>
              {this.getImageType('Hiring Decision').img && this.getImageType('Hiring Decision').img !== 'waitingImg'
                  && this.getStatus(this.getImageType('Hiring Decision').img === 'successImg' ? 'is-hired' : 'is-not-hired')}
            </div>
            {this.getImageType('Hiring Decision').img === 'waitingImg' && (
              <Fragment>
                {/* <p className="bx--col-md-8 m-0 ml-md-1 mt-md-0 mt-2 mb-md-0 mb-2 d-flex font-weight-light" style={{ flex: 1 }}>Take a decision to Select or Reject the Candidate.</p> */}
                <WorkflowAction application={application} user={user} handleOpenEmailModal={interviewflow.handleOpenEmailModal} />
              </Fragment>
            )}
          </div>
          <div>
            {this.renderRejectEmail('Hiring Decision')}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  offerDetails: state.OfferInformation.offerDetails,
  timeLine: state.roboroyApplications.timeLine,
  offerAcceptedCount: state.roboroyApplications.offerAcceptedCount,
});

const mapDispatchToProps = {
  getAssetAnalytics,
  getJobAppliedCount,
  sendEmails,
  getOfferDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InterviewStatus));
