import {
  REQUEST_REGISTRATION_LINK,
  SUCCESS_REGISTRATION_LINK,
  ERROR_REGISTRATION_LINK,
  REQUEST_REGISTER_STUDENT,
  SUCCESS_REGISTER_STUDENT,
  ERROR_REGISTER_STUDENT,
  REQUEST_ALL_COUNTRIES,
  SUCCESS_ALL_COUNTRIES,
  REQUEST_GET_ALL_LOCATIONS,
  SUCCESS_GET_ALL_LOCATIONS,
  ERROR_GET_ALL_LOCATIONS,
} from '../../actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  countries: [],
  locations: [],
};

const RegistrationRequest = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case REQUEST_REGISTRATION_LINK:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_REGISTRATION_LINK: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case ERROR_REGISTRATION_LINK:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_REGISTER_STUDENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_REGISTER_STUDENT: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case ERROR_REGISTER_STUDENT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ALL_COUNTRIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ALL_COUNTRIES:
      return {
        ...state,
        loading: false,
        error: null,
        countries: payload,
      };
    case REQUEST_GET_ALL_LOCATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ALL_LOCATIONS:
      return {
        ...state,
        loading: false,
        error: null,
        locations: payload,
      };
    case ERROR_GET_ALL_LOCATIONS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default RegistrationRequest;
