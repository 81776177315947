import React, { Fragment, Component} from 'react';
import { logout, sessionLogoutUser } from 'actions';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loading, Tile } from 'carbon-components-react';

import {fetchUserLoginState} from 'actions/userstate';

function SessionTimeOutPage(props) {
  return (
    <Tile className="xp-m__session--container mt-1">
      <Tile className="mb-2 mt-5" style={{'padding': '80px'}}>
        <div className="empty text-center">
          <p className="empty-title h5">Session Timeout.</p>
          <div className="empty-icon">
            <i className="fa fa-flag fa-2x" />
          </div>
          <div>
            Your session has timed out. Please login again. <a href="/">Login Here</a>
          </div>
        </div>
      </Tile>
    </Tile>
  );
}

export default SessionTimeOutPage