import { withTranslation } from 'react-i18next';
import React from 'react';
import Select from 'react-select';
import './dropdown.css';

const customStyles = {
  multiValue: (base, state) => ({
    ...base,
    alignItems: 'center',
    height: '30px',
  }),
};

const DropDownBox = ({
  title, selectedValue, props, name, options, onChange,
  disabled, isLoading, isClearable = true, isRightAlign, isMulti,
  placeholder, onInputChange, custom, className = '', mainClassName = '',
  titleClass = '', selectClass = '', required, labelClass = '', style,
  hasImages = false, isSearchable = true, noDropdownClass
}) => (
  <div className={`form-group ${!noDropdownClass ? 'dropdown':''} ${mainClassName === '' ? 'row m-0' : `${mainClassName}`} ${disabled ? 'disabled-select' : ''}`}>
    { title && (
      <div className={`${titleClass || 'col-sm-12 col-md-3'}`}>
        <label className={`${labelClass || 'form-label pt-3 pb-0 mb-0'} ${disabled ? 'disabled-label' : ''}`}>
          {required ? <div className="bx--row m-0 p-0"><div>{title}</div><div className="text-danger">*</div></div> : title }
        </label>
      </div>
    )}
    <div className={custom ? `${className}` : `newjob_input_container col-sm-12 col-md-9 ${className}`}>
      {onInputChange ? (
        <Select
          isMulti={isMulti}
          required={required}
          defaultValue={selectedValue}
          style={style}
          styles={hasImages ? customStyles : {}}
          className={`education ${selectClass || 'default'}`} //selectClass can be "light", "search", "none".
          classNamePrefix=""
          isDisabled={disabled}
          isLoading={isLoading}
          isClearable={(isMulti || isClearable) && isClearable !== false}
          isRtl={isRightAlign}
          noOptionsMessage={({ inputValue }) => !inputValue ? 'Start typing to see options' : options && Array.isArray(options) && options.length <= 0 && 'No Options'}
          isSearchable={isSearchable}
          placeholder={placeholder === null || placeholder === undefined ? 'select...' : placeholder}
          onInputChange={InputValue => onInputChange(InputValue)}
          onChange={value => onChange(value && value !== null ? value.value : '', value, name)}
          options={options}
          value={selectedValue && selectedValue !== null && (selectedValue.value || (Array.isArray(selectedValue) && selectedValue[0] && selectedValue[0].value)) ? selectedValue : ''}
        />
      )
        : (
          <Select
            isMulti={isMulti}
            required={required}
            defaultValue={selectedValue}
            className={`education ${selectClass || 'default'}`} //selectClass can be "light", "search", "none".
            classNamePrefix=""
            isDisabled={disabled}
            style={style}
            styles={hasImages ? customStyles : {}}
            noOptionsMessage={({ inputValue }) => !inputValue && 'Start typing to see options'}
            isLoading={isLoading}
            isClearable={(isMulti || isClearable) && isClearable !== false}
            isRtl={isRightAlign}
            isSearchable={isSearchable}
            placeholder={placeholder === null || placeholder === undefined ? 'select...' : placeholder}
            onChange={value => onChange(value && value !== null ? value.value : '', value, name)}
            options={options}
            value={selectedValue && selectedValue !== null && (selectedValue.value || (Array.isArray(selectedValue) && selectedValue[0] && selectedValue[0].value)) ? selectedValue : ''}
          />
        )}
    </div>
  </div>
);
export default DropDownBox;
