import { withTranslation } from 'react-i18next';
import React from 'react';
import {
  TextInput,
  Button,
} from 'carbon-components-react';

const FacultyUploadedDocs = (props) => {
  const {
    studentUploadDocs, handleDeleteStdDocs,
    t, showUploadBtn, handleUploadFile,
    disabled,
  } = props;
  return (
    <div>
      {studentUploadDocs
        && Array.isArray(studentUploadDocs)
        && studentUploadDocs.length > 0
        && studentUploadDocs.map((res, index) => {
          const { name, typeId, typeName } = res || {};
          return (
            <div className="d-lg-flex align-items-center">
              <div>
                <TextInput
                  className="mb-2"
                  id={typeId}
                  value={typeName}
                  disabled
                  hideLabel
                />
              </div>
              <div className="ml-lg-2">
                <TextInput
                  className="mb-2"
                  id={typeId}
                  value={name}
                  disabled
                  hideLabel={false}
                  name={name}
                />
              </div>
              {
                showUploadBtn === false && (
                  <div className="ml-lg-2">
                    <a style={{ color: 'red' }} onClick={() => handleDeleteStdDocs(typeId)} href="javascript:void(0)">{t('delete')}</a>
                  </div>
                )
              }
              {
                showUploadBtn === true && !disabled && (
                  <div className="ml-lg-2">
                    <div className="ml-2">
                      <input
                        type="file"
                        className="bx--file-input"
                        id={`upload-${typeId}`}
                        name={`upload-${typeId}`}
                        accept=".doc,.docx,.pdf"
                        onChange={(e) => handleUploadFile(e, typeId, typeName)}
                      />
                      <div className="bx--file h-auto mx-auto text-center newjob-upload-jd" data-file>
                        <label
                          id={`upload-${typeId}`}
                          htmlFor={`upload-${typeId}`}
                          className="bx--file-btn bx--btn bx--btn--primary bx--btn--sm uploadFile"
                          role="button"
                          tabIndex="0"
                        >
                          {t('upload')}
                          <input
                            style={{ minHeight: '2.5rem' }}
                            type="file"
                            className="bx--file-input"
                            id={`upload-${typeId}`}
                            disabled={disabled}
                            accept=".doc,.docx,.pdf"
                            onChange={(e) => handleUploadFile(e, typeId, typeName)}
                            name={`upload-${typeId}`}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          );
        })}
    </div>
  );
};

export default (withTranslation()(FacultyUploadedDocs));
