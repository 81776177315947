import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { Tile } from 'carbon-components-react';
import BackRow from 'components/common/BackRow/BackRow';
import { connect } from 'react-redux';

import CandidateProfile from 'containers/roboroy/CandidateProfile';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus' },
      { id: 'talentPool', name: 'Talent Pool', href: '/app/campus/talentpool' },
      { id: 'profile-dashboard', name: 'Profile', href: '' },
    ];
  }

  render() {
    const { match: { params: { profileId } = {} } = {}, loading = false } = this.props;
    const { isProfileLoaded = false } = this.props;
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <div className="">
          <CandidateProfile showOtherJobsTab profileId={profileId} notEditable noBenchMark noNameImg />
        </div>
        <JDProcessOverlay show={loading || !isProfileLoaded} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.roboroyApplications.loading,
  isProfileLoaded: state.roboroyApplications.isProfileLoaded,
});

export default connect(mapStateToProps, {})(ProfilePage);
