import callApi from 'utils/callApi';
import { baseUrl, regularUrl } from 'utils/config';
import {
  REQUEST_GET_STUDENTS_LIST,
  REQUEST_GET_STUDENTS,
  REQUEST_GET_STUDENT_FACETS,
  REQUEST_IMPORT_STUDENTS,
  REQUEST_IMPORT_STUDENTS_ATTENDANCE,
  REQUEST_IMPORT_STUDENTS_LO_INFO,
  REQUEST_CONFIRM_STUDENTS_EMAIL_INFO
} from './actionTypes';

const fetchAllStudentsList = data => dispatch => {
  const { itemsPerPage = 10, activePage = 1, searchKey = '' } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/companies/studentslist?`;
  if (data && data.location) {
    url += `location=${encodeURIComponent(JSON.stringify(data.location))}&`;
  }
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  if (searchKey) {
    url += `searchKey=${searchKey}`;
  }
  const params = {
    dispatch,
    actionType: REQUEST_GET_STUDENTS_LIST,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getAssessmentsCounts = data => (dispatch) => {
  const { batch } = data || {};
  let url = `${baseUrl}/events/s/assessments/counts?x=1`;
  if(batch){
    url+=`&batch=${batch}`
  }
  const params = {
    dispatch,
    actionType: "REQUEST_GET_ASSESSMENTS_COUNTS",
    url,
    method: 'GET',
  };
  return callApi(params);
}

const getStudentsList = data => (dispatch) => {
  const {
    itemsPerPage = 10,
    activePage = 1,
    searchKey = '',
    Faculties,
    Courses,
    Institutions,
    Suspended,
    Selected,
    Batch,
    userId,
    Scholar
  } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/companies/browseStudents?_=${new Date().getTime()}&`;
  if (Faculties && Array.isArray(Faculties) && Faculties.length > 0) {
    url += `faculty=${encodeURIComponent(JSON.stringify(Faculties))}&`;
  }
  if (
    data &&
    data['Application Submitted'] &&
    Array.isArray(data['Application Submitted']) &&
    data['Application Submitted'].length > 0
  ) {
    url += `isAppSubmitted=${encodeURIComponent(
      JSON.stringify(data['Application Submitted'])
    )}&`;
  }
  if (
    data &&
    data['Open Market'] &&
    Array.isArray(data['Open Market']) &&
    data['Open Market'].length > 0
  ) {
    url += `isOpenMarket=${encodeURIComponent(
      JSON.stringify(data['Open Market'])
    )}&`;
  }
  if (
    data &&
    data['Internship Date'] &&
    Array.isArray(data['Internship Date']) &&
    data['Internship Date'].length > 0
  ) {
    url += `internshipDate=${encodeURIComponent(
      JSON.stringify(data['Internship Date'])
    )}&`;
  }
  if (
    data &&
    data['Hired'] &&
    Array.isArray(data['Hired']) &&
    data['Hired'].length > 0
  ) {
    url += `isHired=${encodeURIComponent(
      JSON.stringify(data['Hired'])
    )}&`;
  }
  if (
    data &&
    data['Completed Workflow'] &&
    Array.isArray(data['Completed Workflow']) &&
    data['Completed Workflow'].length > 0
  ) {
    url += `isCompletedWorkflow=${encodeURIComponent(
      JSON.stringify(data['Completed Workflow'])
    )}&`;
  }
  if (
    data &&
    data['Applied to Jobs'] &&
    Array.isArray(data['Applied to Jobs']) &&
    data['Applied to Jobs'].length > 0
  ) {
    url += `hasAppliedToJobs=${encodeURIComponent(
      JSON.stringify(data['Applied to Jobs'])
    )}&`;
  }
  if (
    data &&
    data['Accepted Offer'] &&
    Array.isArray(data['Accepted Offer']) &&
    data['Accepted Offer'].length > 0
  ) {
    url += `hasAcceptedOffer=${encodeURIComponent(
      JSON.stringify(data['Accepted Offer'])
    )}&`;
  }
  if (Suspended && Array.isArray(Suspended) && Suspended.length > 0) {
    url += `suspended=${encodeURIComponent(JSON.stringify(Suspended))}&`;
  }
  if (Selected && Array.isArray(Selected) && Selected.length > 0) {
    url += `selected=${encodeURIComponent(JSON.stringify(Selected))}&`;
  }
  if (Courses && Array.isArray(Courses) && Courses.length > 0) {
    url += `course=${encodeURIComponent(JSON.stringify(Courses))}&`;
  }
  if (Institutions && Array.isArray(Institutions) && Institutions.length > 0) {
    url += `institution=${encodeURIComponent(JSON.stringify(Institutions))}&`;
  }
  if (
    data &&
    data['Uploaded Signed Offer Letter'] &&
    Array.isArray(data['Uploaded Signed Offer Letter']) &&
    data['Uploaded Signed Offer Letter'].length > 0
  ) {
    url += `isSignedOffer=${encodeURIComponent(
      JSON.stringify(data['Uploaded Signed Offer Letter'])
    )}&`;
  }
  if (
    data &&
    data['Offer In Progress'] &&
    Array.isArray(data['Offer In Progress']) &&
    data['Offer In Progress'].length > 0
  ) {
    url += `offerInProgress=${encodeURIComponent(
      JSON.stringify(data['Offer In Progress'])
    )}&`;
  }
  if (Batch && Array.isArray(Batch) && Batch.length > 0) {
    url += `batch=${encodeURIComponent(JSON.stringify(Batch))}&`;
  }
  if (
    data &&
    data['Shortlisted By Companies'] &&
    Array.isArray(data['Shortlisted By Companies']) &&
    data['Shortlisted By Companies'].length > 0
  ) {
    url += `isShortlistedByCompany=${encodeURIComponent(
      JSON.stringify(data['Shortlisted By Companies'])
    )}&`;
  }
  if (Scholar && Array.isArray(Scholar) && Scholar.length > 0) {
    url += `scholar=${encodeURIComponent(JSON.stringify(Scholar))}&`;
  }
  if (
    data &&
    data['Selected For GEIP'] &&
    Array.isArray(data['Selected For GEIP']) &&
    data['Selected For GEIP'].length > 0
  ) {
    url += `isSelectedForGEIP=${encodeURIComponent(
      JSON.stringify(data['Selected For GEIP'])
    )}&`;
  }
  if (
    data &&
    data['Profiling Assessment'] &&
    Array.isArray(data['Profiling Assessment']) &&
    data['Profiling Assessment'].length > 0
  ) {
    url += `isProfilingAssessmentDone=${encodeURIComponent(
      JSON.stringify(data['Profiling Assessment'])
    )}&`;
  }
  if (
    data &&
    data['Room Offline Video Interview'] &&
    Array.isArray(data['Room Offline Video Interview']) &&
    data['Room Offline Video Interview'].length > 0
  ) {
    url += `isRoomAssessmentDone=${encodeURIComponent(
      JSON.stringify(data['Room Offline Video Interview'])
    )}&`;
  }
  if (
    data &&
    data['Booked Panel Interview'] &&
    Array.isArray(data['Booked Panel Interview']) &&
    data['Booked Panel Interview'].length > 0
  ) {
    url += `isBookPanelInterviewDone=${encodeURIComponent(
      JSON.stringify(data['Booked Panel Interview'])
    )}&`;
  }
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  if (searchKey) {
    url += `searchKey=${searchKey}`;
  }
  if(userId){
    url += `&userId=${userId}`
  }
  const params = {
    dispatch,
    actionType: REQUEST_GET_STUDENTS,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getStudentsFacets = data => (dispatch) => {
  const { Faculties, Courses, Institutions, Suspended, Selected, Batch, Scholar } = data || {};
  const finalData = {};
  if (Faculties && Array.isArray(Faculties) && Faculties.length > 0) {
    finalData.Faculties = Faculties;
  }
  if (Courses && Array.isArray(Courses) && Courses.length > 0) {
    finalData.Courses = Courses;
  }
  if (Suspended && Array.isArray(Suspended) && Suspended.length > 0) {
    finalData.Suspended = Suspended;
  }
  if (Selected && Array.isArray(Selected) && Selected.length > 0) {
    finalData.Selected = Selected;
  }
  if (Institutions && Array.isArray(Institutions) && Institutions.length > 0) {
    finalData.Institutions = Institutions;
  }
  if (
    data &&
    data['Application Submitted'] &&
    Array.isArray(data['Application Submitted']) &&
    data['Application Submitted'].length > 0
  ) {
    finalData.IsAppSubmitted = data['Application Submitted'];
  }
  if (
    data &&
    data['Open Market'] &&
    Array.isArray(data['Open Market']) &&
    data['Open Market'].length > 0
  ) {
    finalData.isOpenMarket = data['Open Market'];
  }
  if (
    data &&
    data['Internship Date'] &&
    Array.isArray(data['Internship Date']) &&
    data['Internship Date'].length > 0
  ) {
    finalData.InternshipDate = data['Internship Date'];
  }
  if (
    data &&
    data['Applied to Jobs'] &&
    Array.isArray(data['Applied to Jobs']) &&
    data['Applied to Jobs'].length > 0
  ) {
    finalData.hasAppliedToJobs = data['Applied to Jobs'];
  }
  if (
    data &&
    data['Accepted Offer'] &&
    Array.isArray(data['Accepted Offer']) &&
    data['Accepted Offer'].length > 0
  ) {
    finalData.hasAcceptedOffer = data['Accepted Offer'];
  }
  if (
    data &&
    data['Uploaded Signed Offer Letter'] &&
    Array.isArray(data['Uploaded Signed Offer Letter']) &&
    data['Uploaded Signed Offer Letter'].length > 0
  ) {
    finalData.isSignedOffer = data['Uploaded Signed Offer Letter'];
  }

  if (
    data &&
    data['Offer In Progress'] &&
    Array.isArray(data['Offer In Progress']) &&
    data['Offer In Progress'].length > 0
  ) {
    finalData.offerInProgress = data['Offer InProgress'];
  }
  if (Batch && Array.isArray(Batch) && Batch.length > 0) {
    finalData.Batch = Batch;
  }

  if (
    data &&
    data['Shortlisted By Companies'] &&
    Array.isArray(data['Shortlisted By Companies']) &&
    data['Shortlisted By Companies'].length > 0
  ) {
    finalData.isShortlistedByCompany = data['Shortlisted By Companies'];
  }
  if (Scholar && Array.isArray(Scholar) && Scholar.length > 0) {
    finalData.Scholar = Scholar;
  }
  const url = `${baseUrl}/companies/studentfacets?_=${new Date().getTime()}&filters=${encodeURIComponent(
    JSON.stringify(finalData)
  )}`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_STUDENT_FACETS,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const uploadStudentsData = link => dispatch => {
  const url = `${regularUrl}/upload/xls`;
  const params = {
    dispatch,
    actionType: REQUEST_IMPORT_STUDENTS,
    url,
    body: link,
    method: 'POST',
  };
  return callApi(params);
};

const uploadStudentsAttendance = link => dispatch => {
  const url = `${regularUrl}/upload/studentcsv`;
  const params = {
    dispatch,
    actionType: REQUEST_IMPORT_STUDENTS_ATTENDANCE,
    url,
    body: link,
    method: 'POST',
  };
  return callApi(params);
};

const uploadStudentsLoInfo = link => dispatch => {
  const url = `${regularUrl}/upload/studentlocsv`;
  const params = {
    dispatch,
    actionType: REQUEST_IMPORT_STUDENTS_LO_INFO,
    url,
    body: link,
    method: 'POST',
  };
  return callApi(params);
};

const sendOfferConfirmationMail = (data) => dispatch => {
  const url = `${baseUrl}/companies/sendOfferConfirmationMail`;

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CONFIRM_STUDENTS_EMAIL_INFO,
  };
  return callApi(params);
};

export {
  fetchAllStudentsList,
  getStudentsList,
  getStudentsFacets,
  uploadStudentsData,
  uploadStudentsAttendance,
  uploadStudentsLoInfo,
  sendOfferConfirmationMail,
  getAssessmentsCounts
};
