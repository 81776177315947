import callApi from 'utils/callApi';
import { baseUrl } from 'utils/config';
import { REQUEST_GPT } from '../actionTypes';

const reRenderContent = ({ itemsPerPage = 10, activePage = 1, searchKey = null, selected = {} }) => (dispatch) => {
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/profile-base/getGlobalTalentPool?`
  if (searchKey != null && searchKey.length > 0)
    url += `searchKey=${encodeURIComponent(searchKey)}`;
  if (selected.Companies && selected.Companies.length > 0)
    url += `&company=${encodeURIComponent(JSON.stringify(selected.Companies))}`;
  if (selected.Countries && selected.Countries.length > 0)
    url += `&location=${encodeURIComponent(JSON.stringify(selected.Countries))}`;
  if (selected.Skills && selected.Skills.length > 0)
    url += `&skills=${encodeURIComponent(JSON.stringify(selected.Skills))}`;
  if (selected.Jobname && selected.Jobname.length > 0)
    url += `&jobname=${encodeURIComponent(JSON.stringify(selected.Jobname))}`;
  if (selected.Experience && selected.Experience.length > 0)
    url += `&experienceList=${(JSON.stringify(selected.Experience))}`;
  url += `&filter[skip]=${skip}`;
  url += `&filter[limit]=${itemsPerPage}`;

  // const paginateUrl = `${baseUrl}/Profiles/findGlobalTalent?_=${new Date().getTime()}&filter[limit]=${itemsPerPage}&filter[skip]=${skip}${
  //   (searchKey) ?`&searchKey=${searchKey}` : ''
  // }`;

  const params = {
    actionType: REQUEST_GPT,
    dispatch,
    url: url,
    method: 'GET',
  };

  return callApi(params);
};

export { reRenderContent };
