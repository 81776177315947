import {
  REQUEST_GET_USER_WORKFLOWS,
  SUCCESS_GET_USER_WORKFLOWS,
  ERROR_GET_USER_WORKFLOWS,
  REQUEST_GET_WORKFLOW_STAGES,
  SUCCESS_GET_WORKFLOW_STAGES,
  ERROR_GET_WORKFLOW_STAGES,
  REQUEST_CREATE_WORKFLOW,
  SUCCESS_CREATE_WORKFLOW,
  ERROR_CREATE_WORKFLOW,
  REQUEST_ASSIGN_WORKFLOW_TO_APPLICANT,
  SUCCESS_ASSIGN_WORKFLOW_TO_APPLICANT,        
  ERROR_ASSIGN_WORKFLOW_TO_APPLICANT,
  REQUEST_UPDATE_WORKFLOW_CONFIG,
  SUCCESS_UPDATE_WORKFLOW_CONFIG,
  ERROR_UPDATE_WORKFLOW_CONFIG,    
  REQUEST_SKIP_WORKFLOW_STAGE,
  SUCCESS_SKIP_WORKFLOW_STAGE,
  ERROR_SKIP_WORKFLOW_STAGE,    
  REQUEST_UPDATE_WORKFLOW_APPL_STAGE,
  SUCCESS_UPDATE_WORKFLOW_APPL_STAGE,
  REQUEST_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER,
  SUCCESS_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER,
  ERROR_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER,
  ERROR_UPDATE_WORKFLOW_APPL_STAGE,
  REQUEST_APPL_WORKFLOW,
  SUCCESS_APPL_WORKFLOW,
  ERROR_APPL_WORKFLOW,
  REQUEST_DELETE_WORKFLOW,
  SUCCESS_DELETE_WORKFLOW,
  ERROR_DELETE_WORKFLOW,
  REQUEST_DELETE_WORKFLOW_APPL_SUBSTAGE_USER,
  SUCCESS_DELETE_WORKFLOW_APPL_SUBSTAGE_USER,
  ERROR_DELETE_WORKFLOW_APPL_SUBSTAGE_USER,
} from '../actions/actionTypes';
  
const initState = {
  loading: true,
  error: null,
  workflows: [],
  workflowstages:[],
  workflow : null
};
  
const workflows = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_APPL_WORKFLOW:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_APPL_WORKFLOW:
      return {
        ...state,
        workflow: (payload && payload.data) || {},
        loading: false,
        error: null,
      };
    case ERROR_APPL_WORKFLOW:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_WORKFLOW_APPL_SUBSTAGE_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_WORKFLOW_APPL_SUBSTAGE_USER:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_WORKFLOW_APPL_SUBSTAGE_USER:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_USER_WORKFLOWS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_USER_WORKFLOWS:
      const { data = [] } = payload;
      return {
        ...state,
        workflows: data,
        loading: false,
        error: null,
      };
    case ERROR_GET_USER_WORKFLOWS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
      case REQUEST_GET_WORKFLOW_STAGES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_WORKFLOW_STAGES:
      return {
        ...state,
        workflowstages: (payload) || [],
        loading: false,
        error: null,
      };
    case ERROR_GET_WORKFLOW_STAGES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };        
    case REQUEST_CREATE_WORKFLOW:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_WORKFLOW:
      return {
        ...state,          
        loading: false,
        workflow: (payload) || {},
        error: null,
      };
    case ERROR_CREATE_WORKFLOW:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_WORKFLOW:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_WORKFLOW:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_WORKFLOW:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
      case REQUEST_UPDATE_WORKFLOW_CONFIG:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_WORKFLOW_CONFIG:
      return {
        ...state,          
        loading: false,
        workflow: (payload) || {},
        error: null,
      };
    case ERROR_UPDATE_WORKFLOW_CONFIG:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };      
      case REQUEST_SKIP_WORKFLOW_STAGE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SKIP_WORKFLOW_STAGE:
      return {
        ...state,          
        loading: false,
        workflow: (payload) || {},
        error: null,
      };
    case ERROR_SKIP_WORKFLOW_STAGE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };              
      case REQUEST_ASSIGN_WORKFLOW_TO_APPLICANT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ASSIGN_WORKFLOW_TO_APPLICANT:
      return {
        ...state,          
        loading: false,
        error: null,
      };
    case ERROR_ASSIGN_WORKFLOW_TO_APPLICANT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };              
    case REQUEST_UPDATE_WORKFLOW_APPL_STAGE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_WORKFLOW_APPL_STAGE:
      return {
        ...state,          
        loading: false,
        error: null,
      };
    case ERROR_UPDATE_WORKFLOW_APPL_STAGE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };       
      case REQUEST_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER:
      return {
        ...state,          
        loading: false,
        error: null,
      };
    case ERROR_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };            
    default:
      return state;
  }
};

export default workflows;
