import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_CREATE_LANGUAGES,
  REQUEST_EDIT_LANGUAGES,
  REQUEST_DELETE_LANGUAGES,
  REQUEST_GET_LANGUAGES,
} from './actionTypes';

const getLanguagesList = data => (dispatch) => {
  const {
    activePage,
    itemsPerPage,
    keyword,
  } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/languages?_=${new Date().getTime()}&`;
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  if (keyword) {
    url += `searchKey=${keyword}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_LANGUAGES,
  };

  return callApi(params);
};

const createLanguageDetails = data => (dispatch) => {
  const url = `${baseUrl}/languages?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_LANGUAGES,
  };

  return callApi(params);
};

const upsertLanguageDetailsById = data => (dispatch) => {
  const {
    languageId,
  } = data || {};
  const url = `${baseUrl}/languages/${languageId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_LANGUAGES,
  };

  return callApi(params);
};

const deleteLanguageDetailsById = languageId => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No language id passed',
  };
  if (!languageId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/languages/${languageId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_LANGUAGES,
  };
  return callApi(params);
};

export {
  getLanguagesList,
  createLanguageDetails,
  upsertLanguageDetailsById,
  deleteLanguageDetailsById,
};
