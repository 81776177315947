import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import {
  Tile,
  TextArea,
  TextInput,
  Button,
  InlineNotification,
  Checkbox,
} from 'carbon-components-react';
import zxcvbn from 'zxcvbn';
import phone from 'phone';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import PublicHeader from 'containers/common/publicHeader';
import { getAllCountries } from 'actions/admin/CompanyAndOffice';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
import { getAllFaculties } from 'actions/candidate/CandidateDetails';
import { getLocations } from 'actions/campus/registrationRequest';
import { xSearchCompanies, xCreateCompany } from 'actions/xpa';
import { debounce } from 'lodash';
import './companyRegister.css';
import OfficeDetails from 'components/roboroy/common/OfficeDetails';
import ContactDetails from 'components/roboroy/ContactDetails';
import WizardSteps from 'components/roboroy/WizardSteps';
import {
  createInstitutionUser,
  createCompanyUser,
  getCompanySizes,
  validateCompanyUEN,
  getCompanyTypes,
  getSaluationList,
  getCompanyIndustries,
} from 'actions/companyRegistration';
import {
  contactInfoValidation,
  officeDetailsValidation,
  companyDetailsValid,
  getLocationsDetails,
} from 'containers/common/campusLogin/validationFunctions';
import { createUserForACompany } from 'actions/InstitutionView';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

class CompanyRegistration extends Component {
  companyPaths = [
    { id: 'campus', name: 'Hiring', href: '/app/campus' },
    {
      id: 'institution',
      name: 'Browse Companies',
      href: '/app/campus/browse/companies',
    },
    { id: 'registration', name: 'Register Company', href: '' },
  ];

  institutionPaths = [
    { id: 'campus', name: 'Hiring', href: '/app/campus' },
    {
      id: 'institution',
      name: 'Browse Institutions',
      href: '/app/campus/browse/institutions',
    },
    { id: 'registration', name: 'Register Institution', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedCompanyObj: {},
      companyName: null,
      compDesc: '',
      website: '',
      firstname: '',
      lastname: '',
      email: '',
      isDropdownLoading: false,
      companyNameInvalid: false,
      isFirstNameValid: false,
      isFirstNameValidText: '',
      isLastNameValid: false,
      isLastNameValidText: '',
      isEmailValid: false,
      isEmailValidText: '',
      showRegForm: true,
      phoneNumber: null,
      isValidCountryCode: false,
      isValidCountryCodeValue: '',
      isPhoneNumberValid: false,
      isPhoneNumberValidText: '',
      countryCode: 65,
      designation: '',
      isValidDesignation: false,
      isValidDesignationVal: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      zipcode: null,
      location: { label: 'singapore', value: 18 },
      country: 202,
      officeName: '',
      disabled: false,
      list: [],
      currStep: 0,
      showLanguageModal: false,
      pwdLevel: ['weak', 'weak', 'good', 'strong', 'stronger'],
      score: 0,
      institutions: [],
      facultyArr: [],
      isTermChecked: false,
      companyUEN: null,
      isCompanyUENValid: false,
      isCompanyUENValidMsg: '',
      hideCompanyDetTerms: false,
      termsConditions: '',
      companyTypesArr: [],
      showDropDownLoading: false,
      saluationListArr: [],
      saluationVal: {},
      industriesArr: [],
      companyArr: [],
      showDBErrMsg: false,
      isPrivacyPolicyChecked:false,
      showPrivacyPolicyErr:false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        [`${0}-status`]: 'process',
        [`${1}-status`]: 'error',
        [`${2}-status`]: 'error',
        [`${3}-status`]: 'error',
      },
      () => {
        this.props.xSearchCompanies('', 1, null).then((comps) => {
          if (comps && !comps.error) {
            let {
              companyArr = [],
            } = this.state;
            if (comps && !comps.error && Array.isArray(comps) && comps.length > 0) {
              companyArr = comps.map((c) => {
                const {
                  companyId,
                  companyName,
                  companyadditionalinfo,
                } = c || {};
                const {
                  companyUEN,
                } = companyadditionalinfo || {};
                const data = {
                  label: companyName,
                  value: companyId,
                  companyUEN,
                };
                return data;
              });
            }
            this.setState({
              companyArr,
            }, () => {
              this.props.xSearchCompanies('', 2, 1).then((insts) => {
                if (insts && !insts.error) {
                  const {
                    companyadditionalinfo: { termsConditions } = {},
                  } = insts || {};
                  this.setState({
                    termsConditions,
                  }, () => {
                    this.props.getCompanyTypes().then((types) => {
                      if (types && !types.error) {
                        this.props.getSaluationList().then((sals) => {
                          if (sals && !sals.error) {
                            this.props.getCompanySizes().then((sizes) => {
                              if (sizes && !sizes.error) {
                                this.props.getAllCountries().then((ctrs) => {
                                  if (ctrs && !ctrs.error) {
                                    this.handleFetchDropDownData();
                                  }
                                }).catch(e => console.error('Error::::', e));
                              }
                            }).catch(e => console.error('Error::::', e));
                          }
                        }).catch(e => console.error('Error::::', e));
                      }
                    }).catch(e => console.error('Error::::', e));
                  });
                }
              }).catch(e => console.error('Error::::', e));
            });
          }
        }).catch(e => console.error('Error::::', e));
      },
    );
  }

  handleFetchDropDownData = () => {
    let {
      companyTypesArr = [],
      saluationListArr = [],
      companySizeArr = [],
    } = this.state;
    const {
      companyTypes,
      saluationList,
      companySize,
    } = this.props;

    if (companyTypes && Array.isArray(companyTypes) && companyTypes.length > 0) {
      companyTypesArr = companyTypes.map((type) => {
        const { companyTypeId, companyName } =
          type || {};
        const data = {
          label: companyName,
          value: companyTypeId,
        };
        return data;
      });
    }

    if (
      saluationList &&
      Array.isArray(saluationList) &&
      saluationList.length > 0
    ) {
      saluationListArr = saluationList.map(
        (sals) => {
          const {
            saluationId,
            saluationName,
          } = sals || {};
          const data = {
            label: saluationName,
            value: saluationId,
          };
          return data;
        }
      );
    }

    if (companySize && Array.isArray(companySize) && companySize.length > 0) {
      companySizeArr = companySize.map((size) => {
        const { sizeId, sizeName } = size;
        const data = {
          label: sizeName,
          value: sizeId,
        };
        return data;
      });
    }

    this.setState({
      companyTypesArr,
      saluationListArr,
      companySizeArr,
    }, () => {
      this.handleIndustriesdata();
    });
  }

  handleIndustriesdata = () => {
    this.props.getCompanyIndustries().then((inds) => {
      if (inds && !inds.error) {
        const {
          industriesList,
        } = this.props;
        let industriesArr = [];
        if (industriesList && Array.isArray(industriesList) && industriesList.length > 0) {
          const filteredIndustriesList=industriesList.filter((ids)=>{return ids.flag===null})
          industriesArr = filteredIndustriesList.map((ids) => {
            const {
              industryId,
              industryName,
            } = ids || {};
            const data = {
              label: industryName,
              value: industryId,
            };
            return data;
          });
        }
        this.setState({
          industriesArr,
        });
      }
    });
  }

  handleChange = (e) => {
    let value = null;
    if(e.target.name=='companyUEN'){
      value=e.target.value.trim();
    }else{
      value=e.target.value;
    }

    this.setState({
      [e.target.name]: value,
      companyVals: {},
    });
  };

  handleChangeCompany = (e) => {
    this.setState(
      {
        selectedCompanyObj: e,
        companyNameInvalid: false,
      },
      () => {
        this.setState({
          disabled: true,
        });
      }
    );
  };

  handleSubmitValues = () => {
    const {
      countries,
      t,
      match: { params: { status } = {} } = {},
    } = this.props;
    let finalPersonNumber = [];
    const {
      firstname,
      lastname,
      email,
      selectedCompanyObj,
      website,
      compDesc,
      designation,
      countryCode,
      phoneNumber,
      addressLine1,
      addressLine2,
      zipcode,
      country,
      location,
      officeName,
      password,
      sizeVal,
      skills: skillsVal,
      jobTitle,
      jobDescription,
      facultyVal: { value: facultyId } = {},
      companyUEN,
      companyName,
      companyTypeVal: { value: companytypeId } = {},
      saluationVal: { value: saluationId } = {},
      customCompanyType,
      customIndustryName,
      industryVal,
    } = this.state;
    // const { label: companyName } = selectedCompanyObj || {};
    const data = {
      website,
      description: compDesc,
      addressLine1,
      addressLine2,
      zipcode,
      countryId: country,
      locationName: location && location.label,
      state: location && location.label,
      officeName: `${companyName} office`,
      password,
      industryName: industryVal && industryVal.label,
      industryId: industryVal && industryVal.value,
      sizeId: sizeVal && sizeVal.value,
      jobTitle,
      jobDescription,
      facultyId,
      saluationId,
    };

    let skills = [];
    if (skillsVal && Array.isArray(skillsVal) && skillsVal.length > 0) {
      skills = skillsVal.map(res => res && res.label);
    }

    data.skills = skills;

    if (firstname && firstname !== null && firstname !== undefined) {
      data.firstname = firstname;
    }

    if (lastname && lastname !== null && lastname !== undefined) {
      data.lastname = lastname;
    }

    if (email && email !== null && email !== undefined) {
      data.email = email;
    }

    if (countryCode && countryCode !== null && countryCode !== undefined) {
      data.countryCode = countryCode;
    }

    if (phoneNumber && phoneNumber.toString() && phoneNumber.length < 8) {
      this.setState({
        isPhoneNumberValid: true,
      });
      return;
    }
    if (status === 'company') {
      data.ctypeId = 1;
      data.phone = phoneNumber;
      data.codeId = countryCode;
      data.companyName = companyName;
      data.displayCompanyName = companyName;
      data.companyUEN = companyUEN;
      data.institutionId = 1;
      data.designation = designation;
      data.companytypeId = companytypeId;
      if (companytypeId && Number(companytypeId) === 7) {
        data.customCompanyType = customCompanyType;
      }
      if (industryVal && industryVal.label === 'Others') {
        data.customIndustryName = customIndustryName;
      }
      this.props
        .createCompanyUser(data)
        .then(newCompany => {
          const { error, companyId } = newCompany || {};
          if (error) {
            this.setState({
              showDBErrMsg: true,
              hideCompanyDetTerms: null,
              showRegForm: null
            })
            console.error(error);
            return;
          }
          this.setState({
            selectedCompanyObj: { label: companyName, value: companyId },
            companyNameInvalid: false,
            showRegForm: false,
          });
        })
        .finally(() => {
          this.setState({
            isDropdownLoading: false,
          });
        });
    } else {
      data.ctypeId = 2;
      data.companyName = companyName;
      if (designation && designation !== null && designation !== undefined) {
        data.designation = designation;
      }
      this.props
        .createInstitutionUser(data)
        .then(newCompany => {
          const { error } = newCompany || {};
          if (error) {
            this.setState({
              showError: true,
            });
          }
        })
        .finally(() => {
          this.setState({
            isDropdownLoading: false,
          });
        });
    }
  };

  handleFinalStep = () => {
    const { isTermChecked, isPrivacyPolicyChecked } = this.state;
    if (!isTermChecked || !isPrivacyPolicyChecked) {
      const invalidData={};
      invalidData.showTermsErr = (!isTermChecked)?true:false;
      invalidData.showPrivacyPolicyErr = (!isPrivacyPolicyChecked)?true:false;
      this.setState({
        ...invalidData
      });
    } else if (
      this.state[`${0}-status`] !== 'error' &&
      this.state[`${1}-status`] !== 'error' &&
      this.state[`${2}-status`] !== 'error'
    ) {
      this.setState(
        {
          showRegForm: false,
          showTermsErr: false,
        },
        () => {
          this.handleSubmitValues();
        }
      );
    } else {
      if (this.state[`${0}-status`] === 'error') {
        this.onChange(0);
      } else if (this.state[`${1}-status`] === 'error') {
        this.onChange(1);
      } else if (this.state[`${2}-status`] === 'error') {
        this.onChange(2);
      }
    }
  };

  handleContactInfoValidation = (curr) => {
    const {
      firstname,
      lastname,
      email,
      countryCode,
      phoneNumber,
      designation,
      saluationVal: { value: saluationId } = {},
    } = this.state;
    const { match: { params: { status } = {} } = {}, t } = this.props;
    const contactInfo = {
      firstname,
      lastname,
      email,
      countryCode,
      phoneNumber,
      t,
      designation,
      status,
    };
    let isSaluationValid = false;
    if (!saluationId) {
      isSaluationValid = true;
    } else {
      isSaluationValid = false;
    }
    const validContactInfo = contactInfoValidation(contactInfo);
    this.setState(
      {
        ...validContactInfo,
        isSaluationValid,
      },
      () => {
        this.handleContactInfoStatusValid(curr);
      }
    );
  };

  handleContactInfoStatusValid = (curr) => {
    const {
      isFirstNameValid,
      isLastNameValid,
      isEmailValid,
      isValidCountryCode,
      isPhoneNumberValid,
      isValidDesignation,
      currStep,
      isSaluationValid,
      designation,
    } = this.state;
    if (curr !== true) {
      if (
        currStep !== 2 &&
        (isFirstNameValid === true ||
          isLastNameValid === true ||
          isEmailValid === true ||
          isValidCountryCode === true ||
          isPhoneNumberValid === true ||
          isValidDesignation === true ||
          isSaluationValid === true)
      ) {
        this.setState({
          [`${2}-status`]: 'error',
        });
      } else if (currStep === 2) {
        if (
          isFirstNameValid === true ||
          isLastNameValid === true ||
          isEmailValid === true ||
          isValidCountryCode === true ||
          isPhoneNumberValid === true ||
          isValidDesignation === true ||
          isSaluationValid === true
        ) {
          this.setState({
            [`${2}-status`]: 'process',
          });
        } else if (
          isFirstNameValid !== true &&
          isLastNameValid !== true &&
          isEmailValid !== true &&
          isValidCountryCode !== true &&
          isPhoneNumberValid !== true &&
          isValidDesignation !== true &&
          isSaluationValid !== true
        ) {
          if (curr === null) {
            this.setState({
              [`${2}-status`]: 'process',
              currStep: currStep + 1,
              [`${3}-status`]: 'process',
            });
          } else if (curr !== null) {
            this.setState({
              [`${2}-status`]: 'process',
            });
          }
        }
      }
    } else {
      if (isFirstNameValid !== true &&
        isLastNameValid !== true &&
        isEmailValid !== true &&
        isValidCountryCode !== true &&
        isPhoneNumberValid !== true &&
        isValidDesignation !== true &&
        isSaluationValid !== true) {
          const { uenCompId, companyUEN, firstname, lastname, email, phoneNumber, countryCode, saluationVal: { value: saluationId } = {} } = this.state;
          const data = {
            companyUEN,
            firstname,
            lastname,
            email,
            institutionId: 1,
            companyId: uenCompId,
            phone: phoneNumber,
            codeId: countryCode,
            saluationId,
            designation,
          };
          this.props.createCompanyUser(data).then((res) => {
            if (res && !res.error) {
              this.setState({
                showRegForm: false,
              });
            }else{
              this.setState({
                showErrNotif: true,
                errType: 'error',
                errMsg: res.error.message || 'Sorry, something went wrong'
              })
            }
          });
        }
    }
  };

  handleOfficeValidation = curr => {
    const {
      addressLine1,
      addressLine2,
      zipcode,
      country,
      location,
      officeName,
    } = this.state;
    const { t } = this.props;
    const officeInfo = {
      addressLine1,
      addressLine2,
      zipcode,
      country,
      location,
      officeName,
      t,
    };
    const officeValidInfo = officeDetailsValidation(officeInfo);
    this.setState(
      {
        ...officeValidInfo,
      },
      () => {
        this.handleOfficeStatusValid(curr);
      }
    );
  };

  handleOfficeStatusValid = curr => {
    const {
      isValidAddrLine1,
      isValidCountry,
      isValidOfficeName,
      isValidZipcode,
      isValidCity,
      currStep,
    } = this.state;
    if (currStep === 1) {
      if (
        isValidAddrLine1 === true ||
        isValidCountry === true ||
        // isValidOfficeName === true ||
        isValidZipcode === true ||
        isValidCity === true
      ) {
        this.setState({
          [`${0}-status`]: 'process',
        });
      } else if (
        (isValidAddrLine1 !== true ||
          isValidCountry !== true ||
          // isValidOfficeName !== true ||
          isValidZipcode !== true ||
          isValidCity !== true) &&
        curr === null
      ) {
        this.setState({
          [`${0}-status`]: 'process',
          [`${2}-status`]: 'process',
          currStep: currStep + 1,
        });
      } else if (
        (isValidAddrLine1 !== true ||
          isValidCountry !== true ||
          // isValidOfficeName !== true ||
          isValidZipcode !== true ||
          isValidCity !== true) &&
        curr !== null
      ) {
        this.setState({
          [`${0}-status`]: 'process',
        });
      }
    }
  };

  handleJobDetailsValidation = curr => {
    const {
      jobTitle,
      jobDescription,
      skills,
      institutionVal: { value: institutionId } = {},
      facultyVal: { value: facultyId } = {},
    } = this.state;
    let isValidJobTitle = false;
    let isValidJobDesc = false;
    let isSkillsValid = false;
    let isInstitutionValid = false;
    let isFacultyValid = false;
    if (!jobTitle) {
      isValidJobTitle = true;
    } else {
      isValidJobTitle = false;
    }
    if (!jobDescription) {
      isValidJobDesc = true;
    } else {
      isValidJobDesc = false;
    }
    if (!institutionId) {
      isInstitutionValid = true;
    } else {
      isInstitutionValid = false;
    }
    if (!facultyId) {
      isFacultyValid = true;
    } else {
      isFacultyValid = false;
    }
    if (!skills || (skills && Array.isArray(skills) && skills.length <= 0)) {
      isSkillsValid = true;
    } else {
      isSkillsValid = false;
    }
    this.setState(
      {
        isValidJobTitle,
        isValidJobDesc,
        isSkillsValid,
        isInstitutionValid,
        isFacultyValid,
      },
      () => {
        this.handleJobStatusValidation(curr);
      }
    );
  };

  handleCompanyValidation = curr => {
    const {
      selectedCompanyObj,
      sizeVal: { value: sizeId } = {},
      companyTypeVal: { value: companytypeId } = {},
      companyName,
      customCompanyType,
      customIndustryName,
      industryVal: { value: industryId } = {},
    } = this.state;
    const { t } = this.props;
    // const { label: companyName } = selectedCompanyObj || {};
    const compDetails = {
      companyName,
      t,
    };
    let isSizeValid = false;
    if (!sizeId) {
      isSizeValid = true;
    } else {
      isSizeValid = false;
    }
    let isTypeValid = false;
    if (!companytypeId) {
      isTypeValid = true;
    } else {
      isTypeValid = false;
    }

    let isIndustryValid = false;
    if (!industryId) {
      isIndustryValid = true;
    } else {
      isIndustryValid = false;
    }

    let isCustomIndustryValid = false;
    if (industryId && Number(industryId) === 27 && !customIndustryName) {
      isCustomIndustryValid = true;
    } else {
      isCustomIndustryValid = false;
    }
    let isCustomCompTypeValid = false;
    if (companytypeId && Number(companytypeId) === 7 && !customCompanyType) {
      isCustomCompTypeValid = true;
    } else {
      isCustomCompTypeValid = false;
    }
    const validationRes = companyDetailsValid(compDetails);
    this.setState(
      {
        ...validationRes,
        isSizeValid,
        isTypeValid,
        isCustomCompTypeValid,
        isIndustryValid,
        isCustomIndustryValid,
      },
      () => {
        const {
          companyNameInvalid,
        } = this.state;
        if (companyNameInvalid !== true && isSizeValid !== true
          && isTypeValid !== true && isCustomCompTypeValid !== true
          && isIndustryValid !== true && isCustomIndustryValid !== true) {
          this.handleInstDetStsValidation(curr);
        }
      }
    );
  };

  onCreateCompany = com => {
    this.setState({
      isDropdownLoading: true,
    });
    if (!com) {
      return;
    }
    const selectedCompanyObj = { label: com, value: 1 };
    this.setState({
      selectedCompanyObj,
      disabled: false,
    });
  };

  onInputCompanyDropdown = value => {
    if (/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value)) {
      if (value && value.length >= 2) {
        const {
          history: { location: { pathname } = {} } = {},
          match: { params: { status } = {} } = {},
        } = this.props;
        let ctypeId = 0;
        if (status === 'company') {
          ctypeId = 1;
        } else {
          ctypeId = 2;
        }
        return this.props.xSearchCompanies(value, ctypeId).then(res => {
          if (res && Array.isArray(res)) {
            const options = res.map(one => ({
              label: one.companyName,
              value: one.companyId,
            }));
            return options;
          }
          return [{ label: 'X', value: -1 }];
        });
      }
    }
  };

  handleRedirectToBrowsePage = () => {
    const {
      history: { push } = {},
      match: { params: { status } = {} } = {},
    } = this.props;
    if (push) {
      push('/');
    }
  };

  handleRedirectToHome = () => {
    const { history: { push } = {} } = this.props;
    if (push) {
      push('/');
    }
  };

  handleValidateUEN = curr => {
    const { companyUEN, currStep } = this.state;
    const { t } = this.props;
    let { isCompanyUENValid, isCompanyUENValidMsg } = this.state;
    if (!companyUEN || companyUEN <= 0 || (companyUEN && companyUEN.toString() && (companyUEN.length < 9 || companyUEN.length > 11))) {
      isCompanyUENValid = true;
      isCompanyUENValidMsg = `${t('UENVAlidMsg')}`;
    } else {
      isCompanyUENValid = false;
      isCompanyUENValidMsg = '';
    }
    this.setState(
      {
        isCompanyUENValid,
        isCompanyUENValidMsg,
      },
      () => {
        if (this.state.isCompanyUENValid !== true) {
          const data = {
            companyUEN,
          };
          this.props.validateCompanyUEN(data).then(res => {
            if (res && !res.error) {
              if (res && res !== {} && Array.isArray(res) && res.length > 0 && res[0] && res[0].companyId) {
                this.setState({
                  currStep: 1,
                  hideCompanyDetTerms: true,
                  [`${0}-status`]: 'process',
                  [`${1}-status`]: 'process',
                  uenCompId: res[0] && res[0].companyId,
                });
              } else {
                this.setState({
                  currStep: currStep + 1,
                  [`${0}-status`]: 'process',
                  [`${1}-status`]: 'process',
                  hideCompanyDetTerms: false,
                });
              }
            }
          });
        }
      }
    );
  };

  onChange = current => {
    if (current !== null) {
      this.setState({ currStep: current }, () => {
        this.handleCompanyValidation(current);
        this.handleOfficeValidation(current);
        this.handleContactInfoValidation(current);
      });
    } else {
      const { currStep } = this.state;
      if (currStep === 0) {
        this.handleValidateUEN(null);
      } else if (currStep === 1) {
        this.handleCompanyValidation(null);
      } else if (currStep === 2) {
        this.handleContactInfoValidation(null);
      }
    }
  };

  handleSubmitUserDetails = () => {
    const { hideCompanyDetTerms } = this.state;
    this.handleContactInfoValidation(hideCompanyDetTerms);
  };

  handleJobStatusValidation = curr => {
    const {
      isSkillsValid,
      isValidJobDesc,
      isValidJobTitle,
      isFacultyValid,
      isInstitutionValid,
    } = this.state;
    let { currStep } = this.state;
    let currStepStatus = 'process';
    if (currStep === 1) {
      if (
        isSkillsValid === true ||
        isValidJobDesc === true ||
        isValidJobTitle === true ||
        isFacultyValid === true ||
        isInstitutionValid === true
      ) {
        currStepStatus = 'process';
      } else if (
        isSkillsValid !== true &&
        isValidJobDesc !== true &&
        isValidJobTitle !== true &&
        isFacultyValid !== true &&
        isInstitutionValid !== true &&
        curr === null
      ) {
        currStep += 1;
        currStepStatus = 'process';
      } else if (
        isSkillsValid !== true &&
        isValidJobDesc !== true &&
        isValidJobTitle !== true &&
        isFacultyValid !== true &&
        isInstitutionValid !== true &&
        curr !== null
      ) {
        currStepStatus = 'process';
      }
    }
    this.setState({
      [`${1}-status`]: currStepStatus,
      currStep,
    });
  };

  handleInstDetStsValidation = (curr) => {
    const {
      companyNameInvalid,
      isSizeValid,
      currStep,
      isTypeValid,
      isIndustryValid,
      isCustomIndustryValid,
    } = this.state;
    let currStepStatus = 'process';
    if (currStep === 0) {
      if (
        companyNameInvalid === true ||
        isSizeValid === true ||
        isTypeValid === true ||
        isIndustryValid === true ||
        isCustomIndustryValid === true
      ) {
        currStepStatus = 'process';
      } else if (
        companyNameInvalid !== true &&
        isSizeValid !== true &&
        isTypeValid !== true &&
        isIndustryValid !== true &&
        isCustomIndustryValid !== true &&
        curr === null
      ) {
        currStepStatus = 'process';
      } else if (
        companyNameInvalid !== true &&
        isSizeValid !== true &&
        isTypeValid !== true &&
        isIndustryValid !== true &&
        isCustomIndustryValid !== true &&
        curr !== null
      ) {
        currStepStatus = 'process';
      }
    }
    this.setState(
      {
        [`${0}-status`]: currStepStatus,
      },
      () => {
        this.handleOfficeValidation(curr);
      }
    );
  };

  onInputDropdownChange = (value) => {
    if (value) {
      this.setState(
        {
          showDropDownLoading: true,
        },
        () => {
          this.props.getLocations(value).then((res) => {
            if (res && !res.error) {
              const { locations } = this.props;
              const values = {
                locations,
              };
              const locationsArr = getLocationsDetails(values);
              this.setState({
                locationsArr,
                showDropDownLoading: false,
              });
            }
          });
        }
      );
    }
  };

  handleRedirectToLogin = () => {
    const { history: { push } = {} } = this.props;
    if (push) {
      push('/');
    }
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState(
      {
        [name]: metaValue,
      },
      () => {
        const { location, companyVals } = this.state;
        let country = null;
        if (name === 'location' && location) {
          const { countryId } = location;
          if (countryId) {
            country = countryId;
          }
          this.setState({
            country,
          });
        }
        if (name === 'companyVals' && companyVals && companyVals.value) {
          const {
            companyUEN,
          } = companyVals || {};
          this.setState({
            companyUEN,
          });
        }
      }
    );
  };

  handleLangugaeModal = () => {
    const { showLanguageModal } = this.state;
    this.setState({
      showLanguageModal: !showLanguageModal,
    });
  };

  onCreateOptionClick = (name, value) => {
    if (value && typeof value === 'string' && value.trimLeft().length === 0)
      return;
    if (value && typeof value === 'string') value = value.trimLeft();
    const newSkill = {
      label: value,
      value: 1,
    };
    let { skills } = this.state;
    if (!skills || (skills && skills.length <= 0)) {
      skills = [newSkill];
    } else if (skills && skills.length > 0) {
      skills.push(newSkill);
    }
    this.setState({
      [name]: skills,
      skillAdded: true,
      displayMessage: true,
      success: true,
      message: 'New skill created successfully!',
    });
    window.setTimeout(() => {
      this.setState({
        message: '',
        displayMessage: false,
      });
    }, 2000);
  };

  onIndustriesInputChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) {
      this.handleIndustriesdata();
      return;
    }

    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.props.getCompanyIndustries(value).then((res) => {
        const options = Array.isArray(res) && res.map(one => ({ label: one.industryName, value: one.industryId }));
        this.setState({
          industriesArr: options || [],
        });
        if (cb && typeof cb === 'function') cb(options || []);
      });
    }
  }

  handleCheckBoxChange = () => {
    const { isTermChecked } = this.state;
    const stateProps={};
    if(!isTermChecked){
      stateProps.showTermsErr=false
    }
    stateProps.isTermChecked=!isTermChecked;
    this.setState({...stateProps})
  };

  handleOnBackSteps = () => {
    const {
      currStep,
    } = this.state;
    this.setState({
      currStep: currStep - 1,
    });
  }

  handleLogin = () => {
    const { history: { push } = {} } = this.props;
    if(push) {
      push('/public/company/login')
    }
  }

  generateUEN = () => {
    const companyUEN = Math.round(Math.random() * 10000000000);
    this.setState({ companyUEN, disableUEN:true });
  }

  render() {
    const {
      companyNameInvalid,
      isDropdownLoading,
      showRegForm,
      isEmailValid,
      isEmailValidText,
      isFirstNameValid,
      isFirstNameValidText,
      isLastNameValid,
      isLastNameValidText,
      companyName,
      isPhoneNumberValid,
      isPhoneNumberValidText,
      isValidCountryCode,
      isValidCountryCodeValue,
      countryCode,
      phoneNumber,
      isValidDesignationVal,
      isValidDesignation,
      designation,
      email,
      firstname,
      lastname,
      website,
      compDesc,
      selectedCompanyObj,
      addressLine1,
      addressLine2,
      zipcode,
      country,
      city,
      officeName,
      isValidAddrLine1,
      isValidAddrLine1Text,
      isValidCity,
      isValidCityText,
      isValidCountry,
      isValidCountryText,
      isValidOfficeName,
      isValidOfficeNameText,
      list,
      isValidZipcode,
      isValidZipcodeText,
      disabled,
      currStep,
      showLanguageModal,
      sizeVal,
      isSizeValid,
      companySizeArr,
      locationsArr,
      location,
      showError,
      skills,
      isSkillsValid,
      jobTitle,
      jobDescription,
      isValidJobTitle,
      isValidJobDesc,
      institutionVal,
      institutions,
      isInstitutionValid,
      isFacultyValid,
      facultyArr,
      facultyVal,
      isTermChecked,
      showTermsErr,
      companyUEN,
      isCompanyUENValidMsg,
      isCompanyUENValid,
      showDropDownLoading,
      hideCompanyDetTerms,
      termsConditions,
      companyTypesArr,
      companyTypeVal,
      isTypeValid,
      saluationListArr,
      isSaluationValid,
      saluationVal,
      customCompanyType,
      isCustomCompTypeValid,
      isIndustryValid,
      industriesArr,
      industryVal,
      customIndustryName,
      isCustomIndustryValid,
      companyArr,
      companyVals,
      showDBErrMsg,
      isPrivacyPolicyChecked,
      showPrivacyPolicyErr,
      disableUEN,
    } = this.state;
    const {
      history: { location: { pathname } = {} } = {},
      countries,
      t,
      match: { params: { status } = {} } = {},
      loading,
      noPublicHeader,
    } = this.props;
    const currRoute = pathname && pathname.split('/app/campus/');
    const companyErrorMsg = `${t('companyCreatedErrMsg')}`;
    const instErrorMsg = `${t('instCreatedErrMsg')}`;
    let stepsArr = [];
    if (hideCompanyDetTerms === false) {
      stepsArr = [
        'UEN',
        `${t('details')}`,
        `${t('contact')}`,
        `${t('termsOfUse')}`,
      ];
    } else if (hideCompanyDetTerms === true) {
      stepsArr = ['UEN', `${t('contact')}`];
    }

    return (
      <Fragment>
        {!noPublicHeader && <PublicHeader
          handleRedirectToLogin={this.handleRedirectToLogin}
          showLanguageModal={showLanguageModal}
          handleLangugaeModal={this.handleLangugaeModal}
        />}
        <div className="main-page-alignment reg-page bx--tile">
          {showRegForm === true && (
            <div className="mt-3 p-5">
              <WizardSteps
                header={`${status === 'institution' ? t('institution') : t('company')} ${t('registration')}`}
                handleRedirectToHome={this.handleRedirectToHome}
                currStep={currStep}
                hideBtnValues
                hideCompanyDetTerms={hideCompanyDetTerms}
                onChange={this.onChange}
                handleFinalStep={this.handleFinalStep}
                handleSubmitUserDetails={this.handleSubmitUserDetails}
                state={this.state}
                stepsArr={stepsArr}
                handleOnBackSteps={this.handleOnBackSteps}
              />
              {currStep === 0 && (
                <div>
                  <div className="mt-2 mb-1 bx--type-zeta">
                    UEN is the standard identification number of an entity. Please visit <a target="_blank" href="https://www.uen.gov.sg/">https://www.uen.gov.sg/</a> for more information.
                  </div>
                  <div className="mt-2">
                    <TextInput
                      className="mb-2"
                      name="companyUEN"
                      id="companyUEN"
                      value={companyUEN}
                      disabled={disabled || disableUEN}
                      labelText={t('companyUEN')}
                      placeholder="2020012345N"
                      invalidText={isCompanyUENValidMsg}
                      invalid={isCompanyUENValid}
                      onChange={e => this.handleChange(e)}
                    />
                    <div>
                      <span>
                        Don't have UEN? {' '}
                        <a href="javascript:void(0);" onClick={this.generateUEN}>
                          Generate UEN
                        </a>
                      </span>
                      {/* <Button size="sm" kind="secondary" onClick={this.generateUEN}>Generate UEN</Button> */}
                    </div>
                  </div>
                  <div className="mb-2 mt-2 font-weight-bold">
                    OR
                  </div>
                  <div className="mt-2 mb-2 text-dark">
                    You may also select from the below dropdown list for the company’s name if your company has already registered before.
                  </div>
                  <div className="mb-2">
                    <div className="bx--label">
                      {t('select')} {t('company')}
                    </div>
                    <DropDownBox
                      title=""
                      mainClassName="bx--form-item mb-0"
                      titleClass=""
                      labelClass=""
                      required
                      disabled={disabled}
                      custom
                      className="w-100"
                      options={companyArr}
                      placeholder={t('company')}
                      onChange={(value, e, name) =>
                        this.handleDropdownChange(value, e, name)
                      }
                      name="companyVals"
                      selectedValue={companyVals}
                    />
                  </div>
                </div>
              )}
              {currStep === 1 && hideCompanyDetTerms === false && (
                <div className="xs-device lg-device sm-device large-device x-lg-device mt-3">
                  {disabled === true && (
                    <InlineNotification
                      lowContrast
                      kind="error"
                      title=""
                      subtitle={
                        status === 'institution'
                          ? `${instErrorMsg}`
                          : `${companyErrorMsg}`
                      }
                      onCloseButtonClick={() => {
                        this.setState({ disabled: true });
                      }}
                    />
                  )}
                  <div className="mb-2 mt-2 h6">{t('companyInfo')}</div>
                  <hr />
                  {/* <div className={disabled === true ? 'xpa-disable-cursor' : ''}> */}
                  {/* <label htmlFor="companyName" className="bx--label">{status === 'institution' ? <span>{t('institution')} {t('name')}</span> : <span>{t('company')} {t('name')}</span>}</label> */}
                  <TextInput
                    className="mb-2"
                    name="companyName"
                    id="companyName"
                    value={companyName}
                    disabled={disabled}
                    labelText={
                      status === 'institution' ? (
                        <span>
                          {t('institution')} {t('name')}
                        </span>
                      ) : (
                        <span>
                          {t('company')} {t('name')}
                        </span>
                      )
                    }
                    placeholder="Select company"
                    onChange={e => this.handleChange(e)}
                    invalid={companyNameInvalid}
                    invalidText={t('companyNameValid')}
                  />

                  {/* <AsyncCreatableSelect
                          id="companyName"
                          name="companyName"
                          className={companyNameInvalid ? 'xpa-rs-wrap invalid mb-2' : 'xpa-rs-wrap mb-2'}
                          classNamePrefix="xrs"
                          placeholder="Select company"
                          // isDisabled={disabled}
                          isClearable
                          selectedValue={companyName}
                          defaultOptions={list}
                          noOptionsMessage={({ inputValue }) => !inputValue && 'Start typing to search companies'}
                          loadOptions={debounce(this.onInputCompanyDropdown, 150)}
                          onCreateOption={this.onCreateCompany}
                          onChange={this.handleChangeCompany}
                          value={selectedCompanyObj}
                        />
                        {
                          companyNameInvalid === true && (
                            <div className="invalid-msg">{status === 'institution' ? <span>{t('instNameValid')}</span> : <span>{t('companyNameValid')}</span>}</div>
                          )
                        } */}
                  {/* </div> */}
                  <div className="mb-2">
                    <div className="bx--label">
                      {status === 'institution'
                        ? `${t('institution')} ${t('size')}`
                        : `${t('company')} ${t('size')}`}
                    </div>
                    <DropDownBox
                      title=""
                      mainClassName="bx--form-item mb-0"
                      titleClass=""
                      labelClass=""
                      required
                      disabled={disabled}
                      custom
                      className={`w-100 ${isSizeValid ? 'invalid' : ''}`}
                      options={companySizeArr}
                      placeholder="Size"
                      onChange={(value, e, name) =>
                        this.handleDropdownChange(value, e, name)
                      }
                      name="sizeVal"
                      selectedValue={sizeVal}
                    />
                    {isSizeValid === true && (
                      <div className="invalid-msg">{t('commonValidMsg')}</div>
                    )}
                  </div>
                  <div className="mb-2">
                    <div className="bx--label">
                      Industry
                    </div>
                    <DropDownBox
                      title=""
                      mainClassName="bx--form-item mb-0"
                      titleClass=""
                      labelClass=""
                      required
                      onInputChange={this.onIndustriesInputChange}
                      disabled={disabled}
                      custom
                      className={`w-100 ${isIndustryValid ? 'invalid' : ''}`}
                      options={industriesArr}
                      placeholder="Sector"
                      onChange={(value, e, name) =>
                        this.handleDropdownChange(value, e, name)
                      }
                      name="industryVal"
                      selectedValue={industryVal}
                    />
                    {isIndustryValid === true && (
                      <div className="invalid-msg">{t('commonValidMsg')}</div>
                    )}
                  </div>
                  {
                    industryVal && industryVal.label === 'Others'
                      && (
                        <div className="mt-2">
                          <TextInput
                            className="mb-2"
                            name="customIndustryName"
                            id="customIndustryName"
                            value={customIndustryName}
                            disabled={disabled}
                            invalid={isCustomIndustryValid}
                            labelText={t('workShift')}
                            placeholder={t('workShift')}
                            onChange={e => this.handleChange(e)}
                            invalidText={t('commonValidMsg')}
                          />
                        </div>
                      )
                  }
                  <div className="mb-2">
                    <div className="bx--label">Company Type</div>
                    <DropDownBox
                      title=""
                      mainClassName="bx--form-item mb-0"
                      titleClass=""
                      labelClass=""
                      required
                      disabled={disabled}
                      custom
                      className={`w-100 ${isTypeValid ? 'invalid' : ''}`}
                      options={companyTypesArr}
                      placeholder={t('type')}
                      onChange={(value, e, name) =>
                        this.handleDropdownChange(value, e, name)
                      }
                      name="companyTypeVal"
                      selectedValue={companyTypeVal}
                    />
                    {isTypeValid === true && (
                      <div className="invalid-msg">{t('commonValidMsg')}</div>
                    )}
                  </div>
                  {companyTypeVal &&
                    companyTypeVal.value &&
                    Number(companyTypeVal.value) === 7 && (
                      <div className="mt-2">
                        <TextInput
                          className="mb-2"
                          name="customCompanyType"
                          id="customCompanyType"
                          value={customCompanyType}
                          disabled={disabled}
                          invalid={isCustomCompTypeValid}
                          labelText={t('customCompanyType')}
                          placeholder={t('customCompanyType')}
                          onChange={e => this.handleChange(e)}
                          invalidText={t('commonValidMsg')}
                        />
                      </div>
                    )}
                  <div>
                    <TextArea
                      className="some-class"
                      id="test2"
                      name="compDesc"
                      value={compDesc}
                      labelText={t('desc')}
                      placeholder={t('desc')}
                      disabled={disabled}
                      hideLabel={false}
                      onChange={e => this.handleChange(e)}
                    />
                  </div>
                  <div className="mt-2">
                    <TextInput
                      className="mb-2"
                      name="website"
                      id="website"
                      value={website}
                      disabled={disabled}
                      labelText={t('website')}
                      placeholder={t('websitePlaceholder')}
                      onChange={e => this.handleChange(e)}
                    />
                  </div>
                  <div className="h6 mb-2 mt-2">{t('ofcAddr')}</div>
                  <hr />
                  <OfficeDetails
                    countries={countries}
                    handleChange={this.handleChange}
                    city={city}
                    zipcode={zipcode}
                    showOfcInput={false}
                    officeName={officeName}
                    addressLine1={addressLine1}
                    addressLine2={addressLine2}
                    country={country}
                    showDropDownLoading={showDropDownLoading}
                    isValidAddrLine1={isValidAddrLine1}
                    isValidAddrLine1Text={isValidAddrLine1Text}
                    isValidCity={isValidCity}
                    isValidCityText={isValidCityText}
                    isValidCountry={isValidCountry}
                    isValidCountryText={isValidCountryText}
                    isValidOfficeName={isValidOfficeName}
                    isValidOfficeNameText={isValidOfficeNameText}
                    isValidZipcode={isValidZipcode}
                    isValidZipcodeText={isValidZipcodeText}
                    disabled={disabled}
                    location={location}
                    handleDropdownChange={this.handleDropdownChange}
                    onInputDropdownChange={this.onInputDropdownChange}
                    locationsArr={locationsArr}
                  />
                </div>
              )}
              {(currStep === 2 ||
                (hideCompanyDetTerms === true && currStep === 1)) && (
                <div className="xs-device sm-device lg-device x-lg-device large-device">
                  {showError === true && (
                    <InlineNotification
                      lowContrast
                      kind="error"
                      title=""
                      subtitle={t('processingError')}
                      onCloseButtonClick={() => {
                        this.setState({ showError: false });
                      }}
                    />
                  )}
                  {hideCompanyDetTerms === true && (
                    <InlineNotification
                      lowContrast
                      kind="info"
                      title=""
                      subtitle={t('uenRegisteredMsg')}
                      onCloseButtonClick={() => {}}
                    />
                  )}
                  {this.state.showErrNotif && (
                    <InlineNotification
                      lowContrast
                      kind={this.state.errType}
                      title=""
                      subtitle={this.state.errMsg}
                      onCloseButtonClick={() => {}}
                    />
                  )}
                  <div className="h6 mb-2 mt-2">{t('contactInfoHeading')}</div>
                  <hr />
                  <ContactDetails
                    firstname={firstname}
                    lastname={lastname}
                    showSecondaryPhone={false}
                    disabled={disabled}
                    showPhoneDetails
                    isFirstNameValid={isFirstNameValid}
                    isFirstNameValidText={isFirstNameValidText}
                    handleChange={this.handleChange}
                    t={t}
                    saluationListArr={saluationListArr}
                    handleDropdownChange={this.handleDropdownChange}
                    isLastNameValid={isLastNameValid}
                    isSaluationValid={isSaluationValid}
                    saluationVal={saluationVal}
                    isLastNameValidText={isLastNameValidText}
                    isEmailValid={isEmailValid}
                    email={email}
                    isEmailValidText={isEmailValidText}
                    countryCode={countryCode}
                    isValidCountryCode={isValidCountryCode}
                    countries={countries}
                    isValidCountryCodeValue={isValidCountryCodeValue}
                    phoneNumber={phoneNumber}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isPhoneNumberValidText={isPhoneNumberValidText}
                    status={status}
                    designation={designation}
                    isValidDesignation={isValidDesignation}
                    isValidDesignationVal={isValidDesignationVal}
                  />
                </div>
              )}
              {currStep === 3 && hideCompanyDetTerms === false && (
                <div>
                  {/* {showTermsErr === true && (
                    <InlineNotification
                      lowContrast
                      kind="error"
                      title=""
                      subtitle={t('errMsgTerms')}
                      onCloseButtonClick={() => {
                        this.setState({ showTermsErr: false });
                      }}
                    />
                  )} */}
                  <div className="h6 mb-2 mt-3">{t('termsOfUse')}</div>
                  <div className="text-justify">
                    <TextArea
                      className="some-class"
                      id="termsConditions"
                      name="termsConditions"
                      value={termsConditions}
                      labelText={t('desc')}
                      placeholder={t('termsOfUse')}
                      hideLabel
                      rows={23}
                      disabled
                      onChange={e => this.handleChange(e)}
                    />
                  </div>
                  <div className="xpa-customize-checkbox">
                    <Checkbox
                      name="isTermChecked"
                      id="isTermChecked"
                      checked={isTermChecked}
                      onChange={this.handleCheckBoxChange}
                      labelText={t('acceptAllConditions')}
                    />
                    {
                      showTermsErr === true && (
                        <div className="invalid-msg">
                          {t('errMsgTerms')}
                        </div>
                      )
                    }
                  </div>
                  
                  <div className="mt-4 xpa-customize-checkbox">
                    <Checkbox
                      id='acceptance'
                      name='accptance'
                      checked={isPrivacyPolicyChecked}
                      onChange={(e)=>{
                        const stateProps={};
                        stateProps.isPrivacyPolicyChecked=e;
                        if(e===true){
                          stateProps.showPrivacyPolicyErr=false
                        }
                        this.setState({...stateProps})
                      }}
                      labelText={<span>By ticking this, you agree to the <a href="https://x0pa.com/privacy-policy/" target="_blank">privacy policy</a> of X0PA.</span>}
                    />
                    {
                      showPrivacyPolicyErr === true && (
                        <div className="invalid-msg">
                          {t('errMsgPrivacyPolicy')}
                        </div>
                      )
                    }
                  </div>
                </div>
              )}
            </div>
          )}
          {
            showRegForm === true && (
              <div className="d-flex justify-content-center mt-4">
                <Button kind="secondary" onClick={this.handleRedirectToHome}>
                  {t('cancel')}
                </Button>
                {
                  currStep !== 0 && (
                    <Button
                      kind="tertiary"
                      className="ml-2"
                      onClick={this.handleOnBackSteps}>
                      Back
                    </Button>
                  )
                }
                {
                  ((currStep !== 3 && hideCompanyDetTerms !== true) || (currStep !== 1 && hideCompanyDetTerms === true)) && (
                    <Button
                      kind="primary"
                      className="ml-2"
                      onClick={() => this.onChange(null)}>
                      {t('next')}
                    </Button>
                  )
                }
                {
                  currStep === 3 && hideCompanyDetTerms !== true && (
                    <Button
                      kind="primary"
                      className="ml-lg-2 ml-md-2 mt-2 mt-lg-0 mt-md-0"
                      onClick={this.handleFinalStep}>
                      {t('submit')}
                    </Button>
                  )
                }
                {currStep === 1 && hideCompanyDetTerms === true && (
                <Button
                  kind="primary"
                  className="ml-2"
                  onClick={this.handleSubmitUserDetails}>
                  {t('submit')}
                </Button>
              )}
              </div>
            )
          }
          {showRegForm === false && (
            <div className="mt-5 p-5 text-center">
              <div className="">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                  style={{ maxWidth: 100 }}>
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </div>
              
              {hideCompanyDetTerms === false ? (
                <div className="h4 mt-2 mb-2">{t('regMsg')}</div>
              ) : (
                <div className="h4 mt-2 mb-2">{t('regMsg')}</div>
              )}

              <div className="">
                <Button
                  kind="primary"
                  small
                  onClick={this.handleLogin}>
                  {t('login')}
                </Button>
              </div>
            </div>
          )}

          {showRegForm === null && (
            <div className="mt-5 p-5 text-center">
              <div className="">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                  style={{ maxWidth: 100 }}>
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </div>

              {
                hideCompanyDetTerms === null && showDBErrMsg ===true && (
                  <div className="h4 mt-2 mb-2">{t('errMsg')}</div>
                )
              }

              <div className="">
                <Button
                  kind="primary"
                  small
                  onClick={this.handleRedirectToBrowsePage}>
                  {t('goToHomePage')}
                </Button>
              </div>
            </div>
          )}
        </div>
        <JDProcessOverlay show={loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  countries: state.companyAndOffice.countries,
  companySize: state.CompanyRegistration.companySize,
  locations: state.RegistrationRequest.locations,
  loading: state.CompanyRegistration.loading,
  saluationList: state.CompanyRegistration.saluationList,
  industriesList: state.CompanyRegistration.industriesList,
  companyTypes: state.CompanyRegistration.companyTypes,
});

const mapDispatchToProps = {
  xSearchCompanies,
  xCreateCompany,
  getAllCountries,
  createInstitutionUser,
  createCompanyUser,
  getCompanySizes,
  getLocations,
  getAllFaculties,
  validateCompanyUEN,
  getCompanyTypes,
  createUserForACompany,
  getSaluationList,
  getCompanyIndustries,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CompanyRegistration));
