import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getJobApprovalList,
  createJobApprovalList,
  deleteJobApprovalList,
  editJobApprovalList,
  updateJobApprovalList
} from 'actions/talentSearch/JobApprovals';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import {
  Modal,
  Button,
  Select,
  SelectItem,
  Tile,
  TextInput,
  InlineNotification,
  Checkbox,
  Toggle,
  Tag
} from 'carbon-components-react';
import {
  getJobInfo,
  updateJobReject
} from 'actions/talentSearch/jobList';
import {
  getAllFaculties,
} from 'actions/candidate/CandidateDetails';
import {
  getAuthorizedUsers,
} from 'actions/job';
import {
  handleSelectAllFacultyCourses,
} from 'containers/common/FacultyCheckboxSelectionClearfns';
import Avatar from 'components/common/Avatar';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  editJob,
} from 'actions/talentSearch/newJob';
import FacultyCoursesSelection from 'components/roboroy/FacultyCoursesSelection';
import './editJob';
import {
  upsertJobBatchActions,
  updateJobApprovedVacancies
} from 'actions/talentSearch/jobList';
import _ from 'lodash';

const NoContentBox = ({
  t,
}) => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">{t('noUserMsg')}</p>
    </div>
  </Tile>
);

class JobApprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsData: [],
      selectedUserId: 0,
      isOpenAddApprovalsModal: false,
      isOpenApproveJobModal: false,
      isOpenRejectJobModal: false,
      vacancies: null,
      approvedVacancies: null,
      vacancyLoader: false,
      showNotifInfo: false,
      jobRejected: false,
      jobRejectModal: false,
      // showButton: false
    };
  }

  componentDidMount() {
    // const { jobId } = this.props;
    // this.props.getAuthorizedUsers();
    // this.props.getJobApprovalList(jobId).then(() => {

    // });
    const facultyData = {
      facultyActivePage: 1,
      facultyItemsPerPage: 1000,
      facultySearchKey: null,
      schoolId: 1,
    };
    this.setState({
      showLoader: true,
    }, () => {
      this.props.getAllFaculties(facultyData).then((res) => {
        if (res && !res.error) {
          // this.setRows();
          const {
            job: {
              // approvedVacancies,
              facultyApprovedVacancies,
              jobcoursemap,
              jobfacultymap,
            } = {},
            user: {
              roleId,
              facultyId,
              courseAdmin,
              company: {
                ctypeId
              } = {},
              isSuperAdmin,
              isAdmin,
              courseId,
            } = {},
          } = this.props;
          if(facultyApprovedVacancies && Array.isArray(facultyApprovedVacancies) && facultyApprovedVacancies.length >0){
            facultyApprovedVacancies.forEach((ap) => {
              let apFId = ap.facultyId
              if(apFId == facultyId){
                this.setState({
                  approvedVacancies: ap.facultyApprovedVacancies,
                  approvesFacultyId: ap.facultyId
                })
              }
            })
          }
          this.setRows();

          const jobCourseData = {};
          const jobCourseMapData = {};
          const jobFaculties = {};
          if (jobcoursemap && Array.isArray(jobcoursemap) && jobcoursemap.length > 0) {

            jobcoursemap.forEach((crs) => {
              const {
                facultyId,
                courseId,
              } = crs || {};
              if (jobCourseData[`courses-${facultyId}`]) {
                jobCourseData[`courses-${facultyId}`].push(courseId);
              } else {
                jobCourseData[`courses-${facultyId}`] = [courseId];
              }
              if (jobCourseMapData[facultyId]) {
                jobCourseMapData[facultyId].courses.push(courseId);
              } else {
                jobCourseMapData[facultyId] = {
                  courses: [courseId],
                };
              }
              jobFaculties[`faculty-${facultyId}`] = facultyId;
            });
          }
          if (jobfacultymap && Array.isArray(jobfacultymap) && jobfacultymap.length > 0
            && jobcoursemap && Array.isArray(jobcoursemap) && jobcoursemap.length <= 0) {
            jobfacultymap.forEach((facs) => {
              const {
                facultyId,
              } = facs || {};
              jobFaculties[`faculty-${facultyId}`] = facultyId;
            });
          }
          const courseMapKeys = jobCourseMapData && Object.keys(jobCourseMapData);
          let finalCourseMapData = [];
          if (courseMapKeys && Array.isArray(courseMapKeys) && courseMapKeys.length > 0) {
            finalCourseMapData = courseMapKeys.map((courses) => {
              const coursData = {
                facultyId: courses,
                courses: jobCourseMapData && jobCourseMapData[courses] && jobCourseMapData[courses].courses,
              };
              return coursData;
            });
          }
          this.setState({
            coursemap: finalCourseMapData,
            ...jobCourseData,
            ...jobFaculties,
            showLoader: false,
            // approvedVacancies
          });
        }
      });
    });
  }

  handleSendJobApiParams = () => {
    const {
      job,
      approvalList = [],
    } = this.props;
    const {
      recuiterCompanyId,
      recruiterId,
      companyId,
      jobname: {
        nameName,
      } = {},
      internalCode,
      institutionId,
      cities: {
        cityId,
      } = {},
      industryId,
      office: {
        officeId,
      } = {},
      workdayId,
      customShift,
      additionalComments,
      skills,
      termId,
      functionId,
      maxSalary,
      currencyId,
      vacancy,
      closeDate,
      jobDesc,
      jobReq,
      jobId,
      teamId,
      countryId,
      jobdocuments,
      jobjd,
    } = job || {};
    const data = {
      state: true,
      fromResume: false,
      draft: false,
      // companyId: recuiterCompanyId,
      companyId: companyId,
      recruiterId,
      title: nameName,
      internalCode,
      isPrivate: false,
      institutionId,
      cityId,
      countryId,
      industryId,
      officeId,
      workdayId,
      customShift,
      additionalComments,
      termId,
      functionId,
      maxSalary,
      currencyId,
      vacancy,
      closeDate,
      jobDesc,
      jobReq,
      jobId: jobId && Number(jobId),
      teamId,
      country: { countryId },
      jobDocuments: jobdocuments,
    };
    if (jobjd && jobjd.jobDocId) {
      data.jobJDArr = [jobjd];
    }
    if (skills && Array.isArray(skills) && skills.length > 0) {
      data.skillsmusthave = skills.map((res) => {
        const {
          skill: {
            skillName,
          } = {},
        } = res || {};
        const skData = {
          skill: skillName,
          typeId: 1,
        };
        return skData;
      });
    }
    return data;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activeApprovalModal = () => {
    this.setState({
      isOpenAddApprovalsModal: true,
    });
  }

  dismissApprovalModal = () => {
    this.setState({
      isOpenAddApprovalsModal: false,
    });
  }

  setRows = () => {
    let { approvalList = [], jobId, t, user, job } = this.props;
    const { /*approvedVacancies,*/ facultyApprovedVacancies } = job || {}
    const { approvedVacancies, approvesFacultyId } = this.state;
    if (approvedVacancies == null || approvedVacancies <= 0) {
      this.setState({
        showButton: true
      })
    }
    const {
      facultyId,
      courseId,
      isSuperAdmin,
      courseAdmin,
    } = user || {};
    const {
      isPrimaryCourseAdmin
    } = this.state
    let rejectCount = []
    let approvalLength = approvalList && approvalList.length

    if(isSuperAdmin){
      let facultyMapObj = {};

      if(facultyApprovedVacancies && Array.isArray(facultyApprovedVacancies) && facultyApprovedVacancies.length > 0){
        facultyApprovedVacancies.forEach(hs => {
          const { facultyId, facultyApprovedVacancies} = hs || {};
          facultyMapObj[`${facultyId}`] = { approvedFacultyId: facultyId, appVacancies: facultyApprovedVacancies }
        })
      }

      if(approvalList && Array.isArray(approvalList) && approvalList.length > 0){
        approvalList = approvalList.map((res) => {
          const { facultyId: approvalFacultyId } = res || {};
          if(facultyMapObj[`${approvalFacultyId}`]){
            const { approvedFacultyId, appVacancies } = facultyMapObj[`${approvalFacultyId}`];
            res.appVacancies = appVacancies
          }
          return res
        })
      }
    } 

    if (jobId) {
      let filteredData = approvalList && Array.isArray(approvalList)
        && approvalList.length > 0 && approvalList.map((res) => {
          const {
            appVacancies,
            isApproved,
            jobApproveId,
            vacancies,
            facultyId: apprFacultyId,
            courseId: apprCourseId,
            isPrimaryCourseAdmin: appIsPrimaryCourseAdmin,
            faculty: {
              facultyName,
            } = {},
            courses: {
              courseName
            } = {},
          } = res;
          if (appIsPrimaryCourseAdmin) {
            this.setState({
              isPrimaryCourseAdmin: apprCourseId
            })
          }
          if (!courseName) {
            return {}
          }
          if (res && res.isApproved !== null && res.isApproved == false) {
            let isApproveFlag = res && res.isApproved
            rejectCount.push(isApproveFlag)
          }

          if (rejectCount.length === approvalList.length - 1) {
            this.setState({
              showErrMsg: true
            })
          }
          else {
            this.setState({
              showErrMsg: false
            })
          }
          return {
            id: `list_${jobApproveId}`,
            name: (
              <div className="bx--type-zeta text-capitalize">
                {facultyName}
              </div>
            ),
            coursename: (
              <div className="bx--type-zeta text-capitalize">
                {courseName}
              </div>
            ),
            primarycourseadmin: (
              <div>
                <Toggle
                  className="mb-0"
                  id={apprCourseId}
                  toggled={this.state.isPrimaryCourseAdmin == apprCourseId}
                  labelA='No'
                  labelB='Yes'
                  disabled={courseAdmin ||(isSuperAdmin ? '' : apprFacultyId != facultyId)}
                  onToggle={(e) => this.onAdminToggleClick(e, apprFacultyId, apprCourseId, jobApproveId)}
                />
              </div>
            ),
            // email: `${email}`,
            status: (
              <div>
                <span className="d-lg-none d-md-none bx--type-zeta pr-2">Status:</span>
                { isApproved === null && <span className="bx--tag bx--tag--cool-gray">{t('approvalStatusMsg1')}</span>}
                { isApproved === true && <span className="bx--tag bx--tag--green">{t('accepted')}</span>}
                { isApproved === false && <span className="bx--tag bx--tag--red">{t('rejected')}</span>}
              </div>
            ),
            vacancies: (
              <div>
                <div className="w-50 text-center d-none d-lg-block d-md-block">
                  {vacancies ? vacancies : 0}
                </div>
                <div className="d-lg-none d-md-none">
                  <span className="bx--type-zeta pr-2">No of Approved Vacancies:</span> <span>{vacancies ? vacancies : 0}</span>
                </div>
              </div>
            ),
            action: (
              <div>
                {
                  (isApproved === null && (apprFacultyId == facultyId || isSuperAdmin === true)) && (
                    <div className="d-flex">
                      <div>
                        <Button
                          kind="primary"
                          size="small"
                          disabled={(courseAdmin === true && apprCourseId != courseId || this.state.showButton == true || appVacancies == 0)}
                          onClick={() => this.activeApproveJobModal(apprFacultyId, null, jobApproveId)}
                        >
                          {t('accept')}
                        </Button>
                      </div>
                      <div className="ml-2">
                        <Button
                          kind="danger"
                          size="small"
                          disabled={(courseAdmin === true && apprCourseId != courseId || this.state.showButton == true || appVacancies == 0)}
                          onClick={() => this.activeRejectJobModal(apprFacultyId, jobApproveId)}
                        >
                          {t('reject')}
                        </Button>
                      </div>
                    </div>
                  )
                }
                <div className="d-flex">
                  {
                    isApproved !== null && (isSuperAdmin === true || apprFacultyId == facultyId) && (
                      <div>
                        <Button
                          kind="tertiary"
                          size="small"
                          disabled={(courseAdmin === true && apprCourseId != courseId)}
                          onClick={() => this.activeApproveJobModal(apprFacultyId, vacancies, jobApproveId)}
                        >
                          {t('edit')}
                        </Button>
                      </div>
                    )
                  }
                  {
                    isApproved === true && (isSuperAdmin === true || apprFacultyId == facultyId) && (
                      <div className="ml-2">
                        <Button
                          kind="danger"
                          size="small"
                          disabled={(courseAdmin === true && apprCourseId != courseId)}
                          onClick={() => this.activeRejectJobModal(apprFacultyId, jobApproveId)}
                        >
                          {t('reject')}
                        </Button>
                      </div>
                    )
                  }
                </div>
              </div>
            ),
          };
        });
      filteredData = filteredData && Array.isArray(filteredData) ? filteredData : [];
      this.setState({
        rowsData: filteredData,
      });
    }
  }

  onAdminToggleClick = (value, apprFacultyId, courseId, jobApproveId) => {
    const {
      jobId,
      user
    } = this.props
    if (value) {
      this.setState({
        isPrimaryCourseAdmin: courseId
      }, () => {
        this.props.updateJobApprovalList(jobId && Number(jobId), apprFacultyId, jobApproveId, this.state.isPrimaryCourseAdmin).then((res) => {
          if (res && !res.error) {
            this.props.getJobApprovalList(jobId).then((res) => {
              this.setRows();
            });
          }
        })
      })
    }
    else {
      this.setState({
        isPrimaryCourseAdmin: null
      }, () => {
        this.setRows();
      })

    }
  }

  activeApproveJobModal = (facultyId, vacancies, jobApproveId) => {
    this.setState({
      isOpenApproveJobModal: true,
      facultyId,
      vacancies,
      approveId: jobApproveId
    });
  }

  dismissApproveJobMpdal = () => {
    this.setState({
      isOpenApproveJobModal: false,
      facultyId: null,
    });
  }

  activeRejectJobModal = (facultyId, jobApproveId) => {
    this.setState({
      isOpenRejectJobModal: true,
      facultyId,
      approveId: jobApproveId
    });
  }

  dismissRejectJobMpdal = () => {
    this.setState({
      isOpenRejectJobModal: false,
      facultyId: null,
    });
  }

  activeJobRejectModal = () => {
    this.setState({
      jobRejectModal: true,
    });
  }

  dismissJobRejectModal = () => {
    this.setState({
      jobRejectModal: false,
    })
  }


  handleDeleteApprovedUsers = (jobApproveId) => {
    const { jobId } = this.props;
    this.props.deleteJobApprovalList(jobId, jobApproveId).then(() => {
      this.props.getJobApprovalList(jobId).then(() => {
        this.setRows();
      });
    });
  }

  getCompanyUsers = () => {
    const {
      authorizedUsers,
      user,
      t,
    } = this.props;
    const { users } = authorizedUsers || {};
    const selectList = [];
    selectList.push(
      <SelectItem
        selected="selected"
        value="placeholder-item"
        text={t('chooseUser')}
      />
    );
    if (users && Array.isArray(users) && users.length > 0) {
      users.map((eachAuthorizedUser) => {
        const { userInfo } = eachAuthorizedUser || {};
        if (userInfo && userInfo.userId && user && user.userId && (user.userId !== userInfo.userId)) {
          selectList.push(
            (userInfo && userInfo.userId
              && (
                <SelectItem
                  value={`${userInfo && userInfo.userId}`}
                  text={`${userInfo && userInfo.firstname} ${userInfo && userInfo.lastname} (${userInfo && userInfo.email})`}
                />)),
          );
        }
      });
    }
    return selectList;
  }

  handleSubmitApprovalList = () => {
    const { jobId } = this.props;
    const data = {
      userId: this.state.selectedUserId,
      jobId,
    };
    this.props.createJobApprovalList(data).then(() => {
      this.props.getJobApprovalList(jobId).then(() => {
        this.dismissApprovalModal();
        this.setRows();
      });
    });
  }

  handleApproveCurrentUser = (jobApprovedId) => {
    const {
      jobId,
      vacancy,
      job: {
        approvedVacancies
      } = {},
    } = this.props;
    const {
      facultyId,
      coursemap,
      approveId
    } = this.state;
    let isVacancyGrtr = false;
    let isVacancyValid = false;
    if (!vacancies || vacancies <= 0) {
      isVacancyValid = true;
    } else if (vacancies && Number(vacancies) > vacancy) {
      isVacancyValid = true;
      isVacancyGrtr = true;
    } else {
      isVacancyValid = false;
    }
    let vacancies = 0
    if (coursemap && Array.isArray(coursemap) && coursemap.length > 0) {
      const finalData = this.handleSendJobApiParams();
      finalData.coursemap = coursemap;
      finalData.jobApproveId = approveId;
      finalData.facultyOverwrite = false;
      finalData.isApproval = true;
      this.setState({
        showLoader: true,
      }, () => {
        this.props.editJob(finalData).then((result) => {
          if (result && !result.error) {
            let jobApproveId = approveId
            this.props.editJobApprovalList(jobId && Number(jobId), true, vacancies && Number(vacancies), facultyId, jobApproveId).then((res) => {
              if (res && !res.error) {

                this.props.getJobApprovalList(jobId).then(() => {
                  this.dismissApproveJobMpdal();
                  this.props.getJobInfo({ jobId });
                  this.setRows();
                  this.setState({
                    showLoader: false,
                  })
                });
              }
              if (res && res.error) {
                this.setState({
                  showNotifInfo: true,
                  showLoader: false
                })
              }
            })
          }
        });
      });
    }
  }

  handleApproveCurrentUsers = (jobApprovedId) => {
    const {
      jobId,
      vacancy,
      job: {
        approvedVacancies
      } = {},
    } = this.props;
    const {
      vacancies,
      facultyId,
      coursemap,
      approveId
    } = this.state;
    let isVacancyGrtr = false;
    let isVacancyValid = false;
    if (!vacancies || vacancies <= 0) {
      isVacancyValid = true;
    } else if (vacancies && Number(vacancies) > vacancy) {
      isVacancyValid = true;
      isVacancyGrtr = true;
    } else {
      isVacancyValid = false;
    }
    this.setState({
      isVacancyValid,
      isVacancyGrtr,
    }, () => {
      if (coursemap && Array.isArray(coursemap) && coursemap.length > 0 && this.state.isVacancyGrtr !== true && this.state.isVacancyValid !== true) {
        const finalData = this.handleSendJobApiParams();
        finalData.coursemap = coursemap;
        finalData.jobApproveId = approveId;
        finalData.facultyOverwrite = false;
        finalData.isApproval = true;
        this.setState({
          showLoader: true,
        }, () => {
          this.props.editJob(finalData).then((result) => {
            if (result && !result.error) {
              if (this.state.isVacancyValid !== true && this.state.isVacancyGrtr !== true) {
                let jobApproveId = approveId
                this.props.editJobApprovalList(jobId && Number(jobId), true, vacancies && Number(vacancies), facultyId, jobApproveId).then((res) => {
                  if (res && !res.error) {

                    this.props.getJobApprovalList(jobId).then(() => {
                      this.dismissApproveJobMpdal();
                      this.props.getJobInfo({ jobId });
                      this.setRows();
                      this.setState({
                        showLoader: false,
                      })
                    });
                  }
                  if (res && res.error) {
                    this.setState({
                      showNotifInfo: true,
                      showLoader: false
                    })
                  }
                })
              }
            }
          });
        });
      }
    });
  }

  handleDisApproveCurrentUser = (jobApprovedId) => {
    const {
      jobId,
    } = this.props;
    const {
      facultyId,
      approveId
    } = this.state;
    let jobApproveId = approveId
    this.props.editJobApprovalList(jobId && Number(jobId), false, 0, facultyId, jobApproveId).then((res) => {
      if (res && !res.error) {
        this.props.getJobApprovalList(jobId).then(() => {
          this.dismissRejectJobMpdal();
          this.setRows();
        });
      }
    });
  }

  handleChangeCheckboxValues = (e, courseId, facultyId) => {
    let {
      coursemap,
    } = this.state;
    let selectedCourses = [];
    if (e === true) {
      if (!coursemap || (coursemap && Array.isArray(coursemap) && coursemap.length <= 0)) {
        coursemap = [{ facultyId, courses: [courseId] }];
        selectedCourses = [courseId];
      } else if (coursemap && Array.isArray(coursemap) && coursemap.length > 0) {
        let filteredData = coursemap.find(res => res.facultyId === facultyId);
        if (filteredData && filteredData.courses && Array.isArray(filteredData.courses) && filteredData.courses.length > 0) {
          filteredData.courses.push(courseId);
          selectedCourses = filteredData.courses;
        } else {
          filteredData = { facultyId, courses: [courseId] };
          selectedCourses = [courseId];
          coursemap.push(filteredData);
        }
      }
    }
    this.setState({
      coursemap,
      [`courses-${facultyId}`]: selectedCourses,
    }, () => {
      if (e === false) {
        const {
          coursemap: stateCourses,
        } = this.state;
        let remainingCourses = [];
        if (stateCourses && Array.isArray(stateCourses) && stateCourses.length > 0) {
          const filteredList = stateCourses.filter(res => res.facultyId !== facultyId);
          let filteredArr = [];
          if (filteredList && Array.isArray(filteredList) && filteredList.length <= 0) {
            filteredArr = stateCourses.filter(res => res.facultyId === facultyId);
            if (filteredArr && Array.isArray(filteredArr) && filteredArr[0] && filteredArr[0].courses && Array.isArray(filteredArr[0].courses) && filteredArr[0].courses.length > 1) {
              const filtCour = filteredArr[0].courses.filter(res => res !== courseId);
              filteredArr[0].courses = filtCour;
              remainingCourses = filtCour;
            } else {
              filteredArr = stateCourses.filter(res => res.facultyId !== facultyId);
              remainingCourses = [];
            }
            this.setState({
              coursemap: filteredArr,
              [`courses-${facultyId}`]: remainingCourses,
            });
          } else {
            filteredArr = stateCourses.find(res => res.facultyId === facultyId);
            if (filteredArr && filteredArr.courses && Array.isArray(filteredArr.courses) && filteredArr.courses.length > 1) {
              const filtCour = filteredArr.courses.filter(res => res !== courseId);
              filteredArr.courses = filtCour;
              remainingCourses = filtCour;
              filteredList.push(filteredArr);
            }
            this.setState({
              coursemap: filteredList,
              [`courses-${facultyId}`]: remainingCourses,
            });
          }
        }
      }
    });
  }

  handleSelectAllCheckboxes = (facultyId, courses, e) => {
    const {
      user: {
        company: {
          ctypeId,
        } = {},
      } = {},
    } = this.props;
    if (e === true) {
      const {
        coursemap,
      } = this.state;
      const data = handleSelectAllFacultyCourses({ courses, facultyId, ctypeId, coursemap });
      const {
        filteredList,
        selectedCourses,
      } = data;
      this.setState({
        coursemap: filteredList,
        [`courses-${facultyId}`]: selectedCourses,
        [`faculty-${facultyId}`]: facultyId,
      });
    } else {
      this.handleClearAllCheckboxes(facultyId);
    }
  }

  handleClearAllCheckboxes = (facultyId) => {
    const {
      coursemap,
    } = this.state;
    let filteredData = [];
    if (coursemap && Array.isArray(coursemap) && coursemap.length > 1) {
      filteredData = coursemap.filter(res => res.facultyId !== facultyId);
    } else if (coursemap && Array.isArray(coursemap) && coursemap.length === 1) {
      filteredData = [];
    }
    this.setState({
      [`courses-${facultyId}`]: [],
      coursemap: filteredData,
      [`faculty-${facultyId}`]: null,
    });
  }

  onVacancyChange = (jobId, approvedVacancies) => {
    const {
      vacancy,
    } = this.props;
    let isVacancyGrtr = false;
    let isVacancyValid = false;
    if (!approvedVacancies || approvedVacancies <= 0) {
      isVacancyValid = true;
    } else if (approvedVacancies && Number(approvedVacancies) > vacancy) {
      isVacancyValid = true;
      isVacancyGrtr = true;
    } else {
      isVacancyValid = false;
    }
    this.setState({
      isVacancyValid,
      isVacancyGrtr,
    }, () => {
      if (this.state.isVacancyGrtr !== true && this.state.isVacancyValid !== true) {
        let data = {
          jobId: jobId,
          jobvacancies: approvedVacancies,
        };
        this.props.updateJobApprovedVacancies(data).then((res) => {
          if (res && !res.error) {
            this.setState({
              showNotifInfo: true,
              approvedVacancies
            })
            this.props.getJobInfo({ jobId }).then((res) => {
              this.setState({ vacancyLoader: false });
            })
          }
        })
      }
    });
  };

  onRejectJob = () => {
    const {
      jobId
    } = this.props
    let data = {
      jobId: jobId,
      jobRejected: true
    }
    this.setState({
      showLoader: true
    }, () => {
      this.props.updateJobReject(data).then((res) => {
        if (res && !res.error) {
          this.props.getJobApprovalList(jobId).then(() => {
            this.dismissJobRejectModal();
            this.props.getJobInfo({ jobId });
            this.setRows();
            this.setState({
              showLoader: false,
            })
          });
        }
      })
    })
  }

  render() {
    const {
      t,
      jobId,
      vacancy,
      facultyData,
      user: {
        isAdmin,
        isSuperAdmin,
        facultyId: userFacultyId = null,
        courseAdmin,
        courseId: userCourseId,
        roleId: userRoleId,
        // facultyId,
        company: {
          ctypeId,
        } = {},
      } = {},
      user,
      job: {
        facultyApprovedVacancies
      } = {}
    } = this.props;
    const {
      isPrimaryCourseAdmin,
      jobRejectModal
    } = this.state

    const { rowsData, isOpenAddApprovalsModal, isVacancyGrtr, facultyId, showLoader,
      showNotifInfo, approvedVacancies, showErrMsg } = this.state;

    const {
      isOpenRejectJobModal,
      isOpenApproveJobModal,
    } = this.state;  

    let {
      approvalList: listofApproval
    } = this.props

    let facultyApprVacancies = facultyApprovedVacancies;
    let isModal = true
    let finalFacultyData = [];
    let avaliableVacancies = [];
    let disabled = false

    if (listofApproval && Array.isArray(listofApproval) && listofApproval.length > 0) {
      listofApproval.forEach((res) => {
        avaliableVacancies.push(res && res.vacancies)
      })
    }
    let sumofApprovedVacancies = avaliableVacancies.reduce(function (a, b) {
      let a1 = parseInt(a, 10) || 0;
      let b1 = parseInt(b, 10) || 0;
      return a1 + b1;
    }, 0);
    let remainingVacancies = approvedVacancies - sumofApprovedVacancies

    if (facultyData && Array.isArray(facultyData) && facultyData.length > 0) {
      finalFacultyData = facultyData.filter(res => res.facultyId == facultyId);
    }
    
    const headersApprovalsList = [
      { key: 'name', header: `${t('name')}` },
      { key: 'coursename', header: `${t('coursename')}` },
      { key: 'primarycourseadmin', header: `${t('primarycourseadmin')}` },
      // { key: 'email', header: `${t('email')}` },
      { key: 'status', header: `${t('status')}` },
      // { key: 'vacancies', header: `${t('apprvdVacancies')}` },
      { key: 'action', header: `${t('action')}` },
    ];
  
    if(ctypeId && Number(ctypeId) == 2 && userRoleId && userRoleId == 4){
      if(userFacultyId && userFacultyId != null && !courseAdmin && isSuperAdmin){
        disabled = false
      }
      else if(userCourseId !=null && courseAdmin === true){
        if(isPrimaryCourseAdmin != undefined && isPrimaryCourseAdmin != null && isPrimaryCourseAdmin == userCourseId){
          disabled = false
        }
        else{
          disabled = true
        }
      }
    }

    return (
      <Fragment>
        <div className="xp-m__job--container">
          <div className="bx--type-delta mb-2">
            {t('jobApproval')}
          </div>
          {
            ctypeId && Number(ctypeId) === 2 && (
              <div>
                <p>
                  Please be reminded to edit job to include other diplomas
                  first before accepting/rejecting the job. If all the course admins below reject the job from
                  the system, a rejection email will be sent to the employer.
              </p>
              </div>
            )

          }
          {
            this.state.showButton === true && (
              <InlineNotification
                lowContrast
                className="mt-1 mb-1"
                title=""
                iconDescription="describes the close button"
                kind="info"
                subtitle={`Please contact your primary course admin to set the number of approved vacancies.`}
                onCloseButtonClick={() => {
                  this.setState({ showButton: false });
                }}
              />
            )
          }
          {
            ctypeId && Number(ctypeId) === 2 && (courseAdmin === true || (isAdmin === true)) && !isSuperAdmin && (
              <div className="mt-2 ml-2">
                <div className="mt-2">
                  <strong>{'Total Approved Vacancies'}</strong>
                </div>
                <div className="bx--row col-xl-4 align-items-center mt-2">
                  {showNotifInfo === true && (
                    <InlineNotification
                      lowContrast
                      className="mt-1 mb-1"
                      title=""
                      iconDescription="describes the close button"
                      kind="info"
                      subtitle={t('prefSaved')}
                      onCloseButtonClick={() => {
                        this.setState({ showNotifInfo: false });
                      }}
                    />
                  )}
                  <div className="mr-2">
                    <TextInput
                      name="approvedVacancies"
                      id="approvedVacancies"
                      placeholder={vacancy}
                      onChange={this.handleChange}
                      value={approvedVacancies}
                      required
                      type="number"
                      disabled={disabled}
                      invalid={this.state.isVacancyValid || isVacancyGrtr}
                      invalidText={`${isVacancyGrtr ? `This job has only ${vacancy} vacancies, please enter accordingly` : `${t('vacancyValidationMsg')}`} `}
                    />
                  </div>

                  {/* {(userRoleId && userRoleId===4 && userFacultyId && userFacultyId!==null) && } */}
                  {
                    ((isPrimaryCourseAdmin == userCourseId) || (userRoleId && userRoleId === 4 && userFacultyId && userFacultyId !== null)) && (
                      <div className="">
                        {' '}
                        <Button
                          className="bx--btn bx--btn--primary full-width mt-2" size="small"
                          disabled={disabled}
                          onClick={() => this.onVacancyChange(jobId, this.state.approvedVacancies)}>
                          {' '}
                          Save{' '}
                        </Button>
                        <JDProcessOverlay
                          show={this.state && this.state.vacancyLoader}
                          message="processing..."
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
          {
            !userCourseId && (isAdmin === true) && ctypeId && Number(ctypeId) === 2 && (
              <div className="mt-2 ml-2">
                <div className="text-right mb-lg-0 mb-xs-2">
                  <Button kind="danger" className="text-right"
                    onClick={() => this.activeJobRejectModal()}>
                    Reject Job
                  </Button>
                  <div className="text-left">
                    <Modal
                      open={jobRejectModal}
                      shouldSubmitOnEnter
                      danger
                      modalHeading={t('reject')}
                      primaryButtonText={t('submit')}
                      secondaryButtonText={t('cancel')}
                      onRequestClose={this.dismissJobRejectModal}
                      onRequestSubmit={this.onRejectJob}
                    >
                      <div className="text-left text-dark">
                        {t('areYouSureMsg')}
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            )
          }
          {
            ctypeId && Number(ctypeId) === 2 && isSuperAdmin && (
              (facultyApprVacancies && Array.isArray(facultyApprVacancies) 
                 && facultyApprVacancies.length>0) && facultyApprVacancies.map((resp) => {
                  const {
                    facultyApprovedVacancies,
                    faculty
                  } = resp || {};
                  return (
                    <div className="row pb-2">
                      <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                        <h4 className="bx--modal-header__label mt-2">
                          {t('approvesVacancies')} {faculty && faculty.facultyName}
                        </h4>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                        <Tag className="text-uppercase" type="blue">
                          {facultyApprovedVacancies || t('forApproved')}
                        </Tag>
                      </div>
                    </div>
                  )
              })
            )
          }

          {/* <div className="text-right mb-lg-0 mb-xs-2"><Button className="text-right" small onClick={this.activeApprovalModal}>{t('add')} {t('approvers')}</Button></div> */}
          <GenericDataTable rows={rowsData} headers={headersApprovalsList} />
          {
            rowsData && Array.isArray(rowsData)
            && rowsData.length <= 0
            && <NoContentBox t={t} />
          }
        </div>
        <Modal
          open={isOpenAddApprovalsModal}
          shouldSubmitOnEnter
          modalHeading={t('addApproverMsg')}
          primaryButtonText={t('add')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissApprovalModal}
          onRequestSubmit={this.handleSubmitApprovalList}
        >
          <Select
            labelText={`${t('select')} ${t('user')}`}
            hideLabel
            onChange={this.handleChange}
            name="selectedUserId"
            id="jc-user-select"
          >
            {this.getCompanyUsers()}
          </Select>
        </Modal>
        <Modal
          open={isOpenApproveJobModal}
          shouldSubmitOnEnter
          modalHeading={t('accept')}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissApproveJobMpdal}
          onRequestSubmit={this.handleApproveCurrentUser}
        >
          {showNotifInfo === true && (
            <InlineNotification
              lowContrast
              className="mt-1 mb-1"
              title=""
              iconDescription="describes the close button"
              kind="info"
              subtitle={`${t('jobApproveVacancies')} ${remainingVacancies} ${remainingVacancies > 0 ? 'vacancies' : 'vacancies'
                } , ${t('totalVacancies')} ${approvedVacancies}`}
              onCloseButtonClick={() => {
                this.setState({ showNotifInfo: false });
              }}
            />
          )}
          {/*<div className="text-dark mb-2">
            {t('areYouSureMsg')}
          </div>
          <TextInput
            name="vacancies"
            id="vacancies"
            // placeholder={vacancy}
            placeholder="vacancies"
            labelText={t('apprvdVacancies')}
            onChange={this.handleChange}
            value={this.state.vacancies}
            required
            type="number"
            invalid={this.state.isVacancyValid || isVacancyGrtr}
            invalidText={`${isVacancyGrtr ? `This job has only ${vacancy} vacancies, please enter accordingly` : `${t('vacancyValidationMsg')}`} `}
          />*/}
          {/*<div className="mt-2">
            <FacultyCoursesSelection
              facultyData={finalFacultyData}
              isSuperAdmin={isSuperAdmin}
              userFacultyId={userFacultyId}
              ctypeId={ctypeId}
              hideCoursesDetails
              isAdmin={isAdmin}
              state={this.state}
              isModal={isModal}
              handleChangeCheckboxValues={this.handleChangeCheckboxValues}
              handleSelectAllCheckboxes={this.handleSelectAllCheckboxes}
              handleClearAllCheckboxes={this.handleClearAllCheckboxes}
            />
          </div>*/}
        </Modal>
        <Modal
          open={isOpenRejectJobModal}
          shouldSubmitOnEnter
          danger
          modalHeading={t('reject')}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissRejectJobMpdal}
          onRequestSubmit={this.handleDisApproveCurrentUser}
        >
          {
            showErrMsg == true ? (
              <div>
                <div className="text-dark">
                  {t('updateLastCourseAdminReject')} {' '}  {t('areYouSureMsg')}
                </div>
                <br />
                <br />
                <div className="text-dark">
                  {t('areYouSureMsg')}
                </div>
              </div>
            ) : (
                <div className="text-dark">
                  {t('areYouSureMsg')}
                </div>
              )
          }
          {/*<div className="text-dark">
            {t('areYouSureMsg')}
          </div>*/}
        </Modal>
        <JDProcessOverlay show={(this.props && (this.props.loading || this.props.jobLoading)) || showLoader} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authorizedUsers: state.jobs.authorizedUsers,
  loading: state.JobApprovals.loading,
  // approvalList: state.JobApprovals.approvalList,
  user: state.x0paDuser.user,
  jobLoading: state.jobs.loading,
  facultyData: state.candidateDetails.facultyData,
});

const mapDispatchToProps = {
  getAuthorizedUsers,
  getJobApprovalList,
  createJobApprovalList,
  deleteJobApprovalList,
  editJobApprovalList,
  getJobInfo,
  getAllFaculties,
  editJob,
  upsertJobBatchActions,
  updateJobApprovalList,
  updateJobReject,
  updateJobApprovedVacancies
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobApprovals));
