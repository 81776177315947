import {
REQUEST_BROWSE_EMPLOYEE_INFO,
ERROR_BROWSE_EMPLOYEE_INFO,
SUCCESS_BROWSE_EMPLOYEE_INFO,
REQUEST_BROWSE_EMPLOYEE_ID_INFO,
ERROR_BROWSE_EMPLOYEE_ID_INFO,
SUCCESS_BROWSE_EMPLOYEE_ID_INFO,
REQUEST_BROWSE_EMPLOYEE_JOBS_INFO,
ERROR_BROWSE_EMPLOYEE_JOBS_INFO,
SUCCESS_BROWSE_EMPLOYEE_JOBS_INFO,
REQUEST_CONFIRM_COMPANY_EMAIL_INFO,
SUCCESS_CONFIRM_COMPANY_EMAIL_INFO,
ERROR_CONFIRM_COMPANY_EMAIL_INFO
} from 'actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  employees: [],
  jobs:[],  
  count: 0,
  jobsCount: 0
};

const BrowseEmployees = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_BROWSE_EMPLOYEE_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_BROWSE_EMPLOYEE_INFO:
      return {
        ...state,
        employees: payload && payload.employees,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_BROWSE_EMPLOYEE_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_BROWSE_EMPLOYEE_ID_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_BROWSE_EMPLOYEE_ID_INFO:
      return {
        ...state,
        employees: payload && payload.employees,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_BROWSE_EMPLOYEE_ID_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      }; 
     case REQUEST_BROWSE_EMPLOYEE_JOBS_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_BROWSE_EMPLOYEE_JOBS_INFO:
      return {
        ...state,
        jobs: payload && payload.jobs,
        jobsCount: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_BROWSE_EMPLOYEE_JOBS_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CONFIRM_COMPANY_EMAIL_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CONFIRM_COMPANY_EMAIL_INFO:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CONFIRM_COMPANY_EMAIL_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };            
     
    default:
      return state;
  }
};

export default BrowseEmployees;
