import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tile } from 'carbon-components-react';
import {
  getCandidateProfile,
} from 'actions/talentSearch/candidatesList';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import finlandImage from 'assets/images/business-finland.jpg';
import FillCircle from 'components/common/FillCircle/FillCircle';
import { FitForFinlandHelp } from 'components/common/atoms';
import SetMixPanel from 'components/common/GetCurrentPath';

import './Login.css';

class StartFinland extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    SetMixPanel();
    const { user: { profileId = null } = {} } = this.props;
    let overallFinlandScore = null;
    if (profileId !== null) {
      this.props.getCandidateProfile(profileId).then( (res) => {
        const { profile, finlandprofile: [finlandProfile = null] = [] } = res || {};
        if (profile && profile.overallScore && finlandProfile) {
          overallFinlandScore = profile.overallScore;
          this.setState({overallFinlandScore});
        }
        // if (finlandProfile === null) this.props.history.push('/app/campus/candidate/business/finland/0');
      });
    }
  }

  render() {
    return (
      <div className="finlandLanding col bx--row m-0 p-0">
        <div className="left-side col-sm m-0 p-0 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${finlandImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <div className="text-dark m-3  p-2" style={{ background: `rgba(255,255,255,0.65)` }}>
            <div className="h2 font-weight-bold text-center"> WHY WORK IN FINLAND? </div>
            <div className="bx--type-gamma">
              <ol style={{ listStyle: 'disc', fontSize: '1rem' }}>
                <li style={{ listStyle: 'disc', listStylePosition: 'inside', textIndent: '-1em', paddingLeft: '1em' }}>Finland, the happiest country in the world (UN World Happiness Report 2019)</li>
                <li style={{ listStyle: 'disc', listStylePosition: 'inside', textIndent: '-1em', paddingLeft: '1em' }}>Most employee friendly working hours (European Company Survey)</li>
                <li style={{ listStyle: 'disc', listStylePosition: 'inside', textIndent: '-1em', paddingLeft: '1em' }}>Greenest country in the world (Yale University)</li>
                <li style={{ listStyle: 'disc', listStylePosition: 'inside', textIndent: '-1em', paddingLeft: '1em' }}>3rd most equal in Global Gender Gap Report 2017 (World Economic Forum)</li>
                <li style={{ listStyle: 'disc', listStylePosition: 'inside', textIndent: '-1em', paddingLeft: '1em' }}>Paid maternity and paternity leave</li>
                <li style={{ listStyle: 'disc', listStylePosition: 'inside', textIndent: '-1em', paddingLeft: '1em' }}>1st in skill development at work (European Working Conditions Survey)</li>
              </ol>
              <div style={{ fontSize: '1.25rem' }}>Follow us on LinkedIn #FinlandWorks!</div>
            </div>
            <div className="text-center"><a href="https://www.businessfinland.fi/en/do-business-with-finland/kampanjasivut/indiatalentboost/" target="_blank" type="button" className="bx--btn bx--btn--primary mt-3" >Learn more</a></div>
          </div>
        </div>
        <div className="right-side d-flex justify-content-center align-items-center col-sm m-0 p-0">
          <div className="d-block p-3 text-center">
            {this.state && this.state.overallFinlandScore ? (
              <div className="bx--type-gamma mb-3">Thank you for submitting the application.</div>
            ) : (
              <div className="bx--type-gamma mb-3">Submit your application for opportunity to Work in Finland</div>
            )}
            {
              this.state && this.state.overallFinlandScore && (
                <div className="align-items-center bx--type-caps bx--type-epsilon d-flex mt-2 pt-2 pb-2 pl-2 pr-3 mb-3 border">
                  <div className="pl-2 pb-2 pt-2 pr-0">
                  Your Fit for Finland Score:
                  <span className="ml-2 mr-2" style={{position:'relative',top:'2px'}}><FitForFinlandHelp /></span>
                  </div>
                  <FillCircle fill={Math.round(this.state.overallFinlandScore)} fillColor="#4b2040" textColor="#53777A" />
                </div>
              )
            }
            <div className="m-0 d-inline-flex">
              <div className="mr-2">
                <Button onClick={() => { this.props.history.push('/app/campus/candidate/business/finland/0'); }}>
                  {this.state && this.state.overallFinlandScore ? 'Edit Application' : 'Apply'}
                </Button>
              </div>
            </div>
            <div className="mt-1 mb-1">
              or
            </div>            
            <div className="bx--type- mb-3">
              <a href="#" onClick={() => { this.props.history.push('/app/campus/candidate'); }}>View Dashboard</a> to track your application status
            </div>            
          </div>
        </div>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  candidateProfile: state.roboroyApplications.candidateProfile,
  loading: state.roboroyApplications.loading,
});

const mapDispatchToProps = {
  getCandidateProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartFinland);
