import { useTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Loading } from 'carbon-components-react';
import FillCircle from 'components/common/FillCircle/FillCircle';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import '../Dashboard.css';
import JobsListView from 'components/roboroy/JobsListView';

const JobsView = ({
  dashboard,
  handleJobsClick,
  handleOpenJobsClick,
  handleClosedJobsClick,
  handleSharedJobsClick,
  jobsCount,
  jobs,
  jobLoading,
  totalJobs,
  showViewAll,
  push,
}) => {
  const { t } = useTranslation();
  const openJobsPercent = (((jobsCount && jobsCount.open) * 100) / dashboard.jobs);
  const closedJobsPercent = (((jobsCount && jobsCount.closed) * 100) / dashboard.jobs);
  return (
    <Fragment>
      <div className="mb-2">
        <span className="heading-style h6 text-uppercase font-style-heading">
          {t('jobs')}
        </span>
        {/* <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={handleJobsClick}>
              <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large'}}>
                    {dashboard.jobs || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('totalJobs')}</div>
                </div>
                <div style={{ fontSize: 'xx-large'}}><i className="fa fa-files-o xpa-text-grey--light ml-3" aria-hidden="true" /></div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={handleOpenJobsClick}>
              <div className="d-flex justify-content-around align-items-center p-2">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large'}}>
                    {(jobsCount && jobsCount.open) || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('open')}</div>
                </div>
                <div className="ml-3">
                  <FillCircle
                    fill={Number.isNaN(Number(`${openJobsPercent}`))
                      ? 0
                      : Math.round(Number(openJobsPercent))}
                    fillColor="#33BBFF"
                    textColor="#000000"
                    isPercent={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-2">
            <div style={{ cursor: 'pointer' }} onClick={handleClosedJobsClick}>
              <div className="d-flex justify-content-around align-items-center p-2">
                <div className="xpa-f-sz-width">
                  <div className="bx--type-zeta text-center xpa-link" style={{ fontSize: 'xx-large' }}>
                    {(jobsCount && jobsCount.closed) || 0}
                  </div>
                  <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz ">{t('closed')}</div>
                </div>
                <div className="ml-3">
                  <FillCircle
                    fill={Number.isNaN(Number(`${closedJobsPercent}`))
                      ? 0
                      : Math.round(Number(closedJobsPercent))}
                    fillColor="#008000"
                    textColor="#000000"
                    isPercent={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div style={{ flex: 1 }}>
          <GenericTabs
            className="d-flex justify-content-end"
            selected={0}
            tabContent={[
              {
                label: `${showViewAll === true ? `${t('recentlyPosted')}` : `${t('jobs')}`}`,
                value: '',
                onClick: () => {},
              },
            ]}
          />
        </div>
        {
            jobLoading === true
            && (
              <div className="bx--row justify-content-around">
                <Loading withOverlay={false} />
              </div>
            )
          }
        <JobsListView push={push} jobs={jobs} jobLoading={jobLoading} />
        {
          jobs && Array.isArray(jobs)
          && jobs.length <= 0
          && jobLoading !== true
          && (
            <div className="card-body text-center">
              <p className="font-style-heading h6">{t('noJobsPosted')}</p>
            </div>
          )
        }
        {/*
          showViewAll === true && (
            <div>
              {
                totalJobs
                  && totalJobs > 5
                  ? (
                    <div className="xpa-link" onClick={handleJobsClick}>{t('more')}</div>
                  ) : null
              }
            </div>
          )
        */}
      </div>
      <hr />
    </Fragment>
  );
};

export default JobsView;
