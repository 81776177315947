import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import classnames from 'classnames';

import CircularProgress from 'components/common/CircularProgress';

import './XopaModal.css';

function randomScore() {
  return Math.round(Math.random() * (100 - 50) + 50);
}

class XopaModal extends Component {
  dismiss = (e) => {
    if (e.target.className === 'job_details__modal--bg xopa__modal') {
      this.hide();
    }
  };

  hide = () => {
    this.props.dismiss();
  };

  render() {
    return (
      <div
        className={classnames('job_details__modal--bg xopa__modal', { hidden: !this.props.active })}
        onClick={this.dismiss}
      >
        <div className="job_details__modal xopa__modal">
          <div className="job_details__modal__header">
            <p>Profile</p>
            <span onClick={this.hide}>
              <i className="icon icon-cross" />
            </span>
          </div>
          <div className="job_details__modal__body xopa__modal__body">
            <div className="profile_info">
              <div className="modal__profile__pic">
              </div>
              <div className="modal__stats__table">
                <div>
                  <div className="modal_states_left">
                    <span>Name/ID:</span>
                  </div>
                  <div className="modal_states_right">Patrik Luk</div>
                </div>
                <div>
                  <div className="modal_states_left">
                    <span>current dept:</span>
                  </div>
                  <div className="modal_states_right">Marketing</div>
                </div>
                <div>
                  <div className="modal_states_left">
                    <span>location:</span>
                  </div>
                  <div className="modal_states_right">London</div>
                </div>
                <div>
                  <div className="modal_states_left">
                    <span>designation</span>
                  </div>
                  <div className="modal_states_right">Marketing Lead</div>
                </div>
                <div>
                  <div className="modal_states_left">
                    <span>video interview</span>
                  </div>
                  <div className="modal_states_right">
                    <i className="fa fa-video-camera" aria-hidden="true" />
                  </div>
                </div>
                <div>
                  <div className="modal_states_left">
                    <span>linkedin:</span>
                  </div>
                  <div className="modal_states_right">
                    <a href="/">
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal__shortlist">
                <div>
                  <span>Shortlist:</span>
                </div>
                <div className="cell__shortlist__inner">
                  <div className="check">
                    <i className="fa fa-check check" aria-hidden="true" />
                  </div>
                  <div className="check">
                    <i className="fa fa-times times" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>

            <div className="outcome_info">
              <div className="outcome_table">
                <div>
                  <div className="outcome_left" />
                  <div className="outcome_right outcome_right_header">
                    <h5>Predict OutComes</h5>
                  </div>
                </div>
                <div>
                  <div className="outcome_left" />
                  <div className="outcome_right outcome_right_header">
                    <div>
                      <span>Hireability</span>
                    </div>
                    <div>
                      <span>Retention</span>
                    </div>
                    <div>
                      <span>Acceptability</span>
                    </div>
                    <div>
                      <span>Performance</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="outcome_left">
                    <span>Candidate Score</span>
                  </div>
                  <div className="outcome_right">
                    <div>
                      <CircularProgress getColor="getLoyaltyColor" value={randomScore()} />
                    </div>
                    <div>
                      <CircularProgress getColor="getLoyaltyColor" value={randomScore()} />
                    </div>
                    <div>
                      <CircularProgress getColor="getLoyaltyColor" value={randomScore()} />
                    </div>
                    <div>
                      <CircularProgress getColor="getLoyaltyColor" value={randomScore()} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="outcome_left">
                    <span>Company Average</span>
                  </div>
                  <div className="outcome_right">
                    <div>
                      <CircularProgress getColor="getLoyaltyColor" value={randomScore()} />
                    </div>
                    <div>
                      <CircularProgress getColor="getLoyaltyColor" value={randomScore()} />
                    </div>
                    <div>
                      <CircularProgress getColor="getLoyaltyColor" value={randomScore()} />
                    </div>
                    <div>
                      <CircularProgress getColor="getLoyaltyColor" value={randomScore()} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="outcome_labels">
                <div className="outcome_poor">
                  <span /> Poor
                </div>
                <div className="outcome_average">
                  <span /> Average
                </div>
                <div className="outcome_good">
                  <span /> Good
                </div>
                <div className="outcome_excellent">
                  <span /> Excellent
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default XopaModal;
