import { withTranslation } from 'react-i18next';
import React from 'react';
import Avatar from 'components/common/Avatar';

const OfficeDetails = ({ details, company = {}, region, countryFull, type, email, firstname, lastname, commuteTime, roleId, contactnumber, canApplytoJob, isAdmin, isSuperAdmin, ctypeId, designation }) => {
    const {
       officeName, officeWebsite, address,
    } = details || {};
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 pt-1">
        <div className="">
          <h4 className="mt-0">{type}</h4>
          <hr />
          <div className="d-flex align-items-center mt-1 mb-1">
            <div>
              <Avatar
                user={{
                  firstname,
                  lastname,
                  picture: null,
                }}
                classNameInfo="rounded-circle"
                size="30"
              />
            </div>
            <div className="ml-2 text-capitalize bx--type-zeta">
              {firstname} {lastname}
            </div>
          </div>
          {company ? <div className="bx--type-zeta text-uppercase"><strong>{company.displayCompanyName}</strong></div> : ''}
          {
            company && company.website
              ? (
                <div>
                    <a target="_blank" href={company.website.indexOf('http')>=0 ? company.website : `https://${company.website}`}>
                      {company.website}
                    </a>
                </div>
              ) : null
          }
          <small className="card-subtitle text-dark">{region&& region!=null&&region.length>0?region + ', ':''}{countryFull || ''}</small>
          <hr />
          {
            !canApplytoJob || (isSuperAdmin || (isAdmin && ctypeId && Number(ctypeId) === 2)) ? (
              <div>
                <h4 className="mt-0">Contact Info</h4>
                {
                  email && (
                    <div>
                      <a className="text-lowercase" href={`mailto:${email}`}>{email}</a>
                    </div>
                  )
                }
                {
                  contactnumber && (
                    <div>
                      {contactnumber}
                    </div>
                  )
                }
                {
                  designation && (
                    <div>
                      {designation}
                    </div>
                  )
                }
              </div>
            ) : ' '
          }
          {/* <div>
            <div className="card-subtitle  text-dark">{address.addressLine1}</div>
            <div className="card-subtitle  text-dark">{address.addressLine2}</div>
          </div>
          <div>
            <div className="card-subtitle  text-dark">
              {address.location.locationName} {address.zipcode ? `- ${address.zipcode}` : ''}
            </div>
            <div className="card-subtitle  text-dark">{address.addressLine2}</div>
            <div className="card-subtitle  text-dark">
              {(address && address.location && address.location.state && address.location.state.stateFull) || ''}
            </div>
            <div className="card-subtitle  text-dark">
              {address.location.country.countryFull || ''}
            </div>
          </div>
          <div className="card-subtitle">
            <a href={officeWebsite}>{officeWebsite}</a>
          </div> */}

          {/* <div className="text-justify">{company ? (<div><div className="divider" />{company.description}</div>) : ''}</div> */}
        </div>
      </div>
    );
  };

  export default OfficeDetails;
