import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Button, ToolbarItem,
  Modal, TextInput, Pagination,
  Select, SelectItem,
} from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import BackRow from 'components/common/BackRow/BackRow';
import {
  getCitiesList,
  createCityDetails,
  upsertCityDetails,
  deleteCityDetailsById,
} from 'actions/CitiesDetails';
import {
  getAllCountries,
} from 'actions/admin/CompanyAndOffice';

class CitiesDetails extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'cities', name: 'Browse Cities', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      keyword: '',
      isOpenCityModal: false,
      cityId: 0,
      cityName: '',
      countryId: 0,
      isOpenDeleteCityModal: false,
      showCannotDeleteModal:false,
      cannotDeleteModalMessage:""
    };
  }

  componentDidMount() {
    this.props.getAllCountries();
    this.setState({
      activePage: 1,
      itemsPerPage: 10,
      keyword: '',
    }, () => {
      this.handleCitiesApiCall();
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  activeCityModal = (cityId, cityName, countryId) => {
    if (cityId) {
      this.setState({
        isOpenCityModal: true,
        cityName,
        cityId,
        countryId,
      });
    } else {
      this.setState({
        isOpenCityModal: true,
        cityName: '',
        cityId: 0,
        countryId: 0,
      });
    }
  }

  activeDeleteCityModal = (cityId) => {
    this.setState({
      isOpenDeleteCityModal: true,
      cityId,
    });
  }

  dismissDeleteCityModal = () => {
    this.setState({
      isOpenDeleteCityModal: false,
      cityId: 0,
    });
  }

  dismissCityModal = () => {
    this.setState({
      isOpenCityModal: false,
      cityName: '',
      cityId: 0,
    });
  }

  handleCitiesApiCall = () => {
    const {
      activePage,
      itemsPerPage,
      keyword,
    } = this.state;
    const data = {
      activePage,
      itemsPerPage,
      keyword,
    };
    this.props.getCitiesList(data);
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleCitiesApiCall();
    });
  }

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.handleCitiesApiCall()
      ), 1000);
    });
  }

  handleSubmitCityDetails = () => {
    const {
      t,
    } = this.props;
    const {
      cityName,
      countryId,
    } = this.state;
    let isValidCityName = null;
    let isValidCityNameText = '';
    let isValidCountryId = null;
    let isValidCountryIdValue = '';
    if (!countryId) {
      isValidCountryId = true;
      isValidCountryIdValue = `${t('countryValidation')}`;
    } else {
      isValidCountryId = false;
      isValidCountryIdValue = '';
    }

    if (!cityName) {
      isValidCityName = true;
      isValidCityNameText = `${t('cityValidation')}`;
    } else {
      isValidCityName = false;
      isValidCityNameText = '';
    }

    this.setState({
      isValidCityName,
      isValidCityNameText,
      isValidCountryId,
      isValidCountryIdValue,
    }, () => {
      this.handleCityValidation();
    });
  }

  handleDeleteCityModal = () => {
    const {
      cityId,
    } = this.state;
    const{t}=this.props;
    this.props.deleteCityDetailsById(cityId).then((res) => {
      if (res && !res.error) {
        this.handleCitiesApiCall();
        this.dismissDeleteCityModal();
      } else {
        const { code, message } = res.error;
        if (code !== undefined && code != null && code == 23503) {
          this.dismissDeleteCityModal();
          this.setState({ showCannotDeleteModal: true, cannotDeleteModalMessage: t('showCannotDeleteModalMsg') })
        }
      }
    })
      .catch(e => console.log('Error:::::', e));
  }

  handleCityValidation = () => {
    const {
      cityName,
      countryId,
      cityId,
      isValidCityName,
      isValidCountryId
    } = this.state;
    const data = {
      cityName,
      countryId,
    };
    if (isValidCityName !== true && isValidCountryId !== true) {
      if (cityId) {
        data.cityId = cityId;
        this.props.upsertCityDetails(data).then((res) => {
          if (res && !res.error) {
            this.handleCitiesApiCall();
            this.dismissCityModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      } else {
        this.props.createCityDetails(data).then((res) => {
          if (res && !res.error) {
            this.handleCitiesApiCall();
            this.dismissCityModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      }
    }
  }

  render() {
    const {
      t,
      cities,
      citiesCount,
      countries,
      cityLoading,
      loading,
      user: {
        isSuperAdmin
      },
    } = this.props;
    const {
      activePage, isOpenCityModal,
      cityId, countryId,
      isValidCityName,
      isValidCityNameText,
      isValidCountryId,
      isValidCountryIdValue,
      isOpenDeleteCityModal,
    } = this.state;
    const otherActionButtons = [];
    if (isSuperAdmin === true) {
      otherActionButtons.push(
        <ToolbarItem>
          <Button
            kind="primary"
            onClick={() => this.activeCityModal(null, null)}
          >
            {t('add')} {t('cities')}
          </Button>
        </ToolbarItem>,
      );
    }
    const columnWithFilter = [
      {
        title: `${t('city')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('country')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <div className="bx--type-delta">
          {t('cities')}
        </div>
        <GenericDataTable
          className=""
          otherActions={otherActionButtons}
          headers={
            columnWithFilter.map((data, index) => (
              {
                key: `header_${index}`,
                header: data.title,
                className: data.className,
                colpropName: data.propName,
              }
            ))}
          rows={
            (citiesCount && citiesCount > 0
              && cities && Array.isArray(cities)
              && cities.length > 0)
              ? cities.map((res) => {
                const {
                  cityId: propCityId,
                  cityName,
                  countryId: propCounntryId,
                  country: {
                    countryFull,
                  } = {},
                } = res || {};
                return ({
                  isExpanded: true,
                  id: `row_${propCityId}`,
                  header_0: (
                    <div className="text-capitalize text-center text-lg-left text-md-left font-weight-bold">
                      {cityName}
                    </div>
                  ),
                  header_1: (
                    <div className="text-dark text-center text-lg-left text-md-left text-capitalize font-weight-bold">
                      {countryFull}
                    </div>
                  ),
                  header_2: (
                    isSuperAdmin === true ? (
                      <div className="d-flex justify-content-center">
                        <div>
                          <Button onClick={() => this.activeCityModal(propCityId, cityName, propCounntryId)} kind="tertiary" small>{t('edit')}</Button>
                        </div>
                        <div className="ml-2">
                          <Button onClick={() => this.activeDeleteCityModal(propCityId)} kind="danger" small>{t('delete')}</Button>
                        </div>
                      </div>
                    ) : '-'
                  ),
                });
              }) : []
          }
          selectable={false}
          searchable
          onSearchInputChange={this.handleSearchSubmit}
          expandable={false}
          batchActions={false}
        />
        {
          (citiesCount && citiesCount > 0) ? (
            <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={citiesCount} />
          ) : null
        }
        {
          ((!cities) || (cities && Array.isArray(cities) && cities.length <= 0))
          && (
            <div className="bx--tile">
              <div className="text-center">
                <div className="empty-icon">
                  <i className="fa fa-flag fa-2x" />
                </div>
                <p className="empty-title h5">{t('noCityMsg')}</p>
                <Button
                  className="mr-2"
                  onClick={() => this.activeCityModal(null, null, null)}
                  kind="primary"
                >
                  {t('add')} {t('cities')}
                </Button>
              </div>
            </div>
          )
        }
        <Modal
          open={isOpenCityModal}
          modalHeading={`${!cityId ? t('add') : t('edit')} ${t('city')}`}
          modalLabel={`${t('city')}`}
          primaryButtonText={!cityId ? t('add') : t('save')}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissCityModal}
          onRequestSubmit={this.handleSubmitCityDetails}
        >
          <div>
            <div className="mb-2">
              <Select
                name="countryId"
                labelText={<span>{t('country')} {t('code')}</span>}
                id="countryId"
                className="w-100"
                onChange={e => this.handleChange(e)}
                value={countryId}
                invalid={isValidCountryId}
                invalidText={isValidCountryIdValue}
              >
                <SelectItem text={t('select')} />
                {
                  countries && Array.isArray(countries) && countries.map(b => (
                    <SelectItem key={b.countryId} text={`${b.countryFull}`} value={`${b.countryId}`} />
                  ))
                }
              </Select>
            </div>
            <TextInput
              className="mb-2"
              name="cityName"
              id="cityName"
              labelText={`${t('city')} ${t('name')}`}
              value={this.state.cityName}
              placeholder={`${t('city')} ${t('name')}`}
              invalid={isValidCityName}
              invalidText={isValidCityNameText}
              onChange={e => this.handleChange(e)}
            />
          </div>
        </Modal>
        <Modal
          open={isOpenDeleteCityModal}
          modalHeading={`${t('delete')} ${t('city')}`}
          modalLabel={`${t('city')}`}
          primaryButtonText={t('delete')}
          danger
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissDeleteCityModal}
          onRequestSubmit={this.handleDeleteCityModal}
        >
          <div className="text-dark font-weight-bold">
            {t('deleteCityMsg')}
          </div>
        </Modal>

        <Modal
          open={this.state.showCannotDeleteModal}
          modalHeading="Cannot delete"
          modalLabel={`${t('city')}`}
          // primaryButtonText={t('delete')}
          passiveModal
          // secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={()=>this.setState({ showCannotDeleteModal: false })}
        // onRequestSubmit={this.handleDeleteCityModal}
        >
          <div className="text-dark font-weight-bold">
            {this.state.cannotDeleteModalMessage}
          </div>
        </Modal>
        <JDProcessOverlay show={loading || cityLoading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  cities: state.StudentProfile.cities,
  citiesCount: state.StudentProfile.citiesCount,
  countries: state.companyAndOffice.countries,
  loading: state.StudentProfile.loading,
  cityLoading: state.CitiesDetails.loading,
});

const mapDispatchToProps = {
  getCitiesList,
  createCityDetails,
  getAllCountries,
  upsertCityDetails,
  deleteCityDetailsById,
};

export default
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CitiesDetails));
