import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Tile, Button, Modal, Pagination,
} from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';

const FacultyView = (props) => {
  const {
    t,
    facultyData,
    otherActionButtons,
    columnWithFilter,
    handleRedirectToFacultyViewPage,
    activeFacultyModal,
    isOpenFacultyDeleteModal,
    activeFacultyDeleteModal,
    deleteFacultyDeleteModal,
    handleSubmitFacultyDeleteModal,
    handleFacultyPaginationChange,
    handleSearchFacultyData,
    facultyActivePage,
    facultyCount,
    propAdmin,
    propSuperAdmin,
    userFacultyId,
  } = props || {};
  return (
    <Fragment>
      <div className="h6 mb-2">
        {t('faculty')} {t('details')}
      </div>
      <div className="mb-2">
        {t('SchoolAccMsg')}
      </div>
      <GenericDataTable
        className=""
        otherActions={otherActionButtons}
        headers={
          columnWithFilter.map((data, index) => (
            {
              key: `header_${index}`,
              header: data.title,
              className: data.className,
              colpropName: data.propName,
            }
          ))}
        rows={
          (facultyData
          && Array.isArray(facultyData)
          && facultyData.length > 0)
            ? facultyData.map((res) => {
              const {
                facultyName: propFacultyName,
                facultyId,
                internshipStartDate,
                internshipEndDate,
              } = res || {};
              return ({
                isExpanded: true,
                id: `row_${facultyId}`,
                header_0: (
                  <div onClick={() => handleRedirectToFacultyViewPage(facultyId)} className="xpa-link text-center text-lg-left text-md-left text-capitalize font-weight-bold">
                    {propFacultyName}
                  </div>
                ),
                header_1: (
                  <div className="d-flex justify-content-center">
                    {
                      (propSuperAdmin === true) || (userFacultyId == facultyId && propAdmin === true)
                        ? (
                          <Fragment>
                            <div>
                              <Button onClick={() => activeFacultyModal(facultyId, propFacultyName, internshipStartDate, internshipEndDate)} kind="tertiary" small>{t('edit')}</Button>
                            </div>
                            {/*<div className="ml-2">
                              <Button onClick={() => activeFacultyDeleteModal(facultyId)} kind="danger" small>{t('delete')}</Button>
                            </div>*/}
                          </Fragment>
                        ) : '-'
                    }
                  </div>
                ),
              });
            }) : []}
        selectable={false}
        searchable
        onSearchInputChange={handleSearchFacultyData}
        expandable={false}
        batchActions={false}
      />
      {
        (facultyCount && facultyCount > 0) ? (
          <Pagination onChange={handleFacultyPaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={facultyActivePage} totalItems={facultyCount} />
        ) : null
      }
      {
        (!facultyData || (
          facultyData && Array.isArray(facultyData)
            && facultyData.length <= 0
        )) && (
          <div className="bx--tile">
            <div className="text-center">
              <div className="empty-icon">
                <i className="fa fa-flag fa-2x" />
              </div>
              <p className="empty-title h5">{t('noFacultyMsg')}</p>
              {
                (propAdmin === true || propSuperAdmin === true)
                  && (
                    <Button
                      className="mr-2"
                      onClick={() => activeFacultyModal(null, null)}
                      kind="primary"
                    >
                      {t('add')} {t('faculty')}
                    </Button>
                  )
              }
            </div>
          </div>
        )
      }
      <Modal
        open={isOpenFacultyDeleteModal}
        modalHeading={`${t('delete')} ${t('faculty')}`}
        modalLabel={`${t('faculty')}`}
        danger
        primaryButtonText={t('delete')}
        secondaryButtonText={t('cancel')}
        iconDescription="Close the modal"
        onRequestClose={deleteFacultyDeleteModal}
        onRequestSubmit={handleSubmitFacultyDeleteModal}
      >
        <div className="text-dark font-weight-bold">
          {t('facultyDeleteMsg')}
        </div>
      </Modal>
    </Fragment>
  );
};

export default (withTranslation()(FacultyView));
