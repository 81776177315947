import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

/* Common */
import Profile from 'containers/common/profile/Profile';
import NavRightList from 'components/Header/NavRightList';
import LeftNavigationList from 'components/Header/LeftNavigationList';
import Upload from 'components/Header/Upload';
import Contact from 'components/common/contact';
import NotFound from 'components/common/NotFound';
import { Button } from 'carbon-components-react';
import geipImage from 'assets/images/republic-polytechnic.png';
import republicPolytechnic from 'assets/images/republic-polytechnic-white.png';
import HeaderWithActions from 'components/Header/HeaderWithActions';
import SideNavList from 'components/Header/SideNav';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import {
  Header,
  HeaderMenuButton,
  SkipToContent,
  HeaderName,
} from 'carbon-components-react/lib/components/UIShell';
import {
  Tile, Pagination, Modal
} from 'carbon-components-react';

/* Main Routes */
import RecruiterRoutes from './recruiter';
import CandidateRoutes from './candidate';
import CampusRoutes from './campus';
import CarbonIconsHeader from 'components/roboroy/CarbonIconsHeader';

/* Misc */
import PrivateRoute from './privateRoute';

import './mainApp.css';

import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import Avatar from 'components/common/Avatar';
import { first } from 'lodash';
import {
  PagingInfo,
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import StudentProfileView from '../containers/roboroy/StudentProfileView';
import moment from 'moment';
const NoStudentsBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no students for your current search.</p>
    </div>
  </Tile>
);

const NoCompaniesUsersBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no company users for your current search.</p>
    </div>
  </Tile>
);

const MainApp = ({
  match,
  location,
  isUserLoggedIn,
  roleId,
  isAdmin,
  suspended,
  products,
  displayMobileSidebar,
  history,
  showSubItems,
  subItems,
  handleShowSubMenu,
  showSimulatorSubMenu,
  handleShowSimulatorSubMenu,
  handleChangeTabClick,
  handleSelect,
  selectedTab,
  label,
  handleRedirectMainPage,
  desc,
  isSideNavExpanded,
  onClickSideNavExpand,
  ctypeId: propCtypeId,
  companyId,
  isSuperAdmin,
  students,
  companiesUsers,
  companiesUsersCount,
  activePage,
  count,
  itemsPerPage,
  handleSearchSubmit,
  handlePaginationChange,
  handleCompaniesUsersPaginationChange,
  handleCompaniesUsersSearchSubmit,
  currentUserId,
  t,
}) => {
  const tabContent = [
    {
      label: `${t('Students')}`,
      value: '',
      onClick: () => { handleChangeTabClick('students'); },
    },
    {
      label: `${t('Company Users')}`,
      value: '',
      onClick: () => { handleChangeTabClick('company'); },
    },
  ]

  const columnWithFilter = [
    {
      title: `${t('students')}`,
      className: 'text-left',
      name: 'offerId',
      propName: 'offerId',
    },
    {
      title: `${t('action')}`,
      className: 'text-left',
      name: 'offerId',
      propName: 'offerId',
    },
  ]

  const columnWithFilterCompaniesUsers = [
    {
      title: `${t('Companies Users')}`,
      className: 'text-left',
      name: 'offerId',
      propName: 'offerId',
    },
    {
      title: `${t('Company Name')}`,
      className: 'text-left',
      name: 'offerId',
      propName: 'offerId',
    },
    {
      title: `${t('Action')}`,
      className: 'text-left',
      name: 'offerId',
      propName: 'offerId',
    },
  ]

  const isInStaging=window.location.origin.includes('rps.x0pa.ai')


  // const isSimulateClass=(sessionStorage.getItem('userToken')!==undefined && sessionStorage.getItem('userToken')!==null);
  // console.log(isSimulateClass)
  return (
    <Fragment>
      <Header aria-label="RP">
        <HeaderMenuButton
          aria-label="Open menu"
          onClick={() => { onClickSideNavExpand(); }}
          isActive={false}
        />
        <HeaderName
          className="pr-0 pl-0"
          onClick={() => {
            handleRedirectMainPage();
          }}
          href="javascript:void(0)"
          prefix="">
          <img className="xpa-top-header--logo p-2"
            style={isInStaging?{ height: '100%' }:{}}
            src={isInStaging ? 'https://rpopubassets.blob.core.windows.net/login/logo/rps.x0pa.ai.png' : republicPolytechnic}
            alt="logo" />
        </HeaderName>
        <SkipToContent />
        <HeaderWithActions
          propLabel={label}
          showSubItems={showSubItems}
          handleShowSubMenu={handleShowSubMenu}
          history={history}
        />
        {isUserLoggedIn && !displayMobileSidebar ? (
          <NavRightList history={history}
            showSimulatorSubMenu={showSimulatorSubMenu}
            handleShowSimulatorSubMenu={handleShowSimulatorSubMenu}
          />
        ) : null}
        <div className="hide-side-nav d-md-block d-sm-block d-xs-block d-xl-none">
          <SideNavList history={history} isSideNavExpanded={isSideNavExpanded} />
        </div>
      </Header>
      {showSubItems === true && (
        <div className="bx--tile subitems-card bx--row p-0 m-0 align-items-center d-flex  mb-1 mt-2 mt-lg-4 mt-md-5 mt-sm-5 mt-xs-5">
          <div className="bx--col-lg-3 d-flex d-flex--wrap mt-3 mb-0  ml-3">
            <div>
              <div className="h4 font-weight-bold">{t(label)}</div>
              <div className="">
                {t(desc)}
              </div>
            </div>
          </div>
          <div className="bx--col-lg-6 d-flex d-flex--wrap mb-3 mt-5 xpa-bdr--left">
            {subItems &&
              Array.isArray(subItems) &&
              subItems.length > 0 &&
              subItems.map((res) => {
                const {
                  subItemsLabel, subItemsIcon, subItemsPath, ctypeId,
                  subDesc,
                } = res || {};
                return (
                  <Fragment>
                    {
                      (roleId === 3 ? true : (((isSuperAdmin === true || isAdmin === true || (propCtypeId && Number(propCtypeId) === 1)) && ctypeId.includes(0)) ? true : (ctypeId && ctypeId.includes(Number(propCtypeId))))) && (
                        <Fragment>
                          <div className="d-flex bx--col-lg-3 ml-2 w-100">
                            <div>
                              <CarbonIconsHeader label={subItemsLabel} />
                            </div>
                            <div>
                              <div className="ml-3 h6 text-dark">
                                <Link
                                  className="font-weight-bold"
                                  onClick={() => handleShowSubMenu([], null, false)}
                                  to={(subItemsLabel === 'institutions' && propCtypeId && Number(propCtypeId) === 2) ? `/app/campus/institution/info/${companyId}` : subItemsPath}>
                                  {t(subItemsLabel)}
                                </Link>
                                <div className="small w-75">
                                  {t(subDesc)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      )
                    }
                  </Fragment>
                );
              })}
          </div>
        </div>
      )}
      <Modal
        open={showSimulatorSubMenu}
        onRequestClose={handleShowSimulatorSubMenu}
        passiveModal
        size='lg'
        modalHeading={t('Simulate')}
      >
        <div className="">
          <div className="">
            <div>
              <div className="mb-2">
                <span className={`xpa-tab mr-1 ${selectedTab === 0 ? "xpa-selected-tab" : "xpa-non-selected-tab"}`} onClick={() => { handleChangeTabClick('students') }}>Students</span>
                <span className={`xpa-tab ${selectedTab === 1 ? "xpa-selected-tab" : "xpa-non-selected-tab"}`} onClick={() => { handleChangeTabClick('company') }}>Company Users</span>
              </div>
              {/* <GenericTabs
                  selected={selectedTab}
                  tabContent={tabContent}
                /> */}
            </div>
            {selectedTab === 0 &&
              <div>
                <div className="meta d-flex justify-content-between w-100 align-items-center">
                  <div>
                    <PagingInfo
                      end={Math.min(
                        Number(count),
                        Number(itemsPerPage) *
                        Number(activePage)
                      )}
                      start={
                        1 +
                        Number(activePage - 1) *
                        Number(itemsPerPage)
                      }
                      totalResults={Number(count)}
                    />
                  </div>
                </div>
                <div className="xpa-table">
                  <GenericDataTable
                    className=""
                    // otherActions={otherActionsButton}
                    headers={
                      columnWithFilter.map((data, index) => (
                        {
                          key: `header_${index}`,
                          header: data.title,
                          className: data.className,
                          colpropName: data.propName,
                        }
                      ))}
                    rows={students && Array.isArray(students)
                      && students.length > 0
                      ? students.map((res) => {
                        const {
                          _source: {
                            userId,
                            picture,
                            profile: {
                              firstName,
                              lastName,
                              email,
                              profileId,
                              suspended,
                            } = {},
                            studentProfileId,
                            studentUniversityId,
                            isOpenMarket,
                            isAppSubmitted,
                            hasAppliedToJobs,
                            isShortlistedByCompany,
                            isCompletedWorkflow,
                            offerInProgress,
                            hasAcceptedOffer,
                            isHired,
                            isSignedOffer
                          } = {},
                        } = res || {};
                        return ({
                          isExpanded: true,
                          id: `row_${studentProfileId}`,
                          // key: `${studentProfileId}`,
                          header_0: (
                            <div className="d-flex">
                              {/* <h6>{`${firstName} ${lastName}`}</h6> */}
                              <div className="mt-2">
                                <Avatar
                                  user={{
                                    firstname: firstName,
                                    lastname: lastName,
                                    picture,
                                  }}
                                  classNameInfo="rounded-circle"
                                  size="60"
                                />
                              </div>
                              <div className="mt-2 ml-2">
                                <div><small><b className="text-dark text-uppercase">{t('studentUniversityId')}</b> {studentUniversityId}</small></div>
                                <div className="bx--type-epsilon text-uppercase">{firstName} {lastName}</div>
                                <div>
                                  <i className="fa fa-envelope-o" aria-hidden="true" />&nbsp;
                            {/* <a href={`mailto:${email}`}> */}
                                  {email}
                                  {/* </a> */}
                                </div>
                                <div>
                                  {
                                    suspended
                                      ? (
                                        <span className="btn btn-sm btn-danger">{t('accountSuspended')}</span>
                                      ) : null
                                  }
                                </div>
                              </div>
                            </div>
                          ),
                          header_1: (
                            <div className="">
                              <Button kind='primary' onClick={() => handleSelect(currentUserId, userId)}>
                                Select
                                </Button>
                            </div>
                          )
                        });
                      }) : []}
                    // selectable={isSuperAdmin || (isAdmin && ctypeId && Number(ctypeId) === 2)}
                    searchable
                    onSearchInputChange={handleSearchSubmit}
                    expandable={false}
                  // batchActionClick={this.handleSelectCustomRegModal}
                  />
                  {
                    students && Array.isArray(students)
                    && students.length > 0
                    && (
                      <Pagination onChange={handlePaginationChange} pageSizes={[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]} page={activePage} totalItems={count} />
                    )
                  }
                  {
                    ((!students)
                      || (students && Array.isArray(students)
                        && students.length <= 0))
                    && <NoStudentsBox />
                  }
                </div>
              </div>
            }
            {selectedTab === 1 &&
              <div>
                <PagingInfo
                  end={Math.min(
                    Number(companiesUsersCount),
                    Number(itemsPerPage) *
                    Number(activePage)
                  )}
                  start={
                    1 +
                    Number(activePage - 1) *
                    Number(itemsPerPage)
                  }
                  totalResults={Number(companiesUsersCount)}
                />
                <div className="xpa-table">
                  <GenericDataTable
                    className=""
                    // otherActions={otherActionsButton}
                    headers={
                      columnWithFilterCompaniesUsers.map((data, index) => (
                        {
                          key: `header_${index}`,
                          header: data.title,
                          className: data.className,
                          colpropName: data.propName,
                        }
                      ))}
                    rows={companiesUsers && Array.isArray(companiesUsers)
                      && companiesUsers.length > 0
                      ? companiesUsers.map((res, index) => {
                        const {
                          firstname,
                          lastname,
                          email,
                          userId,
                          picture,
                          company: {
                            company_name
                          }
                        } = res || {};
                        return ({
                          isExpanded: true,
                          id: `row_${index}`,
                          // key: `${studentProfileId}`,
                          header_0: (
                            <div className="d-flex">
                              {/* <h6>{`${firstName} ${lastName}`}</h6> */}
                              <div className="mt-2">
                                <Avatar
                                  user={{
                                    firstname: firstname,
                                    lastname: lastname,
                                    picture,
                                  }}
                                  classNameInfo="rounded-circle"
                                  size="60"
                                />
                              </div>
                              <div className="mt-2 ml-2">
                                <div><small><b className="text-dark text-uppercase">{t('User ID')}</b> {userId}</small></div>
                                <div className="bx--type-epsilon text-uppercase">{firstname} {lastname}</div>
                                <div>
                                  <i className="fa fa-envelope-o" aria-hidden="true" />&nbsp;
                            {/* <a href={`mailto:${email}`}> */}
                                  {email}
                                  {/* </a> */}
                                </div>
                                {/* <div>
                                  {
                                    suspended
                                      ? (
                                        <span className="btn btn-sm btn-danger">{t('accountSuspended')}</span>
                                      ) : null
                                  }
                                </div> */}
                              </div>
                            </div>
                          ),
                          header_1: (
                            <div className="">
                              <div className="bx--type-epsilon text-capitalize">{company_name}</div>
                            </div>
                          )
                          ,
                          header_2: (
                            <div className="">
                              <Button kind='primary' onClick={() => handleSelect(currentUserId, userId)}>
                                Select
                              </Button>
                            </div>
                          )
                        });
                      }) : []}
                    // selectable={isSuperAdmin || (isAdmin && ctypeId && Number(ctypeId) === 2)}
                    searchable
                    onSearchInputChange={handleCompaniesUsersSearchSubmit}
                    expandable={false}
                  // batchActionClick={this.handleSelectCustomRegModal}
                  />
                  {
                    companiesUsers && Array.isArray(companiesUsers)
                    && companiesUsers.length > 0
                    && (
                      <Pagination onChange={handleCompaniesUsersPaginationChange} pageSizes={[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]} page={activePage} totalItems={companiesUsersCount} />
                    )
                  }
                  {
                    ((!companiesUsers)
                      || (companiesUsers && Array.isArray(companiesUsers)
                        && companiesUsers.length <= 0))
                    && <NoCompaniesUsersBox />
                  }
                </div>
              </div>
            }
          </div>
        </div>

      </Modal>

      <div className="w-100 pt-3">
        <div>
          <section className="section-align-center bx--tile pl-lg-5 pl-4 pr-4 pt-5 mt-3 pr-lg-5 main-application-view w-100">
            {suspended ? (
              <Route
                render={props => (
                  <div className="bx--tile text-center">
                    <div className="h5">Account Suspended</div>
                    <div>
                      This account has been suspended by the Admin. Please
                      contact help-internship@rp.edu.sg.
                    </div>
                  </div>
                )}
              />
            ) : null}
            {!suspended ? (
              <Switch>
                <PrivateRoute
                  isAuthenticated={isUserLoggedIn}
                  products={['common']}
                  product="nosub"
                  path={`${match.url}/contact`}
                  component={Contact}
                />
                <PrivateRoute
                  isAuthenticated={isUserLoggedIn}
                  products={['common']}
                  product="nosub"
                  path={`${match.url}/profile`}
                  component={Profile}
                  // component={StudentProfileView}
                />
                {roleId && roleId !== 3 && (
                  <div className="nc-routes">
                    <Switch>
                      <Route
                        path="/app"
                        render={routeProps => (
                          <RecruiterRoutes
                            isUserLoggedIn={isUserLoggedIn}
                            roleId={roleId}
                            isAdmin={isAdmin}
                            products={products}
                            {...routeProps}
                          />
                        )}
                      />
                      <Route
                        path={`${match.url}/*`}
                        render={props => (
                          <NotFound {...props} roleId={roleId} />
                        )}
                      />
                    </Switch>
                  </div>
                )}
                {roleId && roleId === 3 && (
                  <div className="c-routes">
                    <Switch>
                      <Route
                        path="/app/campus/candidate"
                        render={routeProps => (
                          <CandidateRoutes
                            isUserLoggedIn={isUserLoggedIn}
                            roleId={roleId}
                            {...routeProps}
                          />
                        )}
                      />
                      <Route
                        path={`${match.url}/*`}
                        render={props => (
                          <NotFound {...props} roleId={roleId} />
                        )}
                      />
                    </Switch>
                  </div>
                )}
                {
                  <div className="c-routes">
                    <Switch>
                      <Route
                        path="/robocampus"
                        render={routeProps => (
                          <CampusRoutes
                            isUserLoggedIn={isUserLoggedIn}
                            roleId={roleId}
                            {...routeProps}
                          />
                        )}
                      />
                      <Route
                        path={`${match.url}/*`}
                        render={props => (
                          <NotFound {...props} roleId={roleId} />
                        )}
                      />
                    </Switch>
                  </div>
                }
                <Route
                  path={`${match.url}/*`}
                  render={props => <NotFound {...props} roleId={roleId} />}
                />
              </Switch>
            ) : null}
          </section>
        </div>
      </div>
      <footer>
        <div className="bx--tile text-center mt-2">
          <div>Powered By <img style={{ width: '40px', height: '20px' }} src='https://xcdn.x0pa.ai/xfe/xlogos/xl-brown-transparent-80h.png' alt="X0PA" /> (<a target="_blank" href="https://x0pa.com/">www.x0pa.com</a>)</div>
          <div className="text-center mt-2">
            Copyright &copy; {moment().format('YYYY')}, Ngee Ann Polytechnic. All Rights Reserved.
          </div>
          <div className="text-center mt-2">
            <a target="_blank" href="https://tech.gov.sg/report_vulnerability">Report Vulnerability</a>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default withTranslation()(MainApp);
