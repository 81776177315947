import {
  REQUEST_CREATE_STUDENT_PROFILE,
  SUCCESS_CREATE_STUDENT_PROFILE,
  REQUEST_UPDATE_STUDENT_PROFILE,
  SUCCESS_UPDATE_STUDENT_PROFILE,
  REQUEST_DELETE_STUDENT_PROFILE,
  SUCCESS_DELETE_STUDENT_PROFILE,
  REQUEST_GET_STUDENT_PROFILE,
  SUCCESS_GET_STUDENT_PROFILE,
  REQUEST_CREATE_STUDENT_LANGUAGES,
  SUCCESS_CREATE_STUDENT_LANGUAGES,
  REQUEST_UPDATE_STUDENT_LANGUAGES,
  SUCCESS_UPDATE_STUDENT_LANGUAGES,
  REQUEST_DELETE_STUDENT_LANGUAGES,
  SUCCESS_DELETE_STUDENT_LANGUAGES,
  REQUEST_CREATE_CITY_PREFERENCES,
  SUCCESS_CREATE_CITY_PREFERENCES,
  REQUEST_UPDATE_CITY_PREFERENCES,
  SUCCESS_UPDATE_CITY_PREFERENCES,
  REQUEST_DELETE_CITY_PREFERENCES,
  SUCCESS_DELETE_CITY_PREFERENCES,
  REQUEST_CREATE_INDUSTRY_PREFERENCES,
  SUCCESS_CREATE_INDUSTRY_PREFERENCES,
  REQUEST_UPDATE_INDUSTRY_PREFERENCES,
  SUCCESS_UPDATE_INDUSTRY_PREFERENCES,
  REQUEST_DELETE_INDUSTRY_PREFERENCES,
  SUCCESS_DELETE_INDUSTRY_PREFERENCES,
  ERROR_CREATE_STUDENT_PROFILE,
  ERROR_UPDATE_STUDENT_PROFILE,
  ERROR_DELETE_STUDENT_PROFILE,
  ERROR_GET_STUDENT_PROFILE,
  ERROR_CREATE_STUDENT_LANGUAGES,
  ERROR_UPDATE_STUDENT_LANGUAGES,
  ERROR_DELETE_STUDENT_LANGUAGES,
  ERROR_CREATE_CITY_PREFERENCES,
  ERROR_UPDATE_CITY_PREFERENCES,
  ERROR_DELETE_CITY_PREFERENCES,
  ERROR_CREATE_INDUSTRY_PREFERENCES,
  ERROR_UPDATE_INDUSTRY_PREFERENCES,
  ERROR_DELETE_INDUSTRY_PREFERENCES,
  REQUEST_CREATE_FUNCTION_PREFERENCES,
  REQUEST_UPDATE_FUNCTION_PREFERENCES,
  REQUEST_DELETE_FUNCTION_PREFERENCES,
  SUCCESS_CREATE_FUNCTION_PREFERENCES,
  SUCCESS_UPDATE_FUNCTION_PREFERENCES,
  SUCCESS_DELETE_FUNCTION_PREFERENCES,
  ERROR_CREATE_FUNCTION_PREFERENCES,
  ERROR_UPDATE_FUNCTION_PREFERENCES,
  ERROR_DELETE_FUNCTION_PREFERENCES,
  REQUEST_CREATE_STUDENT_PREFERENCES,
  ERROR_CREATE_STUDENT_PREFERENCES,
  SUCCESS_CREATE_STUDENT_PREFERENCES,
  REQUEST_UPDATE_STUDENT_PREFERENCES,
  ERROR_UPDATE_STUDENT_PREFERENCES,
  SUCCESS_UPDATE_STUDENT_PREFERENCES,
  REQUEST_DELETE_STUDENT_PREFERENCES,
  ERROR_DELETE_STUDENT_PREFERENCES,
  SUCCESS_DELETE_STUDENT_PREFERENCES,
  REQUEST_GET_CITIES_LIST,
  ERROR_GET_CITIES_LIST,
  SUCCESS_GET_CITIES_LIST,
  REQUEST_GET_PREFERRED_SKILLS,
  ERROR_GET_PREFERRED_SKILLS,
  SUCCESS_GET_PREFERRED_SKILLS,
  REQUEST_GET_SKILL_LEARNS,
  SUCCESS_GET_SKILL_LEARNS,
  ERROR_GET_SKILL_LEARNS,
  REQUEST_CREATE_SKILL_LEARN,
  SUCCESS_CREATE_SKILL_LEARN,
  ERROR_CREATE_SKILL_LEARN,
  REQUEST_DELETE_SKILL_LEARN,
  SUCCESS_DELETE_SKILL_LEARN,
  ERROR_DELETE_SKILL_LEARN,
  REQUEST_CREATE_STUDENT_COMPANY_PREFERENCES,
  SUCCESS_CREATE_STUDENT_COMPANY_PREFERENCES,
  ERROR_CREATE_STUDENT_COMPANY_PREFERENCES,
  REQUEST_GET_COMPANY_TYPES,
  SUCCESS_GET_COMPANY_TYPES,
  ERROR_GET_COMPANY_TYPES,
} from '../../actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  cities: [],
  citiesCount: 0,
  preferredSkills: [],
  companyTypes: [],
};

const StudentProfile = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case REQUEST_CREATE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_UPDATE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_DELETE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_GET_STUDENT_PROFILE: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_CREATE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_UPDATE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_DELETE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_CREATE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_UPDATE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_DELETE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_CREATE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_UPDATE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case REQUEST_DELETE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_CREATE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_UPDATE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_DELETE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_GET_STUDENT_PROFILE: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_CREATE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_UPDATE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_DELETE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_CREATE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_UPDATE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_DELETE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_CREATE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_UPDATE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case SUCCESS_DELETE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: null,
        ...payload,
      };
    }
    case ERROR_CREATE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_UPDATE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_DELETE_STUDENT_PROFILE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_GET_STUDENT_PROFILE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_CREATE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_UPDATE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_DELETE_STUDENT_LANGUAGES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_CREATE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_UPDATE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_DELETE_CITY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_CREATE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_UPDATE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_DELETE_INDUSTRY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_CREATE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ERROR_CREATE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case SUCCESS_CREATE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case REQUEST_UPDATE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ERROR_UPDATE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case SUCCESS_UPDATE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case REQUEST_DELETE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: true,
      };
    }
    case ERROR_DELETE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case SUCCESS_DELETE_STUDENT_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_CREATE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case REQUEST_UPDATE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case REQUEST_DELETE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_CREATE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case SUCCESS_UPDATE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case SUCCESS_DELETE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case ERROR_CREATE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_UPDATE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ERROR_DELETE_FUNCTION_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_GET_CITIES_LIST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_GET_CITIES_LIST: {
      return {
        ...state,
        cities: payload && payload.cities,
        citiesCount: payload && payload.count,
        loading: false,
        error: false,
      };
    }
    case ERROR_GET_CITIES_LIST: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_GET_PREFERRED_SKILLS: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_GET_PREFERRED_SKILLS: {
      return {
        ...state,
        preferredSkills: payload,
        loading: false,
        error: false,
      };
    }
    case ERROR_GET_PREFERRED_SKILLS: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_GET_SKILL_LEARNS: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_GET_SKILL_LEARNS: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case ERROR_GET_SKILL_LEARNS: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_CREATE_SKILL_LEARN: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_CREATE_SKILL_LEARN: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case ERROR_CREATE_SKILL_LEARN: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_DELETE_SKILL_LEARN: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_DELETE_SKILL_LEARN: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case ERROR_DELETE_SKILL_LEARN: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_CREATE_STUDENT_COMPANY_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_CREATE_STUDENT_COMPANY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case ERROR_CREATE_STUDENT_COMPANY_PREFERENCES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case REQUEST_GET_COMPANY_TYPES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_GET_COMPANY_TYPES: {
      return {
        ...state,
        companyTypes: payload,
        loading: false,
        error: false,
      };
    }
    case ERROR_GET_COMPANY_TYPES: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export default StudentProfile;
