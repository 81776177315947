import { withTranslation } from 'react-i18next';
import React from 'react';
import { connect } from 'react-redux';
import { login } from 'actions';
import { push } from 'react-router-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from 'carbon-components-react';
import { iconArrowLeft } from 'carbon-icons';

import { fetchUserLoginState } from 'actions/userstate';
import './Login.css';
// import { Mixpanel } from 'components/common/Mixpanel';
import SetMixPanel from 'components/common/GetCurrentPath';
import PublicHeader from 'containers/common/publicHeader';


class ProfessionalLogin extends React.Component {
  state = {
    isLogging: false,
    username: '',
    password: '',
    error: false,
  };

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value,
      error: false,
    });
  }

  openPopup = () => {
    SetMixPanel();
    const purl = `${window.location.origin}/auth/candidate/linkedin`;
    const wwidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const wheight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.height;

    const wleft = ((wwidth / 2) - 400);
    const wtop = ((wheight / 2) - 300);
    const winFeatures = `width=800,height=600,top=${wtop},left=${wleft}`;

    const rpop = window.open(purl, 'lpopup1', winFeatures);
  }

  componentDidMount() {
    window.redirectToDashboard2 = (data, origin) => {
      console.log(data, origin);
    };
    this.props.fetchUserLoginState().then(res => {
      const { user: { userId, roleId } = {} } = this.props;
      if (userId && userId !== null && roleId && roleId === 3) {
        this.props.history.push('/app/campus/candidate/business/finland/start');
      }
    })
  }

  render() {
    const formClass = classNames({
      'form-signin': true,
      'text-center': true,
      'card': true,
      'error': this.state.error,
    });
    
    return (
      <div className="professionallogin login-page col bx--row m-0 p-0">
        <PublicHeader />
        <div className="left-side col-12 col-md-6 col-lg-6 col-xl-6 m-0 p-0 d-flex justify-content-center align-items-center flex-column">
          {/* <Link className="bx--btn bx--btn--ghost mt-3 ml-2" to="/" style={{ color: 'white', background: `rgba(0,0,0,0.4)` }}>
            <Icon className="mr-2" icon={iconArrowLeft} fill="white" description="leftArrow" />
            Go back to home page
          </Link> */}
          <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
            <div className="m-3 text-center p-2" style={{ background: `rgba(0,0,0,0.4)` }}>
              <div className="h1 font-weight-bold"> As a job seeker on X0PA, you’ll get </div>
              <div className="login-list" style={{ fontSize: 18 }}>
                <ol>
                  <li class="m-0 p-0">Skill recommendations for your preferred jobs, helping you become a better match for your prospective employers.</li>
                  <li class="m-0 p-0">Job recommendations matching your profile.</li>
                  <li class="m-0 p-0">A transparent dashboard for the activity on your profile.</li>
                </ol>
              </div>
              <a href="https://www.x0pa.com" target="_blank" className="bx--btn bx--btn--tertiary mt-3" style={{ color: 'white', borderColor: 'white' }}>Learn more</a>
            </div>
          </div>
        </div>
        <div className="right-side d-flex justify-content-center align-items-center col m-0 p-0" style={{ backgroundColor: 'white', height: 768 }}>
          <div className="align-items-center d-flex justify-content-center m-0 row">
            <div className="d-flex justify-content-center">
              <img className="mb-4 mx-auto" src={require('assets/images/republic-polytechnic.png')} />
            </div>
            <div className="bx--col-lg-12 bx--col-md-12 d-flex justify-content-center xpa--login">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-4 d-flex justify-content-around" id="candidate-login" role="tabpanel" aria-labelledby="candidate-login-tab">
                <div className="">
                  <div className="form-signin text-center" style={{ height: 'auto' }}>{/* card */}
                    <div className="mx-auto mt-auto mb-auto">
                      <small className="text-dark bx--type-caption">Sign-in with LinkedIn to get started</small>
                      <div className="boxOr ml-3 pt-2">
                        <div className="cursor-pointer" onClick={() => this.openPopup()}>
                          <img src={require('assets/images/linkedin/Sign-In-Large---Default.png')} style={{ width: "100%" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  login, push, fetchUserLoginState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalLogin);
