import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_CF_TOKEN,
  REQUEST_CF_USER_PROFILES,
  REQUEST_CF_REVOKE_PROFILE,
  REQUEST_CF_USER_CALENDARS,
  REQUEST_CF_EVENT_DATA,
  REQUEST_CF_RETRY_SLOT,
  REQUEST_CF_REFRESH_CALENDARS,
} from './actionTypes';

const requestCfToken = data => (dispatch) => {
  const url = `${baseUrl}/cronofy/token?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CF_TOKEN,
    url,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

const requestCfUserProfiles = (institutionId) => (dispatch) => {
  const url = `${baseUrl}/cronofy/user-profiles?_=${new Date().getTime()}&institutionId=${institutionId}`;
  const params = {
    dispatch,
    actionType: REQUEST_CF_USER_PROFILES,
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

const requestCfUserCalendars = (institutionId) => (dispatch) => {
  const url = `${baseUrl}/cronofy/user-calendars?_=${new Date().getTime()}&institutionId=${institutionId}`;
  const params = {
    dispatch,
    actionType: REQUEST_CF_USER_CALENDARS,
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

const refreshCfUserCalendars = (institutionId) => (dispatch) => {
  const url = `${baseUrl}/cronofy/refresh-user-calendars?_=${new Date().getTime()}&institutionId=${institutionId}`;
  const params = {
    dispatch,
    actionType: REQUEST_CF_REFRESH_CALENDARS,
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

const revokeCfUserProfile = (cfProfileId, connectorId) => (dispatch) => {
  const url = `${baseUrl}/cronofy/revoke-profile?_=${new Date().getTime()}`;
  const data = {
    profileId: cfProfileId,
  };
  if (connectorId && connectorId > 0) {
    data.connectorId = connectorId || 0;
  }
  const params = {
    dispatch,
    actionType: REQUEST_CF_REVOKE_PROFILE,
    url,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

const getEventData = qsObj => (dispatch) => {
  const url = `${baseUrl}/cronofy/rts-cb?_=${new Date().getTime()}`;
  const {
    authState: eventId,
    authResult: result,
  } = qsObj || {};
  if (!eventId || !result) {
    return Promise.reject(new Error({
      message: 'error',
    }));
  }
  const data = {
    eventId,
    result,
    xApiKey: '6a1535ff-2dbb-6432-8f61-5dfd714346be',
  };
  const params = {
    dispatch,
    actionType: REQUEST_CF_EVENT_DATA,
    url,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

const retryBookingSlot = qsObj => (dispatch) => {
  const url = `${baseUrl}/cronofy/rts-cb?_=${new Date().getTime()}`;
  const {
    authState: eventId,
  } = qsObj || {};
  if (!eventId) {
    return Promise.reject(new Error({
      message: 'error',
    }));
  }
  const data = {
    eventId,
    xApiKey: '6a1535ff-2dbb-6432-8f61-5dfd714346be',
    retry: true,
  };
  const params = {
    dispatch,
    actionType: REQUEST_CF_RETRY_SLOT,
    url,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

export {
  requestCfToken,
  requestCfUserProfiles,
  revokeCfUserProfile,
  getEventData,
  retryBookingSlot,
  requestCfUserCalendars,
  refreshCfUserCalendars,
};
