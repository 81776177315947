import React, { Component, Fragment, useEffect, useState  } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import AmpLoader from 'containers/roboroy/JobInfo/AmpLoader';

class PublicAmpPlayer extends Component {
  paths = [{ id: 'roboroy', name: 'Recruiter', href: '/app/roboroy' }];


  constructor(props) {
    super(props);
  }


  render() {
    const {
      location
    } = this.props;

    let params = queryString.parse(this.props.location.search)

    const {url, jwt, id} = params || {};
    // alert(location.search);

    return (
      <div style={{ background: 'white' }} >
        <AmpLoader id={id} url={url} jwt={jwt} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicAmpPlayer);