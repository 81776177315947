import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_MARKETPLACES,
  REQUEST_MARKETAPP,
  REQUEST_COMPANY_MARKETPLACE_MAP,
} from './actionTypes';

const getMarketPlaces = () => (dispatch) => {
  const url = `${baseUrl}/marketplaces?_=${new Date().getTime()}&`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_MARKETPLACES,
  };
  return callApi(params);
};

const updateMarketplaceApp = (data) => (dispatch) => {
  const url = `${baseUrl}/Companymarketplace?_=${new Date().getTime()}&`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_COMPANY_MARKETPLACE_MAP,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  };
  return callApi(params);
};

const getMarketApp = data => (dispatch) => {
  const { id = 0, keyword = '' } = data;
  const url = `${baseUrl}/marketplaces?_=${new Date().getTime()}&marketplaceId=${id}&searchKey=${keyword}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_MARKETAPP,
  };
  return callApi(params);
};

export {
  getMarketPlaces,
  getMarketApp,
  updateMarketplaceApp
};
