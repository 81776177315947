import {
  REQUEST_CREATE_APPL_NOTES,
  REQUEST_GET_APPL_NOTES,
  REQUEST_EDIT_APPL_NOTES,
  REQUEST_DELETE_APPL_NOTES,
} from 'actions/actionTypes';
import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';


const createApplicantNotes = data => (dispatch) => {
  const url = `${baseUrl}/notes?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_APPL_NOTES,
  };

  return callApi(params);
};

const getApplicantNotes = (appId, userId) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No application id passed',
  };
  if (!appId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/notes?appId=${appId}&xuid=${userId || 0}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_APPL_NOTES,
  };

  return callApi(params);
};

const editApplicantNotes = (data, noteId) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No note id passed',
  };
  if (!noteId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/notes/${noteId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_APPL_NOTES,
  };

  return callApi(params);
};

const deleteApplicationNotes = noteId => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No note id passed',
  };
  if (!noteId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/notes/${noteId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_APPL_NOTES,
  };
  return callApi(params);
};

export {
  createApplicantNotes,
  getApplicantNotes,
  editApplicantNotes,
  deleteApplicationNotes,
};
