import React from "react";
import ReactDOM from "react-dom";

import {
  Button,
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "carbon-components-react";

class ModalStateManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }


  setOpen = (setValue) => {
    this.setState({ open: setValue });
  };


  render() {
      const {primaryButtonKind, secondaryButtonKind,className,primaryButtonText, secondaryButtonText,modalHeader,modalBody,launchButtonText,modalSize,launchButtonSize,onRequestSubmit,kind}=this.props
    return (
      <>
        {typeof document === "undefined"
          ? null
          : ReactDOM.createPortal(
              <ComposedModal size={modalSize}
                open={this.state.open}
                onClose={() => this.setOpen(false)}
              >
                <ModalHeader><h3>{modalHeader}</h3></ModalHeader>
                <ModalBody>
                {modalBody}
                </ModalBody>
                <ModalFooter>
                  <Button kind={secondaryButtonKind} onClick={()=>this.setOpen(false)}>
                    {secondaryButtonText}
                  </Button>
                  <Button kind={primaryButtonKind} onClick={onRequestSubmit}>
                    {primaryButtonText}
                  </Button>
                </ModalFooter>
              </ComposedModal>,
              document.body
            )}
        <Button kind={kind} className={className} size={launchButtonSize} onClick={() => this.setOpen(true)}>
          {launchButtonText}
        </Button>
      </>
    );
  }
}

export default ModalStateManager;