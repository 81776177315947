import callApi from 'utils/callApi';
import { baseUrl } from 'utils/config';
import {
  REQUEST_GET_COMPANIES_LIST,
  REQUEST_GET_COMPANIES_FACETS,
} from './actionTypes';

const fetchAllCompaniesList = data => (dispatch) => {
  const { itemsPerPage, activePage, keyword = '', actionName } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/companies/companieslist?`;
  if (
    data &&
    data.Locations &&
    Array.isArray(data.Locations) &&
    data.Locations.length > 0
  ) {
    url += `location=${encodeURIComponent(JSON.stringify(data.Locations))}&`;
  }

  if (
    data &&
    data.Status &&
    Array.isArray(data.Status) &&
    data.Status.length > 0
  ) {
    url += `status=${encodeURIComponent(JSON.stringify(data.Status))}&`;
  }

  if (
    data &&
    data.Companymapstatus &&
    Array.isArray(data.Companymapstatus) &&
    data.Companymapstatus.length > 0
  ) {
    url += `companymapstatus=${encodeURIComponent(
      JSON.stringify(data.Companymapstatus)
    )}&`;
  }
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  if (keyword) {
    url += `searchKey=${keyword}&`;
  }
  const params = {
    dispatch,
    url,
    method: 'GET',
  };
  if (!actionName) {
    params.actionType = REQUEST_GET_COMPANIES_LIST;
  } else {
    params.actionType = actionName;
  }
  return callApi(params);
};

const getCompaniesExport = (data) =>(dispatch)=> {
  const {
    batch,
  } = data;
  let url = `${baseUrl}/companies/exportcompanies?_=${new Date().getTime()}`

  if (batch) {
    url += `&filterArr[batch]=${JSON.stringify(batch)}`
  }
  window.location.href = url;
}

const fetchAllCompaniesFacets = data => (dispatch) => {
  const finalData = {};
  if (
    data &&
    data.Locations &&
    Array.isArray(data.Locations) &&
    data.Locations.length > 0
  ) {
    finalData.Locations = data.Locations;
  }
  if (
    data &&
    data.Status &&
    Array.isArray(data.Status) &&
    data.Status.length > 0
  ) {
    finalData.Status = data.Status;
  }
  if (
    data &&
    data.Companymapstatus &&
    Array.isArray(data.Companymapstatus) &&
    data.Companymapstatus.length > 0
  ) {
    finalData.Companymapstatus = data.Companymapstatus;
  }
  const url = `${baseUrl}/companies/companyfacets?filters=${encodeURIComponent(
    JSON.stringify(finalData)
  )}`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_COMPANIES_FACETS,
    url,
    method: 'GET',
  };
  return callApi(params);
};

export { fetchAllCompaniesList, fetchAllCompaniesFacets, getCompaniesExport };
