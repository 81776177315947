import { useTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SetMixPanel from 'components/common/GetCurrentPath';
import { activeProducts } from 'utils/common';
import { Tooltip } from 'carbon-components-react';

import NavItems from './navitems';
import './SubHeader.css';


class SubHeader extends Component {

    handleMenuClick = (e) => {
      const clickedItem = e && e.currentTarget && e.currentTarget.textContent;
      const pathArray = e && e.currentTarget && e.currentTarget.getAttribute('data-listitempath') && e.currentTarget.getAttribute('data-listitempath') !== null ? e.currentTarget.getAttribute('data-listitempath').split('/'):[];
      const trackData = `LEFTNAV_${(pathArray.length > 2 ? pathArray[2] : 'app').toUpperCase()}_${(clickedItem || 'page').replace(/ /g, '_').toUpperCase()}_CLICKED`;
      SetMixPanel(trackData);
      const link = e && e.currentTarget && e.currentTarget.getAttribute('data-listitempath');
      link && link !== null && this.props.push(link);
    }

    hideSubMenu = (menuIndex, level) => {
      if (level > 0) {
        return false;
      }
      if (window.location.pathname.indexOf(NavItems[menuIndex].name) >= 0) {
        return false;
      }
      return true;
    }

    resetStates = () => {
      for (let prop in this.state) {
        this.state[prop] = '';
      }
    }

    render() {
      const {
        user: { roleId = 0, company: { ctypeId } = {}, isAdmin = false, isSuperAdmin = false }, products = [], companyName = 'company',
        displayMobileSidebar = false, neonPoly = false,
      } = this.props;
      const { location: { pathname } = {} } = window || {};
      return (
        <div className="xpa-subheader bx--col-12 p-3">
          <Menus menus={NavItems} menukey="" isAdmin={isAdmin} isSuperAdmin={isSuperAdmin} neonPoly={neonPoly} handleMenuClick={this.handleMenuClick} hideSubMenu={this.hideSubMenu} level={0} roleId={roleId} ctypeId={ctypeId} />
        </div>
      );
    }
}

const Menus = (inputPorps) => {
  const {
    menus,
    handleMenuClick,
    menukey,
    hideSubMenu,
    level,
    roleId,
    ctypeId,
    neonPoly,
    isAdmin,
    isSuperAdmin,
  } = inputPorps;

  const getListItems = (list, menuIndex) => (list.map((eachItem, itemIndex) => (
    (eachItem.roles.indexOf(roleId) !== -1) && (((isSuperAdmin === true) || (isAdmin === true && eachItem && !(eachItem.ctypeId.includes(0)))) ? true : eachItem && eachItem.ctypeId && eachItem.ctypeId.includes(Number(ctypeId)))
    && <ListItem listItem={eachItem} handleMenuClick={handleMenuClick} hideSubMenu={hideSubMenu} itemkey={menuIndex + '' + itemIndex} key={eachItem.path} />))
  );
  return (
    <div>
      {menus.map((eachMenu, menuIndex) => (
        <ul className={hideSubMenu(menuIndex, level) ? 'nav flex-column d-none' : 'nav flex-column'} data-menuname={eachMenu.name} key={eachMenu.name}>
          {
            (neonPoly && eachMenu && Array.isArray(eachMenu.items)
              ? getListItems(eachMenu.items.filter(o => o.label !== 'Recommendations'), menuIndex)
              : getListItems(eachMenu.items.map(o => o), menuIndex)
            )
          }
        </ul>
      ))}
    </div>
  );
}

const isSelected = (link, path) => {
  return link === path;
};

const ListItem = ({ listItem, itemkey, handleMenuClick, hideSubMenu }) => {
  return (
    <li className={`${isSelected(window.location.pathname.toLowerCase(), listItem.path.toLowerCase()) ? 'active-selected' : ''} nav-item ml-0 bx--col-12 p-0`} data-key={itemkey}>
      {listItem.path ? getList(listItem, handleMenuClick) : (<strong><a>{listItem.label}</a></strong>)}
      {
          listItem && listItem.menus ? (
            <Menus menus={listItem.menus} menukey={itemkey} hideSubMenu={hideSubMenu} handleMenuClick={handleMenuClick} level={1} />
          ) : ''
      }
    </li>
  )
};

const getList = (listItem, onClick) => {
  const { svg, icon, label, path, clName } = listItem;
  const Svg = svg && typeof svg !== 'object' ? <div data-listitempath={path} dangerouslySetInnerHTML={{ __html: svg }} style={{ color: 'white' }} /> : svg;
  return (
    <a href="javascript:void(0)" className="bx--col-12 d-block mb-2 mt-2 p-0 listItem text-center text-white" onClick={onClick} data-listitempath={listItem.path} data-menulabel={listItem.label} style={{ lineHeight: 1 }}>
      <Tooltip
        direction="right"
        triggerText={ Svg || <div aria-hidden="true" className={`iconsweets ${icon} ${clName} bx--type-legal`} style={{ fontSize: 16 }} data-tooltip={label} data-listitempath={path} /> }
        showIcon={false}
      >
        <span className="bx--type-caption font-weight-bold" data-listitempath={listItem.path}>{useTranslation().t(listItem.label)}</span>
      </Tooltip>
      <div className="bx--type-legal d-sm-block d-md-none" data-listitempath={listItem.path}>{useTranslation().t(listItem.label)}</div>
    </a>
  );
};

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  neonPoly: false,
});

export default withRouter(connect(mapStateToProps, {})(SubHeader));
