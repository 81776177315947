import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Button, Modal, TextInput, Form, InlineNotification, Select, SelectItem,
} from 'carbon-components-react';
import { Mixpanel } from 'components/common/Mixpanel';
import isEmail from 'validator/lib/isEmail';

import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import CustomCard from 'components/common/CustomCard';

import { addReferenceInvites, getReferenceInvites } from 'actions/candidate/references';

import './References.css';

const CardContent = ({ references, companyName }) => {
  return (
    <div>
      <small>{`${references.emailAddress}`} </small>
      <br />
      <small className="bx--type-caps">{`${companyName?companyName:''}`}</small>
      <br />
      <small className="badge badge-dark">{`${references.feedbackProvided ? 'Feedback provided' : 'Email sent'}`}</small>
    </div>
  );
};

class CandidateReferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModal: false,
      fullname1: '',
      fullname2: '',
      fullname3: '',
      email: '',
      email1: '',
      email2: '',
      email3: '',
      companyId: 0,
      companyId1: 0,
      companyId2: 0,
      companyId3: 0,
      isError: false,
      errorMessage: '',
      ascDesc: 'DESC',
      itemsPerPage: 10,
      currentSortProp: 'referenceId',
      activePage: 1,
      filterOptions: '',
      sortParam: '',
      invalidEmail: false,
      fullName: '',
      emailAddress: '',
      inviteSent: false,
      invalidcompanyId: false,
      invalidcompanyId1: false,
      invalidcompanyId2: false,
      invalidcompanyId3: false,
      invalidFullName: false,
      companyDefault: 'placeholder-item',
      status: 'given',
      selectedTab: 0,
    };
  }

  componentDidMount() {
    this.props.getReferenceInvites({status:this.state.status});
  }

  getReferenceInvites = () => {
    this.props.getReferenceInvites({status:this.state.status})
      .then((res) => {
        this.setState({
          fullName: '',
          emailAddress: '',
          fullname1: '',
          fullname2: '',
          fullname3: '',
          email: '',
          email1: '',
          email2: '',
          email3: '',
          companyId: 0,
          companyId1: 0,
          companyId2: 0,
          companyId3: 0,
          isError: false,
          invalidEmail: false,
          invalidEmail1: false,
          invalidEmail2: false,
          invalidEmail3: false,
          errorMessage: '',
          invalidcompanyId: false,
          invalidcompanyId1: false,
          invalidcompanyId2: false,
          invalidcompanyId3: false,
          invalidFullName: false,
          companyDefault: 'placeholder-item',
        });
      });
  }
  activateModal = () => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  renderReferenceRows = () => {
    const { references = [], companies = [], response = [] } = this.props;
    const { status } = this.state;
    if (status === 'received') {
      const rows = references && Array.isArray(references) && references.length > 0 && references.map((ref) => {
        let companyName = '';
        const oc = companies
          .filter(e => (Number(e.companyId) === Number(ref.companyId)));
        companyName = oc && oc[0] && oc[0].companyName;
        return {
          isExpanded: false,
          id: `row_${ref.referenceId}`,
          header_0: ref.fullName,
          header_1: ref.emailAddress,
          header_2: companyName,
          header_3: <div className="">{
            ref.feedbackProvided ? <span className="badge badge-outline-success">Completed</span> : <span className="badge badge-outline-warning">Awaiting response</span>
          }</div>,
        };
      });
      return rows || [];
    } else {
      const rows = response
      && response.map((res) => {
        return {
        isExpanded: false,
        id: `row_${res.referenceId}`,
        header_0: `${res.userInfo && res.userInfo.firstname} ${res.userInfo && res.userInfo.lastname}`,
        header_1: res.userInfo && res.userInfo.email,
        header_2: res.userInfo && res.userInfo.company && res.userInfo.company.companyName||"",
        header_3: <Button key="view-feedback-btn" href={`/app/campus/candidate/feedbackgiven/${res.referenceId}`} small kind="primary" > View Feedback </Button>,
        };
      });
      return rows || [];
    }
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
      invalidEmail: false,
      invalidEmail1: false,
      invalidEmail2: false,
      invalidEmail3: false,
      invalidcompanyId1: false,
      invalidcompanyId2: false,
      invalidcompanyId3: false,
      invalidFullName: false,
    });
  }

  handleInvalidEmail = (st) => {
    this.setState({
      [st]: true,
    });
  }

  addReferences = (ev, multiple) => {
    ev.preventDefault();
    if (!multiple) {
      const {
        companyId,
        fullName = '',
      } = this.state;
      if (!fullName || ((fullName + '').trim().length < 2)) {
        this.setState({
          invalidFullName: true,
        });
        return false;
      }
      const isValidEmail = isEmail(this.state.email);
      if (!isValidEmail) {
        this.setState({
          invalidEmail: true,
        });
        return false;
      }
      if (!companyId) {
        this.setState({
          invalidcompanyId: true,
        });
        return false;
      }
      const refInvites = [{
        fullName: this.state.fullName,
        emailAddress: this.state.email,
        companyId: this.state.companyId,
      }];
      this.props.addReferenceInvites(refInvites)
        .then((res) => {
          if (res && res.data && res.data[0] && res.data[0].error) {
            this.setState({
              activeModal: false,
              isError: true,
              errorMessage: res.data[0].error && res.data[0].message,
            });
          } else {
            this.setState({
              inviteSent: true,
              activeModal: false,
              invalidcompanyId: false,
              invalidEmail: false,
            }, () => {
              this.getReferenceInvites({status:this.state.status});
            });
          }
        });
    } else {
      const {
        companyId1, companyId2, companyId3,
        fullname1, fullname2, fullname3,
        email1, email2, email3,
      } = this.state;
      const isValidEmail1 = isEmail(email1);
      const isValidEmail2 = isEmail(email2);
      const isValidEmail3 = isEmail(email3);
      if (!isValidEmail1) {
        this.handleInvalidEmail('invalidEmail1');
        return false;
      }
      if (!isValidEmail2) {
        this.handleInvalidEmail('invalidEmail2');
        return false;
      }
      if (!isValidEmail3) {
        this.handleInvalidEmail('invalidEmail3');
        return false;
      }
      if (!isValidEmail1 || !isValidEmail2 || !isValidEmail3) {
        return false;
      }
      if (!companyId1) {
        this.setState({
          invalidcompanyId1: true,
        });
        return false;
      }
      if (!companyId2) {
        this.setState({
          invalidcompanyId2: true,
        });
        return false;
      }
      if (!companyId3) {
        this.setState({
          invalidcompanyId3: true,
        });
        return false;
      }
      if (this.state.invalidcompanyId1
        || this.state.invalidcompanyId2
        || this.state.invalidcompanyId3) {
        return false;
      }
      const refInvites = [{
        fullName: fullname1,
        companyId: companyId1,
        emailAddress: email1,
      },
      {
        fullName: fullname2,
        companyId: companyId2,
        emailAddress: email2,
      },
      {
        fullName: fullname3,
        companyId: companyId3,
        emailAddress: email3,
      }];
      const uniqueEmailAddresses = new Set([email1, email2, email3]);
      if (uniqueEmailAddresses.size === 3) {
        this.props.addReferenceInvites(refInvites)
          .then((res) => {
            if (res && res.error) {
              this.setState({
                isError: true,
              });
            } else {
              this.setState({
                isError: false,
              }, () => {
                this.getReferenceInvites({status:this.state.status});
              });
            }
          });
      } else {
        this.setState({
          isError: true,
          invalidEmail1: true,
          invalidEmail2: true,
          invalidEmail3: true,
          errorMessage: 'Please add 3 unique valid references.',
        });
      }
    }
  }

  initialReferences = (companies, t = this.props.t) => (
    <Tile className="mb-2 ref-form">
      <h4>{t('addReferences')}</h4>
      <p>{t('referencesFeedback')}</p>
      <Form onSubmit={ev => this.addReferences(ev, true)}>
        {
          [1, 2, 3].map(i => (
            <div className="mt-2 mb-2" key={i}>
              <span className="text-dark bx--type-zeta">
                {t('reference')} #{i}
              </span>
              <div className="bx--row">
                <TextInput
                  required
                  id={`fullname${i}`}
                  name={`fullname${i}`}
                  labelText={t("fullName")}
                  placeholder={t("fullName")}
                  onChange={(ev) => { this.handleChange(ev); }}
                  value={this.state[`fullname${i}`]}
                  maxLength={30}
                  minLength={2}
                  className="mr-2"
                />
                <TextInput
                  required
                  id={`email${i}`}
                  name={`email${i}`}
                  type="email"
                  labelText={t("email")}
                  placeholder={t("email")}
                  onChange={(ev) => { this.handleChange(ev); }}
                  value={this.state[`email${i}`]}
                  invalid={this.state[`invalidEmail${i}`]}
                  invalidText={t('noValidEmail')}
                  maxLength={80}
                />
                {
                  this.companiesDropDown(companies, `companyId${i}`)
                }
              </div>
            </div>))
        }
        <Button type="submit">{t('submit')}</Button>
      </Form>
    </Tile>
  );

  columnSortJobList = ({ propName }) => {
    if(propName === 'action') return;
    const {
      ascDesc,
      currentSortProp,
      activePage,
    } = this.state;
    let sortVal = 'DESC';
    if (currentSortProp && (currentSortProp === propName)) {
      sortVal = (ascDesc && (ascDesc === 'DESC')) ? 'ASC' : 'DESC';
    } else {
      sortVal = 'DESC';
    }
    const qs = ascDescProp => `filter[order][0]=${propName}%20${ascDescProp}`;
    this.setState({
      ascDesc: sortVal,
      sortParam: qs(sortVal),
      activePage: 1,
      currentSortProp: propName,
    }, () => {
      this.props.getReferenceInvites({ 
        itemsPerPage: this.state.itemsPerPage,
        activePage,
        filterOptions: this.state.sortParam,
        status:this.state.status,
      });
    });
  }

  handleInterviewsTabClick = (tabType) => {
    let status = tabType;
    let selectedTab = 0;
    switch (tabType) {
      case 'given':
        selectedTab = 0;
        status = 'given';
        break;
      case 'received':
        selectedTab = 1;
        status = 'received';
        break;
      default:
        selectedTab = this.state.selectedTab;
        status = this.state.status;
        break;
    }

    Mixpanel.track(`References -> ${status}`);

    this.setState({ status, selectedTab }, () => {
      this.getReferenceInvites({status});
    });
  }

  companiesDropDown = (companies = [], name = 'companyId') => {
    const isInavlid = this.state[`invalid${name}`];
    return companies.length > 0 && (
      <Select
        hideLabel={false}
        name={name}
        labelText="Company"
        invalidText="A valid value is required"
        onChange={(ev) => { this.handleChange(ev); }}
        id={`select-${name}`}
        defaultValue={this.state.companyDefault}
        invalid={isInavlid}
      >
        <SelectItem
          disabled
          hidden
          value="placeholder-item"
          text="Choose a company"
        />
        {
          companies.map(c => (
            <SelectItem
              key={c.companyId}
              value={c.companyId}
              text={c.companyName}
            />))
        }
      </Select>
    );
  }

  render() {
    const paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
      { id: 'candidate-references', name: 'References', href: '' },
    ];
    const columnWithFilter = [
      {
        tid: 'ref-full-name',
        title: 'Full Name',
        className: '',
        name: 'fullName',
        propName: 'fullName',
      },
      {
        tid: 'ref-email',
        title: 'Email',
        className: '',
        name: 'email',
        propName: 'email',
      },
      {
        tid: 'ref-company',
        title: 'Company',
        className: '',
        name: 'company',
        propName: 'company',
      },
    ];
    if (this.state.status === "received") {
      columnWithFilter.push({
        tid: 'ref-status',
        title: 'Status',
        className: '',
        name: 'status',
        propName: 'status',
      });
    } else {
      columnWithFilter.push({
        tid: 'ref-action',
        title: 'Action',
        className: '',
        name: 'action',
        propName: 'action',
      });
    }
    const {
      activeModal,
      isError,
      errorMessage,
      invalidEmail,
      inviteSent,
      selectedTab,
      status,
    } = this.state;
    const { references = [], companies = [], loading, response } = this.props;
    const otherActionButtons = [];
    if(status === 'received')
    otherActionButtons.push(
      <Button
        key="add-reference-btn"
        onClick={this.activateModal}
        small
        kind="primary"
      >
        Add Reference
      </Button>
    );
    const { t } = this.props;
    return (
      <Fragment>
        <BackRow paths={paths} />
        <h4 className="bx--type-gamma font-weight-bold text-capitalize">{t('references')}</h4>
        <GenericTabs
          selected={selectedTab}
          tabContent={[
            {
              label: t('feedbackGiven'),
              value: '',
              onClick: (e) => this.handleInterviewsTabClick("given")
            },
            {
              label: t("feedbackReceived"),
              value: '',
              onClick: (e) => this.handleInterviewsTabClick("received")
            },
          ]} />
        {
          isError ? (
            <InlineNotification lowContrast
              kind="error"
              role="alert"
              className="mt-1 mb-1"
              title="Error occured"
              subtitle={errorMessage}
              iconDescription="Closes the notification"
              onCloseButtonClick={() => this.setState({ isError: false })}
            />) : null
        }
        {
          inviteSent ?  (
            <InlineNotification lowContrast
              kind="success"
              role="alert"
              className="mt-1 mb-1"
              title="Email invitation sent successfully"
              subtitle=""
              iconDescription="Closes the notification"
              onCloseButtonClick={() => this.setState({ isError: false })}
            />) : null
        }
        {
          (references.length < 1) && status === 'received' && this.initialReferences(companies)
        }
        {
          (response && response.length < 1) && status === 'given' && (<Tile className="mb-2 ref-form">
            <div className="bx--row flex-column align-items-center">
              <div className="empty-icon">
                <i className="h1 icon-flag" style={{ fontSize: '60px'}} />
              </div>
              <p className="bx--type-beta pt-4 pb-4 mb-0">{t('noReferenceRequest')}</p>
            </div>
          </Tile>)
        }
        {/* {
          <div className="d-lg-none d-md-block d-sm-block ml-1">
            <div className="clearfix">
              <Button
                className="float-right"
                key="add-reference-btn"
                onClick={this.activateModal}
                small
                kind="primary"
              >
                Add Reference
              </Button>
            </div>
            {
              references.map((reference,i) => {
                let companyName = '';
                const oc = companies
                  .filter(e => (Number(e.companyId) === Number(reference.companyId)));
                companyName = oc && oc[0] && oc[0].companyName;
                return (
                  <CustomCard
                    className="card-layout d-block d-md-none"
                    title={`${reference.fullName}`}
                    key={i}
                  >
                    <CardContent
                      references={reference}
                      companyName={companyName}
                    />
                  </CustomCard>
                );
              })
            }
          </div>
        } */}
        { ((status === 'given' && response && response.length > 0) || (status === 'received' && references && references.length > 0))
          && (
            <GenericDataTable
            className="d-non1e d-lg-block"
            title=""
            rowClass="p-0"
            headers={
              columnWithFilter.map((data, index) => (
                {
                  key: `header_${index}`,
                  header: data.title,
                  className: data.className,
                  colpropName: data.propName,
                }
              ))
          }
            rows={this.renderReferenceRows()}
            selectable={false}
            searchable={false}
            expandable={false}
            batchActions={false}
            otherActions={otherActionButtons}
            customSorting={this.columnSortJobList}
          /> 
          )
        }
        <Modal
          onRequestClose={this.dismissModal}
          open={status==='received' && activeModal===true}
          modalLabel="References"
          onRequestSubmit={ev => this.addReferences(ev, false)}
          primaryButtonText="Save"
          secondaryButtonText="Cancel"
          modalHeading="Add Reference"
        >
          <Form onSubmit={ev => this.addReferences(ev, false)}>
            <TextInput
              required
              id="fullname"
              name="fullName"
              labelText="Full Name"
              placeholder="Full Name"
              onChange={(ev) => { this.handleChange(ev); }}
              value={this.state.fullName}
              invalid={this.state.invalidFullName}
              invalidText="Please enter full name"
              maxLength={30}
              minLength={2}
              className="mb-2"
            />
            <TextInput
              required
              id="email"
              name="email"
              type="email"
              labelText="Email"
              placeholder="Email"
              onChange={(ev) => { this.handleChange(ev); }}
              value={this.state.email}
              invalid={invalidEmail}
              invalidText="Please enter valid email address"
              maxLength={80}
              className="mb-2"
            />
            {
              this.companiesDropDown(companies)
            }
          </Form>
        </Modal>
        <JDProcessOverlay show={loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.candidateReferences.loading,
  references: state.candidateReferences.references,
  response: state.candidateReferences.response,
  candidateReferences:state.candidateReferences,
  companies: state.candidateReferences.companies,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getReferenceInvites,
  addReferenceInvites,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CandidateReferences));
