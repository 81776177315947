import React, { Component } from 'react';
import { Button } from 'carbon-components-react';
import * as Sentry from '@sentry/browser';

import './error-boundary.css';

class XPAErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    const { userId, email } = this.props.user;
    Sentry.withScope((scope) => {
      scope.setUser({ email: email || 'unknown@x0pa.ai', xpauid: userId || -1 });
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="xpa-error-boundary">
          <div className="xpa-error-content text-center p-4">
            <div className="bx--type-beta text-center p-2">
              <i className="fa fa-bug fa-2x" />
            </div>
            <h4 className="mb-4">It seems we have encountered an issue</h4>
            <p>
              Our team has been notified about the issue.
              If you would like to help, tell us what happened by reporting it.
            </p>
            <Button
              onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
            >
              Report feedback
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default XPAErrorBoundary;
