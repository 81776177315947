import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Modal, TextArea,
  Pagination, Tile, ModalWrapper,
} from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';

import {
  addQuestion,
  deleteQuestion,
  updateQuestion,
  fetchAll,
} from 'actions/talentSearch/newJob';

import {
  REQUEST_MANAGE_QUESTION,
} from 'actions/talentSearch/actionTypes';

class JobQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      questionsData: [],
      questionValue: '',
      selectedQuestions: [],
      questionBankData: [],
      questionBankCount: 0,
      isEdit: false,
      isQuestionPresent: true,
      showAddToQuestionBank: false,
      // activeModalPage: 1,
      // activeQuestionPage: 1,
      // modalLimit: 10,
      // questionLimit: 10,
    };
  }

  componentDidMount() {
    this.setAllQuestions();
  }

  setAllQuestions = () => {
    const { questionsData } = this.state;
    const selectedIds = Array.isArray(questionsData) && questionsData.map(({ id }) => id);
    const payload = {
      actionType: REQUEST_MANAGE_QUESTION,
      apiName: 'jobquestionbank',
      value: '',
    };
    this.props.fetchAll(payload).then((data) => {
      const { jobQuestions: questions = [] } = data || {};
      let questionsModalData = [];
      questionsModalData = questions.map(({ jobQuestionBankId, question }) => ({
        question,
        action: (
          selectedIds.includes(jobQuestionBankId)
            ? <Button name={jobQuestionBankId} kind="danger" small onClick={this.deleteQuestion}>Delete</Button>
            : <Button name={jobQuestionBankId} className="mr-2" kind="primary" small onClick={this.addQuestion}>Add</Button>
        ),
        id: jobQuestionBankId,
      }));
      this.setState({ questionsModalData }, () => {
        this.setQuestionBank();
        this.setJobQuestions();
      });
    });
  }

  setJobQuestions = (questions) => {
    if (questions) {
      // console.log('setJobQuestions ==> ', questions);
      this.setState({ selectedQuestions: questions }, async () => {
        await this.setQuestions();
        await this.setAllQuestions();
      });
    }
  }

  handleQuestionsTabClick = (tabType) => {
    let selectedTab = 0;
    switch (tabType) {
      case 'jobquestions':
        selectedTab = 0;
        break;
      case 'questions':
        selectedTab = 1;
        break;
      default:
        selectedTab = 0;
        break;
    }
    this.setState({
      selectedTab,
    });
  }

  addQuestionBankModal = () => {
    this.setState({
      showAddToQuestionBank: true,
      questionValue: '',
      isEdit: false,
      isQuestionPresent: true,
    });
  }

  closeQuestionBankModal = () => {
    this.setState({
      showAddToQuestionBank: false,
      questionValue: '',
      isEdit: false,
      isQuestionPresent: true,
    });
  }

  editQuestion = (e) => {
    const { target: { name = null } = {} } = e || {};
    if (name === null) return;
    const { jobCreation: { questions: { jobQuestions } = {} } = {} } = this.props;
    const editQuestion = Array.isArray(jobQuestions)
      ? jobQuestions.filter(({ jobQuestionBankId } = {}) => jobQuestionBankId == name)
      : [];
    if (Array.isArray(editQuestion) && editQuestion.length === 1) {
      const { question: questionValue = '', jobQuestionBankId: selectedQuestionId } = editQuestion[0];
      this.setState({
        showAddToQuestionBank: true,
        questionValue,
        isEdit: true,
        selectedQuestionId,
      });
    }
  }

  setQuestions= () => {
    const { selectedQuestions: jobQuestions } = this.state;
    // console.log('setQuestions => ', jobQuestions);
    const questionsData = Array.isArray(jobQuestions) && jobQuestions.map(({ jobQuestionBankId, question, id }) => ({
      question,
      action: <Button name={jobQuestionBankId || id} kind="danger" small onClick={this.deleteQuestion}>Delete</Button>,
      id: jobQuestionBankId || id,
    }));
    this.setState({ questionsData });
  }

  setQuestionBank = () => {
    const { jobCreation: { questions: { jobQuestions = null } = {} } = {} } = this.props;
    const questionBankData = Array.isArray(jobQuestions) && jobQuestions.map(({ jobQuestionBankId, question }) => ({
      question,
      action: (
        <div className="bx--row m-0">
          <Button name={jobQuestionBankId} className="mr-2" kind="tertiary" small onClick={this.editQuestion}>Edit</Button>
          <Button name={jobQuestionBankId} kind="danger" small onClick={this.deleteFromQuestionBank}>Delete</Button>
        </div>
      ),
      id: jobQuestionBankId,
    }));
    this.setState({ questionBankData });
  }

  deleteFromQuestionBank = ({ target: { name } = {} } = {}) => {
    this.props.deleteQuestion(name).then(() => this.getUpdatedQuestionsData());
  }

  addQuestion = ({ target: { name } = {} } = {}) => {
    const { questionsModalData = [] } = this.state;
    const question = Array.isArray(questionsModalData) && questionsModalData.filter(({ id }) => id == name);
    if (Array.isArray(question) && question.length === 1) this.props.setJobQuestions(question[0].question, 'add');
  }

  deleteQuestion = ({ target: { name } = {} } = {}) => {
    const { questionsModalData = [] } = this.state;
    const question = Array.isArray(questionsModalData) && questionsModalData.filter(({ id }) => id == name);
    if (Array.isArray(question) && question.length === 1) this.props.setJobQuestions(question[0].question, 'delete');
  }

  setModalQuestions = (allQuestions) => {
    const { selectedQuestions, setModalQuestions } = this.state;
    // console.log('setModalQuestions ==> ', allQuestions, selectedQuestions, setModalQuestions);
    let selectedIds = Array.isArray(selectedQuestions) && selectedQuestions.map(({ jobQuestionBankId }) => jobQuestionBankId);
    if (Array.isArray(selectedIds) && selectedIds.length === 0 && Array.isArray(selectedQuestions)) {
      selectedQuestions.map(({ id }) => id);
    }
    const questionsModalData = Array.isArray(allQuestions) ? allQuestions.map(({ jobQuestionBankId, question }) => ({
      question,
      action: (
        selectedIds.includes(jobQuestionBankId)
          ? <Button name={jobQuestionBankId} kind="danger" small onClick={this.deleteQuestion}>Delete</Button>
          : <Button name={jobQuestionBankId} className="mr-2" kind="primary" small onClick={this.addQuestion}>Add</Button>
      ),
      id: jobQuestionBankId,
    })) : [];
    this.setState({ questionsModalData });
  }

  onAddQuestionbankClick = () => {
    const {
      questionValue = null,
      isEdit,
    } = this.state;
    if (!questionValue || questionValue === null || questionValue.length === 0) return this.setState({ isQuestionPresent: false });
    this.setState({
      questionValue: '',
      showAddToQuestionBank: false,
      isQuestionPresent: true,
    }, () => {
      const { companyId } = this.props.user;
      if (!isEdit) {
        this.props.addQuestion({ question: questionValue, companyId }).then(() => this.setAllQuestions());
      } else {
        const { selectedQuestionId: id } = this.state;
        this.props.updateQuestion({ question: questionValue, companyId, id }).then(() => this.setAllQuestions());
      }
      this.setQuestions();
    });
  }

  // modalDataChange = ({ value, limit, skip }) => {
  //   const payload = {
  //     actionType: REQUEST_MANAGE_QUESTION,
  //     apiName: 'jobquestionbank',
  //     value,
  //     limit,
  //     skip,
  //   };
  //   this.props.fetchAll(payload).then((data) => {
  //     const { questions = [] } = data;
  //     let questionsModalData = questions;
  //     const { questionsData } = this.state;
  //     const selectedIds = Array.isArray(questionsData) && questionsData.map(({ id }) => id);
  //     questionsModalData = questionsModalData.map(({ jobQuestionBankId, question }) => ({
  //       question,
  //       action: (
  //         selectedIds.includes(jobQuestionBankId)
  //           ? <Button name={jobQuestionBankId} kind="danger" small onClick={this.deleteQuestion}>Delete</Button>
  //           : <Button name={jobQuestionBankId} className="mr-2" kind="primary" small onClick={this.addQuestion}>Add</Button>
  //       ),
  //       id: jobQuestionBankId,
  //     }));
  //     this.setState({ questionsModalData });
  //   });
  // }

  // onModalSearchChange = ({ target: { value = '' } = {} }) => {
  //   console.log(value);
  //   if (value !== null && value.lenght > 0) {
  //     this.modalDataChange({ limit: 10, skip: 0, value });
  //   }
  // }

  render() {
    const {
      selectedTab,
      questionsData,
      questionBankData,
      questionValue,
      isQuestionPresent,
      showAddToQuestionBank,
      questionsModalData,
      questionBankCount,
      isEdit,
    } = this.state;
    const questionHeaders = [
      { key: 'question', header: 'Question' },
      { key: 'action', header: 'Action' },
    ];
    const questionWrapper = (
      <ModalWrapper id="jobquestion" passiveModal buttonTriggerText="Add Question" modalHeading="Add Question" shouldCloseAfterSubmit>
        <GenericDataTable
          className=""
          title=""
          headers={questionHeaders}
          rows={questionsModalData || []}
          searchable
          onSearchInputChange={(e) => console.log(e)}
        />
        {Array.isArray(questionsModalData) && questionsModalData.length === 0 && (
          <Tile className="bx--type-epsilon pt-2 pb-2 align-items-center d-flex justify-content-center">No questions are added</Tile>
        )}
        {
          Array.isArray(questionsModalData) && questionsModalData.length > 0 && (
            <Pagination onChange={this.handleModalPaginationChange} pageSizes={[10, 20, 30, 40, 50]} totalItems={questionBankCount} />
          )
        }
      </ModalWrapper>
    );
    const questionAction = <Button small onClick={this.addQuestionBankModal}>Add New Question</Button>;
    return (
      <div>
        <h4 class="bx--data-table-v2-header mb-2">Job Questions</h4>
        <GenericTabs
          selected={selectedTab}
          tabContent={[
            {
              label: 'Job Questions',
              value: '',
              onClick: () => this.handleQuestionsTabClick('jobquestions'),
            },
            {
              label: 'Question Bank',
              value: '',
              onClick: () => this.handleQuestionsTabClick('questions'),
            },
          ]}
        />
        {selectedTab === 0 && (
          <div className="bx--col p-0">
            <GenericDataTable
              className=""
              title=""
              headers={questionHeaders}
              rows={questionsData || []}
              otherActions = {[questionWrapper]}
            />
            {Array.isArray(questionsData) && questionsData.length === 0 && (
              <Tile className="bx--type-epsilon pt-2 pb-2 align-items-center d-flex justify-content-center">No questions are added</Tile>
            )}
            <Pagination
              onChange={this.handleLangPaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              totalItems={questionsData.length}
            />
          </div>
        )}
        {selectedTab === 1 && (
          <div className="bx--col p-0">
            <GenericDataTable
              className=""
              title=""
              headers={questionHeaders}
              rows={questionBankData || []}
              otherActions={[questionAction]}
            />
            {Array.isArray(questionBankData) && questionBankData.length === 0 && (
              <Tile className="bx--type-epsilon pt-2 pb-2 align-items-center d-flex justify-content-center">No questions in question bank</Tile>
            )}
            <Pagination
              onChange={this.handleLangPaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              totalItems={questionBankCount}
            />
            <Modal
              open={showAddToQuestionBank}
              modalHeading={`${isEdit ? 'Edit' : 'Add'} Question`}
              primaryButtonText={`${isEdit ? 'Save' : 'Add'}`}
              secondaryButtonText="Cancel"
              onRequestClose={this.closeQuestionBankModal}
              onRequestSubmit={this.onAddQuestionbankClick}
              onSecondarySubmit={this.closeQuestionBankModal}
            >
              <TextArea
                className="questionField full-width"
                labelText="Question"
                invalid={!isQuestionPresent}
                invalidText="Question must not be empty"
                placeholder="Please enter the question"
                rows={4}
                cols={80}
                type="text"
                name="questionValue"
                value={questionValue}
                onChange={({ target: { value = '' } = {} } = {}) => this.setState({ questionValue: value })}
              />
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  candidateProfile: state.roboroyApplications.candidateProfile,
  taskId: state.resumeUpload.taskId,
  jobCreation: state.jobCreation,
});

const mapDispatchToProps = {
  fetchAll,
  addQuestion,
  deleteQuestion,
  updateQuestion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    withRef: true,
    forwardRef: true,
  },
)(JobQuestions);
