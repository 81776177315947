import { SET_YEAR } from '../actions/actionTypes';

const selectedYear = (state = 2015, action) => {
  switch (action.type) {
    case SET_YEAR:
      return action.year;
    default:
      return state;
  }
};
export default selectedYear;
