const emailTemplatesList = {
  application: {
    group: 'application',
    name: 'Application',
    templates: {
      shortlist: {
        name: 'Shortlist Application',
      },
      reject: {
        name: 'Reject Application',
      },
      'passive-introduction-email': {
        name: 'Introduction Email',
      },
      'passive-followup-email': {
        name: 'Follow up Email',
      },
    },
  },
  assessments: {
    group: 'assessments',
    name: 'Assessments',
    templates: {
      'written-assessment': {
        name: 'Written Assessment Invitation',
      },
      'request-retake-exam': {
        name: 'Request Retake Exam',
      },
      'reject-retake-request': {
        name: 'Reject Retake Request',
      },
      'accept-retake-request': {
        name: 'Accept Retake Request',
      },
    },
  },
  collaborator: {
    group: 'collaborator',
    name: 'Collaborator',
    templates: {
      'add-collaborator': {
        name: 'Add Collaborator',
      },
    },
  },
  meeting: {
    group: 'meeting',
    name: 'Online Interview',
    templates: {
      'zoom-meeting-candidate': {
        name: 'Online Meeting Candidate',
      },
      'zoom-meeting-recruiter': {
        name: 'Online Meeting Recruiter',
      },
    },
  },
  offers: {
    group: 'offers',
    name: 'Offers',
    templates: {
      'recruiter-sends-consent': {
        name: 'Send Offer Consent',
      },
      'recruiter-rejects-interview': {
        name: 'Reject Interview',
      },
      'candidate-accepts-consent': {
        name: 'Accepts Offer Consent',
      },
      'candidate-rejects-consent': {
        name: 'Rejects Offer Consent',
      },
      'recruiter-sends-offer-details': {
        name: 'Offer Details',
      },
    },
  },
  Documents: {
    group: 'documents',
    name: 'Documents',
    templates: {
      'recruiter-sends-document-details': {
        name: 'Send Documents Content',
      },
    },
  },
  references: {
    group: 'references',
    name: 'References',
    templates: {
      'request-reference-invite': {
        name: 'Request Reference Invite',
      },
    },
  },
  registration: {
    group: 'registration',
    name: 'Registration',
    templates: {
      'registration-invite': {
        name: 'Registration Invite',
      },
    },
  },
  scheduling: {
    group: 'scheduling',
    name: 'Scheduling',
    templates: {
      'scheduling-book-slot': {
        name: 'Scheduling Book Slot',
      },
      'scheduling-candidate-confirmation': {
        name: 'Slot Candidate Confirmed',
      },
      'scheduling-recruiter-confirmation': {
        name: 'Slot Recruiter Confirmed',
      },
      're-scheduling-book-slot': {
        name: 'Re-scheduling slot',
      },
    },
  },
  share: {
    group: 'share',
    name: 'Share',
    templates: {
      'email-job': {
        name: 'Email Job',
      },
      'email-cv': {
        name: 'Email CV',
      },
    },
  },
  sourcing: {
    group: 'sourcing',
    name: 'AI Sourcing',
    templates: {
      'arya-application': {
        name: 'Arya Application',
      },
    },
  },
  videointerview: {
    group: 'videointerview',
    name: 'Video Interview',
    templates: {
      interview: {
        name: 'Video Interview Invitation',
      },
      'interview-reminder': {
        name: 'Interview Reminder',
      },
    },
  },
  workflow: {
    group: 'workflow',
    name: 'Workflow',
    templates: {
      'workflow-created': {
        name: 'Workflow Created',
      },
      'workflow-status-update': {
        name: 'Workflow Status Update',
      },
      'workflow-action-required': {
        name: 'Workflow Action Required',
      },
      'workflow-assign-substage': {
        name: 'Assign Users to Workflow Stage',
      },
    },
  },
  custom: {
    group: 'custom',
    name: 'Custom Email',
    templates: {
      'custom-email': {
        name: 'Custom Email',
      },
    },
  },
  // 'reference-invite': {

  // },
  // 'scheduling-auto-error': {

  // },
  // 'request-company-access': {

  // },
  // 'reset-password': {

  // },
};

export default emailTemplatesList;
