import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
    REQUEST_USER_TOKEN
} from './actionTypes';

const generateUserToken = data => (dispatch) => {
    const { currentUserId, userIdToSimulate } = data;
    const err = {
        data: '',
        error: true,
        message: 'Missing required fields.',
    };
    if (currentUserId === null || currentUserId === undefined || userIdToSimulate === null || userIdToSimulate === undefined) {
        return Promise.reject(err);
    }
    let url = `${baseUrl}/simulate/login`;

    const params = {
        headers: {
            'content-type': 'application/json',
          },
          dispatch,
          method: 'POST',
          url,
          body: JSON.stringify(data),
          actionType: REQUEST_USER_TOKEN,
    };
    return callApi(params);
}

export { generateUserToken };