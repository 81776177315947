import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
    REQUEST_USER_EMAIL_NOTIFICATIONS
} from './actionTypes';

const getUserEmailNotifications = data => (dispatch) => {
    const { id } = data;
    const err = {
        data: '',
        error: true,
        message: 'Missing required fields.',
    };
    if (id === null || id === undefined ) {
        return Promise.reject(err);
    }
    let url = `${baseUrl}/notification/${id}`;

    const params = {
        headers: {
            'content-type': 'application/json',
          },
          dispatch,
          method: 'GET',
          url,
          actionType: REQUEST_USER_EMAIL_NOTIFICATIONS,
    };
    return callApi(params);
}

export { getUserEmailNotifications };