import {
  REQUEST_GPT,
  ERROR_GPT,
  SUCCESS_GPT,
} from 'actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  globalProfiles: {
    count: 0,
    profiles: [],
  },
};

const globalTalentPool = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_GPT:
      return {
        ...state,
        globalProfiles: [],
        error: null,
        loading: true,
      };
    case ERROR_GPT:
      return {
        ...state,
        globalProfiles: [],
        loading: false,
        error: payload.error,
      };
    case SUCCESS_GPT:
      return {
        globalProfiles: payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default globalTalentPool;
