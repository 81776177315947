import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, TextArea, Button, TextInput } from 'carbon-components-react';
import { CheckmarkOutline16, CheckmarkFilled16, CloseOutline16, CloseFilled16 } from '@carbon/icons-react';
import OfficeDetails from 'components/roboroy/common/OfficeDetails';
import {
  fetchAllOffices,
  addOffice,
  upsertOfficeDetails,
} from 'actions/talentSearch/newJob';
import {
  getLocationsDetails,
} from 'containers/common/campusLogin/validationFunctions';
import { getAllCountries } from 'actions/admin/CompanyAndOffice';
import {
  upsertCompanyDetailsById,
  getCompanyDetailsById,
} from 'actions/InstitutionView';
import {
  getLocations,
} from 'actions/campus/registrationRequest';
import companyLogoPlaceholder from 'assets/images/company.png';
import { updatePictureUrl, uploadLogo } from 'actions/userProfile/action';
import { updateCompanyInfo } from 'actions/admin/AdminSettings';
import {
 getEmployees,
 getEmployeesById,
} from 'actions/BrowseEmployees';
import { getBatchDetails } from 'actions/BatchManagementView';

class EmployeeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { user, employees } = this.props;
  }

  render() {
    const {
    } = this.state;
    const { user, t, history, employees,
      active, contactnumber, designation, email, firstname, iv, lastname,
      picture, userId, companyId, companyName, description,
      domain, website } = this.props;
    const { isAdmin, isSuperAdmin, company: { ctypeId } = {}, } = user || {};

    console.log("this.state::::",this.state)
    console.log("employeesInfo$$$$$$$$$$$$",employees[0])
  
    return (
      <Fragment>
        <div className="bx--row">
          <div className="bx--col-lg-2 ml-3 bx--col-md-3">
            {t('company')} {t('info')}
          </div>
          <div className="bx--col-lg-6 bx--col-md-4">
            <div className="d-flex">
              <div className="bx--col-md-3   text-dark">
                <div>
                  {t('companyName')}:
                </div>
                <div>
                  {t('desc')}:
                </div>
                <div>{t('website')}:</div>
                <div>{t('domain')}:</div>
              </div>
              <div>
                <div className="mb-0  ">
                  {companyName ? companyName : '-'}
                </div>
                <div className="mb-0  ">
                  {description ? description : '-'}
                </div>
                <div className="mb-0  ">
                  {website ? website : '-'}
                </div>
                <div className="mb-0   text-capitalize">
                  {domain ? domain : '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="bx--row">
          <div className="bx--col-lg-2 ml-3 bx--col-md-3">
            {t('user')} {t('info')}
          </div>
          <div className="bx--col-lg-6 bx--col-md-4">
            <div className="d-flex">
              <div className="bx--col-md-3   text-dark">
                <div>
                  {t('firstName')} :
                </div>
                <div>
                  {t('lastName')} :
                </div>
                <div>{t('email')}:</div>
                <div>{t('designation')}:</div>
                <div>{t('phone')}:</div>
                <div>{t('active')}:</div>
              </div>
              <div>
                <div className="mb-0  ">
                  {firstname ? firstname : '-'}
                </div>
                <div className="mb-0  ">
                  {lastname ? lastname : '-'}
                </div>
                <div className="mb-0  ">
                  {email ? email : '-'}
                </div>
                <div className="mb-0   text-capitalize">
                  {designation ? designation : '-'}
                </div>
                <div className="mb-0  ">
                  {contactnumber ? contactnumber : '-'}
                </div>
                <div className="mb-0">
                  {active === true ? <span className="xpa-bx--tag--s bx--tag--green">Active<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">In Active<CloseOutline16 className="ml-1" width='12' /></span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  employees: state.BrowseEmployees.employees,
  count: state.BrowseEmployees.count,
  company: state.jobs.company,
  companies: state.BrowseCompanies.companies,
  batchDetails: state.batchDetails.batchDetails,
});

const mapDispatchToProps = {
  getEmployees,
  getBatchDetails,
  getEmployeesById,
  getBatchDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EmployeeInfo));
