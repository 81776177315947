import {
  REQUEST_GET_COMPANY_DETAILS_BY_ID,
  SUCCESS_GET_COMPANY_DETAILS_BY_ID,
  ERROR_GET_COMPANY_DETAILS_BY_ID,
  REQUEST_EDIT_COMPANY_DETAILS_BY_ID,
  SUCCESS_EDIT_COMPANY_DETAILS_BY_ID,
  ERROR_EDIT_COMPANY_DETAILS_BY_ID,
  REQUEST_GET_USERS_LIST,
  SUCCESS_GET_USERS_LIST,
  ERROR_GET_USERS_LIST,
  REQUEST_GET_STUDENTS_LIST_ADMIN,
  SUCCESS_GET_STUDENTS_LIST_ADMIN,
  ERROR_GET_STUDENTS_LIST_ADMIN,
  REQUEST_CREATE_FACULTY,
  SUCCESS_CREATE_FACULTY,
  ERROR_CREATE_FACULTY,
  REQUEST_DELETE_FACULTY,
  SUCCESS_DELETE_FACULTY,
  ERROR_DELETE_FACULTY,
  REQUEST_EDIT_FACULTY,
  SUCCESS_EDIT_FACULTY,
  ERROR_EDIT_FACULTY,
  REQUEST_CREATE_COURSE,
  SUCCESS_CREATE_COURSE,
  ERROR_CREATE_COURSE,
  REQUEST_EDIT_COURSE,
  SUCCESS_EDIT_COURSE,
  ERROR_EDIT_COURSE,
  REQUEST_DELETE_COURSE,
  SUCCESS_DELETE_COURSE,
  ERROR_DELETE_COURSE,
  REQUEST_GET_JOB_PROFILE_LIST_MATCHING,
  SUCCESS_GET_JOB_PROFILE_LIST_MATCHING,
  ERROR_GET_JOB_PROFILE_LIST_MATCHING,
  REQUEST_CREATE_USER_FOR_COMPANY,
  SUCCESS_CREATE_USER_FOR_COMPANY,
  ERROR_CREATE_USER_FOR_COMPANY,
  REQUEST_GET_STATUS_MATCHING,
  SUCCESS_STATUS_MATCHING,
  ERROR_GET_STATUS_MATCHING,
  REQUEST_UPSERT_COMP_ADDL_INFO,
  SUCCESS_UPSERT_COMP_ADDL_INFO,
  ERROR_UPSERT_COMP_ADDL_INFO,
} from 'actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  companyDetails: {},
  usersList: [],
  usersCount: 0,
  studentsCount: 0,
  students: [],
  taskId: '',
  taskState: '',
};

const InstitutionView = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_GET_COMPANY_DETAILS_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_COMPANY_DETAILS_BY_ID:
      return {
        ...state,
        companyDetails: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_COMPANY_DETAILS_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_EDIT_COMPANY_DETAILS_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_EDIT_COMPANY_DETAILS_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_EDIT_COMPANY_DETAILS_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_USERS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_USERS_LIST:
      return {
        ...state,
        usersList: (payload && payload.users) || [],
        usersCount: (payload && payload.count) || 0,
        loading: false,
        error: null,
      };
    case ERROR_GET_USERS_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_STUDENTS_LIST_ADMIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STUDENTS_LIST_ADMIN:
      return {
        ...state,
        students: (payload && payload.students) || [],
        studentsCount: (payload && payload.count) || 0,
        loading: false,
        error: null,
      };
    case ERROR_GET_STUDENTS_LIST_ADMIN:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_FACULTY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_FACULTY:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_FACULTY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_FACULTY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_FACULTY:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_FACULTY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_EDIT_FACULTY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_EDIT_FACULTY:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_EDIT_FACULTY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_COURSE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_COURSE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_COURSE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_EDIT_COURSE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_EDIT_COURSE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_EDIT_COURSE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_COURSE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_COURSE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_COURSE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_JOB_PROFILE_LIST_MATCHING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_JOB_PROFILE_LIST_MATCHING:
      return {
        ...state,
        taskId: payload && payload.resData.task_id,
        loading: false,
        error: null,
      };
    case ERROR_GET_JOB_PROFILE_LIST_MATCHING:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_USER_FOR_COMPANY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_USER_FOR_COMPANY:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_USER_FOR_COMPANY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_STATUS_MATCHING:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUCCESS_STATUS_MATCHING:
      return {
        ...state,
        taskState: payload && payload.task_state,
        loading: false,
        error: null,
      };
    case ERROR_GET_STATUS_MATCHING:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_COMP_ADDL_INFO:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUCCESS_UPSERT_COMP_ADDL_INFO:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_COMP_ADDL_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default InstitutionView;
