import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loading } from 'carbon-components-react';

import {getDocumentById} from 'actions/document';


class ViewCv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cv: '',
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { match: { params: { id } = {} } = {} } = this.props;
    this.props.getDocumentById(id)
      .then((res) => {
        if (res && !res.error) {
          console.log('RES', res);
          const { resp, type: type1 } = res || {};
          const { type: type2 } = resp || {};
          if (this.viewableFile(type1) || this.viewableFile(type2)) {
            console.log('RES', resp instanceof Blob, Object.prototype.toString.call(resp));
            let c;
            if (resp instanceof Blob || Object.prototype.toString.call(resp) === '[object Blob]') {
              console.log('GOT RESP');
              c = URL.createObjectURL(resp);
            } else {
              c = URL.createObjectURL(res);
            }
            this.setState({
              loading: false,
              cv: c,
              mimeType: type1 || type2,
            });
          } else {
            const a = document.createElement('a');
            const url = URL.createObjectURL(resp || res);
            a.href = url;
            let fName = (res && res.fileName) || (resp && resp.fileName)
            let fileName = 'document.docx'
            if(fName){
              fileName = fName
            }
            else{
              fileName =  this.getDocumentName(type1, type2, fName);
            }
            // const fileName = this.getDocumentName(type1, type2, fName);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              this.setState({
                loading: false,
                downloaded: true,
                documentName: fileName,
                cv: null,
              });
            }, 100);
          }
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  getNameFromMimeType = (mtypeLower) => {
    const now = Date.now();
    let fileName = `Document-${now}`;
    if (mtypeLower === 'image/jpeg') {
      fileName = `Document-${now}.jpeg`;
    }
    if (mtypeLower === 'image/png') {
      fileName = `Document-${now}.png`;
    }
    if (mtypeLower === 'image/svg+xml') {
      fileName = `Document-${now}.svg`;
    }
    if (mtypeLower === 'image/webp') {
      fileName = `Document-${now}.webp`;
    }
    if (mtypeLower === 'application/msword') {
      fileName = `Document-${Date.now()}.doc`;
    }
    if (mtypeLower === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      fileName = `Document-${Date.now()}.docx`;
    }
    return fileName;
  }

  getDocumentName = (type1, type2) => {
    const now = Date.now();
    let fileName = `Document-${now}.docx`;
    if (type1) {
      const mtypeLower = type1.toLowerCase();
      fileName = this.getNameFromMimeType(mtypeLower);
    }
    if (type2) {
      const mtypeLower = type2.toLowerCase();
      fileName = this.getNameFromMimeType(mtypeLower);
    }
    return fileName;
  }

  viewableFile = (type) => {
    return ['application/pdf'].includes(type);
    // return ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp', 'application/pdf'].includes(type);
  }

  render() {
    const { cv, loading, error, downloaded, documentName, mimeType } = this.state;
    return (
      <div className="bx--row xpa-vh-100 justify-content-center align-items-center ml-2 mr-2">
        {
          error && (
            <h1>
              Unable to view/download the document
            </h1>
          )
        }
        {
          loading && !error && <Loading />
        }
        {
          downloaded && !loading && !error && (
            <div>
              <h2>{`${documentName} downloaded successfully`}</h2>
            </div>
          )
        }
        {
          !loading && !error && cv && (
            <embed
              src={`${cv}`}
              type={mimeType || "application/pdf"}
              style={{
                width: '100vw',
                height: '100vh',
              }}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getDocumentById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCv);
