import {
  REQUEST_ANALYTICS_DATA,
  SUCCESS_ANALYTICS_DATA,
  REQUEST_ANALYTICS_ACTIVITY_REPORTS,
  SUCCESS_ANALYTICS_ACTIVITY_REPORTS,
  ERROR_ANALYTICS_ACTIVITY_REPORTS,
  REQUEST_ANALYTICS_LOGS,
  SUCCESS_ANALYTICS_LOGS,
  ERROR_ANALYTICS_LOGS,
} from '../actions/actionTypes';

const initState = {
  loading: false,
  analytics: {},
  activityReports: {},
  logs: [],
  count: 0,
};

const Analytics = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case REQUEST_ANALYTICS_DATA: {
      return {
        ...state,
        loading: true,
      }
  };
    case SUCCESS_ANALYTICS_DATA: {
      return {
        ...state,
        loading: false,
        analytics: payload,
      }
  };
  case REQUEST_ANALYTICS_ACTIVITY_REPORTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ANALYTICS_ACTIVITY_REPORTS:
      return {
        ...state,
        activityReports: payload,
        loading: false,
        error: null,
      };
    case ERROR_ANALYTICS_ACTIVITY_REPORTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ANALYTICS_LOGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ANALYTICS_LOGS:
      return {
        ...state,
        logs: payload && payload.result,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_ANALYTICS_LOGS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default Analytics;
