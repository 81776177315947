import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getNormalized } from 'utils/normalize';
import Avatar from 'components/common/Avatar';
import ApplicantInfo from 'containers/roboroy/ApplicantInfo/ApplicantInfo';
import {
  SkeletonText, Pagination, Button, InlineNotification, StructuredListWrapper, StructuredListHead,
  StructuredListBody, StructuredListRow, StructuredListCell, Select, SelectItem, TooltipIcon,
  Modal, RadioButton, Tile,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import BackRow from 'components/common/BackRow/BackRow';
import XopaTable from 'containers/roboroy/XopaTable';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import Toast from 'components/common/Toast';
import ColorLegend from 'components/common/ColorLegend';
import CustomCard from 'components/common/CustomCard';
import ColoredCircle from 'components/common/ColoredCircle';
import CreateWorkflowModal from 'components/roboroy/WorkflowModule/createWorkflow';
import WorkflowStatusUpdateModal from 'components/roboroy/WorkflowModule/workflowStatusUpdate';
import { ApplicationBatchUpdateModal } from 'components/roboroy/common/ApplicationBatchUpdateModal';
import EmailModal from 'components/roboroy/common/EmailModal';
import ApplicantsTabs from 'components/common/ApplicantTabs';
import ProductTour from 'components/common/ProductTour/ProductTour';
import {AISourcedWithNoEmail, AISourcedHelpInfo, AISourcedEmailMissingWarning} from 'components/common/atoms';
import SetMixPanel from 'components/common/GetCurrentPath';
import { getCustomEmailTemplates, getEmailTemplates } from 'actions/email-templates';
import { sendEmails } from 'actions/talentSearch/talentPool';
import { getMoreCandidatesAiSourcing } from 'actions/talentSearch/candidatesList';

import {
  reRenderContent,
  findFromUserTalentPool,
  setShortlistStatus,
  addApplicantToTalentPool,
  bulkShortlistReject,
  bulkLike,
  getRefreshScores,
} from 'actions/talentSearch/candidatesList';

import {
  getWorkflowStages,
  getAllUserWorkflow,
} from 'actions/workflow';

import { sendEmailTemplate } from 'actions/email-templates';

import { getBucketNames, createBucket } from 'actions/talentSearch/buckets';

import './CandidatesListXopa.css';

const ApplicantScores = ({ application, minMax, getNormalized }) => (
  <div className="applicant-score-card p-0 pt-1">
    <div className="container xp-reset">
      <div className="row">
        <div className={`space-top col text-center text-uppercase ${(application && application.skillMatch === null) ? ' mb-0 ' : ''}`}>
          <h3 className={`xp-c-text-align__center ${(application && application.skillMatch === null) ? ' mb-0 ' : ''}`}>
            {application && application.skillMatch === null ? (
              <SkeletonText className="xpa-circle--skeleton mb-0" />
            ) : (
                  <ColoredCircle
                    className="xp-c__colored-circle--small"
                    getColor="getAbsoluteMatchingColor"
                    value={application && application.skillMatch ? Math.round(application.skillMatch) : (application && application.skillMatch === null ? 'N/A' : 0)} 
                  />
                )
            }
          </h3>
          <small className="text-uppercase">CV Relevance (%)</small>
        </div>
        <div className={`space-top col text-center text-uppercase ${(application && application.loyalty === null) ? ' mb-0 ' : ''}`}>
          <h3 className={`xp-c-text-align__center ${(application && application.loyalty === null) ? ' mb-0 ' : ''}`}>
            {application && application.loyalty === null ? (
              <SkeletonText className="xpa-circle--skeleton mb-0" />
            ) : (
                  <ColoredCircle
                    className="xp-c__colored-circle--small"
                    getColor="getAbsoluteMatchingColor"
                    value={(application && application.loyalty) ? ((application.loyalty == -1) ? 'N/A' : Math.round(application.loyalty)) : (application && application.loyalty === null ? 'N/A' : 0)}
                  />
                )
              }
          </h3>
          <small className="text-uppercase">Loyalty (%)</small>
        </div>
        <div className={`space-top col text-center text-uppercase ${(application && application.performance === null) ? ' mb-0 ' : ''}`}>
          <h3 className={`xp-c-text-align__center ${(application && application.performance === null) ? ' mb-0 ' : ''}`}>
            {application && application.performance === null ? (
              <SkeletonText className="xpa-circle--skeleton mb-0" />
            ) : (
                  <ColoredCircle
                    className="xp-c__colored-circle--small"
                    getColor="getAbsoluteMatchingColor"
                    value={(application && application.performance) ? ((application.performance == -1) ? 'N/A' : Math.round(application.performance)) : (application && application.performance === null ? 'N/A' : 0)}
                  />
                )
              }
          </h3>
          <small className="text-uppercase">Performance (%)</small>
        </div>
      </div>
    </div>
  </div>
);


class NewCandidatesListXopa extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      match: {
        params: {
          id, status, page, limit,
        },
      },
      location: {
        search,
      } = {},
    } = this.props;

    const shortlistStatus = this.getShortlistStatus(status);
    this.setState({
      activePage: (page && parseInt(page, 10)) || 1,
      itemsPerPage: (limit && parseInt(limit, 10)) || 10,
      status: status || 'applied',
    }, () => {
      if (id) {
        this.setState({
          jobId: id,
          status,
          selectedTab: this.getSelectedTabByStatus(status),
        }, () => {
          let finalData = {};
          if (search) {
            finalData = this.handleSearchQuery(search);
          }
          const data = {
            shortlistStatus,
            status,
            itemsPerPage: this.state.itemsPerPage,
            activePage: this.state.activePage || 1,
            jobId: id,
            searchKey: this.state.keyword
          };
          const filterData = { ...finalData, ...data };
          this.props.reRenderContent(filterData, true);
        });
      } else {
        this.setState({
          status,
          selectedTab: this.getSelectedTabByStatus(status),
        }, () => {
          let finalData = {};
          if (status !== 'processing' && status !== 'applied') {
            this.props.history.push({
              pathname: `/app/campus/candidates/xopa/${status}`,
              search: `?applicationStatus=${status}&`,
            });
            if (this.props.history && this.props.history.location && this.props.history.location.search) {
              finalData = this.handleSearchQuery(this.props.history && this.props.history.location && this.props.history.location.search);
            }
          }
          if (search) {
            finalData = this.handleSearchQuery(search);
          }
          const data = {
            shortlistStatus,
            status,
            itemsPerPage: this.state.itemsPerPage,
            activePage: 1,
            jobId: 0,
            searchKey: this.state.keyword,
          };
          const filterData = { ...finalData, ...data };
          this.props.reRenderContent(filterData, true);
        });
      }
      this.props.getAllUserWorkflow();
      this.props.getWorkflowStages();
      this.props.getBucketNames();
      this.props.getEmailTemplates();
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      applications: { applications },
    } = nextProps;
    this.setState({
      selectedAppId: Array.isArray(applications) && applications[0] && applications[0].appId ? applications[0].appId : null,
    });

    const { history, match } = this.props;
    const { id } = match.params;
    const { status } = nextProps.match.params;
    if (this.state.selectedTab !== 0) {
      this.setState({
        selectedTab: !this.state.selectedTab ? this.getSelectedTabByStatus(status) : this.state.selectedTab
      });
    }
  }

  handleSearchQuery = (search) => {
    const searchSplit = search.split('?');
    const resSplt = searchSplit[1].split('&');
    const finalVal = {};
    resSplt && Array.isArray(resSplt) && resSplt.length > 0
      && resSplt.forEach((res) => {
        if (res) {
          const valSpl = res.split('=');
          let valueArr = [];
          if (valSpl[1].includes(',')) {
            valueArr = valSpl[1].split(',');
          } else {
            valueArr = [valSpl[1]];
          }
          finalVal[valSpl[0]] = valSpl[0];
          finalVal[`filterName_${valSpl[0]}`] = valueArr;
        }
      });
      return finalVal;
  }

  getShortlistStatus = (status) => {
    if (status) {
      if (status === 'shortlisted') {
        return true;
      } else if (status === 'rejected') {
        return false;
      }
    }
    return null;
  };

  getSelectedTabByStatus = (status) => {
    if (status) {
      if (status === 'processing') {
        return 0;
      } else if (status === 'is_liked') {
        return 1;
      } else if (status === 'screening') {
        return 2;
      } else if (status === 'shortlisted') {
        return 3;
      } else if (status === 'rejected') {
        return 4;
      } else if (status === 'recommended') {
        return 5;
      }
      return 0;
    }
    return 0;
  };

  fetchJobApplicants = (id, shortlistStatus) => {
    if (this.state.jobId || id) {
      this.props.reRenderContent({ jobId: this.state.jobId || id, shortlistStatus }, true);
    } else {
      this.props.reRenderContent({
        itemsPerPage: this.state.itemsPerPage,
        activePage: 1,
        shortlistStatus,
        searchKey: this.state.keyword
      }, true);
    }
  };

  getCard = (app) => {
    const {
      appId = null,
      shortlisted,
      inviteaccept,
      candidateInfo: [candidateinfo] = [],
      profile: { firstName = null, lastName = null, totalExp } = {},
      job: { jobname: { nameName = null } = {} },
      location = null,
    } = app || {};
    const { userInfo: { picture = null } = {} } = candidateinfo || {};
    
    return (
      <Fragment>
        <div className=""><small className="text-dark">Application Id: {appId}</small></div>
        <div className="xpa-link d-flex text-capitalize bx--type-delta">  
          {(<Avatar
            user={{
              firstname: firstName,
              lastname: lastName,
              picture: shortlisted && inviteaccept ? picture : null,
            }}
            size="30"
            classNameInfo="rounded-circle mr-1"
          />)}
          {firstName !== null && <span className="mr-1">{firstName}</span>}
          {lastName !== null && <span>{lastName}</span>}
          {firstName === null && lastName === null && <span>Name Unidentified</span>}
        </div>
        <div className="bx--row m-0 text-dark">
          {location !== null && (
            <div className="mr-4">
              <small>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 12, height: 12 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin">
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                {` ${location}`}
              </small>
            </div>
          )}
          <div className="mr-2">
            <small>
              <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 12, height: 12 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-briefcase">
                <rect x="2" y="7" width="20" height="14" rx="2" ry="2" />
                <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
              </svg>
              {totalExp != 0 ? (Math.round(totalExp) > 1 ? ` ${Math.round(totalExp)} years experience` : ` ${Math.round(totalExp)} year experience`) : ' No experience'}
            </small>
          </div>
        </div>
        {/* <div className="">
          {roboroyShortlist && (
            <div>
              <div className="bx--type-epsilon">
                <span aria-hidden="true" className="fa fa-thumbs-up text-success" />
                <small className="ml-1">Roboroy upvotes this candidate</small>
              </div>
            </div>
          )}
          {!roboroyShortlist && (
            <div>
              <div className="bx--type-epsilon">
                <span aria-hidden="true" className="fa fa-thumbs-down text-danger" />
                <small className="ml-1">Roboroy downvotes this candidate</small>
              </div>
            </div>
          )}
        </div> */}
        <ApplicantScores application={app} minMax={app.minMax} getNormalized={getNormalized} />
      </Fragment>
    );
  }

  onCardClick = (e) => {
    const { currentTarget } = e;
    if (currentTarget) this.setState({ selectedAppId: Number(currentTarget.getAttribute('name')) }, () => (
      this.refs.appPofile && this.refs.appPofile.getWrappedInstance && this.refs.appPofile.getWrappedInstance().updateComponent(this.state.selectedAppId)
    ));
  }

  render() {
    const { history, applications: { applications } } = this.props;
    const { selectedAppId, jobId } = this.state;
    const applicationInfoUrl = `${window.location.origin}/app/campus/new/candidate/app/${selectedAppId}/job/${jobId}`;

    return (
      <div className="component-container bx--row m-0">
        <div className="leftcards-container bx--col-md-3 bx--tile p-0 d-md-none d-lg-block">
          <div className="leftcards-container-child">
            { Array.isArray(applications) && applications.map(
              app => <div className={`${selectedAppId == app.appId ? 'selected-card' : '' } left-card bx--type pl-2 pt-2`} name={app.appId} onClick={this.onCardClick} key={app.appId}>{this.getCard(app)}</div>
            )}
          </div>
        </div>
        <div className="rightdetails-container bx--col-md-9 pl-3 pt-3">
          <div className="right-maincard-details">Results related to data</div>
          <hr className="p-1" />
          <div className="right-selectedcard-details">
            {/* { selectedAppId !== null && jobId && jobId !== null ? <ApplicantInfo id={selectedAppId} ref="appPofile" jobId={jobId} isChild history={history} /> : null} */}
            { selectedAppId !== null && jobId && jobId !== null ? <iframe title={`ApplicationInfo Iframe - Application Is ${selectedAppId}`} id={`appInfo-iframe-${selectedAppId}`} className="application-iframe" src={applicationInfoUrl} /> : null}
          </div>
        </div>
      </div>
    );
  }
}
 
const mapStateToProps = state => ({
  loading: state.roboroyApplications.loading,
  applications: state.roboroyApplications.allApplications,
  talentSearchComplete: state.roboroyApplications.talentSearchComplete,
  tasks: state.resumeUpload,
  talentSearchPayload: state.roboroyApplications.talentSearchPayload,
  taskStatus: state.taskStatus,
  buckets: state.roboroyTalentPool.buckets,
  user: state.x0paDuser.user,
  workflowstages: state.workflows.workflowstages,
  emailTemplates: state.emailTemplates.templates,
});

const mapDispatchToProps = {
  findFromUserTalentPool,
  reRenderContent,
  setShortlistStatus,
  addApplicantToTalentPool,
  bulkShortlistReject,
  bulkLike,
  getRefreshScores,
  getWorkflowStages,
  getAllUserWorkflow,
  getBucketNames,
  createBucket,
  sendEmailTemplate,
  getCustomEmailTemplates,
  getEmailTemplates,
  sendEmails,
  getMoreCandidatesAiSourcing,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCandidatesListXopa);