import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_USER_INVITATION,
  REQUEST_REJECT_INVITATION,
  REQUEST_ASSO_USER_APPL_MAP,
  REQUEST_OFFER_CONSENT_STATUS,
  REQUEST_GET_OFFER_CONSENT,
} from './actionTypes';

const getUserInvitation = ({
  appId,
}) => (dispatch) => {
  if (!appId) {
    return Promise.reject({
      message: 'Missing required fields',
      statusCode: 401,
    });
  }
  const url = `${baseUrl}/applications/invitations/${appId}?_=${new Date().getTime()}&invite=${appId}&shortlisted=true`;

  const params = {
    actionType: REQUEST_USER_INVITATION,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const rejectInvitation = ({
  invite, appId = 0,
}) => (dispatch) => {
  const url = `${baseUrl}/applications/invitations/${invite}?_=${new Date().getTime()}`;

  const params = {
    actionType: REQUEST_REJECT_INVITATION,
    dispatch,
    url,
    method: 'PATCH',
  };

  return callApi(params);
};

const getOfferConsent = (data) => (dispatch) => {
  const url = `${baseUrl}/applications/offerinvites/${data}?_=${new Date().getTime()}&code=${data}`;
  const params = {
    actionType: REQUEST_GET_OFFER_CONSENT,
    dispatch,
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  };

  return callApi(params);
};

const offerConsentStatus = data => (dispatch) => {
  const {
    inviteHash,
  } = data || {};
  const url = `${baseUrl}/applications/offerinvites/${inviteHash}?_=${new Date().getTime()}`;
  const params = {
    actionType: REQUEST_OFFER_CONSENT_STATUS,
    dispatch,
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: 'PATCH',
  };

  return callApi(params);
};

const updateUserApplicationAndMap = ({
  invite,
  jobId,
  inviteaccept,
  profileId,
}) => (dispatch) => {
  const err = {
    data: null,
    error: true,
    message: 'Missing required fields',
  };
  if (!invite || !jobId || !inviteaccept || !profileId) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/applications/invites/${invite}?_=${new Date().getTime()}`;
  const data = JSON.stringify({
    invite,
    jobId,
    inviteaccept,
    profileId,
  });
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_ASSO_USER_APPL_MAP,
    dispatch,
    url,
    body: data,
    method: 'PATCH',
  };

  return callApi(params);
};

export {
  getUserInvitation, rejectInvitation, updateUserApplicationAndMap, offerConsentStatus,
  getOfferConsent,
};
