import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput, Button, InlineNotification, Select, SelectItem,
} from 'carbon-components-react';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { debounce } from 'lodash';
import isEmail from 'validator/lib/isEmail';

import {
  xUserRegRequest,
  xSearchCompanies,
  xCreateCompany,
} from 'actions/xpa';

class UserRegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompanyObj: {},
      isDropdownLoading: false,
      notifType: 'success',
      notifShow: false,
      notifMessage: 'default message',
      isSubmitLoading: false,
    };
  }

  componentDidMount() {
   
  }

  onInputChange = (evt) => {
    if (evt && evt.target) {
      const { name, value } = evt.target;
      this.setState({
        [`${name}Invalid`]: false,
        [name]: value,
      });
    }
  }

  isInputValid = (input) => {
    if (!input || (input && !input.trim())) {
      return false;
    }
    return true;
  }

  onFormSubmit = () => {
    const {
      firstName,
      lastName,
      selectedCompanyObj,
      email,
      selectedProduct,
    } = this.state;
    const { label: companyName, value: companyId } = selectedCompanyObj || {};
    const invalidObj = {};
    let isInvalid = false;
    const validFirstName = this.isInputValid(firstName);
    if (!validFirstName) {
      invalidObj.firstNameInvalid = true;
      isInvalid = true;
    }
    const validLastName = this.isInputValid(lastName);
    if (!validLastName) {
      invalidObj.lastNameInvalid = true;
      isInvalid = true;
    }
    const validCompanyName = this.isInputValid(companyName);
    if (!validCompanyName) {
      invalidObj.companyNameInvalid = true;
      isInvalid = true;
    }
    const validEmail = this.isInputValid(email);
    if (!validEmail || !isEmail(email)) {
      invalidObj.emailInvalid = true;
      isInvalid = true;
    } else {
      const domains = [
        '@gmail', '@yahoo', '@hotmail', '@outlook',
        '@msn', '@comcast', '@live', '@aol',
      ];
      domains.forEach((tld) => {
        if (email.includes(tld)) {
          invalidObj.emailInvalid = true;
          isInvalid = true;
        }
      });
    }

    const validSelectedProduct = this.isInputValid(selectedProduct);
    if (!validSelectedProduct) {
      invalidObj.productNameInvalid = true;
      isInvalid = true;
    }
    
    const loadState = {
      notifShow: false,
      isSubmitLoading: true,
    };
    this.setState({ ...invalidObj, ...loadState });
    if (!isInvalid) {
      this.props.xUserRegRequest({
        firstName,
        lastName,
        companyId,
        email,
        companyName,
        productName: selectedProduct,
      })
        .then((res) => {
          const { error } = res || {};
          if (!error && res) {
            this.setState({
              notifShow: true,
              notifType: 'success',
              notifMessage: 'Thank you for signing up, our sales team will contact you shortly.',
              isSubmitLoading: false,
            });
          } else {
            throw new Error((error && error.message) || 'Unknown error occured.');
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            notifShow: true,
            notifType: 'error',
            notifMessage: (err && err.message) || 'Unknown error occured.',
            isSubmitLoading: false,
          });
        });
    } else {
      this.setState({
        isSubmitLoading: false,
      });
    }
  }

  onInputCompanyDropdown = (value) => {
    if (/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value)) {
      if (value.length >= 2) {
        return this.props.xSearchCompanies(value).then((res) => {
          if (res && Array.isArray(res)) {
            const options = res.map(one => ({ label: one.company_name, value: one.company_id }));
            return options;
          }
          return [{ label: 'X', value: -1 }];
        });
      }
    }
  };

  onCreateCompany = (com) => {
    this.setState({
      isDropdownLoading: true,
    });
    if (!com) {
      return;
    }
    this.props.xCreateCompany(com.toLowerCase()).then((newCompany) => {
      const { companyName, companyId, error } = newCompany || {};
      if (error) {
        console.error(error);
        return;
      }
      this.setState({
        selectedCompanyObj: { label: companyName, value: companyId },
        companyNameInvalid: false,
      });
    }).finally(() => {
      this.setState({
        isDropdownLoading: false,
      });
    });
  }

  handleChangeCompany = (e) => {
    this.setState({
      selectedCompanyObj: e,
      companyNameInvalid: false,
    });
  }

  handleCloseNotification = () => {
    this.setState({
      notifShow: false,
      notifType: 'success',
      notifMessage: 'default message',
    });
  }

  render() {
    const {
      notifShow, notifType, notifMessage,
      isSubmitLoading, firstNameInvalid, lastNameInvalid,
      companyNameInvalid, isDropdownLoading, emailInvalid,
      productNameInvalid,
    } = this.state;
    return (
      <div className="xpa-user-register">
        <div className="bx--type-beta text-center pt-4 pb-4">Signup for X0PA to get started!</div>
        <div className="mt-2 mb-2">
          {
            notifShow && (
              <InlineNotification lowContrast
                className="mb-0 mt-0"
                title={notifMessage}
                subtitle=""
                kind={notifType}
                onCloseButtonClick={this.handleCloseNotification}
              />
            )
          }
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <TextInput
              required
              id="firstName"
              className="mb-2 mr-2"
              name="firstName"
              labelText="First Name"
              placeholder="Jane"
              invalid={firstNameInvalid}
              invalidText="Please enter first name"
              onChange={this.onInputChange}
            />
            <TextInput
              required
              id="lastName"
              className="mb-2"
              name="lastName"
              labelText="Last Name"
              placeholder="Doe"
              invalid={lastNameInvalid}
              invalidText="Please enter last name"
              onChange={this.onInputChange}
            />
          </div>
        </div>
        <div className="">
          <div className="xpa-dp-wrap mr-2">
            <label htmlFor="companyName" className="bx--label">Company</label>
            <AsyncCreatableSelect
              id="companyName"
              name="companyName"
              className={companyNameInvalid ? 'xpa-rs-wrap invalid mb-2' : 'xpa-rs-wrap mb-2'}
              classNamePrefix="xrs"
              placeholder="Select company"
              isDisabled={isDropdownLoading}
              isClearable
              noOptionsMessage={({ inputValue }) => !inputValue && 'Start typing to search companies'}
              loadOptions={debounce(this.onInputCompanyDropdown, 150)}
              onCreateOption={this.onCreateCompany}
              onChange={this.handleChangeCompany}
            />
            <div className="bx--form-requirement" id="companyName-error-msg">Please select company</div>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <TextInput
              required
              id="email"
              className="mb-2"
              type="email"
              name="email"
              labelText="Business Email"
              placeholder="janedoe@acme.com"
              invalid={emailInvalid}
              invalidText="Please enter valid business email"
              onChange={this.onInputChange}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <Select
              invalidText="Please select a product"
              onChange={(e) => {
                this.setState({ selectedProduct: (e && e.target && e.target.value), productNameInvalid: false });
              }}
              id="add-sub-select"
              defaultValue="no-product"
              labelText="Choose a product"
              invalid={productNameInvalid}
              className="mt-2 mb-2 mr-2"
            >
              <SelectItem
                disabled
                hidden
                value="no-product"
                text="--None--"
              />
              <SelectItem
                value="ROBOROY"
                text="X0PA FULL"
              />
              <SelectItem
                value="X0PA EXT"
                text="X0PA EXTENSION"
              />
              <SelectItem
                value="ENTERPRISE"
                text="ENTERPRISE"
              />
            </Select>
            {
              isSubmitLoading ? (
                <Button>Processing ...</Button>
              ) : (
                <Button onClick={this.onFormSubmit}>Submit</Button>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  xUserRegRequest,
  xSearchCompanies,
  xCreateCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRegisterForm);
