import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import Avatar from 'components/common/Avatar';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import {
  getUserActivityLogs,
} from 'actions/UserActivityLogs';
import moment from 'moment';
import {
  Pagination,
} from 'carbon-components-react';

class UserActivityLogs extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'institution', name: 'Insitution Info', href: '/app/campus/institution/info/1' },
    { id: 'user-info', name: 'User Activity Logs', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
    };
  }

  componentDidMount() {
    this.handleActivityLogs();
  }

  handlePaginationChange = (data) => {
    const activePage = data.activePage || 1;
    const itemsPerPage = data.itemsPerPage || 10;
    this.setState({
      activePage,
      itemsPerPage,
    }, () => {
      this.handleActivityLogs();
    });
  }

  handleActivityLogs = () => {
    const {
      match: {
        params: {
          userId,
        } = {},
      } = {},
    } = this.props;
    const {
      activePage,
      itemsPerPage,
    } = this.state;
    const data = {
      userId: userId && Number(userId),
      activePage,
      itemsPerPage,
    };
    this.props.getUserActivityLogs(data);
  }

  render() {
    const {
      t,
      loading,
      activityLogs,
      count,
    } = this.props;
    const {
      userinfo: {
        firstname,
        lastname,
        email,
      } = {},
    } = (activityLogs && activityLogs[0]) || [];
    const {
      activePage,
    } = this.state;
    const columnWithFilter = [
      {
        title: `${t('status')}`,
        className: 'text-left',
        name: 'status',
        propName: 'status',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'createdAt',
        propName: 'createdAt',
      },
    ];
    return (
      <div>
        <BackRow paths={this.paths} />
        <div className="mt-2 mb-2">
          {
            activityLogs && Array.isArray(activityLogs)
              && activityLogs.length > 0
              && (
                <div>
                  <div className="d-flex align-items-center mb-1 text-uppercase">
                    <div>
                      <Avatar
                        user={{
                          firstname,
                          lastname,
                          picture: null,
                        }}
                        classNameInfo="rounded-circle"
                        size="30"
                      />
                    </div>
                    <div className="ml-2 h6">
                      {firstname} {lastname}
                    </div>
                  </div>
                  <div className="d-flex justify-content-left">
                    <div>
                      <svg width="16" height="9" viewBox="0 0 16 11">
                        <path d="M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-dark font-weight-bold ml-2 mt-0">
                        <a className="text-lowercase" href={`mailto:${email}`}>{email}</a>
                      </span>
                    </div>
                  </div>
                </div>
              )
          }
        </div>
        <GenericDataTable
          className=""
          otherActions={[]}
          headers={
            columnWithFilter.map((data, index) => (
              {
                key: `header_${index}`,
                header: data.title,
                className: data.className,
                colpropName: data.propName,
              }
            ))}
          rows={activityLogs && Array.isArray(activityLogs) && activityLogs.length > 0 ? activityLogs.map((res) => {
            const {
              logActivityId,
              created,
              status,
            } = res || {};
            return ({
              isExpanded: true,
              id: `row_${logActivityId}`,
              header_0: (
                <div className="text-capitalize font-weight-bold text-dark">
                  {status}
                </div>
              ),
              header_1: (
                <div>
                  {moment(created).format('DD/MM/YY, hh:mma')}
                </div>
              ),
            });
          }) : []}
          selectable={false}
          searchable={false}
          onSearchInputChange={() => {}}
          expandable={false}
          batchActions={false}
        />
        {
          count > 0 && (
            <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
          )
        }
        {
          (count <= 0 || !activityLogs) && (
            <div className="bx--tile">
              <div className="text-center">
                <div className="empty-icon">
                  <i className="fa fa-flag fa-2x" />
                </div>
                <p className="empty-title h5">{t('noLogsMsg')}</p>
              </div>
            </div>
          )
        }
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  activityLogs: state.UserActivityLogs.activityLogs,
  count: state.UserActivityLogs.count,
  loading: state.UserActivityLogs.loading,
});

const mapDispatchToProps = {
  getUserActivityLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserActivityLogs));
