import React from 'react';
import moment from 'moment';
import OfficeDetails from './officeDetails';
import JobDescription from './jobDescription';
import {
    Tag,
    FileUploaderItem
} from 'carbon-components-react';
import { View16 } from '@carbon/icons-react';


const JobInfoCompleteDetails = ({ job = {}, user = {}, t, isJobOfferPage }) => {
    console.log('job:', job)
    const {
        office,
        vacancy,
        companysize,
        // requiredExp,
        // department,
        // displayCompanyName,
        // qualificationtype,
        jobDesc,
        jobReq,
        benefit,
        collaboration,
        jobId,
        dashboard,
        jobname = {},
        // jobfunction,
        // term,
        // hourtype,
        // jobseniority,
        // internalCode,
        company,
        region,
        country,
        closeDate,
        canApplytoJob,
        openDate,
        // recuitercompany,
        // agencyName,
        // locationAgnostic,
        skills,
        status,
        isClosed,
        linkedinJobId,
        // isPrivate,
        // isAgency,
        // hoursPerDay,
        // daysPerWeek,
        approvedVacancies,
        internshipStartDate,
        internshipEndDate,
        jobPreferences = null,
        // jobQuestions,
        joblanguages,
        industrypreferences = null,
        jobfunctionpreferences = null,
        company: { description = null, website } = {},
        currency,
        minSalary = null,
        jobcoursemap,
        jobfacultymap,
        jobdocuments,
        jobjd,
        maxSalary,
        jobfunction: { functionName } = {},
        term: { termName } = {},
        customIndustry,
        customShift,
        workdays: { workdayType } = {},
        industry: { industryName } = {},
        scores,
        emailId,
        user: {
            firstname,
            lastname,
            email,
            contactnumber,
            designation,
        } = {},
        additionalComments,
        approvedList,
        facultyApprovedVacancies
    } = job;
    const {
        commuteTime,
    } = scores || {};
    const { roleId, isAdmin, isSuperAdmin, company: { ctypeId } = {} } = user || {};
    const { preferenceBackoffice, preferenceTaskOriented, preferenceTryNew } =
        jobPreferences !== null ? jobPreferences : {};
    const finalJobCoursesData = {};
    if (jobcoursemap && Array.isArray(jobcoursemap) && jobcoursemap.length > 0) {
        jobcoursemap.forEach(res => {
            const { faculty: { facultyName } = {}, courses: { courseName } = {} } =
                res || {};
            if (finalJobCoursesData[facultyName]) {
                finalJobCoursesData[facultyName].push(courseName);
            } else {
                finalJobCoursesData[facultyName] = [courseName];
            }
        });
    }
    let isPrimaryCourseId;
    let isPrimaryCourseArr = []
    if (approvedList && Array.isArray(approvedList) && approvedList.length > 0) {
        approvedList.forEach((res) => {
            if (res && res.isPrimaryCourseAdmin === true) {
                let coursesnames = res && res.courses
                coursesnames.forEach(resp => {
                    let { courses: { courseId, courseName } = {} } =
                        resp || {};
                    if (res.courseId == courseId) {
                        isPrimaryCourseId = courseName
                        isPrimaryCourseArr.push(isPrimaryCourseId)
                    }
                });
            }
        })
    }
    const facultyNames = finalJobCoursesData && Object.keys(finalJobCoursesData);
    let langData = {};
    if (joblanguages && Array.isArray(joblanguages) && joblanguages.length > 0) {
        joblanguages.forEach(res => {
            const {
                jobLangId,
                rating,
                language: { languageId, languageFull } = {},
                languagetype: { langTypeId } = {},
            } = res || {};
            const data = {
                langTypeId,
                languageFull,
                jobLangId,
                rating,
            };
            if (langData[languageId]) {
                langData[languageId].languagesType.push(rating);
            } else {
                langData[languageId] = {
                    ...data,
                    languagesType: [rating],
                };
            }
        });
    }
    const keys = langData && Object.keys(langData);
    // const questions = Array.isArray(jobQuestions) && jobQuestions.map(({jobquestionbank: { question }}) => question);
    const { countryFull = '', countryId = 0 } = country;

    // if (country) {
    //   countryFull = country.countryFull;
    //   countryId = country.countryId;
    // }
    // const recruiterCollaborator = collaboration && collaboration.filter(eachCollaborator => (eachCollaborator && eachCollaborator.roleId == 4));
    // let recruiterUser;
    // if (recruiterCollaborator && recruiterCollaborator.length > 0) {
    //   [recruiterUser] = recruiterCollaborator;
    // }
    const langHeaders = [
        { key: 'language', header: `${t('language')}` },
        { key: 'written', header: `${t('writing')}` },
        { key: 'verbal', header: `${t('speaking')}` },
    ];
    const columnWithFilter = [
        {
            title: `${t('language')}`,
            className: 'text-left',
            name: 'offerId',
            propName: 'offerId',
        },
        {
            title: `${t('writing')}`,
            className: 'text-center',
            name: 'actions',
            propName: 'actions',
        },
        {
            title: `${t('speaking')}`,
            className: 'text-center',
            name: 'dsfs',
            propName: 'dsfs',
        },
    ];

    let s3BucketDomain =
        'https://x0pa-rps-storage.s3-ap-southeast-1.amazonaws.com';
    if (window.location.host.includes('internship-placement.rp.edu.sg')) {
        s3BucketDomain = 'https://x0pa-rp-storage.s3-ap-southeast-1.amazonaws.com';
    }

    return (
        <div className="pt-1 xp-m__jobinfo--full-info">
            <div className="">
                <div className="">
                    <div className=" col-12 pl-0 pr-0">
                        <div className="">
                            <div className="row pb-2">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                                    <div className="">
                                        {/* <div className="row pb-2">
                        <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                          <h4 className="bx--modal-header__label mt-2">{t('website')}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                          <Tag type="blue">
                            <span className="text-uppercase">
                              {website || t('noLink')}
                            </span>
                          </Tag>
                        </div>
                      </div> */}
                                        {/* <div className="row pb-2">
                        <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                          <h4 className="bx--modal-header__label mt-2">{t('companySize')}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                          <Tag type="blue">
                            <span className="text-uppercase">
                              {companysize || 'No Count given'}
                            </span>
                          </Tag>
                        </div>
                      </div> */}
                                        {/* <div className="row pb-2">
                        <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                          <h4 className="bx--modal-header__label mt-2">{t('back')} {t('office')}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                          <Tag type="blue">
                            <span className="text-uppercase">
                              {preferenceBackoffice && preferenceBackoffice !== null ? `${preferenceBackoffice}%` : '-'}
                            </span>
                          </Tag>
                        </div>
                      </div>
                      <div className="row pb-2">
                        <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                          <h4 className="bx--modal-header__label mt-2">{t('taskOriented')}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                          <Tag type="blue">
                            <span className="text-uppercase">
                              {preferenceTaskOriented && preferenceTaskOriented !== null ? `${preferenceTaskOriented}%` : '-'}
                            </span>
                          </Tag>
                        </div>
                      </div>
                      <div className="row pb-2">
                        <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                          <h4 className="bx--modal-header__label mt-2">{t('tryNew')}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                          <Tag type="blue">
                            <span className="text-uppercase">
                              {preferenceTryNew && preferenceTryNew !== null ? `${preferenceTryNew}%` : '-'}
                            </span>
                          </Tag>
                        </div>
                      </div> */}
                                        {
                                            roleId && Number(roleId) !== 3 && roleId && Number(roleId) === 4 && ctypeId && Number(ctypeId) === 1 && (
                                                <div className="pt-4">
                                                    <span>The approved number of vacancies may be lower than your request,
                                                    to allow even distribution of our students among all companies
                                                    </span>
                                                </div>
                                            )
                                        }
                                        {roleId && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('stipend')}
                                                    </h4>
                                                </div>
                                                {!maxSalary ? (
                                                    <div className="col-xs-12 col-sm-12 col-md-9 text-left">
                                                        <Tag type="blue">{t('notSpecified')}</Tag>
                                                    </div>
                                                ) : (
                                                    <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                        <Tag type="blue">
                                                            <span className="text-uppercase">
                                                                {maxSalary
                                                                    ? `${currency ? currency.currencyShort : ''
                                                                    } ${maxSalary}`
                                                                    : '-'}
                                                            </span>
                                                        </Tag>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {
                                            roleId && Number(roleId) !== 3 && (
                                                <div className="row pb-2">
                                                    <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                        <h4 className="bx--modal-header__label mt-2">
                                                            {t('vacancies')}
                                                        </h4>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                        <Tag className="text-uppercase" type="blue">
                                                            {vacancy || t('any')}
                                                        </Tag>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        {/* {
                                            roleId && Number(roleId) !== 3 &&
                                            (facultyApprovedVacancies && Array.isArray(facultyApprovedVacancies)
                                                && facultyApprovedVacancies.length > 0) && facultyApprovedVacancies.map((resp) => {
                                                    const {
                                                        facultyApprovedVacancies,
                                                        faculty
                                                    } = resp || {};
                                                    return (
                                                        <div className="row pb-2">
                                                            <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                                <h4 className="bx--modal-header__label mt-2">
                                                                    {t('approvesVacancies')} {faculty && faculty.facultyName}
                                                                </h4>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                                <Tag className="text-uppercase" type="blue">
                                                                    {facultyApprovedVacancies || t('forApproved')}
                                                                </Tag>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                        } */}
                                        {region && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('region')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    <Tag className="text-uppercase" type="blue">
                                                        {region}
                                                    </Tag>
                                                </div>
                                            </div>
                                        )}
                                        {countryFull && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('country')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    <Tag className="text-uppercase" type="blue">
                                                        {countryFull}
                                                    </Tag>
                                                </div>
                                            </div>
                                        )}
                                        {functionName && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('jobFunction')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    <Tag className="text-uppercase" type="blue">
                                                        {functionName}
                                                    </Tag>
                                                </div>
                                            </div>
                                        )}
                                        {/* {termName && (
                        <div className="row pb-2">
                          <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                            <h4 className="bx--modal-header__label mt-2">
                              {t('term')}
                            </h4>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                            <Tag className="text-uppercase" type="blue">
                              {termName}
                            </Tag>
                          </div>
                        </div>
                      )}
                      {industryName && (
                        <div className="row pb-2">
                          <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                            <h4 className="bx--modal-header__label mt-2">
                              {t('industries')}
                            </h4>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                            {customIndustry ? (
                              <Tag className="text-uppercase" type="blue">
                                {customIndustry}
                              </Tag>
                            ) : (
                              <Tag className="text-uppercase" type="blue">
                                {industryName}
                              </Tag>
                            )}
                          </div>
                        </div>
                      )} */}
                                        {workdayType && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('noOfWorkDays')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    {customShift ? (
                                                        <Tag className="text-uppercase" type="blue">
                                                            {customShift}
                                                        </Tag>
                                                    ) : (
                                                        <Tag className="text-uppercase" type="blue">
                                                            {workdayType}
                                                        </Tag>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {skills && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('mustHaveSkills')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    {skills.map(
                                                        (eachSkill, index) =>
                                                            eachSkill &&
                                                            eachSkill.requirementId == 1 && (
                                                                <Tag
                                                                    className="text-uppercase"
                                                                    type="green"
                                                                    key={index}>
                                                                    {eachSkill &&
                                                                        eachSkill.skill &&
                                                                        eachSkill.skill.skillName}
                                                                </Tag>
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {isJobOfferPage && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        Estimated commute time
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    <Tag className="text-uppercase" type="purple">
                                                        {(commuteTime && commuteTime > 0) ? `${Math.round(commuteTime)} mins` : 'Not Available'}
                                                    </Tag>
                                                </div>
                                            </div>
                                        )}


                                        {Array.isArray(industrypreferences) && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('industryPreferences')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    {industrypreferences.map(
                                                        (industry, index) =>
                                                            industry && (
                                                                <Tag type="community" key={index}>
                                                                    <span className="text-uppercase">
                                                                        {industry}
                                                                    </span>
                                                                </Tag>
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {Array.isArray(jobfunctionpreferences) && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('jobFunctionPreference')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    {jobfunctionpreferences.map(
                                                        (jobfunction, index) =>
                                                            jobfunction && (
                                                                <Tag type="community" key={index}>
                                                                    <span className="text-uppercase">
                                                                        {jobfunction}
                                                                    </span>
                                                                </Tag>
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {linkedinJobId && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        Linkedin {t('job')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    <a
                                                        target="_blank"
                                                        href={`https://www.linkedin.com/jobs/view/${linkedinJobId}/`}>{`${linkedinJobId}`}</a>
                                                </div>
                                            </div>
                                        )}
                                        {internshipStartDate && internshipEndDate && (
                                            <div className="row pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('internshipPeriod')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    {
                                                        <div className="bx--row m-0">
                                                            <Tag type="blue">
                                                                <span className="text-uppercase">
                                                                    {moment(internshipStartDate).format(
                                                                        'MMM DD YYYY'
                                                                    )}
                                                                </span>
                                                            </Tag>
                                                            <p className="ml-2 mr-2 bx--type-omega mt-1">
                                                                to
                                </p>
                                                            <Tag type="blue">
                                                                <span className="text-uppercase">
                                                                    {moment(internshipEndDate).format(
                                                                        'MMM DD YYYY'
                                                                    )}
                                                                </span>
                                                            </Tag>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {/* {closeDate && (
                        <div className="row pb-2">
                          <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                            <h4 className="bx--modal-header__label mt-2">
                              {t('closeDate')}
                            </h4>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                            {
                              <Tag type="blue">
                                <span className="text-uppercase">
                                  {moment(closeDate).format('MMM DD YYYY')}
                                </span>
                              </Tag>
                            }
                          </div>
                        </div>
                      )} */}
                                        {
                                            roleId && roleId !== 3 && additionalComments && (
                                                <div className="row pb-2">
                                                    <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                        <h4 className="bx--modal-header__label mt-2">
                                                            {t('addlComments')}
                                                        </h4>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                        {additionalComments}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {office && (
                                            <div className="row align-items-center pb-2">
                                                <div className="col-xs-12 col-sm-12 col-md-3 text-gray text-left">
                                                    <h4 className="bx--modal-header__label mt-2">
                                                        {t('stdReportingAddrs')}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-9  text-left">
                                                    <div className="bx--row m-0">
                                                        <span className="text-capitalize">
                                                            {office.address && office.address.addressLine1}{' '}
                                                            {office.address && office.address.addressLine2},{' '}
                                                            {office.address && office.address.zipcode}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 ml-0 pl-0">
                                    <OfficeDetails
                                        details={office}
                                        commuteTime={commuteTime}
                                        roleId={roleId}
                                        company={company && company !== null ? company : ''}
                                        type={`${t('company')} ${t('info')}`}
                                        region={region && region !== null ? region : ''}
                                        email={email}
                                        firstname={firstname}
                                        canApplytoJob={canApplytoJob}
                                        contactnumber={contactnumber}
                                        lastname={lastname}
                                        isAdmin={isAdmin}
                                        isSuperAdmin={isSuperAdmin}
                                        ctypeId={ctypeId}
                                        designation={designation}
                                        countryFull={
                                            countryFull && countryFull !== null ? countryFull : ''
                                        }
                                    />
                                </div>
                            </div>
                            {/* <div className="">
                  {
                    roleId && roleId !== 3
                      && (
                        <div className="xpa-modal__section pt-4">
                          <p className="bx--type-caps bx--type-delta">{t('jobPreferences')}</p>
                          <hr />
                          <PercentageSlider disabled hideSideLabels minLabel={t('taskOrientedPreference')} maxLabel={t('independentPreference')} value={Number(preferenceTaskOriented)} name="preferenceTaskOriented" onChange={() => {}} />
                          <PercentageSlider disabled hideSideLabels minLabel={t('backEndPreference')} maxLabel={t('frontEndPreference')} value={Number(preferenceBackoffice)} name="preferenceBackoffice" onChange={() => {}} />
                          <PercentageSlider disabled hideSideLabels minLabel={t('tryNewPreference')} maxLabel={t('enhanceFutherEnhanceSkills')} value={Number(preferenceTryNew)} name="preferenceTryNew" onChange={() => {}} />
                        </div>
                      )
                  }
                </div> */}
                            {/* {((facultyNames &&
                                Array.isArray(facultyNames) &&
                                facultyNames.length > 0) || (jobfacultymap && Array.isArray(jobfacultymap) && jobfacultymap.length > 0))
                                &&
                                roleId &&
                                Number(roleId) !== 3 && (
                                    <div>
                                        <div className="h6 text-uppercase">
                                            {t('faculty')} & {t('courses')}
                                        </div>
                                        <hr />
                                    </div>
                                )} */}
                            {/* <div className="bx--row">
                                {
                                    jobfacultymap && Array.isArray(jobfacultymap) && jobfacultymap.length > 0
                                    && facultyNames &&
                                    Array.isArray(facultyNames) &&
                                    facultyNames.length <= 0 && jobfacultymap.map((facs) => {
                                        const {
                                            facultyName,
                                        } = facs || {};
                                        return (
                                            <div className="bx--col-lg-4 mb-1 text-capitalize">
                                                {facultyName}
                                            </div>
                                        )
                                    })
                                }
                            </div> */}
                            {/* {facultyNames &&
                                Array.isArray(facultyNames) &&
                                facultyNames.length > 0 &&
                                roleId &&
                                Number(roleId) !== 3 &&
                                facultyNames.map(res => {
                                    return (
                                        <div className="mb-2">
                                            <div className="bx--type-zeta text-capitalize mb-2">
                                                {res}
                                            </div>
                                            <div className="bx--row">
                                                {finalJobCoursesData &&
                                                    Array.isArray(finalJobCoursesData[res]) &&
                                                    finalJobCoursesData[res].length > 0 &&
                                                    finalJobCoursesData[res].map(courses => (
                                                        <div className="bx--col-lg-4 mb-1 text-capitalize">
                                                            {courses}
                                                            {' '}
                                                            {courses != null &&
                                                                isPrimaryCourseArr && Array.isArray(isPrimaryCourseArr)
                                                                && isPrimaryCourseArr.length > 0
                                                                && isPrimaryCourseArr.includes(courses) ? (
                                                                <span
                                                                    title="Approved"
                                                                    className="approved-color">
                                                                    <i className="fas fa-check-circle" /> Primary
                                                                </span>
                                                            ) : ''}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    );
                                })} */}
                            <div className="pt-4">
                                <p className="h6 text-uppercase">{t('jobjd')}</p>
                                <hr />
                                {
                                    <div
                                        className="uploaded-files d-flex"
                                        style={{ width: '100%' }}>
                                        {jobjd && jobjd.jobDocId && (
                                            <div className="d-flex mr-2" key={jobjd.jobDocId}>
                                                <FileUploaderItem
                                                    key={jobjd.jobDocId}
                                                    uuid={jobjd.jobDocId}
                                                    name={jobjd.fileName}
                                                    status="complete"
                                                    iconDescription="View JD"
                                                />
                                                {jobjd.location && (
                                                    <a
                                                        style={{ backgroundColor: '#f4f4f4' }}
                                                        className="d-flex mb-2 pr-2 align-items-center"
                                                        target="_blank"
                                                        href={`/viewjd/${jobjd.jobDocId}/jd?`}>
                                                        <View16 />
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                }
                                {(!jobjd || (jobjd && !jobjd.jobDocId)) && (
                                    <div>{t('noJobJDMsg')}</div>
                                )}
                            </div>
                            <div className="pt-4">
                                <p className="h6 text-uppercase">{t('jobdocuments')}</p>
                                <hr />
                                {
                                    <div
                                        className="uploaded-files d-flex"
                                        style={{ width: '100%' }}>
                                        {jobdocuments &&
                                            Array.isArray(jobdocuments) &&
                                            jobdocuments.map(({ jobDocId, fileName, location }) => (
                                                <div className="d-flex mr-2" key={jobDocId}>
                                                    <FileUploaderItem
                                                        key={jobDocId}
                                                        uuid={jobDocId}
                                                        name={fileName}
                                                        status="complete"
                                                        iconDescription="View document"
                                                    />
                                                    {jobDocId && (
                                                        <a
                                                            style={{ backgroundColor: '#f4f4f4' }}
                                                            className="d-flex mb-2 pr-2 align-items-center"
                                                            target="_blank"
                                                            href={`/viewjd/${jobDocId}/jd?`}>
                                                            <View16 />
                                                        </a>
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                }
                                {(!jobdocuments ||
                                    (jobdocuments &&
                                        Array.isArray(jobdocuments) &&
                                        jobdocuments.length <= 0)) && (
                                        <div>{t('noJobDocsMsg')}</div>
                                    )}
                            </div>
                            <div className="">
                                <div className="pt-4">
                                    <p className="h6 text-uppercase">{t('overview')}</p>
                                    <hr />
                                    <JobDescription
                                        jobDesc={jobDesc}
                                        jobReq={jobReq}
                                        benefit={benefit}
                                    />
                                </div>
                            </div>
                            {/* <div className="xpa-modal__section pt-4">
                  <p className="bx--type-caps bx--type-delta">{t('companyDescription')}</p>
                  <hr />
                  <div className="xpa-format--pre-content jdesc mb-3 text-nowrap">{description !== null ? description : '-'}</div>
                </div> */}
                            {/* <div className="">
                  <div className="xpa-modal__section pt-4">
                    <p className="bx--type-caps bx--type-delta">{t('language')} {t('preferences')}</p>
                    <hr />
                    {
                      keys && Array.isArray(keys)
                      && keys.length > 0
                      ? (
                        <GenericDataTable
                          className=""
                          // title={t('languageProficiency')}
                          headers={
                            columnWithFilter.map((data, index) => (
                              {
                                key: `header_${index}`,
                                header: data.title,
                                className: data.className,
                                colpropName: data.propName,
                              }
                            ))}
                          rows={keys.map((res) => {
                            const {
                              jobLangId,
                              languageFull,
                              languagesType,
                            } = (langData && langData[res]) || {};
                            let writingVal = '';
                            if (languagesType && languagesType[0] == 1) {
                              writingVal = 'Not required';
                            } else if (languagesType && languagesType[0] == 2) {
                              writingVal = 'Basic';
                            } else if (languagesType && languagesType[0] == 3) {
                              writingVal = 'required';
                            }
                            let speakingVal = '';
                            if (languagesType && languagesType[1] == 1) {
                              speakingVal = 'Not required';
                            } else if (languagesType && languagesType[1] == 2) {
                              speakingVal = 'Basic';
                            } else if (languagesType && languagesType[1] == 3) {
                              speakingVal = 'required';
                            }
                            return ({
                              isExpanded: true,
                              id: `row_${jobLangId}`,
                              header_0: (
                                <div className="text-capitalize font-weight-bold">
                                  {languageFull}
                                </div>
                              ),
                              header_1: (
                                <div className="">
                                  {writingVal}
                                </div>
                              ),
                              header_2: (
                                <div className="">
                                  {speakingVal}
                                </div>
                              ),
                            });
                          })}
                          otherActions = {[]}
                        />
                      ) : '-'
                    }
                  </div>
                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobInfoCompleteDetails;