export const REQUEST_USER = 'REQUEST_USER';
export const SUCCESS_USER = 'SUCCESS_USER';

export const REQUEST_USERINFO = 'REQUEST_USERINFO';
export const SUCCESS_USERINFO = 'SUCCESS_USERINFO';

export const REQUEST_USERROLES = 'REQUEST_USERROLES';
export const SUCCESS_USERROLES = 'SUCCESS_USERROLES';

export const REQUEST_OFFICES = 'REQUEST_OFFICES';
export const SUCCESS_OFFICES = 'SUCCESS_OFFICES';

export const REQUEST_DOMAINS = 'REQUEST_DOMAINS';
export const SUCCESS_DOMAINS = 'SUCCESS_DOMAINS';

export const REQUEST_COMPANIES = 'REQUEST_COMPANIES';
export const SUCCESS_COMPANIES = 'SUCCESS_COMPANIES';

export const REQUEST_UPLOAD_IMAGE = 'REQUEST_UPLOAD_IMAGE';
export const SUCCESS_UPLOAD_IMAGE = 'SUCCESS_UPLOAD_IMAGE';

export const REQUEST_PROFILE_UPDATE = 'REQUEST_PROFILE_UPDATE';
export const SUCCESS_PROFILE_UPDATE = 'SUCCESS_PROFILE_UPDATE';
