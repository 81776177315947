import { withTranslation } from 'react-i18next';
import React from 'react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import { Select, SelectItem, TextArea } from 'carbon-components-react';

const InterviewFeedbackTable = (props) => {
  const {
    columnWithFilter,
    rowsData,
    t,
    handleChange,
    state,
    responseData,
    hideAddFeedBtn,
    userFeedbackDone,
    editFeedback,
  } = props;
  return (
    <GenericDataTable
      title=""
      customSorting={() => {}}
      rowClass="p-0"
      headers={columnWithFilter.map((data, index) => ({
        key: `header_${index}`,
        header: data.title,
        className: data.className,
        colpropName: data.propName,
      }))}
      rows={rowsData.map((data, idx) => {
        const { groupName, groupDesc, key, textKey, hideGroupName, className } = data || {};
        return {
          isExpanded: false,
          id: (!hideAddFeedBtn || editFeedback) ? `row_${key}_${idx}` : `${responseData && responseData.profileFeedbackId}_${key}_${groupName}`,
          header_0: (
            <div>
              {!hideGroupName && <div className="bx--type-zeta">{groupName}</div>}
              <div className={`${className} mt-2 text-justify`}>{groupDesc}</div>
            </div>
          ),
          header_1: (
            <div>
              <Select
                name={key}
                labelText=""
                hideLabel
                disabled={hideAddFeedBtn && editFeedback !== true}
                id={(!hideAddFeedBtn || editFeedback) ? key : responseData && responseData.profileFeedbackId}
                onChange={(e) => handleChange(e)}
                value={(!hideAddFeedBtn || editFeedback) ? state && state[key] && Number(state[key]) : responseData[key] && Number(responseData[key])}
                invalid={state[`invalid-${key}`]}
                invalidText={t('offerMsg4')}
                className="w-100">
                <SelectItem text={t('select')} />
                {[1, 2, 3, 4, 5].map((res) => (
                  <SelectItem key={res} text={res} value={res} />
                ))}
              </Select>
            </div>
          ),
          header_2: (
            <div>
              <TextArea
                name={textKey}
                disabled={hideAddFeedBtn && editFeedback !== true}
                value={(!hideAddFeedBtn || editFeedback) ? state && state[textKey] : responseData[textKey]}
                onChange={handleChange}
                id={(!hideAddFeedBtn || editFeedback) ? textKey : responseData && responseData.profileFeedbackId}
                labelText="Remarks"
              />
            </div>
          ),
        };
      })}
      selectable={false}
      searchable={false}
      onSearchInputChange={() => {}}
      expandable={false}
    />
  );
};

export default withTranslation()(InterviewFeedbackTable);
