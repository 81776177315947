import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
//import Company from '../../components/Admin/Company';
import {
  getAuthorizedCompanies,
  authorizeCompany
} from '../../actions/admin/CompanyAndOffice';

import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';

// import AccordionTable from './accordionTable';


import { DataTable, Button, Loading, Pagination, Tile } from 'carbon-components-react';
const {
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TableHeader,} = DataTable;

const NoAgenciesBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no agencies currently associated.</p>
    </div>
  </Tile>
);

class AgencyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: 0,
      rowsDataAgencies: [],
      headersInfoAgencies: [
        { key: 'agencyName', header: 'Agency Name' },
        { key: 'requested', header: 'Requested by' },
        { key: 'status', header: 'Status' },
        { key: 'action', header: 'Action' },
      ],
      rowData: [],
      error: false,
      activePage:1,
      limit:10,
    };
  }

  componentDidMount() {
    this.reRenderContent();
  }


  CardRowBox = (rowData, isAdmin = false) => {
    return (
      <div className="mt-2 mb-2">
        <div className="bx--type-zeta text-capitalize">{`${rowData.companyName}`}</div>
        {rowData.description ? <div className="text-dark text-justify mt-1 mb-1">{rowData.description}</div> : ''}
        {rowData.website ? <a href="">{rowData.website}</a> : ''}
        <div className="mt-2">{rowData.action}</div>
      </div>
    );
  }

  onAuthorize = (obj,status)=>{
    const data = {
      parentCompanyId: obj.parentCompanyId,
      childCompanyId: obj.childCompanyId,
      status:status,
    };
    this.props.authorizeCompany(data).then(()=>this.reRenderContent());
  }

  handleRedirectCompanyInfoPage = (companyId) => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push(`/app/campus/company/info/${companyId}`);
    }
  }

  reRenderContent = ({limit=null,skip=null}={}) => {
    limit = limit===null?this.state.limit:limit;
    skip = skip===null?((this.state.activePage-1)*limit):skip;
    this.props.getAuthorizedCompanies({limit,skip}).then(() => {
      const {agencies={}}=this.props.companyAndOffice.authorizedCompanies;
      let agencyList = agencies.companies||[];
      let rowData = [];
      let rowsDataAgencies = agencyList.map((agency, index)=>{
        const {userinfo={},parentCompany={},status=""} = agency; 
        return {
          id : `agency_${index}`,
          agencyName: (
            <div onClick={() => this.handleRedirectCompanyInfoPage(parentCompany.companyId)} className="text-capitalize xpa-link bx--type-zeta">
              {parentCompany.companyName}
            </div>
          ),
          requested: (
            <div className="text-capitalize">
              {`${userinfo.firstname||""} ${userinfo.lastname||""}`}
            </div>
          ),
          status:(<Fragment>
            {status === "Pending" && (<div className="mt-2 ml-1 bx--tag bx--tag--cool-gray">Pending</div>)}
            {status === "Accepted" && (<div className="mt-2 ml-1 bx--tag bx--tag--green">Accepted</div>)}
            {status === "Rejected" || status === "Revoked"  && (<div className="mt-2 ml-1 bx--tag bx--tag--red">Rejected</div>)}
          </Fragment>),
          action:(<Fragment>
            {status === "Pending" && (<Fragment><Button kind="primary" className="ml-2" small onClick={(e) => this.onAuthorize(agency,"Accepted")}>Accept</Button>
            <Button kind="danger" className="ml-2" small onClick={(e) => this.onAuthorize(agency,"Rejected")}>Decline</Button></Fragment>)}
            {status === "Accepted" && <Button kind="danger" className="ml-2" small onClick={(e) => this.onAuthorize(agency,"Revoked")}>Revoke</Button>}
            {status === "Rejected" || status === "Revoked"  && "-"}
          </Fragment>)
        };
      });
      rowsDataAgencies = rowsDataAgencies && Array.isArray(rowsDataAgencies) ? rowsDataAgencies : [];
      this.setState({ rowsDataAgencies });
    });
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const limit = data.pageSize || this.state.limit;
    this.setState({ activePage, limit }, () =>
      this.reRenderContent({
        limit:limit,
        skip: (activePage-1)*limit,
      }));
  };

  setCompany = () => (this.setState({ isCompanyPresent: true }));
  setSelectCompany = () => (this.setState({ isSelectedCompany: false }));

  render() {
    const { error, headersInfoAgencies, rowsDataAgencies } = this.state;
    const { user: { company = {}, currentCompany = {} } } = this.props;    

    let isParentCompany = false;
    if ((company && company.companyId) == (currentCompany && currentCompany.companyId)) {
      isParentCompany = true;
    }

    return (
      (!isParentCompany ? (
        (<Tile className="bx--col-md-3 mt-2 mb-2">
          <div className="text-center text-dark pt-2">
            Switch to your parent company view to see recruitment agencies you manage.
          </div>
        </Tile>)
        ) : (

      <div>
        <Loading active={this.props.loading} className='companiesLoading' />
          <div className="agencies">
              <GenericDataTable rows={rowsDataAgencies} headers={headersInfoAgencies} rowClass={"text-center p-0 bx--col-12"} />
            {this.props.count > 0 && <Pagination
              onChange={this.handlePaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              page={this.state.activePage}
              totalItems={this.props.count} />}
            {this.state.rowsDataAgencies && this.state.rowsDataAgencies.length <= 0 && (<NoAgenciesBox />)}
        </div>
      </div>
      ))

    );
  }
}


const mapStateToProps = state => ({
  companyAndOffice: state.companyAndOffice,
  count: state && state.companyAndOffice && state.companyAndOffice.authorizedCompanies && state.companyAndOffice.authorizedCompanies.agencies && state.companyAndOffice.authorizedCompanies.agencies.count || 0,
  companyId: state.companyAndOffice.companyId,
  loading: state.companyAndOffice.loading,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getAuthorizedCompanies,
  authorizeCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyDetails);
