import { combineReducers } from 'redux';
import { reducer as tooltip } from 'redux-tooltip';
import { routerReducer } from 'react-router-redux';
import settings from './settings';
import employeeData from './employeeData';
import employees from './employees';
import user from './user';
import candidateData from './candidateData';
import currentTab from './currentTab';
import showAttritionModal from './showAttritionModal';
import selectedYear from './selectedYear';
import signUp from './signUp';
import profile from './userProfile/userProfile.reducer';
import Filters from './leftNavFilter';
import Analytics from './analytics';
import analyticsInfo from './analyticsInfo'
import OfferInformation from './offerInformation';
import CalendarEvents from './calendarEvents';
import globalSearch from './globalSearch';
import JobApprovals from './talentSearch/JobApprovals';
import DocumentsUpload from './DocumentsUpload';
import ApplicantNotes from './ApplicantNotes';
import BrowseCompanies from './BrowseCompanies';
import BrowseInstitutions from './BrowseInstitutions';
import BrowseStudents from './BrowseStudents';
import BrowseEmployees from './BrowseEmployees';
import userEmailConfirmation from './userEmailConfirmation';
import StudentAssessments from './studentAssessments';

// reducers related to talentSearch
import applicationData from './talentSearch/applicationData';
import roboroyTalentPool from './talentSearch/talentPool';
import roboroyDashboard from './talentSearch/roboroyDashboard';
import roboroyApplications from './talentSearch/roboroyApplications';
import jobAnalytics from './talentSearch/jobAnalytics';
import enterprise from './talentSearch/enterprise'
import resumeUpload from './talentSearch/resumeUpload';
import jobs from './talentSearch/jobList';
import jobCreation from './talentSearch/newJob';
import taskStatus from './talentSearch/taskStatus';
import UserActivityLogs from './UserActivityLogs';

// Candidate specific
import candidateDashboard from './candidate/dashboard';
import candidateJobs from './candidate/jobs';
import candidateReferences from './candidate/references';
import BrowseJobs from './candidate/browse';
import invitation from './invite';
import candidateDetails from './candidate/candidateDetails';
import SkillsRecommended from './candidate/SkillsRecommended';
import finlandProfile from './candidate/businessFinland';

// Global Talent pool
import gtp from './gtp';

import x0paDuser from './x0paDuser';
import videoInterviews from './videointerview';

import workflows from './workflow';
// reducers related to admin
import companyAndOffice from './admin/companyAndOffice';
import AdminDashboard from './admin/AdminDashboard';
import registration from './admin/registration';
import adminSettings from './admin/admin-settings';
import RecruitersList from './admin/RecruitersList';

//Users usage report
import usageReport from './talentSearch/usersusagereport';

// marketplace
import marketplace from './marketplace';

import emailTemplates from './email-templates';
import cronofy from './cronofy';

/* Student Profile */
import StudentProfile from './campus/internCandidate';
import RegistrationRequest from './campus/registrationRequest';

import ProductLanguge from './languageChange';

import UploadDocuments from './uploadAllDocuments';
import CompanyRegistration from './companyRegistration';
import StudentProfileView from './StudentProfileView';
import InstitutionView from './InstitutionView';
import CitiesDetails from './CitiesDetails';
import LanguagesView from './LanguagesView';
import Reports from './Reports';

import batchDetails from './BatchManagementView';
import CompaniesUsers from './CompaniesUsers';
import userTokenInfo from './simulate.js'
import userEmailNotifications from './userEmailNotifications.js'
import companyJobs from './transferjob.js'
 

const reducers = { 
  routing: routerReducer,
  x0paDuser,
  settings,
  signUp,
  employeeData,
  user,
  candidateData,
  tooltip,
  currentTab,
  showAttritionModal,
  jobCreation,
  jobs,
  roboroyApplications,
  jobAnalytics,
  enterprise,
  resumeUpload,
  roboroyDashboard,
  taskStatus,
  employees,
  applicationData,
  roboroyTalentPool,
  selectedYear,
  companyAndOffice,
  AdminDashboard,
  profile,
  candidateDashboard,
  candidateJobs,
  invitation,
  gtp,
  videoInterviews,
  workflows,
  registration,
  candidateReferences,
  usageReport,
  BrowseJobs,
  Filters,
  emailTemplates,
  adminSettings,
  cronofy,
  Analytics,
  analyticsInfo,
  userTokenInfo,
  batchDetails,
  CompaniesUsers,
  marketplace,
  OfferInformation,
  CalendarEvents,
  candidateDetails,
  SkillsRecommended,
  globalSearch,
  finlandProfile,
  RecruitersList,
  JobApprovals,
  DocumentsUpload,
  ApplicantNotes,
  StudentProfile,
  BrowseCompanies,
  BrowseInstitutions,
  BrowseStudents,
  ProductLanguge,
  RegistrationRequest,
  UploadDocuments,
  CompanyRegistration,
  StudentProfileView,
  InstitutionView,
  CitiesDetails,
  LanguagesView,
  Reports,
  UserActivityLogs,
  userEmailNotifications,
  companyJobs,
  BrowseEmployees,
  userEmailConfirmation,
  StudentAssessments,
};

export default combineReducers(reducers);
