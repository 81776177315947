import {
  SUCCESS_USER,
  SUCCESS_USERINFO,
  SUCCESS_UPLOAD_IMAGE,
  SUCCESS_GTP_OPTIN_UPDATE,
  ERROR_GTP_OPTIN_UPDATE,
} from '../../actions/userProfile/action.constant';

const Profile = (state = {
  userInfo: {},
  publicVisibility: false,
  imageStatus: {
    result: {
      files: ''
    }
  }
}, action) => {
  switch (action.type) {
    case SUCCESS_USER:
      return {
        ...state,
        users: action.payload
      }
    case SUCCESS_USERINFO:
      return {
        ...state,
        userInfo: action.payload
      }
    case SUCCESS_UPLOAD_IMAGE:
      return {
        ...state,
        imageStatus: action.payload
      }
    default:
      return state;
  }
}

export default Profile;
