import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Tooltip, Button, TextArea, InlineNotification,
} from 'carbon-components-react';
import StarRatingComponent from 'react-star-rating-component';

import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy//NewJob/JDProcessOverlay';

import {
  getReferenceInviteConfigs,
  addReferenceFeedback,
  validateReference,
} from 'actions/candidate/references';

class ReferenceFeedback extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
    { id: 'references', name: 'References', href: '/app/campus/candidate/references' },
    { id: 'reference-feedback', name: 'Feedback', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      narrativeText: '',
      feedbackSubmitted: false,
      inviteError: false,
    };
  }

  componentDidMount() {
    this.getReferenceFeedback();
  }

  getReferenceFeedback = () => {
    const { match: { params: { id = '' } } } = this.props;
    const rid = id && id.split('x');
    if (rid.length === 4) {
      this.props.validateReference(id)
        .then((res) => {
          if (res && res.error) {
            this.setState({
              inviteError: true,
            });
          } else {
            this.props.getReferenceInviteConfigs(rid[2])
              .then((r) => {
                if (r && r.error) {
                  this.setState({
                    inviteError: true,
                  });
                }
              });
          }
        });
    }
  }

  starRating = (metricId, rating, feedbackProvided = false) => {
    let metricRating = this.state[`rate-${metricId}`];
    if (rating) {
      metricRating = rating;
    }
    return (
      <StarRatingComponent
        name={`rate-${metricId}`}
        starCount={5}
        value={metricRating ? metricRating : parseInt(0, 10)}
        starColor="#ffb400"
        emptyStarColor="#ddd"
        renderStarIcon={(index, value) => {
          return (
            <span>
              <i className={index <= value ? 'fa fa-star' : 'fa fa-star'} />
            </span>
          );
        }}
        editing={!feedbackProvided}
        renderStarIconHalf={() => {
          return (
            <span>
              <span style={{ position: 'absolute' }}><i className="fa fa-star" /></span>
              <span><i className="fa fa-star-half" /></span>
            </span>
          );
        }}
        onStarClick={(nextValue, prevValue, name) => {
          this.setState({
            [name]: nextValue,
          });
        }}
      />
    );
  }

  handleNarrativeChange = (ev) => {
    this.setState({
      narrativeText: ev.target.value,
    });
  }

  handleSubmit = () => {
    const {
      match: { params: { id = '' } },
      questions: {
        metricsResult,
      } = {},
      user: { userId } = {},
    } = this.props;
    const rlen = (Array.isArray(metricsResult) && metricsResult.length) || 0;
    const reqLength = metricsResult
      .map((qs) => {
        const { metrics = [] } = qs || {};
        return metrics.length;
      })
      .reduce((a, b) => Number(a) + Number(b));
    const ratingsArr = Object.keys(this.state).filter(k => k.includes('rate-'));
    // console.log('reqLength', reqLength, ratingsArr.length, reqLength - rlen);
    const rId = id && id.split('x');
    if (reqLength) {
      if (ratingsArr
          && Array.isArray(ratingsArr)
          && (ratingsArr.length === (reqLength - rlen - 1))
        ) {
        const metricIdArr = ratingsArr.map((r) => {
          const mId = r.split('rate-');
          return {
            referenceId: rId && rId[2],
            refId: userId,
            applicantRefId: rId && rId[1],
            metricId: mId && mId[1],
            rating: this.state[`rate-${mId[1]}`],
          };
        });
        metricIdArr.push({
          referenceId: rId && rId[2],
          refId: userId,
          applicantRefId: rId && rId[1],
          metricId: 23,
          feedback: this.state.narrativeText,
        });
        this.props.addReferenceFeedback(metricIdArr)
          .then(() => {
            this.setState({ feedbackSubmitted: true }, this.getReferenceFeedback);
          });
      } else {
        alert('Please rate each & every metric');
      }
    } else {
      const metricIdArr = [{
        referenceId: rId && rId[2],
        metricId: 23,
        refId: userId,
        applicantRefId: rId && rId[1],
        feedback: this.state.narrativeText,
      }];
      this.props.addReferenceFeedback(metricIdArr)
        .then(() => this.setState({ feedbackSubmitted: true }, this.getReferenceFeedback));
    }
  }

  renderSection = metricName => (
    <div className="bx--type-delta bx--type-caps mb-3">
      {metricName}
    </div>
  )

  renderFeedback = (rm, feedbackProvided) => {
    const {
      metricId, metricName,
      feedback,
    } = rm || {};
    const { narrativeText } = this.state;
    return (
      <div className="pt-2 pb-2">
        {
          feedback && feedbackProvided &&  (
          <Fragment>
            <div className="bx--type-delta bx--type-caps mb-2">{metricName}</div>
            <p>{feedback}</p>
          </Fragment>)
        }
        {
          !feedback && !feedbackProvided && (
            <div className="pt-2 pb-2">
              <TextArea
                rows={4}
                name="feedback-narrative"
                id="feedback-narrative"
                labelText="Any additional feedback / comments (recommended minimum 200 words)"
                onChange={this.handleNarrativeChange}
                value={narrativeText}
              />
            </div>
          )
        }
      </div>);
  }

  renderRating = (metric, feedbackProvided) => {
    const {
      metricId,
      metricName,
      metricDescription,
      rating,
    } = metric || {};
    return (
      <div className="bx--row pl-2 pr-2 mb-4" key={metricId}>
        <div style={{ width: '250px' }} className="mr-4">
          <p className="mb-0">{metricName}</p>
          {metricDescription && (
            <div className="text-dark text-justify text-uppercase">
              <small>{metricDescription}</small>
            </div>)}
        </div>
        {this.starRating(metricId, rating, feedbackProvided)}
      </div>
    );
  }

  renderMetric = (metric, feedbackProvided) => {
    const {
      metricId, metricName, metricDescription,
      feedback: Feedback = {},
      ratingmetrictype: {
        metricTypeName = '',
      } = {},
    } = metric || {};
    const { rating } = Feedback || {};
    const ratingObj = {
      metricId,
      metricName,
      metricDescription,
      rating,
    };
    const { feedback } = Feedback || {};
    const feedbackObj = {
      metricId,
      metricName,
      feedback,
    };
    let renderContent = (<div />);
    switch (metricTypeName.toLowerCase()) {
      case 'section':
        renderContent = this.renderSection(metricName);
        break;
      case 'rating':
        renderContent = this.renderRating(ratingObj, feedbackProvided);
        break;
      case 'feedback':
        renderContent = this.renderFeedback(feedbackObj, feedbackProvided);
        break;
      default:
        //
    }
    return renderContent;
  }

  render() {
    const {
      questions:{
        sectionId,
        metricsResult = [],
      },
      questions,
      loading,
      referrer: {
        feedbackProvided,
        referrerName,
        referrerEmail,
      } = {},
    } = this.props;
    const { feedbackSubmitted, inviteError } = this.state;
    return (
      <div>
        {
          !loading && feedbackSubmitted && !inviteError && (
            <InlineNotification lowContrast
              kind="success"
              role="alert"
              className="mt-1 mb-1"
              title="Feedback submitted successfully"
              subtitle=""
              iconDescription="Closes the notification"
              onCloseButtonClick={() => this.setState({ feedbackSubmitted: false })}
            />
          )
        }
        <BackRow paths={this.paths} />
        { inviteError && (
          <div className="bx--tile">
            <div className="bx--row flex-column align-items-center">
              <div className="empty-icon">
                <i className="h1 icon-flag" style={{ fontSize: '60px' }} />
              </div>
              <p className="bx--type-beta pt-4 pb-4 mb-0">Reference invite not found.</p>
            </div>
          </div>
        )}
        {
        (!loading && !inviteError) && (
          <Tile className="p-4">
            <h4 className="bx--type-gamma font-weight-bold text-capitalize">Reference Feedback</h4>
            {
              !feedbackProvided && (
              <p className="mb-0">
                Please provide feedback for the candidate
                <b> { referrerName } ({ referrerEmail }).</b>
                <div>We keep your rating private from the candidate.</div>
              </p>)
            }
            {
              feedbackProvided && (
              <p className="mb-0">
                You have provided the following feedback for
                <b> { referrerName } ({ referrerEmail }).</b>
                <div>We keep your rating private from the candidate.</div>
              </p>)
            }
            <div className="bx--modal-header__label bx--type-delta">Rating criteria</div>            
            <p className="text-dark bx--type-omega mt-2">
              <span className="pr-2"><i className="fa fa-star text-warning"></i> - Area of concern</span>
              <span className="pr-2"><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i> - Below average</span>
              <span className="pr-2"><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i> - Average</span>
              <span className="pr-2"><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i> - Good</span>
              <span className="pr-2"><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i><i className="fa fa-star text-warning"></i> - Exceptional</span>
            </p> 
            <div>
              <div className="bx--row justify-content-between">
                {
                  questions && (
                    <Fragment>
                      {
                            <Fragment key={sectionId}>
                                {
                                  metricsResult.map((m = {}) =>(m && (
                                    <div className="bx--col-md-5">
                                      {
                                        Array.isArray(m.metrics)
                                        && (m.metrics).map(res => (res && <Fragment key={res.metricId}>{this.renderMetric(res, feedbackProvided)}</Fragment>))
                                      }
                                    </div>
                                  ))
                                  )
                                }
                            </Fragment>
                      }
                    </Fragment>
                  )
                }
              </div>
            </div>
            {
              !feedbackProvided && (questions) && (
              <div className="bx--row align-items-center justify-content-between pr-4 pl-4 pt-2">
                <Button onClick={this.handleSubmit}>Submit</Button>
              </div>)
            }
          </Tile>)
        }
        {
          (false && !loading && feedbackSubmitted && !inviteError) && (
          <Tile className="row justify-content-center p-5 text-center">
            <div className="bx--row flex-column align-items-center">
              <div className="empty-icon">
                <i className="h1 icon-flag" style={{ fontSize: '60px' }} />
              </div>
              <h3>Thanks for submitting the feedback.</h3>
              <p className="bx--type-beta pt-4 pb-4 mb-0">We appreciate your time.</p>
            </div>
          </Tile>)
        }
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>);
  }
}

const mapStateToProps = state => ({
  loading: state.candidateReferences.loading,
  user: state.x0paDuser.user,
  questions: state.candidateReferences.questions,
  referrer: state.candidateReferences.referrer,
});

const mapDispatchToProps = {
  getReferenceInviteConfigs,
  addReferenceFeedback,
  validateReference,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceFeedback);
