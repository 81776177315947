import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import {
  getAnalyticsActivityReports,
} from 'actions/analytics';
import PieChartAnalytics from 'components/AnalyticsView/PieChartAnalytics';
import BarChartAnalytics from 'components/AnalyticsView/BarChartAnalytics';
import './AdminAnalyticsView.css';

class AdminAnalyticsView extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'analytics', name: 'Analytics', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      internshipAggsData: [],
      cityAggsData: [],
      countriesAggsData: [],
      industryAggsData: [],
      industryPreferenceAggsData: [],
      functionAggsData: [],
      jobFunctionPreferenceAggsData: [],
    };
  }

  componentDidMount() {
    this.props.getAnalyticsActivityReports().then((res) => {
      if (res && !res.error) {
        this.handleAnalyticsReport();
      }
    }).catch(e => console.log('Error:::::', e));
  }

  handleAnalyticsReport = () => {
    const {
      activityReports,
    } = this.props;
    const {
      citiesAggs,
      countriesAggs,
      internshipAggs,
      industryAggs,
      functionAggs,
      cityPreferenceAggs,
      jobFunctionPreferenceAggs,
      industryPreferenceAggs,
    } = activityReports || {};
    const keys = internshipAggs && Object.keys(internshipAggs);
    const internshipAggsFilter = keys && Array.isArray(keys)
      && keys.length > 0
      && keys.map((res, idx) => {
        const data = {};
        if (idx <= 4) {
          data.key = `${res} weeks`;
          data.count = Number(internshipAggs[res]);
        }
        return data;
      });
    const internshipAggsData = internshipAggsFilter && Array.isArray(internshipAggsFilter)
      && internshipAggsFilter.length > 0 && internshipAggsFilter.filter(res => res && res.key && res.key !== null && res.count !== 0);
    const cityAggsData = citiesAggs && Array.isArray(citiesAggs)
      && citiesAggs.length > 0
      && citiesAggs.map((res) => {
        const {
          count,
          city_name,
        } = res || {};
        const finalData = {
          name: city_name,
          value: Number(count) ,
        };
        return finalData;
      });
    const countriesAggsData = countriesAggs && Array.isArray(countriesAggs)
      && countriesAggs.length > 0
      && countriesAggs.map((res) => {
        const {
          count,
          country_full,
        } = res || {};
        const data = {
          value: Number(count) ,
          name: country_full,
        };
        return data;
      });
    const industryAggsArr = industryAggs && Array.isArray(industryAggs)
      && industryAggs.length > 0
      && industryAggs.map((res, idx) => {
        const {
          industry_name,
          count,
        } = res || {};
        const data = {};
        if (idx <= 4) {
          data.key = industry_name;
          data.count = Number(count);
        }
        return data;
      });
    const industryAggsData = industryAggsArr && Array.isArray(industryAggsArr)
      && industryAggsArr.length > 0 && industryAggsArr.filter(res => res && res.key);
    const functionAggsFilter = functionAggs && Array.isArray(functionAggs)
      && functionAggs.length > 0
      && functionAggs.map((res) => {
        const {
          function_name,
          count,
        } = res || {};
        const data = {
          key: function_name,
          count: Number(count),
        };
        return data;
      });
    const functionAggsData = functionAggsFilter && Array.isArray(functionAggsFilter)
      && functionAggsFilter.length > 0
      && functionAggsFilter.filter(res => res && res.key);
    const industryPreferenceAggsFilter = industryPreferenceAggs && Array.isArray(industryPreferenceAggs)
    && industryPreferenceAggs.length > 0
    && industryPreferenceAggs.map((res, idx) => {
      const {
        industry_name,
        count,
      } = res || {};
      const finalData = {};
      if (idx <= 4) {
        finalData.key = industry_name;
        finalData.count = Number(count);
      }
      return finalData;
    });
    const industryPreferenceAggsData = industryPreferenceAggsFilter && Array.isArray(industryPreferenceAggsFilter)
      && industryPreferenceAggsFilter.length > 0
      && industryPreferenceAggsFilter.filter(res => res && res.key);
    const cityPreferenceAggsData = cityPreferenceAggs && Array.isArray(cityPreferenceAggs)
    && cityPreferenceAggs.length > 0
    && cityPreferenceAggs.map((res) => {
      const {
        city_name,
        count,
      } = res || {};
      const finalData = {
        name: city_name,
        value: Number(count) ,
      };
      return finalData;
    });
  const jobFunctionPreferenceAggsData = jobFunctionPreferenceAggs && Array.isArray(jobFunctionPreferenceAggs)
  && jobFunctionPreferenceAggs.length > 0
  && jobFunctionPreferenceAggs.map((res) => {
    const {
      function_name,
      count,
    } = res || {};
    const finalData = {
      name: function_name,
      value: Number(count) ,
    };
    return finalData;
  });
    this.setState({
      jobFunctionPreferenceAggsData,
      cityPreferenceAggsData,
      industryPreferenceAggsData,
      functionAggsData,
      industryAggsData,
      countriesAggsData,
      cityAggsData,
      internshipAggsData,
    });
  }

  render() {
    const {
      t,
      activityReports,
    } = this.props;
    const {
      skillAggs,
      totalApplns,
      skillPreferenceAggs,
      totalJobs,
    } = activityReports || {};
    const {
      jobFunctionPreferenceAggsData,
      cityPreferenceAggsData,
      industryPreferenceAggsData,
      functionAggsData,
      industryAggsData,
      countriesAggsData,
      cityAggsData,
      internshipAggsData,
    } = this.state;
    return (
      <Fragment>
        <BackRow
          paths={this.paths}
        />
        <div className="h5 font-weight-bold mt-2 mb-2">
          {t('analytics')}
        </div>
        <div>
          <div className="h4 font-weight-bold text-dark">
            {t('jobs')} {t('analytics')}
          </div>
          <div className="d-flex justify-content-center">
            <div className="analytics-tile analytics-jobs-card">
              <div className="h5 text-center font-weight-bold">
                {totalJobs}
              </div>
              <div className="bx--type-epsilon text-center text-uppercase mb-2 text-dark">
                {t('totalJobs')}
              </div>
            </div>
          </div>
          <hr className="mt-2 mb-3" />
          <div className="bx--type-zeta text-capitalize mb-2">
            {t('topSkills')}
          </div>
          <div className="bx--row">
            {
              skillAggs && Array.isArray(skillAggs)
                && skillAggs.length > 0
                && skillAggs.map((res) => {
                  const {
                    skill_name,
                    count,
                  } = res || {};
                  return (
                    <div className="bx--tag bx--tag--beta ml-2 d-flex p-3">
                      <div className="text-capitalize">{skill_name}</div>
                      <div className="ml-1 bx--tag bx--tag--custom">{count}</div>
                    </div>
                  );
                })
            }
            {
              ((!skillAggs) || (skillAggs && Array.isArray(skillAggs)
              && skillAggs.length <= 0))
              && (
                <div className="ml-2 text-dark font-weight-bold text-center">{t('noSkills')}</div>
              )
            }
          </div>
          <hr className="mt-2 mb-2" />
          <div className="bx--row">
            <PieChartAnalytics data={cityAggsData} value="distributionByCity" />
            <PieChartAnalytics data={countriesAggsData} value="distributionByCountry" />
          </div>
          <hr className="mt-2 mb-3" />
          <div className="bx--row">
            <BarChartAnalytics data={internshipAggsData} value="distributionByInternship" toolTip="Jobs" />
            <BarChartAnalytics data={functionAggsData} value="distributionByFunction" toolTip="Jobs" />
            <BarChartAnalytics data={industryAggsData} value="distributionByIndustry" toolTip="Jobs" />
          </div>
        </div>
        <div className="mt-2 w-100">
          <div className="h4 font-weight-bold text-dark">
            {t('applications')} {t('analytics')}
          </div>
          <div className="d-flex justify-content-center">
            <div className="analytics-tile analytics-applications-card">
              <div className="h5 text-center font-weight-bold">
                {totalApplns}
              </div>
              <div className="bx--type-epsilon text-center text-uppercase mb-2 text-dark">
                {t('totalApplications')}
              </div>
            </div>
          </div>
          <hr className="mt-2 mb-2" />
          <div className="bx--type-zeta text-capitalize mb-2">
            {t('prefSkills')}
          </div>
          <div className="bx--row">
            {
              skillPreferenceAggs && Array.isArray(skillPreferenceAggs)
                && skillPreferenceAggs.length > 0
                && skillPreferenceAggs.map((res) => {
                  const {
                    skill_name,
                    count,
                  } = res || {};
                  return (
                    <div className="bx--tag bx--tag--beta ml-2 d-flex p-3">
                      <div className="text-capitalize">{skill_name}</div>
                      <div className="ml-1 bx--tag bx--tag--custom">{count}</div>
                    </div>
                  );
                })
            }
            {
              ((!skillPreferenceAggs) || (skillPreferenceAggs && Array.isArray(skillPreferenceAggs)
              && skillPreferenceAggs.length <= 0))
              && (
                <div className="ml-2 text-dark font-weight-bold text-center">{t('noSkills')}</div>
              )
            }
          </div>
          <hr className="mt-2 mb-2" />
          <div className="bx--row">
            <PieChartAnalytics data={cityPreferenceAggsData} value="preferredCitiesAnalytics" />
            <PieChartAnalytics data={jobFunctionPreferenceAggsData} value="preferredJobFunc" />
          </div>
          <hr className="mt-2 mb-2" />
          <div className="d-flex justify-content-center">
            <div>
              <div className="bx--type-zeta text-capitalize mb-3">
                {t('prefIndustries')}
              </div>
              <BarChartAnalytics data={industryPreferenceAggsData} value="" toolTip="applications" />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  activityReports: state.Analytics.activityReports,
});

const mapDispatchToProps = {
  getAnalyticsActivityReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminAnalyticsView));
