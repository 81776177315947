import { flaskUrl } from 'utils/config';
import callApi from '../../utils/callApi';
import { REQUEST_RESUME_UPLOAD } from './actionTypes';

// job parsing.
const parseResume = (file, pageType, jobId) => (dispatch) => {
  const url = `${flaskUrl}/resumes/parse`;

  const params = {
    actionType: REQUEST_RESUME_UPLOAD,
    url,
    dispatch,
    method: 'POST',
    body: file,
  };
  return callApi(params);
};

export default parseResume;
