export const REQUEST_USER_INVITATION = 'REQUEST_USER_INVITATION';
export const SUCCESS_USER_INVITATION = 'SUCCESS_USER_INVITATION';
export const ERROR_USER_INVITATION = 'ERROR_USER_INVITATION';

export const REQUEST_REJECT_INVITATION = 'REQUEST_REJECT_INVITATION';
export const SUCCESS_REJECT_INVITATION = 'SUCCESS_REJECT_INVITATION';
export const ERROR_REJECT_INVITATION = 'ERROR_REJECT_INVITATION';

export const REQUEST_ASSO_USER_APPL_MAP = 'REQUEST_ASSO_USER_APPL_MAP';
export const SUCCESS_ASSO_USER_APPL_MAP = 'SUCCESS_ASSO_USER_APPL_MAP';
export const ERROR_ASSO_USER_APPL_MAP = 'ERROR_ASSO_USER_APPL_MAP';

export const REQUEST_GET_OFFER_CONSENT = 'REQUEST_GET_OFFER_CONSENT';
export const SUCCESS_GET_OFFER_CONSENT = 'SUCCESS_GET_OFFER_CONSENT';
export const ERROR_GET_OFFER_CONSENT = 'ERROR_GET_OFFER_CONSENT';

export const REQUEST_OFFER_CONSENT_STATUS = 'REQUEST_OFFER_CONSENT_STATUS';
export const SUCCESS_OFFER_CONSENT_STATUS = 'SUCCESS_OFFER_CONSENT_STATUS';
export const ERROR_OFFER_CONSENT_STATUS = 'ERROR_OFFER_CONSENT_STATUS';
