import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  InlineNotification,
} from 'carbon-components-react';
import {
  getCandidateJobRecommendations,
} from 'actions/candidate/SkillsRecommended';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import Toast from 'components/common/Toast';
import CandidateInfo from 'components/roboroy/candidate/CandidateInfo';
import { fetchCandidateDashboard, getRecommendedJobs, getCandidateProfileView } from 'actions/candidate/dashboard';
import { fetchAll } from 'actions/userProfile/action';
import { REQUEST_USERINFO } from 'actions/userProfile/action.constant';

import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import {
  getCandidateProfile,
  getJobAppliedCount,
} from 'actions/talentSearch/candidatesList';

// import { Mixpanel } from 'components/common/Mixpanel';
import SetMixPanel from 'components/common/GetCurrentPath';

import './dashboard.css';
import JobInvites from './containers/JobInvites';
import JobsApplied from './containers/JobsApplied';
import JobsInterviews from './containers/JobsInterviews';
import JobsOffers from './containers/JobsOffers';
// import StdInfo from './containers/roboroy/dashboard/Dashboard/StdInfo';
import Stddetail from './Stddetail'

const track = {
  CANDIDATE_DASHBOARD_VIEWJOBS: 'CANDIDATE_DASHBOARD_VIEWJOBS',
  CANDIDATE_DASHBOARD_STARTFINLAND: 'CANDIDATE_DASHBOARD_STARTFINLAND',
};

class CandidateDashboard extends Component {
  state = {
    activeModal: false,
    uploadResumeClicked: false,
  };

  componentDidMount() {
    const {
      history: {
        push,
      },
    } = this.props;
    this.props.fetchAll({
      actionType: REQUEST_USERINFO,
      apiName: 'userinfo-base/profileInformation',
    }).then((res) => {
      const {
        meta,
      } = res || {};
      const {
        agreeRecommendations,
        userId: candidateUserId,
      } = meta || {};
      const { profileId = null } = this.props.user || {};
      if (profileId !== null) {
        this.props.getCandidateProfile(profileId).then((res) => {
          if(res && !res.error){
            let appInfo = res.applicationDetails
            if(appInfo && Array.isArray(appInfo) && appInfo.length > 0){
              appInfo.forEach((resps) => {
                let jobNameInfo = resps && resps.job && resps.job.jobname
                let jobName = jobNameInfo && jobNameInfo.nameName
                let companyNameInfo = resps && resps.job && resps.job.company
                let companyName = companyNameInfo && companyNameInfo.companyName
                this.setState({
                  companyName,
                  jobName
                })
              })
            }
          }

        })
        this.props.getJobAppliedCount(profileId).then((resp) => {
          if(resp && !resp.error){
            this.setState({
              jobapplycount: resp.count
            })
          }
        })
      }
      this.props.getCandidateProfileView(candidateUserId);
      this.props.fetchCandidateDashboard().then((response) => {
        if (response && response.profileId && agreeRecommendations === true) {
          this.props.getRecommendedJobs(response.profileId).then((jobs) => {
            if (jobs && jobs.length <= 0 && response.personDocId) {
              this.props.getCandidateJobRecommendations(response.profileId);
            }
          });
        }
      });
    });
  }

  handleShortListClick = (push) => {
    push('/app/campus/candidate/jobs/shortlisted');
  };

  handleRejectClick = (push) => {
    push('/app/campus/candidate/jobs/rejected');
  };

  activateModal = () => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
      uploadResumeClicked: true,
    });
  };

  toastSuccess = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  refresh = (partialRefresh) => {
    // setTimeout(() => {
    //   });
    // }, 1000);
  };

  handleRedirectRegister = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props || {};
    if (push) {
      push('/app/campus/candidate/register/profile');
    }
  }

  handleRedirectProfile = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props || {};
    if (push) {
      push('/app/profile');
    }
  }

  handleRedirectJobsApplied = (link) => {
    switch (link) {
      case 'applied':
        this.props.history.push('/app/campus/candidate/jobs/applied');
        break;
      case 'shortlisted':
        this.props.history.push('/app/campus/candidate/jobs/shortlisted');
        break;
      case 'rejected':
        this.props.history.push('/app/campus/candidate/jobs/rejected');
        break;
      case 'onsiteinterview-completed':
        this.props.history.push('/app/campus/candidate/jobs/onsiteinterview-completed');
        break;
      case 'onsiteinterview-inprogress':
        this.props.history.push('/app/campus/candidate/jobs/onsiteinterview-inprogress');
        break;
      case 'offer-completed':
        this.props.history.push('/app/campus/candidate/jobs/offer-completed');
        break;
      case 'offer-inprogress':
        this.props.history.push('/app/campus/candidate/jobs/offer-inprogress');
        break;
      default:
        break;
    }
  }

  render() {
    const {
      dashboard: {
        recommendedJobs = [],
        dashboard: {
          recuiterJobInvites,
          recuiterJobInvitesAccepted,
          recuiterJobsInvitesRejected,
          recuiterJobInvitesPending,
          candidateJobsApplied,
          candidateJobsShortlisted,
          candidateJobsRejected,
          candidateJobsScreening,
          offerCompleted,
          offerInprogress,
          offerInvites,
          onsiteInterviewsInprogress,
          onsiteInterviewsCompleted,
          recuiterJobStatus: {
            inprogressCount: rInprogressCount = 0,
            videointerviewCount: rVideointerviewCount = 0,
            phoneinterviewCount: rPhoneinterviewCount = 0
          } = {},
          candidateJobStatus: {
            stagesInProgress: { phoneInterview = 0, videoInterview = 0, writtenAssessment = 0 } = {}
          } = {},
          summary,
          personDocId,
        } = {},
      } = {},
      user: { userId, publicVisibility, firstname = '', lastname = '', picture, profileId },
      loading,
      history: { push },
      taskId,
      dashboardProfileViewsCount,
      dashboardProfileViews,
      t,
      candidateProfile: {
        documents,
        studentprofile: {
          faculty: {
            totalJobsApplicable,
          } = {},
          isAppSubmitted,
          isRoomAssessmentDone,
          isProfilingAssessmentDone,
          isLiveInterviewDone,
        } = {},
      } = {},
      count,
      offerAcceptedCount,
    } = this.props;
    const {
      jobapplycount,
      companyName,
      jobName
    } = this.state
    let PCD = "Pending Companies' Decision"
    let totalJobsApplyFor = (totalJobsApplicable || 0) - (jobapplycount || 0)
    if(totalJobsApplyFor<0){
      totalJobsApplyFor = 0
    }
    let filteredDocs = [];
    if (documents && Array.isArray(documents) && documents.length > 0) {
      filteredDocs = documents.filter(res => res.typeId === 12)
    }

    return (
      <Fragment>
        <div className="xpa-pg-candidate--dashboard">
          <div className="">
            <div>
              {t('welcome')}!
            </div>
              

            <h5 className="text-uppercase pb-2 font-weight-bold">
              {firstname} {lastname}
            </h5>
          </div>
          <hr />
          <Stddetail 
          firstname={firstname} 
          lastname={lastname}
          picture={picture}
          profileId={profileId}
          isAppSubmitted={isAppSubmitted}
          isRoomAssessmentDone={isRoomAssessmentDone}
          isProfilingAssessmentDone={isProfilingAssessmentDone}
          isLiveInterviewDone={isLiveInterviewDone}
          push={push}/>
            
          {
            offerAcceptedCount > 0 && (
              <div>
                {
                  filteredDocs && Array.isArray(filteredDocs)
                    && filteredDocs.length <= 0 ? (
                      <InlineNotification
                        lowContrast
                        kind="warning"
                        title={
                          <div>
                            Congratulations! You have successfully secured your internship placement as {jobName} with {companyName}.&nbsp;&nbsp;
                            <span onClick={() => { push && push({ pathname: '/app/profile', state: { selectedTab: 1 } }) }} className="xpa-link">Click here to upload the offer letter </span>
                            once you have signed it with the company.
                          </div>
                        }
                        subtitle=""
                        hideCloseButton
                      />
                    ) : (
                      <InlineNotification
                        lowContrast
                        kind="success"
                        title="You have concluded the entire process"
                        subtitle=""
                        hideCloseButton
                      />
                    )
                }
              </div>
            )
          }
          {
            candidateJobsShortlisted > 0 && offerInprogress === 0 && offerAcceptedCount === 0 && (
              <InlineNotification
                lowContrast
                kind="warning"
                title={
                  <div>
                    You have been shortlisted by a company/ some companies for interviews.
                    Please check your email inbox regularly for more communications.
                  </div>
                }
                subtitle=""
                hideCloseButton
              />
            )
          }
          {
            offerInprogress > 0 && offerAcceptedCount === 0 && (
              <InlineNotification
                lowContrast
                kind="warning"
                title={
                  <div>
                   A Company / Some companies would like to offer you an internship position.&nbsp;&nbsp;
                   <span onClick={() => { push && push({ pathname: '/app/campus/candidate/jobs/offer-inprogress', state: { selectedTab: 2 } }) }} className="xpa-link">Click </span>
                   to view the offer(s) and accept/reject.
                  </div>
                }
                subtitle=""
                hideCloseButton
              />
            )
          }
          {
            isAppSubmitted !== true
            && (
              <div className="mb-2">
                <div className="mb-2">
                  {/* {t('registerGeipHeading')} */}
                </div>
                {/* <div>
                  <Button onClick={this.handleRedirectRegister} small kind="primary">{t('start')}</Button>
                </div> */}
              </div>
            )
          }
          {
            isAppSubmitted === true && false
            && (
              <div className="mb-2">
                <div className="d-lg-flex d-md-flex align-items-center">
                  <div>
                    <h6 className="font-weight-bold mb-0 mr-3">{t('viewApplRpnew')}</h6>
                  </div>
                  <div>
                    <Button onClick={this.handleRedirectProfile} small kind="primary">{t('viewprofile')}</Button>
                  </div>
                  <div>
                    <Link
                      className="ml-lg-2 ml-md-2 mt-2 mt-lg-0 mt-md-0 bx--btn bx--btn--tertiary bx--btn--sm"
                      target="_blank"
                      to={`/view/${personDocId}/cv?_=${new Date().getTime()}&ref=${btoa(userId)}`}
                      >
                      {t('downloadResume')}
                    </Link>
                  </div>
                </div>
              </div>
            )
          }
          {/* <hr /> */}
          {/* <InlineNotification lowContrast
            kind="info"
            title={t('toEditProfile')}
            subtitle={
              <Link className="bx--inline-notification__title" onClick={() => SetMixPanel(track.CANDIDATE_EDITPROFILE_CLICKED)} to='/app/campus/candidate/interncandidate/1'>{t('clickHere')}.</Link>
            }
            hideCloseButton
          /> */}
          {this.state.uploadResumeClicked === true && <div className="toastCandidate">
            <Toast taskId={taskId} successAction={this.toastSuccess} partialRefresh={this.refresh} />
          </div>}
          {/* <GtpOptinCard hideIfSet={publicVisibility} /> */}
          {/* <div className="mb-2">
            <div className="">
              <div className="">
                <CandidateInfo
                  firstname={firstname}
                  lastname={lastname}
                  picture={picture}
                  profileId={profileId}
                  push={push}
                />
              </div>
            </div>
            <hr />
            <div className="text-left">
              <Button onClick={this.activateModal}>{t('uploadResume')}</Button>
              {personDocId && (
                <Fragment>
                  <Link
                    className="ml-2 bx--btn bx--btn--tertiary"
                    target="_blank"
                    to={`/view/${personDocId}/cv?_=${new Date().getTime()}&ref=${btoa(userId)}`}
                    >
                    {t('viewResume')}
                  </Link>
                  <Link
                      className="ml-2 mt-2 bx--btn bx--btn--tertiary"
                      to="/app/campus/candidate/references"
                    >
                      References
                    </Link>
                </Fragment>
              )}
            </div>
            <hr />
          </div> */}
          {/* {
            summary && (
              <div>
                <div className="text-left">
                  <blockquote>
                    <p className="text-dark">
                      {summary}
                    </p>
                  </blockquote>
                </div>
              </div>
            )
          } */}
          {/*
            dashboardProfileViews
            && Array.isArray(dashboardProfileViews)
            && dashboardProfileViews.length > 0
            && (
              <div className="mt-2 pt-0 p-2">
                <div className="d-flex mb-0 ml-2 align-items-center">
                  <div>
                    <div className="h2 font-weight-bold text-dark pt-2 m-0 text-center">{Math.round(dashboardProfileViewsCount) || 0}</div>
                    <div className="text-dark">{t('profileViews')}</div>
                  </div>
                  <div className="ml-2" style={{ borderLeft: '1px solid #ccc', height: '75px' }}></div>
                  <div className="ml-3">
                    {
                      dashboardProfileViews.map((res, idx) => {
                        const {
                          companyName,
                          count,
                        } = res || {};
                        return (
                          <div>
                            {
                              idx <= 4
                                && (
                                  <span>{count} views from <strong>{companyName}</strong>.</span>
                                )
                            }
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            )
          */}
          {/* <JobInvites
            recuiterJobInvites={recuiterJobInvites}
            recuiterJobInvitesAccepted={recuiterJobInvitesAccepted}
            recuiterJobsInvitesRejected={recuiterJobsInvitesRejected}
            recuiterJobInvitesPending={recuiterJobInvitesPending}
            history={this.props.history}
          /> */}

          {
            isAppSubmitted === true  && false && (
              <div>
                          <div>
            <h4 className="text-uppercase">
              Applications
            </h4>
            <div className="bx--row justify-content-between">
              <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                <div onClick={() => { push && push('/app/campus/candidate/browse') }}  style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                  {totalJobsApplyFor}
                </div>
                <div className="text-dark text-uppercase">
                  Application Slots
                </div>
              </div>
              <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                <div onClick={() => { this.handleRedirectJobsApplied('applied') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                  {candidateJobsApplied}
                </div>
                <div className="text-dark text-uppercase">
                  Number Of Positions Applied
                </div>
              </div>
              <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                <div onClick={() => { this.handleRedirectJobsApplied('shortlisted') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                  {candidateJobsShortlisted}
                </div>
                <div className="text-dark text-uppercase">
                  Shortlisted By Companies For Interviews
                </div>
              </div>
              {/*<div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                <div onClick={() => { this.handleRedirectJobsApplied('onsiteinterview-completed') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                  {onsiteInterviewsCompleted}
                </div>
                <div className="text-dark text-uppercase">
                  Interviewed
                </div>
              </div>*/}
            </div>
            <div className="bx--row justify-content-between mt-3">
              <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                <div onClick={() => { this.handleRedirectJobsApplied('onsiteinterview-inprogress') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                  {onsiteInterviewsInprogress}
                </div>
                <div className="text-dark text-uppercase">
                  {PCD}
                </div>
              </div>
              {/*<div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                <div onClick={() => { this.handleRedirectJobsApplied('offer-completed') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                  {offerCompleted}
                </div>
                <div className="text-dark text-uppercase">
                  Offered
                </div>
              </div>*/}
               <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                <div onClick={() => { this.handleRedirectJobsApplied('offer-inprogress') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                  {offerInprogress}
                </div>
                <div className="text-dark text-uppercase">
                  Offered By Companies
                </div>
              </div>
              <div className="bx--col-xl-4 bx--col-lg-4 bx--col-md-2 bx--col-sm-4 bx--col-xs-4 text-center">
                <div onClick={() => { this.handleRedirectJobsApplied('rejected') }} style={{ fontSize: 'xx-large' }} className="xpa-link font-weight-bold">
                  {candidateJobsRejected}
                </div>
                <div className="text-dark text-uppercase">
                  Rejected
                </div>
              </div>
            </div>
          </div>
          <hr />
                <JobsApplied
                  candidateJobsApplied={candidateJobsApplied}
                  candidateJobsShortlisted={candidateJobsShortlisted}
                  candidateJobsRejected={candidateJobsRejected}
                  candidateJobsScreening={candidateJobsScreening}
                  history={this.props.history}
                />
                <JobsInterviews
                  videoInterview={videoInterview}
                  phoneInterview={phoneInterview}
                  writtenAssessment={writtenAssessment}
                  history={this.props.history}
                  onsiteInterviewsInprogress={onsiteInterviewsInprogress}
                  onsiteInterviewsCompleted={onsiteInterviewsCompleted}
                />
                <JobsOffers
                  offerInvites={offerInvites}
                  offerInprogress={offerInprogress}
                  offerCompleted={offerCompleted}
                  history={this.props.history}
                />
              </div>
            )
          }
          <ResumeUpload
            pageType="candidate-profile"
            dismiss={this.dismissModal}
            active={this.state.activeModal}
            multipleResume={false}
          />
          <JDProcessOverlay show={loading} message="processing..." />
        </div>
      </Fragment>
    );
  }
  }

const mapStateToProps = state => ({
  loading: state.candidateDashboard.loading,
  dashboard: state.candidateDashboard,
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
  taskId: state.resumeUpload.taskId,
  candidateProfile: state.roboroyApplications.candidateProfile,
  dashboardProfileViews: state.candidateDetails.dashboardProfileViews,
  dashboardProfileViewsCount: state.candidateDetails.dashboardProfileViewsCount,
  offerAcceptedCount: state.roboroyApplications.offerAcceptedCount,
});

const mapDispatchToProps = {
  fetchAll,
  fetchCandidateDashboard,
  getRecommendedJobs,
  getCandidateJobRecommendations,
  getCandidateProfile,
  getCandidateProfileView,
  getJobAppliedCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CandidateDashboard));
