import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loading } from 'carbon-components-react';

import {
  getOfferDocumentsById,
} from 'actions/offerInformation';

class ViewOfferDocumentCv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: '',
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { match: { params: { id } = {} } = {} } = this.props;
    this.props.getOfferDocumentsById(id)
      .then((res) => {
        if (res && !res.error) {
          const c = URL.createObjectURL(res);
          this.setState({
            loading: false,
            docs: c,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  render() {
    const { docs, loading, error } = this.state;
    return (
      <div className="bx--row xpa-vh-100 justify-content-center align-items-center ml-2 mr-2">
        {
          error && (
            <h1>
              Error occured while downloading the docs.
            </h1>
          )
        }
        {
          loading && !error && <Loading />
        }
        {
          !loading && !error && (
            <embed
              src={`${docs}`}
              type="application/pdf"
              style={{
                width: '100vw',
                height: '100vh',
              }}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getOfferDocumentsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewOfferDocumentCv);
