import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getCandidateRecommendedSkills,
  getSkillRecommendations,
} from 'actions/candidate/SkillsRecommended';
import { Link } from 'react-router-dom';
import FillCircle from 'components/common/FillCircle/FillCircle';
import { fetchCandidateDashboard, getRecommendedJobs } from 'actions/candidate/dashboard';
import {
  Tile, Tag, StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,
  Button,
} from 'carbon-components-react';

class SkillsRecommended extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    const {
      user: {
        profileId,
      } = {},
    } = this.props;
    this.props.fetchCandidateDashboard();
    if (profileId) {
      this.props.getCandidateRecommendedSkills(profileId).then((res) => {
        if (res && Array.isArray(res.skills) && res.skills.length <= 0) {
          this.props.getSkillRecommendations().then(() => {
            this.props.getCandidateRecommendedSkills(profileId);
          });
        }
      });
      this.props.getRecommendedJobs(profileId);
    }
  }

  render() {
    const {
      recommendedSkills,
      recommendedJobs = [],
      dashboard: {
        personDocId,
      } = {},
      user: {
        agreeRecommendations,
      } = {},
    } = this.props;
    return (
      <Fragment>
        <div className="h5 font-weight-bold">X0PA Recommendations</div>
        {
          agreeRecommendations === true ? (
            <Fragment>
              <Tile>
                <div>
                  <div className="mt-1 h5 font-weight-bold text-uppercase text-dark">
                    Skills
                  </div>
                  <hr />
                  <div>
                    {recommendedSkills && Array.isArray(recommendedSkills)
                      && recommendedSkills.length > 0 && personDocId
                      ? (
                        <div className="ml-1 font-weight-bold mb-2">Based on your current profile, X0PA recommends the following skills for you:</div>
                      ) : (
                        <div>
                          {
                            !personDocId && (
                              <span className="ml-1 font-weight-bold mb-2">Please upload your resume to get skill recommendation</span>
                            )
                          }
                        </div>
                      )
                    }
                    {
                      recommendedSkills && Array.isArray(recommendedSkills)
                        && recommendedSkills.length > 0
                        && recommendedSkills.map((skills, key) => (
                          <Tag
                            key={key}
                            type="beta"
                          >
                            <span className="text-uppercase">
                              {skills && skills.skill && skills.skill.skillName}
                            </span>
                          </Tag>
                        ))
                    }
                  </div>
                </div>
              </Tile>
              {
                <Tile className="p-4 mt-2 mb-2">
                  <div className="h5 font-weight-bold text-dark">
                    Jobs You May Be Interested In
                  </div>
                  <hr />
                  <div>
                    {
                      personDocId && recommendedJobs.length <= 0
                        ? (
                          <span className="font-weight-bold mb-2">We are crunching job recommendations and will show up soon on your profile.</span>
                        ) : (
                          <div className="font-weight-bold mb-2">
                            {
                              !personDocId && (
                                <span>Please upload your resume to get job recommendations.</span>
                              )
                            }
                          </div>
                        )
                    }
                  </div>
                  <div>
                    {
                      recommendedJobs.length > 0 && (
                        <StructuredListWrapper>
                          <StructuredListHead>
                            <StructuredListRow head>
                              <StructuredListCell head>Job Title</StructuredListCell>
                              <StructuredListCell head>Company</StructuredListCell>
                              <StructuredListCell head>Relevancy</StructuredListCell>
                            </StructuredListRow>
                          </StructuredListHead>
                          <StructuredListBody>
                            {recommendedJobs.map((rj) => {
                              const {
                                match_id: matchId,
                                company_name: companyName,
                                name_name: nameName,
                                job_id: jobId,
                                skillonlymatch = 0,
                              } = rj;
                              const fillScore = Number.isNaN(Number(skillonlymatch))
                                ? 0
                                : Math.round(Number(skillonlymatch));
                              return (
                                <StructuredListRow key={matchId}>
                                  <StructuredListCell style={{ verticalAlign: 'middle' }}>
                                    <Link to={`/app/campus/job/${jobId}`}>{nameName}</Link>
                                  </StructuredListCell>
                                  <StructuredListCell
                                    className="text-dark"
                                    style={{ verticalAlign: 'middle' }}
                                  >
                                    {companyName}
                                  </StructuredListCell>
                                  <StructuredListCell style={{ verticalAlign: 'middle' }}>
                                    <FillCircle
                                      fill={fillScore}
                                      fillColor="#53777A"
                                      textColor="#53777A"
                                    />
                                  </StructuredListCell>
                                </StructuredListRow>
                              );
                            })}
                          </StructuredListBody>
                        </StructuredListWrapper>)
                    }
                  </div>
                </Tile>
              }
          </Fragment>
        ) : (
          <Tile>
            <div className="ml-2">
              <span className="font-weight-bold">You have choosen to disable recommendations from X0PA, to enable recommendations, please navigate to settings tab in my profile.</span>
              <div className="mt-2">
                <Button
                  kind="tertiary"
                  href='/app/profile'
                  small
                >
                  Change settings
                </Button>
              </div>
            </div>
          </Tile>
        )
      }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.SkillsRecommended.loading,
  error: state.SkillsRecommended.error,
  recommendedSkills: state.SkillsRecommended.recommendedSkills,
  recommendedJobs: state.candidateDashboard.recommendedJobs,
  dashboard: state.candidateDashboard.dashboard,
});

const mapDispatchToProps = {
  getCandidateRecommendedSkills,
  getSkillRecommendations,
  fetchCandidateDashboard,
  getRecommendedJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsRecommended);
