import {
  REQUEST_MARKETPLACES,
  ERROR_MARKETPLACES,
  SUCCESS_MARKETPLACES,
  REQUEST_MARKETAPP,
  ERROR_MARKETAPPS,
  SUCCESS_MARKETAPP,
  REQUEST_COMPANY_MARKETPLACE_MAP,
  ERROR_COMPANY_MARKETPLACE_MAP,
  SUCCESS_COMPANY_MARKETPLACE_MAP,
} from '../actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  applications: [],
  applicationDetails: {},
};

const marketplace = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case REQUEST_MARKETPLACES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ERROR_MARKETPLACES: {
      return {
        ...state,
        ...payload,
        error: true,
        loading: false,
      };
    }
    case SUCCESS_MARKETPLACES: {
      return {
        ...state,
        applications: payload,
        error: null,
        loading: false,
      };
    }
    case REQUEST_MARKETAPP: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ERROR_MARKETAPPS: {
      return {
        ...state,
        ...payload,
        error: true,
        loading: false,
      };
    }
    case SUCCESS_MARKETAPP: {
      return {
        ...state,
        applicationDetails: payload,
        error: null,
        loading: false,
      };
    }
    case REQUEST_COMPANY_MARKETPLACE_MAP: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ERROR_COMPANY_MARKETPLACE_MAP: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    case SUCCESS_COMPANY_MARKETPLACE_MAP: {
      return {
        ...state,
        error: null,
        loading: false,
      };
    }    
    default:
      return state;
  }
};

export default marketplace;
