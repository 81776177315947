import { withTranslation } from 'react-i18next';
import React from 'react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import {
  Button, TextInput,
} from 'carbon-components-react';
import moment from 'moment';

const OfferTable = ({
  offerDetails,
  otherActionButtons,
  currencies,
  timeInterval,
  columnWithFilter,
  applications,
  activeOfferSendModal,
  activeAddOfferModal,
  activeAcceptOfferModal,
  activeRejectOfferModal,
  roleId,
  latestOfferId,
  collaboratorRes,
  activeOfferAcceptanceModal,
  handleAddInputValues,
  inputValues,
  handleChangeInputValues,
  state,
  handleDeleteInputValues,
  isValidTextInput,
  isValidTextInputContent,
  handleUploadFile,
  handleSubmitOfferDocuments,
  activeDeleteOfferDocModal,
  user,
  offerAcceptedCount,
  t,
}) => {
  const {
    appId, jobId,
    applicationsource,
    applicationemail,
    profile: {
      firstName,
      lastName,
    } = {},
  } = (applications && applications[0]) || [{}];
  const {
    isAdmin,
    isSuperAdmin,
  } = user;
  const { sourceName = '' } = applicationsource || {};
  const sendOfferResArr = applicationemail && Array.isArray(applicationemail)
  && applicationemail.filter(e => e.templateName === 'recruiter-sends-offer-details');
  const applicationSourceName = (sourceName && ((''+sourceName).toLowerCase())) || '';
  const superAdmin = isSuperAdmin === true;
  const admin = isAdmin === true && user.currentCompany && user.currentCompany.ctypeId == 1;
  return (
    <div>
      <GenericDataTable
        className=""
        otherActions={otherActionButtons}
        expandedRows={
        offerDetails.map((res) => {
          return (
            <span>
              <OfferInformationDetails
                offerId={res && res.offerId}
                offerDetails={offerDetails}
                currencies={currencies}
                timeInterval={timeInterval}
                inputValues={inputValues}
                handleAddInputValues={handleAddInputValues}
                handleChangeInputValues={handleChangeInputValues}
                state={state}
                handleDeleteInputValues={handleDeleteInputValues}
                isValidTextInput={isValidTextInput}
                isValidTextInputContent={isValidTextInputContent}
                handleUploadFile={handleUploadFile}
                handleSubmitOfferDocuments={handleSubmitOfferDocuments}
                roleId={roleId}
                activeDeleteOfferDocModal={activeDeleteOfferDocModal}
                t={t}
              />
            </span>
          );
        })
        }
        headers={
          columnWithFilter.map((data, index) => (
            {
              key: `header_${index}`,
              header: data.title,
              className: data.className,
              colpropName: data.propName,
            }
          ))
        }
        rows={
          offerDetails.map((res) => {
            const {
              offerrejectreason,
            } = res || {};
            const {
              reason,
              rejectreason: {
                reason: rejectedReasonMsg
              } = {},
            } = offerrejectreason || {};
            const currenciesName = currencies && Array.isArray(currencies) && currencies.find(result => result.currencyId == res.currencyId);
            const salaryIntervalRes = timeInterval && Array.isArray(timeInterval)
            && timeInterval.length > 0
            && timeInterval.find(result => result.intervalId == res.salaryTypeId);
            return ({
              isExpanded: true,
              id: `row_${res && res.offerId}`,
              header_0: (
                <div className="text-capitalize m-0">
                  {res && res.name}
                  <div className="text-dark">
                    {
                      res
                        && res.startDate
                        ? (
                          <small title="Posted on">{`Posted on: ${moment(res && res.startDate).format('MMM DD YYYY')}`}</small>
                        ) : null
                    }
                  </div>
                  <div>
                    {
                      rejectedReasonMsg && rejectedReasonMsg !== ''
                        && (
                          <div className="text-justify">
                            <small><b>Reject Reason</b>: {rejectedReasonMsg}</small>
                          </div>
                        )
                    }
                  </div>
                  {
                    reason && reason !== ""
                      && (
                        <div>
                          <small><b>Additional Information</b>: {reason}</small>
                        </div>
                      )
                  }
                </div>
              ),
              header_1: (
                <div className="text-capitalize">
                  {res && res.position}
                </div>
              ),
              // header_2: res && res.joiningDate && moment(res.joiningDate).format('MMM DD, YYYY'),
              header_2: (
                res
                  && res.status
                  && res.status.toString()
                  && res.status.toLowerCase() === 'draft'
                  ? (
                    <span className="bx--tag bx--tag--beta ">{t('draft')}</span>
                  ) : (
                    res.status
                    && res.status.toString()
                    && res.status.toLowerCase() === 'inprogress'
                    ? (
                      <span className="bx--tag bx--tag--cool-gray">{t('offersInProgress')}</span>
                    ) : (
                      res.status
                      && res.status.toString()
                      && res.status.toLowerCase() === 'accepted'
                      ? (
                        <span className="bx--tag bx--tag--green">{t('accepted')}</span>
                      ) : (
                        <span className="bx--tag bx--tag--red">{t('rejected')}</span>
                      )
                    )
                  )
              ),
              header_3: (
                <div>
                  {
                    roleId && roleId !== 3
                      && (
                        <div className="d-flex">
                          <div>
                            { res
                              && res.status
                              && res.status.toString()
                              && res.status.toLowerCase() === 'draft'
                              && (
                              <Button
                                className="ml-2"
                                disabled={!(collaboratorRes.length > 0 || superAdmin || admin) || offerAcceptedCount > 0}
                                onClick={() => activeOfferSendModal(applicationSourceName, appId, jobId, res && res.offerId)}
                                kind="primary"
                                small
                              >
                                &nbsp;{t('send')}&nbsp;
                              </Button>
                              )
                            }
                          </div>
                          <div>
                            { sendOfferResArr
                              && Array.isArray(sendOfferResArr)
                              && sendOfferResArr.length > 0
                              && res
                              && res.status
                              && res.status.toString()
                              && res.status.toLowerCase() === 'inprogress'
                              && (
                              <Button
                                className="ml-2"
                                disabled={!(collaboratorRes.length > 0 || superAdmin || admin) || offerAcceptedCount > 0}
                                onClick={() => activeOfferSendModal(applicationSourceName, appId, jobId, res && res.offerId)}
                                kind="primary"
                                small
                              >
                                &nbsp;{t('resend')} {t('offer')}&nbsp;
                              </Button>
                              )
                            }
                          </div>
                          <div>
                            <span>
                              {
                              res
                              && res.status
                              && res.status.toString()
                              && res.status.toLowerCase() !== 'inprogress'
                              && res.status.toLowerCase() !== 'draft'
                              && '-'
                              }
                            </span>
                            {/* {
                              res
                              && res.status
                              && res.status.toString()
                              && res.status.toLowerCase() === 'draft'
                                ? (
                                  <Button
                                    className="ml-2"
                                    onClick={() => activeAddOfferModal(res && res.offerId)}
                                    disabled={!(collaboratorRes.length > 0 || superAdmin || admin)}
                                    kind="tertiary"
                                    small
                                  >
                                    &nbsp;{t('edit')}&nbsp;
                                  </Button>
                                ) : (
                                  <span>
                                    {
                                    res
                                    && res.status
                                    && res.status.toString()
                                    && res.status.toLowerCase() !== 'inprogress'
                                    && '-'
                                    }
                                  </span>
                                )
                            } */}
                          </div>
                        </div>
                      )
                  }
                  {
                    roleId && roleId === 3
                      && res
                      && res.status
                      && res.status.toString()
                      && res.status.toLowerCase() === 'inprogress'
                      ? (
                        <div className="d-flex justify-lg-content-center justify-content-md-center">
                          <Button
                            className="ml-2"
                            onClick={() => activeAcceptOfferModal(res && res.offerId)}
                            kind="primary"
                            small
                          >
                            &nbsp;{t('accept')}&nbsp;
                          </Button>
                          <Button
                            className="ml-2"
                            onClick={() => activeRejectOfferModal(res && res.offerId)}
                            kind="danger"
                            small
                          >
                            &nbsp;{t('reject')}&nbsp;
                          </Button>
                        </div>
                      ) : (
                        <span>
                          {
                            roleId
                            && roleId === 4 ? null : '-'
                          }
                        </span>
                      )
                  }
                  {/* {
                    roleId && Number(roleId) === 3 && res
                      && res.status
                      && res.status.toString()
                      && res.status.toLowerCase() === 'accepted'
                      && (
                        <span className="bx--tag bx--tag--green">{t('accepted')}</span>
                      )
                  }
                  {
                    roleId && Number(roleId) === 3
                      && res.status
                      && res.status.toString()
                      && res.status.toLowerCase() === 'rejected' && (
                        <span className="bx--tag bx--tag--red">{t('rejected')}</span>
                    )
                  } */}
                  {/* {
                    roleId && roleId === 3
                    && res
                    && res.status
                    && res.status.toString()
                    && res.status.toLowerCase() !== 'inprogress'
                    && res.status.toLowerCase() !== 'draft'
                    && latestOfferId == (res && res.offerId)
                    && (
                      <Button
                        kind="primary"
                        small
                        className="ml-2"
                        onClick={() => activeOfferAcceptanceModal(res && res.offerId, res && res.status)}
                      >
                        {t('changeDecision')}
                      </Button>
                    )
                  } */}
                </div>
              ),
            })})
            }
        selectable={false}
        searchable={false}
        onSearchInputChange={() => { }}
        expandable={false}
        batchActions={false}
      />
    </div>
  );
};

const OfferInformationDetails = ({
  offerId, offerDetails, currencies, timeInterval,
  inputValues, handleAddInputValues, handleChangeInputValues,
  state, handleDeleteInputValues, isValidTextInput, isValidTextInputContent,
  handleUploadFile, handleSubmitOfferDocuments, roleId, activeDeleteOfferDocModal,
  t,
}) => {
  const offerDetailsRes = offerDetails && Array.isArray(offerDetails)
    && offerDetails.length > 0
    && offerDetails.find(res => res.offerId == offerId);
  const currenciesName = currencies && Array.isArray(currencies)
    && currencies.length > 0
    && currencies.find(res => res.currencyId == offerDetailsRes.currencyId);
  const salaryIntervalRes = timeInterval && Array.isArray(timeInterval)
    && timeInterval.length > 0
    && timeInterval.find(res => res.intervalId == offerDetailsRes.salaryTypeId);
  const euityIntervalRes = timeInterval && Array.isArray(timeInterval)
  && timeInterval.length > 0
  && timeInterval.find(res => res.intervalId == offerDetailsRes.equityVestingInterval);
  return (
    <div className="mb-2">
      {offerDetailsRes && offerDetailsRes !== null
        && (
          <div>
            <div className="text-center h5 mt-2">
              <strong>
                {t('offer')} {t('details')}
              </strong>
            </div>
            <section className="bx--structured-list">
              <div className="bx--structured-list-tbody">
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('offer')} {t('name')}</div>
                  <div className="bx--structured-list-td">
                    {offerDetailsRes.name ? offerDetailsRes.name : '-'}
                  </div>
                </div>
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('position')}</div>
                  <div className="bx--structured-list-td">
                    {offerDetailsRes.position ? offerDetailsRes.position : '-'}
                  </div>
                </div>
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('joiningDate')}</div>
                  <div className="bx--structured-list-td">
                    {offerDetailsRes.joiningDate ? moment(offerDetailsRes.joiningDate).format('MMM DD, YYYY') : '-'}
                  </div>
                </div>
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('managerName')}</div>
                  <div className="bx--structured-list-td">
                    {offerDetailsRes.managerName ? offerDetailsRes.managerName : '-'}
                  </div>
                </div>
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('salary')}</div>
                  <div className="bx--structured-list-td">
                    {currenciesName
                      && currenciesName.currencyShort
                      ? currenciesName.currencyShort
                      : null }
                    &nbsp;
                    {offerDetailsRes.salary
                      ? offerDetailsRes.salary
                      : null}
                    &nbsp;
                    (
                    {salaryIntervalRes
                      && salaryIntervalRes.intervalType
                      ? salaryIntervalRes.intervalType
                      : null}
                    )
                  </div>
                </div>
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('equity')}</div>
                  <div className="bx--structured-list-td">
                    {
                      offerDetailsRes.equity ? (
                        <span>
                          {currenciesName
                            && currenciesName.currencyShort
                            ? currenciesName.currencyShort
                            : null}
                          &nbsp;
                          {offerDetailsRes.equity ? offerDetailsRes.equity : null}
                          &nbsp;
                          {t('vesting')}
                          &nbsp;
                          {euityIntervalRes
                            && euityIntervalRes.intervalType
                            ? euityIntervalRes.intervalType
                            : null}
                          &nbsp;
                          {t('for')}
                          &nbsp;
                          {offerDetailsRes.equityVestingPeriod
                            ? offerDetailsRes.equityVestingPeriod : 0}
                          &nbsp;
                          {offerDetailsRes.equityVestingPeriod
                            && offerDetailsRes.equityVestingPeriod === 1
                            ? `${t('year')}` : `${t('years')}`}
                        </span>
                      ) : (<span>-</span>)}
                  </div>
                </div>
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('bonus')}</div>
                  <div className="bx--structured-list-td">
                    {offerDetailsRes.bonus ? offerDetailsRes.bonus : '-'}
                  </div>
                </div>
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('hoursInWeek')}</div>
                  <div className="bx--structured-list-td">
                    {offerDetailsRes.weeklyHours ? offerDetailsRes.weeklyHours : '-'}
                  </div>
                </div>
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('sickDaysAllowance')}</div>
                  <div className="bx--structured-list-td">
                    {offerDetailsRes.sickDaysAllowance ? offerDetailsRes.sickDaysAllowance : '-'}
                  </div>
                </div>
                {
                  offerDetailsRes.status
                  && offerDetailsRes.status.toString()
                  && offerDetailsRes.status.toLowerCase() === 'rejected'
                  && offerDetailsRes.offerrejectreason
                  && (
                    <div className="bx--structured-list-row">
                      <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('offer')} {t('reject')} {t('reason')}</div>
                      <div className="bx--structured-list-td">
                        <b>{offerDetailsRes.offerrejectreason.reason ? offerDetailsRes.offerrejectreason.reason : '-'}</b>
                      </div>
                    </div>
                  )
                }
                {
                  offerDetailsRes.status
                  && offerDetailsRes.status.toString()
                  && offerDetailsRes.status.toLowerCase() === 'draft'
                  && roleId !== 3
                  && (
                    <div className="bx--structured-list-row">
                      <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('documents')}</div>
                        <div className="bx--structured-list-td">
                          {
                              offerDetailsRes.offerdocuments
                                && Array.isArray(offerDetailsRes.offerdocuments)
                                && offerDetailsRes.offerdocuments.length > 0
                                && offerDetailsRes.offerdocuments.map((offs) => {
                                  const {
                                    documentName,
                                    hashedOfferDocumentId,
                                    offerDocumentId,
                                    docType,
                                  } = offs || {};
                                  let downloadUrl = '';
                                  if (docType && docType.toString() && docType.toLowerCase() === 'pdf') {
                                    downloadUrl = `/view/${hashedOfferDocumentId}/docs?_=${new Date().getTime()}&ref=${btoa(offerDocumentId)}`;
                                  } else {
                                    downloadUrl = `/roboroy/api/v1/offerdocuments/${hashedOfferDocumentId}/doc?_=${new Date().getTime()}&ref=${btoa(offerDocumentId)}`;
                                  }
                                  return (
                                    <div className="d-flex mb-1">
                                      <div style={{ minWidth: '145px' }} className="font-weight-bold text-dark">{documentName}</div>
                                      <div className="mb-1 ml-2">
                                        <a target="_blank" href={downloadUrl}>{t('view')}</a>
                                      </div>
                                      <div onClick={() => activeDeleteOfferDocModal(offerDocumentId)} className="xpa-link ml-2">{t('delete')}</div>
                                    </div>
                                  );
                                })
                            }
                          {
                          inputValues.map((vals, index) => {
                            return (
                              <div className="d-flex">
                                <div>
                                  <TextInput
                                    className="mb-2"
                                    id={`textInput_${index}`}
                                    value={state[`textInput_${index}`]}
                                    placeholder={`${t('name')} ${t('of')} ${t('document')}`}
                                    hideLabel={false}
                                    name={`textInput_${index}`}
                                    onChange={(e) => { handleChangeInputValues(e, index, offerId); }}
                                    invalid={isValidTextInput}
                                    invalidText={isValidTextInputContent}
                                  />
                                </div>
                                <div className="ml-2">
                                  <div className="bx--file h-auto mx-auto text-center newjob-upload-jd" data-file>
                                    <label
                                      htmlFor="jd_upload"
                                      className="bx--file-btn bx--btn bx--btn--tertiary uploadFile"
                                      role="button"
                                      tabIndex="0"
                                    >
                                      {t('uploadFile')}
                                    </label>
                                    <input
                                      type="file"
                                      className="bx--file-input"
                                      id="jd_upload"
                                      name="jobDescFile"
                                      accept=".doc,.docx,.pdf"
                                      onChange={(e) => handleUploadFile(e, index)}
                                      data-file-uploader
                                      data-target="[data-file-container]"
                                    />
                                    <div data-file-container className="bx--file-container"></div>
                                  </div>
                                </div>
                                <div className="ml-2">
                                  <Button onClick={() => handleDeleteInputValues(index)} kind="danger">{t('delete')}</Button>
                                </div>
                                <div className="ml-2">
                                  <Button kind="primary" onClick={() => {handleSubmitOfferDocuments(index)}}>{t('save')}</Button>
                                </div>
                              </div>
                            );
                          })
                        }
                          {
                            <Button
                              kind="tertiary"
                              onClick={() => handleAddInputValues()}
                              small
                              disabled={inputValues.length === 1}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-plus-circle">
                                <circle cx="12" cy="12" r="10" />
                                <line x1="12" y1="8" x2="12" y2="16" />
                                <line x1="8" y1="12" x2="16" y2="12" />
                              </svg>
                              {t('add')}
                            </Button>
                          }
                        </div>
                    </div>
                  )
                }
                {
                  offerDetailsRes.status
                    && offerDetailsRes.status.toString()
                    && offerDetailsRes.status.toLowerCase() !== 'draft'
                    && (
                      <div className="bx--structured-list-row">
                        <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">{t('documents')}</div>
                        <div className="bx--structured-list-td">
                          {
                              offerDetailsRes.offerdocuments
                                && Array.isArray(offerDetailsRes.offerdocuments)
                                && offerDetailsRes.offerdocuments.length > 0
                                && offerDetailsRes.offerdocuments.map((offs) => {
                                  const {
                                    documentName,
                                    hashedOfferDocumentId,
                                    offerDocumentId,
                                    docType,
                                  } = offs || {};
                                  let downloadUrl = '';
                                  if (docType && docType.toString() && docType.toLowerCase() === 'pdf') {
                                    downloadUrl = `/view/${hashedOfferDocumentId}/docs?_=${new Date().getTime()}&ref=${btoa(offerDocumentId)}`;
                                  } else {
                                    downloadUrl = `/roboroy/api/v1/offerdocuments/${hashedOfferDocumentId}/doc?_=${new Date().getTime()}&ref=${btoa(offerDocumentId)}`;
                                  }
                                  return (
                                    <div className="d-flex mb-1">
                                      <div style={{ minWidth: '145px' }} className="font-weight-bold text-dark">{documentName}</div>
                                      <div className="mb-1 ml-2">
                                        <a target="_blank" href={`/view/${downloadUrl}/docs?_=${new Date().getTime()}&ref=${btoa(offerDocumentId)}`}>{t('view')}</a>
                                      </div>
                                    </div>
                                  );
                                })
                            }
                        </div>
                      </div>
                    )
                }
              </div>
            </section>
          </div>
        )
      }
    </div>
  );
};

export default (withTranslation()(OfferTable));
