import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastNotification } from 'carbon-components-react';
import checkTaskStatus from 'actions/talentSearch/taskStatus';

import './Toast.css';

class Toast extends Component {
  state = {
    tasks: {},
    show: false,
    errorMessages: {
      441: 'Unable to complete this request at the moment. Pls. try again later. ERROR CODE: 441',
      420: 'Unable to complete this request at the moment. Pls. try again later. ERROR CODE: 420',
      461: 'The uploaded resume is not of required file format. Pls. upload .pdf/.doc/.docx files. ERROR CODE: 461',
      442: 'Missing documents',
      462: 'Incorrect parsing process',
      463: 'Uploaded documents do not meet the minimum information requirement',
      471: 'Incomplete information retrieval',
      472: 'Incomplete metadata retrieval',
      481: 'Internal data process failure',
    },
  };


  componentDidMount() {
    // console.log('--- yay!!', this.state.tasks);
  }

  componentWillReceiveProps(props) {
    let self = this;
    // console.log('--- yes!!', props);
    if (props && props.taskId && this.state && this.state.tasks && !this.state.tasks[props.taskId]) {
      let taskId = props.taskId;

      this.state.tasks[props.taskId] = {};
      this.setState({ show: true, message: 'Initiated resume processing', toastType: 'info' });

      this.updateLocalStorage(props.taskId, {
        task_state: 'IN PROGRESS',
        task_result: null,
      });

      let timer = setInterval(() => {
        self.getTaskStatus(taskId);
      }, 5000);

      let tasks = this.state.tasks;
      tasks[props.taskId].timer = timer;
      this.setState({ tasks });
      this.getTaskStatus(props.taskId);
      // console.log("--- creating a new task");
    }

    // console.log(" ~~~~~~~~ >> ", props);
    if (props && props.taskStatus && props.taskStatus.error && props.taskStatus.error.error_code && props.taskStatus.error.error_message) {
      if (this.state.tasks[props.taskId] && this.state.tasks[props.taskId].timer) {
        self.clearInterval(this.state.tasks[props.taskId].timer);
        let message = 'There was an error in processing. Please try uploading again.';
        let toastType = 'warning';
        self.setState({ show: true, message, toastType });

        this.updateLocalStorage(props.taskId, {
          task_state: 'FAILED',
          task_result: {
            message,
            toastType: 'danger',
          },
        });
        self.props.successAction();
      }
    } else if (props && props.taskStatus && props.taskStatus.task_state && (props.taskStatus.task_state === 'SUCCESS')) {
      if (this.state.tasks[props.taskId] && this.state.tasks[props.taskId].timer) {
        self.clearInterval(this.state.tasks[props.taskId].timer);
        let message = 'Unknown error. Pls. try again later.';
        let toastType = 'warning';

        if ((props.taskStatus.task_result == null) || (props.taskStatus.task_result && !props.taskStatus.task_result.error_code)) {
          message = 'Resume processing is complete.';
          toastType = 'success';
          self.setState({ show: true, message, toastType });
        } else if ((props.taskStatus.task_result !== null) && (props.taskStatus.task_result.error_code !== null)) {
          if (this.state.errorMessages[props.taskStatus.task_result.error_code] !== null) {
            message = this.state.errorMessages[props.taskStatus.task_result.error_code];
          } else {
            message = 'There was an error in processing.';
          }
          toastType = 'warning';
          self.setState({ show: true, message, toastType });
        }

        this.updateLocalStorage(props.taskId, {
          task_state: (toastType == 'warning' ? 'FAILED' : 'SUCCESS'),
          task_result: {
            message,
            toastType: (toastType == 'warning' ? 'danger' : toastType),
          },
        });
      }
      // self.props.successAction("candidates");
    } else if (props && props.taskStatus && props.taskStatus.task_state && (props.taskStatus.task_state === "SUCCESSFUL CREATION") && !this.state.partialRefreshCompleted) {
      this.setState({ partialRefreshCompleted: true });
      self.props.partialRefresh(true);
    }

    if (this.state.show) {
      setTimeout(() => {
        self.setState({ show: false });
      }, 5000);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((nextProps && nextProps.taskStatus && nextProps.taskStatus.task_state && (nextProps.taskStatus.task_state === "SUCCESS"))) {
      if ((this.props && this.props.taskStatus && this.props.taskStatus.task_state && (this.props.taskStatus.task_state === "SUCCESS"))) {
        return false;
      }
    }
    return true;
  }

  componentWillUpdate(nextProps) {
    let self = this;
    if (nextProps && nextProps.taskStatus && nextProps.taskStatus.task_state && (nextProps.taskStatus.task_state === "SUCCESS")) {
      self.props.successAction(nextProps && nextProps.taskStatus && nextProps.taskStatus.returnvalue && nextProps.taskStatus.returnvalue.isEmailExists);
    }
  }

  componentWillUnmount() {
    for (const [key, value] of Object.entries(this.state.tasks)) {
      this.clearInterval(this.state.tasks[key].timer);
    }
  }

  getTaskStatus(taskId) {
    let self = this;
    this.props.checkTaskStatus(taskId);
  }

  updateLocalStorage(taskId, task) {
    if (typeof (Storage) !== "undefined") {
      if (!localStorage.notifications) {
        localStorage.notifications = JSON.stringify({});
      }
      try {
        let notificationsObj = JSON.parse(localStorage.notifications);
        notificationsObj[taskId] = (task);
        notificationsObj[taskId].timestamp = (new Date()).getTime();
        localStorage.notifications = JSON.stringify(notificationsObj);
      } catch (e) {

      }
    }
  }

  clearInterval(timer) {
    clearInterval(timer);
  }

  render() {
    const { taskId } = this.props;
    const {
      show,
      toastType,
      message,
    } = this.state;
    return (
      show && (
      <ToastNotification
        lowContrast
        onCloseButtonClick={() => { this.setState({ show: false }); }}
        className=""
        title={`Task status: ${toastType == "success" ? 'Completed' : ''}${toastType == "info" ? 'Initiated' : ''}${toastType == "warning" ? 'Failed' : ''}`}
        subtitle={message || ''}
        caption=""
        captionNode={<Link to="" />}
        iconDescription="describes the close button"
        kind={toastType}
        style={{
          width: '300px',
          marginBottom: '.5rem',
          position: 'absolute',
          top: '70px',
          right: '0px',
          visibility: 'visible',
        }}
      />)
    );
  }
}

const mapStateToProps = state => ({
  taskStatus: state.taskStatus,
});

const mapActionsToProps = {
  checkTaskStatus,
};

export default connect(mapStateToProps, mapActionsToProps)(Toast);
