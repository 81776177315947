import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import JobInfoCompleteDetails from "../../../../components/roboroy/jobInfo/jobInfoCompleteDetails";
import {
  ComposedModal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalWrapper,
  Button,
  Modal,
  RadioButton,
  TextArea,
  InlineNotification,
  RadioButtonGroup,
} from 'carbon-components-react';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import {
  editOfferDetails,
  getOfferDetails,
  getCurrencyValues,
  getRejectReasons,
} from 'actions/offerInformation';
import OfferTable from 'components/roboroy/common/offerTable';
import {
  getJobInfo
} from 'actions/talentSearch/jobList';

const Chip = ({ title }) =>
  title ? <div className="bx--tag bx--tag--blue  mr-2">{title}</div> : null;

class CandidateOffers extends Component {
  candidatepaths = [
    { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
    { id: 'offer', name: 'Offer details', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      currOfferId: 0,
      isOpenAcceptOfferModal: false,
      isOpenRejectOfferModal: false,
      isOpenOfferAcceptanceModal: false,
      selectedRadioButton: 0,
      reason: '',
      isValidReason: false,
      isValidReasonText: '',
      notifError: false,
      acceptButton: false,
      rejectButton: false,
      currStatus: '',
      showJobvacanicesErr: false,
      isOpenJobClosedModal: false
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {
          id
        } = {}
      } = {},
      application: {
        jobId
      } = {}
    } = this.props;
    const appId = id;

    this.props.getOfferDetails(appId);
    this.props.getCurrencyValues();
    this.props.getRejectReasons();
    this.handleGetJobInfo(jobId);
  }


  handleGetJobInfo = (jobId) => {
    return this.props.getJobInfo({ jobId })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOfferDetails = tabType => {
    let selectedTab = 0;
    switch (tabType) {
      case 'offers':
        selectedTab = 0;
        break;
      default:
        selectedTab = 0;
        break;
    }
    this.setState({
      selectedTab,
    });
  };

  handleChangeRadioButton = value => {
    this.setState({
      selectedRadioButton: value,
      notifError: false,
    });
  };

  handleChangeOfferAcceptanceRadioButton = button => {
    if (button === 'accept') {
      this.setState({
        isOpenAcceptOfferModal: true,
        isOpenRejectOfferModal: false,
        acceptButton: true,
        rejectButton: false,
      });
    } else if (button === 'reject') {
      this.setState({
        isOpenRejectOfferModal: true,
        isOpenAcceptOfferModal: false,
        acceptButton: false,
        rejectButton: true,
      });
    }
  };

  activeAcceptOfferModal = offerId => {
    this.setState({
      isOpenAcceptOfferModal: true,
      currOfferId: offerId,
    });
  };

  activeRejectOfferModal = offerId => {
    this.setState({
      isOpenRejectOfferModal: true,
      currOfferId: offerId,
    });
  };

  activeOfferAcceptanceModal = (offerId, status) => {
    this.setState({
      isOpenOfferAcceptanceModal: true,
      currOfferId: offerId,
      currStatus: status,
    });
  };

  dismissOfferAcceptanceModal = () => {
    this.setState({
      isOpenOfferAcceptanceModal: false,
      acceptButton: false,
      rejectButton: false,
    });
  };

  dismissRejectOfferModal = () => {
    this.setState({
      isOpenRejectOfferModal: false,
      acceptButton: false,
      rejectButton: false,
    });
  };

  dismissAcceptOfferModal = () => {
    this.setState({
      isOpenAcceptOfferModal: false,
      acceptButton: false,
      rejectButton: false,
    });
  };

  handleAcceptOfferModal = () => {
    const { currOfferId } = this.state;
    const { application } = this.props;
    const { appId } = application || {};
    const data = {
      offerId: currOfferId,
      appId,
      status: 'accepted',
    };

    this.props.getOfferDetails(appId).then((res) => {
      if (res && res.offers && res.offers[0].status === 'rejected') {
        this.setState({ isOpenJobClosedModal: true, isOpenAcceptOfferModal: false });
      } else {
        this.props.editOfferDetails(data).then((res) => {
          if (res && !res.error) {
            this.dismissAcceptOfferModal();
            this.dismissOfferAcceptanceModal();
            this.props.getOfferDetails(appId);
          } else if (res && res.error && res.error.code === 'x0pa-94') {
            this.dismissAcceptOfferModal();
            this.dismissOfferAcceptanceModal();
            this.props.getOfferDetails(appId);
            this.setState({
              showJobvacanicesErr: true,
            })
          }
        });
      }
    });

  };

  handleRejectOfferModal = () => {
    const { currOfferId, selectedRadioButton, reason } = this.state;
    const { application } = this.props;
    const { appId } = application || {};
    const data = {
      offerId: currOfferId,
      reasonId: selectedRadioButton,
      appId,
      status: 'rejected',
      reason,
    };
    if (!selectedRadioButton || selectedRadioButton === 0) {
      this.setState({
        notifError: true,
      });
    } else if (selectedRadioButton === 6 && !reason) {
      this.setState({
        isValidReason: true,
        isValidReasonText: 'Please enter valid reason',
      });
    } else {
      this.props.editOfferDetails(data).then(() => {
        this.dismissRejectOfferModal();
        this.dismissOfferAcceptanceModal();
        this.props.getOfferDetails(appId);
      });
    }
  };

  render() {
    const {
      application = {},
      offerDetails = [],
      currencies = [],
      timeInterval,
      user: { roleId } = {},
      rejectReason,
      t,
    } = this.props;
    const {
      jobId,
      jobfunction: { functionName } = {},
      jobseniority: { seniorityName } = {},
      jobname: { nameName } = {},
      hourtype: { typeName } = {},
      term: { termName } = {},
    } = (application && application.job) || {};
    const {
      selectedTab,
      isOpenAcceptOfferModal,
      isOpenRejectOfferModal,
      selectedRadioButton,
      isValidReason,
      isValidReasonText,
      isOpenOfferAcceptanceModal,
      notifError,
      acceptButton,
      rejectButton,
      currStatus,
      showJobvacanicesErr,
      isOpenJobClosedModal
    } = this.state;
    const columnWithFilter = [
      {
        title: `${t('offer')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('position')}`,
        className: 'text-center',
        name: 'position',
        propName: 'position',
      },
      // {
      //   title: `${t('joiningDate')}`,
      //   className: 'text-center',
      //   name: 'joiningDate',
      //   propName: 'joiningDate',
      // },
      // {
      //   title: `${t('salary')}`,
      //   className: 'text-center',
      //   name: 'salary',
      //   propName: 'salary',
      // },
      // {
      //   title: `${t('bonus')}`,
      //   className: 'text-center',
      //   name: 'jobId',
      //   propName: 'jobId',
      // },
      {
        title: `${t('status')}`,
        className: 'text-center',
        name: 'status',
        propName: 'status',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    const sortedOfferArr = offerDetails.map(o => o.offerId);
    return (
      <Fragment>
        <BackRow paths={this.candidatepaths} />
        <InlineNotification
          lowContrast
          className="mt-0"
          title={t('offerAcceptMsg')}
          subtitle=""
          iconDescription=""
          kind="info"
        />
        {
          showJobvacanicesErr === true && (
            <InlineNotification
              lowContrast
              className="mt-0"
              title="This internship position is no longer available as it has been accepted by another student. Please apply for other positions / accept other offer(s) instead. Thank you."
              subtitle=""
              iconDescription=""
              kind="error"
            />
          )
        }
        <div>
          <div className="row pb-2">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="">
                <div className="text-dark">
                  <small title="Job">{`Requisition ID: ${jobId}`}</small>
                </div>
                <div className="card-title h5 mb-1 d-inline-block text-center font-weight-bold text-uppercase">
                  {nameName}
                </div>
                <div className="card-subtitle mt-1 text-dark text-uppercase bx--modal-header__label pb-1">
                  {functionName}
                </div>
                <div className="card-subtitle pt-1">
                  <Chip className="ml-0" title={seniorityName} />
                  <Chip title={typeName} />
                  <Chip title={termName} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <GenericTabs
            selected={selectedTab}
            tabContent={[
              {
                className: '',
                label: `${t('offer')} ${t('details')}`,
                value: '',
                onClick: () => this.handleOfferDetails('offers'),
              },
            ]}
          />
        </div>
        <div>
          {selectedTab == 0 && (
            <div className="mt-2">
              {offerDetails &&
                Array.isArray(offerDetails) &&
                offerDetails.length > 0 ? (
                <div>
                  <OfferTable
                    offerDetails={offerDetails}
                    currencies={currencies}
                    timeInterval={timeInterval}
                    columnWithFilter={columnWithFilter}
                    roleId={roleId}
                    activeAcceptOfferModal={this.activeAcceptOfferModal}
                    activeRejectOfferModal={this.activeRejectOfferModal}
                    activeOfferAcceptanceModal={this.activeOfferAcceptanceModal}
                    latestOfferId={sortedOfferArr && sortedOfferArr[0]}
                    user={this.props.user}
                  />
                </div>
              ) : (
                <div className="mt-2">
                  <div className="mt-2">
                    <div className="text-center">
                      <div className="empty-icon">
                        <i className="fa fa-flag fa-2x" />
                      </div>
                      <p className="empty-title h5">{t('offerMsg12')}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <hr />
        </div>

        {this.props.jobs
          && Array.isArray(this.props.jobs)
          && this.props.jobs.length > 0
          && this.props.user
          && (<div className="col-sm-12 col-lg-9 col-xl-10 col-md mt-4 pr-0">
            <div>
              <h3>Job Details</h3>
            </div>
            <JobInfoCompleteDetails job={this.props.jobs[0]} user={this.props.user} t={t} isJobOfferPage />
          </div>)
        }

        <JDProcessOverlay show={this.props.loading} message="processing..." />

        <ComposedModal
          open={isOpenJobClosedModal}
          onRequestClose={
            () => {
              this.setState({ isOpenJobClosedModal: false }, () => {
                window.location.reload();
              })
            }
          }
          onClose={() => {
            this.setState({ isOpenJobClosedModal: false }, () => {
              window.location.reload();
            })
          }}
        >
          <ModalHeader><b>Message</b></ModalHeader>
          <ModalBody>
            <div><h3>Sorry, this offer has already been closed.</h3></div>
          </ModalBody>
          <ModalFooter>
            <div style={{ width: "100%" }}>
              <Button style={{ width: "50%" }} className="float-right" kind="primary" onClick={() => {
                this.setState({ isOpenJobClosedModal: false }, () => {
                  window.location.reload();
                });
              }}>
                OK
            </Button>
            </div>
          </ModalFooter>
        </ComposedModal>

        <Modal
          open={isOpenOfferAcceptanceModal}
          onRequestClose={this.dismissOfferAcceptanceModal}
          modalHeading="Change Offer Acceptance Decision"
          passiveModal>
          <div>
            <RadioButton
              onChange={() =>
                this.handleChangeOfferAcceptanceRadioButton('accept')
              }
              id="accept"
              name="decision"
              disabled={false}
              labelText={t('accept')}
              value="accept"
              checked={acceptButton}
              disabled={currStatus === 'accepted'}
            />
            <RadioButton
              onChange={() =>
                this.handleChangeOfferAcceptanceRadioButton('reject')
              }
              id="reject"
              name="decision"
              disabled={false}
              labelText={t('reject')}
              value="reject"
              checked={rejectButton}
              disabled={currStatus === 'rejected'}
            />
          </div>
        </Modal>
        <div style={{ zIndex: '1' }}>
          <Modal
            open={isOpenAcceptOfferModal}
            modalHeading={t('offerModalHeading')}
            primaryButtonText={t('accept')}
            secondaryButtonText={t('cancel')}
            onRequestClose={this.dismissAcceptOfferModal}
            onRequestSubmit={this.handleAcceptOfferModal}>
            <div className="text-dark mb-2">
              Are you sure you want to confirm your acceptance of this offer?
              </div>
            <div className="mb-3">
              {t('offeracceptMsg')}
            </div>
            <div className="">
              If yes, your acceptance will be sent to the company and the offer round would be completed.
            </div>
          </Modal>
        </div>
        <div style={{ zIndex: '1' }}>
          <Modal
            open={isOpenRejectOfferModal}
            modalHeading={t('offerModalHeading2')}
            primaryButtonText={t('reject')}
            secondaryButtonText={t('cancel')}
            onRequestClose={this.dismissRejectOfferModal}
            onRequestSubmit={this.handleRejectOfferModal}>
            <div className="text-dark mb-2">
              {t('offerMsg13')}
            </div>
            <div className="mb-2">
              {t('offerMsg14')}
            </div>
            {notifError && (
              <InlineNotification
                lowContrast
                className="mt-0"
                title={t('offerMsg15')}
                subtitle=""
                iconDescription=""
                kind="error"
              />
            )}
            {rejectReason &&
              Array.isArray(rejectReason) &&
              rejectReason.length > 0 &&
              rejectReason.map(res => {
                return (
                  <div className="d-flex mb-2">
                    <RadioButton
                      key={`${res && res.reasonId}`}
                      onChange={() =>
                        this.handleChangeRadioButton(res && res.reasonId)
                      }
                      id={`${res && res.reasonId}`}
                      name="reason"
                      disabled={false}
                      labelText={`${res && res.reason}`}
                      value={selectedRadioButton}
                    />
                  </div>
                );
              })}
            {
              <TextArea
                name="reason"
                className="mb-2"
                labelText={t('additionalInfo')}
                onChange={e => {
                  this.handleChange(e);
                }}
                invalid={isValidReason}
                invalidText={isValidReasonText}
              />
            }
          </Modal>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.jobs.jobs,
  user: state.x0paDuser.user,
  loading: state.OfferInformation.loading,
  error: state.OfferInformation.error,
  offerDetails: state.OfferInformation.offerDetails,
  timeInterval: state.OfferInformation.timeInterval,
  currencies: state.OfferInformation.currencies,
  application: state.OfferInformation.application,
  rejectReason: state.OfferInformation.rejectReason,
});

const mapDispatchToProps = {
  getJobInfo,
  editOfferDetails,
  getOfferDetails,
  getCurrencyValues,
  getRejectReasons,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CandidateOffers));
