import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AnalyticsLineGraph from 'components/roboroy/Analytics/analyticsLineGraph';
import AnalyticsBarGraph from 'components/roboroy/Analytics/analyticsBarGraph';
import AnalyticsStackedBarGraph from 'components/roboroy/Analytics/analyticsStackedBarGraph';
import AnalyticsFunnel from 'components/roboroy/Analytics/analyticsFunnel';
import Duration from 'components/roboroy/Analytics/durationFilter';
import { Tile, Accordion, AccordionItem, InlineNotification } from 'carbon-components-react';
import HumanTime from 'utils/humanTime';
import _ from 'lodash';
import { getAnalytics } from 'actions/analytics';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';

// const

import './analytics.css'

class AnalyticsList extends Component {
  constructor(props) {
    super(props);
    this.state={
      startDate:null,
      endDate:null,
      interval:"",
      currRecrId: 0,
      currComId: 0,
    };
  }

  static getDerivedStateFromProps(prevProps, nextState) {
    if (prevProps.recruiterId !== nextState.recruiterId) {
      return { currRecrId: prevProps.recruiterId, currComId: prevProps.companyID };
    } else return null;
  }

  prepareApplicationSourcingData = (trendData=[]) => {
    let combinedApplnShortlistedSourceTrend = [];
    trendData && Array.isArray(trendData) && trendData.map((eachTrend) => {
      combinedApplnShortlistedSourceTrend = eachTrend && eachTrend.sourceTrend && _.concat(combinedApplnShortlistedSourceTrend, eachTrend.sourceTrend);
    });

    let aggregationBySourceType = {};
    combinedApplnShortlistedSourceTrend.map(eachSourceTrend => {
      if (!aggregationBySourceType[eachSourceTrend.sourceType]) {
        aggregationBySourceType[eachSourceTrend.sourceType] = {
          source      : eachSourceTrend.source,
          sourceType  : eachSourceTrend.sourceType,
          total       : 0
        };
      }
      aggregationBySourceType[eachSourceTrend.sourceType].total += parseInt(eachSourceTrend.total || 0,10);
    });

    combinedApplnShortlistedSourceTrend = [];
    Object.keys(aggregationBySourceType).map((key) => {
      combinedApplnShortlistedSourceTrend.push(aggregationBySourceType[key]);
    });

    return combinedApplnShortlistedSourceTrend;
  }

  durationChange = (data={})=>{
    let {startDate=0, endDate=0, interval=''} = data;
    const {
      user: {
        currentCompany: {
          companyId: currCompanyId,
        } = {},
      } = {},
      match: {
        path,
      } = {},
      value: finalValue,
      jobID = null,
      applicationID = null,
      companyID: propCompanyId = null,
    } = this.props;
    const paramValue = path && path.toString() && path.split('/').reverse()[0];
    let value = '';
    let companyID = null;
    if (paramValue && paramValue.toLowerCase() === 'analytics') {
      companyID = currCompanyId;
      value = 'analytics';
    } else if (finalValue && finalValue.toString() && finalValue.toLowerCase() === 'company') {
      companyID = propCompanyId;
      value = 'company';
    }
    this.setState({
      startDate,
      endDate,
      interval,
    }, () => {
      this.props.getAnalytics({
        startDate,
        endDate,
        interval,
        jobID,
        applicationID,
        value,
        companyID,
        recruiterId: this.state.currRecrId,
        currId: this.state.currComId,
      });
    });
    return;
  }

  mergeTrends = (first, second) => {
    var mergedArr = _.map(first, function(element) {
      var treasure = _.find(second, { startTime: element && element.startTime });
      return _.extend(element, treasure);
    });

    return mergedArr;
  }

  render() {
    const {jobs={},applications={},workflowStages={}, hiringKPI={}, hiringFunnel={}, sourcing={}, recruiter} = this.props.analytics||{};
    let {leaderboard:{recruiters:{fastest=[],slowest=[]}={}}={}} = this.props.analytics||{};
    const { leaderboard = {} } = this.props.analytics;
    const {jobID=null, applicationID=null, companyID=null, user: { currentCompany: { companyName = '' } = {} } = {}} = this.props;
    let combinedJobsTrends = [];
    let combinedApplsTrend = [];
    if ((jobs && jobs.created && jobs.created.trend) || (jobs && jobs.closed && jobs.closed.trend)) {
      let openJobsTrend = [];
      let closedJobsTrend = [];
      let reopenedJobsTrend = [];

      if (jobs && jobs.created && jobs.created.trend) {
        openJobsTrend = JSON.parse(JSON.stringify(jobs && jobs.created && jobs.created.trend));
      }
      if (jobs && jobs.closed && jobs.closed.trend) {
        closedJobsTrend = JSON.parse(JSON.stringify(jobs && jobs.closed && jobs.closed.trend));
      }
      if (jobs && jobs.reopened && jobs.reopened.trend) {
        reopenedJobsTrend = JSON.parse(JSON.stringify(jobs && jobs.reopened && jobs.reopened.trend));
      }

      combinedJobsTrends = this.mergeTrends(openJobsTrend, closedJobsTrend);
      combinedJobsTrends = this.mergeTrends(combinedJobsTrends, reopenedJobsTrend);
    }

    if ((applications && applications.screening && applications.screening.trend) ||
        (applications && applications.shortlisted && applications.shortlisted.trend) ||
        (applications && applications.accepted && applications.accepted.trend) ||
        (applications && applications.rejected && applications.rejected.trend)) {

        let screeningTrend = [];
        let shortlistTrend = [];
        let acceptedTrend = [];
        let rejectedTrend = [];

        if (applications && applications.screening && applications.screening.trend) {
          screeningTrend = JSON.parse(JSON.stringify(applications && applications.screening && applications.screening.trend));
        }
        if (applications && applications.shortlisted && applications.shortlisted.trend) {
          shortlistTrend = JSON.parse(JSON.stringify(applications && applications.shortlisted && applications.shortlisted.trend));
        }
        if (applications && applications.accepted && applications.accepted.trend) {
          acceptedTrend = JSON.parse(JSON.stringify(applications && applications.accepted && applications.accepted.trend));
        }
        if (applications && applications.rejected && applications.rejected.trend) {
          rejectedTrend = JSON.parse(JSON.stringify(applications && applications.rejected && applications.rejected.trend));
        }

        combinedApplsTrend = this.mergeTrends(screeningTrend, shortlistTrend);
        combinedApplsTrend = this.mergeTrends(combinedApplsTrend, acceptedTrend);
        combinedApplsTrend = this.mergeTrends(combinedApplsTrend, rejectedTrend);
    }

    const combinedApplnScreeningSourceTrend = this.prepareApplicationSourcingData(applications && applications.screening && applications.screening.trend && applications.screening.trend);
    const combinedApplnShortlistedSourceTrend = this.prepareApplicationSourcingData(applications && applications.shortlisted && applications.shortlisted.trend && applications.shortlisted.trend);
    const combinedApplnRejectedSourceTrend = this.prepareApplicationSourcingData(applications && applications.rejected && applications.rejected.trend && applications.rejected.trend);
    const combinedApplnAcceptedSourceTrend = this.prepareApplicationSourcingData(applications && applications.accepted && applications.accepted.trend && applications.accepted.trend);
    let label="Average time";
    let name="avg";
    let aggregations = [
      { name: "min", type: "date", color: "#003f5c", label: "Min." },
      { name: "avg", type: "date", color: "#665191", label: "Avg." },
      { name: "max", type: "date", color: "#a05195", label: "Max." },
    ];
    if (applicationID !== null) {
      label="Total time";
      name="avg";
      aggregations = [{ name: name, type: "date", color: "#a05195", label: label }];
    }
    const { currRecrId, currComId } = this.state;
    return (
      <Fragment>
        {
          currRecrId !== null && currRecrId !== 0 && currComId !== null && recruiter && (
            <div>
              <Tile className="mt-2">
                <div className="bx--row p-1">
                  <div className="bx--col-lg-1">
                    <Avatar
                      user={{
                        firstname: recruiter.firstname,
                        lastname: recruiter.lastname,
                        picture: recruiter.picture ? recruiter.picture : null,
                      }}
                      classNameInfo="rounded-circle"
                      size="60"
                    />
                  </div>
                  <div>
                    <div className="text-dark font-weight-bold bx--type-gamma text-capitalize">
                      {recruiter.firstname} {recruiter.lastname}
                    </div>
                    <div className="">
                      <span className="text-capitalize">{recruiter.company && recruiter.company.companyName}</span>
                      <span>{recruiter.company && recruiter.company.website ? `(${recruiter.company.website})` : null}</span>
                    </div>
                    <div className="">
                      <svg width="16" height="9" viewBox="0 0 16 11">
                      <path d="M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
                      </svg>
                      <span className="text-dark bx--modal-header__label ml-2 mt-0">
                        <a href={`mailto:${recruiter.email}`}>{recruiter.email}</a>
                      </span>
                    </div>
                  </div>
                </div>
              </Tile>
            </div>
          )
        }
        {jobID===null && applicationID===null && currRecrId === null && (<div className="bx--type-gamma font-weight-bold text-capitalize">
          Your recruitment hiring Analytics for {companyName}
        </div>)}
        <InlineNotification lowContrast
            title={`This section provides analytics and insights into your hiring process by providing metrics such as Time to hire, Diversity of hire, Channel sourcing efficiency and Time to completion for each workflow stage for successful hires.`}
            subtitle=""
            iconDescription="describes the close button"
            kind="info"
            hideCloseButton
        />
      <Tile className="bx--col-12 rounded shadow-lg xp-analytics">
        {<Duration durationChange={this.durationChange} hide={!applicationID}/>}
        <Accordion className="full-width">
          {
              jobID===null && applicationID===null && Object.keys(jobs).length > 0 && <div><AnalyticsLineGraph
              className="p-0 pt-2 pt-lg-3 p-lg-3 ml-4"
              data={combinedJobsTrends || []}
              subheading={
                <div>
                  <div class="">
                    <div class="bx--row">
                      <div class="bx--col-xs-6 ">
                        <span class="d-inline-block">
                          {(jobs && jobs.created && jobs.created.total) || 0}
                        </span>
                        <span class="d-inline-block bx--type-legal text-dark pl-1 pr-1">(Created)</span>
                      </div>
                      <div class="bx--col-xs-6 ">
                        <span class="d-inline-block pl-2">
                          {(jobs && jobs.closed && jobs.closed.total) || 0}
                        </span>
                        <span class="d-inline-block bx--type-legal text-dark pl-1">(Closed)</span>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="bx--row">
                      <div class="bx--col-xs-6 ">
                        <span class="d-inline-block">
                          {(jobs && jobs.reopened && jobs.reopened.total) || 0}
                        </span>
                        <span class="d-inline-block bx--type-legal text-dark pl-1 pr-1">(Reopened)</span>
                      </div>
                    </div>
                  </div>
                </div>
              }
              heading={"Job postings"}
              tag={[{ name: "created", type: "number", color: "#ffa600", label: "Jobs created" },
                    { name: "closed", type: "number", color: "#245c39", label: "Jobs closed" },
                    { name: "reopened", type: "number", color: "#ff7c43", label: "Jobs re-opened" },
                  ]}
            />
            {jobID && <hr className="1"/>}
            </div>
          }

          {
            applicationID===null && Object.keys(applications).length > 0 && <AccordionItem
            className="full-width"
            open={false}
            title={
              <AnalyticsLineGraph
                data={combinedApplsTrend || []}
                subheading={
                  <div>
                    <div class="">
                      <div class="bx--row">
                        <div class="bx--col-xs-6 ">
                          <span class="d-inline-block">
                            {(applications && applications.screening && applications.screening.total) || 0}
                          </span>
                          <span class="d-inline-block bx--type-legal text-dark pl-1 pr-1">(Screening)</span>
                        </div>
                        <div class="bx--col-xs-6 ">
                          <span class="d-inline-block pl-2">
                            {(applications && applications.shortlisted && applications.shortlisted.total) || 0}
                          </span>
                          <span class="d-inline-block bx--type-legal text-dark pl-1">(Shortlisted)</span>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="bx--row">
                        <div class="bx--col-xs-6 ">
                          <span class="d-inline-block">
                            {(applications && applications.accepted && applications.accepted.total) || 0}
                          </span>
                          <span class="d-inline-block bx--type-legal text-dark pl-1 pr-1">(Accepted)</span>
                        </div>
                        <div class="bx--col-xs-6 ">
                          <span class="d-inline-block pl-2">
                            {(applications && applications.rejected && applications.rejected.total) || 0}
                          </span>
                          <span class="d-inline-block bx--type-legal text-dark pl-1">(Rejected)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                heading={"Applications"}
                tag={
                  [
                    { name: "screening", type: "number", color: "#2f4b7c", label: "Screening" },
                    { name: "shortlisted", type: "number", color: "#a05195", label: "Shortlisted" },
                    { name: "accepted", type: "number", color: "#488f31", label: "Accepted" },
                    { name: "rejected", type: "number", color: "#de425b", label: "Rejected" },
                  ]}
              />

            }
            onHeadingClick={() => { }}
          >
            <AnalyticsStackedBarGraph
              data={combinedApplnScreeningSourceTrend || []}
              heading={`Sourcing - Incoming applications`}
              tag={"sourceType"}
              aggregations={[
                { name: "total", type: "number", color: "#665191", label: "Total" },

              ]}
              pivotAggregation={{ name: "total", type: "number", color: "#665191", label: "Total" }}
              summary={applications && applications.screening}
            />
            <AnalyticsStackedBarGraph
              data={combinedApplnShortlistedSourceTrend || []}
              heading={`Sourcing - Shortlisted applications`}
              tag={"sourceType"}
              aggregations={[
                { name: "total", type: "number", color: "#665191", label: "Total" },

              ]}
              pivotAggregation={{ name: "total", type: "number", color: "#665191", label: "Total" }}
              summary={applications && applications.shortlisted}
            />
            <AnalyticsStackedBarGraph
              data={combinedApplnRejectedSourceTrend || []}
              heading={`Sourcing - Rejected applications`}
              tag={"sourceType"}
              aggregations={[
                { name: "total", type: "number", color: "#665191", label: "Total" },

              ]}
              pivotAggregation={{ name: "total", type: "number", color: "#665191", label: "Total" }}
              summary={applications && applications.rejected}
            />
            <AnalyticsStackedBarGraph
              data={combinedApplnAcceptedSourceTrend || []}
              heading={`Sourcing - Accepted applications`}
              tag={"sourceType"}
              aggregations={[
                { name: "total", type: "number", color: "#665191", label: "Total" },

              ]}
              pivotAggregation={{ name: "total", type: "number", color: "#665191", label: "Total" }}
              summary={applications && applications.accepted}
            />
          </AccordionItem>}
        { Object.keys(hiringKPI).length > 0 && <div>
          {applicationID===null && <hr/>         }

          <AnalyticsLineGraph
              className="p-0 pt-2 pt-lg-3 p-lg-3 ml-4"
              data={hiringKPI.timeToHire && hiringKPI.timeToHire.personal && hiringKPI.timeToHire.personal.trend ? hiringKPI.timeToHire.personal.trend : []}
              heading={"Time to hire"}
              showGraph={applicationID!==null?false:true}
              subheading={
                <div>
                  {HumanTime.getHumanTime((hiringKPI.timeToHire && hiringKPI.timeToHire.personal)||0)}
                  <span class="d-inline-block bx--type-legal text-dark pl-1">{applicationID!==null?"(Total)":"(Avg.)"}</span>
                </div>
              }
              tag={[{name:name, type:"date", color:"#5886a5", label: label}]}

            />
            </div> }
            { Object.keys(hiringKPI).length > 0 && !applicationID && <div>
              <hr/>
              <AnalyticsLineGraph
              className="p-0 pt-2 pt-lg-3 p-lg-3 ml-4"
              data={hiringKPI.diversityOfHire && hiringKPI.diversityOfHire.trend ? hiringKPI.diversityOfHire.trend : []}
              subheading={
                <div class="">
                  <div class="bx--row">
                    <div class="bx--col-xs-6 ">
                      <span class="d-inline-block">
                      {(hiringKPI.diversityOfHire && hiringKPI.diversityOfHire.Male)||0}
                      </span>
                      <span class="d-inline-block bx--type-legal text-dark pl-1 pr-1">(Male)</span>
                    </div>
                    <div class="bx--col-xs-6 ">
                      <span class="d-inline-block pl-2">
                      {(hiringKPI.diversityOfHire && hiringKPI.diversityOfHire.Female)||0}
                      </span>
                    <span class="d-inline-block bx--type-legal text-dark pl-1">(Female)</span>
                    </div>
                  </div>
                </div>
              }
              heading={"Diversity of hire"}
              tag={[{name:"Male", type:"number", color:"#5886a5", label: "No. of males hired"},{name:"Female", type:"number", color:"#ec838a", label: "No. of females hired"}]}
            /></div>}

            { Object.keys(sourcing).length > 0 && <div className="">
              <hr/>
              <AnalyticsLineGraph
                className="p-0 pt-2 pt-lg-3 p-lg-3 ml-4"
                data={sourcing && sourcing.volume && sourcing.volume.trend ? sourcing.volume.trend : []}
                value={(hiringKPI.diversityOfHire && hiringKPI.diversityOfHire.Male)||0}
                subheading={
                  <div class="">
                    <div class="bx--row">
                      <div class="bx--col-xs-6 ">
                        <span class="d-inline-block">
                        {(6)||0}
                        </span>
                        <span class="d-inline-block bx--type-legal text-dark pl-1 pr-1">(Active sources)</span>
                      </div>
                    </div>
                  </div>
                }
                heading={"Sourcing - by volume"}
                tag={[
                        {name:"linkedin", type:"number", color:"#003f5c", label: "LinkedIn"},
                        {name:"candidate", type:"number", color:"#665191", label: "Candidate Applied"},
                        {name:"ai sourcing", type:"number", color:"#a05195", label: "AI Sourcing"},
                        {name:"recruiter", type:"number", color:"#d45087", label: "Recruiter"},
                        {name:"globaltalentpool", type:"number", color:"#ff7c43", label: "Global Talent Pool"},
                        {name:"recruiting agency", type:"number", color:"#ffa600", label: "Recruiting Agency"},
                    ]}
              />
            </div>}
            { Object.keys(sourcing).length > 0 && <div className="">
              <hr/>
              <AnalyticsLineGraph
                className="p-0 pt-2 pt-lg-3 p-lg-3 ml-4"
                data={sourcing && sourcing.conversion && sourcing.conversion.trend ? sourcing.conversion.trend : []}
                value={(hiringKPI.diversityOfHire && hiringKPI.diversityOfHire.Male)||0}
                subheading={
                  <div class="">
                    <div class="bx--row">
                      <div class="bx--col-xs-6 ">
                        <span class="d-inline-block">
                        {(6)||0}
                        </span>
                        <span class="d-inline-block bx--type-legal text-dark pl-1 pr-1">(Active sources)</span>
                      </div>
                    </div>
                  </div>
                }
                heading={"Sourcing - by conversion efficiency"}
                tag={[
                        {name:"linkedin", type:"number", color:"#003f5c", label: "LinkedIn"},
                        {name:"candidate", type:"number", color:"#665191", label: "Candidate Applied"},
                        {name:"ai sourcing", type:"number", color:"#a05195", label: "AI Sourcing"},
                        {name:"recruiter", type:"percent", color:"#d45087", label: "Recruiter"},
                        {name:"globaltalentpool", type:"number", color:"#ff7c43", label: "Global Talent Pool"},
                        {name:"recruiting agency", type:"number", color:"#ffa600", label: "Recruiting Agency"},
                    ]}
              />
            </div> }
            { Object.keys(workflowStages).length > 0 && <div><hr/><AccordionItem
                  className="full-width"
                  open={false}
                  title={
                    <AnalyticsBarGraph
                        data={workflowStages.trend?workflowStages.trend:[]}
                        heading={"Workflow stages - Time for completion"}
                        tag={"stage"}
                        labelTag={applicationID!==null?"Total":"Avg."}
                        value={workflowStages.trend&&Array.isArray(workflowStages.trend)?workflowStages.trend.map(n=>n.avg&&n.avg || 0):[]}/>
                  }
                  onHeadingClick={() => { }}
                >
              {
                workflowStages.trend && Array.isArray(workflowStages.trend) && workflowStages.trend.map((eachWorkflowStage,key) => {
                  return (
                    <AnalyticsStackedBarGraph key={key}
                        data={eachWorkflowStage.subStages.trend?eachWorkflowStage.subStages.trend:[]}
                        heading={eachWorkflowStage.stage}
                        tag={"subStage"}
                        isApplicant={applicationID!==null?true:false}
                        aggregations={aggregations}
                        pivotAggregation={{name:name, type:"date", color:"#665191", label: label}}
                        summary={eachWorkflowStage}
                        />
                  )
                })
              }
            </AccordionItem></div>      }
          {companyID !== null &&
           ((fastest && Array.isArray(fastest) && fastest.length > 0) ||
           (slowest && Array.isArray(slowest) && slowest.length > 0)) &&
            < Fragment >
            <hr className="recruiters" />
            <div className="bx--col-lg-3 bx--col-xs-12 card-link ml-lg-4 mt-lg-3">
              <div className="text-dark card-subtitle mb-3 text-center text-lg-left h4 ml-lg-3">Leaderboard</div>
            </div>
            <div className="ml-lg-4">
              <div className="bx--row ml-lg-3">
                {Array.isArray(fastest) && fastest.length > 0 && <div className="bx--col-lg-6 bx--col-12">
                  <div className="card-link">
                    <div className="text-dark text-center text-lg-left card-subtitle mb-3 h6">Fastest</div>
                  </div>
                  <div className="text-center ml-lg-2">
                    {
                      fastest && Array.isArray(fastest) && fastest.filter((obj, index)=>(index<5)).map((ele,key)=>(
                        <div className="bx--row mb-2 justify-content-lg-start justify-content-center card-subtitle" key={key}>
                          <div className="text-dark mr-3">{ele.name}</div>
                          <div className="ml-3 text-info">{HumanTime.getHumanTime(ele.avgTime || 0)}</div>
                      </div>))
                    }
                  </div>
                </div>}
                {Array.isArray(slowest) && slowest.length > 0 && <div className="bx--col-lg-6 bx--col-12">
                  <div className="card-link">
                    <div className="text-dark text-center text-lg-left mb-3 h6">Slowest</div>
                  </div>
                  <div className="text-center ml-lg-2">
                    {
                      slowest && Array.isArray(slowest) && slowest.filter((obj, index)=>(index<5)).map((ele, key) => (
                        <div className="bx--row mb-2 justify-content-lg-start justify-content-center card-subtitle" key={key}>
                          <div className="text-dark mr-3">{ele.name}</div>
                          <div className="ml-3 text-info">{HumanTime.getHumanTime(ele.avgTime || 0)}</div>
                        </div>))
                    }
                  </div>
                </div>}
              </div>
            </div>
            </Fragment>}

      </Accordion>
      <JDProcessOverlay show={this.props.loading} message="processing..." />

      </Tile>
      {
        !applicationID && (
          <Tile className="mt-2">
            { Object.keys(hiringFunnel).length > 0
              ? (<div>
              <AnalyticsFunnel
              heading={"Hiring Funnel"}
              subheading={
                <div>
                </div>
              }
            data={hiringFunnel}
          /></div>):(
            <div>
              <div className="text-dark card-subtitle h6 font-weight-bold">Hiring Funnel</div>
              <div className="ml-4 bx--type-zeta mt-2">There is no sufficient data to show hiring funnel.</div>
            </div>
          )}
          </Tile>
        )
      }
      {
        leaderboard && leaderboard !== null
          && leaderboard !== undefined
          && leaderboard.recruiters
          && Array.isArray(leaderboard.recruiters)
          && leaderboard.recruiters.length > 0
          && leaderboard.recruiters.map((res) => (
            <div className="bx--tile p-0 mt-2">
            <div className="">
              <div className="bx--row p-2">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="text-sm-center text-md-center text-center text-lg-left">
                    <div className="card-title h5 mt-2 mb-0 d-inline-block text-center text-md-left font-weight-bold">
                      <div className="bx--row p-1">
                        <div className="xpa-analytics-avatar-width mr-2">
                          <Avatar
                            user={{
                              firstname: res && res.name,
                              lastname: null,
                              picture: (res && res.picture) ? res.picture : null,
                            }}
                            classNameInfo="rounded-circle mt-3"
                            size="60"
                          />
                        </div>
                        <div class="pt-1">
                          <div className="text-dark font-weight-bold bx--type-gamma">
                            <Link
                              to={{
                                pathname: `/app/campus/users/${res && res.userId}`,
                                search: `recruiterId=${res && res.userId}`,
                              }}
                            ><span className="text-capitalize">{res && res.name}</span>
                            </Link>
                          </div>
                          <div className="xp-font-sz">
                            <small>Created jobs: {res && res.jobs && res.jobs.created || 0}</small>
                          </div>
                          <div className="xp-font-sz">
                            <small>Re-Opened jobs: {res && res.jobs && res.jobs.reopened || 0}</small>
                          </div>
                          <div className="xp-font-sz">
                            <small>Closed jobs: {res && res.jobs && res.jobs.closed || 0}</small>
                          </div>
                          <div className="xp-font-sz-link"><Link to="/app/campus/jobs">View all</Link></div>
                        </div>
                      </div>              
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                  <div className="p-2">
                    <div className="row">
                      <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                        <h3 className="xp-c-text-align__center ">
                        {HumanTime.getHumanTime((res && res.avgTime)||0)}
                        </h3>
                        <span className="bx--modal-header__label mt-2">Average Time</span>
                      </div>
                      <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                        <h3 className="xp-c-text-align__center ">
                        {(res && res.candidatesHired) ? Math.round(res.candidatesHired) : 0}
                        </h3>
                        <span className="bx--modal-header__label mt-2">Candidates Hired</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ))
      }
    </Fragment>
    )
  }
}


const mapStateToProps = state => ({
  loading: state.Analytics.loading,
  analytics: state.Analytics.analytics,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getAnalytics,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsList);
