import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  TextInput, Select, SelectItem,
  RadioButton, RadioButtonGroup,
} from 'carbon-components-react';

const TalentPoolBuckets = ({
  handleChange, handleChangeRadioButton,
  useBucket, buckets, bucketName, isBucketNameValid, invalidText,
}) => (
  <Fragment>
    <div className="mt-4 d-inline-flex">
      <RadioButtonGroup onChange={handleChangeRadioButton} defaultSelected="select" name="radio-button-group">
        <RadioButton
          value="select"
          id="radio-1"
          disabled={false}
          labelText="Choose an Existing Bucket"
        />
        <RadioButton
          value="create-new"
          id="radio-2"
          disabled={false}
          labelText="Create a New Bucket"
        />
      </RadioButtonGroup>
    </div>
    <div>
      {
        useBucket === false
        && (
        <Select
          name="selectedBucketId"
          hideLabel
          invalid={isBucketNameValid}
          invalidText={invalidText}
          id="select-talentpool-bucket"
          onChange={handleChange}
        >
          <SelectItem text="Select" />
          {
            buckets && Array.isArray(buckets) && buckets.map(b => (
              <SelectItem key={b.bucketId} text={`${b.bucketName}`} value={b.bucketId} />
            ))
          }
        </Select>)
      }
      <div style={{ maxWidth: '300px' }}>
        {
          useBucket
          && (
          <TextInput
            name="bucketName"
            onChange={handleChange}
            placeholder="Bucket Name"
            invalid={isBucketNameValid}
            invalidText={invalidText}
            value={bucketName}
          />)
        }
      </div>
    </div>
  </Fragment>
);

export { TalentPoolBuckets };
