import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import { 
  REQUEST_GETALL_DOC_TYPES,
  REQUEST_GET_PROFILE_DOCS,
  REQUEST_ADD_PROFILE_DOC,
  REQUEST_DELETE_PROFILE_DOC,
  REQUEST_ADD_PROFILE_SIGNED_DOC,
  SUCCESS_ADD_PROFILE_SIGNED_DOC
} from './actionTypes';
import { deleteApiCache } from 'utils/helpers';

const getDocumentTypes = () => (dispatch) => {
  const url = `${baseUrl}/Documenttypes`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GETALL_DOC_TYPES,
  };
  return callApi(params);
};

const getAllDocuments = () => (dispatch) => {
  const url = `${baseUrl}/Persondocuments?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PROFILE_DOCS,
  };
  return callApi(params);
};

const uploadDocument = data => (dispatch) => {
  const url = `${baseUrl}/Persondocuments?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_ADD_PROFILE_DOC,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };
  deleteApiCache('xpa-api-profiles');
  return callApi(params);
};

const uploadSignedOfferDocument = data => (dispatch) => {
  const url = `${baseUrl}/persondocs/signedOffer?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_ADD_PROFILE_SIGNED_DOC,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };
  deleteApiCache('xpa-api-profiles');
  return callApi(params);
};

const deleteDocument = id => (dispatch) => {
  const url = `${baseUrl}/Persondocuments/${id}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_PROFILE_DOC,
  };
  return callApi(params);
};

export {
  getDocumentTypes,
  getAllDocuments,
  uploadDocument,
  deleteDocument,
  uploadSignedOfferDocument
};
