import callApi from '../../utils/callApi';
import { baseUrl, flaskUrl } from '../../utils/config';
import {
  REQUEST_CREATE_NEW_JOB,
  REQUEST_CREATE_NEW_SKILLS,
  REQUEST_ALL_SKILLS,
  REQUEST_DROPDOWN_DATA,
  REQUEST_PARSE_JD,
  REQUEST_JD_ASSOCIATE,
  REQUEST_CREATE_NEW_JOB_TITLE,
  REQUEST_CREATE_NEW_COMPANY,
  REQUEST_JOB_NAMES,
  REQUEST_ALL_COUNTRIES,
  REQUEST_ALL_COMPANIES,
  REQUEST_ALL_OFFICES,
  REQUEST_TOPFIVE_RESULTS,
  REQUEST_MANAGE_QUESTION,
  REQUEST_GET_WORKDAYS_LIST,
} from './actionTypes';
import {
  REQUEST_CREATE_NEW_OFFICE,
} from '../actionTypes'
import _ from 'lodash';
import { deleteJobApiCache, deleteApiCache } from 'utils/helpers';

const createNewOption = actionData => (dispatch) => {
  const { apiName, actionType, data } = actionData;
  const url = `${baseUrl}/${apiName}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType,
  };

  return callApi(params);
};

const parseJdJobReg = body => (dispatch) => {
  const url = `${flaskUrl}/jobdocuments/jd/parse`;

  const params = {
    dispatch,
    method: 'POST',
    url,
    body,
    actionType: 'REQUEST_PARSE_JD',
  };
  return callApi(params);
};

const parseJd = body => (dispatch) => {
  const url = `${flaskUrl}/jobs/jobdescriptions/uploads`;

  const params = {
    dispatch,
    method: 'POST',
    url,
    body,
    actionType: REQUEST_PARSE_JD,
  };
  return callApi(params);
};

const fetchDropdownData = () => (dispatch) => {
  const url = `${baseUrl}/jobs/meta?_=${new Date().getTime()}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_DROPDOWN_DATA,
  };

  return callApi(params);
};

const getFirstFiveMatchInTableCol = (table, colName, key) => (dispatch) => {
  const search = encodeURI(`%${key}%`);
  const url = `${baseUrl}/${table}?filter={"limit":5,"where":{"${colName}":{"like":"${search}","options":"i"}}}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_TOPFIVE_RESULTS,
  };

  return callApi(params);
};

const fetchAllOffices = cmpId => (dispatch) => {
  // Addresses?filter= Offices?filter[where][companyId]=${cmpId}
  // const data = { "where": { "addressId": { "inq": [ 179, 178 ] } } }
  const url = `${baseUrl}/offices?companyId=${cmpId}&_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_OFFICES,
  };

  return callApi(params);
};

const addOffice = data => (dispatch) => {
  const url = `${baseUrl}/offices?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_NEW_OFFICE,
  };

  return callApi(params);
}

const upsertOfficeDetails = data => (dispatch) => {
  const {
    officeId,
  } = data || {};
  const url = `${baseUrl}/offices/${officeId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_NEW_OFFICE,
  };
  
  return callApi(params);
}

const associateJd = jobId => (dispatch) => {
  const url = `${flaskUrl}/jobs/jobdescriptions?job_id=${jobId}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_JD_ASSOCIATE,
  };

  return callApi(params);
};

const saveJob = jobData => (dispatch) => {
  delete jobData["currency"];
  delete jobData["jobDescEditorState"];
  delete jobData["jobDescOldEditorState"];
  delete jobData["jobReqEditorState"];
  delete jobData["jobReqOldEditorState"];
  if (jobData.isPrivate === null) {
    jobData.isPrivate = true;
  }
  const url = `${baseUrl}/jobs`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(_.omit(jobData, 'term')),
    actionType: REQUEST_CREATE_NEW_JOB,
  };
  deleteJobApiCache();
  return callApi(params);
};

const editJob = jobData => (dispatch) => {
  delete jobData.currency;
  delete jobData.jobDescEditorState;
  delete jobData.jobDescOldEditorState;
  delete jobData.jobReqEditorState;
  delete jobData.jobReqOldEditorState;
  const { jobId = 0 } = jobData;
  if (jobData.country === undefined) {
    jobData.country = {};
  }
  if (jobData.countryId.label) {
    jobData.country.countryFull = jobData.countryId.label;
  } else {
    jobData.country.countryFull = jobData.countryName;
  }

  if (jobData.countryId.value) {
    jobData.country.countryId = jobData.countryId.value;
    jobData.countryId = jobData.countryId.value;
  } else {
    jobData.country.countryId = jobData.countryId;
  }

  if (jobData.isPrivate === null) {
    jobData.isPrivate = true;
  }
  const url = `${baseUrl}/jobs/${jobId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PUT',
    url,
    body: JSON.stringify(_.omit(jobData, 'term')),
    actionType: REQUEST_CREATE_NEW_JOB,
  };
  deleteJobApiCache();
  return callApi(params);
};

const updateFormData = params => (dispatch) => {
  dispatch({
    type: 'UPDATE_FORM_DATA',
    payload: params,
  });
};

const fetchCompanyId = data => (dispatch) => {
  const url = `${baseUrl}/companies/${data}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_COMPANIES,
  };

  return callApi(params);
};

const fetchAll = data => (dispatch) => {
  const {
    actionType,
    apiName,
    paramName,
    value = null,
    skip = null,
    limit = null,
  } = data;
  let url = `${baseUrl}/${apiName}?`;
  if (skip !== null) {
    url += `filter[skip]=${skip}`;
  }
  if (limit !== null) {
    url += `&filter[limit]=${limit}`;
  }
  if (value !== null && value.length > 0) {
    url += `&search=${encodeURIComponent(value)}`;
  }

  //  const url = `${baseUrl}/${apiName}?filter[where][${paramName}]=${value}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType,
  };

  return callApi(params);
};

const addQuestion = data => (dispatch) => {
  const url = `${baseUrl}/jobquestionbank`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_MANAGE_QUESTION,
  };
  return callApi(params);
};

const updateQuestion = data => (dispatch) => {
  const { id } = data;
  const url = `${baseUrl}/jobquestionbank/${id}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_MANAGE_QUESTION,
  };
  return callApi(params);
};

const deleteQuestion = id => (dispatch) => {
  const url = `${baseUrl}/jobquestionbank/${id}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_MANAGE_QUESTION,
  };
  return callApi(params);
};

const getWorkdaysList = () => (dispatch) => {
  const url = `${baseUrl}/workdays?_=${new Date().getTime()}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_WORKDAYS_LIST,
  };

  return callApi(params);
};

export {
  parseJd,
  fetchDropdownData,
  saveJob,
  updateFormData,
  fetchAll,
  associateJd,
  fetchCompanyId,
  createNewOption,
  editJob,
  fetchAllOffices,
  getFirstFiveMatchInTableCol,
  addOffice,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  upsertOfficeDetails,
  getWorkdaysList,
  parseJdJobReg
};
