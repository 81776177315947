import { TOGGLE_MAP_VIEW } from '../actions/actionTypes';

const initialSettings = {
  mapView: false,
};

const settings = (state = initialSettings, action) => {
  // console.log(action)
  switch (action.type) {
    case TOGGLE_MAP_VIEW:
      return {
        ...state,
        mapView: !state.mapView,
      };
    default:
      return state;
  }
};

export default settings;
