import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { REQUEST_CANDIDATE_INVITE_UPDATE } from '../actionTypes';

const fetchCandidateJobs = ({
  status,
  itemsPerPage = 10,
  activePage = 0,
  filterOptions = '',
  count,
}, typeOfAction) => (dispatch) => {
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  const url = `${baseUrl}/applications?_=${new Date().getTime()}&count=${count}&status=${status}&filter[limit]=${itemsPerPage}&filter[skip]=${skip}&`.concat(filterOptions);
  const params = {
    dispatch,
    actionType: typeOfAction,
    url,
    method: 'GET',
  };

  return callApi(params);
};


const setInviteStatus = data => (dispatch) => {
  const url = `${baseUrl}/applications?_=${new Date().getTime()}`;
  const inviteStatusObj = [data];
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(inviteStatusObj),
    actionType: REQUEST_CANDIDATE_INVITE_UPDATE,
  };
  return callApi(params);
};

export { fetchCandidateJobs, setInviteStatus };
