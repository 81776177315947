import { withTranslation } from 'react-i18next';
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { InlineNotification } from 'carbon-components-react';

import AnalyticsList from 'containers/roboroy/AnalyticsList/AnalyticsList';
import CandidateBrowseJobs from 'containers/roboroy/candidate/browse';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';


class userCompany extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showAnalyticsModule : false
        };
    }
    displayAnalytics = () => {
        this.setState({showAnalyticsModule : true});
    }
    render() {
        const {  user: { company: { companyName = 'company' } = {} }, match: { path } = {} } = this.props;
        const finalValue = path && path.toString() && path.split('/').reverse()[0];

        let tabContent = [
            {
              label: 'Jobs',
              value: <CandidateBrowseJobs forCompany={this.props.user && this.props.user.companyId||null}/>,
              onClick: () => {},
            },
            {
                label: 'Analytics',
                value: (
                    this.state.showAnalyticsModule ? (<AnalyticsList value = {finalValue} jobID={null} applicationID={null} companyID={this.props.user && this.props.user.companyId||null} recruiterId={null} />) : (<div></div>)                    
                ),
                onClick: () => { this.displayAnalytics()},
            },            
        ];

        return (
        <Fragment>
            <div className="bx--type-gamma font-weight-bold text-capitalize">{this.props.user && this.props.user.company && this.props.user.company.companyName} - Leaders</div>
            <InlineNotification lowContrast
                title={`This is the Leader's view for ${companyName}. Here you can look at open requisitions and hiring analytics at a company level.`}
                subtitle=""
                iconDescription="describes the close button"
                kind="info"
                hideCloseButton
            />                   
            <GenericTabs
                selected={0}
                tabContent={tabContent}
            />             
        </Fragment>);
    }
}


const mapStateToProps = state => ({
    user: state.x0paDuser.user,
  });
  
  const mapDispatchToProps = {};
  
  export default connect(mapStateToProps, mapDispatchToProps)(userCompany);