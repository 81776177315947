import callApi from 'utils/callApi';
import { baseUrl } from 'utils/config';
import { deleteApiCache } from 'utils/helpers';
import {
  REQUEST_GET_USER_WORKFLOWS,
  REQUEST_GET_WORKFLOW_STAGES,
  REQUEST_CREATE_WORKFLOW,
  REQUEST_ASSIGN_WORKFLOW_TO_APPLICANT,
  REQUEST_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER,
  REQUEST_UPDATE_WORKFLOW_APPL_STAGE,
  REQUEST_UPDATE_WORKFLOW_CONFIG,
  REQUEST_SKIP_WORKFLOW_STAGE,
  REQUEST_APPL_WORKFLOW,
  REQUEST_SCHEDULE_STAGE,
  REQUEST_AVAILABLE_SLOTS,
  REQUEST_DELETE_WORKFLOW_APPL_SUBSTAGE_USER,
  REQUEST_DELETE_WORKFLOW,
} from './actionTypes';

const getApplicationWorkflow = (appId) => (dispatch) => {
    const url = `${baseUrl}/applications/${appId}/workflows`;
    const params = {
      actionType: REQUEST_APPL_WORKFLOW,
      dispatch,
      url,
      method: 'GET'
    };
    deleteApiCache('xpa-api-appl');
    deleteApiCache('xpa-api');
    return callApi(params);
  };

const getAllUserWorkflow = ({workflowId} = {}) => (dispatch) => {
  const url = `${baseUrl}/workflows`;
  const params = {
    actionType: REQUEST_GET_USER_WORKFLOWS,
    dispatch,
    url,
    method: 'GET'
  };

  return callApi(params);
};

const updateApplicationStage = (data) => (dispatch) => {
    const url = `${baseUrl}/Workflowapplicationstages`;
    const params = {
        headers: {
            'content-type': 'application/json',
            },
        actionType: REQUEST_UPDATE_WORKFLOW_APPL_STAGE,
        dispatch,
        url: url,
        method: 'PATCH',
        body: JSON.stringify(data),
    };
    deleteApiCache('xpa-api-appl');
    deleteApiCache('xpa-api');
    return callApi(params);
}

const deleteCurrUser = data => (dispatch) => {
  const url = `${baseUrl}/workflows/${data && data.workflowId}/stages/${data && data.appStageId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER,
    dispatch,
    url,
    method: 'DELETE',
    body: JSON.stringify(data),
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const assignSubStageToUser = (data) => (dispatch) => {
    const url = `${baseUrl}/workflows/${data && data.workflowId}/stages/${data && data.appStageId}`;
    const params = {
        headers: {
            'content-type': 'application/json',
            },
        actionType: REQUEST_UPDATE_WORKFLOW_APPL_SUBSTAGE_USER,
        dispatch,
        url: url,
        method: 'POST',
        body: JSON.stringify(data),
    };
    deleteApiCache('xpa-api-appl');
    deleteApiCache('xpa-api');
    return callApi(params);
}

const updateApplicationSubStage = (data) => (dispatch) => {
    const url = `${baseUrl}/Workflowappstageusersubstagemaps`;
    const params = {
        headers: {
            'content-type': 'application/json',
            },
        actionType: REQUEST_UPDATE_WORKFLOW_APPL_STAGE,
        dispatch,
        url: url,
        method: 'PATCH',
        body: JSON.stringify(data),
    };
    deleteApiCache('xpa-api-appl');
    deleteApiCache('xpa-api');
    return callApi(params);
}

const updateSubStage = (data) => (dispatch) => {
    const {
        appStageId,
        workflowId,
        userMapId,
    } = data
    const url = `${baseUrl}/workflows/${workflowId}/stages/${appStageId}/substages/${userMapId}`;
    const params = {
        headers: {
            'content-type': 'application/json',
            },
        actionType: REQUEST_UPDATE_WORKFLOW_APPL_STAGE,
        dispatch,
        url: url,
        method: 'POST',
        body: JSON.stringify(data),
    };
    deleteApiCache('xpa-api-appl');
    deleteApiCache('xpa-api');
    return callApi(params);
}

const getWorkflowStages = () => (dispatch) => {
    const url = `${baseUrl}/Workflowstages/getAllWorkflowStages`;
    const params = {
      actionType: REQUEST_GET_WORKFLOW_STAGES,
      dispatch,
      url,
      method: 'GET'
    };

    return callApi(params);
  };

const cloneWorkflow = (data) => (dispatch) => {
  const {
    appId,
  } = data;
  const finalData = {
    appId: appId,
  };
  const url = `${baseUrl}/workflows/${data && data.workflowId}/clones`;
  const params = {
      headers: {
          'content-type': 'application/json',
          },
      actionType: REQUEST_CREATE_WORKFLOW,
      dispatch,
      url: url,
      method: 'POST',
      body: JSON.stringify(finalData),
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const createWorkflow = (data) => (dispatch) => {
    const url = `${baseUrl}/workflows`;
    const params = {
        headers: {
            'content-type': 'application/json',
            },
        actionType: REQUEST_CREATE_WORKFLOW,
        dispatch,
        url: url,
        method: 'POST',
        body: JSON.stringify(data),
    };
    deleteApiCache('xpa-api-appl');
    deleteApiCache('xpa-api');
    return callApi(params);
};

const assignWorkflowToJob = (data) => (dispatch) => {
  const url = `${baseUrl}/jobs/workflows`;
  const params = {
      headers: {
          'content-type': 'application/json',
          },
      actionType: REQUEST_ASSIGN_WORKFLOW_TO_APPLICANT,
      dispatch,
      url: url,
      method: 'POST',
      body: JSON.stringify([data]),
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const updateStageConfigs = data => (dispatch) => {
  const url = `${baseUrl}/workflows/${data && data.workflowId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_UPDATE_WORKFLOW_CONFIG,
    dispatch,
    url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const deleteJobWorkflow = workflowId => (dispatch) => {
  const url = `${baseUrl}/workflows/${workflowId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_WORKFLOW,
    dispatch,
    url,
    method: 'DELETE',
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const skipStage = data => (dispatch) => {
  const url = `${baseUrl}/workflows/${data && data.workflowId}/stages/${data && data.appStageId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_SKIP_WORKFLOW_STAGE,
    dispatch,
    url: url,
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const createPhoneInterviewEvent = data => (dispatch) => {
  const { appId, appStageId } = data || {};
  const url = `${baseUrl}/events/create?appId=${appId && appId.toString()}&appStageId=${appStageId && appStageId.toString()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_SCHEDULE_STAGE,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };
  deleteApiCache('xpa-api-appl');
  deleteApiCache('xpa-api');
  return callApi(params);
};

const scheduleStage = data => (dispatch) => {
  const url = `${baseUrl}/cronofy/real-time-schedule`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_SCHEDULE_STAGE,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const manuallyScheduleStage = data => (dispatch) => {
  const url = `${baseUrl}/cronofy/manual-schedule`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_SCHEDULE_STAGE,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };
  deleteApiCache('xpa-api-appl');
  return callApi(params);
};

const getAvailabilitySlots = data => (dispatch) => {
  const url = `${baseUrl}/cronofy/available-slots`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_AVAILABLE_SLOTS,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return callApi(params);
};

export {
  getAllUserWorkflow,
  getWorkflowStages,
  createWorkflow,
  cloneWorkflow,
  updateApplicationStage,
  updateApplicationSubStage,
  updateSubStage,
  getApplicationWorkflow,
  assignSubStageToUser,
  assignWorkflowToJob,
  updateStageConfigs,
  skipStage,
  scheduleStage,
  deleteJobWorkflow,
  getAvailabilitySlots,
  deleteCurrUser,
  createPhoneInterviewEvent,
  manuallyScheduleStage,
};
