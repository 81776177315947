import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import Avatar from 'components/common/Avatar';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import { Button, ToolbarItem, Modal, Checkbox } from 'carbon-components-react';

const BrowseCompInst = (props) => {
  const {
    data,
    handleRedirectToCompView,
    t,
    activeAcceptCompModal,
    activeRejectCompModal,
    handleSearchSubmit,
    isSuperAdmin,
    isAdmin,
    ctypeId,
    batchActionClickHandler,
    count,
    handleExportCompanies,
    activeExportCompModal,
    dismissExportCompModal,
    isOpenExportCompanyModal,
    handleCheckChange,
    batches,
    checked,
    state
  } = props || {};
  const columnWithFilter = [
    {
      title: `${t('company')} ${t('details')}`,
      className: 'text-left',
      name: 'offerId',
      propName: 'offerId',
    },
    {
      title: `${t('Status')}`,
      className: 'text-left',
      name: 'offerId',
      propName: 'offerId',
    },
    // {
    //   title: `${t('Actions')}`,
    //   className: 'text-left',
    //   name: 'offerId',
    //   propName: 'offerId',
    // },
  ];
  const otherActionsButton = [];
  otherActionsButton.push(
    <ToolbarItem>
      <Button
        kind="primary"
        className="mr-2"
        onClick={() => activeExportCompModal()}
      >
        Export {t('companies')}
      </Button>
    </ToolbarItem>
  );

  return (
    <Fragment>
      {data && Array.isArray(data) && data.length > 0 && (
        <GenericDataTable
          className=""
          otherActions={otherActionsButton}
          headers={columnWithFilter.map((d, index) => ({
            key: `header_${index}`,
            header: d.title,
            className: d.className,
            colpropName: d.propName,
          }))}
          batchActions={
            [{
              label: `${t('send')} ${t('email')}`,
              key: 'email',
              icon: {
                id: 'icon--email',
                name: 'icon--email',
                tags: 'icon--email',
                styles: '',
                viewBox: '0 0 16 11',
                width: '16',
                height: '11',
                svgData: {
                  circles: '',
                  ellipses: '',
                  paths: [
                    {
                      d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                      'fill-rule': 'nonzero',
                    },
                  ],
                  polygons: '',
                  polylines: '',
                  rects: '',
                },
              },
            },
            {
              label: `${t('send')} ${t('email')} ${t('to')} ${t('all')} ${count} ${t('companies')}`,
              key: 'email-all',
              icon: {
                id: 'icon--email',
                name: 'icon--email',
                tags: 'icon--email',
                styles: '',
                viewBox: '0 0 16 11',
                width: '16',
                height: '11',
                svgData: {
                  circles: '',
                  ellipses: '',
                  paths: [
                    {
                      d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                      'fill-rule': 'nonzero',
                    },
                  ],
                  polygons: '',
                  polylines: '',
                  rects: '',
                },
              },
            }
            ]
          }
          rows={
            data && Array.isArray(data) && data.length > 0
              ? data.map((res) => {
                const {
                  _source: {
                    companyId,
                    status,
                    ctypeId,
                    office: {
                      address: {
                        location: {
                          country: { countryFull } = {},
                          state: { stateFull } = {},
                          locationName,
                        } = {},
                      } = {},
                    } = {},
                    company: { description, website } = {},
                    companyinfo: { logo } = {},
                    companyName,
                    displayCompanyName,
                    companymapstatus,
                  } = {},
                } = res || {};
                let statusClass = 'cool-gray';
                if (companymapstatus === 'Pending') {
                  statusClass = 'cool-gray';
                } else if (companymapstatus === 'Accepted') {
                  statusClass = 'green';
                } else if (companymapstatus === 'Rejected') {
                  statusClass = 'red';
                }
                return {
                  isExpanded: true,
                  id: `row_${companyId}`,
                  // key: `${studentProfileId}`,
                  header_0: (
                    <div className="mb-2">
                      <div className="d-flex align-items-center">
                        <div style={{ minWidth: '60px' }} className="mt-2">
                          <Avatar
                            user={{
                              firstname: displayCompanyName,
                              lastname: null,
                              picture: logo,
                            }}
                            isCompanyLogo
                            classNameInfo="rounded-circle xpa-avatar-color"
                            size="60"
                          />
                        </div>
                        <div className="ml-2">
                          <div
                            onClick={() =>
                              handleRedirectToCompView(companyId)
                            }
                            className="bx--type-delta text-uppercase xpa-link">
                            {displayCompanyName}&nbsp;&nbsp;
                            {/* {ctypeId &&
                              Number(ctypeId === 1) &&
                              status === false ? (
                                <span className="btn btn-sm btn-dark">
                                  {t('inActive')}
                                </span>
                              ) : null} */}
                          </div>
                          <div className="text-justify mt-1 text-dark">
                            {description}
                          </div>
                          <div className="">
                            {(countryFull || stateFull || locationName) && (
                              <div className="text-capitalize">
                                <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                />{' '}
                                {countryFull}{' '}
                                {countryFull && locationName ? ',' : null}{' '}
                                {locationName}
                              </div>
                            )}
                          </div>
                          <div>
                            <a target="_blank" href={website}>
                              {website}
                            </a>
                          </div>
                          {/* <div>
                            <span
                              className={`bx--tag bx--tag--${statusClass}`}>
                              {companymapstatus}
                            </span>
                          </div> */}
                        </div>
                      </div>
                      {/* {ctypeId && Number(ctypeId) === 1 && isSuperAdmin === true && (
                        <div>
                          {companymapstatus === 'Pending' && (
                            <div>
                              <div className="d-flex mt-2">
                                <div>
                                  <Button
                                    kind="primary"
                                    small
                                    onClick={() => {
                                      activeAcceptCompModal(
                                        companyId,
                                        'Accepted'
                                      );
                                    }}>
                                    {t('accept')}
                                  </Button>
                                </div>
                                <div className="ml-2">
                                  <Button
                                    kind="danger"
                                    small
                                    onClick={() => {
                                      activeRejectCompModal(
                                        companyId,
                                        'Rejected'
                                      );
                                    }}>
                                    {t('reject')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )} */}
                    </div>
                  ),
                  header_1: (
                    <div>
                      {ctypeId &&
                        Number(ctypeId === 1) &&
                        status === false ? (
                          <span className="btn btn-sm btn-dark mr-2">
                            {t('inActive')}
                          </span>
                        ) : null}
                      <span
                        className={`bx--tag bx--tag--${statusClass}`}>
                        {companymapstatus}
                      </span>
                    </div>
                  ),
                  // header_2: (
                  //   <div>
                  //     {ctypeId && Number(ctypeId) === 1 && isSuperAdmin === true && (
                  //       <div>
                  //         {companymapstatus === 'Pending' && (
                  //           <div>
                  //             {/* <hr /> */}
                  //             <div className="d-flex mt-2">
                  //               <div>
                  //                 <Button
                  //                   kind="primary"
                  //                   small
                  //                   onClick={() => {
                  //                     activeAcceptCompModal(
                  //                       companyId,
                  //                       'Accepted'
                  //                     );
                  //                   }}>
                  //                   {t('accept')}
                  //                 </Button>
                  //               </div>
                  //               <div className="ml-2">
                  //                 <Button
                  //                   kind="danger"
                  //                   small
                  //                   onClick={() => {
                  //                     activeRejectCompModal(
                  //                       companyId,
                  //                       'Rejected'
                  //                     );
                  //                   }}>
                  //                   {t('reject')}
                  //                 </Button>
                  //               </div>
                  //             </div>
                  //           </div>
                  //         )}
                  //       </div>
                  //     )}
                  //   </div>
                  // ),
                };
              })
              : []
          }
          selectable={isSuperAdmin || (isAdmin && ctypeId && Number(ctypeId) === 2)}
          searchable
          onSearchInputChange={(e) => handleSearchSubmit(e)}
          expandable={false}
          batchActionClick={batchActionClickHandler}
        />
      )}
      <Modal
      open={isOpenExportCompanyModal}
      onRequestSubmit={handleExportCompanies}
      onRequestClose={dismissExportCompModal}
      modalHeading={t('exportCompanies')}
      primaryButtonText={t('export')}
      secondaryButtonText={t('cancel')}
      >
      <div>
        <p>
          {t('exportMsg')} 
        </p>
        <p>  
          {t('exportCompanyJobInfo')}
        </p> 
      </div>  
      <br />

      <div className="bx--row mt-1">
        {
          batches && Array.isArray(batches) && batches.length >0 && batches.map((batchInfo) => {
            const {
              batchId,
              batchName,
              isActiveBatch,
              isCurrentBatch,
              createdAt,
              updatedAt
            } = batchInfo || {}
            let disabledUnSelectedBatches
            let checkedValue
            if(checked && Array.isArray(checked) && checked.length>0){
              checked.map((r) => {
                if(r != batchName){
                  disabledUnSelectedBatches = true
                }
                if(r == batchName){
                  checkedValue = batchId
                }
              })
            }
            return (
              <div className="bx--col-lg-4">
                <Checkbox 
                  className="mb-0"
                  key={batchId}
                  labelText={
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="text-capitalize">{batchName}</div>
                      </div>
                    </div>}    
                  checked={checkedValue}
                  id={`batchInfo-${batchId}`}
                  name={`batchInfo-${batchName}`}
                  value={state[`batchInfo-${batchId}`]}
                  onChange={(e) => handleCheckChange(batchId, batchName, e)}
                  disabled={disabledUnSelectedBatches}
                />
              </div>  
            )
          })
        }
      </div>  
      </Modal>
    </Fragment>
  );
};

export default withTranslation()(BrowseCompInst);
