import { withTranslation } from 'react-i18next';
import React from 'react';
import {
  Tile,
} from 'carbon-components-react';

const ModifiedRegionsTooltip = (props) => {
  const { label, regionsArr, toolTip } = props;
  const regionsColor = ['#ffa600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c'];
  const count = [];
  if (regionsArr && Array.isArray(regionsArr) && regionsArr.length > 0) {
    regionsArr.forEach((r, i) => {
      const {
        key,
      } = r || {};
      let val = '';
      if (key === label) {
        val = key;
        const color = regionsColor[i % regionsColor.length];
        const res = { ...r, color };
        count.push(res);
      }
    });
  }
  return (
    <Tile>
      <svg className="recharts-surface pr-1 pt-1" width="16" height="16" viewBox="0 0 32 32" version="1.1"><path stroke="none" fill={count && count[0] && count[0].color} d="M0,4h32v24h-32z" className="recharts-legend-icon"></path></svg>
      {label} {count && count[0] && count[0].count} {toolTip}
    </Tile>
  );
};

export default (withTranslation()(ModifiedRegionsTooltip));
