import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import React, { Fragment, Component } from 'react';
import {
  Tile, Checkbox, Button, Modal,
} from 'carbon-components-react';
import { connect } from 'react-redux';
import { getStudentsFacets } from 'actions/BrowseStudents';
import {
  Layout,
  PagingInfo,
  BooleanFacet,
  MultiCheckboxFacet,
  Facets
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import './FacetsBrowse.css'
import queryString from 'query-string';

class FacetsBrowse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterPropsArr: [],
    }
  }

  // handleChangeCheckBoxVals = (key, e, res) => {
  //   const {
  //     mainVals,
  //     facets
  //   } = this.state;
  //   let {
  //     finalString,
  //   } = this.state;
  //   let filterVals = this.state[`${res}`];
  //   if (e === true) {
  //     if (!Array.isArray(filterVals)) {
  //       filterVals = [key];
  //     } else if (filterVals && Array.isArray(filterVals) && filterVals.length <= 0) {
  //       filterVals = [key];
  //     } else {
  //       filterVals.push(key);
  //     }
  //   }
  //   if (res === "Courses" || res === "Batch") {
  //     facets[`${res}`] = facets[`${res}`].map((item) => {
  //       if (item.key === key) {
  //         item.isSelected = e;
  //       }
  //       return item;
  //     })
  //   } else {
  //     facets[`${res}`] = facets[`${res}`].map((item) => {
  //       if (item.key === key) {
  //         item.isSelected = e;
  //       } else {
  //         item.isSelected = false;
  //       }
  //       return item;
  //     })
  //   }
  //   finalString = '';
  //   const facetValues = {};
  //   for (let arr in facets) {
  //     if (facets[arr] && Array.isArray(facets[arr]) && facets[arr].length > 0) {
  //       const fv = facets[arr].map((item) => {
  //         if (item.isSelected === true) {
  //           return item["key"];
  //         } else {
  //           return null
  //         }
  //       }).filter((item) => item !== null);
  //       if (fv && Array.isArray(fv) && fv.length > 0) {
  //         finalString += `${arr}=${encodeURIComponent(fv)}&`;
  //       }
  //       facetValues[arr] = fv
  //     }
  //   }

  //   queryString.stringify(finalString);
  //   if (e === true || e === false) {
  //     this.setState({
  //       ...facetValues,
  //       facets: facets,
  //       finalString,
  //     }, () => {
  //       this.props.history.push({
  //         pathname: '/app/campus/browse/students',
  //         search: `?${this.state.finalString}`,
  //       });
  //       if (mainVals === null) {
  //         this.props.handleSubmitFilterVals(res);
  //       }
  //     });
  //   } else if (mainVals === null) {
  //     this.props.handleSubmitFilterVals(res);
  //   }
  // }

  handleClearAllFilters = () => {
    const { filterPropsArr } = this.state;
    filterPropsArr.map((filterProps) => {
      filterProps.options.map((option) => {
        option.selected = false;
      })
      filterProps.optionsClone.map((option) => {
        option.selected = false;
      })
    })
    this.setState({
      filterPropsArr,
      defaultCheckList: {}
    }, () => {
      this.handleFacetsState();
      this.props.handleClearFilters();
    })
  }

  componentDidMount() {
    this.setState({
      defaultCheckList: this.props.defaultCheckList,
      facets: this.props.facets
    }, () => {
      this.handleFacetsState();
    })
  }


  handleFacetsState = () => {
    // console.log(this.props)
    // this.props.getStudentsFacets().then(() => {
    // const { } = this.props;
    // console.log(facets);
    // this.setState({
    //   facets: facets
    // }, () => {
    const {
      // facets,
      t,
      values,
      handleChangeCheckBoxVals,
      handleClearFilters,
      activeMoreModal,
      handleSubmitCheckBox,
      dismissMoreModal,
      isOpenMoreModal,
      mainVals,
      showAlteredFacetsLabels,
      handleFacetsLabels,
      keys = { keys },
      isJobsFilter,
      isApplsFilter,
      isCompanyFilter,
      isEmployeeFilter,
      handleSelectClearAll
      // facets,
      // defaultCheckList
    } = this.props;

    const { defaultCheckList, facets } = this.state;

    let filterPropsArr = []
    if (keys && Array.isArray(keys) && keys.length > 0) {
      for (let eachKeyIndex in keys) {
        const res = keys[eachKeyIndex];
        if (facets[res] && Array.isArray(facets[res]) && facets[res].length > 0) {
          const actualLabel = handleFacetsLabels ? (
            res && res === 'Companymapstatus' ? t('regStatus') : (
              showAlteredFacetsLabels ? (
                handleFacetsLabels(res)
              ) : (
                  res
                )
            )
          ) : res;
          const multiValueProps = {
            label: (res==="Faculties" || res==="Courses" || res==="Batch" || res==="Companies" ||isApplsFilter || (isCompanyFilter && res!=="Status"))?
              <div>
                <div>{actualLabel}</div>
                <div>
                  <a href="#" className="xpa-link xpa-facets-link pr-1 xpa-br-1" onClick={()=>{
                    multiValueProps.options.map((option)=>{
                      option.selected=true;
                    })
                    multiValueProps.optionsClone.map((optionClone)=>{
                      optionClone.selected=true;
                    })
                    this.setState({filterPropsArr},()=>this.props.handleSelectClearAll(res,true))
                  }}>Select all</a>
                  <a href="#" className="xpa-link xpa-facets-link pl-1" onClick={()=>{
                    multiValueProps.options.map((option)=>{
                      option.selected=false;
                    })
                    multiValueProps.optionsClone.map((optionClone)=>{
                      optionClone.selected=false;
                    })
                    this.setState({filterPropsArr},()=>this.props.handleSelectClearAll(res,false))
                  }}>Clear all</a>
                </div>
              </div>:actualLabel
            ,
            showSearch: false,
            showMore: false,
            more: 10,
            options: [],
            values: [],
            searchPlaceholder: `Search By ${actualLabel}`,
          };

          const val = facets[res].map((vals, idx) => {
            if(isJobsFilter || isApplsFilter || isEmployeeFilter){
              const {
                key,
                id,status,filterKey
              } = vals || {};
              return {
                value: key,
                count: '',
                selected: (defaultCheckList !== null && defaultCheckList !== undefined && defaultCheckList.hasOwnProperty(filterKey) && defaultCheckList[filterKey].includes(id)),
                id,
                status,
                filterKey,
                label: (idx + 1),
              }
            }else{
              const {
                key,
                isSelected,
              } = vals || {};
              return {
                value: key,
                count: '',
                selected:
                  (defaultCheckList !== null && defaultCheckList !== undefined && defaultCheckList.hasOwnProperty(res) && defaultCheckList[res].includes(key)),
                // isSelected,
                label: (idx + 1),
              }
            }
          })

          multiValueProps.options = val;

          multiValueProps.optionsClone = JSON.parse(
            JSON.stringify(multiValueProps.options)
          );

          multiValueProps.options = multiValueProps.options.slice(
            0,
            multiValueProps.more
          );

          if (
            multiValueProps.optionsClone &&
            multiValueProps.optionsClone.length > multiValueProps.more
          ) {
            multiValueProps.showSearch = true;
            multiValueProps.showMore = true;
          }

          multiValueProps.onMoreClick = (value) => {
            multiValueProps.more += 10;

            let filteredOptions = multiValueProps.optionsClone.slice(
              0,
              multiValueProps.more
            );
            // for (let eachOptionIndex in multiValueProps.options) {
            //   filteredOptions[eachOptionIndex].selected = multiValueProps.options[eachOptionIndex].selected;
            // }
            multiValueProps.options = JSON.parse(
              JSON.stringify(filteredOptions)
            );

            multiValueProps.showMore =
              multiValueProps.optionsClone &&
              multiValueProps.optionsClone.length > multiValueProps.more;

            this.setState({ filterPropsArr });
          }

          multiValueProps.onSearch = (value) => {
            let filteredOptions = multiValueProps.optionsClone.filter(
              (v) =>
                v.value &&
                v.value.search &&
                v.value.search(new RegExp(value, 'gi')) >= 0
            );

            multiValueProps.options = JSON.parse(
              JSON.stringify(filteredOptions)
            );
            multiValueProps.more=multiValueProps.optionsClone.length;
            this.setState({ filterPropsArr });
          }

          multiValueProps.onRemove = (value) => {
            let foundIndex = multiValueProps.options.findIndex(
              (v) => v.value == value
            );
            let cloneFoundIndex=multiValueProps.optionsClone.findIndex(
              (v) => v.value == value
            )
            if (foundIndex >= 0 && cloneFoundIndex>=0) {
              multiValueProps.options[foundIndex].selected = false;
              multiValueProps.optionsClone[cloneFoundIndex].selected = false;
              this.setState({ filterPropsArr });
            }
            if (isJobsFilter || isApplsFilter || isEmployeeFilter) {
              const {id,status,filterKey}=multiValueProps.options[foundIndex];
              this.props.handleChangeCheckBoxVals(false,id,status,filterKey);
            } else {
              this.props.handleChangeCheckBoxVals(value, false, res);
            }
          }

          multiValueProps.onSelect = (value) => {
            let foundIndex = multiValueProps.options.findIndex(
              (v) => v.value == value
            );
            let cloneFoundIndex=multiValueProps.optionsClone.findIndex(
              (v) => v.value == value
            )

            if (foundIndex >= 0 && cloneFoundIndex>=0) {
              multiValueProps.options[foundIndex].selected = true;
              multiValueProps.optionsClone[cloneFoundIndex].selected = true;
              if (value === "yes" || value === "active" || value==="completed") {
                multiValueProps.options.map((option) => {
                  if (option.value === "no" || option.value === "inactive" || option.value==="pending") {
                    option.selected = false;
                  }
                });
                multiValueProps.optionsClone.map((option) => {
                  if (option.value === "no" || option.value === "inactive"|| option.value==="pending") {
                    option.selected = false;
                  }
                });
              } else if (value === "no" || value === "inactive" || value==="pending") {
                multiValueProps.options.map((option) => {
                  if (option.value === "yes" || option.value === "active" || option.value==="completed") {
                    option.selected = false;
                  }
                });
                multiValueProps.optionsClone.map((option) => {
                  if (option.value === "yes" || option.value === "active" || option.value==="completed") {
                    option.selected = false;
                  }
                });
              }
              this.setState({ filterPropsArr });
            }
            if (isJobsFilter || isApplsFilter || isEmployeeFilter) {
              const {id,status,filterKey}=multiValueProps.options[foundIndex];
              if(value==="completed" || value==="pending"){
                this.props.handleChangeRadioBtn(id,status,filterKey)
              }else{
                this.props.handleChangeCheckBoxVals(true,id,status,filterKey);
              }
            } else {
              this.props.handleChangeCheckBoxVals(value, true, res);
            }
          };

          filterPropsArr.push(multiValueProps);
        }
      }
    }
    this.setState({ filterPropsArr })
    // })
    // })
  }


  render() {
    const { filterPropsArr, facets} = this.state;
    const {
      // facets,
      t,
      values,
      handleChangeCheckBoxVals,
      activeMoreModal,
      handleSubmitCheckBox,
      dismissMoreModal,
      isOpenMoreModal,
      mainVals,
      showAlteredFacetsLabels,
      handleFacetsLabels,
    } = this.props;
    // console.log(facets);  
    // console.log(filterPropsArr)
    // let keys = []
    // const finalKeys = facets && Object.keys(facets);
    // if (finalKeys && Array.isArray(finalKeys) && finalKeys.length > 0) {
    //   keys.splice(0, 0, "Faculties")
    //   keys.splice(1, 0, "Courses")
    //   keys.splice(2, 0, "Batch")
    //   keys.splice(3, 0, "Suspended")
    //   keys.splice(4, 0, "Open Market")
    //   keys.splice(5, 0, "Application Submitted")
    //   keys.splice(6, 0, "Applied to Jobs")
    //   keys.splice(7, 0, "Shortlisted By Companies")
    //   keys.splice(8, 0, "Completed Workflow")
    //   keys.splice(9, 0, "Offer In Progress")
    //   keys.splice(10, 0, "Accepted Offer")
    //   keys.splice(11, 0, "Hired")
    //   keys.splice(12, 0, "Uploaded Signed Offer Letter")
    // }
    return (
      // <Tile>

      <div className="text-capitalize">
        <Facets>
          {filterPropsArr &&
            filterPropsArr.map((multiValueProps) => (
              <MultiCheckboxFacet {...{ ...multiValueProps }} />
            ))}
        </Facets>
        {/* {
          keys && Array.isArray(keys)
          && keys.length > 0
          && keys.map((res, i) => {
            return (

              <Fragment>
                {
                  facets[res] && Array.isArray(facets[res])
                  && facets[res].length > 0
                  && (
                    res && res === 'Companymapstatus' ? (
                      <div className="font-weight-bold text-uppercase mb-1">{t('regStatus')}</div>
                    ) : (
                        showAlteredFacetsLabels ? (
                          <div className="font-weight-bold text-uppercase mb-1">
                            {handleFacetsLabels(res)}
                          </div>
                        ) : (
                            <div className="font-weight-bold text-uppercase mb-1">{res}</div>
                          )
                      )
                  )
                }
                {
                  facets[res] && Array.isArray(facets[res])
                  && facets[res].length > 0
                  && facets[res].map((vals, idx) => {
                    const {
                      key,
                      isSelected,
                    } = vals || {};

                    return (
                      <div className="mb-2">
                        {
                          idx <= 4
                          && (
                            <Checkbox
                              labelText={key}
                              id={`${res}_${key}_${idx}`}
                              checked={isSelected}
                              className="text-capitalize mb-0"
                              value={key}
                              onChange={(e) => {
                                console.log(key)
                                console.log(e)
                                console.log(res)
                                handleChangeCheckBoxVals(key, e, res); }}
                            />
                          )
                        }
                      </div>
                    )
                  })
                }
                {
                  facets[res] && Array.isArray(facets[res])
                  && facets[res].length > 5
                  && (
                    <div className="mb-2">
                      <Button
                        kind="ghost"
                        small
                        onClick={() => activeMoreModal(res)}
                      >
                        {t('more')}...
                        </Button>
                    </div>
                  )
                }
              </Fragment>
            )
          })
        } */}
        <div className="text-center">
          <Button
            kind="primary"
            small
            onClick={this.handleClearAllFilters}
          >
            {t('clearFilters')}
          </Button>
        </div>

        <Modal
          onRequestSubmit={handleSubmitCheckBox}
          onRequestClose={dismissMoreModal}
          open={isOpenMoreModal}
          modalLabel={`${t('filters')}`}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading={mainVals}
        >
          <div>
            {
              facets && Array.isArray(facets[mainVals])
              && facets[mainVals].length > 0
              && facets[mainVals].map((vals, idx) => {
                const {
                  key,
                  isSelected,
                } = vals || {};
                return (
                  <div className="mb-2">
                    <Checkbox
                      labelText={key}
                      checked={values && values[`${mainVals}`] && Array.isArray(values[`${mainVals}`]) && values[`${mainVals}`].includes(key)}
                      id={`${mainVals}_${key}_${idx}`}
                      className="text-capitalize"
                      value={key}
                      onChange={(e) => { handleChangeCheckBoxVals(key, e, mainVals); }}
                    />
                  </div>
                );
              })
            }
          </div>
        </Modal>
      </div>
      // </Tile>
    );
  }
}

// const mapStateToProps = state => ({
//   facets: state.BrowseStudents.facets,
// });

// const mapDispatchToProps = {
//   getStudentsFacets,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(FacetsBrowse)));
export default (withTranslation()(withRouter(FacetsBrowse)));
