import isEmail from 'validator/lib/isEmail';

// Contact Info Validation
const contactInfoValidation = (data) => {
  const {
    firstname, lastname, email, countryCode, phoneNumber,
    t, status, designation,
  } = data || {};
  let isFirstNameValid = null;
  let isFirstNameValidText = '';
  let isLastNameValid = null;
  let isLastNameValidText = '';
  let isEmailValid = null;
  let isEmailValidText = '';
  let isValidCountryCode = null;
  let isValidCountryCodeValue = '';
  let isPhoneNumberValid = null;
  let isPhoneNumberValidText = '';
  let isValidDesignation = null;
  let isValidDesignationVal = '';

  if (!firstname || firstname === null || firstname === undefined) {
    isFirstNameValid = true;
    isFirstNameValidText = `${t('nameValidation')}`;
  } else {
    isFirstNameValid = false;
    isFirstNameValidText = '';
  }
  if (!lastname || lastname === null || lastname === undefined) {
    isLastNameValid = true;
    isLastNameValidText = `${t('nameValidation')}`;
  } else {
    isLastNameValid = false;
    isLastNameValidText = '';
  }
  if (!email || email === null || email === undefined || !isEmail(email)) {
    isEmailValid = true;
    isEmailValidText = `${t('emailValidation')}`;
  } else {
    isEmailValid = false;
    isEmailValidText = '';
  }
  if (!countryCode || countryCode === null || countryCode === undefined) {
    isValidCountryCode = true;
    isValidCountryCodeValue = `${t('countryCodeValidation')}`;
  } else {
    isValidCountryCode = false;
    isValidCountryCodeValue = '';
  }
  if (!phoneNumber || phoneNumber === null || phoneNumber === undefined || (phoneNumber && phoneNumber.length !== 8)) {
    isPhoneNumberValid = true;
    isPhoneNumberValidText = `${t('phoneNoValidation')}`;
  } else {
    isPhoneNumberValid = false;
    isPhoneNumberValidText = '';
  }

  if (!designation || designation === null || designation === undefined) {
    isValidDesignation = true;
    isValidDesignationVal = `${t('designationValidation')}`;
  } else {
    isValidDesignation = false;
    isValidDesignationVal = '';
  }

  const finaldata = {
    isFirstNameValid,
    isFirstNameValidText,
    isLastNameValid,
    isLastNameValidText,
    isEmailValid,
    isEmailValidText,
    isValidCountryCode,
    isValidCountryCodeValue,
    isPhoneNumberValid,
    isPhoneNumberValidText,
    isValidDesignation,
    isValidDesignationVal,
  };
  return finaldata;
};

// Office Details Validation
const officeDetailsValidation = (data) => {
  const {
    addressLine1,
    zipcode,
    country,
    location = {},
    officeName,
    t,
  } = data;

  const {
    value: locationId,
  } = location || {};

  let isValidAddrLine1 = null;
  let isValidAddrLine1Text = '';
  let isValidZipcode = null;
  let isValidZipcodeText = '';
  let isValidCountry = null;
  let isValidCountryText = '';
  let isValidCity = null;
  let isValidCityText = '';
  let isValidOfficeName = null;
  let isValidOfficeNameText = '';

  if (!addressLine1 || addressLine1 === null || addressLine1 === undefined) {
    isValidAddrLine1 = true;
    isValidAddrLine1Text = `${t('addressLine1Valid')}`;
  } else {
    isValidAddrLine1 = false;
    isValidAddrLine1Text = '';
  }
  if (!zipcode || zipcode === null || zipcode === undefined) {
    isValidZipcode = true;
    isValidZipcodeText = `${t('zipcodeValidation')}`;
  } else {
    isValidZipcode = false;
    isValidZipcodeText = '';
  }
  if (!country || country === null || country === undefined) {
    isValidCountry = true;
    isValidCountryText = `${t('countryValidation')}`;
  } else {
    isValidCountry = false;
    isValidCountryText = '';
  }
  if (!locationId || locationId === null || locationId === undefined) {
    isValidCity = true;
    isValidCityText = `${t('cityValidation')}`;
  } else {
    isValidCity = false;
    isValidCityText = '';
  }
  if (!officeName || officeName === null || officeName === undefined) {
    isValidOfficeName = true;
    isValidOfficeNameText = `${t('officeValidation')}`;
  } else {
    isValidOfficeName = false;
    isValidOfficeNameText = '';
  }
  const finaldata = {
    isValidAddrLine1,
    isValidAddrLine1Text,
    isValidCountry,
    isValidCountryText,
    isValidOfficeName,
    isValidOfficeNameText,
    isValidZipcode,
    isValidZipcodeText,
    isValidCity,
    isValidCityText,
  };
  return finaldata;
};

const companyDetailsValid = (data) => {
  const {
    companyName,
    domain,
    t,
  } = data;
  let companyNameInvalid = null;
  let isValidDomain = null;
  let isValidDomainText = '';

  if (!companyName || companyName === null || companyName === undefined) {
    companyNameInvalid = true;
  }
  if (!domain || domain === null || domain === undefined) {
    isValidDomain = true;
    isValidDomainText = `${t('domainValidation')}`;
  } else {
    isValidDomain = false;
    isValidDomainText = '';
  }
  const finaldata = {
    companyNameInvalid,
    isValidDomain,
    isValidDomainText,
  };
  return finaldata;
};

const getLocationsDetails = (values) => {
  const {
    locations,
  } = values;
  let locationsArr = [];
  if (locations && Array.isArray(locations) && locations.length > 0) {
    locationsArr = locations.map((data) => {
      const {
        locationName,
        locationId,
        countryId,
      } = data || {};
      const finalData = {
        label: locationName,
        value: locationId,
        countryId,
      };
      return finalData;
    });
  }
  return locationsArr;
};

export {
  contactInfoValidation,
  officeDetailsValidation,
  companyDetailsValid,
  getLocationsDetails,
};
