import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Search, Modal,
  Pagination, Select, SelectItem, InlineNotification
} from 'carbon-components-react';
import BackRow from 'components/common/BackRow/BackRow';
import {
  fetchAllCompaniesList,
  fetchAllCompaniesFacets,
  getCompaniesExport
} from 'actions/BrowseCompanies';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import queryString from 'query-string';
import BrowseCompInst from 'components/roboroy/common/BrowseCompInst';
import FacetsBrowse from 'components/roboroy/common/FacetsBrowse';
import {
  authorizeCompany,
} from 'actions/admin/CompanyAndOffice';
import './Browse.css';
import { getCustomEmailTemplates } from 'actions/email-templates';
import {
  Layout,
  PagingInfo,
  Result,
  SearchBox,
  Facets,
  MultiCheckboxFacet,
  SingleSelectFacet,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import { sendEmailToCompanies } from 'actions/talentSearch/talentPool';
import { getBatchDetails } from 'actions/BatchManagementView';

const NoCompaniesBox = ({
  t,
}) => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="icon icon-3x icon-flag h1" />
      </div>
      <p className="empty-title h5">{t('noCompaniesMsg')}</p>
    </div>
  </Tile>
);

class BrowseCompanies extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'companies', name: 'Company', href: '' },
  ];

  candPaths = [
    { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
    { id: 'companies', name: 'Company', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      activePage: 1,
      itemsPerPage: 10,
      isOpenAcceptCompanyModal: false,
      isOpenRejectCompanyModal: false,
      finalString: null,
      selectTemplateModal: false,
      selectedEmailTemplate: 'placeholder-item',
      // selectedSort: 'placeholder-item',
      checkedItems: {}
    };
  }

  componentDidMount() {
    const {
      location: {
        search,
      } = {},
    } = this.props || {};
    if (search) {
      this.handleSearchQuery();
    } else {
      const {
        itemsPerPage,
        activePage,
        keyword,
      } = this.state;
      const data = {
        itemsPerPage,
        activePage,
        keyword,
      };
      this.props.fetchAllCompaniesList(data);
      this.props.fetchAllCompaniesFacets();
      this.setState({
        defaultCheckList: {}
      })
    }
    this.props.getCustomEmailTemplates();
    this.props.getBatchDetails().then((res) =>{
      if(res && !res.error){
        this.setState({
          batches: (res && res.list)
        })
      }
    })
  }

  handleExportCompanies = () => {
    const {
      match: { params: { limit, page, status: paramStatus } = {} } = {},
      location: { search } = {},
      user: {
        companyId,
        company: {
          ctypeId
        } = {}
      } = {}
    } = this.props;
    const finaldata = this.state;
    let { checkedItems } = finaldata
    let batch
    
    let info = Object.values(checkedItems)
    if(info && Array.isArray(info) && info.length>0){
      info.map((b) => {
        batch = b
      })
    }
    this.props.getCompaniesExport({batch: batch})
    this.setState({
      checkedItems: null,
      isOpenExportCompanyModal: false
    })
  };

  handleSearchQuery = () => {
    const {
      location: {
        search,
      } = {},
    } = this.props;
    const splitSearchVal = search && search.toString()
      && search.split('?');
    const stringVals = splitSearchVal && Array.isArray(splitSearchVal)
      && splitSearchVal.length > 1
      && splitSearchVal[1];
    const filterNameArr = stringVals && stringVals.toString()
      && stringVals.split('&');
    const filterNameSplit = filterNameArr && Array.isArray(filterNameArr)
      && filterNameArr.length > 0
      && filterNameArr.forEach((res) => {
        if (res) {
          const valSpl = res.split('=');
          let valueArr = [];
          const decodedVal = valSpl[1] && decodeURIComponent(valSpl[1]);
          if (decodedVal && decodedVal.includes(',')) {
            valueArr = decodedVal.split(',');
          } else {
            valueArr = [decodedVal];
          }
          const finalFilterName = decodeURIComponent(valSpl[0]);
          let defaultCheckList = {};
          defaultCheckList[finalFilterName] = valueArr[0];
          // console.log(defaultCheckList);
          this.setState({
            defaultCheckList: defaultCheckList,
            [finalFilterName]: valueArr,
            finalString: search,
          }, () => {
            this.handleSubmitFilterVals(null);
          });
        }
      });
  }

  activeAcceptCompModal = () => {
    this.setState({
      isOpenAcceptCompanyModal: true,
    });
  }

  dismissAcceptCompModal = () => {
    this.setState({
      isOpenAcceptCompanyModal: false,
    });
  }

  activeExportCompModal = () => {
    this.setState({
      isOpenExportCompanyModal: true,
    });
  }

  dismissExportCompModal = () => {
    this.setState({
      isOpenExportCompanyModal: false,
    });
  }

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.props.fetchAllCompaniesList({
          itemsPerPage: this.state.itemsPerPage,
          activePage: 1,
          keyword: this.state.keyword,
        })
      ), 1000);
    });
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      this.props.fetchAllCompaniesList(this.state);
    });
  };

  handleClearFilters = () => {
    const {
      activePage,
      itemsPerPage,
      keyword,
    } = this.state;
    this.setState({
      Locations: [],
      Status: [],
      Companymapstatus: [],
      finalString: null,
    }, () => {
      this.props.history.push('/app/campus/browse/companies');
      const data = {
        activePage,
        itemsPerPage,
        keyword,
      };
      this.props.fetchAllCompaniesList(data);
      this.props.fetchAllCompaniesFacets(data);
    });
  }

  handleChangeCheckBoxVals = (key, e, res) => {
    const {
      mainVals,
    } = this.state;
    let {
      finalString,
    } = this.state;
    let filterVals = this.state[`${res}`];
    if (e === true) {
      if (!Array.isArray(filterVals) || res === "Status") {
        filterVals = [key];
      } else if (filterVals && Array.isArray(filterVals) && filterVals.length <= 0) {
        filterVals = [key];
      } else {
        filterVals.push(key);
      }
    }
    this.setState({
      [`${res}`]: filterVals,
      itemsPerPage: 10,
      activePage: 1,
    }, () => {
      if (e === true) {
        if (this.state[`${res}`] && Array.isArray(this.state[`${res}`]) && this.state[`${res}`].length > 0) {
          if (!finalString || finalString === '' || finalString === null || finalString === undefined) {
            finalString = `${res}=${encodeURIComponent(this.state[`${res}`])}&`;
          } else {
            finalString += `${res}=${encodeURIComponent(this.state[`${res}`])}&`;
          }
          queryString.stringify(finalString);
          this.setState({
            finalString,
          }, () => {
            // this.props.history.push({
            //   pathname: '/app/campus/browse/companies',
            //   search: `?${this.state.finalString}`,
            // });
            if (mainVals === null || mainVals === undefined || !mainVals) {
              this.handleSubmitFilterVals(res);
            }
          });
        }
      } else if (e === false) {
        const filteredList = this.state[`${res}`].filter(v => v != key);
        this.setState({
          [`${res}`]: filteredList,
        }, () => {
          if (mainVals === null || mainVals === undefined || !mainVals) {
            this.handleSubmitFilterVals(res);
          }
          const value = this.state[`${res}`];
          if (value && value !== undefined && value !== null && Array.isArray(value) && value.length > 0) {
            finalString += `${res}=${encodeURIComponent(this.state[`${res}`])}&`;
          } else {
            const str = `${res}=${encodeURIComponent(key)}&`;
            finalString = finalString && finalString.replace(str, '');
          }
          this.setState({
            finalString,
          }, () => {
            // this.props.history.push({
            //   pathname: '/app/campus/browse/companies',
            //   search: `?${this.state.finalString}`,
            // });
          });
        });
      } else if (mainVals === null || mainVals === undefined || !mainVals) {
        this.handleSubmitFilterVals(res);
      }
    });
  }

  handleSelectClearAll = (res, isSelectAll) => {
    const {
      mainVals,
    } = this.state;
    const { facets } = this.props;
    if (isSelectAll) {
      const filterVals = [];
      facets[`${res}`].map((item) => {
        filterVals.push(item.key)
      })

      this.setState({
        [`${res}`]: filterVals,
        itemsPerPage: 10,
        activePage: 1,
      }, () => {
        if (mainVals === null || mainVals === undefined || !mainVals) {
          this.handleSubmitFilterVals(res);
        }
      });
    }else{
      this.setState({
        [`${res}`]: [],
        itemsPerPage: 10,
        activePage: 1,
      }, () => {
        if (mainVals === null || mainVals === undefined || !mainVals) {
          this.handleSubmitFilterVals(res);
        }
      });
    }

  }

  handleSubmitFilterVals = (res) => {
    const finaldata = this.state;
    this.props.fetchAllCompaniesList(finaldata);
    // this.props.fetchAllCompaniesFacets(null);
  }

  handleRedirectToRegistration = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/app/campus/registration/company');
    }
  }

  handleRedirectToCompView = (id) => {
    const {
      history: {
        push,
      } = {},
      user: {
        roleId,
      } = {},
    } = this.props;
    if (push && roleId && Number(roleId) !== 3) {
      push(`/app/campus/company/info/${id}`);
    }
  }

  activeMoreModal = (keys) => {
    this.setState({
      isOpenMoreModal: true,
      mainVals: keys,
    });
  }

  dismissMoreModal = () => {
    this.setState({
      isOpenMoreModal: false,
      mainVals: null,
    });
  }

  handleSubmitCheckBox = () => {
    this.handleSubmitFilterVals();
    this.dismissMoreModal();
  }

  handleAcceptRejectManagedCompanies = () => {
    const {
      user: {
        companyId: currCompId,
      } = {},
    } = this.props;
    const {
      companyId, status,
    } = this.state;
    const data = {
      parentCompanyId: companyId,
      childCompanyId: currCompId,
      status,
    };
    this.props.authorizeCompany(data).then((res) => {
      if (res && !res.error) {
        const finaldata = this.state;
        this.dismissAcceptCompModal();
        this.dismissRejectCompModal();
        this.props.fetchAllCompaniesList(finaldata);
        this.props.fetchAllCompaniesFacets(finaldata);
      }
    });
  }

  activeAcceptCompModal = (companyId, status) => {
    this.setState({
      isOpenAcceptCompanyModal: true,
      companyId,
      status,
    });
  }

  dismissAcceptCompModal = () => {
    this.setState({
      isOpenAcceptCompanyModal: false,
      companyId: 0,
      status: '',
    });
  }

  activeRejectCompModal = (companyId, status) => {
    this.setState({
      isOpenRejectCompanyModal: true,
      companyId,
      status,
    });
  }

  dismissRejectCompModal = () => {
    this.setState({
      isOpenRejectCompanyModal: false,
      companyId: 0,
      status: '',
    });
  }

  batchActionClickHandler = (actionItems, actionState) => {
    if (actionState === "email") {
      const companyIds = actionItems.map(({ id = '' }) => Number(id.split('_')[1]));
      this.setState({ selectTemplateModal: true, companyIds })
    }
    if (actionState === "email-all") {
      const data = {
        itemsPerPage: 1000,
        activePage: 1,
      };
      this.props.fetchAllCompaniesList(data).then(({ companies }) => {
        const companyIds = companies.map(({ _id: id }) => Number(id));
        this.setState({ selectTemplateModal: true, companyIds })
      });
    }
  }

  templateModalSubmit = () => {
    const { selectedEmailTemplate, companyIds } = this.state;
    if (selectedEmailTemplate === null || selectedEmailTemplate.indexOf('placeholder-item') > -1) {
      this.setState({ noTemplateError: true });
      return;
    }
    if (Array.isArray(companyIds) && companyIds.length === 0) {
      this.setState({
        selectedEmailTemplate: 'placeholder-item',
        selectTemplateModal: false,
        companyIds: [],
        noTemplateError: false,
      });
      return;
    }
    const finalEmailTemplate = selectedEmailTemplate.split('_')[1];
    const data = {
      companyIds: companyIds,
      templateName: finalEmailTemplate
    }
    this.props.sendEmailToCompanies(data).then((res) => {
      if (res && !res.error) {
        this.setState({
          showSuccessMsg: true,
        }, () => {
          setTimeout(() => {
            this.templateModalClose();
          }, 5000);
        });
      } else if (res && res.error) {
        this.setState({
          noTemplateError: true,
        });
      }
    })
  }

  templateModalClose = () => {
    this.setState({
      showSuccessMsg: false,
      selectedEmailTemplate: 'placeholder-item',
      selectTemplateModal: false,
      companyIds: [],
      noTemplateError: false,
    })
  }

  onCustomTemplatePreview = () => {
    const { selectedEmailTemplate = '' } = this.state;
    if (selectedEmailTemplate !== null && selectedEmailTemplate !== undefined && selectedEmailTemplate !== '' && selectedEmailTemplate !== 'placeholder-item') {
      const previewEmailName = selectedEmailTemplate.split('_')[1];
      const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
      window.open(`/emailpreview/${previewEmailName}`, 'Email Preview', params);
    }
  }

  onEmailTemplateChange = (e) => {
    const selectedEmailTemplate = e.target.value;
    const finalEmailTemplate = e.target.value.split('_')[1]
    this.setState({ selectedEmailTemplate, finalEmailTemplate, noTemplateError: false });
  }


  handleCheckChange = (batchId, batchName, e) => {
    const {checkedItems} = this.state;
    const checkedValues = {...checkedItems};

    if(e === true){
      checkedValues[batchId] = batchName
      this.setState({
        checkedItems: checkedValues
      })
    }
    else{
      this.setState({
        checkedItems: null
      });
    }
  };

  // onSortValueChange = value => {
  //   console.log(value)
  // this.setState(
  //   {
  //     selectedSort: value,
  //   },
  //   () => {
  //     const sortVal = value.indexOf('Asc') > 0 ? 'ASC' : 'DESC';
  //     const propName =
  //       value.indexOf('Asc') > 0
  //         ? value.replace('Asc', '')
  //         : value.replace('Dsc', '');
  //     const qs = `&filter[limit]=${this.state.itemsPerPage}&filter[skip]=0&searchKey=${this.state.keyword}&status=${this.state.status}&filter[order][0]=${propName}%20${sortVal}`;
  //     this.props.filterJobData(qs).then(res => {
  //       this.setState({ activePage: 1 });
  //     });
  //   }
  // );
  // };

  render() {
    const {
      companies, loading, count, t,
      compLoading,
      user: {
        userId,
        isSuperAdmin, isAdmin, company: { ctypeId } = {},
        roleId,
      } = {},
      facets,
      emailTemplates
    } = this.props;

    const {
      keyword, activePage, isOpenMoreModal, mainVals,
      isOpenAcceptCompanyModal, isOpenRejectCompanyModal, defaultCheckList,
      selectTemplateModal, selectedEmailTemplate, noTemplateError, showSuccessMsg,
      isOpenExportCompanyModal,batches
      // selectedSort
    } = this.state;

    const {checkedItems} = this.state;
    const checkedValues = {...checkedItems};
    const checked = Object.values(checkedValues);

    const keys = facets && Object.keys(facets);
    if (keys && Array.isArray(keys)) {
      keys.sort();
    }
    const finalEmailTemplates = emailTemplates && Array.isArray(emailTemplates)
      && emailTemplates.length > 0
      && emailTemplates.filter(res => res && res.ownerId && Number(res.ownerId) === (userId && Number(userId)));


    return (
      <Fragment>
        {
          roleId && Number(roleId) !== 3
          && (
            <BackRow
              paths={this.paths}
            />
          )
        }
        {
          roleId && Number(roleId) === 3
          && (
            <BackRow
              paths={this.candPaths}
            />
          )
        }
        <div className="h6 mb-2 mt-2">{t('browseCompanies')}</div>
        <div className="companies-layout">
          <Layout
            header={
              <div></div>
            }
            bodyHeader={
              <div className="meta d-flex justify-content-between w-100 align-items-center">
                <div>
                  <PagingInfo
                    end={Math.min(
                      Number(count),
                      Number(this.state.itemsPerPage) *
                      Number(this.state.activePage)
                    )}
                    start={
                      1 +
                      Number(this.state.activePage - 1) *
                      Number(this.state.itemsPerPage)
                    }
                    totalResults={Number(count)}
                  />
                </div>

                {/* <div>
                  <Select
                    light
                    className="bx--col-xs-12 p-0"
                    hideLabel
                    invalidText="A valid value is required"
                    onChange={e => this.onSortValueChange(e.target.value)}
                    id="select-1"
                    defaultValue="placeholder-item"
                    value={selectedSort}>
                    <SelectItem
                      value="placeholder-item"
                      disabled
                      hidden
                      text={t('sortBy')}
                    />
                    <SelectItem
                      value="companyName.asc"
                      text={`${t('Company Name')}`}
                    />
                  </Select>
                </div> */}
              </div>
            }

            sideContent={
              (defaultCheckList != undefined && defaultCheckList != null) ?
                <FacetsBrowse
                  handleClearFilters={this.handleClearFilters}
                  handleChangeCheckBoxVals={this.handleChangeCheckBoxVals}
                  values={this.state}
                  showAlteredFacetsLabels={false}
                  facets={facets}
                  keys={keys}
                  activeMoreModal={this.activeMoreModal}
                  handleSubmitCheckBox={this.handleSubmitCheckBox}
                  dismissMoreModal={this.dismissMoreModal}
                  isOpenMoreModal={isOpenMoreModal}
                  mainVals={mainVals}
                  defaultCheckList={defaultCheckList}
                  handleSelectClearAll={this.handleSelectClearAll}
                  isCompanyFilter
                /> : null
            }

            bodyContent={
              <div className="">
                <BrowseCompInst
                  isSuperAdmin={isSuperAdmin}
                  isAdmin={isAdmin} ctypeId={ctypeId}
                  handleSearchSubmit={this.handleSearchSubmit}
                  activeRejectCompModal={this.activeRejectCompModal}
                  activeAcceptCompModal={this.activeAcceptCompModal}
                  data={companies}
                  handleRedirectToCompView={this.handleRedirectToCompView}
                  batchActionClickHandler={this.batchActionClickHandler}
                  count={count}
                  handleExportCompanies={this.handleExportCompanies}
                  activeExportCompModal={this.activeExportCompModal}
                  dismissExportCompModal={this.dismissExportCompModal}
                  isOpenExportCompanyModal={isOpenExportCompanyModal}
                  handleCheckChange={this.handleCheckChange}
                  batches={batches}
                  state={this.state}
                  checked={checked}
                />
                {
                  companies && Array.isArray(companies)
                  && companies.length > 0
                  && (
                    <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
                  )
                }
                {
                  (!companies || (companies && Array.isArray(companies)
                    && companies.length <= 0))
                  && (
                    <NoCompaniesBox t={t} />
                  )
                }
              </div>
            }
          />
        </div>

        <Modal
          onRequestSubmit={this.templateModalSubmit}
          onRequestClose={this.templateModalClose}
          open={selectTemplateModal}
          modalLabel="Email Templates"
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading="Send Mail"
        >
          <div className="bx--modal-content__text">
            {
              showSuccessMsg && <InlineNotification lowContrast kind="success" title="Email Sent Successfully" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showSuccessMsg: false })} />
            }
            {
              noTemplateError && <InlineNotification lowContrast kind="error" title="No template selected" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ noTemplateError: false })} />
            }
            <Select
              labelText="Select Email Template"
              className="mb-4"
              id="email-templates-list"
              onChange={this.onEmailTemplateChange}
              value={selectedEmailTemplate}
              // placeholder="Choose a template"
              defaultValue="placeholder-item"
            >
              <SelectItem disabled value="placeholder-item" text="Choose a template" />
              {
                finalEmailTemplates && Array.isArray(finalEmailTemplates)
                && finalEmailTemplates.map(({ displayName = '', id = null, templateName = '' }) => (<SelectItem key={id} name={templateName} label={displayName} value={`${id}_${templateName}`} />))
              }
            </Select>
            <Fragment>
              <span className="pt-0 pb-1 text-dark d-block">
                {t('clickingOn')} <strong>{t('submit')}</strong> {t('clickingOn2')}&nbsp;
                <span className="xpa-link" onClick={this.onCustomTemplatePreview}>
                  Click here
                </span>{' '}
                to view content of the email.
              </span>
            </Fragment>
          </div>
        </Modal>


        {/* <div className="bx--row">
          <div className="bx--col-lg-4 bx--col-md-12 bx--col-sm-12">
            {(defaultCheckList != undefined && defaultCheckList != null) ?
              <FacetsBrowse
                handleClearFilters={this.handleClearFilters}
                handleChangeCheckBoxVals={this.handleChangeCheckBoxVals}
                values={this.state}
                showAlteredFacetsLabels={false}
                facets={facets}
                keys={keys}
                activeMoreModal={this.activeMoreModal}
                handleSubmitCheckBox={this.handleSubmitCheckBox}
                dismissMoreModal={this.dismissMoreModal}
                isOpenMoreModal={isOpenMoreModal}
                mainVals={mainVals}
                defaultCheckList={defaultCheckList}
              /> : null
            }

          </div>
          <div className="bx--col-lg-8 bx--col-md-12 bx--col-sm-12">
            <div className="bx--row"> */}
        {/* <div className="mt-1 bx--col-lg-11 justify-content-between bx--col-md-6 bx--col-sm-12 d-flex">
                <div>
                  <Search
                    light
                    type="text"
                    labelText={`${t('search')} ${t('company')}`}
                    closeButtonLabelText=""
                    placeHolderText={`${t('search')} ${t('company')}`}
                    id="search company"
                    value={keyword}
                    onChange={this.handleSearchSubmit}
                  />
                </div>
              </div> */}
        {/* <div className="bx--col-lg-11 bx--col-md-12 bx--col-sm-12">
                <BrowseCompInst isSuperAdmin={isSuperAdmin} handleSearchSubmit={this.handleSearchSubmit} activeRejectCompModal={this.activeRejectCompModal} activeAcceptCompModal={this.activeAcceptCompModal} data={companies} handleRedirectToCompView={this.handleRedirectToCompView} />
                {
                  companies && Array.isArray(companies)
                  && companies.length > 0
                  && (
                    <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
                  )
                }
                {
                  (!companies || (companies && Array.isArray(companies)
                    && companies.length <= 0))
                  && (
                    <NoCompaniesBox t={t} />
                  )
                }
              </div>
            </div>
          </div>
        </div> */}
        <Modal
          open={isOpenAcceptCompanyModal}
          shouldSubmitOnEnter
          modalHeading={t('accept')}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissAcceptCompModal}
          onRequestSubmit={this.handleAcceptRejectManagedCompanies}
        >
          <div className="text-dark text-left">
            {t('acceptManagedCompMsg')}
          </div>
        </Modal>
        <Modal
          open={isOpenRejectCompanyModal}
          shouldSubmitOnEnter
          modalHeading={t('reject')}
          danger
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissRejectCompModal}
          onRequestSubmit={this.handleAcceptRejectManagedCompanies}
        >
          <div className="text-dark text-left">
            {t('rejectManagedCompMsg')}
          </div>
        </Modal>
        <JDProcessOverlay show={loading || compLoading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  companies: state.BrowseCompanies.companies,
  locations: state.BrowseCompanies.locations,
  count: state.BrowseCompanies.count,
  facets: state.BrowseCompanies.facets,
  loading: state.BrowseCompanies.loading,
  compLoading: state.companyAndOffice.loading,
  emailTemplates: state.emailTemplates.templates,
});

const mapDispatchToProps = {
  getCustomEmailTemplates,
  fetchAllCompaniesList,
  fetchAllCompaniesFacets,
  authorizeCompany,
  sendEmailToCompanies,
  getBatchDetails,
  getCompaniesExport
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BrowseCompanies));
