import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  Tile, Pagination, Button, Tag,
} from 'carbon-components-react';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import { Mixpanel } from 'components/common/Mixpanel';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import ApplicantCard from 'components/roboroy/common/ApplicantCard';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

import {
  searchGlobally,
} from 'actions/globalSearch';

import {
  talentPoolCard,
  jobCard,
  feedBackCard,
} from 'containers/roboroy/RoboroyCards';

const NoResultsBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no results found for your current search.</p>
    </div>
  </Tile>
);

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      selectedTabName: 'job',
      activePage: 1,
      limit: 10,
      skip: 0,
      rowsData: [],
      headersInfo: [{ key: 'result', header: 'Results' }],
      searchKey: '',
      count: 0,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {
          key = '', name = '', limit = 10, page = 1,
        } = {},
      } = {},
    } = this.props;
    if (key === '' || key === null) return;
    const skip = page > 1 ? (page - 1) * limit : 0;
    switch (name) {
      case 'job': this.setState({
        selectedTab: 0, searchKey: key, selectedTabName: 'job', limit, activePage: page, skip,
      }, () => this.switchTab('job')); break;
      case 'application': this.setState({
        selectedTab: 1, searchKey: key, selectedTabName: 'application', limit, activePage: page, skip,
      }, () => this.switchTab('application')); break;
      case 'feedback': this.setState({
        selectedTab: 2, searchKey: key, selectedTabName: 'feedback', limit, activePage: page, skip,
      }, () => this.switchTab('feedback')); break;
      case 'talentPool': this.setState({
        selectedTab: 3, searchKey: key, selectedTabName: 'talentPool', limit, activePage: page, skip,
      }, () => this.switchTab('talentPool')); break;
      default: break;
    }
  }

  handlePageChange = (data) => {
    const activePage = (data.page) || 1;
    const limit = data.pageSize || 10;
    const skip = activePage > 1 ? (activePage - 1) * 10 : 0;
    this.setState({ activePage, limit, skip }, () => {
      const { selectedTabName, searchKey } = this.state;
      this.props.history.push(`/app/campus/search/${encodeURIComponent(searchKey)}/type/${selectedTabName}/page/${activePage}/limit/${limit}`);
      this.switchTab(selectedTabName);
    });
  }

    applicationCard = application => (
      <ApplicantCard application={application} history={this.props.history}/>
    )

    switchTab = (tabName) => {
      const { searchGlobally } = this.props;
      const {
        limit = 10, searchKey = '', skip = 0, activePage = 1,
      } = this.state;
      if (searchKey === '' || searchKey === null) return;
      const data = {
        limit,
        key: searchKey,
        type: tabName,
        skip,
      };
      searchGlobally(data).then((res) => {
        switch (tabName) {
          case 'job': {
            this.props.history.push(`/app/campus/search/${encodeURIComponent(searchKey)}/type/job/page/${activePage}/limit/${limit}`);
            const { job: { count = 0, result = [] } = {} } = res;
            const rowsData = result !== null ? result.map((one) => {
              const { dashboard } = one;
              const RowData = {
                id: one.jobId,
                openDate: one.openDate,
                title: one.jobname.nameName,
                function: one.jobfunction.functionName,
                seniority: one.jobseniority.seniorityName,
                country: one.country ? one.country.countryFull : '',
                hourtype: one.hourtype.typeName,
                term: one.term.termName,
                total: (dashboard && dashboard.totalApplications) || 0,
                shortlisted: (dashboard && dashboard.numOfShortlistedApplications) || 0,
                rejected: (dashboard && dashboard.numOfRejectedApplications) || 0,
              };
              return ({ id: (one.jobId).toString(), result: jobCard(RowData, true) });
            }) : [];
            this.setState({
              rowsData, selectedTab: 0, count, selectedTabName: 'job',
            });
          } break;
          case 'application': {
            this.props.history.push(`/app/campus/search/${encodeURIComponent(searchKey)}/type/application/page/${activePage}/limit/${limit}`);
            const { application: { count = 0, result = [] } = {} } = res;
            const rowsData = result !== null ? result.map(applicant => ({ id: (applicant.appId).toString(), result: this.applicationCard(applicant) })) : [];
            this.setState({
              rowsData, selectedTab: 1, count, selectedTabName: 'application',
            });
          } break;
          case 'feedback': {
            this.props.history.push(`/app/campus/search/${encodeURIComponent(searchKey)}/type/feedback/page/${activePage}/limit/${limit}`);
            const { feedback: { count = 0, result = [] } = {} } = res;
            const rowsData = result !== null ? result.map((row) => {
              const {
                userInfo = {}, profile = {}, workflowstage = {}, workflowappstageuserfeedback = {},
                jobname = {},
              } = row;
              const RowData = {
                id: row.appStageUserFeedbackId,
                firstName: userInfo.firstname || '',
                lastName: userInfo.lastname || '',
                picture: userInfo && userInfo.picture ? userInfo.picture : null,
                jobName: jobname.nameName || '',
                jobId: row.jobId,
                stage: workflowstage.stageName || '',
                candidateName: Object.keys(profile).length > 0 ? `${profile.firstName !== null ? profile.firstName : ''} ${profile.lastName !== null ? profile.lastName : ''}` : '',
                candidateId: profile.profileId || '',
                comment: workflowappstageuserfeedback ? workflowappstageuserfeedback.feedback : '',
                rating: workflowappstageuserfeedback.rating ? workflowappstageuserfeedback.rating : 0,
              };
              return ({ id: row.appStageUserFeedbackId.toString(), result: feedBackCard(RowData) });
            }) : [];
            this.setState({
              rowsData, selectedTab: 2, count, selectedTabName: 'feedback',
            });
          } break;
          case 'talentPool': {
            this.props.history.push(`/app/campus/search/${encodeURIComponent(searchKey)}/type/talentPool/page/${activePage}/limit/${limit}`);
            const { talentPool: { count = 0, result = [] } = {} } = res;
            const rowsData = result !== null ? result.map((row) => {
              const { we = [], currency: { currencyShort = '' } = {}, salary } = row;
              const com = we.map((e) => {
                const {
                  startDate = '-',
                  endDate = '-',
                  job: {
                    company: {
                      companyName = '-',
                    } = {},
                    jobfunction: {
                      functionName = '-',
                    } = {},
                    jobseniority: {
                      seniorityName = '-',
                    } = {},
                    qualificationtype: {
                      typeName = '-',
                    } = {},
                  } = {},
                } = e;
                return {
                  startDate,
                  endDate,
                  company: companyName,
                  function: functionName,
                  seniority: seniorityName,
                  qualification: typeName,
                };
              });
              const {
                firstName = '', lastName = '', totalExp = 0, address = [], personDocuments = null, buckets = [],
              } = row;
              const currCompany = com[0];
              const RowData = {
                firstName, lastName, totalExp, address, salary, currencyShort, currCompany, personDocuments, buckets, id: row.profileId,
              };
              return { id: (row.profileId).toString(), result: talentPoolCard(RowData, { hideButtons: true }) };
            }) : [];
            this.setState({
              rowsData, selectedTab: 2, count, selectedTabName: 'talentPool',
            });
          } break;
          default: break;
        }
      });
    }

    render() {
      const {
        selectedTab, activePage, rowsData,
        headersInfo, count, limit = 10,
        searchKey = '',
      } = this.state;

      const { loading } = this.props;

      const tabContent = [
        {
          label: 'Jobs',
          value: '',
          onClick: () => this.setState({ skip: 0, limit: 10, activePage: 1 }, () => this.switchTab('job')),
        },
        {
          label: 'Applications',
          value: '',
          onClick: () => this.setState({ skip: 0, limit: 10, activePage: 1 }, () => this.switchTab('application')),
        },
        // {
        //   label: 'Feedback',
        //   value: '',
        //   onClick: () => this.setState({ skip: 0, limit: 10, activePage: 1 }, () => this.switchTab('feedback')),
        // },
        {
          label: 'Talent Pool',
          value: '',
          onClick: () => this.setState({ skip: 0, limit: 10, activePage: 1 }, () => this.switchTab('talentPool')),
        },
      ];
      return (
        <Fragment>
          <div className="x0pa_search">
            <h4 className="bx--type-gamma font-weight-bold">{`Search results for "${searchKey}"`}</h4>
            <GenericTabs selected={selectedTab} tabContent={tabContent} />
            <GenericDataTable
              rows={rowsData}
              noHeaders
              tableStyle={{ borderSpacing: '0px 6px', borderCollapse: 'separate' }}
              trClass="m-0 p-0"
              tdClass="m-0 p-0"
              tbodyClass="bg-transparent"
              headers={headersInfo}
              rowClass="text-center p-0 bx--col-12"
              extras={{ result: { className: 'p-0' } }}
            />
            {count > 0 && <Pagination onChange={this.handlePageChange} pageSizes={[10, 20, 30, 40, 50]} page={Number(activePage)} totalItems={count} />}
            {count === 0 && <NoResultsBox />}
            <JDProcessOverlay show={loading === true} message="processing..." />
          </div>
        </Fragment>);
    }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  searchRes: state.globalSearch.results,
  loading: state.globalSearch.loading,
});

export default connect(mapStateToProps, {
  push,
  searchGlobally,
})(SearchPage);
