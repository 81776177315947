import { withTranslation } from 'react-i18next';
import React from 'react';
import { Tile } from 'carbon-components-react';

const NoSubscription = () => {
  console.log('No active subscriptions');
  return (
    <div className="no-subscriptions">
      <Tile className="">
        <div className="card-body text-center ">
          <div className="empty-icon">
            <i className="h1 icon-flag" />
          </div>
          <p className="empty-title h5">No active subscriptions found.</p>
          <p className="empty-subtitle mb-0">Or you might not have subscription to this particular module.</p>
          <p className="empty-subtitle">Please contact signup@x0pa.com.</p>
        </div>
      </Tile>
    </div>
  );
};

export default NoSubscription;
