import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import {
  TextInput, Button, SelectItem, Select,
  InlineNotification, Loading,
} from 'carbon-components-react';
import DropDownBox from 'components/common/dropdown/Dropdown.component';

import {
  getDocumentTypes,
  getAllDocuments,
  uploadDocument,
  deleteDocument,
} from 'actions/uploadAllDocuments';
import { uploadCampusDocs } from 'actions/userProfile/action';
import {
  getCandidateProfile,
} from 'actions/talentSearch/candidatesList';
import './uploadAllDocuments.css';

class UploadAllDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileTypes: [],
      files: [],
      maxFiles: 10,
      showNotifError: false,
    };
  }

  componentDidMount() {
    this.props.getDocumentTypes().then((res) => {
      const {
        candidateProfile: {
          profile: {
            profileId,
          } = {},
        } = {},
      } = this.props;
      const docs = res && Array.isArray(res)
      && res.length > 0 && res.filter(v => v.typeId === 10);
      const fileTypes = Array.isArray(docs) && docs.map(({ typeId, typeName }) => ({ value: typeId, label: typeName }));
      this.props.getCandidateProfile(profileId).then((det) => {
        if (det && !det.error) {
          const {
            candidateProfile,
          } = this.props;
          const {
            documents,
          } = candidateProfile;
          this.setState({ fileTypes, documents });
        }
      });
    })
      .catch(e => console.log('Error:::::', e));
  }

  componentWillReceiveProps(nextProps) {
    const {
      candidateProfile,
    } = nextProps;
    const {
      documents,
    } = candidateProfile;
    if (this.props.candidateProfile !== nextProps.candidateProfile) {
      this.setState({
        documents,
        maxFiles: 10 - documents && Array.isArray(documents) && documents.length,
      });
    }
  }

  handleUploadFile = (element) => {
    const { files } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    let { index } = this.state;
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    if (!this.state[`textInput_${index}`]) {
      this.setState({
        showNotifError: true,
      });
    } else {
      const { candidateProfile: { profile: { profileId } = {} } = {} } = this.props;
      // if (fileName === null || selectedType === null || profileId === null) return;
      const fileData = element.target.files;
      const currFile = new FormData();
      currFile.append('file', fileData[0]);
      this.props.uploadCampusDocs(currFile).then(({ result: { files = {} } = {} }) => {
        const { file: [fileData = {}] = [] } = files || {};
        const { providerResponse: { location: subLocation } = {} } = fileData !== null ? fileData : {};
        this.props.uploadDocument({
          typeId: 11,
          fileName: this.state[`textInput_${index}`],
          subLocation,
          profileId,
          uploadTimestamp: new Date(),
        }).then(() => {
          this.props.getCandidateProfile(profileId);
          this.onDeleteFile();
          this.setState({
            files: [],
            showNotifError: false,
          });
        });
      }).catch(err => console.log(err));
    }
  }

  handleTypeChange = (selectedOption, value, name) => {
    const { files = null } = this.state;
    if (value !== null && name !== null && Array.isArray(files) && files.length > Number(name)) {
      files[name].selectedType = value;
      files[name].invalidType = false;
      this.setState({ files }, () => {
      });
    }
  };

  // handleChange = ({ target: { value = null, name = null } = {} } = {}) => {
  //   const { files = null } = this.state;
  //   if (value !== null && name !== null && Array.isArray(files) && files.length > Number(name)) {
  //     files[name].fileName = value;
  //     files[name].invalidFileName = false;
  //     this.setState({ files });
  //   }
  // }

  handleChangeInputValues = (e, index) => {
    this.setState({
      [`textInput_${index}`]: e.target.value,
      index: index,
    }, () => {
      if (this.state[`textInput_${index}`] === undefined
        || this.state[`textInput_${index}`] === null
        || this.state[`textInput_${index}`] === '') {
        this.setState({
          isValidTextInput: true,
        });
      } else {
        this.setState({
          isValidTextInput: false,
        });
      }
    });
  }

  handleChange = (e, index) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      this.setState({
        [`selectedVal_${index}`]: this.state.documentType,
      });
    });
  };

  onDeleteFile = ({ target: { name = null } = {} } = {}) => {
    const { files = null } = this.state;
    const {
      index,
    } = this.state;
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      const { personDocId = null } = files[name];
      if (personDocId !== null) {
        this.props.deleteDocument(personDocId).then(() => {
          files.splice(Number(name), 1);
          this.setState({ files });
        });
      } else {
        files.splice(Number(index), 1);
        this.setState({ files });
      }
    }
  }

  onAddFile = () => {
    const { files = null } = this.state;
    const fileData = {
      selectedType: null,
      fileName: null,
      personDocId: null,
    };
    if (Array.isArray(files)) {
      files.push(fileData);
      this.setState({ files });
    }
  }

  handleDeleteDoc = (personDocId) => {
    const {
      user: {
        profileId,
      } = {},
    } = this.props;
    this.props.deleteDocument(personDocId).then(() => {
      this.props.getCandidateProfile(profileId);
    })
      .catch(e => console.log('Error:::::', e));
  }

  render() {
    const { fileTypes, files = null, maxFiles = 10, documents, showNotifError } = this.state;
    const { t } = this.props;
    const hostName = window.location.hostname;
    let link = '';
    if (hostName.includes('internship-placement.rp.edu.sg')) {
      link = 'https://x0pa-rp-storage.s3-ap-southeast-1.amazonaws.com';
    } else {
      link = 'https://x0pa-rps-storage.s3-ap-southeast-1.amazonaws.com';
    }
    return (
      <div className="mb-3 mt-2">
        {
          showNotifError && (
            <InlineNotification lowContrast kind="error" title="" subtitle={t('validationUploading')} />
          )
        }
        {
          ((!documents) || (documents
              && Array.isArray(documents)
              && documents.length <= 0))
              && (
                <div className="d-flex justify-content-center">
                  <Loading withOverlay={false} />
                </div>
              )
        }
        {
          documents && Array.isArray(documents)
            && documents.length >= 0
            && documents.map((res) => {
              const {
                fileName,
                documenttype: {
                  typeName,
                } = {},
                personDocId,
                typeId,
                cvLink,
              } = res || {};
              return (
                <Fragment>
                  {
                    (typeId === 1)
                      && (typeName === 'Student Upload' || typeName == 'Resume/CV')
                      && (
                        <div className="bx--row justify-content-center mb-2">
                          <div className="mr-2  ">
                            <TextInput
                              className="w-100 upload-docs-min-width mb-2"
                              value={typeName}
                              placeholder={t('docName')}
                              disabled
                              hideLabel={false}
                              name={typeName}
                            />
                          </div>
                          <div className="ml-2 bx--col-lg-4">
                            <a target="_blank" href={`/view/${cvLink}/cv?_=${Date.now()}`}>
                              <Button size="small" kind="tertiary">
                                {t('view')} {t('file')}
                              </Button>
                            </a>
                          </div>
                        </div>
                      )
                  }
                  {
                    fileName && typeName === 'Student Upload' && (
                      <div className="bx--row justify-content-center mb-2">
                        {/* <div className="mr-2  ">
                          <TextInput
                            className="w-100 upload-docs-min-width mb-2"
                            value={typeName}
                            placeholder={t('docName')}
                            disabled
                            hideLabel={false}
                            name={typeName}
                          />
                        </div> */}
                        <div className="mr-2">
                          <TextInput
                            className="w-100 upload-docs-min-width mb-2"
                            value={fileName}
                            disabled
                            placeholder={t('docName')}
                            hideLabel={false}
                            name={fileName}
                          />
                        </div>
                        {
                          fileName && this.props.disabled !== true && (
                            <div className="">
                              <Button size="small" onClick={() => this.handleDeleteDoc(personDocId)} kind="danger">{t('delete')}</Button>
                            </div>
                          )
                        }
                        <div className="ml-2">
                          <a target="_blank" href={`/view/${cvLink}/cv?_=${Date.now()}`}>
                            <Button size="small" kind="tertiary">
                              {t('view')} {t('file')}
                            </Button>
                          </a>
                        </div>
                      </div>
                    )
                  }
                </Fragment>
              );
            })
        }
        {Array.isArray(files) && files.length > 0 && documents && Array.isArray(documents)
        && documents.length <= 9 && (
          files.map(({
            selectedType = null,
            fileName = null,
            personDocId = null,
          }, index) => (
            <div className="bx--row ml-2 justify-content-center">
              <div className="bx--row">
                {/* <div className="mb-lg-none  ">
                  <Select
                    id="email-templates-list"
                    defaultValue="placeholder-item"
                    hideLabel
                    className="w-100 upload-docs-min-width mb-2"
                    onChange={(e) => { this.handleChange(e, index); }}
                    name="documentType"
                  >
                    <SelectItem
                      key="id"
                      label="Select one option"
                      disabled
                      value="placeholder-item"
                    />
                    {
                      fileTypes && Array.isArray(fileTypes)
                        && fileTypes.length > 0
                        && fileTypes.map(res => (
                          <SelectItem
                            key={res.value}
                            label={res && res.label}
                            value={res && res.value}
                          />
                        ))
                    }
                  </Select>
                </div> */}
                <div className="ml-lg-2 mb-lg-none  ">
                  <TextInput
                    className="w-100 upload-docs-min-width mb-2"
                    value={fileName}
                    placeholder={t('docName')}
                    onChange={(e) => { this.handleChangeInputValues(e, index); }}
                    hideLabel={false}
                    name={index}
                  />
                </div>
              </div>
              <div className="ml-4">
                <Button size="small" onClick={this.onDeleteFile} name={index} kind="danger">{t('delete')}</Button>
              </div>
              {personDocId === null && (
                <div className="ml-2">
                  <input
                    type="file"
                    className="bx--file-input"
                    id={`upload-${index + 1}`}
                    name={`upload-${index + 1}`}
                    accept=".doc,.docx,.pdf"
                    onChange={(e) => this.handleUploadFile(e)}
                  />
                  <div className="bx--file h-auto mx-auto text-center newjob-upload-jd" data-file>
                    <label
                      id={`upload-${index + 1}`}
                      htmlFor={`upload-${index + 1}`}
                      className="bx--btn bx--btn--sm bx--btn--secondary uploadFile"
                      role="button"
                      tabIndex="0"
                    >
                      {t('upload')}
                    </label>
                    <input
                      type="file"
                      className="bx--file-input"
                      id={`upload-${index + 1}`}
                      accept=".doc,.docx,.pdf"
                      onChange={this.handleUploadFile}
                      name={`upload-${index + 1}`}
                      data-file-uploader
                      data-target="[data-file-container]"
                    />
                    <div data-file-container className="bx--file-container"></div>
                  </div>
                </div>
              )}
            </div>
          )))
        }
        {
          this.props.disabled !== true && (
            <div className="mt-2 d-flex justify-content-center">
              <Button size="small" kind="tertiary" small onClick={this.onAddFile} >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
                  <circle cx="12" cy="12" r="10" />
                  <line x1="12" y1="8" x2="12" y2="16" />
                  <line x1="8" y1="12" x2="16" y2="12" />
                </svg>
                <span className="ml-1">{t('addFile')}</span>
              </Button>
            </div>   
          )
        }
        {(Array.isArray(files) && files.length === maxFiles) ? <small className="d-flex justify-content-center mt-2 text-dark">We accept maximum {maxFiles} files.</small> : null}
      </div>
    );
  }
}

const mapStateToPorps = state => ({
  fileTypes: state.UploadDocuments.docs,
  user: state.x0paDuser.user,
  personDocs: state.UploadDocuments,
});

const dispatchToProps = {
  getDocumentTypes,
  uploadCampusDocs,
  getAllDocuments,
  uploadDocument,
  getCandidateProfile,
  deleteDocument,
};

export default connect(mapStateToPorps, dispatchToProps)(withTranslation()(UploadAllDocuments));
