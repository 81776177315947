import { withTranslation, useTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment, { max } from 'moment';
import './style.css';
import {
  TextInput,
  DataTable,
  Tabs,
  Tab,
  Button,
  Modal,
  Tile,
  InlineNotification,
  Toggle,
  Loading,
  Tag,
  Pagination,
  Search,
} from 'carbon-components-react';
//import { Mixpanel } from 'components/common/Mixpanel';
import SetMixPanel from 'components/common/GetCurrentPath';
import {
  getAllDropDownData,
  getFewDropDownData,
} from 'actions/candidate/CandidateDetails';

import { REQUEST_ALL_COUNTRY } from 'actions/actionTypes';

import queryString from 'query-string';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy//NewJob/JDProcessOverlay';
import LeftNavFilter from 'containers/common/leftNavFilter/leftNavFilter';
import { fetchAllPublicJobs } from 'actions/candidate/browse';
import {
  getCandidateProfile,
  getJobAppliedCount,
} from 'actions/talentSearch/candidatesList';

const { TableContainer, Table, TableRow, TableBody, TableCell } = DataTable;

const wrapperStyle = { width: 400, margin: 50 };

const NoJobsBox = ({ t }) => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">{t('noJobInSelection')}</p>
    </div>
  </Tile>
);

class CandidateBrowseJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      rowsData: [],
      headersInfo: [{ key: 'jobs', header: 'Job Details' }],
      activePage: 1,
      pageSize: 10,
      experience: [0, 2],
      search: '',
      selected: {},
      checked: [],
      Filter: [],
      paths: [
        { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
        { id: 'candidate-browse-jobs', name: 'Browse', href: '' },
      ],
      Countries: '',
      JobType: '',
      finlandProfileId: null,
      countryValue: { label: '', value: '' },
      jobAppliedCounts: ''
    };
  }

  componentDidMount() {
    const search =
      (this.props && this.props.location && this.props.location.search) || '';
    let checked = [];
    let parsedQuery = {
      filters: [],
    };
    const { user: { roleId, profileId } = {} } = this.props;
    if (search) {
      parsedQuery = queryString.parse(search);
    }
    if (parsedQuery && parsedQuery.filters) {
      try {
        parsedQuery.filters = JSON.parse(parsedQuery.filters);
      } catch (e) {
        parsedQuery.filters = [];
      }
    } else {
      parsedQuery = {
        filters: [],
      };
    }
    const { selected } = this.state;
    if (parsedQuery.filters) {
      for (let i = 0; i < parsedQuery.filters.length; i++) {
        for (let keyProp in parsedQuery.filters[i]) {
          selected[keyProp] = parsedQuery.filters[i][keyProp];
          checked = checked.concat(selected[keyProp]);
        }
      }
    }
    SetMixPanel();
    this.setState(
      { selected, Filter: parsedQuery.filters, checked, show: true },
      () => {
        this.prepareRowData();
      }
    );
    if (this.props.publicPage && !selected.JobType) {
      const { selected = [] } = this.state;
      selected.JobType = ['Open Jobs'];
      this.setState({ selected });
    }
    if (roleId && Number(roleId) === 3 && profileId) {
      this.props.getCandidateProfile(profileId);
      this.props.getJobAppliedCount(profileId).then((res) => {
        const { count: jobAppliedCount } = res || {};
        this.setState({
          jobAppliedCounts: jobAppliedCount
        })
      })
    }
    if (
      this.props.publicPage &&
      Array.isArray(this.state.Filter) &&
      this.state.Filter.length === 0
    ) {
      const { Filter = [], checked = [] } = this.state;
      Filter.push({ JobType: ['Open Jobs'] });
      checked.push('Open Jobs');
      this.setState({ Filter, checked });
    }
    this.props.getAllDropDownData({
      actionType: REQUEST_ALL_COUNTRY,
      apiName: 'Countries',
      value: '',
    });
  }

  CardRowBox = (
    {
      title,
      experience,
      company,
      location,
      skills,
      jobId,
      closeDate,
      maxSalary,
      commuteTime,
      choicesRecommendation,
      isJobVacancyAvailable,
      isClosed,
      canApplytoJob,
      totalApplications
    },
    t,
    offerAcceptedCount
  ) => {
    const currDate = moment(closeDate).isSameOrBefore(Date.now());
    return (
      <div className="pl-0 m-0 row align-items-center">
        <div className="jobData bx--col-lg-8 text-sm-center text-md-center text-center text-lg-left">
          <div className="p-0">
            <div className="d-flex align-items-center">
              <div>
                <a
                  className="xpa-link"
                  href={`/app/campus/job/${jobId}`}
                  style={{ color: 'inherit' }}>
                  <span
                    className="mb-1 bx--type-delta font-weight-bold text-uppercase xpa-link"
                    title={t('jobTitle')}>
                    {title}
                  </span>
                </a>
              </div>
              {choicesRecommendation === true && (
                <div className="bx--tag bx--tag--yellow ml-2">
                  {t('recommended')}
                </div>
              )}
            </div>
            <div
              className="mb-1 text-info bx--type-caps"
              style={{ color: '#5596e6' }}
              title={t('company')}>
              {company}
            </div>
            {skills.trim().length > 0 && (
              <div
                className="mb-1 font-weight-light bx--modal-header__label bx--type-delta"
                style={{ fontSize: 12 }}
                title={t('requiredSkill')}>
                <strong>{t('keySkills')}</strong>
                {` ${skills}`}
              </div>
            )}
            <div
              className="mb-1 font-weight-light bx--modal-header__label bx--type-delta"
              style={{ fontSize: 12 }}
              title={t('applicantsCount')}>
              <strong>{t('noOfApplicants')}</strong>
              {` ${totalApplications}`}
            </div>
            {/* <div className="col-2">
            { (rowData && rowData.companyImg && rowData.companyImg.length > 0)?
              <img className="" title="Company image" src={rowData.companyImg} style={{width:"80px",height:"80px",borderRadius:"50%"}}></img>
              :<img className="" title="Company image" src={require("assets/images/company.png")} style={{width:"80px",height:"80px",borderRadius:"50%"}}></img> }
            </div> */}
          </div>
          <div className="row">
            <div className="bx--col-lg-10 pl-0 text-black-50">
              {location && (
                <small className="col-8" title={t('location')}>
                  <i className="fa fa-map-marker xpa-f-sz--M" />
                  {`  ${location}`}
                </small>
              )}
              {/* {
                <small
                  className="col-8 xpa-f-sz--M"
                  title={t('workExperience')}>
                  <i className="fa fa-suitcase" />
                  {experience === '0 year'
                    ? t('fresher')
                    : `  ${experience} ${t('experience')}`}
                </small>
              } */}
            </div>
          </div>
          {/* <div className="text-muted">
            {closeDate != '' && (
              <small className="mt-1">
                <i className="fa fa-calendar" />
                {` ${t('closeDate')} : ${closeDate}`}
              </small>
            )}
          </div> */}
        </div>
        <div className="pl-md-3 pl-sm-3 pl-lg-3 pl-xl-0">
          {((isJobVacancyAvailable != null && isJobVacancyAvailable != undefined && isJobVacancyAvailable === false) ||
            (isClosed !== null && isClosed !== undefined && isClosed === true) ||
            // (canApplytoJob != null && canApplytoJob !== true) ||
            (this.props.offerAcceptedCount != null && this.props.offerAcceptedCount != undefined && this.props.offerAcceptedCount > 0)) ? (
            <div className="d-flex align-items-center">
              <div className="text-uppercase small font-weight-bold bx--tag bx--tag--red">
                job is currently unavailable
              </div>
            </div>
          ) : null}
          {maxSalary && (
            <div className="d-flex align-items-center">
              <div className="text-uppercase small font-weight-bold mr-2">
                Allowance:
              </div>{' '}
              <div className="bx--tag bx--tag--blue">{maxSalary}</div>
            </div>
          )}
          {/*  */}
          {/* {commuteTime && commuteTime > 0 ? ( */}
          <div className="d-flex align-items-center">
            <div className="text-uppercase small font-weight-bold mr-2">
              Estimated commute time:
              </div>{' '}
            <div className="bx--tag bx--tag--purple">
              {(commuteTime && commuteTime > 0) ? `${Math.round(commuteTime)} mins` : 'Not Available'}
            </div>
          </div>
          {/* ) : null} */}
        </div>
        {/* {
          (closeDate === null || closeDate === '' || !currDate) && (
            <div className="col-lg-2 p-0 flex-lg-column align-items-center justify-content-around d-flex">
                <a kind="primary" className='openCV col-md-2 col-lg-8 col-xl-8 mt-md-2 text-sm-center text-md-center bx--btn bx--btn--primary mt-lg-4' style={{ borderRadius: "2px", maxHeight: 35, }} href={`/app/campus/job/${jobId}?popup=1`} >{t('apply')}</a>
            </div>
          )
        } */}
        {closeDate && currDate && (
          <div className="text-warning font-weight-bold mt-2 ml-2">
            {t('notAcceptingApplications')}
          </div>
        )}
      </div>
    );
  };

  prepareRowData = () => {
    const { search, activePage, pageSize, selected } = this.state;
    const searchFilter = {
      skip: (activePage - 1) * pageSize,
      limit: pageSize,
      value: search ? search : '',
      company: selected.Companies ? selected.Companies : [],
      location: selected.Countries ? selected.Countries : [],
      skills: selected.Skills ? selected.Skills : [],
      jobname: selected.Jobname ? selected.Jobname : [],
      experienceList: selected.Experience ? selected.Experience : [],
      JobType: selected && selected.JobType ? selected.JobType : [],
      salaryRange: selected && selected.salaryRange ? selected.salaryRange : [],
      commuteTime: selected && selected.commuteTime ? selected.commuteTime : [],
    };
    this.props.fetchAllPublicJobs(searchFilter).then(() => {
      let jobsList;
      if (this.props.Jobs && Array.isArray(this.props.Jobs)) {
        jobsList = this.props.Jobs;
      }
      jobsList = jobsList && jobsList.map((job) => {
        const jobVacancyAvailable = job._source.isJobVacancyAvailable;
        const isClosed = job._source.isClosed;
        if ((jobVacancyAvailable !== null && jobVacancyAvailable === false) || (isClosed !== null && isClosed === true)) {
          job.flag = 1;
        }
        return job;
      })
      if (jobsList != undefined && jobsList != null) {
        jobsList = jobsList.sort((a, b) => {
          if (b.flag === 1) {
            return -1;
          } else {
            return 1;
          }
        })
      }
      const rowsData = (jobsList != null && jobsList != undefined &&
        jobsList.map(row => {
          row = row._source;
          const RowData = {
            title: row.jobname && row.jobname.nameName,
            commuteTime: row.scores && row.scores.commuteTime,
            choicesRecommendation:
              row.scores && row.scores.choicesRecommendation,
            maxSalary: `${row.maxSalary} SGD`,
            experience: row.jobseniority
              ? row.jobseniority.seniorityName
              : '',
            company: row.company && row.company.displayCompanyName /*row.company.companyName*/,
            location: row.country && row.country.countryFull,
            skills: row.skills.map(ele => ele.skill.skillName).join(', '),
            jobId: row.jobId,
            closeDate:
              row.closeDate && row.closeDate != null
                ? moment(row.closeDate)
                  .format('MMM DD YYYY')
                  .toString()
                : '',
            isJobVacancyAvailable: row.isJobVacancyAvailable,
            isClosed: row.isClosed,
            canApplytoJob: row.canApplytoJob,
            totalApplications: row.totalApplications,
            rowData: row,
          };
          const oneRow = {
            id: row.jobId.toString(),
            jobs: this.CardRowBox(RowData, this.props.t, this.props.offerAcceptedCount),
          };
          return oneRow;
        })) ||
        [];
      this.setState({ rowsData });
    });
  };

  countryDropdown = (value, metaValue = {}, name) => {
    this.setState({ [name]: metaValue });
  };

  searchTimer = null;

  onInputSearch = e => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    const search = e.target && e.target.value ? e.target.value : '';
    this.setState({ search, activePage: 1 });
    this.searchTimer = setTimeout(this.prepareRowData, 1000);
  };

  onFindJobs = () => {
    const { selected = [], Filter = [], checked = [] } = {};
    const country =
      this.state.countryValue &&
        this.state.countryValue !== null &&
        this.state.countryValue.label
        ? this.state.countryValue.label
        : '';
    if (country !== '' && country !== null) {
      selected.Countries = [];
      selected.Countries.push(country);
      Filter.push({ Countries: [country] });
      checked.push(country);
    }
    this.setSelected(selected, Filter, checked);
  };

  handlePaginationChange = data => {
    const activePage = data.page || 1;
    const pageSize = data.pageSize || this.state.pageSize;
    this.setState({ activePage, pageSize }, this.prepareRowData);
  };

  setSelected = (selected, Filter, checked) => {
    this.setState(
      {
        selected,
        Filter,
        checked,
        activePage: 1,
      },
      () => this.prepareRowData()
    );
  };

  handleRedirectRegister = () => {
    const { history: { push } = {} } = this.props || {};
    if (push) {
      push('/app/campus/candidate/register/profile');
    }
  };


  render() {
    const {
      rowsData,
      headersInfo,
      Filter,
      checked,
      show,
      countryValue,
      search,
      paths,
      pageSize,
      activePage,
      jobAppliedCounts
    } = this.state;
    const {
      publicPage = false,
      countries,
      forCompany = null,
      count,
      t,
      offerAcceptedCount,
      candidateProfile: {
        studentprofile: {
          isEligible,
          isAppSubmitted,
          faculty: { totalJobsApplicable } = {},
        } = {},
      } = {},
    } = this.props;
    let totalJobsApps = (totalJobsApplicable || 0) - (jobAppliedCounts || 0)
    if (totalJobsApps < 0) {
      totalJobsApps = 0
    }
    if (!show) return '';
    const filter = JSON.stringify(Filter);
    let url = `/jobs/facets?filters=${encodeURIComponent(
      filter ? `${filter}` : '[]'
    )}`;
    if (forCompany !== null) {
      url = `/jobs/facets?filters=${encodeURIComponent(
        filter ? `${filter}` : '[]'
      )}&companyId=${forCompany}`;
    }
    return (
      <Fragment>
        {!forCompany && <BackRow paths={paths} />}
        {!forCompany && <span className="h6">{t('browseJobs')}</span>}
        {isAppSubmitted !== null &&
          isEligible === true &&
          offerAcceptedCount <= 0 && (
            <InlineNotification
              lowContrast
              kind="warning"
              title={`${t('maxApplicableJobs')} ${totalJobsApplicable} ${totalJobsApplicable > 1 ? 'positions' : 'position'
                } , ${t('revisedApplicableJobs')} ${totalJobsApps} more position(s)`}
              subtitle=""
              hideCloseButton
            />
          )}
        {offerAcceptedCount !== null && offerAcceptedCount !== undefined && offerAcceptedCount > 0 && (
          <InlineNotification
            lowContrast
            kind="error"
            title={t('offerAcceptedErrMsg')}
            subtitle=""
            hideCloseButton
          />
        )}
        {isAppSubmitted !== true && (
          <div className="mb-2 mt-2">
            <div className="mb-2">{t('registerGeipHeading')}</div>
            <div>
              <Button
                onClick={this.handleRedirectRegister}
                small
                kind="primary">
                {t('start')}
              </Button>
            </div>
          </div>
        )}
        {isEligible === true && isAppSubmitted === true && (
          <div>
            <div className="browseJobs">
              {publicPage && (
                <div className="bx--row pt-5 pb-5 justify-content-center p-0 m-0 searchDiv">
                  <div className="searchBlock col-11 ml-5 mr-5 pb-5">
                    <div className="bx--col bx--row m-0 p-0 publicJobSearch align-items-center justify-content-center">
                      <div className="col-md-5 m-0 p-0 col-sm-12 search-item">
                        <Search
                          light
                          type="text"
                          className="pl-0 bx--col-xs-12 bx--col-sm-12 mr-1 p-0"
                          labelText="Search job"
                          closeButtonLabelText=""
                          placeHolderText="Search job"
                          onChange={({ target: { value = '' } }) => {
                            this.setState({ search: value });
                          }}
                          id="search job"
                          value={search}
                        />
                      </div>
                      {((countryValue &&
                        countryValue !== null &&
                        (countryValue.value && countryValue.value !== null)) ||
                        Array.isArray(countries)) && (
                          <div className="col-md-4 p-0 m-0 ml-md-3 mt-md-5 mt-lg-5 mt-xl-5 mb-sm-2 search-item">
                            <DropDownBox
                              required
                              selectClass="search"
                              mainClassName="mb-0"
                              custom
                              className="col-xs-12 col-sm-12 m-0 p-0"
                              options={
                                Array.isArray(countries) &&
                                countries.map(country => ({
                                  label: country.countryFull,
                                  value: country.countryId,
                                }))
                              }
                              placeholder="Country"
                              onChange={(value, e, name) =>
                                this.countryDropdown(value, e, name)
                              }
                              name="countryValue"
                              selectedValue={countryValue}
                            />
                          </div>
                        )}
                      <div className="ml-md-3 search-item mt-md-5 mt-lg-5 mt-xl-5">
                        <Button
                          className="col"
                          kind="primary"
                          style={{ height: 46 }}
                          onClick={() => this.onFindJobs()}>
                          {' '}
                          Find Jobs
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`bx--row ml-0 mr-0 p-0 ${publicPage ? ' pl-md-5 pr-md-5 pb-md-5 ml-md-5 mr-md-5' : ''
                  }`}>
                <LeftNavFilter
                  url={url}
                  className="ml-0 p-0"
                  forCompany={forCompany}
                  setSelected={this.setSelected}
                />
                <div className="bx--col-lg p-0 mt-1">
                  {!publicPage && (
                    <div
                      className="row ml-0 bx--col-xs-12 bx--col-sm-12 mt-2 p-0"
                      style={{ maxWidth: 500 }}>
                      <Search
                        light
                        type="text"
                        className="w-75 pl-0 bx--col-xs-12 bx--col-sm-12 mr-1"
                        labelText="Search job"
                        closeButtonLabelText=""
                        placeHolderText="Search job"
                        onChange={this.onInputSearch}
                        id="search job"
                        value={search}
                      />
                    </div>
                  )}
                  {checked && Array.isArray(checked) && checked.length > 0 && (
                    <div className="mt-2">
                      <label className="bx--label">
                        {t('selectedFilters')}:&nbsp;
                      </label>
                      {checked.map((ele, key) => (
                        <Tag type="private" key={key}>
                          {ele}
                        </Tag>
                      ))}
                    </div>
                  )}
                  <DataTable
                    rows={rowsData}
                    headers={headersInfo}
                    render={({ rows, headers, getHeaderProps }) => {
                      return (
                        <TableContainer title="" className="">
                          <Table
                            style={{
                              borderSpacing: '0px 6px',
                              borderCollapse: 'separate',
                            }}>
                            <TableBody className="bg-transparent">
                              {rows.map(row => (
                                <TableRow key={row.id} className="mb-2">
                                  {row.cells.map(cell => (
                                    <TableCell
                                      key={cell.id}
                                      className="pr-0 pl-0">
                                      {cell.value}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      );
                    }}
                  />
                  {rowsData && rowsData.length > 0 && (
                    <div
                      className=""
                      style={{ borderSpacing: 6, borderCollapse: 'separate' }}>
                      <Pagination
                        style={{ borderSpacing: 6, borderCollapse: 'separate' }}
                        onChange={this.handlePaginationChange}
                        pageSize={pageSize}
                        pageSizes={[10, 20, 30, 40, 50]}
                        page={activePage}
                        totalItems={count || pageSize}
                      />
                    </div>
                  )}
                  {rowsData && rowsData.length <= 0 && <NoJobsBox t={t} />}
                </div>
              </div>
              <JDProcessOverlay
                show={
                  this.props && (this.props.loading || this.props.applLoader)
                }
                message="processing..."
              />
            </div>
          </div>
        )}
        {!isEligible && (
          <div>
            <div className="text-center">
              <h5>{t('studentNotEligibleMsg')}</h5>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.BrowseJobs.loading,
  count: state.BrowseJobs.count,
  BrowseJobs: state.BrowseJobs,
  jobCreation: state.jobCreation,
  countries: state.candidateDetails.countries,
  Jobs: state.BrowseJobs.Jobs,
  facets: state.Filters && state.Filters.facets ? state.Filters.facets : [],
  candidateProfile: state.roboroyApplications.candidateProfile,
  offerAcceptedCount: state.roboroyApplications.offerAcceptedCount,
  applLoader: state.roboroyApplications.loading,
});

const mapDispatchToProps = {
  fetchAllPublicJobs,
  getAllDropDownData,
  getFewDropDownData,
  getCandidateProfile,
  getJobAppliedCount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CandidateBrowseJobs));
