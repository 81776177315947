import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import { REQUEST_SEND_EMAIL_TEMPLATE } from './actionTypes';

const xUserRegRequest = data => (dispatch) => {
  const url = `${baseUrl}/xpa?_=${new Date().getTime()}`;
  const udata = { ...data };
  udata.actionName = 'x-ruser';
  const params = {
    dispatch,
    actionType: 'XPA/CREATE_USER_REQUEST',
    url,
    method: 'POST',
    body: JSON.stringify(udata),
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'sksksk',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

const xSearchCompanies = (val, ctypeId, companyId) => (dispatch) => {
  const data = {
    actionName: 'x-scompanies',
  };
  if (companyId) {
    data.companyId = companyId;
  } else if (val) {
    data.search = val;
    data.ctypeId = ctypeId;
  } else {
    data.ctypeId = ctypeId;
  }
  const url = `${baseUrl}/xpa?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'XPA/SEARCH_COMPANIES',
    url,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'sksksk',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

const xCreateCompany = val => (dispatch) => {
  const data = {
    actionName: 'x-ccompany',
    ...val,
  };
  const url = `${baseUrl}/xpa?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'XPA/CREATE_COMPANY',
    url,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'sksksk',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

const xGetCalendarEventById = eventId => (dispatch) => {
  const finalData = {
    actionName: 'x-get-event',
    eventId,
  };
  const url = `${baseUrl}/xpa?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'XPA/GET_CALENDAR_EVENTS',
    url,
    method: 'POST',
    body: JSON.stringify(finalData),
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'sksksk',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

const xUpsertCalendarEventById = data => (dispatch) => {
  const finalData = {
    actionName: 'x-slot-select',
    ...data,
  };
  const url = `${baseUrl}/xpa?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'XPA/GET_CALENDAR_EVENTS',
    url,
    method: 'POST',
    body: JSON.stringify(finalData),
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'sksksk',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

export {
  xUserRegRequest,
  xSearchCompanies,
  xCreateCompany,
  xGetCalendarEventById,
  xUpsertCalendarEventById,
};
