import {
    REQUEST_JOB_ANALYTICS_DASHBOARD_COUNT,
    ERROR_JOB_ANALYTICS_DASHBOARD_COUNT,
    SUCCESS_JOB_ANALYTICS_DASHBOARD_COUNT,
  } from '../../actions/talentSearch/actionTypes';
  
  const initialState = {
    loading: false,
    error: null,
    jobanalytics: {
        dashboard : {}
    },
  };
  
  const applicationData = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case REQUEST_JOB_ANALYTICS_DASHBOARD_COUNT:
        return {
          ...state,
          loading: true,
          error: null,
          talentSearchComplete: false,
        };
      case ERROR_JOB_ANALYTICS_DASHBOARD_COUNT:
        return {
          ...state,
          jobanalytics: {
            dashboard : {}
          },
          loading: false,
          error: payload.error,
          talentSearchComplete: false,
        };
      case SUCCESS_JOB_ANALYTICS_DASHBOARD_COUNT:
        return {
          ...state,
          loading: false,
          error: null,
          talentSearchComplete: false,
          jobanalytics: {
            dashboard : (payload && payload.dashboard) || {},
            recommendedApplications : (payload && payload.applications) || []
          },          
        };
      default:
        return state;
    }
  };
  
  export default applicationData;
  