import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_REGISTRATION_LINK,
  REQUEST_REGISTER_STUDENT,
  REQUEST_ALL_COUNTRIES,
  REQUEST_GET_ALL_LOCATIONS,
} from '../actionTypes';

const registrationRequest = data => (dispatch) => {
  const url = `${baseUrl}/RegistrationRequest`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_REGISTRATION_LINK,
  };
  return callApi(params);
};

const registerStudent = data => (dispatch) => {
  const url = `${baseUrl}/RegistrationRequest/registerStudent`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_REGISTER_STUDENT,
  };
  return callApi(params);
};

const getCountries = () => (dispatch) => {
  const url = `${baseUrl}/Countries`;
  return callApi({
    dispatch,
    url,
    method: 'GET',
    actionType: REQUEST_ALL_COUNTRIES,
  });
};

const getLocations = key => (dispatch) => {
  const url = `${baseUrl}/location-base/locationList?search=${key}`;
  return callApi({
    dispatch,
    url,
    method: 'GET',
    actionType: REQUEST_GET_ALL_LOCATIONS,
  });
};

export {
  registrationRequest,
  registerStudent,
  getCountries,
  getLocations,
};
