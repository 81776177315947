import {
  REQUEST_CANDIDATE_JOBS,
  SUCCESS_CANDIDATE_JOBS,
  ERROR_CANDIDATE_JOBS,
  REQUEST_CANDIDATE_INVITE_UPDATE,
  SUCCESS_CANDIDATE_INVITE_UPDATE,
  ERROR_CANDIDATE_INVITE_UPDATE,
  REQUEST_GET_JOB_TIMELINE,
  SUCCESS_GET_JOB_TIMELINE,
  ERROR_GET_JOB_TIMELINE,
  REQUEST_INVITE_CANDIDATE_JOBS,
  SUCCESS_INVITE_CANDIDATE_JOBS,
  ERROR_INVITE_CANDIDATE_JOBS,
  REQUEST_APPLIED_CANDIDATE_JOBS,
  SUCCESS_APPLIED_CANDIDATE_JOBS,
  ERROR_APPLIED_CANDIDATE_JOBS,
  REQUEST_INTERVIEWS_CANDIDATE_JOBS,
  SUCCESS_INTERVIEWS_CANDIDATE_JOBS,
  ERROR_INTERVIEWS_CANDIDATE_JOBS,
  REQUEST_OFFERS_CANDIDATE_JOBS,
  SUCCESS_OFFERS_CANDIDATE_JOBS,
  ERROR_OFFERS_CANDIDATE_JOBS,
} from '../../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  shortlisted: [],
  rejected: [],
  timeLine: [],
  canJobs: [],
  inviteJobs: [],
  appliedJobs: [],
  interviewJobs: [],
  offersJobs: [],
};

const candidateJobs = (state = initState, action) => {
  const { type, payload = {} } = action;
  const {
    applications = [], count = 0 ,
  } = payload;
  switch (type) {
    case REQUEST_CANDIDATE_JOBS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CANDIDATE_JOBS:
      return {
        ...state,
        applications,
        count,
        canJobs: payload,
        loading: false,
        error: null,
      };
    case ERROR_CANDIDATE_JOBS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_INVITE_CANDIDATE_JOBS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_INVITE_CANDIDATE_JOBS:
      return {
        ...state,
        inviteJobs: payload,
        loading: false,
        error: null,
      };
    case ERROR_INVITE_CANDIDATE_JOBS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_APPLIED_CANDIDATE_JOBS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_APPLIED_CANDIDATE_JOBS:
      return {
        ...state,
        appliedJobs: payload,
        loading: false,
        error: null,
      };
    case ERROR_APPLIED_CANDIDATE_JOBS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_INTERVIEWS_CANDIDATE_JOBS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_INTERVIEWS_CANDIDATE_JOBS:
      return {
        ...state,
        interviewJobs: payload,
        loading: false,
        error: null,
      };
    case ERROR_INTERVIEWS_CANDIDATE_JOBS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_OFFERS_CANDIDATE_JOBS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_OFFERS_CANDIDATE_JOBS:
      return {
        ...state,
        offersJobs: payload,
        loading: false,
        error: null,
      };
    case ERROR_OFFERS_CANDIDATE_JOBS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CANDIDATE_INVITE_UPDATE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_CANDIDATE_INVITE_UPDATE:
      return {
        ...state,
        ...payload,
        error: null,
        loading: false,
      };
    case ERROR_CANDIDATE_INVITE_UPDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_JOB_TIMELINE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_GET_JOB_TIMELINE:
      return {
        ...state,
        timeLine: (payload && payload.timeLine) || [],
        error: null,
        loading: false,
      };
    case ERROR_GET_JOB_TIMELINE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default candidateJobs;
