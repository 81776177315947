import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_GET_ID_DATA,
  REQUEST_CREATE_PROFILEINFO,
  REQUEST_UPDATE_PROFILEINFO,
  REQUEST_DELETE_PROFILEINFO,
  REQUEST_REMOVE_PROFILESKILL,
  REQUEST_ADD_PROFILESKILL,
  REQUEST_UPDATE_PROFILE,
  REQUEST_ALL_PROFILE_COURSES,
  REQUEST_ALL_SCHOOL_FACULTIES,
  REQUEST_CREATE_PROFILE_COURSES,
} from '../actionTypes';
import { deleteApiCache } from 'utils/helpers';

const getAllDropDownData = data => (dispatch) => {
  const { actionType, apiName, value } = data;
  const url = `${baseUrl}/${apiName}?search=${encodeURIComponent(value)}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType,
  };
  return callApi(params);
};

const getFewDropDownData = data => (dispatch) => {
  const { actionType, apiName, link } = data;
  const url = `${baseUrl}/${apiName}${link}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType,
  };
  return callApi(params);
};

const getAllCourses = data => (dispatch) => {
  const {
    schoolId,
    facultyId,
    courseItemsPerPage,
    courseSearchKey,
    courseActivePage,
  } = data || {};
  const skip = courseActivePage === 0 ? 0 : (courseActivePage - 1) * courseItemsPerPage;
  let url = `${baseUrl}/courses?`

  if(schoolId  && !facultyId){
    url += `schoolId=${schoolId}&`
  }
  if(schoolId && facultyId){
    url += `schoolId=${schoolId}&facultyId=${facultyId}&_=${new Date().getTime()}&`;
  }
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${courseItemsPerPage}&`;
  if (courseSearchKey) {
    url += `searchKey=${courseSearchKey}`;
  }
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_PROFILE_COURSES,
  };
  return callApi(params);
};

const createNewCourses = data => (dispatch) => {
  const { schoolId, facultyId } = data;
  if (!schoolId || !facultyId) {
    return Promise.reject({
      error: true,
      message: 'Please enter valid fields',
    })
  }
  const url = `${baseUrl}/courses?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_PROFILE_COURSES,
  };
  return callApi(params);
};

const getAllFaculties = data => (dispatch) => {
  const {
    schoolId,
    facultyActivePage,
    facultyItemsPerPage,
    facultySearchKey,
  } = data || {};
  if (!schoolId) {
    return Promise.reject({
      error: true,
      message: 'Please enter valid fields',
    })
  }
  const skip = facultyActivePage === 0 ? 0 : (facultyActivePage - 1) * facultyItemsPerPage;
  let url = `${baseUrl}/faculties?schoolId=${Number(schoolId)}&_=${new Date().getTime()}&`;
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${facultyItemsPerPage}&`;
  if (facultySearchKey) {
    url += `searchKey=${facultySearchKey}`;
  }
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_SCHOOL_FACULTIES,
  };
  return callApi(params);
};

const getRowDataById = (table, col, Id) => (dispatch) => {
  const url = `${baseUrl}/${table}?filter[where][${col}]=${Id}&_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ID_DATA,
  };
  return callApi(params);
};

const createProfileSpecificInfo = data => (dispatch) => {
  const { profileId, infoType, infoData } = data;
  const url = `${baseUrl}/profiles/${profileId}/${infoType}`;
  if (infoType === 'projects') {
    delete infoData.projectId;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(infoData),
    actionType: REQUEST_CREATE_PROFILEINFO,
  };
  deleteApiCache('xpa-api-profiles');
  return callApi(params);
};

const updateProfileSpecificInfo = data => (dispatch) => {
  const {
    profileId, infoType, infoTypeId, infoData,
  } = data;
  // console.log(data);
  const url = `${baseUrl}/profiles/${profileId}/${infoType}/${infoTypeId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(infoData),
    actionType: REQUEST_UPDATE_PROFILEINFO,
  };
  deleteApiCache('xpa-api-profiles');
  return callApi(params);
};

const deleteProfileSpecificInfo = data => (dispatch) => {
  const { profileId, infoType, infoTypeId } = data;
  // console.log(data);
  const url = `${baseUrl}/profiles/${profileId}/${infoType}/${infoTypeId}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_PROFILEINFO,
  };
  return callApi(params);
};

const manageSkill = (method = '', link = '', skill = {}) => (dispatch) => {
  const url = `${baseUrl}/profiles/${link}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_REMOVE_PROFILESKILL,
  };
  // console.log(params);
  if (method === 'post' || method === 'POST') {
    params.method = 'POST';
    params.headers = {
      'content-type': 'application/json',
    };
    params.actionType = REQUEST_ADD_PROFILESKILL;
    params.body = JSON.stringify(skill);
  }
  return callApi(params);
};

const updateProfile = data => (dispatch) => {
  const url = `${baseUrl}/profiles/${data.profileId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data.infoData),
    actionType: REQUEST_UPDATE_PROFILE,
  };
  // console.log(params);
  return callApi(params);
};

export {
  getAllDropDownData,
  getFewDropDownData,
  getRowDataById,
  deleteProfileSpecificInfo,
  updateProfileSpecificInfo,
  createProfileSpecificInfo,
  manageSkill,
  updateProfile,
  getAllCourses,
  getAllFaculties,
  createNewCourses,
};
