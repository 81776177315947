import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { v4 } from 'node-uuid';

import ColoredCircle from 'components/common/ColoredCircle';
import XopaModal from 'components/roboroy/XopaModal';

class XopaTable extends Component {
  state = {};
  render() {
    const {
      xopaScores, activateModal, dismissModal, activeModal, sortBy,
    } = this.props;
    return (
      <div className="xopa__table">
        <table>
          <TableHead sortBy={sortBy} />
          <tbody>
            {xopaScores.map(candidate => (
              <XopaRow
                key={v4()}
                candidate={candidate}
                activate={activateModal}
                dismiss={dismissModal}
              />
            ))}
          </tbody>
        </table>
        <XopaModal active={activeModal} dismiss={dismissModal} />
      </div>
    );
  }
}

const XopaRow = ({ candidate: c, activate }) => (
  <tr className="xopa__row">
   {/* onClick={e => activate(c, e)} */}
    <td className="cell__name">
      <div>{c.name}</div>
    </td>
    <td>
      <i
        className="fa fa-video-camera"
        style={{
          color: c.interviewed ? '#4c9c07' : '#eb757b',
        }}
        aria-hidden="true"
      />
    </td>
    <td>{c.stage}</td>
    <td>{c.exp}</td>
    <td>{c.age}</td>
    <td>{c.salary}</td>
    <td>{c.location}</td>
    <td>
      <ColoredCircle value={c.cv_relevance} />
    </td>
    <td>
      <ColoredCircle value={c.acceptability} />
    </td>
    <td>
      <ColoredCircle value={c.twoWayMatch} />
    </td>
    <td>
      <ColoredCircle value={c.loyality} getColor="getLoyaltyColor" />
    </td>
    <td>
      <ColoredCircle value={c.performance} getColor="getPerformanceColor" />
    </td>
  </tr>
);
const TableHead = ({ sortBy }) => (
  <thead>
    <tr>
      <td className="cell__name">
        <div>Name</div>
      </td>
      <td>
        Video <br /> Interview
      </td>
      <td>
        Current <br /> Stage
      </td>
      <td>EXPERIENCE(YRS)</td>
      <td>AGE</td>
      <td>
        current <br /> salary
      </td>
      <td>location</td>
      <td className="pointer" onClick={() => sortBy('cv_relevance')}>
        cv <br /> relevance
      </td>
      <td className="pointer" onClick={() => sortBy('acceptability')}>
        Acceptability
      </td>
      <td className="pointer" onClick={() => sortBy('hireability')}>
        hireability <br /> (%)
      </td>
      <td className="pointer" onClick={() => sortBy('loyality')}>
      Retention <br />(%)
      </td>
      <td className="pointer" onClick={() => sortBy('performance')}>
        performance <br />(%)
      </td>
    </tr>
  </thead>
);

export default XopaTable;
