import React, { Component, Fragment, Suspense } from 'react';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

import NotFound from 'components/common/404/404';
import ServerError from 'components/common/500/500';
import ForgotPassword from 'containers/common/forgotPassword/ForgotPassword';
import ResetPassword from 'containers/common/resetPassword/index';
import Login from 'containers/common/login/Login';
import CampusLogin from 'containers/common/campusLogin/login';
import StudentLogin from 'containers/common/campusLogin/studentLogin';
import CollegeLogin from 'containers/common/campusLogin/collegeLogin';
import CompanyLogin from 'containers/common/campusLogin/companyLogin';
import GoFinland from 'containers/common/goFinland/login';
import RecruiterLogin from 'containers/common/login/RecruiterLogin';
import ProfessionalLogin from 'containers/common/login/ProfessionalLogin';
import registerCandidate from 'containers/common/campusLogin/register';
import RegisterRequest from 'containers/common/campusLogin/candidateRegister';
import CommonPublicReg from 'containers/common/campusLogin/CandidateRegister/CommonPublicReg';
import InviteAccept from 'containers/roboroy/invite';
import InviteReject from 'containers/roboroy/invite/reject';
import OfferInviteAccept from 'containers/roboroy/OfferInvite/OfferInviteAccept';
import OfferInviteReject from 'containers/roboroy/OfferInvite/OfferInviteReject';
import Registration from 'containers/admin/Registration';
import ViewCv from 'containers/roboroy/ViewCv';
import ViewOfferDocumentCv from 'containers/roboroy/ViewOfferDocumentCv';
import ReferenceInvite from 'containers/roboroy/candidate/references/ReferenceInvite';
import AvailableSlots from 'containers/roboroy/AvailableSlots';
import Cronofy from 'containers/roboroy/cronofy';
import CronofyRetry from 'containers/roboroy/cronofy-retry';
import JoinTalentPool from 'containers/roboroy/join/index';
import PreviewData from 'containers/roboroy/email-templates/EmailPreview';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import PublicPage from 'containers/public/index.js';
import XPAErrorBoundary from 'containers/common/error-boundary';
import CompanyRegistration from 'containers/common/campusLogin/companyRegistration';
import { FlaskLoader } from 'components/common/common';
import ViewJobJD from 'containers/roboroy/viewJobJD';
import SessionTimeOutPage from 'containers/public/SessionTimeOutPage';
import UserEmailConfirmation from 'containers/public/UserEmailConfirmation';
import PublicAmpPlayer from 'containers/roboroy/JobInfo/PublicAmpPlayer';

/* Public Routes */
import JobInfo from 'containers/roboroy/JobInfo/JobInfo';

import { fetchUserLoginState } from 'actions/userstate';

import App from './MainApp';

import {
  fetchAllStudentsList,
  getStudentsList
} from 'actions/BrowseStudents';
import{
  getCompaniesUsersList
} from 'actions/CompaniesUsers'
import {generateUserToken} from 'actions/simulate.js'

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMobileSidebar: false,
      isLogging: false,
      searchKey: '',
      showSideBar: false,
      showSearchDropdownBar: false,
      showSubItems: false,
      showSimulatorSubMenu: false,
      selectedTab: 0,
      subItems: [],
      isSideNavExpanded: false,
      keyword: '',
      activePage: 1,
      itemsPerPage: 5,
      isPreviewUser:false
    };
  }

  componentDidMount() {
    const {
      user: {
        userId,
      } = {},
    } = this.props;
    this.props.fetchUserLoginState(userId);
    this.handleSubmitFilterVals();
    this.handleCompaniesUsersFilterVals();
  }

  componentWillReceiveProps() {
    let { searchKey } = this.state;
    const { history: { location } } = this.props;
    if (location.pathname.indexOf('/app/campus/search/') > -1) {
      searchKey = location.pathname.replace('/app/campus/search/', '');
      searchKey = searchKey.slice(0, searchKey.indexOf('/'));
    } else searchKey = '';
    this.setState({ searchKey });
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  setLogging = (isLogging) => {
    this.setState({ isLogging });
  }

  handleSubmitFilterVals = (res) => {
    const finaldata = this.state;
    this.setState({isLogging:true},()=>{
      this.props.getStudentsList(finaldata).then(()=>{this.setState({isLogging:false})});
    })
  }

  handleCompaniesUsersFilterVals = (res) => {
    const finaldata = this.state;
    this.setState({isLogging:true},()=>{
      this.props.getCompaniesUsersList(finaldata).then(()=>{
        this.setState({isLogging:false});
      });
    })
  }

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, searchKey: event.target.value, activePage: 1 }, () => {
      const {
        itemsPerPage,
        activePage,
        keyword,
      } = this.state;

      this.searchTimer = setTimeout(() => (
        this.handleSubmitFilterVals()
      ), 1000);
    });
  }

  handleCompaniesUsersSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, searchKey: event.target.value, activePage: 1 }, () => {
      const {
        itemsPerPage,
        activePage,
        keyword,
      } = this.state;

      this.searchTimer = setTimeout(() => (
        this.handleCompaniesUsersFilterVals()
      ), 1000);
    });
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage, isLogging:true }, () => {
      this.props.getStudentsList(this.state).then(()=>{
        this.setState({isLogging:false})
      });
    });
  }

  handleCompaniesUsersPaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage,isLogging:true }, () => {
      this.props.getCompaniesUsersList(this.state).then(()=>{this.setState({isLogging:false})});
    });
  }

  handleShowSideBar = () => {
    const { showSideBar } = this.state;
    this.setState({
      showSideBar: !showSideBar,
    });
  }

  handleShowSearchBar = () => {
    const { showSearchDropdownBar } = this.state;
    this.setState({
      showSearchDropdownBar: !showSearchDropdownBar,
    });
  }


  toggleSidebar = (toOpen) => {
    this.setState({
      displayMobileSidebar: !((toOpen !== null) || (toOpen !== undefined)) ? toOpen : !this.state.displayMobileSidebar,
    });
  }

  handleRedirectMainPage = () => {
    const {
      history: {
        push,
      } = {},
      user: {
        roleId,
      } = {},
    } = this.props;
    if (push) {
      if (roleId && Number(roleId) === 3) {
        push('/app/campus/candidate');
      } else {
        push('/app/campus');
      }
    }
  }

  onClickSideNavExpand = () => {
    const {
      isSideNavExpanded,
    } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  }

  handleShowSubMenu = (subItems, label, value, desc) => {
    const {
      showSubItems,
    } = this.state;
    if (value === true) {
      if (this.state.subItems && Array.isArray(this.state.subItems) && this.state.subItems.length > 0 && label !== this.state.label) {
        this.setState({
          showSubItems: true,
          showSimulatorSubMenu:false,
          subItems,
          label,
          desc,
        });
      } else {
        this.setState({
          showSubItems: !showSubItems,
          showSimulatorSubMenu:false,
          subItems,
          label,
          desc,
        });
      }
    } else {
      this.setState({
        showSubItems: false,
        showSimulatorSubMenu:false,
        subItems: [],
        label: '',
      });
    }
  }

  handleShowSimulatorSubMenu = () => {
    const {
      showSimulatorSubMenu,
    } = this.state;
    this.setState({
      showSubItems:false,
      showSimulatorSubMenu: !showSimulatorSubMenu,
      itemsPerPage:5,
      activePage:1,
      selectedTab:0
    },()=>{
      this.handleSubmitFilterVals();
      this.handleCompaniesUsersFilterVals();
    });
  }

  handleSelect=(cui, uits)=>{
    const currentUserId=Number(cui);
    const userIdToSimulate=Number(uits);
    const {selectedTab}=this.state;
    this.props.generateUserToken({currentUserId,userIdToSimulate}).then((res)=>{
      sessionStorage.setItem("userToken",res.token);
      sessionStorage.setItem("simulationUserId",userIdToSimulate);
      if(selectedTab===0){
        this.props.history.push(`/app/campus/candidate`);
      }else{
        this.props.history.push(`/app/campus`);
      }
      window.location.reload();
    })
  }

  handleChangeTabClick = (tab) => {
    let selectedTab = 0;
    switch (tab) {
      case 'students':
        selectedTab = 0;
        break;
      case 'company':
        selectedTab = 1;
        break;
      default:
        break;
    }
    this.setState({
      selectedTab,
      itemsPerPage:5,
      activePage:1
    },()=>{
      this.handleSubmitFilterVals();
      this.handleCompaniesUsersFilterVals()
    })
  }

  appRoute = (routeProps) => {
    const {
      isUserLoggedIn = false,
      user: {
        userId,
        email = '',
        roleId = 0,
        isAdmin = false,
        isSuperAdmin,
        products = [],
        suspended = false,
        company: {
          companyName = '',
          companyId,
          ctypeId,
        } = {},
      } = {},
      students,
      count,
      companiesUsers,
      companiesUsersCount,
    } = this.props;
    const {
      showSubItems,
      subItems, label,
      isSideNavExpanded,
      showSimulatorSubMenu,
      selectedTab,
      activePage,
      itemsPerPage
    } = this.state;

    const { displayMobileSidebar, searchKey } = this.state;
    let isYuvoEmail = email && email.indexOf && email.indexOf('yuvohub.com') > -1;
    // isYuvoEmail = true;
    let redirectPath = window.sessionStorage.getItem('xpa-redirect-uri');
    window.sessionStorage.removeItem('xpa-redirect-uri');
    if (redirectPath) {
      return (<Redirect
        {...routeProps}
        to={{
          pathname: redirectPath,
          state: { xfrom: this.props.location },
          search: this.props.location.search,
        }}
      />)
    }
    if (isUserLoggedIn) {
      window.sessionStorage.removeItem('xpa-redirect-uri');
      return (
        <App
          isYuvoEmail={isYuvoEmail}
          isUserLoggedIn={isUserLoggedIn}
          isAdmin={isAdmin}
          suspended={suspended}
          roleId={roleId}
          companyName={companyName}
          isSuperAdmin={isSuperAdmin}
          products={products}
          toggleSidebar={this.toggleSidebar}
          displayMobileSidebar={displayMobileSidebar}
          searchKey={searchKey}
          ctypeId={ctypeId}
          companyId={companyId}
          history={this.props && this.props.history}
          handleShowSideBar={this.handleShowSideBar}
          showSideBar={this.state.showSideBar}
          handleShowSearchBar={this.handleShowSearchBar}
          showSearchDropdownBar={this.state.showSearchDropdownBar}
          handleShowSubMenu={this.handleShowSubMenu}
          handleShowSimulatorSubMenu={this.handleShowSimulatorSubMenu}
          showSimulatorSubMenu={showSimulatorSubMenu}
          selectedTab={selectedTab}
          handleChangeTabClick={this.handleChangeTabClick}
          showSubItems={showSubItems}
          subItems={subItems}
          desc={this.state.desc}
          handleRedirectMainPage={this.handleRedirectMainPage}
          label={label}
          onClickSideNavExpand={this.onClickSideNavExpand}
          isSideNavExpanded={isSideNavExpanded}
          activePage={activePage}
          students={students}
          count={count}
          handleSearchSubmit={this.handleSearchSubmit}
          handlePaginationChange={this.handlePaginationChange}
          companiesUsers={companiesUsers}
          companiesUsersCount={companiesUsersCount}
          handleCompaniesUsersSearchSubmit={this.handleCompaniesUsersSearchSubmit}
          handleCompaniesUsersPaginationChange={this.handleCompaniesUsersPaginationChange}
          handleSelect={this.handleSelect}
          currentUserId={userId}
          itemsPerPage={itemsPerPage}
          {...routeProps}
        />
      );
    }
    window.sessionStorage.setItem('xpa-redirect-uri', this.props.location && this.props.location.pathname);

    return ((isUserLoggedIn === false) ? (
      <Redirect
        {...routeProps}
        to={{
          pathname: '/',
          state: { xfrom: this.props.location },
        }}
      />
    ) : ((isUserLoggedIn === null) && <div className="login-spinner"><div className="xp-c-loader" /></div>));
  }

  render() {
    const {
      isUserLoggedIn = false,
      user: { roleId = 0, products = [] },
      match: {
        url,
      },
      location: {
        state: locState,
      } = {},
    } = this.props;

    let rpath = '/app/profile';
    if (roleId === 3) {
      rpath = '/app/campus/candidate';
    }
    // else if (roleId !== 3) {
    //   const uniqProducts = [...new Set(products)];
    //   const productsList = uniqProducts.filter(p => ['campus'].includes(p.toLowerCase()))
    //     .map(p => p.toLowerCase());
    //   if (Array.isArray(productsList) && productsList[0]) {
    //     if (productsList[0] === 'x0pa ext') {
    //       rpath = '/app/campus';
    //     } else {
    //       rpath = `/app/${productsList[0]}`;
    //     }
    //   }
    // } else {
    //   rpath = '/';
    // }

    if (locState && locState.xfrom) {
      const { pathname: redirectTo } = locState.xfrom || {};
      rpath = redirectTo;
    }

    return (
      <Fragment>
        <XPAErrorBoundary user={this.props.user}>
          <Suspense fallback={<FlaskLoader />}>
            <Switch>
              {/* Redirect based on role */}
              <Route
                exact
                path="/"
                render={(routeProps) => {
                  if (isUserLoggedIn === true) {
                    return (
                      <Redirect to={{ pathname: rpath, state: { xfrom: this.props.location } }} />
                    );
                  }
                  return (
                    (isUserLoggedIn === false) ? (<CampusLogin {...routeProps} setLogging={this.setLogging} />) : (<CampusLogin {...routeProps} setLogging={this.setLogging} />)
                  );
                }
                }
              />

              <Route
                exact
                path="/public/employee/emailconfirmation"
                render={routeProps => (isUserLoggedIn ? <Redirect to={{ pathname: '/app/campus/emailconfirmation', state: { xfrom: this.props.location } }} /> 
                  : <UserEmailConfirmation {...routeProps}/>)}
              />

              <Route
                exact
                path="/recruiter/login"
                render={(routeProps) => {
                  if (isUserLoggedIn === true) {
                    return (
                      <Redirect to={{ pathname: rpath, state: { xfrom: this.props.location } }} />
                    );
                  }
                  return (
                    (isUserLoggedIn === false) ? (<RecruiterLogin {...routeProps} setLogging={this.setLogging} />) : (<RecruiterLogin {...routeProps} setLogging={this.setLogging} />)
                  );
                }
                }
              />
              <Route
                exact
                path="/public/student/login"
                render={routeProps => (isUserLoggedIn ? <Redirect to={{ pathname: '/app/campus/candidate', state: { xfrom: this.props.location } }} /> : <StudentLogin {...routeProps} setLogging={this.setLogging} />)}
              />
              <Route
                exact
                path="/public/RP/login"
                render={routeProps => (isUserLoggedIn ? <Redirect to={{ pathname: '/app/campus', state: { xfrom: this.props.location } }} /> : <CollegeLogin {...routeProps} setLogging={this.setLogging} />)}
              />
              <Route
                exact
                path="/public/company/login"
                render={routeProps => (isUserLoggedIn ? <Redirect to={{ pathname: '/app/campus', state: { xfrom: this.props.location } }} /> : <CompanyLogin {...routeProps} setLogging={this.setLogging} />)}
              />
              <Route exact path={`${url}app/campus/job/:id`} component={JobInfo} />
              <Route exact path="/campus/login" component={CampusLogin} />
              <Route exact path="/workinfinland" component={GoFinland} />
              <Route exact path="/professional/login" component={ProfessionalLogin} />
              <Route exact path="/public/campus/register" component={RegisterRequest} />
              <Route exact path="/public/campus/register/:status" component={CommonPublicReg} />
              <Route exact path="/public/campus/register/:status" component={CommonPublicReg} />
              <Route
                exact
                path="/login"
                render={routeProps => (
                  <CommonPublicReg {...routeProps} />)}
              />
              <Route exact path="/public/session/out" component={SessionTimeOutPage} />
              <Route exact path="/public/campus/:id/basic/register" component={registerCandidate} />
              <Route
                exact
                path={`/public/amp/player`}
                component={PublicAmpPlayer}
              />
              <Route
                exact
                path="/public/:component"
                render={
                  routeProps => (<PublicPage {...routeProps} isUserLoggedIn={isUserLoggedIn} />)
                }
              />
              <Route
                path="/app"
                render={routeProps => (this.appRoute(routeProps))}
              />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route
                exact
                path="/login"
                render={routeProps => (
                  (isUserLoggedIn)
                    ? (
                      <Redirect
                        to={{
                          pathname: rpath,
                          state: { xfrom: this.props.location },
                        }}
                      />
                    ) : <Login {...routeProps} setLogging={this.setLogging} />)}
              />
              <Route path="/invite/:inviteHash/accept" component={InviteAccept} />
              <Route path="/invite/:inviteHash/reject" component={InviteReject} />
              <Route path="/public/join/talentpool/:sharinghash" component={JoinTalentPool} />
              <Route path="/public/offer-consent/:inviteHash/accept" component={OfferInviteAccept} />
              <Route path="/public/register/:status/info" component={CompanyRegistration} />
              <Route path="/public/join/talentpool/:sharinghash" component={JoinTalentPool} />
              <Route path="/registration/:data" component={Registration} />
              <Route path="/view/:id/cv" component={ViewCv} />
              <Route path="/view/:id/docs" component={ViewOfferDocumentCv} />
              <Route path="/public/rf/:id" component={ReferenceInvite} />
              <Route path="/cronofy/cb" component={Cronofy} />
              <Route path="/public/cronofy/cb" component={CronofyRetry} />
              <Route path="/500" component={ServerError} />
              <Route path="/emailpreview/:template" component={PreviewData} />
              <Route path="/public/:eventId/slots" component={AvailableSlots} />
              <Route path="/viewjd/:id/jd" component={ViewJobJD} />
              <Route path="/*" component={NotFound} />
            </Switch>
            <JDProcessOverlay show={this.state.isLogging} message="processing..." />
          </Suspense>
        </XPAErrorBoundary>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
  students:state.BrowseStudents.students,
  studentList: state.BrowseStudents.studentList,
  companiesUsers:state.CompaniesUsers.companiesUsers,
  companiesUsersCount:state.CompaniesUsers.count,
  profile: state.profile.userInfo,
  count: state.BrowseStudents.count,
});

const mapDispatchToProps = {
  fetchUserLoginState,
  fetchAllStudentsList,
  getStudentsList,
  getCompaniesUsersList,
  generateUserToken,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));