import React from 'react';
import { Tile } from 'carbon-components-react';

import republicPolytechnic from 'assets/images/republic-polytechnic.png';

const UnsupportedBrowser = () => (
  <div className="d-flex vh-100 vw-100 justify-content-center align-items-center">
    <Tile style={{ maxWidth: 500 }}>
      <div className="text-center">
        <img
          style={{ height: '100%' }}
          className="xpa-top-header--logo mb-4"
          src={republicPolytechnic}
          alt="logo"
        />
      </div>
      <h2 className="text-center text-uppercase">Browser not supported</h2>
      <div>
        <p>
          You are currently using a browser that is not supported by our
          application. To continue using our application please switch to the
          latest versions of the any of the following browsers.
        </p>
        <ul>
          <li className="font-weight-bold">Chrome</li>
          <li className="font-weight-bold">Firefox</li>
          <li className="font-weight-bold">Microsoft Edge</li>
          <li className="font-weight-bold">Opera</li>
          <li className="font-weight-bold">Safari</li>
        </ul>
      </div>
    </Tile>
  </div>
);

export default UnsupportedBrowser;
