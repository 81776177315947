import {
  REQUEST_DASHBOARD_DATA,
  SUCCESS_DASHBOARD_DATA,
  ERROR_DASHBOARD_DATA,
  REQUEST_DASHBOARD_WORKFLOW_SUBSTAGES_DATA,
  ERROR_DASHBOARD_WORKFLOW_SUBSTAGES_DATA,
  SUCCESS_DASHBOARD_WORKFLOW_SUBSTAGES_DATA,
} from '../../actions/actionTypes';

const initailState = {
  loading: false,
  error: null,
  openJobs: 0,
  totalApplications: 0,
  numOfShortlistedApplications: 0,
  numOfRejectedApplications: 0,
  numOfOffersMade: 0,
  numOfOffersAccepted: 0,
  numOfVideoInterviews: 0,
  numOfPsychometricTests: 0,
  loyalityPercentage: 80,
  loyalityUsers: [{ id: 1, name: 'name 1', score: 98 }, { id: 3, name: 'name 3', score: 93 }],
  subStages: [],
};

const roboroyDashboard = (state = initailState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SUCCESS_DASHBOARD_DATA:
      return {
        ...state,
        ...payload.dashboard,
        loading: false,
        error: null,
      };
    case ERROR_DASHBOARD_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DASHBOARD_WORKFLOW_SUBSTAGES_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SUCCESS_DASHBOARD_WORKFLOW_SUBSTAGES_DATA:
      return {
        ...state,
        subStages: payload && payload.subStages,
        loading: false,
        error: null,
      };
    case ERROR_DASHBOARD_WORKFLOW_SUBSTAGES_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default roboroyDashboard;
