import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_ALL_INDUSTRIES,
  REQUEST_ALL_COUNTRIES,
  REQUEST_ALL_STATES,
  REQUEST_COMPANY_DATA,
  REQUEST_COMPANY_OFFICE_DATA,
  REQUEST_ALL_COMPANIES,
  REQUEST_ADD_AUTHORIZED_COMPANY,
  REQUEST_CREATE_NEW_COMPANY,
  REQUEST_CREATE_NEW_OFFICE,
  REQUEST_CREATE_NEW_STATE,
  REQUEST_UPDATE_COMPANY,
  REQUEST_DELETE_COMPANY,
  REQUEST_UPDATE_OFFICE,
  REQUEST_DELETE_OFFICE,
  REQUEST_UPDATE_OFFICE_DATA,
  REQUEST_AUTHORIZED_COMPANIES,
  REQUEST_AUTHORIZE_NEWCOMPANIES,
  REQUEST_SWITCH_COMPANY,
} from '../actionTypes';

const getAuthorizedCompanies = ({
  itemsPerPage = 0,
  activePage = 0,
  searchKey = '',
  status = '',
}) => (dispatch) => {
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  const url = `${baseUrl}/companymaps?filter[limit]=${itemsPerPage}&filter[skip]=${skip}&${
    (status) ? `status=${status}` : ''
  }&${
    (searchKey) ? `searchKey=${searchKey}` : ''
  }&_=${new Date().getTime()}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_AUTHORIZED_COMPANIES,
  };

  return callApi(params);
};

const associateCompany = (data) => (dispatch) => {
  const url = `${baseUrl}/companymaps`;

  const params = {
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },    
    actionType: REQUEST_ADD_AUTHORIZED_COMPANY,
  };

  return callApi(params);
};

const dissociateCompany = (data) => (dispatch) => {
  const url = `${baseUrl}/companymaps`;

  const params = {
    dispatch,
    method: 'DELETE',
    url,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },    
    actionType: REQUEST_ADD_AUTHORIZED_COMPANY,
  };

  return callApi(params);
};

const getAllIndustries = () => (dispatch) => {
  const url = `${baseUrl}/Industries`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_INDUSTRIES,
  };

  return callApi(params);
};

const getAllStates = (data) => (dispatch) => {

  //const { apiName, paramName, value } = data;
  const url = `${baseUrl}/States`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_STATES,
  };

  return callApi(params);
};

const getAllCountries = () => (dispatch) => {
  const url = `${baseUrl}/Countries`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_COUNTRIES,
  };

  return callApi(params);
};

const createNewOption = actionData => dispatch => {
  const { apiName, actionType, data } = actionData;
  const url = `${baseUrl}/${apiName}`;
  let params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_NEW_COMPANY,
  };
  if(apiName == 'States')
    params.actionType = REQUEST_CREATE_NEW_STATE;
  return callApi(params);
};

const getAllCompanies = (data) => (dispatch) => {

  const { apiName, paramName, value } = data;
  const url = `${baseUrl}/${apiName}?search=${encodeURIComponent(value)}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_COMPANIES,
  };
  return callApi(params);
}

const getCompanyData = ({ type, id = '' }) => (dispatch) => {
  const requestType = type === 'company' ? 'companies' : 'Offices';
  const filterForOffice = encodeURIComponent(JSON.stringify({
    where: { companyId: id },
    include: [
      {
        address: [
          {
            location: ['state', 'country'],
          },
        ],
      },
    ]
  }));

  const companyUrl = `${baseUrl}/${requestType}/${
    id ? `${id}` : ''
  }`;

  const officeUrl = `${baseUrl}/${requestType}?filter=${filterForOffice}`;

  const params = {
    dispatch,
    method: 'GET',
    url: type === 'office' ? officeUrl : companyUrl,
    actionType: type === 'company' ? REQUEST_COMPANY_DATA : REQUEST_COMPANY_OFFICE_DATA,
  };

  return callApi(params);
};

const createNewResource = resourceBody => (dispatch) => {
  const { type, ...others } = resourceBody;
  const requestType = type === 'company' ? 'Companies' : 'Offices';
  const url = `${baseUrl}/${requestType}`;
  const params = {
    dispatch,
    headers: {
      'content-type': 'application/json',
    },
    method: 'PATCH',
    url,
    body: JSON.stringify(resourceBody.others),
    actionType: type === 'company' ? REQUEST_CREATE_NEW_COMPANY : REQUEST_CREATE_NEW_OFFICE,
  };

  return callApi(params);
};

const updateResource = resourceBody => (dispatch) => {
  const { type, ...others } = resourceBody;
  const requestType = type === 'company' ? 'Companies' : 'Offices';
  const url = `${baseUrl}/${requestType}`;

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PUT',
    body: JSON.stringify(others),
    url,
    actionType: type === 'company' ? REQUEST_UPDATE_COMPANY : REQUEST_UPDATE_OFFICE,
  };

  return callApi(params);
};

const deleteResource = resourceBody => (dispatch) => {
  const { type, id } = resourceBody;
  const requestType = type === 'company' ? 'Companies' : 'Offices';
  const url = `${baseUrl}/${requestType}/${id}`;

  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: type === 'company' ? REQUEST_DELETE_COMPANY : REQUEST_DELETE_OFFICE,
  };

  return callApi(params);
};

const updateOfficeResources = ({ type, body }) => (dispatch) => {
  const url = `${baseUrl}/${type}`;

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PUT',
    url,
    body: JSON.stringify(body),
    actionType: REQUEST_UPDATE_OFFICE_DATA,
  };

  return callApi(params);
};

const authorizeCompany = (data)=>(dispatch)=>{
  const url = `${baseUrl}/companymap-base/authorizeNewCompany?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_AUTHORIZE_NEWCOMPANIES,
  };
  return callApi(params);
}

const switchCompany = (companyId)=>(dispatch)=>{
  const url = `${baseUrl}/companymaps/switchCompany?companyId=${Number(companyId)}&_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    credentials: 'include',
    actionType: REQUEST_SWITCH_COMPANY,
  };
  return callApi(params);
}

export {
  getAllIndustries,
  getAllStates,
  getAllCountries,
  getCompanyData,
  getAllCompanies,
  createNewResource,
  updateResource,
  deleteResource,
  createNewOption,
  updateOfficeResources,
  getAuthorizedCompanies,
  associateCompany,
  dissociateCompany,
  authorizeCompany,
  switchCompany,
};
