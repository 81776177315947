import {
  REQUEST_CREATE_COMPANY_USER,
  REQUEST_CREATE_INSTITUTION_USER,
  REQUEST_COMPANY_SIZES,
  REQUEST_COMPANY_UEN_VALIDATION,
  REQUEST_COMPANY_TYPES,
  REQUEST_GET_SALUATION_LIST,
  REQUEST_GET_COMPANY_INDUSTRIES,
} from 'actions/actionTypes';
import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';


const createInstitutionUser = data => (dispatch) => {
  const url = `${baseUrl}/RegistrationRequest/registerInstitution?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_INSTITUTION_USER,
  };

  return callApi(params);
};

const createCompanyUser = data => (dispatch) => {
  const url = `${baseUrl}/RegistrationRequest/registerRecruiter?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_COMPANY_USER,
  };

  return callApi(params);
};

const getCompanySizes = () => (dispatch) => {
  const url = `${baseUrl}/Sizesizes?_=${new Date().getTime()}&`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_COMPANY_SIZES,
  };
  return callApi(params);
};

const getCompanyTypes = () => (dispatch) => {
  const url = `${baseUrl}/companytype-base?_=${new Date().getTime()}&`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_COMPANY_TYPES,
  };
  return callApi(params);
};

const validateCompanyUEN = data => (dispatch) => {
  const {
    companyUEN,
  } = data || {};
  if (!companyUEN) {
    const error = {
      msg: 'Invalid UEN Number',
    };
    Promise.reject(error);
  }
  const url = `${baseUrl}/companies/validate?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_COMPANY_UEN_VALIDATION,
  };

  return callApi(params);
};

const getSaluationList = () => (dispatch) => {
  const url = `${baseUrl}/Saluations?_=${new Date().getTime()}&`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_SALUATION_LIST,
  };
  return callApi(params);
};

const getCompanyIndustries = search => (dispatch) => {
  let url = `${baseUrl}/industries?_=${new Date().getTime()}&`;
  if (search) {
    url += `search=${search}`;
  }
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_COMPANY_INDUSTRIES,
  };
  return callApi(params);
};

export {
  createCompanyUser,
  createInstitutionUser,
  getCompanySizes,
  validateCompanyUEN,
  getCompanyTypes,
  getSaluationList,
  getCompanyIndustries,
};
