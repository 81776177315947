import { SET_TAB } from '../actions/actionTypes';

const tabs = (state = null, action) => {
  switch (action.type) {
    case SET_TAB:
      return action.currentTab;
    default:
      return state;
  }
};

export default tabs;
