import { withTranslation } from 'react-i18next';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

/* Common */
import NotFound from 'components/common/NotFound';

/* Recruiter */
import {
  TalentSearch,
  CandidatesList,
  CandidatesListXopa,
  NewCandidatesListXopa,
  CandidatesListAll,
  JobList,
  NewJob,
  ShortListCandidates,
  FinalOfferDetails,
  ApplicantInfo,
  TalentPool,
  GlobalTalentPool,
  CandidatesInOfferList,
  UserUsageReport,
  CandidateUsageReport,
  HelpSection,
  EmailTemplates,
  CustomizeEmail,
  SendEmail,
  AnalyticsList,
  MarketPlace,
  ApplicationDetails,
  CalendarEvents,
  SearchPage,
  ProfilePage,
  CompaniesList,
  RecruitersList,
  RecruiterAnalytics,
  UserRegistrationRequests,
  X0PAUserView,
  Reports,
  AdminAnalyticsView,
  BrowseCompanies,
  BrowseInstitutions,
  BrowseStudents,
  VideoInterviewsList,
  InterviewQuestions,
  StudentProfileView,
  CompanyRegistration,
  InstitutionView,
  CourseView,
  RegisterRequest,
  registerCandidate,
  CitiesDetails,
  LanguagesView,
  AdminAnalyticsLogs,
  NewJobReg,
  BrowseFaculties,
  StudentProfileRegister,
  Notifications,
  BrowseEmployees,
  EmployeesView,
  UserEmailConfirmation,
  RegisterPublicCandidate
} from './roboroy';

/* Enterprise */
import {
  AdminDashboard,
} from './admin';

/* Misc */
import PrivateRoute from './privateRoute';
import AdminRoute from './AdminRoute';
import userCompany from '../containers/common/userCompnay/userCompany';
import ImportStudents from 'containers/roboroy/ImportStudents';
import AnalyticsDetails from 'containers/roboroy/AnalyticsDetails';
import Analytics from 'containers/roboroy/Analytics'
import UserActivityLogs from 'containers/roboroy/UserActivityLogs';
import { NotificationTextDetails } from 'carbon-components-react';


const RecruiterRoutes = ({ match, isUserLoggedIn, roleId, isAdmin, products }) => (
  <Switch>
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus`}
      component={TalentSearch}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/candidates`}
      component={CandidatesList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/browse/cities`}
      component={CitiesDetails}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/import/students`}
      component={ImportStudents}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/browse/languages`}
      component={LanguagesView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/browse/students`}
      component={BrowseStudents}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/registration/student`}
      component={RegisterRequest}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/:id/basic/register`}
      component={registerCandidate}
    />
    {/* <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/activity/user/:userId`}
      component={UserActivityLogs}
    /> */}
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/registration/company`}
      component={() => <CompanyRegistration noPublicHeader/>}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/admin/logs`}
      component={AdminAnalyticsLogs}
    />
    {/* <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/registration/institution`}
      component={CompanyRegistration}
    /> */}
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/institution/info/:id`}
      component={InstitutionView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/institution/info/:id/:status/:facultyId`}
      component={InstitutionView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/institution/:institutionId/faculty/:facultyId`}
      component={CourseView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/company/info/:id`}
      
      component={InstitutionView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/notifications`}
      component={Notifications}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/notifications/:id`}
      component={Notifications}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/profile/:profileId/student/:studentProfileId`}
      component={StudentProfileView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/profile/:profileId/student/:studentProfileId/tab/:tabName`}
      component={StudentProfileView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/browse/companies`}
      component={BrowseCompanies}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/browse/faculties`}
      component={BrowseFaculties}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/browse/employee`}
      component={BrowseEmployees}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/browse/employee/:id`}
      component={EmployeesView}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/emailconfirmation`}
      component={UserEmailConfirmation}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/campus/update/profile/:profileId`}
      component={StudentProfileRegister}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      product="nosub"
      path={`${match.url}/campus/candidate/register/profile/:profileId/stage/index/:indexId`}
      component={RegisterPublicCandidate}
    />

    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/browse/institutions`}
      component={BrowseInstitutions}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/talentpool`}
      component={TalentPool}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/marketplaceapps`}
      component={MarketPlace}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/search/:key/type/:name/page/:page/limit/:limit`}
      component={SearchPage}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/profile/:profileId`}
      component={ProfilePage}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/new/job/registration`}
      component={NewJobReg}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/new/job/registration/:jobId`}
      component={NewJobReg}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/new/:stageIndex/:jobId`}
      component={NewJob}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/new/:stageIndex`}
      component={NewJob}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/reports`}
      component={Reports}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/new`}
      component={NewJob}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/marketplaceapps/:id`}
      component={ApplicationDetails}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/interviews/status/:status/page/:page/limit/:limit`}
      component={VideoInterviewsList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/interviews`}
      component={VideoInterviewsList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/interviews/status/:status`}
      component={VideoInterviewsList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/interviews/:id`}
      component={InterviewQuestions}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/candidates/xopa/:status/page/:page/limit/:limit`}
      component={CandidatesListAll}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/candidates/xopa/:status/page/:page`}
      component={CandidatesListAll}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/candidates/xopa/:status`}
      component={CandidatesListAll}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/analytics`}
      component={Analytics}
    />
    {/* <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/analyticsdetails`}
      component={AnalyticsDetails}
    /> */}
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs`}
      component={JobList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/:status/page/:page/limit/:limit`}
      component={JobList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/candidate/:id/job/:jobId/:tabName`}
      component={ApplicantInfo}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/candidate/:id/job/:jobId`}
      component={ApplicantInfo}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/new/candidate/app/:id/job/:jobId/:tabName`}
      component={ApplicantInfo}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/new/candidate/app/:id/job/:jobId`}
      component={ApplicantInfo}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/interviews-calendar`}
      component={CalendarEvents}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/offers`}
      component={CandidatesInOfferList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/offers/:status`}
      component={CandidatesInOfferList}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/:id/:status/page/:page/limit/:limit`}
      component={CandidatesListXopa}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/:id/:status/page/:page`}
      component={CandidatesListXopa}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/:id/:status`}
      component={CandidatesListXopa}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={[`${match.url}/campus/newui/:id/:status/page/:page`,
        `${match.url}/campus/newui/:id/:status`,
        `${match.url}/campus/newui`,
      ]}
      component={NewCandidatesListXopa}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/:id/final-offers`}
      component={FinalOfferDetails}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/emails`}
      component={EmailTemplates}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/emails/send/:templateName/:applicationId/job/:jobId`}
      component={SendEmail}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/email/:templateId`}
      component={CustomizeEmail}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/email/add`}
      component={CustomizeEmail}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/company`}
      isAdmin={isAdmin}
      component={userCompany}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/companies`}
      component={CompaniesList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/users/view`}
      component={RecruitersList}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/users/:userId`}
      component={RecruiterAnalytics}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/jobs/:status`}
      component={JobList}
    />
    <PrivateRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/shortlist`}
      component={ShortListCandidates}
    />
    {/* Global Talent Pool */ }
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/gtp`}
      component={GlobalTalentPool}
    />
    {/* Admin routes */}
    <AdminRoute
      isAuthenticated={isUserLoggedIn}
      products={products}
      path={`${match.url}/admin`}
      isAdmin={isAdmin}
      component={AdminDashboard}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/users/usagereport`}
      component={UserUsageReport}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/candidates/usagereport`}
      component={CandidateUsageReport}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/xpa/users/requests`}
      component={UserRegistrationRequests}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/xpa/users/:userId`}
      component={X0PAUserView}
    />
    <PrivateRoute
      exact
      isAuthenticated={isUserLoggedIn}
      products={products}
      product="nosub"
      path={`${match.url}/campus/help`}
      component={HelpSection}
    />
    <Route
      path={`${match.url}/*`}
      render={
        props => (<NotFound {...props} roleId={roleId} />)
      }
    />
  </Switch>
);

export default RecruiterRoutes;
