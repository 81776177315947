import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { REQUEST_TALENTPOOL, REQUEST_SEND_CUSTOMEMAIL, 
  REQUEST_SEND_EMAIL_TO_COMPANIES } from '../actionTypes';

const reRenderContent = ({ itemsPerPage = 10, activePage = 1, searchKey = null, selected = {} }) => (dispatch) => {
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/profile-base/getRecruiterTalentPool?`
  if (searchKey != null && searchKey.length > 0)
    url += `searchKey=${encodeURIComponent(searchKey)}`;
  if (selected.Companies && selected.Companies.length > 0)
    url += `&company=${encodeURIComponent(JSON.stringify(selected.Companies))}`;
  if (selected.Users && selected.Users.length > 0)
    url += `&users=${encodeURIComponent(JSON.stringify(selected.Users))}`;
  if (selected.Countries && selected.Countries.length > 0)
    url += `&location=${encodeURIComponent(JSON.stringify(selected.Countries))}`;
  if (selected.Skills && selected.Skills.length > 0)
    url += `&skills=${encodeURIComponent(JSON.stringify(selected.Skills))}`;
  if (selected.Jobname && selected.Jobname.length > 0)
    url += `&jobname=${encodeURIComponent(JSON.stringify(selected.Jobname))}`;
  if (selected.Experience && selected.Experience.length > 0)
    url += `&experienceList=${encodeURIComponent(JSON.stringify(selected.Experience))}`;
  if (selected.Buckets && selected.Buckets.length > 0)
    url += `&bucketname=${encodeURIComponent(JSON.stringify(selected.Buckets))}`;
  if (selected.Scores && selected.Scores.length > 0)
    url += `&scores=${encodeURIComponent(JSON.stringify(selected.Scores))}`;
  url += `&filter[skip]=${skip}`;
  url += `&filter[limit]=${itemsPerPage}`;

  const params = {
    actionType: REQUEST_TALENTPOOL,
    dispatch,
    url: url,
    method: 'GET',
  };

  return callApi(params);
};

const sendEmails = data => (dispatch) => {
  const url = `${baseUrl}/emailtemplates/send-email/-1`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_SEND_CUSTOMEMAIL,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const sendEmailToCompanies = data => (dispatch) => {
  const url = `${baseUrl}/invitation`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_SEND_EMAIL_TO_COMPANIES,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

export { reRenderContent, sendEmails, sendEmailToCompanies };
