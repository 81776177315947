import {
  REQUEST_WORKFLOW_DOC_TYPES,
  SUCCESS_WORKFLOW_DOC_TYPES,
  ERROR_WORKFLOW_DOC_TYPES,
  REQUEST_CREATE_DOC_TYPES,
  ERROR_CREATE_DOC_TYPES,
  SUCCESS_CREATE_DOC_TYPES,
  REQUEST_UPDATE_DOC_TYPES,
  ERROR_UPDATE_DOC_TYPES,
  SUCCESS_UPDATE_DOC_TYPES,
  REQUEST_DELETE_DOC_TYPES,
  ERROR_DELETE_DOC_TYPES,
  SUCCESS_DELETE_DOC_TYPES,
} from 'actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  documentTypes: [],
};

const DocumentsUpload = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_WORKFLOW_DOC_TYPES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_WORKFLOW_DOC_TYPES:
      return {
        ...state,
        documentTypes: payload && payload.data,
        loading: false,
        error: null,
      };
    case ERROR_WORKFLOW_DOC_TYPES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_DOC_TYPES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_DOC_TYPES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_DOC_TYPES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_DOC_TYPES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_DOC_TYPES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPDATE_DOC_TYPES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_DOC_TYPES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_DOC_TYPES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_DOC_TYPES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default DocumentsUpload;
