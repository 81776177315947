import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Loading } from 'carbon-components-react';

import { requestCfToken } from 'actions/cronofy';

class Cronofy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      isLoading: true,
      isSuccess: false,
      errorMessage: 'Unknown error',
    };
  }

  componentDidMount() {
    const qs = window.location.search.substring(1);
    const qsArr = qs.split('&');
    const qsObj = {};
    qsArr.forEach((r) => {
      if (r.includes('code=')) {
        const codeArr = r.split('code=');
        const code = codeArr && (codeArr.length === 2) && codeArr[1];
        qsObj.authCode = code;
      }
      if (r.includes('state=')) {
        const stateArr = r.split('state=');
        const state = stateArr && (stateArr.length === 2) && stateArr[1];
        qsObj.authState = state;
        if (state && !isNaN(Number(state))) {
          qsObj.connectorId = state;
        }
      }
    });

    this.props.requestCfToken(qsObj)
      .then((res) => {
        if (!res) {
          this.setState({
            isSuccess: false,
            isError: false,
            isLoading: true,
          }, () => {
            setTimeout(() => {
              window.opener.location.reload();
              window.close();
            }, 15000);
          });
          return;
        }
        if (res && res.error) {
          this.setState({
            isError: true,
            isLoading: false,
            errorMessage: res.error.message,
          });
        } else {
          this.setState({
            isSuccess: true,
            isError: false,
            isLoading: false,
          }, () => {
            if (window.opener) {
              window.opener.location.reload();
              setTimeout(() => window.close(), 2000);
            }
          });
        }
      })
      .catch((err) => {
        console.log('Error Catch:', err);
        this.setState({
          isError: true,
          isLoading: false,
          errorMessage: 'Unknown error',
        });
      });
  }

  render() {
    const {
      isLoading, isError, isSuccess, errorMessage,
    } = this.state;
    return (
      <div className="xpa-vh-100 text-center">
        {isLoading && <Loading />}
        {isError && (
          <div style={{ paddingTop: '200px' }}>
            <h4>
              Error occured while connecting the calendar,
              <br />
              please try again.
            </h4>
            <p>{errorMessage}</p>
          </div>)
        }
        <div className="xpa-vh-100">
          {
            !isError && isLoading && (
            <Fragment>
              <h4 style={{ paddingTop: '200px' }}>
                Connecting the calendar...
                <br />
                please do not close the window
              </h4>
              <p>
                This will be automatically closed once the authorization is done.<br />
                Closing the window abruptly interrupts the authorization process.
              </p>
            </Fragment>)
          }
          {
            !isError && isSuccess && !isLoading && (
              <Fragment>
                <h4 style={{ paddingTop: '200px' }}>
                  Authorization successful
                </h4>
                <p>Closing the window in 2 seconds...</p>
              </Fragment>)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
  profile: state.profile.userInfo,
});

const mapDispatchToProps = {
  requestCfToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cronofy);
