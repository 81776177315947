import React, { useEffect, useState } from 'react';

const loadGMaps = (props, callback) => {
  const {url, jwt, id} = props || {};

  const existingScript = document.getElementById('googleMaps');
  if (!existingScript) {
    const script = document.createElement('script');
    script.innerHTML = `
    var myOptions = {
      techOrder: ["azureHtml5JS", "flashSS", "html5FairPlayHLS","silverlightSS", "html5"],
      "logo": {
          "enabled": false
      },
          autoplay: false,
          controls: true,
          muted: false,
          "nativeControlsForTouch": false,
          width: "480",
          height: "360",
          fluid: true,
          playbackSpeed: {
            enabled: true,
            initialSpeed: 1.0,
            speedLevels: [
                { name: "x4.0", value: 4.0 },
                { name: "x3.0", value: 3.0 },
                { name: "x2.0", value: 2.0 },
                { name: "x1.75", value: 1.75 },
                { name: "x1.5", value: 1.5 },
                { name: "x1.25", value: 1.25 },
                { name: "normal", value: 1.0 },
                { name: "x0.75", value: 0.75 },
                { name: "x0.5", value: 0.5 },
            ]
        }
      };
      var myPlayer = amp('${id}', myOptions);
      myPlayer.src([{ src: "${url}", type: "application/vnd.ms-sstr+xml", protectionInfo: [{ type: "AES", authenticationToken: "${jwt}" }] }, ]);
    `;
    script.id = 'googleMaps';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const AmpLoader = (props) => {


  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    loadGMaps(props, () => {
      setLoaded(true);
    });
  });

  const {url, jwt, id} = props || {};

  return (
    <video id={id} class="azuremediaplayer amp-default-skin amp-big-play-centered" tabindex="0"> </video>

  );
}
export default AmpLoader;
