import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import CandidatesListXopa from './CandidatesListXopa';
class CandidatesListAll extends Component {
  constructor(props) {
    super(props);
    }

  render() {
    return (
        <CandidatesListXopa {...this.props} />
    )
  }
}

export default CandidatesListAll;
