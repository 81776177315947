import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import RoboSidebar from 'components/roboroy/Sidebar';

import BackRow from 'components/common/BackRow/BackRow';

import { candidates } from '../data';

import './CandidatesList.css';

import CandidatesTable from '../CandidatesTable';
import { fetchApplicationByCompany } from './redux/actions';

class CandidatesList extends Component {
  state = {
    searchTerm: '',
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  firXopa = () => {
    const { history } = this.props;
    history.push('/app/campus/candidates/xopa');
  };

  paths = [
    { id: 'dashboard', name: 'Dashboard', href: '/app/campus' },
    { id: 'candidates', name: 'Candidates', href: '' }
  ];

  componentDidMount() {
    const compayId = 5143;
    this.props.fetchApplicationByCompany(compayId);
  }

  render() {
    const { candidates } = this.props;

    return (
      <div className="candidates_list">
        {/* <RoboSidebar /> */}
        <div className="candidates_container">
          <div className="candidates_container__header">
            {/* <BackRow paths={this.paths} /> */}
            <div className="candidates_container__header--top">
              <div>
                <h3>Applied Candidates</h3>
                <input
                  placeholder="Search"
                  type="text"
                  name="searchTerm"
                  value={this.state.searchTerm}
                  onChange={this.handleChange}
                />
              </div>

              {/* <button className="xopa__button" onClick={this.firXopa}>
                <span>Fire up</span>
                <img src={logo} alt="xopa logo" />
              </button> */}

              <div className="talentpool">
                <h4>Talent Pool </h4>
                <div className="talent__button first">
                  <span>upload database</span>
                </div>
                <div className="talent__button">
                  <span>upload resumes</span>
                </div>
              </div>
            </div>
            <div className="candidates_container__header--subtitle">
              <p />
            </div>
          </div>
          <CandidatesTable candidates={candidates} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidates: state.applications,
});

const mapDispatchToProps = {
  fetchApplicationByCompany,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CandidatesList);
