import callApi from 'utils/callApi';
import { baseUrl, regularUrl } from 'utils/config';
import {
    REQUEST_GET_COMPANIES_USERS,
} from './actionTypes';

const getCompaniesUsersList = data => (dispatch) => {
    const {
        itemsPerPage = 10,
        activePage = 1,
        searchKey = '',
    } = data || {};
    const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
    let url = `${baseUrl}/companies/companyUsersList?`;

    url += `filter[skip]=${skip}&`;
    url += `filter[limit]=${itemsPerPage}&`;
    if (searchKey) {
        url += `searchKey=${searchKey}`;
    }

    const params = {
        dispatch,
        actionType: REQUEST_GET_COMPANIES_USERS,
        url,
        method: 'GET',
    };
    return callApi(params);
};

export {getCompaniesUsersList};