import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';

class CandidatesTable extends Component {
  state = {
    candidates: this.props.candidates || [],
  };

  render() {
    const { id } = this.props.match.params;
    return (
      <div className="candidates_container__table">
        <div className="candidate__header">
          <span className="candidate__name">Name</span>
          <span
            className={classnames('job_id', {
              opacity0: !!id,
            })}
          >
            Job Id
          </span>
          <span className="candidate__date">Date Applied</span>
          {/* <span className="candidate__internal">Internal</span> */}
          <span className="candidate__stage">current stage</span>
          {/* <span className="candidate__action">action</span> */}
        </div>
        {this.state.candidates.applications.map(candidate => (
          <CandidateRow key={candidate.id} withoutId={!!id} candidate={candidate} />
        ))}
      </div>
    );
  }
}

const candidateObject = {
  id: PropTypes.string.isRequired,
  name: PropTypes.number.isRequired,
  job_id: PropTypes.number.isRequired,
  dateApplied: PropTypes.string.isRequired,
  internal: PropTypes.oneOf(['Yes', 'No']).isRequired,
  stage: PropTypes.string.isRequired,
};

CandidatesTable.propTypes = {
  candidates: PropTypes.shape(candidateObject).isRequired,
};

const CandidateRow = ({ candidate, withoutId }) => (
  <div className="candidate__row" key={candidate.appId}>
    <span className="candidate__name">
      {candidate && candidate.profile && candidate.profile.firstName} &nbsp;
      {candidate && candidate.profile && candidate.profile.lastName}
    </span>
    <span
      className={classnames('job_id', {
        opacity0: withoutId,
      })}
    >
      {candidate && candidate.job && candidate.job.jobId}
    </span>
    <span className="candidate__date">
      {moment(candidate.dateApplied).format('MMM Do YYYY')}
    </span>
    {/* <span className="candidate__internal">{candidate.internal}</span> */}
    <span className="candidate__stage">{candidate.stage}</span>
    {/* <span className="candidate__action">
      <i className="fa fa-trash-o" aria-hidden="true" />
    </span> */}
  </div>
);

CandidateRow.propTypes = {
  candidate: PropTypes.shape(candidateObject).isRequired,
};

export default withRouter(CandidatesTable);
