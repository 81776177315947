import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
import StarRatingComponent from 'react-star-rating-component';
import _ from 'lodash';
import {
  fetchAll,
} from 'actions/talentSearch/newJob';
import {
  createStudentLanguages,
  deleteStudentLanguages,
} from 'actions/campus/internCandidate';
import {
  getCandidateProfile,
} from 'actions/talentSearch/candidatesList';
import {
  manageSkill,
} from 'actions/candidate/CandidateDetails';
import {
  REQUEST_ALL_SKILLS,
  REQUEST_CREATE_NEW_SKILLS,
  REQUEST_ALL_COMPANYTYPES,
  REQUEST_ALL_JOBFUNCTIONS,
  REQUEST_ALL_LANGUAGES,
  REQUEST_ALL_LOCATIONS,
} from 'actions/talentSearch/actionTypes';
import {
  updatePersonSkill,
} from 'actions/candidate/businessFinland';

import {
  Button,
  Pagination,
  Tile,
  Modal,
  InlineNotification,
} from 'carbon-components-react';

import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';


class SkillsAndLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: [],
      languages: [],
      rowsData: [],
      langsData: [],
      activePage: 1,
      limit: 10,
      rating: 0,
      langType: '',
      langValue: '',
      showAddLang: false,
      optLanguages: [],
      showError: false,
    };
  }

  componentDidMount() {
    this.props.fetchAll({
      actionType: REQUEST_ALL_LANGUAGES,
      apiName: 'languages',
      paramName: 'languageFull',
    })
      .then((res) => {
        const options = res && res.languages.map(one => ({ label: one.languageFull, value: one.languageId }));
        this.setState({
          optLanguages: options,
        });
      });
  }

  componentWillReceiveProps(newProps) {
    const {
      candidateProfile,
    } = newProps;
    const {
      languages,
    } = candidateProfile;
    if (this.props.candidateProfile !== newProps.candidateProfile) {
      const finalLanguageArr = languages && Array.isArray(languages)
      && languages.length > 0
      && languages.map((res) => {
        const {
          rating,
          languageId,
          personLangId,
          language: {
            languageFull,
          } = {},
          languagetype: {
            langType,
          } = {},
        } = res || {};
        const data = {};
        data.language = languageFull;
        data.rating = rating;
        data.type = langType;
        data.id = languageId;
        data.personLangId = personLangId;
        return data;
      });
      this.setState({
        languages: finalLanguageArr,
      }, () => {
        this.setLangs();
      });
    }
  }

  handlePaginationChange = ({ page, pageSize }) => {
    let { activePage = 0, limit } = this.state;
    if (page > 1) {
      activePage = (page - 1) * pageSize + 1;
      limit = activePage + pageSize - 1;
    }
    this.setState({ activePage, limit });
  };

  handleLangPaginationChange = ({ page, pageSize }) => {
    let { activeLangPage = 0, langLimit } = this.state;
    if (page > 1) {
      activeLangPage = (page - 1) * pageSize + 1;
      langLimit = activeLangPage + pageSize - 1;
    }
    this.setState({ activeLangPage, langLimit });
  };

  onAddLanguageClick = () => {
    const {
      langValue = null,
      langType = null,
      rating = null,
    } = this.state;
    if (!langValue) {
      this.setState({
        showError: true,
      });
    } else if (!langType) {
      this.setState({
        showError: true,
      });
    } else if (!rating) {
      this.setState({
        showError: true,
      });
    } else {
      let { selectedLanguages = [] } = this.state;
      selectedLanguages = !selectedLanguages || selectedLanguages === null ? [] : selectedLanguages;
      const templang = Array.isArray(selectedLanguages) && langValue.label && langType.label ? selectedLanguages.push({
        language: langValue.label,
        type: langType.label,
        rating,
        id: langValue.value,
      }) : [];
      this.setState({
        showAddLang: false,
        langValue: {},
        langType: {},
        rating: 0,
        selectedLanguages,
        languages: selectedLanguages,
      }, () => {
        const { candidateProfile: { profile: { profileId = '' } = {}, studentprofile: { studentProfileId = '' } = {} } = {} } = this.props;
        const data = { language : langValue.label ? langValue.label : '', rating, langType: langType.label ? langType.label : '' };
        this.props.createStudentLanguages(data, studentProfileId).then(() => {
          this.props.getCandidateProfile(profileId).then(() => {
            this.setLangs();
          })
        });
      });
    }
  }

  deleteLanguage = (personLangId) => {
    // const { target: { name = null } = {} } = e || {};
    const { candidateProfile: { profile: { profileId = '' } = {}, languages, studentprofile: { studentProfileId = '' } = {} } = {} } = this.props;
    // if (name === null) return;
    // let { selectedLanguages, langsData } = this.state;
    // selectedLanguages = Array.isArray(selectedLanguages) && selectedLanguages.filter(({ id } = {}) => id != name);
    // langsData = Array.isArray(langsData) && langsData.filter(({ id } = {}) => id != name);
    // const finalArr = languages && Array.isArray(languages) && languages.filter(e => e.languageId == name);
    // const personLangId = finalArr && Array.isArray(finalArr) && finalArr.length > 0 && finalArr[0].personLangId;
    // this.setState({ selectedLanguages, langsData }, () => {
      this.props.deleteStudentLanguages(studentProfileId, Number(personLangId)).then((res) => {
        if (res && !res.error) {
          this.props.getCandidateProfile(profileId).then((val) => {
            if (val && !val.error) {
              this.setLangs();
            }
          });
        }
      });
    // });
  }

  addLanguageModal = () => {
    this.setState({
      showAddLang: true,
      langValue: {},
      langType: {},
      rating: 0,
    });
  }

  closeLangModal = () => {
    this.setState({
      showAddLang: false,
      langValue: {},
      langType: {},
      rating: 0,
    });
  }

  setLangs= () => {
    const { languages: selectedLanguages = [] } = this.state;
    const { t, notEditable } = this.props;
    const langsData = Array.isArray(selectedLanguages) && selectedLanguages.map((res) => {
      const { language, rating, type, id, personLangId } = res || {};
      return ({
        lang: language,
        type,
        rating: (<StarRatingComponent
          name={language}
          starCount={5}
          value={Number(rating)}
          starColor="#5596e6"
          emptyStarColor="#ddd"
          onStarClick={this.onLangRatingClick}
          renderStarIcon={(index, val) => <i className={index <= val ? 'fa fa-thumbs-up mr-1' : 'fa fa-thumbs-up  mr-1'} />}
        />),
        action: (
          <div>
            {
              notEditable === false ? (
                <Button kind="danger" small onClick={(e) => this.deleteLanguage(personLangId)}>{t('delete')}</Button>
              ) : '-'
            }
          </div>
        ),
        id: personLangId,
      });
    });
    this.setState({ langsData });
  }
  onLangRatingClick = (nextValue, prevValue, name) => {
    this.setState({ [name]: nextValue });
  }

  handleLangChange = (selectedOption, value, name) => {
    this.setState({
      [name]: value,
    });
  };

  onLanguagesInputChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.props.fetchAll({
        actionType: REQUEST_ALL_LANGUAGES,
        apiName: 'languages',
        paramName: 'languageFull',
        value: _.toLower(value),
      })
        .then((res) => {
          const options = res && res.languages.map(one => ({ label: one.languageFull, value: one.languageId }));
          this.setState({
            optLanguages: options,
          });
          if (cb && typeof cb === 'function') cb(options);
        })
        .catch(e => console.log('Error:::::', e));
    }
  }

  handleDropdownChange = (value) => {
    this.setState({
      langValue: value,
    });
  }

  handlePreviousButtonClick = () => {
    const {
      push,
    } = this.props;
    if (push) {
      push('/app/campus/candidate/register/profile/stage/index/2');
    }
  }

  handleNextButtonClick = () => {
    const {
      push,
    } = this.props;
    if (push) {
      push('/app/campus/candidate/interncandidate/1');
    }
  }

  render() {
    const {
      t,
      notEditable,
    } = this.props;
    const headers = [
      { key: 'skill', header: t('skill') },
      { key: 'competency', header: t('competency') },
    ];
    const langHeaders = [
      { key: 'lang', header: t('language') },
      { key: 'type', header: t('type') },
      { key: 'rating', header: t('rating') },
      { key: 'action', header: t('action') },
    ];
    const {
      rowsData = [],
      langsData = [],
      activePage = 1,
      limit = 10,
      rating = 0,
      langType = '',
      langValue = '',
      showAddLang = false,
      skills = [],
      optLanguages,
      showError,
    } = this.state;
    return (
      <Fragment>
        <div className="bx--form-item modal-footer w-100">
          <div className="compitency bx--col mb-3">
            {
              <div>
                <div className="text-right">
                  {
                    notEditable === false && (
                      <Button small onClick={() => this.addLanguageModal()}>{t('addLanguage')}</Button>
                    )
                  }
                </div>
                {
                  langsData && Array.isArray(langsData)
                    && langsData.length > 0
                    && (
                      <GenericDataTable title="" headers={langHeaders} rows={langsData} />
                    )
                }
                {(Array.isArray(langsData) && langsData.length === 0 || !langsData) && (
                  <Tile className="bx--type-epsilon pt-2 pb-2 mt-2 align-items-center d-flex justify-content-center">{t('noLanguages')}</Tile>
                )}
                {/* {
                  langsData && Array.isArray(langsData) && langsData.length > 0
                    && (
                      <Pagination onChange={this.handleLangPaginationChange} pageSizes={[10, 20, 30, 40, 50]} totalItems={langsData.length} />
                    )
                } */}
                <Modal
                  open={showAddLang}
                  // modalHeading={<span>{t('addLanguage')}</span>}
                  modalLabel={<span>{t('language')}</span>}
                  primaryButtonText={t('add')}
                  secondaryButtonText={t('cancel')}
                  onRequestClose={this.closeLangModal}
                  onRequestSubmit={this.onAddLanguageClick}
                  onSecondarySubmit={this.closeLangModal}
                >
                  <div>
                    {
                      showError === true && (
                        <InlineNotification lowContrast kind="error" title="" subtitle={t('errorMessage')} />
                      )
                    }
                  </div>
                  <div>
                    <DropDownBox
                      labelClass="bx--type-epsilon"
                      // titleClass="col-xs-12 col-sm-12 col-md-3 mt-3"
                      title={t('language')}
                      options={optLanguages}
                      placeholder={t('language')}
                      onChange={this.handleLangChange}
                      onInputChange={this.onLanguagesInputChange}
                      name="langValue"
                      selectedValue={langValue}
                    />
                  </div>
                  <div>
                    <DropDownBox
                      labelClass="bx--type-epsilon"
                      // titleClass="col-xs-12 col-sm-12 col-md-3 mt-3"
                      title={t('type')}
                      options={[{ value: '1', label: 'Written' }, { value: '2', label: 'Verbal' }]}
                      placeholder={t('type')}
                      onChange={this.handleLangChange}
                      name="langType"
                      selectedValue={langType}
                    />
                  </div>
                  <div className="bx--row m-0 mt-3 ml-3">
                    <div className="bx--type-epsilon mt-1 mr-3">{t('rating')}</div>
                    <div>
                      <StarRatingComponent
                        name="rating"
                        starCount={5}
                        value={Number(rating)}
                        starColor="#5596e6"
                        emptyStarColor="#ddd"
                        onStarClick={this.onLangRatingClick}
                        renderStarIcon={(index, val) => (
                          <span>
                            <i className={index <= val ? 'fa fa-thumbs-up mr-1' : 'fa fa-thumbs-up  mr-1'} />
                          </span>
                        )}
                      />
                    </div>
                  </div>
                </Modal>
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  fetchAll,
  manageSkill,
  updatePersonSkill,
  createStudentLanguages,
  getCandidateProfile,
  deleteStudentLanguages,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(withTranslation()(SkillsAndLanguage));
