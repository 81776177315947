import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAssetAnalytics } from 'actions/talentSearch/candidatesList';
import moment from 'moment';
import Avatar from 'components/common/Avatar';

const ActionContenrForAnalytics = ({
  actionType,
  sourceName,
  assetType,
  stageName,
  substage,
  handleApplicantTabClick,
  assignedName,
  roleId,
  t,
}) => {
  let finalValue = '';

  if (actionType === 'screening') {
    if (sourceName === 'recruiter' || sourceName === 'candidate') {
      finalValue = <span><strong className="text-capitalize">{sourceName}</strong> {t('hasUploadedResume')}</span>;
    }
  } else if (actionType === 'started' && assetType === 'workflow') {
    finalValue = <span>{t('createdWorkflowMsg')}</span>;
  } else if (assetType === 'substage') {
    if (actionType === 'started' || actionType === 'completed') {
      finalValue = <span>{t('has')} {actionType} <b>{substage}</b> {t('phaseOf')} <Link to="#" onClick={() => handleApplicantTabClick('applicant_workflow')}><b>{stageName}.</b></Link></span>;
    } else if (actionType === 'assigned') {
      finalValue = <span>{t('has')} {t('assigned')} <b>{substage}</b> {t('phaseOf')} <Link to="#" onClick={() => handleApplicantTabClick('applicant_workflow')}><b>{stageName}</b></Link> {t('to')} <span className="text-capitalize">{assignedName}</span>.</span>;
    } else if (actionType === 'delete-assigned') {
      finalValue = <span>{t('has')} {t('removed')} <span className="text-capitalize">{assignedName}</span> {t('asPanelMember')} <b>{substage}</b> {t('phaseOf')} <Link to="#" onClick={() => handleApplicantTabClick('applicant_workflow')}><b>{stageName}</b></Link>.</span>;
    }
  } else if (assetType === 'stage') {
    if (actionType === 'created') {
      finalValue = <span>{t('applicationMovedTo')} <Link to="#" onClick={() => handleApplicantTabClick('applicant_workflow')}><b>{stageName}.</b></Link></span>;
    } else if (actionType === 'completed') {
      finalValue = <span>{t('has')} {t('completed')} <Link to="#" onClick={() => handleApplicantTabClick('applicant_workflow')}><b>{/*{stageName}*/} Interview.</b></Link></span>;
    } else if (actionType === 'skipped' || actionType === 'deleted' || actionType === 'added') {
      finalValue = <span>{t('has')} {actionType} <Link to="#" onClick={() => handleApplicantTabClick('applicant_workflow')}><b>{stageName}.</b></Link></span>;
    }
  } else if (actionType === 'shortlist-email-sent' || actionType === 'consent-email-sent' || actionType === 'offer-detail-sent') {
    finalValue = <span>{t('has')} {t('sent')} {actionType === 'offer-detail-sent' ? <b>an {t('offer')}</b> : <b>{actionType.split('-email-sent')[0]} {t('email')}</b>} {t('to')} {t('the')} {t('applicant')}.</span>;
  } else if (actionType === 'candidate-accepts-consent' || actionType === 'candidate-rejects-consent') {
    finalValue = <span>{t('has')} <b>{actionType === 'candidate-accepts-consent' ? `${t('accepted')}` : `${t('rejected')}`}</b> {t('the')} <b>{t('offer')} {t('consent')} {t('email')}.</b></span>;
  } else if (actionType === 'offer-accepted' || actionType === 'offer-rejected') {
    finalValue = <span>{t('has')} <b>{actionType === 'offer-accepted' ? 'accepted' : 'rejected'}</b> {t('the')} <b>{t('offer')}.</b></span>;
  } else if (actionType === 'is-hired' || actionType === 'is-not-hired') {
    if(roleId!==3){
      finalValue = <span>{t('markedApplicantAs')} <b>{actionType === 'is-hired' ? `${t('hired')}` : `${t('no')} ${t('Hired')}`}</b></span>;
    }else{
      finalValue = actionType === 'is-hired' ? <span>{t('has')} {t('been')} <b>{t('hired')}</b></span> : <span>{t('is')} <b>{t('not')} {t('hired')}</b></span>;
    }
  } else {
    switch (actionType) {
      case 'transferjob':
        finalValue = <span>{t('has')} <b>transfered</b>  {t('the')} {t('application')}.</span>;
        break;
      case 'shortlisted':
        finalValue = <span>{t('has')} <b>{t('shortlisted')}</b> {t('the')} {t('application')}.</span>;
        break;
      case 'rejected':
        finalValue = <span>{t('has')} <b>{t('rejected')}</b> {t('the')} {t('application')}.</span>;
        break;
      case 'shortlist-email-sent':
        finalValue = <span>{t('has')} {t('sent')} <b>{t('shortlist')} {t('email')}</b> {t('to')} {t('the')} {t('applicant')}.</span>;
        break;
      case 'emailacceptance':
        finalValue = <span>{t('has')} {t('accepted')} {t('the')} {t('email')}.</span>;
        break;
      case 'interviewing':
        finalValue = <span>{t('has')} {t('startedInterviewing')}</span>;
        break;
      case 'recruiter-rejects-interview':
        finalValue = <span>{t('has')} {t('sent')} <b>{t('reject')}</b> {t('email')} {t('to')} {t('the')} {t('applicant')}.</span>;
        break;
      case 'reopen-hiring':
        finalValue = <span>{t('has')} {t('reserHiringStatus')}</span>;
        break;
      case 'request-reference-invite':
        finalValue = <span>{t('has')} {t('requestedRef')}</span>;
        break;
      default:
        break;
    }
  }
  return finalValue;
};

class AssetAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    const { application, t } = this.props;
    const { appId } = application || {};
    if (appId) {
      this.props.getAssetAnalytics(appId);
    }
  }

  render() {
    const {
      timeLine, handleApplicantTabClick, t, propsTimeline
    } = this.props;
    let data = [];
    if (propsTimeline && Array.isArray(propsTimeline) && propsTimeline.length > 0) {
      data = propsTimeline;
    } else {
      data = timeLine;
    }
    return (
      <Fragment>
        {
  data && Array.isArray(data) && data.length > 0
    && data.map((res) => {
      const {performedBy}=res;
      const {isSuperAdmin, isAdmin, company:{ctypeId}}=performedBy;
      const roleId= res && res.performedBy && res.performedBy.roleId;
      const isPerfomedByAdmin=((isSuperAdmin===true && parseInt(roleId)===1)|| (isAdmin===true && parseInt(ctypeId)===2 && parseInt(roleId)===4))
      const recruiterName = isPerfomedByAdmin===true?`RP Admin`:`${res && res.performedBy && res.performedBy.firstname} ${res && res.performedBy && res.performedBy.lastname}`;
      const assignedName = `${res && res.assignedTo && res.assignedTo.firstname} ${res && res.assignedTo && res.assignedTo.lastname}`;
      return (
        <div>
          { res && res.actionType && res.actionType.toString()
            && res.actionType.toLowerCase() === 'pending' ? null : (
              <div>
                <div className="d-flex">
                  <div className="mr-2">
                    <span>
                      {
                        res && res.actionType
                          && res.actionType.toString()
                          && res.actionType.toLowerCase() !== 'screening'
                          && res.actionType.toLowerCase() !== 'pending'
                          && res.actionType.toLowerCase() !== 'created'
                          ? (
                            <Avatar
                              user={{
                                firstname: res && res.performedBy && res.performedBy.firstname,
                                lastname: res && res.performedBy && res.performedBy.lastname,
                                picture: res && res.performedBy && res.performedBy.picture ? res.performedBy.picture : null,
                              }}
                              classNameInfo="rounded-circle"
                              size="40"
                            />
                          ) : (
                            <span>
                              {
                                res && res.actionType
                                  && res.actionType.toString()
                                  && res.actionType.toLowerCase() !== 'screening'
                                  && res.actionType.toLowerCase() === 'created'
                                  ? (
                                    <svg style={{ fill: "#6c757d" }} width="30" height="30" viewBox="0 0 16 16"><path d="M8 14.5c3.6 0 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"></path><path d="M11.3 4.7l1.4 1.4L6.8 12 3.3 8.6l1.4-1.4 2.1 2z"></path></svg>
                                  ) : (
                                    <span>
                                      {
                                        res && res.actionType
                                        && res.actionType.toString()
                                        && (
                                          <svg style={{ fill: "#6c757d" }} width="30" height="30" viewBox="0 0 24 24"><path d="M5 6.6l1.2 1.1L11 3v13h2V3l4.8 4.7L19 6.6 12 0z"></path><path d="M22 14v6H2v-6H0v10h24V14z"></path></svg>
                                        )
                                      }
                                    </span>
                                  )
                              }
                            </span>
                          )
                      }
                    </span>
                  </div>
                  <div>
                    <div>
                      {res && res.actionType
                        && res.actionType.toString()
                        && res.actionType.toLowerCase() !== 'screening'
                        && (
                          <span><b className="text-capitalize">{recruiterName}&nbsp;</b></span>
                        )}
                      <ActionContenrForAnalytics
                        handleApplicantTabClick={handleApplicantTabClick}
                        assignedName={assignedName}
                        t={t}
                        roleId={roleId}
                        actionType={res
                          && res.actionType && res.actionType.toString() && res.actionType.toLowerCase()}
                        sourceName={res.applicationsource
                          && res.applicationsource.sourceName
                          && res.applicationsource.sourceName.toString()
                          && res.applicationsource.sourceName.toLowerCase()}
                        assetType={res
                          && res.assetType && res.assetType.toString() && res.assetType.toLowerCase()}
                        // stageName={res && res.workflowstage
                        //   && res.workflowstage.stageName}
                        stageName='Interview'
                        substage={res && res.workflowsubstages && res.workflowsubstages.substage}
                      />
                    </div>
                    <div>
                      {
                        res && res.actionDate
                        && (
                          moment(res.actionDate).fromNow()
                        )
                      }
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            )
          }
        </div>
      );
    })
}

      </Fragment >
    );
  }
}

const mapStateToProps = state => ({
  loading: state.roboroyApplications.loading,
  user: state.x0paDuser.user,
  propsTimeline: state.roboroyApplications.timeLine,
});

const mapDispatchToProps = {
  getAssetAnalytics,
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withTranslation()(AssetAnalytics));

const ActionContenrForAnalyticsTranlation = (withTranslation()(ActionContenrForAnalytics));
export { ActionContenrForAnalyticsTranlation };

