import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { REQUEST_CANDASHBOARD_DATA, REQUEST_CANDIDATE_RECOMMENDED_JOBS, REQUEST_GET_PROFILE_VIEWS } from '../actionTypes';

const fetchCandidateDashboard = () => (dispatch) => {
  const url = `${baseUrl}/applications/dashboards?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CANDASHBOARD_DATA,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getRecommendedJobs = (profileId = 0, jobId = 0, companyId, appId) => (dispatch) => {
  // if (!jobId && !profileId) {
  //   return Promise.reject({
  //     error: true,
  //     message: 'Missing profile id',
  //     data: null,
  //   });
  // }
  let url = `${baseUrl}/Candidatejobmatches/getRecommendedJobs?_=${new Date().getTime()}`;
  if (jobId) {
    url += `&jobId=${jobId}`;
  }
  if (profileId) {
    url += `&profileId=${profileId}`;
  }
  if (companyId) {
    url += `&companyId=${companyId}`;
  }

  if (appId) {
    url += `&appId=${appId}`;
  }
  const params = {
    dispatch,
    actionType: REQUEST_CANDIDATE_RECOMMENDED_JOBS,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const uploadResume = (data) => (dispatch) =>{
  const { profileId, currFile } = data || {};

  if(!profileId)
    return;
  const url = `${baseUrl}/persondocs/${profileId}/resume`;
  const params = {
    dispatch,
    actionType: "REQUEST_UPLOAD_RESUME",
    url,
    method: 'POST',
    body: currFile
  };

  return callApi(params);
}

const getCandidateProfileView = candidateUserId => (dispatch) => {
  const url = `${baseUrl}/profileviews?_=${new Date().getTime()}&candidateUserId=${candidateUserId}`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_PROFILE_VIEWS,
    url,
    method: 'GET',
  };

  return callApi(params);
};

export {
  fetchCandidateDashboard,
  getRecommendedJobs,
  getCandidateProfileView,
  uploadResume
};
