import mixpanel from 'mixpanel-browser';

mixpanel.init('e98d6026cb04eabeaa388c921c8ccb40');

const env_check = process.env.NODE_ENV.trim().toLowerCase() === 'production';

const actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;