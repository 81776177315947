import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { RecruiterView, CandidateErrorView } from 'components/roboroy/invite/invite';

import { offerConsentStatus, getOfferConsent } from 'actions/invite';

import 'containers/roboroy/invite/invite.css';
import { Button } from 'carbon-components-react';

class OfferInviteReject extends React.Component {
  state = {
    error: false,
    offerConsentAccept: '',
  }

  componentDidMount() {
    const {
      match: {
        params: { inviteHash },
      },
    } = this.props;
    this.props.getOfferConsent(inviteHash).then((res) => {
      if (res && res.error) {
        this.setState({
          error: true,
          loading: false,
          errMessage: (res.error.message) || '',
        });
        return;
      }
      const {jobInfo={},company={},profile:{profileId, teamId}={}} = res;
      this.setState({
        company: res.company,
        job: res.jobInfo,
        profile: res.profile,
        profileId: profileId,
        jobId: jobInfo.jobId,
        teamId: teamId,
        loading: false,
      });
    });
  }

  onRejectInviteConsent = () => {
    const {
      match: {
        params: { inviteHash },
      },
    } = this.props;
    this.setState({
      offerConsentAccept: false,
    }, () => {
      const data = {
        inviteHash,
        offerConsentAccept: this.state.offerConsentAccept,
      };
      this.props.offerConsentStatus(data).then((res) => {
        if (res && res.error) {
          this.setState({
            error: true,
          });
        }
        this.setState({
          offerConsentAccept: res && res.acceptOfferConsent && res.acceptOfferConsent.offerConsentAccept,
        });
      });
      this.props.history.push(`/app/campus/candidate`);
    });
  }

  render() {
    const { error } = this.state;
    const { isUserLoggedIn, user: { roleId } = {} } = this.props;
    return (
      <div className="row" style={{ marginLeft: 0, marginRight: 0, height: '100%' }}>
        <div className="col-md-12 invite-content">
          <div className="body-register">
            <div className="card-invite">
              <div className="card card-content">
                {
                  (!isUserLoggedIn || (roleId && roleId === 3)) && !error && (
                    <Fragment>
                      <div className="ml-loo-wrap d-flex justify-content-center">
                        <img src={require('assets/images/republic-polytechnic.png')} alt="" height="auto" width="auto" />
                      </div>
                      <div className="title-register">We are sorry to see you go.</div>
                      <div className="text-center">
                        <div>
                          Are you sure you want to reject the offer consent, if so click the following button.
                        </div>
                        <div>
                          <Button
                            small
                            className="mt-2"
                            onClick={this.onRejectInviteConsent}
                            kind="danger"
                          >
                            Reject Consent
                          </Button>
                        </div>
                      </div>
                    </Fragment>
                  )
                }
                {
                  (isUserLoggedIn && (roleId !== 3)) && <RecruiterView />
                }
                {
                  (!isUserLoggedIn || (roleId && roleId === 3)) && (error) && <CandidateErrorView />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.jobs.loading,
  error: state.jobs.error,
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  offerConsentStatus,
  getOfferConsent,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferInviteReject);
