export const REQUEST_XOPA_APPLICATIONS = 'REQUEST_XOPA_APPLICATIONS';
export const ERROR_XOPA_APPLICATIONS = 'ERROR_XOPA_APPLICATIONS';
export const SUCCESS_XOPA_APPLICATIONS = 'SUCCESS_XOPA_APPLICATIONS';

export const REQUEST_TALENTPOOL = 'REQUEST_TALENTPOOL';
export const ERROR_TALENTPOOL = 'ERROR_TALENTPOOL';
export const SUCCESS_TALENTPOOL = 'SUCCESS_TALENTPOOL';

export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA';
export const ERROR_DASHBOARD_DATA = 'ERROR_DASHBOARD_DATA';
export const SUCCESS_DASHBOARD_DATA = 'SUCCESS_DASHBOARD_DATA';

export const REQUEST_CANDIDATE_CONTENT = 'REQUEST_CANDIDATE_CONTENT';
export const ERROR_CANDIDATE_CONTENT = 'ERROR_CANDIDATE_CONTENT';
export const SUCCESS_CANDIDATE_CONTENT = 'SUCCESS_CANDIDATE_CONTENT';

export const REQUEST_ALLCANDIDATE_CONTENT = 'REQUEST_ALLCANDIDATE_CONTENT';
export const ERROR_ALLCANDIDATE_CONTENT = 'ERROR_ALLCANDIDATE_CONTENT';
export const SUCCESS_ALLCANDIDATE_CONTENT = 'SUCCESS_ALLCANDIDATE_CONTENT';

export const REQUEST_DELETE_APPLICANT_INFO = 'REQUEST_DELETE_APPLICANT_INFO';
export const SUCCESS_DELETE_APPLICANT_INFO = 'SUCCESS_DELETE_APPLICANT_INFO';
export const ERROR_DELETE_APPLICANT_INFO = 'ERROR_DELETE_APPLICANT_INFO';

export const REQUEST_DELETE_CANDIDATE = 'REQUEST_DELETE_CANDIDATE';
export const ERROR_DELETE_CANDIDATE = 'ERROR_DELETE_CANDIDATE';
export const SUCCESS_DELETE_CANDIDATE = 'SUCCESS_DELETE_CANDIDATE';

export const REQUEST_DOWNLOAD_CANDIDATE = 'REQUEST_DOWNLOAD_CANDIDATE';
export const ERROR_DOWNLOAD_CANDIDATE = 'ERROR_DOWNLOAD_CANDIDATE';
export const SUCCESS_DOWNLOAD_CANDIDATE = 'SUCCESS_DOWNLOAD_CANDIDATE';

export const REQUEST_GET_MORE_CANDIDATES_AISOURCING = 'REQUEST_GET_MORE_CANDIDATES_AISOURCING';
export const SUCCESS_GET_MORE_CANDIDATES_AISOURCING = 'SUCCESS_GET_MORE_CANDIDATES_AISOURCING';


export const REQUEST_JOB_ANALYTICS_DASHBOARD_COUNT = 'REQUEST_JOB_ANALYTICS_DASHBOARD_COUNT';
export const ERROR_JOB_ANALYTICS_DASHBOARD_COUNT = 'ERROR_JOB_ANALYTICS_DASHBOARD_COUNT';
export const SUCCESS_JOB_ANALYTICS_DASHBOARD_COUNT = 'SUCCESS_JOB_ANALYTICS_DASHBOARD_COUNT';

export const REQUEST_CANDIDATE_TALENTPOOL_ADD = 'REQUEST_CANDIDATE_TALENTPOOL_ADD';
export const ERROR_CANDIDATE_TALENTPOOL_ADD = 'ERROR_CANDIDATE_TALENTPOOL_ADD';
export const SUCCESS_CANDIDATE_TALENTPOOL_ADD = 'SUCCESS_CANDIDATE_TALENTPOOL_ADD';

export const REQUEST_CANDIDATE_SHORTLIST_UPDATE = 'REQUEST_CANDIDATE_SHORTLIST_UPDATE';
export const ERROR_CANDIDATE_SHORTLIST_UPDATE = 'ERROR_CANDIDATE_SHORTLIST_UPDATE';
export const SUCCESS_CANDIDATE_SHORTLIST_UPDATE = 'SUCCESS_CANDIDATE_SHORTLIST_UPDATE';

export const REQUEST_BULK_SHORTLIST_REJECT = 'REQUEST_BULK_SHORTLIST_REJECT';
export const ERROR_BULK_SHORTLIST_REJECT = 'ERROR_BULK_SHORTLIST_REJECT';
export const SUCCESS_BULK_SHORTLIST_REJECT = 'SUCCESS_BULK_SHORTLIST_REJECT';

export const REQUEST_UPDATE_PLACEMENT = 'REQUEST_UPDATE_PLACEMENT';
export const ERROR_UPDATE_PLACEMENT = 'ERROR_UPDATE_PLACEMENT';
export const SUCCESS_UPDATE_PLACEMENT = 'SUCCESS_UPDATE_PLACEMENT';

export const REQUEST_RESUME_UPLOAD = 'REQUEST_RESUME_UPLOAD';
export const ERROR_RESUME_UPLOAD = 'ERROR_RESUME_UPLOAD';
export const SUCCESS_RESUME_UPLOAD = 'SUCCESS_RESUME_UPLOAD';

export const REQUEST_JOB_DATA = 'REQUEST_JOB_DATA';
export const ERROR_JOB_DATA = 'ERROR_JOB_DATA';
export const SUCCESS_JOB_DATA = 'SUCCESS_JOB_DATA';

export const REQUEST_TALENTPOOL_SEARCH = 'REQUEST_TALENTPOOL_SEARCH';
export const ERROR_TALENTPOOL_SEARCH = 'ERROR_TALENTPOOL_SEARCH';
export const SUCCESS_TALENTPOOL_SEARCH = 'SUCCESS_TALENTPOOL_SEARCH';

export const REQUEST_REGISTRATION = 'REQUEST_REGISTRATION';
export const ERROR_REGISTRATION = 'ERROR_REGISTRATION';
export const SUCCESS_REGISTRATION = 'SUCCESS_REGISTRATION';

export const REQUEST_ASSET_ANALYTICS = 'REQUEST_ASSET_ANALYTICS';
export const ERROR_ASSET_ANALYTICS = 'ERROR_ASSET_ANALYTICS';
export const SUCCESS_ASSET_ANALYTICS = 'SUCCESS_ASSET_ANALYTICS';


// Actions related to New Job
export const REQUEST_PARSE_JD = 'REQUEST_PARSE_JD';
export const SUCCESS_PARSE_JD = 'SUCCESS_PARSE_JD';
export const REQUEST_DROPDOWN_DATA = 'REQUEST_DROPDOWN_DATA';
export const SUCCESS_DROPDOWN_DATA = 'SUCCESS_DROPDOWN_DATA';
export const REQUEST_JD_ASSOCIATE = 'REQUEST_JD_ASSOCIATE';
export const SUCCESS_JD_ASSOCIATE = 'SUCCESS_JD_ASSOCIATE';
export const REQUEST_ALL_SKILLS = 'REQUEST_ALL_SKILLS';
export const SUCCESS_ALL_SKILLS = 'SUCCESS_ALL_SKILLS';
export const REQUEST_ALL_JOB_TITLES = 'REQUEST_ALL_JOB_TITLES';
export const REQUEST_CREATE_NEW_JOB_TITLE = 'REQUEST_CREATE_NEW_JOB_TITLE';
export const SUCCESS_CREATE_NEW_JOB_TITLE = 'SUCCESS_CREATE_NEW_JOB_TITLE';
export const SUCCESS_ALL_JOB_TITLES = 'SUCCESS_ALL_JOB_TITLES';
export const REQUEST_CREATE_NEW_SKILLS = 'REQUEST_CREATE_NEW_SKILLS';
export const SUCCESS_CREATE_NEW_SKILLS = 'SUCCESS_CREATE_NEW_SKILLS';
export const REQUEST_CREATE_NEW_COMPANY = 'REQUEST_CREATE_NEW_COMPANY';
export const SUCCESS_CREATE_NEW_COMPANY = 'SUCCESS_CREATE_NEW_COMPANY';
export const REQUEST_CREATE_NEW_JOB = 'REQUEST_CREATE_NEW_JOB';
export const SUCCESS_CREATE_NEW_JOB = 'SUCCESS_CREATE_NEW_JOB';
export const REQUEST_TOPFIVE_RESULTS = 'REQUEST_TOPFIVE_RESULTS';
export const SUCCESS_TOPFIVE_RESULTS = 'SUCCESS_TOPFIVE_RESULTS';

export const REQUEST_JOB_APPROVAL_LIST = 'REQUEST_JOB_APPROVAL_LIST';
export const SUCCESS_JOB_APPROVAL_LIST = 'SUCCESS_JOB_APPROVAL_LIST';
export const ERROR_JOB_APPROVAL_LIST = 'ERROR_JOB_APPROVAL_LIST';

export const REQUEST_CREATE_APPROVAL_LIST = 'REQUEST_CREATE_APPROVAL_LIST';
export const SUCCESS_CREATE_APPROVAL_LIST = 'SUCCESS_CREATE_APPROVAL_LIST';
export const ERROR_CREATE_APPROVAL_LIST = 'ERROR_CREATE_APPROVAL_LIST';

export const REQUEST_EDIT_APPROVAL_LIST = 'REQUEST_EDIT_APPROVAL_LIST';
export const SUCCESS_EDIT_APPROVAL_LIST = 'SUCCESS_EDIT_APPROVAL_LIST';
export const ERROR_EDIT_APPROVAL_LIST = 'ERROR_EDIT_APPROVAL_LIST';

export const REQUEST_DELETE_APPROVAL_LIST = 'REQUEST_DELETE_APPROVAL_LIST';
export const SUCCESS_DELETE_APPROVAL_LIST = 'SUCCESS_DELETE_APPROVAL_LIST';
export const ERROR_DELETE_APPROVAL_LIST = 'ERROR_DELETE_APPROVAL_LIST';

export const REQUEST_APPLICATION_SHARE = 'REQUEST_APPLICATION_SHARE';
export const SUCCESS_APPLICATION_SHARE = 'SUCCESS_APPLICATION_SHARE';
export const ERROR_APPLICATION_SHARE = 'ERROR_APPLICATION_SHARE';

export const REQUEST_JOB_NAMES = 'REQUEST_JOB_NAMES';
export const SUCCESS_JOB_NAMES = 'SUCCESS_JOB_NAMES';
export const ERROR_JOB_NAMES = 'ERROR_JOB_NAMES';

export const REQUEST_ALL_COUNTRIES = 'REQUEST_ALL_COUNTRIES';
export const SUCCESS_ALL_COUNTRIES = 'SUCCESS_ALL_COUNTRIES';

export const REQUEST_ALL_COMPANIES = 'REQUEST_ALL_COMPANIES';
export const SUCCESS_ALL_COMPANIES = 'SUCCESS_ALL_COMPANIES';

export const REQUEST_MANAGE_QUESTION = 'REQUEST_MANAGE_QUESTION';
export const SUCCESS_MANAGE_QUESTION = 'SUCCESS_MANAGE_QUESTION';

export const REQUEST_ALL_OFFICES = 'REQUEST_ALL_OFFICES';
export const SUCCESS_ALL_OFFICES = 'SUCCESS_ALL_OFFICES';

export const REQUEST_ALL_COMPANYTYPES = 'REQUEST_ALL_COMPANYTYPES';
export const SUCCESS_ALL_COMPANYTYPES = 'SUCCESS_ALL_COMPANYTYPES';

export const REQUEST_ALL_JOBFUNCTIONS = 'REQUEST_ALL_JOBFUNCTIONS';
export const SUCCESS_ALL_JOBFUNCTIONS = 'SUCCESS_ALL_JOBFUNCTIONS';

export const REQUEST_ALL_LANGUAGES = 'REQUEST_ALL_LANGUAGES';
export const SUCCESS_ALL_LANGUAGES = 'SUCCESS_ALL_LANGUAGES';

export const REQUEST_ALL_LOCATIONS = 'REQUEST_ALL_LOCATIONS';
export const SUCCESS_ALL_LOCATIONS = 'SUCCESS_ALL_LOCATIONS';



//Actions related to enterprise
export const REQUEST_ENTERPRISE_DASHBOARD_DATA = 'REQUEST_ENTERPRISE_DASHBOARD_DATA';
export const SUCCESS_ENTERPRISE_DASHBOARD_DATA = 'SUCCESS_ENTERPRISE_DASHBOARD_DATA';
export const ERROR_ENTERPRISE_DASHBOARD_DATA = 'ERROR_ENTERPRISE_DASHBOARD_DATA';

export const REQUEST_ENTERPRISE_ATTRIBUTES_DATA = 'REQUEST_ENTERPRISE_ATTRIBUTES_DATA';
export const SUCCESS_ENTERPRISE_ATTRIBUTES_DATA = 'SUCCESS_ENTERPRISE_ATTRIBUTES_DATA';
export const ERROR_ENTERPRISE_ATTRIBUTES_DATA = 'ERROR_ENTERPRISE_ATTRIBUTES_DATA';

export const REQUEST_ENTERPRISE_MEASURES_DATA = 'REQUEST_ENTERPRISE_MEASURES_DATA';
export const SUCCESS_ENTERPRISE_MEASURES_DATA = 'SUCCESS_ENTERPRISE_MEASURES_DATA';
export const ERROR_ENTERPRISE_MEASURES_DATA = 'ERROR_ENTERPRISE_MEASURES_DATA';

export const REQUEST_DIVERSITY_DATA = 'REQUEST_DIVERSITY_DATA';
export const SUCCESS_DIVERSITY_DATA = 'SUCCESS_DIVERSITY_DATA';
export const ERROR_DIVERSITY_DATA = 'ERROR_DIVERSITY_DATA';

export const REQUEST_DIMENSION_DATA = 'REQUEST_DIMENSION_DATA';
export const SUCCESS_DIMENSION_DATA = 'SUCCESS_DIMENSION_DATA';
export const ERROR_DIMENSION_DATA = 'ERROR_DIMENSION_DATA';

export const REQUEST_PAYGAP_DATA = 'REQUEST_PAYGAP_DATA';
export const SUCCESS_PAYGAP_DATA = 'SUCCESS_PAYGAP_DATA';
export const ERROR_PAYGAP_DATA = 'ERROR_PAYGAP_DATA';

export const REQUEST_TASK_STATUS = 'REQUEST_TASK_STATUS';
export const ERROR_TASK_STATUS = 'ERROR_TASK_STATUS';
export const SUCCESS_TASK_STATUS = 'SUCCESS_TASK_STATUS';

export const REQUEST_BULK_LIKE = 'REQUEST_BULK_LIKE';
export const SUCCESS_BULK_LIKE = 'SUCCESS_BULK_LIKE';
export const ERROR_BULK_LIKE = 'ERROR_BULK_LIKE';

export const REQUEST_REFERSH_SCORES = 'REQUEST_REFERSH_SCORES';
export const SUCCESS_REFERSH_SCORES = 'SUCCESS_REFERSH_SCORES';
export const ERROR_REFERSH_SCORES = 'ERROR_REFERSH_SCORES';

export const REQUEST_OTHER_JOBS = 'REQUEST_OTHER_JOBS';
export const SUCCESS_OTHER_JOBS = 'SUCCESS_OTHER_JOBS';
export const ERROR_OTHER_JOBS = 'ERROR_OTHER_JOBS';

export const REQUEST_CANDIDATE_PROFILE = 'REQUEST_CANDIDATE_PROFILE';
export const SUCCESS_CANDIDATE_PROFILE = 'SUCCESS_CANDIDATE_PROFILE';
export const ERROR_CANDIDATE_PROFILE = 'ERROR_CANDIDATE_PROFILE';

export const REQUEST_EDIT_CANDIDATE_PROFILE = 'REQUEST_EDIT_CANDIDATE_PROFILE';
export const SUCCESS_EDIT_CANDIDATE_PROFILE = 'SUCCESS_EDIT_CANDIDATE_PROFILE';
export const ERROR_EDIT_CANDIDATE_PROFILE = 'ERROR_EDIT_CANDIDATE_PROFILE';

export const REQUEST_GET_WORKDAYS_LIST = 'REQUEST_GET_WORKDAYS_LIST';
export const SUCCESS_GET_WORKDAYS_LIST = 'SUCCESS_GET_WORKDAYS_LIST';
export const ERROR_GET_WORKDAYS_LIST = 'ERROR_GET_WORKDAYS_LIST';

export const REQUEST_UPDATE_APPROVAL_LIST = 'REQUEST_UPDATE_APPROVAL_LIST';
export const ERROR_UPDATE_APPROVAL_LIST = 'ERROR_REQUEST_EDIT_APPROVAL_LIST';
export const SUCCESS_UPDATE_APPROVAL_LIST = 'SUCCESS_UPDATE_APPROVAL_LIST';
