import {
  REQUEST_GLOBAL_SEARCH,
  ERROR_GLOBAL_SEARCH,
  SUCCESS_GLOBAL_SEARCH,
} from '../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  results: {},
};

const globalSearch = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case REQUEST_GLOBAL_SEARCH:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GLOBAL_SEARCH: {
      return {
        ...state,
        loading: false,
        error: null,
        results: payload,
      };
    }
    case ERROR_GLOBAL_SEARCH:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default globalSearch;
