import { withTranslation } from "react-i18next";
import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './DragablePreferences.css';

const DragableComponent = (props) => {
  const {
    onDragEnd,
    Data,
    disabled,
    dataText = null,
    t,
    xtype,
    splitValues,
  } = props;
  let FinalResult = [];
  if (splitValues === true) {
    FinalResult = Array.isArray(Data)
    ? Data.map((one) => {
        return {
          id:
            one && one.toString()
            && one
              .toLowerCase()
              .replace(/\//g, "_")
              .replace(/-/g, "_")
              .replace(/ /g, "_"),
          content: one && one.toString() && one.replace(/\//g, "/ ")
        };
      })
    : [];
  } else {
    FinalResult = Data;
  }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onEnd = result => {
    if (result.destination) {
      FinalResult = reorder(
        FinalResult,
        result.source.index,
        result.destination.index
      );
      if (onDragEnd) onDragEnd(FinalResult);
      props.sendResults(FinalResult);
    }
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#4f8ad6" : "darkgrey",
    color: isDragging ? "white" : "black",
    ...draggableStyle
  });

  const getItemImageStyle = (isDragging, draggableStyle, image) => {
    return {
      backgroundImage: `url(${image})`,
      backgroundPositionY: -50,
      ...draggableStyle
    };
  };

  const getListStyle = isDraggingOver => ({
    padding: grid
  });

  if (!Data || (Array.isArray(Data) && Data.length === 0)) {
    return (
      <div className="bx--type-epsilon text-center text-dark">
        {t("noPreferences")}
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={splitValues === true ? 'col-md-5 col-lg-5 col draggablehead' : 'w-100'}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {Data && Array.isArray(FinalResult) && FinalResult.length > 0
              ? FinalResult.map((item, index) =>
                  (item && item.id) ? (
                    <Draggable
                      key={item.id}
                      isDragDisabled={disabled}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={`${
                            xtype
                              ? `${splitValues === true ? 'items-list bx--tile text-white bx--row' : 'bx--tile mb-1 bx--row'}`
                              : "rounded bx--type-gamma"
                          } d-flex`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={
                            xtype
                              ? getItemImageStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                  `/images/${xtype || "industries"}/${
                                    item.id
                                  }.jpg`
                                )
                              : getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )
                          }
                        >
                          <div
                            className={splitValues === true ? 'text-center justify-content-center pt-5 w-100' : 'w-100'}
                            style={{
                              backgroundColor: `${splitValues === true ? 'rgba(0,0,0,0.4)' : 'white'}`,
                              flex: 1
                            }}
                          >
                            <div className="bx--type-epsilon d-block">
                              {
                                props.splitValues === true && (
                                  <span className="text-capitalize">
                                                                  {t(
                                (
                                  item.content.charAt(0).toLowerCase() +
                                  item.content.substring(1)
                                )
                                  .replace(/ /g, "")
                                  .replace(/\//, "")
                                  .replace(/\//, "")
                                  .replace(/-/g, "")
                              )}
                                  </span>
                                )
                              }
                              {
                                props.splitValues === false && (
                                  <div className="text-capitalize d-flex">
                                    <div className="mt-2">
                                    <i className="fas fa-grip-vertical drag_job_matches" />
                                    </div>
                                    <div className="mt-2 col-lg-3">
                                    <span>
                                      <a target="_blank" href={`/app/campus/job/${item.id}`}>{item.name}</a>
                                    </span>
                                    </div>
                                    <div className="mt-2 text-capitalize col-lg-2">
                                      {item && item.companyName}
                                    </div>
                                    <div className="mt-2 text-capitalize col-lg-1">
                                      {item && item.cityName}
                                    </div>
                                    <div className="d-flex justify-content-center mt-2">
                                      {
                                        item.skills && Array.isArray(item.skills)
                                          && item.skills.length > 0
                                          && item.skills.map((skill, idx) => {
                                            const {
                                              skill: {
                                                skillName,
                                              } = {},
                                            } = skill || {};
                                            return (
                                              <div className="">
                                                {
                                                  idx <= 2 && (
                                                    <div className="bx--tag bx--tag--custom text-capitalize xpa-name-overflow">{skillName}</div>
                                                  )
                                                }
                                              </div>
                                            );
                                          })
                                      }
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                            {dataText !== null &&
                              Object.keys(dataText).length > 0 && (
                                <span className="bx--type-caption d-block">
                                  {dataText[item.id]}
                                </span>
                              )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ) : null
                )
              : null}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default withTranslation()(DragableComponent);
