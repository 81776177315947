import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  SkeletonText,
  Pagination,
  Button,
  InlineNotification,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,
  Select,
  SelectItem,
  TooltipIcon,
  Modal,
  RadioButton,
  div,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import BackRow from 'components/common/BackRow/BackRow';
import XopaTable from 'containers/roboroy/XopaTable';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import Toast from 'components/common/Toast';
import ColorLegend from 'components/common/ColorLegend';
import CustomCard from 'components/common/CustomCard';
import ColoredCircle from 'components/common/ColoredCircle';
import CreateWorkflowModal from 'components/roboroy/WorkflowModule/createWorkflow';
import WorkflowStatusUpdateModal from 'components/roboroy/WorkflowModule/workflowStatusUpdate';
import {
  ApplicationBatchUpdateModal,
  ApplicationBatchUpdateModalTranslate,
} from 'components/roboroy/common/ApplicationBatchUpdateModal';
import EmailModal from 'components/roboroy/common/EmailModal';
import ApplicantsTabs from 'components/common/ApplicantTabs';
import ProductTour from 'components/common/ProductTour/ProductTour';
import {
  AISourcedWithNoEmail,
  AISourcedHelpInfo,
  AISourcedEmailMissingWarning,
} from 'components/common/atoms';
import SetMixPanel from 'components/common/GetCurrentPath';
import {
  getCustomEmailTemplates,
  getEmailTemplates,
} from 'actions/email-templates';
import { sendEmails } from 'actions/talentSearch/talentPool';
import { getMoreCandidatesAiSourcing } from 'actions/talentSearch/candidatesList';

import {
  reRenderContent,
  findFromUserTalentPool,
  setShortlistStatus,
  addApplicantToTalentPool,
  bulkShortlistReject,
  bulkLike,
  getRefreshScores,
} from 'actions/talentSearch/candidatesList';

import { getWorkflowStages, getAllUserWorkflow } from 'actions/workflow';

import { sendEmailTemplate } from 'actions/email-templates';

import { getBucketNames, createBucket } from 'actions/talentSearch/buckets';

import JobAnalytics from './JobAnalytics';

import './CandidatesListXopa.css';
import ApplicantSort from '../../../components/common/ApplicantSort';
import { getDocumentByIds } from 'actions/document';
import { getCompanyJobPostings, transferApplications } from '../../../actions/transferJob';
import Avatar from 'components/common/Avatar';

const CardContent = ({ details, handleCandidateShortlist }) => {
  const {
    profile: { totalExp, email },
    stage,
    location,
    skillMatch,
    twoWayMatch,
    loyalty,
    performance,
    shortlisted,
    appId,
  } = details;
  let formattedLoyalty = loyalty || 0;
  formattedLoyalty =
    formattedLoyalty == -1 ? 'N/A' : Math.round(formattedLoyalty);
  let formattedPerformance = performance || 0;
  formattedPerformance =
    formattedPerformance == -1 ? 'N/A' : Math.round(formattedPerformance);

  return (
    <div className="container">
      <div>
        <small className="d-block pt-1">
          Stage: <b>{stage || '-'}</b>
        </small>
        <small className="d-block pt-1">
          Experience: <b>{Math.round(totalExp) || '-'}</b>
        </small>
        <small className="d-block pt-1">
          Location: <b>{location || '-'}</b>
        </small>
        <div className="container">
          <div className="row">
            <div className="space-top col text-center">
              {skillMatch === null ? (
                <SkeletonText className="xpa-circle--skeleton" />
              ) : (
                <ColoredCircle
                  className="xp-c__colored-circle--big"
                  getColor="getAbsoluteMatchingColor"
                  value={
                    skillMatch
                      ? Math.round(skillMatch)
                      : skillMatch === null
                        ? 'N/A'
                        : 0
                  }
                />
              )}
              <small className="text-uppercase">CV Relevance (%)</small>
            </div>
            {/* <div className="space-top col text-center">
              {twoWayMatch===null ? (
                <SkeletonText className="xpa-circle--skeleton"/>
              ) : (
                <ColoredCircle
                  className="xp-c__colored-circle--big"
                  value={twoWayMatch ? Math.round(twoWayMatch) : (twoWayMatch===null?'N/A':0)}
                />
              )}
              <small className="text-uppercase">Benchmark</small>
            </div> */}
            <div className="space-top col text-center">
              {loyalty === null ? (
                <SkeletonText className="xpa-circle--skeleton" />
              ) : (
                <ColoredCircle
                  className="xp-c__colored-circle--big"
                  getColor="getAbsoluteMatchingColor"
                  value={formattedLoyalty}
                  getColor="getLoyaltyColor"
                />
              )}
              <small className="text-uppercase">Loyalty (%)</small>
            </div>
            <div className="space-top col text-center">
              {performance === null ? (
                <SkeletonText className="xpa-circle--skeleton" />
              ) : (
                <ColoredCircle
                  className="xp-c__colored-circle--big"
                  getColor="getAbsoluteMatchingColor"
                  value={formattedPerformance}
                  getColor="getPerformanceColor"
                />
              )}
              <small className="text-uppercase">Performance (%)</small>
            </div>
          </div>
        </div>
        <div className="d-lg-block d-md-block d-none text-center">
          <hr />
          {email ? (
            <div>
              {details.hasOwnProperty('shortlisted') && shortlisted && (
                <div>
                  <div className="badge badge-outline-success mr-3">
                    Shortlisted
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="text-gray mr-0 text-small d-inline pr-2"
                    onClick={() => handleCandidateShortlist(appId, false)}>
                    Reject?
                  </a>
                </div>
              )}
              {details.hasOwnProperty('shortlisted') && shortlisted === false && (
                <div>
                  <div className="badge badge-outline-danger mr-3">
                    Rejected
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="text-gray mr-0 text-small d-inline pr-2"
                    onClick={() => handleCandidateShortlist(appId, true)}>
                    Shortlist?
                  </a>
                </div>
              )}
              {!details.hasOwnProperty('shortlisted') ||
                (shortlisted === null && (
                  <div>
                    <a
                      href="javascript:void(0)"
                      className="text-gray mr-0 text-small d-inline pr-2"
                      onClick={() => handleCandidateShortlist(appId, true)}>
                      Shortlist
                    </a>
                    <span className="pl-1 pr-1 h5 d-inline text-seondary">
                      |
                    </span>
                    <a
                      href="javascript:void(0)"
                      className="text-gray mr-0 text-small d-inline pr-2 pl-2"
                      onClick={() => handleCandidateShortlist(appId, false)}>
                      Reject
                    </a>
                  </div>
                ))}
            </div>
          ) : (
            <AISourcedEmailMissingWarning />
          )}
        </div>
      </div>
    </div>
  );
};

const RefreshScoresButton = ({ handleRefreshScores }) => (
  <Button onClick={handleRefreshScores} className="mt-0 mr-2" kind="tertiary">
    Refresh Scores
  </Button>
);

const JobAttributesStatus = (conditionCheck, displayText) => {
  return (
    <span class={`badge badge-light`}>
      {conditionCheck ? (
        <svg
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill={`${conditionCheck ? '#2d660a' : '#a53725'}`}>
          <path d="M8.498 0L10 1.502 3.672 7.83 0 4.158l1.502-1.502 2.17 2.17z"></path>
        </svg>
      ) : (
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill={`${conditionCheck ? '#2d660a' : '#a53725'}`}>
          <path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path>
        </svg>
      )}
      <span class="text-uppercase ml-1">{displayText}</span>
    </span>
  );
};

const track = {
  APPLICANTS_JOBNAME_CLICKED: 'APPLICANTS_JOBNAME_CLICKED',
};

const DetailsHeader = ({ details, t }) => {
  const {
    jobId,
    jobname,
    skills,
    // jobfunction,
    // jobseniority,
    // hourtype,
    // term,
    // isAutoPilot,
    // aiSourcing,
    // isPrivate,
  } = (details && details.job && details.job[0]) || {};
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 pt-2 pl-2">
      <div className="xpa-appl-info--job-card__info">
        <div>
          <div className="text-dark ml-1">
            <small title="Job">
              {t('requisitionId')}: {jobId}
            </small>
          </div>
          {jobname && jobname.nameName && (
            <a
              href={`/app/campus/job/${jobId}`}
              onClick={() => SetMixPanel(track.APPLICANTS_JOBNAME_CLICKED)}
              title="Designation"
              className="card-title ml-1 bx--type-delta mb-1 d-inline-block text-center font-weight-bold xpa-link text-capitalize">
              {jobname.nameName}
            </a>
          )}
          <div className="d-flex">
            {skills &&
              Array.isArray(skills) &&
              skills.length > 0 &&
              skills.map((skill, idx) => {
                const { skill: { skillName } = {} } = skill || {};
                return (
                  <div>
                    {idx <= 2 && (
                      <span className="bx--tag bx--tag--blue text-uppercase">{skillName}</span>
                    )}
                  </div>
                );
              })}
          </div>
          {/* {jobfunction
            && jobfunction.functionName
            && (
            <div className="pt-1 card-subtitle text-dark text-uppercase bx--modal-header__label">
              {jobfunction.functionName}
            </div>
            )} */}
          {/* <div className="card-subtitle pt-1">
            {jobseniority
              && jobseniority.seniorityName
              && (
              <div className="bx--tag bx--tag--local mr-2">
                {jobseniority.seniorityName}
              </div>
              )}
            {hourtype
              && hourtype.typeName
              && (
              <div className="bx--tag bx--tag--local  mr-2">
                {hourtype.typeName}
              </div>
              )}
            {term
              && term.termName
              && (
              <div className="bx--tag bx--tag--local  mr-2">
                {term.termName}
              </div>
              )}
          </div> */}
        </div>
        {/* <div className="d-flex">
          <div className="text-dark mr-1">
            {JobAttributesStatus(!isPrivate, "Public Job")}
          </div>
          <div className="text-dark mr-1">
            {JobAttributesStatus(isAutoPilot, "Auto-Pilot")}
          </div>
            <div className="text-dark mr-1">
              {JobAttributesStatus(aiSourcing, "AI Sourcing")}
            </div>
        </div> */}
      </div>
    </div>
  );
};

const NoApplicantBox = ({ t }) => (
  <div className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">{t('noApplicationsMsg')}</p>
    </div>
  </div>
);

const Divider = () => <div className="divider-vert" data-content="OR" />;

const ApplicantTableBox = ({
  selectedTab,
  tabCount,
  applications,
  minMax,
  handleCandidateShortlist,
  handleSearchSubmit,
  addCandidateToTalentPool,
  sortClicked,
  columnSortJobList,
  state,
  handleWorkflowSetup,
  handleWorkflowStatusUpdate,
  batchActionClickHandler,
  handleRefreshScores,
  taskStatus,
  onUploadSuccess,
  handleApplicantsTabClick,
  handlePaginationChange,
  sortedApplicant,
  handleOpenEmailModal,
  onViewEmail,
  activeSendOfferModal,
  onSortValueChange,
  user,
  jobDetails,
  applTitles,
  activePage,
  jobId,
  itemsPerPage,
  searchKey,
  status,
  params,
  history,
  statusSearch,
  search,
  count,
  t,
  defaultCheckList,
  applicationDocs
}) => {
  const uploadedFilesLength = (onUploadSuccess && onUploadSuccess.length) || 0;
  return (
    <div>
      {defaultCheckList !== null && defaultCheckList !== undefined &&
        <XopaTable
          defaultCheckList={defaultCheckList}
          onSortValueChange={onSortValueChange}
          selectedTab={selectedTab}
          tabCount={tabCount}
          handleApplicantsTabClick={handleApplicantsTabClick}
          minMax={minMax}
          xopaScores={applications}
          handleCandidateShortlist={handleCandidateShortlist}
          addCandidateToTalentPool={addCandidateToTalentPool}
          batchActionClickHandler={batchActionClickHandler}
          handleWorkflowStatusUpdate={handleWorkflowStatusUpdate}
          sortClicked={sortClicked}
          state={state}
          handleWorkflowSetup={handleWorkflowSetup}
          columnSortJobList={columnSortJobList}
          handleSearchSubmit={handleSearchSubmit}
          handleRefreshScores={handleRefreshScores}
          taskStatus={taskStatus}
          onUploadSuccess={onUploadSuccess}
          handleOpenEmailModal={e => handleOpenEmailModal(e)}
          onViewEmail={onViewEmail}
          activeSendOfferModal={e => activeSendOfferModal(e)}
          user={user}
          jobDetails={jobDetails}
          applTitles={applTitles}
          activePage={activePage}
          jobId={jobId}
          itemsPerPage={itemsPerPage}
          searchKey={searchKey}
          status={status}
          params={params}
          history={history}
          search={search}
          statusSearch={statusSearch}
          count={count}
          sortedApplicant={sortedApplicant}
          handlePaginationChange={handlePaginationChange}
          uploadedFilesLength={uploadedFilesLength}
          applicationDocs={applicationDocs}
        />
      }

      {applications && applications.length + uploadedFilesLength <= 0 && (
        <NoApplicantBox t={t} />
      )}
    </div>
  );
};

class CandidatesListXopa extends Component {
  constructor(props) {
    super(props);
    this.refresh = this.refresh.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.closeTour = this.closeTour.bind(this);
  }

  state = {
    isOpenBatchActionModal: false,
    itemsPerPage: 10,
    activePage: 1,
    sortedApplicant: [],
    sortByAscending: true,
    ascDesc: '',
    sortParam: '',
    shortlistStatus: null,
    filterOptions: '',
    qsValuesForFilter: '',
    qsValuesForSort: '',
    keyword: '',
    bulkselected: [],
    isbulkSelectAllChecked: false,
    shortlistedApplIds: [],
    status: true,
    selectedTab: 0,
    selectedSection: 0,
    activeWorkflowModal: false,
    productTourIsOpen: false,
    activateWorkflowStatusUpdateModal: false,
    resumeFileCount: 0,
    bucketName: '',
    isBucketNameValid: false,
    invalidText: '',
    selectedBucketId: 0,
    useBucket: false,
    isOpenSearchTalentPoolModal: false,
    searchPool: true,
    isOpenEmailModal: false,
    emailActionType: '',
    emailTemplate: '',
    editEmailBeforeSend: 'edit-email-no',
    activeAppId: 0,
    activeJobId: 0,
    notifShow: false,
    notifMsg: 'Default notification message',
    notifType: 'info',
    activeApplication: {},
    status: 'applied',
    selectTemplateModal: false,
    selectedEmailTemplate: 'placeholder-item',
    selectedEmails: [],
    noTemplateError: false,
    addMoreCandidates: false,
    addCandidatesCount: 0,
    addCandidatesModalError: false,
    defaultCheckList: null,
    transferJobModal: false,
    transferJobValue: "placeholder-item"
  };

  closeTour() {
    this.setState({ productTourIsOpen: false });
  }

  columnSortJobList = ({ propName }) => {
    const { ascDesc, currentSortProp } = this.state;
    let sortVal = 'DESC';
    if (currentSortProp && currentSortProp == propName) {
      sortVal = ascDesc && ascDesc == 'DESC' ? 'ASC' : 'DESC';
    } else {
      sortVal = 'DESC';
    }

    const qs = ascDesc => `filter[order][0]=${propName}%20${ascDesc}`;
    this.setState(
      {
        ascDesc: sortVal,
        sortParam: qs(sortVal),
        activePage: 1,
        currentSortProp: propName,
      },
      () => {
        this.handlePaginationChange({
          page: 1,
          pageSize: this.state.itemsPerPage,
        });
      }
    );
  };
  refresh(partialRefresh) {
    if (partialRefresh) {
      this.handlePaginationChange({ page: 1, pageSize: 10 });
    } else {
      //TODO - Temporary hack to refresh page.
      window.location.reload();
    }

    // let updatedWorkflow = this.state.selectedWorkflow;
    // if (updatedWorkflow) {
    //   updatedWorkflow.timestamp = (new Date()).getTime();
    //   this.setState({ selectedWorkflow : updatedWorkflow,
    //                   activeWorkflowModal: false,
    //                   selectedAppIdForWorkflow: appId,
    //                 });
    // } else {
    //   this.setState({ activeWorkflowModal: false,
    //                   selectedAppIdForWorkflow: appId,
    //                 });
    // }
  }

  componentDidMount() {
    const {
      match: {
        params: { id, status, page, limit },
      },
      location: { search } = {},
    } = this.props;
    const shortlistStatus = this.getShortlistStatus(status);
    // if (page) {
    this.setState(
      {
        activePage: (page && parseInt(page, 10)) || 1,
        itemsPerPage: (limit && parseInt(limit, 10)) || 10,
        status: status || 'applied',
      },
      () => {
        if (id) {
          this.setState(
            {
              jobId: id,
              status,
              selectedTab: this.getSelectedTabByStatus(status),
            },
            () => {
              let finalData = {};
              if (search) {
                finalData = this.handleSearchQuery(search)
              } else {
                this.setState({ defaultCheckList: {} })
              }
              const data = {
                shortlistStatus,
                status,
                itemsPerPage: this.state.itemsPerPage,
                activePage: this.state.activePage || 1,
                jobId: id,
                searchKey: this.state.keyword,
              };
              const filterData = { ...finalData, ...data };
              // const filterData = { ...data };

              this.props.reRenderContent(filterData);
            }
          );
        } else {
          this.setState(
            {
              status,
              selectedTab: this.getSelectedTabByStatus(status),
            },
            () => {
              let finalData = {};
              if (status !== 'processing' && status !== 'applied') {
                this.props.history.push({
                  pathname: `/app/campus/candidates/xopa/${status}`,
                  search: `?applicationStatus=${status}&`,
                });
                if (
                  this.props.history &&
                  this.props.history.location &&
                  this.props.history.location.search
                ) {
                  finalData = this.handleSearchQuery(
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.search
                  );
                }
              }
              if (search) {
                finalData = this.handleSearchQuery(search)
                // this.setState({ defaultCheckList: finalData.defaultCheckList })
              } else {
                this.setState({ defaultCheckList: {} })
              }
              const data = {
                shortlistStatus,
                status,
                itemsPerPage: this.state.itemsPerPage,
                activePage: 1,
                jobId: 0,
                searchKey: this.state.keyword,
              };
              const filterData = { ...finalData, ...data };
              // const filterData = {...data };
              this.props.reRenderContent(filterData);
            }
          );
        }
        this.props.getAllUserWorkflow();
        this.props.getWorkflowStages();
        this.props.getBucketNames();
        // this.props.getCustomEmailTemplates();
        this.props.getEmailTemplates();
      }
    );
    // }
  }

  componentWillReceiveProps(nextProps) {
    const {
      applications: { applications },
    } = nextProps;
    this.setState({
      sortedApplicant: (Array.isArray(applications) && applications) || [],
    });

    const { history, match } = this.props;
    const { id } = match.params;
    const { status } = nextProps.match.params;
    // if (id) {
    //   //console.log('selectedtab, id',this.state.selectedTab);
    //   this.setState({
    //     jobId: id,
    //     selectedTab: !this.state.selectedTab ? this.getSelectedTabByStatus(status) : this.state.selectedTab
    //   });
    // }
    //  else
    if (this.state.selectedTab !== 0) {
      //console.log('SET ZERO...',this.state.selectedTab);
      //console.log('Last...',this.state.selectedTab);
      this.setState({
        selectedTab: !this.state.selectedTab
          ? this.getSelectedTabByStatus(status)
          : this.state.selectedTab,
      });
    }
  }

  activeSearchTalentPoolModal = () => {
    this.setState({
      isOpenSearchTalentPoolModal: true,
    });
  };

  dismissSearchTalentPoolModal = () => {
    this.setState({
      isOpenSearchTalentPoolModal: false,
    });
  };

  getShortlistStatus = status => {
    if (status) {
      if (status === 'shortlisted') {
        return true;
      } else if (status === 'rejected') {
        return false;
      }
    }
    return null;
  };

  getSelectedTabByStatus = status => {
    //console.log('status',status);
    if (status) {
      if (status === 'processing') {
        return 0;
      } else if (status === 'is_liked') {
        return 1;
      } else if (status === 'screening') {
        return 2;
      } else if (status === 'shortlisted') {
        return 3;
      } else if (status === 'rejected') {
        return 4;
      } else if (status === 'recommended') {
        return 5;
      }
      return 0;
    }
    return 0;
  };
  fetchJobApplicants = (id, shortlistStatus) => {
    if (this.state.jobId || id) {
      this.props.reRenderContent({
        jobId: this.state.jobId || id,
        shortlistStatus,
      });
    } else {
      // if job id does not exists, then fetch at user level.
      this.props.reRenderContent({
        itemsPerPage: this.state.itemsPerPage,
        activePage: 1,
        shortlistStatus,
        searchKey: this.state.keyword,
      });
    }
  };

  toastSuccess = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRadioButton = e => {
    switch (e) {
      case 'select':
        this.setState({
          useBucket: false,
        });
        break;
      case 'create-new':
        this.setState({
          useBucket: true,
        });
        break;
      default:
    }
  };

  handleChangeTalentPoolButton = e => {
    switch (e) {
      case 'select':
        this.setState({
          searchPool: false,
        });
        break;
      case 'search':
        this.setState({
          searchPool: true,
        });
        break;
      default:
    }
  };

  itemPerPageClicked = e => {
    const { jobId } = this.state;

    this.props.reRenderContent({
      itemsPerPage: e.target.value,
      activePage: this.state.activePage,
      jobId,
      searchKey: this.state.keyword,
    });
    this.handleChange(e);
  };

  reRenderAfterAction = (error = false) => {
    const {
      itemsPerPage,
      activePage,
      jobId,
      qsValuesForSort,
      keyword,
    } = this.state;

    this.setState(
      {
        activePage: 1,
        bulkselected: [],
        isbulkSelectAllChecked: false,
      },
      () => {
        if (!error) {
          //Instead of rerendering work with the response
          this.props.reRenderContent({
            itemsPerPage: itemsPerPage,
            activePage: 1,
            jobId: jobId,
            filterOptions: qsValuesForSort,
            searchKey: keyword,
          });
        } else {
          this.setState({
            bulkselected: [],
            isbulkSelectAllChecked: false,
          });
        }
      }
    );
  };

  batchHandler = () => {
    const {
      bucketName,
      batchActionState,
      batchActionItems,
      selectedBucketId,
      useBucket,
    } = this.state;
    let actionState = batchActionState;
    const actionItems = batchActionItems;

    if (actionState === 'transfer') {
      const companyId = actionItems[0].id.split("_")[4];
      const jobId = actionItems[0].id.split("_")[5];
      const applIds = actionItems.map((actionItem) => {
        return actionItem.id.split('_')[1]
      })
      this.props.getCompanyJobPostings({ companyId }).then(() => {
        this.setState({ transferJobRequiredCompanyId: companyId, transferJobRequiredJobId: jobId, applIds }, () => {
          this.openTransferJobModal();
        })
      })
    }

    if (actionState === 'talentpool') {
      if (useBucket) {
        this.dismissBatchModal();
        this.props.createBucket(bucketName).then(res => {
          this.props.getBucketNames();
          this.setState({
            selectedBucketId: res && res.data && res.data.bucketId,
          });
          let bulkSrObj = actionItems.map(eachItem => {
            return {
              profileId: eachItem && eachItem.id && eachItem.id.split('_')[2],
              appId: eachItem && eachItem.id && eachItem.id.split('_')[1],
              bucketId: selectedBucketId,
            };
          });
          this.addCandidateToTalentPool(bulkSrObj)
            .then(res => {
              this.reRenderAfterAction();
            })
            .catch(err => {
              console.error('Bulk shortlist/reject error', err);
              this.reRenderAfterAction(true);
            });
        });
      } else if (!useBucket) {
        this.dismissBatchModal();
        let bulkSrObj = actionItems.map(eachItem => {
          return {
            profileId: eachItem && eachItem.id && eachItem.id.split('_')[2],
            appId: eachItem && eachItem.id && eachItem.id.split('_')[1],
            bucketId: selectedBucketId,
          };
        });
        this.addCandidateToTalentPool(bulkSrObj)
          .then(res => {
            this.reRenderAfterAction();
          })
          .catch(err => {
            console.error('Bulk shortlist/reject error', err);
            this.reRenderAfterAction(true);
          });
      } else {
        this.dismissBatchModal();
        let bulkSrObj = actionItems.map(eachItem => {
          return {
            profileId: eachItem && eachItem.id && eachItem.id.split('_')[2],
            appId: eachItem && eachItem.id && eachItem.id.split('_')[1],
          };
        });
        this.addCandidateToTalentPool(bulkSrObj)
          .then(res => {
            this.reRenderAfterAction();
          })
          .catch(err => {
            console.error('Bulk shortlist/reject error', err);
            this.reRenderAfterAction(true);
          });
      }
    } else {
      this.dismissBatchModal();
    }
    if (actionState && actionItems) {
      if (actionState == 'shortlist' || actionState == 'reject') {
        const bulkSrObj = actionItems.map(eachItem => {
          const { applications } = this.props;
          const { jobInfo } = applications;
          const { isAutoPilot, isAutoShortlist } = jobInfo && jobInfo[0];
          const applListArr = applications && applications.applications;
          const applId =
            (eachItem &&
              eachItem.cells &&
              eachItem.cells[0] &&
              eachItem.cells[0].id &&
              eachItem.cells[0].id.split('_') &&
              eachItem.cells[0].id.split('_')[1]) ||
            0;
          const applRes =
            applListArr &&
            Array.isArray(applListArr) &&
            applListArr.length > 0 &&
            applListArr.find(res => res.appId == applId);
          const { applicationsource: { sourceName } = {} } = applRes;
          return {
            appId: applId,
            shortlisted:
              actionState == 'shortlist'
                ? true
                : actionState == 'reject'
                  ? false
                  : null,
            sourceName,
            isAutoPilot,
            isAutoShortlist,
          };
        });
        this.props
          .bulkShortlistReject(bulkSrObj)
          .then(res => {
            this.reRenderAfterAction();
          })
          .catch(err => {
            console.error('Bulk shortlist/reject error', err);
            this.reRenderAfterAction(true);
          });
      } else if (actionState == 'is_liked') {
        const bulkSrObj = actionItems.map(eachItem => {
          return {
            appId:
              (eachItem &&
                eachItem.cells &&
                eachItem.cells[0] &&
                eachItem.cells[0].id &&
                eachItem.cells[0].id.split('_') &&
                eachItem.cells[0].id.split('_')[1]) ||
              0,
            isLiked: (actionState = 'is_liked' ? true : null),
          };
        });
        this.props
          .bulkLike(bulkSrObj)
          .then(() => {
            this.reRenderAfterAction();
          })
          .catch(() => {
            this.reRenderAfterAction(true);
          });
      }
    }

    //resetting state
    this.setState(
      {
        batchActionItems: null,
        batchActionState: null,
      },
      () => { }
    );

    //Creating buckets
  };

  openTransferJobModal = () => {
    this.setState({ showTransferJobModal: true, transferJobValue: "placeholder-item" });
  }

  dismissTransferJobModal = () => {
    this.setState({ showTransferJobModal: false });
  }

  handleTransferJobSubmit = () => {
    const { transferJobValue, applIds, transferJobRequiredCompanyId, transferJobRequiredJobId } = this.state;
    if (transferJobValue === "placeholder-item") {
      this.setState({ showSelectTranferJobError: true })
      return;
    }
    const data = {
      "appIds": applIds,
      "jobId": transferJobRequiredJobId,
      "companyId": transferJobRequiredCompanyId,
      "transferJobId": transferJobValue
    }
    this.props.transferApplications(data)
      .then((res) => {
        const { totalAppsCount = 0, appsWithNoCourses = [], existingAppsList = [], transferredApps = [] } = res;
        const isTransferJobApplsFilled = res && res.error && res.error.code === 'x0pa-99';
        // totalAppsCount , transferredApps, existingAppsList, appsWithNoCourses
        this.setState({
          totalAppsCount,
          appsWithNoCourses,
          existingAppsList,
          transferredApps,
          showTransferJobModal: false,
          isTransferJobApplsFilled
        }, () => {
          this.setState({
            showTransferJobResponseModal: true
          })
        })
      })
  }

  batchActionClickHandler = (actionItems, actionState) => {
    if (actionState === 'email') {
      const actionItemsIds = actionItems.map(({ id = '' }) =>
        Number(id.split('_')[1])
      );
      this.getSelectedRowsMails(actionItemsIds);
      return;
    }

    this.setState(
      {
        batchActionItems: actionItems,
        batchActionState: actionState,
      },
      () => {
        this.batchHandler();
      }
    );
  };

  handleRefreshScores = () => {
    const {
      user: { userId },
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getRefreshScores(id, userId);
  };

  startTalentPoolSearch = () => {
    const { searchPool, selectedBucketId, jobId } = this.state;
    this.dismissSearchTalentPoolModal();
    if (searchPool) {
      this.props.findFromUserTalentPool(jobId);
    } else if (
      !searchPool &&
      (selectedBucketId || Number(selectedBucketId) !== 0)
    ) {
      this.props.findFromUserTalentPool(jobId, selectedBucketId);
    }
  };

  activateModal = job => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  activateWorkflowModal = (application, appId) => {
    this.setState({
      activeWorkflowModal: true,
      selectedAppIdForWorkflow: appId,
      selectedApplicationForWorkflow: application,
    });
  };

  activateWorkflowStatusUpdateModal = (application, appId, workflow) => {
    this.setState({
      activateWorkflowStatusUpdateModal: true,
      selectedAppIdForWorkflow: appId,
      selectedApplicationForWorkflow: application,
      selectedWorkflow: workflow,
    });
  };

  dismissWorkflowStatusUpdateModal = () => {
    this.setState({
      activateWorkflowStatusUpdateModal: false,
    });
  };

  dismissWorkflowModal = () => {
    this.setState({
      activeWorkflowModal: false,
    });
  };

  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'open', name: 'Jobs', href: '/app/campus/jobs' },
    {
      id: 'applicants',
      name: 'Applicants',
      href: '/app/campus/candidates/xopa',
    },
  ];

  handlePaginationChange = data => {
    // console.log("------ ", data, this.state.activePage);
    const activePage = data.page || this.state.activePage || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    const status = data.status || this.state.status;
    const {
      match: {
        params: { id },
      },
      location: { search } = {},
      history: { push } = {},
    } = this.props;

    if (id) {
      push &&
        push(
          `/app/campus/jobs/${id}/${status}/page/${activePage}/limit/${itemsPerPage}${search ? search : '/'
          }`
        );
    } else {
      push &&
        push(
          `/app/campus/candidates/xopa/${status}/page/${activePage}/limit/${itemsPerPage}${search ? search : '/'
          }`
        );
    }

    this.setState({ activePage, status, itemsPerPage }, () => {
      //console.log("AP SET",this.state.status, this.state.selectedTab);
      let finalData = {};
      if (search) {
        finalData = this.handleSearchQuery(search);
      }
      const payloadData = {
        itemsPerPage,
        activePage,
        filterOptions: this.state.sortParam,
        jobId: this.state.jobId || id,
        shortlistStatus: this.state.shortlistStatus,
        searchKey: data.searchKey || null,
        status: this.state.status || 0,
      };
      const filterData = { ...finalData, ...payloadData };
      this.props.reRenderContent(filterData).then(res => {
        //console.log('RERENDER CONTENT', res, this.state.status, this.state.selectedTab);
      });
    });
  };

  handleCandidateShortlist = (appId, shortlistStatus) => {
    this.props.setShortlistStatus(appId, shortlistStatus).then(res => {
      this.handlePaginationChange({ page: 1 });
    });
  };

  addCandidateToTalentPool = profileItems => {
    const { selectedBucketId } = this.state;
    let bulkSrObj = profileItems.map(eachProfile => {
      // return {
      //   profileId: eachProfile && eachProfile.profileId,
      //   appId: eachProfile && eachProfile.appId,
      //   bucketId: (selectedBucketId && selectedBucketId !==0 ) ? selectedBucketId : '',
      // };
      const tpObj = {
        profileId: eachProfile && eachProfile.profileId,
        appId: eachProfile && eachProfile.appId,
      };
      if (selectedBucketId || Number(selectedBucketId) !== 0) {
        tpObj.bucketId = selectedBucketId;
      }
      return tpObj;
    });
    return this.props.addApplicantToTalentPool(bulkSrObj);
    // .then((res) => {
    //   this.handlePaginationChange({ page: 1 });
    // });
  };

  handleWorkflowSetup = (application, appId) => {
    // alert("setting up interview");
    this.activateWorkflowModal(application, appId);
  };

  handleWorkflowStatusUpdate = (application, appId, workflow) => {
    this.activateWorkflowStatusUpdateModal(application, appId, workflow);
  };

  handleApplicantsTabClick = tabType => {
    let selectedTab;
    let status = tabType;
    switch (tabType) {
      case 'shortlisted':
        selectedTab = 3;
        break;
      case 'rejected':
        selectedTab = 4;
        break;
      case 'screening':
        selectedTab = 2;
        break;
      case 'recommended':
        selectedTab = 5;
        break;
      case 'aiSourcing':
        selectedTab = 6;
        break;
      case 'processing':
        selectedTab = 0;
        break;
      case 'isLiked':
        selectedTab = 1;
        break;
      default:
        selectedTab = 0;
        status = null;
        break;
    }
    //console.log('tabtype',tabType);
    const shortlistStatus = this.getShortlistStatus(tabType);
    //console.log('functiontab',selectedTab);
    this.setState(
      { selectedTab, shortlistStatus, status, keyword: null },
      () => {
        this.handlePaginationChange({
          page: 1,
        });
      }
    );
  };

  handleTabSectionClick = tabType => {
    let selectedSection;
    switch (tabType) {
      case 'applications':
        selectedSection = 0;
        break;
      case 'analytics':
        selectedSection = 1;
        break;
      case 'graph':
        selectedSection = 2;
        break;
      default:
        selectedSection = 0;
        break;
    }
    this.setState({ selectedSection });
  };

  activateBatchModal = () => {
    // console.log('Close Apply Job Modal');
    this.setState({
      isOpenBatchActionModal: true,
    });
  };

  dismissBatchModal = () => {
    // console.log('Close Apply Job Modal');
    const { isBucketNameValid } = this.state;
    if (isBucketNameValid) {
      this.setState({
        isBucketNameValid: false,
        invalidText: '',
        bucketName: '',
      });
    }
    this.setState({
      isOpenBatchActionModal: false,
      bucketName: '',
    });
  };
  // handleSearchSubmit = () => {
  //   this.handlePaginationChange({ page: 1, searchKey: this.state.keyword });
  // }

  searchTimer = null;

  handleSearchSubmit = event => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value });
    this.searchTimer = setTimeout(
      () =>
        this.handlePaginationChange({ page: 1, searchKey: this.state.keyword }),
      1000
    );
  };

  onResumeUploadSuccess = res => {
    this.setState({
      resumeFileCount: res && res.processedFiles,
    });
    return res && res.processedFiles;
  };

  handleOpenEmailModal = e => {
    const { applications = {} } = this.props;
    const { applications: applicationsArr = [] } = applications || {};
    const activeApplication =
      applicationsArr &&
      Array.isArray(applicationsArr) &&
      applicationsArr.filter(a => a.appId == (e.appId || 0));
    if (activeApplication && activeApplication[0]) {
      const updatObj = {
        emailActionType: (e && e.name) || 'default',
        emailTemplate: e && e.template,
        activeAppId: e && e.appId,
        activeJobId: e && e.jobId,
        activeApplication: activeApplication[0] || {},
      };
      if (e.source === 'ai sourcing') {
        updatObj.editEmailBeforeSend = 'edit-email-yes';
      }
      this.setState(updatObj, () => {
        this.setState({ isOpenEmailModal: true });
      });
    }
  };

  dismissEmailModal = () => {
    this.setState({
      isOpenEmailModal: false,
      emailActionType: '',
      emailTemplate: '',
      activeAppId: 0,
      activeJobId: 0,
      notifShow: false,
      notifType: 'info',
      notifMsg: 'Default notification message',
      isSendingEmail: false,
      activeApplication: {},
    });
  };

  handleSendEmail = () => {
    const {
      editEmailBeforeSend,
      emailTemplate,
      emailActionType,
      activeAppId,
      activeJobId,
    } = this.state;
    const { history: { push } = {} } = this.props;
    if (
      editEmailBeforeSend === 'edit-email-yes' ||
      emailTemplate === 'custom-email'
    ) {
      let d =
        push &&
        push(
          `/app/campus/emails/send/${emailTemplate}/${activeAppId}/job/${activeJobId}`
        );
    } else {
      const sendEmailData = {
        payload: {
          appId: activeAppId || 0,
        },
        templateId: -1,
        actionName: 'send-email',
        templateName: emailTemplate,
        emailActionType,
      };
      this.setState(
        {
          isSendingEmail: true,
        },
        () => {
          this.props
            .sendEmailTemplate(sendEmailData)
            .then(res => {
              if (res && !res.error) {
                this.dismissEmailModal();
                window.location.reload();
              } else {
                console.error('Send email error:', res);
                const { message } = (res && res.error) || {};
                throw new Error(message || 'Error occured while sending email');
              }
            })
            .catch(err => {
              console.error(err);
              const { message } = err || {};
              this.setState({
                isSendingEmail: false,
                notifShow: true,
                notifType: 'error',
                notifMsg: message || 'Error occured while sending email',
              });
            });
        }
      );
    }
  };

  handleSendOfferEmail = () => {
    const { emailTemplate, emailActionType, activeAppId } = this.state;
    const sendEmailData = {
      payload: {
        appId: activeAppId || 0,
      },
      templateId: -1,
      actionName: 'send-email',
      templateName: emailTemplate,
      emailActionType,
    };
    this.props.sendEmailTemplate(sendEmailData);
  };

  onEditEmailChange = ev => {
    this.setState({
      editEmailBeforeSend: ev,
    });
  };

  onViewEmail = () => {
    const { emailTemplate } = this.state;
    const params =
      'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
    window.open(`/emailpreview/${emailTemplate}`, 'Email Preview', params);
  };

  onSortValueChange = value => {
    const sortVal = value.indexOf('Asc') > 0 ? 'ASC' : 'DESC';
    const propName =
      value.indexOf('Asc') > 0
        ? value.replace('Asc', '')
        : value.replace('Dsc', '');
    const qs = ascDesc => `filter[order][0]=${propName}%20${ascDesc}`;
    this.setState(
      {
        ascDesc: sortVal,
        sortParam: qs(sortVal),
        activePage: 1,
        isLoading: true,
        currentSortProp: propName,
      },
      () => {
        this.handlePaginationChange({
          page: 1,
        });
      }
    );
  };

  onEmailModalTemplateChange = e => {
    const selectedEmailTemplate = e && e.target && e.target.value;
    if (!selectedEmailTemplate) return;
    this.setState({
      emailTemplate: selectedEmailTemplate,
    });
  };

  handleSearchQuery = searchString => {
    const search = decodeURIComponent(searchString);
    const searchSplit = search.split('?');
    const resSplt = searchSplit[1].split('&');
    const finalVal = {};
    let defaultCheckList = {};
    resSplt &&
      Array.isArray(resSplt) &&
      resSplt.length > 0 &&
      resSplt.forEach(res => {
        if (res) {
          const valSpl = res.split('=');
          let valueArr = [];
          if (valSpl[1].includes(',')) {
            valueArr = valSpl[1].split(',');
          } else {
            valueArr = [valSpl[1]];
          }
          defaultCheckList[valSpl[0]] = valueArr;
          finalVal[valSpl[0]] = valSpl[0];
          finalVal[`filterName_${valSpl[0]}`] = valueArr;
          finalVal.defaultCheckList = defaultCheckList
          this.setState({ defaultCheckList: defaultCheckList })
        }
      });
    return finalVal;
  };

  templateModalSubmit = () => {
    const { selectedEmails, selectedEmailTemplate } = this.state;
    if (
      selectedEmailTemplate === null ||
      selectedEmailTemplate.indexOf('placeholder-item') > -1
    ) {
      this.setState({ noTemplateError: true });
      return;
    }
    if (Array.isArray(selectedEmails) && selectedEmails.length === 0) {
      this.setState({
        selectedEmailTemplate: 'placeholder-item',
        selectTemplateModal: false,
        selectedEmails: [],
        noTemplateError: false,
      });
      return;
    }
    const templateId = selectedEmailTemplate.split('_')[0];
    const templateName = selectedEmailTemplate.split('_')[1];
    const emailData = selectedEmails;
    const data = {
      templateId,
      templateName,
      emailData,
      actionName: 'send-email',
    };
    if (this.state.sendingEmails) { return; }
    // user-defined-email
    this.setState({
      sendingEmails: true,
      notifShow: false,
      notifMsg: '',
    }, () => {
      this.props.sendEmails(data)
        .then((res) => {
          const { error } = res || {};
          if (error) {
            throw new Error('Error occured.');
          }
          this.setState({
            selectedEmailTemplate: 'placeholder-item',
            // selectTemplateModal: false,
            selectedEmails: [],
            notifShow: true,
            notifKind: 'success',
            notifMsg: 'Email(s) sent successfully',
            sendingEmails: false,
          });
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            notifShow: true,
            notifKind: 'error',
            notifMsg: 'Error occured while sending emails',
            sendingEmails: false,
          });
        });
    });

  };

  templateModalClose = () => {
    this.setState({
      selectedEmailTemplate: 'placeholder-item',
      selectTemplateModal: false,
      selectedEmails: [],
      noTemplateError: false,
    });
  };

  onEmailTemplateChange = e => {
    const selectedEmailTemplate = e.target.value;
    this.setState({ selectedEmailTemplate, noTemplateError: false });
  };

  onCustomTemplatePreview = () => {
    const { selectedEmailTemplate = '' } = this.state;
    if (selectedEmailTemplate !== null && selectedEmailTemplate !== undefined && selectedEmailTemplate !== '' && selectedEmailTemplate !== 'placeholder-item') {
      const previewEmailName = selectedEmailTemplate.split('_')[1];
      const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
      window.open(`/emailpreview/${previewEmailName}`, 'Email Preview', params);
    }
  }

  getSelectedRowsMails = selected => {
    const { sortedApplicant } = this.state;
    const mailsArray = Array.isArray(sortedApplicant)
      ? sortedApplicant.filter(
        ({ appId = null }) => selected.indexOf(Number(appId)) > -1
      )
      : [];
    const emailData = [];
    if (Array.isArray(mailsArray)) {
      mailsArray.forEach(e => {
        const {
          candidateInfo: [{ userinfo } = {}] = [],
          profile: { profileId = null, email } = {},
          appId = null,
        } = e || {};
        // const { email = null } = userinfo || {};
        if (email !== null && profileId !== null && email.length > 0) {
          emailData.push({
            email,
            profileId,
            appId,
          });
        }
      });
    }
    this.setState({ selectTemplateModal: true, selectedEmails: emailData, notifShow: false, notifMsg: ' ' });
  };

  addMoreCandidates = () => {
    const {
      addCandidatesCount,
      addCandidatesModalError,
      addMoreCandidates,
    } = this.state;
    if (
      !addCandidatesCount ||
      addCandidatesCount === null ||
      addCandidatesCount === 0
    )
      this.setState({ addCandidatesModalError: true });
    else {
      this.setState(
        {
          addCandidatesCount: 0,
          addCandidatesModalError: false,
          addMoreCandidates: false,
        },
        () => {
          const {
            applications: { jobInfo: [{ jobId = null } = {}] = [] } = {},
          } = this.props;
          if (addCandidatesCount !== 0 && jobId !== null) {
            this.props.getMoreCandidatesAiSourcing({
              count: addCandidatesCount,
              jobId,
            });
          }
        }
      );
    }
  };

  onDownloadClick = () => {
    const {
      applications: { jobInfo, applicationDocs } = {},
    } = this.props;
    let personDocIds = []
    if (applicationDocs && Array.isArray(applicationDocs) && applicationDocs.length > 0) {
      applicationDocs.forEach((res) => {
        let personDocId = res && res.personDocId
        personDocIds.push(personDocId)
      })
    }
    this.props.getDocumentByIds(personDocIds).then((res) => {
      if (res && !res.error) {
        let resptype = res && res.resp && res.resp.type
        let objectResp = res && res.resp
        if (resptype === 'application/pdf') {
          const c = URL.createObjectURL(objectResp);
          this.setState({
            loading: false,
            cv: c,
          });
        } else {
          const a = document.createElement('a');
          const url = URL.createObjectURL(objectResp);
          a.href = url;
          let fileName = `Document-${Date.now()}`;
          if (resptype == 'application/msword') {
            fileName = `Document-${Date.now()}.doc`;
          }
          if (resptype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            fileName = `Document-${Date.now()}.docx`;
          }
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            this.setState({
              loading: false,
              downloaded: true,
              documentName: fileName,
              cv: null,
            });
          }, 100);
        }
      } else {
        this.setState({
          loading: false,
          error: true,
        });
      }
    })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  render() {
    const {
      history: { push },
      match: {
        params: { id, status },
      },
      applications: {
        applications,
        count,
        minMax,
        dashboard,
        canUserUploadResume,
        refreshscoreid,
      } = {},
      talentSearchComplete,
      talentSearchPayload,
      tasks,
      user: { userId } = {},
      workflowstages,
      companyJobs
    } = this.props;
    const {
      applications: { jobInfo, applicationDocs } = {},
      emailTemplates = null,
      t,
    } = this.props;
    const jobinfo = jobInfo && jobInfo[0];
    const collaboration = jobinfo && jobinfo.collaboration;
    const collaboratorRes =
      collaboration &&
      collaboration.filter(
        res => res.userId == userId && (res.roleId == 1 || res.roleId == 4)
      );
    const {
      itemsPerPage,
      bulkselected,
      isbulkSelectAllChecked,
      selectedTab,
      selectedSection,
      searchPool,
      notifShow,
      notifType,
      notifMsg,
      isSendingEmail,
      defaultCheckList,
      sortedApplicant,
      totalAppsCount = 0,
      transferredApps = [],
      existingAppsList = [],
      appsWithNoCourses = [],
      isTransferJobApplsFilled = false
    } = this.state;
    const countValue = Math.ceil(count / itemsPerPage);
    // todo: As discussed on group, will be removed once new object has been added to response.
    const jobDetails = jobInfo ? { job: jobInfo } : {};
    // console.log("ts = ",talentSearchPayload, talentSearchComplete);
    // if (talentSearchComplete) {
    //   this.fetchJobApplicants(id);
    // }
    const applTitles = [
      {
        titleId: 1,
        titleName: `${t('status')}`,
        status: 'as',
        values: {
          applicationStatus: [
            { statusName: `${t('screening')}`, statusId: 'screening' },
            { statusName: `${t('shortlisted')}`, statusId: 'shortlisted' },
            { statusName: `${t('rejected')}`, statusId: 'rejected' },
            { statusName: 'Favourites', statusId: 'favourites' },
            { statusName: 'Interviews in Progress', statusId: 'pendingInterviews' },
            { statusName: 'Interviews Completed', statusId: 'completedInterviews' },
          ],
        },
        objKey: 'statusId',
        filterKey: 'applicationStatus',
      },
      // {
      //   titleId: 2,
      //   titleName: `${t('type')}`,
      //   status: 'at',
      //   values: {
      //     applicantType: [
      //       { applicantTypeName: `${t('favouriteCandidate')}`, applicantTypeId: 'isLiked' },
      //       { applicantTypeName: `${t('roboroyShortlisted')}`, applicantTypeId: 'recommended' },
      //       { applicantTypeName: 'Roboroy Downvotes', applicantTypeId: 'downVotes' },
      //     ],
      //   },
      //   objKey: 'applicantTypeId',
      //   filterKey: 'applicationType',
      // },
      // {
      //   titleId: 3,
      //   titleName: `${t('source')}`,
      //   status: 's',
      //   values: {
      //     source: [
      //       { sourceName: `${t('candidateUploaded')}`, sourceId: 'candidate' },
      //       { sourceName: `${t('recruiterUploaded')}`, sourceId: 'recruiter' },
      //       { sourceName: `${t('recruiterAgency')}`, sourceId: 'recruiting agency' },
      //       { sourceName: 'LinkedIn', sourceId: 'linkedin' },
      //       { sourceName: `${t('aiSourcing')}`, sourceId: 'ai sourcing' },
      //       { sourceName: `${t('recruiterTalentpool')}`, sourceId: 'recruitertalentpool' },
      //     ],
      //   },
      //   objKey: 'sourceId',
      //   filterKey: 'sourcingStatus',
      // },
      {
        titleId: 4,
        titleName: `${t('interviewType')}`,
        status: 'it',
        values: {
          workflowstages: [
            {
              stageTypeId: 3002,
              stageTypeName: 'Onsite Interview',
            },
          ],
        },
        objKey: 'stageTypeName',
        filterKey: 'workflowStages',
      },
      {
        titleId: 5,
        titleName: `${t('workflowStages')}`,
        status: 'wss',
        values: {
          workflowSubStageStatus: [
            { subStageName: `${t('scheduling')}`, subStageId: 'Scheduling' },
            // { subStageName: `${t('interview')}`, subStageId: 'Interview' },
            // { subStageName: `${t('feedback')}`, subStageId: 'Feedback' },
            { subStageName: `${t('decision')}`, subStageId: 'Decision' },
          ],
        },
        objKey: 'subStageId',
        filterKey: 'workflowSubStageStatus',
      },
      {
        titleId: 6,
        titleName: `${t('offerStatus')}`,
        status: 'os',
        values: {
          offerStatus: [
            // { offerName: `${t('consent')} ${t('sent')}`, offerId: 'consent-email-sent' },
            // { offerName: `${t('consent')} ${t('accepted')}`, offerId: 'candidate-accepts-consent' },
            // { offerName: `${t('consent')} ${t('rejected')}`, offerId: 'candidate-rejects-consent' },
            {
              offerName: `${t('offer')} sent`,
              offerId: 'offer-detail-sent',
            },
            {
              offerName: `${t('offer')} ${t('accepted')}`,
              offerId: 'offer-accepted',
            },
            {
              offerName: `${t('offer')} ${t('rejected')}`,
              offerId: 'offer-rejected',
            },
          ],
        },
        objKey: 'offerId',
        filterKey: 'offerStatus',
      },
      {
        titleId: 7,
        titleName: `${t('hiringStatus')}`,
        status: 'hs',
        values: {
          hiringStatus: [
            { hiringName: `${t('hired')}`, hiringId: 'is-hired' },
            { hiringName: `${t('no')}-${t('hire')}`, hiringId: 'is-not-hired' },
          ],
        },
        objKey: 'hiringId',
        filterKey: 'hiringStatus',
      },
      {
        titleId: 8,
        titleName: `${t('Gender')}`,
        status: 'g',
        values: {
          gender: [
            { genderName: `${t('Male')}`, genderId: 'male' },
            { genderName: `${t('Female')}`, genderId: 'female' },
            { genderName: `${t('Undisclosed')}`, genderId: 'undisclosed' },
          ],
        },
        objKey: 'genderId',
        filterKey: 'gender',
      },
      {
        titleId: 9,
        titleName: `${t('Skills')}`,
        status: 's',
        values: {
          skills: [
            { skillName: `${t('Microsoft Powerpoint')}`, skillId: 'ms-powerpoint' },
            { skillName: `${t('Microsoft Word')}`, skillId: 'ms-word' },
            { skillName: `${t('Microsoft Office')}`, skillId: 'ms-office' },
            { skillName: `${t('Microsoft Excel')}`, skillId: 'ms-excel' },
            { skillName: `${t('Customer Service Oriented')}`, skillId: 'cso' },
            { skillName: `${t('Operations')}`, skillId: 'operations' },
            { skillName: `${t('Cashiering')}`, skillId: 'cashiering' },
            { skillName: `${t('Customer Service')}`, skillId: 'customer-service' },
            { skillName: `${t('Retail Sales')}`, skillId: 'retail-sales' },
          ],
        },
        objKey: 'skillId',
        filterKey: 'skills',
      },
    ];
    const guideSteps = [
      {
        selector: '.unknown-class-meow-meow',
        content:
          'This is the job applicants listing page. It provides details about all the CVs you have received for a job.',
      },
      {
        selector: '.candidates_list .xpa-appl-info--job-card__info',
        content:
          'This section provides basic information about the current job.',
      },
      {
        selector: '.candidates_list .xpa-appl-info--job-card__actions',
        content:
          'Resumes can be added by choosing upload resume option or by searching your talent pool.',
      },
      {
        selector: '.candidates_list .xpa-appl-info--job-card__upload-resume',
        content:
          'Upload the resumes that you have received by choosing this option. Multiple resumes can be uploaded at once.',
      },
      {
        selector: '.candidates_list .xpa-appl-info--job-card__search-talent',
        content:
          'Search your talent pool to find candidates fit for this role.',
      },
      {
        selector: '.candidates_list .xpa-appl-info--job-card',
        content:
          'This section provides basic information about the job and actions that can be perfomed.',
      },
      {
        selector: '.candidates_list .xpa-appl-info--list-tabs',
        content:
          'Applicants are easily grouped into different categories such as All Applicants, shortlisted, rejected, Roboroy recommended and favourites',
      },
      {
        selector: '.candidates_list .xpa-appl-info--list',
        content:
          'This section contains the list of applicants and their X0PA metrics.',
      },
      {
        selector:
          '.candidates_list .xpa-appl-info--list table tbody tr:nth-child(1) td:nth-child(1) ',
        content: 'Choose multiple candidates at once to perform bulk actions',
      },
      {
        selector:
          '.candidates_list .xpa-appl-info--list table tbody tr:nth-child(1) td:nth-child(3) ',
        content:
          "Basic profile information about the candidate such as name, year of experience and Roboroy's recommendation for this candidate",
      },
      {
        selector:
          '.candidates_list .xpa-appl-info--list table tbody tr:nth-child(1) td:nth-child(4) ',
        content:
          "CV relevance score for the candidate which denotes how close the candidate's resume matches with job description.",
      },
      {
        selector:
          '.candidates_list .xpa-appl-info--list table tbody tr:nth-child(1) td:nth-child(5) ',
        content:
          "Loyalty score for the candidate which denotes how similar is the candidate's profile when compared to employees at similar position in an organization.",
      },
      {
        selector:
          '.candidates_list .xpa-appl-info--list table tbody tr:nth-child(1) td:nth-child(6) ',
        content:
          'Performance score for the candidate which denotes the probability of a candidate to be working at the same position for the next one year without intention to change.',
      },
      // {
      //   selector: '.candidates_list .xpa-appl-info--list table tbody tr:nth-child(1) td:nth-child(7) ',
      //   content: 'Benchmark score for the candidate which denotes the probability for the candidate to deserve an earlier than average promotion.',
      // },
      {
        selector:
          '.candidates_list .xpa-appl-info--list table tbody tr:nth-child(1) td:nth-child(8) ',
        content:
          "Interview status section shows the current status of candidate's interview and actions that need to be performed",
      },
      {
        selector:
          '.candidates_list .xpa-appl-info--list .bx--toolbar-search-container',
        content: 'Filter candidates list by name search',
      },
      {
        selector: '.candidates_list .xpa-appl-info--list .bx--toolbar-content',
        content: 'Trigger recalculation of X0PA metrics for all candidates.',
      },
      {
        selector: '.xpa-rightnav .bx--tooltip-icon',
        content:
          'Check the status of your resume uploads or talent search here.',
      },
    ];

    const tabContent = [
      {
        label: `${t('applications')}`,
        value: '',
        onClick: e => this.handleTabSectionClick('applicants'),
      },
    ];
    // if (id) {
    //   tabContent.push({
    //     label: `Snapshot`,
    //     value: '',
    //     onClick: (e) => this.handleTabSectionClick("analytics")
    //   });
    // }

    const notifObj = { notifShow, notifType, notifMsg };
    const jobTasks = tasks && tasks[id];
    let taskId = tasks.task_id;
    if (jobTasks && Array.isArray(jobTasks)) {
      taskId = jobTasks[0].taskId;
    }

    const customEmailTemplates =
      emailTemplates !== null && Array.isArray(emailTemplates)
        ? emailTemplates.filter(e => {
          return (e && e.templateName === 'company-ihl-rejects')
        })
        : [];
    const {
      aiSourcing = false,
      externaljobmapping: {
        insightDetails: {
          response: { timeTaken, totalRecordsFound, totalRecordsTaken } = {},
        } = {},
      } = {},
    } = jobinfo || {};

    return (
      <div className="candidates_list">
        {/* <RoboSidebar /> */}
        {/* {talentSearchComplete && (<Toast taskId={talentSearchPayload.task_id} successAction={this.fetchJobApplicants} />)} */}
        <div className="candidates_container xp-c-container xp-c-background--light">
          <BackRow
            paths={this.paths}
            showGuidedTour={true}
            handleGuidedTourClick={() =>
              this.setState({ productTourIsOpen: true })
            }
          />
          <ProductTour
            onRequestClose={this.closeTour}
            isOpen={this.state.productTourIsOpen}
            steps={guideSteps}
          />
          {/*collaboratorRes && collaboratorRes.length > 0 && jobinfo && jobinfo.jobWorkflowStages && Array.isArray(jobinfo.jobWorkflowStages) && jobinfo.jobWorkflowStages.length <= 1 && (
            <InlineNotification lowContrast
              kind="error"
              title={(
                <div className="bx--row m-0">
                  <div className="mr-2">{t('noWorkflowMsg')}</div>
                  <Link to={{ pathname: `/app/campus/job/${jobinfo && jobinfo.jobId}`, state: { activeSelectedTab: 4 } }}> {t('setupWorkflow')}</Link>
                </div>
              )}
              subtitle=""
              hideCloseButton
            />
          )*/}
          {collaboratorRes &&
            collaboratorRes.length > 0 &&
            jobinfo &&
            jobinfo.jobWorkflowStages &&
            Array.isArray(jobinfo.jobWorkflowStages) &&
            jobinfo.jobWorkflowStages.length > 1 && (
              <InlineNotification
                lowContrast
                kind="success"
                title={
                  <div className="bx--row m-0">
                    <div className="mr-2">
                      you can modify default interview workflow stages for this
                      job.
                    </div>
                    <Link
                      to={{
                        pathname: `/app/campus/job/${jobinfo && jobinfo.jobId}`,
                        state: { activeSelectedTab: 4 },
                      }}>
                      {' '}
                      Edit Workflow
                    </Link>
                  </div>
                }
                subtitle=""
                hideCloseButton
              />
            )}
          <div className="candidates_container__header ml-3">
            <div className="row">
              <div className="col">
                <h4 className="bx--type-gamma font-weight-bold text-capitalize mb-0 pb-0">
                  {t('applicants')}
                </h4>
              </div>
            </div>
            {id && (
              <div>
                {jobinfo && jobinfo.isClosed && (
                  <InlineNotification
                    lowContrast
                    onCloseButtonClick={() => { }}
                    className="mt-1 mb-1"
                    title="This job is now closed and not accepting any applications."
                    subtitle=""
                    iconDescription="describes the close button"
                    kind="warning"
                  />
                )}
                <div className="-0 mb-2">
                  <div className=" xpa-appl-info--job-card p-0">
                    <div className="row">
                      {Object.values(jobDetails).length > 0 && (
                        <DetailsHeader details={jobDetails} t={t} />
                      )}
                      <div className="pb-2">
                        <div className="clearfix">
                          <Button className="" onClick={this.onDownloadClick}>Click here to download CVs of all applicants</Button>
                        </div>
                      </div>


                      {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 xpa-appl-info--job-card__actions">
                        <div className="xp-reset text-center ml-lg-5">
                          {jobinfo && !jobinfo.isClosed && canUserUploadResume && (<div className="xpa-bg__none mt-lg-3">
                            <div className="pb-2">
                              <div className="column pt-2">
                                <div className="clearfix">
                                  <Button onClick={() => this.activateModal()} className="xp-btn-width--L xpa-appl-info--job-card__resume-upload">
                                    UPLOAD RESUME
                                </Button>
                                </div>
                              </div>
                              <div className="column pt-2">
                                <div className="clearfix">
                                  <Button kind="tertiary" onClick={this.activeSearchTalentPoolModal} className="xp-btn-width--L xpa-appl-info--job-card__search-talent">
                                    SEARCH TALENT POOL
                                </Button>
                                </div>
                              </div>
                            </div>
                          </div>)}
                        </div>
                      </div> */}
                    </div>
                    <hr />
                  </div>
                </div>
                {/* {
                      jobinfo
                      && jobinfo.jobWorkflowStages
                      && Array.isArray(jobinfo.jobWorkflowStages)
                      && jobinfo.jobWorkflowStages.length > 1
                      && (
                        <div className="bx--tile pt-0 mb-2 pt-2">
                          <strong className="bx--type-zeta">Default interview workflow for shortlisted candidates:</strong>
                          {jobinfo.jobWorkflowStages.map((res) => {
                            return (
                              <div>
                                <div className="text-dark"><span>{res && res.workflowstage && res.workflowstage.stageName}</span></div>
                              </div>
                            );
                          }
                        )}
                        </div>
                      )
                    }
                    {
                      collaboratorRes && collaboratorRes.length > 0
                        && jobinfo
                        && jobinfo.jobWorkflowStages
                        && Array.isArray(jobinfo.jobWorkflowStages)
                        && jobinfo.jobWorkflowStages.length <= 1
                        && (
                          <div className="bx--tile pt-0 mb-2 pt-2">
                            <strong className="bx--type-zeta">Default interview workflow for shortlisted candidates:</strong>
                            <div className="">
                              <span>
                                There is currently no default interview workflow setup for this job.
                                To avoid manually setting interview workflow for each and every shortlisted applicant individually, click on the button below.
                              </span></div>
                            <Link
                              className="bx--btn bx--btn--sm bx--btn--danger mt-2"
                              to={{
                                pathname: `/app/campus/job/${jobinfo && jobinfo.jobId}`,
                                state: { activeSelectedTab: 4 },
                              }}
                            >
                              Setup Interview Workflow
                            </Link>
                          </div>
                        )
                    } */}
              </div>
            )}
            {id && (
              <div className=" bx--tile d-lg-none ">
                <div className="d-md-none d-sm-block bx--row ">
                  <div className="mt-2 bx--col-sm-12">
                    <ApplicantsTabs
                      handleApplicantsTabClick={e =>
                        this.handleApplicantsTabClick(e.target.value)
                      }
                      selectedTab={selectedTab}
                      tabCount={dashboard}
                    />
                  </div>
                  <div className="mt-2 bx--col-sm-12">
                    <ApplicantSort onSortValueChange={this.onSortValueChange} />
                  </div>
                  <div className="mt-2 bx--col-sm-12">
                    <RefreshScoresButton
                      handleRefreshScores={this.handleRefreshScores}
                    />
                  </div>
                </div>
              </div>
            )}
            <ResumeUpload
              pageType="candidates"
              jobId={id}
              dismiss={this.dismissModal}
              active={this.state.activeModal}
              onUploadSuccess={this.onResumeUploadSuccess}
            />
            <ApplicationBatchUpdateModalTranslate
              actionType={this.state.batchActionState}
              onRequestClose={this.dismissBatchModal}
              open={this.state.isOpenBatchActionModal}
              onRequestSubmit={this.batchHandler}
              buckets={this.props.buckets}
              handleChange={e => this.handleChange(e)}
              isBucketNameValid={this.state.isBucketNameValid}
              invalidText={this.state.invalidText}
              bucketName={this.state.bucketName}
              handleChangeRadioButton={this.handleChangeRadioButton}
              useBucket={this.state.useBucket}
            />
            <EmailModal
              actionType={this.state.emailActionType}
              open={this.state.isOpenEmailModal}
              onRequestSubmit={this.handleSendEmail}
              onRequestClose={this.dismissEmailModal}
              onEditEmailChange={this.onEditEmailChange}
              editEmailBeforeSend={this.state.editEmailBeforeSend}
              onViewEmail={this.onViewEmail}
              isLoading={isSendingEmail}
              notifObj={notifObj}
              application={this.state.activeApplication}
              onEmailModalTemplateChange={this.onEmailModalTemplateChange}
              emailTemplate={this.state.emailTemplate}
            />
          </div>
          {/* {aiSourcing && (
            <div>
              <div className="bx--type-zeta">Add more candidates from AI Source:</div>
              <div>We have filtered eligible candidate's profiles and added to this job. If you want to import more candidates with matching job criteria, click on the button below.</div>
              <div className="mt-2"><Button small onClick={() => this.setState({ addMoreCandidates: true })} kind="tertiary">Get More Candidates</Button></div>
            </div>
          )} */}
          <div id="table-layout" className="mb-2 pt-1 mt-1 d-block">
            {/* <GenericTabs
              selected={selectedSection}
              className="xpa-appl-info--list-tabs"
              tabContent={tabContent}
            /> */}

            {/* {selectedSection == 0 && ( */}
            {/* {sortedApplicant && Array.isArray(sortedApplicant) && sortedApplicant.length>0 && */}
            <ApplicantTableBox
              selectedTab={selectedTab}
              minMax={minMax}
              tabCount={dashboard}
              onSortValueChange={this.onSortValueChange}
              handleWorkflowSetup={this.handleWorkflowSetup}
              handleWorkflowStatusUpdate={this.handleWorkflowStatusUpdate}
              applications={this.state.sortedApplicant}
              addCandidateToTalentPool={this.addCandidateToTalentPool}
              handleCandidateShortlist={this.handleCandidateShortlist}
              handleSearchSubmit={this.handleSearchSubmit}
              columnSortJobList={this.columnSortJobList}
              sortClicked={this.sortClicked}
              state={this.state}
              batchActionClickHandler={this.batchActionClickHandler}
              handleRefreshScores={this.handleRefreshScores}
              taskStatus={this.props.taskStatus}
              onUploadSuccess={this.state.resumeFileCount}
              handleApplicantsTabClick={this.handleApplicantsTabClick}
              handleOpenEmailModal={this.handleOpenEmailModal}
              activeSendOfferModal={this.activeSendOfferModal}
              user={this.props.user}
              applTitles={applTitles}
              applicationDocs={applicationDocs}
              jobDetails={
                this.props.applications && this.props.applications.jobInfo
              }
              activePage={this.state.activePage}
              params={
                this.props && this.props.match && this.props.match.params
              }
              jobId={
                this.props &&
                this.props.match &&
                this.props.match.params &&
                this.props.match.params.id
              }
              itemsPerPage={this.state.itemsPerPage}
              searchKey={this.state.keyword}
              status={this.state.status}
              history={this.props.history}
              t={t}
              statusSearch={
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.search
              }
              search={this.props.location && this.props.location.search}
              count={count}
              handlePaginationChange={this.handlePaginationChange}
              sortedApplicant={this.state.sortedApplicant}
              defaultCheckList={defaultCheckList}
            />
            {/* } */}
            {/* {selectedSection == 0 &&
              this.state.sortedApplicant &&
              this.state.sortedApplicant.length > 0 && (
                <div className="">
                  <Pagination
                    onChange={this.handlePaginationChange}
                    pageSizes={[10, 20, 30, 40, 50]}
                    pageSize={this.state.itemsPerPage}
                    page={this.state.activePage}
                    totalItems={count}
                  />
                </div>
              )} */}
            {/* )} */}

            {selectedSection == 1 && <JobAnalytics jobId={id || 0} />}

            <CreateWorkflowModal
              dismiss={this.dismissWorkflowModal}
              show={this.state.activeWorkflowModal}
              updateParentPage={this.refresh}
              application={this.state.selectedApplicationForWorkflow}
              appId={this.state.selectedAppIdForWorkflow}
              value={this.props && this.props.match && this.props.match.params}
            />

            <WorkflowStatusUpdateModal
              dismiss={this.dismissWorkflowStatusUpdateModal}
              show={this.state.activateWorkflowStatusUpdateModal}
              showInModal={true}
              jobId={
                id ||
                (this.state.selectedApplicationForWorkflow &&
                  this.state.selectedApplicationForWorkflow.jobId)
              }
              updateParentPage={this.refresh}
              application={this.state.selectedApplicationForWorkflow}
              appId={this.state.selectedAppIdForWorkflow || 0}
              handleOpenEmailModal={e => this.handleOpenEmailModal(e)}
            />
          </div>
          {!id && (
            <div className="bx--tile d-lg-none d-md-block d-sm-block">
              <ApplicantsTabs
                handleApplicantsTabClick={e =>
                  this.handleApplicantsTabClick(e.target.value)
                }
                selectedTab={selectedTab}
                tabCount={dashboard}
              />
            </div>
          )}

          {this.state.sortedApplicant &&
            Array.isArray(this.state.sortedApplicant) &&
            this.state.sortedApplicant.map((details, index) => {
              const {
                profile,
                isInTalentPool,
                isLiked,
                applicationSourceId,
              } = details;
              const { firstName, lastName } = profile || {};
              return (
                <CustomCard
                  titleOnClick={() =>
                    push(
                      `/app/campus/candidate/${details.appId}/job/${details &&
                      details.job &&
                      details.job.jobId}/candidateprofile`
                    )
                  }
                  className="card-layout"
                  title={
                    <div className="d-flex">
                      {`${firstName} ${lastName}`}
                      {isInTalentPool && (
                        <span className="ml-1">
                          <TooltipIcon tooltipText="Talent pool candidate">
                            <svg width="20" height="20" fill-rule="evenodd">
                              <path
                                d="M6 15.745A6.968 6.968 0 0 0 10 17a6.968 6.968 0 0 0 4-1.255V15.5a2.5 2.5 0 0 0-2.5-2.5h-3A2.5 2.5 0 0 0 6 15.5v.245zm-.956-.802A3.5 3.5 0 0 1 8.5 12h3a3.5 3.5 0 0 1 3.456 2.943 7 7 0 1 0-9.912 0zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"
                                fill-rule="nonzero"></path>
                              <path
                                d="M10 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                                fill-rule="nonzero"></path>
                            </svg>
                          </TooltipIcon>
                        </span>
                      )}
                      {isLiked && (
                        <span className="ml-1 mt-1">
                          <TooltipIcon tooltipText="Favourite candidate">
                            <svg width="13" height="13" viewBox="0 0 16 15">
                              <path d="M8.109 11.75l-4.5 3.2c-.2.1-.5 0-.4-.3l1.5-5.1-4.6-3.2c-.2-.2-.1-.5.1-.5l5.5-.5 2-5.2c.1-.2.4-.2.5 0l2 5.2 5.5.5c.2 0 .3.3.1.4l-4.5 3.3 1.5 5.1c.1.2-.2.4-.4.3l-4.3-3.2z"></path>
                            </svg>
                          </TooltipIcon>
                        </span>
                      )}
                      {applicationSourceId && applicationSourceId === 5 && (
                        <span className="badge badge-secondary d-inline-block ml-1">
                          AI Sourced
                        </span>
                      )}
                    </div>
                  }
                  key={`card_${index}`}>
                  <CardContent
                    details={details}
                    handleCandidateShortlist={this.handleCandidateShortlist}
                  />
                </CustomCard>
              );
            })}
          {
            <Modal
              onRequestSubmit={this.templateModalSubmit}
              onRequestClose={this.templateModalClose}
              open={this.state.selectTemplateModal}
              modalLabel="Email Templates"
              primaryButtonText={this.state.sendingEmails ? "Sending..." : "Submit"}
              secondaryButtonText="Cancel"
              modalHeading="Send Mail">
              <div className="bx--modal-content__text">
                {this.state.noTemplateError && (
                  <InlineNotification
                    lowContrast
                    kind="error"
                    title="No template selected"
                    subtitle=""
                    hideCloseButton
                    onCloseButtonClick={() =>
                      this.setState({ noTemplateError: false })
                    }
                  />
                )}
                {this.state.notifShow && (
                  <InlineNotification
                    lowContrast
                    kind={this.state.notifKind}
                    title={this.state.notifMsg}
                    subtitle=""
                    hideCloseButton
                    onCloseButtonClick={() =>
                      this.setState({ notifShow: false })
                    }
                  />
                )}
                <Select
                  labelText="Select Email Template"
                  className="mb-4"
                  id="email-templates-list"
                  onChange={this.onEmailTemplateChange}
                  value={this.state.selectedEmailTemplate}
                  defaultValue="placeholder-item">
                  <SelectItem
                    disabled
                    value="placeholder-item"
                    text="Choose a template"
                  />
                  {customEmailTemplates &&
                    Array.isArray(customEmailTemplates) &&
                    customEmailTemplates.map(
                      ({ displayName = '', id = null, templateName = '' }) => (
                        <SelectItem
                          key={id}
                          name={templateName}
                          label={displayName}
                          value={`${id}_${templateName}`}
                        />
                      )
                    )}
                </Select>
                {
                  <Fragment>
                    <span className="pt-0 pb-1 text-dark d-block">
                      Clicking on <strong>submit</strong> will send an email to
                      the applicant(s).&nbsp;
                      <span className="xpa-link" onClick={this.onCustomTemplatePreview}>
                        Click here
                      </span>{' '}
                      to view content of the email.
                    </span>
                  </Fragment>
                }
              </div>
            </Modal>
          }

          <Modal
            onRequestSubmit={this.handleTransferJobSubmit}
            onRequestClose={this.dismissTransferJobModal}
            open={this.state.showTransferJobModal}
            // modalLabel="Transfer Job"
            // size="sm"
            primaryButtonText="Transfer"
            secondaryButtonText="Cancel"
            modalHeading="Transfer Job">
            {this.state.showSelectTranferJobError &&
              <InlineNotification
                lowContrast
                kind="error"
                title="Please select job to tranfer"
                subtitle=""
                hideCloseButton
                onCloseButtonClick={() =>
                  this.setState({ showSelectTranferJobError: false })
                }
              />}
            Select the job that you would like to transfer to
            <Select
              light
              className="bx--col-xs-12 bx--col-sm-12 bx--col-md-6 bx--col-lg-4 bx--col-xl-4 p-0"
              hideLabel
              invalidText="A valid value is required"
              onChange={(e) => {
                this.setState({ transferJobValue: e.target.value, showSelectTranferJobError: false })
              }}
              id="select-1"
              // defaultValue={this.state.transferJobDefaultValue}
              value={this.state.transferJobValue}>
              <SelectItem disabled hidden value="placeholder-item" text={t('Select Job')} />
              {companyJobs && Array.isArray(companyJobs) && companyJobs.length > 0 &&
                companyJobs.map((companyJob) => {
                  const { jobname: { nameName }, jobId } = companyJob;
                  const { transferJobRequiredJobId } = this.state;
                  if (parseInt(jobId) !== parseInt(transferJobRequiredJobId)) {
                    return <SelectItem value={`${jobId}`} text={nameName} />
                  }
                })
              }
            </Select>

          </Modal>

          <Modal
            // onRequestSubmit={this.handleTransferJobSubmit}
            onRequestClose={() => this.setState({ showTransferJobResponseModal: false }, () => { window.location.reload() })}
            // open={true}
            open={this.state.showTransferJobResponseModal}
            // modalLabel="Transfer Job"
            // size="sm"
            // primaryButtonText="Transfer"
            // secondaryButtonText="Cancel"
            size="lg"
            passiveModal
            modalHeading="Transferred Applications Status">
            <InlineNotification
              className="xpa-customize-inline-notification"
              lowContrast
              kind="error"
              title=""
              subtitle=""
              hideCloseButton
              onCloseButtonClick={() => { }}
            >
              <div className="d-flex">
                <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 20 20" aria-hidden="true" class="customize--bx--inline-notification-error__icon"><path d="M10,1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S15,1,10,1z M13.5,14.5l-8-8l1-1l8,8L13.5,14.5z"></path><path d="M13.5,14.5l-8-8l1-1l8,8L13.5,14.5z" data-icon-path="inner-path" opacity="0"></path><title>closes notification</title></svg>
                <p class="bx--inline-notification__title mb-1">Unsuccessful Applications {`(${Math.abs(parseInt(totalAppsCount) - transferredApps.length)} out of ${totalAppsCount})`}</p>
              </div>

              {isTransferJobApplsFilled === true &&
                <h4>Transferred job vacancies are filled.</h4>
              }

              {(existingAppsList.length > 0 || appsWithNoCourses.length > 0) ?
                (<div>
                  {(existingAppsList.length > 0) &&
                    <div>
                      {/* {`(${existingAppsList.length} out of ${existingAppsList.length + appsWithNoCourses.length})`}  */}
                      <p className="mb-0">Transfer of below applications are unsuccessful because the <strong>applicants has already applied to the transfering job</strong>.</p>
                      <div class="bx--row mb-3">
                        {
                          existingAppsList.map((existingApplication) => {
                            const { appId = "", profile, userInfo } = existingApplication || {};
                            const { firstName = "", lastName = "" } = profile || {};
                            const { picture } = userInfo || {};
                            return (
                              <div className="bx--col-lg-6 p-0 xpa-bx-col-m">
                                <div className="xpa-app-card ">
                                  <div>
                                    <small className="text-dark">
                                      {t('applicationId')}: {appId}
                                    </small>
                                  </div>
                                  <Avatar
                                    user={{
                                      firstname: firstName,
                                      lastname: lastName,
                                      picture: (picture !== undefined) ? picture : null
                                      // picture: application.shortlisted && application.inviteaccept ? application && application.candidateInfo && application.candidateInfo.userinfo && application.candidateInfo.userinfo.picture : null,
                                    }}
                                    size="25"
                                    classNameInfo="rounded-circle mr-1 d-inline-block"
                                  />
                                  <div
                                    // onClick={() => { history && history.push && history.push(`/app/campus/candidate/${application.appId}/job/${jobId}/candidateprofile`) }} 
                                    // card-title mb-1 xpa-link text-center font-weight-bold text-capitalize 1.05 .xp-avatar-25 top:-2
                                    className="d-inline-block bx--type-delta">
                                    {`${firstName} ${lastName}`}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  }

                  {(appsWithNoCourses.length > 0) &&
                    <div>
                      {/* {`(${appsWithNoCourses.length} out of ${appsWithNoCourses.length + appsWithNoCourses.length})`}  */}
                      <p className="mb-0">Transfer of below applications are unsuccessful because the <strong>courses in these applications are not approved in transfering job</strong>.</p>
                      <div class="bx--row">
                        {appsWithNoCourses.map((appWithNoCourses) => {
                          const { appId = "", profile, userInfo } = appWithNoCourses || {};
                          const { firstName = "", lastName = "" } = profile || {};
                          const { picture } = userInfo;
                          return (
                            <div className="bx--col-lg-6 p-0 xpa-bx-col-m">
                              <div className="xpa-app-card ">
                                <div>
                                  <small className="text-dark">
                                    {t('applicationId')}: {appId}
                                  </small>
                                </div>
                                <Avatar
                                  user={{
                                    firstname: firstName,
                                    lastname: lastName,
                                    picture: (picture !== undefined) ? picture : null,
                                    // picture: application.shortlisted && application.inviteaccept ? application && application.candidateInfo && application.candidateInfo.userinfo && application.candidateInfo.userinfo.picture : null,
                                  }}
                                  size="25"
                                  classNameInfo="rounded-circle mr-1 d-inline-block"
                                />
                                <div
                                  // onClick={() => { history && history.push && history.push(`/app/campus/candidate/${application.appId}/job/${jobId}/candidateprofile`) }} 
                                  // card-title mb-1 xpa-link text-center font-weight-bold text-capitalize 1.05 .xp-avatar-25 top:-2
                                  className="d-inline-block bx--type-delta">
                                  {`${firstName} ${lastName}`}
                                </div>
                              </div>
                            </div>
                          );
                        })
                        }
                      </div>
                    </div>
                  }
                </div>) : (
                  <div>
                    <h4>No applications to show. All applications have been transferred successfully.</h4>
                  </div>
                )
              }
            </InlineNotification>

            <InlineNotification
              className="xpa-customize-inline-notification"
              lowContrast
              kind="success"
              title=""
              subtitle=""
              hideCloseButton
              onCloseButtonClick={() =>
                this.setState({ addCandidatesModalError: false })
              }
            >
              <div className="d-flex">
                <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 20 20" aria-hidden="true" class="customize--bx--inline-notification-success__icon"><path d="M10,1c-4.9,0-9,4.1-9,9s4.1,9,9,9s9-4,9-9S15,1,10,1z M8.7,13.5l-3.2-3.2l1-1l2.2,2.2l4.8-4.8l1,1L8.7,13.5z"></path><path fill="none" d="M8.7,13.5l-3.2-3.2l1-1l2.2,2.2l4.8-4.8l1,1L8.7,13.5z" data-icon-path="inner-path" opacity="0"></path><title>closes notification</title></svg>
                <p class="bx--inline-notification__title mb-1">Successfully Transferred Applications {`(${transferredApps.length} out of ${totalAppsCount})`}</p>
              </div>
              {transferredApps.length > 0 ?
                <div class="bx--row">
                  {transferredApps.map((transferredApp) => {
                    const { appId = "", profile, userInfo } = transferredApp || {};
                    const { firstName = "", lastName = "" } = profile || {};
                    const { picture } = userInfo || {};
                    return (
                      <div className="bx--col-lg-6 p-0 xpa-bx-col-m">
                        <div className="xpa-app-card ">
                          <div>
                            <small className="text-dark">
                              {t('applicationId')}: {appId}
                            </small>
                          </div>
                          <Avatar
                            user={{
                              firstname: firstName,
                              lastname: lastName,
                              picture: (picture !== undefined) ? picture : null,
                            }}
                            size="25"
                            classNameInfo="rounded-circle mr-1 d-inline-block"
                          />
                          <div
                            // onClick={() => { history && history.push && history.push(`/app/campus/candidate/${application.appId}/job/${jobId}/candidateprofile`) }} 
                            // card-title mb-1 xpa-link text-center font-weight-bold text-capitalize 1.05 .xp-avatar-25 top:-2
                            className="d-inline-block bx--type-delta">
                            {`${firstName} ${lastName}`}
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div> :
                <div>
                  <h4>No applications have been transferred successfully.</h4>
                </div>
              }
            </InlineNotification>
          </Modal>

          {/* {(<div className="d-block bx--tile mt-3">
            <div className="">
              <h5>{t('legend')}</h5>
              <div className="d-block">
                <ColorLegend t={t} className="col-12" />
              </div>
              <StructuredListWrapper className="mb-0 d-none d-sm-block d-md-block">
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>
                      {t('metric')}
                  </StructuredListCell>
                    <StructuredListCell head>
                      {t('definition')}
                  </StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell noWrap className="bx--modal-header__label text-dark">
                      {t('skillScore')}
                    </StructuredListCell>
                    <StructuredListCell>
                      {t('cvRelevanceMsg')}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell noWrap className="bx--modal-header__label text-dark">
                      Benchmark
                    </StructuredListCell>
                    <StructuredListCell>
                    This score signifies how similar is the candidate's profile when compared to employees at similar position in an organization.
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell noWrap className="bx--modal-header__label text-dark">
                    {t('compatibilityScore')}
                    </StructuredListCell>
                    <StructuredListCell>
                      {t('loyaltyMsg')}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell noWrap className="bx--modal-header__label text-dark">
                    {t('finalScore')}

                    </StructuredListCell>
                    <StructuredListCell>
                      {t('performanceMsg')}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
          </div>)} */}
        </div>
        <JDProcessOverlay show={this.props.loading || this.props.companyJobsLoading} message="processing..." />
        <Toast
          taskId={
            taskId ||
            (talentSearchPayload && talentSearchPayload.task_id) ||
            refreshscoreid
          }
          successAction={this.toastSuccess}
          partialRefresh={this.refresh}
        />
        <Modal
          className="some-class"
          open={this.state.isOpenSearchTalentPoolModal}
          modalHeading="Search Talent Pool"
          modalAriaLabel=""
          primaryButtonText="Search"
          secondaryButtonText="Cancel"
          onRequestClose={this.dismissSearchTalentPoolModal}
          onRequestSubmit={() => this.startTalentPoolSearch()}>
          <div>
            <p className="bx--modal-content__text">
              Select an option to search for recruiter talent pool
            </p>
            <div className="mt-4 ml-2">
              <RadioButton
                name="radio"
                defaultChecked
                onChange={e => this.handleChangeTalentPoolButton(e)}
                value="search"
                id="radio-1"
                disabled={false}
                labelText="Search Entire Talent Pool"
              />
              <RadioButton
                name="radio"
                onChange={e => this.handleChangeTalentPoolButton(e)}
                value="select"
                id="radio-2"
                disabled={false}
                labelText="Select an existing bucket to search"
              />
            </div>
            {searchPool === false && (
              <div className="mt-2 mb-2">
                <Select
                  name="selectedBucketId"
                  hideLabel
                  id="search-talentpool-bucket"
                  onChange={e => this.handleChange(e)}>
                  <SelectItem text="Select" />
                  {this.props.buckets &&
                    this.props.buckets.map(b => (
                      <SelectItem
                        key={b.bucketId}
                        text={`${b.bucketName}`}
                        value={b.bucketId}
                      />
                    ))}
                </Select>
              </div>
            )}
          </div>
        </Modal>
        <Modal
          open={this.state.addMoreCandidates}
          modalHeading="Add more candidates"
          primaryButtonText="Add"
          secondaryButtonText="Cancel"
          onRequestClose={() =>
            this.setState({
              addCandidatesCount: 0,
              addMoreCandidates: false,
              addCandidatesModalError: false,
            })
          }
          onRequestSubmit={() => this.addMoreCandidates()}>
          <Fragment>
            <div className="p-2">
              {this.state.addCandidatesModalError && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  title="No count selected"
                  subtitle=""
                  hideCloseButton
                  onCloseButtonClick={() =>
                    this.setState({ addCandidatesModalError: false })
                  }
                />
              )}
              <div className="bx--type-epsilon">
                How many Ai Source Candidates would you like to import ?
              </div>
              <div className="bx--row m-0 d-flex align-items-baseline mt-2">
                <Select
                  name="addCandidatesCount"
                  hideLabel
                  id="add_candidates_count"
                  onChange={e => this.handleChange(e)}
                  value={this.state.addCandidatesCount}>
                  <SelectItem text="count" />
                  <SelectItem key={10} text={`10`} value={10} />
                  <SelectItem key={20} text={`20`} value={20} />
                  <SelectItem key={30} text={`30`} value={30} />
                  <SelectItem key={40} text={`40`} value={40} />
                  <SelectItem key={50} text={`50`} value={50} />
                  <SelectItem key={100} text={`100`} value={100} />
                </Select>
              </div>
            </div>
          </Fragment>
        </Modal>
      </div>
    );
  }
}

// todo- change style of container border depdinging on value
// eslint-disable-next-line

const mapStateToProps = state => ({
  loading: state.roboroyApplications.loading,
  applications: state.roboroyApplications,
  talentSearchComplete: state.roboroyApplications.talentSearchComplete,
  tasks: state.resumeUpload,
  talentSearchPayload: state.roboroyApplications.talentSearchPayload,
  taskStatus: state.taskStatus,
  buckets: state.roboroyTalentPool.buckets,
  user: state.x0paDuser.user,
  workflowstages: state.workflows.workflowstages,
  emailTemplates: state.emailTemplates.templates,
  companyJobs: state.companyJobs.companyJobs,
  companyJobsLoading: state.companyJobs.loading
});

const mapDispatchToProps = {
  findFromUserTalentPool,
  reRenderContent,
  setShortlistStatus,
  addApplicantToTalentPool,
  bulkShortlistReject,
  bulkLike,
  getRefreshScores,
  getWorkflowStages,
  getAllUserWorkflow,
  getBucketNames,
  createBucket,
  sendEmailTemplate,
  getCustomEmailTemplates,
  getEmailTemplates,
  sendEmails,
  getMoreCandidatesAiSourcing,
  getDocumentByIds,
  getCompanyJobPostings,
  transferApplications
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CandidatesListXopa));
