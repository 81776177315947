import callApi from 'utils/callApi';
import { baseUrl } from 'utils/config';
import {
  REQUEST_GET_INTERVIEWS,
  REQUEST_CREATE_INTERVIEW,
  REQUEST_GET_INTERVIEW_URL,
  REQUEST_GET_INTERVIEW,
  REQUEST_INTERVIEW_INVITE,
  REQUEST_CANDIDATE_ANSWERS,
  REQUEST_INTERVIEW_STATUS,
  REQUEST_SEND_REMINDER,
  REQUEST_VIDEO_INTERVIEW_RESPONSE_DETAILS,
  REQUEST_CANDIDATE_VIDEO_INTERVIEW_RATING,
  REQUEST_GET_VIDEO_INDEXER,
  REQUEST_GET_CAND_VI_RESPONSES_BY_ID,
} from './actionTypes';

const createAnInterview = ({
  appId, jobId, isJobWorkflow = false, appStageUserMapId,
  interviewName, templateId, stageId, workflowId } = {}) => (dispatch) => {
    const err = {
      data: '',
      error: true,
      message: 'No application id passed',
    };
    if (!interviewName) {
      return Promise.reject(err);
    }
    const vurl = `${baseUrl}/videointerviews?_=${new Date().getTime()}`;

    const data = {
      appId,
      jobId,
      appStageUserMapId,
      interviewName,
      isJobWorkflow,
      stageId,
      workflowId,
    };
    if (templateId) {
      data.templateId = templateId;
    }
    const params = {
      headers: {
        'content-type': 'application/json',
      },
      actionType: REQUEST_CREATE_INTERVIEW,
      dispatch,
      url: vurl,
      method: 'POST',
      body: JSON.stringify(data),
    };

    return callApi(params);
  };

const getInterviewsForRecruiter = ({
  itemsPerPage,
  activePage = 0,
  filterOptions = '',
  searchKey = null,
  status = 'draft',
  currentCompanyId,
}) => (dispatch) => {
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let xcid = new Date().getTime();
  if (currentCompanyId) {
    xcid = currentCompanyId;
  }
  const vurl = `${baseUrl}/videointerviews?xcid=${xcid}&status=${status}${itemsPerPage ? `&filter[limit]=${itemsPerPage}` : ''}&filter[skip]=${skip}${(searchKey) ? `&searchKey=${searchKey}` : '&'
    }`.concat(filterOptions);
  const params = {
    actionType: REQUEST_GET_INTERVIEWS,
    dispatch,
    url: vurl,
    method: 'GET',
  };

  return callApi(params);
};

const getInterviewURL = ({ interviewId } = {}) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No interviewId passed',
  };
  if (!interviewId) {
    return Promise.reject(err);
  }
  const vurl = `${baseUrl}/videointerviews/${interviewId}/urls?_=${new Date().getTime()}`;
  const data = JSON.stringify({
    interviewId,
  });
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_GET_INTERVIEW_URL,
    dispatch,
    url: vurl,
    method: 'GET',
  };

  return callApi(params);
};

const getAnInterview = ({ interviewId } = {}) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No interview id passed',
  };
  if (!interviewId) {
    return Promise.reject(err);
  }
  const vurl = `${baseUrl}/videointerviews/${interviewId}?_=${new Date().getTime()}`;
  const data = JSON.stringify({
    interviewId,
  });
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_GET_INTERVIEW,
    dispatch,
    url: vurl,
    method: 'GET',
  };

  return callApi(params);
};

const inviteCandidate = (pdata = {}) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid invite candidate data passed',
  };
  const {
    interviewId, interviewName, email, interviewURL,
    firstName, lastName, preferName, atsJobId, atsCandidateId,
    templateName,
  } = pdata;
  if (!interviewName || !email || !interviewURL || !interviewId || !atsJobId || !atsCandidateId) {
    return Promise.reject(err);
  }
  const vurl = `${baseUrl}/videointerviews/invites?_=${new Date().getTime()}`;
  const data = JSON.stringify({
    interviewId,
    interviewName,
    email,
    interviewURL,
    firstName,
    lastName,
    preferName,
    atsJobId,
    atsCandidateId,
    templateName,
  });
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_INTERVIEW_INVITE,
    dispatch,
    url: vurl,
    method: 'POST',
    body: data,
  };

  return callApi(params);
};

const candidateAnswers = ({ interview, candidate } = {}) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid data passed',
  };
  if (!interview || !candidate) {
    return Promise.reject(err);
  }
  const vurl = `${baseUrl}/videointerviews/${interview}/answers?_=${new Date().getTime()}`;
  // const data = JSON.stringify({
  //   interview,
  //   candidate,
  // });
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_CANDIDATE_ANSWERS,
    dispatch,
    url: vurl,
    method: 'GET',
    // body: data,
  };

  return callApi(params);
};

const getInterviewStatus = ({ interview, questionsEditable = true } = {}) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid data passed',
  };
  if (!interview || !questionsEditable) {
    return Promise.reject(err);
  }
  const vurl = `${baseUrl}/videointerviews/${interview}/status?_=${new Date().getTime()}`;
  const data = JSON.stringify({
    interview,
    questionsEditable,
  });
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_INTERVIEW_STATUS,
    dispatch,
    url: vurl,
    method: 'PUT',
    body: data,
  };

  return callApi(params);
};

const sendReminder = (reminderObj = {}) => (dispatch) => {
  const {
    email,
    firstName,
    lastName,
    recruiterName,
    templateName,
    companyName,
    interviewId,
  } = reminderObj;
  const err = {
    data: '',
    error: true,
    message: 'No valid data passed',
  };
  if (!email || !templateName || !recruiterName || !companyName || !interviewId) {
    return Promise.reject(err);
  }
  const vurl = `${baseUrl}/videointerviews/invites?_=${new Date().getTime()}`;
  const data = JSON.stringify(reminderObj);
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_SEND_REMINDER,
    dispatch,
    url: vurl,
    method: 'POST',
    body: data,
  };

  return callApi(params);
};

const fetchVideoInterviewDetails = (id) => (dispatch) => {
  const url = `${baseUrl}/videointerviews/${id}/ratings?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_VIDEO_INTERVIEW_RESPONSE_DETAILS,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const videoInterviewRating = ({ interviewId, questionId, rating, comments } = {}) => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid data passed',
  };
  if (!questionId) {
    return Promise.reject(err);
  }
  const vurl = `${baseUrl}/videointerviews/${interviewId}/ratings`;
  const data = JSON.stringify([{
    questionId,
    rating,
    comments,
  }]);
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_CANDIDATE_VIDEO_INTERVIEW_RATING,
    dispatch,
    url: vurl,
    method: 'POST',
    body: data,
  };

  return callApi(params);
};

const getVideoInterviewEmotions = questionId => (dispatch) => {
  const url = `${baseUrl}/videointerviewresponses/${questionId}?_=${new Date().getTime()}`;
  const params = {
    actionType: REQUEST_GET_VIDEO_INDEXER,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getCandidateVIById = sid => (dispatch) => {
  const err = {
    data: '',
    error: true,
    message: 'No valid data passed',
  };
  if (!sid) {
    return Promise.reject(err);
  }
  const url = `${baseUrl}/events/s/assessments/details?_=${new Date().getTime()}&profileId=${sid}`;
  const params = {
    actionType: REQUEST_GET_CAND_VI_RESPONSES_BY_ID,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);

}
export {
  getInterviewsForRecruiter,
  createAnInterview,
  getInterviewURL,
  getAnInterview,
  inviteCandidate,
  candidateAnswers,
  getInterviewStatus,
  sendReminder,
  fetchVideoInterviewDetails,
  videoInterviewRating,
  getVideoInterviewEmotions,
  getCandidateVIById
};
