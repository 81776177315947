const publicRoutes = [
  '/',
  '/forgot-password',
  '/reset-password',
  '/login',
  '/500',
  '/robocampus/register',
  '/campus/login',
  '/workinfinland',
  '/professional/login',
  '/recruiter/login',
  '/public/:component',
];

const selectedApplicationIndex = (products = []) => {
  const pathname = window.location.pathname;
  const uniqProducts = [...new Set(products)];
  const productsList = uniqProducts.map(p => p.toLowerCase());
  let index = 1;
  if (pathname.includes('enterprise')) {
    index = productsList.indexOf('enterprise');
  } else if (pathname.includes('company')) {
    index = products.length + 2;
  } else if (pathname.includes('roboroy')) {
    index = productsList.indexOf('roboroy');
  } else if (pathname.includes('admin')) {
    index = products.length;
  } else if (pathname.includes('profile')) {
    index = products.length + 1;
  }
  
  return index;
};

const activeProducts = (products = [], isAdmin, companyName='') => {
  const uniqProducts = [...new Set(products)];
  const res = uniqProducts
    .filter(p => ['roboroy', 'x0pa ext'].includes(p.toLowerCase()))
    .map((product) => {
      let path;
      switch (product.toLowerCase()) {
        case 'roboroy':
          path = '/app/roboroy';
          break;
        case 'x0pa ext':
          path = '/app/roboroy';
          break;
        default:
          path = null;
      }
      return {
        label: product,
        path,
      };
    });
  // if (isAdmin && Array.isArray(res)) {
  //   res.push({
  //     label: 'Admin',
  //     path: '/app/admin',
  //   });
  // }
  // res.push({
  //   label: 'Profile',
  //   path: '/app/profile',
  // });
  // if(uniqProducts.includes('ROBOROY'))
  //   res.push({
  //     label: companyName.length>0?companyName:"Company",
  //     path: '/app/campus/company',
  //   })
  return res;
};

const isPublicRoute = (route) => {
  const hrp = route.includes('/invite/') || route.includes('/registration/')
  || route.includes('/app/campus/job/') || route.includes('/view/')
  || route.includes('/public/');
  const ipr = publicRoutes.includes(route);
  return (hrp || ipr);
};

export {
  selectedApplicationIndex,
  activeProducts,
  isPublicRoute,
};
