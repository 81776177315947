import {
  REQUEST_ALL_QUALTYPE,
  ERROR_ALL_QUALTYPE,
  SUCCESS_ALL_QUALTYPE,
  REQUEST_ALL_HOURTYPE,
  ERROR_ALL_HOURTYPE,
  SUCCESS_ALL_HOURTYPE,
  REQUEST_ALL_MAJOR,
  ERROR_ALL_MAJOR,
  SUCCESS_ALL_MAJOR,
  REQUEST_ALL_COUNTRY,
  ERROR_ALL_COUNTRY,
  SUCCESS_ALL_COUNTRY,
  REQUEST_FEW_SCHOOLS,
  ERROR_FEW_SCHOOLS,
  SUCCESS_FEW_SCHOOLS,
  REQUEST_ALL_QUALIFICATION,
  ERROR_ALL_QUALIFICATION,
  SUCCESS_ALL_QUALIFICATION,
  REQUEST_ALL_COMPANIES,
  SUCCESS_ALL_COMPANIES,
  REQUEST_CANDIDATE_JOBS,
  SUCCESS_CANDIDATE_JOBS,
  REQUEST_CREATE_NEW_CERTIFICATE,
  SUCCESS_CREATE_NEW_CERTIFICATE,
  REQUEST_CREATE_NEW_ORGANIZATION,
  SUCCESS_CREATE_NEW_ORGANIZATION,
  REQUEST_FEW_CERTIFICATES,
  SUCCESS_FEW_CERTIFICATES,
  REQUEST_FEW_ETHNICITIES,
  SUCCESS_FEW_ETHNICITIES,
  REQUEST_FEW_ORGANIZATION,
  SUCCESS_FEW_ORGANIZATION,
  REQUEST_FEW_CERTTYPE,
  SUCCESS_FEW_CERTTYPE,
  REQUEST_GET_ID_DATA,
  SUCCESS_GET_ID_DATA,
  REQUEST_CREATE_PROFILEINFO,
  REQUEST_UPDATE_PROFILEINFO,
  REQUEST_DELETE_PROFILEINFO,
  SUCCESS_CREATE_PROFILEINFO,
  SUCCESS_UPDATE_PROFILEINFO,
  SUCCESS_DELETE_PROFILEINFO,
  REQUEST_REMOVE_PROFILESKILL,
  REQUEST_ADD_PROFILESKILL,
  SUCCESS_REMOVE_PROFILESKILL,
  SUCCESS_ADD_PROFILESKILL,
  REQUEST_UPDATE_PROFILE,
  SUCCESS_UPDATE_PROFILE,
  REQUEST_FEW_ACTIVITIES,
  SUCCESS_FEW_ACTIVITIES,
  REQUEST_FEW_HOBBIES,
  SUCCESS_FEW_HOBBIES,
  REQUEST_FEW_HOBBYTYPES,
  SUCCESS_FEW_HOBBYTYPES,
  REQUEST_FEW_ACTIVITIESTYPES,
  SUCCESS_FEW_ACTIVITIESTYPES,
  REQUEST_GET_PROFILE_VIEWS,
  SUCCESS_GET_PROFILE_VIEWS,
  ERROR_GET_PROFILE_VIEWS,
  REQUEST_FEW_FACULTY,
  SUCCESS_FEW_FACULTY,
  REQUEST_FEW_COURSES,
  SUCCESS_FEW_COURSES,
  REQUEST_ALL_PROFILE_COURSES,
  SUCCESS_ALL_PROFILE_COURSES,
  ERROR_ALL_PROFILE_COURSES,
  REQUEST_ALL_SCHOOL_FACULTIES,
  SUCCESS_ALL_SCHOOL_FACULTIES,
  ERROR_ALL_SCHOOL_FACULTIES,
  REQUEST_CREATE_PROFILE_COURSES,
  SUCCESS_CREATE_PROFILE_COURSES,
  ERROR_CREATE_PROFILE_COURSES,
} from '../../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  dashboardProfileViews: [],
  dashboardProfileViewsCount: 0,
  facultyData: [],
  facultyCount: 0,
  coursesData: [],
  coursesCount: 0,
};

const candidateDetails = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case REQUEST_ALL_QUALTYPE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_ALL_QUALTYPE:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_ALL_QUALTYPE:
      return {
        ...state,
        qualtypes: payload,
        error: null,
        loading: false,
      };
    case REQUEST_ALL_HOURTYPE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_ALL_HOURTYPE:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case REQUEST_ALL_PROFILE_COURSES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_ALL_PROFILE_COURSES:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_ALL_PROFILE_COURSES:
      return {
        ...state,
        coursesData: (payload && payload.courses) || [],
        coursesCount: payload && payload.count,
        error: null,
        loading: false,
      };
    case REQUEST_ALL_SCHOOL_FACULTIES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_ALL_SCHOOL_FACULTIES:
      return {
        ...state,
        facultyData: (payload && payload.faculities) || [],
        facultyCount: (payload && payload.count) || 0,
        error: null,
        loading: false,
      };
    case ERROR_ALL_SCHOOL_FACULTIES:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_ALL_HOURTYPE:
      return {
        ...state,
        hourtypes: payload,
        error: null,
        loading: false,
      };
    case REQUEST_ALL_MAJOR:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_ALL_MAJOR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_ALL_MAJOR:
      return {
        ...state,
        majors: payload,
        error: null,
        loading: false,
      };
    case REQUEST_ALL_COUNTRY:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_ALL_COUNTRY:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_ALL_COUNTRY:
      return {
        ...state,
        countries: payload,
        error: null,
        loading: false,
      };
    case REQUEST_FEW_SCHOOLS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_FEW_SCHOOLS:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_FEW_SCHOOLS:
      return {
        ...state,
        schools: payload,
        error: null,
        loading: false,
      };
    case REQUEST_ALL_QUALIFICATION:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ERROR_ALL_QUALIFICATION:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case SUCCESS_ALL_QUALIFICATION:
      return {
        ...state,
        error: null,
        loading: false,
        qualifications: payload,
      };
    case REQUEST_ALL_COMPANIES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_ALL_COMPANIES:
      return {
        ...state,
        error: null,
        loading: false,
        companies: payload && Array.isArray(payload) ? payload : [payload],
      };
    case REQUEST_CANDIDATE_JOBS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_CANDIDATE_JOBS:
      return {
        ...state,
        error: null,
        loading: false,
        jobs: payload,
      };
    case REQUEST_CREATE_NEW_CERTIFICATE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REQUEST_CREATE_NEW_ORGANIZATION:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REQUEST_FEW_CERTIFICATES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REQUEST_FEW_ORGANIZATION:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REQUEST_FEW_CERTTYPE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_CREATE_NEW_CERTIFICATE:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case SUCCESS_CREATE_NEW_ORGANIZATION:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case SUCCESS_FEW_CERTIFICATES:
      return {
        ...state,
        error: null,
        loading: false,
        certificates: payload,
      };
    case SUCCESS_FEW_ORGANIZATION:
      return {
        ...state,
        error: null,
        loading: false,
        organizations: payload,
      };
    case SUCCESS_FEW_CERTTYPE:
      return {
        ...state,
        error: null,
        loading: false,
        certificateTypes: payload,
      };
    case REQUEST_GET_ID_DATA:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_GET_ID_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case REQUEST_CREATE_PROFILEINFO:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REQUEST_UPDATE_PROFILEINFO:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REQUEST_DELETE_PROFILEINFO:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_CREATE_PROFILEINFO:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case SUCCESS_UPDATE_PROFILEINFO:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case SUCCESS_DELETE_PROFILEINFO:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case REQUEST_REMOVE_PROFILESKILL:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REQUEST_ADD_PROFILESKILL:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_REMOVE_PROFILESKILL:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case SUCCESS_ADD_PROFILESKILL:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case REQUEST_UPDATE_PROFILE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_UPDATE_PROFILE:
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    case REQUEST_FEW_ETHNICITIES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_FEW_ETHNICITIES:
      return {
        ...state,
        error: null,
        loading: false,
        ethnicities: payload,
      };
    case REQUEST_FEW_ACTIVITIES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_FEW_ACTIVITIES:
      return {
        ...state,
        error: null,
        loading: false,
        activity: payload,
      };
    case REQUEST_FEW_ACTIVITIESTYPES:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SUCCESS_FEW_ACTIVITIESTYPES:
      return {
        ...state,
        error: false,
        loading: false,
        activityTypes: payload,
      };
    case REQUEST_FEW_HOBBIES:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SUCCESS_FEW_HOBBIES:
      return {
        ...state,
        error: false,
        loading: false,
        hobbies: payload,
      };
    case REQUEST_FEW_HOBBYTYPES:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SUCCESS_FEW_HOBBYTYPES:
      return {
        ...state,
        error: false,
        loading: false,
        hobbytypes: payload,
      };
    case REQUEST_GET_PROFILE_VIEWS:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SUCCESS_GET_PROFILE_VIEWS:
      return {
        ...state,
        error: false,
        loading: false,
        dashboardProfileViews: payload && payload.stats,
        dashboardProfileViewsCount: payload && payload.count,
      };
    case ERROR_GET_PROFILE_VIEWS:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case REQUEST_FEW_FACULTY:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SUCCESS_FEW_FACULTY:
      return {
        ...state,
        error: false,
        loading: false,
        faculty: payload,
      };
    case REQUEST_FEW_COURSES:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SUCCESS_FEW_COURSES:
      return {
        ...state,
        error: false,
        loading: false,
        courses: payload,
      };
    case REQUEST_CREATE_PROFILE_COURSES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_CREATE_PROFILE_COURSES:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case ERROR_CREATE_PROFILE_COURSES:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default candidateDetails;
