import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Button, Loading, InlineLoading, InlineNotification } from 'carbon-components-react';

import { getEventData, retryBookingSlot } from 'actions/cronofy';

class CronofyRetry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      isLoading: true,
      isSuccess: false,
      errorMessage: 'Unknown error',
      isRetry: false,
      retryable: false,
      event: {},
    };
  }

  componentDidMount() {
    const qsObj = this.getQueryString();
    this.props.getEventData(qsObj)
      .then((res = {}) => {
        if(res && res.error) {
          this.setState({
            isLoading: false,
            isError: true,
            errorMessage: res.error.message || 'Unknown error',
          });
          return;
        }
        if (res.retryable) {
          this.setState({
            isLoading: false,
            retryable: true,
            event: res.event,
          });
        } else {
          this.setState({
            isLoading: false,
            retryable: false,
            event: res.event,
          });
        }
        console.log(res);
      })
      .catch(err => console.log('ERR:', err));
  }

  getQueryString = () => {
    const qs = window.location.search.substring(1);
    const qsArr = qs.split('&');
    const qsObj = {};
    qsArr.forEach((r) => {
      if (r.includes('result=')) {
        const codeArr = r.split('result=');
        const code = codeArr && (codeArr.length === 2) && codeArr[1];
        qsObj.authResult = code;
      }
      if (r.includes('state=')) {
        const stateArr = r.split('state=');
        const state = stateArr && (stateArr.length === 2) && stateArr[1];
        qsObj.authState = state;
      }
    });
    return qsObj;
  }

  retrySlot = () => {
    this.setState({
      isRetry: true,
    }, () => {
      const qsObj = this.getQueryString();
      console.log(qsObj);
      this.props.retryBookingSlot(qsObj)
        .then((res) => {
          if (res && res.error) {
            this.setState({
              isError: true,
              errorMessage: (res.error && res.error.message) || 'Unknown error',
            });
            console.error(res.error);
          } else {
            window.location.href = res.url;
          }
        });
    });
  }

  render() {
    const {
      isLoading, isRetry, isError, retryable, errorMessage,
      event: {
        summary,
        eventId,
        description,
        end,
      } = {},
    } = this.state;
    return (
      <div className="bx--grid text-justify">
        <div className="bx--row xpa-vh-100 justify-content-center align-items-center">
          <div className="bx--col-md-6 bx--tile">

            { isError && (<InlineNotification lowContrast kind="error" title={errorMessage} subtitle="" />)}
            { isLoading && <Loading />}
            {
              !isError && !retryable && (
              <Fragment>
                <h4 className="text-center">{summary}</h4>
                <p>
                We are sorry to see that we are unable to find a common slot that works for you.
                </p>
              </Fragment>
              )
            }
            { !isLoading && retryable && (
            <Fragment>
              <div className="bx--type-alpha text-center mb-4">How about the week after?</div>
              <p>
                We are sorry to see that we are unable to find a common slot that works for you before 
                &nbsp;<strong>{ moment(end).format('Do MMM YYYY') }</strong>.
              </p>
              <p>
                Would you be interested in scheduling the slot after
                &nbsp;<strong>{ moment(end).format('Do MMM YYYY') }</strong>?
              </p>
              <div className="text-center">
                {
                  !isRetry && (<Button onClick={this.retrySlot}>Yes, book a slot</Button>)
                }
                { isRetry && (
                  <div className="bx--btn bx--btn--primary">
                    Finding a slot ...
                    {/* <InlineLoading /> */}
                  </div>)
                }
              </div>
            </Fragment>)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
  profile: state.profile.userInfo,
});

const mapDispatchToProps = {
  getEventData,
  retryBookingSlot,
};

export default connect(mapStateToProps, mapDispatchToProps)(CronofyRetry);
