import {
  REQUEST_GET_CALENDAR_EVENTS,
  REQUEST_GET_CALENDAR_EVENT_ID,
  REQUEST_CREATE_CALENDAR_EVENT,
  REQUEST_GET_STUDENT_INTERVIEW_EVENT,
  REQUEST_CREATE_INSTITUTION_SLOT,
  REQUEST_GET_INSTITUTION_SLOTS,
  REQUEST_DELETE_INSTITUTION_SLOT,
  REQUEST_EDIT_INSTITUTION_SLOT,
  REQUEST_DELETE_CALENDAR_EVENT,
} from 'actions/actionTypes';
import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';

const getCalendarEvents = status => (dispatch) => {
  const url = `${baseUrl}/events?${status ? `status=${status}` : ''}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CALENDAR_EVENTS,
  };

  return callApi(params);
};

const getCalendarEventById = eventId => (dispatch) => {
  const url = `${baseUrl}/events/${eventId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CALENDAR_EVENT_ID,
  };

  return callApi(params);
};

const createCalendarEvent = data => (dispatch) => {
  const url = `${baseUrl}/cronofy/events`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_CREATE_CALENDAR_EVENT,
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const deleteCalendarEvent = eventId => (dispatch) => {
  if (!eventId) {
    return Promise.reject('No event to delete.');
  }
  const url = `${baseUrl}/cronofy/events/${eventId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_CALENDAR_EVENT,
  };

  return callApi(params);
};

const getStudentInterviewSlot = sid => (dispatch) => {
  let url = `${baseUrl}/events/student-interviews?_=${Date.now()}`;
  if (sid) {
    url += `&profileId=${sid}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_STUDENT_INTERVIEW_EVENT,
  };

  return callApi(params);
};

const createInstitutionSlot = data => (dispatch) => {
  const { institutionId } = data || {};
  if (!institutionId) {
    return Promise.reject('No institutionId passed');
  }
  const url = `${baseUrl}/events/institution-slots`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_CREATE_INSTITUTION_SLOT,
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const listInstitutionSlots = institutionId => (dispatch) => {
  if (!institutionId) {
    return Promise.reject('No institutionId passed');
  }
  const url = `${baseUrl}/events/institution-slots?institutionId=${institutionId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_INSTITUTION_SLOTS,
  };

  return callApi(params);
};

const updateInstitutionSlot = data => (dispatch) => {
  const { institutionId, slotId } = data || {};
  if (!institutionId || !slotId) {
    return Promise.reject('Missing data');
  }
  const url = `${baseUrl}/events/institution-slots/${slotId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    actionType: REQUEST_EDIT_INSTITUTION_SLOT,
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const deleteInstitutionSlot = slotId => (dispatch) => {
  if (!slotId) {
    return Promise.reject('No slotId passed');
  }
  const url = `${baseUrl}/events/institution-slots/${slotId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_INSTITUTION_SLOT,
  };

  return callApi(params);
};

const getAvailabilityElementToken = data => (dispatch) => {
  const url = `${baseUrl}/cronofy/elemtoken`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: 'REQUEST_ELEMENT_TOKEN',
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const rejectCalendarEvent = eventId => (dispatch) => {
  if (!eventId) {
    return Promise.reject('No event to reject.');
  }
  const url = `${baseUrl}/cronofy/events/${eventId}/reject`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    actionType: 'REQUEST_REJECT_CALENDAR_EVENT',
  };

  return callApi(params);
};

const acceptCalendarEvent = eventId => (dispatch) => {
  if (!eventId) {
    return Promise.reject('No event to accept.');
  }
  const url = `${baseUrl}/cronofy/events/${eventId}/accept`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    actionType: 'REQUEST_ACCEPT_CALENDAR_EVENT',
  };

  return callApi(params);
};

const getInstitutionFreeSlots = (slotId, connectorId) => (dispatch) => {
  const url = `${baseUrl}/cronofy/islots?slotId=${slotId}&connectorId=${connectorId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: 'REQUEST_INSTITUTION_FREE_SLOTS',
    dispatch,
    url,
    method: 'GET',
  };
  return callApi(params);
};

export {
  getCalendarEvents,
  getCalendarEventById,
  createCalendarEvent,
  getStudentInterviewSlot,
  createInstitutionSlot,
  listInstitutionSlots,
  updateInstitutionSlot,
  deleteInstitutionSlot,
  getAvailabilityElementToken,
  deleteCalendarEvent,
  rejectCalendarEvent,
  acceptCalendarEvent,
  getInstitutionFreeSlots,
};
