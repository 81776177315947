import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Checkbox, Loading,
  Accordion, AccordionItem, RadioButton, Search,
} from 'carbon-components-react';
import FilterTitle from './filterTitle';

const FilterJobs = ({
  handleOpenAcc, isOpenAccr,
  handleChangeCheckboxValues, titles,
  handleSelectAllValues, state, handleClearAllValues,
  t, handleChangeRadioBtn, handleSearchCompanyName,
  showLoader,
}) => (
  <Fragment>
    <Accordion>
      {
        titles && Array.isArray(titles)
          && titles.length > 0
          && titles.map((res) => {
            const { values } = res;
            const {
              jobSeniority: seniorityArr,
              jobTerms: termArr,
              hourTypes: typeArr,
              jobVisbility,
              jobAutoPilot,
              jobSourced,
              approvalStatus,
              finalCompsArr,
              finalCoursesArr,
            } = values || {};
            return (
              <div>
                {
                  res && (
                    <AccordionItem
                      title={res && (
                        <FilterTitle
                          titleName={res && res.titleName}
                          titleId={res && res.titleId}
                          currStatus={res && res.filterKey}
                          state={state}
                        />
                      )}
                      open={(isOpenAccr === (res && res.status))}
                      onClick={() => handleOpenAcc(res.status)}
                    >
                      <div className="d-flex ml-3">
                        <div className="mt-1 text-capitalize" onClick={() => handleSelectAllValues(values, res && res.status, res && res.filterKey)}><a href='#'>{t('selectAll')} |</a></div>
                        <div className="mt-1"><a href='#' onClick={() => handleClearAllValues(res && res.status, res && res.filterKey)}>&nbsp;{t('clear')}</a></div>
                      </div>
                      <div className="mt-2 mb-2">
                        {
                          res && res.titleId === 8 && (
                            <Search
                              light={false}
                              type="text"
                              style={{ maxWidth: 500 }}
                              className="p-0 col-lg-6"
                              labelText="Search job"
                              closeButtonLabelText=""
                              placeHolderText={`${t('search')} ${t('company')}`}
                              onChange={handleSearchCompanyName}
                              id="search pool"
                              value={state.companyName}
                            />
                          )
                        }
                        {
                          res && res.titleId === 8 && showLoader && (
                            <div className="bx--row justify-content-around">
                              <Loading withOverlay={false} />
                            </div>
                          )
                        }
                      </div>
                      <div className="ml-3">
                        <div>
                          {
                            res && res.titleId === 1 && (
                              <div className="bx--row">
                                {
                                  seniorityArr
                                    && Array.isArray(seniorityArr)
                                    && seniorityArr.length > 0
                                    && seniorityArr.map(seniority => (
                                      <div className="bx--col-lg-6">
                                        <Checkbox
                                          checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(seniority.seniorityId.toString() || 0)) : false}
                                          labelText={seniority && seniority.seniorityName}
                                          id={`seniorityName_${seniority.seniorityId}`}
                                          name={`seniorityName_${seniority.seniorityId}`}
                                          onChange={(e) => { handleChangeCheckboxValues(e, seniority.seniorityId.toString(), res.status, res.filterKey); }}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            )
                          }
                          {
                            res && res.titleId === 2 && (
                              <div className="">
                                {
                                  typeArr
                                    && Array.isArray(typeArr)
                                    && typeArr.length > 1
                                    && typeArr.map(vals => (
                                      <Checkbox
                                        checked={(state && state[`filteredArr-${res && res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes((vals.typeId.toString()) || 0)) : false}
                                        labelText={vals && vals.typeName}
                                        id={`typeName_${vals.typeId}`}
                                        name={`typeName_${vals.typeId}`}
                                        onChange={(e) => { handleChangeCheckboxValues(e, vals.typeId.toString(), res.status, res.filterKey); }}
                                      />
                                    ))
                                }
                              </div>
                            )
                          }
                          {
                            res && res.titleId === 3 && (
                              <div className="bx--row">
                                {
                                  termArr && Array.isArray(termArr)
                                    && termArr.length > 1
                                    && termArr.map(terms => (
                                      <div className="bx--col-lg-6">
                                        <Checkbox
                                          checked={(state && state[`filteredArr-${res && res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes((terms.termId.toString()) || 0)) : false}
                                          labelText={terms && terms.termName}
                                          id={`termName_${terms.termId}`}
                                          name={`termName_${terms.termId}`}
                                          onChange={(e) => { handleChangeCheckboxValues(e, terms.termId.toString(), res.status, res.filterKey); }}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            )
                          }
                          {
                            res && res.titleId === 4 && (
                              <div className="bx--row">
                                {
                                  jobVisbility && Array.isArray(jobVisbility)
                                    && jobVisbility.length > 0
                                    && jobVisbility.map(vis => (
                                      <div className="bx--col-lg-6">
                                        <Checkbox
                                          checked={(state && state[`filteredArr-${res && res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes((vis && vis.isPrivate.toString()) || 0)) : false}
                                          labelText={vis && vis.visbName}
                                          id={`visName_${vis && vis.isPrivate}`}
                                          name={`visName_${vis && vis.isPrivate}`}
                                          onChange={(e) => { handleChangeCheckboxValues(e, vis && vis.isPrivate.toString(), res.status, res.filterKey); }}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            )
                          }
                          {
                            res && res.titleId === 5 && (
                              <div className="bx--row">
                                {
                                  jobAutoPilot && Array.isArray(jobAutoPilot)
                                    && jobAutoPilot.length > 0
                                    && jobAutoPilot.map(aup => (
                                      <div className="bx--col-lg-6">
                                        <Checkbox
                                          checked={(state && state[`filteredArr-${res && res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes((aup && aup.isAutoPilot.toString()) || 0)) : false}
                                          labelText={aup && aup.autoPilName}
                                          id={`autoPilot_${aup && aup.isAutoPilot}`}
                                          name={`autoPilot_${aup && aup.isAutoPilot}`}
                                          onChange={(e) => { handleChangeCheckboxValues(e, aup && aup.isAutoPilot.toString(), res.status, res.filterKey); }}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            )
                          }
                          {
                            res && res.titleId === 6 && (
                              <div className="bx--row">
                                {
                                  jobSourced && Array.isArray(jobSourced)
                                    && jobSourced.length > 0
                                    && jobSourced.map(source => (
                                      <div className="bx--col-lg-6">
                                        <Checkbox
                                          checked={(state && state[`filteredArr-${res && res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes((source && source.aiSourced.toString()) || 0)) : false}
                                          labelText={source && source.sourcedName}
                                          id={`sourced_${source && source.aiSourced}`}
                                          name={`sourced_${source && source.aiSourced}`}
                                          onChange={(e) => { handleChangeCheckboxValues(e, source && source.aiSourced.toString(), res.status, res.filterKey); }}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            )
                          }
                          {
                            res && res.titleId === 7 && (
                              <div className="bx--row">
                                {
                                  approvalStatus
                                    && Array.isArray(approvalStatus)
                                    && approvalStatus.length > 0
                                    && approvalStatus.map(appr => (
                                      <div className="bx--col-lg-6">
                                        <RadioButton
                                          checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(appr.approvalStatus.toString() || 0)) : false}
                                          labelText={appr && appr.visbName}
                                          id={`approval_${appr.approvalStatus}`}
                                          name="approval"
                                          onChange={(e) => { handleChangeRadioBtn(appr.approvalStatus.toString(), res.status, res.filterKey); }}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            )
                          }
                          {
                            res && res.titleId === 8 && (
                              <div className="bx--row">
                                {
                                  finalCompsArr
                                    && Array.isArray(finalCompsArr)
                                    && finalCompsArr.length > 0
                                    && showLoader !== true
                                    && finalCompsArr.map(comps => (
                                      <div className="bx--col-lg-6">
                                        <Checkbox
                                          checked={(state && state[`filteredArr-${res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes(comps.companyId.toString() || 0)) : false}
                                          labelText={<div className="text-capitalize">{comps && comps.companyName}</div>}
                                          id={`comp_${comps.companyId}`}
                                          name={`comp_${comps.companyId}`}
                                          onChange={(e) => { handleChangeCheckboxValues(e, comps.companyId.toString(), res.status, res.filterKey); }}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            )
                          }
                          {
                            res && res.titleId === 9 && (
                              <div className="bx--row">
                                {
                                  finalCoursesArr && Array.isArray(finalCoursesArr)
                                    && finalCoursesArr.length > 1
                                    && finalCoursesArr.map(courses => (
                                      <div className="bx--col-lg-6">
                                        <Checkbox
                                          checked={(state && state[`filteredArr-${res && res.filterKey}`]) ? (state[`filteredArr-${res && res.filterKey}`].includes((courses.courseId.toString()) || 0)) : false}
                                          labelText={<div className="text-capitalize">{courses && courses.courseName}</div>}
                                          id={`con_${courses.courseId}`}
                                          name={`con_${courses.courseId}`}
                                          onChange={(e) => { handleChangeCheckboxValues(e, courses.courseId.toString(), res.status, res.filterKey); }}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </AccordionItem>
                  )
                }
              </div>
            );
          })
      }
    </Accordion>
  </Fragment>
);

export default (withTranslation()(FilterJobs));
