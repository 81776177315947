import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Cell, Legend,
  PieChart, Pie, ResponsiveContainer,
} from 'recharts';

const PieChartAnalytics = (props) => {
  const {
    data, t, value,
  } = props;
  const cityColors = ['#ffa600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c'];
  return (
    <Fragment>
      <div className="bx--col-lg-6">
        <div className="bx--type-zeta text-center text-capitalize mb-2">
          {t(`${value}`)}
        </div>
        {
          data && Array.isArray(data)
          && data.length > 0
          ? (
            <ResponsiveContainer width={630} height={250}>
              <PieChart>
                <Pie legendType="square" data={data} labelLine label dataKey="value" cx="50%" cy="50%" outerRadius={80}>
                  {
                    data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={cityColors[index]} />
                    ))
                  }
                </Pie>
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <div className="text-dark font-weight-bold text-center">{t('noAnalyticsMsg')}</div>
          )
        }
      </div>
    </Fragment>
  );
};

export default (withTranslation()(PieChartAnalytics));
