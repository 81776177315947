import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { TooltipIcon, Pagination, Tile } from 'carbon-components-react';

import BackRow from 'components/common/BackRow/BackRow';
import JobDetailsModal from 'containers/roboroy/JobList/JobDetailsModal';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import CustomCard from 'components/common/CustomCard';
import Avatar from 'components/common/Avatar';
import moment from 'moment';

import { fetchCandidateJobs, setInviteStatus } from 'actions/candidate/jobs';

import WorkflowAction from 'components/roboroy/WorkflowModule/workflowAction';
import WorkflowStatusUpdateModal from 'components/roboroy/WorkflowModule/workflowStatusUpdate';
import SetMixPanel from 'components/common/GetCurrentPath';
import './JobList.css';
import CandidateJobsList from 'containers/roboroy/candidate/dashboard/components/CandidateJobsList';

const track = {
  ROBOROY_DASHBOARD_APPLICANT_CLICKED: 'ROBOROY_DASHBOARD_APPLICANT_CLICKED',
  ROBOROY_DASHBOARD_APPLICANTJOB_CLICKED: 'ROBOROY_DASHBOARD_APPLICANTJOB_CLICKED',
};


const NoJobMessageBox = ({ croute, t }) => (
  <div className="card-body text-center">
    <div className="empty">
      <p className="empty-title h5">
        {croute === 'invites' ? t('noJobInvites') : null}
        {croute === 'invites-accepted' ? t('noAcceptedJobs') : null}
        {croute === 'invites-rejected' ? t('noRejectedJobs') : null}
        {croute === 'invites-pending' ? t('noPendingJobs') : null}
        {croute === 'applied' ? t('noAppliedJobs') : null}
        {croute === 'accepted' ? t('notAcceptedJobs') : null}
        {croute === 'rejected' ? t('noInvitesRejected') : null}
        {croute === 'screening' ? t('noInvitesPending') : null}
        {croute === 'videointerview' ? t('noInProgressVideoInterviews') : null}
        {croute === 'onsiteinterview-inprogress' ? t('noInProgressOnsiteInterviews') : null}
        {croute === 'onsiteinterview-completed' ? t('noCompletedOnsiteInterviews') : null}
        {croute === 'phoneinterview' ? t('noInProgressPhoneInterviews') : null}
        {croute === 'writtenassessment' ? t('noInProgressAssessments') : null}
        {croute === 'offer-invites' ? t('noOffers') : null}
        {croute === 'offer-inprogress' ? t('noInProgressOffers') : null}
        {croute === 'offer-completed' ? t('noCompletedOffers') : null}
      </p>
    </div>
  </div>
);

const CardContent = ({ details, push, status, renderApplInvitation, handleWorkflowStatusUpdate, handleWorkflowSetup }) => {
  const { jobId, appId, job: { company: { companyName } = {} } = {} } = details;
  return (
    <div className="text-center ">
      <div className="d-flex">
        <p href="javascript:void(0)" onClick={() => push(`/app/campus/job/${jobId}?appId=${appId}`)} className="text-left">
          <small>Job ID: {jobId}</small>
        </p>
        <p href="javascript:void(0)" onClick={() => push(`/app/campus/job/${appId}`)} className="text-left ml-2">
          <small>Application ID: {appId}</small>
        </p>
      </div>
      <p className="btn btn-info btn-pill  mr-2 d-block">
        <small>{companyName}</small>
      </p>
      <p className="btn btn-outline-dark btn-pill  mr-2 d-block">
        <span>
          {status === 'shortlisted' ? (
            <span className="text-success">Shortlisted</span>
          ) : status === 'rejected' ? (
            <span className="text-danger">Rejected</span>
          ) : (
            <span className="text-dark">Screening</span>
          )}
          <TooltipIcon
            tooltipText="Download Resume"
            className="d-inline-block ml-1"
            onClick={(e) => {
              window.location.href = `/view/${details &&
                details.job &&
                details.job.personDocuments &&
                details.job.personDocuments[0] &&
                details.job.personDocuments[0].cvLink}/cv`;
            }}
          >
            <svg width="12" height="16" viewBox="0 0 12 16">
              <path d="M10.581 3.996L8.014 1.429l.026 2.559 2.541.008zM7.011 1H1v14h10V4.997l-2.963-.01a1 1 0 0 1-.997-.99L7.01 1zM1 0h7l4 4v11a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z" />
              <path d="M3 9h6v1H3zm0 3h6v1H3z" />
            </svg>
          </TooltipIcon>
        </span>
      </p>
      <div className="Email Invite">
        <div className="column pt-0">
          <div className="clearfix text-center">
            {details.inviteaccept === null && details.invitesent === null ? <span>-</span> : ''}
            {details.inviteaccept === null && details.invitesent === true && renderApplInvitation}
            {details.inviteaccept === false ? (
              <div>
                <div className="badge badge-outline-danger">Rejected</div>
              </div>
            ) : null}
            {details.inviteaccept === true ? (
              <div>
                <div className="badge badge-outline-success">Accepted</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <div className="workflowaction">
          <WorkflowAction
            isCandidateFlow
            application={details}
            jobDetails={details && details.job}
            handleWorkflowStatusUpdate={handleWorkflowStatusUpdate}
            handleWorkflowSetup={handleWorkflowSetup}
          />
        </div>
      </div>
    </div>
  );
};

const columnWithFilter = [
  {
    tid: 'app-id',
    title: 'Application ID',
    className: 'text-center',
    name: 'appId',
    propName: 'appId'
  },
  {
    tid: 'job-id',
    title: 'Job ID',
    className: 'text-center',
    name: 'jobId',
    propName: 'jobId'
  },
  {
    tid: 'job-title',
    title: 'Job Title',
    className: 'text-center',
    name: 'jobName',
    propName: 'job.jobname.nameName'
  },
  {
    tid: 'company',
    title: 'Company',
    className: 'text-center',
    name: 'companyName',
    propName: 'job.company.companyName'
  },
  {
    tid: 'recruiterStatus',
    title: 'Resume Status',
    className: 'text-center',
    name: 'companyName',
    propName: 'shortlisted'
  },
  {
    tid: 'invite',
    title: 'Email Invite',
    className: 'text-center',
    name: 'companyName',
    propName: 'inviteaccept'
  },
  {
    tid: 'interviewstatus',
    title: 'Interview Status',
    className: 'text-center',
    name: 'companyName',
    propName: 'workflow'
  }
  ];

  const getRouteParam = (props) => {
  const { match: { params: { status } = {} } = {} } = props;
  // let qparam = { shortlisted: null };
  // switch (status) {
  //   case 'shortlisted':
  //     qparam = { shortlisted: true };
  //     break;
  //   case 'rejected':
  //     qparam = { shortlisted: false };
  //     break;
  //   default:
  //     qparam = { shortlisted: 'open' };
  // }
  return { status };
  };

  const getBreadCrumb = (props) => {
  const { match: { params: { status } = {} } = {}, t } = props;
  let qparam = '';
  switch (status) {
    case 'invites':
      qparam = `${t('jobInvites')}`;
      break;
    case 'invites-accepted':
      qparam = `${t('jobInvites')} ${t('accepted')}`;
      break;
    case 'invites-rejected':
      qparam = `${t('jobInvites')} ${t('rejected')}`;
      break;
    case 'invites-pending':
      qparam = `${t('jobInvites')} ${t('pending')}`;
      break;
    case 'applied':
      qparam = `${t('applied')}`;
      break;
    case 'shortlisted':
      qparam = `${t('shortlisted')}`;
      break;
    case 'screening':
      qparam = 'Pending Employer';
      break;
    case 'rejected':
      qparam = `${t('rejected')}`;
      break;
    case 'videointerview':
      qparam = `${t('videoInterviews')}`;
      break;
    case 'phoneinterview':
      qparam = `${t('phoneInterviewsTab')}`;
      break;
    case 'writtenassessment':
      qparam = 'Written Assessments';
      break;
    case 'invite-inprogress':
      qparam = `${t('invitesInProgress')}`;
      break;
    case 'offer-inprogress':
      qparam = `${t('offers')} ${t('offersInProgressTab')}`;
      break;
    case 'offer-invites':
      qparam = `${t('offer')} ${t('invites')}`;
      break;
    case 'offer-completed':
      qparam = `${t('offer')} ${t('completed')}`;
      break;
    case 'inprogress':
      qparam = `${t('offersInProgressTab')}`;
      break;
    case 'onsiteinterview-inprogress':
      qparam = `${t('onsiteInprogress')}`;
      break;
    case 'onsiteinterview-completed':
      qparam = `${t('onsiteCompleted')}`;
      break;
    default:
      qparam = '';
  }
  return qparam;
  };

  class CandidateJobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      arrowFlag: false,
      sortByAscending: true,
      activeModal: false,
      sortedJobs: [],
      totalCount: 0,
      activePage: 0,
      ascDesc: '',
      activeJob: {},
      sortParam: '',
      activeFilter: null,
      qsValuesForFilter: [],
      qsValuesForSort: [],
      itemsPerPage: 30,
      activeColumn: '',
      inviteStatus: '',
      offerConsentAccept: '',
      activeWorkflowModal: false,
      activateWorkflowStatusUpdateModal: false,
      paths: [
        { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
        { id: 'candidate-jobs', name: getBreadCrumb(props), href: '' }
      ]
    };
  }

  componentDidMount() {
    const qparam = getRouteParam(this.props);
    this.setState(
      {
        itemsPerPage: 10,
        activePage: 0,
        sortByAscending: false,
      },
      () => {
        const params = {
          qsValueLabel: 'qsValuesForSort',
          data: columnWithFilter[0],
          index: 0,
        };
        const { itemsPerPage } = this.state;

        const jb = this.props.fetchCandidateJobs({ itemsPerPage, skip: 0, count: false, ...qparam }, 'REQUEST_CANDIDATE_JOBS');
        jb.then((res = {}) => {
          this.setState({
            sortedJobs: (res.candash && res.candash.applicantions) || [],
          });
        });
      }
    );
  }

  callSortAndFilter = (params) => {
    const { sortByAscending, activePage } = this.state;
    this.setState({ ascDesc: sortByAscending ? 'ASC' : 'DESC', activePage: 0 }, () => {
      this.filterAndSortOptionChange({ ...params });
    });
  };

  handleWorkflowSetup = (application, appId) => {
    // alert("setting up interview");
    this.activateWorkflowModal(application, appId);
  };

  handleWorkflowStatusUpdate = (application, appId, workflow) => {
    this.activateWorkflowStatusUpdateModal(application, appId, workflow);
  };

  activateWorkflowModal = (application, appId) => {
    this.setState({
      activeWorkflowModal: true,
      selectedAppIdForWorkflow: appId,
      selectedApplicationForWorkflow: application,
    });
  };

  activateWorkflowStatusUpdateModal = (application, appId, workflow) => {
    this.setState({
      activateWorkflowStatusUpdateModal: true,
      selectedAppIdForWorkflow: appId,
      selectedApplicationForWorkflow: application,
      selectedWorkflow: workflow,
    });
  };

  dismissWorkflowStatusUpdateModal = () => {
    this.setState({
      activateWorkflowStatusUpdateModal: false,
    });
  };

  dismissWorkflowModal = () => {
    this.setState({
      activeWorkflowModal: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activateModal = (activeJob) => {
    // this.props.changeModalJob(job);
    this.setState({
      activeJob,
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  handlePaginationChange = (data) => {
    const { match: { params: { status } = {} } = {} } = this.props;
    const activePage = data.page - 1 || 0;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage }, () => {
      this.props.fetchCandidateJobs({
        itemsPerPage,
        activePage: activePage + 1,
        filterOptions: this.state.sortParam,
        status,
        count: false
      }, 'REQUEST_CANDIDATE_JOBS');
    });
  };

  columnSortJobList = ({ propName }) => {
    const { ascDesc, currentSortProp } = this.state;
    const qparam = getRouteParam(this.props);
    let sortVal = 'DESC';
    if (currentSortProp && currentSortProp === propName) {
      sortVal = ascDesc && ascDesc === 'DESC' ? 'ASC' : 'DESC';
    } else {
      sortVal = 'DESC';
    }

    const qs = ascDesc => `filter[order][0]=${propName}%20${ascDesc}`;
    this.setState(
      {
        ascDesc: sortVal,
        sortParam: qs(sortVal),
        activePage: 1,
        currentSortProp: propName
      },
      () => {
        this.props.fetchCandidateJobs({
          itemsPerPage: this.state.itemsPerPage,
          activePage: this.state.activePage,
          filterOptions: this.state.sortParam,
          searchKey: this.state.keyword,
          ...qparam,
          count: false,
        }, 'REQUEST_CANDIDATE_JOBS');
      }
    );
  };

  renderApplInvitation = applId => (
    <div className="btn-group mt-0">
      <button
        type="button"
        className="job__details-button-height pt-0 pb-0 bx--btn bx--btn--primary btn-sm btn-success"
        onClick={() => this.handleInvitationAction(applId, true)}
      >
        Accept
      </button>
      <button
        type="button"
        className="job__details-button-height pt-0 pb-0 bx--btn bx--btn--primary btn-sm btn-danger ml-2"
        onClick={() => this.handleInvitationAction(applId, false)}
      >
        Reject
      </button>
    </div>
  );

  renderOfferInvitation = appId => (
    <div className="btn-group">
      <button
        type="button"
        className=" job__details-button-height bx--btn bx--btn--primary btn-sm btn-success"
        onClick={() => this.handleOfferAcceptAction(appId, true)}
      >
        Accept
      </button>
      <button
        type="button"
        className="job__details-button-height bx--btn bx--btn--primary btn-sm btn-danger ml-2"
        onClick={() => this.handleOfferAcceptAction(appId, false)}
      >
        Reject
      </button>
    </div>
  )

  getApplications = () => {
    const { canjobs: { applications = [], count = 0 } = {} } = this.props;
    return {
      sortedJobs: applications,
      count
    };
  };

  handleInvitationAction = (appId, status) => {
    const data = { appId, inviteaccept: status };
    const currenstatus = this.props.setInviteStatus(data);
    currenstatus.then((res, err) => {
      const qparam = getRouteParam(this.props);
      this.props.fetchCandidateJobs({ activePage: this.state.actievPage, count: false, ...qparam }, 'REQUEST_CANDIDATE_JOBS');
      if (err) return;
      this.setState({
        inviteStatus: status,
      });
    });
  };

  handleOfferAcceptAction = (appId, status) => {
    const data = {
      appId,
      offerConsentAccept: status,
    };
    this.props.setInviteStatus(data).then((res, err) => {
      const qparam = getRouteParam(this.props);
      this.props.fetchCandidateJobs({ activePage: this.state.actievPage, count: false, ...qparam }, 'REQUEST_CANDIDATE_JOBS');
      if (err) return;
      this.setState({
        offerConsentAccept: status,
      });
    });
  }

  render() {
    const { itemsPerPage } = this.state;
    const {
      history: { push }, match: { params: { status } = {} } = {}, t, renderApplInvitation,
      renderOfferInvitation,
    } = this.props;
    const { sortedJobs, count } = this.getApplications() || [];
    const updatedPaths = [
      { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
      { id: 'candidate-jobs', name: getBreadCrumb(this.props), href: '' },
    ];
    return (
      <div className="joblist">
        <div className="joblist__container xp-c-container xp-c-background--light ">
          <BackRow paths={updatedPaths} />
          <div className="mt-4">
            <h4 className="bx--type-gamma font-weight-bold text-capitalize">{getBreadCrumb(this.props)}</h4>
            {
              sortedJobs && Array.isArray(sortedJobs)
              && sortedJobs.length > 0
              && sortedJobs.map((job, idx) => {
                const {
                  appId,
                  jobId,
                  job: {
                    openDate,
                    jobname: {
                      nameName,
                    } = {},
                    company: {
                      companyName,
                    } = {},
                    country: {
                      countryFull,
                    } = {},
                  } = {},
                } = job || {};
                return (
                  <Fragment>
                    <div className={`bx--row m-0 ${((sortedJobs.length - 1) === idx) && 'pb-2'}`}>
                      <div className="ml-3 mt-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                      </div>
                      <div className="text-capitalize ml-1 bx--col-lg-3 bx--col-md-11 pl-0 bx--col-sm-4  bx--col-xs-10 mt-2">
                        <a href={`/app/campus/candidate/applications/${appId}/offers`} className="font-weight-bold" onClick={() => SetMixPanel(track.ROBOROY_DASHBOARD_JOBS_CLICKED)}>{nameName}</a>
                        <span className="ml-1">({`${jobId}`})</span>
                      </div>
                      <div className="bx--col-lg-2 bx--col-md-12 bx--col-xs-12 bx--col-sm-12ld mt-2">
                        <span className="bx--tag bx--tag--blue">{t('internship')}</span>
                      </div>
                      <div className="ml-2 mt-2 font-weight-bold text-dark bx--col-lg-2 bx--col-md-12 bx--col-xs-12 bx--col-sm-12 text-capitalize">
                        {companyName}
                      </div>
                      <div className="ml-2 mt-1 text-dark bx--col-lg-1 bx--col-md-12 bx--col-xs-12 bx--col-sm-12 text-capitalize">
                        <div className="d-flex">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 16, height: 16 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin">
                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                              <circle cx="12" cy="10" r="3" />
                            </svg>
                          </div>
                          <div className="ml-1 mt-1">{countryFull}</div>
                        </div>
                      </div>
                      <div className="bx--col-lg-2 bx--col-md-1 ml-2 bx--col-sm-12 mt-2">
                        <div className="emailInvite">
                          <div className="column pt-0">
                            <div className="clearfix">
                              {
                                (status
                                  && ((status === 'offer-invites') || (status === 'offer-inprogress') || (status === 'offer-completed')))
                                  && (
                                    <div>
                                      {
                                        job
                                        && job.offerConsentSent === null
                                        && job.offerConsentAccept === null ? <span>-</span> : ''
                                      }
                                      {/* {
                                        job
                                          && job.offerConsentSent === true
                                          && job.offerConsentAccept === null
                                          && this.renderOfferInvitation(job.appId)
                                      } */}
                                      {/* {job.offerConsentAccept === false ? (
                                        <div>
                                          <div className="btn btn-sm btn-outline-danger">{t('invitesRejectedTab')}</div>
                                        </div>
                                      ) : null}
                                      {job.offerConsentAccept === true ? (
                                        <div>
                                          <div className="btn btn-sm btn-outline-success">{t('invitesAcceptedTab')}</div>
                                        </div>
                                      ) : null} */}
                                    </div>
                                  )
                                  // : (
                                  //   <div>
                                  //     {job && job.inviteaccept === null && job.invitesent === null ? <span>-</span> : ''}
                                  //     {/* {job && job.inviteaccept === null
                                  //       && job.invitesent === true
                                  //       && this.renderApplInvitation(job.appId)} */}
                                  //     {job && job.inviteaccept === false ? (
                                  //       <div>
                                  //         <div className="btn btn-sm btn-outline-danger">{t('invitesRejectedTab')}</div>
                                  //       </div>
                                  //     ) : null}
                                  //     {job && job.inviteaccept === true ? (
                                  //       <div>
                                  //         <div className="btn btn-sm btn-outline-success">{t('invitesAcceptedTab')}</div>
                                  //       </div>
                                  //     ) : null}
                                  //   </div>
                                  // )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 ml-3 ml-lg-0 text-dark text-right">
                        {moment(openDate).fromNow()}
                      </div>
                    </div>
                    {
                      sortedJobs && !((sortedJobs.length - 1) === idx) && <hr className="mb-2 mt-1" />
                    }
                  </Fragment>
                );
              })
            }
            {
              count <= 0 && <NoJobMessageBox croute={status} t={t} />
            }
          </div>
          {sortedJobs &&
          sortedJobs.length > 0 &&
          (
            <div className="">
              <Pagination
                onChange={this.handlePaginationChange}
                pageSizes={[ 10, 20, 30, 40, 50 ]}
                totalItems={count}
              />
            </div>
          )}
        </div>
        <JDProcessOverlay show={this.props && this.props.loading} message={t('processing')} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.candidateJobs.loading,
  error: state.candidateJobs.error,
  canjobs: state.candidateJobs,
});

const mapDispatchToProps = {
  fetchCandidateJobs,
  setInviteStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CandidateJobList));
