import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';

import './FillCircle.css';

const propTypes = {
  fill: PropTypes.number,
  fillColor: PropTypes.string,
  textColor: PropTypes.string,
};

const defaultProps = {
  fill: 0,
  fillColor: '#53777A',
  textColor: '#53777A',
};

const FillCircle = (props) => {
  const { fill, fillColor, textColor, isPercent } = props;
  let pClass = `xpa-pg-circle p${Number(fill)}`;
  if (Number(fill) > 50) {
    pClass = `xpa-pg-circle over50 p${fill}`;
  }
  return (
    <div className={pClass}>
      <span style={{ color: textColor }}>{fill}{isPercent === true ? '%' : null}</span>
      <div className="left-half-clipper">
        <div className="first50-bar" style={{ backgroundColor: fillColor }} />
        <div className="value-bar" style={{ borderColor: fillColor }} />
      </div>
    </div>
  );
};

FillCircle.propTypes = propTypes;
FillCircle.defaultProps = defaultProps;

export default FillCircle;
