import {
  REQUEST_CREATE_APPL_NOTES,
  SUCCESS_CREATE_APPL_NOTES,
  ERROR_CREATE_APPL_NOTES,
  REQUEST_GET_APPL_NOTES,
  SUCCESS_GET_APPL_NOTES,
  ERROR_GET_APPL_NOTES,
  REQUEST_EDIT_APPL_NOTES,
  SUCCESS_EDIT_APPL_NOTES,
  ERROR_EDIT_APPL_NOTES,
  REQUEST_DELETE_APPL_NOTES,
  SUCCESS_DELETE_APPL_NOTES,
  ERROR_DELETE_APPL_NOTES,
} from 'actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  notes: [],
};

const ApplicantNotes = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_CREATE_APPL_NOTES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_APPL_NOTES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_APPL_NOTES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_APPL_NOTES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_APPL_NOTES:
      return {
        ...state,
        notes: payload && payload.notes,
        loading: false,
        error: null,
      };
    case ERROR_GET_APPL_NOTES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_EDIT_APPL_NOTES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_EDIT_APPL_NOTES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_EDIT_APPL_NOTES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_APPL_NOTES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_APPL_NOTES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_APPL_NOTES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default ApplicantNotes;
