import { withTranslation } from 'react-i18next';
import React from 'react';
import ColorCode from 'utils/ColorCodes';
import classNames from 'classnames';

const ColoredCircle = ({
  value, getColor, style, className,normalizedValue,showValue=true,
  noStyle,
}) => {
  let color = ColorCode[getColor](value);
  if (normalizedValue && (value && (value !== 'N/A'))) {
    color = ColorCode[getColor](normalizedValue);
  }

  return (
    <div className={classNames({'d-flex': !noStyle, 'justify-content-center': !noStyle })}>
      <div
        className={`${className} ${value}`}
        style={{
          borderRadius: '50%',
          width: '35px',
          height: '35px',
          color: 'white',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          fontSize: '12px',
          background: color.withWhiteText,
          ...style,
        }}
      >
        {showValue?value:''}
      </div>
    </div>
  );
};

ColoredCircle.defaultProps = {
  getColor: 'getLoyaltyColor',
};

export default ColoredCircle;
