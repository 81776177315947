import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import moment from 'moment';
import SetMixPanel from 'components/common/GetCurrentPath';

const track = {
  ROBOROY_DASHBOARD_JOBS_CLICKED: 'ROBOROY_DASHBOARD_JOBS_CLICKED',
};

const JobsListView = (props) => {
  const {
    jobs,
    jobLoading,
    ctypeId,
    t,
    push,
  } = props;
  return (
    <Fragment>
      {
        jobs && Array.isArray(jobs)
          && jobs.length > 0
          && jobLoading === false
          && jobs.map((jobData, idx) => {
            const {
              jobId,
              jobname: {
                nameName,
              } = {},
              company: {
                companyName,
                displayCompanyName
              } = {},
              institution = {},
              country: {
                countryFull,
              } = {},
              openDate,
              dashboard: {
                totalApplications,
                numOfShortlistedApplications,
                numOfRejectedApplications,
              } = {},
            } = jobData || {};
            return (
              <Fragment>
                <div className="bx--row align-items-center">
                  <div onClick={() => { push && push(`/app/campus/job/${jobId}`) }} className="ml-4 bx--col-lg-2 bx--col-md-7 pl-0 bx--col-sm-4  bx--col-xs-10 mt-1">
                    <div className=" xpa-link xpa-name-overflow font-weight-bold text-capitalize">
                      {nameName} <span className="text-dark font-weight-normal">({jobId})</span>
                    </div>
                    <small className="mt-1  text-dark text-uppercase xpa-name-overflow xpa-name-td-width-fix">
                      {displayCompanyName}
                    </small>
                  </div>
                  <div className="bx--col-lg-2 ml-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 16, height: 16 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin">
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                          <circle cx="12" cy="10" r="3" />
                        </svg>
                      </div>
                      <div className="ml-1 mt-1">{countryFull}</div>
                    </div>
                  </div>
                  <div className="bx--col-lg-6">
                    <div className="d-flex justify-content-around">
                      <div>
                        <div className="text-center">
                        <b className="text-dark"><span onClick={() => { push && push(`/app/campus/jobs/${jobId}/applied`) }} style={{ fontSize: 'large' }} className="xpa-link font-weight-bold">{totalApplications}</span></b>
                        </div>
                        <div className="text-dark">{t('applications')}</div>
                      </div>
                      <div className="">
                          <div className="text-center"><b className="text-dark"><span onClick={() => { push && push(`/app/campus/jobs/${jobId}/shortlisted`) }} style={{ fontSize: 'large' }} className="xpa-link font-weight-bold">{numOfShortlistedApplications}</span></b></div>
                         <div className="text-dark">{t('shortlisted')}</div> </div>
                      <div className="">
                          <div className="text-center"><b className="text-dark"><span onClick={() => { push && push(`/app/campus/jobs/${jobId}/rejected`) }} style={{ fontSize: 'large' }} className="xpa-link font-weight-bold">{numOfRejectedApplications}</span></b></div>
                         <div className="text-dark">{t('rejected')}</div> </div>
                    </div>
                  </div>
                  <div className="mt-1 ml-2 text-dark text-right">
                    {moment(openDate).format('DD/MM/YY, hh:mma')}
                  </div>
                </div>
                {
                  !(((jobs.length - 1) == idx)) && <hr className="mb-2 mt-1" />
                }
              </Fragment>
            );
          })
      }
    </Fragment>
  );
};

export default (withTranslation()(JobsListView));
