import React, { Component, Fragment } from 'react';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import languageChange from 'actions/languageChange';
import {
  Switcher,
  SwitcherItem,
} from 'carbon-components-react/lib/components/UIShell';
import './languageChange.css';

class LanguageChange extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const localStorageData = localStorage.getItem('language');
    const lang = (localStorageData && localStorageData.toUpperCase()) || 'EN';
    const language = this.props.languages.filter(one => one.label === (lang === null ? 'EN' : lang))[0];
    if (language && language !== null) this.props.languageChange(language);
    this.props.i18n.changeLanguage(lang === null ? 'EN' : lang);
  }

  onLanguageChange = (id, value) => {
    this.props.languageChange(value);
    localStorage.setItem('language', value.label);
    this.props.i18n.changeLanguage(value.label);
  }

  render() {
    const { languages = [], showLanguageModal } = this.props;
    return (
      <Fragment>
        {
          showLanguageModal === true && (
            <Switcher>
              {
                languages && Array.isArray(languages)
                  && languages.length > 0
                  && languages.map((res) => {
                    const {
                      label,
                    } = res || {};
                    return (
                      <SwitcherItem onClick={() => this.onLanguageChange(null, res)} className="text-center">
                        {label}
                      </SwitcherItem>
                    );
                  })
              }
            </Switcher>
          )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  language: state.ProductLanguge.language,
  languages: state.ProductLanguge.languages,
});

const mapDispatchToProps = {
  languageChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LanguageChange));