import React, { Fragment, Component, useRef,  useState} from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import classNames from 'classnames';
import PublicHeader from 'containers/common/publicHeader';
import { withTranslation } from 'react-i18next';
import { logout, logoutUser, sessionLogoutUser } from 'actions';
import IdleTimer from 'react-idle-timer';

const  AutoLogout = ({logoutUser, history}) => {
  const idleTimerRef = useRef(null) 

  const onIdle = () => {
    console.log('User idle')
    sessionLogoutUser()
  } 
  return (
    <div>
      <IdleTimer
        ref ={idleTimerRef}
        timeout = {1000 * 60 * 120}
        onIdle ={onIdle}
      >
      </IdleTimer>
    </div>
  )
}

export default AutoLogout
