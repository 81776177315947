import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Tooltip } from 'carbon-components-react';

export const AISourcedEmailMissingWarning = () => (
  <div className="mt-1 xpa-cand-info--card-actions pb-0 mr-4">        
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#dc3545"><path d="M7.25 6h1.5v5h-1.5z"></path><path d="M.75 16a.75.75 0 0 1-.67-1.085L7.33.415a.75.75 0 0 1 1.34 0l7.25 14.5A.75.75 0 0 1 15.25 16H.75zm1.214-1.5h12.072L8 2.427 1.964 14.5z"></path><path d="M7 12.75a1 1 0 1 1 2 0 1 1 0 1 1-2 0"></path></svg>            
    <span className="text-danger ml-1">Application is missing email address.</span>
    <span className="position-relative ml-1" style={{top:"0.25rem"}}>
      <AISourcedWithNoEmail/> 
    </span>                    
  </div>
);

export const FitForFinlandHelp = () => (
  <Tooltip clickToOpen={true} triggerText="" tabIndex={0}
      icon={{
        "id": "icon--info--outline",
        "name": "icon--info--outline",
        "tags": "icon--info--outline",
        "styles": "",
        "viewBox": "0 0 16 16",
        "width": "16",
        "height": "16",
        "svgData": {
          "circles": "",
          "ellipses": "",
          "paths": [
            {
              "d": "M8 14.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z",
              "fill-rule": "nonzero"
            },
            {
              "fill-rule": "nonzero",
              "d": "M9 13H7V7h2z"
            },
            {
              "d": "M7 4a1 1 0 1 1 2 0 1 1 0 1 1-2 0"
            }
          ],
          "polygons": "",
          "polylines": "",
          "rects": ""
        }
      }}
    >
      <p className="bx--tooltip__label">
        <strong>About Fit for Finland Score</strong>
      </p>
      <p>
        The Fit for Finland score is calculated with the help on an AI tool that matches your profile to developers who are already working and living in Finland. It takes into account both cultural factors and technical requirements voiced by recruiting companies in Finland. Your score ranges from 0 to 100 and we consider matches above 50 already a nice fit!
      </p>
  </Tooltip>     
  );


export const AISourcedEmailMaskHelpInfo = () => (
  <Tooltip clickToOpen={false} triggerText="" tabIndex={0}
      icon={{
        "id": "icon--info--outline",
        "name": "icon--info--outline",
        "tags": "icon--info--outline",
        "styles": "",
        "viewBox": "0 0 16 16",
        "width": "16",
        "height": "16",
        "svgData": {
          "circles": "",
          "ellipses": "",
          "paths": [
            {
              "d": "M8 14.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z",
              "fill-rule": "nonzero"
            },
            {
              "fill-rule": "nonzero",
              "d": "M9 13H7V7h2z"
            },
            {
              "d": "M7 4a1 1 0 1 1 2 0 1 1 0 1 1-2 0"
            }
          ],
          "polygons": "",
          "polylines": "",
          "rects": ""
        }
      }}
    >
      <p className="bx--tooltip__label">
        <strong>Email ID masked</strong>
      </p>
      <p>
        For candidates sourced through AI sourcing, inorder to see the candidate's email, users would need to shortlist the applicant for the job. 
      </p>
  </Tooltip>     
  );

export const AISourcedHelpInfo = () => (
<Tooltip clickToOpen={false} triggerText="" tabIndex={0}
    icon={{
      "id": "icon--info--outline",
      "name": "icon--info--outline",
      "tags": "icon--info--outline",
      "styles": "",
      "viewBox": "0 0 16 16",
      "width": "16",
      "height": "16",
      "svgData": {
        "circles": "",
        "ellipses": "",
        "paths": [
          {
            "d": "M8 14.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z",
            "fill-rule": "nonzero"
          },
          {
            "fill-rule": "nonzero",
            "d": "M9 13H7V7h2z"
          },
          {
            "d": "M7 4a1 1 0 1 1 2 0 1 1 0 1 1-2 0"
          }
        ],
        "polygons": "",
        "polylines": "",
        "rects": ""
      }
    }}
  >
    <p className="bx--tooltip__label">
      <strong>AI Sourced Candidate</strong>
    </p>
    <p>
      This candidate was sourced using X0PA's AI sourcing algorithms as a potential fit for this particular job requisition. They have not directly applied for this job and hence we advise to first shortlist the candidate and send <strong>Introduction email</strong> providing information about the job.
    </p>
</Tooltip>     
);
export const AISourcedWithNoEmail = () => (
<Tooltip clickToOpen={false} triggerText="" tabIndex={0}
    icon={{
      "id": "icon--info--outline",
      "name": "icon--info--outline",
      "tags": "icon--info--outline",
      "styles": "",
      "viewBox": "0 0 16 16",
      "width": "16",
      "height": "16",
      "svgData": {
        "circles": "",
        "ellipses": "",
        "paths": [
          {
            "d": "M8 14.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z",
            "fill-rule": "nonzero"
          },
          {
            "fill-rule": "nonzero",
            "d": "M9 13H7V7h2z"
          },
          {
            "d": "M7 4a1 1 0 1 1 2 0 1 1 0 1 1-2 0"
          }
        ],
        "polygons": "",
        "polylines": "",
        "rects": ""
      }
    }}          >
  <p className="bx--tooltip__label">
    <strong>Email not found</strong>
  </p>
  <p>
    We could not get the email address information for this candidate. If you already know the email address of this candidate, then add the email address in "Candidate Profile" section.
  </p>
</Tooltip>    
);
