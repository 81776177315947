import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal, FileUploaderButton, Button,
} from 'carbon-components-react';
import BackRow from 'components/common/BackRow/BackRow';
import {
  uploadStudentsData,
} from 'actions/BrowseStudents';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import Avatar from 'components/common/Avatar';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import StudentCsv from 'assets/files/RPStudentTemplate.xls';

class ImportStudents extends Component {
  paths = [
    { id: 'student', name: 'Grad Hiring', href: '/app/campus/browse/students' },
    { id: 'student-1', name: 'Import Students', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      isOpenUploadModal: false,
      showSuccessMsg: false,
      uploadedStudents: [],
    };
  }

  activeUplaodModal = () => {
    this.setState({
      isOpenUploadModal: true,
    });
  }

  dismissUplaodModal = () => {
    this.setState({
      isOpenUploadModal: false,
    });
  }

  handleSubmitUploadModal = (element) => {
    const { files } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    let { index } = this.state;
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('excel', fileData[0]);
    this.props.uploadStudentsData(currFile).then((res) => {
      if (res && !res.error) {
        this.dismissUplaodModal();
        let otherFacultyStudentsData = [];
        let failedToImportStudentsData = [];
        const {
          existingStudents,
          otherFacultyStudents,
          failedToImportList,
        } = res || {};
        if (failedToImportList && Array.isArray(failedToImportList) && failedToImportList.length > 0) {
          failedToImportStudentsData = failedToImportList.map((stds) => {
            const {
              name: failedStdName,
              email,
            } = stds || {};
            const data = {
              firstname: failedStdName,
              lastname: null,
              email,
              status: 'Failed',
            };
            return data;
          });
        }
        if (otherFacultyStudents && Array.isArray(otherFacultyStudents) && otherFacultyStudents.length > 0) {
          otherFacultyStudentsData = otherFacultyStudents.map((stds) => {
            const {
              Fullname,
              Email,
              Schoolname,
            } = stds || {};
            const data = {
              firstname: Fullname,
              lastname: '',
              email: Email,
              Schoolname,
              status: true,
            };
            return data;
          });
        }
        let finalUploadedStudents = existingStudents;
        if (finalUploadedStudents && Array.isArray(finalUploadedStudents) && finalUploadedStudents.length > 0) {
          finalUploadedStudents.push(...otherFacultyStudentsData);
        } else {
          finalUploadedStudents = otherFacultyStudentsData;
        }
        let finalStudentsList = finalUploadedStudents;
        if (finalStudentsList && Array.isArray(finalStudentsList) && finalStudentsList.length > 0) {
          finalStudentsList.push(...failedToImportStudentsData);
        } else {
          finalStudentsList = failedToImportStudentsData;
        }
        this.setState({
          showSuccessMsg: true,
          uploadedStudents: finalStudentsList,
        });
      }
    }).catch(e => console.log('Error:::::', e));
  }

  handleRedirectToBrowsePage = () => {
    const currPage = window.location.pathname;
    if (currPage.includes('/app/campus/browse/students')) {
      window.location.reload();
    } else {
      const {
        history: {
          push,
        } = {},
      } = this.props;
      if (push) {
        push('/app/campus/browse/students');
      }
    }
  }

  render() {
    const {
      t,
      history: {
        push,
      } = {},
      loading,
    } = this.props;
    const {
      isOpenUploadModal,
      showSuccessMsg,
      uploadedStudents,
    } = this.state;
    const columnWithFilter = [
      {
        title: `${t('name')}`,
        className: 'text-left',
        name: 'user',
        propName: 'user',
      },
      {
        title: `${t('email')}`,
        className: 'text-left',
        name: 'email',
        propName: 'email',
      },
      {
        title: 'School Name',
        className: 'text-left',
        name: 'school',
        propName: 'school',
      },
      {
        title: 'Other Faculty Student',
        className: 'text-left',
        name: 'Faculty',
        propName: 'Faculty',
      },
      {
        title: 'Failed to upload',
        className: 'text-left',
        name: 'upload',
        propName: 'upload',
      },
    ];
    return (
      <Fragment>
        <BackRow
          paths={this.paths}
        />
        <div>
          <div>{t('welcome')}</div>
          <div className="h4 font-weight-bold font-style-heading">{t('letsStart')}</div>
          <hr />
        </div>
        {
          !showSuccessMsg && (
            <div className="mt-2">
              <div className="mb-2">
                {t('importStdsMsg')} {t('template')} {t('importStdsMsg1')}
              </div>
              <div className="d-flex">
                <Button
                  onClick={this.activeUplaodModal}
                  kind="primary"
                  size="small"
                >
                  {t('import')} {t('students')}
                </Button>
                <Button
                  kind="ghost"
                  size="small"
                  className="ml-2"
                >
                  <a style={{ textDecoration: 'none' }} target="_blank" href={StudentCsv}>
                    {t('download')} {t('template')}
                  </a>
                </Button>
              </div>
            </div>
          )
        }
        {
          showSuccessMsg && (
            <div>
              <div className="d-lg-flex d-md-flex text-center text-lg-left text-md-left justify-content-between align-items-center">
                <div className="d-lg-flex d-md-flex text-center text-lg-left text-md-left">
                  <div style={{ minWidth: '100px' }} className="mb-3">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{ maxWidth: 60 }}>
                      <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                      <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                    </svg>
                  </div>
                  <div>
                    <div className="h5 mb-2">{t('fileUploaded')}</div>
                    <div className="bx--type-zeta">
                      {t('fileUploaded1')}
                    </div>
                    <div className="text-dark mb-2 mt-1">
                      {t('fileUploaded2')}
                    </div>
                  </div>
                </div>
                <div className="ml-3">
                  <Button
                    kind="primary"
                    onClick={this.handleRedirectToBrowsePage}
                  >
                    {t('browseStudents')}
                  </Button>
                </div>
              </div>
              {
                uploadedStudents && Array.isArray(uploadedStudents)
                  && uploadedStudents.length > 0
                  && (
                    <div>
                      <div className="h6 mt-2 mt-lg-0 mt-md-0 mb-2">
                        {t('alreadyImportedStdsMsg')}
                      </div>
                      <GenericDataTable
                        className=""
                        otherActions={[]}
                        headers={
                          columnWithFilter.map((data, index) => (
                            {
                              key: `header_${index}`,
                              header: data.title,
                              className: data.className,
                              colpropName: data.propName,
                            }
                          ))}
                        rows={
                          (uploadedStudents && Array.isArray(uploadedStudents)
                          && uploadedStudents.length > 0)
                            ? uploadedStudents.map((res, idx) => {
                              const {
                                firstname,
                                lastname,
                                email,
                                status,
                                Schoolname,
                              } = res || {};
                              return ({
                                isExpanded: true,
                                id: `row_${idx + 1}`,
                                header_0: (
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Avatar
                                        user={{
                                          firstname,
                                          lastname,
                                          picture: null,
                                        }}
                                        classNameInfo="rounded-circle"
                                        size="30"
                                      />
                                    </div>
                                    <div className="ml-2 text-capitalize">
                                      {firstname} {lastname}
                                    </div>
                                  </div>
                                ),
                                header_1: (
                                  <div>
                                    {
                                      email ? (
                                        <div>
                                          <span className="d-lg-none d-md-none bx--type-zeta pr-2">Email</span> <span>{email}</span>
                                        </div>
                                      ) : '-'
                                    }
                                  </div>
                                ),
                                header_2: (
                                  <div>
                                    {
                                      Schoolname ? (
                                        <div>
                                          <span className="d-lg-none d-md-none bx--type-zeta pr-2">School Name</span> <span>{Schoolname}</span>
                                        </div>
                                      ) : (
                                        <div>
                                          <span className="d-lg-none d-md-none bx--type-zeta pr-2">School Name</span> -
                                        </div>
                                      )
                                    }
                                  </div>
                                ),
                                header_3: (
                                  <div className="d-flex align-items-center">
                                    <div className="d-lg-none d-md-none bx--type-zeta pr-2">
                                      Other Faculty Student
                                    </div>
                                    {
                                      status === true ? (
                                        <div className="bx--tag bx--tag--green">
                                          {t('yes')}
                                        </div>
                                      ) : (
                                        <div className="bx--tag bx--tag--red">
                                          {t('no')}
                                        </div>
                                      )
                                    }
                                  </div>
                                ),
                                header_4: (
                                  <div>
                                    {
                                      status === 'Failed' ? (
                                        <div>
                                          <span className="d-lg-none d-md-none bx--type-zeta pr-2">Failed to upload</span> <span className="bx--tag bx--tag--red">{status}</span>
                                        </div>
                                      ) : (
                                        <div>
                                          -
                                        </div>
                                      )
                                    }
                                  </div>
                                ),
                              });
                            }) : []
                        }
                        selectable={false}
                        searchable={false}
                        onSearchInputChange={() => {}}
                        expandable={false}
                        batchActions={false}
                      />
                    </div>
                  )
              }
            </div>
          )
        }
        <Modal
          onRequestSubmit={this.handleSubmitUploadModal}
          onRequestClose={this.dismissUplaodModal}
          open={isOpenUploadModal}
          passiveModal
          modalLabel=""
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading={<div className="text-capitalize">{t('upload')}</div>}
        >
          <div>
            <div className="mb-2 mt-2">
              {t('importStudentsMsg')}
            </div>
            <FileUploaderButton
              accept={['.xls', '.xlsx', '.XLS', '.XLSX']}
              buttonKind="primary"
              className="bob"
              labelText={`${t('upload')}`}
              name="index"
              onChange={this.handleSubmitUploadModal}
              role="button"
              tabIndex={0}
            />
          </div>
        </Modal>
        <JDProcessOverlay show={loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.BrowseStudents.loading,
});

const mapDispatchToProps = {
  uploadStudentsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImportStudents));
