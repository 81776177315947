import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import 'containers/roboroy/JobList/JobList.css';

const FilterTitle = ({
  titleName,
  titleId,
  state,
  currStatus,
}) => (
  <Fragment>
    <div className="d-flex">
      <div className="text-uppercase font-weight-bold" style={{ fontSize: '16px' }}>{titleName}</div>
      <div className="mt-1 mt-1">
        {
            state[`selected_${currStatus}`] === true
            && titleId === 1
            && (
              <div className="small_circle" style={{ backgroundColor: 'rgb(62, 165, 89)' }} />
            )
        }
        {
            state[`selected_${currStatus}`] === true
            && titleId === 2
            && (
              <div className="small_circle" style={{ backgroundColor: 'rgb(62, 165, 89)' }} />
            )
        }
        {
            state[`selected_${currStatus}`] === true
            && titleId === 3
            && (
              <div className="small_circle" style={{ backgroundColor: 'rgb(62, 165, 89)' }} />
            )
        }
        {
            state[`selected_${currStatus}`] === true
            && titleId === 4
            && (
              <div className="small_circle" style={{ backgroundColor: 'rgb(62, 165, 89)' }} />
            )
        }
        {
            state[`selected_${currStatus}`] === true
            && titleId === 5
            && (
              <div className="small_circle" style={{ backgroundColor: 'rgb(62, 165, 89)' }} />
            )
        }
        {
            state[`selected_${currStatus}`] === true
            && titleId === 6
            && (
              <div className="small_circle" style={{ backgroundColor: 'rgb(62, 165, 89)' }} />
            )
        }
        {
            state[`selected_${currStatus}`] === true
            && titleId === 7
            && (
              <div className="small_circle" style={{ backgroundColor: 'rgb(62, 165, 89)' }} />
            )
        }
        {
            state[`selected_${currStatus}`] === true
            && titleId === 9
            && (
              <div className="small_circle" style={{ backgroundColor: 'rgb(62, 165, 89)' }} />
            )
        }
      </div>
    </div>
  </Fragment>
);

export default FilterTitle;
