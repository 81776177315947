import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { REQUEST_CANDIDATE_CONTENT } from './actionTypes';

const findAllUserJobApplicants = (applicantId, jobId = 0) => (dispatch) => {
  const url = `${baseUrl}/applications/${applicantId}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_CANDIDATE_CONTENT,
  };

  return callApi(params);
};

export default findAllUserJobApplicants;
