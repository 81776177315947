import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tag, Button, Select, SelectItem, InlineNotification,
} from 'carbon-components-react';
import {
  getApplicationWorkflow,
  updateStageConfigs,
  deleteJobWorkflow,
} from 'actions/workflow';
import {
  getJobTimeLine,
} from 'actions/talentSearch/jobList';
import {
  getCurrentStageInProgress,
  isThereARejectedStage,
} from 'components/roboroy/WorkflowModule/workflowUtils';

const isCurrentUserAdminLevelCollaborator = (user, collaborationList, institutionId) => {
  if (user && collaborationList && Array.isArray(collaborationList)) {
    const userId = user && user.userId;
    const {
      isSuperAdmin,
      isAdmin,
      company: {
        ctypeId,
        companyId,
      } = {},
    } = user || {};
    const foundUserList = collaborationList.filter(eachCollaborator => (
      eachCollaborator && eachCollaborator.userId === userId
      && ((eachCollaborator.roleId === 1) || (eachCollaborator.roleId === 4))
    ));
    const adminCase = (isAdmin === true && ctypeId && Number(ctypeId) === 2 && institutionId === companyId);
    return ((foundUserList && Array.isArray(foundUserList) && foundUserList.length > 0) || isSuperAdmin === true || adminCase);
  }
  return false;
};

const StageStatus = ({
  workflow, collaboration, user, currentStageInProgress, index,
  eachStage, hasARejectedStage, handleDeleteStage, handleAddStage,
  handleMoveStageUp, handleMoveStageDown, stageIndex, isLastStage,
  jobId, t, institutionId,
}) => {
  return (
  <span>
    {
        eachStage
        && eachStage.stageMeta
        && (eachStage.stageMeta.jobStageId || eachStage.stageMeta.appStageId)
        && eachStage.stageMeta.workflowstage
        && eachStage.stageMeta.workflowstage.stageName !== 'Final Decision'
        // && eachStage.stageMeta.workflowstage.workflowstagetype
        // && eachStage.stageMeta.workflowstage.workflowstagetype.stageTypeName
        // && eachStage.stageMeta.workflowstage.workflowstagetype.stageTypeName !== 'Offer Round'
        && eachStage.stageMeta.workflowstage.visibility !== false
          ? (
            <span>
              {
                  (
                    <span>
                      {!jobId && eachStage.stageMeta && eachStage.stageMeta.outcomeId && <Tag className="ml-2" type="community">Completed</Tag>}
                      {!jobId && eachStage.stageMeta && (index === currentStageInProgress.index) && !eachStage.stageMeta.outcomeId && !hasARejectedStage && <Tag className="ml-2" type="private">{t('offersInProgressTab')}</Tag>}
                      {!jobId && eachStage.stageMeta && (index !== currentStageInProgress.index) && !eachStage.stageMeta.outcomeId && !hasARejectedStage&& <Tag className="ml-2" type="beta">{t('notStarted')}</Tag>}
                      {!jobId && eachStage.stageMeta && !eachStage.stageMeta.outcomeId && hasARejectedStage && <Tag className="ml-2" type="beta">{t('notStarted')}</Tag>}
                      {eachStage.stageMeta && !eachStage.stageMeta.outcomeId
                      && workflow
                      && !workflow.outcomeId
                      && (isCurrentUserAdminLevelCollaborator(user, collaboration, institutionId))
                      && (
                      <Button
                        small
                        kind="tertiary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddStage(workflow, eachStage.stageMeta.appStageId);
                        }}
                        className="ml-1"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
                          <circle cx="12" cy="12" r="10" />
                          <line x1="12" y1="8" x2="12" y2="16" />
                          <line x1="8" y1="12" x2="16" y2="12" />
                        </svg>
                      </Button>
                      )
                    }
                      {eachStage.stageMeta && !eachStage.stageMeta.outcomeId
                      && workflow
                      && !workflow.outcomeId
                      && (isCurrentUserAdminLevelCollaborator(user, collaboration, institutionId))
                      && (
                      <Button
                        disabled={isLastStage || (!jobId && index === currentStageInProgress.index)}
                        small
                        kind="tertiary"
                        onClick={(e) => { e.stopPropagation(); handleMoveStageDown(workflow, stageIndex); }}
                        className="ml-1"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-down">
                          <line x1="12" y1="5" x2="12" y2="19" />
                          <polyline points="19 12 12 19 5 12" />
                        </svg>
                      </Button>
                      )
                    }
                      {eachStage.stageMeta
                      && !eachStage.stageMeta.outcomeId
                      && workflow
                      && !workflow.outcomeId
                      && (isCurrentUserAdminLevelCollaborator(user, collaboration, institutionId))
                      && (
                      <Button
                        disabled={(!jobId && index === currentStageInProgress.index + 1) || (index === currentStageInProgress.index) }
                        small
                        kind="tertiary"
                        onClick={(e) => { e.stopPropagation(); handleMoveStageUp(workflow, stageIndex); }}
                        className="ml-1"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-up">
                          <line x1="12" y1="19" x2="12" y2="5" />
                          <polyline points="5 12 12 5 19 12" />
                        </svg>
                      </Button>
                      )
                      }
                      {eachStage.stageMeta && !eachStage.stageMeta.outcomeId
                      && workflow
                      && !workflow.outcomeId
                      && (isCurrentUserAdminLevelCollaborator(user, collaboration, institutionId))
                      && (
                        <Button
                          small
                          disabled={(!jobId && index === currentStageInProgress.index)}
                          kind="danger"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteStage(workflow.workflowId, workflow.stageConfig, eachStage.stageMeta.stageId, workflow);
                          }}
                          className="ml-1"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2">
                            <polyline points="3 6 5 6 21 6" />
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                            <line x1="10" y1="11" x2="10" y2="17" />
                            <line x1="14" y1="11" x2="14" y2="17" />
                          </svg>
                        </Button>
                      )
                      }
                    </span>
                  )
                }
            </span>
          ) : null
        }
  </span>
)};

const getBalanceWorkflowStages = (workflowStages, currentSelectedStages) => {
  const remainingStagesMap = {};
  const remainingStages = [];
  if (currentSelectedStages) {
    workflowStages.forEach((eachStage) => {
      if (eachStage && eachStage.stageTypeId) {
        remainingStagesMap[eachStage.stageTypeId] = eachStage && eachStage.stages
                                                              && eachStage.stages.length;
      }
    });

    // now substract already selected stages from the total count.
    currentSelectedStages.forEach((eachStage) => {
      const stageTypeId = eachStage && eachStage.stageMeta
                                    && eachStage.stageMeta.workflowstage
                                    && eachStage.stageMeta.workflowstage.stageTypeId;
      if (stageTypeId) {
        remainingStagesMap[stageTypeId] -= 1;
      }
    });

    workflowStages.forEach((eachStage) => {
      if (eachStage && eachStage.stageTypeId && remainingStagesMap[eachStage.stageTypeId] > 0) {
        remainingStages.push(eachStage);
      }
    });
  }
  return remainingStages;
};

class ApplicantWorkflowStagesEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    if (this && this.props && this.props.appId && !this.props.showInModal) {
      this.rerenderContent();
    }
    if (this && this.props && this.props.workflows && this.props.workflows.workflow) {
      this.setState({workflow: this.props.workflows.workflow});
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.appId !== prevProps.appId) {
      this.rerenderContent();
    }
  }

  rerenderContent() {
    const { appId, jobId, handleGetJobInfo } = this.props;
    if (appId) {
      this.getThisWorkflow(appId).then(() => {
        if (this && this.props && this.props.workflows && this.props.workflows.workflow) {
          this.setState({workflow: this.props.workflows.workflow});
        }    
      });
    } else if (!appId && jobId) {
      handleGetJobInfo(jobId);
      this.props.getJobTimeLine(jobId);
    }
  }

  getThisWorkflow(appId) {
    return this.props.getApplicationWorkflow(appId);
  }

  handleDeleteStage = (workflowId, stageConfig, stageId, workflow) => {
    const { appId, jobId } = this.props;
    const isLastStage = (workflow.stages.length === 2);
    let stageConfigList = stageConfig.split(',');
    let newStageConfigList = stageConfigList.filter(eachConfig => eachConfig != stageId);
    const data = {
      workflowId,
      stageConfig: newStageConfigList.join(','),
    };
    const finalData = { ...data };
    if (!appId && jobId !== null) {
      finalData.jobId = jobId;
    } else if (appId !== null) {
      finalData.appId = appId;
    }
    this.props.updateStageConfigs(finalData).then((res) => {
      this.rerenderContent();
      // if (isLastStage) {
      //   this.props.deleteJobWorkflow(workflowId);
      // }
    });
  }

  handleAddStage = (workflow, appStageId) => {
    let indexToInsertAt = 0;
    const { appId, jobId } = this.props;
    let isAlreadyAddInProgress = false;
    const data = workflow && workflow.stages.forEach((eachStage, index) => {
      if (eachStage.stageMeta && eachStage.stageMeta.appStageId == appStageId  && !isAlreadyAddInProgress) {
        indexToInsertAt = index + 1;
      }
      if (eachStage && eachStage.mode && eachStage.mode == 'add') {
        isAlreadyAddInProgress = true;
        indexToInsertAt = index;
      }
    });

    if (!isAlreadyAddInProgress) {
      workflow.stages.splice(indexToInsertAt, 0, {mode:"add"});
      this.setState({workflow});
    } else {
      this.showEditWorkflowError(indexToInsertAt, 'Please finish adding stage.');
    }

  }

  handleAddStageCancel = (workflow, indexToRemoveAt) => {
    workflow.stages.splice(indexToRemoveAt, 1);
    this.setState({workflow, [`selectedStageVal${indexToRemoveAt}`] : undefined});
  }  

  handleStageMovement = (workflow, indexToMove, direction) => {
    const { appId, jobId } = this.props;
    let configuration = workflow;
    let stageConfigs = [];
    configuration && configuration.stages.forEach(function(eachStage, index) {
      if (eachStage.stageMeta && eachStage.stageMeta.stageId) {
        stageConfigs.push(eachStage.stageMeta.stageId);
      } 
    });
    let temp = stageConfigs[indexToMove];
    stageConfigs[indexToMove] = stageConfigs[indexToMove+direction];
    stageConfigs[indexToMove+direction] = temp;
    const data = {
      workflowId: configuration.workflowId,
      stageConfig: stageConfigs.join(','),
    };
    const finalData = { ...data };
    if (!appId && jobId !== null) {
      finalData.jobId = jobId;
    } else if (appId !== null) {
      finalData.appId = appId;
    }
    this.props.updateStageConfigs(finalData).then(() => {
      this.rerenderContent();
    });
  }
  handleMoveStageDown = (workflow, indexToMove) => {
    this.handleStageMovement(workflow,indexToMove,1);
  }  

  handleMoveStageUp = (workflow, indexToMove) => {
    this.handleStageMovement(workflow,indexToMove,-1);
  }  


  handleAddStageSave = (workflowStages, currentStages, indexToSaveAt) => {
    const { appId, jobId } = this.props;
    if (this.state[`selectedStageVal${indexToSaveAt}`]) {
      let stageTypeId = this.state[`selectedStageVal${indexToSaveAt}`];
      //getting all stageids for this stage type
      let remainingStagesInSelectedStageType = [];

      //find remaining stages in each stage type
      workflowStages.forEach(function(eachStage, index) {
        if (eachStage && eachStage.stageTypeId == stageTypeId) {
          remainingStagesInSelectedStageType = eachStage && eachStage.stages && eachStage.stages.filter(eachStageItem => {
            let returnVal = true;
            currentStages.forEach((currentStageItem) => {
              if (currentStageItem.stageMeta && currentStageItem.stageMeta.stageId == eachStageItem.stageId) {
                returnVal = false;
              }              
            })

            return returnVal;
          })
        }
      });      

      if (remainingStagesInSelectedStageType && remainingStagesInSelectedStageType.length > 0) {
        const configuration = this.state.workflow;
        configuration.stages[indexToSaveAt] = {
          mode: 'add',
          stageMeta: {
            stageId: remainingStagesInSelectedStageType[0].stageId,
          },
        };
        let stageConfigs = [];
        configuration && configuration.stages.forEach(function(eachStage, index) {
          if (eachStage.stageMeta && eachStage.stageMeta.stageId) {
            stageConfigs.push(eachStage.stageMeta.stageId);
          } 
        });
        const data = {
          workflowId: configuration.workflowId,
          stageConfig: stageConfigs.join(','),
        };
        const finalData = { ...data };
        if (!appId && jobId !== null) {
          finalData.jobId = jobId;
        } else if (appId !== null) {
          finalData.appId = appId;
        }
        this.props.updateStageConfigs(finalData).then(() => {
          this.rerenderContent();
        });
      } else {
        this.showEditWorkflowError(indexToSaveAt, 'Invalid configuration choosen. Please retry again.');          
      }
    } else {
      this.showEditWorkflowError(indexToSaveAt, 'Please select a valid stage.');
    }
  }

  showEditWorkflowError = (indexToShowAt, errorMsg) => {
    const configuration = this.state.workflow;
    configuration.stages[indexToShowAt] = {
      mode: 'add',
      errorMsg,
    };
    this.setState({ workflow: configuration });
  }

  handleAddStageSelectChange = (selectedStage, index) => {
    this.setState({
      [`selectedStageVal${index}`]: selectedStage,
    });
  }

  render() {
    const {
      workflow, application, user, workflowStages, jobId,
      t, appId,
    } = this.props;
    const currentStageInProgress = getCurrentStageInProgress(workflow);
    const hasARejectedStage = isThereARejectedStage(workflow);
    let balanceWorkflowStages = getBalanceWorkflowStages(workflowStages, workflow && workflow.stages);
    if (!appId && jobId) {
      balanceWorkflowStages = balanceWorkflowStages.filter(res => res && res.stageTypeId && Number(res.stageTypeId) !== 3007);
    }
    return (
      <Fragment>
        {
          workflow && workflow.stages && Array.isArray(workflow.stages) && (workflow.stages).length > 1 ? (
            <div>
              {balanceWorkflowStages && balanceWorkflowStages.length == 0 && (
              <InlineNotification lowContrast
                subtitle=""
                title={t('editWorkflowMsg1')}
                kind="error"
              />
              )}
              {workflow.stages.map((eachStage, index) => {
                return (
                  <div className="border-bottom">
                    {eachStage && eachStage.stageMeta && eachStage.stageMeta.workflowstage
                      && (eachStage.stageMeta.jobStageId || eachStage.stageMeta.appStageId)
                      && eachStage.stageMeta.workflowstage.visibility !== false
                      && (
                      <div className="p-1">
                        <div className="text-uppercase row">
                          <span className="col-md-3 pt-2 pb-1">{eachStage.stageMeta.workflowstage.stageName}</span>
                          <span className="col-md-6">
                            <StageStatus
                              user={user}
                              t={t}
                              collaboration={application && application.collaboration}
                              workflow={workflow}
                              institutionId={application && application.institutionId ? application.institutionId : application && application.job && application.job.institutionId}
                              currentStageInProgress={currentStageInProgress}
                              hasARejectedStage={hasARejectedStage}
                              index={index}
                              jobId={jobId}
                              eachStage={eachStage}
                              handleDeleteStage={this.handleDeleteStage}
                              handleAddStage={this.handleAddStage}
                              handleMoveStageDown={this.handleMoveStageDown}
                              handleMoveStageUp={this.handleMoveStageUp}
                              stageIndex={index}
                              isLastStage={workflow.stages.length === index + 2}
                            />
                          </span>
                        </div>
                      </div>
                      )}
                    {eachStage && eachStage.mode && eachStage.mode === 'add'
                      && (
                        balanceWorkflowStages && balanceWorkflowStages.length > 0 && (
                          <div className="row pb-2">
                            <div className="col-md-3 mt-auto mb-auto">
                              <Select
                                id="interview-template"
                                labelText="Select Stage to Add"
                                defaultValue={null}
                                onChange={(e) => { this.handleAddStageSelectChange(e.target.value, index); }}
                              >
                                <SelectItem
                                  value="placeholder-item"
                                  text="-- None --"
                                />
                                {
                                  balanceWorkflowStages.map(stages => (
                                    <SelectItem
                                      key={stages.stageTypeId}
                                      value={stages.stageTypeId}
                                      text={stages.stageTypeName}
                                    />))
                                }
                              </Select>
                            </div>
                            <div className="col-md-5 mt-auto mb-auto pt-2">
                              <Button
                                small
                                kind="tertiary"
                                onClick={() => {
                                  this.handleAddStageSave(workflowStages, workflow && workflow.stages, index);
                                }}
                                onFocus={() => { }}
                              >
                                {t('save')}
                              </Button>
                              <Button
                                small
                                kind="tertiary"
                                className=" ml-1"
                                onClick={() => { this.handleAddStageCancel(workflow, index); }}
                                onFocus={() => { }}
                              >
                              {t('cancel')}
                              </Button>
                            </div>
                          </div>)
        
                      )}
                    {eachStage && eachStage.mode && eachStage.mode == 'add' && eachStage.errorMsg
                      && <p className="text-danger m-0 bx--type-zeta mt-1">{eachStage.errorMsg}</p>
                    }
                  </div>
                )})
              }
            </div>
          ) : (
            <div className="text-dark col-md-12 pt-2">
              {t('editWorkflowMsg2')}
            </div>
          )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.workflows.loading,
  user: state.x0paDuser.user,
  workflows: state.workflows,
  offerDetails: state.OfferInformation.offerDetails,
});

const mapDispatchToProps = {
  getApplicationWorkflow,
  updateStageConfigs,
  deleteJobWorkflow,
  getJobTimeLine,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ApplicantWorkflowStagesEditor));
