import callApi from 'utils/callApi';
import { baseUrl } from 'utils/config';
import { deleteJobApiCache, deleteApiCache } from 'utils/helpers';

import {
  REQUEST_BUCKETS,
  REQUEST_GET_BUCKETS,
  REQUEST_EDIT_BUCKETS,
  REQUEST_DEL_BUCKETS,
  REQUEST_ADD_CANDIDATES_BUCKETS,
} from '../../actions/actionTypes';

const getBucketNames = () => (dispatch) => {
  const url = `${baseUrl}/buckets`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_BUCKETS,
  };
  return callApi(params);
};


const createBucket = bucketName => (dispatch) => {
  const url = `${baseUrl}/buckets`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify({ bucketName }),
    actionType: REQUEST_BUCKETS,
  };
  deleteApiCache('xpa-api-buckets');
  return callApi(params);
};

const delBucket = bucketId => (dispatch) => {
  const url = `${baseUrl}/buckets`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    body: JSON.stringify({ bucketId }),
    actionType: REQUEST_DEL_BUCKETS,
  };
  deleteApiCache('xpa-api-buckets');
  return callApi(params);
};

const editBucketName = (bucketId, bucketName) => (dispatch) => {
  const url = `${baseUrl}/buckets`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify({ bucketId, bucketName }),
    actionType: REQUEST_EDIT_BUCKETS,
  };
  deleteApiCache('xpa-api-buckets');
  return callApi(params);
};

const addCandidatesToBuckets = (profileId, bucketId) => (dispatch) => {
  const url = `${baseUrl}/profile-base/addCandidateToBucket`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify([{ profileId, bucketId }]),
    actionType: REQUEST_ADD_CANDIDATES_BUCKETS,
  };
  return callApi(params);
};

const reRenderContent = ({ itemsPerPage = 10, activePage = 1, searchKey = null }) => (dispatch) => {
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/buckets?`
  if (searchKey != null && searchKey.length > 0)
    url += `searchKey=${encodeURIComponent(searchKey)}`;
  url += `&filter[skip]=${skip}`;
  url += `&filter[limit]=${itemsPerPage}`;

  const params = {
    actionType: REQUEST_GET_BUCKETS,
    dispatch,
    url,
    method: 'GET',
  };
  return callApi(params);
};

export {
  getBucketNames, createBucket, delBucket, editBucketName, addCandidatesToBuckets, reRenderContent,
};
