import { withTranslation } from 'react-i18next';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const NotFound = ({roleId}) => {
  let dashboardLink = '/';
  switch(roleId) {
    case 4:
     dashboardLink = '/app/campus';
     break;
    case 3:
      dashboardLink = '/app/campus/candidate';
      break;
    default:
      //
  }
  return (
    <div style={{ margin: '2em' }}>
      <h3>How did you end up here?</h3>
      <div style={{ marginTop: '1em' }}>
        <Link to={dashboardLink} className="bx--btn bx--btn--primary">
          Go to dashboard
        </Link>
      </div>
    </div>
  )
};

export default withRouter(NotFound);
