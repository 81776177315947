import * as at from './actiontypes';

// creators
export const fetchAllSkillsRequest = () => ({
  type: at.FETCH_ALL_SKILLS_REQUEST,
});

export const fetchAllSkillsSuccess = data => ({
  type: at.FETCH_ALL_SKILLS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchAllSkillsError = error => ({
  type: at.FETCH_ALL_SKILLS_ERROR,
  payload: {
    error,
  },
});

export const createSkillRequest = () => ({
  type: at.CREATE_SKILL_REQUEST,
});

export const createSkillSuccess = data => ({
  type: at.CREATE_SKILL_SUCCESS,
  payload: {
    data,
  },
});

export const createSkillError = error => ({
  type: at.CREATE_SKILL_ERROR,
  payload: {
    error,
  },
});

export const addNewSkill = (name, skill) => ({
  type: at.ADD_SKILL,
  payload: {
    name,
    skill,
  },
});

export const removeNewSkill = (name, id) => ({
  type: at.REMOVE_SKILL,
  payload: {
    name,
    id,
  },
});

// jd parsing
export const jdParseStart = () => ({
  type: at.JD_PARSE_START,
});

export const jdParseSuccess = data => ({
  type: at.JD_PARSE_SUCCESS,
  payload: {
    data,
  },
});

export const jdParseError = error => ({
  type: at.JD_PARSE_ERROR,
  payload: {
    error,
  },
});

// dropdown data
export const fetchDropDownDataRequest = () => ({
  type: at.FETCH_DROPDOWN_DATA_REQUEST,
});

export const fetchDropDownDataSuccess = data => ({
  type: at.FETCH_DROPDOWN_DATA_SUCCESS,
  payload: {
    data,
  },
});

export const fetchDropDownDataError = error => ({
  type: at.FETCH_DROPDOWN_DATA_ERROR,
  payload: {
    error,
  },
});

// export const changeEducation = data => ({
//   type: at.CHANAGE_EDUCATION,
//   payload: {
//     data,
//   },
// });

// export const changeCurrency = data => ({
//   type: at.CHANAGE_CURRENCY,
//   payload: {
//     data,
//   },
// });

// export const changeJobFunction = data => ({
//   type: at.CHANGE_JOB_FUNCTION,
//   payload: {
//     data,
//   },
// });

export const changeNewJobDropdown = (name, data) => ({
  type: at.CHANAGE_DROPDOWN,
  payload: {
    data,
    name,
  },
});

export const changeNewJobInput = data => ({
  type: at.CHANGE_JOB_CREATION_INPUT,
  payload: {
    // {name: "input name", value "input value"}
    ...data,
  },
});

export const changeEndDate = data => ({
  type: at.CHANGE_END_DATE,
  payload: {
    data,
  },
});
