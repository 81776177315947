import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Button, Loading } from 'carbon-components-react';

import { RecruiterView, CandidateErrorView } from 'components/roboroy/invite/invite';

import { fetchUserLoginState } from 'actions/userstate';
import { offerConsentStatus, getOfferConsent } from 'actions/invite';
import defaultLogo from 'assets/images/user.svg';
import 'containers/roboroy/invite/invite.css';

class OfferInviteAccept extends React.Component {
  state = {
    company: {},
    job: {},
    profile: {},
    profileId: 0,
    error: false,
    loading: true,
    errMessage: '',
    offerConsentAccept: null,
    visibleState: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { inviteHash },
      },
    } = this.props;
    this.props.getOfferConsent(inviteHash).then((res) => {
      if (res && res.error) {
        this.setState({
          error: true,
          loading: false,
          errMessage: (res.error.message) || '',
        });
        return;
      }
      const {jobInfo={},company={},profile:{profileId, teamId}={}} = res;
      this.setState({
        company: res.company,
        job: res.jobInfo,
        profile: res.profile,
        profileId: profileId,
        jobId: jobInfo.jobId,
        teamId: teamId,
        loading: false,
      });
    });
    const {
      isUserLoggedIn,
      user: {roleId} = {},
    } = this.props;
    if (!isUserLoggedIn) {
      window.sessionStorage.setItem('xpa-redirect-uri', `/public/offer-consent/${inviteHash}/accept`);
    }
  }

  handleOfferConsentStatus = () => {
    const {
      match: {
        params: { inviteHash },
      },
    } = this.props;
    this.setState({
      offerConsentAccept: true,
    }, () => {
      const data = {
        offerConsentAccept: this.state.offerConsentAccept,
        inviteHash,
      };
      const djob = this.props.offerConsentStatus(data);
      djob.then((res = {}) => {
        if (res && res.error) {
          this.setState({
            error: true,
            loading: false,
            errMessage: (res.error.message) || '',
          });
          return;
        }
        // this.props.history.push('/app/campus/candidate/offers');
      });
    });
  }

  onConfirmInterest = () => {
    const {
      match: {
        params: { inviteHash },
      },
    } = this.props;
    this.setState({
      offerConsentAccept: true,
      visibleState: true,
    }, () => {
      const data = {
        offerConsentAccept: this.state.offerConsentAccept,
        inviteHash,
      };
      const djob = this.props.offerConsentStatus(data);
      djob.then((res = {}) => {
        // this.setState({
        //   visibleState: true,
        //   loading: false,
        // });
        if (res && res.error) {
          this.setState({
            error: true,
            loading: false,
            errMessage: (res.error.message) || '',            
          });
          return;
        }
      });
    });
  }

  goToDashboard = () => {
    this.props.history.push('/app/campus/candidate');
  }

  openPopup = () => {
    const purl = `${window.location.origin}/auth/candidate/linkedin`;
    const wwidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const wheight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const wleft = ((wwidth / 2) - 400);
    const wtop = ((wheight / 2) - 300);
    const winFeatures = `width=800,height=600,top=${wtop},left=${wleft}`;

    const rpop = window.open(purl, 'lpopup', winFeatures);

  }

  render() {
    const {
      company: {
        companyName = "Default Company",
      } = {},
      loading,
      error,
      offerConsentAccept,
      visibleState,
    } = this.state;
    console.log('visibleState', visibleState);
    const {
      job: {
        jobname: {
          nameName: jobName = "Job Name"
        } = {},
        jobfunction: {
          functionName = "Function Name"
        } = {},
        jobseniority: {
          seniorityName = "Experience"
        } = {},
        hourtype: {
          typeName = "Job Type"
        } = {},
        term: {
          termName = "Job Term"
        } = {},
      } = {},
      errMessage,
    } = this.state;

    const { isUserLoggedIn, user: { roleId } = {} } = this.props;

    const candidateView = () => {
      if (error || visibleState === false) {
        return (<CandidateErrorView message={errMessage} />);
      } else {
        return (
          <Fragment>
            <div className="ml-loo-wrap d-flex justify-content-center">
              <img src={require('assets/images/republic-polytechnic.png')} alt="" height="auto" width="auto" />
            </div>
            <div className="title-register">Congratulations! You have completed the interview stages.</div>
            <div className="text-center">
            {
              !isUserLoggedIn && (<div>
                <div className="text-center">
                  Please sign in below to move forward with your candidature.
                </div>
                <div className="box-subscribe mt-2 mb-4">
                  <div className="linkedin-wrap" onClick={() => this.openPopup()}>
                    <Button
                      kind="tertiary"
                      href="/public/student/login"
                    >
                      Sign In
                    </Button>
                  </div>
                </div>
              </div>)
            }
            {
              (isUserLoggedIn && (roleId === 3)) && !visibleState && (<div>
                <div className="box-subscribe mt-2 mb-4">
                  <Button onClick={this.onConfirmInterest}>
                    Confirm Your Interest
                  </Button>
                  {/* <Link className="bx--btn bx--btn--primary" to="/app/campus/candidate">
                    
                  </Link> */}
                </div>
                <div className="text-center mb-4">
                  On confirming your interest, you will be redirected to offer detail page.
                </div>
              </div>)
            }
            {
              (isUserLoggedIn && (roleId === 3)) && visibleState === true && (<div>
                <div className="box-subscribe mt-2 mb-4">
                  <Button onClick={this.goToDashboard}>
                    Go to dashboard
                  </Button>
                  {/* <Link className="bx--btn bx--btn--primary" to="/app/campus/candidate">
                    
                  </Link> */}
                </div>
                <div className="text-center mb-4">
                  On clicking the above button, you will be redirected to your dashboard.
                </div>
              </div>)
            }
            <div className="container">
              <div className="row jc-card">
                <div className="col-sm-12 col-md-6">
                  <div className="">
                    <div className="card-title h5">{jobName}</div>
                    {/* <div className="card-subtitle mb-1 text-gray">
                      {functionName}
                    </div>
                    <div className="card-subtitle pt-2">
                      <div className="bx--tag bx--tag--private mr-2">{seniorityName}</div>
                      <div className="bx--tag bx--tag--private mr-2">{typeName}</div>
                      <div className="bx--tag bx--tag--private mr-2">{termName}</div>
                    </div> */}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 invite-company">
                  <div className="avatar-wrap">
                    <img src={defaultLogo} width="60" height="60" style={{borderRadius:"50%"}}
                      className="company-avatar"
                    />
                    <div className="card-title text-capitalize h5 pt-2 mb-1">{companyName}</div>
                    <div className="card-subtitle text-gray">
                      Recruiting Team
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Fragment>
        )
      }
    }

    return (
      <div className="row" style={{ marginLeft: 0, marginRight: 0, height: '100%' }}>
        <div className="col-md-12 invite-content">
          <div className="body-register">
            <div className="card-invite">
              <div className="card card-content">
                { loading && (
                  <div className="bx--row justify-content-center">
                    <Loading withOverlay={false} />
                  </div>)
                }
                { !loading && isUserLoggedIn && (roleId !== 3) ? <RecruiterView /> : candidateView() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.jobs.loading,
  error: state.jobs.error,
  application: state.invitation,
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  offerConsentStatus,
  fetchUserLoginState,
  getOfferConsent,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferInviteAccept);