import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';

const getUserReport = userId => (dispatch) => {
  const url = `${baseUrl}/userinfos/${userId}/report?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'GET_USER_REPORT',
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'XPASUSER',
    },
    responseType: 'application/json',
  };
  return callApi(params);
};

const editUser = data => (dispatch) => {
  const { userId } = data || {};
  if (!userId) {
    return Promise.reject(new Error('No valid data.'));
  }
  const url = `${baseUrl}/userinfos/${userId}/edit?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'EDIT_USER_REPORT',
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'XPASUSER',
    },
    body: JSON.stringify(data),
    responseType: 'application/json',
  };
  return callApi(params);
};

const createUserSubscription = data => (dispatch) => {
  const {
    userId, startDate, endDate, productName,
  } = data || {};
  if (!userId || !startDate || !endDate || !productName) {
    return Promise.reject(new Error('Please select all the required fields.'));
  }
  const url = `${baseUrl}/usersubscriptions?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'REQUEST_CREATE_SUBSCRIPTION',
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'XPASUSER',
    },
    body: JSON.stringify(data),
    responseType: 'application/json',
  };
  return callApi(params);
};

const deleteUserSubscription = data => (dispatch) => {
  const {
    userId, userSubscriptionId,
  } = data || {};
  if (!userId || !userSubscriptionId) {
    return Promise.reject(new Error('Please select all the required fields.'));
  }
  const url = `${baseUrl}/usersubscriptions/${userSubscriptionId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'REQUEST_DELETE_SUBSCRIPTION',
    url,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'XPASUSER',
    },
    body: JSON.stringify(data),
    responseType: 'application/json',
  };
  return callApi(params);
};

const editUserSubscription = data => (dispatch) => {
  const {
    userId, userSubscriptionId, startDate, endDate,
  } = data || {};
  if (!userId || !userSubscriptionId || !startDate || !endDate) {
    return Promise.reject(new Error('No valid data.'));
  }
  const url = `${baseUrl}/usersubscriptions/${userSubscriptionId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'REQUEST_EDIT_SUBSCRIPTION',
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'XPASUSER',
    },
    body: JSON.stringify(data),
    responseType: 'application/json',
  };
  return callApi(params);
};

const sendUserActivationEmail = data => (dispatch) => {
  const {
    userId,
    email,
    isUserActive,
  } = data || {};
  if (!userId || !email || !isUserActive) {
    return Promise.reject(new Error('Please select all the required fields.'));
  }
  const edata = {
    userId,
    email,
  };
  const url = `${baseUrl}/userinfos/${userId}/activate?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: 'REQUEST_USER_ACTIVATION',
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Token': 'XPASUSER',
    },
    body: JSON.stringify(edata),
    responseType: 'application/json',
  };
  return callApi(params);
};

export {
  getUserReport,
  editUser,
  createUserSubscription,
  deleteUserSubscription,
  editUserSubscription,
  sendUserActivationEmail,
};
