import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Tour from 'reactour';

class ProductTour extends Component {
    
    render() {
        const { steps, onRequestClose, isOpen} = this.props;
        return (
            <Tour
                steps={steps}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
            />  
        )
    }
}

export default ProductTour;
