import {
  REQUEST_TASK_STATUS,
  ERROR_TASK_STATUS,
  SUCCESS_TASK_STATUS,
} from '../../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
};

const taskStatus = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_TASK_STATUS:
      return {
        ...state,
        loading: true,
      };
    case ERROR_TASK_STATUS:
      return {
        ...state,
        ...payload,
        loading: false,
      };    
    case SUCCESS_TASK_STATUS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default taskStatus;
