import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, Loading, InlineNotification } from 'carbon-components-react';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import { getTalentPoolInviteInfo } from 'actions/userstate';
import defaultLogo from 'assets/images/user.svg';
import companyLogoPlaceholder from 'assets/images/company.png';


class JoinTalentPool extends React.Component {
    state = {
        company: {},
        job: {},
        profile: {},
        profileId: 0,
        error: false,
        loading: true,
    };

    componentDidMount() {
        const {
            match: {
                params: { sharinghash },
            },
            user: {
                userId,
            } = {},
        } = this.props;

        const djob = this.props.getTalentPoolInviteInfo(userId, sharinghash);
        djob
            .then((res = {}) => {
                this.setState({
                    error: true,
                    loading: false,
                    recruiterInfo: res
                })
                return;
            });
    };

    activateModal = () => {
        this.setState({
            activeModal: true,
        });
    };
    dismissModal = () => {
        this.setState({
            activeModal: false
        });
    };
    onUploadSuccess = () => {
        this.setState({
            resumeSubmitted: true
        });
    };
    openPopup = () => {
        const purl = `${window.location.origin}/auth/candidate/linkedin`;
        const wwidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const wheight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        const wleft = ((wwidth / 2) - 400);
        const wtop = ((wheight / 2) - 300);
        const winFeatures = `width=800,height=600,top=${wtop},left=${wleft}`;

        const rpop = window.open(purl, 'lpopup', winFeatures);

    }
    render() {
        const {
            loading,
            recruiterInfo: {
                company: {
                    companyName
                } = {},
                companyInfo: {
                    emailBg,
                    logo
                } = {},
                picture,
                firstname,
                lastname,
            } = {}
        } = this.state;

        let logoURL = companyLogoPlaceholder;
        if (logo) {
            logoURL = `https://s3-ap-southeast-1.amazonaws.com/x0pa-companies/${logo}`;
        }
        const {
            isUserLoggedIn,
            user: { roleId } = {},
        } = this.props;

        const candidateView = () => {
            return (
                <Fragment>
                    <div className="text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="avatar-wrap">
                                        <img src={defaultLogo} width="60" height="60" style={{ borderRadius: "50%" }}
                                            className="company-avatar"
                                        />
                                        <div className="card-title h5 pt-2 mb-1">{firstname} {lastname}</div>
                                        <div className="card-subtitle text-dark">
                                            {companyName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (<div>
                                <div className="text-center bx--type-gamma pb-2">
                                    Come & join my talent pool to explore a world of opportunities.
                                </div>
                            </div>)
                        }
                    </div>
                    {
                        !isUserLoggedIn && (<div>
                            <div className="text-center">
                                <div className="text-dark bx--type-zeta pb-1">Please sign in below to upload your CV.</div>
                                <div className="linkedin-wrap" onClick={() => this.openPopup()}>
                                    <img src={require('assets/images/linkedin/Sign-In-Large---Default.png')}
                                        title="Sign in with LinkedIn" width="210" height="39" />
                                </div>
                            </div>
                        </div>)
                    }
                    {
                        isUserLoggedIn && (<div className="text-center xpa-modal--resume-upload" data-toggle="modal" data-target="#xpa-modal--resume-upload">
                            <Button onClick={() => this.activateModal()}>
                                SUBMIT CV
                            </Button>
                        </div>)
                    }
                    <ResumeUpload
                        pageType="talentpool"
                        sharinghash={this.props.match && this.props.match.params && this.props.match.params.sharinghash}
                        jobId={this.props.match.params.id}
                        dismiss={this.dismissModal}
                        active={this.state.activeModal}
                        onUploadSuccess={this.onUploadSuccess}
                    />
                    <br />
                    <div className="m-logo-wrap">
                        <img
                            alt="Company Logo"
                            src={logoURL}
                            className="img60_60 mr-2 border"
                        />
                    </div>
                </Fragment>
            )
        }

        return (
            <div className="row" style={{ marginLeft: 0, marginRight: 0, height: '100%' }}>
                <div className="col-md-12 invite-content">
                    <div className="body-register">
                        <div className="card-invite">
                            {this.state.resumeSubmitted &&
                                <InlineNotification lowContrast
                                    onCloseButtonClick={() => { }}
                                    className="mt-0"
                                    title="Thank you! Your CV has been successfully submitted and added to my talent pool."
                                    subtitle=""
                                    iconDescription="describes the close button"
                                    kind="success"

                                />
                            }
                            <div className="card card-content">
                                {loading && (
                                    <div className="bx--row justify-content-center">
                                        <Loading withOverlay={false} />
                                    </div>)
                                }
                                {candidateView()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.jobs.loading,
    error: state.jobs.error,
    application: state.invitation,
    isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
    user: state.x0paDuser.user,
});

const mapDispatchToProps = {
    getTalentPoolInviteInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinTalentPool);
