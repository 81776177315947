
const updateSkills = (candidateProfile, Skills) => {
  if (candidateProfile.skills) candidateProfile.skills.push(Skills);
};

const updateEduction = (candidateProfile, index, School) => {
  if (candidateProfile.education && Array.isArray(candidateProfile.education)
  && candidateProfile.education.length >= index) {
    candidateProfile.education[index].push(School);
  }
};

const updateExperience = (candidateProfile, index, Company) => {
  if (candidateProfile.experience && Array.isArray(candidateProfile.experience)
  && candidateProfile.experience.length >= index) {
    candidateProfile.experience[index].push(Company);
  }
};

const addEduction = (candidateProfile, School) => {
  if (candidateProfile.education
    && Array.isArray(candidateProfile.education)) candidateProfile.education.push(School);
};

const addExperience = (candidateProfile, Company) => {
  if (candidateProfile.experience
    && Array.isArray(candidateProfile.experience)) candidateProfile.experience.push(Company);
};

module.exports = {
  updateSkills,
  updateEduction,
  updateExperience,
  addEduction,
  addExperience,
};
