import { isPublicRoute } from 'utils/common';

const errorResponse = actionType => `ERROR_${actionType}`;
const successResponse = actionType => `SUCCESS_${actionType}`;

const createAction = (type, payload) => ({
  type,
  payload,
});

// calls endpoint
const callApi = (params) => {
  const {
    dispatch, actionType, headers, url, ...otherParams
  } = params;
  let finalHeaders=headers;
  if(dispatch!=null && dispatch!=undefined){
    dispatch({ type: actionType });
  }
  const token=sessionStorage.getItem('userToken');
  if(token!==null && token!==undefined){
    if(headers){
      finalHeaders["Authorization"]=token
    }else{
      finalHeaders={"Authorization":token}
    }
  }
  const fetchOptions = {
    ...otherParams,
    headers:finalHeaders,
    credentials: 'include',
    mode: 'cors',
  };
  
  // if (url.indexOf('v1/') >= 0) {
  //   delete fetchOptions.credentials;
  // }

  return fetch(url, fetchOptions)
    .then((response) => {
      const actionName = actionType.split('REQUEST_')[1];
      const errorResponseType = errorResponse(actionName);

      if (response.ok) {
        const successResponseType = successResponse(actionName);
        if (params && params.responseType && params.responseType === 'text/html') {
          return response.text().then(resp => dispatch(createAction(successResponseType, resp)));
        }
        if (params && params.responseType && params.responseType === 'application/pdf') {
          const ctype = response.headers.get('Content-Type');
          if (ctype.includes('application/json')) {
            return response.json().then((resp) => {
              dispatch(createAction(errorResponseType, resp));
              return Promise.resolve(resp);
            });
          }
          return response.blob().then((resp) => {
            dispatch(createAction(successResponseType, resp));
            const cdh = response.headers.get('content-disposition');
            if (resp.type !== 'application/pdf' && cdh) {
              const fileNameArr = cdh.split('filename=');
              const fileName = fileNameArr[1];
              return Promise.resolve({ resp, fileName });
            }
            return Promise.resolve({ resp });
          });
        }
        return response.json().then((resp) => {
          dispatch(createAction(successResponseType, resp));
          return Promise.resolve(resp);
        });
      }

      return response.json().then((resp) => {
        dispatch(createAction(errorResponseType, { error: resp }));
        return Promise.reject(resp);
      }).catch((resp) => {
        dispatch(createAction(errorResponseType, { error: resp }));
        return Promise.reject(resp);
      });
    }).catch((err = {}) => {
      console.error('Request error:', err);
      const { error, code } = err;
      const originPath = '/';
      const currentPath = window.location.pathname;
      if (isPublicRoute(currentPath)) {
        return;
      }
      if (code && error) {
        if (error === 'Unauthorized'
          && code === 'X0PA-401-1'
          && (originPath !== currentPath)
        ) {
          window.location.href = window.location.origin;
        }
      }
    });
};

export default callApi;
