import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Modal, Pagination, Button, ToolbarItem,
  Select, SelectItem, InlineNotification,
  RadioButton,
  RadioButtonGroup,
} from 'carbon-components-react';
import queryString from 'query-string';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { getCustomEmailTemplates } from 'actions/email-templates';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import BackRow from 'components/common/BackRow/BackRow';
import FacetsBrowse from 'components/roboroy/common/FacetsBrowse';
import Avatar from 'components/common/Avatar';
import {
 getEmployees,
 sendCompanyConfirmationMail
} from 'actions/BrowseEmployees';
import {
  editCandidateProfile,
} from 'actions/talentSearch/candidatesList';
import { sendEmails } from 'actions/talentSearch/talentPool';
import { CheckmarkOutline16, CheckmarkFilled16, CloseOutline16, CloseFilled16 } from '@carbon/icons-react';
import { keys } from 'lodash';
import "./BrowseStudents.css";
import {
  Layout,
  PagingInfo,
  Result,
  SearchBox,
  Facets,
  MultiCheckboxFacet,
  SingleSelectFacet,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import { getBatchDetails } from 'actions/BatchManagementView';
import { fetchAllCompaniesList } from 'actions/BrowseCompanies';
import FilterData from './FilterData';

const NoStudentsBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no students for your current selection.</p>
    </div>
  </Tile>
);

class BrowseEmployees extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'employee', name: 'Employee', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      activePage: 1,
      itemsPerPage: 10,
      isOpenMoreModal: false,
      mainVals: null,
      isOpenMarketModal: false,
      finalCompsArr: [],
      finalBatchArr: []
    };
    // this.handleFetchCompanisList();
  }

  componentDidMount() {
    const { location } = this.props;
    const { search, state } = location || {};
    this.props.getEmployees().then((res) => {
      if(res && !res.error){
        let employeesList = res && res.employees
        if(employeesList && Array.isArray(employeesList) && employeesList.length > 0){
          this.setState({
            employeesArr: employeesList
          })
        }
      }
    })
    this.handleFetchBatchList();
    // this.handleFetchCompanisList();
    this.setState({
      defaultCheckList: {}
    })
  }

  handleFetchBatchList = () => {
    const { user: { company: { ctypeId } = {} } = {} } = this.props;
    this.props.getBatchDetails().then((res) => {
      const { list } = res;
      this.setState({ finalBatchArr: list }
        , () => {
          this.props.getBatchDetails().then((res) => {
            const { list: batchList } = res;
            const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
            if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
              if (parseInt(ctypeId) !== 1) {
                this.setState({ "currentBatch": currentBatch.batchName })
              }
            }
          });
        }
      )
    })
  }

  handleFetchCompanisList = () => {
    const managedCompData = {
      activePage: 1,
      itemsPerPage: 1000,
      Companymapstatus: ['Accepted'],
    };
    this.props.fetchAllCompaniesList(managedCompData).then(res => {
      if (res && !res.error) {
        const { companies } = this.props;
        let finalCompsArr = [];
        if (companies && Array.isArray(companies) && companies.length > 0) {
          finalCompsArr = companies.map(comps => {
            const { _source: { companyId, companyName, displayCompanyName } = {} } = comps || {};
            return { companyId, companyName:displayCompanyName };
          });
          finalCompsArr.sort((a, b) => {
            if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
              return -1;
            }
            if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
              return 1;
            }
            if (a.companyName.toLowerCase() === b.companyName.toLowerCase()) {
              return 0;
            }
          });
          this.setState({
            finalCompsArr,
          });
        }
      }
    });
  };

  handlePaginationChange = data => {
    const { history: { push } = {}, location: { search } = {} } = this.props;
    const { status } = this.state;
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      const currData = {
        itemsPerPage: this.state.itemsPerPage,
        activePage: this.state.activePage,
        filterOptions: this.state.sortParam,
        searchKey: this.state.keyword,
      };
      if (search) {
        const renderData = this.handleSearchQuery(search);
        const finalData = { ...currData, ...renderData };
        this.props.getEmployees(finalData).then(() => {
        });
      } else {
        this.props.getEmployees(currData).then(() => {
        });
      }
    });
  };

  handleSearchQuery = searchString => {
    const search = decodeURIComponent(searchString);
    const searchSplit = search.split('?');
    const resSplt = searchSplit[1].split('&');
    const finalVal = {};
    let defaultCheckList = {};
    let resSpltLength = 0;
    resSplt &&
      Array.isArray(resSplt) &&
      resSplt.length > 0 &&
      resSplt.forEach(res => {
        if (res) {
          const valSpl = res.split('=');
          let valueArr = [];
          if (valSpl[1].includes(',')) {
            valueArr = valSpl[1].split(',');
          } else {
            valueArr = [valSpl[1]];
          }
          defaultCheckList[valSpl[0]] = valueArr;
          finalVal[valSpl[0]] = valSpl[0];
          finalVal[`filterName_${valSpl[0]}`] = valueArr;
          finalVal.defaultCheckList = defaultCheckList;
        }
        resSpltLength++;
        if (resSpltLength === resSplt.length) {
          this.setState({ defaultCheckList: defaultCheckList })
        }
      });
    return finalVal;
  };

  handleSearchSubmit = event => {
    const { location: { search, state } = {} } = this.props;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      const data = {
        itemsPerPage: this.state.itemsPerPage,
        activePage: 1,
        filterOptions: this.state.sortParam,
        searchKey: this.state.keyword,
      };
      if (search) {
        const renderData = this.handleSearchQuery(search);
        const finalData = { ...data, ...renderData };
        this.searchTimer = setTimeout(
          () => this.props.getEmployees(finalData),
          1000
        );
      } else {
        this.searchTimer = setTimeout(
          () => this.props.getEmployees(data),
          1000
        );
      }
    });
  };

  searchTimerVal = null;

  handleSearchCompanyName = event => {
    const { finalCompsArr } = this.state;
    if (this.searchTimerVal) {
      clearTimeout(this.searchTimerVal);
    }
    const companyName =
      event.target.value &&
      event.target.value.toString() &&
      event.target.value.toLowerCase() &&
      event.target.value.trim();
    let finalFilteredArr = [];
    if (companyName && companyName !== '' && companyName.length > 0) {
      finalFilteredArr = finalCompsArr.filter(res => {
        const { companyName: propCompName } = res;
        if (
          propCompName &&
          propCompName.toLowerCase() &&
          !propCompName.includes(companyName)
        ) {
          return false;
        }
        return true;
      });
      this.setState(
        {
          finalCompsArr: finalFilteredArr,
          showLoader: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showLoader: false,
            });
          }, 1000);
        }
      );
    } else {
      this.setState(
        {
          showLoader: true,
        },
        () => {
          this.handleFetchCompanisList();
          setTimeout(() => {
            this.setState({
              showLoader: false,
            });
          }, 1000);
        }
      );
    }
  };

  handleSelectCustomRegModal = (actionItems, actionState) => {
    if (actionState !== 'list') {
      this.setState({
        selectedValue: null,
        isOpenPassiveCustRegModal: true,
        actionItems,
        actionState,
      });
    }
  }

  handleChangeRadioButton = (e) => {
    switch (e) {
      case 'placement-confirmation-email':
        this.setState({
          selectedValue: e,
          isOpenSendConfirmEmailModal: true
        });
        break;  
      default:
        break;
    }
  };

  handleCurrentSelectedEmail = (selected) => {
    const {
      employees,
    } = this.props;
    const mailsArray = Array.isArray(employees) ? employees.filter((res) => {
      const {
        userId
      } = res || {};
      if(selected == userId)
      return selected;
    }) : [];
    let emailData = [];
    if (mailsArray && Array.isArray(mailsArray) && mailsArray.length > 0) {
      emailData = mailsArray.map((res) => {
        let {
         userId
        } = res || {};
        const data = {
          userId
        };
        return data;
      });
    }
    return emailData;
  }

  handleGetSelectedEmails = (selected) => {
    const { employees } = this.props;
    const mailsArray = Array.isArray(employees) ? employees.filter((res) => {
      const {
        userId
      } = res || {};
      return selected.indexOf(userId) > -1;
    }) : [];
    const emailData = [];
    if (Array.isArray(mailsArray)) {
      mailsArray.forEach((e) => {
        let {
          userId
        } = e || {};
        if (userId !== null) {
          emailData.push({
            userId
          });
        }
      });
    }
    return emailData;
  }

  handleSubmitSendConfirmationEmail = () => {
    console.log("handleSubmitConfirmationEmail called")
    const {
      actionItems, actionState,
    } = this.state;
    let emailData = [];
    if (actionState === 'email') {
      const actionItemsIds = actionItems.map(({ id = '' }) => Number(id.split('_')[1]));
      emailData = this.handleCurrentSelectedEmail(actionItemsIds);
      let employeeIds = [];
      if(emailData && Array.isArray(emailData) && emailData.length > 0){
        emailData.forEach((res) => {
          let employeeId = res && res.userId
          employeeIds.push(employeeId)
        })
      }
      let dataDet = {}
      dataDet.employeeIds = employeeIds
      this.props.sendCompanyConfirmationMail(dataDet).then((res) => {
        if (res && !res.error) {
          this.setState({
            isOpenSendConfirmEmailModal: false,
            isOpenPassiveCustRegModal: false,
          });
        }
      });
    } else if (actionState === 'email-all') {
      const {
        employees,
        count,
      } = this.props;

      const {
        location: { search } = {},
      } = this.props;

      if (search) {
        const data = {
          itemsPerPage: count,
          activePage: 1,
          searchKey: null,
        };
        const renderData = this.handleSearchQuery(search);
        const finalData = { ...data, ...renderData };
        this.props.getEmployees(finalData).then((res) => {
          if(res && !res.error){
            let {employees } =this.props
            let employeeIds = [];
            if (employees && Array.isArray(employees) && employees.length > 0) {
              employeeIds = employees.map((stds) => {
                const {
                  userId
                } = stds || {};
                return userId;
              });
            }
            emailData = this.handleGetSelectedEmails(employeeIds);

            const info = {
              employeeIds: employeeIds,
              batch: finalData && finalData.filterName_batch,
            };
            this.props.sendCompanyConfirmationMail(info).then((ems) => {
              if (ems && !ems.error) {
                this.setState({
                  isOpenSendConfirmEmailModal: false,
                  isOpenPassiveCustRegModal: false,
                  itemsPerPage: 10,
                }, () => {
                  this.props.getEmployees(this.state)
                });
              }
            });
          }
        })
      } else{
        const data = {
          itemsPerPage: count,
          activePage: 1,
          searchKey: null,
        };
        this.setState({
          itemsPerPage: count,
        }, () => {
          this.props.getEmployees(this.state).then((res) => {
            if (res && !res.error) {
              const {
                employees,
              } = this.props;
              let employeeIds = [];
              if (employees && Array.isArray(employees) && employees.length > 0) {
                employeeIds = employees.map((stds) => {
                  const {
                    userId
                  } = stds || {};
                  return userId;
                });
              }
              emailData = this.handleGetSelectedEmails(employeeIds);
              const finalData = {
                employeeIds: employeeIds
              };
              this.props.sendCompanyConfirmationMail(finalData).then((ems) => {
                if (ems && !ems.error) {
                  this.setState({
                    isOpenSendConfirmEmailModal: false,
                    isOpenPassiveCustRegModal: false,
                    itemsPerPage: 10,
                  }, () => {
                    this.props.getEmployees(this.state)
                  });
                }
              });
            }
          });
        });
      }
    }
  }

  onViewConfirmationEmail = () => {
    const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
    window.open(`/emailpreview/company-placement-confirmation-email`, 'Email Preview', params);
    console.log("onViewConfirmationEmail called")
  }

  render() {
    const {
      employees, count, t, companies, batchDetails,
      loading, user: { userId, isSuperAdmin, isAdmin, company: { ctypeId } = {} } = {},
    } = this.props;
    const {
      employeesArr,
      activePage,
      defaultCheckList,
      finalCompsArr,
      finalBatchArr,
      isOpenPassiveCustRegModal,
      isOpenSendConfirmEmailModal
    } = this.state;

    let batchList = (batchDetails && batchDetails.list)

    const columnWithFilter = [
      {
        title: `${t('employee')} ${t('details')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('Status')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
    ];
    const titles = [ 
      {
        titleId: 1,
        titleName: 'Status',
        status: 'ap',
        values: {
          status: [
            { visbName: 'Active', status: 1 },
            { visbName: 'InActive', status: 2 },
          ],
        },
        filterKey: 'status',
      },
      {
        titleId: 2,
        titleName: 'Companies',
        status: 'cp',
        values: {
          finalCompsArr,
        },
        filterKey: 'companyIds',
      },
      {
        titleId: 3,
        titleName: 'Batch',
        status: 'b',
        values: {
          finalBatchArr,
        },
        filterKey: 'batch',
      },
      {
        titleId: 4,
        titleName: 'Employees With Interns',
        status: 'ei',
        values: {
          status: [
            { visbName: 'Yes', status: 1 },
            { visbName: 'No', status: 2 },
          ],
        },
        filterKey: 'interns',
      },
      {
        titleId: 5,
        titleName: 'Email Acknowledgment',
        status: 'ea',
        values: {
          status: [
            { visbName: 'Received', status: 1 },
            { visbName: 'Not Received', status: 2 },
          ],
        },
        filterKey: 'acknowledge',
      }
    ]

    const facets = {
      "Status": [
        { key: 'active', id: '1', isSelected: false, filterKey: "status", status: "ap" },
        { key: 'inactive', id: '2', isSelected: false, filterKey: "status", status: "ap" },
      ],
      "Batch": batchList.map(batch => {
        return {
          key: batch.batchName,
          isSelected: false,
          id: batch.batchName,
          status: "b",
          filterKey: "batch"
        }
      }),
      "Companies": companies.map(company => {
        const {
          _source: {
            displayCompanyName,
            companyId
          } = {},
        } = company || {};
        return {
          key: displayCompanyName,
          isSelected: false,
          id: companyId.toString(),
          status: "cp",
          filterKey: "companyIds"
        }
      }),
      "Employees With Interns": [
        { key: 'yes', id: '1', isSelected: false, filterKey: "interns", status: "ei" },
        { key: 'no', id: '2', isSelected: false, filterKey: "interns", status: "ei" },
      ],
      "Email Acknowledgment": [
        { key: 'received', id: '1', isSelected: false, filterKey: "acknowledge", status: "ea" },
        { key: 'not received', id: '2', isSelected: false, filterKey: "acknowledge", status: "ea" },
      ],
    }

    const keys = facets && Object.keys(facets);

    return(
       <Fragment>
          <BackRow
            paths={this.paths}
          />
          <div className="bx--type-delta mb-2 mt-2">Browse Employees</div>
          <div className="students-layout">
            <Layout 
              bodyHeader={
                <div className="meta d-flex justify-content-between w-100 align-items-center">
                  <div>
                    <PagingInfo
                      end={Math.min(
                        Number(count),
                        Number(this.state.itemsPerPage) *
                        Number(this.state.activePage)
                      )}
                      start={
                        1 +
                        Number(this.state.activePage - 1) *
                        Number(this.state.itemsPerPage)
                      }
                      totalResults={Number(count)}
                    />
                  </div>
                </div>
              }
              sideContent={
                facets !== undefined && facets !== null &&
                  (
                    (Number(ctypeId) === 2)
                    ||
                    (facets["Companies"] !== undefined && facets["Batch"] !== undefined &&
                      facets["Companies"].length > 0 && facets["Batch"].length > 0 && facets["Status"] !== undefined)
                  ) &&
                  defaultCheckList != null && defaultCheckList != undefined &&
                  <FilterData
                    defaultCheckList={defaultCheckList}
                    itemsPerPage={this.state.itemsPerPage}
                    activePage={this.state.activePage}
                    filterOptions={this.state.sortParam}
                    searchKey={this.state.keyword}
                    titles={titles}
                    handleSearchCompanyName={this.handleSearchCompanyName}
                    showLoader={this.state.showLoader}
                    history={this.props.history}
                    params={this.props.match && this.props.match.params}
                    search={
                      this.props.location && this.props.location.search
                    }
                    handlePaginationChange={this.handlePaginationChange}
                    filterEmployee={true}
                    facets={facets}
                    keys={keys}
                  />
              }
              bodyContent={
                <div className="">
                  <GenericDataTable
                    className=""
                    batchActions={
                      [
                        {
                          label: `${t('send')} ${t('email')}`,
                          key: 'email',
                          icon: {
                            id: 'icon--email',
                            name: 'icon--email',
                            tags: 'icon--email',
                            styles: '',
                            viewBox: '0 0 16 11',
                            width: '16',
                            height: '11',
                            svgData: {
                              circles: '',
                              ellipses: '',
                              paths: [
                                {
                                  d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                                  'fill-rule': 'nonzero',
                                },
                              ],
                              polygons: '',
                              polylines: '',
                              rects: '',
                            },
                          },
                        },
                        {
                          label: `${t('send')} ${t('email')} ${t('all')} ${count} ${t('employees')}`,
                          key: 'email-all',
                          icon: {
                            id: 'icon--email',
                            name: 'icon--email',
                            tags: 'icon--email',
                            styles: '',
                            viewBox: '0 0 16 11',
                            width: '16',
                            height: '11',
                            svgData: {
                              circles: '',
                              ellipses: '',
                              paths: [
                                {
                                  d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                                  'fill-rule': 'nonzero',
                                },
                              ],
                              polygons: '',
                              polylines: '',
                              rects: '',
                            },
                          },
                        }
                      ]
                    }
                    headers={
                      columnWithFilter.map((data, index) => (
                        {
                          key: `header_${index}`,
                          header: data.title,
                          className: data.className,
                          colpropName: data.propName,
                        }
                    ))}
                    rows={employees && Array.isArray(employees)
                      && employees.length > 0
                      ? employees.map((resp) => {
                        const {
                          firstname,
                          lastname,
                          email,
                          userId,
                          active,
                          picture
                        } = resp || {};
                        return ({
                          isExpanded: true,
                          id: `row_${userId}`,
                          header_0: (
                            <div className="d-flex p-2">
                              <div className="mt-2">
                                <Avatar
                                  user={{
                                    firstname: firstname,
                                    lastname: lastname,
                                    picture,
                                  }}
                                  classNameInfo="rounded-circle"
                                  size="60"
                                />
                              </div>
                              <div className="mt-2 ml-2">
                                <div><small><b className="text-dark text-uppercase">User ID</b> {userId}</small></div>
                                <div className="bx--type-epsilon text-uppercase xpa-link"><a href={`/app/campus/browse/employee/${userId}`}>{firstname} {lastname}</a></div>
                                <div>
                                  <i className="fa fa-envelope-o" aria-hidden="true" />&nbsp;
                                  <a href={`mailto:${email}`}>{email}</a>
                                </div>
                              </div>
                            </div>
                          ),
                          header_1: (
                            <div className="">
                              {active === true ? <span className="xpa-bx--tag--s bx--tag--green">Active<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Inactive<CloseOutline16 className="ml-1" width='12' /></span>}
                            </div>
                          )
                        });
                    }) : []}
                    expandable={false}
                    searchable
                    onSearchInputChange={this.handleSearchSubmit}
                    selectable={isSuperAdmin || (isAdmin && ctypeId && Number(ctypeId) === 2)}
                    batchActionClick={this.handleSelectCustomRegModal}
                  />
                  {
                    employees && Array.isArray(employees)
                    && employees.length > 0
                    && (
                      <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={this.state.activePage} totalItems={count} />
                    )
                  }
                </div>
              }
            />
          </div>
          <Modal
            onRequestClose={() => { this.setState({ isOpenPassiveCustRegModal: false }); }}
            open={isOpenPassiveCustRegModal}
            passiveModal
            modalLabel="Select Templates"
            modalHeading={t('select')}
          >
            <div className="row ml-1">
              <RadioButtonGroup onChange={e => {
                this.handleChangeRadioButton(e);
              }}
                defaultSelected={this.state.selectedValue}
                valueSelected={this.state.selectedValue}
              >
                <RadioButton
                  name="templates"
                  value="placement-confirmation-email"
                  labelText="Company Confirmation Email"
                /> 
              </RadioButtonGroup>
            </div>
          </Modal>

          <Modal
            onRequestSubmit={this.handleSubmitSendConfirmationEmail}
            onRequestClose={() => {
              this.setState({
                isOpenSendConfirmEmailModal: false,
              })
            }}
            open={isOpenSendConfirmEmailModal}
            modalLabel="Send Confirmation Email"
            primaryButtonText={t('submit')}
            secondaryButtonText={t('cancel')}
            modalHeading="Send Mail"
          >
            <div>
              {t('confirmCompanyEmailMsg')}
            </div>
            <span className="pt-0 pb-1 text-dark d-block">
              Clicking on <strong>submit</strong> will send an email to
              the employee(s).&nbsp;
              <span className="xpa-link" onClick={this.onViewConfirmationEmail}>
                Click here
              </span>{' '}
              to view content of the email.
            </span>
          </Modal>
       </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  employees: state.BrowseEmployees.employees,
  count: state.BrowseEmployees.count,
  company: state.jobs.company,
  companies: state.BrowseCompanies.companies,
  batchDetails: state.batchDetails.batchDetails,
});

const mapDispatchToProps = {
  getEmployees,
  getBatchDetails,
  fetchAllCompaniesList,
  sendCompanyConfirmationMail
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BrowseEmployees));