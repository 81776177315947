
// Select all faculty Courses
const handleSelectAllFacultyCourses = ({ courses, facultyId, ctypeId, coursemap }) => {
  let selectedCourses = [];
  if (courses && Array.isArray(courses) && courses.length > 0) {
    selectedCourses = courses.map(res => res.courseId);
  }
  const finalCourseMap = {
    facultyId,
  };
  if (ctypeId && Number(ctypeId) === 1 && facultyId && Number(facultyId) !== 60) {
    finalCourseMap.courses = [];
  } else {
    finalCourseMap.courses = selectedCourses;
  }
  let filteredList = [];
  if (coursemap && Array.isArray(coursemap) && coursemap.length > 0) {
    filteredList = coursemap.filter(res => res.facultyId !== facultyId);
    if (filteredList && Array.isArray(filteredList) && filteredList.length > 0) {
      filteredList.push(finalCourseMap);
    } else {
      filteredList = [finalCourseMap];
    }
  } else {
    filteredList = [finalCourseMap];
  }
  const finalData = {
    filteredList,
    selectedCourses,
  };
  return finalData;
};

export {
  handleSelectAllFacultyCourses,
}