import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_CANDIDATE_RECOMMENDED_SKILLS,
  REQUEST_SKILLS_RECOMMENDATIONS,
  REQUEST_JOBS_RECOMMENDATIONS,
} from '../actionTypes';

const getCandidateRecommendedSkills = profileId => (dispatch) => {
  const url = `${baseUrl}/skillsrecommend?profileId=${profileId}`;
  const params = {
    dispatch,
    actionType: REQUEST_CANDIDATE_RECOMMENDED_SKILLS,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getSkillRecommendations = () => (dispatch) => {
  const url = `${baseUrl}/candidates/skills/recommendations`;
  const params = {
    dispatch,
    actionType: REQUEST_SKILLS_RECOMMENDATIONS,
    url,
    method: 'POST',
  };

  return callApi(params);
};

const getCandidateJobRecommendations = profileId => (dispatch) => {
  const url = `${baseUrl}/profiles/${profileId}/matches`;
  const params = {
    dispatch,
    actionType: REQUEST_JOBS_RECOMMENDATIONS,
    url,
    method: 'POST',
  };

  return callApi(params);
};

export {
  getCandidateRecommendedSkills,
  getSkillRecommendations,
  getCandidateJobRecommendations,
};
