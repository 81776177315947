import {
  REQUEST_CF_USER_PROFILES,
  SUCCESS_CF_USER_PROFILES,
  ERROR_CF_USER_PROFILES,
  REQUEST_CF_REVOKE_PROFILE,
  SUCCESS_CF_REVOKE_PROFILE,
  ERROR_CF_REVOKE_PROFILE,
  REQUEST_CF_USER_CALENDARS,
  SUCCESS_CF_USER_CALENDARS,
  ERROR_CF_USER_CALENDARS,
} from '../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  profiles: [],
  calendars: [],
};

const cronofy = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_CF_USER_PROFILES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CF_USER_PROFILES: {
      return {
        ...state,
        profiles: (payload && payload.profiles) || [],
        loading: false,
        error: null,
      };
    }
    case ERROR_CF_USER_PROFILES:
      return {
        ...state,
        profiles: [],
        loading: false,
        error: payload.error,
      };
    case REQUEST_CF_USER_CALENDARS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CF_USER_CALENDARS: {
      return {
        ...state,
        calendars: (payload && payload.calendars) || [],
        loading: false,
        error: null,
      };
    }
    case ERROR_CF_USER_CALENDARS:
      return {
        ...state,
        calendars: [],
        loading: false,
        error: payload.error,
      };
    case REQUEST_CF_REVOKE_PROFILE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CF_REVOKE_PROFILE: {
      return {
        ...state,
        profiles: (payload && payload.profiles) || [],
        loading: false,
        error: null,
      };
    }
    case ERROR_CF_REVOKE_PROFILE:
      return {
        ...state,
        profiles: [],
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default cronofy;
