import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
// = styles =
// 3rd
// import 'styles/core.scss';
import 'styles/iconsweets2/styles.css'
// import 'js/bootstrap.min.js';
import 'fonts/font-awesome.min.css';
import 'react-image-lightbox/style.css';
import LanguageHeader from 'containers/common/languageChange';

const links = [
  '/public/campus/register',
  '/public/student/login',
  '/public/college/login',
  '/public/company/login',
  '/public/campus/institution/register',
  '/public/campus/company/register',
  '/public/candidate/register',
];

class App extends Component {
  componentDidMount() {}

  render() {
    const link = window.location.pathname;
    return (
      <div className="x0pa-app">
        
        <main>
        { links.includes(link) && (
          <div className="d-flex justify-content-end position-fixed w-100 mt-3" style={{ zIndex: 999 }}>
            <LanguageHeader />
            
          </div>
        )}
        {this.props.children}

        </main>
        
      </div>
    );
  }
}

export default App;
