import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import { REQUEST_USERINFO, REQUEST_UPLOAD_IMAGE, REQUEST_PROFILE_UPDATE } from './action.constant';
import { REQUEST_GTP_OPTIN_UPDATE, REQUEST_CHANGE_USER_TZ } from 'actions/actionTypes';

const fetchAll = (data) => (dispatch) => {
  const { actionType, apiName} = data;
  const url = `${baseUrl}/${apiName}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: actionType,
  };
  return callApi(params);
}

const uploadImage = (data) => (dispatch) => {
  const url = `${baseUrl}/Images/x0pa-profile-images/upload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    body: data,
    actionType: REQUEST_UPLOAD_IMAGE,
  }
  return callApi(params);
}

const uploadDocs = data => (dispatch) => {
  const url = `${baseUrl}/Images/x0pa-candidate-docs/upload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    body: data,
    actionType: REQUEST_UPLOAD_IMAGE,
  };
  return callApi(params);
};

const uploadCampusDocs = data => (dispatch) => {
  const url = `${baseUrl}/Images/x0pa-cstage-docs/upload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    body: data,
    actionType: REQUEST_UPLOAD_IMAGE,
  };
  return callApi(params);
};

const uploadLogo = (data) => (dispatch) => {
  const url = `${baseUrl}/Images/x0pa-companies/upload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    body: data,
    actionType: REQUEST_UPLOAD_IMAGE,
  }
  return callApi(params);
}

const updatePictureUrl = (userId, picture) => (dispatch) => {
  const url = `${baseUrl}/userinfos/${userId}?_=${new Date().getTime()}` //`http://localhost:3000/api/Applications?access_token=`;
  const profileObj = {
    userId,
    picture
  };
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(profileObj),
    actionType: REQUEST_PROFILE_UPDATE,
  };
  return callApi(params);
}

const updateGtpOptin = (data) => (dispatch) => {
  const {
    userId
  } = data;
  const url = `${baseUrl}/userInfos/${userId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_GTP_OPTIN_UPDATE,
  };
  return callApi(params);
};

const updateTimezone = data => (dispatch) => {
  const {
    userId
  } = data;
  const url = `${baseUrl}/userInfos/${userId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CHANGE_USER_TZ,
  };
  return callApi(params);
};

export {
  fetchAll,
  uploadImage,
  updatePictureUrl,
  updateGtpOptin,
  uploadLogo,
  updateTimezone,
  uploadDocs,
  uploadCampusDocs,
};
