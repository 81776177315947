import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from 'carbon-components-react';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import '../dashboard.css';
import { fetchCandidateJobs, setInviteStatus } from 'actions/candidate/jobs';
import CandidateJobsList from '../components/CandidateJobsList';

class JobInvites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      status: '',
      invitesLoading: false,
    };
  }

  componentDidMount() {
    this.setState({
      status: 'invites',
      invitesLoading: true,
    }, () => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_INVITE_CANDIDATE_JOBS').then(() => {
        this.setState({
          invitesLoading: false,
        });
      });
    });
  }

  handleRedirectJobInvites = (link) => {
    switch (link) {
      case 'invites':
        this.props.history.push('/app/campus/candidate/jobs/invites');
        break;
      case 'accepted':
        this.props.history.push('/app/campus/candidate/jobs/invites-accepted');
        break;
      case 'rejected':
        this.props.history.push('/app/campus/candidate/jobs/invites-rejected');
        break;
      case 'pending':
        this.props.history.push('/app/campus/candidate/jobs/invites-pending');
        break;
      default:
        break;
    }
  }

  handleJobInvitesTabClick = (tabType) => {
    let selectedTab = 0;
    let status = '';
    switch (tabType) {
      case 'invites':
        selectedTab = 0;
        status = 'invites';
        break;
      case 'accepted':
        selectedTab = 1;
        status = 'invites-accepted';
        break;
      case 'rejected':
        selectedTab = 2;
        status = 'invites-rejected';
        break;
      case 'pending':
        selectedTab = 3;
        status = 'invites-pending';
        break;
      default:
        break;
    }
    this.setState({
      selectedTab,
      status,
      invitesLoading: true,
    }, () => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_INVITE_CANDIDATE_JOBS').then(() => {
        this.setState({
          invitesLoading: false,
        });
      });
    });
  }

  handleRedirectToAllInvitesView = (status) => {
    this.props.history.push(`/app/campus/candidate/jobs/${status}`);
  }

  renderApplInvitation = applId => (
    <div className="btn-group mt-0">
      <button
        type="button"
        className="job__details-button-height pt-0 pb-0 bx--btn bx--btn--primary btn-sm btn-success"
        onClick={() => this.handleInvitationAction(applId, true)}
      >
        Accept
      </button>
      <button
        type="button"
        className="job__details-button-height pt-0 pb-0 bx--btn bx--btn--primary btn-sm btn-danger ml-2"
        onClick={() => this.handleInvitationAction(applId, false)}
      >
        Reject
      </button>
    </div>
  );

  renderOfferInvitation = appId => (
    <div className="btn-group">
      <button
        type="button"
        className="job__details-button-height bx--btn bx--btn--primary btn-sm btn-success"
        onClick={() => this.handleOfferAcceptAction(appId, true)}
      >
        Accept
      </button>
      <button
        type="button"
        className="job__details-button-height bx--btn bx--btn--primary btn-sm btn-danger ml-2"
        onClick={() => this.handleOfferAcceptAction(appId, false)}
      >
        Reject
      </button>
    </div>
  )

  handleInvitationAction = (appId, status) => {
    const data = { appId, inviteaccept: status };
    const currenstatus = this.props.setInviteStatus(data);
    currenstatus.then((res, err) => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_INVITE_CANDIDATE_JOBS').then(() => {
        this.setState({
          invitesLoading: false,
        });
      });
      if (err) return;
      this.setState({
        inviteStatus: status,
      });
    });
  };

  handleOfferAcceptAction = (appId, status) => {
    const data = {
      appId,
      offerConsentAccept: status,
    };
    this.props.setInviteStatus(data).then((res, err) => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_INVITE_CANDIDATE_JOBS').then(() => {
        this.setState({
          invitesLoading: false,
        });
      });
      if (err) return;
      this.setState({
        offerConsentAccept: status,
      });
    });
  }

  render() {
    const {
      recuiterJobInvites, recuiterJobInvitesAccepted,
      recuiterJobInvitesPending, recuiterJobsInvitesRejected,
      inviteJobs: { applications = [], count = 0 } = {},
      t,
    } = this.props;

    const {
      selectedTab, status, invitesLoading,
    } = this.state;
    return (
      <Fragment>
        <div className="mt-2 p-2">
          <span className="heading-style h6 text-uppercase font-style-heading">
            {t('jobsForYou')}
          </span>
          <div className="bx--row pb-2">
            <div className="bx--col-lg-3 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobInvites('invites'); }}>{recuiterJobInvites || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('invites')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobInvites('accepted'); }}>{recuiterJobInvitesAccepted || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('invitesAccepted')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                    <span className="xpa-link" onClick={() => { this.handleRedirectJobInvites('rejected'); }}>{recuiterJobsInvitesRejected || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('invitesRejected')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobInvites('pending'); }}>{recuiterJobInvitesPending || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('invitesPending')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <GenericTabs
              className="d-flex justify-content-end mb-2"
              selected={selectedTab}
              tabContent={[
                {
                  label: t('invitesTab'),
                  value: '',
                  onClick: () => { this.handleJobInvitesTabClick('invites'); },
                },
                {
                  label: t('invitesAcceptedTab'),
                  value: '',
                  onClick: () => { this.handleJobInvitesTabClick('accepted'); },
                },
                {
                  label: t('invitesRejectedTab'),
                  value: '',
                  onClick: () => { this.handleJobInvitesTabClick('rejected'); },
                },
                {
                  label: t('invitesPendingTab'),
                  value: '',
                  onClick: () => { this.handleJobInvitesTabClick('pending'); },
                },
              ]}
            />
          </div>
          {
            invitesLoading === true
              && (
                <div className="bx--row justify-content-around">
                  <Loading withOverlay={false} />
                </div>
              )
          }
          {
            invitesLoading === false
              && (
                <CandidateJobsList
                  sortedJobs={applications}
                  status={status}
                  count={count}
                  renderApplInvitation={this.renderApplInvitation}
                  renderOfferInvitation={this.renderOfferInvitation}
                />
              )
          }
          {
            count && count > 5
              ? (
                <div>
                  <span className="xpa-link" onClick={() => { this.handleRedirectToAllInvitesView(status); }}>{t('more')}</span>
                </div>
              ) : null
          }
        </div>
        <hr />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.candidateJobs.loading,
  error: state.candidateJobs.error,
  canjobs: state.candidateJobs,
  inviteJobs: state.candidateJobs.inviteJobs,
});

const mapDispatchToProps = {
  fetchCandidateJobs,
  setInviteStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobInvites));
