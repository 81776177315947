import { withTranslation } from 'react-i18next';
import React from 'react';
import Select from 'react-select';
// import { DropdownButton, MenuItem } from 'react-bootstrap';
import flaskLoaderImg from 'assets/loader.svg';

const PerPageDropDown = ({ perPageClicked }) => (
  <div className="float-left xp-pos__half-rem">
    <div className="form-group mb-0">
      <select name="perPageNumber" onChange={perPageClicked} className="form-select form-control form-control-sm">
        <option value="10">Items per page</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="40">40</option>
      </select>
    </div>
  </div>
);

const BulkAction = ({ onBulkSelect, actionData = [] }) => (
  <div className="float-left xp-pos__half-rem">
    <div className="form-group mb-0 ml-2">
      <div className="btn-group btn-group-sm">
        {
          actionData.map((actObj)=> {
            const { action, btnClasses = "btn btn-default", name, disabled = false } = actObj;
            return (
              <button className={btnClasses}
                onClick={(e) => onBulkSelect(e, action)}
                key={action}
                disabled={disabled}
              >
                {name}
              </button>
            )
          })
        }
      </div>
    </div>
  </div>
);

const KeywordFilter = ({ component, handleSearchSubmit }) => (
  <div className="float-right xp-pos__half-rem">
    <div className="form-group mb-0">
      <input
            className="mr-2"
            type="text"
            name="keyword"
            value={component.state.keyword}
            onChange={(event) => {
              component.setState({keyword: event.target.value});
            }}
            onKeyPress={(event) => {
              if(event.key == 'Enter'){
                handleSearchSubmit();
              }
            }}
          />
          <button className="bx--btn bx--btn--primary bx--btn--sm mr-2" onClick={() => (handleSearchSubmit())}>
            Search
          </button>
          <button className="btn btn-sm btn-light" onClick={() => {
            component.setState({keyword: ''}, () => (
              handleSearchSubmit()
            ));
            }
          }>
            Clear
          </button>

    </div>
  </div>
);

const TextBox = ({
  value, onChange, type = 'text', name, placeholder, error = '',
}) => (
  <div className={`form-group ${error ? 'has-error' : ''}`}>
    <input
      className="column col-10 form-input"
      required
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
    />
    {error && <p className="form-input-hint">{error}</p>}
  </div>
);

const SelectDropdown = ({
  onChange, className = '', name, placeholder, options, value,onInputChange,
}) => (
  <Select
    required
    className={className}
    placeholder={`Select your ${placeholder}`}
    onChange={e => onChange(e, name)}
    options={options}
    onInputChange = {value => onInputChange(value)}
    value={value}
    clearable={false}
    clearValueText={false}
  />
);


const RenderMessage = ({ message, success }) => (
  <div className={`col-6 toast alert alert-${success ? 'success' : 'danger'}`}>{message}</div>
);

const FlaskLoader = () => (
  <div className="xpa-loader-wrap">
    <img style={{ width: '100px', height: '100px' }} src={flaskLoaderImg} d={flaskLoaderImg} alt="Application Loader" />
  </div>
);

/* Can be removed added as a part of cleanup */
const ArrowUp = () => (<span aria-hidden="true" className="icon-arrow-up"></span>);
const ArrowDown = () => (<span aria-hidden="true" className="icon-arrow-down"></span>);

export {
  SelectDropdown, PerPageDropDown, TextBox, RenderMessage, KeywordFilter,
  BulkAction, ArrowUp, ArrowDown, FlaskLoader,
};
