import { withTranslation } from 'react-i18next';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import NoSubscription from 'components/common/NoSubscription';

const hasProductSubscription = (subscribedProductsList, productsArr) => {
  let hasAccess = false;
  subscribedProductsList.forEach((sp) => {
    if (productsArr.includes(sp)) {
      hasAccess = true;
    }
  });
  return hasAccess;
};

const PrivateRoute = ({
  component: Component, product, isAuthenticated, products = [], path, ...rest
}) => {
  let productsArr = [product];
  if (product.includes(',')) {
    productsArr = product.split(',');
  }
  const subscribedProductsList = products.map(p => p && p.toLowerCase());
  if ((products.length > 0 && hasProductSubscription(subscribedProductsList, productsArr))
    || (product === 'nosub')) {
    return (
      <Route
        {...rest}
        render={props => ((isAuthenticated) ? <Component {...props} /> : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ))}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={props => (<NoSubscription {...props} />)}
    />
  );
};

export default PrivateRoute;
