import {
    REQUEST_USER_TOKEN,
    ERROR_USER_TOKEN,
    SUCCESS_USER_TOKEN,
} from '../actions/actionTypes';

const initialState = {
    userTokenInfo: {}
}

const userTokenInfo = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case REQUEST_USER_TOKEN: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_USER_TOKEN: {
            return {
                ...state,
                loading: false,
                userTokenInfo: payload.message,
            }
        };
        case ERROR_USER_TOKEN:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
}

export default userTokenInfo;