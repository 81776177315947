import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import UserRegisterForm from 'containers/common/user-register';

import {
  xUserRegRequest,
  xSearchCompanies,
  xCreateCompany,
} from 'actions/xpa';

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="xpa-onboard">
        <div className="xpa-reg-wrap d-flex flex-column flex-sm-row">
          <div className="xpa-reg-content d-flex xpa-flex-1 p-4 p-md-2 justify-content-center align-items-center flex-column">
            <h1 className="font-weight-bold">
              Optimise the 6 KPIs of Hiring
            </h1>
            <ul>
              <li className="bx--type-delta">Quality of Hire</li>
              <li className="bx--type-delta">56% reduction in cost to hire</li>
              <li className="bx--type-delta">87% reduction in time to hire </li>
              <li className="bx--type-delta">Retention of Hire</li>
              <li className="bx--type-delta">Diversity of Hire</li>
              <li className="bx--type-delta">Candidate’s Experience of Hiring Process</li>
            </ul>
          </div>
          <div className="xpa-reg-form xpa-flex-1 p-4 p-md-2 justify-content-center flex-column">
            <UserRegisterForm />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  xUserRegRequest,
  xSearchCompanies,
  xCreateCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
