import { withTranslation } from 'react-i18next';
import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';

import ColorCode from 'utils/ColorCodes';

import './react-circular-progressbar.css';

const CircularProgress = ({ value, ...props }) => {
  const colorClass = ColorCode[props.getColor](value).cl;
  return (
    <CircularProgressbar
      initialAnimation
      strokeWidth={13}
      classes={{
        root: 'CircularProgressbar',
        trail: 'CircularProgressbar-trail',
        path: `CircularProgressbar-path ${colorClass}`,
        text: `CircularProgressbar-text ${colorClass}`,
        background: 'CircularProgressbar-background',
      }}
      percentage={value}
    />
  );
};

export default CircularProgress;
