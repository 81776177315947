import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ChromePicker } from 'react-color';

import {
  Tile, Button, StructuredListWrapper, StructuredListBody,
  StructuredListRow, StructuredListCell,
} from 'carbon-components-react';

import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

import {
  updatePictureUrl,
  uploadLogo,
} from 'actions/userProfile/action';
import { getCompanyInfo, updateCompanyInfo } from 'actions/admin/AdminSettings';
import companyLogoPlaceholder from 'assets/images/company.png';

class AdminSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyLogo: '',
      displayColorPicker: false,
      emailBackground: '#f6f8f9',
      uploading: false,
    };
  }

  componentDidMount = () => {
    this.props.getCompanyInfo()
      .then((res) => {
        if (res && res.data) {
          const { emailBg, logo } = res.data || {};
          if (logo && emailBg) {
            this.setState({
              emailBackground: emailBg,
              companyLogo: logo,
            });
            return;
          }
          if (emailBg) {
            this.setState({
              emailBackground: emailBg,
            });
          }
          if (logo) {
            this.setState({
              companyLogo: logo,
            });
          }
        }
      });
  }

  changeImage = (element) => {
    const { user: { currentCompany: { companyId = 0 } = {} } = {} } = this.props;
    const fileData = element.target.files;
    const file = new FormData();
    file.append('file', fileData[0]);
    this.setState({
      uploading: true,
    });
    this.props.uploadLogo(file)
      .then((res) => {
        const { name } = (res && res.result && res.result.files && res.result.files.file[0]) || {};
        this.setState({
          companyLogo: name,
          uploading: false,
        });
        if (name) {
          this.props.updateCompanyInfo({
            companyId,
            logo: name,
          });
        }
      })
      .catch(() => {
        this.setState({
          uploading: false,
        });
      });
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeComplete = (color) => {
    this.setState({ emailBackground: color.hex });
  };

  saveBackgroundColor = () => {
    const { emailBackground } = this.state;
    const { user: { currentCompany: { companyId = 0 } = {} } = {} } = this.props;
    const { companyInfo: { emailBg } } = this.props;
    if (emailBg !== emailBackground) {
      this.props.updateCompanyInfo({
        companyId,
        emailBg: emailBackground,
      })
        .then(() => {
        })
        .catch(e => console.log(e));
    }
  }

  render() {
    const {
      companyLogo, displayColorPicker, emailBackground, uploading,
    } = this.state;
    const popover = {
      position: 'absolute',
      zIndex: '2',
    };
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };
    const colorBox = {
      backgroundColor: emailBackground,
      height: '32px',
      width: '80px',
      lineHeight: '32px',
      textAlign: 'center',
      border: '2px solid #000',
      float: 'left',
    };
    let logoURL = companyLogoPlaceholder;
    if (companyLogo) {
      logoURL = `https://s3-ap-southeast-1.amazonaws.com/x0pa-companies/${companyLogo}`;
    }
    return (
      <div className="mt-4">
        <Tile className="">
          <div className="bx--row">
            <div className="card-subtitle bx--type-omega pt-1 mt-lg-3 mt-md-3 bx--col-lg-3">Company Logo</div>
            <div className="bx--col-lg-9">
              <img
                alt="Company Logo"
                src={logoURL}
                className="img60_60 rounded-circle mr-2 border"
              />
              {
                !uploading && (
                  <label
                    className="bx--btn bx--btn--primary bx--btn--sm mt-3"
                    htmlFor="logo-upload"
                  >
                    Change Logo
                    <input
                      name="logo-upload"
                      accept=".png,.jpg,.jpeg,.gif,image/gif,image/jpg,image/jpeg,image/png"
                      id="logo-upload"
                      type="file"
                      onChange={this.changeImage}
                      style={{ opacity: 0, width: 1, height: 1 }}
                    />
                  </label>
                )
              }
              {
                uploading && (
                  <Button small>Loading ...</Button>
                )
              }
            </div>
          </div>
          <hr className="mb-sm-3 mb-lg-0" />
          <div className="bx--row">
            <span className="card-subtitle bx--type-omega mt-lg-3 bx--col-lg-3"> Email Template Background Color</span>
            <div className=" bx--col-lg-9">
              <div className="mt-2">
                <div onClick={this.handleClick} style={colorBox}>
                    &nbsp;&nbsp;
                </div>
                  {
                    displayColorPicker ? (
                      <div style={popover}>
                        <div style={cover} onClick={this.handleClose} />
                        <ChromePicker
                          color={emailBackground}
                          onChangeComplete={this.handleChangeComplete}
                        />
                      </div>) : null
                  }
                  <Button className="ml-2" kind="tertiary" small onClick={this.saveBackgroundColor}>
                    Save
                  </Button>
              </div>
            </div>
          </div>
          {/* <hr className="mb-1" /> */}
          {/* <span className="card-subtitle bx--type-omega mb-2">Email templates</span>
          <p className="bx--type-zeta font-weight-normal text-justify mt-2">
                    Alerts and notification emails are sent from RoboRoy to Recruiters, candidates and collaborators.
                    By default, RoboRoy sends a default email message. However, if you wish to modify the email content,
                    you may do so by customizing the templates below.
          </p>
          <Link to="/app/campus/emails" className="bx--btn bx--btn--primary bx--btn--sm">
            Customize Email templates
          </Link> */}
        </Tile>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.adminSettings.loading,
  companyInfo: state.adminSettings && state.adminSettings.companyInfo,
});

const mapDispatchToProps = {
  updatePictureUrl,
  updateCompanyInfo,
  getCompanyInfo,
  uploadLogo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
