import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import ColoredCircle from 'components/common/ColoredCircle';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  Tile,
  Toggle,
  SelectItem,
  Select,
  Button,
  Loading,
  Pagination,
  InlineNotification,
  ToolbarItem,
  Modal,
  Checkbox,
  TextInput
} from 'carbon-components-react';
import {
  Layout,
  PagingInfo,
  Result,
  SearchBox,
  Facets,
  MultiCheckboxFacet,
  SingleSelectFacet,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';
import moment from 'moment';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import { getBatchDetails } from 'actions/BatchManagementView';
import './StudentProfileView.css';
import {
 getEmployees,
 getEmployeesById,
 getEmployeesJobs
} from 'actions/BrowseEmployees';
import { fetchAllCompaniesList } from 'actions/BrowseCompanies';
import EmployeeInfo from 'containers/common/EmployeeInfo';
import { CheckmarkOutline16, CheckmarkFilled16, CloseOutline16, CloseFilled16 } from '@carbon/icons-react';
import {
  reRenderContent,
} from 'actions/talentSearch/jobList';


class EmployeesView extends Component {

  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'employee', name: 'Employee', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      activePage: 1,
      itemsPerPage: 10,
    };
  }

  componentDidMount() {
    const { selectedTab } = this.state;
    const { history: { push } = {}, user: { roleId } = {},
      match: { params: { id } = {} } = {}, employees } = this.props;
    let data = {
      employeeId: id
    }

    this.props.getEmployeesById(data).then((res) =>{
      if(res && !res.error){
        let employees = (res && res.employees)
        if(employees && Array.isArray(employees) && employees.length > 0){
          employees.forEach((res) => {
            const {
              active, contactnumber, designation, email, firstname, iv, lastname,
              picture, userId, company: { companyId, companyName, ctypeId, description,
               domain, website } = {} 
             } = res || {};
             this.setState({
              active: active, contactnumber: contactnumber, designation: designation,
              email: email, firstname: firstname, lastname: lastname, picture: picture,
              companyName: companyName, description: description,
              website: website, domain: domain, usersCompanyId: companyId
             })
          })
        }
      }
    })

    this.props.getEmployeesJobs(data).then((resp) => {
      let employeeJobsList = (resp && resp.jobs) || []
      let employeeJobCount =  (resp && resp.count) || 0
      if(resp && !resp.error){
        this.setState({
          jobs: employeeJobsList,
          employeeJobCount: employeeJobCount
        })
      }
    });
    this.handleSingleSelectFacets();
  }

  handleSingleSelectFacets = () => {
    const {
      match: {
        params: {
          id
        } = {},
      } = {},
      location: { pathname },
      t,
    } = this.props;
    let leftNavData = [
      {
        titleName: 'Basic Information',
        titleProp: 'basicInformation',
      },
    ]
    const multiValueProps = {
      label: 'Sections',
      options: [],
      onRemove: () => { },
      onSelect: (val) => {
        const foundIndex = leftNavData.findIndex(
          (r) => r && r.titleName === val
        );
        const propValue = leftNavData[foundIndex].titleProp;
      },
    };

    const filterSingleLinkArr = [];
    leftNavData.forEach((res) => {
      const { titleName, titleProp } = res || {};
      const finalObj = {
        selected: false,
        value: titleName,
        count: '',
        propVal: titleProp,
      };
      filterSingleLinkArr.push(finalObj);
    });
    multiValueProps.options.push(...filterSingleLinkArr);
    this.setState({
      multiValueProps: [multiValueProps],
    });
  }

  handleSearchQuery = searchString => {
    const search = decodeURIComponent(searchString);
    const searchSplit = search.split('?');
    const resSplt = searchSplit[1].split('&');
    const finalVal = {};
    let defaultCheckList = {};
    let resSpltLength = 0;
    resSplt &&
      Array.isArray(resSplt) &&
      resSplt.length > 0 &&
      resSplt.forEach(res => {
        if (res) {
          const valSpl = res.split('=');
          let valueArr = [];
          if (valSpl[1].includes(',')) {
            valueArr = valSpl[1].split(',');
          } else {
            valueArr = [valSpl[1]];
          }
          defaultCheckList[valSpl[0]] = valueArr;
          finalVal[valSpl[0]] = valSpl[0];
          finalVal[`filterName_${valSpl[0]}`] = valueArr;
          finalVal.defaultCheckList = defaultCheckList;
        }
        resSpltLength++;
        if (resSpltLength === resSplt.length) {
          this.setState({ defaultCheckList: defaultCheckList })
        }
      });
    return finalVal;
  };

  handleSearchSubmit = event => {
    const { match: { params: { limit, page, id } = {} } = {}, 
     location: { search, state } = {} } = this.props;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      const data = {
        itemsPerPage: this.state.itemsPerPage,
        activePage: 1,
        filterOptions: this.state.sortParam,
        searchKey: this.state.keyword,
        employeeId: id
      };
      if (search) {
        const renderData = this.handleSearchQuery(search);
        const finalData = { ...data, ...renderData };
        this.searchTimer = setTimeout(
          () => this.props.getEmployeesJobs(finalData).then((res) => {
            let employeeJobsList = (res && res.jobs) || []
            let employeeJobCount =  (res && res.count) || 0
            if(res && !res.error){
              this.setState({
                jobs: employeeJobsList,
                employeeJobCount: employeeJobCount
              })
            }
          }),
          1000
        );
      } else {
        this.searchTimer = setTimeout(
          () => this.props.getEmployeesJobs(data).then((res) => {
            let employeeJobsList = (res && res.jobs) || []
            let employeeJobCount =  (res && res.count) || 0
            if(res && !res.error){
              this.setState({
                jobs: employeeJobsList,
                employeeJobCount: employeeJobCount
              })
            }
          }),
          1000
        );
      }
    });
  };

  searchTimerVal = null;

  handlePaginationChange = data => {
    const { history: { push } = {}, location: { search } = {}, 
      match: { params: { id } = {} } = {}, } = this.props;

    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      const currData = {
        itemsPerPage: this.state.itemsPerPage,
        activePage: this.state.activePage,
        filterOptions: this.state.sortParam,
        searchKey: this.state.keyword,
        employeeId: id
      };
      if (search) {
        const renderData = this.handleSearchQuery(search);
        const finalData = { ...currData, ...renderData };
        this.props.getEmployeesJobs(finalData).then((res) => {
          let employeeJobsList = (res && res.jobs) || []
          let employeeJobCount =  (res && res.count) || 0
          if(res && !res.error){
            this.setState({
              jobs: employeeJobsList,
              employeeJobCount: employeeJobCount
            })
          }
        });
      } else {
        this.props.getEmployeesJobs(currData).then((res) => {
          let employeeJobsList = (res && res.jobs) || []
          let employeeJobCount =  (res && res.count) || 0
          if(res && !res.error){
            this.setState({
              jobs: employeeJobsList,
              employeeJobCount: employeeJobCount
            })
          }
        });
      }
    });
  };

  render() {
    const {
      match: { params: { id } = {} } = {},
      t,
      history,
      user: {
        roleId,
        userId,
        isSuperAdmin,
        isAdmin,
        courseAdmin: isCourseAdmin,
        facultyId: userFacultyId,
        currentCompany: {
          ctypeId
        } = {},
      } = {},
      employees,
      count,
      jobsCount,
    } = this.props;
    let employeesInfo = {
      ...this.state
    }
    const { companyName, contactnumber, description, designation,
      domain, email, firstname, lastname, picture, website, active,
      usersCompanyId
    } = employeesInfo
    const {
      multiValueProps,
      employeeJobCount,
      jobs,
      itemsPerPage,
      activePage,
    } = this.state;
    const columnWithFilter = [
      {
        title: `${t('job')} ${t('Id')}`,
        className: 'text-left',
        name: 'jobid',
        propName: 'jobid',
      },
      {
        title: `${t('job')} ${t('name')}`,
        className: 'text-left',
        name: 'jobname',
        propName: 'jobname',
      },
      {
        title: `${t('batch')}`,
        className: 'text-center',
        name: 'batch',
        propName: 'batch',
      },
      {
        title: `${t('role')}`,
        className: 'text-center',
        name: 'role',
        propName: 'role',
      }
    ];

    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <div className="institutions-layout">
          <Layout 
            sideContent={
              <Facets>
                {multiValueProps &&
                  multiValueProps.map((Multiprop) => (
                    <SingleLinksFacet {...{ ...Multiprop }} />
                  ))}
              </Facets>
            }
            bodyContent={
              <div>
                <div>
                  <Fragment>
                    <div className="bx--row">
                      <div className="bx--col-lg-2 ml-3 bx--col-md-3">
                        {t('company')} {t('info')}
                      </div>
                      <div className="bx--col-lg-6 bx--col-md-4">
                        <div className="d-flex">
                          <div className="bx--col-md-3   text-dark">
                            <div>
                              {t('companyName')}:
                            </div>
                            <div>
                              {t('desc')}:
                            </div>
                            <div>{t('website')}:</div>
                            <div>{t('domain')}:</div>
                          </div>
                          <div>
                            <div className="mb-0  ">
                              {companyName ? companyName : '-'}
                            </div>
                            <div className="mb-0  ">
                              {description ? description : '-'}
                            </div>
                            <div className="mb-0  ">
                              {website ? website : '-'}
                            </div>
                            <div className="mb-0   text-capitalize">
                              {domain ? domain : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="bx--row">
                      <div className="bx--col-lg-2 ml-3 bx--col-md-3">
                        {t('user')} {t('info')}
                      </div>
                      <div className="bx--col-lg-6 bx--col-md-4">
                        <div className="d-flex">
                          <div className="bx--col-md-3   text-dark">
                            <div>
                              {t('firstName')} :
                            </div>
                            <div>
                              {t('lastName')} :
                            </div>
                            <div>{t('email')}:</div>
                            <div>{t('designation')}:</div>
                            <div>{t('phone')}:</div>
                            <div>{t('active')}:</div>
                          </div>
                          <div>
                            <div className="mb-0  ">
                              {firstname ? firstname : '-'}
                            </div>
                            <div className="mb-0  ">
                              {lastname ? lastname : '-'}
                            </div>
                            <div className="mb-0  ">
                              {email ? email : '-'}
                            </div>
                            <div className="mb-0   text-capitalize">
                              {designation ? designation : '-'}
                            </div>
                            <div className="mb-0  ">
                              {contactnumber ? contactnumber : '-'}
                            </div>
                            <div className="mb-0">
                              {active === true ? <span className="xpa-bx--tag--s bx--tag--green">Active<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Inactive<CloseOutline16 className="ml-1" width='12' /></span>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                </div>
                <hr />
                <div>
                  <Fragment>
                    <div className="h6 mb-2 mt-2">
                      {t('userJobs')}
                    </div>
                    <div className="mb-2">
                      {t('userJobInfo')}
                    </div>
                    <div className="meta d-flex justify-content-between w-100 align-items-center">
                      <div>
                        <PagingInfo
                          end={Math.min(
                            Number(employeeJobCount),
                            Number(itemsPerPage) *
                            Number(activePage)
                          )}
                          start={
                            1 +
                            Number(activePage - 1) *
                            Number(itemsPerPage)
                          }
                          totalResults={Number(employeeJobCount)}
                        />
                      </div>
                    </div>
                    <GenericDataTable 
                      className=""
                      headers={
                        columnWithFilter.map((data, index) => (
                          {
                            key: `header_${index}`,
                            header: data.title,
                            className: data.className,
                            colpropName: data.propName,
                          }
                      ))}
                      rows={
                        (jobs && Array.isArray(jobs)
                          && jobs.length > 0)
                          ? jobs.map((res) => {
                            const {
                              jobId,
                              recruiterId,
                              jobBatch,
                              companyId,
                              jobname: {
                                nameName
                              } = {},
                              company: {
                                companyName,
                                displayCompanyName,
                                description
                              } = {}
                            } = res
                            return (
                              {
                                isExpanded: true,
                                id: `row_${jobId}`,
                                header_0: (
                                  <div>{jobId ? jobId : '-'}</div>
                                ),
                                header_1: (
                                  <a
                                    href={`/app/campus/job/${jobId}`}
                                    className="xpa-name-overflow text-capitalize xpa-link bx--type-epsilon ml-2" style={{ width: '150px' }}>
                                    {nameName ? nameName : '-'}
                                  </a>
                                ),
                                header_2: (
                                  <div>{jobBatch ? jobBatch : '-'}</div>
                                ),
                                 header_3: (
                                  <div>{
                                    usersCompanyId == companyId ? (
                                      id == recruiterId ? "Owner" : "Collaborator"
                                    ) : '-'
                                  }</div>
                                ),
                              }
                            )
                          }) : []
                       } 
                      searchable
                      onSearchInputChange={this.handleSearchSubmit}
                    /> 
                    {
                      (employeeJobCount && employeeJobCount > 0) ? (
                        <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={this.state.activePage} totalItems={employeeJobCount} />
                      ) : null
                    }
                  </Fragment>
                </div>
              </div>    
            }
          />
        </div>
      </Fragment>  
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  employees: state.BrowseEmployees.employees,
  count: state.BrowseEmployees.count,
  company: state.jobs.company,
  companies: state.BrowseCompanies.companies,
  batchDetails: state.batchDetails.batchDetails,
  jobs: state.BrowseEmployees.jobs,
  jobsCount: state.BrowseEmployees.jobsCount
});

const mapDispatchToProps = {
  getEmployees,
  getBatchDetails,
  fetchAllCompaniesList,
  getEmployeesById,
  reRenderContent,
  getEmployeesJobs
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EmployeesView));
