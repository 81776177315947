import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  TextInput,
  TextArea,
  Button,
  Select,
  SelectItem,
  DatePicker,
  DatePickerInput,
} from 'carbon-components-react';
import moment from 'moment';
import FacultyUploadedDocs from './FacultyUploadedDocs';
import FacultyQuestionsConfig from './FacultyQuestionsConfig';

const FacultySettings = (props) => {
  const {
    t, handleChange, totalJobsApplicable, cvTemplate,
    termsConditions, additionalDocLink, studentUploadDocs,
    documentTypes, handleAddInputValues, inputValues,
    handleChangeInputValues, handleDeleteInputValues, state,
    handleAddStudentDocs, handleSelectItemChange, handleDeleteStdDocs,
    questInputValues, questionConfig, handleAddQuesInputValues,
    handleAddQuestVals, handleDeleteQuesConfig, handleDateChange,
    isValidCvTemplate, isValidAddlDocs,
  } = props;
  let filteredDocs = [];
  if (studentUploadDocs && Array(studentUploadDocs) && studentUploadDocs.length > 0) {
    const typeIds = studentUploadDocs.map(res => res.typeId);
    filteredDocs = documentTypes.filter(res => res && !(typeIds.includes(res.typeId)));
  } else {
    filteredDocs = documentTypes;
  }
  return (
    <div className="ml-2">
      <div className="mb-2">
        {t('schoolStgsMsg')}
      </div>
      <div className="bx--row align-items-center mt-2">
        <div className="bx--type-zeta bx--col-lg-3">
          {t('totalJobsApplicable')}
        </div>
        <div className="bx--col-lg-5">
          <TextInput
            className="mb-2"
            name="totalJobsApplicable"
            id="totalJobsApplicable"
            type="number"
            hideLabel
            min={1}
            labelText={t('totalJobsApplicable')}
            value={totalJobsApplicable}
            placeholder="0"
            onChange={e => handleChange(e)}
          />
        </div>
      </div>
      <hr />
      <div className="bx--row align-items-center mt-2">
        <div className="bx--type-zeta bx--col-lg-3">
          {t('cvTemplate')}
        </div>
        <div className="bx--col-lg-5">
          <TextInput
            className="mb-2"
            name="cvTemplate"
            id="cvTemplate"
            hideLabel
            invalid={isValidCvTemplate}
            invalidText={t('validLinkMsg')}
            labelText={t('cvTemplate')}
            value={cvTemplate}
            placeholder="www.link.com"
            onChange={e => handleChange(e)}
          />
        </div>
      </div>
      <div className="bx--row align-items-center mt-2">
        <div className="bx--type-zeta bx--col-lg-3">
          {t('additionalDocLink')}
        </div>
        <div className="bx--col-lg-5">
          <TextInput
            className="mb-2"
            name="additionalDocLink"
            id="additionalDocLink"
            hideLabel
            invalid={isValidAddlDocs}
            invalidText={t('validLinkMsg')}
            labelText={t('additionalDocLink')}
            value={additionalDocLink}
            placeholder="www.link.com"
            onChange={e => handleChange(e)}
          />
        </div>
      </div>
      {/* <div className="bx--row align-items-center mt-2">
        <div className="bx--type-zeta bx--col-lg-3">
          {t('internship')}
        </div>
        <div className="bx--col-lg-5">
          <DatePicker
            dateFormat="m/d/Y"
            datePickerType="range"
            id="date-picker"
            light={false}
            locale="en"
            onChange={e => handleDateChange(e)}
            short={false}
          >
            <DatePickerInput
              className="some-class"
              disabled={false}
              iconDescription="Icon description"
              id="date-picker-input-id-start"
              labelText={t('internshipStrtDate')}
              pattern="d{1,2}/d{4}"
              placeholder="mm/dd/yyyy"
              type="text"
              value={state.internshipStartDate && moment(state.internshipStartDate).format('MM-DD-YYYY')}
            />
            <DatePickerInput
              className="some-class"
              disabled={false}
              iconDescription="Icon description"
              id="date-picker-input-id-end"
              labelText={t('internshipEndDate')}
              pattern="d{1,2}/d{4}"
              placeholder="mm/dd/yyyy"
              type="text"
              value={state.internshipEndDate && moment(state.internshipEndDate).format('MM-DD-YYYY')}
            />
          </DatePicker>
        </div>
      </div> */}
      <hr />
      <div className="bx--row align-items-center mt-2">
        <div className="bx--type-zeta bx--col-lg-3">
          {t('docsTobeUploaded')}
        </div>
        <div className="bx--col-lg-6">
          <FacultyUploadedDocs showUploadBtn={false} handleDeleteStdDocs={handleDeleteStdDocs} studentUploadDocs={studentUploadDocs} />
          <div>
            {
            inputValues.map((vals, index) => {
              return (
                <div className="d-lg-flex align-items-center">
                  <div>
                    <Select
                      id={`docType-${index}`}
                      defaultValue="placeholder-item"
                      hideLabel
                      className="w-100 docs-min-width mb-2"
                      onChange={(e) => { handleSelectItemChange(e, index); }}
                      name={`docType-${index}`}
                      invalid={state.isValidSelectDropDown}
                      invalidText={t('offerMsg4')}
                    >
                      <SelectItem
                        key="id"
                        label="Select one option"
                        disabled
                        value="placeholder-item"
                      />
                      {
                        filteredDocs && Array.isArray(filteredDocs)
                          && filteredDocs.length > 0
                          && filteredDocs.map(res => (
                            <SelectItem
                              key={res.typeId}
                              id={res.typeId}
                              label={res && res.typeName}
                              value={res && res.typeName}
                            />
                          ))
                      }
                    </Select>
                  </div>
                  <div className="ml-lg-2">
                    <TextInput
                      className="mb-2"
                      id={`textInput_${index}`}
                      value={state[`textInput_${index}`]}
                      placeholder="name of document"
                      hideLabel={false}
                      name={`textInput_${index}`}
                      onChange={(e) => { handleChangeInputValues(e, index, 'upload'); }}
                      invalid={state.isValidTextInput}
                      invalidText={t('offerMsg11')}
                    />
                  </div>
                  <div className="ml-lg-2">
                    <a style={{ color: 'red' }} onClick={() => handleDeleteInputValues(index, 'upload')} href="javascript:void(0)">{t('delete')}</a>
                  </div>
                  <div className="ml-lg-2">
                    <a onClick={() => handleAddStudentDocs(index)} href="javascript:void(0)">{t('add')}</a>
                  </div>
                </div>
              );
            })
          }
          </div>
          {
            ((inputValues && Array.isArray(inputValues)
              && inputValues.length === 0
              && studentUploadDocs && Array.isArray(studentUploadDocs)
              && studentUploadDocs.length < 10) || (!studentUploadDocs))
              && (
                <a onClick={() => handleAddInputValues()} href="javascript:void(0)">{t('add')} {t('documents')}</a>
              )
          }
        </div>
      </div>
      <div className="bx--row align-items-center mb-2 mt-4">
        <div className="bx--type-zeta bx--col-lg-3">
          {t('addlQuesMsg')}
        </div>
        <div className="bx--col-lg-6">
          <FacultyQuestionsConfig showAnsInput={false} questionConfig={questionConfig} handleDeleteQuesConfig={handleDeleteQuesConfig} />
          <div>
            {
              questInputValues.map((res, idx) => (
                <div className="d-flex align-items-center">
                  <div>
                    <TextInput
                      className="mb-2"
                      id={`question-${idx}`}
                      value={state[`question-${idx}`]}
                      placeholder={t('questions')}
                      hideLabel={false}
                      name={`question-${idx}`}
                      onChange={(e) => { handleChangeInputValues(e, idx, 'ques'); }}
                      invalid={state.isValidQuestion}
                      invalidText={t('offerMsg11')}
                    />
                  </div>
                  <div className="ml-lg-2">
                    <a style={{ color: 'red' }} onClick={() => handleDeleteInputValues(idx, 'ques')} href="javascript:void(0)">{t('delete')}</a>
                  </div>
                  <div className="ml-lg-2">
                    <a onClick={() => handleAddQuestVals(idx)} href="javascript:void(0)">{t('add')}</a>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="mt-2">
            {
              ((questInputValues && Array.isArray(questInputValues)
                && questInputValues.length === 0
                && questionConfig && Array.isArray(questionConfig)
                && questionConfig.length < 20) || (!questionConfig))
                && (
                  <a onClick={() => handleAddQuesInputValues()} href="javascript:void(0)">
                    {t('add')} {t('questions')}
                  </a>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default (withTranslation()(FacultySettings));
