import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import CandidateProfile from 'containers/roboroy/CandidateProfile';
import Avatar from 'components/common/Avatar';
import {
  InlineNotification,
} from 'carbon-components-react';

const CandidateInfo = (props) => {
  const {
    firstname, lastname, picture, profileId,
    t, push,
  } = props;
  let image = picture;
  if (picture && (!((picture.indexOf('http:') >= 0) || (picture.indexOf('https:') >= 0)))) {
    image = `/api/v1/Images/x0pa-profile-images/download/${picture}?_=${(new Date()).getTime()}`;
  }
  return (
    <Fragment>
      {
        !picture && (
          <InlineNotification lowContrast
            className="mt-1 mb-1"
            title=""
            iconDescription="describes the close button"
            kind="warning"
            subtitle={<div>{t('uploadPic')} <span onClick={() => { push && push('/app/profile') }} className="xpa-link">{t('here')}</span></div>}
            onCloseButtonClick={() => {}}
          />
        )
      }
      <div className="candidate-info text-center d-lg-flex mt-2">
        <div className="mr-3 mb-2">
          <Avatar
            user={{
              firstname,
              lastname,
              picture: picture ? picture : null,
            }}
            size="100"
            classNameInfo = "rounded-circle"
          />
        </div>
        <div className="text-left ml-0" style={{ width: '100%' }}>
          <CandidateProfile
            profileId={profileId}
            noNameImg
          />
        </div>
      </div>
    </Fragment>
  );
};

export default (withTranslation()(CandidateInfo));
