import { SET_EMPLOYEES } from '../actions/actionTypes';

const employees = (state = [], action) => {
  switch (action.type) {
    case SET_EMPLOYEES:
      return action.data;
    default:
      return state;
  }
};

export default employees;
