import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput, Button, Modal,
  Tile, Select, SelectItem,
} from 'carbon-components-react';

import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';

const NoJobBoardsBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no jobs boards currently associated.</p>
    </div>
  </Tile>
);

class ApplicationSettings extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            userName:"",
            password:"",
            apiUser:"",
            apiKey:"",
            selectedValue:"",
            showJobBoardsModal:false,
            headersInfo: [
                { key: 'name', header: 'Name' },
                { key: 'username', header: 'Username' },        
                { key: 'action', header: 'Action' },
              ],
            rowsData:[],
         }
    }

    componentDidMount(){
        const {apiKey, apiUser} = this.props;
        this.setState({apiKey, apiUser},()=>{
            const test = [{id:123,name:"Monster",username:"Sai@sai.com"},
                      {id:1234,name:"Indeed",username:"aSi@sai.com"},
                      {id:12345,name:"Dice",username:"aiS@sai.com"},
                      {id:123456,name:"Careerbuilder",username:"iSa@sai.com"}];
            this.prepareRowsData(test);
        });
    }

    onShowEditAuth = ()=>{
        this.props.showAuthModalfn();
    }

    onAddJobBoard = ()=>{
        this.setState({showJobBoardsModal:true})
    }

    onRemoveAuth = ()=>{
        console.log("work in progress");
    }

    prepareRowsData = (rows)=>{
        const rowsData = rows && Array.isArray(rows) && rows.map((row,i)=>{
            row.id = row.id === undefined ?row.id=i:row.id;
            return {
                id:row.id.toString(),
                name:row.name||"",
                username:row.username||"",
                action:<div className="bx--row ml-0 justify-content-center justify-content-lg-start">
                    <div className="mr-2"><Button kind="tertiary" small name={row.id} onClick={this.onAddJobBoard}>Edit</Button></div>
                    <div className=""><Button kind="tertiary" small name={row.id} onClick={this.onRemoveAuth}>Remove</Button></div>
                </div>
            }
        })
        this.setState({rowsData});
    }

    render() { 
        const {apiKey, apiUser, userName, rowsData, headersInfo, password, showJobBoardsModal} = this.state;
        const otherAction = [(<Button small onClick={this.onAddJobBoard}>Add Job Board</Button>)];
        const extras = {
            name:{className:"text-center",},
            username:{className:"text-center",},
            action:{className:"mt-2",},
        };
        return ( 
            <div className="ml-lg-3">
                {/* <div className="bx--row ml-0">
                    <div className="mt-2 mb-2 bx--type-epsilon">{`Auth Information`}</div>
                    <div className="mb-2 ml-2"><Button kind="tertiary" small onClick={this.onShowEditAuth} className="">Edit</Button></div>
                </div>
                <div className="mb-2">
                    <div className="bx--row ml-lg-0">
                        <div className="mr-1 bx--type-zeta mt-2">{`Username :`}</div>
                        <div className="bx--col-lg-4">
                            <div className="bx--type-zeta font-weight-normal mt-2">{"apiUser"}</div>
                        </div>
                    </div>
                    <div className="bx--row mt-3 ml-lg-0">
                        <div className="mr-1 mt-2 bx--type-zeta">{`API Token :`}</div>
                        <div className="bx--col-lg-4">
                            <div className="bx--type-zeta font-weight-normal mt-2">{"apiKey"}</div>
                        </div>
                    </div>
                </div>
                <hr/> */}
                <div className="mt-2">
                  <Modal
                    className=""
                    open={showJobBoardsModal}
                    modalHeading="Add Job Board"
                    primaryButtonText="Add"
                    secondaryButtonText="Cancel"
                    iconDescription="Close the modal"
                    onRequestClose={() => { this.setState({ showJobBoardsModal: false }) }}
                    onRequestSubmit={()=>{}}
                    onSecondarySubmit={()=>{}}
                  >
                <div className="">
                  <div className="mb-3">
                    <Select className="bx--col-xs-12 p-0" hideLabel onChange={({target:{value=""}}) => { this.setState({selectedValue:value}) }} id="select-1" defaultValue="placeholder">
                      <SelectItem disabled hidden value="placeholder" text="Select Job Board" />
                      <SelectItem value="Career Builder" text="Career Builder" />
                      <SelectItem value="Monster" text="Monster" />
                      <SelectItem value="Dice" text="Dice" />
                      <SelectItem value="Indeed" text="Indeed" />
                    </Select>
                  </div>
                  <div className="p-1 ml-lg-0 mb-3">
                      <div className="mr-2 bx--type-zeta mt-2">{`Username:`}</div>
                      <div className="mt-1">
                          <TextInput required id="userName1" name="userName" labelText="" placeholder="User Name" onChange={ev=>this.handleChange(ev)} value={userName} maxLength={50} />
                      </div>
                  </div>
                  <div className="p-1 ml-lg-0 mb-3">
                      <div className="mr-2 bx--type-zeta mt-2">{`Password:`}</div>
                      <div className="mt-1">
                          <TextInput required id="password" type="password" name="password" labelText="" placeholder="Password" onChange={ev=>this.handleChange(ev)} value={password} maxLength={50} />
                      </div>
                  </div>
                </div>
                </Modal>
                  <div className="mt-2 mb-2 bx--type-epsilon">{`Job Boards Linked`}</div>
                  {rowsData && Array.isArray(rowsData) && rowsData.length > 0 ? 
                    <GenericDataTable rows={rowsData} headers={headersInfo} rowClass="p-0" extras={extras} otherActions={otherAction}/>
                    : <NoJobBoardsBox />}
                </div>
            </div>
      );
    }
}


const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSettings);