import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EmailModal from 'components/roboroy/common/EmailModal';
import {
  Button,
  ToolbarItem,
  Modal,
  TextInput,
  DatePicker,
  DatePickerInput,
  Select,
  SelectItem,
  InlineNotification,
  RadioButton,
  RadioButtonGroup,
  TextArea,
} from 'carbon-components-react';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { sendEmailTemplate } from 'actions/email-templates';
import findAllUserJobApplicants from 'actions/talentSearch/applicantInfo';
import {
  createOfferDetails,
  editOfferDetails,
  getOfferDetails,
  getCurrencyValues,
  createOfferDocuments,
  uploadOfferDocs,
  deleteOfferDocumentsById,
} from 'actions/offerInformation';
import { bulkShortlistReject } from 'actions/talentSearch/candidatesList';
import OfferTable from 'components/roboroy/common/offerTable';
import {
  getAssetAnalytics,
  getJobAppliedCount,
} from 'actions/talentSearch/candidatesList';

const NoContentBox = ({
  activeAddOfferModal,
  collaboratorRes,
  activeCloseOfferModal,
  roleId,
  t,
  user,
  handleAddOfferModal,
}) => {
  const { isAdmin, isSuperAdmin } = user;
  const superAdmin = isSuperAdmin === true;
  const admin =
    isAdmin === true && user.currentCompany && user.currentCompany.ctypeId == 1;
  return (
    <div className="mt-2">
      <div className="text-center">
        <div className="empty-icon">
          <i className="fa fa-flag fa-2x" />
        </div>
        <p className="empty-title h5">{t('noOffers')}</p>
        {roleId && roleId !== 3 && (
          <div>
            <Button
              onClick={handleAddOfferModal}
              disabled={!(collaboratorRes.length > 0 || admin || superAdmin)}>
              {t('add')} {t('offer')}
            </Button>
            {/* <Button
                  className="ml-2"
                  onClick={activeCloseOfferModal}
                  disabled={(!(collaboratorRes.length > 0 || superAdmin || admin))}
                  kind="tertiary"
                >
                  {t('skip')} {t('offer')} {t('round')}
                </Button> */}
          </div>
        )}
      </div>
    </div>
  );
};

class OfferInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAddOfferModal: false,
      isOpenSendOfferModal: false,
      emailActionType: '',
      emailTemplate: '',
      editEmailBeforeSend: 'edit-email-no',
      notifShow: false,
      notifMsg: 'Default notification message',
      notifType: 'info',
      currOfferId: 0,
      offerName: null,
      position: '',
      salary: 0,
      joiningDate: '',
      currencyId: 0,
      salaryTypeId: 0,
      equityVestingInterval: 0,
      equity: 0,
      equityVestingPeriod: 0,
      weeklyHours: 40,
      managerName: '',
      sickDaysAllowance: 0,
      bonus: 0,
      isOpenCloseOfferModal: false,
      isReopenOfferModal: false,
      selectButton: '',
      modalError: false,
      finalOfferRejectReason: '',
      isPositionNameValid: false,
      isPositionNameValidText: '',
      isSalaryValid: false,
      isSalaryValidNumber: '',
      isCurrencySelectedValid: false,
      isCurrencySelectedValidText: '',
      isJoiningDateValid: false,
      isJoiningDateValidNumber: '',
      isSalaryTypeIdValid: false,
      isSalaryTypeIdValidNumber: '',
      isEquityValid: false,
      isEquityValidNumber: '',
      isEquityPeriodValid: false,
      isEquityPeriodValidNumber: '',
      isEquityVestingIntervalValid: false,
      isEquityVestingIntervalValidNumber: '',
      isFinalOfferRejectReasonValid: false,
      isFinalOfferRejectReasonValidText: '',
      inputValues: [],
      isValidTextInput: false,
      isValidTextInputContent: '',
      newDocuments: [],
      isOpenDeleteOfferDocModal: false,
      offerDocId: 0,
    };
  }

  componentDidMount() {
    const { applications: { applications = [] } = {} } = this.props;
    const { appId, profileId } = applications[0] || [];
    this.props.getOfferDetails(appId);
    this.props.getCurrencyValues();
    this.props.getJobAppliedCount(profileId);
  }

  handleAddInputValues = () => {
    this.setState({
      inputValues: this.state.inputValues.concat([{}]),
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDatePickerValue = (e, value) => {
    this.setState({
      joiningDate: value,
    });
  };

  activeAddOfferModal = offerId => {
    const {
      applications: { applications = [] } = {},
      offerDetails = [],
      jobInfo,
    } = this.props;
    const { profile: { firstName, lastName } = {} } = applications[0] || [];
    const { jobname: { nameName } = {}, maxSalary } = jobInfo || {};
    this.setState({
      isOpenAddOfferModal: true,
      currOfferId: offerId,
    });
    if (offerId) {
      const offerDetailsRes =
        offerDetails &&
        Array.isArray(offerDetails) &&
        offerDetails.length > 0 &&
        offerDetails.find(res => res.offerId == offerId);
      this.setState({
        offerName:
          offerDetailsRes && offerDetailsRes.name ? (
            offerDetailsRes.name
          ) : (
            <span>
              {`Offer #${offerDetails &&
                Array.isArray(offerDetails) &&
                offerDetails.length + 1}`}
            </span>
          ),
        position:
          offerDetailsRes && offerDetailsRes.position ? (
            offerDetailsRes.position
          ) : (
            <span>{nameName}</span>
          ),
        salary:
          offerDetailsRes && offerDetailsRes.salary ? (
            offerDetailsRes.salary
          ) : (
            <span>{maxSalary}</span>
          ),
        joiningDate:
          offerDetailsRes && offerDetailsRes.joiningDate
            ? offerDetailsRes.joiningDate
            : '',
        // equity: (offerDetailsRes && offerDetailsRes.equity) ? offerDetailsRes.equity : '',
        // equityVestingPeriod: (offerDetailsRes && offerDetailsRes.equityVestingPeriod) ? offerDetailsRes.equityVestingPeriod : '',
        currencyId:
          offerDetailsRes && offerDetailsRes.currencyId
            ? offerDetailsRes.currencyId
            : '',
        salaryTypeId:
          offerDetailsRes && offerDetailsRes.salaryTypeId
            ? offerDetailsRes.salaryTypeId
            : '',
        // equityVestingInterval: (offerDetailsRes && offerDetailsRes.equityVestingInterval) ? offerDetailsRes.equityVestingInterval : '',
        weeklyHours:
          offerDetailsRes && offerDetailsRes.weeklyHours
            ? offerDetailsRes.weeklyHours
            : '',
        managerName:
          offerDetailsRes && offerDetailsRes.managerName
            ? offerDetailsRes.managerName
            : '',
        sickDaysAllowance:
          offerDetailsRes && offerDetailsRes.sickDaysAllowance
            ? offerDetailsRes.sickDaysAllowance
            : '',
        // bonus: (offerDetailsRes && offerDetailsRes.bonus) ? offerDetailsRes.bonus : '',
      });
    } else {
      this.setState({
        position: nameName,
        salary: maxSalary,
      });
    }
  };

  activeCloseOfferModal = () => {
    this.setState({
      isOpenCloseOfferModal: true,
    });
  };

  dismissCloseOfferModal = () => {
    const { isFinalOfferRejectReasonValid } = this.state;
    if (isFinalOfferRejectReasonValid === true) {
      this.setState({
        isFinalOfferRejectReasonValid: false,
        isFinalOfferRejectReasonValidText: '',
      });
    }
    this.setState({
      isOpenCloseOfferModal: false,
      finalOfferRejectReason: '',
    });
  };

  activeReopenOfferModal = () => {
    this.setState({
      isReopenOfferModal: true,
    });
  };

  dismissReopenOfferModal = () => {
    this.setState({
      isReopenOfferModal: false,
    });
  };

  dismissAddOfferModal = () => {
    const {
      isPositionNameValid,
      isJoiningDateValid,
      isSalaryValid,
      isSalaryTypeIdValid,
      isCurrencySelectedValid,
      isEquityValid,
      isEquityPeriodValid,
      isEquityVestingIntervalValid,
    } = this.state;
    if (
      isPositionNameValid === true ||
      isJoiningDateValid === true ||
      isSalaryValid === true ||
      isSalaryTypeIdValid === true ||
      isCurrencySelectedValid === true ||
      isEquityValid === true ||
      isEquityPeriodValid === true ||
      isEquityVestingIntervalValid === true
    ) {
      this.setState({
        isPositionNameValid: false,
        isPositionNameValidText: '',
        isJoiningDateValid: false,
        isJoiningDateValidNumber: '',
        isSalaryValid: false,
        isSalaryValidNumber: '',
        isSalaryTypeIdValid: false,
        isSalaryTypeIdValidNumber: '',
        isCurrencySelectedValid: false,
        isCurrencySelectedValidText: '',
        isEquityValid: false,
        isEquityValidNumber: '',
        isEquityPeriodValid: false,
        isEquityPeriodValidNumber: '',
        isEquityVestingIntervalValid: false,
        isEquityVestingIntervalValidNumber: '',
      });
    }
    this.setState({
      isOpenAddOfferModal: false,
    });
  };

  handleChangeRadioButton = e => {
    switch (e) {
      case 'hired':
        this.setState({
          selectButton: false,
          modalError: false,
        });
        break;
      case 'not-hired':
        this.setState({
          selectButton: true,
          modalError: false,
        });
        break;
      default:
    }
  };

  handleReopenOfferModal = () => {
    const { applications: { applications = [] } = {} } = this.props;
    const { appId } = applications[0] || [];
    this.setState(
      {
        isHired: 're-open',
      },
      () => {
        const { isHired } = this.state;
        const bulkSrObj = {
          appId,
          isHired,
        };
        this.props.getAssetAnalytics(appId);
        this.props.bulkShortlistReject([bulkSrObj]).then(() => {
          this.props.findAllUserJobApplicants(appId);
          this.dismissReopenOfferModal();
          window.location.reload();
        });
      }
    );
  };

  handleCloseOfferModel = () => {
    const { selectButton } = this.state;
    const { applications: { applications = [] } = {}, t } = this.props;
    const { appId } = applications[0] || [];
    if (
      selectButton === null ||
      selectButton === undefined ||
      selectButton === ''
    ) {
      this.setState({
        modalError: true,
      });
    } else if (selectButton === false) {
      this.setState(
        {
          isHired: true,
        },
        () => {
          const { isHired } = this.state;
          const bulkSrObj = {
            appId,
            isHired,
          };
          this.props.getAssetAnalytics(appId);
          this.props.bulkShortlistReject([bulkSrObj]).then(() => {
            this.props.findAllUserJobApplicants(appId);
            this.dismissCloseOfferModal();
            window.location.reload();
          });
        }
      );
    } else {
      this.setState(
        {
          isHired: false,
        },
        () => {
          const { isHired, finalOfferRejectReason } = this.state;
          const bulkSrObj = {
            appId,
            isHired,
            finalOfferRejectReason,
          };
          if (
            finalOfferRejectReason === null ||
            finalOfferRejectReason === undefined ||
            finalOfferRejectReason === ''
          ) {
            this.setState({
              isFinalOfferRejectReasonValid: true,
              isFinalOfferRejectReasonValidText: `${t('offerMsg8')}`,
            });
          } else {
            this.props.getAssetAnalytics(appId);
            this.props.bulkShortlistReject([bulkSrObj]).then(() => {
              this.props.findAllUserJobApplicants(appId);
              this.dismissCloseOfferModal();
              window.location.reload();
            });
          }
        }
      );
    }
  };

  handleAddOfferModal = () => {
    const {
      offerName,
      position,
      salary,
      joiningDate,
      currencyId,
      equity,
      equityVestingPeriod,
      weeklyHours,
      managerName,
      salaryTypeId,
      equityVestingInterval,
      sickDaysAllowance,
      bonus,
      currOfferId,
    } = this.state;
    const {
      applications: { applications = [] } = {},
      jobInfo,
      offerDetails,
    } = this.props;
    const { jobname: { nameName } = {}, maxSalary } = jobInfo || {};
    const { appId, profile: { firstName, lastName } = {} } =
      applications[0] || [];
    const data = {
      appId,
      name: `Offer #${offerDetails &&
        Array.isArray(offerDetails) &&
        offerDetails.length + 1}`,
      position: nameName,
      salary: maxSalary || 0,
      // equity,
      // equityVestingPeriod,
      status: 'draft',
      // joiningDate,
      // currencyId,
      // salaryTypeId,
      // equityVestingInterval,
      // weeklyHours,
      // managerName,
      // sickDaysAllowance,
      // bonus,
    };
    // if (position === null || position === undefined || position === '') {
    //   this.setState({
    //     isPositionNameValid: true,
    //     isPositionNameValidText: 'Please enter valid position name',
    //   });
    // }
    // else if (salary === null || salary === undefined || salary === 0) {
    //   this.setState({
    //     isSalaryValid: true,
    //     isSalaryValidNumber: 'Please enter correct amount of salary',
    //   });
    // } else if (currencyId === null || currencyId === undefined || currencyId === 0) {
    //   this.setState({
    //     isCurrencySelectedValid: true,
    //     isCurrencySelectedValidText: 'Please select valid currency',
    //   });
    // }
    // else if (joiningDate === null || joiningDate === undefined || joiningDate === '') {
    //   this.setState({
    //     isJoiningDateValid: true,
    //     isJoiningDateValidNumber: 'Please select correct joining date',
    //   });
    // }
    // else if (salaryTypeId === null || salaryTypeId === undefined || salaryTypeId === 0) {
    //   this.setState({
    //     isSalaryTypeIdValid: true,
    //     isSalaryTypeIdValidNumber: 'Please select correct frequency',
    //   });
    // }
    // else if (!equity && equityVestingPeriod) {
    //   this.setState({
    //     isEquityValid: true,
    //     isEquityValidNumber: 'Please enter correct value',
    //   });
    // } else if ((equity === null || equity === undefined) && !equityVestingPeriod) {
    //   this.setState({
    //     isEquityPeriodValid: true,
    //     isEquityPeriodValidNumber: 'Please enter correct equity period',
    //   });
    // } else if (equity && equityVestingPeriod && equityVestingInterval == 0) {
    //   this.setState({
    //     isEquityVestingIntervalValid: true,
    //     isEquityVestingIntervalValidNumber: 'Please select correct frequency',
    //   });
    // }
    if (currOfferId && currOfferId !== 0) {
      const finalData = { ...data };
      finalData.offerId = currOfferId;
      this.props.getAssetAnalytics(appId);
      this.props.editOfferDetails(finalData).then(() => {
        this.dismissAddOfferModal();
        this.props.getOfferDetails(appId);
      });
    } else {
      this.props.getAssetAnalytics(appId);
      this.props.createOfferDetails(data).then(() => {
        this.dismissAddOfferModal();
        this.props.getOfferDetails(appId);
      });
    }
  };

  activeOfferSendModal = (applicationSourceName, appId, jobId, offerId) => {
    const { applications: { applications = [] } = {} } = this.props;
    if (applications && applications[0]) {
      const updatObj = {
        emailActionType: 'recruiter-offer-request',
        emailTemplate: 'recruiter-sends-offer-details',
        activeAppId: appId,
        activeJobId: jobId,
        activeApplication: applications[0] || {},
      };
      if (applicationSourceName === 'ai sourcing') {
        updatObj.editEmailBeforeSend = 'edit-email-yes';
      }
      this.setState(updatObj, () => {
        this.setState({
          isOpenSendOfferModal: true,
          currOfferId: offerId,
        });
      });
    }
  };

  dismissOfferSendModal = () => {
    this.setState({
      isOpenSendOfferModal: false,
      emailActionType: '',
      emailTemplate: '',
      notifShow: false,
      notifType: 'info',
      notifMsg: 'Default notification message',
      isSendingEmail: false,
      activeApplication: {},
    });
  };

  onEditEmailChange = ev => {
    this.setState({
      editEmailBeforeSend: ev,
    });
  };

  onViewEmail = () => {
    const params =
      'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
    window.open(
      '/emailpreview/recruiter-sends-offer-details',
      'Email Preview',
      params
    );
  };

  handleOfferSendRequest = () => {
    const { applications: { applications = [] } = {} } = this.props;
    const { appId, jobId, profile: { email } = {} } = (applications &&
      applications[0]) || [{}];
    const { editEmailBeforeSend, emailActionType, currOfferId } = this.state;
    const { push } = this.props;
    if (editEmailBeforeSend === 'edit-email-yes') {
      let d =
        push &&
        push(
          `/app/campus/emails/send/recruiter-sends-offer-details/${appId}/job/${jobId}`
        );
    } else {
      const sendEmailData = {
        payload: {
          appId: appId || 0,
          offerId: currOfferId || 0,
        },
        templateId: -1,
        actionName: 'send-email',
        templateName: 'recruiter-sends-offer-details',
        email,
        emailActionType,
      };
      this.setState(
        {
          isSendingEmail: true,
        },
        () => {
          this.props
            .sendEmailTemplate(sendEmailData)
            .then(res => {
              if (res && !res.error) {
                this.dismissOfferSendModal();
                window.location.reload();
              } else {
                console.error('Send email error:', res);
                const { message } = (res && res.error) || {};
                throw new Error(message || 'Error occured while sending email');
              }
            })
            .catch(err => {
              console.error(err);
              const { message } = err || {};
              this.setState({
                isSendingEmail: false,
                notifShow: true,
                notifType: 'error',
                notifMsg: message || 'Error occured while sending email',
              });
            });
        }
      );
    }
  };

  handleCloseNotifClick = () => {
    this.setState({
      modalError: false,
    });
  };

  onEmailTemplateChange = e => {
    const selectedEmailTemplate = e && e.target && e.target.value;
    if (!selectedEmailTemplate) return;
    this.setState({
      emailTemplate: selectedEmailTemplate,
    });
  };

  handleDeleteInputValues = index => {
    const { inputValues } = this.state;
    const filteredValues =
      inputValues &&
      Array.isArray(inputValues) &&
      inputValues.length > 0 &&
      inputValues.filter((vals, idx) => idx != index);
    this.setState({
      inputValues: filteredValues,
    });
  };

  activeDeleteOfferDocModal = docId => {
    this.setState({
      isOpenDeleteOfferDocModal: true,
      offerDocId: docId,
    });
  };

  dismissDeleteOfferDocModal = () => {
    this.setState({
      isOpenDeleteOfferDocModal: false,
    });
  };

  handleDeleteOfferDocumentModal = () => {
    const { applications: { applications = [] } = {} } = this.props;
    const { appId } = applications[0] || [];
    const { offerDocId } = this.state;
    this.props.deleteOfferDocumentsById(offerDocId).then(() => {
      this.props.getOfferDetails(appId);
      this.dismissDeleteOfferDocModal();
    });
  };

  handleUploadFile = (element, index) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('file', fileData[0]);
    this.props.uploadOfferDocs(currFile).then(res => {
      const { result: { files: { file } = {} } = {} } = res || {};
      const { providerResponse: { location } = {} } = (file && file[0]) || [];
      this.setState({
        [`uploadFile_${index}`]: location,
      });
    });
  };

  handleSubmitOfferDocuments = index => {
    const { t } = this.props;
    const { isValidTextInput, currOfferId } = this.state;
    if (
      this.state[`textInput_${index}`] === undefined ||
      this.state[`textInput_${index}`] === null ||
      this.state[`textInput_${index}`] === '' ||
      isValidTextInput === true
    ) {
      this.setState({
        isValidTextInput: true,
        isValidTextInputContent: `${t('offerMsg11')}`,
      });
    } else {
      let { newDocuments } = this.state;
      if (
        newDocuments &&
        Array.isArray(newDocuments) &&
        newDocuments.length <= 0
      ) {
        newDocuments = [
          {
            offerId: currOfferId,
            documentName: this.state[`textInput_${index}`],
            uploadLocation: this.state[`uploadFile_${index}`],
          },
        ];
      } else {
        newDocuments.push({
          offerId: currOfferId,
          documentName: this.state[`textInput_${index}`],
          uploadLocation: this.state[`uploadFile_${index}`],
        });
      }
      this.setState(
        {
          newDocuments,
        },
        () => {
          const { applications: { applications = [] } = {} } = this.props;
          const { appId } = applications[0] || [];
          if (
            this.state.newDocuments &&
            Array.isArray(this.state.newDocuments) &&
            this.state.newDocuments.length > 0
          ) {
            this.state.newDocuments.forEach(res => {
              const { offerId, documentName, uploadLocation } = res;
              const data = {
                offerId,
                documentName,
                uploadLocation,
              };
              this.props.createOfferDocuments(data).then(() => {
                this.props.getOfferDetails(appId);
                this.setState({
                  inputValues: [],
                  newDocuments: [],
                });
              });
            });
          }
        }
      );
    }
  };

  handleChangeInputValues = (e, index, offerId) => {
    this.setState(
      {
        [`textInput_${index}`]: e.target.value,
        currOfferId: offerId,
      },
      () => {
        if (
          this.state[`textInput_${index}`] === undefined ||
          this.state[`textInput_${index}`] === null ||
          this.state[`textInput_${index}`] === ''
        ) {
          this.setState({
            isValidTextInput: true,
          });
        } else {
          this.setState({
            isValidTextInput: false,
          });
        }
      }
    );
  };

  render() {
    const {
      applications: { applications = [] } = {},
      offerDetails,
      timeInterval,
      currencies,
      user: { userId, roleId, company: { ctypeId } = {} } = {},
      t,
      user,
      offerAcceptedCount,
    } = this.props;
    const {
      isOpenSendOfferModal,
      editEmailBeforeSend,
      notifShow,
      notifType,
      notifMsg,
      isSendingEmail,
      activeApplication,
      isOpenAddOfferModal,
      weeklyHours,
      offerName,
      position,
      salary,
      joiningDate,
      currencyId,
      equity,
      equityVestingPeriod,
      modalError,
      managerName,
      salaryTypeId,
      equityVestingInterval,
      sickDaysAllowance,
      bonus,
      emailTemplate,
      isOpenCloseOfferModal,
      selectButton,
      isPositionNameValid,
      isPositionNameValidText,
      isSalaryValid,
      isSalaryValidNumber,
      isCurrencySelectedValid,
      isCurrencySelectedValidText,
      isJoiningDateValid,
      isJoiningDateValidNumber,
      isSalaryTypeIdValid,
      isSalaryTypeIdValidNumber,
      isEquityValid,
      isEquityValidNumber,
      isEquityPeriodValid,
      isEquityPeriodValidNumber,
      isEquityVestingIntervalValid,
      isEquityVestingIntervalValidNumber,
      isFinalOfferRejectReasonValid,
      isFinalOfferRejectReasonValidText,
      isReopenOfferModal,
      finalOfferRejectReason,
      isValidTextInput,
      isValidTextInputContent,
      isOpenDeleteOfferDocModal,
    } = this.state;
    const {
      appId,
      jobId,
      applicationsource,
      applicationemail,
      offerConsentAccept,
      offerConsentSent,
      collaboration = [],
      isHired,
      finalOfferRejectReason: rejectReason,
      profile: { firstName, lastName } = {},
    } = (applications && applications[0]) || [{}];
    const { sourceName = '' } = applicationsource || {};
    const sendOfferResArr =
      applicationemail &&
      Array.isArray(applicationemail) &&
      applicationemail.filter(
        e => e.templateName === 'recruiter-sends-offer-details'
      );
    const applicationSourceName =
      (sourceName && ('' + sourceName).toLowerCase()) || '';
    const notifObj = { notifShow, notifType, notifMsg };
    const currName = `Offer #${offerDetails &&
      Array.isArray(offerDetails) &&
      offerDetails.length + 1}`;
    const collaboratorRes = collaboration.filter(
      res => res.userId == userId && (res.roleId == 1 || res.roleId == 4)
    );
    const { isAdmin, isSuperAdmin } = user;
    const superAdmin = isSuperAdmin === true;
    const admin = isAdmin === true;
    const columnWithFilter = [
      {
        title: `${t('offer')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('position')}`,
        className: 'text-center',
        name: 'position',
        propName: 'position',
      },
      // {
      //   title: `${t('joiningDate')}`,
      //   className: 'text-center',
      //   name: 'joiningDate',
      //   propName: 'joiningDate',
      // },
      // {
      //   title: `${t('salary')}`,
      //   className: 'text-center',
      //   name: 'salary',
      //   propName: 'salary',
      // },
      // {
      //   title: `${t('bonus')}`,
      //   className: 'text-center',
      //   name: 'jobId',
      //   propName: 'jobId',
      // },
      {
        title: `${t('status')}`,
        className: 'text-center',
        name: 'status',
        propName: 'status',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    const otherActionButtons = [];
    otherActionButtons.push(
      <div>
        {((roleId &&
          roleId !== 3 &&
          isHired !== true &&
          isHired !== false &&
          offerDetails &&
          Array.isArray(offerDetails) &&
          offerDetails[0] &&
          offerDetails[0].status &&
          offerDetails[0].status.toString() &&
          offerDetails[0].status.toLowerCase() === 'inprogress') ||
          (isHired !== true &&
            isHired !== false &&
            offerDetails &&
            Array.isArray(offerDetails) &&
            offerDetails.length > 0)) && (
          <ToolbarItem>
            <Button
              className="mr-2"
              disabled={offerAcceptedCount > 0}
              onClick={this.activeCloseOfferModal}
              kind="primary">
              {t('close')} {t('offer')}
            </Button>
          </ToolbarItem>
        )}
        {roleId && roleId !== 3 && isHired === null && (
          <ToolbarItem>
            <Button
              disabled={
                !(
                  offerDetails &&
                  Array.isArray(offerDetails) &&
                  offerDetails[0] &&
                  offerDetails[0].status &&
                  offerDetails[0].status.toString() &&
                  offerDetails[0].status.toLowerCase() === 'rejected'
                ) ||
                !(collaboratorRes.length > 0 || superAdmin || admin) ||
                offerAcceptedCount > 0
              }
              onClick={this.handleAddOfferModal}
              kind="primary">
              {t('add')} {t('offer')}
            </Button>
          </ToolbarItem>
        )}
        {roleId &&
          roleId !== 3 &&
          offerDetails &&
          Array.isArray(offerDetails) &&
          offerDetails.length > 0 &&
          isHired !== null && (
            <ToolbarItem>
              <Button
                disabled={
                  !(collaboratorRes.length > 0 || superAdmin || admin) ||
                  offerAcceptedCount > 0
                }
                className="mr-2"
                onClick={this.activeReopenOfferModal}
                kind="tertiary">
                {t('reOpen')} {t('offer')}
              </Button>
            </ToolbarItem>
          )}
      </div>
    );
    return (
      <Fragment>
        <div className="bx--type-delta">{t('offers')}</div>
        {offerAcceptedCount > 0 && (
          <InlineNotification
            lowContrast
            subtitle=""
            title="This candidate is no longer available."
            kind="error"
          />
        )}
        {/* {
          offerDetails && Array.isArray(offerDetails)
            && offerDetails.length > 0
            && offerDetails[0]
            && offerDetails[0].status
            && offerDetails[0].status.toString()
            && offerDetails[0].status.toLowerCase() === 'accepted'
            && isHired !== true
            && isHired !== false
            && (
              <InlineNotification lowContrast
                kind="success"
                subtitle=""
                title="Congratulations! The candidate has accepted your offer! Please click on Close Offer Round button to finish hiring."
                iconDescription="describes the close button"
                onCloseButtonClick={() => {}}
              />
            )
        }
        {
          isHired === true
          && (
            <InlineNotification lowContrast
              kind="success"
              subtitle=""
              title="Congrulations! This candidate has been hired!"
              iconDescription="describes the close button"
              onCloseButtonClick={() => {}}
            />
          )
        }
        {
           isHired === false
           && (
             <InlineNotification lowContrast
               kind="error"
               subtitle=""
               title="This candidate has marked as no hire!"
               iconDescription="describes the close button"
               onCloseButtonClick={() => {}}
             />
           )
        } */}
        {offerDetails &&
        Array.isArray(offerDetails) &&
        offerDetails.length > 0 &&
        roleId &&
        roleId !== 3 ? (
          <div>
            <OfferTable
              offerDetails={offerDetails}
              otherActionButtons={otherActionButtons}
              currencies={currencies}
              timeInterval={timeInterval}
              columnWithFilter={columnWithFilter}
              applications={applications}
              activeOfferSendModal={this.activeOfferSendModal}
              activeAddOfferModal={this.activeAddOfferModal}
              roleId={roleId}
              offerAcceptedCount={offerAcceptedCount}
              collaboratorRes={collaboratorRes}
              activeAcceptOfferModal={() => {}}
              handleAddInputValues={this.handleAddInputValues}
              inputValues={this.state.inputValues}
              state={this.state}
              handleDeleteInputValues={this.handleDeleteInputValues}
              handleChangeInputValues={this.handleChangeInputValues}
              isValidTextInput={isValidTextInput}
              isValidTextInputContent={isValidTextInputContent}
              handleUploadFile={this.handleUploadFile}
              handleSubmitOfferDocuments={this.handleSubmitOfferDocuments}
              activeDeleteOfferDocModal={this.activeDeleteOfferDocModal}
              user={user}
            />
          </div>
        ) : (
          <div className="text-center">
            {roleId === 3 && (
              <Button
                href={`/app/campus/candidate/applications/${appId}/offers`}
                kind="tertiary"
                small>
                {t('view')} {t('offer')}
              </Button>
            )}
          </div>
        )}

        {isHired === null &&
          offerConsentAccept === true &&
          offerConsentSent === true &&
          (offerDetails &&
            Array.isArray(offerDetails) &&
            offerDetails.length <= 0 && (
              <div>
                <NoContentBox
                  activeAddOfferModal={() => this.activeAddOfferModal(0)}
                  collaboratorRes={collaboratorRes}
                  activeCloseOfferModal={this.activeCloseOfferModal}
                  roleId={roleId}
                  t={t}
                  handleAddOfferModal={this.handleAddOfferModal}
                  user={this.props.user}
                />
              </div>
            ))}
        {isHired !== null &&
          offerConsentAccept &&
          offerConsentSent &&
          offerConsentAccept === true &&
          offerConsentSent === true &&
          offerDetails &&
          Array.isArray(offerDetails) &&
          roleId &&
          roleId !== 3 &&
          offerDetails.length <= 0 && (
            <section className="bx--structured-list">
              <div className="bx--structured-list-tbody">
                <div className="bx--structured-list-row">
                  <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">
                    {t('action')}
                  </div>
                  <div className="bx--structured-list-td">
                    <Button
                      disabled={
                        !(collaboratorRes.length > 0 || superAdmin || admin)
                      }
                      className="mr-2"
                      onClick={this.activeReopenOfferModal}
                      kind="tertiary"
                      small>
                      {t('reOpen')} {t('offer')}
                    </Button>
                  </div>
                </div>
                {isHired === false && rejectReason !== null && (
                  <div className="bx--structured-list-row">
                    <div className="bx--structured-list-td bx--structured-list-content--nowrap text-dark bx--modal-header__label">
                      {t('reject')} {t('reason')}
                    </div>
                    <div className="bx--structured-list-td">{rejectReason}</div>
                  </div>
                )}
              </div>
            </section>
          )}
        {isHired !== null &&
          offerConsentAccept &&
          offerConsentSent &&
          offerConsentAccept === true &&
          offerConsentSent === true &&
          offerDetails &&
          Array.isArray(offerDetails) &&
          roleId &&
          roleId === 3 &&
          offerDetails.length <= 0 && (
            <div className="font-weight-bold text-dark mt-1">
              {t('offerMsg1')}
            </div>
          )}
        {isHired !== true && (!offerConsentAccept || !offerConsentSent) && (
          <span className="text-dark col-12 pt-2">{t('offerMsg2')}</span>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
        <Modal
          open={isOpenAddOfferModal}
          shouldSubmitOnEnter
          modalHeading={`${t('add')} ${t('offer')} ${t('details')}`}
          primaryButtonText={t('add')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissAddOfferModal}
          onRequestSubmit={this.handleAddOfferModal}>
          <div>
            {/* <TextInput
              name="offerName"
              className="mb-2"
              labelText={`${t('offer')} ${t('name')}`}
              onChange={(e) => { this.handleChange(e); }}
              value={offerName === null ? currName : offerName}
            /> */}
            <div className="w-50">
              <TextInput
                name="position"
                className="mb-2"
                placeholder={`${t('enter')} ${t('the')} ${t('position')}`}
                labelText={t('position')}
                invalid={isPositionNameValid}
                invalidText={isPositionNameValidText}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={position}
              />
            </div>
            <div className="d-flex mb-2 ml-2">
              <DatePicker
                id="date-picker"
                onChange={(e, i) => {
                  this.handleDatePickerValue(e, i);
                }}
                datePickerType="single">
                <DatePickerInput
                  className="w-100"
                  name="joiningDate"
                  id="date-picker-input-id"
                  labelText={t('startDate')}
                  pattern="d{1,2}/d{4}"
                  placeholder="mm/dd/yyyy"
                  invalid={isJoiningDateValid}
                  invalidText={isJoiningDateValidNumber}
                  onClick={() => {}}
                  onChange={() => {}}
                  value={joiningDate}
                />
              </DatePicker>
            </div>
            <div className="w-50">
              <TextInput
                name="managerName"
                className="mb-2"
                placeholder={`${t('enter')} ${t('the')} ${t('managerName')}`}
                labelText={t('managerName')}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={managerName}
              />
            </div>
            {/* <div className="d-flex align-items-center">
              <div className="mr-2">
                <TextInput
                  name="salary"
                  className="mb-2"
                  labelText={t('salary')}
                  placeholder="10000"
                  type={t('salary')}
                  invalid={isSalaryValid}
                  invalidText={isSalaryValidNumber}
                  onChange={(e) => { this.handleChange(e); }}
                  value={salary ? salary : ''}
                />
              </div>
              <div className="mt-4 mr-2">
                {
                  <Select
                    name="currencyId"
                    hideLabel
                    id="current-currency-id"
                    invalid={isCurrencySelectedValid}
                    invalidText={isCurrencySelectedValidText}
                    onChange={(e) => { this.handleChange(e); }}
                    value={currencyId}
                  >
                    <SelectItem text={t('currencyHolder')} />
                    {
                      currencies && Array.isArray(currencies) && currencies.map(res => (
                        <SelectItem key={res.currencyId} text={`${res.currencyShort}`} value={res.currencyId} />
                      ))
                    }
                  </Select>
                  }
              </div>
              <div className="mt-4">
                {
                  <Select
                    name="salaryTypeId"
                    hideLabel
                    id="current-currency-id"
                    invalid={isSalaryTypeIdValid}
                    invalidText={isSalaryTypeIdValidNumber}
                    onChange={(e) => { this.handleChange(e); }}
                    value={salaryTypeId}
                  >
                    <SelectItem text={t('frequencyHolder')} />
                    {
                      timeInterval && Array.isArray(timeInterval) && timeInterval.map(res => (
                        <SelectItem key={res.intervalId} text={`${res.intervalType}`} value={res.intervalId} />
                      ))
                    }
                  </Select>
                  }
              </div>
            </div> */}
            {/* <div className="d-flex align-items-center">
              <div className="mr-2">
                <TextInput
                  name="equity"
                  className="mb-2"
                  labelText={t('equity')}
                  placeholder="1"
                  onChange={(e) => { this.handleChange(e); }}
                  invalid={isEquityValid}
                  invalidText={isEquityValidNumber}
                  value={equity ? equity : ''}
                />
              </div>
              <div className="mr-2">
                <TextInput
                  name="equityVestingPeriod"
                  className="mb-2"
                  labelText={t('equityPeriod')}
                  placeholder="4"
                  invalid={isEquityPeriodValid}
                  invalidText={isEquityPeriodValidNumber}
                  onChange={(e) => { this.handleChange(e); }}
                  value={equityVestingPeriod ? equityVestingPeriod : ''}
                />
              </div>
              <div className="mt-4">
                {
                  <Select
                    name="equityVestingInterval"
                    hideLabel
                    id="current-currency-id"
                    onChange={(e) => { this.handleChange(e); }}
                    invalid={isEquityVestingIntervalValid}
                    invalidText={isEquityVestingIntervalValidNumber}
                    value={equityVestingInterval}
                  >
                    <SelectItem text={t('frequencyHolder')} />
                    {
                      timeInterval && Array.isArray(timeInterval) && timeInterval.map(res => (
                        <SelectItem key={res.intervalId} text={`${res.intervalType}`} value={res.intervalId} />
                      ))
                    }
                  </Select>
                  }
              </div>
            </div> */}
            {/* <TextInput
              name="bonus"
              className="mb-2"
              type="number"
              labelText={t('bonus')}
              placeholder="1000"
              onChange={(e) => { this.handleChange(e); }}
              value={bonus ? bonus : ''}
            /> */}
            {/* <div className="d-flex">
              <TextInput
                name="weeklyHours"
                className="mb-2"
                labelText={t('hoursInWeek')}
                type="number"
                value={weeklyHours}
                onChange={(e) => { this.handleChange(e); }}
              />
              <TextInput
                name="sickDaysAllowance"
                className="mb-2 ml-2"
                labelText={t('sickDays')}
                placeholder="2"
                type="number"
                value={sickDaysAllowance ? sickDaysAllowance : ''}
                onChange={(e) => { this.handleChange(e); }}
              />
            </div> */}
          </div>
        </Modal>
        <Modal
          open={isOpenCloseOfferModal}
          shouldSubmitOnEnter
          modalHeading={`${t('close')} ${t('offer')}`}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissCloseOfferModal}
          onRequestSubmit={this.handleCloseOfferModel}>
          <div>
            {offerDetails &&
              Array.isArray(offerDetails) &&
              offerDetails.length > 0 &&
              offerDetails[0] &&
              offerDetails[0].status &&
              offerDetails[0].status.toString() &&
              offerDetails[0].status.toLowerCase() !== 'accepted' && (
                <InlineNotification
                  lowContrast
                  kind="warning"
                  subtitle=""
                  title={t('offerMsg3')}
                  iconDescription="describes the close button"
                  onCloseButtonClick={() => {}}
                />
              )}
            {modalError && (
              <InlineNotification
                lowContrast
                kind="error"
                subtitle=""
                title={t('offerMsg4')}
                iconDescription="describes the close button"
                onCloseButtonClick={this.handleCloseNotifClick}
              />
            )}
            <div className="bx--label mb-0">{t('offerMsg5')}</div>
            <div className="text-dark">{t('offerMsg6')}</div>
            <div>
              <RadioButtonGroup
                defaultSelected="hired"
                selected="hired"
                legend="Group Legend"
                name="radio-button-group"
                valueSelected="default-selected"
                onChange={e => this.handleChangeRadioButton(e)}>
                <RadioButton
                  value="hired"
                  disabled={
                    offerDetails &&
                    Array.isArray(offerDetails) &&
                    offerDetails.length > 0 &&
                    offerDetails[0] &&
                    offerDetails[0].status &&
                    offerDetails[0].status.toString() &&
                    offerDetails[0].status.toLowerCase() !== 'accepted'
                  }
                  checked
                  id="radio-1"
                  labelText={t('yes')}
                />
                <RadioButton
                  value="not-hired"
                  id="radio-2"
                  labelText={t('no')}
                />
              </RadioButtonGroup>
            </div>
            <div>
              {selectButton && selectButton === true && (
                <TextArea
                  labelText={t('offerMsg7')}
                  name="finalOfferRejectReason"
                  id="test2"
                  invalid={isFinalOfferRejectReasonValid}
                  invalidText={isFinalOfferRejectReasonValidText}
                  onChange={e => {
                    this.handleChange(e);
                  }}
                  value={finalOfferRejectReason}
                />
              )}
            </div>
          </div>
        </Modal>
        <Modal
          open={isReopenOfferModal}
          shouldSubmitOnEnter
          modalHeading={`${t('reOpen')} ${t('offer')}`}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissReopenOfferModal}
          onRequestSubmit={this.handleReopenOfferModal}>
          <div className="text-dark bx--type-zeta">{t('offerMsg9')}</div>
        </Modal>
        <Modal
          open={isOpenDeleteOfferDocModal}
          modalHeading={`${t('delete')} ${t('note')}`}
          danger
          primaryButtonText={t('delete')}
          secondaryButtonText={t('cancel')}
          onRequestClose={this.dismissDeleteOfferDocModal}
          onRequestSubmit={this.handleDeleteOfferDocumentModal}>
          <p>{t('offerMsg10')}</p>
        </Modal>
        <EmailModal
          actionType="recruiter-offer-request"
          open={isOpenSendOfferModal}
          onRequestSubmit={this.handleOfferSendRequest}
          onRequestClose={this.dismissOfferSendModal}
          onEditEmailChange={this.onEditEmailChange}
          editEmailBeforeSend={editEmailBeforeSend}
          onViewEmail={this.onViewEmail}
          isLoading={isSendingEmail}
          notifObj={notifObj}
          application={activeApplication}
          onEmailTemplateChange={this.onEmailTemplateChange}
          emailTemplate={emailTemplate}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.OfferInformation.loading,
  error: state.OfferInformation.error,
  offerDetails: state.OfferInformation.offerDetails,
  timeInterval: state.OfferInformation.timeInterval,
  currencies: state.OfferInformation.currencies,
  offerAcceptedCount: state.roboroyApplications.offerAcceptedCount,
});

const mapDispatchToProps = {
  sendEmailTemplate,
  createOfferDetails,
  editOfferDetails,
  getOfferDetails,
  bulkShortlistReject,
  getCurrencyValues,
  findAllUserJobApplicants,
  getAssetAnalytics,
  createOfferDocuments,
  uploadOfferDocs,
  deleteOfferDocumentsById,
  getJobAppliedCount,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OfferInformation));
