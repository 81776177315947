import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import BigCalendar from 'react-big-calendar';
import { connect } from 'react-redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {
  getCalendarEvents,
  getCalendarEventById,
} from 'actions/calendarEvents';
import {
  Modal, Tile, Button,
} from 'carbon-components-react';
import BackRow from 'components/common/BackRow/BackRow';

class CalendarEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenSelectedEventModal: false,
      events: [],
      selectedEvent: '',
    };
  }

  componentDidMount() {
    this.props.getCalendarEvents().then(() => {
      this.handleEvents();
    });
  }

  activeSelectedEventModal = (event) => {
    this.setState({
      isOpenSelectedEventModal: true,
      selectedEvent: event.id,
    }, () => {
      const eventId = this.state.selectedEvent;
      this.props.getCalendarEventById(eventId);
    });
  }

  dismissSelectedEventModal = () => {
    this.setState({
      isOpenSelectedEventModal: false,
    });
  }

  handleEvents = () => {
    const { calendarEvents } = this.props;
    const allEvents = calendarEvents && Array.isArray(calendarEvents)
    && calendarEvents.length > 0
    && calendarEvents.map((res) => {
      return {
        id: res && res.eventId,
        title: res && res.summary,
        start: res && res.start && moment(res.start).toDate(),
        end: res && res.end && moment(res.end).toDate(),
        stageTypeName: (res.workflowapplicationstage
        && res.workflowapplicationstage.workflowstage
        && res.workflowapplicationstage.workflowstage.workflowstagetype
        && res.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName),
        allDay: false,
      };
    });
    this.setState({
      events: allEvents,
    });
  }

  handleSelectedEvent = (event) => {
    const { stageTypeName } = event;
    let backgroundColor = '';
    if (stageTypeName && stageTypeName.toString() && stageTypeName.toLowerCase() === 'phone interview') {
      backgroundColor = '#ffa600';
    } else if (stageTypeName && stageTypeName.toString() && stageTypeName.toLowerCase() === 'onsite interview') {
      backgroundColor = '#a05195';
    } else if (stageTypeName && stageTypeName.toString() && stageTypeName.toLowerCase() === 'online interview') {
      backgroundColor = '#003f5c';
    } else {
      backgroundColor = '#ff654f';
    }
    const style = {
      backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      fontWeight: '700',
    };
    return {
      style,
    };
  }

  handleViewApplication = (application) => {
    const { appId, jobId } = application;
    this.props.history.push(`candidate/${appId}/job/${jobId}`);
  }

  render() {
    const { calendarEvents, eventInfo, t } = this.props;
    const { isOpenSelectedEventModal, selectedEvent, events } = this.state;
    const localizer = BigCalendar.momentLocalizer(moment);
    const paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus' },
      { id: 'i-calendar', name: 'Interview Calendar', href: '' }
    ];    
    return (
      <Fragment>
        <BackRow paths={paths} showGuidedTour={false}/>
        <div className="h4 bx--ty-gamma font-weight-bold mb-2">{t('interview')} {t('calendar')}</div>
        <p>
          {t('calendarEventsMsg')}
        </p>
        <Tile className="mt-2">
          <div style={{ height: '800px' }}>
            <BigCalendar
              selectable
              localizer={localizer}
              events={events || []}
              views={['month', 'week', 'day']}
              defaultView="day"
              onSelectEvent={(event) => { this.activeSelectedEventModal(event); }}
              startAccessor="start"
              endAccessor="end"
              eventPropGetter={(e) => this.handleSelectedEvent(e)}
            />
          </div>
        </Tile>
        {/* <Tile className="mt-2">
          <div className="h5">Legend</div>
          <div>
            <span className="small-cir" style={{ backgroundColor: '#a05195' }} />Onsite Interview
            <span className="bg-yellow small-cir" />Phone Interview
            <span className="small-cir" style={{ backgroundColor: '#003f5c' }} />Online Interview
            <div className="small-cir" style={{ backgroundColor: '#ff654f' }} />Deleted Candidates
          </div>
        </Tile>         */}
        <Modal
          open={isOpenSelectedEventModal}
          shouldSubmitOnEnter
          passiveModal
          onRequestClose={this.dismissSelectedEventModal}
        >
          <div className="mt-0">
            {
              eventInfo && eventInfo !== null
                && (
                  <div>
                    <div className="h6"><strong className="text-capitalize">{eventInfo.summary}</strong></div>
                    <div>
                      {
                        eventInfo.workflowapplicationstage
                          && eventInfo.workflowapplicationstage.workflowstage
                          && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype
                          && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName
                          && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName.toString()
                          && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName.toLowerCase() === 'phone interview'
                          ? (
                            <strong className="badge p-1" style={{ backgroundColor: '#ffa600' }}>{eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName}</strong>
                          ) : (
                            eventInfo.workflowapplicationstage
                              && eventInfo.workflowapplicationstage.workflowstage
                              && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype
                              && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName
                              && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName.toString()
                              && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName.toLowerCase() === 'onsite interview'
                              ? (
                                <strong className="badge p-1" style={{ backgroundColor: '#a05195' }}>{eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName}</strong>
                              ) : (
                                eventInfo.workflowapplicationstage
                              && eventInfo.workflowapplicationstage.workflowstage
                              && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype
                              && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName
                              && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName.toString()
                              && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName.toLowerCase() === 'online interview'
                              && (<strong className="badge p-1" style={{ backgroundColor: '#003f5c' }}>{eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName}</strong>)
                              )
                          )
                      }
                    </div>
                      {
                        eventInfo.start && eventInfo.end
                          && (
                            <div className="text-dark">
                              <div><strong>{moment(eventInfo.start).format('MMMM Do YYYY')}</strong></div>
                              <div>
                                <strong>{moment(eventInfo.start).format('h:mm a')} to {moment(eventInfo.end).format('h:mm a')}</strong>
                              </div>
                            </div>
                          )
                      }
                      {
                        eventInfo.meetingId !== null
                          && eventInfo.zoommeetings
                          && eventInfo.zoommeetings.startUrl
                          && eventInfo.zoommeetings.password
                          && (
                            <div>
                              <strong>
                                <a href={eventInfo.zoommeetings.startUrl} target="_blank" >Join Meeting</a>
                              </strong>
                              <div>
                                <strong>Password: {eventInfo.zoommeetings.password}</strong>
                              </div>
                            </div>
                          )
                      }
                      {
                        eventInfo.workflowapplicationstage
                        && eventInfo.workflowapplicationstage.workflowstage
                        && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype
                        && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName
                        && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName.toString()
                        && eventInfo.workflowapplicationstage.workflowstage.workflowstagetype.stageTypeName.toLowerCase() === 'phone interview'
                        && (
                          eventInfo.application
                                && eventInfo.application.personmobile
                                && eventInfo.application.personmobile.mobile
                                && eventInfo.application.personmobile.country
                                && (
                                  <strong>{eventInfo.application.personmobile.country.countryCode ? `(${(eventInfo.application.personmobile.country.countryCode)})` : null}{eventInfo.application.personmobile.mobile}</strong>
                                )
                        )
                      }
                    <div>
                      <Button
                        kind="tertiary"
                        small
                        onClick={() => this.handleViewApplication(eventInfo && eventInfo.application)}
                      >
                        View Application
                      </Button>
                      {
                        !eventInfo.workflowapplicationstage
                          && (
                            <div>
                              <p className="text-danger mt-1">The candidate has choosen to delete this application information.</p>
                            </div>
                          )
                      }
                    </div>
                  </div>
                )
            }
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.CalendarEvents.loading,
  error: state.CalendarEvents.error,
  calendarEvents: state.CalendarEvents.calendarEvents,
  eventInfo: state.CalendarEvents.eventInfo,
});

const mapDispatchToProps = {
  getCalendarEvents,
  getCalendarEventById,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalendarEvents));
