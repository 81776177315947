import {
    REQUEST_GET_USER_EMAIL_INFO,
    SUCCESS_GET_USER_EMAIL_INFO,
    ERROR_GET_USER_EMAIL_INFO,
    REQUEST_USER_EMAIL_INFO,
    SUCCESS_USER_EMAIL_INFO,
    ERROR_USER_EMAIL_INFO
} from '../actions/actionTypes';

const initialState = {
    userEmailConfirmation: [],
    userEmailInfo: {}
}

const userEmailConfirmation = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {

    case REQUEST_USER_EMAIL_INFO: {
      return {
        ...state,
        loading: true,
      }
    };
    case SUCCESS_USER_EMAIL_INFO: {
      return {
        ...state,
        loading: false,
        userEmailInfo: payload,
      }
    };
    case ERROR_USER_EMAIL_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_USER_EMAIL_INFO: {
      return {
        ...state,
        loading: true,
      }
    };
    case SUCCESS_GET_USER_EMAIL_INFO: {
      return {
        ...state,
        loading: false,
        userEmailConfirmation: payload,
      }
    };
    case ERROR_GET_USER_EMAIL_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

export default userEmailConfirmation;