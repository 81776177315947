import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    Tile, Checkbox, Button, Modal,
} from 'carbon-components-react';
 import './style.css';
 import queryString from 'query-string';

 import { getFacets } from 'actions/leftNavFilter';
import { 
  REQUEST_FACETS_DATA,
  REQUEST_FACETS_RESULT,} from 'actions/actionTypes';

class LeftNavFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      selected: {},
      moreChecked: [],
      moreSelected: [],
      isModal: false,
      modalSelected: [],
      Selected: '',
    };
  }
  
  componentDidMount() {
    this.props.getFacets({ value: this.props.url }).then(() => {
      this.setChecked();
    });
  }

  setChecked = () => {
    const { checked, selected } = this.checkedFromUrl();
    this.setState({ checked, selected });
  }

  checkedFromUrl = () => {
    const filter = queryString.parse(this.props.url);
    const Propertykey = Object.keys(filter)[0];
    const checkedData = JSON.parse(filter[Propertykey]) || [];
    const checkedValue = [];
    checkedData.map((one) => {
      if (Object.keys(one).length > 0) {
        Object.keys(one).map(val => checkedValue.push(one[val]));
      }
    });
    const selected = [];
    checkedData.map(one => Object.keys(one).map(val => selected[val] = one[val]));
    const checked = [].concat.apply([], checkedValue);
    return ({ checked, selected });
  }
  
  updateAll = () => {
    const { selected, checked } = this.state;
    let temp = selected;
    Object.keys(selected).map(ele => (selected[ele].length === 0 ? delete temp[ele] : ele));
    let filter = [];
    Object.keys(temp).map((ele) => {
      let oneData = {};
      oneData[ele] = temp[ele];
      filter.push(oneData);
    });
    const update = new Promise((res, rej) => { this.props.setSelected(temp, filter, checked);res();});
    update.then(() => this.props.getFacets({ value: this.props.url }));
  }
  
  handleCheck = (value, ID, e) => {
    const name = e.target.name;
    const id = ID.split('_')[0];
    let { checked, selected, moreChecked, moreSelected, isModal } = this.state;
    if (isModal == false) {
      checked.includes(name) ? checked = checked.filter(ele => ele != name) : checked.push(name);
      if (selected[id]) {
        selected[id].includes(name) ? selected[id] = selected[id].filter(ele => ele != name) : selected[id].push(name);
      } else {
        selected[id] = [];
        selected[id].push(name);
      }
      this.setState({ checked, selected }, () => { this.updateAll() });
    } else {
      moreChecked.includes(name) ? moreChecked = moreChecked.filter(ele => ele != name) : moreChecked.push(name);
      if (moreSelected[id]) {
        moreSelected[id].includes(name) ? moreSelected[id] = moreSelected[id].filter(ele => ele != name) : moreSelected[id].push(name);
      } else {
        moreSelected[id] = [];
        moreSelected[id].push(name);
      }
      this.setState({ moreChecked, moreSelected });
    }
  }

  onMoreApply = () => {
    let { checked, selected, moreChecked, moreSelected, isModal, modalSelected, Selected } = this.state;
    isModal = !isModal;
    checked = Array.from(moreChecked);
    selected = moreSelected;
    moreChecked = [];
    moreSelected = {};
    modalSelected = [];
    Selected = '';
    this.setState({ checked, isModal, selected, moreChecked, moreSelected, modalSelected, Selected }, () => { this.updateAll() });
  }

  onMore = (e) => {
    let { checked, selected, moreChecked, moreSelected,
      Selected, isModal, modalSelected } = this.state;
    if (isModal == false) {
      moreChecked = Array.from(checked);
      Selected = e.target.name;
      modalSelected = Array.from(this.props.facets[Selected]);
      moreSelected = selected;
      this.setState({ moreChecked, moreSelected, modalSelected, Selected, isModal: true });
    }
    else {
      moreChecked = [];
      moreSelected = {};
      modalSelected = [];
      Selected = '';
      this.setState({ moreChecked, moreSelected, modalSelected, Selected, isModal: false });
    }
  }

  componentDidUpdate() {
    const { checked, selected } = this.checkedFromUrl();
    if (this.state.checked.filter(one => !checked.includes(one)).length !== 0 || checked.filter(one => !this.state.checked.includes(one)).length !== 0) {
      this.setState({ checked, selected });
    }
  }

  render() {
    const { props } = this;
    const { t } = props;
    return (
      <div className="leftNavFilter bx--col-xs-12 bx--col-sm-12 bx--col-lg-3 p-0 mt-sm-2 mb-sm-2 p-lg-1 mr-lg-2 m-0">
        <Tile className="p-3">
          <p className="bx--modal-header__label bx--type-delta h6 font-weight-bold mb-3">{t('searchFilters')}</p>
          <div className="mt-0">
            {props.facets && Object.keys(props.facets).map((ele, pos) => {
              if (props.facets && Array.isArray(props.facets[ele]) && props.facets[ele].length > 0) {
                return (
                  <fieldset className="bx--fieldset mb-2" key={pos}>
                    {
                      ele !== 'JobType' && ele !== 'salaryRange' && ele !== 'commuteTime'
                        && ele !== 'Jobname'
                        && (
                          <div className="font-weight-bold text-uppercase mb-1">{ele}</div>
                        )
                    }
                    {
                      ele === 'JobType' && (
                        <div className="font-weight-bold text-uppercase mb-1">Job Type</div>
                      )
                    }
                    {
                      ele === 'salaryRange' && (
                        <div className="font-weight-bold text-uppercase mb-1">Salary Range</div>
                      )
                    }
                    {
                      ele === 'commuteTime' && (
                        <div className="font-weight-bold text-uppercase mb-1">Commute Time (in mins)</div>
                      )
                    }
                    {
                      ele === 'Jobname' && (
                        <div className="font-weight-bold text-uppercase mb-1">Job Name</div>
                      )
                    }
                    {props.facets && Array.isArray(props.facets[ele]) && props.facets[ele].map((filter, index) => {
                      if (index < 5) {
                        return (
                          <Checkbox
                            className={`${ele}_${index}`}
                            key={index}
                            checked={this.state.checked.includes(`${filter.value ? filter.value : filter.key}`)}
                            wrapperClassName="mb-0 pb-0"
                            title={filter.key}
                            className="text-capitalize"
                            labelText={<div className="row ml-0"><div className="checkBoxElepsis mr-1">{filter.key}</div>
                            {
                              filter && filter.doc_count ? (
                                <div className="text-dark">{`(${filter.doc_count})`}</div>
                              ) : (
                                ele !== 'commuteTime' && (
                                  <div className="text-dark">
                                  (0)
                                </div>
                                )
                              )
                            }</div>}
                            disabled={false}
                            hideLabel={false}
                            name={`${filter.value ? filter.value : filter.key}`}
                            onChange={this.handleCheck}
                            id={`${ele}_${index}`}
                          />
                        );
                      }
                    })}
                    <div className="m-0 bx--label text-info">
                      <button className="bx--btn bx--btn--ghost bx--btn--sm" onClick={this.onMore} name={ele} type="button">{t('more')}...</button>
                    </div>
                  </fieldset>
                );
              }
            })}
          </div>
          <Modal 
            open={this.state.isModal}
            modalHeading={this.state.Selected}
            primaryButtonText={t('apply')}
            secondaryButtonText={t('cancel')}
            onBlur={() => { }}
            onRequestClose={this.onMore}
            onRequestSubmit={this.onMoreApply}
            onSecondarySubmit={this.onMore}
          >
            {this.state.modalSelected && Array.isArray(this.state.modalSelected) && this.state.modalSelected.map((filter, index) => {
              return (
                index > 4 && (
                  <div className="text-capitalize">
                    <Checkbox
                      key={index}
                      className={`${this.state.Selected}_${index}`}
                      checked={this.state.moreChecked.includes(`${filter.key}`)}
                      wrapperClassName="mb-0 pb-0"
                      title={filter.key}
                      labelText={<div className="row ml-0"><div className="checkBoxElepsis mr-1">{`${filter.key}`}</div><div className="text-dark">{`(${filter.doc_count})`}</div></div>}
                      disabled={false}
                      hideLabel={false}
                      name={`${filter.key}`}
                      onChange={this.handleCheck}
                      id={`${this.state.Selected}_${index}`}
                    />
                  </div>
                )
              );
            })}
          </Modal>
          <div className="d-block text-center">
            <Button
              className="clearfilter justify-content-center"
              small
              kind="primary"
              onClick={() => (this.setState({ checked: [], selected: [] }, () => { this.updateAll() }))}
            >
              {t('clearFilters')}
            </Button>
          </div>
        </Tile>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  facets: state.Filters && state.Filters.facets ? state.Filters.facets : [],
});

const mapDispatchToProps = {
  getFacets,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeftNavFilter));
