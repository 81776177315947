import {
  REQUEST_RESUME_UPLOAD,
  ERROR_RESUME_UPLOAD,
  SUCCESS_RESUME_UPLOAD,
} from '../../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  taskId: '',
};

const resumeUpload = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_RESUME_UPLOAD:
      return {
        ...state,
        loading: true,
      };
    case ERROR_RESUME_UPLOAD:
    case SUCCESS_RESUME_UPLOAD: {
      const { processedFiles, meta } = payload || {};
      const { job_id: jobId, user_id: userId } = meta || {};
      let key = jobId;
      const newJobTasks = [];
      const queuedTasksForJob = state[jobId] || [];
      if (processedFiles && Array.isArray(processedFiles) && processedFiles.length > 0) {
        processedFiles.forEach((f) => {
          if (!jobId) {
            key = userId;
          }
          newJobTasks.push({
            taskId: f.taskId,
            name: f.originalname,
          });
        });
      }
      const {
        taskId: currTaskId,
      } = (newJobTasks && newJobTasks[0]) || [];
      return {
        ...state,
        ...payload,
        taskId: currTaskId,
        [key]: [...queuedTasksForJob, ...newJobTasks],
        loading: false,
      };
    }
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default resumeUpload;
