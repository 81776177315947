import callApi from '../../utils/callApi';
import { baseUrl } from '../../utils/config';
import {
  REQUEST_USER_INVITE,
  REQUEST_USER_INVITE_UPDATE,
  REQUEST_USER_DELETE,
} from '../actionTypes';

const getInvites = (limit=10,skip=0) => (dispatch) => {
  const url = `${baseUrl}/userinviteemailmap?filter[limit]=${limit}&filter[skip]=${skip}&_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_USER_INVITE_UPDATE,
  };

  return callApi(params);
};

const sendInvite = data => (dispatch) => {
  const url = `${baseUrl}/userinviteemailmap`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_USER_INVITE,
  };
  return callApi(params);
};

const deleteInvite = data => (dispatch) => {
  console.log(data);
  const url = `${baseUrl}/userinviteemailmap`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
    actionType: REQUEST_USER_DELETE,
  };
  return callApi(params);
};

export {
  getInvites,
  sendInvite,
  deleteInvite,
};
