import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tile,
  TextInput,
  Button,
  InlineNotification,
  Loading,
} from 'carbon-components-react';
import isEmail from 'validator/lib/isEmail';
import PublicHeader from 'containers/common/publicHeader';
import { getAllCountries } from '../../../actions/admin/CompanyAndOffice';
import { registrationRequest } from '../../../actions/campus/registrationRequest';
import BackRow from 'components/common/BackRow/BackRow';
import './Login.css';

class RegisterRequest extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    {
      id: 'browseInstitutions',
      name: 'Browse Institutions',
      href: '/app/campus/browse/institutions',
    },
    { id: 'student', name: 'Registration', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailInvalid: false,
      validDomain: true,
      mailSentSuccess: false,
      userAlreadyExists: false,
    };
  }

  onChangeEvent = (name, value) => {
    this.setState({
      [name]: value,
      emailInvalid: false,
      validDomain: true,
      mailSentSuccess: false,
    });
  };

  onRegister = () => {
    const { history: { push } = {}, user } = this.props;
    const { userId } = user || {};
    let showLogo = null;
    if (userId) {
      showLogo = false;
    } else {
      showLogo = true;
    }
    const { email } = this.state;
    let { emailInvalid } = this.state;
    if (
      email === undefined ||
      email === null ||
      email.trim().length === 0 ||
      !isEmail(email)
    ) {
      emailInvalid = true;
      this.setState({ emailInvalid });
    } else {
      emailInvalid = false;
      this.props.registrationRequest({ email }).then((res) => {
        if (res && res.email && res.email !== null) {
          this.setState({ mailSentSuccess: true, email: '' }, () => {
            if (showLogo === false && push) {
              push(res && res.redirectLink);
            }
          });
        } else if (res.err && res.err.error !== 'User already exist') {
          this.setState({ validDomain: false, userAlreadyExists: false });
        } else if (res.err && res.err.error === 'User already exist') {
          this.setState({ validDomain: true, userAlreadyExists: true });
        } else {
          this.setState({
            validDomain: true,
            userAlreadyExists: false,
          });
        }
      });
    }
  };

  handleRedirectToLogin = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/');
    }
  }

  render() {
    const {
      email,
      emailInvalid,
      validDomain,
      mailSentSuccess,
      userAlreadyExists,
    } = this.state;
    const { t, user, isUserLoggedIn } = this.props;
    const invalidText = 'A valid email is required';
    const { userId } = user || {};
    let showLogo = null;
    if (userId) {
      showLogo = false;
    } else {
      showLogo = true;
    }
    return (
      <Fragment>
        {
          showLogo !== true && (
            <BackRow paths={this.paths} />
          )
        }
        {
          isUserLoggedIn !== true && (
            <div>
              <PublicHeader
                push={this.props.history.push}
                handleRedirectToLogin={this.handleRedirectToLogin}
              />
            </div>
          )
        }
        <div className={`${isUserLoggedIn !== true ? 'login-page' : ''} studentlogin col-12 bx--row m-0 p-0`}>
          {
            isUserLoggedIn !== true && (
              <div className="student-image col-12 col-md-6 col-lg-6 col-xl-6 m-0 p-0 d-flex justify-content-center align-items-center flex-column">
                <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
                  <div
                    className="m-3 p-2"
                    style={{ background: 'rgba(0,0,0,0.4)' }}>
                    <div>{t('studentInfo')}</div>
                    <div className="bx--type-alpha font-weight-bold">
                      {t('studentInfo1')}
                    </div>
                    <div className="login-list" style={{ fontSize: 18 }}>
                      {t('studentInfo2')}
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          <div
            className="right-side d-flex justify-content-center align-items-center pt-3 pr-3 pl-3 col m-0 p-0"
            style={isUserLoggedIn ? {} : { backgroundColor: 'white', height: '100vh' }}>
            <div className="d-block text-center">
              {showLogo === true && (
                <div className="ml-3">
                  <img
                    className="mb-4 geip-image-resolution"
                    src={require('assets/images/geiImage.png')}
                  />
                </div>
              )}
              <div className="h5 text-uppercase">
                {t('registrationRequest')}
              </div>
              {validDomain === false && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  title=""
                  subtitle={t('domainNotRegistered')}
                  onCloseButtonClick={() => {
                    this.setState({ validDomain: true });
                  }}
                />
              )}
              {userAlreadyExists === true && (
                <InlineNotification
                  lowContrast
                  kind="error"
                  title=""
                  subtitle={t('userExists')}
                  onCloseButtonClick={() => {
                    this.setState({ userAlreadyExists: false });
                  }}
                />
              )}
              {mailSentSuccess === true && (
                <InlineNotification
                  lowContrast
                  kind="success"
                  title=""
                  subtitle={t('linkSentSucess')}
                  onCloseButtonClick={() => {
                    this.setState({ mailSentSuccess: false });
                  }}
                />
              )}
              {
                !isUserLoggedIn && (
                  <InlineNotification
                    lowContrast
                    kind="error"
                    title=""
                    subtitle={t('progressHeading2')}
                    onCloseButtonClick={() => {
                    }}
                  />
                )
              }
              {
                !isUserLoggedIn && (
                  <div className="h6">{t('enterSchoolEmail')}</div>
                )
              }
              <div className="ml-0 mb-3">
                <div>
                  <TextInput
                    id="email"
                    name="email"
                    hideLabel
                    labelText={t('email')}
                    invalid={emailInvalid}
                    invalidText={invalidText}
                    placeholder={t('email')}
                    value={email}
                    onChange={({ target: { value = '', name = '' } = {} }) => {
                      this.onChangeEvent(name, value);
                    }}
                  />
                </div>
              </div>
              <div className="bx--col-12 justify-content-center ml-0">
                <Button small className="" onClick={(e) => this.onRegister(e)}>
                  {t('sendLink')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {this.props.regReq.loading && <Loading withOverlay />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  regReq: state.RegistrationRequest,
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
});

const mapDispatchToProps = {
  getAllCountries,
  registrationRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RegisterRequest));
