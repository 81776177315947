import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { Button, Tag, Tile } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Mixpanel } from 'components/common/Mixpanel';
import SetMixPanel from 'components/common/GetCurrentPath';
import Avatar from 'components/common/Avatar';

const track = {
  TALENTPOOL_VIEWCV_CLICKED: 'TALENTPOOL_VIEWCV_CLICKED',
  TALENTPOOL_ADDTOJOB_CLICKED: 'TALENTPOOL_ADDTOJOB_CLICKED',
  TALENTPOOL_ADDTOBUCKED_CLICKED: 'TALENTPOOL_ADDTOBUCKED_CLICKED',
  TALENTPOOL_PROFILE_CLICKED: 'TALENTPOOL_PROFILE_CLICKED',
  JOB_JOBTITLE_CLICKED: 'JOB_JOBTITLE_CLICKED',
  JOB_ALL_APPLICATIONS_CLICKED: 'JOB_ALL_APPLICATIONS_CLICKED',
  JOB_SHORTLISTED_APPLICATIONS_CLICKED: 'JOB_SHORTLISTED_APPLICATIONS_CLICKED',
  JOB_REJECTED_APPLICATIONS_CLICKED: 'JOB_REJECTED_APPLICATIONS_CLICKED',
  FEEDBACK_PROFILE_CLICKED: 'FEEDBACK_PROFILE_CLICKED',
  FEEDBACK_CANDIDATE_CLICKED: 'FEEDBACK_CANDIDATE_CLICKED',
  FEEDBACK_JOB_CLICKED: 'FEEDBACK_JOB_CLICKED',
};

const Chip = ({ title }) => title ? <div className="bx--tag bx--tag--local  ml-0 mr-2">{title}</div> : null;

const talentPoolCard = (rowData, {
  addToJob, activeAddToBucketModal,
  hideButtons = false, profilePage = false,
  profileId = 0,
} = {}) => {
  if (profilePage) {
    return (
      <div className="mt-md-0 mt-2 m-0 p-0">
        { rowData.personDocuments && rowData.personDocuments.length > 0 && (
          // <div className="addToJob mr-1 m-0 p-0 align-items-center">
          <Fragment>
            <div><a className="bx--btn bx--btn--primary mr-1 mb-2 talentpool_button bx--col-xs-12 bx--col-sm-12 bx--col-md-0" onClick={(e) => SetMixPanel(track.TALENTPOOL_VIEWCV_CLICKED)} style={{ height: 33 }} target="_blank" href={`/view/${rowData.personDocuments[0].cvLink}/cv`}>View CV</a></div>
            <div><Button kind="tertiary" className="mb-2 talentpool_button  bx--col-xs-12 bx--col-sm-12 bx--col-md-0" name={rowData.id} onClick={(e) => { SetMixPanel(track.TALENTPOOL_ADDTOJOB_CLICKED); return addToJob(e, profileId); }} small>Add to Job</Button></div>
          </Fragment>
          // </div>
        )}
        <div><Button kind="tertiary" className="mb-2 talentpool_button  bx--col-xs-12 bx--col-sm-12 bx--col-md-0" name={rowData.id || profileId} onClick={(e) => { SetMixPanel(track.TALENTPOOL_ADDTOBUCKED_CLICKED); return activeAddToBucketModal(e, profileId); }} small>Add to Bucket</Button></div>
      </div>
    );
  }

  return (
    <div className="pl-0 m-0 row" style={{ padding: '1rem' }}>
      <div className="bx--col-12 ml-2">
        <div className="p-0">
          <div className="xpa-cand-info--card-info h5 mb-0 bx--row">
            {(<Avatar
              user={{
                firstname: rowData.firstName,
                lastname: rowData.lastName,
                picture: null,
              }}
              size="30"
              classNameInfo="rounded-circle mr-1"
            />)}
            <Link className="mt-1 ml-1" to={{ pathname: `/app/campus/profile/${rowData.id}` }} onClick={() => (SetMixPanel(track.TALENTPOOL_PROFILE_CLICKED))} style={{ color: 'inherit' }}>
              {rowData.firstName && rowData.lastName && rowData.firstName.length > 0 && rowData.lastName.length > 0
                ? <div className="mb-1 h5 font-weight-bold" title="Name">{`${rowData.firstName} ${rowData.lastName}`}</div>
                : <div className="mb-1 h5 font-weight-bold" title="Name">Unknown</div>}
            </Link>
          </div>
          {/* <div className="bx--row m-0 p-0">
            {(
              <Avatar
                user={{
                  firstname: rowData.firstName,
                  lastname: rowData.lastName,
                  picture: null,
                }}
                size="30"
                classNameInfo="rounded-circle mr-1"
              />)}
            <Link to={{ pathname: `/app/campus/profile/${rowData.id}` }} style={{ color: 'inherit' }}>
              {rowData.firstName && rowData.lastName && rowData.firstName.length > 0 && rowData.lastName.length > 0
                ? <div className="mb-1 h5 font-weight-bold" title="Name">{`${rowData.firstName} ${rowData.lastName}`}</div>
                : <div className="mb-1 h5 font-weight-bold" title="Name">Unknown</div>}
            </Link>
          </div> */}
          {/* <a style={{color: "inherit"}}>
            {rowData.firstName && rowData.lastName && rowData.firstName.length > 0 && rowData.lastName.length > 0
              ? <div className="mb-1 h5 font-weight-bold" title="Name">{`${rowData.firstName} ${rowData.lastName}`}</div>
              : <div className="mb-1 h5 font-weight-bold" title="Name">Unknown</div>}
          </a> */}
          {rowData.currCompany && rowData.currCompany != null && rowData.currCompany.company && rowData.currCompany.company !== '-'
          && <div className="h6 text-info m-0 bx--type-caps" style={{ color: '#5596e6' }} title="Current Company">{`${rowData.currCompany.company}`}</div>}
          {rowData.currCompany && rowData.currCompany != null && (
            <div className="text-muted" title="Working Since">{rowData.currCompany.startDate && rowData.currCompany.startDate != null && (
              <small className="mt-1"><i className="fa fa-calendar" />
                {`  ${moment(rowData.currCompany.startDate).format('MMM DD YYYY')} - ${rowData.currCompany.endDate && rowData.currCompany.endDate !== null ? moment(rowData.currCompany.endDate).format('MMM DD YYYY') : 'present'}`}
              </small>
            )}
            </div>
          )}
          {rowData.currCompany && rowData.currCompany.qualification && rowData.currCompany.qualification !== '-' && (
            <div className="mb-0 mt-1 font-weight-light bx--modal-header__label bx--type-delta" style={{ fontSize: 12 }} title="Qualification">{` ${rowData.currCompany.qualification}`}</div>
          )}
        </div>
        <div className="row">
          <div className="pl-0 text-black-50">
            {rowData.address.location && <small className="col-8" title="Location"><i className="fa fa-map-marker" />{`  ${rowData.address.location.locationName}`}</small>}
            {<small className="col-8" title="Experience"><i className="fa fa-suitcase" />{Math.round(rowData.totalExp) === 0 ? ' No experience' : `  ${Math.round(rowData.totalExp)} years experience`}</small>}
          </div>
        </div>
        <div className="row ml-0 pl-0 text-muted">
          { rowData.salary && rowData.currencyShort && (
            <div title="Salary">{rowData.salary && rowData.salary != null && (
              <small className="mt-1"><i className="fa fa-money" />
                {`  ${rowData.salary} ${rowData.currencyShort && rowData.currencyShort !== null && rowData.currencyShort}`}
              </small>
            )}
            </div>
          )}
        </div>
        {rowData.overallScore && (
          <div class="mt-2 ml-1 badge badge-outline-secondary">Score: {Math.round(rowData.overallScore)}</div>
        )}
        <div>
          {rowData.buckets && Array.isArray(rowData.buckets) && rowData.buckets.map(({ bucketName }, key) => <Tag type="beta" key={key}>{bucketName}</Tag>)}
        </div>
        {hideButtons ? '' : <hr className="mt-2 mb-2 p-0" /> }
        {hideButtons ? '' : (
          <div className="d-flex">
            { rowData.personDocuments && rowData.personDocuments.length > 0 && (
              <div className="addToJob mr-3 p-0 align-items-center">
                <a className="bx--btn bx--btn--primary mr-3" style={{ height: 33 }} target="_blank" href={`/view/${rowData.personDocuments[0].cvLink}/cv`}>View CV</a>
                <Button kind="tertiary" name={rowData.id} onClick={addToJob} small>Add to Job</Button>
              </div>
            )}
            <Button kind="tertiary" name={rowData.id} onClick={activeAddToBucketModal} small>Add to Bucket</Button>
          </div>
        )}
      </div>
      {/* ,flexDirection:'column' */}
    </div>
  )};

const jobCard = (rowData, isSearch = false) => (
  <div className="bx--tile p-0">
    <div className="">
      <div className="bx--row p-2">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <div className="text-sm-center text-md-left text-center">
            <div className="text-dark">
              <small title="Job">{`Requisition ID: ${rowData.id}`}</small>
            </div>
            <div className="d-flex justify-content-sm-center justify-content-md-start">
              <Link
                to={{
                  pathname: `/app/campus/job/${rowData.id}`,
                  state: { selectedTab: 0 },
                }}
                title="Designation"
                onClick={({ target = {} }) => {
                  SetMixPanel(track.JOB_JOBTITLE_CLICKED);
                }}
              >
                <div className="card-title h5 mb-1 d-inline-block text-sm-center text-md-left font-weight-bold xpa-link text-capitalize">{rowData.title}</div>
              </Link>
              {
                rowData.draft === true && (
                  <div className="badge badge-outline-warning ml-1 mb-2 mt-1">draft</div>
                )
              }
            </div>
            <div title="Departmant" className="card-subtitle bx--row ml-0 text-dark justify-content-lg-start justify-content-center text-uppercase bx--modal-header__label pb-1">
              {rowData.function && (
                <div className="justify-content-center mr-3" title="Job Function">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 12, height: 12 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list">
                    <line x1="8" y1="6" x2="21" y2="6" />
                    <line x1="8" y1="12" x2="21" y2="12" />
                    <line x1="8" y1="18" x2="21" y2="18" />
                    <line x1="3" y1="6" x2="3" y2="6" />
                    <line x1="3" y1="12" x2="3" y2="12" />
                    <line x1="3" y1="18" x2="3" y2="18" />
                  </svg>
                  {`  ${rowData.function}`}
                </div>
              )}
              {rowData.country !== '' && (
                <div className="justify-content-center" title="Location">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 12, height: 12 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin">
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                    <circle cx="12" cy="10" r="3" />
                  </svg>
                  {`  ${rowData.country}`}
                </div>
              )}
            </div>
            <div className="card-subtitle pt-1">
              <Chip title={rowData.seniority} />
              <Chip title={rowData.hourtype} />
              <Chip title={rowData.term} />
            </div>
            <div className="text-dark">
              <small title="Posted on">{`Posted on: ${moment(rowData.openDate).format('MMM DD YYYY')}`}</small>
            </div>
          </div>
        </div>
        {isSearch === false && (
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="job-applicant-info pt-4">
              <div className="row">
                <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                  <h3 className="xp-c-text-align__center ">
                    <Link to={`/app/campus/jobs/${rowData.id}/applied`} onClick={(target={})=>{SetMixPanel(track.JOB_ALL_APPLICATIONS_CLICKED)}} >{rowData.total || 0}</Link>
                  </h3>
                  <span className="bx--modal-header__label mt-2">Applications</span>
                </div>
                <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                  <h3 className="xp-c-text-align__center ">
                    <Link to={`/app/campus/jobs/${rowData.id}/shortlisted`} onClick={(target={})=>{SetMixPanel(track.JOB_SHORTLISTED_APPLICATIONS_CLICKED)}}>{rowData.shortlisted || 0}</Link>
                  </h3>
                  <span className="bx--modal-header__label mt-2">Shortlisted</span>
                </div>
                <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                  <h3 className="xp-c-text-align__center ">
                    <Link to={`/app/campus/jobs/${rowData.id}/rejected`} onClick={({target={}})=>{SetMixPanel(track.JOB_REJECTED_APPLICATIONS_CLICKED)}}>{rowData.rejected || 0}</Link>
                  </h3>
                  <span className="bx--modal-header__label mt-2">Rejected</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

const feedBackCard = rowData => (
  <div className="bx--tile p-0">
    <div className="bx--col-12">
      <div className="p-0 pt-2 m-0">
        <div className="xpa-cand-info--card-info h5 ml-0 mb-0 bx--row">
          {(<Avatar
            user={{
              firstname: rowData.firstName,
              lastname: rowData.lastName,
              picture: rowData.picture !== '' ? rowData.picture : null,
            }}
            size="30"
            classNameInfo="rounded-circle mr-1"
          />)}
          <Link className="mt-1 ml-1" to={{ pathname: `/app/campus/profile/${rowData.id}` }} onClick={() => SetMixPanel(track.FEEDBACK_PROFILE_CLICKED)} style={{ color: '#5558AF' }}>
            {(rowData.firstName && rowData.lastName) && (rowData.firstName.length > 0
            || rowData.lastName.length > 0)
              ? (
                <div className="mb-1 h5 font-weight-bold" title="Commented By">{
                  `${rowData.firstName.length > 0 && rowData.lastName.length > 0
                    ? `${rowData.firstName} ${rowData.lastName}`
                    : rowData.firstName + rowData.lastName
                  }`
                }
                </div>
              )
              : <div className="mb-1 h5 font-weight-bold" title="Commented By">No Name</div>}
          </Link>
        </div>
        <div className="text-dark mt-1 ml-1"><span className="font-weight-bold">Commented:</span><span className="bx--type-omega font-weight-normal" style={{ overflowWrap: 'break-word' }}>{`  ${rowData.comment}`}</span></div>
        {/* <div className="ml-1 bx--row" style={{ overflowWrap: 'break-word' }}>
          <div className="bx--type-omega text-dark mt-2 ml-3">Commented:</div>
          <div className="text-">{`  ${rowData.comment}`}</div>
        </div> */}
      </div>
      <Link className="mt-1 ml-1" to={{ pathname: `/app/campus/profile/${rowData.candidateId}` }} onClick={() => SetMixPanel(track.FEEDBACK_CANDIDATE_CLICKED)} style={{ color: 'inherit' }}>
        {<span className="mb-1 font-weight-bold" title="Commented On"><span className="text-dark">Candidate:</span><span style={{ color: '#5558AF' }}>{`  ${rowData.candidateName.trim()}`}</span></span>}
      </Link>
      <div className="ml-1 font-weight-bold" title="stage"><span className="text-dark">Interview Stage:</span>{`  ${rowData.stage}`}</div>
      {/* <i className="fa fa-step-forward" /> */}
      <Link className="mt-1 ml-1" to={{ pathname: `/app/campus/job/${rowData.jobId}` }} onClick={() => SetMixPanel(track.FEEDBACK_JOB_CLICKED)} style={{ color: 'inherit' }}>
        {<span className="mb-1 font-weight-bold" title="Job"><span className="text-dark">Job Name:</span><span style={{ color: '#5558AF' }}>{`  ${rowData.jobName}`}</span></span>}
      </Link>
    </div>
  </div>
);

const companiesCard = rowData => (
  <div className="bx--tile p-0">
    <div className="">
      <div className="bx--row p-2">
        <div className="col-xs-12 col-sm-12 col-md-6">
          <div className="text-sm-center text-sm-center text-center text-md-left mt-3 ml-2">
            <div className="bx--row p-1">
              <div>
                <Avatar
                  user={{
                    firstname: rowData.name,
                    lastname: '',
                    picture: rowData.logo ? rowData.logo : null,
                  }}
                  isCompanyLogo
                  classNameInfo="rounded-circle"
                  size="40"
                />
              </div>
              <div className="card-title h5 mt-2 ml-2 d-inline-block text-center text-md-left font-weight-bold text-capitalize">
                {
                  rowData.isParentCompany ? (
                    <Link
                    to={{
                      pathname: `/app/campus/company`
                    }}
                  >
                    {rowData.name || 0}
                  </Link>
                  ) : (
                    rowData.name
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <div className="p-2">
            <div className="row">
              <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                <h3 className="xp-c-text-align__center ">
                  <Link
                    to={{
                      pathname: `/app/campus/jobs/open/page/1/limit/10`,
                      search: `companyId=${rowData.id}`,
                    }}
                  >
                    {rowData.open || 0}
                  </Link>
                </h3>
                <span className="bx--modal-header__label mt-2">Open Jobs</span>
              </div>
              <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                <h3 className="xp-c-text-align__center ">
                  <Link
                    to={{
                      pathname: `/app/campus/jobs/closed/page/1/limit/10`,
                      search: `companyId=${rowData.id}`,
                    }}
                  >
                    {rowData.closed || 0}
                  </Link>
                </h3>
                <span className="bx--modal-header__label mt-2">Closed Jobs</span>
              </div>
              {/* <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                <h3 className="xp-c-text-align__center ">
                  <Link
                    to={{
                      pathname: `/app/campus/jobs/shared/page/1/limit/10`,
                      search: `companyId=${rowData.id}`,
                    }}
                  >
                    {rowData.shared || 0}
                  </Link>
                </h3>
                <span className="bx--modal-header__label mt-2">Shared Jobs</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const RecruitersView = rowData => (
  <div className="bx--tile p-0">
    <div className="">
      <div className="bx--row p-2">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <div className="text-sm-center text-md-center text-center text-lg-left">
            <div className="card-title h5 mt-2 mb-0 d-inline-block text-center text-md-left font-weight-bold text-capitalize">
              <div className="bx--row p-1">
                <div className="bx--col-lg-1">
                  <Avatar
                    user={{
                      firstname: rowData.firstname,
                      lastname: rowData.lastName,
                      picture: rowData.picture ? rowData.picture : null,
                    }}
                    classNameInfo="rounded-circle"
                    size="60"
                  />
                </div>
                <div className="pt-1">
                  <div className="text-dark font-weight-bold bx--type-gamma text-capitalize">
                    <Link
                      to={{
                        pathname: `/app/campus/users/${rowData.id}`,
                        search: `recruiterId=${rowData.id}`,
                      }}
                    >{rowData.firstname} {rowData.lastName}
                    </Link>
                  </div>
                  <div className="">
                    <span className="text-capitalize">{rowData.company && rowData.company.companyName}</span>
                    <span>{rowData.company && rowData.company.website ? `(${rowData.company.website})` : null}</span>
                  </div>
                  <div className="">
                    <svg width="16" height="9" viewBox="0 0 16 11">
                    <path d="M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
                    </svg>
                    <span className="text-dark bx--modal-header__label ml-2 mt-0">
                      <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
                    </span>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <div className="p-2">
            <div className="row">
              <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                <h3 className="xp-c-text-align__center ">
                  <Link
                    to={{
                      pathname: `/app/campus/jobs/open/page/1/limit/10`,
                      search: `recruiterId=${rowData.id}`,
                    }}
                  >{rowData.open || 0}
                  </Link>
                </h3>
                <span className="bx--modal-header__label mt-2">Open Jobs</span>
              </div>
              <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                <h3 className="xp-c-text-align__center ">
                  <Link
                    to={{
                      pathname: `/app/campus/jobs/closed/page/1/limit/10`,
                      search: `recruiterId=${rowData.id}`,
                    }}
                  >{rowData.closed || 0}
                  </Link>
                </h3>
                <span className="bx--modal-header__label mt-2">Closed Jobs</span>
              </div>
              <div className="text-center col m-sm-0 m-md-0 m-lg-autom-s p-0 p-md-0 p-lg-auto">
                <h3 className="xp-c-text-align__center ">
                  <Link
                    to={{
                      pathname: `/app/campus/jobs/shared/page/1/limit/10`,
                      search: `companyId=${rowData.companyId}&recruiterId=${rowData.id}`,
                    }}
                  >{rowData.shared || 0}
                  </Link>
                </h3>
                <span className="bx--modal-header__label mt-2">Shared Jobs</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export {
  talentPoolCard,
  jobCard,
  feedBackCard,
  companiesCard,
  RecruitersView,
};
