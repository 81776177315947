import {
    REQUEST_USER_EMAIL_NOTIFICATIONS,
    ERROR_USER_EMAIL_NOTIFICATIONS,
    SUCCESS_USER_EMAIL_NOTIFICATIONS,
} from '../actions/actionTypes';

const initialState = {
    userEmailNotifications: {}
}

const userEmailNotifications = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case REQUEST_USER_EMAIL_NOTIFICATIONS: {
            return {
                ...state,
                loading: true,
            }
        };
        case SUCCESS_USER_EMAIL_NOTIFICATIONS: {
            return {
                ...state,
                loading: false,
                userEmailNotifications: payload,
            }
        };
        case ERROR_USER_EMAIL_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
}

export default userEmailNotifications;