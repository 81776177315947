import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import {
  TooltipDefinition, SkeletonText, ModalWrapper,
  TextArea, TextInput, Button, Tooltip, Modal,
} from 'carbon-components-react';
import { sendEmailTemplate } from 'actions/email-templates';
import { Mixpanel } from 'components/common/Mixpanel';
import { ApplicationBatchUpdateModal, ApplicationBatchUpdateModalTranslate } from 'components/roboroy/common/ApplicationBatchUpdateModal';
import ProductTour from 'components/common/ProductTour/ProductTour';
import { getNormalized } from 'utils/normalize';
import BackRow from 'components/common/BackRow/BackRow';
import EmailModal from 'components/roboroy/common/EmailModal';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import CandidateProfile from 'containers/roboroy/CandidateProfile';
import findAllUserJobApplicants from 'actions/talentSearch/applicantInfo';
import AssetAnalytics from 'containers/roboroy/AssetAnalytics';
import OfferInformation from 'containers/roboroy/OfferManagement/OfferInformation';
import ApplicantNotes from '../ApplicantNotes';
import { getAssetAnalytics,  getJobAppliedCount } from 'actions/talentSearch/candidatesList';
import {
  bulkShortlistReject,
  addApplicantToTalentPool,
  shareCV,
  bulkLike,
  findOtherJobsApplied,
  deleteApplicantInfo,
  updateReversePlacement
} from 'actions/talentSearch/candidatesList';
import {
  getOfferDetails,
} from 'actions/offerInformation';
import {
  updateSubStage,
  getWorkflowStages,
  getAllUserWorkflow,
  getApplicationWorkflow,
} from 'actions/workflow';
import ApplicantCard from 'components/roboroy/common/ApplicantCard';
import { getBucketNames, createBucket } from 'actions/talentSearch/buckets';

import './ApplicantInfo.css';
import InterviewStatus from './InterviewStatus';

let stillLoading = false;

class ApplicantInfo extends Component {
  constructor(props) {
    super(props);
    this.closeTour = this.closeTour.bind(this);
  }

  state = {
    loading: false,
    error: null,
    applications: [],
    selectedTab: 0,
    productTourIsOpen: false,
    isOpenBatchActionModal: false,
    bucketName: '',
    selectedBucketId: 0,
    useBucket: false,
    isBucketNameValid: false,
    invalidText: '',
    isInterviewAccepted: false,
    isOpenSendConsentModal: false,
    isOpenEmailModal: false,
    emailActionType: '',
    emailTemplate: '',
    editEmailBeforeSend: 'edit-email-no',
    activeAppId: 0,
    activeJobId: 0,
    notifShow: false,
    notifMsg: 'Default notification message',
    notifType: 'info',
    activeApplication: {},
    isOpenDeleteApplicationModal: false,
    workflowdocuments: {},
    itemsPerPage: 10,
    activePage: 1,
    placementModal: false
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeTour() {
    this.setState({ productTourIsOpen: false });
  }

  activateModal = (job) => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'open', name: 'Jobs', href: '/app/campus/jobs' },
    { id: 'job', name: 'Job Posting', href: '/app/campus/jobs/applied' },
    { id: 'applicant', name: 'Applicant information', href: '/app/campus/candidates/xopa' },
  ];

  handleCandidateShortlist = (appId, shortlistStatus) => {
    const { applications } = this.props;
    const {
      applicationsource: {
        sourceName,
      } = {},
    } = applications && applications.applications[0];
    const {
      jobInfo: {
        isAutoPilot,
        isAutoShortlist,
      } = {},
    } = applications;
    const bulkSrObj = [
      {
        appId,
        shortlisted: shortlistStatus,
        sourceName,
        isAutoPilot,
        isAutoShortlist,
      },
    ];
    this.props.bulkShortlistReject(bulkSrObj).then((res) => {
      this.props.findAllUserJobApplicants(this.state.id);
      this.props.getAssetAnalytics(appId);
      window.location.reload();
    });
  };

  addCandidateTofavourites = (appId, isLiked) => {
    const bulkSrObj = [
      {
        appId,
        isLiked,
      }
    ];
    this.props.bulkLike(bulkSrObj).then((res) => {
      this.renderContent();
    });
  }

  componentDidMount() {
    const { history, match } = this.props;
    const path = window.location.pathname;
    const { id = null, jobId = null, tabName = "candidateprofile" } = match.params || {};
    const selectedTab = this.getTabId(tabName);
    this.setState({ id, jobId, selectedTab });
    if (jobId) {
      this.paths[this.paths.length - 2] = {
        id: 'job-posting',
        name: 'Applicants',
        href: `/app/campus/jobs/${jobId}/applied`,
      };
    }

    this.onMountCalls(id);
  }

  updateComponent = (id) => (this.state.id != id ? this.onMountCalls(id) : null)

  onMountCalls = (id) => {
    this.setState({ id }, () => {
      this.renderContent(true);
    });
    this.props.getAllUserWorkflow();
    this.props.getWorkflowStages();
    this.props.getApplicationWorkflow(id).then((res) => {
      const acceptedStates = [6000, 6002, 6005];
      if (acceptedStates.includes(Number(res && res.data && res.data.outcomeId))) {
        this.setState({
          isInterviewAccepted: true,
        });
      }
    });
    this.props.getBucketNames();
  }

  activateBatchModal = () => {
    // console.log('Close Apply Job Modal');
    this.setState({
      isOpenBatchActionModal: true,
    });
  }

  dismissBatchModal = () => {
    // console.log('Close Apply Job Modal');
    this.setState({
      isOpenBatchActionModal: false,
    });
  }

  handleOtherJobsPaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ itemsPerPage, activePage }, () => {
      this.renderContent();
    });
  };

  renderContent = (flag = false) => {
    const { id, jobId } = this.state;
    // if (id) {
    //   // console.log("---- id ", id);
    //   // TODO
    //   this.props.findAllUserJobApplicants(id);
    // } else {
    //   // if job id does not exists, then fetch at user level.
    //   this.props.findAllUserJobApplicants();
    // }
    stillLoading = flag;
    this.props.findAllUserJobApplicants(id, jobId).then((res) => {
      const {profileId} =this.props.applications.applications[0];
      this.props.getJobAppliedCount(profileId);
      stillLoading = false;
      if (res && res.applications && res.applications[0]) {
        this.setState({ candidateProfileId: res && res.applications && res.applications[0] && res.applications[0].profileId }, () => {
          //this.props.findOtherJobsApplied(id, this.state.candidateProfileId, this.state.itemsPerPage, this.state.activePage);
        });
      }
    });
  }

  addCandidateToTalentPool = (profileItems) => {
    const { selectedBucketId } = this.state;
    const bulkSrObj = profileItems.map((eachProfile) => {
      const tpObj = {
        profileId: eachProfile && eachProfile.profileId,
        appId: eachProfile && eachProfile.appId,
      };
      if (selectedBucketId || Number(selectedBucketId) !== 0) {
        tpObj.bucketId = selectedBucketId;
      }
      return tpObj;
    });
    return this.props.addApplicantToTalentPool(bulkSrObj).then(() => {
      this.renderContent();
    });
  }

  handleUserSubStageCompletion = ({ workflowId, appStageId, appStageUserMapId, feedback, outcomeId }) => {
    this.props.updateSubStage({
      workflowId,
      appStageId,
      feedback,
      outcomeId,
      userMapId: appStageUserMapId
    }).then((resp) => {
      this.renderContent();
      //   console.log("---- resp = ", resp);
      //   this.props.updateApplicationSubStage({
      //       appStageUserMapId,
      //       appStageUserFeedbackId : resp.appStageUserFeedbackId,
      //   }).then(() => {
      // this.props.history.push(`/app/campus/interviews/${interviewId}`);
      //   })
    });
  }

  handleChangeRadioButton = (e) => {
    switch (e) {
      case 'select':
        this.setState({
          useBucket: false,
        });
        break;
      case 'create-new':
        this.setState({
          useBucket: true,
        });
        break;
      default:
    }
  }

  batchHandler = () => {
    this.dismissBatchModal();
    const {
      bucketName, useBucket, selectedBucketId, selectedId,
      batchActionState, batchActionItems,
    } = this.state;
    const actionState = batchActionState;
    const actionItems = batchActionItems;
    if (actionState && actionItems) {
      if ((actionState == "shortlist") || (actionState == "reject")) {
        this.handleCandidateShortlist(actionItems.appId, actionItems.shortlistStatus);
      } else if (actionState == "is_liked") {
        this.addCandidateTofavourites(actionItems.appId, actionItems.is_liked);
      } else if (actionState === 'talentpool') {
        if (useBucket) {
          this.dismissBatchModal();
          this.props.createBucket(bucketName).then((res) => {
            this.props.getBucketNames();
            this.setState({
              selectedBucketId: res && res.data && res.data.bucketId,
            });
            let bulkSrObj = actionItems.map((eachItem) => {
              return {
                profileId: eachItem && eachItem.profileId,
                appId: eachItem && eachItem.appId,
                bucketId: selectedBucketId,
              };
            });
            this.addCandidateToTalentPool(bulkSrObj);
          });
        } else if (!useBucket) {
          this.dismissBatchModal();
          let bulkSrObj = actionItems.map((eachItem) => {
            return {
              profileId: eachItem && eachItem.profileId,
              appId: eachItem && eachItem.appId,
              bucketId: selectedBucketId,
            };
          });
          this.addCandidateToTalentPool(bulkSrObj);
        } else {
          this.dismissBatchModal();
          let bulkSrObj = actionItems.map((eachItem) => {
            return {
              profileId: eachItem && eachItem.profileId,
              appId: eachItem && eachItem.appId,
            };
          });
          this.addCandidateToTalentPool(bulkSrObj);
        }
      } else {
        this.dismissBatchModal();
      }
    }

    //resetting state
    this.setState({
      batchActionItems: null,
      batchActionState: null
    }, () => {
    });
  }

  activeDeleteApplicationModal = () => {
    this.setState({
      isOpenDeleteApplicationModal: true,
    });
  }

  dismissDeletApplicationModal = () => {
    this.setState({
      isOpenDeleteApplicationModal: false,
    });
  }

  handleDeleteApplicationModal = () => {
    const { applications, history: { push } } = this.props;
    const { appId, jobId } = applications && applications.applications[0];
    this.props.deleteApplicantInfo(appId).then(() => {
      this.dismissDeletApplicationModal();
      push(`/app/campus/jobs/${jobId}/applied`);
    });
  }

  batchActionClickHandler = (actionItems, actionState) => {
    this.setState({
      batchActionItems: actionItems,
      batchActionState: actionState
    }, () => {
      this.activateBatchModal();
    });
  }

  validateShareCVInputValid = () => {
    let validationObj = {
      error: false
    };

    if ((!this.state.email) || (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)))) {
      validationObj.error = true;
      validationObj.email = {
        message: "Please enter a valid email ID"
      }
    }

    return validationObj;
  }

  submitShare = (cvLink, user) => {
    let validationObj = this.validateShareCVInputValid();
    this.setState({ shareCVValidator: validationObj });
    const {
      applications: {
        applications = [],
      } = {},
    } = this.props;
    const { appId = 0 } = (applications && Array.isArray(applications) && applications[0]) || {}
    if (validationObj && !validationObj.error) {
      this.props.shareCV({
        firstName: user.firstname,
        lastName: user.lastname,
        email: this.state.email,
        message: this.state.message,
        cvLink,
        templateName: 'email-cv',
        appId: appId || 0,
      });
      return true;
    }

    return false;
  }

  handleOpenEmailModal = (e) => {
    const {
      applications = {},
    } = this.props;
    const { applications: applicationsArr = [] } = applications || {};
    const activeApplication = applicationsArr && Array.isArray(applicationsArr)
      && applicationsArr.filter(a => a.appId == (e.appId || 0));
    if (activeApplication && activeApplication[0]) {
      const updatObj = {
        emailActionType: (e && e.name) || 'default',
        emailTemplate: (e && e.template),
        activeAppId: (e && e.appId),
        activeJobId: (e && e.jobId),
        activeApplication: activeApplication[0] || {},
        workflowdocuments: (e && e.workflowdocuments) || {},
      };
      if (e.source === 'ai sourcing') {
        updatObj.editEmailBeforeSend = 'edit-email-yes';
      }
      this.props.getAssetAnalytics(updatObj.activeAppId);
      this.setState(updatObj, () => {
        this.setState({ isOpenEmailModal: true, workflowdocuments: updatObj.workflowdocuments });
      });
    }
  }

  dismissEmailModal = () => {
    this.setState({
      isOpenEmailModal: false,
      emailActionType: '',
      emailTemplate: '',
      activeAppId: 0,
      activeJobId: 0,
      notifShow: false,
      notifType: 'info',
      notifMsg: 'Default notification message',
      isSendingEmail: false,
      activeApplication: {},
    });
  }

  handleSendEmail = () => {
    const {
      editEmailBeforeSend, emailTemplate,
      emailActionType, activeAppId, activeJobId, workflowdocuments,
    } = this.state;
    const { history: { push } = {} } = this.props;
    if ((editEmailBeforeSend === 'edit-email-yes') || (emailTemplate === 'custom-email')) {
      let d = push && push(`/app/campus/emails/send/${emailTemplate}/${activeAppId}/job/${activeJobId}`);
    } else {
      const sendEmailData = {
        payload: {
          appId: activeAppId || 0,
          workflowdocuments,
        },
        templateId: -1,
        actionName: 'send-email',
        templateName: emailTemplate,
        emailActionType,
      };
      this.setState({
        isSendingEmail: true,
      }, () => {
        this.props.sendEmailTemplate(sendEmailData)
          .then((res) => {
            if (res && !res.error) {
              this.dismissEmailModal();
              window.location.reload();
            } else {
              console.error('Send email error:', res);
              const { message } = (res && res.error) || {};
              throw new Error(message || 'Error occured while sending email');
            }
          })
          .catch((err) => {
            console.error(err);
            const { message } = err || {};
            this.setState({
              isSendingEmail: false,
              notifShow: true,
              notifType: 'error',
              notifMsg: message || 'Error occured while sending email',
            });
          });
      });
    }
  }

  onEditEmailChange = (ev) => {
    this.setState({
      editEmailBeforeSend: ev,
    });
  }

  onViewEmail = () => {
    const { emailTemplate } = this.state;
    const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
    window.open(`/emailpreview/${emailTemplate}`, 'Email Preview', params);
  }

  onEmailTemplateChange = (e) => {
    const selectedEmailTemplate = e && e.target && e.target.value;
    if (!selectedEmailTemplate) return;
    this.setState({
      emailTemplate: selectedEmailTemplate,
    });
  }

  handleApplicantTabClick = (tabType) => {
    let selectedTab = 0;
    let status = tabType;
    Mixpanel.track(`Jobs List -> ${status}`);
    switch (tabType) {
      case 'applicant_workflow':
        selectedTab = 1;
        break;
      case 'applicant-notes':
        selectedTab = 2;
        break;
      case 'applicant_timeline':
        selectedTab = 3;
        break;
      case 'applicant_offer':
        selectedTab = 4;
        break;
      // case 'applicant_documents':
      //   selectedTab = 5;
      //   break;
      case 'applicant_otherjobs':
        selectedTab = 6;
        break;
      case 'applicant_references':
        selectedTab = 7;
        break;
      case 'applicant_analytics':
        selectedTab = 8;
        break;
      case 'suitable-jobs':
        selectedTab = 9;
        break;
      default:
        selectedTab = 0;
        status = null;
        break;
    }

    this.setState({ selectedTab, keyword: null }, () => {
      const { id, jobId } = this.state;
      const { isChild } = this.props;
      const path = window.location.pathname;
      if (!isChild && path.indexOf('/new/candidate/app/') === -1) this.props.history.replace(`/app/campus/candidate/${id}/job/${jobId}/${this.getTabName(selectedTab)}`);
      else this.props.history.replace(`/app/campus/new/candidate/app/${id}/job/${jobId}/${this.getTabName(selectedTab)}`);
      // this.renderContent();
    });
  }
  handleReload = () => {
    window.location.reload();
  }

  handleChange = (event) => {
    const { target: { name, value } = {} } = event;
    this.setState({
      [name]: value,
    });
  }

  getTabId = (name) => {
    switch (name) {
      case 'candidateprofile': return 0;
      case 'interviewstatus': return 1;
      case 'notes': return 2;
      case 'timeline': return 3;
      case 'offerinformation': return 4;
      case 'documents': return 5;
      case 'otherjobsapplied': return 6;
      case 'references': return 7;
      case 'analytics': return 8;
      case 'suitablejobs': return 9;
      default: return 0;
    }
  }

  getTabName = (id) => {
    switch (id) {
      case 0: return 'candidateprofile';
      case 1: return 'interviewstatus';
      case 2: return 'notes';
      case 3: return 'timeline';
      case 4: return 'offerinformation';
      case 5: return 'documents';
      case 6: return 'otherjobsapplied';
      case 7: return 'references';
      case 8: return 'analytics';
      case 9: return 'suitablejobs';
      default: return 0;
    }
  }

  updatePlacementInfo = (data) => {
    const { applications, history: { push } } = this.props;
    const { appId, jobId, isHired } = applications && applications.applications[0];

    if(isHired === true){
      this.setState({
        loading: true
      }, () => {
        this.props.updateReversePlacement(data).then((res) => {
          if(res && !res.error){
            this.setState({
              loading: false
            })
            this.dismissPlacementModal();
            this.renderContent();
            window.location.reload();
          }
        }). catch((err) => {
          console.log("err:::", err)
        })
      })
    }
  }

  activePlacementModal = () => {
    this.setState({
      placementModal: true,
    });
  }

  dismissPlacementModal = () => {
    this.setState({
      placementModal: false,
    })
  }

  render() {
    console.log(this.props)
    const { applications = [], jobInfo} = this.props.applications;
    const { history: { push }, t, offerAcceptedCount } = this.props;
    const {
      selectedTab, shareCVValidator, useBucket, batchActionState, isOpenBatchActionModal,
      isOpenSendConsentModal, notifShow, notifType, notifMsg, activeApplication,
      emailTemplate, editEmailBeforeSend, isSendingEmail, placementModal
    } = this.state;
    const notifObj = { notifShow, notifType, notifMsg };
    const {
      isActive,
      appId,
      jobId = 0,
      workflow,
      personDocuments,
      job: {
        jobfunction: {
          functionName = '',
        } = {},
        jobname: {
          nameName = '',
        } = {},
        jobseniority: {
          seniorityName = '',
        } = {},
        hourtype: {
          typeName = '',
        } = {},
        term: {
          termName = '',
        } = {},
        region,
        company: {
          companyName = ''
        } = {},
        country: {
          countryFull = ''
        } = {},
        office: {
          officeName = '',
          officeWebsite = '',
          address: {
            addressLine1 = '',
            addressLine2 = '',
            zipcode = null,
            location: {
              locationName = '',
            } = {}
          } = {},
        } = {}
      } = {} } = applications[0] || {};
    const jobInfoObj = (jobInfo && Array.isArray(jobInfo) && jobInfo[0]) || null;
    const interviewflow = {
      handleUserSubStageCompletion: this.handleUserSubStageCompletion,
      showInModal: false,
      application: this.props.applications.applications && this.props.applications.applications[0],
      appId: this.props.applications.applications && this.props.applications.applications[0] && this.props.applications.applications[0].appId,
      jobId: jobId,
      updateParentPage: this.handleReload,
      handleOpenEmailModal: this.handleOpenEmailModal,
      handleApplicantTabClick: this.handleApplicantTabClick,
      getOfferDetails: this.props.getOfferDetails,
      jobInfo: jobInfoObj,
    };

    const guideSteps = [
      {
        selector: '.unknown-class-meow-meow',
        content: 'This is the candidate information page. It provides details about a particular candidate of a job.',
      },
      {
        selector: '.xpa-cand-info .xpa-cand-info--card-info',
        content: 'This section provides basic information about the candidate.',
      },
      {
        selector: '.xpa-cand-info .xpa-cand-info--card-actions',
        content: 'Candidates can be shortlisted, rejected, added to talent pool or added to favorite list here.',
      },
      {
        selector: '.xpa-cand-info .applicant-score-card',
        content: 'X0PA metrics for the candidate for this particular job posting can be seen here.',
      },
      {
        selector: '.xpa-cand-info .xpa-cand-info--job-info',
        content: 'Details about the current job is shown here.',
      },
      {
        selector: '.xpa-cand-info .xpa-cand-info--int-status',
        content: 'This section provides information about the interview status for the candidate.',
      },
      {
        selector: '.xpa-cand-info .xpa-cand-info--docs',
        content: 'View, download and email candidate CV here',
      },
      {
        selector: '.xpa-cand-info .xpa-cand-info--other-jobs',
        content: 'Checkout other jobs this candidate has applied to and their status.',
      },
    ];

    const cvLink = personDocuments && personDocuments.length > 0 && personDocuments[0] && personDocuments[0].cvLink;
    return (
      <div className="xp-c-container xp-c-background--light">
        {window.location.pathname.indexOf('new/candidate/app') === -1 && (
          <Fragment>
            <BackRow paths={this.paths} showGuidedTour={true} handleGuidedTourClick={() => this.setState({ productTourIsOpen: true })} />
            <ProductTour onRequestClose={this.closeTour} isOpen={this.state.productTourIsOpen} steps={guideSteps} />
          </Fragment>
        )}
        {this.state.candidateProfileId && this.props.applications &&
          this.props.applications.applications &&
          this.props.applications.applications.length > 0 ? (
            <div className="xpa-cand-info mb-4">
              <ApplicantCardLocal
                handleChange={this.handleChange}
                addCandidateToTalentPool={this.addCandidateToTalentPool}
                offerAcceptedCount={offerAcceptedCount}
                application={this.props.applications.applications[0]}
                minMax={this.props.applications.minMax}
                getNormalized={getNormalized}
                isInterviewAccepted={this.state.isInterviewAccepted}
                batchActionClickHandler={this.batchActionClickHandler}
                t={t}
                activeDeleteApplicationModal={this.activeDeleteApplicationModal}
                handleCandidateShortlist={this.handleCandidateShortlist}
                updatePlacementInfo = {this.updatePlacementInfo}
                activePlacementModal = {this.activePlacementModal}
                dismissPlacementModal = {this.dismissPlacementModal}
                placementModal = {placementModal}
                user={this.props.user}
              />
              <div className={`row m-0 p-0 mt-2 ${(isActive !== false) ? 'xp-m__applicantinfo--container' : ''}`}>
                {(isActive === false)
                  && (
                    <div className="p-4 mb-2 col-sm-12">
                      <div className="empty text-center">
                        <div className="empty-icon">
                          <i className="fa fa-flag fa-2x text-danger" />
                        </div>
                        <p className="text-danger m-0 p-0">The candidate has choosen to delete this application information. </p>
                      </div>
                    </div>)
                }
                {(isActive !== false)
                  && (
                    <div className="p-4 col-12 col-lg-3 col-md-4 col-xl-2 xpa-bg--gray12">
                      <ApplicantTabs selectedTab={selectedTab} t={t} handleApplicantTabClick={this.handleApplicantTabClick} />
                    </div>
                  )}
                {(isActive !== false) && (
                  <div className="col-lg-8 col-md-8 ml-2">
                    {
                      (selectedTab == 0) && (this.state.candidateProfileId) && (
                        <div className="text-left">
                          <CandidateProfile
                            application={this.props.applications.applications && this.props.applications.applications[0]}
                            noNameImg
                            notEditable
                          />
                        </div>)
                    }
                    {
                      (selectedTab == 1) && (
                        <Fragment>
                          <div className="bx--type-delta d-none d-lg-block d-md-block">{t('interview')} {t('status')}</div>
                          <InterviewStatus
                            application={this.props.applications.applications && this.props.applications.applications[0]}
                            interviewflow={interviewflow}
                            push={push}
                            jobsData={this.props.applications && this.props.applications.jobInfo}
                            handleCandidateShortlist={this.handleCandidateShortlist}
                            value={this.props && this.props.match && this.props.match.params}
                            jobInfo={jobInfoObj}
                          />
                        </Fragment>
                      )
                    }
                    {
                      selectedTab === 2 && (
                        <ApplicantNotes
                          appId={this.props.applications.applications && this.props.applications.applications[0] && this.props.applications.applications[0].appId || null}
                        />
                      )
                    }
                    {
                      (selectedTab === 3) && (
                        <Fragment>
                          <div className="bx--type-delta">{t('timeline')}</div>
                          <hr className="mt-1 mb-1" />
                          <AssetAnalytics
                            application={this.props.applications.applications && this.props.applications.applications[0]}
                            handleApplicantTabClick={this.handleApplicantTabClick}
                            timeLine={this.props.timeLine}
                            user={this.props.user}
                          />
                        </Fragment>
                      )
                    }
                    {
                      (selectedTab === 4) && (
                        <OfferInformation
                          applications={this.props.applications}
                          push={push}
                        />
                      )
                    }
                  </div>)}
              </div>
            </div>
          ) : (
            <div className="p-4 mb-2">
              <div className="empty text-center">
                <div className="empty-icon">
                  <i className="fa fa-flag fa-2x" />
                </div>
                <p className="empty-title h5">{t('noApplMsg')}</p>
                <ul>
                  <li>
                    {t('noApplMsg2')}
                  </li>
                </ul>
              </div>
            </div>
          )}
        <JDProcessOverlay show={stillLoading || this.props.roboLoading || this.props.workflowLoading} message="processing..." />
        <Modal
          open={this.state.isOpenDeleteApplicationModal}
          shouldSubmitOnEnter
          danger
          modalHeading={t('delete')}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          onClick={this.activeDeleteApplicationModal}
          onRequestClose={this.dismissDeletApplicationModal}
          onRequestSubmit={this.handleDeleteApplicationModal}
        >
          <div className="text-dark font-weight-bold">{t('deleteApplicationMsg')}</div>
        </Modal>
        <EmailModal
          actionType={this.state.emailActionType}
          open={this.state.isOpenEmailModal}
          onRequestSubmit={this.handleSendEmail}
          onRequestClose={this.dismissEmailModal}
          onEditEmailChange={this.onEditEmailChange}
          editEmailBeforeSend={this.state.editEmailBeforeSend}
          onViewEmail={this.onViewEmail}
          isLoading={isSendingEmail}
          notifObj={notifObj}
          application={this.state.activeApplication}
          onEmailTemplateChange={this.onEmailTemplateChange}
          emailTemplate={this.state.emailTemplate}
        />
        <ApplicationBatchUpdateModalTranslate
          actionType={batchActionState}
          onRequestClose={this.dismissBatchModal}
          open={isOpenBatchActionModal}
          onRequestSubmit={this.batchHandler}
          buckets={this.props.buckets}
          handleChange={(e) => this.handleChange(e)}
          isBucketNameValid={this.state.isBucketNameValid}
          invalidText={this.state.invalidText}
          bucketName={this.state.bucketName}
          handleChangeRadioButton={this.handleChangeRadioButton}
          useBucket={this.state.useBucket}
        />
      </div>
    );
  }
}

const ApplicantTabs = ({ selectedTab, handleApplicantTabClick, t }) => {

  return (<GenericTabs
    className="xpa-cand-info--tabs xpa-c-tabs--vertical m-0"
    selected={selectedTab}
    tabContent={[
      {
        className: 'xpa-cand-info--int-status ml-0',
        label: `${t('candidate')} ${t('profile')}`,
        value: '',
        onClick: () => handleApplicantTabClick('candidate_profile'),
      },
      {
        className: 'xpa-cand-info--int-status ml-0',
        label: `${t('interview')} ${t('status')}`,
        value: '',
        onClick: () => handleApplicantTabClick('applicant_workflow'),
      },
      {
        className: 'xpa-cand-info--int-status ml-0',
        label: `${t('notes')}`,
        value: '',
        onClick: () => handleApplicantTabClick('applicant-notes'),
      },
      {
        className: 'xpa-cand-info--int-status ml-0',
        label: `${t('timeline')}`,
        value: '',
        onClick: () => handleApplicantTabClick('applicant_timeline'),
      },
      {
        className: 'xpa-cand-info--docs ml-0',
        label: `${t('offer')} ${t('info')}`,
        value: '',
        onClick: () => handleApplicantTabClick('applicant_offer'),
      },
      // {
      //   className: 'xpa-cand-info--docs ml-0',
      //   label: `${t('documents')}`,
      //   value: '',
      //   selected: true,
      //   onClick: () => handleApplicantTabClick('applicant_documents'),
      // },
      // {
      //   className: 'xpa-cand-info--other-jobs ml-0',
      //   label: `${t('other')} ${t('jobs')} ${t('applied')}`,
      //   value: '',
      //   selected: true,
      //   onClick: () => handleApplicantTabClick('applicant_otherjobs'),
      // },
      // {
      //   className: 'xpa-cand-info--references ml-0',
      //   label: `${t('references')}`,
      //   value: '',
      //   selected: true,
      //   onClick: () => handleApplicantTabClick('applicant_references'),
      // },
      // {
      //   className: 'xpa-cand-info--analytics ml-0',
      //   label: 'Analytics',
      //   value: '',
      //   selected: true,
      //   onClick: () => handleApplicantTabClick('applicant_analytics'),
      // },
      // {
      //   className: 'xpa-cand-info--other-jobs ml-0',
      //   label: 'Suitable Jobs',
      //   value: '',
      //   selected: true,
      //   onClick: () => handleApplicantTabClick('suitable-jobs'),
      // },
    ]} />
  )
}


const ShortlistButton = ({ application, actionHandler, status, t }) => (
  <Button
    small
    onClick={() => actionHandler({ appId: application.appId, shortlistStatus: true }, "shortlist")}
    onFocus={() => { }}
    className={`xpa--workflow-click mt-1 mr-1`}
    kind={status === null ? 'secondary' : (status === true ? 'tertiary' : 'secondary')}
    disabled={status || application.inviteaccept !== null}
    icon={
      {
        "id": "icon--checkmark--outline",
        "name": "icon--checkmark--outline",
        "tags": "icon--checkmark--outline",
        "styles": "",
        "viewBox": "0 0 16 16",
        "width": "16",
        "height": "16",
        "svgData": {
          "circles": "",
          "ellipses": "",
          "paths": [
            {
              "d": "M8 14.5c3.6 0 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
            },
            {
              "d": "M11.3 4.7l1.4 1.4L6.8 12 3.3 8.6l1.4-1.4 2.1 2z"
            }
          ],
          "polygons": "",
          "polylines": "",
          "rects": ""
        }
      }
    }
    iconDescription="Add">
    {status ? `${t('shortlisted')}` : `${t('shortlist')}`}
  </Button>
);

const LikedButton = ({ application, actionHandler, t }) => (
  <Button
    small
    onClick={() => actionHandler({ appId: application.appId, is_liked: true }, "is_liked")}
    onFocus={() => { }}
    className={`xpa--workflow-click mt-1 mr-1`}
    kind="tertiary"
    icon={
      {
        "id": "icon--favorite",
        "name": "icon--favorite",
        "tags": "icon--favorite",
        "styles": "",
        "viewBox": "0 0 16 15",
        "width": "16",
        "height": "16",
        "svgData": {
          "circles": "",
          "ellipses": "",
          "paths": [
            {
              "d": "M8.109 11.75l-4.5 3.2c-.2.1-.5 0-.4-.3l1.5-5.1-4.6-3.2c-.2-.2-.1-.5.1-.5l5.5-.5 2-5.2c.1-.2.4-.2.5 0l2 5.2 5.5.5c.2 0 .3.3.1.4l-4.5 3.3 1.5 5.1c.1.2-.2.4-.4.3l-4.3-3.2z"
            }
          ],
          "polygons": "",
          "polylines": "",
          "rects": ""
        }
      }
    }
    iconDescription="Add">
    {t('addToFav')}
  </Button>
);

const RejectButton = ({ application, actionHandler, status, isInterviewAccepted, t }) => (
  <Button
    small
    onClick={() => actionHandler({ appId: application.appId, shortlistStatus: false }, "reject")}
    onFocus={() => { }}
    disabled={(!status && (status === false)) || isInterviewAccepted || application.inviteaccept !== null}
    className={`xpa--workflow-click mt-1 mr-1`}
    kind={status === null ? 'secondary' : (status === false ? 'tertiary' : 'secondary')}
    icon={
      {
        "id": "icon--close--outline",
        "name": "icon--close--outline",
        "tags": "icon--close--outline",
        "styles": "",
        "viewBox": "0 0 16 16",
        "width": "16",
        "height": "16",
        "svgData": {
          "circles": "",
          "ellipses": "",
          "paths": [
            {
              "d": "M8 14.5c3.6 0 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
            },
            {
              "d": "M8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6z"
            }
          ],
          "polygons": "",
          "polylines": "",
          "rects": ""
        }
      }
    }
    iconDescription="Add">
    {(!status && (status === false)) ? `${t('rejected')}` : `${t('reject')}`}
  </Button>
);

const DeleteInfoButton = ({ activeDeleteApplicationModal, t }) => (
  <Button
    small
    onClick={activeDeleteApplicationModal}
    onFocus={() => { }}
    className={`xpa--workflow-click mt-1 mr-1`}
    kind='danger'
  >
    <div className="d-flex mt-1">
      {t('delete')} {t('application')}
      <span className="ml-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true" data-reactid="1201"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
      </span>
    </div>
  </Button>
);

// const AddToTalentPoolButton = ({ application, actionHandler }) => (
// <Button
//   small
//   onClick={() => actionHandler([{ profileId: application.profile.profileId, appId: application.appId }], "talentpool")}
//   onFocus={() => { }}
//   className={`xpa--workflow-click mt-1 mr-1`}
//   kind="tertiary"
//   icon={
//     {
//       "id": "icon--add--outline",
//       "name": "icon--add--outline",
//       "tags": "icon--add--outline",
//       "styles": "",
//       "viewBox": "0 0 16 16",
//       "width": "16",
//       "height": "16",
//       "svgData": {
//         "circles": "",
//         "ellipses": "",
//         "paths": [
//           {
//             "d": "M8 14.5c3.6 0 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5zM8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
//           },
//           {
//             "d": "M7 7V4h2v3h3v2H9v3H7V9H4V7h3z"
//           }
//         ],
//         "polygons": "",
//         "polylines": "",
//         "rects": ""
//       }
//     }
//   }
//   iconDescription="Add">
//   Add to talent pool
// </Button>
// );

const EmailCVButton = ({ user, cvLink, validator, handleChange, submitShare, className, t }) => (
  <ModalWrapper
    id="transactional-passive-modal"
    handleSubmit={() => submitShare(cvLink, user)}
    buttonTriggerClassName={`bx--btn--lg ml-0 ${className}`}
    triggerButtonKind="secondary"
    passiveModal={false}
    primaryButtonText={t('send')}
    buttonTriggerText={t('emailCv')}
    modalLabel={t('sharing')}
    modalHeading={t('emailCv')}
    shouldCloseAfterSubmit
  >
    {/* <Form className="some-class" onSubmit={(e) => submitShare(e, cvLink, user)}> */}
    <TextInput
      className="mb-2"
      id="test2"
      labelText={t('toAddress')}
      name="email"
      invalid={validator && validator.error}
      invalidText={validator && validator.email && validator.email.message}
      placeholder={t('sendCvHolder')}
      onChange={(ev) => { handleChange(ev); }}
    />
    <TextArea
      labelText={t('message')}
      className="some-class mb-2"
      placeholder={t('emailCvHolder')}
      name="message"
      onChange={(ev) => { handleChange(ev); }}
    />
    <small class="text-dark">{t('emailCvMsg')}</small>
    {/* <Button type="submit" className="some-class mt-2 d-block">
    Submit
    </Button> */}
    {/* </Form> */}
  </ModalWrapper>
);

const ApplicantCardLocal = ({
  isInterviewAccepted, user, cvLink, application,
  handleCandidateShortlist, addCandidateToTalentPool,
  minMax, getNormalized, batchActionClickHandler,
  handleChange, submitShare, shareCVValidator,
  activeDeleteApplicationModal, t, offerAcceptedCount, updatePlacementInfo, activePlacementModal,
  dismissPlacementModal, placementModal
}) => (
  <div className="p-2 xpa-cand-info--card">
    <div className="card-body pt-0 pb-0 pl-2 pr-2">
      {/* <div className="row"> */}
      <ApplicantCard application={application} offerAcceptedCount={offerAcceptedCount} showEmail={true} 
        updatePlacementInfo={updatePlacementInfo}
        activePlacementModal = {activePlacementModal}
        dismissPlacementModal = {dismissPlacementModal}
        placementModal = {placementModal}
        isApplicantInfo={true}
        user={user}
      />
      {/* <ApplicantScores
    application={application}
    minMax={minMax}
    getNormalized={getNormalized} /> */}
      {/* </div> */}
    </div>
    <div>
      {application && !(application.isActive === false) && <hr className="mb-1 ml-2 mr-2" />}
      {application && application.profile && application.profile.email ? (
        (application.isActive !== false) && (<div className="mt-1 xpa-cand-info--card-actions pb-2 ml-2 mr-2">
          {/* <ShortlistButton t={t} application={application} status={application.shortlisted} actionHandler={batchActionClickHandler} />
          <RejectButton t={t} application={application} status={application.shortlisted} isInterviewAccepted={isInterviewAccepted} actionHandler={batchActionClickHandler} />     */}
          {/* {application && !application.isInTalentPool && (
              <AddToTalentPoolButton  application={application} actionHandler={batchActionClickHandler}/>
          )} */}
          {application && !application.isLiked && (<LikedButton t={t} application={application} status={application.isLiked} actionHandler={batchActionClickHandler} />)}
          {
            application
            && application.applicationsource
            && application.applicationsource.sourceName
            && application.applicationsource.sourceName.toString()
            && (
              application.applicationsource.sourceName.toLowerCase() === 'ai sourcing'
              || application.applicationsource.sourceName.toLowerCase() === 'linkedin'
              || application.applicationsource.sourceName.toLowerCase() === 'recruiter'
              || application.applicationsource.sourceName.toLowerCase() === 'recruitertalentpool'
              || application.applicationsource.sourceName.toLowerCase() === 'globaltalentpool'
            )
            && application.inviteaccept !== true
            && <DeleteInfoButton
              t={t}
              application={application}
              activeDeleteApplicationModal={activeDeleteApplicationModal}
            />
          }
        </div>)
      ) : (
          <div className="ml-2 d-flex">
            {/* <AISourcedEmailMissingWarning/> */}
            <div>
              {
                application
                && application.applicationsource
                && application.applicationsource.sourceName
                && application.applicationsource.sourceName.toString()
                && (application.applicationsource.sourceName.toLowerCase() === 'ai sourcing'
                  || application.applicationsource.sourceName.toLowerCase() === 'recruitertalentpool'
                  || application.applicationsource.sourceName.toLowerCase() === 'recruiter')
                && application.inviteaccept !== true
                && <DeleteInfoButton
                  t={t}
                  application={application}
                  activeDeleteApplicationModal={activeDeleteApplicationModal}
                />
              }
            </div>
          </div>
        )}
    </div>

  </div>
);

const mapStateToProps = state => ({
  loading: state.roboroyApplications.loading || state.workflows.loading || stillLoading,
  roboLoading: state.roboroyApplications.loading,
  workflowLoading: state.workflows.loading,
  error: state.roboroyApplications.error,
  applications: state.roboroyApplications,
  otherJobsApplied: state.roboroyApplications.otherJobsApplied,
  totalCount: state.roboroyApplications.totalCount,
  user: state.x0paDuser.user,
  timeLine: state.roboroyApplications.timeLine,
  buckets: state.roboroyTalentPool.buckets,
  offerAcceptedCount: state.roboroyApplications.offerAcceptedCount
});

const mapDispatchToProps = {
  findAllUserJobApplicants,
  bulkShortlistReject,
  updateSubStage,
  shareCV,
  addApplicantToTalentPool,
  bulkLike,
  getWorkflowStages,
  getAllUserWorkflow,
  findOtherJobsApplied,
  getApplicationWorkflow,
  getBucketNames,
  createBucket,
  sendEmailTemplate,
  getOfferDetails,
  deleteApplicantInfo,
  getAssetAnalytics,
  getJobAppliedCount,
  updateReversePlacement
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(withTranslation()(ApplicantInfo));
