import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal, Select, SelectItem, InlineNotification,
} from 'carbon-components-react';
import { getCustomEmailTemplates } from 'actions/email-templates';

class EmailTemplatesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noTemplateError: false,
      selectedEmailTemplate: 'placeholder-item',
    };
  }

  componentDidMount() {
    this.props.getCustomEmailTemplates();
  }

  onEmailTemplateChange = (e) => {
    const selectedEmailTemplate = e.target.value;
    this.setState({ selectedEmailTemplate, noTemplateError: false });
  }

  render() {
    const {
      isOpen, emailTemplates,
      templateModalClose, t,
      templateModalSubmit,
    } = this.props;
    const { noTemplateError, selectedEmailTemplate } = this.state;
    return (
      <Modal
        onRequestSubmit={() => templateModalSubmit(selectedEmailTemplate)}
        onRequestClose={templateModalClose}
        open={isOpen}
        modalLabel="Email Templates"
        primaryButtonText="Submit"
        secondaryButtonText="Cancel"
        modalHeading="Send Mail"
      >
        <div className="bx--modal-content__text">
          {
            noTemplateError && <InlineNotification lowContrast kind="error" title="No template selected" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ noTemplateError: false })} />
          }
          <Select
            labelText="Select Email Template"
            className="mb-4"
            id="email-templates-list"
            onChange={this.onEmailTemplateChange}
            value={selectedEmailTemplate}
            defaultValue="placeholder-item"
          >
            <SelectItem disabled value="placeholder-item" text="Choose a template" />
            {
              emailTemplates && Array.isArray(emailTemplates)
                && emailTemplates.map(({ displayName = '', id = null, templateName = '' }) => (<SelectItem key={id} name={templateName} label={displayName} value={`${id}_${templateName}`} />))
            }
          </Select>
          <Fragment>
            <span className="pt-0 pb-1 text-dark d-block">
              {t('clickingOn')} <strong>{t('submit')}</strong> {t('clickingOn2')}
            </span>
          </Fragment>
        </div>
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
  emailTemplates: state.emailTemplates.templates,
});

const mapDispatchToProps = {
  getCustomEmailTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailTemplatesModal));
