import { withTranslation } from 'react-i18next';
import React from 'react';

import './FinalOfferCard.css';

const FinalOfferCard = ({
  name, id, cvLink, tel, email, accept, reject,
}) => (
  <div className="FinalOfferCard">
    <div className="FinalOfferCard__detailsContainer">
      <div className="FinalOfferCard__profileImg">
        <img src={require('./profile-image.png')} alt="" />
      </div>
      <div className="FinalOfferCard__details">
        <table>
          <tr>
            <th>Name/ID:</th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>CV:</th>
            <td>
              <a href={cvLink}>CV</a>
            </td>
          </tr>
          <tr>
            <th>Tel:</th>
            <td>{tel}</td>
          </tr>
          <tr>
            <th> Email: </th>
            <td>{email}</td>
          </tr>
        </table>
      </div>
    </div>
    <div className="FinalOfferCard__decision">
      <div>
        <span>Offer Made</span>
        <div className="FinalOfferCard__decision__icons">
          <span className="FinalOfferCard__decision__icon FinalOfferCard__decision__icon--check">
            <i className="fa fa-check" aria-hidden="true" />
          </span>
          <span className="FinalOfferCard__decision__icon FinalOfferCard__decision__icon--seperator">
            <i className="fa fa-bars" aria-hidden="true" />
          </span>
          <span className="FinalOfferCard__decision__icon FinalOfferCard__decision__icon--times">
            <i className="fa fa-times" aria-hidden="true" />
          </span>
        </div>
      </div>
      <div>
        <span>Offer Accepted/Rejected</span>
        <div className="FinalOfferCard__decision__icons">
          <span className="FinalOfferCard__decision__icon FinalOfferCard__decision__icon--check">
            <i className="fa fa-check" aria-hidden="true" />
          </span>
          <span className="FinalOfferCard__decision__icon FinalOfferCard__decision__icon--seperator">
            <i className="fa fa-bars" aria-hidden="true" />
          </span>
          <span className="FinalOfferCard__decision__icon FinalOfferCard__decision__icon--times">
            <i className="fa fa-times" aria-hidden="true" />
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default FinalOfferCard;
