import { withTranslation } from 'react-i18next';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

/* Common */
import NotFound from 'components/common/NotFound';


import {
  InternCandidate,
} from './robocampus';

/* Misc */
import PrivateRoute from './privateRoute';
// import AdminRoute from './AdminRoute';

const CampusRoutes = ({
  match, isUserLoggedIn, roleId, isAdmin, products,
}) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        isAuthenticated={isUserLoggedIn}
        product="robocampus"
        path={`${match.url}/interncandidate/:stageIndex`}
        component={InternCandidate}
      />
      <Route
        path={`${match.url}/*`}
        render={
          props => (<NotFound {...props} roleId={roleId} />)
        }
      />
    </Switch>
  );
};

export default CampusRoutes;
