import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Button, ToolbarItem,
  Modal, TextInput, Pagination,
} from 'carbon-components-react';
import BackRow from 'components/common/BackRow/BackRow';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  getLanguagesList,
  createLanguageDetails,
  upsertLanguageDetailsById,
  deleteLanguageDetailsById,
} from 'actions/LanguagesView';

class LanguagesView extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'institution', name: 'Browse Languages', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      keyword: '',
      languageId: 0,
      languageFull: '',
      isOpenLanguageModal: false,
      isValidLanguageName: false,
      isValidLanguageNameText: '',
      isOpenDeletLanguageModal: false,
      languageShort: '',
      languageNative: '',
    };
  }

  componentDidMount() {
    this.setState({
      activePage: 1,
      itemsPerPage: 10,
      keyword: '',
    }, () => {
      this.handleLanguageApiCall();
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleLanguageApiCall = () => {
    const {
      activePage,
      itemsPerPage,
      keyword,
    } = this.state;
    const data = {
      activePage,
      itemsPerPage,
      keyword,
    };
    this.props.getLanguagesList(data);
  }

  activeLanguageModal = (data) => {
    if (data) {
      this.setState({
        isOpenLanguageModal: true,
        ...data,
      });
    } else {
      this.setState({
        isOpenLanguageModal: true,
        languageShort: '',
        languageNative: '',
        languageFull: '',
        languageId: 0,
      });
    }
  }

  dismissLanguageModal = () => {
    this.setState({
      isOpenLanguageModal: false,
      languageShort: '',
      languageNative: '',
      languageFull: '',
      isValidLanguageName: false,
      isValidLanguageNameText: '',
      languageId: 0,
    });
  }

  activeDeleteLanguageModal = (languageId) => {
    this.setState({
      isOpenDeletLanguageModal: true,
      languageId,
    });
  }

  dismissDeleteLanguageModal = () => {
    this.setState({
      isOpenDeletLanguageModal: false,
      languageId: 0,
    });
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleLanguageApiCall();
    });
  }

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      this.searchTimer = setTimeout(() => (
        this.handleLanguageApiCall()
      ), 1000);
    });
  }

  handleSubmitLangValues = () => {
    const {
      languageFull,
    } = this.state;
    const {
      t,
    } = this.props;
    let isValidLanguageName = false;
    let isValidLanguageNameText = '';
    if (!languageFull) {
      isValidLanguageName = true;
      isValidLanguageNameText = `${t('langFullValid')}`;
    } else {
      isValidLanguageName = false;
      isValidLanguageNameText = '';
    }
    this.setState({
      isValidLanguageName,
      isValidLanguageNameText,
    }, () => {
      this.handleValidSubmitLang();
    });
  }

  handleValidSubmitLang = () => {
    const {
      isValidLanguageName,
      languageNative,
      languageShort,
      languageFull,
      languageId,
    } = this.state;
    if (isValidLanguageName !== true) {
      const data = {
        languageNative,
        languageShort,
        languageFull,
      };
      if (!languageId) {
        this.props.createLanguageDetails(data).then((res) => {
          if (res && !res.error) {
            this.handleLanguageApiCall();
            this.dismissLanguageModal();
          }
        })
          .catch(e => console.log('Error', e));
      } else {
        data.languageId = languageId;
        this.props.upsertLanguageDetailsById(data).then((res) => {
          if (res && !res.error) {
            this.handleLanguageApiCall();
            this.dismissLanguageModal();
          }
        })
          .catch(e => console.log('Error', e));
      }
    }
  }

  handleDelSubmitlangDet = () => {
    const {
      languageId,
    } = this.state;
    this.props.deleteLanguageDetailsById(languageId).then((res) => {
      if (res && !res.error) {
        this.handleLanguageApiCall();
        this.dismissDeleteLanguageModal();
      }
    })
      .catch(e => console.log('Error', e));
  }

  render() {
    const {
      t,
      languages,
      count,
      loading,
    } = this.props;
    const {
      activePage, isOpenLanguageModal,
      languageId, isValidLanguageName,
      isValidLanguageNameText, isOpenDeletLanguageModal,
    } = this.state;
    const columnWithFilter = [
      {
        title: `${t('language')} ${t('name')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      },
    ];
    const otherActionButtons = [];
    otherActionButtons.push(
      <ToolbarItem>
        <Button
          kind="primary"
          onClick={() => this.activeLanguageModal(null)}
        >
          {t('add')} {t('language')}
        </Button>
      </ToolbarItem>,
    );
    return (
      <Fragment>
        <BackRow paths={this.paths} />
        <div className="bx--type-delta">
          {t('languages')}
        </div>
        <GenericDataTable
          className=""
          otherActions={otherActionButtons}
          headers={
            columnWithFilter.map((data, index) => (
              {
                key: `header_${index}`,
                header: data.title,
                className: data.className,
                colpropName: data.propName,
              }
            ))}
          rows={(languages && Array.isArray(languages)
            && languages.length > 0) ? languages.map((res) => {
              const {
                languageId: propLanguageId,
                languageFull: propLanguageFull,
                languageNative: propLangNative,
                languageShort: propLangShort,
              } = res || {};
              const data = {
                languageId: propLanguageId,
                languageFull: propLanguageFull,
                languageNative: propLangNative,
                languageShort: propLangShort,
              };
              return ({
                isExpanded: true,
                id: `row_${propLanguageId}`,
                header_0: (
                  <div className="text-capitalize font-weight-bold">
                    {propLanguageFull}
                  </div>
                ),
                header_1: (
                  <div className="d-flex justify-content-center">
                    <div>
                      <Button onClick={() => this.activeLanguageModal(data)} kind="tertiary" small>{t('edit')}</Button>
                    </div>
                    <div className="ml-2">
                      <Button onClick={() => this.activeDeleteLanguageModal(propLanguageId)} kind="danger" small>{t('delete')}</Button>
                    </div>
                  </div>
                ),
              });
            }) : []
        }
          selectable={false}
          searchable
          onSearchInputChange={this.handleSearchSubmit}
          expandable={false}
          batchActions={false}
        />
        {
          (count && count > 0) ? (
            <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
          ) : null
        }
        {
          ((!languages) || (languages && Array.isArray(languages) && languages.length <= 0))
            && (
              <div className="bx--tile">
                <div className="text-center">
                  <div className="empty-icon">
                    <i className="fa fa-flag fa-2x" />
                  </div>
                  <p className="empty-title h5">{t('noLangMsg')}</p>
                  <Button
                    className="mr-2"
                    onClick={() => this.activeLanguageModal(null)}
                    kind="primary"
                  >
                    {t('add')} {t('language')}
                  </Button>
                </div>
              </div>
            )
        }
        <Modal
          open={isOpenLanguageModal}
          modalHeading={`${!languageId ? t('add') : t('edit')} ${t('language')}`}
          modalLabel={`${t('language')}`}
          primaryButtonText={!languageId ? t('add') : t('save')}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissLanguageModal}
          onRequestSubmit={this.handleSubmitLangValues}
        >
          <div>
            <TextInput
              className="mb-2"
              name="languageFull"
              id="languageFull"
              labelText={`${t('language')} ${t('name')}`}
              value={this.state.languageFull}
              placeholder={`${t('language')} ${t('name')}`}
              invalid={isValidLanguageName}
              invalidText={isValidLanguageNameText}
              onChange={e => this.handleChange(e)}
            />
            <TextInput
              className="mb-2"
              name="languageShort"
              id="languageShort"
              labelText={`${t('language')} ${t('short')}`}
              value={this.state.languageShort}
              placeholder={`${t('language')} ${t('short')}`}
              onChange={e => this.handleChange(e)}
            />
            <TextInput
              className="mb-2"
              name="languageNative"
              id="languageNative"
              labelText={`${t('language')} ${t('native')}`}
              value={this.state.languageNative}
              placeholder={`${t('language')} ${t('native')}`}
              onChange={e => this.handleChange(e)}
            />
          </div>
        </Modal>
        <Modal
          open={isOpenDeletLanguageModal}
          modalHeading={`${t('delete')} ${t('language')}`}
          modalLabel={`${t('language')}`}
          primaryButtonText={t('delete')}
          danger
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissDeleteLanguageModal}
          onRequestSubmit={this.handleDelSubmitlangDet}
        >
          <div className="text-dark font-weight-bold">
            {t('deleteLanguageMsg')}
          </div>
        </Modal>
        <JDProcessOverlay show={(loading)} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  languages: state.LanguagesView.languages,
  count: state.LanguagesView.count,
  loading: state.LanguagesView.loading,
});

const mapDispatchToProps = {
  getLanguagesList,
  createLanguageDetails,
  upsertLanguageDetailsById,
  deleteLanguageDetailsById,
};

export default
connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LanguagesView));
