import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  Accordion, AccordionItem
} from 'carbon-components-react';
import { getCandidateVIById } from 'actions/videoInterview';

class CandidateVideoInterview extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { profileId } = this.props || {};
    this.props.getCandidateVIById(profileId);
  }

  render() {
    const { loading, videoInterviewRes } = this.props || {};
    const { idata } = videoInterviewRes || {};
    let filtreredIdata = [];
    if (idata && Array.isArray(idata) && idata.length > 0) {
      filtreredIdata = idata.filter((res) => {
        const { qid } = res || {};
        if (
          qid !== 'overall-score'
        ) {
          return true;
        }
        return false;
      });
    }

    return (
      <div>
        {filtreredIdata && Array.isArray(filtreredIdata) && filtreredIdata.length > 0 ? (
          <Accordion>
            {filtreredIdata.map((res, idx) => {
              const {
                qid,
                qtext,
                answer,
              } = res || {};
              const { answerUrl, streaming, answer2Url, answerMp4Url } = answer || {};
              const { url, jwt } = streaming || {};
              const renderHtml = (htmlObject) => {
                return <span dangerouslySetInnerHTML={htmlObject} />;
              };
              var div = document.createElement('div');
              div.innerHTML = qtext;
              const finalQuest = div.textContent || div.innerText || '';
              return (
                qid !== 'overall-score' && (
                  <AccordionItem
                    key={qid}
                    title={(
                      <div className="h6">
                        Q{idx + 1} {renderHtml({ __html: finalQuest })}
                      </div>
                    )}
                  >
                    <div className="ml-4">
                      {answerUrl && (
                        <div>
                          <iframe
                            src={`/public/amp/player?url=${encodeURIComponent(
                              url
                            )}&jwt=${encodeURIComponent(
                              jwt
                            )}&id=${`vid-${qid}`}`}
                            width="400"
                            height="365"></iframe>
                        </div>
                      )}
                      {
                        !answerUrl && answer2Url && (
                          <div>
                            <video
                              id={`vid-${qid}`}
                              class="azuremediaplayer amp-default-skin"
                              controls
                              style={{
                                aspect: 1.32,
                                width: 400,
                                maxWidth: 485,
                                height: 365,
                              }}
                              data-setup='{"nativeControlsForTouch": false, techOrder: ["azureHtml5JS", "flashSS", "html5FairPlayHLS","silverlightSS", "html5"]}'>
                              <source
                                src={answer2Url}
                                type="video/webm"
                              />
                            </video>
                            {
                              answerMp4Url && (
                                <source
                                  src={answerMp4Url}
                                  type="video/mp4"
                                />
                              )
                            }
                          </div>
                        )
                      }
                      {
                        !answerUrl && !answer2Url && (
                          <div className="small bx--type-zeta">
                            Waiting for the video interview response
                          </div>
                        )
                      }
                    </div>

                  </AccordionItem>
                )
              )
            })}
          </Accordion>
        ) : (
          <div className="bx--tile">
            <div className="bx--type-gamma">No Video Interviews Available</div>
          </div>
        )}
        <JDProcessOverlay
          show={loading}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
  loading: state.videoInterviews.loading,
  videoInterviewRes: state.videoInterviews.videoInterviewRes
});

const mapDispatchToProps = {
  getCandidateVIById
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateVideoInterview);
