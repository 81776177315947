import {
  REQUEST_RECRUITERS_LIST,
  SUCCESS_RECRUITERS_LIST,
  ERROR_RECRUITERS_LIST,
} from 'actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  usersList: [],
  count: 0,
};


const RecruitersList = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_RECRUITERS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_RECRUITERS_LIST:
      return {
        ...state,
        usersList: payload && payload.users,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_RECRUITERS_LIST:
      return {
        ...state,
        loading: false,
        error: payload && payload.error,
      };
    default:
      return state;
  }
};

export default RecruitersList;
