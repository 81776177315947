import { withTranslation } from 'react-i18next';
import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import './dropdown.css';

const customStyles = {
  multiValue: (base, state) => ({
    ...base,
    alignItems: 'center',
    height: '30px',
  }),
};

const DropDownBoxWithCreate = ({
  multi, selectedValue, title,
  name, required, options,
  onChange, onInputChange, newOptionCreator,
  isLoading, isClearable = true, placeholder = 'select...',
  disabled, isAsync, custom, className = '', mainClassName = '',
  titleClass = '', selectClass = '', labelClass = '', style,
  hasImages = false, isDisableDropdownClass
}) => (
  <div className={`form-group ${isDisableDropdownClass===false?`dropdown`:''} ${mainClassName === '' ? 'row m-0' : `${mainClassName}`} ${disabled ? 'disabled-select' : ''}`}>
    { title && (
      <div className={`${titleClass || 'col-xs-12 col-sm-12 col-md-3'}`}>
        <label for={name} className={`${labelClass || 'form-label pt-3 pb-0 mb-0'}`}>
          {required ? <div className="bx--row m-0 p-0"><div>{title}</div><div className="text-danger">*</div></div> : title }
        </label>
      </div>
    )}
    <div id={name} className={custom ? `${className}` : `newjob_input_container col-sm-12 col-md-9 ${className}`}>
      {isAsync ? (
        <AsyncCreatableSelect
          className={`education ${selectClass || 'default'}`} //selectClass can be "light", "search", "none".
          isMulti={multi}
          defaultValue={selectedValue}
          isDisabled={disabled}
          required={required}
          style={style}
          styles={hasImages ? customStyles : {}}
          placeholder={placeholder === null || placeholder === undefined ? 'select...' : placeholder}
          isLoading={isLoading}
          isClearable={isClearable}
          isValidNewOption={(inputValue, selectValue, selectOptions) => {
            const isNotDuplicated = !selectOptions
              .map(option => option.label)
              .includes(inputValue);
            const isNotEmpty = inputValue !== '';
            return isNotEmpty && isNotDuplicated;
          }}
          noOptionsMessage={({ inputValue }) => !inputValue && 'Start typing to see options'}
          onChange={value => onChange(value, name)}
          loadOptions={(value, call) => onInputChange(value, call)}
          onCreateOption={value => newOptionCreator(name, value)}
          value={selectedValue || ''}
        />
      ) : (
        <CreatableSelect
          isMulti={multi}
          defaultValue={selectedValue}
          isDisabled={disabled}
          required={required}
          style={style}
          styles={hasImages ? customStyles : {}}
          noOptionsMessage={({ inputValue }) => !inputValue && 'Start typing to see options'}
          placeholder={placeholder === null || placeholder === undefined ? 'select...' : placeholder}
          isLoading={isLoading}
          isClearable={isClearable}
          className={`education ${selectClass || 'default'}`} //selectClass can be "light", "search", "none".
          onChange={(value, e) => onChange(value, name, e)}
          onInputChange={value => onInputChange(value)}
          options={options}
          onCreateOption={value => newOptionCreator(name, value)}
          value={selectedValue || ''}
        />
      )}
    </div>
  </div>
);

export default (withTranslation()(DropDownBoxWithCreate));
