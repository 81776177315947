import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavItemsList from 'components/Header/navItemsList';
import {
  HeaderNavigation,
  HeaderMenu,
  HeaderMenuItem,
} from 'carbon-components-react/lib/components/UIShell';
import classnames from 'classnames';

import './HeaderWithActions.css'

class HeaderWithActions extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleRedirect = (path) => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push(path);
    }
  };

  render() {
    const {
      user: {
        roleId,
        company,
        isAdmin,
        isSuperAdmin,
      } = {},
      t,
      handleShowSubMenu,
      showSubItems,
      propLabel,
    } = this.props || {};
    const {
      ctypeId: propCtypeId,
    } = company || {};
    const {
      showModal,
    } = this.state;
    return (
      <Fragment>
        <HeaderNavigation aria-label="GEIP">
          {
            NavItemsList && Array.isArray(NavItemsList)
              && NavItemsList.length > 0
              && NavItemsList.map((navItems, idx) => {
                const {
                  label,
                  path,
                  roles,
                  ctypeId,
                  subItems,
                  desc,
                  cxName,
                } = navItems || {};

                const cx = classnames('x-nav-item', cxName);

                return (
                  subItems && Array.isArray(subItems)
                    && subItems.length <= 0
                    && roles && Array.isArray(roles) && roles.includes(roleId)
                    && (roleId === 3 ? true : (((isSuperAdmin === true || isAdmin === true || (propCtypeId && Number(propCtypeId) === 1)) && ctypeId.includes(0)) ? true : (ctypeId && ctypeId.includes(Number(propCtypeId)))))
                    ? (
                      <HeaderMenuItem className={cx} onClick={() => handleShowSubMenu(subItems, label, false)} key={`${label}-${idx + 1}`} element={Link} to={path}>
                        <b>{t(label)}</b>
                      </HeaderMenuItem>
                    ) : (
                      roles && Array.isArray(roles) && roles.includes(roleId)
                    && (roleId === 3 ? true : (((isSuperAdmin === true || isAdmin === true || (propCtypeId && Number(propCtypeId) === 1)) && ctypeId.includes(0)) ? true : (ctypeId && ctypeId.includes(Number(propCtypeId)))))
                    && (
                      <HeaderMenuItem onClick={() => handleShowSubMenu(subItems, label, true, desc)} key={`${label}-${idx + 1}`}>
                        <b>
                          {t(label)}
                        </b>&nbsp;&nbsp;
                          {
                            (propLabel === label && showSubItems === true) ? (
                              <i className="fa fa-chevron-up" />
                            ) : (
                              <i className="fa fa-chevron-down" />
                            )
                          }
                      </HeaderMenuItem>
                    )
                    )
                );
              })
          }
        </HeaderNavigation>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  taskStatus: state.taskStatus,
});

export default connect(mapStateToProps, {})(withTranslation()(HeaderWithActions));
