import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import defaultAvatar from 'assets/images/user.svg';
import { Pagination, Button, DataTable, Search, Tag, Modal, InlineNotification, Tile } from 'carbon-components-react';
import moment from 'moment';
import './gtp.css';

//import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';

import { PerPageDropDown, KeywordFilter } from 'components/common/common';
import Toast from 'components/common/Toast';
import { filterJobData, addProfileToJob } from 'actions/talentSearch/jobList';
import BackRow from 'components/common/BackRow/BackRow';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import EmptyGpt from './EmptyGpt';
import ProfileRow from './ProfileRow';
import LeftNavFilter from 'containers/common/leftNavFilter/leftNavFilter';

import { reRenderContent } from 'actions/gtp';

const { TableContainer, Table, TableRow, TableBody, TableCell, TableHead, TableHeader } = DataTable;

const NoContentBox = () => (
	<Tile className="">
		<div className="card-body text-center">
			<div className="empty-icon">
				<i className="fa fa-flag fa-2x" />
			</div>
			<p className="empty-title h5">There are no candidates for your current selection.</p>
		</div>
	</Tile>
);

class GlobalTalentPool extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			perPageNumber: 10,
			activePage: 1,
			keyword: '',
			Filter: [],
			selected: {},
			checked: [],
			headersInfo: [ { key: 'name', header: 'Name' } ],
			rowsData: [],
			selectedId: 0,
			showAddToJobModel: false,
			selectedJobId: 0,
			jobSearchKey: '',
			jobsRows: [],
			jobsHeaders: [
				{ key: 'job', header: 'Job' },
				{ key: 'company', header: 'Company' },
				{ key: 'action', header: 'Action' }
			],
			jobsLimit: 5,
			jobsskip: 0,
			totalJobs: 0,
			addedToJobs: []
		};

		this.paths = [
			{ id: 'campus', name: 'Campus', href: '/app/campus' },
			{
				id: 'gtp',
				name: 'Global Talent Pool',
				href: '/app/campus/candidates/xopa'
			}
		];
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	itemsPerPageChanged = (e) => {
		this.props
			.reRenderContent({
				itemsPerPage: e.target.value,
				activePage: 1,
				searchKey: this.state.keyword,
				selected: this.state.selected
			})
			.then(() => this.setRows());
		this.handleChange(e);
	};

	componentDidMount() {
		this.props
			.reRenderContent({
				itemsPerPage: this.state.perPageNumber,
				activePage: 1,
				searchKey: this.state.keyword,
				selected: this.state.selected
			})
			.then(() => this.setRows());
	}

	handlePaginationChange = (data) => {
		const activePage = data.page || 1;
		const itemsPerPage = data.pageSize || this.state.itemsPerPage;
		this.setState({ activePage }, () => {
			this.props
				.reRenderContent({
					itemsPerPage,
					activePage: activePage,
					searchKey: this.state.keyword,
					selected: this.state.selected
				})
				.then(() => this.setRows());
		});
	};

	CardRowBox = (rowData) => {
		const { currCompany = {}, firstName, lastName, picture, personDocuments = [] } = rowData;
		return (
			<div className="bx--tile pl-0 m-0 row">
				<div className="bx--col-lg">
					<div className="gtpcandidateInfo p-0 row">
						<div className="col-lg-2 mr-4 p-0 mr-lg-0">
							<div className="flex-column text-sm-center text-md-center text-center">
								<img src={picture ? picture : defaultAvatar} className="rounded-circle" />
							</div>
						</div>
						<div
							className="col-lg-10 text-sm-center text-md-center text-center text-lg-left"
							style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
						>
							{firstName && lastName && firstName.length > 0 && lastName.length > 0 ? (
								<div
									className="mb-1 h5 ml-lg-2 font-weight-bold"
									title="Name"
								>{`${firstName} ${lastName}`}</div>
							) : (
								<div className="mb-1 h5 ml-lg-2 font-weight-bold" title="Name">{`Unknown`}</div>
							)}
							{currCompany &&
							currCompany != null &&
							currCompany.jobname &&
							currCompany.jobname != '-' && (
								<div
									className="font-weight-light ml-lg-2 bx--modal-header__label text-dark font-weight-bold m-0"
									style={{ color: '#5596e6' }}
									title="Current Title"
								>{`${currCompany.jobname}`}</div>
							)}
							{currCompany &&
							currCompany != null &&
							currCompany.company &&
							currCompany.company != '-' && (
								<div
									className="h6 text-info m-0 ml-lg-2"
									style={{ color: '#5596e6' }}
									title="Current Company"
								>{`${currCompany.company}`}</div>
							)}
							{currCompany &&
							currCompany != null &&
							currCompany.startDate &&
							currCompany.startDate != null && (
								<div className="text-muted ml-lg-2" title="Working Since">
									{
										<small className="mt-1">
											<i className="fa fa-calendar" />
											{`  ${moment(currCompany.startDate).format(
												'MMM DD YYYY'
											)} - ${currCompany.endDate && currCompany.endDate != null
												? moment(currCompany.endDate).format('MMM DD YYYY')
												: 'present'}`}
										</small>
									}
								</div>
							)}
							{currCompany.location &&
							currCompany.location != null &&
							currCompany.location != '-' && (
								<div className="row">
									<div className="col-lg-10 ml-lg-2 pl-0 text-black-50">
										<small className="col-8" title="Location">
											<i className="fa fa-map-marker" />
											{`  ${currCompany.location}`}
										</small>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<hr />
				<div
					className={`bx--row align-items-center p-0 justify-content-center mt-lg-4 mt-sm-1 
        ${personDocuments && personDocuments.length > 0 ? 'bx--col-lg-3' : 'bx--col-lg-2  ml-lg-5'}`}
				>
					{personDocuments &&
					personDocuments.length > 0 && (
						<div className="openCVBtn">
							<a
								className="bx--btn bx--btn--primary text-sm-center text-md-center"
								style={{ height: 33 }}
								target="_blank"
								href={`/view/${personDocuments[0].cvLink}/cv`}
							>
								View CV
							</a>
							{/* <Button kind="tertiary" className='mt-3 p-0' style={{ borderRadius: "2px", height: 35, width:89.24 }} >Add to job</Button> */}
						</div>
					)}
					<Button kind="tertiary" className="ml-3" name={rowData.profileId} onClick={this.addToJob} small>
						Add to Job
					</Button>
				</div>
			</div>
		);
	};

	setRows = () => {
		const Jobs = (this.props.profileData.globalProfiles && this.props.profileData.globalProfiles.jobs) || [];
		const rowsData =
			(Jobs &&
				Array.isArray(Jobs) &&
				Jobs.map((row) => {
					row = row._source;
					const { we = [], avatar } = row; //currency: { currencyShort = '' } = {}, salary
					let picture = avatar;
					if (avatar && !(avatar.indexOf('http:') >= 0 || avatar.indexOf('https:') >= 0)) {
						picture = `/api/v1/Images/x0pa-profile-images/download/${picture}?_=${new Date().getTime()}`;
					}
					// Need to condider multiple current jobs based on discussion
					const com = we.map((e) => {
						const {
							startDate = '-',
							endDate = '-',
							job: {
								company: { companyName = '-' } = {},
								jobname: { nameName = '-' } = {},
								jobfunction: { functionName = '-' } = {},
								jobseniority: { seniorityName = '-' } = {},
								qualificationtype: { typeName = '-' } = {},
								office: { address: { location: { locationName = '-' } = {} } = {} } = {}
							} = {}
						} = e;
						return {
							startDate,
							endDate,
							company: companyName,
							function: functionName,
							seniority: seniorityName,
							qualification: typeName,
							location: locationName,
							jobname: nameName
						};
					});
					const { firstName = '', lastName = '', totalExp = 0, personDocuments = [] } = row;
					const currCompany = com[0];
					const RowData = {
						firstName,
						lastName,
						totalExp,
						currCompany,
						personDocuments,
						picture
					};
					const oneRow = {
						id: row.profileId.toString(),
						name: this.CardRowBox(RowData)
					};
					return oneRow;
				})) ||
			[];
		this.setState({ rowsData });
	};

	setSelected = (selected, Filter, checked) => {
		this.setState({ selected, Filter, checked }, () => {
			this.props
				.reRenderContent({
					itemsPerPage: this.state.itemsPerPage,
					activePage: 1,
					searchKey: this.state.keyword,
					selected: this.state.selected
				})
				.then(() => this.setRows());
		});
	};

	searchTimer = null;

	handleSearchSubmit = (event) => {
		if (this.searchTimer) {
			clearTimeout(this.searchTimer);
		}
		this.setState({ keyword: event.target.value, activePage: 1 });
		this.searchTimer = setTimeout(
			() =>
				this.props
					.reRenderContent({
						itemsPerPage: this.state.itemsPerPage,
						activePage: this.state.activePage,
						searchKey: this.state.keyword,
						selected: this.state.selected
					})
					.then(() => this.setRows()),
			1000
		);
	};

	handleJobsPaginationChange = (data) => {
		const jobsskip = data.page ? (data.page - 1) * data.pageSize : 1;
		const jobsLimit = data.pageSize || this.state.jobsLimit;
		this.setState({ jobsskip, jobsLimit }, () => {
			this.onSearchJobChange({ target: { value: '' } });
		});
	};

	showJobsToAdd = () => {
		const { selectedId, jobsskip = 0, jobsLimit = 5, jobSearchKey = '', addedToJobs = [] } = this.state;
		const qs = `&filter[skip]=${jobsskip}&filter[limit]=${jobsLimit}&searchKey=${jobSearchKey}&status=open`;
		this.props.filterJobData(qs).then((res) => {
			const { count = 0, jobs = [] } = res;
			const jobsRows = jobs.map((row) => {
				return {
					id: row.jobId.toString(),
					job: (
						<div className="text-sm-center text-md-center text-center text-lg-left">
							<div className="text-dark">
								<small title="Job">{`Requisition ID: ${row.jobId}`}</small>
							</div>
							<div
								title="Designation"
								className="card-title h6 mb-1 d-inline-block text-center text-uppercase"
							>
								{row.jobname.nameName}
							</div>
						</div>
					),
					company: row.company.companyName,
					action: (
						<Button kind="tertiary" small onClick={() => this.onAddJob(row.jobId, Number(selectedId))}>
							Add
							<svg
								className="bx--btn__icon"
								width="16"
								height="16"
								viewBox="0 0 16 16"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7 7H4v2h3v3h2V9h3V7H9V4H7v3zm1 9A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"
									fillRule="evenodd"
								/>
							</svg>
						</Button>
					)
				};
			});
			this.setState({ jobsRows, totalJobs: count });
		});
	};

	searchJobTimer = null;

	onSearchJobChange = ({ target: { value = '' } } = {}) => {
		if (this.searchJobTimer) {
			clearTimeout(this.searchJobTimer);
		}
		this.setState({ jobSearchKey: value }, () => {
			if (value.length > 2) this.searchJobTimer = setTimeout(this.showJobsToAdd, 1000);
		});
	};

	addToJob = ({target:{name}})=>{
		this.setState({selectedId : name, showAddToJobModel : true, jobSearchKey:"", jobsRows:[], jobsskip:0},)
	  }

	onAddJob = (jobId = 0, profileId = 0) => {
		let { addedToJobs } = this.state;
		addedToJobs.push(jobId);
		this.setState(
			{ selectedId: 0, showAddToJobModel: false, addedToJobs, jobSearchKey: '', jobsRows: [], jobsskip: 0 },
			() => {
				this.props.addProfileToJob(jobId, profileId);
			}
		); //.then(this.showJobsToAdd)
	};

	closeAddToJob = () => {
		this.setState({ selectedId: 0, showAddToJobModel: false, jobSearchKey: '', jobsRows: [], jobsskip: 0 });
	};

	toastSuccess = () => {
		setTimeout(() => {
			window.location.reload();
		}, 5000);
	};

	render() {
		const { globalProfiles: { jobs = [], count } } = this.props.profileData;
		const {
			perPageNumber,
			Filter,
			rowsData,
			headersInfo,
			selected,
			checked,
			jobsRows,
			jobsHeaders,
			jobSearchKey,
			totalJobs
		} = this.state;
		const filter = JSON.stringify(Filter);
		const url = `/profile-base/filterFacets?filters=${encodeURIComponent(filter ? `${filter}` : '[]')}`;
		return (
			<div className="xpav-gpt">
				<BackRow paths={this.paths} />
				{this.props.jobTaskId && (
					<InlineNotification lowContrast
						kind="success"
						title="This profile"
						subtitle="will be added to the selected job momentarily."
						hideCloseButton
					/>
				)}
				<h4 className="bx--type-gamma font-weight-bold text-capitalize">Global Talent Pool</h4>
				<Toast taskId={this.props.jobTaskId} successAction={this.toastSuccess} partialRefresh={() => {}} />
				<div className="bx--row p-lg-2">
					<LeftNavFilter url={url} className="" setSelected={this.setSelected} />
					<div className="bx--col-lg bx--col-xs-12 p-0">
						<div className="row ml-0 mr-0" style={{ maxWidth: 500 }}>
							<Search
								light
								type="text"
								className="SearchGtp pl-0 bx--col-xs-12 bx--col-sm-12 mt-2"
								labelText="Search job"
								closeButtonLabelText=""
								placeHolderText="Search pool"
								onChange={this.handleSearchSubmit}
								id="search pool"
								value={this.state.keyword}
							/>
						</div>
						{checked &&
						Array.isArray(checked) &&
						checked.length > 0 && (
							<div className="mt-2">
								<label className="bx--label">Selected Filters:&nbsp;</label>
								{checked.map((ele, key) => (
									<Tag type="private" key={key}>
										{ele}
									</Tag>
								))}
							</div>
						)}
						<DataTable
							rows={rowsData}
							headers={headersInfo}
							render={({ rows, headers, getHeaderProps }) => {
								return (
									<TableContainer title="" className="">
										<Table
											style={{
												borderSpacing: '0px 6px',
												borderCollapse: 'separate'
											}}
										>
											<TableBody className="bg-transparent">
												{rows.map((row) => (
													<TableRow key={row.id} className="mb-2">
														{row.cells.map((cell) => (
															<TableCell key={cell.id} className="pr-0 pl-0">
																{cell.value}
															</TableCell>
														))}
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								);
							}}
						/>
						{jobs &&
						jobs.length > 0 && (
							<div className="">
								<Pagination
									onChange={this.handlePaginationChange}
									pageSizes={[ 10, 20, 30, 40, 50 ]}
									page={this.state.activePage}
									totalItems={count}
								/>
							</div>
						)}
						<JDProcessOverlay show={this.props.loading || this.props.jobsLoading} message="processing..." />
						{this.state.rowsData && this.state.rowsData.length <= 0 && <NoContentBox />}
					</div>
				</div>
				<Modal
					onRequestClose={this.closeAddToJob}
					passiveModal
					open={this.state.showAddToJobModel}
					modalLabel="Search Job"
					modalHeading="Add to Job"
				>
					<Search
						className="mb-2"
						value={jobSearchKey}
						labelText="Search Jobs"
						placeHolderText="Enter job title"
						onChange={this.onSearchJobChange}
					/>
					{jobsRows.length > 0 && (
						<div>
							<DataTable
								rows={jobsRows}
								headers={jobsHeaders}
								render={({ rows, headers, getHeaderProps }) => (
									<TableContainer title="Jobs">
										<Table>
											<TableHead>
												<TableRow>
													{headers.map((header) => (
														<TableHeader {...getHeaderProps({ header })}>
															{header.header}
														</TableHeader>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{rows.map((row) => (
													<TableRow key={row.id}>
														{row.cells.map((cell) => (
															<TableCell key={cell.id}>{cell.value}</TableCell>
														))}
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								)}
							/>
							<Pagination
								onChange={this.handleJobsPaginationChange}
								pageSizes={[ 5, 10, 15, 20, 25 ]}
								page={this.state.activePage}
								totalItems={this.props.jobs.count}
							/>
						</div>
					)}
					{totalJobs === 0 &&
					jobSearchKey.length > 0 && (
						<Tile className="">
							<div className="card-body text-center ">
								<div className="empty-icon">
									<i className="icon icon-3x icon-people" />
								</div>
								<p className="empty-title h5">There are no jobs to show for the current Search</p>
							</div>
						</Tile>
					)}
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.gtp.loading,
	error: state.gtp.error,
	profileData: state.gtp,
	jobs: state.jobs,
	jobsLoading: state.jobs.loading,
	jobTaskId: state.jobs.task_id
});

const mapDispatchToProps = {
	reRenderContent,
	filterJobData,
	addProfileToJob
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalTalentPool);
