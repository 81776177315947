import callApi from 'utils/callApi';
import { baseUrl, regularUrl } from 'utils/config';
import {
  REQUEST_BROWSE_EMPLOYEE_INFO,
  REQUEST_BROWSE_EMPLOYEE_ID_INFO,
  REQUEST_BROWSE_EMPLOYEE_JOBS_INFO,
  REQUEST_CONFIRM_COMPANY_EMAIL_INFO
} from './actionTypes';

const getEmployees = data => dispatch => {
  const { 
    itemsPerPage = 10,
    activePage = 1, 
    searchKey = '', 
    filterName_status = [],
    status = '',
    filterName_companyIds = [],
    companyIds = '',
    batch,
    filterName_batch,
    filterName_interns = [],
    interns = '',
    acknowledge = '',
    filterName_acknowledge = []
   } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/companies/browseEmployees?`;
 
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  if (searchKey) {
    url += `searchKey=${searchKey}`;
  }
  
  if (status) {
    filterName_status.map((res) => {
      if (res == 1) {
        url += `&filterArr[${status}]=true`;
      }
      if (res == 2) {
        url += `&filterArr[${status}]=false`;
      }
    });
  }

  if (interns) {
    filterName_interns.map((res) => {
      if (res == 1) {
        url += `&filterArr[${interns}]=true`;
      }
      if (res == 2) {
        url += `&filterArr[${interns}]=false`;
      }
    });
  }

  if (acknowledge) {
    filterName_acknowledge.map((res) => {
      if (res == 1) {
        url += `&filterArr[${acknowledge}]=true`;
      }
      if (res == 2) {
        url += `&filterArr[${acknowledge}]=false`;
      }
    });
  }

  if (batch) {
    url += `&filterArr[batch]=${JSON.stringify(filterName_batch)}`
  }

  if (companyIds) {
    url += `&filterArr[companyIds]=${JSON.stringify(filterName_companyIds)}`
  }
  const params = {
    dispatch,
    actionType: REQUEST_BROWSE_EMPLOYEE_INFO,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getEmployeesById = data => dispatch => {
  const { 
    itemsPerPage = 10,
    activePage = 1, 
    employeeId = 0
   } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/companies/employee/${employeeId}?`;
 
  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  
  const params = {
    dispatch,
    actionType: REQUEST_BROWSE_EMPLOYEE_ID_INFO,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getEmployeesJobs = data => dispatch => {
  const { 
    itemsPerPage = 10,
    activePage = 1, 
    employeeId = 0,
    searchKey
   } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/companies/employee/jobs/${employeeId}?`;

  url += `filter[skip]=${skip}&`;
  url += `filter[limit]=${itemsPerPage}&`;
  
  if (searchKey) {
    url += `searchKey=${searchKey}`;
  }
  
  const params = {
    dispatch,
    actionType: REQUEST_BROWSE_EMPLOYEE_ID_INFO,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const sendCompanyConfirmationMail = (data) => dispatch => {
  const {  
    batch,
    employeeIds
   } = data || {};

  let url = `${baseUrl}/companies/sendConfirmationMailToEmployee`;

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CONFIRM_COMPANY_EMAIL_INFO,
  };
  return callApi(params);
};

export {
 getEmployees,
 getEmployeesById,
 getEmployeesJobs,
 sendCompanyConfirmationMail
};