import React, { Fragment } from 'react';
import useState from "react";
import ReactDOM from "react-dom";

import {
  Accordion, AccordionItem, Tag, ProgressIndicator, ProgressStep, Button,
  TextArea, Select, SelectItem, InlineNotification, TextInput, Form,
  StructuredListWrapper, StructuredListHead, StructuredListRow,
  StructuredListCell, StructuredListBody, Modal
} from 'carbon-components-react';
import moment from 'moment';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

import CreateAnInterviewForm from 'containers/roboroy/interview/createInterviewForm';
// import CreateAssessmentForm from 'containers/roboroy/writtenTest/createassessmentForm';
import StarRatingComponent from 'react-star-rating-component';
import './workflowStages.css';
import CreateWorkflowModal from 'components/roboroy/WorkflowModule/createWorkflow';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import SelectChipWithImage from 'components/common/SelectChipWithImage.js';
import DocumentsUpload from 'containers/roboroy/DocumentsUpload';
import { withTranslation } from 'react-i18next';

import {
  getCurrentStageInProgress,
  getCurrentSubStageInProgress,
  isThereARejectedStage,
  isThisPhoneInterviewSchedulingSubStage,
  isThisOnsiteInterviewSchedulingSubStage,
  canShowScheduleButton,
  isEventCreatedForStage,
  isSchedulingConfirmed,
  isThisOnSiteOrPhoneInterviewSubstage,
  isThisOnlineInterviewSubstage,
  isThisOnlineInterviewSchedulingSubStage,
  isThisPhoneInterviewStage,
} from './workflowUtils';

import ModalStateManager from "../common/modalStateManager";

import {
  AssignedUserAvatar,
  ScheduleSubStageAction,
  SlotsPicker,
} from './workflowHelpers';


const STAGES = {
  SCHEDULING: 'scheduling',
};

//checks if this is the current stage.
const isThisSubStageCurrentlyInProgress = (givenSubStage, currentSubStageInProgress) => {
  // console.log("--- is this in progress = ", currentSubStageInProgress.subStage, givenSubStage, (currentSubStageInProgress.subStage && currentSubStageInProgress.subStage.appStageUserMapId) == (givenSubStage && givenSubStage.appStageUserMapId));
  return ((currentSubStageInProgress.subStage && currentSubStageInProgress.subStage.collaborators && currentSubStageInProgress.subStage.collaborators[0] && currentSubStageInProgress.subStage.collaborators[0].appStageUserMapId) == (givenSubStage && givenSubStage.collaborators && givenSubStage.collaborators[0] && givenSubStage.collaborators[0].appStageUserMapId));
}

const isThisSubStageComplete = (givenSubStage) => {
  return givenSubStage && givenSubStage.collaborators && givenSubStage.collaborators[0] && givenSubStage.collaborators[0].appStageUserFeedbackId;
  //  return (givenSubStage.appStageUserFeedbackId);
}

const isThisStageComplete = (givenStage) => {
  return (givenStage && givenStage.stageMeta && givenStage.stageMeta.outcomeId);
}

const isThisStageCurrent = (givenStage, currentStageInProgress) => {
  return (givenStage && givenStage.stageMeta && givenStage.stageMeta.appStageId) == ((currentStageInProgress && currentStageInProgress.stage && currentStageInProgress.stage.stageMeta && currentStageInProgress.stage.stageMeta.appStageId))
}
//checks if this is a video interview stage
const isThisVideoInterviewStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.workflowstage && givenStage.stageMeta.workflowstage.workflowstagetype && (givenStage.stageMeta.workflowstage.workflowstagetype.stageTypeName == "Video Interview");
}

//checks if this is a video interview stage
const isThisFinalDecisionStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.workflowstage && givenStage.stageMeta.workflowstage.workflowstagetype && (givenStage.stageMeta.workflowstage.workflowstagetype.stageTypeName == "Final Decision");
}
//checks if this is a written assessment stage
const isThisWrittenAssessmentStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.workflowstage && givenStage.stageMeta.workflowstage.workflowstagetype && (givenStage.stageMeta.workflowstage.workflowstagetype.stageTypeName == "Written Assessment");
}

const isThisDocumentCollectionStage = (givenStage) => {
  return givenStage && givenStage.stageMeta && givenStage.stageMeta.workflowstage && givenStage.stageMeta.workflowstage.workflowstagetype && (givenStage.stageMeta.workflowstage.workflowstagetype.stageTypeName == "Document Collection");
}

//checks if this is a scheduling substage
const isThisASchedulingSubStage = (givenSubStage) => {
  return givenSubStage && givenSubStage.subStageMeta && (givenSubStage.subStageMeta.substage.toLowerCase() == "scheduling");
}

const isThisVideoInterviewSchedulingSubStage = (givenStage, givenSubStage) => {
  return (isThisVideoInterviewStage(givenStage) && isThisASchedulingSubStage(givenSubStage));
}

const isThisWrittenAssessmentSchedulingSubStage = (givenStage, givenSubStage) => {
  return (isThisWrittenAssessmentStage(givenStage) && isThisASchedulingSubStage(givenSubStage));
}

const isThisDocumentCollectionSchedulingSubstage = (givenStage, givenSubStage) => {
  return (isThisDocumentCollectionStage(givenStage) && isThisASchedulingSubStage(givenSubStage));
}

const isThisFeedbackSubstage = (givenSubStage) => {
  return (givenSubStage && givenSubStage.subStageMeta && givenSubStage.subStageMeta.substage.toLowerCase() == "feedback");
}

const isThisDocumentSubstage = (givenSubStage) => {
  return (givenSubStage && givenSubStage.subStageMeta && givenSubStage.subStageMeta.substage.toLowerCase() == "document");
}

const isThisDecisionSubstage = (givenSubStage) => {
  return (givenSubStage && givenSubStage.subStageMeta && givenSubStage.subStageMeta.substage.toLowerCase() == "decision");
}

const isCurrentUserAdminLevelCollaborator = (user, collaborationList) => {
  if (user && collaborationList && Array.isArray(collaborationList)) {
    let userId = user && user.userId;
    let foundUserList = collaborationList.filter(eachCollaborator => eachCollaborator && eachCollaborator.userId == userId && ((eachCollaborator.roleId == 1) || (eachCollaborator.roleId == 4)));
    const arrLength = foundUserList && Array.isArray(foundUserList) && foundUserList.length > 0;
    const superAdmin = user && user.isSuperAdmin === true;
    const admin = user && user.isAdmin === true && user.currentCompany && user.currentCompany.ctypeId == 1;
    return (arrLength || superAdmin || admin);
  }
  return false;
}
//checks if we are currently waiting on user to provide feedback/complete an action on this substage.
const shouldUserActOnThisSubStage = (user, application, givenSubStage, currentSubStageInProgress) => {
  //if the sub-stage is assigned to the current user and if this substage is the current substage and if feedback is not given, then true
  // console.log("shouldUserActOnThisSubStage = ", givenSubStage, currentSubStageInProgress, user, isThisSubStageCurrentlyInProgress(givenSubStage, currentSubStageInProgress));
  //if the current user is a collabortor for this job and is an administrator.
  return ((givenSubStage && ((givenSubStage.isAssignedToCurrentUser)
    // && !givenSubStage.appStageUserFeedbackId
    && !((givenSubStage && givenSubStage.collaborators && givenSubStage.collaborators[0] && givenSubStage.collaborators[0].appStageUserFeedbackId)) || (user && user.isSuperAdmin === true) || (user && user.isAdmin === true))
    && isThisSubStageCurrentlyInProgress(givenSubStage, currentSubStageInProgress)))
  // && ((currentSubStageInProgress && currentSubStageInProgress.subStage && currentSubStageInProgress.subStage.appStageUserMapId) == givenSubStage.appStageUserMapId)));
}

//gets the badge class to use for a given stage
const getStageBadge = (givenStage) => {

}

let feedbackForCurrentSubStageInProgress = '';
let rating;

//gets the badge class to use for substage outcome
const getBadgeColorForSubStageOutcome = (outcomeId) => {
  let badgeColor;
  // console.log(outcomeId);
  switch (parseInt(outcomeId, 10)) {
    case 6000:
      badgeColor = 'text-success';
      break;
    case 6001:
      badgeColor = 'text-danger';
      break;
    case 6005:
      badgeColor = 'text-success';
      break;
    case 6004:
      badgeColor = 'text-dark';
      break;
    default:
      badgeColor = 'text-warning';
  }
  return badgeColor;
};

// const handleUserSubStageCompletion = ({appStageUserMapId}) => {
//   this.props.handleUserSubStageCompletion({appStageUserMapId, feedback: ' ', outcomeId:6005});
// }collaboration

const StageStatus = ({ t, workflow, collaboration, user, currentStageInProgress, index, eachStage, hasARejectedStage, handleDeleteStage, handleSkipStage }) => {
  return (
    <span>
      {
        eachStage
          && eachStage.stageMeta
          && eachStage.stageMeta.workflowstage
          && eachStage.stageMeta.workflowstage.workflowstagetype
          && eachStage.stageMeta.workflowstage.workflowstagetype.stageTypeName
          && eachStage.stageMeta.workflowstage.workflowstagetype.stageTypeName !== 'Offer Round'
          ? (
            <span>
              {
                (index == currentStageInProgress.index) ?
                  (
                    <span>
                      {eachStage.stageMeta && eachStage.stageMeta.outcomeId && <Tag className="ml-2" type="green">{t('completed')}</Tag>}
                      {eachStage.stageMeta && !eachStage.stageMeta.outcomeId && !hasARejectedStage && <Tag className="ml-2" type="cool-gray">{t('offersInProgressTab')}</Tag>}
                      {eachStage.stageMeta && !isThisFinalDecisionStage(eachStage) && !eachStage.stageMeta.outcomeId && !hasARejectedStage && user && user.isSuperAdmin === true && <Button small kind="tertiary"
                        onClick={(e) => { e.stopPropagation(); handleSkipStage(eachStage.stageMeta.appStageId, workflow && workflow.workflowId); }}
                        className="ml-1 mt-md-0 mt-2">
                        {t('skipThisStage')}
                      </Button>}

                      {eachStage.stageMeta && !eachStage.stageMeta.outcomeId && hasARejectedStage && <Tag className="ml-2" type="beta">{t('notStarted')}</Tag>}
                    </span>
                  )
                  : (
                    <span>
                      {eachStage.stageMeta && eachStage.stageMeta.outcomeId && <Tag className="ml-2" type="green">{t('completed')}</Tag>}
                      {eachStage.stageMeta && !eachStage.stageMeta.outcomeId && <Tag className="ml-2" type="beta">{t('notStarted')}</Tag>}
                      {/* {eachStage.stageMeta && !eachStage.stageMeta.outcomeId && (isCurrentUserAdminLevelCollaborator(user, collaboration)) && <Button small kind="danger"
                        onClick={(e) => { e.stopPropagation(); handleDeleteStage(workflow.workflowId, workflow.stageConfig, eachStage.stageMeta.stageId);  }}
                        className="ml-1">
                          Delete this stage
                      </Button>
                      }           */}
                    </span>
                  )
              }
            </span>
          ) : null
      }
    </span>
  );
}

const StageOutcome = ({ eachStage }) => (
  <span>
    {
      isThisStageComplete(eachStage) && (
        <strong className={`pl-2 ${getBadgeColorForSubStageOutcome(eachStage && eachStage.stageMeta && eachStage.stageMeta.outcomeId)} text-uppercase`}>{eachStage && eachStage.stageMeta && eachStage.stageMeta.outcome && eachStage.stageMeta.outcome.outcome}</strong>
      )
    }
  </span>
);

const StageProgressIndicator = ({ t, eachStage }) => (
  <div>
    {
      eachStage && eachStage.subStages ? (
        <ProgressIndicator currentIndex={getCurrentSubStageInProgress(eachStage).index}>
          {eachStage.subStages.map((eachSubStage) => (
            <ProgressStep label={eachSubStage && eachSubStage.subStageMeta && eachSubStage.subStageMeta.substage} />
          ))}
        </ProgressIndicator>
      ) : (<div>{t('noSTageDefined')}</div>)
    }
  </div>
);

const SubStageStatus = ({ t, eachSubStage, currentSubStageInProgress }) => (
  <div className="bx--structured-list-row border-0 ">
    <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0  bx--structured-list-content--nowrap bx--modal-header__label">{t('status')}:</div>
    <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0  text-warning">
      {isThisSubStageCurrentlyInProgress(eachSubStage, currentSubStageInProgress) ? (
        <strong className="text-warning text-uppercase">{t('offersInProgressTab')}</strong>
      ) : isThisSubStageComplete(eachSubStage) ? (
        <strong className={`${getBadgeColorForSubStageOutcome(eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserFeedbackId && eachSubStage.collaborators[0].subStageFeedback && eachSubStage.collaborators[0].subStageFeedback.outcome && eachSubStage.collaborators[0].subStageFeedback.outcome.outcomeId)} text-uppercase`}>{eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserFeedbackId && eachSubStage.collaborators[0].subStageFeedback && eachSubStage.collaborators[0].subStageFeedback.outcome && eachSubStage.collaborators[0].subStageFeedback.outcome.outcome}</strong>
      ) : (
        <strong className="text-dark text-uppercase">{t('notStarted')}</strong>
      )
      }
    </div>
  </div>
);

const EventInfo = ({ t, mobileNumber, eachStage, user }) => {
  const { stageMeta } = eachStage || {};
  const { calendarevents } = stageMeta || {};
  const { zoommeetings } = calendarevents || {};
  const { startUrl, meetingId, password } = zoommeetings || {};
  const { roleId } = user || {};
  return (
    <section className="bx--structured-list pb-0 mb-0">
      <div className="bx--structured-list-tbody">

        {!meetingId && mobileNumber && (roleId != 3) && (
          <div className="bx--structured-list-row border-0 ">
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell pl-0 pt-1 pb-1 bx--structured-list-content--nowrap bx--modal-header__label">Phone Number:</div>
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0 pl-3">
              <div className="bx--row">
                <small className="bx--col-xs-6">
                  {mobileNumber}
                </small>
              </div>
            </div>
          </div>
        )}

        {meetingId && (roleId != 3) && (
          <div className="bx--structured-list-row border-0 ">
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell pl-0 pt-1 pb-1 bx--structured-list-content--nowrap bx--modal-header__label">Meeting ID:</div>
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0">
              <div className="bx--row">
                <small className="bx--col-xs-6">
                  {meetingId}
                </small>
              </div>
            </div>
          </div>
        )}

        {meetingId && (roleId != 3) && startUrl && (
          <div className="bx--structured-list-row border-0 ">
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell pl-0 pt-1 pb-1 bx--structured-list-content--nowrap bx--modal-header__label">Meeting Link:</div>
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0">
              <div className="bx--row">
                <small className="bx--col-xs-6">
                  <a href={startUrl} className="bx--btn bx--btn--tertiary bx--btn--sm">Start Meeting Link</a>
                </small>
              </div>
            </div>
          </div>
        )}

        {meetingId && (roleId != 3) && password && (
          <div className="bx--structured-list-row border-0 ">
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell pl-0 pt-1 pb-1 bx--structured-list-content--nowrap bx--modal-header__label">Meeting Password:</div>
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0">
              <div className="bx--row">
                <small className="bx--col-xs-6 bx--type-strong">
                  {password}
                </small>
              </div>
            </div>
          </div>
        )}

        {calendarevents && calendarevents.start && (
          <div className="bx--structured-list-row border-0 ">
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell pl-0 pt-1 pb-1 bx--structured-list-content--nowrap bx--modal-header__label">Event start:</div>
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0 pl-3">
              <div className="bx--row">
                <small className="bx--col-xs-8">
                  {moment(eachStage.stageMeta.calendarevents.start).utc().format('Do MMM YYYY, HH:mm')} SGT
                </small>
              </div>
            </div>
          </div>
        )}

        {/* {calendarevents && calendarevents.end && (
          <div className="bx--structured-list-row border-0 ">
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell pl-0 pt-1 pb-1 bx--structured-list-content--nowrap bx--modal-header__label">Event end:</div>
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0 pl-3">
              <div className="bx--row">
                <small className="bx--col-xs-6">
                  {moment(calendarevents.end).utc().format('Do MMM YYYY, HH:mm')} SGT
                </small>
              </div>
            </div>
          </div>
        )} */}

        {calendarevents && calendarevents.eventMeta && calendarevents.eventMeta.eventAdditionalInfo && (
          <div className="bx--structured-list-row border-0 ">
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell pl-0 pt-1 pb-1 bx--structured-list-content--nowrap bx--modal-header__label">Additional Info:</div>
            <div className="bx--structured-list-td d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell p-0 m-0 pl-3">
              <div className="bx--row">
                <small className="bx--col-xs-6">
                  {calendarevents.eventMeta.eventAdditionalInfo}
                </small>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const EventSlotsSent = ({ t, eachStage }) => {
  const {
    stageMeta: {
      calendarevents = {},
    } = {},
  } = eachStage || {};
  const { customSlots } = calendarevents || {};
  return (
    <div className="mb-2">
      {
        customSlots && Array.isArray(customSlots) && (customSlots.length > 0) && (
          <Fragment>
            <p className="mb-0">{t('workflowMsg6')}</p>
            <StructuredListWrapper className="mb-0">
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head className="pl-0">
                    {t('date')}
                  </StructuredListCell>
                  <StructuredListCell head className="pl-0">
                    {t('start')} {t('time')}(HH:MM)
                  </StructuredListCell>
                  {/* <StructuredListCell head>
                   {t('end')} {t('time')} (HH:MM)
                  </StructuredListCell> */}
                </StructuredListRow>
              </StructuredListHead>
              <StructuredListBody>
                {
                  customSlots && Array.isArray(customSlots) && (
                    customSlots.map((slot = {}, i) => {
                      const { start, end } = slot || {};
                      return (
                        <StructuredListRow key={`st-${i}`}>
                          <StructuredListCell className="p-1">{moment(start).format('Do MMM YYYY')}</StructuredListCell>
                          <StructuredListCell className="p-1">{moment(start).format('HH:mm')}</StructuredListCell>
                          {/* <StructuredListCell className="p-1">{moment(end).format('HH:mm')}</StructuredListCell> */}
                        </StructuredListRow>
                      );
                    })
                  )
                }
              </StructuredListBody>
            </StructuredListWrapper>
          </Fragment>
        )
      }
    </div>
  );
};

const SubStageUserAssignmentInfo = ({
  workflow, eachStage, user, eachSubStage, collaboration, handleReassignSubStage,
  t,
}) => (
  <div className="bx--structured-list-row border-0">
    <div className="bx--structured-list-td d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell p-0 m-0  bx--structured-list-content--nowrap bx--modal-header__label">{t('assignedTo')}</div>
    <div className="bx--structured-list-td d-none d-sm-none d-md-none d-lg-block d-xl-block pl-0">
      <div className="">
        <div className="">
          {eachSubStage && eachSubStage.subStageFeedback && eachSubStage.subStageFeedback.outcomeId || (isEventCreatedForStage(eachStage) && isThisASchedulingSubStage(eachSubStage)) ? (
            <AssignedUserAvatar eachSubStage={eachSubStage} />
          ) : (
            <div style={{ width: 300, maxWidth: 550 }}>
              <DropDownBox
                title=""
                titleClass=""
                selectClass="light"
                disabled={
                  (eachSubStage.subStageFeedback && eachSubStage.subStageFeedback.outcomeId)
                  || (!isCurrentUserAdminLevelCollaborator(user, collaboration))
                }
                isClearable={false}
                isSearchable={false}
                hasImages
                custom
                className="w-100"
                options={Array.isArray(collaboration) ? collaboration.map(({ userinfo = {} }) => {

                  return ({
                    label: SelectChipWithImage({ picture: (userinfo && userinfo.picture) || '', label: `${(userinfo && userinfo.firstname) || ''} ${(userinfo && userinfo.lastname) || ''}` }),
                    value: (userinfo && userinfo.userId) || 0,
                  });
                }) : []}
                placeholder={t('collaborator')}
                onChange={(collabId, e) => {
                  // if (Array.isArray(e) && e.length === 0) return;
                  // else if (Array.isArray(e) && e.length > 0) {
                  //   const currCollbs = Array.isArray(collaboration) && collaboration.map(({ userId = null, roleId = null }) => ({ userId, roleId }));
                  //   const users = e.map(o => o.value);
                  //   const collabs = currCollbs.filter(({ userId = null }) => users.includes(userId));
                  //   if (collabs.length !== 0 && collabs.length > eachSubStage.collaborators.length ) {
                  //     handleReassignSubStage({
                  //       workflowId: workflow.workflowId,
                  //       appStageId: eachSubStage.appStageId,
                  //       subStageId: eachSubStage.subStageId,
                  //       collabs,
                  //     });
                  //   } else {
                  //     const difference = Array.isArray(eachSubStage.collaborators) && eachSubStage.collaborators.filter(({ userinfo = {} }) => !users.includes( userinfo.userId ))[0].userinfo || {};
                  //     handleReassignSubStage({
                  //       workflowId: workflow.workflowId,
                  //       appStageId: eachSubStage.appStageId,
                  //       subStageId: eachSubStage.subStageId,
                  //       userId: difference.userId,
                  //       roleId: (difference.roleId) || 0,
                  //       deleteCurrId: difference.userId,
                  //     })
                  //   }
                  // }
                  const { collaborators = [] } = eachSubStage;
                  const current = Array.isArray(collaboration) && collaboration.filter(one => one.userId == collabId)[0];
                  const collabs = [{ roleId: current.roleId, userId: current.userId }];
                  handleReassignSubStage({
                    workflowId: workflow.workflowId,
                    appStageId: eachSubStage.appStageId,
                    subStageId: eachSubStage.subStageId,
                    userId: collabId,
                    roleId: current.roleId || 0,
                    deleteCurrId: collaborators[0].userId,
                  });
                }}
                name="collobarator"
                selectedValue={Array.isArray(eachSubStage.collaborators) && eachSubStage.collaborators.map(({ userinfo = {} }) => {
                  return ({
                    label: SelectChipWithImage({ picture: (userinfo.picture) || '', label: `${(userinfo.firstname) || ''} ${(userinfo.lastname) || ''}` }),
                    value: (userinfo.userId) || 0,
                  });
                })}
              />
            </div>
          )}
        </div>
        {/* {eachSubStage.subStageFeedback && eachSubStage.subStageFeedback.outcomeId ? '' : (
        <div className="bx--col-xs-6">
          <Button
            id={`select-change-${eachSubStage.appStageUserMapId}`}
            className="ml-2 mb-3 mt-1"
            onClick={() => {}}
            onFocus={() => {}}
            small
            kind="tertiary"
          >
            Save
          </Button>
        </div>)} */}
      </div>
    </div>
    <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none small_dropdown" style={{ width: 300, maxWidth: 550 }}>
      <DropDownBox
        title={t('assignedTo')}
        titleClass="bx--modal-header__label ml-3"
        selectClass="light"
        disabled={
          (eachSubStage.subStageFeedback && eachSubStage.subStageFeedback.outcomeId)
          || (!isCurrentUserAdminLevelCollaborator(user, collaboration))
        }
        isClearable={false}
        hasImages
        custom
        className="bx--col ml-md-1 ml-3"
        options={Array.isArray(collaboration) ? collaboration.map(({ userinfo = {} }) => {

          return ({
            label: SelectChipWithImage({ picture: (userinfo && userinfo.picture) || '', label: `${(userinfo && userinfo.firstname) || ''} ${(userinfo && userinfo.lastname) || ''}` }),
            value: (userinfo && userinfo.userId) || 0,
          });
        }) : []}
        placeholder={t('collaborator')}
        onChange={(collabId, e) => {
          // if (Array.isArray(e) && e.length === 0) return;
          // else if (Array.isArray(e) && e.length > 0) {
          //   const currCollbs = Array.isArray(collaboration) && collaboration.map(({ userId = null, roleId = null }) => ({ userId, roleId }));
          //   const users = e.map(o => o.value);
          //   const collabs = currCollbs.filter(({ userId = null }) => users.includes(userId));
          //   if (collabs.length !== 0 && collabs.length > eachSubStage.collaborators.length ) {
          //     handleReassignSubStage({
          //       workflowId: workflow.workflowId,
          //       appStageId: eachSubStage.appStageId,
          //       subStageId: eachSubStage.subStageId,
          //       collabs,
          //     });
          //   } else {
          //     const difference = Array.isArray(eachSubStage.collaborators) && eachSubStage.collaborators.filter(({ userinfo = {} }) => !users.includes( userinfo.userId ))[0].userinfo || {};
          //     handleReassignSubStage({
          //       workflowId: workflow.workflowId,
          //       appStageId: eachSubStage.appStageId,
          //       subStageId: eachSubStage.subStageId,
          //       userId: difference.userId,
          //       roleId: (difference.roleId) || 0,
          //       deleteCurrId: difference.userId,
          //     })
          //   }
          // }
          const { collaborators = [] } = eachSubStage;
          const current = Array.isArray(collaboration) && collaboration.filter(one => one.userId == collabId)[0];
          handleReassignSubStage({
            workflowId: workflow.workflowId,
            appStageId: eachSubStage.appStageId,
            subStageId: eachSubStage.subStageId,
            userId: collabId,
            roleId: current.roleId || 0,
            deleteCurrId: collaborators[0].userId,
          });
        }}
        name="collobarator"
        selectedValue={Array.isArray(eachSubStage.collaborators) && eachSubStage.collaborators.map(({ userinfo = {} }) => {
          return ({
            label: SelectChipWithImage({ picture: (userinfo.picture) || '', label: `${(userinfo.firstname) || ''} ${(userinfo.lastname) || ''}` }),
            value: (userinfo.userId) || 0,
          });
        })}
      />
    </div>
  </div>
);

const InterviewCandidateResponse = ({ t, eachStage, eachSubStage }) => (
  <div className="bx--structured-list-row border-0 ">
    {
      (isThisVideoInterviewStage(eachStage) || isThisWrittenAssessmentStage(eachStage)) && isThisFeedbackSubstage(eachSubStage) && !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage) && !isThisWrittenAssessmentSchedulingSubStage(eachStage, eachSubStage) &&
      (
        <div className="bx--structured-list-td p-0 m-0  bx--structured-list-content--nowrap bx--modal-header__label">{t('workflowMsg7')}:&nbsp;</div>
      )
    }
    {
      isThisVideoInterviewStage(eachStage) && isThisFeedbackSubstage(eachSubStage) && !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage) && (eachStage && eachStage.stageMeta && eachStage.stageMeta.videoInterviewId) &&
      (
        <div className="bx--structured-list-td p-0 m-0 pb-2">
          <a href={`/app/campus/interviews/${eachStage && eachStage.stageMeta && eachStage.stageMeta.videoInterviewId}`} className="bx--btn bx--btn--tertiary bx--btn--sm">{t('workflowMsg8')}</a>
        </div>
      )
    }
    {
      isThisWrittenAssessmentStage(eachStage) && isThisFeedbackSubstage(eachSubStage) && !isThisWrittenAssessmentSchedulingSubStage(eachStage, eachSubStage) && (eachStage && eachStage.stageMeta && eachStage.stageMeta.writtenAssessmentId) &&
      (
        <div className="bx--structured-list-td p-0 m-0 pb-2">
          <div>
            {eachStage
              && eachStage.stageMeta
              && eachStage.stageMeta.writtenassessment
              && eachStage.stageMeta.writtenassessment.retakeRequestStatus
              && eachStage.stageMeta.writtenassessment.retakeRequestStatus.toString()
              && eachStage.stageMeta.writtenassessment.retakeRequestStatus.toLowerCase() === "requested"
              && (
                <span>
                  <InlineNotification lowContrast
                    subtitle=""
                    title="Candidate wants to retake the written assessment. Please Click the below button to accept/reject the retake request"
                    kind="warning"
                  />
                </span>
              )
            }
          </div>
          <a href={`/app/campus/assessments/${eachStage && eachStage.stageMeta && eachStage.stageMeta.writtenAssessmentId}`} className="bx--btn bx--btn--tertiary bx--btn--sm">Written Assessment response</a>
        </div>
      )
    }
  </div>
)

const SubStageFeedbackResponse = ({ t, eachSubStage }) => (
  <div className="bx--structured-list-row border-0 ">
    <div className="bx--structured-list-td p-0 m-0  bx--structured-list-content--nowrap bx--modal-header__label d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell">{t('feedback')}:</div>
    <div className="bx--structured-list-td p-0 m-0 d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell">
      {(eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserFeedbackId && eachSubStage.collaborators[0].subStageFeedback && eachSubStage.collaborators[0].subStageFeedback.feedback) || '-'}
    </div>
  </div>
);

const SubStageRating = ({ eachSubStage }) => (
  <div className="bx--structured-list-row border-0 ">
    <div className="bx--structured-list-td p-0 m-0 mt-2 d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell  bx--structured-list-content--nowrap bx--modal-header__label" style={{ position: 'relative', top: '-14px' }}>Rating:</div>
    <div className="bx--structured-list-td p-0 m-0 d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell">
      <StarRatingComponent
        name="rate1"
        starCount={5}
        value={parseInt((eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserFeedbackId && eachSubStage.collaborators[0].subStageFeedback && eachSubStage.collaborators[0].subStageFeedback.outcome && eachSubStage.collaborators[0].subStageFeedback.rating) || 0, 10)}
        starColor="#ffb400"
        emptyStarColor="#ddd"
        renderStarIcon={(index, value) => {
          return (
            <span>
              <i className={index <= value ? 'fa fa-star' : 'fa fa-star'} />
            </span>
          );
        }}
        renderStarIconHalf={() => {
          return (
            <span>
              <span style={{ position: 'absolute' }}><i className="fa fa-star" /></span>
              <span><i className="fa fa-star-half" /></span>
            </span>
          );
        }}
        editing={false}
      />
    </div>
  </div>
)
const SubStageCompletionAction = ({ t, workflow, eachStage, eachSubStage, feedbackForCurrentSubStageInProgress, handleUserSubStageCompletion }) => (
  <div>
    <p>If you would like to take the interview process offline(email etc), click on 'Arrange an interview offline' to skip the interview scheduling process</p>
    <Button
      small
      icon={
        {
          "id": "icon--checkmark",
          "name": "icon--checkmark",
          "tags": "icon--checkmark",
          "styles": "",
          "viewBox": "0 0 10 8",
          "width": "10",
          "height": "8",
          "svgData": {
            "circles": "",
            "ellipses": "",
            "paths": [
              {
                "fill-rule": "nonzero",
                "d": "M8.498 0L10 1.502 3.672 7.83 0 4.158l1.502-1.502 2.17 2.17z"
              }
            ],
            "polygons": "",
            "polylines": "",
            "rects": ""
          }
        }
      }
      iconDescription="Yes"
      onClick={(e) => {
        handleUserSubStageCompletion({
          workflowId: workflow && workflow.workflowId,
          appStageId: eachStage && eachStage.stageMeta && eachStage.stageMeta.appStageId,
          appStageUserMapId: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserMapId,
          feedback: feedbackForCurrentSubStageInProgress,
          outcomeId: 6005,
          openModal: true
        }); feedbackForCurrentSubStageInProgress = '';
      }}
      onFocus={() => { }}
      kind="primary"
      className="ml-0"
    >
      {t('workflowMsg12')}
    </Button>
    {' '}
    {/* <p>If you would like to take the interview process offline(email etc), click on 'Arrange an interview offline' to skip the interview scheduling process</p> */}
  </div>

);

const SubStageReminderAction = ({ t, submitShare }) => (
  <Button
    small
    icon={
      {
        "id": "icon--notification-on",
        "name": "icon--notification-on",
        "tags": "icon--notification-on",
        "styles": "",
        "viewBox": "0 0 14 16",
        "width": "14",
        "height": "16",
        "svgData": {
          "circles": "",
          "ellipses": "",
          "paths": [
            {
              "fill-rule": "nonzero",
              "d": "M11.199 9.9a.5.5 0 0 1-.2-.4V6.23a4 4 0 0 0-3.99-4A3.989 3.989 0 0 0 3.01 6.206L3 9.52a.5.5 0 0 1-.202.4L1 11.252V13h12v-1.75L11.199 9.9zM4.55 14H.5a.5.5 0 0 1-.5-.5V11a.5.5 0 0 1 .202-.402l1.799-1.333.01-3.062A4.99 4.99 0 0 1 6.5 1.254V0h1v1.254a5 5 0 0 1 4.498 4.975v3.02L13.8 10.6a.5.5 0 0 1 .2.4v2.5a.5.5 0 0 1-.5.5H9.45a2.5 2.5 0 0 1-4.9 0zm1.035 0a1.5 1.5 0 0 0 2.83 0h-2.83z"
            }
          ],
          "polygons": "",
          "polylines": "",
          "rects": ""
        }
      }
    }
    iconDescription="Send Reminder"
    onClick={submitShare}
    onFocus={() => { }}
    kind="tertiary"
  >
    {t('workflowMsg10')}
  </Button>
);


const SubStageFeedbackForm = ({ t, workflow, eachStage, feedbackForCurrentSubStageInProgress, handleUserSubStageCompletion, eachSubStage }) => (
  <div className="bx--structured-list-row border-0 ">
    <div className="bx--structured-list-td p-0 m-0  bx--structured-list-content--nowrap bx--modal-header__label d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell">{t('feedback')}:</div>
    <div className="bx--structured-list-td p-0 m-0 d-block d-sm-block d-md-block d-lg-table-cell d-xl-table-cell">
      <div className="bx--row">
        <div className="bx--col-12">
          <TextArea
            labelText=""
            className="some-class"
            onChange={(e) => { feedbackForCurrentSubStageInProgress = e.target.value; }}
            placeholder="Enter your notes/feedback here."
            id="test5"
          />
        </div>
      </div>
      <div className="row">
        <div className="mr-3 col-12 pt-2">
          <Button small kind="primary"
            onClick={(e) => {
              handleUserSubStageCompletion({
                workflowId: workflow && workflow.workflowId,
                appStageId: eachStage && eachStage.stageMeta && eachStage.stageMeta.appStageId,
                appStageUserMapId: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserMapId,
                feedback: feedbackForCurrentSubStageInProgress,
                outcomeId: 6005
              }); feedbackForCurrentSubStageInProgress = '';
            }}
            className="some-class">
            {t('submit')}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const onStarClick = (nextValue, prevValue, name, rating) => {
  rating = nextValue;
}

const getRatingContent = (rating) => {
  switch (parseInt(rating, 10)) {
    case 0:
      return 'Not rated'
    case 1:
      return 'Poor'
    case 2:
      return 'Fair'
    case 3:
      return 'Average'
    case 4:
      return 'Good'
    case 5:
      return 'Excellent'
    default:
      break;
  }
}




const SubStageDecisionActions = ({ t, workflow, eachStage, rating, eachSubStage, feedbackForCurrentSubStageInProgress, handleUserSubStageCompletion, onStarClick, canSendOffer, activeOfferJobModal, dismissOfferJobModal, offerjobModal }) => {
  // const callHandleUserSubStageCompletion = 
  let message = ''
  if (canSendOffer === true) {
    message = `${t('offerMessage1')} ${t('offerMessage2')}`
  }
  else {
    message = <span>You have sent out more offers than approved vacancies. Please contact the <a href="https://www.rp.edu.sg/docs/default-source/student-life-doc/internship/internship---period-master.pdf" target="_blank">RP staff</a> from the respective school/diploma to increase the number of vacancies for this position.</span>
    // message = 'You have sent out more offers than the number of approved vacancies. Would you still like to proceed?'
  }
  return (
    <div className="row">
      {/* <div className="col-12 pb-2">
      <div>
        <strong className="bx--modal-header__label mt-2 ">
          {t('workflowMsg11')}:&nbsp;
        </strong>
      </div>
      <span className="xpa-pos--top-8px">
        <StarRatingComponent
          name={`rate${eachSubStage && eachSubStage.appStageUserMapId}`}
          starCount={5}
          value={rating}
          starColor="#ffb400"
          emptyStarColor="#ddd"
          renderStarIcon={(index, value) => {
            return (
              <span>
                <i className={index <= value ? 'fa fa-star' : 'fa fa-star'} />
              </span>
            );
          }}
          renderStarIconHalf={() => {
            return (
              <span>
                <span style={{ position: 'absolute' }}><i className="fa fa-star" /></span>
                <span><i className="fa fa-star-half" /></span>
              </span>
            );
          }}
          onStarClick={(nextValue, prevValue, name) => { console.log(nextValue); rating = nextValue; }}
        />
        <span>{getRatingContent(rating)}</span>
      </span>
    </div> */}
      <div className=" col-12">
        <Button small kind="tertiary" className="mr-3" onClick={() => activeOfferJobModal()}> Make an Offer </Button>

        <Modal
          open={offerjobModal}
          shouldSubmitOnEnter
          danger
          modalHeading={t('makeAnOffer')}
          primaryButtonText={t('send')}
          secondaryButtonText={t('cancel')}
          onRequestClose={dismissOfferJobModal}
          onRequestSubmit={() => {
            handleUserSubStageCompletion({
              workflowId: workflow && workflow.workflowId,
              appStageId: eachStage && eachStage.stageMeta && eachStage.stageMeta.appStageId,
              appStageUserMapId: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserMapId,
              feedback: feedbackForCurrentSubStageInProgress,
              outcomeId: 6000,
              rating: rating
            }); feedbackForCurrentSubStageInProgress = '';
          }}
        >
          <div className="text-left text-dark">
            {message}
          </div>
        </Modal>

        {/* <Modal
            open={cantOfferjobModal}
            passiveModal
            modalHeading={t('cantMakeAnOffer')}
            onRequestClose={dismissOfferJobModal}
            onRequestSubmit={dismissOfferJobModal}
          >
            <div className="text-left text-dark">
              {message}
            </div>
          </Modal> */}
        {/*<Button
          small
          kind="tertiary"
          onClick={(e) => {
            handleUserSubStageCompletion({
              workflowId: workflow && workflow.workflowId,
              appStageId: eachStage && eachStage.stageMeta && eachStage.stageMeta.appStageId,
              appStageUserMapId: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserMapId,
              feedback: feedbackForCurrentSubStageInProgress,
              outcomeId: 6000,
              rating: rating
            }); feedbackForCurrentSubStageInProgress = '';
          }}
          className="mr-3"
        >
          Make an Offer
      </Button>*/}
        {/* <Button
        small
        kind="danger"
        onClick={(e) => {
          // ModalStateManager()
          handleUserSubStageCompletion({
            workflowId: workflow && workflow.workflowId,
            appStageId: eachStage && eachStage.stageMeta && eachStage.stageMeta.appStageId,
            appStageUserMapId: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserMapId,
            feedback: feedbackForCurrentSubStageInProgress,
            outcomeId: 6001,
            rating: rating
          }); feedbackForCurrentSubStageInProgress = '';
        }
        }
      >
        {t('reject')}
      </Button> */}
        <ModalStateManager
          kind="danger" t={t} modalSize="sm" launchButtonSize="sm"
          primaryButtonText={t('yes')}
          secondaryButtonText={t('cancel')}
          modalBody={t('workflowModalWarningText')}
          modalHeader={t('Are you sure?')}
          onRequestSubmit={() => {
            handleUserSubStageCompletion({
              workflowId: workflow && workflow.workflowId,
              appStageId: eachStage && eachStage.stageMeta && eachStage.stageMeta.appStageId,
              appStageUserMapId: eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserMapId,
              feedback: feedbackForCurrentSubStageInProgress,
              outcomeId: 6001,
              rating: rating
            }); feedbackForCurrentSubStageInProgress = '';
          }} launchButtonText={t('reject')}
          className="bx--btn--sm"
          primaryButtonKind="danger"
          secondaryButtonKind="secondary" />
      </div>

    </div>
  );
}

const getCurrentStageValue = (stage) => {
  const { stageMeta: { currentSubstageId = null } = {}, subStages = [] } = stage;
  if (currentSubstageId === null) return Math.round((100 / subStages.length) * 1);
  let val = 1;
  subStages.find((sub, i) => { val = i + 2; return sub.subStageId == currentSubstageId });
  return Math.round((100 / subStages.length) * val);
};

const ApplicantWorkflowStages = ({
  workflow, appId, jobId, handleUserSubStageCompletion, handleReassignSubStage,
  handleDeleteStage, handleSkipStage, isCandidateFlow = false, application,
  updateParentPage, dismiss, submitShare, user, handleSubstageSchedule,
  notification, onNotifClose, isScheduleBtnLoading,
  handleAddPhone, handlePhoneChange, applicantMobileNumber,
  onDateInputChange, onChangeTime, offerAcceptedCount,
  handleGetSlots, isEditingSlots, value, handleOpenEmailModal, applicationSourceName,
  handleDeleteSlot, handleAddSlot, t, noSlots, handleOnChangeDateTime, availableSlots,
  changeEventAdditionalInfo, eventAdditionalInfo, canSendOffer, activeOfferJobModal,
  dismissOfferJobModal, offerjobModal, isAdditionalInfoInvalid, isAdditionalInfoInvalidText
}) => {
  let currentStageInProgress = getCurrentStageInProgress(workflow);
  let currentSubStageInProgress = getCurrentSubStageInProgress(currentStageInProgress.stage);
  let hasARejectedStage = isThereARejectedStage(workflow);
  const {
    showNotif = false,
    notifType = 'info',
    notifMessage = `${t('unKnownErr')}`,
  } = notification || {};
  const {
    roleId,
  } = user || {};
  const {
    mobile = [],
    collaboration = [],
  } = application || {};
  const {
    mobile: mobileNumber,
  } = (mobile && mobile[0]) || [];
  return (
    workflow && workflow.stages && Array.isArray(workflow.stages) && (workflow.stages).length > 0 ? (
      <div>
        <p>Arrange interview via this system (see below).</p>
        {
          roleId && Number(roleId) !== 3 && (
            <InlineNotification
              lowContrast
              className="mt-2 mb-2"
              // title={t('offerEmpInfoMsg')}
              title={t('offerEmpInfoMsg1')}
              subtitle=""
              iconDescription=""
              kind="info"
            />
          )
        }
        <Accordion>
          {workflow.stages.map((eachStage, index) => {
            const subStagesArr = eachStage && eachStage.subStages && eachStage.subStages.slice(0);//.reverse();
            return (
              eachStage && eachStage.stageMeta && eachStage.stageMeta.workflowstage &&
              (
                <AccordionItem
                  open={isThisStageCurrent(eachStage, currentStageInProgress)}
                  title={
                    <div className="text-uppercase">
                      {/*{eachStage.stageMeta.workflowstage.stageName && eachStage.stageMeta.workflowstage.stageName.replace('Onsite', '')} */}
                    Interview
                    <StageStatus t={t} user={user} collaboration={application && application.collaboration} workflow={workflow} currentStageInProgress={currentStageInProgress} hasARejectedStage={hasARejectedStage} index={index} eachStage={eachStage} handleDeleteStage={handleDeleteStage} handleSkipStage={handleSkipStage} />
                      <StageOutcome t={t} eachStage={eachStage} />
                    </div>
                  }
                  onHeadingClick={() => { }}
                >
                  <div>
                    {
                      showNotif && (
                        <InlineNotification lowContrast
                          kind={notifType}
                          title={notifMessage}
                          subtitle=""
                          iconDescription="closes the error message"
                          onCloseButtonClick={onNotifClose}
                        />
                      )
                    }
                    <Fragment>
                      <div className="d-none d-md-block d-lg-block">
                        <StageProgressIndicator t={t} eachStage={eachStage} />
                      </div>
                      <div className="d-block d-md-none d-lg-none mb-2">
                        <Progress
                          className="bx--col mt-2"
                          theme={
                            {
                              active: {
                                symbol: <div className="bx--type-zeta">{`${getCurrentStageValue(eachStage)}%`}</div>,
                                trailColor: '#f4f7fb',
                                color: '#5596e6',
                              },
                              success: {
                                symbol: <div className="bx--type-zeta">{`${getCurrentStageValue(eachStage)}%`}</div>,
                                trailColor: '#f4f7fb',
                                color: '#5596e6',
                              },
                            }
                          }
                          percent={getCurrentStageValue(eachStage)}
                        />
                      </div>
                    </Fragment>
                    {
                      subStagesArr.map((eachSubStage, subStageIndex) => {
                        const isThisVISchedulingSubStage = isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage);
                        // console.log("----- isThisVISchedulingSubStage ", isThisVISchedulingSubStage);
                        const isThisAssessmentSchedulingSubStage = isThisWrittenAssessmentSchedulingSubStage(eachStage, eachSubStage);
                        const appStageUserMapId = subStagesArr && subStagesArr[subStageIndex + 1] &&
                          eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserMapId
                          && `${eachSubStage && eachSubStage.collaborators && eachSubStage.collaborators[0] && eachSubStage.collaborators[0].appStageUserMapId}-${subStagesArr[subStageIndex + 1] && subStagesArr[subStageIndex + 1].collaborators && subStagesArr[subStageIndex + 1].collaborators[0] && subStagesArr[subStageIndex + 1].collaborators[0].appStageUserMapId}`;
                        // console.log("-------- appStageUserMapId ", appStageUserMapId);

                        return (
                          (!hasARejectedStage || (hasARejectedStage && isThisStageComplete(eachStage))) && (
                            <div>
                              <h6 className=" text-dark bx--type-delta">{eachSubStage && eachSubStage.subStageMeta && eachSubStage.subStageMeta.substage}</h6>
                              <section className="bx--structured-list pb-0 mb-0">
                                <div className="bx--structured-list-tbody">
                                  <SubStageStatus t={t} eachSubStage={eachSubStage} currentSubStageInProgress={currentSubStageInProgress} />
                                  {/* {user && user.roleId !== 3 && <SubStageUserAssignmentInfo t={t} workflow={workflow} eachStage={eachStage} eachSubStage={eachSubStage} user={user} collaboration={application && application.collaboration} handleReassignSubStage={handleReassignSubStage}/>} */}
                                  {(isThisSubStageCurrentlyInProgress(eachSubStage, currentSubStageInProgress)
                                    || isThisSubStageComplete(eachSubStage))
                                    && !isCandidateFlow
                                    && !shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress)
                                    && isThisFeedbackSubstage(eachSubStage)
                                    && user && user.roleId !== 3
                                    && <SubStageFeedbackResponse t={t} rating={rating} eachSubStage={eachSubStage} feedbackForCurrentSubStageInProgress={feedbackForCurrentSubStageInProgress} />
                                  }
                                  {(isThisSubStageCurrentlyInProgress(eachSubStage, currentSubStageInProgress)
                                    || isThisSubStageComplete(eachSubStage))
                                    && !isCandidateFlow
                                    && !shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress)
                                    && isThisDecisionSubstage(eachSubStage)
                                    && user && user.roleId !== 3
                                    && <SubStageRating rating={rating} eachSubStage={eachSubStage} />
                                  }
                                  {/* <div> */}
                                  {(isThisSubStageCurrentlyInProgress(eachSubStage, currentSubStageInProgress) || isThisSubStageComplete(eachSubStage)) && !isCandidateFlow && user && user.roleId !== 3 && <InterviewCandidateResponse t={t} eachStage={eachStage} eachSubStage={eachSubStage} />}
                                  {(isThisSubStageCurrentlyInProgress(eachSubStage, currentSubStageInProgress) || isThisSubStageComplete(eachSubStage)) && !isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress) &&
                                    (
                                      isThisFeedbackSubstage(eachSubStage) && (
                                        <SubStageFeedbackForm t={t} eachStage={eachStage} workflow={workflow} eachSubStage={eachSubStage} handleUserSubStageCompletion={handleUserSubStageCompletion} feedbackForCurrentSubStageInProgress={feedbackForCurrentSubStageInProgress} />
                                      )
                                    )
                                  }
                                </div>
                              </section>
                              <div>
                                {
                                  !isCandidateFlow
                                  && isThisVISchedulingSubStage
                                  && !isThisSubStageComplete(eachSubStage)
                                  && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress) &&
                                  <div>
                                    <CreateAnInterviewForm
                                      interviewId={eachStage && eachStage.stageMeta && eachStage.stageMeta.videoInterviewId}
                                      hideForm={eachStage && eachStage.stageMeta && eachStage.stageMeta.videoInterviewId}
                                      hideTransactionButtons={false}
                                      jobId={jobId ? jobId : 0}
                                      appStageId={eachStage && eachStage.stageMeta && eachStage.stageMeta.appStageId}
                                      appStageUserMapId={appStageUserMapId}
                                      appId={appId} />
                                  </div>
                                }
                              </div>
                              {!isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress) &&
                                (
                                  !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage) && (
                                    isThisDecisionSubstage(eachSubStage) && (
                                      <div>
                                        <SubStageDecisionActions t={t} eachStage={eachStage} workflow={workflow} rating={rating} onStarClick={onStarClick} eachSubStage={eachSubStage} feedbackForCurrentSubStageInProgress={feedbackForCurrentSubStageInProgress} handleUserSubStageCompletion={handleUserSubStageCompletion} canSendOffer={canSendOffer}
                                          activeOfferJobModal={activeOfferJobModal}
                                          dismissOfferJobModal={dismissOfferJobModal} offerjobModal={offerjobModal}
                                        // cantOfferjobModal={cantOfferjobModal}
                                        />
                                      </div>
                                    )
                                  )
                                )
                              }
                              {!isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress) &&
                                (
                                  !isThisASchedulingSubStage(eachSubStage) && !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage) && (
                                    !isThisDecisionSubstage(eachSubStage)
                                    && !isThisDocumentSubstage(eachSubStage)
                                    && !isThisFeedbackSubstage(eachSubStage) && (
                                      <Fragment>
                                        <hr />
                                        <div className="pt-3">
                                          <SubStageCompletionAction t={t} eachStage={eachStage} workflow={workflow} handleUserSubStageCompletion={handleUserSubStageCompletion} eachSubStage={eachSubStage} feedbackForCurrentSubStageInProgress={feedbackForCurrentSubStageInProgress} />
                                        </div>
                                      </Fragment>)
                                  )
                                )
                              }

                              {
                                // !isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress)
                                isThisDocumentSubstage(eachSubStage)
                                && <DocumentsUpload
                                  eachSubStage={eachSubStage}
                                  appId={appId}
                                  handleUserSubStageCompletion={handleUserSubStageCompletion}
                                  jobId={jobId}
                                  handleOpenEmailModal={handleOpenEmailModal}
                                  applicationSourceName={applicationSourceName}
                                  currentSubStageInProgress={currentSubStageInProgress}
                                  isThisSubStageCurrentlyInProgress={isThisSubStageCurrentlyInProgress}
                                />
                              }
                              {!isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress) &&
                                (mobileNumber || !isThisPhoneInterviewStage(eachStage)) && canShowScheduleButton(eachStage, eachSubStage) && (
                                  <Fragment>
                                    <div className="pt-2">
                                      <SlotsPicker
                                        availableSlots={availableSlots}
                                        onChangeDateTime={handleOnChangeDateTime}
                                        onDeleteSlot={handleDeleteSlot}
                                        onAddSlot={handleAddSlot}
                                        changeEventAdditionalInfo={changeEventAdditionalInfo}
                                        eventAdditionalInfo={eventAdditionalInfo}
                                        isAdditionalInfoInvalid={isAdditionalInfoInvalid}
                                        isAdditionalInfoInvalidText={isAdditionalInfoInvalidText}
                                      />
                                      <ScheduleSubStageAction
                                        handleSubStageSchedule={handleSubstageSchedule}
                                        eachSubStage={eachSubStage}
                                        isScheduleBtnLoading={isScheduleBtnLoading}
                                      />
                                    </div>
                                  </Fragment>)
                              }
                              {!isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress) &&
                                (!mobileNumber && isThisPhoneInterviewStage(eachStage)) && canShowScheduleButton(eachStage, eachSubStage) && (
                                  <Fragment>
                                    <div className="pt-2 bx--type-zeta text-danger">
                                      {t('workflowMsg4')}
                                    </div>
                                    <Form className="bx--row pt-2" onSubmit={handleAddPhone}>
                                      <div className="bx--col-md-4">
                                        <TextInput
                                          id="applicant-mobile"
                                          name="applicant-mobile"
                                          hideLabel
                                          placeholder="9876543211"
                                          onChange={handlePhoneChange}
                                          value={applicantMobileNumber}
                                        />
                                      </div>
                                      <Button type="submit" className="ml-2">{t('add')} {t('phone')}</Button>
                                    </Form>
                                  </Fragment>)
                              }
                              {!isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress)
                                && !canShowScheduleButton(eachStage, eachSubStage)
                                && (
                                  isThisPhoneInterviewSchedulingSubStage(eachStage, eachSubStage)
                                  || isThisOnsiteInterviewSchedulingSubStage(eachStage, eachSubStage)
                                  || isThisOnlineInterviewSchedulingSubStage(eachStage, eachSubStage)
                                )
                                && !isSchedulingConfirmed(eachStage, eachSubStage) && (
                                  <Fragment>
                                    { mobileNumber && <div className="mb-1 mt-1">
                                      Applicant Mobile Number:
                                    <span className="font-weight-bold ml-2">{mobileNumber}</span>
                                    </div>}
                                    <div className="pt-2 bx--type-zeta text-primary">
                                      {t('workflowMsg5')}
                                    </div>
                                    <div className="pt-2">
                                      <EventSlotsSent t={t} eachStage={eachStage} />
                                      {
                                        isEditingSlots && ((availableSlots && availableSlots.length > 0) || noSlots)
                                          && availableSlots.length > 0 ? (
                                          <SlotsPicker
                                            availableSlots={availableSlots}
                                            onChangeDateTime={handleOnChangeDateTime}
                                            onDeleteSlot={handleDeleteSlot}
                                            onAddSlot={handleAddSlot}
                                            changeEventAdditionalInfo={changeEventAdditionalInfo}
                                            eventAdditionalInfo={eventAdditionalInfo}
                                          />
                                        ) : (
                                          <div />)
                                      }
                                      <ScheduleSubStageAction
                                        isResend
                                        isReschedule
                                        handleSubStageSchedule={handleSubstageSchedule}
                                        eachSubStage={eachSubStage}
                                        isScheduleBtnLoading={isScheduleBtnLoading}
                                      />
                                    </div>
                                  </Fragment>)
                              }
                              {!isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress) &&
                                (
                                  isThisASchedulingSubStage(eachSubStage)
                                  && !isThisPhoneInterviewSchedulingSubStage(eachStage, eachSubStage)
                                  && isThisOnsiteInterviewSchedulingSubStage(eachStage, eachSubStage)
                                  && !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage)
                                  && !isThisOnlineInterviewSchedulingSubStage(eachStage, eachSubStage)
                                  && !isThisWrittenAssessmentSchedulingSubStage(eachStage, eachSubStage)
                                  && !isThisDocumentCollectionSchedulingSubstage(eachStage, eachSubStage)
                                  && (
                                    !isThisDecisionSubstage(eachSubStage) && !isThisFeedbackSubstage(eachSubStage) && (
                                      <Fragment>
                                        <hr />
                                        <div className="pt-3">
                                          <SubStageCompletionAction t={t} workflow={workflow} eachStage={eachStage} handleUserSubStageCompletion={handleUserSubStageCompletion} eachSubStage={eachSubStage} feedbackForCurrentSubStageInProgress={feedbackForCurrentSubStageInProgress} />
                                        </div>
                                      </Fragment>)
                                  )
                                )
                              }
                              {
                                user && (user.roleId == 3)
                                && isThisOnsiteInterviewSchedulingSubStage(eachStage, eachSubStage)
                                && !isSchedulingConfirmed(eachStage, eachSubStage) &&
                                eachStage && eachStage.stageMeta && eachStage.stageMeta.calendarevents
                                && eachStage.stageMeta.calendarevents && eachStage.stageMeta.calendarevents.rtsUrl
                                && (
                                  <div className="mt-2" style={{ lineHeight: '32px' }}>
                                    <a></a>
                                    <div style={{ display: "inline-block", width: "30%" }}>Schedule interview: </div>
                                    <Button
                                      target="_blank"
                                      rel="noopener,nofollow"
                                      href={eachStage.stageMeta.calendarevents.rtsUrl}
                                      size="small"
                                    >
                                      Book a slot
                                  </Button>
                                  </div>
                                )
                              }
                              {
                                isSchedulingConfirmed(eachStage, eachSubStage) && (
                                  <Fragment>
                                    <EventInfo t={t} eachStage={eachStage} mobileNumber={mobileNumber} user={user} />

                                    {
                                      !isThisSubStageComplete(eachSubStage) && (
                                        <div className="pt-2">
                                          <ScheduleSubStageAction
                                            isReschedule
                                            handleSubStageSchedule={handleSubstageSchedule}
                                            eachSubStage={eachSubStage}
                                            isScheduleBtnLoading={isScheduleBtnLoading}
                                          />
                                        </div>
                                      )
                                    }
                                  </Fragment>
                                )
                              }
                              {
                                shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress)
                                && (
                                  isThisOnSiteOrPhoneInterviewSubstage(eachStage, eachSubStage)
                                  || isThisOnlineInterviewSubstage(eachStage, eachSubStage)
                                )
                                && (
                                  <div className="pt-2">
                                    <ScheduleSubStageAction
                                      isReschedule
                                      handleSubStageSchedule={handleSubstageSchedule}
                                      eachSubStage={eachSubStage}
                                      isScheduleBtnLoading={isScheduleBtnLoading}
                                    />
                                  </div>
                                )
                              }
                              {!isCandidateFlow && shouldUserActOnThisSubStage(user, application, eachSubStage, currentSubStageInProgress) &&
                                (
                                  isThisVideoInterviewStage(eachStage) && !isThisVideoInterviewSchedulingSubStage(eachStage, eachSubStage) && (
                                    !isThisDecisionSubstage(eachSubStage) && !isThisFeedbackSubstage(eachSubStage) && (
                                      <Fragment>
                                        <div className="pt-2">
                                          <SubStageReminderAction t={t} submitShare={() => submitShare(eachStage)} />
                                        </div>
                                      </Fragment>)
                                  )
                                )
                              }
                              {/* </div> */}
                              <hr />
                            </div>)
                        )
                      })}
                  </div>
                </AccordionItem>
              )
            )
          })
          }
        </Accordion>
      </div>
    ) : (<div className="text-dark col-12 pt-2 p-0">
      {
        offerAcceptedCount > 0 ? (
          <div>
            <InlineNotification
              lowContrast
              subtitle=""
              title="This candidate is no longer available."
              kind="error"
            />
          </div>
        ) : (
          <div>
            {
              workflow && workflow.outcomeId !== null ? (
                <div className="bx--type-epsilon">
                  This candidate has completed the interview.
                </div>
              ) : (
                <CreateWorkflowModal
                  appId={appId ? appId : 0}
                  showInModal={true}
                  application={application}
                  show={false}
                  updateParentPage={updateParentPage}
                  dismiss={() => { }}
                  collaboration={collaboration}
                  value={value}
                />
              )
            }
          </div>
        )
      }
    </div>)
  )

}

export default (withTranslation()(ApplicantWorkflowStages));
