import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Tile } from 'carbon-components-react';
import HumanTime from 'utils/humanTime';
const colors = ["#a05195","#346888","#5886a5","#7aa6c2","#9dc6e0","#c1e7ff"];

class ModifiedTooltip extends Component {
    render() {
        const { active, labelTag } = this.props;
        if (active) {
            const { payload, label } = this.props;
            const data = payload && payload[0] && payload[0].payload;
            const timeTaken  = HumanTime.getHumanTime(data.value || 0);
            return (<div className="recharts-default-tooltip columns card rounded shadow-sm" style={{ margin: "5px", padding: "10px", whiteSpace: "nowrap", textAlign: "center", }}>
                    {
                        (
                            <div key={data.name} className="recharts-tooltip-label pb-0 mb-0 row bx--row bx--col-12" style={{ width: "300px" }}>
                                <div className="text-gray pb-0 mb-0  bx--col-xs-12 bx--col-md-6  bx--col-lg-8 bx--type-omega text-left font-weight-normal">
                                    <span className="d-inline-block mr-1" style={{ background: colors[0], width:"10px", height: "10px" }}></span>
                                    {`${data.name} `}<small className="text-dark">{labelTag}</small>
                                </div>
                                <div className="metriccard-number pt-0 pb-0 mb-0 bx--col-xs-12 bx--col-md-6 bx--col-lg-4 bx--type-omega text-left" style={{ color: colors[0] }}>{`${timeTaken}`}</div>
                            </div>
                        )
                    }
                </div>)
        }
        return null;
    }
}

// function getExactTime(milliSec) {
//     let hours = Math.round(((milliSec / 1000) / 60) / 60);
//     let days = Math.floor(hours / 24);
//     hours = days > 0 ? (hours - days * 24) : hours;
//     return (days > 0 ? days.toString() + " days" : "") + " " + (hours > 0 ? hours.toString() + " hours" : "");
// }



function AnalyticsBarGraph(props) {
    const Data = [];
    const keys = props.tag && props.data && props.data.map(ele=>{
        let eachObj = {
            name : ele[props.tag]
        };
        eachObj.value = ele.avg > 0 ? ele.avg : 0;
        Data.push(eachObj);
        return ele[props.tag];
    })||[];
    // console.log("===== AnalyticsBarGraph = ", props, keys, Data);
    const {labelTag="Avg."} = props;
    const avgTime = Array.isArray(props.value) && props.value.reduce((a, b) => a + b, 0);
    return (<div className="analyticsBar">
        <div className="bx--row row m-0 p-0 card-link justify-content-center align-items-center text-lg-left">
            <div className="bx--col-lg-3 bx--col-xs-12 col-lg-4 mt-lg-3">
                <div className="text-dark card-subtitle mb-3 h6">{props.heading || "-"}</div>
                <div className="bx--type-omega card-subtitle text-info h3">
                    {HumanTime.getHumanTime(avgTime)}<span class="d-inline-block bx--type-caption text-dark pl-1">{labelTag}</span>
                </div>
                {/* <div className="bx--type-omega card-subtitle text-info h6">{`${props.progress} ${prop.progress>0?"higher than usual.":"less than usual."}`}</div> */}
            </div>
            <div className="bx--col-lg-9 bx--col-xs-12 col-lg-8 ml-lg-0 mt-lg-0 mt-1 ml-4">
                <ResponsiveContainer className="barGraph justify-content-center align-items-center" width="70%" height="90%" barCategoryGap="5%">
                    <BarChart data={Data || []}
                        className="ml-lg-5" layout="vertical">
                        <Tooltip  content={<ModifiedTooltip labelTag={labelTag}/>} />
                        <XAxis type="number" hide={true}   dataKey="value" domain={['dataMin', 'dataMax']}/>
                        <YAxis type="category" dataKey="name" hide={true} >
                        </YAxis>
                        <Bar dataKey="value" width="100%" fill='#a05195'/>);                        
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>);
}

export default AnalyticsBarGraph;