import { withTranslation } from 'react-i18next';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, isAuthenticated, isAdmin, ...rest }) => (
  <Route
    {...rest}
    render={props => ((isAuthenticated && isAdmin) ? <Component {...props} /> : (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />))
    }
  />
);

export default AdminRoute;
