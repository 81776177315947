import {
  REQUEST_GET_FINLAND_PROFILE,
  ERROR_GET_FINLAND_PROFILE,
  SUCCESS_GET_FINLAND_PROFILE,
  REQUEST_CREATE_FINLAND_PROFILE,
  ERROR_CREATE_FINLAND_PROFILE,
  SUCCESS_CREATE_FINLAND_PROFILE,
  REQUEST_UPDATE_FINLAND_PROFILE,
  ERROR_UPDATE_FINLAND_PROFILE,
  SUCCESS_UPDATE_FINLAND_PROFILE,
  REQUEST_DELETE_FINLAND_PROFILE,
  ERROR_DELETE_FINLAND_PROFILE,
  SUCCESS_DELETE_FINLAND_PROFILE,
  REQUEST_POST_PREFERENCE_RANKS,
  ERROR_POST_PREFERENCE_RANKS,
  SUCCESS_POST_PREFERENCE_RANKS,
  REQUEST_UPDATE_PREFERENCE_RANKS,
  ERROR_UPDATE_PREFERENCE_RANKS,
  SUCCESS_UPDATE_PREFERENCE_RANKS,
  REQUEST_POST_WORK_PREFERENCE,
  ERROR_POST_WORK_PREFERENCE,
  SUCCESS_POST_WORK_PREFERENCE,
  REQUEST_UPDATE_WORK_PREFERENCE,
  ERROR_UPDATE_WORK_PREFERENCE,
  SUCCESS_UPDATE_WORK_PREFERENCE,
  REQUEST_POST_PERSONHOBBIES,
  ERROR_POST_PERSONHOBBIES,
  SUCCESS_POST_PERSONHOBBIES,
  REQUEST_UPDATE_PERSONHOBBIES,
  ERROR_UPDATE_PERSONHOBBIES,
  SUCCESS_UPDATE_PERSONHOBBIES,
  REQUEST_POST_PERSONACTIVITIES,
  ERROR_POST_PERSONACTIVITIES,
  SUCCESS_POST_PERSONACTIVITIES,
  REQUEST_UPDATE_PERSONACTIVITIES,
  ERROR_UPDATE_PERSONACTIVITIES,
  SUCCESS_UPDATE_PERSONACTIVITIES,
  REQUEST_DELETE_PREFERENCE_RANKS,
  ERROR_DELETE_PREFERENCE_RANKS,
  SUCCESS_DELETE_PREFERENCE_RANKS,
  REQUEST_DELETE_WORK_PREFERENCE,
  ERROR_DELETE_WORK_PREFERENCE,
  SUCCESS_DELETE_WORK_PREFERENCE,
  REQUEST_DELETE_PERSONHOBBIES,
  ERROR_DELETE_PERSONHOBBIES,
  SUCCESS_DELETE_PERSONHOBBIES,
  REQUEST_DELETE_PERSONACTIVITIES,
  ERROR_DELETE_PERSONACTIVITIES,
  SUCCESS_DELETE_PERSONACTIVITIES,
  REQUEST_GET_PREFERENCE_RANKS,
  ERROR_GET_PREFERENCE_RANKS,
  SUCCESS_GET_PREFERENCE_RANKS,
  REQUEST_GET_WORK_PREFERENCE,
  ERROR_GET_WORK_PREFERENCE,
  SUCCESS_GET_WORK_PREFERENCE,
  REQUEST_FINLAND_PROFILE_SUBMIT,
  SUCCESS_FINLAND_PROFILE_SUBMIT,
  REQUEST_UPDATE_PERSONSKILL,
  SUCCESS_UPDATE_PERSONSKILL,
  REQUEST_ADD_TO_TALENTPOOL,
  SUCCESS_ADD_TO_TALENTPOOL,
} from '../../actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  preferenceRanks: [],
  workpreferences: [],
  personHobbies: [],
  personActivities: [],
  profile: {},
};

const finlandProfile = (state = initState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case REQUEST_GET_FINLAND_PROFILE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_GET_FINLAND_PROFILE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_GET_FINLAND_PROFILE:
      return {
        ...state,
        loading: false,
        error: false,
        profile: payload,
      };
    case REQUEST_UPDATE_FINLAND_PROFILE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_UPDATE_FINLAND_PROFILE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_UPDATE_FINLAND_PROFILE:
      return {
        ...state,
        loading: false,
        error: false,
        profile: payload,
      };
    case REQUEST_DELETE_FINLAND_PROFILE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_DELETE_FINLAND_PROFILE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_DELETE_FINLAND_PROFILE:
      return {
        ...state,
        loading: false,
        error: false,
        profile: payload,
      };
    case REQUEST_POST_PREFERENCE_RANKS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_POST_PREFERENCE_RANKS:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_POST_PREFERENCE_RANKS:
      return {
        ...state,
        loading: false,
        error: false,
        preferenceRanks: payload,
      };
    case REQUEST_UPDATE_PREFERENCE_RANKS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_UPDATE_PREFERENCE_RANKS:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_UPDATE_PREFERENCE_RANKS:
      return {
        ...state,
        loading: false,
        error: false,
        preferenceRanks: payload,
      };
    case REQUEST_POST_WORK_PREFERENCE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_POST_WORK_PREFERENCE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_POST_WORK_PREFERENCE:
      return {
        ...state,
        loading: false,
        error: false,
        workpreferences: payload,
      };
    case REQUEST_UPDATE_WORK_PREFERENCE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_UPDATE_WORK_PREFERENCE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_UPDATE_WORK_PREFERENCE:
      return {
        ...state,
        loading: false,
        error: false,
        workpreferences: payload,
      };
    case REQUEST_POST_PERSONHOBBIES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_POST_PERSONHOBBIES:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_POST_PERSONHOBBIES:
      return {
        ...state,
        loading: false,
        error: false,
        personHobbies: payload,
      };
    case REQUEST_UPDATE_PERSONHOBBIES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_UPDATE_PERSONHOBBIES:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_UPDATE_PERSONHOBBIES:
      return {
        ...state,
        loading: false,
        error: false,
        personHobbies: payload,
      };
    case REQUEST_POST_PERSONACTIVITIES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_POST_PERSONACTIVITIES:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_POST_PERSONACTIVITIES:
      return {
        ...state,
        loading: false,
        error: false,
        personActivities: payload,
      };
    case REQUEST_UPDATE_PERSONACTIVITIES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_UPDATE_PERSONACTIVITIES:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_UPDATE_PERSONACTIVITIES:
      return {
        ...state,
        loading: false,
        error: false,
        personActivities: payload,
      };
    case REQUEST_DELETE_PREFERENCE_RANKS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_DELETE_PREFERENCE_RANKS:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_DELETE_PREFERENCE_RANKS:
      return {
        ...state,
        loading: false,
        error: false,
        preferenceRanks: payload,
      };
    case REQUEST_DELETE_WORK_PREFERENCE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_DELETE_WORK_PREFERENCE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_DELETE_WORK_PREFERENCE:
      return {
        ...state,
        loading: false,
        error: false,
        workpreferences: payload,
      };
    case REQUEST_DELETE_PERSONHOBBIES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_DELETE_PERSONHOBBIES:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_DELETE_PERSONHOBBIES:
      return {
        ...state,
        loading: false,
        error: false,
        personHobbies: payload,
      };
    case REQUEST_DELETE_PERSONACTIVITIES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_DELETE_PERSONACTIVITIES:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_DELETE_PERSONACTIVITIES:
      return {
        ...state,
        loading: false,
        error: false,
        personActivities: payload,
      };
    case REQUEST_CREATE_FINLAND_PROFILE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERROR_CREATE_FINLAND_PROFILE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SUCCESS_CREATE_FINLAND_PROFILE:
      return {
        ...state,
        loading: false,
        error: false,
        profile: payload,
      };
    case REQUEST_GET_PREFERENCE_RANKS: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ERROR_GET_PREFERENCE_RANKS: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case SUCCESS_GET_PREFERENCE_RANKS: {
      return {
        ...state,
        loading: false,
        error: false,
        preferenceRanks: payload,
      };
    }
    case REQUEST_GET_WORK_PREFERENCE: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ERROR_GET_WORK_PREFERENCE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case SUCCESS_GET_WORK_PREFERENCE: {
      return {
        ...state,
        loading: false,
        error: false,
        workpreferences: payload,
      };
    }
    case REQUEST_FINLAND_PROFILE_SUBMIT: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SUCCESS_FINLAND_PROFILE_SUBMIT: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case REQUEST_UPDATE_PERSONSKILL: {
      return {
        loading: true,
        error: false,
        ...state,
      };
    }
    case SUCCESS_UPDATE_PERSONSKILL: {
      return {
        loading: false,
        error: false,
        ...state,
        updatesSkill: payload,
      };
    }
    case REQUEST_ADD_TO_TALENTPOOL: {
      return {
        loading: true,
        error: false,
        ...state,
      };
    };
    case SUCCESS_ADD_TO_TALENTPOOL: {
      return {
        loading: false,
        error: false,
        ...state,
      };
    };
    default:
      return state;
  }
};

export default finlandProfile;
