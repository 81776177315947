import { transform, camelCase } from 'lodash';
import {
  REQUEST_DIVERSITY_DATA,
  REQUEST_DIMENSION_DATA,
  REQUEST_PAYGAP_DATA,
  REQUEST_ENTERPRISE_DASHBOARD_DATA,
  REQUEST_ENTERPRISE_MEASURES_DATA,
  REQUEST_ENTERPRISE_ATTRIBUTES_DATA,  
  SUCCESS_DIVERSITY_DATA,
  SUCCESS_DIMENSION_DATA,
  SUCCESS_PAYGAP_DATA,
  SUCCESS_ENTERPRISE_DASHBOARD_DATA,
  SUCCESS_ENTERPRISE_MEASURES_DATA,
  SUCCESS_ENTERPRISE_ATTRIBUTES_DATA,
  ERROR_DIVERSITY_DATA,
  ERROR_DIMENSION_DATA,
  ERROR_PAYGAP_DATA,
  ERROR_ENTERPRISE_DASHBOARD_DATA,
  ERROR_ENTERPRISE_MEASURES_DATA,
  ERROR_ENTERPRISE_ATTRIBUTES_DATA  
} from '../../actions/talentSearch/actionTypes';

const initState = {
  diversity: {},
  ethnicity: [],
  paygap: {},
  dimensions: {},
  dashboard: [],
  attributes: [],
  measures: {}
};


const enterprise = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case REQUEST_ENTERPRISE_MEASURES_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };    
    case ERROR_ENTERPRISE_MEASURES_DATA:
      return {
        ...state,
        loading: false,
      };    
    case SUCCESS_ENTERPRISE_MEASURES_DATA:
      return {
        ...state,
        loading: false,
        measures: action.payload
      };    
    case REQUEST_ENTERPRISE_ATTRIBUTES_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };       
    case ERROR_ENTERPRISE_ATTRIBUTES_DATA:
      return {
        ...state,
        loading: false,
        attributes: action.payload
      };        
    case SUCCESS_ENTERPRISE_ATTRIBUTES_DATA:
      return {
        ...state,
        loading: false,
        attributes: action.payload
      }; 
    case REQUEST_ENTERPRISE_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };        
    case ERROR_ENTERPRISE_DASHBOARD_DATA:
      return {
        ...state,
        loading: false,
        dashboard: []
      };            
    case SUCCESS_ENTERPRISE_DASHBOARD_DATA:
      return {
        ...state,
        loading: false,
        dashboard: action.payload
      };  
    case REQUEST_DIVERSITY_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };      
    case ERROR_DIVERSITY_DATA:
      return {
        ...state,
        loading: false,
        diversity: action.payload,
        ethnicity: action.payload && action.payload.aggregations && action.payload.aggregations.ethnicity || state && state.ethnicity,
      };        
    case SUCCESS_DIVERSITY_DATA:
      return {
        ...state,
        loading: false,
        diversity: action.payload,
        ethnicity: action.payload && action.payload.aggregations && action.payload.aggregations.ethnicity || state && state.ethnicity,
      };
    case REQUEST_DIMENSION_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };      
    case ERROR_DIMENSION_DATA:
      return {
        ...state,
        loading: false,
        dimensions: action.payload && action.payload.dimensions || [],
      };      
    case SUCCESS_DIMENSION_DATA:
      return {
        ...state,
        loading: false,
        dimensions: action.payload && action.payload.dimensions || [],
      };
    case REQUEST_PAYGAP_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };       
    case ERROR_PAYGAP_DATA:
      return {
        ...state,
        loading: false,
        paygap: action.payload && action.payload || [],
      };      
    case SUCCESS_PAYGAP_DATA:
      return {
        ...state,
        loading: false,
        paygap: action.payload && action.payload || [],
      };
    default:
      return state;
  }
};

export default enterprise;