import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Tile, Button, ToolbarItem,
  Modal, TextInput, Toggle,
  Pagination, InlineNotification,
  SelectItem, Select,
} from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import Avatar from 'components/common/Avatar';
import ContactDetails from 'components/roboroy/ContactDetails';
import Course from './Course';
import {
  getAllCourses,
} from 'actions/candidate/CandidateDetails';
import {
  Layout,
  PagingInfo,
  Result,
  SearchBox,
  Facets,
  MultiCheckboxFacet,
  SingleSelectFacet,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';

const UsersView = (props) => {
  const {
    t,
    usersList,
    usersCount,
    activeUsersModal,
    isOpenUserModal,
    dismissUserModal,
    propCompanyName,
    handleChange,
    firstname,
    lastname,
    isFirstNameValid,
    isFirstNameValidText,
    isLastNameValid,
    isLastNameValidText,
    isEmailValid,
    email,
    isEmailValidText,
    countryCode,
    isValidCountryCode,
    countries,
    isValidCountryCodeValue,
    phoneNumber,
    isPhoneNumberValid,
    isPhoneNumberValidText,
    designation,
    isValidDesignation,
    usersActivePage,
    isValidDesignationVal,
    handleUsersPaginationChange,
    handleSearchUsersData,
    isAdmin,
    isSuperAdmin,
    active,
    courseAdmin,
    handleIsAdminToggleClick,
    handleIsSuperAdminToggleClick,
    handleActiveToggleClick,
    handleCourseAdminToggleClick,
    handleSubmitUserDetails,
    handleViewNotification,
    userId,
    ctypeId,
    propAdmin,
    propSuperAdmin,
    propCourseAdmin,
    userCtypeId,
    showUserErrNotif,
    paramFacultyId,
    facultyData,
    userFacultyId,
    isValidUserFaculty,
    currUserId,
    hideUserEditOpt,
    saluationListArr,
    handleDropdownChange,
    saluationVal,
    isSaluationValid,
    editSettings,
    isCurrFaculty,
    push,
    secondaryphone,
    schoolId,
    coursesData,
    courseId,
    usersItemsPerPage,
    isCompanyInfo,
    handleCompanyUserActiveToggle,
    isOpenUserJobTransferModal,
    dismissUserJobTransferModal,
    handleSubmitUserJobTransfer,
    userChange,
    selectedUserValue,
    selectedUserToTransferJob,
    inactivityStatus,
    handleInactivityToggle,
    isOpenInactiveModal,
    isTransferJobUserInvalid,
    isTransferJobUserInvalidText,
    dismissInactiveModal,
    handleSubmitInactiveModal,
    inactiveModalMsg,
    isJobTransferUnsuccessfull
  } = props || {};
  let options=(usersList && Array.isArray(usersList)
    && usersList.length > 0
    ? (usersList.map((one, indx) => ({
        label: `${one.saluation && one.saluation.saluationName} ${one.firstname} ${one.lastname}`,
        value: one.userId,
        active: one.active
    }))) : [])
    options = (options && Array.isArray(options)
    && options.length > 0
    ? (options.filter((one, indx) => (
      one.value!=selectedUserValue && one.active && one.active!=false
    ))) : [])
  const cantPerformJobTransfer = options && Array.isArray(options) && options.length == 0;
  const columnWithFilter = [
    {
      title: `${t('user')} ${t('name')}`,
      className: 'text-left',
      name: 'user',
      propName: 'user',
    },
    {
      title: `${t('email')}`,
      className: 'text-left',
      name: 'email',
      propName: 'email',
    },
  ];
  if (ctypeId && Number(ctypeId) === 2 && !paramFacultyId) {
    columnWithFilter.push({
      title: `${t('company')}`,
      className: 'text-left',
      name: 'company',
      propName: 'company',
    },
      {
        title: `${t('isSuperAdmin')}`,
        className: 'text-center',
        name: 'isSuperAdmin',
        propName: 'isSuperAdmin',
      },
      {
        title: `${t('isAdmin')}`,
        className: 'text-center',
        name: 'isAdmin',
        propName: 'isAdmin',
      },
      {
        title: `${t('active')}`,
        className: 'text-center',
        name: 'isSuperAdmin',
        propName: 'isSuperAdmin',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      });
  }
  if (ctypeId && Number(ctypeId) === 2 && paramFacultyId) {
    columnWithFilter.push({
      title: `${t('company')}`,
      className: 'text-left',
      name: 'company',
      propName: 'company',
    },
      {
        title: `${t('isAdmin')}`,
        className: 'text-center',
        name: 'isAdmin',
        propName: 'isAdmin',
      },
      {
        title: `${t('active')}`,
        className: 'text-center',
        name: 'isSuperAdmin',
        propName: 'isSuperAdmin',
      },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      });
  }
  if (ctypeId && Number(ctypeId) === 1) {
    columnWithFilter.push({
      title: `${t('active')}`,
      className: 'text-center',
      name: 'active',
      propName: 'active',
    },
      {
        title: `${t('action')}`,
        className: 'text-center',
        name: 'actions',
        propName: 'actions',
      });
  }
  const otherActionButtons = [];
  if (propSuperAdmin === true || ((propAdmin===true || propCourseAdmin === true) && Number(userCtypeId) === 2 && Number(ctypeId)===1) || (editSettings && isCurrFaculty && hideUserEditOpt !== true && (propAdmin === true && ctypeId && Number(ctypeId) === 1))) {
    otherActionButtons.push(
      <ToolbarItem>
        <Button
          onClick={activeUsersModal}
          kind="primary"
        >
          {t('add')} {t('users')}
        </Button>
      </ToolbarItem>,
    );
  }
  let disableEmail = false;
  if (userId) {
    disableEmail = true;
  } else {
    disableEmail = false;
  }
  return (
    <Fragment>
      <div className="h6 mb-2 mt-2">
        {t('userAccs')}
      </div>
      <div className="mb-2">
        {t('userAccMsg')}
      </div>
      <div className="meta d-flex justify-content-between w-100 align-items-center">
        <div>
          <PagingInfo
            end={Math.min(
              Number(usersCount),
              Number(usersItemsPerPage) *
              Number(usersActivePage)
            )}
            start={
              1 +
              Number(usersActivePage - 1) *
              Number(usersItemsPerPage)
            }
            totalResults={Number(usersCount)}
          />
        </div>
      </div>
      <GenericDataTable
        className=""
        otherActions={otherActionButtons}
        headers={
          columnWithFilter.map((data, index) => (
            {
              key: `header_${index}`,
              header: data.title,
              className: data.className,
              colpropName: data.propName,
            }
          ))}
        rows={
          (usersList && Array.isArray(usersList)
            && usersList.length > 0)
            ? usersList.map((res) => {
              const {
                userId: propUserId,
                firstname: propFirstName,
                lastname: propLastName,
                email: propEmail,
                picture,
                isAdmin: propIsAdmin,
                isSuperAdmin: propIsSuperAdmin,
                active: propActive,
                courseAdmin,
                courseId,
                codeId,
                designation: propDesignation,
                phone,
                facultyId: propFacultyId,
                saluationId,
                schoolId,
                company: {
                  companyName,
                } = {},
                faculty: {
                  facultyName,
                } = {},
                saluation: {
                  saluationName,
                } = {},
                secondaryphone: propSecondaryPhone,
                inactivityStatus
              } = res || {};
              const data = {
                userId: propUserId,
                firstname: propFirstName,
                lastname: propLastName,
                isAdmin: propIsAdmin,
                isSuperAdmin: propIsSuperAdmin,
                active: propActive,
                email: propEmail,
                secondaryphone: propSecondaryPhone,
                countryCode: codeId,
                phoneNumber: phone,
                designation: propDesignation,
                userFacultyId: propFacultyId,
                courseId: courseId,
                courseAdmin: courseAdmin,
                saluationVal: { label: saluationName, value: saluationId },
                inactivityStatus
              };
              return ({
                isExpanded: true,
                id: `row_${propUserId}`,
                header_0: (
                  <div className="d-flex align-items-center">
                    <div>
                      <Avatar
                        user={{
                          firstname: propFirstName,
                          lastname: propLastName,
                          picture,
                        }}
                        classNameInfo="rounded-circle"
                        size="30"
                      />
                    </div>
                    {
                      propSuperAdmin === true ? (
                        // onClick={() => { push && push(`/app/campus/activity/user/${propUserId}`) }}
                        <div className="ml-2 text-capitalize">
                          {propFirstName} {propLastName}
                        </div>
                      ) : (
                          <div className="ml-2 text-capitalize">
                            {propFirstName} {propLastName}
                          </div>
                        )
                    }
                  </div>
                ),
                header_1: (
                  <div>{propEmail}</div>
                ),
                header_2: (
                  <div>
                    {
                      ctypeId && Number(ctypeId) === 2 && (
                        <div>
                          <div className="text-uppercase">{companyName}</div>
                          <div className="text-dark text-capitalize">{facultyName}</div>
                        </div>
                      )
                    }
                    {
                      ctypeId && Number(ctypeId) === 1 && (
                        <div className="d-lg-flex d-md-flex justify-content-center">
                          {!isCompanyInfo ? (
                            <div>
                              <span className="d-lg-none d-md-none bx--type-zeta pr-2">Active:</span>
                              {
                                propActive === true ? (
                                  <div className="bx--tag bx--tag--green text-uppercase">
                                    {t('yes')}
                                  </div>
                                ) : (
                                    <div className="bx--tag bx--tag--red text-uppercase">
                                      {t('no')}
                                    </div>
                                  )
                              }
                            </div>
                          ):(
                            <div>
                              <Toggle
                                // id={`user-active-toggle-${propUserId}}
                                onToggle={(e)=>{
                                  handleCompanyUserActiveToggle(e, propUserId)
                                }}
                                toggled={propActive}
                                labelA={t('no')}
                                labelB={t('yes')}
                                disabled={userId == currUserId}
                              />
                            </div>
                          )}
                        </div>
                      )
                    }
                  </div>
                ),
                header_3: (
                  <div className="d-lg-flex d-md-flex justify-content-center">
                    {
                      ctypeId && Number(ctypeId) === 2 && paramFacultyId ? (
                        <div>
                          <span className="d-lg-none d-md-none bx--type-zeta pr-2">Admin:</span>
                          {
                            propIsAdmin === true ? (
                              <div className="bx--tag bx--tag--green text-uppercase">
                                {t('yes')}
                              </div>
                            ) : (
                                <div className="bx--tag bx--tag--red text-uppercase">
                                  {t('no')}
                                </div>
                              )
                          }
                        </div>
                      ) : (
                          <div>
                            {
                              ctypeId && Number(ctypeId) === 1 || ctypeId && Number(ctypeId) === 2
                                && editSettings 
                                ? (
                                  <div className="d-lg-flex d-md-flex justify-content-center">
                                    {
                                      (propSuperAdmin === true || (propAdmin === true))
                                        ? (
                                          <div>
                                            <div className="d-lg-flex d-md-flex justify-content-center mb-1">
                                              <span className="d-lg-none d-md-none bx--type-zeta pr-2">Action:</span>
                                              <Button onClick={() => activeUsersModal(data)} className="w-100" kind="tertiary" small>{t('edit')}</Button>
                                            </div>
                                            <div className="d-lg-flex d-md-flex justify-content-center">
                                              <span className="d-lg-none d-md-none bx--type-zeta pr-2">Action:</span>
                                              <Button onClick={()=>handleViewNotification(data)} kind="primary" small>{t('View Notifications')}</Button>
                                            </div>
                                          </div>
                                        ) : '-'
                                    }
                                  </div>
                                ) : ctypeId && Number(ctypeId) === 2 && !paramFacultyId ? (
                                  <div className="d-lg-flex d-md-flex justify-content-center">
                                    <span className="d-lg-none d-md-none bx--type-zeta pr-2">Super Admin:</span>
                                    {
                                      propIsSuperAdmin === true ? (
                                        <div className="bx--tag bx--tag--green text-uppercase">
                                          {t('yes')}
                                        </div>
                                      ) : (
                                          <div className="bx--tag bx--tag--red text-uppercase">
                                            {t('no')}
                                          </div>
                                        )
                                    }
                                  </div>
                                ) : '-'
                            }
                          </div>
                        )
                    }
                  </div>
                ),
                header_4: (
                  <div>
                    {
                      ctypeId && Number(ctypeId) === 2 && !paramFacultyId && (
                        <div className="d-lg-flex d-md-flex justify-content-center">
                          <span className="d-lg-none d-md-none bx--type-zeta pr-2">Admin:</span>
                          {
                            propIsAdmin === true ? (
                              <div className="bx--tag bx--tag--green text-uppercase">
                                {t('yes')}
                              </div>
                            ) : (
                                <div className="bx--tag bx--tag--red text-uppercase">
                                  {t('no')}
                                </div>
                              )
                          }
                        </div>
                      )
                    }
                    {
                      ctypeId && Number(ctypeId) === 2 && paramFacultyId && (
                        <div className="d-lg-flex d-md-flex justify-content-center">
                          <span className="d-lg-none d-md-none bx--type-zeta pr-2">Active:</span>
                          {
                            propActive === true ? (
                              <div className="bx--tag bx--tag--green text-uppercase">
                                {t('yes')}
                              </div>
                            ) : (
                                <div className="bx--tag bx--tag--red text-uppercase">
                                  {t('no')}
                                </div>
                              )
                          }
                        </div>
                      )
                    }
                  </div>
                ),
                header_5: (
                  <div>
                    {
                      ctypeId && Number(ctypeId) === 2 && !paramFacultyId && (
                        <div className="d-lg-flex d-md-flex justify-content-center">
                          <span className="d-lg-none d-md-none bx--type-zeta pr-2">Active:</span>
                          {
                            propActive === true ? (
                              <div className="bx--tag bx--tag--green text-uppercase">
                                {t('yes')}
                              </div>
                            ) : (
                                <div className="bx--tag bx--tag--red text-uppercase">
                                  {t('no')}
                                </div>
                              )
                          }
                        </div>
                      )
                    }
                    {
                      (propSuperAdmin === true || isCurrFaculty) && paramFacultyId
                        ? (
                          <div className="d-lg-flex d-md-flex justify-content-center">
                            <span className="d-lg-none d-md-none bx--type-zeta pr-2">Action:</span>
                            <Button onClick={() => activeUsersModal(data)} kind="tertiary" small>{t('edit')}</Button>
                          </div>
                        ) : null
                    }
                    {/* <div className="ml-2">
                      <Button kind="danger" small>{t('delete')}</Button>
                    </div> */}
                  </div>
                ),
                header_6: (
                  <div>
                    {
                      propSuperAdmin === true && !paramFacultyId && ctypeId && Number(ctypeId) === 2
                      && (
                          <div className="d-lg-flex d-md-flex justify-content-center">
                            <span className="d-lg-none d-md-none bx--type-zeta pr-2">Action:</span>
                            <Button onClick={() => activeUsersModal(data)} kind="tertiary" small>{t('edit')}</Button>
                          </div>
                      )
                    }
                  </div>
                )
              });
            }) : []
        }
        selectable={false}
        searchable={false}
        onSearchInputChange={handleSearchUsersData}
        expandable={false}
        batchActions={false}
      />
      {
        (usersCount && usersCount > 0) ? (
          <Pagination onChange={handleUsersPaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={usersActivePage} totalItems={usersCount} />
        ) : null
      }
      {
        (!usersList || usersCount === 0 || (
          usersList && Array.isArray(usersList)
          && usersList.length <= 0
        ))
        && (
          <div className="bx--tile">
            <div className="text-center">
              <div className="empty-icon">
                <i className="fa fa-flag fa-2x" />
              </div>
              <p className="empty-title h5">{t('noFacultyMsg')}</p>
              {
                hideUserEditOpt !== true && ((propAdmin === true && Number(userCtypeId) === Number(ctypeId)) || propSuperAdmin === true)
                && (
                  <Button
                    className="mr-2"
                    onClick={activeUsersModal}
                    kind="primary"
                  >
                    {t('add')} {t('users')}
                  </Button>
                )
              }
            </div>
          </div>
        )
      }
      <Modal
        // className="modal-container"
        open={isOpenUserJobTransferModal}
        modalHeading={`${t('Select user to transfer job')}`}
        modalLabel='Transfer Job'
        primaryButtonText={t('transfer job')}
        secondaryButtonText={t('cancel')}
        iconDescription="Close the modal"
        onRequestClose={dismissUserJobTransferModal}
        onRequestSubmit={handleSubmitUserJobTransfer}
        passiveModal={cantPerformJobTransfer}
      >
        <div>
            <Select
              name="activeUsersList"
              labelText={t('Active Users List')}
              id="activeUsersList"
              onChange={(e) => userChange(e)}
              value={selectedUserToTransferJob}
              className="w-50"
              invalid={isTransferJobUserInvalid}
              invalidText={isTransferJobUserInvalidText}
            >
              <SelectItem text="Select" value="select"/>
              {
                options.map((res) => {
                  const {
                    label,
                    value,
                  } = res || {};
                  return (
                    <SelectItem key={value} text={label} value={value} />
                  );
                })
              }
            </Select>
            {cantPerformJobTransfer &&
              <div className="">
                <span style={{color:"red"}}>
                  There are jobs attached to this user and no other users of this company are currently active, so job transfer cannot be performed and user cannot be set to inactive.
                </span>
              </div>
            }
            {isJobTransferUnsuccessfull && 
              <div className="">
                <span style={{color:"red"}}>
                  {isJobTransferUnsuccessfull.transferJobCount>0 ? 
                    `Job transfer unsuccesfull. Only ${isJobTransferUnsuccessfull.transferJobCount} of ${isJobTransferUnsuccessfull.totalJobs} jobs are transfered successfully. So, user cannot be set as inactive.`
                    :`Job transfer unsuccesfull. No job has been transferred successfully. So, user cannot be set as inactive.`
                  }
                </span>
              </div>
            }
        </div>
      </Modal>

      <Modal
        // className="modal-container"
        open={isOpenInactiveModal}
        modalHeading={`${t('Set user as inactive')}`}s
        modalLabel='Inactive'
        primaryButtonText={t('Set as Inactive')}
        secondaryButtonText={t('cancel')}
        iconDescription="Close the modal"
        onRequestClose={dismissInactiveModal}
        onRequestSubmit={handleSubmitInactiveModal}
      >
        <div>
          <span>{inactiveModalMsg}</span>
        </div>
      </Modal>

      <Modal
        className="modal-container"
        open={isOpenUserModal}
        modalHeading={`${!userId ? `${t('add')}` : `${t('edit')}`} ${t('user')}`}
        modalLabel={`${t('user')}`}
        primaryButtonText={t('save')}
        secondaryButtonText={t('cancel')}
        iconDescription="Close the modal"
        onRequestClose={dismissUserModal}
        onRequestSubmit={handleSubmitUserDetails}
      >
        <div>
          {
            showUserErrNotif === true && (
              <InlineNotification lowContrast
                className="mt-1"
                title=""
                iconDescription="describes the close button"
                kind="error"
                subtitle={t('userExists')}
                onCloseButtonClick={() => { }}
              />
            )
          }
          <TextInput
            labelText={`${ctypeId === 1 ? `${t('company')}` : `${t('institution')}`} ${t('name')}`}
            value={propCompanyName && propCompanyName.toUpperCase()}
            disabled
          />
          <ContactDetails
            firstname={firstname}
            lastname={lastname}
            isSaluationValid={isSaluationValid}
            disabled={false}
            showSecondaryPhone
            secondaryphone={secondaryphone}
            isFirstNameValid={isFirstNameValid}
            isFirstNameValidText={isFirstNameValidText}
            handleChange={handleChange}
            isLastNameValid={isLastNameValid}
            isLastNameValidText={isLastNameValidText}
            isEmailValid={isEmailValid}
            email={email}
            saluationListArr={saluationListArr}
            isEmailValidText={isEmailValidText}
            countryCode={countryCode}
            handleDropdownChange={handleDropdownChange}
            isValidCountryCode={isValidCountryCode}
            saluationVal={saluationVal}
            countries={countries}
            isValidCountryCodeValue={isValidCountryCodeValue}
            phoneNumber={phoneNumber}
            isPhoneNumberValid={isPhoneNumberValid}
            isPhoneNumberValidText={isPhoneNumberValidText}
            designation={designation}
            isValidDesignation={isValidDesignation}
            isValidDesignationVal={isValidDesignationVal}
            disableEmail={disableEmail}
            ctypeId={ctypeId}
          />
          <div className="w-100">
            {
              !paramFacultyId && (
                facultyData && Array.isArray(facultyData)
                && facultyData.length > 0
                && (
                  <Select
                    name="userFacultyId"
                    labelText={t('faculty')}
                    id="userFacultyId"
                    onChange={e => handleChange(e)}
                    value={userFacultyId}
                    className="w-100"
                    invalid={isValidUserFaculty}
                    invalidText={t('commonValidMsg')}
                  >
                    <SelectItem text="Select" />
                    {
                      facultyData.map((res) => {
                        const {
                          facultyId,
                          facultyName,
                        } = res || {};
                        return (
                          <SelectItem key={facultyId} text={facultyName} value={facultyId} />
                        );
                      })
                    }
                  </Select>
                )
              )
            }
          </div>
          {
            ctypeId && Number(ctypeId) === 2 && !paramFacultyId && (
              <div className="mt-2">
                <div className="bx--label">{t('courseadmin')}</div>
                <Toggle
                  id="toggle-4"
                  onToggle={handleCourseAdminToggleClick}
                  toggled={courseAdmin}
                  labelA={t('no')}
                  labelB={t('yes')}
                  disabled={userId == currUserId}
                />
                { courseAdmin === true ? (
                  <Course facultyData={facultyData} t={t} schoolId={schoolId} handleChange={handleChange}
                    courseAdmin={courseAdmin} facultyId={userFacultyId} courseId={courseId} />
                ) : ''
                }
              </div>

            )
          }
          { ctypeId && Number(ctypeId) === 2 && 
            <div className="mt-2">
              <div className="bx--label">{t('isAdmin')}</div>
              <Toggle
                id="toggle-1"
                onToggle={handleIsAdminToggleClick}
                toggled={isAdmin}
                labelA={t('no')}
                labelB={t('yes')}
                disabled={userId == currUserId}
              />
            </div>
          }
          {
            (ctypeId && Number(ctypeId) === 2 && !paramFacultyId) && (
              <div className="mt-2">
                <div className="bx--label">{t('isSuperAdmin')}</div>
                <Toggle
                  id="toggle-2"
                  onToggle={handleIsSuperAdminToggleClick}
                  toggled={isSuperAdmin}
                  labelA={t('no')}
                  labelB={t('yes')}
                  disabled={userId == currUserId}
                />
              </div>
            )
          }

          {!isCompanyInfo &&
            <div className="mt-2">
            <div className="bx--label">{t('active')}</div>
            <Toggle
              id="toggle-3"
              onToggle={handleActiveToggleClick}
              toggled={active}
              labelA={t('no')}
              labelB={t('yes')}
              disabled={userId == currUserId}
            />
          </div>
          }

          { userId ? (
            <div className="mt-2">
              <div className="mt-2">
                <strong>Account Inactivity Status</strong>
              </div>
              {!inactivityStatus ?
              <div>
                <small>Account of this user is currently <strong style={{ color: "green" }}>Active</strong>.</small>
              </div> :
              <div className="">
                <div className="text-dark mt-2 mb-2 ">
                  <small>Account of this user is currently <strong style={{ color: "red" }}>Inactive</strong>. Toggle the below button to activate this account.</small>
                </div>
                <div>
                  <Toggle
                    // id={`user-active-toggle-${propUserId}}
                    onToggle={(e)=>{
                      handleInactivityToggle(e);
                    }}
                    toggled={!inactivityStatus}
                    labelA={t('no')}
                    labelB={t('yes')}
                    disabled={inactivityStatus === false}
                  />
                </div>
              </div>}
            </div>
          ) : null}
        </div>
      </Modal>
    </Fragment>
  );
};

export default (withTranslation()(UsersView));
