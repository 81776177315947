import { withTranslation } from 'react-i18next';
import React from 'react';
import { FlaskLoader } from 'components/common/common';
import GeipLoader from 'assets/images/geip-loader.png';
// import { connect } from 'react-redux';

const JDProcessOverlay = ({ show, t }) =>
  (show ? (
    <div className="jdmodal__overlay">
      <div className="jdmodal__body">
        <div>
          <FlaskLoader />
        </div>
        {/* <h3>{message}</h3> */}
      </div>
    </div>
  ) : null);

// const mapStateToProps = state => ({
//   loading: state.jobCreation && state.jobCreation.jdData && state.jobCreation.jdData.loading,
// });

export default (withTranslation()(JDProcessOverlay));
