import { withTranslation, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import DragablePreferences from "containers/robocampus/DragablePreferences";
import DragableComponent from "containers/robocampus/DragableComponent";
import { getCitiesList } from "actions/CitiesDetails";
import {
  getAllIndustries,
} from 'actions/admin/CompanyAndOffice';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import './Preferences.css';
class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IndustryPreferencesDropdown: [
        "Banking/Finance/Insurance",
        "Aerospace",
        "Artificial Intelligence",
        "Transportation",
        "Travel/Leisure/Tourism",
        "Entertainment",
        "Sports",
        "Apparel/Fashion",
        "Engineering/Manufacturing",
        "Logistics/Supply Chain",
        "Information Technology",
        "Energy",
        "Education",
        "Food",
        "Art/Design",
        'Government/Public Sector',
        "Investment",
        "Health/Fitness",
        "Healthcare",
        "Law",
        "Consultancy",
        "E-Commerce and Retail",
        "Marketing",
        "Advertising",
        "Real Estate",
        "Human Resources",
        "Non-Profit/Social",
        "Media",
        "Environment",
        "Others"
      ],

      jobFunctionPreferencesDropdown: [
        "Data",
        "Accounting/Banking/Finance",
        "Human Resources",
        "Software Engineering",
        "Engineering",
        "Research",
        "Strategy/Consulting",
        "Design",
        "Marketing/Advertising",
        "Administrative",
        "Business Development/Sales",
        "Operations/Procurement",
        "Product/Program Management",
        "Writing/Editing",
        "Others"
      ],
      jobPreferences: [
        "City Preference",
        "Industry Preference",
        "Individual Preference",
        "Job Function Preference"
      ],
      jobPreferencesData: {
        city_preference: props.t("city_preference"),
        industry_preference: props.t("industry_preference"),
        individual_preference: props.t("individual_preference"),
        job_function_preference: props.t("job_function_preference")
      }
    };
  }

  componentDidMount() {
    this.handleOnComponentDidMount();
  }
  componentDidUpdate = (prevProps) => {
    const { jobs: prevJobs, industries:prevIndustries, functions: prevFunctions } = prevProps || {};
    const { jobs, industries, functions } = this.props || {};
    if(prevJobs !== jobs || prevIndustries !== industries || prevFunctions !== functions){
      this.handlePreferences();
    }
  }

  handleOnComponentDidMount = () => {
    this.handlePreferences();
    const data = {
      activePage: 1,
      itemsPerPage: -1,
      keyword: '',
    };
    this.props.getCitiesList(data);
    this.props.getAllIndustries();
  }

  handlePreferences = () => {
    const { jobs = null, industries, functions } = this.props;
    const propIndustryPreferences = industries && Array.isArray(industries) && industries.length>0 && industries.map((one) => {return {value: one, label: one}});
    const propJobFunctionPreferences = functions && Array.isArray(functions) && functions.length>0 && functions.map((one) => {return {value: one, label: one}});
    const stateData = {
      IndustryPreferences: propIndustryPreferences,
      jobFunctionPreferences: propJobFunctionPreferences,
    }
    if (Array.isArray(jobs) && jobs.length > 0) {
      stateData.jobPreferences = jobs;
    }
    this.setState({
      ...stateData,
    })
  }

  sendResults = (data, name) => {
    const info = Array.isArray(data) && data.map(one => one.content);
    this.setState({ [name]: info }, () => this.props.setData(info, name));
  };

  sendJobMatchesRes = (data, name) => {
    const info = data;
    this.setState({ [name]: info }, () => this.props.setData(info, name));
  }

  setPreferenceLimitExceededError = (invalidPropName, invalidPropText) => {
    this.setState({
      [invalidPropName]: true,
      [`${invalidPropName}Text`]: invalidPropText
    },() => {
      setTimeout(() => {
        this.setState({
          [invalidPropName]: false,
          [`${invalidPropName}Text`]: ''
        })    
      }, 5000);
    })
  }

  onPreferenceDropdownChange = (data, name) => {
    const limit = name == 'IndustryPreferences' ? 5: 2;
    if(data && data.length>limit){
      // this.setState({
      //   [`${name}Invalid`]: true, 
      //   [`${name}InvalidText`]: !data?'Please select industry preference':'You can select at most top 5 industries',
      // });
      this.setPreferenceLimitExceededError(`${name}Invalid`, `You can select at most ${limit} options`);
      return;
    }
    const info = Array.isArray(data) && data.map(one => one.label);
    this.setState({ 
      [name]: data,
      [`${name}Invalid`]: false, 
      [`${name}InvalidText`]: ''
    }, () => this.props.setData(info, name))
    // if (value === undefined) return;
    // if (params === 'cities') {
    //   if (value.length <= 3) {
    //     this.setState({ [params]: value });
    //   }
    // } else {
    //   this.setState({ [params]: value });
    // }
  }

  render() {
    const {
      disabled = false,
      industries = [],
      functions = [],
      jobs = [],
      t,
      hide = [],
      cityPreferences=[],
      cities=[],
      jobMatchesPreferences,
      scores,
      user: {
        roleId,
      } = {},
      staticIndustries,
    } = this.props;
    // const industryPref = staticIndustries && Array.isArray(staticIndustries)
    // && staticIndustries.length > 0
    // && staticIndustries.map((ind) => {
    //   const {
    //     industryName,
    //   } = ind;
    //   return industryName;
    // });
    const cityPrefData = cities && Array.isArray(cities) && cities.map(res => res && res.cityName);
    const {
      jobPreferences: jobPref,
      jobFunctionPreferencesDropdown,
      IndustryPreferencesDropdown,
      jobPreferencesData,
      IndustryPreferences,
      jobFunctionPreferences,
    } = this.state;
    const industryPrefDropdown =  IndustryPreferencesDropdown && Array.isArray(IndustryPreferencesDropdown) && IndustryPreferencesDropdown.length>0 && IndustryPreferencesDropdown.map((industry, indx) => { return {value: industry, label: industry}; }); 
    const jobFunctionPrefDropdown =  jobFunctionPreferencesDropdown && Array.isArray(jobFunctionPreferencesDropdown) && jobFunctionPreferencesDropdown.length>0 && jobFunctionPreferencesDropdown.map((jobFunction, indx) => { return {value: jobFunction, label: jobFunction}; }); 
    const jobMatchesPreferencesData = scores && Array.isArray(scores)
    && scores.length > 0
    && scores.map((res) => {
      const {
        jobId,
        job: {
          company: {
            companyName,
          } = {},
          cities: {
            cityName,
          } = {},
          jobname: {
            nameName,
          } = {},
          skills,
        } = {},
      } = res || {};
      const data = {
        id: jobId,
        name: nameName,
        skills,
        companyName,
        cityName,
      };
      return data;
    });
    return (
      <Fragment>
        {!hide.includes("industries") ? (
          <Fragment>
            <div className="bx--type-epsilon mb-3">
              {
                roleId === 3 && (
                  <span>{t("industryPreferences")}</span>
                )
              }
              {
                roleId !== 3 && (
                  <span>{t("industryPrefJob")}</span>
                )
              }
              <span style={{ color: "red" }}>*</span>
            </div>
            <div>
              {!disabled ? <div>
                <DropDownBox
                  // title={t('preferredCities')}
                  required
                  disabled={disabled}
                  custom
                  className={`col-sm-12 col-md-9 m-0 p-0 ${this.state.IndustryPreferencesInvalid?'xpa-invalid-ddb':''}`}
                  labelClass="bx--type-zeta mb-0"
                  titleClass={`m-0`}
                  mainClassName={`row m-0 pl-1`}
                  options={industryPrefDropdown}
                  name="IndustryPreferences"
                  placeholder='Select'
                  onChange={(id, value, name) => this.onPreferenceDropdownChange(value, name)}
                  selectedValue={IndustryPreferences}
                  isMulti
                  noDropdownClass
                />
                {this.state.IndustryPreferencesInvalid && <div className="xpa-ddb-error-msg mb-2">{this.state.IndustryPreferencesInvalidText}</div>}
              </div>:(
                <div>
                  {IndustryPreferences && Array.isArray(IndustryPreferences) && IndustryPreferences.length>0 && IndustryPreferences.map((each) => {
                    const { label } = each || {};
                    return <span className="bx--tag bx--tag--blue">{label}</span>
                  })}
                </div>
              )}
            </div>
            {/* <div className="bx--row bx--col justify-content-center mb-3 m-0 p-0">
              {!disabled ? (
                <DragablePreferences
                  sendResults={data =>
                    this.sendResults(data, "IndustryPreferences")
                  }
                  selected={industries}
                  disabled={disabled}
                  Data={industryPref || []}
                  limit={5}
                  xtype="industries"
                />
              ) : (
                <DragableComponent
                  disabled
                  splitValues
                  sendResults={data =>
                    this.sendResults(data, "IndustryPreferences")
                  }
                  Data={industries || []}
                  xtype="industries"
                />
              )}
            </div> */}
            <hr className="mt-3 mb-3" />
          </Fragment>
        ) : null}
        {!hide.includes("jobfunctions") ? (
          <Fragment>
            <div className="bx--type-epsilon mb-3">
              {
                roleId === 3 && (
                  <span>{t("jobFunctionPreferences")}</span>
                )
              }
              {
                roleId !== 3 && (
                  <span>{t("jobFuncPrefMsg")}</span>
                )
              }
              <span style={{ color: "red" }}>*</span>
            </div>
            <div>
              {!disabled ? <div>
                <DropDownBox
                  // title={t('preferredCities')}
                  required
                  disabled={disabled}
                  custom
                  className={`col-sm-12 col-md-9 m-0 p-0 ${this.state.jobFunctionPreferencesInvalid?'xpa-invalid-ddb':''}`}
                  labelClass="bx--type-zeta mb-0"
                  titleClass={`m-0`}
                  mainClassName={`row m-0 pl-1`}
                  options={jobFunctionPrefDropdown}
                  name="jobFunctionPreferences"
                  placeholder='Select'
                  onChange={(id, value, name) => this.onPreferenceDropdownChange(value, name)}
                  selectedValue={jobFunctionPreferences}
                  isMulti
                  noDropdownClass
                />
                {this.state.jobFunctionPreferencesInvalid && <div className="xpa-ddb-error-msg mb-2">{this.state.jobFunctionPreferencesInvalidText}</div>}
              </div>:(
                <div>
                  {jobFunctionPreferences && Array.isArray(jobFunctionPreferences) && jobFunctionPreferences.length>0 && jobFunctionPreferences.map((each) => {
                    const { label } = each || {};
                    return <span className="bx--tag bx--tag--purple">{label}</span>
                  })}
                </div>
              )}
            </div>
            {/* <div className="bx--row bx--col justify-content-center mb-3 m-0 p-0">
              {!disabled ? (
                <DragablePreferences
                  sendResults={data =>
                    this.sendResults(data, "jobFunctionPreferences")
                  }
                  selected={functions}
                  Data={functionPref || []}
                  limit={2}
                  xtype="jobfunctions"
                />
              ) : (
                <DragableComponent
                  disabled
                  splitValues
                  sendResults={data =>
                    this.sendResults(data, "jobFunctionPreferences")
                  }
                  Data={functions || []}
                  xtype="jobfunctions"
                />
              )}
            </div> */}
            <hr className="mt-3 mb-3" />
          </Fragment>
        ) : null}
        {!hide.includes("jobpreferences") ? (
          <Fragment>
            <div className="bx--type-epsilon mb-3">
              {t("jobPreferencesTitle")}
              <span style={{ color: "red" }}>*</span>
            </div>
            <div className="text-dark font-weight-bold">{t('preferencesTxt')}</div>
            <div className="bx--row bx--col justify-content-center mb-3 m-0 p-0">
              <DragableComponent
                disabled={disabled}
                splitValues
                sendResults={data => this.sendResults(data, "jobPreferences")}
                Data={disabled ? jobs : jobPref || []}
                dataText={jobPreferencesData}
                xtype="preferences"
              />
            </div>
            <hr className="mt-3 mb-3" />
          </Fragment>
        ) : null}
        {!hide.includes("citypreferences") ? (
          <Fragment>
            {/* <div className="bx--type-epsilon mb-3 ml-4 pl-2">
              {t("preferredCities")}
              <span style={{ color: "red" }}>*</span>
            </div> */}
            {/* <div className="bx--row bx--col justify-content-center mb-3 ml-5">
              {!disabled ? (
                <DragablePreferences
                  sendResults={data =>
                    this.sendResults(data, "cityPreferences")
                  }
                  selected={cityPreferences}
                  disabled={disabled}
                  Data={cityPrefData || []}
                  limit={3}
                  xtype="citypreferences"
                />
              ) : (
                <DragableComponent
                  disabled
                  splitValues
                  sendResults={data =>
                    this.sendResults(data, "cityPreferences")
                  }
                  Data={cityPreferences || []}
                  xtype="citypreferences"
                />
              )}
            </div> */}
          </Fragment>
        ) : null}
        {!hide.includes('jobMatches') ? (
          <Fragment>
            <div className="bx--type-epsilon mb-3">
              {t('jobMatches')}
            </div>
            <div className="mt-2 mb-2 bx--tile p-2">
              <p className="text-dark text-justify mt-2">
                {t('draggablePrefMsg')}
              </p>
            </div>
            <div className="bx--row bx--col justify-content-center mb-3 m-0 p-0">
              <DragableComponent
                disabled={disabled}
                splitValues={false}
                sendResults={data => this.sendJobMatchesRes(data, 'jobMatchesPreferences')}
                Data={disabled ? jobMatchesPreferencesData : jobMatchesPreferences || []}
                dataText={jobMatchesPreferencesData}
                xtype="jobMatchesPreferences"
              />
            </div>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  cities: state.StudentProfile.cities,
  staticIndustries: state.companyAndOffice.industries,
});

const mapDispatchToProps = {
  getCitiesList,
  getAllIndustries,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Preferences));
