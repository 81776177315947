import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import RoboSidebar from 'components/roboroy/Sidebar';
import BackRow from 'components/common/BackRow/BackRow';
import ShortListTable from './ShortListTable';

import { xopaScores, jobs } from '../data';
import logo from 'assets/logo-white.png';

import './ShortListCandidates.css';

class ShortListCandidates extends Component {
  state = {
    searchTerm: '',
    xopaScores,
    job: {},
    count: 5,
    activeModal: false,
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      job: jobs[0],
    });
    const { id } = this.props.match.params;
    this.paths = [
      { id: 'dashboard', name: 'Dashboard', href: '/app/campus' },
      { id: 'shortlist', name: 'Shortlist Candidates', href: `/app/campus/jobs/${id}/shotlist` },
    ];
  }

  increment = () => {
    const { count, xopaScores } = this.state;
    if (count > xopaScores.length) {
      alert('no more cadidates to display.');
      return;
    }
    this.setState(ps => ({
      count: parseInt(ps.count, 10) + 1,
    }));
  };

  decrement = () => {
    if (this.state.count === 0) return;
    this.setState(ps => ({
      count: parseInt(ps.count, 10) - 1,
    }));
  };

  activateModal = (c, e) => {
    if (
      e.target.className.includes('check') ||
      e.target.className.includes('times') ||
      e.target.className.includes('fa')
    ) {
      return;
    }
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = () => {
    this.setState({
      activeModal: false,
    });
  };

  sortBy = prop => {
    const xopaScores = this.state.xopaScores.sort((a, b) => (a[prop] || 0) - (b[prop] || 0));

    if (this.sort[prop]) {
      xopaScores.reverse();
      this.sort[prop] = false;
    } else {
      this.sort[prop] = true;
    }

    this.setState({
      xopaScores,
    });
  };

  sort = {
    hireability: true,
    acceptability: true,
    loyality: true,
    performance: true,
    cv_relevance: true,
  };

  render() {
    return (
      <div className="candidates_list">
        {/* <RoboSidebar /> */}
        <div className="candidates_container">
          {/* <BackRow paths={this.paths} /> */}
          <div className="candidates_container__header">
            <div className="candidates_container__header--top">
              <div>
                <h3>Shortlist Candidates</h3>
                <Counter
                  count={this.state.count}
                  increment={this.increment}
                  decrement={this.decrement}
                />
              </div>
              <div className="shortlist__header__right">
                <input
                  placeholder="Search"
                  type="text"
                  name="searchTerm"
                  value={this.state.searchTerm}
                  onChange={this.handleChange}
                />
                <button className="xopa__button nopointer" onClick={this.firXopa}>
                  <img src={logo} alt="xopa logo" />
                  <span>Scores</span>
                </button>
              </div>
            </div>
            <ShortListTable
              xopaScores={xopaScores.slice(0, this.state.count)}
              activateModal={this.activateModal}
              dismissModal={this.dismissModal}
              activeModal={this.state.activeModal}
              sortBy={this.sortBy}
            />
          </div>
        </div>
      </div>
    );
  }
}

const Counter = ({ increment, decrement, count = 0 }) => (
  <div className="counter">
    <span onClick={increment}>
      <i className="fa fa-plus" aria-hidden="true" />
    </span>
    <span>{count}</span>
    <span onClick={decrement}>
      <i className="fa fa-minus" aria-hidden="true" />
    </span>
  </div>
);

// eslint-disable-next-line
const mapStateToProps = state => ({

});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShortListCandidates);
