import {
  REQUEST_CANDIDATE_RECOMMENDED_SKILLS,
  SUCCESS_CANDIDATE_RECOMMENDED_SKILLS,
  ERROR_CANDIDATE_RECOMMENDED_SKILLS,
  REQUEST_SKILLS_RECOMMENDATIONS,
  SUCCESS_SKILLS_RECOMMENDATIONS,
  ERROR_SKILLS_RECOMMENDATIONS,
  REQUEST_JOBS_RECOMMENDATIONS,
  SUCCESS_JOBS_RECOMMENDATIONS,
  ERROR_JOBS_RECOMMENDATIONS,
} from '../../actions/actionTypes';

const initState = {
  loading: true,
  error: null,
  recommendedSkills: [],
};

const SkillsRecommended = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_CANDIDATE_RECOMMENDED_SKILLS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CANDIDATE_RECOMMENDED_SKILLS:
      return {
        ...state,
        recommendedSkills: (payload && payload.skills) || null,
        loading: false,
        error: null,
      };
    case ERROR_CANDIDATE_RECOMMENDED_SKILLS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SKILLS_RECOMMENDATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SKILLS_RECOMMENDATIONS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SKILLS_RECOMMENDATIONS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_JOBS_RECOMMENDATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_JOBS_RECOMMENDATIONS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_JOBS_RECOMMENDATIONS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};
export default SkillsRecommended;
