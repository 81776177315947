import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import ProfileInfo from 'containers/common/profile/ProfileInfo';
import CandidateBio from 'components/roboroy/CandidateBio';
import { EditIcon, AddIcon, TrashIcon } from 'components/common/Icons';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { Mixpanel } from 'components/common/Mixpanel';

import {
  Tile, Modal, TextInput,
  Select, SelectItem, Button,
} from 'carbon-components-react';

import './bioInformation.css';

import { editCandidateProfile, getCountries, getCandidateProfile } from 'actions/talentSearch/candidatesList';

class BioInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPermanentAddress: '',
      address: '',
      address2: '',
      location: '',
      countryId: null,
      countryFull: '',
      stateFull: '',
      zipcode: '',
      permanentAddressExist: false,
      missingFields: false,
    };
  }

  setPermanentAddress = (permanentAddressId = null) => {
    if (permanentAddressId === null || Object.keys(permanentAddressId).length === 0) return;
    if (permanentAddressId.addressId && permanentAddressId.addressId !== null) {
      const {
        addressLine1: address,
        addressLine2: address2,
        zipcode,
        location: {
          locationName: location,
          country: {
            countryFull,
            countryId,
          } = {},
          state: {
            stateFull,
          } = {},
        } = {},
      } = permanentAddressId || {};
      this.setState({
        address, address2, zipcode, location, countryFull,
        countryId, stateFull, permanentAddressExist: true,
      })
    }
  }

  componentDidMount() {
    const {
      candidateProfile: { profile = {}} = {},
      user: {
        profileId,
      },
    } = this.props;
    if (profile !== null && (!profile.profileId || profile.profileId === null)) {
      if (profileId) {
        this.props.getCandidateProfile(profileId).then((res) => {
          const { profile = {} } = res || {};
          this.setPermanentAddress(profile !== null ? profile.permanentAddressId || null : null);
        });
      }
    } else {
      this.setPermanentAddress(profile !== null ? profile.permanentAddressId || null : null);
    }
  }

  closeAddressModal = () => {
    this.setState({
      editPermanentAddress: '',
    });
  }

  handleChange = ({ target: { value, name } }) => {
    const val = value.trimLeft();
    // if (RegExp('^/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/').test(val))
    this.setState({ [name]: val });
  }

  countryChange = ({ target: { value: countryId } }) => {
    if (countryId && countryId !== null) {
      const { countries } = this.props;
      const Country = countries[countryId - 1];
      const { countryFull } = Country;
      this.setState({ countryFull, countryId });
    }
  }

  onSaveAddress = () => {
    const {
      address = '',
      address2 = '',
      location = '',
      countryId = null,
      countryFull = '',
      stateFull = '',
      zipcode = '',
    } = this.state;

    const {
      user: {
        profileId,
      },
    } = this.props;

    let {
      editPermanentAddress = '',
      permanentAddressExist = false,
      isAdd1Valid = false,
      isLocationValid = false,
      isStateValid = false,
      isZipValid = false,
      isCountryValid = false,
    } = {};

    if (address === '') isAdd1Valid = true;
    if (location === '') isLocationValid = true;
    if (countryId === null) isCountryValid = true;
    if (stateFull === '') isStateValid = true;
    if (zipcode === '') isZipValid = true;

    if (!isAdd1Valid && !isLocationValid && !isCountryValid && !isStateValid && !isZipValid) {
      permanentAddressExist = true;
      editPermanentAddress = '';
      this.setState({ permanentAddressExist, editPermanentAddress }, () => {
        const permanentAddress = {
          "permanentaddress": {
            addressLine1: address,
            addressLine2: address2,
             "location" : {
               country : countryFull,
               locationName: location,
               state: stateFull,
             },
             zipcode,
           },
           profileId: Number(profileId),
        };
        this.props.editCandidateProfile(permanentAddress).then(res => {
          this.props.getCandidateProfile(profileId);
        })
      });
    } else {
      this.setState({
        isAdd1Valid, isLocationValid, isCountryValid, isStateValid, isZipValid,
      });
    }
  }

  onNextStep = (goNext) => {
    if (goNext) {
      this.setState({ missingFields: false }, () => this.props.onNextStep());
      return true;
    }
    this.setState({ missingFields: true });
    return false;
  }

  onPrevStep = (e) => {
    this.props.onPrevStep(e);
  }

  render() {
    const {
      missingFields = false,
    } = this.state;

    return (
      <div className="bio_info bx--col m-0">
        <ProfileInfo intern bio />
        <Tile className="mt-3">
          <CandidateBio onPrevStep={e => this.onPrevStep(e)} onNextStep={e => this.onNextStep(e)} />
        </Tile>
        {(
          <Modal open={missingFields} passiveModal danger modalHeading="Validation Error" onRequestClose={() => this.setState({ missingFields: false })}>
            <p className="bx--modal-content__text">
              <div className="bx--row m-0 p-0">
                <div>
                  Please fill all the mandatory fields marked with <span className="text-danger">*</span> to create the profile.
                </div>
              </div>
            </p>
          </Modal>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countries: state.roboroyApplications.countries,
  user: state.x0paDuser.user,
  candidateProfile: state.roboroyApplications.candidateProfile,
  profile: state.roboroyApplications.profile,
  loading: state.roboroyApplications.loading,
});

const mapDispatchToProps = {
  getCountries,
  editCandidateProfile,
  getCandidateProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(BioInformation);

        {/* <Tile className="mt-3">
          <div className="p-0">
            <div className="bx--accordion__title ml-4">
              <div className="bx--row row">
                <div className="mb-1 bx--row m-0 pt-2 ml-1 h5 font-weight-bold text-uppercase">
                  <div>Permanent address</div>
                  <div className="text-danger ml-1">*</div>
                </div>
                {!permanentAddressExist && (
                  <Button onClick={() => this.setState({ editPermanentAddress: 'Add' })} title="Add Address" className="ml-1 mt-1" kind="ghost" small>
                    <AddIcon />
                  </Button>
                )}
                {permanentAddressExist && (
                  <Button onClick={() => this.setState({ editPermanentAddress: 'Edit' })} title="Edit Address" className="ml-1 mt-1" kind="ghost" small>
                    <EditIcon />
                  </Button>
                )}
              </div>
            </div>
            {permanentAddressExist ? (
              <div className="bx--type-strong text-dark ml-3">
                <div>{address},</div>
                {address2 && address2 !== '' && (<div>{address2},</div>)}
                <div>{`${location}, ${stateFull}, ${zipcode}`}</div>
                <div>{countryFull}</div>
              </div>) : (
                <div className="bx--type-delta text-dark ml-2 pl-2 mt-2">Permanent Address is not available</div>
            )}
          </div>
          <Modal
            open={editPermanentAddress !== ''}
            shouldSubmitOnEnter
            modalHeading={`${editPermanentAddress} Address`}
            primaryButtonText={`${editPermanentAddress === 'Add' ? 'Add' : 'Save'}`}
            secondaryButtonText="Cancel"
            onRequestClose={this.closeAddressModal}
            onRequestSubmit={this.onSaveAddress}
          >
            <div className="mt-2 bx--col-12">
              <TextInput name="address" id="address" labelText="Address 1" placeholder="Address" onChange={e => this.handleChange(e)} invalid={isAdd1Valid} invalidText="A valid value is required" value={address} />
            </div>
            <div className="mt-2 bx--col-12">
              <TextInput name="address2" id="address2" labelText="Address 2" placeholder="Address" onChange={e => this.handleChange(e)} value={address2} />
            </div>
            <div className="mt-2 bx--col-12">
              <TextInput name="location" id="location" labelText="Location" placeholder="Location" onChange={e => this.handleChange(e)} invalid={isLocationValid} invalidText="A valid value is required" value={location} />
            </div>
            <div className="mt-2  bx--col-12">
              <TextInput name="stateFull" id="stateFull" labelText="State Name" placeholder="State" onChange={e => this.handleChange(e)} invalid={isStateValid} invalidText="A valid value is required" value={stateFull} />
            </div>
            <div className="bx--col-12 bx--row m-0">
              <div className="mt-2 mr-2">
                <Select name="countryId" labelText="Country" id="countryId" onChange={e => this.countryChange(e)} value={countryId} invalid={isCountryValid} invalidText="A valid value is required">
                  <SelectItem text="Select" />
                  {
                    countries && Array.isArray(countries) && countries.map(b => (
                      <SelectItem key={b.countryId} text={`${b.countryFull}`} value={`${b.countryId}`} />
                    ))
                  }
                </Select>
              </div>
              <div className="mt-2 mb-1">
                <TextInput name="zipcode" id="zipcode" labelText="Zipcode" placeholder="zipcode" onChange={e => this.handleChange(e)} invalid={isZipValid} invalidText="A valid value is required" value={zipcode} />
              </div>
            </div>
          </Modal>
        </Tile> */}
