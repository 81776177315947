import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from 'carbon-components-react';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import '../dashboard.css';
import { fetchCandidateJobs, setInviteStatus } from 'actions/candidate/jobs';
import CandidateJobsList from '../components/CandidateJobsList';

class JobsApplied extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      status: '',
      appliedLoading: false,
    };
  }

  componentDidMount() {
    this.setState({
      status: 'applied',
      appliedLoading: true,
    }, () => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_APPLIED_CANDIDATE_JOBS').then(() => {
        this.setState({
          appliedLoading: false,
        });
      });
    });
  }

  handleJobsAppliedTabClick = (tabType) => {
    let selectedTab = 0;
    let status = '';
    switch (tabType) {
      case 'applied':
        selectedTab = 0;
        status = 'applied';
        break;
      case 'shortlisted':
        selectedTab = 1;
        status = 'shortlisted';
        break;
      case 'rejected':
        selectedTab = 2;
        status = 'rejected';
        break;
      case 'screening':
        selectedTab = 3;
        status = 'screening';
        break;
      default:
        break;
    }
    this.setState({
      selectedTab,
      status,
      appliedLoading: true,
    }, () => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_APPLIED_CANDIDATE_JOBS').then(() => {
        this.setState({
          appliedLoading: false,
        });
      });
    });
  }

  handleRedirectJobsApplied = (link) => {
    switch (link) {
      case 'applied':
        this.props.history.push('/app/campus/candidate/jobs/applied');
        break;
      case 'shortlisted':
        this.props.history.push('/app/campus/candidate/jobs/shortlisted');
        break;
      case 'rejected':
        this.props.history.push('/app/campus/candidate/jobs/rejected');
        break;
      case 'screening':
        this.props.history.push('/app/campus/candidate/jobs/screening');
        break;
      default:
        break;
    }
  }

  handleRedirectToAllAppliedView = (status) => {
    this.props.history.push(`/app/campus/candidate/jobs/${status}`);
  }

  renderApplInvitation = applId => (
    <div className="btn-group mt-0">
      <button
        type="button"
        className="job__details-button-height pt-0 pb-0 bx--btn bx--btn--primary btn-sm btn-success"
        onClick={() => this.handleInvitationAction(applId, true)}
      >
        Accept
      </button>
      <button
        type="button"
        className="job__details-button-height pt-0 pb-0 bx--btn bx--btn--primary btn-sm btn-danger ml-2"
        onClick={() => this.handleInvitationAction(applId, false)}
      >
        Reject
      </button>
    </div>
  );

  renderOfferInvitation = appId => (
    <div className="btn-group">
      <button
        type="button"
        className="job__details-button-height bx--btn bx--btn--primary btn-sm btn-success"
        onClick={() => this.handleOfferAcceptAction(appId, true)}
      >
        Accept
      </button>
      <button
        type="button"
        className="job__details-button-height bx--btn bx--btn--primary btn-sm btn-danger ml-2"
        onClick={() => this.handleOfferAcceptAction(appId, false)}
      >
        Reject
      </button>
    </div>
  )

  handleInvitationAction = (appId, status) => {
    const data = { appId, inviteaccept: status };
    const currenstatus = this.props.setInviteStatus(data);
    currenstatus.then((res, err) => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_APPLIED_CANDIDATE_JOBS').then(() => {
        this.setState({
          invitesLoading: false,
        });
      });
      if (err) return;
      this.setState({
        inviteStatus: status,
      });
    });
  };

  handleOfferAcceptAction = (appId, status) => {
    const data = {
      appId,
      offerConsentAccept: status,
    };
    this.props.setInviteStatus(data).then((res, err) => {
      this.props.fetchCandidateJobs({
        itemsPerPage: 5,
        skip: 0,
        count: false,
        status: this.state.status,
      }, 'REQUEST_APPLIED_CANDIDATE_JOBS').then(() => {
        this.setState({
          invitesLoading: false,
        });
      });
      if (err) return;
      this.setState({
        offerConsentAccept: status,
      });
    });
  }

  render() {
    const {
      candidateJobsApplied,
      candidateJobsShortlisted,
      candidateJobsRejected,
      candidateJobsScreening,
      renderApplInvitation,
      renderOfferInvitation,
      appliedJobs: {
        applications, count,
      } = {},
      t,
    } = this.props;
    const {
      selectedTab, status, appliedLoading,
    } = this.state;
    return (
      <Fragment>
        <div className="mt-2 p-2">
          <span className="heading-style h6 text-uppercase font-style-heading">
            {t('myApplications')}
          </span>
          {/* <div className="bx--row pb-2">
            <div className="bx--col-lg-3 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobsApplied('applied')}}>{candidateJobsApplied || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('jobsApplied')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobsApplied('shortlisted')}}>{candidateJobsShortlisted || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('jobsShortlisted')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobsApplied('rejected')}}>{candidateJobsRejected || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">{t('jobsRejected')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
              <div>
                <div className="d-flex justify-content-around align-items-center p-2 mr-3">
                  <div className="xpa-f-name-sz-width">
                    <div className="bx--type-zeta text-center" style={{ fontSize: 'xx-large' }}>
                      <span className="xpa-link" onClick={() => { this.handleRedirectJobsApplied('screening')}}>{candidateJobsScreening || 0}</span>
                    </div>
                    <div className="text-dark text-uppercase bx--type-caption mb-2 xpa-f-sz">Pending Employer</div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div>
            <GenericTabs
              className="d-flex justify-content-end mb-2"
              selected={selectedTab}
              tabContent={[
                {
                  label: t('jobsAppliedTab'),
                  value: '',
                  onClick: () => { this.handleJobsAppliedTabClick('applied'); },
                },
                {
                  label: t('jobsShortlistedTab'),
                  value: '',
                  onClick: () => { this.handleJobsAppliedTabClick('shortlisted'); },
                },
                {
                  label: t('jobsRejectedTab'),
                  value: '',
                  onClick: () => { this.handleJobsAppliedTabClick('rejected'); },
                },
                {
                  label: 'Pending Employer',
                  value: '',
                  onClick: () => { this.handleJobsAppliedTabClick('screening'); },
                },
              ]}
            />
          </div>
          {
            appliedLoading === true
              && (
                <div className="bx--row justify-content-around">
                  <Loading withOverlay={false} />
                </div>
              )
          }
          {
            appliedLoading === false
              && (
                <CandidateJobsList
                  sortedJobs={applications}
                  status={status}
                  count={count}
                  renderApplInvitation={this.renderApplInvitation}
                  renderOfferInvitation={this.renderOfferInvitation}
                />
              )
          }
          {
            count && count > 5
              ? (
                <div>
                  <span className="xpa-link" onClick={() => { this.handleRedirectToAllAppliedView(status); }}>{t('more')}</span>
                </div>
              ) : null
          }
        </div>
        <hr />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.candidateJobs.loading,
  error: state.candidateJobs.error,
  canjobs: state.candidateJobs,
  appliedJobs: state.candidateJobs.appliedJobs,
});

const mapDispatchToProps = {
  fetchCandidateJobs,
  setInviteStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobsApplied));
