import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { LineChart, Line, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import PipelineComponent from './PipelineComponent';

function AnalyticsFunnel(props) { 
    const {data} = props;
    let hiringFunnelFiltered = [];
    let hiringFunnelLabels = [
      'Applications received',
      'Applications shortlisted',
      'Candidates interviewed',
      'Candidates hired'
    ];

    let goal = 
  

        hiringFunnelFiltered.push(
          {
            title     : hiringFunnelLabels[0],
            actual    : Math.round(data.screening) || 0,
            revenue   : '',
            revLabel  : '',
            goal      : Math.round(data.screening) + 100,
          },
          {
            title     : hiringFunnelLabels[1],
            actual    : Math.round(data.shortlisted) || 0,
            revenue   : '',
            revLabel  : '',
            goal      : Math.round(data.screening) + 100
          },
          {
            title     : hiringFunnelLabels[2],
            actual    : Math.round(data.emailacceptance) || 0,
            revenue   : '',
            revLabel  : '',
            goal      : Math.round(data.screening) + 100
          },
          {
            title     : hiringFunnelLabels[3],
            actual    : Math.round(data['is-hired']) || 0,
            revenue   : '',
            revLabel  : '',
            goal      : Math.round(data.screening) + 100
          },
        );
    
  return (<div className="p-0 pt-2 pt-lg-3 p-lg-3">
    <div className="bx--row row m-0 p-0 card-link text-sm-center text-md-center text-center text-lg-left">
      <div className="bx--col-md-12 bx--col-xs-12 col-lg-12 mt-lg-3 pl-5">
        <div className="text-dark card-subtitle mb-3 h6">{props.heading || "-"}</div>
        <div className="bx--type-omega card-subtitle text-info h3">
          {props.subheading}
        </div>
      </div>
      <div className="bx--col-xs-12 bx--col-md-12 col-lg-12 ml-lg-0 mt-lg-0 mt-1 ml-4 pl-4">
            <PipelineComponent data = {hiringFunnelFiltered}
                outerValueIdentifier = 'revenue'
                outerSecondaryValueIdentifier = 'revLabel'
                innerValueIdentifier = 'actual'
                compareValueIdentifier = 'goal'
                outerLabelIdentifier = 'goalLabel' >
            </PipelineComponent>
      </div>
    </div>
  </div>);
}

export default AnalyticsFunnel;